<div class="container-fluid bgcolor2" [formGroup]="dashboardForm" >
    <form [formGroup]="dashboardForm" autocomplete="off" novalidate>  
    <div class="row"> 
        <div class="col-sm-1">
           
        </div>
        <div class="col-sm-11">
              <div class="row mb-0 pb-0">
                <div class="col-sm-5 text-left justify-content-left mb-0 pb-0">
                          
                  <div class="row pl-5 page-title-box my-4">
              <h1 class="welcome-text mb-0">{{greet}} 
                  {{userName}}</h1>
              <h3 class="welcome-sub-text pl-2 pt-2">
        {{branchData.branchName}} </h3>
              <!-- <h3 class="welcome-sub-text pl-2 pt-2"> to F.C Road Branch </h3> -->
          </div> 
</div> 
                  <div class="col-sm-7">
            </div>
          </div>
                 <div class="row seven-cols app-dashboard">
                  <div class="col-md-1 mb-2">
					<div class="card card2 overflow-hidden">
						<div class="card-body p-0">
							<div class="row"> 
								<div class="col p-0">
									<h2 class="mb-1">{{todaysAppointment}}</h2>
									<p class="muted-text m-0">Todays Appt</p>
								</div>
								<div class="col col-auto p-0">
                  <div class="icon-box icon-box-md">
                    <span class="material-symbols-outlined">
                      event_available
                      </span>
                                  <img src="../../assets/icon-bg4.svg" class="icon-bg iconPadding">
                               </div>
              </div>
							</div>
						</div>
					</div>
                  </div> 
                 
                  <div class="col-md-1 mb-2">
                              <div class="card card2 overflow-hidden">
                                <div class="card-body p-0">
                                  <div class="row">
                                    <div class="col p-0">
                                      <h2 class="mb-1">{{totalAppointmentCompleted}}</h2>
                                      <p class="muted-text m-0">Completed Appt</p>
                                    </div>
                                    <div class="col col-auto p-0">
                                      <div class="icon-box icon-box-md">
                                                      <span class="material-symbols-outlined">check_box</span>
                                                      <img src="../../assets/icon-bg2.svg" class="icon-bg iconPadding">
                                                   </div>
                                  </div>
                                  </div>
                                </div>
                              </div>
                                      </div>
                                      <div class="col-md-1 mb-2">
                                        <div class="card card2 overflow-hidden">
                                          <div class="card-body p-0">
                                            <div class="row">
                                              <div class="col p-0">
                                                <h2 class="mb-1">{{totalAppointmentCancel}}</h2>
                                                <p class="muted-text m-0">Cancel Appt</p>
                                              </div>
                                              <div class="col col-auto p-0">
                                                <div class="icon-box icon-box-md">
                                                  <span class="material-symbols-outlined">event_busy</span>
                                                                <img src="../../assets/icon-bg8.svg" class="icon-bg iconPadding">
                                                             </div>
                                            </div>
                                            </div>
                                          </div>
                                        </div>
                                                </div>
                                                <div class="col-md-1 mb-2">
                                                  <div class="card card2 overflow-hidden">
                                                    <div class="card-body p-0">
                                                      <div class="row">
                                                        <div class="col p-0">
                                                          <h2 class="mb-1">{{totalAppointmentReschedule}}</h2>
                                                          <p class="muted-text m-0">Rescheduled Appt</p>
                                                        </div>
                                                        <div class="col col-auto p-0">
                                                          <div class="icon-box icon-box-md">
                                                            <span class="material-symbols-outlined">event_repeat</span>
                                                                          <img src="../../assets/icon-bg11.svg" class="icon-bg iconPadding">
                                                                       </div>
                                                      </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                          </div>
                                                          <div class="col-md-1 mb-2">
                                                            <div class="card card2 overflow-hidden">
                                                              <div class="card-body p-0">
                                                                <div class="row">
                                                                  <div class="col p-0">
                                                                    <h2 class="mb-1">{{totalASP | number : '1.2-2'}}</h2>
                                                                    <p class="muted-text m-0">Avg. Selling</p>
                                                                  </div>
                                                                  <div class="col col-auto p-0">
                                                                    <div class="icon-box icon-box-md">
                                                                      <span class="material-symbols-outlined">monetization_on</span>
                                                                      <img src="../../assets/icon-bg3.svg" class="icon-bg iconPadding">
                                                                                 </div>
                                                                </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                                    </div>
                  </div>
            <!-- <div class="row seven-cols mt-2">                                 
              <div class="col-md-1">
                    <a class="dashboard-stat primary2" (click)="scrollto('Todays Appointments')">
                      <div class="row">
                     
                      <div class="details">
                        <span class="content">Todays Appointments</span>
                        <span class="value">{{todaysAppointment}}</span>
                      </div> 
                      <div class="visual mll">
                        <img src="../../assets/all.png" width=55>
                      </div>
                      <i class="fa fa-play-circle more"></i>
                      </div>
                    </a>
                </div>
                <div class="col-md-1">
                  <a class="dashboard-stat primary3" (click)="scrollto('Todays Appointments')">
                    <div class="row">
                   
                    <div class="details">
                      <span class="content">Completed Appt.</span>
                      <span class="value">{{totalAppointmentCompleted}}</span>
                    </div> 
                    <div class="visual mll">
                      <img src="../../assets/check.png" class="ml-3" width=55>
                    </div>
                    <i class="fa fa-play-circle more"></i>
                    </div>
                  </a>
              </div>

              <div class="col-md-1">
                <a class="dashboard-stat primary4" (click)="scrollto('Cancel Appointment')">
                  <div class="row">
                  
                  <div class="details">
                    <span class="content">Cancel Appt.</span>
                    <span class="value">{{totalAppointmentCancel}}</span>
                  </div> 
                  <div class="visual mll">
                    <img src="../../assets/signal.png" class="margin_l" width=50>
                  </div>
                  <i class="fa fa-play-circle more"></i>
                  </div>
                </a>
            </div>
            <div class="col-md-1">
              <a class="dashboard-stat primary5" (click)="scrollto('Appointment Rescheduled')">
                <div class="row">
               
                <div class="details">
                  <span class="content"> Rescheduled Appt.</span>
                  <span class="value">{{totalAppointmentReschedule}}</span>
                </div> 
                <div class="visual mll" >
                  <img src="../../assets/wall-clock.png" width=50>
                </div>
                <i class="fa fa-play-circle more"></i>
                </div>
              </a>
          </div>
          <div class="col-md-1">
            <a class="dashboard-stat primary6" (click)="scrollto('Appointment Rescheduled')">
              <div class="row">
            
              <div class="details">
                <span class="content">Avg. Selling Price</span>
                <span class="value">{{totalASP | number : '1.2-2'}}</span>
              </div> 
              <div class="visual mll">
                <img src="../../assets/analytics.png" width=50>
              </div>
              <i class="fa fa-play-circle more"></i>
              </div>
            </a>
        </div>
        </div> -->
        <div class="row"> 
          <div class="row col-sm-12">
                <div class="col-sm-3 mt-1 mb-1" > 
                      <mat-form-field class="example-full-width "  appearance="outline">
                        <mat-label>Select Type</mat-label>
                        <mat-select (click)="selectBranch()" class="colorblack" formControlName="selectType" >
                 
                          <mat-option value="Monthly"  (click)="selectType('Monthly')">Monthly</mat-option>
                          <mat-option value="Yearly" (click)="selectType('Yearly')">Yearly</mat-option>
                          <mat-option value="Custom" (click)="selectType('Custom')">Custom</mat-option>
                        </mat-select>
                      </mat-form-field>
                      </div>
                      <div class="col-sm-3 mt-1 mb-1" *ngIf="isFromToVisible">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Form Date</mat-label>
                          <input matInput placeholder="Date" [matDatepicker]="formDate" readonly formControlName="formDate" [max]="currentdate" (dateChange)="selectFromdate($event)"/>
                          <mat-datepicker-toggle matSuffix [for]="formDate">
                          </mat-datepicker-toggle>
                          <mat-datepicker #formDate></mat-datepicker>
                        </mat-form-field> 
                        </div> 
                        <div class="col-sm-3 mt-1 mb-1" *ngIf="isFromToVisible">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>To Date</mat-label>
                            <input formControlName="toDate" class="colorblack date_font"  matInput [max]="laterDate"
                            [matDatepicker]="toDate" readonly  id="toDate" (dateChange)="toDatee($event)">
                        <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
                        <mat-datepicker #toDate></mat-datepicker>

                            <!-- <input matInput placeholder="Date" readonly [matDatepicker]="toDate" 
                            formControlName="toDate" (dateChange)="toDate()"/>
                            <mat-datepicker-toggle matSuffix [for]="toDate">
                            </mat-datepicker-toggle>
                            <mat-datepicker #toDate></mat-datepicker> --> 
                          </mat-form-field>
                          </div>
                          <div class="col-sm-1 Refreshwidth  text-center mt-2 pr-0 mr-0 pl-0 ml-0">
                            <a (click)="refres()" class="align-self-center ms-1 add-icon highlighted-color"><span class="material-symbols-outlined fontSize mt-2">refresh</span></a>
                           
                          </div>
                          <div class="col-sm-2">
                            <div class="row" *ngIf="userType == 'Super Admin'">
                              <div class="col-sm-10 pr-1">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Branch</mat-label>
                                  <mat-select #select3
                                              (click)="fetchBranchList();" 
                                              formControlName="branchRefId">
                                    <mat-select-filter *ngIf="select3.focused" 
                                                       [placeholder]="'Filter'" 
                                                       [displayMember]="'branchName'"
                                                       [array]="BranchList" 
                                                       (filteredReturn)="filteredListBranch = $event">
                                    </mat-select-filter>
                                    <mat-option *ngFor="let item of filteredListBranch" 
                                                [value]="item._id"
                                                (click)="branchClick(item._id, item.branchName);">
                                      {{ item.branchName }}
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                      
                              </div>
                             
                            </div>
              
                      
                      
                        </div>
                          <!-- <div class="col-sm-2 " *ngIf="!isFromToVisible">
                          </div> -->
                          <div class="col-sm-6 margin_l" *ngIf ="!isFromToVisible">
                          </div>
                          <!-- <div class="col-sm-8 margin_l" *ngIf="!isFromToVisible">
                          </div> -->
                      
                          <div class="col-sm-4 mt-2" >
                            </div>
                            </div>
                            <div class="col-sm-4 mt-2" >
                              <div class="row">
                                <div class="col-sm-12">
                <div class="white_card card card_height_100 mb_30 overflow_hidden"
                 (click)="scrollto('Total Sales')">
                  <div class="white_card_header">
                    <div class="box_header m-0">
                      <div class="main-title">
                        <h4 class="m-0">Total Sales</h4>
                        <!-- <h4 class="m-0">Total Sales</h3> -->
                      </div>
                    </div>
                  </div> 
                  <div class="white_card_body pb-0">
                    <div class="Sales_Details_plan_a">
                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <canvas baseChart
                          [data]="salesArr"
                          [labels]="salesLabels"
                          [options]="barChartOptions"
                          [legend]="barChartLegend"
                          [chartType]="barChartType">
                  </canvas>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4 mt-2" >
            <div class="row">
              <div class="col-sm-12">
                <div class="white_card card_height_100 mb_30 overflow_hidden"
                 (click)="scrollto('Total Purchase')">
                  <div class="white_card_header">
                    <div class="box_header m-0">
                      <div class="main-title">
                        <h4 class="m-0">Total Purchase</h4>
                      </div>
                    </div>
                  </div> 
                  <div class="white_card_body pb-0">
                    <div class="Sales_Details_plan_a">
                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <canvas baseChart
                          [data]="purchaseArr"
                          [labels]="purchaseLabels"
                          [options]="barChartOptions"
                          [legend]="barChartLegend"
                          [chartType]="barChartType"
                          >
                       </canvas> 
                        </div>
                      </div>
                    </div>
                  </div>
                </div> 
              </div>
            </div>
          </div>
          <div class="col-sm-4 mt-2" >
            <div class="row">
              <div class="col-sm-12">
                <div class="white_card card_height_100 mb_30 overflow_hidden"
                 (click)="scrollto('Repair Activity')">
                  <div class="white_card_header">
                    <div class="box_header m-0">
                      <div class="main-title">
                        <h4 class="m-0">Stock In Hand</h4>
                      </div>
                    </div>
                  </div> 
                  <div class="white_card_body pb-0">
                    <div class="Sales_Details_plan_a">
                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <canvas baseChart
                           [data]="stockInHandArr"
                           [labels]="stockInHandLabels" 
                           [chartType]="pieChartType"
                           [legend]="barChartLegend"
                           [options]="barChartOptions" 
                           [colors]='chartColors'></canvas>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> 
          <div class="col-sm-4 mt-3" >
            <div class="row">
              <div class="col-sm-12">
                <div class="white_card card_height_100 mb_30 overflow_hidden"
                 (click)="scrollto('Total Purchase')">
                  <div class="white_card_header">
                    <div class="box_header m-0">
                      <div class="main-title">
                        <h4 class="m-0">Total Bill </h4>
                      </div>
                    </div>
                  </div> 
                  <div class="white_card_body pb-0">
                    <div class="Sales_Details_plan_a">
                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <canvas baseChart
                           [data]="paymentStatusArr"
                           [labels]="pieChartLabelsBill"
                           [chartType]="pieChartType"
                           [colors]='chartColors'
                           [legend]="barChartLegend">
                          </canvas>
                        </div>
                    </div>
                  </div>
</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4 mt-3" >
            <div class="row">
              <div class="col-sm-12">
                <div class="white_card card_height_100 mb_30 overflow_hidden"
                 (click)="scrollto('Repair Activity')">
                  <div class="white_card_header">
                    <div class="box_header m-0">
                      <div class="main-title">
                        <h4 class="m-0">Cheque Status</h4>
                      </div>
                    </div>
                  </div> 
                  <div class="white_card_body pb-0">
                    <div class="Sales_Details_plan_a">
                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <canvas baseChart
                           [data]="pieChartDataCheque"
                           [labels]="pieChartLabelsCheque"
                           [chartType]="pieChartType" 
                           [colors]='chartColors'
                           [legend]="barChartLegend"></canvas>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4 mt-3" >
            <div class="row">
              <div class="col-sm-12">
                <div class="white_card card card_height_100 mb_30 overflow_hidden"
                 (click)="scrollto('Total Sales')">
                  <div class="white_card_header">
                    <div class="box_header m-0">
                      <div class="main-title">
                        <h4 class="m-0">Service Type & Test Type</h4>
                      </div>
                    </div>
                  </div> 
                  <div class="white_card_body pb-0">
                    <div class="Sales_Details_plan_a">
                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <canvas
                            baseChart 
                            [datasets]="arrServiceRateTest" 
                            [labels]="serviceRateTestLabels" 
                            [options]="lineChartOptions"
                            [colors]='chartColors' 
                            [legend]="lineChartLegend" 
                            [chartType]="barChartType" 
                            [plugins]="lineChartPlugins"
                            (chartHover)="chartHovered($event)" 
                            (chartClick)="chartClicked($event)"  >

                        </canvas>
                        
                                <!-- [colors]="lineChartColors"  -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
         
       
         
        
          <div class="col-sm-4 mt-3" >
            <div class="row">
              <div class="col-sm-12">
                <div class="white_card card_height_100 mb_30 overflow_hidden"
                 (click)="scrollto('Total Purchase')">
                  <div class="white_card_header">
                    <div class="box_header m-0">
                      <div class="main-title">
                        <h4 class="m-0">Details About Inquiry </h4>
                      </div>
                    </div>
                  </div> 
                  <div class="white_card_body pb-0">
                    <div class="Sales_Details_plan_a">
                      <div class="row">
                        <div class="col-sm-12 mb-2">
                        <canvas
                            baseChart 
                            [datasets]="InquiryArr" 
                            [labels]="lineChartLabelsInquiry" 
                            [options]="barChartOptions"
                            [colors]="chartColors" 
                            [legend]="barChartLegend" 
                            [chartType]="pieChartType" 
                            [plugins]="lineChartPlugins"
                            (chartHover)="chartHovered($event)" 
                            (chartClick)="chartClicked($event)"
                          >
                     
                        </canvas>
                      </div>
                    </div>
                  </div>
</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4 mt-3" >
            <div class="row">
              <div class="col-sm-12">
                <div class="white_card card_height_100 mb_30 overflow_hidden"
                 (click)="scrollto('Total Purchase')">
                  <div class="white_card_header">
                    <div class="box_header m-0">
                      <div class="main-title">
                        <h4 class="m-0">Total Appointments Done By Audiologists</h4>
                      </div>
                    </div>
                  </div> 
                  <div class="white_card_body pb-0">
                    <div class="Sales_Details_plan_a">
                      <div class="row">
                        <div class="col-sm-12 mb-2">
                        <canvas
                            baseChart 
                            [datasets]="docterArryData"  
                            [labels]="docterLabelsInquiry"  
                            [options]="barChartOptions"
                            [colors]="chartColors" 
                            [legend]="barChartLegend" 
                            [chartType]="pieChartType" 
                            [plugins]="lineChartPlugins"
                            (chartHover)="chartHovered($event)" 
                            (chartClick)="chartClicked($event)"
                          >
                     
                        </canvas>
                      </div>
                    </div>
                  </div>
</div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-sm-4 mt-3" >
            <div class="row">
              <div class="col-sm-12">
                <div class="white_card card_height_100 mb_30 overflow_hidden"
                 (click)="scrollto('Repair Activity')">
                  <div class="white_card_header pb-0 mb-0">
                    <div class="box_header m-0">
                      <div class="main-title row">
                          <div class="col-sm-6">
                        <h4 class="m-0">Repair Activity</h4>
                        </div>
                        <div class="col-sm-6 mt-0 pt-0">
                          <mat-form-field class="example-full-width  mt-0 pt-0"  appearance="outline">
                            <mat-label>Select Type</mat-label>
                            <mat-select class="colorblack" formControlName="repairActivity" > 
                              <mat-option value="scenario1" (click)="RepairActivity('scenario1')">Standard                              </mat-option>
                              <mat-option value="scenario2" (click)="RepairActivity('scenario2')">Pre-Approved Estimate</mat-option>
                            </mat-select>
                          </mat-form-field>
                          </div>
                      </div>
                    </div>
                  </div> 
                  <div class="white_card_body pb-0 mb-0 pt-1">
                    <div class="Sales_Details_plan_a pb-0 mb-0 ">
                      <div class="row pb-0 mb-0">
                        <div style="display: block" style="width: 70vw; height: 35vh;">
                          <canvas *ngIf="scenario1" baseChart
                          [datasets]="scenario1data"
                          [labels]="scenario1dataLabel"
                          [options]="barChartOptions"
                          [legend]="barChartLegend"
                          [chartType]="barChartType"
                          >
                       </canvas>
                       <canvas  *ngIf="scenario2" baseChart
                       [datasets]="scenarioData2"
                       [labels]="scenario2dataLabel"
                       [options]="barChartOptions"
                       [legend]="barChartLegend"
                       [chartType]="barChartType"
                       >
                    </canvas>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <div class="col-sm-4 mt-3" >
            <div class="row">
              <div class="col-sm-12">
                <div class="white_card card_height_100 mb_30 overflow_hidden"
                 (click)="scrollto('Repair Activity')">
                  <div class="white_card_header">
                    <div class="box_header m-0">
                      <div class="main-title">
                        <h4 class="m-0">Clinic Expenses</h4>
                      </div>
                    </div>
                  </div> 
                  <div class="white_card_body pb-0">
                    <div class="Sales_Details_plan_a">
                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <canvas
                          baseChart 
                          [datasets]="clinicExpenseData" 
                          [labels]="labelsClinicExpenses" 
                          [options]="barChartOptions"
                          [colors]="chartColors" 
                          [legend]="barChartLegend" 
                          [chartType]="pieChartType" 
                          [plugins]="lineChartPlugins"
                          (chartHover)="chartHovered($event)" 
                          (chartClick)="chartClicked($event)"
                        >
                
                      </canvas>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
          <div class="col-sm-4 mt-3" >
            <div class="row">
              <div class="col-sm-12">
                <div class="white_card card_height_100 mb_30 overflow_hidden"
                 (click)="scrollto('Repair Activity')">
                  <div class="white_card_header">
                    <div class="box_header m-0">
                      <div class="main-title">
                        <h4 class="m-0">Mode Of Payment</h4>
                      </div>
                    </div>
                  </div> 
                  <div class="white_card_body pb-0">
                    <div class="Sales_Details_plan_a">
                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <canvas baseChart
                           [data]="pieChartDataPaymentMode"
                           [labels]="paymentMode"
                           [chartType]="pieChartType"
                           [options]="lineChartOptions"
                           [colors]='chartColors' 
                           [legend]="lineChartLegend" 
                           [plugins]="lineChartPlugins"
                           [legend]="barChartLegend"
                           ></canvas>

                           <!-- [datasets]="arrServiceRateTest" 
                           [labels]="serviceRateTestLabels" 
                           [options]="lineChartOptions"
                           [colors]='chartColors' 
                           [legend]="lineChartLegend" 
                           [chartType]="barChartType" 
                           [plugins]="lineChartPlugins" -->

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4 mt-3" >
            <div class="row">
              <div class="col-sm-12">
                <div class="white_card card_height_100 mb_30 overflow_hidden"
                 (click)="scrollto('Repair Activity')">
                  <div class="white_card_header">
                    <div class="box_header m-0">
                      <div class="main-title">
                        <h4 class="m-0">Receivable & Payable</h4>
                      </div>
                    </div>
                  </div> 
                  <div class="white_card_body pb-0">
                    <div class="Sales_Details_plan_a">
                      <div class="row">
                        <div class="col-sm-12 mb-2">

                          <canvas baseChart
                          [data]="payReceiveArr"
                          [labels]="payReaceiveLabels" 
                          [chartType]="'pie'"
                          [legend]="true"
                          [options]="pieChartOptions" 
                          [colors]='chartColors'></canvas>

                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4 mt-2" >
            <div class="row">
              <div class="col-sm-12">
                <div class="white_card card card_height_100 mb_30 overflow_hidden"
               >
                  <div class="white_card_header">
                    <div class="box_header m-0">
                      <div class="main-title">
                        <h4 class="m-0">State-District</h4>
                        <!-- <h4 class="m-0">Total Sales</h3> -->
                      </div>
                    </div>
                  </div> 
                  <div class="white_card_body pb-0">
                    <div class="Sales_Details_plan_a">
                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <canvas baseChart
                          [data]="districtData"
                          [labels]="districtLabels"
                          [options]="barChartOptions"
                          [legend]="barChartLegend"
                          [chartType]="barCharteHorizontal">
                  </canvas>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="col-sm-4 mt-3" >
            <div class="row">
              <div class="col-sm-12">
                <div class="white_card card_height_100 mb_30 overflow_hidden"
                 (click)="scrollto('Repair Activity')">
                  <div class="white_card_header">
                    <div class="box_header m-0">
                      <div class="main-title">
                        <h4 class="m-0">Follow Up Status</h4>
                      </div>
                    </div>
                  </div> 
                  <div class="white_card_body pb-0">
                    <div class="Sales_Details_plan_a">
                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <canvas baseChart
                           [data]="followUpStatusArr"
                           [labels]="pieChartLabelsFollowUp"
                           [chartType]="pieChartType"
                           [colors]='chartColors'
                           [legend]="barChartLegend"></canvas>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4 mt-3" >
            <div class="row">
              <div class="col-sm-12">
                <div class="white_card card_height_100 mb_30 overflow_hidden"
                 (click)="scrollto('Repair Activity')">
                  <div class="white_card_header">
                    <div class="box_header m-0">
                      <div class="main-title">
                        <h4 class="m-0">Inquiry To Patient Converted</h4>
                      </div>
                    </div>
                  </div> 
                  <div class="white_card_body pb-0">
                    <div class="Sales_Details_plan_a">
                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <!-- <canvas baseChart
                          [data]="inquryToPatientArr"
                          [labels]="inquiryToPatientLabels"
                          [options]="barChartOptions"
                          [legend]="barChartLegend"
                          [chartType]="barChartType">
                     </canvas> -->
                     <canvas baseChart
                     [data]="inquryToPatientArr"
                     [labels]="inquiryToPatientLabels" 
                     [chartType]="'pie'"
                     [legend]="true"
                     [options]="pieChartOptions" 
                     [colors]='chartColors'></canvas>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
            <div class="col-sm-4 mt-2" >
            <div class="row">
              <div class="col-sm-12">
                <div class="white_card card_height_100 mb_30 overflow_hidden"
                 (click)="scrollto('Repair Activity')">
                  <div class="white_card_header">
                    <div class="box_header m-0">
                      <div class="main-title">
                        <h4 class="m-0">HA Business Converted</h4>
                      </div>
                    </div>
                  </div> 
                  <div class="white_card_body pb-0">
                    <div class="Sales_Details_plan_a">
                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <!-- <canvas
                          baseChart
                          [chartType]="'pie'"
                          [data]="pieChartData"
                          [labels]="pieChartLabels"
                          [options]="pieChartOptions"
                          [legend]="true"
                          [colors]="pieChartColor">
                      </canvas> -->
                          <canvas baseChart
                           [data]="HaBusinessArr"
                           [labels]="pieChartLabels" 
                           [chartType]="'pie'"
                           [legend]="true"
                           [options]="pieChartOptions" 
                           [colors]='chartColors'></canvas>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
                </div>
    </div>
      </div>
      </form>
    </div>
    