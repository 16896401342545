import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
 import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  MatSnackBar, 
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { AfterViewInit, Directive, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner"; 
import { PopUpBranchComponent } from "../pop-up-branch/pop-up-branch.component";
import { HttpClient } from "@angular/common/http";
@Component({
  selector: 'app-doctor-master',
  templateUrl: './doctor-master.component.html',
  styleUrls: ['./doctor-master.component.scss']
})
export class DoctorMasterComponent implements OnInit {

  errormessage: string;
  page: number = 1;
  size: number = 10;
  doctorList: any;
  totalLength: any;
  searchText: any;
  did: any;

  allState=[
    {name:'Andaman and Nicobar Islands',value:'AN' },
    {name:'Andhra Pradesh',value:'AP'},
    { name:'Arunachal Pradesh',value:'AR' },
{name:'Assam',value:'AS' },
{ name:'Bihar',value:'BR'},
 { name:'Chandigarh',value:'CH' },
{name:'Chhattisgarh',value:'CT' },
{ name:'Dadra and Nagar Haveli', value:'DN' },
 { name:'Daman and Diu', value:'DD'},
 { name:'Delhi',value:'DL'},
 {name:'Goa',value:'GA' },
{name:'Gujarat', value:'GJ'}, 
{name:'Haryana',value:'HR'}, 
{name:'Himachal Pradesh',value:'HP'}, 
{ name:'Jammu and Kashmir',value:'JK'}, 
 {name:'Jharkhand',value:'JH'}, 
{ name:'Karnataka', value:'KA'},
{ name:'Kerala',value:'KL'},
{name:'Ladakh',value:'LA'},
 {name:'Lakshadweep',value:'LD' },
 {name:'Madhya Pradesh', value:'MP'},
{ name:'Maharashtra',value:'MH'},
 { name:'Manipur',value:'MN'},
{ name:'Meghalaya', value:'ML' },
{ name:'Mizoram',value:'MZ' },
{ name:'Nagaland',value:'NL' },
 { name:'Odisha',value:'OR'},
 { name:'Puducherry', value:'PY'},
 {name:'Punjab', value:'PB' },
{ name:'Rajasthan', value:'RJ'},           
 {name:'Punjab',value:'PB'},   
 {name:'Rajasthan', value:'RJ'},
{name:'Sikkim',value:'SK' },
{ name:'Tamil Nadu',value:'TN'}, 
 {name:'Telangana',value:'TG'},   
  { name:'Tripura',  value:'TR' },
 { name:'Uttar Pradesh', value:'UP'},
{name:'Uttarakhand',value:'UT'},  
{ name:'West Bengal', value:'WB' },                        
  ]
  allPreFix=[
    {name:'Mr',value:'Mr' },
    {name:'Dr',value:'Dr' },
    {name:'Dr(Mr)',value:'Dr(Mr)' },
    { name:'Dr(Mrs)',value:'Dr(Mrs)'},
    {name:'Dr(Ms)',value:'Dr(Ms)'},
    {name:'Dr(Smt)',value:'Dr(Smt)'},
      ]
 
      filteredList: { name: string; value: string; }[];
      filteredListPreFix: { name: string; value: string; }[];
  BranchList: any;
  branchName: string;
  filteredListBranch: any;
  minDate: Date;
  bID: any;
  stateCityArr2: any[];
  stateCity2: any;
  districtData2: any;
  filteredList2: { state: string; districts: string; }[];
  filteredListDistrict2: { district: string;}[];

  stateCityArr3: any[];
  stateCity3: any;
  districtData3: any;
  filteredList3: { state: string; districts: string; }[];
  filteredListDistrict3: { district: string;}[];
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
     public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    private httpClient: HttpClient,
  ) {}
  doctorMasterForm: FormGroup;
  status: any;
  submitButton = true;
  updateButton = false;
  deleteButton = false;
  cancelButton= false;
  loggedId: any;
  doctorImage:any;
  date: any;
  altAddressCheck = false;
  isChecked= false;
  branchRef: string;
  clinicRef: string;
  
  userType: string;
  userPermission:any;
  doctorCreate= true;
  doctorUpdate= true;
  isSuperAdmin= true;
  superBranchId = 0;
  maxdate:any;
  
  ngOnInit() {
    this.page=1;
    const currentYear = new Date().getFullYear();
   // this.minDate = new Date(currentYear - 9, 0, 1);
    this.maxdate = new Date(currentYear - 17, 11, 31);

    //this.maxdate = new Date(2005,0,1);
    this.minDate = new Date(1900,0,1);
    this.filteredList = this.allState.slice();
    this.filteredListPreFix = this.allPreFix.slice();
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.userType = sessionStorage.getItem('userType');

    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    if(this.userType !== 'Super Admin'){
      this.isSuperAdmin= false;
    this.userPermission.forEach((element) => {
      if(element.pageName == 'Doctor' && element.create == false)
      {
          this.doctorCreate = false;  
      }
      if(element.pageName == 'Doctor' && element.update == false)
      {
          this.doctorUpdate = false;  
      }    
    });
  }
    this.doctorMasterForm = this.fb.group({
      "branchWiseId": new FormControl(''),
      "prefix": new FormControl(''),
      "firstName": new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z-\\s]*$"),]),
      "lastName": new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z-\\s]*$"),]),
      "middleName": new FormControl('',Validators.pattern("^[a-zA-Z-\\s]*$")),
      "alternateName": new FormControl('', Validators.pattern("^[a-zA-Z-\\s]*$")),
      "dob": new FormControl('',[Validators.required]),
      "age": new FormControl(''),
      "sex": new FormControl('',Validators.required),
      "doctorStatus": new FormControl(''),
      "landline": new FormControl('',Validators.pattern("[0-9]{3,4}[-][0-9]{6,8}")),
      "mobile1": new FormControl('', [
        Validators.required,
        Validators.pattern("(0|91)?[6-9][0-9]{9}"),
      ]),
      "mobile2": new FormControl('',Validators.pattern("^((\\+91-?)|0)?[6-9][0-9]{9}$")),
      "email": new FormControl('', [Validators.pattern("[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}")]),
      "streetAddress": new FormControl('',Validators.required),
      "taluka": new FormControl(''),
      "district": new FormControl('',),
      "state": new FormControl('',Validators.required),
      "pincode": new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"),Validators.maxLength(6),Validators.minLength(6),]),
      "country": new FormControl('', [
        Validators.pattern("[A-Za-z _-]{3,19}$"),
      ]),
      "streetAddress2": new FormControl(''),
      "taluka2": new FormControl('',Validators.pattern("^[a-zA-Z ]*$")),
      "district2": new FormControl('',Validators.pattern("^[a-zA-Z ]*$")),
      "state2": new FormControl(''),
      "pincode2": new FormControl('',Validators.pattern("^[0-9]{6}$")),
      "country2": new FormControl('', [
        Validators.pattern("[A-Za-z _-]{3,19}$"),
      ]),
      "altAddress": new FormControl(''),
      "notes": new FormControl(''),
      "degree": new FormControl(''),
      "photo": new FormControl(''),
      "createdAt": this.date,
      "updatedAt": this.date,
      "searchDoctor": new FormControl(''),
      "branchRefId": new FormControl(),
      "clinicRefId": new FormControl(),
      "branchName": new FormControl(''),
     
    });
    this.fetchDoctorsList();
    this.getLastId();
    this.doctorImage ="../../assets/user2.png";
    //
    this.doctorMasterForm.controls["doctorStatus"].setValue("Active");
    this.doctorMasterForm.controls["country"].setValue("India");
    this.doctorMasterForm.controls["country2"].setValue("India");
    this.doctorMasterForm.controls["state"].setValue("Maharashtra");
    this.doctorMasterForm.controls["state2"].setValue("Maharashtra");
    let arr=[]
    arr = [
      "Ahmednagar",
      "Akola",
      "Amravati",
      "Aurangabad",
      "Beed",
      "Bhandara",
      "Buldhana",
      "Chandrapur",
      "Dhule",
      "Gadchiroli",
      "Gondia",
      "Hingoli",
      "Jalgaon",
      "Jalna",
      "Kolhapur",
      "Latur",
      "Mumbai City",
      "Mumbai Suburban",
      "Nagpur",
      "Nanded",
      "Nandurbar",
      "Nashik",
      "Osmanabad",
      "Palghar",
      "Parbhani",
      "Pune",
      "Raigad",
      "Ratnagiri",
      "Sangli",
      "Satara",
      "Sindhudurg",
      "Solapur",
      "Thane",
      "Wardha",
      "Washim",
      "Yavatmal"
  ]
  this.getDistrict2(arr);
  this.getDistrict3(arr);
   // this.removeValidator();
   // this.fetchBranchList();
   this.getState2();
   this.getState3();
  }

  getState2()
  {
   this.httpClient.get("assets/state-city.json").subscribe(data =>{ 
     this.stateCity2 = data;
     this.stateCityArr2 = [];
     this.stateCity2.states.forEach((element) => {
       this.stateCityArr2.push(element);
     });
     this.filteredList2 = this.stateCityArr2.slice();
     //
   })
  }
  getDistrict2(state)
  {
   //
   this.districtData2 = [];
   state.forEach((element) => {
     //
     this.districtData2.push({"district":element});
   });
   //
   let arr = [];
   arr = state;
  this.filteredListDistrict2 = this.districtData2.slice();
  }
  getState3()
  {
   this.httpClient.get("assets/state-city.json").subscribe(data =>{ 
     this.stateCity3 = data;
     this.stateCityArr3 = [];
     this.stateCity3.states.forEach((element) => {
       this.stateCityArr3.push(element);
     });
     this.filteredList3 = this.stateCityArr3.slice();
     //
   })
  }

  getDistrict3(state)
  {
   //
   this.districtData3 = [];
   state.forEach((element) => {
     //
     this.districtData3.push({"district":element});
   });
   //
   let arr = [];
   arr = state;
  this.filteredListDistrict3 = this.districtData3.slice();
  }
  getAllBranch()
  {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      if (res.status) {        
        let length = res.data.length - 1;

        this.doctorMasterForm.controls["branchRefId"].setValue(res.data[length]._id);
        this.branchClick(res.data[length]._id,this.branchName);
        if(this.userType == 'Super Admin')
        {  
          this.apimicroservice.getBranchById(res.data[length]._id).subscribe(res => {
            this.spinner.hide();
            this.doctorMasterForm.get("branchName").setValue(res.data[length].branchName);
           });
        }
       
      }
    });
  }
  // removeValidator() { 
  //   this.doctorMasterForm.controls['prefix'].setErrors(null);
  //   this.doctorMasterForm.controls['prefix'].clearValidators();
  //   this.doctorMasterForm.controls['prefix'].updateValueAndValidity();

  //   this.doctorMasterForm.controls['firstName'].setErrors(null);
  //   this.doctorMasterForm.controls['firstName'].clearValidators();
  //   this.doctorMasterForm.controls['firstName'].updateValueAndValidity();

  //   this.doctorMasterForm.controls['lastName'].setErrors(null);
  //   this.doctorMasterForm.controls['lastName'].clearValidators();
  //   this.doctorMasterForm.controls['lastName'].updateValueAndValidity();

  //   this.doctorMasterForm.controls['dob'].setErrors(null);
  //   this.doctorMasterForm.controls['dob'].clearValidators();
  //   this.doctorMasterForm.controls['dob'].updateValueAndValidity();

  //   this.doctorMasterForm.controls['sex'].setErrors(null);
  //   this.doctorMasterForm.controls['sex'].clearValidators();
  //   this.doctorMasterForm.controls['sex'].updateValueAndValidity();

  //   this.doctorMasterForm.controls['mobile1'].setErrors(null);
  //   this.doctorMasterForm.controls['mobile1'].clearValidators();
  //   this.doctorMasterForm.controls['mobile1'].updateValueAndValidity();

  //   this.doctorMasterForm.controls['streetAddress'].setErrors(null);
  //   this.doctorMasterForm.controls['streetAddress'].clearValidators();
  //   this.doctorMasterForm.controls['streetAddress'].updateValueAndValidity();

  //   this.doctorMasterForm.controls['pincode'].setErrors(null);
  //   this.doctorMasterForm.controls['pincode'].clearValidators();
  //   this.doctorMasterForm.controls['pincode'].updateValueAndValidity();



  // }
 
  // setValidator() { 
  //   this.doctorMasterForm.controls['prefix'].setValidators(Validators.required);
  //   this.doctorMasterForm.controls['prefix'].updateValueAndValidity();

  //   this.doctorMasterForm.controls['firstName'].setValidators([Validators.required, Validators.pattern("^[a-zA-Z ]*$")]);
  //   this.doctorMasterForm.controls['firstName'].updateValueAndValidity();

  //   this.doctorMasterForm.controls['lastName'].setValidators([Validators.required, Validators.pattern("^[a-zA-Z ]*$")]);
  //   this.doctorMasterForm.controls['lastName'].updateValueAndValidity();

  //   this.doctorMasterForm.controls['dob'].setValidators(Validators.required);
  //   this.doctorMasterForm.controls['dob'].updateValueAndValidity();

  //   this.doctorMasterForm.controls['sex'].setValidators(Validators.required);
  //   this.doctorMasterForm.controls['sex'].updateValueAndValidity();

  //   this.doctorMasterForm.controls['mobile1'].setValidators([Validators.required,Validators.pattern("(0|91)?[6-9][0-9]{9}")]);
  //   this.doctorMasterForm.controls['mobile1'].updateValueAndValidity();

  //   this.doctorMasterForm.controls['streetAddress'].setValidators(Validators.required);
  //   this.doctorMasterForm.controls['streetAddress'].updateValueAndValidity();

  //   this.doctorMasterForm.controls['pincode'].setValidators([Validators.required, Validators.pattern("^[0-9]*$"),Validators.maxLength(6),Validators.minLength(6)]);
  //   this.doctorMasterForm.controls['pincode'].updateValueAndValidity();

  // }



  submitDoctor() {
   // this.setValidator();
    // if(this.userType == 'Super Admin')
    // {
    //   this.doctorMasterForm.controls['branchRefId'].setValidators(Validators.required);
    //   this.doctorMasterForm.controls['branchRefId'].updateValueAndValidity();
    // }
    // else{
    //   this.doctorMasterForm.controls['branchRefId'].setErrors(null);
    //   this.doctorMasterForm.controls['branchRefId'].clearValidators();
    //   this.doctorMasterForm.controls['branchRefId'].updateValueAndValidity();
    // }
    if (this.doctorMasterForm.invalid) {
      //this.doctorMasterForm.get("prefix").markAsTouched();
      this.doctorMasterForm.get("firstName").markAsTouched();
      this.doctorMasterForm.get("lastName").markAsTouched();
      this.doctorMasterForm.get("middleName").markAsTouched();
      this.doctorMasterForm.get("alternateName").markAsTouched();
      this.doctorMasterForm.get("dob").markAsTouched();
      this.doctorMasterForm.get("sex").markAsTouched();
      this.doctorMasterForm.get("doctorStatus").markAsTouched();
      this.doctorMasterForm.get("landline").markAsTouched();
      this.doctorMasterForm.get("mobile1").markAsTouched();
      this.doctorMasterForm.get("mobile2").markAsTouched();
      this.doctorMasterForm.get("email").markAsTouched();
      this.doctorMasterForm.get("streetAddress").markAsTouched();
      this.doctorMasterForm.get("taluka").markAsTouched();
      this.doctorMasterForm.get("district").markAsTouched();
      this.doctorMasterForm.get("state").markAsTouched();
      this.doctorMasterForm.get("country").markAsTouched();
      this.doctorMasterForm.get("pincode").markAsTouched();
      this.doctorMasterForm.get("altAddress").markAsTouched();
      this.doctorMasterForm.get("notes").markAsTouched();
      this.doctorMasterForm.get("degree").markAsTouched();
     // this.doctorMasterForm.get("branchRefId").markAsTouched();
      return;
    } else {
      this.addDoctor();
    }
  }
  update() {
    //this.setValidator();
    // if(this.userType == 'Super Admin')
    // {
    //   this.doctorMasterForm.controls['branchRefId'].setValidators(Validators.required);
    //   this.doctorMasterForm.controls['branchRefId'].updateValueAndValidity();
    // }
    // else{
    //   this.doctorMasterForm.controls['branchRefId'].setErrors(null);
    //   this.doctorMasterForm.controls['branchRefId'].clearValidators();
    //   this.doctorMasterForm.controls['branchRefId'].updateValueAndValidity();
    // }
    if (this.doctorMasterForm.invalid) {
      //this.doctorMasterForm.get("prefix").markAsTouched();
      this.doctorMasterForm.get("firstName").markAsTouched();
      this.doctorMasterForm.get("lastName").markAsTouched();
      this.doctorMasterForm.get("middleName").markAsTouched();
      this.doctorMasterForm.get("alternateName").markAsTouched();
      this.doctorMasterForm.get("dob").markAsTouched();
      this.doctorMasterForm.get("sex").markAsTouched();
      this.doctorMasterForm.get("doctorStatus").markAsTouched();
      this.doctorMasterForm.get("landline").markAsTouched();
      this.doctorMasterForm.get("mobile1").markAsTouched();
      this.doctorMasterForm.get("mobile2").markAsTouched();
      this.doctorMasterForm.get("email").markAsTouched();
      this.doctorMasterForm.get("streetAddress").markAsTouched();
      this.doctorMasterForm.get("taluka").markAsTouched();
      this.doctorMasterForm.get("district").markAsTouched();
      this.doctorMasterForm.get("state").markAsTouched();
      this.doctorMasterForm.get("country").markAsTouched();
      this.doctorMasterForm.get("pincode").markAsTouched();
      this.doctorMasterForm.get("altAddress").markAsTouched();
      this.doctorMasterForm.get("notes").markAsTouched();
      this.doctorMasterForm.get("degree").markAsTouched();
     // this.doctorMasterForm.get("branchRefId").markAsTouched();
      return;
    } else {
      this.updateDoctor();
    }
  }
  
  addDoctor() {
    this.spinner.show();
    let payload = this.doctorMasterForm.value;
    payload.photo = this.doctorImage;
    payload.clinicRefId = this.clinicRef;
    payload.branchRefId = this.branchRef;
    // if(this.userType !== 'Super Admin')
    // {
    //   payload.branchRefId = this.branchRef;
    //   payload.clinicRefId = this.clinicRef;
    //   payload.branchName = this.branchName;
    // }
    this.apimicroservice.saveDoctorsData(payload).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.apimicroservice.openSnackbar(res.message);
        this.ngOnInit();
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    },
    (ERROR:HttpErrorResponse) => {
       this.apimicroservice.openSnackbar(ERROR.error.message);
      this.spinner.hide();
  }
    
    
    ); 
  }

  fetchDoctorsList() {
    let pg = 1;
    let size = 10;
    let search='';
   
    this.spinner.show();
    this.searchText = this.doctorMasterForm.get("searchDoctor").value;
    let branchRef=sessionStorage.getItem('branchRef');
    let clinicRef=sessionStorage.getItem('clinicRef');
   
  //    if(this.userType !== 'Super Admin')
  //    {
  //   this.apimicroservice.getAllDoctors(pg, size,search,this.branchRef,this.clinicRef).subscribe(res => {
  //     this.spinner.hide();
  //     if (res.status) {
  //       //
  //       this.doctorList = res.data.data;
  //       this.totalLength = res.data.totalItems;
  //       //this.apimicroservice.openSnackbar(res.message);
  //     } else {
  //       //this.apimicroservice.openSnackbar(res.message);
  //     }
  //   }); 
  // }
  // else
  // {
    this.apimicroservice.getAllDoctors(pg, size,search,branchRef,clinicRef).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        //
        this.doctorList = res.data.data;
        this.totalLength = res.data.totalItems;
      //  this.apimicroservice.openSnackbar(res.message);
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    }); 
  //}
  } 

  prepopulation(id) {
    this.spinner.show();
    this.apimicroservice.getDoctorById(id).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        let data=res.data[0];
        this.doctorMasterForm = this.fb.group({
          "branchWiseId": new FormControl(data.branchWiseId),
          "prefix": new FormControl(data.prefix),
          "firstName": new FormControl(data.firstName, [Validators.required, Validators.pattern("^[a-zA-Z-\\s]*$")]),
          "lastName": new FormControl(data.lastName, [Validators.required, Validators.pattern("^[a-zA-Z-\\s]*$")]),
          "middleName": new FormControl(data.middleName),
          "alternateName": new FormControl(data.alternateName,Validators.pattern("^[a-zA-Z-\\s]*$")),
          "dob": new FormControl(data.dob,[Validators.required]),
          "age": new FormControl(data.age),
          "sex": new FormControl(data.sex,Validators.required),
          "doctorStatus": new FormControl(data.doctorStatus),
          "landline": new FormControl(data.landline,Validators.pattern("[0-9]{3,4}[-][0-9]{6,8}")),
          "mobile1": new FormControl(data.mobile1, [
            Validators.required,
            Validators.pattern("(0|91)?[6-9][0-9]{9}"),
          ]),
          "mobile2": new FormControl(data.mobile2,Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")),
          "email": new FormControl(data.email, [Validators.pattern("[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}")]),
          "streetAddress": new FormControl(data.streetAddress),
          "taluka": new FormControl(data.taluka),
          "district": new FormControl(data.district),
          "state": new FormControl(data.state,Validators.required),
          "pincode": new FormControl(data.pincode, [ Validators.pattern("^[0-9]*$"),Validators.maxLength(6),Validators.minLength(6),]),
          "country": new FormControl(data.country, [
            Validators.pattern("[A-Za-z _-]{3,19}$"),
          ]),
          "streetAddress2": new FormControl(data.streetAddress2),
          "taluka2": new FormControl(data.taluka2,Validators.pattern("^[a-zA-Z ]*$")),
          "district2": new FormControl(data.district2,Validators.pattern("^[a-zA-Z ]*$")),
          "state2": new FormControl(data.state2),
          "pincode2": new FormControl(data.pincode2, [ Validators.pattern("^[0-9]*$"),Validators.maxLength(6),Validators.minLength(6),]),
          "country2": new FormControl(data.country2, [
            Validators.pattern("[A-Za-z _-]{3,19}$"),
          ]),
          "altAddress": new FormControl(data.altAddress),
          "notes": new FormControl(data.notes),
          "degree": new FormControl(data.degree),
          "photo": new FormControl(data.photo),
          "updatedAt": new Date(),
          "branchRefId": new FormControl(data.branchRefId),
      "clinicRefId": new FormControl(data.clinicRefId),
      "branchName": new FormControl(data.branchName),
      
        });
        this.superBranchId = data.branchWiseId;
        this.bID=data.branchWiseId;
        if(data.altAddress == 'true' || data.altAddress == true)
        {
          this.altAddressCheck = true;
          this.isChecked = true;
        }
        else{
          this.altAddressCheck = false;
          this.isChecked = false;
          
        }
        this.doctorImage = data.photo ? data.photo : "../../assets/user2.png"
        this.did = data._id;
        this.updateButton = true;
        this.submitButton = false;
        this.cancelButton=true;
      } else {
      //  this.apimicroservice.openSnackbar(res.message);
      }
    }); 
  }
  
  addNew() {
    this.doctorMasterForm.reset();
    this.updateButton = false;
    this.deleteButton = false;
  }

  ageCalculate()
{
  let payload = this.doctorMasterForm.value;
  //
  
  if (payload.dob) {
    var timeDiff = Math.abs(Date.now() - new Date(payload.dob).getTime());
    let age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
    //
    this.doctorMasterForm.controls["age"].setValue(age);
      }
    
}


  getLastId()
  {
    // if(this.userType !== 'Super Admin'){
    //   this.apimicroservice.getLastDoctorID(this.branchRef).subscribe(res => {
    //     //   
    //     // if(res['data']['data'] == '')
    //     // {
    //     //   this.doctorMasterForm.controls['branchWiseId'].setValue('1');
    //     // }
    //     if (res['status']) {
    //       let doctorIdd = res['data']['totalItems'];
    //       this.doctorMasterForm.controls['branchWiseId'].setValue(doctorIdd + 1);
    //     } else {
    //       this.apimicroservice.openSnackbar("Something went wrong");
    //     }
       
    //   })
    // }
   // else{
      this.apimicroservice.getLastDoctorAll().subscribe(res => {
        //   
        // if(res['data']['data'] == '')
        // {
        //   this.doctorMasterForm.controls['branchWiseId'].setValue('1');
        // }
        if (res['status']) {
          let doctorIdd = res['data']['totalItems'];
          this.superBranchId = doctorIdd + 1;
          this.bID= doctorIdd + 1;
           this.doctorMasterForm.controls['branchWiseId'].setValue(doctorIdd + 1);
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
       
      });
    //}
    
  }


  updateDoctor() {
    this.spinner.show();
    let payload = this.doctorMasterForm.value;
    payload.photo = this.doctorImage;
    payload.clinicRefId = this.clinicRef;
    // if(this.userType !== 'Super Admin')
    // {
    //   payload.branchRefId = this.branchRef;
    //   payload.clinicRefId = this.clinicRef;
    //   payload.branchName = this.branchName;
    // }
    this.apimicroservice.editDoctorData(this.did,payload).subscribe(res => {
      this.spinner.hide();
      this.updateButton=false;
      this.cancelButton=false;
      this.submitButton=true;
      if (res.status) {
        this.apimicroservice.openSnackbar(res.message);
        this.ngOnInit();
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    }); 
  }

  // deleteDoctor(id){
  //   this.spinner.show();
  //   this.apimicroservice.deleteDoctorData(id).subscribe(res => {
  //     this.spinner.hide();
  //     if (res.status) {
  //       this.apimicroservice.openSnackbar(res.message);
  //       this.ngOnInit();
  //     } else {
  //       this.apimicroservice.openSnackbar(res.message);
  //     }
  //   }); 
  // }
 
  onPageChange(event) {
    this.spinner.show();
    this.page = event;
   // let p = this.page - 1;
    this.searchText = this.doctorMasterForm.get("searchDoctor").value;
    let branchRef=sessionStorage.getItem('branchRef');
    let clinicRef=sessionStorage.getItem('clinicRef');
    
    // if(this.userType !== 'Super Admin')
    // {
    //   this.apimicroservice.getAllDoctors(this.page, this.size, this.searchText,this.branchRef,this.clinicRef).subscribe(res => {
    //     this.spinner.hide();
    //     if (res.status) {
    //       this.doctorList = res.data.data;
    //       this.totalLength = res.data.totalItems;
    //     } else {
    //       //this.apimicroservice.openSnackbar(res.message);
    //     }
    //     });
    //   }
    //   else
    //   {
        this.apimicroservice.getAllDoctors(this.page, this.size, this.searchText,branchRef,clinicRef).subscribe(res => {
          this.spinner.hide();
          if (res.status) {
            this.doctorList = res.data.data;
            this.totalLength = res.data.totalItems;
          } else {
           // this.apimicroservice.openSnackbar(res.message);
          }
          });
     // }
  }
  onKeyUpEvent(event: any) {
    //
    
    this.searchText = this.doctorMasterForm.get("searchDoctor").value; 
    let branchRef=sessionStorage.getItem('branchRef');
    let clinicRef=sessionStorage.getItem('clinicRef');
    let userRef='';
    let page=1;
    let size=10;
    // if(this.userType !== 'Super Admin')
    // {
    //   this.apimicroservice.getAllDoctors(this.page, this.size, this.searchText,this.branchRef,this.clinicRef).subscribe((res) => {
    //       if(res.status){
    //         this.doctorList = res.data.data;
    //       this.totalLength = res.data.totalItems;
    //       }else{
    //         //this.apimicroservice.openSnackbar(res.message);
    //       }
    //     });
    //   }
    //   else{
        this.apimicroservice.getAllDoctors(page,size,this.searchText,branchRef,clinicRef).subscribe((res) => {
          if(res.status){
            this.doctorList = res.data.data;
          this.totalLength = res.data.totalItems;
          this.page=res.data.pageNumber;
          }else{
            //this.apimicroservice.openSnackbar(res.message);
          }
        });
      //}
  }

  cancelUpdate(){
    this.doctorImage ="../../assets/user2.png";
    this.submitButton=true;
    this.updateButton=false;
    this.cancelButton=false;
    this.ngOnInit();
    this.doctorMasterForm.reset();
  }
 
  redirect(to): void {
    this.router.navigate([to]);
  }
  changemob1() {
    let payload = this.doctorMasterForm.value;
    if (payload.mobile1 != '') {
      if (
        payload.mobile1 == payload.mobile2 ||
        payload.mobile1 == payload.mobile3 ||
        payload.mobile1 == payload.mobile4 ||
        payload.mobile1 == payload.mobile5
      ) {
        this.apimicroservice.openSnackbar("Mobile number 1 must be different");
        this.doctorMasterForm.controls["mobile1"].setValue('');
      }
    }
  }
  changemob2() {
    let payload = this.doctorMasterForm.value;
    if (payload.mobile2 != '') {
      if (
        payload.mobile2 == payload.mobile1 ||
        payload.mobile2 == payload.mobile3 ||
        payload.mobile2 == payload.mobile4 ||
        payload.mobile2 == payload.mobile5
      ) {
        this.apimicroservice.openSnackbar("Mobile number 2 must be different");
        this.doctorMasterForm.controls["mobile2"].setValue('');
      }
    }
  }
  
  onChange($event: Event) {
    const file = ($event.target as HTMLInputElement).files[0];
    const fileName = file.name;
    this.errormessage = '';
    let ext = fileName.substring(fileName.lastIndexOf(".") + 1);
    if (
      ext.toLowerCase() == "png" ||
      ext.toLowerCase() == "jpg" ||
      ext.toLowerCase() == "jpeg" ||
      ext.toLowerCase() == "csv" ||
      ext.toLowerCase() == "xlsx" ||
      ext.toLowerCase() == "pdf" ||
      ext.toLowerCase() == "docx" 
    ) { 
      this.convertToBase64(file);
    }
  }
  convertToBase64(file: File) {
    const observable = new Observable((subscriber: Subscriber<any>) => {
      this.readFile(file, subscriber);
    });
    observable.subscribe((d) => {
      this.doctorImage = d;
      
    });
  }
  readFile(file: File, subscriber: Subscriber<any>) {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      subscriber.next(fileReader.result);
      subscriber.complete();
    };
    fileReader.onerror = (error) => {
      subscriber.error(error);
      subscriber.complete();
    };
  }
  onChangeAltAddress(e){
    if(e.checked == true){
     this.altAddressCheck = true;
     this.isChecked = true;
     
    }
    else
    {
      this.altAddressCheck = false;
      this.isChecked = false;
    }
  }
  toDoctorForm() {
    document.getElementById("toDoctorForm").scrollIntoView();
  }
  toDoctorList() {
    document.getElementById("toDoctorList").scrollIntoView();
  }
  photoRecet()
  {
    this.doctorImage ="../../assets/user2.png";
  }
  fetchBranchList() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      //  
      if (res.status) {    
        this.BranchList = res.data;
        this.filteredListBranch= this.BranchList.slice();
      } else {
       //this.apimicroservice.openSnackbar(res.message);
      }
    })
  } 
  branchClick(id,branchName)
  {

    this.doctorMasterForm.controls["branchName"].setValue(branchName);

   this.apimicroservice.getBranchById(id).subscribe(res => { 
     //
       
     let data = res.data[0];
     let payload = this.doctorMasterForm.value;
      payload.clinicRefId = res.data[0].clinicRefId;
      this.doctorMasterForm.controls["clinicRefId"].setValue(res.data[0].clinicRefId);
      if(this.updateButton == false){
        this.spinner.show();
        this.apimicroservice.getLastDoctorID(payload.branchRefId).subscribe(res => {
          //   
         
          if (res['status']) {
            let doctorIdd = res['data']['totalItems'];
            this.doctorMasterForm.controls['branchWiseId'].setValue(doctorIdd + 1);
          } else {
            this.apimicroservice.openSnackbar("Something went wrong");
          }
          this.spinner.hide();
        })
      }
    });
  }
   openBranchPopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpBranchComponent,
      {
        width: "1200px",
      });
      dialogRef3.afterClosed().subscribe((result) => {
        let clinicRef= sessionStorage.getItem('clinicRef');
        this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
          //  
          if (res.status) {    
            this.BranchList = res.data;
            this.filteredListBranch= this.BranchList.slice();
            this.branchClick(res.data[0]._id,res.data[0].branchName);
            this.doctorMasterForm.get("branchRefId").setValue(res.data[0]._id)
          } else {
           //this.apimicroservice.openSnackbar(res.message);
          }
        })
        //this.fetchBranchList();
      })
  }


  WhatsApp()
  {
    let payload = this.doctorMasterForm.value;  
    let num = payload.mobile1; 
    if(num.length == 10)
    {
      sessionStorage.setItem('whatsAppNumber',payload.mobile1);
      let mobileNumber = sessionStorage.getItem('whatsAppNumber');
      window.open("https://wa.me/91"+mobileNumber);
    }
    else
    {
      this.doctorMasterForm.get("mobile1").markAsTouched();
    }
  }

  WhatsApp2()
  {
    let payload = this.doctorMasterForm.value;  
    let num = payload.mobile2; 
    if(num.length == 10)
    {
      sessionStorage.setItem('whatsAppNumber',payload.mobile2);
      let mobileNumber2 = sessionStorage.getItem('whatsAppNumber');
      window.open("https://wa.me/91"+mobileNumber2);
    }
    else
    {
      this.doctorMasterForm.get("mobile2").markAsTouched();
    }
  }
  onback() {
    this.apimicroservice.back()
  }
}