<div class="dialog-wrapper">
    <!-- <h3 mat-dialog-title>Change Password </h3> -->
    <mat-dialog-content>
      <div class="container">
        <form [formGroup]="birthdayForm" autocomplete="off" novalidate>

         
                <div class="card-body  ">
                    <div class="row">
                    <div class="col-sm-11"></div>
                    <div class="col-sm-1">
                        <div class="page-title-right row justify-content-end d-flex align-items-center float-end">
                          <a (click)="onClose()" matTooltip="Close" class="d-flex align-items-center pr-2"><span class="material-symbols-outlined pr-2">close</span></a>
                          </div>
                      </div>
                  
               
                            <div class="col-sm-4 "></div>
                            <div class="col-sm-4 ">
                                <img src="../../assets/gift.gif" >
                            </div>
                            <div class="col-sm-4 "></div>
                      
                        <div class="col-sm-12">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>DOB
                                </mat-label>
                                <input formControlName="dob" matInput [matDatepicker]="dob" id="dob"
                                placeholder="DD/MM/YYYY"
                                  readonly (dateChange)="ageCalculate()">
                                <mat-datepicker-toggle matSuffix [for]="dob">
                                </mat-datepicker-toggle>
                                <mat-datepicker #dob></mat-datepicker>
       
                              </mat-form-field>
                        </div>
                        <div class="col-sm-12">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Change DOB
                                </mat-label>
                                <input formControlName="changedob" matInput [matDatepicker]="changedob" id="changedob" [min]="minDate"
                                  [max]="currentdate" placeholder="DD/MM/YYYY" (dateChange)="ageCalculate2()">
                                <mat-datepicker-toggle matSuffix [for]="changedob">
                                </mat-datepicker-toggle>
                                <mat-datepicker #changedob></mat-datepicker>
       
                              </mat-form-field>
                        </div>
                        <div class="col-sm-6">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Patient Name </mat-label>
                            <input matInput readonly 
                            placeholder="Patient Name"
                              formControlName="patientName" />
   
                          </mat-form-field>
                      </div>
                      <div class="col-sm-6">
                        <div class="row">
                          <div class="col-sm-10">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label> Mobile 1
                              </mat-label>
                              <input matInput maxlength="10" placeholder="Mobile 1"
                                formControlName="mobile1" />
 
                            </mat-form-field>
                           
                          </div> 
                          <div class="col-sm-2 wApp mb-2">
                            <i class="bi bi-whatsapp whatsapp-icon wApp fa-lg " (click)="WhatsApp();"
                              matTooltip="WhatsApp Integration"></i>
                          </div>
                        
                        </div>
                    </div>
                        <div class="col-sm-4 p-1">
                            <button class="btn btn-sm btn-primary example-full-width" type="button"
                            (click)="updatePatient('Done')">Done</button>
                            </div>
                        <div class="col-sm-4 p-1">
                            <button class="btn btn-sm btn-primary example-full-width" type="button"
                             (click)="updatePatient('Not Answered')">Not Answered</button>
                        </div>
                        <div class="col-sm-4 p-1">
                            <button class="btn btn-sm btn-primary example-full-width" type="button"
                            (click)="updatePatient('')" >Change Birthday</button>
                        </div>
                      
                        </div>
                </div>
           
   
       
</form>


      </div>
    </mat-dialog-content>
  
  </div>
  