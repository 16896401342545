<div class="container-fluid bgcolor2" [formGroup]="usersMasterForm" >
  <div class="row">
    
    <div class="col-sm-12">
      <div class="row ">
        <div class="col-sm-1"></div>
        <div class="col-sm-11">
            <div class="row pr-3">
              
                <div class="card mt-2 divbody borderradius2 mb-3">
                  <div class="row pl-2 pr-2 pt-2">
                    <div class="col-sm-11">
                      <!-- <h1 class="fass " id="toUserForm">User Master</h1> -->
                    </div>
          
          
                    <div class="col-sm-1">
                      <!-- <div class="row justify-content-end mr-1">
                     
                        <img src="../../assets/list.png" (click)="toUserList()" matTooltip="List" width="35rem">
                      </div> -->
                    </div>
                  </div>
                  <div class="row">
                  <div class="col-sm-2 mt-0 pt-0">
                    <!-- <h1 class="titlecolor"> Patient Master</h1> -->
                        <div class="col-sm-12 bg-secondary rounded-3 p-1 mb-2 ml-2">
                         
                            
                          <div class="col-sm-12 ">
                            <img *ngIf="!patientImage" class=" img2 frame rounded-circle " src="../../assets/main.jpeg" alt="Amanda Wilson" width="500rem">
                            <img *ngIf="patientImage" class=" img2 frame rounded-circle " [src]="patientImage" alt="patient photo" width="500rem">
                            <!-- <button class="btn btn-light shadow btn-sm mb-2" type="button"><i class="ai-refresh-cw me-2"></i>Change avatar</button> -->
                            
                        </div>
                        <div class="col-sm-12 btnpossition "  >
                          <!-- <h1 class="fass p-0 m-0"> Patient Master</h1> -->
                          <input #fileUpload type="file" style="display: none;" (change)="onChange($event)" />
                          <button class="btn btn-light shadow btn-sm m-0 mb-1 example-full-width"
                           matTooltip="Upload JPG, GIF or PNG image.300 x 300 required." 
                           (click)="fileUpload.click()" ><i class="ai-refresh-cw me-2" ></i>Upload Photo</button>
                           <!-- <button *ngIf="userType !== 'Super Admin'" class="btn btn-light shadow btn-sm m-0 mb-1 example-full-width"
                          
                           ><i class="ai-refresh-cw me-2" ></i>Change Password</button> -->
                           <button type="button" class="btn btn-light shadow btn-sm m-0 mb-1 example-full-width"
                           (click)="openChangePasswordPopUp()" ><i class="ai-refresh-cw me-2" ></i>Change Password </button>
                           <!-- <a [routerLink]="" class="p-0 m-0">Upload Photo</a>   -->
                          
                        </div>
                            
                          </div>
                    </div>
                    <div class="col-sm-10">
                        <div class="row p-2">
                          <div class="col-sm-3" *ngIf="userType !== 'Super Admin'">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>User Type </mat-label> 
                              <mat-select  formControlName="userType">
                                <mat-option value="Doctor">Audiologists</mat-option>
                                <mat-option value="Clinical Staff">Clinical Staff</mat-option>
                                <mat-option value="Other">Other</mat-option>
                                </mat-select>

                              <mat-error class="margin_tp"
                              *ngIf="usersMasterForm.controls['userType'].touched && usersMasterForm.controls['userType'].errors?.required">
                              This field is mandatory.
                            </mat-error>
                            </mat-form-field>  
                          </div>
                          <div class="col-sm-3">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>User Name</mat-label>
                              <input matInput placeholder="User Name" [readonly]="userType == 'Super Admin'"
                              formControlName="userName" required />
                              <mat-error class="margin_tp"
                              *ngIf="usersMasterForm.controls['userName'].touched && usersMasterForm.controls['userName'].errors?.required">
                              This field is mandatory.
                            </mat-error>
                          
                            </mat-form-field>
                           
                          </div>
                          <div class="col-sm-3">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Email Id</mat-label>
                              <input required type="email" class="emailClass" [readonly]="userType == 'Super Admin'"
                              matInput formControlName="emailId" placeholder="email id" 
                                 />
                                 <mat-error class="margin_tp"
                                 *ngIf="usersMasterForm.controls['emailId'].touched && usersMasterForm.controls['emailId'].errors?.required">
                                 This field is mandatory.
                               </mat-error>
                               <mat-error class="margin_tp"
                               *ngIf="usersMasterForm.controls['emailId'].touched && usersMasterForm.controls['emailId'].errors?.pattern">
                               Please Enter Valid Email.
                             </mat-error>

                            </mat-form-field>
                          
                          </div>
                          <!-- <div *ngIf="isPassword || submitButton" class="col-sm-3">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Password</mat-label>
                              <input required type="password" matInput placeholder="Password" formControlName="password" required />
                              <mat-error class="margin_tp"
                              *ngIf="usersMasterForm.controls['password'].touched && usersMasterForm.controls['password'].errors?.required">
                              This field is mandatory.
                            </mat-error>
                            <mat-error class="margin_tp"
                              *ngIf="usersMasterForm.controls['password'].touched && usersMasterForm.controls['password'].errors?.pattern">
                              Please enter strong password.
                            </mat-error>
                            </mat-form-field>
                          
                          </div> -->
                        
                          <!-- <div class="col-sm-3" *ngIf="userType !== 'Super Admin'">
                            <mat-form-field
                              class="example-full-width"
                              appearance="outline"
                            >
                              <mat-label>Role</mat-label>
                             
                              <mat-select required formControlName="roleRefId" >
                              <mat-option>Select</mat-option>
                              <mat-option *ngFor="let item of rolesList" [value]="item._id"
                              >
                               {{ item.roleName }}</mat-option>
                              </mat-select>
                              <mat-error class="margin_tp"
                              *ngIf="usersMasterForm.controls['roleRefId'].touched && usersMasterForm.controls['roleRefId'].errors?.required">
                              This field is mandatory.
                            </mat-error>
                            </mat-form-field>
                            
                          </div>
                         -->
                          
                         <div   *ngIf="userType !== 'Super Admin'"  class="col-sm-3">
                          <div class="row">
                            <div class="col-sm-10 pr-1"> 
                          <mat-form-field
                            class="example-full-width"
                            appearance="outline"
                          >
                            <mat-label>Role</mat-label>
                            <mat-select #select1 (click)="fetchRoleList();" formControlName="roleRefId" >
                              <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'roleName'"
                              [array]="rolesList"  (filteredReturn)="filteredListRole =$event"></mat-select-filter>
                            <mat-option *ngFor="let item of filteredListRole" [value]="item._id"
                            >
                             {{ item.roleName }}</mat-option>
                            </mat-select>
                           
                          </mat-form-field>
                          <mat-error class="margin_tp"
                          *ngIf="usersMasterForm.controls['roleRefId'].touched && usersMasterForm.controls['roleRefId'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                        </div>
                        <div class="col-sm-2 mTopp mt-3 no-print">
                          <i (click)="openRolePopUp();" matTooltip="Add New Role" 
                          class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                             </div>
                       
                        </div>
                        </div>
                        <!-- <div *ngIf="!roleCreate" class="col-sm-3">
                          <div class="row">
                            <div class="col-sm-12 pr-1"> 
                          <mat-form-field
                            class="example-full-width"
                            appearance="outline"
                          >
                            <mat-label>Role</mat-label>
                            <mat-select #select1 (click)="fetchRoleList();" formControlName="roleRefId" >
                              <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'roleName'"
                              [array]="rolesList"  (filteredReturn)="filteredListRole =$event"></mat-select-filter>
                            <mat-option *ngFor="let item of filteredListRole" [value]="item._id"
                            >
                             {{ item.roleName }}</mat-option>
                            </mat-select>
                           
                          </mat-form-field>
                          <mat-error class="margin_tp"
                          *ngIf="usersMasterForm.controls['roleRefId'].touched && usersMasterForm.controls['roleRefId'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                        </div>
                        </div>
                        </div> -->
                         <div class="col-sm-3" *ngIf="userType !== 'Super Admin'">
                          <div class="row">
                            <div class="col-sm-10 pr-1"> 
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Branch</mat-label>
                          
                              <mat-select #select1 required formControlName="branchRefId" >
                                <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'branchName'"
                                [array]="BranchList"  (filteredReturn)="filteredListBranch =$event"></mat-select-filter>
                                <mat-option *ngFor="let item of filteredListBranch" [value]="item._id" 
                             
                                >
                                 {{ item.branchName }}</mat-option>
                                </mat-select>
                                
                                <mat-error class="margin_tp"
                                *ngIf="usersMasterForm.controls['branchRefId'].touched && usersMasterForm.controls['branchRefId'].errors?.required">
                                This field is mandatory.
                              </mat-error>
                            </mat-form-field>
                            
                            </div>
                            <div class="col-sm-2 mt-3 mTop no-print">
                              <i (click)="openBranchPopUp();" matTooltip="Add New Branch" class="blue_col mTop fa-solid fa-circle-plus
                               pl-1 fa-lg"></i>
                            </div>
                            </div>
                          </div>
                          <div class="col-sm-3">
                            <mat-form-field
                              class="example-full-width"
                              appearance="outline"
                            >
                              <mat-label> Status</mat-label>
                           
                              <mat-select [disabled]="userType == 'Super Admin'" required formControlName="status">
                                  <mat-option value="Active">Active</mat-option>
                                  <mat-option value="In-active">In-active</mat-option>
                                  </mat-select>
                                  <mat-error class="margin_tp"
                                  *ngIf="usersMasterForm.controls['status'].touched && usersMasterForm.controls['status'].errors?.required">
                                  This field is mandatory.
                                </mat-error>
                            </mat-form-field>
                          
                          </div>
                    
                       <div class="col-sm-10" *ngIf="submitButton"></div>
                       <div class="col-sm-8" *ngIf="updateButton"></div>
                        <div class="col-sm-2 marg_t"> 
                          <button type="button" class="btn btn-primary btn-sm example-full-width" *ngIf="updateButton && permissionUpdate"
                          (click)="updateUser()">Update</button>
                          <button type="button" class="btn btn-primary btn-sm example-full-width" *ngIf="submitButton && permissionCreate"
                          (click)="submitUser()">Save </button>
                        </div>
                        <div class="col-sm-2 marg_t " *ngIf="cancelButton">
                          <button class="btn btn-primary btn-sm  example-full-width" type="reset" (click)="cancelUpdate()">Cancel</button>
                       </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
     
</div>
  </div>
</div>
