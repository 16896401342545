<div class="container-fluid bgcolor2" >
    <form  autocomplete="off" novalidate>
        <div class="row">
            <div class="col-sm-12">
                        <div class="row col-sm-12">
                            <div class="col-sm-4"></div>
                            <div class="col-sm-7">
                                <h1 class="pt-2 pl-0">Billing Menu</h1>
                                <!-- <h3 class="col-sm-12">Stock Menu</h3> -->
                            </div>
                            <div class="col-sm-1 justify-content-end">
                                  <a (click)="onClose()" matTooltip="Close" class="d-flex align-items-center mt-2"><span class="material-symbols-outlined">close</span></a>
                              </div>
                      </div>
                      <div class="row col-sm-12">
                      <div class="icons-wraper dashboard-boxes col-sm-12">
                        <ul *ngIf="way !== 2" class=" row rowStyle">
                            <li class=" col-sm-6">
                                <a class="icon-card p-0 col-sm-12"
                                (click)="openAdvancePopUp()">
                                    <div class="icon-box">
                                        <i class="fa-solid fa-money-bills icon-img"></i>
                                        <img src="../../assets/icon-bg8.svg" alt="" class=" icon-bg">
                                     </div>
                                     <h4 class="">Advance Amount</h4>
                                </a>
                          </li>
                            <li class=" col-sm-6">
                                <a class="icon-card p-0 col-sm-12"
                                (click)="openBillingPopUp()">
                                   <div class="icon-box">
                                      <i class="bi bi-receipt-cutoff icon-img"></i>
                                      <img src="../../assets/icon-bg7.svg" alt="" class="icon-bg">
                                   </div>
                                   <h4 class="">Summary</h4>
                                </a>
                          </li>
                       
                </ul>
                <ul class=" row rowStyle">
                   
                  <li class=" col-sm-6">
                    <a class="icon-card p-0 col-sm-12"
                    (click)="openServicePopUp()">
                        <div class="icon-box">
                            <i class="fa fa-money icon-img" aria-hidden="true"></i>
                            <img src="../../assets/icon-bg5.svg" alt="" class="icon-bg">
                          
                         </div> 
                         <h4 class="">Service Bill</h4>
                    </a>
              </li> 
                <li class=" col-sm-6"> 
                    <a class="icon-card p-0 col-sm-12"
                    (click)="openDevicePopUp()">
                       <div class="icon-box">
                          <i class="fa-solid fa-ear-listen icon-img"></i>
                          <img src="../../assets/icon-bg8.svg" alt="" class=" icon-bg">
                       </div>
                       <h4 class="">HA Bill</h4>
                    </a>
              </li>
              
        </ul>
            </div>
            </div>
  </div>
  </div>
    </form>
</div>
