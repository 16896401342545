<div class="container-fluid " [formGroup]="roleMasterForm" >
  <form [formGroup]="roleMasterForm" autocomplete="off" novalidate>
    <div class="row">
      <div class="col-sm-12 p-0 ">
        <div class="row">
          <div class="col-sm-10">
            <div class="row ">

              <!-- <span><img src="../../assets/role.png" width="30rem"> </span>  -->
              <span>    <h1 class="fass ml-3 pt-1" id="toRoleForm">Add Role</h1> </span>
            </div>
            
              </div>
              <div class="col-sm-2">
                <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                  <a (click)="onClose()" matTooltip="Close" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">close</span></a>
                  </div>
              </div>
             
        </div>
        <div class="card pr-1 pt-4 mb-2 pl-1" >
          
                          <div class="row p-2">
                            <div class="col-sm-4 mt-3 ">
                              <!-- <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Role Id</mat-label>
                                <input matInput formControlName="roleId" readonly  />
                               
                              </mat-form-field> -->
                              <mat-label class="center" formControlName="roleId" ngDefaultControl >{{bId}}</mat-label>
                            </div>
                         
                            <div class="col-sm-8 mt-1">
                              <mat-form-field
                                class="example-full-width"
                                appearance="outline"
                              >
                                <mat-label>Role Name
                                  <!-- <span class="required-asterisk">*</span> -->
                                </mat-label>
                              
                                <input required matInput  formControlName="roleName" placeholder="Enter Role Name">
                                
                              </mat-form-field>
                              <mat-error class="margin_tp"
                                *ngIf="roleMasterForm.controls['roleName'].touched && roleMasterForm.controls['roleName'].errors?.required">
                                This field is mandatory.
                              </mat-error>
                              <mat-error class="margin_tp"
                              *ngIf="roleMasterForm.controls['roleName'].touched && roleMasterForm.controls['roleName'].errors?.pattern">
                                  Please enter valid Role.
                            </mat-error>
                            </div>
                           
                      <!-- <div class="col-sm-2" *ngIf="updateButton"></div> -->
                   
                      <div class="col-sm-4 mb-2 mt-2" *ngIf="submitButton"></div>
                          <div class="col-sm-4 mb-2 mt-3" *ngIf="submitButton && roleCreate">
                            <button type="button" class="btn btn-sm btn-primary example-full-width" (click)="saveRole()">Save </button>
                        
                          </div>
                          <div class="col-sm-4 mb-2 mt-2" *ngIf="submitButton"></div>
                          <div  class="col-sm-4 mb-2 mt-2" *ngIf="updateButton && roleUpdate">
                            <button type="button" *ngIf="updateButton" class="btn btn-sm btn-primary example-full-width" (click)="update()">Update </button>
                          </div>
                          <div class="col-sm-4 mb-2 mt-2 " *ngIf="cancelButton">
                            <button class="btn btn-primary btn-sm  example-full-width" type="reset" (click)="cancelUpdate()">Cancel </button>
                         </div>
                         <div class="col-sm-2" *ngIf="updateButton"></div>
                          </div>
                  
        </div>
        <!-- <div class="card pr-4 pt-1 pl-4" >
              
          <div class="col-sm-12">
            <div class="row">
                <div class="col-sm-8 pl-1 pr-1">
                    <h3 class="titlecolor"> Role List</h3>  
                </div>
                <div class="col-sm-4 mb-2">
                    <div class="input-group md-form form-sm form-1 pl-0">
                      <div class="input-group-prepend">
                        <span class="input-group-text purple lighten-3" id="basic-text1"><i class="fas fa-search text-white"
                            aria-hidden="true"></i></span>
                      </div>
                      <input class="form-control my-0 py-1" type="text" placeholder="Search" formControlName="searchRole" aria-label="Search" (change)="onKeyUpEvent($event)">
                    </div>
                </div>
                <div class="col-sm-12">
                    <table id="toRoleList" 
                    class="table table-hover table-striped table-bordered">
                        <thead>
                          <tr class="text-center">
                            <th>Role Id</th>
                            <th>Role Name</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="
                            let item of rolesList
                            | paginate : { id:'role_pagination', 
                            itemsPerPage: size, currentPage: page, totalItems:totalLength }
                          " (click)="prepopulation(item._id)"
                     
                          style="cursor:pointer; text-align: center ">
                          <td matTooltip="click to view">
                            {{item.roleId}}
                          </td>
                          <td matTooltip="click to view">
                            {{item.roleName}}
                          </td>
                          
                        </tbody>
                        </table>
                </div>
                <div class="col-sm-12 pt-0 text-right">
                  <pagination-controls id="role_pagination" directionLinks="true"
                   (pageChange)="onPageChange($event)" class="float-right my-pagination">
                  </pagination-controls>
                </div>
            </div>
        </div>
        </div> -->
      
  </div>
    </div>
    </form>
  </div>
  