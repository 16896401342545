import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-pop-up-item',
  templateUrl: './pop-up-item.component.html',
  styleUrls: ['./pop-up-item.component.scss']
})
export class PopUpItemComponent implements OnInit {
  popupItemForm: FormGroup;
  // itemId: any;
   serialNoList =[];
   disableSubmit = false;
   constructor(private fb: FormBuilder,
     public dialogRef: MatDialogRef<PopUpItemComponent>,@Inject(MAT_DIALOG_DATA) public data:any) {
       this.serialNoList = data.serialValue;
       dialogRef.disableClose = true;}
 
   ngOnInit(): void {
     //this.itemId=this.data.id;
     this.disableSubmit = false;
     
     //
     this.popupItemForm = this.fb.group({  
       "device": this.fb.array([]),
     });
     
     if(this.serialNoList.length){
      this.prepopulation();
       
     }else{
      this.addNew();
     }
   }
 
   addNew() {
     const creds = this.popupItemForm.controls.device as FormArray;
         creds.push(
         this.fb.group({
           "item": new FormControl('',[Validators.required,Validators.pattern("^[a-zA-Z0-9 -]+$")]),   
         //toggle: false
 
 })
 );
   }
 
   prepopulation(){
     this.serialNoList.forEach(element => {    
       const tmpDict = {};
 
       tmpDict['item'] = new FormControl(element,Validators.required);
       
       const creds = this.popupItemForm.controls.device as FormArray;
        creds.push(new FormGroup(tmpDict));
        //
       });
   }
 
   sumbitSerialNo(){
     const creds = this.popupItemForm.controls.device as FormArray;  
     if(creds.invalid)
     {
       creds.controls.forEach(element =>{
         element.get('item').markAsTouched();
       });
          
     }
     else{
      this.sumbitNumbers();
     }
   }
 
   sumbitNumbers(){
    const tempData = this.popupItemForm.controls.device.value;
    ////
    this.dialogRef.close({data:tempData});
   //  if(tempData.length == 1){
   //    if(tempData.item == ""){
   //     this.dialogRef.close();
   //    }else{
   //     this.dialogRef.close({data:tempData});
   //    }
   //  }else{
   //   this.dialogRef.close({data:tempData});
   //  }
    
   }
   deleteRow(index){
     (<FormArray>this.popupItemForm.get("device")).removeAt(index);
   }
   findDuplicate(srno,p):boolean{
     const creds = this.popupItemForm.controls.device as FormArray;
     let myArr = creds.controls;
     let test = myArr.filter(data => data['controls']['item'].value == srno && srno !== null)
 
     if(test.length > 1){
       this.disableSubmit = true;
       return true;
     }else{
       this.disableSubmit = false;
       return false;
     }
 
   }
   onClose(): void {
     this.dialogRef.close();
   }
 
 }
 