import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-pdfviewer',
  templateUrl: './pdfviewer.component.html',
  styleUrls: ['./pdfviewer.component.scss']
})
export class PdfviewerComponent implements OnInit {
  src
  constructor(private dialogRef: MatDialogRef<PdfviewerComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.src = data
  }
  isImage = false;
  ngOnInit(): void {
    this.src = this.data
    
    if (this.src.startsWith('data:image')) {
      this.isImage = true;
    }
    else {
      this.isImage = false;
    }
    
  } 
  dowload()
  {
    const link=document.createElement("a");
     link.href=this.src;
     link.download='Download';
     link.click()
  }
}