<div class="dialog-wrapper">
    <!-- <h3 mat-dialog-title>Change Password </h3> -->
      <div class="container">
        <form [formGroup]="popupChangeDeviceForm" autocomplete="off" novalidate>
        <div class="row">
          <div class="col-sm-12">
            
              <div class="card pr-4 pt-4 mb-2 pl-4" >
                    <div class="row">
                    <div class="col-sm-10">
                      <p class="text-muted">Change Of Device</p>
                        
                    </div>
                    <div class="col-sm-2 ">
                      <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                        <a (click)="onClose()" matTooltip="Close" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">close</span></a>
                        </div>
                    </div>
                 
              </div>
                  <div class="col-sm-12">
                    <div class="row">
                        
                  <!-- <div class="col-sm-6">
                    <label class="pt-3 lable_style">Model No. :</label>
                    </div> -->
          <div class="col-sm-12 pl-1 pr-1">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Model Name</mat-label>
              <input matInput placeholder="Model Name" readonly formControlName="modelName" />
              
             </mat-form-field>
      
           <!-- <mat-error class="margin_tp"
             *ngIf="popupChangeDeviceForm.controls['modelNo'].touched && popupChangeDeviceForm.controls['modelNo'].errors?.pattern">
             Enter valid brand name.
           </mat-error> -->
          </div>
          <div class="col-sm-12 pl-1 pr-1">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Original Serial No</mat-label>
              <input matInput placeholder="Serial No" readonly formControlName="originalSerialNo" />
             </mat-form-field>

          </div>
          <div class="col-sm-12 pl-1 pr-1">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>New Serial No</mat-label>
              <input required matInput placeholder="Serial No" formControlName="serialNo" />
             </mat-form-field>
             <mat-error class="margin_tp"
             *ngIf="popupChangeDeviceForm.controls['serialNo'].touched && popupChangeDeviceForm.controls['serialNo'].errors?.required">
             This field is mandatory.
           </mat-error>
          </div>
      
                </div>
                <div class="row mb-2">
                    <div *ngIf="submitButton" class="col-sm-3"></div>
                  
                <div *ngIf="submitButton" class="col-sm-6">
                    <button class="btn btn-primary btn-sm example-full-width" type="button" (click)="save()" >Save</button>
                  </div>
                  <div *ngIf="updateButton" class="col-sm-6">
                    <button class="btn btn-primary btn-sm example-full-width" type="button" >Update</button>
                  </div>
                  <div *ngIf="updateButton" class="col-sm-6">
                    <button class="btn btn-primary btn-sm example-full-width" type="reset" (click)="cancelUpdate()" >Cancle</button>
                  </div>
                  <div *ngIf="submitButton" class="col-sm-3"></div>
                 
                </div>
              </div> 
            </div>  
              <!-- <div class="card pr-3 pt-4 pl-3" >
              
                                  <div class="row">
                                    <div class="col-sm-5">
                                        <h3 class="titlecolor"> Brand List</h3>  
                                    </div>
                                    <div class="col-sm-7 mb-2">
                                        <div class="input-group md-form form-sm form-1 pl-0">
                                          <div class="input-group-prepend">
                                            <span class="input-group-text purple lighten-3" id="basic-text1"><i class="fas fa-search text-white"
                                                aria-hidden="true"></i></span>
                                          </div>
                                          <input class="form-control my-0  py-1" type="text"
                                           placeholder="Search" formControlName="searchBrand"
                                           (keyup)="onKeyUpEvent($event)" aria-label="Search">
                                        </div>
                                    </div>
                                    <div class="col-sm-12 table-responsive">
                                      <table id="toDeviceList" 
                                   class="table table-bordered data-table-custom">
                                          <thead>
                                            <tr class="text-center">
                                              <th>Brand Name</th>
                                               </tr>
                                          </thead>
                                          <tbody >
                                            
                                            <tr  *ngFor="let data of listOfBrand | paginate : { id:'brandMaster',
                                            itemsPerPage: size, currentPage: page, totalItems:totalLength }"
                                         (click)="prepopulation(data._id)" 
                                          style="cursor:pointer; text-align: center ">
                                              <td matTooltip="click to view">{{data.brandName}}</td>
                                         
                                            </tr>
                                            
                                            
                                          </tbody>
                                        </table>
                                      </div>
                                      <div class="col-sm-12 pt-0 text-right">
                                        <pagination-controls id="brandMaster" directionLinks="true"  
                                        (pageChange)="onPageChange($event)" class="float-right pagination my-pagination"></pagination-controls>
                                      </div>
                                  </div>
                                </div> -->
          
          </div>
      
        </div>
</form>


      </div>
  
   
  </div>