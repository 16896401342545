<div class="container-fluid bgcolor2" [formGroup]="BillingForm">

  <div class="row">
      <div class="col-sm-1">

      </div>
      <div class="col-sm-11">
        <div class="row pl-0 ">

          <div class="col-12 pl-0 ">
            <div class="row ">
            <div class="col-sm-9">    
                <h1 class="pt-2 pl-0" id="toBillingForm">Billing Record</h1>
                </div>
                <div class="col-sm-3 mt-1">
                  <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                    <a (click)="onback()" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">arrow_back</span>Back</a>
                    <a (click)="printForm()" class="d-flex align-items-center ms-3 pr-2 mt-2"><span class="material-symbols-outlined pr-2">print</span>Print</a>
                    <a (click)="toBillingLst()" class="d-flex align-items-center ms-3 pr-2 mt-2"><span class="material-symbols-outlined pr-2">reorder</span>List</a>
          </div>
          </div>
        </div>
       </div> 
       </div>

          <!-- <div class="row">
              <div class="col-sm-9">
                  <div class="row ">

                      <span><img src="../../assets/bill.png" width="35rem"> </span> 
                      <span> <h1 class="fass ml-3 pt-1" id="toBillingForm">Billing Record</h1></span>
                    </div>
                 
                  </div>
                  <div class="col-sm-3" >
               
                      <div class="row justify-content-end ">
                        <img matTooltip="Back" class="mb-2 pb-2 mr-2" width=35rem src="../../assets/back.png" (click)="onback()">
                        <img matTooltip="Print Bill" class="mb-2 pb-2 mr-2"  (click)="printForm()" width="35rem" src="../../assets/print.png">  
                        <img src="../../assets/list.png"  (click)="toBillingLst()" class="mb-2 pb-2" matTooltip="List" width="35rem">
                     </div>
                     </div>
                 
            </div> -->
            <div class="col-sm-12 pl-0">
              <div class="row">
                  <div class="card divbody borderradius p-3 pb-0">
     
                      
                    
                        <div class="row mb-3">
                     
                     <div class="col-sm-2 justify-content-end" >
                      <label class="lable_style pt-2 header justify-content-end">Bill No :</label>
                      
                    </div>
                    <div class="col-sm-4 marginLeft">
                      <label class="labelStyle justify-content-left">{{billNo}}</label>
                    </div>
                    <div class="col-sm-4"></div>
                    <div class="col-sm-1">
                        <!-- <label class="justify-content-right  ml-4 labelStyle">Date : </label> -->
                        <label class="lable_style pt-2 header justify-content-end">Date :</label>
                      
                    </div>
                    <div class="col-sm-1">
                      <label class="labelStyle justify-content-left"> {{currentdate | date: 'dd/MM/yyyy'}}</label>
                    </div>
                    <div class="col-sm-2 justify-content-end" >
                      <label class="lable_style pt-2 header justify-content-end">Patient Name :</label>
                      
                    </div>
                    <div class="col-sm-4 marginLeft">
                      <label class="labelStyle justify-content-left">{{patientName}}</label>
                    </div>
                    <div class="col-sm-6"></div>
                    <!-- <div class="col-sm-8"></div>
                    <div class="col-sm-2 justify-content-end" >
                      <label class="lable_style pt-2 mt-1 header justify-content-end">Please enter paid amount :</label>
                      
                    </div>
                    <div class="col-sm-1 marginLeft"> 
                      <mat-form-field class="example-full-width lable_style2" appearance="outline">
                        <mat-label class="lable_style2">Paid amount</mat-label>
                        <input matInput placeholder="Paid" #d maxlength="8" (input)="test();" (keydown)="eventHandler($event)"
                         formControlName="totalCal"  />
                      </mat-form-field>
                      <mat-error 
                      *ngIf="BillingForm.controls['totalCal'].touched && BillingForm.controls['totalCal'].errors?.pattern">
                      Please enter valid amount.
                    </mat-error>
                    <mat-error 
                    *ngIf="BillingForm.controls['totalCal'].touched && BillingForm.controls['totalCal'].errors?.required">
                    This field is mandatory.
                  </mat-error>
                    </div> 
                    <div class="col-sm-1">
                    <button (click)="totalCalculation()" class="btn btn-primary btn-sm mt-2" >Apply</button>
                   </div> -->
                    <!-- <div class="col-sm-2 justify-content-end mt-2" >
                      <label class="lable_style pt-2 header justify-content-end">Patient Name :</label>
                    </div>
                    <div class="col-sm-4 marginLeft mt-2">
                       
                        <div class="col-sm-11">
                          <label class="labelStyle justify-content-left pl">{{patientName}}</label>
                        </div>
                       
                    </div> --> 
                    </div>
                    <div class="row">
                      
                                <div class="col-sm-12 table-responsive">
                                  <form [formGroup]="BillingForm" autocomplete="off" novalidate>
                                  <table id="toBillingLst" 
                               class="table table-bordered table-responsive
                                data-table-custom"> 
                                          <thead>
                                              <tr class="text-center pt-1 pb-1"> 
                                                  <th class=" pt-1 pb-1 pr-2 pl-2">Sr.No.</th>
                                                   <th class=" pt-1 pb-1">Item / Service Name</th>
                                                   <th class=" pt-1 pb-1">Ear</th>
                                                   <th class="pt-1 pb-1">Warranty Period<br><small>(in no. of years)</small></th>
                                                  <th class=" pt-1 pb-1">HSN/SAC</th>
                                                  <th class=" pt-1 pb-1 pr-2 pl-2">Quantity</th>
                                                  <th class=" pt-1 pb-1">Rate</th>
                                                  <th class=" pt-1 pb-1">Other</th>
                                                  <th class=" pt-1 pb-1">Total Amount</th> 
                                                  <th class=" pt-1 pb-1">Paid Amount</th>
                                         
                                                  <th [hidden]="true" class="pt-1 pb-1">UnPaid Amount</th>
                                                  <th class=" pt-1 pb-1">Status</th>
                                                  <!-- <th class="width3 pt-1 pb-1">
                                                      <div class="row ">
                                                          
                                                          <div class="col-sm-9 pr-0">
                                                              Status
                                                              </div>
                                                              <div class="col-sm-1 pl-0">
                                                                  
                                                                  <mat-checkbox class="example-full-width mt-1" formControlName="selectAllService" (change)="selectAllService($event)"> 
                                                          
                                                                  </mat-checkbox>
                                                              </div>
                                                             
                                                      </div>
                                                      
                                                     
                                                  </th> -->
                                        
                                              </tr> 
                                          </thead>  
                                          <tbody formArrayName="serviceInfo"> 
                                              <tr [formGroupName]="i"
                                              *ngFor="let row of BillingForm.get('serviceInfo')['controls']; let i = index; let last = last" role="row"
                                              class="odd text-center">
                                               <td class=" pl-3 pr-3 mt-2 pb-0 text-center"> 
                                                <label class="example-full-width">{{i + 1}}</label></td>
                                              
                                             <td  class="width1 width3 mt-2 ">{{row.controls.serviceName.value}} {{row.controls.serialNumber.value ? dashVal : serialVal}} {{row.controls.serialNumber ? row.controls.serialNumber.value : serialVal}}</td>
                                             <td class="pt-1 pr-3 mr-0  pl-3"> 
                                              <mat-form-field class="example-full-width width3 pr-0 mr-0 lable_style2" appearance="outline">
                                                <mat-label>Ear</mat-label> 
                                                    <mat-select #select1 placeholder="Ear" class="colorblack" 
                                                    formControlName="earStyle"> 
                                                    <mat-option value='left'>Left</mat-option>
                                                    <mat-option value='right'>Right</mat-option>
                                               </mat-select>
 <mat-error class="margin_tp"
                                                *ngIf="row.controls.earStyle.touched && row.controls.earStyle.errors?.required">
                                                   This Field is Mandatory
                                              </mat-error>
                                         
                                              </mat-form-field>
                                        

                                          
                                          </td>

                                             <td class=" widthNew pt-1 pr-3 mr-0  pl-3"> 
                                              <mat-form-field class="example-full-width widthNew  pr-0 mr-0 lable_style2" appearance="outline">
                                                <mat-label>Warranty Period
                                                  <!-- <span class="required-asterisk">*</span> -->
                                                </mat-label>
                                                    <mat-select #select1 placeholder="Warranty Period" class="colorblack" 
                                                    formControlName="warrantyPeriod">
                              <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'name'"
                               [array]="yearData"
                                (filteredReturn)="filteredListWarrenty =$event"></mat-select-filter>
                              <mat-option (click)="calculateExpiry(i)"
                              *ngFor="let item of filteredListWarrenty" [value]="item.value">
                                {{item.name}}
                              </mat-option>
                            </mat-select>

                                                <mat-error class="margin_tp mt-3"
                                                *ngIf="row.controls.warrantyPeriod.touched && row.controls.warrantyPeriod.errors?.required">
                                                   This Field is Mandatory
                                              </mat-error>
                                         
                                              </mat-form-field>
                                        

                                              <!-- <mat-error
                                              *ngIf="row.controls.warrantyPeriod.touched && row.controls.warrantyPeriod.errors?.required">
                                              This field is mandatory.
                                            </mat-error> -->
                                              <!-- <mat-error
                                    *ngIf="row.controls.warrantyPeriod.touched && row.controls.warrantyPeriod.errors?.pattern">
                                    Please enter period in number only.
                                  </mat-error>  -->
                                  <mat-form-field *ngIf="showWarranty == true" class="example-full-width widthNew  pr-0 mr-0 lable_style2" appearance="outline">
                                                <mat-label class="lable_style2">Warranty Period</mat-label>
                                            <input maxlength="1" placeholder="Warranty Period" formControlName="warrantyPeriodTxt" 
                                            matInput type="text" (change)="calculateExpTxt($event,i)"/>
                                           
                                          </mat-form-field>
                                          <mat-error class="margin_tp" *ngIf="row.controls.warrantyPeriodTxt.touched && row.controls.warrantyPeriodTxt.errors?.pattern">
                                            Please enter period in number only.
                                          </mat-error>
                                          
                                          </td>
                                             <td class="mt-2 pb-0">{{row.controls.hasSac.value}}</td>
                                             <td class="mt-2 pb-0 pr-0 pl-0 ">
                                             {{row.controls.quantity.value}}
                                              </td>
                                                  <td class="mt-2 pb-0">{{row.controls.rate.value}}</td>
                                                  <td class="mt-2 pb-0">{{row.controls.discount.value?row.controls.discount.value:0}}</td>
                                                  <td class="mt-2 pb-0">{{row.controls.totalServiceAmount.value  | number : '1.2-2'}}</td>
                                                  <!-- <td class="mt-2 pb-0">{{row.controls.servicePaidAmount.value}}</td> -->
                                                  <td class="mt-2 pb-0"> {{row.controls.subValue.value}} </td>
                                                    <td [hidden]="true" class=" pt-1 pr-0 mt-2 pb-0 mr-0 tdW2"> 
                                                 
                                                      <input  type='text' readonly class="inputClass example-full-width tdW2  pr-0 mr-0 lable_style2"
                                                       placeholder="Amount" formControlName="serviceAdvanceAmount">
                                                  </td>
                                                 
                                                    <td class="labelStyle" matTooltip="Click To View" >
                                                      <label *ngIf="row.controls.totalServiceAmount.value === row.controls.subValue.value; else elseBlock">Paid</label>
                                                 
                                                    <ng-template #elseBlock>
                                                      <label>Unpaid</label>
                                                    </ng-template> 
 
                                                      </td>
                                                  <!-- <td class="mt-2 pb-0" *ngIf="row.controls.totalServiceAmount.value === row.controls.subValue.value"> 
                                                     
                                                       Paid
                                                  </td>
                                                  <td class="mt-2 pb-0" *ngIf="row.controls.totalServiceAmount.value !== row.controls.subValue.value"> 
                                                    Unpaid
                                                  </td>
                                                  <td class="mt-2 pb-0" *ngIf="row.controls.status.value === 'postdated'"> 
                                                    Cheque Postdated
                                                  </td>
                                                  <td class="mt-2 pb-0" *ngIf="row.controls.status.value === 'deposited'"> 
                                                    Cheque Deposited
                                                  </td>
                                                  <td class="mt-2 pb-0" *ngIf="row.controls.status.value === 'clear'"> 
                                                    Cheque Clear
                                                  </td>
                                                  <td class="mt-2 pb-0" *ngIf="row.controls.status.value === 'bounce'"> 
                                                    {{row.controls.comment.value}}
                                                  </td> -->
                                              
                                                 </tr>
                                                </tbody>
                                                <tbody>
                                                 <!-- <tr>
                                                  <td colspan="8" class="width3 mt-2 pb-0" > 
                                               
                                                    </td>
                                                  <td class="width3 mt-2 pb-0" > 
                                                    Total 
                                                    </td>

                                                       <td class="pt-1 pr-0 mr-0 tdW2 pl-3" > 
                                                    <mat-form-field class="example-full-width tdW2  pr-0 mr-0 lable_style2" appearance="outline">
                                                      <mat-label class="lable_style2">Total</mat-label>
                                                      <input matInput placeholder="Total"
                                                       (change)="totalCalculation()" formControlName="totalCal"  />
                                                    </mat-form-field>

                                                    </td>
                                                    <td class="width3 mt-2 pb-0" > 
                                                  
                                                      </td>
                                                 </tr> -->
                                                </tbody>

                                        
                                          <thead>
                                          
                                              <tr >
                                                 
                                            
                                                <th colspan="8" rowspan="10"> 
                                                  <div  class="row mt-0 pt-0">
                                                  
                                                    <div *ngIf="showMsg" class="col-sm-12 blue_col mt-0 pt-0 row">
                                                      <div class="col-sm-6 transLabel">
                                                        Advance receipt of Rs.{{chequeAmt}} available for this patient. Do you want to add to current bill :
                                                        </div> 

                                                    <div class="col-sm-2 ">
                                                      <button class="btn mt-0 btn-primary btn-sm example-full-width" type="button"
                                                      (click)="advanceCopy('Yes')">Yes</button>
                                                    </div> 
                                                    <div class="col-sm-2">
                                                      <button class="btn mt-0 btn-primary btn-sm example-full-width" type="button"
                                                      (click)="advanceCopyNo()">No</button>
                                                    </div>
                                                    <div class="col-sm-2"></div> 
                                                  
                                                    <div class="col-sm-12 bcol">
                                                     ------------------------------------------------------------------------------------------------------------------------
                                                    </div>
                                                    
                                                   </div>
                                                   <div *ngIf="showMsg1" class="col-sm-12 blue_col mt-0 pt-0 row">
                                                    <div class="col-sm-6 transLabel">
                                                      Advance receipt of Rs.{{chequeAmt}} added to the bill. Do you want to reward back :
                                                      </div> 
                                                      
                                                  <div class="col-sm-2 ">
                                                    <button class="btn mt-0 btn-primary btn-sm example-full-width" type="button"
                                                    (click)="advanceCopy('No')">Reward back</button>
                                                  </div> 
                                                  <!-- <div class="col-sm-2">
                                                    <button class="btn mt-0 btn-primary btn-sm example-full-width" type="button"
                                                    (click)="advanceCopy('Yes')">No</button>
                                                  </div> -->
                                                  <div class="col-sm-2"></div> 
                                                
                                                  <div class="col-sm-12 bcol">
                                                   ------------------------------------------------------------------------------------------------------------------------
                                                  </div>
                                                  
                                                 </div>
                                             
                                                    <div  class="col-sm-12 row">
                                                  
                                                      <div class="col-sm-8 mr-0 mb-2 pr-0">
                                                   
                                                        Total Rupees in words : 
                                                        {{totalAmountInWords}}

                                                      </div>
                                                    
                                                      <div class="col-sm-4"></div>
                                                    </div> 
                                                    <br><br>
                                              <div  class="col-sm-3">
                                                <mat-form-field class="lable_style header mTop" appearance="outline">
                                                  <mat-label>Mode Of Payment
                                                    <!-- <span class="required-asterisk">*</span> -->
                                                  </mat-label>
                    
                                                  <mat-select [disabled]="readonly" placeholder="Payment Mode" class="colorblack" 
                                                  formControlName="modeOfPayment" >
                                               <mat-option (click)="ModeOfPaymentClick('Cash')" value="Cash">Cash</mat-option>
                                               <mat-option (click)="ModeOfPaymentClick('Card')" value="Card">Card</mat-option>
                                               <mat-option (click)="ModeOfPaymentClick('UPI')" value="UPI">UPI</mat-option>
                                               <mat-option (click)="ModeOfPaymentClick('Cheque')" value="Cheque">Cheque</mat-option>
                                               <mat-option (click)="ModeOfPaymentClick('RTGS/NEFT/IMPS')" value="RTGS/NEFT/IMPS">RTGS/NEFT/IMPS</mat-option>
                                                  </mat-select>  
                                                  
                    
                                                </mat-form-field>
                                                
                                                <mat-error
                                      *ngIf="BillingForm.controls['modeOfPayment'].touched && BillingForm.controls['modeOfPayment'].errors?.required">
                                      This field is mandatory.
                                    </mat-error>
                                                <!-- <label class="lable_style pt-2 header justify-content-left">Add Cheque Details :</label> -->
                                               
                                            </div>
                                           
                                   
                                          
                                            </div>
                                           </th>
                                           
                                    
                                                <th *ngIf="totalAmount - totalAmtPaidTillDate - advanceAmt !== 0" colspan="3" class="text-left pt-1 pb-1 pr-0 pl-0">
                                                  <div class="row col-sm-12 pr-0">
                                                    <div class="col-sm-5 mt-3">
                                                       enter paid amount:
                                                      </div>
                                                      <div class="col-sm-4 pr-2">
                                                        <mat-form-field class="example-full-width widthQty lable_style2" appearance="outline">
                                                          <mat-label  class="lable_style2">Paid amount</mat-label>
                                                          <input [disabled]="isAdvance" matInput placeholder="Paid" maxlength="8" 
                                                           formControlName="totalCal" [readonly]="isAdvance" />
                                                        </mat-form-field>
                                                        <!-- (input)="test();" (keydown)="eventHandler($event)" -->
                                                        <mat-error 
                                                        *ngIf="BillingForm.controls['totalCal'].touched && BillingForm.controls['totalCal'].errors?.pattern">
                                                        Please enter valid amount.
                                                      </mat-error>
                                                      <!-- <mat-error 
                                                      *ngIf="BillingForm.controls['totalCal'].touched && BillingForm.controls['totalCal'].errors?.required">
                                                      This field is mandatory.
                                                    </mat-error> -->
                                                        </div>
                                                        <div class="col-sm-3 pr-0">
                                                          <button (click)="totalCalculation()"
                                                           [disabled]="isAdvance" 
                                                          class="btn btn-primary btn-sm example-full-width mt-2 mr-0  pr-0" >Apply</button>
                                                          </div>
                                                  </div>
                                               
                                               
                                                 
                                           
                                             
                                                   
                                                
                                               </th>
                                                
                                               
                                              </tr>
                                          
                                              <tr class="text-left">
                                                <th colspan="2" class="text-left pt-1 pb-1 pr-0 pl-3"> Total Other Amount</th>
                                                <th class="pt-1 pb-1 widthsize text-right">₹{{discount | number : '1.2-2'}}</th>
                                              </tr>
                                              <tr *ngIf="state == 'Maharashtra'" class="text-left">
                                                <th colspan="2" class="text-left pt-1 pb-1 pr-0 pl-3">SGST</th>
                                                <th class="pt-1 pb-1 widthsize text-right">₹{{totalsgstAmt | number : '1.2-2'}}</th>
                                              </tr>
                                              <tr *ngIf="state == 'Maharashtra'" class="text-center">
                                                <th colspan="2" class="text-left pt-1 pb-1 pr-0 pl-3">CGST</th>
                                                <th class="pt-1 pb-1 widthsize text-right">₹{{totalcgstAmt | number : '1.2-2'}}</th>
                                              </tr>
                                              <tr *ngIf="state !== 'Maharashtra'" class="text-left">
                                                <th colspan="2" class="text-left pt-1 pb-1 pr-0 pl-3">IGST</th>
                                                <th class="pt-1 widthsize pb-1 text-right">₹{{totaligstAmt | number : '1.2-2'}}</th>
                                              </tr>
                                              <tr class="text-center"> 
            
                                                <th colspan="2" class="text-left pt-1 pb-1 pr-0 pl-3">Total Bill Amount</th>
                                                <th  class="pt-1  widthsize pb-1 text-right ">₹{{totalAmount | number : '1.2-2'}}</th> 
                                                 
                                              </tr>
                                              <tr class="text-center">
                                  
                                              
                                                <th colspan="2" class="text-left pt-1 pb-1 pr-0 pl-3">Total Amount Paid Till Date</th>
                                                <th class="pt-1 pb-1 widthsize text-right">₹{{totalPaidAmount  | number : '1.2-2'}}</th>
                                               
                                              </tr>
                                              <tr [hidden]="true" class="text-center">
                                  
                                              
                                                <th colspan="2" class="text-left pr-0 pl-3 pt-1 pb-1">Advance</th>
                                                <th class="pt-1 pb-1 widthsize text-right">₹{{advanceAmount | number : '1.2-2'}}</th>
                                               
                                              </tr>
                                              
                                              <tr class="text-center" *ngIf="pendingChequeAmount !== 0" >
                                                <th colspan="2" class="text-left pt-1 pr-0 pl-3 pb-1">Pending Amount By Cheque Till Now</th>
                                                <th class="pt-1 pb-1 widthsize text-right">₹{{pendingChequeAmount | number : '1.2-2'}}</th>
                                               
                                              </tr>
                                              <tr class="text-center" *ngIf="bounceAmount !== 0">
                                  
                                              
                                                <th colspan="2" class="text-left pt-1 pr-0 pl-3 pb-1">Cheque Bounce Amount Till Now</th>
                                                <th  class="pt-1 pb-1 widthsize text-right">₹{{bounceAmount | number : '1.2-2'}}</th>
                                               
                                              </tr> 
                                              <tr class="text-center">
                                   
                                              
                                                <th colspan="2" class="text-left pr-0 pl-3 pt-1 pb-1">HA Advance Receipt Amount</th>
                                                <th  class="pt-1 pb-1 widthsize text-right">₹{{chequeAdvance | number : '1.2-2'}}</th>
                                               
                                              </tr>
                                              <tr class="text-center">

                                                <th colspan="2" class="text-left pt-1 pr-0 pl-3 pb-1">Remaining Balance</th>
                                                <!-- <th  class="pt-1 pb-1 widthsize text-right">₹{{balanceAmount | number : '1.2-2'}}</th> -->
                                                <th  class="pt-1 pb-1 widthsize text-right">₹{{totalAmount - totalAmtPaidTillDate - advanceAmt  | number : '1.2-2'}}</th>
                                              </tr>
                                         
                                              <!-- <tr class="text-center">
                                                <th colspan="7" class="text-left">Total Rupees in words : 
                                                  <label class="lable_style pt-2 header justify-content-left">Two Thousand Only</label>
                                                </th>
                                              </tr> -->
                                            </thead>
                                            
                                      </table>
                                      </form>
                                  </div>
                                  <div class="col-sm-9">
                                  </div>
                                  <div *ngIf="totalAmount - totalAmtPaidTillDate - advanceAmt == 0" class="col-sm-2 mb-2 mt-2"></div>
                                  <div class="col-sm-1 mb-2 mt-2">
                                    <button class="btn btn-primary btn-sm example-full-width" type="button"
                                      (click)="preview()">Preview</button>
                                  </div>
                                  <div class="col-sm-1 mb-2 mt-2" *ngIf="totalAmount - totalAmtPaidTillDate - advanceAmt !== 0">
                                    <button class="btn btn-primary btn-sm example-full-width" type="button" (click)="editBill(billNo)">Edit
                                      Bill</button>
                                  </div>
                                  <div class="col-sm-1 mb-2 mt-2" *ngIf="totalAmount - totalAmtPaidTillDate - advanceAmt !== 0">
                                    <button class="btn btn-primary btn-sm example-full-width" type="button" [disabled]="disableSubmit"
                                      (click)="submitPayment()">Save</button>
                                  </div>
                              </div>
                         
                      </div>
                      <div class="card divbody borderradius p-3 mt-2 pb-0">
                            <div class="col-sm-12">
                              <div class="row">
                                <!-- <div class="col-sm-1"></div> -->
                                <div class="col-sm-3  ">
                                  <h1 class="titlecolor" id="toDoctorList">History</h1>
                                </div>
                                <div class="col-sm-6  ">
                                </div>
                                <div class="search-box col-sm-3 pb-2">
                                  <input class="form-control amber-border" type="text" placeholder="Search" 
                                  formControlName="searchHistory" aria-label="Search" (keyup)="onKeyUpEvent($event)"
                                  >
                                  <div class="input-group-append">
                                     <span class="input-group-text amber mt-0 pt-0" id="basic-text1"><span class="material-symbols-outlined">search</span></span>
                                  </div>
                               </div>
                                <!-- <div class="col-sm-3">
                                  <div class="input-group md-form form-sm form-1 pl-0">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text purple lighten-3" id="basic-text1"><i
                                          class="fas fa-search text-white" aria-hidden="true"></i></span>
                                    </div>
                                    <input class="form-control my-0 py-1" type="text" placeholder="Search"
                                      formControlName="searchHistory" (keyup)="onKeyUpEvent($event)" type="text" aria-label="Search">
                                  </div>
                                </div> -->
                                <div class="col-sm-12 table-responsive">
                                  <table id="toBillingLst" 
                               class="table table-bordered data-table-custom">
                              <thead>
                                                      <tr class="text-center">
                                                          <th>Payment Date</th>
                                                          <th>Payment Mode</th>
                                                           <th>Paid Amount</th>
                                                           <th>Receipt Type</th>
                                                          <th>Receipt No.</th>
                                                          <th>Action</th>
        
                                                      </tr>
                                                  </thead> 
                                                  <tbody>
                                                      <tr *ngFor="let item of paymentHistoryList | paginate : { id:'billMaster',
                                                      itemsPerPage: size, currentPage: page, totalItems:totalLength }"  
                                                       style="cursor:pointer; text-align: center ">
                                                       <td class="labelStyle" matTooltip="Click To View ">{{item.createdAt | date:'dd/MM/yyyy'}}</td>
                                                       <td class="labelStyle"   matTooltip="Click To View" 
                                                       >{{item.modeOfPayment}}
                                                     </td>
                                                  
                                                     <!-- <td class="labelStyle" matTooltip="Click To View" >{{item.paidAmount}}</td> -->
                                                  
                                                     <td class="labelStyle" matTooltip="Click To View" >
                                                      <label *ngIf="item.modeOfPayment == 'Cheque'; else elseBlock1">{{item.pendingChequeAmount}}</label>
                                                      <!-- <label *ngIf="item.modeOfPayment == 'Cheque' && item.modeOfPayment == 'Clear'; else elseBlock1">{{item.pendingChequeAmount}}</label> -->
                                                      <ng-template #elseBlock1>
                                                      <label>{{item.paidAmount}}</label>
                                                    </ng-template> 

                                                      </td>

                                                     <td class="labelStyle" matTooltip="Click To View" >
                                                      <label *ngIf="item.advanceStatus == 'Yes'; else elseBlock">Advance</label>
                                                     <!-- <ng-template #elseBlock2>
                                                      <label *ngIf="item.advanceStatus == 'Yes'; else elseBlock">Advance</label>
                                                    </ng-template> --> 
                                                    <ng-template #elseBlock>
                                                      <label>Regular</label>
                                                    </ng-template> 

                                                      </td>
                                                     <td class="labelStyle"  >    
                                                       <!-- path:'bill-form/billNo/receipt/:receiptNo',-->
                                                       <a href="#"  [routerLink]="['/receipt/',item.receiptNo]"  class="link-primary"
                                                       class="repairtb" 
                                                         matTooltip="Receipt View">{{item.receiptNo}}</a>
                                                         </td>
                                                         <td class=" text-center no-print mt-0 pt-0 mb-0 pb-0 pr-1 mr-1 pl-1 ml-1">

                                                          <div class="row">
                                                            <div class=" col-sm-3  ml-0 pl-0"></div>
                                                            <div class=" col-sm-2  ml-0 pl-0">
                                                              <button *ngIf="!item.receiptNo" mat-button mat-icon-button class="ml-0 pl-0 ">
                                                                <img matTooltip="Create Receipt" class="mt-1 " (click)="openConfirmatipnPopUp(item._id)"
                                                                 width="25" src="../../assets/bill2.png">
                                                              </button>
                                                            </div>
                                                            <div class=" col-sm-2  ml-0 pl-0"></div>
                                                            <div class=" col-sm-2 ml-0 pl-0">
                                                              <button *ngIf="!item.receiptNo" mat-button mat-icon-button>
                                                                <img matTooltip="Delete Recepit" (click)="deleteReceipt(item)" class="mt-1 ml-0 pl-0" width="25" src="../../assets/dustbin.png">
                                                              </button>
                                                            </div>
                                                            <div class=" col-sm-3  ml-0 pl-0"></div>
                                                          </div>
                                    
                                    
                                                        </td>
                                                         </tr>
                                                   
      
                                                  </tbody>
                                              </table>
                                </div>
                                <div *ngIf="history == true" class="col-sm-12 pt-0 text-right">
                                  <pagination-controls id="billMaster" directionLinks="true"
                        (pageChange)="onPageChange($event)" class="float-right pagination my-pagination labelStyle"></pagination-controls>
                              
                          </div>
                              </div>
                            </div>
                          </div>
                       
                  </div>

              </div>
          

      </div>
  </div>

</div>