<div class="container-fluid" [formGroup]="placeOrderPrintForm">
    <form [formGroup]="placeOrderPrintForm" autocomplete="off" novalidate>
        <div class="row ">
            <div class="col-sm-12 ">

                <div class="col-sm-12">

                    <div class="row">
                        <div class="col-sm-11"></div>
                        <div class="col-sm-1">

                            <div class="row no-print justify-content-end ">
                                <a (click)="printThisPage()" class="d-flex justify-content-end align-items-end ms-3">
                                    <span class="material-symbols-outlined pr-2">print</span>Print</a>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="row">
                                <table id="toBillingLst" class="table table-hover mb-0 pb-0 table-bordered">


                                    <tbody>

                                        <tr role="row" class="odd tw">

                                            <td class=" mt-0 pt-0 mb-0 pt-0">
                                                <div class="row w2 pt-2  mt-0 pt-0 mb-0 pt-0">
                                                    <div class="col-sm-12  mt-0 pt-0 mb-0 pt-0" style="display:block;">
                                                        <img *ngIf="!logoimg" class="img2 mt-2 pt-0 mb-0 pt-0"
                                                            width="100%" height="100%"
                                                            src="../../assets/clinic_logoDemo.png" alt="Amanda Wilson">
                                                        <img *ngIf="logoimg" width="100%" height="100%" [src]="logoimg"
                                                            alt=" photo" class="img2  mt-2 pt-0 mb-0 pt-0">
                                                    </div>
                                                </div>
                                            </td>
                                            <td
                                                class="pr-0  mr-2 pl-0 ml-0 text-center justify-content-center pt-1 pb-0 ">
                                                <div class="row w1">
                                                    <div class="col-sm-12  mb-0 pb-0">
                                                        <div class="row">
                                                            <div class="col-sm-12">
                                                                <strong class="auto-style2 fass mb-0 pb-0 mt-0 pt-0">
                                                                    <h1 class="fsize" *ngIf="reportName !== ''">
                                                                        {{reportName}} </h1>
                                                                    <h1 class="fsize" *ngIf="reportName == ''">Clinic
                                                                        Name</h1>
                                                                </strong>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-12  mt-0 pt-0">
                                                        <div class="row">
                                                            <div class="col-sm-12">

                                                                <label class="col-sm-12" *ngIf="reportName !== ''">
                                                                    {{reportAddress}}, {{taluka}} , {{district}},
                                                                    {{state}},</label>
                                                                <label class="col-sm-12 mt-1" *ngIf="reportName !== ''">
                                                                    {{country}} - {{reportPinCode}}</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-12 mt-2 text-center justify-content-center">
                                                        <div class="row">
                                                            <div class="col-sm-12  pb-2">
                                                                <label *ngIf="reportName !== ''">Mobile No :
                                                                    <b>
                                                                        {{reportPhoneNumber | slice:0:2}} {{reportPhoneNumber | slice:2:4}} {{reportPhoneNumber | slice:4:7}} {{reportPhoneNumber | slice:7:10}},
                                                                        {{reportPhoneNumber1 | slice:0:2}} {{reportPhoneNumber1 | slice:2:4}} {{reportPhoneNumber1 | slice:4:7}} {{reportPhoneNumber1 | slice:7:10}},
                                                                    </b>

                                                                </label>
                                                            </div>
                                                            <div
                                                                class="col-sm-12 pb-2 text-center justify-content-center">
                                                                <label class="trans">Email Id :{{emailAdd}}</label>
                                                            </div>
                                                            <div
                                                                class="col-sm-12 pb-2 text-center justify-content-center">
                                                                <label class="trans">Website :
                                                                    {{website}} </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>

                                            <td class="w2 pt-1 pb-0 text-center
                                                  justify-content-center">

                                                <div class="row marginTopp">
                                                    <!-- <div class="col-sm-12 pb-2">
                                                        <strong>
                                                            GST Number :{{companyGst}}
                                                        </strong>
                                                    </div>
                                                    <br> -->
                                                    <div class="col-sm-12 pb-2">
                                                        <strong> Weekly Off : {{reportWeeklyOff}}</strong>
                                                    </div>
                                                    <br>
                                                    <div class="col-sm-12">
                                                        <strong class="labelMin" *ngIf="openTime !== '' &&
                                                        closeTime !== ''">From<br> {{openTime}}<br> To<br> {{closeTime}}</strong> 
                                                    </div>
                                                </div>
                                            </td>

                                        </tr>

                                    </tbody>
                                </table>
                                <div class="col-sm-12 mt-2 mb-0 pt-0 pb-0">
                                    <h1 class="text-center mb-1 pt-0 pb-0">
                                        Place Order Form</h1>
                                </div>
                                <table id="toBillingLst" class="table table-hover mb-0 pt-0 pb-0 table-bordered">
                                    <thead class="bcol">
                                        <th colspan="4" class="pt-1 bcol pb-0">
                                            <label>HA Company :</label>
                                        </th>
                                        <th class="fontSize bcol pt-1 pb-0">
                                            <label
                                                *ngIf="placeOrderData.companyName !== '' && placeOrderData.companyName !== null
                                        && placeOrderData.companyName !== undefined; else elseBlock1">{{placeOrderData.companyName}}</label>
                                            <ng-template #elseBlock1>
                                                <label class="fontSize">-</label>
                                            </ng-template>
                                        </th>
                                        <th colspan="4" class="pt-1 bcol pb-0">
                                            <label>Sales Person Name :</label>
                                        </th>
                                        <th class="fontSize bcol pt-1 pb-0">
                                            <label
                                                *ngIf="placeOrderData.salesPersonName !== '' && placeOrderData.salesPersonName !== null
                                        && placeOrderData.salesPersonName !== undefined; else elseBlock1">{{placeOrderData.salesPersonName}}</label>
                                            <ng-template #elseBlock1>
                                                <label class="fontSize">-</label>
                                            </ng-template>
                                        </th>
                                        <th class="pt-1 bcol pb-0">
                                            <label>Date : {{currentdate | date: 'dd/MM/yyyy'}}</label>
                                        </th>

                                    </thead>
                                    <thead class="bcol">
                                        <th class="pt-1 bcol pb-0">
                                            <label>Address :</label>
                                        </th>
                                        <th colspan="4" class="width1 bcol pt-1 pb-0">
                                            <label>
                                                {{placeOrderData.companyAddress}}

                                            </label>
                                        </th>
                                        <th class="pt-1 bcol pb-0">
                                            <label>CAN No :</label>
                                        </th>
                                        <th colspan="4" class="fontSize bcol pt-1 pb-0">
                                            <label
                                                *ngIf="placeOrderData.canNumber !== '' && placeOrderData.canNumber !== null
                                        && placeOrderData.canNumber !== undefined; else elseBlock1">{{placeOrderData.canNumber}}</label>
                                            <ng-template #elseBlock1>
                                                <label class="fontSize">-</label>
                                            </ng-template>
                                        </th>
                                        <th class="pt-1 bcol pb-0">
                                            <label>Time : {{currentdate | date: 'h:mm a'}}</label>
                                        </th>
                                    </thead>
                                    <!-- <thead class="bcol">
                                        <th class="pt-1 bcol pb-0">
                                            <label>Email :</label>
                                        </th>
                                        <th colspan="4" class="width1 bcol pt-1 pb-0">
                                            <label>
                                                {{placeOrderData.companyMail}}

                                            </label>
                                        </th>
                                        <th class="pt-1 bcol pb-0">
                                            <label>Time : {{currentdate | date: 'h:mm a'}}</label>
                                        </th>
                                    </thead> -->

                                </table>

                                <table id="toBillingLst" class="table table-hover table-bordered">
                                    <thead>
                                        <tr class="text-center">
                                            <th class=" pt-1 pb-1">Device Name</th>
                                            <th class=" pt-1 pb-1">Qty</th>
                                            <th class=" pt-1 pb-1">FOC Units</th>
                                            <th class=" pt-1 pb-1">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of HAModelDetails[0]"
                                            style="cursor:pointer; text-align: center ">

                                            <td matTooltip="click to view">
                                                <label *ngIf="item.deviceName !== '' && item.deviceName !== null
                                            && item.deviceName !== undefined; else elseBlock1">
                                                    {{item.deviceName}}
                                                </label>
                                            </td>
                                            <td matTooltip="click to view">
                                                <label *ngIf="item.qty !== '' && item.qty !== null
                                            && item.qty !== undefined; else elseBlock1">
                                                    {{item.qty}}
                                                </label>
                                            </td>
                                            <td matTooltip="click to view">
                                                <label *ngIf="item.focUnits !== '' && item.focUnits !== null
                                            && item.focUnits !== undefined; else elseBlock1">
                                                    {{item.focUnits}}
                                                </label>
                                            </td>
                                            <td matTooltip="click to view">
                                                <label *ngIf="item.totalNo !== '' && item.totalNo !== null
                                            && item.totalNo !== undefined; else elseBlock1">
                                                    {{item.totalNo}}
                                                </label>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table id="toBillingLst" class="table table-hover table-bordered">
                                    <thead>
                                        <tr class="text-center">
                                            <th class=" pt-1 pb-1">Receivers</th>
                                            <th class=" pt-1 pb-1">Size</th>
                                            <th class=" pt-1 pb-1">Power</th>
                                            <th class=" pt-1 pb-1">Qty</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of HAModelReceiver[0]"
                                            style="cursor:pointer; text-align: center ">

                                            <td matTooltip="click to view">
                                                <label *ngIf="item.receivers !== '' && item.receivers !== null
                                            && item.receivers !== undefined; else elseBlock1">
                                                    {{item.receivers}}
                                                </label>
                                            </td>
                                            <td matTooltip="click to view">
                                                <label *ngIf="item.size !== '' && item.size !== null
                                            && item.size !== undefined; else elseBlock1">
                                                    {{item.size}}
                                                </label>
                                            </td>
                                            <td matTooltip="click to view">
                                                <label *ngIf="item.power !== '' && item.power !== null
                                            && item.power !== undefined; else elseBlock1">
                                                    {{item.power}}
                                                </label>
                                            </td>
                                            <td matTooltip="click to view">
                                                <label *ngIf="item.receiversQty !== '' && item.receiversQty !== null
                                            && item.receiversQty !== undefined; else elseBlock1">
                                                    {{item.receiversQty}}
                                                </label>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table id="toBillingLst" class="table table-hover table-bordered">
                                    <thead>
                                        <tr class="text-center">
                                            <th class=" pt-1 pb-1">Accessories</th>
                                            <th class=" pt-1 pb-1">Type</th>
                                            <th class=" pt-1 pb-1">Qty</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of HAModelAccessories[0]"
                                            style="cursor:pointer; text-align: center ">

                                            <td matTooltip="click to view">
                                                <label *ngIf="item.accessories !== '' && item.accessories !== null
                                            && item.accessories !== undefined; else elseBlock1">
                                                    {{item.accessories}}
                                                </label>
                                            </td>
                                            <td matTooltip="click to view">
                                                <label *ngIf="item.accessoriesType !== '' && item.accessoriesType !== null
                                            && item.accessoriesType !== undefined; else elseBlock1">
                                                    {{item.accessoriesType}}
                                                </label>
                                            </td>
                                            <td matTooltip="click to view">
                                                <label *ngIf="item.accessoriesQty !== '' && item.accessoriesQty !== null
                                            && item.accessoriesQty !== undefined; else elseBlock1">
                                                    {{item.accessoriesQty}}
                                                </label>
                                            </td>
                                        
                                        </tr>
                                    </tbody>
                                </table>
                                <table id="toBillingLst" class="table table-hover table-bordered">
                                    <thead>
                                        <tr class="text-center">
                                            <th colspan="2" class=" pt-1 pb-1">Other</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="text-left">
                                            <td class="pt-1 pb-0 boldd">HA Color</td>
                                            <td class=" pt-1 pb-0" matTooltip="click to view">
                                                <label *ngIf="placeOrderData.haColorName !== '' && placeOrderData.haColorName !== null
                                            && placeOrderData.haColorName !== undefined; else elseBlock1">
                                            {{placeOrderData.haColorName}}
                                                </label>
                                            </td>
                                       
                                        </tr>
                                        <tr class="text-left">
                                            <td class="pt-1 pb-0 boldd">Note</td>
                                            <td class=" pt-1 pb-0" matTooltip="click to view">
                                                <label *ngIf="placeOrderData.note !== '' && placeOrderData.note !== null
                                            && placeOrderData.note !== undefined; else elseBlock1">
                                            {{placeOrderData.note}}
                                                </label>
                                            </td>
                                          
                                        </tr>
                                       
                                    </tbody>
                                </table>
                                <div class="col-sm-12 mt-5">
                                    <div class="row">
                                        <div class="col-sm-2">
                                            <!-- <img src='{{ signatureImg }}' /> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <div class="row">
                                        <div class="col-sm-2">
                                            <!-- <h2 class="mt-5 text-center">Clients Signature</h2> -->
                                        </div>
                                        <div class="col-sm-8"></div>
                                        <div class="col-sm-2">
                                            <h2 class="mt-5 ml-4">Signature</h2>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>


            </div>
        </div>
    </form>
</div>