<div class="container-fluid " [formGroup]="earMouldDashboardForm">
    <form [formGroup]="earMouldDashboardForm" autocomplete="off" novalidate>
        <div class="row">
   
            <div class="col-sm-12">
               
              <div class="row pt-1">
                <div class="col-sm-12 pb-2">
                    <div class="row margingTop pb-2">
              <div class="card divbody col-sm-12 boxsssss borderradius p-3 pb-0 no-print">
                      <div class="col-sm-12">
                <div class="row">
                                    <div class="col-sm-8">
                                        <h1 class="titlecolor"> Ear Mould Order Form List</h1>
                                    </div>
                                    <div class="col-sm-1 justify-content-right">
                                        <div class="row">
                                            <div class="col-sm-6"></div>
                                            <div class="col-sm-6 justify-content-right">
                                                <div class="icon-btns pb-4">
                                                    <!-- (click)="billing(data.patientRefId,data.appointId,data.branchRefId,data)" -->
                                                    <a class="icon-box2" data-toggle="tooltip" data-placement="top" matTooltip="Ear Mold"
                                   
                                    (click)="earMold(branchRefId,patientRef,branchNamee,pName)" >
                                    <span class="material-symbols-outlined">hearing</span>
                                    <img src="../../assets/icon-bg10.svg" class="icon-bg2">
                                    </a> 
                                                    </div>
                                      </div> 
                                      </div>
                                      </div>
                                  
                                    <div class="search-box col-sm-3 pb-2">
                                        <input class="form-control amber-border" type="text" placeholder="Search" 
                                        formControlName="searchEarMouldOrder" aria-label="Search" (keyup)="onKeyUpEvent($event)"
                                        >
                                        <div class="input-group-append">
                                           <span class="input-group-text amber lighten-3" id="basic-text1"><span class="material-symbols-outlined">search</span></span>
                                        </div>
                                     </div>
                                   
                                    <div class="col-sm-12">
                                        <table id="toearMouldDashboardFormList" 
                                        class="table table-bordered data-table-custom">
                                      
                                            <thead>
                                                <tr class="text-center">
                                                    <th>Ear Mould ID </th>
                                                    <!-- <th>Patient Name</th> -->
                                                    <th *ngIf="userType == 'Super Admin'">Branch Name</th>
                                                    <th>Company Name</th>
                                                    <th>Billing Company Name</th>
                                                    
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of listOfEarMouldOrders | paginate : { id:'ear_pagination', 
                                                itemsPerPage: size, currentPage: page, totalItems:totalLength }" 
                                                (click)="prepopulation(item._id)"
                                                 (click)="toearMouldDashboardForm()" (click)="redirectToEarMouldForm(item._id)"
                                                 style="cursor:pointer; text-align: center ">
  
                                                 <td matTooltip="click to view">{{item.branchWiseId}}</td>
                                                 <!-- <td matTooltip="click to view">{{item.firstName}}  {{item.lastName}}</td> -->
                                                 <td *ngIf="userType == 'Super Admin'" matTooltip="click to view" 
                                                 >{{item.branchName}}</td>
                                                 <td matTooltip="click to view">{{item.companyName}}</td>
                                                 
                                                 
                                                 <td matTooltip="click to view">{{item.billingCompanyName}}</td>
                                                    
                                                </tr>
                                              
                                            
                                               
  
                                            </tbody>
                                        </table>
                                
                                    <div class="col-sm-12 pt-0 text-right">
                                            <pagination-controls id="ear_pagination" directionLinks="true"
                                  (pageChange)="onPageChange($event)" class="float-right pagination my-pagination"></pagination-controls>
                                   
                                </div>
                            </div>
                    
                    </div>
  </div>
                </div>
              </div>
            </div>
          
                  </div>
              </div>
  
        </div>
    
    </form>
  </div>