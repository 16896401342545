import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from "../api-service.service";
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import {Router,ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-goods-inword-return',
  templateUrl: './goods-inword-return.component.html',
  styleUrls: ['./goods-inword-return.component.scss']
})
export class GoodsInwordReturnComponent implements OnInit {
  size:number = 5;
  page:number = 1;
  listOfBatch: any;
  totalLength: any;
  updateButton = false;
  submitButton = true;
  goodInWordReturnForm: FormGroup;
  userPermission: any;
  userType: any;
  permissionList=[];
  loggedId: any;
  dispatchDeviceCreate= true;
  dispatchDeviceUpdate: boolean = false;
  _id: string;
  
  constructor( public apimicroservice: ApiServiceService,
    private fb: FormBuilder,
    public router: Router,private route: ActivatedRoute) { }
    
  ngOnInit(): void {
   
    // this.userPermission = JSON.parse(sessionStorage.getItem("userPermissions"));
    // this.userType = sessionStorage.getItem("type");
    // this.loggedId =  sessionStorage.getItem('id');
    // this._id = this.route.snapshot.paramMap.get('id');
    // if(this.userType == 'User')
    // {
    //   this.userPermission.forEach((element) => {
    //     this.permissionList.push(element.pageName);
    //     if (element.pageName == "Add Dispatch Device" && element.create == false) {
    //       this.dispatchDeviceCreate = false;
    //     } 
    //     if (element.pageName == "Add Dispatch Device" && element.update == false) {
    //       this.dispatchDeviceUpdate = false;
    //     } 
    //   });
    // }

    
    this.goodInWordReturnForm = this.fb.group({
      "grnNo": new FormControl('',[Validators.required]), 
      "supplierName": new FormControl('',[Validators.required]), 
      "transporterName": new FormControl('',[Validators.required]),   
      "grnDate": new FormControl('',[Validators.required]),   
      "poNumber": new FormControl('',[Validators.required]), 
      "poDate": new FormControl('',[Validators.required]), 
      "invoiceNo": new FormControl('',[Validators.required]), 
      "invoiceDate": new FormControl('',[Validators.required]), 
      "device": this.fb.array([]),
      "searchGoods": new FormControl(''), 
      
    });
    this.addNew();
  }

  submitGoodsInWord() {
    const creds = this.goodInWordReturnForm.controls.device as FormArray;     
    if(this.goodInWordReturnForm.invalid) {
     
      this.goodInWordReturnForm.get('grnNo').markAsTouched();
      this.goodInWordReturnForm.get('supplierName').markAsTouched();
      this.goodInWordReturnForm.get('transporterName').markAsTouched();
      this.goodInWordReturnForm.get('grnDate').markAsTouched();
      this.goodInWordReturnForm.get('poNumber').markAsTouched();
      this.goodInWordReturnForm.get('poDate').markAsTouched();
      this.goodInWordReturnForm.get('invoiceNo').markAsTouched();
      this.goodInWordReturnForm.get('invoiceDate').markAsTouched();
     
    }
    else if(creds.invalid)
    {
         creds.get('serialNumber').markAsTouched();
         creds.get('qty').markAsTouched();
         creds.get('rate').markAsTouched();
         creds.get('cdlNo').markAsTouched();
         creds.get('warrentyPeriod').markAsTouched();
         creds.get('amount').markAsTouched();
    }
    else{
     this.addGoodsInWord();
    }
}

updateGoodsInWord() {
  // let payload = this.goodInWordReturnForm.value;
  // this.spinner.show();
  // this.apimicroservice.updateServer(this._id,payload).subscribe(res => {
  //   //
  //   this.spinner.hide();
  //   if(res.status){
  //     this.apimicroservice.openSnackbar(res.message);
  //     this.router.navigate(['govt-servers']);
  //   }else{
  //     this.apimicroservice.openSnackbar(res.message);
  //   }
  // });
}

addGoodsInWord(){
  // let payload = this.goodInWordReturnForm.value;
  // this.spinner.show();
  // //
  
  // this.apimicroservice.addGoodsInWord(payload).subscribe(res => {
  //   //
  //   this.spinner.hide();
  //   if(res.status){
  //     this.apimicroservice.openSnackbar(res.message);
  //     this.router.navigate(['dispatch-device-page']);
  //   }else{
  //     this.apimicroservice.openSnackbar(res.message);
  //   }
  // });  
  

}

  prepopulation(id){
    // this.spinner.show();
    // this.apimicroservice.getDispatchDeviceById(id).subscribe(res => {
    //   //
    //   this.spinner.hide();
    //  let data = res.data[0];
    //   if(res.status){
    //     this.goodInWordReturnForm = this.fb.group({
    //       "batchname": new FormControl(data.batchname,[Validators.required]),  
    //       "device": this.fb.array([]),
    //     });
    //     let preArr = [];
    //     data.device.forEach(element => {
      
    //       preArr.push(element);
    //       //       
    //       const tmpDict = {};
    //       tmpDict['uinFrom'] = new FormControl(element.uinFrom);
    //       tmpDict['uinTo'] = new FormControl(element.uinTo);
    //       tmpDict['plantLoc'] = new FormControl(element.plantLoc);
    //       const creds = this.goodInWordReturnForm.controls.device as FormArray;
    //        creds.push(new FormGroup(tmpDict));
    //        //
    //       });
    //   }
    // });
  }
  deleteRow(index){
    (<FormArray>this.goodInWordReturnForm.get("device")).removeAt(index);
  }
  addNew() {
    const creds = this.goodInWordReturnForm.controls.device as FormArray;
        creds.push(
        this.fb.group({
          "serialNumber": new FormControl('',[Validators.required]),   
          "qty": new FormControl('',[Validators.required]),   
          "rate": new FormControl('',[Validators.required]), 
          "cdlNo": new FormControl('',[Validators.required]),   
          "warrentyPeriod": new FormControl('',[Validators.required]),   
          "amount": new FormControl('',[Validators.required]),   
        toggle: false

})
);
  }
  redirect(to): void {
    this.router.navigate([to]);
   }
  onback() {
    this.apimicroservice.back()
  }
  onPageChange(event)
  {
  }
}