import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
 import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { AfterViewInit, Directive, ViewChild } from "@angular/core";

import { MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner"; 
import { HttpClient } from "@angular/common/http";
@Component({
  selector: 'app-clinic-master',
  templateUrl: './clinic-master.component.html',
  styleUrls: ['./clinic-master.component.scss']
})
export class ClinicMasterComponent implements OnInit {

  errormessage: string;
  clinicData:any;
  totalLength:any;
  CId: any;
  size=5;
  page=1;
  allDays=[
    {name:'None'},
    {name:'Sunday',value:'Sunday'},
    { name:'Monday',value:'Monday' },
    {name:'Tuesday',value:'Tuesday' },
    { name:'Wednesday',value:'Wednesday'},
    {name:'Thursday',value:'Thursday'},
    {name:'Friday',value:'Friday'},
    {name:'Saturday',value:'Saturday'},
      ]
      allState=[
        {name:'Andaman and Nicobar Islands',value:'AN' },
        {name:'Andhra Pradesh',value:'AP'},
        { name:'Arunachal Pradesh',value:'AR' },
    {name:'Assam',value:'AS' },
    { name:'Bihar',value:'BR'},
     { name:'Chandigarh',value:'CH' },
    {name:'Chhattisgarh',value:'CT' },
    { name:'Dadra and Nagar Haveli', value:'DN' },
     { name:'Daman and Diu', value:'DD'},
     { name:'Delhi',value:'DL'},
     {name:'Goa',value:'GA' },
    {name:'Gujarat', value:'GJ'}, 
    {name:'Haryana',value:'HR'}, 
    {name:'Himachal Pradesh',value:'HP'}, 
    { name:'Jammu and Kashmir',value:'JK'}, 
     {name:'Jharkhand',value:'JH'}, 
    { name:'Karnataka', value:'KA'},
    { name:'Kerala',value:'KL'},
    {name:'Ladakh',value:'LA'},
     {name:'Lakshadweep',value:'LD' },
     {name:'Madhya Pradesh', value:'MP'},
    { name:'Maharashtra',value:'MH'},
     { name:'Manipur',value:'MN'},
    { name:'Meghalaya', value:'ML' },
    { name:'Mizoram',value:'MZ' },
    { name:'Nagaland',value:'NL' },
     { name:'Odisha',value:'OR'},
     { name:'Puducherry', value:'PY'},
     {name:'Punjab', value:'PB' },
    { name:'Rajasthan', value:'RJ'},           
     {name:'Punjab',value:'PB'},   
     {name:'Rajasthan', value:'RJ'},
    {name:'Sikkim',value:'SK' },
    { name:'Tamil Nadu',value:'TN'}, 
     {name:'Telangana',value:'TG'},   
      { name:'Tripura',  value:'TR' },
     { name:'Uttar Pradesh', value:'UP'},
    {name:'Uttarakhand',value:'UT'},  
    { name:'West Bengal', value:'WB' },                        
      ]
  filteredListDays: ({ name: string; value?: undefined; } | { name: string; value: string; })[];
  filteredList: { name: string; value: string; }[]; 
  userType: string;
  preId: any;
  emailId: string;
  logoIcon: string;
  
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
  public apimicroservice: ApiServiceService,
    public router: Router,
     public dialog: MatDialog,
    public datepipe: DatePipe,
    private httpClient: HttpClient,
    private spinner: NgxSpinnerService
  ) {}
  clinicMasterForm: FormGroup;
  status: any;
  submitButton = true;
  updateButton = false;
  deleteButton = false;
  cancelButton= false;
  
  loggedId: any;
  //myimage;
  date: any;
  searchText:any;
  userPermission:any;
  clinicCreate= true;
  clinicUpdate= true;
  stateCityArr2: any[];
  stateCity2: any;
  districtData2: any;
  filteredList2: { state: string; districts: string; }[];
  filteredListDistrict2: { district: string;}[];

  ngOnInit() {
    this.page=1;
    this.filteredListDays = this.allDays.slice();
    this.filteredList = this.allState.slice();
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    this.userType = sessionStorage.getItem('userType');
    this.emailId = sessionStorage.getItem('emailId');
    
    if(this.userType !== 'Super Admin'){
     
    this.userPermission.forEach((element) => {
      if(element.pageName == 'Clinic' && element.create == false)
      {
          this.clinicCreate = false;  
      }
      if(element.pageName == 'Clinic' && element.update == false)
      {
          this.clinicUpdate = false;  
      }  
    });
  }
    this.clinicMasterForm = this.fb.group({ 
     "clinicId": new FormControl(''),
     "clinicName": new FormControl('',[Validators.required, Validators.pattern("^[a-zA-Z_@./#&+-\\s]*$")]),
     "clinicPhoneNumber": new FormControl('',[Validators.required, Validators.pattern("(0|91)?[6-9][0-9]{9}")]),
     "clinicPhoneNumber1": new FormControl('',[Validators.pattern("(0|91)?[6-9][0-9]{9}")]),
     "clinicType": new FormControl('',[Validators.required]),
      "clinicEmail":new FormControl('', [Validators.required,Validators.pattern("(?![0-9]+@)[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}")]),
      "clinicWebsite":new FormControl('', [Validators.required]),
      // Validators.pattern("^((http|https)://)[-a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)$")
      "openTime": new FormControl('',Validators.required),
      "closeTime": new FormControl('',Validators.required),
      "clinicPinCode":new FormControl('', [Validators.required, Validators.pattern("^[0-9]{6}$")]),
       "clinicStatus": new FormControl(''),
      "taluka": new FormControl('',Validators.pattern("^[a-zA-Z ]*$")),
      "district": new FormControl('',Validators.pattern("^[a-zA-Z ]*$")),
      "state": new FormControl('',Validators.required),
      "clinicLandline": new FormControl('',Validators.pattern("[0-9]{3,4}[-][0-9]{6,8}")),
      "country": new FormControl('', [
        Validators.pattern("[A-Za-z _-]{3,19}$"),
      ]),
      "clinicAddress": new FormControl('',[Validators.required]),
      "clinicWeeklyOff":new FormControl(''),
      "createdAt": new Date(),
      "searchClinic":new FormControl(''),
      // "bankName":new FormControl('',Validators.pattern("^[a-zA-Z_-\\s]*$")),
      // "bankBranchName":new FormControl('',Validators.pattern("^[a-zA-Z_-\\s]*$")),
      // "accountNumber":new FormControl('',[Validators.pattern("^[0-9]{9,18}$"),Validators.maxLength(18),Validators.minLength(9),]),
      // "ifcCode":new FormControl('',Validators.pattern("^[A-Za-z]{4}0[A-Z0-9a-z]{6}$")),
      // "upiId":new FormControl('',Validators.pattern("^[A-Za-z0-9]{4,}@[A-Z0-9a-z]{4,}$")),

      "uploadLogo": '',
    });

    let arr=[]
    arr = [
      "Ahmednagar",
      "Akola",
      "Amravati",
      "Aurangabad",
      "Beed",
      "Bhandara",
      "Buldhana",
      "Chandrapur",
      "Dhule",
      "Gadchiroli",
      "Gondia",
      "Hingoli",
      "Jalgaon",
      "Jalna",
      "Kolhapur",
      "Latur",
      "Mumbai City",
      "Mumbai Suburban",
      "Nagpur",
      "Nanded",
      "Nandurbar",
      "Nashik",
      "Osmanabad",
      "Palghar",
      "Parbhani",
      "Pune",
      "Raigad",
      "Ratnagiri",
      "Sangli",
      "Satara",
      "Sindhudurg",
      "Solapur",
      "Thane",
      "Wardha",
      "Washim",
      "Yavatmal"
  ]

    this.logoIcon ="../../assets/clinic_logo2.jpg";
    //
    this.clinicMasterForm.controls["clinicStatus"].setValue("Active");
    this.clinicMasterForm.controls["state"].setValue("Maharashtra");
    this.fetchClinicList();
    this.getState2();
   this.getDistrict2(arr);
  }

  getState2()
  {
   this.httpClient.get("assets/state-city.json").subscribe(data =>{ 
     this.stateCity2 = data;
     this.stateCityArr2 = [];
     this.stateCity2.states.forEach((element) => {
       this.stateCityArr2.push(element);
     });
     this.filteredList2 = this.stateCityArr2.slice();
     //
   })
  }
  getDistrict2(state)
  {
   //
   this.districtData2 = [];
   state.forEach((element) => {
     //
     this.districtData2.push({"district":element});
   });
   //
   let arr = [];
   arr = state;
  this.filteredListDistrict2 = this.districtData2.slice();
  }
  submitClinic() {
   // this.setValidor();
    //
    if (this.clinicMasterForm.invalid) {
      //
      //
      this.clinicMasterForm.get("clinicName").markAsTouched();
      this.clinicMasterForm.get("clinicAddress").markAsTouched();
      this.clinicMasterForm.get("clinicType").markAsTouched();
      this.clinicMasterForm.get("clinicPinCode").markAsTouched();
      this.clinicMasterForm.get("clinicPhoneNumber").markAsTouched();
      this.clinicMasterForm.get("clinicPhoneNumber1").markAsTouched();
      this.clinicMasterForm.get("clinicEmail").markAsTouched();
       this.clinicMasterForm.get("clinicWebsite").markAsTouched();
       this.clinicMasterForm.get("clinicWeeklyOff").markAsTouched();
       this.clinicMasterForm.get("openTime").markAsTouched();
       this.clinicMasterForm.get("closeTime").markAsTouched();
      
        return;
    } else {
//
//
      this.addClinic();
    }
  }

  // removeValidator() { 
  //   this.clinicMasterForm.controls['clinicName'].setErrors(null);
  //   this.clinicMasterForm.controls['clinicName'].clearValidators();
  //   this.clinicMasterForm.controls['clinicName'].updateValueAndValidity();

  //   this.clinicMasterForm.controls['clinicAddress'].setErrors(null);
  //   this.clinicMasterForm.controls['clinicAddress'].clearValidators();
  //   this.clinicMasterForm.controls['clinicAddress'].updateValueAndValidity();

  //   this.clinicMasterForm.controls['clinicType'].setErrors(null);
  //   this.clinicMasterForm.controls['clinicType'].clearValidators();
  //   this.clinicMasterForm.controls['clinicType'].updateValueAndValidity();

  //   this.clinicMasterForm.controls['clinicPinCode'].setErrors(null);
  //   this.clinicMasterForm.controls['clinicPinCode'].clearValidators();
  //   this.clinicMasterForm.controls['clinicPinCode'].updateValueAndValidity();
    
  //   this.clinicMasterForm.controls['clinicPhoneNumber'].setErrors(null);
  //   this.clinicMasterForm.controls['clinicPhoneNumber'].clearValidators();
  //   this.clinicMasterForm.controls['clinicPhoneNumber'].updateValueAndValidity();

  //   this.clinicMasterForm.controls['clinicEmail'].setErrors(null);
  //   this.clinicMasterForm.controls['clinicEmail'].clearValidators();
  //   this.clinicMasterForm.controls['clinicEmail'].updateValueAndValidity();

  
  //   }

    // setValidor() { 
    //   this.clinicMasterForm.controls['clinicName'].setValidators([Validators.required,Validators.pattern("^[a-zA-Z_@./#&+-\\s]*$")]);
    //   this.clinicMasterForm.controls['clinicName'].updateValueAndValidity();

    //   this.clinicMasterForm.controls['clinicEmail'].setValidators([Validators.required,Validators.email,Validators.pattern("[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}")]);
    //   this.clinicMasterForm.controls['clinicEmail'].updateValueAndValidity();

    //   this.clinicMasterForm.controls['clinicType'].setValidators(Validators.required);
    //   this.clinicMasterForm.controls['clinicType'].updateValueAndValidity();

    //   this.clinicMasterForm.controls['clinicPhoneNumber'].setValidators([Validators.required,Validators.pattern("(0|91)?[6-9][0-9]{9}")]);
    //   this.clinicMasterForm.controls['clinicPhoneNumber'].updateValueAndValidity();

    //   this.clinicMasterForm.controls['clinicAddress'].setValidators(Validators.required);
    //   this.clinicMasterForm.controls['clinicAddress'].updateValueAndValidity();

    //   this.clinicMasterForm.controls['clinicPinCode'].setValidators([Validators.required,Validators.pattern("^[0-9]{6}$")]);
    //   this.clinicMasterForm.controls['clinicPinCode'].updateValueAndValidity();

    //   }


  updateClinicDetails() {
   // this.setValidor();
    if (this.clinicMasterForm.invalid) {
      
      this.clinicMasterForm.get("clinicName").markAsTouched();
      this.clinicMasterForm.get("clinicAddress").markAsTouched();
      this.clinicMasterForm.get("clinicType").markAsTouched();
      this.clinicMasterForm.get("clinicPinCode").markAsTouched();
      this.clinicMasterForm.get("clinicPhoneNumber").markAsTouched();
      this.clinicMasterForm.get("clinicPhoneNumber1").markAsTouched();
      this.clinicMasterForm.get("clinicEmail").markAsTouched();
      // this.clinicMasterForm.get("clinicWorkingHours").markAsTouched();
       this.clinicMasterForm.get("clinicWeeklyOff").markAsTouched();
      return;
    } else {
      this.updateClinic();
    }
  }

  addClinic() {
    this.spinner.show();
    let payload = this.clinicMasterForm.value
    payload.uploadLogo = this.logoIcon;
    this.apimicroservice.saveClinicMaster(payload).subscribe((res) => {

      
      if (res.status) {
        //
        let id = sessionStorage.getItem('userRef');
        let payload = {}
        sessionStorage.setItem('clinicRef',res.data.data._id);
        payload['clinicRefId'] = res.data.data._id;
        payload['emailId'] = this.emailId;
        this.apimicroservice.updateUser(id,payload).subscribe(res => {
          this.spinner.hide();
          if(res.status){
            this.apimicroservice.openSnackbar("success");
            this.logoIcon = "../../assets/clinic_logo2.jpg";
            
            this.ngOnInit();

          }else{
            this.apimicroservice.openSnackbar(res.message);
          }
        });



        // this.apimicroservice.saveClinicMaster(payload).subscribe((res) => {

        //   if (res.status) {
        //     //
        //     this.spinner.hide();
        //     this.ngOnInit();
        //   } else {
        //     this.apimicroservice.openSnackbar(res.message);
        //   }
        // });
        this.apimicroservice.openSnackbar(res.message);
        this.ngOnInit();
      } else {
        this.spinner.hide();
        this.apimicroservice.openSnackbar(res.message);
      }
      
    });
  }
  openSnackbar(message: string) {
    return this._snackBar.open(message, "close", {
      verticalPosition: "bottom",
      horizontalPosition: "right",
      duration: 2000,
      panelClass: ["snackbarStyle"],
    });
  }
  
 
  fetchClinicList() {

    let pg = 1;
    let size = 5;
    let search='';
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.spinner.show();
    this.apimicroservice.getClinicData(pg,size,search,clinicRef).subscribe(res => {
      this.spinner.hide();
      this.clinicData = res.data.clinicData;
      this.totalLength=res.data.totalItems;
      this.preId = res.data.clinicData[0]._id;
      if(this.clinicData.length > 0 )
      {
        this.prepopulation(this.preId);
      } 
      
      if(res.status){
       // this.apimicroservice.openSnackbar(res.message);
        
      }else{
        //this.apimicroservice.openSnackbar(res.message);
      }
  });
  }
 
  prepopulation(id) {
    this.spinner.show();
  this.apimicroservice.getClinicDataById(id).subscribe(res => {
    this.spinner.hide();
      this.updateButton = true;
      this.cancelButton=true;
      this.submitButton= false;
      this.deleteButton= true;
    let data = res.data[0];
      this.clinicMasterForm = this.fb.group({
        "clinicId": new FormControl(data.clinicId),
      "clinicName": new FormControl(data.clinicName, [Validators.required, Validators.pattern("^[a-zA-Z_@./#&+-\\s]*$")]),
      "clinicType": new FormControl(data.clinicType, [Validators.required]),
      "clinicPhoneNumber":new FormControl(data.clinicPhoneNumber, [Validators.required, Validators.pattern("(0|91)?[7-9][0-9]{9}")]),
      "clinicPhoneNumber1":new FormControl(data.clinicPhoneNumber1, [Validators.pattern("(0|91)?[7-9][0-9]{9}")]),
      "clinicEmail":new FormControl(data.clinicEmail, [Validators.required,Validators.pattern("(?![0-9]+@)[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}")]),
      "clinicWebsite":new FormControl(data.clinicWebsite, [Validators.required]),
      "clinicWorkingHours":new FormControl(data.clinicWorkingHours, Validators.pattern("^[0-9]*$")),
      "openTime": new FormControl(data.openTime,Validators.required),
      "closeTime": new FormControl(data.closeTime,Validators.required),
      "clinicWeeklyOff":new FormControl(data.clinicWeeklyOff),
      "clinicStatus": new FormControl(data.clinicStatus),
      "clinicPinCode":new FormControl(data.clinicPinCode, [Validators.required, Validators.pattern("^[0-9]{6}$")]),
      "taluka": new FormControl(data.taluka,Validators.pattern("^[a-zA-Z ]*$")),
      "district": new FormControl(data.district,Validators.pattern("^[a-zA-Z ]*$")),
      "state": new FormControl(data.state,Validators.required),
      "clinicLandline": new FormControl(data.clinicLandline,Validators.pattern("[0-9]{3,4}[-][0-9]{6,8}")),
      "country": new FormControl(data.country, [Validators.pattern("[A-Za-z _-]{3,19}$"), ]),
      "clinicAddress": new FormControl(data.clinicAddress,[Validators.required]),
      "updateddAt": new Date(),
      "searchClinic":new FormControl(''), 
      // "bankName":new FormControl(data.bankName,Validators.pattern("^[a-zA-Z_-\\s]*$")),
      // "bankBranchName":new FormControl(data.bankBranchName,Validators.pattern("^[a-zA-Z_-\\s]*$")),
      // "accountNumber":new FormControl(data.accountNumber,[Validators.pattern("^[0-9]{9,18}$"),Validators.maxLength(18),Validators.minLength(9),]),
      // "ifcCode":new FormControl(data.ifcCode,Validators.pattern("^[A-Za-z]{4}0[A-Z0-9a-z]{6}$")),
      // "upiId":new FormControl(data.upiId,Validators.pattern("^[A-Za-z0-9]{4,}@[A-Z0-9a-z]{4,}$")),
      "uploadLogo": '',
      });
      this.CId =id;
      this.logoIcon = data.uploadLogo ? data.uploadLogo : "../../assets/clinic_logo2.jpg"
  });
  }
  addNew() {
    this.clinicMasterForm.reset();
    this.updateButton = false;
    this.deleteButton = false;
  }
  updateClinic() {
    this.spinner.show();
    let payload = this.clinicMasterForm.value;
    //
    
    payload.uploadLogo = this.logoIcon;
     
    this.apimicroservice.updateClinicMaster(this.CId,payload).subscribe(res => {
      this.spinner.hide();
      sessionStorage.setItem('clinicRef',res.data._id);

      let id = sessionStorage.getItem('userRef');
      let userID = sessionStorage.getItem('userID');
      //
      let payload2 = {}
      sessionStorage.setItem('clinicRef',res.data._id);
      payload2['clinicRefId'] = res.data._id;  
      payload2['emailId'] = this.emailId;
      payload2['userID'] = userID;
      this.apimicroservice.updateUser(id,payload2).subscribe(res => { 
        if(res.status){
          this.apimicroservice.openSnackbar("success");
          this.logoIcon = "../../assets/clinic_logo2.jpg";
          
          this.ngOnInit();

        }else{
          this.apimicroservice.openSnackbar(res.message);
        }
      });
      if (res.status) {
     
      
        this.apimicroservice.openSnackbar(res.message);
        this.submitButton=true;
      this.updateButton = false;
      this.cancelButton=false;
      this.logoIcon = "../../assets/clinic_logo2.jpg";
this.ngOnInit();
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    });
    
  }
 
  // getLastId()
  // {
  //   this.apimicroservice.getLastClinicID().subscribe(res => {
  //     //   
  //     if(res['data']['data'] == '')
  //     {
  //       this.clinicMasterForm.controls['clinicId'].setValue('1');
  //     }
  //     if (res['status']) {
  //       let clinicIdd = res['data'][0].clinicId;
  //       this.clinicMasterForm.controls['clinicId'].setValue(clinicIdd + 1);
  //     } else {
  //       this.apimicroservice.openSnackbar("Something went wrong");
  //     }
     
  //   })
  // }

  deleteClinic(id){
    this.apimicroservice.deleteClinicData(id).subscribe(res => {
      this.fetchClinicList();
      if (res.status) {
        this.apimicroservice.openSnackbar(res.message);
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
      this.clinicMasterForm.reset();
      // this.myimage ="../../assets/images/avatar.jpg";
   
      this.submitButton=true;
      this.updateButton = false;
     
      this.deleteButton=false;
     
    });
   }
  onPageChange(event) {
    this.spinner.show();
    this.page = event;
   // pg = this.page;
   this.searchText = this.clinicMasterForm.get('searchClinic').value;
   let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getClinicData(this.page,this.size,this.searchText,clinicRef).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.clinicData = res.data.clinicData;
        this.totalLength=res.data.totalItems;
      } else {
        this.apimicroservice.openSnackbar("Something went wrong");
      }

    })
  }
  // onKeyUpEvent(event: any) {
  //   //
  //   this.searchText = this.clinicMasterForm.get('searchClinic').value;
    
  //     this.apimicroservice.getClinicData(this.page,this.size,this.searchText).subscribe((res) => {
  //         this.clinicData = res.data.clinicData;
       
  //         this.totalLength = res.data.totalItems;
  //       });
  // }
 
  redirect(to): void {
    this.router.navigate([to]);
  }
  
  onChange($event: Event) {
    const file = ($event.target as HTMLInputElement).files[0];
    const fileName = file.name;
    this.errormessage = '';
    let ext = fileName.substring(fileName.lastIndexOf(".") + 1);
    if (
      ext.toLowerCase() == "png" ||
      ext.toLowerCase() == "jpg" ||
      ext.toLowerCase() == "jpeg" ||
      ext.toLowerCase() == "csv" ||
      ext.toLowerCase() == "xlsx" ||
      ext.toLowerCase() == "pdf" ||
      ext.toLowerCase() == "docx" 
    ) { 
      this.convertToBase64(file);
    }
  }
  cancelUpdate(){ 
    this.submitButton=true;
    this.updateButton=false;
    this.cancelButton=false;
    this.logoIcon ="../../assets/clinic_logo2.jpg";
    this.ngOnInit();
    this.clinicMasterForm.reset();

  }
  convertToBase64(file: File) {
    const observable = new Observable((subscriber: Subscriber<any>) => {
      this.readFile(file, subscriber);
    });
    observable.subscribe((d) => {
      this.logoIcon = d;   
    });
  }
  readFile(file: File, subscriber: Subscriber<any>) {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      subscriber.next(fileReader.result);
      subscriber.complete();
    };
    fileReader.onerror = (error) => {
      subscriber.error(error);
      subscriber.complete();
    };
  }
  // toClinicForm() { 
  //   document.getElementById("toClinicForm").scrollIntoView();
  // }
  // toClinicList(){
  //   document.getElementById("toClinicTable").scrollIntoView();
  // }
  toOpenTimeChange()
  {

    let payload = this.clinicMasterForm.value;
    let dd = new Date();
    let a = '';
    let b ='';
    a = payload.openTime;
    b = payload.closeTime;
    //
    //
    
    const date1 = new Date(dd.toDateString()+" "+a);
    const date2 = new Date(dd.toDateString()+" "+b);
    if(date2 <= date1 && payload.closeTime !== '')
    {
      this.apimicroservice.openSnackbar("Open Time should be Less than Close Time");
      this.clinicMasterForm.controls['openTime'].setValue('');
    }
  }
  toCloseTimeChange()
  {
    
    let payload = this.clinicMasterForm.value;
    let dd = new Date();
    let a = '';
    let b ='';
    a = payload.openTime;
    b = payload.closeTime;
    //
    //
    // const [hh,mm,ss] = a.split(':');
    // const [hh2,mm2,ss2] = b.split(':');
    const date1 = new Date(dd.toDateString()+" "+a);
    const date2 = new Date(dd.toDateString()+" "+b);
    //+" "+a);
    //
    if(date2.getTime() <= date1.getTime() && payload.openTime !== '')
    {
      this.apimicroservice.openSnackbar("Close Time should be Greater than Open Time");
      this.clinicMasterForm.controls['closeTime'].setValue('');
    }
  }
  onback() {
    this.apimicroservice.back()
  }
  WhatsApp()
  {
    let payload = this.clinicMasterForm.value;  
    let num = payload.clinicPhoneNumber; 
    if(num.length == 10)
    {
      sessionStorage.setItem('whatsAppNumber',payload.clinicPhoneNumber);
      let mobileNumber = sessionStorage.getItem('whatsAppNumber');
      window.open("https://wa.me/91"+mobileNumber);
    }
    else
    {
      this.clinicMasterForm.get("clinicPhoneNumber").markAsTouched();
    }
  }
  WhatsApp1()
  {
    let payload = this.clinicMasterForm.value;  
    let num = payload.clinicPhoneNumber1; 
    if(num.length == 10)
    {
      sessionStorage.setItem('whatsAppNumber',payload.clinicPhoneNumber1);
      let mobileNumber = sessionStorage.getItem('whatsAppNumber');
      window.open("https://wa.me/91"+mobileNumber);
    }
    else
    {
      this.clinicMasterForm.get("clinicPhoneNumber1").markAsTouched();
    }
  }
}