import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { Router, ActivatedRoute } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { AfterViewInit, Directive, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { PopUpBranchComponent } from "../pop-up-branch/pop-up-branch.component";
import { PopUpPatientsourceComponent } from "../pop-up-patientsource/pop-up-patientsource.component";
import { PopUpPatientComponent } from "../pop-up-patient/pop-up-patient.component";
import { PatientSearchPopComponent } from "../patient-search-pop/patient-search-pop.component";
import { CommunicationComponent } from "../communication/communication.component";
// import * as states from '../state-city.json'; 
import { HttpClient } from "@angular/common/http";
import { PopupInquryPatientDuplicateComponent } from "../popup-inqury-patient-duplicate/popup-inqury-patient-duplicate.component";
import * as XLSX from "xlsx";
// import panzoom from "panzoom";
declare var google: any;
import * as FileSaver from 'file-saver';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Component({
  selector: 'app-patient-master',
  templateUrl: './patient-master.component.html',
  styleUrls: ['./patient-master.component.scss']
})
export class PatientMasterComponent implements OnInit {
  errormessage: string;
  searchText: any;
  patientImage: any;
  totalLength: number;
  page: number = 1;
  size: number = 10;
  listOfPatients: any;
  pid: any;
  age: Number;
  allState = [
    { name: 'Andaman and Nicobar Islands', value: 'AN' },
    { name: 'Andhra Pradesh', value: 'AP' },
    { name: 'Arunachal Pradesh', value: 'AR' },
    { name: 'Assam', value: 'AS' },
    { name: 'Bihar', value: 'BR' },
    { name: 'Chandigarh', value: 'CH' },
    { name: 'Chhattisgarh', value: 'CT' },
    { name: 'Dadra and Nagar Haveli', value: 'DN' },
    { name: 'Daman and Diu', value: 'DD' },
    { name: 'Delhi', value: 'DL' },
    { name: 'Goa', value: 'GA' },
    { name: 'Gujarat', value: 'GJ' },
    { name: 'Haryana', value: 'HR' },
    { name: 'Himachal Pradesh', value: 'HP' },
    { name: 'Jammu and Kashmir', value: 'JK' },
    { name: 'Jharkhand', value: 'JH' },
    { name: 'Karnataka', value: 'KA' },
    { name: 'Kerala', value: 'KL' },
    { name: 'Ladakh', value: 'LA' },
    { name: 'Lakshadweep', value: 'LD' },
    { name: 'Madhya Pradesh', value: 'MP' },
    { name: 'Maharashtra', value: 'MH' },
    { name: 'Manipur', value: 'MN' },
    { name: 'Meghalaya', value: 'ML' },
    { name: 'Mizoram', value: 'MZ' },
    { name: 'Nagaland', value: 'NL' },
    { name: 'Odisha', value: 'OR' },
    { name: 'Puducherry', value: 'PY' },
    { name: 'Punjab', value: 'PB' },
    { name: 'Rajasthan', value: 'RJ' },
    { name: 'Punjab', value: 'PB' },
    { name: 'Rajasthan', value: 'RJ' },
    { name: 'Sikkim', value: 'SK' },
    { name: 'Tamil Nadu', value: 'TN' },
    { name: 'Telangana', value: 'TG' },
    { name: 'Tripura', value: 'TR' },
    { name: 'Uttar Pradesh', value: 'UP' },
    { name: 'Uttarakhand', value: 'UT' },
    { name: 'West Bengal', value: 'WB' },
  ]

  allPreFix = [
    { name: 'Mr', value: 'Mr' },
    { name: 'Mrs', value: 'Mrs' },
    { name: 'Ms', value: 'Ms' },
    { name: 'Master', value: 'Master' },
    { name: 'Baby', value: 'Baby' },
    { name: 'Adv', value: 'Adv' },
    { name: 'Col', value: 'Col' },
    { name: 'Dr', value: 'Dr' },
    { name: 'Dr(Mrs)', value: 'Dr(Mrs)' },
    { name: 'Dr(Ms)', value: 'Dr(Ms)' },
    { name: 'Dr(Smt)', value: 'Dr(Smt)' },
    { name: 'M/s', value: 'M/s' },
    { name: 'Maj', value: 'Maj' },
    { name: 'Prof', value: 'Prof' },
    { name: 'Shri', value: 'Shri' },
    { name: 'Smt', value: 'Smt' },
  ]
  // allPatientSource=[
  //   {name:'Audiologist',value:'Audiologist'},
  //   { name:'Camp',value:'Camp' },
  //   {name:'Chemist/Pharmacist',value:'Chemist/Pharmacist' },
  //   { name:'Client',value:'Client'},
  //   {name:'Deaf School',value:'Deaf School'},
  //   {name:'Direct Mail',value:'Direct Mail'},
  //     ]
  allPatientSource = [];
  allOccupation = [
    { name: 'Accounting', value: 'Accounting' },
    { name: 'Admin/Clerical/Customer Service', value: 'Admin/Clerical/Customer Service' },
    { name: 'Banking/Finance/Brokerage', value: 'Banking/Finance/Brokerage' },
    { name: 'Construction/Trade', value: 'Construction/Trade' },
    { name: 'Designer', value: 'Designer' },
    { name: 'Food Service/Restaurant/Hospitality', value: 'Food Service/Restaurant/Hospitality' },
    { name: 'Human Resources', value: 'Human Resources' },
    { name: 'Information Technology', value: 'Information Technology' },
    { name: 'Insurance', value: 'Insurance' },
    { name: 'Legal', value: 'Legal' },
    { name: 'Manufacturing/Supply Chain/Purchasing', value: 'Manufacturing/Supply Chain/Purchasing' },
    { name: 'Marketing/Advertising/PR', value: 'Marketing/Advertising/PR' },
    { name: 'Medical/Healthcare', value: 'Medical/Healthcare' },
    { name: 'New Media/Creative Arts/Writers', value: 'New Media/Creative Arts/Writers' },
    { name: 'Sales/Sales Management', value: 'Sales/Sales Management' },
    { name: 'Telecom', value: 'Telecom' },
    { name: 'Other', value: 'Other' },
  ]
  filteredList: { state: string; districts: string; }[];
  filteredList2: { state: string; districts: string; }[]; 
  filteredListDistrict: { district: string; }[];
  filteredListDistrict2: { district: string; }[];
  filteredListPreFix: { name: string; value: string; }[];
  filteredListPatientSource: any;
  filteredListOccupation: { name: string; value: string; }[];
  fieldsOfPatients: any;
  arrayPatients = [{
    //"patientMiddleName": "Yes",
    "patientAlternateName": "Yes",
    "patientClientSource": "Yes",
    //"patientStreetAddress": "Yes",
    "patientPincode": "Yes",
    "patientLandline1": "Yes",
    "patientLandline2": "Yes",
    "patientMobile1": "Yes",
    "patientMobile2": "Yes",
    "patientMobile3": "Yes",
    "patientMobile4": "Yes",
    "patientMobile5": "Yes",
    "patientEmail": "Yes",
    "patientTaluka": "Yes",
    "patientDistrict": "Yes",
    "patientState": "Yes",
    "patientCountry": "Yes",
    "patientAltAddress": "Yes",
    "patientOccupation": "Yes",
    "patientAadhaarNo": "Yes",
    "patientNotes": "Yes",
    "patientDOB": "Yes",
  }];
  patientLength: any;
  readonlyStatus = false;
  branchRef: string;
  clinicRef: string;
  userRef: string;
  userType: string;
  BranchList: any;
  superBranchId: any;
  branchName: any;
  filteredListBranch: any;
  minDate: Date;
  patientList: any;
  filteredListPatient: any;
  isPatient = false;
  isDoctor = false;
  isAdvertiesment = false;
  isSocialMedia = false;
  InquiryId: any;
  isDecease = false;
  patientNo: string;
  bID: any;
  pName: any;
  pAge: any;
  pGender: any;
  pDob = false;
  stateCity: any;
  stateCityArr = [];
  districtData: any;
  stateCityArr2: any[];
  stateCity2: any;
  districtData2: any;
  listOfPatientsExport: any;
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    public route: ActivatedRoute,
    private httpClient: HttpClient
  ) { }
  patientMasterForm: FormGroup;
  inquiryForm: FormGroup;
  status: any;
  submitButton = true;
  updateButton = false;
  deleteButton = false;
  cancelButton = false;
  loggedId: any;

  date: any;
  otherOccupation = false;
  altAddressCheck = false;
  isChecked = false;
  userPermission: any;
  inquiryId: any;
  //permissionList=[];
  patientCreate = true;
  patientUpdate = true;
  isSuperAdmin = true;
  currentdate: any;
  isOther = false;
  // stateData: statesData[] = states; 
  ngOnInit() {
    this.isPatient = false,
    this.isDoctor = false,
    this.isAdvertiesment = false,
    this.isSocialMedia = false,
    this.isOther = false
    let Inquiry = sessionStorage.getItem('InquiryId');
    if (Inquiry == 'true') {
      this.route.queryParams.forEach(params => {
        if (params.InquiryId) {
          this.prepopulateInquiry(params.InquiryId)
        }
      });
    }
    this.currentdate = new Date();
    this.minDate = new Date(1900, 0, 1);
    this.submitButton = true;
    this.updateButton = false;
    // this.filteredList = this.allState.slice(); 
    this.filteredListPreFix = this.allPreFix.slice();
    //this.filteredListPatientSource = this.allPatientSource.slice();
    this.filteredListOccupation = this.allOccupation.slice();
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.userType = sessionStorage.getItem('userType');
    this.branchName = sessionStorage.getItem('branchName');
    this.patientNo = this.route.snapshot.paramMap.get('patientNo');
    ////
    ////
    this.page = 1;
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    ////
    if (this.userType !== 'Super Admin') {
      this.isSuperAdmin = false;
      this.userPermission.forEach((element) => {
        if (element.pageName == 'Patient' && element.create == false) {
          this.patientCreate = false;
        }
        if (element.pageName == 'Patient' && element.update == false) {
          this.patientUpdate = false;
        }
      });
    }
    // ////

    this.patientMasterForm = this.fb.group({
      "branchWiseId": new FormControl(""),
      "prefix": new FormControl(''),
      "firstName": new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z-\\s]*$")]),
      "lastName": new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z-\\s]*$")]),
      "middleName": new FormControl('', [Validators.pattern("^[a-zA-Z-\\s]*$"), Validators.required]),
      "alternateName": new FormControl('', Validators.pattern("^[a-zA-Z-\\s]*$")),
      "dob": new FormControl('',Validators.required),
      "age": new FormControl('', Validators.pattern("^[0-9 ]*$")),
      "sex": new FormControl('', Validators.required),
      "clientSource": new FormControl(''),
      "clientStatus": new FormControl(''),
      "landline1": new FormControl('', Validators.pattern("[0-9]{3,4}[-][0-9]{6,8}")),
      "landline2": new FormControl('', Validators.pattern("[0-9]{3,4}[-][0-9]{6,8}")),
      "mobile1": new FormControl('', [Validators.pattern("(0|91)?[6-9][0-9]{9}")]),
      "mobile2": new FormControl('', Validators.pattern("(0|91)?[6-9][0-9]{9}")),
      "mobile3": new FormControl('', Validators.pattern("(0|91)?[6-9][0-9]{9}")),
      "mobile4": new FormControl('', Validators.pattern("(0|91)?[6-9][0-9]{9}")),
      "mobile5": new FormControl('', Validators.pattern("(0|91)?[6-9][0-9]{9}")),
      "email": new FormControl('', [Validators.email, Validators.pattern("(?![0-9]+@)[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}")]),
      "streetAddress": new FormControl(''),
      "taluka": new FormControl('', Validators.pattern("^[a-zA-Z ]*$")),
      "district": new FormControl(''),
      "state": new FormControl(''),
      "pincode": new FormControl('', [Validators.pattern("^[0-9]{6}$"), Validators.maxLength(6), Validators.minLength(6),]),
      "country": new FormControl('', [
        Validators.pattern("[A-Za-z _-]{3,19}$"),
      ]),
      "streetAddress2": new FormControl(''),
      "taluka2": new FormControl('', Validators.pattern("^[a-zA-Z ]*$")),
      "district2": new FormControl(''),
      "state2": new FormControl(''),
      "pincode2": new FormControl('', [Validators.pattern("^[0-9]{6}$"), Validators.maxLength(6), Validators.minLength(6),]),
      "country2": new FormControl('', [
        Validators.pattern("[A-Za-z _-]{3,19}$"),
      ]),

      "altAddress": new FormControl(''),
      "notes": new FormControl(''),
      "occupation": new FormControl(''),
      "otherOccupation": new FormControl('', Validators.pattern("^[a-zA-Z ]*$")),
      // "audiogram": new FormControl(''),
      "photo": '',
      "aadhaarNo": new FormControl('', Validators.pattern("^[2-9]\\d{3}[\\s-]?\\d{4}[\\s-]?\\d{4}$")),
      "createdAt": new Date(),
      "searchPatient": new FormControl(''),
      "branchRefId": new FormControl(), 
      "clinicRefId": new FormControl(),
      "branchName": new FormControl(),
      "patientdropDown": new FormControl(),
      "doctorDropDown": new FormControl(),
      "advertisement": new FormControl(),
      "socialMedia": new FormControl(),
      "decease": new FormControl(),
      "patientName": new FormControl(),
      "birthdayStatus": new FormControl(''),

    });

    this.patientImage = "../../assets/user2.png";

    this.patientMasterForm.controls["prefix"].setValue('Mr');
    this.patientMasterForm.controls["clientStatus"].setValue("Active");
    this.patientMasterForm.controls["country"].setValue("India");
    this.patientMasterForm.controls["country2"].setValue("India");
    this.patientMasterForm.controls["state"].setValue("Maharashtra");
    this.patientMasterForm.controls["state2"].setValue("Maharashtra");
    this.patientMasterForm.controls["birthdayStatus"].setValue('Not Answered');
    let arr = []
    arr = [
      "Ahmednagar",
      "Akola",
      "Amravati",
      "Aurangabad",
      "Beed",
      "Bhandara",
      "Buldhana",
      "Chandrapur",
      "Dhule",
      "Gadchiroli",
      "Gondia",
      "Hingoli",
      "Jalgaon",
      "Jalna",
      "Kolhapur",
      "Latur",
      "Mumbai City",
      "Mumbai Suburban",
      "Nagpur",
      "Nanded",
      "Nandurbar",
      "Nashik",
      "Osmanabad",
      "Palghar",
      "Parbhani",
      "Pune",
      "Raigad",
      "Ratnagiri",
      "Sangli",
      "Satara",
      "Sindhudurg",
      "Solapur",
      "Thane",
      "Wardha",
      "Washim",
      "Yavatmal"
    ]
    this.getDistrict(arr);
    this.getDistrict2(arr);
    this.getPatient();
    this.fetchPatientList();
    this.fetchPatientSourceList();
    this.getLastId();
    this.fetchPatientSourceList();
    this.fetchAllPatientList();
    if (this.patientNo) {
      this.prepopulation(this.patientNo);
    }
    this.getState();
    this.getState2();
    this.pName = false;
  }
  deceaseClick() {
    let payload = this.patientMasterForm.value;
    if (payload.decease == 'Yes') {
      this.patientMasterForm.controls["clientStatus"].setValue('In-active');
      // this.readonlyStatus = true;
    }
    // else
    // {
    //   this.patientMasterForm.controls["clientStatus"].setValue('Active');
    //   this.readonlyStatus = false;
    // }
  }
  patientStatus() {
    let payload = this.patientMasterForm.value;
    if (payload.clientStatus == 'Active' && payload.decease == 'Yes') {
      this.apimicroservice.openSnackbar("Patient set as deceased, so can't change the status as Active");
      this.patientMasterForm.controls["clientStatus"].setValue('In-active');
    }

  }
  getState() {
    this.httpClient.get("assets/state-city.json").subscribe(data => {
      this.stateCity = data;
      this.stateCityArr = [];
      this.stateCity.states.forEach((element) => {
        this.stateCityArr.push(element);
      });
      this.filteredList = this.stateCityArr.slice();
      ////
    })
  }
  getDistrict(state) {
    ////
    this.districtData = [];
    state.forEach((element) => {
      ////
      this.districtData.push({ "district": element });
    });
    ////
    let arr = [];
    arr = state;
    this.filteredListDistrict = this.districtData.slice();
  }
  getState2() {
    this.httpClient.get("assets/state-city.json").subscribe(data => {
      this.stateCity2 = data;
      this.stateCityArr2 = [];
      this.stateCity2.states.forEach((element) => {
        this.stateCityArr2.push(element);
      });
      this.filteredList2 = this.stateCityArr2.slice();
      ////
    })
  }
  getDistrict2(state) {
    ////
    this.districtData2 = [];
    state.forEach((element) => {
      ////
      this.districtData2.push({ "district": element });
    });
    ////
    let arr = [];
    arr = state;
    this.filteredListDistrict2 = this.districtData2.slice();
  }
  prepopulateInquiry(id) {
    this.spinner.show();
    this.apimicroservice.getInquiryDataById(id).subscribe(res => {
      ////

      this.spinner.hide();
      // return
      // this.submitButton=false;
      //  this.updateButton =true;
      //  this.deleteButton= true;
      // this.cancelButton=true;

      let data = res.data[0];

      this.patientMasterForm = this.fb.group({
        "branchWiseId": new FormControl(data.branchWiseId),
        "prefix": new FormControl(data.prefix),
        "firstName": new FormControl(data.firstName, [Validators.required, Validators.pattern("^[a-zA-Z-\\s]*$")]),
        "lastName": new FormControl(data.lastName, [Validators.required, Validators.pattern("^[a-zA-Z-\\s]*$")]),
        "middleName": new FormControl(data.middleName, [Validators.pattern("^[a-zA-Z-\\s]*$"), Validators.required,]),
        "alternateName": new FormControl(data.alternateName, Validators.pattern("^[a-zA-Z-\\s]*$")),
        "dob": new FormControl(data.dob),
        "age": new FormControl(data.age, Validators.pattern("^[0-9 ]*$")),
        "sex": new FormControl(data.sex, Validators.required),
        "clientSource": new FormControl(data.clientSource),
        "landline1": new FormControl(data.landline1, Validators.pattern("[0-9]{3,4}[-][0-9]{6,8}")),
        "landline2": new FormControl(data.landline2, Validators.pattern("[0-9]{3,4}[-][0-9]{6,8}")),
        "mobile1": new FormControl(data.mobile, [Validators.pattern("(0|91)?[6-9][0-9]{9}")]),
        "mobile2": new FormControl(data.mobile2, Validators.pattern("(0|91)?[6-9][0-9]{9}")),
        "mobile3": new FormControl(data.mobile3, Validators.pattern("(0|91)?[6-9][0-9]{9}")),
        "mobile4": new FormControl(data.mobile4, Validators.pattern("(0|91)?[6-9][0-9]{9}")),
        "mobile5": new FormControl(data.mobile5, Validators.pattern("(0|91)?[6-9][0-9]{9}")),
        "email": new FormControl(data.email, [Validators.email, Validators.pattern("(?![0-9]+@)[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}")]),
        "streetAddress": new FormControl(data.streetAddress),
        "taluka": new FormControl(data.taluka, Validators.pattern("^[a-zA-Z ]*$")),
        "district": new FormControl(data.district),
        "state": new FormControl(data.state),
        "pincode": new FormControl(data.pincode, [Validators.pattern("^[0-9]*$"), Validators.maxLength(6), Validators.minLength(6),]),
        "country": new FormControl(data.country, [
          Validators.pattern("[A-Za-z _-]{3,19}$"),
        ]),
        "streetAddress2": new FormControl(data.streetAddress2),
        "taluka2": new FormControl(data.taluka2, Validators.pattern("^[a-zA-Z ]*$")),
        "district2": new FormControl(data.district2),
        "state2": new FormControl(data.state2),
        "pincode2": new FormControl(data.pincode2, [Validators.pattern("^[0-9]*$"), Validators.maxLength(6), Validators.minLength(6),]),
        "country2": new FormControl(data.country2, [
          Validators.pattern("[A-Za-z _-]{3,19}$"),
        ]),
        "altAddress": new FormControl(data.altAddress),
        "notes": new FormControl(data.notes),
        "occupation": new FormControl(data.occupation),
        "otherOccupation": new FormControl(data.otherOccupation),
        // "audiogram": new FormControl(data.audiogram),
        "photo": '',
        "aadhaarNo": new FormControl(data.aadhaarNo),
        "updatedAt": new Date(),
        "searchPatient": new FormControl(''),
        "branchRefId": new FormControl(data.branchRefId),
        "clinicRefId": new FormControl(data.clinicRefId),
        "branchName": new FormControl(data.branchName),
        "patientdropDown": new FormControl(data.patientdropDown),
        "doctorDropDown": new FormControl(data.doctorDropDown),
        "advertisement": new FormControl(data.advertisement),
        "socialMedia": new FormControl(data.socialMedia),
        "birthdayStatus": new FormControl(data.birthdayStatus),
      });
      //this.getDistrict(data.state);
      this.InquiryId = id;
      this.superBranchId = data.branchWiseId;
      this.bID = data.branchWiseId;
      //  this.myimage = data.image ? data.image : "../../assets/images/setting2.png";
      //  this.myLocationimage = data.locationImage ? data.locationImage : "../../assets/images/no-image-available.png";
    });

    this.prepopulationDataInqury(id);
  }
  prepopulationDataInqury(id) {
    this.apimicroservice.getInquiryDataById(id).subscribe(res => {
      let data = res.data[0];

      this.inquiryForm = this.fb.group({

        "clinicRefId": new FormControl(data.clinicRefId),
        "branchRefId": new FormControl(data.branchRefId),
        "inquiryId": new FormControl(data.inquiryId),
        "branchWiseId": new FormControl(data.branchWiseId),
        "branchName": new FormControl(data.branchName),
        "inquiryType": new FormControl(data.inquiryType, Validators.required),
        "firstName": new FormControl(data.firstName, [Validators.required, Validators.pattern("^[a-zA-Z-\\s]*$")]),
        "lastName": new FormControl(data.lastName, [Validators.required, Validators.pattern("^[a-zA-Z-\\s]*$")]),
        "sex": new FormControl(data.sex, Validators.required),
        "mobile": new FormControl(data.mobile, [Validators.required, Validators.pattern("(0|91)?[6-9][0-9]{9}")]),
        "email": new FormControl(data.email, [Validators.email, Validators.pattern("(?![0-9]+@)[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}")]),
        "age": new FormControl(data.age, [Validators.pattern("^[0-9]*$")]),
        "dob": new FormControl(data.dob),
        "streetAddress": new FormControl(data.streetAddress),
        "taluka": new FormControl(data.taluka, Validators.pattern("^[a-zA-Z ]*$")),
        "district": new FormControl(data.district),
        "state": new FormControl(data.state),
        "country": new FormControl(data.country, [Validators.pattern("[A-Za-z _-]{3,19}$"),]),
        "pincode": new FormControl(data.pincode, [Validators.pattern("^[0-9]{6}$")]),
        "updateddAt": new Date(),
        "searchInquiry": new FormControl(''),
        "redirectToPatient": new FormControl(data.redirectToPatient),
        "convertTopatient": new FormControl(data.convertTopatient),

      });

      this.InquiryId = id;
      this.superBranchId = data.branchWiseId;
      this.bID = data.branchWiseId;
    });


  }
  getAllBranch() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      if (res.status) {
        this.patientMasterForm.get("branchRefId").setValue(res.data[0]._id);
        this.branchClick(res.data[0]._id, this.branchName);
        if (this.userType == 'Super Admin') {
          this.apimicroservice.getBranchById(res.data[0]._id).subscribe(res => {
            this.spinner.hide();
            this.patientMasterForm.get("branchName").setValue(res.data[0].branchName);
          });
        }

      }
    });
  }
  getPatient() {
    this.apimicroservice.getPatientGenericAllData().subscribe(res => {
      ////
      // this.patientLength= res.data.data.length
      if (res.data.length > 0) {
        this.fieldsOfPatients = res.data[0];
        // this.patientLength = 1;
      }
      else {
        this.fieldsOfPatients = this.arrayPatients;
      }

      ////
      ////", res.data);

      if (res.status) {
        //this.apimicroservice.openSnackbar(res.message);  
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }

    });

  }
  submitPatient() {
    //this.setValidor();
    let payload = this.patientMasterForm.value;
    ////

    // if(this.userType == 'Super Admin')
    // {
    //   this.patientMasterForm.controls['branchRefId'].setValidators(Validators.required);
    //   this.patientMasterForm.controls['branchRefId'].updateValueAndValidity();
    // }
    // else{
    //   this.patientMasterForm.controls['branchRefId'].setErrors(null);
    //   this.patientMasterForm.controls['branchRefId'].clearValidators();
    //   this.patientMasterForm.controls['branchRefId'].updateValueAndValidity();
    // }
    this.patientMasterForm.get("dob").markAsTouched();
    if (this.patientMasterForm.invalid) {
      this.patientMasterForm.get("state").markAsTouched();
      this.patientMasterForm.get("district").markAsTouched();
      this.patientMasterForm.get("prefix").markAsTouched();
      this.patientMasterForm.get("firstName").markAsTouched();
      this.patientMasterForm.get("lastName").markAsTouched();
      this.patientMasterForm.get("landline1").markAsTouched();
      this.patientMasterForm.get("sex").markAsTouched();
      this.patientMasterForm.get("middleName").markAsTouched();
      this.patientMasterForm.get("streetAddress").markAsTouched();
      this.patientMasterForm.get("dob").markAsTouched();

      this.patientMasterForm.get("branchRefId").markAsTouched();

      return;
    } else {
      this.addPatient();
    }
    //  if (data.dob) {
    //   var timeDiff = Math.abs(Date.now() - new Date(data.dob).getTime());
    //   let age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
    //   ////
    //   this.earMouldNewForm.controls["age"].setValue(age);
    //     }
  }
  // removeValidator() { 
  //   this.patientMasterForm.controls['prefix'].setErrors(null);
  //   this.patientMasterForm.controls['prefix'].clearValidators();
  //   this.patientMasterForm.controls['prefix'].updateValueAndValidity();

  //   this.patientMasterForm.controls['firstName'].setErrors(null);
  //   this.patientMasterForm.controls['firstName'].clearValidators();
  //   this.patientMasterForm.controls['firstName'].updateValueAndValidity();

  //   this.patientMasterForm.controls['lastName'].setErrors(null);
  //   this.patientMasterForm.controls['lastName'].clearValidators();
  //   this.patientMasterForm.controls['lastName'].updateValueAndValidity();

  //   this.patientMasterForm.controls['dob'].setErrors(null);
  //   this.patientMasterForm.controls['dob'].clearValidators();
  //   this.patientMasterForm.controls['dob'].updateValueAndValidity();

  //   this.patientMasterForm.controls['sex'].setErrors(null);
  //   this.patientMasterForm.controls['sex'].clearValidators();
  //   this.patientMasterForm.controls['sex'].updateValueAndValidity();

  //   this.patientMasterForm.controls['mobile1'].setErrors(null);
  //   this.patientMasterForm.controls['mobile1'].clearValidators();
  //   this.patientMasterForm.controls['mobile1'].updateValueAndValidity();

  //   }
  // setValidor() {   
  //   this.patientMasterForm.controls['prefix'].setValidators(Validators.required);
  //   this.patientMasterForm.controls['prefix'].updateValueAndValidity();


  //   this.patientMasterForm.controls['firstName'].setValidators([Validators.required,Validators.pattern("^[a-zA-Z ]*$")]);
  //   this.patientMasterForm.controls['firstName'].updateValueAndValidity();

  //   this.patientMasterForm.controls['lastName'].setValidators([Validators.required,Validators.pattern("^[a-zA-Z ]*$")]);
  //   this.patientMasterForm.controls['lastName'].updateValueAndValidity();

  //   this.patientMasterForm.controls['dob'].setValidators(Validators.required);
  //   this.patientMasterForm.controls['dob'].updateValueAndValidity();

  //   this.patientMasterForm.controls['sex'].setValidators(Validators.required);
  //   this.patientMasterForm.controls['sex'].updateValueAndValidity();

  //   this.patientMasterForm.controls['mobile1'].setValidators([Validators.required,Validators.pattern("(0|91)?[6-9][0-9]{9}")]);
  //   this.patientMasterForm.controls['mobile1'].updateValueAndValidity();

  //   }
  updatePatientDetails() {
    // this.setValidor();
    // if(this.userType == 'Super Admin')
    // {
    //   this.patientMasterForm.controls['branchRefId'].setValidators(Validators.required);
    //   this.patientMasterForm.controls['branchRefId'].updateValueAndValidity();
    // }
    // else{
    //   this.patientMasterForm.controls['branchRefId'].setErrors(null);
    //   this.patientMasterForm.controls['branchRefId'].clearValidators();
    //   this.patientMasterForm.controls['branchRefId'].updateValueAndValidity();
    // }
    if (this.patientMasterForm.invalid) {
      // this.patientMasterForm.get("prefix").markAsTouched();
      this.patientMasterForm.get("firstName").markAsTouched();
      this.patientMasterForm.get("lastName").markAsTouched();
      this.patientMasterForm.get("dob").markAsTouched();
      this.patientMasterForm.get("sex").markAsTouched();
      this.patientMasterForm.get("middleName").markAsTouched()
      this.patientMasterForm.get("streetAddress").markAsTouched();
      this.patientMasterForm.get("landline1").markAsTouched();
      this.patientMasterForm.get("state").markAsTouched();
      //  this.patientMasterForm.get("branchRefId").markAsTouched();
      return;
    } else {
      this.updatePatient();
    }
  } 
  ageCalculate() {
    let payload = this.patientMasterForm.value;
    ////

    if (payload.dob) {
      var timeDiff = Math.abs(Date.now() - new Date(payload.dob).getTime());
      let age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
      ////
      this.patientMasterForm.controls["age"].setValue(age);
    }

  }
  addPatient() {
    this.spinner.show();
    let payload = this.patientMasterForm.value;
    payload.photo = this.patientImage;
    payload.clinicRefId = this.clinicRef;
    payload.branchRefId = this.branchRef;

    ////
    this.apimicroservice.addPatient(payload).subscribe(res => {
      this.spinner.hide();
      sessionStorage.setItem('InquiryId', 'false');
      this.ngOnInit();

      this.covertToPatient();
      if (res.status) {
        this.apimicroservice.openSnackbar(res.message);
        this.patientImage = "../../assets/user2.png";

      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    }, (ERROR: HttpErrorResponse) => {
      this.apimicroservice.openSnackbar(ERROR.error.message);
      ////
      this.spinner.hide();
    });

  }
  openSnackbar(message: string) {
    return this._snackBar.open(message, "close", {
      verticalPosition: "bottom",
      horizontalPosition: "right",
      duration: 2000,
      panelClass: ["snackbarStyle"],
    });
  }

  getLastId() {
    //   if(this.userType !== 'Super Admin'){
    //   this.apimicroservice.getLastPatientID(this.branchRef).subscribe(res => {
    //     ////   
    //     // if(res['data']['data'] == '')
    //     // {
    //     //   this.patientMasterForm.controls['branchWiseId'].setValue('1');
    //     // }
    //     if (res['status']) {
    //       let clientIdd = res['data']['totalItems'];
    //       this.patientMasterForm.controls['branchWiseId'].setValue(clientIdd + 1);
    //     } else {
    //       this.apimicroservice.openSnackbar("Something went wrong");
    //     }

    //   })
    // }
    // else
    // {
    this.apimicroservice.getLastPatientAll().subscribe(res => {
      ////
      // if(res['data']['data'] == '')
      // {
      //   this.patientMasterForm.controls['branchWiseId'].setValue('1');
      // }
      if (res['status']) {
        let clientIdd = res['data']['totalItems'];
        this.superBranchId = clientIdd + 1;
        this.bID = clientIdd + 1;
        this.patientMasterForm.controls['branchWiseId'].setValue(clientIdd + 1);
      } else {
        this.apimicroservice.openSnackbar("Something went wrong");
      }

    })
    // }
  }

  fetchPatientList() {
    let pg = 1;
    let size = 10;
    let search = ''; 
    this.spinner.show();
    this.searchText = this.patientMasterForm.get('searchPatient').value;
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';

     if(this.userType !== 'Super Admin')
     {
      this.apimicroservice.getPatientList(pg,size,search,branchRef,clinicRef).subscribe(res => {
        this.spinner.hide(); 
          this.listOfPatients = res.data.data;
          this.totalLength = res.data.totalItems;
         if(res.status){
           //this.apimicroservice.openSnackbar(res.message);        
         }else{
           //this.apimicroservice.openSnackbar(res.message);
         }
       });
     }
     else
     {
    this.apimicroservice.getPatientList(pg, size, search, branchRefSuperAdmin, clinicRefSuperAdmin).subscribe(res => {
      this.spinner.hide();
      this.listOfPatients = res.data.data;
      this.listOfPatientsExport = res.data.data;
      this.totalLength = res.data.totalItems;
      if (res.status) {
        //this.apimicroservice.openSnackbar(res.message);        
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    });
    }


  }

  prepopulation(id) {
    this.spinner.show(); 
    this.isDecease = true;
    this.readonlyStatus = true;
    this.apimicroservice.getPatientById(id).subscribe(res => {
      this.spinner.hide();
      let data = res.data[0];
      ////
      this.pid = id;
      if (res.status) {
        this.updateButton = true;
        this.submitButton = false;
        this.cancelButton = true;
        // let date2;
        // if(data.isMigrated == true)
        // {
        //   date2 = new Date(data.dob);
        //   date2.setDate(date2.getDate()+1);
        // }
        // else
        // {
        //  date2 = data.dob;
        // }
          this.patientMasterForm = this.fb.group({
          "branchWiseId": new FormControl(data.branchWiseId),
          "prefix": new FormControl(data.prefix),
          "firstName": new FormControl(data.firstName, [Validators.required, Validators.pattern("^[a-zA-Z-\\s]*$")]),
          "lastName": new FormControl(data.lastName, [Validators.required, Validators.pattern("^[a-zA-Z-\\s]*$")]),
          "middleName": new FormControl(data.middleName, [Validators.pattern("^[a-zA-Z-\\s]*$"), Validators.required]),
          "alternateName": new FormControl(data.alternateName, Validators.pattern("^[a-zA-Z-\\s]*$")),
          "dob": new FormControl(data.dob,Validators.required),
          "age": new FormControl(data.age, Validators.pattern("^[0-9 ]*$")),
          "sex": new FormControl(data.sex, Validators.required),
          "clientSource": new FormControl(data.clientSource),
          "clientStatus": new FormControl(data.clientStatus),
          "landline1": new FormControl(data.landline1, Validators.pattern("[0-9]{3,4}[-][0-9]{6,8}")),
          "landline2": new FormControl(data.landline2, Validators.pattern("[0-9]{3,4}[-][0-9]{6,8}")),
          "mobile1": new FormControl(data.mobile1, [Validators.pattern("(0|91)?[6-9][0-9]{9}")]),
          "mobile2": new FormControl(data.mobile2, Validators.pattern("(0|91)?[6-9][0-9]{9}")),
          "mobile3": new FormControl(data.mobile3, Validators.pattern("(0|91)?[6-9][0-9]{9}")),
          "mobile4": new FormControl(data.mobile4, Validators.pattern("(0|91)?[6-9][0-9]{9}")),
          "mobile5": new FormControl(data.mobile5, Validators.pattern("(0|91)?[6-9][0-9]{9}")),
          "email": new FormControl(data.email, [Validators.email, Validators.pattern("(?![0-9]+@)[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}")]),
          "streetAddress": new FormControl(data.streetAddress),
          "taluka": new FormControl(data.taluka, Validators.pattern("^[a-zA-Z ]*$")),
          "district": new FormControl(data.district),
          "state": new FormControl(data.state),
          "pincode": new FormControl(data.pincode, [Validators.pattern("^[0-9]*$"), Validators.maxLength(6), Validators.minLength(6),]),
          "country": new FormControl(data.country, [
            Validators.pattern("[A-Za-z _-]{3,19}$"),
          ]),
          "streetAddress2": new FormControl(data.streetAddress2),
          "taluka2": new FormControl(data.taluka2, Validators.pattern("^[a-zA-Z ]*$")),
          "district2": new FormControl(data.district2, Validators.pattern("^[a-zA-Z ]*$")),
          "state2": new FormControl(data.state2),
          "pincode2": new FormControl(data.pincode2, [Validators.pattern("^[0-9]*$"), Validators.maxLength(6), Validators.minLength(6),]),
          "country2": new FormControl(data.country2, [
            Validators.pattern("[A-Za-z _-]{3,19}$"),
          ]),
          "altAddress": new FormControl(data.altAddress),
          "notes": new FormControl(data.notes),
          "occupation": new FormControl(data.occupation),
          "otherOccupation": new FormControl(data.otherOccupation),
          // "audiogram": new FormControl(data.audiogram),
          "photo": '',
          "aadhaarNo": new FormControl(data.aadhaarNo),
          "updatedAt": new Date(),
          "searchPatient": new FormControl(''),
          "branchRefId": new FormControl(data.branchRefId),
          "clinicRefId": new FormControl(data.clinicRefId),
          "branchName": new FormControl(data.branchName),
          "patientdropDown": new FormControl(data.patientdropDown),
          "doctorDropDown": new FormControl(data.doctorDropDown),
          "advertisement": new FormControl(data.advertisement),
          "socialMedia": new FormControl(data.socialMedia),
          "decease": new FormControl(data.decease),
          "patientName": new FormControl(data.firstName + ' ' + data.lastName),
          "birthdayStatus": new FormControl(data.birthdayStatus),
        });

        // this.patientMasterForm.controls["decease"].setValue('No');
        this.deceaseClick();
        let prefix;
        let fname;
        let lname;
        let mname;
        if (data.prefix == null) {
          prefix = '';
        }
        else {
          prefix = data.prefix + '. ';
        }
        if (data.firstName == null) {
          fname = '';
        }
        else {
          fname = data.firstName;
        }
        if (data.middleName == null) {
          mname = '';
        }
        else {
          mname = data.middleName;
        }
        if (data.lastName == null) {
          lname = '';
        }
        else {
          lname = data.lastName;
        }
        this.pName = prefix + fname + ' ' + mname + ' ' + lname;
        let date = this.datepipe.transform(new Date(), 'dd-MM');
        let dob = this.datepipe.transform(data.dob, 'dd-MM');
        ////

        if (date == dob) {
          this.pDob = true;
        }
        else {
          this.pDob = false;
        }
        if (data.age == '') {
          this.pAge = '';
        }
        else {
          this.pAge = data.age + '/';
        }
        if (data.sex == "Male") {
          this.pGender = "M";
        }
        else if (data.sex == "Female") {
          this.pGender = "F";
        }

        this.patientClick();
        // this.patientMasterForm.controls["decease"].setValue('No');
        this.superBranchId = data.branchWiseId;
        this.bID = data.branchWiseId;
        if (data.altAddress == 'true' || data.altAddress == true) {
          this.altAddressCheck = true;
          this.isChecked = true;
        }
        else {
          this.altAddressCheck = false;
          this.isChecked = false;

        }

        this.patientImage = data.photo ? data.photo : "../../assets/user2.png"
        this.occupationClick();

      } else {
        //    //this.apimicroservice.openSnackbar(res.message);
      }
    });
  }
  addNew() {
    this.patientMasterForm.reset();
    this.updateButton = false;
    this.deleteButton = false;
  }
  updatePatient() {
    this.spinner.show();
    this.isDecease = false;
    let payload = this.patientMasterForm.value;
    payload.photo = this.patientImage;
    payload.clinicRefId = this.clinicRef;
    // if(this.userType !== 'Super Admin')
    // {
    //   payload.branchRefId = this.branchRef;
    //   payload.clinicRefId = this.clinicRef;
    //   payload.branchName = this.branchName;
    // }

    this.apimicroservice.updatePatientDetails(payload, this.pid).subscribe(res => {
      ////
      // this.isDecease = false;
      this.spinner.hide();
      if (res.status) {
        this.apimicroservice.openSnackbar(res.message);
        this.patientImage = "../../assets/user2.png";
        this.updateButton = false;
        this.submitButton = true;
        this.cancelButton = false;
        this.ngOnInit();
      } else {
        //  //this.apimicroservice.openSnackbar(res.message);
      }
    });

  }

  onPageChange(event) {
    this.spinner.show();
    this.page = event; 
    this.searchText = this.patientMasterForm.get('searchPatient').value;
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';

    if(this.userType !== 'Super Admin')
    {
     this.apimicroservice.getPatientList(this.page,this.size,this.searchText,branchRef,clinicRef).subscribe(res => {
      this.spinner.hide();
        this.listOfPatients = res.data.data;
        this.totalLength = res.data.totalItems;
        //this.buildingLenght = this.listofbuild.length;
       if(res.status){
        //  this.apimicroservice.openSnackbar(res.message);

       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }

     });
    }
    else{ 
    this.apimicroservice.getPatientList(this.page, this.size, this.searchText, branchRefSuperAdmin, clinicRefSuperAdmin).subscribe(res => {
      this.spinner.hide();
      this.listOfPatients = res.data.data;
      this.totalLength = res.data.totalItems;
      //this.buildingLenght = this.listofbuild.length;
      if (res.status) {
        //  this.apimicroservice.openSnackbar(res.message);

      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }

    });
    }
  }

  //   dateFormat(){
  //     let payload =this.patientMasterForm.value;
  //     ////
  //     var Pdob = this.patientMasterForm.value.dob;
  //    // var pattern =
  //    var pattern ="/^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/";
  //    if (Pdob == null || Pdob == "" ||Pdob ==!pattern) {
  //    // errMessage += "Invalid date of birth\n";
  //    //// ;
  //    return false;
  // }
  // else {  
  //     return true
  // }
  //   }


  myValidator() {
    var userBirthDate = this.patientMasterForm.value.dob;
    ////
    var regexVar = /^(0?[1-9]|[12][0-9]|3[01])[\/](0?[1-9]|1[012])[\/]\d{4}$/;
    if (!regexVar.test(userBirthDate)) {
      //alert("Plese enter valid date format dd/mm/yyyy");
      ////
      this.patientMasterForm.controls['dob'].setValidators(Validators.pattern("/^(0?[1-9]|[12][0-9]|3[01])[\/](0?[1-9]|1[012])[\/]\d{4}$/"));
      this.patientMasterForm.controls['dob'].updateValueAndValidity();
    }
    else {
      this.patientMasterForm.controls['dob'].setErrors(null);
      this.patientMasterForm.controls['dob'].clearValidators();
      this.patientMasterForm.controls['dob'].updateValueAndValidity();
      //   alert("Thanks.... You entered correct datee ");
    }
  }
  // myValidator(){
  //var userBirthDate = this.patientMasterForm.value.dob;
  //var regx = /^\d{2}\/\d{2}\/\d{4}$/;
  // if(!regx.test(userBirthDate)){
  //////
  // this.patientMasterForm.controls['dob'].setValidators(Validators.pattern("^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$"));
  //   this.patientMasterForm.controls['dob'].updateValueAndValidity();
  //}
  // else{
  //////
  // this.patientMasterForm.controls['dob'].setErrors(null);
  // this.patientMasterForm.controls['dob'].clearValidators();
  // this.patientMasterForm.controls['dob'].updateValueAndValidity();
  // }

  // }
  onKeyUpEvent(event: any) {
    this.searchText = this.patientMasterForm.get('searchPatient').value;
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';

    if(this.userType !== 'Super Admin')
    {
      ////

     this.apimicroservice.getPatientList(this.page,this.size,this.searchText,branchRef,clinicRef).subscribe(res => {
        ////
        this.listOfPatients = res.data.data;
        this.totalLength = res.data.totalItems;
        //this.buildingLenght = this.listofbuild.length;
       if(res.status){
        //  this.apimicroservice.openSnackbar(res.message);

       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
    }
    else{
    ////
    let pg = 1;
    let size = 10;
    //   this.apimicroservice.getPatientListExport(this.searchText,branchRef,clinicRef).subscribe(res => {
    //     this.listOfPatientsExport = res.data.data;
    //   ////
    //  }); 
    this.apimicroservice.getPatientList(pg, size, this.searchText, branchRefSuperAdmin, clinicRefSuperAdmin).subscribe(res => {
      ////
      this.listOfPatients = res.data.data;
      this.totalLength = res.data.totalItems;
      this.page = res.data.pageNumber;
      //this.buildingLenght = this.listofbuild.length;
      if (res.status) {
        //  this.apimicroservice.openSnackbar(res.message);

      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    });
    if(this.userType !== 'Super Admin'){
      this.apimicroservice.getPatientListExport(this.searchText, branchRef, clinicRef).subscribe(res => {
        this.listOfPatientsExport = res.data.data;
      });
    }else{
    this.apimicroservice.getPatientListExport(this.searchText, branchRefSuperAdmin, clinicRefSuperAdmin).subscribe(res => {
      this.listOfPatientsExport = res.data.data;
    });
  }
    } 
  }

  redirect(to): void {
    this.router.navigate([to]);
  }
  changemob1() {
    let payload = this.patientMasterForm.value;
    if (payload.mobile1 != '') {
      if (
        payload.mobile1 == payload.mobile2 ||
        payload.mobile1 == payload.mobile3 ||
        payload.mobile1 == payload.mobile4 ||
        payload.mobile1 == payload.mobile5
      ) {
        this.apimicroservice.openSnackbar("Mobile number 1 must be different");
        this.patientMasterForm.controls["mobile1"].setValue('');
      }
    }
  }
  changemob2() {
    let payload = this.patientMasterForm.value;
    if (payload.mobile2 != '') {
      if (
        payload.mobile2 == payload.mobile1 ||
        payload.mobile2 == payload.mobile3 ||
        payload.mobile2 == payload.mobile4 ||
        payload.mobile2 == payload.mobile5
      ) {
        this.apimicroservice.openSnackbar("Mobile number 2 must be different");
        this.patientMasterForm.controls["mobile2"].setValue('');
      }
    }
  }
  changemob3() {
    let payload = this.patientMasterForm.value;
    if (payload.mobile3 != '') {
      if (
        payload.mobile3 == payload.mobile1 ||
        payload.mobile3 == payload.mobile2 ||
        payload.mobile3 == payload.mobile4 ||
        payload.mobile3 == payload.mobile5
      ) {
        this.apimicroservice.openSnackbar("Mobile number 3 must be different");
        this.patientMasterForm.controls["mobile3"].setValue('');
      }
    }
  }
  changemob4() {
    let payload = this.patientMasterForm.value;
    if (payload.mobile4 != '') {
      if (
        payload.mobile4 == payload.mobile1 ||
        payload.mobile4 == payload.mobile2 ||
        payload.mobile4 == payload.mobile3 ||
        payload.mobile4 == payload.mobile5
      ) {
        this.apimicroservice.openSnackbar("Mobile number 4 must be different");
        this.patientMasterForm.controls["mobile4"].setValue('');
      }
    }
  }
  changemob5() {
    let payload = this.patientMasterForm.value;
    if (payload.mobile5 != '') {
      if (
        payload.mobile5 == payload.mobile1 ||
        payload.mobile5 == payload.mobile2 ||
        payload.mobile5 == payload.mobile3 ||
        payload.mobile5 == payload.mobile4
      ) {
        this.apimicroservice.openSnackbar("Mobile number 5 must be different");
        this.patientMasterForm.controls["mobile5"].setValue('');
      }
    }
  }

  cancelUpdate() {

    this.patientImage = "../../assets/user2.png";
    this.submitButton = true;
    this.updateButton = false;
    this.cancelButton = false;
    this.isDecease = false;
    this.ngOnInit();
    this.patientMasterForm.reset();
    this.patientMasterForm.controls["clientStatus"].setValue("Active");
  }

  onChange($event: Event) {
    const file = ($event.target as HTMLInputElement).files[0];
    const fileName = file.name;
      this.errormessage = '';
      let ext = fileName.substring(fileName.lastIndexOf(".") + 1);
      if (
        ext.toLowerCase() == "png" ||
        ext.toLowerCase() == "jpg" ||
        ext.toLowerCase() == "jpeg"
      ) {
        this.convertToBase64(file);
      }
  }


  convertToBase64(file: File) {
    const observable = new Observable((subscriber: Subscriber<any>) => {
      this.readFile(file, subscriber);
    });
    observable.subscribe((d) => {
      (d) => {
        this.patientImage = d;
      }
    });
  }

  readFile(file: File, subscriber: Subscriber<any>) {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      subscriber.next(fileReader.result);
      subscriber.complete();
    };
    fileReader.onerror = (error) => {
      subscriber.error(error);
      subscriber.complete();
    };
  }


  occupationClick() {
    let payload = this.patientMasterForm.value;
    if (payload.occupation == 'Other') {
      this.otherOccupation = true;
    }
    else {
      this.otherOccupation = false;
    }

  }
  onChangeAltAddress(e) {
    if (e.checked == true) {
      this.altAddressCheck = true;
      this.isChecked = true;

    }
    else {
      this.altAddressCheck = false;
      this.isChecked = false;
    }
  }
  toPatientForm() {
    document.getElementById("toPatientForm").scrollIntoView();
  }
  toPatientList() {
    document.getElementById("toPatientList").scrollIntoView();
  }
  fetchBranchList() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      ////
      if (res.status) {
        this.BranchList = res.data;
        this.filteredListBranch = this.BranchList.slice();
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
  branchClick(id, branchName) {
    this.patientMasterForm.controls["branchName"].setValue(branchName);

    this.apimicroservice.getBranchById(id).subscribe(res => {
      let payload = this.patientMasterForm.value;
      payload.clinicRefId = res.data[0].clinicRefId;
      this.patientMasterForm.controls["clinicRefId"].setValue(res.data[0].clinicRefId);
      if (this.updateButton == false) {
        this.spinner.show();
        this.apimicroservice.getLastPatientID(payload.branchRefId).subscribe(res => {
          ////
          if (res['status']) {
            let clientIdd = res['data']['totalItems'];
            this.patientMasterForm.controls['branchWiseId'].setValue(clientIdd + 1);
          } else {
            this.apimicroservice.openSnackbar("Something went wrong");
          }
          this.spinner.hide();
        })
      }
    });
  }
  openBranchPopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpBranchComponent,
      {
        width: "1200px",

      });
    dialogRef3.afterClosed().subscribe((result) => {
      this.fetchBranchList();
    })
  }
  openPatientSourcePopUp() {
    //localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpPatientsourceComponent,
      {
        width: "400px",
      });

    dialogRef3.afterClosed().subscribe((result) => { 
      let clinicRef= sessionStorage.getItem('clinicRef');
      let branchRef= sessionStorage.getItem('branchRef');
      let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
      let branchRefSuperAdmin= '';
      if (this.userType !== 'Super Admin') {
      this.apimicroservice.getPatientSourceList(branchRef,clinicRef).subscribe(res => {
        ////
        this.allPatientSource = res.data;
        this.filteredListPatientSource = this.allPatientSource.slice();
        this.patientMasterForm.get("clientSource").setValue(res.data[0].patientSource);
      });
    }else{
      this.apimicroservice.getPatientSourceList(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
        ////
        this.allPatientSource = res.data;
        this.filteredListPatientSource = this.allPatientSource.slice();
        this.patientMasterForm.get("clientSource").setValue(res.data[0].patientSource);
      });
    }
      // this.fetchPatientSourceList();  
    });
  }
  openPatientPopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpPatientComponent,
      {
        width: "1200px",
      });
    dialogRef3.afterClosed().subscribe((result) => {
      this.fetchAllPatientList()
    });
  }
  fetchPatientSourceList() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
    this.apimicroservice.getPatientSourceList(branchRef,clinicRef).subscribe(res => {
      ////
      this.allPatientSource = res.data;
      this.filteredListPatientSource = this.allPatientSource.slice();
    });
  }else{
    this.apimicroservice.getPatientSourceList(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
      ////
      this.allPatientSource = res.data;
      this.filteredListPatientSource = this.allPatientSource.slice();
    });
  }
  }
  controlClick() {
    sessionStorage.setItem("controlClick", "patient");
    this.redirect('/home/generic-setting');
  }
  WhatsApp() {
    let payload = this.patientMasterForm.value;
    let num = payload.mobile1;
    if (num.length == 10) {
      sessionStorage.setItem('whatsAppNumber', payload.mobile1);
      let mobileNumber = sessionStorage.getItem('whatsAppNumber');
      window.open("https://wa.me/91" + mobileNumber);
    }
    else {
      this.patientMasterForm.get("mobile1").markAsTouched();
    }
  }

  WhatsApp2() {
    let payload = this.patientMasterForm.value;
    let num = payload.mobile2;
    if (num.length == 10) {
      sessionStorage.setItem('whatsAppNumber', payload.mobile2);
      let mobileNumber2 = sessionStorage.getItem('whatsAppNumber');
      window.open("https://wa.me/91" + mobileNumber2);
    }
    else {
      this.patientMasterForm.get("mobile2").markAsTouched();
    }
  }

  WhatsApp3() {
    let payload = this.patientMasterForm.value;
    let num = payload.mobile3;
    if (num.length == 10) {
      sessionStorage.setItem('whatsAppNumber', payload.mobile3);
      let mobileNumber3 = sessionStorage.getItem('whatsAppNumber');
      window.open("https://wa.me/91" + mobileNumber3);
    }
    else {
      this.patientMasterForm.get("mobile3").markAsTouched();
    }
  }

  WhatsApp4() {
    let payload = this.patientMasterForm.value;
    let num = payload.mobile4;
    if (num.length == 10) {
      sessionStorage.setItem('whatsAppNumber', payload.mobile4);
      let mobileNumber4 = sessionStorage.getItem('whatsAppNumber');
      window.open("https://wa.me/91" + mobileNumber4);
    }
    else {
      this.patientMasterForm.get("mobile4").markAsTouched();
    }
  }
  WhatsApp5() {
    let payload = this.patientMasterForm.value;
    let num = payload.mobile5;
    if (num.length == 10) {
      sessionStorage.setItem('whatsAppNumber', payload.mobile5);
      let mobileNumber5 = sessionStorage.getItem('whatsAppNumber');
      window.open("https://wa.me/91" + mobileNumber5);
    }
    else {
      this.patientMasterForm.get("mobile5").markAsTouched();
    }
  }
  fetchAllPatientList() {
    this.apimicroservice.getAllPatientlist().subscribe(res => {
      ////
      if (res.status) {
        this.patientList = res.data.data;
        this.filteredListPatient = this.patientList.slice();
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    })
  } 

  patientClick() {
    let payload = this.patientMasterForm.value;
    if (payload.clientSource !== null && payload.clientSource !== '') 
    {
    if (payload.clientSource == 'patient') {
      this.isPatient = true,
        this.isDoctor = false,
        this.isAdvertiesment = false,
        this.isSocialMedia = false,
        this.isOther = false
    }
    else if (payload.clientSource == 'doctor') {
      this.isPatient = false,
        this.isDoctor = true,
        this.isAdvertiesment = false,
        this.isSocialMedia = false,
        this.isOther = false
    }
    else if (payload.clientSource == 'advertisement') {
      this.isPatient = false,
        this.isDoctor = false,
        this.isAdvertiesment = true,
        this.isSocialMedia = false,
        this.isOther = false
    }
    else if (payload.clientSource == 'social media') {
      this.isPatient = false,
        this.isDoctor = false,
        this.isAdvertiesment = false,
        this.isSocialMedia = true,
        this.isOther = false
    }
    else {
      this.isPatient = false,
        this.isDoctor = false,
        this.isAdvertiesment = false,
        this.isSocialMedia = false,
        this.isOther = true
    }
  }
  }

  patientNameClick(fname, lname) {
    this.patientMasterForm.controls["patientName"].setValue(fname + ' ' + lname);
    //this.patientMasterForm.controls["branchRefId"].setValue(pId);
    this.apimicroservice.getPatientById(this.pid).subscribe(res => {
      if (res.status) {
        let data = res.data[0];
        ////

        //this.patientMasterForm.controls["emailId"].setValue(data.email);
      }
    });
  }
  covertToPatient() {
    let payload = this.inquiryForm.value;
    // if(this.userType !== 'Super Admin')
    // {
    //   payload.branchRefId = this.branchRef;
    //   payload.clinicRefId = this.clinicRef;
    //   payload.branchName = this.branchName;
    // }
    payload.convertTopatient = 'Yes';
    this.apimicroservice.updateInquiryData(this.InquiryId, payload).subscribe(res => {
      this.router.navigate(['/home/patient-master'], { queryParams: { InquiryId: this.InquiryId } })
      this.ngOnInit();
      if (res.status) {
        this.apimicroservice.openSnackbar(res.message);
        this.submitButton = true;
        this.updateButton = false;
        this.cancelButton = false;
        // this.deleteButton=false;
        //this.ngOnInit();
        // this.inquiryForm.reset();
        // this.fetchInquiry();

      } else {
        this.apimicroservice.openSnackbar(res.message);
      }

    },
    );

  }
  onback() {
    this.apimicroservice.back()
  }
  openDepartmentPopUp() {
    const dialogRef3 = this.dialog.open(PatientSearchPopComponent, {
      width: "97%",
      height: "90%", 
    });

    dialogRef3.afterClosed().subscribe((result) => {
      ////

      this.patientMasterForm.get("patientName").setValue(result.data.firstName + ' ' + result.data.lastName);
      this.patientMasterForm.get("patientdropDown").setValue(result.data._id);
      this.patientNameClick(result.data.firstName, result.data.lastName);

    });
  }
  openCommunicationPopUp(pid) {
    const dialogRef3 = this.dialog.open(CommunicationComponent, {
      width: "70%",
      data: {
        patientId: pid,
      },
    });
  }
  inquiryPatientDuplicateCheck() {
    let payload = this.patientMasterForm.value;
    ////

    this.spinner.show();
    this.apimicroservice.getInquiryPatientDuplicate(payload.firstName, payload.lastName, payload.middleName).subscribe(res => {
      ////
      this.spinner.hide();
      let data = [];
      res.data.data.forEach((element) => {
        ////
        this.patientMasterForm = this.fb.group({
          "branchWiseId": new FormControl(element.branchWiseId),
          "prefix": new FormControl(element.prefix),
          "firstName": new FormControl(element.firstName, [Validators.required, Validators.pattern("^[a-zA-Z-\\s]*$")]),
          "lastName": new FormControl(element.lastName, [Validators.required, Validators.pattern("^[a-zA-Z-\\s]*$")]),
          "middleName": new FormControl(element.middleName, [Validators.pattern("^[a-zA-Z-\\s]*$"), Validators.required,]),
          "alternateName": new FormControl(element.alternateName, Validators.pattern("^[a-zA-Z-\\s]*$")),
          "dob": new FormControl(element.dob),
          "age": new FormControl(element.age, Validators.pattern("^[0-9 ]*$")),
          "sex": new FormControl(element.sex, Validators.required),
          "clientSource": new FormControl(element.clientSource),
          "clientStatus": new FormControl(element.clientStatus),
          "landline1": new FormControl(element.landline1, Validators.pattern("[0-9]{3,4}[-][0-9]{6,8}")),
          "landline2": new FormControl(element.landline2, Validators.pattern("[0-9]{3,4}[-][0-9]{6,8}")),
          "mobile1": new FormControl(element.mobile, [Validators.pattern("(0|91)?[6-9][0-9]{9}"),]),
          "mobile2": new FormControl(element.mobile2, Validators.pattern("(0|91)?[6-9][0-9]{9}")),
          "mobile3": new FormControl(element.mobile3, Validators.pattern("(0|91)?[6-9][0-9]{9}")),
          "mobile4": new FormControl(element.mobile4, Validators.pattern("(0|91)?[6-9][0-9]{9}")),
          "mobile5": new FormControl(element.mobile5, Validators.pattern("(0|91)?[6-9][0-9]{9}")),
          "email": new FormControl(element.email, [Validators.email, Validators.pattern("(?![0-9]+@)[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}")]),
          "streetAddress": new FormControl(element.streetAddress),
          "taluka": new FormControl(element.taluka, Validators.pattern("^[a-zA-Z ]*$")),
          "district": new FormControl(element.district),
          "state": new FormControl(element.state),
          "pincode": new FormControl(element.pincode, [Validators.pattern("^[0-9]*$"), Validators.maxLength(6), Validators.minLength(6),]),
          "country": new FormControl(element.country, [
            Validators.pattern("[A-Za-z _-]{3,19}$"),
          ]),
          "streetAddress2": new FormControl(element.streetAddress2),
          "taluka2": new FormControl(element.taluka2, Validators.pattern("^[a-zA-Z ]*$")),
          "district2": new FormControl(element.district2),
          "state2": new FormControl(element.state2),
          "pincode2": new FormControl(element.pincode2, [Validators.pattern("^[0-9]*$"), Validators.maxLength(6), Validators.minLength(6),]),
          "country2": new FormControl(element.country2, [
            Validators.pattern("[A-Za-z _-]{3,19}$"),
          ]),
          "altAddress": new FormControl(element.altAddress),
          "notes": new FormControl(element.notes),
          "occupation": new FormControl(element.occupation),
          "otherOccupation": new FormControl(element.otherOccupation),
          // "audiogram": new FormControl(element.audiogram),
          "photo": '',
          "aadhaarNo": new FormControl(element.aadhaarNo),
          "updatedAt": new Date(),
          "searchPatient": new FormControl(''),
          "branchRefId": new FormControl(element.branchRefId),
          "clinicRefId": new FormControl(element.clinicRefId),
          "branchName": new FormControl(element.branchName),
          "patientdropDown": new FormControl(element.patientdropDown),
          "doctorDropDown": new FormControl(element.doctorDropDown),
          "advertisement": new FormControl(element.advertisement),
          "socialMedia": new FormControl(element.socialMedia),
        });
      });
      sessionStorage.setItem("copyFromInqury", "no");

    });
  }
  openInquryPatientDuplicatePopUp() {
    let payload = this.patientMasterForm.value;
    let fname = payload.firstName.toLowerCase();
    let lname = payload.lastName.toLowerCase();
    let middleName = payload.middleName.toLowerCase();
    this.apimicroservice.getInquiryPatientDuplicate(fname, lname, middleName).subscribe(res => {
      ////
      let dataa = [];
      dataa = res.data.data;
      if (dataa.length > 0 && payload.firstName !== '') {
        const dialogRef3 = this.dialog.open(PopupInquryPatientDuplicateComponent,
          {
            width: "600px",
            // height: "570px" 
          });
        dialogRef3.afterClosed().subscribe((result) => {
          let copyInqury = sessionStorage.getItem('copyFromInqury');
          if (copyInqury == 'yes') {
            ////

            this.inquiryPatientDuplicateCheck();
          }
        });
      }
    });

  }
  patientListExport()
  {
    this.searchText = this.patientMasterForm.get('searchPatient').value;
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    this.apimicroservice.getPatientListExport(this.searchText,branchRef,clinicRef).subscribe(res => {
        this.listOfPatients = res.data.data;
        this.totalLength = res.data.totalItems;
      ////
     }); 
  }
  export() {
    let payload = this.patientMasterForm.value;
    this.exportAsExcelFile(this.fileName);
  }
  fileName = "Patient List Report.xlsx";
  public exportAsExcelFile(excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.listOfPatientsExport);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }
}
