import { Component, OnInit } from "@angular/core";
import {
  FormGroup, FormControl, Validators, FormBuilder,
} from "@angular/forms";
import { ApiServiceService } from "../api-service.service";
import { Observable, Subscriber } from "rxjs";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import { MatDialog } from '@angular/material/dialog';
import { PopUpBranchComponent } from "../pop-up-branch/pop-up-branch.component";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { PopupBillingcompanyComponent } from "../popup-billingcompany/popup-billingcompany.component";
@Component({
  selector: 'app-generic-setting',
  templateUrl: './generic-setting.component.html',
  styleUrls: ['./generic-setting.component.scss']
})
export class GenericSettingComponent implements OnInit {
  totalLength: any;
  fieldsOfPatients: any;

  private _id: any;
  fieldsOfCalenders: any;
  clinicList: any;
  filteredListClinic: any;
  cid: string;
  userType: string;
  clinicRef: string;
  durationList: any;
  time: any;
  controlStatus: string;
  fromPage = 'controlPanel';
  errormessage: string;
  logoIcon: any;
  allDays = [
    { name: 'Sunday', value: 'Sunday' },
    { name: 'Monday', value: 'Monday' },
    { name: 'Tuesday', value: 'Tuesday' },
    { name: 'Wednesday', value: 'Wednesday' },
    { name: 'Thursday', value: 'Thursday' },
    { name: 'Friday', value: 'Friday' },
    { name: 'Saturday', value: 'Saturday' },
  ]
  allState = [
    { name: 'Andaman and Nicobar Islands', value: 'AN' },
    { name: 'Andhra Pradesh', value: 'AP' },
    { name: 'Arunachal Pradesh', value: 'AR' },
    { name: 'Assam', value: 'AS' },
    { name: 'Bihar', value: 'BR' },
    { name: 'Chandigarh', value: 'CH' },
    { name: 'Chhattisgarh', value: 'CT' },
    { name: 'Dadra and Nagar Haveli', value: 'DN' },
    { name: 'Daman and Diu', value: 'DD' },
    { name: 'Delhi', value: 'DL' },
    { name: 'Goa', value: 'GA' },
    { name: 'Gujarat', value: 'GJ' },
    { name: 'Haryana', value: 'HR' },
    { name: 'Himachal Pradesh', value: 'HP' },
    { name: 'Jammu and Kashmir', value: 'JK' },
    { name: 'Jharkhand', value: 'JH' },
    { name: 'Karnataka', value: 'KA' },
    { name: 'Kerala', value: 'KL' },
    { name: 'Ladakh', value: 'LA' },
    { name: 'Lakshadweep', value: 'LD' },
    { name: 'Madhya Pradesh', value: 'MP' },
    { name: 'Maharashtra', value: 'MH' },
    { name: 'Manipur', value: 'MN' },
    { name: 'Meghalaya', value: 'ML' },
    { name: 'Mizoram', value: 'MZ' },
    { name: 'Nagaland', value: 'NL' },
    { name: 'Odisha', value: 'OR' },
    { name: 'Puducherry', value: 'PY' },
    { name: 'Punjab', value: 'PB' },
    { name: 'Rajasthan', value: 'RJ' },
    { name: 'Punjab', value: 'PB' },
    { name: 'Rajasthan', value: 'RJ' },
    { name: 'Sikkim', value: 'SK' },
    { name: 'Tamil Nadu', value: 'TN' },
    { name: 'Telangana', value: 'TG' },
    { name: 'Tripura', value: 'TR' },
    { name: 'Uttar Pradesh', value: 'UP' },
    { name: 'Uttarakhand', value: 'UT' },
    { name: 'West Bengal', value: 'WB' },
  ]
  filteredListDays: ({ name: string; value?: undefined; } | { name: string; value: string; })[];
  filteredList: { name: string; value: string; }[];
  clinicData: any;
  preId: any;
  listOfBranch: any;
  preId2: any;
  fieldsOfHeaders: any;
  fieldsOfFooters: any;

  _rfid: any;
  _rhid: any;
  BranchList = [];
  filteredListBranch: any;
  branchSelect = false;
  userRef: string;
  userName: string;
  repairId: any;
  filteredListBillingCompany: any;
  billingCompanyList: any;
  headerList: any;

  page: number = 1;
  size: number = 5;
  doctorList: any;
  totalLengthHeader: any;
  searchText: any;
  branchRef: string;
  constructor(
    private fb: FormBuilder,
    public apimicroservice: ApiServiceService,
    public router: Router,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    private httpClient: HttpClient,
  ) { }

  patientSubmitButton = true;
  patientUpdateButton = false;
  calenderSubmitButton = true;
  calenderUpdateButton = false
  reportSubmitButton = true;
  reportUpdateButton = false;
  reportFooterSubmitButton = true;
  reportFooterUpdateButton = true;
  isCalender = false;
  isClinic = false;
  isReport = false;
  isRepair = false;
  isPatient = true;
  isBranch = false;
  isVendor = false;
  isDevice = false;
  isBillingCompany = false;
  isUser = false;
  stateCity2: any;
  stateCityArr2: any[];
  filteredList2: { state: string; districts: string; }[];
  filteredListDistrict2: { district: string; }[];
  istrictData2: any;
  districtData2: any;
  isClinicalStaff = false;
  isAppointmentType = false;
  isServices = false;
  isDoctor = false;
  isCourierServiceProvider = false;
  isRole = false;
  isServiceRate = false;
  isAllMasters = true;
  patientSettingForm: FormGroup;
  calenderSettingForm: FormGroup;
  reportSettingForm: FormGroup;
  reportSettingFooterForm: FormGroup;
  repairGenricForm: FormGroup;
  userPermission: any;
  genericSettingCreate = true;
  genericSettingUpdate = true;
  repairSubmitButton = true;
  repairUpdateButton = false;
  calenderID;
  header = true;
  footer = false;
  ngOnInit() {

    this.filteredListDays = this.allDays.slice();
    this.filteredList = this.allState.slice();
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    this.userType = sessionStorage.getItem('userType');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.userRef = sessionStorage.getItem('userRef');
    this.userName = sessionStorage.getItem('userName');
    this.branchRef = sessionStorage.getItem('branchRef');
    this.controlStatus = sessionStorage.getItem('controlClick');
    if (this.userType !== 'Super Admin') {
      this.userPermission.forEach((element) => {
        if (element.pageName == 'Generic Setting' && element.create == false) {
          this.genericSettingCreate = false;
        }
        if (element.pageName == 'Generic Setting' && element.update == false) {
          this.genericSettingUpdate = false;
        }


      });
    }
    this.patientSettingForm = this.fb.group({

      "clinicRefId": new FormControl(''),
      // "patientMiddleName": new FormControl(''),
      "patientAlternateName": new FormControl(''),
      "patientClientSource": new FormControl(''),
      "patientLandline1": new FormControl(''),
      "patientLandline2": new FormControl(''),
      "patientMobile1": new FormControl(''),
      "patientMobile2": new FormControl(''),
      "patientMobile3": new FormControl(''),
      "patientMobile4": new FormControl(''),
      "patientMobile5": new FormControl(''),
      "patientEmail": new FormControl(''),
      "patientTaluka": new FormControl(''),
      "patientDistrict": new FormControl(''),
      "patientState": new FormControl(''),
      "patientCountry": new FormControl(''),
      "patientAltAddress": new FormControl(''),
      "patientOccupation": new FormControl(''),
      "patientAadhaarNo": new FormControl(''),
      "patientNotes": new FormControl(''),
      "patientStreetAddress": new FormControl(''),
      "patientPincode": new FormControl(''),
      "patientDOB": new FormControl(''),
    
    });

    this.calenderSettingForm = this.fb.group({
      "duration": new FormControl(''),
      "calenderAppointmentType": new FormControl(''),
      "calenderClinicBranch": new FormControl(''),
      "calenderDoctorName": new FormControl(''),
      "defaultView": new FormControl(''),

      // "dailyCalender": new FormControl(''),
      // "weeklyCalender": new FormControl(''),
      // "monthlyCalender": new FormControl(''),
    });
    this.reportSettingForm = this.fb.group({
      // "clinicId": new FormControl(''),
      "billingRefId": new FormControl('', Validators.required),
      "billingCompanyName": new FormControl(''),
      "reportName": new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z_@./#&+-\\s]*$")]),
      "reportPhoneNumber": new FormControl('', [Validators.required, Validators.pattern("(0|91)?[7-9][0-9]{9}")]),
      "reportPhoneNumber1": new FormControl('', [Validators.pattern("(0|91)?[7-9][0-9]{9}")]),
      //  "clinicType": new FormControl('',[Validators.required]),
      "reportEmail": new FormControl('', [Validators.pattern("(?![0-9]+@)[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}")]),
      "reportWebsite": new FormControl('',Validators.required),
      // , Validators.pattern("^((http|https)://)[-a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)$")
      "openTime": new FormControl(''),
      "closeTime": new FormControl(''),
      "reportPinCode": new FormControl('', [Validators.required, Validators.pattern("^[0-9]{6}$")]),
      "taluka": new FormControl('', Validators.pattern("^[a-zA-Z ]*$")),
      "district": new FormControl('', Validators.pattern("^[a-zA-Z ]*$")),
      "state": new FormControl(''),
      "reportClinicLandline": new FormControl('', Validators.pattern("[0-9]{3,4}[-][0-9]{6,8}")),
      "country": new FormControl('', [
        Validators.pattern("[A-Za-z _-]{3,19}$"),
      ]),
      "reportAddress": new FormControl('', [Validators.required]),
      "reportWeeklyOff": new FormControl('', [Validators.required]),
      "createdAt": new Date(),
      "uploadLogo": '',
      // "bankName":new FormControl('',Validators.pattern("^[a-zA-Z_-\\s]*$")),
      // "bankBranchName":new FormControl('',Validators.pattern("^[a-zA-Z_-\\s]*$")),
      // "accountNumber":new FormControl('',[Validators.pattern("^[0-9]{9,18}$"),Validators.maxLength(18),Validators.minLength(9),]),
      // "ifcCode":new FormControl('',Validators.pattern("^[A-Za-z]{4}0[A-Z0-9a-z]{6}$")),
      // "upiId":new FormControl('',Validators.pattern("^[A-Za-z0-9]{4,}@[A-Z0-9a-z]{4,}$")),
      "bankName":new FormControl('',Validators.pattern("^[a-zA-Z_-\\s]*$")),
      "bankBranchName":new FormControl('',Validators.pattern("^[a-zA-Z_-\\s]*$")),
      "accountNumber":new FormControl('',[Validators.pattern("^[0-9]{9,18}$"),Validators.maxLength(18),Validators.minLength(9),]),
      "ifcCode":new FormControl('',Validators.pattern("^[A-Za-z]{4}0[A-Z0-9a-z]{6}$")),
      "upiId":new FormControl('',Validators.pattern("^[a-zA-Z0-9_@./#&+-\\s]*$")),
      
      "searchHeader": new FormControl(''),
      
      "selectType": new FormControl(''),
    });
    this.reportSettingFooterForm = this.fb.group({
      "clinicBranchName": new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z_@./#&+-\\s]*$")]),
      "place": new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z ]*$")]),
      "date": new FormControl(''),
      "patientName": new FormControl(''),
      "selectType": new FormControl(''),
      "createdAt": new Date(),

    });
    this.repairGenricForm = this.fb.group({
      "repairCalPercentage": new FormControl(''),
      "loggedUserName": new FormControl(''),
      "loggedUserRefId": new FormControl(''),
      "createdAt": new Date(),
    });


    let arr = []
    arr = [
      "Ahmednagar",
      "Akola",
      "Amravati",
      "Aurangabad",
      "Beed",
      "Bhandara",
      "Buldhana",
      "Chandrapur",
      "Dhule",
      "Gadchiroli",
      "Gondia",
      "Hingoli",
      "Jalgaon",
      "Jalna",
      "Kolhapur",
      "Latur",
      "Mumbai City",
      "Mumbai Suburban",
      "Nagpur",
      "Nanded",
      "Nandurbar",
      "Nashik",
      "Osmanabad",
      "Palghar",
      "Parbhani",
      "Pune",
      "Raigad",
      "Ratnagiri",
      "Sangli",
      "Satara",
      "Sindhudurg",
      "Solapur",
      "Thane",
      "Wardha",
      "Washim",
      "Yavatmal"
    ]

    this.header = true;
    this.footer = false;
    this.reportSettingForm.controls["selectType"].setValue('Header');
    this.reportSettingForm.controls["state"].setValue("Maharashtra");
    this.fetchBillingCompanyList();
    this.copyFromClinic();
    this.getPatient();
    this.getCalender();
    this.fetchClinicData();
    this.getReportHeaderData();
    this.getReportFooterData();
    this.getDuration();
    this.getDistrict2(arr);
    this.getState2();
    this.prepopulationRepair(this.userRef);
    //

    this.patientSettingForm.controls["clinicRefId"].setValue(this.clinicRef);
    //
    this.calenderSettingForm.controls["duration"].setValue("30 Minute");
    this.calenderSettingForm.controls["defaultView"].setValue("Month");

    this.patientSettingForm.controls["patientMiddleName"].setValue("Yes");
    this.patientSettingForm.controls["patientAlternateName"].setValue("Yes");
    this.patientSettingForm.controls["patientClientSource"].setValue("Yes");
    this.patientSettingForm.controls["patientLandline1"].setValue("Yes");
    this.patientSettingForm.controls["patientLandline2"].setValue("Yes");
    this.patientSettingForm.controls["patientMobile2"].setValue("Yes");
    this.patientSettingForm.controls["patientMobile1"].setValue("Yes");

    this.patientSettingForm.controls["patientMobile3"].setValue("Yes");
    this.patientSettingForm.controls["patientMobile4"].setValue("Yes");
    this.patientSettingForm.controls["patientMobile5"].setValue("Yes");
    this.patientSettingForm.controls["patientEmail"].setValue("Yes");
    this.patientSettingForm.controls["patientTaluka"].setValue("Yes");
    this.patientSettingForm.controls["patientDistrict"].setValue("Yes");
    this.patientSettingForm.controls["patientState"].setValue("Yes");
    this.patientSettingForm.controls["patientCountry"].setValue("Yes");
    this.patientSettingForm.controls["patientAltAddress"].setValue("Yes");
    this.patientSettingForm.controls["patientOccupation"].setValue("Yes");
    this.patientSettingForm.controls["patientAadhaarNo"].setValue("Yes");
    this.patientSettingForm.controls["patientNotes"].setValue("Yes");
    this.patientSettingForm.controls["patientStreetAddress"].setValue("Yes");
    this.patientSettingForm.controls["patientPincode"].setValue("Yes");
    this.patientSettingForm.controls["patientDOB"].setValue("Yes");

    if (this.controlStatus == 'calander') {
      this.calenderClick();
      this.fromPage = 'calender';
    }
    else if (this.controlStatus == 'patient') {
      this.patientClick()
      this.fromPage = 'patient';
    }
    else if (this.controlStatus == 'controlPanel') {
      // this.patientClick()
      this.fromPage = 'controlPanel';
    }

    //
    this.getReportHeaderData();
  }

  getState2() {
    this.httpClient.get("assets/state-city.json").subscribe(data => {
      this.stateCity2 = data;
      this.stateCityArr2 = [];
      this.stateCity2.states.forEach((element) => {
        this.stateCityArr2.push(element);
      });
      this.filteredList2 = this.stateCityArr2.slice();
      //
    })
  }
  getDistrict2(state) {
    //
    this.districtData2 = [];
    state.forEach((element) => {
      //
      this.districtData2.push({ "district": element });
    });
    //
    let arr = [];
    arr = state;
    this.filteredListDistrict2 = this.districtData2.slice();
  }
  toOpenTimeChange() {

    let payload = this.reportSettingForm.value;
    let dd = new Date();
    let a = '';
    let b = '';
    a = payload.openTime;
    b = payload.closeTime;
    //
    //

    const date1 = new Date(dd.toDateString() + " " + a);
    const date2 = new Date(dd.toDateString() + " " + b);
    if (date2 <= date1 && payload.closeTime !== '') {
      this.apimicroservice.openSnackbar("Open Time should be Less than Close Time");
      this.reportSettingForm.controls['openTime'].setValue('');
    }
  }
  toCloseTimeChange() {

    let payload = this.reportSettingForm.value;
    let dd = new Date();
    let a = '';
    let b = '';
    a = payload.openTime;
    b = payload.closeTime;
    //
    //
    // const [hh,mm,ss] = a.split(':');
    // const [hh2,mm2,ss2] = b.split(':');
    const date1 = new Date(dd.toDateString() + " " + a);
    const date2 = new Date(dd.toDateString() + " " + b);
    // + " " + a);
    //
    if (date2.getTime() <= date1.getTime() && payload.openTime !== '') {
      this.apimicroservice.openSnackbar("Close Time should be Greater than Open Time");
      this.reportSettingForm.controls['closeTime'].setValue('');
    }
  }
  onChange($event: Event) {
    const file = ($event.target as HTMLInputElement).files[0];
    const fileName = file.name;
    this.errormessage = '';
    let ext = fileName.substring(fileName.lastIndexOf(".") + 1);
    if (
      ext.toLowerCase() == "png" ||
      ext.toLowerCase() == "jpg" ||
      ext.toLowerCase() == "jpeg" ||
      ext.toLowerCase() == "csv" ||
      ext.toLowerCase() == "xlsx" ||
      ext.toLowerCase() == "pdf" ||
      ext.toLowerCase() == "docx" 
    ) { 
      this.convertToBase64(file);
    }
  }
  convertToBase64(file: File) {
    const observable = new Observable((subscriber: Subscriber<any>) => {
      this.readFile(file, subscriber);
    });
    observable.subscribe((d) => {
      this.logoIcon = d;
    });
  }
  readFile(file: File, subscriber: Subscriber<any>) {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      subscriber.next(fileReader.result);
      subscriber.complete();
    };
    fileReader.onerror = (error) => {
      subscriber.error(error);
      subscriber.complete();
    };
  }
  fetchClinicData() {
    this.cid = '';
    this.apimicroservice.getClinicList(this.cid).subscribe(res => {
      //
      this.clinicList = res.data;
      this.patientSettingForm.controls["clinicRefId"].setValue(res.data[0]._id);
      this.filteredListClinic = this.clinicList.slice();
      this.onSelectClinic(res.data[0]._id);
      if (res.status) {
        //this.apimicroservice.openSnackbar(res.message);

      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    });
  }
  onSelectClinic(id) {
    //
  }

  patientClick() {
    this.isPatient = true;
    this.isCalender = false;
    this.isReport = false;
    this.isRepair = false;
    this.isClinic = false;
    this.isBranch = false;
    this.isVendor = false;
    this.isDevice = false;
    this.isBillingCompany = false;
    this.isUser = false;
    this.isClinicalStaff = false;
    this.isAppointmentType = false;
    this.isServices = false;
    this.isDoctor = false;
    this.isCourierServiceProvider = false;
    this.isRole = false;
    this.isServiceRate = false;

  }
  getPatient() {
    this.apimicroservice.getPatientGenericAllData().subscribe(res => {
      //  //
      //  //
      if (res.data.length > 0) {
        this._id = res.data[0]._id;
        this.fieldsOfPatients = res.data[0];
      }


      if (res.status) {
        //  this.apimicroservice.openSnackbar(res.message);

      } else {

      }
      if (res.data[0] == null) {
        this.patientSubmitButton = true;
        this.patientUpdateButton = false;
      } else {

        this.patientPrepopulation();
      }

    });

  }

  submitPatient() {
    // this.spinner.show();
    let payload = this.patientSettingForm.value;


    this.apimicroservice.savePatientGenericData(payload).subscribe(res => {
      // this.spinner.hide();
      // //

      this.ngOnInit();
      if (res.status) {
        this.apimicroservice.openSnackbar(res.message);

      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    })
    //   (ERROR:HttpErrorResponse) => {
    //     this.apimicroservice.openSnackbar(ERROR.error.message);
    //    //
    //    this.spinner.hide();
    // });
  }

  patientPrepopulation() {
    // this.spinner.show();
    this.apimicroservice.getPatientGenericDataById(this._id,).subscribe(res => {
      // this.spinner.hide();
      let data = res.data[0];
      //
      if (res.status) {
        this.patientSubmitButton = false;
        this.patientUpdateButton = true;


        this.patientSettingForm = this.fb.group({
          "clinicRefId": new FormControl(data.clinicRefId),
          "patientMiddleName": new FormControl(data.patientMiddleName),
          "patientAlternateName": new FormControl(data.patientAlternateName),
          "patientClientSource": new FormControl(data.patientClientSource),
          "patientLandline1": new FormControl(data.patientLandline1),
          "patientLandline2": new FormControl(data.patientLandline2),
          "patientMobile2": new FormControl(data.patientMobile2),
          "patientMobile1": new FormControl(data.patientMobile1),

          "patientMobile3": new FormControl(data.patientMobile3),
          "patientMobile4": new FormControl(data.patientMobile4),
          "patientMobile5": new FormControl(data.patientMobile5),
          "patientEmail": new FormControl(data.patientEmail),
          "patientTaluka": new FormControl(data.patientTaluka),
          "patientDistrict": new FormControl(data.patientDistrict),
          "patientState": new FormControl(data.patientState),
          "patientCountry": new FormControl(data.patientCountry),
          "patientAltAddress": new FormControl(data.patientAltAddress),
          "patientOccupation": new FormControl(data.patientOccupation),
          "patientAadhaarNo": new FormControl(data.patientAadhaarNo),
          "patientNotes": new FormControl(data.patientNotes),
          "patientStreetAddress": new FormControl(data.patientStreetAddress),
          "patientPincode": new FormControl(data.patientPincode),
          "patientDOB": new FormControl(data.patientDOB),
        });

      } else {
        //    //this.apimicroservice.openSnackbar(res.message);
      }
    });
  }

  updatePatient() {
    // this.spinner.show();
    let payload = this.patientSettingForm.value;

    //  //
    this.apimicroservice.updatePatientGenericData(payload, this._id).subscribe(res => {
      //
      //  this.spinner.hide();
      if (res.status) {
        this.apimicroservice.openSnackbar(res.message);

        this.ngOnInit();
      } else {
        //  //this.apimicroservice.openSnackbar(res.message);
      }
    });

  }
  updateRepair() {
    // this.spinner.show();
    let payload = this.repairGenricForm.value;

    //  //
    this.apimicroservice.updateRepairGenericData(payload, this.repairId).subscribe(res => {
      //
      //  this.spinner.hide();
      if (res.status) {
        this.apimicroservice.openSnackbar(res.message);

        this.ngOnInit();
      } else {
        //  //this.apimicroservice.openSnackbar(res.message);
      }
    });

  }
  calenderClick() {
    this.isCalender = true;
    this.isPatient = false;
    this.isReport = false;
    this.isRepair = false;
    this.isClinic = false;
    this.isBranch = false;
    this.isVendor = false;
    this.isDevice = false;
    this.isBillingCompany = false;
    this.isUser = false;
    this.isClinicalStaff = false;
    this.isAppointmentType = false;
    this.isServices = false;
    this.isDoctor = false;
    this.isCourierServiceProvider = false;
    this.isRole = false;
    this.isServiceRate = false;


  }
  repairClick() {
    this.isCalender = false;
    this.isPatient = false;
    this.isReport = false;
    this.isRepair = true;
    this.isClinic = false;
    this.isBranch = false;
    this.isVendor = false;
    this.isDevice = false;
    this.isBillingCompany = false;
    this.isUser = false;
    this.isClinicalStaff = false;
    this.isAppointmentType = false;
    this.isServices = false;
    this.isDoctor = false;
    this.isCourierServiceProvider = false;
    this.isRole = false;
    this.isServiceRate = false;
  }
  reportClick() {
    let payload = this.reportSettingForm.value;
    let payload2 = this.reportSettingFooterForm.value;
    this.isReport = true;
    this.isRepair = false;
    this.isPatient = false;
    this.isCalender = false;
    this.isClinic = false;
    this.isBranch = false;
    this.isVendor = false;
    this.isDevice = false;
    this.isBillingCompany = false;
    this.isUser = false;
    this.isClinicalStaff = false;
    this.isAppointmentType = false;
    this.isServices = false;
    this.isDoctor = false;
    this.isCourierServiceProvider = false;
    this.isRole = false;
    this.isServiceRate = false;
    payload.selectType = 'Header'
    payload2.selectType = 'Header'
    //  this.header = true;
    //this.reportSettingForm.controls["selectType"].setValue('Header');
    // this.copyFromClinic();
    this.getReportHeaderData();
  }
  getCalender() {
    this.apimicroservice.getAppointGenericAllData().subscribe(res => {
      //
      // //

      if (res.data.length > 0) {
        this.calenderID = res.data[0]._id;
        this.fieldsOfCalenders = res.data[0];
        this.calenderPrepopulation();
        if (res.status) {
          // this.apimicroservice.openSnackbar(res.message);

        } else {
          //this.apimicroservice.openSnackbar(res.message);
        }
      }
      else {
        this.calenderSubmitButton = true;
        this.calenderUpdateButton = false;

      }




    });
  }

  submitCalender() {

    // this.spinner.show();
    let payload = this.calenderSettingForm.value;

    payload.duration = this.time;
    this.apimicroservice.saveAppointGenericData(payload).subscribe(res => {
      // this.spinner.hide();
      //

      this.ngOnInit();
      if (res.status) {
        this.apimicroservice.openSnackbar(res.message);

      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    })
    //   (ERROR:HttpErrorResponse) => {
    //     this.apimicroservice.openSnackbar(ERROR.error.message);
    //    //
    //    this.spinner.hide();
    // });
  }

  getDuration() {
    this.apimicroservice.getDurationListAll().subscribe(res => {
      if (res.status) {
        //
        //
        this.durationList = res.data;

      }
    });
  }
  onSelectduration(duration) {
    this.time = duration.value;
    //

  }



  updateCalender() {

    // this.spinner.show();
    let payload = this.calenderSettingForm.value;
    payload.duration = this.time;
    //
    this.apimicroservice.updateAppointGenericData(payload, this.calenderID).subscribe(res => {
      //
      //  this.spinner.hide();
      if (res.status) {
        this.apimicroservice.openSnackbar(res.message);

        this.ngOnInit();
      } else {

      }
    });

  }

  calenderPrepopulation() {

    // this.spinner.show();
    this.apimicroservice.getAppointGenericDataById(this.calenderID).subscribe(res => {
      // this.spinner.hide();
      let data = res.data[0];

      if (res.status) {
        this.calenderSubmitButton = false;
        this.calenderUpdateButton = true;


        this.calenderSettingForm = this.fb.group({

          "duration": new FormControl(data.duration),
          "calenderAppointmentType": new FormControl(data.calenderAppointmentType),
          "calenderClinicBranch": new FormControl(data.calenderClinicBranch),
          "calenderDoctorName": new FormControl(data.calenderDoctorName),
          "defaultView": new FormControl(data.defaultView)
        });

      } else {
        //    //this.apimicroservice.openSnackbar(res.message);
      }
    });

  }


  clinicClick() {
    this.isPatient = false;
    this.isClinic = true;
    this.isBranch = false;
    this.isVendor = false;
    this.isDevice = false;
    this.isBillingCompany = false;
    this.isUser = false;
    this.isClinicalStaff = false;
    this.isAppointmentType = false;
    this.isServices = false;
    this.isDoctor = false;
    this.isCourierServiceProvider = false;
    this.isRole = false;
    this.isServiceRate = false;

  }
  branchClick() {
    this.isPatient = false;
    this.isClinic = false;
    this.isBranch = true;
    this.isVendor = false;
    this.isDevice = false;
    this.isBillingCompany = false;
    this.isUser = false;
    this.isClinicalStaff = false;
    this.isAppointmentType = false;
    this.isServices = false;
    this.isDoctor = false;
    this.isCourierServiceProvider = false;
    this.isRole = false;
    this.isServiceRate = false;

  }
  vendorClick() {
    this.isPatient = false;
    this.isClinic = false;
    this.isBranch = false;
    this.isVendor = true;
    this.isDevice = false;
    this.isBillingCompany = false;
    this.isUser = false;
    this.isClinicalStaff = false;
    this.isAppointmentType = false;
    this.isServices = false;
    this.isDoctor = false;
    this.isCourierServiceProvider = false;
    this.isRole = false;
    this.isServiceRate = false;

  }
  deviceClick() {
    this.isPatient = false;
    this.isClinic = false;
    this.isBranch = false;
    this.isVendor = false;
    this.isDevice = true;
    this.isBillingCompany = false;
    this.isUser = false;
    this.isClinicalStaff = false;
    this.isAppointmentType = false;
    this.isServices = false;
    this.isDoctor = false;
    this.isCourierServiceProvider = false;
    this.isRole = false;
    this.isServiceRate = false;

  }
  billingCompanyClick() {
    this.isPatient = false;
    this.isClinic = false;
    this.isBranch = false;
    this.isVendor = false;
    this.isDevice = false;
    this.isBillingCompany = true;
    this.isUser = false;
    this.isClinicalStaff = false;
    this.isAppointmentType = false;
    this.isServices = false;
    this.isDoctor = false;
    this.isCourierServiceProvider = false;
    this.isRole = false;
    this.isServiceRate = false;

  }
  userClick() {
    this.isPatient = false;
    this.isClinic = false;
    this.isBranch = false;
    this.isVendor = false;
    this.isDevice = false;
    this.isBillingCompany = false;
    this.isUser = true;
    this.isClinicalStaff = false;
    this.isAppointmentType = false;
    this.isServices = false;
    this.isDoctor = false;
    this.isCourierServiceProvider = false;
    this.isRole = false;
    this.isServiceRate = false;

  }
  clinicalStaffClick() {
    this.isPatient = false;
    this.isClinic = false;
    this.isBranch = false;
    this.isVendor = false;
    this.isDevice = false;
    this.isBillingCompany = false;
    this.isUser = false;
    this.isClinicalStaff = true;
    this.isAppointmentType = false;
    this.isServices = false;
    this.isDoctor = false;
    this.isCourierServiceProvider = false;
    this.isRole = false;
    this.isServiceRate = false;

  }
  appointmentTypeClick() {
    this.isPatient = false;
    this.isClinic = false;
    this.isBranch = false;
    this.isVendor = false;
    this.isDevice = false;
    this.isBillingCompany = false;
    this.isUser = false;
    this.isClinicalStaff = false;
    this.isAppointmentType = true;
    this.isServices = false;
    this.isDoctor = false;
    this.isCourierServiceProvider = false;
    this.isRole = false;
    this.isServiceRate = false;

  }
  servicesClick() {
    this.isPatient = false;
    this.isClinic = false;
    this.isBranch = false;
    this.isVendor = false;
    this.isDevice = false;
    this.isBillingCompany = false;
    this.isUser = false;
    this.isClinicalStaff = false;
    this.isAppointmentType = false;
    this.isServices = true;
    this.isDoctor = false;
    this.isCourierServiceProvider = false;
    this.isRole = false;
    this.isServiceRate = false;

  }
  doctorClick() {
    this.isPatient = false;
    this.isClinic = false;
    this.isBranch = false;
    this.isVendor = false;
    this.isDevice = false;
    this.isBillingCompany = false;
    this.isUser = false;
    this.isClinicalStaff = false;
    this.isAppointmentType = false;
    this.isServices = false;
    this.isDoctor = true;
    this.isCourierServiceProvider = false;
    this.isRole = false;
    this.isServiceRate = false;

  }
  courierServiceProviderClick() {
    this.isPatient = false;
    this.isClinic = false;
    this.isBranch = false;
    this.isVendor = false;
    this.isDevice = false;
    this.isBillingCompany = false;
    this.isUser = false;
    this.isClinicalStaff = false;
    this.isAppointmentType = false;
    this.isServices = false;
    this.isDoctor = false;
    this.isCourierServiceProvider = true;
    this.isRole = false;
    this.isServiceRate = false;

  }
  roleClick() {
    this.isPatient = false;
    this.isClinic = false;
    this.isBranch = false;
    this.isVendor = false;
    this.isDevice = false;
    this.isBillingCompany = false;
    this.isUser = false;
    this.isClinicalStaff = false;
    this.isAppointmentType = false;
    this.isServices = false;
    this.isDoctor = false;
    this.isCourierServiceProvider = false;
    this.isRole = true;
    this.isServiceRate = false;

  }
  serviceRateClick() {
    this.isPatient = false;
    this.isClinic = false;
    this.isBranch = false;
    this.isVendor = false;
    this.isDevice = false;
    this.isBillingCompany = false;
    this.isUser = false;
    this.isClinicalStaff = false;
    this.isAppointmentType = false;
    this.isServices = false;
    this.isDoctor = false;
    this.isCourierServiceProvider = false;
    this.isRole = false;
    this.isServiceRate = true;

  }
  cancelUpdateGenric() {
    this.calenderSettingForm.reset();
    this.calenderSubmitButton = true;
    this.calenderUpdateButton = false;
    this.ngOnInit();
  }
  cancelUpdatePatient() {
    this.patientSettingForm.reset();
    this.patientSubmitButton = true;
    this.patientUpdateButton = false;
    this.ngOnInit();
  }
  selectType(e) {
    if (e == 'Header') {
      this.header = true;
      this.footer = false;
      this.reportSettingForm.controls["selectType"].setValue('Header');
      this.reportSettingFooterForm.controls["selectType"].setValue('Header');
    }
    else {
      this.header = false;
      this.footer = true;
      this.reportSettingForm.controls["selectType"].setValue('Footer');
      this.reportSettingFooterForm.controls["selectType"].setValue('Footer');
    }
  }
  copyFromClinic() {
    let payload = this.reportSettingForm.value;
    let payload2 = this.reportSettingFooterForm.value;


    this.branchSelect = false;
    let pg = 1;
    let size = 5;
    let search = '';
    let clinicRef= sessionStorage.getItem('clinicRef');
    if (this.header == true) {
      let clinicRef= sessionStorage.getItem('clinicRef');
      this.apimicroservice.getClinicData(pg, size, search,clinicRef).subscribe(res => {
        this.clinicData = res.data.clinicData;
        this.totalLength = res.data.totalItems;

        if (this.clinicData.length > 0) {
          this.preId = res.data.clinicData[0]._id;
         // this.prepopulation2(this.preId);
        }
        else {
          this.apimicroservice.openSnackbar("Clinic Is Not Available");
        }
      });
    }
    else {
      this.apimicroservice.getClinicData(pg, size, search,clinicRef).subscribe(res => {
        this.clinicData = res.data.clinicData;
        this.totalLength = res.data.totalItems;

        if (this.clinicData.length > 0) {
          this.preId = res.data.clinicData[0]._id;
          this.prepopulationClinicFooter(this.preId);
        }
        else {
          this.apimicroservice.openSnackbar("Clinic Is Not Available");
        }
      });
    }

  }
  prepopulation2(id) {
    this.apimicroservice.getClinicDataById(id).subscribe(res => {
      let data = res.data[0];
      this.reportSettingForm = this.fb.group({
        "billingCompanyName": new FormControl(data.billingCompanyName),
        "billingRefId": new FormControl(data.billingRefId),
        "reportName": new FormControl(data.clinicName, [Validators.required, Validators.pattern("^[a-zA-Z_@./#&+-\\s]*$")]),
        "reportPhoneNumber": new FormControl(data.clinicPhoneNumber, [Validators.required, Validators.pattern("(0|91)?[7-9][0-9]{9}")]),
        "reportPhoneNumber1": new FormControl(data.clinicPhoneNumber1, [Validators.pattern("(0|91)?[7-9][0-9]{9}")]),
        "reportEmail": new FormControl(data.clinicEmail, [Validators.pattern("(?![0-9]+@)[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}")]),
        "reportWebsite": new FormControl(data.clinicWebsite,Validators.required),
        "openTime": new FormControl(data.openTime),
        "closeTime": new FormControl(data.closeTime),
        "reportPinCode": new FormControl(data.clinicPinCode, [Validators.required, Validators.pattern("^[0-9]{6}$")]),
        "taluka": new FormControl(data.taluka, Validators.pattern("^[a-zA-Z ]*$")),
        "district": new FormControl(data.district, Validators.pattern("^[a-zA-Z ]*$")),
        "state": new FormControl(data.state),
        "reportClinicLandline": new FormControl(data.clinicLandline, Validators.pattern("[0-9]{3,4}[-][0-9]{6,8}")),
        "country": new FormControl(data.country, [
          Validators.pattern("[A-Za-z _-]{3,19}$"),
        ]),
        "reportAddress": new FormControl(data.clinicAddress, [Validators.required]),
        "reportWeeklyOff": new FormControl(data.clinicWeeklyOff, [Validators.required]),
        "updatedAt": new Date(),
        "uploadLogo": '',
      "bankName":new FormControl(data.bankName,Validators.pattern("^[a-zA-Z_-\\s]*$")),
        "bankBranchName":new FormControl(data.bankBranchName,Validators.pattern("^[a-zA-Z_-\\s]*$")),
        "accountNumber":new FormControl(data.accountNumber,[Validators.pattern("^[0-9]{9,18}$"),Validators.maxLength(18),Validators.minLength(9),]),
        "ifcCode":new FormControl(data.ifcCode,Validators.pattern("^[A-Za-z]{4}0[A-Z0-9a-z]{6}$")),
        "upiId":new FormControl(data.upiId,Validators.pattern("^[a-zA-Z0-9_@./#&+-\\s]*$")),
        // "bankName":new FormControl(data.bankName,Validators.pattern("^[a-zA-Z_-\\s]*$")),
        // "bankBranchName":new FormControl(data.bankBranchName,Validators.pattern("^[a-zA-Z_-\\s]*$")),
        // "accountNumber":new FormControl(data.accountNumber,[Validators.pattern("^[0-9]{9,18}$"),Validators.maxLength(18),Validators.minLength(9),]),
        // "ifcCode":new FormControl(data.ifcCode,Validators.pattern("^[A-Za-z]{4}0[A-Z0-9a-z]{6}$")),
        // "upiId":new FormControl(data.upiId,Validators.pattern("^[A-Za-z0-9]{4,}@[A-Z0-9a-z]{4,}$")),
        "searchHeader": new FormControl(''),
        "selectType": new FormControl(data.selectType),
      });
      this.reportSettingForm.controls["selectType"].setValue('Header');
      this.reportSettingFooterForm.controls["selectType"].setValue('Header');
      this.logoIcon = data.uploadLogo ? data.uploadLogo : "../../assets/user2.png"
    });

  }
  prepopulationClinicFooter(id) {
    this.apimicroservice.getClinicDataById(id).subscribe(res => {
      let data = res.data[0];
      this.reportSettingFooterForm = this.fb.group({

        "clinicBranchName": new FormControl(data.clinicName, [Validators.required, Validators.pattern("^[a-zA-Z_@./#&+-\\s]*$")]),
        "place": new FormControl(data.taluka, [Validators.required, Validators.pattern("^[a-zA-Z ]*$")]),
        "date": new FormControl(''),
        "patientName": new FormControl(''),
        "selectType": new FormControl(data.selectType),
        "updatedAt": new Date(),
      });
    });
  }
  prepopulationRepair(id) {
    this.apimicroservice.getRepairGenericDataById(id).subscribe(res => {
      let data = res.data[0];
      //

      if (res.data.length > 0) {
        this.repairSubmitButton = false;
        this.repairUpdateButton = true;
        this.repairGenricForm = this.fb.group({
          "repairCalPercentage": new FormControl(data.repairCalPercentage),
          "loggedUserName": new FormControl(data.loggedUserName),
          "loggedUserRefId": new FormControl(data.loggedUserRefId),
          "updatedAt": new Date(),
        });
        this.repairId = data._id;
      }
    });
  }
  copyFromBranch() {
    this.branchSelect = true;
    this.fetchBranchList();
    // let pg = 1;
    // let size = 5;
    // let search = '';
    // if (this.header == true) {
    //   this.apimicroservice.getBranchList(pg, size, search, this.clinicRef).subscribe(res => {
    //     this.listOfBranch = res.data.branchData;
    //     this.totalLength = res.data.totalItems;
    //     if (this.listOfBranch.length > 0) {
    //       this.preId2 = res.data.branchData[0]._id;
    //       this.prepopulationBranch(this.preId2);
    //     }
    //     else {
    //       this.apimicroservice.openSnackbar("Branch Is Not Available");
    //     }
    //   });
    // }
    // else {
    //   this.apimicroservice.getBranchList(pg, size, search, this.clinicRef).subscribe(res => {
    //     this.listOfBranch = res.data.branchData;
    //     this.totalLength = res.data.totalItems;
    //     if (this.listOfBranch.length > 0) {
    //       this.preId2 = res.data.branchData[0]._id;
    //       this.prepopulationBranchFooter(this.preId2);
    //     }
    //     else {
    //       this.apimicroservice.openSnackbar("Branch Is Not Available");
    //     }
    //   });
    // }
  }
  prepopulationBranch(id) {
    this.apimicroservice.getBranchById(id).subscribe(res => {
      let data = res.data[0];
      //let type = this.reportSettingForm.controls['selectType'].value;
      this.reportSettingForm = this.fb.group({
        "billingCompanyName": new FormControl(data.billingCompanyName),
        "billingRefId": new FormControl(data.billingRefId, Validators.required),
        "reportName": new FormControl(data.branchName, [Validators.required, Validators.pattern("^[a-zA-Z_@./#&+-\\s]*$")]),
        "reportPhoneNumber": new FormControl(data.branchPhoneNumber, [Validators.required, Validators.pattern("(0|91)?[7-9][0-9]{9}")]),
        // "reportPhoneNumber1": new FormControl(data.branchPhoneNumber, [Validators.required, Validators.pattern("(0|91)?[7-9][0-9]{9}")]),
        "reportEmail": new FormControl(data.branchEmailId, [Validators.pattern("(?![0-9]+@)[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}")]),
        // "reportWebsite":new FormControl(data.reportWebsite, [Validators.required,Validators.pattern("^((http|https)://)[-a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)$")]),
        "openTime": new FormControl(data.openTime),
        "closeTime": new FormControl(data.closeTime),
        "reportPinCode": new FormControl(data.branchPinCode, [Validators.required, Validators.pattern("^[0-9]{6}$")]),
        "taluka": new FormControl(data.taluka, Validators.pattern("^[a-zA-Z ]*$")),
        "district": new FormControl(data.district, Validators.pattern("^[a-zA-Z ]*$")),
        "state": new FormControl(data.state),
        "reportClinicLandline": new FormControl(data.branchLandline, Validators.pattern("[0-9]{3,4}[-][0-9]{6,8}")),
        "country": new FormControl(data.country, [
          Validators.pattern("[A-Za-z _-]{3,19}$"),
        ]),
        "reportAddress": new FormControl(data.branchStreetAddress, [Validators.required]),
        "reportWeeklyOff": new FormControl(data.branchWeeklyOff, [Validators.required]),
        "createdAt": new Date(),
        "uploadLogo": '',
        "branchRefId": new FormControl(),

        "bankName":new FormControl(data.bankName,Validators.pattern("^[a-zA-Z_-\\s]*$")),
        "bankBranchName":new FormControl(data.bankBranchName,Validators.pattern("^[a-zA-Z_-\\s]*$")),
        "accountNumber":new FormControl(data.accountNumber,[Validators.pattern("^[0-9]{9,18}$"),Validators.maxLength(18),Validators.minLength(9),]),
        "ifcCode":new FormControl(data.ifcCode,Validators.pattern("^[A-Za-z]{4}0[A-Z0-9a-z]{6}$")),
        "upiId":new FormControl(data.upiId,Validators.pattern("^[a-zA-Z0-9_@./#&+-\\s]*$")),

        // "bankName":new FormControl(data.bankName,Validators.pattern("^[a-zA-Z_-\\s]*$")),
        // "bankBranchName":new FormControl(data.bankBranchName,Validators.pattern("^[a-zA-Z_-\\s]*$")),
        // "accountNumber":new FormControl(data.accountNumber,[Validators.pattern("^[0-9]{9,18}$"),Validators.maxLength(18),Validators.minLength(9),]),
        // "ifcCode":new FormControl(data.ifcCode,Validators.pattern("^[A-Za-z]{4}0[A-Z0-9a-z]{6}$")),
        // "upiId":new FormControl(data.upiId,Validators.pattern("^[A-Za-z0-9]{4,}@[A-Z0-9a-z]{4,}$")),
        "selectType": new FormControl('Header'),
        "searchHeader": new FormControl(''),
      });
      this.logoIcon = data.uploadLogo ? data.uploadLogo : "../../assets/user2.png"
      // this.getDistrict2();
    });
  }
  prepopulationBranchFooter(id) {
    // let type = this.reportSettingFooterForm.controls['selectType'].value;
    this.apimicroservice.getBranchById(id).subscribe(res => {
      let data = res.data[0];
      this.reportSettingFooterForm = this.fb.group({
        "clinicBranchName": new FormControl(data.branchName, [Validators.required, Validators.pattern("^[a-zA-Z_@./#&+-\\s]*$")]),
        "place": new FormControl(data.taluka, [Validators.required, Validators.pattern("^[a-zA-Z ]*$")]),
        "date": new FormControl(''),
        "patientName": new FormControl(''),
        "selectType": new FormControl('Footer'),
        "branchRefId": new FormControl(),
      });
    });
  }


  getReportHeaderData() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
    this.apimicroservice.getReportHeaderGenericAllData(branchRef,clinicRef).subscribe(res => {
      //
      //  //
      if (res.data.length > 0) {
        this.headerList = res.data;
        this._rhid = res.data[0]._id;
        this.fieldsOfHeaders = res.data[0];
      }
      //

      if (res.status) {
        //  this.apimicroservice.openSnackbar(res.message);

      } else {

      }
      if (res.data.length == 0) {
        this.reportSubmitButton = true;
        this.reportUpdateButton = false;
      } else {

       // this.headerPrepopulation(res.data[0]._id);
      }

    });
  }{
    this.apimicroservice.getReportHeaderGenericAllData(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
      //
      //  //
      if (res.data.length > 0) {
        this.headerList = res.data;
        this._rhid = res.data[0]._id;
        this.fieldsOfHeaders = res.data[0];
      }
      //

      if (res.status) {
        //  this.apimicroservice.openSnackbar(res.message);

      } else {

      }
      if (res.data.length == 0) {
        this.reportSubmitButton = true;
        this.reportUpdateButton = false;
      } else {

       // this.headerPrepopulation(res.data[0]._id);
      }

    });
  }
  }

  getReportFooterData() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
    this.apimicroservice.getReportFooterGenericAllData(branchRef,clinicRef).subscribe(res => {
      //
      //  //
      if (res.data.length > 0) {
        this._rfid = res.data[0]._id;
        this.fieldsOfFooters = res.data[0];
      }

      //
      if (res.status) {
        //  this.apimicroservice.openSnackbar(res.message);

      } else {

      }
      if (res.data.length == 0) {
        this.reportFooterSubmitButton = true;
        this.reportFooterUpdateButton = false;
      } else {

        this.footerPrepopulation();
      }

    });
  }else{
    this.apimicroservice.getReportFooterGenericAllData(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
      //
      //  //
      if (res.data.length > 0) {
        this._rfid = res.data[0]._id;
        this.fieldsOfFooters = res.data[0];
      }

      //
      if (res.status) {
        //  this.apimicroservice.openSnackbar(res.message);

      } else {

      }
      if (res.data.length == 0) {
        this.reportFooterSubmitButton = true;
        this.reportFooterUpdateButton = false;
      } else {

        this.footerPrepopulation();
      }

    });
  }
  }

  headerPrepopulation(id) {
    this.apimicroservice.getReportHeaderGenericDataById(id).subscribe(res => {
      // this.spinner.hide();
      let data = res.data[0];
      //
      if (res.status) {
        this.reportSubmitButton = false;
        this.reportUpdateButton = true;


        this.reportSettingForm = this.fb.group({
          "billingCompanyName": new FormControl(data.billingCompanyName),
          "billingRefId": new FormControl(data.billingRefId, Validators.required),
          "reportName": new FormControl(data.reportName, [Validators.required, Validators.pattern("^[a-zA-Z_@./#&+-\\s]*$")]),
          "reportPhoneNumber": new FormControl(data.reportPhoneNumber, [Validators.required, Validators.pattern("(0|91)?[7-9][0-9]{9}")]),
          "reportPhoneNumber1": new FormControl(data.reportPhoneNumber1, [Validators.pattern("(0|91)?[7-9][0-9]{9}")]),
          "reportEmail": new FormControl(data.reportEmail, [Validators.pattern("(?![0-9]+@)[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}")]),
          "reportWebsite": new FormControl(data.reportWebsite,Validators.required),
          "openTime": new FormControl(data.openTime),
          "closeTime": new FormControl(data.closeTime),
          "reportPinCode": new FormControl(data.reportPinCode, [Validators.required, Validators.pattern("^[0-9]{6}$")]),
          "taluka": new FormControl(data.taluka, Validators.pattern("^[a-zA-Z ]*$")),
          "district": new FormControl(data.district, Validators.pattern("^[a-zA-Z ]*$")),
          "state": new FormControl(data.state),
          "reportClinicLandline": new FormControl(data.reportClinicLandline, Validators.pattern("[0-9]{3,4}[-][0-9]{6,8}")),
          "country": new FormControl(data.country, [
            Validators.pattern("[A-Za-z _-]{3,19}$"),
          ]),
          "reportAddress": new FormControl(data.reportAddress, [Validators.required]),
          "reportWeeklyOff": new FormControl(data.reportWeeklyOff, [Validators.required]),
          "updatedAt": new Date(),
          "uploadLogo": '',
          "branchRefId": new FormControl(),

          "bankName":new FormControl(data.bankName,Validators.pattern("^[a-zA-Z_-\\s]*$")),
          "bankBranchName":new FormControl(data.bankBranchName,Validators.pattern("^[a-zA-Z_-\\s]*$")),
          "accountNumber":new FormControl(data.accountNumber,[Validators.pattern("^[0-9]{9,18}$"),Validators.maxLength(18),Validators.minLength(9),]),
          "ifcCode":new FormControl(data.ifcCode,Validators.pattern("^[A-Za-z]{4}0[A-Z0-9a-z]{6}$")),
          "upiId":new FormControl(data.upiId,Validators.pattern("^[a-zA-Z0-9_@./#&+-\\s]*$")),
          // "bankName":new FormControl(data.bankName,Validators.pattern("^[a-zA-Z_-\\s]*$")),
          // "bankBranchName":new FormControl(data.bankBranchName,Validators.pattern("^[a-zA-Z_-\\s]*$")),
          // "accountNumber":new FormControl(data.accountNumber,[Validators.pattern("^[0-9]{9,18}$"),Validators.maxLength(18),Validators.minLength(9),]),
          // "ifcCode":new FormControl(data.ifcCode,Validators.pattern("^[A-Za-z]{4}0[A-Z0-9a-z]{6}$")),
          // "upiId":new FormControl(data.upiId,Validators.pattern("^[A-Za-z0-9]{4,}@[A-Z0-9a-z]{4,}$")),
          "selectType": new FormControl(data.selectType),
          "searchHeader": new FormControl(''),
        });
        this.logoIcon = data.uploadLogo ? data.uploadLogo : "../../assets/user2.png"

      } else {
        //    //this.apimicroservice.openSnackbar(res.message);
      }
    });
  }

  footerPrepopulation() {
    this.apimicroservice.getReportFooterGenericDataById(this._rfid).subscribe(res => {
      // this.spinner.hide();
      let data = res.data[0];
      //
      if (res.status) {
        this.reportFooterSubmitButton = false;
        this.reportFooterUpdateButton = true;
        this.reportSettingFooterForm = this.fb.group({
          "clinicBranchName": new FormControl(data.clinicBranchName, [Validators.required, Validators.pattern("^[a-zA-Z_@./#&+-\\s]*$")]),
          "place": new FormControl(data.place, [Validators.required, Validators.pattern("^[a-zA-Z ]*$")]),
          "date": new FormControl(data.date),
          "patientName": new FormControl(data.patientName),
          "selectType": new FormControl(data.selectType),
          "branchRefId": new FormControl(),
        });

      } else {
        //    //this.apimicroservice.openSnackbar(res.message);
      }
    });
  }
  submitHeader() {
    //  this.setValidor();
    if (this.reportSettingForm.invalid) {
      this.reportSettingForm.get("billingRefId").markAsTouched(); 
   return;
    } else {
      this.submitReportHeader();
    }
  }
  submitReportHeader() { 
    let payload = this.reportSettingForm.value;
    payload.clinicRefId = sessionStorage.getItem('clinicRef');
    payload.branchRefId = sessionStorage.getItem('branchRef');
    payload.uploadLogo = this.logoIcon; 
    this.apimicroservice.saveReportHeaderGenericData(payload).subscribe(res => {
      if (res.status) {
        this.apimicroservice.openSnackbar(res.message);
        this.ngOnInit();
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    },
      (ERROR:HttpErrorResponse) => {
        this.apimicroservice.openSnackbar(ERROR.error.message);
       //
    });
  }
  updateReportFooter() {
    //  this.setValidor();
    if (this.reportSettingFooterForm.invalid) {
      this.reportSettingFooterForm.get("clinicBranchName").markAsTouched();
      this.reportSettingFooterForm.get("place").markAsTouched();

      return;
    } else {
      this.updateFooter();
    }
  }
  updateReportHeader() {
    let payload = this.reportSettingForm.value;
    payload.uploadLogo = this.logoIcon;
    payload.clinicRefId = sessionStorage.getItem('clinicRef');
    payload.branchRefId = sessionStorage.getItem('branchRef');
    //  //
    this.apimicroservice.updateReportHeaderGenericData(payload,this._rhid).subscribe(res => {
      //
      //  this.spinner.hide();
      if (res.status) {
        this.apimicroservice.openSnackbar(res.message);
        this.ngOnInit();
      } else {
        //  //this.apimicroservice.openSnackbar(res.message);
      }
    });
  }

  cancelUpdateReportHeader() {
    this.reportSettingForm.reset();
    this.reportSubmitButton = true;
    this.reportUpdateButton = false;
    this.ngOnInit();
  }

  submitReportFooter() {
    let payload = this.reportSettingFooterForm.value;


    this.apimicroservice.saveReportFooterGenericData(payload).subscribe(res => {
      // this.spinner.hide();
      // //

      this.ngOnInit();
      if (res.status) {
        this.apimicroservice.openSnackbar(res.message);

      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    })
    //   (ERROR:HttpErrorResponse) => {
    //     this.apimicroservice.openSnackbar(ERROR.error.message);
    //    //
    //    this.spinner.hide();
    // });
  }
  submitRepair() {
    let payload = this.repairGenricForm.value;
    payload.loggedUserName = this.userName;
    payload.loggedUserRefId = this.userRef;
    this.apimicroservice.saveRepairGenericData(payload).subscribe(res => {
 
      if (res.status) {
        this.apimicroservice.openSnackbar(res.message);
        this.ngOnInit();
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
  updateFooter() {
    let payload = this.reportSettingFooterForm.value;

    //  //
    this.apimicroservice.updateReportFooterGenericData(payload, this._rfid).subscribe(res => {
      //
      //  this.spinner.hide();
      if (res.status) {
        this.apimicroservice.openSnackbar(res.message);
        //  this.copyFromClinic();
        this.ngOnInit();
      } else {
        //  //this.apimicroservice.openSnackbar(res.message);
      }
    });

  }

  cancelReportFooter() {
    this.reportSettingFooterForm.reset();
    this.reportFooterSubmitButton = true;
    this.reportFooterUpdateButton = false;
    this.ngOnInit();
  }
  cancelRepair() {
    this.repairGenricForm.reset();
    this.repairSubmitButton = true;
    this.repairUpdateButton = false;
    this.ngOnInit();
  }

  fetchBranchList() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      if (res.status) {
        this.BranchList = res.data;
        this.filteredListBranch = this.BranchList.slice();

      } else {
        //  this.apimicroservice.openSnackbar(res.message);
      }
    })
  }

  onBranchClick(id, bname) {
    if (this.header == true) {

      if (this.BranchList.length > 0) {
        this.preId2 = id;
        this.prepopulationBranch(this.preId2);

      }
      else {
        this.apimicroservice.openSnackbar("Branch Is Not Available");
      }

    }
    else {

      if (this.BranchList.length > 0) {
        this.preId2 = id;
        this.prepopulationBranchFooter(this.preId2);
      }
      else {
        this.apimicroservice.openSnackbar("Branch Is Not Available");
      }

    }
  }
  openBranchPopUp() {

    const dialogRef3 = this.dialog.open(PopUpBranchComponent,
      {
        width: "1200px",
      });
    dialogRef3.afterClosed().subscribe((result) => {
      this.fetchBranchList();
    })
  }
  onback() {
    this.apimicroservice.back()
  }
  fetchBillingCompanyList(){
    let id='';
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getBillingDataById(id,clinicRef).subscribe(res => {
      //
      this.billingCompanyList = res.data;
      this.filteredListBillingCompany= this.billingCompanyList.slice();
      let lenght = res.data.length-1;  
      this.companyClick(res.data[lenght]._id,res.data[lenght].companyName);
      this.reportSettingForm.get("billingRefId").setValue(res.data[lenght]._id);
      if(res.status){
        //this.apimicroservice.openSnackbar(res.message);
      }else{ 
        //this.apimicroservice.openSnackbar(res.message);
      }
    });
  }
  openBillingCompPopUp() {
    const dialogRef3 = this.dialog.open(PopupBillingcompanyComponent,
      {
        width: "1200px",
      });

    dialogRef3.afterClosed().subscribe((result) => {
      let id = '';
      let clinicRef= sessionStorage.getItem('clinicRef');
      this.apimicroservice.getBillingDataById(id,clinicRef).subscribe(res => {

        if (res.status) {
          //this.apimicroservice.openSnackbar(res.message);
          //
          this.billingCompanyList = res.data;
          this.filteredListBillingCompany = this.billingCompanyList.slice();
          this.companyClick(res.data[0]._id, res.data[0].companyName);
          this.reportSettingForm.get("billingRefId").setValue(res.data.data[0]._id);
        } else {
          //this.apimicroservice.openSnackbar(res.message);
        }
      });
      //this.fetchBillingCompanyList();  
    });
  }
  companyClick(bid, bCompanyName) {
    // this.billingCompany = bcname;
    this.reportSettingForm.controls['billingCompanyName'].setValue(bCompanyName);
  }
  onPageChange(event) {
    //  this.spinner.show();
      this.page = event;
      this.searchText = '';
      let clinicRef= sessionStorage.getItem('clinicRef');
      let branchRef= sessionStorage.getItem('branchRef');
      let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
      let branchRefSuperAdmin= '';
       
      if(this.userType !== 'Super Admin')
      {
        this.apimicroservice.getHeaderData(this.page,this.size,this.searchText,branchRef,clinicRef).subscribe(res => {
         // this.spinner.hide(); 
            this.headerList = res.data.data;
            this.totalLengthHeader = res.data.totalItems;
         if(res.status){
          //  this.apimicroservice.openSnackbar(res.message);
         }else{
           //this.apimicroservice.openSnackbar(res.message);
         }
       });
      }
      else{
        this.apimicroservice.getHeaderData(this.page,this.size,this.searchText,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
          //this.spinner.hide(); 
            this.headerList = res.data.data;
            this.totalLengthHeader = res.data.totalItems;
           if(res.status){
            //  this.apimicroservice.openSnackbar(res.message);
           }else{
             //this.apimicroservice.openSnackbar(res.message);
           }
      
         });
      }
    }
    onKeyUpEvent(event: any) {
      //
      
      this.searchText = this.reportSettingForm.get("searchHeader").value; 
      let clinicRef= sessionStorage.getItem('clinicRef');
      let branchRef= sessionStorage.getItem('branchRef');
      let userRef='';
      let page=1;
      let size=5;
      this.apimicroservice.getHeaderData(page,size,this.searchText,branchRef,clinicRef).subscribe(res => {
        if(res.status){
          this.headerList = res.data.data;
          this.totalLengthHeader = res.data.totalItems;
            }else{
              //this.apimicroservice.openSnackbar(res.message);
            }
          });
        //}
    }
}