import { Component, Inject, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  FormArray
} from "@angular/forms";
 import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  MatSnackBar,
} from "@angular/material/snack-bar";
import { MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { PopUpBranchComponent } from "../pop-up-branch/pop-up-branch.component";
import { PopupServiceComponent } from "../popup-service/popup-service.component";
import { PopUpPatientComponent } from "../pop-up-patient/pop-up-patient.component";
import { PatientSearchPopComponent } from '../patient-search-pop/patient-search-pop.component';
import { PopupConfirmationMsgComponent } from "../popup-confirmation-msg/popup-confirmation-msg.component";

@Component({
  selector: 'app-popup-add-device',
  templateUrl: './popup-add-device.component.html',
  styleUrls: ['./popup-add-device.component.scss']
})
export class PopupAddDeviceComponent implements OnInit {
  popupAddDeviceForm: FormGroup;
  date: any;
  listOfAppointment:any;
  totalLength:any;
  appointmentId: any; 
  size=5;
  page=1; 
  searchText: any; 
  submitButton = true;
  updateButton = false;
  allColor: any;
  
  filteredList: { name: string; value: string; }[];
  filteredListAppoinmentType: { name: string; value: string; }[];
  userType: string;
  branchRef: string;
  clinicRef: string;
  BranchList: any;
  isSuperAdmin= true;
  superBranchId: any;
  branchName: string;
  filteredListBranch: any;
  currentdate: Date;
  listOfBilling: any;
  id: any;
  courierList: any;
  filteredListCourier: any;
  disableFromCity = false;
  disableToCity = true;
  tocityList: any;
  cityList: any; 
  fromCity: any;
  toCity: any;
  courierServiceCreate = true;
  branchCreate = true;
  filteredListPatient: any;
  patientList: any;
  totalBillAmt = 0; 
  haDisplay = false;
  serialNoList :any[] = [];
  printData=[
    {
      srNo:1,
      date:'201',
      serviceName:'Test',
      companyName:'A Company',
      rate:100,
     },
     {
      srNo:2,
      date:'202',
      serviceName:'Test',
      companyName:'A Company',
      rate:200,
     },
     {
      srNo:3,
      date:'203',
      serviceName:'Test 2',
      companyName:'A Company',
      rate:100,
     },
     {
      srNo:4,
      date:'204',
      serviceName:'Test 3',
      companyName:'A Company',
      rate:150,
     },                 
  ]
  patientRefId: any;
  appointId: any;
  billingList = [];
  DeviceList2: any;
  filteredListDevice2;
  pagename: any;
  patientDetails: any;
  billRefId: any;
  tempServicArr = [];
  billingInfo = [];
  billingData = [];
  tempData: any[];
  patientCreate = true;
  billNumber: any;
  patientName: any;
  patientRefIdd: any;
  haList: any;
  filteredListHa: any;
  deviceInfo: any;
  way: any;
  isDraft: any;
  billNo: any;
  isBilling: any;
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<PopupAddDeviceComponent>,@Inject(MAT_DIALOG_DATA) public data:any,
  ) { 
    this.patientRefId = data.patientRefId;
    this.appointId = data.appointId;
   // this.paientdetails = JSON.parse(data.patientDetails);
    this.pagename = data.page; 
    dialogRef.disableClose = true;
    this.way=data.way;
    this.isDraft = data.isDraft;
    this.billNo = data.billNo;
    this.isBilling=data.isBilling;
  }
  userPermission:any;
  billingCreate= true;
  billingUpdate= true;
  disableSubmit = true;
  AllDeviceList = [];
  ngOnInit(): void {
    this.disableSubmit = true; 
    this.haDisplay = false;
    if(this.data.patientDetails !== ''){
      this.patientDetails = JSON.parse(this.data.patientDetails);
    }else{
      this.patientDetails = this.data.patientDetails;
    }
   
    this.currentdate = new Date(); 
    this.submitButton = true;
    this.updateButton = false;
    //
    //
    //
    this.page=1;
    this.size = 5;
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.userType = sessionStorage.getItem('userType');
    this.userType = sessionStorage.getItem('userType');
    // this.pagename == 'Billing Page';
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    if(this.userType !== 'Super Admin'){
      this.isSuperAdmin= false;
    this.userPermission.forEach((element) => {
      if(element.pageName == 'Billing Record' && element.create == false)
      {
          this.billingCreate = false;  
      }
      if(element.pageName == 'Billing Record' && element.update == false)
      {
          this.billingUpdate = false;  
      }  
      // if(element.pageName == 'Billing Record' && element.create == false)
      // {
      //     this.patientCreate = false;  
      // }  
    });
  }
  
  //
  let i = 0;
    this.popupAddDeviceForm = this.fb.group({

      "searchBilling": new FormControl(''), 
      "branchRefId": new FormControl(),   
      "branchName":new FormControl(''), 
      "deviceDetails": this.fb.array([]), 
      "serviceInfo": this.fb.array([]), 
      "patientRefId":new FormControl(),
      "patientName": new FormControl(''),
      "appointId":new FormControl(this.appointId),
      "haId":new FormControl(''),
      "haRefId":new FormControl(),
       
    });
     this.addRow(i);
     if(this.userType !== 'Super Admin' && this.billNo == undefined){
      this.fetchPatientList();
      this.getPatient(this.patientRefId);
      this.popupAddDeviceForm.controls["branchName"].setValue(this.branchName);
      this.popupAddDeviceForm.controls["branchRefId"].setValue(this.branchRef);
    }else if(this.userType == 'Super Admin' && this.billNo == undefined){
      this.fetchBranchList();
      this.fetchPatientList();
        this.getPatient(this.patientRefId);
    }
    
    
 if(this.billNo !== undefined)
 {
  this.fetchBranchList();
  this.fetchPatientList(); 
this.getBillById();
 }
//this.BillingForm.controls['date'].setValue(this.currentdate);
}
getBillById()
{ 
this.apimicroservice.getBillDataById(this.billNo).subscribe(res => {
 //

  this.submitButton=false;
   this.updateButton =true; 
  //  this.deleteButton= true;
 // this.cancelButton=true;
   let data = res.data[0];
    
    
     this.popupAddDeviceForm = this.fb.group({

      "searchBilling": new FormControl(''), 
      "branchRefId": new FormControl(data.branchRefId),   
      "branchName":new FormControl(data.branchName), 
      "deviceDetails": this.fb.array([]), 
      "serviceInfo": this.fb.array([]), 
      "patientRefId":new FormControl(data.patientRefId),
      "patientName": new FormControl(data.patientName),
      "appointId":new FormControl(this.appointId),
      "billingCompanyRefId":new FormControl(data.billingCompanyRefId),
      "billingCompanyName":new FormControl(data.billingCompanyName),
      "haId":new FormControl(data.haId),
      "haRefId":new FormControl(data.haRefId),
      "updateddAt":new Date(),
     });
     this.getPatient(data.patientRefId);
     this.billRefId = data._id
     let preArr = [];
    data.deviceInfo.forEach((element,i) => {
  
  
      preArr.push(element);
      //       
      const tmpDict = {};
      tmpDict['_id'] = new FormControl(element._id);
      tmpDict['deviceRefId'] = new FormControl(element.deviceRefId);
      tmpDict['model'] = new FormControl(element.model,Validators.required);
      tmpDict['serialNumber'] = new FormControl(element.serialNumber);
      tmpDict['tempArray'] = new FormControl(element.serialNumber);
      tmpDict['changeModel'] = new FormControl(element.changeModel);
      tmpDict['changeSerialNumber'] = new FormControl(element.changeSerialNumber);
      
      tmpDict['qty'] = new FormControl(element.qty);
      tmpDict['mrp'] = new FormControl(element.mrp,[Validators.pattern("^[0-9 ]*$"),Validators.required]);
      tmpDict['discountType'] = new FormControl(element.discountType);
      tmpDict['discount'] = new FormControl(element.discount); 
      tmpDict['billingCompanyRefId'] = new FormControl(data.billingCompanyRefId);
      tmpDict['billingCompanyName'] = new FormControl(data.billingCompanyName);
      tmpDict['earStyle'] = new FormControl(element.earStyle);
      tmpDict['sgst'] = new FormControl(element.sgst);
      tmpDict['cgst'] = new FormControl(element.cgst);
      tmpDict['igst'] = new FormControl(element.igst);
      tmpDict['hasSac'] = new FormControl(element.hasSac);
      
      const creds = this.popupAddDeviceForm.controls.deviceDetails as FormArray;
       creds.push(new FormGroup(tmpDict)); 
       
       
       
       this.clickModel2(creds.value[i],i,'submit');
      });
      // data.serviceInfo.forEach((element,i) => {
      //   
        
      //       preArr.push(element);
      //       //       
      //       const tmpDict = {};
      //       tmpDict['deviceRefId'] = new FormControl(element.deviceRefId);
      //       tmpDict['model'] = new FormControl(element.model,Validators.required);
      //       tmpDict['serialNumber'] = new FormControl(element.serialNumber);
      //       tmpDict['changeModel'] = new FormControl(element.changeModel);
      //       tmpDict['changeSerialNumber'] = new FormControl(element.changeSerialNumber);
            
      //       tmpDict['qty'] = new FormControl(element.qty);
      //       tmpDict['mrp'] = new FormControl(element.mrp,[Validators.pattern("^[0-9 ]*$"),Validators.required]);
      //       tmpDict['discountType'] = new FormControl(element.discountType);
      //       tmpDict['discount'] = new FormControl(element.discount);
      //       tmpDict['billingCompanyRefId'] = new FormControl(element.billingCompanyRefId);
      //       tmpDict['billingCompanyName'] = new FormControl(element.billingCompanyName);
      //       tmpDict['earStyle'] = new FormControl(element.earStyle);
      //       tmpDict['sgst'] = new FormControl(element.sgst);
      //       tmpDict['cgst'] = new FormControl(element.cgst);
      //       tmpDict['igst'] = new FormControl(element.igst);
      //       tmpDict['hasSac'] = new FormControl(element.hasSac);
            
      //       const creds = this.popupAddDeviceForm.controls.deviceDetails as FormArray;
      //        creds.push(new FormGroup(tmpDict)); 
      //       //  this.clickModel2(data.deviceInfo[i],i,'submit');
      //       });
     
});
}
getPatient(id)
{
  this.apimicroservice.getPatientById(id).subscribe(res => {
    this.spinner.hide();
    let data = res.data[0]; 
    if(this.billNo == undefined)
    {
      this.popupAddDeviceForm.get("patientName").setValue(data.firstName + " " + data.lastName);
    this.popupAddDeviceForm.get("patientRefId").setValue(data._id);
    }


    this.onSelectPatient(
      data
    ); 
    this.fetchHaList(data._id);
   });
}
// this.popupAddDeviceForm.get("patientName").setValue(result.data.firstName + " " + result.data.lastName);
// this.popupAddDeviceForm.get("patientRefId").setValue(result.data._id);
// this.onSelectPatient(
//   result.data
// );
// this.fetchHaList(result.data._id);
fetchBranchList() {
  let clinicRef= sessionStorage.getItem('clinicRef');
  this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
    if (res.status) {  
      //  
      this.BranchList = res.data; 
      this.filteredListBranch= this.BranchList.slice();
      let length =res.data.length - 1;
      this.branchName= res.data[length].branchName;
      this.popupAddDeviceForm.controls["branchRefId"].setValue(res.data[length]._id); 
      if(this.userType == 'Super Admin')
      {  
        this.branchClick(res.data[length]._id,this.branchName);
        this.apimicroservice.getBranchById(res.data[length]._id).subscribe(res => {
          this.spinner.hide();
          this.popupAddDeviceForm.get("branchName").setValue(res.data[length].branchName);
         });
      }
      else
      {
        this.branchClick(this.branchRef,this.branchName);  
      }
      
    } else { 
    //  this.apimicroservice.openSnackbar(res.message);
    } 
  })
} 


fetchPatientList(){
  this.searchText = '';
  let clinicRef= sessionStorage.getItem('clinicRef');
  let branchRef= sessionStorage.getItem('branchRef');
  let currentdate = new Date().toISOString();
  //
    // this.apimicroservice.getAppointmentbydatebranch(currentdate,this.branchRef).subscribe(res => {
      this.apimicroservice.getPatientListByBranchClinic(branchRef,clinicRef).subscribe(res => {
      //   
      if (res.status) {    
        this.patientList = res.data;
        this.filteredListPatient = this.patientList.slice();
         
      } else {
       // this.apimicroservice.openSnackbar(res.message);
      }
    })
  
  // {
  //   let branchRef = '';
  //   let clinicRef = '';
  //     this.apimicroservice.getPatientListByBranchClinic(branchRef,clinicRef).subscribe(res => {
  //     //   
  //     if (res.status) {    
  //       this.patientList = res.data;
  //       this.filteredListPatient = this.patientList.slice();
  //     } else {
  //     }
  //   })
  // }
  
} 

branchClick(id,branchName){
let payload = this.popupAddDeviceForm.value;
  this.popupAddDeviceForm.controls["branchName"].setValue(branchName);
 this.branchRef = id;
 this.branchName = branchName;
 this.popupAddDeviceForm.controls["deviceDetails"].reset();
// this.fetchPatientList();
}

addRow(i) {
  
  const creds = this.popupAddDeviceForm.controls.deviceDetails as FormArray;
      creds.push(
      this.fb.group({
        "deviceRefId":new FormControl('',[Validators.required]), 
        "model": new FormControl(''), 
        "serialNumber": new FormControl(''), 
        "changeModel": new FormControl(''), 
        "changeSerialNumber": new FormControl(''), 
        "qty": new FormControl(1),  
        "mrp": new FormControl('',[Validators.required,Validators.pattern("^[0-9]*$"),Validators.minLength(4)]), 
        "discountType": new FormControl('',Validators.required), 
        "discount":new FormControl('',[Validators.required,Validators.pattern("^[0-9]*$")]),
        "billingCompanyRefId": new FormControl(),
        "earStyle": new FormControl(),
        "billingCompanyName":new FormControl(''),
        "sgst":new FormControl(''),
        "cgst":new FormControl(''),
        "igst":new FormControl(''),
        "hasSac":new FormControl(''),
        "tempArray":new FormControl(''),
     
      toggle: false

})
);
}

deleteRow(index,id){
  //this.dynamicRows.splice(index, 1);
  (<FormArray>this.popupAddDeviceForm.get("deviceDetails")).removeAt(index);

  this.apimicroservice.deleteStock(id,{}).subscribe(res => {
       
    if (res['status']) {
    } else {
      this.apimicroservice.openSnackbar("Something went wrong");
    }
   
  })

}

getLastIDBilling(){
  //
let billComp = '';
let uniqNo:any; 


  if(this.billingInfo.length > 0){ 
        
    this.billingInfo.forEach((element,index) => {
      if(element.billingCompanyRefId !== undefined)
      {
        this.apimicroservice.getLastBillID(element.billingCompanyRefId).subscribe(res => {
          //   
          // if(res['data']['data'] == '')
          // {
          //   this.inquiryForm.controls['branchWiseId'].setValue('1');
          // }
          if (res['status']) {
            billComp = element.billingCompanyName.substring(0,3);
            uniqNo = Math.floor(1000 + Math.random() * 9000);
            let billIdd = res['data']['totalItems'];
            this.billingInfo[index]['billingId'] = billIdd + 1;
            let id = billIdd + 1;
            this.billingInfo[index]['billNo'] = billComp + "-" + uniqNo + id;
            // this.inquiryForm.controls['branchWiseId'].setValue(billIdd + 1);
            //
          } else {
            this.apimicroservice.openSnackbar("Something went wrong");
          }
         
        })
      }
    
    });
  
} 
}

fetchModelList2() {
  let branchRef= sessionStorage.getItem('branchRef');
  // if(this.userType !== 'Super Admin')
  // {
  //   this.apimicroservice.getAllDeviceList(this.branchRef).subscribe(res => {
  //     if (res.status) {    
  //       this.DeviceList2 = res.data.data;
  //       this.filteredListDevice2= this.DeviceList2.slice();
  //       this.DeviceList2.forEach(ele => {
  //         this.billingData.push({"billingCompanyRefId":ele.billingCompanyRefId,"billingCompanyName":ele.billingCompanyName});
  //       });
  //       //

  //       const ids = this.billingData.map(o => o.billingCompanyRefId);
  //       this.billingInfo = this.billingData.filter(({billingCompanyRefId},index) => !ids.includes(billingCompanyRefId,index + 1));
  //       this.getLastIDBilling();
  //     } else {
  //      // this.apimicroservice.openSnackbar(res.message);
  //     }
  //   })
  // }
  // else
  // {
    this.apimicroservice.getAllDeviceList(branchRef).subscribe(res => {
      if (res.status) {     
        this.DeviceList2 = res.data.data;
        //
        this.filteredListDevice2= this.DeviceList2.slice();
        this.DeviceList2.forEach(ele => {
          this.billingData.push({"billingCompanyRefId":ele.billingCompanyRefId,"billingCompanyName":ele.billingCompanyName,"hasSac":ele.has});
        });
        //
  
        const ids = this.billingData.map(o => o.billingCompanyRefId);
        this.billingInfo = this.billingData.filter(({billingCompanyRefId},index) => !ids.includes(billingCompanyRefId,index + 1));
        this.getLastIDBilling();
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    })
  //}
 
}

  prepopulation(id) {
    this.spinner.show();
    this.apimicroservice.getPatientDateHistoryById(this.patientRefId,this.appointId).subscribe(res => {
      this.spinner.hide();
      this.submitButton=false;
       this.updateButton =true;
      //  this.deleteButton= true;
     // this.cancelButton=true;
     if(res.data.length > 0){
       let data = res.data[0];
         this.popupAddDeviceForm = this.fb.group({
          "searchBilling": new FormControl(''), 
          "deviceDetails": this.fb.array([]),
          "branchRefId": new FormControl(), 
          "branchName":new FormControl(''),
          "patientRefId":new FormControl(this.patientRefId),
          "patientName":new FormControl(''),
          "appointId":new FormControl(this.appointId),
          "haId":new FormControl(''),
          "haRefId":new FormControl(),
          "updateddAt":new Date(),
         });

         this.billRefId = data._id
         let preArr = [];
        data.deviceDetails.forEach(element => {
      
          preArr.push(element);
          //       
          const tmpDict = {};
          tmpDict['_id'] = new FormControl(element._id);
          tmpDict['deviceRefId'] = new FormControl(element.deviceRefId);
          tmpDict['model'] = new FormControl(element.model,Validators.required);
          tmpDict['serialNumber'] = new FormControl(element.serialNumber);
          tmpDict['changeModel'] = new FormControl(element.changeModel);
          tmpDict['changeSerialNumber'] = new FormControl(element.changeSerialNumber);
          tmpDict['tempArray'] = new FormControl(element.tempArray);
          tmpDict['qty'] = new FormControl(element.qty);
          tmpDict['mrp'] = new FormControl(element.mrp,[Validators.pattern("^[0-9 ]*$"),Validators.required]);
          tmpDict['discountType'] = new FormControl(element.mrp);
          tmpDict['billingCompanyRefId'] = new FormControl(element.billingCompanyRefId);
          tmpDict['billingCompanyName'] = new FormControl(element.billingCompanyName);
          tmpDict['sgst'] = new FormControl(element.sgst);
          tmpDict['cgst'] = new FormControl(element.cgst);
          tmpDict['igst'] = new FormControl(element.igst);
          tmpDict['hasSac'] = new FormControl(element.hasSac);
          const creds = this.popupAddDeviceForm.controls.deviceDetails as FormArray;
           creds.push(new FormGroup(tmpDict));
           //
          });
     }
     });

    
  }

  topopupAddDeviceForm() {
    document.getElementById("topopupAddDeviceForm").scrollIntoView();
  }
  toBillingLst() {
    document.getElementById("toBillingLst").scrollIntoView();
  }

   
  fetchBillList() {
    let search='';
    this.fetchHaList(this.patientRefId);
    this.apimicroservice.getBillingDataByPatientId(this.patientRefId,this.appointId).subscribe(res => {
      //  
      if (res.status) {    
        this.billingList = res.data;
        this.totalLength=res.data.totalItems;
         

        if(this.billingList.length > 0){
          this.branchRef = this.billingList[0].branchRefId;
          this.billNumber = this.billingList[0].billNo;
          this.patientName = this.billingList[0].patientName;
          this.patientRefIdd = this.billingList[0].patientRefId;
        }else{
          this.branchRef = this.patientDetails.branchRefId;
        }
      //  if(this.billingList.deviceInfo.length > 0){
      //    this.AllDeviceList = this.billingList.deviceInfo
      //  }
      } else {
       // this.apimicroservice.openSnackbar(res.message);
      }
    });
  }

  onPageChange(event) {
    this.page = event;
    let search='';
    let branchRef= sessionStorage.getItem('branchRef');
    this.apimicroservice.getBillingDetailsByPatientId(this.page,this.size,search,this.patientRefId,this.appointId,branchRef).subscribe(res => {
      //  
      if (res.status) {    
        this.billingList = res.data.data;
        this.totalLength=res.data.totalItems;
      } else {
       // this.apimicroservice.openSnackbar(res.message);
      }
    });
    // if (this.userType !== 'Super Admin') {
    //   this.apimicroservice.getDoctorAvailablePaginationData(this.page,this.size,this.branchRef,this.clinicRef).subscribe(res => {
    //     if (res.status) {
    //       this.printData = res.data.data;
    //       this.totalLength=res.data.totalItems;
    //     } else {
    //       this.apimicroservice.openSnackbar("Something went wrong");
    //     }
  
    //   })
    // } 
  }

  printForm()
  {
    this.redirect('print-bill');
  } 
  redirect(to): void {
    this.router.navigate([to]);
   } 
   onClose(): void {
    this.dialogRef.close();
  }
  openServicePopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopupServiceComponent,
      {
        width: "800px",
      }); 
  }
  clickModel(item,i){
    
    
   // this.popupAddDeviceForm.controls["deviceRefId"].setValue(id);
   this.disableSubmit = false;
   //
   let tempservices = [];
   this.serialNoList[i] =[];
   
   let id = item._id;
   let model = item.model;
   let mval = item.mrp;
   let billid = item.billingCompanyRefId;
   let compname = item.billingCompanyName;
   let sgst = item.sgst;
   let cgst = item.cgst;
   let igst = item.igst;
   let has = item.has;
   let srNo=item.serialNumber;
   let dRefId = item.deviceRefId;
   //
   
   let tempsrno = [];
   let tempArr = this.popupAddDeviceForm.get("deviceDetails").value;
   let dstatus = "unsold";
   let draftStatus = "sold";
  // let transferStatus = "In-Transit";
  //  let branchRef = '';
  //  if(this.userType !== 'Super Admin'){
  //   branchRef = this.branchRef;
  //  }else{
  //   branchRef = '';
  //  }
   let control = (<FormArray>this.popupAddDeviceForm.get("deviceDetails")).at(i);
   //

        control.patchValue({
          mrp: mval,
          model: model,
          billingCompanyRefId:billid,
          billingCompanyName:compname,
          sgst:sgst,
          cgst:cgst,
          igst:igst,
          hasSac:has,
          qty: 1, 
          discountType:'Rupees',
          // discount: '0',
          changeModel:'',
          changeSerialNumber:'',
           
      });
  // //
  let isDraft=true;
  let deviceId="";
  let branchRef= sessionStorage.getItem('branchRef');
  this.apimicroservice.getStockMaintainByDeviceIdStatus(id,deviceId,dstatus,branchRef).subscribe(res => {
    //
    let data = res.data;
    if(res.message !== 'No Data Found'){
      //
      res.data.forEach(element =>{ 
        if(element.transferStatus == undefined || element.transferStatus == "" || element.transferStatus == "Accept" || element.transferStatus == "Reject"){
         if(element.serialNumber.includes('No SerialNo'))
         {

         } 
         else
         {
          tempsrno.push(element);
         }
    
        }
       });
    // this.serialNoList[i] = data;
    this.serialNoList[i] = tempsrno;
     let deviceRef = control.get('deviceRefId').value;
    //  if(i > 0){
      tempservices = this.serialNoList[i];
      tempArr.forEach(element =>{
        if(element.deviceRefId == deviceRef){
          tempservices = tempservices.filter(m => m.serialNumber!=element.serialNumber);
        }
      });
      //
      this.serialNoList[i] = tempservices;
     //
     
    //  }
    }
    else
    {
      //
      this.serialNoList[i] = [];
    }
    
  });
 
    
  }
  clickModel2(item,i,e){
    
    
   // this.popupAddDeviceForm.controls["deviceRefId"].setValue(id);
   this.disableSubmit = false;
   //
   let tempservices = [];
   this.serialNoList[i] =[];
   
   let id = item._id;
   let model = item.model;
   let mval = item.mrp;
   let billid = item.billingCompanyRefId;
   let compname = item.billingCompanyName;
   let sgst = item.sgst;
   let cgst = item.cgst;
   let igst = item.igst;
   let has = item.has;
   let srNo=item.serialNumber;
   let dRefId;
   if(e == 'update')
   {
     dRefId = item._id;
   }
   else
   {
     dRefId = item.deviceRefId;
   }
   //
   
   let tempsrno = [];
   let tempArr = this.popupAddDeviceForm.get("deviceDetails").value;
   let dstatus = "unsold";
   let draftStatus = "sold";
  // let transferStatus = "In-Transit";
  //  let branchRef = '';
  //  if(this.userType !== 'Super Admin'){
  //   branchRef = this.branchRef;
  //  }else{
  //   branchRef = '';
  //  }
   let control = (<FormArray>this.popupAddDeviceForm.get("deviceDetails")).at(i);
   //

        control.patchValue({
          mrp: mval,
          model: model,
          billingCompanyRefId:billid,
          billingCompanyName:compname,
          sgst:sgst,
          serialNumber:srNo,
          cgst:cgst,
          igst:igst,
          hasSac:has,
          qty: 1, 
          discountType:'Rupees',
          // discount: '0',
          changeModel:'',
          changeSerialNumber:'',
           
      });
  // //
  let isDraft=true;
  let deviceId="";
  let branchRef= sessionStorage.getItem('branchRef');
  // dstatus
  this.apimicroservice.getStockMaintainByDeviceIdDraft(dRefId,deviceId,dstatus,this.billNo,branchRef).subscribe(res => {
    // 
    let data = res.data;
    if(res.message !== 'No Data Found'){
      //
      res.data.forEach(element =>{ 
        if(element.transferStatus == undefined || element.transferStatus == "" || element.transferStatus == "Accept" || element.transferStatus == "Reject"){
         if(element.serialNumber.includes('No SerialNo'))
         {

         } 
         else
         {
          tempsrno.push(element);
         }
    
        }
       });
    // this.serialNoList[i] = data;
    this.serialNoList[i] = tempsrno;
     let deviceRef = control.get('deviceRefId').value;
     
     
    //  if(i > 0){
    //   tempservices = this.serialNoList[i];
    //   tempArr.forEach(element =>{
    //     if(element.deviceRefId == deviceRef){
    //       tempservices = tempservices.filter(m => m.serialNumber!=element.serialNumber);
    //     }
    //   });
    //   //
    //   this.serialNoList[i] = tempservices;
    //  //
     
    //  }
    }
    else
    {
      //
      this.serialNoList[i] = [];
    }
    this.submitButton = false;
    this.updateButton = true;
    
    
  });
 
 
    
  }

  onSelectSerialNo(srno,status,i){

  }

  updateDeviceBill(){ 
    
    
    let payload = this.popupAddDeviceForm.value;
//     let info = [];
//     let deviceInfo=[];
//     let dataInfo = payload.deviceDetails;
//     dataInfo.forEach(ele =>{
//       info.push({"serviceName":ele.model,"serialNumber":ele.serialNumber,"rate":ele.mrp,"discount":ele.discount,
//       "earStyle":ele.earStyle,"quantity":ele.qty,"status":"unpaid","sgst":ele.sgst,"cgst":ele.cgst,
//       "igst":ele.igst,"hasSac":ele.hasSac,"billingCompanyName":ele.billingCompanyName,"billingCompanyRefId":ele.billingCompanyRefId});

//       deviceInfo.push({"deviceRefId":ele.deviceRefId,"model":ele.model,"qty":ele.qty,"serialNumber":ele.serialNumber,
//       "earStyle":ele.earStyle,"changeModel":'',"changeSerialNumber":'',"mrp":ele.mrp,"discount":ele.discount,
//       "discountType":ele.discountType,"status":"unpaid","sgst":ele.sgst,"cgst":ele.cgst,"igst":ele.igst,
//       "hasSac":ele.hasSac,"billingCompanyName":ele.billingCompanyName,"billingCompanyRefId":ele.billingCompanyRefId});
//  });
//  payload['deviceInfo'] = deviceInfo;
//  payload['serviceInfo'] = info; 
//    
//    

    this.apimicroservice.updateBillingDetails(payload,this.billRefId).subscribe(res => { 
      //  this.spinner.hide();
        //this.ngOnInit();
        if(res.status){
         // this.apimicroservice.openSnackbar(res.message);
         if(this.deviceInfo.length > 0){
          let devicePayload = {};
           
          // this.deviceInfo.forEach((ele) =>{ 
          //   devicePayload['warrantyPeriod'] = ele.warrantyPeriod;
          //   devicePayload['expiryDate'] = ele.expiryDate;  
          //   devicePayload['paymentStatus'] = paymentStatus;  
            // this.apimicroservice.updateSoldDeviceDetails(devicePayload,ele.serialNumber).subscribe(res => {
            //   //
            // });
          // }); 
        }
      }
    });
    this.dialogRef.close();
  }
  update() { 
    this.spinner.show();
    let payload = this.popupAddDeviceForm.value;
    
    
    let billingPayload = {};
    let billingPayloadNew = {};
    let haPayload = {};
    let servicePayload = {};
    let dataInfo = payload.deviceDetails;

    let serviceInfo2 = payload.deviceDetails;
    let info = [];
    let deviceInfo = [];
    let tempInfo = [];
    // let uniqNum2 = Math.floor(1000 + Math.random() * 9000);
    // let withoutAppId = "appId-" + uniqNum2;
    if (this.billingInfo.length > 0) {
      let bar = new Promise<void>((resolve, reject) => {
        
        this.billingInfo.forEach(element => {

          billingPayloadNew = {};
          info = [];
          deviceInfo = [];
          let totalBill = 0;
          let totalBalance = 0;
          dataInfo.map(ele => {
            
            if (element.billingCompanyRefId === ele.billingCompanyRefId) {
              let totalRate = 0;
              let discountAmt = 0;
              let totalServiceAmount = 0;
              let sgstval = 0;
              let cgstval = 0;
              let igstval = 0;
              
              totalRate = parseInt(ele.qty) * parseInt(ele.mrp);

              if (ele.sgst !== null) {
                sgstval = (totalRate * parseInt(ele.sgst)) / 100
              }
              if (ele.cgst !== null) {
                cgstval = (totalRate * parseInt(ele.cgst)) / 100
              }
              if (ele.igst !== null) {
                igstval = (totalRate * parseInt(ele.igst)) / 100
              }
              if (ele.sgst !== null && ele.cgst !== null) {
                totalServiceAmount = totalRate + sgstval + cgstval;
              } else if (ele.igst !== null) {
                totalServiceAmount = totalRate + igstval;
              } else {
                totalServiceAmount = totalRate;
              }


              if (ele.discountType == 'Percentage') {
                if (ele.discount == '') {
                  discountAmt = 0;
                } else {
                  discountAmt = (parseInt(ele.mrp) * parseInt(ele.discount)) / 100;
                }

              } else {
                if (ele.discount == '') {
                  discountAmt = 0;
                } else {
                  discountAmt = parseInt(ele.discount);
                }

              }

              if (discountAmt !== 0) {
                totalServiceAmount = totalServiceAmount - discountAmt;
              }
              info.push({ "serviceName": ele.model, "serialNumber": ele.serialNumber, "tempArray": ele.tempArray, "rate": ele.mrp, "discount": discountAmt, "earStyle": ele.earStyle, "quantity": ele.qty, "status": "unpaid", "totalRate": totalRate, "totalServiceAmount": totalServiceAmount, "sgst": ele.sgst, "cgst": ele.cgst, "igst": ele.igst, "hasSac": ele.hasSac });
              deviceInfo.push({ "deviceRefId": ele.deviceRefId, "model": ele.model, "qty": ele.qty, "serialNumber": ele.serialNumber, "tempArray": ele.tempArray, "earStyle": ele.earStyle, "changeModel": '', "changeSerialNumber": '', "mrp": ele.mrp, "discount": discountAmt, "discountType": ele.discountType, "status": "unpaid", "totalRate": totalRate, "totalServiceAmount": totalServiceAmount, "sgst": ele.sgst, "cgst": ele.cgst, "igst": ele.igst, "hasSac": ele.hasSac });
              
              let amt = 0;
              if (discountAmt !== null) {
                //amt = parseInt(ele.rate) - parseInt(ele.discount);
                amt = totalRate - discountAmt;
                totalBill = totalBill + amt;
              } else {
                // totalBill = totalBill + parseInt(ele.rate);
                totalBill = totalBill + totalRate;
              }
              
        
              totalBalance = totalBalance + totalServiceAmount;
              billingPayloadNew["mobile"] = this.patientDetails.mobile1;
              billingPayloadNew["patientName"] = this.patientDetails.firstName + ' ' + this.patientDetails.lastName;
              billingPayloadNew["patientRefId"] = this.patientDetails._id;
              billingPayloadNew["patientId"] = this.patientDetails.patientId;
              billingPayloadNew["branchRefId"] = this.branchRef;
              billingPayloadNew["branchName"] = this.branchName;
              billingPayloadNew['billingCompanyRefId'] = element.billingCompanyRefId;
              billingPayloadNew['billingCompanyName'] = element.billingCompanyName;
              billingPayloadNew['billingId'] = element.billingId;
              billingPayloadNew['billNo'] = this.billNo;
              billingPayloadNew['totalAmount'] = totalBill;
              billingPayloadNew['balanceAmount'] = totalBalance;
              billingPayloadNew['serviceInfo'] = info;
              // billingPayloadNew['withoutAppId'] = withoutAppId;
              billingPayloadNew['deviceInfo'] = deviceInfo;
              billingPayloadNew['paymentStatus'] = "Unpaid";
              billingPayloadNew['haId'] = payload.haId;
              billingPayloadNew['haRefId'] = payload.haRefId;
              //
              billingPayloadNew['tempArray'] = ele.tempArray;
              billingPayloadNew['serialNumber'] = ele.serialNumber;
            }
          });
          if (info.length > 0) {
            let resData = new Promise<void>((resolve, reject) => {
              // 
              haPayload['haId'] = payload.haId;
              haPayload['haFlag'] = "Yes";

              
              billingPayloadNew['isDraft'] = payload.isDraft;
              // this.apimicroservice.updateBillingDetails(billingPayloadNew).subscribe(res => {
              
              this.apimicroservice.updateBillingDetails(billingPayloadNew, this.billRefId).subscribe(res => {
                this.spinner.hide();
                if (res.status) {
                  // this.apimicroservice.openSnackbar("bill generated");
                  // if(payload.haRefId !== '' && payload.haRefId !== null && payload.haRefId !== undefined)
                  // {
                  //   this.apimicroservice.updateHADetails(haPayload,payload.haRefId).subscribe(res => {
                  //   });
                  // }
                  resolve();
                }
                this.dialogRef.close();
              })

            });



          }

        });
      });
      bar.then(() => {
        this.dialogRef.close({
          data: {
            patientRefId: this.patientRefId,
            appointId: this.appointId,
            // withoutAppId:withoutAppId,
            page: this.pagename
          }
        });

      });

    }

  }
  submitDeviceBillList(type) {
    //this.setValidator();
    
    const creds = this.popupAddDeviceForm.controls.deviceDetails as FormArray; 
    if(this.pagename == 'Billing Page'){
      this.popupAddDeviceForm.controls['patientName'].setValidators(Validators.required);
      this.popupAddDeviceForm.controls['patientName'].updateValueAndValidity();
    if(this.userType == 'Super Admin')
    {
      this.popupAddDeviceForm.controls['branchRefId'].setValidators(Validators.required);
      this.popupAddDeviceForm.controls['branchRefId'].updateValueAndValidity();
      
    }
    else{
      this.popupAddDeviceForm.controls['branchRefId'].setErrors(null);
      this.popupAddDeviceForm.controls['branchRefId'].clearValidators();
      this.popupAddDeviceForm.controls['branchRefId'].updateValueAndValidity();
      
    }    
  }
    if(creds.invalid || this.popupAddDeviceForm.invalid) {
      if(this.pagename == 'Billing Page'){
      this.popupAddDeviceForm.get('branchRefId').markAsTouched();
      this.popupAddDeviceForm.get('patientName').markAsTouched(); 
      }
      creds.controls.forEach(element =>{
            element.get('deviceRefId').markAsTouched();
            element.get('serialNumber').markAsTouched();
            element.get('mrp').markAsTouched();
            element.get('discount').markAsTouched();
            element.get('discountType').markAsTouched();
          }); 
    }
    else{
      if(this.isBilling == 'true')
      {
        this.addDeviceBill(type);
      }else
      {
        this.addDeviceBill2();
      }

     } 
}

  addDeviceBill(type){
    this.spinner.show();
      let payload = this.popupAddDeviceForm.value; 
    let billingPayload = {};
    let billingPayloadNew = {};
    let haPayload = {};
    let servicePayload = {};
    let dataInfo = payload.deviceDetails;
   
    let serviceInfo2 = payload.deviceDetails;
    let info = [];
    let deviceInfo = [];
    let tempInfo = [];
      let uniqNum2 = Math.floor(1000 + Math.random() * 9000);
      let withoutAppId = "appId-" + uniqNum2;
      
      
      
      if(this.billingInfo.length > 0){
        
        
        let bar = new Promise<void>((resolve,reject) =>{ 
          
        this.billingInfo.forEach(element => {
          
          billingPayloadNew = {};
          info = [];
          deviceInfo = [];
          let totalBill = 0;
          let totalBalance = 0;
          dataInfo.forEach(ele =>{
           if(element.billingCompanyRefId === ele.billingCompanyRefId){
            let totalRate = 0;
            let discountAmt = 0;
            let totalServiceAmount = 0;
            let sgstval = 0;
            let cgstval = 0;
            let igstval = 0;
            
               totalRate = parseInt(ele.qty) * parseInt(ele.mrp);

               if(ele.sgst !== null){
                sgstval = (totalRate * parseInt(ele.sgst)) /100
              }
              if(ele.cgst !== null){
                cgstval = (totalRate * parseInt(ele.cgst)) /100
              }
              if(ele.igst !== null){
                igstval = (totalRate * parseInt(ele.igst)) /100
              }
              if(ele.sgst !== null && ele.cgst !== null){
               totalServiceAmount = totalRate + sgstval + cgstval;
              }else if(ele.igst !== null){
               totalServiceAmount = totalRate + igstval;
              }else{
                totalServiceAmount = totalRate;
              }


               if(ele.discountType == 'Percentage'){
                if(ele.discount == ''){
                  discountAmt = 0;
                }else{
                  discountAmt = (parseInt(ele.mrp) * parseInt(ele.discount)) / 100;
                }
                
               }else{
                if(ele.discount == ''){
                  discountAmt = 0;
                }else{
                  discountAmt = parseInt(ele.discount);
                }
                
               }

               if(discountAmt !== 0){
                totalServiceAmount = totalServiceAmount - discountAmt;
                }
               info.push({"serviceName":ele.model,"serialNumber":ele.serialNumber,"tempArray":ele.tempArray,"rate":ele.mrp,"discount":discountAmt,"earStyle":ele.earStyle,"quantity":ele.qty,"status":"unpaid","totalRate":totalRate,"totalServiceAmount":totalServiceAmount,"sgst":ele.sgst,"cgst":ele.cgst,"igst":ele.igst,"hasSac":ele.hasSac});
               deviceInfo.push({"deviceRefId":ele.deviceRefId,"model":ele.model,"qty":ele.qty,"serialNumber":ele.serialNumber,"tempArray":ele.tempArray,"earStyle":ele.earStyle,"changeModel":'',"changeSerialNumber":'',"mrp":ele.mrp,"discount":discountAmt,"discountType":ele.discountType,"status":"unpaid","totalRate":totalRate,"totalServiceAmount":totalServiceAmount,"sgst":ele.sgst,"cgst":ele.cgst,"igst":ele.igst,"hasSac":ele.hasSac});
               let amt = 0;
          if(discountAmt !== null){
            //amt = parseInt(ele.rate) - parseInt(ele.discount);
            amt = totalRate - discountAmt;
            totalBill = totalBill + amt;
          }else{
           // totalBill = totalBill + parseInt(ele.rate);
           totalBill = totalBill + totalRate; 
          }
          totalBalance = totalBalance + totalServiceAmount;
          billingPayloadNew["mobile"] = this.patientDetails.mobile1;
              billingPayloadNew["patientName"] = this.patientDetails.firstName + ' ' + this.patientDetails.lastName;
              billingPayloadNew["patientRefId"] = this.patientDetails._id;
              billingPayloadNew["patientId"] = this.patientDetails.patientId;
              billingPayloadNew["branchRefId"] = this.branchRef;
              billingPayloadNew["branchName"] = this.branchName;
              billingPayloadNew['billingCompanyRefId'] = element.billingCompanyRefId;
              billingPayloadNew['billingCompanyName'] = element.billingCompanyName;
              billingPayloadNew['billingId'] = element.billingId;
              billingPayloadNew['billNo'] = element.billNo;
              billingPayloadNew['totalAmount'] = totalBill;
              billingPayloadNew['balanceAmount'] = totalBalance;
              billingPayloadNew['serviceInfo'] = info;
              // billingPayloadNew['withoutAppId'] = withoutAppId;
              billingPayloadNew['deviceInfo'] = deviceInfo;
              billingPayloadNew['paymentStatus'] = "Unpaid";
              billingPayloadNew['haId'] = payload.haId;
              billingPayloadNew['haRefId'] = payload.haRefId;
              //
              billingPayloadNew['tempArray'] = ele.tempArray;
              billingPayloadNew['serialNumber'] = ele.serialNumber;
           }
          });
          if(info.length > 0){ 
            
           // billingPayloadNew = this.patientDetails;
            //
            //   
            let tempInfo = info;
           let resData = new Promise<void>((resolve,reject) => {
            // 
            haPayload['haId'] = payload.haId;
            haPayload['haFlag']="Yes";
            
    
            if(type == 'draft')
            { 
              billingPayloadNew['isDraft'] = 'true';
              this.apimicroservice.addBillingDetailsAsDraft(billingPayloadNew).subscribe(res => {
                this.spinner.hide(); 
                if(res.status){
                  this.apimicroservice.openSnackbar("bill generated");
                  if(payload.haRefId !== '' && payload.haRefId !== null && payload.haRefId !== undefined)
                  {
                    this.apimicroservice.updateHADetails(haPayload,payload.haRefId).subscribe(res => {
                    });
                  }
                 resolve();
                }
              })
            }
            else
            {
              
              billingPayloadNew['isDraft'] = 'false'; 
              // this.apimicroservice.updateBillingDetails(billingPayloadNew).subscribe(res => {
                this.apimicroservice.updateBillingDetails(billingPayloadNew,this.billRefId).subscribe(res => { 
                this.spinner.hide();   
                if(res.status){ 
                  this.apimicroservice.openSnackbar("bill generated");
                  if(payload.haRefId !== '' && payload.haRefId !== null && payload.haRefId !== undefined)
                  {
                    this.apimicroservice.updateHADetails(haPayload,payload.haRefId).subscribe(res => {
                    });
                  }
                 resolve();
                }
              })
            }
          });

          resData.then(() => {
            //
            //
            let stockPayload = {};
            // let devicepayload = {};
            // devicepayload['billNo'] = element.billNo;
            // devicepayload['patientRefId'] = this.patientDetails._id;
            // devicepayload['patientName'] = this.patientDetails.firstName + ' ' + this.patientDetails.lastName;;      
            //    deviceInfo.forEach(ele =>{
            //   devicepayload['model']= ele.model;
            //   devicepayload['deviceRefId'] = ele.deviceRefId;
            //   devicepayload['qty']=ele.qty;
            //   devicepayload['serialNumber']=ele.serialNumber;
            //   devicepayload['mrp']=ele.mrp;
            //   devicepayload['discount']=ele.discount;
            //   devicepayload['discountType']=ele.discountType;
            //   devicepayload['status']="sold";
            //   devicepayload['totalRate']=ele.totalRate;
            //   devicepayload['sgst']=ele.sgst;
            //   devicepayload['cgst']=ele.cgst;
            //   devicepayload['igst']=ele.igst;
            //   devicepayload['totalServiceAmount']=ele.totalServiceAmount;
              
            //   this.apimicroservice.addSoldDeviceDetails(devicepayload).subscribe(res => {
            //     //
            //   //   stockPayload['serialNumber'] = ele.serialNumber;
            //   //   stockPayload['status'] = "sold";
            //   //  let resp = await this.apimicroservice.updateStockMaintainBySerial(stockPayload).toPromise();
               
            //   //    //
            //   //  // st = "done";
            //    resolve();
            //   });
            // });   
          
            if(type == 'draft')
            {
            tempInfo.forEach(async ele =>{
                 stockPayload['serialNumber'] = ele.serialNumber;
                 stockPayload['status'] = "sold";
                 stockPayload['isLockedForSale'] = this.billNo;
                 
                 
                let res = await this.apimicroservice.updateStockMaintainBySerial(stockPayload).toPromise();
                resolve();
               });
              }
              else{
                tempInfo.forEach(async ele =>{
                  stockPayload['serialNumber'] = ele.serialNumber;
                  stockPayload['status'] = "sold";
                //  stockPayload['isDraft'] = "false";
                  stockPayload['isLockedForSale'] = this.billNo;
                  
                 let res = await this.apimicroservice.updateStockMaintainBySerial(stockPayload).toPromise();
                 resolve();
                }); 
              }
              
          });
           
          }
          
        });
      });
        bar.then(() => {        
          this.dialogRef.close({data:{ 
            patientRefId:this.patientRefId,
            appointId:this.appointId,
            withoutAppId:withoutAppId,
            page:this.pagename  
          }});
        
        });
        
      }
    
  }


  onSelectPatient(item){
    // this.patientRefId = item.patientRefId;
    // this.appointId = item.appointId;
    // this.patientDetails = item;
    // this.fetchBillList();
    // this.fetchModelList2();
//
    this.patientRefId = item._id;
    this.appointId = '';
    this.patientDetails = item;
    // this.submitButton = true;
    // this.updateButton = false;
    this.fetchModelList2();
  }

  openPatientPopUp() {
    
    const dialogRef3 = this.dialog.open(PopUpPatientComponent,
      {
        width: "1200px",
      });
  
      dialogRef3.afterClosed().subscribe((result) => {
       // this.fetchPatientList();
       let clinicRef= sessionStorage.getItem('clinicRef');
       let branchRef= sessionStorage.getItem('branchRef');
       let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
       let branchRefSuperAdmin= '';
       if (this.userType !== 'Super Admin') {
       this.apimicroservice.getPatientListAll(branchRef,clinicRef).subscribe(res => {
        if (res.status) {
          // //
          this.patientList = res.data.data;
          this.filteredListPatient= this.patientList.slice();
          this.popupAddDeviceForm.get("patientName").setValue(this.patientList[0].firstName + " " +this.patientList[0].lastName);
          this.onSelectPatient(this.patientList[0]);
        }
      });
    }else{
      this.apimicroservice.getPatientListAll(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
        if (res.status) {
          // //
          this.patientList = res.data.data;
          this.filteredListPatient= this.patientList.slice();
          this.popupAddDeviceForm.get("patientName").setValue(this.patientList[0].firstName + " " +this.patientList[0].lastName);
          this.onSelectPatient(this.patientList[0]);
        }
      });
    }
       });
  }
  serialClick(i,serialNumber)
  {
    let control = (<FormArray>this.popupAddDeviceForm.get("deviceDetails")).at(i);
    //
    
         control.patchValue({
           qty: 1, 
           discountType:'Rupees',
           serialNumber:serialNumber
          //  discount:0,
       });
  }
  openBranchPopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpBranchComponent,
      {
        width: "1200px",
      });
  
      dialogRef3.afterClosed().subscribe((result) => {
        this.fetchBranchList();  
       });
  }

  openPatientSearchPopUp() {
    if(this.way !== 2)
    {
    const dialogRef3 = this.dialog.open(PatientSearchPopComponent, {
      width: "97%",
      height: "90%", 
    });
    dialogRef3.afterClosed().subscribe((result) => {
      this.popupAddDeviceForm.get("patientName").setValue(result.data.firstName + " " + result.data.lastName);
      this.popupAddDeviceForm.get("patientRefId").setValue(result.data._id);
      this.onSelectPatient(
        result.data
      ); 
      this.fetchHaList(result.data._id);
    });
  }
  }
  fetchHaList(id){
    this.searchText = ''; 
    let haFlag = 'No';
    this.haList= [];
    this.filteredListHa =[];
    this.haDisplay = false;
        this.apimicroservice.getHADataByPatient(id,haFlag).subscribe(res => {
        if (res.status && res.data.length >0) {     
          this.haList = res.data;
          this.haDisplay = true;
          this.filteredListHa = this.haList.slice();
          //
          
        } else {
         // this.apimicroservice.openSnackbar(res.message);
        }
      })
    
  } 
  haClick(id)
  {
    this.popupAddDeviceForm.controls["haId"].setValue(id);
  }
  openConfirmationMsg(type){
    let payload = this.popupAddDeviceForm.value;
    
    const creds = this.popupAddDeviceForm.controls.deviceDetails as FormArray; 
    if(this.pagename == 'Billing Page'){
      this.popupAddDeviceForm.controls['patientName'].setValidators(Validators.required);
      this.popupAddDeviceForm.controls['patientName'].updateValueAndValidity();
    if(this.userType == 'Super Admin')
    {
      this.popupAddDeviceForm.controls['branchRefId'].setValidators(Validators.required);
      this.popupAddDeviceForm.controls['branchRefId'].updateValueAndValidity();
      
    }
    else{
      this.popupAddDeviceForm.controls['branchRefId'].setErrors(null);
      this.popupAddDeviceForm.controls['branchRefId'].clearValidators();
      this.popupAddDeviceForm.controls['branchRefId'].updateValueAndValidity();
      
    }    
  }
    if(creds.invalid || this.popupAddDeviceForm.invalid) {
      if(this.pagename == 'Billing Page'){
      this.popupAddDeviceForm.get('branchRefId').markAsTouched();
      this.popupAddDeviceForm.get('patientName').markAsTouched(); 
      }
      creds.controls.forEach(element =>{
            element.get('deviceRefId').markAsTouched();
            element.get('serialNumber').markAsTouched();
            element.get('mrp').markAsTouched();
            element.get('discount').markAsTouched();
            element.get('discountType').markAsTouched();
          });  
    }
    else{
      const dialogRef3 = this.dialog.open(PopupConfirmationMsgComponent,
        {  
          width: "24%",
          // height: "23%",
          data: {  
            from:'Add Device',
            pData: payload.patientName
          }
        });
        dialogRef3.afterClosed().subscribe((result) => {
          
          if(result == 'Yes')
          {  
            this.submitDeviceBillList(type);
          }
         });
     }  


  
  } 
  addDeviceBill2(){
    this.spinner.show();
      let payload = this.popupAddDeviceForm.value; 
    let billingPayload = {};
    let billingPayloadNew = {};
    let haPayload = {};
    let servicePayload = {};
    let dataInfo = payload.deviceDetails;
    //
    
    let serviceInfo2 = payload.deviceDetails;
    // let uniqNum = Math.floor(1000 + Math.random() * 9000);
    // let withoutAppId = "appId-" + uniqNum;
    // serviceInfo2.forEach(ele =>{
    //   this.oldServiceRateInfo.push(ele);
    // });
    // 
    //
    // //
    // servicePayload['serviceRateInfo'] = this.oldServiceRateInfo; 
    //let billingPayload = this.paientdetails;
    let info = [];
    let deviceInfo = [];
    let tempInfo = [];
       
    // let ss = "process";
    // let st = "process";
    
    
      let uniqNum2 = Math.floor(1000 + Math.random() * 9000);
      let withoutAppId = "appId-" + uniqNum2;
      if(this.billingInfo.length > 0){
        let bar = new Promise<void>((resolve,reject) =>{ 
        this.billingInfo.forEach(element => {
          billingPayloadNew = {};
          info = [];
          deviceInfo = [];
          let totalBill = 0;
          let totalBalance = 0;
          dataInfo.forEach(ele =>{
           
           if(element.billingCompanyRefId === ele.billingCompanyRefId){
            let totalRate = 0;
            let discountAmt = 0;
            let totalServiceAmount = 0;
            let sgstval = 0;
            let cgstval = 0;
            let igstval = 0;

               totalRate = parseInt(ele.qty) * parseInt(ele.mrp);

               if(ele.sgst !== null){
                sgstval = (totalRate * parseInt(ele.sgst)) /100
              }
              if(ele.cgst !== null){
                cgstval = (totalRate * parseInt(ele.cgst)) /100
              }
              if(ele.igst !== null){
                igstval = (totalRate * parseInt(ele.igst)) /100
              }
              if(ele.sgst !== null && ele.cgst !== null){
               totalServiceAmount = totalRate + sgstval + cgstval;
              }else if(ele.igst !== null){
               totalServiceAmount = totalRate + igstval;
              }else{
                totalServiceAmount = totalRate;
              }


               if(ele.discountType == 'Percentage'){
                if(ele.discount == ''){
                  discountAmt = 0;
                }else{
                  discountAmt = (parseInt(ele.mrp) * parseInt(ele.discount)) / 100;
                }
                
               }else{
                if(ele.discount == ''){
                  discountAmt = 0;
                }else{
                  discountAmt = parseInt(ele.discount);
                }
                
               }

               if(discountAmt !== 0){
                totalServiceAmount = totalServiceAmount - discountAmt;
                }
               info.push({"serviceName":ele.model,"serialNumber":ele.serialNumber,"tempArray":ele.serialNumber,"rate":ele.mrp,"discount":discountAmt,"earStyle":ele.earStyle,"quantity":ele.qty,"status":"unpaid","totalRate":totalRate,"totalServiceAmount":totalServiceAmount,"sgst":ele.sgst,"cgst":ele.cgst,"igst":ele.igst,"hasSac":ele.hasSac});
               deviceInfo.push({"deviceRefId":ele.deviceRefId,"model":ele.model,"qty":ele.qty,"serialNumber":ele.serialNumber,"tempArray":ele.serialNumber,"earStyle":ele.earStyle,"changeModel":'',"changeSerialNumber":'',"mrp":ele.mrp,"discount":discountAmt,"discountType":ele.discountType,"status":"unpaid","totalRate":totalRate,"totalServiceAmount":totalServiceAmount,"sgst":ele.sgst,"cgst":ele.cgst,"igst":ele.igst,"hasSac":ele.hasSac});
               let amt = 0;
          if(discountAmt !== null){
            //amt = parseInt(ele.rate) - parseInt(ele.discount);
            amt = totalRate - discountAmt;
            totalBill = totalBill + amt;
          }else{
           // totalBill = totalBill + parseInt(ele.rate);
           totalBill = totalBill + totalRate;
          }
          totalBalance = totalBalance + totalServiceAmount;
           }
          });
          if(info.length > 0){
           // billingPayloadNew = this.patientDetails;
            //
            //
            billingPayloadNew["mobile"] = this.patientDetails.mobile1;
            billingPayloadNew["patientName"] = this.patientDetails.firstName + ' ' + this.patientDetails.lastName;
            billingPayloadNew["patientRefId"] = this.patientDetails._id;
            billingPayloadNew["patientId"] = this.patientDetails.patientId;
            billingPayloadNew["branchRefId"] = this.branchRef; 
            billingPayloadNew["branchName"] = this.branchName;
            billingPayloadNew['billingCompanyRefId'] = element.billingCompanyRefId;
            billingPayloadNew['billingCompanyName'] = element.billingCompanyName;
            billingPayloadNew['billingId'] = element.billingId;
            billingPayloadNew['billNo'] = element.billNo;
            billingPayloadNew['totalAmount'] = totalBill;
            billingPayloadNew['balanceAmount'] = totalBalance;
            billingPayloadNew['serviceInfo'] = info;
            billingPayloadNew['withoutAppId'] = withoutAppId;
            billingPayloadNew['deviceInfo'] = deviceInfo;
            billingPayloadNew['paymentStatus'] = "Unpaid";
            billingPayloadNew['haId'] = payload.haId;
            billingPayloadNew['haRefId'] = payload.haRefId;
            //
            billingPayloadNew['haId'] = payload.haId;
            billingPayloadNew['haRefId'] = payload.haRefId;
            let tempInfo = info;
           let resData = new Promise<void>((resolve,reject) => {
            //
            haPayload['haId'] = payload.haId;
            haPayload['haFlag']="Yes";
            
            
             this.apimicroservice.addBillingDetails(billingPayloadNew).subscribe(res => {
              this.spinner.hide(); 
               
              //
              if(res.status){
                this.apimicroservice.openSnackbar("bill generated");
                if(payload.haRefId !== '' && payload.haRefId !== null && payload.haRefId !== undefined)
                {
                  this.apimicroservice.updateHADetails(haPayload,payload.haRefId).subscribe(res => {
                  });
                }
               resolve();
              }
            })
          });

          resData.then(() => {
            //
            //
            let stockPayload = {};
            // let devicepayload = {};
            // devicepayload['billNo'] = element.billNo;
            // devicepayload['patientRefId'] = this.patientDetails._id;
            // devicepayload['patientName'] = this.patientDetails.firstName + ' ' + this.patientDetails.lastName;;

            
            
            //    deviceInfo.forEach(ele =>{
            //   devicepayload['model']= ele.model;
            //   devicepayload['deviceRefId'] = ele.deviceRefId;
            //   devicepayload['qty']=ele.qty;
            //   devicepayload['serialNumber']=ele.serialNumber;
            //   devicepayload['mrp']=ele.mrp;
            //   devicepayload['discount']=ele.discount;
            //   devicepayload['discountType']=ele.discountType;
            //   devicepayload['status']="sold";
            //   devicepayload['totalRate']=ele.totalRate;
            //   devicepayload['sgst']=ele.sgst;
            //   devicepayload['cgst']=ele.cgst;
            //   devicepayload['igst']=ele.igst;
            //   devicepayload['totalServiceAmount']=ele.totalServiceAmount;
              
            //   this.apimicroservice.addSoldDeviceDetails(devicepayload).subscribe(res => {
            //     //
            //   //   stockPayload['serialNumber'] = ele.serialNumber;
            //   //   stockPayload['status'] = "sold";
            //   //  let resp = await this.apimicroservice.updateStockMaintainBySerial(stockPayload).toPromise();
               
            //   //    //
            //   //  // st = "done";
            //    resolve();
            //   });
            // });  
          
          
            tempInfo.forEach(async ele =>{
                 stockPayload['serialNumber'] = ele.serialNumber;
                 stockPayload['status'] = "sold";
                 stockPayload['isLockedForSale'] = element.billNo;
                 
                let res = await this.apimicroservice.updateStockMaintainBySerial(stockPayload).toPromise();
                
                  //
                // st = "done";
                resolve();
               });
              
          });
           
          }
          
        });
      });
        // //
        bar.then(() => {
          
          this.dialogRef.close({data:{ 
            patientRefId:this.patientRefId,
            appointId:this.appointId,
            withoutAppId:withoutAppId,
            page:this.pagename  
          }});
        
        });
        
      }
    
  }

}