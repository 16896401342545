<div class="container-fluid bgcolor2">
  <form autocomplete="off" [formGroup]="doctorAvailabiltyForm" novalidate>
    <div class="row">
      <div class="col-sm-1">

      </div>
      <div class="col-sm-11">
        <div class="row pl-0 ">

          <div class="col-12 pl-0 ">
            <div class="row ">
              <div class="col-sm-9">

                <h1 class="pt-2 pl-0" id="toDoctorAvailabilityForm">Audiologists Availability</h1>
              </div>
              <div class="col-sm-3 mt-1">
                <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                  <a (click)="onback()" class="d-flex align-items-center pr-2 mt-2"><span
                      class="material-symbols-outlined pr-2">arrow_back</span>Back</a>
                  <a (click)="toDoctorAvailabilityList()" class="d-flex align-items-center ms-3 pr-2 mt-2"><span
                      class="material-symbols-outlined pr-2">reorder</span>List</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row ">
          <div class="col-sm-12 pr-4">
            <div class="row">

              <div class="card card2 col-sm-12 divbody mb-3  borderradius ">

                <div class="row p-2 ">

                  <div class="col-sm-12 ">
                    <div class="panel-body">

                      <div class="col-sm-12">

                        <div class="row">
                          <div class="col-sm-2 mt-2">
                            <h5 class="pb-0 mb-0">Select Audiologists :</h5>
                            <!-- <label class="header">Select Audiologists  :</label> -->
                          </div>
                          <div *ngIf="doctorCreate" class="col-sm-3">
                            <div class="row">
                              <div class="col-sm-10 pr-1">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Audiologists
                                  </mat-label>
                                  <mat-select required #select2 formControlName="doctorRefId"
                                    (click)="fetchDoctorList();">
                                    <mat-select-filter *ngIf="select2.focused" [placeholder]="'Filter'"
                                      [displayMember]="'firstName'" [array]="doctorList"
                                      (filteredReturn)="filteredListDoctor =$event"></mat-select-filter>

                                    <mat-option *ngFor="let item of filteredListDoctor" [value]="item._id"
                                      (click)="getBranchListById(item._id,item.firstName,item.lastName);">
                                      {{ item.firstName }} {{ item.lastName }}</mat-option>
                                  </mat-select>
                                </mat-form-field>
                                <mat-error class="margin_tp"
                                  *ngIf="doctorAvailabiltyForm.controls['doctorRefId'].touched && doctorAvailabiltyForm.controls['doctorRefId'].errors?.required">
                                  This field is mandatory.
                                </mat-error>
                              </div>
                              <div class="col-sm-2 mt-2 ml-0 pl-0 mTop no-print">
                                <i (click)="openDoctorPopUp();" matTooltip="Add New Audiologists"
                                  class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                              </div>
                            </div>

                          </div>
                          <div *ngIf="!doctorCreate" class="col-sm-3">
                            <div class="row">
                              <div class="col-sm-12">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Audiologists
                                  </mat-label>
                                  <mat-select #select2 formControlName="doctorRefId" (click)="fetchDoctorList();">
                                    <mat-select-filter *ngIf="select2.focused" [placeholder]="'Filter'"
                                      [displayMember]="'firstName'" [array]="doctorList"
                                      (filteredReturn)="filteredListDoctor =$event"></mat-select-filter>

                                    <mat-option *ngFor="let item of filteredListDoctor" [value]="item._id"
                                      (click)="getBranchListById(item._id,item.firstName,item.lastName);">
                                      {{ item.firstName }} {{ item.lastName }}</mat-option>
                                  </mat-select>
                                </mat-form-field>
                                <mat-error class="margin_tp"
                                  *ngIf="doctorAvailabiltyForm.controls['doctorRefId'].touched && doctorAvailabiltyForm.controls['doctorRefId'].errors?.required">
                                  This field is mandatory.
                                </mat-error>
                              </div>

                            </div>

                          </div>
                          <!-- <div class="col-sm-2 mt-2"></div> -->
                          <div class="col-sm-2 mt-2">
                            <h5 class="pb-0 mb-0">Select Branch :</h5>

                          </div>
                          <div *ngIf="branchCreate" class="col-sm-3">
                            <div class="row">
                              <div class="col-sm-10 pr-1">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Branch
                                  </mat-label>
                                  <mat-select required #select1 formControlName="branchRefId">
                                    <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'"
                                      [displayMember]="'branchName'" [array]="BranchList"
                                      (filteredReturn)="filteredListBranch =$event"></mat-select-filter>

                                    <mat-option *ngFor="let item of filteredListBranch" [value]="item._id"
                                      (click)="onSelectBranch(item)">
                                      {{ item.branchName }}</mat-option>
                                  </mat-select>
                                </mat-form-field>
                                <mat-error class="margin_tp"
                                  *ngIf="doctorAvailabiltyForm.controls['branchRefId'].touched && doctorAvailabiltyForm.controls['branchRefId'].errors?.required">
                                  This field is mandatory.
                                </mat-error>
                              </div>
                              <div class="col-sm-2 mt-2 ml-0 pl-0 mTop no-print">
                                <i (click)="openBranchPopUp();" matTooltip="Add New Branch"
                                  class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="!branchCreate" class="col-sm-3">
                            <div class="row">
                              <div class="col-sm-12">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Branch
                                  </mat-label>
                                  <mat-select #select1 formControlName="branchRefId">
                                    <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'"
                                      [displayMember]="'branchName'" [array]="BranchList"
                                      (filteredReturn)="filteredListBranch =$event"></mat-select-filter>

                                    <mat-option *ngFor="let item of filteredListBranch" [value]="item._id"
                                      (click)="onSelectBranch(item)">
                                      {{ item.branchName }}</mat-option>
                                  </mat-select>
                                </mat-form-field>
                                <mat-error class="margin_tp"
                                  *ngIf="doctorAvailabiltyForm.controls['branchRefId'].touched && doctorAvailabiltyForm.controls['branchRefId'].errors?.required">
                                  This field is mandatory.
                                </mat-error>
                              </div>

                            </div>
                          </div>

                          <!-- <div class="col-sm-2 mt-2">
                                  <label class="header">Branch Open & Close Time:</label>
                               </div> -->
                          <!-- <div class="col-sm-3">
                                <div class="row">
                                  <div class="col-sm-12">
                                    <label class="pt-2 close_style justify-content-end lable_style" *ngIf="openTime == undefined">00.00 am To 00.00 pm</label>
                                    <label class="pt-2 close_style justify-content-end lable_style" *ngIf="openTime">{{openTime}} To {{closeTime}}</label>
                                  </div>
                                  </div>
                                  </div> -->
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <table class="table table-bordered mt-2 data-table-custom" style="text-align: center;">
                          <thead>

                            <tr>
                              <th></th>
                              <th>Morning</th>
                              <th>Afternoon</th>
                              <th>Evening </th>
                            </tr>
                          </thead>
                          <tbody formArrayName="dayInfo">

                            <tr [formGroupName]="i"
                              *ngFor="let row of doctorAvailabiltyForm.get('dayInfo')['controls']; let i = index; let last = last"
                              role="row" class="odd text-center">
                              <td class="align-middle">
                                <!-- <mat-label matLine ngDefaultControl [value]="dayLabel" formControlName="day">{{dayLabel}}</mat-label> -->
                                <mat-form-field class="example-full-width lable_style2" appearance="outline">
                                  <!-- <mat-label matLine ngDefaultControl formControlName="day"></mat-label>        -->
                                  <input readonly formControlName="day" matInput type="text" />
                                </mat-form-field>
                              </td>
                              <td class="align-middle">
                                <div class="row ">
                                  <div class="col-sm-6 ">
                                    <!-- <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>From  
                                        </mat-label>
                                        <input formControlName="morningFrom" (change)="toMorningFromChange($event,i)"
                                        matInput id="appt" type="time" name="appt" step="2" [min]="openTime" max="12:00:00">
                                      <span class="validity"></span>
                                      </mat-form-field> -->
                                    <div class="row">
                                      <div class="col-sm-9 p-0">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>From
                                          </mat-label>
                                          <input matInput placeholder="09:00 am" formControlName="morningFrom"
                                            [ngxTimepicker]="pickerA" value="" [min]="morningFromTime"
                                            [max]="morningToTime" [disableClick]="true"
                                            (change)="toMorningFromChange(i)" />
                                          <ngx-material-timepicker-toggle matSuffix
                                            [for]="pickerA"></ngx-material-timepicker-toggle>
                                          <ngx-material-timepicker (closed)="toMorningFromChange(i)"
                                            #pickerA></ngx-material-timepicker>

                                        </mat-form-field>
                                        <mat-error class="margin_tp"
                                          *ngIf="row.controls.morningFrom.touched && row.controls.morningFrom.errors?.pattern">
                                          Enter time format like- 9:00 AM.
                                        </mat-error>
                                      </div>
                                      <div class="col-sm-2 mt-2">
                                        <span (click)="refreshMorning(i)" matTooltip="clear"
                                         class="material-symbols-outlined pointer mt-3">
                                          autorenew
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-sm-6">
                                    <div class="row">
                                      <div class="col-sm-9 p-0">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>To

                                          </mat-label>
                                          <input matInput placeholder="12:00 pm" formControlName="morningTo"
                                            [ngxTimepicker]="pickerB" value="" [min]="morningFromTime"
                                            [max]="morningToTime" [disableClick]="true"
                                            (change)="toMorningToChange(i)" />
                                          <ngx-material-timepicker-toggle matSuffix
                                            [for]="pickerB"></ngx-material-timepicker-toggle>
                                          <ngx-material-timepicker (closed)="toMorningToChange(i)"
                                            #pickerB></ngx-material-timepicker>

                                        </mat-form-field>
                                        <mat-error class="margin_tp"
                                          *ngIf="row.controls.morningTo.touched && row.controls.morningTo.errors?.pattern">
                                          Enter time format like- 12:00 PM.
                                        </mat-error>
                                      </div>
                                      <div class="col-sm-2 mt-2">
                                        <span (click)="refreshMorningTo(i)" matTooltip="clear"
                                         class="material-symbols-outlined pointer mt-3">
                                          autorenew
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>

                              <td class="align-middle">
                                <div class="row ">
                                  <div class="col-sm-6">
                                    <div class="row ">
                                      <div class="col-sm-9 p-0">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>From

                                          </mat-label>
                                          <!-- <input formControlName="afternoonFrom" (change)="toAfternoonFromChange($event,i)"  
                                        matInput id="appt" type="time" name="appt" step="2" min="12:00:00" max="16:00:00"> -->
                                          <input matInput placeholder="12:00 pm" formControlName="afternoonFrom"
                                            [ngxTimepicker]="pickerC" value="" [min]="afternoonFromTime"
                                            [max]="afternoonToTime" [disableClick]="true"
                                            (change)="toAfternoonFromChange(i)" />
                                          <ngx-material-timepicker-toggle matSuffix
                                            [for]="pickerC"></ngx-material-timepicker-toggle>
                                          <ngx-material-timepicker (closed)="toAfternoonFromChange(i)"
                                            #pickerC></ngx-material-timepicker>
                                        </mat-form-field>
                                        <mat-error class="margin_tp"
                                          *ngIf="row.controls.afternoonFrom.touched && row.controls.afternoonFrom.errors?.pattern">
                                          Enter time format like- 1:00 PM.
                                        </mat-error>
                                      </div>
                                      <div class="col-sm-2 mt-2"> 
                                        <span (click)="refreshAfternoon(i)" matTooltip="clear" class="material-symbols-outlined pointer mt-3">
                                          autorenew
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-sm-6">
                                    <div class="row">
                                      <div class="col-sm-9 p-0">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>To

                                          </mat-label>
                                          <!-- <input formControlName="afternoonTo" (change)="toAfternoonToChange($event,i)" 
                                       matInput id="appt" type="time" name="appt" step="2" min="12:00:00" max="16:00:00"> -->
                                          <input matInput placeholder="03:00 pm" formControlName="afternoonTo"
                                            [ngxTimepicker]="pickerD" value="" [min]="afternoonFromTime"
                                            [max]="afternoonToTime" [disableClick]="true"
                                            (change)="toAfternoonToChange(i)" />
                                          <ngx-material-timepicker-toggle matSuffix
                                            [for]="pickerD"></ngx-material-timepicker-toggle>
                                          <ngx-material-timepicker (closed)="toAfternoonToChange(i)"
                                            #pickerD></ngx-material-timepicker>
                                        </mat-form-field>
                                      </div>

                                      <div class="col-sm-2 mt-2">
                                        <span (click)="refreshAfternoonTo(i)" matTooltip="clear" class="material-symbols-outlined pointer mt-3">
                                          autorenew
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td class="align-middle">
                                <div class="row ">
                                  <div class="col-sm-6">
                                    <div class="row ">
                                      <div class="col-sm-9 p-0">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>From </mat-label>
                                          <input matInput placeholder="04:00 pm" formControlName="eveningFrom"
                                            [ngxTimepicker]="pickerE" value="" [min]="eveningFromTime"
                                            [max]="eveningToTime" [disableClick]="true"
                                            (change)="toEveningFromChange(i)" />
                                          <ngx-material-timepicker-toggle matSuffix
                                            [for]="pickerE"></ngx-material-timepicker-toggle>
                                          <ngx-material-timepicker (closed)="toEveningFromChange(i)"
                                            #pickerE></ngx-material-timepicker>
                                        </mat-form-field>
                                      </div>
                                      <div class="col-sm-2 mt-2">
                                        <span (click)="refreshEvening(i)" matTooltip="clear" 
                                        class="material-symbols-outlined pointer mt-3">
                                          autorenew
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-sm-6">
                                    <div class="row">
                                      <div class="col-sm-9 p-0">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>To</mat-label>

                                          <input matInput placeholder="08:00 pm" formControlName="eveningTo"
                                            [ngxTimepicker]="pickerF" value="" [min]="eveningFromTime"
                                            [max]="eveningToTime" [disableClick]="true"
                                            (change)="toEveningToChange(i)" />
                                          <ngx-material-timepicker-toggle matSuffix
                                            [for]="pickerF"></ngx-material-timepicker-toggle>
                                          <ngx-material-timepicker (closed)="toEveningToChange(i)"
                                            #pickerF></ngx-material-timepicker>
                                        </mat-form-field>
                                        <mat-error class="margin_tp"
                                          *ngIf="row.controls.eveningTo.touched && row.controls.eveningTo.errors?.pattern">
                                          Enter time format like- 10:00 PM.
                                        </mat-error>
                                      </div>
                                      <div class="col-sm-2 mt-2">
                                        <span (click)="refreshEveningTo(i)" matTooltip="clear" class="material-symbols-outlined pointer mt-3">
                                          autorenew
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>

                            </tr>
                            <!-- <tr >
                                    <td class="align-middle">
                                        Tuesday
                                    </td>
                                  <td class="align-middle">
                                    <div class="row ">
                                        <div class="col-sm-6">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>From 
                                          
                                        </mat-label>
                                           <input matInput id="appt" type="time" name="appt" step="2">
                                      </mat-form-field>
                                      </div>
                                        <div class="col-sm-6">
                                      <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>To
                                          
                                        </mat-label>
                                           <input matInput id="appt" type="time" name="appt" step="2">
                                      </mat-form-field>
                                      </div>
                                      </div>
                                  </td>
                                 
                                  <td class="align-middle">
                                    <div class="row ">
                                        <div class="col-sm-6">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>From 
                                          
                                        </mat-label>
                                           <input matInput id="appt" type="time" name="appt" step="2">
                                      </mat-form-field>
                                      </div>
                                        <div class="col-sm-6">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                      <mat-label>To
                                        
                                      </mat-label>
                                        <input matInput id="appt" type="time" name="appt" step="2">
                                    </mat-form-field>
                                    </div>
                                    </div>
                                  </td>
                                  <td class="align-middle">
                                    <div class="row ">
                                        <div class="col-sm-6">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>From 
                                          
                                        </mat-label>
                                           <input matInput id="appt" type="time" name="appt" step="2">
                                      </mat-form-field>
                                      </div>
                                        <div class="col-sm-6">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                      <mat-label>To
                                        
                                      </mat-label>
                                        <input matInput id="appt" type="time" name="appt" step="2">
                                    </mat-form-field>
                                    </div>
                                    </div>
                                  </td>
                                  
                                </tr>  -->





                          </tbody>
                        </table>

                      </div>
                      <div class="row">
                        <div *ngIf="submitButton" class="col-sm-10 mt-2">
                        </div>
                        <div *ngIf="updateButton" class="col-sm-8 mt-2">
                        </div>
                        <div *ngIf="doctorAvailabilityCreate && submitButton" class="col-sm-2 mt-2">
                          <button type="button" (click)="saveAvailabilityData()"
                            class="btn btn-sm btn-primary example-full-width" [disabled]="disableSubmit">Save </button>
                        </div>
                        <div *ngIf="doctorAvailabilityUpdate && updateButton" class="col-sm-2 mt-2">
                          <button type="button" (click)="updateAvailabilityData()"
                            class="btn btn-sm btn-primary example-full-width " [disabled]="disableUpdate">Update
                          </button>
                        </div>
                        <div class="col-sm-2 mt-2" *ngIf="cancelButton">
                          <button class="btn btn-sm btn-primary example-full-width" (click)="cancelUpdate()">Cancel
                          </button>
                        </div>
                      </div>


                      <div class="col-sm-12 pt-0 text-right">
                        <!-- <pagination-controls id="clinicMaster" directionLinks=" Yes" (pageChange)="onPageChange($event)"
                              class="float-right pt-3 my-pagination"></pagination-controls> -->

                      </div>


                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-sm-12 ">
            <div class="row">
              <div class="card divbody  mb-2 borderradius p-3 mr-2 pb-0">
                <div class="col-sm-12">
                  <div class="row">
                    <div class="col-sm-4    ">
                      <h1 class="titlecolor">List Of Availability</h1>
                    </div>
                    <div class="col-sm-5"></div>
                    <div class="search-box col-sm-3 pb-2">
                      <input class="form-control amber-border" type="text" placeholder="Search"
                        formControlName="searchDoctorAvailable" aria-label="Search" (keyup)="onKeyUpEvent($event)">
                      <div class="input-group-append">
                        <span class="input-group-text amber lighten-3" id="basic-text1"><span
                            class="material-symbols-outlined">search</span></span>
                      </div>
                    </div>

                    <div class="col-sm-12 table-responsive">
                      <table id="toDoctorAvailabilityList" class="table table-bordered data-table-custom"
                        style="text-align: center;">
                        <thead>

                          <tr>

                            <th>Audiologists</th>
                            <th>Branch</th>

                          </tr>
                        </thead>
                        <tbody>
                          <tr (click)="prepopulation(item._id,false)" *ngFor="let item of listofDoctorAvailable
                          | paginate : { id:'doctorAvailableMaster', 
                          itemsPerPage: size, currentPage: page, totalItems:totalLength }" style="cursor: pointer;"
                            (click)="toDoctorAvailabilityForm()">

                            <td matTooltip="click to view">{{item.doctorName}}</td>
                            <td matTooltip="click to view">{{item.branchName}}</td>

                          </tr>


                        </tbody>
                      </table>
                    </div>
                    <div class="col-sm-12 pt-0 text-right">
                      <pagination-controls id="doctorAvailableMaster" directionLinks="true"
                        (pageChange)="onPageChange($event)"
                        class="float-right pagination my-pagination"></pagination-controls>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  </form>
</div>