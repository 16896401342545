import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
 import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";
import { PopUpPatientComponent } from "../pop-up-patient/pop-up-patient.component";
import { PopUpBranchComponent } from "../pop-up-branch/pop-up-branch.component";
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { PopupCompanyComponent } from "../popup-company/popup-company.component";
import { PopupBillingcompanyComponent } from "../popup-billingcompany/popup-billingcompany.component";
import { PatientSearchPopComponent } from "../patient-search-pop/patient-search-pop.component";
import { Router,ActivatedRoute } from "@angular/router";
@Component({
  selector: 'app-earmould-dashboard',
  templateUrl: './earmould-dashboard.component.html',
  styleUrls: ['./earmould-dashboard.component.scss']
})
export class EarmouldDashboardComponent implements OnInit {

  earMouldDashboardForm: FormGroup;
  submitButton = true;
  updateButton = false;
  filteredListBranch: any;
  listOfEarMouldOrders:any;
  BranchList = [];
  size:number = 5;
  page:number = 1;
  totalLength: any;
  filteredListPatient: any;
  patientList = [];
  branchRef: any;
  searchText: string;
  clinicRef: any;
  pid: any;
  patientSelect = false;
  date: Date;
  userPermission:any;
  earMouldCreate= true;
  earMouldUpdate= true;
  isSuperAdmin= true;
  branchName: any;
  superBranchId: any;
  userRef: string;
  userType: string;
  cancelButton = false;
  earId: any;
  canNumberList = [];
  filteredListCanNumber: any;
  audiogramFile: any;
  errormessage: string;
  companyList = [];
  filteredListCompany: any;
  companyName: any;
  address: any;
  email: any;
  canNumber: any;
  billingCompanyList = [];
  filteredListBillingCompany: any;
  billingCompany: any;
  patientCreate = true;
  branchCreate = true;
  billingCreate = true;
  companyCreate = true;
  RightDisable = false;
  LeftDisable = false;
  pId: any;
  patientRef: any;
  branchRefId: any;
  branchNamee: any;
  pName: string;

  constructor(private fb: FormBuilder,
    public apimicroservice: ApiServiceService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    public route : ActivatedRoute,
    public router: Router) { }
    
  ngOnInit(): void {
    this.date = new Date();
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.userRef = sessionStorage.getItem('userRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    this.userType = sessionStorage.getItem('userType');
   
    if(this.userType !== 'Super Admin'){
      this.isSuperAdmin= false;
    this.userPermission.forEach((element) => {
      if(element.pageName == 'Ear Mould' && element.create == false)
      {
          this.earMouldCreate = false;  
      }
      if(element.pageName == 'Ear Mould' && element.update == false)
      {
          this.earMouldUpdate = false;  
      }  
      if(element.pageName == 'Patient' && element.create == false)
      {
          this.patientCreate = false;  
      } 
      if(element.pageName == 'Branch' && element.create == false)
      {
          this.branchCreate = false;  
      }
      if(element.pageName == 'Billing Company' && element.create == false)
      {
          this.billingCreate = false;  
      }
      if(element.pageName == 'Company Master' && element.create == false)
      {
          this.companyCreate = false;  
      }  
      
    });
  } 

    this.earMouldDashboardForm = this.fb.group({
 
      "branchWiseId": new FormControl(''),
      "date": new FormControl(''),
      "manufacturing": new FormControl(''),
      
      "branchRefId": new FormControl(''),
      "clinicRefId": new FormControl(''),
      "branchName": new FormControl(''),
      "patientRefId": new FormControl('',Validators.required),
      "patientUniqueId": new FormControl(''),
      "patientName": new FormControl(''),
      "firstName": new FormControl(''),
      "lastName": new FormControl(''),
      "mobile1": new FormControl(''),
      "age" :new FormControl(''),

      "companyRefId": new FormControl('',Validators.required),
      "companyName": new FormControl(''),
      "companyAddress": new FormControl(''),
      "companyEmail": new FormControl(''),
      "canNumber": new FormControl(''),
     
      "ear": new FormControl('',Validators.required),
      "leftStyle":new FormControl(''),
      "rightStyle":new FormControl(''),
      "leftHAType" : new FormControl(''),
      "rightHAType" : new FormControl(''),
      "leftHardness" :new FormControl(''),
      "rightHardness" :new FormControl(''),
      "leftVent":new FormControl(''),
      "rightVent":new FormControl(''),
      "leftCanalLenght":new FormControl(''),
      "rightCanalLenght":new FormControl(''),
      "leftRemovalString":new FormControl(''),
      "rightRemovalString":new FormControl(''),
      "estimateRequired" : new FormControl('',),
      "complaint" :new FormControl(''),
      "audiogram" :new FormControl(false),
      "audiogramFile" :new FormControl(''),
     // "earImpression" :new FormControl(false),
      "earImpressionText" :new FormControl('',[Validators.pattern("^[0-9]*$"),Validators.maxLength(10)]),
      "billingRefId" :new FormControl('',Validators.required),
      "billingCompanyName":new FormControl(''),
      "createdAt": new Date(),
      "searchEarMouldOrder":new FormControl(''),
   
    });
    let latest_date =this.datepipe.transform(this.date, 'dd/MM/yyyy');
    this.earMouldDashboardForm.get("date").setValue(latest_date);
    this.earMouldDashboardForm.controls["manufacturing"].setValue("Manufacturer");
    //

    this.route.queryParams.forEach(params => {
      this.pId = params.PatientId;
      //
      localStorage.setItem("fromGlobal",'false');
      this.patientRef = this.pId;
      this.fetchEarMouldOrderList();
      this.getPatient(this.patientRef);
    });
      this.fetchCompanyList();
      this.fetchPatientList();
      this.fetchBillingCompanyList();
      this.fetchBranchList();
     
     this.getLastId();
     this.getAllBranch();
  }
  getPatient(id){
    this.apimicroservice.getPatientById(id).subscribe(res => {     
        this.pName =res.data[0].firstName + ' ' + res.data[0].middleName + ' ' + res.data[0].lastName;

     });
  }
  getAllBranch()
  { 
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      if (res.status) {        
        let length =res.data.length - 1;

        this.branchName= res.data[length].branchName;
        this.branchRefId= res.data[length]._id; 
        this.branchNamee =res.data[length].branchName;

        this.earMouldDashboardForm.controls["branchRefId"].setValue(res.data[length]._id);
     
        if(this.userType == 'Super Admin')
        {  
          this.branchClick(res.data[length]._id,this.branchName);
          this.apimicroservice.getBranchById(res.data[length]._id).subscribe(res => {
            this.spinner.hide();
            this.earMouldDashboardForm.get("branchName").setValue(res.data[length].branchName);
           });
        }
       
      }
    });
  }
 
  fetchEarMouldOrderList() {
    let pg = 1;
    let size = 5;
 //   this.spinner.show();
 let clinicRef= sessionStorage.getItem('clinicRef');
 let branchRef= sessionStorage.getItem('branchRef');
 let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
 let branchRefSuperAdmin= '';
 let patientRef=sessionStorage.getItem('ear_patientRefId');
    this.searchText = this.earMouldDashboardForm.get('searchEarMouldOrder').value;

    if(this.userType !== 'Super Admin')
     {
      this.apimicroservice.getEarMouldOrderDashboard(pg,size,this.searchText,branchRef,patientRef,clinicRef).subscribe(res => {
        //
        this.listOfEarMouldOrders = res.data.data;
        this.totalLength = res.data.totalItems;
        //this.buildingLenght = this.listofbuild.length;
       if(res.status){
       //  this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
      });
     }else{
      this.apimicroservice.getEarMouldOrderDashboard(pg,size,this.searchText,branchRefSuperAdmin,this.patientRef,clinicRefSuperAdmin).subscribe(res => {
        //
        this.listOfEarMouldOrders = res.data.data;
        this.totalLength = res.data.totalItems;
        //this.buildingLenght = this.listofbuild.length;
       if(res.status){
       //  this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
      });
     }

  }
  toearMouldDashboardForm() {
    document.getElementById("toearMouldDashboardForm").scrollIntoView();
  }
  toearMouldDashboardFormList() {
    document.getElementById("toearMouldDashboardFormList").scrollIntoView();
  }
  prepopulation(id)
  {
    this.apimicroservice.getEarMouldDataById(id).subscribe(res => {
      //
      let data = res.data[0];
      this.earId = id;
    
         this.updateButton = true;
         this.submitButton = false;
         this.cancelButton = true;
         
         this.branchClick(data.branchRefId,data.branchName);
         this.onSelectPatient(data.patientRefId);
        
        //  this.onSelectDoctor(data.doctorRefId);
        this.earMouldDashboardForm = this.fb.group({
          "branchWiseId": new FormControl(data.branchWiseId),
      "date": new FormControl(data.date),
      "manufacturing": new FormControl(data.manufacturing),   
      "branchRefId": new FormControl(data.branchRefId),
      "clinicRefId": new FormControl(data.clinicRefId),
      "branchName": new FormControl(data.branchName),
      "patientRefId": new FormControl(data.patientRefId,Validators.required),
      "patientUniqueId": new FormControl(data.patientUniqueId),
   ///   "patientName": new FormControl(data.patientName),
      "firstName": new FormControl(data.firstName),
      "lastName": new FormControl(data.lastName),
      "mobile1": new FormControl(data.mobile1),
      "age" :new FormControl(data.age),

      "companyRefId": new FormControl(data.companyRefId,Validators.required),
      "companyName": new FormControl(data.companyName),
      "companyAddress": new FormControl(data.companyAddress),
      "companyEmail": new FormControl(data.companyEmail),
     "canNumber":new FormControl(data.canNumber),
      "ear": new FormControl(data.ear,Validators.required),
      "leftStyle":new FormControl(data.leftStyle),
      "rightStyle":new FormControl(data.rightStyle),
      "leftHAType" : new FormControl(data.leftHAType),
      "rightHAType" : new FormControl(data.rightHAType),
      "leftHardness" :new FormControl(data.leftHardness),
      "rightHardness" :new FormControl(data.rightHardness),
      "leftVent":new FormControl(data.leftVent),
      "rightVent":new FormControl(data.rightVent),
      "leftCanalLenght":new FormControl(data.leftCanalLenght),
      "rightCanalLenght":new FormControl(data.rightCanalLenght),
      "leftRemovalString":new FormControl(data.leftRemovalString),
      "rightRemovalString":new FormControl(data.rightRemovalString),
      "estimateRequired" : new FormControl(data.estimateRequired),
      "complaint" :new FormControl(data.complaint),
      "audiogram" :new FormControl(data.audiogram),
      "audiogramFile" :new FormControl(data.audiogramFile),
     // "earImpression" :new FormControl(data.earImpression),
      "earImpressionText" :new FormControl(data.earImpressionText,[Validators.pattern("^[0-9]*$"),Validators.maxLength(10)]),
      "billingRefId" :new FormControl(data.billingRefId,Validators.required),
      "billingCompanyName":new FormControl(data.billingCompanyName),
      "searchEarMouldOrder":new FormControl(''),
      "patientName": new FormControl(data.firstName + ' ' + data.lastName),
        "updatedAt": new Date(),
          
        });
    this.superBranchId = data.branchWiseId;
    this.audiogramFile = data.audiogramFile ? data.audiogramFile : "../../assets/images/setting2.png";
     });
  }
 
  cancelUpdate(){
    this.earMouldDashboardForm.reset();
     this.submitButton=true;
     this.updateButton=false;
     this.cancelButton = false;
    this.ngOnInit();
  }

  submitEarMouldOrderList() {
    let payload = this.earMouldDashboardForm.value;
    if(this.userType == 'Super Admin')
    {
      this.earMouldDashboardForm.controls['branchRefId'].setValidators(Validators.required);
      this.earMouldDashboardForm.controls['branchRefId'].updateValueAndValidity();
    }
    else{
      this.earMouldDashboardForm.controls['branchRefId'].setErrors(null);
      this.earMouldDashboardForm.controls['branchRefId'].clearValidators();
      this.earMouldDashboardForm.controls['branchRefId'].updateValueAndValidity();
    }
    
    if (this.earMouldDashboardForm.invalid) {

       this.earMouldDashboardForm.get("patientRefId").markAsTouched();
       this.earMouldDashboardForm.get("billingRefId").markAsTouched();
       this.earMouldDashboardForm.get("companyRefId").markAsTouched();
       this.earMouldDashboardForm.get("ear").markAsTouched();
       

    }else {
      this.saveData();
    }
    
  }

  
  saveData(){
    
    let payload = this.earMouldDashboardForm.value;
    //
    payload.earMoldOrderDate = this.date;
    if(this.userType !== 'Super Admin')
    {
      payload.branchRefId = this.branchRef;
      payload.clinicRefId = this.clinicRef;
      payload.branchName = this.branchName;
    }
    this.spinner.show();
    payload.audiogramFile = this.audiogramFile;
    this.apimicroservice.addEarMold(payload).subscribe(res => {
      this.spinner.hide();
      this.toearMouldDashboardForm();
      this.ngOnInit();
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    },(ERROR:HttpErrorResponse) => {
      this.toearMouldDashboardForm();
      this.apimicroservice.openSnackbar(ERROR.error.message);
      this.spinner.hide();
  });  
  } 

  updateEarMouldOrderList() {
    let payload = this.earMouldDashboardForm.value;
    if(this.userType == 'Super Admin')
    {
      this.earMouldDashboardForm.controls['branchRefId'].setValidators(Validators.required);
      this.earMouldDashboardForm.controls['branchRefId'].updateValueAndValidity();
    }
    else{
      this.earMouldDashboardForm.controls['branchRefId'].setErrors(null);
      this.earMouldDashboardForm.controls['branchRefId'].clearValidators();
      this.earMouldDashboardForm.controls['branchRefId'].updateValueAndValidity();
    }
    
    if (this.earMouldDashboardForm.invalid) {

       this.earMouldDashboardForm.get("patientRefId").markAsTouched();
       this.earMouldDashboardForm.get("billingRefId").markAsTouched();
       this.earMouldDashboardForm.get("companyRefId").markAsTouched();
       this.earMouldDashboardForm.get("ear").markAsTouched();
    }else {
      this.updateEarMoldData();
    }
    
  }

  updateEarMoldData() {
    this.spinner.show();
    let payload = this.earMouldDashboardForm.value;
    payload.earMoldOrderDate = this.date;
    if(this.userType !== 'Super Admin')
    {
      payload.branchRefId = this.branchRef;
      payload.clinicRefId = this.clinicRef;
      payload.branchName = this.branchName;
    }
    payload.audiogramFile = this.audiogramFile;
     this.apimicroservice.updateEarMold(payload,this.earId).subscribe(res => {
       //
       this.spinner.hide();
       if(res.status){
         this.apimicroservice.openSnackbar(res.message);
          this.updateButton = false;
          this.submitButton = true;
         this.cancelButton=false;
         this.toearMouldDashboardForm();
          this.ngOnInit();
       }else{
       //  //this.apimicroservice.openSnackbar(res.message);
       }
     });
    
  }
  
  onKeyUpEvent(event: any) {
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    let patientRef=sessionStorage.getItem('ear_patientRefId');
    this.searchText = this.earMouldDashboardForm.get('searchEarMouldOrder').value;

    if(this.userType !== 'Super Admin')
     {
      this.apimicroservice.getEarMouldOrderDashboard(this.page,this.size,this.searchText,branchRef,patientRef,clinicRef).subscribe(res => {
        //
        this.listOfEarMouldOrders = res.data.data;
        this.totalLength = res.data.totalItems;
        //this.buildingLenght = this.listofbuild.length;
       if(res.status){
        // this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
      });
     }else{
      this.apimicroservice.getEarMouldOrderDashboard(this.page,this.size,this.searchText,branchRefSuperAdmin,patientRef,clinicRefSuperAdmin).subscribe(res => {
        //
        this.listOfEarMouldOrders = res.data.data;
        this.totalLength = res.data.totalItems;
        //this.buildingLenght = this.listofbuild.length;
       if(res.status){
       //  this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
      });
    }
  }
  onPageChange(event) {
    this.page = event;
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    let patientRef=sessionStorage.getItem('ear_patientRefId');
    this.searchText = this.earMouldDashboardForm.get('searchEarMouldOrder').value;

    if(this.userType !== 'Super Admin')
     {
      this.apimicroservice.getEarMouldOrderDashboard(this.page,this.size,this.searchText,branchRef,patientRef,clinicRef).subscribe(res => {
        //
        this.listOfEarMouldOrders = res.data.data;
        this.totalLength = res.data.totalItems;
        //this.buildingLenght = this.listofbuild.length;
       if(res.status){
        // this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
      });
     }else{
      this.apimicroservice.getEarMouldOrderDashboard(this.page,this.size,this.searchText,branchRefSuperAdmin,patientRef,clinicRefSuperAdmin).subscribe(res => {
        //
        this.listOfEarMouldOrders = res.data.data;
        this.totalLength = res.data.totalItems;
        //this.buildingLenght = this.listofbuild.length;
       if(res.status){
        // this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
      });
    }
  }
  fetchPatientList(){
    this.searchText = '';
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
      this.apimicroservice.getPatientListByBranchClinic(branchRef,clinicRef).subscribe(res => {
        //
        
        if (res.status) {    
          this.patientList = res.data;
          this.filteredListPatient = this.patientList.slice();
          // this.onSelectPatient(this.patientList[0]._id);
          // this.earMouldDashboardForm.get("patientRefId").setValue(this.patientList[0]._id);
        } else {
         // this.apimicroservice.openSnackbar(res.message);
        }
      })
   // }
   
  }
  fetchCompanyList(){
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    this.apimicroservice.getAllCompanylist(branchRef,clinicRef).subscribe(res => {
      //
       if (res.status) {    
         this.companyList = res.data.data;
         this.filteredListCompany= this.companyList.slice();
        //  this.onSelectCompany(this.companyList[0].companyName,this.companyList[0].companyAddress,this.companyList[0].companyEmail,this.companyList[0].canNumber);
        
        //  this.earMouldDashboardForm.get("companyRefId").setValue(res.data.data[0]._id);
       } else {
       //  this.apimicroservice.openSnackbar(res.message);
       }
     })
  }

  fetchBranchList() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      if (res.status) {    
        this.BranchList = res.data;
        this.filteredListBranch= this.BranchList.slice();
      } else {
      //  this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
  
  branchClick(id,branchName){

   this.earMouldDashboardForm.controls["branchName"].setValue(branchName);
   this.branchRef = id;
   this.searchText = '';
  //  this.apimicroservice.getPatientListByBranchClinic(id,this.clinicRef).subscribe(res => {
  //    //
  //    //
  //    if (res.status) {  
  //      if(res.data.length > 0){
  //       this.patientList = res.data;
  //       this.filteredListPatient = this.patientList.slice();
  //       // this.onSelectPatient(this.patientList[0]._id);
  //       // this.earMouldDashboardForm.get("patientRefId").setValue(this.patientList[0]._id);
         
  //      }  else{
  //       this.patientList = res.data;
  //      } 
       
  //    } else {
  //    //  this.apimicroservice.openSnackbar(res.message);
  //    }
  //  })

   this.apimicroservice.getBranchById(id).subscribe(res => { 
     //
       
     let data = res.data[0];
     let payload = this.earMouldDashboardForm.value;
      payload.clinicRefId = res.data[0].clinicRefId;
      this.earMouldDashboardForm.controls["clinicRefId"].setValue(res.data[0].clinicRefId);
      if(this.updateButton == false){
       //  this.spinner.show();
        this.apimicroservice.getLastEarMoldID(payload.branchRefId).subscribe(res => {
            
         
          if (res['status']) {
            let repairIdd = res['data']['totalItems'];
            this.earMouldDashboardForm.controls['branchWiseId'].setValue(repairIdd + 1);
          } else {
            this.apimicroservice.openSnackbar("Something went wrong");
          }
         //  this.spinner.hide();
        })
      }
    });

 }

 onSelectPatient(id){
  this.patientSelect=true;
  this.apimicroservice.getPatientById(id).subscribe(res => {
    let data = res.data[0];
    this.pid = id;
//

    this.earMouldDashboardForm.controls["firstName"].setValue(data.firstName);
    this.earMouldDashboardForm.controls["lastName"].setValue(data.lastName);
    this.earMouldDashboardForm.controls["patientUniqueId"].setValue(data.patientId);
    this.earMouldDashboardForm.controls["mobile1"].setValue(data.mobile1);
    this.earMouldDashboardForm.controls["age"].setValue(data.age);


    if (data.dob) {
      var timeDiff = Math.abs(Date.now() - new Date(data.dob).getTime());
      let age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
      //
      this.earMouldDashboardForm.controls["age"].setValue(age);
        }
    
   
  });
}
getLastId()
{
  if(this.userType !== 'Super Admin'){
  this.apimicroservice.getLastEarMoldID(this.branchRef).subscribe(res => {
    //   
    if (res['status']) {
      
      let clientIdd = res.data.totalItems;
      //
      
      this.earMouldDashboardForm.controls['branchWiseId'].setValue(clientIdd + 1);
      
      
    } else {
      this.apimicroservice.openSnackbar("Something went wrong");
    }
   
  })
}
else
{
  this.apimicroservice.getEarMoldLastAll().subscribe(res => {
    //   
    if (res['status']) {
      
      let clientIdd = res.data.totalItems;
      //
      
      this.superBranchId = clientIdd + 1;
      
      
      //this.earMouldDashboardForm.controls['branchWiseId'].setValue(clientIdd + 1);
    } else {
      this.apimicroservice.openSnackbar("Something went wrong");
    }
   
  })
}
}

onSelectCompany(cname,address,email,canNumber){
  //
  
  this.companyName = cname;
  this.address = address;
  this.email = email;
  this.canNumber = canNumber;

  this.earMouldDashboardForm.controls['companyName'].setValue(this.companyName);
  this.earMouldDashboardForm.controls['companyAddress'].setValue(this.address);
  this.earMouldDashboardForm.controls['companyEmail'].setValue(this.email);
  this.earMouldDashboardForm.controls['canNumber'].setValue(this.canNumber)
  }

  companyClick(bid,bcname){
    this.billingCompany = bcname;
    this.earMouldDashboardForm.controls['billingCompanyName'].setValue(this.billingCompany);
  }

  fetchBillingCompanyList(){
    let id='';
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getBillingDataById(id,clinicRef).subscribe(res => {
      //
      this.billingCompanyList = res.data;
      this.filteredListBillingCompany= this.billingCompanyList.slice();
      if(res.status){
        //this.apimicroservice.openSnackbar(res.message);
        
      }else{
        //this.apimicroservice.openSnackbar(res.message);
      }
    });
  }
 openPatientPopUp() {
    
    const dialogRef3 = this.dialog.open(PopUpPatientComponent,
      {
        width: "1200px",
      });

      dialogRef3.afterClosed().subscribe((result) => {
        let clinicRef= sessionStorage.getItem('clinicRef');
        let branchRef= sessionStorage.getItem('branchRef');
        this.apimicroservice.getPatientListByBranchClinic(branchRef,clinicRef).subscribe(res => {
          //
          
          if (res.status) {    
            this.patientList = res.data;
            let length =res.data.length - 1;
            this.filteredListPatient = this.patientList.slice();
            this.onSelectPatient(this.patientList[length]._id);
            this.earMouldDashboardForm.get("patientRefId").setValue(this.patientList[length]._id);
          } else {
           // this.apimicroservice.openSnackbar(res.message);
          }
        })
       });
  }
openBranchPopUp() {
  
  const dialogRef3 = this.dialog.open(PopUpBranchComponent,
    {
      width: "1200px",
    });

    dialogRef3.afterClosed().subscribe((result) => {
      let clinicRef= sessionStorage.getItem('clinicRef');
      this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
        if (res.status) {    
          this.BranchList = res.data;
          this.filteredListBranch= this.BranchList.slice();
          this.branchClick(res.data[0]._id,res.data[0].branchName);
          this.earMouldDashboardForm.get("branchRefId").setValue(res.data[0]._id);
        } else {
        //  this.apimicroservice.openSnackbar(res.message);
        }
      })
     // this.fetchBranchList();  
     });
} 

openCompanyPopUp(){
  const dialogRef3 = this.dialog.open(PopupCompanyComponent,
    {
      width: "900px",
    });

    dialogRef3.afterClosed().subscribe((result) => {
      //this.fetchCompanyList();  
      let clinicRef= sessionStorage.getItem('clinicRef');
      let branchRef= sessionStorage.getItem('branchRef');
      this.apimicroservice.getAllCompanylist(branchRef,clinicRef).subscribe(res => {
        //
         if (res.status) {    
           this.companyList = res.data.data;
           this.filteredListCompany= this.companyList.slice();
           this.onSelectCompany(this.companyList[0].companyName,this.companyList[0].companyAddress,this.companyList[0].companyEmail,this.companyList[0].canNumber);
          
           this.earMouldDashboardForm.get("companyRefId").setValue(res.data.data[0]._id);
         } else {
         //  this.apimicroservice.openSnackbar(res.message);
         }
       })
     });
}

openBillingCompPopUp(){
  const dialogRef3 = this.dialog.open(PopupBillingcompanyComponent,
    {
      width: "1200px",
    });

    dialogRef3.afterClosed().subscribe((result) => {
      let id='';
      let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getBillingDataById(id,clinicRef).subscribe(res => {
     
      if(res.status){
        //this.apimicroservice.openSnackbar(res.message);
        //
        this.billingCompanyList = res.data;
        this.filteredListBillingCompany= this.billingCompanyList.slice();
       this.companyClick(res.data[0]._id,res.data[0].companyName);
       this.earMouldDashboardForm.get("billingRefId").setValue(res.data.data[0]._id);
      }else{
        //this.apimicroservice.openSnackbar(res.message);
      }
    });
      //this.fetchBillingCompanyList();  
     });
}

onChange($event: Event) {
  const file = ($event.target as HTMLInputElement).files[0];
  const fileName = file.name;
  this.errormessage = '';
  let ext = fileName.substring(fileName.lastIndexOf(".") + 1);
  if (
    ext.toLowerCase() == "png" ||
    ext.toLowerCase() == "jpg" ||
    ext.toLowerCase() == "jpeg" ||
    ext.toLowerCase() == "csv" ||
    ext.toLowerCase() == "xlsx" ||
    ext.toLowerCase() == "pdf" ||
    ext.toLowerCase() == "docx" 
  ) { 
    this.convertToBase64(file);
  }
}
convertToBase64(file: File) {
  const observable = new Observable((subscriber: Subscriber<any>) => {
    this.readFile(file, subscriber);
  });
  observable.subscribe((d) => {
    this.audiogramFile = d;
    
  });
}
readFile(file: File, subscriber: Subscriber<any>) {
  const fileReader = new FileReader();
  fileReader.readAsDataURL(file);

  fileReader.onload = () => {
    subscriber.next(fileReader.result);
    subscriber.complete();
  };
  fileReader.onerror = (error) => {
    subscriber.error(error);
    subscriber.complete();
  };
}

copyToRight(){
    
  //
  let payload = this.earMouldDashboardForm.value;

  this.earMouldDashboardForm.controls["rightStyle"].setValue(payload.leftStyle);
  this.earMouldDashboardForm.controls["rightHAType"].setValue(payload.leftHAType);
  this.earMouldDashboardForm.controls["rightHardness"].setValue(payload.leftHardness);
 
  this.earMouldDashboardForm.controls["rightVent"].setValue(payload.leftVent);
  this.earMouldDashboardForm.controls["rightCanalLenght"].setValue(payload.leftCanalLenght);

  this.earMouldDashboardForm.controls["rightRemovalString"].setValue(payload.leftRemovalString);
 

}
leftReset(){ 
  this.earMouldDashboardForm.controls["leftStyle"].reset();
this.earMouldDashboardForm.controls["leftHAType"].reset();
this.earMouldDashboardForm.controls["leftHardness"].reset();
this.earMouldDashboardForm.controls["leftVent"].reset();
this.earMouldDashboardForm.controls["leftCanalLenght"].reset();
this.earMouldDashboardForm.controls["leftRemovalString"].reset();



}
copyToLeft(){
    
  //
  let payload = this.earMouldDashboardForm.value;

  this.earMouldDashboardForm.controls["leftStyle"].setValue(payload.rightStyle);
  this.earMouldDashboardForm.controls["leftHAType"].setValue(payload.rightHAType);
  this.earMouldDashboardForm.controls["leftHardness"].setValue(payload.rightHardness);
 
  this.earMouldDashboardForm.controls["leftVent"].setValue(payload.rightVent);
  this.earMouldDashboardForm.controls["leftCanalLenght"].setValue(payload.rightCanalLenght);

  this.earMouldDashboardForm.controls["leftRemovalString"].setValue(payload.rightRemovalString);
 

}
rightReset(){ 
  this.earMouldDashboardForm.controls["rightStyle"].reset();
this.earMouldDashboardForm.controls["rightHAType"].reset();
this.earMouldDashboardForm.controls["rightHardness"].reset();
this.earMouldDashboardForm.controls["rightVent"].reset();
this.earMouldDashboardForm.controls["rightCanalLenght"].reset();
this.earMouldDashboardForm.controls["rightRemovalString"].reset();



}
globalPrint() {
  let currentDate =new Date();
let date=currentDate.toLocaleDateString('en-GB');
  let titleName='Ear Mould Form_'+date+'.pdf'
    var tempTitle = document.title;
    document.title = titleName;
    window.print();
    document.title = tempTitle;
}  
WhatsApp()
  {
    let payload = this.earMouldDashboardForm.value;  
    let num = payload.mobile1; 
    if(num.length == 10)
    {
      sessionStorage.setItem('whatsAppNumber',payload.mobile1);
      let mobileNumber = sessionStorage.getItem('whatsAppNumber');
      window.open("https://wa.me/91"+mobileNumber);
    }
    else
    {
      this.earMouldDashboardForm.get("mobile1").markAsTouched();
    }
  }
  onback() {
    this.apimicroservice.back()
  }


  onEarChange(e){
    let payload = this.earMouldDashboardForm.value;
    //
    
    if(e == 'Right')
    {
      this.RightDisable = false;
      this.LeftDisable = true;
    }
    else if(e == 'Left')
    {
      this.LeftDisable = false;
      this.RightDisable = true;
    }
    else
    {
      this.RightDisable = false;
      this.LeftDisable = false;
    }
   }
   openDepartmentPopUp() {
    const dialogRef3 = this.dialog.open(PatientSearchPopComponent, {
      width: "97%",
      height: "90%", 
    });

    dialogRef3.afterClosed().subscribe((result) => {
      this.earMouldDashboardForm.get("patientName").setValue(result.data.firstName + ' ' + result.data.lastName);
      this.earMouldDashboardForm.get("patientRefId").setValue(result.data._id);
      this.onSelectPatient(result.data._id);
    
    });
  }
  redirectToEarMouldForm(earMouldNo){ 
    this.router.navigate(['ear-mould',earMouldNo]);
  }
  earMold(branchRefId,patientRefId,branchName,patientName)
    { 
      sessionStorage.setItem('ear_branchRefId',branchRefId);
      sessionStorage.setItem('ear_patientRefId',patientRefId);
      sessionStorage.setItem('ear_branchName',branchName);
      sessionStorage.setItem('ear_patientName',patientName);
     this.redirect('/home/ear-mould');
    }
  redirect(to): void {
    this.router.navigate([to]);
   }
}