import { Component, Inject, OnInit } from "@angular/core";
import {FormGroup,FormBuilder,FormControl} from '@angular/forms';
import { ApiServiceService } from '../api-service.service';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-popup-add-instructions',
  templateUrl: './popup-add-instructions.component.html',
  styleUrls: ['./popup-add-instructions.component.scss']
})
export class PopupAddInstructionsComponent implements OnInit {
  instructionId:any;
  variables3: any;
  filteredList1: any;
  filteredList2: any;
  variables4: any;
  popupAddInstructionsForm: FormGroup;
  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<PopupAddInstructionsComponent>,@Inject(MAT_DIALOG_DATA) public data:any,
    public apimicroservice: ApiServiceService,
) {dialogRef.disableClose = true; }
addNewInstruction = false;

  ngOnInit() {
    this.instructionId=this.data.id;
   this.getDataById();
    this.popupAddInstructionsForm = this.fb.group({  
      "rightInstructionName": new FormControl(''),
      "leftInstructionName": new FormControl(''),
      "rightInstructionNameList": new FormControl(''),
      "leftInstructionNameList": new FormControl(''),
    });
    this.getLeftInstructionData();
    this.getrightInstructionData();
    
  }


  saveLeftInstructionData(){
    let payload = this.popupAddInstructionsForm.value;
   
    
    this.apimicroservice.submitLeftInstructionData(payload).subscribe(res => {
      //
      
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);
        
        this.ngOnInit();
        //this.loadBuildigdata()
       this.onClose();
        
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    });
  }

  saveRightInstructionData(){
    let payload = this.popupAddInstructionsForm.value;
   
    
    this.apimicroservice.submitRightInstructionData(payload).subscribe(res => {
      //
      
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);
        
        this.ngOnInit();
        //this.loadBuildigdata()
       this.onClose();
        
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    });
  }

  getLeftInstructionData(){
    this.apimicroservice.getLeftInstructionData().subscribe(res => {
      //
      this.variables3 = res.data;
      this.filteredList1 = this.variables3.slice();
    });

  }
  getrightInstructionData(){
    this.apimicroservice.getRightInstructionData().subscribe(res => {
      //
      this.variables4 = res.data;
      this.filteredList2 = this.variables4.slice();
    });
  }

  getDataById()
  {
   
  }
  
  onClose(): void {
    this.dialogRef.close();
  }

  addNewInstructionFunc()
  {
    this.addNewInstruction = true;
  }
  cancelFunc()
  {
    this.addNewInstruction = false;
  }

}