<div class="container-fluid bgcolor2" [formGroup]="repairActionForm">
    <form [formGroup]="repairActionForm" autocomplete="off" novalidate>
      <div class="row col-sm-12 p-0 m-0">
        <div class="col-sm-12">
          <div class="row pl-0 ">
            <div class="col-12 pl-0 ">
              <div class="row ">
              <div class="col-sm-9">
              
                  <h1 class="pt-2 pl-0" id="torepairActionForm">Add New Repair Action</h1>
                  </div> 
                  <div class="col-sm-3 mt-1">
                    <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
            
                      <!-- <a (click)="toFollowupList()" class="d-flex align-items-center ms-3 pr-2 mt-2"><span class="material-symbols-outlined pr-2">reorder</span>List</a>   -->
                      <a (click)="onClose()" class="d-flex align-items-center ms-3 pr-2 mt-2"><span class="material-symbols-outlined pr-2">close</span></a>
                    </div>
            </div>
          </div>
         </div>
         </div>
  
  
          <div class="row ">
            <div class="col-sm-12 pr-4">
              <div class="row">
                <div class="card divbody scroll height borderradius mb-2 p-1">
                    <div class="col-sm-12">
                     
                      <div class="row pt-0 mt-0">
                         
                      <div class="col-sm-12">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Select Action </mat-label>
                          <mat-select placeholder="Type"  
                          class="colorblack" 
                          formControlName="repairActionName" > 
                          <mat-option (click)="actionClick();" value="Sent To">1. Sent To</mat-option>
                          <mat-option (click)="actionClick();" value="Estimate Received From">2. Estimate Received From</mat-option>
                          <mat-option (click)="actionClick();" value="Estimate Calculated">3. Estimate Calculated</mat-option>
                          <mat-option (click)="actionClick();" value="Estimate Advised To">4. Estimate Advised To</mat-option>
                          <mat-option (click)="actionClick();" value="Estimate Approved By">5. Estimate Approved By</mat-option>
                          <mat-option (click)="actionClick();" value="Estimate Rejected By">6. Estimate Rejected By</mat-option>
                          <mat-option (click)="actionClick();" value="Received From">7. Received From</mat-option>

                          <mat-option (click)="actionClick();" value="Programmed By">8. Programmed By</mat-option>
                          <mat-option (click)="actionClick();" value="Checked By">9. Checked By</mat-option>
                      
                          <mat-option (click)="actionClick();" value="Communication With">10. Communication With</mat-option>
                          <mat-option (click)="actionClick();" value="Unrepaired Ready">11. Unrepaired Ready</mat-option>
                          <mat-option (click)="actionClick();" value="Create Final Bill">12. Create Final Bill</mat-option>
                          <mat-option (click)="actionClick();" value="Payment Done">13. Payment Done</mat-option>
                          <mat-option (click)="actionClick();" value="Duly Repaired Ready At">14. Duly Repaired Ready At</mat-option>
                          <mat-option (click)="actionClick();" value="Collected By">15. Collected By</mat-option>
                          <mat-option (click)="actionClick();" value="Change of Device">16. Change of Device</mat-option>
                  
                  
                   
                  
                    
                   
                    
                    
                   
                         </mat-select>   
                        </mat-form-field>
                        <mat-error 
                        *ngIf="repairActionForm.controls['repairActionName'].touched && repairActionForm.controls['repairActionName'].errors?.required">
                            This field is mandatory.
                      </mat-error>
                      </div>
                      </div>
                      <div class="row pt-0 mt-0">
                        <div *ngIf="ActionPaymentDone" class="col-sm-12 mt-2">
                            <mat-form-field class="lable_style header" appearance="outline">
                              <mat-label>Select</mat-label>
                              <mat-select placeholder="Payment Type" class="colorblack" formControlName="selectPaymentType">
                           <mat-option value="Full">Full</mat-option>
                           <mat-option value="Partial">Partial</mat-option> 
                             </mat-select>
                            </mat-form-field>
                         </div>
                      <div *ngIf="ActionPaymentDone " class="col-sm-12 mt-2">
                        <!-- <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Payment Type</mat-label>
                            <mat-select placeholder="Payment Type"  
                            class="colorblack" 
                            formControlName="paymentType" >
                      <mat-option value="Full">Full</mat-option>
                      <mat-option value="Partial">Partial</mat-option>
                           </mat-select>   
                          </mat-form-field> --> 
                        
                          <mat-form-field class="lable_style header " appearance="outline">
                            <mat-label>Payment Type</mat-label>
                            <mat-select placeholder="Payment Mode" class="colorblack" formControlName="paymentType">
                         <mat-option (click)="ModeOfPaymentClick('Cash')" value="Cash">Cash</mat-option>
                         <mat-option (click)="ModeOfPaymentClick('Card')" value="Card">Card</mat-option>
                         <mat-option (click)="ModeOfPaymentClick('UPI')" value="UPI">UPI</mat-option>
                         <mat-option (click)="ModeOfPaymentClick('Cheque')" value="Cheque">Cheque</mat-option>
                         <mat-option (click)="ModeOfPaymentClick('RTGS/NEFT/IMPS')" value="RTGS/NEFT/IMPS">RTGS/NEFT/IMPS</mat-option>
                            </mat-select>
                           

                          </mat-form-field>
                       </div>
                       
                       <!-- <div *ngIf="ActionCheckedBy || ActionProgrammedBy" class="col-sm-12">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>User Type</mat-label>
                          <mat-select formControlName="userType">
                            <mat-option (click)="doctorSelect()" value="Doctor">Audiologists</mat-option>
                            <mat-option (click)="clinicalStaffSelect()" value="Clinical Staff">Clinical Staff</mat-option>
                            </mat-select>
                         
                        </mat-form-field> 
                  
                      </div> -->
                      <div class="col-sm-12 mt-2" *ngIf="ActionCheckedBy || ActionProgrammedBy">
                        <!-- <mat-form-field class="example-full-width lable_style2" appearance="outline">
                          <mat-label>User Name</mat-label>
                          <input matInput 
                          placeholder="User Name" formControlName="userName" />
                           </mat-form-field> -->
                           <mat-label class="labelStyle " ngDefaultControl formControlName="userName">
                            <div class="row">
                              <div class="col-sm-4">
                              <h3>User Name : </h3> 
                              </div>
                              <div class="col-sm-8">
                               <h3 >{{userName}} </h3> 
                               </div>
                              </div></mat-label> 
                  </div>


                      <!-- <div *ngIf="isDoctor" class="col-sm-12">
                        <div class="row">
                          <div class="col-sm-10 pr-1"> 
                        <mat-form-field
                          class="example-full-width" 
                          appearance="outline" >
                          <mat-label>Audiologists Name</mat-label>
                          <mat-select required #select1 (click)="fetchDoctorList();" formControlName="userRefId" >
                            <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'firstName'"
                            [array]="doctorList"  (filteredReturn)="filteredListDoctor =$event"></mat-select-filter>
                          <mat-option *ngFor="let item of filteredListDoctor" (click)="doctorClick(item.firstName,item.lastName)" [value]="item._id"
                          >
                           {{ item.firstName }}  {{ item.lastName }}</mat-option>
                          </mat-select>
                         
                        </mat-form-field>
                      </div>
                      <div class="col-sm-2 mTopp mt-2 no-print">
                        <i (click)="openDoctorPopUp();" matTooltip="Add New Audiologists" 
                        class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                           </div>
         
                      </div>
                      </div>
                      <div *ngIf="isclinicalStaff" class="col-sm-12">
                        <div class="row">
                          <div class="col-sm-10 pr-1"> 
                        <mat-form-field
                          class="example-full-width"
                          appearance="outline" > 
                          <mat-label>Clinical Staff Name</mat-label>
                          <mat-select required #select1 (click)="fetchClinicalStaffList();" formControlName="userRefId" >
                            <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'staffMemberName'"
                            [array]="clinicalStaffList"  (filteredReturn)="filteredListClinicalStaff =$event"></mat-select-filter>
                          <mat-option *ngFor="let item of filteredListClinicalStaff" 
                          (click)="clinicalClick(item.staffMemberName)" [value]="item._id"
                          >
                           {{ item.staffMemberName }}</mat-option>
                          </mat-select>
                         
                        </mat-form-field>
                      </div>
                      <div class="col-sm-2 mTopp  mt-2 no-print">
                        <i (click)="openClinicalStaffPopUp();" matTooltip="Add New ClinicalStaff" 
                        class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                           </div>
                      
                      </div>
                      </div>  -->
                 
 
                    <!-- <div *ngIf="ActionCommunicationWith || ActionSentTo || ActionReceivedFrom" class="col-sm-12">
                      <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Select</mat-label>
                          <mat-select placeholder="Select Type"  
                          class="colorblack" 
                          formControlName="clientVendorBranchRadioBtn" >
                    <mat-option (click)="selectType('Client')" value="Client">Client</mat-option>
                    <mat-option (click)="selectType('Vendor')" value="Vendor">Vendor</mat-option>
                    <mat-option *ngIf="ActionSentTo" (click)="selectType('Branch')" value="Branch">Branch</mat-option>
                         </mat-select>   
                        </mat-form-field>
                     </div>  -->

                     <div *ngIf="ActionCommunicationWith || ActionSentTo || ActionReceivedFrom
                     || ActionCollectedBy || ActionEstimateAdvised || ActionEstimateReceived 
                     || ActionEstimateRejected || ActionEstimateApproved" class="col-sm-12">
                      <div class="row">
                        <div class="col-sm-4 mt-2"> 
                      <mat-label  class="mt-1 lclass">Select Type :</mat-label>
                      </div> 
                      <div class="col-sm-8">
                      <mat-radio-group formControlName="clientVendorBranchRadioBtn"
                      class="example-full-width" aria-label="Select an option">
                        <div class="row"> 
                            <div *ngIf="ActionCollectedBy || ActionCommunicationWith 
                            || ActionEstimateAdvised || ActionEstimateApproved || ActionEstimateRejected
                            || ActionReceivedFrom || ActionSentTo" class="col-sm-4 p-1 mt-2">
                        <mat-radio-button  (click)="selectType('Client')" value="Client">Patient</mat-radio-button>
                        </div>
                        <div *ngIf="ActionCollectedBy || ActionEstimateApproved
                        || ActionEstimateRejected || ActionEstimateAdvised "  class="col-sm-4 p-1 mt-2">
                          <mat-radio-button (click)="selectType('Next To Kin')" value="Next To Kin">Next To Kin</mat-radio-button>
                          </div>
                          <!-- <div class="col-sm-4 p-1 mt-2"></div> -->
                        <div *ngIf="ActionCommunicationWith 
                        || ActionEstimateReceived  || ActionReceivedFrom  || ActionSentTo"  class="col-sm-4 p-1 mt-2">
                      <mat-radio-button (click)="selectType('Vendor')" value="Vendor">HA Company</mat-radio-button>
                      </div>
                      <div *ngIf="ActionEstimateApproved" class="col-sm-4 p-1 mt-2">
                        <mat-radio-button 
                         (click)="selectType('Clinic')" value="Clinic">Clinic</mat-radio-button>
                        </div>
                      <div *ngIf="ActionSentTo" class="col-sm-4 p-1 mt-2">
                      <mat-radio-button  (click)="selectType('Branch')"
                       value="Branch">Branch</mat-radio-button>
                      </div>
                      </div>  
                    </mat-radio-group>
                    </div>
                    </div>
                    </div>
                    <div *ngIf="isNextToKin" class="col-sm-12 mt-1">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Next To Kin</mat-label>
                      <input  matInput placeholder="Other"
                     formControlName="other" />
                    </mat-form-field> 
                   </div> 
                   <div *ngIf="isClinic" class="col-sm-12 mt-3">
                      <mat-label class="labelStyle " ngDefaultControl formControlName="clinicName">
                        <div class="row ml-2">
                           <h5 >{{clinicName}} </h5> 
                          </div></mat-label> 

                  </div> 
                  <!-- *ngIf="ActionCollectedBy || isClient || ActionEstimateAdvised || ActionEstimateApproved
                     || ActionEstimateRejected" -->
                     <div *ngIf="isClient && bytheFault" class="col-sm-12 mt-3"> 
                        <mat-label class="labelStyle " ngDefaultControl formControlName="patientName">
                       
                            <div class="row">
                              <div class="col-sm-4">
                              <h3>Patient Name : </h3> 
                              </div>
                              <div class="col-sm-8">
                               <h3 >{{patientName}} </h3> 
                               </div>
                              </div>
                          </mat-label>  
                        <!-- <input   matInput   
                        placeholder="Patient Name" formControlName="patientName" /> -->
                    </div> 
                    <div class="col-sm-12 mt-3" *ngIf="isVendor || ActionEstimateReceived">
                      <mat-label class="labelStyle " ngDefaultControl formControlName="vendorName">
                          
                             <div class="row">
                              <div class="col-sm-6">
                              <h3>HA Company : </h3> 
                              </div>
                              <div class="col-sm-6">
                               <h3 >{{companyName}} </h3> 
                               </div>
                               <div class="col-sm-6 mt-3">
                                <h3>Billing HA Company : </h3> 
                                </div> 
                                <div class="col-sm-6">
                                
                                    <div class="row"> 
                                      <div class="col-sm-10 mr-0 pr-0 pl-0 ml-0">
                                        <mat-form-field class="example-full-width mr-0 pr-0" appearance="outline">
                                          <mat-label>Select HA Company </mat-label>
                                          <mat-select #select3 formControlName="billingCompanyRefId" >
                                            <mat-select-filter *ngIf="select3.focused" [placeholder]="'Filter'" [displayMember]="'companyName'"
                                            [array]="companyList"  (filteredReturn)="filteredListCompany =$event"></mat-select-filter>    
                                            <mat-option *ngFor="let item of filteredListCompany" [value]="item._id">
                                           {{ item.companyName }}</mat-option>
                                          </mat-select>
                                        </mat-form-field> 
                                 
                                  
                                    </div>
                                    <div class="col-sm-2 mTop no-print">
                                      <i (click)="openCompanyPopUp();" matTooltip="Add Company" 
                                      class="blue_col mTop fa-solid fa-circle-plus ml-0 
                                       pl-1 fa-lg"></i>
                                    </div>  
                                    </div>
                                  </div>
                              </div>
                            
                            </mat-label> 
                    </div>
                 
                    <!-- <div *ngIf="ActionReceivedFrom && isClient" class="col-sm-3 mt-3"></div> -->
                    
                     <!-- <button class="btn btn-primary btn-sm mt-2 example-full-width gColor" 
                      type="button" color="secondary" (click)="openInward('Inward')">Add Inward</button> -->
                     <!-- Inward sectionnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn start -->
                          
                      <div *ngIf="ActionReceivedFrom || ActionSentTo" class="col-sm-12">
                          <div class="row"> 
                              <div class="col-sm-2 mt-3 " [hidden]="true">
                                <mat-label class="center" ngDefaultControl formControlName="branchWiseId" *ngIf="userType !== 'Super Admin'" >{{bId}}</mat-label>
                                <mat-label class="center" ngDefaultControl [value]="superBranchId" *ngIf="userType == 'Super Admin'" >{{superBranchId}}</mat-label>
                               </div>

                              <div class="col-sm-12">
                                  <mat-form-field class="example-full-width " appearance="outline">
                                      <mat-label>Date</mat-label>
                                      <input matInput placeholder="Choose a date" 
                                      formControlName="date" readonly>
                       
                                  </mat-form-field>
                                 
                              </div>
                            
                              <div class="col-sm-12">
                                    <mat-label class="labelStyle " ngDefaultControl formControlName="inwardOutward">
                                        <div class="row ml-2 mt-3">
                                           <h5 >{{isInwardOutward}} </h5> 
                                          </div>
                                    </mat-label> 
                                </div>
                              
                                <div class="col-sm-12"> 
                                  <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label *ngIf="outward">To</mat-label>
                                    <mat-label *ngIf="inward">From</mat-label> 
                                    <mat-select formControlName="refType">
                                      <mat-option (click)="selectRef('Client');" value="Client">Client</mat-option>
                                      <mat-option (click)="selectRef('Manufacturer');" value="Manufacturer">Manufacturer</mat-option>
                                    </mat-select>
                                  </mat-form-field>
                   <!-- <mat-error class="mt-1"
                  *ngIf="repairActionForm.controls['refType'].touched && repairActionForm.controls['refType'].errors?.required">
                 This field is mandatory.
                 </mat-error> -->
                                </div> 
                                <div *ngIf="client" class="col-sm-12">
                               
                                  <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>Patient Name</mat-label>
                                    <input matInput readonly placeholder="Patient Name" formControlName="patientName" />
                                    </mat-form-field> 
                                    <!-- <mat-error
                                    *ngIf="repairActionForm.controls['patientName'].touched && repairActionForm.controls['patientName'].errors?.required">
                                    This field is mandatory.
                                  </mat-error> -->
                             
                                  </div>
            
                                  <div *ngIf="manufacturer" class=" col-sm-12">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                      <mat-label>Manufacturer</mat-label>
                                      <input matInput readonly placeholder="Manufacturer Name" formControlName="companyName" />
                                      </mat-form-field>  
                                  
                                    </div>
                                                
                                                  <div class=" col-sm-12">
                                                    <div class="row">
                                                      <div class="col-sm-10 pr-1">
                                                        <mat-form-field class="example-full-width" appearance="outline">
                                                          <mat-label>Select Type</mat-label> 
                                                          <mat-select #select3  formControlName="typeRefId" >
                                                            <mat-select-filter *ngIf="select3.focused" [placeholder]="'Filter'" [displayMember]="'typeName'"
                                                            [array]="inoutTypeList" (filteredReturn)="filteredListInoutType =$event"></mat-select-filter>    
                                                            <mat-option *ngFor="let item of filteredListInoutType" 
                                                            [value]="item._id" (click)="type(item.inoutType);">
                                                           {{ item.inoutType }}</mat-option>
                                                           </mat-select>
                                                        </mat-form-field>
                                                
                                                    </div>
                                                    <div class="col-sm-2 mTop no-print">
                                                      <i (click)="openTypePopUp();" matTooltip="Add New Type" 
                                                      class="blue_col mTop fa-solid fa-circle-plus
                                                       pl-1 fa-lg"></i>
                                                    </div>  
                                                    </div>
                                                    </div>
                                                    <div *ngIf="isDevices" class="col-sm-12">
                                                      <mat-form-field class="example-full-width" appearance="outline">
                                                        <mat-label>Select Device</mat-label>
                                                        <mat-select formControlName="deviceType">
                                                          <mat-option value="New">New</mat-option>
                                                          <mat-option value="For Repair">For Repairs</mat-option>
                                                        </mat-select>
                                                      </mat-form-field>

                                                    </div>
                                                    <div *ngIf="isDocuments" class="col-sm-12 ">
                                                      <mat-form-field class="example-full-width" appearance="outline">
                                                        <mat-label>Select Documents</mat-label>
                                                        <mat-select formControlName="docType">
                                                          <mat-option value="Letter">Letter</mat-option>
                                                          <mat-option value="Miscellaneous">Miscellaneous</mat-option>
                                                        </mat-select>
                                                      </mat-form-field>

                                                    </div>
                                                    <div class="col-sm-12">
                                                      <mat-form-field class="example-full-width " appearance="outline">
                                                          <mat-label>Documents Number</mat-label>
                                                          <input matInput placeholder="Add Documents Number" 
                                                          formControlName="documentsNumber" >          
                                                      </mat-form-field> 
                                                 
                                              
                                                  </div> 
                                                
                                                  <div *ngIf="outward" class=" col-sm-12">
                                                    <div class="row">
                                                      <div class="col-sm-10 pr-1">
                                                        <mat-form-field class="example-full-width" appearance="outline">
                                                          <mat-label>City</mat-label> 
                                                          <mat-select #select3 formControlName="cityRefId" >
                                                            <mat-select-filter *ngIf="select3.focused" [placeholder]="'Filter'" [displayMember]="'cityName'"
                                                            [array]="cityList" (filteredReturn)="filteredListCity =$event"></mat-select-filter>    
                                                            <mat-option *ngFor="let item of filteredListCity" 
                                                            [value]="item._id" (click)="selectCity(item.cityName);">
                                                           {{ item.cityName }}</mat-option>
                                                          </mat-select>
                                                        </mat-form-field>
                                                 
                                                    </div>
                                                    <div class="col-sm-2 mTop no-print">
                                                      <i (click)="openFromCityPopUp();" matTooltip="Add City" class="blue_col mTop fa-solid fa-circle-plus
                                                       pl-1 fa-lg"></i>
                                                    </div>  
                                                    </div>
                                                    </div>
                                                  <div class=" col-sm-12">
                                                    <div class="row">
                                                      <div class="col-sm-10 pr-1">
                                                        <mat-form-field class="example-full-width" appearance="outline">
                                                          <mat-label>Courier Service</mat-label> 
                                                          <mat-select  #select3 formControlName="courierRefId" >
                                                            <mat-select-filter *ngIf="select3.focused" [placeholder]="'Filter'" [displayMember]="'serviceProviderName'"
                                                            [array]="courierList"  (filteredReturn)="filteredListCourier =$event"></mat-select-filter>    
                                                            <mat-option *ngFor="let item of filteredListCourier" 
                                                            [value]="item._id" (click)="selectCourier(item.serviceProviderName);">
                                                           {{ item.serviceProviderName }}</mat-option>
                                                          </mat-select>
                                                        </mat-form-field>
                                                        <!-- <mat-error class="margin_tp"
                                                        *ngIf="repairActionForm.controls['courierRefId'].touched && repairActionForm.controls['courierRefId'].errors?.required">
                                                        This field is mandatory.
                                                      </mat-error> -->
                                                  
                                                    </div>
                                                    <div class="col-sm-2 mTop no-print">
                                                      <i (click)="openCourierServicePopUp();" matTooltip="Add Courier Service" class="blue_col mTop fa-solid fa-circle-plus
                                                       pl-1 fa-lg"></i>
                                                    </div>  
                                                    </div>
                                                    </div>
                                                    <div class="col-sm-12">
                                                      <mat-form-field class="example-full-width " appearance="outline">
                                                          <mat-label>Tracking Number</mat-label>
                                                          <input matInput placeholder="Add Tracking Number" 
                                                          formControlName="trackingNumber" >          
                                                      </mat-form-field>
                                                      <!-- <mat-error
                                                      *ngIf="repairActionForm.controls['trackingNumber'].touched && repairActionForm.controls['trackingNumber'].errors?.required">
                                                      This field is mandatory. 
                                                    </mat-error>  -->
                                                
                                                  </div>
                                                
                                                <div *ngIf="inward" class="col-sm-12">
                                                  <mat-form-field class="example-full-width" appearance="outline">
                                                    <mat-label>Doc Date</mat-label>
                                                    <input formControlName="docDate" matInput [matDatepicker]="docDate" id="docDate" 
                                                    placeholder="DD/MM/YYYY"   >
                             
                                                    <mat-datepicker-toggle matSuffix [for]="docDate">
                                                    </mat-datepicker-toggle>
                                                    <mat-datepicker #docDate></mat-datepicker>
                                                  
                                                  </mat-form-field>
                                            
                                                </div>
                                                   
                                                
                                                    <div class="col-sm-12">
                                                      <!-- <div class="row">
                                                        <div class="col-sm-10 pr-1">  -->
                                                      <mat-form-field class="example-full-width" appearance="outline">
                                                        <mat-label>Office 
                                                          <!-- <span class="required-asterisk">*</span> -->
                                                        </mat-label>
                                                        <mat-select #select5 (click)="fetchBranchList();" formControlName="branchRefId" >
                                                          <mat-select-filter *ngIf="select5.focused" [placeholder]="'Filter'" [displayMember]="'branchName'"
                                                          [array]="BranchList"  (filteredReturn)="filteredListBranch =$event"></mat-select-filter>
                                                          <mat-option *ngFor="let item of filteredListBranch" [value]="item._id" 
                                                          (click)="branchClick(item._id,item.branchName);"
                                                          >
                                                           {{ item.branchName }}</mat-option>
                                                          </mat-select>
                                                         
                                                      </mat-form-field>
                                                 
                                                        </div>
                                                        <!-- <div class="col-sm-2 mt-2 mTop no-print">
                                                          <i (click)="openBranchPopUp();" matTooltip="Add New Office" 
                                                          class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                                                             </div>
                                                       
                                                        </div>
                                                    </div> -->
                                          <!-- <div *ngIf="!isDocuments && !isDevices" class="col-sm-3"></div>
                                          <div *ngIf="!isDocuments && !isDevices" class="col-sm-1"></div> -->
                                          <div *ngIf="!ActionReceivedFrom && !ActionSentTo" class="col-sm-12">
                                            <mat-form-field class="example-full-width" appearance="outline">
                                              <mat-label>Notes</mat-label>
                                      
                                              <textarea matInput formControlName="notes" maxlength="1000"
                                              cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                              cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" ></textarea>
                                            </mat-form-field>
                                          </div>
                                         
                                          <div hidden *ngIf="isSuperAdmin" class="col-sm-12"> 
                    
                                            <div class="row">
                                              <div class="col-sm-12 mt-2">
                                                <h5 class="pb-0 mb-0">Select Branch :</h5>
                                          
                                              </div>
                                              </div>
                                               <div class="row">
                          
                                          <div class="col-sm-12" >
                                            <!-- <div class="row">
                                              <div class="col-sm-10 pr-1">  -->
                                            <mat-form-field class="example-full-width" appearance="outline">
                                              <mat-label>Branch 
                                              </mat-label>
                                              <input matInput readonly placeholder="Branch Name" formControlName="branchName" />

                                               
                                            </mat-form-field>
                                          
                                              </div>
                                              <!-- <div class="col-sm-2 mt-2 mTop no-print">
                                                <i (click)="openBranchPopUp();" matTooltip="Add New Branch" 
                                                class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                                                   </div>
                                             
                                              </div>
                                          </div> -->
                                     </div>
                                     </div>
                                     <div class="col-sm-12">
                                      <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label *ngIf="inward">Items Received</mat-label>
                                        <mat-label *ngIf="outward">Items Sent</mat-label>
                                        
                                        <textarea matInput formControlName="notes" maxlength="1000"
                                        cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                        cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" ></textarea>
                                      </mat-form-field>
                                    </div>
                            
                      </div>
                      <!-- Inward endddddd -->
                    </div>
                     
                    <!-- <div *ngIf="ActionSentTo" class="col-sm-12">
                      <button class="btn btn-primary btn-sm mt-2 example-full-width gColor" 
                      type="button" color="secondary" (click)="openInward('Outward')">Add Outward</button>
                    </div> -->
                    <div *ngIf="ActionEstimateCalculated" class="col-sm-6 mt-1">
                      <mat-label>Left Fitted By : </mat-label>
                      <mat-label class="black ml-2" ngDefaultControl formControlName="leftFittedBy">{{leftType}}</mat-label>
                    </div>  
                    <div *ngIf="ActionEstimateCalculated" class="col-sm-6 mt-1">
                      <!-- <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Fitted By</mat-label>
                        <input  matInput  readonly
                       formControlName="fittedBy" />
                      </mat-form-field>  -->
                      <mat-label>Right Fitted By : </mat-label>
                      <mat-label  class="black" ngDefaultControl formControlName="rightFittedBy">{{rightType}}</mat-label>
                      <!-- <mat-radio-group formControlName="fittedBy" class="example-full-width" aria-label="Select an option">
                        <mat-radio-button value="Fitted By Us" >Fitted By Us</mat-radio-button>
                        <mat-radio-button class="ml-3" value="Not Fitted By Us">Not Fitted By Us</mat-radio-button>
                      </mat-radio-group> -->
  
  
                      <!-- <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Fitted By</mat-label>
                        <mat-select placeholder="Fitted By"  
                        class="colorblack" 
                        formControlName="fittedBy" >
                  <mat-option value="Internal">Internal</mat-option>
                  <mat-option value="External">External</mat-option>
                       </mat-select>     
                      </mat-form-field> -->
                    </div> 
                    <div *ngIf="ActionEstimateCalculated" class="col-sm-6">
                     <mat-form-field class="example-full-width" appearance="outline">
                       <mat-label>Left Device Age(in Days)</mat-label>
                       <input  matInput placeholder="Age" maxlength="3"
                      formControlName="leftDeviceAgeInYears" />
                     </mat-form-field>
                   </div>
                    
                 
                   <div *ngIf="ActionEstimateCalculated" class="col-sm-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Right Device Age(in Days)</mat-label>
                      <input  matInput placeholder="Age" maxlength="3"
                     formControlName="rightDeviceAgeInYears" />
                    </mat-form-field>
                  </div> 
                  
                  <!-- <div *ngIf="ActionEstimateCalculated" class="col-sm-12 pt-0 mt-0">
                                
                    <div class="row">
                     <div class="col-sm-10">
                     <mat-form-field class="example-full-width  pt-0 mt-0" appearance="outline">
                       <mat-label>Fitted By </mat-label>   
                       <mat-select required #select1 formControlName="fittedBy" >
                         <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'model'"
                         [array]="allDevices" (filteredReturn)="filteredListDevice =$event"></mat-select-filter>
                         <mat-option *ngFor="let item of filteredListDevice" [value]="item.model" 
                         (click)="onLeftModelChange($event,item._id,item.newSerialNo,item.oldSerialNo)">
                          {{ item.model }}</mat-option>
                         </mat-select>
                     </mat-form-field> 
                   
                   </div>
                     <div class="col-sm-2 no-print">
                   <i (click)="openDeviceByPatientPopUp();" 
                    matTooltip="Add New Device" class="blue_col mTop fa-solid fa-circle-plus
                    pl-1 fa-lg"></i>
                    </div> 
                    </div>
                   </div> -->
                   <div *ngIf="ActionEstimateCalculated" class="col-sm-4">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Amount</mat-label>
                      <input  matInput readonly 
                     [value]="amountA"/>
                    </mat-form-field> 
                  </div>
                  <div *ngIf="ActionEstimateCalculated && restrictData" class="col-sm-4">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Courier Charges</mat-label>
                      <input maxlength="5"  matInput 
                     formControlName="courierCharges" (input)="estimateCalculation()" />
                    </mat-form-field> 
                    <mat-error 
                        *ngIf="repairActionForm.controls['courierCharges'].touched && repairActionForm.controls['courierCharges'].errors?.pattern">
                           Enter Valid Amount.
                      </mat-error>
                  </div>
                    <div *ngIf="ActionCommunicationWith || ActionEstimateAdvised || ActionEstimateApproved || ActionEstimateRejected" class="col-sm-12">
                      <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Communication Type</mat-label>
                          <mat-select placeholder="Communication"  
                          class="colorblack" 
                          formControlName="phoneMsg" >
                    <mat-option (click)="selectPhoneMsg('Phone')" value="Phone">Phone</mat-option>
                    <mat-option (click)="selectPhoneMsg('Message')" value="Message">Message</mat-option>
                         </mat-select>   
                        </mat-form-field>
                     </div>
                     <!-- <div class="col-sm-12" *ngIf="isPhone">
                      <div class="row">
                        <div class="col-sm-10">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label> Mobile 
                        </mat-label>
                        <input matInput maxlength="10" placeholder="Mobile Number"
                          formControlName="phone" />
                      </mat-form-field>
                      <mat-error class="margin_tp"
                      *ngIf="repairActionForm.controls['phone'].touched && repairActionForm.controls['phone'].errors?.pattern">
                          Please enter valid mobile number.
                    </mat-error>
                    </div>
                    <div class="col-sm-2 wApp">
                      <i class="bi bi-whatsapp whatsapp-icon wApp fa-lg" (click)="WhatsApp();" matTooltip="WhatsApp Integration" ></i>
                        </div>
              </div>
              </div> -->
              <!-- <div *ngIf="isMessage" class="col-sm-12">  
                <mat-form-field class="example-full-width "  appearance="outline">
                  <mat-label>Message</mat-label>
                  <textarea matInput formControlName="message"
                  cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" ></textarea>
                </mat-form-field> 
            </div> -->
            <div class="col-sm-12" *ngIf="ActionDulyRepaired || isBranch || ActionUnrepairedReady" >
              <!-- <div class="row">
                <div class="col-sm-10 pr-1">  -->
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Branch </mat-label>
                <mat-select #select5 (click)="fetchBranchList();" formControlName="branchRefId" >
                  <mat-select-filter *ngIf="select5.focused" [placeholder]="'Filter'" [displayMember]="'branchName'"
                  [array]="BranchList"  (filteredReturn)="filteredListBranch =$event"></mat-select-filter>
                  <mat-option *ngFor="let item of filteredListBranch" [value]="item._id" 
                  (click)="branchClick(item._id,item.branchName);">
                   {{ item.branchName }}</mat-option>
                  </mat-select>
                 
              </mat-form-field>
              <!-- <mat-error class="margin_tp"
                  *ngIf="repairActionForm.controls['branchRefId'].touched && repairActionForm.controls['branchRefId'].errors?.required">
                  This field is mandatory.
                </mat-error> -->
                </div>
                <!-- <div class="col-sm-2 mt-2 mTop no-print">
                  <i (click)="openBranchPopUp();" matTooltip="Add New Branch" 
                  class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                     </div> -->
               
                <!-- </div>
            </div> -->

            <div *ngIf="ActionPaymentDone || ActionEstimateAdvised || ActionEstimateApproved 
             || ActionEstimateRejected" class="col-sm-12">
              <mat-form-field  class="example-full-width mTop" appearance="outline">
                  <mat-label>Amount</mat-label>
         
                  <input *ngIf="step == 'Estimate Advised To'" matInput formControlName="amountC" placeholder="Amount Approved by patient" /> 
                  <input *ngIf="step !== 'Estimate Advised To'" matInput formControlName="amountC" placeholder="Amount C(Rs.)" /> 
                </mat-form-field>
             </div>
             <div *ngIf="ActionEstimateCalculated && restrictData" class="col-sm-4">
              <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Final Amount</mat-label>
                  <input matInput formControlName="amountB" [value]="estimateAmount" readonly
                   placeholder="Final Amount(Rs.)" /> 
                </mat-form-field>
             </div>
             <div *ngIf="ActionEstimateReceived" class="col-sm-12">
               <mat-form-field class="example-full-width" appearance="outline">
                   <mat-label>Amount</mat-label>
                   <input matInput formControlName="amountA" placeholder="Amount(Rs.)" /> 
                 </mat-form-field>
              </div>
                    <div *ngIf="ActionCheckedBy || ActionCollectedBy || ActionCommunicationWith
                    || ActionEstimateAdvised || ActionEstimateApproved || ActionEstimateReceived
                    || ActionEstimateRejected || ActionProgrammedBy" class="col-sm-12">  
                      <mat-form-field class="example-full-width "  appearance="outline">
                        <mat-label>Comments</mat-label>
                        <textarea matInput formControlName="textBoxEntry" maxlength="1000"
                        cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" ></textarea>
                      </mat-form-field> 
                  </div>

                  <div  *ngIf="ActionCollectedBy && !hideSignature" class="col-sm-12">
                             
                    <div class="row col-sm-12">
                        <h5>Add Signature :</h5> 
                      </div> 
                      </div>
                      <div *ngIf="hideSignature" class="col-sm-12">
                             
                        <div class="row col-sm-12">
                            <h5> Signature :</h5>
                          </div> 
                          </div>
                  <!-- <div *ngIf="!hideSignature" class="col-sm-12 pr-1">  
                    <canvas (click)="canvasClick()" #canvas (touchstart)="startDrawing($event)"
                    (touchmove)="moved($event)" [ngClass]="{'canvas2' : ActionCollectedBy}"
                    [ngClass]="{'canvas1' : !ActionCollectedBy}" class="example-full-width"></canvas>
                  </div> -->
                   <!-- <canvas (click)="canvasClick()" [ngClass]="{'canvas2' : ActionCollectedBy}"
                   [ngClass]="{'canvas1' : !ActionCollectedBy}" class="bb col-sm-12"
                    #canvas (touchstart)="startDrawing($event)" (touchmove)="moved($event)"></canvas> -->

<div class="col-sm-12" *ngIf="!hideSignature">
  <div class="row">
  
    <div class="col-sm-12">
  <canvas (click)="canvasClick()" class="example-full-width " #canvas (touchstart)="startDrawing($event)"
  [ngClass]="{'canvas2' : ActionCollectedBy}"
  [ngClass]="{'canvas1' : !ActionCollectedBy}" (touchmove)="moved($event)"></canvas>
    </div>
 
    </div>

</div> 

<!-- <div  *ngIf="ActionCollectedBy && !hideSignature" class="col-sm-12">
<div *ngIf="signatureImg !== undefined" class="row col-sm-12">
<div class="col-sm-12">
<button class="btn btn-primary btn-sm mt-2 example-full-width"  type="button" 
(click)="clearPad()">Clear Signature</button>
</div>
<div class="col-sm-12">
<button class="btn btn-primary btn-sm mt-2 example-full-width" 
type="button"  color="secondary" (click)="savePad()">Save Signature</button>
</div>
</div>  

</div> --> 

<div *ngIf="hideSignature" class="col-sm-12">
<img src='{{ signatureImg }}' />
</div>
                 

                    <div class="row col-sm-12 pl-3 pr-3 mb-2">
                        <div class="col-sm-4" ></div>
                        <div  class="col-sm-4 mt-2">
                       
                          <button *ngIf="signatureImg !== undefined && ActionCollectedBy && !hideSignature"  class="btn mb-2 btn-sm btn-primary example-full-width"  
                          type="button" 
                          (click)="clearPad()">Clear Signature</button>
                          </div>
                          <!-- <div class="col-sm-12">
                          <button class="btn btn-primary btn-sm mt-2 example-full-width" 
                          type="button"  color="secondary" (click)="savePad()">Save Signature</button>
                          </div> -->
                         
                        <div class="col-sm-4 mt-2 justify-content-end mr-0 pr-0" *ngIf="submitButton && AddRepairActionCreate">
                          <button *ngIf="restrictData" class="btn mb-2  mr-0 pr-0 btn-sm btn-primary example-full-width"
                          (click)="submitAction()" type="button" [disabled]="disableSubmit">Save</button>
                        </div>
                       
                       

                    
                        <!-- <div class="col-sm-3 mt-2" *ngIf="updateButton">
                          <button class="btn mb-2 btn-primary btn-sm  example-full-width" (click)="cancelUpdate()"
                          type="reset">Cancel </button>
                        </div> -->
    
                      </div>
                 
                    </div>
                    </div>
  
                  </div>
  
                  
                   
                     
                   
                  
                </div>
              </div>
            </div>
  
        </div>
      </div>
    </form>
  </div>