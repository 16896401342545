<div class="container-fluid bgcolor2" [formGroup]="clinicExpensesForm">
  <form autocomplete="off" [formGroup]="clinicExpensesForm" novalidate>
    <div class="row">
        <div class="col-sm-1">

        </div>
        <div class="col-sm-11">
          <div class="row pl-0 ">
            <div class="col-12 pl-0 ">
              <div class="row ">
              <div class="col-sm-9">
                  <h1 class="pt-2 pl-0" id="toClinicExpensesForm">Clinic Expenses</h1>
                  </div>
                  <div class="col-sm-3 mt-1">
                    <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                      <a (click)="onback()" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">arrow_back</span>Back</a>
                      <a (click)="toClinicExpensesList()" class="d-flex align-items-center ms-3 pr-2 mt-2"><span class="material-symbols-outlined pr-2">reorder</span>List</a>
                 </div> 
            </div>
          </div>
         </div>
         </div>  

            <div class="row">
                <div class="col-sm-12 pr-4">
                    <div class="row">
                        <div class="card divbody mb-3 borderradius p-2">
                           
                            <div class="col-sm-12">
                                <div class="row">
                                    <!-- <div class="col-sm-3 ">
                           
                                        <mat-form-field *ngIf="userType !== 'Super Admin'" class="example-full-width" appearance="outline">
                                          <mat-label>Expenses Id</mat-label>
                                          <input matInput formControlName="branchWiseId" readonly  />   
                                        </mat-form-field>
                                        <mat-form-field *ngIf="userType == 'Super Admin'" class="example-full-width" appearance="outline">
                                          <mat-label>Expenses Id</mat-label>
                                          <input matInput [value]="superBranchId" readonly  />
                                          </mat-form-field>
                                      </div> -->

                                  <div class="col-sm-3 ">
                                        <mat-form-field class="example-full-width " appearance="outline">
                                            <mat-label>Date</mat-label>
                                            <input matInput  [min]="currentdate"  [matDatepicker]="date" placeholder="Choose a date" 
                                            formControlName="date" readonly>
                             
                                             <mat-datepicker-toggle matSuffix [for]="date">
                                             </mat-datepicker-toggle>
                                             <mat-datepicker #date></mat-datepicker>
                                           
                                        </mat-form-field>
                                        <mat-error
                                        *ngIf="clinicExpensesForm.controls['date'].touched && clinicExpensesForm.controls['date'].errors?.required">
                                        This field is mandatory.
                                      </mat-error>
                                    </div>

                                    <div class="col-sm-3" *ngIf="isSuperAdmin && branchCreate == true">
                                      <div class="row">
                                          <div class="col-sm-10 pr-1">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>Select Branch 
                                            <!-- <span class="required-asterisk">*</span> -->
                                          </mat-label>
                                          <mat-select required #select2 formControlName="branchRefId" >
                                            <mat-select-filter *ngIf="select2.focused" [placeholder]="'Filter'" [displayMember]="'branchName'"
                                            [array]="branchList"  (filteredReturn)="filteredListBranch =$event"></mat-select-filter>
                                            <mat-option *ngFor="let item of filteredListBranch" [value]="item._id" 
                                            (click)="branchClick(item._id,item.branchName);"
                                            >
                                             {{ item.branchName }}</mat-option>
                                            </mat-select>
                                           
                                        </mat-form-field>
                                        <mat-error class="margin_tp"
                                        *ngIf="clinicExpensesForm.controls['branchRefId'].touched && clinicExpensesForm.controls['branchRefId'].errors?.required">
                                        This field is mandatory.
                                      </mat-error>
                                       </div>
                                       <div class="col-sm-2 mTop no-print">
                                        <i (click)="openBranchPopUp();" matTooltip="Add New Branch" class="blue_col mTop fa-solid fa-circle-plus
                                         pl-1 fa-lg"></i>
                                     </div>
                                       </div>
                                   </div>
                                   <div class="col-sm-3" *ngIf="isSuperAdmin && branchCreate == false">
                                   
                                      <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>Select Branch 
                                          <!-- <span class="required-asterisk">*</span> -->
                                        </mat-label>
                                        <mat-select #select2 formControlName="branchRefId" >
                                          <mat-select-filter *ngIf="select2.focused" [placeholder]="'Filter'" [displayMember]="'branchName'"
                                          [array]="branchList"  (filteredReturn)="filteredListBranch =$event"></mat-select-filter>
                                          <mat-option *ngFor="let item of filteredListBranch" [value]="item._id" 
                                          (click)="branchClick(item._id,item.branchName);"
                                          >
                                           {{ item.branchName }}</mat-option>
                                          </mat-select>
                                         
                                      </mat-form-field>
                                      <mat-error class="margin_tp"
                                      *ngIf="clinicExpensesForm.controls['branchRefId'].touched && clinicExpensesForm.controls['branchRefId'].errors?.required">
                                      This field is mandatory.
                                    </mat-error>
                                     
                                 </div>
                                 <div class="col-sm-3 ">

                                  <mat-form-field class="example-full-width lable_style2" appearance="outline">
                                    <mat-label>Expense Vendor Name</mat-label>
                                    <mat-select required #select3
                                     formControlName="vendorRefId" >
                                      <mat-select-filter  *ngIf="select3.focused" [placeholder]="'Filter'"
                                        [displayMember]="'vendorName'" [array]="vendorList"
                                        (filteredReturn)="filteredListVendor =$event"></mat-select-filter>
                                      <mat-option *ngFor="let vendor of filteredListVendor" [(value)]="vendor._id"
                                        (onSelectionChange)="onSelectVendor(vendor.vendorName)">{{
                                        vendor.vendorName }}</mat-option>
                                    </mat-select>
            
                                  </mat-form-field>
                                  <mat-error
                                  *ngIf="clinicExpensesForm.controls['vendorRefId'].touched && clinicExpensesForm.controls['vendorRefId'].errors?.required">
                                  This field is mandatory.</mat-error>
                                  <!-- <mat-error class="margin_tp"
                                    *ngIf="clinicExpensesForm.controls['vendorRefId'].touched && clinicExpensesForm.controls['vendorRefId'].errors?.required">
                                    This field is mandatory.
                                  </mat-error> -->
                                </div>
                                 <div class="col-sm-3 ">
                                  <div class="row">
                                    <div class="col-sm-10 pr-1">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Expenses Type
                                </mat-label>
                                  <mat-select required #select1 class="colorblack"  formControlName="expensesType"   >
                                    <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'expenceType'"
                                      [array]="allExpensesType"  (filteredReturn)="filteredList =$event"></mat-select-filter>
                                    <mat-option *ngFor="let item of filteredList" 
                                    (click)="onSelectExpence(item._id)" [value]="item.expenceType"
                                  
                                     >
                                      {{item.expenceType}}
                                    </mat-option> 
                                  </mat-select>
                                
                              </mat-form-field>
                              <mat-error class="margin_tp"
                                *ngIf="clinicExpensesForm.controls['expensesType'].touched && clinicExpensesForm.controls['expensesType'].errors?.required">
                                This field is mandatory.
                              </mat-error>
                            </div>
                            <div class="col-sm-2 mTop no-print">
                              <i (click)="openExpensePopUp();" matTooltip="Add New Expense Type" class="blue_col mTop fa-solid fa-circle-plus
                               pl-1 fa-lg"></i>
                          </div>
                          </div>
                          </div>

                          <div class="col-sm-3 ">
                           
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Expenses Description</mat-label>
                              <input required matInput formControlName="expenseName"   />   
                            </mat-form-field>
                            <mat-error
                            *ngIf="clinicExpensesForm.controls['expenseName'].touched && clinicExpensesForm.controls['expenseName'].errors?.required">
                            This field is mandatory.</mat-error>
                            <mat-error class="margin_tp"
                                      *ngIf="clinicExpensesForm.controls['expenseName'].touched && clinicExpensesForm.controls['expenseName'].errors?.pattern">
                                      Please enter valid expense name.
                           </mat-error>
                          </div>
                         

                                    <div class="col-sm-3 ">
                           
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>Amount</mat-label>
                                          <input type="number" required matInput formControlName="amount" maxlength="7"/>   
                                        </mat-form-field>
                                        <mat-error
                                        *ngIf="clinicExpensesForm.controls['amount'].touched && clinicExpensesForm.controls['amount'].errors?.required">
                                        This field is mandatory.</mat-error>
                                        <mat-error class="margin_tp"
                                          *ngIf="clinicExpensesForm.controls['amount'].touched && clinicExpensesForm.controls['amount'].errors?.pattern">
                                          Please enter valid amount.
                                        </mat-error>
                                        <mat-error class="margin_tp"
                        *ngIf="clinicExpensesForm.controls['amount'].touched && clinicExpensesForm.controls['amount'].errors?.minlength">
                            Enter minimum 2 digit amount.
                      </mat-error>
                      <mat-error class="margin_tp"
                      *ngIf="clinicExpensesForm.controls['amount'].touched && clinicExpensesForm.controls['amount'].errors?.maxlength">
                            Enter maximum 7 digit amount.
                      </mat-error>
                                      </div>
                                    
                                    
                              
                              <div  *ngIf="hide == true" class="col-sm-1 mt-3">
                                <!-- <label *ngIf="hide == false" class="header">Upload File</label> -->
                                <label class="header">Uploaded File :</label>
                                </div>
                                <div class="col-sm-2 mt-3" *ngIf="hide == false">
                                  <!-- <input  type="file" class="example-full-width " #fileUpload
                                   name="Upload File" id="txtFileUpload" placeholder="Upload File"
                                  (change)="onChange($event);" />  -->
 
 
                                  <input #fileUpload type="file" style="display: none;" (change)="onChange($event)" />
                                  <button type="button" class="btn btn-light shadow btn-sm m-0 mb-1 example-full-width"
                                   matTooltip="Upload JPG,PNG,CSV or PDF image.300 x 300 required." 
                                   (click)="fileUpload.click()" ><i class="ai-refresh-cw me-2" ></i>Upload File/Photo</button>

                              </div>
                              <div class="col-sm-2 mt-3" *ngIf="hide == true">
                                <div class="row ">
                                  <a class="col-sm-10" (click)="showbase64(clientImage)">
                                    <label class="blue_col mt-0 pt-0">{{fileName}}</label></a>
                                    <i class="fa fa-times col-sm-2 pointer" (click)="remove()" 
                                    aria-hidden="true" style="cursor:pointer;" ></i>
  
                                  <!-- <i class="col-sm-4 fa fa-trash-o"
                                  style="cursor:pointer;" (click)="remove()"></i> -->
                                </div>
                              </div>

                              <!-- <div  class="col-sm-2 mt-3"> 
                               <input type="file" class="example-full-width " 
                                #fileUpload name="Upload File" id="txtFileUpload" (change)="onChange($event);" /> 
                                <a (click)="showbase64(clientImage)">View Document</a>
                            </div> -->
                            <!-- <div class="col-sm-3 pt-2">
                                <img *ngIf="!patientImage" class=" img2 frame rounded-circle " src="../../assets/no_data_found.jpeg" alt="Amanda Wilson" width="500rem"> 
                                <img *ngIf="clientImage"  id="myImg" class=" img2 frame" [src]="clientImage" (click)="imgClick()"  alt="expenses photo" width="150rem">
                                

                            </div> -->
                        
 

                                    <div class="col-sm-2 offset-sm-10 mb-2 mt-2" *ngIf="submitButton && clinicExpensesCreate"> 
                                        <button class="btn btn-primary btn-sm example-full-width"  type="button"
                                         (click)="submitClinicExpenses()">Save </button>

                                    </div>
                                    <div class="col-sm-2 offset-sm-8 mb-2 mt-2" *ngIf="updateButton && clinicExpensesUpdate">
                                        <button class="btn btn-primary btn-sm example-full-width"  type="button"
                                         (click)="updateClinicExpenses()">Update </button>
                                    </div>
                                    <div class="col-sm-2 mb-2 mt-2 " *ngIf="updateButton">
                                        <button class="btn btn-primary btn-sm example-full-width"  (click)="cancelUpdate()" type="reset" >Cancel </button>
                                       
                                    </div>
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 pl-0 pr-2">
                <div class="row">
                    <div class="card divbody borderradius p-3 pb-0">
                            <div class="col-sm-12">
                                <div class="row">
                                    <div class="col-sm-9 ">
                                        <h1 class="titlecolor">Clinic Expenses List</h1>
                                    </div>
                                    <div class="search-box col-sm-3 pb-2">
                                      <input class="form-control amber-border" type="text" placeholder="Search" 
                                      formControlName="searchExpenses" aria-label="Search" (keyup)="onKeyUpEvent($event)"
                                      >
                                      <div class="input-group-append">
                                         <span class="input-group-text amber lighten-3" id="basic-text1"><span class="material-symbols-outlined">search</span></span>
                                      </div>
                                   </div>
                                 
                                    <div class="col-sm-12 table-responsive">
                                      <table id="toClinicExpensesList" 
                                   class="table table-bordered data-table-custom">
                                            <thead>
                                                <tr class="text-center">
                                                    <th> ID</th>
                                                    <th> Date</th>
                                                    <th *ngIf="userType == 'Super Admin'">Branch Name</th>
                                                     <th>Amount</th>
                                                    <th>Expenses Type</th>
                                                   
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of listOfExpenses | paginate : { id:'expensesMaster',
                                                itemsPerPage: size, currentPage: page, totalItems:totalLength }" 
                                                (click)="prepopulation(item._id)"
                                                 (click)="toClinicExpensesForm()" 
                                                 style="cursor:pointer; text-align: center ">
                                                 <td matTooltip="click to view">{{item.branchWiseId}}</td>
                                                 <td matTooltip="click to view">{{item.date| date:'dd/MM/yyyy'}}</td>
                                                 <td *ngIf="userType == 'Super Admin'" matTooltip="click to view" >{{item.branchName}}</td>
                                                 <td matTooltip="click to view">{{item.amount}}
                                               </td>
                                                    <td matTooltip="click to view">{{item.expensesType}}</td>
                                                   </tr>
                                            

                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="col-sm-12 pt-0 text-right">
                                            <pagination-controls id="expensesMaster" directionLinks="true"
                                  (pageChange)="onPageChange($event)" class="float-right pagination my-pagination"></pagination-controls>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div id="myModal" class="modal">
                    <span (click)="closeClick()" class="close">&times;</span>
                    <img class="modal-content" [src]="modalImg" id="img01">
                    <div id="caption"></div>
                  </div>

        </div>
    </div>
    </form>
</div>