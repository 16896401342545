<div class="container-fluid " [formGroup]="deviceNotesMasterForm">
    <form [formGroup]="deviceNotesMasterForm" autocomplete="off" novalidate>
        <div class="row">
            <div class="col-sm-12">
                                  <div class="row"> 
            <div class="col-sm-12 ">
              <div class="row ">
            
                  <div class="col-sm-11">
                    <h1 class="titlecolor" >Notes</h1>  
                </div>
                <div class="col-sm-1 justify-content-end mt-1">
                  <div class="page-title-right row justify-content-end d-flex align-items-center float-end">
                    
                    <a (click)="onClose()" class="d-flex align-items-center ms-3 pr-2"><span class="material-symbols-outlined pr-2">close</span></a>
             </div>
          </div> 

                 
          </div>
         </div>
       
                                <!-- <div class="col-sm-3">
                                    <label class="header mt-4">Notes Added By {{patientName}},</label><br>
                                    <label class="header mt-2"> On {{date | date:'MMMM d, y'}}</label>
                                    </div> -->
                                <div class="col-sm-7 mt-3">
                                        <mat-form-field class="example-full-width mb-4" appearance="outline">
                                            <mat-label>Notes</mat-label>
                                            <textarea placeholder="Start writing" maxlength="1000"
                                             matInput required formControlName="notes"
                                            cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                            cdkAutosizeMinRows="7" cdkAutosizeMaxRows="10" ></textarea>
                                           </mat-form-field>
                            <mat-error
                            *ngIf="deviceNotesMasterForm.controls['notes'].touched && deviceNotesMasterForm.controls['notes'].errors?.required">
                            This field is mandatory.
                            </mat-error>
                            <div class="row">
                            <div class="col-sm-3 mb-2 offset-sm-6 mt-4" *ngIf="updateButton && notesUpdate">
                               
                                <button  class="btn btn-primary  btn-sm  example-full-width"  (click)="updateNotesData()" type="button">Update </button>
                               
                              </div>
                              <div class="col-sm-3 mb-2 mt-4" *ngIf="cancelButton">        
                                <button class="btn btn-primary btn-sm  example-full-width"  (click)="cancelUpdate()">Cancel </button>
                                </div>
                            <div class="col-sm-3 offset-sm-9 mb-2 mt-1" *ngIf="submitButton && notesCreate" >
                              <button class="btn btn-primary btn-sm example-full-width"  (click)="submitNotes()" type="button">Save </button> 
                            </div> 
                        </div>
                                </div>
                                <div class="col-sm-5 "> 

                                    <div class="tab-content  pb-3" id="pills-tabContent">
                                        <div class="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="pills-home-tab">
                                           <h2>Notes History </h2>
                                           <div class="col-sm-12 scroll height pl-0 ml-0">
                                           <ul class="timeline history-timeline ml-1">
                                              <li *ngFor="let item of noteData"
                                              (click)="prepopulation(item._id)" class="mb-5 pl-0">
                                                 <h5 class="highlighted-color">
                                              {{item.createdAt | date:'MMM d, y, - h:mm a'}}
                                                 </h5>
                                                 <div class="d-flex mb-2">
                                                    <span class="title-text">Details :</span>
                                                    <span> {{item.notes}}</span>
                                                 </div>
                                              </li>
                                              <li *ngIf="noteDataLenght == true">
                                                <h2>Notes History Not Available</h2>
                                              </li>
                                           </ul>
                                           </div>
                                        </div>
                                        </div>
                                </div>
                               
                            </div>
                        </div>
                   
        </div>
        </form>
    </div>

    