import {
  Component,
  OnInit,
  VERSION,
  ElementRef,
  ViewChild,  
  ViewEncapsulation,
} from "@angular/core";
// import { Calendar, CalendarOptions } from '@fullcalendar/angular';
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import { MatDialog } from "@angular/material/dialog";
import { DatePipe, getLocaleMonthNames } from "@angular/common";
import { FormBuilder } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { ApiServiceService } from "../api-service.service";
import { PopupTotalBillComponent } from "../popup-total-bill/popup-total-bill.component";
import { NgxSpinnerService } from "ngx-spinner";
import { PopUpPatientComponent } from "../pop-up-patient/pop-up-patient.component";
import { PopupCancelConfirmComponent } from "../popup-cancel-confirm/popup-cancel-confirm.component";
import { AppoinmentPopupComponent } from "../appoinment-popup/appoinment-popup.component";
import { async } from "@rxweb/reactive-form-validators";
import { BillingPopupComponent } from "../billing-popup/billing-popup.component";
@Component({
  selector: 'app-opd-dashboard',
  templateUrl: './opd-dashboard.component.html', 
  styleUrls: ['./opd-dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OPDDashboardComponent implements OnInit {
  repair = false;
  patient = true;
  @ViewChild("calendar", { static: true }) calendar: ElementRef<any>;
  name = "Angular " + VERSION.major;

appointmentlist;
  userType: string;
  userPermission: any;
  CheckupDetailCreate= true;
  CheckupDetailUpdate= true;
  patientInfo: string;
  branchRefId: string;
  clinicRef: string;
  bookRefId: any;
  bookDate: any;
  pName: string;
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
  ) {}
  appoinmentlist;
// appoinmentlist=[{
//   id: "743901840",
//   name:"Alok Kumar",
//   doctorName: "Nilesh",
//   branchName: "FC Road",
//   dateTime: "Feb 08 2022, 08:30 AM - 09:30 AM",
//   titleOfAppoinment:"Assessment",
//   status:"Confirmed"
//   // billingPice:"500.00",
// },{
//   id: "983901840",
//   name:"Rahul Patel",
//   doctorName: "Nilesh",
//   branchName: "SOLSA Road",
//   dateTime: "Feb 08 2022, 09:40 AM - 10:30 AM",
//   titleOfAppoinment:"Assessment",
//   status:" Pending"
// },
// {
//   id: "743901840",
//   name:"Ajay",
//   doctorName: "Nilesh",
//   branchName: "Nagpur Road",
//   dateTime: "Feb 08 2022, 11:30 AM - 12:00 PM",
//   titleOfAppoinment:"Assessment",
//   status:" Flexible Time"
// },
// {
//   id: "743901840",
//   name:"Sultan",
//   doctorName: "Nilesh",
//   branchName: "MG Road",
//   dateTime: "Feb 08 2022, 09:30 AM - 10:15 AM",
//   titleOfAppoinment:"Assessment",
//   status:"Rejected"
// }] 
  ngOnInit(): void {
    this.bookDate = null;
    this.bookRefId = null;
    localStorage.setItem("AptRefId",'');
    this.userType = sessionStorage.getItem('userType');
    this.branchRefId = sessionStorage.getItem("branchRef");
    this.clinicRef = sessionStorage.getItem("clinicRef");
    var calendarEl = this.calendar.nativeElement;
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    //
    if(this.userType !== 'Super Admin'){
    this.userPermission.forEach((element) => {
      if(element.pageName == 'Checkup Details' && element.create == false)
      {
          this.CheckupDetailCreate = false;  
      }
      if(element.pageName == 'Checkup Details' && element.update == false)
      {
          this.CheckupDetailUpdate = false;  
      }    
    });
  }
  let bId = "";
  //
  if (this.userType !== "Super Admin") {
    bId = this.branchRefId;
  } else {
    bId = "";
  }
   
    var calendar = new Calendar(calendarEl, {
      
      dateClick: (arg) => {
        //
        let dddate = new Date(arg.date).setHours(new Date(arg.date).getHours() + 6);
        let datess = new Date(dddate).toISOString();
        this.appointmentlist =[];
        this.appoinmentlist=[];
        let branchRef= sessionStorage.getItem('branchRef');
        //
        this.apimicroservice.getAppointmentbydatebranch(datess,branchRef).subscribe((res) => {
          //
          if (res.status) {
            let ssss = [];
            let wwww = [];
            if(res.data.length > 0){
              res.data.forEach((element) => {
                sessionStorage.setItem('aptDate',element.startDateTime);
                let x = {};
                x["title"] = element.clientName;
                x["start"] = new Date(element.startDateTime);
                x["end"] = new Date(element.endDateTime);
                x["color"] = element.eventColor;
                ssss.push(x);
                let z = {};
                z["id"]= element._id,
                z["name"]= element.clientName,
                z["doctorName"]=element.doctorName,
                z["doctorRefId"]=element.doctorRefId,
                z["branchName"]= element.branchName,
                z["branchRefId"]= element.branchRefId,
                z["patientRefId"]=element.patientRefId,
                z["patientName"]=element.patientName, 
                z["patientId"]=element.patientId,
                z["clientSource"]=element.clientSource,
                z["dateTime"]= new Date(element.startDateTime).toLocaleString(),
                z["titleOfAppoinment"]=element.AppoinmentType,
                z["appointId"]=element.appointId,
                z["appointStatus"]=element.aptStatus,
                z["status"]= "N/A",
                z["Mob"] = element.contactNumber
                wwww.push(z)
              });
              this.appointmentlist = ssss;
             this.appoinmentlist = wwww;

             //
             
            }else{
              this.appointmentlist = [{"title": "No Appointments Available","color":"white"}];
            }
          } else {
            this.apimicroservice.openSnackbar(res.message);
          }
        });
      },
      initialView: "dayGridMonth",
      initialDate: new Date(),
      selectable: true,
      plugins: [dayGridPlugin, timeGridPlugin],
      headerToolbar: {
        left: "prev,next",
        center: "title",
        right: "dayGridMonth",
      },
    });
    let currentdate = new Date().toISOString();
    let branchRef= sessionStorage.getItem('branchRef');
    this.apimicroservice.getAppointmentbydatebranch(currentdate,branchRef).subscribe((res) => {
      this.appointmentlist = [];
      this.appoinmentlist = [];
      if (res.status) {
        let ssss = [];
        let wwww = [];
        if(res.data.length > 0){
          res.data.forEach((element) => {
            sessionStorage.setItem('aptDate',element.startDateTime);
            let x = {};
            x["title"] = element.clientName;
            x["start"] = new Date(element.startDateTime);
            x["end"] = new Date(element.endDateTime);
            x["color"] = element.eventColor;
            ssss.push(x);
            let z = {};
            z["id"]= element._id,
            z["name"]= element.clientName,
            z["doctorName"]=element.doctorName,
            z["doctorRefId"]=element.doctorRefId,
            z["branchName"]= element.branchName,
            z["branchRefId"]= element.branchRefId,
            z["patientRefId"]=element.patientRefId,
            z["patientName"]=element.patientName,
            z["patientId"]=element.patientId,
            z["clientSource"]=element.clientSource,
            z["dateTime"]= new Date(element.startDateTime).toLocaleString(),
            z["titleOfAppoinment"]=element.AppoinmentType,
            z["appointId"]=element.appointId,
            z["appointStatus"]=element.aptStatus,
            z["status"]= "N/A",
            z["Mob"] = element.contactNumber
            wwww.push(z)
          });
          this.appointmentlist = ssss;
         this.appoinmentlist = wwww;
        }else{
          this.appointmentlist = [{"title": "No Appointments Available","color":"white"}];
        }
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    });
    calendar.render();
  }

  // id: "743901840",
  // name:"Alok Kumar",
  // doctorName: "Nilesh",
  // branchName: "FC Road",
  // dateTime: "Feb 08 2022, 08:30 AM - 09:30 AM",
  // titleOfAppoinment:"Assessment",
  // status:"Confirmed"

  chnagetable(e){
    if(e == "patient"){
      this.patient = true;
      this.repair = false
    }else if(e == "repair"){
      this.patient = false;
      this.repair = true
    }else{
    
    }
    }
    loadpatient(e,aptId){  
      
      localStorage.setItem("patient",JSON.stringify(e));
      localStorage.setItem("AptRefId",aptId);
      if(this.userType == 'Super Admin')
           {
          this.apimicroservice.getBranchById(e.branchRefId).subscribe(res => {   
            let data = res.data[0];
            //
            sessionStorage.setItem('superClinicRefId',res.data[0].clinicRefId);
            // let pid = res.data[0].clinicRefId;
            // this.router.navigate(['home','opt',pid]);
           });
          } 
          if(this.CheckupDetailCreate == true)
          {
          
            this.redirect('/home/opt');
          }
          else
          {
            this.apimicroservice.openSnackbar("Sorry,You Dont Have Permission To Check Details");
          }
        
    } 
    editAppointment(id)
    {
      //
         
     sessionStorage.setItem('appointment_id',id);
     let e = null;
     this.openBookAppointmentPopup(id);
    // this.redirect('calander');
    }
    editPatient(id)
    {
        const dialogRef3 = this.dialog.open(PopUpPatientComponent,
         
          {
            data: {
              id: id,
            },
            width: "1200px",
          });
      //   
    }
    billing(patientId,appointId,branchRefId,e)
    {
   //  sessionStorage.setItem('appointment_id',id);
     //this.redirect('popup-total-bill'); 
     this.patientInfo = JSON.stringify(e);
      const dialogRef = this.dialog.open(BillingPopupComponent,
        {   
          width: "600px",
          data: { 
            patientRefId: patientId,
            appointId:appointId,
            branchRefId: branchRefId, 
            patientDetails:this.patientInfo,
            way:2,
            page:'Billing Page'
          } 
        }
        // {
        //   width: "800px",
        //   height: "530px"
        // }
        );
    }
    repairClick(branchRefId,patientRefId,branchName,patientName)
    {
     sessionStorage.setItem('repair_branchRefId',branchRefId);
     sessionStorage.setItem('repair_patientRefId',patientRefId);
     sessionStorage.setItem('repair_branchName',branchName);
     sessionStorage.setItem('repair_patientName',patientName);
     this.redirect('repair-complaint');   
    }
    hat(id,name)
    { 
     sessionStorage.setItem('hat_patientRefId',id);
     sessionStorage.setItem('hat_patientName',name);
     this.redirect('/home/ha-form'); 
    }
    earMold(branchRefId,patientRefId,branchName,patientName)
    { 
      sessionStorage.setItem('ear_branchRefId',branchRefId);
      sessionStorage.setItem('ear_patientRefId',patientRefId);
      sessionStorage.setItem('ear_branchName',branchName);
      sessionStorage.setItem('ear_patientName',patientName);
     this.redirect('/home/ear-mould');
    } 
    redirect(to): void {
      this.router.navigate([to]);
     }
     onback() {
      this.apimicroservice.back()
    }  
     functiondelete(id,pname,appdate){ 
      //this.spinner.show(); 
      //
      const dialogRef3 = this.dialog.open(PopupCancelConfirmComponent,
        { 
          width: "50%",
          //height: "570px"
          data: {
            appointRefId: id,
            appointDate:appdate, 
            patientName:pname
          }
        });
        dialogRef3.afterClosed().subscribe((result) => {
          this.ngOnInit(); 
         });
      // this.apimicroservice.deleteAppointmentData(id).subscribe(res => {
      //   //this.spinner.hide();
      //   if (res.status) {
      //   //  this.apimicroservice.openSnackbar(res.message);
      //     this.ngOnInit();
  
      //   } else {
      //    // this.apimicroservice.openSnackbar(res.message);
      //   }
      // });  
    } 
    openBookAppointmentPopup(id) {
      this.bookRefId = id;
    //
    
      const dialogRef3 = this.dialog.open(AppoinmentPopupComponent, {
        width: "70%",
        //height: "550px",
       data: {
         patientInfo:  this.bookRefId,
         bookDate: this.bookDate,
       },
     }); 
 
     dialogRef3.afterClosed().subscribe((result) => {
       this.ngOnInit();
       
     });
     
     
    }
    breakFuc()
    {
      //
    }
     getPatientData(id)
    {
   
      var BreakException = {}; 
      this.apimicroservice.getPatientById(id).subscribe(res => {
        //return "malar"
        this.spinner.hide();
        let data = res.data[0]; 
        var BreakException = {}; 
        //
         if(res.status){
      let prefix;
      let fname;
      let lname;
      let mname;
      if(data.prefix == null)
      {
        prefix = '';
      }
      else
      {
        prefix = data.prefix + '. ' ;
      }
      if(data.firstName == null)
      {
        fname = '';
      }
      else
      {
        fname = data.firstName;
      }
      if(data.middleName == null)
      {
        mname = '';
      }
      else
      {
        mname = data.middleName;
      }
      if(data.lastName == null)
      {
        lname = '';
      }
      else
      {
        lname = data.lastName;
      }
      this.pName = prefix + fname + ' ' + mname + ' ' + lname;
      //
      return 123;
     throw BreakException;
         }else{ 
       //    //this.apimicroservice.openSnackbar(res.message);
         }
       }); 
    }
}
