<div class="container-fluid bgcolor2" [formGroup]="placeOrderForm">
  <form [formGroup]="placeOrderForm" autocomplete="off" novalidate>
    <div class="row col-sm-12 p-0 m-0 body">
      <div class="col-sm-1">

      </div>
      <div class="col-sm-11 divName">
        <div class="row pl-0 ">

          <div class="col-12 pl-0 ">
            <div class="row ">
              <div class="col-sm-9">

                <h1 class="pt-2 pl-0" id="toPlaceOrderForm">Place Order</h1>
              </div>
              <div class="col-sm-3 mt-1">
                <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                  <a (click)="onback()" class="d-flex align-items-center pr-2 mt-2 no-print"><span
                      class="material-symbols-outlined pr-2">arrow_back</span>Back</a>
                  <a (click)="toPlaceOrderList()" class="d-flex align-items-center ms-3 pr-2 mt-2 no-print"><span
                      class="material-symbols-outlined pr-2">reorder</span>List</a>
                      <a *ngIf="isPrepopulate" (click)="printForm()" class="d-flex justify-content-end align-items-end mt-2">
                        <span class="material-symbols-outlined pr-1">print</span>Print</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 pr-4">
            <div class="row">
              <div class="card divbody borderradius mb-3 pl-4 pr-4 p-1">

                <div class="row">
                  <div class="col-sm-12">
                    <div class="row mt-2">
                      <div class=" col-sm-1 mt-3 ">

                        <mat-label class="center" ngDefaultControl formControlName="branchWiseId"
                          *ngIf="userType !== 'Super Admin'">{{bID}}</mat-label>
                        <mat-label class="center" ngDefaultControl [value]="superBranchId"
                          *ngIf="userType == 'Super Admin'">{{superBranchId}}</mat-label>
                      </div> 
                      <div class=" col-sm-2">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Date</mat-label>
                          <input matInput placeholder="Date" formControlName="date" readonly />
                        </mat-form-field>
                      </div>

                      <!-- <div class="col-sm-4"></div> -->
                      <div *ngIf="isSuperAdmin && branchCreate" class="col-sm-3">
                        <div class="row">
                          <div class="col-sm-10 pr-1">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Branch
                                <!-- <span class="required-asterisk">*</span> -->
                              </mat-label>
                              <mat-select #select3 formControlName="branchRefId">
                                <mat-select-filter *ngIf="select3.focused" [placeholder]="'Filter'"
                                  [displayMember]="'branchName'" [array]="BranchList"
                                  (filteredReturn)="filteredListBranch =$event"></mat-select-filter>
                                <mat-option *ngFor="let item of filteredListBranch" [value]="item._id"
                                  (click)="branchClick(item._id,item.branchName);">
                                  {{ item.branchName }}</mat-option>
                              </mat-select>

                            </mat-form-field>
                            <mat-error class="margin_tp"
                              *ngIf="placeOrderForm.controls['branchRefId'].touched && placeOrderForm.controls['branchRefId'].errors?.required">
                              This field is mandatory.
                            </mat-error>
                          </div>
                          <div class="col-sm-2 mt-2 no-print">
                            <i (click)="openBranchPopUp();" matTooltip="Add New Branch"
                              class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"> </i>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="isSuperAdmin && !branchCreate" class="col-sm-3">
                        <div class="row">
                          <div class="col-sm-12 pr-1">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Branch
                                <!-- <span class="required-asterisk">*</span> -->
                              </mat-label>
                              <mat-select #select4 formControlName="branchRefId">
                                <mat-select-filter *ngIf="select4.focused" [placeholder]="'Filter'"
                                  [displayMember]="'branchName'" [array]="BranchList"
                                  (filteredReturn)="filteredListBranch =$event"></mat-select-filter>
                                <mat-option *ngFor="let item of filteredListBranch" [value]="item._id"
                                  (click)="branchClick(item._id,item.branchName);">
                                  {{ item.branchName }}</mat-option>
                              </mat-select>

                            </mat-form-field>
                            <mat-error class="margin_tp"
                              *ngIf="placeOrderForm.controls['branchRefId'].touched && placeOrderForm.controls['branchRefId'].errors?.required">
                              This field is mandatory.
                            </mat-error>
                          </div>

                        </div>
                      </div>
                      <div class="col-sm-3">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Status</mat-label>
                          <mat-select #select5 formControlName="placeOrderStatus">
                            <mat-option value="Pending">Pending</mat-option>
                            <mat-option value="Received">Received</mat-option>
                            <mat-option value="Received Incomplete">Received Incomplete</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row mt-2 ">



                      <div class="col-sm-12 ">
                        <h5 class="header">Clinic Details :</h5>
                      </div>

                      <div class="col-sm-3 ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Clinic Name
                            <!-- <span class="required-asterisk">*</span>  -->
                          </mat-label>
                          <input matInput placeholder="Name" readonly formControlName="clinicName" />

                        </mat-form-field>

                      </div>
                      <div class="col-sm-3 ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Clinic Address
                            <!-- <span class="required-asterisk">*</span>  -->
                          </mat-label>
                          <input matInput placeholder="Address" readonly formControlName="clinicAddress" />

                        </mat-form-field>

                      </div>
                      <div class="col-sm-3 ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Clinic Email
                            <!-- <span class="required-asterisk">*</span>  -->
                          </mat-label>
                          <input matInput placeholder="Email" class="emailClass" readonly
                            formControlName="clinicMail" />

                        </mat-form-field>

                      </div>
                      <div class="col-sm-3 ">
                        <div class="row">
                          <div class="col-sm-10">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Phone Number</mat-label>
                              <input matInput placeholder="Phone Number" readonly formControlName="clinicPhone" />

                            </mat-form-field>
                          </div>
                          <div class="col-sm-2 wApp no-print">
                            <i class="bi bi-whatsapp whatsapp-icon wApp fa-lg" (click)="WhatsApp();"
                              matTooltip="WhatsApp Integration"></i>
                          </div>
                        </div>
                      </div>



                    </div>
                    <div class=" row mt-2">

                      <div class="col-sm-12 ">
                        <h5 class="header mt-1">Company Details :</h5>
                      </div>
 
                      <div class=" col-sm-3" *ngIf="companyCreate">
                        <div class="row">
                          <div class="col-sm-10 pr-1">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Select HA Company </mat-label>
                              <mat-select #select1 formControlName="companyRefId" required>
                                <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'"
                                  [displayMember]="'companyName'" [array]="companyList"
                                  (filteredReturn)="filteredListCompany =$event"></mat-select-filter>
                                <mat-option *ngFor="let item of filteredListCompany" [value]="item._id"
                                  (click)="onSelectCompany(item)">
                                  {{ item.companyName }}</mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-error class="margin_tp"
                              *ngIf="placeOrderForm.controls['companyRefId'].touched && placeOrderForm.controls['companyRefId'].errors?.required">
                              This field is mandatory.
                            </mat-error>

                          </div>
                          <div class="col-sm-2 mt-2 no-print">
                            <i (click)="openCompanyPopUp();" matTooltip="Add Company"
                              class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"> </i>
                          </div>
                        </div>
                      </div>
                      <div class=" col-sm-3" *ngIf="!companyCreate">
                        <div class="row">
                          <div class="col-sm-12 pr-1">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Select HA Company </mat-label>
                              <mat-select #select2 formControlName="companyRefId" required>
                                <mat-select-filter *ngIf="select2.focused" [placeholder]="'Filter'"
                                  [displayMember]="'companyName'" [array]="companyList"
                                  (filteredReturn)="filteredListCompany =$event"></mat-select-filter>
                                <mat-option *ngFor="let item of filteredListCompany" [value]="item._id"
                                  (click)="onSelectCompany(item)">
                                  {{ item.companyName }}</mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-error class="margin_tp"
                              *ngIf="placeOrderForm.controls['companyRefId'].touched && placeOrderForm.controls['companyRefId'].errors?.required">
                              This field is mandatory.
                            </mat-error>

                          </div>

                        </div>
                      </div>
                      <div class="col-sm-3 ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>HA Company Name
                            <!-- <span class="required-asterisk">*</span>  -->
                          </mat-label>
                          <input matInput placeholder="HA Company Name" formControlName="companyName" readonly />

                        </mat-form-field>

                      </div>
                      <div class="col-sm-3 ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label> Address
                            <!-- <span class="required-asterisk">*</span>  -->
                          </mat-label>
                          <input matInput placeholder="Address" formControlName="companyAddress" readonly />

                        </mat-form-field>

                      </div>
                      <div class="col-sm-3 ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>HA Company Email
                          </mat-label>
                          <input matInput placeholder="HA Company Email" class="emailClass" readonly
                            formControlName="companyMail" />

                        </mat-form-field>

                      </div>
                      <div class="col-sm-3 ">
                        <div class="row">
                          <div class="col-sm-10">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Phone Number</mat-label>
                              <input matInput placeholder="Phone Number" formControlName="companyPhoneNumber"
                                readonly />

                            </mat-form-field>
                          </div>
                          <div class="col-sm-2 wApp no-print">
                            <i class="bi bi-whatsapp whatsapp-icon wApp fa-lg" (click)="WhatsApp();"
                              matTooltip="WhatsApp Integration"></i>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-3 ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>CAN Number</mat-label>
                          <input matInput placeholder="CAN Number" formControlName="canNumber" readonly />

                        </mat-form-field>

                      </div>
                      <!-- <div  class="col-sm-3">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Make</mat-label>
                          <input matInput placeholder="Make" formControlName="make" readonly />
  
                        </mat-form-field>
                        
                      </div> -->
                      <div class=" col-sm-3">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Sales Person Name</mat-label>
                          <input placeholder="Name" formControlName="salesPersonName" readonly type="text" matInput />
                        </mat-form-field>
                        <!-- <mat-error class="margin_tp"
                          *ngIf="placeOrderForm.controls['salesPersonName'].touched && placeOrderForm.controls['salesPersonName'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                        <mat-error class="margin_tp"
                      *ngIf="placeOrderForm.controls['salesPersonName'].touched && placeOrderForm.controls['salesPersonName'].errors?.pattern">
                          Please enter valid Name.
                    </mat-error> -->
                      </div>
                      <div class=" col-sm-3">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Sales Person Number</mat-label>
                          <input placeholder="Number" formControlName="salesPersonNumber" readonly type="text"
                            matInput />
                        </mat-form-field>
                      </div>
                      
                    </div>

                    <div class=" row mt-2">

                      <div class="col-sm-12">
                        <h5 class="header mt-1">HA Model Details :</h5>
                      </div>
                      <div class="col-sm-12 mt-2 table-responsive">
                        <table id="toHAFormList" class="table table-bordered data-table-custom">

                          <thead>
                            <tr class="text-center">
                              <th>Device Name</th>
                              <th>Qty</th>
                              <th>FOC Units</th>
                              <th>Total</th>
                              <th class="no-print">Action</th>

                            </tr>
                          </thead>
                          <tbody formArrayName="HAModelDetails">

                            <tr [formGroupName]="i"
                              *ngFor="let row of placeOrderForm.get('HAModelDetails')['controls']; let i = index; let last = last"
                              role="row" class="odd ">
                              <td>
                                <div class="row">
                                  <div class="col-sm-10 ">

                                    <mat-form-field class="example-full-width" appearance="outline">
                                      <mat-label>Device Name</mat-label>
                                      <mat-select #select5 formControlName="deviceRefId">
                                        <mat-select-filter *ngIf="select5.focused" [placeholder]="'Filter'"
                                          [displayMember]="'model'" [array]="DeviceList"
                                          (filteredReturn)="filteredListDevice =$event"></mat-select-filter>
                                        <mat-option *ngFor="let item of filteredListDevice" [value]="item._id"
                                          (click)="selectDevice(item.model,item._id,i)">
                                          {{ item.model }}</mat-option>
                                      </mat-select>
                                    </mat-form-field>
                                    <mat-error class="margin_tp"
                                      *ngIf="row.controls.deviceRefId.touched && row.controls.deviceRefId.errors?.required">
                                      This Field is Mandatory
                                    </mat-error>
                                  </div>
                                  <div class="col-sm-2 mt-2  pl-0 ml-0 ">
                                    <i (click)="openDevicePopUp();" matTooltip="Add Device"
                                      class="blue_col mTop fa-solid fa-circle-plus fa-lg no-print"> </i>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <mat-form-field class="example-full-width lable_style2  widthh" appearance="outline">
                                  <mat-label class="lable_style2"> Qty</mat-label>
                                  <input placeholder="Qty" formControlName="qty" type="text"
                                  (input)="totalCal(i);" matInput maxlength="4" />

                                </mat-form-field>
                                <mat-error class="margin_tp"
                                  *ngIf="row.controls.qty.touched && row.controls.qty.errors?.required">
                                  This Field is Mandatory
                                </mat-error>
                                <mat-error class="margin_tp"
                                  *ngIf="row.controls.qty.touched && row.controls.qty.errors?.pattern">
                                  Please enter valid quantity.
                                </mat-error>
                              </td>
                              <td>
                                <mat-form-field class="example-full-width lable_style2  widthh" appearance="outline">
                                  <mat-label class="lable_style2">FOC Units</mat-label>
                                  <input placeholder="FOC" formControlName="focUnits" (input)="totalCal(i);" type="text"
                                    matInput maxlength="4" />
                                </mat-form-field>
                              </td>
                              <td>
                                <mat-form-field class="example-full-width lable_style2  widthh" appearance="outline">
                                  <mat-label class="lable_style2"> Total</mat-label>
                                  <input placeholder="Total" required formControlName="totalNo" type="text" matInput />

                                </mat-form-field> 
                                <mat-error class="margin_tp"
                                  *ngIf="row.controls.totalNo.touched && row.controls.totalNo.errors?.required">
                                  This Field is Mandatory
                                </mat-error>
                                <mat-error class="margin_tp"
                                  *ngIf="row.controls.totalNo.touched && row.controls.totalNo.errors?.pattern">
                                  Please enter valid Total.
                                </mat-error>
                              </td>
                              <td class="text-center no-print">
                                <button mat-button mat-icon-button class=" ml-3 mr-2" *ngIf="last" (click)="addRow(i);"
                                  [disabled]="placeOrderForm.controls.HAModelDetails.invalid || placeOrderForm.disabled">
                                  <img matTooltip="Add" class="mt-1 " width="25" src="../../assets/add.png">
                                </button>
                                <button mat-button mat-icon-button (click)="deleteRow(i);"
                                  [disabled]="placeOrderForm.get('HAModelDetails')['controls']['length']===1 || placeOrderForm.disabled">
                                  <img matTooltip="Remove" class="mt-1" width="25" src="../../assets/dustbin.png">
                                </button>
                              </td>
                            </tr>
                          </tbody>
                          <!-- <thead>
                            <tr class="text-center">
                              <th class="no-print"></th>
                              <th class="no-print"> </th>
                              <th>Total</th>
                              <th>
                                <mat-form-field class="example-full-width lable_style2  widthh" appearance="outline">
                                  <mat-label class="lable_style2"> Total</mat-label>
                                  <input placeholder="Total" formControlName="total" matInput type="text" readonly />
                                </mat-form-field>
                              </th>
                              <th class="no-print"></th>
                            </tr>
                          </thead> -->
                        </table>
                        <hr>


                      </div>

                    </div>
                    <div class=" row mt-2">
                      <div class="col-sm-12 mt-2 table-responsive">
                        <table id="toHAFormList" class="table table-bordered data-table-custom">

                          <thead>
                            <tr class="text-center">
                              <th>Receivers</th>
                              <th>Size</th>
                              <th>Power</th>
                              <th>Qty</th>
                              <th class="no-print">Action</th>

                            </tr>
                          </thead>
                          <tbody formArrayName="HAModelReceiver">

                            <tr [formGroupName]="i"
                              *ngFor="let row of placeOrderForm.get('HAModelReceiver')['controls']; let i = index; let last = last"
                              role="row" class="odd ">
                              <td>
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Receivers</mat-label>
                                  <mat-select #select5 formControlName="receivers">
                                    <mat-option value="Right">Right</mat-option>
                                    <mat-option value="Left">Left</mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </td>
                              <td>
                                <div class="row">
                                  <div class="col-sm-10 pr-1">
                                    <mat-form-field class="example-full-width lable_style2  widthh"
                                      appearance="outline">
                                      <mat-label class="lable_style2">Size</mat-label>
                                      <!-- <input placeholder="Size" formControlName="size" type="text" matInput /> -->

                                      <mat-select #select3 formControlName="sizeRef">
                                        <mat-select-filter *ngIf="select3.focused" [placeholder]="'Filter'"
                                          [displayMember]="'size'" [array]="sizeList"
                                          (filteredReturn)="filteredListSize =$event"></mat-select-filter>
                                        <mat-option *ngFor="let item of filteredListSize" [value]="item._id"
                                          (click)="selectSize(item._id,item.size,i);">
                                          {{ item.size }}</mat-option>
                                      </mat-select>
                                    </mat-form-field>
                                  </div>
                                  <div class="col-sm-2 mt-2 no-print">
                                    <i (click)="openOrderSizePopUp();" matTooltip="Add New Size"
                                      class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"> </i>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div class="row">
                                  <div class="col-sm-10 pr-1">
                                    <mat-form-field class="example-full-width lable_style2  widthh"
                                      appearance="outline">
                                      <mat-label class="lable_style2">Power</mat-label>
                                      <!-- <input placeholder="Power" formControlName="power" type="text" matInput
                                 /> -->
                                      <mat-select #select3 formControlName="powerRef">
                                        <mat-select-filter *ngIf="select3.focused" [placeholder]="'Filter'"
                                          [displayMember]="'power'" [array]="powerList"
                                          (filteredReturn)="filteredListPower =$event"></mat-select-filter>
                                        <mat-option *ngFor="let item of filteredListPower" [value]="item._id"
                                          (click)="selectPower(item._id,item.power,i);">
                                          {{ item.power }}</mat-option>
                                      </mat-select>
                                    </mat-form-field>
                                  </div>
                                  <div class="col-sm-2 mt-2 no-print">
                                    <i (click)="openPowerPopUp();" matTooltip="Add New Power"
                                      class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"> </i>
                                  </div>
                                </div>

                              </td>
                              <td>
                                <mat-form-field class="example-full-width lable_style2  widthh" appearance="outline">
                                  <mat-label class="lable_style2">Qty</mat-label>
                                  <input placeholder="Qty" formControlName="receiversQty" type="text" matInput />
                                </mat-form-field>
                              </td>
                              <td class="text-center no-print">
                                <button mat-button mat-icon-button class=" ml-3 mr-2" *ngIf="last"
                                  (click)="addRowReceiver(i);"
                                  [disabled]="placeOrderForm.controls.HAModelReceiver.invalid || placeOrderForm.disabled">
                                  <img matTooltip="Add" class="mt-1 " width="25" src="../../assets/add.png">
                                </button>
                                <button mat-button mat-icon-button (click)="deleteRowReceiver(i);"
                                  [disabled]="placeOrderForm.get('HAModelReceiver')['controls']['length']===1 || placeOrderForm.disabled">
                                  <img matTooltip="Remove" class="mt-1" width="25" src="../../assets/dustbin.png">
                                </button>
                              </td>
                            </tr>
                          </tbody>

                        </table>
                        <hr>
                      </div>

                    </div>
                    <div class=" row mt-2">
                      <div class="col-sm-12 mt-2 table-responsive">
                        <table id="toHAFormList" class="table table-bordered data-table-custom">

                          <thead>
                            <tr class="text-center">
                              <th>Accessories</th>
                              <th>Type</th>
                              <th>Qty</th>
                              <th class="no-print">Action</th>

                            </tr>
                          </thead>
                          <tbody formArrayName="HAModelAccessories">

                            <tr [formGroupName]="i"
                              *ngFor="let row of placeOrderForm.get('HAModelAccessories')['controls']; let i = index; let last = last"
                              role="row" class="odd ">
                              <td>
                                <div class="row">
                                  <div class="col-sm-10 pr-1">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                      <mat-label>Accessories</mat-label>
                                      <!-- <mat-select #select5 formControlName="accessories">
                                        <mat-option value="Domes">Domes</mat-option>
                                        <mat-option value="Wax guards">Wax guards</mat-option>
                                        <mat-option value="Chargers">Chargers</mat-option>
                                      </mat-select> -->
                                      <mat-select #select3 formControlName="accessoriesRef">
                                        <mat-select-filter *ngIf="select3.focused" [placeholder]="'Filter'"
                                          [displayMember]="'accesories'" [array]="AccessoriesList"
                                          (filteredReturn)="filteredListAccessories =$event"></mat-select-filter>
                                        <mat-option *ngFor="let item of filteredListAccessories" [value]="item._id"
                                          (click)="selectAccessories(item._id,item.accesories,i);">
                                          {{ item.accesories }}</mat-option>
                                      </mat-select>
                                    </mat-form-field>
                                  </div>
                                  <div class="col-sm-2 mt-2 no-print">
                                    <i (click)="openAccessoriesPopUp();" matTooltip="Add New Accessories"
                                      class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"> </i>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div class="row">
                                  <div class="col-sm-10 pr-1">
                                    <mat-form-field class="example-full-width lable_style2  widthh"
                                      appearance="outline">
                                      <mat-label class="lable_style2">Type</mat-label>
                                      <!-- <mat-select #select5 formControlName="accessoriesType">
                                    <mat-option value="Tulip">Tulip</mat-option>
                                    <mat-option value="AX Ric">AX Ric</mat-option>
                                    <mat-option value="Portable Ax">Portable Ax</mat-option>
                                  </mat-select> -->
                                      <mat-select #select3 formControlName="accessoriesTypeRef">
                                        <mat-select-filter *ngIf="select3.focused" [placeholder]="'Filter'"
                                          [displayMember]="'type'" [array]="AccessoriesTypeList"
                                          (filteredReturn)="filteredListAccessoriesType =$event"></mat-select-filter>
                                        <mat-option *ngFor="let item of filteredListAccessoriesType" [value]="item._id"
                                          (click)="selectType(item._id,item.type,i);">
                                          {{ item.type }}</mat-option>
                                      </mat-select>
                                    </mat-form-field>
                                  </div>
                                  <div class="col-sm-2 mt-2 no-print">
                                    <i (click)="openOrderTypePopUp();" matTooltip="Add New Type"
                                      class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"> </i>
                                  </div>
                                </div>

                              </td>
                              <td>
                                <mat-form-field class="example-full-width lable_style2  widthh" appearance="outline">
                                  <mat-label class="lable_style2">Qty</mat-label>
                                  <input placeholder="Qty" formControlName="accessoriesQty" type="text" matInput />
                                </mat-form-field>
                              </td>

                              <td class="text-center no-print">
                                <button mat-button mat-icon-button class=" ml-3 mr-2" *ngIf="last"
                                  (click)="addRowAccessories(i);"
                                  [disabled]="placeOrderForm.controls.HAModelAccessories.invalid || placeOrderForm.disabled">
                                  <img matTooltip="Add" class="mt-1 " width="25" src="../../assets/add.png">
                                </button>
                                <button mat-button mat-icon-button (click)="deleteRowAccessories(i);"
                                  [disabled]="placeOrderForm.get('HAModelAccessories')['controls']['length']===1 || placeOrderForm.disabled">
                                  <img matTooltip="Remove" class="mt-1" width="25" src="../../assets/dustbin.png">
                                </button>
                              </td>
                            </tr>
                          </tbody>

                        </table>
                        <hr>
                      </div>
                      <div class=" col-sm-3">
                        <div class="row">
                          <div class="col-sm-10 pr-1">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Select HA Color </mat-label>
                              <mat-select #select1 formControlName="haColorRefId" required>
                                <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'"
                                  [displayMember]="'HaColorName'" [array]="haColorList"
                                  (filteredReturn)="filteredListHaColor =$event"></mat-select-filter>
                                <mat-option *ngFor="let item of filteredListHaColor" [value]="item._id"
                                  (click)="onSelectHaColor(item)">
                                  {{ item.HaColorName }}</mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-error class="margin_tp"
                              *ngIf="placeOrderForm.controls['haColorRefId'].touched && placeOrderForm.controls['haColorRefId'].errors?.required">
                              This field is mandatory.
                            </mat-error>
  
                          </div>
                          <div class="col-sm-2 mt-2 no-print">
                            <i (click)="openHAColorPopUp();" matTooltip="Add HA color"
                              class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"> </i>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="billingCreate" class="col-sm-3">
                        <div class="row">
                          <div class="col-sm-10 pr-1">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Billing Company
                                <!-- <span class="required-asterisk">*</span> -->
                              </mat-label>
                              <mat-select #select1 required formControlName="billingCompanyRefId">
                                <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'"
                                  [displayMember]="'companyName'" [array]="billingCompanyList"
                                  (filteredReturn)="filteredListBillingCompany =$event"></mat-select-filter>
                                <mat-option *ngFor="let item of filteredListBillingCompany" [value]="item._id"
                                  (click)="companyClick(item._id,item.companyName);">
                                  {{ item.companyName }}</mat-option>
                              </mat-select>
    
                            </mat-form-field>
                            <mat-error class="margin_tp"
                              *ngIf="placeOrderForm.controls['billingCompanyRefId'].touched && placeOrderForm.controls['billingCompanyRefId'].errors?.required">
                              This field is mandatory.
                            </mat-error>
                          </div>
                          <div class="col-sm-2 mt-2 no-print">
                            <i (click)="openBillingCompPopUp();" matTooltip="Add Billing Company" class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                          </div>
                        </div> 
                      </div>
                      <div *ngIf="!billingCreate" class="col-sm-3">
                        <div class="row">
                          <div class="col-sm-12 pr-1">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Billing Company
                                <!-- <span class="required-asterisk">*</span> -->
                              </mat-label>
                              <mat-select #select1 formControlName="billingCompanyRefId">
                                <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'"
                                  [displayMember]="'companyName'" [array]="billingCompanyList"
                                  (filteredReturn)="filteredListBillingCompany =$event"></mat-select-filter>
                                <mat-option *ngFor="let item of filteredListBillingCompany" [value]="item._id"
                                  (click)="companyClick(item._id,item.companyName);">
                                  {{ item.companyName }}</mat-option>
                              </mat-select> 
    
                            </mat-form-field>
                            <mat-error class="margin_tp"
                              *ngIf="placeOrderForm.controls['billingCompanyRefId'].touched && placeOrderForm.controls['billingCompanyRefId'].errors?.required">
                              This field is mandatory.
                            </mat-error>
                          </div>
    
                        </div>
                      </div>
                      <div class=" col-sm-4">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Notes</mat-label>
                          <textarea maxlength="1000" matInput formControlName="notes" 
                          cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5"></textarea>
                        </mat-form-field>
                      </div>
                    </div>
                  

                  </div>
                  <div class="col-sm-10 no-print" *ngIf="submitButton">
                  </div>
                  <div class="col-sm-4 no-print" *ngIf="updateButton">
                  </div>
                  <!-- <div class="col-sm-2 mb-2 mt-2 no-print" >
                                        <button class="btn btn-primary btn-sm example-full-width" type="button" 
                                         >Email</button>
                                      </div> -->
                  <div class="col-sm-2 mb-2 mt-2 no-print" *ngIf="updateButton && placeOrderUpdate">
                    <button class="btn btn-primary btn-sm example-full-width" type="button"
                      (click)="printForm();">Generate Pdf</button>
                  </div>

                  <div class="col-sm-2 mb-2 mt-2 no-print" *ngIf="submitButton && placeOrderCreate">
                    <button class="btn btn-primary btn-sm example-full-width" type="button"
                      (click)="submit()">Save</button>
                  </div>
                  <div class="col-sm-2 mb-2 mt-2 no-print" *ngIf="updateButton && placeOrderUpdate">
                    <button class="btn btn-primary btn-sm   example-full-width" type="button"
                      (click)="openCopyPopUp()">Copy Latter</button>
                  </div>
                  <div class="col-sm-2 mb-2 mt-2 no-print" *ngIf="updateButton && placeOrderUpdate">
                    <button class="btn btn-primary btn-sm   example-full-width" type="button"
                      (click)="updatePlaceOrderData()">Update</button>
                  </div>
                  <div class="col-sm-2 mb-2 mt-2 no-print" *ngIf="cancelButton">
                    <button class="btn btn-primary btn-sm example-full-width" (click)="cancelUpdate()"
                      type="reset">Cancel</button>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-12 pl-0 pr-2 no-print">
          <div class="row">
            <div class="card divbody col-sm-12 borderradius p-3 pb-0">
              <div class="col-sm-12">
                <div class="row">
                  <div class="col-sm-9 ">
                    <h1 class="titlecolor"> Place Order List</h1>
                  </div>
                  <div class="search-box col-sm-3 pb-2">
                    <input class="form-control amber-border" type="text" placeholder="Search"
                      formControlName="searchPlaceOrder" aria-label="Search" (keyup)="onKeyUpEvent($event)"
                      onkeydown="return (event.keyCode!=13);">
                    <div class="input-group-append">
                      <span class="input-group-text amber lighten-3" id="basic-text1"><span
                          class="material-symbols-outlined">search</span></span>
                    </div>
                  </div>

                  <div class="col-sm-12 table-responsive">
                    <table id="toPlaceOrderList" class="table table-bordered data-table-custom">
                      <thead>
                        <tr class="text-center">
                          <th>Place Order ID</th>
                          <th *ngIf="userType == 'Super Admin'">Branch Name</th>
                          <th>Clinic Name</th>
                          <th>Company Name</th>
                          <th>Sales Person</th>
                          <th>Order Date</th>
                          <th>Received Date</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of listofPlaceOrder | paginate : { id:'placeOrderMaster',
                                      itemsPerPage: size, currentPage: page, totalItems:totalLength }"
                          (click)="prepopulation(item._id)" (click)="toPlaceOrderForm()"
                          style="cursor:pointer; text-align: center ">
                          <td matTooltip="click to view">{{item.branchWiseId}}</td>
                          <td *ngIf="userType == 'Super Admin'" matTooltip="click to view">{{item.branchName}}
                          </td>
                          <td matTooltip="click to view">{{item.clinicName}}</td>
                          <td matTooltip="click to view">{{item.companyName}}</td>
                          <td matTooltip="click to view">{{item.salesPersonName}}</td>
                          <!-- <td class="justify-content-center">
                        <tr class="justify-content-center" *ngFor="let prod of item.HAModelDetails">
                          <label class="justify-content-center">{{prod.leftModel}}</label>

                        </tr>
                        </td> -->
                          <td matTooltip="click to view">{{item.createdAt | date:'dd/MM/yyyy'}}</td>
                          <td class="labelStyle" matTooltip="Click To View">
                            <label *ngIf="item.placeOrderStatus == 'Received'; else elseBlock">{{item.updatedAt |
                              date:'dd/MM/yyyy' }}</label>
                            <ng-template #elseBlock>
                              <label>-</label>
                            </ng-template>
                          </td>

                          <!-- <td class="justify-content-center">
                          <tr class="justify-content-center" *ngFor="let prod of item.HAModelDetails">
                            <label class="justify-content-center">{{prod.rightModel}}</label>

                          </tr>
                        </td> -->
                          <!-- <td *ngFor="let prod of item.HAModelDetails" matTooltip="click to view">{{prod.model}}</td> -->
                          <td matTooltip="click to view">{{item.placeOrderStatus}}</td>
                        </tr>


                      </tbody>
                    </table>
                  </div>
                  <div class="col-sm-12 pt-0 text-right">
                    <pagination-controls id="placeOrderMaster" directionLinks="true" (pageChange)="onPageChange($event)"
                      class="float-right pagination my-pagination"></pagination-controls>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>


      </div>
    </div>
  </form>
</div>