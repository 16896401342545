import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.scss']
})
export class MasterComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<MasterComponent>,@Inject(MAT_DIALOG_DATA) public data:any,) { 
        dialogRef.disableClose = true;
    }
    userPermission: any;
    userType: string;
    patientView= true;
    clinicView= true;
    branchView= true;
    vendorView= true;
    devicesView= true;
    companyMasterView = true;
    billingCompanyView= true;
    userView= true;
    clinicalStaffView= true;
    appointmentTypeView= true;
    servicesView= true;
    doctorView= true;
    courierServiceProviderView= true;
    roleView= true;
    serviceRateView= true;
    genericSettingView= true;
    doctorAvailabilityView= true;
    migratedDataView= true;
  ngOnInit(): void {
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    this.userType = sessionStorage.getItem('userType');
    if(this.userType !== 'Super Admin'){
    this.userPermission.forEach((element) => {
      if(element.pageName == 'Patient' && element.view == false)
      {
          this.patientView = false;  
      }
      if(element.pageName == 'Clinic' && element.view == false)
      {
          this.clinicView = false;  
      }    
      if(element.pageName == 'Branch' && element.view == false)
      {
          this.branchView = false;  
      }   
      if(element.pageName == 'Vendor' && element.view == false)
      {
          this.vendorView = false;  
      } 
      if(element.pageName == 'Devices' && element.view == false)
      {
          this.devicesView = false;  
      }
      if(element.pageName == 'Billing Company' && element.view == false)
      {
          this.billingCompanyView = false;  
      }
      if(element.pageName == 'User' && element.view == false)
      {
          this.userView = false;  
      }
      if(element.pageName == 'Clinical Staff' && element.view == false)
      {
          this.clinicalStaffView = false;  
      }
      if(element.pageName == 'Appointment Type' && element.view == false)
      {
          this.appointmentTypeView = false;  
      }
      if(element.pageName == 'Services' && element.view == false)
      {
          this.servicesView = false;  
      }
      if(element.pageName == 'Doctor' && element.view == false)
      {
          this.doctorView = false;  
      }
      if(element.pageName == 'Courier Service Provider' && element.view == false)
      {
          this.courierServiceProviderView = false;  
      }
      if(element.pageName == 'Role' && element.view == false)
      {
          this.roleView = false;  
      }
      if(element.pageName == 'Service Rate' && element.view == false)
      {
          this.serviceRateView = false;  
      }
      if(element.pageName == 'Generic Setting' && element.view == false)
      {
          this.genericSettingView = false;  
      }
      if(element.pageName == 'Doctor Availability' && element.view == false)
      {
          this.doctorAvailabilityView = false;  
      }
      if(element.pageName == 'Company Master' && element.view == false)
      { 
          this.companyMasterView = false;  
      }
      if(element.pageName == 'Migrated Data' && element.view == false)
      { 
          this.migratedDataView = false;  
      }
      
    });
  }
  }

  close(): void {
    this.dialogRef.close();
  }
  controlClick()
  {
    sessionStorage.setItem('controlClick','controlPanel');
    this.dialogRef.close();
  }
  onClose(): void {
    this.dialogRef.close();
  }
}
