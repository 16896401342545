import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
 import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";
import { MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PopUpPatientComponent } from "../pop-up-patient/pop-up-patient.component";
import { PopUpBranchComponent } from "../pop-up-branch/pop-up-branch.component";
import { PopUpDeviceComponent } from "../pop-up-device/pop-up-device.component";
import { PatientSearchPopComponent } from '../patient-search-pop/patient-search-pop.component';
import { Router } from "@angular/router";
@Component({
  selector: 'app-goods-in-word-return',
  templateUrl: './goods-in-word-return.component.html',
  styleUrls: ['./goods-in-word-return.component.scss']
})
export class GoodsInWordReturnComponent implements OnInit {
  purchaseReturnForm: FormGroup;
  userType: string;
  isSuperAdmin = true;
  userPermission: any;
  branchRef: string;
  clinicRef: string;
  branchName: string;
  purchaseReturnFormCreate = true;
  purchaseReturnFormUpdate = true;
  filteredListBranch: any;
  branchList: any;
  size=10;
  page=1;
  searchText: any;
  submitButton = true;
  updateButton = false;
  superBranchId: any;
  patientlist: any;
  filteredPatientList: any;
  patientImage: any; 
  patientImage2: any; 
  errormessage: string;
  deviceList: any;
  filteredDeviceList: any;
  listOfpurchaseReturn: any;
  totalLength: any;
  pid: any;
returnDate :Date;
modalImg: any;
modalImg2: any;
modal: any;
modal2: any;
patientCreate = true;
isTempGiven= false;
  hat_patientRefId: string;
  hat_patientName: string;
  ifPrint='false';
  currentdate: Date;
  maxDate: Date;
  bID: any;
  isPrepopulate = false;
  serialNoList = [];
  leftSerialNoList = [];
  rightSerialNoList = [];
  maxDateStr: string;
  minDateStr: string;
  qtyCount = 0;
  tempArr: any;
  tempData: any;
  oldSerialNo = '';
  preData :any;
  returnno = 0;
  constructor(private fb: FormBuilder,
    public apimicroservice: ApiServiceService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    public router: Router) { }

  ngOnInit(): void {
    this.isPrepopulate = false;
    this.oldSerialNo = '';
    this.currentdate = new Date(); 
    this.page=1;
    var futureDate = new Date();
    futureDate.setDate(futureDate.getDate() + 30);
    //
    this.maxDate =futureDate;
   // this.maxDateStr = futureDate.toDateString();
   let mn = futureDate.getMonth() + 1;
   this.maxDateStr = futureDate.getDate() + '/' + mn + '/' + futureDate.getFullYear();
    let mm = this.currentdate.getMonth() + 1;
    this.minDateStr = this.currentdate.getDate() + '/' + mm + '/' + this.currentdate.getFullYear();
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.userType = sessionStorage.getItem('userType');
    this.hat_patientRefId = sessionStorage.getItem('hat_patientRefId');
    this.hat_patientName = sessionStorage.getItem('hat_patientName');
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    if(this.userType !== 'Super Admin'){
      this.isSuperAdmin= false;
    this.userPermission.forEach((element) => {
      if(element.pageName == 'Purchase Return' && element.create == false)
      {
          this.purchaseReturnFormCreate = false;  
      }
      if(element.pageName == 'Purchase Return' && element.update == false)
      {
          this.purchaseReturnFormUpdate = false;  
      }  
      if(element.pageName == 'Patient' && element.create == false)
      {
          this.patientCreate = false;  
      }  
    });
  }
    this.purchaseReturnForm = this.fb.group({
      "returnId": new FormControl(''),
      "purchaseReturnDate": new FormControl(''),
      "branchRefId": new FormControl(),     
      "branchName": new FormControl(''),
      "deviceRefId": new FormControl([Validators.required]),
      "deviceName": new FormControl(''),
      //"serialNo": new FormControl(),
      "serialNumber": new FormControl('',[Validators.required]),
      "status": new FormControl(),
      "qty": new FormControl(0),
      "comment": new FormControl(),
       "searchReturn": new FormControl(''),
      "createdAt": new Date(),
      // "updatedAt": new Date(),
      
    });
    //this.patientImage ="../../assets/no_data_found.jpeg";
    
   // this.purchaseReturnForm.get("status").setValue('Issued');
   this.purchaseReturnForm.get("purchaseReturnDate").setValue(this.currentdate);
    this.fetchPurchaseReturnList();
    //this.getDevice();
    this.getLastId();


    if(this.userType == 'Super Admin'){
      this.fetchBranchList(); 
      this.getAllBranch();
    }
  //   if(this.hat_patientRefId !== '')
  //  {
  //   this.purchaseReturnForm.controls["patientName"].setValue(this.hat_patientName);
  //   this.selectPatient(this.hat_patientRefId);  
  //   sessionStorage.setItem('hat_patientName','');
  //   sessionStorage.setItem('hat_patientRefId','');
  //  }
  
 //  this.removeValidator();
  }

  // removeValidator() { 
  //   this.purchaseReturnForm.controls['patientName'].setErrors(null);
  //   this.purchaseReturnForm.controls['patientName'].clearValidators();
  //   this.purchaseReturnForm.controls['patientName'].updateValueAndValidity();
    
  //   this.purchaseReturnForm.controls['phoneNo'].setErrors(null);
  //   this.purchaseReturnForm.controls['phoneNo'].clearValidators();
  //   this.purchaseReturnForm.controls['phoneNo'].updateValueAndValidity();
    
  //   this.purchaseReturnForm.controls['noOfDays'].setErrors(null);
  //   this.purchaseReturnForm.controls['noOfDays'].clearValidators();
  //   this.purchaseReturnForm.controls['noOfDays'].updateValueAndValidity();
   
  //   this.purchaseReturnForm.controls['advanceRs'].setErrors(null);
  //   this.purchaseReturnForm.controls['advanceRs'].clearValidators();
  //   this.purchaseReturnForm.controls['advanceRs'].updateValueAndValidity();

   
    
  // }


  // setValidator() { 
  //   this.purchaseReturnForm.controls['patientName'].setValidators([Validators.required,Validators.pattern("^[a-zA-Z ]*$")]);
  //   this.purchaseReturnForm.controls['patientName'].updateValueAndValidity();

  //   this.purchaseReturnForm.controls['phoneNo'].setValidators(Validators.required);
  //   this.purchaseReturnForm.controls['phoneNo'].updateValueAndValidity();

  //   this.purchaseReturnForm.controls['noOfDays'].setValidators([Validators.required,Validators.min(7), Validators.max(365)]);
  //   this.purchaseReturnForm.controls['noOfDays'].updateValueAndValidity();

  //   this.purchaseReturnForm.controls['advanceRs'].setValidators([Validators.required,Validators.pattern("^[0-9 .]*$")]);
  //   this.purchaseReturnForm.controls['advanceRs'].updateValueAndValidity();
    
  // }
//   getAllBranch()
// {
//   this.apimicroservice.getAllBranchList().subscribe(res => {
//     if (res.status) {        
//       let length =res.data.length - 1;
//       this.branchName =res.data[length].branchName;
//       this.purchaseReturnForm.controls["branchRefId"].setValue(res.data[length]._id);
//       this.branchClick(res.data[length]._id,this.branchName);
//       if(this.userType == 'Super Admin')
//       {  
//         this.apimicroservice.getBranchById(res.data[length]._id).subscribe(res => {
//           this.spinner.hide();
//           this.purchaseReturnForm.get("branchName").setValue(res.data[length].branchName);
//          });
//       }
     
//     }
//   });
// }
  getAllBranch()
  {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      if (res.status) {        
        let length = res.data.length - 1;
        this.purchaseReturnForm.controls["branchRefId"].setValue(res.data[length]._id);
        this.purchaseReturnForm.get("branchName").setValue(res.data[length].branchName);
        this.branchClick(res.data[length]._id,res.data[length].branchName); 
        ////
        // if(this.userType == 'Super Admin')
        // {  
          
        //   this.apimicroservice.getBranchById(res.data[length]._id).subscribe(res => {
        //     this.spinner.hide();
        //     this.purchaseReturnForm.get("branchName").setValue(res.data[length].branchName);
        //     //
        //    });
        // }
       
      }
    });
  }
  closeClick()
  {
    this.modal.style.display = "none";
  }
  imgClick()
  {
    this.modal.style.display = "block";
    this.modalImg.src = this.patientImage;
  }
  closeClick2()
  {
    this.modal2.style.display = "none";
  }
  imgClick2()
  {
    this.modal2.style.display = "block";
    this.modalImg2.src = this.patientImage2;
  }
  topurchaseReturnForm() {
    document.getElementById("topurchaseReturnForm").scrollIntoView();
  }
  topurchaseReturnFormList() {
    document.getElementById("topurchaseReturnFormList").scrollIntoView();
  }
  prepopulation(id)
  {
    this.spinner.show();
   // this.preData = [];
    this.apimicroservice.getStockMaintainById(id).subscribe(res => {
      this.spinner.hide();
      let data = res.data[0];
      this.preData = data;
      this.pid = id;
      this.isPrepopulate = true;
       if(res.status){
         this.updateButton = true;
         this.submitButton = false;
      //   this.cancelButton=true;
         this.purchaseReturnForm = this.fb.group({
          "returnId": new FormControl(data.returnId),
      "purchaseReturnDate": new FormControl(data.purchaseReturnDate),
      "branchRefId": new FormControl(data.branchRefId),     
      "branchName": new FormControl(data.branchName),
      "deviceRefId": new FormControl(data.deviceRefId,[Validators.required]),
      "deviceName": new FormControl(data.deviceName),
      "serialNumber": new FormControl([data.serialNumber],[Validators.required]),
      "status": new FormControl(data.status),
      "qty": new FormControl(data.qty),
      "comment": new FormControl(data.comment),
       "searchReturn": new FormControl(''),
          "updatedAt": new Date(),
    
        });
         this.superBranchId = data.returnId;
         //this.superBranchId = data.branchWiseId;
         this.bID =data.returnId;
          this.oldSerialNo = data.serialNumber;
          this.qtyCount = data.qty;
          this.selectDevice(data.deviceRefId,data.deviceName);
        // this.tempHagivenClick();
       }else{
     //    //this.apimicroservice.openSnackbar(res.message);
       }
     });
  }
 
  cancelUpdate(){
    this.patientImage = "../../assets/no_data_found.jpeg";
    this.patientImage2 = "../../assets/no_data_found.jpeg";
    this.submitButton=true;
    this.updateButton=false;
    this.purchaseReturnForm.get("purchaseReturnDate").setValue(this.currentdate);
    this.ngOnInit();
    this.purchaseReturnForm.reset();
    this.purchaseReturnForm.get("purchaseReturnDate").setValue(this.currentdate);
  }
  getAppPatient()
  {
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
    this.apimicroservice.getPatientListAll(branchRef,clinicRef).subscribe(res => {
      if (res.status) {
        // //
        this.patientlist = res.data.data;
        this.filteredPatientList= this.patientlist.slice();
        this.selectPatient(this.patientlist[0]._id);
       this.purchaseReturnForm.get("patientName").setValue(this.patientlist[0].firstName);
      }
    });
  }else{
    this.apimicroservice.getPatientListAll(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
      if (res.status) {
        // //
        this.patientlist = res.data.data;
        this.filteredPatientList= this.patientlist.slice();
        this.selectPatient(this.patientlist[0]._id);
       this.purchaseReturnForm.get("patientName").setValue(this.patientlist[0].firstName);
      }
    });
  }
  }
  selectPatient(id){
    this.purchaseReturnForm.get("patientRefId").setValue(id);
   this.apimicroservice.getPatientById(id).subscribe(res => {
     //
   // let mobile1 = res.data[0].mobile1
    this.purchaseReturnForm.get("pBranchName").setValue(res.data[0].branchName);
   // this.purchaseReturnForm.get("branchRefId").setValue(res.data[0].branchRefId);
   this.purchaseReturnForm.get("phoneNo").setValue(res.data[0].mobile1);
   this.purchaseReturnForm.get("address").setValue(res.data[0].streetAddress);
    //
    });
  }
  getDevice(){
    this.apimicroservice.getAllDevice().subscribe(res => {
      if (res.status) {
        this.deviceList = res.data.data;
        this.filteredDeviceList= this.deviceList.slice();
        // this.selectDevice(this.deviceList[0]._id);
        // this.purchaseReturnForm.get("deviceName").setValue(this.deviceList[0].model);
      }
    });
  }
  selectDevice(id,dname){
    this.returnno = this.purchaseReturnForm.get("returnId").value;
    this.purchaseReturnForm.get("deviceRefId").setValue(id);
    let dstatus = "unsold";
    let deviceId= "";
    let branchRef= sessionStorage.getItem('branchRef');
    let tempsrno = [];
    if(this.userType == 'Super Admin'){
    // let branchRef = this.purchaseReturnForm.controls["branchRefId"].value;
    let branchRef= sessionStorage.getItem('branchRef');
    this.apimicroservice.getStockMaintainByDeviceIdStatus(id,deviceId,dstatus,branchRef).subscribe(res => {
      //
      let data = res.data;
      if(res.message !== 'No Data Found'){
        //
        res.data.forEach(element =>{ 
          if(element.transferStatus == undefined || element.transferStatus == "" || element.transferStatus == "Accept" || element.transferStatus == "Reject"){
            tempsrno.push(element);
          }
         });
      
      this.serialNoList = tempsrno;
      if(this.oldSerialNo !== ''){
        this.serialNoList.push(this.preData);
        let arr = [];
        arr.push(this.oldSerialNo);
        this.purchaseReturnForm.controls['serialNumber'].setValue(arr);
      }
      this.tempArr = [];
      this.tempData = [];
      this.serialNoList.forEach(ele =>{
        this.tempArr.push({"_id":ele._id,"serialNumber":ele.serialNumber});
        this.tempData.push({"_id":ele._id,"serialNumber":ele.serialNumber,"status":ele.status,"returnId":0});
      });
      
      }else{
        this.serialNoList = [];
        this.tempArr = [];
      this.tempData = [];
      }
    })
  }else{
    this.apimicroservice.getStockMaintainByDeviceIdStatus(id,deviceId,dstatus,branchRef).subscribe(res => {
      //
      let data = res.data;
      if(res.message !== 'No Data Found'){
        //
        res.data.forEach(element =>{ 
          if(element.transferStatus == undefined || element.transferStatus == "" || element.transferStatus == "Accept" || element.transferStatus == "Reject"){
            tempsrno.push(element);
          }
         });
      // this.serialNoList[i] = data;
      this.serialNoList = tempsrno;
      if(this.oldSerialNo !== ''){
        this.serialNoList.push(this.preData);
        let arr = [];
        arr.push(this.oldSerialNo);
        this.purchaseReturnForm.controls['serialNumber'].setValue(arr);
      }
      this.tempArr = [];
      this.tempData = [];
      this.serialNoList.forEach(ele =>{
        this.tempArr.push({"_id":ele._id,"serialNumber":ele.serialNumber});
        this.tempData.push({"_id":ele._id,"serialNumber":ele.serialNumber,"status":ele.status,"returnId":0});
      });
      
      }else{
        this.serialNoList = [];
        this.tempArr = [];
      this.tempData = [];
      }
    })
  }
  }

  onSelectSerialNo(event:any,srno,id,status){
    // //
     //
     //
     //
     //
     //let tempArr = [];
     
     
     if(event.selected == true){
       //
       this.qtyCount = this.qtyCount + 1;
       
       this.tempData.forEach(ele =>{
        if(ele.serialNumber == srno){
          ele.status = "return";
          ele.returnId = this.returnno;
          this.returnno = this.returnno + 1;
        }
       })
         
         //
         this.purchaseReturnForm.get("qty").setValue(this.qtyCount);
      
     }else{
       //
       this.qtyCount = this.qtyCount - 1;
       
       this.tempData.forEach(ele =>{
        if(ele.serialNumber == srno){
          ele.status = "unsold";
          this.returnno = this.returnno - 1;
        }
       })
       this.purchaseReturnForm.get("qty").setValue(this.qtyCount);
      //
      
     } 
   }

  serialClick(srno){
    //.value);
    let rightsrno =  this.purchaseReturnForm.get("RightSerialNo").value;
    //
    let tempsrnos= [];
    if(rightsrno == null){
    tempsrnos = this.leftSerialNoList;
    tempsrnos = tempsrnos.filter(m => m.serialNumber!=srno);
  //
  this.rightSerialNoList = tempsrnos;
  }else{
    if(rightsrno == srno){
      this.apimicroservice.openSnackbar("Do not enter same left and right serial number!");
      this.purchaseReturnForm.get("LeftSerialNo").setValue('');
    }
  }
  }
  rightserialClick(srno){
    //.value);
    let leftsrno =  this.purchaseReturnForm.get("LeftSerialNo").value;
    //
    let tempsrnos= [];
    if(leftsrno == null){
    tempsrnos = this.rightSerialNoList;
    tempsrnos = tempsrnos.filter(m => m.serialNumber!=srno);
  //
  this.leftSerialNoList = tempsrnos;
    }else{
      if(leftsrno == srno){
        this.apimicroservice.openSnackbar("Do not enter same left and right serial number!");
        this.purchaseReturnForm.get("RightSerialNo").setValue('');
      }
    }
  }
 
  getLastId()
  {
     if(this.userType !== 'Super Admin'){
      let status = "return";
      let branchRef= sessionStorage.getItem('branchRef');
    this.apimicroservice.getLastReurnID(branchRef,status).subscribe(res => {
      //   
     
      if (res['status']) {
        
        let clientIdd = res.data.length;
        //
        this.purchaseReturnForm.controls['returnId'].setValue(clientIdd + 1);
        this.bID=clientIdd+1;      
      } else {
        this.apimicroservice.openSnackbar("Something went wrong");
      }
     
    })
  }
  else
  {
    let status = "return";
    let branchRef= sessionStorage.getItem('branchRef');
    this.apimicroservice.getLastReurnID(branchRef,status).subscribe(res => {
      //   
     
      if (res['status']) {
        
        let clientIdd = res.data.length;
        //
        this.superBranchId = clientIdd + 1;
        this.bID = clientIdd + 1;
      } else {
        this.apimicroservice.openSnackbar("Something went wrong");
      }
     
    })
  }
  }
  saveData(){
    //this.setValidator();
    if(this.userType == 'Super Admin')
    {
      this.purchaseReturnForm.controls['branchRefId'].setValidators(Validators.required);
      this.purchaseReturnForm.controls['branchRefId'].updateValueAndValidity();
    }
    else{
      this.purchaseReturnForm.controls['branchRefId'].setErrors(null);
      this.purchaseReturnForm.controls['branchRefId'].clearValidators();
      this.purchaseReturnForm.controls['branchRefId'].updateValueAndValidity();
    }
    if (this.purchaseReturnForm.invalid) {
      
      this.purchaseReturnForm.get("serialNumber").markAsTouched();
      this.purchaseReturnForm.get("deviceRefId").markAsTouched();
      
      return;
    } else {
      this.spinner.show();
      let payload = this.purchaseReturnForm.value;
      //
      payload.createdAt= new Date();
      let stockMaintainPayload = {};
      if(this.userType !== 'Super Admin')
      {
        payload.branchRefId = this.branchRef;
        payload.branchName = this.branchName;
        //
      }
      stockMaintainPayload['status'] = "return";
      stockMaintainPayload['purchaseReturnDate'] = payload.purchaseReturnDate;
      stockMaintainPayload['comment'] = payload.comment;
      
      let bar = new Promise<void>((resolve,reject) =>{
      this.tempData.forEach(element =>{
        //
         if(element.status == "return"){ 
          stockMaintainPayload['returnId'] = element.returnId;
          this.apimicroservice.updateStockMaintain(element._id,stockMaintainPayload).subscribe(res => {
            //
            this.apimicroservice.openSnackbar("Save Data Successfully");
            resolve();
          });
         }
        
  });
});
bar.then(() => {
  this.ngOnInit();
});
      
    }
    
  }
  fetchPurchaseReturnList() {
    let pg = 1;
    let size = 10;
    let search='';
    let branchRef= sessionStorage.getItem('branchRef');
    this.spinner.show();
    this.searchText = this.purchaseReturnForm.get('searchReturn').value; 
      let status = 'return';
      this.apimicroservice.getStockPaginationData(pg,size,search,branchRef,status).subscribe(res => {
        this.spinner.hide(); 
          this.listOfpurchaseReturn = res.data.stockData;
          this.totalLength = res.data.totalItems;
         if(res.status){
          // this.apimicroservice.openSnackbar(res.message);        
         }else{
           //this.apimicroservice.openSnackbar(res.message);
         }
       });
  }
  onKeyUpEvent(event: any) {
    this.searchText = this.purchaseReturnForm.get('searchReturn').value;
    let branchRef= sessionStorage.getItem('branchRef');
      //
      let status = 'return';
      this.apimicroservice.getStockPaginationData(this.page,this.size,this.searchText,branchRef,status).subscribe(res => {
        this.spinner.hide(); 
          this.listOfpurchaseReturn = res.data.stockData;
          this.totalLength = res.data.totalItems;
          this.page=res.data.pageNumber;
        //this.buildingLenght = this.listofbuild.length;
       if(res.status){
        //  this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
  }
  onPageChange(event) {
  //  this.spinner.show();
    this.page = event;
    this.searchText = this.purchaseReturnForm.get('searchReturn').value;
    let branchRef= sessionStorage.getItem('branchRef');
      let status = 'return';
      this.apimicroservice.getStockPaginationData(this.page,this.size,this.searchText,branchRef,status).subscribe(res => {
       // this.spinner.hide(); 
          this.listOfpurchaseReturn = res.data.stockData;
          this.totalLength = res.data.totalItems;
        //this.buildingLenght = this.listofbuild.length;
       if(res.status){
        //  this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
  
     });
  }
  updateReturnDetails() {
  //  this.setValidator();
  if(this.userType == 'Super Admin')
    {
      this.purchaseReturnForm.controls['branchRefId'].setValidators(Validators.required);
      this.purchaseReturnForm.controls['branchRefId'].updateValueAndValidity();
    }
    else{
      this.purchaseReturnForm.controls['branchRefId'].setErrors(null);
      this.purchaseReturnForm.controls['branchRefId'].clearValidators();
      this.purchaseReturnForm.controls['branchRefId'].updateValueAndValidity();
    }
    if (this.purchaseReturnForm.invalid) {
      
      this.purchaseReturnForm.get("serialNumber").markAsTouched();
      this.purchaseReturnForm.get("deviceRefId").markAsTouched();
     
      return;
    } else {
      this.updatepurchaseReturnForm();
    }
  }
  updatepurchaseReturnForm() {
    this.spinner.show();
    let payload = this.purchaseReturnForm.value;
    
    let stockMaintainPayload = {};
    if(this.userType !== 'Super Admin')
    {
      payload.branchRefId = this.branchRef;
      payload.branchName = this.branchName;
      //
    }
    
    let bar = new Promise<void>((resolve,reject) =>{
      this.spinner.hide();
    this.tempData.forEach(element =>{
      //
       if((element.status == "return") || (element.serialNumber == this.oldSerialNo)){
        stockMaintainPayload['status'] = element.status;
        stockMaintainPayload['purchaseReturnDate'] = payload.purchaseReturnDate;
      stockMaintainPayload['comment'] = payload.comment;
        this.apimicroservice.updateStockMaintain(element._id,stockMaintainPayload).subscribe(res => {
          //
          this.apimicroservice.openSnackbar(res.message);
          resolve();
        });
       }
      //  else if(element.serialNumber == this.oldSerialNo){
      //   stockMaintainPayload['status'] = element.status;
      //   stockMaintainPayload['purchaseReturnDate'] = payload.purchaseReturnDate;
      // stockMaintainPayload['comment'] = payload.comment;
      //   this.apimicroservice.updateStockMaintain(element._id,stockMaintainPayload).subscribe(res => {
      //     //
      //     resolve();
      //   });
      //  }
      
});
});
bar.then(() => {
//this.ngOnInit();
window.location.reload();
});
    
    
  }
  purchaseReturn() {
 //this.setValidator();
 if(this.userType == 'Super Admin')
 {
   this.purchaseReturnForm.controls['branchRefId'].setValidators(Validators.required);
   this.purchaseReturnForm.controls['branchRefId'].updateValueAndValidity();
 }
 else{
   this.purchaseReturnForm.controls['branchRefId'].setErrors(null);
   this.purchaseReturnForm.controls['branchRefId'].clearValidators();
   this.purchaseReturnForm.controls['branchRefId'].updateValueAndValidity();
 }
 if (this.purchaseReturnForm.invalid) {
   
   this.purchaseReturnForm.get("serialNumber").markAsTouched();
   this.purchaseReturnForm.get("deviceRefId").markAsTouched();
   
   return;
 } else {
   this.spinner.show();
   let payload = this.purchaseReturnForm.value;
   payload.createdAt= new Date();
   let stockMaintainPayload = {};
   if(this.userType !== 'Super Admin')
   {
     payload.branchRefId = this.branchRef;
     payload.branchName = this.branchName;
   }
   stockMaintainPayload['status'] = "unsold";
   stockMaintainPayload['purchaseReturnDate'] = payload.purchaseReturnDate;
   stockMaintainPayload['comment'] = payload.comment;
    
   let bar = new Promise<void>((resolve,reject) =>{
   this.tempData.forEach(element =>{
       this.apimicroservice.updateStockMaintain(element._id,stockMaintainPayload).subscribe(res => {
         this.apimicroservice.openSnackbar("Returned Data Successfully");
         resolve();
       });
});
});
bar.then(() => {
this.ngOnInit();
});
   
 }
  }
  dayChange()
  {
    let payload = this.purchaseReturnForm.value;
    //);
     this.returnDate = new Date();
     this.returnDate.setDate(this.returnDate.getDate() + parseInt(payload.noOfDays));
   // this.returnDate.setDate(this.returnDate.getDate() + (payload.noOfDays*60*60*1000));
    //
    this.purchaseReturnForm.get("date").setValue(this.returnDate);
    
  }

  openPatientPopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpPatientComponent,
      {
        width: "1200px",
      });
      dialogRef3.afterClosed().subscribe((result) => {
        let clinicRef= sessionStorage.getItem('clinicRef');
        let branchRef= sessionStorage.getItem('branchRef');
        let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
        let branchRefSuperAdmin= '';
        if (this.userType !== 'Super Admin') {
        this.apimicroservice.getPatientListAll(branchRef,clinicRef).subscribe(res => {
          if (res.status) {
            // //
            this.patientlist = res.data.data;
            this.filteredPatientList= this.patientlist.slice();
            this.selectPatient(this.patientlist[0]._id);
            //this.purchaseReturnForm.get("patientName").setValue(res.data.data[0]);
            //
            this.purchaseReturnForm.get("patientName").setValue(this.patientlist[0].firstName + " " +this.patientlist[0].lastName);
          }
        });
      }else{
        this.apimicroservice.getPatientListAll(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
          if (res.status) {
            // //
            this.patientlist = res.data.data;
            this.filteredPatientList= this.patientlist.slice();
            this.selectPatient(this.patientlist[0]._id);
            //this.purchaseReturnForm.get("patientName").setValue(res.data.data[0]);
            //
            this.purchaseReturnForm.get("patientName").setValue(this.patientlist[0].firstName + " " +this.patientlist[0].lastName);
          }
        });
      }
         //  this.onSelectPatient(this.patientList[0]._id);
        //  this.custompurchaseReturnForm.get("patientRefId").setValue(this.patientList[0]._id);
       });
  }  
  openBranchPopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpBranchComponent,
      {
        width: "1200px",
      });
      dialogRef3.afterClosed().subscribe((result) => {
        let clinicRef= sessionStorage.getItem('clinicRef');
        this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
          //  
          if (res.status) {    
            this.branchList = res.data;
            this.filteredListBranch= this.branchList.slice();
            this.branchClick(res.data[0]._id,res.data[0].branchName);
            this.purchaseReturnForm.get("branchRefId").setValue(res.data[0]._id);
          } else {
           // this.apimicroservice.openSnackbar(res.message);
          }
        })
        //this.fetchBranchList();
      })
  }

  openDevicePopUp(){
    //localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpDeviceComponent,
      {
        width: "1200px",
      });
      dialogRef3.afterClosed().subscribe((result) => {
       
        this.apimicroservice.getAllDevice().subscribe(res => {
          if (res.status) {
            this.deviceList = res.data.data;
            this.filteredDeviceList= this.deviceList.slice();
            this.selectDevice(this.deviceList[0]._id,this.deviceList[0].model);
            this.purchaseReturnForm.get("deviceRefId").setValue(this.deviceList[0]._id);
            this.purchaseReturnForm.get("deviceName").setValue(this.deviceList[0].model);
          }
        });
        
       });
  }
  fetchBranchList() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      //  
      if (res.status) {    
        this.branchList = res.data;
        this.filteredListBranch= this.branchList.slice();
      } else {
       // this.apimicroservice.openSnackbar(res.message);
      }
    })
  } 
  branchClick(id,branchName)
  {
    let branchRef= sessionStorage.getItem('branchRef');
    this.purchaseReturnForm.controls["branchName"].setValue(branchName);
    this.spinner.show();
    this.apimicroservice.getStockMaintainByBranchId(id,branchRef).subscribe(res => {
      this.spinner.hide();
      //
      const ids = res.data.map(o => o.deviceRefId);
      let temparr = [];
      let allDevices = []; 
      temparr = res.data;
      allDevices = temparr.filter(({deviceRefId},index) => !ids.includes(deviceRefId, index + 1))
      // this.deviceList[i] = allDevices;
      // //
      this.deviceList = allDevices;
      this.filteredDeviceList= this.deviceList.slice();
     if(res.status){
       //this.apimicroservice.openSnackbar(res.message);
       
     }else{
       //this.apimicroservice.openSnackbar(res.message);
     }

   });
      if(this.updateButton == false){
        
        let status = "return";
        let branchRef= sessionStorage.getItem('branchRef');
    this.apimicroservice.getLastReurnID(branchRef,status).subscribe(res => {
      //   
     
      if (res['status']) {
        
        let clientIdd = res.data.length;
        //
        this.purchaseReturnForm.controls['returnId'].setValue(clientIdd + 1);
      } else {
        this.apimicroservice.openSnackbar("Something went wrong");
      }
      
    })
        
      }
  }
  tempHagivenClick()
  {
    let payload = this.purchaseReturnForm.value;
    if(payload.tempHagiven == 'Yes')
    {
      this.isTempGiven =true;
    }
    else{
      this.isTempGiven =false;
    }
    
  }
  
  printThisPage(_callback) {
  
   
    //   return new Promise<void>((resolve, reject) => {
    //     //
    //     resolve();
    // });
    
    //
    this.ifPrint='true';
    _callback(); 
      
  }
 
//    f1() {
//     this.ifPrint='true';
//     //
// }

//  f2() {
//    //
//    const printContents = document.getElementById('permit').innerHTML;
// const originalContents = document.body.innerHTML;
// document.body.innerHTML = printContents;
//    window.print();
// }
// async global() {
//   await this.f1();
//   this.f2();
// }

global() {
  this.f1().then(res => {
      this.f2()
  })
}

f1() {
  return new Promise<any>((resolve, reject) => {

         this.ifPrint='true';
    //
let x =1;
      setTimeout( () => {
          resolve(x);
      }, 100);
      
  });
  
}

f2() {
  this.ifPrint='false';
  //const printContents = document.getElementById('permit').innerHTML;
//const originalContents = document.body.innerHTML;
//document.body.innerHTML = printContents;
 // window.print();
 let currentDate =new Date();
let date=currentDate.toLocaleDateString('en-GB');
 let titleName='Hearing Aid Trial Form_'+date
 var tempTitle = document.title;
 document.title = titleName;
 window.print();
 document.title = tempTitle;
} 
WhatsApp()
  {
    let payload = this.purchaseReturnForm.value;  
    let num = payload.phoneNo; 
    if(num.length == 10)
    {
      sessionStorage.setItem('whatsAppNumber',payload.phoneNo);
      let mobileNumber = sessionStorage.getItem('whatsAppNumber');
      window.open("https://wa.me/91"+mobileNumber);
    }
    else
    {
      this.purchaseReturnForm.get("phoneNo").markAsTouched();
    }
  }
  onback() {
    this.apimicroservice.back()
  }
  ageCalculate()
{
  let payload = this.purchaseReturnForm.value;
    // To set two dates to two variables 
    var date1 = new Date(); 
	var date2 = new Date(payload.tillDate); 
  
    var Time = date2.getTime() - date1.getTime(); 
    var Days = Math.ceil(Time / (1000 * 60 * 60 * 24)); //Diference in Days
//
this.purchaseReturnForm.controls["noOfDays"].setValue(Days);
}

openPatientSearchPopUp() {
  const dialogRef3 = this.dialog.open(PatientSearchPopComponent, {
    width: "97%",
    height: "90%", 
  });

  dialogRef3.afterClosed().subscribe((result) => {
    this.purchaseReturnForm.get("patientName").setValue(result.data.firstName + " "+ result.data.lastName);
    //this.getAppPatient();
    this.selectPatient(
      result.data._id
    );
  });
} 
printForm() {
  this.router.navigate(['ha-form-print',this.pid]);
 }
 redirect(to): void {
  this.router.navigate([to]);
}
}