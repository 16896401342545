<div class="dialog-wrapper">
    <!-- <h3 mat-dialog-title>Change Password </h3> -->
    <mat-dialog-content>
      <div class="container">
        <form [formGroup]="popupHARightSerialForm" autocomplete="off" novalidate>
        <div class="row">
          <div class="col-sm-12">
            <div class="card-group">
              <div class="card p-4" >
              
                <div class="card-body  ">
                    <div class="col-sm-12">
                        <div class="row">
                            <div class="col-sm-10">
                                <p class="text-muted ">Add Right Serial No :</p>
                                </div>
                                <div class="col-sm-1">
                                  <div class="page-title-right row justify-content-end d-flex align-items-center float-end">
                                    <a (click)="onClose()" matTooltip="Close" class="d-flex align-items-center pr-2"><span class="material-symbols-outlined pr-2">close</span></a>
                                    </div>
                                </div>
                               
                        </div>
                    </div>
                   
                  
                
                  <div class="col-sm-12">
                        <div class="white_box QA_section card_height_100">
                          <div class="table-responsive">
                            <table class="table lms_table_active2 dataTable table-hover table-bordered
                            no-footer dtr-inline" id="DataTables_Table_0"
                              role="grid">
                              <thead>
                                <tr class="text-center">
                                  <th>Serial No.</th>
                                  <th>Add</th>
                                  <th>Delete</th>
                                </tr>
                              </thead>
                              <tbody formArrayName="device">
    
                                <tr [formGroupName]="i"
                                  *ngFor="let row of popupHARightSerialForm.get('device')['controls']; let i = index; let last = last"
                                  role="row" class="odd text-center">
                               
                                  <td>
                                    <mat-form-field class="example-full-width lable_style2 widthh" appearance="outline">
                                      <mat-label class="lable_style2">Right Serial No.</mat-label>
                                      <input required placeholder="Right Serial Number" formControlName="rightSerialNumber" matInput
                                        type="text" />
                                    </mat-form-field>
                                    <p style="color:red;" *ngIf="findDuplicate(row.controls.rightSerialNumber.value,i) && i > 0">
                                      Duplicate Right Serial Number
                                    </p>
                                    <mat-error class="margin_tp"
                                    *ngIf="row.controls.rightSerialNumber.touched && row.controls.rightSerialNumber.errors?.required">
                                       This Field is Mandatorty
                                  </mat-error>
                                  </td>
                                  <td>
                                    
                                      <!-- <button mat-button mat-icon-button *ngIf="last" (click)="addNew();" matTooltip="Add" [disabled]="popupHARightSerialForm.controls.device.invalid">
                                        <mat-icon class="mt-2 iconcol">add_circle_outline</mat-icon>
                                      </button> -->
                                      <button mat-button mat-icon-button *ngIf="last" (click)="addNew();" [disabled]="popupHARightSerialForm.controls.device.invalid">
                                        <!-- <mat-icon class="mt-2 iconcol">add_circle_outline</mat-icon> -->
                                        <img matTooltip="Add New Right Serial No" class="mt-1" width="25" src="../../assets/add.png"> 
                                      </button>
                                  </td>
                                  <td>
                                    <!-- <img class="pl-2 mt-2" (click)="deleteRow(i);" matTooltip="Delete" width="30" src="../../assets/minus-sign.png"> -->
                                    <!-- <button mat-button mat-icon-button (click)="deleteRow(i);" matTooltip="Delete" [disabled]="popupHARightSerialForm.get('device')['controls']['length']===1">
                                      <mat-icon class="mt-2 iconcol">remove_circle_outline</mat-icon>
                                    </button> -->
                                    <button mat-button mat-icon-button (click)="deleteRow(i);" [disabled]="popupHARightSerialForm.get('device')['controls']['length']===1">
                                      <img matTooltip="Remove Serial No" class="mt-1" width="25" src="../../assets/dustbin.png"> 
                                  </button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <hr>
    
                          </div>
                        </div>
                          </div>
                        
                       <div class="row">
                        <div class="col-sm-3">
                            </div>
                        <div class="col-sm-6">
                            <button class="btn btn-sm btn-primary example-full-width"  type="button"
                            (click)="sumbitSerialNo()" [disabled]="disableSubmit">Submit</button>
                        </div>
                        <div class="col-sm-3">
                        </div>
                       </div>
                        
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4"></div>
        </div>
</form>


      </div>
    </mat-dialog-content>
  
  </div>
  