<div class="container-fluid ">

  <div class="row">
    <div class="col-sm-1">

    </div>
    <div class="col-sm-11">
      <div class="row">
        <div class="col-sm-12 pr-4">
          <div class="row">
            <div class="col-sm-11">
              <div class="row ">

                <span>
                  <!-- <img src="../../assets/service.png" width="35rem"> -->

                </span>

                <span>
                  <h1 class="fass ml-3 pt-1">Control panel</h1>
                </span>
              </div>

            </div>
            <div class="col-sm-1 ">
              <div class="row justify-content-end mr-1">
                <a (click)="onback()" class="d-flex align-items-center pr-2 mt-2"><span
                    class="material-symbols-outlined pr-2">arrow_back</span>Back</a>
                <!-- <img matTooltip="Back" class="mb-2 pb-2" width=35rem src="../../assets/back.png" (click)="onback()"> -->
              </div>
            </div>

          </div>

          <div class="row margingTop">
            <div class="card card2 divbody boxsssss borderradius mb-3 pl-4 pr-4 p-1">

              <div class="row p-2 ">
                <div class="col-sm-2">
                  <div class="row divv">
                    <div class="panel-group  mb-4">
                      <div class="panel panel-default ">

                        <div class="ml-3 vl" *ngIf="fromPage == 'controlPanel'">

                          <div class="panel-body   text-center active justify-content-center margin_left mb-4"
                            *ngIf="isPatient" (click)="patientClick();">
                            <div class="row ml-2 text-center justify-content-center">
                              <span class="ml-4">
                                <div class="icon-btns">
                                  <a class="icon-box2" data-toggle="tooltip" data-placement="top" matTooltip="Patient">
                                    <span class="material-symbols-outlined">
                                      person
                                    </span>
                                    <img src="../../assets/icon-bg1.svg" class="icon-bg2">
                                  </a>
                                </div>
                              </span>
                              <h3 class="ml-2 bold cursor mt-2">Patient</h3>
                            </div>
                          </div>

                          <div class="panel-body justify-content-center text-center mb-4" *ngIf="!isPatient"
                            (click)="patientClick();">
                            <div class="row  ml-2 text-center justify-content-center">
                              <span class="ml-4">
                                <div class="icon-btns">
                                  <a class="icon-box2" data-toggle="tooltip" data-placement="top" matTooltip="Patient">
                                    <span class="material-symbols-outlined">
                                      person
                                    </span>
                                    <img src="../../assets/icon-bg1.svg" class="icon-bg2">
                                  </a>
                                </div>
                              </span>
                              <h3 class="ml-2 cursor mb-0  mt-2">Patient</h3>
                            </div>
                          </div>

                          <div class="panel-body  justify-content-center text-center active margin_left mb-4"
                            *ngIf="isCalender" (click)="calenderClick();">
                            <div class="row  ml-3 justify-content-center">
                              <span class="ml-4">
                                <div class="icon-btns">
                                  <a class="icon-box2" data-toggle="tooltip" data-placement="top" matTooltip="Calender">
                                    <span class="material-symbols-outlined">
                                      edit_calendar
                                    </span>
                                    <img src="../../assets/icon-bg2.svg" class="icon-bg2">
                                  </a>
                                </div>
                              </span>
                              <h3 class="ml-2 bold cursor mt-2">Calender</h3>
                            </div>
                          </div>
                          <div class="panel-body justify-content-center text-center mb-4" *ngIf="!isCalender"
                            (click)="calenderClick();">
                            <div class="row   ml-3 justify-content-center">
                              <span class="ml-4">
                                <div class="icon-btns">
                                  <a class="icon-box2" data-toggle="tooltip" data-placement="top" matTooltip="Calender">
                                    <span class="material-symbols-outlined">
                                      edit_calendar
                                    </span>
                                    <img src="../../assets/icon-bg2.svg" class="icon-bg2">
                                  </a>
                                </div>
                              </span>
                              <h3 class="ml-2 cursor mt-2">Calender</h3>
                            </div>
                          </div>
                          <div class="panel-body  justify-content-center text-center active margin_left mb-4"
                            *ngIf="isReport" (click)="reportClick();">
                            <div class="row ml-1  justify-content-center">
                              <span class="ml-3">
                                <div class="icon-btns">
                                  <a class="icon-box2" data-toggle="tooltip" data-placement="top" matTooltip="Report">
                                    <span class="material-symbols-outlined">
                                      file_copy
                                    </span>
                                    <img src="../../assets/icon-bg5.svg" class="icon-bg2">
                                  </a>
                                </div>

                                <!-- <img src="../../assets/report2.png" width="25rem"> -->
                              </span>
                              <h3 class="ml-2 bold cursor mt-2">Report</h3>
                            </div>
                          </div>
                          <div class="panel-body justify-content-center text-center mb-4" *ngIf="!isReport"
                            (click)="reportClick();">
                            <div class="row  ml-1 justify-content-center">
                              <span class="ml-3">
                                <div class="icon-btns">
                                  <a class="icon-box2" data-toggle="tooltip" data-placement="top" matTooltip="Report">
                                    <span class="material-symbols-outlined">
                                      file_copy
                                    </span>
                                    <img src="../../assets/icon-bg5.svg" class="icon-bg2">
                                  </a>
                                </div>
                              </span>
                              <h3 class="ml-2 cursor mt-2">Report</h3>
                            </div>
                          </div>
                          <div class="panel-body  justify-content-center text-center active margin_left mb-4"
                          *ngIf="isRepair" (click)="repairClick();">
                          <div class="row ml-1  justify-content-center">
                            <span class="ml-3">
                              <div class="icon-btns">
                                <a class="icon-box2" data-toggle="tooltip" data-placement="top" matTooltip="Repair">
                                  <span class="material-symbols-outlined">
                                    handyman
                                  </span>
                                  <img src="../../assets/icon-bg6.svg" class="icon-bg2">
                                </a>
                              </div>

                            </span>
                            <h3 class="ml-2 bold cursor mt-2">Repair</h3>
                          </div>
                        </div>
                        <div class="panel-body justify-content-center text-center mb-4" *ngIf="!isRepair"
                          (click)="repairClick();">
                          <div class="row  ml-1 justify-content-center">
                            <span class="ml-3">
                              <div class="icon-btns">
                                <a class="icon-box2" data-toggle="tooltip" data-placement="top" matTooltip="Repair">
                                  <span class="material-symbols-outlined">
                                    handyman
                                  </span>
                                  <img src="../../assets/icon-bg6.svg" class="icon-bg2">
                                </a>
                              </div>
                            </span>
                            <h3 class="ml-2 cursor mt-2">Repair</h3>
                          </div>
                        </div>

                          <!-- <div class="panel-body justify-content-center text-center active margin_left mb-4" *ngIf="isRepair" (click)="repairClick();">
    <div class="row ml-1  justify-content-center">
    <span  class="ml-3">
      <div class="icon-btns">
        <a class="icon-box2" data-toggle="tooltip" data-placement="top" 
matTooltip="Repair" >
<span class="material-symbols-outlined">
  handyman
</span>
<img src="../../assets/icon-bg6.svg" class="icon-bg2">
</a>
        </div>
     </span>
     <h3 class="ml-2 bold cursor mt-2">Repair</h3> 
    </div>
  </div> 
                          <div class="panel-body justify-content-center text-center mb-4" *ngIf="!isRepair" (click)="repairClick();">
    <div class="row  ml-1 justify-content-center">
    <span class="ml-3"><div class="icon-btns">
      <a class="icon-box2" data-toggle="tooltip" data-placement="top" 
matTooltip="Repair" >
<span class="material-symbols-outlined">
  handyman
</span>
  <img src="../../assets/icon-bg6.svg" class="icon-bg2">
</a>
      </div> </span> 
    <h3 class="ml-2 cursor mt-2">Repair</h3>  
  </div>
</div> -->



                        </div>
                        <div class="ml-3 vl2" *ngIf="fromPage == 'calender'">

                          <div class="panel-body  justify-content-center text-center active margin_left "
                            *ngIf="isCalender" (click)="calenderClick();">
                            <div class="row  ml-3 justify-content-center">
                              <span class="ml-4">
                                <!-- <img src="../../assets/calendar (1).png" width="25rem"> -->
                                <div class="icon-btns pb-4">
                                  <a class="icon-box2" data-toggle="tooltip" data-placement="top" matTooltip="Calender">
                                    <span class="material-symbols-outlined">
                                      edit_calendar
                                    </span>
                                    <img src="../../assets/icon-bg2.svg" class="icon-bg2">
                                  </a>
                                </div>
                              </span>
                              <h3 class="ml-2 bold cursor">Calender</h3>
                            </div>
                          </div>
                          <div class="panel-body justify-content-center text-center" *ngIf="!isCalender"
                            (click)="calenderClick();">
                            <div class="row   ml-3 justify-content-center">
                              <span class="ml-4">
                                <div class="icon-btns pb-4">
                                  <a class="icon-box2" data-toggle="tooltip" data-placement="top" matTooltip="Calender">
                                    <span class="material-symbols-outlined">
                                      edit_calendar
                                    </span>
                                    <img src="../../assets/icon-bg2.svg" class="icon-bg2">
                                  </a>
                                </div>
                              </span>
                              <h3 class="ml-2 cursor">Calender</h3>
                            </div>
                          </div>
                        </div>

                        <div class="ml-3 vl3" *ngIf="fromPage == 'patient'">

                          <div class="panel-body   text-center active justify-content-center margin_left mb-4"
                            *ngIf="isPatient" (click)="patientClick();">
                            <div class="row ml-2 text-center justify-content-center">
                              <span class="ml-4">
                                <div class="icon-btns pb-4">
                                  <a class="icon-box2" data-toggle="tooltip" data-placement="top" matTooltip="Patient">
                                    <span class="material-symbols-outlined">
                                      person
                                    </span>
                                    <img src="../../assets/icon-bg1.svg" class="icon-bg2">
                                  </a>
                                </div>
                              </span>
                              <h3 class="ml-2 bold cursor">Patient</h3>
                            </div>
                          </div>

                          <div class="panel-body justify-content-center text-center mb-4" *ngIf="!isPatient"
                            (click)="patientClick();">
                            <div class="row  ml-2 text-center justify-content-center">
                              <span class="ml-4">
                                <div class="icon-btns pb-4">
                                  <a class="icon-box2" data-toggle="tooltip" data-placement="top" matTooltip="Patient">
                                    <span class="material-symbols-outlined">
                                      person
                                    </span>
                                    <img src="../../assets/icon-bg1.svg" class="icon-bg2">
                                  </a>
                                </div>
                              </span>
                              <h3 class="ml-2 cursor">Patient</h3>
                            </div>
                          </div>
                        </div>


                      </div>


                    </div>


                  </div>
                </div>
                <!-- border -->
                <div class="col-sm-10 ">
                  <div class="panel-body">
                    <form autocomplete="off" [formGroup]="patientSettingForm" novalidate>
                      <div *ngIf="isPatient" class="col-sm-12">
                        <div class="col-sm-12">
                          <div class="row mt-1">
                            <div class="col-sm-6">
                              <div class="row">


                                <span>
                                  <div class="icon-btns pb-4">
                                    <a class="icon-box2" data-toggle="tooltip" data-placement="top"
                                      matTooltip="Patient">
                                      <span class="material-symbols-outlined">
                                        person
                                      </span>
                                      <img src="../../assets/icon-bg1.svg" class="icon-bg2">
                                    </a>
                                  </div>
                                </span>
                                <span>
                                  <h1 class="fass ml-3 pt-1" id="toPatientForm">Patient</h1>
                                </span>
                              </div>
                            </div>
                            <div class="col-sm-3"></div>
                            <div class="col-sm-3">
                              <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Clinic Name
                                  <span class="required-asterisk">*</span>
                                </mat-label>


                                <mat-select #select1 placeholder="Clinic Name" class="colorblack"
                                  formControlName="clinicRefId">
                                  <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'"
                                    [displayMember]="'clinicName'" [array]="clinicList"
                                    (filteredReturn)="filteredListClinic =$event"></mat-select-filter>
                                  <mat-option *ngFor="let item of filteredListClinic" [value]="item._id"
                                    (click)="onSelectClinic(item._id)">
                                    {{item.clinicName}}
                                  </mat-option>
                                </mat-select>


                              </mat-form-field>
                            </div>

                          </div>
                        </div>
                        <table class="table table-hover table-striped table-bordered" style="text-align:center">
                          <thead>

                            <tr>

                              <th>Input Fields</th>
                              <th>Display</th>



                            </tr>
                          </thead>
                          <tbody>
                            <!--                              
                              <tr >
                                <td class="align-middle">Middle Name</td>
                               
                                <td class="align-middle">
                                  <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>Display
                                      
                                    </mat-label>
                                    <mat-select formControlName="patientMiddleName">
                                      <mat-option value="Yes"> Yes</mat-option>
                                      <mat-option value="No">No</mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                </td>
                                
                              </tr>  -->


                            <tr>
                              <td class="align-middle">Alternate Name</td>
                              <td class="align-middle">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Display

                                  </mat-label>
                                  <mat-select formControlName="patientAlternateName">
                                    <mat-option value="Yes"> Yes</mat-option>
                                    <mat-option value="No">No</mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </td>

                            </tr>


                            <tr>
                              <td class="align-middle">Patient Source</td>
                              <td class="align-middle">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Display

                                  </mat-label>
                                  <mat-select formControlName="patientClientSource">
                                    <mat-option value="Yes"> Yes</mat-option>
                                    <mat-option value="No">No</mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </td>

                            </tr>
                            <tr>
                              <td class="align-middle">Landline 1</td>
                              <td class="align-middle">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Display

                                  </mat-label>
                                  <mat-select formControlName="patientLandline1">
                                    <mat-option value="Yes"> Yes</mat-option>
                                    <mat-option value="No">No</mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </td>

                            </tr>
                            <tr>
                              <td class="align-middle">Landline 2</td>
                              <td class="align-middle">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Display

                                  </mat-label>
                                  <mat-select formControlName="patientLandline2">
                                    <mat-option value="Yes"> Yes</mat-option>
                                    <mat-option value="No">No</mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </td>

                            </tr>
                            <tr>
                              <td class="align-middle">Mobile 1</td>
                              <td class="align-middle">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Display

                                  </mat-label>
                                  <mat-select formControlName="patientMobile1">
                                    <mat-option value="Yes"> Yes</mat-option>
                                    <mat-option value="No">No</mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </td>

                            </tr>
                            <tr>
                              <td class="align-middle">Mobile 2</td>
                              <td class="align-middle">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Display

                                  </mat-label>
                                  <mat-select formControlName="patientMobile2">
                                    <mat-option value="Yes"> Yes</mat-option>
                                    <mat-option value="No">No</mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </td>

                            </tr>
                            <tr>
                              <td class="align-middle">Mobile 3</td>
                              <td class="align-middle">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Display

                                  </mat-label>
                                  <mat-select formControlName="patientMobile3">
                                    <mat-option value="Yes"> Yes</mat-option>
                                    <mat-option value="No">No</mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </td>

                            </tr>
                            <tr>
                              <td class="align-middle">Mobile 4</td>
                              <td class="align-middle">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Display

                                  </mat-label>
                                  <mat-select formControlName="patientMobile4">
                                    <mat-option value="Yes"> Yes</mat-option>
                                    <mat-option value="No">No</mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </td>

                            </tr>

                            <tr>
                              <td class="align-middle">Mobile 5</td>
                              <td class="align-middle">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Display

                                  </mat-label>
                                  <mat-select formControlName="patientMobile5">
                                    <mat-option value="Yes"> Yes</mat-option>
                                    <mat-option value="No">No</mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </td>

                            </tr>

                            <tr>
                              <td class="align-middle">Email Address</td>
                              <td class="align-middle">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Display

                                  </mat-label>
                                  <mat-select formControlName="patientEmail">
                                    <mat-option value="Yes"> Yes</mat-option>
                                    <mat-option value="No">No</mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </td>

                            </tr>

                            <tr>
                              <td class="align-middle">Street Address</td>
                              <td class="align-middle">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Display

                                  </mat-label>
                                  <mat-select formControlName="patientStreetAddress">
                                    <mat-option value="Yes"> Yes</mat-option>
                                    <mat-option value="No">No</mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </td>

                            </tr>
                            <tr>
                              <td class="align-middle">Pincode</td>
                              <td class="align-middle">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Display

                                  </mat-label>
                                  <mat-select formControlName="patientPincode">
                                    <mat-option value="Yes"> Yes</mat-option>
                                    <mat-option value="No">No</mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </td>

                            </tr>

                            <tr>
                              <td class="align-middle">Taluka</td>
                              <td class="align-middle">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Display

                                  </mat-label>
                                  <mat-select formControlName="patientTaluka">
                                    <mat-option value="Yes"> Yes</mat-option>
                                    <mat-option value="No">No</mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </td>

                            </tr>
                            <tr>
                              <td class="align-middle">District</td>
                              <td class="align-middle">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Display

                                  </mat-label>
                                  <mat-select formControlName="patientDistrict">
                                    <mat-option value="Yes"> Yes</mat-option>
                                    <mat-option value="No">No</mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </td>

                            </tr>
                            <tr>
                              <td class="align-middle">State</td>
                              <td class="align-middle">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Display

                                  </mat-label>
                                  <mat-select formControlName="patientState">
                                    <mat-option value="Yes"> Yes</mat-option>
                                    <mat-option value="No">No</mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </td>

                            </tr>
                            <tr>
                              <td class="align-middle">Country</td>
                              <td class="align-middle">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Display

                                  </mat-label>
                                  <mat-select formControlName="patientCountry">
                                    <mat-option value="Yes"> Yes</mat-option>
                                    <mat-option value="No">No</mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </td>

                            </tr>


                            <tr>
                              <td class="align-middle">Alternate Address</td>
                              <td class="align-middle">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Display

                                  </mat-label>
                                  <mat-select formControlName="patientAltAddress">
                                    <mat-option value="Yes"> Yes</mat-option>
                                    <mat-option value="No">No</mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </td>

                            </tr>



                            <tr>
                              <td class="align-middle">Occupation</td>
                              <td class="align-middle">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Display

                                  </mat-label>
                                  <mat-select formControlName="patientOccupation">
                                    <mat-option value="Yes"> Yes</mat-option>
                                    <mat-option value="No">No</mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </td>

                            </tr>
                            <tr>
                              <td class="align-middle">Aadhaar Card Number</td>
                              <td class="align-middle">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Display

                                  </mat-label>
                                  <mat-select formControlName="patientAadhaarNo">
                                    <mat-option value="Yes"> Yes</mat-option>
                                    <mat-option value="No">No</mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </td>

                            </tr>

                            <tr>
                              <td class="align-middle">Note</td>
                              <td class="align-middle">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Display

                                  </mat-label>
                                  <mat-select formControlName="patientNotes">
                                    <mat-option value="Yes"> Yes</mat-option>
                                    <mat-option value="No">No</mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </td>

                            </tr>

                            <br>


                          </tbody>
                        </table>
                        <div class="row">
                          <div *ngIf="patientSubmitButton && genericSettingCreate" class="col-sm-10"></div>
                          <div *ngIf="patientUpdateButton && genericSettingUpdate" class="col-sm-6"></div>
                          <div class="col-sm-2">
                            <button *ngIf="patientSubmitButton && genericSettingCreate"
                              class="btn btn-sm btn-primary example-full-width " (click)="submitPatient()"
                              type="button">Save </button>
                          </div>
                          <div class="col-sm-2">
                            <button *ngIf="patientUpdateButton && genericSettingUpdate"
                              class="btn btn-sm btn-primary example-full-width  " (click)="updatePatient()"
                              type="button">Update </button>
                          </div>
                          <div class="col-sm-2">
                            <button *ngIf="patientUpdateButton && genericSettingUpdate"
                              class="btn btn-sm btn-primary example-full-width " (click)="cancelUpdatePatient()"
                              type="reset">Cancel</button>
                          </div>
                        </div>
                      </div>



                    </form>

                    <form autocomplete="off" [formGroup]="calenderSettingForm" novalidate>
                      <div *ngIf="isCalender" class="col-sm-12">
                        <div class="col-sm-12">
                          <div class="row mt-1">
                            <span>
                              <div class="icon-btns pb-4">
                                <a class="icon-box2" data-toggle="tooltip" data-placement="top" matTooltip="Calender">
                                  <span class="material-symbols-outlined">
                                    edit_calendar
                                  </span>
                                  <img src="../../assets/icon-bg2.svg" class="icon-bg2">
                                </a>
                              </div>
                            </span>
                            <span>
                              <h1 class="fass ml-3 pt-1" id="toCalenderForm">Calender</h1>
                            </span>
                          </div>
                        </div>
                        <table class="table table-hover table-striped table-bordered" style="text-align:center">
                          <thead>

                            <tr>

                              <th>Input Fields</th>
                              <th>Display</th>



                            </tr>
                          </thead>
                          <tbody>

                            <tr>
                              <td class="align-middle">Duration</td>

                              <td class="align-middle">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Duration</mat-label>
                                  <mat-select formControlName="duration">
                                    <mat-option *ngFor="let row of durationList" (click)="onSelectduration(row)"
                                      [value]="row.value">{{row.duration}}</mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </td>

                            </tr>




                            <tr>
                              <td class="align-middle">Default Calendar View</td>
                              <td class="align-middle">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Default Calendar View

                                  </mat-label>
                                  <mat-select formControlName="defaultView">
                                    <mat-option value="dayGridMonth"> Month</mat-option>
                                    <mat-option value="timeGridWeek">Week</mat-option>
                                    <mat-option value="timeGridDay">Day</mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </td>

                            </tr>
                            <br>

                          </tbody>
                        </table>
                        <div class="row">
                          <div *ngIf="calenderSubmitButton && genericSettingCreate" class="col-sm-10"></div>
                          <div *ngIf="calenderUpdateButton && genericSettingUpdate" class="col-sm-6"></div>
                          <div class="col-sm-2">
                            <button *ngIf="calenderSubmitButton && genericSettingCreate"
                              class="btn btn-sm btn-primary example-full-width" (click)="submitCalender()">Save
                            </button>
                          </div>
                          <div class="col-sm-2">
                            <button *ngIf="calenderUpdateButton && genericSettingUpdate"
                              class="btn btn-sm btn-primary example-full-width " (click)="updateCalender()">Update
                            </button>
                          </div>
                          <div class="col-sm-2">
                            <button *ngIf="calenderUpdateButton && genericSettingUpdate"
                              class="btn btn-sm btn-primary example-full-width "
                              (click)="cancelUpdateGenric()">Cancel</button>
                          </div>
                        </div>
                      </div>
                    </form>

                    <div *ngIf="isReport" class="col-sm-12">
                      <form autocomplete="off" [formGroup]="reportSettingForm" *ngIf="header" novalidate>
                        <div class="row mt-1">
                          <div class="col-sm-3" *ngIf="!branchSelect">
                            <div class="row mt-1">
                              <span>
                                <div class="icon-btns pb-4">
                                  <a class="icon-box2" data-toggle="tooltip" data-placement="top" matTooltip="Report">
                                    <span class="material-symbols-outlined">
                                      file_copy
                                    </span>
                                    <img src="../../assets/icon-bg5.svg" class="icon-bg2">
                                  </a>
                                </div>
                              </span>
                              <span>
                                <h1 class="fass ml-3 pt-1" id="toCalenderForm">Report</h1>
                              </span>
                            </div>
                          </div>
                          <div class="col-sm-3"></div>



                          <div class="col-sm-5" *ngIf="branchSelect">
                            <div class="row mt-1">
                              <span>
                                <div class="icon-btns pb-4">
                                  <a class="icon-box2" data-toggle="tooltip" data-placement="top" matTooltip="Report">
                                    <span class="material-symbols-outlined">
                                      file_copy
                                    </span>
                                    <img src="../../assets/icon-bg5.svg" class="icon-bg2">
                                  </a>
                                </div>
                              </span>
                              <span>
                                <h1 class="fass ml-3 pt-1" id="toCalenderForm">Report</h1>
                              </span>
                            </div>
                          </div>
                          <div class="col-sm-2" *ngIf="!branchSelect"></div>
                          <div class="col-sm-1 mt-2">
                            <!-- <div class="row justify-content-end mr-1">
                                          <img src="../../assets/clinic.png" class="mr-2" (click)="copyFromClinic()" matTooltip="Copy From Clinic" width="35rem">
                                        </div> -->
                            <div class="row justify-content-end ">
                              <img src="../../assets/clinic.png" class="mr-2" (click)="copyFromClinic()"
                                matTooltip="Copy From Clinic" width="30rem">
                              <img src="../../assets/branch.png" (click)="copyFromBranch()"
                                matTooltip="Copy From Branch" width="30rem">
                            </div>
                          </div>

                          <!-- <div class="col-sm-3 " *ngIf="branchCreate">
                                        <div class="row">
                                          <div class="col-sm-10 pr-1"> 
                                      <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>Branch 
                                          
                                        </mat-label> 
                                        <mat-select #select1 formControlName="branchRefId">
                                          <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'branchName'"
                                          [array]="BranchList"  (filteredReturn)="filteredListBranch =$event"></mat-select-filter>
                                         
                                          <mat-option *ngFor="let item of filteredListBranch" [value]="item._id" 
                                          (click)="branchClick(item._id,item.branchName);"
                                          >
                                           {{ item.branchName }}</mat-option>
                                          </mat-select>
                                          
                                      </mat-form-field> 
                                      
                                     </div>
                                     <div class="col-sm-2 mt-3">
                                      <img (click)="openBranchPopUp();" matTooltip="Add New Branch" width="25" src="../../assets/add_img.png"> 
                                    </div>
            
                                     </div>
            
                                    </div> -->

                          <div class="col-sm-3">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Select
                                <span class="required-asterisk">*</span>
                              </mat-label>


                              <mat-select placeholder="select" class="colorblack" formControlName="selectType">

                                <mat-option (click)="selectType('Header')" value="Header">Header</mat-option>
                                <mat-option (click)="selectType('Footer')" value="Footer">Footer</mat-option>
                              </mat-select>

                            </mat-form-field>
                          </div>
                          <div class="col-sm-3 " *ngIf="branchSelect == true">
                            <div class="row">
                              <div class="col-sm-10 pr-1">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Branch
                                    <!-- <span class="required-asterisk">*</span> -->
                                  </mat-label>
                                  <mat-select #select1 formControlName="branchRefId">
                                    <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'"
                                      [displayMember]="'branchName'" [array]="BranchList"
                                      (filteredReturn)="filteredListBranch =$event"></mat-select-filter>

                                    <mat-option *ngFor="let item of filteredListBranch" [value]="item._id"
                                      (click)="onBranchClick(item._id,item.branchName);">
                                      {{ item.branchName }}</mat-option>
                                  </mat-select>

                                </mat-form-field>
                                <!-- <mat-error 
                                          *ngIf="reportSettingForm.controls['branchRefId'].touched && reportSettingForm.controls['branchRefId'].errors?.required">
                                          This field is mandatory.
                                      </mat-error> -->
                              </div>
                              <div class="col-sm-2 mt-2 no-print">
                                <img (click)="openBranchPopUp();" matTooltip="Add New Branch" width="25"
                                  src="../../assets/add_img.png">
                              </div>
                            </div>

                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-2">
                            <!-- <h1 class="titlecolor"> Patient Master</h1> -->
                            <div class="col-sm-12 bg-secondary rounded-3 p-1">


                              <div class="col-sm-12 ">
                                <img *ngIf="!logoIcon" class=" img2 frame  " src="../../assets/user2.png"
                                  alt="Amanda Wilson" width="500rem"><!--rounded-circle-->
                                <img *ngIf="logoIcon" class=" img2 frame  " [src]="logoIcon" alt="patient photo"
                                  width="500rem"><!--rounded-circle-->
                                <!-- <button class="btn btn-light shadow btn-sm mb-2" type="button"><i class="ai-refresh-cw me-2"></i>Change avatar</button> -->

                              </div>
                              <div class="col-sm-12 btnpossition">
                                <!-- <h1 class="fass p-0 m-0"> Patient Master</h1> -->
                                <input #fileUpload type="file" style="display: none;" (change)="onChange($event)" />
                                <button class="btn btn-light shadow btn-sm m-0 example-full-width"
                                  matTooltip="Upload JPG, GIF or PNG image.300 x 300 required."
                                  (click)="fileUpload.click()"><i class="ai-refresh-cw me-2"></i>Upload Photo</button>
                                <!-- <a [routerLink]="" class="p-0 m-0">Upload Photo</a>   -->

                              </div>

                            </div>
                          </div>
                          <div class="col-sm-10">
                            <div class="row">

                              <div class="col-sm-12">

                                <div class="row">
                                  <div class="col-sm-12 pb-0 mb-0">
                                    <h5 class="pb-0 mb-0 mt-2">Billing Company Details :</h5>

                                  </div>
                                  <div class="col-sm-3 pb-0 mb-0">
                                    <div class="row">
                                      <div class="col-sm-10 pr-1">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>Billing Company
                                          </mat-label> 
                                          <mat-select #select1 required formControlName="billingRefId">
                                            <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'"
                                              [displayMember]="'companyName'" [array]="billingCompanyList"
                                              (filteredReturn)="filteredListBillingCompany =$event"></mat-select-filter>
                                            <mat-option *ngFor="let item of filteredListBillingCompany"
                                              [value]="item._id" (click)="companyClick(item._id,item.companyName);">
                                              {{ item.companyName }}</mat-option>
                                          </mat-select>

                                        </mat-form-field>
                                        <mat-error class="margin_tp"
                                          *ngIf="reportSettingForm.controls['billingRefId'].touched && reportSettingForm.controls['billingRefId'].errors?.required">
                                          This field is mandatory.
                                        </mat-error>
                                      </div>
                                      <div class="col-sm-2 mt-1 no-print">
                                        <i (click)="openBillingCompPopUp();" matTooltip="Add Billing Company"
                                          class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-sm-9 pb-0 mb-0">
                                  </div>
                                  <div class="col-sm-12 pb-0 mb-0">
                                    <h5 class="pb-0 mb-0 mt-2"> Details :</h5>

                                  </div>

                                </div>



                                <div class="row">

                                  <div class=" col-sm-3 ">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                      <mat-label> Name
                                        <!-- <span class="required-asterisk">*</span>   -->
                                      </mat-label>
                                      <input required formControlName="reportName" matInput placeholder=" Name" />


                                    </mat-form-field>
                                    <mat-error
                                      *ngIf="reportSettingForm.controls['reportName'].touched && reportSettingForm.controls['reportName'].errors?.pattern">
                                      Please enter valid Name</mat-error>

                                    <mat-error
                                      *ngIf="reportSettingForm.controls['reportName'].touched && reportSettingForm.controls['reportName'].errors?.required">
                                      This field is mandatory.</mat-error>
                                  </div>
                                  <div class=" col-sm-3  ">

                                    <mat-form-field class="example-full-width" appearance="outline">
                                      <mat-label>Open Time</mat-label>
                                      <!-- <input formControlName="openTime" (change)="toOpenTimeChange()" matInput id="appt-time" type="time" name="appt-time"
                                                step="2"> -->
                                      <input required matInput placeholder="08:00 am" formControlName="openTime"
                                        [ngxTimepicker]="pickerF" value="" [min]="'07:00 am'" [max]="'09:00 pm'"
                                        [disableClick]="true" readonly />
                                      <ngx-material-timepicker-toggle matSuffix
                                        [for]="pickerF"></ngx-material-timepicker-toggle>
                                      <ngx-material-timepicker (closed)="toOpenTimeChange()"
                                        #pickerF></ngx-material-timepicker>
                                    </mat-form-field>

                                  </div>
                                  <div class=" col-sm-3  ">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                      <mat-label>Close Time</mat-label>
                                      <!-- <input id="appt-time2" type="time" (change)="toCloseTimeChange()"
                                               name="appt-time2" step="2" formControlName="closeTime"
                                                matInput> -->
                                      <input required matInput placeholder="08:00 pm" formControlName="closeTime"
                                        [ngxTimepicker]="pickerA" value="" [min]="'08:00 am'" [max]="'09:00 pm'"
                                        [disableClick]="true" readonly />
                                      <ngx-material-timepicker-toggle matSuffix
                                        [for]="pickerA"></ngx-material-timepicker-toggle>
                                      <ngx-material-timepicker (closed)="toCloseTimeChange()"
                                        #pickerA></ngx-material-timepicker>
                                    </mat-form-field>
                                  </div>
                                  <div class=" col-sm-3  ">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                      <mat-label>Weekly Off</mat-label>
                                      <mat-select required #select3 placeholder="Weekly Off" class="colorblack"
                                        formControlName="reportWeeklyOff">
                                        <mat-select-filter *ngIf="select3.focused" [placeholder]="'Filter'"
                                          [displayMember]="'name'" [array]="allDays"
                                          (filteredReturn)="filteredListDays =$event"></mat-select-filter>
                                        <mat-option *ngFor="let item of filteredListDays" [value]="item.value">
                                          {{item.name}}
                                        </mat-option>
                                      </mat-select>

                                    </mat-form-field>
                                    <mat-error
                                      *ngIf="reportSettingForm.controls['reportWeeklyOff'].touched && reportSettingForm.controls['reportWeeklyOff'].errors?.required">
                                      This field is mandatory.</mat-error>
                                  </div>
                                </div>
                                <div class="row">


                                  <div class="col-sm-12  mt-2 ">
                                    <label class="header">Contact Details :</label>
                                  </div>

                                  <div class=" col-sm-3  ">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                      <mat-label> Mobile Number 1</mat-label>
                                      <input required formControlName="reportPhoneNumber" matInput maxlength="10"
                                        placeholder="Phone Number" />
                                    </mat-form-field>
                                    <mat-error
                                      *ngIf="reportSettingForm.controls['reportPhoneNumber'].touched && reportSettingForm.controls['reportPhoneNumber'].errors?.pattern">
                                      Please enter valid Phone Number</mat-error>
                                    <mat-error
                                      *ngIf="reportSettingForm.controls['reportPhoneNumber'].touched && reportSettingForm.controls['reportPhoneNumber'].errors?.required">
                                      This field is mandatory.</mat-error>
                                  </div>
                                  <div class=" col-sm-3  ">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                      <mat-label> Mobile Number 2</mat-label>
                                      <input formControlName="reportPhoneNumber1" matInput maxlength="10"
                                        placeholder="Mobile Number" />
                                    </mat-form-field>
                                    <mat-error
                                      *ngIf="reportSettingForm.controls['reportPhoneNumber1'].touched && reportSettingForm.controls['reportPhoneNumber1'].errors?.pattern">
                                      Please enter valid Phone Number</mat-error>
                                  </div>
                                  <div class=" col-sm-3  ">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                      <mat-label>Landline

                                      </mat-label>
                                      <input formControlName="reportClinicLandline" class="emailClass" matInput
                                        placeholder=" Email ID" />

                                    </mat-form-field>
                                    <!-- <mat-error
                                              *ngIf="reportSettingForm.controls['reportEmail'].touched && reportSettingForm.controls['reportEmail'].errors?.required">
                                              This field is mandatory.</mat-error> -->

                                    <mat-error class="margin_tp"
                                      *ngIf="reportSettingForm.controls['reportClinicLandline'].touched && reportSettingForm.controls['reportClinicLandline'].errors?.pattern">
                                      Please enter valid Landline.
                                    </mat-error>
                                  </div>

                                  <div class=" col-sm-3  ">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                      <mat-label> Email ID

                                      </mat-label>
                                      <input formControlName="reportEmail" class="emailClass" matInput
                                        placeholder=" Email ID" />

                                    </mat-form-field>
                                    <!-- <mat-error
                                              *ngIf="reportSettingForm.controls['reportEmail'].touched && reportSettingForm.controls['reportEmail'].errors?.required">
                                              This field is mandatory.</mat-error> -->

                                    <mat-error class="margin_tp"
                                      *ngIf="reportSettingForm.controls['reportEmail'].touched && reportSettingForm.controls['reportEmail'].errors?.pattern">
                                      Please enter valid email.
                                    </mat-error>
                                  </div>
                                  <div class=" col-sm-3 mt-2">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                      <mat-label>Website
                                        <!-- <span class="required-asterisk">*</span> -->
                                      </mat-label>
                                      <input  formControlName="reportWebsite" class="emailClass"
                                        matInput placeholder="Website" />

                                    </mat-form-field>
                                    <mat-error
                                      *ngIf="reportSettingForm.controls['reportWebsite'].touched && reportSettingForm.controls['reportWebsite'].errors?.required">
                                      This field is mandatory.</mat-error>

                                    <!-- <mat-error class="margin_tp"
                                      *ngIf="reportSettingForm.controls['reportWebsite'].touched && reportSettingForm.controls['reportWebsite'].errors?.pattern">
                                      Please enter valid site.
                                    </mat-error> -->
                                  </div>

                                </div>
                              </div>

                            </div>


                          </div>

                          <div class="col-sm-12">

                            <div class="row">

                              <div class="col-sm-12 pb-0 mb-0">
                                <h5 class="pb-0 mb-0 mt-2">Address Details :</h5>

                              </div>
                            </div> 
                            <div class="row">
                              <div class=" col-sm-3  ">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Street Address

                                  </mat-label>
                                  <input required formControlName="reportAddress" matInput
                                    placeholder="Street Address" />
                                </mat-form-field>
                                <mat-error
                                  *ngIf="reportSettingForm.controls['reportAddress'].touched && reportSettingForm.controls['reportAddress'].errors?.required">
                                  This field is mandatory.</mat-error>
                              </div>
                              <div class=" col-sm-3  ">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Country</mat-label>
                                  <input matInput placeholder="Country" formControlName="country" />

                                </mat-form-field>
                                <mat-error class="margin_tp"
                                  *ngIf="reportSettingForm.controls['country'].touched && reportSettingForm.controls['country'].errors?.pattern">
                                  Please enter valid country.
                                </mat-error>
                              </div>
                              <div class=" col-sm-3  ">
                                
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>State</mat-label>
                                  <mat-select required #select11 placeholder="State" class="colorblack"
                                    formControlName="state">
                                    <mat-select-filter *ngIf="select11.focused" [placeholder]="'Filter'"
                                      [displayMember]="'state'" [array]="stateCityArr2"
                                      (filteredReturn)="filteredList2 =$event"></mat-select-filter>
                                    <mat-option *ngFor="let item of filteredList2" [value]="item.state"
                                      (click)="getDistrict2(item.districts)">
                                      {{item.state}}
                                    </mat-option>
                                  </mat-select>

                                </mat-form-field>
                              </div>

                              <div class=" col-sm-3  ">
                                <!-- <mat-form-field class="example-full-width" appearance="outline">
                                              <mat-label>District</mat-label>
                                              <input matInput placeholder="District" formControlName="district" />
                                             
                                            </mat-form-field> -->
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>District</mat-label>
                                  <mat-select #select12 placeholder="District" class="colorblack"
                                    formControlName="district">
                                    <mat-select-filter *ngIf="select12.focused" [placeholder]="'Filter'"
                                      [displayMember]="'district'" [array]="districtData2"
                                      (filteredReturn)="filteredListDistrict2 =$event"></mat-select-filter>
                                    <mat-option *ngFor="let item of filteredListDistrict2" [value]="item.district">
                                      {{item.district}}
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                                <mat-error class="margin_tp"
                                  *ngIf="reportSettingForm.controls['district'].touched && reportSettingForm.controls['district'].errors?.pattern">
                                  Please enter valid district.
                                </mat-error>
                              </div>

                              <div class=" col-sm-3 mt-2">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Taluka</mat-label>
                                  <input matInput placeholder="Taluka" formControlName="taluka" />

                                </mat-form-field>
                                <mat-error class="margin_tp"
                                  *ngIf="reportSettingForm.controls['taluka'].touched && reportSettingForm.controls['taluka'].errors?.pattern">
                                  Please enter valid taluka.
                                </mat-error>
                              </div>

                              <div class=" col-sm-3  mt-2">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Pin Code

                                  </mat-label>
                                  <input required formControlName="reportPinCode" matInput matInput maxlength="6"
                                    placeholder="Pincode(e.g:416008)" />
                                </mat-form-field>
                                <mat-error
                                  *ngIf="reportSettingForm.controls['reportPinCode'].touched && reportSettingForm.controls['reportPinCode'].errors?.pattern">
                                  Please enter valid PinCode</mat-error>

                                <mat-error
                                  *ngIf="reportSettingForm.controls['reportPinCode'].touched && reportSettingForm.controls['reportPinCode'].errors?.required">
                                  This field is mandatory.</mat-error>
                              </div>

                            </div>
                          
                              <div class="row">
                            
                                  <div class="col-sm-12 pb-0 mb-0">
                                    <h5 class="pb-0 mb-0 mt-2">Bank Details :</h5>
                              
                                </div>
                              </div>
        
        
                              <div class="row">
                                <div class=" col-sm-3 mt-2 ">
                                  <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>Bank Name</mat-label>
                                    <input  formControlName="bankName" 
                                    matInput placeholder="Bank Name" />
                                  </mat-form-field>
                                  <mat-error class="margin_tp"
                      *ngIf="reportSettingForm.controls['bankName'].touched && reportSettingForm.controls['bankName'].errors?.pattern">
                      Please enter valid bank Name.
                    </mat-error>
                                </div>
                                <div class=" col-sm-3 mt-2">
                                  <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>Branch Name</mat-label>
                                    <input  formControlName="bankBranchName" 
                                    matInput placeholder="Branch Name" />
                                  </mat-form-field>
                                  <mat-error class="margin_tp"
                      *ngIf="reportSettingForm.controls['bankBranchName'].touched && reportSettingForm.controls['bankBranchName'].errors?.pattern">
                      Please enter valid Branch Name.
                    </mat-error>
                                </div>
                                <div class=" col-sm-3 mt-2">
                                  <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>Account Number</mat-label>
                                    <input  maxlength="18" formControlName="accountNumber" 
                                    matInput placeholder="Account Number" />
                                  </mat-form-field>
                                  <mat-error class="margin_tp"
                      *ngIf="reportSettingForm.controls['accountNumber'].touched && reportSettingForm.controls['accountNumber'].errors?.pattern">
                      Please enter valid account Number.
                    </mat-error>
                    <mat-error class="margin_tp"
                      *ngIf="reportSettingForm.controls['accountNumber'].touched && reportSettingForm.controls['accountNumber'].errors?.maxlength">
                      Please enter maximum 18 digit account Number.
                    </mat-error>
                  
                                </div>
                                <div class=" col-sm-3 mt-2">
                                  <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>IFSC Code</mat-label>
                                    <input  formControlName="ifcCode" 
                                    matInput maxlength="15" placeholder="IFSC Code" />
                                  </mat-form-field>
                                  <mat-error class="margin_tp"
                      *ngIf="reportSettingForm.controls['ifcCode'].touched && reportSettingForm.controls['ifcCode'].errors?.pattern">
                      Please enter valid IFSC Code .
                    </mat-error>
                                </div>
                                <div class=" col-sm-3 mt-2">
                                  <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>UPI Id</mat-label>
                                    <input class="smallCase" formControlName="upiId" 
                                    matInput placeholder="UPI Id" /> 
                                  </mat-form-field> 
                                  <mat-error class="margin_tp"
                      *ngIf="reportSettingForm.controls['upiId'].touched && reportSettingForm.controls['upiId'].errors?.pattern">
                      Please enter valid UPI Id.
                    </mat-error> 
                                </div>
                              
                              </div>
        
                            <div class="row">
                              <div *ngIf="reportSubmitButton && genericSettingCreate" class="col-sm-10"></div>
                              <div *ngIf="reportUpdateButton && genericSettingUpdate" class="col-sm-6"></div>
                              <div class="col-sm-2">
                                <button *ngIf="reportSubmitButton && genericSettingCreate" type="button"
                                  class="btn btn-sm btn-primary example-full-width" (click)="submitHeader()">Save
                                </button>
                              </div>
                              <div class="col-sm-2">
                                <button *ngIf="reportUpdateButton && genericSettingUpdate" type="button"
                                  class="btn btn-sm btn-primary example-full-width " (click)="updateReportHeader()">Update
                                </button>
                              </div>
                              <div class="col-sm-2">
                                <button *ngIf="reportUpdateButton && genericSettingUpdate"
                                  class="btn btn-sm btn-primary example-full-width "
                                  (click)="cancelUpdateReportHeader()">Cancel</button>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-3 mt-4 ">
                            <h1 class="titlecolor">List Of Header</h1>
                          </div>
                          <div class="col-sm-6 mt-4">
                          </div>
                          <div class="search-box col-sm-3 mt-4">
                            <input class="form-control amber-border" type="text" placeholder="Search"
                              formControlName="searchHeader" aria-label="Search" (keyup)="onKeyUpEvent($event)"
                              onkeydown="return (event.keyCode!=13);">
                            <div class="input-group-append">
                              <span class="input-group-text amber lighten-3" id="basic-text1"><span
                                  class="material-symbols-outlined">search</span></span>
                            </div>
                          </div>
                          <div class="col-sm-12 table-responsive">
                            <table id="toHAFormList" 
                         class="table table-bordered data-table-custom">
                         <thead>
                                      <tr class="text-center">
                                          <th>Billing Company Name</th>
                                          <th>Report Name</th>
                                          <th>Report Email</th>
                                          <th>Date</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr *ngFor="let item of headerList | paginate : { id:'report_pagination', 
                                      itemsPerPage: size, currentPage: page, totalItems:totalLengthHeader }" 
                                      (click)="headerPrepopulation(item._id)"
                                     
                                       style="cursor:pointer; text-align: center ">

                                       <td matTooltip="click to view">{{item.billingCompanyName}}</td>
                                           <td matTooltip="click to view">{{item.reportName}}</td>
                                          <td matTooltip="click to view">{{item.reportEmail}}</td>
                                          <td matTooltip="click to view">{{item.createdAt | date :'d/M/yy'}}</td>
                                       
                                      </tr>
                                    
                                  
                                     

                                  </tbody>
                              </table>
                          </div>
                          <div class="col-sm-12 pt-0 text-right">
                                  <pagination-controls id="report_pagination" directionLinks="true"
                        (pageChange)="onPageChange($event)" class="float-right pagination my-pagination"></pagination-controls>
                              
                          </div>

                          <!-- <div class="col-sm-12">
                     
                                    <div class="row">
                                  
                                        <div class="col-sm-12 pb-0 mb-0">
                                          <h5 class="pb-0 mb-0 mt-2">Bank Details :</h5>
                                    
                                      </div>
                                    </div>
              
              
                                    <div class="row">
                                      <div class=" col-sm-3  ">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>Bank Name</mat-label>
                                          <input class="colorblack" formControlName="bankName" 
                                          matInput placeholder="Bank Name" />
                                        </mat-form-field>
                                        <mat-error class="margin_tp"
                                        *ngIf="reportSettingForm.controls['bankName'].touched && reportSettingForm.controls['bankName'].errors?.pattern">
                                        Please enter valid bank Name.
                                      </mat-error>
                                      </div>
                                      <div class=" col-sm-3  ">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>Branch Name</mat-label>
                                          <input class="colorblack" formControlName="bankBranchName" 
                                          matInput placeholder="Branch Name" />
                                        </mat-form-field>
                                        <mat-error class="margin_tp"
                                        *ngIf="reportSettingForm.controls['bankBranchName'].touched && reportSettingForm.controls['bankBranchName'].errors?.pattern">
                                        Please enter valid bankBranch Name.
                                      </mat-error>
                                      </div>
                                      <div class=" col-sm-3  ">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>Account Number</mat-label>
                                          <input class="colorblack" formControlName="accountNumber" 
                                          matInput placeholder="Account Number" />
                                        </mat-form-field>
                                        <mat-error class="margin_tp"
                                        *ngIf="reportSettingForm.controls['accountNumber'].touched && reportSettingForm.controls['accountNumber'].errors?.pattern">
                                        Please enter valid account Number.
                                      </mat-error>
                                      </div>
                                      <div class=" col-sm-3  ">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>IFSC Code</mat-label> 
                                          <input class="colorblack" formControlName="ifcCode" 
                                          matInput placeholder="IFC Code" /> 
                                        </mat-form-field>
                                        <mat-error class="margin_tp"
                                        *ngIf="reportSettingForm.controls['ifcCode'].touched && reportSettingForm.controls['ifcCode'].errors?.pattern">
                                        Please enter valid IFSC Code.
                                      </mat-error>
                                      </div>
                                      <div class=" col-sm-3  mt-2">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>UPI Id</mat-label>
                                          <input class="colorblack" formControlName="upiId" 
                                          matInput placeholder="UPI Id" />
                                        </mat-form-field>
                                        <mat-error class="margin_tp"
                                        *ngIf="reportSettingForm.controls['upiId'].touched && reportSettingForm.controls['upiId'].errors?.pattern">
                                        Please enter valid UPI Id.
                                      </mat-error>
                                      </div>
                                    
                                    </div>
              
              
                                  </div> -->
                        </div>
                   
                      </form>
                      <form autocomplete="off" [formGroup]="reportSettingFooterForm" *ngIf="footer" novalidate>
                        <div class="row mt-1">
                          <div class="col-sm-6" *ngIf="!branchSelect">
                            <div class="row mt-1">
                              <span>
                                <div class="icon-btns pb-4">
                                  <a class="icon-box2" data-toggle="tooltip" data-placement="top" matTooltip="Report">
                                    <span class="material-symbols-outlined">
                                      file_copy
                                    </span>
                                    <img src="../../assets/icon-bg5.svg" class="icon-bg2">
                                  </a>
                                </div>
                              </span>
                              <span>
                                <h1 class="fass ml-3 pt-1" id="toCalenderForm">Report</h1>
                              </span>
                            </div>
                          </div>
                          <div class="col-sm-5" *ngIf="branchSelect">
                            <div class="row mt-1">
                              <span>
                                <div class="icon-btns pb-4">
                                  <a class="icon-box2" data-toggle="tooltip" data-placement="top" matTooltip="Report">
                                    <span class="material-symbols-outlined">
                                      file_copy
                                    </span>
                                    <img src="../../assets/icon-bg5.svg" class="icon-bg2">
                                  </a>
                                </div>
                              </span>
                              <span>
                                <h1 class="fass ml-3 pt-1" id="toCalenderForm">Report</h1>
                              </span>
                            </div>
                          </div>
                          <div class="col-sm-2" *ngIf="!branchSelect"></div>
                          <div class="col-sm-1 mt-2">

                            <div class="row justify-content-end ">
                              <img src="../../assets/clinic.png" class="mr-2" (click)="copyFromClinic()"
                                matTooltip="Copy From Clinic" width="30rem">
                              <img src="../../assets/branch.png" (click)="copyFromBranch()"
                                matTooltip="Copy From Branch" width="30rem">
                            </div>
                          </div>


                          <div class="col-sm-3">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Select
                                <span class="required-asterisk">*</span>
                              </mat-label>


                              <mat-select placeholder="select" class="colorblack" formControlName="selectType">

                                <mat-option (click)="selectType('Header')" value="Header">Header</mat-option>
                                <mat-option (click)="selectType('Footer')" value="Footer">Footer</mat-option>
                              </mat-select>

                            </mat-form-field>
                          </div>
                          <div class="col-sm-3 " *ngIf="branchSelect == true">
                            <div class="row">
                              <div class="col-sm-12 pr-1">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Branch
                                    <!-- <span class="required-asterisk">*</span> -->
                                  </mat-label>
                                  <mat-select #select1 formControlName="branchRefId">
                                    <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'"
                                      [displayMember]="'branchName'" [array]="BranchList"
                                      (filteredReturn)="filteredListBranch =$event"></mat-select-filter>

                                    <mat-option *ngFor="let item of filteredListBranch" [value]="item._id"
                                      (click)="onBranchClick(item._id,item.branchName);">
                                      {{ item.branchName }}</mat-option>
                                  </mat-select>

                                </mat-form-field>
                                <!-- <mat-error 
                                          *ngIf="reportSettingForm.controls['branchRefId'].touched && reportSettingForm.controls['branchRefId'].errors?.required">
                                          This field is mandatory.
                                      </mat-error> -->
                              </div>

                            </div>

                          </div>
                        </div>
                        <div class="row">

                          <div class=" col-sm-3 ">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label> Name

                              </mat-label>
                              <input required formControlName="clinicBranchName" matInput placeholder=" Name" />


                            </mat-form-field>
                            <mat-error
                              *ngIf="reportSettingFooterForm.controls['clinicBranchName'].touched && reportSettingFooterForm.controls['clinicBranchName'].errors?.pattern">
                              Please enter valid Name</mat-error>
                            <mat-error
                              *ngIf="reportSettingFooterForm.controls['clinicBranchName'].touched && reportSettingFooterForm.controls['clinicBranchName'].errors?.required">
                              This field is mandatory.
                            </mat-error>


                          </div>
                          <div class=" col-sm-3 ">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label> Display Date

                              </mat-label>

                              <mat-select formControlName="date">
                                <mat-option value="Yes"> Yes</mat-option>
                                <mat-option value="No">No</mat-option>
                              </mat-select>

                            </mat-form-field>

                          </div>
                          <div class=" col-sm-3 ">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label> Place

                              </mat-label>
                              <input required formControlName="place" matInput placeholder=" Place" />

                            </mat-form-field>
                            <mat-error
                              *ngIf="reportSettingFooterForm.controls['place'].touched && reportSettingFooterForm.controls['place'].errors?.required">
                              This field is mandatory.
                            </mat-error>
                          </div>
                          <div class=" col-sm-3 ">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Display Patient Name

                              </mat-label>
                              <mat-select formControlName="patientName">
                                <mat-option value="Yes"> Yes</mat-option>
                                <mat-option value="No">No</mat-option>
                              </mat-select>

                            </mat-form-field>

                          </div>
                        </div>
                        <div class="row mt-2">
                          <div *ngIf="reportFooterSubmitButton && genericSettingCreate" class="col-sm-10"></div>
                          <div *ngIf="reportFooterUpdateButton && genericSettingUpdate" class="col-sm-6"></div>
                          <div class="col-sm-2">
                            <button *ngIf="reportFooterSubmitButton && genericSettingCreate" type="button"
                              class="btn btn-sm btn-primary example-full-width" (click)="submitReportFooter()">Save
                            </button>
                          </div>
                          <div class="col-sm-2">
                            <button *ngIf="reportFooterUpdateButton && genericSettingUpdate" type="button"
                              class="btn btn-sm btn-primary example-full-width " (click)="updateReportFooter()">Update
                            </button>
                          </div>
                          <div class="col-sm-2">
                            <button *ngIf="reportFooterUpdateButton && genericSettingUpdate"
                              class="btn btn-sm btn-primary example-full-width "
                              (click)="cancelReportFooter()">Cancel</button>
                          </div>
                        </div>
                      </form>

                    </div>
                    <div *ngIf="isRepair" class="col-sm-12">
                                  <div class="col-sm-12">
                                    <div class="row mt-1">
                                      <span><div class="icon-btns pb-4">
                                        <a class="icon-box2" data-toggle="tooltip" data-placement="top" 
                                matTooltip="Calender" >
                                <span class="material-symbols-outlined">
                                  handyman
                                </span>
                                <img src="../../assets/icon-bg6.svg" class="icon-bg2">
                                </a>
                                        </div> </span> 
                                      <span><h1 class="fass ml-3 pt-1" id="toCalenderForm">Repair</h1></span>
                                    </div>
                                      </div>
                                  <form autocomplete="off" [formGroup]="repairGenricForm" novalidate>
                                  <div class="col-sm-12">
                  
                                    <div class="col-sm-12 mt-2 ">
                                      <label class="header">Add Repair Calculation Percentage :</label>
                                    </div>
            
                                  <div class="col-sm-12">
                                    <div class="row">
                                    <div class=" col-sm-3  ">
                                      <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>Add Percentage</mat-label>
                                        <input required formControlName="repairCalPercentage" matInput placeholder="Percentage" />
                                        <!-- <mat-select formControlName="repairCalPercentage">
                                          <mat-option value="Yes">Yes</mat-option>
                                          <mat-option value="No">No</mat-option>
                                        </mat-select> -->
                                      </mat-form-field>
                                   
                                    </div>
                                   
                                    </div>
                              </div>
                           
            
                            </div>
                            <div class="row mt-2">
                              <div *ngIf="repairSubmitButton && genericSettingCreate" class="col-sm-10"></div>
                              <div *ngIf="repairUpdateButton && genericSettingUpdate" class="col-sm-6"></div>
                              <div class="col-sm-2">
                                <button *ngIf="repairSubmitButton && genericSettingCreate" type="button" class="btn btn-sm btn-primary example-full-width" (click)="submitRepair()"
                             >Save</button>  
                               </div>
                               <div class="col-sm-2">
                                <button  *ngIf="repairUpdateButton && genericSettingUpdate" type="button" class="btn btn-sm btn-primary example-full-width " (click)="updateRepair()"
                                    >Update </button>
                              </div>
                              <div class="col-sm-2">
                                  <button *ngIf="repairUpdateButton && genericSettingUpdate" class="btn btn-sm btn-primary example-full-width " (click)="cancelRepair()"
                              >Cancel</button>
                             </div>
                                </div>
                            </form>

                                </div>

                    <div class="col-sm-12 pt-0 text-right">

                    </div>
                  </div>
                </div>
              </div>