<div class="col-sm-12" [formGroup]="invoicePopUpForm">
    <div class="row">
       
        <div class="col-sm-5  ">
            <h1 class="titlecolor" >  Search Invoice</h1>  
        </div>
        
        <div class="col-sm-6">
            <div class="input-group md-form form-sm form-1 pl-0">
              <div class="input-group-prepend">
                <span class="input-group-text purple lighten-3" id="basic-text1"><i class="fas fa-search text-white"
                    aria-hidden="true"></i></span>
              </div>
              <input class="form-control my-0 py-1" type="text" onkeydown="return (event.keyCode!=13);"
               formControlName="searchBilling"
              aria-label="Search" (keyup)="onKeyUpEvent($event)" placeholder="Search" aria-label="Search">

            </div>
        </div>
        <div class="col-sm-1 text-right pb-2">
          <a (click)="onClose()" matTooltip="Close" class="d-flex align-items-center pr-2 mt-2">
            <span class="material-symbols-outlined pr-2">close</span></a>
        
      </div>
      <div class="col-sm-12 table-responsive">
        <table id="toPatientList" 
     class="table table-bordered data-table-custom">
            <thead>
              <tr style="text-align: center;">
                <!-- <th>ID</th> -->
                <th>Invoice Number</th>
                <th>Date</th>
                <th>Patient Name</th>
                <th>Mobile Number</th>
                <th>Billing Company</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody> 
                <tr *ngFor="let item of billingList | paginate : { id:'billingFormMaster',
                itemsPerPage: size, currentPage: page, totalItems:totalLength } let i = index" 
                 (click)="toPatientForm(item)" 
                 style="cursor:pointer; text-align: center ">
                 <td matTooltip="Click To View" >{{item.billNo}}</td>
                 <td  matTooltip="Click To View" 
                 >{{item.createdAt | date:'dd/MM/yyyy'}}
               </td>
               <!-- | date -->
               <td matTooltip="Click To View" >{{item.patientName}}</td>
               <td matTooltip="Click To View" >{{item.mobile}}</td>
               <td matTooltip="Click To View" >{{item.billingCompanyName}}</td>
             <td *ngIf="item.paymentStatus == 'Paid'" class="green">          
              {{item.paymentStatus}}
               </td>
               <td matTooltip="Click To View" *ngIf="item.paymentStatus == 'Unpaid'" class="red">          
                {{item.paymentStatus}}
                 </td>
                 <td matTooltip="Click To View" *ngIf="item.paymentStatus == 'Cheque Payment Pending'" class="red">          
                  {{item.paymentStatus}}
                   </td>
                 
                   </tr>
             

            </tbody>
          </table>
        </div>
        <div class="col-sm-12 pt-0 text-right">
            <pagination-controls id="billingFormMaster" directionLinks="true"
  (pageChange)="onPageChange($event)" class="float-right pagination my-pagination"></pagination-controls>
        
    </div>
    </div>
</div>
