import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomepageComponent } from './homepage/homepage.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AppointmentComponent } from './appointment/appointment.component';
import {MatDialogModule} from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatIconModule} from '@angular/material/icon';
import { PatientMasterComponent } from './patient-master/patient-master.component';
import { NgModule, NO_ERRORS_SCHEMA, Injectable, CUSTOM_ELEMENTS_SCHEMA, Injector, ErrorHandler, enableProdMode, Pipe, PipeTransform } from '@angular/core';
import { HttpTokenInterceptorService } from './http-token-interceptor.service';

import {DateAdapter} from '@angular/material/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http'; 
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDatepickerModule} from '@angular/material/datepicker';
import  { MAT_DATE_LOCALE } from '@angular/material/core';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatMenuModule} from '@angular/material/menu';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCardModule} from '@angular/material/card';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTabsModule} from '@angular/material/tabs';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatChipsModule} from '@angular/material/chips';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatDividerModule} from '@angular/material/divider';
import {MatNativeDateModule} from '@angular/material/core';
import {MatRippleModule} from '@angular/material/core';
import {MatTreeModule} from '@angular/material/tree';
import { VendorMasterComponent } from './vendor-master/vendor-master.component';
import { DevicesMasterComponent } from './devices-master/devices-master.component';
import { ClinicMasterComponent } from './clinic-master/clinic-master.component';
import { BillingCompanyComponent } from './billing-company/billing-company.component';
import { ClinicalStaffMasterComponent } from './clinical-staff-master/clinical-staff-master.component';
import { ProfileComponent } from './profile/profile.component';
import { OutpatientComponent } from './outpatient/outpatient.component';
import { OPDDashboardComponent } from './opd-dashboard/opd-dashboard.component';
import { MasterComponent } from './master/master.component';
// import { SukuWebcomponentsModule } from '@suku/webcomponents/lib/suku-webcomponents.module';

import { CourierServiceProviderMasterComponent } from './courier-service-provider-master/courier-service-provider-master.component';
import { DoctorMasterComponent } from './doctor-master/doctor-master.component';
import { RoleMasterComponent } from './role-master/role-master.component';
import { ServicesMasterComponent } from './services-master/services-master.component';
import { UsersMasterComponent } from './users-master/users-master.component';
import { SideNavbarComponent } from './side-navbar/side-navbar.component';
import { HeaderComponent } from './header/header.component';
import { CalanderComponent } from './calander/calander.component';
import { FullCalendarModule } from '@fullcalendar/angular'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { GoodsInWordComponent } from './goods-in-word/goods-in-word.component';
import { GoodsInwordReturnComponent } from './goods-inword-return/goods-inword-return.component';
import { StockTransferComponent } from './stock-transfer/stock-transfer.component';
import { ServiceRateComponent } from './service-rate/service-rate.component';
import { RepairComplaintComponent } from './repair-complaint/repair-complaint.component';
import { PopupItemsCollectedComponent } from './popup-items-collected/popup-items-collected.component';
import { PopupNewComplaintComponent } from './popup-new-complaint/popup-new-complaint.component';
import { PopupSerialNoComponent } from './popup-serial-no/popup-serial-no.component'; // a plugin!
import { AppointmentTypeComponent } from './appointment-type/appointment-type.component';
import { PopupAddInstructionsComponent } from './popup-add-instructions/popup-add-instructions.component';
import { PopupItemsPackedComponent } from './popup-items-packed/popup-items-packed.component';
import { BranchMasterComponent } from './branch-master/branch-master.component';
import { EarMouldComponent } from './ear-mould/ear-mould.component'; // a plugin!
// import { NgxMatTimepickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
// import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { InventoryMenuComponent } from './inventory-menu/inventory-menu.component';
import { LoginComponent } from './login/login.component';
import { MatSelectFilterModule } from 'mat-select-filter';
import { FilterPipe } from './filter.pipe'; 
import { PopupBrandComponent } from './popup-brand/popup-brand.component';
import { UserTabComponent } from './user-tab/user-tab.component';
import { PermissionsComponent } from './permissions/permissions.component';
import { BillingComponent } from './billing/billing.component';
import { ClinicExpensesComponent } from './clinic-expenses/clinic-expenses.component';
import { HaFormComponent } from './ha-form/ha-form.component';
import { GenericSettingComponent } from './generic-setting/generic-setting.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { DoctorAvailabilityComponent } from './doctor-availability/doctor-availability.component';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { EarMouldNewComponent } from './ear-mould-new/ear-mould-new.component';
import { InquiryComponent } from './inquiry/inquiry.component';
import { CustomHearingAidComponent } from './custom-hearing-aid/custom-hearing-aid.component';
import { PlaceOrderComponent } from './place-order/place-order.component';
import { ReportComponent } from './report/report.component';
import { HaFormSerialPopupComponent } from './ha-form-serial-popup/ha-form-serial-popup.component';
import { HaFormRightserialPopupComponent } from './ha-form-rightserial-popup/ha-form-rightserial-popup.component';
import { HaFormChargerserialPopupComponent } from './ha-form-chargerserial-popup/ha-form-chargerserial-popup.component';
import { PopUpBranchComponent } from './pop-up-branch/pop-up-branch.component';
import { PopUpPatientComponent } from './pop-up-patient/pop-up-patient.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { PopUpDoctorComponent } from './pop-up-doctor/pop-up-doctor.component';
import { PopUpVendorComponent } from './pop-up-vendor/pop-up-vendor.component';
import { PopUpRoleComponent } from './pop-up-role/pop-up-role.component';
import { PopUpServicetypeComponent } from './pop-up-servicetype/pop-up-servicetype.component';
import { InwardOutwardComponent } from './inward-outward/inward-outward.component';
import { PopUpAppointmenttypeComponent } from './pop-up-appointmenttype/pop-up-appointmenttype.component';
import { PopUpItemComponent } from './pop-up-item/pop-up-item.component';
import { PopUpClinicalStaffComponent } from './pop-up-clinical-staff/pop-up-clinical-staff.component';
import { PopUpExpenseTypeComponent } from './pop-up-expense-type/pop-up-expense-type.component';
import { PopUpCityComponent } from './pop-up-city/pop-up-city.component';
import { PopUpCanNumberComponent } from './pop-up-can-number/pop-up-can-number.component';
import { PopUpPatientsourceComponent } from './pop-up-patientsource/pop-up-patientsource.component';
import { PopUpCourierserviceComponent } from './pop-up-courierservice/pop-up-courierservice.component';
import { ReportsComponent } from './reports/reports.component';
import { PopupFollowupreasonComponent } from './popup-followupreason/popup-followupreason.component';
import { PopupBookappointmentComponent } from './popup-bookappointment/popup-bookappointment.component';
import { CompanyMasterComponent } from './company-master/company-master.component';
import { PopupCompanyComponent } from './popup-company/popup-company.component';
import { ChartsModule } from 'ng2-charts';
import { PopupBillingcompanyComponent } from './popup-billingcompany/popup-billingcompany.component';
import { PrintBillComponent } from './print-bill/print-bill.component';
import { BillFormComponent } from './bill-form/bill-form.component';
import { PopupTotalBillComponent } from './popup-total-bill/popup-total-bill.component';
import { PopupModeOfPaymentComponent } from './popup-mode-of-payment/popup-mode-of-payment.component';
import { PopupServiceComponent } from './popup-service/popup-service.component';
import { PopupAddDeviceComponent } from './popup-add-device/popup-add-device.component';
import { PopUpStyleComponent } from './pop-up-style/pop-up-style.component';
import { PopUpDevicetypeComponent } from './pop-up-devicetype/pop-up-devicetype.component';
import { PopUpBatterytypeComponent } from './pop-up-batterytype/pop-up-batterytype.component';
import { PopUpConditionComponent } from './pop-up-condition/pop-up-condition.component';
import { ReceiptComponent } from './receipt/receipt.component';
import { WhatsAppPopupComponent } from './whats-app-popup/whats-app-popup.component';
import { ChequeListComponent } from './cheque-list/cheque-list.component';
import { ChequeReconciliationComponent } from './cheque-reconciliation/cheque-reconciliation.component';
import { HolidayLeaveComponent } from './holiday-leave/holiday-leave.component';
import { ClinicHolidayComponent } from './clinic-holiday/clinic-holiday.component';
import { DoctorLeaveComponent } from './doctor-leave/doctor-leave.component';
import { PatientGlobalSearchComponent } from './patient-global-search/patient-global-search.component';
import { ChequeAlertComponent } from './cheque-alert/cheque-alert.component';
import { MatBadgeModule } from '@angular/material/badge';
import { PopUpDeviceComponent } from './pop-up-device/pop-up-device.component';
import { PopupTestTypeComponent } from './popup-test-type/popup-test-type.component';
import { PopupDepartmentComponent } from './popup-department/popup-department.component';
import { DateFormat } from './date-format';
import { PatientSearchPopComponent } from './patient-search-pop/patient-search-pop.component';
import { AppoinmentPopupComponent } from './appoinment-popup/appoinment-popup.component';
import { SearchDashboardComponent } from './search-dashboard/search-dashboard.component';
import { PopupCancelConfirmComponent } from './popup-cancel-confirm/popup-cancel-confirm.component';
import { PopupPostComponent } from './popup-post/popup-post.component';
import { PopupRejectstockComponent } from './popup-rejectstock/popup-rejectstock.component';
import { PopUpInvoiceComponent } from './pop-up-invoice/pop-up-invoice.component';
import { PopUpDeviceDataComponent } from './pop-up-device-data/pop-up-device-data.component';
import { PopUpRepairDataComponent } from './pop-up-repair-data/pop-up-repair-data.component';
import { PatientDashboardComponent } from './patient-dashboard/patient-dashboard.component';
import { PaymentDashboardComponent } from './payment-dashboard/payment-dashboard.component';
import { DeviceDashboardComponent } from './device-dashboard/device-dashboard.component';
import { RepairDashboardComponent } from './repair-dashboard/repair-dashboard.component';
import { EarmouldDashboardComponent } from './earmould-dashboard/earmould-dashboard.component';
import { AppoinmentDashboardComponent } from './appoinment-dashboard/appoinment-dashboard.component';
import { CommunicationDashboardComponent } from './communication-dashboard/communication-dashboard.component';
import { PopupDeviceDetailsGlobalComponent } from './popup-device-details-global/popup-device-details-global.component';
import { CommunicationComponent } from './communication/communication.component';
import { FaceplateComponent } from './faceplate/faceplate.component';
import { PopUpShellComponent } from './pop-up-shell/pop-up-shell.component';
import { PopUpAccesoriesComponent } from './pop-up-accesories/pop-up-accesories.component';
import { MakePopupComponent } from './make-popup/make-popup.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { PopUpDeviceByPatientComponent } from './pop-up-device-by-patient/pop-up-device-by-patient.component';
import { PopupDeviceByPatientComponent } from './popup-device-by-patient/popup-device-by-patient.component';
import { PopupInquryPatientDuplicateComponent } from './popup-inqury-patient-duplicate/popup-inqury-patient-duplicate.component';
import { PopupPlaceOrderMailComponent } from './popup-place-order-mail/popup-place-order-mail.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { NotesComponent } from './notes/notes.component';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { DeviceNotesComponent } from './device-notes/device-notes.component';
import { PopupChequeDetailsComponent } from './popup-cheque-details/popup-cheque-details.component';
import { PopupFollowUpComponent } from './popup-follow-up/popup-follow-up.component';
import { InwardOutwardNewComponent } from './inward-outward-new/inward-outward-new.component';
import { RepairActionComponent } from './repair-action/repair-action.component';
import { PopUpInwardOutwardComponent } from './pop-up-inward-outward/pop-up-inward-outward.component';
import { FollowupDashboardComponent } from './followup-dashboard/followup-dashboard.component';
import { InwardOutwadPopupTypeComponent } from './inward-outwad-popup-type/inward-outwad-popup-type.component';
import { RepairPrintComponent } from './repair-print/repair-print.component';
import { CustomHaPrintComponent } from './custom-ha-print/custom-ha-print.component';
import { HaFormPrintComponent } from './ha-form-print/ha-form-print.component';
import { EarMouldPrintComponent } from './ear-mould-print/ear-mould-print.component';
import { BillingPopupComponent } from './billing-popup/billing-popup.component';
import { AdvanceAmountComponent } from './advance-amount/advance-amount.component';
import { RepairCalculationComponent } from './repair-calculation/repair-calculation.component';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MY_DATE_FORMATS } from './my-date-formats';
import { PopupBillComponent } from './popup-bill/popup-bill.component';
import { PopupSalesPersonDetailsComponent } from './popup-sales-person-details/popup-sales-person-details.component';
import { PopupPlaceOrderSizeComponent } from './popup-place-order-size/popup-place-order-size.component';
import { PopupPlaceOrderPowerComponent } from './popup-place-order-power/popup-place-order-power.component';
import { PopupPlaceOrderTypeComponent } from './popup-place-order-type/popup-place-order-type.component';
import { PopupPlaceOrderAccessoriesComponent } from './popup-place-order-accessories/popup-place-order-accessories.component';
import { DataMigrationComponent } from './data-migration/data-migration.component';
import { GoodsInWordReturnComponent } from './goods-in-word-return/goods-in-word-return.component';
import { DiagnosisComponent } from './diagnosis/diagnosis.component';
import { PdfviewerComponent } from './pdfviewer/pdfviewer.component';
import { BackdatedAptComponent } from './backdated-apt/backdated-apt.component';
import { PopupHaColorComponent } from './popup-ha-color/popup-ha-color.component';
import { PopUpChangeOfDeviceComponent } from './pop-up-change-of-device/pop-up-change-of-device.component';
import { PlaceOrderPrintComponent } from './place-order-print/place-order-print.component';
import { PatientTrackingStatusComponent } from './patient-tracking-status/patient-tracking-status.component';
import { PopupConfirmationMsgComponent } from './popup-confirmation-msg/popup-confirmation-msg.component';
import { PopupDragAppointmentComponent } from './popup-drag-appointment/popup-drag-appointment.component';
import { PopupStockInHandComponent } from './popup-stock-in-hand/popup-stock-in-hand.component';
import { FittedByUsComponent } from './fitted-by-us/fitted-by-us.component';
import { PopUpFittedByUsComponent } from './pop-up-fitted-by-us/pop-up-fitted-by-us.component';
import { PreviewPopupComponent } from './preview-popup/preview-popup.component';
import { PopupTrackingStatusComponent } from './popup-tracking-status/popup-tracking-status.component';
import {TitleCasePipe} from '@angular/common';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { BirthdayStatusComponent } from './birthday-status/birthday-status.component';
//import { PopUpDeviceComponent } from './pop-up-device/pop-up-device.component';
FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin
]);
@NgModule({
  declarations: [
    AppComponent,
    FilterPipe,
    HomepageComponent,
    DashboardComponent,
    AppointmentComponent,
    PatientMasterComponent,
    VendorMasterComponent,
    DevicesMasterComponent,
    ClinicMasterComponent,
    BillingCompanyComponent,
    ClinicalStaffMasterComponent,
    ProfileComponent,
    OutpatientComponent,
    OPDDashboardComponent,
    MasterComponent,
    CourierServiceProviderMasterComponent,
    DoctorMasterComponent,
    RoleMasterComponent,
    ServicesMasterComponent,
    UsersMasterComponent,
    SideNavbarComponent,
    HeaderComponent,
    CalanderComponent,
    GoodsInWordComponent,
    GoodsInwordReturnComponent,
    StockTransferComponent,
    ServiceRateComponent,
    RepairComplaintComponent,
    PopupItemsCollectedComponent,
    PopupNewComplaintComponent,
    PopupSerialNoComponent,
    AppointmentTypeComponent,
    PopupAddInstructionsComponent,
    PopupItemsPackedComponent,
    BranchMasterComponent,
    EarMouldComponent,
    InventoryMenuComponent,
    LoginComponent,
    PopupBrandComponent,
    UserTabComponent,
    PermissionsComponent,
    BillingComponent,
    ClinicExpensesComponent,
    HaFormComponent,
    GenericSettingComponent,
    DoctorAvailabilityComponent,
    EarMouldNewComponent,
    InquiryComponent,
    CustomHearingAidComponent,
    PlaceOrderComponent,
    ReportComponent,
    HaFormSerialPopupComponent,
    HaFormRightserialPopupComponent,
    HaFormChargerserialPopupComponent,
    PopUpBranchComponent,
    PopUpPatientComponent,
    ForgotPasswordComponent,
    PopUpDoctorComponent,
    PopUpVendorComponent,
    PopUpRoleComponent,
    PopUpServicetypeComponent,
    InwardOutwardComponent,
    PopUpAppointmenttypeComponent,
    PopUpItemComponent,
    PopUpClinicalStaffComponent,
    PopUpExpenseTypeComponent,
    PopUpCityComponent,
    PopUpCanNumberComponent,
    PopUpPatientsourceComponent,
    PopUpCourierserviceComponent,
    ReportsComponent,
    PopupFollowupreasonComponent,
    PopupBookappointmentComponent,
    CompanyMasterComponent,
    PopupCompanyComponent,
    PopupBillingcompanyComponent,
    PrintBillComponent,
    BillFormComponent,
    PopupTotalBillComponent,
    PopupModeOfPaymentComponent,
    PopupServiceComponent,
    PopupAddDeviceComponent,
    PopUpStyleComponent,
    PopUpDevicetypeComponent,
    PopUpBatterytypeComponent,
    PopUpConditionComponent,
    ReceiptComponent,
    WhatsAppPopupComponent,
    ChequeListComponent,
    ChequeReconciliationComponent,
    HolidayLeaveComponent,
    ClinicHolidayComponent,
    DoctorLeaveComponent,
    PatientGlobalSearchComponent,
    ChequeAlertComponent,
    PopUpDeviceComponent,
    PopupTestTypeComponent,
    PopupDepartmentComponent,
    PatientSearchPopComponent,
    AppoinmentPopupComponent,
    SearchDashboardComponent,
    PopupCancelConfirmComponent,
    PopupPostComponent,
    PopupRejectstockComponent,
    PopUpInvoiceComponent,
    PopUpDeviceDataComponent,
    PopUpRepairDataComponent,
    PatientDashboardComponent,
    PaymentDashboardComponent,
    DeviceDashboardComponent,
    RepairDashboardComponent,
    EarmouldDashboardComponent,
    AppoinmentDashboardComponent,
    CommunicationDashboardComponent,
    PopupDeviceDetailsGlobalComponent,
    CommunicationComponent,
    FaceplateComponent,
    PopUpShellComponent,
    PopUpAccesoriesComponent,
    MakePopupComponent,
    ChangePasswordComponent,
    PopUpDeviceByPatientComponent,
    PopupDeviceByPatientComponent,
    PopupInquryPatientDuplicateComponent,
    PopupPlaceOrderMailComponent,
    NotesComponent,
    DeviceNotesComponent,
    PopupChequeDetailsComponent,
    PopupFollowUpComponent,
    InwardOutwardNewComponent,
    RepairActionComponent,
    PopUpInwardOutwardComponent,
    FollowupDashboardComponent,
    InwardOutwadPopupTypeComponent,
    RepairPrintComponent,
    CustomHaPrintComponent,
    HaFormPrintComponent,
    EarMouldPrintComponent,
    BillingPopupComponent,
    AdvanceAmountComponent,
    RepairCalculationComponent,
    PopupBillComponent,
    PopupSalesPersonDetailsComponent,
    PopupPlaceOrderSizeComponent,
    PopupPlaceOrderPowerComponent,
    PopupPlaceOrderTypeComponent,
    PopupPlaceOrderAccessoriesComponent,
    DataMigrationComponent,
    GoodsInWordReturnComponent,
    DiagnosisComponent,
    PdfviewerComponent,
    BackdatedAptComponent,
    PopupHaColorComponent,
    PopUpChangeOfDeviceComponent,
    PlaceOrderPrintComponent,
    PatientTrackingStatusComponent,
    PopupConfirmationMsgComponent,
    PopupDragAppointmentComponent,
    PopupStockInHandComponent,
    FittedByUsComponent,
    PopUpFittedByUsComponent,
    PreviewPopupComponent,
    PopupTrackingStatusComponent,
    BirthdayStatusComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatDialogModule,
    BrowserAnimationsModule,
     MatIconModule,
     FormsModule,
     HttpClientModule,
     ReactiveFormsModule,
     AppRoutingModule,
     BrowserAnimationsModule,
     MatAutocompleteModule,
     MatButtonModule,
     MatButtonToggleModule,
     MatCardModule,
     MatCheckboxModule,
     MatChipsModule,
     MatDialogModule,
     MatDividerModule,
     MatExpansionModule,
     MatGridListModule,
     MatIconModule,
     MatInputModule,
     MatListModule,
     MatMenuModule,
     MatNativeDateModule,
     MatPaginatorModule,
     MatProgressBarModule,
     MatProgressSpinnerModule,
     MatRadioModule,
     MatRippleModule,
     MatSelectModule,
     MatSidenavModule,
     MatSliderModule,
     MatSlideToggleModule,
     MatSnackBarModule,
     MatSortModule,
     MatStepperModule,
     MatTableModule,
     MatTabsModule,
     MatToolbarModule,
     MatTooltipModule,
     MatTreeModule,
     NgxPaginationModule,
     FullCalendarModule,
     MatDatepickerModule,
     NgxMaterialTimepickerModule.setLocale('en-US'),
     MatSelectFilterModule,
     NgxSpinnerModule,
     ChartsModule,
     MatBadgeModule,
     ClipboardModule,
     RxReactiveFormsModule,
     PdfViewerModule
    // NgZorroAntdModule
  ],
  // providers   : [ { provide: NZ_I18N, useValue: en_US } ],
  entryComponents: [AppointmentComponent,MasterComponent],


  schemas: [CUSTOM_ELEMENTS_SCHEMA],

providers: [TitleCasePipe,{ provide: DateAdapter, useClass: DateFormat },
  { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpTokenInterceptorService,
    multi: true
  },
DatePipe
],

bootstrap: [AppComponent]

})

export class AppModule {

constructor(private dateAdapter: DateAdapter<Date>) {

dateAdapter.setLocale("en-in"); // DD/MM/YYYY

}

}
/***************************************************************** */
    // NgZorroAntdModule
//   schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
//   providers: [
//     { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
//     DatePipe
//   ],
//   bootstrap: [AppComponent]
// })
// export class AppModule { }
