import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  FormArray 
} from "@angular/forms";
 import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { AfterViewInit, Directive, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { PopUpBranchComponent } from "../pop-up-branch/pop-up-branch.component";
import { PopUpPatientComponent } from "../pop-up-patient/pop-up-patient.component";
import { PopupBillingcompanyComponent } from "../popup-billingcompany/popup-billingcompany.component";
import { PopupCompanyComponent } from "../popup-company/popup-company.component";
import { PatientSearchPopComponent } from "../patient-search-pop/patient-search-pop.component";
import { FaceplateComponent } from "../faceplate/faceplate.component";
import { PopUpShellComponent } from "../pop-up-shell/pop-up-shell.component";
import { PdfviewerComponent } from "../pdfviewer/pdfviewer.component";

@Component({
  selector: 'app-custom-hearing-aid',
  templateUrl: './custom-hearing-aid.component.html',
  styleUrls: ['./custom-hearing-aid.component.scss']
})
export class CustomHearingAidComponent implements OnInit {
  errormessage: string;
  searchText: any;
  HaId: any;
  allState=[
    {name:'Andaman and Nicobar Islands',value:'AN' },
    {name:'Andhra Pradesh',value:'AP'},
    { name:'Arunachal Pradesh',value:'AR' },
{name:'Assam',value:'AS' },
{ name:'Bihar',value:'BR'},
 { name:'Chandigarh',value:'CH' },
{name:'Chhattisgarh',value:'CT' },
{ name:'Dadra and Nagar Haveli', value:'DN' },
 { name:'Daman and Diu', value:'DD'},
 { name:'Delhi',value:'DL'},
 {name:'Goa',value:'GA' },
{name:'Gujarat', value:'GJ'}, 
{name:'Haryana',value:'HR'}, 
{name:'Himachal Pradesh',value:'HP'}, 
{ name:'Jammu and Kashmir',value:'JK'}, 
 {name:'Jharkhand',value:'JH'}, 
{ name:'Karnataka', value:'KA'},
{ name:'Kerala',value:'KL'},
{name:'Ladakh',value:'LA'},
 {name:'Lakshadweep',value:'LD' },
 {name:'Madhya Pradesh', value:'MP'},
{ name:'Maharashtra',value:'MH'},
 { name:'Manipur',value:'MN'},
{ name:'Meghalaya', value:'ML' },
{ name:'Mizoram',value:'MZ' },
{ name:'Nagaland',value:'NL' },
 { name:'Odisha',value:'OR'},
 { name:'Puducherry', value:'PY'},
 {name:'Punjab', value:'PB' },
{ name:'Rajasthan', value:'RJ'},           
 {name:'Punjab',value:'PB'},   
 {name:'Rajasthan', value:'RJ'},
{name:'Sikkim',value:'SK' },
{ name:'Tamil Nadu',value:'TN'}, 
 {name:'Telangana',value:'TG'},   
  { name:'Tripura',  value:'TR' },
 { name:'Uttar Pradesh', value:'UP'},
{name:'Uttarakhand',value:'UT'},  
{ name:'West Bengal', value:'WB' },                        
  ]
  filteredList: { name: string; value: string; }[];
  gstCheckbox= true;
  userType: string;
  clinicRef: string;
  clinicId: string;
  BranchList: any;
  filteredListBranch: any;
   superBranchId: any;
   branchRef: any;
  filteredListPatient: any;
  patientList: any;
  pid: any;
  DeviceList: any;
  DeviceList2: any;
  filteredListDevice: any;
  filteredListDevice2: any;
  audiogramFile: any;
  branchName: string;
  billingCompanyList = [];
  filteredListBillingCompany:any;
  billingCompany: any;
  //company Master
  companyList = [];
  filteredListCompany: any;
  companyName: any;
  address: any;
  email: any;
  canNumber: any;
  billCompanyName = '';
  isPrint=false;
  file: any;
  faceplateList: any;
  filteredListfaceplate: any[];
  faceplateName: any;
  shellList: any;
  filteredListshell: any;
  shellName: any;
  bID: any;
  make: any;
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
   public apimicroservice: ApiServiceService,
    public router: Router,
     public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService
  ) {}
  customHaForm: FormGroup;
  status: any;
  submitButton = true;
  updateButton = false;
  deleteButton=false;
  cancelButton = false;
  loggedId: any;
  myimage;
  date: any;
  size=10;
  page=1;
  data: any; 
  totalLength:any;
  listofCustomHA:any;
  userPermission:any;
  customHACreate= true;
  customHAUpdate= true;
  isSuperAdmin= true;
  patientSelect=false;
  patientCreate = true;
  branchCreate = true;
  billingCreate = true;
  companyCreate = true;
  RightDisable = false;
  LeftDisable = false;
  isPrepopulate= false;
  ngOnInit(): void {
    this.page=1;
    this.isPrepopulate = false;
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.date=new Date();
    this.filteredList = this.allState.slice();
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    this.userType = sessionStorage.getItem('userType');
    this.branchRef = sessionStorage.getItem('branchRef');
    //
    
    this.branchName = sessionStorage.getItem('branchName');
    if(this.userType !== 'Super Admin'){
      this.isSuperAdmin= false;
    this.userPermission.forEach((element) => {
      if(element.pageName == 'Custom Hearing Aid' && element.create == false)
      {
          this.customHACreate = false;  
      }
      if(element.pageName == 'Custom Hearing Aid' && element.update == false)
      {
          this.customHAUpdate = false;  
      }  
      if(element.pageName == 'Patient' && element.create == false)
      {
          this.patientCreate = false;  
      } 
      if(element.pageName == 'Branch' && element.create == false)
      {
          this.branchCreate = false;  
      }
      if(element.pageName == 'Billing Company' && element.create == false)
      {
          this.billingCreate = false;  
      } 
      if(element.pageName == 'Company Master' && element.create == false)
      {
          this.companyCreate = false;  
      }
    });
  }

  
    this.customHaForm = this.fb.group({
 
      "clinicRefId": new FormControl(''),
      "branchRefId": new FormControl(''),
      "billingRefId": new FormControl('',Validators.required),
      "branchWiseId": new FormControl(''),
      "branchName": new FormControl(''),
      "patientRefId":new FormControl('',Validators.required),
    "firstName" :new FormControl(''),
    "lastName" :new FormControl(''),
    "mobile1":new FormControl('',[Validators.pattern("(0|91)?[6-9][0-9]{9}")]),
    "patientUniqueId" :new FormControl(''),
    "age" :new FormControl(''),
    "right250HZ":new FormControl('',[Validators.pattern("^(-)?[0123456789]?[012]?[05]$"),Validators.min(-15),Validators.max(120)]),
    "left250HZ":new FormControl('',[Validators.pattern("^(-)?[0123456789]?[012]?[05]$"),Validators.min(-15),Validators.max(120)]),
    "right500HZ":new FormControl('',[Validators.pattern("^(-)?[0123456789]?[012]?[05]$"),Validators.min(-15),Validators.max(120)]),
    "left500HZ":new FormControl('',[Validators.pattern("^(-)?[0123456789]?[012]?[05]$"),Validators.min(-15),Validators.max(120)]),
    "right1KHZ":new FormControl('',[Validators.pattern("^(-)?[0123456789]?[012]?[05]$"),Validators.min(-15),Validators.max(120)]),
    "left1KHZ":new FormControl('',[Validators.pattern("^(-)?[0123456789]?[012]?[05]$"),Validators.min(-15),Validators.max(120)]),
    "right3KHZ":new FormControl('',[Validators.pattern("^(-)?[0123456789]?[012]?[05]$"),Validators.min(-15),Validators.max(120)]),
    "left3KHZ":new FormControl('',[Validators.pattern("^(-)?[0123456789]?[012]?[05]$"),Validators.min(-15),Validators.max(120)]),
    "right4KHZ":new FormControl('',[Validators.pattern("^(-)?[0123456789]?[012]?[05]$"),Validators.min(-15),Validators.max(120)]),
    "left4KHZ":new FormControl('',[Validators.pattern("^(-)?[0123456789]?[012]?[05]$"),Validators.min(-15),Validators.max(120)]),
    "right6KHZ":new FormControl('',[Validators.pattern("^(-)?[0123456789]?[012]?[05]$"),Validators.min(-15),Validators.max(120)]),
    "left6KHZ":new FormControl('',[Validators.pattern("^(-)?[0123456789]?[012]?[05]$"),Validators.min(-15),Validators.max(120)]),
    "right8KHZ":new FormControl('',[Validators.pattern("^(-)?[0123456789]?[012]?[05]$"),Validators.min(-15),Validators.max(120)]),
    "left8KHZ":new FormControl('',[Validators.pattern("^(-)?[0123456789]?[012]?[05]$"),Validators.min(-15),Validators.max(120)]),
    "leftModel" : new FormControl(''), 
    "rightModel" : new FormControl(''),
    "leftMake" : new FormControl(''),
    "rightMake" : new FormControl(''),
    "leftStyle" : new FormControl(''),
    "rightStyle" : new FormControl(''),
    "leftBattery" : new FormControl(''),
    "rightBattery" : new FormControl(''),
    "ear" : new FormControl('',Validators.required),
    "leftShell" : new FormControl(''),
    "rightShell" : new FormControl(''),
    "leftFacePlate" : new FormControl(''),
    "rightFacePlate" : new FormControl(''),
    "leftVent" : new FormControl(''),
    "rightVent" : new FormControl(''),
    "leftMatrix" : new FormControl(''),
    "rightMatrix" : new FormControl(''),
    "leftRemovalString" : new FormControl(''),
    "rightRemovalString" : new FormControl(''),
    "leftProgramSwitch":new FormControl(''),
    "rightProgramSwitch":new FormControl(''),
    "leftVolumeControl":new FormControl(''),
    "rightVolumeControl":new FormControl(''),
   // "estimateRequired":new FormControl(''),
    "complaint":new FormControl(''),
    "audiogram":new FormControl(false),
    "audiogramFile":new FormControl(''),
   "uploadFileName":new FormControl(''),
    //"earImpression":new FormControl(false),
    "earImpressionValue":new FormControl('',[Validators.pattern("^[0-9 ]*$"),Validators.maxLength(10)]),
    "billingCompanyName":new FormControl(''),

    //company Master
    "companyRefId": new FormControl('',Validators.required),
    "companyName": new FormControl(''),
    "companyAddress": new FormControl(''),
    "companyEmail": new FormControl(''),
    "canNumber": new FormControl(''),
    "make":new FormControl(),

    "leftDeviceRefId":new FormControl(),
      "rightDeviceRefId":new FormControl(),
      "createdAt": this.date,
      "searchCustomHA": new FormControl(''),
      "patientName": new FormControl(''), 
      "date": new FormControl(''),
    });
    let latest_date = this.datepipe.transform(this.date, 'dd/MM/yyyy');
    this.customHaForm.get("date").setValue(latest_date);
  //  this.customHaForm.controls["InquiryStatus"].setValue("Active");
  this.uploadFileName = "";
  this.hide = false;
  this.onEarChange('Both');
    this.fetchCustomHAList();
    this.getLastId();
    this.fetchBranchList();
  
    this.fetchBillingCompanyList();
    this.getAllBranch();
    this.fetchPatientList();
    this.fetchCompanyList();
    this.fetchModelList();
    this.fetchModelList2();
  }
  getAllBranch()
  {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      if (res.status) {        
       let length = res.data.length - 1;
       this.branchName = res.data[length].branchName;
        this.customHaForm.controls["branchRefId"].setValue(res.data[length]._id);
     
        if(this.userType == 'Super Admin')
        {  
          this.branchClick(res.data[length]._id,this.branchName);
          this.apimicroservice.getBranchById(res.data[length]._id).subscribe(res => {
            this.spinner.hide();
            this.customHaForm.get("branchName").setValue(res.data[length].branchName);
           });
        }
       
      }
    });
  }
  fetchBranchList() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      //  
      if (res.status) {    
        this.BranchList = res.data;
        this.filteredListBranch= this.BranchList.slice();
      } else {
     //   this.apimicroservice.openSnackbar(res.message);
      }
    })
  }  
  submitCustomHA() {
    let payload = this.customHaForm.value;
    if(this.userType == 'Super Admin')
    {
      this.customHaForm.controls['branchRefId'].setValidators(Validators.required);
      this.customHaForm.controls['branchRefId'].updateValueAndValidity();
    }
    else{
      this.customHaForm.controls['branchRefId'].setErrors(null);
      this.customHaForm.controls['branchRefId'].clearValidators();
      this.customHaForm.controls['branchRefId'].updateValueAndValidity();
    }
    if(payload.ear == 'Right')
    {
      this.customHaForm.controls['rightModel'].setValidators(Validators.required);
      this.customHaForm.controls['rightModel'].updateValueAndValidity();

       
      this.customHaForm.controls['leftModel'].setErrors(null);
      this.customHaForm.controls['leftModel'].clearValidators();
      this.customHaForm.controls['leftModel'].updateValueAndValidity();

    }
    else if(payload.ear == 'Left')
    {
      this.customHaForm.controls['leftModel'].setValidators(Validators.required);
      this.customHaForm.controls['leftModel'].updateValueAndValidity();

     
      this.customHaForm.controls['rightModel'].setErrors(null);
      this.customHaForm.controls['rightModel'].clearValidators();
      this.customHaForm.controls['rightModel'].updateValueAndValidity();

    
    }
    else if(payload.ear == 'Both')
    {
      this.customHaForm.controls['rightModel'].setValidators(Validators.required);
      this.customHaForm.controls['rightModel'].updateValueAndValidity();

      this.customHaForm.controls['leftModel'].setValidators(Validators.required);
      this.customHaForm.controls['leftModel'].updateValueAndValidity();
    }
    else 
    {
      this.customHaForm.controls['leftModel'].setErrors(null);
      this.customHaForm.controls['leftModel'].clearValidators();
      this.customHaForm.controls['leftModel'].updateValueAndValidity();

      this.customHaForm.controls['rightModel'].setErrors(null);
      this.customHaForm.controls['rightModel'].clearValidators();
      this.customHaForm.controls['rightModel'].updateValueAndValidity();
    } 
    if (this.customHaForm.invalid) {
      this.customHaForm.get("billingRefId").markAsTouched();
      this.customHaForm.get("patientRefId").markAsTouched();
      this.customHaForm.get("ear").markAsTouched();
      this.customHaForm.get("age").markAsTouched();

      this.customHaForm.get("companyRefId").markAsTouched();
      this.customHaForm.get("leftModel").markAsTouched();
      this.customHaForm.get("rightModel").markAsTouched();
      return;
    } else {
      this.addCustomHA();
    }
  }


  updateCustomHADetails() {
  //  this.setValidor();
  //
  
  if(this.userType == 'Super Admin')
    {
      //
      this.customHaForm.controls['branchRefId'].setValidators(Validators.required);
      this.customHaForm.controls['branchRefId'].updateValueAndValidity();
    }
    else{
      this.customHaForm.controls['branchRefId'].setErrors(null);
      this.customHaForm.controls['branchRefId'].clearValidators();
      this.customHaForm.controls['branchRefId'].updateValueAndValidity();
    }
    //
    if (this.customHaForm.invalid) {
      //
      this.customHaForm.get("billingRefId").markAsTouched();
      this.customHaForm.get("patientRefId").markAsTouched();
      this.customHaForm.get("ear").markAsTouched();
      this.customHaForm.get("age").markAsTouched();
     // this.customHaForm.get("battery").markAsTouched();
     this.customHaForm.get("companyRefId").markAsTouched();
      return;
    } else {
      //
      this.updateCustomHA();
    }
  }

  // updateUpdateSingleCustomer() {
  //   if (this.customHaForm.invalid) {
  //     // this.customHaForm.get("companyName").markAsTouched();
  //     // this.customHaForm.get("inquiryId").markAsTouched();
  //     // this.customHaForm.get("companyRegisteredAddress").markAsTouched();
  //     // this.customHaForm.get("registeredUnderGST").markAsTouched();
  //     // this.customHaForm.get("companyGST").markAsTouched();
  //     // this.customHaForm.get("companyContactNumber").markAsTouched();
  //     // this.customHaForm.get("companyPAN").markAsTouched();
  //     // this.customHaForm.get("propriter").markAsTouched();
  //     // this.customHaForm.get("companyType").markAsTouched();
  //     return;
  //   } else {
  //   //update code
  //   }
  // }
  
  addCustomHA() {
    this.spinner.show();
    let payload = this.customHaForm.value;
    payload.createdAt= new Date();
   payload.audiogramFile = this.audiogramFile;
   payload.fileName =  this.uploadFileName;
       if(this.userType !== 'Super Admin')
       {
         payload.branchRefId = this.branchRef;
         payload.clinicRefId = this.clinicRef;
         payload.branchName = this.branchName;
       }

      this.apimicroservice.saveCustomHAData(payload).subscribe(res => {
        this.spinner.hide();
        if (res.status) {
          this.apimicroservice.openSnackbar(res.message);
          this.customHaForm.controls["uploadFileName"].setValue("");
          this.ngOnInit();
          // this.customHaForm.reset();
          // this.customHaForm.controls["uploadFileName"].setValue("");
        // this.customHaForm.reset();
          // this.fetchInquiry();
        } else {
          this.apimicroservice.openSnackbar(res.message);
        }   
      },(ERROR:HttpErrorResponse) => {
        this.apimicroservice.openSnackbar(ERROR.error.message);
        this.spinner.hide();
    });
  }
  openSnackbar(message: string) {
    return this._snackBar.open(message, "close", {
      verticalPosition: "bottom",
      horizontalPosition: "right",
      duration: 2000,
      panelClass: ["snackbarStyle"],
    });
  }
  

  fetchCustomHAList() {
    let pg = 1;
    let size = 10;
    let search='';
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    this.spinner.show();
    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getCustomHA(pg,size,search,branchRef,clinicRef).subscribe(res => {
        this.spinner.hide();
        this.listofCustomHA = res.data.data;
        this.totalLength=res.data.totalItems;
        if(res.status){
         // this.apimicroservice.openSnackbar(res.message);
          
        }else{
          //this.apimicroservice.openSnackbar(res.message);
        }
      });
    } else {
      this.apimicroservice.getCustomHA(pg,size,search,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
        this.spinner.hide();
        this.listofCustomHA = res.data.data;
        this.totalLength=res.data.totalItems;
        if(res.status){
         // this.apimicroservice.openSnackbar(res.message);
          
        }else{
          //this.apimicroservice.openSnackbar(res.message);
        }
      });
      
    }
    
  }
  branchClick(id,branchName)
  {

    this.customHaForm.controls["branchName"].setValue(branchName);
    // this.apimicroservice.getPatientListByBranchClinic(id,this.clinicRef).subscribe(res => {
    //   //
      
    //   if (res.status) {  
    //     if(res.data.length > 0){
    //      this.patientList = res.data;
    //      this.filteredListPatient = this.patientList.slice();
    //     //  this.onSelectPatient(this.patientList[0]._id);
    //     //  this.customHaForm.get("patientRefId").setValue(this.patientList[0]._id);
          
    //     }  else{
    //      this.patientList = res.data;
    //     } 
        
    //   } else {
    //     //this.apimicroservice.openSnackbar(res.message);
    //   }
    // })
   this.apimicroservice.getBranchById(id).subscribe(res => { 
     //
       
     let data = res.data[0];
     let payload = this.customHaForm.value;
      payload.clinicRefId = res.data[0].clinicRefId;
      this.customHaForm.controls["clinicRefId"].setValue(res.data[0].clinicRefId);
      if(this.updateButton == false){
        this.spinner.show();
        this.apimicroservice.getLastCustomHAID(payload.branchRefId).subscribe(res => {
          //   
         
          if (res['status']) {
            let HAIdd = res['data']['totalItems'];
            this.bID = HAIdd + 1;
            this.customHaForm.controls['branchWiseId'].setValue(HAIdd + 1);
          } else {
            this.apimicroservice.openSnackbar("Something went wrong");
          }
          this.spinner.hide();
        })
      }
    });
  }
  prepopulation(id) {
    this.spinner.show();
    this.apimicroservice.getCustomHADataById(id).subscribe(res => {
      this.spinner.hide();
    
      this.submitButton=false;
       this.updateButton =true; 
      //  this.deleteButton= true;
      this.cancelButton=true; 
       this.isPrepopulate = true;
       let data = res.data[0];
       this.onSelectPatient(data.patientRefId); 
       this.getCompany(data.companyRefId);
       //
       let pName; 
      if(data.patientName)
      {
      pName = data.patientName;
      }else{
        pName = data.firstName + ' ' + data.lastName;
      }
       this.customHaForm = this.fb.group({
 
        "clinicRefId": new FormControl(data.clinicRefId),
        "branchRefId": new FormControl(data.branchRefId),
        "billingRefId": new FormControl(data.billingRefId,Validators.required),
        "branchWiseId": new FormControl(data.branchWiseId),
        "branchName": new FormControl(data.branchName),
        "patientRefId":new FormControl(data.patientRefId,Validators.required),
        "firstName" :new FormControl(data.firstName),
        "lastName" :new FormControl(data.lastName),
        "mobile1":new FormControl(data.mobile1,[Validators.pattern("(0|91)?[6-9][0-9]{9}")]),
    //"streetAddress": new FormControl(data.streetAddress),
      "patientUniqueId" :new FormControl(data.patientUniqueId),
      "age" :new FormControl(data.age),
      "right250HZ":new FormControl(data.right250HZ,Validators.pattern("^[0-9]*$")),
      "left250HZ":new FormControl(data.left250HZ,Validators.pattern("^[0-9]*$")),
      "right500HZ":new FormControl(data.right500HZ,Validators.pattern("^[0-9]*$")),
      "left500HZ":new FormControl(data.left500HZ,Validators.pattern("^[0-9]*$")),
      "right1KHZ":new FormControl(data.right1KHZ,Validators.pattern("^[0-9]*$")),
      "left1KHZ":new FormControl(data.left1KHZ,Validators.pattern("^[0-9]*$")),
      "right3KHZ":new FormControl(data.right3KHZ,Validators.pattern("^[0-9]*$")),
      "left3KHZ":new FormControl(data.left3KHZ,Validators.pattern("^[0-9]*$")),
      "right4KHZ":new FormControl(data.right4KHZ,Validators.pattern("^[0-9]*$")),
      "left4KHZ":new FormControl(data.left4KHZ,Validators.pattern("^[0-9]*$")),
      "right6KHZ":new FormControl(data.right6KHZ,Validators.pattern("^[0-9]*$")),
      "left6KHZ":new FormControl(data.left6KHZ,Validators.pattern("^[0-9]*$")),
      "right8KHZ":new FormControl(data.right8KHZ,Validators.pattern("^[0-9]*$")),
      "left8KHZ":new FormControl(data.left8KHZ,Validators.pattern("^[0-9]*$")),
      "leftModel" : new FormControl(data.leftModel), 
      "rightModel" : new FormControl(data.rightModel), 
      "leftMake" : new FormControl(data.leftMake),
      "rightMake" : new FormControl(data.rightMake),
      "leftStyle" : new FormControl(data.leftStyle),
      "rightStyle" : new FormControl(data.rightStyle),
      "leftBattery" : new FormControl(data.leftBattery),
      "rightBattery" : new FormControl(data.rightBattery),
      "ear" : new FormControl(data.ear,Validators.required),
      "leftShell" : new FormControl(data.leftShell),
      "rightShell" : new FormControl(data.rightShell),
      "leftFacePlate" : new FormControl(data.leftFacePlate),
      "rightFacePlate" : new FormControl(data.rightFacePlate),
      "leftVent" : new FormControl(data.leftVent),
      "rightVent" : new FormControl(data.rightVent),
      "leftMatrix" : new FormControl(data.leftMatrix),
      "rightMatrix" : new FormControl(data.rightMatrix),
      "leftRemovalString" : new FormControl(data.leftRemovalString),
      "rightRemovalString" : new FormControl(data.rightRemovalString),
      "leftProgramSwitch":new FormControl(data.leftProgramSwitch),
      "rightProgramSwitch":new FormControl(data.rightProgramSwitch),
      "leftVolumeControl":new FormControl(data.leftVolumeControl),
      "rightVolumeControl":new FormControl(data.rightVolumeControl),
      //"estimateRequired":new FormControl(data.estimateRequired),
      "complaint":new FormControl(data.complaint),
      "audiogram":new FormControl(data.audiogram), 
      "audiogramFile":new FormControl(data.audiogramFile),
     "uploadFileName":new FormControl(data.uploadFileName),
      //"earImpression":new FormControl(data.earImpression),
      "earImpressionValue":new FormControl(data.earImpressionValue,[Validators.pattern("^[0-9 ]*$"),Validators.maxLength(10)]),
      "billingCompanyName":new FormControl(data.billingCompanyName),

      "companyRefId": new FormControl(data.companyRefId,Validators.required),
      "companyName": new FormControl(data.companyName),
      "companyAddress": new FormControl(data.companyAddress),
      "companyEmail": new FormControl(data.companyEmail),
     "canNumber":new FormControl(data.canNumber),
     "make":new FormControl(data.make),
     "date":new FormControl(''),
     "leftDeviceRefId":new FormControl(data.leftDeviceRefId),
     "rightDeviceRefId":new FormControl(data.rightDeviceRefId),
        "updatedAt": this.date, 
        "searchCustomHA": new FormControl(''),
        "patientName": new FormControl(pName),
      });
      let latest_date = this.datepipe.transform(data.createdAt,'dd/MM/yyyy');
      this.customHaForm.get("date").setValue(latest_date);

      this.customHaForm.controls["uploadFileName"].setValue(data.uploadFileName);
        this.billCompanyName = data.billingCompanyName;
         this.HaId = id;
         this.superBranchId = data.branchWiseId;
         this.bID=data.branchWiseId;
         
         //
         //
         
          // this.audiogramFile = data.audiogramFile ? data.audiogramFile : "../../assets/images/setting2.png";
          // this.uploadFileName = data.audiogramFile ? data.audiogramFile : 'no-data'; 

          this.audiogramFile = data.audiogramFile ? data.audiogramFile : "../../assets/images/setting2.png";
          this.uploadFileName = data.uploadFileName;
          this.hide = true;
        //  this.myLocationimage = data.locationImage ? data.locationImage : "../../assets/images/no-image-available.png";
     });

    
  }
  addNew() {
    this.customHaForm.reset();
    this.updateButton = false;
    this.deleteButton = false;
  }
  updateCustomHA() {
    this.spinner.show();
    let payload = this.customHaForm.value;
    payload.audiogramFile = this.audiogramFile;
    payload.fileName = this.uploadFileName;
    if(this.userType !== 'Super Admin')
    {
      payload.branchRefId = this.branchRef;
      payload.clinicRefId = this.clinicRef;
      payload.branchName = this.branchName;
    }
    // payload.image = this.myimage;
    this.apimicroservice.updateCustomHAData(this.HaId,payload).subscribe(res => {
      this.spinner.hide();
      this.ngOnInit();
      if (res.status) {
        this.apimicroservice.openSnackbar(res.message);
        this.submitButton=true;
      this.updateButton = false;
      this.cancelButton=false;
      // this.deleteButton=false;
      //this.ngOnInit();
      // this.customHaForm.reset();
      // this.fetchInquiry();
      
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
      
    },
    );
    
  }

   getLastId()
   { 
    if(this.userType !== 'Super Admin'){
      this.apimicroservice.getLastCustomHAID(this.branchRef).subscribe(res => {
        //   
        if (res['status']) { 
          let HAIdd = res['data']['totalItems'];
          this.customHaForm.controls['branchWiseId'].setValue(HAIdd + 1);
          this.bID = HAIdd + 1
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
       
      })
    }
    else 
    {
      this.apimicroservice.getLastCustomHAAll().subscribe(res => {
        //   
        // if(res['data']['data'] == '')
        // {
        //   this.patientMasterForm.controls['branchWiseId'].setValue('1');
        // }
        if (res['status']) {
          let HAIdd = res['data']['totalItems'];
          this.superBranchId = HAIdd + 1;
          this.bID=HAIdd + 1;
          //this.patientMasterForm.controls['branchWiseId'].setValue(clientIdd + 1);
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
       
      })
    }
   }
 

  onPageChange(event) {
    //this.spinner.show();
    this.page = event;
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    //pg = this.page;
    this.searchText = this.customHaForm.get('searchCustomHA').value;
    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getCustomHA(this.page,this.size,this.searchText,branchRef,clinicRef).subscribe(res => {
      //  this.spinner.hide();
        if (res.status) {
          this.listofCustomHA = res.data.data;
          this.totalLength=res.data.totalItems;
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
  
      })
    } else {
      this.apimicroservice.getCustomHA(this.page,this.size,this.searchText,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
       // this.spinner.hide();
        if (res.status) {
          this.listofCustomHA = res.data.data;
          this.totalLength=res.data.totalItems;
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
  
      })
      
    }
   
  }
  onKeyUpEvent(event: any) {
    // let pg = 1;

    // let size=5;
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    let page=1;
    let size=10;

    this.searchText = this.customHaForm.get('searchCustomHA').value;
    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getCustomHA(page,size,this.searchText,branchRef,clinicRef).subscribe(res => {
      
        if (res.status) {
          this.listofCustomHA = res.data.data;
          this.totalLength=res.data.totalItems;
          this.page=res.data.pageNumber;
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
  
      })
    } else {
      this.apimicroservice.getCustomHA(page,size,this.searchText,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
      
        if (res.status) {
          this.listofCustomHA = res.data.data;
          this.totalLength=res.data.totalItems;
          this.page=res.data.pageNumber;
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
  
      })
    }
   
  }

  companyClick(bid,bcname){
    this.billingCompany = bcname;
    this.customHaForm.controls['billingCompanyName'].setValue(this.billingCompany);
  }
  fetchBillingCompanyList(){
    let id=''; 
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getBillingDataById(id,clinicRef).subscribe(res => {
      //
      this.billingCompanyList = res.data;
      this.filteredListBillingCompany= this.billingCompanyList.slice();
      let lenght = res.data.length-1;  
      this.companyClick(res.data[lenght]._id,res.data[lenght].companyName);
      this.customHaForm.get("billingRefId").setValue(res.data[lenght]._id);
      if(res.status){
        //this.apimicroservice.openSnackbar(res.message);
      }else{ 
        //this.apimicroservice.openSnackbar(res.message);
      }
    });
  }
  // fetchCompanyList(){
  //   this.apimicroservice.getAllCompanylist().subscribe(res => {
  //     //
  //      if (res.status) {    
  //        this.companyList = res.data.data;
  //        this.filteredListCompany= this.companyList.slice();
  //       //  this.onSelectCompany(this.companyList[0].companyName,this.companyList[0].companyAddress,this.companyList[0].companyEmail,this.companyList[0].canNumber);
        
  //       //  this.customHaForm.get("companyRefId").setValue(res.data.data[0]._id);
  //      } else {
  //      //  this.apimicroservice.openSnackbar(res.message);
  //      }
  //    })
  // }
  
  fetchCompanyList() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    this.apimicroservice.getAllCompanylist(branchRef,clinicRef).subscribe(res => {
      
      if (res.status) {
        this.companyList = res.data.data;  
        this.filteredListCompany = this.companyList.slice();
        let lenght = res.data.totalItems-1; 
        
        this.customHaForm.controls['companyRefId'].setValue(res.data.data[lenght]._id);
        this.onSelectCompany(res.data.data[lenght].companyName,res.data.data[lenght].companyAddress,
          res.data.data[lenght].companyEmail,res.data.data[lenght].canNumber,res.data.data[lenght].make);

      } else {
        //  this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
  onSelectCompany(cname,address,email,canNumber,make){
    //
    
    this.companyName = cname;
    this.address = address;
    this.email = email;
    this.canNumber = canNumber;
     this.make=make;
    this.customHaForm.controls['companyName'].setValue(this.companyName);
    this.customHaForm.controls['companyAddress'].setValue(this.address);
    this.customHaForm.controls['companyEmail'].setValue(this.email);
    this.customHaForm.controls['canNumber'].setValue(this.canNumber);
    this.customHaForm.controls['make'].setValue(this.make);
   
    } 
    getCompany(id) {
      this.spinner.show();
      this.apimicroservice.getCompanyById(id).subscribe(res => {
        this.spinner.hide();
        if (res.status) {
          let data=res.data[0];
          this.onSelectCompany(data.companyName,data.companyAddress,data.companyEmail,data.canNumber,data.make);
        } else {
        //  this.apimicroservice.openSnackbar(res.message);
        }
      }); 
    }
  redirect(to): void {
    this.router.navigate([to]);
  }
   
  cancelUpdate(){
    this.customHaForm.reset();
    this.submitButton=true;
    this.updateButton=false;
    this.cancelButton=false;
    this.ngOnInit();
  }

  toCustomHAForm() {
    document.getElementById("toCustomHAForm").scrollIntoView();
  }
  toCustomHAList() {
    document.getElementById("toCustomHAList").scrollIntoView();
  }
  fetchPatientList(){
    this.searchText = '';
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    //
    if(this.userType !== 'Super Admin')
    {
      this.apimicroservice.getPatientListByBranchClinic(branchRef,clinicRef).subscribe(res => {
        //
        
        if (res.status) {    
          this.patientList = res.data;
          this.filteredListPatient = this.patientList.slice();
        } else {
         // this.apimicroservice.openSnackbar(res.message);
        }
      })
    }
    else
    {
      this.apimicroservice.getPatientListByBranchClinic(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
        //
        
        if (res.status) {    
          this.patientList = res.data;
          this.filteredListPatient = this.patientList.slice();
        } else {
         // this.apimicroservice.openSnackbar(res.message);
        }
      })
   }
   
  } 
  onSelectPatient(id){
    this.patientSelect=true;
    this.apimicroservice.getPatientById(id).subscribe(res => {
      let data = res.data[0];
      this.pid = id;
      this.customHaForm.controls["firstName"].setValue(data.firstName);
      this.customHaForm.controls["lastName"].setValue(data.lastName);
      this.customHaForm.controls["patientUniqueId"].setValue(data.patientId);
      this.customHaForm.controls["mobile1"].setValue(data.mobile1);
     // this.customHaForm.controls["streetAddress"].setValue(data.streetAddress);

        if (data.dob) {
          var timeDiff = Math.abs(Date.now() - new Date(data.dob).getTime());
          let age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
          //
          this.customHaForm.controls["age"].setValue(age);
            }
        
      //this.customHaForm.controls["age"].setValue(data.streetAddress);
    });

  }

 
  openBranchPopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpBranchComponent,
      {
        width: "1200px",
      });

      dialogRef3.afterClosed().subscribe((result) => {
        let clinicRef= sessionStorage.getItem('clinicRef');
        this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
          //  
          if (res.status) {    
            this.BranchList = res.data;
            this.filteredListBranch= this.BranchList.slice();
            
        let length = res.data.length - 1;
        this.customHaForm.controls["branchRefId"].setValue(res.data[length]._id);
     
            this.branchClick(res.data[length]._id,res.data[length].branchName);
           // this.customHaForm.get("branchRefId").setValue(res.data[0]._id);
          } else {
         //   this.apimicroservice.openSnackbar(res.message);
          }
        });
        // this.fetchBranchList();  
       });
  }

  openPatientPopUp() {
     
    const dialogRef3 = this.dialog.open(PopUpPatientComponent,
      {
        width: "1200px",
      });
      dialogRef3.afterClosed().subscribe((result) => {
        let clinicRef= sessionStorage.getItem('clinicRef');
        let branchRef= sessionStorage.getItem('branchRef');
        this.apimicroservice.getPatientListByBranchClinic(branchRef,clinicRef).subscribe(res => {
          //
          
          if (res.status) {    
            this.patientList = res.data;
            this.filteredListPatient = this.patientList.slice();
             this.onSelectPatient(res.data[0]._id);
             //
             
             this.customHaForm.get("patientRefId").setValue(res.data[0]._id);
          } else {
           // this.apimicroservice.openSnackbar(res.message);
          }
        })

         //  this.onSelectPatient(this.patientList[0]._id);
        //  this.customHaForm.get("patientRefId").setValue(this.patientList[0]._id);
       });
  } 
  
  openBillingCompPopUp(){
    const dialogRef3 = this.dialog.open(PopupBillingcompanyComponent,
      {
        width: "1200px",
      });
  
      dialogRef3.afterClosed().subscribe((result) => {
        let id='';
        let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getBillingDataById(id,clinicRef).subscribe(res => {
     
      if(res.status){
        //
        this.billingCompanyList = res.data;
        this.filteredListBillingCompany= this.billingCompanyList.slice();
        this.companyClick(res.data[0]._id,res.data[0].companyName);
        this.customHaForm.get("billingRefId").setValue(res.data[0]._id);
      }
    });
       // this.fetchBillingCompanyList();  
       });
  }


  openCompanyPopUp(){
    const dialogRef3 = this.dialog.open(PopupCompanyComponent,
      {
        width: "900px",
      });
  
      dialogRef3.afterClosed().subscribe((result) => {
        let clinicRef= sessionStorage.getItem('clinicRef');
        let branchRef= sessionStorage.getItem('branchRef');
        this.apimicroservice.getAllCompanylist(branchRef,clinicRef).subscribe(res => {
          //
           if (res.status) {    
             this.companyList = res.data.data;
             this.filteredListCompany= this.companyList.slice();
             this.onSelectCompany(this.companyList[0].companyName,this.companyList[0].companyAddress,this.companyList[0].companyEmail,this.companyList[0].canNumber,this.companyList[0].make);
            
             this.customHaForm.get("companyRefId").setValue(res.data.data[0]._id);
           } else {
           //  this.apimicroservice.openSnackbar(res.message);
           }
         })
       });
  }
uploadFileName = "";
hide = false;
  onChange($event: Event) {
    const file = ($event.target as HTMLInputElement).files[0];
    let fileName = file.name;
    //
  this.customHaForm.controls["uploadFileName"].setValue(fileName);
  this.errormessage = '';
  let ext = fileName.substring(fileName.lastIndexOf(".") + 1);
  if (
    ext.toLowerCase() == "png" ||
    ext.toLowerCase() == "jpg" ||
    ext.toLowerCase() == "jpeg" ||
    ext.toLowerCase() == "csv" ||
    ext.toLowerCase() == "xlsx" ||
    ext.toLowerCase() == "pdf" ||
    ext.toLowerCase() == "docx" 
  ) { 
    this.convertToBase64(file);
    this.hide = true;
    this.uploadFileName = fileName;
  }
  fileName= '';
  }
  convertToBase64(file: File) {
    const observable = new Observable((subscriber: Subscriber<any>) => {
      this.readFile(file, subscriber);
    });
    observable.subscribe((d) => {
      this.audiogramFile = d;
      
    });
  }
  readFile(file: File, subscriber: Subscriber<any>) {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      subscriber.next(fileReader.result);
      subscriber.complete();
    };
    fileReader.onerror = (error) => {
      subscriber.error(error);
      subscriber.complete();
    }; 
  }


  copyToRight(){
    
    //
    let payload = this.customHaForm.value;
  
    this.customHaForm.controls["rightModel"].setValue(payload.leftModel);
    this.customHaForm.controls["rightMake"].setValue(payload.leftMake);
    this.customHaForm.controls["rightStyle"].setValue(payload.leftStyle);
   
    this.customHaForm.controls["rightBattery"].setValue(payload.leftBattery);
    this.customHaForm.controls["rightShell"].setValue(payload.leftShell);

    this.customHaForm.controls["rightFacePlate"].setValue(payload.leftFacePlate);
    this.customHaForm.controls["rightVent"].setValue(payload.leftVent);
    this.customHaForm.controls["rightMatrix"].setValue(payload.leftMatrix);
    this.customHaForm.controls["rightRemovalString"].setValue(payload.leftRemovalString);
    this.customHaForm.controls["rightProgramSwitch"].setValue(payload.leftProgramSwitch);
    this.customHaForm.controls["rightVolumeControl"].setValue(payload.leftVolumeControl);
  
  }

  leftReset(){ 
    this.customHaForm.controls["leftModel"].reset();
  this.customHaForm.controls["leftMake"].reset();
  this.customHaForm.controls["leftStyle"].reset();
  this.customHaForm.controls["leftBattery"].reset();
  this.customHaForm.controls["leftShell"].reset();
  this.customHaForm.controls["leftFacePlate"].reset();
  this.customHaForm.controls["leftVent"].reset();
  this.customHaForm.controls["leftMatrix"].reset();
  this.customHaForm.controls["leftRemovalString"].reset();
  this.customHaForm.controls["leftProgramSwitch"].reset();
  this.customHaForm.controls["leftVolumeControl"].reset();
  
  
  
  }


  copyToLeft(){
    
    //
    let payload = this.customHaForm.value;
  
    this.customHaForm.controls["leftModel"].setValue(payload.rightModel);
    this.customHaForm.controls["leftMake"].setValue(payload.rightMake);
    this.customHaForm.controls["leftStyle"].setValue(payload.rightStyle);
   
    this.customHaForm.controls["leftBattery"].setValue(payload.rightBattery);
    this.customHaForm.controls["leftShell"].setValue(payload.rightShell);

    this.customHaForm.controls["leftFacePlate"].setValue(payload.rightFacePlate);
    this.customHaForm.controls["leftVent"].setValue(payload.rightVent);
    this.customHaForm.controls["leftMatrix"].setValue(payload.rightMatrix);
    this.customHaForm.controls["leftRemovalString"].setValue(payload.rightRemovalString);
    this.customHaForm.controls["leftProgramSwitch"].setValue(payload.rightProgramSwitch);
    this.customHaForm.controls["leftVolumeControl"].setValue(payload.rightVolumeControl);
  
  }


  rightReset(){ 
    this.customHaForm.controls["rightModel"].reset();
  this.customHaForm.controls["rightMake"].reset();
  this.customHaForm.controls["rightStyle"].reset();
  this.customHaForm.controls["rightBattery"].reset();
  this.customHaForm.controls["rightShell"].reset();
  this.customHaForm.controls["rightFacePlate"].reset();
  this.customHaForm.controls["rightVent"].reset();
  this.customHaForm.controls["rightMatrix"].reset();
  this.customHaForm.controls["rightRemovalString"].reset();
  this.customHaForm.controls["rightProgramSwitch"].reset();
  this.customHaForm.controls["rightVolumeControl"].reset(); 
  }
  global() {
    this.f1().then(res => {
        this.f2()
    })
  }
  
  f1() {
    return new Promise<any>((resolve, reject) => {
         this.isPrint=true;
      //
  let x =1;
        setTimeout( () => {
            resolve(x);
        }, 100);
        
    });
    
  }
  
  f2() {
   
    let currentDate =new Date();
let date=currentDate.toLocaleDateString('en-GB');
    let titleName='Custom HA Form_'+date
    var tempTitle = document.title;
    document.title = titleName;
    window.print();
    document.title = tempTitle;
    this.isPrint = false;
  }
//   globalPrint() {
//     this.isPrint = true;
//     let currentDate =new Date();
// let date=currentDate.toLocaleDateString('en-GB');
//     let titleName='Custom HA Form_'+date
//     var tempTitle = document.title;
//     document.title = titleName;
//     window.print();
//     document.title = tempTitle;
//   } 
  
onEarChange(e){
 let payload = this.customHaForm.value;
 //
 
 if(e == 'Right')
 {
   this.RightDisable = false;
   this.LeftDisable = true;
 }
 else if(e == 'Left')
 {
   this.LeftDisable = false;
   this.RightDisable = true;
 }
 else
 {
   this.RightDisable = false;
   this.LeftDisable = false;
 }
}

WhatsApp()
  {
    let payload = this.customHaForm.value;  
    let num = payload.mobile1; 
    if(num.length == 10)
    {
      sessionStorage.setItem('whatsAppNumber',payload.mobile1);
      let mobileNumber = sessionStorage.getItem('whatsAppNumber');
      window.open("https://wa.me/91"+mobileNumber);
    }
    else
    {
      this.customHaForm.get("mobile1").markAsTouched();
    }
  }
  onback() {
    this.apimicroservice.back()
  }

  onLeftModelChange(event,id,brand){
    //
    
    this.customHaForm.controls["leftDeviceRefId"].setValue(id);
    this.customHaForm.controls["leftMake"].setValue(brand);
    let str = event.target.value;
    let leftModel = event.target.value;
    //let leftSerialNo = this.repairComplaintForm.get('leftSrNo').value;
  }

  onRightModelChange(event,id,brand){
    this.customHaForm.controls["rightDeviceRefId"].setValue(id);
    this.customHaForm.controls["rightMake"].setValue(brand);
     let str = event.target.value;
     let rightModel = event.target.value;
      //let rightSerialNo = this.repairComplaintForm.get('rightSrNo').value;
  }

  fetchModelList() {
    let branchRef= sessionStorage.getItem('branchRef');
    if(this.userType !== 'Super Admin')
    {
      this.apimicroservice.getAllDeviceList(branchRef).subscribe(res => {
        if (res.status) {    
          this.DeviceList = res.data.data;
          this.filteredListDevice= this.DeviceList.slice();
        } else {
          //this.apimicroservice.openSnackbar(res.message);
        }
      })
    }
    else
    {
      this.apimicroservice.getAllDeviceList(branchRef).subscribe(res => {
        if (res.status) {    
          this.DeviceList = res.data.data;
          this.filteredListDevice= this.DeviceList.slice();
        } else {
        // this.apimicroservice.openSnackbar(res.message);
        }
      })
    }
   
  } 

  fetchModelList2() {
    let branchRef= sessionStorage.getItem('branchRef');
    if(this.userType !== 'Super Admin')
    {
      this.apimicroservice.getAllDeviceList(branchRef).subscribe(res => {
        if (res.status) {    
          this.DeviceList2 = res.data.data;
          this.filteredListDevice2= this.DeviceList2.slice();
        } else {
         // this.apimicroservice.openSnackbar(res.message);
        }
      })
    }
    else
    {
      this.apimicroservice.getAllDeviceList(branchRef).subscribe(res => {
        if (res.status) {    
          this.DeviceList2 = res.data.data;
          this.filteredListDevice2= this.DeviceList2.slice();
        } else {
          //this.apimicroservice.openSnackbar(res.message);
        }
      })
    }
   
  }
  openDepartmentPopUp() {
    const dialogRef3 = this.dialog.open(PatientSearchPopComponent, {
      width: "97%",
      height: "90%",  
    });
 
    dialogRef3.afterClosed().subscribe((result) => {
      this.customHaForm.get("patientName").setValue(result.data.firstName + ' ' + result.data.lastName);
      this.customHaForm.get("patientRefId").setValue(result.data._id);
      this.onSelectPatient(result.data._id);
      
    });
  }


  fetchFaceplateList() {
    this.apimicroservice.getAllFaceplateList().subscribe(res => {
      //  
      if (res.status) {    
        this.faceplateList = res.data;
        this.filteredListfaceplate= this.faceplateList.slice();
      } else {
     //   this.apimicroservice.openSnackbar(res.message);
      }
    })
  } 

  openFaceplatePopUpLeft() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(FaceplateComponent,
      {
        width: "400px",
      }); 

      dialogRef3.afterClosed().subscribe((result) => {
        this.apimicroservice.getAllFaceplateList().subscribe(res => {
          //  
          if (res.status) {    
            this.faceplateList = res.data;
            this.filteredListfaceplate= this.faceplateList.slice();
           // this.faceplateClick(res.data[0]._id,res.data[0].branchName);
            this.customHaForm.get("leftFacePlate").setValue(res.data[0]._id);
          } else {
         //   this.apimicroservice.openSnackbar(res.message);
          }
        })
        // this.fetchBranchList();  
       });
  }
  openFaceplatePopUpRight() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(FaceplateComponent,
      {
        width: "400px",
      });

      dialogRef3.afterClosed().subscribe((result) => {
        this.apimicroservice.getAllFaceplateList().subscribe(res => {
          //  
          if (res.status) {    
            this.faceplateList = res.data;
            this.filteredListfaceplate= this.faceplateList.slice();
           // this.faceplateClick(res.data[0]._id,res.data[0].branchName);
            this.customHaForm.get("rightFacePlate").setValue(res.data[0]._id);
          } else {
         //   this.apimicroservice.openSnackbar(res.message);
          }
        })
        // this.fetchBranchList();  
       }); 
  }
  // faceplateClick(bid,fpname){
  //   this.faceplateName = fpname;
  //   // this.customHaForm.controls['rightFacePlate'].setValue(this.faceplateName);
  //   // this.customHaForm.controls['leftFacePlate'].setValue(this.faceplateName);
  // }

  fetchShellList() {
    this.apimicroservice.getAllShellList().subscribe(res => {
      //  
      if (res.status) {    
        this.shellList = res.data;
        this.filteredListshell= this.shellList.slice();
      } else {
     //   this.apimicroservice.openSnackbar(res.message);
      }
    })
  } 
  
  openShellPopUpLeft() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpShellComponent,
      {
        width: "400px",
      });

      dialogRef3.afterClosed().subscribe((result) => {
        this.apimicroservice.getAllShellList().subscribe(res => {
          //  
          if (res.status) {    
            this.shellList = res.data;
            this.filteredListshell= this.shellList.slice();
            this.shellClick(res.data[0]._id,res.data[0].branchName);
            this.customHaForm.get("leftShell").setValue(res.data[0]._id);
          } else {
         //   this.apimicroservice.openSnackbar(res.message);
          }
        })
        // this.fetchBranchList();  
       });
  }
  openShellPopUpRight() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpShellComponent,
      {
        width: "400px",
      });

      dialogRef3.afterClosed().subscribe((result) => {
        this.apimicroservice.getAllShellList().subscribe(res => {
          //  
          if (res.status) {    
            this.shellList = res.data;
            this.filteredListshell= this.shellList.slice();
            this.shellClick(res.data[0]._id,res.data[0].branchName);
            this.customHaForm.get("leftShell").setValue(res.data[0]._id);
          } else {
         //   this.apimicroservice.openSnackbar(res.message);
          }
        })
        // this.fetchBranchList();  
       });
  }
  shellClick(bid,sname){
    this.shellName = sname;
    this.customHaForm.controls['leftShell'].setValue(this.shellName);
  }
  printForm() {
    this.router.navigate(['custom-ha-print',this.HaId]);
   }
   showbase64(e){
    const dialogRef3 = this.dialog.open(PdfviewerComponent,
      {
        width: "1200px",
        data: e
      });
  }
  remove(){
    this.hide = false;
  }
}