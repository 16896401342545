import { Component, Inject, OnInit } from "@angular/core";
import { ApiServiceService } from '../api-service.service';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import * as moment from 'moment';

@Component({ 
  selector: 'app-popup-cancel-confirm',
  templateUrl: './popup-cancel-confirm.component.html',
  styleUrls: ['./popup-cancel-confirm.component.scss']
})
export class PopupCancelConfirmComponent implements OnInit {
  appointRefId: any; 
  appointDate: any;
  patientName = '';
  appDate: any;
  appTime: any; 

  constructor(public dialogRef: MatDialogRef<PopupCancelConfirmComponent>,@Inject(MAT_DIALOG_DATA) public data:any,
  public apimicroservice: ApiServiceService,private spinner: NgxSpinnerService,) { 
    this.appointRefId = data.appointRefId;
    this.patientName = data.patientName;
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.appointDate = new Date(this.data.appointDate);
    this.patientName = this.patientName.charAt(0).toUpperCase() + this.patientName.slice(1);
     let tt = this.appointDate;
     tt = moment(this.appointDate);
     this.appDate = tt.format("MMMM Do YYYY");
     this.appTime = tt.format("LT");
     //);
     //);
    //
    //
    //
  }
  confirmSubmit(){
    this.spinner.show();
    this.apimicroservice.deleteAppointmentData(this.appointRefId).subscribe(res => {
        this.spinner.hide();
        
        if (res.status) {
        this.apimicroservice.openSnackbar("Appointment Cancelled Successfully!");
          //this.ngOnInit();
          this.onClose(true);
   
        } else {
         // this.apimicroservice.openSnackbar(res.message);
        }
      }); 
  }

  confirmCancel(){
   this.onClose(false);
  }

  onClose(e): void {
    this.dialogRef.close(e);
  }
}
