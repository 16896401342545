<div class="container-fluid " [formGroup]="earMouldNewForm">
    <form [formGroup]="earMouldNewForm" autocomplete="off" novalidate>
    <div class="row">
        <div class="col-sm-1">

        </div>
        <div class="col-sm-11"> 
            <div class="row">
                <div class="col-sm-10"> 
                    <div class="row ">

                        <span><img src="../../assets/contact-form.png" width="35rem"> </span> 
                        <span> <h1 class="fass ml-3 pt-1" id="toearMouldNewForm">Earmold Order Form</h1></span>
                      </div>
                   
                    </div>
                    <div class="col-sm-2 " >
                      <div class="row justify-content-end mr-1 ">
                        <img matTooltip="Back" class="mb-2 pb-2" width=35rem src="../../assets/back.png" (click)="onback()">
                      <img src="../../assets/list.png"  (click)="toearMouldNewFormList()" class="mb-2 pb-2" matTooltip="List" width="35rem">
                     </div>
                     </div>
              </div>
            <div class="row mr-1">
              <div class="col-sm-12 mb-2  mr-0 pr-0">
                
                    <div class="card divbody boxsssss mb-3 borderradius p-2">  
                      <div class="row p-1">
                          <div class=" col-sm-2">
                            <!-- <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Repair Id</mat-label>
                              <input matInput formControlName="repairId" readonly  />   
                            </mat-form-field> -->
                            <mat-form-field *ngIf="userType !== 'Super Admin'" class="example-full-width" appearance="outline">
                              <mat-label>Ear Mould Order Id</mat-label>
                              <input matInput formControlName="branchWiseId" />   
                            </mat-form-field>
                            <mat-form-field *ngIf="userType == 'Super Admin'" class="example-full-width" appearance="outline">
                              <mat-label>Ear Mould Order Id</mat-label>
                              <input matInput [value]="superBranchId" readonly  />
                             
                            </mat-form-field>
                            </div>
                          
                            <div class=" col-sm-3">
                              <div class="row">
                                <div class="col-sm-10 pr-1">
                                  <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>Select Can Number </mat-label>
                                    <mat-select #select2 (click)="fetchCanNumberList();" formControlName="accountNumber">
                                      <mat-select-filter *ngIf="select2.focused" [placeholder]="'Filter'" [displayMember]="'canNumber'"
                                      [array]="canNumberList"  (filteredReturn)="filteredListCanNumber =$event"></mat-select-filter>    
                                      <mat-option *ngFor="let item of filteredListCanNumber" [value]="item.canNumber"
                                    >
                                     {{ item.canNumber }}</mat-option>
                                    </mat-select>
                                  </mat-form-field>

                              <!-- <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Can No</mat-label>
                                <input matInput formControlName="accountNumber"/>   
                              </mat-form-field> -->
                              </div>
                              <div class="col-sm-2 mTop">
                                <img (click)="openEarMouldPopUp();" matTooltip="Add Can Number" width="25" src="../../assets/add_img.png"> 
                              </div> 
                              </div>
                              </div>
                            
                            <div class="col-sm-3">
                              <div class="row">
                                <div class="col-sm-10 pr-1">
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>PO</mat-label>
                                    <input matInput formControlName="poNumber"/>   
                                  </mat-form-field>
                                  </div>
                                  </div>
                            </div>
                            <div class="col-sm-1"></div>
                          <div class="col-sm-3 close_style justify-content-end mt-2">
                            <label class="pt-2 close_style justify-content-end lable_style">Date : </label>
                            <label class="pt-2 close_style justify-content-end lable_style2">{{date | date:'dd/MM/yyyy'}}</label>
                          </div>
                          
                          
                        </div>
                    
                        <div class="row p-1">
                          <div class="col-sm-3">
                            <label class="lable_style pt-2 header">Patient Information :</label>
                          </div>
                          <div class="row col-sm-12">
                            <div  *ngIf="userType == 'Super Admin'" class="col-sm-3 ">
                              <div class="row">
                                <div class="col-sm-10 pr-1"> 
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Branch 
                                <!-- <span class="required-asterisk">*</span> -->
                              </mat-label>  
                              <mat-select #select1 formControlName="branchRefId">
                                <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'branchName'"
                                [array]="BranchList"  (filteredReturn)="filteredListBranch =$event"></mat-select-filter>
                               
                                <mat-option *ngFor="let item of filteredListBranch" [value]="item._id" 
                                (click)="branchClick(item._id,item.branchName);"
                                >
                                 {{ item.branchName }}</mat-option>
                                </mat-select>
                                
                            </mat-form-field> 
                            <mat-error 
                                *ngIf="earMouldNewForm.controls['branchRefId'].touched && earMouldNewForm.controls['branchRefId'].errors?.required">
                                This field is mandatory.
                              </mat-error>
                           </div>
                           <div class="col-sm-2 mTop">
                            <img (click)="openBranchPopUp();" matTooltip="Add New Branch" width="25" src="../../assets/add_img.png"> 
                          </div>

                           </div>

                          </div>
                          <div class="col-sm-3">
                            <div class="row">
                              <div class="col-sm-10 pr-1">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Select Patient </mat-label>
                              <mat-select #select2 (click)="fetchPatientList();" formControlName="patientRefId">
                                <mat-select-filter *ngIf="select2.focused" [placeholder]="'Filter'" [displayMember]="'firstName'"
                                [array]="patientList"  (filteredReturn)="filteredListPatient =$event"></mat-select-filter>    
                                <mat-option  *ngFor="let item of filteredListPatient" [value]="item._id" (click)="onSelectPatient(item._id)"
                              >
                               {{ item.firstName }}</mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-error
                              *ngIf="earMouldNewForm.controls['patientRefId'].touched && earMouldNewForm.controls['patientRefId'].errors?.required">
                              This field is mandatory.
                            </mat-error>
                          </div>
                          <div class="col-sm-2 mTop">
                            <img (click)="openPatientPopUp();" matTooltip="Add New Patient" width="25" src="../../assets/add_img.png"> 
                          </div> 
                       </div>
                       </div>
                       <div class="col-sm-6" *ngIf="userType == 'Super Admin'"></div>
                       <div class="col-sm-9" *ngIf="userType !== 'Super Admin'"></div>
                          <div class="col-sm-3 mt-1"  *ngIf="patientSelect == true"  >
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label> First Name </mat-label>
                              <input matInput placeholder="First Name " formControlName="firstName" readonly />
                              <!-- <mat-error
                              *ngIf="earMouldNewForm.controls['leftSerialNo'].touched && earMouldNewForm.controls['leftSerialNo'].errors?.required">
                              This field is mandatory.
                            </mat-error> -->
                            </mat-form-field>
                          </div>
                          <!-- <div class="col-sm-3" *ngIf="userType == 'Super Admin'"></div> -->
                          <div class="col-sm-3 mt-1" *ngIf="patientSelect == true" >
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>  Last Name</mat-label>
                              <input matInput placeholder="Last Name" formControlName="lastName" readonly />
                              <!-- <mat-error
                              *ngIf="earMouldNewForm.controls['leftSerialNo'].touched && earMouldNewForm.controls['leftSerialNo'].errors?.required">
                              This field is mandatory.
                            </mat-error> --> 
                            </mat-form-field>
                          </div>
                          <!-- <div class="col-sm-4"></div> -->
                          <!-- <div class="col-sm-3" *ngIf="patientSelect == true" >
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>  Gender</mat-label>
                              <input matInput placeholder="sex"  formControlName="sex"  readonly />
                              <mat-error
                              *ngIf="earMouldNewForm.controls['leftSerialNo'].touched && earMouldNewForm.controls['leftSerialNo'].errors?.required">
                              This field is mandatory.
                            </mat-error>
                            </mat-form-field>
                          </div> -->
                          <div class="col-sm-3 mt-1" *ngIf="patientSelect == true" >
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label> Mobile No </mat-label>
                              <input matInput placeholder="mobileNo" maxlength="10" formControlName="mobile1"  />
                              <mat-error
                              *ngIf="earMouldNewForm.controls['mobile1'].touched && earMouldNewForm.controls['mobile1'].errors?.pattern">
                              Please enter valid Phone Number.
                            </mat-error>
                            </mat-form-field>
                          </div>
                          <!-- <div class="col-sm-4" *ngIf="userType == 'Super Admin'"></div> -->
                          <div class="col-sm-3 mt-1" *ngIf="patientSelect == true" >
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label> Address </mat-label>
                              <!-- <input matInput placeholder="Address" formControlName="streetAddress" readonly /> -->
                              <textarea maxlength="1000" placeholder="Address" readonly matInput formControlName="streetAddress"
                              cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                              cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5" ></textarea>
                            </mat-form-field>
                          </div>
                      </div>
                      
                        </div>
                    </div>
            
            </div>
            <div class="col-sm-6 mr-0 pr-0 mb-2 ">
            
                  <div class="card  divbody boxsssss mb-3 borderradius p-2">  
                      <div class="col-sm-12">
                          <div class="col-sm-12 bg-secondary rounded-3 pl-4 pr-4 mb-3">
                              <div class="row justify-content-center">
                                <h4 class="fass mt-2">Hearing Aid Dispenser :</h4>
                              </div>
                            </div>
                            <div class="row col-sm-12">
                              <mat-form-field class="example-full-width" appearance="outline">
                                <textarea maxlength="1000" placeholder="Hearing Aid Dispenser" matInput formControlName="hearingAidDispenser"
                                cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                cdkAutosizeMinRows="5" cdkAutosizeMaxRows="5" ></textarea>

                       
                            </mat-form-field>
                          </div>
                      </div>
                  </div>
           
          </div>
          <div class="col-sm-6 mr-0 pr-0 mb-2 ">
           
                <div class="card  minCard6 divbody boxsssss mb-3 borderradius p-2">  
                    <div class="col-sm-12">
                        <div class="col-sm-12 bg-secondary rounded-3 pl-4 pr-4 mb-3">
                            <div class="row justify-content-center">
                              <h4 class="fass mt-2">Speciality Items :</h4>
                            </div>
                          </div>
                          <div class="row col-sm-12">
                            <div class="col-sm-4">
                               <mat-checkbox class="example-full-width " formControlName="ER15" >
                                   ER-15
                               </mat-checkbox>
                            </div>
                            <div class="col-sm-4">
                               <mat-checkbox class="example-full-width " formControlName="ER25">
                                   ER-25
                               </mat-checkbox>
                            </div>
                           <div class="col-sm-4">
                               
                                 <mat-checkbox class="example-full-width " formControlName="musicianEarplug">
                                   Musician Ear Plug
                               </mat-checkbox>
                           </div>
                           <div class="col-sm-4">
                               <mat-checkbox class="example-full-width " formControlName="solidEarplugs">
                                   Solid Ear Plugs
                               </mat-checkbox>
                           </div>
                         <div class="col-sm-4">
                           <mat-checkbox class="example-full-width " formControlName="sonicValueEarplug">
                               Sonic Value Earplug
                           </mat-checkbox>
                           </div>
                           <div class="col-sm-4">
                               <mat-checkbox class="example-full-width " formControlName="ipod_Mp3Mold">
                                   IPod,MP3 Mold
                               </mat-checkbox>
                           </div>
                           <div class="col-sm-4">
                               <mat-checkbox class="example-full-width " formControlName="hockNoiseBraker">
                                   Hocks Noise Braker
                               </mat-checkbox>
                           </div>
                       </div>
                    </div>
                </div>
           
        </div>
        <div class="col-sm-6 mr-0 pr-0 mb-2 ">
         
              <div class="card   divbody boxsssss mb-3 borderradius p-2">  
                  <div class="col-sm-12">
                      <div class="col-sm-12 bg-secondary rounded-3 pl-4 pr-4 mb-3">
                          <div class="row justify-content-center">
                            <h4 class="fass mt-2">Custom Earmolds :</h4>
                          </div>
                        </div>
                        <div class="row col-sm-12">
                          <div class="col-sm-4">
                              <mat-checkbox class="example-full-width " formControlName="canal">
                                  Canal
                              </mat-checkbox>
                          </div>
                          <div class="col-sm-4">
                              <mat-checkbox class="example-full-width " formControlName="noa">
                                  NOA (Small)
                              </mat-checkbox>
                          </div>
                          <div class="col-sm-4">
                              <mat-checkbox class="example-full-width " formControlName="listeningTube">
                                  Listening Tube
                              </mat-checkbox>
                          </div>
                          <div class="col-sm-4">
                              <mat-checkbox class="example-full-width " formControlName="standard">
                                  Standard
                              </mat-checkbox>
                          </div>
                          <div class="col-sm-4">
                              <mat-checkbox class="example-full-width " formControlName="skeleton">
                                  Skeleton
                              </mat-checkbox>
                          </div>
                          <div class="col-sm-4">
                              <mat-checkbox class="example-full-width " formControlName="nob">
                                  NOB (Medium)
                              </mat-checkbox>
                          </div>
                          <div class="col-sm-4">
                              <mat-checkbox class="example-full-width " formControlName="customReceiver">
                                  Receiver
                              </mat-checkbox>
                          </div>
                          <div class="col-sm-4">
                              <mat-checkbox class="example-full-width " formControlName="Skeleton_3BY4">
                                  3/4 Skeleton
                              </mat-checkbox>
                          </div>
                          <div class="col-sm-4">
                              <mat-checkbox class="example-full-width " formControlName="headPhoneStareo">
                                  Head Phone Stereo
                              </mat-checkbox>
                          </div>
                          <div class="col-sm-4">
                              <mat-checkbox class="example-full-width " formControlName="totalSeal">
                                  Total Seal
                              </mat-checkbox>
                          </div>
                          <div class="col-sm-4">
                              <mat-checkbox class="example-full-width " formControlName="shell">
                                  Shell
                              </mat-checkbox>
                          </div>
                          <div class="col-sm-4">
                              <mat-checkbox class="example-full-width " formControlName="noc">
                                  NOC (Large)
                              </mat-checkbox>
                          </div>
                          <div class="col-sm-4">
                              <mat-checkbox class="example-full-width " formControlName="totalFlex">
                                  Total Flex
                              </mat-checkbox>
                          </div>
                          <div class="col-sm-4">
                              <mat-checkbox class="example-full-width " formControlName="halfShell">
                                  Half Shell
                              </mat-checkbox> 
                          </div>
                          <div class="col-sm-4">
                              <mat-checkbox class="example-full-width " formControlName="stethosscopeMold">
                                  Stethoscope Mold
                              </mat-checkbox>
                          </div>
                          <div class="col-sm-4">
                              <mat-checkbox class="example-full-width " formControlName="headset">
                                  Headset
                              </mat-checkbox>
                          </div>
                          <div class="col-sm-4">
                              <mat-checkbox class="example-full-width " formControlName="pilotPartner">
                                  Pilot Partner
                              </mat-checkbox>
                          </div>
                          <div class="col-sm-4">
                              <mat-checkbox class="example-full-width " formControlName="canalLock" >
                                  Canal Lock
                              </mat-checkbox>
                          </div>
                          <div class="col-sm-4">
                              <mat-checkbox class="example-full-width " formControlName="soundPort">
                                  Sound Port
                              </mat-checkbox>
                          </div>
                        </div>
                  </div>
              </div>
        
      </div>
      <div class="col-sm-6 mr-0 pr-0 mb-2 ">
       
            <div class="card   divbody boxsssss mb-3 borderradius p-2">  
                <div class="col-sm-12">
                    <div class="col-sm-12 bg-secondary rounded-3 pl-4 pr-4 mb-3">
                        <div class="row justify-content-center">
                          <h4 class="fass mt-2">Custom Earmolds :</h4>
                        </div>
                      </div>
                      <div class="row col-sm-12">
                        <div class="col-sm-4">
                            <mat-checkbox class="example-full-width " formControlName="earPlugs">
                                Ear Plugs
                            </mat-checkbox>
                           
                        </div>
                        <div class="col-sm-4">
                            <mat-checkbox class="example-full-width " formControlName="swimPlugs" >
                                Swim Plugs (Floatable)
                            </mat-checkbox>
                         
                        </div>
                        <div class="col-sm-4">
                            <mat-checkbox class="example-full-width " formControlName="filtered">
                                Filtered
                            </mat-checkbox>
                          
                        </div>
                        <div class="col-sm-4">
                            <mat-checkbox class="example-full-width " formControlName="sleepingPlugs">
                                Sleeping Plugs
                            </mat-checkbox>
                          
                        </div>
                        <div class="col-sm-4">
                            <mat-checkbox class="example-full-width " formControlName="sleepingPlugs">
                                Handle
                            </mat-checkbox>
                          
                        </div>
                        <div class="col-sm-4">
                            <mat-checkbox class="example-full-width " formControlName="noiseReductionPlug">
                                Noise Reduction Plug
                            </mat-checkbox>
                          
                        </div>
                        <div class="col-sm-4">
                            <mat-checkbox class="example-full-width " formControlName="left">
                                Left
                            </mat-checkbox>
                          
                        </div>
                        <div class="col-sm-4">
                            <mat-checkbox class="example-full-width " formControlName="right">
                                Right
                            </mat-checkbox>
                          
                        </div>
                        <div class="col-sm-4">
                            <mat-checkbox class="example-full-width " formControlName="cord">
                                Cord
                            </mat-checkbox>
                          
                        </div>
                      </div>
                </div>
            </div>
     
    </div>
                <div class="col-sm-6 mr-0 pr-0 mb-2  mb-2">
                   
                        <div class="card  divbody boxsssss mb-3 borderradius p-2">  
                            <div class="col-sm-12">
                                <div class="col-sm-12 bg-secondary rounded-3 pl-4 pr-4 mb-3">
                                    <div class="row justify-content-center">
                  
                                      <h4 class="fass mt-2">Custom Earmold Materials </h4>
                  
                                    </div>
                                  </div>
                                  <div class="col-sm-12">
                                    <mat-radio-group formControlName="customEarmoldMaterials" class="example-full-width" aria-label="Select an option">
                                        <div class="row">
                                            <div class="col-sm-4 p-1">
                                        <mat-radio-button value="Lucite">Lucite</mat-radio-button>
                                        </div>
                                        <div class="col-sm-4 p-1">
                                      <mat-radio-button class="ml-3" value="Pink">Pink</mat-radio-button>
                                      </div>
                                      <div class="col-sm-4 p-1">
                                      <mat-radio-button class="ml-3" value="Brown">Brown</mat-radio-button>
                                      </div>
                                      <div class="col-sm-4 p-1">
                                      <mat-radio-button  value="Dark Brown">Dark Brown</mat-radio-button>
                                      </div>
                                      <div class="col-sm-4 p-1">
                                      <mat-radio-button class="ml-3" value="Flex Canal">Flex Canal</mat-radio-button>
                                      </div>
                                     
                                      <div class="col-sm-4 p-1">
                                      <mat-radio-button class="ml-3" value="UV Processing">UV Processing</mat-radio-button>
                                      </div>
                                      <div class="col-sm-4 p-1">
                                      <mat-radio-button value="Proseal(Silicone)">Proseal(Silicone)</mat-radio-button>
                                      </div>
                                      <div class="col-sm-4 p-1">
                                        <mat-radio-button class="ml-3" value="Clear">Clear</mat-radio-button>
                                        </div>
                                      <div class="col-sm-4 p-1">
                                      <mat-radio-button  class="ml-3" value="Bio-Pore Soft & Extra Soft">Bio-Pore Soft & <br> Extra Soft</mat-radio-button>
                                  </div>
                                  </div>
                                    </mat-radio-group>
                                  </div>
                            </div>
                        </div>
                  
                </div>
                <div class="col-sm-6  mb-2 mr-0 pr-0 ">
                  
                        <div class="card divbody boxsssss mb-3 borderradius p-2">  
                            <div class="col-sm-12">
                                <div class="col-sm-12 bg-secondary rounded-3 pl-4 pr-4 mb-3">
                                    <div class="row">
                                        <div class="col-sm-2">
                                      <h4 class="fass mt-2">Venting</h4>
                                        </div>
                                        <div class="col-sm-6"></div>
                                        <div class="col-sm-4">
                                      <h4 class="fass mt-2"> Attachment</h4>
                                    </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                  <div class="col-sm-5">
                                    <mat-radio-group formControlName="venting" class="example-full-width" aria-label="Select an option">
                                        <div class="row">
                                          <div class="col-sm-6 p-1">
                                        <mat-radio-button value="Pressure Vent">Pressure Vent</mat-radio-button>
                                        </div>
                                      <div class="col-sm-6 p-1">
                                      <mat-radio-button class="ml-3" value="Push-Pall(Elbo)">Push-Pall(Elbo)</mat-radio-button>
                                      </div>
                                    <div class="col-sm-6 p-1">
                                      <mat-radio-button value="Small">Small</mat-radio-button>
                                      </div>
                                    <div class="col-sm-6 p-1">
                                      <mat-radio-button class="ml-3" value="Medium">Medium</mat-radio-button>
                                      </div>
                                    <div class="col-sm-6 p-1">
                                      <mat-radio-button value="Glue Tab">Glue Tab</mat-radio-button>
                                      </div>
                                    <div class="col-sm-6 p-1">
                                      <mat-radio-button class="ml-3" value="Large">Large</mat-radio-button>
                                      </div>
                                    <div class="col-sm-6 p-1">
                                      <mat-radio-button value="Thru-Tubes">Thru-Tubes</mat-radio-button>
                                      </div>
                                    <div class="col-sm-6 p-1">
                                        <mat-radio-button class="ml-3" value="Select-A-Vent">Select-A-Vent</mat-radio-button>
                                        </div>
                                      <div class="col-sm-6 p-1">
                                      <mat-radio-button  value="Parallel">Parallel</mat-radio-button>
                                  </div>
                                  </div>
                                    </mat-radio-group>
                                  </div>
                                  <div class="col-sm-1">
                                    <mat-divider vertical class="dividepad divideheight"></mat-divider>
                                  </div>
                                  <div class="col-sm-5">
                                    <mat-radio-group formControlName="attachment" class="example-full-width" aria-label="Select an option">
                                        <div class="row">
                                          <div class="col-sm-6 p-1">
                                        <mat-radio-button value="Lenght __ inches">Lenght __ inches</mat-radio-button>
                                        </div>
                                      <div class="col-sm-6 p-1">
                                      <mat-radio-button class="ml-3" value="3MM Libby">3MM Libby</mat-radio-button>
                                      </div>
                                    <div class="col-sm-6 p-1">
                                      <mat-radio-button value="4MM Libby">4MM Libby</mat-radio-button>
                                      </div>
                                    <div class="col-sm-6 p-1">
                                      <mat-radio-button class="ml-3" value="#12">#12</mat-radio-button>
                                      </div>
                                    <div class="col-sm-6 p-1">
                                      <mat-radio-button value="#16">#16</mat-radio-button>
                                      </div>
                                    <div class="col-sm-6 p-1">
                                      <mat-radio-button class="ml-3" value="#13 reg">#13 reg</mat-radio-button>
                                      </div>
                                    <div class="col-sm-6 p-1">
                                      <mat-radio-button value="#13 med">#13 med</mat-radio-button>
                                      </div>
                                    <div class="col-sm-6 p-1">
                                        <mat-radio-button class="ml-3" value="#13 hvy">#13 hvy</mat-radio-button>
                                        </div>
                                      <div class="col-sm-6 p-1">
                                      <mat-radio-button  value="#13 Drywall">#13 Drywall</mat-radio-button>
                                  </div>
                                <div class="col-sm-6 p-1">
                                    <mat-radio-button  class="ml-3" value="#13 Hvy Drywall">#13 Hvy Drywall</mat-radio-button>
                                </div>
                                  </div>
                                    </mat-radio-group>
                                  </div>
                                  </div>
                                  <div class="row mt-2">
                                    <div class="col-sm-3">
                                        <label>Aspect Tubing</label>
                                    </div>
                                    <div class="col-sm-9">
                                      <mat-radio-group formControlName="aspectTubing" class="example-full-width" aria-label="Select an option">
                                          <div class="row">
                                              <div class="col-sm-3">
                                          <mat-radio-button value="XL">XL</mat-radio-button>
                                          </div>
                                          <div class="col-sm-3">
                                            <mat-radio-button value="L">L</mat-radio-button>
                                            </div>
                                            <div class="col-sm-3">
                                                <mat-radio-button value="M">M</mat-radio-button>
                                                </div>
                                                <div class="col-sm-3">
                                                    <mat-radio-button value="S">S</mat-radio-button>
                                                    </div> 
                                          </div>
                                          </mat-radio-group>
                                          </div>
                                          </div>

                            </div>
                        </div>
                   
                </div>
                <div class="col-sm-6  mb-2  mr-0 pr-0">
                 
                        <div class="card minCard2 divbody boxsssss mb-3 borderradius p-2">  
                            <div class="col-sm-12">
                                <div class="col-sm-12 bg-secondary rounded-3 pl-4 pr-4 mb-3">
                                    <div class="row justify-content-center">
                                      <h4 class="fass mt-2">Sound Bore </h4>
                  
                                    </div>
                                  </div>
                                  <div class="col-sm-12">
                                    <mat-radio-group formControlName="soundBore" class="example-full-width" aria-label="Select an option">
                                        <div class="row">
                                            <div class="col-sm-4">
                                        <mat-radio-button value="Bell Canal">Bell Canal</mat-radio-button>
                                        </div>
                                        <div class="col-sm-4">
                                      <mat-radio-button class="ml-3" value="Acoustic Modifier">Acoustic Modifier</mat-radio-button>
                                      </div>
                                      <div class="col-sm-4">
                                      <mat-radio-button class="ml-3" value="Seal Ring">Seal Ring</mat-radio-button>
                                      </div>
                                  
                                  </div>
                                    </mat-radio-group>
                                  </div>
                            </div>
                        </div>
                  
                </div>
                <div class="col-sm-6  mb-2  mr-0 pr-0">
                 
                        <div class="card divbody boxsssss mb-3 borderradius p-2">  
                            <div class="col-sm-12">
                                <div class="col-sm-12 bg-secondary rounded-3 pl-4 pr-4 mb-3">
                                    <div class="row justify-content-center">
                                      <h4 class="fass mt-2">Modifications</h4>
                  
                                    </div>
                                  </div>
                                  <div class="col-sm-12">
                                    <mat-radio-group formControlName="modifications" class="example-full-width" aria-label="Select an option">
                                        <div class="row">
                                            <div class="col-sm-4 p-1">
                                        <mat-radio-button value="Short Canal">Short Canal</mat-radio-button>
                                        </div>
                                        <div class="col-sm-4 p-1">
                                      <mat-radio-button class="ml-3" value="Long Canal">Long Canal</mat-radio-button>
                                      </div>
                                      <div class="col-sm-4 p-1">
                                      <mat-radio-button class="ml-3" value="Initials">Initials</mat-radio-button>
                                      </div>
                                      <div class="col-sm-4 p-1">
                                      <mat-radio-button value="Colour Code">Colour Code</mat-radio-button>
                                      </div>
                                      <div class="col-sm-4 p-1">
                                      <mat-radio-button class="ml-3" value="No Helix">No Helix</mat-radio-button>
                                      </div>
                                     
                                      <div class="col-sm-4 p-1">
                                      <mat-radio-button class="ml-3" value="Helix">Helix</mat-radio-button>
                                      </div>
                                      <div class="col-sm-4 p-1">
                                      <mat-radio-button value="Helix Lock">Helix Lock</mat-radio-button>
                                      </div>
                                    
                                  </div>
                                    </mat-radio-group>
                                  </div>
                            </div>
                        </div>
               
                </div>
                <div class="col-sm-6  mb-2  mr-0 pr-0">
                 
                        <div class="card divbody boxsssss borderradius p-2">  
                            <div class="col-sm-12">
                                <!-- <div class="col-sm-12 bg-secondary rounded-3 pl-4 pr-4 mb-3">
                                    <div class="row">
                  
                                      <h4 class="fass mt-2"></h4>
                  
                                    </div>
                                  </div> -->
                                  <div class="col-sm-12">
                                 
                                 <table class="table table-hover table-striped table-bordered"
                                 style="text-align:center">
                                  <!-- <thead>
          
                                    <tr>
                                        <th></th>
                                        <th>Morning</th>
                                      <th>Afternoon</th>
                                      <th>Evening </th>
                                    </tr>
                                  </thead> -->
                                  <tbody>
                                   
                                    <tr 
                                     role="row"
                                      class="odd text-center" >
                                        <td  class="align-middle">
                                          <label>AIR RT BONE</label>
                                        </td>
                                        <td  class="align-middle">
                                            <!-- <mat-label matLine ngDefaultControl [value]="dayLabel" formControlName="day">{{dayLabel}}</mat-label> -->
                                            <mat-form-field class="example-full-width lable_style2" appearance="outline">
                                                <!-- <mat-label matLine ngDefaultControl formControlName="day"></mat-label>        -->
                                                <mat-label> AIR RT BONE</mat-label> 
                                                <input matInput formControlName="airRtBone1" />
                                              </mat-form-field>
                                        </td>
                                        <td  class="align-middle">
                                            <!-- <mat-label matLine ngDefaultControl [value]="dayLabel" formControlName="day">{{dayLabel}}</mat-label> -->
                                            <mat-form-field class="example-full-width lable_style2" appearance="outline">
                                                <!-- <mat-label matLine ngDefaultControl formControlName="day"></mat-label>        -->
                                                <mat-label> AIR RT BONE</mat-label> 
                                                <input matInput formControlName="airRtBone2" />
                                              </mat-form-field>
                                        </td>
                                        </tr>
                                      
                                           <tr 
                                           role="row"
                                            class="odd text-center" >
                                              <td  class="align-middle">
                                                <label>FREQ</label>
                                              </td>
                                              <td  class="align-middle">
                                                  <!-- <mat-label matLine ngDefaultControl [value]="dayLabel" formControlName="day">{{dayLabel}}</mat-label> -->
                                                  <mat-form-field class="example-full-width lable_style2" appearance="outline">
                                                      <!-- <mat-label matLine ngDefaultControl formControlName="day"></mat-label>        -->
                                                      <mat-label> FREQ</mat-label> 
                                                      <input matInput formControlName="frequency1"/>
                                                    </mat-form-field>
                                              </td>
                                              <td  class="align-middle">
                                                  <!-- <mat-label matLine ngDefaultControl [value]="dayLabel" formControlName="day">{{dayLabel}}</mat-label> -->
                                                  <mat-form-field class="example-full-width lable_style2" appearance="outline">
                                                      <!-- <mat-label matLine ngDefaultControl formControlName="day"></mat-label>        -->
                                                      <mat-label> FREQ</mat-label> 
                                                      <input matInput formControlName="frequency2"/>
                                                    </mat-form-field>
                                              </td>
                                              </tr> 
                                              <tr 
                                              role="row"
                                               class="odd text-center" >
                                                 <td  class="align-middle">
                                                   <label>AIR RT BONE</label>
                                                 </td>
                                                 <td  class="align-middle">
                                                     <!-- <mat-label matLine ngDefaultControl [value]="dayLabel" formControlName="day">{{dayLabel}}</mat-label> -->
                                                     <mat-form-field class="example-full-width lable_style2" appearance="outline">
                                                         <!-- <mat-label matLine ngDefaultControl formControlName="day"></mat-label>        -->
                                                         <mat-label> AIR RT BONE</mat-label> 
                                                         <input matInput formControlName="airRtBone3"/>
                                                       </mat-form-field>
                                                 </td>
                                                 <td  class="align-middle">
                                                     <!-- <mat-label matLine ngDefaultControl [value]="dayLabel" formControlName="day">{{dayLabel}}</mat-label> -->
                                                     <mat-form-field class="example-full-width lable_style2" appearance="outline">
                                                         <!-- <mat-label matLine ngDefaultControl formControlName="day"></mat-label>        -->
                                                         <mat-label> AIR RT BONE</mat-label> 
                                                         <input matInput formControlName="airRtBone4"/>
                                                       </mat-form-field>
                                                 </td>
                                                 </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
               
                </div>
                <div class="col-sm-6  mb-2  mr-0 pr-0">
                    
                        <div class="card divbody boxsssss mb-3 borderradius p-2">  
                            <div class="col-sm-12">
                                <div class="col-sm-12 bg-secondary rounded-3 pl-4 pr-4 mb-3">
                                    <div class="row">
                                        <div class="col-sm-4">
                                      <h4 class="fass mt-2">Hearing & Fitting</h4>
                                        </div>
                                        <div class="col-sm-4"></div>
                                        <div class="col-sm-4">
                                      <h4 class="fass mt-2">Hearing Loss</h4>
                                    </div>
                                    </div>
                                  </div>

                                  <div class="row">
                                    <div class="col-sm-6">
                                  <div class="col-sm-12">
                                    <mat-form-field class="example-full-width lable_style2" appearance="outline">
                                        <mat-label> Make</mat-label>       
                                        <input matInput type="text" formControlName="make"/>
                                      </mat-form-field>
                                  </div>
                                  <div class="col-sm-12">
                                    <mat-form-field class="example-full-width lable_style2" appearance="outline">
                                        <mat-label> Model</mat-label>       
                                        <input matInput type="text" formControlName="model"/>
                                      </mat-form-field>
                                  </div>
                                  <div class="col-sm-12">
                                    <mat-form-field class="example-full-width lable_style2" appearance="outline">
                                        <mat-label> Receiver</mat-label>       
                                        <input matInput type="text" formControlName="receiver"/>
                                      </mat-form-field>
                                  </div>
                                  </div>
                                  <div class="col-sm-6">
                                    <div class="col-sm-12">
                                        <mat-radio-group formControlName="hearingLoss" class="example-full-width" aria-label="Select an option">
                                            <div class="row">
                                                <div class="col-sm-3">
                                            <mat-radio-button value="Mild">Mild</mat-radio-button>
                                            </div>
                                            <div class="col-sm-3">
                                          <mat-radio-button class="ml-3" value="Med">Med</mat-radio-button>
                                          </div>
                                          <div class="col-sm-3">
                                          <mat-radio-button class="ml-3" value="Serve">Serve</mat-radio-button>
                                          </div>
                                        
                                      </div>
                                        </mat-radio-group>
                                      </div>
                                      <div class="col-sm-12 bg-secondary rounded-3 pl-4 pr-4 mb-3">
                                        <div class="row">
                      
                                          <h4 class="fass mt-2">Ear Texture</h4>
                      
                                        </div>
                                      </div>
                                      <div class="col-sm-12">
                                        <mat-radio-group formControlName="earTexture" class="example-full-width" aria-label="Select an option">
                                            <div class="row">
                                                <div class="col-sm-3">
                                            <mat-radio-button value="Mild">Mild</mat-radio-button>
                                            </div>
                                            <div class="col-sm-3">
                                          <mat-radio-button class="ml-3" value="Med">Med</mat-radio-button>
                                          </div>
                                          <div class="col-sm-3">
                                          <mat-radio-button class="ml-3" value="Serve">Serve</mat-radio-button>
                                          </div>
                                        
                                      </div>
                                        </mat-radio-group>
                                      </div>
                                      </div>
                                  </div>
                            </div>
                    
                    </div>
                </div>
              
            
                  <div class="col-sm-12  mb-2  mr-0 pr-0">
                 
                        <div class="card minCard4 divbody boxsssss mb-3 borderradius p-2">  
                            <div class="col-sm-12">
                                <div class="col-sm-12 bg-secondary rounded-3 pl-4 pr-4 mb-3">
                                    <div class="row justify-content-center">
                                      <h4 class="fass mt-2">Remark</h4>
                  
                                    </div>
                                  </div>
                                  <div class="col-sm-12">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                        <!-- <mat-label>Notes</mat-label> -->
                                
                                        <textarea maxlength="1000" placeholder="Remark" matInput formControlName="remark"
                                        cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                        cdkAutosizeMinRows="5" cdkAutosizeMaxRows="5" ></textarea>
                                      </mat-form-field>
                                    
                                  </div>
                            </div>
                        </div>
                   
                </div> 
                <div class="row col-sm-12">
                  <div *ngIf="submitButton" class="col-sm-10  mb-2"></div>
                  <div *ngIf="updateButton" class="col-sm-8  mb-2"></div>
                  <div *ngIf="submitButton" class="col-sm-2   example-full-width mb-2">
                      <button  type="button" *ngIf="earMouldCreate" class="btn btn-sm btn-primary example-full-width "
                      (click)="submitEarMouldOrderList();"> Save</button>
                      
                    </div>
                    <div *ngIf="updateButton" class="col-sm-2 mb-2">
                      <button  type="button" *ngIf="earMouldUpdate" class="btn btn-sm btn-primary example-full-width"
                      (click)="updateEarMouldOrderList();"> Update</button>
                    </div>
                    <div *ngIf="cancelButton" class="col-sm-2 mb-2 ">
                      <button class="btn btn-primary btn-sm example-full-width"  type="reset"  (click)="cancelUpdate()">Cancel</button>
                    </div>
              </div>
           
            <div class="col-sm-12 mb-2  mr-0 pr-0">
               
              <div class="card divbody boxsssss mb-3 borderradius p-2">  
                
                            <div class="col-sm-12">
                                <div class="row">
                                    <div class="col-sm-9 ">
                                        <h1 class="titlecolor"> Ear Mould Order Form List</h1>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="input-group md-form form-sm form-1 pl-0">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text purple lighten-3" id="basic-text1"><i
                                                        class="fas fa-search text-white" aria-hidden="true"></i></span>
                                            </div>
                                            <input class="form-control my-0 py-1" type="text" placeholder="Search"
                                                formControlName="searchEarMouldOrder" aria-label="Search" (keyup)="onKeyUpEvent($event)" >
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <table id="toearMouldNewFormList"
                                         class="table table-hover table-striped table-bordered" 
                                     >
                                            <thead>
                                                <tr class="text-center">
                                                    <th>ID</th>
                                                    <th>Order Date</th>
                                                    <th *ngIf="userType == 'Super Admin'">Branch Name</th>
                                                    <th>Patient Name</th>
                                                    <th>Mobile No.</th>
                                                    
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of listOfEarMouldOrders | paginate : { id:'ear_pagination', 
                                                itemsPerPage: size, currentPage: page, totalItems:totalLength }" 
                                                (click)="prepopulation(item._id)"
                                                 (click)="toearMouldNewForm()" 
                                                 style="cursor:pointer; text-align: center ">

                                                 <td matTooltip="click to view">{{item.branchWiseId}}</td>
                                                 <td matTooltip="click to view">{{item.earMoldOrderDate | date :'dd/MM/yy'}}</td>
                                                 <td *ngIf="userType == 'Super Admin'" matTooltip="click to view" 
                                                 >{{item.branchName}}</td>
                                                 <td matTooltip="click to view">{{item.firstName}}  {{item.lastName}}</td>
                                                 <td matTooltip="click to view">{{item.mobile1}}</td>
                                                    
                                                </tr>
                                              
                                            
                                               

                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="col-sm-12 pt-0 text-right">
                                            <pagination-controls id="ear_pagination" directionLinks="true"
                                  (pageChange)="onPageChange($event)" class="float-right pagination my-pagination"></pagination-controls>
                                        
                                    </div>
                                </div>
                            </div>
                    
                    </div>

                </div>
              </div>

        </div>
    </div>
    </form>
</div>