<div class="container-fluid bgcolor2" [formGroup]="appointmentMaster">
  <div class="row">
      <div class="col-sm-1">
          <div class="card m-3">
          
          </div> 
      </div>
      <div class="col-sm-11">
          <div class="row pt-2">
              <div class="col-sm-12 text-left">
                <div class="row pl-0 ">
                  <div class="col-12 pl-0 ">
                    <div class="row ">
                    <div class="col-sm-8">
                        <h1 class="pt-2 pl-0">Appointment Calendar</h1> 
                        </div>
                        <div class="col-sm-4 mt-1">
                          <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end"> 
                            <a (click)="onback()" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">arrow_back</span>Back</a>
                            <a [routerLink]="['/home/doctor-availability']" class="d-flex align-items-center ms-3 pr-2 mt-2"><span class="material-symbols-outlined pr-2">medical_services</span>Audiologists</a>
                            <a (click)="controlClick()" class="d-flex align-items-center ms-3 pr-2 mt-2"><span class="material-symbols-outlined pr-2">settings</span>Control Panel</a>
                            <!-- <img matTooltip="Audiologists Availability" class="mb-2 pb-2 mr-2" width="25" [routerLink]="['/home/doctor-availability']"  src="../../assets/doctor_availablity.png">  
                            <img matTooltip="Control Panel" (click)="controlClick()" class="mb-2 pb-2 mr-2" width="25" [routerLink]="['/home/generic-setting']"  src="../../assets/general settings.png">   -->
                          </div>
                  </div>
                </div>
               </div>
               </div>
                <div class="row">
                  <div class="col-sm-12 card pb-3 pt-3 cu fc-bg-event" #calendar>
                  </div>
                  </div>
              </div>
           
          </div>
      </div>
  </div>
</div>
