<div class="container-fluid bgcolor2" [formGroup]="vendorMasterForm">
  <form [formGroup]="vendorMasterForm" autocomplete="off" novalidate>
    <div class="row col-sm-12 p-0 m-0">
      <div class="col-sm-1">

      </div>
      <div class="col-sm-11">
        <div class="row pl-0 ">
          <div class="col-12 pl-0 ">
            <div class="row ">
            <div class="col-sm-9">
            
                <h1 class="pt-2 pl-0" id="toVendorForm">Expense Vendor</h1>
                </div>
                <div class="col-sm-3 mt-1">
                  <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                    <a (click)="onback()" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">arrow_back</span>Back</a>
                    <a (click)="toVendorList()" class="d-flex align-items-center ms-3 pr-2 mt-2"><span class="material-symbols-outlined pr-2">reorder</span>List</a>  
          </div>
          </div>
        </div>
       </div>
       </div>


        <div class="row ">
          <div class="col-sm-12 pr-4">
            <div class="row">
              <div class="card divbody borderradius mb-2 p-1">
                <div class="row pl-3 pr-3">
                  <div class="col-sm-12">
                    <div class="row mt-2">
                      <div class="col-sm-12  ">
                        <h5 class="pb-0 mb-0">Expense Vendor Details :</h5>
                        <!-- <label class="header">Vendor Details :</label> -->
                      </div>
                    </div>
                    <div class="row pt-0 mt-0">
                        <div class="col-sm-1 mt-3">
                         
                          <!-- <mat-form-field *ngIf="userType !== 'Super Admin'" class="example-full-width" appearance="outline">
                            <mat-label>Vendor Id</mat-label>
                            <input matInput formControlName="branchWiseId" readonly  />   
                          </mat-form-field>
                          <mat-form-field *ngIf="userType == 'Super Admin'" class="example-full-width" appearance="outline">
                            <mat-label>Vendor Id</mat-label>
                            <input matInput [value]="superBranchId" readonly  />
                            </mat-form-field> -->
                            <mat-label class="center" ngDefaultControl formControlName="branchWiseId" *ngIf="userType !== 'Super Admin'" >{{bId}}</mat-label>
                              <mat-label class="center" ngDefaultControl [value]="superBranchId" *ngIf="userType == 'Super Admin'" >{{superBranchId}}</mat-label>
                        </div>
                    <div class="col-sm-2">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Expense Vendor Name
                          <!-- <span class="required-asterisk">*</span>    -->
                        </mat-label>
                        <input  matInput placeholder="Expense Vendor Name" formControlName="vendorName"  />
                        
                      </mat-form-field>
                      <mat-error
                          *ngIf="vendorMasterForm.controls['vendorName'].touched && vendorMasterForm.controls['vendorName'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                        <mat-error class="margin_tp"
                          *ngIf="vendorMasterForm.controls['vendorName'].touched && vendorMasterForm.controls['vendorName'].errors?.pattern">
                          Please enter valid vendor name.
                        </mat-error>
                    </div>

                    <div class="col-sm-3">
                      <div class="row">
                        <div class="col-sm-10 pr-1">
                          
                      <mat-form-field class="example-full-width "  appearance="outline">
                        <mat-label>Expense Vendor Brands 
                          <!-- <span class="-asterisk">*</span>    -->
                        </mat-label>
                        <mat-select  #select1 placeholder="Brands" (click)="fetchBrandList();"  class="colorblack" 
                         formControlName="vendorBrands" >
                          <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'brandName'"
                            [array]="brandlist"  (filteredReturn)="filteredListBrand =$event"></mat-select-filter>
                          <mat-option *ngFor="let item of filteredListBrand" 
                           [value]="item._id" (onSelectionChange)="brandSelect(item.brandName)">
                            {{item.brandName}}
                          </mat-option>
                        </mat-select>
                       
                      </mat-form-field>
                      <mat-error
                      *ngIf="vendorMasterForm.controls['vendorBrands'].touched && vendorMasterForm.controls['vendorBrands'].errors?.required">
                      This field is mandatory.
                    </mat-error>
                      </div>
                      <div class="col-sm-2 mt-3 ml-0 pl-0 ">
                        <i (click)="openBrandPopUp();" matTooltip="Add New Brand" 
                        class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                    
                    </div>
                    </div>
                  </div> 
               
                    <div class="col-sm-3">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Vendor GST Number</mat-label>
                        <input  matInput placeholder="eg.(29ABCDE1234F2Z5)"
                          formControlName="vendorGstNo" />
                        
                      </mat-form-field>
                      <mat-error class="margin_tp"
                      *ngIf="vendorMasterForm.controls['vendorGstNo'].touched && vendorMasterForm.controls['vendorGstNo'].errors?.pattern">
                      Please enter valid GST number.
                    </mat-error>
                    </div>
                    <div class="col-sm-3">
                      <div class="row">
                        <div class="col-sm-10">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Vendor Mobile Number 
                          <!-- <span class="required-asterisk">*</span>  -->
                        </mat-label>
                        <input matInput maxlength="10" placeholder="Mobile Number" formControlName="vendorPhoneNumber"  />
                       
                      </mat-form-field>
                    <mat-error class="margin_tp"
                      *ngIf="vendorMasterForm.controls['vendorPhoneNumber'].touched && vendorMasterForm.controls['vendorPhoneNumber'].errors?.pattern">
                      Please enter valid vendor Mobile number.
                    </mat-error>
                    </div>
                    <div class="col-sm-2 wApp  ml-0 pl-0">
                      <i class="bi bi-whatsapp whatsapp-icon wApp fa-lg" (click)="WhatsApp();" matTooltip="WhatsApp Integration" ></i>
                    </div>
                    </div>
                    </div>

                    <div class="col-sm-9"></div>
                 
                      <div class="col-sm-12  mb-0">
                        <h5 class="pb-0 mb-0">Account Details :</h5>
                        <!-- <label class="header">Account Contact Details :</label> -->
                      </div>
                
                    <div class="col-sm-3 pt-0 mt-0">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Name
                        </mat-label>
                        <input  matInput placeholder="Contact Person Accounts"
                          formControlName="contactPersonAccounts" />
                      </mat-form-field>
                      <mat-error class="margin_tp"
                      *ngIf="vendorMasterForm.controls['contactPersonAccounts'].touched && vendorMasterForm.controls['contactPersonAccounts'].errors?.pattern">
                      Please Enter Valid Contact Person Accounts.
                    </mat-error>
                    <mat-error
                    *ngIf="vendorMasterForm.controls['contactPersonAccounts'].touched && vendorMasterForm.controls['contactPersonAccounts'].errors?.required">
                    This field is mandatory.
                  </mat-error>
                    </div>

                    <div class="col-sm-3">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Account Number</mat-label>
                        <input maxlength="18" minlength="9" matInput 
                          placeholder="Contact Person Accounts Number" formControlName="contactPersonAccountNo" />
                      </mat-form-field>
                      <mat-error class="margin_tp"
                      *ngIf="vendorMasterForm.controls['contactPersonAccountNo'].touched && vendorMasterForm.controls['contactPersonAccountNo'].errors?.pattern">
                      Please Enter Valid Contact Person Accounts Number.
                    </mat-error>
                    <!-- <mat-error
                    *ngIf="vendorMasterForm.controls['contactPersonAccountNo'].touched && vendorMasterForm.controls['contactPersonAccountNo'].errors?.required">
                    This field is mandatory.
                  </mat-error> -->
                    </div>
                    <div class="col-sm-6"></div>
                      <div class="col-sm-12 mt-1">
                        <h5 class="pb-0 mb-0">Sales Contact Details :</h5>
                        <!-- <label class="header">Sales Contact Details :</label> -->
                      </div>


                    <div class="col-sm-3 pt-0 mt-0 ">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Name</mat-label>
                        <input  matInput placeholder="Name"
                          formControlName="contactPersonSales" />
                      
                      </mat-form-field>
                      <mat-error class="margin_tp"
                      *ngIf="vendorMasterForm.controls['contactPersonSales'].touched && vendorMasterForm.controls['contactPersonSales'].errors?.pattern">
                      Please enter valid contact person sales name.
                    </mat-error>
                    </div>
                    <div class="col-sm-3 pt-0 mt-0 ">
                      <div class="row">
                        <div class="col-sm-10">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Contact Number </mat-label>
                        <input matInput maxlength="10" 
                          placeholder="Contact Number" formControlName="contactPersonSalesNo" />
                        
                      </mat-form-field>
                      <mat-error class="margin_tp"
                          *ngIf="vendorMasterForm.controls['contactPersonSalesNo'].touched && vendorMasterForm.controls['contactPersonSalesNo'].errors?.pattern">
                          Please enter valid contact person sales number.
                        </mat-error>
                    </div>
                    <div class="col-sm-2 wApp  ml-0 pl-0">
                      <i class="bi bi-whatsapp whatsapp-icon wApp fa-lg" (click)="WhatsApp();" matTooltip="WhatsApp Integration" ></i>
                      </div>

                    </div>
                    </div>
                    <div class="col-sm-12 mt-1">
                      <h5 class="pb-0 mb-0">Customer Care Contact Details :</h5>
                      <!-- <label class="header">Customer Care Contact Details :</label> -->
                    </div>
              
                    <div class="col-sm-3 pt-0 mt-0">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Name
                        </mat-label>
                        <input matInput 
                          placeholder="Name" formControlName="customerCareContactPerson" />
                       
                      </mat-form-field>
                      <mat-error class="margin_tp"
                      *ngIf="vendorMasterForm.controls['customerCareContactPerson'].touched && vendorMasterForm.controls['customerCareContactPerson'].errors?.pattern">
                      Please enter valid customer care contact person.
                    </mat-error>
                  
                    </div>
                  
                    <div class="col-sm-3 pt-0 mt-0">
                      <div class="row">
                        <div class="col-sm-10">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Contact Number</mat-label>
                        <input matInput maxlength="10" 
                          placeholder="Contact Number"
                          formControlName="customerCareContactPersonNo" />
                       
                      </mat-form-field>
                      <mat-error class="margin_tp"
                      *ngIf="vendorMasterForm.controls['customerCareContactPersonNo'].touched && vendorMasterForm.controls['customerCareContactPersonNo'].errors?.pattern">
                      Please enter valid customer care contact person no.
                    </mat-error>
                    </div>
                    <div class="col-sm-2 wApp ml-0 pl-0">
                      <i class="bi bi-whatsapp whatsapp-icon wApp fa-lg" (click)="WhatsApp();" matTooltip="WhatsApp Integration" ></i>
                    </div>
                    </div>
                    </div>
                  </div>
                  </div>


                  <div class="col-sm-12">
                   
                    <div class="row">
                      <div class="col-sm-12 mt-1">
                        <h5 class="pb-0 mb-0">Address Details :</h5>
                        <!-- <label class="header">Address Details :</label> -->
                      </div>
                    </div>
                    <div class="row pt-0 mt-0">
                      <div class="col-sm-3">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Street Address 
                            <!-- <span class="required-asterisk">*</span>  -->
                          </mat-label>
                          <input  matInput placeholder="Street Address" formControlName="vendorStreetAddress"  />
                        
                        </mat-form-field>
                        <mat-error
                        *ngIf="vendorMasterForm.controls['vendorStreetAddress'].touched && vendorMasterForm.controls['vendorStreetAddress'].errors?.required">
                        This field is mandatory.
                      </mat-error>
                      </div>
                      <div class="col-sm-3">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Taluka</mat-label>
                          <input matInput placeholder="Taluka" formControlName="taluka" />
                         
                        </mat-form-field>
                        <mat-error class="margin_tp"
                        *ngIf="vendorMasterForm.controls['taluka'].touched && vendorMasterForm.controls['taluka'].errors?.pattern">
                        Please enter valid taluka.
                      </mat-error>
                      </div>
                      <div  class=" col-sm-3  ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>District</mat-label>
                          <!-- <input matInput placeholder="District" formControlName="district" /> -->
                          <mat-select   #select12 placeholder="District" class="colorblack"  formControlName="district"   >
                            <mat-select-filter  *ngIf="select12.focused" [placeholder]="'Filter'" [displayMember]="'district'"
                              [array]="districtData2" (filteredReturn)="filteredListDistrict2 =$event"></mat-select-filter>
                            <mat-option *ngFor="let item of filteredListDistrict2" 
                             [value]="item.district" >
                              {{item.district}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                        <mat-error class="margin_tp"
                        *ngIf="vendorMasterForm.controls['district'].touched && vendorMasterForm.controls['district'].errors?.pattern">
                            Please enter valid district.
                      </mat-error>
                      </div>
                      <div  class=" col-sm-3  "> 
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>State</mat-label>
                          <mat-select   #select11 placeholder="State" class="colorblack"  formControlName="state"   >
                            <mat-select-filter  *ngIf="select11.focused" [placeholder]="'Filter'" [displayMember]="'state'"
                              [array]="stateCityArr2"  (filteredReturn)="filteredList2 =$event"></mat-select-filter>
                            <mat-option *ngFor="let item of filteredList2" 
                             [value]="item.state" (click)="getDistrict2(item.districts)">
                              {{item.state}}
                            </mat-option>
                          </mat-select>
                        
                        </mat-form-field>
                        <mat-error
                        *ngIf="vendorMasterForm.controls['state'].touched && vendorMasterForm.controls['state'].errors?.required">
                        This field is mandatory.
                      </mat-error>
                      </div>
                      <div class="col-sm-3 ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Pin Code 
                            <!-- <span class="required-asterisk">*</span>  -->
                          </mat-label>
                          <input  formControlName="vendorPinCode" maxlength="6"  matInput placeholder="Pincode(e.g:416008)" />
                         
  
                        </mat-form-field>
                        <mat-error
                        *ngIf="vendorMasterForm.controls['vendorPinCode'].touched && vendorMasterForm.controls['vendorPinCode'].errors?.pattern">
                        Please enter valid PinCode</mat-error>

                      <mat-error
                        *ngIf="vendorMasterForm.controls['vendorPinCode'].touched && vendorMasterForm.controls['vendorPinCode'].errors?.required">
                        This field is mandatory.</mat-error>
                      </div>
                  
                      <!-- <div class="col-sm-3">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>District</mat-label>
                          <input matInput placeholder="District" formControlName="district" />
                       
                        </mat-form-field>
                        <mat-error class="margin_tp"
                        *ngIf="vendorMasterForm.controls['district'].touched && vendorMasterForm.controls['district'].errors?.pattern">
                        Please enter valid district.
                      </mat-error>
                      </div> -->
                   
                      <!-- <div class="col-sm-3">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>State</mat-label>
                          <mat-select required  #select2 placeholder="State" class="colorblack" formControlName="state">
                            <mat-select-filter *ngIf="select2.focused" [placeholder]="'Filter'" [displayMember]="'name'" [array]="allState"
                              (filteredReturn)="filteredList =$event"></mat-select-filter>
                            <mat-option *ngFor="let item of filteredList" [value]="item.value">
                              {{item.name}}
                            </mat-option>
                          </mat-select>
  
                        </mat-form-field>
                        <mat-error class="margin_tp"
                                    *ngIf="vendorMasterForm.controls['state'].touched && vendorMasterForm.controls['state'].errors?.required">
                                    This field is mandatory.
                                  </mat-error>
                      </div> -->
                      <div class="col-sm-3">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Country</mat-label>
                          <input matInput placeholder="Country" formControlName="country" />
                       
                        </mat-form-field>
                        <mat-error class="margin_tp"
                        *ngIf="vendorMasterForm.controls['country'].touched && vendorMasterForm.controls['country'].errors?.pattern">
                        Please enter valid country.
                      </mat-error>
                      </div>

                    </div>

                  </div>
                </div>

                
                  <div class="row pl-3 pr-2">
                      
                    <div class="col-sm-12">
                     
                      <div class="row">
                        <div class="col-sm-12  mt-1">
                          <h5 class="pb-0 mb-0">Other Details :</h5>
                          <!-- <label class="header">Other Details :</label> -->
                        </div>
                      </div>
                      <div class="row pt-0 mt-0">

                        <div class="col-sm-3">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Vendor Status</mat-label>
    
                            <mat-select formControlName="vendorStatus">
                              <mat-option value="Active">Active</mat-option>
                              <mat-option value="In-active">In-active</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                      
                    </div>
                    <!-- <div *ngIf="isSuperAdmin" class="col-sm-12"> 
                            
                      <div class="row">
                        <div class="col-sm-12  mt-1">
                      <label class="header">Select Branch :</label>
                        </div>
                        </div>
                        <div class="row">

                    <div class="col-sm-3">
                      <div class="row">
                        <div class="col-sm-10 pr-1">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Branch 
                        </mat-label>
                        <mat-select  #select3 (click)="fetchBranchList();" formControlName="branchRefId" >
                          <mat-select-filter *ngIf="select3.focused" [placeholder]="'Filter'" [displayMember]="'branchName'"
                                      [array]="BranchList"  (filteredReturn)="filteredListBranch =$event"></mat-select-filter>
                          <mat-option *ngFor="let item of filteredListBranch" [value]="item._id" 
                          (click)="branchClick(item._id,item.branchName);"
                          > 
                           {{ item.branchName }}</mat-option>
                          </mat-select>
                         
                      </mat-form-field>
                      <mat-error class="margin_tp"
                          *ngIf="vendorMasterForm.controls['branchRefId'].touched && vendorMasterForm.controls['branchRefId'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                    </div>
                    <div class="col-sm-2 mTop">
                      <i (click)="openBranchPopUp();" matTooltip="Add New Branch" 
                      class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                    </div>
                    </div>
                    </div> 
               </div>
               </div> -->
                  </div>
                  <div class="row pl-3 pr-3 mb-2">
                    <div class="col-sm-6"></div>
                    <div class="col-sm-4" *ngIf="submitButton"></div>
                    <div class="col-sm-2 mt-2" *ngIf="submitButton && vendorCreate">
                      <button class="btn mb-2 btn-sm btn-primary example-full-width"
                      (click)="submitVendor()" type="button" >Save</button>
                    </div>
                    <div class="col-sm-2" *ngIf="updateButton">
                    </div>
                    <div class="col-sm-2 mt-2" *ngIf="updateButton && vendorUpdate">
                      <button type="button" class="btn mb-2 btn-sm btn-primary example-full-width" (click)="updateVendorDetails()"
                      >Update</button>
                    </div>
                    <div class="col-sm-2 mt-2" *ngIf="updateButton">
                      <button class="btn mb-2 btn-primary btn-sm  example-full-width" (click)="cancelUpdate()"
                      type="reset">Cancel </button>
                    </div>

                  </div>
                   
                 
                
              </div>
            </div>
          </div>

        </div>

        <div class="row">
          <div class="col-sm-12 ">
            <div class="row">
              <div class="card divbody  borderradius  mb-2 pt-3 mr-2 pb-0">
                <div class="col-sm-12">
                  <div class="row">
                    <div class="col-sm-3">
                      <h1 class="titlecolor">  List Of Expense Vendor</h1>
                    </div>
                    <div class="col-sm-6"></div>
                    <div class="search-box col-sm-3 pb-2">
                      <input class="form-control amber-border" type="text" placeholder="Search" 
                      formControlName="searchVendor" aria-label="Search" (keyup)="onKeyUpEvent($event)"
                      >
                      <div class="input-group-append">
                         <span class="input-group-text amber lighten-3" id="basic-text1"><span class="material-symbols-outlined">search</span></span>
                      </div>
                   </div>
                    <!-- <div class="col-sm-3">
                      <div class="input-group md-form form-sm form-1 pl-0">
                        <div class="input-group-prepend">
                          <span class="input-group-text purple lighten-3" id="basic-text1"><i
                              class="fas fa-search text-white" aria-hidden="true"></i></span>
                        </div>
                        <input class="form-control my-0 py-1" type="text" formControlName="searchVendor"
                          (keyup)="onKeyUpEvent($event)" placeholder="Search" aria-label="Search">
                      </div>
                    </div>  -->
                    <div class="col-sm-12">
                      <table id="toVendorTable" 
                      class="table table-bordered data-table-custom"  style="text-align: center;">
                 
                        <thead>

                          <tr>
                            <th>Vendor Id</th>
                            <th *ngIf="userType == 'Super Admin'">Branch Name</th>
                            <th>Expense Vendor Name</th>
                            <th>Expense Vendor Address</th>
                            <th> Pin Code</th>
                            <th>Vendor Phone Number</th>
                            <th>Contact Person Account No</th>
                            <th>Expense Vendor GST Number</th>


                          </tr>
                        </thead>
                        <tbody>
                          <tr  *ngFor="
                                            let item of listOfVendors
                                            | paginate : { id:'vendor_pagination', 
                                            itemsPerPage: size, currentPage: page, totalItems:totalLength }
                                          " style="cursor: pointer;" (click)="toVendorForm()" (click)="prepopulation(item._id)">
                            <td matTooltip="click to view">{{item.branchWiseId}}</td>
                            <td *ngIf="userType == 'Super Admin'" matTooltip="click to view" 
                            >{{item.branchName}}
                          </td>
                            <td matTooltip="click to view">{{item.vendorName}}</td>
                            <td matTooltip="click to view">{{item.vendorStreetAddress}}</td>
                            <td matTooltip="click to view">{{item.vendorPinCode}}</td>
                            <td matTooltip="click to view">{{item.vendorPhoneNumber}}</td>
                            <td matTooltip="click to view">{{item.contactPersonAccountNo}}</td>
                            <td matTooltip="click to view">{{item.vendorGstNo}}</td>


                          </tr>


                        </tbody>
                      </table>
                    </div> 
                    <div class="col-sm-12 pt-0 text-right">
                      <pagination-controls id="vendor_pagination" directionLinks="true"
                        (pageChange)="onPageChange($event)" class="float-right pt-3 my-pagination">
                      </pagination-controls>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  </form>
</div>