import { Component, OnInit, ViewChild } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  FormArray,
} from "@angular/forms";
 import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";
import { MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HaFormSerialPopupComponent } from "../ha-form-serial-popup/ha-form-serial-popup.component";
import { HaFormRightserialPopupComponent } from "../ha-form-rightserial-popup/ha-form-rightserial-popup.component";
import { HaFormChargerserialPopupComponent } from "../ha-form-chargerserial-popup/ha-form-chargerserial-popup.component";
import { PopUpPatientComponent } from "../pop-up-patient/pop-up-patient.component";
import { PopUpBranchComponent } from "../pop-up-branch/pop-up-branch.component";
import { PopUpDeviceComponent } from "../pop-up-device/pop-up-device.component";
import { PatientSearchPopComponent } from '../patient-search-pop/patient-search-pop.component';
import { Router } from "@angular/router";
import SignaturePad from 'signature_pad';
import { ElementRef } from '@angular/core';
import { PdfviewerComponent } from "../pdfviewer/pdfviewer.component";
import { PopupBillingcompanyComponent } from "../popup-billingcompany/popup-billingcompany.component";
@Component({
  selector: 'app-ha-form',
  templateUrl: './ha-form.component.html',
  styleUrls: ['./ha-form.component.scss']
})
export class HaFormComponent implements OnInit {
  HAForm: FormGroup;
  userType: string;
  isSuperAdmin = true;
  userPermission: any;
  branchRef: string;
  clinicRef: string;
  branchName: string;
  haFormCreate = true;
  haFormUpdate = true;
  filteredListBranch: any;
  branchList: any;
  size=10;
  page=1;
  searchText: any;
  submitButton = true;
  updateButton = false;
  superBranchId: any;
  patientlist: any;
  filteredPatientList: any;
  patientImage: any; 
  patientImage2: any; 
  errormessage: string;
  deviceList: any;
  filteredDeviceList: any;
  listOfHAForm: any;
  totalLength: any;
  pid: any;
returnDate :Date;
modalImg: any;
modalImg2: any;
modal: any;
modal2: any;
patientCreate = true;
isTempGiven= false;
  hat_patientRefId: string;
  hat_patientName: string;
  ifPrint='false';
  currentdate: Date;
  maxDate: Date;
  bID: any;
  isPrepopulate = false;
  leftSerialNoList = [];
  rightSerialNoList = [];
  maxDateStr: string;
  minDateStr: string;
  hide = false;
  hide2 = true;
  ///
  title = 'signatureJS';
  signaturePad: SignaturePad;
  @ViewChild('canvas') canvasEl: ElementRef;
  signatureImg: string;
  fileName: any;
  fileName2: any;
  signPadShow = false;
  billingCreate = true; 
  billingCompanyList: any;
  filteredListBillingCompany: any;

  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
  }

  startDrawing(event: Event) {
    ////
  }

  moved(event: Event) {
    // works in device not in browser
  }

  clearPad() {
    this.signaturePad.clear();
  }
 
  savePad() {
    const base64Data = this.signaturePad.toDataURL();
    this.signatureImg = base64Data;
  }
  constructor(private fb: FormBuilder,
    public apimicroservice: ApiServiceService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    public router: Router) { }

  ngOnInit(): void {
    this.isPrepopulate = false;
    this.currentdate = new Date(); 
    this.page=1;
    var futureDate = new Date();
    futureDate.setDate(futureDate.getDate() + 30);
    ////
    this.maxDate =futureDate;
   // this.maxDateStr = futureDate.toDateString();
   let mn = futureDate.getMonth() + 1;
   this.maxDateStr = futureDate.getDate() + '/' + mn + '/' + futureDate.getFullYear();
    let mm = this.currentdate.getMonth() + 1;
    this.minDateStr = this.currentdate.getDate() + '/' + mm + '/' + this.currentdate.getFullYear();
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.userType = sessionStorage.getItem('userType');
    this.hat_patientRefId = sessionStorage.getItem('hat_patientRefId');
    this.hat_patientName = sessionStorage.getItem('hat_patientName');
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    if(this.userType !== 'Super Admin'){
      this.isSuperAdmin= false;
    this.userPermission.forEach((element) => {
      if(element.pageName == 'HA Form' && element.create == false)
      {
          this.haFormCreate = false;  
      }
      if(element.pageName == 'HA Form' && element.update == false)
      {
          this.haFormUpdate = false;  
      }  
      if(element.pageName == 'Patient' && element.create == false)
      {
          this.patientCreate = false;  
      }  
      if(element.pageName == 'Billing Company' && element.create == false)
      {
          this.billingCreate = false;  
      }
    });
  }
  let i = 0;
 
    this.HAForm = this.fb.group({
      "hAId": new FormControl(''),
      "clinicRefId": new FormControl(''),
      "patientName": new FormControl('',[Validators.required,Validators.pattern("^[a-zA-Z ]*$")]),
      "phoneNo": new FormControl('',[Validators.pattern("(0|91)?[6-9][0-9]{9}")]),
      "patientRefId": new FormControl(''),
      "address": new FormControl(),
      "branchRefId": new FormControl(),     
      "branchName": new FormControl(''),
      "pBranchName": new FormControl(''),
     
     
      //"serialNo": new FormControl(),
     
      "noOfDays": new FormControl(''),
       "advanceRs": new FormControl('',[Validators.required,Validators.pattern("^[0-9 .]*$")]),
      "iDProof": new FormControl(),
      "idProofImage": new FormControl(),
      "status": new FormControl(),
       "searchHA": new FormControl(),
       "remark": new FormControl(),
       "tempHagiven": new FormControl(),
       "tempHagivenSerialNo": new FormControl(),
      "date":  new FormControl(),
      "tillDate":  new FormControl('',[Validators.required]),
      "Signaturephoto": '', 
      "haFlag":  new FormControl(''),
      "createdAt": new Date(),
      "fileName":new FormControl(''),
      "fileName2":new FormControl(''),
      "billingRefId":new FormControl(''),
      "deviceInfo": this.fb.array([]),
      // "updatedAt": new Date(),
      
    });
    //this.patientImage ="../../assets/no_data_found.jpeg";
    ////
    this.HAForm.get("status").setValue('Issued');
    this.addNew(i);
    this.fetchHAList();
    this.getDevice();
    this.getLastId();
    this.fetchBillingCompanyList();
    this.fileName = '';
    this.hide = false;
    this.fileName2 = '';
    this.hide2 = false;
    this.modal = document.getElementById("myModal");
    this.modal2 = document.getElementById("myModal2");
    this.modalImg = document.getElementById("img01");
    this.modalImg2 = document.getElementById("img02");
    if(this.userType == 'Super Admin'){
      this.fetchBranchList(); 
      this.getAllBranch();
    }
    if(this.hat_patientRefId !== '')
   {
    this.HAForm.controls["patientName"].setValue(this.hat_patientName);
    this.selectPatient(this.hat_patientRefId);  
    sessionStorage.setItem('hat_patientName','');
    sessionStorage.setItem('hat_patientRefId','');
   }
  
 //  this.removeValidator();
  }
  deleteRow(index){
    (<FormArray>this.HAForm.get("deviceInfo")).removeAt(index);
  }
  addNew(i) {
    const creds = this.HAForm.controls.deviceInfo as FormArray;
        creds.push(
        this.fb.group({
          "deviceRefId": new FormControl(),
          "deviceName": new FormControl(),  
          "LeftSerialNo": new FormControl(),
          "RightSerialNo": new FormControl(),
          "ChargerSerialNo": new FormControl(),
           toggle: false
})
);
  }
fetchBillingCompanyList(){
  let id=''; 
  let clinicRef= sessionStorage.getItem('clinicRef');
  this.apimicroservice.getBillingDataById(id,clinicRef).subscribe(res => {
    this.billingCompanyList = res.data;
    this.filteredListBillingCompany= this.billingCompanyList.slice();
    let lenght = res.data.length-1;  
    this.HAForm.get("billingRefId").setValue(res.data[lenght]._id);
    if(res.status){
      //this.apimicroservice.openSnackbar(res.message);
    }else{ 
      //this.apimicroservice.openSnackbar(res.message);
    }
  });
}
openBillingCompPopUp(){
  const dialogRef3 = this.dialog.open(PopupBillingcompanyComponent,
    {
      width: "1200px",
    });

    dialogRef3.afterClosed().subscribe((result) => {
      let id='';
      let clinicRef= sessionStorage.getItem('clinicRef');
  this.apimicroservice.getBillingDataById(id,clinicRef).subscribe(res => {
   
    if(res.status){
      //
      this.billingCompanyList = res.data;
      this.filteredListBillingCompany= this.billingCompanyList.slice();
      this.HAForm.get("billingRefId").setValue(res.data[0]._id);
    }
  });
     // this.fetchBillingCompanyList();  
     });
}
  getAllBranch()
  {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      if (res.status) {        
        let length = res.data.length - 1;
        this.HAForm.controls["branchRefId"].setValue(res.data[length]._id);
        this.branchClick(res.data[length]._id,res.data[length].branchName); 
        //////
        if(this.userType == 'Super Admin')
        {  
          
          this.apimicroservice.getBranchById(res.data[length]._id).subscribe(res => {
            this.spinner.hide();
            this.HAForm.get("branchName").setValue(res.data[length].branchName);
            ////
           });
        }
       
      }
    });
  }
  closeClick()
  {
    this.modal.style.display = "none";
  }
  imgClick()
  {
    this.modal.style.display = "block";
    this.modalImg.src = this.patientImage;
  }
  closeClick2()
  {
    this.modal2.style.display = "none";
  }
  imgClick2()
  {
    this.modal2.style.display = "block";
    this.modalImg2.src = this.patientImage2;
  }
  toHAForm() {
    document.getElementById("toHAForm").scrollIntoView();
  }
  toHAFormList() {
    document.getElementById("toHAFormList").scrollIntoView();
  }
  prepopulation(id)
  {
    this.spinner.show();
    this.signPadShow = false; 
    this.apimicroservice.getHAById(id).subscribe(res => {
      this.spinner.hide();
      let data = res.data[0];
      // let lSrNo = res.data[0].LeftSerialNo[0];
      // let RSrNo;
      // let lSrNo;
      // if(res.data[0].LeftSerialNo !== null)
      // {
      //   lSrNo = res.data[0].LeftSerialNo[0];
      // }
      // else
      // {
      //   lSrNo = res.data[0].LeftSerialNo;
      // }
      
      // if(res.data[0].RightSerialNo !== null)
      // {
      //   RSrNo = res.data[0].RightSerialNo[0];
      // }
      // else
      // {
      // RSrNo = res.data[0].RightSerialNo;
      // }

      this.pid = id;
      this.isPrepopulate = true;
       if(res.status){
         this.updateButton = true;
         this.submitButton = false;
      //   this.cancelButton=true;
         this.HAForm = this.fb.group({
          "hAId": new FormControl(data.hAId),
          "patientName": new FormControl(data.patientName,[Validators.required,Validators.pattern("^[a-zA-Z ]*$")]),
          "phoneNo": new FormControl(data.phoneNo,[Validators.pattern("(0|91)?[6-9][0-9]{9}")]),
          "patientRefId": new FormControl(data.patientRefId),
          "address": new FormControl(data.address),
          "branchRefId": new FormControl(data.branchRefId),     
          "branchName": new FormControl(data.branchName),
          "pBranchName": new FormControl(data.pBranchName),
         // "deviceRefId": new FormControl(data.deviceRefId),
          // "deviceName": new FormControl(data.deviceName), 
          // "serialNo": new FormControl(data.serialNo),
          // "LeftSerialNo": new FormControl(data.LeftSerialNo),
          // "RightSerialNo": new FormControl(data.RightSerialNo),
          // "ChargerSerialNo": new FormControl(data.ChargerSerialNo),
          "noOfDays": new FormControl(data.noOfDays),
           "advanceRs": new FormControl(data.advanceRs,[Validators.required,Validators.pattern("^[0-9 .]*$")]),
          "iDProof": new FormControl(data.iDProof),
          "idProofImage": new FormControl(data.idProofImage),
          "status": new FormControl(data.status),
           "searchHA": new FormControl(data.searchHA),
           "remark": new FormControl(data.remark),
           "tempHagiven": new FormControl(data.tempHagiven),
           "tempHagivenSerialNo": new FormControl(data.tempHagivenSerialNo),
          "date": new Date(data.date),
          "clinicRefId": new FormControl(data.clinicRefId),
          "tillDate":  new FormControl(data.tillDate,[Validators.required]),
          "haFlag":  new FormControl(data.haFlag),
          "fileName":new FormControl(data.fileName),
          "fileName2":new FormControl(data.fileName2),
          "billingRefId":new FormControl(data.billingRefId),
          "Signaturephoto": '', 
          "updatedAt": new Date(),
          "deviceInfo": this.fb.array([]),
        }); 
        let preArr = [];
        data.deviceInfo.forEach((element,i) => {  
          preArr.push(element);  
          const tmpDict = {};
          tmpDict['deviceName'] = new FormControl(element.deviceName);
          tmpDict['LeftSerialNo'] = new FormControl(element.LeftSerialNo);
          tmpDict['RightSerialNo'] = new FormControl(element.RightSerialNo);
          tmpDict['ChargerSerialNo'] = new FormControl(element.ChargerSerialNo);
          tmpDict['deviceRefId'] = new FormControl(element.deviceRefId);
          //this.selectDevice(element.deviceRefId,i);
           const creds = this.HAForm.controls.deviceInfo as FormArray;
           creds.push(new FormGroup(tmpDict));
           this.selectDevice(element.deviceRefId,i);
          });
        

      
        this.signatureImg = data.Signaturephoto;
         this.superBranchId = data.hAId;
         //this.superBranchId = data.branchWiseId;
         this.bID =data.hAId;
         this.patientImage = data.idProofImage ? data.idProofImage : "../../assets/no_data_found.jpeg"
         this.patientImage2 = data.idProofImage2 ? data.idProofImage2 : "../../assets/no_data_found.jpeg"
         this.tempHagivenClick();
         this.fileName = data.fileName ? data.fileName: "no_data";
         this.hide = true;
         this.fileName2 = data.fileName2 ? data.fileName2: "no_data";
         this.hide2 = true;
       }else{
     //    //this.apimicroservice.openSnackbar(res.message);
       }
     });
  }
 
  cancelUpdate(){
    this.fileName = "no_data";
    this.fileName2 = "no_data";
    this.patientImage = "../../assets/no_data_found.jpeg";
    this.patientImage2 = "../../assets/no_data_found.jpeg";
    this.signatureImg = "";
    this.submitButton=true;
    this.updateButton=false;
    this.ngOnInit();
    this.HAForm.reset();
  }
  getAppPatient()
  {
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
    this.apimicroservice.getPatientListAll(branchRef,clinicRef).subscribe(res => {
      if (res.status) {
        // ////
        this.patientlist = res.data.data;
        this.filteredPatientList= this.patientlist.slice();
        this.selectPatient(this.patientlist[0]._id);
       this.HAForm.get("patientName").setValue(this.patientlist[0].firstName);
      }
    });
  }else{
    this.apimicroservice.getPatientListAll(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
      if (res.status) {
        // ////
        this.patientlist = res.data.data;
        this.filteredPatientList= this.patientlist.slice();
        this.selectPatient(this.patientlist[0]._id);
       this.HAForm.get("patientName").setValue(this.patientlist[0].firstName);
      }
    });
  }
  }
  selectPatient(id){
    this.HAForm.get("patientRefId").setValue(id);
   this.apimicroservice.getPatientById(id).subscribe(res => {
     ////
   // let mobile1 = res.data[0].mobile1
    this.HAForm.get("pBranchName").setValue(res.data[0].branchName);
   // this.HAForm.get("branchRefId").setValue(res.data[0].branchRefId);
   this.HAForm.get("phoneNo").setValue(res.data[0].mobile1);
   this.HAForm.get("address").setValue(res.data[0].streetAddress);
    ////
    });
  }
  getDevice(){
    this.apimicroservice.getAllDevice().subscribe(res => {
      if (res.status) {
        this.deviceList = res.data.data;
        this.filteredDeviceList= this.deviceList.slice();
        // this.selectDevice(this.deviceList[0]._id);
        // this.HAForm.get("deviceName").setValue(this.deviceList[0].model);
      }
    });
  }
  selectDevice(id,i){
    let control = (<FormArray>this.HAForm.get("deviceInfo")).at(i);
    control.get('deviceRefId').setValue(id);


    // this.HAForm.get("deviceRefId").setValue(id);
    let dstatus = "unsold";
    let deviceId = "";
    let tempsrno = [];
    if(this.userType == 'Super Admin'){
      let branchRef= sessionStorage.getItem('branchRef');
    this.apimicroservice.getStockMaintainByDeviceIdStatus(id,deviceId,dstatus,branchRef).subscribe(res => {
      ////
      let data = res.data;
      if(res.message !== 'No Data Found'){
        ////
        res.data.forEach(element =>{ 
          if(element.transferStatus == undefined || element.transferStatus == "" || element.transferStatus == "Accept" || element.transferStatus == "Reject"){
            tempsrno.push(element);
          }
         });
      // this.serialNoList[i] = data;
      this.leftSerialNoList[i] = tempsrno;
      this.rightSerialNoList[i] = tempsrno;
      }else{
        this.leftSerialNoList[i] = [];
        this.rightSerialNoList[i] = [];
      }
    })
  }else{
    let branchRef= sessionStorage.getItem('branchRef');
    this.apimicroservice.getStockMaintainByDeviceIdStatus(id,deviceId,dstatus,branchRef).subscribe(res => {
      ////
      let data = res.data;
      if(res.message !== 'No Data Found'){
        ////
        res.data.forEach(element =>{ 
          if(element.transferStatus == undefined || element.transferStatus == "" || element.transferStatus == "Accept" || element.transferStatus == "Reject"){
            tempsrno.push(element);
          }
         });
      // this.serialNoList[i] = data;
      this.leftSerialNoList[i] = tempsrno;
      this.rightSerialNoList[i] = tempsrno;
      }else{
        this.leftSerialNoList[i] = [];
        this.rightSerialNoList[i] = [];
      }
    })
  }
  }

  serialClick(srno,i){
    ////.value);
    let control = (<FormArray>this.HAForm.get("deviceInfo")).at(i);
    let rightsrno =  control.get('RightSerialNo').setValue(srno);

    // let rightsrno =  this.HAForm.get("RightSerialNo").value;
    ////
    let tempsrnos= [];
    if(rightsrno == null){
    tempsrnos = this.leftSerialNoList[i];
    tempsrnos = tempsrnos.filter(m => m.serialNumber!=srno);
  ////
  this.rightSerialNoList[i] = tempsrnos;
  }else{
    if(rightsrno == srno){
      this.apimicroservice.openSnackbar("Do not enter same left and right serial number!");
      control.get('LeftSerialNo').setValue('');
    }
  }
  }
  rightserialClick(srno,i){
    ////.value);
    // let leftsrno =  this.HAForm.get("LeftSerialNo").value;
    let control = (<FormArray>this.HAForm.get("deviceInfo")).at(i);
    let leftsrno =  control.get('LeftSerialNo').setValue(srno);

    ////
    let tempsrnos= [];
    if(leftsrno == null){
    tempsrnos = this.rightSerialNoList[i];
    tempsrnos = tempsrnos.filter(m => m.serialNumber!=srno);
  ////
  this.leftSerialNoList[i] = tempsrnos;
    }else{
      if(leftsrno == srno){
        this.apimicroservice.openSnackbar("Do not enter same left and right serial number!");
        control.get('RightSerialNo').setValue('');
        // this.HAForm.get("RightSerialNo").setValue('');
      }
    }
  }
  onChange($event: Event) { 
    const file = ($event.target as HTMLInputElement).files[0];
    const fileName = file.name;
    this.errormessage = '';
    let ext = fileName.substring(fileName.lastIndexOf(".") + 1);
    if (
      ext.toLowerCase() == "png" ||
      ext.toLowerCase() == "jpg" ||
      ext.toLowerCase() == "jpeg" ||
      ext.toLowerCase() == "csv" ||
      ext.toLowerCase() == "xlsx" ||
      ext.toLowerCase() == "pdf" ||
      ext.toLowerCase() == "docx" 
    ) { 
      this.convertToBase64(file);
      this.hide = true;
      this.fileName = fileName;
    }
    
  }
  convertToBase64(file: File) {
    const observable = new Observable((subscriber: Subscriber<any>) => {
      this.readFile(file, subscriber);
    });
    observable.subscribe((d) => {
      this.patientImage = d;
      
    });
  }
  readFile(file: File, subscriber: Subscriber<any>) {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      subscriber.next(fileReader.result);
      subscriber.complete();
    };
    fileReader.onerror = (error) => {
      subscriber.error(error);
      subscriber.complete();
    };
  } 

  onChangeFile($event: Event) {
    const file = ($event.target as HTMLInputElement).files[0];
    const fileName = file.name;
    if (file.size >= 3000000) {
     // this.apimicroservice.openSnackbar("Max upload size allowed is 3mb");
      this.errormessage = "Max upload size allowed is 3mb";
    } else {
      this.errormessage = '';
      let ext = fileName.substring(fileName.lastIndexOf(".") + 1);
      if (
        ext.toLowerCase() == "png" ||
        ext.toLowerCase() == "jpg" ||
        ext.toLowerCase() == "jpeg"
      ) {
        this.convertToBase642(file);
        this.hide2 = true;
        this.fileName2 = fileName;
      }
    }
  }
  convertToBase642(file: File) {
    const observable = new Observable((subscriber: Subscriber<any>) => {
      this.readFile2(file, subscriber);
    });
    observable.subscribe((d) => {
      this.patientImage2 = d;
      
    });
  }
  readFile2(file: File, subscriber: Subscriber<any>) {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      subscriber.next(fileReader.result);
      subscriber.complete();
    };
    fileReader.onerror = (error) => {
      subscriber.error(error);
      subscriber.complete();
    };
  } 
  getLastId()
  {
    if(this.userType !== 'Super Admin'){
    this.apimicroservice.getLastHAID(this.branchRef).subscribe(res => {
      ////   
     
      if (res['status']) {
        
        let clientIdd = res.data.totalItems;
        ////
        this.HAForm.controls['hAId'].setValue(clientIdd + 1);
        this.bID=clientIdd+1;
        //this.HAForm.controls['branchWiseId'].setValue(clientIdd + 1);
        
      } else {
        this.apimicroservice.openSnackbar("Something went wrong");
      }
     
    })
  }
  else
  {
    this.apimicroservice.getLastHAFormAll().subscribe(res => {
      ////   
     
      if (res['status']) {
        
        let clientIdd = res.data.totalItems;
        ////
        this.superBranchId = clientIdd + 1;
        this.bID = clientIdd + 1;
      } else {
        this.apimicroservice.openSnackbar("Something went wrong");
      }
     
    })
  }
  }
  saveData(){
    //this.setValidator();
    if (this.HAForm.invalid) {
      this.HAForm.get("patientName").markAsTouched();
      this.HAForm.get("phoneNo").markAsTouched();
      this.HAForm.get("tillDate").markAsTouched();
      this.HAForm.get("advanceRs").markAsTouched();
      return;
    } else {
      this.spinner.show();
      let payload = this.HAForm.value;
      ////
      payload.createdAt= new Date();
      payload.fileName = this.fileName;
      payload.fileName2 = this.fileName2;
      payload.idProofImage = this.patientImage;
      payload.idProofImage2 = this.patientImage2;
      payload.Signaturephoto = this.signatureImg;
      payload.haFlag = 'No';
      
      if(this.userType !== 'Super Admin')
      {
        payload.branchRefId = this.branchRef;
        payload.clinicRefId = this.clinicRef;
        payload.branchName = this.branchName;
        ////
      }
        this.apimicroservice.addHAFormData(payload).subscribe(res => {
          this.spinner.hide();
          if (res.status) {
            ////
            this.apimicroservice.openSnackbar(res.message);
            this.signatureImg = "";
            this.signaturePad.clear();
           this.ngOnInit();
           this.HAForm.reset();
          } else {
            this.apimicroservice.openSnackbar(res.message); 
          } 
        },(ERROR:HttpErrorResponse) => {
          this.apimicroservice.openSnackbar(ERROR.error.message);
         this.spinner.hide();
      });
    }
    
  }
  fetchHAList() {
    let pg = 1;
    let size = 10;
    let search='';
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    this.spinner.show();
    this.searchText = this.HAForm.get('searchHA').value; 
     if(this.userType !== 'Super Admin')
     {
      this.apimicroservice.getHAList(pg,size,search,branchRef,clinicRef).subscribe(res => {
        this.spinner.hide(); 
          this.listOfHAForm = res.data.data;
          this.totalLength = res.data.totalItems;
         if(res.status){
          // this.apimicroservice.openSnackbar(res.message);        
         }else{
           //this.apimicroservice.openSnackbar(res.message);
         }
       });
     }
     else
     {
      this.apimicroservice.getHAList(pg,size,search,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
        this.spinner.hide(); 
          this.listOfHAForm = res.data.data;
          this.totalLength = res.data.totalItems;
         if(res.status){
         //  this.apimicroservice.openSnackbar(res.message);        
         }else{
           //this.apimicroservice.openSnackbar(res.message);
         }
       });
     }
    
   
  }
  onKeyUpEvent(event: any) {
    this.searchText = this.HAForm.get('searchHA').value;
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    let page=1;
    let size=10;
    if(this.userType !== 'Super Admin')
    {
      ////
      
      this.apimicroservice.getHAList(page,size,this.searchText,branchRef,clinicRef).subscribe(res => {
        this.spinner.hide(); 
          this.listOfHAForm = res.data.data;
          this.totalLength = res.data.totalItems;
          this.page=res.data.pageNumber;
        //this.buildingLenght = this.listofbuild.length;
       if(res.status){
        //  this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
    }
    else{
      ////
      this.apimicroservice.getHAList(page,size,this.searchText,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
        this.spinner.hide(); 
          this.listOfHAForm = res.data.data;
          this.totalLength = res.data.totalItems;
          this.page=res.data.pageNumber;
        //this.buildingLenght = this.listofbuild.length;
       if(res.status){
        //  this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
    }
  }
  onPageChange(event) {
  //  this.spinner.show();
    this.page = event;
    this.searchText = '';
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    
    if(this.userType !== 'Super Admin')
    {
      this.apimicroservice.getHAList(this.page,this.size,this.searchText,branchRef,clinicRef).subscribe(res => {
       // this.spinner.hide(); 
          this.listOfHAForm = res.data.data;
          this.totalLength = res.data.totalItems;
        //this.buildingLenght = this.listofbuild.length;
       if(res.status){
        //  this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
  
     });
    }
    else{
      this.apimicroservice.getHAList(this.page,this.size,this.searchText,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
        //this.spinner.hide(); 
          this.listOfHAForm = res.data.data;
          this.totalLength = res.data.totalItems;
          //this.buildingLenght = this.listofbuild.length;
         if(res.status){
          //  this.apimicroservice.openSnackbar(res.message);
           
         }else{
           //this.apimicroservice.openSnackbar(res.message);
         }
    
       });
    }
  }
  updateHADetails() {
  //  this.setValidator();
        this.HAForm.controls['tillDate'].setErrors(null);
      this.HAForm.controls['tillDate'].clearValidators();
      this.HAForm.controls['tillDate'].updateValueAndValidity();
    if (this.HAForm.invalid) {
      
      this.HAForm.get("patientName").markAsTouched();
      this.HAForm.get("phoneNo").markAsTouched();
      this.HAForm.get("tillDate").markAsTouched();
      this.HAForm.get("advanceRs").markAsTouched();
     
      return;
    } else {
      this.updateHAForm();
    }
  }
  updateHAForm() {
    this.spinner.show();
    let payload = this.HAForm.value;
    payload.fileName  = this.fileName;
    payload.fileName2  = this.fileName2;
    payload.idProofImage = this.patientImage;
    payload.idProofImage2 = this.patientImage2;
    payload.Signaturephoto = this.signatureImg; 
    if(this.userType !== 'Super Admin')
    {
      payload.branchRefId = this.branchRef;
      payload.clinicRefId = this.clinicRef;
      payload.branchName = this.branchName;
    }
     this.apimicroservice.updateHADetails(payload,this.pid).subscribe(res => {
       ////
       this.spinner.hide();
      this.signatureImg = "";
      this.signaturePad.clear();
       if(res.status){ 
         this.apimicroservice.openSnackbar(res.message);
         this.fileName = "no_data"
         this.fileName2 = "no_data"
         this.patientImage = "../../assets/no_data_found.jpeg"; 
         this.patientImage2 = "../../assets/no_data_found.jpeg";
          this.updateButton = false;
          this.submitButton = true;
         // this.cancelButton=false;
          this.ngOnInit();
       }else{
       //  //this.apimicroservice.openSnackbar(res.message);
       }
     });
    
  }
  dayChange()
  {
    let payload = this.HAForm.value;
    ////);
     this.returnDate = new Date();
     this.returnDate.setDate(this.returnDate.getDate() + parseInt(payload.noOfDays));
   // this.returnDate.setDate(this.returnDate.getDate() + (payload.noOfDays*60*60*1000));
    ////
    this.HAForm.get("date").setValue(this.returnDate);
    
  }

  openPopUp(i) {

    let control = (<FormArray>this.HAForm.get("deviceInfo")).at(i);
    let ssno =  control.get('LeftSerialNo').value;
    // let ssno = this.HAForm.get('LeftSerialNo').value;
    ////
    const dialogRef1 = this.dialog.open(HaFormSerialPopupComponent,
      {
        width: "600px",
        data: {
          serialValue: ssno
        }
      });
    dialogRef1.afterClosed().subscribe((result) => {
      let tempsrno = [];
      ////
      if(result !== undefined){
        result.data.forEach(element =>{
          tempsrno.push(element.leftSerialNumber);
          ////
         })
         control.get('LeftSerialNo').setValue(tempsrno);
        //  this.HAForm.controls['LeftSerialNo'].setValue(tempsrno);
      }
    });
  }

  openPopUpRight(i){
    // let ssno = this.HAForm.get('RightSerialNo').value;
    let control = (<FormArray>this.HAForm.get("deviceInfo")).at(i);
    let ssno =  control.get('RightSerialNo').value;
    const dialogRef2 = this.dialog.open(HaFormRightserialPopupComponent,
      {
        width: "600px",
        data: {
          serialValue: ssno
        }
      });
    dialogRef2.afterClosed().subscribe((result) => {
      let tempsrno = [];
      ////
      if(result !== undefined){
      result.data.forEach(element =>{
        tempsrno.push(element.rightSerialNumber);
        ////
       })
       control.get('RightSerialNo').setValue(tempsrno);
      //  this.HAForm.controls['RightSerialNo'].setValue(tempsrno);
      }
    });
  }
  openPopUpCharger(i){
    // let ssno = this.HAForm.get('ChargerSerialNo').value;
    let control = (<FormArray>this.HAForm.get("deviceInfo")).at(i);
    let ssno =  control.get('ChargerSerialNo').value;
    const dialogRef3 = this.dialog.open(HaFormChargerserialPopupComponent,
      {
        width: "600px",
        data: {
          serialValue: ssno
        }
      });
    dialogRef3.afterClosed().subscribe((result) => {
      let tempsrno = [];
      ////
      if(result !== undefined){
      result.data.forEach(element =>{
        tempsrno.push(element.chargerSerialNumber);
        ////
       })
       control.get('ChargerSerialNo').setValue(tempsrno);
      //  this.HAForm.controls['ChargerSerialNo'].setValue(tempsrno);
      }
    });
  }
  openPatientPopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpPatientComponent,
      {
        width: "1200px",
      });
      dialogRef3.afterClosed().subscribe((result) => {
        let clinicRef= sessionStorage.getItem('clinicRef');
        let branchRef= sessionStorage.getItem('branchRef');
        let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
        let branchRefSuperAdmin= '';
        if (this.userType !== 'Super Admin') {
        this.apimicroservice.getPatientListAll(branchRef,clinicRef).subscribe(res => {
          if (res.status) {
            // ////
            this.patientlist = res.data.data;
            this.filteredPatientList= this.patientlist.slice();
            this.selectPatient(this.patientlist[0]._id);
            //this.HAForm.get("patientName").setValue(res.data.data[0]);
            ////
            this.HAForm.get("patientName").setValue(this.patientlist[0].firstName + " " +this.patientlist[0].lastName);
          }
        });
        }else{
          this.apimicroservice.getPatientListAll(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
            if (res.status) {
              // ////
              this.patientlist = res.data.data;
              this.filteredPatientList= this.patientlist.slice();
              this.selectPatient(this.patientlist[0]._id);
              //this.HAForm.get("patientName").setValue(res.data.data[0]);
              ////
              this.HAForm.get("patientName").setValue(this.patientlist[0].firstName + " " +this.patientlist[0].lastName);
            }
          });
        }
         //  this.onSelectPatient(this.patientList[0]._id);
        //  this.customHaForm.get("patientRefId").setValue(this.patientList[0]._id);
       });
  }  
  openBranchPopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpBranchComponent,
      {
        width: "1200px",
      });
      dialogRef3.afterClosed().subscribe((result) => {
        let clinicRef= sessionStorage.getItem('clinicRef');
        this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
          ////  
          if (res.status) {    
            this.branchList = res.data;
            this.filteredListBranch= this.branchList.slice();
            this.branchClick(res.data[0]._id,res.data[0].branchName);
            this.HAForm.get("branchRefId").setValue(res.data[0]._id);
          } else {
           // this.apimicroservice.openSnackbar(res.message);
          }
        })
        //this.fetchBranchList();
      })
  }

  openDevicePopUp(i){
    //localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpDeviceComponent,
      {
        width: "1200px",
      });
      dialogRef3.afterClosed().subscribe((result) => {
       
        this.apimicroservice.getAllDevice().subscribe(res => {
          if (res.status) {
            this.deviceList = res.data.data;
            this.filteredDeviceList= this.deviceList.slice();
           
            let control = (<FormArray>this.HAForm.get("deviceInfo")).at(i);
            control.get('deviceName').setValue(this.deviceList[0]._id);
            this.selectDevice(this.deviceList[0]._id,i);
          }
        });
        
       });
  }
  fetchBranchList() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      ////  
      if (res.status) {    
        this.branchList = res.data;
        this.filteredListBranch= this.branchList.slice();
      } else {
       // this.apimicroservice.openSnackbar(res.message);
      }
    })
  } 
  branchClick(id,branchName)
  {
    this.HAForm.controls["branchName"].setValue(branchName);

   this.apimicroservice.getBranchById(id).subscribe(res => { 
     let payload = this.HAForm.value;
      payload.clinicRefId = res.data[0].clinicRefId;
      this.HAForm.controls["clinicRefId"].setValue(res.data[0].clinicRefId);
      if(this.updateButton == false){
        this.spinner.show();
        this.apimicroservice.getLastHAID(payload.branchRefId).subscribe(res => {
          ////   
          if (res['status']) {
             
              let clientIdd = res.data.totalItems;
              ////
              this.HAForm.controls['hAId'].setValue(clientIdd + 1);
             // this.HAForm.controls['branchWiseId'].setValue(clientIdd + 1);
              
          } else {
            this.apimicroservice.openSnackbar("Something went wrong");
          }
          this.spinner.hide();
        })
      }
    });
  }
  tempHagivenClick()
  {
    let payload = this.HAForm.value;
    if(payload.tempHagiven == 'Yes')
    {
      this.isTempGiven =true;
    }
    else{
      this.isTempGiven =false;
    }
    
  }
  
  printThisPage(_callback) {
  
   
    //   return new Promise<void>((resolve, reject) => {
    //     ////
    //     resolve();
    // });
    
    ////
    this.ifPrint='true';
    _callback(); 
      
  }
 
//    f1() {
//     this.ifPrint='true';
//     ////
// }

//  f2() {
//    ////
//    const printContents = document.getElementById('permit').innerHTML;
// const originalContents = document.body.innerHTML;
// document.body.innerHTML = printContents;
//    window.print();
// }
// async global() {
//   await this.f1();
//   this.f2();
// }

global() {
  this.f1().then(res => {
      this.f2()
  })
}

f1() {
  return new Promise<any>((resolve, reject) => {

         this.ifPrint='true';
    ////
let x =1;
      setTimeout( () => {
          resolve(x);
      }, 100);
      
  });
  
}

f2() {
  this.ifPrint='false';
  //const printContents = document.getElementById('permit').innerHTML;
//const originalContents = document.body.innerHTML;
//document.body.innerHTML = printContents;
 // window.print();
 let currentDate =new Date();
let date=currentDate.toLocaleDateString('en-GB');
 let titleName='Hearing Aid Trial Form_'+date
 var tempTitle = document.title;
 document.title = titleName;
 window.print();
 document.title = tempTitle;
} 
WhatsApp()
  {
    let payload = this.HAForm.value;  
    let num = payload.phoneNo; 
    if(num.length == 10)
    {
      sessionStorage.setItem('whatsAppNumber',payload.phoneNo);
      let mobileNumber = sessionStorage.getItem('whatsAppNumber');
      window.open("https://wa.me/91"+mobileNumber);
    }
    else
    {
      this.HAForm.get("phoneNo").markAsTouched();
    }
  }
  onback() {
    this.apimicroservice.back()
  }
  ageCalculate()
{
  let payload = this.HAForm.value;
    // To set two dates to two variables 
    var date1 = new Date(); 
	var date2 = new Date(payload.tillDate); 
  
    var Time = date2.getTime() - date1.getTime(); 
    var Days = Math.ceil(Time / (1000 * 60 * 60 * 24)); //Diference in Days
////
this.HAForm.controls["noOfDays"].setValue(Days);
}

openPatientSearchPopUp() {
  const dialogRef3 = this.dialog.open(PatientSearchPopComponent, {
    width: "97%",
    height: "90%", 
  });

  dialogRef3.afterClosed().subscribe((result) => {
    this.HAForm.get("patientName").setValue(result.data.firstName + " "+ result.data.lastName);
    //this.getAppPatient();
    this.selectPatient(
      result.data._id
    );
  });
} 
printForm() {
  this.router.navigate(['ha-form-print',this.pid]);
 }
 redirect(to): void {
  this.router.navigate([to]);
} 
canvasClick()
{
  this.signPadShow = true;
 this.signatureImg = ""; 
 ////
 
}
remove(){
  this.hide = false;
}
remove2(){
  this.hide2 = false;
}
showbase64(e){
  const dialogRef3 = this.dialog.open(PdfviewerComponent,
    {
      width: "1200px",
      data: e
    });
}
}