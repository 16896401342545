import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiServiceService } from '../api-service.service';

@Component({
  selector: 'app-faceplate',
  templateUrl: './faceplate.component.html',
  styleUrls: ['./faceplate.component.scss']
})
export class FaceplateComponent implements OnInit {
  faceplateForm: FormGroup;
  
  totalLength: any;
  searchText: any;
  page:number = 1;
  size:number = 3;
  sid: any;
  submitButton = true;
  updateButton = false;
  cancelButton = false;
  listOfFaceplate: any;
 
  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<FaceplateComponent>,@Inject(MAT_DIALOG_DATA) public data:any, 
    public apimicroservice: ApiServiceService,
    private spinner: NgxSpinnerService,

  ) { FaceplateComponent}

  ngOnInit(): void {
    this.page = 1;
    this.faceplateForm = this.fb.group({  
      "faceplate": new FormControl('',[Validators.required,Validators.pattern("^[a-zA-Z0-9 -]+$")]),
      "createdAt": new Date(),
      "searchfaceplate": new FormControl(''),
    });
    this.fetchFaceplateList();
  }


  fetchFaceplateList() {
    let pg = 1;
    let size = 3;
    let search='';
        this.apimicroservice.getFaceplateData(pg,size,search).subscribe(res => {
           this.listOfFaceplate = res.data.data;
           this.totalLength = res.data.totalItems;
           //this.buildingLenght = this.listofbuild.length;
          if(res.status){
           // this.apimicroservice.openSnackbar(res.message);
            
          }else{
            //this.apimicroservice.openSnackbar(res.message);
          }
     
        });   
 
  }
  saveFaceplate(){
    let payload = this.faceplateForm.value; 
    //
    this.spinner.show();
    this.apimicroservice.saveFaceplateData(payload).subscribe(res => {
      //
      this.spinner.hide();
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);      
        //this.ngOnInit();
       this.onClose();        
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    },(ERROR:HttpErrorResponse) => {
      this.apimicroservice.openSnackbar(ERROR.error.message);
      this.spinner.hide();
      this.onClose();    
  });
  }
  save() {
    if (this.faceplateForm.invalid) {
      this.faceplateForm.get("faceplate").markAsTouched();
      return;
    } else {
      this.saveFaceplate();
    }
  }
  onKeyUpEvent(event: any) {
    this.searchText = this.faceplateForm.get('searchfaceplate').value;
    let page=1;
    let size=10
    this.apimicroservice.getFaceplateData(page,size,this.searchText).subscribe(res => {
      this.listOfFaceplate = res.data.data;
      this.totalLength = res.data.totalItems;
      this.page=res.data.pageNumber;
       if(res.status){
        //  this.apimicroservice.openSnackbar(res.message);    
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
  }

  prepopulation(id) {
    this.apimicroservice.getFaceplateDataById(id).subscribe(res => {
      let data = res.data[0];
      this.sid = id;
       if(res.status){
        this.submitButton = false;
         this.updateButton = true;
         this.cancelButton = true;
         this.faceplateForm = this.fb.group({
          "faceplate" :new FormControl(data.faceplate),
          "updateddAt": new Date(),
         });
       }else{
     //    //this.apimicroservice.openSnackbar(res.message);
       }
     });
  }
  updateFaceplate() {
    let payload = this.faceplateForm.value;
     this.apimicroservice.updateFaceplateData(payload,this.sid).subscribe(res => {
       if(res.status){
         this.apimicroservice.openSnackbar(res.message);
          this.updateButton = false;
          this.submitButton = true;
          this.ngOnInit();
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
    
  }
  onPageChange(event) {
    this.page = event;
   // let p = this.page - 1;
    this.searchText = this.faceplateForm.get('searchfaceplate').value;
 
    this.apimicroservice.getFaceplateData(this.page,this.size,this.searchText).subscribe(res => {
      this.listOfFaceplate = res.data.data;
      //
      
      this.totalLength = res.data.totalItems;
         if(res.status){
          //  this.apimicroservice.openSnackbar(res.message);
           
         }else{
           //this.apimicroservice.openSnackbar(res.message);
         }
    
       });
  
  }
  cancelUpdate(){
    this.faceplateForm.reset();
    this.submitButton=true;
    this.updateButton=false;
    this.cancelButton=false;
    this.ngOnInit();
  }
  onClose(): void {
    this.dialogRef.close();
  }
}
