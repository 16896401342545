import { Component, Inject, OnInit } from "@angular/core";
import { ApiServiceService } from '../api-service.service';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import * as moment from 'moment';

@Component({
  selector: 'app-popup-confirmation-msg',
  templateUrl: './popup-confirmation-msg.component.html',
  styleUrls: ['./popup-confirmation-msg.component.scss']
})
export class PopupConfirmationMsgComponent implements OnInit {
  pName: any;
  billNo: any;
  dataFrom: any;
  constructor(public dialogRef: MatDialogRef<PopupConfirmationMsgComponent>,@Inject(MAT_DIALOG_DATA) public data:any,
  public apimicroservice: ApiServiceService,private spinner: NgxSpinnerService,) { 

    dialogRef.disableClose = true; 
  } 

  ngOnInit(): void {
    this.dataFrom = this.data.from;
this.pName = this.data.pData;
this.billNo = this.data.billNo;
  }
  confirmSubmit(){
    this.onClose('Yes');
  }

  confirmCancel(){
   this.onClose('No');
  }

  onClose(e): void {
    this.dialogRef.close(e);
  }
}
