<div class="container-fluid " [formGroup]="doctorMasterForm">
    <form [formGroup]="doctorMasterForm" autocomplete="off" novalidate>
      <div class="row mtt">
     
        <div class="col-sm-12">
  
          <div class="row">
            <div class="col-sm-12 pr-4">
          
                  <div class="row ">
                    <div class="col-sm-11">
                        <div class="row ">
                            <!-- <span><img src="../../assets/earDoctor.png" width="30rem"> </span>  -->
                            <span> <h1 class="fass ml-3 pt-1">Add Audiologists</h1></span>
                        </div>
                        
                      </div>
                      <div class="col-sm-1">
                        <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                          <a (click)="onClose()" matTooltip="Close" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">close</span></a>
                          </div>
                      </div>
                 
             </div>
                  <div class="row">
                    <div class="col-sm-2">
  
                      <!-- <h1 class="titlecolor"> Patient Master</h1> -->
                      <div class="col-sm-12 bg-secondary rounded-3 p-1 pb-2 mb-4">
  
  
                        <div class="col-sm-12 ">
  
                          <img *ngIf="!doctorImage" class=" img2 frame rounded-circle" src="../../assets/main.jpeg"
                            alt="Amanda Wilson" width="500rem">
                          <img *ngIf="doctorImage" class=" img2 frame rounded-circle" [src]="doctorImage"
                            alt="doctor photo" width="500rem">
                        </div>
                        <div class="col-sm-12 btnpossition ">
  
                          <input #fileUpload type="file" style="display: none;" (change)="onChange($event)" />
                          <button class="btn btn-light shadow btn-sm m-0 example-full-width"
                            matTooltip="Upload JPG, GIF or PNG image.300 x 300 required." (click)="fileUpload.click()"><i
                              class="ai-refresh-cw me-2"></i>Upload Photo</button>
                        </div>
                      </div>
  
  
                    </div>
                    <div class="col-sm-10">
                      <div class="row pt-1 pr-1">
                        <div class=" col-sm-3 ">
                          <div class="row p">
                            <div class=" col-sm-3 mt-3 ">
                             
                               <!-- <mat-form-field *ngIf="userType !== 'Super Admin'" class="example-full-width" appearance="outline">
                                <mat-label>Audiologists Id</mat-label>
                                <input matInput formControlName="branchWiseId" readonly  />   
                              </mat-form-field> -->
                              <!-- <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Audiologists Id</mat-label>
                                <input matInput [value]="superBranchId" readonly  />
                                </mat-form-field> -->
                                <mat-label class="center" formControlName="branchWiseId" ngDefaultControl [value]="superBranchId"  >{{superBranchId}}</mat-label>
                            </div>
                            <div class=" col-sm-9 ">
                              <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Prefix
                                  <!-- <span class="required-asterisk">*</span> -->
                                </mat-label>
  
                                <mat-select  #select1 placeholder="PreFix" class="colorblack" formControlName="prefix">
                                  <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'name'" [array]="allPreFix"
                                    (filteredReturn)="filteredListPreFix =$event"></mat-select-filter>
                                  <mat-option *ngFor="let item of filteredListPreFix" [value]="item.value">
                                    {{item.name}}
                                  </mat-option>
                                </mat-select>
                               
  
                              </mat-form-field>
                              <!-- <mat-error class="margin_tp"
                              *ngIf="doctorMasterForm.controls['prefix'].touched && doctorMasterForm.controls['prefix'].errors?.required">
                              mandatory.
                            </mat-error> -->
                            </div>
                          </div>
                        </div>
  
                        <div class="col-sm-3 ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>First Name
                              <!-- <span class="required-asterisk">*</span> -->
                            </mat-label>
                            <input required matInput formControlName="firstName" placeholder="First Name"  />
                            
                          </mat-form-field>
                          <mat-error class="margin_tp"
                          *ngIf="doctorMasterForm.controls['firstName'].touched && doctorMasterForm.controls['firstName'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                        <mat-error class="margin_tp"
                          *ngIf="doctorMasterForm.controls['firstName'].touched && doctorMasterForm.controls['firstName'].errors?.pattern">
                          Please enter valid first name.
                        </mat-error>
                        </div>
  
                        <div class="col-sm-3 ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Middle Name</mat-label>
                            <input matInput placeholder="Middle Name" formControlName="middleName" />
                           
                          </mat-form-field>
                          <mat-error class="margin_tp"
                          *ngIf="doctorMasterForm.controls['middleName'].touched && doctorMasterForm.controls['middleName'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                        <mat-error class="margin_tp"
                          *ngIf="doctorMasterForm.controls['middleName'].touched && doctorMasterForm.controls['middleName'].errors?.pattern">
                          Please enter valid middle name.
                        </mat-error>
                        </div>
                        <div class="col-sm-3 ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Last Name
                              <!-- <span class="required-asterisk">*</span> -->
                            </mat-label>
                            <input required matInput placeholder="Last Name" formControlName="lastName"  />
                                                   </mat-form-field>
                                                   <mat-error class="margin_tp"
                                                   *ngIf="doctorMasterForm.controls['lastName'].touched && doctorMasterForm.controls['lastName'].errors?.required">
                                                   This field is mandatory.
                                                 </mat-error>
                                                 <mat-error class="margin_tp"
                                                   *ngIf="doctorMasterForm.controls['lastName'].touched && doctorMasterForm.controls['lastName'].errors?.pattern">
                                                   Please enter valid last name.
                                                 </mat-error>
                       
                        </div>
  
  
                        <div class="col-sm-3 ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Alternate Name </mat-label>
                            <input matInput matTooltip="Previous Names e.g. Maiden Names" formControlName="alternateName"
                              placeholder="Alternate Name" />
                            <mat-error class="margin_tp"
                              *ngIf="doctorMasterForm.controls['alternateName'].touched && doctorMasterForm.controls['alternateName'].errors?.pattern">
                              Please enter valid Alternate name.
                            </mat-error>
                          </mat-form-field>
                        </div>
                        <div class="col-sm-3 ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>DOB
                              <!-- <span class="required-asterisk">*</span> -->
                            </mat-label>
                            <input required matInput  formControlName="dob" 
                            [min]="minDate" [max]="maxdate" (dateChange)="ageCalculate()" [matDatepicker]="dateOfBirth" id="dateOfBirth"
                              placeholder="DD/MM/YYYY" >
  
                            <mat-datepicker-toggle matSuffix [for]="dateOfBirth">
                            </mat-datepicker-toggle>
                            <mat-datepicker #dateOfBirth></mat-datepicker>
                           
                          </mat-form-field>
                          <mat-error class="mt-1"
                          *ngIf="doctorMasterForm.controls['dob'].touched && doctorMasterForm.controls['dob'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                        </div>
                        <div class="col-sm-3">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Age
                              <!-- <span class="required-asterisk">*</span> -->
                            </mat-label>
                            <input matInput placeholder="Age" formControlName="age" />
  
                          </mat-form-field>
  
                        </div>
                        <div class="col-sm-3 ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label> Gender 
                              <!-- <span class="required-asterisk">*</span> -->
                            </mat-label>
  
                            <mat-select required  formControlName="sex">
                              <mat-option value="Male">Male</mat-option>
                              <mat-option value="Female">Female</mat-option>
                              <mat-option value="Transgender">Transgender</mat-option>
                            </mat-select>
                           
                          </mat-form-field>
                          <mat-error class="mt-1"
                          *ngIf="doctorMasterForm.controls['sex'].touched && doctorMasterForm.controls['sex'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                        </div>
                        <div class="col-sm-3 ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Degree</mat-label>
                            <input matInput placeholder="Degree" formControlName="degree" />
                          </mat-form-field>
                        </div>
                       
  
  
                        <div class="col-sm-12 ">
                          <label class="header">Contact Details :</label>
                        </div>
                        <div class="col-sm-3">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Landline 1</mat-label>
                            <input matInput maxlength="15" placeholder=" Landline eg 0223-666666" formControlName="landline" />
  
                          </mat-form-field>
  
                        </div>
  
                        <div class="col-sm-3 ">
                          <div class="row">
                            <div class="col-sm-10">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label> Mobile 1 
                              <!-- <span class="required-asterisk">*</span> -->
                            </mat-label>
                            <input required matInput maxlength="10" (change)="changemob1()" formControlName="mobile1"
                              placeholder="Mobile 1" />
                                                   </mat-form-field>
                                                   <mat-error class="margin_tp"
                                                   *ngIf="doctorMasterForm.controls['mobile1'].touched && doctorMasterForm.controls['mobile1'].errors?.required">
                                                   This field is mandatory.
                                                 </mat-error>
                                                 <mat-error class="margin_tp"
                                                   *ngIf="doctorMasterForm.controls['mobile1'].touched && doctorMasterForm.controls['mobile1'].errors?.pattern">
                                                   Enter valid Mobile1 Number.
                                                 </mat-error>
                       
                        </div>
                        <div class="col-sm-2 wApp">
                          <i class="bi bi-whatsapp whatsapp-icon wApp fa-lg" (click)="WhatsApp();" matTooltip="WhatsApp Integration" ></i>
                          </div>
                        </div>
                        </div>
                        <div class="col-sm-3 ">
                          <div class="row">
                            <div class="col-sm-10">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Mobile 2</mat-label>
                            <input matInput maxlength="10" (change)="changemob2()" formControlName="mobile2" placeholder="Mobile 2 " />
                                                    </mat-form-field>
                                                    <mat-error class="margin_tp"
                              *ngIf="doctorMasterForm.controls['mobile2'].touched && doctorMasterForm.controls['mobile2'].errors?.required">
                              This field is mandatory.
                            </mat-error>
                            <mat-error class="margin_tp"
                              *ngIf="doctorMasterForm.controls['mobile2'].touched && doctorMasterForm.controls['mobile2'].errors?.pattern">
                              Enter valid Mobile2 Number.
                            </mat-error>
  </div>
  <div class="col-sm-2 wApp">
    <i class="bi bi-whatsapp whatsapp-icon wApp fa-lg" (click)="WhatsApp2();" matTooltip="WhatsApp Integration" ></i>
    </div>
  </div>
                        </div>
  
                        <div class="col-sm-3 ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label> Email Address</mat-label>
                            <input matInput type="email" class="emailClass" placeholder="Email" formControlName="email" />
  
                            <mat-error class="margin_tp"
                              *ngIf="doctorMasterForm.controls['email'].touched && doctorMasterForm.controls['email'].errors?.pattern">
                              Please enter a valid Email Address.
                            </mat-error>
                          </mat-form-field>
  
                        </div>
  
  
                      </div>
                    </div>
                  </div>
                  <div class="row pl-2 pr-2">
                    <div class="col-sm-12">
  
                    
                      <div class="row">
                        <div class="col-sm-12 ">
                          <label class="header">Address Details :</label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-3 ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Street Address
                              <!-- <span class="required-asterisk">*</span> -->
                            </mat-label>
                            <input required matInput placeholder="Street Address" formControlName="streetAddress" />
                           
                          </mat-form-field>
                          <mat-error class="margin_tp"
                          *ngIf="doctorMasterForm.controls['streetAddress'].touched && doctorMasterForm.controls['streetAddress'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                        </div>
                        <div class="col-sm-3 ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Taluka</mat-label>
                            <input matInput placeholder="Taluka " formControlName="taluka">
  
  
                          </mat-form-field>
  
                        </div>
                        <div class="col-sm-3 ">
                          <!-- <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>District </mat-label>
                            <input matInput placeholder="District " formControlName="district">
  
  
  
                          </mat-form-field> -->
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>District</mat-label>
                            <mat-select   #select12 placeholder="District" class="colorblack"  formControlName="district"   >
                              <mat-select-filter  *ngIf="select12.focused" [placeholder]="'Filter'" [displayMember]="'district'"
                                [array]="districtData2" (filteredReturn)="filteredListDistrict2 =$event"></mat-select-filter>
                              <mat-option *ngFor="let item of filteredListDistrict2" 
                               [value]="item.district" >
                                {{item.district}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="col-sm-3 ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>State</mat-label>
                            <mat-select   #select11 placeholder="State" class="colorblack"  formControlName="state"   >
                              <mat-select-filter  *ngIf="select11.focused" [placeholder]="'Filter'" [displayMember]="'state'"
                                [array]="stateCityArr2"  (filteredReturn)="filteredList2 =$event"></mat-select-filter>
                              <mat-option *ngFor="let item of filteredList2" 
                               [value]="item.state" (click)="getDistrict2(item.districts)">
                                {{item.state}}
                              </mat-option>
                            </mat-select>
                          
                          </mat-form-field>
                          <mat-error class="margin_tp"
                          *ngIf="doctorMasterForm.controls['state'].touched && doctorMasterForm.controls['state'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                        </div>
                        <div class="col-sm-3 ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Pincode 
                              <!-- <span class="required-asterisk">*</span> -->
                            </mat-label>
                            <input required matInput maxlength="6" placeholder="Pincode(e.g:416008)" formControlName="pincode" />
                            
                          </mat-form-field>
                          <mat-error class="margin_tp"
                              *ngIf="doctorMasterForm.controls['pincode'].touched && doctorMasterForm.controls['pincode'].errors?.pattern">
                              Enter only number.
                            </mat-error>
                            <mat-error class="margin_tp"
                              *ngIf="doctorMasterForm.controls['pincode'].touched && doctorMasterForm.controls['pincode'].errors?.maxlength">
                              Enter 6 digit Pincode.
                            </mat-error>
                            <mat-error class="margin_tp"
                              *ngIf="doctorMasterForm.controls['pincode'].touched && doctorMasterForm.controls['pincode'].errors?.minlength">
                              Enter 6 digit Pincode.
                            </mat-error>
                            <mat-error class="margin_tp"
                            *ngIf="doctorMasterForm.controls['pincode'].touched && doctorMasterForm.controls['pincode'].errors?.required">
                            This field is mandatory.
                          </mat-error>
                        </div>
                        <div class="col-sm-3 ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Country </mat-label>
                            <input matInput formControlName="country" placeholder="country " />
                           
                          </mat-form-field>
                          <mat-error class="margin_tp"
                          *ngIf="doctorMasterForm.controls['country'].touched && doctorMasterForm.controls['country'].errors?.pattern">
                          Country name is invalid and should be starts with capital letter.
                        </mat-error>
                        </div>
                       
                       <!-- <div class="col-sm-6"></div> -->
                        <div class=" col-sm-4 mt-2">
  
                          <mat-checkbox class="example-margin header" formControlName="altAddress" [checked]="isChecked"
                            (change)="onChangeAltAddress($event)"> Alternate Address
                          </mat-checkbox>
                        </div>
                      </div>
                    </div>
                    <div class="row pl-3 pr-3">
                      
                      <div class="col-sm-12"  *ngIf="altAddressCheck ">
                        <label class="header">Alternate Address Details :</label>
                      </div>
                    
                      <div *ngIf="altAddressCheck" class="col-sm-3 ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Street Address</mat-label>
                          <input matInput placeholder="Address" formControlName="streetAddress2" />
                        </mat-form-field>
                      </div>
                      <div *ngIf="altAddressCheck" class="col-sm-3 ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Taluka</mat-label>
                          <input matInput placeholder="Taluka" formControlName="taluka2" />
                          <mat-error class="margin_tp"
                            *ngIf="doctorMasterForm.controls['taluka2'].touched && doctorMasterForm.controls['taluka2'].errors?.pattern">
                            Please enter valid taluka.
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <div *ngIf="altAddressCheck" class="col-sm-3 ">
                        <!-- <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>District</mat-label>
                          <input matInput placeholder="District" formControlName="district2" />
                          <mat-error class="margin_tp"
                            *ngIf="doctorMasterForm.controls['district2'].touched && doctorMasterForm.controls['district2'].errors?.pattern">
                            Please enter valid district.
                          </mat-error>
                        </mat-form-field> -->
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>District</mat-label>
                          <mat-select   #select122 placeholder="District" class="colorblack"  formControlName="district2"   >
                            <mat-select-filter  *ngIf="select122.focused" [placeholder]="'Filter'" [displayMember]="'district'"
                              [array]="districtData2" (filteredReturn)="filteredListDistrict3 =$event"></mat-select-filter>
                            <mat-option *ngFor="let item of filteredListDistrict3" 
                             [value]="item.district" >
                              {{item.district}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div *ngIf="altAddressCheck" class="col-sm-3 ">
                        <!-- <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>State</mat-label>
                          <mat-select #select3 placeholder="State" class="colorblack" formControlName="state2">
                            <mat-select-filter *ngIf="select3.focused" [placeholder]="'Filter'" [displayMember]="'name'" [array]="allState"
                              (filteredReturn)="filteredList =$event"></mat-select-filter>
                            <mat-option *ngFor="let item of filteredList" [value]="item.value">
                              {{item.name}}
                            </mat-option>
                          </mat-select>
    
    
                        </mat-form-field> -->
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>State</mat-label>
                          <mat-select   #select11 placeholder="State" class="colorblack"  formControlName="state2"   >
                            <mat-select-filter  *ngIf="select11.focused" [placeholder]="'Filter'" [displayMember]="'state'"
                              [array]="stateCityArr2"  (filteredReturn)="filteredList3 =$event"></mat-select-filter>
                            <mat-option *ngFor="let item of filteredList3" 
                             [value]="item.state" (click)="getDistrict3(item.districts)">
                              {{item.state}}
                            </mat-option>
                          </mat-select>
                        
                        </mat-form-field>
                      </div>
                      <div *ngIf="altAddressCheck" class="col-sm-3 ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Pincode </mat-label>
                          <input matInput maxlength="6" placeholder="Pincode(e.g:416008)" formControlName="pincode2" />
                          
    
                        </mat-form-field>
                        <mat-error class="margin_tp"
                            *ngIf="doctorMasterForm.controls['pincode2'].touched && doctorMasterForm.controls['pincode2'].errors?.pattern">
                            Please enter valid pincode.
                          </mat-error>
                      </div>
                      <div *ngIf="altAddressCheck" class="col-sm-3 ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Country</mat-label>
                          <input matInput placeholder="Country" formControlName="country2" />
                          
                        </mat-form-field>
                        <mat-error class="margin_tp"
                            *ngIf="doctorMasterForm.controls['country2'].touched && doctorMasterForm.controls['country2'].errors?.pattern">
                            Please enter valid country.
                          </mat-error>
                      </div>                 
                    </div>
  
                    <div class="col-sm-12">
                      <div class="row">
                       
                        <div class="col-sm-12 ">
                          <label class="header">Other Details :</label>
                        </div>
  
  
                        <div class="col-sm-3 ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Audiologists Status</mat-label>
  
                            <mat-select formControlName="doctorStatus">
                              <mat-option value="Active">Active</mat-option>
                              <mat-option value="In-active">In-active</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        
                        <div class="col-sm-4">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Notes</mat-label>
  
                            <textarea maxlength="1000" matInput formControlName="notes" 
                            cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                              cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5"></textarea>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                    <!-- <div *ngIf="isSuperAdmin" class="col-sm-12">
                              
                      <div class="row">
                        <div class="col-sm-12  ">
                      <label class="header">Select Branch :</label>
                        </div>
                        </div>
                        <div class="row">
  
                    <div class="col-sm-3">
                      <div class="row">
                        <div class="col-sm-10 pr-1">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Branch 
                         
                        </mat-label>
                        <mat-select required #select4 (click)="fetchBranchList();" formControlName="branchRefId" >
                          <mat-select-filter *ngIf="select4.focused" [placeholder]="'Filter'" [displayMember]="'branchName'"
                          [array]="BranchList"  (filteredReturn)="filteredListBranch =$event"></mat-select-filter>
                          <mat-option *ngFor="let item of filteredListBranch" [value]="item._id"
                          (click)="branchClick(item._id,item.branchName);" >
                           {{ item.branchName }}</mat-option>
                          </mat-select>
                          
                      </mat-form-field>
                      <mat-error class="margin_tp"
                      *ngIf="doctorMasterForm.controls['branchRefId'].touched && doctorMasterForm.controls['branchRefId'].errors?.required">
                      This field is mandatory.
                    </mat-error>
                    </div>
                    <div class="col-sm-2 mTop">
                      <img (click)="openBranchPopUp();" matTooltip="Add New Branch" width="25" src="../../assets/add_img.png"> 
                    </div>
                    </div>
                    </div>
                    
               </div>
               </div> -->
               <div class="col-sm-10" *ngIf="submitButton">
                <label class="mt-3 redCol">*Note : Please Set Doctor Availability So As Make Doctor Available</label>
               </div>
                    <div class="col-sm-5" *ngIf="cancelButton"></div>
                    <div class="col-sm-2 mt-2 ">
                        <button *ngIf="submitButton && doctorCreate" class="btn btn-primary btn-sm mt-3 example-full-width"
                          (click)="submitDoctor()" type="button">Save </button>
                        <button *ngIf="updateButton  && doctorUpdate" class="btn btn-primary btn-sm mt-3 example-full-width"
                          (click)="update()" type="button">Update </button>
                      </div>
                      <div class="col-sm-2  mt-2" *ngIf="cancelButton">
                        <button class="btn btn-primary btn-sm mt-3  example-full-width" (click)="cancelUpdate()">Cancel
                        </button>
                      </div>
                  </div>
                 
                 
  
            </div>
          </div>
  
        </div>
      </div>
    </form>
  </div>