import { Component, Inject, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  FormArray
} from "@angular/forms";
 import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  MatSnackBar,
} from "@angular/material/snack-bar";
import { MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { PopUpBranchComponent } from "../pop-up-branch/pop-up-branch.component";
@Component({
  selector: 'app-cheque-reconciliation',
  templateUrl: './cheque-reconciliation.component.html',
  styleUrls: ['./cheque-reconciliation.component.scss']
})
export class ChequeReconciliationComponent implements OnInit {
 
  receiptNo: any;
modeOfPaymentForm: FormGroup;
date: any;
listOfAppointment:any;
totalLength:any;
appointmentId: any;
size=5;
page=1;
searchText: any;
submitButton = true;
updateButton = false;
allColor: any;

filteredList: { name: string; value: string; }[];
filteredListAppoinmentType: { name: string; value: string; }[];
userType: string;
branchRef: string;
clinicRef: string;
BranchList: any;
isSuperAdmin= true;
superBranchId: any;
branchName: string;
filteredListBranch: any;
currentdate: Date;
listOfBilling: any;
id: any;
courierList: any;
filteredListCourier: any;
disableFromCity = false;
disableToCity = true;
tocityList: any;
cityList: any;
fromCity: any;
toCity: any;
courierServiceCreate = true;
branchCreate = true;
filteredListPatient: any;
patientList: any;
totalBillAmt = 0;
printData=[
  {
    srNo:1,
    date:'201',
    serviceName:'Test',
    companyName:'A Company',
    rate:100,
   },
   {
    srNo:2,
    date:'202',
    serviceName:'Test',
    companyName:'A Company',
    rate:200,
   },
   {
    srNo:3,
    date:'203',
    serviceName:'Test 2',
    companyName:'A Company',
    rate:100,
   },
   {
    srNo:4,
    date:'204',
    serviceName:'Test 3',
    companyName:'A Company',
    rate:150,
   },                 
]
patientRefId: any;
appointId: any;
billingList: any;
mode: any;
disableSubmit = false;
  billNo: any;
  paymentDate: any;
  chequePaymentStatus: any;
  serviceInfo = [];
  billServiceInfo = [];
  balanceAmount = 0;
  pendingAmount = 0;
  paidAmount = 0;
  billRefId: any;
  paymentRefId: any;
  chkstatus = '';
  paymentStatus: any;
  allPaydata = [];
  totalPaidAmount = 0;
  chequeStatus: any;
  chequeNo: string;
  rNo: any;
  chequeImage: any;
  btn: HTMLElement;
  isAdvance: any;
  minDate: Date;
  minDate3: any;
  minDate2: any;
  totalAmount: any;
  chequeAmt: any;
constructor(
  private fb: FormBuilder,
  private _snackBar: MatSnackBar,
  public apimicroservice: ApiServiceService,
  public router: Router,
   public dialog: MatDialog,
  public datepipe: DatePipe,
  private spinner: NgxSpinnerService,
  private route: ActivatedRoute
) { 
}
userPermission:any;
billingCreate= true;
billingUpdate= true;
paymentMode :any;
modalImg: any;
modal: any; 

ngOnInit(): void {
  this.chequeNo = this.route.snapshot.paramMap.get('chequeNo');
  this.serviceInfo = [];
  this.billServiceInfo = []; 
  this.chkstatus = '';
  this.balanceAmount = 0;
  this.pendingAmount = 0;
  this.paidAmount = 0;
  this.totalPaidAmount = 0;
  this.currentdate = new Date();
  this.page=1;
  this.size = 5;
  this.branchRef = sessionStorage.getItem('branchRef');
  this.clinicRef = sessionStorage.getItem('clinicRef');
  this.branchName = sessionStorage.getItem('branchName');
  this.userType = sessionStorage.getItem('userType');
  this.userType = sessionStorage.getItem('userType');
  this.chequeNo = sessionStorage.getItem('chequeNo'); 
  
  if(sessionStorage.getItem('rNo') == null || sessionStorage.getItem('rNo') == 'null')
  {
    this.rNo = 0;
  }
  else{
    this.rNo = sessionStorage.getItem('rNo');
  
  }

  this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
  if(this.userType !== 'Super Admin'){
    this.isSuperAdmin= false;
  this.userPermission.forEach((element) => {
    if(element.pageName == 'Billing Record' && element.create == false)
    {
        this.billingCreate = false;  
    }
    if(element.pageName == 'Billing Record' && element.update == false)
    {
        this.billingUpdate = false;  
    }  
  });

}
this.modal = document.getElementById("myModal");

this.btn = document.getElementById("myBtn");
window.onclick = function(event) {
  if (event.target ==this.modal) {
    this.modal.style.display = "none";
  }
}
this.disableSubmit = false;
  this.modeOfPaymentForm = this.fb.group({

    "searchBilling": new FormControl(''), 
    "chequeStatus": new FormControl(''), 
    "date": new FormControl(''), 
    "billNo": new FormControl(''), 
    "alertType": new FormControl(''), 
    "billid": new FormControl(''), 
    
    "chequeArray": this.fb.array([]),
  });
  let i = 0; 
  this.fetchChequeData();
  //this.fetchBillData();
 // this.addRow(i);
}

fetchChequeData(){
  this.apimicroservice.getPaymentHistoryByChequeNo(this.chequeNo).subscribe(res => {
    if(res.status){
   let data = res.data[0];
   this.isAdvance = data.advanceStatus;
   this.serviceInfo = data.serviceInfo;
   this.paymentRefId = data._id;
   this.billNo = data.billNo;
  //  this.chequeStatus = data.chequeStatus;
   this.paymentDate = data.paymentDate;
   let dt = new Date(this.paymentDate);
   this.chequePaymentStatus = data.chequePaymentStatus;
   
   if(this.chequePaymentStatus == 'Clear' || this.chequePaymentStatus == 'bounce'){
     this.submitButton = false;
     this.modeOfPaymentForm.disable();
   }
   if(this.chequePaymentStatus == 'Clear' || this.chequePaymentStatus == 'bounce'){
    this.submitButton = false;
    this.modeOfPaymentForm.disable();
  }
   this.modeOfPaymentForm.controls['billNo'].setValue(this.billNo);
   this.modeOfPaymentForm.controls['chequeStatus'].setValue(this.chequePaymentStatus);
  //  this.paidAmount = data.paidAmount;
   this.fetchBillData();
   this.allPaymentByBillNo();
   data.chequeDetails.forEach((element,i) => {
    this.modeOfPaymentForm.controls['chequeStatus'].setValue(element.chequeStatus);
     if(element.chequeStatus == 'postdated'){
       this.disableSubmit = true;
     }else{
       this.disableSubmit = false;
     }
    this.addRow(element,i)
   });
  }else{
    
  }
  });
}

allPaymentByBillNo(){
  this.apimicroservice.getPaymentHistoryByBillNo(this.billNo).subscribe(res => {
    this.allPaydata = res.data;
  });
}

fetchBillData(){
  this.paidAmount = 0;
  this.apimicroservice.getBillDataById(this.billNo).subscribe(res => {
   let data = res.data[0];
  this.totalPaidAmount = 0;
  this.balanceAmount = 0;
  this.chequeAmt = 0;
  this.pendingAmount = 0;
  this.totalAmount = 0;
  this.paidAmount = data.paidAmount + data.pendingChequeAmount;  
  this.billServiceInfo = data.serviceInfo;
  this.billRefId = data._id;
  this.paymentStatus = data.paymentStatus;
  this.totalPaidAmount = data.paidAmount;
  this.balanceAmount = data.balanceAmount;
  this.chequeAmt = data.pendingChequeAmount;
  this.pendingAmount = data.pendingChequeAmount;
  this.totalAmount = data.totalAmount;
  //  res.data.forEach(element => {
  //   this.billServiceInfo = element.serviceInfo;
  //  this.billRefId = element._id;
  //  this.totalPaidAmount = this.totalPaidAmount + element.paidAmount;
  //  this.balanceAmount = this.balanceAmount + element.balanceAmount;
  //  this.chequeAmt = this.chequeAmt + element.pendingChequeAmount;
  //  this.pendingAmount = this.pendingAmount + element.pendingChequeAmount;
  //  this.totalAmount = this.totalAmount + element.totalAmount;

  //  });
  //  this.billServiceInfo = data.serviceInfo;
  //  this.billRefId = data._id;
  //  this.paymentStatus = data.paymentStatus;
  //  this.totalPaidAmount = data.paidAmount;
  //  this.balanceAmount = data.balanceAmount;
  //  this.chequeAmt = data.pendingChequeAmount;
  //  this.pendingAmount = data.pendingChequeAmount;
  //  this.totalAmount = data.totalAmount;
  });
}

prepopulation(id) {
  this.redirect('bill-form');
}

tomodeOfPaymentForm() {
  document.getElementById("tomodeOfPaymentForm").scrollIntoView();
}
toBillingLst() {
  document.getElementById("toBillingLst").scrollIntoView();
}

openSnackbar(message: string) {
  return this._snackBar.open(message, "close",
    { verticalPosition: 'bottom', horizontalPosition: 'right', duration: 2300, panelClass: ['snackbarStyle'] });
}

onPageChange(event) {
  this.page = event;
  let search='';
}

printForm()
{
  this.redirect('print-bill');
} 
redirect(to): void {
  this.router.navigate([to]);
 } 

addRow(data,i) {
  const creds = this.modeOfPaymentForm.controls.chequeArray as FormArray;
      creds.push(
      this.fb.group({
        "chequeDate": new FormControl(data.chequeDate),   
        "chequeNo": new FormControl(data.chequeNo),  
        "bankName": new FormControl(data.bankName), 
        "amount": new FormControl(data.amount),
        "chequeStatus": new FormControl(data.chequeStatus,Validators.required),
        "alertType": new FormControl(''),
        "billid": new FormControl(''), 
        "photo": '', 
      toggle: false
})
);
this.chequeImage = data.photo ? data.photo : "../../assets/user2.png"
}
deleteRow(index){
  //this.dynamicRows.splice(index, 1);
  (<FormArray>this.modeOfPaymentForm.get("chequeArray")).removeAt(index);
}

onSelectStatus(event:any,i){
  let chequeControl = (<FormArray>this.modeOfPaymentForm.get("chequeArray")).at(i);
  let statusVal = chequeControl.get("chequeStatus").value;
  if(statusVal == 'postdated'){
    this.disableSubmit = true;
  }else{
    this.disableSubmit = false;
  }
}

submitChequeDetails(){
  const creds = this.modeOfPaymentForm.controls.chequeArray as FormArray;  
  if(creds.invalid)
  {
    creds.controls.forEach(element =>{
      element.get('chequeStatus').markAsTouched();
    });
       
  }
  else{
   this.sumbitCheque();
  }
}

sumbitCheque(){ 
  this.spinner.show();
  let cdate = new Date();
  const tempData = this.modeOfPaymentForm.controls.chequeArray.value;
  let paymentpayload = {}; 
  let paymentpayload2 = {};
  let billingPayload = {};
  let bouncePayload = {};
  let chequeStatus = ''; 
  let finalStatus = '';
  let finalStatusBill = '';
  let bounceStatus = '';
  let tempArr = [];
  let serviceArr = [];
  let comment = '';
  let tempStatus = '';
  tempArr = tempData;
  let billPaymentStatus = 'clear';
  let checkPaidAmt;
  checkPaidAmt = 0;
  let j = 0;
  let payStatus = 'Unpaid';
  let bounceAmount = 0;
  let chequeNo = 0;
  tempArr.forEach(ele =>{
    if(ele.chequeStatus == 'Deposited'){
      chequeStatus = 'deposited';
      payStatus = 'Unpaid';
    }
    if(ele.chequeStatus == 'Clear'){
      chequeStatus = 'Clear';
      payStatus = 'Paid';
      chequeNo = ele.chequeNo;
      // checkPaidAmt = JSON.parse(checkPaidAmt) + JSON.parse(ele.amount); 
      //  this.balanceAmount = this.balanceAmount - checkPaidAmt;
      // this.pendingAmount = this.pendingAmount - checkPaidAmt;
      // this.paidAmount = this.paidAmount + checkPaidAmt;  
      // this.totalPaidAmount = this.totalPaidAmount + this.paidAmount;
    }
    if(ele.chequeStatus == 'bounce'){
      payStatus = 'Unpaid';
      chequeStatus = 'bounce';
      bounceAmount = ele.amount;
      j++;
    }

  });

  if(chequeStatus == 'bounce'){
    finalStatus = 'bounce';
    comment = j + " cheques has bounced";
  }else{
    finalStatus = chequeStatus;
  }
  
  this.allPaydata.forEach(ele =>{
    if(chequeStatus == 'deposited'){
      billPaymentStatus = 'deposited';
    }
    if(chequeStatus == 'postdated'){
      billPaymentStatus = 'postdated';
    }
    if(chequeStatus == 'Clear'){
      billPaymentStatus = 'clear';
    }
    if(chequeStatus == 'bounce'){
      bounceStatus = 'bounce';
    }

  });
  if(bounceStatus == 'bounce' || finalStatus == 'bounce'){
    finalStatusBill = 'bounce';
    comment = j + " cheques has bounced";
  }else{
    finalStatusBill = billPaymentStatus;
  }

  this.serviceInfo.forEach(ele =>{
    ele.status = finalStatusBill;
    if(finalStatusBill == 'bounce'){ 
      ele.comment = comment;
    }
    serviceArr.push({"serviceName":ele.serviceName,"testType":ele.testType});
  });

  this.billServiceInfo.forEach((element,i) =>{ 
    let myvalue = this.billServiceInfo[i]['totalServiceAmount'];
    let oldvalue = '0';
    if(this.billServiceInfo[i]['initialValue'])
    {
      oldvalue = this.billServiceInfo[i]['initialValue'];
    }

   checkPaidAmt = checkPaidAmt + JSON.parse(oldvalue); 
    if (checkPaidAmt >= myvalue) {

      let ddd = checkPaidAmt - myvalue; 
      if (Math.sign(ddd) == 1) {
        this.billServiceInfo[i]['serviceAdvanceAmount'] = 0;
        this.billServiceInfo[i]['servicePaidAmount'] = myvalue;
      } else {
        this.billServiceInfo[i]['serviceAdvanceAmount'] = 0;
        this.billServiceInfo[i]['servicePaidAmount'] = myvalue;
      }
      if (Math.sign(ddd) == 1) {
        checkPaidAmt = ddd;
        // alert("checkPaidAmt"+checkPaidAmt);
      } else {
        checkPaidAmt = 0;
      }
    } else if (myvalue >= checkPaidAmt && checkPaidAmt !== 0) {
      1160 > 1000
      1000 - 1160
      let ddd = checkPaidAmt - myvalue;
      if (Math.sign(ddd) == -1) {
        let diffrence = myvalue - checkPaidAmt;
        if (checkPaidAmt == myvalue) { 
          this.billServiceInfo[i]['serviceAdvanceAmount'] = 0;
          this.billServiceInfo[i]['servicePaidAmount'] = checkPaidAmt;
          this.billServiceInfo[i]['initialValue'] = checkPaidAmt;
        } else {
          this.billServiceInfo[i]['serviceAdvanceAmount'] = diffrence;
          this.billServiceInfo[i]['servicePaidAmount'] = checkPaidAmt;
          this.billServiceInfo[i]['initialValue'] = checkPaidAmt;
        }
      } else {
      } 
      if (Math.sign(ddd) == -1) {
        checkPaidAmt = 0;
      } else {
        checkPaidAmt = ddd;
      }
    } else {
      this.billServiceInfo[i]['serviceAdvanceAmount'] = myvalue;
      this.billServiceInfo[i]['servicePaidAmount'] = 0;
    }
  });
  
  this.chequePaymentStatus = finalStatus;
  if(this.chequePaymentStatus == 'bounce'){
    this.pendingAmount = this.pendingAmount - bounceAmount;
  }
  if(this.paidAmount == this.totalAmount || this.paidAmount > this.totalAmount){
    tempStatus = 'Paid';
  }else{ 
    tempStatus = 'Unpaid';
  }
  billingPayload['serviceInfo'] = this.billServiceInfo; 
  billingPayload['paidAmount'] = this.paidAmount; 
  billingPayload['balanceAmount'] = this.balanceAmount;
  
  billingPayload['pendingChequeAmount'] = this.pendingAmount;
  billingPayload['bounceAmount'] = bounceAmount;
  billingPayload['paymentStatus'] = tempStatus;  
  paymentpayload['paymentStatus'] = payStatus;
  paymentpayload['chequePaymentStatus'] = this.chequePaymentStatus;
  paymentpayload['chequeDetails'] = tempArr;
  paymentpayload2['chequeDetails'] = tempArr;
  paymentpayload['serviceInfo'] = this.serviceInfo; 
  paymentpayload['paidAmount'] = this.paidAmount; 
  paymentpayload['balanceAmount'] = this.balanceAmount;
  paymentpayload['pendingChequeAmount'] = this.chequeAmt;
  paymentpayload['bounceAmount'] = bounceAmount;
  paymentpayload['bounceAmount'] = bounceAmount;
  paymentpayload['alertType'] = '10';
  // paymentpayload['receiptNo']= this.rNo;   
  paymentpayload['billid']= this.chequeNo;
  paymentpayload['updatedAt']= cdate;
  paymentpayload2['billid']= this.chequeNo; 
  paymentpayload2['receiptNo']= this.rNo; 
  paymentpayload2['alertType']= '10';

  let receiptNo = Math.floor(10000 + Math.random() * 90000);
  paymentpayload['receiptNo'] = receiptNo;
  let chequeControl = (<FormArray>this.modeOfPaymentForm.get("chequeArray")).at(0);
  let chequeType = chequeControl.get("chequeStatus").value;
  paymentpayload['chequePaymentStatus'] = chequeType;
  
  this.apimicroservice.updatePaymentHistoryDetails(paymentpayload,this.paymentRefId).subscribe(res => {
    this.spinner.hide();
      //this.ngOnInit(); 
      if(res.status){
       // this.apimicroservice.openSnackbar(res.message);
     
       let bar1 = new Promise<void>((resolve, reject) => {
         if(paymentpayload['paymentStatus'] !== 'Paid')
         {
            this.apimicroservice.openSnackbar('Your Warrenty will start after full payment');
           resolve();
         }
         else 
         {
            this.apimicroservice.openSnackbar('Your Warrenty is started');
           
           let payloadOneWeek = {};
        this.minDate = new Date();
        this.minDate.setDate(this.minDate.getDate()+7);
          payloadOneWeek['patientRefId'] = res.data.patientRefId;
          payloadOneWeek['patientName'] = res.data.patientName;
          payloadOneWeek['followupType'] = 'Phone Call';
          payloadOneWeek['followupTime'] = 'One Week';
          payloadOneWeek['followupDate'] = this.minDate;
          payloadOneWeek['followupStatus'] = 'Follow Up Scheduled';
          payloadOneWeek['branchRefId'] = this.branchRef;
          payloadOneWeek['clinicRefId'] = this.clinicRef;

           let barweek = new Promise<void>((resolve, reject) => {
           this.apimicroservice.addFollowup(payloadOneWeek).subscribe(res => {
             this.spinner.hide(); 
             if(res.status){
               let data = res.data;
               payloadOneWeek['followupId'] = data.followupId;
               this.apimicroservice.addFollowupAlerts(payloadOneWeek).subscribe(res => {
                 if(res.status){
                   this.apimicroservice.openSnackbar('alert created');
                 }
               }); 
               
             }else{
               this.apimicroservice.openSnackbar(res.message);
             }
           },(ERROR:HttpErrorResponse) => {
             this.apimicroservice.openSnackbar(ERROR.error.message);
             this.spinner.hide();
         });
         setTimeout( () => {
           // this.spinner.show();
           resolve();
       }, 100);
       });
       barweek.then(()=>
       {
       let payloadSixMonth = {};
       this.minDate2 = new Date();
       this.minDate2.setMonth(this.minDate2.getMonth()+6);
       payloadSixMonth['patientRefId'] = res.data.patientRefId;
       payloadSixMonth['patientName'] = res.data.patientName;
       payloadSixMonth['followupType'] = 'Phone Call';
       payloadSixMonth['followupTime'] = 'Six Month';
       payloadSixMonth['followupDate'] = this.minDate2;
       payloadSixMonth['followupStatus'] = 'Follow Up Scheduled';
       payloadSixMonth['branchRefId'] = this.branchRef;
       payloadSixMonth['clinicRefId'] = this.clinicRef;
       let barMonth = new Promise<void>((resolve, reject) => {
       this.apimicroservice.addFollowup(payloadSixMonth).subscribe(res => {
         this.spinner.hide(); 
         if(res.status){
           let data = res.data;
           payloadSixMonth['followupId'] = data.followupId;
           this.apimicroservice.addFollowupAlerts(payloadSixMonth).subscribe(res => {
             if(res.status){
               this.apimicroservice.openSnackbar('alert created');
             }
           }); 
           
         }else{
           this.apimicroservice.openSnackbar(res.message);
         }
       },(ERROR:HttpErrorResponse) => {
         this.apimicroservice.openSnackbar(ERROR.error.message);
         this.spinner.hide();
     });
     setTimeout( () => {
       // this.spinner.show();
       resolve();
   }, 100);
   });
   barMonth.then(()=>
   {
     let payloadOne = {};
     this.minDate3 = new Date();
     this.minDate3.setMonth(this.minDate3.getMonth()+12);
     payloadOne['patientRefId'] = res.data.patientRefId;
     payloadOne['patientName'] = res.data.patientName;
      payloadOne['followupType'] = 'Phone Call';
      payloadOne['followupTime'] = 'One Year';
      payloadOne['followupDate'] = this.minDate3;
      payloadOne['followupStatus'] = 'Follow Up Scheduled';
      payloadOne['branchRefId'] = this.branchRef;
      payloadOne['clinicRefId'] = this.clinicRef;

     this.apimicroservice.addFollowup(payloadOne).subscribe(res => {
       this.spinner.hide(); 
       if(res.status){
         let data = res.data;
         payloadOne['followupId'] = data.followupId;
         this.apimicroservice.addFollowupAlerts(payloadOne).subscribe(res => {
           if(res.status){
             this.apimicroservice.openSnackbar('alert created');
           }
         }); 
         
       }else{
         this.apimicroservice.openSnackbar(res.message);
       }
     },(ERROR:HttpErrorResponse) => {
       this.apimicroservice.openSnackbar(ERROR.error.message);
       this.spinner.hide();
   });
   })
   });
         setTimeout( () => {
           // this.spinner.show();
           resolve();
       }, 100);
         }
    
     });
     

        this.apimicroservice.updateBillingDetails(billingPayload,this.billRefId).subscribe(res => {
          if(res.status){
            this.apimicroservice.openSnackbar("Cheque "+this.chequePaymentStatus);
          
         let chequeControl = (<FormArray>this.modeOfPaymentForm.get("chequeArray")).at(0);
          let chequeType = chequeControl.get("chequeStatus").value;
            
            if(chequeType == 'bounce'){  
              let chequeNum = Math.floor(10000 + Math.random() * 90000);
              paymentpayload['billid']= "chq-" + chequeNum;
              paymentpayload['chequeNo']= this.chequeNo;      
              paymentpayload['receiptNo']= this.rNo;      
 
              paymentpayload2['billid']= "chq-" + this.chequeNo;  
              paymentpayload2['receiptNo']= this.rNo;  
                this.apimicroservice.addBounceAlerts(paymentpayload).subscribe(res => {
                  if(res.status){
                    this.apimicroservice.openSnackbar('alert created');
                  window.location.reload();
                    // this.ngOnInit();
                  }
                });  
             
               }
               else if(chequeType == 'Received'){
                paymentpayload['alertType']= '101';
                let chequeNum = Math.floor(10000 + Math.random() * 90000);
                paymentpayload['billid']= "chq-" + chequeNum;
                paymentpayload['chequeNo']= this.chequeNo;  
                paymentpayload2['billid']= "chq-" + chequeNum;  
                paymentpayload2['alertType']= '101';
                paymentpayload['receiptNo']= this.rNo;  
                paymentpayload2['receiptNo']= this.rNo;  
                this.apimicroservice.addAlertNew(paymentpayload).subscribe(res => {
                  if(res.status){
                    this.apimicroservice.openSnackbar('alert created');
                    window.location.reload();
                    // this.ngOnInit();
                  }
                });
              
               }
               else if(chequeType == 'postdated')
               { 
                paymentpayload['alertType']= '102';        
                let chequeNum = Math.floor(10000 + Math.random() * 90000);
                paymentpayload['billid']= "chq-" + chequeNum;
                paymentpayload['chequeNo']= this.chequeNo;  
                paymentpayload['receiptNo']= this.rNo;     

                paymentpayload2['billid']= "chq-" + this.chequeNo;  
                paymentpayload['receiptNo']= this.rNo;  
                paymentpayload2['alertType']= '102';
                paymentpayload2['receiptNo']= this.rNo;  
             
                this.apimicroservice.addAlertNew(paymentpayload).subscribe(res => {
                  if(res.status){
                    this.apimicroservice.openSnackbar('alert created');
                    window.location.reload();
                    // this.ngOnInit();
                  }
                });
               }
               else if(chequeType == 'Deposited')
               {
                paymentpayload['alertType']= '103';       
                let chequeNum = Math.floor(10000 + Math.random() * 90000);
                paymentpayload['billid']= "chq-" + chequeNum;
                paymentpayload['chequeNo']= this.chequeNo; 
                paymentpayload2['billid']= "chq-" + chequeNum;  
                paymentpayload['receiptNo']= this.rNo;  
                paymentpayload2['alertType']= '103';
                paymentpayload2['receiptNo']= this.rNo;  

                paymentpayload['receiptNo']= this.rNo;       
                this.apimicroservice.addAlertNew(paymentpayload).subscribe(res => {
                  if(res.status){
                    this.apimicroservice.openSnackbar('alert created');
                  window.location.reload();
                    // this.ngOnInit();
                  }
                });
             
               }
               else if(chequeType == 'Clear') 
               {
               paymentpayload['alertType']= '104'; 
                let chequeNum = Math.floor(10000 + Math.random() * 90000);
                paymentpayload['billid']= "chq-" + chequeNum;
                paymentpayload['chequeNo']= this.chequeNo;   
                paymentpayload['receiptNo']= this.rNo;        
             paymentpayload2['billid']= "chq-" + chequeNum;  
             paymentpayload2['alertType']= '104';
             paymentpayload2['receiptNo']= this.rNo;  

                this.apimicroservice.addAlertNew(paymentpayload).subscribe(res => {
                  if(res.status){
                    this.apimicroservice.openSnackbar('alert created');
                    window.location.reload();
                    // this.ngOnInit();
                  }
                }); 

               }
               else if(chequeType == 'Cancelled')
               {
                paymentpayload['alertType']= '105'; 
                let chequeNum = Math.floor(10000 + Math.random() * 90000);
                paymentpayload['billid']= "chq-" + chequeNum;
                paymentpayload['chequeNo']= this.chequeNo;    
                paymentpayload['receiptNo']= this.rNo;        
                
                paymentpayload2['billid']= "chq-" + this.chequeNo; 
                paymentpayload2['alertType']= '105';
                paymentpayload2['receiptNo']= this.rNo;    

                this.apimicroservice.addAlertNew(paymentpayload).subscribe(res => {
                  if(res.status){
                    this.apimicroservice.openSnackbar('alert created');
                    window.location.reload();
                    // this.ngOnInit();
                  }
                });
               }
               else if(chequeType == 'Returned')
               {
                paymentpayload['alertType']= '106';  
                let chequeNum = Math.floor(10000 + Math.random() * 90000);
                paymentpayload['billid']= "chq-" + chequeNum;
                paymentpayload['chequeNo']= this.chequeNo;       
                paymentpayload['receiptNo']= this.rNo; 
                
                paymentpayload2['billid']= "chq-" + this.chequeNo; 
                paymentpayload2['alertType']= '106'; 
                paymentpayload2['receiptNo']= this.rNo;  
            
                this.apimicroservice.addAlertNew(paymentpayload).subscribe(res => {
                  if(res.status){
                    this.apimicroservice.openSnackbar('alert created');
                    window.location.reload();
                    // this.ngOnInit();
                  }
                });
               }
              this.redirect('home/cheque-list');

          }
        })
      }
  });
}

cancelSumbit(){
  this.redirect('home/cheque-list');
}

findDuplicate(chqno,p):boolean{
  const creds = this.modeOfPaymentForm.controls.chequeArray as FormArray;
  let myArr = creds.controls;
  let test = myArr.filter(data => data['controls']['chequeNo'].value == chqno && chqno !== null)

  if(test.length > 1){
    this.disableSubmit = true;
    return true;
  }else{
    this.disableSubmit = false;
    return false;
  }

}
onback() {
  this.apimicroservice.back()
}
imgClick()
{
  
 var modal = document.getElementById("myModal");

   modal.style.display = "block";

}
close()
{
  var modal = document.getElementById("myModal");
  modal.style.display = "none";
}
}

