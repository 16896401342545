import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  FormArray 
} from "@angular/forms";
 import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute } from "@angular/router";
import { element } from "protractor";
@Component({
  selector: 'app-custom-ha-print',
  templateUrl: './custom-ha-print.component.html',
  styleUrls: ['./custom-ha-print.component.scss']
})
export class CustomHaPrintComponent implements OnInit {
  customHaPrintForm: FormGroup;
  date: any;
  size=5;
  page=1;
  userType: string;
  branchRef: string;
  clinicRef: string;
  BranchList: any;
  isSuperAdmin= true;
  
  headerData: any;
  reportName: any;
  reportAddress: any;
  district: any;
  taluka: any;
  state: any;
  country: any;
  reportPinCode: any;
  reportPhoneNumber: any;
  openTime: any;
  closeTime: any;
  logoimg: any;
  footerData: any;
  emailAdd: any;
  website:any;
  reportWeeklyOff: any;
  prifix: any;
  lastName: any;
  firstName: any;
  middleName: any;
  address: any;
  invoiceDate: any;
  pMobile: any;
  companyGst: any;
  bankName: string;
  bankBranchName: string;
  accountNumber: string;
  ifcCode: string;
  upiId: string;
  customHaNo: string;
  currentdate: Date;
  branchName: string;
  companyName: any;
  companyCanNumber: any;
  companyMake: any;
  leftModel: any;
  leftMake: any;
  leftBattery: any;
  leftStyle: any;
  leftVent: any;
  leftRemovalString: any;
  leftMatrix: any;
  leftShell: any;
  leftFaceplate: any;
  leftProgramSwitch: any;
  leftVolumeControl: any;
  rightModel: any;
  rightMake: any;
  rightBattery: any;
  rightStyle: any;
  rightVent: any;
  rightRemovalString: any;
  rightShell: any;
  rightMatrix: any;
  rightFaceplate: any;
  rightVolumeControl: any;
  rightProgramSwitch: any;
  right250HZ: string;
  right500HZ: string;
  right1KHZ: string;
  right3KHZ: string;
  right4KHZ: string;
  right6KHZ: string;
  right8KHZ: string;
  left250HZ: string;
  left500HZ: string;
  left1KHZ: string;
  left3KHZ: string;
  left4KHZ: string;
  left6KHZ: string;
  left8KHZ: string;
  streetAddress1: any;
  taluka1: any;
  district1: any;
  state1: any;
  country1: any;
  pincode1: any;
  reportPhoneNumber1: any;
  billingRef: any;
  
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
     public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute, 
  ) { }
  userPermission:any;
  repairCreate= true;
  repairUpdate= true;

  ngOnInit(): void {
    this.currentdate = new Date();
    this.page=1;
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    if(this.userType !== 'Super Admin'){
      this.isSuperAdmin= false;
    this.userPermission.forEach((element) => {
      if(element.pageName == 'Repair Print' && element.create == false)
      {
          this.repairCreate = false;  
      }
      if(element.pageName == 'Repair Print' && element.update == false)
      {
          this.repairUpdate = false;  
      }  
    });
  }
    this.customHaPrintForm = this.fb.group({
      "searchBilling": new FormControl(''), 
      
    }); 
    let bar = new Promise<void>((resolve, reject) => {
      this.customHaNo = this.route.snapshot.paramMap.get('customHaNo'); 
      this.apimicroservice.getCustomHADataById(this.customHaNo).subscribe(res => {
        this.billingRef = res.data[0].billingRefId;
        resolve();
      });  

    });
   bar.then(()=>{
    this.prepopulation(this.customHaNo);
    this.getReportHeaderGenericAllData();
    this.getReportFooterGenericAllData();
   });



} 
prepopulation(id) {
 this.apimicroservice.getCustomHADataById(id).subscribe(res => {
    this.companyName =res.data[0].companyName;
    // this.billingRef = res.data[0].billingCompanyRefId;
    this.companyCanNumber =res.data[0].canNumber;
    this.companyMake =res.data[0].make;
    if(res.data[0].leftModel == ''){ this.leftModel ="-";}else {this.leftModel =res.data[0].leftModel;}
    if(res.data[0].leftMake == ''){ this.leftMake ="-";}else {this.leftMake =res.data[0].leftMake;}
    if(res.data[0].leftBattery == ''){ this.leftBattery ="-";}else {this.leftBattery =res.data[0].leftBattery;}
    if(res.data[0].leftStyle == ''){ this.leftStyle ="-";}else {this.leftStyle =res.data[0].leftStyle;}
    if(res.data[0].leftVent == ''){ this.leftVent ="-";}else {this.leftVent =res.data[0].leftVent;}
    if(res.data[0].leftRemovalString == ''){ this.leftRemovalString ="-";}else {this.leftRemovalString =res.data[0].leftRemovalString;}
    if(res.data[0].leftMatrix == ''){ this.leftMatrix ="-";}else {this.leftMatrix =res.data[0].leftMatrix;}
    if(res.data[0].leftShell == ''){ this.leftShell ="-";}else {this.leftShell =res.data[0].leftShell;}
    if(res.data[0].leftFacePlate == ''){ this.leftFaceplate ="-";}else {this.leftFaceplate =res.data[0].leftFacePlate;}
    if(res.data[0].leftProgramSwitch == ''){ this.leftProgramSwitch ="-";}else {this.leftProgramSwitch =res.data[0].leftProgramSwitch;}
    if(res.data[0].leftVolumeControl == ''){ this.leftVolumeControl ="-";}else {this.leftVolumeControl =res.data[0].leftVolumeControl;}

    if(res.data[0].rightModel == ''){ this.rightModel ="-";}else {this.rightModel =res.data[0].rightModel;}
    if(res.data[0].rightMake == ''){ this.rightMake ="-";}else {this.rightMake =res.data[0].rightMake;}
    if(res.data[0].rightBattery == ''){ this.rightBattery ="-";}else {this.rightBattery =res.data[0].rightBattery;}
    if(res.data[0].rightStyle == ''){ this.rightStyle ="-";}else {this.rightStyle =res.data[0].rightStyle;}
    if(res.data[0].rightVent == ''){ this.rightVent ="-";}else {this.rightVent =res.data[0].rightVent;}
    if(res.data[0].rightRemovalString == ''){ this.rightRemovalString ="-";}else {this.rightRemovalString =res.data[0].rightRemovalString;}
    if(res.data[0].rightMatrix == ''){ this.rightMatrix ="-";}else {this.rightMatrix =res.data[0].rightMatrix;}
    if(res.data[0].rightShell == ''){ this.rightShell ="-";}else {this.rightShell =res.data[0].rightShell;}
    if(res.data[0].rightFacePlate == ''){ this.rightFaceplate ="-";}else {this.rightFaceplate =res.data[0].rightFacePlate;}
    if(res.data[0].rightProgramSwitch == ''){ this.rightProgramSwitch ="-";}else {this.rightProgramSwitch =res.data[0].rightProgramSwitch;}
    if(res.data[0].rightVolumeControl == ''){ this.rightVolumeControl ="-";}else {this.rightVolumeControl =res.data[0].rightVolumeControl;}

     if(res.data[0].right250HZ == ''){ this.right250HZ ="-";}else {this.right250HZ =res.data[0].right250HZ;}
     if(res.data[0].right500HZ == ''){ this.right500HZ ="-";}else {this.right500HZ =res.data[0].right500HZ;}
     if(res.data[0].right1KHZ == ''){ this.right1KHZ ="-";}else {this.right1KHZ =res.data[0].right1KHZ;}
     if(res.data[0].right3KHZ == ''){ this.right3KHZ ="-";}else {this.right3KHZ =res.data[0].right3KHZ;}
     if(res.data[0].right4KHZ == ''){ this.right4KHZ ="-";}else {this.right4KHZ =res.data[0].right4KHZ;}
     if(res.data[0].right6KHZ == ''){ this.right6KHZ ="-";}else {this.right6KHZ =res.data[0].right6KHZ;}
     if(res.data[0].right8KHZ == ''){ this.right8KHZ ="-";}else {this.right8KHZ =res.data[0].right8KHZ;}

     if(res.data[0].left250HZ == ''){ this.left250HZ ="-";}else {this.left250HZ =res.data[0].left250HZ;}
     if(res.data[0].left500HZ == ''){ this.left500HZ ="-";}else {this.left500HZ =res.data[0].left500HZ;}
     if(res.data[0].left1KHZ == ''){ this.left1KHZ ="-";}else {this.left1KHZ =res.data[0].left1KHZ;}
     if(res.data[0].left3KHZ == ''){ this.left3KHZ ="-";}else {this.left3KHZ =res.data[0].left3KHZ;}
     if(res.data[0].left4KHZ == ''){ this.left4KHZ ="-";}else {this.left4KHZ =res.data[0].left4KHZ;}
     if(res.data[0].left6KHZ == ''){ this.left6KHZ ="-";}else {this.left6KHZ =res.data[0].left6KHZ;}
     if(res.data[0].left8KHZ == ''){ this.left8KHZ ="-";}else {this.left8KHZ =res.data[0].left8KHZ;}

   

    // res.data.forEach(element => {
    //   //
    //   if(element.rightDead == true)
    //   {
    //     this.rightArr.push({"value":"Dead"});
    //   }
    //   if(element.rightNoisy == true)
    //   {
    //     this.rightArr.push({"value":"Noisy"});
    //   }
    // });

    this.apimicroservice.getPatientById(res.data[0].patientRefId).subscribe(res => { 
      this.prifix =res.data[0].prefix;
      this.lastName =res.data[0].lastName;
      this.firstName =res.data[0].firstName;
      this.middleName =res.data[0].middleName;
      this.pMobile =res.data[0].mobile1;

      this.streetAddress1 =res.data[0].streetAddress;
      this.taluka1 =res.data[0].taluka;
      this.district1 =res.data[0].district;
      this.state1 =res.data[0].state;
      this.country1 =res.data[0].country;
      this.pincode1 =res.data[0].pincode;
      
      // this.address =res.data[0].streetAddress + ',' + res.data[0].taluka + ' ' + res.data[0].district + ' ' +
      //  res.data[0].state + ' ' + res.data[0].country + ',' + res.data[0].pincode;
   });
   });
 
}

printThisPage() {
let currentDate =new Date();
let date=currentDate.toLocaleDateString('en-GB');
  let repair = this.customHaNo;
  let titleName= 'Custom-HA_'+date;
    var tempTitle = document.title;
    document.title = titleName;
    window.print(); 
    document.title = tempTitle;
}

redirect(to): void {
  this.router.navigate([to]);
 }
getReportHeaderGenericAllData()
{ 

  this.apimicroservice.getHeaderDataByBillingRefId(this.billingRef).subscribe(res => {
    if(res.status){  
      this.apimicroservice.openSnackbar(res.message);  
      this.headerData = res.data;
      //
      if(res.data.length > 0){ 
        this.reportName = this.headerData[0].reportName;
        this.reportAddress = this.headerData[0].reportAddress;
        this.reportWeeklyOff = this.headerData[0].reportWeeklyOff;
        this.emailAdd = this.headerData[0].reportEmail;
        this.website = this.headerData[0].reportWebsite;
        this.taluka = this.headerData[0].taluka;
        this.district = this.headerData[0].district;
        this.state = this.headerData[0].state;
        this.country = this.headerData[0].country;
        this.reportPinCode = this.headerData[0].reportPinCode;
        this.reportPhoneNumber = this.headerData[0].reportPhoneNumber;
        this.reportPhoneNumber1 = this.headerData[0].reportPhoneNumber1;
        this.openTime = this.headerData[0].openTime;
        this.closeTime = this.headerData[0].closeTime;
        this.logoimg = res.data[0].uploadLogo;
        
        this.bankName = res.data[0].bankName;
        this.bankBranchName = res.data[0].bankBranchName;
        this.accountNumber = res.data[0].accountNumber;
        this.ifcCode = res.data[0].ifcCode;
        this.upiId = res.data[0].upiId;
      }else{
        this.reportName = "";
        this.reportAddress = "";
        this.reportWeeklyOff ="";
        this.taluka = "";
        this.district = "";
        this.state = "";
        this.country = "";
        this.reportPinCode = "";
        this.reportPhoneNumber = "";
        this.reportPhoneNumber1 = "";
        this.openTime = "";
        this.closeTime = "";
        this.emailAdd = "";
        this.website = "";
        this.logoimg = "";
        this.bankName = "";
        this.bankBranchName = "";
        this.accountNumber = "";
        this.ifcCode = "";
        this.upiId = "";
      }
      
      if(this.logoimg == "" || this.logoimg == '../../assets/main.jpeg')
      this.logoimg = "../../assets/clinic_logo3.jpg"
      //
    }else{
      //this.apimicroservice.openSnackbar(res.message);
    }
  });
}
getReportFooterGenericAllData()
{
  let clinicRef= sessionStorage.getItem('clinicRef');
  let branchRef= sessionStorage.getItem('branchRef');
  let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
  let branchRefSuperAdmin= '';
  if (this.userType !== 'Super Admin') {
  this.apimicroservice.getReportFooterGenericAllData(branchRef,clinicRef).subscribe(res => {
    if(res.status){
    //  this.apimicroservice.openSnackbar(res.message);
      this.footerData = res.data;
      //
    }else{
      //this.apimicroservice.openSnackbar(res.message);
    }
  });
}else{
  this.apimicroservice.getReportFooterGenericAllData(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
    if(res.status){
    //  this.apimicroservice.openSnackbar(res.message);
      this.footerData = res.data;
      //
    }else{
      //this.apimicroservice.openSnackbar(res.message);
    }
  });
}
}

}

 