<div class="container-fluid bgcolor2" [formGroup]="popupfittedByUsForm">
    <form [formGroup]="popupfittedByUsForm" autocomplete="off" novalidate>
      <div class="row col-sm-12 p-0 m-0">
        <div class="col-sm-12">
          <div class="row pl-0 ">
            <div class="col-12 pl-0 ">
              <div class="row ">
              <div class="col-sm-9"> 
              
                  <h1 class="pt-2 pl-0" id="toVendorForm">Fitted By Us</h1>
                  </div> 
                  <div class="col-sm-3 mt-1">
                    <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                      <a (click)="onClose()" matTooltip="Close" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">close</span></a>
                      </div>
    
            </div>
          </div>
         </div>
         </div>
  
         <div class="row ">
          <div class="col-sm-12 pr-4">
            <div class="row">
              <div class="card divbody borderradius mb-2 p-1">
                <div class="row pl-3 pr-3">
                  <div class="col-sm-12">

                    <div class="row pt-0 mt-0">

                      <div class="col-sm-12  mb-0">
                        <h5 class="pb-0 mb-0">Device Details :</h5>
                        <!-- <label class="header">Account Contact Details :</label> -->
                      </div>

                      <div class="col-sm-3">
                        <div class="row">
                          <div class="col-sm-10 mr-0 pr-0">


                            <mat-form-field class="example-full-width  lable_style2" appearance="outline">
                              <mat-label class="lable_style2">Model</mat-label>
 
                              <mat-select placeholder="Model" #select2 formControlName="model" class="colorblack">
                                <mat-select-filter *ngIf="select2.focused" [placeholder]="'Filter'"
                                  [displayMember]="'model'" [array]="deviceList"
                                  (filteredReturn)="filteredListDevice =$event"></mat-select-filter>
                                <mat-option *ngFor="let device of filteredListDevice" [(value)]="device.model"
                                  (click)="selectDevice(device._id)">{{ device.model }}</mat-option>
                              </mat-select>

                            </mat-form-field>

                            <mat-error class="margin_tp"
                              *ngIf="popupfittedByUsForm.controls['model'].touched && popupfittedByUsForm.controls['model'].errors?.required">
                              This field is mandatory.
                            </mat-error>
                          </div>
                          <div class="col-sm-2 no-print mTop">
                            <i (click)="openDevicePopUp();" matTooltip="Add New Model" class="blue_col mTop ml-0 pl-1 fa-solid fa-circle-plus
                           pl-1 fa-lg"></i>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-3 pt-0 mt-0">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Discount Type</mat-label>
                          <!-- <input readonly matInput placeholder="Discount Type"
                            formControlName="discountType" /> -->
                          <mat-select #select2 formControlName="discountType" class="colorblack">

                            <mat-option value="Rupees">Rupees</mat-option>
                            <mat-option value="Percentage">Percentage</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <mat-error
                          *ngIf="popupfittedByUsForm.controls['discountType'].touched && popupfittedByUsForm.controls['discountType'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                      </div>

                      <div class="col-sm-3 pt-0 mt-0">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Discount</mat-label>
                          <input matInput placeholder="Discount" formControlName="discount" />
                        </mat-form-field>
                        <!-- <mat-error
                          *ngIf="popupfittedByUsForm.controls['discount'].touched && popupfittedByUsForm.controls['discount'].errors?.required">
                          This field is mandatory.
                        </mat-error> -->
                      </div>
                      <div class="col-sm-3 pt-0 mt-0">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Bill No</mat-label>
                          <input matInput placeholder="Bill No" formControlName="billNo" />
                        </mat-form-field>
                        <mat-error
                          *ngIf="popupfittedByUsForm.controls['billNo'].touched && popupfittedByUsForm.controls['billNo'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                      </div>

                      <div class="col-sm-3 pt-0 mt-0">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Brand</mat-label>
                          <input readonly matInput placeholder="Brand" formControlName="brandLable" />
                        </mat-form-field>
                        <mat-error
                          *ngIf="popupfittedByUsForm.controls['brandLable'].touched && popupfittedByUsForm.controls['brandLable'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                      </div>


                      <!-- <div class="col-sm-3 pt-0 mt-0">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Billing Company Name</mat-label>
                          <input  matInput placeholder="Billing Company Name"
                            formControlName="serviceName" />
                        </mat-form-field>
                      <mat-error
                      *ngIf="popupfittedByUsForm.controls['billingCompanyName'].touched && popupfittedByUsForm.controls['billingCompanyName'].errors?.required">
                      This field is mandatory.
                    </mat-error>
                      </div> -->
                      <div class="col-sm-3">
                        <div class="row">
                          <div class="col-sm-10 pr-1">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Billing Company
                                <!-- <span class="required-asterisk">*</span> -->
                              </mat-label>
                              <mat-select #select1 formControlName="billingCompanyName">
                                <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'"
                                  [displayMember]="'companyName'" [array]="billingCompanyList"
                                  (filteredReturn)="filteredListBillingCompany =$event"></mat-select-filter>
                                <mat-option *ngFor="let item of filteredListBillingCompany" [value]="item.companyName">
                                  {{ item.companyName }}</mat-option>
                              </mat-select>

                            </mat-form-field>
                            <mat-error class="margin_tp"
                              *ngIf="popupfittedByUsForm.controls['billingCompanyName'].touched && popupfittedByUsForm.controls['billingCompanyName'].errors?.required">
                              This field is mandatory.
                            </mat-error>
                          </div>
                          <div class="col-sm-2 mt-2 no-print">
                            <i (click)="openBillingCompPopUp();" matTooltip="Add Billing Company"
                              class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-3 pt-0 mt-0">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>MRP</mat-label>
                          <input matInput placeholder="MRP" formControlName="mrp" />
                        </mat-form-field>
                        <mat-error
                          *ngIf="popupfittedByUsForm.controls['mrp'].touched && popupfittedByUsForm.controls['mrp'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                      </div>
                      <div class="col-sm-3 pt-0 mt-0">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Sales Rate</mat-label>
                          <input matInput placeholder="Total Rate" formControlName="totalRate" />
                        </mat-form-field>
                        <mat-error
                          *ngIf="popupfittedByUsForm.controls['totalRate'].touched && popupfittedByUsForm.controls['totalRate'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                      </div>
                      <div class="col-sm-3 pt-0 mt-0">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Qty</mat-label>
                          <input matInput placeholder="Qty" formControlName="qty" />
                        </mat-form-field>
                        <mat-error
                          *ngIf="popupfittedByUsForm.controls['qty'].touched && popupfittedByUsForm.controls['qty'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                      </div>
                      <div class="col-sm-3 pt-0 mt-0">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Serial Number1</mat-label>
                          <input matInput placeholder="serial Number1" formControlName="serialNumber1" />
                        </mat-form-field>
                        <mat-error
                          *ngIf="popupfittedByUsForm.controls['serialNumber1'].touched && popupfittedByUsForm.controls['serialNumber1'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                      </div>
                      <div class="col-sm-3 pt-0 mt-0">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Serial Number2</mat-label>
                          <input matInput placeholder="Serial Number2" formControlName="serialNumber2" />
                        </mat-form-field>
                        <!-- <mat-error
                          *ngIf="popupfittedByUsForm.controls['serialNumber2'].touched && popupfittedByUsForm.controls['serialNumber2'].errors?.required">
                          This field is mandatory.
                        </mat-error> -->
                      </div>
                      <div class="col-sm-3">
                        <div class="row">
                          <div class="col-sm-10 pr-1">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Select Patient </mat-label>
                              <input required (click)="openDepartmentPopUp();" matInput readonly
                                placeholder="Patient Name" formControlName="patientName" />

                            </mat-form-field>
                            <mat-error
                              *ngIf="popupfittedByUsForm.controls['patientName'].touched && popupfittedByUsForm.controls['patientName'].errors?.required">
                              This field is mandatory.
                            </mat-error>
                          </div>
                          <div class="col-sm-2 mTop no-print">
                            <i (click)="openPatientPopUp();" matTooltip="Add New Patient" class="blue_col mTop fa-solid fa-circle-plus
                             pl-1 fa-lg"></i>
                          </div>
                        </div>
                      </div>

                      <div class="col-sm-3 pt-0 mt-0">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Sold Date</mat-label>

                          <input formControlName="createdAt" matInput [matDatepicker]="createdAt" id="createdAt"
                            [max]="createdAt" placeholder="DD/MM/YYYY">
                          <mat-datepicker-toggle matSuffix [for]="createdAt">
                          </mat-datepicker-toggle>
                          <mat-datepicker #createdAt></mat-datepicker>

                        </mat-form-field>
                        <mat-error
                          *ngIf="popupfittedByUsForm.controls['createdAt'].touched && popupfittedByUsForm.controls['createdAt'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                      </div>
                      <div class="col-sm-3 pt-0 mt-0">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Payment Status</mat-label>
                          <!-- <input  matInput placeholder="Payment Status"
                            formControlName="paymentStatus" /> -->
                          <mat-select #select2 formControlName="paymentStatus" class="colorblack">

                            <mat-option value="Paid">Paid</mat-option>
                            <mat-option value="Unpaid">Unpaid</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <mat-error
                          *ngIf="popupfittedByUsForm.controls['paymentStatus'].touched && popupfittedByUsForm.controls['paymentStatus'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                      </div>
                      <!-- <div class="col-sm-3 pt-0 mt-0">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Branch Name</mat-label>
                          <input  matInput placeholder="branchName"
                            formControlName="branchName" />
                        </mat-form-field>
                      <mat-error
                      *ngIf="popupfittedByUsForm.controls['branchName'].touched && popupfittedByUsForm.controls['branchName'].errors?.required">
                      This field is mandatory.
                    </mat-error>
                      </div> -->
                      <div class="col-sm-3 ">
                        <div class="row">
                          <div class="col-sm-10 pr-1">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Branch
                                <!-- <span class="required-asterisk">*</span> -->
                              </mat-label>
                              <mat-select #select1 formControlName="branchName">
                                <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'"
                                  [displayMember]="'branchName'" [array]="BranchList"
                                  (filteredReturn)="filteredListBranch =$event"></mat-select-filter>

                                <mat-option *ngFor="let item of filteredListBranch" [value]="item.branchName">
                                  {{ item.branchName }}</mat-option>
                              </mat-select>

                            </mat-form-field>
                            <mat-error
                              *ngIf="popupfittedByUsForm.controls['branchName'].touched && popupfittedByUsForm.controls['branchName'].errors?.required">
                              This field is mandatory.
                            </mat-error>
                          </div>
                          <div class="col-sm-2 mt-2 no-print">
                            <i (click)="openBranchPopUp();" matTooltip="Add New Branch"
                              class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                          </div>

                        </div>

                      </div>
                    </div>

                    <div class="row pl-3 pr-3 mb-2">
                      <div class="col-sm-6"></div>
                      <div class="col-sm-4" *ngIf="submitButton"></div>
                      <div class="col-sm-2 mt-2" *ngIf="submitButton && vendorCreate">
                        <button class="btn mb-2 btn-sm btn-primary example-full-width" type="button"
                          (click)="saveDetails()">Save</button>
                      </div>
                      <div class="col-sm-2" *ngIf="updateButton">
                      </div>
                      <div class="col-sm-2 mt-2" *ngIf="updateButton && vendorUpdate">
                        <button type="button" class="btn mb-2 btn-sm btn-primary example-full-width"
                          (click)="updateMigration()">Update</button>
                      </div>
                      <div class="col-sm-2 mt-2" *ngIf="updateButton">
                        <button class="btn mb-2 btn-primary btn-sm  example-full-width" (click)="cancelUpdate()"
                          type="reset">Cancel </button>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

            </div>


          </div>
        </div>




      </div>
      </div>

    </form>
  </div>