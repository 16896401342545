import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
 import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { AfterViewInit, Directive, ViewChild } from "@angular/core";

import { MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner"; 
import { PopUpBranchComponent } from "../pop-up-branch/pop-up-branch.component";
import { PopupDepartmentComponent } from "../popup-department/popup-department.component";
import { PopupPostComponent } from "../popup-post/popup-post.component";
import { HttpClient } from "@angular/common/http";
@Component({
  selector: 'app-clinical-staff-master',
  templateUrl: './clinical-staff-master.component.html',
  styleUrls: ['./clinical-staff-master.component.scss']
})
export class ClinicalStaffMasterComponent implements OnInit {

  errormessage: string;
  searchText: any;
  staffId: any;
  clinicData: any;
  allState=[
    {name:'Andaman and Nicobar Islands',value:'AN' },
    {name:'Andhra Pradesh',value:'AP'},
    { name:'Arunachal Pradesh',value:'AR' },
{name:'Assam',value:'AS' },
{ name:'Bihar',value:'BR'},
 { name:'Chandigarh',value:'CH' },
{name:'Chhattisgarh',value:'CT' },
{ name:'Dadra and Nagar Haveli', value:'DN' },
 { name:'Daman and Diu', value:'DD'},
 { name:'Delhi',value:'DL'},
 {name:'Goa',value:'GA' },
{name:'Gujarat', value:'GJ'}, 
{name:'Haryana',value:'HR'}, 
{name:'Himachal Pradesh',value:'HP'}, 
{ name:'Jammu and Kashmir',value:'JK'}, 
 {name:'Jharkhand',value:'JH'}, 
{ name:'Karnataka', value:'KA'},
{ name:'Kerala',value:'KL'},
{name:'Ladakh',value:'LA'},
 {name:'Lakshadweep',value:'LD' },
 {name:'Madhya Pradesh', value:'MP'},
{ name:'Maharashtra',value:'MH'},
 { name:'Manipur',value:'MN'},
{ name:'Meghalaya', value:'ML' },
{ name:'Mizoram',value:'MZ' },
{ name:'Nagaland',value:'NL' },
 { name:'Odisha',value:'OR'},
 { name:'Puducherry', value:'PY'},
 {name:'Punjab', value:'PB' },
{ name:'Rajasthan', value:'RJ'},           
 {name:'Punjab',value:'PB'},   
 {name:'Rajasthan', value:'RJ'},
{name:'Sikkim',value:'SK' },
{ name:'Tamil Nadu',value:'TN'}, 
 {name:'Telangana',value:'TG'},   
  { name:'Tripura',  value:'TR' },
 { name:'Uttar Pradesh', value:'UP'},
{name:'Uttarakhand',value:'UT'},  
{ name:'West Bengal', value:'WB' },                        
  ]
  allDays=[
    {name:'None',value:'None'},
{name:'Sunday',value:'Sunday'},
{ name:'Monday',value:'Monday' },
{name:'Tuesday',value:'Tuesday' },
{ name:'Wednesday',value:'Wednesday'},
{name:'Thursday',value:'Thursday'},
{name:'Friday',value:'Friday'},
{name:'Saturday',value:'Saturday'},
  ]
  allDepartment=[
    {name:'Audiology',value:'Audiology'},
    { name:'Admin',value:'Admin' },
    {name:'Accounts',value:'Accounts' },
    { name:'HR',value:'HR'},
    { name:'Housekeeping',value:'Housekeeping'},
   
  ] 
  allPosts=[
    {name:'Audiologist',value:'Audiologist'},
    { name:'Assistant Audiologist',value:'Assistant Audiologist' },
    {name:'SLP',value:'SLP' },
    { name:'CSO',value:'CSO'},
    { name:'Janitor',value:'Janitor'},
    { name:'Watchman',value:'Watchman'},
   
  ]
  filteredList: { name: string; value: string; }[];
  departmentList: { name: string; value: string; }[];
  postsList: { name: string; value: string; }[];
  filteredListDays: ({ name: string; value?: undefined; } | { name: string; value: string; })[];
  branchRef: string;
  clinicRef: string;
  BranchList: any;
  userType: string;
  branchName: string;
  superBranchId: any;
  filteredListBranch: any;
  currentdate: Date;
  departmentArr: any;
  postArr: any;
  postList: any;
  bId: any;
  stateCityArr2: any[];
  stateCity2: any;
  districtData2: any;
  filteredList2: { state: string; districts: string; }[];
  filteredListDistrict2: { district: string;}[];
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
   public apimicroservice: ApiServiceService,
    public router: Router,
     public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    private httpClient: HttpClient,
  ) {}
  clinicalStaffMasterForm: FormGroup;
  status: any;
  submitButton = true;
  updateButton = false;
  deleteButton = false;
  cancelButton=false;
  loggedId: any;
  myimage;
  date: any;
  size:number =10;
  page:number =1;
  staffData: any; 
  totalLength:any;
  listofstaff:any;
  
  userPermission:any;
  staffCreate= true;
  staffUpdate= true;
  isSuperAdmin= true;
  ngOnInit() {
    this.page=1;
    this.currentdate = new Date();
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.userType = sessionStorage.getItem('userType');
    this.date=new Date();
    this.filteredList = this.allState.slice();
   // this.departmentList = this.allDepartment.slice();
    //this.postsList = this.allPosts.slice();
    this.filteredListDays = this.allDays.slice();
    if(this.userType !== 'Super Admin'){
      this.isSuperAdmin= false;
  this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    //
    this.userPermission.forEach((element) => {
      if(element.pageName == 'Clinical Staff' && element.create == false)
      {
          this.staffCreate = false;  
      }
      if(element.pageName == 'Clinical Staff' && element.update == false)
      {
          this.staffUpdate = false;  
      }  
    });
  }
    this.clinicalStaffMasterForm = this.fb.group({
      "branchWiseId": new FormControl(''),
      "staffMemberName": new FormControl('',[Validators.required,Validators.pattern("^[a-zA-Z_-\\s]*$")]),
      "staffMemberShortCode": new FormControl('',Validators.required),
      "department": new FormControl('',Validators.required),
      "post": new FormControl('',Validators.required),
      "weeklyOff": new FormControl(''),
      "assignedClinic": new FormControl('',Validators.required),
      "openTime": new FormControl('',Validators.required),
      "closeTime": new FormControl('',Validators.required),
      "basicSalaryPerHours": new FormControl('',[Validators.pattern("^[0-9]*$")]),
      "dailyTravellingAllowence": new FormControl('',[Validators.pattern("^[0-9]*$")]),
      "workingFrom": new FormControl(''),
      // "address": new FormControl('',Validators.required),
      "staffContactNumbers": new FormControl('',[Validators.required,Validators.pattern("(0|91)?[6-9][0-9]{9}")]),
      "staffMemberAddress": new FormControl('',Validators.required),
      "PinCode":new FormControl('', [Validators.required,Validators.pattern("^[0-9]{6}$")]),
      "taluka": new FormControl('',Validators.pattern("^[a-zA-Z ]*$")),
      "district": new FormControl('',Validators.pattern("^[a-zA-Z ]*$")),
      "state": new FormControl('',Validators.required),
      "country": new FormControl('', [Validators.pattern("[A-Za-z _-]{3,19}$"),]),
      "searchStaff": new FormControl(''),
      "createdAt": this.date,
      "branchName": new FormControl(),
      "branchRefId": new FormControl(),
      "clinicRefId": new FormControl(),
      
      
    });
    this.clinicalStaffMasterForm.controls["assignedClinic"].setValue(this.clinicRef);
    this.clinicalStaffMasterForm.controls["workingFrom"].setValue(this.currentdate);
    this.clinicalStaffMasterForm.controls["country"].setValue("India");
    this.clinicalStaffMasterForm.controls["state"].setValue("Maharashtra");
    let arr=[]
    arr = [
      "Ahmednagar",
      "Akola",
      "Amravati",
      "Aurangabad",
      "Beed",
      "Bhandara",
      "Buldhana",
      "Chandrapur",
      "Dhule",
      "Gadchiroli",
      "Gondia",
      "Hingoli",
      "Jalgaon",
      "Jalna",
      "Kolhapur",
      "Latur",
      "Mumbai City",
      "Mumbai Suburban",
      "Nagpur",
      "Nanded",
      "Nandurbar",
      "Nashik",
      "Osmanabad",
      "Palghar",
      "Parbhani",
      "Pune",
      "Raigad",
      "Ratnagiri",
      "Sangli",
      "Satara",
      "Sindhudurg",
      "Solapur",
      "Thane",
      "Wardha",
      "Washim",
      "Yavatmal"
  ]
  this.getDistrict2(arr);
    this.fetchStaffList();
    this.fetchClinicList();
    this.getLastId();
    this.removeValidator();
    this.fetchBranchList();
    this.getAllBranch();
    this.getDepartmentAllData();
    this.getPostAllData();
    this.getState2();
  } 

  getState2()
  {
   this.httpClient.get("assets/state-city.json").subscribe(data =>{ 
     this.stateCity2 = data;
     this.stateCityArr2 = [];
     this.stateCity2.states.forEach((element) => {
       this.stateCityArr2.push(element);
     });
     this.filteredList2 = this.stateCityArr2.slice(); 
     //
   })
  }
  getDistrict2(state)
  {
   //
   this.districtData2 = [];
   state.forEach((element) => {
     //
     this.districtData2.push({"district":element});
   });
   //
   let arr = [];
   arr = state;
  this.filteredListDistrict2 = this.districtData2.slice();
  }
  getDepartmentAllData(){
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
    this.apimicroservice.getDepartmentAllData(branchRef,clinicRef).subscribe(res => {
      //  
      if (res.status) {    
        this.departmentArr = res.data;
        this.departmentList= this.departmentArr.slice();
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    })
  }else{
    this.apimicroservice.getDepartmentAllData(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
      //  
      if (res.status) {    
        this.departmentArr = res.data;
        this.departmentList= this.departmentArr.slice();
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
  }
  getAllBranch()
  {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      if (res.status) {         
        let length =res.data.length - 1; 
        this.branchName=res.data[length].branchName;
        this.clinicalStaffMasterForm.controls["branchRefId"].setValue(res.data[length]._id);
        this.branchClick(res.data[length]._id,this.branchName);
        if(this.userType == 'Super Admin')
        {  
          this.apimicroservice.getBranchById(res.data[length]._id).subscribe(res => {
            this.spinner.hide();
            this.clinicalStaffMasterForm.get("branchName").setValue(res.data[length].branchName);
           });
        }
      }
    });
  }
  removeValidator() { 
    this.clinicalStaffMasterForm.controls['staffMemberName'].setErrors(null);
    this.clinicalStaffMasterForm.controls['staffMemberName'].clearValidators();
    this.clinicalStaffMasterForm.controls['staffMemberName'].updateValueAndValidity();
    
    this.clinicalStaffMasterForm.controls['staffMemberShortCode'].setErrors(null);
    this.clinicalStaffMasterForm.controls['staffMemberShortCode'].clearValidators();
    this.clinicalStaffMasterForm.controls['staffMemberShortCode'].updateValueAndValidity();
    
    this.clinicalStaffMasterForm.controls['assignedClinic'].setErrors(null);
    this.clinicalStaffMasterForm.controls['assignedClinic'].clearValidators();
    this.clinicalStaffMasterForm.controls['assignedClinic'].updateValueAndValidity();
   
    this.clinicalStaffMasterForm.controls['department'].setErrors(null);
    this.clinicalStaffMasterForm.controls['department'].clearValidators();
    this.clinicalStaffMasterForm.controls['department'].updateValueAndValidity();

    this.clinicalStaffMasterForm.controls['post'].setErrors(null);
    this.clinicalStaffMasterForm.controls['post'].clearValidators();
    this.clinicalStaffMasterForm.controls['post'].updateValueAndValidity();

    this.clinicalStaffMasterForm.controls['openTime'].setErrors(null);
    this.clinicalStaffMasterForm.controls['openTime'].clearValidators();
    this.clinicalStaffMasterForm.controls['openTime'].updateValueAndValidity();

    this.clinicalStaffMasterForm.controls['closeTime'].setErrors(null);
    this.clinicalStaffMasterForm.controls['closeTime'].clearValidators();
    this.clinicalStaffMasterForm.controls['closeTime'].updateValueAndValidity();

    this.clinicalStaffMasterForm.controls['staffMemberAddress'].setErrors(null);
    this.clinicalStaffMasterForm.controls['staffMemberAddress'].clearValidators();
    this.clinicalStaffMasterForm.controls['staffMemberAddress'].updateValueAndValidity();

    this.clinicalStaffMasterForm.controls['PinCode'].setErrors(null);
    this.clinicalStaffMasterForm.controls['PinCode'].clearValidators();
    this.clinicalStaffMasterForm.controls['PinCode'].updateValueAndValidity();

    this.clinicalStaffMasterForm.controls['staffContactNumbers'].setErrors(null);
    this.clinicalStaffMasterForm.controls['staffContactNumbers'].clearValidators();
    this.clinicalStaffMasterForm.controls['staffContactNumbers'].updateValueAndValidity();

  }


  setValidator() { 
    this.clinicalStaffMasterForm.controls['staffMemberName'].setValidators([Validators.required,Validators.pattern("^[a-zA-Z_-\\s]*$")]);
    this.clinicalStaffMasterForm.controls['staffMemberName'].updateValueAndValidity();

    this.clinicalStaffMasterForm.controls['staffMemberShortCode'].setValidators(Validators.required);
    this.clinicalStaffMasterForm.controls['staffMemberShortCode'].updateValueAndValidity();

    this.clinicalStaffMasterForm.controls['assignedClinic'].setValidators(Validators.required);
    this.clinicalStaffMasterForm.controls['assignedClinic'].updateValueAndValidity();

    this.clinicalStaffMasterForm.controls['department'].setValidators(Validators.required);
    this.clinicalStaffMasterForm.controls['department'].updateValueAndValidity();
    
    this.clinicalStaffMasterForm.controls['post'].setValidators(Validators.required);
    this.clinicalStaffMasterForm.controls['post'].updateValueAndValidity();
    
    this.clinicalStaffMasterForm.controls['openTime'].setValidators(Validators.required);
    this.clinicalStaffMasterForm.controls['openTime'].updateValueAndValidity();
    
    this.clinicalStaffMasterForm.controls['closeTime'].setValidators(Validators.required);
    this.clinicalStaffMasterForm.controls['closeTime'].updateValueAndValidity();

    this.clinicalStaffMasterForm.controls['staffMemberAddress'].setValidators(Validators.required);
    this.clinicalStaffMasterForm.controls['staffMemberAddress'].updateValueAndValidity();

    this.clinicalStaffMasterForm.controls['PinCode'].setValidators([Validators.required,Validators.pattern("^[0-9]{6}$")]);
    this.clinicalStaffMasterForm.controls['PinCode'].updateValueAndValidity();

    this.clinicalStaffMasterForm.controls['staffContactNumbers'].setValidators([Validators.required,Validators.pattern("(0|91)?[6-9][0-9]{9}")]);
    this.clinicalStaffMasterForm.controls['staffContactNumbers'].updateValueAndValidity();


  }



  submitStaff() {
    //
    this.setValidator();
    if(this.userType == 'Super Admin')
    {
      this.clinicalStaffMasterForm.controls['branchRefId'].setValidators(Validators.required);
      this.clinicalStaffMasterForm.controls['branchRefId'].updateValueAndValidity();
    }
    else{
      this.clinicalStaffMasterForm.controls['branchRefId'].setErrors(null);
      this.clinicalStaffMasterForm.controls['branchRefId'].clearValidators();
      this.clinicalStaffMasterForm.controls['branchRefId'].updateValueAndValidity();
    }
    if (this.clinicalStaffMasterForm.invalid) {
      //
      this.clinicalStaffMasterForm.get("staffMemberName").markAsTouched();
      this.clinicalStaffMasterForm.get("staffMemberShortCode").markAsTouched();
      this.clinicalStaffMasterForm.get("department").markAsTouched();
      this.clinicalStaffMasterForm.get("post").markAsTouched();
      this.clinicalStaffMasterForm.get("weeklyOff").markAsTouched();
      this.clinicalStaffMasterForm.get("assignedClinic").markAsTouched();
      this.clinicalStaffMasterForm.get("PinCode").markAsTouched();
      this.clinicalStaffMasterForm.get("openTime").markAsTouched();
      this.clinicalStaffMasterForm.get("closeTime").markAsTouched();
      this.clinicalStaffMasterForm.get("basicSalaryPerHours").markAsTouched();
      this.clinicalStaffMasterForm.get("dailyTravellingAllowence").markAsTouched();
      this.clinicalStaffMasterForm.get("workingFrom").markAsTouched();
      this.clinicalStaffMasterForm.get("staffMemberAddress").markAsTouched();
      this.clinicalStaffMasterForm.get("staffContactNumbers").markAsTouched();
      this.clinicalStaffMasterForm.get("state").markAsTouched();


      return;
    } else {
      //
      this.addStaff();
    }
  }
  updateStaffDetails() {

    this.setValidator();
    if(this.userType == 'Super Admin')
    {
      this.clinicalStaffMasterForm.controls['branchRefId'].setValidators(Validators.required);
      this.clinicalStaffMasterForm.controls['branchRefId'].updateValueAndValidity();
    }
    else{
      this.clinicalStaffMasterForm.controls['branchRefId'].setErrors(null);
      this.clinicalStaffMasterForm.controls['branchRefId'].clearValidators();
      this.clinicalStaffMasterForm.controls['branchRefId'].updateValueAndValidity();
    }
    if (this.clinicalStaffMasterForm.invalid) {
      this.clinicalStaffMasterForm.get("staffMemberName").markAsTouched();
      this.clinicalStaffMasterForm.get("staffMemberShortCode").markAsTouched();
      this.clinicalStaffMasterForm.get("department").markAsTouched();
      this.clinicalStaffMasterForm.get("post").markAsTouched();
      this.clinicalStaffMasterForm.get("weeklyOff").markAsTouched();
      this.clinicalStaffMasterForm.get("assignedClinic").markAsTouched();
      // this.clinicalStaffMasterForm.get("dailyWorkingHours").markAsTouched();
      this.clinicalStaffMasterForm.get("openTime").markAsTouched();
      this.clinicalStaffMasterForm.get("closeTime").markAsTouched();
      this.clinicalStaffMasterForm.get("basicSalaryPerHours").markAsTouched();
      this.clinicalStaffMasterForm.get("dailyTravellingAllowence").markAsTouched();
      this.clinicalStaffMasterForm.get("workingFrom").markAsTouched();
     this.clinicalStaffMasterForm.get("state").markAsTouched();
      this.clinicalStaffMasterForm.get("staffContactNumbers").markAsTouched();
      this.clinicalStaffMasterForm.get("staffMemberAddress").markAsTouched();
     
      return;
    } else {
      this.updateStaff();
    }
  }

  // updateUpdateSingleCustomer() {
  //   if (this.clinicalStaffMasterForm.invalid) {
  //     this.clinicalStaffMasterForm.get("staffMemberName").markAsTouched();
  //     this.clinicalStaffMasterForm.get("staffMemberShortCode").markAsTouched();
  //     this.clinicalStaffMasterForm.get("department").markAsTouched();
  //     this.clinicalStaffMasterForm.get("post").markAsTouched();
  //     this.clinicalStaffMasterForm.get("weeklyOff").markAsTouched();
  //     this.clinicalStaffMasterForm.get("assignedClinic").markAsTouched();
  //     this.clinicalStaffMasterForm.get("dailyWorkingHours").markAsTouched();
  //     this.clinicalStaffMasterForm.get("basicSalaryPerHours").markAsTouched();
  //     this.clinicalStaffMasterForm.get("dailyTravellingAllowence").markAsTouched();
  //     this.clinicalStaffMasterForm.get("workingFrom").markAsTouched();
  //     this.clinicalStaffMasterForm.get("address").markAsTouched();
  //     this.clinicalStaffMasterForm.get("staffContactNumbers").markAsTouched();
  //     this.clinicalStaffMasterForm.get("staffMemberAddress").markAsTouched();
     
  //     return;
  //   } else {
  //   //update code
  //   }
  // }
  
  addStaff() {
    this.spinner.show();
    let payload = this.clinicalStaffMasterForm.value;
    payload.createdAt= new Date();

   if(this.userType !== 'Super Admin')
    {
      payload.branchRefId = this.branchRef;
      payload.clinicRefId = this.clinicRef;
      payload.branchName = this.branchName;
    }
    

      //  //);
      this.apimicroservice.saveClinicalStaffData(payload).subscribe(res => {
        this.spinner.hide();
        this.ngOnInit();
        // this.next.emit();
        if (res.status) {
          this.apimicroservice.openSnackbar(res.message);
          //this.ngOnInit();
          this.clinicalStaffMasterForm.reset();
        } else {
          this.apimicroservice.openSnackbar(res.message);
        }
        
        // this.myimage ="../assets/images/setting2.png";
        // this.myLocationimage ="../../assets/images/no-image-available.png";
       
      });
  }
  // openSnackbar(message: string) {
  //   return this._snackBar.open(message, "close", {
  //     verticalPosition: "bottom",
  //     horizontalPosition: "right",
  //     duration: 2000,
  //     panelClass: ["snackbarStyle"],
  //   });
  // }
  

  
  // deleteStaff(id){
  //   this.spinner.show();
  //   this.apimicroservice.deleteClinicalStaffData(id).subscribe(res => {
  //     this.spinner.hide();
  //     this.fetchStaffList();
  //     if (res.status) {
  //       this.apimicroservice.openSnackbar(res.message);
  //     } else {
  //       this.apimicroservice.openSnackbar(res.message);
  //     }
  //     this.clinicalStaffMasterForm.reset();  
  //     this.submitButton=true;
  //     this.updateButton = false;
      
  //     this.deleteButton=false;
      
  //   });
  //  }


  fetchStaffList() {
    this.spinner.show();
    let pg = 1;
    let size = 10;
    let search='';
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
  

    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getClinicalStaffData(pg,size,search,branchRef,clinicRef).subscribe(res => {
        this.spinner.hide();
        this.listofstaff = res.data.clinicalStaffData;
        this.totalLength=res.data.totalItems; 
      });
    } else {
      this.apimicroservice.getClinicalStaffData(pg,size,search,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
        this.spinner.hide();
        this.listofstaff = res.data.clinicalStaffData;
        this.totalLength=res.data.totalItems;
      });
    }

   
  }

  prepopulation(id) {
    this.spinner.show();
    this.apimicroservice.getClinicalStaffDataById(id).subscribe(res => {
      this.submitButton=false;
       this.updateButton =true;
       this.deleteButton= true;
       this.cancelButton=true;
       this.spinner.hide();
       let data = res.data[0];
 
         this.clinicalStaffMasterForm = this.fb.group({
          
          "branchWiseId": new FormControl(data.branchWiseId),
          "staffMemberName": new FormControl(data.staffMemberName,[Validators.required,Validators.pattern("^[a-zA-Z_-\\s]*$")]),
          "staffMemberShortCode": new FormControl(data.staffMemberShortCode,Validators.required),
          "department": new FormControl(data.department,Validators.required),
          "post": new FormControl(data.post,Validators.required),
          "weeklyOff": new FormControl(data.weeklyOff),
          "assignedClinic": new FormControl(data.assignedClinic,Validators.required),
          "openTime": new FormControl(data.openTime,Validators.required),
          "closeTime": new FormControl(data.closeTime,Validators.required),
          // "dailyWorkingHours": new FormControl(data.dailyWorkingHours,Validators.required),
          "basicSalaryPerHours": new FormControl(data.basicSalaryPerHours,[Validators.pattern("^[0-9]*$")]),
          "dailyTravellingAllowence": new FormControl(data.dailyTravellingAllowence,[Validators.pattern("^[0-9]*$")]),
          "workingFrom": new FormControl(data.workingFrom),
          // "address": new FormControl(data.address,Validators.required),
          "staffContactNumbers": new FormControl(data.staffContactNumbers,[Validators.required,Validators.pattern("(0|91)?[6-9][0-9]{9}")]),
          "staffMemberAddress": new FormControl(data.staffMemberAddress,Validators.required),
          "searchStaff": new FormControl(''),
          "PinCode":new FormControl(data.PinCode, [Validators.required,Validators.pattern("^[0-9]{6}$")]),
          "taluka": new FormControl(data.taluka,Validators.pattern("^[a-zA-Z ]*$")),
          "district": new FormControl(data.district,Validators.pattern("^[a-zA-Z ]*$")),
          "state": new FormControl(data.state,Validators.required),
          "country": new FormControl(data.country, [Validators.pattern("[A-Za-z _-]{3,19}$"), ]),
          // "createdAt": this.date,
          "updatedAt": this.date,
          //  "id":id,
          "branchRefId": new FormControl(data.branchRefId),
          "clinicRefId": new FormControl(data.clinicRefId),
          "branchName": new FormControl(data.branchName),
         });
         this.superBranchId = data.branchWiseId;
         this.bId=data.branchWiseId;
         this.staffId = id;
        
         
        //  this.myimage = data.image ? data.image : "../../assets/images/setting2.png";
        //  this.myLocationimage = data.locationImage ? data.locationImage : "../../assets/images/no-image-available.png";
     });


  }
  addNew() {
    this.clinicalStaffMasterForm.reset();
    this.updateButton = false;
    this.deleteButton = false;
  }
  updateStaff() {
    this.spinner.show();
    let payload = this.clinicalStaffMasterForm.value;

    if(this.userType !== 'Super Admin')
     {
       payload.branchRefId = this.branchRef;
       payload.clinicRefId = this.clinicRef;
       payload.branchName = this.branchName;
     }
    

    this.apimicroservice.updateClinicalStaffData(this.staffId,payload).subscribe(res => {
      this.spinner.hide();
      this.ngOnInit();
      if (res.status) {
        this.apimicroservice.openSnackbar(res.message);
        this.submitButton=true;
        this.updateButton = false;
        this.cancelButton=false;
       
        this.deleteButton=false;
       // this.ngOnInit();
      // this.clinicalStaffMasterForm.reset();
          //this.fetchStaffList();
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
     
     //  this.clinicalStaffMasterForm.reset();
    },
    );
    
  }
 
  onPageChange(event) {
    this.spinner.show();
    this.page = event;
    //pg = this.page;
    this.searchText = '';
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    

    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getClinicalStaffData(this.page,this.size,this.searchText,branchRef,clinicRef).subscribe(res => {
        this.spinner.hide();
        if (res.status) {
          this.listofstaff = res.data.clinicalStaffData;
          this.totalLength=res.data.totalItems;
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
  
      })
    } else {
      this.apimicroservice.getClinicalStaffData(this.page,this.size,this.searchText,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
        this.spinner.hide();
        if (res.status) {
          this.listofstaff = res.data.clinicalStaffData;
          this.totalLength=res.data.totalItems;
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
  
      })
    }


    
  }
  onKeyUpEvent(event: any) {
  
    this.searchText = this.clinicalStaffMasterForm.get('searchStaff').value;
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    let page=1;
    let size=10;

    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getClinicalStaffData(page,size,this.searchText,branchRef,clinicRef).subscribe(res => {
        //
        if (res.status) {
          this.listofstaff = res.data.clinicalStaffData;
          this.totalLength=res.data.totalItems;
          this.page=res.data.pageNumber;
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
  
      })
    } else {

      this.apimicroservice.getClinicalStaffData(page,size,this.searchText,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
        //
        if (res.status) {
          this.listofstaff = res.data.clinicalStaffData;
          this.totalLength=res.data.totalItems;
          this.page=res.data.pageNumber;
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
  
      })
      
    }

  

  }
 
  redirect(to): void {
    this.router.navigate([to]);
  }
  


  getLastId()
  { 
    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getLastStaffID(this.branchRef).subscribe(res => {
        //   
        // if(res['data']['data'] == '')
        // {
        //   this.clinicalStaffMasterForm.controls['branchWiseId'].setValue('1');
        // }
        if (res['status']) {
          let staffIdd = res['data']['totalItems'];
          this.bId=staffIdd + 1;
          //this.clinicalStaffMasterForm.controls['branchWiseId'].setValue(staffIdd + 1);
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
       
      })
    } else {
      this.apimicroservice.getLastStaffAll().subscribe(res => {
        //   
        // if(res['data']['data'] == '')
        // {
        //   this.clinicalStaffMasterForm.controls['branchWiseId'].setValue('1');
        // }
        if (res['status']) {
          let staffIdd = res['data']['totalItems'];
          this.superBranchId = staffIdd + 1;
          this.bId=staffIdd + 1; 
           //this.clinicalStaffMasterForm.controls['branchWiseId'].setValue(staffIdd + 1);
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
       
      })
      
    }
    
  }

  onChange($event: Event) {
    const file = ($event.target as HTMLInputElement).files[0];
    const fileName = file.name;
    this.errormessage = '';
    let ext = fileName.substring(fileName.lastIndexOf(".") + 1);
    if (
      ext.toLowerCase() == "png" ||
      ext.toLowerCase() == "jpg" ||
      ext.toLowerCase() == "jpeg" ||
      ext.toLowerCase() == "csv" ||
      ext.toLowerCase() == "xlsx" ||
      ext.toLowerCase() == "pdf" ||
      ext.toLowerCase() == "docx" 
    ) { 
      this.convertToBase64(file);
    }
  }

  cancelUpdate(){
    this.clinicalStaffMasterForm.reset();
    this.submitButton=true;
    this.updateButton=false;
    this.cancelButton=false;
    this.ngOnInit();
  }

  convertToBase64(file: File) {
    const observable = new Observable((subscriber: Subscriber<any>) => {
      this.readFile(file, subscriber);
    });
    observable.subscribe((d) => {
      this.myimage = d;
      
    });
  }
  readFile(file: File, subscriber: Subscriber<any>) {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      subscriber.next(fileReader.result);
      subscriber.complete();
    };
    fileReader.onerror = (error) => {
      subscriber.error(error);
      subscriber.complete();
    };
  }
  fetchClinicList() {
    let id= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllClinicList(id).subscribe(res => {
      this.clinicData = res.data;
      if(res.status){
     //   this.apimicroservice.openSnackbar(res.message);      
      }else{
        //this.apimicroservice.openSnackbar(res.message);
      }
  });
  }
  onSelectClinic(id){
    //
  }
  toClinicalStaffForm() {
    document.getElementById("toClinicalStaffForm").scrollIntoView();
  }
  toClinicalStaffList(){
    document.getElementById("toClinicalStaffTable").scrollIntoView();
  }
  toOpenTimeChange()
  {
    // let payload = this.clinicalStaffMasterForm.value;
    // if(payload.closeTime <= payload.openTime && payload.closeTime !== '')
    // {
    //   this.apimicroservice.openSnackbar("Open Time should be greater than Close Time");
    //   this.clinicalStaffMasterForm.controls['openTime'].setValue('');
    // }
    let payload = this.clinicalStaffMasterForm.value;
    let dd = new Date();
    let a = '';
    let b ='';
    a = payload.openTime;
    b = payload.closeTime;
    //
    //
    
    const date1 = new Date(dd.toDateString()+" "+a);
    const date2 = new Date(dd.toDateString()+" "+b);
    if(date2 <= date1 && payload.closeTime !== '')
    {
      this.apimicroservice.openSnackbar("start Time should be Less than Close Time");
      this.clinicalStaffMasterForm.controls['openTime'].setValue('');
    }
  }
  toCloseTimeChange()
  {
    // let payload = this.clinicalStaffMasterForm.value;
    // if(payload.closeTime <= payload.openTime && payload.openTime !== '')
    // {
    //   this.apimicroservice.openSnackbar("Close Time should be Less than Open Time");
    //   this.clinicalStaffMasterForm.controls['closeTime'].setValue('');
    // }
    let payload = this.clinicalStaffMasterForm.value;
    let dd = new Date();
    let a = '';
    let b ='';
    a = payload.openTime;
    b = payload.closeTime;
    //
    //
    // const [hh,mm,ss] = a.split(':');
    // const [hh2,mm2,ss2] = b.split(':');
    const date1 = new Date(dd.toDateString()+" "+a);
    const date2 = new Date(dd.toDateString()+" "+b);
    //+" "+a);
    //
    if(date2.getTime() <= date1.getTime() && payload.openTime !== '')
    {
      this.apimicroservice.openSnackbar("End Time should be Greater than Open Time");
      this.clinicalStaffMasterForm.controls['closeTime'].setValue('');
    }
  }
  fetchBranchList() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      //  
      if (res.status) {    
        this.BranchList = res.data;
        this.filteredListBranch= this.BranchList.slice();
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    })
  } 
  branchClick(id,branchName)
  {

    this.clinicalStaffMasterForm.controls["branchName"].setValue(branchName);

   this.apimicroservice.getBranchById(id).subscribe(res => { 
     //
       
     let data = res.data[0];
     let payload = this.clinicalStaffMasterForm.value;
      payload.clinicRefId = res.data[0].clinicRefId;
      this.clinicalStaffMasterForm.controls["clinicRefId"].setValue(res.data[0].clinicRefId);
       if(this.updateButton == false){ 
        this.spinner.show();
        this.apimicroservice.getLastStaffID(payload.branchRefId).subscribe(res => {
          //   
         
          if (res['status']) {
            let staffIdd = res['data']['totalItems'];
            this.clinicalStaffMasterForm.controls['branchWiseId'].setValue(staffIdd + 1);
            //this.bId=staffIdd + 1;
          } else {
            this.apimicroservice.openSnackbar("Something went wrong");
          }
          this.spinner.hide();
        })
      }
    });
  }
   openBranchPopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpBranchComponent,
      {
        width: "1200px",
      });
      dialogRef3.afterClosed().subscribe((result) => {
        let clinicRef= sessionStorage.getItem('clinicRef');
        this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
          //  
          if (res.status) {    
            this.BranchList = res.data;
            this.filteredListBranch= this.BranchList.slice();

          this.branchClick(res.data[0]._id,res.data[0].branchName);
            this.clinicalStaffMasterForm.get("branchRefId").setValue(res.data[0]._id);
          } 
        })
        //this.fetchBranchList();
      })
  }
  WhatsApp()
  {
    let payload = this.clinicalStaffMasterForm.value;  
    let num = payload.staffContactNumbers; 
    if(num.length == 10)
    {
      sessionStorage.setItem('whatsAppNumber',payload.staffContactNumbers);
      let mobileNumber = sessionStorage.getItem('whatsAppNumber');
      window.open("https://wa.me/91"+mobileNumber);
    }
    else
    {
      this.clinicalStaffMasterForm.get("staffContactNumbers").markAsTouched();
    }
  }
  onback() {
    this.apimicroservice.back()
  }
  openDepartmentPopUp() {
    const dialogRef3 = this.dialog.open(PopupDepartmentComponent,
      {
        width: "400px",
      }); 
  
      dialogRef3.afterClosed().subscribe((result) => {
        let clinicRef= sessionStorage.getItem('clinicRef');
        let branchRef= sessionStorage.getItem('branchRef');
        let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
        let branchRefSuperAdmin= '';
        if (this.userType !== 'Super Admin') {
        this.apimicroservice.getDepartmentAllData(branchRef,clinicRef).subscribe(res => {
          //  
          if (res.status) {    
            this.departmentArr = res.data;
            this.departmentList= this.departmentArr.slice();
            this.clinicalStaffMasterForm.get("department").setValue(res.data[0].departmentName);
          } else {
            //this.apimicroservice.openSnackbar(res.message);
          }
        })
      }else{
        this.apimicroservice.getDepartmentAllData(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
          //  
          if (res.status) {    
            this.departmentArr = res.data;
            this.departmentList= this.departmentArr.slice();
            this.clinicalStaffMasterForm.get("department").setValue(res.data[0].departmentName);
          } else {
            //this.apimicroservice.openSnackbar(res.message);
          }
        })
      }
       //this.getDepartmentAllData();  
      });
  } 
  getPostAllData(){
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
    this.apimicroservice.getPostAllData(branchRef,clinicRef).subscribe(res => {
      //  
      if (res.status) {    
        this.postArr = res.data;
        this.postList= this.postArr.slice();
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    })
  }else{
    this.apimicroservice.getPostAllData(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
      //  
      if (res.status) {    
        this.postArr = res.data;
        this.postList= this.postArr.slice();
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
  } 
  openPostPopUp() {
    const dialogRef3 = this.dialog.open(PopupPostComponent,
      {
        width: "400px",
      }); 
  
      dialogRef3.afterClosed().subscribe((result) => {
        let clinicRef= sessionStorage.getItem('clinicRef');
        let branchRef= sessionStorage.getItem('branchRef');
        let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
        let branchRefSuperAdmin= '';
        if (this.userType !== 'Super Admin') {
        this.apimicroservice.getPostAllData(branchRef,clinicRef).subscribe(res => {
          //  
          if (res.status) {    
            this.postArr = res.data;
            //
            this.postList= this.postArr.slice();
            this.clinicalStaffMasterForm.get("post").setValue(res.data[0].postName);
          } else {
            //this.apimicroservice.openSnackbar(res.message);
          }
        })
      }else{
        this.apimicroservice.getPostAllData(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
          //  
          if (res.status) {    
            this.postArr = res.data;
            //
            this.postList= this.postArr.slice();
            this.clinicalStaffMasterForm.get("post").setValue(res.data[0].postName);
          } else {
            //this.apimicroservice.openSnackbar(res.message);
          }
        })
      }
      
      });
  }

}

