import { Component, Inject, OnInit } from "@angular/core";
import {FormGroup,FormBuilder,FormControl} from '@angular/forms';
import { ApiServiceService } from '../api-service.service';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-popup-new-complaint',
  templateUrl: './popup-new-complaint.component.html',
  styleUrls: ['./popup-new-complaint.component.scss']
})
export class PopupNewComplaintComponent implements OnInit {
  complaintId:any;
  popupNewComplaintForm: FormGroup;
  variables3: any;
  filteredList1: any;
  filteredList2: any;
  variables4: any;
  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<PopupNewComplaintComponent>,@Inject(MAT_DIALOG_DATA) public data:any,
    public apimicroservice: ApiServiceService,
) { dialogRef.disableClose = true;}
 addNewComplaint = false;
  ngOnInit() {
    this.complaintId=this.data.id;
   this.getDataById();
    this.popupNewComplaintForm = this.fb.group({  
      "leftComplaintName": new FormControl(''),
      "rightComplaintName": new FormControl(''),
      "rightRepairItemNameList": new FormControl(''),
      "leftComplaintNameList": new FormControl(''),
      
      
    });
    this.getLeftComplaintData();
    this.getRightComplaintData();
  }
  getDataById()
  {
   
  }


  saveLeftComplaintData(){
    let payload = this.popupNewComplaintForm.value;
   
    
    this.apimicroservice.submitLeftComplaintData(payload).subscribe(res => {
      //
      
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);
        
        this.ngOnInit();
        //this.loadBuildigdata()
       this.onClose();
        
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    });
  }

  saveRightComplaintData(){
    let payload = this.popupNewComplaintForm.value;
   
    
    this.apimicroservice.submitRightComplaintData(payload).subscribe(res => {
      //
      
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);
        
        this.ngOnInit();
        //this.loadBuildigdata()
       this.onClose();
        
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    });
  }
  getLeftComplaintData(){
    this.apimicroservice.getLeftComplaintAllData().subscribe(res => {
      //
      this.variables3 = res.data;
      this.filteredList1 = this.variables3.slice();
    });
    
  }
  
   // fetching right complaint data
   getRightComplaintData(){
    this.apimicroservice.getRightComplaintAllData().subscribe(res => {
      //
      this.variables4 = res.data;
      this.filteredList2 = this.variables4.slice();
    });
  }
  
  onClose(): void {
    this.dialogRef.close();
  }
  addNewComplaintFunc()
  {
    this.addNewComplaint = true;
  }
  cancelFunc()
  {
    this.addNewComplaint = false;
  }

}