<div class="container-fluid bgcolor2" [formGroup]="followUpForm">
    <form [formGroup]="followUpForm" autocomplete="off" novalidate>
      <div class="row col-sm-12 p-0 m-0">
        <div class="col-sm-12"> 
          <div class="row pl-0 ">
            <div class="col-12 pl-0 ">
              <div class="row ">
              <div class="col-sm-9">
              
                  <h1 class="pt-2 pl-0" id="toFollowupForm">Follow Up</h1>
                  </div> 
                  <div class="col-sm-3 mt-1">
                    <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
            
                      <!-- <a (click)="toFollowupList()" class="d-flex align-items-center ms-3 pr-2 mt-2"><span class="material-symbols-outlined pr-2">reorder</span>List</a>   -->
                      <a (click)="onClose()" class="d-flex align-items-center ms-3 pr-2 mt-2"><span class="material-symbols-outlined pr-2">close</span></a>
                    </div>
            </div>
          </div>
         </div>
         </div>
  
  
          <div class="row ">
            <div class="col-sm-12 pr-4">
              <div class="row">
                <div class="card divbody borderradius mb-2 p-1">
                    <div class="col-sm-12">
                     
                      <div class="row pt-0 mt-0">
                         
                      <div class="col-sm-3">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Follow Up Type </mat-label>
                          <mat-select placeholder="Type"  
                          class="colorblack"  
                          formControlName="followupType" >
                    <mat-option value="In-Person">In-Person</mat-option>
                    <mat-option value="Phone Call">Phone Call</mat-option>
                         </mat-select>   
                        </mat-form-field> 
                        <mat-error 
                        *ngIf="followUpForm.controls['followupType'].touched && followUpForm.controls['followupType'].errors?.required">
                            This field is mandatory.
                      </mat-error>
                      </div>
                     

                      <div *ngIf="followupId !== undefined" class="col-sm-3">
                        <div class="col-sm-10">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Contact No</mat-label>
                            <input matInput placeholder="Number" formControlName="contactNo"
                            [value]="mobile1" readonly>
                          </mat-form-field>
                        </div> 
                        <div class="col-sm-2 wApp mb-2">
                          <i class="bi bi-whatsapp whatsapp-icon wApp fa-lg " (click)="WhatsApp();"
                            matTooltip="WhatsApp Integration"></i>
                        </div>
                      
                      </div>

                      <div class="col-sm-3">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Follow Up </mat-label>
                          <mat-select placeholder="Type"  
                          class="colorblack" 
                          formControlName="followupTime" >
                    <mat-option value="One Week" (click)="onSelectFollowupTime()">One Week</mat-option>
                    <mat-option value="Six Month" (click)="onSelectFollowupTime()">Six Month</mat-option>
                    <mat-option value="One Year" (click)="onSelectFollowupTime()">One Year</mat-option>
                    <mat-option value="Custom" (click)="onSelectFollowupTime()">Custom</mat-option>
                         </mat-select>   
                        </mat-form-field>
                        <mat-error 
                        *ngIf="followUpForm.controls['followupTime'].touched && followUpForm.controls['followupTime'].errors?.required">
                            This field is mandatory.
                      </mat-error>
                      </div>
                      <div class="col-sm-3">
                        <mat-form-field class="example-full-width " appearance="outline">
                           <mat-label>Followup Date</mat-label>
                           <input matInput [matDatepicker]="followupDate" placeholder="Choose a date" 
                           formControlName="followupDate" [disabled]="dateDisable">
             
                            <mat-datepicker-toggle matSuffix [for]="followupDate">
                            </mat-datepicker-toggle>
                            <mat-datepicker #followupDate></mat-datepicker>   
                       </mat-form-field> 
                       </div>
                      <div *ngIf="followupId !== undefined" class="col-sm-3">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Status</mat-label>
                          <mat-select placeholder="Type"  
                          class="colorblack" 
                          formControlName="followupStatus" >
                          <mat-option value="Follow Up Scheduled">Follow Up Scheduled</mat-option>
                    <mat-option value="Follow Up Done">Follow Up Done</mat-option>
                    <mat-option value="Not Answered">Not Answered</mat-option>
                    <mat-option value="Rescheduled Follow Up">Rescheduled Follow Up</mat-option>
                         </mat-select>   
                        </mat-form-field>
                 
                      </div>
                      <div class="col-sm-6">
                           
                        <mat-form-field class="example-full-width "  appearance="outline">
                          <mat-label>Comments
                          </mat-label>
                          <textarea matInput formControlName="followupComments" maxlength="1000"
                          cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                          cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" ></textarea>
                        </mat-form-field>
                      
                    </div> 
                    <div class="row col-sm-12 pl-3 pr-3 mb-2">
                        <div class="col-sm-6"></div>
                        <div class="col-sm-4" *ngIf="submitButton"></div>
                        <div class="col-sm-2 mt-2" *ngIf="submitButton && vendorCreate">
                          <button class="btn mb-2 btn-sm btn-primary example-full-width"
                          (click)="submitFollowup()" type="button" >Save</button>
                        </div>
                        <div class="col-sm-2" *ngIf="updateButton">
                        </div>
                        <div class="col-sm-2 mt-2" *ngIf="updateButton && vendorUpdate">
                          <button type="button" class="btn mb-2 btn-sm btn-primary example-full-width" (click)="updateFollowupDetails()"
                          >Update</button>
                        </div>
                        <div class="col-sm-2 mt-2" *ngIf="updateButton">
                          <button class="btn mb-2 btn-primary btn-sm  example-full-width" (click)="cancelUpdate()"
                          type="reset">Cancel </button>
                        </div>
    
                      </div>
                 
                    </div>
                    </div>
  
                  </div>
  
                  
                   
                     
                   
                   
                </div>
              </div>
            </div>
  
  
        </div>
      </div>
    </form>
  </div>