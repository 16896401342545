<div class="container-fluid bgcolor2" [formGroup]="stockTransferForm">
  <form [formGroup]="stockTransferForm" autocomplete="off" novalidate>
    <div class="row">
      <div class="col-sm-1">
       
      </div> 
      <div class="col-sm-11">
        <div class="row pl-0 ">

          <div class="col-12 pl-0 ">
            <div class="row ">
            <div class="col-sm-9">
            
                <h1 class="pt-2 pl-0" id="toStockTranferForm" >Branch Stock Transfer</h1>
                </div>
                <div class="col-sm-3 mt-1">
                  <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                    <a (click)="onback()" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">arrow_back</span>Back</a>
                    <a (click)="toStockTranferList()" class="d-flex align-items-center ms-3 pr-2 mt-2"><span class="material-symbols-outlined pr-2">reorder</span>List</a>
             </div>
          </div>
        </div>
       </div>
       </div>
       
          <div class="row">
            <div class="col-sm-12 pr-4">
              <div class="row">
                <div class="card divbody mb-3 borderradius p-1">
                  <div class="col-sm-12">
                    <div class="row">
                      <div class="col-sm-3">
                        <div class="row">
                          <div class="col-sm-3 mt-4 pr-1">
                        <!-- <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label> ID</mat-label>
                          <input matInput formControlName="stockTransId" readonly  />
                       
                        </mat-form-field> -->
                        <mat-label class="center" formControlName="stockTransId" ngDefaultControl >{{bId}}</mat-label>
                   </div>
                   <div class="col-sm-6 pr-1">
                        <mat-form-field class="example-full-width lable_style2" appearance="outline" >
                            <mat-label>Date</mat-label>
                            <input matInput [matDatepicker]="dateOfTransfer" formControlName="dateOfTransfer" id="dateOfTransfer"
                            (dateChange)="getTime();" placeholder="DD/MM/YYYY" >
                    <mat-datepicker-toggle matSuffix [for]="dateOfTransfer">
                    </mat-datepicker-toggle>
                    <mat-datepicker #dateOfTransfer></mat-datepicker>
                          </mat-form-field>
                        </div>
                        <div class="col-sm-3 pr-1">
                          <mat-form-field class="example-full-width lable_style2" appearance="outline" >
                              <mat-label>Time</mat-label>
                              <input readonly matInput formControlName="timeValue" [value]="timeValue" />
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="branchCreate" class="col-sm-3 ">
                        <div class="row">
                          <div class="col-sm-10 pr-1">
                        <mat-form-field class="example-full-width lable_style2" appearance="outline" >
                            <mat-label>From Branch</mat-label>
                            <mat-select required formControlName="fromBranchRefId" [disabled]="disableFromBranch">
                              <mat-option
                                  *ngFor="let branch of branchList"
                                  [(value)]="branch._id"
                                  (click)="onSelectFromBranch(branch._id,branch.branchName)"
                                  >{{ branch.branchName }}</mat-option
                                >
                            </mat-select>
                          </mat-form-field>
                          <mat-error class="margin_tp"
                          *ngIf="stockTransferForm.controls['fromBranchRefId'].touched && stockTransferForm.controls['fromBranchRefId'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                          </div>
                          <div class="col-sm-2 mt-2 no-print">
                            <i (click)="openBranchPopUp();" matTooltip="Add New Branch" class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"> </i>
                          </div>
                        
                          </div>
                      </div>
                      <div *ngIf="!branchCreate" class="col-sm-3 ">
                        <div class="row">
                          <div class="col-sm-12 pr-1">
                        <mat-form-field class="example-full-width lable_style2" appearance="outline" >
                            <mat-label>From Branch</mat-label>
                            <mat-select required formControlName="fromBranchRefId" [disabled]="disableFromBranch">
                              <mat-option
                                  *ngFor="let branch of branchList"
                                  [(value)]="branch._id"
                                  (click)="onSelectFromBranch(branch._id,branch.branchName)"
                                  >{{ branch.branchName }}</mat-option
                                >
                            </mat-select>                          
                          </mat-form-field>
                           <mat-error class="margin_tp"
                          *ngIf="stockTransferForm.controls['fromBranchRefId'].touched && stockTransferForm.controls['fromBranchRefId'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                          </div>                         
                          </div>
                      </div>
                      <div *ngIf="branchCreate" class="col-sm-3">
                        <div class="row">
                          <div class="col-sm-10 pr-1">
                        <mat-form-field class="example-full-width lable_style2" appearance="outline" >
                            <mat-label>To Branch</mat-label>
                            <mat-select required formControlName="toBranchRefId" [disabled]="disableToBranch">
                              <mat-option
                                  *ngFor="let branch of toBranchList"
                                  [(value)]="branch._id"
                                  (click)="onSelectToBranch(branch._id,branch.branchName)"
                                  >{{ branch.branchName }}</mat-option
                                >
                            </mat-select>
                          </mat-form-field>
                          <mat-error class="margin_tp"
                          *ngIf="stockTransferForm.controls['toBranchRefId'].touched && stockTransferForm.controls['toBranchRefId'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                      </div>
                      <div class="col-sm-2 mt-2 no-print">
                        <i (click)="openBranchPopUp();" matTooltip="Add New Branch" class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"> </i>
                      </div>
                   
                          </div>
                      </div>
                      <div *ngIf="!branchCreate" class="col-sm-3">
                        <div class="row">
                          <div class="col-sm-12 pr-1">
                        <mat-form-field class="example-full-width lable_style2" appearance="outline" >
                            <mat-label>To Branch</mat-label>
                            <mat-select required formControlName="toBranchRefId" [disabled]="disableToBranch">
                              <mat-option
                                  *ngFor="let branch of toBranchList"
                                  [(value)]="branch._id"
                                  (click)="onSelectToBranch(branch._id,branch.branchName)"
                                  >{{ branch.branchName }}</mat-option
                                >
                            </mat-select>
                          </mat-form-field>
                          <mat-error class="margin_tp"
                        *ngIf="stockTransferForm.controls['toBranchRefId'].touched && stockTransferForm.controls['toBranchRefId'].errors?.required">
                        This field is mandatory.
                      </mat-error>
                          </div>
                        
                          </div>
                      </div>
                      <div class="col-sm-3 ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Hand Over Person Name</mat-label>
                          <input required matInput placeholder="Name" formControlName="handOverPerson"/>
                         
                        </mat-form-field>
                        <mat-error class="margin_tp"
                        *ngIf="stockTransferForm.controls['handOverPerson'].touched && stockTransferForm.controls['handOverPerson'].errors?.required">
                        This field is mandatory.
                      </mat-error>
                      </div>
                      <div class="col-sm-6">
                        <mat-form-field class="example-full-width lable_style2" appearance="outline">
                            <mat-label class="lable_style2">Transfer Note</mat-label>       
                        <textarea matInput formControlName="transferNote" maxlength="1000"
                        cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" ></textarea>
                      </mat-form-field>  
                      </div>
                      <div class="col-sm-3 ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Transfer Status</mat-label>
                          <input readonly matInput [value]="transferStatus" />
                         
                        </mat-form-field>
                      </div>
                      <div class="col-sm-3" *ngIf="transferStatus == 'Reject'">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Reason Of Reject</mat-label>
                          <input readonly matInput [value]="reasonOfReject" />
                         
                        </mat-form-field>
                      </div>
                      <!-- <div class="col-sm-3  ">
                        <mat-form-field class="example-full-width lable_style2" appearance="outline" >
                            <mat-label>Device Name</mat-label>
                            <mat-select formControlName="deviceName" >
                                <mat-option value="Device 1">Device 1</mat-option>
                                <mat-option value="Device 2">Device 2</mat-option>
                                <mat-option value="Device 3">Device 3</mat-option>
                                <mat-option value="Device 4">Device 4</mat-option>
                            </mat-select>
                          </mat-form-field>
                      </div>
                      <div class="col-sm-3  ">
                        <mat-form-field class="example-full-width lable_style2" appearance="outline">
                            <mat-label class="lable_style2">Serial Number</mat-label>
                        <input placeholder="Serial Number" formControlName="serialNumber" 
                        matInput type="text"   />          
                      </mat-form-field>  
                      </div> -->
  
                     
  
                      <!-- <div class="col-sm-3 pl-1 pr-1">
                        <mat-form-field class="example-full-width lable_style2" appearance="outline">
                            <mat-label class="lable_style2">GRN Date</mat-label>
                            <input  matInput [matDatepicker]="Date" formControlName="grnDate"
                         id="Date" placeholder="DD/MM/YYYY">
                       
                        <mat-datepicker-toggle matSuffix [for]="Date">
                        </mat-datepicker-toggle>
                        <mat-datepicker #Date></mat-datepicker>
                    </mat-form-field>
                      </div>
  
  
                      <div class="col-sm-3 pl-1 pr-1">
                        <mat-form-field class="example-full-width lable_style2" appearance="outline">
                            <mat-label class="lable_style2">PO Number</mat-label>
                        <input placeholder="PO" formControlName="poNumber"
                        matInput type="text"   />          
                      </mat-form-field>  
                      </div>
                      <div class="col-sm-3 pl-1 pr-1">
                        <mat-form-field class="example-full-width lable_style2" appearance="outline">
                            <mat-label class="lable_style2">PO Date</mat-label>
                            <input  matInput [matDatepicker]="Date2" formControlName="poDate"
                         id="Date2" placeholder="DD/MM/YYYY">
                       
                        <mat-datepicker-toggle matSuffix [for]="Date2">
                        </mat-datepicker-toggle>
                        <mat-datepicker #Date2></mat-datepicker>
                    </mat-form-field>
                      </div>
                      <div class="col-sm-3 pl-1 pr-1">
                        <mat-form-field class="example-full-width lable_style2" appearance="outline">
                            <mat-label class="lable_style2">Invoice No</mat-label>
                        <input placeholder="Invoice" formControlName="invoiceNo"
                        matInput type="text"   />          
                      </mat-form-field> 
                      </div>
  
                      <div class="col-sm-3 pl-1 pr-1">
                        <mat-form-field class="example-full-width lable_style2" appearance="outline">
                            <mat-label class="lable_style2">Invoice Date</mat-label>
                        <input placeholder="Date" formControlName="invoiceDate"
                        matInput type="text"   />          
                      </mat-form-field>  
                      </div> -->
                      <div class="col-sm-12 mt-2 table-responsive">
                        <table id="toHAFormList" 
                     class="table table-bordered data-table-custom">
                          <thead>
                            <tr class="text-center">
                              <th>Device</th>
                              <th>Serial No.</th>
                              <th>Transfer Qty</th>
                              <th >Action</th>
                             
                            </tr>
                          </thead>
                            <tbody formArrayName="device">
                   
                              <tr [formGroupName]="i"
                              *ngFor="let row of stockTransferForm.get('device')['controls']; let i = index; let last = last"
                                role="row"
                                class="odd "
                              >
                                <td *ngIf="transferStatus == 'In-Progress' || transferStatus == 'Not In Progress'">
                                 
                          
                                        <mat-form-field class="example-full-width lable_style2 widthh" appearance="outline">
                                            <mat-label class="lable_style2">Device Name</mat-label>
                                            
                                              <mat-select required formControlName="deviceRefId" class="colorblack">
                                                <mat-option
                                                    *ngFor="let device of deviceList[i]"
                                                    [value]="device.deviceRefId"
                                                    (click)="onSelectDevice(device.deviceRefId,device.deviceName,
                                                    device.isSerialNo,i)"
                                                    >{{ device.deviceName }}
                                                </mat-option>
                                              </mat-select>
                                      </mat-form-field>
                                      <mat-error class="margin_tp"
                                      *ngIf="row.controls.deviceRefId.touched && row.controls.deviceRefId.errors?.required">
                                         This Field is Mandatory
                                    </mat-error>
                                 
                                </td>
                                <td *ngIf="transferStatus == 'In-Transit' || transferStatus == 'Reject' || transferStatus == 'Accept'">
                                 
                                  <mat-form-field class="example-full-width lable_style2 widthh" appearance="outline">
                                    <mat-label class="lable_style2">Device Name</mat-label>
                                    <input required formControlName="deviceName" matInput
                                      type="text" />
                
                                  </mat-form-field>
                                  
                          </td>
                                
                        <td *ngIf="transferStatus == 'In-Progress' || transferStatus == 'Not In Progress'">  
                          
                                    <mat-form-field class="example-full-width lable_style2 widthh" appearance="outline">
                                        <mat-label class="lable_style2">Serial Number</mat-label>
                                
                                    <mat-select formControlName="serialNumber" multiple>
                                      <mat-option #matOption (click)="onSelectSerialNo(matOption,item.serialNumber,item._id,item.status,i)"
                                      *ngFor="let item of serialNoList[i]" 
                                         [value]="item.serialNumber" 
                                         >{{ item.serialNumber }}
                                      </mat-option>
                                  </mat-select>
                                  </mat-form-field>
                                  <mat-error class="margin_tp"
                                  *ngIf="row.controls.serialNumber.touched && row.controls.serialNumber.errors?.required">
                                     This Field is Mandatory
                                </mat-error>
                </td>
                <td *ngIf="transferStatus == 'In-Transit' || transferStatus == 'Reject' || transferStatus == 'Accept'">
                
                  <mat-form-field class="example-full-width lable_style2 widthh" appearance="outline">
                    <mat-label class="lable_style2">Serial Number</mat-label>
                
                    <mat-select formControlName="serialNumber" multiple>
                      <mat-option 
                        *ngFor="let item of serialNoList[i]" [value]="item">{{ item }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  
                </td>
                <td class="coldisplay">
                  <mat-form-field class="example-full-width lable_style2 widthh" appearance="outline">
                    
                    <input placeholder="Serial Number" formControlName="serialNumberStatus" matInput
                      type="text" />

                  </mat-form-field>
                 
                </td>
                <td> 
                                
                  <mat-form-field class="example-full-width lable_style2  widthh" appearance="outline">
                      <mat-label class="lable_style2">Transfer Qty</mat-label>
                  <input placeholder="Qty" formControlName="qty" 
                  matInput type="text" readonly  />
                
                </mat-form-field>
                <mat-error class="margin_tp"
                *ngIf="row.controls.qty.touched && row.controls.qty.errors?.required">
                   This Field is Mandatory
              </mat-error>
          </td>

            <td class="text-center ">  
         
                <button mat-button mat-icon-button class=" ml-3 mr-2" *ngIf="last" (click)="addNew(i);" [disabled]="stockTransferForm.controls.device.invalid || stockTransferForm.disabled">
                  <img matTooltip="Add" class="mt-1 " width="25" src="../../assets/add.png"> 
                </button>
              
                  <button mat-button mat-icon-button (click)="deleteRow(i);" [disabled]="stockTransferForm.get('device')['controls']['length']===1 || stockTransferForm.disabled">
                    <img matTooltip="Remove" class="mt-1" width="25" src="../../assets/dustbin.png"> 
                  </button>

             

                <!-- <button mat-button mat-icon-button *ngIf="last" (click)="addNew(i);" matTooltip="Add" [disabled]="stockTransferForm.controls.device.invalid || stockTransferForm.disabled">
                  <mat-icon class="mt-2 iconcol">add_circle_outline</mat-icon>
                </button>
                <button mat-button mat-icon-button (click)="deleteRow(i);" matTooltip="Delete" [disabled]="stockTransferForm.get('device')['controls']['length']===1 || stockTransferForm.disabled">
                  <mat-icon class="mt-2 iconcol">remove_circle_outline</mat-icon>
                </button> -->
            </td>
          
                              </tr>
                            </tbody>
                          </table>
                          <hr>
                    
                              </div>
                             
                              <div class="col-sm-8 mt-2 mb-2" *ngIf="submitButton"> </div>
                              <div class="col-sm-4 mt-2 mb-2" *ngIf="deliveryButton" ></div>
                              <div class="col-sm-8 mt-2 mb-2" *ngIf="!deliveryButton" ></div>
                              <div class="col-sm-6 mt-2 mb-2" *ngIf="updateButton"> </div>
                              <!-- <div class="col-sm-8 mt-2 mb-2" *ngIf="dispatchListButton || hideDispatchListButton"> </div> -->
                              <div class="col-sm-2 mt-2 mb-2" *ngIf="dispatchListButton">
                                <button class="btn btn-primary btn-sm  example-full-width" type="button"  (click)="dispatchList()">Show Dispatch List</button>
                              </div>
                              <div class="col-sm-2 mt-2 mb-2" *ngIf="hideDispatchListButton">
                                <button class="btn btn-primary btn-sm  example-full-width" type="button"  (click)="hideDispatchList()">Hide Dispatch List</button>
                              </div>
                             
                            <!-- <div class="col-sm-4" *ngIf="deliveryButton">
                            </div> -->
                            <!-- <div class="col-sm-6" *ngIf="updateButton">
                            </div> -->
                            <div class="col-sm-4" *ngIf="acceptButton"></div>
                        
                           
                              
                           
                              <div class="col-sm-2 mt-2 mb-2" *ngIf="submitButton" >
                                <button *ngIf="stockTransferCreate" type="button" class="btn btn-primary btn-sm example-full-width" (click)="submitStockTransfer()">Save</button>
                               
                              </div>
                              <div class="col-sm-2 mt-2 mb-2" *ngIf="deliveryButton" >
                               
                                <button  class="btn btn-primary btn-sm example-full-width" type="button" (click)="sendToDelivery()">Dispatch</button>
                              </div>
                              <div class="col-sm-2 mt-2 mb-2" *ngIf="proceedupdateButton" >
                               
                                <button  *ngIf="stockTransferUpdate" class="btn btn-primary btn-sm example-full-width" type="button" (click)="proceedToUpdateStockTransfer()">Proceed To Update</button>
                              </div>
                              <div class="col-sm-2 mt-2 mb-2"*ngIf="updateButton" >
                               
                                <button  *ngIf="stockTransferUpdate" class="btn btn-primary btn-sm example-full-width" type="button" (click)="updateStockTransfer()">Update</button>
                              </div>
                              <div class="col-sm-2 mt-2 mb-2" *ngIf="cancelButton">
                                <button class="btn btn-primary btn-sm  example-full-width" type="reset"  (click)="cancelUpdate()">Cancel</button>
                            </div>
                            <div class="col-sm-2 mt-2 mb-2" *ngIf="acceptButton">
                              <button class="btn btn-primary btn-sm  example-full-width" type="button" (click)="acceptStock()">Accept</button>
                          </div>
                          <div class="col-sm-2 mt-2 mb-2" *ngIf="rejectButton"> 
                            <button class="btn btn-primary btn-sm  example-full-width"  type="button" (click)="rejectStock()">Reject</button>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 pl-0 pr-2" *ngIf="showList">
            <div class="row">
                <div class="card divbody borderradius mb-2 p-3 pb-0">
                      <div class="col-sm-12">
                          <div class="row">
                              <div class="col-sm-9 pl-1 pr-1">
                                  <h1 class="titlecolor"> Branch Dispatched Stock Transfer List</h1>  
                              </div>
                              <div class="search-box col-sm-3 pb-2">
                                <input class="form-control amber-border" type="text" placeholder="Search" 
                                formControlName="searchDispatchStockTransfer" aria-label="Search" (keyup)="onKeyUpDispatchEvent($event)"
                                >
                                <div class="input-group-append">
                                   <span class="input-group-text amber lighten-3" id="basic-text1"><span class="material-symbols-outlined">search</span></span>
                                </div>
                             </div>
                             
                              <div class="col-sm-12">
                                <div class="table-responsive">
                                <table class="table table-hover table-bordered" id="toStockTranferList">
                                  <thead>
                                    <tr class="text-center">
                                      <th>From Branch </th>
                                      <th>To Branch</th>
                                      <th>Ref. Id</th>
                                      <th>Transfer Status</th>
                                      
                                      <!-- <th>Action</th> -->
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="
                                    let item of listOfDispatchStocks
                                    | paginate : { id:'dispatch_pagination', 
                                    itemsPerPage: disize, currentPage: dipage, totalItems:ditotalLength }
                                  " (click)="prepopulation(item._id)"
                                  (click)="toStockTranferForm()" style="cursor:pointer; text-align: center ">
                                      <td>{{item.fromBranchName}}</td>
                                      <td>{{item.toBranchName}}</td>
                                      <td>{{item.stockTransId}}</td>
                                      <td>{{item.transferStatus}}</td>
                                     
                                      <!-- <td class="text-center">
                                        <i matTooltip="View" (click)="prepopulation(item._id)" class="fas fa-eye"></i>
                                       </td> -->
                                    
                                    </tr>
                                 
                                  </tbody>
                                </table>
                                </div>
                              </div>
                              <div class="col-sm-12 pt-0 text-right">
                                <pagination-controls id="dispatch_pagination" directionLinks="true"
                                  (pageChange)="onPageDispatchChange($event)" class="float-right pt-3 my-pagination"></pagination-controls>
                             
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              
          </div>
          <div class="col-sm-12 pl-0 pr-2">
            <div class="row">
                <div class="card divbody borderradius mb-2 p-3 pb-0">
                      <div class="col-sm-12">
                          <div class="row">
                              <div class="col-sm-9 pl-1 pr-1">
                                  <h1 class="titlecolor"> Branch Stock Transfer List</h1>  
                              </div>
                              <div class="search-box col-sm-3 pb-2">
                                <input class="form-control amber-border" type="text" placeholder="Search" 
                                formControlName="searchStockTransfer" aria-label="Search" (keyup)="onKeyUpEvent($event)"
                                >
                                <div class="input-group-append">
                                   <span class="input-group-text amber lighten-3" id="basic-text1"><span class="material-symbols-outlined">search</span></span>
                                </div>
                             </div>
                            
                              <div class="col-sm-12">
                                <div class="table-responsive">
                                <table class="table table-hover table-bordered" id="toStockTranferList">
                                  <thead>
                                    <tr class="text-center">
                                      <th>Transfer Id</th>
                                      <th>From Branch </th>
                                      <th>To Branch</th>
                                      <th>Date Of Transfer</th>
                                      <th>No. Of Items</th>
                                      <th>Transfer Status</th>
                                      <!-- <th>Action</th> -->
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="
                                    let item of listOfStocks
                                    | paginate : { id:'transfer_pagination', 
                                    itemsPerPage: size, currentPage: page, totalItems:totalLength }
                                  " (click)="prepopulation(item._id)"
                                  (click)="toStockTranferForm()" style="cursor:pointer; text-align: center ">
                                    <td class="width2">{{item.stockTransId}}</td>    
                                  <td>{{item.fromBranchName}}</td>
                                      <td>{{item.toBranchName}}</td>
                                      <td>{{item.dateOfTransfer | date}}</td>
                                      <td class="justify-content-center width3">
                                      <tr class="justify-content-center" *ngFor="let prod of item.device">
                                        <label class="justify-content-center ml-4">{{prod.qty}}</label>
                                      </tr>
                                      </td>
                                     <td>{{item.transferStatus}}</td>
                                      <!-- <td class="text-center">
                                        <i matTooltip="View" (click)="prepopulation(item._id)" class="fas fa-eye"></i>
                                       </td> -->
                                    
                                    </tr>
                                 
                                  </tbody>
                                </table>
                                </div>
                              </div>
                              <div class="col-sm-12 pt-0 text-right">
                                <pagination-controls id="transfer_pagination" directionLinks="true"
                                  (pageChange)="onPageChange($event)" class="float-right pt-3 my-pagination"></pagination-controls>
                             
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              
          </div>

        
          

     
      </div>
    </div>
    </form>
  </div>