import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-ha-form-rightserial-popup',
  templateUrl: './ha-form-rightserial-popup.component.html',
  styleUrls: ['./ha-form-rightserial-popup.component.scss']
})
export class HaFormRightserialPopupComponent implements OnInit {
  popupHARightSerialForm: FormGroup;
  // itemId: any;
   serialNoList =[];
   disableSubmit = false;
  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<HaFormRightserialPopupComponent>,@Inject(MAT_DIALOG_DATA) public data:any) {
      this.serialNoList = data.serialValue;
      dialogRef.disableClose = true;
     }

  ngOnInit(): void {
    this.disableSubmit = false;
    
    //
    this.popupHARightSerialForm = this.fb.group({  
      "device": this.fb.array([]),
    });
    
    if(this.serialNoList == null){
      this.addNew();
    }else{
      this.prepopulation();
      
    }
  }

  addNew() {
    const creds = this.popupHARightSerialForm.controls.device as FormArray;
        creds.push(
        this.fb.group({
          "rightSerialNumber": new FormControl('',[Validators.required,Validators.pattern("^[a-zA-Z0-9 -]+$")]),   
        //toggle: false

})
);
  }

  prepopulation(){
    this.serialNoList.forEach(element => {    
      const tmpDict = {};

      tmpDict['rightSerialNumber'] = new FormControl(element,Validators.required);
      
      const creds = this.popupHARightSerialForm.controls.device as FormArray;
       creds.push(new FormGroup(tmpDict));
       //
      });
  }

  sumbitSerialNo(){
    const creds = this.popupHARightSerialForm.controls.device as FormArray;  
    if(creds.invalid)
    {
      creds.controls.forEach(element =>{
        element.get('rightSerialNumber').markAsTouched();
      });
         
    }
    else{
     this.sumbitNumbers();
    }
  }

  sumbitNumbers(){
   const tempData = this.popupHARightSerialForm.controls.device.value;
   ////
   this.dialogRef.close({data:tempData});
  //  if(tempData.length == 1){
  //    if(tempData.rightSerialNumber == ""){
  //     this.dialogRef.close();
  //    }else{
  //     this.dialogRef.close({data:tempData});
  //    }
  //  }else{
  //   this.dialogRef.close({data:tempData});
  //  }
   
  }
  deleteRow(index){
    (<FormArray>this.popupHARightSerialForm.get("device")).removeAt(index);
  }
  findDuplicate(srno,p):boolean{
    const creds = this.popupHARightSerialForm.controls.device as FormArray;
    let myArr = creds.controls;
    let test = myArr.filter(data => data['controls']['rightSerialNumber'].value == srno && srno !== null)

    if(test.length > 1){
      this.disableSubmit = true;
      return true;
    }else{
      this.disableSubmit = false;
      return false;
    }

  }
  onClose(): void {
    this.dialogRef.close();
  }

}
