<div class="dialog-wrapper ">
    <div class="row capitalize">
      <div class="col-sm-5"></div>
      <div class="col-sm-1">
        <!-- <h5 class="header">Letter :</h5> -->
        <h2 mat-dialog-title class="title">Letter</h2>
          <!-- <h1 class="titlecolor" >  Search Patients</h1>   -->
      </div>
     
      <div class="col-sm-5"></div>
      <div class="col-sm-1 text-right pb-2">
        <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
          <a (click)="onClose()" matTooltip="Close" class="d-flex align-items-center pr-2"><span class="material-symbols-outlined pr-2">close</span></a>
          </div>
      </div>
      <div class="row col-sm-12" id = 'testTable'>
        <div class="col-sm-8 text-left">
          <h3> Sub: Order Confirmation Mail</h3>
            </div>
            <div class="col-sm-4 text-right">
              <label> Date: {{todaysDate | date:'dd/MM/yyyy'}}</label>
                </div>
            <!-- <div class="col-sm-6 text-left"></div> -->
            <div class="col-sm-12 text-left" >
              <label>
Dear <label class="capitalize">{{cName}}</label>, <br>
 
Please arrange to send the following<br>


</label>

</div>
<div class="col-sm-1"></div>
<div class="col-sm-10 table-responsive">
  <table class="table table-bordered data-table-custom">
       <thead> 
         <tr class="text-center">
          <th>Device Name</th>
          <th>Qty</th>
          <th>FOC Units</th> 
          <th>Total</th>
        
         </tr>
       </thead>
       <tbody>
         <tr *ngFor="let item of HAModelDetailsData"
           class="justify-content-center"
           style="cursor:pointer; text-align: center ">
           <td matTooltip="click to view">{{item.deviceName}}</td>
           <td matTooltip="click to view">{{item.qty}}</td>
           <td matTooltip="click to view">{{item.focUnits}}</td>
           <td matTooltip="click to view">{{item.totalNo}}</td>
         </tr>


       </tbody>
     </table>
     <br>
      <table class="table table-bordered data-table-custom">
          <thead> 
            <tr class="text-center">
              <th>Receivers</th>
              <th>Size</th>
              <th>Power</th>
              <th>Qty</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of HAModelReceiverData"
              class="justify-content-center"
              style="cursor:pointer; text-align: center ">
              <td matTooltip="click to view">{{item.receivers}}</td>
              <td matTooltip="click to view">{{item.size}}</td>
              <td matTooltip="click to view">{{item.power}}</td>
              <td matTooltip="click to view">{{item.receiversQty}}</td>
            </tr>
   
   
          </tbody>
        </table>
     <br>
     <table class="table table-bordered data-table-custom">
          <thead> 
            <tr class="text-center">
              <th>Accessories</th>
               <th>Type</th>
              <th>Qty</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of HAModelAccessoriesData"
              class="justify-content-center"
              style="cursor:pointer; text-align: center ">
              <td matTooltip="click to view">{{item.accessories}}</td>
              <td matTooltip="click to view">{{item.accessoriesType}}</td>
              <td matTooltip="click to view">{{item.accessoriesQty}}</td>
            </tr>
   
   
          </tbody>
        </table>
      </div> 
      <div class="col-sm-1"></div>
          <div class="col-sm-12">
            <label><b>HA Color :</b>{{haColor}},</label>
          </div>
          <div class="col-sm-12">
            <label><b>Notes :</b>{{notes}}</label>
          </div>
          <div class="col-sm-12 mt-3">
            <label>*This need to be billed on {{clinicName}}</label>
          </div> 
          <div class="col-sm-12">  
            <label>  Regards,</label>
          </div>
          <div class="col-sm-12">
            <label>Clinical Audiologist,</label>
          </div>
          <div class="col-sm-12">
            <label><b>{{clinicName}}</b></label>
          </div>
          <div class="col-sm-12">
            <label>CAN :<b> {{canNumber}}</b></label>
          </div>
          <div class="col-sm-12">
            <label>Mobile :{{clinicPhone}}</label>
          </div>
       
</div>
     <div class="col-sm-10"></div>
     <div class="col-sm-2">
      <!-- <button type="button" class="btn btn-primary no-print btn-sm example-full-width" 
      [cdkCopyToClipboard]="copyValue" (click)="copied()">Copy</button> -->
      <button id = "button" class="btn btn-primary no-print btn-sm example-full-width" 
       (click)="copied()">Copy</button>
     </div>
    </div>
    </div>
     