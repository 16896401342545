import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {AfterViewInit, Directive, ViewChild} from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { UsersMasterComponent } from '../users-master/users-master.component';
import { PermissionsComponent } from '../permissions/permissions.component';


@Component({
  selector: 'app-user-tab',
  templateUrl: './user-tab.component.html',
  styleUrls: ['./user-tab.component.scss']
})
export class UserTabComponent implements OnInit {
  selectedIndex: number = 0;
  disable : Boolean = true;
  userPermission: any;
  userType: any;
  permissionList =[];
  CustomerSettingView = true;
  UserSettingView= true;
  UserPermissionsView= true;
  constructor(public router: Router,
    private route: ActivatedRoute,
    private change: ChangeDetectorRef) { }
    @ViewChild(UsersMasterComponent, {static: false}) private UserMaster: UsersMasterComponent;
    @ViewChild(PermissionsComponent, {static: false}) private Permissions: PermissionsComponent;
  ngOnInit(): void {
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermissions"));
    this.userType = sessionStorage.getItem("type");
     
    // if(this.userType == 'User')
    // {
    //   this.userPermission.forEach((element) => {
    //     this.permissionList.push(element.pageName);
    //     if (element.pageName == "Customer Setting" && element.view == false) {
    //       this.CustomerSettingView = false;
    //     } 
    //       if (element.pageName == "User-Setting" && element.view == false) {
    //       this.UserSettingView = false;
    //     } 
    //     if (element.pageName == "User-Permissions" && element.view == false) {
    //       this.UserPermissionsView = false;
    //     } 
    //   });
    // }
    this.disable = true;
  }
  onTabChanged(event: MatTabChangeEvent) 
  {
      if(event.tab.textLabel == 'User Master')
      {
        this.UserMaster.ngOnInit();//Or whatever name the method is called
        this.disable = true;
      }
      else if(event.tab.textLabel == 'Permission')
      {
        this.Permissions.ngOnInit();//Or whatever name the method is called
      }
      
  }
  
 

public tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    
  this.selectedIndex = tabChangeEvent.index;
}

public next() {
  
   
  this.disable = false;
  this.selectedIndex += 1;
  this.Permissions.ngOnInit();
}

public prev() {
  this.selectedIndex -= 1;
 // this.UserMaster.ngOnInit();
  this.disable = true;
}

public enable(){
  this.disable = false;
 // this.Permissions.ngOnInit();
}


ngOnChanges(changes: UserTabComponent) {
}
redirect(to): void {
  this.router.navigate([to]);
}  

}
