import { Component, OnInit, Inject } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
 import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { AfterViewInit, Directive, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner"; 
import { PopUpBranchComponent } from "../pop-up-branch/pop-up-branch.component";
import { PopUpCanNumberComponent } from "../pop-up-can-number/pop-up-can-number.component";
import { MakePopupComponent } from "../make-popup/make-popup.component";
@Component({
  selector: 'app-popup-company',
  templateUrl: './popup-company.component.html',
  styleUrls: ['./popup-company.component.scss']
})
export class PopupCompanyComponent implements OnInit {

  errormessage: string;
  page: number = 1;
  size: number = 5;
  doctorList: any;
  totalLength: any;
  searchText: any;
  did: any;

  allState=[
    {name:'Andaman and Nicobar Islands',value:'AN' },
    {name:'Andhra Pradesh',value:'AP'},
    { name:'Arunachal Pradesh',value:'AR' },
{name:'Assam',value:'AS' },
{ name:'Bihar',value:'BR'},
 { name:'Chandigarh',value:'CH' },
{name:'Chhattisgarh',value:'CT' },
{ name:'Dadra and Nagar Haveli', value:'DN' },
 { name:'Daman and Diu', value:'DD'},
 { name:'Delhi',value:'DL'},
 {name:'Goa',value:'GA' },
{name:'Gujarat', value:'GJ'}, 
{name:'Haryana',value:'HR'}, 
{name:'Himachal Pradesh',value:'HP'}, 
{ name:'Jammu and Kashmir',value:'JK'}, 
 {name:'Jharkhand',value:'JH'}, 
{ name:'Karnataka', value:'KA'},
{ name:'Kerala',value:'KL'},
{name:'Ladakh',value:'LA'},
 {name:'Lakshadweep',value:'LD' },
 {name:'Madhya Pradesh', value:'MP'},
{ name:'Maharashtra',value:'MH'},
 { name:'Manipur',value:'MN'},
{ name:'Meghalaya', value:'ML' },
{ name:'Mizoram',value:'MZ' },
{ name:'Nagaland',value:'NL' },
 { name:'Odisha',value:'OR'},
 { name:'Puducherry', value:'PY'},
 {name:'Punjab', value:'PB' },
{ name:'Rajasthan', value:'RJ'},           
 {name:'Punjab',value:'PB'},   
 {name:'Rajasthan', value:'RJ'},
{name:'Sikkim',value:'SK' },
{ name:'Tamil Nadu',value:'TN'}, 
 {name:'Telangana',value:'TG'},   
  { name:'Tripura',  value:'TR' },
 { name:'Uttar Pradesh', value:'UP'},
{name:'Uttarakhand',value:'UT'},  
{ name:'West Bengal', value:'WB' },                        
  ]
  
 
    
  BranchList: any;
  branchName: string;
  filteredListBranch: any;
  filteredListCanNumber: any;
  canNumberList: any;
  companyList: any;
  canNo: any;
  makeList: any;
  filteredMakeList: any;
  bId: any;
 
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
     public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<PopupCompanyComponent>,@Inject(MAT_DIALOG_DATA) public data:any
  ) {dialogRef.disableClose = true;}

  companyMasterForm: FormGroup;
  status: any;
  submitButton = true;
  updateButton = false;
  deleteButton = false;
  cancelButton= false;
  loggedId: any;
  doctorImage:any;
  date: any;
  altAddressCheck = false;
  isChecked= false;
  branchRef: string;
  clinicRef: string;
  
  userType: string;
  userPermission:any;
  companyCreate= true;
  companyUpdate= true;
  isSuperAdmin= true;
  superBranchId: any;
  currentdate:any;
  ngOnInit() {
    this.page=1;
    this.currentdate = new Date();
    //this.filteredList = this.allState.slice();
    
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.userType = sessionStorage.getItem('userType');

    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    if(this.userType !== 'Super Admin'){
      this.isSuperAdmin= false;
    this.userPermission.forEach((element) => {
      if(element.pageName == 'Company Master' && element.create == false)
      {
          this.companyCreate = false;  
      }
      if(element.pageName == 'Company Master' && element.update == false)
      {
          this.companyUpdate = false;  
      }    
    });
  }

  
    this.companyMasterForm = this.fb.group({
      "branchWiseId": new FormControl(''),
      "companyName": new FormControl('',[Validators.required,Validators.pattern("^[a-zA-Z &-\\s]*$")]),
      "companyAddress": new FormControl('',Validators.required),
      "companyContact": new FormControl('', [Validators.pattern("(0|91)?[6-9][0-9]{9}")]),
      "landline":new FormControl('',Validators.pattern("[0-9]{3,4}[-][0-9]{6,8}")),
      "pincode":new FormControl('',[Validators.required, Validators.pattern("^[0-9]*$"),Validators.maxLength(6),Validators.minLength(6)]),
      "companyEmail": new FormControl('', [Validators.required,Validators.pattern("[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}")]),
     // "canRefId": new FormControl('',[Validators.required]),
      "canNumber": new FormControl('',Validators.required),
      "createdAt": new Date(),
      "searchCompany": new FormControl(''),
      // "branchRefId": new FormControl(),
      // "clinicRefId": new FormControl(),
      // "branchName": new FormControl(),
     
    });
   // this.fetchCompanyList();
    this.getLastId();
    //this.fetchCanNumberList();
    this.fetchMakeList();
    //this.doctorImage ="../../assets/main.jpeg";
    //
   // this.companyMasterForm.controls["doctorStatus"].setValue("Active");
    
    //this.setValidator();
    // this.fetchBranchList();
    // this.getAllBranch();
  }
  // getAllBranch()
  // {
  //   this.apimicroservice.getAllBranchList().subscribe(res => {
  //     if (res.status) {        
  //       this.companyMasterForm.get("branchRefId").setValue(res.data[0]._id);
  //       this.branchClick(res.data[0]._id,this.branchName);
  //       if(this.userType == 'Super Admin')
  //       {  
  //         this.apimicroservice.getBranchById(res.data[0]._id).subscribe(res => {
  //           this.spinner.hide();
  //           this.companyMasterForm.get("branchName").setValue(res.data[0].branchName);
  //          });
  //       }
       
  //     }
  //   });
  // }
  removeValidator() { 
    this.companyMasterForm.controls['companyName'].setErrors(null);
    this.companyMasterForm.controls['companyName'].clearValidators();
    this.companyMasterForm.controls['companyName'].updateValueAndValidity();

    // this.companyMasterForm.controls['companyContact'].setErrors(null);
    // this.companyMasterForm.controls['companyContact'].clearValidators();
    // this.companyMasterForm.controls['companyContact'].updateValueAndValidity();

    this.companyMasterForm.controls['canNumber'].setErrors(null);
    this.companyMasterForm.controls['canNumber'].clearValidators();
    this.companyMasterForm.controls['canNumber'].updateValueAndValidity();

    this.companyMasterForm.controls['companyAddress'].setErrors(null);
    this.companyMasterForm.controls['companyAddress'].clearValidators();
    this.companyMasterForm.controls['companyAddress'].updateValueAndValidity();

    this.companyMasterForm.controls['pincode'].setErrors(null);
    this.companyMasterForm.controls['pincode'].clearValidators();
    this.companyMasterForm.controls['pincode'].updateValueAndValidity();

  }
 
  setValidator() { 
    this.companyMasterForm.controls['companyName'].setValidators([Validators.required,Validators.pattern("^[a-zA-Z &-\\s]*$")]);
    this.companyMasterForm.controls['companyName'].updateValueAndValidity();

    // this.companyMasterForm.controls['companyContact'].setValidators([Validators.required, Validators.pattern("(0|91)?[6-9][0-9]{9}")]);
    // this.companyMasterForm.controls['companyContact'].updateValueAndValidity();

    this.companyMasterForm.controls['canNumber'].setValidators([Validators.required]);
    this.companyMasterForm.controls['canNumber'].updateValueAndValidity();

    this.companyMasterForm.controls['companyAddress'].setValidators([Validators.required]);
    this.companyMasterForm.controls['companyAddress'].updateValueAndValidity();

    this.companyMasterForm.controls['pincode'].setValidators([Validators.required]);
    this.companyMasterForm.controls['pincode'].updateValueAndValidity();

  }

  submitCompany() {
   // this.setValidator();
    // if(this.userType == 'Super Admin')
    // {
    //   this.companyMasterForm.controls['branchRefId'].setValidators(Validators.required);
    //   this.companyMasterForm.controls['branchRefId'].updateValueAndValidity();
    // }
    // else{
    //   this.companyMasterForm.controls['branchRefId'].setErrors(null);
    //   this.companyMasterForm.controls['branchRefId'].clearValidators();
    //   this.companyMasterForm.controls['branchRefId'].updateValueAndValidity();
    // }
    if (this.companyMasterForm.invalid) {
      this.companyMasterForm.get("companyName").markAsTouched();
      // this.companyMasterForm.get("companyContact").markAsTouched();
      this.companyMasterForm.get("canNumber").markAsTouched();
      this.companyMasterForm.get("companyEmail").markAsTouched();
      this.companyMasterForm.get("companyAddress").markAsTouched();
      this.companyMasterForm.get("pincode").markAsTouched();
      return;
    } else {
      this.addCompany();
    }
  }
  update() {
  
    // if (this.companyMasterForm.invalid) {
    //   this.companyMasterForm.get("companyName").markAsTouched();
    //   this.companyMasterForm.get("companyContact").markAsTouched();
    //   this.companyMasterForm.get("canRefId").markAsTouched();
    //   this.companyMasterForm.get("companyEmail").markAsTouched();
    //   this.companyMasterForm.get("companyAddress").markAsTouched();
    //   this.companyMasterForm.get("pincode").markAsTouched();
    //   return;
    // } else {
    //   this.updateCompany();
    // }
  }
  
  addCompany() {
    this.spinner.show();
    let payload = this.companyMasterForm.value;
    // payload.canNumber = this.canNo;
      payload.branchRefId = this.branchRef;
      payload.clinicRefId = this.clinicRef;
      payload.branchName = this.branchName;

    this.apimicroservice.saveCompanyData(payload).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.apimicroservice.openSnackbar(res.message);
        
        this.ngOnInit();
        this.onClose();
      //  this.removeValidator();
        
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    }); 
  }

  // fetchCompanyList() {
  //   let pg = 1;
  //   let size = 5;
  //   let search='';
   
  //   this.spinner.show();
  //   this.searchText = this.companyMasterForm.get("searchCompany").value;
     
  //   this.apimicroservice.getAllCompanies(pg,size,search).subscribe(res => {
  //     this.spinner.hide();
  //     if (res.status) {
  //       //
  //       this.companyList = res.data.data;
  //       this.totalLength = res.data.totalItems;
  //       this.apimicroservice.openSnackbar(res.message);
  //     } else {
  //       //this.apimicroservice.openSnackbar(res.message);
  //     }
  //   }); 
  
  
  // }

  // prepopulation(id) {
  //   this.spinner.show();
  //   this.apimicroservice.getCompanyById(id).subscribe(res => {
  //     this.spinner.hide();
  //     if (res.status) {
  //       let data=res.data[0];
  //       this.companyMasterForm = this.fb.group({
  //     "branchWiseId": new FormControl(data.branchWiseId),
  //     "companyName": new FormControl(data.companyName,[Validators.required,Validators.pattern("^[a-zA-Z ]*$")]),
  //     "companyAddress": new FormControl(data.companyAddress,Validators.required),
  //     "companyContact": new FormControl(data.companyContact, [Validators.required,Validators.pattern("(0|91)?[6-9][0-9]{9}")]),
  //     "landline":new FormControl(data.landline),
  //     "pincode":new FormControl(data.pincode,[Validators.required, Validators.pattern("^[0-9]*$"),Validators.maxLength(6),Validators.minLength(6)]),
  //     "companyEmail": new FormControl(data.companyEmail, [Validators.required,Validators.pattern("[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}")]),
  //     "canRefId": new FormControl(data.canRefId,[Validators.required]),
  //     "canNumber": new FormControl(data.canNumber),
  //     "searchCompany": new FormControl(''), 
  //    "updatedAt": new Date(),
  //   //  "branchRefId": new FormControl(data.branchRefId),
  //   //   "clinicRefId": new FormControl(data.clinicRefId),
  //   //   "branchName": new FormControl(data.branchName),
      
  //       });
  //      // this.superBranchId = data.branchWiseId;
       
  //       //this.doctorImage = data.photo ? data.photo : "../../assets/main.jpeg"
  //       this.did = data._id;
  //       this.updateButton = true;
  //       this.submitButton = false;
  //       this.cancelButton=true;
  //     } else {
  //       this.apimicroservice.openSnackbar(res.message);
  //     }
  //   }); 
  // }
  
  // addNew() {
  //   this.companyMasterForm.reset();
  //   this.updateButton = false;
  //   this.deleteButton = false;
  // }



  getLastId()
  {
    
      this.apimicroservice.getLastCompanyID().subscribe(res => {
        //   
        // if(res['data']['data'] == '')
        // {
        //   this.companyMasterForm.controls['branchWiseId'].setValue('1');
        // }
        if (res['status']) {
          let companyIdd = res['data']['totalItems'];
          this.bId=companyIdd + 1;
          this.companyMasterForm.controls['branchWiseId'].setValue(companyIdd + 1);
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
       
      })
    
    
  }


  // updateCompany() {
  //   this.spinner.show();
  //   let payload = this.companyMasterForm.value;
  //   payload.canNumber = this.canNo;
  //   // if(this.userType !== 'Super Admin')
  //   // {
  //   //   payload.branchRefId = this.branchRef;
  //   //   payload.clinicRefId = this.clinicRef;
  //   //   payload.branchName = this.branchName;
  //   // }
  //   this.apimicroservice.editCompanyData(this.did,payload).subscribe(res => {
  //     this.spinner.hide();
  //     this.updateButton=false;
  //     this.cancelButton=false;
  //     this.submitButton=true;
  //     if (res.status) {
  //       this.apimicroservice.openSnackbar(res.message);
       
  //       this.ngOnInit();
  //      // this.removeValidator();
        
  //     } else {
  //       this.apimicroservice.openSnackbar(res.message);
  //     }
  //   }); 
  // }

  // onPageChange(event) {
  //   this.spinner.show();
  //   this.page = event;
  //  // let p = this.page - 1;
  //   this.searchText = this.companyMasterForm.get("searchCompany").value;
  
  //     this.apimicroservice.getAllCompanies(this.page, this.size, this.searchText).subscribe(res => {
  //       this.spinner.hide();
  //       if (res.status) {
  //         this.companyList = res.data.data;
  //         this.totalLength = res.data.totalItems;
  //       } else {
  //         this.apimicroservice.openSnackbar(res.message);
  //       }
  //       });
      
      
  // }
  // onKeyUpEvent(event: any) {
  //   //
    
  //   this.searchText = this.companyMasterForm.get("searchCompany").value; 
    
  //     this.apimicroservice.getAllCompanies(this.page, this.size, this.searchText).subscribe((res) => {
  //         if(res.status){
  //           this.companyList = res.data.data;
  //         this.totalLength = res.data.totalItems;
  //         }else{
  //           this.apimicroservice.openSnackbar(res.message);
  //         }
  //       });
      
      
  // }

  // cancelUpdate(){
  //   //this.doctorImage ="../../assets/main.jpeg";
  //   this.submitButton=true;
  //   this.updateButton=false;
  //   this.cancelButton=false;
  //   this.ngOnInit();
  //  // this.removeValidator();
  //   //this.companyMasterForm.reset();
  // }
 
  redirect(to): void {
    this.router.navigate([to]);
  }
  changemob1() {
    let payload = this.companyMasterForm.value;
    if (payload.mobile1 != '') {
      if (
        payload.mobile1 == payload.mobile2 ||
        payload.mobile1 == payload.mobile3 ||
        payload.mobile1 == payload.mobile4 ||
        payload.mobile1 == payload.mobile5
      ) {
        this.apimicroservice.openSnackbar("Mobile number 1 must be different");
        this.companyMasterForm.controls["mobile1"].setValue('');
      }
    }
  }
  changemob2() {
    let payload = this.companyMasterForm.value;
    if (payload.mobile2 != '') {
      if (
        payload.mobile2 == payload.mobile1 ||
        payload.mobile2 == payload.mobile3 ||
        payload.mobile2 == payload.mobile4 ||
        payload.mobile2 == payload.mobile5
      ) {
        this.apimicroservice.openSnackbar("Mobile number 2 must be different");
        this.companyMasterForm.controls["mobile2"].setValue('');
      }
    }
  }
  
  
  onChangeAltAddress(e){
    if(e.checked == true){
     this.altAddressCheck = true;
     this.isChecked = true;
     
    }
    else
    {
      this.altAddressCheck = false;
      this.isChecked = false;
    }
  }
 
 
  // fetchBranchList() {
  //   this.apimicroservice.getAllBranchList().subscribe(res => {
  //     //  
  //     if (res.status) {    
  //       this.BranchList = res.data;
  //       this.filteredListBranch= this.BranchList.slice();
  //     } else {
  //       this.apimicroservice.openSnackbar(res.message);
  //     }
  //   })
  // } 
  // branchClick(id,branchName)
  // {

  //   this.companyMasterForm.controls["branchName"].setValue(branchName);

  //  this.apimicroservice.getBranchById(id).subscribe(res => { 
  //    //
       
  //    let data = res.data[0];
  //    let payload = this.companyMasterForm.value;
  //     payload.clinicRefId = res.data[0].clinicRefId;
  //     this.companyMasterForm.controls["clinicRefId"].setValue(res.data[0].clinicRefId);
  //     if(this.updateButton == false){
  //       this.spinner.show();
  //       this.apimicroservice.getLastDoctorID(payload.branchRefId).subscribe(res => {
  //         //   
         
  //         if (res['status']) {
  //           let companyIdd = res['data']['totalItems'];
  //           this.companyMasterForm.controls['branchWiseId'].setValue(companyIdd + 1);
  //         } else {
  //           this.apimicroservice.openSnackbar("Something went wrong");
  //         }
  //         this.spinner.hide();
  //       })
  //     }
  //   });
  // }
  fetchCanNumberList(){
    this.searchText = '';
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if(this.userType !== 'Super Admin')
    {
      this.apimicroservice.getCanAllData(branchRef,clinicRef).subscribe(res => {
        //
        
        if (res.status) {    
          this.canNumberList = res.data;
          this.filteredListCanNumber = this.canNumberList.slice();
        } else {
         // this.apimicroservice.openSnackbar(res.message);
        }
      })
    }
    else
    {
      this.apimicroservice.getCanAllData(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
        //
        
        if (res.status) {    
          this.canNumberList = res.data;
          this.filteredListCanNumber = this.canNumberList.slice();
        } else {
         // this.apimicroservice.openSnackbar(res.message);
        }
      })
    }
    
  }
  WhatsApp()
  {
    let payload = this.companyMasterForm.value;  
    let num = payload.companyContact; 
    if(num.length == 10)
    {
      sessionStorage.setItem('whatsAppNumber',payload.companyContact);
      let mobileNumber = sessionStorage.getItem('whatsAppNumber');
      window.open("https://wa.me/91"+mobileNumber);
    }
    else
    {
      this.companyMasterForm.get("companyContact").markAsTouched();
    }
  }
  onSelectCan(cno){
   this.canNo = cno;
   //
  }
   openBranchPopUp() {
    
    const dialogRef3 = this.dialog.open(PopUpBranchComponent,
      {
        width: "1200px",
      });
      
  }

  openCanNoPopUp() {
    
    const dialogRef3 = this.dialog.open(PopUpCanNumberComponent,
      {
        width: "500px",
      });
      dialogRef3.afterClosed().subscribe((result) => {
        this.fetchCanNumberList();  
       });
  }

  onClose(): void {
    this.dialogRef.close();
  }

  openMakePopUp() {
    localStorage.clear();
    const dialogRef6 = this.dialog.open(MakePopupComponent,
      {
        width: "400px",
      });
      dialogRef6.afterClosed().subscribe((result) =>{
        this.apimicroservice.getAllMakeList().subscribe(res => {
          //  
          if (res.status) {    
            this.makeList = res.data;
            this.filteredMakeList= this.makeList.slice();
            this.companyMasterForm.get("make").setValue(res.data[0].make);
          } else {
            //this.apimicroservice.openSnackbar(res.message);
          }
        })
       // this.fetchConditionTypeList();
      })
  }

  
  fetchMakeList() {
    this.apimicroservice.getAllMakeList().subscribe(res => {
      //  
      if (res.status) {    
        this.makeList = res.data;
        this.filteredMakeList= this.makeList.slice();
        //this.deviceMasterForm.get("condition").setValue(res.data[0].condition)
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    })
  } 
}
