import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { AfterViewInit, Directive, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { PopUpBranchComponent } from "../pop-up-branch/pop-up-branch.component";
import { PopUpCityComponent } from "../pop-up-city/pop-up-city.component";
import { PopUpItemComponent } from "../pop-up-item/pop-up-item.component";
import { PopUpCourierserviceComponent } from "../pop-up-courierservice/pop-up-courierservice.component";
import { PopupTotalBillComponent } from "../popup-total-bill/popup-total-bill.component";
import { PatientSearchPopComponent } from "../patient-search-pop/patient-search-pop.component";
import { PopUpPatientComponent } from "../pop-up-patient/pop-up-patient.component";
import { PopupBillComponent } from "../popup-bill/popup-bill.component";
import { PopupAddDeviceComponent } from "../popup-add-device/popup-add-device.component";

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {
  BillingForm: FormGroup;
  date: any;
  listOfAppointment: any;
  totalLength: any;
  appointmentId: any;
  size = 10;
  page = 1;
  searchText: any;
  submitButton = true;
  updateButton = false;
  allColor: any;

  filteredList: { name: string; value: string; }[];
  filteredListAppoinmentType: { name: string; value: string; }[];
  userType: string;
  branchRef: string;
  clinicRef: string;
  BranchList: any;
  isSuperAdmin = true;
  superBranchId: any;
  branchName: string;
  filteredListBranch: any;
  currentdate: Date;
  listOfBilling: any;
  id: any;
  courierList: any;
  filteredListCourier: any;
  disableFromCity = false;
  disableToCity = true;
  tocityList: any;
  cityList: any;
  fromCity: any;
  toCity: any;
  courierServiceCreate = true;
  branchCreate = true;
  PatientCreate = true;
  billingList: any;
  maxDateStr: string;
  isSearch = true;
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService
  ) { }
  userPermission: any;
  billingCreate = true;
  billingUpdate = true;
  printData = [
    {
      srNo: 1,
      date: '10/03/2020',
      aptId: 101,
      patientName: 'Shivani Bhosale',
      mobile: 9088776653,
      status: 'Paid'
    },
    {
      srNo: 2,
      date: '10/03/2020',
      aptId: 102,
      patientName: 'Sai Mane',
      mobile: 9276321653,
      status: 'Unpaid'
    },
    {
      srNo: 3,
      date: '10/03/2020',
      aptId: 103,
      patientName: 'Rahul Thakur',
      mobile: 8932776653,
      status: 'Unpaid'
    },
    {
      srNo: 4,
      date: '10/03/2020',
      aptId: 104,
      patientName: 'Aishwarya Shaha',
      mobile: 8877776653,
      status: 'Paid'
    },
  ]
  ngOnInit(): void {
    this.isSearch = false;
    this.currentdate = new Date();
    let mm = this.currentdate.getMonth() + 1;
    this.maxDateStr = this.currentdate.getDate() + '/' + mm + '/' + this.currentdate.getFullYear();
    this.page = 1;
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.userType = sessionStorage.getItem('userType');
    this.userType = sessionStorage.getItem('userType');
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    if (this.userType !== 'Super Admin') {
      this.isSuperAdmin = false;
      this.userPermission.forEach((element) => {
        if (element.pageName == 'Billing' && element.create == false) {
          this.billingCreate = false;
        }
        if (element.pageName == 'Billing' && element.update == false) {
          this.billingUpdate = false;
        }
        if (element.pageName == 'Courier Service Provider' && element.create == false) {
          this.courierServiceCreate = false;
        }
        if (element.pageName == 'Branch' && element.create == false) {
          this.branchCreate = false;
        }
        if (element.pageName == "Patient" && element.create == false) {
          this.PatientCreate = false;
        }
      });
    }
    this.disableFromCity = false;
    this.disableToCity = true;
    this.BillingForm = this.fb.group({
      "patientId": new FormControl(''),
      "patientName": new FormControl(''),
      "billNo": new FormControl(''),
      "status": new FormControl(''),
      "fdate": new FormControl(''),
      "todate": new FormControl(''),
      "docketNo": new FormControl('', [Validators.pattern("^[0-9]*$")]),
      "searchBilling": new FormControl(''),

    });

    // this.removeValidator();
    this.fetchBilling();
    let today = new Date();
    this.BillingForm.controls['fdate'].setValue(today);
    this.BillingForm.controls['todate'].setValue(today);
  }

  searchBillingData() {
    this.isSearch = true;
    let branchRef= sessionStorage.getItem('branchRef');
    if (this.userType !== "Super Admin") {
      branchRef = sessionStorage.getItem('branchRef');
    } else {
      branchRef = sessionStorage.getItem('branchRef');
    }
    this.searchText = this.BillingForm.get('searchBilling').value;
    if (this.searchText == null) {
      this.searchText = '';
    }
    let patientId = this.BillingForm.get('patientId').value;
    if (patientId == null) {
      patientId = '';
    }
    let patientName = this.BillingForm.get('patientName').value;
    if (patientName == null) {
      patientName = '';
    }
    let billNo = this.BillingForm.get('billNo').value;
    if (billNo == null) {
      billNo = '';
    }
    let status = this.BillingForm.get('status').value;
    if (status == null) {
      status = '';
    }
    let fromDate = this.BillingForm.get('fdate').value;
    if (fromDate == null) {
      fromDate = '';
    }
    let toDate = this.BillingForm.get('todate').value;
    if (toDate == null) {
      toDate = '';
    }

    this.apimicroservice.getAllBillingDetails(this.page, this.size, this.searchText, patientId, patientName, billNo, status, fromDate, toDate, branchRef).subscribe(res => {
      //  
      if (res.status) {
        this.billingList = res.data.data;
        this.totalLength = res.data.totalItems;

      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    });
  }
  redirectToBillForm(billNo,isMigrated,isDraft) {
    this.router.navigate(['bill-form', billNo]);
    // if (isDraft == 'true') {
    //   this.editBill(billNo);
    // }
    // else if (isMigrated !== 'true') {
    //   this.router.navigate(['bill-form', billNo]);
    // }
  }
  editBill(billNo) {
    const dialogRef = this.dialog.open(PopupAddDeviceComponent,
      {
        data: {
          patientRefId: '',
          appointId: '',
          patientDetails: '',
          page: "Billing Page",
          isDraft: 'true',
          billNo: billNo,
          
        }
      }

    );
    dialogRef.afterClosed().subscribe((result) => {
      this.fetchBilling();
    })
  }
  fetchBilling() {
    // let pg = 1;
    // let size = 10;
    let search = '';
    let patientId = '';
    let patientName = '';
    let billNo = '';
    let status = '';
    let fromDate = '';
    let toDate = '';
    let branchRef= sessionStorage.getItem('branchRef');
    if (this.userType !== "Super Admin") {
      branchRef = sessionStorage.getItem('branchRef');
    } else {
      branchRef = sessionStorage.getItem('branchRef');
    }
    this.apimicroservice.getAllBillingDetails(this.page, this.size, search, patientId, patientName, billNo, status, fromDate, toDate, branchRef).subscribe(res => {
      //  
      if (res.status) {
        this.billingList = res.data.data;
        this.totalLength = res.data.totalItems;

      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    });


  }
  prepopulation(id) {
    this.redirect('bill-form');
  }
  redirect(to): void {
    this.router.navigate([to]);
  }
  cancelUpdate() {

    this.submitButton = true;
    this.updateButton = false;

    this.ngOnInit();
    this.BillingForm.reset();
  }

  openSnackbar(message: string) {
    return this._snackBar.open(message, "close", {
      verticalPosition: "bottom",
      horizontalPosition: "right",
      duration: 2000,
      panelClass: ["snackbarStyle"],
    });
  }
  toBillingForm() {
    document.getElementById("toBillingForm").scrollIntoView();
  }
  toBillingLst() {
    document.getElementById("toBillingLst").scrollIntoView();
  }

  onPageChange(event) {
    
    let fromDate;
    let toDate;
    if (this.isSearch == true) {
      fromDate = this.BillingForm.get('fdate').value;
      toDate = this.BillingForm.get('todate').value;
    }
    else {
      fromDate = '';
      toDate = '';
    }
    this.page = event;
    this.searchText = this.BillingForm.get('searchBilling').value;
    let patientId = this.BillingForm.get('patientId').value;
    let patientName = this.BillingForm.get('patientName').value;
    let billNo = this.BillingForm.get('billNo').value;
    let status = this.BillingForm.get('status').value;

    let branchRef= sessionStorage.getItem('branchRef');
    if (this.userType !== "Super Admin") {
      branchRef = sessionStorage.getItem('branchRef');
    } else {
      branchRef = sessionStorage.getItem('branchRef');
    }
    this.apimicroservice.getAllBillingDetails(this.page, this.size, this.searchText, patientId, patientName, billNo, status, fromDate, toDate, branchRef).subscribe(res => {
      //  
      if (res.status) {
        this.billingList = res.data.data;
        this.totalLength = res.data.totalItems;

      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    });
  }
  onKeyUpEvent(event: any) {

    this.searchText = this.BillingForm.get('searchBilling').value;
    let patientId = this.BillingForm.get('patientId').value;
    let patientName = this.BillingForm.get('patientName').value;
    let billNo = this.BillingForm.get('billNo').value;
    let status = this.BillingForm.get('status').value;
    let fromDate = '';
    let toDate = '';
    let pg = 1;
    let size = 10;
    // if (this.isSearch == true) {
    //   fromDate = this.BillingForm.get('fdate').value;
    //   toDate = this.BillingForm.get('todate').value;
    // }
    // else {
    //   fromDate = '';
    //   toDate = '';
    // }
    
    //
    //
    let branchRef= sessionStorage.getItem('branchRef');
    if (this.userType !== "Super Admin") {
      branchRef = sessionStorage.getItem('branchRef');
    } else {
      branchRef = sessionStorage.getItem('branchRef');
    }
    this.apimicroservice.getAllBillingDetails(pg,size, this.searchText, patientId, patientName, billNo, status, fromDate, toDate, branchRef).subscribe(res => {
      //  
      if (res.status) {
        this.billingList = res.data.data;
        this.totalLength = res.data.totalItems;

      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    });
  }

  toOpenDateChnage() {

    let payload = this.BillingForm.value;
    // const date1 = new Date(dd.toDateString()+" "+a);
    // const date2 = new Date(dd.toDateString()+" "+b);

    if (payload.fdate >= payload.todate && payload.todate !== '') {
      this.apimicroservice.openSnackbar("From Date should be Less than To Date");
      this.BillingForm.controls['fdate'].setValue('');
    }
  }
  toCloseDateChnage() {

    let payload = this.BillingForm.value;
    // const date1 = new Date(dd.toDateString()+" "+a);
    // const date2 = new Date(dd.toDateString()+" "+b);

    if (payload.todate <= payload.fdate && payload.fdate !== '') {
      this.apimicroservice.openSnackbar("To Date should be Greater than From Date");
      this.BillingForm.controls['todate'].setValue('');
    }
  }
  billing() {
    const dialogRef = this.dialog.open(PopupTotalBillComponent,
      {
        data: {
          patientRefId: '',
          appointId: '',
          patientDetails: '',
          page: "Billing Page"
        }
      }

    );
    dialogRef.afterClosed().subscribe((result) => {
      this.fetchBilling();
    })
  }
  billingNew() {
    const dialogRef2 = this.dialog.open(PopupBillComponent,
      {
        data: {
          patientRefId: '',
          appointId: '',
          patientDetails: '',
          page: "Billing Page"
        },
        width: "600px",
      });
    dialogRef2.afterClosed().subscribe((result) => {
      this.fetchBilling();
    })
  }

  onback() {
    this.apimicroservice.back()
  }
  openDepartmentPopUp() {
    const dialogRef3 = this.dialog.open(PatientSearchPopComponent, {
      width: "97%",
      height: "90%",
    });

    dialogRef3.afterClosed().subscribe((result) => {
      this.BillingForm.get("patientName").setValue(result.data.firstName + ' ' + result.data.lastName);
    });
  }
  openPatientPopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpPatientComponent, {
      width: "1200px",
    });
    dialogRef3.afterClosed().subscribe((result) => {
      let clinicRef= sessionStorage.getItem('clinicRef');
      let branchRef= sessionStorage.getItem('branchRef');
      let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
      let branchRefSuperAdmin= '';
      if (this.userType !== 'Super Admin') {
      this.apimicroservice.getPatientListAll(branchRef,clinicRef).subscribe((res) => {
        if (res.status) {
          // this.patientlist = res.data.data;
          // this.filteredPatientList = this.patientlist.slice();

          this.BillingForm.get("patientName").setValue(res.data.data[0].firstName + ' ' + res.data.data[0].lastName);

        }
      });
    }else{
      this.apimicroservice.getPatientListAll(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe((res) => {
        if (res.status) {
          // this.patientlist = res.data.data;
          // this.filteredPatientList = this.patientlist.slice();

          this.BillingForm.get("patientName").setValue(res.data.data[0].firstName + ' ' + res.data.data[0].lastName);

        }
      });
    }
    });
  }

}

