<div class="wrapper bgcolor2">
    <div class="main-panel">
            <div class="content">
         <div class="margin_top">
        
            <div class="col-sm-12">
                <div class="row">
                    <div class="col-sm-1">
                        </div>
                        <div class="col-sm-11">
           <mat-tab-group (selectedTabChange)="onTabChanged($event)" class="mat-tab-labels" [selectedIndex]="selectedIndex">
            <!-- <mat-tab *ngIf="CustomerSettingView" label='Customer Setting'> 
              <app-customer-setting-page-v1></app-customer-setting-page-v1>
            </mat-tab> -->
           
             <mat-tab label="User Master">
              <app-users-master (next)="next()" (enable)="enable()"></app-users-master>
            </mat-tab>
            
             <mat-tab *ngIf="disable" [disabled]="disable" matTooltip="Please select user">
              <ng-template *ngIf="disable" mat-tab-label>
                <label class="mt-2" matTooltip="Please select user">
                  Permission
                </label>
              </ng-template>
              
                <app-permissions (prev)="prev()"></app-permissions>
              </mat-tab>
              <mat-tab label="Permission" *ngIf="!disable" [disabled]="disable">
                  <app-permissions (prev)="prev()"></app-permissions>
              </mat-tab>
            </mat-tab-group>
             </div>
             </div>
         </div>
        </div>
       </div> 
        </div>
   </div>