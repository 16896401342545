<div class="container-fluid " [formGroup]="appointmentMaster">
    
    <!-- <mat-dialog-content> -->
        <!-- <div class="container"> -->
            <form [formGroup]="appointmentMaster" autocomplete="off" novalidate>
                <div class="row mtt">
                  
                                <div class="col-sm-12">
                                    <div class="row">
                                        <div class="col-sm-11">
                                          <div class="row ">
                              
                                            <span><img src="../../assets/medical-appointment.png" width="30rem"> </span> 
                                            <span><h1 class="fass ml-3 pt-1">Book Appointment</h1></span>
                                          </div>
                                          
                                        </div>
                              
                                        <div class="col-sm-1 ">
                                            <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                                              <a (click)="onClose()" matTooltip="Close" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">close</span></a>
                                              </div>
                                          </div>
                                     
                                      </div>
                                      <!-- <div class="col-sm-12 card pb-3 pt-3 heightttt"> -->
                                        <div class="col-sm-12 pb-3 pt-3 heightttt">
                                        <div class="row">
                                            <div class="col-sm-10 pr-1">
                                                <mat-form-field class="example-full-width" appearance="outline">
                                                    <mat-label>Patient Name</mat-label>

                                                    <mat-select #select2 (click)="fetchPatient();"
                                                        formControlName="clientName">
                                                        <mat-select-filter *ngIf="select2.focused"
                                                            [placeholder]="'Filter'" [displayMember]="'firstName'"
                                                            [array]="patientlist"
                                                            (filteredReturn)="filteredPatientList =$event">
                                                        </mat-select-filter>
                                                        <mat-option *ngFor="let row of filteredPatientList"
                                                            (click)="selectPatient(row._id)" [value]="row.firstName">
                                                            {{row.firstName}}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <div *ngIf="PatientCreate" class="col-sm-2 mt-2 mTop no-print">
                                                    <i (click)="openPatientPopUp();" matTooltip="Add New Patient" 
                                                    class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-10 pr-1">
                                                <mat-form-field class="example-full-width" appearance="outline">
                                                    <mat-label>Contact Number</mat-label>
                                                    <input matInput readonly placeholder="Contact Number"
                                                        formControlName="contactNumber" required />
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-10 pr-1">
                                                <mat-form-field class="example-full-width" appearance="outline">
                                                    <mat-label>Appoinment Type</mat-label>
                                                    <mat-select #select1 formControlName="AppoinmentType">
                                                        <mat-select-filter *ngIf="select1.focused"
                                                            [placeholder]="'Filter'" [displayMember]="'appointmentType'"
                                                            [array]="appointtypelist"
                                                            (filteredReturn)="filteredAppointTypeList =$event">
                                                        </mat-select-filter>
                                                        <mat-option *ngFor="let row of filteredAppointTypeList"
                                                            [value]="row.appointmentType" (click)="dddd(row)">
                                                            {{row.appointmentType}}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <div *ngIf="AppointmentTypeCreate" class="col-sm-2 mt-2 mTop no-print ">
                                                    <i (click)="openAppointmentTypePopUp();" matTooltip="Add New Appointment Type" 
                                                    class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-10 pr-1">
                                                <mat-form-field class="col-sm-12 pl-0 pr-0" appearance="outline">
                                                    <mat-label>Appointment Date</mat-label>
                                                    <input matInput [min]="currentdate" [matDatepicker]="startDateTime"
                                                        placeholder="Choose a date" formControlName="startDateTime"
                                                        readonly (dateChange)="toDateChange()">

                                                    <mat-datepicker-toggle matSuffix [for]="startDateTime">
                                                    </mat-datepicker-toggle>
                                                    <mat-datepicker #startDateTime></mat-datepicker>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-10 pr-1">
                                                <mat-form-field class="example-full-width" appearance="outline">
                                                    <mat-label>Clinic Branch</mat-label>
                                                    <mat-select #select3 formControlName="clinicLocation">
                                                        <mat-select-filter *ngIf="select3.focused"
                                                            [placeholder]="'Filter'" [displayMember]="'branchName'"
                                                            [array]="cliniclist"
                                                            (filteredReturn)="filteredClinicList =$event">
                                                        </mat-select-filter>
                                                        <mat-option *ngFor="let row of filteredClinicList"
                                                            (click)="selectBranch(row._id)" [value]="row.branchName">
                                                            {{row.branchName}}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <!-- <div class="col-sm-2 mTop">
                                                <img (click)="openBranchPopUp();" matTooltip="Add New Branch" width="25" src="../../assets/add_img.png"> 
                                              </div> -->
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-10 pr-1">
                                                <mat-form-field class="example-full-width" appearance="outline">
                                                    <mat-label>Audiologists Name</mat-label>
                                                    <!-- <mat-select formControlName="doctorName">
                                                  <mat-option  *ngFor="let row of doctorlist" [value]="row.firstName">{{row.firstName}}</mat-option>
                                                </mat-select> -->
                                                    <mat-select #select4 (click)="fetchDoctorList()"
                                                        formControlName="doctorName">
                                                        <mat-select-filter *ngIf="select4.focused"
                                                            [placeholder]="'Filter'" [displayMember]="'firstName'"
                                                            [array]="doctorList"
                                                            (filteredReturn)="filteredDoctorList =$event">
                                                        </mat-select-filter>
                                                        <mat-option *ngFor="let row of filteredDoctorList"
                                                            (click)="selectDoctor(row._id)"
                                                            [value]="row.firstName + ' ' + row.lastName">{{
                                                            row.firstName }} {{ row.lastName }}</mat-option>
                                                        <!-- <mat-option *ngFor="let item of doctorList"  (click)="selectDoctor(item._id)" [value]="item.firstName">
                                                   {{ item.firstName }} {{ item.lastName }}</mat-option> -->
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <div *ngIf="DoctorCreate" class="col-sm-2 mt-2 mTop no-print ">
                                                    <i (click)="openDoctorPopUp();" matTooltip="Add New Doctor" 
                                                    class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                                            </div>
                                        </div>
 
                                        <div class="row m-0 pt-0 pb-3 pr-0 pl-0 og height2 mb-3">
                                            <!-- <span *ngFor="let item of timeLabels"> -->
                                            <div *ngIf="timeLabels.length == 0 && searcheddoctor">
                                                <h2 class="pl-3"> Audiologists Un-available</h2>
                                            </div>
                                            <div class="" *ngFor="let item of timeLabels">
                                                <button *ngIf="item.status=='unbooked' && item.isDisabled == 'Yes'"
                                                    (click)="onSelectTime(item.slot)" id="btnslot"
                                                    class="col-sm-12 bordercolor p-2 m-1 not-allowed text-center pointer btn btn-default example-full-width"
                                                    disabled>{{item.slot}}</button>
                                                <button *ngIf="item.status=='unbooked' && item.isDisabled == 'No'"
                                                    (click)="onSelectTime(item.slot)" id="btnslot"
                                                    class="col-sm-12 bordercolor p-2 m-1 text-center pointer btn btn-default example-full-width">{{item.slot}}</button>
                                                <!-- <button *ngIf="item.status=='unbooked' && item.slot > myFormattedDate" (click)="onSelectTime(item.slot)" 
                                                  id="btnslot" class="col-sm-12 bordercolor p-2 m-1 text-center pointer btn btn-default example-full-width"
                                                  >{{item.slot}}</button> -->
                                                <button *ngIf="item.status=='booked'" width="100" disabled
                                                    class="col-sm-12 bookbordercolor p-2 m-1 text-center not-allowed  btn btn-default example-full-width">{{item.slot}}</button>
                                            </div>

                                            <!-- </span> -->

                                        </div>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <button [disabled]="isSave" *ngIf="savebutton && bookAppointmentsCreate"
                                                    class="btn btn-primary example-full-width" type="button" (click)="submit()"> Save
                                                </button>
                                            </div>
                                            <!-- <div class="col-sm-12">
                                                <button *ngIf="updatebutton && bookAppointmentsUpdate"
                                                    class="btn btn-primary example-full-width mb-3" (click)="update()">
                                                    Update </button>
                                            </div> -->
                                        </div>
                                        <!-- <div class="row pl-0 pr-0">
                                            <div class="col-sm-6 widthh ">
                                                <button *ngIf="updatebutton" class="btn widthh btn-primary "
                                                    (click)="functiondelete()"> Cancel Appoinment</button>
                                            </div>
                                            <div class="col-sm-6 widthh">
                                                <button *ngIf="updatebutton" class="btn widthh btn-primary"
                                                    (click)="reset()"> Clear </button>
                                            </div>
                                        </div> -->
                                    </div>
                              
                    </div>
                </div>
            </form>
        <!-- </div> -->
    <!-- </mat-dialog-content> -->
</div>