import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators, 
  FormBuilder,
} from "@angular/forms";
 import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { AfterViewInit, Directive, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { PopUpBranchComponent } from "../pop-up-branch/pop-up-branch.component";
import { PopUpCityComponent } from "../pop-up-city/pop-up-city.component";
import { PopUpItemComponent } from "../pop-up-item/pop-up-item.component";
import { PopUpCourierserviceComponent } from "../pop-up-courierservice/pop-up-courierservice.component";
import { PopupTotalBillComponent } from "../popup-total-bill/popup-total-bill.component";
import { PatientSearchPopComponent } from "../patient-search-pop/patient-search-pop.component";
import { PopUpPatientComponent } from "../pop-up-patient/pop-up-patient.component";
import { PopupModeOfPaymentComponent } from "../popup-mode-of-payment/popup-mode-of-payment.component";
import { PopupBillingcompanyComponent } from "../popup-billingcompany/popup-billingcompany.component";
@Component({
  selector: 'app-advance-amount',
  templateUrl: './advance-amount.component.html',
  styleUrls: ['./advance-amount.component.scss']
})
export class AdvanceAmountComponent implements OnInit {
  advanceAmountForm: FormGroup;
  date: any;
  totalLength:any;
  size=10;
  page=1;
  searchText: any;
  submitButton = true;
  updateButton = false;

  filteredList: { name: string; value: string; }[];
  filteredListAppoinmentType: { name: string; value: string; }[];
  userType: string;
  branchRef: string;
  clinicRef: string;
  BranchList: any;
  isSuperAdmin= true;
  superBranchId: any;
  branchName: string;
  filteredListBranch: any;
  currentdate: Date;
  
  courierServiceCreate = true;
  branchCreate = true;
  advanceList: any;
  patientCreate = true;
  patientRefId: any;
  patientList: any;
  filteredListPatient: any;
  prepoId: any;
  modeval: string;
  chequeDetails=[];
  readonlyAmt = false;
  billingCompanyList: any;
  filteredListBillingCompany: any;
  billingCreate= true;
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
     public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService 
  ) { }
  userPermission:any;
  advanceAmountCreate= true;
  advanceAmountUpdate= true;
  readonly = false;
  ngOnInit(): void {
    this.readonly = false;
    this.readonlyAmt = false;
    this.currentdate = new Date();
    this.page=1;
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.userType = sessionStorage.getItem('userType');
    this.userType = sessionStorage.getItem('userType');
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    if(this.userType !== 'Super Admin'){
      this.isSuperAdmin= false;
    this.userPermission.forEach((element) => {
      if(element.pageName == 'Advance Amount' && element.create == false)
      {
          this.advanceAmountCreate = false;  
      }
      if(element.pageName == 'Advance Amount' && element.update == false)
      {
          this.advanceAmountUpdate = false;  
      }  
      if(element.pageName == 'Patient' && element.update == false)
      {
          this.patientCreate = false;  
      }    
      if(element.pageName == 'Billing Company' && element.create == false)
      {
          this.billingCreate = false;  
      }
    });
  }
    this.advanceAmountForm = this.fb.group({
      "billNo": new FormControl(''),
      "patientRefId": new FormControl(''),
      "patientName": new FormControl('',Validators.required), 
      "patientId": new FormControl(''), 
      "receiptNo": new FormControl(''),
      "mobile": new FormControl(''), 
      "branchRefId": new FormControl('',Validators.required),
      "branchName": new FormControl(''),
      "paidAmount": new FormControl('',[Validators.required,Validators.pattern("^[0-9 .]*$")]), 
      "paymentDate": new Date(),
      "modeOfPayment": new FormControl('',Validators.required),
      "totalAmount": new FormControl('',Validators.pattern("^[0-9 .]*$")),
      "totalDiscount": new FormControl(''),
      "totalsgst": new FormControl(''),
      "totalcgst": new FormControl(''),
      "totaligst": new FormControl(''),
      "balanceAmount": new FormControl(''),
      "pendingChequeAmount": new FormControl(''),
      "bounceAmount": new FormControl(''),
      "paymentStatus": new FormControl(''),
      "chequePaymentStatus": new FormControl(''),
      "gst": new FormControl(''),
      "transactionNo": new FormControl(''),
      "rtgsNft": new FormControl(''),
      "bankName": new FormControl(''),
      "rtgsAmount": new FormControl(''),
      "description": new FormControl(''),
      "advanceStatus": new FormControl(''),
      "billingCompanyName": new FormControl(''),
      "billingCompanyRefId" :new FormControl('',Validators.required),
       "chequeDetails":this.fb.array([]),
      "createdAt":new Date(),
      "searchBilling": new FormControl(''),
      
      
    });
  
 // this.removeValidator();
  this.fetchAdvAmtData();
  this.getAllBranch();
  this.fetchBillingCompanyList();
}
submitData() {
  let payload = this.advanceAmountForm.value;
 if (this.advanceAmountForm.invalid) {
  this.advanceAmountForm.get("billingCompanyRefId").markAsTouched();
    this.advanceAmountForm.get("patientName").markAsTouched();
    this.advanceAmountForm.get("branchRefId").markAsTouched();
    this.advanceAmountForm.get("paidAmount").markAsTouched();
    this.advanceAmountForm.get("totalAmount").markAsTouched();
    this.advanceAmountForm.get("modeOfPayment").markAsTouched();
    return;
  } else {
    
    // //
    this.addData();
  }
}
  addData(){ 
    this.spinner.show();
    let payload = this.advanceAmountForm.value; 
    if(this.userType !== 'Super Admin')
    {
      payload.branchRefId = this.branchRef;
      payload.branchName = this.branchName;
    } 
    let receiptNo = Math.floor(10000 + Math.random() * 90000);
    payload.receiptNo = receiptNo;
    payload.advanceStatus = 'Yes';
    payload.totalAmount = parseInt(payload.totalAmount);
    payload.balanceAmount = payload.totalAmount - parseInt(payload.paidAmount);
    if(payload.modeOfPayment == 'Cheque')
    {
       
      let bar = new Promise<void>((resolve,reject) =>{ 
        this.spinner.hide(); 
        payload['pendingChequeAmount'] = parseInt(payload.paidAmount);
        this.chequeDetails.forEach(ele =>{
          receiptNo = Math.floor(10000 + Math.random() * 90000);
          payload['chequeDetails'] = ele;
          payload['chequePaymentStatus'] = "postdated";
          payload['receiptNo'] = receiptNo;
          payload['advanceStatus'] = 'Yes';  
          payload['paidAmount'] = 0;
          payload['balanceAmount'] = payload.totalAmount - parseInt(ele.amount);
    
          this.apimicroservice.addPaymentHistoryDetails(payload).subscribe(res => {
            if(res.status){
              this.apimicroservice.openSnackbar("cheque payment in-progress");
              resolve();
            } 
          });
          
        }); 
        
       });
        bar.then(() => {
          this.ngOnInit();
           //window.location.reload();
        });
    }
    else
    {
      this.apimicroservice.addPaymentHistoryDetails(payload).subscribe(res => {
        this.spinner.hide(); 
        this.ngOnInit();
        if(res.status){
          this.apimicroservice.openSnackbar(res.message);
      
        }else{
          this.apimicroservice.openSnackbar(res.message);
        }
      },(ERROR:HttpErrorResponse) => {
        this.apimicroservice.openSnackbar(ERROR.error.message);
       //
       this.spinner.hide();
    });
    }
  
  }

  redirectToBillForm(billNo){
    this.router.navigate(['bill-form',billNo]);
  }
 
  fetchAdvAmtData() {
    let payload = this.advanceAmountForm.value;
    let search='';
    let branchRef= sessionStorage.getItem('branchRef');
    if(this.userType !== "Super Admin"){
       branchRef= sessionStorage.getItem('branchRef');
    }else{
       branchRef= sessionStorage.getItem('branchRef');
    }
    let billNo = '';
    let advanceStatus = 'Yes';
    this.apimicroservice.getAllPaymentHistoryByAdvance(this.page,this.size,branchRef,search,billNo,advanceStatus).subscribe(res => {
      // this.spinner.hide(); 
      //
       if(res.status){
        this.advanceList = res.data.data;
        this.totalLength=res.data.totalItems;
        this.apimicroservice.openSnackbar(res.message);        
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
    
   
  }
  prepopulation(id) { 
    this.redirect('bill-form');
  }

  calculateAmt(event:any){
    let totalamt = event.target.value;
    let advanceamt = this.advanceAmountForm.controls['paidAmount'].value;
    if((advanceamt !== '') && (parseInt(totalamt) < parseInt(advanceamt))){
      this.apimicroservice.openSnackbar("Please enter total amount greater than or equal to advance amount!");
      this.advanceAmountForm.controls['totalAmount'].setValue('');
    }
  }
  
  prepopulation2(id) {
    this.spinner.show(); 
    this.apimicroservice.getPaymentHistoryByReceiptNo(id).subscribe(res => {
      this.spinner.hide();
     let data = res.data[0];
     //
     this.submitButton = false;
     this.updateButton = true;
      if(res.status){
        this.advanceAmountForm = this.fb.group({
          "billNo": new FormControl(data.billNo),
      "patientRefId": new FormControl(data.patientRefId),
      "patientName": new FormControl(data.patientName,Validators.required), 
      "patientId": new FormControl(data.patientId), 
      "receiptNo": new FormControl(data.receiptNo),
      "mobile": new FormControl(data.mobile), 
      "branchRefId": new FormControl(data.branchRefId,Validators.required),
      "branchName": new FormControl(data.branchName),
      "paidAmount": new FormControl(data.paidAmount,[Validators.required,Validators.pattern("^[0-9 .]*$")]), 
      "paymentDate": new FormControl(data.paymentDate),
      "modeOfPayment": new FormControl(data.modeOfPayment,Validators.required),
      "totalAmount": new FormControl(data.totalAmount),
      "totalDiscount": new FormControl(data.totalDiscount),
      "totalsgst": new FormControl(data.totalsgst),
      "totalcgst": new FormControl(data.totalcgst),
      "totaligst": new FormControl(data.totaligst),
      "balanceAmount": new FormControl(data.balanceAmount),
      "pendingChequeAmount": new FormControl(data.pendingChequeAmount),
      "bounceAmount": new FormControl(data.bounceAmount),
      "paymentStatus": new FormControl(data.paymentStatus),
      "chequePaymentStatus": new FormControl(data.chequePaymentStatus),
      "gst": new FormControl(data.gst),
      "transactionNo": new FormControl(data.transactionNo),
      "rtgsNft": new FormControl(data.rtgsNft),
      "bankName": new FormControl(data.bankName),
      "rtgsAmount": new FormControl(data.rtgsAmount),
      "description": new FormControl(data.description),
      "advanceStatus": new FormControl(data.advanceStatus),
      "searchBilling": new FormControl(''),
      "billingCompanyName": new FormControl(data.billingCompanyName),
      "billingCompanyRefId" :new FormControl(data.billingCompanyRefId,Validators.required),
          "updatedAt": new Date(),
        });
        this.readonlyAmt = true;
        if(data.modeOfPayment == 'Cheque')
        {
          this.chequeDetails = data.chequeDetails;
          this.advanceAmountForm.controls["paidAmount"].setValue(data.pendingChequeAmount);
          this.readonly = true;
          
        }
        
        this.prepoId=data._id;
      }
    });
  }
  redirect(to): void {
    this.router.navigate([to]);
   } 

  cancelUpdate(){
 
    this.submitButton=true;
    this.updateButton=false;

    this.ngOnInit();
    this.advanceAmountForm.reset();
  }

  openSnackbar(message: string) {
    return this._snackBar.open(message, "close", {
      verticalPosition: "bottom",
      horizontalPosition: "right",
      duration: 2000,
      panelClass: ["snackbarStyle"],
    });
  }
  toadvanceAmountForm() {
    document.getElementById("toadvanceAmountForm").scrollIntoView();
  }
  toBillingLst() {
    document.getElementById("toBillingLst").scrollIntoView();
  }

  onPageChange(event) {
    this.page = event;
    let search=''; 
    let billNo = '';
    let advanceStatus = 'Yes';
    this.spinner.show();
    this.searchText ='';
    let payload = this.advanceAmountForm.value;
    this.searchText = this.advanceAmountForm.get('searchBilling').value;
    let branchRef= sessionStorage.getItem('branchRef');
    if(this.userType !== "Super Admin"){
      branchRef = sessionStorage.getItem('branchRef');
    }else{
      branchRef = sessionStorage.getItem('branchRef');
    }
   
    this.apimicroservice.getAllPaymentHistoryByAdvance(this.page,this.size,branchRef,this.searchText,billNo,advanceStatus).subscribe(res => {
       this.spinner.hide(); 
      //
       if(res.status){
        this.advanceList = res.data.data;
        this.totalLength=res.data.totalItems;
        this.apimicroservice.openSnackbar(res.message);        
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });

  }
  onKeyUpEvent(event: any) {
    let payload = this.advanceAmountForm.value;
    this.searchText = this.advanceAmountForm.get('searchBilling').value;
    let branchRef= sessionStorage.getItem('branchRef');
    if(this.userType !== "Super Admin"){
      branchRef = sessionStorage.getItem('branchRef');
    }else{
      branchRef = sessionStorage.getItem('branchRef');
    }
    let billNo = '';
    let advanceStatus = 'Yes';
    this.apimicroservice.getAllPaymentHistoryByAdvance(this.page,this.size,branchRef,this.searchText,billNo,advanceStatus).subscribe(res => {
      //
       if(res.status){
        this.advanceList = res.data.data;
        this.totalLength=res.data.totalItems;
        this.apimicroservice.openSnackbar(res.message);        
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       } 
     });
  }

    branchClick(id,branchName){
      this.advanceAmountForm.controls["branchName"].setValue(branchName);
     this.branchRef = id;
     this.searchText = '';
     let branchRef='';
     let clinicRef='';
     this.apimicroservice.getBranchById(id).subscribe(res => { 
       //
          
       let data = res.data[0];
       let payload = this.advanceAmountForm.value;
        payload.clinicRefId = res.data[0].clinicRefId;
        this.advanceAmountForm.controls["branchRefId"].setValue(res.data[0]._id);
       //  this.repairComplaintForm.controls["clinicRefId"].setValue(res.data[0].clinicRefId);
        if(this.updateButton == false){

        }
      });
 
   }
    fetchBranchList() {
      let clinicRef= sessionStorage.getItem('clinicRef');
      this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
        if (res.status) {    
          this.BranchList = res.data;
          this.filteredListBranch= this.BranchList.slice();
        } else {
        //  this.apimicroservice.openSnackbar(res.message);
        }
      })
    }
    getAllBranch()
    { 
      let clinicRef= sessionStorage.getItem('clinicRef');
      this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
        if (res.status) { 
          this.BranchList = res.data;
          this.filteredListBranch= this.BranchList.slice();       
          let length = res.data.length - 1;
          this.branchName=res.data[length].branchName;   
          this.advanceAmountForm.controls["branchRefId"].setValue(res.data[length]._id);
          this.branchClick(res.data[length]._id,this.branchName);
         
        }
      });
    }

    openBranchPopUp() {
      localStorage.clear();
      const dialogRef3 = this.dialog.open(PopUpBranchComponent,
        {
          width: "1200px", 
        //  height: "570px"
        });
         dialogRef3.afterClosed().subscribe((result) => {
          let clinicRef= sessionStorage.getItem('clinicRef');
          this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
            //  
            if (res.status) {    
              this.BranchList = res.data;
              this.filteredListBranch= this.BranchList.slice();
              this.advanceAmountForm.get("branchRefId").setValue(res.data[0]._id)
            } else {
              this.apimicroservice.openSnackbar(res.message);
            }
          })
        })
    }
    openPatientSearchPopUp() {
      const dialogRef3 = this.dialog.open(PatientSearchPopComponent, {
        width: "97%",
        height: "90%", 
      });
  
      dialogRef3.afterClosed().subscribe((result) => {
        this.advanceAmountForm.get("patientName").setValue(result.data.firstName + " " + result.data.lastName);
        this.advanceAmountForm.get("patientRefId").setValue(result.data._id);
        this.onSelectPatient( 
          result.data
        );
      });
    }
    onSelectPatient(item){
      this.patientRefId = item._id;
      this.submitButton = true;
      this.updateButton = false;
    }
    
  updateData() {
    this.spinner.show();
    let payload = this.advanceAmountForm.value;
     payload.paymentDate = new Date();
    if(this.userType !== 'Super Admin')
    {
      payload.branchRefId = this.branchRef;
      payload.branchName = this.branchName;
    } 
    payload.advanceStatus = 'Yes';
    if(payload.modeOfPayment == 'Cheque')
    {
      
      let bar = new Promise<void>((resolve,reject) =>{
        this.spinner.hide(); 
        payload['pendingChequeAmount'] = parseInt(payload.paidAmount);
        this.chequeDetails.forEach(ele =>{
          // receiptNo = Math.floor(10000 + Math.random() * 90000);
          payload['chequeDetails'] = ele;
          payload['chequePaymentStatus'] = "postdated";
          // payload['receiptNo'] = receiptNo;
          payload['advanceStatus'] = 'Yes'; 
          payload['paidAmount'] = 0; 
    
          this.apimicroservice.updatePaymentHistoryDetails(payload,this.prepoId).subscribe(res => {
            this.spinner.hide();  
            if(res.status){
              this.apimicroservice.openSnackbar("cheque payment in-progress");
              resolve();
            } 
          });
          
        });
        
       });
        bar.then(() => {
          this.spinner.hide();  
          this.updateButton=false;
          this.submitButton=true; 
          this.ngOnInit();
           //window.location.reload();
        });
    }
    else
    {
      this.apimicroservice.updatePaymentHistoryDetails(payload,this.prepoId).subscribe(res => {
           this.spinner.hide();  
      this.updateButton=false;
      this.submitButton=true; 
        this.ngOnInit();
        if(res.status){
          this.apimicroservice.openSnackbar(res.message);
      
        }else{
          this.apimicroservice.openSnackbar(res.message);
        }
      },(ERROR:HttpErrorResponse) => {
        this.apimicroservice.openSnackbar(ERROR.error.message);
       //
       this.spinner.hide();
    });
    }
  } 
    openPatientPopUp() {   
      const dialogRef3 = this.dialog.open(PopUpPatientComponent,
        {
          width: "1200px",
        });
        dialogRef3.afterClosed().subscribe((result) => {
         // this.fetchPatientList();
         let clinicRef= sessionStorage.getItem('clinicRef');
         let branchRef= sessionStorage.getItem('branchRef');
         let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
         let branchRefSuperAdmin= '';
         if (this.userType !== 'Super Admin') {
         this.apimicroservice.getPatientListAll(branchRef,clinicRef).subscribe(res => {
          if (res.status) {
            // //
            this.patientList = res.data.data;
            this.filteredListPatient= this.patientList.slice();  
            this.advanceAmountForm.get("patientRefId").setValue(this.patientList[0]._id);
            this.advanceAmountForm.get("patientName").setValue(this.patientList[0].firstName + " " +this.patientList[0].lastName);
            this.onSelectPatient(this.patientList[0]);
          }
        });
      }else{
        this.apimicroservice.getPatientListAll(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
          if (res.status) {
            // //
            this.patientList = res.data.data;
            this.filteredListPatient= this.patientList.slice();  
            this.advanceAmountForm.get("patientRefId").setValue(this.patientList[0]._id);
            this.advanceAmountForm.get("patientName").setValue(this.patientList[0].firstName + " " +this.patientList[0].lastName);
            this.onSelectPatient(this.patientList[0]);
          }
        });
      }
         });
    }
  onback() {
    this.apimicroservice.back()
  }
  ModeOfPaymentClick(e)
  {
    let mode = e;
    
   if(e == 'Cheque')
   {
     this.modeval = 'Cheque';
     
     let finalChequeAmt = [];      
     //
     const dialogRef = this.dialog.open(PopupModeOfPaymentComponent,
       { 
         data: {
           data: mode,
           checkAmountArr:finalChequeAmt,
           chequeData:this.chequeDetails,
           pagename:'Advance Amount Page'
         }
       });
  
       dialogRef.afterClosed().subscribe((result) => {
        this.readonly = true;
         //
         this.chequeDetails = [];
         if(result !== undefined){
         this.chequeDetails = result.data;
         //
         let chequeAmt = 0;
         this.chequeDetails.forEach(ele =>{
            chequeAmt = chequeAmt + parseInt(ele.amount);
         });
         this.advanceAmountForm.controls['paidAmount'].setValue(chequeAmt);
         }else{
           this.advanceAmountForm.controls['modeOfPayment'].setValue('Cash');
           this.modeval = 'Cash';
         }
       });
   }
   else{
      this.modeval = mode;
      this.chequeDetails = [];
      this.readonly = false;
     }
   }
  fetchBillingCompanyList(){
    let id='';
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getBillingDataById(id,clinicRef).subscribe(res => {
      //
      this.billingCompanyList = res.data;
      this.filteredListBillingCompany= this.billingCompanyList.slice();
      let lenght = res.data.length-1;  
      this.companyClick(res.data[lenght]._id,res.data[lenght].companyName);
      this.advanceAmountForm.get("billingCompanyRefId").setValue(res.data[lenght]._id);
      if(res.status){
        //this.apimicroservice.openSnackbar(res.message);
      }else{ 
        //this.apimicroservice.openSnackbar(res.message);
      }
    });
  }
  companyClick(bid,bcname){
    this.advanceAmountForm.controls['billingCompanyRefId'].setValue(bid);
    this.advanceAmountForm.controls['billingCompanyName'].setValue(bcname);
  }
  
openBillingCompPopUp(){
  const dialogRef3 = this.dialog.open(PopupBillingcompanyComponent,
    {
      width: "1200px",
    //  height: "560px"
    });

    dialogRef3.afterClosed().subscribe((result) => {
      let id='';
      let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getBillingDataById(id,clinicRef).subscribe(res => {
     
      if(res.status){
        this.billingCompanyList = res.data;
        this.filteredListBillingCompany= this.billingCompanyList.slice();
       this.companyClick(res.data[0]._id,res.data[0].companyName);
       this.advanceAmountForm.get("billingCompanyRefId").setValue(res.data.data[0]._id);
      }else{
        //this.apimicroservice.openSnackbar(res.message);
      }
    });
      //this.fetchBillingCompanyList();  
     });
}
}

