<div class="container-fluid " >
  <div class="row ">
    <div class="col-sm-12 ">
       <div class="col-sm-12">
                  
                  <div class="row">

                    <div class="col-sm-11" ></div>
                    <div class="col-sm-1" >
                   
                      <div class="row no-print justify-content-end ">
                        <a (click)="printThisPage()" class="d-flex justify-content-end align-items-end ms-3">
                          <span class="material-symbols-outlined pr-2">print</span>Print</a>
                     </div> 
                     </div>
     
                     <div class="col-sm-12 card mTop ">
                      <div class="row cardd">
                <div class="col-sm-12">
                    <div class="row  justify-content-center">
                        <div class="col-sm-3 pr-0 mr-0 mt-0 pt-0"> 
                          <div class="row w2 pt-2  mt-0 pt-0 mb-0 pt-0">
                            <div class="col-sm-12  mt-0 pt-0 mb-0 pt-0"
                            style="display:block;" > 
                              <img *ngIf="!logoimg" class="img2 mt-2 pt-0 mb-0 pt-0"
                              width="100%" height="100%"
                          src="../../assets/clinic_logoDemo.png" alt="Amanda Wilson" >
                          <img *ngIf="logoimg" width="100%" height="100%"
                         [src]="logoimg" alt=" photo" class="img2  mt-2 pt-0 mb-0 pt-0">
                          </div>
                          </div>
                        </div>
                        
                        <div class="col-sm-6 text-center justify-content-center mt-3">
                          <div class="row text-center justify-content-center">
                            <div class="col-sm-12  text-center justify-content-center  mb-0 pb-0">
                              <div class="row ">
                                <div class="col-sm-12 ">
                          <strong class="fass mb-0 pb-0 mt-0 pt-0">
                              <h1 class="fsize" *ngIf="reportName !== ''">{{reportName}} </h1>
                              <h1 class="fsize" *ngIf="reportName == ''">Clinic Name</h1>
                           </strong>
                           </div>
                           </div>
                           </div>
                         
                           <div class="col-sm-12  text-center justify-content-center  mb-0 pb-0">
                            <div class="row">
                                  <label class="col-sm-12" *ngIf="reportName !== ''">
                                    {{reportAddress}},{{taluka}} , {{district}}, {{state}},</label>
                                    <label class="col-sm-12 mt-1" *ngIf="reportName !== ''">
                                    {{country}} - {{reportPinCode}}</label>
                                    </div>
                                </div>
                        

                          <div class="col-sm-12 mt-2 text-center justify-content-center">
                            <div class="row">
                              <div class="col-sm-12  pb-2">
                            <label  *ngIf="reportName !== ''">Mobile No :
                              <b>
                                {{reportPhoneNumber | slice:0:2}} {{reportPhoneNumber | slice:2:4}} {{reportPhoneNumber | slice:4:7}} {{reportPhoneNumber | slice:7:10}},
                                {{reportPhoneNumber1 | slice:0:2}} {{reportPhoneNumber1 | slice:2:4}} {{reportPhoneNumber1 | slice:4:7}} {{reportPhoneNumber1 | slice:7:10}},
                              </b>
                            
                            </label>
                            </div>
                          <div class="col-sm-12 pb-2 text-center justify-content-center">
                          <label class="trans">Email Id :{{emailAdd}}</label> 
                          </div>
                          <div class="col-sm-12 pb-2 text-center justify-content-center">
                            <label class="trans">Website :
                              {{website}} </label> 
                            </div>
                        </div>
                        </div>
                         
                        </div>
                        </div>
                        <!-- <div class="vl col-sm-1 mt-3"></div> -->
                          <div class="col-sm-3 pr-0 pl-0 ml-0 mr-0 example-full-width mt-5 spanClass">
                            <div class="row ">
                              <div class="col-sm-12 pb-2">
                               <strong>
                                Receipt Number: {{recepitNo}}
                         </strong>
                         </div>
                          <br>
                          <div class="col-sm-12 pb-2">
                            
                            <strong  *ngIf="openTime !== '' &&
                            closeTime !== ''">From {{openTime}} To {{closeTime}}</strong> 
                       
                          </div>
                          <br>
                          <div class="col-sm-12">
                            <strong> Date: {{currentdate | date: 'dd/MM/yyyy'}}</strong>
                           </div>
                       </div>

                            <!-- <strong><span class="auto-style2 spanClass fass custom-label-title example-full-width">
                              Receipt <br>No :    <label class="labelStyle justify-content-left example-full-width">{{recepitNo}}</label> 
                          </span></strong>  
                           <br>  
                            <strong><span class="auto-style2 spanClass fass custom-label-title">
                              Date : <label class="labelStyle justify-content-left">{{currentdate | date: 'dd/MM/yyyy'}}</label> 
                          </span></strong>   -->
                
                          </div>

                      </div>
                   
                    </div>
                   
                 
           
            
                                    <div class="col-sm-12 ">
                                        <table id="toBillingLst"
                                         class="table bgCol table-hover table-bordered" 
                                     >
                                            <thead>
                                              <tr class="text-center">
                                                    
                                                      <th class=" text-center">
                                                      <div class="row  pt-0 mt-1 text-center">
                                                        <label class="lable_style header justify-content-left mt-1 text-left">Received with thanks from :</label>
                                                       </div> 
                                                        <div class="row  pt-0 mt-2 text-center">
                                                          <label class="labelStyle justify-content-left">
                                                            <b>{{patientName}}</b></label>
                                                       </div>
                                                       <br> <br>

                                                       </th>
                                                      
                                                       <th class=" text-center">
                                                       
                       
                                                        <div class="row  pt-0 mt-0 text-center">
                                                           <label class="lable_style text-center  col-sm-12  pt-0 mt-0 mb-4">Rs.</label>
                                                          </div>
                                                           <div class="row  pt-0 mt-4 text-center">
                                                           <label class="labelStyle justify-content-left pt-0 mt-0 mtt col-sm-12">  {{billData.paidAmount}}</label>
                                                          </div>
                                                         </th>
                                                         <!-- <th class="margintt text-center">
                                                       
                       
                                                          <div class="row  text-center">
                                                             <label class="lable_style text-center  mb-4 col-sm-12">Ps.</label>
                                                            <label class="labelStyle col-sm-12 mtt  mt-4 justify-content-left">00</label>
                                                           </div>
                                                        
                                                         
                                                        </th> -->
                                                        </tr>
                                            
                                            </thead> 
                                         
                                        </table>
                                        <div class="row">
                                          <div class="col-sm-12">
                                            <div class="row ">
                       
                                              <div class="col-sm-4 justify-content-end" >
                                               <label class="lable_style pt-2 header justify-content-end">The sum of rupees :</label>
                                               
                                             </div>
                                         
                                            <div class="col-sm-8">
                                              <label class="labelStyle justify-content-left"> {{totalAmountInWords}} </label>
                                              <hr>
                                              </div>
                                              </div>
    
                                              <div class="row mt-1">
                           
                                                <div class="col-sm-4 justify-content-end" >
                                                 <label class="lable_style pt-2 header justify-content-end">By cash/cheque no:</label>
                                                 
                                               </div>
                                           
                                              <div class="col-sm-8 custom-label-title">
                                                <label class="labelStyle justify-content-left custom-label-title">  {{billData.modeOfPayment}}</label>
                                                <hr>
                                                </div>
                                                </div>
                                            
                                                <div class="row mt-1">
                           
                                                  <div class="col-sm-4 justify-content-end" >
                                                   <label class="lable_style pt-2 header justify-content-end">Drawn on:</label>
                                                   
                                                 </div>
                                             
                                                <div class="col-sm-8">
                                                  <label class="labelStyle justify-content-left">  </label>
                                                  <hr>
                                                  </div>
                                                  </div>
    
                                                  <div class="row mt-2">
                           
                                                    <div class="col-sm-4 justify-content-end" >
                                                     <label class="lable_style pt-2 header justify-content-end">In full/part payment:</label>
                                                     
                                                   </div>
                                               
                                                  <div class="col-sm-8">
                                                    <label *ngIf="billData.balanceAmount == 0" class="labelStyle justify-content-left"> Full Payment </label>
                                                    <label *ngIf="billData.balanceAmount !== 0" class="labelStyle justify-content-left"> Partially Payment </label>
                                                    <hr>
                                                    </div>
                                                    </div>
    
                                                    <div class="row mt-1">
                                                      <div class="col-sm-4 justify-content-end" >
                                                       <label class="lable_style pt-2 header justify-content-end">Against bill no:</label>
                                                     </div>
                                                    <div class="col-sm-8">
                                                      <label class="labelStyle justify-content-left"> {{billData.billNo}}  </label>
                                                      <hr>
                                                      </div>
                                                    </div>
                                                    <div class="row mt-1">
                                                      <div class="col-sm-4 justify-content-end" >
                                                       <label class="lable_style pt-2 header justify-content-end">Receipt type:</label>
                                                     </div>
                                                        <div class="col-sm-8">
                                                      <label *ngIf="billData.advanceStatus == 'Yes'" 
                                                      class="labelStyle justify-content-left"> From Advance</label>
                                                      <label *ngIf="billData.advanceStatus == 'No'" 
                                                      class="labelStyle justify-content-left"> From Regular</label>
                                                      <hr>
                                                      </div>
                                                    </div>
                                                    <div class="row mt-1" *ngIf="billData.advanceStatus == 'Yes'">
                                                      <div class="col-sm-4 justify-content-end" >
                                                       <label class="lable_style pt-2 header justify-content-end">Remaining balance:</label>
                                                     </div>
                                                        <div class="col-sm-8">
                                                      <label 
                                                      class="labelStyle justify-content-left"> {{billData.balanceAmount}}</label>
                              
                                                      <hr>
                                                      </div>
                                                    </div>
                                                    <div class="row mt-1" *ngIf="billData.advanceStatus == 'Yes'">
                                                      <div class="col-sm-4 justify-content-end" >
                                                       <label class="lable_style pt-2 header justify-content-end">Description:</label>
                                                     </div>
                                                        <div class="col-sm-8">
                                                      <label 
                                                      class="labelStyle justify-content-left
                                                      col-sm-12 pl-0 ml-0"> {{billData.description}}</label>
                              
                                                      <hr>
                                                      </div>
                                                      
                                                    </div>
                                        </div>
                                         
                                         
                                        </div>
                                      
                                                 
                                                  <div class="row">
                       
                                                  <div class="col-sm-12 marginTopp">
                                                    <label class="labelStyle justify-content-left">  </label>
                                                    <hr>
                                                    </div>
                                                    <div class="col-sm-12 mb-0">
                                                      <label class="lable_style pt-2 header justify-content-end">All payment by cheque are acknowledged subject to realisation.</label>
                                                      </div>
                                                    <div class="col-sm-12 mt-0 pt-0 ">
                                                      <label class="labelStyle justify-content-left">  </label>
                                                      <hr class="marginTopp2">
                                                      </div>
                                                    </div>
                                  
                                </div>
                              </div>

    </div>
    </div>
    </div>
    </div>
</div>