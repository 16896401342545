import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
 import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { Router,ActivatedRoute } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  MatSnackBar,
} from "@angular/material/snack-bar";

import { MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PopupBrandComponent } from "../popup-brand/popup-brand.component";
import { NgxSpinnerService } from "ngx-spinner";
import { PopupDeviceDetailsGlobalComponent } from "../popup-device-details-global/popup-device-details-global.component";

@Component({
  selector: 'app-device-dashboard',
  templateUrl: './device-dashboard.component.html',
  styleUrls: ['./device-dashboard.component.scss']
})
export class DeviceDashboardComponent implements OnInit {
  errormessage: string;
  searchText: any;
  devicelist=[];
  id: any;
// allBrand=[ 
// {name:'Brand 1',value:'Brand 1'},
// { name:'Brand 2',value:'Brand 2' },
// {name:'Brand 3',value:'Brand 3' },
// { name:'Brand 4',value:'Brand 4'},
// {name:'Brand 5',value:'Brand 5'},
// {name:'Brand 6',value:'Brand 6'},
// {name:'Brand 7',value:'Brand 7'},
//   ]
 
  // deviceStyle=[
  //   {name:'BTE',value:'BTE'},
  //   { name:'CIC',value:'CIC' },
  //   {name:'IIC',value:'IIC' },
  //   { name:'ITC',value:'ITC'},
  //   {name:'ITE',value:'ITE'},
  //   {name:'RIC',value:'RIC'},
  //   {name:'RemoteMic',value:'RemoteMic'},
  //   {name:'Remote',value:'Remote'},
  //   {name:'Charger',value:'Charger'},
  //   {name:'TV Connector',value:'TV Connector'},
  //   {name:'Implan',value:'Implan'},
  //     ]
      // batteryType=[
      //   {name:'10',value:'10'},
      //   { name:'13',value:'13' },
      //   {name:'312',value:'312' },
      //   { name:'675',value:'675'},
      //   {name:'AA',value:'AA'},
      //   {name:'AAA',value:'AAA'},
      //   {name:'Rechargeable',value:'Rechargable'},
      //   {name:'Power Adaptor',value:'Power Adaptor'},
      //     ]
          // allDeviceType=[
          //   {name:'Hearing Aid',value:'Hearing Aid'},
          //   { name:'Accessory',value:'Accessory' },
          //   {name:'Battery',value:'Battery' },
          //   { name:'Programming Hardware',value:'Programming Hardware'},
          //   {name:'Part & Spares',value:'Part & Spares'},
          // ]
          // allCondition=[
          //   {name:'DBR',value:'DBR'},
          //   { name:'Returned',value:'Returned' },
          //   {name:'Lost',value:'Lost' },
          //   { name:'Obsolete',value:'Obsolete'},
          //   {name:'Working',value:'Working'},
          //   {name:'Fitted',value:'Fitted'},
          // ]
          allVent=[
            {name:'No Vent',value:'No Vent'},
            { name:'1 mm',value:'1 mm' },
            {name:'2 mm',value:'2 mm' },
            { name:'IROS',value:'IROS'},
           
          ]
  filteredList: { name: string; value: string; }[];
  filteredDeviceList: { name: string; value: string; }[];
  filteredConditionList: { name: string; value: string; }[];
  filteredAllVentList: { name: string; value: string; }[];

  filteredListStyle: { name: string; value: string; }[];
  filteredListbatteryType: { name: string; value: string; }[];
  brandlist: any;
  branchRef: string;
  clinicRef: string;
  BranchList: any;
 deviceTypelist:any;
 styleList:any;
 batteryTypeList:any;
 conditionTypeList:any;
  userType: string;
  branchName: string;
  filteredListBranch: any;
  listOfBilling: any;
  filteredBillingCompany: any;
  isGstApplicable= false;
  pId: any;
  devicelist2=[];
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
     public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    public route : ActivatedRoute
  ) {}
  deviceDashboardForm: FormGroup;
  status: any;
  submitButton = true;
  updateButton = false;
  deleteButton = false;
  cancelButton=false;
  loggedId: any;
  myimage;
  date: any;
  size:number = 5;
  page:number = 1;
  totalLength: any;
  size1:number = 5;
  page1:number = 1;
  totalLength1: any;
  arr = [];
  userPermission:any;
  devicesCreate= true;
  devicesUpdate= true;
  isSuperAdmin= true;
  superBranchId: any;
  detailsView = false;
  ngOnInit() {
    this.date = new Date();
    this.page=1;
    //this.filteredListStyle = this.deviceStyle.slice();
   // this.filteredListbatteryType = this.batteryType.slice();
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.userType = sessionStorage.getItem('userType');
   // this.filteredDeviceList = this.allDeviceType.slice();
    // this.filteredConditionList = this.allCondition.slice();
    this.filteredAllVentList = this.allVent.slice();
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    if(this.userType !== 'Super Admin'){
      this.isSuperAdmin= false;
    this.userPermission.forEach((element) => {
      if(element.pageName == 'Devices' && element.create == false)
      {
          this.devicesCreate = false;  
      }
      if(element.pageName == 'Devices' && element.update == false)
      {
          this.devicesUpdate = false;  
      }    
    });
  }
    this.deviceDashboardForm = this.fb.group({
      "branchWiseId": new FormControl(''),
      "searchDevice": new FormControl(''),
      "brand": new FormControl('',Validators.required),
      "brandLable": new FormControl(''),
      "model": new FormControl('',Validators.required),
      //"serialNo": new FormControl('',Validators.required),
      "deviceType": new FormControl('',Validators.required),
      "style": new FormControl(''),
      "batteryType": new FormControl(''),
      "cordType": new FormControl(''),
      "ear": new FormControl(''),
      "technology": new FormControl(''),
      "condition": new FormControl(''),
      "deviceColor": new FormControl('', Validators.pattern("^[a-zA-Z ]*$")),
      "shellColor": new FormControl('', Validators.pattern("^[a-zA-Z ]*$")),
      "facePlateColor": new FormControl('', Validators.pattern("^[a-zA-Z ]*$")),
      "vent": new FormControl(''),
      "matrix": new FormControl(''),
      "receiver": new FormControl(''),
      "dome": new FormControl(''),
      "facePlateNumber": new FormControl('',Validators.pattern("^[0-9 ]*$")),
      "waxManagement": new FormControl(''),
      "removalString": new FormControl(''),
      "notes": new FormControl(''),
      "createdAt": this.date,
      "branchRefId": new FormControl(),
      "clinicRefId": new FormControl(),
      "branchName": new FormControl(),
      "billingCompanyRefId": new FormControl('',Validators.required),
      "billingCompanyName": new FormControl(),
      "gstApplicable": new FormControl('No',Validators.required),
      "has": new FormControl('',[Validators.pattern("^[0-9 ]*$"),Validators.maxLength(6),Validators.minLength(4)]),
      "sgst": new FormControl('',[Validators.pattern("^[0-9 ]*$"),Validators.maxLength(3)]),
      "cgst": new FormControl('',[Validators.pattern("^[0-9 ]*$"),Validators.maxLength(3)]),
      "igst": new FormControl('',[Validators.pattern("^[0-9 ]*$"),Validators.maxLength(3)]),
      "mrp": new FormControl('',[Validators.required,Validators.pattern("^[0-9 ]*$"),Validators.maxLength(8)]),
    });
     this.deviceDashboardForm.controls["deviceType"].setValue("Hearing Aid");
     this.deviceDashboardForm.controls["technology"].setValue("Digital");
     this.deviceDashboardForm.controls["style"].setValue("RIC");
     //this.deviceDashboardForm.controls["batteryType"].setValue("Rechargable");
     this.deviceDashboardForm.controls["ear"].setValue("Not Defined");
     this.deviceDashboardForm.controls["batteryType"].setValue("Rechargeable");
     this.route.queryParams.forEach(params => {
      if (params.PatientId) {
        this.pId = params.PatientId;
        this.fetchInternalDevice();
        this.fetchExternalDevice();
      }
    }); 
    
  }
  // fetchDeviceList() {
  //   let pg = 1;
  //   let size = 5;
  //   let search='';
  //   this.searchText = this.deviceDashboardForm.get('searchDevice').value;    
  //   let branchRef='';
  //   let clinicRef='';
  //   this.apimicroservice.getBillingDeviceDataByPatientRef(this.pId).subscribe(res => {
  //     //this.spinner.hide();
  //       // this.devicelist = res.data.deviceData;
  //       // this.totalLength = res.data.totalItems; 
  //       //  
  //       this.devicelist = res.data;  
  //     // this.totalLength = res.data.totalItems;  
  //     });
  // }  
  fetchInternalDevice() {
    this.spinner.show();
    let pg = 1;
    let size = 5;
    let search='';   
    this.devicelist = [];
    this.searchText = this.deviceDashboardForm.get('searchDevice').value;    
    this.apimicroservice.getInternalDevice(pg,size,search,this.pId).subscribe(res => {
    this.spinner.hide();
         res.data.data.forEach((element) => {
         this.devicelist.push(element);
              });
        this.totalLength = res.data.totalItems; 
       }); 
    // this.apimicroservice.getDeviceByPatientById(this.pId).subscribe(res => {
    //   this.devicelist = res.data;
    // });  
  }
  
  fetchExternalDevice() {
       this.spinner.show();
       let pg = 1;
       let size1 = 5;
       let search='';  
       this.devicelist2 = [];
       this.searchText = this.deviceDashboardForm.get('searchDevice').value;    
    //  this.apimicroservice.getExternalDevice(pg,size1,search,this.pId).subscribe(res => {
    //   this.spinner.hide();
    //   res.data.deviceData.forEach((element) => {
    //     this.devicelist2.push(element)
    //   });  
    //   this.totalLength1 = res.data.totalItems;  
    //       }); 
          let type = 'Not Fitted By Us';  
          this.apimicroservice.getDeviceDetailsByPatient(pg,size1,search,this.pId,type).subscribe(res => {
            this.spinner.hide();
            res.data.deviceData.forEach((element) => {
              this.devicelist2.push(element)
            });  
            this.totalLength1 = res.data.totalItems;  
                }); 
    // } 
     }
  onPageChange(event) {
    this.page = event;
    this.spinner.show();
    this.searchText = this.deviceDashboardForm.get("searchDevice").value;   
    this.devicelist = [];
    this.apimicroservice.getInternalDevice(this.page,this.size,this.searchText,this.pId).subscribe(res => {
    this.spinner.hide();
         res.data.data.forEach((element) => {
         this.devicelist.push(element)
              });
        this.totalLength = res.data.totalItems; 
       }); 
     // }
  } 
  onPageChange2(event) {
    this.page1 = event;
    this.spinner.show();
    this.searchText = this.deviceDashboardForm.get("searchDevice").value;
    this.devicelist2 = [];   
    let type = 'Not Fitted By Us'; 
    this.apimicroservice.getDeviceDetailsByPatient(this.page1,this.size1,this.searchText,this.pId,type).subscribe(res => {
      this.spinner.hide();
      res.data.deviceData.forEach((element) => {
        this.devicelist2.push(element)
      });  
      this.totalLength1 = res.data.totalItems;  
          }); 
  }
  onKeyUpEvent(event: any) {
    this.searchText = this.deviceDashboardForm.get("searchDevice").value;  
   let pg = 1;
   let size = 5;
   let pg1 = 1;
   let size1 = 5;
   this.devicelist = [];
    this.apimicroservice.getInternalDevice(pg,size,this.searchText,this.pId).subscribe(res => {
    this.spinner.hide();
    this.devicelist = [];
         res.data.data.forEach((element) => {
         this.devicelist.push(element)
              });
        this.totalLength = res.data.totalItems;
        this.page = res.data.pageNumber;
       }); 
          /////////External Device
          let type = 'Not Fitted By Us'; 
          this.apimicroservice.getDeviceDetailsByPatient(pg1,size1,this.searchText,this.pId,type).subscribe(res => {
            this.devicelist2 = [];   
            res.data.deviceData.forEach((element) => {
            this.devicelist2.push(element)
          });  
          this.totalLength1 = res.data.totalItems;  
          this.page1 = res.data.pageNumber;
              }); 
  }
  openDetailsPopUp(deviceid,sno,model,isExtl) {
    //
    //
     const dialogRef3 = this.dialog.open(PopupDeviceDetailsGlobalComponent,
       {
          // width: "800px",
          data: { 
           deviceRefId:deviceid,
           serialNo:sno,
           model:model,
           pId:this.pId, 
           isExternal:isExtl
         } 
         // height: "570px"
       }); 
       // dialogRef3.afterClosed().subscribe((result) => {
       //   this.fetchBrandList();
       // })
   }
  redirect(to): void {
    this.router.navigate([to]);
  }
 
} 