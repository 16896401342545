import { Component, Inject, OnInit } from "@angular/core";
import {FormGroup,FormBuilder,FormControl, Validators} from '@angular/forms';
import { ApiServiceService } from '../api-service.service';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner"; 
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-pop-up-patientsource',
  templateUrl: './pop-up-patientsource.component.html',
  styleUrls: ['./pop-up-patientsource.component.scss']
})  
export class PopUpPatientsourceComponent implements OnInit {
  popupPatientSourceForm: FormGroup;
  totalLength:any;
  searchText:any;
  page = 1;
  size = 3;
  listOfSorce: any;
  submitButton = true;
  updateButton = false;
  cancelButton = false;
  sid: any;
  
  // Define clinicRef and branchRef
  clinicRef: any;
  branchRef: any;
  userType: string;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<PopUpPatientsourceComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any, 
    public apimicroservice: ApiServiceService,
    private spinner: NgxSpinnerService
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.popupPatientSourceForm = this.fb.group({  
      "patientSource": new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z\\-\\/ ]+$")]),
      "createdAt": new Date(),
      "searchSource": new FormControl(''),   
    });
    this.fetchPatientSourceList();

    // Initialize clinicRef and branchRef if they need to be fetched
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.userType = sessionStorage.getItem('userType');
  }

  savePatientSource() {
    let payload = this.popupPatientSourceForm.value; 
    payload.clinicRefId = this.clinicRef;
    payload.branchRefId = this.branchRef;

    this.spinner.show();
    payload.patientSource = payload.patientSource.toLowerCase();

    this.apimicroservice.submitPatientSourceData(payload).subscribe(res => {
      this.spinner.hide();
      if(res.status) {
        this.apimicroservice.openSnackbar(res.message);      
        this.ngOnInit();
        this.onClose();        
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    }, (ERROR: HttpErrorResponse) => {
      this.apimicroservice.openSnackbar(ERROR.error.message);
      this.spinner.hide();
      this.onClose();    
    });
  }

  save() {
    if (this.popupPatientSourceForm.invalid) {
      this.popupPatientSourceForm.get("patientSource").markAsTouched();
      return;
    } else {
      this.savePatientSource();
    }
  }
  onKeyUpEvent(event: any) {
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    this.searchText = this.popupPatientSourceForm.get('searchSource').value;
    if (this.userType !== 'Super Admin') {
    this.apimicroservice.getPatientSource(this.page,this.size,this.searchText,branchRef,clinicRef).subscribe(res => {
      this.listOfSorce = res.data.data;
      this.totalLength = res.data.totalItems;
       if(res.status){
        //  this.apimicroservice.openSnackbar(res.message);    
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       } 
     });
    }else{
      this.apimicroservice.getPatientSource(this.page,this.size,this.searchText,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
        this.listOfSorce = res.data.data;
        this.totalLength = res.data.totalItems;
         if(res.status){
          //  this.apimicroservice.openSnackbar(res.message);    
         }else{
           //this.apimicroservice.openSnackbar(res.message);
         } 
       });
    }
  }
  onPageChange(event) {
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    this.page = event;
   // let p = this.page - 1;
    this.searchText = this.popupPatientSourceForm.get('searchSource').value; 
    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getPatientSource(this.page,this.size,this.searchText,branchRef,clinicRef).subscribe(res => {
      this.listOfSorce = res.data.data;
      this.totalLength = res.data.totalItems;
         if(res.status){
          //  this.apimicroservice.openSnackbar(res.message);      
         }else{
           //this.apimicroservice.openSnackbar(res.message);
         }
    
       });
      }else{
        this.apimicroservice.getPatientSource(this.page,this.size,this.searchText,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
          this.listOfSorce = res.data.data;
          this.totalLength = res.data.totalItems;
             if(res.status){
              //  this.apimicroservice.openSnackbar(res.message);      
             }else{
               //this.apimicroservice.openSnackbar(res.message);
             }
        
           });
      }
  
  }
  fetchPatientSourceList() {
    let pg = 1;
    let size = 3;
    let search='';
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
        this.apimicroservice.getPatientSource(pg,size,search,branchRef,clinicRef).subscribe(res => {
           this.listOfSorce = res.data.data;
           this.totalLength = res.data.totalItems;
           //this.buildingLenght = this.listofbuild.length;
          if(res.status){
           // this.apimicroservice.openSnackbar(res.message);       
          }else{
            //this.apimicroservice.openSnackbar(res.message);
          }     
        });   
      }else{
        this.apimicroservice.getPatientSource(pg,size,search,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
          this.listOfSorce = res.data.data;
          this.totalLength = res.data.totalItems;
          //this.buildingLenght = this.listofbuild.length;
         if(res.status){
          // this.apimicroservice.openSnackbar(res.message);       
         }else{
           //this.apimicroservice.openSnackbar(res.message);
         }     
       }); 
      }
  }
  prepopulation(id) {
    this.apimicroservice.getSourceById(id).subscribe(res => {
      let data = res.data[0];
      this.sid = id;
       if(res.status){
        this.submitButton = false;
         this.updateButton = true;
         this.cancelButton = true;
         this.popupPatientSourceForm = this.fb.group({  
          "patientSource": new FormControl(data.patientSource,[Validators.required,Validators.pattern("^[a-zA-Z\\-\\/ ]+$")]),
          "updateddAt": new Date(),
          "searchSource": new FormControl(''),   
        });
       }else{
     //    //this.apimicroservice.openSnackbar(res.message);
       }
     });
  }
  updateData() {
    let payload = this.popupPatientSourceForm.value;
    payload.patientSource = payload.patientSource.toLowerCase();
     this.apimicroservice.updateSourceData(payload,this.sid).subscribe(res => {
       if(res.status){
         this.apimicroservice.openSnackbar(res.message);
          this.updateButton = false;
          this.submitButton = true;
          this.ngOnInit();
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
    
  }
  cancelUpdate(){
    this.popupPatientSourceForm.reset();
    this.submitButton=true;
    this.updateButton=false;
    this.cancelButton=false;
    this.ngOnInit();
  }
  onClose(): void {
    this.dialogRef.close();
  }
}