import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ApiServiceService } from '../api-service.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner"; 
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-pop-up-servicetype',
  templateUrl: './pop-up-servicetype.component.html',
  styleUrls: ['./pop-up-servicetype.component.scss']
})
export class PopUpServicetypeComponent implements OnInit {

  popupServiceTypeForm: FormGroup;
  listOfServiceType: any;
  totalLength: any;
  searchText: any;
  page: number = 1;
  size: number = 3;
  sid: any;
  submitButton = true;
  updateButton = false;
  cancelButton = false;
  clinicRefId: string;
  branchRefId: string;
  
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<PopUpServicetypeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, 
    public apimicroservice: ApiServiceService,
    private spinner: NgxSpinnerService,
  ) { 
    dialogRef.disableClose = true;
  }

  addNewComplaint = false;

  ngOnInit(): void {
    this.page = 1;
    this.popupServiceTypeForm = this.fb.group({  
      "serviceType": new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9 -]+$")]),
      "createdAt": new Date(),
      "searchServiceType": new FormControl(''),
    });
    this.fetchServiceList();
    this.getSessionData();
  }

  getSessionData() {
    this.clinicRefId = sessionStorage.getItem('clinicRef');
    this.branchRefId = sessionStorage.getItem('branchRef');
    if (!this.clinicRefId || !this.branchRefId) {
      console.error('Clinic or Branch reference ID not found in session storage');
      // You might want to handle this error, perhaps by showing a message to the user
    }
  }

  fetchServiceList() { 
    let pg = 1;
    let size = 3;
    let search = '';
    this.apimicroservice.getServiceType(pg, size, search).subscribe(res => {
      this.listOfServiceType = res.data.data;
      this.totalLength = res.data.totalItems;
      if (res.status) {
        // this.apimicroservice.openSnackbar(res.message);
      } else {
        // this.apimicroservice.openSnackbar(res.message);
      }
    });   
  }

  saveServiceType() {
    let payload = this.popupServiceTypeForm.value; 
    
    // Add clinicRefId and branchRefId to the payload
    payload.clinicRefId = this.clinicRefId;
    payload.branchRefId = this.branchRefId;

    this.spinner.show();
    this.apimicroservice.submitServiceTypeData(payload).subscribe(
      res => {
        this.spinner.hide(); 
        if (res.status) {
          this.apimicroservice.openSnackbar(res.message);      
          this.ngOnInit();
          this.onClose();        
        } else {
          this.apimicroservice.openSnackbar(res.message);
        }
      },
      (ERROR: HttpErrorResponse) => {
        this.apimicroservice.openSnackbar(ERROR.error.message);
        this.spinner.hide();
        this.onClose();    
      }
    );
  }

  save() {
    if (this.popupServiceTypeForm.invalid) {
      this.popupServiceTypeForm.get("serviceType").markAsTouched();
      return;
    } else {
      this.saveServiceType();
    }
  }

  onKeyUpEvent(event: any) {
    this.searchText = this.popupServiceTypeForm.get('searchServiceType').value;
    this.apimicroservice.getServiceType(this.page, this.size, this.searchText).subscribe(res => {
      this.listOfServiceType = res.data.data;
      this.totalLength = res.data.totalItems;
      if (res.status) {
        // this.apimicroservice.openSnackbar(res.message);    
      } else {
        // this.apimicroservice.openSnackbar(res.message);
      }
    });
  }

  prepopulation(id) {
    this.apimicroservice.getServiceTypeById(id).subscribe(res => {
      let data = res.data[0];
      this.sid = id;
      if (res.status) {
        this.submitButton = false;
        this.updateButton = true;
        this.cancelButton = true;
        this.popupServiceTypeForm = this.fb.group({
          "serviceType": new FormControl(data.serviceType, [Validators.required, Validators.pattern("^[a-zA-Z0-9 -]+$")]),
          "updateddAt": new Date(),
          "searchServiceType": new FormControl(''),
        });
      } else {
        // this.apimicroservice.openSnackbar(res.message);
      }
    });
  }

  updateServiceType() {
    let payload = this.popupServiceTypeForm.value;
    
    // Add clinicRefId and branchRefId to the payload
    payload.clinicRefId = this.clinicRefId;
    payload.branchRefId = this.branchRefId;
    
    this.apimicroservice.updateServiceType(payload, this.sid).subscribe(res => {
      if (res.status) {
        this.apimicroservice.openSnackbar(res.message);
        this.updateButton = false;
        this.submitButton = true;
        this.onClose();       
      } else {
        // this.apimicroservice.openSnackbar(res.message);
      }
    });
  }

  onPageChange(event) {
    this.page = event;
    this.searchText = this.popupServiceTypeForm.get('searchServiceType').value;
 
    this.apimicroservice.getServiceType(this.page, this.size, this.searchText).subscribe(res => {
      this.listOfServiceType = res.data.data;
      this.totalLength = res.data.totalItems;
      if (res.status) {
        // this.apimicroservice.openSnackbar(res.message);
      } else {
        // this.apimicroservice.openSnackbar(res.message);
      }
    });
  }

  cancelUpdate() {
    this.popupServiceTypeForm.reset();
    this.submitButton = true;
    this.updateButton = false;
    this.cancelButton = false;
    this.ngOnInit();
  }

  onClose(): void {
    this.dialogRef.close();
  }
}