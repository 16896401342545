import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiServiceService } from '../api-service.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  userChangePasswordForm: FormGroup;
  email:String;
  private formSumitAttempt: boolean;
  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<ChangePasswordComponent>,@Inject(MAT_DIALOG_DATA) public data:any,
    public apimicroservice: ApiServiceService

  ) { }

  ngOnInit(): void {
    this.email = sessionStorage.getItem('emailId');
    
    this.userChangePasswordForm = this.fb.group({
     'newPassword': ['', [Validators.required, Validators.minLength(8)]],
      'password': ['', [Validators.required, Validators.minLength(8)]],
    });
  }


  ChangePassword() {
    let payload = {
      password: this.userChangePasswordForm.value.newPassword,
      currentpassword: this.userChangePasswordForm.value.password,
      emailId: this.email,
    }; 
    
 //
 
   this.apimicroservice.userChangePassword(payload).subscribe((res) => {
      if (res.status) {
        // //
      
        this.apimicroservice.openSnackbar(res.message);
        this.dialogRef.close();
      } else {
        
        this.apimicroservice.openSnackbar(res.message);
        this.dialogRef.close();
      }
    },
    (ERROR: HttpErrorResponse) => {
      let example = ERROR.error.message;
      this.apimicroservice.openSnackbar(example);
    }
    );
  }
  isFieldValid(field: string) {
    return (
      (!this.userChangePasswordForm.get(field).valid && this.userChangePasswordForm.get(field).touched) ||
      (this.userChangePasswordForm.get(field).untouched && this.formSumitAttempt)
    );
  }
  onSubmit() {
    this.formSumitAttempt = true;
    if (this.userChangePasswordForm.valid) {
    }
  }
  onClose(): void {
    this.dialogRef.close();
  }
}
