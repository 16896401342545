import { Component, Inject, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
 import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  MatSnackBar,
} from "@angular/material/snack-bar";
import { MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { PopUpBranchComponent } from "../pop-up-branch/pop-up-branch.component";
import { PopupServiceComponent } from "../popup-service/popup-service.component";
import { PopupAddDeviceComponent } from "../popup-add-device/popup-add-device.component";


@Component({
  selector: 'app-popup-total-bill',
  templateUrl: './popup-total-bill.component.html',
  styleUrls: ['./popup-total-bill.component.scss']
}) 
export class PopupTotalBillComponent implements OnInit {
  BillingForm: FormGroup; 
  date: any;
  listOfAppointment:any;
  totalLength:any; 
  appointmentId: any;
  size=5;
  page=1;
  searchText: any;
  submitButton = true;
  updateButton = false;
  allColor: any;
  
  filteredList: { name: string; value: string; }[];
  filteredListAppoinmentType: { name: string; value: string; }[];
  userType: string;
  branchRef: string;
  clinicRef: string;
  BranchList: any;
  isSuperAdmin= true;
  superBranchId: any;
  branchName: string;
  filteredListBranch: any;
  currentdate: Date;
  listOfBilling: any;
  id: any;
  courierList: any;
  filteredListCourier: any;
  disableFromCity = false;
  disableToCity = true;
  tocityList: any;
  cityList: any;
  fromCity: any;
  toCity: any;
  courierServiceCreate = true;
  branchCreate = true;
  filteredListPatient: any;
  patientList: any;
  totalBillAmt = 0;
  printData=[
    {
      srNo:1,
      date:'201',
      serviceName:'Test',
      companyName:'A Company',
      rate:100,
     },
     {
      srNo:2,
      date:'202',
      serviceName:'Test',
      companyName:'A Company',
      rate:200,
     },
     {
      srNo:3,
      date:'203',
      serviceName:'Test 2',
      companyName:'A Company',
      rate:100,
     },
     {
      srNo:4,
      date:'204',
      serviceName:'Test 3',
      companyName:'A Company',
      rate:150,
     },                 
  ]
  patientRefId: any;
  appointId: any;
  billingList = [];
  patientDetails: any;
  pagename: any;
  withoutAppId: any;
  branchRefId: any;
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
     public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<PopupTotalBillComponent>,@Inject(MAT_DIALOG_DATA) public data:any,
  ) { 
    this.patientRefId = data.patientRefId; 
    this.appointId = data.appointId; 
    this.patientDetails = data.patientDetails; 
    this.pagename = data.page;
    this.withoutAppId = data.withoutAppId;
    this.branchRefId = data.branchRefId;
    dialogRef.disableClose = true;
  } 
  userPermission:any;
  billingCreate= true;
  billingUpdate= true;

  ngOnInit(): void {
    this.currentdate = new Date();
    this.billingList = [];
    this.totalBillAmt = 0;
    //
   
    this.page=1;
    this.size = 5; 
    sessionStorage.setItem('bId',this.branchRefId);
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.userType = sessionStorage.getItem('userType');
    this.userType = sessionStorage.getItem('userType');
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    if(this.userType !== 'Super Admin'){
      this.isSuperAdmin= false;
    this.userPermission.forEach((element) => {
      if(element.pageName == 'Billing Record' && element.create == false)
      {
          this.billingCreate = false;  
      }
      if(element.pageName == 'Billing Record' && element.update == false)
      {
          this.billingUpdate = false;  
      }  
    });
  }
  //
  //
  //
    this.BillingForm = this.fb.group({

      "searchBilling": new FormControl(''), 
      
    });
    
     
    if(this.pagename !== 'Billing Page'){
    this.fetchBillList(); 
    }else{
      this.fetchBillingListByWithoutAppId();
    }
  //this.BillingForm.controls['date'].setValue(this.currentdate);
}
  
  prepopulation(billNo) {
    this.dialogRef.close();
    //this.redirect('bill-form');
    this.router.navigate(['bill-form',billNo]);
  } 

  toBillingForm() {
    document.getElementById("toBillingForm").scrollIntoView();
  }
  toBillingLst() {
    document.getElementById("toBillingLst").scrollIntoView();
  }

   
  fetchBillList() {
    let search='';
    this.totalBillAmt = 0;
    let branchRef= sessionStorage.getItem('branchRef');
    this.apimicroservice.getBillingDetailsByPatientId(this.page,this.size,search,this.patientRefId,this.appointId,branchRef).subscribe(res => {
      //  
      if (res.status) {    
        this.billingList = res.data.data;
        this.totalLength=res.data.totalItems;
        this.billingList.forEach(element =>{
          this.totalBillAmt = this.totalBillAmt + element.totalAmount;
        })
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    });
  }

  fetchBillingListByWithoutAppId(){
    let search=''; 
    this.totalBillAmt = 0;
    let branchRef= sessionStorage.getItem('branchRef');
      this.apimicroservice.getBillingDetailsByWithoutAppId(this.page,this.size,search,this.patientRefId,this.withoutAppId,branchRef).subscribe(res => {
        //  
        if (res.status) {    
          this.billingList = res.data.data;
          this.totalLength=res.data.totalItems;
          this.billingList.forEach(element =>{
            this.totalBillAmt = this.totalBillAmt + element.totalAmount;
          })
        } else {
          this.apimicroservice.openSnackbar(res.message);
        }
      });
    }

  onPageChange(event) {
    this.page = event;
    let search='';
    this.totalBillAmt = 0;
    let branchRef= sessionStorage.getItem('branchRef');
    this.apimicroservice.getBillingDetailsByPatientId(this.page,this.size,search,this.patientRefId,this.appointId,branchRef).subscribe(res => {
      //  
      if (res.status) {    
        this.billingList = res.data.data;
        this.totalLength=res.data.totalItems;
        this.billingList.forEach(element =>{
          this.totalBillAmt = this.totalBillAmt + element.totalAmount;
        });
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    });
    // if (this.userType !== 'Super Admin') {
    //   this.apimicroservice.getDoctorAvailablePaginationData(this.page,this.size,this.branchRef,this.clinicRef).subscribe(res => {
    //     if (res.status) {
    //       this.printData = res.data.data;
    //       this.totalLength=res.data.totalItems;
    //     } else {
    //       this.apimicroservice.openSnackbar("Something went wrong");
    //     }
  
    //   })
    // } 
  }

  printForm()
  {
    this.redirect('print-bill');
  } 
  redirect(to): void {
    this.router.navigate([to]);
   } 
   onClose(): void {
    this.dialogRef.close();
  }
  openServicePopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopupServiceComponent,
      { 
        width: "1200px", 
          data: {
            patientRefId: this.patientRefId,
            appointId:this.appointId,
            patientDetails:this.patientDetails,
            page:this.pagename,
          }
      
      }); 

      dialogRef3.afterClosed().subscribe((result) => {
        //
        if(result !== undefined){
          this.patientRefId = result.data.patientRefId;
          this.appointId = result.data.appointId;
          //
          if(result.data.withoutAppId !== undefined){
            this.withoutAppId = result.data.withoutAppId;
            this.fetchBillingListByWithoutAppId();
          }else{
          this.fetchBillList();
          } 
        }
        
       });
  } 
  openDevicePopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopupAddDeviceComponent,
      {
         width: "1200px", 
        data: {
          patientRefId: this.patientRefId,
          appointId:this.appointId,
          patientDetails:this.patientDetails,
          page:this.pagename,
        }
      }
      ); 

      dialogRef3.afterClosed().subscribe((result) => {
        //
        if(result !== undefined){
          this.patientRefId = result.data.patientRefId;
          this.appointId = result.data.appointId;
          if(result.data.withoutAppId !== undefined){
            this.withoutAppId = result.data.withoutAppId;
            this.fetchBillingListByWithoutAppId();
          }else{
          this.fetchBillList();
          }
        }
        
       });
  }
  
  // openServicePopUp() {
  //   const dialogRef3 = this.dialog.open(PopupServiceComponent,
  //     {
  //       width: "1200px",
  //       height: "570px"
  //     });
  // }
}