<div class="dialog-wrapper">
    <div class="container">
      <form [formGroup]="printBillForm" autocomplete="off" novalidate>
        <div class="row ">
            <div class="col-sm-12 ">
              
           
                    <div class="row">
                    
                      <div class="col-sm-12">
                        <div class="row"> 
                          <table id="toBillingLst"
                          class="table table-hover mb-0 pb-0 table-bordered">
                                  
                                
                          <tbody >
                          
                            <tr role="row"
                             class="odd tw">
                              
                             <td class=" mt-0 pt-0 mb-0 pt-0">  
                              <div class="row w2 pt-2  mt-0 pt-0 mb-0 pt-0">
                                <div class="col-sm-12  mt-0 pt-0 mb-0 pt-0"
                                style="display:block;" >
                                  <img *ngIf="!logoimg" class="img2 mt-2 pt-0 mb-0 pt-0"
                                  width="100%" height="100%"
                              src="../../assets/clinic_logoDemo.png" alt="Amanda Wilson" >
                              <img *ngIf="logoimg" width="100%" height="100%"
                             [src]="logoimg" alt=" photo" class="img2  mt-2 pt-0 mb-0 pt-0">
                              </div>
                              </div>
                            </td>
                              <td class="pr-0  mr-2 pl-0 ml-0 text-center justify-content-center pt-1 pb-0 ">
                                <div class="row w1">
                                  <div class="col-sm-12  mb-0 pb-0">
                                    <div class="row">
                                      <div class="col-sm-12">
                                <strong class="auto-style2 fass mb-0 pb-0 mt-0 pt-0">
                                    <h1 class="fsize" *ngIf="reportName !== ''">{{reportName}} </h1>
                                    <h1 class="fsize" *ngIf="reportName == ''">Clinic Name</h1>
                                 </strong>
                                 </div>
                                 </div>
                                 </div>
  
                                  <div class="col-sm-12  mt-0 pt-0">
                                    <div class="row">
                                      <div class="col-sm-12">
           
                                        <label class="col-sm-12" *ngIf="reportName !== ''">
                                          {{reportAddress}}, {{taluka}} , {{district}}, {{state}},</label>
                                          <label class="col-sm-12 mt-1" *ngIf="reportName !== ''">
                                          {{country}} - {{reportPinCode}}</label>
                                      </div>
                                </div> 
                                </div>
  
                                <div class="col-sm-12 mt-2 text-center justify-content-center">
                                  <div class="row">
                                    <div class="col-sm-12  pb-2">
                                  <label *ngIf="reportName !== ''">Mobile No :
                                    <b>
                                      {{reportPhoneNumber | slice:0:2}} {{reportPhoneNumber | slice:2:4}} {{reportPhoneNumber | slice:4:7}} {{reportPhoneNumber | slice:7:10}},
                    {{reportPhoneNumber1 | slice:0:2}} {{reportPhoneNumber1 | slice:2:4}} {{reportPhoneNumber1 | slice:4:7}} {{reportPhoneNumber1 | slice:7:10}},
                                    </b>
                                  
                                  </label>
                                  </div>
                                <div class="col-sm-12 pb-2 text-center justify-content-center">
                                <label class="trans">Email Id :{{emailAdd}}</label> 
                                </div>
                                <div class="col-sm-12 pb-2 text-center justify-content-center">
                                  <label class="trans">Website :
                                    {{website}} </label> 
                                  </div>
                              </div>
                              </div>
                              </div>
                                  </td>
                                 
                                  <td class="w2 pt-1 pb-0 text-center
                                  justify-content-center">
                                
                                     <div class="row marginTopp">
                                       <!-- <div class="col-sm-12 pb-2">
                                        <strong>
                                  GST Number: {{companyGst}}
                                  </strong>
                                  </div>
                                   <br> -->
                                   <div class="col-sm-12 pb-2">
                                   <strong> Weekly Off : {{reportWeeklyOff}}</strong>
                                   </div>
                                   <br>
                                   <div class="col-sm-12">
                                    <strong class="labelMin" *ngIf="openTime !== '' &&
                                    closeTime !== ''">From<br> {{openTime}}<br> To<br> {{closeTime}}</strong> 
                                    </div>
                                </div>
                                 </td> 
                        
                               </tr>
                               
                        </tbody> 
                          </table>

                        <div class="col-sm-12 mt-0 mb-0 pt-0 pb-0">
                          <h1 class="text-center mt-0 mb-0 pt-0 pb-0">INVOICE</h1></div>
                          <table id="toBillingLst"
                           class="table table-hover col-sm-12 mt-0 pt-0 table-bordered">
                  <thead class="bcol">
                      <th  colspan="2" class="pt-1 bcol pb-0">
                        <label>Name :</label>
                        </th>
                    
                      <th colspan="4" class="fontSize bcol pt-1 pb-0">
                    
                        <label *ngIf="prifix !== '' && prifix !== null
                        && prifix !== undefined; else elseBlock1">{{prifix}}. {{firstName}} {{lastName}}</label>
                       <ng-template #elseBlock1>
                         <label class="fontSize">{{firstName}} {{lastName}} </label>
                       </ng-template>  
                    </th>

                   
                       <th  class=" pt-1 bcol pb-0">
                        <label>Invoice :</label></th>
                       <th  class=" pt-1 bcol pb-0">
                        <label>{{billNo}}</label>
                        </th>
                   </thead>
                   <thead  class="bcol">
                    <th colspan="2" class="pt-1 bcol pb-0">
                      <label>Address :</label>
                      </th>
                     <th colspan="4" class=" bcol pt-1 pb-0">
                      <label>
                        {{streetAddress1}} {{taluka1}} {{district1}} {{state1}} 
                        {{country1}} {{pincode1}}
                        </label>
                     </th>
                     <th class=" pt-1 bcol pb-0">
                      <label>Date & Time :</label>
                       </th>
                     <th class=" pt-1 bcol pb-0">
                      <label>{{invoiceDate | date: 'dd/MM/yyyy'}},
                        {{currentdate | date: 'h:mm a'}}</label>
                      
                     </th>
                 </thead>
                 <thead class="bcol">
                  <th colspan="2" class="pt-1 bcol pb-0">
                       <label>Contact Details :</label>
                    </th>
                   <th colspan="4" class=" bcol pt-1 pb-0">
                    
                      <label *ngIf="pMobile !== '' && pMobile !== null
                      && pMobile !== undefined; else elseBlock">
                      <label>{{pMobile}}</label>
                      </label>
                     <ng-template #elseBlock>
                       <label>-</label>
                     </ng-template>  
                  </th>
                   <th class=" pt-1 bcol pb-0">
                    <label>GST Number :</label>
                    </th>
                   <th class=" pt-1 bcol pb-0">
                    <label>{{gstNumber}}</label>
                    </th>
               </thead>
               </table>
                              <!-- <thead>
                                      <th colspan="8">
                                          <div class="row col-sm-12 mb-3">
                                              <div class="col-sm-2 justify-content-end" >
                                               <label class="lable_style pt-2 header justify-content-end">Bill No :</label>
                                               
                                             </div>
                                             <div class="col-sm-4 marginLeft">
                                               <label class="labelStyle justify-content-left">{{billNo}}</label>
                                             </div>
                                             <div class="col-sm-4"></div>
                                             <div class="col-sm-2">
                                              <div class="row">
                                                <div class="col-sm-2"></div>
                                                <div class="col-sm-4 justify-content-end">
                                                 <label class="lable_style pt-2 header justify-content-end">Date:</label>
                                                 </div>
                                                 <div class="col-sm-4 text-left justify-content-left">
                                                 <label class="labelStyle text-left justify-content-left"> {{currentdate | date: 'dd/MM/yyyy'}}</label>
                                             </div>
                                             <div class="col-sm-2"></div>
                                             </div>
                                          </div>
                                             <div class="col-sm-2 justify-content-end" >
                                               <label class="lable_style pt-2 header justify-content-end">Patient Name :</label>
                                               
                                             </div>
                                             <div class="col-sm-4 marginLeft">
                                               <label class="labelStyle justify-content-left">{{patientName}}</label>
                                             </div>
                       
                                             </div>
                                         </th>
                              </thead>  --> 
                              <!-- <table id="toBillingLst"
                              class="table table-hover col-sm-12 table-bordered">
                                <thead>
                                  <tr class="text-center">
                                      <th  class=" pt-1 pb-1 text-left"> -->



                                        <div class="table-responsive">
                                        <table id="toBillingLst"
                                        class="table table-hover col-sm-12 table-bordered">
                              <thead>
                                  <tr class="text-center"> 
                                      <th class="width3 pt-1 pb-1">Sr.No.</th>
                                      <th class="width3 pt-1 pb-1">Item/Service Name</th>
                                      <th class="width3 pt-1 pb-1 ">Ear</th>
                                      <th class="width3 pt-1 pb-1 ">Warranty</th>
                                      <th class="width3 pt-1 pb-1 ">Warranty Till Date</th>
                                      <th class="width3 pt-1 pb-1">Serial Number</th>
                                      <th class="width3 pt-1 pb-1">HSN</th>
                                     <th class="width3 pt-1 pb-1">Qty</th>
                                     <th class="width3 pt-1 pb-1">Rate</th>
                                     <th class="width3 pt-1 pb-1">Other</th>
                                     <th class="width3 pt-1 pb-1">Amount</th>
                                    
                                  </tr>
                              </thead> 
                           
                              <tbody formArrayName="serviceInfo">
                                <tr [formGroupName]="i"
                                *ngFor="let row of printBillForm.get('serviceInfo')['controls']; let i = index; let last = last" role="row"
                                 class="odd text-center">
                                 <td class="width3 pt-1 pb-0">{{i + 1}}</td>
                        
                               <td class="width3 pt-1 pb-0">{{row.controls.serviceName.value}}
                                <td class="width3 pt-1 pb-0">{{row.controls.earStyle.value}}

                               </td>
                               <td class="labelStyle width3" matTooltip="Click To View" >
                                <label class="width3" *ngIf="paymentStatus == 'Paid'; else elseBlock">{{row.controls.warrantyPeriod.value}}</label>
                 
                              <ng-template #elseBlock>
                                <label>-</label>
                              </ng-template> 

                                </td>
                                <td class="width3 labelStyle" matTooltip="Click To View" >
                                  <label *ngIf="paymentStatus == 'Paid'; else elseBlock">
                                    {{row.controls.expiryDate.value ? (row.controls.expiryDate.value | date:'dd/MM/yyyy') : dashVal}}
                                  </label>
                   
                                <ng-template #elseBlock>
                                  <label>-</label>
                                </ng-template> 

                                  </td>

                               <!-- <td class=" pt-1 pb-0">{{row.controls.expiryDate.value ? (row.controls.expiryDate.value | date:'dd/MM/yyyy') : dashVal}}</td> -->
                               <td class="width3 pt-1 pb-0" matTooltip="Click To View" >
                                <label *ngIf="row.controls.serialNumber ? row.controls.serialNumber.value : serialVal
                                ; else elseBlock">{{row.controls.serialNumber ? row.controls.serialNumber.value : serialVal}}</label>
                            

                                </td>
                           
                               <td class="width3 pt-1 pb-0">{{row.controls.hasSac.value}}</td>
                               <td class="width3 pt-1 pb-0">{{row.controls.quantity.value}}</td>
                                    <td class="width3 pt-1 pb-0">{{row.controls.rate.value}}</td>
                                    <td class="width3 pt-1 pb-0">{{row.controls.discount.value?row.controls.discount.value:0}}</td>
                                    <td class="width3 pt-1 pb-0">{{row.controls.totalRate.value - row.controls.discount.value}} </td>
                              
                                 
                                   </tr>
                             

                            </tbody>
                              <thead>
                               
                            
                                  <tr  class="text-center">
                                    <th colspan="9" rowspan="2" class="text-left pt-1 pb-1"></th>
                                    <th  class="text-left pt-1 pb-1">GST</th>
                                    <th  class="pt-1 pb-1 text-center">₹{{totalGST | number : '1.2-2'}} </th>
                                  </tr>
                                  <!-- <tr class="text-center">
                                    <th class="text-left pt-1 pb-1">SGST</th>
                                    <th  class="pt-1 pb-1 text-center">₹ {{totalsgstAmt}} ({{totalsgst}}%)</th>
                                  </tr>
                                  <tr class="text-center">
                                    <th class="text-left pt-1 pb-1">CGST</th>
                                    <th  class="pt-1 pb-1 text-center">₹ {{totalcgstAmt}} ({{totalcgst}}%)</th>
                                  </tr>
                                  <tr class="text-center">
                                    <th class="text-left pt-1 pb-1">IGST</th>
                                    <th *ngIf="totalsgstAmt == 0 && totalsgstAmt == 0" class="pt-1 pb-1 text-center">₹ {{totaligstAmt}} ({{totaligst}}%)</th>
                                    <th *ngIf="totalsgstAmt !== 0 && totalsgstAmt !== 0" class="pt-1 pb-1 text-center">-</th>
                                  </tr> -->
                                  <tr class="text-center">
                                 
                                    <th class="text-left pt-1 pb-1">Total</th>
                                    <th  class="pt-1 pb-1 text-center ">₹{{totalAmount | number : '1.2-2'}}</th>
                                      
                                  </tr> 
                                  <!-- <tr class="text-center">
                      
                                  
                                    <th class="text-left pt-1 pb-1">Advance</th>
                                    <th   class="pt-1 pb-1 text-center">₹ {{advanceAmount}}</th>
                                   
                                  </tr> -->
                                  <!-- <tr class="text-center">
                      
                                  
                                    <th class="text-left pt-1 pb-1">Balance</th>
                                    <th  class="pt-1 pb-1 text-center">₹ {{balanceAmount}}</th>
                                   
                                  </tr> -->
                                </thead>
                              </table>
</div>
                              <!-- <table id="toBillingLst"
                              class="table col-sm-12 table-hover table-bordered">

                                <thead>
                                  <tr class="text-center">
                                      <th class=" pt-1 pb-1">HSN/SAC</th> -->

                              <table id="toBillingLst"
                              class="table table-hover col-sm-12 table-bordered">
                                <thead>
                                  <tr class="text-center">
                                      <th  class=" pt-1 pb-1 text-left">
                                        Amount in words:
                                        <label class="lable_style custom-label-title header justify-content-left">{{totalAmountInWords}}</label>
                                      </th>
                                        
                                  </tr>
                              </thead> 
                            
                            </table>
                          <table id="toBillingLst"
                          class="table col-sm-12 table-hover table-bordered">

                            <thead>
                              <tr class="text-center pt-3 pb-3">
                                  <th class="mt-2 mb-2">HSN/SAC</th>
                                  <th class=" pt-1 pb-1">Taxable Value</th>
                                  <th colspan="2" class=" pt-1 pb-1">CGST</th>
                                  <th colspan="2" class=" pt-1 pb-1">SGST</th>
                                 <th colspan="2" class=" pt-1 pb-1">IGST</th>
                                 <th colspan="2" class=" pt-1 pb-1">Total GST Amount</th>
                              </tr>
                          </thead> 
                       
                          <tbody >
                            <tr role="row"
                             class="odd text-center" 
                             *ngFor="let data of resultArr">
                              <td class="pt-1 pb-0">{{data.hasSac}}</td> 
                              <!--  -->
                            
                           <td class=" pt-1 pb-0">{{data.rate}}</td>
                           <!--  -->
                           <td class="labelStyle">
                            <label *ngIf="state1 == 'Maharashtra'; else elseBlock">{{data.cgst}}%</label>
                          <ng-template #elseBlock>
                            <label>-</label>
                          </ng-template>
                            </td>

                            <td class="labelStyle">
                              <label *ngIf="state1 == 'Maharashtra'; else elseBlock">₹{{data.cgstAmt}}</label>
                            <ng-template #elseBlock>
                              <label>-</label>
                            </ng-template>
                              </td>

                           <!-- <td class=" pt-1 pb-0 text-right">{{data.cgst}}%</td>
                           <td class=" pt-1 pb-0 text-right">{{data.cgstAmt}}</td>  -->
                         <!--  -->
                         <td class="labelStyle">
                          <label *ngIf="state1 == 'Maharashtra'; else elseBlock">{{data.sgst}}%</label>
                        <ng-template #elseBlock>
                          <label>-</label>
                        </ng-template>
                          </td>
                          <td class="labelStyle">
                            <label *ngIf="state1 == 'Maharashtra'; else elseBlock">₹{{data.sgstAmt}}</label>
                          <ng-template #elseBlock>
                            <label>-</label>
                          </ng-template>
                            </td>

                           <!-- <td class="pt-1 pb-0 text-right">{{data.sgst}}% </td>
                                <td class="pt-1 pb-0 text-right">₹{{data.sgstAmt}}</td> -->

                                <!-- <td *ngIf="state1 !== 'Maharashtra'" 
                                 class="pt-1 pb-0 text-right">{{data.igst}}%</td> -->
                                  
                                 <td class="labelStyle"> 
                                  <label *ngIf="state1 !== 'Maharashtra'; else elseBlock">{{data.igst}}%</label>
                                <ng-template #elseBlock>
                                  <label>-</label>
                                </ng-template>
                                  </td>
                                  <td class="labelStyle">
                                    <label *ngIf="state1 !== 'Maharashtra'; else elseBlock">₹{{data.igstAmt}}</label>
                                  <ng-template #elseBlock>
                                    <label>-</label>
                                  </ng-template>
                                    </td>
                                    <td *ngIf="state1 == 'Maharashtra'" class="labelStyle">
                                     ₹{{data.cgstAmt + data.sgstAmt}}
                               
                                      </td>
                                      <td *ngIf="state1 !== 'Maharashtra'" class="labelStyle">
                                        ₹{{data.igstAmt}}
                                         </td>

                                    

                                    <!-- <td *ngIf="data.cgst !== null && data.sgst !== null" 
                                    class="pt-1 pb-0">₹{{data.cgstAmt + data.sgstAmt}}
                                      </td> -->
                                <!-- <td *ngIf="data.cgst == null && data.sgst == null && data.igst !== null" 
                                 class="pt-1 pb-0 text-right">₹{{data.igstAmt}}</td>

                                <td *ngIf="data.cgst !== null && data.sgst !== null"  class="pt-1 pb-0 text-right">-</td>
                                <td *ngIf="data.cgst !== null && data.sgst !== null" class="pt-1 pb-0 text-right">-</td>

                                <td *ngIf="data.cgst !== null && data.sgst !== null" 
                                class="pt-1 pb-0">₹{{data.cgstAmt + data.sgstAmt}}
                                  </td>
                                  <td *ngIf="data.cgst == null && data.sgst == null && data.igst !== null" 
                                  class="pt-1 pb-0">₹{{data.igstAmt}}</td> -->
                               </tr>
                               <tr role="row"
                               class="odd text-center"> 
                               <td class="pt-1 pb-0">Total</td>
                              
                                <td class=" pt-1 pb-0">{{totalRate}}</td>
                             <!-- <td class=" pt-1 pb-0 text-right">- </td> -->
                             <!-- <td class=" pt-1 pb-0 text-right">₹{{totalCgst}}</td>
                             <td class="pt-1 pb-0 text-right">-</td> -->
                                  <!-- <td class="pt-1 pb-0 text-right">₹{{totalSgst}}</td>
                                  <td class="pt-1 pb-0 text-right">-</td> -->
                                  <td class="pt-1 pb-0 text-right">-</td>
                                  <td class="labelStyle">
                                    <label *ngIf="state1 == 'Maharashtra'; else elseBlock">₹{{totalCgst}}</label>
                                  <ng-template #elseBlock>
                                    <label>-</label>
                                  </ng-template>
                                    </td>
                                    <td class="pt-1 pb-0 text-right">-</td>
                                  <td class="labelStyle">
                                    <label *ngIf="state1 == 'Maharashtra'; else elseBlock">₹{{totalSgst}}</label>
                                  <ng-template #elseBlock>
                                    <label>-</label>
                                  </ng-template>
                                    </td>
                                    <td class="pt-1 pb-0 text-right">-</td>
                                    <td class="labelStyle">
                                      <label *ngIf="state1 !== 'Maharashtra'; else elseBlock">₹{{totalIgst}}</label>
                                    <ng-template #elseBlock>
                                      <label>-</label>
                                    </ng-template>
                                      </td>
                                      <!-- <td class="pt-1 pb-0 text-right">-</td> -->
                                  <!-- <td *ngIf="totalCgst == 0 && totalSgst == 0 && totalIgst !== 0"
                                   class="pt-1 pb-0 text-right">₹{{totalIgst}}</td> -->

                           

                                  <!-- <td *ngIf="totalCgst !== 0 && totalSgst !== 0" 
                                  class="pt-1 pb-0 text-right">-</td> -->
                                  <td class="pt-1 pb-0">₹{{totalGST}}</td>
                                 </tr>

                        </tbody>
                          </table>
                          <table id="toBillingLst"
                          class="table table-hover col-sm-12 table-bordered">
                            <thead>
                              <tr class="text-center">
                                  <th class=" pt-1 pb-1">Receipt</th>
                                  <th class=" pt-1 pb-1">Date</th>
                                  <th class=" pt-1 pb-1">Amount</th>
                                  <!-- <th class=" pt-1 pb-1">Ref Number</th> -->
                                 <th class=" pt-1 pb-1">Payment Mode</th>
                          
                              </tr>
                          </thead> 
                        
                          <tbody >
                            <tr *ngFor="let item of paymentHistoryList"
                            class="text-center">
                             <td class="pt-1 pb-0">{{item.receiptNo}}</td>     
                           <td class=" pt-1 pb-0">{{item.createdAt | date:'dd/MM/yyyy'}}</td>
                           <td class=" pt-1 pb-0">₹{{item.paidAmount}}</td>
                           <!-- <td class=" pt-1 pb-0">-</td> -->
                           <td class="pt-1 pb-0">{{item.modeOfPayment}}</td>
                               </tr>
                             
                        </tbody>
                          </table>
                          <div class="col-sm-9 maxTable pr-2 pb-0 pt-0 pl-0">
                            <table id="toBillingLst"
                            class="table p-0 table-hover col-sm-6 maxTable table-bordered">
                         
                           <tbody >
                            <tr role="row"
                             class="odd text-left">
                             <td class="pt-1 pr-0 mr-0 pl-0 ml-0 pb-0">
                              <label class="mb-3 mt-2 pl-2">
                                <b>Terms & Conditions:</b>
                              </label>
                              <div class="row pr-0 mr-0 pl-0 ml-0 col-sm-12">
                                <div class="col-sm-12 mt-4">
                                  <label class="pb-0 mb-0 col-sm-12 lableSpace">
                                    - Goods once sold will not be taken back,Exchanged or Replaced.</label>
                                </div>
                                <div class="col-sm-12 mt-4">
                                  <label class="pb-0 mb-0 lableSpace col-sm-12">
                                    - Amount once paid will not be refunded.</label>
                                </div>
                                <div class="col-sm-12 mt-4">
                                  <label class="pb-0  mb-0 lableSpace col-sm-12">
                                    - Received goods in good condition and to the satisfaction.</label>
                                </div>
                                <div class="col-sm-12 mt-4">
                                  <label class="pb-0 mb-0 lableSpace col-sm-12">
                                    - After-sales service, if needed, to be rendered by respective 
                                    manufactures themselves as per their warranty terms & conditions.
                                  </label>
                                </div>
                              
                                <div class="col-sm-12 mt-4">
                                  <label class="pb-0 mb-0 lableSpace col-sm-12">
                                    - Any issue or discrepancy pertaining to this Invoice shall be reported  
                                    within 2 days.No changes will be made thereafter.
                                  </label>
                                </div>
                             

                                <div class="col-sm-12 mt-4">
                                  <label class="pb-0 mb-0 lableSpace col-sm-12">
                                    - Interest of 24% per annum will be charged for overdue amount.
                                  </label>
                                </div>

                                <div class="col-sm-12 mt-4">
                                  <label class="pb-0 lableSpace mb-0 col-sm-12">
                                    - Subject to Pune Jurisdiction only
                                  </label>
                                </div>

                               
                              </div>
                              <!-- <label class="pb-0 mt-2 mb-0">
                                - Received goods in good condition and to the satisfaction.</label> <br><br>
                                <label class="pb-0 mt-2 mb-0">
                                     - After-sales service, if needed, to be rendered by respective manufactures  
                                     </label>
                                     <label class="pb-0 mt-2 mb-0">
                                &nbsp;&nbsp;themselves as per their warranty terms & conditions. 
                                </label>
                                <br><br>
                                <label class="pb-0 mt-2 mb-0">
                                  - Any issue or discrepancy pertaining to this Invoice shall be reported within 
                                  </label>
                                  <label class="pb-0 mt-2 mb-0">
                             &nbsp;&nbsp;2 days.No changes will be made thereafter.
                             </label>
                             <br><br>

                                  <label class="pb-0 mt-2 mb-0">
                                - Interest of 24% per annum will be charged for overdue amount. 
                                </label><br><br>
                                <label class="pb-0 mt-2 mb-0">
                                - Subject to {{taluka}} Jurisdiction only
                                </label><br><br>  -->

                              
                             </td>     
                        
                               </tr>
                               <tr role="row"
                               class="odd text-right">
                               <td class="pt-1 pb-0 auto-style4"><br> 
                                <br><br>   <br><br>
                                <label class="pb-0 mt-2 mb-0">Receiver’s Signature</label>
                             
                                </td>     
                           
                                 </tr>
                                
                        </tbody>
                          </table>
                   </div>
                   <div class="col-sm-3 pr-0 maxTable pb-0 pt-0">
                        <table id="toBillingLst"
                        class="table p-0 table-hover maxTable col-sm-6 table-bordered">
                         <tbody >
                          <!-- <tr role="row"
                           class="odd text-left">
                           <td class="pt-1 pb-0">
                            <label class="mb-3 mt-2">
                              <b>BANK Details :</b>
                            </label>

                             <br>
                           &nbsp; &nbsp;
                           <label class="mb-4">{{bankName}}, {{bankBranchName}}</label>
                          <br>
                           &nbsp; &nbsp;
                           <label class="mb-4">Branch A/c Number: {{accountNumber}}</label>
                           <br>
                           &nbsp; &nbsp;
                           <label class="mb-4">IFSC Code: {{ifcCode}}</label>
                           <br>

                           &nbsp; &nbsp;
                           <label class="mb-4 trans">UPI Id: {{upiId}}</label>
                           
                           </td>     
                             </tr> -->
                             <tr role="row"
                             class="odd ">
                             <td colspan="2" class="pt-1 pb-0 p-2">
                              <div class="col-sm-12 mt-4 p-0">
                                <label class="pb-0 mb-0 p-0 lableSpace text-left col-sm-12">
                                  It is certified that the particulars
                                 given above are true and correct
                                </label>
                              </div>

                             
                                <br>  <br><br><br>
                                <br> <br><br><br>
                                <br>  <br><br><br><br>
                                <!-- <div class="row mt-2">
                                  <div class="col-sm-8"></div>
                                  <div class="col-sm-2 justify-content-left">
                                    <label class="pb-0 mt-2 justify-content-center text-center pl-3 mb-0">For</label><br>
                                  
                                  </div>
                                  <div class="col-sm-2 justify-content-left"></div>
                             </div>
                                <div class="row">
                                  <div class="col-sm-6"></div>
                                  <div class="col-sm-6 justify-content-left">
                                   <label class="pb-0 mt-2 justify-content-left mb-0">{{reportName}}</label>
                                  </div>
                             
                             </div> -->
                             </td>     
                         
                               </tr>

                               <tr role="row"
                               class="odd ">
                               <td class="pt-1 pb-0 auto-style4 p-0"><br> 
                                <br><br>   <br><br>
                                <div class="col-sm-12 row text-center">
                                  <div class="col-sm-4">

                                  </div>
                                  <div class="col-sm-4">
                                    <label class="pb-0 mt-2 mb-0 text-center">For</label>
                                  </div>
                                  <div class="col-sm-4">

                                  </div>
                                  <div class="col-sm-12 p-0 text-center">
                                    <label class="pb-0 mt-2 mb-0 p-0 text-center">{{reportName}}</label>
                                  </div>
                                </div>
                               
                               
                             
                                </td>     
                           
                                 </tr>
                              
                      </tbody>
                        </table>
                  </div>
                  
                  </div>
                  
                    
                 </div>




                                   <div class="col-sm-10"></div> 
                                   <div class="col-sm-2 mt-2">
                                     <button mat-dialog-close class="btn btn-primary btn-sm btnCol example-full-width">Close</button>
                                   </div>
                               
                        </div>
    
            </div>
        </div>
</form>


    </div>
  </div>