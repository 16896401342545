import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { ApiServiceService } from "../api-service.service";
import { MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner"; 
import { HttpErrorResponse } from "@angular/common/http";
@Component({
  selector: 'app-role-master',
  templateUrl: './role-master.component.html',
  styleUrls: ['./role-master.component.scss']
})
export class RoleMasterComponent implements OnInit {

  errormessage: string;
  searchText: any;
  size: number = 10;
  page: number = 1;
  rolesList: any;
  totalLength: any;
  did: any;
  userType: string;
  clinicRef: string;
  clinicId: string;
  preRoleName = "";
  bId: any;
  constructor(
    private fb: FormBuilder,
    public apimicroservice: ApiServiceService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService
  ) { }
  roleMasterForm: FormGroup;
  status: any;
  submitButton = true;
  updateButton = false;
  deleteButton = false;
  cancelButton=false;
  loggedId: any;
  date: any;
  userPermission:any;
 roleCreate= true;
 roleUpdate= true;

  ngOnInit() {
    this.page=1;
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.clinicId ='622b1d4e39a66ac7fd3ada1c';
    this.submitButton = true;
    this.updateButton = false;
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    this.userType = sessionStorage.getItem('userType');
    if(this.userType !== 'Super Admin'){
    this.userPermission.forEach((element) => {
      if(element.pageName == 'Role' && element.create == false)
      {
          this.roleCreate = false;  
      }
      if(element.pageName == 'Role' && element.update == false)
      {
          this.roleUpdate = false;  
      }  
    });
  }
    this.roleMasterForm = this.fb.group({

      "roleId": new FormControl(''),
      "roleName": new FormControl('',[Validators.required, Validators.pattern("^[a-zA-Z ]*$")]),
      "clinicRefId": new FormControl(),
      "createdAt": this.date,
      "searchRole": new FormControl(''),
    });
    this.fetchRoleList();
    this.getLastId();
   // this.removeValidator();
  }

  // setValidator() { 
  //   this.roleMasterForm.controls['roleName'].setValidators([Validators.required,Validators.pattern("^[a-zA-Z ]*$")]);
  //   this.roleMasterForm.controls['roleName'].updateValueAndValidity();
  // }

  // removeValidator() { 
  //   this.roleMasterForm.controls['roleName'].setErrors(null);
  //   this.roleMasterForm.controls['roleName'].clearValidators();
  //   this.roleMasterForm.controls['roleName'].updateValueAndValidity();
  // }




  prepopulation(id) {
    this.spinner.show();
    this.apimicroservice.getRoleById(id).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        let data = res.data[0];
        this.roleMasterForm = this.fb.group({
          "roleId": new FormControl(data.roleId),
          "roleName": new FormControl(data.roleName, [Validators.required, Validators.pattern("^[a-zA-Z ]*$")]),
          "clinicRefId": new FormControl(data.clinicRefId),
          "updatedAt": new Date(),
        });
        this.did = id;
        this.bId=data.roleId;
        this.preRoleName = data.roleName;
        this.updateButton = true;
        this.submitButton = false;
        this.cancelButton=true;
        this.roleName();
      } else {
       // this.apimicroservice.openSnackbar(res.message);
      }
    });
  }
  saveRole() {

   // this.setValidator();
  
   
    if (this.roleMasterForm.invalid) {
      this.roleMasterForm.get("roleName").markAsTouched();
      return;
    } else {
      this.addRole();
    }
  }
  update() {
   // this.setValidator();
    if (this.roleMasterForm.invalid) {
      this.roleMasterForm.get("roleName").markAsTouched();
      return;
    } else {
      this.updateRole();
    }
  }

  updateRole() {
    let roleType = "";
    let payload = this.roleMasterForm.value;
    roleType = this.roleMasterForm.get('roleName').value;
    
    if(roleType.toLowerCase() !== this.preRoleName.toLowerCase()){
      this.apimicroservice.getRoleNameExist(payload).subscribe(res => {
        //
        if(res.data.length > 0){
          this.apimicroservice.openSnackbar(res.message);
        }else{
          this.spinner.show();
    // let payload = this.roleMasterForm.value;
    if(this.userType !== 'Super Admin')
    {
      
      payload.clinicRefId = this.clinicRef;
     
    }else{
      payload.clinicRefId =  this.clinicId;
    }
    
    this.apimicroservice.editRoleData(this.did, payload).subscribe(res => {
      this.spinner.hide();
      this.ngOnInit();
      this.updateButton = false;
      this.submitButton = true;
      this.cancelButton=false;
      if (res.status) {
        this.apimicroservice.openSnackbar(res.message);
      
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    },(ERROR:HttpErrorResponse) => {
      this.apimicroservice.openSnackbar(ERROR.error.message);
     this.spinner.hide();
  });
        }
      });
    }else{
      this.spinner.show();
      // let payload = this.roleMasterForm.value;
      if(this.userType !== 'Super Admin')
      {
        
        payload.clinicRefId = this.clinicRef;
       
      }else{
        payload.clinicRefId =  this.clinicId;
      }
      
      this.apimicroservice.editRoleData(this.did, payload).subscribe(res => {
        this.spinner.hide();
        this.ngOnInit();
        this.updateButton = false;
        this.submitButton = true;
        this.cancelButton=false;
        if (res.status) {
          this.apimicroservice.openSnackbar(res.message);
        
        } else {
          this.apimicroservice.openSnackbar(res.message);
        }
      },(ERROR:HttpErrorResponse) => {
        this.apimicroservice.openSnackbar(ERROR.error.message);
       this.spinner.hide();
    });
    }

  }

  addRole() {
    this.spinner.show();
    let payload = this.roleMasterForm.value;
    if(this.userType !== 'Super Admin')
    {
    
      payload.clinicRefId = this.clinicRef;
      
    }else{
      payload.clinicRefId =  this.clinicId;
    }
   
    this.apimicroservice.saveRolesData(payload).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.apimicroservice.openSnackbar(res.message);
        this.ngOnInit();
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    },(ERROR:HttpErrorResponse) => {
      this.apimicroservice.openSnackbar(ERROR.error.message);
     this.spinner.hide();
  });
  }

  fetchRoleList() {
    let pg = 1;
    let size = 10;
    let search='';
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.spinner.show();
    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getAllRoles(pg, size, search,clinicRef).subscribe(res => {
        this.spinner.hide();
        if (res.status) {
          //
          
          this.rolesList = res.data.data;
          this.totalLength = res.data.totalItems;
           
        } else {
          //this.apimicroservice.openSnackbar(res.message);
        }
      })
    } else {
      this.apimicroservice.getAllRoles(pg, size, search,clinicRef).subscribe(res => {
        this.spinner.hide();
        if (res.status) {
          //
          
          this.rolesList = res.data.data;
          this.totalLength = res.data.totalItems;
           
        } else {
          //this.apimicroservice.openSnackbar(res.message);
        }
      })
    }
   
  }

  addNew() {
    this.roleMasterForm.reset();
    this.updateButton = false;
    this.deleteButton = false;
  }

  getLastId()
  {
    this.apimicroservice.getLastRoleID().subscribe(res => {
      //   
      if(res['data']['data'] == '')
      {
        this.roleMasterForm.controls['roleId'].setValue('1');
      }
      if (res['status']) {
       // let roleIdd = res['data'][0].roleId;
        let roleIdd = res['data']['totalItems'];
        this.bId=roleIdd + 1;
        this.roleMasterForm.controls['roleId'].setValue(roleIdd + 1);
      } else {
        this.apimicroservice.openSnackbar("Something went wrong");
      }
     
    })
  }


  // deleteRole(id) {
  //   this.apimicroservice.deleteRoleData(id).subscribe((res) => {
  //     if (res.status) {
  //       this.apimicroservice.openSnackbar(res.message);
  //       this.ngOnInit();
  //     } else {
  //       this.apimicroservice.openSnackbar(res.message);
  //     }
  //   });
  // }

  onPageChange(event) {
    this.spinner.show();
    this.page = event;
    let p = this.page;
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.searchText = this.roleMasterForm.get("searchRole").value;
    if (this.userType !== 'Super Admin'){
      this.apimicroservice.getAllRoles(p, this.size, this.searchText,clinicRef).subscribe((res) => {
        this.spinner.hide();
        if (res.status) {
          this.rolesList = res.data.data;
          this.totalLength = res.data.totalItems;
        } else {
          //this.apimicroservice.openSnackbar(res.message);
        }
      });
    } else {
      this.apimicroservice.getAllRoles(p, this.size, this.searchText,clinicRef).subscribe((res) => {
        this.spinner.hide();
        if (res.status) {
          this.rolesList = res.data.data;
          this.totalLength = res.data.totalItems;
        } else {
         // this.apimicroservice.openSnackbar(res.message);
        }
      });
    }
    
  }
  onKeyUpEvent(event: any) {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.searchText = this.roleMasterForm.get("searchRole").value;
    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getAllRoles(this.page, this.size, this.searchText,clinicRef).subscribe((res) => {
        if (res.status) {
          this.rolesList = res.data.data;
          this.totalLength = res.data.totalItems;
        } else {
         // this.apimicroservice.openSnackbar(res.message);
        }
      });
    } else {
      this.apimicroservice.getAllRoles(this.page, this.size, this.searchText,clinicRef).subscribe((res) => {
        if (res.status) {
          this.rolesList = res.data.data;
          this.totalLength = res.data.totalItems;
        } else {
         // this.apimicroservice.openSnackbar(res.message);
        }
      });
    }
   
  }

  cancelUpdate(){
    this.roleMasterForm.reset();
    this.submitButton=true;
    this.updateButton=false;
    this.cancelButton=false;
    this.ngOnInit();
  }
  toRoleForm() {
    document.getElementById("toRoleForm").scrollIntoView();
  }
  toRoleList() {
    document.getElementById("toRoleList").scrollIntoView();
  }
  roleName()
  {
    let payload = this.roleMasterForm.value;
    let str1 = 'Super Admin';
    if(str1.toLowerCase() === payload.roleName.toLowerCase())
    {
      this.roleMasterForm.controls["roleName"].setValue('');
      this.apimicroservice.openSnackbar('Super Admin Is Not a Role');
    } // true
  }
 
  onback() {
    this.apimicroservice.back()
  }
}