import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
 import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { AfterViewInit, Directive, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { PopUpBranchComponent } from "../pop-up-branch/pop-up-branch.component";
import { PopUpCityComponent } from "../pop-up-city/pop-up-city.component";
import { PopUpItemComponent } from "../pop-up-item/pop-up-item.component";
import { PopUpCourierserviceComponent } from "../pop-up-courierservice/pop-up-courierservice.component";
import { ElementRef } from '@angular/core';
import SignaturePad from 'signature_pad';
import { PopUpPatientComponent } from "../pop-up-patient/pop-up-patient.component";
import { PatientSearchPopComponent } from "../patient-search-pop/patient-search-pop.component";
import { ActivatedRoute } from "@angular/router";
@Component({
  selector: 'app-inward-outward',
  templateUrl: './inward-outward.component.html',
  styleUrls: ['./inward-outward.component.scss']
})
export class InwardOutwardComponent implements OnInit {
  title = 'signatureJS';
  signaturePad: SignaturePad; 
  @ViewChild('canvas') canvasEl: ElementRef;
  signatureImg: string;
  errormessage: string;
  bId: any;
  inwardOutwardId: string;
  inwardOutwardBranch: string;

  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
  }

  startDrawing(event: Event) {
    //
    // works in device not in browser

  }

  moved(event: Event) {
    // works in device not in browser
  }

  clearPad() {
    this.signaturePad.clear();
  }
 
  savePad() {
    const base64Data = this.signaturePad.toDataURL();
    this.signatureImg = base64Data;
  }
  inwardOutwardForm: FormGroup;
  date: any;
  listOfAppointment:any;
  totalLength:any;
  appointmentId: any;
  size=10;
  page=1;
  searchText: any;
  submitButton = true;
  updateButton = false;
  allColor: any;
  
  allExpensesType=[
    {name:'Telephone Expenses', value:'Telephone Expenses'},
    {name:'Travelling Expenses', value:'Travelling Expenses' },
    {name:'Office Equipment and Supplies',value:'Office Equipment and Supplies'},
     { name:'Electricity / Utility',value:'Electricity / Utility' },
     { name:'Property Tax', value:'Property Tax' },
      {name:'Legal Expenses', value:'Legal Expenses'},
      { name:'Bank Charges', value:'Bank Charges' },
       {name:'Repair and Maintenance Expenses', value:'Repair and Maintenance Expenses'},
       { name:'Insurance Expenses',value:'Insurance Expenses'},
        { name:'Advertising Expenses', value:'Advertising Expenses'},
        { name:'Rent',value:'Rent' },
         {name:'Courier charges',value:'Courier charges' }, 
  ]
  
  
  filteredList: { name: string; value: string; }[];
  filteredListAppoinmentType: { name: string; value: string; }[];
  userType: string;
  branchRef: string;
  clinicRef: string;
  BranchList: any;
  isSuperAdmin= true;
  superBranchId: any;
  branchName: string;
  filteredListBranch: any;
  currentdate: any;
  listOfInwardOutward: any;
  id: any;
  courierList: any;
  filteredListCourier: any;
  disableFromCity = false;
  disableToCity = true;
  tocityList: any;
  cityList: any;
  fromCity: any;
  toCity: any;
  courierServiceCreate = true;
  branchCreate = true;
  filteredCityList: any;
  filteredToCityList: any;
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
     public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute
  ) { }
  userPermission:any;
  inwardOutwardCreate= true;
  inwardOutwardUpdate= true;

  ngOnInit(): void {
   let date=new Date();
    this.currentdate =this.datepipe.transform(date, 'dd/MM/yyyy');
   //
   
    this.page=1;
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.userType = sessionStorage.getItem('userType');
    this.filteredList = this.allExpensesType.slice();
    this.userType = sessionStorage.getItem('userType');
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    if(this.userType !== 'Super Admin'){
      this.isSuperAdmin= false;
    this.userPermission.forEach((element) => {
      if(element.pageName == 'Inward Outward' && element.create == false)
      {
          this.inwardOutwardCreate = false;  
      }
      if(element.pageName == 'Inward Outward' && element.update == false)
      {
          this.inwardOutwardUpdate = false;  
      }  
      if(element.pageName == 'Courier Service Provider' && element.create == false)
      {
          this.courierServiceCreate = false;  
      } 
      if(element.pageName == 'Branch' && element.create == false)
      {
          this.branchCreate = false;  
      } 
    });
  }
  this.inwardOutwardId = this.route.snapshot.paramMap.get('inwardOutwardId'); 
  this.inwardOutwardBranch = this.route.snapshot.paramMap.get('branchName'); 
  this.disableFromCity = false;
  this.disableToCity = true;
    this.inwardOutwardForm = this.fb.group({
      "branchWiseId": new FormControl(''),
      "date": new FormControl('',Validators.required),
      "inwardOutward": new FormControl('',Validators.required),
      "fromInwardOutward": new FormControl('',Validators.required),
      "toInwardOutward": new FormControl('',Validators.required),
      "fromCityName": new FormControl(''),
      "toCityName": new FormControl(''),
      "docketNo": new FormControl('',[Validators.pattern("^[0-9]*$")]), 
      "branchRefId": new FormControl(''),
      "item": new FormControl('',Validators.required),
      "branchName": new FormControl(''),
      "courierRefId": new FormControl('',Validators.required), 
      "courierProviderName": new FormControl(''), 
      "clinicRefId": new FormControl(''), 
      "remark": new FormControl(''), 
      "Signaturephoto": '', 
      "searchInwardOutward": new FormControl(''), 
      "reciverName": new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z ]*$")]),
      "patientName": new FormControl('',Validators.required),
      "patientRefId": new FormControl(),
    });
  this.inwardOutwardForm.controls['date'].setValue(this.currentdate);
  this.inwardOutwardForm.controls['inwardOutward'].setValue("Inward");

 // this.removeValidator();
  this.fetchFromCityList();
  this.fetchBranchList();
  this.getLastId();
  this.fetchInwardOutward();
  this.fetchCourierList();
  this.getAllBranch();
  if(this.inwardOutwardId !== '' && this.inwardOutwardId !== undefined
  && this.inwardOutwardId !== null) 
  {
    this.prepopulationByBranchId(this.inwardOutwardId,this.inwardOutwardBranch);
  }
}

canvasClick()
{
 this.signatureImg = "";
 
 //
 
}
getAllBranch()
{
  let clinicRef= sessionStorage.getItem('clinicRef');
  this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
    if (res.status) {        
      let length =res.data.length - 1;
      this.branchName =res.data[length].branchName;
      this.inwardOutwardForm.controls["branchRefId"].setValue(res.data[length]._id);
      this.branchClick(res.data[length]._id,this.branchName);
      if(this.userType == 'Super Admin')
      {  
        this.apimicroservice.getBranchById(res.data[length]._id).subscribe(res => {
          this.spinner.hide();
          this.inwardOutwardForm.get("branchName").setValue(res.data[length].branchName);
         });
      }
     
    }
  });
}
  // setValidator() { 
  //   this.inwardOutwardForm.controls['date'].setValidators(Validators.required);
  //   this.inwardOutwardForm.controls['date'].updateValueAndValidity();

  //   this.inwardOutwardForm.controls['inwardOutward'].setValidators(Validators.required);
  //   this.inwardOutwardForm.controls['inwardOutward'].updateValueAndValidity();

  //   this.inwardOutwardForm.controls['fromInwardOutward'].setValidators(Validators.required);
  //   this.inwardOutwardForm.controls['fromInwardOutward'].updateValueAndValidity();

  //   this.inwardOutwardForm.controls['toInwardOutward'].setValidators(Validators.required);
  //   this.inwardOutwardForm.controls['toInwardOutward'].updateValueAndValidity();

  //   this.inwardOutwardForm.controls['courierRefId'].setValidators(Validators.required);
  //   this.inwardOutwardForm.controls['courierRefId'].updateValueAndValidity();

  //   this.inwardOutwardForm.controls['item'].setValidators(Validators.required);
  //   this.inwardOutwardForm.controls['item'].updateValueAndValidity();

  // }

  // removeValidator() { 
  //   this.inwardOutwardForm.controls['date'].setErrors(null);
  //   this.inwardOutwardForm.controls['date'].clearValidators();
  //   this.inwardOutwardForm.controls['date'].updateValueAndValidity();

  //   this.inwardOutwardForm.controls['inwardOutward'].setErrors(null);
  //   this.inwardOutwardForm.controls['inwardOutward'].clearValidators();
  //   this.inwardOutwardForm.controls['inwardOutward'].updateValueAndValidity();

  //   this.inwardOutwardForm.controls['fromInwardOutward'].setErrors(null);
  //   this.inwardOutwardForm.controls['fromInwardOutward'].clearValidators();
  //   this.inwardOutwardForm.controls['fromInwardOutward'].updateValueAndValidity();

  //   this.inwardOutwardForm.controls['toInwardOutward'].setErrors(null);
  //   this.inwardOutwardForm.controls['toInwardOutward'].clearValidators();
  //   this.inwardOutwardForm.controls['toInwardOutward'].updateValueAndValidity();

  //   this.inwardOutwardForm.controls['courierRefId'].setErrors(null);
  //   this.inwardOutwardForm.controls['courierRefId'].clearValidators();
  //   this.inwardOutwardForm.controls['courierRefId'].updateValueAndValidity();

  //   this.inwardOutwardForm.controls['item'].setErrors(null);
  //   this.inwardOutwardForm.controls['item'].clearValidators();
  //   this.inwardOutwardForm.controls['item'].updateValueAndValidity();

  // }

  submitInwordOutword(){
   // this.setValidator();
    let payload = this.inwardOutwardForm.value;
    
    if(this.userType == 'Super Admin')
    {
      this.inwardOutwardForm.controls['branchRefId'].setValidators(Validators.required);
      this.inwardOutwardForm.controls['branchRefId'].updateValueAndValidity();
    }
    else{
      this.inwardOutwardForm.controls['branchRefId'].setErrors(null);
      this.inwardOutwardForm.controls['branchRefId'].clearValidators();
      this.inwardOutwardForm.controls['branchRefId'].updateValueAndValidity();
    }
    
    if (this.inwardOutwardForm.invalid) {
      this.inwardOutwardForm.get("reciverName").markAsTouched();
       this.inwardOutwardForm.get("date").markAsTouched();
       this.inwardOutwardForm.get("inwardOutward").markAsTouched();
       this.inwardOutwardForm.get("fromInwardOutward").markAsTouched();
       this.inwardOutwardForm.get("toInwardOutward").markAsTouched();
       this.inwardOutwardForm.get("courierRefId").markAsTouched();
       this.inwardOutwardForm.get("item").markAsTouched();
       this.inwardOutwardForm.get("patientName").markAsTouched();

    }else {
      this.addData();
    }
  }
  
  
  addData(){
    this.spinner.show();
    let payload = this.inwardOutwardForm.value;
    payload.fromCityName = this.fromCity;
    payload.toCityName = this.toCity;
    payload.Signaturephoto = this.signatureImg; 
    if(this.userType !== 'Super Admin')
    {
      payload.branchRefId = this.branchRef;
      payload.clinicRefId = this.clinicRef;
      payload.branchName = this.branchName;
    }
   //
    
    this.apimicroservice.addInwardOutward(payload).subscribe(res => {
      this.spinner.hide();
      this.ngOnInit();
      this.signatureImg = "";
      this.signaturePad.clear();
      if(res.status){
      //  this.signatureImg = "../../assets/user2.png";
        this.apimicroservice.openSnackbar(res.message);
        
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    },(ERROR:HttpErrorResponse) => {
      this.apimicroservice.openSnackbar(ERROR.error.message);
     //
     this.spinner.hide();
  });
  }
  getLastId()
  {
    if(this.userType !== 'Super Admin'){
    this.apimicroservice.getInwordOutWordLastID(this.branchRef).subscribe(res => {
      //   
      if (res['status']) {
        let clientIdd = res['data']['totalItems'];
        
      //  this.bId=clientIdd+1;
        this.inwardOutwardForm.controls['branchWiseId'].setValue(clientIdd + 1);
      } else {
        this.apimicroservice.openSnackbar("Something went wrong");
      }
     
    })
  }
  else
  {
    this.apimicroservice.getLastInwardOutwardAll().subscribe(res => {
      //   
      if (res['status']) {
        let clientIdd = res['data']['totalItems'];
        this.superBranchId = clientIdd + 1;
        //this.bId =clientIdd + 1;
      } else {
        this.apimicroservice.openSnackbar("Something went wrong");
      }
     
    })
  }
  }
  fetchInwardOutward() {
    let pg = 1;
    let size = 10;
    let search='';
    this.spinner.show();
    this.searchText ='';
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    
     if(this.userType !== 'Super Admin')
     {
      this.apimicroservice.getInwardOutwardList(pg,size,search,branchRef,clinicRef).subscribe(res => {
        this.spinner.hide(); 
          this.listOfInwardOutward = res.data.data;
          this.totalLength = res.data.totalItems;
         if(res.status){
          // this.apimicroservice.openSnackbar(res.message);        
         }else{
           //this.apimicroservice.openSnackbar(res.message);
         }
       });
     }
     else
     {
      this.apimicroservice.getInwardOutwardList(pg,size,search,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
        this.spinner.hide(); 
        //
          this.listOfInwardOutward = res.data.data;
          this.totalLength = res.data.totalItems;
         if(res.status){
          // this.apimicroservice.openSnackbar(res.message);        
         }else{
           //this.apimicroservice.openSnackbar(res.message);
         }
       });
     }
    
   
  }

  prepopulation(id) {
    this.spinner.show();
    this.apimicroservice.getInOutwardById(id).subscribe(res => {
      this.spinner.hide();
      let data = res.data[0]; 
      this.id = id;
       if(res.status){
         this.updateButton = true;
         this.submitButton = false;
         this.disableFromCity = true;
         this.disableToCity = true;
         this.inwardOutwardForm = this.fb.group({
          "branchWiseId": new FormControl(data.branchWiseId),
          "date": new FormControl(data.date,Validators.required),
          "inwardOutward": new FormControl(data.inwardOutward,Validators.required),
          "fromInwardOutward": new FormControl(data.fromInwardOutward,Validators.required),
          "toInwardOutward": new FormControl(data.toInwardOutward,Validators.required),
          "fromCityName": new FormControl(data.fromCityName),
          "toCityName": new FormControl(data.toCityName),
          "docketNo": new FormControl(data.docketNo,[Validators.pattern("^[0-9]*$")]), 
          "branchRefId": new FormControl(data.branchRefId),
          "item": new FormControl(data.item,Validators.required), 
          "branchName": new FormControl(data.branchName),
          "courierRefId": new FormControl(data.courierRefId,Validators.required), 
          "courierProviderName": new FormControl(data.courierProviderName),
          "clinicRefId": new FormControl(data.clinicRefId),
          "remark": new FormControl(data.remark),
          "searchInwardOutward": new FormControl(''), 
          "reciverName": new FormControl(data.reciverName, [Validators.required, Validators.pattern("^[a-zA-Z ]*$")]),
          "Signaturephoto": '', 
          "patientName": new FormControl(data.patientName,Validators.required),
          "patientRefId": new FormControl(data.patientRefId),
        });
        this.superBranchId = data.branchWiseId;
        this.bId =data.branchWiseId;
        this.signatureImg = data.Signaturephoto;
       let date =this.datepipe.transform(data.date, 'dd/MM/yyyy');
        this.inwardOutwardForm.controls['date'].setValue(date);
      //
      
       }else{
     //    //this.apimicroservice.openSnackbar(res.message);
       }
     });
  }
  prepopulationByBranchId(id,branchName) {
    this.spinner.show();
    this.apimicroservice.getInOutwardByBranchId(id,branchName).subscribe(res => {
      this.spinner.hide();
      let data = res.data[0]; 
      this.id = id;
       if(res.status){
         this.updateButton = true;
         this.submitButton = false;
         this.disableFromCity = true;
         this.disableToCity = true;
         this.inwardOutwardForm = this.fb.group({
          "branchWiseId": new FormControl(data.branchWiseId),
          "date": new FormControl(data.date,Validators.required),
          "inwardOutward": new FormControl(data.inwardOutward,Validators.required),
          "fromInwardOutward": new FormControl(data.fromInwardOutward,Validators.required),
          "toInwardOutward": new FormControl(data.toInwardOutward,Validators.required),
          "fromCityName": new FormControl(data.fromCityName),
          "toCityName": new FormControl(data.toCityName),
          "docketNo": new FormControl(data.docketNo,[Validators.pattern("^[0-9]*$")]), 
          "branchRefId": new FormControl(data.branchRefId),
          "item": new FormControl(data.item,Validators.required), 
          "branchName": new FormControl(data.branchName),
          "courierRefId": new FormControl(data.courierRefId,Validators.required), 
          "courierProviderName": new FormControl(data.courierProviderName),
          "clinicRefId": new FormControl(data.clinicRefId),
          "remark": new FormControl(data.remark),
          "searchInwardOutward": new FormControl(''), 
          "reciverName": new FormControl(data.reciverName, [Validators.required, Validators.pattern("^[a-zA-Z ]*$")]),
          "Signaturephoto": '', 
          "patientName": new FormControl(data.patientName,Validators.required),
          "patientRefId": new FormControl(data.patientRefId),
        });
        this.superBranchId = data.branchWiseId;
        this.bId =data.branchWiseId;
        this.signatureImg = data.Signaturephoto;
       let date =this.datepipe.transform(data.date, 'dd/MM/yyyy');
        this.inwardOutwardForm.controls['date'].setValue(date);
      //
      
       }else{
     //    //this.apimicroservice.openSnackbar(res.message);
       }
     });
  }

  UpdateInwordOutword(){
   // this.setValidator();
    let payload = this.inwardOutwardForm.value;
    
    if(this.userType == 'Super Admin')
    {
      this.inwardOutwardForm.controls['branchRefId'].setValidators(Validators.required);
      this.inwardOutwardForm.controls['branchRefId'].updateValueAndValidity();
    }
    else{
      this.inwardOutwardForm.controls['branchRefId'].setErrors(null);
      this.inwardOutwardForm.controls['branchRefId'].clearValidators();
      this.inwardOutwardForm.controls['branchRefId'].updateValueAndValidity();
    }
    
    if (this.inwardOutwardForm.invalid) {
      
      this.inwardOutwardForm.get("reciverName").markAsTouched();
       this.inwardOutwardForm.get("date").markAsTouched();
       this.inwardOutwardForm.get("inwardOutward").markAsTouched();
       this.inwardOutwardForm.get("fromInwardOutward").markAsTouched();
       this.inwardOutwardForm.get("toInwardOutward").markAsTouched();
       this.inwardOutwardForm.get("courierRefId").markAsTouched();
       this.inwardOutwardForm.get("item").markAsTouched();
       this.inwardOutwardForm.get("patientName").markAsTouched();
       

    }else {
      this.updateData();
    }
  }

  updateData(){
    this.spinner.show();
    let payload = this.inwardOutwardForm.value; 
    payload.fromCityName = this.fromCity;
    payload.toCityName = this.toCity;
    payload.Signaturephoto = this.signatureImg;
    if(this.userType !== 'Super Admin')
    {
      payload.branchRefId = this.branchRef;
      payload.clinicRefId = this.clinicRef;
      payload.branchName = this.branchName;
    }
  
     this.apimicroservice.updateInOutwardDetails(payload,this.id).subscribe(res => {
       //
       this.spinner.hide();
       if(res.status){
         this.apimicroservice.openSnackbar(res.message);
        // this.signatureImg = "../../assets/user2.png";
          this.updateButton = false;
          this.submitButton = true;
          this.signatureImg = "";
          this.signaturePad.clear();
          this.ngOnInit();
          
       }else{ 
       //  //this.apimicroservice.openSnackbar(res.message);
       }
     });
  }
 
  cancelUpdate(){
    this.inwardOutwardForm.reset();
    this.signatureImg =""; 
    this.submitButton=true;
    this.updateButton=false;
    this.ngOnInit();
  }
  // onChange($event: Event) {
  //   const file = ($event.target as HTMLInputElement).files[0];
  //   const fileName = file.name;
  //   if (file.size >= 3000000) {
  //    // this.apimicroservice.openSnackbar("Max upload size allowed is 3mb");
  //     this.errormessage = "Max upload size allowed is 3mb";
  //   } else {
  //     this.errormessage = '';
  //     let ext = fileName.substring(fileName.lastIndexOf(".") + 1);
  //     if (
  //       ext.toLowerCase() == "png" ||
  //       ext.toLowerCase() == "jpg" ||
  //       ext.toLowerCase() == "jpeg"
  //     ) {
  //       this.convertToBase64(file);
  //     }
  //   }
  // }
  // convertToBase64(file: File) {
  //   const observable = new Observable((subscriber: Subscriber<any>) => {
  //     this.readFile(file, subscriber);
  //   });
  //   observable.subscribe((d) => {
  //     this.patientImage = d;
      
  //   });
  // }
  // readFile(file: File, subscriber: Subscriber<any>) {
  //   const fileReader = new FileReader();
  //   fileReader.readAsDataURL(file);

  //   fileReader.onload = () => {
  //     subscriber.next(fileReader.result);
  //     subscriber.complete();
  //   };
  //   fileReader.onerror = (error) => {
  //     subscriber.error(error);
  //     subscriber.complete();
  //   };
  // }
  openSnackbar(message: string) {
    return this._snackBar.open(message, "close", {
      verticalPosition: "bottom",
      horizontalPosition: "right",
      duration: 2000,
      panelClass: ["snackbarStyle"],
    });
  }
  toInwardOutwardForm() {
    document.getElementById("toInwardOutwardForm").scrollIntoView();
  }
  toInwardOutwardLst() {
    document.getElementById("toInwardOutwardLst").scrollIntoView();
  }

   openBranchPopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpBranchComponent,
      {
        width: "1200px",
      });
      dialogRef3.afterClosed().subscribe((result) => {
        let clinicRef= sessionStorage.getItem('clinicRef');
        this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
          //  
          if (res.status) {    
            this.BranchList = res.data;
            this.filteredListBranch= this.BranchList.slice();
            this.branchClick(res.data[0]._id,res.data[0].branchName);
            this.inwardOutwardForm.get("branchRefId").setValue(res.data[0]._id);
          }
        })
        //this.fetchBranchList();
      })
  }
  fetchBranchList() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      //  
      if (res.status) {    
        this.BranchList = res.data;
        this.filteredListBranch= this.BranchList.slice();
      } else {
       // this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
  
  fetchFromCityList(){
    //let id = '';
    this.apimicroservice.getCityList().subscribe(res => {
      //
     
     this.cityList = res.data;
    //  this.filteredListBranch= this.branchList.slice();
     this.tocityList = res.data;
    //  this.filteredListToBranch= this.toBranchList.slice();
     
     if(res.status){
       //this.apimicroservice.openSnackbar(res.message);
       
     }else{
       //this.apimicroservice.openSnackbar(res.message);
     }
  });
  }

  fetchCourierList() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
    this.apimicroservice.getCourierAllData(branchRef,clinicRef).subscribe(res => {
      //  
      if (res.status) {    
        this.courierList = res.data.data;
        //
        
        this.filteredListCourier= this.courierList.slice();
      } else {
       // this.apimicroservice.openSnackbar(res.message);
      }
    })
  }else{
    this.apimicroservice.getCourierAllData(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
      //  
      if (res.status) {    
        this.courierList = res.data.data;
        //
        
        this.filteredListCourier= this.courierList.slice();
      } else {
       // this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
  }
  branchClick(id,branchName)
  {

    this.inwardOutwardForm.controls["branchName"].setValue(branchName);

   this.apimicroservice.getBranchById(id).subscribe(res => { 
     //
       
     let data = res.data[0];
     let payload = this.inwardOutwardForm.value;
      payload.clinicRefId = res.data[0].clinicRefId;
      this.inwardOutwardForm.controls["clinicRefId"].setValue(res.data[0].clinicRefId);
      if(this.updateButton == false){
        this.spinner.show();
        this.apimicroservice.getInwordOutWordLastID(payload.branchRefId).subscribe(res => {
          //   
         
          if (res['status']) {
            let doctorIdd = res['data']['totalItems'];
           // this.inwardOutwardForm.controls['branchWiseId'].setValue(doctorIdd + 1);
            this.bId=doctorIdd+1;
            //
            this.inwardOutwardForm.controls['branchWiseId'].setValue(doctorIdd + 1);
          } else {
            this.apimicroservice.openSnackbar("Something went wrong");
          }
          this.spinner.hide();
        })
      }
    });
  }
  onPageChange(event) {
    this.spinner.show();
    this.page = event;
    this.searchText = this.inwardOutwardForm.get('searchInwardOutward').value;
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    
    if(this.userType !== 'Super Admin')
    {
     this.apimicroservice.getInwardOutwardList(this.page,this.size,this.searchText,branchRef,clinicRef).subscribe(res => {
        this.spinner.hide(); 
          this.listOfInwardOutward = res.data.data;
          this.totalLength = res.data.totalItems;
       if(res.status){
        //  this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
  
     });
    }
    else{
        this.apimicroservice.getInwardOutwardList(this.page,this.size,this.searchText,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
          this.spinner.hide(); 
            this.listOfInwardOutward = res.data.data;
            this.totalLength = res.data.totalItems;
         if(res.status){
          //  this.apimicroservice.openSnackbar(res.message);
           
         }else{
           //this.apimicroservice.openSnackbar(res.message);
         }
    
       });
    }
  }
  onKeyUpEvent(event: any) {
    this.searchText = this.inwardOutwardForm.get('searchInwardOutward').value;
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    let page=1;
    let size=10;
    if(this.userType !== 'Super Admin')
    {
      //
      
      this.apimicroservice.getInwardOutwardList(page,size,this.searchText,branchRef,clinicRef).subscribe(res => {
        this.spinner.hide(); 
          this.listOfInwardOutward = res.data.data;
          this.totalLength = res.data.totalItems;
          this.page=res.data.pageNumber;
       if(res.status){
        //  this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
    }
    else{
        this.apimicroservice.getInwardOutwardList(page,size,this.searchText,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
          this.spinner.hide(); 
            this.listOfInwardOutward = res.data.data;
            this.totalLength = res.data.totalItems;
            this.page=res.data.pageNumber;
       if(res.status){
        //  this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
    }
  }
  openPopUp() {
    let ssno = this.inwardOutwardForm.get('item').value;
    //
    const dialogRef1 = this.dialog.open(PopUpItemComponent,
      {
        width: "600px",
        data: {
          serialValue: ssno
        }
      });
    dialogRef1.afterClosed().subscribe((result) => {
      let tempsrno = [];
      //
      if(result !== undefined){
        result.data.forEach(element =>{
          tempsrno.push(element.item);
          //
         })
         this.inwardOutwardForm.controls['item'].setValue(tempsrno);
      }
    });
  }
  openCourierServicePopUp(){
    const dialogRef3 = this.dialog.open(PopUpCourierserviceComponent,
      {
        width: "1200px",
      });

      dialogRef3.afterClosed().subscribe((result) => {
        let clinicRef= sessionStorage.getItem('clinicRef');
        let branchRef= sessionStorage.getItem('branchRef');
        let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
        let branchRefSuperAdmin= '';
        if (this.userType !== 'Super Admin') {
        this.apimicroservice.getCourierAllData(branchRef,clinicRef).subscribe(res => {
          //  
          if (res.status) {    
            this.courierList = res.data.data;
            //
            
            this.filteredListCourier= this.courierList.slice();
            this.courierClick( res.data.data[0]._id, res.data.data[0].serviceProviderName);
            this.inwardOutwardForm.get("courierRefId").setValue(res.data.data[0]._id);
          } 
        })
      }else{
        this.apimicroservice.getCourierAllData(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
          //  
          if (res.status) {    
            this.courierList = res.data.data;
            //
            
            this.filteredListCourier= this.courierList.slice();
            this.courierClick( res.data.data[0]._id, res.data.data[0].serviceProviderName);
            this.inwardOutwardForm.get("courierRefId").setValue(res.data.data[0]._id);
          } 
        })
      }
       // this.fetchCourierList(); 
       }); 
  }
  courierClick(id,nm)
  {
    this.inwardOutwardForm.controls["courierProviderName"].setValue(nm);
  }

  onSelectFromCity(id,bname){
    //
    //
    this.disableToCity = false;
    this.fromCity = bname;
    //this.inwardOutwardForm.controls['fromBranchName'].setValue(bname);
    this.tocityList = this.cityList;
    //this.tocityList = this.tocityList.filter(m => m._id!=id);
    this.tocityList = this.tocityList;
  }
  onSelectToCity(id,bname){
    //
    //
    this.toCity = bname;
    //this.inwardOutwardForm.controls['toBranchName'].setValue(bname);
  }

  fetchToPopupCityList(){
    //let id = '';
    let id = this.inwardOutwardForm.get('fromInwardOutward').value;
    this.apimicroservice.getCityList().subscribe(res => {
      //
    //  this.filteredListBranch= this.branchList.slice();
     this.tocityList = res.data;
     this.tocityList = this.tocityList.filter(m => m._id!=id);
    //  this.filteredListToBranch= this.toBranchList.slice();
     
     if(res.status){
       //this.apimicroservice.openSnackbar(res.message);
       
     }else{
       //this.apimicroservice.openSnackbar(res.message);
     }
  });
  }

  openFromCityPopUp() {
   
    const dialogRef3 = this.dialog.open(PopUpCityComponent,
      {
        width: "500px",
      });

      dialogRef3.afterClosed().subscribe((result) => {
        this.apimicroservice.getCityList().subscribe(res => {
          //
        
         if(res.status){
         this.cityList = res.data;
          this.tocityList = res.data;
          this.filteredCityList= this.cityList.slice();
           this.onSelectFromCity(res.data[0]._id,res.data[0].cityName)
           this.inwardOutwardForm.get("fromInwardOutward").setValue(res.data[0]._id)
         }
      });
        //this.fetchFromCityList();  
       });
  }
  openToCityPopUp(){
    
    const dialogRef3 = this.dialog.open(PopUpCityComponent,
      {
        width: "500px",
      });

      dialogRef3.afterClosed().subscribe((result) => {
        this.apimicroservice.getCityList().subscribe(res => {
          let id = this.inwardOutwardForm.get('fromInwardOutward').value;
         if(res.status){
          
           //
            this.tocityList = res.data;
            this.tocityList = this.tocityList.filter(m => m._id!=id);
            this.filteredToCityList= this.cityList.slice();
            this.onSelectToCity(res.data[0]._id,res.data[0].cityName)
            this.inwardOutwardForm.get("toInwardOutward").setValue(res.data[0]._id)
           //  this.filteredToCityList= this.toBranchList.slice();
           
         }
      });
        this.fetchToPopupCityList();  
       });
  } 
  openDepartmentPopUp() {
    const dialogRef3 = this.dialog.open(PatientSearchPopComponent, {
      width: "97%",
      height: "90%", 
    });
    dialogRef3.afterClosed().subscribe((result) => {
      let middleName;
      if(result.data.middleName == null)
      {
        middleName = '';
      }
      else{
        middleName = result.data.middleName;
      }
      this.inwardOutwardForm.get("patientName").setValue(result.data.firstName + ' ' + middleName + ' ' + result.data.lastName);
      this.inwardOutwardForm.get("patientRefId").setValue(result.data._id);
    });
  }  
  openPatientPopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpPatientComponent, {
      width: "1200px",
    });
    dialogRef3.afterClosed().subscribe((result) => {
      let clinicRef= sessionStorage.getItem('clinicRef');
      let branchRef= sessionStorage.getItem('branchRef');
      let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
      let branchRefSuperAdmin= '';
      if (this.userType !== 'Super Admin') {
      this.apimicroservice.getPatientListAll(branchRef,clinicRef).subscribe((res) => {
        if (res.status) {
          // this.patientlist = res.data.data;
          // this.filteredPatientList = this.patientlist.slice();
          this.inwardOutwardForm.get("patientName").setValue(res.data.data[0].firstName);
          this.inwardOutwardForm.get("patientRefId").setValue(res.data.data[0]._id);
        }
      });
    }else{
      this.apimicroservice.getPatientListAll(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe((res) => {
        if (res.status) {
          // this.patientlist = res.data.data;
          // this.filteredPatientList = this.patientlist.slice();
          this.inwardOutwardForm.get("patientName").setValue(res.data.data[0].firstName);
          this.inwardOutwardForm.get("patientRefId").setValue(res.data.data[0]._id);
        }
      });
    }
    });
  } 
  InwardOutwardClick(e)
  {
    let payload = this.inwardOutwardForm.value;
    if(e == 'Inward')
    {
      this.inwardOutwardForm.get("toInwardOutward").setValue('');
    }
    else if(e == 'Outward')
    {
      this.inwardOutwardForm.get("toInwardOutward").setValue('');
    }
  }
  
  onback() {
    this.apimicroservice.back()
  }
}