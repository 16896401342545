import { Component, Inject, OnInit } from "@angular/core";
import {FormGroup,FormControl,Validators,FormBuilder} from "@angular/forms";
import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Router,ActivatedRoute } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  MatSnackBar,
} from "@angular/material/snack-bar";
import { MatDialog} from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { PopupTotalBillComponent } from "../popup-total-bill/popup-total-bill.component";
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-device-notes',
  templateUrl: './device-notes.component.html',
  styleUrls: ['./device-notes.component.scss']
})
export class DeviceNotesComponent implements OnInit {
  size = 10;
  page = 1;
  totalLength: any;
  searchText: any;
  branchRef: string;
  clinicRef: string;
  userType: string;
  noteData: any;
  pId: any;
  paientdetails: any;
  patientName: any;
  patientRefId: any;
  noteDataLenght=false;
  notesId: any;
  deviceRefId: any;
  deviceName: any;
  constructor( 
    public router: Router,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    public route : ActivatedRoute,
    public dialogRef: MatDialogRef<DeviceNotesComponent>,@Inject(MAT_DIALOG_DATA) public data:any,  
  ) {dialogRef.disableClose = true;
    this.deviceRefId = data.deviceRefId;
    this.deviceName = data.deviceName;
    this.pId = data.pId;
  }  

  deviceNotesMasterForm: FormGroup;
  status: any;
  submitButton = true;
  updateButton = false;
  deleteButton = false;
  BranchList: any;

  resetButton = true;
  cancelButton = false;
  loggedId: any;
  myimage;
  date: any; 
  userPermission:any;
  notesCreate= true;
  notesUpdate= true;
  isSuperAdmin= true;
  serviceTypeArr = [];
  filteredList = [];
  filteredList1 = [];
  ngOnInit() {
    this.page=1;
    this.noteDataLenght =false;
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.userType = sessionStorage.getItem('userType');
    this.date = new Date();
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    if(this.userType !== 'Super Admin'){
      this.isSuperAdmin= false;
    this.userPermission.forEach((element) => {
      if(element.pageName == 'Notes' && element.create == false)
      {
          this.notesCreate = false;  
      }
      if(element.pageName == 'Notes' && element.update == false)
      {
          this.notesUpdate = false;  
      }  
    });
  }
    this.deviceNotesMasterForm = this.fb.group({
      // "patientId": new FormControl(''),
      "patientName": new FormControl(''),
      "patientRefId": new FormControl(''),
      "deviceRefId": new FormControl(''),
      "deviceName": new FormControl(''),
      "noteDate": new FormControl(this.date),
      "notes": new FormControl('', [Validators.required]),
      "createdAt": this.date,
      "searchNotes": new FormControl('')
    }); 
    //
    this.apimicroservice.getPatientById(this.pId).subscribe(res => { 
      this.patientName =res.data[0].firstName + ' ' + res.data[0].lastName;
      this.patientRefId = res.data[0]._id; 
      //    
   });
      // this.apimicroservice.patientGlobalSearch(this.pId).subscribe(res => {
      //   this.paientdetails = res.data[0]; 
      //   this.patientName = this.paientdetails.patientName;
      //   this.patientRefId = this.paientdetails.patientRefId; 
      //   //
        
      // });
    this.fetchNotesData();
  }

  submitNotes() {
    if (this.deviceNotesMasterForm.invalid) {
      this.deviceNotesMasterForm.get("notes").markAsTouched();
      return;
    } else {
      this.submitData();
    }
  }
  updateNotesData() {
    if (this.deviceNotesMasterForm.invalid) {
      this.deviceNotesMasterForm.get("notes").markAsTouched();
      return;
    } else {
      this.updateData();
    }
  }

  submitData() {
    this.spinner.show();
    let payload = this.deviceNotesMasterForm.value;
    payload.createdAt = new Date();
    payload.patientName = this.patientName;
    payload.patientRefId = this.patientRefId;
    payload.deviceRefId = this.deviceRefId;
    payload.deviceName = this.deviceName;
    this.apimicroservice.saveDeviceNotesData(payload).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.apimicroservice.openSnackbar(res.message);
        this.ngOnInit();
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }

    },(ERROR:HttpErrorResponse) => {
      this.apimicroservice.openSnackbar(ERROR.error.message);
     this.spinner.hide();
  });
  }
  updateData() {
    let payload = this.deviceNotesMasterForm.value;
   this.spinner.show();
   payload.updatedAt = new Date();
    this.apimicroservice.updateDeviceNotesData(this.notesId,payload).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.apimicroservice.openSnackbar(res.message);
        this.submitButton = true;
        this.updateButton = false;
        this.cancelButton=false;
        this.ngOnInit();
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    },(ERROR:HttpErrorResponse) => {
      this.apimicroservice.openSnackbar(ERROR.error.message);
     this.spinner.hide();
  });
  }

  fetchNotesData() { 
    this.spinner.show();
    this.noteData=[];
      this.apimicroservice.getDeviceNotesDataByDeviceId(this.deviceRefId,this.pId).subscribe(res => {
        this.spinner.hide(); 
        if(res.message == 'No Data Found')
        { 
          this.noteDataLenght =true;
        }
        else
        { 
          this.noteData = res.data;
          this.noteDataLenght =false;
        }
        
      //  this.totalLength = res.data.totalItems;
      }); 
  }
  prepopulation(id) {
    this.spinner.show();
    this.apimicroservice.getDeviceNotesDataById(id).subscribe(res => {
      this.submitButton = false;
      this.updateButton = true;
      this.deleteButton = true;
      this.resetButton = false;
      this.spinner.hide();
      this.cancelButton = true;
      let data = res.data[0];
      this.deviceNotesMasterForm = this.fb.group({
        "patientName": new FormControl(data.patientName),
        "patientRefId": new FormControl(data.patientRefId),
        "deviceRefId": new FormControl(data.deviceRefId),
        "deviceName": new FormControl(data.deviceName),
        "noteDate": new FormControl(data.noteDate),
        "notes": new FormControl(data.notes,[Validators.required]),
        "updatedAt": this.date,
        "searchNotes": new FormControl('')
      });
       this.notesId = data._id;
    });
  }

  onPageChange(event) {
    this.spinner.show();
    this.page = event;
    this.searchText = this.deviceNotesMasterForm.get('searchNotes').value;
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let userRef='';

      this.apimicroservice.getServiceData(this.page,this.size,this.searchText,branchRef,clinicRef).subscribe(res => {
        this.spinner.hide();
        if (res.status) {
          this.noteData = res.data.data;
          this.totalLength = res.data.totalItems;
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
  
      })
  }
  onKeyUpSearch(event: any) {
    this.searchText = this.deviceNotesMasterForm.get('searchNotes').value;
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let userRef='';

      this.apimicroservice.getServiceData(this.page,this.size,this.searchText,branchRef,clinicRef).subscribe(res => {
        //
        if (res.status) {
          this.noteData = res.data.data;
          this.totalLength = res.data.totalItems;
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
      })  
  }

  redirect(to): void {
    this.router.navigate([to]);
  }
  cancelUpdate(){  
    this.deviceNotesMasterForm.reset();
    this.submitButton=true;
    this.updateButton=false;
    this.cancelButton=false;
  }
  toServicesForm() {
    document.getElementById("toServicesForm").scrollIntoView();
  }
  toServicesList() {
    document.getElementById("toServicesList").scrollIntoView();
  }
  onClose(): void {
    this.dialogRef.close();
  }
}
