import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  FormArray 
} from "@angular/forms";
 import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute } from "@angular/router";
import { element } from "protractor";
@Component({
  selector: 'app-ear-mould-print',
  templateUrl: './ear-mould-print.component.html',
  styleUrls: ['./ear-mould-print.component.scss']
})
export class EarMouldPrintComponent implements OnInit {
  EarMouldPrintForm: FormGroup;
  date: any;
  size=5;
  page=1;
  userType: string;
  branchRef: string;
  clinicRef: string;
  BranchList: any;
  isSuperAdmin= true;
  
  headerData: any;
  reportName: any;
  reportAddress: any;
  district: any;
  taluka: any;
  state: any;
  country: any;
  reportPinCode: any;
  reportPhoneNumber: any;
  openTime: any;
  closeTime: any;
  logoimg: any;
  footerData: any;
  emailAdd: any;
  website:any;
  reportWeeklyOff: any;
  prifix: any;
  lastName: any;
  firstName: any;
  middleName: any;
  address: any;
  invoiceDate: any;
  pMobile: any;
  companyGst: any;
  bankName: string;
  bankBranchName: string;
  accountNumber: string;
  ifcCode: string;
  upiId: string;
  earMouldNo: string;
  currentdate: Date;
  branchName: string;
  companyName: any;
  companyCanNumber: any;
  companyMake: any;
  leftHardness: string;
  leftStyle: string;
  leftHAType: string;
  leftCanalLenght: string;
  leftVent: string;
  leftRemovalString: string;
  rightHardness: string;
  rightStyle: string;
  rightHAType: string;
  rightCanalLenght: string;
  rightVent: string;
  rightRemovalString: string;
  streetAddress1: any;
  taluka1: any;
  country1: any;
  district1: any;
  state1: any;
  pincode1: any;
  reportPhoneNumber1: any;
  
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
     public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute, 
  ) { }
  userPermission:any;
  repairCreate= true;
  repairUpdate= true;

  ngOnInit(): void {
    this.currentdate = new Date();
    this.page=1;
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    if(this.userType !== 'Super Admin'){
      this.isSuperAdmin= false;
    this.userPermission.forEach((element) => {
      if(element.pageName == 'Repair Print' && element.create == false)
      {
          this.repairCreate = false;  
      }
      if(element.pageName == 'Repair Print' && element.update == false)
      {
          this.repairUpdate = false;  
      }  
    });
  }
    this.EarMouldPrintForm = this.fb.group({
      "searchBilling": new FormControl(''),    
    }); 
     this.getReportHeaderGenericAllData();
     this.getReportFooterGenericAllData();
     this.earMouldNo = this.route.snapshot.paramMap.get('earMouldNo'); 
     this.prepopulation(this.earMouldNo);
} 
prepopulation(id) {
  this.apimicroservice.getEarMouldDataById(id).subscribe(res => {
    this.companyName =res.data[0].companyName;
    this.companyCanNumber =res.data[0].canNumber;
    this.companyMake =res.data[0].make;
    if(res.data[0].leftHardness == ''){ this.leftHardness ="-";}else {this.leftHardness =res.data[0].leftHardness;}
    if(res.data[0].leftStyle == ''){ this.leftStyle ="-";}else {this.leftStyle =res.data[0].leftStyle;}
    if(res.data[0].leftHAType == ''){ this.leftHAType ="-";}else {this.leftHAType =res.data[0].leftHAType;}
    if(res.data[0].leftCanalLenght == ''){ this.leftCanalLenght ="-";}else {this.leftCanalLenght =res.data[0].leftCanalLenght;}
    if(res.data[0].leftVent == ''){ this.leftVent ="-";}else {this.leftVent =res.data[0].leftVent;}
    if(res.data[0].leftRemovalString == ''){ this.leftRemovalString ="-";}else {this.leftRemovalString =res.data[0].leftRemovalString;}
  
    if(res.data[0].rightHardness == ''){ this.rightHardness ="-";}else {this.rightHardness =res.data[0].rightHardness;}
    if(res.data[0].rightHardness == ''){ this.rightStyle ="-";}else {this.rightStyle =res.data[0].rightStyle;}
    if(res.data[0].rightHAType == ''){ this.rightHAType ="-";}else {this.rightHAType =res.data[0].rightHAType;}
    if(res.data[0].rightCanalLenght == ''){ this.rightCanalLenght ="-";}else {this.rightCanalLenght =res.data[0].rightCanalLenght;}
    if(res.data[0].rightVent == ''){ this.rightVent ="-";}else {this.rightVent =res.data[0].rightVent;}
    if(res.data[0].rightRemovalString == ''){ this.rightRemovalString ="-";}else {this.rightRemovalString =res.data[0].rightRemovalString;}

     
    this.apimicroservice.getPatientById(res.data[0].patientRefId).subscribe(res => { 
      this.prifix =res.data[0].prefix;
      this.lastName =res.data[0].lastName;
      this.firstName =res.data[0].firstName;
      this.middleName =res.data[0].middleName;
      this.pMobile =res.data[0].mobile1;
      this.streetAddress1 =res.data[0].streetAddress;
      this.taluka1 =res.data[0].taluka;
      this.district1 =res.data[0].district;
      this.state1 =res.data[0].state;
      this.country1 =res.data[0].country;
      this.pincode1 =res.data[0].pincode;
      // this.address =res.data[0].streetAddress + ',' + res.data[0].taluka + ' ' + res.data[0].district + ' ' +
      //  res.data[0].state + ' ' + res.data[0].country + ',' + res.data[0].pincode;
   });
   });
 
}

printThisPage() {
let currentDate =new Date();
let date=currentDate.toLocaleDateString('en-GB');
  let repair = this.earMouldNo;
  let titleName= 'Ear Mould_'+date;
    var tempTitle = document.title;
    document.title = titleName;
    window.print();
    document.title = tempTitle;
}

redirect(to): void {
  this.router.navigate([to]);
 }
getReportHeaderGenericAllData()
{
  let clinicRef= sessionStorage.getItem('clinicRef');
  let branchRef= sessionStorage.getItem('branchRef');
  let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
  let branchRefSuperAdmin= '';
  if (this.userType !== 'Super Admin') {
  this.apimicroservice.getReportHeaderGenericAllData(branchRef,clinicRef).subscribe(res => {
    if(res.status){
      this.apimicroservice.openSnackbar(res.message);  
      this.headerData = res.data;
      //
      if(res.data.length > 0){
        this.reportName = this.headerData[0].reportName;
        this.reportAddress = this.headerData[0].reportAddress;
        this.reportWeeklyOff = this.headerData[0].reportWeeklyOff;
        this.emailAdd = this.headerData[0].reportEmail;
        this.website = this.headerData[0].reportWebsite;
        this.taluka = this.headerData[0].taluka;
        this.district = this.headerData[0].district;
        this.state = this.headerData[0].state;
        this.country = this.headerData[0].country;
        this.reportPinCode = this.headerData[0].reportPinCode;
        this.reportPhoneNumber = this.headerData[0].reportPhoneNumber;
        this.reportPhoneNumber1 = this.headerData[0].reportPhoneNumber1;
        this.openTime = this.headerData[0].openTime;
        this.closeTime = this.headerData[0].closeTime;
        this.logoimg = res.data[0].uploadLogo;
        
        this.bankName = res.data[0].bankName;
        this.bankBranchName = res.data[0].bankBranchName;
        this.accountNumber = res.data[0].accountNumber;
        this.ifcCode = res.data[0].ifcCode;
        this.upiId = res.data[0].upiId;
      }else{
        this.reportName = "";
        this.reportAddress = "";
        this.reportWeeklyOff ="";
        this.taluka = "";
        this.district = "";
        this.state = "";
        this.country = "";
        this.reportPinCode = "";
        this.reportPhoneNumber = "";
        this.reportPhoneNumber1 = "";
        this.openTime = "";
        this.closeTime = "";
        this.emailAdd = "";
        this.website = "";
        this.logoimg = "";
        this.bankName = "";
        this.bankBranchName = "";
        this.accountNumber = "";
        this.ifcCode = "";
        this.upiId = "";
      }
      
      if(this.logoimg == "" || this.logoimg == '../../assets/main.jpeg')
      this.logoimg = "../../assets/clinic_logo3.jpg"
      //
    }else{
      //this.apimicroservice.openSnackbar(res.message);
    }
  });
  }else{
    this.apimicroservice.getReportHeaderGenericAllData(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);  
        this.headerData = res.data;
        //
        if(res.data.length > 0){
          this.reportName = this.headerData[0].reportName;
          this.reportAddress = this.headerData[0].reportAddress;
          this.reportWeeklyOff = this.headerData[0].reportWeeklyOff;
          this.emailAdd = this.headerData[0].reportEmail;
          this.website = this.headerData[0].reportWebsite;
          this.taluka = this.headerData[0].taluka;
          this.district = this.headerData[0].district;
          this.state = this.headerData[0].state;
          this.country = this.headerData[0].country;
          this.reportPinCode = this.headerData[0].reportPinCode;
          this.reportPhoneNumber = this.headerData[0].reportPhoneNumber;
          this.reportPhoneNumber1 = this.headerData[0].reportPhoneNumber1;
          this.openTime = this.headerData[0].openTime;
          this.closeTime = this.headerData[0].closeTime;
          this.logoimg = res.data[0].uploadLogo;
          
          this.bankName = res.data[0].bankName;
          this.bankBranchName = res.data[0].bankBranchName;
          this.accountNumber = res.data[0].accountNumber;
          this.ifcCode = res.data[0].ifcCode;
          this.upiId = res.data[0].upiId;
        }else{
          this.reportName = "";
          this.reportAddress = "";
          this.reportWeeklyOff ="";
          this.taluka = "";
          this.district = "";
          this.state = "";
          this.country = "";
          this.reportPinCode = "";
          this.reportPhoneNumber = "";
          this.reportPhoneNumber1 = "";
          this.openTime = "";
          this.closeTime = "";
          this.emailAdd = "";
          this.website = "";
          this.logoimg = "";
          this.bankName = "";
          this.bankBranchName = "";
          this.accountNumber = "";
          this.ifcCode = "";
          this.upiId = "";
        }
        
        if(this.logoimg == "" || this.logoimg == '../../assets/main.jpeg')
        this.logoimg = "../../assets/clinic_logo3.jpg"
        //
      }else{
        //this.apimicroservice.openSnackbar(res.message);
      }
    });
  }
}
getReportFooterGenericAllData()
{
  let clinicRef= sessionStorage.getItem('clinicRef');
  let branchRef= sessionStorage.getItem('branchRef');
  let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
  let branchRefSuperAdmin= '';
  if (this.userType !== 'Super Admin') {
  this.apimicroservice.getReportFooterGenericAllData(branchRef,clinicRef).subscribe(res => {
    if(res.status){
    //  this.apimicroservice.openSnackbar(res.message);
      this.footerData = res.data;
      //
    }else{
      //this.apimicroservice.openSnackbar(res.message);
    }
  });
}else{
  this.apimicroservice.getReportFooterGenericAllData(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
    if(res.status){
    //  this.apimicroservice.openSnackbar(res.message);
      this.footerData = res.data;
      //
    }else{
      //this.apimicroservice.openSnackbar(res.message);
    }
  });
}
}

}

 