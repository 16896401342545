import { Component, OnInit } from "@angular/core";
import {
  FormGroup, 
  FormControl,
  Validators,
  FormBuilder,
  FormArray 
} from "@angular/forms";
 import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute } from "@angular/router";
import { element } from "protractor";
@Component({
  selector: 'app-repair-print',
  templateUrl: './repair-print.component.html',
  styleUrls: ['./repair-print.component.scss']
})
export class RepairPrintComponent implements OnInit {
  repairPrintForm: FormGroup;
  date: any;
  size=5;
  page=1;
  userType: string;
  branchRef: string;
  clinicRef: string;
  BranchList: any;
  isSuperAdmin= true;
  
  headerData: any;
  reportName: any;
  reportAddress: any;
  district: any;
  taluka: any;
  state: any;
  country: any;
  reportPinCode: any;
  reportPhoneNumber: any;
  openTime: any;
  closeTime: any;
  logoimg: any;
  footerData: any;
  emailAdd: any;
  website:any;
  reportWeeklyOff: any;
  prifix: any;
  lastName: any;
  firstName: any;
  middleName: any;
  address: any;
  invoiceDate: any;
  pMobile: any;
  companyGst: any;
  bankName: string;
  bankBranchName: string;
  accountNumber: string;
  ifcCode: string;
  upiId: string;
  repairNo: string;
  currentdate: Date;
  branchName: string;
  companyName: any;
  companyCanNumber: any;
  companyMake: any;
  rightModel: any;
  rightSrNo: any;
  leftModel: any;
  leftSrNo: any;
  leftWarranty: any;
  rightWarranty: any;
  rightDateOfPurchase: any;
  leftDateOfPurchase: any;
  rightRepairQuotation: any;
  leftRepairQuotation: any;
  rightPastRepairRef: any;
  leftPastRepairRef: any;
  rightOtherRemark: any;
  leftOtherRemark: any;
  rightAcessoriesIncluded: any;
  leftAcessoriesIncluded: any;
  rightArr: any;
  leftArr: any;
  leftPhysicalCondition:any;
  rightPhysicalCondition:any;
  streetAddress1: any;
  state1: any;
  country1: any;
  district1: any;
  taluka1: any;
  pincode1: any;
  reportPhoneNumber1: any;
  signatureImg: any;
  billingCompanyRefId: any;
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
     public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute, 
  ) { }
  userPermission:any;
  repairCreate= true;
  repairUpdate= true;

  ngOnInit(): void {
    this.leftArr=[];
    this.currentdate = new Date();
    this.page=1;
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    if(this.userType !== 'Super Admin'){
      this.isSuperAdmin= false;
    this.userPermission.forEach((element) => {
      if(element.pageName == 'Repair Print' && element.create == false)
      {
          this.repairCreate = false;  
      }
      if(element.pageName == 'Repair Print' && element.update == false)
      {
          this.repairUpdate = false;  
      }  
    });
  }
    this.repairPrintForm = this.fb.group({
      "searchBilling": new FormControl(''), 
      
    }); 

     let bar = new Promise<void>((resolve, reject) => {
      this.repairNo = this.route.snapshot.paramMap.get('repairNo'); 
      this.apimicroservice.getRepairDataById(this.repairNo).subscribe(res => {
        this.billingCompanyRefId = res.data[0].billingCompanyRefId;
        resolve();
      }); 

    });
   bar.then(()=>{
    this.prepopulation(this.repairNo);
    this.getReportHeaderGenericAllData();
    this.getReportFooterGenericAllData();
   });

}
prepopulation(id) {

  this.rightArr=[];
  this.leftArr=[];
  this.leftPhysicalCondition=[];
  this.rightPhysicalCondition=[];
  this.apimicroservice.getRepairDataById(id).subscribe(res => {
    this.signatureImg =res.data[0].Signaturephoto;
    this.companyName =res.data[0].companyName;
    this.companyCanNumber =res.data[0].canNumber;
    this.companyMake =res.data[0].make;
    this.rightModel=res.data[0].rightModel;
    this.rightSrNo=res.data[0].rightSrNo;
    this.leftModel=res.data[0].leftModel;
    this.leftSrNo=res.data[0].leftSrNo;
    this.leftWarranty=res.data[0].leftWarranty;
    this.rightWarranty=res.data[0].rightWarranty;
    this.rightDateOfPurchase=res.data[0].rightDateOfPurchase;
    this.leftDateOfPurchase=res.data[0].leftDateOfPurchase;
    this.rightRepairQuotation=res.data[0].rightRepairQuotation;
    this.leftRepairQuotation=res.data[0].leftRepairQuotation;
    this.rightPastRepairRef=res.data[0].rightPastRepairRef;
    this.leftPastRepairRef=res.data[0].leftPastRepairRef;
    this.rightOtherRemark=res.data[0].rightOtherRemark;
    this.leftOtherRemark=res.data[0].leftOtherRemark;
    this.rightAcessoriesIncluded=res.data[0].rightAcessories;
    this.leftAcessoriesIncluded=res.data[0].leftAcessories;

    this.rightArr =[];
    res.data.forEach(element => {
      //
      if(element.rightDead == true)
      {
        this.rightArr.push({"value":"Dead"});
      }
      if(element.rightNoisy == true)
      {
        this.rightArr.push({"value":"Noisy"});
      }
      if(element.rightDistortion == true)
      {
        this.rightArr.push({"value":"Distortion"});
      }
      if(element.rightFeedback == true)
      {
        this.rightArr.push({"value":"Feedback"});
      }
      if(element.rightConnectivityIssue == true)
      {
        this.rightArr.push({"value":"Connectivity Issue"});
      }
      if(element.rightTightFit == true) 
      {
        this.rightArr.push({"value":"Tight Fit"});
      }
      if(element.rightLooseFit == true)
      {
        this.rightArr.push({"value":"Loose Fit"});
      }
      if(element.rightOtherShellIssue == true)
      {
        this.rightArr.push({"value":"Other Shell Issue"});
      }
      if(element.rightCharging == true)
      {
        this.rightArr.push({"value":"Charging"});
      }
      if(element.rightBluetooth == true)
      {
        this.rightArr.push({"value":"Bluetooth"});
      }
      if(element.rightServicing == true)
      {
        this.rightArr.push({"value":"Servicing"});
      }    
      ///left
      if(element.leftDead == true)
      {
        this.leftArr.push({"value":"Dead"});
      }
      if(element.leftNoisy == true)
      {
        this.leftArr.push({"value":"Noisy"});
      }
      if(element.leftDistortion == true)
      {
        this.leftArr.push({"value":"Distortion"});
      }
      if(element.leftFeedback == true)
      {
        this.leftArr.push({"value":"Feedback"});
      }
      if(element.leftConnectivityIssue == true)
      {
        this.leftArr.push({"value":"Connectivity Issue"});
      }
      if(element.leftTightFit == true) 
      {
        this.leftArr.push({"value":"Tight Fit"});
      }
      if(element.leftLooseFit == true)
      {
        this.leftArr.push({"value":"Loose Fit"});
      }
      if(element.leftOtherShellIssue == true)
      {
        this.leftArr.push({"value":"Other Shell Issue"});
      }
      if(element.leftCharging == true)
      {
        this.leftArr.push({"value":"Charging"});
      }
      if(element.leftBluetooth == true)
      {
        this.leftArr.push({"value":"Bluetooth"});
      }
      if(element.leftServicing == true)
      {
        this.leftArr.push({"value":"Servicing"});
      }  
        ///PHYSICAL CONDITION left
        if(element.leftAnyPhysicalDamage == "Yes")
        {
          this.leftPhysicalCondition.push({"value":"Any Physical Damage"});
        }
        if(element.leftVisibleCorrorsion == "Yes")
        {
          this.leftPhysicalCondition.push({"value":"Visible Corrosion"});
        }
        if(element.leftBatteryLeakageVisible == "Yes")
        {
          this.leftPhysicalCondition.push({"value":"Battery Leakage Visible"});
        }
        if(element.leftMouldIncluded == "Yes")
        {
          this.leftPhysicalCondition.push({"value":"Mould Included (BTE)"});
        }
        if(element.leftMouldDamaged == "Yes")
        {
          this.leftPhysicalCondition.push({"value":"Mould Damaged (BTE)"});
        }
         ///PHYSICAL CONDITION Right
         if(element.rightAnyPhysicalDamage == "Yes")
         {
           this.rightPhysicalCondition.push({"value":"Any Physical Damage"});
         }
         if(element.rightVisibleCorrorsion == "Yes")
         {
           this.rightPhysicalCondition.push({"value":"Visible Corrosion"});
         }
         if(element.rightBatteryLeakageVisible == "Yes")
         {
           this.rightPhysicalCondition.push({"value":"Battery Leakage Visible"});
         }
         if(element.rightMouldIncluded == "Yes")
         {
           this.rightPhysicalCondition.push({"value":"Mould Included (BTE)"});
         }
         if(element.rightMouldDamaged == "Yes")
         {
           this.rightPhysicalCondition.push({"value":"Mould Damaged (BTE)"});
         }
         //
         //
    });

    this.apimicroservice.getPatientById(res.data[0].patientRefId).subscribe(res => { 
      this.prifix =res.data[0].prefix;
      this.lastName =res.data[0].lastName;
      this.firstName =res.data[0].firstName;
      this.middleName =res.data[0].middleName;
      this.pMobile =res.data[0].mobile1;
      
      this.streetAddress1 =res.data[0].streetAddress;
      this.taluka1 =res.data[0].taluka;
      this.district1 =res.data[0].district;
      this.state1 =res.data[0].state;
      this.country1 =res.data[0].country;
      this.pincode1 =res.data[0].pincode;
      // this.address =res.data[0].streetAddress + ',' + res.data[0].taluka + ' ' + res.data[0].district + ' ' +
      //  res.data[0].state + ' ' + res.data[0].country + ',' + res.data[0].pincode;
   });
   });
   //
   
}

printThisPage() {
let currentDate =new Date();
let date=currentDate.toLocaleDateString('en-GB');
  let repair = this.repairNo;
  let titleName= 'Repair_'+date;
    var tempTitle = document.title;
    document.title = titleName;
    window.print();
    document.title = tempTitle;
}

redirect(to): void {
  this.router.navigate([to]);
 }
getReportHeaderGenericAllData()
{
  this.apimicroservice.getHeaderDataByBillingRefId(this.billingCompanyRefId).subscribe(res => {
    if(res.status){ 
      this.apimicroservice.openSnackbar(res.message);  
      this.headerData = res.data;
      //
      if(res.data.length > 0){
        this.reportName = this.headerData[0].reportName;
        this.reportAddress = this.headerData[0].reportAddress;
        this.reportWeeklyOff = this.headerData[0].reportWeeklyOff;
        this.emailAdd = this.headerData[0].reportEmail;
        this.website = this.headerData[0].reportWebsite;
        this.taluka = this.headerData[0].taluka;
        this.district = this.headerData[0].district;
        this.state = this.headerData[0].state;
        this.country = this.headerData[0].country;
        this.reportPinCode = this.headerData[0].reportPinCode;
        this.reportPhoneNumber = this.headerData[0].reportPhoneNumber;
        this.reportPhoneNumber1 = this.headerData[0].reportPhoneNumber1;
        this.openTime = this.headerData[0].openTime;
        this.closeTime = this.headerData[0].closeTime;
        this.logoimg = res.data[0].uploadLogo;
        
        this.bankName = res.data[0].bankName;
        this.bankBranchName = res.data[0].bankBranchName;
        this.accountNumber = res.data[0].accountNumber;
        this.ifcCode = res.data[0].ifcCode;
        this.upiId = res.data[0].upiId;
      }else{
        this.reportName = "";
        this.reportAddress = "";
        this.reportWeeklyOff ="";
        this.taluka = "";
        this.district = "";
        this.state = "";
        this.country = "";
        this.reportPinCode = "";
        this.reportPhoneNumber = "";
        this.reportPhoneNumber1 = "";
        this.openTime = "";
        this.closeTime = "";
        this.emailAdd = "";
        this.website = "";
        this.logoimg = "";
        this.bankName = "";
        this.bankBranchName = "";
        this.accountNumber = "";
        this.ifcCode = "";
        this.upiId = "";
      }
      
      if(this.logoimg == "" || this.logoimg == '../../assets/main.jpeg')
      this.logoimg = "../../assets/clinic_logo3.jpg"
      //
    }else{
      //this.apimicroservice.openSnackbar(res.message);
    }
  });
}
getReportFooterGenericAllData()
{
  let clinicRef= sessionStorage.getItem('clinicRef');
  let branchRef= sessionStorage.getItem('branchRef');
  let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
  let branchRefSuperAdmin= '';
  if (this.userType !== 'Super Admin') {
  this.apimicroservice.getReportFooterGenericAllData(branchRef,clinicRef).subscribe(res => {
    if(res.status){
    //  this.apimicroservice.openSnackbar(res.message);
      this.footerData = res.data;
      //
    }else{
      //this.apimicroservice.openSnackbar(res.message);
    }
  });
}else{
  this.apimicroservice.getReportFooterGenericAllData(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
    if(res.status){
    //  this.apimicroservice.openSnackbar(res.message);
      this.footerData = res.data;
      //
    }else{
      //this.apimicroservice.openSnackbar(res.message);
    }
  });
}
}
 
}

