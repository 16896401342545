import { Component, Inject, OnInit } from "@angular/core";
import { ApiServiceService } from '../api-service.service';
import { NgxSpinnerService } from "ngx-spinner";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PatientSearchPopComponent } from "../patient-search-pop/patient-search-pop.component";
import { PopUpPatientComponent } from "../pop-up-patient/pop-up-patient.component";

@Component({
  selector: 'app-popup-place-order-mail',
  templateUrl: './popup-place-order-mail.component.html',
  styleUrls: ['./popup-place-order-mail.component.scss']
})
export class PopupPlaceOrderMailComponent implements OnInit {
  
  appointRefId: any;
  appointDate: any;
  patientName : any;
  appDate: any;
  appTime: any; 
  communicationMasterForm: FormGroup;
  address: any;
  todaysDate: Date;
  pincode: any;
  cName: any;
  copyValue: string;
  clinicName: any;
  clinicPhone: any;
  HAModelDetailsData: any;
  HAModelReceiverData: any;
  HAModelAccessoriesData: any;
  canNumber: any;
  haColor: any;
  notes: any;

  constructor(public dialogRef: MatDialogRef<PopupPlaceOrderMailComponent>,@Inject(MAT_DIALOG_DATA) public data:any,
  public apimicroservice: ApiServiceService,private spinner: NgxSpinnerService, private fb: FormBuilder,
  public dialog: MatDialog) { 
    dialogRef.disableClose = true;
    
  }

  ngOnInit(): void {
    //  this.onSelectPatient(this.data.patientId);
    this.todaysDate = new Date();
    this.cName = this.data.companyName; 
    // this.cName.style.textTransform = 'capitalize';
    this.clinicName = this.data.clinicName;
    this.clinicPhone = this.data.clinicPhone;
    this.canNumber = this.data.canNumber;
    this.haColor = this.data.haColor;
    this.notes = this.data.notes;
   //
   this.prepopulation(this.data.lastId);
    this.copyValue =
    `Sub: Order Confirmation Mail`  + '\n' + 
    `Dear` + ` ` + this.cName + `,` + '\n' +  
    `Please arrange to send the following` + '\n' +
    `*This need to be billed on` + ` ` + this.clinicName + '\n' +
    `Regards,` + '\n' + 
    // `Order Placed Successfully From ` +  this.cName + `Please Check.` + '\n' +
    `Clinical Audiologist,` + '\n' + 
     this.clinicName + '\n' +
     this.canNumber + '\n' + 
    `Mobile :` + this.clinicPhone;
    let table = document.querySelector('#testTable');
    let button = document.querySelector('#button');
    function selectNode(node){
      let range  =  document.createRange();
      range.selectNodeContents(node)
      let select =  window.getSelection()
      select.removeAllRanges()
      select.addRange(range)
    }
    button.addEventListener('click',function(){
      selectNode(table);
      document.execCommand('copy')
      
    })
  }
  selectNode(node){
    let range  =  document.createRange();
    range.selectNodeContents(node)
    let select =  window.getSelection()
    select.removeAllRanges()
    select.addRange(range)
  } 
  copied()
  {
    // navigator.clipboard.writeText(copy_btn).then().catch(e => console.error(e));
    // this.apimicroservice.openSnackbar('Copied');
    // this.dialogRef.close();
    let table = document.querySelector('#testTable');
    let button = document.querySelector('#button');
    function selectNode(node){
      let range  =  document.createRange();
      range.selectNodeContents(node)
      let select =  window.getSelection()
      select.removeAllRanges()
      select.addRange(range)
    }
    button.addEventListener('click',function(){
      selectNode(table);
      document.execCommand('copy')
      
    })
  }
  prepopulation(id) {
    this.apimicroservice.getPlaceOrderDataById(id).subscribe(res => {
       let data = res.data[0];
       this.HAModelDetailsData=[];
       this.HAModelReceiverData=[];
       this.HAModelAccessoriesData=[];
        data.HAModelDetails.forEach(element => {
        this.HAModelDetailsData.push(element);
     });
     data.HAModelReceiver.forEach(element => {
      this.HAModelReceiverData.push(element);
   });
   data.HAModelAccessories.forEach(element => {
    this.HAModelAccessoriesData.push(element);
 });

    });
  }
  
  onClose(): void {
    this.dialogRef.close();
  }
  printThisPage() {
    let currentDate =new Date();
    let date=currentDate.toLocaleDateString('en-GB');
      let titleName= 'Communication_'+date;
        var tempTitle = document.title;
        document.title = titleName;
        window.print();
        document.title = tempTitle;
    }
}
