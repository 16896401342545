<div class="dialog-wrapper">
  <div class="row">
  <div class="col-sm-11">
    <h3 mat-dialog-title class="title">Stock Reject Confirmation :</h3>
      <!-- <h1 class="titlecolor" >  Search Patients</h1>   -->
  </div>
  <div class="col-sm-1 text-right pb-2">
    <button mat-icon-button class="close-button" [mat-dialog-close]="true">
        <mat-icon class="close-icon" color="warn">close</mat-icon>
      </button>
</div>
<div class="col-sm-12">
        Do You Really Want To Reject Stock?
       <div class="container">
        <form [formGroup]="PopupRejectStockForm" autocomplete="off" novalidate>
        <div class="row">
          <div class="col-sm-12">
             
                        <div class="row">
                          
                            <label class="header mt-3">Reject Reason:</label>
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Reject Reason</mat-label>
                                <textarea matInput formControlName="reasonOfReject" maxlength="1000"
                                placeholder="Reject Reason"></textarea>
                              </mat-form-field>
                        </div>  
            </div>
            </div>
            </form>
            </div>
</div> 
<div class="col-sm-3"></div>
<div class="col-sm-3">
 <button type="button" class="btn btn-primary btn-sm example-full-width" 
 (click)="confirmSubmit()">Yes</button>
</div>
<div class="col-sm-3">
  <button type="button" class="btn btn-primary btn-sm example-full-width" 
  (click)="confirmCancel()">No</button>
 </div>
 <div class="col-sm-3"></div>
  </div>
  </div>
  