<div class="col-sm-12" [formGroup]="devicePopUpForm">
    <div class="row">
       
        <div class="col-sm-5  ">
            <h1 class="titlecolor" >  Search Device</h1>  
        </div>
         
        <div class="col-sm-6">
            <div class="input-group md-form form-sm form-1 pl-0">
              <div class="input-group-prepend">
                <span class="input-group-text purple lighten-3" id="basic-text1"><i class="fas fa-search text-white"
                    aria-hidden="true"></i></span>
              </div>
              <input class="form-control my-0 py-1" type="text"  formControlName="searchDevice"
              aria-label="Search"  onkeydown="return (event.keyCode!==91);"  (keyup)="onKeyUpEvent($event)" placeholder="Search" aria-label="Search">

            </div>
        </div>
        <div class="col-sm-1">
          <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
            <a (click)="onClose()" matTooltip="Close" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">close</span></a>
            </div>
        </div>
       
      <!-- <div class="col-sm-12 table-responsive">
        <table id="toDeviceList" 
     class="table table-bordered data-table-custom">
            <thead>
              <tr class="text-center">
                <th>Device ID</th>
                <th>Brand</th>
                <th>Model</th>
                <th>Device Type</th>
                <th>Battery Type</th>
                <th>Cord Type</th>
                <th>Style</th>
                <th>Ear</th>
              </tr>
            </thead>
            <tbody>
   
              <tr
              *ngFor="let item of devicelist | paginate : { id:'devicepaginate', 
              itemsPerPage: size, currentPage: page, totalItems:totalLength } "
                role="row"
                class="odd text-center"
                (click)="toPatientForm(item)" style="cursor:pointer; text-align: center " >

                <td matTooltip="click to view">{{item.branchWiseId}}</td>
             
                <td  matTooltip="click to view">{{item.brandLable}}</td>
                <td  matTooltip="click to view">{{item.model}}</td>
                <td  matTooltip="click to view">{{item.deviceType}}</td>
                <td  matTooltip="click to view">{{item.batteryType}}</td>
                <td  matTooltip="click to view">{{item.cordType}}</td>
                <td  matTooltip="click to view">{{item.style}}</td>
                <td  matTooltip="click to view">{{item.ear}}</td>
              
              </tr>
            </tbody>
          </table>
        </div> -->
        <div class="col-sm-12">
          <div class="row">
          <div class="col-sm-12">
            <h4 class="internalDevice mb-0 mt-0">Fitted By Us :</h4>  
            <div class="col-sm-12 table-responsive">
              <table id="toDeviceList"  
           class="table table-bordered data-table-custom">
          
<thead>
<tr class="text-center">
  <th>Brand</th>
  <th>Model</th>
  <th>Serial Number</th>
  <th>Device Type</th>
  <th>Battery Type</th>
</tr>
</thead>
<tbody>

<tr
*ngFor="let item of devicelist | paginate : { id:'devicepaginate', 
itemsPerPage: size, currentPage: page, totalItems:totalLength } "
  role="row"
  class="odd text-center" 
  (click)="toPatientForm(item)" style="cursor:pointer; text-align: center " >
  <td  matTooltip="click to view">{{item.deviceData[0].brandLable}}</td>
  <td  matTooltip="click to view">{{item.model}}</td>
  <td  matTooltip="click to view">{{item.serialNumber}}</td>
  <td  matTooltip="click to view">{{item.deviceData[0].deviceType}}</td>
  <td  matTooltip="click to view">{{item.deviceData[0].batteryType}}</td>
</tr>
</tbody>
</table>
<div class="col-sm-12 pt-0 text-right">
<pagination-controls id="devicepaginate" directionLinks="true"  
(pageChange)="onPageChange($event)" 
class="float-right pt-3 my-pagination"></pagination-controls>
</div>
</div> 
          </div>
          
            <div class="col-sm-12">
              <h4 class="externalDevice mb-0 mt-0">Not Fitted By Us :</h4>
              <div class="col-sm-12 table-responsive">
                <table id="toDeviceList"
             class="table table-bordered data-table-custom">
            
<thead>
  <tr class="text-center">
    <th>Brand</th>
    <th>Model</th>
    <th>Serial Number</th>
    <th>Device Type</th>
    <th>Warranty</th>

  </tr>
</thead>
<tbody>

  <tr
  *ngFor="let item of devicelist2 | paginate : { id:'devicepaginate2', 
  itemsPerPage: size1, currentPage: page1, totalItems:totalLength1 } "
    role="row"
    class="odd text-center" 
    style="cursor:pointer; text-align: center " (click)="toPatientForm(item)"  >

    <td matTooltip="click to view">{{item.brandLable}}</td>
    <td matTooltip="click to view">{{item.model}}</td>
    <td matTooltip="click to view">{{item.newSerialNo}}</td>
    <td matTooltip="click to view">{{item.deviceType}}</td>
    <td matTooltip="click to view">{{item.warranty}}</td>
   
  </tr>
</tbody>
</table>
<div class="col-sm-12 pt-0 text-right">
<pagination-controls id="devicepaginate2" directionLinks="true"  
(pageChange)="onPageChange2($event)" 
  class="float-right pt-3 my-pagination"></pagination-controls>
  </div>
  </div>
            </div>
           
              </div>
              </div>
        <!-- <div class="col-sm-12 pt-0 text-right">
            <pagination-controls id="devicepaginate" directionLinks="true"  
            (pageChange)="onPageChange($event)" 
              class="float-right pt-3 my-pagination"></pagination-controls>
        </div> -->
    </div>
</div>
