import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiServiceService } from '../api-service.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-data-migration',
  templateUrl: './data-migration.component.html',
  styleUrls: ['./data-migration.component.scss']
})
export class DataMigrationComponent implements OnInit {
  clinicDataArr: any;
  customHearingAidDataArr: any;
  chaData: any;
  repairDataArr: any;
  earmoldDataArr: any;
  companyDataArr: any;
  salesPersonDataArr: any;
  deviceDataArr: any;
  conditionData: any;
  batteryTypeDataArr: any;
  styleDataArr: any;
  devicTypeData: any;
  brandDataArr: any;
  billingCompanyDataArr: any;
  appointmentData: any;
  appointmentTypeData: any;
  patientDataArr: any;
  audiologistData: any;
  branchDataArr: any;
  deviceByPatientArr: any;

  constructor(
    private fb: FormBuilder,
    public apimicroservice: ApiServiceService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService
  ) { }
  dataMigrationForm: FormGroup;
  branchMigrationForm: FormGroup;
  audiologistsForm: FormGroup;
  patientForm: FormGroup;
  appointmentTypeForm: FormGroup;
  appointmentForm: FormGroup;
  billingCompanyForm: FormGroup;
  brandForm: FormGroup;
  deviceTypeForm: FormGroup;
  styleForm: FormGroup;
  batteryTypeForm: FormGroup;
  conditionForm: FormGroup;
  deviceForm: FormGroup;
  salesPersonForm: FormGroup;
  companyForm: FormGroup;
  earmoldForm: FormGroup;
  repairForm: FormGroup;
  customHearingAidForm: FormGroup;
  communicationForm: FormGroup;
  deviceByPatientForm: FormGroup;

  ngOnInit(): void {
    this.dataMigrationForm = this.fb.group({
      "clinicData": new FormControl('',[Validators.required]),
    }); 
    this.branchMigrationForm = this.fb.group({
      "branchData": new FormControl('',[Validators.required]),
    }); 
    this.audiologistsForm = this.fb.group({
      "audiologyData": new FormControl('',[Validators.required]),//
    }); 
    this.patientForm = this.fb.group({
      "patientData": new FormControl('',[Validators.required]),
    }); 
    this.appointmentTypeForm = this.fb.group({
      "appintmentTypeData": new FormControl('',[Validators.required]),//
    }); 
    this.appointmentForm = this.fb.group({
      "appintmentData": new FormControl('',[Validators.required]),//
    }); 
    this.billingCompanyForm = this.fb.group({
      "billingCompanyData": new FormControl('',[Validators.required]),
    }); 
    this.brandForm = this.fb.group({
      "brandData": new FormControl('',[Validators.required]),
    }); 
    this.deviceTypeForm = this.fb.group({
      "deviceTypeData": new FormControl('',[Validators.required]),
    }); 
    this.styleForm = this.fb.group({
      "styleData": new FormControl('',[Validators.required]),
    }); 
    this.batteryTypeForm = this.fb.group({
      "batteryTypeData": new FormControl('',[Validators.required]),
    }); 
    this.conditionForm = this.fb.group({
      "conditionTypeData": new FormControl('',[Validators.required]),//
    }); 
    this.deviceForm = this.fb.group({
      "deviceData": new FormControl('',[Validators.required]),
    }); 
    this.salesPersonForm = this.fb.group({
      "salesPersonData": new FormControl('',[Validators.required]),
    }); 
    this.companyForm = this.fb.group({
      "companyData": new FormControl('',[Validators.required]),
    }); 
    this.earmoldForm = this.fb.group({
      "earmoldData": new FormControl('',[Validators.required]),
    }); 
    this.repairForm = this.fb.group({
      "repairData": new FormControl('',[Validators.required]),
    }); 
    this.customHearingAidForm = this.fb.group({
      "customHearingAidData": new FormControl('',[Validators.required]),
    }); 
    this.communicationForm = this.fb.group({
      "communicationData": new FormControl('',[Validators.required]),//
    }); 
    this.deviceByPatientForm = this.fb.group({
      "deviceByPatientData": new FormControl('',[Validators.required]),//
    }); 
  }

  clinicUpload(ev){
      let workBook = null;
      let jsonData = null;
      const reader = new FileReader();
      const file = ev.target.files[0];
      reader.onload = async(event) => {
        const data = reader.result;
        workBook = XLSX.read(data, { type: 'binary',cellDates: true,dateNF: 'YYYYMMDD HH:mm:ss' });
        jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial = XLSX.utils.sheet_to_json(sheet);
          return initial;
        }, {});
        this.spinner.show()
        // //
        const dataString = JSON.stringify(jsonData);
        this.clinicDataArr = JSON.parse(dataString)
        await setTimeout(() => {
          this.spinner.hide();
        }, 2000);
        //
        
        // this.savebatchdata(data);
      }
      reader.readAsBinaryString(file);
  }
  branchUpload(ev){
      let workBook = null;
      let jsonData = null;
      const reader = new FileReader();
      const file = ev.target.files[0];
      reader.onload = async(event) => {
        const data = reader.result;
        workBook = XLSX.read(data, { type: 'binary',cellDates: true });
        jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial = XLSX.utils.sheet_to_json(sheet);
          return initial;
        }, {});
        this.spinner.show()
        // //
        const dataString = JSON.stringify(jsonData);
        this.branchDataArr = JSON.parse(dataString)
        await setTimeout(() => {
          this.spinner.hide();
        }, 2000);
        //
        
        // this.savebatchdata(data);
      }
      reader.readAsBinaryString(file);
  }
  audiologistsUpload(ev){
      let workBook = null;
      let jsonData = null;
      const reader = new FileReader();
      const file = ev.target.files[0];
      reader.onload = async(event) => {
        const data = reader.result;
        workBook = XLSX.read(data, { type: 'binary',cellDates: true });
        jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial = XLSX.utils.sheet_to_json(sheet);
          return initial;
        }, {});
        this.spinner.show()
        // //
        const dataString = JSON.stringify(jsonData);
        this.audiologistData = JSON.parse(dataString)
        await setTimeout(() => {
          this.spinner.hide();
        }, 2000);
        //
        
        // this.savebatchdata(data);
      }
      reader.readAsBinaryString(file);
  }
  patientUpload(ev){
      let workBook = null;
      let jsonData = null;
      const reader = new FileReader();
      const file = ev.target.files[0];
      reader.onload = async(event) => {
        const data = reader.result;
        workBook = XLSX.read(data, { type: 'binary',cellDates: true });
        jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial = XLSX.utils.sheet_to_json(sheet);
          return initial;
        }, {});
        this.spinner.show()
        // //
        const dataString = JSON.stringify(jsonData);
        this.patientDataArr = JSON.parse(dataString)
        await setTimeout(() => {
          this.spinner.hide();
        }, 2000);
        //
        
        // this.savebatchdata(data);
      }
      reader.readAsBinaryString(file);
  }
  appointmentTypeUpload(ev){
      let workBook = null;
      let jsonData = null;
      const reader = new FileReader();
      const file = ev.target.files[0];
      reader.onload = async(event) => {
        const data = reader.result;
        workBook = XLSX.read(data, { type: 'binary',cellDates: true });
        jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial = XLSX.utils.sheet_to_json(sheet);
          return initial;
        }, {});
        this.spinner.show()
        // //
        const dataString = JSON.stringify(jsonData);
        this.appointmentTypeData = JSON.parse(dataString)
        await setTimeout(() => {
          this.spinner.hide();
        }, 2000);
        //
        
        // this.savebatchdata(data);
      }
      reader.readAsBinaryString(file);
  }
  appointmentUpload(ev){
      let workBook = null;
      let jsonData = null;
      const reader = new FileReader();
      const file = ev.target.files[0];
      reader.onload = async(event) => {
        const data = reader.result;
        workBook = XLSX.read(data, { type: 'binary',cellDates: true });
        jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial = XLSX.utils.sheet_to_json(sheet);
          return initial;
        }, {});
        this.spinner.show()
        // //
        const dataString = JSON.stringify(jsonData);
        this.appointmentData = JSON.parse(dataString)
        await setTimeout(() => {
          this.spinner.hide();
        }, 2000);
        //
        
        // this.savebatchdata(data);
      }
      reader.readAsBinaryString(file);
  }
  billingCompanyUpload(ev){
      let workBook = null;
      let jsonData = null;
      const reader = new FileReader();
      const file = ev.target.files[0];
      reader.onload = async(event) => {
        const data = reader.result;
        workBook = XLSX.read(data, { type: 'binary',cellDates: true });
        jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial = XLSX.utils.sheet_to_json(sheet);
          return initial;
        }, {});
        this.spinner.show()
        // //
        const dataString = JSON.stringify(jsonData);
        this.billingCompanyDataArr = JSON.parse(dataString)
        await setTimeout(() => {
          this.spinner.hide();
        }, 2000);
        //
        
        // this.savebatchdata(data);
      }
      reader.readAsBinaryString(file);
  }
  brandUpload(ev){
      let workBook = null;
      let jsonData = null;
      const reader = new FileReader();
      const file = ev.target.files[0];
      reader.onload = async(event) => {
        const data = reader.result;
        workBook = XLSX.read(data, { type: 'binary',cellDates: true });
        jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial = XLSX.utils.sheet_to_json(sheet);
          return initial;
        }, {});
        this.spinner.show()
        // //
        const dataString = JSON.stringify(jsonData);
        this.brandDataArr = JSON.parse(dataString)
        await setTimeout(() => {
          this.spinner.hide();
        }, 2000);
        //
        
        // this.savebatchdata(data);
      }
      reader.readAsBinaryString(file);
  }
  deviceTypeUpload(ev){
      let workBook = null;
      let jsonData = null;
      const reader = new FileReader();
      const file = ev.target.files[0];
      reader.onload = async(event) => {
        const data = reader.result;
        workBook = XLSX.read(data, { type: 'binary',cellDates: true });
        jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial = XLSX.utils.sheet_to_json(sheet);
          return initial;
        }, {});
        this.spinner.show()
        // //
        const dataString = JSON.stringify(jsonData);
        this.devicTypeData = JSON.parse(dataString)
        await setTimeout(() => {
          this.spinner.hide();
        }, 2000);
        //
        
        // this.savebatchdata(data);
      }
      reader.readAsBinaryString(file);
  }
  styleUpload(ev){
      let workBook = null;
      let jsonData = null;
      const reader = new FileReader();
      const file = ev.target.files[0];
      reader.onload = async(event) => {
        const data = reader.result;
        workBook = XLSX.read(data, { type: 'binary',cellDates: true });
        jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial = XLSX.utils.sheet_to_json(sheet);
          return initial;
        }, {});
        this.spinner.show()
        // //
        const dataString = JSON.stringify(jsonData);
        this.styleDataArr = JSON.parse(dataString)
        await setTimeout(() => {
          this.spinner.hide();
        }, 2000);
        //
        
        // this.savebatchdata(data);
      }
      reader.readAsBinaryString(file);
  }
  batteryTypeUpload(ev){
      let workBook = null;
      let jsonData = null;
      const reader = new FileReader();
      const file = ev.target.files[0];
      reader.onload = async(event) => {
        const data = reader.result;
        workBook = XLSX.read(data, { type: 'binary',cellDates: true });
        jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial = XLSX.utils.sheet_to_json(sheet);
          return initial;
        }, {});
        this.spinner.show()
        // //
        const dataString = JSON.stringify(jsonData);
        this.batteryTypeDataArr = JSON.parse(dataString)
        await setTimeout(() => {
          this.spinner.hide();
        }, 2000);
        //
        
        // this.savebatchdata(data);
      }
      reader.readAsBinaryString(file);
  }
  conditionUpload(ev){
      let workBook = null;
      let jsonData = null;
      const reader = new FileReader();
      const file = ev.target.files[0];
      reader.onload = async(event) => {
        const data = reader.result;
        workBook = XLSX.read(data, { type: 'binary',cellDates: true });
        jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial = XLSX.utils.sheet_to_json(sheet);
          return initial;
        }, {});
        this.spinner.show()
        // //
        const dataString = JSON.stringify(jsonData);
        this.conditionData = JSON.parse(dataString)
        await setTimeout(() => {
          this.spinner.hide();
        }, 2000);
        //
        
        // this.savebatchdata(data);
      }
      reader.readAsBinaryString(file);
  }
  deviceUpload(ev){
      let workBook = null;
      let jsonData = null;
      const reader = new FileReader();
      const file = ev.target.files[0];
      reader.onload = async(event) => {
        const data = reader.result;
        workBook = XLSX.read(data, { type: 'binary',cellDates: true });
        jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial = XLSX.utils.sheet_to_json(sheet);
          return initial;
        }, {});
        this.spinner.show()
        // //
        const dataString = JSON.stringify(jsonData);
        this.deviceDataArr = JSON.parse(dataString)
        await setTimeout(() => {
          this.spinner.hide();
        }, 2000);
        //
        
        // this.savebatchdata(data);
      }
      reader.readAsBinaryString(file);
  }
  salesPersonUpload(ev){
      let workBook = null;
      let jsonData = null;
      const reader = new FileReader();
      const file = ev.target.files[0];
      reader.onload = async(event) => {
        const data = reader.result;
        workBook = XLSX.read(data, { type: 'binary',cellDates: true });
        jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial = XLSX.utils.sheet_to_json(sheet);
          return initial;
        }, {});
        this.spinner.show()
        // //
        const dataString = JSON.stringify(jsonData);
        this.salesPersonDataArr = JSON.parse(dataString)
        await setTimeout(() => {
          this.spinner.hide();
        }, 2000);
        //
        
        // this.savebatchdata(data);
      }
      reader.readAsBinaryString(file);
  }
  companyUpload(ev){
      let workBook = null;
      let jsonData = null;
      const reader = new FileReader();
      const file = ev.target.files[0];
      reader.onload = async(event) => {
        const data = reader.result;
        workBook = XLSX.read(data, { type: 'binary',cellDates: true });
        jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial = XLSX.utils.sheet_to_json(sheet);
          return initial;
        }, {});
        this.spinner.show()
        // //
        const dataString = JSON.stringify(jsonData);
        this.companyDataArr = JSON.parse(dataString)
        await setTimeout(() => {
          this.spinner.hide();
        }, 2000);
        //
        
        // this.savebatchdata(data);
      }
      reader.readAsBinaryString(file);
  }
  earmoldUpload(ev){
      let workBook = null;
      let jsonData = null;
      const reader = new FileReader();
      const file = ev.target.files[0];
      reader.onload = async(event) => {
        const data = reader.result;
        workBook = XLSX.read(data, { type: 'binary',cellDates: true });
        jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial = XLSX.utils.sheet_to_json(sheet);
          return initial;
        }, {});
        this.spinner.show()
        // //
        const dataString = JSON.stringify(jsonData);
        this.earmoldDataArr = JSON.parse(dataString)
        await setTimeout(() => {
          this.spinner.hide();
        }, 2000);
        //
        
        // this.savebatchdata(data);
      }
      reader.readAsBinaryString(file);
  }
  repairUpload(ev){
      let workBook = null;
      let jsonData = null;
      const reader = new FileReader();
      const file = ev.target.files[0];
      reader.onload = async(event) => {
        const data = reader.result;
        workBook = XLSX.read(data, { type: 'binary',cellDates: true });
        jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial = XLSX.utils.sheet_to_json(sheet);
          return initial;
        }, {});
        this.spinner.show()
        // //
        const dataString = JSON.stringify(jsonData);
        this.repairDataArr = JSON.parse(dataString)
        await setTimeout(() => {
          this.spinner.hide();
        }, 2000);
        //
        
        // this.savebatchdata(data);
      }
      reader.readAsBinaryString(file);
  }
  chaUpload(ev){
      let workBook = null;
      let jsonData = null;
      const reader = new FileReader();
      const file = ev.target.files[0];
      reader.onload = async(event) => {
        const data = reader.result;
        workBook = XLSX.read(data, { type: 'binary',cellDates: true });
        jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial = XLSX.utils.sheet_to_json(sheet);
          return initial;
        }, {});
        this.spinner.show()
        // //
        const dataString = JSON.stringify(jsonData);
        this.chaData = JSON.parse(dataString)
        await setTimeout(() => {
          this.spinner.hide();
        }, 2000);
        //
        
        // this.savebatchdata(data);
      }
      reader.readAsBinaryString(file);
  }
  communicationUpload(ev){
      let workBook = null;
      let jsonData = null;
      const reader = new FileReader();
      const file = ev.target.files[0];
      reader.onload = async(event) => {
        const data = reader.result;
        workBook = XLSX.read(data, { type: 'binary',cellDates: true });
        jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial = XLSX.utils.sheet_to_json(sheet);
          return initial;
        }, {});
        this.spinner.show()
        // //
        const dataString = JSON.stringify(jsonData);
        this.customHearingAidDataArr = JSON.parse(dataString)
        await setTimeout(() => {
          this.spinner.hide();
        }, 2000);
        //
        
        // this.savebatchdata(data);
      }
      reader.readAsBinaryString(file);
  }
  deviceByPatientUpload(ev){
    //
    
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev.target.files[0];
    reader.onload = async(event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary',cellDates: true });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      this.spinner.show()
      // //
      const dataString = JSON.stringify(jsonData);
      this.deviceByPatientArr = JSON.parse(dataString)
      await setTimeout(() => {
        this.spinner.hide();
      }, 2000);
      //
      
      // this.savebatchdata(data);
    }
    reader.readAsBinaryString(file);
}

  saveClinic(){
    let payload = this.clinicDataArr;
    this.apimicroservice.saveClinitMData(payload).subscribe(res=>{
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);
        this.ngOnInit();
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
  saveBranch(){
    let payload = this.branchDataArr;
    this.apimicroservice.saveBranchMData(payload).subscribe(res=>{
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);
        this.ngOnInit();
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
  saveAudiologists(){
    let payload = this.audiologistData;
    this.apimicroservice.saveAudiologistsMData(payload).subscribe(res=>{
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);
        this.ngOnInit();
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
  savePatients(){
    let payload = this.patientDataArr;
    this.apimicroservice.savePatientMData(payload).subscribe(res=>{
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);
        this.ngOnInit();
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
  saveAppointmentType(){
    let payload = this.appointmentTypeData;
    this.apimicroservice.saveAppointmentTypeMData(payload).subscribe(res=>{
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);
        this.ngOnInit();
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
  saveAppointment(){
    let payload = this.appointmentData;
    this.apimicroservice.saveAppointmentMData(payload).subscribe(res=>{
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);
        this.ngOnInit();
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
  saveBillingCompany(){
    let payload = this.billingCompanyDataArr;
    this.apimicroservice.saveBillingCompanyMData(payload).subscribe(res=>{
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);
        this.ngOnInit();
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
  saveBrand(){
    let payload = this.brandDataArr;
    this.apimicroservice.saveBrandMData(payload).subscribe(res=>{
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);
        this.ngOnInit();
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
  saveDeviceType(){
    let payload = this.devicTypeData;
    this.apimicroservice.saveDeviceTypeMData(payload).subscribe(res=>{
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);
        this.ngOnInit();
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
  saveStyle(){
    let payload = this.styleDataArr;
    this.apimicroservice.saveStyleMData(payload).subscribe(res=>{
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);
        this.ngOnInit();
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
  saveBatteryType(){
    let payload = this.batteryTypeDataArr;
    this.apimicroservice.saveBatteryTypeMData(payload).subscribe(res=>{
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);
        this.ngOnInit();
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
  saveCondition(){
    let payload = this.conditionData;
    this.apimicroservice.saveConditionMData(payload).subscribe(res=>{
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);
        this.ngOnInit();
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
  saveDevice(){
    let payload = this.deviceDataArr;
    this.apimicroservice.saveDeviceMData(payload).subscribe(res=>{
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);
        this.ngOnInit();
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    }) 
  }
  saveSalesPerson(){
    let payload = this.salesPersonDataArr;
    this.apimicroservice.saveSalePerson(payload).subscribe(res=>{
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);
        this.ngOnInit();
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    })
  } 
  saveCompany(){
    let payload = this.companyDataArr;
    this.apimicroservice.saveCompanyMData(payload).subscribe(res=>{
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);
        this.ngOnInit();
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
  saveEarmold(){
    let payload = this.earmoldDataArr;
    this.apimicroservice.saveEarmoldMData(payload).subscribe(res=>{
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);
        this.ngOnInit();
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
  saveRepair(){
    let payload = this.repairDataArr;
    this.apimicroservice.saveRepairMData(payload).subscribe(res=>{
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);
        this.ngOnInit();
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
  saveCHA(){
    let payload = this.chaData;
    this.apimicroservice.saveCHAMData(payload).subscribe(res=>{
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);
        this.ngOnInit();
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
  saveCommunication(){
    let payload = this.companyDataArr;
    this.apimicroservice.saveCommunicationMData(payload).subscribe(res=>{
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);
        this.ngOnInit();
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
  saveDeviceByPatient(){
    let payload = this.deviceByPatientArr;
    this.apimicroservice.saveDeviceByPatientMData(payload).subscribe(res=>{
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);
        this.ngOnInit();
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    })
  }

}
