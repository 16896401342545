<div class="container-fluid " [formGroup]="goodInWordReturnForm">
    <div class="row">
      <div class="col-sm-1">
       
      </div>
      <div class="col-sm-11">
        
          <div class="row">
            <div class="col-sm-12 pr-4">
              <div class="row">
                <div class="card divbody boxsssss borderradius p-1">
                 
  
                  <div class="col-sm-12">
                    <!-- <h1 class="titlecolor"> Patient Master</h1> -->
                        <div class="col-sm-12 bg-secondary rounded-3 pl-4 pr-4 mb-3">
                            <div class="row">
                           
                                  <h1 class="fass mt-2">Goods Inword Return</h1>
                                 
                            </div>
                          </div>
                    </div>
                  <div class="col-sm-12">
                    <div class="row">
                      <div class="col-sm-3 pl-1 pr-1">
                        <mat-form-field class="example-full-width lable_style2" appearance="outline">
                            <mat-label class="lable_style2">GRN No</mat-label>
                        <input placeholder="GRN" formControlName="grnNo" 
                        matInput type="text"   />          
                      </mat-form-field>  
  
                      </div>
  
                      <div class="col-sm-3 pl-1 pr-1">
                        <mat-form-field class="example-full-width lable_style2" appearance="outline" >
                            <mat-label>Supplier Name</mat-label>
                            <mat-select formControlName="supplierName" >
                                <mat-option value="Supplier 1">Supplier 1</mat-option>
                                <mat-option value="Supplier 2">Supplier 2</mat-option>
                                <mat-option value="Supplier 3">Supplier 3</mat-option>
                                <mat-option value="Supplier 4">Supplier 4</mat-option>
                            </mat-select>
                          </mat-form-field>
                      </div>
  
                      <div class="col-sm-3 pl-1 pr-1">
                        <mat-form-field class="example-full-width lable_style2" appearance="outline">
                            <mat-label class="lable_style2">Transporter Name</mat-label>
                        <input placeholder="Name" formControlName="transporterName" 
                        matInput type="text"   />          
                      </mat-form-field>  
                      </div>
  
                      <div class="col-sm-3 pl-1 pr-1">
                        <mat-form-field class="example-full-width lable_style2" appearance="outline">
                            <mat-label class="lable_style2">GRN Date</mat-label>
                            <input  matInput [matDatepicker]="Date" formControlName="grnDate"
                         id="Date" placeholder="DD/MM/YYYY">
                       
                        <mat-datepicker-toggle matSuffix [for]="Date">
                        </mat-datepicker-toggle>
                        <mat-datepicker #Date></mat-datepicker>
                    </mat-form-field>
                      </div>
  
  
                      <div class="col-sm-3 pl-1 pr-1">
                        <mat-form-field class="example-full-width lable_style2" appearance="outline">
                            <mat-label class="lable_style2">PO Number</mat-label>
                        <input placeholder="PO" formControlName="poNumber"
                        matInput type="text"   />          
                      </mat-form-field>  
                      </div>
                      <div class="col-sm-3 pl-1 pr-1">
                        <mat-form-field class="example-full-width lable_style2" appearance="outline">
                            <mat-label class="lable_style2">PO Date</mat-label>
                            <input  matInput [matDatepicker]="Date2" formControlName="poDate"
                         id="Date2" placeholder="DD/MM/YYYY">
                       
                        <mat-datepicker-toggle matSuffix [for]="Date2">
                        </mat-datepicker-toggle>
                        <mat-datepicker #Date2></mat-datepicker>
                    </mat-form-field>
                      </div>
                      <div class="col-sm-3 pl-1 pr-1">
                        <mat-form-field class="example-full-width lable_style2" appearance="outline">
                            <mat-label class="lable_style2">Invoice No</mat-label>
                        <input placeholder="Invoice" formControlName="invoiceNo"
                        matInput type="text"   />          
                      </mat-form-field> 
                      </div>
  
                      <div class="col-sm-3 pl-1 pr-1">
                        <mat-form-field class="example-full-width lable_style2" appearance="outline">
                            <mat-label class="lable_style2">Invoice Date</mat-label>
                        <input placeholder="Date" formControlName="invoiceDate"
                        matInput type="text"   />          
                      </mat-form-field>  
                      </div>
  
                    
                      <div class="col-sm-12">
                        <div class="white_box QA_section card_height_100">
                            <div class="table-responsive">
                            <table
                            class="table lms_table_active2 dataTable no-footer dtr-inline table-bordered"
                            id="DataTables_Table_0"
                            role="grid"
                          >
                          <thead>
                            <tr class="text-center">
                              <th>Device</th>
                              <th>Qty</th>
                              <th>Rate</th>
                              <th>CDL No</th>
                              <th>Warrenty Period</th>
                              <th>Amount</th>
                              <th ></th>
                            </tr>
                          </thead>
                            <tbody formArrayName="device">
                   
                              <tr [formGroupName]="i"
                              *ngFor="let row of goodInWordReturnForm.get('device')['controls']; let i = index; let last = last"
                                role="row"
                                class="odd "
                              >
                                <td>
                                 
                          
                                        <mat-form-field class="example-full-width lable_style2 widthh" appearance="outline">
                                            <mat-label class="lable_style2">Serial Number</mat-label>
                                            <mat-select required formControlName="serialNumber" class="colorblack">
                                                <mat-option>None</mat-option>
                                                  <mat-option value="Device 1">Device 1</mat-option>
                                                <mat-option value="Device 2">Device 2</mat-option>
                                                <mat-option value="Device 3">Device 3</mat-option>
                                                <mat-option value="Device 4">Device 4</mat-option>
                                              </mat-select>
                                      </mat-form-field>
                               
                                 
                                </td>
                                <td> 
                                
                                        <mat-form-field class="example-full-width lable_style2  widthh" appearance="outline">
                                            <mat-label class="lable_style2">Qty</mat-label>
                                        <input placeholder="Qty" formControlName="qty" 
                                        matInput type="text"   />
                                      
                                      </mat-form-field>
                                 
                                </td>
                                <td>  
                                 
                                            <mat-form-field class="example-full-width lable_style2 widthh" appearance="outline">
                                                <mat-label class="lable_style2">Rate</mat-label>
                                            <input placeholder="Rate" formControlName="rate" 
                                            matInput type="text"   />
                                          
                                          </mat-form-field>
                                         
                        </td>
                        <td>  
                          
                                    <mat-form-field class="example-full-width lable_style2 widthh" appearance="outline">
                                        <mat-label class="lable_style2">CDL No</mat-label>
                                    <input placeholder="CDL No" formControlName="cdlNo" 
                                    matInput type="text"   />
                                  
                                  </mat-form-field>
                                  
                </td>
                <td>  
                
                            <mat-form-field class="example-full-width lable_style2 widthh " appearance="outline">
                                <mat-label class="lable_style2">Warrenty Period</mat-label>
                            <input placeholder="Warrenty Period" formControlName="warrentyPeriod" 
                            matInput type="text"   />
                          
                          </mat-form-field>
                        
            </td>
            <td>  
          
                        <mat-form-field class="example-full-width lable_style2 widthh " appearance="outline">
                            <mat-label class="lable_style2">Amount</mat-label>
                        <input placeholder="Amount" formControlName="amount" 
                        matInput type="text"   />
                      
                      </mat-form-field>
                      
            </td>
            <td>  
                <!-- <i matTooltip="Delete" (click)="deleteRow(i)" class="fas fa-plus mt-3"></i> -->
                <img class="pl-1 mt-2" (click)="addNew();" matTooltip="Add" width="30" src="../../assets/add-button.png">
                    
            </td>
            
                              </tr>
                            </tbody>
                          </table>
                          <hr>
                    
            </div>     
                              </div>
                            
                           
                            
                              </div>
                             
                              <!-- <div class="col-sm-3 mt-2">
                                <button *ngIf="submitButton" class="btn btn-primary example-full-width" >Save Changes</button>
                                <button *ngIf="updateButton" class="btn btn-primary example-full-width" >Update Changes</button>
                              </div> -->
                              <div class="col-sm-7">
                                </div>
              <div class="col-sm-4" >
                <label><b>Total Amount Rs.</b> </label><br>
                <label >Total Tax Rs.</label><br>
                <label>Total Invoice Amount Rs.</label>
                </div>
                <div class="col-sm-1 close_style" >
                  <label><b>0.00</b> </label><br>
                  <label > 0.00</label><br>
                  <label> 0.00</label>
                </div>
               
                 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          

          <div class="row mt-2">
            <div class="col-sm-12 pr-4">
              <div class="row">
                <div class="card divbody boxsssss borderradius p-3 mb-3 pb-0">
                  <div class="col-sm-12">
                    <div class="row">
                      <div class="col-sm-8 pl-1 pr-1">
                        <h1 class="titlecolor"> Goods Inword Return List</h1>
                      </div>
                      <div class="search-box col-sm-4 pb-2">
                        <input class="form-control amber-border" type="text" placeholder="Search" 
                        formControlName="searchGoods" aria-label="Search"
                        onkeydown="return (event.keyCode!=13);">
                        <div class="input-group-append">
                           <span class="input-group-text amber lighten-3" id="basic-text1"><span class="material-symbols-outlined">search</span></span>
                        </div>
                     </div>
                   
                      <div class="col-sm-12">
                        <table class="table table-hover table-striped table-bordered">
                          <thead>
                            <tr class="text-center">
                       
                              <th>GRN No</th>
                              <th>Transporter Name </th>
                              <th>Supplier Name </th>
                              <th>PO Name</th>
                              <th>Invoice No</th>
                              <th >Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="text-center">
                              <td class="pt-1 pb-1 colorblack">
                             898877
                              </td>
                              <td class="pt-1 pb-1 colorblack">
                               SLK Transport
                              </td>
                              <td class="pt-1 pb-1 colorblack">
                              Parle Supply
                              </td>
                              <td class="pt-1 pb-1 colorblack">
                                Sharad
                                </td>
                              <td class="pt-1 pb-1 colorblack">
                               998277
                              </td>
                            
                                <td class="colorblack text-left"> 
                                  <i matTooltip="View" class="fas fa-eye"></i> </td>
                                <!-- <button class="btn btn-secondary btn-sm" (click)="prepopulation(item._id)"><i class="fas fa-eye text-white"></i></button>
                                <button class="btn btn-danger btn-sm" (click)="deleteDoctor(item._id)"><i class="fas fa-trash"></i></button> -->
                             
                            </tr>
                          </tbody>
                        </table>
                        
                      </div>
                    
                    </div>
                    <pagination-controls id="batchpaginate" directionLinks="true"  
                    (pageChange)="onPageChange($event)" 
                      class="float-right pt-3"></pagination-controls>
                  </div>
                </div>
              </div>
  
            </div>
       </div>
     
      </div>
    </div>
  </div>