<div class="dialog-wrapper">
    <div class="row">
           
      <div class="col-sm-11">
   
     <label *ngIf="dataFrom == 'Add Device'">  Bill Generate Confirmation :</label>
     <label *ngIf="dataFrom == 'billing Form'">  Receipt Generate Confirmation :</label>
          <!-- <h1 class="titlecolor" >  Search Patients</h1>   -->
      </div>
      <div class="col-sm-1 "> 
        <div class="page-title-right row justify-content-end d-flex align-items-center float-end">
          <a (click)="onClose('')" matTooltip="Close" class="d-flex align-items-center pr-2"><span class="material-symbols-outlined pr-2">close</span></a>
          </div>
      </div> 
   
    <div class="col-sm-12 mb-4">
      <h4 *ngIf="dataFrom == 'Add Device'" mat-dialog-title class="title"> Do you really want to generate bill for {{pName}}?</h4>
      <h4 *ngIf="dataFrom == 'billing Form'" mat-dialog-title class="title"> Do you really want to generate Receipt for,<br> {{billNo}} this Bill No?</h4>
     </div>   
     <div class="col-sm-3"></div>
     <div class="col-sm-3">
      <button type="button" class="btn btn-primary btn-sm example-full-width" 
      (click)="confirmSubmit()">Yes</button>
     </div>
     <div class="col-sm-3">
      <button type="button" class="btn btn-primary btn-sm example-full-width" 
      (click)="confirmCancel()">No</button>
     </div>
     <div class="col-sm-3"></div>
    </div>
    </div>
     