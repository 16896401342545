<div class="container-fluid bgcolor2" [formGroup]="usersMasterForm" >
  <form [formGroup]="usersMasterForm" autocomplete="off" novalidate>
    <div class="row">
      
      <div class="col-sm-12">
        <div class="row ">
          <div class="col-sm-12">
              <div class="row">
                 
                  <div class="card divbody  mb-3">
                    <div class="row pl-0 mr-1 pr-1">

                      <div class="col-12 pl-0 ">
                        <div class="row ">
                        <div class="col-sm-9" id="toUserForm" > 
                        
                            <!-- <h1 class="pt-2 pl-0" id="toClinicalStaffForm">Clinical Staff</h1> -->
                            </div>
                            <div class="col-sm-3 mt-1">
                              <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                                <a (click)="onback()" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">arrow_back</span>Back</a>
                                <a (click)="toUserList()" class="d-flex align-items-center ms-3 pr-2 mt-2"><span class="material-symbols-outlined pr-2">reorder</span>List</a>
                           </div>
                      </div>
                    </div>
                   </div>
                   </div>

                    <div class="row" >
              <div class="col-sm-2 mt-0 pt-0">
                <!-- <h1 class="titlecolor"> Patient Master</h1> -->
                    <div class="col-sm-12 bg-secondary rounded-3 p-1 mb-2 ml-2" >
                     
                        
                      <div class="col-sm-12 ">
                      <img *ngIf="!patientImage" class=" img2 frame  " src="../../assets/user2.png" alt="Amanda Wilson" width="500rem"><!--rounded-circle-->  
                        <img *ngIf="patientImage" class=" img2 frame " [src]="patientImage" alt="patient photo" width="500rem"><!--rounded-circle-->
                        <!-- <button class="btn btn-light shadow btn-sm mb-2" type="button"><i class="ai-refresh-cw me-2"></i>Change avatar</button> -->
                        
                    </div> 
                    <div class="col-sm-12 btnpossition "  >
                      <!-- <h1 class="fass p-0 m-0"> Patient Master</h1> -->
                      <input #fileUpload type="file" style="display: none;" (change)="onChange($event)" />
                      <button type="button" class="btn btn-light shadow btn-sm m-0 mb-1 example-full-width"
                       matTooltip="Upload JPG, GIF or PNG image.300 x 300 required." 
                       (click)="fileUpload.click()" ><i class="ai-refresh-cw me-2" ></i>Upload Photo</button>
                       <!-- <a [routerLink]="" class="p-0 m-0">Upload Photo</a>   -->
                       <!-- <button type="button" class="btn btn-light shadow btn-sm m-0 mb-1 example-full-width"
                       (click)="openChangePasswordPopUp()" ><i class="ai-refresh-cw me-2" ></i>Change Password </button> -->
                    </div>
                        
                      </div>
                </div>
           
                      <div class="col-sm-10">
                          <div class="row p-2"> 
                            <div class="col-sm-3">
                              <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>User Type</mat-label>
                                <mat-select  formControlName="userType">
                                  <mat-option (click)="doctorSelect()" value="Doctor">Audiologists</mat-option>
                                  <mat-option (click)="clinicalStaffSelect()" value="Clinical Staff">Clinical Staff</mat-option>
                                  <mat-option (click)="otherSelect()" value="Other">Other</mat-option>
                                  </mat-select>
                               
                              </mat-form-field> 
                              <mat-error class="margin_tp"
                              *ngIf="usersMasterForm.controls['userType'].touched && usersMasterForm.controls['userType'].errors?.required">
                              This field is mandatory.
                            </mat-error> 
                            </div>
                            <div *ngIf="isDoctor" class="col-sm-3">
                              <div class="row">
                                <div class="col-sm-10 pr-1"> 
                              <mat-form-field
                                class="example-full-width" 
                                appearance="outline" >
                                <mat-label>Audiologists Name</mat-label>
                                <mat-select required #select1 (click)="fetchDoctorList();" formControlName="doctorRefId" >
                                  <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'firstName'"
                                  [array]="doctorList"  (filteredReturn)="filteredListDoctor =$event"></mat-select-filter>
                                <mat-option *ngFor="let item of filteredListDoctor" (click)="doctorClick(item.firstName,item.lastName,item.branchRefId,item._id)" [value]="item._id"
                                >
                                 {{ item.firstName }}  {{ item.lastName }}</mat-option>
                                </mat-select>
                               
                              </mat-form-field>
                              <mat-error class="margin_tp"
                              *ngIf="usersMasterForm.controls['doctorRefId'].touched && usersMasterForm.controls['doctorRefId'].errors?.required">
                              This field is mandatory.
                            </mat-error>
                            </div>
                            <div class="col-sm-2  no-print">
                              <i (click)="openDoctorPopUp();" matTooltip="Add New Audiologists" 
                              class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                                 </div>
               
                            </div>
                            </div>
                            <div *ngIf="isclinicalStaff" class="col-sm-3">
                              <div class="row">
                                <div class="col-sm-10 pr-1"> 
                              <mat-form-field
                                class="example-full-width"
                                appearance="outline" >
                                <mat-label>Clinical Staff Name</mat-label>
                                <mat-select required #select1 (click)="fetchClinicalStaffList();" formControlName="clinicalStaffRefId" >
                                  <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'staffMemberName'"
                                  [array]="clinicalStaffList"  (filteredReturn)="filteredListClinicalStaff =$event"></mat-select-filter>
                                <mat-option *ngFor="let item of filteredListClinicalStaff" 
                                (click)="clinicalClick(item.staffMemberName,item.branchRefId)" [value]="item._id"
                                >
                                 {{ item.staffMemberName }}</mat-option>
                                </mat-select>
                               
                              </mat-form-field>
                              <mat-error class="margin_tp"
                              *ngIf="usersMasterForm.controls['clinicalStaffRefId'].touched && usersMasterForm.controls['clinicalStaffRefId'].errors?.required">
                              This field is mandatory.
                            </mat-error>
                            </div>
                            <div class="col-sm-2  no-print">
                              <i (click)="openClinicalStaffPopUp();" matTooltip="Add New ClinicalStaff" 
                              class="blue_col mTop fa-solid fa-circle-plus fa-lg"></i>
                                 </div>
                            
                            </div>
                            </div> 
                            <div *ngIf="isOther" class="col-sm-3">
                              <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Name</mat-label>
                                <input matInput placeholder="User Name" formControlName="userName" />
                                
                            
                              </mat-form-field>
                              <mat-error class="margin_tp"
                                *ngIf="usersMasterForm.controls['userName'].touched && usersMasterForm.controls['userName'].errors?.required">
                                This field is mandatory.
                              </mat-error>
                             
                            </div>
                            <div class="col-sm-3">
                              <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>User ID</mat-label>
                                <input matInput class="useridclass" placeholder="XYZ OR xyz" formControlName="userID" />
                                
                            
                              </mat-form-field>
                              <mat-error class="margin_tp"
                                *ngIf="usersMasterForm.controls['userID'].touched && usersMasterForm.controls['userID'].errors?.required">
                                This field is mandatory.
                              </mat-error>
                              <mat-error class="margin_tp"
                              *ngIf="usersMasterForm.controls['userID'].touched && usersMasterForm.controls['userID'].errors?.pattern">
                              Please enter valid user Id.
                            </mat-error>
                            <mat-error class="margin_tp"
                              *ngIf="usersMasterForm.controls['userID'].touched && usersMasterForm.controls['userID'].errors?.maxlength">
                              Enter maximum length of 25 characters or alphanumeric user Id.
                            </mat-error>
                            <mat-error class="margin_tp"
                              *ngIf="usersMasterForm.controls['userID'].touched && usersMasterForm.controls['userID'].errors?.minlength">
                              Enter minimum length of 5 characters or alphanumeric user Id.
                            </mat-error>
                            </div>
                            <div class="col-sm-3">
                              <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Email Id</mat-label>
                                <input class="emailClass"  type="email" matInput formControlName="emailId" placeholder="email id" 
                                   />
                                  

                              </mat-form-field>
                              <mat-error class="margin_tp"
                              *ngIf="usersMasterForm.controls['emailId'].touched && usersMasterForm.controls['emailId'].errors?.required">
                              This field is mandatory.
                            </mat-error>
                            <mat-error class="margin_tp"
                            *ngIf="usersMasterForm.controls['emailId'].touched && usersMasterForm.controls['emailId'].errors?.pattern">
                            Please Enter Valid Email.
                          </mat-error>
                            
                            </div>
                            <!-- <div *ngIf="isPassword || submitButton" class="col-sm-3">
                              <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Password</mat-label>
                                <input required type="password" matInput placeholder="Password" formControlName="password" required />
                                <mat-error class="margin_tp"
                                *ngIf="usersMasterForm.controls['password'].touched && usersMasterForm.controls['password'].errors?.required">
                                This field is mandatory.
                              </mat-error>
                              <mat-error class="margin_tp"
                                *ngIf="usersMasterForm.controls['password'].touched && usersMasterForm.controls['password'].errors?.pattern">
                                Please enter strong password.
                              </mat-error>
                              </mat-form-field>
                            
                            </div> -->
                          
                            <div  *ngIf="roleCreate" class="col-sm-3">
                              <div class="row">
                                <div class="col-sm-9 pr-1"> 
                              <mat-form-field
                                class="example-full-width"
                                appearance="outline"
                              >
                                <mat-label>Role</mat-label>
                                <mat-select #select1 (click)="fetchRoleList();" formControlName="roleRefId" >
                                  <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'roleName'"
                                  [array]="rolesList"  (filteredReturn)="filteredListRole =$event"></mat-select-filter>
                                <mat-option *ngFor="let item of filteredListRole" [value]="item._id"
                                >
                                 {{ item.roleName }}</mat-option>
                                </mat-select>
                               
                              </mat-form-field>
                              <mat-error class="margin_tp"
                              *ngIf="usersMasterForm.controls['roleRefId'].touched && usersMasterForm.controls['roleRefId'].errors?.required">
                              This field is mandatory.
                            </mat-error>
                            </div>
                            <div class="col-sm-3 ml-0 pl-0  no-print">
                              <i (click)="openRolePopUp();" matTooltip="Add New Role" 
                              class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                                 </div>
                           
                            </div>
                            </div>
                            <div *ngIf="!roleCreate" class="col-sm-3">
                              <div class="row">
                                <div class="col-sm-12 pr-1"> 
                              <mat-form-field
                                class="example-full-width"
                                appearance="outline"
                              >
                                <mat-label>Role</mat-label>
                                <mat-select #select1 (click)="fetchRoleList();" formControlName="roleRefId" >
                                  <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'roleName'"
                                  [array]="rolesList"  (filteredReturn)="filteredListRole =$event"></mat-select-filter>
                                <mat-option *ngFor="let item of filteredListRole" [value]="item._id"
                                >
                                 {{ item.roleName }}</mat-option>
                                </mat-select>
                               
                              </mat-form-field>
                              <mat-error class="margin_tp"
                              *ngIf="usersMasterForm.controls['roleRefId'].touched && usersMasterForm.controls['roleRefId'].errors?.required">
                              This field is mandatory.
                            </mat-error>
                            </div>
                            </div>
                            </div>
                          
                            <div *ngIf="branchCreate" class="col-sm-3">
                              <div class="row">
                                <div class="col-sm-10 pr-1"> 
                              <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Branch</mat-label>
                            
                                <mat-select #select2 (click)="fetchBranchList();" formControlName="branchRefId" >
                                  <mat-select-filter *ngIf="select2.focused" [placeholder]="'Filter'" [displayMember]="'branchName'"
                                  [array]="BranchList"  (filteredReturn)="filteredListBranch =$event"></mat-select-filter>
                                  <mat-option *ngFor="let item of filteredListBranch" [value]="item._id"
                                  >
                                   {{ item.branchName }}</mat-option>
                                  </mat-select>
                                  
                              </mat-form-field>
                              <mat-error class="margin_tp"
                              *ngIf="usersMasterForm.controls['branchRefId'].touched && usersMasterForm.controls['branchRefId'].errors?.required">
                              This field is mandatory.
                            </mat-error>
                            </div>
                            <div class="col-sm-2  ml-0 pl-0 no-print">
                              <i (click)="openBranchPopUp();" matTooltip="Add New Branch" 
                              class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                                 </div>
                           
                            </div>

                            </div>
                            <div *ngIf="!branchCreate" class="col-sm-3">
                              <div class="row">
                                <div class="col-sm-12"> 
                              <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Branch</mat-label>
                            
                                <mat-select #select2 (click)="fetchBranchList();" formControlName="branchRefId" >
                                  <mat-select-filter *ngIf="select2.focused" [placeholder]="'Filter'" [displayMember]="'branchName'"
                                  [array]="BranchList"  (filteredReturn)="filteredListBranch =$event"></mat-select-filter>
                                  <mat-option *ngFor="let item of filteredListBranch" [value]="item._id"
                                  >
                                   {{ item.branchName }}</mat-option>
                                  </mat-select>
                                  
                              </mat-form-field>
                              <mat-error class="margin_tp"
                              *ngIf="usersMasterForm.controls['branchRefId'].touched && usersMasterForm.controls['branchRefId'].errors?.required">
                              This field is mandatory.
                            </mat-error>
                            </div>
                            </div>

                            </div>
                            <div class="col-sm-3">
                              <mat-form-field
                                class="example-full-width"
                                appearance="outline"
                              >
                                <mat-label> Status</mat-label>
                             
                                <mat-select  formControlName="status">
                                    <mat-option value="Active">Active</mat-option>
                                    <mat-option value="In-active">In-active</mat-option>
                                    </mat-select>
                                   
                              </mat-form-field>
                              <mat-error class="margin_tp"
                              *ngIf="usersMasterForm.controls['status'].touched && usersMasterForm.controls['status'].errors?.required">
                              This field is mandatory.
                            </mat-error>
                            </div>
                           
                         <div class="col-sm-10" *ngIf="submitButton"></div>
                         <div class="col-sm-8" *ngIf="updateButton"></div>
                          <div  class="col-sm-2 mt-2">
                            <button type="button" class="btn btn-primary btn-sm example-full-width" *ngIf="updateButton && permissionUpdate"
                            (click)="updateUser()">Update </button>
                            <button type="button" class="btn btn-primary btn-sm example-full-width" *ngIf="submitButton && permissionCreate"
                            (click)="submitUser()">Next </button>
                          </div>
                          <div class="col-sm-2 mt-2" *ngIf="cancelButton">
                            <button class="btn btn-primary btn-sm  example-full-width" type="reset" (click)="cancelUpdate()">Cancel </button>
                         </div>
                          </div>
                      </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
        <div class="col-sm-12 pl-0 pr-1">
          <div class="row">
              <div class="card divbody borderradius mb-2 p-3 pb-0">
                      <div class="col-sm-12">
                          <div class="row">
                              <div class="col-sm-9 pl-1 pr-1">
                                  <h1 class="titlecolor"> User List</h1>  
                              </div>
                              <div class="search-box col-sm-3 pb-2">
                                <input class="form-control amber-border" type="text" placeholder="Search" 
                                formControlName="searchUser" aria-label="Search" (keyup)="onKeyUpEvent($event)"
                                onkeydown="return (event.keyCode!=13);">
                                <div class="input-group-append">
                                   <span class="input-group-text amber lighten-3" id="basic-text1"><span class="material-symbols-outlined">search</span></span>
                                </div>
                             </div>
                              <!-- <div class="col-sm-3">
                                  <div class="input-group md-form form-sm form-1 pl-0">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text purple lighten-3" id="basic-text1"><i class="fas fa-search text-white"
                                          aria-hidden="true"></i></span>
                                    </div>
                                    <input class="form-control my-0 py-1"  (keyup)="onKeyUpEvent($event)" onkeydown="return (event.keyCode!=13);" 
                                  type="text" placeholder="Search" formControlName="searchUser" aria-label="Search">
                                  </div>
                              </div> -->
                              <div class="col-sm-12 table-responsive">
                                <table id="toUserList" 
                                class="table table-bordered data-table-custom">
                               
                                <thead>
                                  <tr class="text-center">
                                    <th>User No.</th>
                                    <th>Name</th>
                                    <th>User Type</th>
                                    <th>User ID</th>
                                    <th>Email</th>
                                    <th>Status</th>
                                  
                                    <!-- <th>Action</th> -->
                                  </tr>
                                </thead>
                                <tbody  >
                       
                                  <tr
                                  *ngFor="let item of userlist | paginate : { id:'userpaginate', 
                                  itemsPerPage: size, currentPage: page2, totalItems:totalLength } "
                                    role="row"
                                    class="odd text-center"
                                    style="cursor: pointer;" (click)="toUserForm()"
                                 (click)="prepopulation(item._id)"
                                  >
                                  <td *ngIf="item.userType !== 'Super Admin'">{{item.userId}}</td>
                                    <td *ngIf="item.userType !== 'Super Admin'">{{item.userName}}</td>
                                    <td *ngIf="item.userType !== 'Super Admin'">{{item.userType == 'Doctor'?'Audiologist':item.userType}}</td>
                                    <td *ngIf="item.userType !== 'Super Admin'">{{item.userID}}</td>
                                    <td class="emailClass" *ngIf="item.userType !== 'Super Admin'">{{item.emailId}}</td>
                                    <td *ngIf="item.userType !== 'Super Admin'">{{item.status}}</td>
                                  
                                    <!-- <td class="text-center">
                                      <i matTooltip="View" (click)="prepopulation(item._id)" class="fas fa-eye"></i>
                                     </td> -->
                                 
                                  </tr>
                                </tbody>
                              </table>
                              </div>
                              <div class="col-sm-12 pt-0 text-right">
                              <pagination-controls id="userpaginate" directionLinks="true"  
                              (pageChange)="onPageChange($event)" 
                                class="float-right pt-3 close_style my-pagination"></pagination-controls>
                                </div>
                          </div>
                      </div>
                  </div>
              </div>
              
          </div>

  </div>
    </div>
    </form>
  </div>
  