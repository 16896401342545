import { DatePipe } from '@angular/common';
import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { ApiServiceService } from '../api-service.service';

@Component({
  selector: 'app-pop-up-invoice',
  templateUrl: './pop-up-invoice.component.html',
  styleUrls: ['./pop-up-invoice.component.scss']
}) 
export class PopUpInvoiceComponent implements OnInit {
  listofbilling: any;
  userType: string;
  clinicRef: string;
  billingList: any;
  branchRef: string;
  branchName: string;
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService, 
    public router: Router,
     public dialog: MatDialog,
    public datepipe: DatePipe, 
    private spinner: NgxSpinnerService,
    public route : ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: {},
    private dialogRef: MatDialogRef<PopUpInvoiceComponent>
  ) { dialogRef.disableClose = true; }
  invoicePopUpForm: FormGroup;
  page:number = 1;
  size:number = 5;
  listOfPatients: any;
  searchText: any;
  totalLength:number;
  ngOnInit(): void { 
  this.page=1;
  this.userType = sessionStorage.getItem('userType');
  this.clinicRef = sessionStorage.getItem('clinicRef');
  this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.invoicePopUpForm = this.fb.group({
      "patientId": new FormControl(''),
      "patientName": new FormControl(''),
      "billNo": new FormControl(''),
      "status": new FormControl(''),
      "fdate": new FormControl(''),
      "todate": new FormControl(''),
      "docketNo": new FormControl('',[Validators.pattern("^[0-9]*$")]), 
      "searchBilling": new FormControl(''), 
    });
    this.fetchBilling();
  }
  fetchBilling() {
    let search='';
    let patientId = '';
    let patientName = '';
    let billNo = '';
    let status = '';
    let fromDate = '';
    let toDate = '';
    let branchRef= sessionStorage.getItem('branchRef');
    if(this.userType !== "Super Admin"){
      branchRef = sessionStorage.getItem('branchRef');
    }else{
      branchRef = sessionStorage.getItem('branchRef');
    }
    this.apimicroservice.getAllBillingDetails(this.page,this.size,search,patientId,patientName,billNo,status,fromDate,toDate,branchRef).subscribe(res => {
      //  
      if (res.status) {    
        this.billingList = res.data.data;
        this.totalLength=res.data.totalItems;
        
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    });
  }
  toPatientForm(e) { 
    //
    let fromG = localStorage.getItem('fromGlobal');
    if(fromG == 'true') 
    { 
      this.dialogRef.close();
    //  this.router.navigate(['/home/patient-global-search'],{ queryParams: { PatientId: e._id } })
      // this.router.navigate(['/global-dashboard'],{ queryParams: { PatientId: e.patientRefId      } });
      this.router.navigate(['/global-dashboard'],{ queryParams: { PatientId: e.patientRefId } });
    }
    else  
    {
      this.dialogRef.close({ data: e })
    }
  }
  onPageChange(event) {
    this.page = event;
    this.searchText = this.invoicePopUpForm.get('searchBilling').value;
    let patientId = this.invoicePopUpForm.get('patientId').value;
    let patientName = this.invoicePopUpForm.get('patientName').value;
    let billNo = this.invoicePopUpForm.get('billNo').value;
    let status = this.invoicePopUpForm.get('status').value;
    let fromDate = this.invoicePopUpForm.get('fdate').value;
    let toDate = this.invoicePopUpForm.get('todate').value;
    let branchRef= sessionStorage.getItem('branchRef');
    if(this.userType !== "Super Admin"){
      branchRef = sessionStorage.getItem('branchRef');
    }else{
      branchRef = sessionStorage.getItem('branchRef');
    }
    this.apimicroservice.getAllBillingDetails(this.page,this.size,this.searchText,patientId,patientName,billNo,status,fromDate,toDate,branchRef).subscribe(res => {
      //  
      if (res.status) {    
        this.billingList = res.data.data;
        this.totalLength=res.data.totalItems;    
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    });
  }
  onKeyUpEvent(event: any) {    
    this.searchText = this.invoicePopUpForm.get('searchBilling').value;
    let patientId = this.invoicePopUpForm.get('patientId').value;
    let patientName = this.invoicePopUpForm.get('patientName').value;
    let billNo = this.invoicePopUpForm.get('billNo').value;
    let status = this.invoicePopUpForm.get('status').value;
    let fromDate = this.invoicePopUpForm.get('fdate').value;
    let toDate = this.invoicePopUpForm.get('todate').value;
    //
    //
    let branchRef= sessionStorage.getItem('branchRef');
    if(this.userType !== "Super Admin"){
      branchRef = sessionStorage.getItem('branchRef');
    }else{
      branchRef = sessionStorage.getItem('branchRef');
    }
    this.apimicroservice.getAllBillingDetails(this.page,this.size,this.searchText,patientId,patientName,billNo,status,fromDate,toDate,branchRef).subscribe(res => {
      //  
      if (res.status) {    
        this.billingList = res.data.data;
        this.totalLength=res.data.totalItems;
        
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    });
  }
  onClose(): void {
    this.dialogRef.close();
  }
}
