import { Component, Inject, OnInit } from "@angular/core";
import {FormGroup,FormBuilder,FormControl, Validators} from '@angular/forms';
import { ApiServiceService } from '../api-service.service';
import { NgxSpinnerService } from "ngx-spinner"; 
import { HttpErrorResponse } from '@angular/common/http';
import { PopUpBranchComponent } from "../pop-up-branch/pop-up-branch.component";
import { MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-pop-up-can-number',
  templateUrl: './pop-up-can-number.component.html',
  styleUrls: ['./pop-up-can-number.component.scss']
})
export class PopUpCanNumberComponent implements OnInit {
  popupCanNumberForm: FormGroup;
  branchRef: string;
  clinicRef: string;
  userType: string;
  BranchList: any;
  filteredListBranch: any;
  
  constructor(private fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<PopUpCanNumberComponent>,@Inject(MAT_DIALOG_DATA) public data:any, 
    public apimicroservice: ApiServiceService,private spinner: NgxSpinnerService) { 
      dialogRef.disableClose = true;
    }

  ngOnInit(): void {
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.userType = sessionStorage.getItem('userType');
    this.popupCanNumberForm = this.fb.group({  
      //"canId": new FormControl(''),
      "clinicRefId": new FormControl(), 
      "branchRefId": new FormControl(),
      "canNumber": new FormControl('',[Validators.required, Validators.pattern("^[a-zA-Z0-9 -]+$")]),
      "createdAt": new Date(), 
    });
    this.fetchBranchList();
    this.getAllBranch();
  }
  getAllBranch()
  {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      if (res.status) {        
        this.popupCanNumberForm.controls['branchRefId'].setValue(res.data[0]._id);
        this.popupCanNumberForm.controls["clinicRefId"].setValue(res.data[0].clinicRefId);
        this.branchClick(res.data[0]._id);
        if(this.userType == 'Super Admin')
        {  
          this.apimicroservice.getBranchById(res.data[0]._id).subscribe(res => {
            this.spinner.hide();
            //this.popupCanNumberForm.controls['branchName'].setValue(res.data[0].branchName);
           });
        }
       
      }
    });
  }

  saveCanNumber(){
    let payload = this.popupCanNumberForm.value; 
    //
    if(this.userType !== 'Super Admin')
    {
      payload.clinicRefId = this.clinicRef;
      payload.branchRefId = this.branchRef;
    }
    this.spinner.show(); 
    this.apimicroservice.submitCanNumberData(payload).subscribe(res => {
      //
      this.spinner.hide();
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);      
        this.ngOnInit();
       this.onClose();        
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    } ,(ERROR:HttpErrorResponse) => {
      this.apimicroservice.openSnackbar(ERROR.error.message);
      this.spinner.hide();
      this.onClose();    
  });
  }
  save() {
    if (this.popupCanNumberForm.invalid) {
      this.popupCanNumberForm.get("canNumber").markAsTouched();
      return;
    } else {
      this.saveCanNumber();
    }
  }
  
  
  onClose(): void {
    this.dialogRef.close();
  }
  fetchBranchList() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      //  
      if (res.status) {    
        this.BranchList = res.data;
        this.filteredListBranch= this.BranchList.slice();
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    })
  } 
  openBranchPopUp() {
    localStorage.clear(); 
    const dialogRef3 = this.dialog.open(PopUpBranchComponent,
      {
        width: "1200px",
      });
      dialogRef3.afterClosed().subscribe((result) => {
        this.fetchBranchList();
      })
  }
  branchClick(id)
  {
    this.spinner.show();
   this.apimicroservice.getBranchById(id).subscribe(res => { 
     let payload = this.popupCanNumberForm.value;
      payload.clinicRefId = res.data[0].clinicRefId;
      this.popupCanNumberForm.controls["clinicRefId"].setValue(res.data[0].clinicRefId);
        this.spinner.hide();
       
    });
  }
}

