import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiServiceService } from '../api-service.service';
import { GetColorName } from "hex-color-to-color-name";
import { TitleCasePipe } from '@angular/common';
@Component({
  selector: 'app-patient-tracking-status',
  templateUrl: './patient-tracking-status.component.html',
  styleUrls: ['./patient-tracking-status.component.scss']
})
export class PatientTrackingStatusComponent implements OnInit {
  patientTrackingStatusForm: FormGroup;
  listOfStatus:any;
  totalLength:any;
  searchText:any;
  page = 1;
  size =3;
  sid:any; 
  submitButton = true;
  updateButton = false;
  cancelButton = false;
  constructor(public dialogRef: MatDialogRef<PatientTrackingStatusComponent>,@Inject(MAT_DIALOG_DATA) public data:any,
  public apimicroservice: ApiServiceService,private spinner: NgxSpinnerService,
  private fb: FormBuilder,
  private titlecasePipe:TitleCasePipe,
  ) { 

    dialogRef.disableClose = true; 
    
  } 
  userType: string;

  ngOnInit(): void { 
    this.userType = sessionStorage.getItem('userType') || '';
    this.page = 1;
    this.patientTrackingStatusForm = this.fb.group({  
      "trackingStatusName": new FormControl('',[Validators.required,Validators.pattern("^[a-zA-Z0-9 -.]+$")]),
      "color": new FormControl('',[Validators.required]),
      "colorName": new FormControl(''),
      "clinicRefId": new FormControl(sessionStorage.getItem('clinicRefId') || ''),
      "branchRefId": new FormControl(sessionStorage.getItem('branchRefId') || ''),
      "createdAt": new Date(),
      "search": new FormControl(''),
    });
    this.fetchStatusList();
  }
 

  fetchStatusList() {
    let pg = 1;
    let size = 3; 
    let search=''; 
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
        this.apimicroservice.getTrackingStatusData(pg,size,search,branchRef,clinicRef).subscribe(res => {
          //
          
           this.listOfStatus = res.data.data;
           this.totalLength = res.data.totalItems;
           //this.buildingLenght = this.listofbuild.length;
          if(res.status){
           // this.apimicroservice.openSnackbar(res.message);
            
          }else{
            //this.apimicroservice.openSnackbar(res.message);
          }
     
        });  
      }else{
        this.apimicroservice.getTrackingStatusData(pg,size,search,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
          //
          
           this.listOfStatus = res.data.data;
           this.totalLength = res.data.totalItems;
           //this.buildingLenght = this.listofbuild.length;
          if(res.status){
           // this.apimicroservice.openSnackbar(res.message);
            
          }else{
            //this.apimicroservice.openSnackbar(res.message);
          }
     
        }); 
      } 
 
  }
  saveTrackingStatus(){
    let payload = this.patientTrackingStatusForm.value; 
    let clinicRefId = sessionStorage.getItem("clinicRef"); 
    let branchRefId = sessionStorage.getItem("branchRef"); 

    payload.clinicRefId = clinicRefId;
    payload.branchRefId = branchRefId;
    //
    this.spinner.show();
    payload.trackingStatusName = this.titlecasePipe.transform(payload.trackingStatusName);
    this.apimicroservice.saveTrackingStatus(payload).subscribe(res => {
      //
      this.spinner.hide();
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);      
        //this.ngOnInit();
       this.onClose();        
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    },(ERROR:HttpErrorResponse) => {
      this.apimicroservice.openSnackbar(ERROR.error.message);
      this.spinner.hide();
      this.onClose();    
  });
  }
  save() {
    if (this.patientTrackingStatusForm.invalid) {
      this.patientTrackingStatusForm.get("trackingStatusName").markAsTouched();
      this.patientTrackingStatusForm.get("color").markAsTouched();
      return;
    } else {
      this.saveTrackingStatus();
    }
  }
  onKeyUpEvent(event: any) {
    this.searchText = this.patientTrackingStatusForm.get('search').value;
    let page=1;
    let size=3;
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
    this.apimicroservice.getTrackingStatusData(page,size,this.searchText,branchRef,clinicRef).subscribe(res => {
      this.listOfStatus = res.data.data;
      this.totalLength = res.data.totalItems;
      this.page=res.data.pageNumber;
       if(res.status){
        //  this.apimicroservice.openSnackbar(res.message);    
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
    }else{
      this.apimicroservice.getTrackingStatusData(page,size,this.searchText,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
        this.listOfStatus = res.data.data;
        this.totalLength = res.data.totalItems;
        this.page=res.data.pageNumber;
         if(res.status){
          //  this.apimicroservice.openSnackbar(res.message);    
         }else{
           //this.apimicroservice.openSnackbar(res.message);
         }
       });
    }
  }

  prepopulation(id) { 
    this.apimicroservice.getTrackingStatusById(id).subscribe(res => {
      let data = res.data[0];
      this.sid = id;
       if(res.status){
        this.submitButton = false;
         this.updateButton = true;
         this.cancelButton = true;
         this.patientTrackingStatusForm = this.fb.group({
          "trackingStatusName": new FormControl(data.trackingStatusName,[Validators.required,Validators.pattern("^[a-zA-Z0-9 -.]+$")]),
          "color": new FormControl(data.color,[Validators.required]),
          "search": new FormControl(''),
          "colorName": new FormControl(data.colorName),
   "updateddAt": new Date(),
         });
       }else{
     //    //this.apimicroservice.openSnackbar(res.message);
       }
     });
  }
  update() {
    if (this.patientTrackingStatusForm.invalid) {
      this.patientTrackingStatusForm.get("trackingStatusName").markAsTouched();
      this.patientTrackingStatusForm.get("color").markAsTouched();
      return;
    } else {
      this.updateTrackingStatusData();
    }
  }
  updateTrackingStatusData() {
    let payload = this.patientTrackingStatusForm.value;
    let clinicRefId = sessionStorage.getItem("clinicRef"); 
    let branchRefId = sessionStorage.getItem("branchRef"); 

    payload.clinicRefId = clinicRefId;
    payload.branchRefId = branchRefId;
     this.apimicroservice.updateTrackingStatusData(payload,this.sid).subscribe(res => {
       if(res.status){
         this.apimicroservice.openSnackbar(res.message);
          this.updateButton = false;
          this.submitButton = true;
          this.ngOnInit();
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
    
  }
  onPageChange(event) {
    this.page = event;
   // let p = this.page - 1;
    this.searchText = this.patientTrackingStatusForm.get('search').value;
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
    this.apimicroservice.getTrackingStatusData(this.page,this.size,this.searchText,branchRef,clinicRef).subscribe(res => {
      //
      
      this.listOfStatus = res.data.data;
      this.totalLength = res.data.totalItems;
         if(res.status){
          //  this.apimicroservice.openSnackbar(res.message);
           
         }else{
           //this.apimicroservice.openSnackbar(res.message);
         }
    
       });
      }else{
        this.apimicroservice.getTrackingStatusData(this.page,this.size,this.searchText,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
          //
          
          this.listOfStatus = res.data.data;
          this.totalLength = res.data.totalItems;
             if(res.status){
              //  this.apimicroservice.openSnackbar(res.message);
               
             }else{
               //this.apimicroservice.openSnackbar(res.message);
             }
        
           });
      }
  
  }
  cancelUpdate(){
    this.patientTrackingStatusForm.reset();
    this.submitButton=true;
    this.updateButton=false;
    this.cancelButton=false;
    this.ngOnInit();
  }
  onClose(): void {
    this.dialogRef.close(); 
  }
  onSelectColor(event:any){
    //
    let val = event.target.value; 
    const colorName = GetColorName(val);
    this.patientTrackingStatusForm.controls.colorName.setValue(colorName);
      //.value);
    }
}
