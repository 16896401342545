<div class="container-fluid bgcolor2" [formGroup]="followUpForm">
    <form [formGroup]="followUpForm" autocomplete="off" novalidate>
   
          <!-- <div class="row pl-0 ">
            <div class="col-12 pl-0 ">
              <div class="row ">
              <div class="col-sm-9"> 
              
                  <h1 class="pt-2 pl-0" id="toFollowupForm">Follow Up</h1>
                  </div> 
                  <div class="col-sm-3 mt-1">
                    <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                <a class="d-flex align-items-center ms-3 pr-2 mt-2"><span class="material-symbols-outlined pr-2">close</span></a>
                    </div>
            </div>
          </div>
         </div> 
         </div> -->
  
  
         <div class="row pt-1">
          <div class="col-sm-12 pb-2">
              <!-- <div class="row margingTop pb-2">
                <div class="card divbody boxsssss borderradius pr-3 pb-2 p-1 " >

 
 

                  <div class="col-sm-12 mt-3">
                    <div class="row">
                        <div class="col-sm-12 ">
                            <h1 class="titlecolor">Follow Up</h1>
                        </div>
                     

                    <div class="col-sm-12">
                     
                      <div class="row pt-0 mt-0">
                         
                      <div class="col-sm-3">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Follow Up Type </mat-label>
                          <mat-select placeholder="Type"  
                          class="colorblack" 
                          formControlName="followupType" >
                    <mat-option value="In-Person">In-Person</mat-option>
                    <mat-option value="Phone Call">Phone Call</mat-option>
                         </mat-select>   
                        </mat-form-field>
                        <mat-error 
                        *ngIf="followUpForm.controls['followupType'].touched && followUpForm.controls['followupType'].errors?.required">
                            This field is mandatory.
                      </mat-error>
                      </div>
                      <div class="col-sm-3">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Follow Up </mat-label>
                          <mat-select placeholder="Type"  
                          class="colorblack" 
                          formControlName="followupTime" >
                    <mat-option value="One Week" (click)="onSelectFollowupTime()">One Week</mat-option>
                    <mat-option value="Six Month" (click)="onSelectFollowupTime()">Six Month</mat-option>
                    <mat-option value="One Year" (click)="onSelectFollowupTime()">One Year</mat-option>
                    <mat-option value="Custom" (click)="onSelectFollowupTime()">Custom</mat-option>
                         </mat-select>   
                        </mat-form-field>
                        <mat-error 
                        *ngIf="followUpForm.controls['followupTime'].touched && followUpForm.controls['followupTime'].errors?.required">
                            This field is mandatory.
                      </mat-error>
                      </div>
                      <div class="col-sm-3">
                        <mat-form-field class="example-full-width " appearance="outline">
                           <mat-label>Followup Date</mat-label>
                           <input matInput [matDatepicker]="followupDate" placeholder="Choose a date" 
                           formControlName="followupDate" [disabled]="dateDisable">
             
                            <mat-datepicker-toggle matSuffix [for]="followupDate">
                            </mat-datepicker-toggle>
                            <mat-datepicker #followupDate></mat-datepicker>   
                       </mat-form-field> 
                       </div>
                      <div *ngIf="followupId !== undefined" class="col-sm-3">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Status</mat-label>
                          <mat-select placeholder="Type"  
                          class="colorblack" 
                          formControlName="followupStatus" >
                          <mat-option value="Follow Up Scheduled">Follow Up Scheduled</mat-option>
                    <mat-option value="Follow Up Done">Follow Up Done</mat-option>
                    <mat-option value="Not Answered">Not Answered</mat-option>
                    <mat-option value="Rescheduled Follow Up">Rescheduled Follow Up</mat-option>
                         </mat-select>   
                        </mat-form-field>
                 
                      </div>
                      <div class="col-sm-6">
                           
                        <mat-form-field class="example-full-width "  appearance="outline">
                          <mat-label>Comments
                          </mat-label>
                          <textarea matInput formControlName="followupComments"
                          cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                          cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" ></textarea>
                        </mat-form-field>
                      
                    </div> 
                    <div class="row col-sm-12 pl-3 pr-3 mb-2">
                        <div class="col-sm-6"></div>
                        <div class="col-sm-4" *ngIf="submitButton"></div>
                        <div class="col-sm-2 mt-2" *ngIf="submitButton && vendorCreate">
                          <button class="btn mb-2 btn-sm btn-primary example-full-width"
                          (click)="submitFollowup()" type="button" >Save</button>
                        </div>
                        <div class="col-sm-2" *ngIf="updateButton">
                        </div>
                        <div class="col-sm-2 mt-2" *ngIf="updateButton && vendorUpdate">
                          <button type="button" class="btn mb-2 btn-sm btn-primary example-full-width" (click)="updateFollowupDetails()"
                          >Update</button>
                        </div>
                        <div class="col-sm-2 mt-2" *ngIf="updateButton">
                          <button class="btn mb-2 btn-primary btn-sm  example-full-width" (click)="cancelUpdate()"
                          type="reset">Cancel </button>
                        </div>
    
                      </div>
                 
                    </div>
                    </div>
  
                  </div>
  
                  
                   
                     
                   
                  
                </div>
              </div>
            </div> -->
  
          <div *ngIf="followupId == undefined" class="row">
            <div class="col-sm-12 ">
              <div class="row">
                <div class="card divbody  borderradius  mb-2 pt-3 mr-2 pb-0">
                  <div class="col-sm-12">
                    <div class="row">
                      <div class="col-sm-8">
                        <h1 class="titlecolor">  List Of Follow Up</h1>
                      </div>
                     
                      <div class="col-sm-1 justify-content-right">
                        <div class="row">
                            <div class="col-sm-6"></div>
                            <div class="col-sm-6 justify-content-right">
                                <div class="icon-btns pb-4"> 
                                    <!-- (click)="billing(data.patientRefId,data.appointId,data.branchRefId,data)" -->
                                    <a class="icon-box2" data-toggle="tooltip" data-placement="top" matTooltip="Add Follow Up"
                    (click)="openFollowUpPopup()" >
                    <span class="material-symbols-outlined">
                      fact_check
                      </span>
                    <img src="../../assets/icon-bg10.svg" class="icon-bg2">
                    </a> 
                                    </div>
                      </div>
                      </div>
                      </div>
                      <div class="search-box col-sm-3 pb-2">
                        <input class="form-control amber-border" type="text" placeholder="Search" 
                        formControlName="searchFollowup" aria-label="Search" (keyup)="onKeyUpEvent($event)"
                        >
                        <div class="input-group-append">
                           <span class="input-group-text amber lighten-3" id="basic-text1"><span class="material-symbols-outlined">search</span></span>
                        </div>
                     </div>
                     
                      <div class="col-sm-12">
                        <table id="toFollowupTable" 
                        class="table table-bordered data-table-custom"  style="text-align: center;">
                   
                          <thead>
  
                            <tr>
                              <!-- <th>Vendor Id</th>
                              <th *ngIf="userType == 'Super Admin'">Branch Name</th> -->
                              <!-- <th>Patient Name</th> -->
                              <th>Followup Type</th>
                              <th>Followup Time</th>
                              <th>Followup Date</th>
                              <th>Followup Status</th>
                              <th>Followup Comments</th>
  
  
                            </tr>
                          </thead>
                          <tbody>
                            <tr  *ngFor="
                                              let item of listOfFollowups
                                              | paginate : { id:'followup_pagination', 
                                              itemsPerPage: size, currentPage: page, totalItems:totalLength }
                                            " style="cursor: pointer;" (click)="toFollowupForm()" (click)="prepopulation(item._id)">
                              <!-- <td matTooltip="click to view">{{item.branchWiseId}}</td>
                              <td *ngIf="userType == 'Super Admin'" matTooltip="click to view" 
                              >{{item.branchName}}
                            </td> -->
                              <!-- <td matTooltip="click to view">{{item.patientName}}</td> -->
                              <td matTooltip="click to view">{{item.followupType}}</td>
                              <td matTooltip="click to view">{{item.followupTime}}</td>
                              <td matTooltip="click to view">{{item.followupDate | date:'dd/MM/yyyy'}}</td>
                              <td matTooltip="click to view">{{item.followupStatus}}</td>
                              <td matTooltip="click to view">{{item.followupComments}}</td>
  
  
                            </tr>
  
  
                          </tbody>
                        </table>
                      </div> 
                      <div class="col-sm-12 pt-0 text-right">
                        <pagination-controls id="followup_pagination" directionLinks="true"
                          (pageChange)="onPageChange($event)" class="float-right pt-3 my-pagination">
                        </pagination-controls>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
            </div>
          </div>
  
        </div>
      </div>
    </form>
  </div>