import { Component,OnInit,Inject, ElementRef, ViewChild, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { DatePipe } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ApiServiceService } from '../api-service.service';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from "ngx-spinner";
import * as moment from 'moment';
import { element } from 'protractor';
import { PopUpPatientComponent } from '../pop-up-patient/pop-up-patient.component';
import { PopUpBranchComponent } from '../pop-up-branch/pop-up-branch.component';
import { PopUpDoctorComponent } from '../pop-up-doctor/pop-up-doctor.component';
import { PopUpAppointmenttypeComponent } from "../pop-up-appointmenttype/pop-up-appointmenttype.component";
import { Pipe, PipeTransform } from '@angular/core';
import { PopupBookappointmentComponent } from '../popup-bookappointment/popup-bookappointment.component';
import { HttpErrorResponse } from '@angular/common/http'; 
import { PatientSearchPopComponent } from '../patient-search-pop/patient-search-pop.component';
import { MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { PopupCancelConfirmComponent } from '../popup-cancel-confirm/popup-cancel-confirm.component';

@Component({
  selector: 'app-appoinment-popup',
  templateUrl: './appoinment-popup.component.html',
  styleUrls: ['./appoinment-popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
}) 
export class AppoinmentPopupComponent implements OnInit, AfterViewInit {
  @ViewChild("calendar", { static: true }) calendar: ElementRef<any>;
  appointmentPopUp: FormGroup;
  appointtypelist;
  doctorlist; 
  patientlist;
  cliniclist;
  durationList;
  searchText: any;
  updateRefid;
  savebutton = true;
  updatebutton = false;
  isUpdate = false;
  clear = false;
  isSuperAdmin= true;
  delete = false;
  searcheddoctor = false;
  endDateTime: string;
  fieldsOfCalenders: any;
  newAppointment: any;
  arrayCalender = [
    {
      calenderDuration: "Yes",
      calenderAppointmentType: "Yes",
      calenderClinicBranch: "Yes",
      calenderDoctorName: "Yes",
      dailyCalender: "Yes",
      weeklyCalender: "Yes",
      monthlyCalender: "Yes",
    },
  ];
  userType: string;
  listOfDoctorAvail: any;
  totalLength: any;
  clinicRef: any;
  branchRef: any;
  doctorList: any;
  timeLabels = [];
  durationValue: any;
  appointList: any;
  filteredPatientList: any;
  filteredAppointTypeList: any;
  filteredClinicList: any;
  filteredDoctorList: any;
  patientRefId: any;
  logged_id: string;
  pipe = new DatePipe("en-US"); // Use your own locale
  myFormattedDate: string;
  dateTime: Date;
  dateTime2: Date;
  dd: Date;
  isSave = true;
  closetime: any;
  userName: string;
  doctorID: string;
  PatientCreate = true;
  AppointmentTypeCreate = true;
  DoctorCreate = true;
  BackDatedCreate= true;
  appointment_id: string;
  patientName: any;
  patientId: any;
  openBranchTime: any;
  tt: string;
  ct: string;
  weeklyOff: any;
  branchHolidayList = [];
  doctorLeavesList = [];
  unavailable = false;
  branchmsg = "";
  branchUnavailable = false;
  halfDay: any;
  halfdayFromTime: any;
  halfdayToTime: any;
  isHalfDay = true;
  pName: any;
  pRefId: any;
  pMobile: any;
  firstName: any;
  patientIdd: any;
  lastName: any;
  appType = "";
  aptBackDate: Date;
 
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
     public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<AppoinmentPopupComponent>,@Inject(MAT_DIALOG_DATA) public data:any,
  ) { dialogRef.disableClose = true;
    this.pName = data.pName;
    this.pRefId = data.pRefId;
    this.pMobile = data.pMobile;
    this.firstName = data.firstName; 
    this.patientIdd = data.patientId; 
    this.lastName = data.lastName;
   }
  page2: number = 1;
  size: number = 5;
  branchRefId: string;
  clinicRefId: string;
  doctorRefId: string;
  userPermission: any;
  bookAppointmentsCreate = true;
  bookAppointmentsUpdate = true;
  currentdate;
  isDisabled = false;
  dayArray = [
    { value: "Monday", id: 1 },
    { value: "Tuesday", id: 2 },
    { value: "Wednesday", id: 3 },
    { value: "Thursday", id: 4 },
    { value: "Friday", id: 5 },
    { value: "Saturday", id: 6 },
    { value: "Sunday", id: 7 },
  ];
  @ViewChild("select1", { static: false }) nameField: MatSelect;
  ngOnInit(): void {
    ////
     ////
     
    this.currentdate = new Date();

    this.updatebutton = false;
    this.timeLabels = [];
    this.searcheddoctor = false;
    this.clinicRef = sessionStorage.getItem("clinicRef");
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    this.userType = sessionStorage.getItem("userType");
    this.logged_id = sessionStorage.getItem("userRef");
    this.branchRefId = sessionStorage.getItem("branchRef");
    this.branchRef = this.branchRefId;
    this.userName = sessionStorage.getItem("userName");
    this.doctorID = sessionStorage.getItem("doctorRefId");
    this.appointment_id = sessionStorage.getItem("appointment_id");
    
    ////
    ////
    const now = Date.now();
    this.myFormattedDate = this.pipe.transform(now, "shortTime");
    this.getCalender();
    if (this.userType !== "Super Admin") {
      this.isSuperAdmin= false;
      this.userPermission.forEach((element) => {
        if (
          element.pageName == "Book Appointments" &&
          element.create == false
        ) {
          this.bookAppointmentsCreate = false;
        }
        if (
          element.pageName == "Book Appointments" &&
          element.update == false
        ) {
          this.bookAppointmentsUpdate = false;
        } 
        if (element.pageName == "Patient" && element.create == false) {
          this.PatientCreate = false;
        }
        if (element.pageName == "Appointment Type" && element.create == false) {
          this.AppointmentTypeCreate = false;
        }
        if (element.pageName == "Doctor" && element.create == false) {
          this.DoctorCreate = false;
        }
        if (element.pageName == "Back Dated Appointment Permissions" && element.create == false) {
          this.BackDatedCreate = false;
        }
      }); 
    }
    if(this.BackDatedCreate == false)
    {
      this.aptBackDate = new Date();
    }
    else
    {
      this.aptBackDate = new Date("1980-12-31");
    }
    ////
    this.appointmentPopUp = this.fb.group({
      clientName: new FormControl("",Validators.required),
      contactNumber: new FormControl(""),
      eventColor: new FormControl(""),
      doctorName: new FormControl(""),
      clinicLocation: new FormControl(""),
      AppoinmentType: new FormControl("",Validators.required),
      startDateTime: new FormControl("",Validators.required),
      appoinmentTime: new FormControl(""),
      patientRefId: new FormControl(),
      aptStatus: new FormControl("Cancel"), 

      
    }); 
 
    if(this.pName !== undefined)
    {
      ////
      
      this.appointmentPopUp.get("clientName").setValue(this.pName);
      this.appointmentPopUp.get("patientRefId").setValue(this.pRefId);
      this.appointmentPopUp.get("contactNumber").setValue(this.pMobile);
      
      let clinicRef= sessionStorage.getItem('clinicRef');
      this.apimicroservice.getAllBranchList(clinicRef).subscribe((res) => {
        if (res.status) {
          this.cliniclist = res.data; 
          let length =res.data.length - 1;
          this.filteredClinicList = this.cliniclist.slice();
          if (
            this.userType == "Super Admin") {
            this.appointmentPopUp
              .get("clinicLocation")
              .setValue(this.cliniclist[length].branchName);
            this.selectBranch(this.cliniclist[length]._id);
          }
        }
      });

      let date = new Date(); 
      this.appointmentPopUp.get("startDateTime").setValue(date);
      // let clinicRef= sessionStorage.getItem('clinicRef');
      let branchRef= sessionStorage.getItem('branchRef');
      let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
      let branchRefSuperAdmin= '';
      if (this.userType !== 'Super Admin') {
      this.apimicroservice.getPatientListAll(branchRef,clinicRef).subscribe((res) => {
        if (res.status) {
         
          this.patientlist = res.data.data;
          this.filteredPatientList = this.patientlist.slice();
        

          this.selectPatient(
            this.pRefId,  
            this.firstName, 
            this.lastName,
            this.patientIdd
          );
         
        }
      });
    }else{
      this.apimicroservice.getPatientListAll(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe((res) => {
        if (res.status) {
         
          this.patientlist = res.data.data;
          this.filteredPatientList = this.patientlist.slice();
        

          this.selectPatient(
            this.pRefId,  
            this.firstName, 
            this.lastName,
            this.patientIdd
          );
         
        }
      });
    }

    
    } 
    let id= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(id).subscribe((res) => {
      if (res.status) {
    
        this.cliniclist = res.data;
        let length =res.data.length - 1;
        this.filteredClinicList = this.cliniclist.slice();

        ////

        ////
        
      }
    });
    ////
    ////
    if(this.data.patientInfo !== null && this.pName == undefined)
    {
      ////
      
      this.apimicroservice
      .getAppointmentbyId(this.data.patientInfo)
      .subscribe((res) => {
        if (res.status) {
          let dataaaa = res.data[0];
          ////
          this.updateRefid = dataaaa._id;
          this.savebutton = false;
          this.updatebutton = true; 
          this.clear = true;
          this.delete = true;
        
          this.appointmentPopUp = this.fb.group({
            clientName: new FormControl(dataaaa.clientName,Validators.required),
            contactNumber: new FormControl(dataaaa.contactNumber),
          
            eventColor: new FormControl(dataaaa.eventColor),
            doctorName: new FormControl(dataaaa.doctorName),
            doctorRefId: new FormControl(dataaaa.doctorRefId),
            
            clinicLocation: new FormControl(dataaaa.clinicLocation),
            AppoinmentType: new FormControl(dataaaa.AppoinmentType,Validators.required),
            startDateTime: new FormControl(dataaaa.startDateTime,Validators.required),
            appoinmentTime: new FormControl(dataaaa.appoinmentTime),
            aptStatus: new FormControl(dataaaa.aptStatus),
          
          });
          this.patientRefId = dataaaa.patientRefId;
          this.patientName = dataaaa.patientName;
          this.patientId = dataaaa.patientId;
          this.branchRef = dataaaa.branchRefId;
          this.doctorID = dataaaa.doctorRefId;
        
          this.eventclickbranch(
            dataaaa.branchRefId,
            dataaaa.doctorRefId
          );
          let dateSt = new Date(dataaaa.startDateTime);
          dateSt.setDate(dateSt.getDate()-1);
          let tdate=this.datepipe.transform(dateSt, 'yyyy-MM-dd');
          this.apimicroservice.getDoctorLeaveDataByDoctorId(tdate,dataaaa.doctorRefId).subscribe(res => {
            this.spinner.hide(); 
            if(res.message == 'No Data Found')
            {
               this.isHalfDay = false;
            }
            else
            {
              this.isHalfDay = true;
              this.halfDay = res.data[0].leaveType;  
              ////
               this.halfdayFromTime = res.data[0].fromTime;
               this.halfdayToTime = res.data[0].toTime;
            }
           });
          
        } else {
          
        }
    });
  }
  else if(this.data.bookDate !== null && this.pName == undefined)
  { 
    ////
    this.timeLabels = [];

    this.appointmentPopUp = this.fb.group({
      clientName: new FormControl("",Validators.required),
      contactNumber: new FormControl(),
      
      eventColor: new FormControl(), 
      doctorName: new FormControl(),
      
      clinicLocation: new FormControl(),
      AppoinmentType: new FormControl("",Validators.required),
      startDateTime: new FormControl(this.data.bookDate,Validators.required),
      appoinmentTime: new FormControl(this.newAppointment),
      aptStatus: new FormControl("Cancel"),
      
    });

    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
    this.apimicroservice.getAppointmentTypelist(branchRef,clinicRef).subscribe((res) => {
      if (res.status) {
        // ////
        this.appointtypelist = res.data.data;
        this.filteredAppointTypeList = this.appointtypelist.slice();      
         let length =res.data.data.length - 1; 
         this.appType = this.appointtypelist[length].appointmentType;
          this.appointmentPopUp.get("AppoinmentType").setValue(this.appointtypelist[length].appointmentType);
          this.dddd(this.appointtypelist[length]);
      }
    });
  } else {
    this.apimicroservice.getAppointmentTypelist(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe((res) => {
      if (res.status) {
        // ////
        this.appointtypelist = res.data.data;
        this.filteredAppointTypeList = this.appointtypelist.slice();      
         let length =res.data.data.length - 1; 
         this.appType = this.appointtypelist[length].appointmentType;
          this.appointmentPopUp.get("AppoinmentType").setValue(this.appointtypelist[length].appointmentType);
          this.dddd(this.appointtypelist[length]);
      }
    });
  }

    
    if (this.userType !== "Super Admin") {
      //  let bid = sessionStorage.getItem('branchRef');
      this.apimicroservice.getBranchById(this.branchRefId).subscribe((res) => {
        console.log("Apoinment response10====>>",res);
        ////
        this.branchRef = this.branchRefId;

        // this.appointmentPopUp
        //   .get("clinicLocation")
        //   .setValue(res.data[0].branchName);
        this.appointmentPopUp.controls["clinicLocation"].disable();
        this.selectBranch(res.data[0]._id);

        if (this.userType == "Doctor") {
          let date = new Date();
          ////
          ////
          ////
          this.appointmentPopUp.get("startDateTime").setValue(date);
          this.appointmentPopUp.get("doctorName").setValue(this.userName);
          this.selectDoctor(this.doctorID);
        }
      });
  
    }
    else
    {
      this.apimicroservice.getAllBranchList(clinicRef).subscribe((res) => {
        if (res.status) {
          // ////
          this.cliniclist = res.data;
          let length =res.data.length - 1;
          this.filteredClinicList = this.cliniclist.slice();
  
          ////
  
          ////
          if (
            this.userType == "Super Admin") {
            this.appointmentPopUp
              .get("clinicLocation")
              .setValue(this.cliniclist[length].branchName);
            this.selectBranch(this.cliniclist[length]._id);
          }
        }
      });
    }
  }
 
  //  this.appointmentPopUp.get("startDateTime").setValue(this.currentdate);
    this.isSave = true;
    this.toDateChange();
    if (this.userType == "Doctor") {
      //this.appointmentPopUp.get("contactNumber").setValue(mobile1);
    }
    ////

    
    // this.apimicroservice.getAppointmentTypelist().subscribe((res) => {
    //   if (res.status) {
    //     // ////
    //     this.appointtypelist = res.data.data;
    //     this.filteredAppointTypeList = this.appointtypelist.slice();      
    //      let length =res.data.data.length - 1; 
    //      this.appType = this.appointtypelist[length].appointmentType;
    //       //this.appointmentPopUp.get("AppoinmentType").setValue(this.appointtypelist[length].appointmentType);
    //       this.dddd(this.appointtypelist[length]);
    //   }
    // });

    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
    this.apimicroservice.getAppointmentTypelist(branchRef,clinicRef).subscribe((res) => {
      if (res.status) {
        // ////
        this.appointtypelist = res.data.data;
        this.filteredAppointTypeList = this.appointtypelist.slice();      
         let length =res.data.data.length - 1; 
         this.appType = this.appointtypelist[length].appointmentType;
          this.appointmentPopUp.get("AppoinmentType").setValue(this.appointtypelist[length].appointmentType);
          this.dddd(this.appointtypelist[length]);
      }
    });
  } else {
    this.apimicroservice.getAppointmentTypelist(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe((res) => {
      if (res.status) {
        // ////
        this.appointtypelist = res.data.data;
        this.filteredAppointTypeList = this.appointtypelist.slice();      
         let length =res.data.data.length - 1; 
         this.appType = this.appointtypelist[length].appointmentType;
          this.appointmentPopUp.get("AppoinmentType").setValue(this.appointtypelist[length].appointmentType);
          this.dddd(this.appointtypelist[length]);
      }
    });
  }
  if (this.userType !== 'Super Admin') {
    this.apimicroservice.getPatientListAll(branchRef,clinicRef).subscribe((res) => {
      if (res.status) {
        // ////
        this.patientlist = res.data.data;
        this.filteredPatientList = this.patientlist.slice();
      }
    });
  }else{
    this.apimicroservice.getPatientListAll(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe((res) => {
      if (res.status) {
        // ////
        this.patientlist = res.data.data;
        this.filteredPatientList = this.patientlist.slice();
      }
    });
  }
  if (this.userType !== 'Super Admin') {
    this.apimicroservice.getAllDoctorslist(branchRef,clinicRef).subscribe((res) => {
      if (res.status) {
        this.filteredDoctorList= [];
        // ////
        this.doctorlist = res.data.data;
      }
    });
  }else{
    this.apimicroservice.getAllDoctorslist(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe((res) => {
      if (res.status) {
        this.filteredDoctorList= [];
        // ////
        this.doctorlist = res.data.data;
      }
    });
  }
  

    setTimeout(() => {
      ////
      let ssss = [];
      // let clinicRef = sessionStorage.getItem('clinicRef');
      // let branchRef = sessionStorage.getItem('branchRef');
      
      // if (this.userType !== "Super Admin") {
      //   clinicRef = sessionStorage.getItem('clinicRef');
      //   branchRef = sessionStorage.getItem('branchRef');
      // } else {
      //    clinicRef = sessionStorage.getItem('clinicRef');
      //    branchRef = '';
      // }
      let clinicRef= sessionStorage.getItem('clinicRef');
      let branchRef= sessionStorage.getItem('branchRef');
      let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
      let branchRefSuperAdmin= '';
      if (this.userType !== 'Super Admin') {
      this.apimicroservice.getAppointment(branchRef, clinicRef).subscribe((res) => {
        if (res.status) {
          ////
          if (res.data.length > 0) {
            let w = {
              start: "2022-06-21T10:00:00",
              end: "2022-06-25T10:00:00",
              backgroundColor: "pink",
              display: "background",
            };
            ssss.push(w);
            res.data.forEach((element) => {
              let x = {};
              x["id"] = element._id;
              x["title"] = element.clientName;
              x["start"] = new Date(element.startDateTime);
              x["end"] = new Date(element.endDateTime);
              x["color"] = element.eventColor;
              x["backgroundColor"] = element.eventColor;

              ssss.push(x);
            });
            var today = new Date().toISOString().slice(0, 10);
            var calendarEl = this.calendar.nativeElement;
            var calendar = new Calendar(calendarEl, {
              initialView: this.fieldsOfCalenders.defaultView,

              //   validRange: {
              //     start: today
              //  },
              initialDate: new Date(),
              selectable: true,
              editable: true,
              eventTimeFormat: {
                // like '14:30:00'
                hour: "numeric",
                minute: "2-digit",
                meridiem: "short",
              },
              //  slotMinTime: "09:00:00",
              //  slotMaxTime:"23:00:00",
              //weekends:true,
              slotMinTime: this.tt,
              slotMaxTime: this.ct,
              eventBackgroundColor: "",

              // hiddenDays:[2],
              dateClick: (info) => {
                ////
                this.reset();
                this.timeLabels = [];

                this.appointmentPopUp = this.fb.group({
                  clientName: new FormControl("",Validators.required),
                  contactNumber: new FormControl(),
                  // "title": new FormControl(),
                  eventColor: new FormControl(),
                  doctorName: new FormControl(),
                  // "clinicName": new FormControl(res.data.clientName),
                  clinicLocation: new FormControl(),
                  AppoinmentType: new FormControl("",Validators.required),
                  startDateTime: new FormControl(info.date,Validators.required),
                  appoinmentTime: new FormControl(this.newAppointment),
                  aptStatus: new FormControl("Cancel"),
                  // "duration": new FormControl(''),
                });
              },

              eventDrop: (info) => {
                this.apimicroservice
                  .getAppointmentbyId(info.event.id)
                  .subscribe((res) => {
                    // alert(JSON.stringify(info.event.start))
                    if (res.status) {
                      let dataaaa = res.data[0];
                      this.updateRefid = dataaaa._id;
                      // this.selectBranch(dataaaa.branchRefId);
                      // this.selectDoctor(dataaaa.doctorRefId);
                      // this.getDoctorAvailability();
                      this.branchRef = dataaaa.branchRefId;
                      this.patientRefId = dataaaa.patientRefId;
                      this.patientName = dataaaa.patientName;
                      this.patientId = dataaaa.patientId;
                      this.appointmentPopUp = this.fb.group({
                        clientName: new FormControl(dataaaa.clientName,Validators.required),
                        contactNumber: new FormControl(dataaaa.contactNumber),
                        // "title": new FormControl(dataaaa.title),
                        eventColor: new FormControl(dataaaa.eventColor),
                        doctorName: new FormControl(dataaaa.doctorName),
                        doctorRefId: new FormControl(dataaaa.doctorRefId),
                        // "clinicName": new FormControl(res.data.clientName),
                        clinicLocation: new FormControl(dataaaa.clinicLocation),
                        AppoinmentType: new FormControl(dataaaa.AppoinmentType,Validators.required),
                        startDateTime: new FormControl(info.event.start,Validators.required),
                        appoinmentTime: new FormControl(dataaaa.appoinmentTime),
                        aptStatus: new FormControl(dataaaa.aptStatus),
                        // "duration": new FormControl(dataaaa.duration),
                      });
                      this.branchRef = dataaaa.branchRefId;
                      this.doctorID = dataaaa.doctorRefId;
               
                      //  let date= new Date(info.event.start);
                      //  date.setDate(date.getDate()-1);
                      //  let dateSt = date.toISOString(); 
                      //  ////
                      //  ////

                       let dateSt = new Date(info.event.start);
                       dateSt.setDate(dateSt.getDate()-1);
                       let tdate=this.datepipe.transform(dateSt, 'yyyy-MM-dd');


                      this.apimicroservice.getDoctorLeaveDataByDoctorId(tdate,dataaaa.doctorRefId).subscribe(res => {
                        this.spinner.hide(); 
                        if(res.message == 'No Data Found')
                        {
                           this.isHalfDay = false;
                        }
                        else 
                        {
                          this.isHalfDay = true;
                          this.halfDay = res.data[0].leaveType;  
                          ////
                           this.halfdayFromTime = res.data[0].fromTime;
                           this.halfdayToTime = res.data[0].toTime;
                        }
                       });
                      this.draganddropbranch(
                        dataaaa.doctorRefId,
                        dataaaa.appoinmentTime,
                        info.event.start
                      );
                    } else {
                      //  this.apimicroservice.openSnackbar(res.message);
                    }
                  });
              },
              eventClick: (arg) => {
                ////
                ////
                ////
                // let datess = arg.date.toISOString();
                // this.getDoctorAvailability();
                this.apimicroservice
                  .getAppointmentbyId(arg.event._def.publicId)
                  .subscribe((res) => {
                    if (res.status) {
                      let dataaaa = res.data[0];
                      ////
                      this.updateRefid = dataaaa._id;
                      this.savebutton = false;
                      this.updatebutton = true;
                      this.clear = true;
                      this.delete = true;
                      // ////);
                      this.appointmentPopUp = this.fb.group({
                        clientName: new FormControl(dataaaa.clientName,Validators.required),
                        contactNumber: new FormControl(dataaaa.contactNumber),
                        // "title": new FormControl(dataaaa.title),
                        eventColor: new FormControl(dataaaa.eventColor),
                        doctorName: new FormControl(dataaaa.doctorName),
                        doctorRefId: new FormControl(dataaaa.doctorRefId),
                        // "clinicName": new FormControl(res.data.clientName),
                        clinicLocation: new FormControl(dataaaa.clinicLocation),
                        AppoinmentType: new FormControl(dataaaa.AppoinmentType,Validators.required),
                        startDateTime: new FormControl(dataaaa.startDateTime,Validators.required),
                        appoinmentTime: new FormControl(dataaaa.appoinmentTime),
                        aptStatus: new FormControl(dataaaa.aptStatus),
                        // "duration": new FormControl(dataaaa.duration),
                      });
                    
                      let dateSt = new Date(dataaaa.startDateTime);
                      dateSt.setDate(dateSt.getDate()-1);
                      let tdate=this.datepipe.transform(dateSt, 'yyyy-MM-dd');
                         this.apimicroservice.getDoctorLeaveDataByDoctorId(tdate,dataaaa.doctorRefId).subscribe(res => {
                        this.spinner.hide(); 
                        if(res.message == 'No Data Found')
                        {
                           this.isHalfDay = false;
                        }
                        else
                        {
                          this.isHalfDay = true;
                          this.halfDay = res.data[0].leaveType;  
                          ////
                           this.halfdayFromTime = res.data[0].fromTime;
                           this.halfdayToTime = res.data[0].toTime;
                        }
                       });
                      this.patientRefId = dataaaa.patientRefId;
                      this.patientName = dataaaa.patientName;
                      this.patientId = dataaaa.patientId;
                      this.branchRef = dataaaa.branchRefId;
                      this.doctorID = dataaaa.doctorRefId;
                      // this.toDateChange();
                      this.eventclickbranch(
                        dataaaa.branchRefId,
                        dataaaa.doctorRefId
                      );
                      // this.selectBranch(dataaaa.branchRefId);
                      // this.selectDoctor(dataaaa.doctorRefId);
                      // this.getDoctorAvailability();
                    } else {
                      //this.apimicroservice.openSnackbar(res.message);
                    }
                  });
                //  this.openBookAppointmentPopup();
              },
              plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
              droppable: true,
              headerToolbar: {
                left: "prev,next",
                center: "title",
                right: "dayGridMonth,timeGridWeek,timeGridDay",
              },
              events: ssss,
            });

            calendar.render();
          } else {
            var today = new Date().toISOString().slice(0, 10);
            var calendarEl = this.calendar.nativeElement;
            ////

            // //
            var calendar = new Calendar(calendarEl, {
              initialView: this.fieldsOfCalenders.defaultView,
              //   initialView: 'timeGridWeek',

              //   validRange: {
              //     start: today
              //  },
              initialDate: new Date(),
              selectable: true,
              editable: true,
              eventTimeFormat: {
                // like '14:30:00'
                hour: "numeric",
                minute: "2-digit",
                meridiem: "short",
              },
              slotMinTime: this.tt,
              slotMaxTime: this.ct,
              // slotMinTime: "09:00:00",
              // slotMaxTime:"23:00:00",
              // hiddenDays:[2],
              eventBackgroundColor: "",
              dateClick: (info) => {
                ////
                ////
                this.reset();
                this.timeLabels = [];

                this.appointmentPopUp = this.fb.group({
                  clientName: new FormControl("",Validators.required),
                  contactNumber: new FormControl(),
                  // "title": new FormControl(),
                  eventColor: new FormControl(),
                  doctorName: new FormControl(),
                  // "clinicName": new FormControl(res.data.clientName),
                  clinicLocation: new FormControl(),
                  AppoinmentType: new FormControl("",Validators.required),
                  startDateTime: new FormControl(info.date,Validators.required),
                  appoinmentTime: new FormControl(this.newAppointment),
                  aptStatus: new FormControl("Cancel"),
                  // "duration": new FormControl(''),
                });
                // this.openBookAppointmentPopup();
              },
              eventClick: (arg) => {
                ////
                // ////
                // let datess = arg.date.toISOString();
                this.apimicroservice
                  .getAppointmentbyId(arg.event._def.publicId)
                  .subscribe((res) => {
                    if (res.status) {
                      let dataaaa = res.data[0];
                      this.updateRefid = dataaaa._id;
                      this.savebutton = false;
                      this.updatebutton = true;
                      this.clear = true;
                      this.delete = true;
                      // ////);
                      this.appointmentPopUp = this.fb.group({
                        clientName: new FormControl(dataaaa.clientName,Validators.required),
                        contactNumber: new FormControl(dataaaa.contactNumber),
                        // "title": new FormControl(dataaaa.title),
                        eventColor: new FormControl(dataaaa.eventColor),
                        doctorName: new FormControl(dataaaa.doctorName),
                        doctorRefId: new FormControl(dataaaa.doctorRefId),
                        // "clinicName": new FormControl(res.data.clientName),
                        clinicLocation: new FormControl(dataaaa.clinicLocation),
                        AppoinmentType: new FormControl(dataaaa.AppoinmentType,Validators.required),
                        startDateTime: new FormControl(dataaaa.startDateTime,Validators.required),
                        appoinmentTime: new FormControl(dataaaa.appoinmentTime),
                        aptStatus: new FormControl(dataaaa.aptStatus),
                        // "duration": new FormControl(dataaaa.duration),
                      });
                      let dateSt = new Date(dataaaa.startDateTime);
                      dateSt.setDate(dateSt.getDate()-1);
                      let tdate=this.datepipe.transform(dateSt, 'yyyy-MM-dd');
                         this.apimicroservice.getDoctorLeaveDataByDoctorId(tdate,dataaaa.doctorRefId).subscribe(res => {
                        this.spinner.hide(); 
                        if(res.message == 'No Data Found')
                        {
                           this.isHalfDay = false;
                        }
                        else
                        {
                          this.isHalfDay = true;
                          this.halfDay = res.data[0].leaveType;  
                          ////
                           this.halfdayFromTime = res.data[0].fromTime;
                           this.halfdayToTime = res.data[0].toTime;
                        }
                       });
                      this.patientRefId = dataaaa.patientRefId;
                      this.patientName = dataaaa.patientName;
                      this.patientId = dataaaa.patientId;
                      // this.selectBranch(dataaaa.branchRefId);
                      // this.selectDoctor(dataaaa.doctorRefId);
                      // this.getDoctorAvailability();
                      this.branchRef = dataaaa.branchRefId;
                      this.doctorID = dataaaa.doctorRefId;
                      //this.toDateChange();
                      this.eventclickbranch(
                        dataaaa.branchRefId,
                        dataaaa.doctorRefId
                      );
                    } else {
                      // this.apimicroservice.openSnackbar(res.message);
                    }
                  });
              },
              plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
              droppable: true,
              headerToolbar: {
                left: "prev,next",
                center: "title",
                right: "dayGridMonth,timeGridWeek,timeGridDay",
              },
            });

            calendar.render();
          }
        } else {
          //  this.apimicroservice.openSnackbar(res.message);
        }
      });
    }else{
      this.apimicroservice.getAppointment(branchRefSuperAdmin, clinicRefSuperAdmin).subscribe((res) => {
        if (res.status) {
          ////
          if (res.data.length > 0) {
            let w = {
              start: "2022-06-21T10:00:00",
              end: "2022-06-25T10:00:00",
              backgroundColor: "pink",
              display: "background",
            };
            ssss.push(w);
            res.data.forEach((element) => {
              let x = {};
              x["id"] = element._id;
              x["title"] = element.clientName;
              x["start"] = new Date(element.startDateTime);
              x["end"] = new Date(element.endDateTime);
              x["color"] = element.eventColor;
              x["backgroundColor"] = element.eventColor;

              ssss.push(x);
            });
            var today = new Date().toISOString().slice(0, 10);
            var calendarEl = this.calendar.nativeElement;
            var calendar = new Calendar(calendarEl, {
              initialView: this.fieldsOfCalenders.defaultView,

              //   validRange: {
              //     start: today
              //  },
              initialDate: new Date(),
              selectable: true,
              editable: true,
              eventTimeFormat: {
                // like '14:30:00'
                hour: "numeric",
                minute: "2-digit",
                meridiem: "short",
              },
              //  slotMinTime: "09:00:00",
              //  slotMaxTime:"23:00:00",
              //weekends:true,
              slotMinTime: this.tt,
              slotMaxTime: this.ct,
              eventBackgroundColor: "",

              // hiddenDays:[2],
              dateClick: (info) => {
                ////
                this.reset();
                this.timeLabels = [];

                this.appointmentPopUp = this.fb.group({
                  clientName: new FormControl("",Validators.required),
                  contactNumber: new FormControl(),
                  // "title": new FormControl(),
                  eventColor: new FormControl(),
                  doctorName: new FormControl(),
                  // "clinicName": new FormControl(res.data.clientName),
                  clinicLocation: new FormControl(),
                  AppoinmentType: new FormControl("",Validators.required),
                  startDateTime: new FormControl(info.date,Validators.required),
                  appoinmentTime: new FormControl(this.newAppointment),
                  aptStatus: new FormControl("Cancel"),
                  // "duration": new FormControl(''),
                });
              },

              eventDrop: (info) => {
                this.apimicroservice
                  .getAppointmentbyId(info.event.id)
                  .subscribe((res) => {
                    // alert(JSON.stringify(info.event.start))
                    if (res.status) {
                      let dataaaa = res.data[0];
                      this.updateRefid = dataaaa._id;
                      // this.selectBranch(dataaaa.branchRefId);
                      // this.selectDoctor(dataaaa.doctorRefId);
                      // this.getDoctorAvailability();
                      this.branchRef = dataaaa.branchRefId;
                      this.patientRefId = dataaaa.patientRefId;
                      this.patientName = dataaaa.patientName;
                      this.patientId = dataaaa.patientId;
                      this.appointmentPopUp = this.fb.group({
                        clientName: new FormControl(dataaaa.clientName,Validators.required),
                        contactNumber: new FormControl(dataaaa.contactNumber),
                        // "title": new FormControl(dataaaa.title),
                        eventColor: new FormControl(dataaaa.eventColor),
                        doctorName: new FormControl(dataaaa.doctorName),
                        doctorRefId: new FormControl(dataaaa.doctorRefId),
                        // "clinicName": new FormControl(res.data.clientName),
                        clinicLocation: new FormControl(dataaaa.clinicLocation),
                        AppoinmentType: new FormControl(dataaaa.AppoinmentType,Validators.required),
                        startDateTime: new FormControl(info.event.start,Validators.required),
                        appoinmentTime: new FormControl(dataaaa.appoinmentTime),
                        aptStatus: new FormControl(dataaaa.aptStatus),
                        // "duration": new FormControl(dataaaa.duration),
                      });
                      this.branchRef = dataaaa.branchRefId;
                      this.doctorID = dataaaa.doctorRefId;
               
                      //  let date= new Date(info.event.start);
                      //  date.setDate(date.getDate()-1);
                      //  let dateSt = date.toISOString(); 
                      //  ////
                      //  ////

                       let dateSt = new Date(info.event.start);
                       dateSt.setDate(dateSt.getDate()-1);
                       let tdate=this.datepipe.transform(dateSt, 'yyyy-MM-dd');


                      this.apimicroservice.getDoctorLeaveDataByDoctorId(tdate,dataaaa.doctorRefId).subscribe(res => {
                        this.spinner.hide(); 
                        if(res.message == 'No Data Found')
                        {
                           this.isHalfDay = false;
                        }
                        else 
                        {
                          this.isHalfDay = true;
                          this.halfDay = res.data[0].leaveType;  
                          ////
                           this.halfdayFromTime = res.data[0].fromTime;
                           this.halfdayToTime = res.data[0].toTime;
                        }
                       });
                      this.draganddropbranch(
                        dataaaa.doctorRefId,
                        dataaaa.appoinmentTime,
                        info.event.start
                      );
                    } else {
                      //  this.apimicroservice.openSnackbar(res.message);
                    }
                  });
              },
              eventClick: (arg) => {
                ////
                ////
                ////
                // let datess = arg.date.toISOString();
                // this.getDoctorAvailability();
                this.apimicroservice
                  .getAppointmentbyId(arg.event._def.publicId)
                  .subscribe((res) => {
                    if (res.status) {
                      let dataaaa = res.data[0];
                      ////
                      this.updateRefid = dataaaa._id;
                      this.savebutton = false;
                      this.updatebutton = true;
                      this.clear = true;
                      this.delete = true;
                      // ////);
                      this.appointmentPopUp = this.fb.group({
                        clientName: new FormControl(dataaaa.clientName,Validators.required),
                        contactNumber: new FormControl(dataaaa.contactNumber),
                        // "title": new FormControl(dataaaa.title),
                        eventColor: new FormControl(dataaaa.eventColor),
                        doctorName: new FormControl(dataaaa.doctorName),
                        doctorRefId: new FormControl(dataaaa.doctorRefId),
                        // "clinicName": new FormControl(res.data.clientName),
                        clinicLocation: new FormControl(dataaaa.clinicLocation),
                        AppoinmentType: new FormControl(dataaaa.AppoinmentType,Validators.required),
                        startDateTime: new FormControl(dataaaa.startDateTime,Validators.required),
                        appoinmentTime: new FormControl(dataaaa.appoinmentTime),
                        aptStatus: new FormControl(dataaaa.aptStatus),
                        // "duration": new FormControl(dataaaa.duration),
                      });
                    
                      let dateSt = new Date(dataaaa.startDateTime);
                      dateSt.setDate(dateSt.getDate()-1);
                      let tdate=this.datepipe.transform(dateSt, 'yyyy-MM-dd');
                         this.apimicroservice.getDoctorLeaveDataByDoctorId(tdate,dataaaa.doctorRefId).subscribe(res => {
                        this.spinner.hide(); 
                        if(res.message == 'No Data Found')
                        {
                           this.isHalfDay = false;
                        }
                        else
                        {
                          this.isHalfDay = true;
                          this.halfDay = res.data[0].leaveType;  
                          ////
                           this.halfdayFromTime = res.data[0].fromTime;
                           this.halfdayToTime = res.data[0].toTime;
                        }
                       });
                      this.patientRefId = dataaaa.patientRefId;
                      this.patientName = dataaaa.patientName;
                      this.patientId = dataaaa.patientId;
                      this.branchRef = dataaaa.branchRefId;
                      this.doctorID = dataaaa.doctorRefId;
                      // this.toDateChange();
                      this.eventclickbranch(
                        dataaaa.branchRefId,
                        dataaaa.doctorRefId
                      );
                      // this.selectBranch(dataaaa.branchRefId);
                      // this.selectDoctor(dataaaa.doctorRefId);
                      // this.getDoctorAvailability();
                    } else {
                      //this.apimicroservice.openSnackbar(res.message);
                    }
                  });
                //  this.openBookAppointmentPopup();
              },
              plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
              droppable: true,
              headerToolbar: {
                left: "prev,next",
                center: "title",
                right: "dayGridMonth,timeGridWeek,timeGridDay",
              },
              events: ssss,
            });

            calendar.render();
          } else {
            var today = new Date().toISOString().slice(0, 10);
            var calendarEl = this.calendar.nativeElement;
            ////

            // //
            var calendar = new Calendar(calendarEl, {
              initialView: this.fieldsOfCalenders.defaultView,
              //   initialView: 'timeGridWeek',

              //   validRange: {
              //     start: today
              //  },
              initialDate: new Date(),
              selectable: true,
              editable: true,
              eventTimeFormat: {
                // like '14:30:00'
                hour: "numeric",
                minute: "2-digit",
                meridiem: "short",
              },
              slotMinTime: this.tt,
              slotMaxTime: this.ct,
              // slotMinTime: "09:00:00",
              // slotMaxTime:"23:00:00",
              // hiddenDays:[2],
              eventBackgroundColor: "",
              dateClick: (info) => {
                ////
                ////
                this.reset();
                this.timeLabels = [];

                this.appointmentPopUp = this.fb.group({
                  clientName: new FormControl("",Validators.required),
                  contactNumber: new FormControl(),
                  // "title": new FormControl(),
                  eventColor: new FormControl(),
                  doctorName: new FormControl(),
                  // "clinicName": new FormControl(res.data.clientName),
                  clinicLocation: new FormControl(),
                  AppoinmentType: new FormControl("",Validators.required),
                  startDateTime: new FormControl(info.date,Validators.required),
                  appoinmentTime: new FormControl(this.newAppointment),
                  aptStatus: new FormControl("Cancel"),
                  // "duration": new FormControl(''),
                });
                // this.openBookAppointmentPopup();
              },
              eventClick: (arg) => {
                ////
                // ////
                // let datess = arg.date.toISOString();
                this.apimicroservice
                  .getAppointmentbyId(arg.event._def.publicId)
                  .subscribe((res) => {
                    if (res.status) {
                      let dataaaa = res.data[0];
                      this.updateRefid = dataaaa._id;
                      this.savebutton = false;
                      this.updatebutton = true;
                      this.clear = true;
                      this.delete = true;
                      // ////);
                      this.appointmentPopUp = this.fb.group({
                        clientName: new FormControl(dataaaa.clientName,Validators.required),
                        contactNumber: new FormControl(dataaaa.contactNumber),
                        // "title": new FormControl(dataaaa.title),
                        eventColor: new FormControl(dataaaa.eventColor),
                        doctorName: new FormControl(dataaaa.doctorName),
                        doctorRefId: new FormControl(dataaaa.doctorRefId),
                        // "clinicName": new FormControl(res.data.clientName),
                        clinicLocation: new FormControl(dataaaa.clinicLocation),
                        AppoinmentType: new FormControl(dataaaa.AppoinmentType,Validators.required),
                        startDateTime: new FormControl(dataaaa.startDateTime,Validators.required),
                        appoinmentTime: new FormControl(dataaaa.appoinmentTime),
                        aptStatus: new FormControl(dataaaa.aptStatus),
                        // "duration": new FormControl(dataaaa.duration),
                      });
                      let dateSt = new Date(dataaaa.startDateTime);
                      dateSt.setDate(dateSt.getDate()-1);
                      let tdate=this.datepipe.transform(dateSt, 'yyyy-MM-dd');
                         this.apimicroservice.getDoctorLeaveDataByDoctorId(tdate,dataaaa.doctorRefId).subscribe(res => {
                        this.spinner.hide(); 
                        if(res.message == 'No Data Found')
                        {
                           this.isHalfDay = false;
                        }
                        else
                        {
                          this.isHalfDay = true;
                          this.halfDay = res.data[0].leaveType;  
                          ////
                           this.halfdayFromTime = res.data[0].fromTime;
                           this.halfdayToTime = res.data[0].toTime;
                        }
                       });
                      this.patientRefId = dataaaa.patientRefId;
                      this.patientName = dataaaa.patientName;
                      this.patientId = dataaaa.patientId;
                      // this.selectBranch(dataaaa.branchRefId);
                      // this.selectDoctor(dataaaa.doctorRefId);
                      // this.getDoctorAvailability();
                      this.branchRef = dataaaa.branchRefId;
                      this.doctorID = dataaaa.doctorRefId;
                      //this.toDateChange();
                      this.eventclickbranch(
                        dataaaa.branchRefId,
                        dataaaa.doctorRefId
                      );
                    } else {
                      // this.apimicroservice.openSnackbar(res.message);
                    }
                  });
              },
              plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
              droppable: true,
              headerToolbar: {
                left: "prev,next",
                center: "title",
                right: "dayGridMonth,timeGridWeek,timeGridDay",
              },
            });

            calendar.render();
          }
        } else {
          //  this.apimicroservice.openSnackbar(res.message);
        }
      });
    }
    }, 300);

    this.fetchDuration();
    this.fetchAllBranchLeaves();
    if (this.appointment_id !== "") {
      ////
   
      this.apimicroservice
        .getAppointmentbyId(this.appointment_id)
        .subscribe((res) => {
          if (res.status) {
            let dataaaa = res.data[0];
            this.updateRefid = dataaaa._id;
            this.savebutton = false;
            this.updatebutton = true;
            this.clear = true;
            this.delete = true;
            // //);
            this.appointmentPopUp = this.fb.group({
              clientName: new FormControl(dataaaa.clientName,Validators.required),
              contactNumber: new FormControl(dataaaa.contactNumber),
              // "title": new FormControl(dataaaa.title),
              eventColor: new FormControl(dataaaa.eventColor),
              doctorName: new FormControl(dataaaa.doctorName),
              doctorRefId: new FormControl(dataaaa.doctorRefId),
              // "clinicName": new FormControl(res.data.clientName),
              clinicLocation: new FormControl(dataaaa.clinicLocation),
              AppoinmentType: new FormControl(dataaaa.AppoinmentType,Validators.required),
              startDateTime: new FormControl(dataaaa.startDateTime,Validators.required),
              appoinmentTime: new FormControl(dataaaa.appoinmentTime),
              aptStatus: new FormControl("Rescheduled"),
              // "duration": new FormControl(dataaaa.duration),
            });
            let dateSt = new Date(dataaaa.startDateTime);
            dateSt.setDate(dateSt.getDate()-1);
            let tdate=this.datepipe.transform(dateSt, 'yyyy-MM-dd');
               this.apimicroservice.getDoctorLeaveDataByDoctorId(tdate,dataaaa.doctorRefId).subscribe(res => {
              this.spinner.hide(); 
              if(res.message == 'No Data Found')
              {
                 this.isHalfDay = false;
              }
              else
              {
                this.isHalfDay = true;
                this.halfDay = res.data[0].leaveType;  
                ////
                 this.halfdayFromTime = res.data[0].fromTime;
                 this.halfdayToTime = res.data[0].toTime;
              }
             });
            this.patientRefId = dataaaa.patientRefId;
            this.patientName = dataaaa.patientName;
            this.patientId = dataaaa.patientId;
            this.branchRef = dataaaa.branchRefId;
            this.doctorID = dataaaa.doctorRefId;
            // this.toDateChange();
            ////

            this.appointmentPopUp
              .get("clinicLocation")
              .setValue(dataaaa.clinicLocation);

            // this.selectBranch(dataaaa.branchRefId);
            this.appointmentPopUp.controls["clinicLocation"].disable();
            this.eventclickbranch(dataaaa.branchRefId, dataaaa.doctorRefId);

            // this.selectBranch(dataaaa.branchRefId);
            // this.selectDoctor(dataaaa.doctorRefId);
            // this.getDoctorAvailability();
          } else {
            //   this.apimicroservice.openSnackbar(res.message);
          }
        });
      sessionStorage.setItem("appointment_id", "");
    } else {
      sessionStorage.setItem("appointment_id", "");
    }
  }

  ngAfterViewInit(): void {
    this.ngOnInit();
  }

  fetchPatient() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
    this.apimicroservice.getPatientListAll(branchRef,clinicRef).subscribe((res) => {
      if (res.status) {
        // ////
        this.patientlist = res.data.data;
        this.filteredPatientList = this.patientlist.slice();
      }
    });
  }else{
      this.apimicroservice.getPatientListAll(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe((res) => {
        if (res.status) {
          // ////
          this.patientlist = res.data.data;
          this.filteredPatientList = this.patientlist.slice();
        }
      });
    }
  }

  fetchAllBranchLeaves() {
    let id = "";
    this.apimicroservice.getBranchHolidayDataById(id).subscribe((res) => {
      if (res.status) {
        ////
        this.branchHolidayList = res.data;
      }
    });
  }

  // fetchAppointmentTypeList() {
  //   this.apimicroservice.getAppointmentTypelist().subscribe((res) => {
  //     if (res.status) {
  //       // ////
  //       this.appointtypelist = res.data.data;
  //       this.filteredAppointTypeList = this.appointtypelist.slice();
  //     }
  //   });
  // }

  fetchAppointmentTypeList() {
    let clinicRef = sessionStorage.getItem('clinicRef');
    let branchRef = sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin = sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin = '';
    
    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getAppointmentTypelist(branchRef, clinicRef).subscribe((res) => {
        if (res.status) {
          this.appointtypelist = res.data.data;
          this.filteredAppointTypeList = this.appointtypelist.slice();
          let length = res.data.data.length - 1; 
          this.appType = this.appointtypelist[length].appointmentType;
          this.appointmentPopUp.get("AppoinmentType").setValue(this.appointtypelist[length].appointmentType);
          this.dddd(this.appointtypelist[length]);
        }
      });
    } else {
      this.apimicroservice.getAppointmentTypelist(branchRefSuperAdmin, clinicRefSuperAdmin).subscribe((res) => {
        if (res.status) {
          this.appointtypelist = res.data.data;
          this.filteredAppointTypeList = this.appointtypelist.slice();
          let length = res.data.data.length - 1;
          this.appType = this.appointtypelist[length].appointmentType;
          this.appointmentPopUp.get("AppoinmentType").setValue(this.appointtypelist[length].appointmentType);
          this.dddd(this.appointtypelist[length]);
        }
      });
    }
  }

  getDoctorAvailability() {
   
    this.apimicroservice
      .getDoctorAppointAvailable(
        this.branchRef,
        this.clinicRef,
        this.doctorRefId
      ) 
      .subscribe((res) => {
        // //
        // //
        // let appointStartTime = this.appointmentPopUp.value.startDateTime._d.toLocaleTimeString('en-GB');
        // let appointEndTime = this.appointmentPopUp.value.endDateTime._d.toLocaleTimeString('en-GB');
        let day = this.appointmentPopUp.value.startDateTime;
        let appointDay = moment(day).format("dddd");
        ////

        let drInfo = res.data[0];
        this.timeLabels = [];
        drInfo.dayInfo.forEach((element) => {
          // ////
          if (element.day == appointDay) {
            if(this.halfDay == 'Half Day' && this.isHalfDay == true)
                    {
                     ////
                       ////
                       ////
    
                       this.timelineLabels(
                        this.halfdayFromTime,
                        this.durationValue,
                        "minutes",
                        this.halfdayToTime
                      );
                    }
                    else
                    {
                      this.timelineLabels(
                        element.morningFrom,
                        this.durationValue,
                        "minutes",
                        element.morningTo
                      );
                      this.timelineLabels(
                        element.afternoonFrom,
                        this.durationValue,
                        "minutes",
                        element.afternoonTo
                      );
                      this.timelineLabels(
                        element.eveningFrom,
                        this.durationValue,
                        "minutes",
                        element.eveningTo
                      );
                    }
        
          }
        });
      });
  }

  timelineLabels = (desiredStartTime, interval, period, desiredEndTime) => {
    // const periodsInADay = moment.duration(1, 'day').as(period);
    // const xyz= moment(desiredEndTime,'hh:mm').subtract(desiredStartTime,'hours').format();
 
    // parse time using 24-hour clock and use UTC to prevent DST issues
    const start = moment.utc(desiredStartTime, "hh:mm A");
    const end = moment.utc(desiredEndTime, "hh:mm A");
    ////
    ////
    // account for crossing over to midnight the next day
    if (end.isBefore(start)) end.add(0, "day");
    // calculate the duration
    const d = moment.duration(end.diff(start)).as(period);

    const periodsInADay = moment.duration(desiredEndTime).as(period);
    // //

    // //

    // this.timeLabels = [];
    const startTimeMoment = moment(desiredStartTime, "hh:mm A");
    for (let i = 0; i < d; i += interval) {
      startTimeMoment.add(i === 0 ? 0 : interval, period);
     
      this.timeLabels.push({
        slot: startTimeMoment.format("hh:mm A"),
        status: "unbooked",
        isDisabled: "No",
      });
      // //
    }

    return this.timeLabels;
  };

  reset() {
    this.savebutton = true;
    this.updatebutton = false;
    this.clear = false;
    this.delete = false;
    this.appointmentPopUp.reset();
    this.timeLabels = [];
  }
  btnClick()
  {
    if(this.appointmentPopUp.invalid) {
      this.appointmentPopUp.get("clientName").markAsTouched();
      this.appointmentPopUp.get("AppoinmentType").markAsTouched();
      return;
    }
  }
  submit() {  

 if (this.appointmentPopUp.invalid) {
      this.appointmentPopUp.get("clientName").markAsTouched();
      this.appointmentPopUp.get("AppoinmentType").markAsTouched();
      return;
    } else {
      if (
        this.BackDatedCreate == false && this.appointmentPopUp.value.startDateTime <
        (new Date(this.currentdate).toISOString())) {
          ////
          
        this.apimicroservice.openSnackbar(
          "Previous Dated Slots Cannot be booked"
        );
        this.ngOnInit();
      } else {
        this.spinner.show();
        let payload = this.appointmentPopUp.value;
        payload.appoinmentTime = this.newAppointment;
        payload.branchRefId = this.branchRef;
        payload.clinicRefId = this.clinicRef;
        payload.doctorRefId = this.doctorRefId;
         
        if(this.pRefId == this.patientRefId)
        {
          payload.patientRefId = this.pRefId;
          payload.patientName = this.pName;
          payload.patientId = this.patientIdd;  
        }
        else{
          payload.patientRefId = this.patientRefId;
          payload.patientName = this.patientName;
          payload.patientId = this.patientId;
        }

        payload.aptStatus = "Cancel";
        // ////
        this.apimicroservice.addAppointment(payload).subscribe(
          (res) => {
            // ////
  
            this.spinner.hide();
            if (res.status) { 
              // ////
              if (res.message == "Saved Successfully") {
                this.apimicroservice.openSnackbar("Appoinment Saved");
              } else {
                this.apimicroservice.openSnackbar(res.message);
              }
              this.dialogRef.close(); 
              this.ngOnInit();
            } else {
              this.apimicroservice.openSnackbar(res.message);
            }
          },
          (ERROR: HttpErrorResponse) => {
            //The number  is unverified. Trial accounts cannot send messages to unverified numbers; verify  at twilio.com/user/account/phone-numbers/verified, or purchase a Twilio number to send messages to unverified numbers.
            // this.apimicroservice.openSnackbar(ERROR.error.message);
            if (
              ERROR.error.message ==
              "The number  is unverified. Trial accounts cannot send messages to unverified numbers; verify  at twilio.com/user/account/phone-numbers/verified, or purchase a Twilio number to send messages to unverified numbers."
            ) {
              //this.apimicroservice.openSnackbar("Invalid Phone Number");
            }
            this.dialogRef.close();
            this.spinner.hide();
          }
        );
      }

    }
   
  }

  update() {
    if (
      this.BackDatedCreate == false && this.appointmentPopUp.value.startDateTime <
      (new Date(this.currentdate).toISOString())
    ) {
      ////
      this.apimicroservice.openSnackbar(
        "Previous Dated Slots Cannot be booked"
      );
      this.ngOnInit();
    } else {
      this.spinner.show();
      let payload = this.appointmentPopUp.value;
      payload.appoinmentTime = this.newAppointment;
      payload.branchRefId = this.branchRef;
      payload.doctorRefId = this.doctorRefId;
      payload.patientRefId = this.patientRefId;
      payload.patientName = this.patientName;
      payload.patientId = this.patientId;
      payload.aptStatus = "Rescheduled";
      this.apimicroservice
        .updateAppointment(this.updateRefid, this.appointmentPopUp.value)
        .subscribe((res) => {
          this.spinner.hide();
          if (res.status) {
            // //
            this.savebutton = true;
            this.updatebutton = false;
            this.clear = false;
            this.delete = false;
            
            this.apimicroservice.openSnackbar(res.message);
            this.dialogRef.close();
            this.ngOnInit();
          } else {
            this.apimicroservice.openSnackbar(res.message);
          }
        },(ERROR: HttpErrorResponse) => {
          //The number  is unverified. Trial accounts cannot send messages to unverified numbers; verify  at twilio.com/user/account/phone-numbers/verified, or purchase a Twilio number to send messages to unverified numbers.
          // this.apimicroservice.openSnackbar(ERROR.error.message);
          if (
            ERROR.error.message ==
            "The number  is unverified. Trial accounts cannot send messages to unverified numbers; verify  at twilio.com/user/account/phone-numbers/verified, or purchase a Twilio number to send messages to unverified numbers."
          ) {
           // this.apimicroservice.openSnackbar("Invalid Phone Number");
          }
          this.dialogRef.close();
          this.spinner.hide();
        });
    }
  }

  fetchDuration() {
    this.apimicroservice.getAppointGenericAllData().subscribe((res) => {
      // //
      if (res.message == "No Data Found") {
        this.durationValue = 30;
      } else {
        if (res.data[0].duration == undefined) {
          this.durationValue = 30;
        } else {
          this.durationValue = res.data[0].duration;
        }
        // this.durationValue = res.data[0].duration;
      }
      // if (res.status) {
      //   this.durationValue = res.data[0].duration;
      // } else {
      // }
    });
  }
  functiondelete(){ 
    //this.spinner.show(); 
    //
    let payload = this.appointmentPopUp.value;
    const dialogRef3 = this.dialog.open(PopupCancelConfirmComponent,
      { 
        width: "50%",
        //height: "570px"
        data: {
          appointRefId: this.updateRefid,
          appointDate:payload.startDateTime,  
          patientName:payload.clientName
        }
      });
      dialogRef3.afterClosed().subscribe((result) => {
        
        
        if(result ==true)
        {
          this.appointmentPopUp.get("aptStatus").setValue("Cancel");
        }
        this.savebutton = true;
        this.updatebutton = false; 
        this.clear = false;
        this.delete = false;
        this.onClose();
        this.appointmentPopUp.reset();
        this.ngOnInit();
       });
  } 
  onClose(): void {
    this.dialogRef.close();
  }
  // functiondelete() {
  //   this.spinner.show();
  //   let payload = this.appointmentPopUp.value;
  //   this.appointmentPopUp.get("aptStatus").setValue("Cancel");
  //   this.apimicroservice 
  //     .deleteAppointmentData(this.updateRefid)
  //     .subscribe((res) => {
  //       this.spinner.hide();
  //       if (res.status) {
  //         //  this.apimicroservice.openSnackbar(res.message);
  //         this.savebutton = true;
  //         this.updatebutton = false;
  //         this.clear = false;
  //         this.delete = false;
  //         this.appointmentPopUp.reset();
  //         this.ngOnInit();
  //       } else {
  //         // this.apimicroservice.openSnackbar(res.message);
  //       }
  //     });
  // }

  onSelectTime(time) {
    this.isSave = false;
    if (this.updatebutton == true) {
      this.isUpdate = false;
    }
    this.branchUnavailable = false;
    let ssss = this.appointmentPopUp.get("startDateTime").value;
    let currentime = new Date();
    let ss = moment.utc(time, "HH:mm A").toISOString();
    let mydaya = new Date(ss);
    let dtOffset = new Date(
      mydaya.setMinutes(mydaya.getMinutes() + currentime.getTimezoneOffset())
    );
    let hours = new Date(dtOffset).getHours();
    let minutes = new Date(dtOffset).getMinutes();
    let timex = new Date(ssss).setHours(hours);
    let timess = new Date(timex).setMinutes(minutes);
    this.appointmentPopUp
      .get("startDateTime")
      .setValue(new Date(timess).toISOString());
    this.appointmentPopUp.get("appoinmentTime").setValue(time);
    this.newAppointment = time;

    // // Date in EST and ISO format: "2021-11-30T15:33:32.222Z"
    // //);
  }

  dddd(e) {
    ////  
    this.appointmentPopUp.get("eventColor").setValue(e.color);
  }
  toDateChange() {
    let payload = this.appointmentPopUp.value;

    if (Date.parse(payload.endDateTime) <= Date.parse(payload.startDateTime)) {
      this.apimicroservice.openSnackbar(
        "To date should be greater than From date"
      );
      this.appointmentPopUp.controls["startDateTime"].setValue("");
    }

    this.apimicroservice
      .getAppointmentbydatedate(payload.startDateTime)
      .subscribe((res) => {
        // //
        if (res.status) {
          //  //
          //this.apimicroservice.openSnackbar(res.message);
        } else {
          // this.apimicroservice.openSnackbar(res.message);
        }
      });
    ////
    ////
    ////
    this.appointmentPopUp.controls["doctorName"].setValue("");
    this.timeLabels = [];
    if (this.updatebutton == true) {
      this.isUpdate = true;
    }
    // this.filteredDoctorList =[];
    //this.timeLabels = [];
    if (payload.doctorName == "") {
      this.searcheddoctor = false;
      this.timeLabels = [];
      if (this.updatebutton == true) {
        this.isUpdate = true;
      }
    }
    ////
    if (payload.clinicLocation !== "") {
      ////

      ////
      if (this.updatebutton == true) {
        this.isUpdate = true;
      }
      let bar = new Promise<void>((resolve, reject) => {
        this.selectBranch(this.branchRef);
        resolve();
      });

      bar.then(() => {
        if (payload.doctorName !== "") {
          ////
          // this.filteredDoctorList =[];
          //this.timeLabels = [];
          ////
          if (this.appointmentPopUp.controls["doctorName"].disabled == false) {
            ////
            this.searcheddoctor = false;
            this.timeLabels = [];
            if (this.updatebutton == true) {
              this.isUpdate = true;
            } 
            if (payload.doctorName == "") {
              this.searcheddoctor = false;
              this.timeLabels = [];
            }
            this.selectDoctor(this.doctorID);
          }
        } else {
          ////
          this.searcheddoctor = false;
          this.timeLabels = [];
          if (this.updatebutton == true) {
            this.isUpdate = true;
          }
        }
      });
    }

    // this.timeLabels = [];
  }
  fromDateChange() {
    let payload = this.appointmentPopUp.value;
    if (Date.parse(payload.endDateTime) <= Date.parse(payload.startDateTime)) {
      this.apimicroservice.openSnackbar(
        "From date should be Less than To date"
      );
      this.appointmentPopUp.controls["endDateTime"].setValue("");
    }
  }
  toTimeClick() {
    // let payload = this.appointmentPopUp.value;
    // let durationTime = this.datepipe.transform(payload.duration, 'mm:ss a');
    // ////
    // let timee = durationTime + payload.startDateTime;
    // //
    // let latest_date =this.datepipe.transform(timee, 'MMM d, y, h:mm:ss a');
    // this.endDateTime =  latest_date;
    // //
  }

  selectBranch(id) { 
    this.filteredDoctorList= [];
    this.timeLabels = [];
    this.branchRef = id;
    // let clinicRef= sessionStorage.getItem('clinicRef');
    // let branchRef= sessionStorage.getItem('branchRef');
    
    //this.isSave = false;
    let payload = this.appointmentPopUp.value;
    this.searcheddoctor = false;
    this.appointmentPopUp.controls["doctorName"].setValue("");
    if (this.updatebutton == true) {
      this.isUpdate = true;
      this.branchUnavailable = false;
      this.branchmsg = "";
      this.appointmentPopUp.controls["doctorName"].enable();
    } else {
      this.isSave = true;
      this.branchUnavailable = false;
      this.branchmsg = "";
      this.appointmentPopUp.controls["doctorName"].enable();
    }
    let appDate = this.appointmentPopUp.controls["startDateTime"].value;
    this.apimicroservice.getBranchById(id).subscribe((res) => {
      console.log("Apoinment response1====>>",res);
      //  //
      //  //
      this.clinicRef = res.data[0].clinicRefId;
      this.openBranchTime = res.data[0].openTime; 
      this.closetime = res.data[0].closeTime;
      this.weeklyOff = res.data[0].branchWeeklyOff;
      this.tt = moment(this.openBranchTime, "hh:mm A").format("HH:mm:ss");
      ////
      this.ct = moment(this.closetime, "hh:mm A").format("HH:mm:ss");
      ////
      ////
      ////
      // this.fetchDoctorList();

      appDate = new Date(appDate);
      let datesarr = [];
      if (this.branchHolidayList.length > 0) {
        this.branchHolidayList.forEach((ele) => {
          if (ele.branchRefId == this.branchRef) {
            datesarr.push({
              startDate: ele.fromDate,
              endDate: ele.toDate,
              holidayName: ele.holidayName,
            });
          }
        });
        this.filteredDoctorList= [];
        ////
        datesarr.forEach((ele) => {
          let st = new Date(ele.startDate);
          let et = new Date(ele.endDate);
          let holiday = ele.holidayName;
          let tomorrow = moment(et).add(1, "days");
          if (appDate.getTime() >= st.getTime() && appDate < tomorrow) {
            // this.openSnackbar("On occusion of "+holiday+", "+"Clinic is Close From"+st.toDateString()+" to "+et.toDateString());
            this.unavailable = true;
            if (st.toDateString() !== et.toDateString()) {
              this.branchmsg =
                "On occusion of " + 
                holiday +
                ", " +
                "clinic will be closed from " +
                st.toDateString() +
                " to " +
                et.toDateString();
            } else {
              this.branchmsg =
                "On occusion of " +
                holiday +
                ", " +
                "clinic will be closed on " +
                st.toDateString();
            }
            ////
            // this.isSave = true;
            if (this.updatebutton == true) {
              this.isUpdate = true;
              this.branchUnavailable = true;
              this.appointmentPopUp.controls["doctorName"].disable();
              this.timeLabels = [];
            } else {
              this.isSave = true;
              this.branchUnavailable = true;
              this.appointmentPopUp.controls["doctorName"].disable();
              this.timeLabels = [];
            }
          }
        });
        // this.branchHolidayList.forEach(ele =>{
        //   if(ele.branchRefId == id){
        //    found = "true";
        //    holidayName = ele.holidayName;
        //    startDt = new Date(ele.fromDate);
        //    endDate = new Date(ele.toDate);
        //   }
        // });
      } 
      //  else{
        this.filteredDoctorList= [];
      ////
      let appDate2 = this.appointmentPopUp.controls["startDateTime"].value;
      appDate2 = new Date(appDate2);
      let dayNo = 0;
      this.dayArray.forEach((ele, i) => {
        if (ele.value == this.weeklyOff) {
          dayNo = ele.id;
        }
      });
      let startDate = moment(appDate2);
      let dateeee = startDate.clone(),
        isWeekend = false;

      if (dateeee.isoWeekday() == dayNo) {
        isWeekend = true;
      }
      if (isWeekend) {
        //appDate = dd;

        // this.openSnackbar('Cant add appointment - branch weekly off!');
        this.unavailable = true;
        this.branchmsg = "Branch weekly off!";
        ////
        // this.isSave = true;
        if (this.updatebutton == true) {
          this.isUpdate = true;
          this.branchUnavailable = true;
          this.appointmentPopUp.controls["doctorName"].disable();
          this.timeLabels = [];
        } else {
          this.isSave = true;
          this.branchUnavailable = true;
          this.appointmentPopUp.controls["doctorName"].disable();
          this.timeLabels = [];
        }
        //  return false;
      }
      // }

      //  if(found == "true"){
      //      if(appDate >= startDt && appDate <= endDate){
      //        this.apimicroservice.openSnackbar("Branch is closed from "+startDt.toDateString()+" to "+endDate.toDateString()+" for"+holidayName+" holiday");
      //        this.appointmentPopUp.controls['startDateTime'].setValue('');
      //      }
      //  }

      //lates code - for doctor
      // this.apimicroservice.getAllDoctorsList(id,this.clinicRef).subscribe(res => {
      //   if (res.status) {
      //     this.doctorList = res.data;
      //     this.filteredDoctorList= this.doctorList.slice();
      //   } else {
      //  //   this.apimicroservice.openSnackbar(res.message);
      //   }
      //   });

      //testing code
      this.spinner.show();
      this.filteredDoctorList= [];
      let clinicRef= sessionStorage.getItem('clinicRef');
      let branchRef= sessionStorage.getItem('branchRef');
      let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
      let branchRefSuperAdmin= '';
      if (this.userType !== 'Super Admin') {
      this.apimicroservice
        .getAllDoctorsListAvailable(id,clinicRef)
        .subscribe((res) => {
          this.spinner.hide();
          if (res.status) {
            this.filteredDoctorList= [];
            ////
            
            if(res.data.length > 0)
            { 
              ////
              
              this.doctorList = res.data;
              this.filteredDoctorList = this.doctorList.slice();
            //  let length =res.data.length - 1;
              this.appointmentPopUp.get("doctorName").setValue(this.doctorList[0].doctorName);
              this.selectDoctor(this.doctorList[0].doctorRefId);
              this.spinner.hide();
            }
           
          } else {
            //   this.apimicroservice.openSnackbar(res.message);
          }
        });
      }else{
        this.apimicroservice
        .getAllDoctorsListAvailable(id,clinicRefSuperAdmin)
        .subscribe((res) => {
          this.spinner.hide();
          if (res.status) {
            this.filteredDoctorList= [];
            ////
            
            if(res.data.length > 0)
            { 
              ////
              
              this.doctorList = res.data;
              this.filteredDoctorList = this.doctorList.slice();
            //  let length =res.data.length - 1;
              this.appointmentPopUp.get("doctorName").setValue(this.doctorList[0].doctorName);
              this.selectDoctor(this.doctorList[0].doctorRefId);
              this.spinner.hide();
            }
           
          } else {
            //   this.apimicroservice.openSnackbar(res.message);
          }
        });
      }
    });
    if (payload.doctorName == "") {
      this.searcheddoctor = false;
      this.timeLabels = [];
    }
  }
  eventclickbranch(branch, doct) {
    this.timeLabels = [];
    this.branchRef = branch;
    let docunavilable = "false";
    //this.fetchDoctorLeaves(doct);
    this.apimicroservice.getBranchById(branch).subscribe((res) => {
      //  ////
      //  //
      this.clinicRef = res.data[0].clinicRefId;
      this.fetchDoctorList();
      this.appointList = [];
      this.searcheddoctor = false;
      this.doctorRefId = doct;
      let day = this.appointmentPopUp.value.startDateTime;
      day = new Date(day).toISOString();

      let datesarr = [];
      let appDate = this.appointmentPopUp.controls["startDateTime"].value;
      appDate = new Date(appDate);
      this.getDoctorAvailability();
      this.apimicroservice
        .getDoctorLeaveDataByRefId(this.doctorRefId)
        .subscribe((res) => {
          if (res.status) {
            ////
            this.doctorLeavesList = res.data;

            if (this.doctorLeavesList.length > 0) {
              this.doctorLeavesList.forEach((ele) => {
                datesarr.push({ startDate: ele.fromDate, endDate: ele.toDate });
              });

              datesarr.forEach((ele) => {
                let st = new Date(ele.startDate);
                let et = new Date(ele.endDate);
                let tomorrow = moment(et).add(1, "days");
                if (appDate.getTime() >= st.getTime() && appDate < tomorrow) {
                  this.openSnackbar(
                    "Audiologists is on leave From - " +
                      st.toDateString() +
                      " to - " +
                      et.toDateString()
                  );
                  this.timeLabels = [];
                  docunavilable = "true";
                  this.isUpdate = true;
                }
              });
              if (docunavilable == "false") {
                this.isUpdate = false;
                this.apimicroservice
                  .getDoctorAppointAvailable(
                    this.branchRef,
                    this.clinicRef,
                    this.doctorRefId
                  )
                  .subscribe((res) => {
                    // //
                    // //
                    // let appointStartTime = this.appointmentPopUp.value.startDateTime._d.toLocaleTimeString('en-GB');
                    // let appointEndTime = this.appointmentPopUp.value.endDateTime._d.toLocaleTimeString('en-GB');
                    let day = this.appointmentPopUp.value.startDateTime;
                    let appointDay = moment(day).format("dddd");
                    let drInfo = res.data[0];
                    this.timeLabels = [];
                    drInfo.dayInfo.forEach((element) => {
                      // //
                      if (element.day == appointDay) {
                        // //
                        //this.timelineLabels(element.morningFrom,1,'hours',element.morningTo);
                        if(this.halfDay == 'Half Day' && this.isHalfDay == true)
                        {
                         ////
                           ////
                           ////

                           this.timelineLabels(
                            this.halfdayFromTime,
                            this.durationValue,
                            "minutes",
                            this.halfdayToTime
                          );
                        }
                        else
                        {
                          ////
                          this.timelineLabels(
                            element.morningFrom,
                            this.durationValue,
                            "minutes",
                            element.morningTo
                          );
                          this.timelineLabels(
                            element.afternoonFrom,
                            this.durationValue,
                            "minutes",
                            element.afternoonTo
                          );
                          this.timelineLabels(
                            element.eveningFrom,
                            this.durationValue,
                            "minutes",
                            element.eveningTo
                          );
                        }

                       
                      }
                    });
                    this.apimicroservice
                      .getAppointmentbydatedoctor(day, this.doctorRefId)
                      .subscribe((res) => {
                        this.dd = new Date();
                        let a = "";
                        let b = "";
                        a = "6:00 AM";
                        b = "6:00 PM";
                        let xyz = this.appointmentPopUp.get("startDateTime")
                          .value;
                        let appointDate = new Date(xyz);
                        /////////
                        ////
                        for (let i = 0; i < this.timeLabels.length; i++) {
                          ////
                          const now = Date.now();
                          this.myFormattedDate = this.pipe.transform(
                            now,
                            "shortTime"
                          );

                          this.dateTime = new Date(
                            this.dd.toDateString() +
                              " " +
                              this.timeLabels[i].slot
                          );
                          this.dateTime2 = new Date(
                            this.dd.toDateString() + " " + this.myFormattedDate
                          );
                          ////
                          ////
                          if (
                            this.dd.toDateString() == appointDate.toDateString()
                          ) {
                            if (this.dateTime < this.dateTime2) {
                              this.timeLabels[i].isDisabled = "Yes";
                            
                            }
                          }
                        }
                        this.appointList = res.data;
                        if (this.appointList.length > 0) {
                          for (let i = 0; i < this.timeLabels.length; i++) {
                            for (let j = 0; j < this.appointList.length; j++) {
                              if (
                                this.timeLabels[i].slot ===
                                this.appointList[j].appoinmentTime
                              ) {
                                this.timeLabels[i].status = "booked";
                              }
                            }
                          }
                          this.searcheddoctor = false;
                        } else {
                          this.searcheddoctor = true;
                        }
                        // //
                      });
                  });
              }
            } else {
              this.isUpdate = false;
              this.apimicroservice
                .getDoctorAppointAvailable(
                  this.branchRef,
                  this.clinicRef,
                  this.doctorRefId
                )
                .subscribe((res) => {
                  // //
                  // //
                  // let appointStartTime = this.appointmentPopUp.value.startDateTime._d.toLocaleTimeString('en-GB');
                  // let appointEndTime = this.appointmentPopUp.value.endDateTime._d.toLocaleTimeString('en-GB');
                  let day = this.appointmentPopUp.value.startDateTime;
                  let appointDay = moment(day).format("dddd");
                  let drInfo = res.data[0];
                  this.timeLabels = [];
                  drInfo.dayInfo.forEach((element) => {
                    // //
                    if (element.day == appointDay) {
                      if(this.halfDay == 'Half Day' && this.isHalfDay == true)
                        {
                         ////
                           ////
                           ////

                           this.timelineLabels(
                            this.halfdayFromTime,
                            this.durationValue,
                            "minutes",
                            this.halfdayToTime
                          );
                        }
                        else
                        {
                          this.timelineLabels(
                            element.morningFrom,
                            this.durationValue,
                            "minutes",
                            element.morningTo
                          );
                          this.timelineLabels(
                            element.afternoonFrom,
                            this.durationValue,
                            "minutes",
                            element.afternoonTo
                          );
                          this.timelineLabels(
                            element.eveningFrom,
                            this.durationValue,
                            "minutes",
                            element.eveningTo
                          );
                        }
                    
                    }
                  });
                  this.apimicroservice
                    .getAppointmentbydatedoctor(day, this.doctorRefId)
                    .subscribe((res) => {
                      this.dd = new Date();
                      let a = "";
                      let b = "";
                      a = "6:00 AM";
                      b = "6:00 PM";
                      let xyz = this.appointmentPopUp.get("startDateTime")
                        .value;
                      let appointDate = new Date(xyz);
                      /////////
                      ////
                      for (let i = 0; i < this.timeLabels.length; i++) {
                        ////
                        const now = Date.now();
                        this.myFormattedDate = this.pipe.transform(
                          now,
                          "shortTime"
                        );

                        this.dateTime = new Date(
                          this.dd.toDateString() + " " + this.timeLabels[i].slot
                        );
                        this.dateTime2 = new Date(
                          this.dd.toDateString() + " " + this.myFormattedDate
                        );
                        ////
                        ////
                        if (
                          this.dd.toDateString() == appointDate.toDateString()
                        ) {
                          if (this.dateTime < this.dateTime2) {
                            this.timeLabels[i].isDisabled = "Yes";
                          
                          }
                        }
                      }
                      this.appointList = res.data;
                      if (this.appointList.length > 0) {
                        for (let i = 0; i < this.timeLabels.length; i++) {
                          for (let j = 0; j < this.appointList.length; j++) {
                            if (
                              this.timeLabels[i].slot ===
                              this.appointList[j].appoinmentTime
                            ) {
                              this.timeLabels[i].status = "booked";
                            }
                          }
                        }
                        this.searcheddoctor = false;
                      } else {
                        this.searcheddoctor = true;
                      }
                      // //
                    });
                });
            }
          }
        });

      //   this.apimicroservice.getDoctorAppointAvailable(this.branchRef,this.clinicRef,this.doctorRefId).subscribe(res => {
      //     // //
      //     // //
      //     // let appointStartTime = this.appointmentPopUp.value.startDateTime._d.toLocaleTimeString('en-GB');
      //     // let appointEndTime = this.appointmentPopUp.value.endDateTime._d.toLocaleTimeString('en-GB');
      //     let day =this.appointmentPopUp.value.startDateTime;
      //     let appointDay = moment(day).format('dddd')
      //     let drInfo = res.data[0];
      //     this.timeLabels = [];
      //     drInfo.dayInfo.forEach(element =>{
      //       // //
      //       if (element.day == appointDay ) {
      //         // //
      //         //this.timelineLabels(element.morningFrom,1,'hours',element.morningTo);
      //         this.timelineLabels(element.morningFrom,this.durationValue,'minutes',element.morningTo);
      //         this.timelineLabels(element.afternoonFrom,this.durationValue,'minutes',element.afternoonTo);
      //         this.timelineLabels(element.eveningFrom,this.durationValue,'minutes',element.eveningTo);
      //         }
      //     })
      //     this.apimicroservice.getAppointmentbydatedoctor(day,this.doctorRefId).subscribe(res => {
      //       this.dd = new Date();
      //       let a = '';
      //       let b ='';
      //       a = '6:00 AM';
      //       b = '6:00 PM';
      //       let xyz = this.appointmentPopUp.get('startDateTime').value;
      //       let appointDate = new Date(xyz);
      //       /////////
      //       //
      //       for(let i=0;i<this.timeLabels.length;i++){
      //         //
      //        const now = Date.now();
      //         this.myFormattedDate = this.pipe.transform(now, 'shortTime');

      //         //
      //         this.dateTime = new Date(this.dd.toDateString()+" "+this.timeLabels[i].slot);
      //         this.dateTime2 = new Date(this.dd.toDateString()+" "+this.myFormattedDate);
      //         //
      //         //
      //         if(this.dd.toDateString() == appointDate.toDateString()){
      //         if(this.dateTime  < this.dateTime2) {
      //           this.timeLabels[i].isDisabled = 'Yes';
      //           //
      //        // this.isDisabled = true;
      //          //

      //          //
      //          //

      //         }
      //        }

      //       }
      //       this.appointList = res.data;
      //       if(this.appointList.length > 0){
      //         for(let i=0;i<this.timeLabels.length;i++){
      //           for(let j=0;j<this.appointList.length;j++){
      //             if(this.timeLabels[i].slot === this.appointList[j].appoinmentTime){
      //               this.timeLabels[i].status = "booked";
      //             }
      //           }
      //         }
      //         this.searcheddoctor = false;
      //       }else{
      //         this.searcheddoctor = true;
      //       }
      //       // //
      //     });

      //   });
    });
  }

  selectPatient(id, fname, lname, pid) {
    ////

    this.patientRefId = id;
    this.patientName = fname + " " + lname;
    this.patientId = pid;
    this.apimicroservice.getPatientById(id).subscribe((res) => {
      // ////);
      let mobile1 = res.data[0].mobile1;
      this.appointmentPopUp.get("contactNumber").setValue(mobile1);
    });
  }

  fetchDoctorLeaves(doctorId) {
    // let docunavilable = "false";
    this.apimicroservice
      .getDoctorLeaveDataByRefId(doctorId)
      .subscribe((res) => {
        if (res.status) {
          ////
          this.doctorLeavesList = res.data;
          // let datesarr = [];
          // let appDate = this.appointmentPopUp.controls['startDateTime'].value;
          // appDate = new Date(appDate);
          // if(this.doctorLeavesList.length > 0){
          //   this.doctorLeavesList.forEach(ele =>{
          //      datesarr.push({"startDate":ele.fromDate,'endDate':ele.toDate});
          //   });

          //   datesarr.forEach(ele =>{
          //     let st = new Date(ele.startDate);
          //     let et = new Date(ele.endDate);

          //     if(appDate >= st && appDate <= et){
          //       this.openSnackbar("Audiologists is on leave From - "+st.toDateString()+" to - "+et.toDateString());
          //        docunavilable = "true";
          //     }
          //   })
          //   if(docunavilable == "false"){

          //   }
          // }else{

          // }
        }
      });
  }
  selectDoctor(id) {
    ////
    let payload = this.appointmentPopUp.value;
    let docunavilable = "false";
    ////
    // this.fetchDoctorLeaves(id);

    this.appointList = [];
    this.searcheddoctor = false;
    this.doctorRefId = id;
    this.doctorID = id;
    let day = this.appointmentPopUp.value.startDateTime;
    day = new Date(day).toISOString();
    let xyz = this.appointmentPopUp.get("startDateTime").value;
    let appointDate = new Date(xyz);
    // this.getDoctorAvailability();
    let datesarr = [];
    let appDate = this.appointmentPopUp.controls["startDateTime"].value;
    appDate = new Date(appDate);
    //  let date = payload.startDateTime.toISOString();

    let dateSt = new Date(payload.startDateTime);
    dateSt.setDate(dateSt.getDate()-1);
    let tdate=this.datepipe.transform(dateSt, 'yyyy-MM-dd');

    let bar = new Promise<void>((resolve, reject) => {
      this.apimicroservice.getDoctorLeaveDataByDoctorId(tdate,id).subscribe(res => {
        this.spinner.hide(); 
        if(res.message == 'No Data Found')
        {
           this.isHalfDay = false;
        }
        else
        {
          this.isHalfDay = true;
          this.halfDay = res.data[0].leaveType;  
          ////
           this.halfdayFromTime = res.data[0].fromTime;
           this.halfdayToTime = res.data[0].toTime;
        }
       });
      this.apimicroservice.getDoctorLeaveDataByRefId(id).subscribe((res) => {
        if (res.status) {
          ////
          this.doctorLeavesList = res.data;
        }
        resolve();
      });
    });
   
    bar.then(() => {
      if (this.doctorLeavesList.length > 0) {
        this.doctorLeavesList.forEach((ele) => {
          datesarr.push({ startDate: ele.fromDate, endDate: ele.toDate });
        });

        datesarr.forEach((ele) => {
          let st = new Date(ele.startDate);
          let et = new Date(ele.endDate);
          let tomorrow = moment(et).add(1, "days");
          ////
          ////
          if (appDate.getTime() >= st.getTime() && appDate < tomorrow) {
            this.openSnackbar(
              "Audiologists is on leave From - " +
                st.toDateString() +
                " to - " +
                et.toDateString()
            );
            this.timeLabels = [];
            docunavilable = "true";
            if (this.updatebutton == true) {
              this.isUpdate = true;
            } else {
              this.isSave = true;
            }
          }
        });
        if (docunavilable == "false") {
          if (this.updatebutton == true) {
            this.isUpdate = true;
          } else {
            this.isSave = true;
          }

          this.apimicroservice
            .getDoctorAppointAvailable(
              this.branchRef,
              this.clinicRef,
              this.doctorRefId
            )
            .subscribe((res) => {
              ////
              if (res.message == "No Data Found") {
                this.openSnackbar("Audiologists Un-Available");
              }
              // ////
              // let appointStartTime = this.appointmentPopUp.value.startDateTime._d.toLocaleTimeString('en-GB');
              // let appointEndTime = this.appointmentPopUp.value.endDateTime._d.toLocaleTimeString('en-GB');
              let day = this.appointmentPopUp.value.startDateTime;
              let appointDay = moment(day).format("dddd");
              let drInfo = res.data[0];
              ////

              this.timeLabels = [];
              ////
              ////

              drInfo.dayInfo.forEach((element) => {
                // ////
                if (element.day == appointDay) {
                  if(this.halfDay == 'Half Day' && this.isHalfDay == true)
                  {
                   ////
                     ////
                     ////

                     this.timelineLabels(
                      this.halfdayFromTime,
                      this.durationValue,
                      "minutes",
                      this.halfdayToTime
                    );
                  }
                  else
                  {
                    this.timelineLabels(
                      element.morningFrom,
                      this.durationValue,
                      "minutes",
                      element.morningTo
                    );
                    this.timelineLabels(
                      element.afternoonFrom,
                      this.durationValue,
                      "minutes",
                      element.afternoonTo
                    );
                    this.timelineLabels(
                      element.eveningFrom,
                      this.durationValue,
                      "minutes",
                      element.eveningTo
                    );
                  }
                 
                }
              });
              this.apimicroservice
                .getAppointmentbydatedoctor(day, this.doctorRefId)
                .subscribe((res) => {
                  ////
                  /////////////////
                  if (payload.doctorName == "") {
                    this.searcheddoctor = false;
                    this.timeLabels = [];
                  }
                  this.dd = new Date();
                  let a = "";
                  let b = "";
                  a = "6:00 AM";
                  b = "6:00 PM";

                  /////////
                  ////
                  for (let i = 0; i < this.timeLabels.length; i++) {
                    ////
                    const now = Date.now();
                    this.myFormattedDate = this.pipe.transform(
                      now,
                      "shortTime"
                    );

                    this.dateTime = new Date(
                      this.dd.toDateString() + " " + this.timeLabels[i].slot
                    );
                    this.dateTime2 = new Date(
                      this.dd.toDateString() + " " + this.myFormattedDate
                    );
                    ////
                    ////
                    ////);
                    ////);
                    if (this.dd.toDateString() == appointDate.toDateString()) {
                      if (this.dateTime < this.dateTime2) {
                        this.timeLabels[i].isDisabled = "Yes";
                        ////
                        // this.isDisabled = true;
                        ////

                      }
                    }
                    if (payload.doctorName == "") {
                      this.searcheddoctor = false;
                      this.timeLabels = [];
                    }
                  }
                  this.appointList = res.data;
                  ////
                  if (this.appointList.length > 0) {
                    if (payload.doctorName == "") {
                      this.searcheddoctor = false;
                      this.timeLabels = [];
                    }
                    for (let i = 0; i < this.timeLabels.length; i++) {
                      ////
                      // if(this.timeLabels[i].slot <==)
                      //  const now = Date.now();
                      //   this.myFormattedDate = this.pipe.transform(now, 'shortTime');
                      //   //
                      //   //
                      //   if(this.timeLabels[i].slot > this.myFormattedDate) {

                      //    this.isDisabled = true;
                      //    //

                      //    //
                      //    //

                      //   }
                      if (payload.doctorName == "") {
                        this.searcheddoctor = false;
                        this.timeLabels = [];
                      }
                      ////////////////
                      for (let j = 0; j < this.appointList.length; j++) {
                        if (
                          this.timeLabels[i].slot ===
                          this.appointList[j].appoinmentTime
                        ) {
                          this.timeLabels[i].status = "booked";
                        }
                        if (payload.doctorName == "") {
                          this.searcheddoctor = false;
                          this.timeLabels = [];
                        }
                      }
                    }
                    this.searcheddoctor = false;
                    if (payload.doctorName == "") {
                      this.searcheddoctor = false;
                      this.timeLabels = [];
                    }
                  } else {
                    this.searcheddoctor = true;
                    if (payload.doctorName == "") {
                      this.searcheddoctor = false;
                      this.timeLabels = [];
                    }
                  }
                  ////
                });
            });
        }
      } else {
        if (this.updatebutton == true) {
          this.isUpdate = true;
        } else {
          this.isSave = true;
        }
        this.apimicroservice
          .getDoctorAppointAvailable(
            this.branchRef,
            this.clinicRef,
            this.doctorRefId
          )
          .subscribe((res) => {
            ////
            if (res.message == "No Data Found") {
              this.openSnackbar("Audiologists Un-Available");
            }
            // //
            // let appointStartTime = this.appointmentPopUp.value.startDateTime._d.toLocaleTimeString('en-GB');
            // let appointEndTime = this.appointmentPopUp.value.endDateTime._d.toLocaleTimeString('en-GB');
            let day = this.appointmentPopUp.value.startDateTime;
            let appointDay = moment(day).format("dddd");
            let drInfo = res.data[0];
            ////

            this.timeLabels = [];
            ////
            ////
            if (payload.doctorName == "") {
              this.searcheddoctor = false;
              this.timeLabels = [];
            }

            drInfo.dayInfo.forEach((element) => {
              // ////
              if (element.day == appointDay) {
                if(this.halfDay == 'Half Day' && this.isHalfDay == true)
                {
                 ////
                   ////
                   ////

                   this.timelineLabels(
                    this.halfdayFromTime,
                    this.durationValue,
                    "minutes",
                    this.halfdayToTime
                  );
                }
                else
                {
                  this.timelineLabels(
                    element.morningFrom,
                    this.durationValue,
                    "minutes",
                    element.morningTo
                  );
                  this.timelineLabels(
                    element.afternoonFrom,
                    this.durationValue,
                    "minutes",
                    element.afternoonTo
                  );
                  this.timelineLabels(
                    element.eveningFrom,
                    this.durationValue,
                    "minutes",
                    element.eveningTo
                  );
                }
                
              }
            });
            this.apimicroservice
              .getAppointmentbydatedoctor(day, this.doctorRefId)
              .subscribe((res) => {
                ////
                /////////////////
                if (payload.doctorName == "") {
                  this.searcheddoctor = false;
                  this.timeLabels = [];
                }
                this.dd = new Date();
                let a = "";
                let b = "";
                a = "6:00 AM";
                b = "6:00 PM";

                /////////
                ////
                for (let i = 0; i < this.timeLabels.length; i++) {
                  if (payload.doctorName == "") {
                    this.searcheddoctor = false;
                    this.timeLabels = [];
                  }
                  ////
                  const now = Date.now();
                  this.myFormattedDate = this.pipe.transform(now, "shortTime");

                 
                  this.dateTime = new Date(
                    this.dd.toDateString() + " " + this.timeLabels[i].slot
                  );
                  this.dateTime2 = new Date(
                    this.dd.toDateString() + " " + this.myFormattedDate
                  );
                  ////
                  ////
                  ////);
                  ////);
                  if (this.dd.toDateString() == appointDate.toDateString()) {
                    if (this.dateTime < this.dateTime2) {
                      this.timeLabels[i].isDisabled = "Yes";
                      ////
                      // this.isDisabled = true;
                      ////

                    }
                  }
                }
                this.appointList = res.data;
                if (this.appointList.length > 0) {
                  for (let i = 0; i < this.timeLabels.length; i++) {
                    ////
                    // if(this.timeLabels[i].slot <==)
                    //  const now = Date.now();
                    //   this.myFormattedDate = this.pipe.transform(now, 'shortTime');
                    //   //
                    //   //
                    //   if(this.timeLabels[i].slot > this.myFormattedDate) {

                    //    this.isDisabled = true;
                    //    //

                    //    //
                    //    //

                    //   }
                    if (payload.doctorName == "") {
                      this.searcheddoctor = false;
                      this.timeLabels = [];
                    }
                    ////////////////
                    for (let j = 0; j < this.appointList.length; j++) {
                      if (
                        this.timeLabels[i].slot ===
                        this.appointList[j].appoinmentTime
                      ) {
                        this.timeLabels[i].status = "booked";
                      }
                      if (payload.doctorName == "") {
                        this.searcheddoctor = false;
                        this.timeLabels = [];
                      }
                    }
                  }
                  this.searcheddoctor = false;
                  if (payload.doctorName == "") {
                    this.searcheddoctor = false;
                    this.timeLabels = [];
                  }
                } else {
                  ////

                  /// this.searcheddoctor = true;
                }
                // //
              });
          });
      }
    });

    // this.apimicroservice.getDoctorAppointAvailable(this.branchRef,this.clinicRef,this.doctorRefId).subscribe(res => {
    //    //
    //    if(res.message == 'No Data Found')
    //    {
    //     this.openSnackbar("Audiologists Un-Available");
    //    }
    //   // //
    //   // let appointStartTime = this.appointmentPopUp.value.startDateTime._d.toLocaleTimeString('en-GB');
    //   // let appointEndTime = this.appointmentPopUp.value.endDateTime._d.toLocaleTimeString('en-GB');
    //   let day =this.appointmentPopUp.value.startDateTime;
    //   let appointDay = moment(day).format('dddd')
    //   let drInfo = res.data[0];
    //   //

    //   this.timeLabels = [];
    //   //
    //   //

    //   drInfo.dayInfo.forEach(element =>{
    //     // //
    //     if (element.day == appointDay ) {
    //       // //
    //       //this.timelineLabels(element.morningFrom,1,'hours',element.morningTo);
    //       this.timelineLabels(element.morningFrom,this.durationValue,'minutes',element.morningTo);
    //       this.timelineLabels(element.afternoonFrom,this.durationValue,'minutes',element.afternoonTo);
    //       this.timelineLabels(element.eveningFrom,this.durationValue,'minutes',element.eveningTo);
    //       }
    //   })
    //   this.apimicroservice.getAppointmentbydatedoctor(day,this.doctorRefId).subscribe(res => {
    //     //
    //     /////////////////
    //     this.dd = new Date();
    //     let a = '';
    //     let b ='';
    //     a = '6:00 AM';
    //     b = '6:00 PM';

    //     /////////
    //     //
    //     for(let i=0;i<this.timeLabels.length;i++){
    //       //
    //      const now = Date.now();
    //       this.myFormattedDate = this.pipe.transform(now, 'shortTime');

    //       //
    //       this.dateTime = new Date(this.dd.toDateString()+" "+this.timeLabels[i].slot);
    //       this.dateTime2 = new Date(this.dd.toDateString()+" "+this.myFormattedDate);
    //       //
    //       //
    //       //);
    //       //);
    //        if(this.dd.toDateString() == appointDate.toDateString()){
    //         if(this.dateTime  < this.dateTime2) {
    //           this.timeLabels[i].isDisabled = 'Yes';
    //           //
    //        // this.isDisabled = true;
    //          //

    //          //
    //          //

    //         }
    //       }

    //     }
    //     this.appointList = res.data;
    //     if(this.appointList.length > 0){
    //       for(let i=0;i<this.timeLabels.length;i++){
    //         //
    //        // if(this.timeLabels[i].slot <==)
    //       //  const now = Date.now();
    //       //   this.myFormattedDate = this.pipe.transform(now, 'shortTime');
    //       //   //
    //       //   //
    //       //   if(this.timeLabels[i].slot > this.myFormattedDate) {

    //       //    this.isDisabled = true;
    //       //    //

    //       //    //
    //       //    //

    //       //   }

    //       ////////////////
    //         for(let j=0;j<this.appointList.length;j++){
    //           if(this.timeLabels[i].slot === this.appointList[j].appoinmentTime){
    //             this.timeLabels[i].status = "booked";
    //           }
    //         }
    //       }
    //       this.searcheddoctor = false;
    //     }else{
    //       this.searcheddoctor = true;
    //     }
    //     // //
    //   });

    // });
    if (payload.doctorName == "") {
      this.searcheddoctor = false;
      this.timeLabels = [];
    }
  }

  fetchDoctorList() {
    this.spinner.show();
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
    this.apimicroservice
      .getAllDoctorsListAvailable(branchRef,clinicRef)
      .subscribe((res) => {
        if (res.status) {
        //   this.filteredDoctorList= [];
        //   this.doctorList = res.data;
        //   this.filteredDoctorList = this.doctorList.slice();
        // //  let length =res.data.length - 1;
        //   this.appointmentPopUp.get("doctorName").setValue(this.doctorList[0].doctorName);
        //   this.selectDoctor(this.doctorList[0].doctorRefId);

          this.filteredDoctorList= [];
          this.doctorList = res.data;
          this.filteredDoctorList = this.doctorList.slice();
         // this.selectDoctor(this.doctorList[0].doctorRefId);
          this.spinner.hide();
        } else {
          //  this.apimicroservice.openSnackbar(res.message);
        }
      });
    }else{
      this.apimicroservice
      .getAllDoctorsListAvailable(branchRefSuperAdmin,clinicRefSuperAdmin)
      .subscribe((res) => {
        if (res.status) {
        //   this.filteredDoctorList= [];
        //   this.doctorList = res.data;
        //   this.filteredDoctorList = this.doctorList.slice();
        // //  let length =res.data.length - 1;
        //   this.appointmentPopUp.get("doctorName").setValue(this.doctorList[0].doctorName);
        //   this.selectDoctor(this.doctorList[0].doctorRefId);

          this.filteredDoctorList= [];
          this.doctorList = res.data;
          this.filteredDoctorList = this.doctorList.slice();
         // this.selectDoctor(this.doctorList[0].doctorRefId);
          this.spinner.hide();
        } else {
          //  this.apimicroservice.openSnackbar(res.message);
        }
      });
    }

    // else
    // {
    //   this.apimicroservice.getAllDoctorsListAvailable(branchRef,clinicRef).subscribe(res => {
    //     if (res.status) {
    //       this.doctorList = res.data;
    //       this.filteredDoctorList= this.doctorList.slice();
    //     } else {
    //     }
    //     });
    // }
  }

  getCalender() {
    this.apimicroservice.getAppointGenericAllData().subscribe((res) => {
      ////

      if (res.data.length > 0) {
        this.fieldsOfCalenders = res.data[0];
      } else {
        this.fieldsOfCalenders = this.arrayCalender;
      }

      // ////",res.data);
      if (res.status) {
        // this.apimicroservice.openSnackbar(res.message);
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    });
  }

  draganddropbranch(id, appoinmentTime, slot) {
    // let payload = this.appointmentPopUp.value;
    this.appointList = [];
    this.searcheddoctor = false;
    this.doctorRefId = id;
    this.doctorID = id;
    let bar = new Promise<void>((resolve, reject) => {
      this.apimicroservice.getBranchById(this.branchRef).subscribe((res) => {
        this.weeklyOff = res.data[0].branchWeeklyOff;
        resolve();
      });
    });

    bar.then(() => {
      let appDate2 = this.appointmentPopUp.controls["startDateTime"].value;
      appDate2 = new Date(appDate2);
      let datesarr2 = [];
      if (this.branchHolidayList.length > 0) {
        this.branchHolidayList.forEach((ele) => {
          if (ele.branchRefId == this.branchRef) {
            datesarr2.push({
              startDate: ele.fromDate,
              endDate: ele.toDate,
              holidayName: ele.holidayName,
            });
          }
        });
        ////
        datesarr2.forEach((ele) => {
          let st = new Date(ele.startDate);
          let et = new Date(ele.endDate);
          let tomorrow = moment(et).add(1, "days");
          let holiday = ele.holidayName;
          ////
          ////
          if (appDate2.getTime() >= st.getTime() && appDate2 < tomorrow) {
            // this.openSnackbar("On occusion of "+holiday+", "+"Clinic is Close From"+st.toDateString()+" to "+et.toDateString());
            this.unavailable = true;
            if (st.toDateString() !== et.toDateString()) {
              this.branchmsg =
                "On occusion of " +
                holiday +
                ", " +
                "clinic will be closed from " +
                st.toDateString() +
                " to " +
                et.toDateString();
            } else {
              this.branchmsg =
                "On occusion of " +
                holiday +
                ", " +
                "clinic will be closed on " +
                st.toDateString();
            }
            ////
            // this.isSave = true;
            this.ngOnInit();
          }
        });
        // this.branchHolidayList.forEach(ele =>{
        //   if(ele.branchRefId == id){
        //    found = "true";
        //    holidayName = ele.holidayName;
        //    startDt = new Date(ele.fromDate);
        //    endDate = new Date(ele.toDate);
        //   }
        // });
      }
      // else{
      ////

      let appDt2 = this.appointmentPopUp.controls["startDateTime"].value;
      appDt2 = new Date(appDt2);
      ////
      let dayNo = 0;
      this.dayArray.forEach((ele, i) => {
        if (ele.value == this.weeklyOff) {
          dayNo = ele.id;
          ////
        }
      });
      let startDate = moment(appDt2);
      let dateeee = startDate.clone(),
        isWeekend = false;
   

      if (dateeee.isoWeekday() == dayNo) {
        isWeekend = true;
      }
      if (isWeekend) {
        //appDate = dd;
        //alert('cant add appointment - branch weekly off!');
        // //
        // this.openSnackbar('Cant add appointment - branch weekly off!');
        this.unavailable = true;
        this.branchmsg = "Branch weekly off!";
        ////
        this.ngOnInit();
        //  return false;
      }
      //}
    });

    let docunavilable = "false";
    this.branchmsg = "";
    let day = this.appointmentPopUp.value.startDateTime;
    day = new Date(day).toISOString();
    let hour = new Date(slot).getHours();
    let minutes = new Date(slot).getMinutes();
    let min = "";
    let hhu = "";
    if (minutes == 0) {
      min = "00";
    } else {
      min = minutes.toString();
    }
    if (hour < 10) {
      hhu = "0" + hour;
    } else {
      hhu = hour.toString();
    }
    let slotraw = hhu + ":" + min;
    var hourEnd = slotraw.indexOf(":");
    var H = +slotraw.substr(0, hourEnd);
    var h = H % 12 || 12;
    var x = h < 10 ? "0" + h : h;
    var ampm = H < 12 ? " AM" : " PM";
    slotraw = x + slotraw.substr(hourEnd, 3) + ampm;
    var ts = slotraw;

    let datesarr = [];
    let appDate = this.appointmentPopUp.controls["startDateTime"].value;
    appDate = new Date(appDate);
    this.apimicroservice.getDoctorLeaveDataByRefId(id).subscribe((res) => {
      if (res.status) {
        ////
        this.doctorLeavesList = res.data;

        if (this.doctorLeavesList.length > 0) {
          this.doctorLeavesList.forEach((ele) => {
            datesarr.push({ startDate: ele.fromDate, endDate: ele.toDate });
          });

          datesarr.forEach((ele) => {
            let st = new Date(ele.startDate);
            let et = new Date(ele.endDate);
            let tomorrow = moment(et).add(1, "days");
            if (appDate.getTime() >= st.getTime() && appDate < tomorrow) {
              this.openSnackbar(
                "Audiologists is on leave From - " +
                  st.toDateString() +
                  " to - " +
                  et.toDateString()
              );
              this.timeLabels = [];
              docunavilable = "true";
              this.ngOnInit();
              //  return;
              // this.isSave = true;
            }
          });
          if (docunavilable == "false") {
            // this.isSave = false;
            this.apimicroservice
              .getDoctorAppointAvailable(
                this.branchRef,
                this.clinicRef,
                this.doctorRefId
              )
              .subscribe((res) => {
                let appointDay = moment(day).format("dddd");
                let drInfo = res.data[0];
                drInfo.dayInfo.forEach((element) => {
                  // //
                  if (element.day == appointDay) {
                    if(this.halfDay == 'Half Day' && this.isHalfDay == true)
                    {
                   this.timelineLabels(
                        this.halfdayFromTime,
                        this.durationValue,
                        "minutes",
                        this.halfdayToTime
                      );
                    }
                    else
                    {
                      this.timelineLabels(
                        element.morningFrom,
                        this.durationValue,
                        "minutes",
                        element.morningTo
                      );
                      this.timelineLabels(
                        element.afternoonFrom,
                        this.durationValue,
                        "minutes",
                        element.afternoonTo
                      );
                      this.timelineLabels(
                        element.eveningFrom,
                        this.durationValue,
                        "minutes",
                        element.eveningTo
                      );
                    }
                   
                  }
                });
                this.apimicroservice
                  .getAppointmentbydatedoctor(day, this.doctorRefId)
                  .subscribe((res) => {
                    this.appointList = res.data;
                    if (this.appointList.length > 0) {
                      for (let i = 0; i < this.timeLabels.length; i++) {
                        // const now = Date.now();
                        // this.myFormattedDate = this.pipe.transform(now, 'shortTime');
                        // //
                        // //
                        // if(this.timeLabels[i].slot > this.myFormattedDate) {

                        //  this.isDisabled = true;
                        //  //

                        //  //
                        //  //

                        // }
                        for (let j = 0; j < this.appointList.length; j++) {
                          if (
                            this.timeLabels[i].slot ===
                            this.appointList[j].appoinmentTime
                          ) {
                            this.timeLabels[i].status = "booked";
                          }
                        } 
                      }
                      if (this.timeLabels.length > 0) {
                        this.timeLabels.forEach((element) => {
                          // const now = Date.now();
                          // this.myFormattedDate = this.pipe.transform(now, 'shortTime');
                          // //
                          // //
                          // if(element.slot > this.myFormattedDate) {

                          //  this.isDisabled = true;
                          //  //

                          //  //
                          //  //

                          // }
                          if (
                            element.slot == ts &&
                            element.status == "unbooked"
                          ) {
                            this.onSelectTime(ts);
                            this.update();
                            return;
                          }
                          if (
                            element.slot == ts &&
                            element.status == "booked"
                          ) {
                            this.apimicroservice.openSnackbar(
                              "Slots are already Booked"
                            );
                            return;
                          }
                          if (element.slot != ts) {
                            this.apimicroservice.openSnackbar(
                              "Audiologists Un-Available"
                            );
                          }
                        });
                        this.ngOnInit();
                      } else {
                        this.apimicroservice.openSnackbar(
                          "Audiologists Un-Available"
                        );
                        this.ngOnInit();
                      }
                    } else {
                      if (this.timeLabels.length > 0) {
                        this.timeLabels.forEach((element) => {
                          if (
                            element.slot == ts &&
                            element.status == "unbooked"
                          ) {
                            //   const now = Date.now();
                            // this.myFormattedDate = this.pipe.transform(now, 'shortTime');
                            // //
                            // //
                            // if(element.slot > this.myFormattedDate) {

                            //  this.isDisabled = true;
                            //  //

                            //  //
                            //  //

                            // }
                            this.onSelectTime(ts);
                            this.update();
                            return;
                          }
                          if (
                            element.slot == ts &&
                            element.status == "booked"
                          ) {
                            this.openSnackbar("Slots are already Booked");
                            return;
                          }
                          if (element.slot != ts) {
                            this.openSnackbar("Audiologists Un-Available");
                          }
                        });
                        this.ngOnInit();
                      } else {
                        this.openSnackbar("Audiologists Un-Available");
                        this.ngOnInit();
                      }
                    }
                  });
              });
          }
        } else {
          // this.isSave = false;
          this.apimicroservice
            .getDoctorAppointAvailable(
              this.branchRef,
              this.clinicRef,
              this.doctorRefId
            )
            .subscribe((res) => {
              let appointDay = moment(day).format("dddd");
              let drInfo = res.data[0];
              drInfo.dayInfo.forEach((element) => {
                // //
                if (element.day == appointDay) {
                  this.timelineLabels(
                    element.morningFrom,
                    this.durationValue,
                    "minutes",
                    element.morningTo
                  );
                  this.timelineLabels(
                    element.afternoonFrom,
                    this.durationValue,
                    "minutes",
                    element.afternoonTo
                  );
                  this.timelineLabels(
                    element.eveningFrom,
                    this.durationValue,
                    "minutes",
                    element.eveningTo
                  );
                }
              });
              this.apimicroservice
                .getAppointmentbydatedoctor(day, this.doctorRefId)
                .subscribe((res) => {
                  this.appointList = res.data;
                  if (this.appointList.length > 0) {
                    for (let i = 0; i < this.timeLabels.length; i++) {
                      // const now = Date.now();
                      // this.myFormattedDate = this.pipe.transform(now, 'shortTime');
                      // //
                      // //
                      // if(this.timeLabels[i].slot > this.myFormattedDate) {

                      //  this.isDisabled = true;
                      //  //

                      //  //
                      //  //

                      // }
                      for (let j = 0; j < this.appointList.length; j++) {
                        if (
                          this.timeLabels[i].slot ===
                          this.appointList[j].appoinmentTime
                        ) {
                          this.timeLabels[i].status = "booked";
                        }
                      }
                    }
                    if (this.timeLabels.length > 0) {
                      this.timeLabels.forEach((element) => {
                        // const now = Date.now();
                        // this.myFormattedDate = this.pipe.transform(now, 'shortTime');
                        // //
                        // //
                        // if(element.slot > this.myFormattedDate) {

                        //  this.isDisabled = true;
                        //  //

                        //  //
                        //  //

                        // }
                        if (
                          element.slot == ts &&
                          element.status == "unbooked"
                        ) {
                          this.onSelectTime(ts);
                          this.update();
                          return;
                        }
                        if (element.slot == ts && element.status == "booked") {
                          this.apimicroservice.openSnackbar(
                            "Slots are already Booked"
                          );
                          return;
                        }
                        if (element.slot != ts) {
                          this.apimicroservice.openSnackbar(
                            "Audiologists Un-Available"
                          );
                        }
                      });
                      this.ngOnInit();
                    } else {
                      this.apimicroservice.openSnackbar(
                        "Audiologists Un-Available"
                      );
                      this.ngOnInit();
                    }
                  } else {
                    if (this.timeLabels.length > 0) {
                      this.timeLabels.forEach((element) => {
                        if (
                          element.slot == ts &&
                          element.status == "unbooked"
                        ) {
                          //   const now = Date.now();
                          // this.myFormattedDate = this.pipe.transform(now, 'shortTime');
                          // //
                          // //
                          // if(element.slot > this.myFormattedDate) {

                          //  this.isDisabled = true;
                          //  //

                          //  //
                          //  //

                          // }
                          this.onSelectTime(ts);
                          this.update();
                          return;
                        }
                        if (element.slot == ts && element.status == "booked") {
                          this.openSnackbar("Slots are already Booked");
                          return;
                        }
                        if (element.slot != ts) {
                          this.openSnackbar("Audiologists Un-Available");
                        }
                      });
                      this.ngOnInit();
                    } else {
                      this.openSnackbar("Audiologists Un-Available");
                      this.ngOnInit();
                    }
                  }
                });
            });
        }
      }
    });

    // this.fetchDoctorLeaves(id);

    // this.apimicroservice.getDoctorAppointAvailable(this.branchRef,this.clinicRef ,this.doctorRefId).subscribe(res => {
    //   let appointDay = moment(day).format('dddd')
    //   let drInfo = res.data[0];
    //   drInfo.dayInfo.forEach(element =>{
    //     // //
    //     if (element.day == appointDay ) {
    //       this.timelineLabels(element.morningFrom,this.durationValue,'minutes',element.morningTo);
    //       this.timelineLabels(element.afternoonFrom,this.durationValue,'minutes',element.afternoonTo);
    //       this.timelineLabels(element.eveningFrom,this.durationValue,'minutes',element.eveningTo);
    //       }
    //   })
    //   this.apimicroservice.getAppointmentbydatedoctor(day,this.doctorRefId).subscribe(res => {
    //     this.appointList = res.data;
    //       if(this.appointList.length > 0){
    //         for(let i=0;i<this.timeLabels.length;i++){
    //           // const now = Date.now();
    //           // this.myFormattedDate = this.pipe.transform(now, 'shortTime');
    //           // //
    //           // //
    //           // if(this.timeLabels[i].slot > this.myFormattedDate) {

    //           //  this.isDisabled = true;
    //           //  //

    //           //  //
    //           //  //

    //           // }
    //           for(let j=0;j<this.appointList.length;j++){
    //             if(this.timeLabels[i].slot === this.appointList[j].appoinmentTime){
    //               this.timeLabels[i].status = "booked";
    //             }
    //           }
    //         }
    //         if(this.timeLabels.length > 0){

    //           this.timeLabels.forEach(element => {
    //             // const now = Date.now();
    //             // this.myFormattedDate = this.pipe.transform(now, 'shortTime');
    //             // //
    //             // //
    //             // if(element.slot > this.myFormattedDate) {

    //             //  this.isDisabled = true;
    //             //  //

    //             //  //
    //             //  //

    //             // }
    //           if(element.slot == ts && element.status == 'unbooked'){
    //             this.onSelectTime(ts);
    //                this.update();
    //              return ;
    //           }
    //          if(element.slot == ts && element.status == 'booked'){
    //             this.apimicroservice.openSnackbar("Slots are already Booked");
    //             return ;
    //           }
    //           if(element.slot != ts ){
    //             this.apimicroservice.openSnackbar("Audiologists Un-Available");
    //           }
    //           });
    //           this.ngOnInit();
    //         }else{
    //            this.apimicroservice.openSnackbar("Audiologists Un-Available");
    //            this.ngOnInit();
    //         }
    //       }else{
    //         if(this.timeLabels.length > 0){
    //           this.timeLabels.forEach(element => {
    //           if(element.slot == ts && element.status == 'unbooked'){
    //             //   const now = Date.now();
    //             // this.myFormattedDate = this.pipe.transform(now, 'shortTime');
    //             // //
    //             // //
    //             // if(element.slot > this.myFormattedDate) {

    //             //  this.isDisabled = true;
    //             //  //

    //             //  //
    //             //  //

    //             // }
    //             this.onSelectTime(ts);
    //                this.update();
    //             return ;
    //           }
    //          if(element.slot == ts && element.status == 'booked'){
    //             this.openSnackbar("Slots are already Booked");
    //             return ;
    //           }
    //           if(element.slot != ts ){
    //             this.openSnackbar("Audiologists Un-Available");
    //           }
    //           });
    //           this.ngOnInit();
    //         }else{
    //            this.openSnackbar("Audiologists Un-Available");
    //            this.ngOnInit();
    //         }
    //       }
    //   });
    // });
  }
  openPatientPopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpPatientComponent, {
      width: "1200px",
    });
    dialogRef3.afterClosed().subscribe((result) => {
      let clinicRef= sessionStorage.getItem('clinicRef');
      let branchRef= sessionStorage.getItem('branchRef');
      let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
      let branchRefSuperAdmin= '';
      if (this.userType !== 'Super Admin') {
      this.apimicroservice.getPatientListAll(branchRef,clinicRef).subscribe((res) => {
        if (res.status) {
          // //
          this.patientlist = res.data.data;
          this.filteredPatientList = this.patientlist.slice();
     
          this.selectPatient(
            res.data.data[0]._id, 
            res.data.data[0].firstName,
            res.data.data[0].lastName,
            res.data.data[0].patientId
          );
          let middleName;
          if(res.data.data[0].middleName == null)
          {
            middleName = '';
          }
          else{
            middleName = res.data.data[0].middleName;
          }
          this.appointmentPopUp.get("clientName").setValue(res.data.data[0].firstName + ' ' + middleName + ' ' + res.data.data[0].lastName);

          // this.appointmentPopUp
          //   .get("clientName")
          //   .setValue(res.data.data[0].firstName);
        }
      });
    }else{
      this.apimicroservice.getPatientListAll(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe((res) => {
        if (res.status) {
          // //
          this.patientlist = res.data.data;
          this.filteredPatientList = this.patientlist.slice();
     
          this.selectPatient(
            res.data.data[0]._id, 
            res.data.data[0].firstName,
            res.data.data[0].lastName,
            res.data.data[0].patientId
          );
          let middleName;
          if(res.data.data[0].middleName == null)
          {
            middleName = '';
          }
          else{
            middleName = res.data.data[0].middleName;
          }
          this.appointmentPopUp.get("clientName").setValue(res.data.data[0].firstName + ' ' + middleName + ' ' + res.data.data[0].lastName);

          // this.appointmentPopUp
          //   .get("clientName")
          //   .setValue(res.data.data[0].firstName);
        }
      });
    }
    });
  }
  //  openBranchPopUp() {
  //   localStorage.clear();
  //   const dialogRef3 = this.dialog.open(PopUpBranchComponent,
  //     {
  //       width: "1200px",
  //       height: "570px"
  //     });
  // }
  openDoctorPopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpDoctorComponent, {
      width: "1200px",
    });
    dialogRef3.afterClosed().subscribe((result) => {
      this.fetchDoctorList();
      ////
      if(result !== undefined){
        this.popUpClose(); 
      }
      
    });
  } 
  openAppointmentTypePopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpAppointmenttypeComponent, {
      width: "700px",
    });

    dialogRef3.afterClosed().subscribe((result) => {
      this.fetchAppointmentTypeList();
    });
  }
  controlClick() {
    sessionStorage.setItem("controlClick", "calander");
  }

  openSnackbar(message: string) {
    return this._snackBar.open(message, "close", {
      verticalPosition: "top",
      horizontalPosition: "right",
      duration: 4500,
      panelClass: ["snackbarStyle"],
    });
  }

  WhatsApp() {
    let payload = this.appointmentPopUp.value;
    let num = payload.contactNumber;
    if (num.length == 10) {
      sessionStorage.setItem("whatsAppNumber", payload.contactNumber);
      let mobileNumber = sessionStorage.getItem("whatsAppNumber");
      window.open("https://wa.me/91" + mobileNumber);
    } else {
      this.appointmentPopUp.get("contactNumber").markAsTouched();
    }
  }
  onback() {
    this.apimicroservice.back();
  }

  openBookAppointmentPopup() {
    // this.appointmentInfo = this.paientdetails;
    // let followDate = this.checkupDetailsForm.get('followupDate').value;
    //  this.appointmentInfo.followupDate = followDate;
    //  //
    const dialogRef3 = this.dialog.open(PopupBookappointmentComponent, {
      width: "500px",
      data: {
        patientInfo: "",
      },
    });

    dialogRef3.afterClosed().subscribe((result) => {
      let msg = "";
      if (result !== undefined) {
        msg = result.data;
      }

      if (msg == "saved") {
        this.apimicroservice.openSnackbar("Follow-Up Appointment is Booked");
      }
    });
  }
 
  openDepartmentPopUp() {
    const dialogRef3 = this.dialog.open(PatientSearchPopComponent, {
      width: "97%",
      height: "90%", 
    });

    dialogRef3.afterClosed().subscribe((result) => {
      let middleName;
      if(result.data.middleName == null)
      {
        middleName = '';
      }
      else{
        middleName = result.data.middleName;
      }
      this.appointmentPopUp.get("clientName").setValue(result.data.firstName + ' ' + middleName + ' ' + result.data.lastName);
    //  this.appointmentPopUp.controls["AppoinmentType"].touched;
      this.nameField.focus();
      this.selectPatient(
        result.data._id,
        result.data.firstName,
        result.data.lastName,
        result.data.patientId
      ); 
    //  this.appointmentPopUp.controls["AppoinmentType"].touched;
      this.nameField.focus();
    });
  }
  popUpClose()
  {
    this.dialogRef.close();
  }
}