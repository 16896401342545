import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
 import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import { 
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { AfterViewInit, Directive, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { PopUpBranchComponent } from '../pop-up-branch/pop-up-branch.component';
import { PopUpRoleComponent } from '../pop-up-role/pop-up-role.component';
import { PopUpDoctorComponent } from '../pop-up-doctor/pop-up-doctor.component';
import { PopUpClinicalStaffComponent } from '../pop-up-clinical-staff/pop-up-clinical-staff.component';
import { ChangePasswordComponent } from '../change-password/change-password.component';
@Component({
  selector: 'app-users-master',
  templateUrl: './users-master.component.html',
  styleUrls: ['./users-master.component.scss']
})
export class UsersMasterComponent implements OnInit {
  @Output() next = new EventEmitter<void>();
    @Output() enable = new EventEmitter<void>();
  errormessage: string;
  searchText: any;
  userlist: any;
  totalLength: any;
  id: any;
  rolesList: any;
  BranchList: any;
  clinicRef: string;
  patientImage: any;
  filteredListBranch: any;
  filteredListRole: any;
  filteredListDoctor: any;
  filteredListClinicalStaff: any;
  doctorList: any;
  clinicalStaffList: any;
  roleCreate=true;
  branchCreate=true;
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
     public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService
  ) {}
  usersMasterForm: FormGroup;
  status: any;
  submitButton = true;
  updateButton = false;
  deleteButton = false;
  cancelButton=false;
  loggedId: any;
  myimage;
  date: any;
  size:number = 10;
  page2:number = 1;
  isPassword= true;
  //permission code
  @Output() prev = new EventEmitter<void>();
  user_role:any;
  permissionForm: FormGroup;
  customerType:any;
  tab = true;
  page= true;
  view= false;
  listofCustomer:any;
  listofuser:any;
  dashboard_view:any;
  Tab1 = false;
 Tab2 = false;
  Tab3 = false;
  Tab4 = false;
  CreateTab1= false;
  CreateTab2= false;
  CreateTab3= false;
  CreateTab4= false;
  UpdateTab1= false;
  UpdateTab2= false;
  UpdateTab3= false;
  UpdateTab4= false;
  DeleteTab1= false;
  DeleteTab2= false;
  DeleteTab3= false;
  DeleteTab4= false;
  CreateTab= true;
  permission :any = [];
  user : any = [];
  selectedItemsList: any;
  listofcustuser: any;
  userSelectList:any = [];
  allPages:any = [];
  tempArray:any = [];
  customerRefId:any;
  userData: any;
  listOfRole: any;
  checkCust:Boolean = true;
  checkUser:Boolean = true;
  disableField:Boolean = false;
  tabenable: boolean = false;
  usertabenable: boolean =false;
  userPermission:any;
  userType:any;
  custRefId:any;
  permissionList=[];
  permissionCreate = true;
  permissionUpdate = true;
  isDoctor = false;
  isclinicalStaff = false;
  isOther = false;
  disableBranch = true;
  ngOnInit() {
    this.page2=1;
    ///permission code
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.checkCust = true;
    this.checkUser = true;
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    this.userType = sessionStorage.getItem('userType');
    this.custRefId = sessionStorage.getItem('custRefId');
    if(this.userType !== 'Super Admin'){
    this.userPermission.forEach((element) => {  
      if(element.pageName == 'User' && element.create == false)
      {
          this.permissionCreate = false;  
      }
      if(element.pageName == 'User' && element.update == false)
      {
          this.permissionUpdate = false;  
      }
      if(element.pageName == 'Role' && element.create == false)
      {
          this.roleCreate = false;  
      }
      if(element.pageName == 'Branch' && element.create == false)
      {
          this.branchCreate = false;  
      }
    });
  }
    // this.allPages = [];
    //
    // for(let i=0;i<this.router.config.length;i++){
    //   let routePath = this.router.config[i].data;

      
    //   if(routePath == undefined){

    //   }else{
    //   // this.allPages.push({pageName:routePath.name,view:false,create:false,update:false,delete:false,isTab:routePath.isTab,id:i,isUserTab:routePath.isUserTab});
      
    //   }
    // }
    // this.tempArray = this.allPages;
    //permission code end
    this.date = new Date();
    this.usersMasterForm = this.fb.group({
      "userType": new FormControl('',Validators.required),
      // "userID": new FormControl('',[Validators.required,Validators.pattern("^[a-zA-Z]+$|\\b.*[a-zA-Z]+.*\\b")]),//alphanumeric validation regex
      "userID": new FormControl('',[Validators.required,Validators.pattern("^[a-zA-Z]*$|^[A-Za-z]\\w*$"),Validators.maxLength(25),Validators.minLength(5)]),
      "userName": new FormControl('',[Validators.required,Validators.pattern("^[a-zA-Z-\\s]*$")]),
      "emailId": new FormControl('',[Validators.required,Validators.pattern("[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}")]),
      // "password": new FormControl('', [Validators.required,
      //   Validators.pattern('^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,20}$')]),
      "status": new FormControl('',Validators.required),
      "roleRefId": new FormControl('',Validators.required),
      "branchRefId": new FormControl('',Validators.required),
      "createdAt": this.date,
      "searchUser": new FormControl(''),
      "clinicRefId": new FormControl(),
      //permission code
      "Page": new FormControl(true, [Validators.required]),
      "Tab": new FormControl(''),
      "View": new FormControl(''),
      "userImage": '',
      "doctorRefId": new FormControl(),
      "clinicalStaffRefId": new FormControl(),
      //"doctorName": new FormControl(),
      //"clinicalStaffName": new FormControl(),
    });
    this.patientImage ="../../assets/user2.png";
    //
    this.usersMasterForm.controls["status"].setValue("Active");
    this.fetchUserList();
    this.fetchRoleList();
    this.fetchBranchList();
    this.fetchDoctorList();
    this.fetchClinicalStaffList();
    this.getAllBranch();
  }
  getAllBranch()
  {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      if (res.status) {        
        length = res.data.length - 1;
        //
        this.usersMasterForm.get("branchRefId").setValue(res.data[length]._id);
      }
    });
  }
  
  submitUser() {
    if (this.usersMasterForm.invalid) {
      this.usersMasterForm.get("userType").markAsTouched();
      this.usersMasterForm.get("userID").markAsTouched();
      this.usersMasterForm.get("userName").markAsTouched();
      this.usersMasterForm.get("emailId").markAsTouched();
      // this.usersMasterForm.get("password").markAsTouched();
      this.usersMasterForm.get("status").markAsTouched();
      this.usersMasterForm.get("roleRefId").markAsTouched();
      this.usersMasterForm.get("branchRefId").markAsTouched();

    
      return;
    } else {
      this.spinner.show();
      this.date = new Date();
      let payload = this.usersMasterForm.value;
      payload.userImage = this.patientImage;
      payload.clinicRefId = this.clinicRef;   
      //

      if(payload.userType == 'Doctor')
        {
          this.doctorSelect();
        }
        else if(payload.userType == 'Clinical Staff')
        {
          this.clinicalStaffSelect();
        }
        else if(payload.userType == 'Other')
        {
          this.otherSelect();
        }
    //   this.apimicroservice.addUser(payload).subscribe((res) => {
    //     this.spinner.hide();
    //     if (res.status) {
    //       this.apimicroservice.openSnackbar(res.message);
    //       this.patientImage = "../../assets/main.jpeg";
    //       this.ngOnInit();
    //     } else {
    //     }
    //   },(ERROR:HttpErrorResponse) => {
    //      this.apimicroservice.openSnackbar(ERROR.error.message);
    //     this.spinner.hide();
    // });
    localStorage.removeItem('userId');
    sessionStorage.setItem("userpayload",JSON.stringify(payload));
    this.next.emit();
    this.spinner.hide(); 
    }
  }
  updateUser() {
    if (this.usersMasterForm.invalid) {
      this.usersMasterForm.get("userName").markAsTouched();
      this.usersMasterForm.get("userID").markAsTouched();
      this.usersMasterForm.get("userType").markAsTouched();
      this.usersMasterForm.get("emailId").markAsTouched();
      // this.usersMasterForm.get("password").markAsTouched();
      this.usersMasterForm.get("status").markAsTouched();
      this.usersMasterForm.get("roleRefId").markAsTouched();
      this.usersMasterForm.get("branchRefId").markAsTouched();
    
      return;
    } else {
      let payload = this.usersMasterForm.value;
    //
    
   // payload.userPermission = this.allPages;

       payload.clinicRefId = this.clinicRef;    
    
     payload.userImage = this.patientImage;
     if(payload.emailId !== 'superadmin@audiology.com')
     {
      this.spinner.show(); 
      this.apimicroservice.updateUser(this.id,payload).subscribe(res => {
        this.spinner.hide();
        this.updateButton = false;
        this.submitButton = true;
        this.cancelButton=false;  
        if(res.status){
          this.apimicroservice.openSnackbar(res.message);
          this.patientImage = "../../assets/user2.png";
          this.ngOnInit();
        }else{
          this.apimicroservice.openSnackbar(res.message);
          this.updateButton = true;
          this.submitButton = false;
          this.cancelButton=true; 
        }
      });
     }
     else
     {
      this.apimicroservice.openSnackbar("Update Functionality Is Not For Super Admin");
      this.usersMasterForm.controls["emailId"].setValue('');
     }
   

    }
  }

  openSnackbar(message: string) {
    return this._snackBar.open(message, "close", {
      verticalPosition: "bottom",
      horizontalPosition: "right",
      duration: 2000,
      panelClass: ["snackbarStyle"],
    });
  }
  

  fetchUserList() {
    this.spinner.show();
    this.searchText = '';   
    let pg = 1;
    let size = 10;
    let clinicRef= sessionStorage.getItem('clinicRef');
    if(this.userType !== 'Super Admin')
     {
    this.apimicroservice.getAllUser(pg,size,this.searchText,this.clinicRef).subscribe(res => {
      this.spinner.hide();
       this.userlist = res.data.userData;
       this.totalLength = res.data.totalItems;     
      if(res.status){
    //this.apimicroservice.openSnackbar(res.message);
      }else{
        //this.apimicroservice.openSnackbar(res.message);
      }

    }); 
  }
  else
  {
    this.apimicroservice.getAllUser(pg,size,this.searchText,clinicRef).subscribe(res => {
      this.spinner.hide();
       this.userlist = res.data.userData;
       this.totalLength = res.data.totalItems;     
      if(res.status){
    //this.apimicroservice.openSnackbar(res.message);
      }else{
        //this.apimicroservice.openSnackbar(res.message);
      }

    }); 
  }
  }

  prepopulation(id) {
    this.spinner.show();
    this.isPassword = false;
    this.apimicroservice.getUserById(id).subscribe(res => {
      this.spinner.hide();
      this.userData = res.data[0];
      let userId = res.data[0]._id;
      localStorage.setItem('userId',userId);
     // this.allPages = res.data[0].userPermission;
    //   if(this.allPages.length !== 0 ){
    //   for(let i=0;i<this.allPages.length;i++){
       
    //     if(this.allPages[i].pageName == "Masters"){
    //        if(this.allPages[i].view == true){
    //       this.tabenable = true;
    //        }else{
    //         this.tabenable = false;
    //        }
    //     }
    //   }
    // }
      // if(this.allPages.length == 0){
      //   this.allPages = this.tempArray;
      //   for(let i=0;i<this.tempArray.length;i++){
       
      //     if(this.tempArray[i].pageName == "Masters"){
      //        if(this.tempArray[i].view == true){
      //       this.tabenable = true;
      //        }else{
      //         this.tabenable = false;
      //        }
      //     }
      //   }
      // }
      //end permission code
      //
     let data = res.data[0];
     this.submitButton = false;
     this.updateButton = true;
     this.cancelButton=true;
     this.enable.emit();
   //  this.fetchDoctorList();
    // this.fetchClinicalStaffList(); 
      if(res.status){
        this.usersMasterForm = this.fb.group({
          "userType": new FormControl(data.userType,Validators.required),
          "userID": new FormControl(data.userID,[Validators.required,Validators.pattern("^[a-zA-Z]*$|^[A-Za-z]\\w*$"),Validators.maxLength(25),Validators.minLength(5)]),
          "userName": new FormControl(data.userName,[Validators.required,Validators.pattern("^[a-zA-Z-\\s]*$")]),
          "emailId": new FormControl(data.emailId,[Validators.required,Validators.pattern("[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}")]),
          // "password": new FormControl(data.password),
          "status": new FormControl(data.status,Validators.required),
          "roleRefId": new FormControl(data.roleRefId,Validators.required),
          "branchRefId": new FormControl(data.branchRefId,Validators.required),
          "clinicRefId": new FormControl(data.clinicRefId),
           "updatedAt": this.date,
           "userImage": '',
           "doctorRefId": new FormControl(data.doctorRefId),
           "clinicalStaffRefId": new FormControl(data.clinicalStaffRefId),
          // "doctorName": new FormControl(data.doctorName),
          // "clinicalStaffName": new FormControl(data.clinicalStaffName),
      // "deviceId": id
        });
        this.id = id;
        //this.usersMasterForm.controls['branchRefId'].setValue(data.branchRefId);
        this.patientImage = data.userImage ? data.userImage : "../../assets/user2.png"
        if(data.userType == 'Doctor')
        {
          this.doctorSelect()
        }
        else if(data.userType == 'Clinical Staff')
        {
          this.clinicalStaffSelect()
        }
        else if(data.userType == 'Other')
        {
          this.otherSelect()
        }
     
      }
    });
  }
  addNew() {
    this.usersMasterForm.reset();
    this.updateButton = false;
    this.deleteButton = false;
  }
  onPageChange(event) {
    this.page2 = event;
 
    this.searchText = this.usersMasterForm.get("searchUser").value;
    let clinicRef= sessionStorage.getItem('clinicRef');
    if(this.userType !== 'Super Admin')
    {
    this.apimicroservice.getAllUser(this.page2,this.size,this.searchText,this.clinicRef).subscribe(res => {
      if(res.status){
      //
       this.userlist = res.data.userData;
       this.totalLength = res.data.totalItems; 
      } else {
        this.apimicroservice.openSnackbar(res.message)
      }     
        });
      }
      else
      {
        this.apimicroservice.getAllUser(this.page2,this.size,this.searchText,clinicRef).subscribe(res => {
          if(res.status){
          //
           this.userlist = res.data.userData;
           this.totalLength = res.data.totalItems; 
          } else {
            this.apimicroservice.openSnackbar(res.message)
          }     
            });
      }
  }
  onKeyUpEvent(event: any) {
    this.searchText = this.usersMasterForm.get("searchUser").value;
    let clinicRef= sessionStorage.getItem('clinicRef');
    if(this.userType !== 'Super Admin')
    {
    this.apimicroservice.getAllUser(this.page2,this.size,this.searchText,this.clinicRef).subscribe(res => {
      //
       this.userlist = res.data.userData;
       this.totalLength = res.data.totalItems;      
        });
      }
      else
      {
        this.apimicroservice.getAllUser(this.page2,this.size,this.searchText,clinicRef).subscribe(res => {
          //
           this.userlist = res.data.userData;
           this.totalLength = res.data.totalItems;      
            });
      }
  }
 
  redirect(to): void {
    this.router.navigate([to]);
  }
  
  fetchRoleList() {
    this.searchText = '';
    let clinicRef='';
      this.apimicroservice.getAllRolelist().subscribe(res => {
        //
        if (res.status) {    
          this.rolesList = res.data;
          this.filteredListRole= this.rolesList.slice();
        } else {
        //  this.apimicroservice.openSnackbar(res.message);
        }
      })
  
  }
  fetchBranchList() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      //
      
      if (res.status) {    
        this.BranchList = res.data;
        this.filteredListBranch= this.BranchList.slice();
      } else {
     //   this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
  cancelUpdate(){
    this.patientImage ="../../assets/user2.png";
    this.usersMasterForm.reset();
    this.submitButton=true;
    this.updateButton=false;
    this.cancelButton=false;
    this.ngOnInit();
  }
  onChange($event: Event) {
    const file = ($event.target as HTMLInputElement).files[0];
    const fileName = file.name;
      this.errormessage = '';
      let ext = fileName.substring(fileName.lastIndexOf(".") + 1);
      if (
        ext.toLowerCase() == "png" ||
        ext.toLowerCase() == "jpg" ||
        ext.toLowerCase() == "jpeg"
      ) {
        this.convertToBase64(file);
      }
    
  }
  convertToBase64(file: File) {
    const observable = new Observable((subscriber: Subscriber<any>) => {
      this.readFile(file, subscriber);
    });
    observable.subscribe((d) => {
      this.patientImage = d;
      
    });
  }
  readFile(file: File, subscriber: Subscriber<any>) {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      subscriber.next(fileReader.result);
      subscriber.complete();
    };
    fileReader.onerror = (error) => {
      subscriber.error(error);
      subscriber.complete();
    };
  } 


// validateForm(){
//   let payload = this.usersMasterForm.value;
//   // payload.custRefId = this.customerRefId;
//   payload.userPermission = this.allPages;
//   this.apimicroservice.updateUser(this.id,payload).subscribe(res => {
//     //
//     this.ngOnInit();
//     if(res.status){
//       this.apimicroservice.openSnackbar(res.message);
//       localStorage.clear();
//       this.usersMasterForm.reset();
//     }else{
//       this.apimicroservice.openSnackbar(res.message);
//     }
//   });
  
// }
toUserForm() {
  document.getElementById("toUserForm").scrollIntoView();
}
toUserList(){
  document.getElementById("toUserList").scrollIntoView();
}
openBranchPopUp() {
  localStorage.clear();
  const dialogRef3 = this.dialog.open(PopUpBranchComponent,
    {
      width: "1200px",
    });
    dialogRef3.afterClosed().subscribe((result) => {
      let clinicRef= sessionStorage.getItem('clinicRef');
      this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
        //
        
        if (res.status) {    
          this.BranchList = res.data;
          this.filteredListBranch= this.BranchList.slice();
          this.usersMasterForm.get("branchRefId").setValue(res.data[0]._id);
        } else {
       //   this.apimicroservice.openSnackbar(res.message);
        }
      })
      //this.fetchBranchList();
    })
}
openRolePopUp() {
  localStorage.clear();
  const dialogRef3 = this.dialog.open(PopUpRoleComponent,
    {
       width: "500px",
    }); 
      dialogRef3.afterClosed().subscribe((result) => {
        this.searchText = '';
        let clinicRef='';
          this.apimicroservice.getAllRolelist().subscribe(res => {
            //
            if (res.status) {    
              this.rolesList = res.data;
              this.filteredListRole= this.rolesList.slice();
              this.fetchRoleList();
              this.usersMasterForm.get("roleRefId").setValue(res.data[0]._id)
            } else {
            //  this.apimicroservice.openSnackbar(res.message);
            }
          })
     // this.fetchRoleList();
    })
}
fetchDoctorList() {
    this.apimicroservice.getAllDoctorlist().subscribe(res => {
      //
      if (res.status) {    
        this.doctorList = res.data.data;
        this.filteredListDoctor= this.doctorList.slice(); 
      } else {
      //  this.apimicroservice.openSnackbar(res.message);
      }
    })


}
fetchClinicalStaffList() {
    this.apimicroservice.getAllclinicalStafflist().subscribe(res => {
      //
      if (res.status) {    
        this.clinicalStaffList = res.data.data;
        this.filteredListClinicalStaff= this.clinicalStaffList.slice();
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    })
}
doctorClick(fname,lname,branchId,id)
{
  this.usersMasterForm.controls["userName"].setValue(fname + ' ' + lname);
  this.usersMasterForm.controls["branchRefId"].setValue(branchId);
    this.apimicroservice.getDoctorById(id).subscribe(res => {
      if (res.status) {
        let data=res.data[0];
        this.usersMasterForm.controls["emailId"].setValue(data.email);
      }
    }); 
} 
clinicalClick(staffName,branchId)
{
  this.usersMasterForm.controls["userName"].setValue(staffName);
  this.usersMasterForm.controls["branchRefId"].setValue(branchId);
}
doctorSelect()
{
this.isDoctor = true;
this.isclinicalStaff = false;
this.isOther = false;
  this.usersMasterForm.controls['doctorRefId'].setValidators(Validators.required);
  this.usersMasterForm.controls['doctorRefId'].updateValueAndValidity();

  this.usersMasterForm.controls['clinicalStaffRefId'].setErrors(null);
  this.usersMasterForm.controls['clinicalStaffRefId'].clearValidators();
  this.usersMasterForm.controls['clinicalStaffRefId'].updateValueAndValidity();

  this.usersMasterForm.controls['userName'].setErrors(null);
  this.usersMasterForm.controls['userName'].clearValidators();
  this.usersMasterForm.controls['userName'].updateValueAndValidity();
}

clinicalStaffSelect()
{
  this.isDoctor = false;
  this.isclinicalStaff = true;
  this.isOther = false;
  
  this.usersMasterForm.controls['clinicalStaffRefId'].setValidators(Validators.required);
  this.usersMasterForm.controls['clinicalStaffRefId'].updateValueAndValidity();

  this.usersMasterForm.controls['doctorRefId'].setErrors(null);
  this.usersMasterForm.controls['doctorRefId'].clearValidators();
  this.usersMasterForm.controls['doctorRefId'].updateValueAndValidity();

  this.usersMasterForm.controls['userName'].setErrors(null);
  this.usersMasterForm.controls['userName'].clearValidators();
  this.usersMasterForm.controls['userName'].updateValueAndValidity();
}
otherSelect()
{
  this.isDoctor = false;
  this.isclinicalStaff = false;
    this.isOther = true;
 // this.usersMasterForm.controls["branchRefId"].setValue('');
  
  this.usersMasterForm.controls['userName'].setValidators(Validators.required);
  this.usersMasterForm.controls['userName'].updateValueAndValidity();

  this.usersMasterForm.controls['doctorRefId'].setErrors(null);
  this.usersMasterForm.controls['doctorRefId'].clearValidators();
  this.usersMasterForm.controls['doctorRefId'].updateValueAndValidity();

  this.usersMasterForm.controls['clinicalStaffRefId'].setErrors(null);
  this.usersMasterForm.controls['clinicalStaffRefId'].clearValidators();
  this.usersMasterForm.controls['clinicalStaffRefId'].updateValueAndValidity();
}
openDoctorPopUp() {
  localStorage.clear();
  const dialogRef3 = this.dialog.open(PopUpDoctorComponent,
    {
      width: "1200px",
    });
    dialogRef3.afterClosed().subscribe((result) => {
      // this.apimicroservice.getAllDoctorlist().subscribe(res => {
      //   //
      //   if (res.status) {    
      //     this.doctorList = res.data.data;
      //     this.filteredListDoctor= this.doctorList.slice(); 
      //     this.fetchDoctorList();
      //     this.usersMasterForm.get("doctorRefId").setValue(res.data.data[0]._id)
      //   } else {
      //   //  this.apimicroservice.openSnackbar(res.message);
      //   }
      // })
       this.fetchDoctorList();  
       });
} 
openClinicalStaffPopUp() {
  localStorage.clear();
  const dialogRef3 = this.dialog.open(PopUpClinicalStaffComponent,
    {
      width: "1200px",
    });
    dialogRef3.afterClosed().subscribe((result) => {
      this.apimicroservice.getAllclinicalStafflist().subscribe(res => {
        //
        if (res.status) {    
          this.clinicalStaffList = res.data.data;
          this.filteredListClinicalStaff= this.clinicalStaffList.slice();
          this.clinicalClick(res.data.data[0].staffMemberName,res.data.data[0].branchRefId);
          this.usersMasterForm.get("clinicalStaffRefId").setValue(res.data.data[0]._id)
        } else {
          //this.apimicroservice.openSnackbar(res.message);
        }
      })
     // this.fetchClinicalStaffList();  
     }); 
}

onback() {
  this.apimicroservice.back()
}
openChangePasswordPopUp() {
  
  const dialogRef3 = this.dialog.open(ChangePasswordComponent,
    {
      // width: "1200px",
      // height: "570px"
    });
    dialogRef3.afterClosed().subscribe((result) => {
      // this.apimicroservice.getAllDoctorlist().subscribe(res => {
      //   //
      //   if (res.status) {    
      //     this.doctorList = res.data.data;
      //     this.filteredListDoctor= this.doctorList.slice(); 
      //     this.fetchDoctorList();
      //     this.usersMasterForm.get("doctorRefId").setValue(res.data.data[0]._id)
      //   } else {
      //   //  this.apimicroservice.openSnackbar(res.message);
      //   }
      // })
       });
}
} 