          <div class="container-fluid " [formGroup]="repairDashboardForm">
                <form [formGroup]="repairDashboardForm" autocomplete="off" novalidate>
                  <div class="row">
        
                    <div class="col-sm-12 pr-4">
                      <div class="row ">
                        <div class="col-sm-12 pr-4">
                          <div class="row">
                            <div class="card divbody mb-3 boxsssss borderradius p-1">
                              <div class="col-sm-12">
                                <div class="row mt-3"> 
                              <div class="col-sm-8 ">
                                  <h1 class="titlecolor"> List Of Repair</h1>  
                              </div> 
                              <div class="col-sm-1 justify-content-right">
                                <div class="row">
                                    <div class="col-sm-6"></div>
                                    <div class="col-sm-6 justify-content-right">
                                        <div class="icon-btns pb-4">
                                          <a class="icon-box2" data-toggle="tooltip" data-placement="top" matTooltip="Repair"
                                        
                                          (click)="repairClick(branchRefId,patientRef,branchNamee,pName)"> 
                                          <span class="material-symbols-outlined">handyman</span>
                                          <img src="../../assets/icon-bg6.svg" class="icon-bg2">
                                          </a>
                                            </div>
                              </div>
                              </div>
                              </div>
                              <div class="search-box col-sm-3 pb-2">
                                <input class="form-control amber-border" type="text" placeholder="Search" 
                                formControlName="searchRepair" aria-label="Search" (keyup)="onKeyUpEvent($event)"
                                >
                                <div class="input-group-append">
                                   <span class="input-group-text amber lighten-3" id="basic-text1"><span class="material-symbols-outlined">search</span></span>
                                </div>
                             </div>
                           
                              <div class="col-sm-12">
                                <table id="toRepairList" 
                                class="table table-bordered data-table-custom">
                <thead>
                  <tr class="text-center"> 
                    <th>Repair ID</th>
                    <th>Date</th>
              
                 
                    <th *ngIf="userType == 'Super Admin'">Branch Name</th>
                    <th>Left Serial No</th>
                    <th>Right Serial No</th>
                    <th>Left Model </th>
                    <th>Right Model </th>
                    <th>Track Repair </th>
                   
                  </tr>
                </thead>
                <tbody>
   
                  <tr *ngFor="let item of listOfRepair | paginate : { id:'repairpaginate', 
          itemsPerPage: size, currentPage: page, totalItems:totalLength } " 
                  (click)="prepopulation(item._id)" (click)="toRepairForm()"
                    >
                    <td  matTooltip="click to view"
                    >{{item.branchWiseId}}</td>
                    <td matTooltip="click to view">{{item.repairDate | date :'d/M/yy'}}</td>
                    <!-- <td matTooltip="click to view">{{item.firstName}}  {{item.lastName}}</td> -->
                    <!-- <td matTooltip="click to view">{{item.mobile1}}</td> -->
                    <td *ngIf="userType == 'Super Admin'" matTooltip="click to view" class="centerrr"
                    >{{item.branchName}}</td>
                    <td matTooltip="click to view" class="centerrr">{{item.leftSrNo}}</td>
                    <td matTooltip="click to view" class="centerrr">{{item.rightSrNo}}</td>
                    <td matTooltip="click to view" class="centerrr">{{item.leftModel}}</td>
                    <td matTooltip="click to view" class="centerrr">{{item.rightModel}}</td>
                    <td (click)="redirectToRepairForm(item._id)" 
                     matTooltip="click to view" class="repairtb">View Tracking</td> 
                  </tr>
                </tbody>
              </table>
                              </div>
                              <div class="col-sm-12 pt-0 text-right">
                                <pagination-controls id="repairpaginate" directionLinks="true" (pageChange)="onPageChange($event)"
                                class="float-right pt-3  my-pagination"></pagination-controls>
                            </div>
                          
                      </div>
                      </div>
                  </div>
              </div>
              
          </div>
         </div>
         </div>
        </div>
      </form>
  
 
    </div>

  
  
  
  
  
  
  
  