<div class="container-fluid" [formGroup]="appointmentPopUp">
  <div class="row scroll_hide">
     
    
             
              <div class="col-sm-12"> 
                  <div class="row"> 
                  <div class="col-sm-11"> 
                      <h2><b>Add / Edit Appointment</b></h2>
                  </div>
                  <div class="col-sm-1">
                    <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                      <a (click)="popUpClose()" matTooltip="Close" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">close</span></a>
                      </div>
                  </div>
                   
                  <div class="col-sm-12 card pb-3 pt-3">
                      <div class="row"> 
                      <div class="col-sm-6">
                    <div class="row"> 
                      <div class="col-sm-10 pr-1"> 
                       
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Patient Name</mat-label>
                          <input (click)="openDepartmentPopUp();" matInput readonly placeholder="Patient Name" formControlName="clientName" />
                          </mat-form-field>  
                          <mat-error
                          *ngIf="appointmentPopUp.controls['clientName'].touched && appointmentPopUp.controls['clientName'].errors?.required">
                          This field is mandatory.
                        </mat-error> 
                        </div>
                        <div *ngIf="PatientCreate" class="col-sm-2 mt-2 ml-0 pl-0 mTop no-print">
                            <i (click)="openPatientPopUp();" matTooltip="Add New Patient" 
                            class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                        </div>   
                        </div>
                        </div> 
                        <div class="col-sm-6">
                        <div class="row"> 
                        <div class="col-sm-10 pr-1">
                         
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Contact Number</mat-label>
                          <input matInput readonly placeholder="Contact Number" formControlName="contactNumber"  />
                        </mat-form-field>
                        </div>
                        <div class="col-sm-2 ml-0 pl-2 wApp">
                          <i class="bi bi-whatsapp whatsapp-icon wApp fa-lg" (click)="WhatsApp();" matTooltip="WhatsApp Integration" ></i>
                        </div>
                      </div>
                        </div>
                        <div class="col-sm-6">
                        <div class="row"> 
                          <div class="col-sm-10 pr-1">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Appointment Type</mat-label>
                          <mat-select #select1 formControlName="AppoinmentType">
                            <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'appointmentType'"
                            [array]="appointtypelist"  (filteredReturn)="filteredAppointTypeList =$event"></mat-select-filter>
                            <mat-option  *ngFor="let row of filteredAppointTypeList" [value]="row.appointmentType" (click)="dddd(row)">{{row.appointmentType}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <mat-error
                        *ngIf="appointmentPopUp.controls['AppoinmentType'].touched && appointmentPopUp.controls['AppoinmentType'].errors?.required">
                        This field is mandatory.
                      </mat-error>
                        </div>
                          <div *ngIf="AppointmentTypeCreate" class="col-sm-2 mt-2 ml-0 pl-0 mTop no-print">
                            <i (click)="openAppointmentTypePopUp();" matTooltip="Add New Appointment Type" 
                            class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                      
                        </div> 
                        </div>
                        </div>
                        <div class="col-sm-6">
                        <div class="row"> 
                          <div class="col-sm-10 pr-1">
                        <mat-form-field class="example-full-width"  appearance="outline">
                          <mat-label>Appointment Date</mat-label>
                          <input matInput [min]="aptBackDate" [matDatepicker]="startDateTime" placeholder="Choose a date" 
                           formControlName="startDateTime" (dateChange)="toDateChange()">
            
                            <mat-datepicker-toggle matSuffix [for]="startDateTime">
                            </mat-datepicker-toggle>
                            <mat-datepicker #startDateTime></mat-datepicker>
                        </mat-form-field>
                        <mat-error
                        *ngIf="appointmentPopUp.controls['startDateTime'].touched && appointmentPopUp.controls['startDateTime'].errors?.required">
                        This field is mandatory.
                      </mat-error>
                        </div>
                        </div>
                        </div>
                        <div class="col-sm-6" *ngIf="isSuperAdmin">
                        <div class="row">
                          <div class="col-sm-10 pr-1">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Clinic Branch</mat-label>
                          <mat-select #select3 formControlName="clinicLocation">
                            <mat-select-filter *ngIf="select3.focused" [placeholder]="'Filter'" [displayMember]="'branchName'"
                                      [array]="cliniclist"  (filteredReturn)="filteredClinicList =$event"></mat-select-filter>
                            <mat-option  *ngFor="let row of filteredClinicList" (click)="selectBranch(row._id)" [value]="row.branchName">{{row.branchName}}</mat-option>
                          </mat-select> 
                        </mat-form-field>
                        </div>
                      
                        </div>
                        </div>
                        <div class="col-sm-6">
                        <div class="row"> 
                          <div class="col-sm-10 pr-1">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Audiologists Name</mat-label>
                         
                          <mat-select #select4 formControlName="doctorName">
                            <mat-select-filter *ngIf="select4.focused" [placeholder]="'Filter'" [displayMember]="'doctorName'"
                                      [array]="doctorList"  (filteredReturn)="filteredDoctorList =$event"></mat-select-filter>
                            <mat-option (click)="fetchDoctorList()" *ngFor="let row of filteredDoctorList" (click)="selectDoctor(row.doctorRefId)" [value]="row.doctorName">{{ row.doctorName }}</mat-option>
                            
                            </mat-select> 
                        </mat-form-field>
                        </div>
                          <div *ngIf="DoctorCreate" class="col-sm-2 mt-2 ml-0 pl-0 mTop no-print">
                            <i (click)="openDoctorPopUp();" matTooltip="Add New Audiologists" 
                            class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                        </div>  
                        </div>
                       </div>
                   </div>
                       <div class="row m-0 pt-0 pr-0 pl-0  ">
                       
                           <div *ngIf="timeLabels.length == 0 && searcheddoctor">
                             <h2 class="pl-3"> Audiologists Un-available</h2>
                           </div>
                           
                          <div *ngFor="let item of timeLabels" class="mr-1">
                            <button *ngIf="item.status=='unbooked' && item.isDisabled == 'Yes'" (click)="onSelectTime(item.slot)" 
                            id="btnslot" class="col-sm-12 bordercolor p-2 m-1 not-allowed text-center pointer btn btn-default example-full-width"
                            disabled>{{item.slot}}</button>
                            <button *ngIf="item.status=='unbooked' && item.isDisabled == 'No'" (click)="onSelectTime(item.slot)" 
                            id="btnslot" class="col-sm-12 bordercolor p-2 m-1 text-center pointer btn btn-default example-full-width"
                            >{{item.slot}}</button>
                         
                            <button *ngIf="item.status=='booked'" width="100" disabled 
                            class="col-sm-12 bookbordercolor p-2 m-1 text-center not-allowed  btn btn-default example-full-width">{{item.slot}}</button>
                          </div>
                           
                        
                       </div>
                       <div class="row col-sm-12">
                          
                          <div class="col-sm-5">
                          
                            <h2  *ngIf="unavailable" class="pl-3"> {{branchmsg}}</h2>
                          
                        </div>
                        <div class="col-sm-5" *ngIf="savebutton && bookAppointmentsCreate"></div>
                        
                        
                        
                        <div *ngIf="updatebutton && bookAppointmentsUpdate" class="col-sm-2">
                        <button [disabled]="isUpdate" type="button"
                          class="btn btn-primary example-full-width mb-3" (click)="update()"> Update </button>
                        </div>
                        <div *ngIf="updatebutton" class="col-sm-3 widthh ">
                          <button class="btn widthh btn-primary" (click)="functiondelete()"> Cancel Appoinment</button>
                          </div>
                          <div *ngIf="updatebutton" class="col-sm-2 widthh">
                          <button class="btn widthh btn-primary" (click)="reset()"> Clear </button>
                          </div>
                          <div 
                           *ngIf="savebutton && bookAppointmentsCreate" class="col-sm-2 p-0 m-0">
                            <button *ngIf="isSave" type="button" class="btn btn-primary example-full-width" (click)="btnClick()"> Save </button>
                            <button *ngIf="!isSave" type="button" class="btn btn-primary example-full-width" (click)="submit()"> Save </button>
                            </div>
                            
                        
                        </div>
                      
                     
                  </div>
              </div>
         
  </div>
</div>