<div class="wrapper bgcolor2">
    <div class="main-panel">
            <div class="content">
         <div class="margin_top">
        
            <div class="col-sm-12"> 
                <div class="row">
                    <div class="col-sm-1">
                        </div>
                        <div class="col-sm-11 mt-2">
           <mat-tab-group (selectedTabChange)="onTabChanged($event)" class="mat-tab-labels" [selectedIndex]="selectedIndex">
          
           
             <!-- <mat-tab label="Clinic Holiday">
              <app-holiday-leave (prev)="prev()" (enable)="enable()"></app-holiday-leave>
            </mat-tab> -->
            <mat-tab label="Clinic Holiday">
                <app-clinic-holiday></app-clinic-holiday>
              </mat-tab>
            <mat-tab label="Leave Management(Audiologists)">
                <app-doctor-leave></app-doctor-leave>
              </mat-tab>
           
            </mat-tab-group>
             </div>
             </div>
         </div>
        </div>
       </div>
        </div>
   </div>