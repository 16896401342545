<div class="container-fluid " [formGroup]="companyMasterForm">
    <form [formGroup]="companyMasterForm" autocomplete="off" novalidate>
      <div class="row">
       
        <div class="col-sm-12">
  
          <div class="row">
            <div class="col-sm-12 pr-4">
              <div class="row">
  
  
                <div class="col-sm-11">
                  <div class="row ">
  
                    <!-- <span><img src="../../assets/company.png" width="35rem"> </span>  -->
                    <span><h1 class="fass ml-3 pt-1">Hearing Aid Company Details</h1></span>
                  </div>
                 
                </div>
                <div class="col-sm-1 "> 
                  <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                    <a (click)="onClose()" matTooltip="Close" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">close</span></a>
                    </div>
                </div>
             
                <!-- <div class="col-sm-2" >
                      <button  class="btn btn-sm btn-primary example-full-width"
                      (click)="toDoctorList()" >List</button>
                     </div> -->
  
              </div>
                <div class="row margingTop">
                    
                    <div class="col-sm-12">
                      <div class="row pt-1 pr-1">
                        <div class=" col-sm-1 mt-3">
                         
                               <!-- <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Company Id</mat-label>
                                <input matInput formControlName="branchWiseId" readonly  />   
                              </mat-form-field> -->
                              <mat-label class="center" ngDefaultControl >{{bId}}</mat-label>
                        </div>
  
                        <div class="col-sm-2 ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Company Name
                              <!-- <span class="required-asterisk">*</span> -->
                            </mat-label>
                            <input matInput formControlName="companyName" placeholder="Company Name"  />
                            
                          </mat-form-field>
                          <mat-error class="margin_tp"
                          *ngIf="companyMasterForm.controls['companyName'].touched && companyMasterForm.controls['companyName'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                        <mat-error class="margin_tp"
                          *ngIf="companyMasterForm.controls['companyName'].touched && companyMasterForm.controls['companyName'].errors?.pattern">
                          Please enter valid company name.
                        </mat-error>
                        </div>
                        <div class=" col-sm-3">
                            <!-- <div class="row">
                              <div class="col-sm-10 pr-1"> -->
                                <!-- <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Select CAN Number </mat-label>
                                  <mat-select #select2 formControlName="canRefId" >
                                    <mat-select-filter *ngIf="select2.focused" [placeholder]="'Filter'" [displayMember]="'canNumber'"
                                    [array]="canNumberList"  (filteredReturn)="filteredListCanNumber =$event"></mat-select-filter>    
                                    <mat-option *ngFor="let item of filteredListCanNumber" [value]="item._id" (click)="onSelectCan(item.canNumber)"
                                  >
                                   {{ item.canNumber }}</mat-option>
                                  </mat-select>
                                </mat-form-field> -->
                                <!-- <mat-error class="margin_tp"
                                *ngIf="companyMasterForm.controls['canRefId'].touched && companyMasterForm.controls['canRefId'].errors?.required">
                                This field is mandatory.
                              </mat-error> -->
                            <!-- <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>CAN No</mat-label>
                              <input matInput formControlName="canRefId"/>   
                            </mat-form-field> -->
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>CAN Number
                                <!-- <span class="required-asterisk">*</span> -->
                              </mat-label>
                              <input matInput formControlName="canNumber"  maxlength="21" placeholder="CAN Number"  />
                              
                            </mat-form-field>
                             <mat-error class="margin_tp"
                                *ngIf="companyMasterForm.controls['canNumber'].touched && companyMasterForm.controls['canNumber'].errors?.required">
                                This field is mandatory.
                              </mat-error>
                            </div>
                            <div class="col-sm-3 ">
                              <div class="row">
                                <div class="col-sm-10 pr-1">
                              <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Make</mat-label>
                                <mat-select #select5 placeholder="make" class="colorblack" formControlName="make">
                                  <mat-select-filter *ngIf="select5.focused" [placeholder]="'Filter'" [displayMember]="'make'" [array]="makeList"
                                    (filteredReturn)="filteredMakeList =$event"></mat-select-filter>
                                  <mat-option *ngFor="let item of filteredMakeList" [value]="item.make">
                                    {{item.make}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                            <div class="col-sm-2 mTop">
                              <i (click)="openMakePopUp();" matTooltip="Add New Make" 
                              class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                           
                            </div>
                           </div>
                           </div>
                            <!-- <div class="col-sm-2 mt-2 mTop no-print">
                                <i (click)="openCanNoPopUp();" matTooltip="Add CAN Number" 
                                class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i> 
                            </div>  -->
                            <!-- </div>
                            </div> -->
                        <div class="col-sm-12 ">
                          <label class="header">Contact Details :</label>
                        </div>
                        <div class="col-sm-4">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Landline</mat-label>
                            <input matInput maxlength="15" placeholder=" Landline eg 0223-666666" formControlName="landline" />
  
                          </mat-form-field>
                          <mat-error class="margin_tp"
                          *ngIf="companyMasterForm.controls['landline'].touched && companyMasterForm.controls['landline'].errors?.pattern">
                          Please enter valid Landline Number
                        </mat-error>
                        </div>
  
                        <div class="col-sm-4 ">
                          <div class="row">
                            <div class="col-sm-10">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label> Mobile Number 
                              <!-- <span class="required-asterisk">*</span> -->
                            </mat-label>
                            <input  matInput maxlength="10" formControlName="companyContact"
                              placeholder="Mobile Number" />
                                                   </mat-form-field>
                                                   <mat-error class="margin_tp"
                                                   *ngIf="companyMasterForm.controls['companyContact'].touched && companyMasterForm.controls['companyContact'].errors?.required">
                                                   This field is mandatory.
                                                 </mat-error>
                                                 <mat-error class="margin_tp"
                                                   *ngIf="companyMasterForm.controls['companyContact'].touched && companyMasterForm.controls['companyContact'].errors?.pattern">
                                                   Enter valid mobile Number.
                                                 </mat-error>
                       
                        </div>
                        <div class="col-sm-2 wApp">
                          <i class="bi bi-whatsapp whatsapp-icon wApp fa-lg" (click)="WhatsApp();" matTooltip="WhatsApp Integration" ></i>
                    
                        </div>
                      </div>
                    </div>
                        <div class="col-sm-4 ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Company Email Address</mat-label>
                            <input matInput type="email" class="emailClass" placeholder="Company Email" formControlName="companyEmail" />
                          </mat-form-field>
                          <mat-error class="margin_tp"
                                                   *ngIf="companyMasterForm.controls['companyEmail'].touched && companyMasterForm.controls['companyEmail'].errors?.required">
                                                   This field is mandatory.
                                                 </mat-error>
                          <mat-error class="margin_tp"
                          *ngIf="companyMasterForm.controls['companyEmail'].touched && companyMasterForm.controls['companyEmail'].errors?.pattern">
                          Please enter valid company email Address.
                        </mat-error>
                        </div>
                        <div class="col-sm-12 ">
                          <label class="header">Address Details :</label>
                        </div>

                        <div class="col-sm-4 ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Company Address
                              <!-- <span class="required-asterisk">*</span> -->
                            </mat-label>
                            <input matInput placeholder="Company Address" formControlName="companyAddress" />
                           
                          </mat-form-field>
                          <mat-error class="margin_tp"
                          *ngIf="companyMasterForm.controls['companyAddress'].touched && companyMasterForm.controls['companyAddress'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                        </div>
  
                        <div class="col-sm-4 ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Pincode 
                              <!-- <span class="required-asterisk">*</span> -->
                            </mat-label>
                            <input matInput maxlength="6" placeholder="Pincode(e.g:416008)" formControlName="pincode" />
                            
                          </mat-form-field>
                          <mat-error class="margin_tp"
                              *ngIf="companyMasterForm.controls['pincode'].touched && companyMasterForm.controls['pincode'].errors?.pattern">
                              Enter only number.
                            </mat-error>
                            <mat-error class="margin_tp"
                              *ngIf="companyMasterForm.controls['pincode'].touched && companyMasterForm.controls['pincode'].errors?.maxlength">
                              Enter 6 digit Pincode.
                            </mat-error>
                            <mat-error class="margin_tp"
                              *ngIf="companyMasterForm.controls['pincode'].touched && companyMasterForm.controls['pincode'].errors?.minlength">
                              Enter 6 digit Pincode.
                            </mat-error>
                            <mat-error class="margin_tp"
                            *ngIf="companyMasterForm.controls['pincode'].touched && companyMasterForm.controls['pincode'].errors?.required">
                            This field is mandatory.
                          </mat-error>
                        </div>
  
                      </div>
                    </div>
                  </div>
               
                       
                 
                 
                  <div class="row mb-2">
                    <div class="col-sm-9" *ngIf="submitButton"></div>
                    <!-- <div class="col-sm-8" *ngIf="cancelButton"></div> -->
                    <div class="col-sm-3 mt-2 ">
                      <button type="button" *ngIf="submitButton && companyCreate" class="btn btn-primary btn-sm mt-3 example-full-width"
                        (click)="submitCompany()">Save </button>
                      <!-- <button type="button" *ngIf="updateButton  && companyUpdate" class="btn btn-primary btn-sm mt-3 example-full-width"
                        (click)="update()">Update </button>
                    </div>
                    <div class="col-sm-2  mt-2" *ngIf="cancelButton">
                      <button class="btn btn-primary btn-sm mt-3  example-full-width" (click)="cancelUpdate()">Cancel
                      </button> -->
                    </div>
                  </div>
               
            </div>
          </div>
  
        </div>
      </div>
    </form>
  </div>