<div class="container-fluid bgcolor2" >
    <form  autocomplete="off" novalidate>
        <div class="row">
            <div class="col-sm-12">
                        <div class="row col-sm-12">
                            <div class="col-sm-5"></div>
                            <div class="col-sm-6">
                                <h1 class="pt-2 pl-0">Stock Menu</h1>
                                <!-- <h3 class="col-sm-12">Stock Menu</h3> -->
                            </div>
                            <div class="col-sm-1 justify-content-end">
                                  <a (click)="onClose()" matTooltip="Close" class="d-flex 
                                  justify-content-end align-items-center mt-2"><span class="material-symbols-outlined">close</span></a>
                              </div>
                      </div> 
                      <div class="row col-sm-12">
                      <div class="icons-wraper dashboard-boxes col-sm-12">
                        <ul class=" row rowStyle pr-4">
                            
                            <li class=" col-sm-4">
                                <a class="icon-card p-0 col-sm-12"
                                (click)="openPurchaseInvoicePopUp()">
                                   <div class="icon-box">
                                      <i class="bi bi-receipt-cutoff icon-img"></i>
                                      <img src="../../assets/icon-bg7.svg" alt="" class=" icon-bg">
                                     
                                   </div>
                                   <h4 class="">Purchase Invoice</h4>
                                </a>
                          </li>
                          <li class=" col-sm-4">
                            <a class="icon-card p-0 col-sm-12"
                            (click)="openStockTransferPopUp()">
                                <div class="icon-box">
                                    <i class="bi bi-send-plus icon-img"></i>
                                    <img src="../../assets/icon-bg8.svg" alt="" class=" icon-bg">
                                 </div>
                                 <h4 class="">Stock Transfer</h4>
                            </a>
                      </li>
                      <li class=" col-sm-4">
                        <a class="icon-card p-0 pr-2 col-sm-12"
                        (click)="openPurchaseReturnPopUp()">
                            <div class="icon-box">
                                <i class="fa fa-exchange icon-img" aria-hidden="true"></i>
                                <!-- <i class="bi bi-send-plus icon-img"></i> -->
                                <img src="../../assets/icon-bg14.svg" class="icon-bg">
                                <!-- <img src="../../assets/icon-bg8.svg" alt="" class=" icon-bg"> -->
                             </div>
                             <h4 class="">Purchase Return</h4>
                        </a>
                  </li>
                        <!-- <li>
                                <a  class="icon-card" [routerLink]="['/dashboard']">
                                    <div class="col-sm-12 icon-box">
                                    
                                        <img src="../../assets/receipt2.png" class="icon-img">
                                        <img src="../../assets/icon-bg7.svg"  class="icon-bg justify-content-center">
                                    </div>
                                    <h4>Dashboard</h4>
                                 </a>

                        </li> -->
                    
                </ul>
            </div>
            </div>
  </div>
  </div>
    </form>
</div>
