import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
// import { CookiesStorageService, LocalStorageService, SessionStorageService, SharedStorageService } from "ngx-store";
import { Router } from '@angular/router';
import {
  ApiServiceService
} from '../app/api-service.service';

@Injectable()
export class HttpTokenInterceptorService implements HttpInterceptor {
    constructor(
        private restApiService: ApiServiceService,
        private router: Router) { }

    redirect(to): void {
        this.router.navigate([to]);
    }
    loginExpiredNotifier(): void {
        this.restApiService.openSnackbar('Your login session expired. Please Re-login to continue');
    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let token = sessionStorage.getItem('token');
    // 
    
        if (token == undefined || token == null || token == "") {
            token = "noToken";
            this.router.navigate(['/login']);
        }
        const authReq = req.clone({ headers: req.headers.set("token", token) });
        return next.handle(authReq).pipe(
            catchError((error, caught) => {
              // 
              
                if (error.status === 403 || error.status === 401) {
                  sessionStorage.setItem('token', "noToken");
                    // this.loginExpiredNotifier();
                    this.router.navigate(['/login']);// remember to import router class and declare it in the class
                    return throwError(error);
                } else {
                    return throwError(error);
                }
            }) as any
        )
        
    }
}