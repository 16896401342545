import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from "../api-service.service";
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import {Router,ActivatedRoute} from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner"; 
import { PopUpBranchComponent } from '../pop-up-branch/pop-up-branch.component';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { PopupRejectstockComponent } from "../popup-rejectstock/popup-rejectstock.component";
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-stock-transfer',
  templateUrl: './stock-transfer.component.html',
  styleUrls: ['./stock-transfer.component.scss']
})
export class StockTransferComponent implements OnInit {
  size= 10;
  disize= 10;
  dipage= 1;
  page= 1;
  listOfBatch: any;
  totalLength: any;
  ditotalLength:any;
  updateButton = false;
  submitButton = true;
  cancelButton= false;
  deliveryButton = false;
  acceptButton = false;
  rejectButton = false;
  stockTransferForm: FormGroup;
  userPermission: any;
  userType: any;
  permissionList=[];
  loggedId: any;
  dispatchDeviceCreate= true;
  dispatchDeviceUpdate: boolean = false;
  _id: string;
  date: Date;
  branchList: any;
  searchText: any;
  listOfStocks: any;
  deviceList: any[]=[];
  serialNoList:any[]=[];
  tempData = [];
  tempArr = [];
  toBranchList = [];
  //count = 0;
  disableFromBranch = false;
  disableToBranch = false;
  fromPrepopulation = false;
  prepopulationData: any;
  qtyCount: number = 0;
  stockTransferCreate= true;
  stockTransferUpdate= true;
  branchRef: any;
  clinicRef: string;
  branchName: string;
  toBranchRefId: any;
  transferStatus: string = "";
  listOfDispatchStocks: any;
  dispatchListButton: boolean = true;
  hideDispatchListButton = false;
  showList = false;
  transStatus:any;
  proceedupdateButton: boolean = false;
  filteredListBranch: any;
  filteredListToBranch: any[];
  branchCreate= true;
  reasonOfReject: any;
  bId: any;
  timeValue: any;
  constructor( public apimicroservice: ApiServiceService,
    private fb: FormBuilder,
    public router: Router,private route: ActivatedRoute,private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    public datepipe: DatePipe) { }
    
  ngOnInit(): void {
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    this.userType = sessionStorage.getItem('userType');
    if(this.userType !== 'Super Admin'){ 
    this.userPermission.forEach((element) => {
      if(element.pageName == 'Stock Transfer' && element.create == false)
      {
          this.stockTransferCreate = false;  
      }
      if(element.pageName == 'Stock Transfer' && element.update == false)
      {
          this.stockTransferUpdate = false;  
      }    
      if(element.pageName == 'Branch' && element.create == false)
      {
          this.branchCreate = false;  
      }  
    });
  }
    this.branchRef = sessionStorage.getItem('branchRef');
    this.userType = sessionStorage.getItem('userType');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.transferStatus= "Not In Progress";
    this.disableFromBranch = true;
    this.disableToBranch = false;
    this.fromPrepopulation = false;
    this.submitButton=true;
  this.proceedupdateButton = false;
  this.updateButton=false;
  this.cancelButton=false;
  this.acceptButton = false;
  this.rejectButton = false;
    this.date = new Date();
    this.timeValue =this.datepipe.transform((new Date), 'h:mm');
    this.qtyCount = 0;
    this.toBranchRefId = this.branchRef;
     let i = 0;
    this.stockTransferForm = this.fb.group({
      "stockTransId": new FormControl(),
      "handOverPerson": new FormControl('',[Validators.required]),
      "dateOfTransfer": new FormControl('',[Validators.required]), 
      "fromBranchRefId": new FormControl('',[Validators.required]), 
      "fromBranchName": new FormControl(''),
      "toBranchName": new FormControl(''),
      "toBranchRefId": new FormControl('',[Validators.required]), 
      "transferNote": new FormControl(''), 
      //"deviceRefId": new FormControl('',[Validators.required]), 
      //"serialNumber": new FormControl('',[Validators.required]), 
      //"transporterName": new FormControl('',[Validators.required]),   
      "searchStockTransfer": new FormControl(''),
      "searchDispatchStockTransfer" : new FormControl(''),
      "timeValue" : new FormControl(''),
      "device": this.fb.array([]),
    });
    
    this.fetchBranchList();
    this.getLastId();
    this.fetchStockTransferList();
    
    this.stockTransferForm.controls['dateOfTransfer'].setValue(this.date);
    // this.timeValue = new Date(this.date).toLocaleTimeString('en',
    // { timeStyle: 'short', hour12: false, timeZone: 'UTC' });

    this.stockTransferForm.controls['timeValue'].setValue(this.timeValue);
    this.addNew(i);
  }

  fetchStockTransferList() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    this.spinner.show();
    this.searchText = this.stockTransferForm.get('searchStockTransfer').value;
    if(this.userType !== 'Super Admin'){
      this.apimicroservice.getStockTransferList(this.page,this.size,this.searchText,branchRef,clinicRef).subscribe(res => {
        //
        this.spinner.hide();
       if(res.status){
        this.listOfStocks = res.data.stockData;
        this.totalLength = res.data.totalItems;
        // this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
    }else{
      this.apimicroservice.getStockTransferList(this.page,this.size,this.searchText,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
        //
        this.spinner.hide();
       if(res.status){
        this.listOfStocks = res.data.stockData;
        this.totalLength = res.data.totalItems;
        // this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
    }
}

dispatchList(){
  this.showList = true;
  let clinicRef= sessionStorage.getItem('clinicRef');
  let branchRef= sessionStorage.getItem('branchRef');
  let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
  let branchRefSuperAdmin= '';
   // this.transStatus = "In-Transit";
   this.transStatus = "In-Progress";
    this.spinner.show();
    this.searchText = this.stockTransferForm.get('searchDispatchStockTransfer').value;
    if(this.userType !== 'Super Admin'){
      this.apimicroservice.getDispatchList(this.dipage,this.disize,this.searchText,branchRef,clinicRef,this.transStatus).subscribe(res => {
        //
        this.spinner.hide();
       if(res.status){
         this.hideDispatchListButton = true;
         this.dispatchListButton = false;
        this.listOfDispatchStocks = res.data.stockData;
        this.ditotalLength = res.data.totalItems;
         //this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
    }else{
      this.apimicroservice.getDispatchList(this.dipage,this.disize,this.searchText,branchRefSuperAdmin,clinicRefSuperAdmin,this.transStatus).subscribe(res => {
        //
        this.spinner.hide();
       if(res.status){
        this.hideDispatchListButton = true;
        this.dispatchListButton = false;
        this.listOfDispatchStocks = res.data.stockData;
        this.ditotalLength = res.data.totalItems;
         //this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
    }
}

hideDispatchList(){
  this.showList = false;
  this.dispatchListButton = true;
  this.hideDispatchListButton = false;
}

  fetchBranchList(){
    //let id = '';
    this.apimicroservice.getBranchByClinicRefId(this.clinicRef).subscribe(res => {
      //
     
     this.branchList = res.data;
    //  this.filteredListBranch= this.branchList.slice();
     this.toBranchList = res.data;
    //  this.filteredListToBranch= this.toBranchList.slice();
     if(this.userType !== 'Super Admin'){
      this.disableFromBranch = true;
      this.disableToBranch = false;
      this.stockTransferForm.controls['fromBranchRefId'].setValue(this.branchRef);
      this.onSelectFromBranch(this.branchRef,this.branchName);
    }else{
      this.disableFromBranch = false;
      this.disableToBranch = true;
    }
     if(res.status){
       //this.apimicroservice.openSnackbar(res.message);
       
     }else{
       //this.apimicroservice.openSnackbar(res.message);
     }
  });
  }

  submitStockTransfer() {
    const creds = this.stockTransferForm.controls.device as FormArray;     
    if(this.stockTransferForm.invalid || creds.invalid) {   
      this.stockTransferForm.get('fromBranchRefId').markAsTouched();
      this.stockTransferForm.get('toBranchRefId').markAsTouched();
      this.stockTransferForm.get('dateOfTransfer').markAsTouched();
     this.stockTransferForm.get('handOverPerson').markAsTouched();
     creds.controls.forEach(element =>{
          element.get('serialNumber').markAsTouched();
          element.get('qty').markAsTouched();
          element.get('deviceRefId').markAsTouched();
        });
    }
    else{
     this.addStockTransfer();
    }
    // else if(creds.invalid)
    // {
    //   creds.controls.forEach(element =>{
    //     element.get('serialNumber').markAsTouched();
    //     element.get('qty').markAsTouched();
    //     element.get('deviceRefId').markAsTouched();
    //   });
         
    // }
    
}
updateStockTransfer() {
  const creds = this.stockTransferForm.controls.device as FormArray;     
  if(this.stockTransferForm.invalid || creds.invalid) {   
    this.stockTransferForm.get('fromBranchRefId').markAsTouched();
    this.stockTransferForm.get('toBranchRefId').markAsTouched();
    this.stockTransferForm.get('dateOfTransfer').markAsTouched();
   this.stockTransferForm.get('handOverPerson').markAsTouched();
   creds.controls.forEach(element =>{
        element.get('serialNumber').markAsTouched();
        element.get('qty').markAsTouched();
        element.get('deviceRefId').markAsTouched();
      });
  }
  else{
   this.updateStock();
  }
  
}
updateStock() {
  let payload = this.stockTransferForm.value;
  payload.transferStatus = "In-Progress";
  payload.clinicRefId = this.clinicRef;
  let stockMaintainPayload = {};
  let stockMaintainPayloadUpdated = {};
  //
  //
  //
  let oldsrnoarr = [];
  let newsenoarr = [];
  let allsrnolist = [];
  this.prepopulationData.device.forEach(element =>{
    element.serialNumber.forEach(item =>{
      oldsrnoarr.push(item);
    })
    
  });
  //
  payload.device.forEach(element =>{
    element.serialNumber.forEach(item =>{
      newsenoarr.push(item);
    }) 
    
  });
  //
  
  let missing = oldsrnoarr.filter(item =>newsenoarr.indexOf(item) < 0);
  //
  if(missing.length > 0){ 
    missing.forEach(element =>{
      allsrnolist.push({"serialNumber":element,"status":"deleted"});
    });
  }

  newsenoarr.forEach(element =>{
    allsrnolist.push({"serialNumber":element,"status":"new"});
  });
  //
  // let arr1 =[];
  // let arr2 = [];
  
  // arr1 = payload.device;
  // arr2 = this.prepopulationData.device;
  
  // const results = arr1.filter(({deviceRefId:id1}) =>!arr2.some(({deviceRefId:id2}) => id2 === id1));
  // //
 // this.spinner.show();
  this.apimicroservice.updateStockTransfer(this._id,payload).subscribe(res => {
    //
  //this.submitButton=true;
  this.deliveryButton = true;
  this.proceedupdateButton = true;
  this.updateButton=false;
  this.cancelButton=true;
   // this.spinner.hide();
    if(res.status){
      this.apimicroservice.openSnackbar(res.message);
      
        let transId = res.data._id;
        allsrnolist.forEach(element =>{
          stockMaintainPayload = {};
           if(element.status == "deleted"){
            stockMaintainPayload['transferStatus'] = "";
            //stockMaintainPayload['transferRefId'] = null;
            stockMaintainPayload['serialNumber'] = element.serialNumber;
            this.apimicroservice.updateStockMaintainByTransfer(transId,stockMaintainPayload).subscribe(res => {
              //
            });
           }else{
            stockMaintainPayload['transferStatus'] = "In-Progress";
            stockMaintainPayload['transferRefId'] = res.data._id;
            stockMaintainPayload['serialNumber'] = element.serialNumber;
            
            this.apimicroservice.updateStockMaintainBySerial(stockMaintainPayload).subscribe(res => {
              //
            });
           }
           
        });
    
      this.stockTransferForm.disable();
      //this.ngOnInit();
    }else{
      //this.apimicroservice.openSnackbar(res.message);
    }
  });
}

addStockTransfer(){
  let payload = this.stockTransferForm.value;
  payload.transferStatus = "In-Progress";
  payload.clinicRefId = this.clinicRef;
  //const tempData= this.stockTransferForm.controls.device.value;
  //this.spinner.show();
  //
  let stockMaintainPayload = {};
  
  this.apimicroservice.addStockTransfer(payload).subscribe(res => {
    //
   // this.spinner.hide();
    if(res.status){
      this.apimicroservice.openSnackbar(res.message);
  //     stockMaintainPayload['branchRefId'] = payload.toBranchRefId;
  //     stockMaintainPayload['transferRefId'] = res.data.data._id;
  //     //
  //     this.tempArr.forEach(element =>{
  //       this.apimicroservice.updateStockMaintain(element._id,stockMaintainPayload).subscribe(res => {
  //         //
  //       });
  // })
  
      stockMaintainPayload['transferStatus'] = "In-Progress";
      stockMaintainPayload['transferRefId'] = res.data.data._id;
      //
       this.tempArr.forEach(element =>{
        //
         
        this.apimicroservice.updateStockMaintain(element._id,stockMaintainPayload).subscribe(res => {
          //
        });
  });
   
    this.ngOnInit();
    this.stockTransferForm.reset();
    }else{
      //this.apimicroservice.openSnackbar(res.message);
    }
    
  });  
}

sendToDelivery(){
  let payload = this.stockTransferForm.value;
  let alertpayload = this.stockTransferForm.value;
  let newsenoarr = [];
  payload.device.forEach(element =>{
    element.serialNumber.forEach(item =>{
      newsenoarr.push(item);
    })
    
  });
  //
  payload.transferStatus = "In-Transit";
  alertpayload.transferStatus = "In-Transit";
  alertpayload.clinicRefId = this.clinicRef;
  //
  let stockMaintainPayload = {};
  //
this.apimicroservice.updateStockTransfer(this._id,payload).subscribe(res => {
    //
  this.submitButton=true;
  this.proceedupdateButton = false;
  this.updateButton=false;
  this.cancelButton= false;
  this.deliveryButton = false;
  // this.acceptButton = true;
  // this.rejectButton = true;
   // this.spinner.hide();
    if(res.status){
    //  this.apimicroservice.openSnackbar(res.message);
      stockMaintainPayload['transferStatus'] = "In-Transit";
      //stockMaintainPayload['transferRefId'] = res.data.data._id;
      //
      let bar = new Promise<void>((resolve,reject) =>{
       newsenoarr.forEach(element =>{
        let stockNum = Math.floor(10000 + Math.random() * 90000);
        stockMaintainPayload['serialNumber'] = element;
        // alertpayload.serialNumber = element;
        // alertpayload.billid = 'ST-' + stockNum;
        // alertpayload.stockTransferId = payload.stockTransferId;
        // alertpayload.stockTransId = payload.stockTransId; 
        // alertpayload.receiptNo = this._id; 
        
        this.apimicroservice.updateStockMaintainByTransfer(this._id,stockMaintainPayload).subscribe(res => {
          //
          if(res.status){ 
            resolve();
            // this.apimicroservice.addDispatchStockAlert(alertpayload).subscribe(res => {
            //   //
            //   // window.location.reload();
            //   resolve();
            // });
          } 
        });
    
  });
});
bar.then(() => {
  // this.ngOnInit();
    //window.location.reload();
    let bar2 = new Promise<void>((resolve,reject) =>{
      newsenoarr.forEach(element =>{
        let stockNum = Math.floor(10000 + Math.random() * 90000);
        alertpayload.serialNumber = element;
        alertpayload.billid = 'ST-' + stockNum;
        alertpayload.stockTransferId = payload.stockTransferId;
        alertpayload.stockTransId = payload.stockTransId; 
        alertpayload.receiptNo = this._id; 
        this.apimicroservice.addDispatchStockAlert(alertpayload).subscribe(res => {
          //
          // window.location.reload();
          resolve();
        });
      });
    });
    bar2.then(() => {
       this.ngOnInit();
    //window.location.reload();
    });
 });
    
    }else{
      //this.apimicroservice.openSnackbar(res.message);
    }
  });
}

acceptStock(){
  let payload = this.stockTransferForm.value;
  let alertpayload = {};
  let newsenoarr = [];
  payload.device.forEach(element =>{
    element.serialNumber.forEach(item =>{
      newsenoarr.push(item);
    })
    
  });
  payload.transferStatus = "Accept";
  //
  //
  let stockMaintainPayload = {};
////
this.apimicroservice.updateStockTransfer(this._id,payload).subscribe(res => {
    //
  this.submitButton=true;
  this.proceedupdateButton= false;
  this.updateButton=false;
  this.cancelButton=false;
  this.acceptButton = false;
  this.rejectButton = false;
   // this.spinner.hide();
    if(res.status){
     // this.apimicroservice.openSnackbar(res.message);
      stockMaintainPayload['branchRefId'] = payload.toBranchRefId;
      stockMaintainPayload['transferStatus'] = "Accept";
      stockMaintainPayload['transferRefId'] = res.data._id;
      alertpayload['transferStatus'] = "Accept";
      alertpayload['alertType'] = 13;
      //alertpayload['billid'] = this._id;
      //
      newsenoarr.forEach(element =>{
        stockMaintainPayload['serialNumber'] = element;
        alertpayload['serialNumber'] = element;
        
        this.apimicroservice.updateStockMaintainByTransfer(this._id,stockMaintainPayload).subscribe(res => {
          //
          if(res.status){
            this.apimicroservice.updateTransferAlert(element,alertpayload).subscribe(res => {
              //
               window.location.reload();
            });
          }
        });
  });
      this.ngOnInit();
    }else{
      //this.apimicroservice.openSnackbar(res.message);
    }
  });
}
 
rejectStock(){
  let payload = this.stockTransferForm.value;
  const dialogRef3 = this.dialog.open(PopupRejectstockComponent,
    {
       width: "50%",
      //height: "570px"
      data: {
        stockRefId: this._id,
        stockTransferPayload:payload
        //patientName:pname
      }
    });
    dialogRef3.afterClosed().subscribe((result) => {
      
      this.ngOnInit(); 
     });
//   let payload = this.stockTransferForm.value;
//   let newsenoarr = [];
//   let alertpayload = {};
//   payload.device.forEach(element =>{
//     element.serialNumber.forEach(item =>{
//       newsenoarr.push(item);
//     })
    
//   });
//   payload.transferStatus = "Reject";
//   //
//   //
//   let stockMaintainPayload = {};
// ////
// this.apimicroservice.updateStockTransfer(this._id,payload).subscribe(res => {
//     //
//   this.submitButton=true;
//   this.proceedupdateButton = false;
//   this.updateButton=false;
//   this.cancelButton=false;
//   this.acceptButton = false;
//   this.rejectButton = false;
//    // this.spinner.hide();
//     if(res.status){
//       //this.apimicroservice.openSnackbar(res.message);
//       stockMaintainPayload['transferStatus'] = "Reject";
//       alertpayload['transferStatus'] = "Reject";
//       alertpayload['alertType'] = 12;
//       //stockMaintainPayload['transferRefId'] = res.data.data._id;
//       //
//       newsenoarr.forEach(element =>{
//         stockMaintainPayload['serialNumber'] = element;
//         alertpayload['serialNumber'] = element;
//         this.apimicroservice.updateStockMaintainByTransfer(this._id,stockMaintainPayload).subscribe(res => {
//           //
//           if(res.status){
//             this.apimicroservice.updateTransferAlert(element,alertpayload).subscribe(res => {
//               //
//               window.location.reload();
//             });
//           }
//         });
//   });
//       this.ngOnInit();
//     }else{
//       //this.apimicroservice.openSnackbar(res.message);
//     }
//   });
}

  prepopulation(id){
   // this.spinner.show();
   this.fromPrepopulation = true;
    this.apimicroservice.getStockTransferById(id).subscribe(res => {
      //
      //this.spinner.hide();
    this.submitButton=false;
    this.cancelButton=true;
    this.disableFromBranch = true;
    this.disableToBranch = true;
     let data = res.data[0];
     this.prepopulationData = res.data[0];
      if(res.status){
        
        this.stockTransferForm = this.fb.group({
          "stockTransId": new FormControl(data.stockTransId),
          "stockTransferId": new FormControl(data.stockTransferId),
          "handOverPerson": new FormControl(data.handOverPerson,[Validators.required]),
          "dateOfTransfer": new FormControl(data.dateOfTransfer, [Validators.required]),
          "fromBranchRefId": new FormControl(data.fromBranchRefId,[Validators.required]),
          "fromBranchName": new FormControl(data.fromBranchName),
          "toBranchName": new FormControl(data.toBranchName),
          "toBranchRefId": new FormControl(data.toBranchRefId,[Validators.required]),
          "transferNote": new FormControl(data.transferNote),  
          "timeValue" : new FormControl(data.timeValue),
          "searchStockTransfer": new FormControl(''),
          "searchDispatchStockTransfer" : new FormControl(''),
          "device": this.fb.array([]),
          

        });
        this._id = data._id;
        
       // this.toBranchRefId = data.toBranchRefId;
        this.transferStatus = data.transferStatus;
        //let preArr = [];
        this.bId=data.stockTransId;
        data.device.forEach((element,index) => {
          let srnolist = [];
         // preArr.push(element);
          // 
          element.serialNumber.forEach(element =>{
            srnolist.push(element);
          })
          
          if(data.transferStatus === "Accept" || data.transferStatus === "In-Transit" || data.transferStatus === "Reject"){
            //
            this.serialNoList[index] = srnolist;
            //
          }   
          const tmpDict = {};
          tmpDict['deviceRefId'] = new FormControl(element.deviceRefId);
          tmpDict['deviceName'] = new FormControl(element.deviceName);
          tmpDict['serialNumber'] = new FormControl(element.serialNumber);
          tmpDict['serialNumberStatus'] = new FormControl(element.serialNumberStatus);
          tmpDict['qty'] = new FormControl(element.qty);
          tmpDict['isSerialNo'] = new FormControl(element.isSerialNo);
          const creds = this.stockTransferForm.controls.device as FormArray;
           creds.push(new FormGroup(tmpDict));
           //
           if(data.transferStatus === "In-Progress"){
             this.onSelectDevice(element.deviceRefId,element.deviceName,
              element.isSerialNo,index);
           }
          });
          if(data.transferStatus === "In-Progress"){
            //this.updateButton = true;
            this.proceedupdateButton = true;
            this.deliveryButton = true;
            this.onSelectFromBranch(data.fromBranchRefId,data.fromBranchName);
            this.stockTransferForm.disable();
            //
          }else if(data.transferStatus === "In-Transit" && data.toBranchRefId === this.branchRef){
            this.acceptButton = true;
            this.rejectButton = true;
            this.proceedupdateButton = false;
            this.updateButton = false;
            this.deliveryButton = false;
            // this.dispatchListButton = true;
            this.stockTransferForm.disable();
            //
          }else if(data.transferStatus === "In-Transit" && data.fromBranchRefId === this.branchRef){
            this.cancelButton=true;
           // this.dispatchListButton = false;
            this.stockTransferForm.disable();
            this.proceedupdateButton = false;
            this.updateButton = false;
            this.deliveryButton = false;
            //
          }else if(data.transferStatus === "Accept" && data.toBranchRefId === this.branchRef){
            this.cancelButton=true;
            //this.dispatchListButton = true;
            this.stockTransferForm.disable();
            this.proceedupdateButton = false;
            this.updateButton = false;
            this.deliveryButton = false;
            //
          }else if(data.transferStatus === "Accept" && data.fromBranchRefId === this.branchRef){
            this.cancelButton=true;
            //this.dispatchListButton = false;
            this.stockTransferForm.disable();
            //
          }else if(data.transferStatus === "Reject" && data.toBranchRefId === this.branchRef){
            this.cancelButton=true;
            this.proceedupdateButton = false;
            this.updateButton = false;
            this.deliveryButton = false;
            this.reasonOfReject = data.reasonOfReject;
            //this.dispatchListButton = true;
            this.stockTransferForm.disable();
            //
          }else if(data.transferStatus === "Reject"&& data.fromBranchRefId === this.branchRef){
            this.cancelButton=true;
            this.proceedupdateButton = false;
            this.updateButton = false;
            this.deliveryButton = false;
            this.reasonOfReject = data.reasonOfReject;
            //this.dispatchListButton = false;
            this.stockTransferForm.disable();
            //
          }else if((data.transferStatus === "Reject" || data.transferStatus === "Accept" || data.transferStatus === "In-Transit") && this.userType == 'Super Admin'){
            this.cancelButton=true;
            this.proceedupdateButton = false;
            this.updateButton = false;
            this.deliveryButton = false;
            if(data.reasonOfReject !== undefined){
              this.reasonOfReject = data.reasonOfReject;
            }
            
            //this.dispatchListButton = false;
            this.stockTransferForm.disable();
          }
          
      }
    });
  }
  deleteRow(index){
    (<FormArray>this.stockTransferForm.get("device")).removeAt(index);
  }
  addNew(i) {
    this.tempData = [];
    let tempdevices = [];
    tempdevices = this.deviceList[i];
    this.fromPrepopulation = false;
    this.qtyCount = 0;
    const creds = this.stockTransferForm.controls.device as FormArray;
        creds.push(
        this.fb.group({
          
          "deviceRefId": new FormControl('',[Validators.required]),
          "deviceName": new FormControl(''),
          "serialNumber": new FormControl('',[Validators.required]), 
          "serialNumberStatus": new FormControl(''),  
          "qty": new FormControl('',[Validators.required]),    
        toggle: false

})
);
   let deviceInfo = (<FormArray>this.stockTransferForm.get("device")).at(i);
   
   let deviceid = deviceInfo.get('deviceRefId').value;
   //
   if(deviceid !== ""){
    i = i + 1;
    //
    tempdevices = tempdevices.filter(m => m.deviceRefId!=deviceid);
    this.deviceList[i] = tempdevices;
   }
  }
  redirect(to): void {
    this.router.navigate([to]);
   }
  onback() {
    this.apimicroservice.back()
  }

  onSelectFromBranch(id,bname){
    //
    //
    this.disableToBranch = false;
    let i = 0;
    this.stockTransferForm.controls['fromBranchName'].setValue(bname);
    this.toBranchList = this.branchList;
    this.toBranchList = this.toBranchList.filter(m => m._id!=id); 
    let branchRef= sessionStorage.getItem('branchRef');
    this.apimicroservice.getStockMaintainByBranchId(id,branchRef).subscribe(res => {
      //
      const ids = res.data.map(o => o.deviceRefId);
      let temparr = [];
      let allDevices = []; 
      temparr = res.data;
      allDevices = temparr.filter(({deviceRefId},index) => !ids.includes(deviceRefId, index + 1))
      this.deviceList[i] = allDevices;
      //

     if(res.status){
       //this.apimicroservice.openSnackbar(res.message);
       
     }else{
       //this.apimicroservice.openSnackbar(res.message);
     }

   });
  }
  onSelectToBranch(id,bname){
    //
    //
    this.stockTransferForm.controls['toBranchName'].setValue(bname);
  }
  
  onSelectDevice(did,dname,isSerialNo,i){
    //
    //
    let payload = this.stockTransferForm.value;
    let deviceInfo = (<FormArray>this.stockTransferForm.get("device")).at(i);
    deviceInfo.patchValue({
      deviceName:dname
    });

    // if(isSerialNo == true) 
    // {
    //   deviceInfo.get('serialNumber').setValidators(Validators.required);
    //   deviceInfo.get('serialNumber').updateValueAndValidity();
    // }
    // else
    // {
    //   deviceInfo.get('serialNumber').setErrors(null);
    //   deviceInfo.get('serialNumber').clearValidators();
    //   deviceInfo.get('serialNumber').updateValueAndValidity();
    // //   deviceInfo.patchValue({
    // //     qty: 1,
    // //     serialNumberStatus:[{"serialNumber":'',"status":'unsold'}]
    // // });
    // }

    let tempsrno = [];
    let temppresrno = [];
    this.tempData = [];
    this.qtyCount = 0;
    let branchRefId = this.stockTransferForm.get("fromBranchRefId").value;
    let branchRef= sessionStorage.getItem('branchRef');
     //
    this.apimicroservice.getStockMaintainByDeviceId(i,did,branchRef).subscribe(res => {
      //
      if(res.data.length > 0){
      if(this.fromPrepopulation == true){
        // if(element.transferRefId == this._id)
        // this.serialNoList[i] = res.data;
        res.data.forEach(element =>{
          if(element.transferRefId == this._id && (element.transferStatus == "In-Progress" || element.transferStatus == "In-Transit" || element.transferStatus == "Reject")){
          //   if(element.serialNumber.includes('No SerialNo'))
          //  { 

          //  }
          //  else
          //  {
            temppresrno.push(element);
           //}
          
          }
         });
         //
         this.serialNoList[i] = temppresrno;
      }else{
        res.data.forEach(element =>{
         if(element.status == "unsold" && (element.transferStatus == undefined || element.transferStatus == "" || element.transferStatus == "Accept" || element.transferStatus == "Reject")){
          // if(element.serialNumber.includes('No SerialNo'))
          // {

          // }
          // else
          // {
            tempsrno.push(element);
          //}
         
         }
        });
        //
        this.serialNoList[i] = tempsrno;
      }

     if(res.status){
      // this.apimicroservice.openSnackbar(res.message);
     }else{
       //this.apimicroservice.openSnackbar(res.message);
     }
    }else{
      this.serialNoList[i] = [];
    }
   });
  }

  onSelectSerialNo(event:any,srno,id,status,i){
   // //
    //
    //
    //
    //let tempArr = [];
    let serialInfo = (<FormArray>this.stockTransferForm.get("device")).at(i);
    
    if(event.selected == true){
      //
      this.qtyCount = this.qtyCount + 1;
        this.tempArr.push({"_id":id,"serialNumber":srno});
        this.tempData.push({"serialNumber":srno,"status":status});
        //
        serialInfo.patchValue({
          qty:this.qtyCount,
          serialNumberStatus:this.tempData
      });
     
    }else{
      //
      this.qtyCount = this.qtyCount - 1;
      this.tempArr = this.tempArr.filter(m => m._id!=id);
      this.tempData = this.tempData.filter(m => m.serialNumber!=srno);
      serialInfo.patchValue({
        qty:this.qtyCount,
        serialNumberStatus:this.tempData
      });
     //
     
    } 
  }
  onPageChange(event)
  {
    this.page = event;
    // let p = this.page - 1;
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    //this.spinner.show();
    this.searchText = this.stockTransferForm.get('searchStockTransfer').value;
    if(this.userType !== 'Super Admin'){
      this.apimicroservice.getStockTransferList(this.page,this.size,this.searchText,branchRef,clinicRef).subscribe(res => {
        //
        //this.spinner.hide();
       if(res.status){
        this.listOfStocks = res.data.stockData;
        this.totalLength = res.data.totalItems;
         //this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
    }else{
      this.apimicroservice.getStockTransferList(this.page,this.size,this.searchText,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
        //
       // this.spinner.hide();
       if(res.status){
        this.listOfStocks = res.data.stockData;
        this.totalLength = res.data.totalItems;
        // this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
    }
  }

  onKeyUpEvent(event: any) {
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
   // this.spinner.show();
    this.searchText = this.stockTransferForm.get('searchStockTransfer').value;
    if(this.userType !== 'Super Admin'){
      this.apimicroservice.getStockTransferList(this.page,this.size,this.searchText,branchRef,clinicRef).subscribe(res => {
        //
       // this.spinner.hide();
       if(res.status){
        this.listOfStocks = res.data.stockData;
        this.totalLength = res.data.totalItems;
         //this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
    }else{
      this.apimicroservice.getStockTransferList(this.page,this.size,this.searchText,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
        //
        //this.spinner.hide();
       if(res.status){
        this.listOfStocks = res.data.stockData;
        this.totalLength = res.data.totalItems;
         //this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
    }
  }

  onPageDispatchChange(event)
  {
    this.dipage = event;
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    this.transStatus = "In-Progress";
    //this.spinner.show();
    this.searchText = this.stockTransferForm.get('searchDispatchStockTransfer').value;
    if(this.userType !== 'Super Admin'){
      this.apimicroservice.getDispatchList(this.dipage,this.disize,this.searchText,branchRef,clinicRef,this.transStatus).subscribe(res => {
        //
        //this.spinner.hide();
       if(res.status){
        this.listOfDispatchStocks = res.data.stockData;
        this.ditotalLength = res.data.totalItems;
         //this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
    }else{
      this.apimicroservice.getDispatchList(this.dipage,this.disize,this.searchText,branchRefSuperAdmin,clinicRefSuperAdmin,this.transStatus).subscribe(res => {
        //
        //this.spinner.hide();
       if(res.status){
        this.listOfDispatchStocks = res.data.stockData;
        this.ditotalLength = res.data.totalItems;
         //this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
    }
  }

  onKeyUpDispatchEvent(event: any) {
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    this.transStatus = "In-Progress";
    //this.spinner.show();
    this.searchText = this.stockTransferForm.get('searchDispatchStockTransfer').value;
    if(this.userType !== 'Super Admin'){
      this.apimicroservice.getDispatchList(this.dipage,this.disize,this.searchText,branchRef,clinicRef,this.transStatus).subscribe(res => {
        //
        //this.spinner.hide();
       if(res.status){
        this.listOfDispatchStocks = res.data.stockData;
        this.ditotalLength = res.data.totalItems;
         //this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
    }else{
      this.apimicroservice.getDispatchList(this.dipage,this.disize,this.searchText,branchRefSuperAdmin,clinicRefSuperAdmin,this.transStatus).subscribe(res => {
        //
       // this.spinner.hide();
       if(res.status){
        this.listOfDispatchStocks = res.data.stockData;
        this.ditotalLength = res.data.totalItems;
         //this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
    }
  }

  proceedToUpdateStockTransfer(){
    this.updateButton = true;
    this.proceedupdateButton = false;
    this.deliveryButton = false;
    this.stockTransferForm.enable();
    this.stockTransferForm.get('fromBranchRefId').disable();
    this.stockTransferForm.get('toBranchRefId').disable();
    // this.disableFromBranch = true;
    // this.disableToBranch = true;
  }

  cancelUpdate(){
    this.stockTransferForm.reset();
    this.ngOnInit();
    this.submitButton=true;
    this.proceedupdateButton = false;
    this.updateButton=false;
    this.cancelButton=false;
    this.deliveryButton = false;
    this.acceptButton = false;
    this.rejectButton = false;
   // this.dispatchListButton = false;
  }
  toStockTranferForm() {
    document.getElementById("toStockTranferForm").scrollIntoView();
  }
  toStockTranferList() {
    document.getElementById("toStockTranferList").scrollIntoView();
  }
  getLastId()
  {
    this.apimicroservice.getStockTransferLastID().subscribe(res => {
      //   
      // if(res['data']['data'] == '')
      // {
      //   this.stockTransferForm.controls['billid'].setValue('1');
      // }
      // else{
        if (res['status']) {
          let stockIdd = res['data']['totalItems'];
          this.stockTransferForm.controls['stockTransId'].setValue(stockIdd + 1);
          this.bId=stockIdd + 1;
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
      //}
    })
  }

  fetchToPopupBranchList(){
    //let id = '';
    let id = this.stockTransferForm.get('fromBranchRefId').value;
    this.apimicroservice.getBranchByClinicRefId(this.clinicRef).subscribe(res => {
      //
    //  this.filteredListBranch= this.branchList.slice();
     this.toBranchList = res.data;
     this.toBranchList = this.toBranchList.filter(m => m._id!=id);
    //  this.filteredListToBranch= this.toBranchList.slice();
     
     if(res.status){
       //this.apimicroservice.openSnackbar(res.message);
       
     }else{
       //this.apimicroservice.openSnackbar(res.message);
     }
  });
  }
  openBranchPopUp() {
    localStorage.clear(); 
    const dialogRef3 = this.dialog.open(PopUpBranchComponent,
      {
        width: "1000px",
      });

      dialogRef3.afterClosed().subscribe((result) => {
      //   this.apimicroservice.getBranchByClinicRefId(this.clinicRef).subscribe(res => {
      //     //
         
      //    this.branchList = res.data;
      //     this.filteredListBranch= this.branchList.slice();
      //     this.onSelectFromBranch(res.data[0]._id,res.data[0].branchName)
      //     this.stockTransferForm.get("fromBranchRefId").setValue(res.data[0]._id);
      //   //  this.toBranchList = res.data;
      //   //  this.filteredListToBranch= this.toBranchList.slice();
      //   //  this.onSelectToBranch(res.data[0]._id,res.data[0].branchName)
      //   //  this.stockTransferForm.get("toBranchRefId").setValue(res.data[0]._id);
      //    if(this.userType !== 'Super Admin'){
      //     this.disableFromBranch = true;
      //     this.disableToBranch = false;
      //     this.stockTransferForm.controls['fromBranchRefId'].setValue(this.branchRef);
      //     this.onSelectFromBranch(this.branchRef,this.branchName);
      //   }else{
      //     this.disableFromBranch = false;
      //     this.disableToBranch = true;
      //   }
      //    if(res.status){
      //      //this.apimicroservice.openSnackbar(res.message);
           
      //    }else{
      //      //this.apimicroservice.openSnackbar(res.message);
      //    }
      // });
        this.fetchBranchList();  
       });
  }
  openToBranchPopUp(){
    localStorage.clear(); 
    const dialogRef3 = this.dialog.open(PopUpBranchComponent,
      {
        width: "1000px",
      });

      dialogRef3.afterClosed().subscribe((result) => {
        this.fetchToPopupBranchList();  
       });
  }
  getTime()
{
  let payload = this.stockTransferForm.value;
  this.timeValue =this.datepipe.transform((new Date), 'h:mm');
}
  


  // openBranchPopUp2() {
  //   localStorage.clear(); 
  //   const dialogRef3 = this.dialog.open(PopUpBranchComponent,
  //     {
  //       width: "1000px",
  //       height: "500px"
  //     });

  //     dialogRef3.afterClosed().subscribe((result) => {
  //       this.apimicroservice.getBranchByClinicRefId(this.clinicRef).subscribe(res => {
  //         //
         
  //       //  this.branchList = res.data;
  //       //   this.filteredListBranch= this.branchList.slice();
  //       //   this.onSelectFromBranch(res.data[0]._id,res.data[0].branchName)
  //       //   this.stockTransferForm.get("fromBranchRefId").setValue(res.data[0]._id);
  //        this.toBranchList = res.data;
  //        this.filteredListToBranch= this.toBranchList.slice();
  //        this.onSelectToBranch(res.data[0]._id,res.data[0].branchName)
  //        this.stockTransferForm.get("toBranchRefId").setValue(res.data[0]._id);
  //        if(this.userType !== 'Super Admin'){
  //         this.disableFromBranch = true;
  //         this.disableToBranch = false;
  //         this.stockTransferForm.controls['fromBranchRefId'].setValue(this.branchRef);
  //         this.onSelectFromBranch(this.branchRef,this.branchName);
  //       }else{
  //         this.disableFromBranch = false;
  //         this.disableToBranch = true;
  //       }
  //        if(res.status){
  //          //this.apimicroservice.openSnackbar(res.message);
           
  //        }else{
  //          //this.apimicroservice.openSnackbar(res.message);
  //        }
  //     });
  //      // this.fetchBranchList();  
  //      });
  // }
}