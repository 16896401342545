import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BillingCompanyComponent } from './billing-company/billing-company.component';
import { ClinicMasterComponent } from './clinic-master/clinic-master.component';
import { ClinicalStaffMasterComponent } from './clinical-staff-master/clinical-staff-master.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DevicesMasterComponent } from './devices-master/devices-master.component';
import { HomepageComponent } from './homepage/homepage.component';
import { OPDDashboardComponent } from './opd-dashboard/opd-dashboard.component';
import { OutpatientComponent } from './outpatient/outpatient.component';
import { PatientMasterComponent } from './patient-master/patient-master.component';
import { ProfileComponent } from './profile/profile.component';
import { VendorMasterComponent } from './vendor-master/vendor-master.component';
import { CourierServiceProviderMasterComponent } from "./courier-service-provider-master/courier-service-provider-master.component";
import { DoctorMasterComponent } from "./doctor-master/doctor-master.component";
import { RoleMasterComponent } from "./role-master/role-master.component";
import { ServicesMasterComponent } from "./services-master/services-master.component";
import { UsersMasterComponent } from "./users-master/users-master.component";
import { CalanderComponent } from './calander/calander.component';
import { GoodsInWordComponent } from './goods-in-word/goods-in-word.component';
import { GoodsInwordReturnComponent } from './goods-inword-return/goods-inword-return.component';
import { StockTransferComponent } from './stock-transfer/stock-transfer.component';
import { ServiceRateComponent } from './service-rate/service-rate.component';
import { RepairComplaintComponent } from './repair-complaint/repair-complaint.component';
import { PopupItemsCollectedComponent } from './popup-items-collected/popup-items-collected.component';
import { PopupNewComplaintComponent } from './popup-new-complaint/popup-new-complaint.component';
import { PopupSerialNoComponent } from './popup-serial-no/popup-serial-no.component';
import { AppointmentTypeComponent } from "./appointment-type/appointment-type.component";
import { PopupItemsPackedComponent } from './popup-items-packed/popup-items-packed.component';
import { PopupAddInstructionsComponent } from './popup-add-instructions/popup-add-instructions.component';
import { BranchMasterComponent } from './branch-master/branch-master.component';
import { EarMouldComponent } from './ear-mould/ear-mould.component';
import { MasterComponent } from './master/master.component';
import { LoginComponent } from './login/login.component';
import { UserTabComponent } from './user-tab/user-tab.component';
import { BillingComponent } from './billing/billing.component';
import { InventoryMenuComponent } from './inventory-menu/inventory-menu.component';
import { ClinicExpensesComponent } from './clinic-expenses/clinic-expenses.component';
import { HaFormComponent } from './ha-form/ha-form.component';
import { PermissionsComponent } from './permissions/permissions.component';
import { GenericSettingComponent } from './generic-setting/generic-setting.component';
import { DoctorAvailabilityComponent } from './doctor-availability/doctor-availability.component';
import { EarMouldNewComponent } from './ear-mould-new/ear-mould-new.component';
import { InquiryComponent } from './inquiry/inquiry.component';
import { CustomHearingAidComponent } from './custom-hearing-aid/custom-hearing-aid.component';
import { PlaceOrderComponent } from './place-order/place-order.component';
import { ReportComponent } from './report/report.component';
import { PopUpBranchComponent } from './pop-up-branch/pop-up-branch.component';
import { PopUpPatientComponent } from './pop-up-patient/pop-up-patient.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { PopUpDoctorComponent } from './pop-up-doctor/pop-up-doctor.component';
import { PopUpVendorComponent } from './pop-up-vendor/pop-up-vendor.component';
// import { InwardOutwardComponent } from './inward-outward/inward-outward.component';
import { InwardOutwardNewComponent } from './inward-outward-new/inward-outward-new.component';
import { CompanyMasterComponent } from './company-master/company-master.component';
import { PrintBillComponent } from './print-bill/print-bill.component';
import { BillFormComponent } from './bill-form/bill-form.component';
import { PopupTotalBillComponent } from './popup-total-bill/popup-total-bill.component';
import { PopupModeOfPaymentComponent } from './popup-mode-of-payment/popup-mode-of-payment.component';
import { PopupServiceComponent } from './popup-service/popup-service.component';
import { PopupAddDeviceComponent } from './popup-add-device/popup-add-device.component';
import { ReceiptComponent } from './receipt/receipt.component';
import { ChequeListComponent } from './cheque-list/cheque-list.component';
import { ChequeReconciliationComponent } from './cheque-reconciliation/cheque-reconciliation.component';
import { HolidayLeaveComponent } from './holiday-leave/holiday-leave.component';
import { ClinicHolidayComponent } from './clinic-holiday/clinic-holiday.component';
import { DoctorLeaveComponent } from './doctor-leave/doctor-leave.component';
import { PatientGlobalSearchComponent } from './patient-global-search/patient-global-search.component';
import { ChequeAlertComponent } from './cheque-alert/cheque-alert.component';
import { WhatsAppPopupComponent } from './whats-app-popup/whats-app-popup.component';
import { SearchDashboardComponent } from './search-dashboard/search-dashboard.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { NotesComponent } from './notes/notes.component';
import { DeviceNotesComponent } from './device-notes/device-notes.component';
import { PopupFollowUpComponent } from './popup-follow-up/popup-follow-up.component';
import { RepairActionComponent } from './repair-action/repair-action.component';
import { RepairPrintComponent } from './repair-print/repair-print.component';
import { CustomHaPrintComponent } from './custom-ha-print/custom-ha-print.component';
import { EarMouldPrintComponent } from './ear-mould-print/ear-mould-print.component';
import { HaFormPrintComponent } from './ha-form-print/ha-form-print.component';
import { BillingPopupComponent } from './billing-popup/billing-popup.component';
import { AdvanceAmountComponent } from './advance-amount/advance-amount.component';
import { RepairCalculationComponent } from './repair-calculation/repair-calculation.component';
import { DataMigrationComponent } from './data-migration/data-migration.component';
import { GoodsInWordReturnComponent } from './goods-in-word-return/goods-in-word-return.component';
import { BackdatedAptComponent } from './backdated-apt/backdated-apt.component';
import { PlaceOrderPrintComponent } from './place-order-print/place-order-print.component';
import { FittedByUsComponent } from './fitted-by-us/fitted-by-us.component';
import { PopUpFittedByUsComponent } from './pop-up-fitted-by-us/pop-up-fitted-by-us.component';


const routes: Routes = [
  {
    path:'home',
    component:HomepageComponent
  },
  {
    path:'dashboard',
    component:DashboardComponent,
    data:{name:"Dashboard",isTab:false}
  },
  {
    path:'home/calander',
    component:CalanderComponent,
    data:{name:"Book Appointments",isTab:false}
  },
  {
    path:'calander',
    component:CalanderComponent
  },
  {
    path:'home/inquiry',
    component:InquiryComponent,
    data:{name:"Inquiry",isTab:false}
  },
  {
    path:'inquiry',
    component:InquiryComponent,
  },
  {
    path:'home/billing',
    component:BillingComponent,
    data:{name:"Billing",isTab:false}
  },
  {
    path:'billing',
    component:BillingComponent,
  },
  {
    path:'print-bill',
    component: PrintBillComponent,
  },
  {
    path:'print-bill',
    component:PrintBillComponent,
    data:{name:"Billing Record",isTab:false}
  },
  {
    path:'repair-print/:repairNo',
    component: RepairPrintComponent,
    data:{name:"Repair Print",isTab:false}
  }, 
  {
    path:'custom-ha-print/:customHaNo',
    component: CustomHaPrintComponent,
    data:{name:"Custom-HA Print",isTab:false}
  }, 
  {
    path:'ear-mould-print/:earMouldNo', 
    component: EarMouldPrintComponent,
    data:{name:"Ear Mould Print",isTab:false}
  }, 
  { 
    path:'ha-form-print/:haFormNo',
    component: HaFormPrintComponent,
    data:{name:"HA Form Print",isTab:false}
  }, 
  { 
    path:'place-order-print/:placeOrderNo',
    component: PlaceOrderPrintComponent, 
    data:{name:"PLace Order Print",isTab:false}
  }, 
  { 
    path:'bill-form/:billNo',
    component: BillFormComponent,
  },
  {
    path:'bill-form',
    component:BillFormComponent,
    data:{name:"Billing Form",isTab:false}
  },
  {
    path:'print-bill/:billNo',
    component: PrintBillComponent,
  },
  {
    path:'print-bill',
    component:PrintBillComponent,
    data:{name:"Print Bill",isTab:false}
  },
  {
    path:'advance-amount',
    component:AdvanceAmountComponent,
    data:{name:"Advance Amount",isTab:false}
  },
  {
    path:'billing-popup',
    component:BillingPopupComponent
  }, 
  {
    path:'popup-service',
    component: PopupServiceComponent,
  },
  {
    path:'popup-service',
    component:PopupServiceComponent,
    data:{name:"Add Service",isTab:false}
  }, 
  {
    path:'popup-add-device',
    component: PopupAddDeviceComponent,
  },
  {
    path:'popup-add-device',
    component:PopupAddDeviceComponent,
    data:{name:"Add Device",isTab:false}
  }, 
  {
    path:'popup-total-bill',
    component:PopupTotalBillComponent,
    data:{name:"Total Bill",isTab:false}
  }, 
  {
    path:'popup-mode-of-payment',
    component:PopupModeOfPaymentComponent,
    data:{name:"Popup Mode Of Payment",isTab:false}
  },
  //   path:'bill-form/billNo/receipt/:receiptNo',
  {
    path:'receipt/:receiptNo',
    component: ReceiptComponent,
  },
  {
    path:'receipt',
    component:ReceiptComponent,
    data:{name:"Receipt",isTab:false}
  }, 
  {
    path:'home/stock',
    component:InventoryMenuComponent,
    data:{name:"Stock",isTab:false}
  },
  {
    path:'stock',
    component:InventoryMenuComponent,
  },
  {
    path:'home/goods-in-word',
    component:GoodsInWordComponent,
    data:{name:"Purchase Invoice",isTab:true,isStockTab:true}
   }, 
   {
    path:'goods-in-word',
    component:GoodsInWordComponent,
   },
   {
    path:'home/goods-inword-return',
    component:GoodsInwordReturnComponent,
    // data:{name:"Purchase Invoice Return",isTab:true}
   },
   {
    path:'home/goods-in-word-return',
    component:GoodsInWordReturnComponent,
    data:{name:"Purchase Return",isTab:true}
   },
   {
    path:'home/stock-transfer',
    component:StockTransferComponent,
    data:{name:"Stock Transfer",isTab:true,isStockTab:true}
   },
   {
    path:'stock-transfer',
    component:StockTransferComponent,
   },
   {
    path:'repair-complaint',
    component:RepairComplaintComponent,
    data:{name:"Repair Complaint",isTab:false}
   },
   {
    path:'repair-complaint/:repairNo',
    component:RepairComplaintComponent,
   },
   {
    path:'home/ear-mould',
    component:EarMouldComponent,
    data:{name:"Ear Mould",isTab:false}
  },
  {
    path:'ear-mould/:earMouldNo',
    component:EarMouldComponent,
  },
  // {
  //   path:'ear-mould2',
  //   component:EarMouldComponent,
  // },
  {
    path:'home/custom-hearing-aid',
    component:CustomHearingAidComponent,
    data:{name:"Custom Hearing Aid",isTab:false}
  },
  {
    path:'custom-hearing-aid',
    component:CustomHearingAidComponent,
  },
  {
    path:'home/ha-form',
    component:HaFormComponent,
    data:{name:"HA Form",isTab:false}
  },
  {
    path:'ha-form',
    component:HaFormComponent,
  },
  {
    path:'home/place-order',
    component:PlaceOrderComponent,
    data:{name:"Place Order",isTab:false}
  },
  {
    path:'place-order',
    component:PlaceOrderComponent,
  },
  // {
  //   path:'inward-outward',
  //   component:InwardOutwardComponent
  // },
  // {
  //   path:'home/inward-outward',
  //   component:InwardOutwardComponent,
  //   data:{name:"Inward Outward",isTab:false}
  //  },
   {
    path:'inward-outward',
    component:InwardOutwardNewComponent
  },
  {
    path:'home/inward-outward',
    component:InwardOutwardNewComponent,
    data:{name:"Inward Outward",isTab:false}
   },
   {
    path:'inward-outward/:inwardOutwardId/:branchName',
    component:InwardOutwardNewComponent,
   },
  // {
  //   path:'home/courier-book',
  //   component:CourierBookComponent,
  //   data:{name:"Courier Book",isTab:false}
  // },
  // {
  //   path:'courier-book',
  //   component:CourierBookComponent,
  // },
  {
    path:'home/report',
    component:ReportComponent,
    data:{name:"Report",isTab:false}
  },
  {
    path:'Report',
    component:ReportComponent,
  },
  {
    path:'home/clinic-expenses',
    component:ClinicExpensesComponent,
    data:{name:"Clinic Expenses",isTab:false}
  },
  {
    path:'clinic-expenses',
    component:ClinicExpensesComponent,
  },
  { 
    path:'home/patient-master',
    component:PatientMasterComponent,
    data:{name:"Patient",isTab:false}
  },
  {
    path:'master',
    component:MasterComponent,
    data:{name:"Settings",isTab:false}
  },
  {
    path:'patient-master/:patientNo',
    component:PatientMasterComponent,
  },
  {
    path:'home/clinic-master',
    component:ClinicMasterComponent,
    data:{name:"Clinic",isTab:true}
  },
  {
    path:'clinic-master',
    component:ClinicMasterComponent,
  },
  {
    path:'home/branch-master',
    component:BranchMasterComponent,
    data:{name:"Branch",isTab:true}
  },
  {
    path:'branch-master',
    component:BranchMasterComponent,
  },
  {
    path:'home/vendor-master',
    component:VendorMasterComponent,
    data:{name:"Vendor",isTab:true}
  },
  {
    path:'vendor-master',
    component:VendorMasterComponent,
  },
  {
    path:'home/devices-master',
    component:DevicesMasterComponent,
    data:{name:"Devices",isTab:true}
  },
  {
    path:'devices-master',
    component:DevicesMasterComponent,
  },
  {
    path:'home/billing-company',
    component:BillingCompanyComponent,
    data:{name:"Billing Company",isTab:true}
  },
  {
    path:'billing-company',
    component:BillingCompanyComponent,
  },
  {
    path:'home/users-master',
    component:UsersMasterComponent,
    data:{name:"User",isTab:true}
  },
  {
    path:'home/permissions',
    component:PermissionsComponent,
    data:{name:"Permissions",isTab:true}
  },
  {
    path:'home/clinical-staff-master',
    component:ClinicalStaffMasterComponent,
    data:{name:"Clinical Staff",isTab:true}
  },
  {
    path:'clinical-staff-master',
    component:ClinicalStaffMasterComponent,
  },
  {
    path:'home/appointment/type-appointment',
    component:AppointmentTypeComponent,
    data:{name:"Appointment Type",isTab:true}
  },
  {
    path:'appointment/type-appointment',
    component:AppointmentTypeComponent,
  },
  {
    path:'home/services-master',
    component:ServicesMasterComponent,
    data:{name:"Services",isTab:true}
  },
  {
    path:'services-master',
    component:ServicesMasterComponent,
  },
  {
    path:'home/doctor-master',
    component:DoctorMasterComponent,
    data:{name:"Audiologist",isTab:true}
  },
  {
    path:'doctor-master',
    component:DoctorMasterComponent,
  },
  {
    path:'home/curier-service-provider',
    component:CourierServiceProviderMasterComponent,
    data:{name:"Courier Service Provider",isTab:true}
  },
  {
    path:'curier-service-provider',
    component:CourierServiceProviderMasterComponent,
  },
  {
    path:'home/role-master',
    component:RoleMasterComponent,
    data:{name:"Role",isTab:true}
  },
  {
    path:'role-master',
    component:RoleMasterComponent,
  },
  {
    path:'home/service-rate',
    component:ServiceRateComponent,
    data:{name:"Service Rate",isTab:true}
   },
   {
    path:'service-rate',
    component:ServiceRateComponent,
   },
   {
    path:'home/doctor-availability',
    component:DoctorAvailabilityComponent,
    data:{name:"Audiologist Availability",isTab:true}
   },
   {
    path:'doctor-availability',
    component:DoctorAvailabilityComponent,
   },
  {
   path:'home/company-master',
   component:CompanyMasterComponent,
   data:{name:"Company Master",isTab:true}
  },
  {
   path:'company-master',
   component:CompanyMasterComponent,
  },
  //  {
  //   path: 'home/opt/:pid',
  //   component: OutpatientComponent
  // },
  {
    path:'home/opt/dashboard',
    component:OPDDashboardComponent,
    data:{name:"Todays Appointment",isTab:false}
   },
   {
    path:'home/opt',
    component:OutpatientComponent,
    data:{name:"Checkup Details",isTab:false}
   },
   {
    path:'opt/dashboard',
    component:OPDDashboardComponent,
   },
   {
    path:'home/generic-setting',
    component:GenericSettingComponent,
    data:{name:"Generic Setting",isTab:false}
   },
   {
    path:'generic-setting',
    component:GenericSettingComponent,
   },
   {
    path:'home/cheque-list',
    component:ChequeListComponent,
    data:{name:"Cheque List",isTab:false}
   }, 
   {
    path:'cheque-list',
    component:ChequeListComponent,
   },
   { 
    path:'home/cheque-reconciliation',
    component:ChequeReconciliationComponent,
    data:{name:"Cheque Reconciliation",isTab:false}
   },
   {
    path:'cheque-reconciliation/:chequeNo',
    component: ChequeReconciliationComponent,
   },
   {
    path:'home/holiday-leave',
    component:HolidayLeaveComponent,
    data:{name:"Holiday/Leave",isTab:false}
   },
   {
    path:'holiday-leave',
    component:HolidayLeaveComponent,
   },
   {
    path:'home/patient-global-search',
    component:PatientGlobalSearchComponent,
    data:{name:"Global Search",isTab:false}
   }, 
   {
    path:'patient-global-search',
    component:PatientGlobalSearchComponent,
   },
   {
    path:'home/cheque-alert',
    component:ChequeAlertComponent,
    data:{name:"Alert",isTab:false}
   }, 
   {
    path:'cheque-alert',
    component:ChequeAlertComponent,
   },
   {
    path:'home/clinic-holiday',
    component:ClinicHolidayComponent,
    data:{name:"Clinic Holidays",isTab:false}
   },
   {
    path:'clinic-holiday',
    component:ClinicHolidayComponent,
   },
   {
    path:'home/doctor-leave',
    component:DoctorLeaveComponent,
    data:{name:"Audiologist Leave",isTab:false} 
   },
   {
    path:'doctor-leave',
    component:DoctorLeaveComponent,
   },
   {
    path:'whats-app-popup',
    component:WhatsAppPopupComponent,
    data:{name:"Whats App Integration",isTab:false}
   },
   {
    path:'notes',
    component:NotesComponent,
    data:{name:"Notes",isTab:false}
   },
   {
    path:'device-notes',
    component:DeviceNotesComponent,
    data:{name:"Device Notes",isTab:false}
   },
   {
    path:'popup-follow-up',
    component:PopupFollowUpComponent,
    data:{name:"Follow Up",isTab:false}
   },
   { 
    path:'repair-action', 
    component:RepairActionComponent,
    data:{name:"Add Repair Action",isTab:false}
   },
   {
    path:'repair-calculation', 
    component:RepairCalculationComponent,
    data:{name:"Repair Calculation Permissions",isTab:false}
   },
   {
    path:'backdated-apt',  
    component:BackdatedAptComponent,
    data:{name:"Back Dated Appointment Permissions",isTab:false}
   },
    {
      path:'profile',
      component:ProfileComponent
    },
   {
    path:'home/popup-items-collected',
    component:PopupItemsCollectedComponent,
    // data:{name:"Add Collected Items",isTab:false}
  },
  {
    path:'home/popup-new-complaint',
    component:PopupNewComplaintComponent,
    // data:{name:"Add New Complaint",isTab:false}
  },
  {
    path:'home/popup-serial-no',
    component:PopupSerialNoComponent,
  },
  
  {
    path:'home/popup-items-packed',
    component:PopupItemsPackedComponent,
    // data:{name:"popup-items-packed",isTab:false}
  },
  {
    path:'home/popup-add-instructions',
    component:PopupAddInstructionsComponent,
    // data:{name:"popup-add-instructions",isTab:false}
  },
  {
    path:'home/user-tab',
    component:UserTabComponent
  },
  {
    path:'user-tab',
    component:UserTabComponent
  },
  {
    path:'pop-up-branch',
    component:PopUpBranchComponent
  },
  {
    path:'pop-up-patient',
    component:PopUpPatientComponent
  },
  {
    path:'forgot-password',
    component:ForgotPasswordComponent
  },
  {
    path:'change-password',
    component:ChangePasswordComponent
  },
  {
    path:'pop-up-doctor',
    component:PopUpDoctorComponent
  },
  {
    path:'pop-up-vendor',
    component:PopUpVendorComponent
  },
  {
    path:'pop-up-fitted-by-us',
    component:PopUpFittedByUsComponent,
   },
  {
    path:'home/fitted-by-us',
    component:FittedByUsComponent,
    data:{name:"Migrated Data",isTab:true}
   },
  { 
    path:'login',
    component:LoginComponent
  },
  {
    path:'global-dashboard',
    component: SearchDashboardComponent
  }, 
  {
    path:'data-migration',
    component: DataMigrationComponent
  }, 
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
