import { Component, Inject, OnInit } from "@angular/core";
import { ApiServiceService } from '../api-service.service';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';

@Component({
  selector: 'app-popup-serial-no',
  templateUrl: './popup-serial-no.component.html',
  styleUrls: ['./popup-serial-no.component.scss']
})
export class PopupSerialNoComponent implements OnInit {
  PopupSerialNoForm: FormGroup;
  email;
  private formSumitAttempt: boolean;
  
  serialNoList =[];
  disableSubmit = false;
  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<PopupSerialNoComponent>,@Inject(MAT_DIALOG_DATA) public data:any,
    public apimicroservice: ApiServiceService) { 
      this.serialNoList = data.serialValue;
      dialogRef.disableClose = true;
    }
   
  ngOnInit() {
    //
    this.PopupSerialNoForm = this.fb.group({  
      "device": this.fb.array([]),
    });
    this.disableSubmit = false;
    if(this.serialNoList.length){
      this.prepopulation();
    }else{
      this.addNew();
    }
    
  }
  addNew() {
    const creds = this.PopupSerialNoForm.controls.device as FormArray;
        creds.push(
        this.fb.group({
          "serialNumber": new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9 -]+$")])
        //toggle: false
        
})
);
  }

  prepopulation(){
    this.serialNoList.forEach(element => {    
      const tmpDict = {};

      tmpDict['serialNumber'] = new FormControl(element,Validators.required);
      
      const creds = this.PopupSerialNoForm.controls.device as FormArray;
       creds.push(new FormGroup(tmpDict));
       //
      });
  }

  sumbitSerialNo(){
    const creds = this.PopupSerialNoForm.controls.device as FormArray;  
    if(creds.invalid)
    {
      creds.controls.forEach(element =>{
        element.get('serialNumber').markAsTouched();
      });
         
    }
    else{
     this.sumbitNumbers();
    }
  }

  sumbitNumbers(){
   const tempData = this.PopupSerialNoForm.controls.device.value;
   ////
   this.dialogRef.close({data:tempData});
  //  if(tempData.length == 1){
  //    if(tempData.serialNumber == ""){
  //     this.dialogRef.close();
  //    }else{
  //     this.dialogRef.close({data:tempData});
  //    }
  //  }else{
  //   this.dialogRef.close({data:tempData});
  //  }
   
  }
  deleteRow(index){
    (<FormArray>this.PopupSerialNoForm.get("device")).removeAt(index);
  }
  findDuplicate(srno,p):boolean{
    const creds = this.PopupSerialNoForm.controls.device as FormArray;
    let myArr = creds.controls;
    
    let test = myArr.filter(data => data['controls']['serialNumber'].value == srno && srno !== null)

    if(test.length > 1){
      this.disableSubmit = true;
      return true;
    }else{
      this.disableSubmit = false;
      return false;
    }

  }
  onClose(): void {
    this.dialogRef.close();
  }

}


