<div class="container-fluid " [formGroup]="patientMasterForm">
    <form [formGroup]="patientMasterForm" autocomplete="off" novalidate>
       
        <div class="row mtt">
          <div class="col-sm-12">    
                <div class="row">
                  <div class="col-sm-10">
                    <div class="row ">
                      <!-- <span><img class=" mt-0 pt-0" src="../../assets/patient.png" width="25rem"> </span>  -->
                      <span><h2 class="fass ml-3  mt-0 pt-0" id="toPatientForm">Add Patient</h2></span>
                    </div>
                    
                  </div>
               
                  <div class="col-sm-2"> 
                    <div class="row justify-content-end ">
                      <a (click)="controlClick()" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">settings</span>Control Panel</a>
                      <!-- <img matTooltip="Control Panel" class="mr-2" (click)="controlClick()" width="25rem" [routerLink]="['/home/generic-setting']"  src="../../assets/general settings.png">   -->
                      <a (click)="onClose()" matTooltip="Close" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">close</span></a> 
                    </div> 
                  </div>
                </div>
                <!-- <div class="row mtt">
                  <div class="col-sm-11">
                   
                      </div>
                <div class="col-sm-1 " >
                 
                  <div class="row justify-content-end ">
                    <img matTooltip="Control Panel" (click)="controlClick()" width="25rem" [routerLink]="['/home/generic-setting']"  src="../../assets/general settings.png">  
               
                 </div>
                 </div>
                 </div> -->
              
                    <div class="row ">
                      
                      <!-- <h1 class="titlecolor"> Patient Master</h1> -->
                      <div class="col-sm-2">
                        <!-- <h1 class="titlecolor"> Patient Master</h1> -->
                            <div class="col-sm-12 bg-secondary rounded-3 p-1 pb-4 ">
                             
                                
                              <div class="col-sm-12 ">
                                <img *ngIf="!patientImage" class=" img2 frame rounded-circle " src="../../assets/main.jpeg" alt="Amanda Wilson" width="500rem">
                                <img *ngIf="patientImage" class=" img2 frame rounded-circle " [src]="patientImage" alt="patient photo" width="500rem">
                                <!-- <button class="btn btn-light shadow btn-sm mb-2" type="button"><i class="ai-refresh-cw me-2"></i>Change avatar</button> -->
                                
                            </div>
                            <div class="col-sm-12 btnpossition "  >
                              <!-- <h1 class="fass p-0 m-0"> Patient Master</h1> -->
                              <input #fileUpload type="file" style="display: none;" (change)="onChange($event)" />
                              <button class="btn btn-light shadow btn-sm m-0 example-full-width"
                               matTooltip="Upload JPG, GIF or PNG image.300 x 300 required." 
                               (click)="fileUpload.click()" ><i class="ai-refresh-cw me-2" ></i>Upload</button>
                               <!-- <a [routerLink]="" class="p-0 m-0">Upload Photo</a>   -->
                              
                            </div>
                                
                              </div>
                        </div>
                      
                      <div class="col-sm-10">
                        <div class="row pt-1 pr-1">
                          <div class=" col-sm-3 ">
                            <div class="row">
                              <div class=" col-sm-6 ">
                            <!-- <mat-form-field *ngIf="userType !== 'Super Admin'" class="example-full-width" appearance="outline">
                              <mat-label>Patient Id</mat-label>
                              <input matInput formControlName="branchWiseId" readonly  />   
                            </mat-form-field> -->
                            <!-- <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Patient Idd</mat-label>
                              <input matInput [value]="superBranchId" readonly  />
                             
                            </mat-form-field> -->
                            <mat-label class="center mt-2" [value]="superBranchId" ngDefaultControl >{{superBranchId}}</mat-label>
                         
                            </div>
                            <div class=" col-sm-6 ">
                            <mat-form-field class="example-full-width " appearance="outline">
                              <mat-label>PreFix
                                <!-- <span class="required-asterisk">*</span> -->
                              </mat-label>
                              <mat-select  #select1 placeholder="PreFix" class="colorblack"  formControlName="prefix" >
                                <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'name'"
                                  [array]="allPreFix" (filteredReturn)="filteredListPreFix =$event"></mat-select-filter>
                                <mat-option *ngFor="let item of filteredListPreFix" 
                                 [value]="item.value">
                                  {{item.name}}
                                </mat-option>
                              </mat-select>
                            
                             
                            </mat-form-field>
                            <mat-error 
                            *ngIf="patientMasterForm.controls['prefix'].touched && patientMasterForm.controls['prefix'].errors?.required">
                            mandatory.
                          </mat-error>
                            </div>
                            </div>
                          </div>
                          <!-- <div class=" col-sm-1 ml-2 mr-2 pr-0 pl-0 ">
                            <mat-form-field class="example-full-width " appearance="outline">
                              <mat-label>PreFix</mat-label>
                              <mat-select required placeholder="PreFix" class="colorblack"  formControlName="prefix"   >
                                <mat-select-filter [placeholder]="'Filter'" [displayMember]="'name'"
                                  [array]="allPreFix" (filteredReturn)="filteredListPreFix =$event"></mat-select-filter>
                                <mat-option *ngFor="let item of filteredListPreFix" 
                                 [value]="item.value">
                                  {{item.name}}
                                </mat-option>
                              </mat-select>
                            
                              <mat-error
                                      *ngIf="patientMasterForm.controls['prefix'].touched && patientMasterForm.controls['prefix'].errors?.required">
                                      This field is mandatory.
                                    </mat-error>
                            </mat-form-field>
                          </div> -->
                         
                           <div class="col-sm-3">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>First Name
                                
                              </mat-label>
                              <input required matInput placeholder="First Name"  formControlName="firstName" />
                         
                            </mat-form-field>
                            <mat-error
                            *ngIf="patientMasterForm.controls['firstName'].touched && patientMasterForm.controls['firstName'].errors?.required">
                                This field is mandatory.
                          </mat-error>
                          <mat-error class="margin_tp"
                            *ngIf="patientMasterForm.controls['firstName'].touched && patientMasterForm.controls['firstName'].errors?.pattern">
                                Please enter valid first name.
                          </mat-error>
                          </div>
                          <div class=" col-sm-3">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Middle Name</mat-label>
                              <input required matInput placeholder="Middle Name" formControlName="middleName" />
                             
                            </mat-form-field>
                            <mat-error class="margin_tp"
                            *ngIf="patientMasterForm.controls['middleName'].touched && patientMasterForm.controls['middleName'].errors?.pattern">
                                Please enter valid middle name.
                          </mat-error>
                          <mat-error
                          *ngIf="patientMasterForm.controls['middleName'].touched && patientMasterForm.controls['middleName'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                          </div>
                           <div class="col-sm-3">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Last Name
                                <!-- <span class="required-asterisk">*</span> -->
                              </mat-label>
                              <input matInput placeholder="Last Name" formControlName="lastName" required/>
                              
                            </mat-form-field>
                            <mat-error
                              *ngIf="patientMasterForm.controls['lastName'].touched && patientMasterForm.controls['lastName'].errors?.required">
                              This field is mandatory.
                            </mat-error>
                            <mat-error class="margin_tp"
                                        *ngIf="patientMasterForm.controls['lastName'].touched && patientMasterForm.controls['lastName'].errors?.pattern">
                                        Please enter valid last name.
                                      </mat-error>
                          </div>
                          <div *ngIf="fieldsOfPatients.patientAlternateName !== 'No'" class="col-sm-3">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Alternate Name </mat-label>
                              <input  matInput placeholder="Alternate Name" formControlName="alternateName" />
                            
                            </mat-form-field>
                            <mat-error class="margin_tp"
                            *ngIf="patientMasterForm.controls['alternateName'].touched && patientMasterForm.controls['alternateName'].errors?.pattern">
                                Please enter valid Alternate name.
                          </mat-error>
                          </div>
                           <div *ngIf="fieldsOfPatients.patientDOB !== 'No'"  class="col-sm-3">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>DOB
                                <!-- <span class="required-asterisk">*</span> -->
                              </mat-label>
                              <input  formControlName="dob" matInput [matDatepicker]="dob" id="dob" 
                              [min]="minDate" [max]="currentdate"
                                placeholder="DD/MM/YYYY"  (dateChange)="ageCalculate()" >
       
                              <mat-datepicker-toggle matSuffix [for]="dob">
                              </mat-datepicker-toggle>
                              <mat-datepicker #dob></mat-datepicker>
                            
                            </mat-form-field>
                            <!-- <mat-error
                            *ngIf="patientMasterForm.controls['dob'].touched && patientMasterForm.controls['dob'].errors?.required">
                            This field is mandatory.
                          </mat-error> -->
                          </div>
                          
                          <div class="col-sm-3"> 
                             <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Age
                              
                            </mat-label>
                            <input  matInput placeholder="Age"  formControlName="age"  />
                        
                          </mat-form-field>
                        <mat-error
                            *ngIf="patientMasterForm.controls['age'].touched && patientMasterForm.controls['age'].errors?.pattern">
                            Enter Valid Age.
                          </mat-error>
                        </div>
                          <div *ngIf="fieldsOfPatients.patientClientSource !== 'No' " class="col-sm-3">
                            <div class="row">
                              <div class="col-sm-10 pr-1">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Patient Source </mat-label>
                              <mat-select  #select1 placeholder="Patient Source" class="colorblack"  formControlName="clientSource" >
                                <mat-select-filter  *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'patientSource'"
                                  [array]="allPatientSource" (filteredReturn)="filteredListPatientSource =$event"></mat-select-filter>
                                <mat-option  (click)="patientClick()" *ngFor="let item of filteredListPatientSource" 
                                 [value]="item.patientSource">
                                  {{item.patientSource}}
                                </mat-option>
                                
                              </mat-select>
                              <!-- <mat-select formControlName="clientSource">
                                <mat-option value="Audiologist">Audiologist</mat-option>
                                <mat-option value="Camp">Camp</mat-option>
                                <mat-option value="Chemist/Pharmacist">Chemist/Pharmacist</mat-option>
                                <mat-option value="Client">Client</mat-option>
                                <mat-option value="Deaf School">Deaf School</mat-option>
                                <mat-option value="Direct Mail">Direct Mail</mat-option>
                              </mat-select> -->
                            </mat-form-field>
                            </div>
                            <div class="col-sm-2 mt-2 mTop no-print">
                              <i (click)="openPatientSourcePopUp();" matTooltip="Add New Patient Source" 
                              class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                            </div>
                            </div>
                          </div>
  
                          <div *ngIf="isPatient" class="col-sm-3">
                       
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Patient Name </mat-label>
                              <mat-select  #select1 (click)="fetchAllPatientList();" formControlName="patientdropDown" >
                                <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'firstName'"
                                [array]="patientList"  (filteredReturn)="filteredListPatient =$event"></mat-select-filter>
                              <mat-option *ngFor="let item of filteredListPatient" (click)="patientNameClick(item.firstName,item.lastName)" [value]="item._id"
                              >
                               {{ item.firstName }}  {{ item.lastName }}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div> 
                          <div *ngIf="isDoctor" class="col-sm-3">
                       
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Doctor Name </mat-label>
                              <input matInput placeholder="Doctor Name"  formControlName="doctorDropDown"  />
                            </mat-form-field>
                          </div>
  
                          <div *ngIf="isAdvertiesment" class="col-sm-3">
                       
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label> Advertisement </mat-label>
                              <input matInput placeholder="advertisement"  formControlName="advertisement"  />
                            </mat-form-field>
                          </div>
                          <div *ngIf="isSocialMedia" class="col-sm-3">
                       
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label> Social Media </mat-label>
                              <input matInput placeholder="Social Media"  formControlName="socialMedia"  />
                            </mat-form-field>
                          </div>
                          <div *ngIf="isOther" class="col-sm-3">

                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label> Other </mat-label>
                              <input matInput placeholder="" formControlName="socialMedia" />
                            </mat-form-field>
                          </div>
                          
                          <!-- <div class="col-sm-3"></div> -->
                          <div class="col-sm-1 mt-3">
                            <mat-label  class="mt-3 lclass">Gender:</mat-label>
                          </div>
                          <div class="col-sm-3">
                            <mat-radio-group required formControlName="sex" class="example-full-width" aria-label="Select an option">
                              <div class="row">
                                  <div class="col-sm-4 p-1 mt-2">
                              <mat-radio-button class="ml-1" value="Male">Male</mat-radio-button>
                              </div>
                              <div class="col-sm-4 p-1 mt-2">
                            <mat-radio-button class="ml-1" value="Female">Female</mat-radio-button>
                            </div>
                            <div class="col-sm-4 p-1 mt-2">
                            <mat-radio-button class="ml-3" value="Other">Other</mat-radio-button>
                            </div>
                            </div>
                          </mat-radio-group>
                            <!-- <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Gender
                                
                              </mat-label>
                              <mat-select  formControlName="sex" required>
                                <mat-option value="Male">Male</mat-option>
                                <mat-option value="Female">Female</mat-option>
                                <mat-option value="Transgender">Transgender</mat-option>
                              </mat-select>
                             
                            </mat-form-field> -->
                            <mat-error
                            *ngIf="patientMasterForm.controls['sex'].touched && patientMasterForm.controls['sex'].errors?.required">
                            This field is mandatory.
                          </mat-error>
                          </div>
                           
                              <div class="col-sm-12 mt-1">
                              <label class="header mb-0 pb-0">Contact Details :</label>
                                </div>
                               
                                   <div *ngIf="fieldsOfPatients.patientLandline1 !== 'No'" class="col-sm-3">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                      <mat-label>Landline 1</mat-label>
                                      <input matInput maxlength="15" placeholder=" Landline eg 0223-666666" formControlName="landline1" />
                                    
                                    </mat-form-field>
                                    <mat-error class="margin_tp"
                                    *ngIf="patientMasterForm.controls['landline1'].touched && patientMasterForm.controls['landline1'].errors?.pattern">
                                        Please enter valid landline number.
                                  </mat-error>
                                  </div>
                                   <div *ngIf="fieldsOfPatients.patientLandline2 !== 'No'" class="col-sm-3">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                      <mat-label>Landline 2</mat-label>
                                      <input matInput maxlength="15" placeholder=" Landline eg 0223-666666" formControlName="landline2" />
                                  
                                    </mat-form-field>
                                    <mat-error class="margin_tp"
                                    *ngIf="patientMasterForm.controls['landline2'].touched && patientMasterForm.controls['landline2'].errors?.pattern">
                                        Please enter valid landline number.
                                  </mat-error>
                                  </div>
                                   <div class="col-sm-3">
                                    <div class="row">
                                      <div class="col-sm-10">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                      <mat-label> Mobile 1 
                                        <!-- <span class="required-asterisk">*</span> -->
                                      </mat-label>
                                      <input  matInput maxlength="10" (change)="changemob1()" placeholder="Mobile 1"
                                        formControlName="mobile1" />
  
                                    </mat-form-field>
                                    <mat-error class="margin_tp"
                                    *ngIf="patientMasterForm.controls['mobile1'].touched && patientMasterForm.controls['mobile1'].errors?.pattern">
                                        Please enter valid mobile number.
                                  </mat-error>
                                
                                  </div>
                                  <div class="col-sm-2 wApp">
                                    <i class="bi bi-whatsapp whatsapp-icon wApp fa-lg" (click)="WhatsApp();" matTooltip="WhatsApp Integration" ></i>
                                    </div>
                                  </div>
                                  </div>
                              
                                   <div *ngIf="fieldsOfPatients.patientMobile2 !== 'No'" class="col-sm-3">
                                    <div class="row">
                                      <div class="col-sm-10">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                      <mat-label>Mobile 2</mat-label>
                                      <input matInput  maxlength="10" (change)="changemob2()" placeholder="Mobile 2 " formControlName="mobile2" />
                                     
                                    </mat-form-field>
                                    <mat-error class="margin_tp"
                                    *ngIf="patientMasterForm.controls['mobile2'].touched && patientMasterForm.controls['mobile2'].errors?.pattern">
                                        Please enter valid mobile number.
                                  </mat-error>
                                  </div>
                                  <div class="col-sm-2 wApp">
                                    <i class="bi bi-whatsapp whatsapp-icon wApp fa-lg" (click)="WhatsApp2();" matTooltip="WhatsApp Integration" ></i>
                                    </div>
                                  </div>
                                  </div>
                                   <div *ngIf="fieldsOfPatients.patientMobile3 !== 'No'" class="col-sm-3">
                                    <div class="row">
                                      <div class="col-sm-10">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                      <mat-label>Mobile 3 </mat-label>
                                      <input matInput  maxlength="10" (change)="changemob3()" placeholder="Mobile 3" formControlName="mobile3" />
                                      
                                    </mat-form-field>
                                    <mat-error class="margin_tp"
                                      *ngIf="patientMasterForm.controls['mobile3'].touched && patientMasterForm.controls['mobile3'].errors?.pattern">
                                          Please enter valid mobile number.
                                    </mat-error>
                                    </div>
                                    <div class="col-sm-2 wApp">
                                      <i class="bi bi-whatsapp whatsapp-icon wApp fa-lg" (click)="WhatsApp3();" matTooltip="WhatsApp Integration" ></i>
                                      </div>
                                    </div>
                                  </div>
                                   <div *ngIf="fieldsOfPatients.patientMobile4 !== 'No'" class="col-sm-3">
                                    
                                    <div class="row">
                                      <div class="col-sm-10"><mat-form-field class="example-full-width" appearance="outline">
                                      <mat-label>Mobile 4</mat-label>
                                      <input matInput  maxlength="10" (change)="changemob4()" placeholder="Mobile 4" formControlName="mobile4" />
                                     
                                    </mat-form-field>
                                    <mat-error class="margin_tp"
                                    *ngIf="patientMasterForm.controls['mobile4'].touched && patientMasterForm.controls['mobile4'].errors?.pattern">
                                        Please enter valid mobile number.
                                  </mat-error>
                                  </div>
                                  <div class="col-sm-2 wApp">
                                    <i class="bi bi-whatsapp whatsapp-icon wApp fa-lg" (click)="WhatsApp4();" matTooltip="WhatsApp Integration" ></i>
                                    </div>
                             </div>
                             </div>
                                   <div *ngIf="fieldsOfPatients.patientMobile5 !== 'No'" class="col-sm-3">
                                    <div class="row">
                                      <div class="col-sm-10">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                      <mat-label>Mobile 5 </mat-label>
                                      <input matInput  maxlength="10" (change)="changemob5()" placeholder="Mobile 5" formControlName="mobile5" />
                                     
                                    
                                    </mat-form-field>
                                    <mat-error class="margin_tp"
                                    *ngIf="patientMasterForm.controls['mobile5'].touched && patientMasterForm.controls['mobile5'].errors?.pattern">
                                        Please enter valid mobile number.
                                  </mat-error>
                                  </div>
                                  <div class="col-sm-2 wApp">
                                    <i class="bi bi-whatsapp whatsapp-icon wApp fa-lg" (click)="WhatsApp5();" matTooltip="WhatsApp Integration" ></i>
                                    </div>
                                  </div>
                                  </div>
                                   <div *ngIf="fieldsOfPatients.patientEmail !== 'No'" class="col-sm-3">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                      <mat-label> Email Address</mat-label>
                                      <input matInput type="email" class="emailClass" placeholder=" Email" formControlName="email"  />
                                     
                                    </mat-form-field>
                                    <mat-error class="margin_tp"
                                    *ngIf="patientMasterForm.controls['email'].touched && patientMasterForm.controls['email'].errors?.email">
                                        Please enter valid email.
                                  </mat-error>
                                  <mat-error class="margin_tp"
                                  *ngIf="patientMasterForm.controls['email'].touched && patientMasterForm.controls['email'].errors?.pattern">
                                  Please enter valid email.
                                </mat-error>
                                  </div>
                               
  
                        </div>
                      </div>
                    </div>  
                      <div class="col-sm-12">
                          <div class="row ">
                          
                            <div class="col-sm-12">
                            
                              <div class="row">
                                <div class="col-sm-12">
                              <label class="header mb-0 pb-0">Address Details :</label>
                                </div>
                                </div>

                                  <div class="row">
                                    <div class=" col-sm-3" *ngIf="fieldsOfPatients.patientStreetAddress !== 'No'">
                                      <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>Street Address
                                          <!-- <span class="required-asterisk">*</span> -->
                                        </mat-label>
                                        <input  matInput placeholder="Address" formControlName="streetAddress" />
                                      
                                      </mat-form-field>
                                 
                                    </div>
                                    <div *ngIf="fieldsOfPatients.patientTaluka !== 'No'" class=" col-sm-3 ">
                                      <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>Taluka</mat-label>
                                        <input matInput placeholder="Taluka" formControlName="taluka" />
                                       
                                      </mat-form-field>
                                      <mat-error class="margin_tp"
                                      *ngIf="patientMasterForm.controls['taluka'].touched && patientMasterForm.controls['taluka'].errors?.pattern">
                                          Please enter valid taluka.
                                    </mat-error>
                                    </div>
                                    <div *ngIf="fieldsOfPatients.patientDistrict !== 'No'" class=" col-sm-3  ">
                                      <!-- <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>District</mat-label>
                                        <input matInput placeholder="District" formControlName="district" />
                                       
                                      </mat-form-field> -->
                                      <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>District</mat-label>
                                        <!-- <input matInput placeholder="District" formControlName="district" /> -->
                                        <mat-select  #select1 placeholder="District" class="colorblack"  formControlName="district"   >
                                          <mat-select-filter  *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'district'"
                                            [array]="districtData" (filteredReturn)="filteredListDistrict =$event"></mat-select-filter>
                                          <mat-option *ngFor="let item of filteredListDistrict" 
                                           [value]="item.district" >
                                            {{item.district}}
                                          </mat-option>
                                        </mat-select>
                                      </mat-form-field>

                                    </div>
                                    <div *ngIf="fieldsOfPatients.patientState !== 'No'" class=" col-sm-3  ">
                                      <!-- <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>State</mat-label>
                                        <mat-select #select3 placeholder="State" class="colorblack"  formControlName="state"   >
                                          <mat-select-filter *ngIf="select3.focused" [placeholder]="'Filter'" [displayMember]="'name'"
                                            [array]="allState"  (filteredReturn)="filteredList =$event"></mat-select-filter>
                                          <mat-option *ngFor="let item of filteredList" 
                                           [value]="item.value"
                                         
                                           >
                                            {{item.name}}
                                          </mat-option>
                                        </mat-select>
                                      
                                      </mat-form-field> -->
                                      <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>State</mat-label>
                                        <mat-select  #select1 placeholder="State" class="colorblack"  formControlName="state"   >
                                          <mat-select-filter  *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'state'"
                                            [array]="stateCityArr"  (filteredReturn)="filteredList1 =$event"></mat-select-filter>
                                          <mat-option *ngFor="let item of filteredList1" 
                                           [value]="item.state" (click)="getDistrict(item.districts)">
                                            {{item.state}}
                                          </mat-option>
                                        </mat-select>
                                      
                                      </mat-form-field>
                                    
                                    </div>
                                    <div class=" col-sm-3  " *ngIf="fieldsOfPatients.patientPincode !== 'No'">
                                      <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>Pincode 
                                          <!-- <span class="required-asterisk">*</span> -->
                                        </mat-label>
                                        <input maxLength="6"  matInput placeholder="Pincode(e.g:416008)" formControlName="pincode" />
                                     
                                      
                                      </mat-form-field>
                                      <mat-error class="margin_tp"
                                      *ngIf="patientMasterForm.controls['pincode'].touched && patientMasterForm.controls['pincode'].errors?.pattern">
                                      Enter only number.
                                    </mat-error>
                                    <mat-error class="margin_tp"
                                      *ngIf="patientMasterForm.controls['pincode'].touched && patientMasterForm.controls['pincode'].errors?.maxlength">
                                      Enter 6 digit Pincode.
                                    </mat-error>
                                    <mat-error class="margin_tp"
                                      *ngIf="patientMasterForm.controls['pincode'].touched && patientMasterForm.controls['pincode'].errors?.minlength">
                                      Enter 6 digit Pincode.
                                    </mat-error>
                                    
                                     
                                    <!-- <mat-error class="margin_tp"
                                    *ngIf="patientMasterForm.controls['pincode'].touched && patientMasterForm.controls['pincode'].errors?.required">
                                    This field is mandatory.
                                  </mat-error> -->
                                    </div>
                                    <div *ngIf="fieldsOfPatients.patientCountry !== 'No'" class=" col-sm-3  ">
                                      <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>Country</mat-label>
                                        <input matInput placeholder="Country" formControlName="country" />
                                       
                                      </mat-form-field>
                                      <mat-error class="margin_tp"
                                      *ngIf="patientMasterForm.controls['country'].touched && patientMasterForm.controls['country'].errors?.pattern">
                                          Please enter valid country.
                                    </mat-error>
                                    </div>
                                   
                               
                               
                                <div *ngIf="fieldsOfPatients.patientAltAddress !== 'No'" class=" col-sm-4 mt-3">
                                  
                                  <mat-checkbox class="example-margin header" formControlName="altAddress"  [checked]="isChecked" (change)="onChangeAltAddress($event)"
                                  > Alternate Address
                                 </mat-checkbox>
                                </div>
                              <!-- <div class="col-sm-6"></div> -->
                                
                               
                              </div>
                            
  
                           
                                <div *ngIf="altAddressCheck" class="row">
                              <div class="col-sm-12">
                                <label class="header">Alternate Address Details :</label>
                                  </div>
                            </div>
                             <div class="row">
                              <div *ngIf="altAddressCheck" class=" col-sm-3  ">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Street Address</mat-label>
                                  <input matInput placeholder="Address" formControlName="streetAddress2" />
                                 
                                </mat-form-field>
                              </div>
                              <div *ngIf="altAddressCheck" class=" col-sm-3  ">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Taluka</mat-label>
                                  <input matInput placeholder="Taluka" formControlName="taluka2" />
                                 
                                </mat-form-field>
                                <mat-error class="margin_tp"
                                *ngIf="patientMasterForm.controls['taluka2'].touched && patientMasterForm.controls['taluka2'].errors?.pattern">
                                    Please enter valid taluka.
                              </mat-error>
                              </div>
                              <div *ngIf="altAddressCheck" class=" col-sm-3  ">
                                <!-- <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>District</mat-label>
                                  <input matInput placeholder="District" formControlName="district2" />
                                 
                                </mat-form-field> -->
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>District</mat-label>
                                  <!-- <input matInput placeholder="District" formControlName="district" /> -->
                                  <mat-select #select1 placeholder="District" class="colorblack"  formControlName="district2"   >
                                    <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'district'"
                                      [array]="districtData2" (filteredReturn)="filteredListDistrict2 =$event"></mat-select-filter>
                                    <mat-option *ngFor="let item of filteredListDistrict2" 
                                     [value]="item.district" >
                                      {{item.district}}
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                                <mat-error class="margin_tp"
                                *ngIf="patientMasterForm.controls['district2'].touched && patientMasterForm.controls['district2'].errors?.pattern">
                                    Please enter valid district.
                              </mat-error>
                              </div>
                              <div *ngIf="altAddressCheck" class=" col-sm-3  ">
                                <!-- <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>State</mat-label>
                                  <mat-select #select4 placeholder="State" class="colorblack"  formControlName="state2"   >
                                    <mat-select-filter *ngIf="select4.focused" [placeholder]="'Filter'" [displayMember]="'name'"
                                      [array]="allState"  (filteredReturn)="filteredList =$event"></mat-select-filter>
                                    <mat-option *ngFor="let item of filteredList" 
                                     [value]="item.value"
                                   
                                     >
                                      {{item.name}}
                                    </mat-option>
                                  </mat-select>
                                
                             
                                </mat-form-field> -->
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>State</mat-label>
                                  <mat-select  #select1 placeholder="State" class="colorblack"  formControlName="state2" >
                                    <mat-select-filter  *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'state'"
                                      [array]="stateCityArr2"  (filteredReturn)="filteredList2 =$event"></mat-select-filter>
                                    <mat-option *ngFor="let item of filteredList2" 
                                     [value]="item.state" (click)="getDistrict2(item.districts)">
                                      {{item.state}}
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>

                              </div>
                              <div *ngIf="altAddressCheck" class=" col-sm-3  ">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Pincode 
                                    
                                  </mat-label>
                                  <input  matInput maxlength="6" placeholder="Pincode(e.g:416008)" formControlName="pincode2" />
                               
                                
                                </mat-form-field>
                                <mat-error class="margin_tp"
                                *ngIf="patientMasterForm.controls['pincode2'].touched && patientMasterForm.controls['pincode2'].errors?.pattern">
                                Enter only number.
                              </mat-error>
                              <mat-error class="margin_tp"
                                *ngIf="patientMasterForm.controls['pincode2'].touched && patientMasterForm.controls['pincode2'].errors?.maxlength">
                                Enter 6 digit Pincode.
                              </mat-error>
                              <mat-error class="margin_tp"
                                *ngIf="patientMasterForm.controls['pincode2'].touched && patientMasterForm.controls['pincode2'].errors?.minlength">
                                Enter 6 digit Pincode.
                              </mat-error>
                                <mat-error class="margin_tp"
                                *ngIf="patientMasterForm.controls['pincode2'].touched && patientMasterForm.controls['pincode2'].errors?.pattern">
                                    Please enter valid pincode.
                              </mat-error>
                              </div>
                              <div *ngIf="altAddressCheck" class=" col-sm-3  ">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Country</mat-label>
                                  <input matInput placeholder="Country" formControlName="country2" />
                                 
                                </mat-form-field>
                                <mat-error class="margin_tp"
                                *ngIf="patientMasterForm.controls['country2'].touched && patientMasterForm.controls['country2'].errors?.pattern">
                                    Please enter valid country.
                              </mat-error>
                              </div>
                            
                           
                             
                      
                            
                            </div>
                          </div>
  
                            <div class="col-sm-12">
                              
                                  <div class="row">
                                    <div class="col-sm-12">
                                  <label class="header mb-0 pb-0">Other Details :</label>
                                    </div>
                                    </div>
                                    <div class="row">
                                      <div *ngIf="fieldsOfPatients.patientOccupation !== 'No'" class=" col-sm-3  ">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>Occupation</mat-label>
                                          <mat-select #select5 placeholder="Occupation" class="colorblack"  formControlName="occupation"   >
                                            <mat-select-filter *ngIf="select5.focused" [placeholder]="'Filter'" [displayMember]="'name'"
                                              [array]="allOccupation" (filteredReturn)="filteredListOccupation =$event"></mat-select-filter>
                                            <mat-option *ngFor="let item of filteredListOccupation" 
                                             [value]="item.value" (click)="occupationClick()">
                                              {{item.name}}
                                            </mat-option>
                                          </mat-select>
                                        
                                        </mat-form-field>
                                        
                                      </div>
                                      <div *ngIf="otherOccupation" class=" col-sm-3  ">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>Other Occupation</mat-label>
                                          <input matInput placeholder="Other" formControlName="otherOccupation" />
                                         
                                        </mat-form-field>
                                        <mat-error class="margin_tp"
                                        *ngIf="patientMasterForm.controls['otherOccupation'].touched && patientMasterForm.controls['otherOccupation'].errors?.pattern">
                                            Please enter valid occupation.
                                      </mat-error>
                                      </div>
                                      <div *ngIf="fieldsOfPatients.patientAadhaarNo !== 'No'" class=" col-sm-3  ">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>Aadhaar Card Number</mat-label>
                                          <input matInput placeholder="e.g(9999-9999-9999)" formControlName="aadhaarNo" />
                                        
                                        </mat-form-field>
                                        <mat-error class="margin_tp"
                                        *ngIf="patientMasterForm.controls['aadhaarNo'].touched && patientMasterForm.controls['aadhaarNo'].errors?.pattern">
                                            Please enter valid aadhaar card number.
                                      </mat-error>
                                      </div>
                                      <div class=" col-sm-3  ">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>Patient Status</mat-label>
                  
                                          <mat-select formControlName="clientStatus">
                                            <mat-option value="Active">Active</mat-option>
                                            <mat-option value="In-active">In-active</mat-option>
                                          </mat-select>
                                        </mat-form-field>
                                      </div>
                                      <div *ngIf="fieldsOfPatients.patientNotes !== 'No'" class="col-sm-6  ">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>Notes</mat-label>
                                  
                                          <textarea matInput formControlName="notes" maxlength="1000"
                                          cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                          cdkAutosizeMinRows="2" cdkAutosizeMaxRows="5" ></textarea>
                                        </mat-form-field>
                                      </div>
                                    </div>
                                   
                                   
                                   
                                  </div>
                                  
                                  <!-- <div *ngIf="isSuperAdmin" class="col-sm-12"> 
                              
                                    <div class="row">
                                      <div class="col-sm-12">
                                    <label class="header mb-0 pb-0">Select Branch :</label>
                                      </div>
                                      </div>
                                      <div class="row mt-0 mb-0 pt-0 pb-0">
  
                                  <div class="col-sm-3">
                                    <div class="row">
                                        <div class="col-sm-10 pr-1">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                      <mat-label>Branch 
                                       
                                      </mat-label>
                                      <mat-select required #select6 (click)="fetchBranchList();" formControlName="branchRefId" >
                                        <mat-select-filter *ngIf="select6.focused" [placeholder]="'Filter'" [displayMember]="'branchName'"
                                        [array]="BranchList"  (filteredReturn)="filteredListBranch =$event"></mat-select-filter>
                                        <mat-option *ngFor="let item of filteredListBranch" [value]="item._id" 
                                        (click)="branchClick(item._id,item.branchName);"
                                        >
                                         {{ item.branchName }}</mat-option>
                                        </mat-select>
                                       
                                    </mat-form-field>
                                    <mat-error class="margin_tp"
                                    *ngIf="patientMasterForm.controls['branchRefId'].touched && patientMasterForm.controls['branchRefId'].errors?.required">
                                    This field is mandatory.
                                  </mat-error>
                                   </div>
                                   <div class="col-sm-2 mTop">
                                    <img (click)="openBranchPopUp();" matTooltip="Add New Branch" width="25" src="../../assets/add_img.png"> 
                                  </div>
                                   </div>
                                  </div>
                       
                             </div>
                             </div> -->
                             <div *ngIf="isDecease" class="col-sm-12"> 
                            
                              <div class="row">
                                <div class="col-sm-2">
                              <label class="header mt-3">Is Decease :</label>
                                </div>
                                <div class="col-sm-2">
                                  <mat-form-field class="example-full-width " appearance="outline">
                                    <mat-label>Decease</mat-label>
                                  <mat-select formControlName="decease">
                                    <mat-option value="Yes">Yes</mat-option>
                                    <mat-option value="No">No</mat-option>
                                 
                                  </mat-select>
                                  </mat-form-field>
                                  
                                </div>
                                </div>
                               
                       </div>
                             <div class="col-sm-10" *ngIf="submitButton"></div>
                             <div class="col-sm-8" *ngIf="updateButton"></div>
                             <div class="col-sm-2" *ngIf="submitButton && patientCreate">
                               <button  class="btn btn-sm btn-primary example-full-width mt-2"
                               (click)="submitPatient()" type="button">Save </button>
                              </div>
                           
                              <div class="col-sm-2" *ngIf="updateButton" >
                                <button *ngIf="patientUpdate" class="btn btn-sm btn-primary  mt-2 example-full-width"
                                (click)="updatePatientDetails()" type="button">Update </button>
                               </div>
                               <div class="col-sm-2" *ngIf="updateButton">
                                <button class="btn btn-primary btn-sm  mt-2 example-full-width"  (click)="cancelUpdate()" type="reset" >Cancel </button>
                               </div>
                          </div>
                       
                        
                      </div>
                </div>
          </div>
      
    </form>
  </div>