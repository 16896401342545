<div class="container-fluid " [formGroup]="popupAddDeviceForm">
  <form [formGroup]="popupAddDeviceForm" autocomplete="off" novalidate>
    <div class="row">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-2">
            <div class="row ">
              <span>
                <h1 class="fass ml-3 pt-1" id="topopupAddDeviceForm">Add HA Bill</h1>
              </span>
            </div>

          </div>
          <div class="col-sm-8">

            <div *ngIf="pagename === 'Billing Page'" class="row">

              <div *ngIf="userType == 'Super Admin'" class="col-sm-4 mb-2">
                <div class="row">
                  <div class="col-sm-10 pr-1">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Branch
                        <!-- <span class="required-asterisk">*</span> -->
                      </mat-label>
                      <mat-select required #select1 formControlName="branchRefId">
                        <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'"
                          [displayMember]="'branchName'" [array]="BranchList"
                          (filteredReturn)="filteredListBranch =$event"></mat-select-filter>

                        <mat-option *ngFor="let item of filteredListBranch" [value]="item._id"
                          (click)="branchClick(item._id,item.branchName);">
                          {{ item.branchName }}</mat-option>
                      </mat-select>

                    </mat-form-field>
                    <mat-error class="margin_tp"
                      *ngIf="popupAddDeviceForm.controls['branchRefId'].touched && popupAddDeviceForm.controls['branchRefId'].errors?.required">
                      This field is mandatory.
                    </mat-error>
                  </div>
                  <div class="col-sm-2 mt-2 mTop no-print">
                    <i (click)="openBranchPopUp();" matTooltip="Add New Branch"
                      class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                  </div>
                </div>
              </div>

              <div *ngIf="patientCreate" class="col-sm-4">
                <div class="row">
                  <div class="col-sm-10 pr-1">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Select Patient </mat-label>

                      <input (click)="openPatientSearchPopUp();" matInput readonly placeholder="Patient Name"
                        formControlName="patientName" />

                    </mat-form-field>

                    <mat-error class="margin_tp"
                      *ngIf="popupAddDeviceForm.controls['patientName'].touched && popupAddDeviceForm.controls['patientName'].errors?.required">
                      This field is mandatory.
                    </mat-error>
                  </div>
                  <div *ngIf="way!==2" class="col-sm-2 mt-2 mTop no-print">
                    <i (click)="openPatientPopUp();" matTooltip="Add New Patient"
                      class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                  </div>
                </div>
              </div>
              <div *ngIf="!patientCreate" class="col-sm-4">
                <div class="row">
                  <div class="col-sm-12 pr-1">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Select Patient </mat-label>
                      <!-- <mat-select #select2 formControlName="patientRefId">

                            <mat-select-filter *ngIf="select2.focused" [placeholder]="'Filter'" [displayMember]="'firstName'"
                            [array]="patientList"  (filteredReturn)="filteredListPatient =$event"></mat-select-filter>
                            
                            <mat-option  *ngFor="let item of filteredListPatient" [value]="item._id" (click)="onSelectPatient(item)"
                          >  
                           {{ item.firstName }}</mat-option>

                          </mat-select> -->
                      <input (click)="openPatientSearchPopUp();" matInput readonly placeholder="Patient Name"
                        formControlName="patientName" />
                    </mat-form-field>
                    <mat-error class="margin_tp"
                      *ngIf="popupAddDeviceForm.controls['patientName'].touched && popupAddDeviceForm.controls['patientName'].errors?.required">
                      This field is mandatory.
                    </mat-error>
                  </div>

                </div>
              </div>
              <div *ngIf="haDisplay == true" class="col-sm-4">

                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Select HA </mat-label>
                  <mat-select #select2 formControlName="haRefId">

                    <mat-select-filter *ngIf="select2.focused" [placeholder]="'Filter'" [displayMember]="'firstName'"
                      [array]="haList" (filteredReturn)="filteredListHa =$event"></mat-select-filter>

                    <mat-option *ngFor="let item of filteredListHa" [value]="item._id" (click)="haClick(item.hAId)">
                      {{ item.hAId }}</mat-option>

                  </mat-select>
                </mat-form-field>


              </div>
              <!-- <div class="col-sm-1 pr-1">
                          <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                            <a (click)="onClose()" matTooltip="Close" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">close</span></a>
                            </div>
                        </div> -->

            </div>
            <div *ngIf="pagename === 'Todays Apt' && haDisplay == true" class="row">
              <div class="col-sm-4">

                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Select HA </mat-label>
                  <mat-select #select2 formControlName="haRefId">

                    <mat-select-filter *ngIf="select2.focused" [placeholder]="'Filter'" [displayMember]="'firstName'"
                      [array]="haList" (filteredReturn)="filteredListHa =$event"></mat-select-filter>

                    <mat-option *ngFor="let item of filteredListHa" [value]="item._id" (click)="haClick(item.hAId)">
                      {{ item.hAId }}</mat-option>

                  </mat-select>
                </mat-form-field>


              </div>
            </div>


          </div>
          <div class="col-sm-2 justify-content-end">
            <div class="row pt-1 pb-1 justify-content-end">

              <a (click)="onClose()" matTooltip="Close" class="d-flex align-items-center pr-2 mt-2"><span
                  class="material-symbols-outlined pr-2">close</span></a>

            </div>
          </div>



        </div>
        <div class="col-sm-12 pl-0">

          <div class="row">
            <div class="col-sm-12 table-responsive">
              <table id="toBillingLst" class="table table-bordered data-table-custom">
                <thead>
                  <tr class="text-center">
                    <th>Select Device</th>
                    <th>Serial Number</th>
                    <th>Qty</th>
                    <th>MRP</th>
                    <th>Discount Type</th>
                    <th>Discount</th>
                    <th>Ear</th>
                    <th>Action</th>

                  </tr>
                </thead>
                <tbody formArrayName="deviceDetails">

                  <tr [formGroupName]="i"
                    *ngFor="let row of popupAddDeviceForm.get('deviceDetails')['controls']; let i = index; let last = last"
                    role="row" class="odd ">
                    <td class=" mt-0 pt-0 mb-0 pb-0 pr-1 mr-1 pl-1 ml-1">
                      <mat-form-field *ngIf="submitButton" class=" widthsize2  mt-2 pt-0 pr-2 pl-2 pb-0"
                        appearance="outline">
                        <mat-label>Model </mat-label>
                        <mat-select #select4 formControlName="deviceRefId">
                          <mat-select-filter *ngIf="select4.focused" [placeholder]="'Filter'" [displayMember]="'model'"
                            [array]="DeviceList2" (filteredReturn)="filteredListDevice2 =$event"></mat-select-filter>

                          <mat-option *ngFor="let item of filteredListDevice2" (click)="clickModel(item,i)"
                            [value]="item._id">
                            {{ item.model }}</mat-option>
 
                        </mat-select>

                      </mat-form-field>
                      <mat-form-field *ngIf="updateButton" class=" widthsize2  mt-2 pt-0 pr-2 pl-2 pb-0"
                        appearance="outline">
                        <mat-label>Model </mat-label>
                        <mat-select #select4 formControlName="deviceRefId">
                          <mat-select-filter *ngIf="select4.focused" [placeholder]="'Filter'" [displayMember]="'model'"
                            [array]="DeviceList2" (filteredReturn)="filteredListDevice2 =$event"></mat-select-filter>

                          <mat-option *ngFor="let item of filteredListDevice2" (click)="clickModel2(item,i,'update')"
                            [value]="item._id">
                            {{ item.model }}</mat-option>
                        </mat-select>

                      </mat-form-field>
                      <mat-error class="margin_tp"
                        *ngIf="row.controls.deviceRefId.touched && row.controls.deviceRefId.errors?.required">
                        This Field is Mandatory
                      </mat-error>
                    </td>
                    <td class=" mt-0 pt-0 mb-0 pb-0 pr-1 mr-1 pl-1 ml-1">
                      <mat-form-field class=" widthsize2 mt-2 pt-0 pr-2 pl-2 pb-0" appearance="outline">
                        <mat-label class="lable_style2">Serial Number</mat-label>

                        <mat-select formControlName="serialNumber">
                          <mat-option *ngFor="let item of serialNoList[i]" [value]="item.serialNumber"
                            (click)="serialClick(i,item.serialNumber)">{{ item.serialNumber }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </td>
                    <td class=" mt-0 pt-0 mb-0 pb-0 pr-1 mr-1 pl-1 ml-1">
                      <mat-form-field class=" widthsize mt-2 pt-0 pr-2 pl-2 pb-0" appearance="outline">
                        <mat-label class="lable_style2">Qty</mat-label>
                        <input placeholder="Qty" formControlName="qty" type="text" readonly matInput />

                      </mat-form-field>

                    </td>
                    <td class=" mt-0 pt-0 mb-0 pb-0 pr-1 mr-1 pl-1 ml-1">
                      <mat-form-field class=" widthsize mt-2 pt-0 pr-2 pl-2 pb-0" appearance="outline">
                        <mat-label class="lable_style2">MRP</mat-label>
                        <input placeholder="MRP" formControlName="mrp" type="text" maxlength="7" matInput />

                      </mat-form-field>
                      <mat-error class="margin_tp"
                        *ngIf="row.controls.mrp.touched && row.controls.mrp.errors?.required">
                        This Field is Mandatory
                      </mat-error>
                      <mat-error class="margin_tp" *ngIf="row.controls.mrp.touched && row.controls.mrp.errors?.pattern">
                        Please enter valid mrp.
                      </mat-error>
                      <mat-error class="margin_tp"
                        *ngIf="row.controls.mrp.touched && row.controls.mrp.errors?.minlength">
                        Enter minimum 4 digit mrp.
                      </mat-error>


                    </td>
                    <td class=" mt-0 pt-0 mb-0 pb-0 pr-1 mr-1 pl-1 ml-1">
                      <mat-form-field class=" widthsize2 mt-2 pt-0 pr-2 pl-2 pb-0" appearance="outline">
                        <mat-label>Discount In</mat-label>
                        <mat-select formControlName="discountType">
                          <mat-option value="Rupees">Rupees</mat-option>
                          <mat-option value="Percentage">Percentage</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-error class="margin_tp"
                        *ngIf="row.controls.discountType.touched && row.controls.discountType.errors?.required">
                        This Field Mandatory</mat-error>
                    </td>
                    <td class=" mt-0 pt-0 mb-0 pb-0 pr-1 mr-1 pl-1 ml-1">
                      <mat-form-field class=" widthsize3 mt-2 pt-0 pr-2 pl-2 pb-0" appearance="outline">
                        <mat-label class="lable_style2">Discount</mat-label>
                        <input placeholder="Discount" formControlName="discount" required type="text" maxlength="6"
                          matInput />

                      </mat-form-field>
                      <mat-error class="margin_tp"
                        *ngIf="row.controls.discount.touched && row.controls.discount.errors?.required">
                        This Field Mandatory</mat-error>
                      <mat-error class="margin_tp"
                        *ngIf="row.controls.discount.touched && row.controls.discount.errors?.pattern">
                        Please enter valid discount.
                      </mat-error>
                      <!-- <mat-error class="margin_tp"
                                *ngIf="row.controls.discount.touched && row.controls.discount.errors?.minlength">
                                    Enter minimum 2 digit discount.
                              </mat-error> -->

                    </td>
                    <td class=" mt-0 pt-0 mb-0 pb-0 pr-1 mr-1 pl-1 ml-1">
                      <mat-form-field class=" widthsize2 mt-2 pt-0 pr-2 pl-2 pb-0" appearance="outline">
                        <mat-label class="lable_style2">Ear</mat-label>

                        <mat-select formControlName="earStyle">
                          <mat-option value="left">Left</mat-option>
                          <mat-option value="right">Right</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </td>
                    <td class=" text-center no-print mt-0 pt-0 mb-0 pb-0 pr-1 mr-1 pl-1 ml-1">

                      <div class="row">
                        <div class=" col-sm-6  ml-0 pl-0">
                          <button mat-button mat-icon-button class="ml-0 pl-0 " *ngIf="last" (click)="addRow(i);"
                            [disabled]="popupAddDeviceForm.controls.deviceDetails.invalid || popupAddDeviceForm.disabled">
                            <img matTooltip="Add" class="mt-1 " width="25" src="../../assets/add.png">
                          </button>
                        </div>
                        <div class=" col-sm-6 ml-0 pl-0">
                          <button mat-button mat-icon-button (click)="deleteRow(i,row.value.serialNumber);"
                            [disabled]="popupAddDeviceForm.get('deviceDetails')['controls']['length']===1 || popupAddDeviceForm.disabled">
                            <img matTooltip="Remove" class="mt-1 ml-0 pl-0" width="25" src="../../assets/dustbin.png">
                          </button>
                        </div>
                      </div>


                    </td>

                  </tr>
                </tbody>

              </table>

            </div>
            <!-- <div class="col-sm-12 pt-0 text-right">
                                        <pagination-controls id="typeMaster" directionLinks="true"
                                          (pageChange)="onPageChange($event)" class="float-right pt-3 my-pagination">
                                        </pagination-controls>
                                      </div> -->

            <div *ngIf="isBilling == 'true'" class="row col-sm-12">
              <div class="col-sm-8" *ngIf="submitButton"></div>
              <div class="col-sm-8" *ngIf="updateButton"></div>

 
              <div class="col-sm-2" *ngIf="updateButton">
                <button type="button" class="btn btn-sm btn-primary example-full-width" (click)="update()">Update
                </button>

              </div>
              <div class="col-sm-2" *ngIf="submitButton">
                <button type="button" class="btn btn-primary btn-sm example-full-width"
                  (click)="openConfirmationMsg('draft')">Save Draft</button>
              </div>
              <!-- <div class="col-sm-2" >
                                          <button type="button" class="btn btn-primary btn-sm example-full-width" 
                                          (click)="openConfirmationMsg('save')">Generate Bill</button>
                                         </div> -->

              <div class="col-sm-2">

                <button type="button" class="btn btn-sm btn-primary example-full-width" (click)="onClose()"
                  type="reset">Cancel </button>

              </div>
              <!-- <div class="col-sm-4" *ngIf="updateButton">
                                          <button class="btn btn-primary btn-sm  example-full-width"  (click)="onClose()" type="reset" >Reset </button>
                                         </div> -->
            </div>
            <div *ngIf="isBilling !== 'true'" class="row col-sm-12 pr-3">
              <div class="col-sm-11" *ngIf="submitButton"></div>
              <div class="col-sm-6" *ngIf="updateButton"></div>
              <div class="col-sm-1 " *ngIf="submitButton">
                <button type="button" class="btn btn-primary btn-sm example-full-width"
                  (click)="openConfirmationMsg('save')">Save</button>
              </div>
              <!-- <div class="col-sm-3" *ngIf="updateButton" >
                                          <button type="button" class="btn btn-sm btn-primary example-full-width"
                                          (click)="updateDeviceBill()" >Update </button>
                                         
                                          
                                         </div>
                                         <div class="col-sm-3" *ngIf="updateButton" >
                                      
                                          <button type="button" class="btn btn-sm btn-primary example-full-width"
                                        >Cancel </button>
                                          
                                         </div> -->
              <!-- <div class="col-sm-4" *ngIf="updateButton">
                                          <button class="btn btn-primary btn-sm  example-full-width"  (click)="onClose()" type="reset" >Reset </button>
                                         </div> -->
            </div>

            <div class="col-sm-10 ">
            </div>


          </div>



        </div>


      </div>
    </div>
  </form>
</div>