import { Component, Inject, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { AfterViewInit, Directive, ViewChild } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PopupBrandComponent } from "../popup-brand/popup-brand.component";
import { NgxSpinnerService } from "ngx-spinner";
import { PopUpBranchComponent } from "../pop-up-branch/pop-up-branch.component";
import { PatientSearchPopComponent } from "../patient-search-pop/patient-search-pop.component";
import { PopUpDeviceComponent } from "../pop-up-device/pop-up-device.component";
import { PopupBillingcompanyComponent } from "../popup-billingcompany/popup-billingcompany.component";
import { PopUpPatientComponent } from "../pop-up-patient/pop-up-patient.component";
@Component({
  selector: 'app-fitted-by-us',
  templateUrl: './fitted-by-us.component.html',
  styleUrls: ['./fitted-by-us.component.scss']
})
export class FittedByUsComponent implements OnInit {
  errormessage: string;
  vid: any;
  searchText: any;
  totalLength: number;
  page: number = 1;
  size: number = 10;
  listOfVendors: any;
  // vendorBrandAdd = true;
  // vendorBrandEdit = false;
  allState = [
    { name: 'Andaman and Nicobar Islands', value: 'AN' },
    { name: 'Andhra Pradesh', value: 'AP' },
    { name: 'Arunachal Pradesh', value: 'AR' },
    { name: 'Assam', value: 'AS' },
    { name: 'Bihar', value: 'BR' },
    { name: 'Chandigarh', value: 'CH' },
    { name: 'Chhattisgarh', value: 'CT' },
    { name: 'Dadra and Nagar Haveli', value: 'DN' },
    { name: 'Daman and Diu', value: 'DD' },
    { name: 'Delhi', value: 'DL' },
    { name: 'Goa', value: 'GA' },
    { name: 'Gujarat', value: 'GJ' },
    { name: 'Haryana', value: 'HR' },
    { name: 'Himachal Pradesh', value: 'HP' },
    { name: 'Jammu and Kashmir', value: 'JK' },
    { name: 'Jharkhand', value: 'JH' },
    { name: 'Karnataka', value: 'KA' },
    { name: 'Kerala', value: 'KL' },
    { name: 'Ladakh', value: 'LA' },
    { name: 'Lakshadweep', value: 'LD' },
    { name: 'Madhya Pradesh', value: 'MP' },
    { name: 'Maharashtra', value: 'MH' },
    { name: 'Manipur', value: 'MN' },
    { name: 'Meghalaya', value: 'ML' },
    { name: 'Mizoram', value: 'MZ' },
    { name: 'Nagaland', value: 'NL' },
    { name: 'Odisha', value: 'OR' },
    { name: 'Puducherry', value: 'PY' },
    { name: 'Punjab', value: 'PB' },
    { name: 'Rajasthan', value: 'RJ' },
    { name: 'Punjab', value: 'PB' },
    { name: 'Rajasthan', value: 'RJ' },
    { name: 'Sikkim', value: 'SK' },
    { name: 'Tamil Nadu', value: 'TN' },
    { name: 'Telangana', value: 'TG' },
    { name: 'Tripura', value: 'TR' },
    { name: 'Uttar Pradesh', value: 'UP' },
    { name: 'Uttarakhand', value: 'UT' },
    { name: 'West Bengal', value: 'WB' },
  ]
  filteredList: { name: string; value: string; }[];
  filteredList2: { state: string; districts: string; }[];
  filteredListDistrict2: { district: string; }[];
  brandlist: any;
  filteredListBrand: any;
  branchRef: string;
  clinicRef: string;
  userRef: string;
  userType: string;
  BranchList: any;
  branchName: any;
  filteredListBranch: any;
  bId: any;
  stateCityArr2: any[];
  stateCity2: any;
  districtData2: any;
  deviceList: any;
  filteredListDevice: any;
  patientList: any;
  filteredListPatient: any;
  billingCompanyList: any;
  filteredListBillingCompany: any;
  patientRefId: any;
  patientName: any;
  billingList: any;
  mid: any;
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    private httpClient: HttpClient,
    // public dialogRef: MatDialogRef<FittedByUsComponent>,@Inject(MAT_DIALOG_DATA) public data:any,  
  ) {
    // dialogRef.disableClose = true;
    //  this.patientRefId = data.patientRefId;
    //  this.patientName = data.patientName;
  }
  fittedByUsForm: FormGroup;
  status: any;
  submitButton = true;
  updateButton = false;
  deleteButton = false;
  cancelButton = false;
  loggedId: any;
  myimage;
  date: any;
  arr = [];

  userPermission: any;
  vendorCreate = true;
  vendorUpdate = true;
  isSuperAdmin = true;
  superBranchId: any;
  ngOnInit() {
    this.page = 1;
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.userType = sessionStorage.getItem('userType');
    this.filteredList = this.allState.slice();
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    if (this.userType !== 'Super Admin') {
      this.userPermission.forEach((element) => {
        this.isSuperAdmin = false;
        if (element.pageName == 'Vendor' && element.create == false) {
          this.vendorCreate = false;
        }
        if (element.pageName == 'Vendor' && element.update == false) {
          this.vendorUpdate = false;
        }
      });
    }
    ////

 
    this.fittedByUsForm = this.fb.group({
      "discountType": new FormControl('', [Validators.required]),
        "discount": new FormControl(''),
        "billNo": new FormControl('', [Validators.required]),
        "brandLable": new FormControl('', [Validators.required]),
        "serviceName": new FormControl(''),
        "model": new FormControl('', [Validators.required]),
        "totalRate": new FormControl('', [Validators.required]),
        "totalServiceAmount": new FormControl(''),
        "mrp": new FormControl('', [Validators.required]),
        "qty": new FormControl('', [Validators.required]),
        "balanceAmount": new FormControl(''),
        "totalAmountWithDiscount": new FormControl(''),
        "billingCompanyName": new FormControl('', [Validators.required]),
        "serialNumber1": new FormControl('', [Validators.required]),
        "serialNumber2": new FormControl(''),
        "patientName": new FormControl('', [Validators.required]),
        "firstName": new FormControl(''),
        "lastName": new FormControl(''),
        "createdAt": new FormControl('', [Validators.required]),
        "paymentStatus": new FormControl('', [Validators.required]),
        "status": new FormControl(''),
        "branchName": new FormControl('', [Validators.required]),
        "searchBilling": new FormControl(''),
    });
    this.fittedByUsForm.get("patientName").setValue(this.patientName);
    this.fetchBranchList();
    this.getAllBillingData();
    this.fetchDeviceList();
    // this.fetchPatientList(); 
    this.fetchBillingCompanyList();
  }
  getAllBillingData() {
    let page = 1;
    let size = 10;
    this.searchText = '';
    this.apimicroservice.getAllBillingData(page, size, this.searchText).subscribe(res => {
      //    
      if (res.status) {
        this.billingList = res.data.data;
        this.totalLength = res.data.totalItems;
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    });
  }

  getById(id) {
    this.apimicroservice.getBillingMigration(id).subscribe(res => {
      //  
      let fname;
      let lname;  
      this.submitButton = false;
      this.updateButton = true;
      this.mid = id;
      if (res.status) {
        let data = res.data[0];
        this.selectDevice(data.deviceInfo[0].deviceRefId);    
        this.apimicroservice.getPatientById(data.patientRefId).subscribe(res => {
          if(res.message !== 'No Data Found')
          {   
       let pdata =res.data[0]; 
       fname=pdata.firstName;
       lname=pdata.lastName;    
          }    
         });

        this.fittedByUsForm = this.fb.group({
          "discountType": new FormControl(data.deviceInfo[0].discountType, [Validators.required]),
          "discount": new FormControl(data.deviceInfo[0].discount),
          "billNo": new FormControl(data.billNo, [Validators.required]),
          "brandLable": new FormControl(data.brandLable, [Validators.required]),
          "serviceName": new FormControl(data.deviceInfo[0].model),
          "model": new FormControl(data.deviceInfo[0].model, [Validators.required]),
          "totalRate": new FormControl(data.deviceInfo[0].totalRate, [Validators.required]),
          "totalServiceAmount": new FormControl(data.deviceInfo[0].totalServiceAmount),
          "mrp": new FormControl(data.deviceInfo[0].mrp, [Validators.required]),
          "qty": new FormControl(data.deviceInfo[0].qty, [Validators.required]),
          "balanceAmount": new FormControl(data.balanceAmount),
          "totalAmountWithDiscount": new FormControl(data.deviceInfo[0].balanceAmount),
          "billingCompanyName": new FormControl(data.billingCompanyName, [Validators.required]),
          "serialNumber1": new FormControl(data.deviceInfo[0].serialNumber, [Validators.required]),
          "serialNumber2": new FormControl(data.serviceInfo[1].serialNumber),
          "patientName": new FormControl(data.patientName, [Validators.required]),
          "firstName": new FormControl(fname),
          "lastName": new FormControl(lname),
          "createdAt": new FormControl(data.createdAt, [Validators.required]),
          "paymentStatus": new FormControl(data.paymentStatus, [Validators.required]),
          "status": new FormControl(data.paymentStatus),
          "branchName": new FormControl(data.branchName, [Validators.required]),
          "searchBilling": new FormControl(''),
        });

      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
      let pload = this.fittedByUsForm.value;
      
      
    });
  }
  saveDevice() {
    // <!-- totalServiceAmount
    // balanceAmount
    // totalAmountWithDIscount


    // discountType discount	billNo	brandLable	serviceName	model	totalRate
    //   totalServiceAmount	mrp	qty	balanceAmount	totalAmountWithDIscount	
    //   billingCompanyName	serialNumber1	serialNumber2	firstName	lastName	createdAt
    //     paymentStatus	status	branchName	billingId
    //     firstName	lastName	createdAt
    //     paymentStatus	status	branchName	billingId -->
    let payload = this.fittedByUsForm.value;

    payload.totalServiceAmount = payload.totalRate;
    payload.balanceAmount = payload.totalRate;
    payload.totalAmountWithDIscount = payload.totalRate;
    payload.serviceName = payload.model;
    payload.status = payload.paymentStatus;

    // let datee = new Date(payload.craetedAt).toISOString();

    // payload.craetedAt =this.datepipe.transform(datee, 'yyyy-MM-dd');
    
    let submitPayload = [];
    submitPayload.push(payload);
    this.apimicroservice.saveBillingMigration(submitPayload).subscribe(res => {
      if (res.status) {
        this.apimicroservice.openSnackbar(res.message);
        this.ngOnInit();
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    })
  }

  fetchPatientList() {
    this.searchText = '';
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    //
    if(this.userType !== 'Super Admin')
    {
      this.apimicroservice.getPatientListByBranchClinic(branchRef,clinicRef).subscribe(res => {
        //

        if (res.status) {    
          this.patientList = res.data;
          this.filteredListPatient = this.patientList.slice();
          // this.onSelectPatient(this.patientList[0]._id);
          // this.fittedByUsForm.get("patientRefId").setValue(this.patientList[0]._id);
        } else {
         // this.apimicroservice.openSnackbar(res.message);
        }
      }) 
    }
    else
    {
    this.apimicroservice.getPatientListByBranchClinic(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
      //

      if (res.status) {
        this.patientList = res.data;
        this.filteredListPatient = this.patientList.slice();
        // this.onSelectPatient(this.patientList[0]._id);
        // this.fittedByUsForm.get("patientRefId").setValue(this.patientList[0]._id);
      } else {
        // this.apimicroservice.openSnackbar(res.message);
      }
    })
    }

  }
  fetchBillingCompanyList() {
    let id = '';
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getBillingDataById(id,clinicRef).subscribe(res => {
      //
      this.billingCompanyList = res.data;
      this.filteredListBillingCompany = this.billingCompanyList.slice();
      let lenght = res.data.length - 1;
      
      this.fittedByUsForm.get("billingCompanyName").setValue(res.data[lenght].companyName);
      if (res.status) {
        //this.apimicroservice.openSnackbar(res.message);

      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    });
  }

  fetchBranchList() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      if (res.status) {
        this.BranchList = res.data;
        this.filteredListBranch = this.BranchList.slice();
      } else {
        //  this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
  fetchDeviceList() {
    let i = 0;
    let id = '';
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
    this.apimicroservice.getDeviceById(id,branchRef,clinicRef).subscribe(res => {
      this.deviceList = res.data;
      this.filteredListDevice = this.deviceList.slice();
      if (res.status) {
        //this.apimicroservice.openSnackbar(res.message);      
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    });
  }else{
    this.apimicroservice.getDeviceById(id,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
      this.deviceList = res.data;
      this.filteredListDevice = this.deviceList.slice();
      if (res.status) {
        //this.apimicroservice.openSnackbar(res.message);      
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    });
  }
  }
  // submitVendor() {
  //  // this.setValidor();
  //   if(this.userType == 'Super Admin')
  //   {
  //     this.fittedByUsForm.controls['branchRefId'].setValidators(Validators.required);
  //     this.fittedByUsForm.controls['branchRefId'].updateValueAndValidity();
  //   }
  //   else{
  //     this.fittedByUsForm.controls['branchRefId'].setErrors(null);
  //     this.fittedByUsForm.controls['branchRefId'].clearValidators();
  //     this.fittedByUsForm.controls['branchRefId'].updateValueAndValidity();
  //   }
  //   if (this.fittedByUsForm.invalid) {
  //     this.fittedByUsForm.get("vendorName").markAsTouched();
  //     this.fittedByUsForm.get("vendorStreetAddress").markAsTouched();
  //     this.fittedByUsForm.get("vendorPhoneNumber").markAsTouched();
  //     this.fittedByUsForm.get("vendorPinCode").markAsTouched();
  //     this.fittedByUsForm.get("vendorBrands").markAsTouched();
  //     // this.fittedByUsForm.get("contactPersonAccounts").markAsTouched();
  //     // this.fittedByUsForm.get("contactPersonAccountNo").markAsTouched();
  //     this.fittedByUsForm.get("state").markAsTouched();

  //     return;
  //   } else {
  //     this.addVendor();
  //   }
  // }

  // removeValidator() { 


  //   this.fittedByUsForm.controls['vendorName'].setErrors(null);
  //   this.fittedByUsForm.controls['vendorName'].clearValidators();
  //   this.fittedByUsForm.controls['vendorName'].updateValueAndValidity();

  //   this.fittedByUsForm.controls['vendorStreetAddress'].setErrors(null);
  //   this.fittedByUsForm.controls['vendorStreetAddress'].clearValidators();
  //   this.fittedByUsForm.controls['vendorStreetAddress'].updateValueAndValidity();

  //   this.fittedByUsForm.controls['vendorBrands'].setErrors(null);
  //   this.fittedByUsForm.controls['vendorBrands'].clearValidators();
  //   this.fittedByUsForm.controls['vendorBrands'].updateValueAndValidity();



  //   this.fittedByUsForm.controls['vendorPinCode'].setErrors(null);
  //   this.fittedByUsForm.controls['vendorPinCode'].clearValidators();
  //   this.fittedByUsForm.controls['vendorPinCode'].updateValueAndValidity();

  //   this.fittedByUsForm.controls['vendorPhoneNumber'].setErrors(null);
  //   this.fittedByUsForm.controls['vendorPhoneNumber'].clearValidators();
  //   this.fittedByUsForm.controls['vendorPhoneNumber'].updateValueAndValidity();

  //   this.fittedByUsForm.controls['contactPersonAccounts'].setErrors(null);
  //  this.fittedByUsForm.controls['contactPersonAccounts'].clearValidators();
  //   this.fittedByUsForm.controls['contactPersonAccounts'].updateValueAndValidity();

  //   this.fittedByUsForm.controls['contactPersonAccountNo'].setErrors(null);
  //   this.fittedByUsForm.controls['contactPersonAccountNo'].clearValidators();
  //   this.fittedByUsForm.controls['contactPersonAccountNo'].updateValueAndValidity();



  //   }

  // setValidor() { 


  //   this.fittedByUsForm.controls['vendorName'].setValidators([Validators.required,Validators.pattern("^[a-zA-Z ]*$")]);
  //   this.fittedByUsForm.controls['vendorName'].updateValueAndValidity();

  //   this.fittedByUsForm.controls['vendorPhoneNumber'].setValidators([Validators.pattern("(0|91)?[6-9][0-9]{9}")]);
  //   this.fittedByUsForm.controls['vendorPhoneNumber'].updateValueAndValidity();

  //   this.fittedByUsForm.controls['vendorStreetAddress'].setValidators(Validators.required);
  //   this.fittedByUsForm.controls['vendorStreetAddress'].updateValueAndValidity();

  //   this.fittedByUsForm.controls['vendorBrands'].setValidators(Validators.required);
  //   this.fittedByUsForm.controls['vendorBrands'].updateValueAndValidity();

  //   this.fittedByUsForm.controls['vendorPinCode'].setValidators([Validators.required,Validators.pattern("^[0-9]{6}$")]);
  //   this.fittedByUsForm.controls['vendorPinCode'].updateValueAndValidity();

  //   this.fittedByUsForm.controls['contactPersonAccounts'].setValidators([Validators.required,Validators.pattern("^[a-zA-Z0-9 -]+$")]);
  //   this.fittedByUsForm.controls['contactPersonAccounts'].updateValueAndValidity();

  //   this.fittedByUsForm.controls['contactPersonAccountNo'].setValidators([Validators.pattern("^[0-9]{9,18}$"),Validators.maxLength(18),Validators.minLength(9),]);
  //   this.fittedByUsForm.controls['contactPersonAccountNo'].updateValueAndValidity();

  //   }


  updateDeviceDetails() {

    if (this.fittedByUsForm.invalid) {
      this.fittedByUsForm.get("discountType").markAsTouched();
      this.fittedByUsForm.get("billNo").markAsTouched();
      this.fittedByUsForm.get("brandLable").markAsTouched();
      this.fittedByUsForm.get("model").markAsTouched();
      this.fittedByUsForm.get("totalRate").markAsTouched();
      this.fittedByUsForm.get("mrp").markAsTouched();
      this.fittedByUsForm.get("qty").markAsTouched();
      this.fittedByUsForm.get("billingCompanyName").markAsTouched();
      this.fittedByUsForm.get("serialNumber1").markAsTouched();
      this.fittedByUsForm.get("patientName").markAsTouched();
      this.fittedByUsForm.get("paymentStatus").markAsTouched();
      this.fittedByUsForm.get("branchName").markAsTouched();
      return;
    } else {
      this.updateMigration();
    }
  }

  addVendor() {
    this.spinner.show();
    let payload = this.fittedByUsForm.value;
    // payload.vendorBrandLable = this.arr;
    ////


    if (this.userType !== 'Super Admin') {
      payload.branchRefId = this.branchRef;
      payload.clinicRefId = this.clinicRef;
      payload.branchName = this.branchName;
    }
    this.apimicroservice.addVendor(payload).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.apimicroservice.openSnackbar(res.message);
        this.ngOnInit();
        //   this.removeValidator();
        //this.loadBuildigdata()

      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    }, (ERROR: HttpErrorResponse) => {
      this.apimicroservice.openSnackbar(ERROR.error.message);
      this.spinner.hide();
    });

  }
  openSnackbar(message: string) {
    return this._snackBar.open(message, "close", {
      verticalPosition: "bottom",
      horizontalPosition: "right",
      duration: 2000,
      panelClass: ["snackbarStyle"],
    });
  }

  getLastId() {
    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getLastVendorID(this.branchRef).subscribe(res => {
        ////   
        // if(res['data']['data'] == '')
        // {
        //   this.fittedByUsForm.controls['branchWiseId'].setValue('1');
        // }
        if (res['status']) {
          let vendorIdd = res['data']['totalItems'];
          // this.fittedByUsForm.controls['branchWiseId'].setValue(vendorIdd + 1);
          this.bId = vendorIdd + 1;
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }

      })
    } else {
      this.apimicroservice.getLastVendorAll().subscribe(res => {
        ////   
        // if(res['data']['data'] == '')
        // {
        //   this.fittedByUsForm.controls['branchWiseId'].setValue('1');
        // }
        if (res['status']) {
          let vendorIdd = res['data']['totalItems'];
          this.superBranchId = vendorIdd + 1;
          this.bId = vendorIdd + 1;
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }

      })
    }

  }
  fetchVendorList() {
    this.spinner.show();
    let pg = 1;
    let size = 10;
    let search = '';
    let branchRef = '';
    let clinicRef = '';
    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getVendorList(pg, size, search, this.branchRef, this.clinicRef).subscribe(res => {
        this.spinner.hide();
        this.listOfVendors = res.data.vendorData;
        this.totalLength = res.data.totalItems;
        //this.buildingLenght = this.listofbuild.length;
        if (res.status) {
          // this.apimicroservice.openSnackbar(res.message);  
        } else {
          //this.apimicroservice.openSnackbar(res.message);
        }
      });
    } else {

      this.apimicroservice.getVendorList(pg, size, search, branchRef, clinicRef).subscribe(res => {
        this.spinner.hide();
        this.listOfVendors = res.data.vendorData;
        this.totalLength = res.data.totalItems;
        //this.buildingLenght = this.listofbuild.length;
        if (res.status) {
          // this.apimicroservice.openSnackbar(res.message);   
        } else {
          //this.apimicroservice.openSnackbar(res.message);
        }
      });
    }
  }

  addNew() {
    this.fittedByUsForm.reset();
    this.updateButton = false;
    this.deleteButton = false;
  }

  updateMigration() {
    this.spinner.show();
    let payload = this.fittedByUsForm.value;
    
    
    
    this.apimicroservice.updateMigrationBilling(this.mid,payload).subscribe(res => {
      this.spinner.hide();
      if (res.status) { 
        this.apimicroservice.openSnackbar(res.message);
        this.updateButton = false;
        this.submitButton = true;
        this.cancelButton = false;
        this.ngOnInit();
      } else {
        //  //this.apimicroservice.openSnackbar(res.message);
      }
    });

  }

  onPageChange(event) {
    this.spinner.show();
    this.page = event;
    // let p = this.page - 1;
    this.searchText = this.fittedByUsForm.get('searchBilling').value;
    if(this.searchText == null)
    {
      this.searchText = ''; 
    }
    let size = 10
    this.apimicroservice.getAllBillingData(this.page, size, this.searchText).subscribe(res => {
      this.spinner.hide();
      this.billingList = res.data.data;
      this.totalLength = res.data.totalItems;
      if (res.status) {
        //  this.apimicroservice.openSnackbar(res.message);  
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }

    });

  }
  onKeyUpEvent(event: any) {
    this.searchText = this.fittedByUsForm.get('searchBilling').value;
    let page = 1;
    let size = 10
    this.apimicroservice.getAllBillingData(this.page, size, this.searchText).subscribe(res => {
      this.billingList = res.data.data;
      this.totalLength = res.data.totalItems;
      this.page = res.data.pageNumber;
      //this.buildingLenght = this.listofbuild.length;
      if (res.status) {
        //  this.apimicroservice.openSnackbar(res.message);   
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    });


  }

  cancelUpdate() {
    this.fittedByUsForm.reset();
    this.submitButton = true;
    this.updateButton = false;
    this.cancelButton = false;
    this.ngOnInit();
    // this.removeValidator();
  }

  redirect(to): void {
    this.router.navigate([to]);
  }
  fetchBrandList() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
    this.apimicroservice.getBrandList(branchRef,clinicRef).subscribe(res => {
      ////
      if (res.status) {
        this.brandlist = res.data;
        this.filteredListBrand = this.brandlist.slice();
        //////
        // this.fittedByUsForm.get("vendorBrands").setValue(this.filteredListBrand[0]._id);
        //  this.fittedByUsForm.controls['vendorBrands'].setValue(this.filteredListBrand[0].brandName);
      }
    });
  }else{
    this.apimicroservice.getBrandList(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
      ////
      if (res.status) {
        this.brandlist = res.data;
        this.filteredListBrand = this.brandlist.slice();
        //////
        // this.fittedByUsForm.get("vendorBrands").setValue(this.filteredListBrand[0]._id);
        //  this.fittedByUsForm.controls['vendorBrands'].setValue(this.filteredListBrand[0].brandName);
      }
    });
  }

  }
  openBrandPopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopupBrandComponent,
      {
        width: "430px",
      });
    dialogRef3.afterClosed().subscribe((result) => {
      let clinicRef= sessionStorage.getItem('clinicRef');
      let branchRef= sessionStorage.getItem('branchRef');
      let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
      let branchRefSuperAdmin= '';
      if (this.userType !== 'Super Admin') {
      this.apimicroservice.getBrandList(branchRef,clinicRef).subscribe(res => {
        ////
        if (res.status) {
          this.brandlist = res.data;
          this.filteredListBrand = this.brandlist.slice();
          this.brandSelect(res.data[0].brandName);
          this.fittedByUsForm.get("vendorBrands").setValue(res.data[0]._id);
          //  this.fittedByUsForm.controls['vendorBrands'].setValue(this.filteredListBrand[0].brandName);
        }
      });
    }else{
      this.apimicroservice.getBrandList(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
        ////
        if (res.status) {
          this.brandlist = res.data;
          this.filteredListBrand = this.brandlist.slice();
          this.brandSelect(res.data[0].brandName);
          this.fittedByUsForm.get("vendorBrands").setValue(res.data[0]._id);
          //  this.fittedByUsForm.controls['vendorBrands'].setValue(this.filteredListBrand[0].brandName);
        }
      });
    }

      // this.fetchBrandList();

    })
  }
  // openBrandPopUp2() {
  //   localStorage.clear();
  //   const dialogRef3 = this.dialog.open(PopupBrandComponent,
  //     {
  //       width: "800px",
  //       data: {
  //         brandData: this.arr
  //       }
  //     }); 
  // }

  openBranchPopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpBranchComponent,
      {
        width: "1200px",
      });

    dialogRef3.afterClosed().subscribe((result) => {
      // this.fetchBrandList();
      let clinicRef= sessionStorage.getItem('clinicRef');
      this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
        ////  
        if (res.status) {
          this.BranchList = res.data;
          this.filteredListBranch = this.BranchList.slice();
          // this.branchClick(res.data[0]._id,res.data[0].branchName);
          this.fittedByUsForm.get("branchName").setValue(res.data[0].branchName);
        } else {
          //  this.apimicroservice.openSnackbar(res.message);
        }
      })
    })
  }
  openDepartmentPopUp() {
    const dialogRef3 = this.dialog.open(PatientSearchPopComponent, {
      width: "97%",
      height: "90%",
    });
 
    dialogRef3.afterClosed().subscribe((result) => {
      this.fittedByUsForm.get("patientName").setValue(result.data.firstName + ' ' + result.data.lastName);
      this.fittedByUsForm.get("firstName").setValue(result.data.firstName);
      this.fittedByUsForm.get("lastName").setValue(result.data.lastName);
    });
  }
  selectDevice(id) {
    // this.fittedByUsForm.get("deviceRefId").setValue(id);
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
    this.apimicroservice.getDeviceById(id,branchRef,clinicRef).subscribe(res => {
      this.spinner.hide();
      let data = res.data[0];
      //this.fittedByUsForm.get("brand").setValue(data.brand);
      this.fittedByUsForm.controls['brandLable'].setValue(data.brandLable);
      // this.fittedByUsForm.controls["deviceType"].setValue(data.deviceType);
      //  this.deviceByPatientForm.get("model").setValue(this.deviceList[lenght].model);
    });
  }else{
    this.apimicroservice.getDeviceById(id,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
      this.spinner.hide();
      let data = res.data[0];
      //this.fittedByUsForm.get("brand").setValue(data.brand);
      this.fittedByUsForm.controls['brandLable'].setValue(data.brandLable);
      // this.fittedByUsForm.controls["deviceType"].setValue(data.deviceType);
      //  this.deviceByPatientForm.get("model").setValue(this.deviceList[lenght].model);
    });
  }
  }
  openDevicePopUp() {
    //localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpDeviceComponent,
      {
        width: "1200px",
      });
    dialogRef3.afterClosed().subscribe((result) => {
      //
      // 
      // this.fetchDeviceList(); 
      let id = '';
      let clinicRef= sessionStorage.getItem('clinicRef');
      let branchRef= sessionStorage.getItem('branchRef');
      let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
      let branchRefSuperAdmin= '';
      if (this.userType !== 'Super Admin') {
      this.apimicroservice.getDeviceById(id,branchRef,clinicRef).subscribe(res => {
        this.deviceList = res.data;
        this.filteredListDevice = this.deviceList.slice();
        let lenght = res.data.length - 1;
        //

        this.fittedByUsForm.get("model").setValue(this.deviceList[lenght].model);
        this.selectDevice(this.deviceList[lenght]._id);

        if (res.status) {
          //this.apimicroservice.openSnackbar(res.message);      
        } else {
          //this.apimicroservice.openSnackbar(res.message);
        }
      });
    }else{
      this.apimicroservice.getDeviceById(id,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
        this.deviceList = res.data;
        this.filteredListDevice = this.deviceList.slice();
        let lenght = res.data.length - 1;
        //

        this.fittedByUsForm.get("model").setValue(this.deviceList[lenght].model);
        this.selectDevice(this.deviceList[lenght]._id);

        if (res.status) {
          //this.apimicroservice.openSnackbar(res.message);      
        } else {
          //this.apimicroservice.openSnackbar(res.message);
        }
      });
    }

    });
  }
  brandSelect(e) {
    ////
    let payload = this.fittedByUsForm.value;
    this.fittedByUsForm.controls['vendorBrandLable'].setValue(e);

    // this.fittedByUsForm.controls['vendorBrands'].setValue(e);

    // if(event.source.selected){
    //   this.arr.push(e);
    // }
    // else{
    //   this.arr = this.arr.filter(b => b!=e);
    // } 
    // ////

    // payload.brandLable = this.arr;
    // // this.vendorBrandAdd = false
    // // this.vendorBrandEdit = true;
  }

  branchClick(id, branchName) {

    this.fittedByUsForm.controls["branchName"].setValue(branchName);

    this.apimicroservice.getBranchById(id).subscribe(res => {
      ////

      let data = res.data[0];
      let payload = this.fittedByUsForm.value;
      payload.clinicRefId = res.data[0].clinicRefId;
      this.fittedByUsForm.controls["clinicRefId"].setValue(res.data[0].clinicRefId);
      if (this.updateButton == false) {
        this.spinner.show();
        this.apimicroservice.getLastVendorID(payload.branchRefId).subscribe(res => {
          ////   

          if (res['status']) {
            let vendorIdd = res['data']['totalItems'];
            this.fittedByUsForm.controls['branchWiseId'].setValue(vendorIdd + 1);
            //  this.bId=vendorIdd+1;
          } else {
            this.apimicroservice.openSnackbar("Something went wrong");
          }
          this.spinner.hide();
        })
      }
    });
  }
  WhatsApp() {
    let payload = this.fittedByUsForm.value;
    let num = payload.vendorPhoneNumber;
    if (num.length == 10) {
      sessionStorage.setItem('whatsAppNumber', payload.vendorPhoneNumber);
      let mobileNumber = sessionStorage.getItem('whatsAppNumber');
      window.open("https://wa.me/91" + mobileNumber);
    }
    else {
      this.fittedByUsForm.get("vendorPhoneNumber").markAsTouched();
    }
  }

  WhatsApp1() {
    let payload = this.fittedByUsForm.value;
    let num = payload.contactPersonSalesNo;
    if (num.length == 10) {
      sessionStorage.setItem('whatsAppNumber', payload.contactPersonSalesNo);
      let mobileNumber = sessionStorage.getItem('whatsAppNumber');
      window.open("https://wa.me/91" + mobileNumber);
    }
    else {
      this.fittedByUsForm.get("contactPersonSalesNo").markAsTouched();
    }
  }
  WhatsApp2() {
    let payload = this.fittedByUsForm.value;
    let num = payload.customerCareContactPersonNo;
    if (num.length == 10) {
      sessionStorage.setItem('whatsAppNumber', payload.customerCareContactPersonNo);
      let mobileNumber = sessionStorage.getItem('whatsAppNumber');
      window.open("https://wa.me/91" + mobileNumber);
    }
    else {
      this.fittedByUsForm.get("customerCareContactPersonNo").markAsTouched();
    }
  }

  onback() {
    this.apimicroservice.back()
  }
  openBillingCompPopUp() {
    const dialogRef3 = this.dialog.open(PopupBillingcompanyComponent,
      {
        width: "1200px",
      });

    dialogRef3.afterClosed().subscribe((result) => {
      let id = '';
      let clinicRef= sessionStorage.getItem('clinicRef');
      this.apimicroservice.getBillingDataById(id,clinicRef).subscribe(res => {

        if (res.status) {

          this.fittedByUsForm.get("billingCompanyName").setValue(res.data.data[0].companyName);
        } else {
          //this.apimicroservice.openSnackbar(res.message);
        }
      });
      //this.fetchBillingCompanyList();  
    });
  }
  openPatientPopUp() {

    const dialogRef3 = this.dialog.open(PopUpPatientComponent,
      {
        width: "1200px",
      });

    dialogRef3.afterClosed().subscribe((result) => {
      let clinicRef= sessionStorage.getItem('clinicRef');
      let branchRef= sessionStorage.getItem('branchRef');
      this.apimicroservice.getPatientListByBranchClinic(branchRef, clinicRef).subscribe(res => {
        //

        if (res.status) {
          this.patientList = res.data;
          let length = res.data.length - 1;
          this.filteredListPatient = this.patientList.slice();

          this.fittedByUsForm.get("patientName").setValue(this.patientList[length].patientName);
        } else {
          // this.apimicroservice.openSnackbar(res.message);
        }
      })
    });
  }
  toBillingForm() {
    document.getElementById("toBillingForm").scrollIntoView();
  }
  toBillingLst() {
    document.getElementById("toBillingLst").scrollIntoView();
  }
} 
