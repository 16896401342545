import { Component, Inject, OnInit } from "@angular/core";
import { ApiServiceService } from '../api-service.service';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import * as moment from 'moment';
@Component({
  selector: 'app-popup-inqury-patient-duplicate',
  templateUrl: './popup-inqury-patient-duplicate.component.html',
  styleUrls: ['./popup-inqury-patient-duplicate.component.scss']
})
export class PopupInquryPatientDuplicateComponent implements OnInit {
  appointRefId: any;
  appointDate: any;
  patientName = '';
  appDate: any;
  appTime: any; 

  constructor(public dialogRef: MatDialogRef<PopupInquryPatientDuplicateComponent>,@Inject(MAT_DIALOG_DATA) public data:any,
  public apimicroservice: ApiServiceService,private spinner: NgxSpinnerService,) { 
    // this.appointRefId = data.appointRefId;
    // this.patientName = data.patientName;
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.appointDate = new Date(this.data.appointDate);
    this.patientName = this.patientName.charAt(0).toUpperCase() + this.patientName.slice(1);
     let tt = this.appointDate;
     tt = moment(this.appointDate); 
     this.appDate = tt.format("MMMM Do YYYY");
     this.appTime = tt.format("LT");
     //);
     //);
    //
    //
    //
  }
  confirmSubmit(){ 
    sessionStorage.setItem("copyFromInqury","yes");
    this.onClose();
  } 
  confirmCancel(){
    sessionStorage.setItem("copyFromInqury","no");
   this.onClose();
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
