<div class="dialog-wrapper">
    <!-- <h3 mat-dialog-title>Change Password </h3> -->
    <mat-dialog-content>
      <div class="container">
        <form autocomplete="off" [formGroup]="popupCanNumberForm" novalidate>
        <div class="row">
          <div class="col-sm-12">
            <div class="card-group">
              <div class="card p-4" >
             

                <div class="card-body col-sm-12">
                    <div class="row">
                    <div class="col-sm-11">
                        <p class="text-muted">Add New CAN Number</p>
                    </div>
                    <div class="col-sm-1">
                      <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                        <a (click)="onClose()" matTooltip="Close" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">close</span></a>
                        </div>
                    </div>
                    <!-- <div class="col-sm-1">
                        <i class="far fa-window-close close_style" (click)="onClose()"></i>
                    </div> -->
              </div>
                  <div class="col-sm-12">
                    <div class="row">
                      <div class="col-sm-6">
                        <label class="pt-3 lable_style">Select Branch:</label>
                        </div>
                        <div class="col-sm-6 pl-1 pr-1"> 
                        <div class="row">
                          <div class="col-sm-10 pr-1">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Branch 
                            <!-- <span class="required-asterisk">*</span> -->
                          </mat-label>
                          <mat-select required #select1 formControlName="branchRefId" >
                            <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'branchName'"
                            [array]="BranchList"  (filteredReturn)="filteredListBranch =$event"></mat-select-filter>
                            <mat-option *ngFor="let item of filteredListBranch" [value]="item._id" 
                            (click)="branchClick(item._id);"
                            >
                             {{ item.branchName }}</mat-option>
                            </mat-select>
                           
                        </mat-form-field>
                        <mat-error class="margin_tp"
                        *ngIf="popupCanNumberForm.controls['branchRefId'].touched && popupCanNumberForm.controls['branchRefId'].errors?.required">
                        This field is mandatory.
                      </mat-error>
                       </div>
                       <div class="col-sm-2 mt-2 mTop no-print">
                        <i (click)="openBranchPopUp();" matTooltip="Add New Branch" 
                        class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i> 
                      </div>
                       </div>
                      </div>
                  <div class="col-sm-6">
                    <label class="pt-3 lable_style">CAN Number :</label>
                    </div>
          <div class="col-sm-6 pl-1 pr-1">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>CAN Number</mat-label>
              <input required matInput placeholder="Can Number" formControlName="canNumber" required />
             
             </mat-form-field>
             <mat-error class="margin_tp"
             *ngIf="popupCanNumberForm.controls['canNumber'].touched && popupCanNumberForm.controls['canNumber'].errors?.required">
             This field is mandatory.
           </mat-error>
           <mat-error class="margin_tp"
             *ngIf="popupCanNumberForm.controls['canNumber'].touched && popupCanNumberForm.controls['canNumber'].errors?.pattern">
             Enter valid Can Number.
           </mat-error>
          </div>
      
      
                </div>
                <div class="row">
                    <div class="col-sm-3"></div>
                <div class="col-sm-6">
                    <button class="btn btn-primary btn-sm example-full-width"   type="button" 
                    (click)="save()" >Save</button>
                  </div>
                  <div class="col-sm-3"></div>
                </div>
</div>
                </div>



                
              </div>
            </div>
          </div>
      
        </div>
</form>


      </div>
    </mat-dialog-content>
  
  </div>