import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import { NgxSpinnerService } from "ngx-spinner";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { AfterViewInit, Directive, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PopupItemsCollectedComponent } from "../popup-items-collected/popup-items-collected.component";
import { PopupNewComplaintComponent } from "../popup-new-complaint/popup-new-complaint.component";
import { refineEventDef } from '@fullcalendar/core';
import { createBuilderStatusReporter } from 'typescript';
import { json } from 'express';
import { PopUpBranchComponent } from "../pop-up-branch/pop-up-branch.component";
import { PopUpPatientComponent } from "../pop-up-patient/pop-up-patient.component";
import { PopupCompanyComponent } from "../popup-company/popup-company.component";
import { PatientSearchPopComponent } from "../patient-search-pop/patient-search-pop.component";
import { ActivatedRoute } from "@angular/router";
import { PopUpAccesoriesComponent } from "../pop-up-accesories/pop-up-accesories.component";
import { PopupDeviceByPatientComponent } from "../popup-device-by-patient/popup-device-by-patient.component";
import { RepairActionComponent } from "../repair-action/repair-action.component";
import SignaturePad from 'signature_pad';
import { ElementRef } from '@angular/core'; 
import { FittedByUsComponent } from "../fitted-by-us/fitted-by-us.component";
import { PopUpFittedByUsComponent } from "../pop-up-fitted-by-us/pop-up-fitted-by-us.component";
import { PopupBillingcompanyComponent } from "../popup-billingcompany/popup-billingcompany.component";
@Component({
  selector: 'app-repair-complaint',
  templateUrl: './repair-complaint.component.html',
  styleUrls: ['./repair-complaint.component.scss']
})
export class RepairComplaintComponent implements OnInit {
  errormessage: string;
  searchText: any;
  repairData: any;
  repairId: any;
  listOfRepair: any;
  patientList: any;
  branchRef: string;
  clinicRef: string;
  userRef: string;
  userType: string;
  patientSelect = false;
  doctorSelect = false;
  listOfdoctor: any;
  patientFirstName: any;
  patientLastName: any;
  patientGender: any;
  patientMobileNo: any;
  patientAddress: any;
  doctorBranchName: any;
  doctorMobileNo: any;
  doctorAddress: any;
  doctorClinicName: any;
  branchName: any;
  superBranchId: any;
  BranchList: any;
  pid: any;
  did: any;
  sessionBranchName: string;
  repairD = new Date();
  isEditable = false;
  statusArr = [];
  matchId: number;
  filteredListPatient: any;
  filteredListDoctor: any;
  scenariodisable = false;
  leftPartData = true;
  rightPartData = true;
  isDisable = true;
  AddRepairActionCreate = true;
  statusArray = [{ name: 'Aid Received for repairs', value: 'Aid Received for repairs' },
  { name: 'Job Receipt Issued', value: 'Job Receipt Issued' },
  { name: 'Sent to Manufacturer', value: 'Sent to Manufacturer' },
  { name: 'Repair Estimate is Received from Company', value: 'Repair Estimate is Received from Company' },
  { name: 'Estimate to Client is calculated', value: 'Estimate to Client is calculated' },
  { name: 'Estimate is Advised by call/SMS/WA', value: 'Estimate is Advised by call/SMS/WA' },
  { name: 'Payment is Received from Client', value: 'Payment is Received from Client' },
  { name: 'Estimate is Approved with the company on Email', value: 'Estimate is Approved with the company on Email' },
  { name: 'Device is repaired and Sent back by the company', value: 'Device is repaired and Sent back by the company' },
  { name: 'Received by NHS', value: 'Received by NHS' },
  { name: 'Checked and Programmed by audiologist', value: 'Checked and Programmed by audiologist' },
  { name: 'Client is advised to collect it or Appointment is arranged', value: 'Client is advised to collect it or Appointment is arranged' },
  { name: 'Or Sent back by Courie', value: 'Or Sent back by Courie' },
  ]
  filteredStatusList: { name: string; value: string; }[];
  filteredListBranch: any;
  stepRefId: any;
  repairRefId: any;
  myrepairId: any;
  showstepper = true;
  DeviceList = [];
  filteredListDevice: any;
  filteredListDevice2: any;
  filteredListSerialNo: any;
  DeviceList2: any;
  patientCreate = true;
  companyCreate = true;
  repair_branchRefId: string;
  repair_patientRefId: string;
  repair_branchName: string;
  companyName: any;
  canNo: any;
  repairNo: string;
  accesoriesList: any;
  filteredListAccesories: any;
  accesories: any;
  bID: any;
  make: any;
  repair_patientName: string;
  pId: any;
  serialNoList: any;
  allDevices: any[];
  repairActionData = [];
  lastStatus: any;
  totalLengthAction: any;
  branchRefId: any;
  repairBranch: void;
  patientMobile: any;
  patientID: any;
  amountA: any;
  amountB: any;
  LeftDeviceAge: any;
  RightDeviceAge: any;
  leftType: any;
  rightType: any;
  serialNumList = [];
  tempDeviceList = [];
  rightSerialNumList = [];
  rightFilteredListSerialNo: any;
  preLeftSrno = '';
  preRightSrno = '';
  fromPrepopulation = false;
  leftdeviceRefId: any;
  rightDeviceRefId: any;
  billNum: any;
  dateOfPurchase: any;
  fromPrepo: any;
  leftWarranty: any;
  rightWarranty: any;
  extenalCreatedRightDate: any;
  extenalCreatedLeftDate: any;
  warrentRight: any;
  warrentLeft: string;
  leftModel: any;
  leftSrNo: any;
  rightModel: any;
  rightSrNo: any;
  pLeftStaus: any;
  pRightStaus: any;
  leftDateOfPurchase: any;
  rightDateOfPurchase: any;
  expDate: any;
  expDate2: any;
  listOfBilling: any;
  filteredBillingCompany: any;
  // pRefId: any;
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute
  ) { }
  repairComplaintForm: FormGroup;
  status: any;
  submitButton = true;
  updateButton = false;
  deleteButton = false;
  cancelButton = false;
  isLinear = false;
  loggedId: any;
  myimage;
  date: any;
  size: number = 10;
  page: number = 1;
  sizeAction: number = 6;
  pageAction: number = 1;
  totalLength: any;
  variables1;
  variable1List: any;
  variables2;
  variable2List: any;
  variables3;
  variable3List: any;
  filteredListCompany: any;
  companyList = [];
  variables4;
  variable4List: any;
  leftSerialNoReadOnly = false;
  rightSerialNoReadOnly = false;
  rightCompanyReadOnly = false;
  leftCompanyReadOnly = false;
  leftHATypeReadOnly = false;
  rightHATypeReadOnly = false;
  leftHAModelReadOnly = false;
  rightHAModelReadOnly = false;
  userPermission: any;
  repairComplaintCreate = true;
  repairComplaintUpdate = true;
  isSuperAdmin = true;
  norecord = true;
  stepperRefId;
  minDate: Date;
  currentDate: any;
  isPrepopulate = false;
  allSerialNo = [];
  title = 'signatureJS';
  signaturePad: SignaturePad;
  @ViewChild('canvas') canvasEl: ElementRef;
  signatureImg: string;
  fileName: any;
  fileName2: any;
  signPadShow = false;
  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
  }

  startDrawing(event: Event) {
    ////console.log(event);
  }

  moved(event: Event) {
    // works in device not in browser
  }

  clearPad() {
    this.signaturePad.clear();
  }

  savePad() {
    const base64Data = this.signaturePad.toDataURL();
    this.signatureImg = base64Data;
  }
  ngOnInit() {
    this.bID = '';
    this.fromPrepopulation = false;
    this.currentDate = new Date();
    this.minDate = new Date(1950, 0, 1);
    this.page = 1;
    this.date = new Date();
    this.isEditable = false;
    this.scenariodisable = false;
    this.leftPartData = true;
    this.rightPartData = true;

    this.statusArr = [
      {
        "labelOne": "Step 1",
        "datetime": "",
        "labeltwo": "Aid Received for repairs",
        "button": true,
        "controlname": "step1",
        "comments": "",
        "placeholderText": "Items collected from patient-Wires,Charger etc.",
        "_id": "6243002ae53ff64495b22797"
      },
      {
        "labelOne": "Step 2",
        "datetime": "",
        "labeltwo": "Job Receipt Issued​",
        "button": true,
        "controlname": "step2",
        "comments": "",
        "placeholderText": "Job Id generated by system & receipt given to",
        "_id": "6243002ae53ff64495b22798"
      },
      {
        "labelOne": "Step 3",
        "datetime": "",
        "labeltwo": "Sent to Manufacturer​",
        "button": true,
        "controlname": "step3",
        "comments": "",
        "placeholderText": "Courier Details-Tracking Id,Date etc.",
        "_id": "6243002ae53ff64495b22799"
      },
      {
        "labelOne": "Step 4",
        "datetime": "",
        "labeltwo": "Repair Estimate is Received from Company​",
        "button": true,
        "controlname": "step4",
        "comments": "",
        "placeholderText": "Status updated with estimates received",
        "_id": "6243002ae53ff64495b2279a"
      },
      {
        "labelOne": "Step 5",
        "datetime": "",
        "labeltwo": "Estimate to Client is calculated​",
        "button": true,
        "controlname": "step5",
        "comments": "",
        "placeholderText": "System calculates the charges to convey to patient",
        "_id": "6243002ae53ff64495b2279b"
      },
      {
        "labelOne": "Step 6",
        "datetime": "",
        "labeltwo": "Estimate is Advised by call/SMS/WA​",
        "button": true,
        "controlname": "step6",
        "comments": "",
        "placeholderText": "Informed patient via Email,SMS or Phone",
        "_id": "6243002ae53ff64495b2279c"
      },
      {
        "labelOne": "Step 7",
        "datetime": "",
        "labeltwo": "Payment is Received from Client​",
        "button": true,
        "controlname": "step7",
        "comments": "",
        "placeholderText": "Status updated with confirmation received from",
        "_id": "6243002ae53ff64495b2279d"
      },
      {
        "labelOne": "Step 8",
        "datetime": "",
        "labeltwo": "Estimate is Approved with the company on Email",
        "button": true,
        "controlname": "step8",
        "comments": "",
        "placeholderText": "Informed company via Email,SMS or Phone",
        "_id": "6243002ae53ff64495b2279e"
      },
      {
        "labelOne": "Step 9",
        "datetime": "",
        "labeltwo": "Device is repaired and Sent back by the company​",
        "button": true,
        "controlname": "step9",
        "comments": "",
        "placeholderText": "Courier details -tracking Id,Date etc.",
        "_id": "6243002ae53ff64495b2279f"
      },
      {
        "labelOne": "Step 10",
        "datetime": "",
        "labeltwo": "Received by NHS​",
        "button": true,
        "controlname": "step10",
        "comments": "",
        "placeholderText": "Status updated as HA received after Repair",
        "_id": "6243002ae53ff64495b227a0"
      },
      {
        "labelOne": "Step 11",
        "datetime": "",
        "labeltwo": "Checked and Programmed by audiologist​​",
        "button": true,
        "controlname": "step11",
        "comments": "",
        "placeholderText": "Repair verified & necessary steps taken by doctor",
        "_id": "6243002ae53ff64495b227a1"
      },
      {
        "labelOne": "Step 12",
        "datetime": "",
        "labeltwo": "Client is advised to collect it or Appointment is arranged​",
        "button": true,
        "controlname": "step12",
        "comments": "",
        "placeholderText": "Repair status informed to patient via Email,SMS or Phone",
        "_id": "6243002ae53ff64495b227a2"
      },
      {
        "labelOne": "Step 13",
        "datetime": "",
        "labeltwo": "Or Sent back by Courier​",
        "button": true,
        "controlname": "step13",
        "comments": "",
        "placeholderText": "Courier Details-Tracking Id,Date",
        "_id": "6243002ae53ff64495b227a3"
      }
    ];
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.userRef = sessionStorage.getItem('userRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    this.userType = sessionStorage.getItem('userType');
    this.repair_branchRefId = sessionStorage.getItem('repair_branchRefId');
    this.repair_patientRefId = sessionStorage.getItem('repair_patientRefId');
    this.repair_branchName = sessionStorage.getItem('repair_branchName');
    this.repair_patientName = sessionStorage.getItem('repair_patientName');
    this.repairNo = this.route.snapshot.paramMap.get('repairNo');

    this.filteredStatusList = this.statusArray.slice();
    if (this.userType !== 'Super Admin') {
      this.isSuperAdmin = false;
      this.userPermission.forEach((element) => {
        if (element.pageName == 'Repair Complaint' && element.create == false) {
          this.repairComplaintCreate = false;
        }
        if (element.pageName == 'Repair Complaint' && element.update == false) {
          this.repairComplaintUpdate = false;
        }
        if (element.pageName == 'Patient' && element.create == false) {
          this.patientCreate = false;
        }
        if (element.pageName == 'Add Repair Action' && element.create == false) {
          this.AddRepairActionCreate = false;
        }
      });
    }

    this.repairComplaintForm = this.fb.group({
      "firstName": new FormControl(''),
      "lastName": new FormControl(''),
      "sex": new FormControl(''),
      "mobile1": new FormControl('', [Validators.pattern("(0|91)?[6-9][0-9]{9}")]),
      "streetAddress": new FormControl(''),
      // "doctorClinicName": new FormControl(''),
      // "doctorBranchName": new FormControl(''),
      // "doctorMobileNo": new FormControl(''),
      // "doctorAddress": new FormControl(''),
      "patientRefId": new FormControl(''),
      // "doctorRefId":new FormControl(''),
      "branchRefId": new FormControl(''),
      "branchWiseId": new FormControl(''),
      "branchName": new FormControl(''),
      //"date": new FormControl(''),
      // "repairId":new FormControl(''),
      // "status":new FormControl(''),
      "leftModel": new FormControl('', Validators.required),
      "rightModel": new FormControl(''),
      "leftSrNo": new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9 -]+$")]),
      "rightSrNo": new FormControl('', Validators.pattern("^[a-zA-Z0-9 -]+$")),
      "leftDead": new FormControl(false),
      "rightDead": new FormControl(false),
      "leftNoisy": new FormControl(false),
      "rightNoisy": new FormControl(false),
      "leftDistortion": new FormControl(false),
      "rightDistortion": new FormControl(false),
      "leftFeedback": new FormControl(false),
      "rightFeedback": new FormControl(false),
      "leftConnectivityIssue": new FormControl(false),
      "rightConnectivityIssue": new FormControl(false),
      "leftTightFit": new FormControl(false),
      "rightTightFit": new FormControl(false),
      "leftLooseFit": new FormControl(false),
      "rightLooseFit": new FormControl(false),
      "leftOtherShellIssue": new FormControl(false),
      "rightOtherShellIssue": new FormControl(false),
      "leftOther": new FormControl(''),
      "rightOther": new FormControl(''),
      "leftAnyPhysicalDamage": new FormControl(''),
      "rightAnyPhysicalDamage": new FormControl(''),
      "leftVisibleCorrorsion": new FormControl(''),
      "rightVisibleCorrorsion": new FormControl(''),
      "leftBatteryLeakageVisible": new FormControl(''),
      "rightBatteryLeakageVisible": new FormControl(''),
      "leftMouldIncluded": new FormControl(''),
      "rightMouldIncluded": new FormControl(''),
      "leftMouldDamaged": new FormControl(''),
      "rightMouldDamaged": new FormControl(''),
      "leftWarranty": new FormControl(''),
      "rightWarranty": new FormControl(''),
      "leftDateOfPurchase": new FormControl(''),
      "rightDateOfPurchase": new FormControl(''),
      "leftRepairQuotation": new FormControl(''),
      "rightRepairQuotation": new FormControl(''),
      "leftPastRepairRef": new FormControl(''),
      "rightPastRepairRef": new FormControl(''),
      "leftOtherRemark": new FormControl(''),
      "rightOtherRemark": new FormControl(''),
      "leftAcessoriesIncluded": new FormControl(''),
      "rightAcessoriesIncluded": new FormControl(''),
      "leftAcessories": new FormControl(''),
      "rightAcessories": new FormControl(''),
      "searchRepair": new FormControl(''),
      "step1": new FormControl(''),
      "step2": new FormControl(''),
      "step3": new FormControl(''),
      "step4": new FormControl(''),
      "step5": new FormControl(''),
      "step6": new FormControl(''),
      "step7": new FormControl(''),
      "step8": new FormControl(''),
      "step9": new FormControl(''),
      "step10": new FormControl(''),
      "step11": new FormControl(''),
      "step12": new FormControl(''),
      "step13": new FormControl(''),
      "scenario": new FormControl('Scenario1'),
      "leftCharging": new FormControl(false),
      "leftBluetooth": new FormControl(false),
      "leftServicing": new FormControl(false),
      "rightCharging": new FormControl(false),
      "rightBluetooth": new FormControl(false),
      "rightServicing": new FormControl(false),
      "leftDeviceRefId": new FormControl(),
      "rightDeviceRefId": new FormControl(),
      "companyRefId": new FormControl('', Validators.required),
      "companyName": new FormControl(''),
      "canNumber": new FormControl(''),
      "make": new FormControl(''),
      "rightDeviceType": new FormControl(''),
      "leftDeviceType": new FormControl(''),
      "createdAt": new Date(),
      "patientName": new FormControl('', Validators.required),
      "leftDeviceAge": new FormControl(''),
      "rightDeviceAge": new FormControl(''),
      "extenalCreatedRightDate": new FormControl(''),
      "extenalCreatedLeftDate": new FormControl(''),
      "date": new FormControl(''),
      "pLeftStaus": new FormControl(''),
      "pRightStaus": new FormControl(''),
      "Signaturephoto": '',
      "deviceType": new FormControl(''),
      "repairActionStatus": new FormControl(''),
      "repairActionLastStatus": new FormControl(''),
      "billingCompanyRefId": new FormControl(''),
        
    });
    let latest_date = this.datepipe.transform(this.date, 'dd/MM/yyyy');
    this.repairComplaintForm.get("date").setValue(latest_date);
    this.repairComplaintForm.get("deviceType").setValue('left');
    this.deviceType('left');
    let ss = this.repairComplaintForm.get('scenario').value;
    this.fetchBillingCompanyDetails();
    this.fetchRepairList();
    this.fetchAllAccesories();
    //   this.fetchStepTrackerlist(ss);
    this.getDoctorList();
    this.disableLeftRightData();
    //this.fetchModelList();
    // this.fetchModelList2();
    this.fetchAllCompanies();
    this.getLastId();
    //  if(this.userType !== 'Super Admin'){
    //   this.fetchPatientList();
    //  }else{
    //   this.fetchBranchList();
    //  }
    this.fetchPatientList();
    if (this.userType == 'Super Admin') {
      // this.fetchBranchList();
      this.getAllBranch();
    }
    if (this.repairNo) {
      this.prepopulation(this.repairNo);
    }

  } 
  getAllBranch() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      if (res.status) {
        this.BranchList = res.data;
        this.filteredListBranch = this.BranchList.slice();
        let length = res.data.length - 1;
        this.branchName = res.data[length].branchName;
        //console.log("res.data[length]._idkkkkkkkkkkkkkkkkkk",res.data[length]._id);

        this.repairComplaintForm.controls["branchRefId"].setValue(res.data[length]._id);
        this.branchClick(res.data[length]._id, this.branchName);
        // if(this.userType == 'Super Admin')
        // {  
        //   this.apimicroservice.getBranchById(res.data[length]._id).subscribe(res => {
        //     this.spinner.hide();
        //     this.repairComplaintForm.get("branchRefId").setValue(res.data[length].branchName);
        //    });
        // }
        if (this.repair_branchRefId !== '' && this.repair_patientRefId !== '') {
          this.repairComplaintForm.controls["branchRefId"].setValue(this.repair_branchRefId);
          this.repairComplaintForm.controls["patientRefId"].setValue(this.repair_patientRefId);
          this.branchClick(this.repair_branchRefId, this.repair_branchName);
          this.repairComplaintForm.get("patientName").setValue(this.repair_patientName);
          this.onSelectPatient(this.repair_patientRefId);
          sessionStorage.setItem('repair_branchRefId', '');
          sessionStorage.setItem('repair_patientRefId', '');
          sessionStorage.setItem('repair_branchName', '');
          sessionStorage.setItem('repair_patientName', '');
        }

      }
    });
  }
  fetchStepTrackerlist(sname) {
    this.apimicroservice.getRepairStatusFlowByScenario(sname).subscribe(res => {
      this.statusArr = [];
      if (res.data.length > 0) {
        if (res.data[0].stepperInfo.length > 0) {
          this.statusArr = res.data[0].stepperInfo;
          //console.log('this.statusArr',res.data[0]);

          //this.stepperRefId =  res.data[0]._id;
          this.norecord = true;
        }
      }
    })
  }
  showTracker(status) {
    let ss = "";
    ss = status;
    //console.log("ss",ss);

    for (let i = 0; i < this.statusArr.length; i++) {
      if (ss.indexOf(this.statusArr[i].labeltwo) > -1) {
        // this.statusArr[i].status = 'completed';
        this.matchId = this.statusArr[i].id;
      }
    }
    for (let i = 0; i <= this.matchId; i++) {
      this.statusArr[i].status = 'completed';
    }
    let j = this.matchId + 1;
    this.statusArr[j].status = "active";
    //console.log("this.statusArr",this.statusArr);
  }

  fetchPatientList() {
    this.searchText = '';
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    //console.log("kkkkkkkkk",this.branchRef);
    if(this.userType !== 'Super Admin')
    {
      this.apimicroservice.getPatientListByBranchClinic(branchRef,clinicRef).subscribe(res => {
        //console.log("fetch patienttttttttt list res",res);

        if (res.status) {    
          this.patientList = res.data;
          this.filteredListPatient = this.patientList.slice();
        } else {
         // this.apimicroservice.openSnackbar(res.message);
        }
      })
    }else{
    this.apimicroservice.getPatientListByBranchClinic(branchRefSuperAdmin, clinicRefSuperAdmin).subscribe(res => {
      //console.log("fetch patienttttttttt list res",res);

      if (res.status) {
        this.patientList = res.data;
        this.filteredListPatient = this.patientList.slice();
      } else {
        // this.apimicroservice.openSnackbar(res.message);
      }
    })
    }

  }

  submitRepairList() {
    let payload = this.repairComplaintForm.value;
    if (this.userType == 'Super Admin') {
      this.repairComplaintForm.controls['branchRefId'].setValidators(Validators.required);
      this.repairComplaintForm.controls['branchRefId'].updateValueAndValidity();
    }
    else {
      this.repairComplaintForm.controls['branchRefId'].setErrors(null);
      this.repairComplaintForm.controls['branchRefId'].clearValidators();
      this.repairComplaintForm.controls['branchRefId'].updateValueAndValidity();
    }
    if (payload.leftModel == '' && payload.rightModel == '') {
      this.repairComplaintForm.controls['leftModel'].setValidators(Validators.required);
      this.repairComplaintForm.controls['leftModel'].updateValueAndValidity();
      this.repairComplaintForm.controls['leftSrNo'].setValidators([Validators.required, Validators.pattern("^[a-zA-Z0-9 -]+$")]);
      this.repairComplaintForm.controls['leftSrNo'].updateValueAndValidity();
      // this.repairComplaintForm.controls['rightModel'].setValidators(Validators.required);
      // this.repairComplaintForm.controls['rightModel'].updateValueAndValidity();
      this.repairComplaintForm.controls['rightSrNo'].setValidators([Validators.pattern("^[a-zA-Z0-9 -]+$")]);
      this.repairComplaintForm.controls['rightSrNo'].updateValueAndValidity();
    } else {
      this.repairComplaintForm.controls['leftModel'].setErrors(null);
      this.repairComplaintForm.controls['leftModel'].clearValidators();
      this.repairComplaintForm.controls['leftModel'].updateValueAndValidity();
      this.repairComplaintForm.controls['leftSrNo'].setErrors(null);
      this.repairComplaintForm.controls['leftSrNo'].clearValidators();
      this.repairComplaintForm.controls['leftSrNo'].updateValueAndValidity();
      // this.repairComplaintForm.controls['rightModel'].setErrors(null);
      // this.repairComplaintForm.controls['rightModel'].clearValidators();
      // this.repairComplaintForm.controls['rightModel'].updateValueAndValidity();
      this.repairComplaintForm.controls['rightSrNo'].setErrors(null);
      this.repairComplaintForm.controls['rightSrNo'].clearValidators();
      this.repairComplaintForm.controls['rightSrNo'].updateValueAndValidity();
    }
    if (this.repairComplaintForm.invalid) {

      this.repairComplaintForm.get("patientName").markAsTouched();
      this.repairComplaintForm.get("companyRefId").markAsTouched();
      this.repairComplaintForm.get("leftModel").markAsTouched();
      this.repairComplaintForm.get("leftSrNo").markAsTouched();
      this.repairComplaintForm.get("billingCompanyRefId").markAsTouched();
      
    } else {
      this.addRepairList();
    }

  }

  getDoctorList() {

    this.apimicroservice.getLastDoctorAll().subscribe(res => {
      //console.log('doctor res',res);
      this.listOfdoctor = res['data']['data'];
      this.filteredListDoctor = this.listOfdoctor.slice();
      //console.log('doctor this.listOfdoctor',this.listOfdoctor);
    })
  }

  addRepairList() {
    // this.spinner.show();
    this.spinner.show();
    let ss = '';
    let payload = this.repairComplaintForm.value;
    payload.repairActionStatus = 'Inprocess';
    
    //console.log("warrentRightwarrentRight",this.warrentRight);
    let todaydate = new Date();
    // this.repairComplaintForm.controls["leftWarranty"]
    if (todaydate.toISOString() <= this.warrentRight) {
      payload.rightWarranty = 'Yes'
    }
    else {
      payload.rightWarranty = 'No'
    }

    // this.repairComplaintForm.controls["leftWarranty"]
    if (todaydate.toISOString() <= this.warrentLeft) {
      payload.leftWarranty = 'Yes'
    }
    else {
      payload.leftWarranty = 'No'
    }
    payload.companyName = this.companyName;
    payload.canNumber = this.canNo;
    payload.leftDeviceAge = this.LeftDeviceAge;
    payload.rightDeviceAge = this.RightDeviceAge;
    payload.extenalCreatedRightDate = this.extenalCreatedRightDate;
    payload.extenalCreatedLeftDate = this.extenalCreatedLeftDate;
    payload.Signaturephoto = this.signatureImg;
    payload.Signaturephoto = this.signatureImg;
    payload.leftDateOfPurchase = this.leftDateOfPurchase;
    payload.rightDateOfPurchase = this.rightDateOfPurchase;
    // ss = payload.status;
    // for(let i=0;i<this.statusArr.length;i++){
    //   if(ss.indexOf(this.statusArr[i].labeltwo) > -1 ){
    //           this.statusArr[i].status = 'completed';
    //           this.statusArr[i].repairDate = this.repairD;
    //   }
    // }
    // payload.repairStatusInfo = this.statusArr;
    payload.repairDate = this.repairD;
    //console.log("repair payload",payload);

    if (this.userType !== 'Super Admin') {
      payload.branchRefId = this.branchRef;
      payload.clinicRefId = this.clinicRef;
      payload.branchName = this.branchName;

    }
    console.log("payloadpayload", payload);

    this.apimicroservice.submitRepairData(payload).subscribe(res => {
      //console.log("res after add repair data :",res);
      this.spinner.hide();
      if (res.status) { 
        this.signatureImg = "";
        this.apimicroservice.openSnackbar(res.message);
        let sspayload = {};
        sspayload['repairRefId'] = res.data.data._id;
        sspayload['repairId'] = res.data.data.repairId;
        this.toRepairForm();
        this.ngOnInit();
        // this.apimicroservice.updateRepairStatusFlow(this.stepRefId,sspayload).subscribe(res => {
        //   //console.log("repair status",res);
        //   this.ngOnInit();
        // });

      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    });
  }


  updateRepairComplaint() {
    if (this.userType == 'Super Admin') {
      this.repairComplaintForm.controls['branchRefId'].setValidators(Validators.required);
      this.repairComplaintForm.controls['branchRefId'].updateValueAndValidity();
    }
    else {
      this.repairComplaintForm.controls['branchRefId'].setErrors(null);
      this.repairComplaintForm.controls['branchRefId'].clearValidators();
      this.repairComplaintForm.controls['branchRefId'].updateValueAndValidity();
    }
    if (this.repairComplaintForm.invalid) {
      this.repairComplaintForm.get("patientName").markAsTouched();
      this.repairComplaintForm.get("companyRefId").markAsTouched();
      this.repairComplaintForm.get("leftModel").markAsTouched();
      this.repairComplaintForm.get("leftSrNo").markAsTouched();
      this.repairComplaintForm.get("billingCompanyRefId").markAsTouched();

      return;
    } else { 
      this.update();
    }
  }

  update() {
    // this.spinner.show();
    let ss = '';
    let payload = this.repairComplaintForm.value;
    payload.companyName = this.companyName;
    payload.canNumber = this.canNo;
    //ss = payload.status;
    // for(let i=0;i<this.statusArr.length;i++){
    //   if(ss.indexOf(this.statusArr[i].labeltwo) > -1 ){
    //           this.statusArr[i].status = 'completed';
    //           this.statusArr[i].repairDate = this.repairD; 
    //   }
    // }
    // payload.repairStatusInfo = this.statusArr;
    payload.repairDate = this.repairD;
    //console.log("payload updated",payload);
    if (this.userType !== 'Super Admin') {
      payload.branchRefId = this.branchRef;
      payload.clinicRefId = this.clinicRef;
      payload.branchName = this.sessionBranchName;
    }
    payload.Signaturephoto = this.signatureImg;
    //  payload.uuid_owner = 1;
    //console.log(payload);
    this.apimicroservice.updateRepairData(this.repairId, payload).subscribe(res => {
      //console.log("Update res :",res);
      this.toRepairForm();
      this.ngOnInit();
      if (res.status) {
        this.signatureImg = "";
        this.apimicroservice.openSnackbar(res.message);

        this.updateButton = false;
        this.submitButton = true;
        this.cancelButton = false;
        this.ngOnInit();

      } else {
        //  //this.apimicroservice.openSnackbar(res.message);
      }
    });

  }

  fetchRepairList() {
    let pg = 1;
    let size = 10;
    let search = '';
    //   this.spinner.show();
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    this.searchText = this.repairComplaintForm.get('searchRepair').value;

    // if (this.userType !== 'Super Admin') {
      this.apimicroservice.getRepairData(pg, size, search, branchRef).subscribe(res => {
        //console.log("Fetch repair res ggggggg:",this.listOfRepair);
        this.listOfRepair = res.data.data;
        this.totalLength = res.data.totalItems;


        //this.buildingLenght = this.listofbuild.length;
        if (res.status) {
          //  this.apimicroservice.openSnackbar(res.message);

        } else {
          //this.apimicroservice.openSnackbar(res.message);
        }
      });
    // } else {
    //   this.apimicroservice.getRepairData(pg, size, this.searchText, branchRefSuperAdmin).subscribe(res => {
    //     //console.log("Fetch repair res :",res);
    //     this.listOfRepair = res.data.data;
    //     this.totalLength = res.data.totalItems;
    //     //console.log("ssssssssss",this.totalLength );
    //     //this.buildingLenght = this.listofbuild.length;
    //     if (res.status) {
    //       // this.apimicroservice.openSnackbar(res.message);

    //     } else {
    //       //this.apimicroservice.openSnackbar(res.message);
    //     }
    //   });
    // }
  }
  // fetchPatientList() {
  //   this.searchText = '';
  //   this.apimicroservice.getPatientList(this.page5, this.size5, this.searchText,this.branchRef,this.clinicRef).subscribe(res => {
  //     //console.log("fetch patient list res",res);

  //     if (res.status) {    
  //       this.patientList = res.data.data;
  //     } else {
  //       this.apimicroservice.openSnackbar(res.message);
  //     }
  //   })
  // }
  // branchClick(id,branchName)
  // {
  //   this.repairComplaintForm.controls["branchName"].setValue(branchName);
  //   this.branchRef = id;
  //   this.searchText = '';
  //   this.apimicroservice.getPatientList(this.page5, this.size5, this.searchText,this.branchRef,this.clinicRef).subscribe(res => {
  //     //console.log("fetch patient list res",res);

  //     if (res.status) {    
  //       this.patientList = res.data.data;
  //     } else {
  //       this.apimicroservice.openSnackbar(res.message);
  //     }
  //   })
  // }
  onSelectPatient(id) {
    //console.log('hahahahah',id)
    this.patientSelect = true;
    this.spinner.show();
    this.apimicroservice.getPatientById(id).subscribe(res => {
      this.spinner.hide();
      let data = res.data[0];
      this.pid = id;


      this.repairComplaintForm.controls["patientName"].setValue(data.firstName + ' ' + data.lastName);
      this.repairComplaintForm.controls["patientRefId"].setValue(data._id);
      this.repairComplaintForm.controls["firstName"].setValue(data.firstName);
      this.repairComplaintForm.controls["lastName"].setValue(data.lastName);
      this.repairComplaintForm.controls["sex"].setValue(data.sex);
      this.repairComplaintForm.controls["mobile1"].setValue(data.mobile1);
      this.repairComplaintForm.controls["streetAddress"].setValue(data.streetAddress);
      this.pId = data._id;
      this.patientID = data.patientId;
      this.fetchModelList(data._id);
      let payload = this.repairComplaintForm.value;
      if (payload.patientName !== '') {
        this.isDisable = false;
      }
      else {
        this.isDisable = true;
      }
    });
    this.spinner.hide();
  }

  // onSelectDoctor(id){
  //   this.doctorSelect=true;
  //   //console.log("selected doctor", id);
  //   this.apimicroservice.getDoctorById(id).subscribe(res => {
  //     let data=res.data[0];
  //     this.did = data._id;

  //     // this.repairComplaintForm.controls["doctorClinicName"].setValue(data.branchName);
  //     this.repairComplaintForm.controls["doctorBranchName"].setValue(data.branchName);
  //     this.repairComplaintForm.controls["doctorMobileNo"].setValue(data.mobile1);
  //     this.repairComplaintForm.controls["doctorAddress"].setValue(data.streetAddress);

  //   }); 
  // }


  prepopulation(id) {
    // this.spinner.show();
    this.showstepper = false;
    this.fromPrepopulation = true;
    this.apimicroservice.getRepairDataById(id).subscribe(res => {
      //console.log("data reapair",res);
      let data = res.data[0]; 
      this.repairId = id;
      this.repairRefId = data._id;
      this.myrepairId = data.repairId;

      this.updateButton = true;
      this.submitButton = false;
      this.cancelButton = true;
      this.scenariodisable = true;
      this.branchClick(data.branchRefId, data.branchName);
      // this.fetchPatientList();
      this.onSelectPatient(data.patientRefId);

      //  this.onSelectDoctor(data.doctorRefId);
      this.repairComplaintForm = this.fb.group({
        "leftDeviceAge": new FormControl(data.leftDeviceAge),
        "rightDeviceAge": new FormControl(data.rightDeviceAge),
        "branchWiseId": new FormControl(data.branchWiseId),
        "status": new FormControl(data.status),
        "firstName": new FormControl(data.firstName),
        "lastName": new FormControl(data.lastName),
        "sex": new FormControl(data.sex),
        "mobile1": new FormControl(data.mobile1, [Validators.pattern("(0|91)?[6-9][0-9]{9}")]),
        "streetAddress": new FormControl(data.streetAddress),
        "clinicName": new FormControl(data.clinicName),


        // "doctorBranchName": new FormControl(data.doctorBranchName),
        // "doctorMobileNo": new FormControl(data.doctorMobileNo),
        // "doctorAddress": new FormControl(data.doctorAddress),

        "patientRefId": new FormControl(data.patientRefId),
        // "doctorRefId":new FormControl(data.doctorRefId),
        "branchRefId": new FormControl(data.branchRefId),
        // "branchWiseId":new FormControl(data.branchWiseId),
        "branchName": new FormControl(data.branchName),

        "leftModel": new FormControl(data.leftModel, Validators.required),
        "rightModel": new FormControl(data.rightModel),
        "leftSrNo": new FormControl(data.leftSrNo, [Validators.required, Validators.pattern("^[a-zA-Z0-9 -]+$")]),
        "rightSrNo": new FormControl(data.rightSrNo, [Validators.pattern("^[a-zA-Z0-9 -]+$")]),
        "leftDead": new FormControl(data.leftDead),
        "rightDead": new FormControl(data.rightDead),
        "leftNoisy": new FormControl(data.leftNoisy),
        "rightNoisy": new FormControl(data.rightNoisy),
        "leftDistortion": new FormControl(data.leftDistortion),
        "rightDistortion": new FormControl(data.rightDistortion),
        "leftFeedback": new FormControl(data.leftFeedback),
        "rightFeedback": new FormControl(data.rightFeedback),
        "leftConnectivityIssue": new FormControl(data.leftConnectivityIssue),
        "rightConnectivityIssue": new FormControl(data.rightConnectivityIssue),
        "rightComplaintInterpretation": new FormControl(data.rightComplaintInterpretation),
        "leftTightFit": new FormControl(data.leftTightFit),

        "rightTightFit": new FormControl(data.rightTightFit),
        "leftLooseFit": new FormControl(data.leftLooseFit),
        "rightLooseFit": new FormControl(data.rightLooseFit),
        "leftOtherShellIssue": new FormControl(data.leftOtherShellIssue),
        "rightOtherShellIssue": new FormControl(data.rightOtherShellIssue),
        "leftOther": new FormControl(data.leftOther),
        "rightOther": new FormControl(data.rightOther),
        "leftAnyPhysicalDamage": new FormControl(data.leftAnyPhysicalDamage),
        "rightAnyPhysicalDamage": new FormControl(data.rightAnyPhysicalDamage),
        "leftVisibleCorrorsion": new FormControl(data.leftVisibleCorrorsion),
        "rightVisibleCorrorsion": new FormControl(data.rightVisibleCorrorsion),
        "leftBatteryLeakageVisible": new FormControl(data.leftBatteryLeakageVisible),
        "rightBatteryLeakageVisible": new FormControl(data.rightBatteryLeakageVisible),
        "leftMouldIncluded": new FormControl(data.leftMouldIncluded),
        "rightMouldIncluded": new FormControl(data.rightMouldIncluded),
        "leftMouldDamaged": new FormControl(data.leftMouldDamaged),
        "rightMouldDamaged": new FormControl(data.rightMouldDamaged),
        "leftWarranty": new FormControl(data.leftWarranty),
        "rightWarranty": new FormControl(data.rightWarranty),
        "leftDateOfPurchase": new FormControl(data.leftDateOfPurchase),
        "rightDateOfPurchase": new FormControl(data.rightDateOfPurchase),
        "leftRepairQuotation": new FormControl(data.leftRepairQuotation),
        "rightRepairQuotation": new FormControl(data.rightRepairQuotation),
        "leftPastRepairRef": new FormControl(data.leftPastRepairRef),
        "rightPastRepairRef": new FormControl(data.rightPastRepairRef),
        "leftOtherRemark": new FormControl(data.leftOtherRemark),
        "rightOtherRemark": new FormControl(data.rightOtherRemark),
        "leftAcessoriesIncluded": new FormControl(data.leftAcessoriesIncluded),
        "rightAcessoriesIncluded": new FormControl(data.rightAcessoriesIncluded),
        "leftAcessories": new FormControl(data.leftAcessories),
        "rightAcessories": new FormControl(data.rightAcessories),
        "scenario": new FormControl(data.scenario),
        "searchRepair": new FormControl(''),
        "step1": new FormControl(''),
        "step2": new FormControl(''),
        "step3": new FormControl(''),
        "step4": new FormControl(''),
        "step5": new FormControl(''),
        "step6": new FormControl(''),
        "step7": new FormControl(''),
        "step8": new FormControl(''),
        "step9": new FormControl(''),
        "step10": new FormControl(''),
        "step11": new FormControl(''),
        "step12": new FormControl(''),
        "step13": new FormControl(''),
        "updatedAt": new Date(),
        "leftCharging": new FormControl(data.leftCharging),
        "leftBluetooth": new FormControl(data.leftBluetooth),
        "leftServicing": new FormControl(data.leftServicing),
        "rightCharging": new FormControl(data.rightCharging),
        "rightBluetooth": new FormControl(data.rightBluetooth),
        "rightServicing": new FormControl(data.rightServicing),

        "leftDeviceRefId": new FormControl(data.leftDeviceRefId),
        "rightDeviceRefId": new FormControl(data.rightDeviceRefId),
        "companyRefId": new FormControl(data.companyRefId, Validators.required),
        "companyName": new FormControl(data.companyName),
        "canNumber": new FormControl(data.canNumber),
        "make": new FormControl(data.make),
        "rightDeviceType": new FormControl(data.rightDeviceType),
        "leftDeviceType": new FormControl(data.leftDeviceType),
        "patientName": new FormControl(data.firstName + ' ' + data.lastName, Validators.required),
        "extenalCreatedRightDate": new FormControl(data.extenalCreatedRightDate),
        "extenalCreatedLeftDate": new FormControl(data.extenalCreatedLeftDate),
        "date": new FormControl(''),
        "pLeftStaus": new FormControl(data.pLeftStaus),
        "pRightStaus": new FormControl(data.pRightStaus),
        "Signaturephoto": '',
        "repairActionStatus": new FormControl(data.repairActionStatus),
        "repairActionLastStatus": new FormControl(data.repairActionLastStatus),
        "deviceType": new FormControl(data.deviceType),
        "billingCompanyRefId": new FormControl(data.billingCompanyRefId),
      });
      this.signatureImg = data.Signaturephoto;
      let latest_date = this.datepipe.transform(data.createdAt, 'dd/MM/yyyy');
      this.repairComplaintForm.get("date").setValue(latest_date);
      // let warrentRight =res.data[0].deviceInfo[0].expiryDate; 
      // //console.log("warrentRightwarrentRight",warrentRight);
      // let todaydate =new Date();
      // if(todaydate.toISOString() <= warrentRight) 
      // { 
      //   this.repairComplaintForm.controls["rightWarranty"].setValue('Yes');
      // }
      // else 
      // { 
      //   this.repairComplaintForm.controls["rightWarranty"].setValue('No');
      // }

      this.onLeftSerialChange(event, data.leftWarranty, data.leftDateOfPurchase, data.pLeftStaus);
      this.onRightSerialChange(event, data.rightWarranty, data.rightDateOfPurchase, data.pRightStaus);
      this.onSelectPatient(data.patientRefId);
      // this.pId = result.data._id;
      this.fetchModelList(data.patientRefId);
      let payload = this.repairComplaintForm.value;
      if (payload.patientName !== '') {
        this.isDisable = false;
      }
      else {
        this.isDisable = true;
      }

      // this.fetchModelList(data.patientRefId);
      this.getRepairActionAllData(id);
      this.superBranchId = data.branchWiseId;
      this.bID = data.branchWiseId;
      //console.log("jjjj",data.leftDeviceRefId);
      this.branchRefId = data.branchRefId;
      this.repairBranch = data.branchName;
      this.patientMobile = data.mobile1;
      this.LeftDeviceAge = data.leftDeviceAge;
      this.RightDeviceAge = data.rightDeviceAge;
      //console.log("this.RightDeviceAgethis.RightDeviceAgeooo",this.RightDeviceAge);

      this.leftType = data.leftDeviceType;
      this.rightType = data.rightDeviceType;

      this.leftModel = data.leftModel;
      this.leftSrNo = data.leftSrNo;
      this.rightModel = data.rightModel;
      this.rightSrNo = data.rightSrNo;
      //  let fromPrepo;
      this.fromPrepo = true;
      this.preLeftSrno = data.leftSrNo;
      this.preRightSrno = data.rightSrNo;
      this.pLeftStaus = data.pLeftStaus;
      this.pRightStaus = data.pRightStaus;
      this.leftdeviceRefId = data.leftDeviceRefId;
      this.rightDeviceRefId = data.rightDeviceRefId;
      this.billNum = data.billNo;
      this.dateOfPurchase = data.dateOfPurchase;
      this.extenalCreatedRightDate = data.extenalCreatedRightDate;
      this.extenalCreatedLeftDate = data.extenalCreatedLeftDate;

      // this.onLeftModelChange(event,data.leftDeviceRefId,data.leftSrNo,data.leftSrNo,data.leftDeviceType,data.billNo,data.dateOfPurchase,fromPrepo);
      // this.onRightModelChange(event,data.rightDeviceRefId,data.rightSrNo,data.rightSrNo,data.rightDeviceType,data.billNo,data.dateOfPurchase,fromPrepo);


      //console.log("this.preLeftSrno",this.preLeftSrno);
      //console.log("this.preRightSrno",this.preRightSrno)
      //this.statusArr = data.repairStatusInfo;

      //this.showTracker(data.status);

      this.fetchStatusByID(id);
      //  this.onRightModelChange(event);
      // this.onLeftModelChange($event);
    });

  }
  addNew() {
    this.repairComplaintForm.reset();
    this.updateButton = false;
    this.deleteButton = false;
  }


  onPageChange(event) {
    // this.spinner.show();
    this.page = event;
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    this.searchText = this.repairComplaintForm.get('searchRepair').value;

    // if (this.userType !== 'Super Admin') {
      this.apimicroservice.getRepairData(this.page, this.size, this.searchText, branchRef).subscribe(res => {
        //console.log("Fetch repair in table res :",res);
        this.listOfRepair = res.data.data;
        this.totalLength = res.data.totalItems;

        if (res.status) {
          //  this.apimicroservice.openSnackbar(res.message);

        } else {
          //this.apimicroservice.openSnackbar(res.message);
        }

      });
    // } else {
    //   this.apimicroservice.getRepairData(this.page, this.size, this.searchText, branchRefSuperAdmin).subscribe(res => {
    //     //console.log("Fetch repair in table res :",res);
    //     this.listOfRepair = res.data.data;
    //     this.totalLength = res.data.totalItems;


    //     if (res.status) {
    //       //   this.apimicroservice.openSnackbar(res.message);

    //     } else {
    //       //this.apimicroservice.openSnackbar(res.message);
    //     }

    //   });
    // }
  }
  onPageChangeAction(event) {
    this.pageAction = event;
    this.searchText = this.repairComplaintForm.get('searchRepair').value;
    this.apimicroservice.getRepairActionAllRepairRefId(this.repairId).subscribe(res => {
      this.repairActionData = res.data.data;
      this.totalLengthAction = res.data.totalItems;
    });
  }

  getLastId() {
    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getLastRepairID(this.branchRef).subscribe(res => {
        //console.log("getLastId res",res);   

        if (res['status'] == true) {
          let repairIdd = res['data']['totalItems'];
          //console.log("repairId",repairIdd);

          this.repairComplaintForm.controls['branchWiseId'].setValue(repairIdd + 1);
          this.bID = repairIdd + 1;
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }

      })
    } else {
      let branchRef= sessionStorage.getItem('branchRef');
      this.apimicroservice.getLastRepairAll(branchRef).subscribe(res => {
        //console.log("getLastId res",res);   

        if (res['status'] == true) {

          let repairIdd = res['data']['totalItems'];
          this.superBranchId = repairIdd + 1;
          this.bID = repairIdd + 1;
          // this.repairComplaintForm.controls['branchWiseId'].setValue( this.superBranchId );
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }

      })
    }

  }

  branchClick(id, branchName) {

    this.repairComplaintForm.controls["branchName"].setValue(branchName);
    this.branchRef = id;
    this.searchText = '';
    let branchRef = '';
    let clinicRef = '';
    //   if(this.userType == 'Super Admin')
    //   {
    //   this.apimicroservice.getPatientList(this.page5,this.size5,this.searchText,branchRef,clinicRef).subscribe(res => {
    //     //console.log("fetch patient list res",res);

    //     if (res.status) {    
    //       this.patientList = res.data.data;
    //       this.filteredListPatient = this.patientList.slice();
    //       // this.onSelectPatient(res.data.data[0]._id);
    //       // this.repairComplaintForm.get("patientRefId").setValue(res.data.data[0]._id);

    //     } else {
    //     //  this.apimicroservice.openSnackbar(res.message);
    //     }
    //   })
    // }
    // else
    // {
    //   this.apimicroservice.getPatientList(this.page5,this.size5,this.searchText,this.branchRef,this.clinicRef).subscribe(res => {
    //     //console.log("fetch patient list res",res);

    //     if (res.status) {    
    //       this.patientList = res.data.data;
    //       this.filteredListPatient = this.patientList.slice();
    //       // this.onSelectPatient(res.data.data[0]._id);
    //       // this.repairComplaintForm.get("patientRefId").setValue(res.data.data[0]._id);

    //     } else {
    //     //  this.apimicroservice.openSnackbar(res.message);
    //     }
    //   })
    // }


    this.apimicroservice.getBranchById(id).subscribe(res => {
      //console.log("res",res);

      let data = res.data[0];
      let payload = this.repairComplaintForm.value;
      payload.clinicRefId = res.data[0].clinicRefId;
      this.repairComplaintForm.controls["branchRefId"].setValue(res.data[0]._id);
      //  this.repairComplaintForm.controls["clinicRefId"].setValue(res.data[0].clinicRefId);
      if (this.updateButton == false) {
        //  this.spinner.show();
        this.apimicroservice.getLastRepairID(payload.branchRefId).subscribe(res => {


          if (res['status']) {
            let repairIdd = res['data']['totalItems'];
            this.repairComplaintForm.controls['branchWiseId'].setValue(repairIdd + 1);
          } else {
            this.apimicroservice.openSnackbar("Something went wrong");
          }
          //  this.spinner.hide();
        })
      }
    });

  }

  onKeyUpEvent(event: any) {

    this.searchText = this.repairComplaintForm.get('searchRepair').value;
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    let page = 1;
    let size = 10
      this.apimicroservice.getRepairData(page, size, this.searchText, branchRef).subscribe(res => {
        //console.log("Fetch earMould res :",res);
        this.listOfRepair = res.data.data;
        this.totalLength = res.data.totalItems;
        this.page = res.data.pageNumber;
        if (res.status) {
          //  this.apimicroservice.openSnackbar(res.message); 
        } else {
          //this.apimicroservice.openSnackbar(res.message);
        }
      });

  }
  redirect(to): void {
    this.router.navigate([to]);
  }

  leftReset() {
    this.repairComplaintForm.controls["leftModel"].reset();
    this.repairComplaintForm.controls["leftSrNo"].reset();
    this.repairComplaintForm.controls["leftDead"].reset();
    this.repairComplaintForm.controls["leftNoisy"].reset();
    this.repairComplaintForm.controls["leftDistortion"].reset();
    this.repairComplaintForm.controls["leftFeedback"].reset();
    this.repairComplaintForm.controls["leftConnectivityIssue"].reset();
    this.repairComplaintForm.controls["leftTightFit"].reset();
    this.repairComplaintForm.controls["leftLooseFit"].reset();
    this.repairComplaintForm.controls["leftOtherShellIssue"].reset();
    this.repairComplaintForm.controls["leftOther"].reset();
    this.repairComplaintForm.controls["leftCharging"].reset();
    this.repairComplaintForm.controls["leftBluetooth"].reset();
    this.repairComplaintForm.controls["leftServicing"].reset();
    this.repairComplaintForm.controls["leftDeviceRefId"].reset();



  }
  leftReset1() {

    this.repairComplaintForm.controls["leftAnyPhysicalDamage"].reset();
    this.repairComplaintForm.controls["leftVisibleCorrorsion"].reset();
    this.repairComplaintForm.controls["leftBatteryLeakageVisible"].reset();
    this.repairComplaintForm.controls["leftMouldIncluded"].reset();
    this.repairComplaintForm.controls["leftMouldDamaged"].reset();
  }
  leftReset2() {
    this.repairComplaintForm.controls["leftWarranty"].reset();
    this.repairComplaintForm.controls["leftDateOfPurchase"].reset();
    this.repairComplaintForm.controls["leftRepairQuotation"].reset();
    this.repairComplaintForm.controls["leftPastRepairRef"].reset();
    this.repairComplaintForm.controls["leftOtherRemark"].reset();
    this.repairComplaintForm.controls["leftAcessoriesIncluded"].reset();
    this.repairComplaintForm.controls["leftAcessories"].reset();


  }

  rightReset() {
    this.repairComplaintForm.controls["rightModel"].reset();
    this.repairComplaintForm.controls["rightSrNo"].reset();
    this.repairComplaintForm.controls["rightDead"].reset();
    this.repairComplaintForm.controls["rightNoisy"].reset();
    this.repairComplaintForm.controls["rightDistortion"].reset();
    this.repairComplaintForm.controls["rightFeedback"].reset();
    this.repairComplaintForm.controls["rightConnectivityIssue"].reset();
    this.repairComplaintForm.controls["rightTightFit"].reset();
    this.repairComplaintForm.controls["rightLooseFit"].reset();
    this.repairComplaintForm.controls["rightOtherShellIssue"].reset();
    this.repairComplaintForm.controls["rightOther"].reset();
    this.repairComplaintForm.controls["rightCharging"].reset();
    this.repairComplaintForm.controls["rightBluetooth"].reset();
    this.repairComplaintForm.controls["rightServicing"].reset();
    this.repairComplaintForm.controls["rightDeviceRefId"].reset();

  }
  rightReset1() {
    this.repairComplaintForm.controls["rightAnyPhysicalDamage"].reset();
    this.repairComplaintForm.controls["rightVisibleCorrorsion"].reset();
    this.repairComplaintForm.controls["rightBatteryLeakageVisible"].reset();
    this.repairComplaintForm.controls["rightMouldIncluded"].reset();
    this.repairComplaintForm.controls["rightMouldDamaged"].reset();

  }
  rightReset2() {

    this.repairComplaintForm.controls["rightWarranty"].reset();
    this.repairComplaintForm.controls["rightDateOfPurchase"].reset();
    this.repairComplaintForm.controls["rightRepairQuotation"].reset();
    this.repairComplaintForm.controls["rightPastRepairRef"].reset();
    this.repairComplaintForm.controls["rightOtherRemark"].reset();
    this.repairComplaintForm.controls["rightAcessoriesIncluded"].reset();
    this.repairComplaintForm.controls["rightAcessories"].reset();
  }

  copyToRight() {

    //console.log('inside copy to right');
    let payload = this.repairComplaintForm.value;

    this.repairComplaintForm.controls["rightModel"].setValue(payload.leftModel);
    //this.repairComplaintForm.controls["rightSrNo"].setValue(payload.leftSrNo);
    this.repairComplaintForm.controls["rightDead"].setValue(payload.leftDead);
    this.repairComplaintForm.controls["rightNoisy"].setValue(payload.leftNoisy);
    this.repairComplaintForm.controls["rightDistortion"].setValue(payload.leftDistortion);
    this.repairComplaintForm.controls["rightFeedback"].setValue(payload.leftFeedback);
    this.repairComplaintForm.controls["rightConnectivityIssue"].setValue(payload.leftConnectivityIssue);
    this.repairComplaintForm.controls["rightTightFit"].setValue(payload.leftTightFit);
    this.repairComplaintForm.controls["rightLooseFit"].setValue(payload.leftLooseFit);
    this.repairComplaintForm.controls["rightOtherShellIssue"].setValue(payload.leftOtherShellIssue);
    this.repairComplaintForm.controls["rightOther"].setValue(payload.leftOther);
    this.repairComplaintForm.controls["rightCharging"].setValue(payload.leftCharging);
    this.repairComplaintForm.controls["rightBluetooth"].setValue(payload.leftBluetooth);
    this.repairComplaintForm.controls["rightServicing"].setValue(payload.leftServicing);
    this.repairComplaintForm.controls["rightDeviceRefId"].setValue(payload.leftDeviceRefId);


  }
  copyToRight1() {

    //console.log('inside copy to right');
    let payload = this.repairComplaintForm.value;

    this.repairComplaintForm.controls["rightAnyPhysicalDamage"].setValue(payload.leftAnyPhysicalDamage);
    this.repairComplaintForm.controls["rightVisibleCorrorsion"].setValue(payload.leftVisibleCorrorsion);
    this.repairComplaintForm.controls["rightBatteryLeakageVisible"].setValue(payload.leftBatteryLeakageVisible);

    this.repairComplaintForm.controls["rightMouldIncluded"].setValue(payload.leftMouldIncluded);
    this.repairComplaintForm.controls["rightMouldDamaged"].setValue(payload.leftMouldDamaged);

  }
  copyToRight2() {

    //console.log('inside copy to right');
    let payload = this.repairComplaintForm.value;
    this.repairComplaintForm.controls["rightWarranty"].setValue(payload.leftWarranty);
    this.repairComplaintForm.controls["rightDateOfPurchase"].setValue(payload.leftDateOfPurchase);
    this.repairComplaintForm.controls["rightRepairQuotation"].setValue(payload.leftRepairQuotation);
    this.repairComplaintForm.controls["rightPastRepairRef"].setValue(payload.leftPastRepairRef);
    this.repairComplaintForm.controls["rightOtherRemark"].setValue(payload.leftOtherRemark);
    this.repairComplaintForm.controls["rightAcessoriesIncluded"].setValue(payload.leftAcessoriesIncluded);
    this.repairComplaintForm.controls["rightAcessories"].setValue(payload.leftAcessories);

  }

  copyToLeft() {

    //console.log('inside copy to left');
    let payload = this.repairComplaintForm.value;
    this.repairComplaintForm.controls["leftModel"].setValue(payload.rightModel);
    //this.repairComplaintForm.controls["leftSrNo"].setValue(payload.rightSrNo);
    this.repairComplaintForm.controls["leftDead"].setValue(payload.rightDead);
    this.repairComplaintForm.controls["leftNoisy"].setValue(payload.rightNoisy);
    this.repairComplaintForm.controls["leftDistortion"].setValue(payload.rightDistortion);
    this.repairComplaintForm.controls["leftFeedback"].setValue(payload.rightFeedback);
    this.repairComplaintForm.controls["leftConnectivityIssue"].setValue(payload.rightConnectivityIssue);
    this.repairComplaintForm.controls["leftTightFit"].setValue(payload.rightTightFit);
    this.repairComplaintForm.controls["leftLooseFit"].setValue(payload.rightLooseFit);
    this.repairComplaintForm.controls["leftOtherShellIssue"].setValue(payload.rightOtherShellIssue);
    this.repairComplaintForm.controls["leftOther"].setValue(payload.rightOther);
    this.repairComplaintForm.controls["leftCharging"].setValue(payload.rightCharging);
    this.repairComplaintForm.controls["leftBluetooth"].setValue(payload.rightBluetooth);
    this.repairComplaintForm.controls["leftServicing"].setValue(payload.rightServicing);
    this.repairComplaintForm.controls["leftDeviceRefId"].setValue(payload.rightDeviceRefId);


  }
  copyToLeft1() {

    //console.log('inside copy to left');
    let payload = this.repairComplaintForm.value;
    this.repairComplaintForm.controls["leftAnyPhysicalDamage"].setValue(payload.rightAnyPhysicalDamage);
    this.repairComplaintForm.controls["leftVisibleCorrorsion"].setValue(payload.rightVisibleCorrorsion);
    this.repairComplaintForm.controls["leftBatteryLeakageVisible"].setValue(payload.rightBatteryLeakageVisible);
    this.repairComplaintForm.controls["leftMouldIncluded"].setValue(payload.rightMouldIncluded);
    this.repairComplaintForm.controls["leftMouldDamaged"].setValue(payload.rightMouldDamaged);
  }
  copyToLeft2() {


    //console.log('inside copy to left');
    let payload = this.repairComplaintForm.value;
    this.repairComplaintForm.controls["leftWarranty"].setValue(payload.rightWarranty);
    this.repairComplaintForm.controls["leftDateOfPurchase"].setValue(payload.rightDateOfPurchase);
    this.repairComplaintForm.controls["leftRepairQuotation"].setValue(payload.rightRepairQuotation);
    this.repairComplaintForm.controls["leftPastRepairRef"].setValue(payload.rightPastRepairRef);
    this.repairComplaintForm.controls["leftOtherRemark"].setValue(payload.rightOtherRemark);
    this.repairComplaintForm.controls["leftAcessoriesIncluded"].setValue(payload.rightAcessoriesIncluded);
    this.repairComplaintForm.controls["leftAcessories"].setValue(payload.rightAcessories);

  }

  cancelUpdate() {
    this.signatureImg = "";
    this.ngOnInit();
    // this.repairComplaintForm.reset();
    this.submitButton = true;
    this.updateButton = false;
    this.cancelButton = false
  }




  toRepairForm() {
    document.getElementById("toRepairForm").scrollIntoView();
  }
  toRepairList() {
    document.getElementById("toRepairList").scrollIntoView();
  }
  fetchBranchList() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      if (res.status) {
        this.BranchList = res.data;
        this.filteredListBranch = this.BranchList.slice();
      } else {
        //  this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
  fetchModelList(pRefId) {
    //console.log("pRefIdpRefId",pRefId);
    this.DeviceList = [];
    this.filteredListDevice = [];
    this.allDevices = [];
    this.apimicroservice.getDeviceByPatientById(pRefId).subscribe(res => {
      if (res.status) {
        //  this.DeviceList = res.data;
        // //console.log("model list",res);
        res.data.forEach(element => {
          this.DeviceList.push({
            model: element.model, _id: element._id, newSerialNo: element.newSerialNo,
            oldSerialNo: element.oldSerialNo, type: element.type, billNo: element.billNo,
            dateOfPurchase: element.dateOfPurchase, createdAt: element.createdAt, warranty: element.warranty
            , paymentStatus: element.paymentStatus
          })
        })
        this.tempDeviceList = this.DeviceList;
        this.allDevices = [];
        const ids = this.DeviceList.map(o => o.model); 
        this.allDevices = this.DeviceList.filter(({ model }, index) => !ids.includes(model, index + 1))
        //console.log("ffffffffffffffffffffffffffffffffff",this.allDevices);
        //console.log("kkkkkkkkkkkkkkkkkkk",this.DeviceList);

        this.filteredListDevice = this.allDevices.slice();
        //console.log("this.DeviceList.lenght",this.DeviceList.length);

        if (this.fromPrepopulation == true) {
          this.onLeftModelChange(event, this.leftdeviceRefId, this.preLeftSrno, this.preLeftSrno, this.leftType, this.billNum,
            this.dateOfPurchase, this.extenalCreatedLeftDate, this.fromPrepo, this.pLeftStaus);
          this.onRightModelChange(event, this.rightDeviceRefId, this.preRightSrno, this.preRightSrno, this.rightType, this.billNum, this.dateOfPurchase,
            this.extenalCreatedRightDate, this.fromPrepo, this.pRightStaus);
        }

      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
  // fetchModelList2() {
  //   let branchRef = '';
  //   if(this.userType !== 'Super Admin')
  //   {
  //     this.apimicroservice.getAllDeviceList(this.branchRef).subscribe(res => {
  //       if (res.status) {    
  //         this.DeviceList2 = res.data.data;
  //         this.filteredListDevice2= this.DeviceList2.slice();
  //       } else {
  //        // this.apimicroservice.openSnackbar(res.message);
  //       }
  //     })
  //   }
  //   else
  //   {
  //     this.apimicroservice.getAllDeviceList(branchRef).subscribe(res => {
  //       if (res.status) {    
  //         this.DeviceList2 = res.data.data;
  //         this.filteredListDevice2= this.DeviceList2.slice();
  //       } else {
  //         //this.apimicroservice.openSnackbar(res.message);
  //       }
  //     })
  //   }

  // }
  fetchStatusByID(id) {
    this.apimicroservice.getRepairStatusFlowById(id).subscribe(res => {

      if (res.data.length > 0) {
        if (res.data[0].stepperInfo.length > 0) {
          this.statusArr = res.data[0].stepperInfo;
          this.stepperRefId = res.data[0]._id;
          this.norecord = false;
        }
      } else {
        this.norecord = true
        this.radioChange({ value: this.repairComplaintForm.get('scenario').value });

      }
    })
  }

  updateStatus(e, i) {
    this.statusArr.forEach(element => {
      if (element._id == e) {
        element['datetime'] = new Date();
        element['button'] = false;
        element['comments'] = this.repairComplaintForm.controls[element.controlname].value;
        element['status'] = "In-Progress";
      }
    });
    for (let j = 0; j < i; j++) {
      this.statusArr[j].status = 'completed';
    }
    let payload = {};
    payload['stepperInfo'] = this.statusArr;
    payload['repairDate'] = this.repairD;
    this.apimicroservice.updateRepairStatusFlow(this.stepperRefId, payload).subscribe(res => {
      //console.log(res);
    })
  }
  createStatus(i) {
    //console.log("this.repairComplaintForm",this.repairComplaintForm.value);
    let scenario = this.repairComplaintForm.controls['scenario'].value;
    this.statusArr[i].datetime = new Date();
    this.statusArr[i].button = false;
    this.statusArr[i].comments = this.repairComplaintForm.controls[this.statusArr[i].controlname].value;
    let payload = {};
    payload['repairRefId'] = this.repairRefId;
    payload['repairId'] = this.myrepairId;
    payload['stepperInfo'] = this.statusArr;
    payload['repairDate'] = this.repairD;
    payload['scenario'] = scenario;
    this.norecord = false;
    this.apimicroservice.addRepairStatusFlow(payload).subscribe(res => {
      //console.log(res);
      this.stepRefId = res.data.data._id;
      this.stepperRefId = res.data.data._id;
    })
  }

  radioChange(event: any) {
    //console.log("event",event.value);
    let ss = event.value;
    // this.fetchStepTrackerlist(ss);
    this.statusArr = [];
    if (event.value == "Scenario1") {
      this.statusArr = [
        {
          "labelOne": "Step 1",
          "datetime": "",
          "labeltwo": "Aid Received for repairs",
          "button": true,
          "controlname": "step1",
          "comments": "",
          "placeholderText": "Items collected from patient-Wires,Charger etc.",
          "status": "In-Progress",
          "_id": "6243002ae53ff64495b22797"
        },
        {
          "labelOne": "Step 2",
          "datetime": "",
          "labeltwo": "Job Receipt Issued​",
          "button": true,
          "controlname": "step2",
          "comments": "",
          "placeholderText": "Job Id generated by system & receipt given to",
          "status": "Pending",
          "_id": "6243002ae53ff64495b22798"
        },
        {
          "labelOne": "Step 3",
          "datetime": "",
          "labeltwo": "Sent to Manufacturer​",
          "button": true,
          "controlname": "step3",
          "comments": "",
          "placeholderText": "Courier Details-Tracking Id,Date etc.",
          "status": "Pending",
          "_id": "6243002ae53ff64495b22799"
        },
        {
          "labelOne": "Step 4",
          "datetime": "",
          "labeltwo": "Repair Estimate is Received from Company​",
          "button": true,
          "controlname": "step4",
          "comments": "",
          "placeholderText": "Status updated with estimates received",
          "status": "Pending",
          "_id": "6243002ae53ff64495b2279a"
        },
        {
          "labelOne": "Step 5",
          "datetime": "",
          "labeltwo": "Estimate to Client is calculated​",
          "button": true,
          "controlname": "step5",
          "comments": "",
          "placeholderText": "System calculates the charges to convey to patient",
          "status": "Pending",
          "_id": "6243002ae53ff64495b2279b"
        },
        {
          "labelOne": "Step 6",
          "datetime": "",
          "labeltwo": "Estimate is Advised by call/SMS/WA​",
          "button": true,
          "controlname": "step6",
          "comments": "",
          "placeholderText": "Informed patient via Email,SMS or Phone",
          "status": "Pending",
          "_id": "6243002ae53ff64495b2279c"
        },
        {
          "labelOne": "Step 7",
          "datetime": "",
          "labeltwo": "Payment is Received from Client​",
          "button": true,
          "controlname": "step7",
          "comments": "",
          "placeholderText": "Status updated with confirmation received from",
          "status": "Pending",
          "_id": "6243002ae53ff64495b2279d"
        },
        {
          "labelOne": "Step 8",
          "datetime": "",
          "labeltwo": "Estimate is Approved with the company on Email",
          "button": true,
          "controlname": "step8",
          "comments": "",
          "placeholderText": "Informed company via Email,SMS or Phone",
          "status": "Pending",
          "_id": "6243002ae53ff64495b2279e"
        },
        {
          "labelOne": "Step 9",
          "datetime": "",
          "labeltwo": "Device is repaired and Sent back by the company​",
          "button": true,
          "controlname": "step9",
          "comments": "",
          "placeholderText": "Courier details -tracking Id,Date etc.",
          "status": "Pending",
          "_id": "6243002ae53ff64495b2279f"
        },
        {
          "labelOne": "Step 10",
          "datetime": "",
          "labeltwo": "Received by NHS​",
          "button": true,
          "controlname": "step10",
          "comments": "",
          "placeholderText": "Status updated as HA received after Repair",
          "status": "Pending",
          "_id": "6243002ae53ff64495b227a0"
        },
        {
          "labelOne": "Step 11",
          "datetime": "",
          "labeltwo": "Checked and Programmed by audiologist​​",
          "button": true,
          "controlname": "step11",
          "comments": "",
          "placeholderText": "Repair verified & necessary steps taken by doctor",
          "status": "Pending",
          "_id": "6243002ae53ff64495b227a1"
        },
        {
          "labelOne": "Step 12",
          "datetime": "",
          "labeltwo": "Client is advised to collect it or Appointment is arranged​",
          "button": true,
          "controlname": "step12",
          "comments": "",
          "placeholderText": "Repair status informed to patient via Email,SMS or Phone",
          "status": "Pending",
          "_id": "6243002ae53ff64495b227a2"
        },
        {
          "labelOne": "Step 13",
          "datetime": "",
          "labeltwo": "Or Sent back by Courier​",
          "button": true,
          "controlname": "step13",
          "comments": "",
          "placeholderText": "Courier Details-Tracking Id,Date",
          "status": "Pending",
          "_id": "6243002ae53ff64495b227a3"
        }
      ];
    } else if (ss == "Scenario2") {
      this.statusArr = [
        {
          "labelOne": "Step 1",
          "datetime": "",
          "labeltwo": "Aid Received for repairs",
          "button": true,
          "controlname": "step1",
          "comments": "",
          "placeholderText": "Items collected from patient-Wires,Charger etc.",
          "status": "In-Progress",
          "_id": "6243002ae53ff64495b22797"
        },
        {
          "labelOne": "Step 2",
          "datetime": "",
          "labeltwo": "Job Receipt Issued​",
          "button": true,
          "controlname": "step2",
          "comments": "",
          "placeholderText": "Job Id generated by system & receipt given to",
          "status": "Pending",
          "_id": "6243002ae53ff64495b22798"
        },
        {
          "labelOne": "Step 3",
          "datetime": "",
          "labeltwo": "Estimate is given to the client",
          "button": true,
          "controlname": "step3",
          "comments": "",
          "placeholderText": "System calculates the charges to convey to patient",
          "status": "Pending",
          "_id": "6243002ae53ff64495b22799"
        },
        {
          "labelOne": "Step 4",
          "datetime": "",
          "labeltwo": "Payment is Received from Client​",
          "button": true,
          "controlname": "step4",
          "comments": "",
          "placeholderText": "Status updated with confirmation received from",
          "status": "Pending",
          "_id": "6243002ae53ff64495b2279a"
        },
        {
          "labelOne": "Step 5",
          "datetime": "",
          "labeltwo": "Estimate is Approved with the company on Email",
          "button": true,
          "controlname": "step5",
          "comments": "",
          "placeholderText": "Informed company via Email,SMS or Phone",
          "status": "Pending",
          "_id": "6243002ae53ff64495b2279e"
        },
        {
          "labelOne": "Step 6",
          "datetime": "",
          "labeltwo": "Sent to Manufacture​",
          "button": true,
          "controlname": "step6",
          "comments": "",
          "placeholderText": "Courier details -tracking Id,Date etc.",
          "status": "Pending",
          "_id": "6243002ae53ff64495b2279c"
        },
        {
          "labelOne": "Step 7",
          "datetime": "",
          "labeltwo": "Device is repaired and Sent back by the company​",
          "button": true,
          "controlname": "step7",
          "comments": "",
          "placeholderText": "Courier details -tracking Id,Date etc.",
          "status": "Pending",
          "_id": "6243002ae53ff64495b2279f"
        },
        {
          "labelOne": "Step 8",
          "datetime": "",
          "labeltwo": "Received by NHS​",
          "button": true,
          "controlname": "step8",
          "comments": "",
          "placeholderText": "Status updated as HA received after Repair",
          "status": "Pending",
          "_id": "6243002ae53ff64495b227a0"
        },
        {
          "labelOne": "Step 9",
          "datetime": "",
          "labeltwo": "Checked and Programmed by audiologist​​",
          "button": true,
          "controlname": "step9",
          "comments": "",
          "placeholderText": "Repair verified & necessary steps taken by doctor",
          "status": "Pending",
          "_id": "6243002ae53ff64495b227a1"
        },
        {
          "labelOne": "Step 10",
          "datetime": "",
          "labeltwo": "Client is advised to collect it or Appointment is arranged​",
          "button": true,
          "controlname": "step10",
          "comments": "",
          "placeholderText": "Repair status informed to patient via Email,SMS or Phone",
          "status": "Pending",
          "_id": "6243002ae53ff64495b227a2"
        },
        {
          "labelOne": "Step 11",
          "datetime": "",
          "labeltwo": "Or Sent back by Courier​",
          "button": true,
          "controlname": "step11",
          "comments": "",
          "placeholderText": "Courier Details-Tracking Id,Date",
          "status": "Pending",
          "_id": "6243002ae53ff64495b227a3"
        }
      ];
    }
  }

  onRightModelChange(event, id, nSno, oSno, type, billNo, dateOfPurchase, RightCreatedAt, fromPrepo, pStaus) {
    //console.log("RightCreatedAt",RightCreatedAt);
    //console.log("dateOfPurchase",dateOfPurchase);
    this.repairComplaintForm.controls["extenalCreatedRightDate"].setValue(RightCreatedAt);
    this.extenalCreatedRightDate = RightCreatedAt;
    //console.log("eventevent",event.target.value);
    //console.log("type",type);
    //console.log("this.bIDthis.bID",this.bID);
    this.repairComplaintForm.controls["rightDeviceRefId"].setValue(id);
    this.repairComplaintForm.controls["rightDeviceType"].setValue(type);
    this.repairComplaintForm.controls['rightSrNo'].setValue('');
    this.rightSerialNumList = [];
    let rmodel = this.repairComplaintForm.get("rightModel").value;
    //console.log("tempDeviceList===",this.tempDeviceList);
    //console.log("rmodel===",rmodel);
    this.tempDeviceList.forEach(ele => {
      if (ele.model == rmodel) {
        if (ele.type == "internal" || ele.type == "Fitted By Us") {
          const found = this.rightSerialNumList.some(el => el.serialNo === ele.oldSerialNo);
          if (!found) {
            this.rightSerialNumList.push({
              "model": ele.model, "serialNo": ele.oldSerialNo,
              "warranty": ele.warranty, "dateOfPurchase": ele.dateOfPurchase, "paymentStatus": ele.paymentStatus
            });
          }
        } else if (ele.type == "external" || ele.type == "Not Fitted By Us") {
          const found = this.rightSerialNumList.some(el => el.serialNo === ele.newSerialNo);
          if (!found) {
            this.rightSerialNumList.push({
              "model": ele.model, "serialNo": ele.newSerialNo,
              "warranty": ele.warranty, "dateOfPurchase": ele.dateOfPurchase, "paymentStatus": ele.paymentStatus
            });
          }
        }

      }
    })
    //console.log("right serialno arr list",this.rightSerialNumList);
    this.rightFilteredListSerialNo = this.rightSerialNumList.slice();
     console.log("this.rightFilteredListSerialNo",this.rightFilteredListSerialNo);
     
    this.apimicroservice.getBillDataById(billNo).subscribe(res => {
      //console.log("all companies res",res);
      this.warrentRight = res.data[0].deviceInfo[0].expiryDate;
      let status = res.data[0].deviceInfo[0].status;
      let todaydate = new Date();
      // this.repairComplaintForm.controls["leftWarranty"]
    
        let day = new Date(res.data[0].createdAt);
        let year = day.getFullYear();
        let month = day.getMonth();
        let datee = day.getDate();
   
      // this.expDate = new Date(this.Devicedata.expiryDate);
      let edate;
      if(res.data[0].isMigrated == true)
      {
         edate= new Date(year + 2, month, datee);
      }else
      {
        edate= new Date(year + parseInt(res.data[0].deviceInfo[0].warrantyPeriod), month, datee);
     }

     console.log("this.edate",edate);
     console.log("edate.toISOString()",edate.toISOString());
     this.expDate2 = edate.toISOString()
     console.log("status",status);
console.log("this.expDate",this.expDate2);
console.log("todaydate.toISOString()",todaydate.toISOString());
        if (todaydate.toISOString() <= this.expDate2 && status == 'Paid') {
        this.repairComplaintForm.get("rightWarranty").setValue('Yes');
        // this.repairComplaintForm.controls["rightWarranty"].setValue('Yes');
      }
      else if(todaydate.toISOString() <= this.expDate2 && status == 'Unpaid') {
        this.repairComplaintForm.get("rightWarranty").setValue('');
      }
      else {
        this.repairComplaintForm.get("rightWarranty").setValue('No');
      }

      let craetedAt;
      if (res.status) {
        if (type == 'Not Fitted By Us') {
          if (dateOfPurchase == null) {
            craetedAt = RightCreatedAt;
          }
          else {
            craetedAt = dateOfPurchase;
          }
        }
        else {
          craetedAt = res.data[0].createdAt;
        }
        if (fromPrepo == false) {
          this.currentDate = new Date();
          var date2 = this.datepipe.transform(this.currentDate, 'MM-dd-yyyy');
          var date1 = this.datepipe.transform(craetedAt, 'MM-dd-yyyy');

          var date11 = new Date(date1);
          var date22 = new Date(date2);

          var Time = date22.getTime() - date11.getTime();
          var Days = Time / (1000 * 3600 * 24); //Diference in Days
          this.RightDeviceAge = Days;
          //console.log("this.RightDeviceAge.RightDeviceAgelllll",this.RightDeviceAge);
        }


      } else {
        //  this.apimicroservice.openSnackbar(res.message);
      }
    })
    let str = event.target.value;
    let rightModel = event.target.value;
    if (this.fromPrepopulation == true) {

      this.repairComplaintForm.controls['rightSrNo'].setValue(oSno);
    } else {

      this.repairComplaintForm.controls['rightSrNo'].setValue('');
    }
    let rightSerialNo = this.repairComplaintForm.get('rightSrNo').value;


    // if(nSno == '')
    // {
    //   this.repairComplaintForm.controls["rightSrNo"].setValue(oSno);
    // } 
    // else
    // {
    //   this.repairComplaintForm.controls["rightSrNo"].setValue(nSno);
    // }
    if (rightModel !== '' && rightSerialNo !== '') {
      //this.rightPartData = false;
      this.repairComplaintForm.get('rightDead').enable();
      this.repairComplaintForm.get('rightNoisy').enable();
      this.repairComplaintForm.get('rightDistortion').enable();
      this.repairComplaintForm.get('rightFeedback').enable();
      this.repairComplaintForm.get('rightConnectivityIssue').enable();
      this.repairComplaintForm.get('rightTightFit').enable();
      this.repairComplaintForm.get('rightLooseFit').enable();
      this.repairComplaintForm.get('rightOtherShellIssue').enable();
      this.repairComplaintForm.get('rightOther').enable();
      this.repairComplaintForm.get('rightAnyPhysicalDamage').enable();
      this.repairComplaintForm.get('rightVisibleCorrorsion').enable();
      this.repairComplaintForm.get('rightBatteryLeakageVisible').enable();
      this.repairComplaintForm.get('rightMouldIncluded').enable();
      this.repairComplaintForm.get('rightMouldDamaged').enable();
      // this.repairComplaintForm.get('rightWarranty').enable();
      // this.repairComplaintForm.get('rightDateOfPurchase').enable();
      this.repairComplaintForm.get('rightRepairQuotation').enable();
      this.repairComplaintForm.get('rightPastRepairRef').enable();
      this.repairComplaintForm.get('rightOtherRemark').enable();
      this.repairComplaintForm.get('rightAcessoriesIncluded').enable();
      this.repairComplaintForm.get('rightCharging').enable();
      this.repairComplaintForm.get('rightBluetooth').enable();
      this.repairComplaintForm.get('rightServicing').enable();
      this.repairComplaintForm.get('rightAcessories').enable();

    } else {
      //this.rightPartData = true;
      this.repairComplaintForm.get('rightDead').disable();
      this.repairComplaintForm.get('rightNoisy').disable();
      this.repairComplaintForm.get('rightDistortion').disable();
      this.repairComplaintForm.get('rightFeedback').disable();
      this.repairComplaintForm.get('rightConnectivityIssue').disable();
      this.repairComplaintForm.get('rightTightFit').disable();
      this.repairComplaintForm.get('rightLooseFit').disable();
      this.repairComplaintForm.get('rightOtherShellIssue').disable();
      this.repairComplaintForm.get('rightOther').disable();
      this.repairComplaintForm.get('rightAnyPhysicalDamage').disable();
      this.repairComplaintForm.get('rightVisibleCorrorsion').disable();
      this.repairComplaintForm.get('rightBatteryLeakageVisible').disable();
      this.repairComplaintForm.get('rightMouldIncluded').disable();
      this.repairComplaintForm.get('rightMouldDamaged').disable();
      // this.repairComplaintForm.get('rightWarranty').disable();
      // this.repairComplaintForm.get('rightDateOfPurchase').disable();
      this.repairComplaintForm.get('rightRepairQuotation').disable();
      this.repairComplaintForm.get('rightPastRepairRef').disable();
      this.repairComplaintForm.get('rightOtherRemark').disable();
      this.repairComplaintForm.get('rightAcessoriesIncluded').disable();
      this.repairComplaintForm.get('rightCharging').disable();
      this.repairComplaintForm.get('rightBluetooth').disable();
      this.repairComplaintForm.get('rightServicing').disable();
      this.repairComplaintForm.get('rightAcessories').disable();

    }
    if (str !== '') {
      this.repairComplaintForm.controls['leftModel'].setErrors(null);
      this.repairComplaintForm.controls['leftModel'].clearValidators();
      this.repairComplaintForm.controls['leftModel'].updateValueAndValidity();
      this.repairComplaintForm.controls['leftSrNo'].setErrors(null);
      this.repairComplaintForm.controls['leftSrNo'].clearValidators();
      this.repairComplaintForm.controls['leftSrNo'].updateValueAndValidity();

    } else {

      this.repairComplaintForm.controls['rightModel'].setValidators(Validators.required);
      this.repairComplaintForm.controls['rightModel'].updateValueAndValidity();
      this.repairComplaintForm.controls['rightSrNo'].setValidators(Validators.required);
      this.repairComplaintForm.controls['rightSrNo'].updateValueAndValidity();
    }
  }

  onRightSerialChange(event, warranty, dob, pStaus) {
    //console.log("eventevent",event.target.value);
    this.rightWarranty = warranty;
    //console.log("warrantyRight",warranty); 
    //console.log("dobRight",dob);
    this.rightDateOfPurchase = dob;
    this.repairComplaintForm.controls["rightDateOfPurchase"].setValue(dob);
    this.repairComplaintForm.controls["pRightStaus"].setValue(pStaus);
    this.repairComplaintForm.get("rightDateOfPurchase").setValue(dob);
    // if(this.rightWarranty !== 0 && this.rightWarranty !== '0')
    // {
    //   this.repairComplaintForm.controls["rightWarranty"].setValue('Yes');
    // }
    // else
    // {
    //   this.repairComplaintForm.controls["rightWarranty"].setValue('No');
    // }
    let rightSerialNo = event.target.value;
    let payload = this.repairComplaintForm.value;

    if (payload.rightSrNo == payload.leftSrNo) {
      this.apimicroservice.openSnackbar("Right Sr No is not same to Left Sr No");
      this.repairComplaintForm.controls['rightSrNo'].setValue('');
    }
    let rightModel = this.repairComplaintForm.get('rightModel').value;
    if (rightSerialNo !== '' && rightModel !== '') {
      // this.rightPartData = false;
      this.repairComplaintForm.get('rightDead').enable();
      this.repairComplaintForm.get('rightNoisy').enable();
      this.repairComplaintForm.get('rightDistortion').enable();
      this.repairComplaintForm.get('rightFeedback').enable();
      this.repairComplaintForm.get('rightConnectivityIssue').enable();
      this.repairComplaintForm.get('rightTightFit').enable();
      this.repairComplaintForm.get('rightLooseFit').enable();
      this.repairComplaintForm.get('rightOtherShellIssue').enable();
      this.repairComplaintForm.get('rightOther').enable();
      this.repairComplaintForm.get('rightAnyPhysicalDamage').enable();
      this.repairComplaintForm.get('rightVisibleCorrorsion').enable();
      this.repairComplaintForm.get('rightBatteryLeakageVisible').enable();
      this.repairComplaintForm.get('rightMouldIncluded').enable();
      this.repairComplaintForm.get('rightMouldDamaged').enable();
      // this.repairComplaintForm.get('rightWarranty').enable();
      // this.repairComplaintForm.get('rightDateOfPurchase').enable();
      this.repairComplaintForm.get('rightRepairQuotation').enable();
      this.repairComplaintForm.get('rightPastRepairRef').enable();
      this.repairComplaintForm.get('rightOtherRemark').enable();
      this.repairComplaintForm.get('rightAcessoriesIncluded').enable();
      this.repairComplaintForm.get('rightCharging').enable();
      this.repairComplaintForm.get('rightBluetooth').enable();
      this.repairComplaintForm.get('rightServicing').enable();
      this.repairComplaintForm.get('rightAcessories').enable();


    } else {
      // this.rightPartData = true;
      this.repairComplaintForm.get('rightDead').disable();
      this.repairComplaintForm.get('rightNoisy').disable();
      this.repairComplaintForm.get('rightDistortion').disable();
      this.repairComplaintForm.get('rightFeedback').disable();
      this.repairComplaintForm.get('rightConnectivityIssue').disable();
      this.repairComplaintForm.get('rightTightFit').disable();
      this.repairComplaintForm.get('rightLooseFit').disable();
      this.repairComplaintForm.get('rightOtherShellIssue').disable();
      this.repairComplaintForm.get('rightOther').disable();
      this.repairComplaintForm.get('rightAnyPhysicalDamage').disable();
      this.repairComplaintForm.get('rightVisibleCorrorsion').disable();
      this.repairComplaintForm.get('rightBatteryLeakageVisible').disable();
      this.repairComplaintForm.get('rightMouldIncluded').disable();
      this.repairComplaintForm.get('rightMouldDamaged').disable();
      // this.repairComplaintForm.get('rightWarranty').disable();
      // this.repairComplaintForm.get('rightDateOfPurchase').disable();
      this.repairComplaintForm.get('rightRepairQuotation').disable();
      this.repairComplaintForm.get('rightPastRepairRef').disable();
      this.repairComplaintForm.get('rightOtherRemark').disable();
      this.repairComplaintForm.get('rightAcessoriesIncluded').disable();
      this.repairComplaintForm.get('rightCharging').disable();
      this.repairComplaintForm.get('rightBluetooth').disable();
      this.repairComplaintForm.get('rightServicing').disable();
      this.repairComplaintForm.get('rightAcessories').disable();


    }
  }

  onLeftModelChange(event, id, nSno, oSno, type, billNo, dateOfPurchase, leftCreatedAt, fromPrepo, pStaus) {
    //console.log("dateOfPurchase",dateOfPurchase);
    //console.log("leftCreatedAt",leftCreatedAt);

    this.repairComplaintForm.controls["extenalCreatedLeftDate"].setValue(leftCreatedAt);
    this.extenalCreatedRightDate = leftCreatedAt;
    //console.log("eventevent",event.target.value); 
    //console.log("sno kkkk",nSno);
    //console.log("oSno kkkk",oSno);    
    //console.log("type",type);
    //console.log("this.bIDthis.bID",this.bID);

    //console.log("billNobillNo",billNo);
    this.serialNumList = [];
    let lmodel = this.repairComplaintForm.get("leftModel").value;
    this.tempDeviceList.forEach(ele => {
      if (ele.model == lmodel) { 
        if (ele.type == "internal" || ele.type == "Fitted By Us") {
          const found = this.serialNumList.some(el => el.serialNo === ele.oldSerialNo);
          if (!found) { 
             
            this.serialNumList.push({
              "model": ele.model, "serialNo": ele.oldSerialNo, 
              "warranty": ele.warranty, "dateOfPurchase": ele.dateOfPurchase, "paymentStatus": ele.paymentStatus
            });
          } 
        } else if (ele.type == "external" || ele.type == "Not Fitted By Us") {
          const found = this.serialNumList.some(el => el.serialNo === ele.newSerialNo);
          if (!found) {
            this.serialNumList.push({
              "model": ele.model, "serialNo": ele.newSerialNo,
              "warranty": ele.warranty, "dateOfPurchase": ele.dateOfPurchase, "paymentStatus": ele.paymentStatus
            });
          }
        }

      }
    })
    console.log("this.tempDeviceList99999999",this.tempDeviceList);
    
    console.log("555555555555555555555555555555",this.serialNumList);

    
    //console.log("serialno arr list",this.serialNumList);
    this.filteredListSerialNo = this.serialNumList.slice();
    console.log("filteredListSerialNofilteredListSerialNo99",this.filteredListSerialNo);
    this.apimicroservice.getBillDataById(billNo).subscribe(res => {
      console.log("all companies reskkkkkkkkkkkkkkkkkkkkk", res);
      this.warrentLeft = res.data[0].deviceInfo[0].expiryDate;
      let status = res.data[0].paymentStatus;
      let todaydate = new Date();

      let day = new Date(res.data[0].createdAt);
      let year = day.getFullYear();
      let month = day.getMonth();
      let datee = day.getDate();
 
    // this.expDate = new Date(this.Devicedata.expiryDate);
    let edate;
    if(res.data[0].isMigrated == true)
    {
       edate= new Date(year + 2, month, datee);
    }else
    {
       edate= new Date(year + parseInt(res.data[0].deviceInfo[0].warrantyPeriod), month, datee);
    }
    console.log("this.expDate8",this.expDate);
    console.log("this.edate",edate);
    console.log("edate.toISOString()",edate.toISOString());
    this.expDate = edate.toISOString()

console.log("status",status);
console.log("this.expDate",this.expDate);
console.log("todaydate.toISOString()",todaydate.toISOString());
      if (todaydate.toISOString() <= this.expDate && status == 'Paid') {
        this.repairComplaintForm.get("leftWarranty").setValue('Yes');
      } 
      else if(todaydate.toISOString() <= this.expDate && status == 'Unpaid') {
        this.repairComplaintForm.get("leftWarranty").setValue('');
      }
      else {
        this.repairComplaintForm.get("leftWarranty").setValue('No');
      }
      let craetedAt;

      if (res.status) {
        if (type == 'Not Fitted By Us') {
          if (dateOfPurchase == null) {
            craetedAt = leftCreatedAt;
          }
          else {
            craetedAt = dateOfPurchase;
          }

        }
        else {
          craetedAt = res.data[0].createdAt;
        }
        if (fromPrepo == false) {
          this.currentDate = new Date();
          var date2 = this.datepipe.transform(this.currentDate, 'MM-dd-yyyy');
          var date1 = this.datepipe.transform(craetedAt, 'MM-dd-yyyy');

          var date11 = new Date(date1);
          var date22 = new Date(date2);

          var Time = date22.getTime() - date11.getTime();
          var Days = Time / (1000 * 3600 * 24); //Diference in Days
          this.LeftDeviceAge = Days;
          //console.log("this.LeftDeviceAge.LeftDeviceAge",this.LeftDeviceAge);
        }


      } else {
        //  this.apimicroservice.openSnackbar(res.message);
      }
    })

    this.repairComplaintForm.controls["leftDeviceType"].setValue(type);
    this.repairComplaintForm.controls["leftDeviceRefId"].setValue(id);
    if (this.fromPrepopulation == true) {
      this.repairComplaintForm.controls['leftSrNo'].setValue(oSno);
    } else {
      this.repairComplaintForm.controls['leftSrNo'].setValue('');
    }


    // if(nSno == '')
    // {
    //   this.repairComplaintForm.controls["leftSrNo"].setValue(oSno);
    // } 
    // else
    // {
    //   this.repairComplaintForm.controls["leftSrNo"].setValue(nSno);
    // }
    let str = event.target.value;
    let leftModel = event.target.value;
    let leftSerialNo = this.repairComplaintForm.get('leftSrNo').value;
    if (leftModel !== '' && leftSerialNo !== '') {
      // this.leftPartData = false;
      this.repairComplaintForm.get('leftDead').enable();
      this.repairComplaintForm.get('leftNoisy').enable();
      this.repairComplaintForm.get('leftDistortion').enable();
      this.repairComplaintForm.get('leftFeedback').enable();
      this.repairComplaintForm.get('leftConnectivityIssue').enable();
      this.repairComplaintForm.get('leftTightFit').enable();
      this.repairComplaintForm.get('leftLooseFit').enable();
      this.repairComplaintForm.get('leftOtherShellIssue').enable();
      this.repairComplaintForm.get('leftOther').enable();
      this.repairComplaintForm.get('leftAnyPhysicalDamage').enable();
      this.repairComplaintForm.get('leftVisibleCorrorsion').enable();
      this.repairComplaintForm.get('leftBatteryLeakageVisible').enable();
      this.repairComplaintForm.get('leftMouldIncluded').enable();
      this.repairComplaintForm.get('leftMouldDamaged').enable();
      // this.repairComplaintForm.get('leftWarranty').enable();
      // this.repairComplaintForm.get('leftDateOfPurchase').enable();
      this.repairComplaintForm.get('leftRepairQuotation').enable();
      this.repairComplaintForm.get('leftPastRepairRef').enable();
      this.repairComplaintForm.get('leftOtherRemark').enable();
      this.repairComplaintForm.get('leftAcessoriesIncluded').enable();
      this.repairComplaintForm.get('leftCharging').enable();
      this.repairComplaintForm.get('leftBluetooth').enable();
      this.repairComplaintForm.get('leftServicing').enable();
      this.repairComplaintForm.get('leftAcessories').enable();

    } else {
      //  this.leftPartData = true;
      this.repairComplaintForm.get('leftDead').disable();
      this.repairComplaintForm.get('leftNoisy').disable();
      this.repairComplaintForm.get('leftDistortion').disable();
      this.repairComplaintForm.get('leftFeedback').disable();
      this.repairComplaintForm.get('leftConnectivityIssue').disable();
      this.repairComplaintForm.get('leftTightFit').disable();
      this.repairComplaintForm.get('leftLooseFit').disable();
      this.repairComplaintForm.get('leftOtherShellIssue').disable();
      this.repairComplaintForm.get('leftOther').disable();
      this.repairComplaintForm.get('leftAnyPhysicalDamage').disable();
      this.repairComplaintForm.get('leftVisibleCorrorsion').disable();
      this.repairComplaintForm.get('leftBatteryLeakageVisible').disable();
      this.repairComplaintForm.get('leftMouldIncluded').disable();
      this.repairComplaintForm.get('leftMouldDamaged').disable();
      // this.repairComplaintForm.get('leftWarranty').disable();
      // this.repairComplaintForm.get('leftDateOfPurchase').disable();
      this.repairComplaintForm.get('leftRepairQuotation').disable();
      this.repairComplaintForm.get('leftPastRepairRef').disable();
      this.repairComplaintForm.get('leftOtherRemark').disable();
      this.repairComplaintForm.get('leftAcessoriesIncluded').disable();
      this.repairComplaintForm.get('leftCharging').disable();
      this.repairComplaintForm.get('leftBluetooth').disable();
      this.repairComplaintForm.get('leftServicing').disable();
      this.repairComplaintForm.get('leftAcessories').disable();
    }

    if (str !== '') {
      this.repairComplaintForm.controls['rightModel'].setErrors(null);
      this.repairComplaintForm.controls['rightModel'].clearValidators();
      this.repairComplaintForm.controls['rightModel'].updateValueAndValidity();
      this.repairComplaintForm.controls['rightSrNo'].setErrors(null);
      this.repairComplaintForm.controls['rightSrNo'].clearValidators();
      this.repairComplaintForm.controls['rightSrNo'].updateValueAndValidity();
    } else {
      this.repairComplaintForm.controls['leftModel'].setValidators(Validators.required);
      this.repairComplaintForm.controls['leftModel'].updateValueAndValidity();
      this.repairComplaintForm.controls['leftSrNo'].setValidators([Validators.required, Validators.pattern("^[a-zA-Z0-9 -]+$")]);
      this.repairComplaintForm.controls['leftSrNo'].updateValueAndValidity();
    }
  }

  onLeftSerialChange(event, warranty, dob, pStaus) {
    //console.log("eventevent",event.target.value);

    //console.log("warrantyLeft",warranty);
    // this.leftWarranty = warranty;
    //console.log("dobLeft",dob);
    this.leftDateOfPurchase = dob;
    this.repairComplaintForm.controls["leftDateOfPurchase"].setValue(dob);
    this.repairComplaintForm.controls["pLeftStaus"].setValue(pStaus);
    this.repairComplaintForm.get("leftDateOfPurchase").setValue(dob);
    // if(this.leftWarranty !== 0 && this.leftWarranty !== '0') 
    // {
    //   this.repairComplaintForm.get("leftWarranty").setValue('Yes');
    // }
    // else
    // { 
    //   this.repairComplaintForm.get("leftWarranty").setValue('No');
    // }
    let payload = this.repairComplaintForm.value;

    if (payload.leftSrNo == payload.rightSrNo) {
      this.apimicroservice.openSnackbar("Left Sr No is not same to Right Sr No");
      this.repairComplaintForm.controls['leftSrNo'].setValue('');
    }
    let leftSerialNo = event.target.value;
    let leftModel = this.repairComplaintForm.get('leftModel').value;
    //console.log("leftSerialNo",leftSerialNo);
    if (leftModel !== '' && leftSerialNo !== '') {
      // this.leftPartData = false;
      this.repairComplaintForm.get('leftDead').enable();
      this.repairComplaintForm.get('leftNoisy').enable();
      this.repairComplaintForm.get('leftDistortion').enable();
      this.repairComplaintForm.get('leftFeedback').enable();
      this.repairComplaintForm.get('leftConnectivityIssue').enable();
      this.repairComplaintForm.get('leftTightFit').enable();
      this.repairComplaintForm.get('leftLooseFit').enable();
      this.repairComplaintForm.get('leftOtherShellIssue').enable();
      this.repairComplaintForm.get('leftOther').enable();
      this.repairComplaintForm.get('leftAnyPhysicalDamage').enable();
      this.repairComplaintForm.get('leftVisibleCorrorsion').enable();
      this.repairComplaintForm.get('leftBatteryLeakageVisible').enable();
      this.repairComplaintForm.get('leftMouldIncluded').enable();
      this.repairComplaintForm.get('leftMouldDamaged').enable();
      // this.repairComplaintForm.get('leftWarranty').enable();
      // this.repairComplaintForm.get('leftDateOfPurchase').enable();
      this.repairComplaintForm.get('leftRepairQuotation').enable();
      this.repairComplaintForm.get('leftPastRepairRef').enable();
      this.repairComplaintForm.get('leftOtherRemark').enable();
      this.repairComplaintForm.get('leftAcessoriesIncluded').enable();
      this.repairComplaintForm.get('leftCharging').enable();
      this.repairComplaintForm.get('leftBluetooth').enable();
      this.repairComplaintForm.get('leftServicing').enable();
      this.repairComplaintForm.get('leftAcessories').enable();


    } else {
      //this.leftPartData = true;
      this.repairComplaintForm.get('leftDead').disable();
      this.repairComplaintForm.get('leftNoisy').disable();
      this.repairComplaintForm.get('leftDistortion').disable();
      this.repairComplaintForm.get('leftFeedback').disable();
      this.repairComplaintForm.get('leftConnectivityIssue').disable();
      this.repairComplaintForm.get('leftTightFit').disable();
      this.repairComplaintForm.get('leftLooseFit').disable();
      this.repairComplaintForm.get('leftOtherShellIssue').disable();
      this.repairComplaintForm.get('leftOther').disable();
      this.repairComplaintForm.get('leftAnyPhysicalDamage').disable();
      this.repairComplaintForm.get('leftVisibleCorrorsion').disable();
      this.repairComplaintForm.get('leftBatteryLeakageVisible').disable();
      this.repairComplaintForm.get('leftMouldIncluded').disable();
      this.repairComplaintForm.get('leftMouldDamaged').disable();
      // this.repairComplaintForm.get('leftWarranty').disable();
      // this.repairComplaintForm.get('leftDateOfPurchase').disable();
      this.repairComplaintForm.get('leftRepairQuotation').disable();
      this.repairComplaintForm.get('leftPastRepairRef').disable();
      this.repairComplaintForm.get('leftOtherRemark').disable();
      this.repairComplaintForm.get('leftAcessoriesIncluded').disable();
      this.repairComplaintForm.get('leftCharging').disable();
      this.repairComplaintForm.get('leftBluetooth').disable();
      this.repairComplaintForm.get('leftServicing').disable();
      this.repairComplaintForm.get('leftAcessories').disable();

    }

  }

  disableLeftRightData() {
    this.repairComplaintForm.get('rightDead').disable();
    this.repairComplaintForm.get('rightNoisy').disable();
    this.repairComplaintForm.get('rightDistortion').disable();
    this.repairComplaintForm.get('rightFeedback').disable();
    this.repairComplaintForm.get('rightConnectivityIssue').disable();
    this.repairComplaintForm.get('rightTightFit').disable();
    this.repairComplaintForm.get('rightLooseFit').disable();
    this.repairComplaintForm.get('rightOtherShellIssue').disable();
    this.repairComplaintForm.get('rightOther').disable();
    this.repairComplaintForm.get('rightAnyPhysicalDamage').disable();
    this.repairComplaintForm.get('rightVisibleCorrorsion').disable();
    this.repairComplaintForm.get('rightBatteryLeakageVisible').disable();
    this.repairComplaintForm.get('rightMouldIncluded').disable();
    this.repairComplaintForm.get('rightMouldDamaged').disable();
    // this.repairComplaintForm.get('rightWarranty').disable();
    // this.repairComplaintForm.get('rightDateOfPurchase').disable();
    this.repairComplaintForm.get('rightRepairQuotation').disable();
    this.repairComplaintForm.get('rightPastRepairRef').disable();
    this.repairComplaintForm.get('rightOtherRemark').disable();
    this.repairComplaintForm.get('rightAcessoriesIncluded').disable();
    this.repairComplaintForm.get('leftDead').disable();
    this.repairComplaintForm.get('leftNoisy').disable();
    this.repairComplaintForm.get('leftDistortion').disable();
    this.repairComplaintForm.get('leftFeedback').disable();
    this.repairComplaintForm.get('leftConnectivityIssue').disable();
    this.repairComplaintForm.get('leftTightFit').disable();
    this.repairComplaintForm.get('leftLooseFit').disable();
    this.repairComplaintForm.get('leftOtherShellIssue').disable();
    this.repairComplaintForm.get('leftOther').disable();
    this.repairComplaintForm.get('leftAnyPhysicalDamage').disable();
    this.repairComplaintForm.get('leftVisibleCorrorsion').disable();
    this.repairComplaintForm.get('leftBatteryLeakageVisible').disable();
    this.repairComplaintForm.get('leftMouldIncluded').disable();
    this.repairComplaintForm.get('leftMouldDamaged').disable();
    // this.repairComplaintForm.get('leftWarranty').disable();
    // this.repairComplaintForm.get('leftDateOfPurchase').disable();
    this.repairComplaintForm.get('leftRepairQuotation').disable();
    this.repairComplaintForm.get('leftPastRepairRef').disable();
    this.repairComplaintForm.get('leftOtherRemark').disable();
    this.repairComplaintForm.get('leftAcessoriesIncluded').disable();
    this.repairComplaintForm.get('leftCharging').disable();
    this.repairComplaintForm.get('leftBluetooth').disable();
    this.repairComplaintForm.get('leftServicing').disable();
    this.repairComplaintForm.get('rightCharging').disable();
    this.repairComplaintForm.get('rightBluetooth').disable();
    this.repairComplaintForm.get('rightServicing').disable();
    this.repairComplaintForm.get('leftAcessories').disable();
    this.repairComplaintForm.get('rightAcessories').disable();



  } 

  fetchAllCompanies() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    this.apimicroservice.getAllCompanylist(branchRef,clinicRef).subscribe(res => {
      console.log("all companies res",res);
      if (res.status) {
        this.companyList = res.data.data;
        this.filteredListCompany = this.companyList.slice();
        let lenght = res.data.totalItems-1;
        this.repairComplaintForm.controls['companyRefId'].setValue(res.data.data[lenght]._id);
        this.onSelectCompany(res.data.data[lenght].companyName, res.data.data[lenght].canNumber,
           res.data.data[lenght].make);

      } else {
        //  this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
  onSelectCompany(cname, canno, make) {
    //console.log(cname);
    //console.log(canno);
    this.companyName = cname;
    this.canNo = canno;
    this.make = make;
    this.repairComplaintForm.controls['canNumber'].setValue(this.canNo);
    this.repairComplaintForm.controls['make'].setValue(this.make);
  }
  openBranchPopUp() {
    const dialogRef3 = this.dialog.open(PopUpBranchComponent,
      {
        width: "1200px",
      });
    dialogRef3.afterClosed().subscribe((result) => {
      let clinicRef= sessionStorage.getItem('clinicRef');
      this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
        if (res.status) {
          this.BranchList = res.data;
          this.filteredListBranch = this.BranchList.slice();
          this.branchClick(res.data[0]._id, res.data[0].branchName);
          this.repairComplaintForm.get("branchRefId").setValue(res.data[0]._id);
        }
      })
    }) 
  }
  openDeviceByPatientPopUp() {
    let payload = this.repairComplaintForm.value;
    //console.log("payloadd",payload);
 
    const dialogRef3 = this.dialog.open(PopupDeviceByPatientComponent,
      {
        width: "1200px",
        data: {
          patientRefId: payload.patientRefId,
          patientName: payload.patientName,
        }
      });
    dialogRef3.afterClosed().subscribe((result) => {

      this.fetchModelList(this.pId);
    })
  }
   openFittedByUsComponent() {
    let payload = this.repairComplaintForm.value;
    //console.log("payloadd",payload);

    const dialogRef3 = this.dialog.open(PopUpFittedByUsComponent,
      {
        width: "1200px",
        data: {   
          patientRefId: payload.patientRefId,
          patientName: payload.patientName,
        }
      });
    dialogRef3.afterClosed().subscribe((result) => {

    //  this.fetchModelList(this.pId);
    })
  }
  openPatientPopUp() {

    const dialogRef3 = this.dialog.open(PopUpPatientComponent,
      {
        width: "1200px",
      });
    dialogRef3.afterClosed().subscribe((result) => {
      // this.fetchPatientList();  
      let clinicRef= sessionStorage.getItem('clinicRef');
      let branchRef= sessionStorage.getItem('branchRef');
      let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
      let branchRefSuperAdmin= '';
      if (this.userType !== 'Super Admin') {
      this.apimicroservice.getPatientListByBranchClinic(branchRef, clinicRef).subscribe(res => {
        //console.log("fetch patienttttttttt list res",res);

        if (res.status) {
          this.patientList = res.data;
          this.filteredListPatient = this.patientList.slice();
          this.onSelectPatient(this.patientList[0]._id);
          this.repairComplaintForm.get("patientRefId").setValue(this.patientList[0]._id);

        } else {
          // this.apimicroservice.openSnackbar(res.message);
        }
      })
    }else{
      this.apimicroservice.getPatientListByBranchClinic(branchRefSuperAdmin, clinicRefSuperAdmin).subscribe(res => {
        //console.log("fetch patienttttttttt list res",res);

        if (res.status) {
          this.patientList = res.data;
          this.filteredListPatient = this.patientList.slice();
          this.onSelectPatient(this.patientList[0]._id);
          this.repairComplaintForm.get("patientRefId").setValue(this.patientList[0]._id);

        } else {
          // this.apimicroservice.openSnackbar(res.message);
        }
      })
    }
    });
  }

  openCompanyPopUp() {
    const dialogRef3 = this.dialog.open(PopupCompanyComponent,
      {
        width: "900px",
      });

    dialogRef3.afterClosed().subscribe((result) => {
      let clinicRef= sessionStorage.getItem('clinicRef');
      let branchRef= sessionStorage.getItem('branchRef');
      this.apimicroservice.getAllCompanylist(branchRef,clinicRef).subscribe(res => {
        //console.log("all companies res",res);
        if (res.status) {
          this.companyList = res.data.data;
          this.filteredListCompany = this.companyList.slice();
          this.onSelectCompany(res.data.data[0].companyName, res.data.data[0].canNumber, res.data.data[0].make);

          this.repairComplaintForm.get("companyRefId").setValue(res.data.data[0]._id);
        } else {
          //  this.apimicroservice.openSnackbar(res.message);
        }
      })

    });
  }
  openRepairActionPopUp() {
    let payload = this.repairComplaintForm.value;
    //console.log("this.RightDeviceAge",this.RightDeviceAge);

    const dialogRef3 = this.dialog.open(RepairActionComponent,
      {
        width: "600px",
        data: {
          patientRefId: payload.patientRefId,
          patientName: payload.patientName,
          companyRefId: payload.companyRefId,
          companyName: payload.companyName,
          repairRefId: this.repairId,
          branchRefId: this.branchRefId,
          repairBranch: this.repairBranch,
          patientMobile: this.patientMobile,
          patientId: this.patientID,
          amountA: this.amountA,
          amountB: this.amountB,
          LeftDeviceAge: this.LeftDeviceAge,
          RightDeviceAge: this.RightDeviceAge,
          leftType: this.leftType,
          rightType: this.rightType,
          rightModel: this.rightModel,
          rightSrNo: this.rightSrNo,
          leftModel: this.leftModel,
          leftSrNo: this.leftSrNo,
          actionId: '',
          repairActionAllData: this.repairActionData
        }
      });
    dialogRef3.afterClosed().subscribe((result) => {
      this.fetchRepairList();
      this.getRepairActionAllData(this.repairId)
    });
  }

  globalPrint() {
    let titleName = 'Repair Complaint Form.pdf'
    var tempTitle = document.title;
    document.title = titleName;
    window.print();
    document.title = tempTitle;
  }
  WhatsApp() {
    let payload = this.repairComplaintForm.value;
    let num = payload.mobile1;
    if (num.length == 10) {
      sessionStorage.setItem('whatsAppNumber', payload.mobile1);
      let mobileNumber = sessionStorage.getItem('whatsAppNumber');
      window.open("https://wa.me/91" + mobileNumber);
    }
    else {
      this.repairComplaintForm.get("mobile1").markAsTouched();
    }
  }
  onback() {
    this.apimicroservice.back()
  }
  openDepartmentPopUp() {
    const dialogRef3 = this.dialog.open(PatientSearchPopComponent, {
      width: "97%",
      height: "90%", 
    });
    dialogRef3.afterClosed().subscribe((result) => {
      this.DeviceList = [];
      this.filteredListDevice = [];
      this.allDevices = [];
      this.rightFilteredListSerialNo = [];
      this.filteredListSerialNo = [];

      this.repairComplaintForm.get("patientName").setValue(result.data.firstName + ' ' + result.data.lastName);
      this.repairComplaintForm.get("patientRefId").setValue(result.data._id);
      this.onSelectPatient(result.data._id);
      this.pId = result.data._id;
      this.fetchModelList(result.data._id);
      let payload = this.repairComplaintForm.value;
      if (payload.patientName !== '') {
        this.isDisable = false;
      }
      else {
        this.isDisable = true;
      }
      this.repairComplaintForm.get("rightModel").setValue('');
      this.repairComplaintForm.get("leftModel").setValue('');
      this.repairComplaintForm.get("rightSrNo").setValue('');
      this.repairComplaintForm.get("lefttSrNo").setValue('');

    });
  }
  openAccesoriesPopUpLeft() {
    const dialogRef3 = this.dialog.open(PopUpAccesoriesComponent,
      {
        width: "400px",
      });

    dialogRef3.afterClosed().subscribe((result) => {
      this.apimicroservice.getAllAccesoriesList().subscribe(res => {
        //console.log("all Accesories List ",res);
        if (res.status) {
          this.accesoriesList = res.data;
          this.filteredListAccesories = this.accesoriesList.slice();
          this.accesoriesClickLeft(res.data[0]._id, res.data[0].accesories);
          this.repairComplaintForm.get("leftAcessoriesIncluded").setValue(res.data[0]._id);
        } else {
          //  this.apimicroservice.openSnackbar(res.message);
        }
      })

    });
  }

  fetchAllAccesories() {
    this.apimicroservice.getAllAccesoriesList().subscribe(res => {
      //console.log("all Accesories List ",res);
      if (res.status) {
        this.accesoriesList = res.data;
        this.filteredListAccesories = this.accesoriesList.slice();

        //  this.onSelectCompany(res.data.data[0].companyName,res.data.data[0].canNumber);

        // this.repairComplaintForm.get("companyRefId").setValue(res.data.data[0]._id);
      } else {
        //  this.apimicroservice.openSnackbar(res.message);
      }
    })
  }

  accesoriesClickLeft(bid, aname) {
    //console.log("anameaname",aname);  
    // this.accesories = aname;
    this.repairComplaintForm.controls['leftAcessories'].setValue(aname);
  }
  accesoriesClickRight(bid, aname) {
    // this.accesories = aname;
    //console.log("anameaname",aname);
    this.repairComplaintForm.controls['rightAcessories'].setValue(aname);
  }
  openAccesoriesPopUpRight() {
    const dialogRef3 = this.dialog.open(PopUpAccesoriesComponent,
      {
        width: "400px",
      });

    dialogRef3.afterClosed().subscribe((result) => {
      this.apimicroservice.getAllAccesoriesList().subscribe(res => {
        //console.log("all Accesories List ",res);
        if (res.status) {
          this.accesoriesList = res.data;
          this.filteredListAccesories = this.accesoriesList.slice();
          this.accesoriesClickRight(res.data[0]._id, res.data[0].accesories);
          this.repairComplaintForm.get("rightAcessoriesIncluded").setValue(res.data[0]._id);
        } else {
          //  this.apimicroservice.openSnackbar(res.message);
        }
      })

    });
  }
  clickModel(item) {
    // this.popupAddDeviceForm.controls["deviceRefId"].setValue(id);

    let tempservices = [];
    let id = item._id;
    this.serialNoList = [];
    let tempsrno = [];
    let deviceId = "";

    let dstatus = "unsold";
    // let transferStatus = "In-Transit";
    //  let branchRef = '';
    //  if(this.userType !== 'Super Admin'){
    //   branchRef = this.branchRef;
    //  }else{
    //   branchRef = '';
    //  }

    let branchRef= sessionStorage.getItem('branchRef');
    this.apimicroservice.getStockMaintainByDeviceIdStatus( id,deviceId, dstatus,branchRef).subscribe(res => {
      //console.log("serialno res",res);
      let data = res.data;
      if (res.message !== 'No Data Found') {
        res.data.forEach(element => {
          if (element.transferStatus == undefined || element.transferStatus == "" || element.transferStatus == "Accept" || element.transferStatus == "Reject") {
            tempsrno.push(element);
          }
        });
        // this.serialNoList[i] = data;
        this.serialNoList = tempsrno;

        tempservices = this.serialNoList;

        //console.log("tempservices",tempservices);
        this.serialNoList = tempservices;
        //console.log("this.serialNoList[i]this.serialNoList[i]",this.serialNoList);

      }


    });
  }
  fetchModelList2() {
    let branchRef= sessionStorage.getItem('branchRef');
    this.apimicroservice.getAllDeviceList(branchRef).subscribe(res => {
      if (res.status) {
        this.DeviceList2 = res.data.data;
        this.filteredListDevice2 = this.DeviceList2.slice();
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    })
    //} 

  }
  getRepairActionAllData(id) {
    this.isPrepopulate = true;
    let page = 1;
    let size = 6;
    let search = '';
    this.apimicroservice.getRepairActionAllRepairRefId(id).subscribe(res => {
      this.repairActionData = res.data.data;
      this.lastStatus = res.data.data[0].status;
      this.totalLengthAction = res.data.totalItems;
      let arr = [];
      let arr2 = [];
      this.repairActionData.forEach((element) => {
        //console.log("elementelementelementelementelementelement",element);

        if (element.repairActionName == 'Estimate Received From') {
          arr.push(element.amountA);
        }
        if (element.amountB !== '' && element.amountB !== null) {
          arr2.push(element.amountB);
        }

      });
      this.amountB = arr2[0];
      this.amountA = arr[0];
    });
  }
  prepopulationAction(id) {
    let payload = this.repairComplaintForm.value;
    //console.log("this.RightDeviceAge",this.RightDeviceAge);
    const dialogRef3 = this.dialog.open(RepairActionComponent,
      {
        width: "600px",
        data: {
          patientRefId: payload.patientRefId,
          patientName: payload.patientName,
          companyRefId: payload.companyRefId,
          companyName: payload.companyName,
          repairRefId: this.repairId,
          branchRefId: this.branchRefId,
          repairBranch: this.repairBranch,
          patientMobile: this.patientMobile,
          patientId: this.patientID,
          amountA: this.amountA,
          amountB: this.amountB,
          LeftDeviceAge: this.LeftDeviceAge,
          RightDeviceAge: this.RightDeviceAge,
          leftType: this.leftType,
          rightType: this.rightType,
          rightModel: this.rightModel,
          rightSrNo: this.rightSrNo,
          leftModel: this.leftModel,
          leftSrNo: this.leftSrNo,
          actionId: id,
          repairActionAllData: this.repairActionData
        }
      });
    dialogRef3.afterClosed().subscribe((result) => {
      this.getRepairActionAllData(this.repairId)
    });
  }
  redirectToBillForm(billNo) {
    this.router.navigate(['bill-form', billNo]);
  }
  printForm() { 
    this.router.navigate(['repair-print', this.repairId]);
  }
  toInwardOutward(id, branchName) {
    //console.log("hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh");
    //console.log("idid",id);
    //console.log("branchNamebranchName",branchName);
    this.router.navigate(['inward-outward', id, branchName]);
  }
  canvasClick() {
    this.signPadShow = true;
    this.signatureImg = "";
    ////console.log("this.signatureImg",this.signatureImg);

  }
  deviceType(e)
  {
    if(e == 'left')
    {
      this.repairComplaintForm.get('rightModel').disable();
      this.repairComplaintForm.get('rightSrNo').disable();
      
      this.repairComplaintForm.get('leftModel').enable();
      this.repairComplaintForm.get('leftSrNo').enable();
    }
    else if(e == 'right')
    {
      this.repairComplaintForm.get('leftModel').disable();
      this.repairComplaintForm.get('leftSrNo').disable();

      this.repairComplaintForm.get('rightModel').enable();
      this.repairComplaintForm.get('rightSrNo').enable();
    }
  }
  openBillingCompPopUp(){ 
    const dialogRef3 = this.dialog.open(PopupBillingcompanyComponent,
      {
        width: "1200px", 
      });
  
      dialogRef3.afterClosed().subscribe((result) => {
        let id='';
        let clinicRef= sessionStorage.getItem('clinicRef');
        this.apimicroservice.getBillingDataById(id,clinicRef).subscribe(res => {
         
          if(res.status){
            //console.log("billing res",res);
            this.listOfBilling = res.data;
            this.filteredBillingCompany= this.listOfBilling.slice();
            this.repairComplaintForm.get("billingCompanyRefId").setValue(res.data[0]._id);
          }
        });
         
       }); 
  }
  fetchBillingCompanyDetails(){
    let id='';
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getBillingDataById(id,clinicRef).subscribe(res => {
      //console.log("billing res",res);
      this.listOfBilling = res.data; 
      this.filteredBillingCompany= this.listOfBilling.slice();
      let lenght = res.data.length-1;  
      this.repairComplaintForm.get("billingCompanyRefId").setValue(res.data[lenght]._id);
      if(res.status){
        //this.apimicroservice.openSnackbar(res.message);
      }else{ 
        //this.apimicroservice.openSnackbar(res.message);
      }
    });
  }
}


