import { Component, Inject, OnInit } from "@angular/core";
import {FormGroup,FormBuilder,FormControl, Validators} from '@angular/forms';
import { ApiServiceService } from '../api-service.service';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-popup-ha-color',
  templateUrl: './popup-ha-color.component.html',
  styleUrls: ['./popup-ha-color.component.scss']
})
export class PopupHaColorComponent implements OnInit {
  complaintId:any;
  popupHaColorForm: FormGroup;
  variables3: any;
  filteredList1: any;
  filteredList2: any;
  variables4: any;
  HaColorData: any;
  submitButton = true;
  updateButton = false;
  cancelButton = false;
  userType: string;
  listOfHaColor: any;
  totalLength: any;
  searchText: any;
  page:number = 1;
  size:number = 3;
  bid: any;
  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<PopupHaColorComponent>,@Inject(MAT_DIALOG_DATA) public data:any, 
    public apimicroservice: ApiServiceService,
) { dialogRef.disableClose = true;}
 addNewComplaint = false;
  ngOnInit() {
    this.page =1;
    this.userType = sessionStorage.getItem('userType');
    this.popupHaColorForm = this.fb.group({  
      "HaColorName": new FormControl('',Validators.required),
      "createdAt": new Date(),
      "searchHaColor": new FormControl(''),
      
    });
    this.fetchHaColorList();
  }
  fetchHaColorList() {
    let pg = 1;
    let size = 3;
    let search='';
    
      if (this.userType !== 'Super Admin') {
        this.apimicroservice.getHaColorData(pg,size,search).subscribe(res => {
           this.listOfHaColor = res.data.data;
           this.totalLength = res.data.totalItems;
           //this.buildingLenght = this.listofbuild.length;
          if(res.status){
            //this.apimicroservice.openSnackbar(res.message);
            
          }else{
            //this.apimicroservice.openSnackbar(res.message);
          }
     
        });   
      } else {

        this.apimicroservice.getHaColorData(pg,size,search).subscribe(res => {
           this.listOfHaColor = res.data.data;
           this.totalLength = res.data.totalItems;
           //this.buildingLenght = this.listofbuild.length;
          if(res.status){
           // this.apimicroservice.openSnackbar(res.message);
            
          }else{
            //this.apimicroservice.openSnackbar(res.message);
          }
     
        });
        
      }

  }
  onPageChange(event) {
    this.page = event;
   // let p = this.page - 1;
    this.searchText = this.popupHaColorForm.get('searchHaColor').value;
 
      this.apimicroservice.getHaColorData(this.page,this.size,this.searchText).subscribe(res => {
        this.listOfHaColor = res.data.data;
        this.totalLength = res.data.totalItems;
         if(res.status){
          //  this.apimicroservice.openSnackbar(res.message);
           
         }else{
           //this.apimicroservice.openSnackbar(res.message);
         }
    
       });
  
  }
  onKeyUpEvent(event: any) {
    this.searchText = this.popupHaColorForm.get('searchHaColor').value;
    this.apimicroservice.getHaColorData(this.page,this.size,this.searchText).subscribe(res => {
      this.listOfHaColor = res.data.data;
      this.totalLength = res.data.totalItems;
       if(res.status){
        //  this.apimicroservice.openSnackbar(res.message);    
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
  }

  prepopulation(id) {
    this.apimicroservice.getHaColorDataById(id).subscribe(res => {
      let data = res.data[0];
      this.bid = id;
       if(res.status){
        this.submitButton = false;
         this.updateButton = true;
         this.cancelButton = true;
         this.popupHaColorForm = this.fb.group({
          "HaColorName" :new FormControl(data.HaColorName,Validators.required),
         });
       }else{
     //    //this.apimicroservice.openSnackbar(res.message);
       }
     });
  }
  saveHaColor(){
    let payload = this.popupHaColorForm.value; 
    //
    
    this.apimicroservice.submitHaColorData(payload).subscribe(res => {
      //
      
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);      
        this.ngOnInit();
       this.onClose();        
      }else if(res.status == false){       
        this.apimicroservice.openSnackbar(res.message);
      }
    },(ERROR:HttpErrorResponse) => {
      this.apimicroservice.openSnackbar(ERROR.error.message);
  });
  } 
  save() {
    if (this.popupHaColorForm.invalid) {
      this.popupHaColorForm.get("HaColorName").markAsTouched();
      return;
    } else {
      this.saveHaColor();
    }
  }
  getLeftComplaintData(){
    // this.apimicroservice.getLeftComplaintAllData().subscribe(res => {
    //   //
    //   this.variables3 = res.data;
    //   this.filteredList1 = this.variables3.slice();
    // });
    
  }
  
  onClose(): void {
    this.dialogRef.close();
  }
  addNewComplaintFunc()
  {
    this.addNewComplaint = true;
  }
  cancelFunc()
  {
    this.addNewComplaint = false;
  }
  updateHaColor() {
    let payload = this.popupHaColorForm.value;
     this.apimicroservice.updateHaColorDetails(payload,this.bid).subscribe(res => {
       if(res.status){
         this.apimicroservice.openSnackbar(res.message);
          this.updateButton = false;
          this.submitButton = true;
          this.ngOnInit();
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
    
  }
  cancelUpdate(){
    this.popupHaColorForm.reset();
    this.submitButton=true;
    this.updateButton=false;
    this.cancelButton=false;
    this.ngOnInit();
  }
}