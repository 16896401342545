<div class="container-fluid bgcolor2" [formGroup]="companyMasterForm">
  <form [formGroup]="companyMasterForm" autocomplete="off" novalidate>
    <div class="row">
      <div class="col-sm-1">

      </div>
      <div class="col-sm-11">

        <div class="row">
          <div class="col-sm-12 pr-4">
            <div class="row pl-0 ">

              <div class="col-12 pl-0 ">
                <div class="row "> 
                <div class="col-sm-9">
                
                    <h1 class="pt-2 pl-0" id="toCompanyForm">Hearing Aid Company</h1>
                    </div>
                    <div class="col-sm-3 mt-1">
                      <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                        <a (click)="onback()" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">arrow_back</span>Back</a>
                        <a (click)="toCompanyList()" class="d-flex align-items-center ms-3 pr-2 mt-2"><span class="material-symbols-outlined pr-2">reorder</span>List</a>
                   </div>
              </div>
            </div>
           </div>
           </div>

             <div class="row margingTop">
              <div class="card divbody borderradius mb-3 pl-4 pr-4 p-1">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="row mt-2">
                      <div class="col-sm-12  ">
                          <h5 class="pb-0 mb-0">Hearing Aid Company Details :</h5>
                        </div>
                      </div>

                      <div class="row pt-1 pr-1">
                        <div class=" col-sm-1 mt-3 ">
                         
                               <!-- <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Company Id</mat-label>
                                <input matInput formControlName="branchWiseId" readonly  />   
                              </mat-form-field> -->
                              <mat-label class="center" ngDefaultControl >{{bId}}</mat-label>
                        </div>
                        <div class="col-sm-2 ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Hearing Aid Company Name
                              <!-- <span class="required-asterisk">*</span> -->
                            </mat-label>
                            <input matInput formControlName="companyName" placeholder="Company Name"  />
                            
                          </mat-form-field>
                          <mat-error class="margin_tp"
                          *ngIf="companyMasterForm.controls['companyName'].touched && companyMasterForm.controls['companyName'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                        <mat-error class="margin_tp"
                          *ngIf="companyMasterForm.controls['companyName'].touched && companyMasterForm.controls['companyName'].errors?.pattern">
                          Please enter valid company name.
                        </mat-error>
                        </div>
                        <div class=" col-sm-3">
                            <div class="row">
                              <div class="col-sm-12">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>CAN Number
                                    <!-- <span class="required-asterisk">*</span> -->
                                  </mat-label>
                                  <input matInput formControlName="canNumber"  maxlength="21" placeholder="CAN Number"  />
                                  
                                </mat-form-field>
                                <!-- <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Select CAN Number </mat-label>
                                  <mat-select #select2 formControlName="canRefId" >
                                    <mat-select-filter *ngIf="select2.focused" [placeholder]="'Filter'" [displayMember]="'canNumber'"
                                    [array]="canNumberList"  (filteredReturn)="filteredListCanNumber =$event"></mat-select-filter>    
                                    <mat-option *ngFor="let item of filteredListCanNumber" [value]="item._id" (click)="onSelectCan(item.canNumber)"
                                  >
                                   {{ item.canNumber }}</mat-option>
                                  </mat-select>
                                </mat-form-field> -->
                                <mat-error class="margin_tp"
                                *ngIf="companyMasterForm.controls['canNumber'].touched && companyMasterForm.controls['canNumber'].errors?.required">
                                This field is mandatory.
                              </mat-error>
                              <mat-error class="margin_tp"
                                *ngIf="companyMasterForm.controls['canNumber'].touched && companyMasterForm.controls['canNumber'].errors?.pattern">
                                Enter valid Can Number.
                              </mat-error>
                            <!-- <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Can No</mat-label>
                              <input matInput formControlName="accountNumber"/>   
                            </mat-form-field> -->
                            </div>
                            <!-- <div class="col-sm-2 mTop">
                              <img (click)="openMakePopUp();" matTooltip="Add Can Number" width="25" src="../../assets/add_img.png"> 
                            </div>  -->
                            </div>
                            </div>
                            <div class="col-sm-3 ">
                              <div class="row">
                                <div class="col-sm-10 pr-1"> 
                              <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Make</mat-label>
                                <mat-select #select5 placeholder="make" class="colorblack" formControlName="make">
                                  <mat-select-filter *ngIf="select5.focused" [placeholder]="'Filter'" [displayMember]="'make'" [array]="makeList"
                                    (filteredReturn)="filteredMakeList =$event"></mat-select-filter>
                                  <mat-option *ngFor="let item of filteredMakeList" [value]="item.make">
                                    {{item.make}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                            <div class="col-sm-2 ml-0 pl-0 mTop">
                              <i (click)="openMakePopUp();" matTooltip="Add New Make" 
                              class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                           
                            </div>
                           </div>
                           </div>
                        <div class="col-sm-12 ">
                          <h5 class="pb-0 mb-0">Contact Details :</h5>
      
                        </div>
                        <div class="col-sm-3">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Landline</mat-label>
                            <input maxlength="15" matInput placeholder=" Landline eg 0223-666666" formControlName="landline" />
  
                          </mat-form-field>
                          <mat-error class="margin_tp"
                          *ngIf="companyMasterForm.controls['landline'].touched && companyMasterForm.controls['landline'].errors?.pattern">
                          Enter valid Landline Number.
                        </mat-error>
                        </div>
  
                        <div class="col-sm-3 ">
                          <div class="row">
                            <div class="col-sm-10">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label> Mobile Number 
                              <!-- <span class="required-asterisk">*</span> -->
                            </mat-label>
                            <input  matInput maxlength="10" formControlName="companyContact"
                              placeholder="Mobile Number" />
                                                   </mat-form-field>
                                                   <mat-error class="margin_tp"
                                                   *ngIf="companyMasterForm.controls['companyContact'].touched && companyMasterForm.controls['companyContact'].errors?.required">
                                                   This field is mandatory.
                                                 </mat-error>
                                                 <mat-error class="margin_tp"
                                                   *ngIf="companyMasterForm.controls['companyContact'].touched && companyMasterForm.controls['companyContact'].errors?.pattern">
                                                   Enter valid mobile Number.
                                                 </mat-error>
                       
                        </div>
                       
                          <div class="col-sm-2 ml-0 pl-0 wApp">
                            <i class="bi bi-whatsapp whatsapp-icon wApp fa-lg" (click)="WhatsApp();" matTooltip="WhatsApp Integration" ></i>
                          <!-- <img (click)="WhatsApp();" class="ml-0 pl-0 mt-2" matTooltip="WhatsApp Integration"
                            width="25" src="../../assets/whatsappGreen.png"> -->
                        </div>
  
                        </div>
                        </div>
  
                        <div class="col-sm-3 ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Company Email Address</mat-label>
                            <input matInput class="emailClass" type="email" placeholder="HA Company Email" formControlName="companyEmail" />
                          </mat-form-field>
                          <mat-error class="margin_tp"
                          *ngIf="companyMasterForm.controls['companyEmail'].touched && companyMasterForm.controls['companyEmail'].errors?.pattern">
                          Please enter a valid company email Address.
                        </mat-error>
                        </div>
                        <div class="col-sm-12 mt-2">
                          <h5 class="pb-0 mb-0">Address Details :</h5>
                        
                        </div>

                        <div class="col-sm-3 ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>HA Company Address
                              <!-- <span class="required-asterisk">*</span> -->
                            </mat-label>
                            <input matInput placeholder="HA Company Address" formControlName="companyAddress" />
                           
                          </mat-form-field>
                          <mat-error class="margin_tp"
                          *ngIf="companyMasterForm.controls['companyAddress'].touched && companyMasterForm.controls['companyAddress'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                        </div>
                        <div  class=" col-sm-3  "> 
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>State</mat-label>
                            <mat-select   #select11 placeholder="State" class="colorblack"  formControlName="state"   >
                              <mat-select-filter  *ngIf="select11.focused" [placeholder]="'Filter'" [displayMember]="'state'"
                                [array]="stateCityArr2"  (filteredReturn)="filteredList2 =$event"></mat-select-filter>
                              <mat-option *ngFor="let item of filteredList2" 
                               [value]="item.state" (click)="getDistrict2(item.districts)">
                                {{item.state}}
                              </mat-option>
                            </mat-select>
                          
                          </mat-form-field>
                          <mat-error
                          *ngIf="companyMasterForm.controls['state'].touched && companyMasterForm.controls['state'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                        </div>
                        <div class="col-sm-3 ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Pincode 
                              <!-- <span class="required-asterisk">*</span> -->
                            </mat-label>
                            <input matInput maxlength="6" placeholder="Pincode(e.g:416008)" formControlName="pincode" />
                            
                          </mat-form-field>
                          <mat-error class="margin_tp"
                              *ngIf="companyMasterForm.controls['pincode'].touched && companyMasterForm.controls['pincode'].errors?.pattern">
                              Enter only number.
                            </mat-error>
                            <mat-error class="margin_tp"
                              *ngIf="companyMasterForm.controls['pincode'].touched && companyMasterForm.controls['pincode'].errors?.maxlength">
                              Enter 6 digit Pincode.
                            </mat-error>
                            <mat-error class="margin_tp"
                              *ngIf="companyMasterForm.controls['pincode'].touched && companyMasterForm.controls['pincode'].errors?.minlength">
                              Enter 6 digit Pincode.
                            </mat-error>
                            <mat-error class="margin_tp"
                            *ngIf="companyMasterForm.controls['pincode'].touched && companyMasterForm.controls['pincode'].errors?.required">
                            This field is mandatory.
                          </mat-error>
                        </div>
                        <div class="col-sm-12 mt-2">
                          <h5 class="pb-0 mb-0">Sales Person Details :</h5>
                        
                        </div>

                        <div class="col-sm-3 ">
                          <div class="row">
                            <div class="col-sm-10 pr-1">
                              <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Sales Person Name</mat-label>
                                <mat-select #select5 placeholder="sales Person Name" class="colorblack" formControlName="salesPersonRefId">
                                  <mat-select-filter *ngIf="select5.focused" [placeholder]="'Filter'" [displayMember]="'salesPersonRefId'" [array]="salesPersonList"
                                    (filteredReturn)="filteredSalesList =$event"></mat-select-filter>
                                  <mat-option *ngFor="let item of filteredSalesList" [value]="item._id"
                                  (click)="salesPersonClick(item.salesPersonName,item._id);">
                                    {{item.salesPersonName}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>

                          <!-- <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Sales Person Name</mat-label>
                            <input matInput placeholder="Name" 
                            formControlName="salesPersonName" />
                          </mat-form-field> -->
                          <mat-error class="margin_tp"
                          *ngIf="companyMasterForm.controls['salesPersonRefId'].touched && companyMasterForm.controls['salesPersonRefId'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                     
                      </div>
                      <div class="col-sm-2 ml-0 pl-0 mt-2">
                        <i (click)="openSalesPopUp();" matTooltip="Add New Sales Person" 
                        class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                      </div>
                      </div>
                        </div>
                        <div class="col-sm-3 ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Contact Number</mat-label>
                            <input matInput placeholder="Mobile Number" readonly
                            formControlName="salesPersonNumber" maxlength="10" />
                          </mat-form-field>
                          <mat-error class="margin_tp"
                          *ngIf="companyMasterForm.controls['salesPersonNumber'].touched && companyMasterForm.controls['salesPersonNumber'].errors?.pattern">
                              Please enter valid Number.
                        </mat-error>
                        </div>
  
                      </div>
                    </div>
                  </div>
               
                       
                 
                 
                  <div class="row mb-2">
                    <div class="col-sm-10" *ngIf="submitButton"></div>
                    <div class="col-sm-8" *ngIf="cancelButton"></div>
                    <div class="col-sm-2 mt-2 ">
                      <button type="button" *ngIf="submitButton && companyCreate" class="btn btn-primary btn-sm mt-3 example-full-width"
                        (click)="submitCompany()">Save </button>
                      <button type="button" *ngIf="updateButton  && companyUpdate" class="btn btn-primary btn-sm mt-3 example-full-width"
                        (click)="update()">Update </button>
                    </div>
                    <div class="col-sm-2  mt-2" *ngIf="cancelButton">
                      <button class="btn btn-primary btn-sm mt-3  example-full-width" (click)="cancelUpdate()">Cancel
                      </button>
                    </div>
                  </div>
  
                </div>
              </div>
            </div>
          </div>
  
          <div class="col-sm-12 pl-0 pr-3">
            <div class="row">
              <div class="card  col-sm-12  borderradius p-3 mb-2">
                <div class="col-sm-12">
                  <div class="row">
                    <!-- <div class="col-sm-1"></div> -->
                    <div class="col-sm-3  ">
                      <h1 class="titlecolor" id="toCompanyList">List Of HA Companies</h1>
                    </div>
                    <div class="col-sm-6  ">
                    </div>
                    <div class="search-box col-sm-3 pb-2">
                      <input class="form-control amber-border" type="text" placeholder="Search" 
                      formControlName="searchCompany" aria-label="Search" (keyup)="onKeyUpEvent($event)"
                      >
                      <div class="input-group-append">
                         <span class="input-group-text amber lighten-3" id="basic-text1"><span class="material-symbols-outlined">search</span></span>
                      </div>
                   </div>
                   
                    <div class="col-sm-12">
                      <table
                      class="table table-bordered data-table-custom">
                        <thead>
                          <tr class="text-center">
                            <th>HA Company ID</th>
                            <th>HA Company Name</th>
                            <th>HA Company Address</th>
                            <th>Mobile Number</th>
                            <th>CAN Number</th>
                            <th>Sales Person</th>
                            <!-- <th>Action</th> -->
                          </tr>
                        </thead>
                        <tbody>
                          <tr (click)="toCompanyForm()" *ngFor="
                          let item of companyList
                          | paginate : { id:'comp_pagination', 
                          itemsPerPage: size, currentPage: page, totalItems:totalLength }
                        " (click)="prepopulation(item._id)" style="cursor:pointer; text-align: center ">
                        
                            <!-- <td *ngIf="item.branchWiseId == '' || item.branchWiseId == null" matTooltip="click to view">
                             -
                            </td> -->
                            <td *ngIf="item.branchWiseId !== '' || item.branchWiseId !== null" matTooltip="click to view">
                              {{item.branchWiseId}} 
                            </td>
                            <td matTooltip="click to view">
                              {{item.companyName}}
                            </td>
                            <td matTooltip="click to view">
                                {{item.companyAddress}}
                              </td>
                            <td matTooltip="click to view">
                              {{item.companyContact}}
                            </td>
                            <td matTooltip="click to view">
                                {{item.canNumber}}
                              </td>
                              <td matTooltip="click to view">
                                {{item.salesPersonName}}
                              </td>
                            <!-- <td class="text-center">
                                <i matTooltip="View" (click)="prepopulation(item._id)" class="fas fa-eye"></i>
                              </td> -->
  
                          </tr>
                        </tbody>
                      </table>
                    </div> 
                    <div class="col-sm-12 pt-0 text-right">
                      <pagination-controls id="comp_pagination" directionLinks="true" (pageChange)="onPageChange($event)"
                        class="float-right pt-3 my-pagination">
                      </pagination-controls>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
          </div>
  
        </div>
      </div>
    </form>
  </div>