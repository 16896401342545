import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Router} from '@angular/router';
import { ApiServiceService } from "../api-service.service";
@Component({
  selector: 'app-inventory-menu',
  templateUrl: './inventory-menu.component.html',
  styleUrls: ['./inventory-menu.component.scss']
})
export class InventoryMenuComponent implements OnInit {
  clinicRef: string;
  branchList: any;
  constructor(
    public dialogRef: MatDialogRef<InventoryMenuComponent>,@Inject(MAT_DIALOG_DATA) public data:any,
    public router: Router,
    public apimicroservice: ApiServiceService,) { 
      dialogRef.disableClose = true;
    }

  ngOnInit(): void {
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.fetchBranchList()
  }
  fetchBranchList() {
    this.apimicroservice.getBranchByClinicRefId(this.clinicRef).subscribe(res => {
      this.branchList = res.data.length;
    //
    
      if(res.status){
       //  this.apimicroservice.openSnackbar(res.message);
        
      }else{
        //this.apimicroservice.openSnackbar(res.message);
      }
 
    });
  
 }
  openPurchaseInvoicePopUp() {
      this.dialogRef.close();
      this.redirect('/home/goods-in-word');
  }
  openStockTransferPopUp() {
    if(this.branchList >= 1)
    {
      this.dialogRef.close();
      this.redirect('/home/stock-transfer');
    }
    else{
      this.apimicroservice.openSnackbar("This Feature Is Not Available");
    }
  }
  openPurchaseReturnPopUp()
  {
    this.dialogRef.close();
    this.redirect('home/goods-in-word-return');
  }
  close(): void {
    this.dialogRef.close();
  }
  redirect(to): void {
    this.router.navigate([to]);
   }
   
  onClose(): void {
    this.dialogRef.close();
  }
}
