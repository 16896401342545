import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
 import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";
import { PopUpPatientComponent } from "../pop-up-patient/pop-up-patient.component";
import { PopUpBranchComponent } from "../pop-up-branch/pop-up-branch.component";
import { MatDialog } from '@angular/material/dialog';
import { PopUpCanNumberComponent } from "../pop-up-can-number/pop-up-can-number.component";
@Component({
  selector: 'app-ear-mould-new',
  templateUrl: './ear-mould-new.component.html',
  styleUrls: ['./ear-mould-new.component.scss']
})
export class EarMouldNewComponent implements OnInit {
  earMouldNewForm: FormGroup;
  submitButton = true;
  updateButton = false;
  filteredListBranch: any;
  listOfEarMouldOrders:any;
  BranchList: any;
  size:number = 50;
  page:number = 1;
  totalLength: any;
  filteredListPatient: any;
  patientList: any;
  branchRef: any;
  searchText: string;
  clinicRef: any;
  pid: any;
  patientSelect = false;
  date: Date;
  userPermission:any;
  earMouldCreate= true;
  earMouldUpdate= true;
  isSuperAdmin= true;
  branchName: any;
  superBranchId: any;
  userRef: string;
  userType: string;
  cancelButton = false;
  earId: any;
  canNumberList: any;
  filteredListCanNumber: any;

  constructor(private fb: FormBuilder,
    public apimicroservice: ApiServiceService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,) { }
    
  ngOnInit(): void {
    this.date = new Date();
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.userRef = sessionStorage.getItem('userRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    this.userType = sessionStorage.getItem('userType');
   
    if(this.userType !== 'Super Admin'){
      this.isSuperAdmin= false;
    this.userPermission.forEach((element) => {
      if(element.pageName == 'Ear Mould' && element.create == false)
      {
          this.earMouldCreate = false;  
      }
      if(element.pageName == 'Ear Mould' && element.update == false)
      {
          this.earMouldUpdate = false;  
      }    
    });
  }

    this.earMouldNewForm = this.fb.group({
      "firstName": new FormControl(''),
      "lastName": new FormControl(''),
      "sex": new FormControl(''),
      "mobile1": new FormControl('',[Validators.pattern("(0|91)?[6-9][0-9]{9}")]), 
      "streetAddress": new FormControl(''),
      "customEarmoldMaterials": new FormControl(''),
      "venting": new FormControl(''),
      "attachment": new FormControl(''),
      "soundBore": new FormControl(''),
      "modifications": new FormControl(''),
      "hearingLoss": new FormControl(''),
      "earTexture": new FormControl(''),
      "earMoldId":new FormControl(''),
      "earMoldOrderDate" : new FormControl(''),
      "accountNumber" :new FormControl(''),
      "poNumber":new FormControl(''),
      "hearingAidDispenser":new FormControl(''),
      "clinicRefId":new FormControl(''),
      "patientRefId" : new FormControl('',Validators.required),
      "canal" :new FormControl(false),
      "skeleton" :new FormControl(false),
      "Skeleton_3BY4" :new FormControl(false),
      "shell" :new FormControl(false),
      "halfShell" :new FormControl(false),
      "canalLock" :new FormControl(false),
      "noa" :new FormControl(false),
      "nob" :new FormControl(false),
      "noc" :new FormControl(false),
      "stethosscopeMold" :new FormControl(false),
      "listeningTube" :new FormControl(false),
      "headPhoneStareo" :new FormControl(false),
      "headset" :new FormControl(false),
      "standard" :new FormControl(false),
      "customReceiver" :new FormControl(false),
      "totalSeal" :new FormControl(false),
      "totalFlex" :new FormControl(false),
      "pilotPartner" :new FormControl(false),
      "soundPort" :new FormControl(false),
      "earPlugs" :new FormControl(false),
      "sleepingPlugs" :new FormControl(false),
      "noiseReductionPlug" :new FormControl(false),
      "swimPlugs" :new FormControl(false),
      "left" :new FormControl(false),
      "right" :new FormControl(false),
      "filtered" :new FormControl(false),
      "handle" :new FormControl(false),
      "cord" :new FormControl(false),
      "ER15" :new FormControl(false),
      "ER25" :new FormControl(false),
      "hockNoiseBraker" :new FormControl(false),
      "sonicValueEarplug" :new FormControl(false),
      "ipod_Mp3Mold" :new FormControl(false),
      "musicianEarplug" :new FormControl(false),
      "solidEarplugs"  :new FormControl(false),
      "aspectTubing" :new FormControl(''),
    "airRtBone1":new FormControl(''),
    "airRtBone2":new FormControl(''),
    "airRtBone3":new FormControl(''),
    "airRtBone4":new FormControl(''),
    "frequency1":new FormControl(''),
    "frequency2":new FormControl(''),
    "make":new FormControl(''),
    "model":new FormControl(''),
    "receiver":new FormControl(''),
    "remark": new FormControl(''),
    "branchRefId": new FormControl(''),
    "branchName": new FormControl(''),
    "branchWiseId": new FormControl(''),
      "createdAt": new Date(),
      "searchEarMouldOrder":new FormControl(''),
      // "updatedAt": new Date(),
      
    });
    this.fetchEarMouldOrderList();
    this.fetchCanNumberList();
     this.getLastId();
     this.getAllBranch();
  }
  // getAllBranch()
  // {
  //   this.apimicroservice.getAllBranchList().subscribe(res => {
  //     if (res.status) {        
  //       this.earMouldNewForm.get("branchRefId").setValue(res.data[0]._id);
  //       this.branchClick(res.data[0]._id,this.branchName);
  //       if(this.userType == 'Super Admin')
  //       {  
  //         this.apimicroservice.getBranchById(res.data[0]._id).subscribe(res => {
  //           this.spinner.hide();
  //           this.earMouldNewForm.get("branchName").setValue(res.data[0].branchName);
  //          });
  //       }
       
  //     }
  //   });
  // }

  fetchEarMouldOrderList() {
    let pg = 1;
    let size = 15;
 //   this.spinner.show();
    let branchRef='';
    let clinicRef='';
    this.searchText = this.earMouldNewForm.get('searchEarMouldOrder').value;

    // if(this.userType !== 'Super Admin')
    //  {
    //   this.apimicroservice.getEarMouldOrderData(pg,size,this.searchText,this.branchRef).subscribe(res => {
    //     //
    //     this.listOfEarMouldOrders = res.data.data;
    //     this.totalLength = res.data.totalItems;
    //     //this.buildingLenght = this.listofbuild.length;
    //    if(res.status){
    //      this.apimicroservice.openSnackbar(res.message);
         
    //    }else{
    //      //this.apimicroservice.openSnackbar(res.message);
    //    }
    //   });
    //  }else{
    //   this.apimicroservice.getEarMouldOrderData(pg,size,this.searchText,branchRef).subscribe(res => {
    //     //
    //     this.listOfEarMouldOrders = res.data.data;
    //     this.totalLength = res.data.totalItems;
    //     //this.buildingLenght = this.listofbuild.length;
    //    if(res.status){
    //      this.apimicroservice.openSnackbar(res.message);
         
    //    }else{
    //      //this.apimicroservice.openSnackbar(res.message);
    //    }
    //   });
    //  }

  }
  toearMouldNewForm() {
    document.getElementById("toearMouldNewForm").scrollIntoView();
  }
  toearMouldNewFormList() {
    document.getElementById("toearMouldNewFormList").scrollIntoView();
  }
  prepopulation(id)
  {
    this.apimicroservice.getEarMouldDataById(id).subscribe(res => {
      //
      let data = res.data[0];
      this.earId = id;
    
         this.updateButton = true;
         this.submitButton = false;
         this.cancelButton = true;
         
         this.branchClick(data.branchRefId,data.branchName);
         this.onSelectPatient(data.patientRefId);
        
        //  this.onSelectDoctor(data.doctorRefId);
        this.earMouldNewForm = this.fb.group({
          "firstName": new FormControl(data.firstName),
          "lastName": new FormControl(data.lastName),
          "sex": new FormControl(''),
          "mobile1": new FormControl(data.mobile1,[Validators.pattern("(0|91)?[6-9][0-9]{9}")]),
          "streetAddress": new FormControl(data.streetAddress),
          "customEarmoldMaterials": new FormControl(data.customEarmoldMaterials),
          "venting": new FormControl(data.venting),
          "attachment": new FormControl(data.attachment),
          "soundBore": new FormControl(data.soundBore),
          "modifications": new FormControl(data.modifications),
          "hearingLoss": new FormControl(data.hearingLoss),
          "earTexture": new FormControl(data.earTexture),
          "earMoldId":new FormControl(data.earMoldId),
          "earMoldOrderDate" : new FormControl(data.earMoldOrderDate),
          "accountNumber" :new FormControl(data.accountNumber),
          "poNumber":new FormControl(data.poNumber),
          "hearingAidDispenser":new FormControl(data.hearingAidDispenser),
          "clinicRefId":new FormControl(data.clinicRefId),
          "patientRefId" : new FormControl(data.patientRefId,Validators.required),
          "canal" :new FormControl(data.canal),
          "skeleton" :new FormControl(data.skeleton),
          "Skeleton_3BY4" :new FormControl(data.Skeleton_3BY4),
          "shell" :new FormControl(data.shell),
          "halfShell" :new FormControl(data.halfShell),
          "canalLock" :new FormControl(data.canalLock),
          "noa" :new FormControl(data.noa),
          "nob" :new FormControl(data.nob),
          "noc" :new FormControl(data.noc),
          "stethosscopeMold" :new FormControl(data.stethosscopeMold),
          "listeningTube" :new FormControl(data.listeningTube),
          "headPhoneStareo" :new FormControl(data.headPhoneStareo),
          "headset" :new FormControl(data.headset),
          "standard" :new FormControl(data.standard),
          "customReceiver" :new FormControl(data.customReceiver),
          "totalSeal" :new FormControl(data.totalSeal),
          "totalFlex" :new FormControl(data.totalFlex),
          "pilotPartner" :new FormControl(data.pilotPartner),
          "soundPort" :new FormControl(data.soundPort),
          "earPlugs" :new FormControl(data.earPlugs),
          "sleepingPlugs" :new FormControl(data.sleepingPlugs),
          "noiseReductionPlug" :new FormControl(data.noiseReductionPlug),
          "swimPlugs" :new FormControl(data.swimPlugs),
          "left" :new FormControl(data.left),
          "right" :new FormControl(data.right),
          "filtered" :new FormControl(data.filtered),
          "handle" :new FormControl(data.handle),
          "cord" :new FormControl(data.cord),
          "ER15" :new FormControl(data.ER15),
          "ER25" :new FormControl(data.ER25),
          "hockNoiseBraker" :new FormControl(data.hockNoiseBraker),
          "sonicValueEarplug" :new FormControl(data.sonicValueEarplug),
          "ipod_Mp3Mold" :new FormControl(data.ipod_Mp3Mold),
          "musicianEarplug" :new FormControl(data.musicianEarplug),
          "solidEarplugs"  :new FormControl(data.solidEarplugs),
          "aspectTubing" :new FormControl(data.aspectTubing),
        "airRtBone1":new FormControl(data.airRtBone1),
        "airRtBone2":new FormControl(data.airRtBone2),
        "airRtBone3":new FormControl(data.airRtBone3),
        "airRtBone4":new FormControl(data.airRtBone4),
        "frequency1":new FormControl(data.frequency1),
        "frequency2":new FormControl(data.frequency2),
        "make":new FormControl(data.make),
        "model":new FormControl(data.model),
        "receiver":new FormControl(data.receiver),
        "remark": new FormControl(data.remark),
        "branchRefId": new FormControl(data.branchRefId),
        "branchName": new FormControl(data.branchName),
        "branchWiseId": new FormControl(data.branchWiseId),
        "searchEarMouldOrder":new FormControl(''),
        "updatedAt": new Date(),
          
        });
    this.superBranchId = data.branchWiseId;
     });
  }
 
  cancelUpdate(){
    this.earMouldNewForm.reset();
     this.submitButton=true;
     this.updateButton=false;
     this.cancelButton = false;
    this.ngOnInit();
  }

  submitEarMouldOrderList() {
    let payload = this.earMouldNewForm.value;
    if(this.userType == 'Super Admin')
    {
      this.earMouldNewForm.controls['branchRefId'].setValidators(Validators.required);
      this.earMouldNewForm.controls['branchRefId'].updateValueAndValidity();
    }
    else{
      this.earMouldNewForm.controls['branchRefId'].setErrors(null);
      this.earMouldNewForm.controls['branchRefId'].clearValidators();
      this.earMouldNewForm.controls['branchRefId'].updateValueAndValidity();
    }
    
    if (this.earMouldNewForm.invalid) {

       this.earMouldNewForm.get("patientRefId").markAsTouched();
     

    }else {
      this.saveData();
    }
    
  }

  
  saveData(){
    
    let payload = this.earMouldNewForm.value;
    //
    payload.earMoldOrderDate = this.date;
    if(this.userType !== 'Super Admin')
    {
      payload.branchRefId = this.branchRef;
      payload.clinicRefId = this.clinicRef;
      payload.branchName = this.branchName;
    }
    this.spinner.show();
    this.apimicroservice.addEarMold(payload).subscribe(res => {
      this.spinner.hide();
      this.toearMouldNewForm();
      this.ngOnInit();
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    },(ERROR:HttpErrorResponse) => {
      this.toearMouldNewForm();
      this.apimicroservice.openSnackbar(ERROR.error.message);
      this.spinner.hide();
  });  
  } 

  updateEarMouldOrderList() {
    let payload = this.earMouldNewForm.value;
    if(this.userType == 'Super Admin')
    {
      this.earMouldNewForm.controls['branchRefId'].setValidators(Validators.required);
      this.earMouldNewForm.controls['branchRefId'].updateValueAndValidity();
    }
    else{
      this.earMouldNewForm.controls['branchRefId'].setErrors(null);
      this.earMouldNewForm.controls['branchRefId'].clearValidators();
      this.earMouldNewForm.controls['branchRefId'].updateValueAndValidity();
    }
    
    if (this.earMouldNewForm.invalid) {

       this.earMouldNewForm.get("patientRefId").markAsTouched();
     

    }else {
      this.updateEarMoldData();
    }
    
  }

  updateEarMoldData() {
    this.spinner.show();
    let payload = this.earMouldNewForm.value;
    payload.earMoldOrderDate = this.date;
    if(this.userType !== 'Super Admin')
    {
      payload.branchRefId = this.branchRef;
      payload.clinicRefId = this.clinicRef;
      payload.branchName = this.branchName;
    }
     this.apimicroservice.updateEarMold(payload,this.earId).subscribe(res => {
       //
       this.spinner.hide();
       if(res.status){
         this.apimicroservice.openSnackbar(res.message);
          this.updateButton = false;
          this.submitButton = true;
         this.cancelButton=false;
         this.toearMouldNewForm();
          this.ngOnInit();
       }else{
       //  //this.apimicroservice.openSnackbar(res.message);
       }
     });
    
  }
  
  onKeyUpEvent(event: any) {
    let branchRef='';
    let clinicRef='';
    this.searchText = this.earMouldNewForm.get('searchEarMouldOrder').value;

    // if(this.userType !== 'Super Admin')
    //  {
    //   this.apimicroservice.getEarMouldOrderData(this.page,this.size,this.searchText,this.branchRef).subscribe(res => {
    //     //
    //     this.listOfEarMouldOrders = res.data.data;
    //     this.totalLength = res.data.totalItems;
    //     //this.buildingLenght = this.listofbuild.length;
    //    if(res.status){
    //      this.apimicroservice.openSnackbar(res.message);
         
    //    }else{
    //      //this.apimicroservice.openSnackbar(res.message);
    //    }
    //   });
    //  }else{
    //   this.apimicroservice.getEarMouldOrderData(this.page,this.size,this.searchText,branchRef).subscribe(res => {
    //     //
    //     this.listOfEarMouldOrders = res.data.data;
    //     this.totalLength = res.data.totalItems;
    //     //this.buildingLenght = this.listofbuild.length;
    //    if(res.status){
    //      this.apimicroservice.openSnackbar(res.message);
         
    //    }else{
    //      //this.apimicroservice.openSnackbar(res.message);
    //    }
    //   });
    // }
  }
  onPageChange(event) {
    this.page = event;
    let branchRef='';
    let clinicRef='';
    this.searchText = this.earMouldNewForm.get('searchEarMouldOrder').value;

    // if(this.userType !== 'Super Admin')
    //  {
    //   this.apimicroservice.getEarMouldOrderData(this.page,this.size,this.searchText,this.branchRef).subscribe(res => {
    //     //
    //     this.listOfEarMouldOrders = res.data.data;
    //     this.totalLength = res.data.totalItems;
    //     //this.buildingLenght = this.listofbuild.length;
    //    if(res.status){
    //      this.apimicroservice.openSnackbar(res.message);
         
    //    }else{
    //      //this.apimicroservice.openSnackbar(res.message);
    //    }
    //   });
    //  }else{
    //   this.apimicroservice.getEarMouldOrderData(this.page,this.size,this.searchText,branchRef).subscribe(res => {
    //     //
    //     this.listOfEarMouldOrders = res.data.data;
    //     this.totalLength = res.data.totalItems;
    //     //this.buildingLenght = this.listofbuild.length;
    //    if(res.status){
    //      this.apimicroservice.openSnackbar(res.message);
         
    //    }else{
    //      //this.apimicroservice.openSnackbar(res.message);
    //    }
    //   });
    // }
  }
  fetchPatientList(){
    this.searchText = '';
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    //
    if(this.userType !== 'Super Admin')
    {
      this.apimicroservice.getPatientListByBranchClinic(branchRef,clinicRef).subscribe(res => {
        //
        
        if (res.status) {    
          this.patientList = res.data;
          this.filteredListPatient = this.patientList.slice();
        } else {
         // this.apimicroservice.openSnackbar(res.message);
        }
      })
    }
    else
    {
      this.apimicroservice.getPatientListByBranchClinic(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
        //
        
        if (res.status) {    
          this.patientList = res.data;
          this.filteredListPatient = this.patientList.slice();
        } else {
         // this.apimicroservice.openSnackbar(res.message);
        }
      })
    }
   
  }
  fetchCanNumberList(){
    this.searchText = '';
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if(this.userType !== 'Super Admin')
    {
      this.apimicroservice.getCanAllData(branchRef,clinicRef).subscribe(res => {
        //
        
        if (res.status) {    
          this.patientList = res.data;
          this.filteredListPatient = this.patientList.slice();
        } else {
          this.apimicroservice.openSnackbar(res.message);
        }
      })
    }
    else
    {
      this.apimicroservice.getCanAllData(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
        //
        
        if (res.status) {    
          this.canNumberList = res.data;
          this.filteredListCanNumber = this.canNumberList.slice();
        } else {
          this.apimicroservice.openSnackbar(res.message);
        }
      })
    }
    
  }
  // fetchBranchList() {
  //   this.apimicroservice.getAllBranchList().subscribe(res => {
  //     if (res.status) {    
  //       this.BranchList = res.data;
  //       this.filteredListBranch= this.BranchList.slice();
  //     } else {
  //       this.apimicroservice.openSnackbar(res.message);
  //     }
  //   })
  // }
  getAllBranch() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      if (res.status) {
        this.BranchList = res.data;
        this.filteredListBranch = this.BranchList.slice();
        let length = res.data.length - 1;
        this.branchName = res.data[length].branchName;
        this.earMouldNewForm.controls["branchRefId"].setValue(res.data[length]._id);
        this.branchClick(res.data[length]._id, this.branchName);
      
      }
    });
  }
  
  branchClick(id,branchName){

   this.earMouldNewForm.controls["branchName"].setValue(branchName);
   let clinicRef= sessionStorage.getItem('clinicRef');
   let branchRef= sessionStorage.getItem('branchRef');
   this.searchText = '';
   this.apimicroservice.getPatientListByBranchClinic(branchRef,clinicRef).subscribe(res => {
     //
     
     if (res.status) {    
       this.patientList = res.data;
       this.filteredListPatient = this.patientList.slice();
     } else {
       this.apimicroservice.openSnackbar(res.message);
     }
   })

   this.apimicroservice.getBranchById(id).subscribe(res => { 
     //
       
     let data = res.data[0];
     let payload = this.earMouldNewForm.value;
      payload.clinicRefId = res.data[0].clinicRefId;
      this.earMouldNewForm.controls["clinicRefId"].setValue(res.data[0].clinicRefId);
      if(this.updateButton == false){
       //  this.spinner.show();
        this.apimicroservice.getLastEarMoldID(payload.branchRefId).subscribe(res => {
            
         
          if (res['status']) {
            let repairIdd = res['data']['totalItems'];
            this.earMouldNewForm.controls['branchWiseId'].setValue(repairIdd + 1);
          } else {
            this.apimicroservice.openSnackbar("Something went wrong");
          }
         //  this.spinner.hide();
        })
      }
    });

 }

 onSelectPatient(id){
  this.patientSelect=true;
  this.apimicroservice.getPatientById(id).subscribe(res => {
    let data = res.data[0];
    this.pid = id;

    this.earMouldNewForm.controls["firstName"].setValue(data.firstName);
    this.earMouldNewForm.controls["lastName"].setValue(data.lastName);
    //this.earMouldNewForm.controls["sex"].setValue(data.sex);
    this.earMouldNewForm.controls["mobile1"].setValue(data.mobile1);
    this.earMouldNewForm.controls["streetAddress"].setValue(data.streetAddress);

  });
}
getLastId()
{
  if(this.userType !== 'Super Admin'){
  this.apimicroservice.getLastEarMoldID(this.branchRef).subscribe(res => {
    //   
    if (res['status']) {
      
      let clientIdd = res.data.totalItems;
      //
      
      this.earMouldNewForm.controls['branchWiseId'].setValue(clientIdd + 1);
      
      
    } else {
      this.apimicroservice.openSnackbar("Something went wrong");
    }
   
  })
}
else
{
  this.apimicroservice.getEarMoldLastAll().subscribe(res => {
    //   
    if (res['status']) {
      
      let clientIdd = res.data.totalItems;
      //
      
      this.superBranchId = clientIdd + 1;
      
      
      //this.earMouldNewForm.controls['branchWiseId'].setValue(clientIdd + 1);
    } else {
      this.apimicroservice.openSnackbar("Something went wrong");
    }
   
  })
}
}
 openPatientPopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpPatientComponent,
      {
        width: "1200px",
      });
      dialogRef3.afterClosed().subscribe((result) => {
        this.fetchPatientList();  
       });
  }
  openBranchPopUp() {
    const dialogRef3 = this.dialog.open(PopUpBranchComponent,
      {
        width: "1200px",
      });
    dialogRef3.afterClosed().subscribe((result) => {
      let clinicRef= sessionStorage.getItem('clinicRef');
      this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
        if (res.status) {
          this.BranchList = res.data;
          this.filteredListBranch = this.BranchList.slice();
          this.branchClick(res.data[0]._id, res.data[0].branchName);
          this.earMouldNewForm.get("branchRefId").setValue(res.data[0]._id);
        }
      })
    })
  }
openEarMouldPopUp() {
  localStorage.clear();
  const dialogRef3 = this.dialog.open(PopUpCanNumberComponent,
    {
      width: "500px",
    });
    dialogRef3.afterClosed().subscribe((result) => {
      this.fetchCanNumberList();  
     });
}
onback() {
  this.apimicroservice.back()
}
}