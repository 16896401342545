<div class="container-fluid bgcolor2" [formGroup]="customHaForm">
  <form [formGroup]="customHaForm" autocomplete="off" novalidate>
    <div class="row col-sm-12 p-0 m-0">
      <div class="col-sm-1">

      </div>
      <div class="col-sm-11 divName">
        <div class="row pl-0 ">

          <div class="col-12 pl-0 ">
            <div class="row ">
            <div class="col-sm-9">
            
              
                <div class="row col-sm-12">

                  <div class="col-sm-6 ">
                    <div class="row ">
             
                  <span>
                    <h1 class="pt-2 pl-0"  id="toCustomHAForm">Custom Hearing Aid</h1>
                  </span>
                  </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="row ">
                    <span>
                      <h1 *ngIf="isPrint" class="fass ml-3 pt-1">Bill To {{billCompanyName}}</h1>
                    </span>
                    </div>
                    </div>
                </div>


                </div>
                <div class="col-sm-3 mt-1">
                  <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                    <a (click)="onback()" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">arrow_back</span>Back</a>
                    <a (click)="toCustomHAList()" class="d-flex align-items-center ms-3 pr-2 mt-2"><span class="material-symbols-outlined pr-2">reorder</span>List</a>
                    <a *ngIf="isPrepopulate" (click)="printForm()" class="d-flex justify-content-end align-items-end mt-2">
                      <span class="material-symbols-outlined pr-1">print</span>Print</a> 

                  </div>
          </div>

                
        </div>
       </div>
       </div>

        <div class="row mt-2">
          <div class="col-sm-12 pr-4">
            <div class="row">
              <div class="card divbody borderradius mb-2 pl-4 pr-4 p-1">
                <div class="row">
                  <div class="col-sm-12">
                    <!-- <div class="row mt-2">
                      <div class=" col-sm-2 ">
                        <mat-label formControlName="branchWiseId" *ngIf="userType !== 'Super Admin'" >{{bID}}</mat-label>
                          <mat-label [value]="superBranchId" *ngIf="userType == 'Super Admin'" >{{superBranchId}}</mat-label>
                      </div>

                      <div class="col-sm-10"></div>
                    </div> -->

                    <div class="row">
                      <!-- <div class="col-sm-12 mt-2">
                        <div class="row"> 
                          <div class="col-sm-12 mt-2">
                          <mat-label class="center" ngDefaultControl formControlName="branchWiseId" *ngIf="userType !== 'Super Admin'" >{{bID}}</mat-label>
                          <mat-label class="center" ngDefaultControl [value]="superBranchId" *ngIf="userType == 'Super Admin'" >{{superBranchId}}</mat-label>
                          </div>
                          </div>
                          </div>  -->

                      <div class="col-sm-12 ">
                        <h5 class="mt-2">Customer Details :</h5>
                      </div>
                    
  
                        <div class=" col-sm-1 mt-3 ">
                          <mat-label class="center" ngDefaultControl formControlName="branchWiseId" *ngIf="userType !== 'Super Admin'" >{{bID}}</mat-label>
                          <mat-label class="center" ngDefaultControl [value]="superBranchId" *ngIf="userType == 'Super Admin'" >{{superBranchId}}</mat-label>
                        </div>
                        <div class=" col-sm-2">  
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Date</mat-label>
                            <input matInput placeholder="Date" formControlName="date" readonly />
                          </mat-form-field>
                        </div>


                      <div *ngIf="patientCreate" class="col-sm-3">
                        <div class="row"> 
                          <div class="col-sm-10 pr-1">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Select Patient </mat-label>
                              <input required (click)="openDepartmentPopUp();" matInput readonly
                               placeholder="Patient Name" formControlName="patientName" />
                              <!-- <mat-select #select2 formControlName="patientRefId">

                                <mat-select-filter *ngIf="select2.focused" [placeholder]="'Filter'"
                                  [displayMember]="'firstName'" [array]="patientList"
                                  (filteredReturn)="filteredListPatient =$event"></mat-select-filter>

                                <mat-option *ngFor="let item of filteredListPatient" [value]="item._id"
                                  (click)="onSelectPatient(item._id)">
                                  {{ item.firstName }}</mat-option>

                              </mat-select> -->

                            </mat-form-field>
                            <mat-error class="margin_tp"
                              *ngIf="customHaForm.controls['patientRefId'].touched && customHaForm.controls['patientRefId'].errors?.required">
                              This field is mandatory.
                            </mat-error>
                          </div>
                          <div class="col-sm-2 mt-2 no-print">
                            <i (click)="openPatientPopUp();" matTooltip="Add New Patient" class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"> </i>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="!patientCreate" class="col-sm-3">
                        <div class="row">
                          <div class="col-sm-12 pr-1">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Select Patient </mat-label>
                              <mat-select #select3 formControlName="patientRefId">
                                <mat-select-filter *ngIf="select3.focused" [placeholder]="'Filter'"
                                  [displayMember]="'firstName'" [array]="patientList"
                                  (filteredReturn)="filteredListPatient =$event"></mat-select-filter>
                                <mat-option *ngFor="let item of filteredListPatient" [value]="item._id"
                                  (click)="onSelectPatient(item._id)">
                                  {{ item.firstName }}</mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-error class="margin_tp"
                              *ngIf="customHaForm.controls['patientRefId'].touched && customHaForm.controls['patientRefId'].errors?.required">
                              This field is mandatory.
                            </mat-error>
                          </div>

                        </div>
                      </div>
                      <div class="col-sm-6"></div>
                      <div class="col-sm-3">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label> Patient Unique Id</mat-label>
                          <input matInput placeholder="Patient Unique Id " formControlName="patientUniqueId" readonly />
                        </mat-form-field>
                      </div>
                      <div class="col-sm-3">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label> First Name </mat-label>
                          <input matInput placeholder="First Name " formControlName="firstName" readonly />
                          <!-- <mat-error
                                      *ngIf="customHaForm.controls['leftSerialNo'].touched && customHaForm.controls['leftSerialNo'].errors?.required">
                                      This field is mandatory.
                                    </mat-error> -->
                        </mat-form-field>
                      </div>
                      <div class="col-sm-3">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label> Last Name</mat-label>
                          <input matInput placeholder="Last Name" formControlName="lastName" readonly />
                          <!-- <mat-error
                                      *ngIf="customHaForm.controls['leftSerialNo'].touched && customHaForm.controls['leftSerialNo'].errors?.required">
                                      This field is mandatory.
                                    </mat-error> -->
                        </mat-form-field>
                      </div>
                      <!-- <div class="col-sm-3">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Age</mat-label>
                          <input matInput placeholder="Age" formControlName="age" readonly />

                        </mat-form-field>
                       
                      </div> -->
                      <div class="col-sm-3">
                        <div class="row">
                          <div class="col-sm-10">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label> Mobile No </mat-label>
                              <input matInput placeholder="mobileNo" maxlength="10" formControlName="mobile1" />
                              <mat-error
                                      *ngIf="customHaForm.controls['mobile1'].touched && customHaForm.controls['mobile1'].errors?.pattern">
                                      Please enter valid Phone Number.
                                    </mat-error>
                            </mat-form-field>
                          </div>
                          <div class="col-sm-2 wApp">
                            <i class="bi bi-whatsapp whatsapp-icon wApp fa-lg" (click)="WhatsApp();" matTooltip="WhatsApp Integration" ></i>
                              </div>
                        
                        </div>
                      </div>


                      <div class="col-sm-3">
                        <!-- <mat-form-field class="example-full-width" appearance="outline">
                                      <mat-label> Address </mat-label>
                                      <input matInput placeholder="Address"  formControlName="streetAddress"   />
                                      <mat-error
                                      *ngIf="customHaForm.controls['leftSerialNo'].touched && customHaForm.controls['leftSerialNo'].errors?.required">
                                      This field is mandatory.
                                    </mat-error>
                                    </mat-form-field> -->
                      </div>







                    </div>
                    <div class="row mb-2">
                      <div class="col-sm-12">
                        <h5 class="mt-2">Company Details :</h5>
                      </div>

                      <div *ngIf="companyCreate" class="col-sm-3">
                        <div class="row">
                          <div class="col-sm-10 pr-1"> 
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Select HA Company </mat-label>
                              <mat-select #select2 formControlName="companyRefId">
                                <mat-select-filter *ngIf="select2.focused" [placeholder]="'Filter'"
                                  [displayMember]="'companyName'" [array]="companyList"
                                  (filteredReturn)="filteredListCompany =$event"></mat-select-filter>
                                <mat-option *ngFor="let item of filteredListCompany" [value]="item._id"
                                  (click)="onSelectCompany(item.companyName,item.companyAddress,item.companyEmail,item.canNumber,item.make)">
                                  {{ item.companyName }}</mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-error
                              *ngIf="customHaForm.controls['companyRefId'].touched && customHaForm.controls['companyRefId'].errors?.required">
                              This field is mandatory.
                            </mat-error>
                          </div>
                          
                          <div class="col-sm-2 mt-2 no-print">
                            <i (click)="openCompanyPopUp();" matTooltip="Add Company"class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"> </i>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="!companyCreate" class="col-sm-3">
                        <div class="row">
                          <div class="col-sm-12 pr-1">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Select Company </mat-label>
                              <mat-select #select2 formControlName="companyRefId">
                                <mat-select-filter *ngIf="select2.focused" [placeholder]="'Filter'"
                                  [displayMember]="'companyName'" [array]="companyList"
                                  (filteredReturn)="filteredListCompany =$event"></mat-select-filter>
                                <mat-option *ngFor="let item of filteredListCompany" [value]="item._id"
                                  (click)="onSelectCompany(item.companyName,item.companyAddress,item.companyEmail,item.canNumber,item.make)">
                                  {{ item.companyName }}</mat-option>
                              </mat-select>
                            </mat-form-field>
                            <!-- <mat-error
                                *ngIf="customHaForm.controls['companyRefId'].touched && customHaForm.controls['companyRefId'].errors?.required">
                                This field is mandatory.
                              </mat-error> -->
                          </div>

                        </div>
                      </div>
                      <div class="col-sm-9"></div>
                      <div class="col-sm-3 mt-1">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>HA Company Name</mat-label>
                          <input matInput placeholder="HA Company Name" formControlName="companyName" readonly />
                        </mat-form-field>
                      </div>
                      <!-- <div class="col-sm-3 mt-1">
                              <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>  Company Address</mat-label>
                                <input matInput placeholder="Company Address" formControlName="companyAddress" readonly />
                              </mat-form-field>
                            </div>  -->
                      <div class="col-sm-3 mt-1">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>HA Company Email</mat-label>
                          <input matInput class="emailClass" placeholder="Company Address" formControlName="companyEmail" readonly />
                        </mat-form-field>
                      </div>
                      <div class="col-sm-3 mt-1">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label> CAN Number</mat-label>
                          <input matInput placeholder="CAN Number" formControlName="canNumber" readonly />
                        </mat-form-field>
                      </div>
                      <div class="col-sm-3 mt-1">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Make</mat-label>
                          <input matInput placeholder="Make" formControlName="make" readonly />
  
                        </mat-form-field>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>




            
              <div class="card divbody borderradius mb-2 pl-4 pr-4 p-1">
                <div class="row mt-2">
                  <div class="col-sm-12 mb-1">
                    <h5 class="mt-2">Custom Hearing Aid Details :</h5>
                  </div>
                  <div class="col-sm-3">
                    <h5 class=" mt-1">Ear :</h5>
                  </div>
                  <div class="col-sm-3">
                    <mat-radio-group formControlName="ear" required class="example-full-width" aria-label="Select Type">
                      <div class="row">
                        <div class="col-sm-4 pt-1">
                          <mat-radio-button (click)="onEarChange('Right')" value="Right">Right</mat-radio-button>
                        </div>
                        <div class="col-sm-4 pt-1">
                          <mat-radio-button (click)="onEarChange('Left')" value="Left">Left</mat-radio-button>
                        </div>
                        <div class="col-sm-4 pt-1">
                          <mat-radio-button (click)="onEarChange('Both')" value="Both">Both</mat-radio-button>
                        </div>
                      </div>
                    </mat-radio-group>
                    <mat-error
                      *ngIf="customHaForm.controls['ear'].touched && customHaForm.controls['ear'].errors?.required">
                      This field is mandatory.
                    </mat-error>
                  </div>
                  <div class="col-sm-6"></div>
                  <div class="col-sm-3">
                    <!-- <label class="header2 mt-1">Estimates Required  :</label> -->
                    <h5 class=" mt-2">Estimates Required :</h5>
                  </div>
                  <div class="col-sm-3">
                    <mat-radio-group formControlName="estimateRequired" class="mt-1 example-full-width"
                      aria-label="Select Type">
                      <div class="row">
                        <div class="col-sm-4 pt-1">
                          <mat-radio-button value="Yes">Yes</mat-radio-button>
                        </div>
                        <div class="col-sm-4 pt-1">
                          <mat-radio-button value="No">No</mat-radio-button>
                        </div>

                      </div>
                    </mat-radio-group>

                  </div>
                  <div class="col-sm-3">
                    <!-- <h5 class="ml-3 mt-3">Complaints/Instructions :</h5> -->
                  </div>
                  <!-- <div class="col-sm-3">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label> Complaints/Instructions </mat-label>
                      <input matInput placeholder="Address" formControlName="streetAddress" readonly />
                      <textarea placeholder="Address" matInput formControlName="complaint" cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2" cdkAutosizeMaxRows="5"></textarea>
                    </mat-form-field>
                  </div> -->
                </div>
              </div>
              <div class="col-sm-6  mb-2 pl-0 pr-2 pt-0 pb-0">
                <div class="grey-title-card card p-0 mb-4">
                  <div class="grey-card-header bg-grey p-3 d-flex justify-content-between align-items-center">
                           <h5>LEFT</h5>
                           <div class="right d-flex align-items-center">
                              <span class="side-mark left-mark">Left</span>
                              <span class="material-symbols-outlined pointer" (click)="leftReset()">restart_alt</span>
                              <span class="material-symbols-outlined font-20 pointer"  (click)="copyToRight()">file_copy</span>
                           </div>
                  </div> 
                  <div class="card=content p-4">
                    <div class="row">
                      <div class="col-sm-6 col-12 mb-lg-0 mb-2">
                        <div class="form-group">
                          <div class="row">
                            <div class="col-sm-4">
                              <h5 class="mt-4">Model :</h5>
                            </div>
                            <div class="col-sm-8">
                              <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Model</mat-label>
                                <mat-select  #select1 formControlName="leftModel" >
                                  <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'model'"
                                  [array]="DeviceList"  (filteredReturn)="filteredListDevice =$event"></mat-select-filter>
                                 
                                  <mat-option *ngFor="let item of filteredListDevice" [value]="item.model" 
                                  (click)="onLeftModelChange($event,item._id,item.brandLable)"
                                  >
                                   {{ item.model }}</mat-option>
                                  </mat-select>
                              </mat-form-field>
                              <mat-error class="margin_tp"
                                *ngIf="customHaForm.controls['leftModel'].touched && customHaForm.controls['leftModel'].errors?.required">
                                This field is mandatory.
                              </mat-error>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6 col-12">
                        <div class="form-group">
                          <div class="row">
                            <div class="col-sm-4">
                              <h5 class="mt-4">Make :</h5>
                            </div>
                            <div class="col-sm-8">
                              <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Make</mat-label>
                                <!-- <input matInput placeholder="Make" [disabled]="LeftDisable" formControlName="leftMake" /> -->
                                <!-- <mat-select  #select11 formControlName="leftMake" >
                                  <mat-select-filter *ngIf="select11.focused" [placeholder]="'Filter'" [displayMember]="'model'"
                                  [array]="DeviceList"  (filteredReturn)="filteredListDevice =$event"></mat-select-filter>
                                 
                                  <mat-option *ngFor="let item of filteredListDevice" [value]="item.brandLable" 
                                  (click)="onLeftModelChange($event,item._id)"
                                  >
                                   {{ item.brandLable }}</mat-option>
                                  </mat-select> -->
                                  <input matInput placeholder="Make" formControlName="leftMake" readonly />
                              </mat-form-field>
           
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                            <div class="col-sm-6 col-12 mb-lg-0 mb-2">
                         <div class="form-group">
                          <div class="row">
                            <div class="col-sm-4 mt-2">
                              <h5>Battery :</h5>
                            </div>
                             <div class="col-sm-8">
                              <mat-radio-group [disabled]="LeftDisable" formControlName="leftBattery" class="example-full-width"
                              aria-label="Select Type">
                              <div class="row ">
                                <div class="col-sm-6 pt-1">
                                  <mat-radio-button value="312">312</mat-radio-button>
                                </div>
                                <div class="col-sm-6 pt-1">
                                  <mat-radio-button value="10">10</mat-radio-button>
                                </div>
                                <div class="col-sm-6 pt-1">
                                  <mat-radio-button value="13">13</mat-radio-button>
                                </div>
                              </div>
                            </mat-radio-group>
                            </div>
                          </div>
                         </div>
                            </div>
                            <div class="col-sm-6 col-12">
                               <div class="form-group">
                                 <div class="row">
                                    <div class="col-sm-4 mt-2">
                                    <h5>Style :</h5>
                                  </div>
                                  <div class="col-sm-8">
                                    <mat-radio-group [disabled]="LeftDisable" formControlName="leftStyle" class="example-full-width"
                        aria-label="Select Type">
                        <div class="row">
                          <!-- <div class="col-sm-6 pt-1">
                            <mat-radio-button value="BTE">BTE</mat-radio-button>
                          </div> -->
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="CIC">CIC</mat-radio-button>
                          </div>
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="IIC">IIC</mat-radio-button>
                          </div>
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="ITC">ITC</mat-radio-button>
                          </div>
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="ITE">ITE</mat-radio-button>
                          </div>
                          <!-- <div class="col-sm-6 pt-1">
                            <mat-radio-button value="RIC">RIC</mat-radio-button>
                          </div> -->
                        </div>
                      </mat-radio-group>
                                 </div>
                              </div>
                              </div>
                             </div>
                             <div class="col-sm-6 col-12">
                              <div class="form-group">
                                <div class="row">
                                   <div class="col-sm-4 mt-2">
                                   <h5>Vent :</h5>
                                 </div>
                                 <div class="col-sm-8">
                                  <mat-radio-group [disabled]="LeftDisable" formControlName="leftVent" class="example-full-width"
                                  aria-label="Select Type">
                                  <div class="row">
                                    <div class="col-sm-6 pt-1">
                                      <mat-radio-button value="No Vent">No Vent</mat-radio-button>
                                    </div>
                                    <div class="col-sm-6 pt-1">
                                      <mat-radio-button value="Pressure">Pressure</mat-radio-button>
                                    </div>
                                    <div class="col-sm-6 pt-1">
                                      <mat-radio-button value="1mm">1mm</mat-radio-button>
                                    </div>
                                    <div class="col-sm-6 pt-1">
                                      <mat-radio-button value="2mm">2mm</mat-radio-button>
                                    </div>
                                    <div class="col-sm-6 pt-1">
                                      <mat-radio-button value="IROS">IROS</mat-radio-button>
                                    </div>
          
                                  </div>
                                </mat-radio-group>
                                </div>
                             </div>
                             </div>
                            </div>
                            <div class="col-sm-6 col-12">
                              <div class="form-group">
                                <div class="row">
                                   <div class="col-sm-4 mt-2">
                                   <h5>Removal String:</h5>
                                 </div>
                                 <div class="col-sm-8">
                                  <mat-radio-group [disabled]="LeftDisable" formControlName="leftRemovalString"
                        class="example-full-width" aria-label="Select Type">
                        <div class="row">
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="Yes">Yes</mat-radio-button>
                          </div>
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="No">No</mat-radio-button>
                          </div>

                        </div>
                      </mat-radio-group>
                                </div>
                             </div>
                             </div>
                            </div>
                            <div class="col-sm-6 col-12">
                              <div class="form-group">
                                <div class="row">
                                   <div class="col-sm-4">
                                   <h5 class="mt-4">Matrix :</h5>
                                 </div>
                                 <div class="col-sm-8">
                                  <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>Matrix</mat-label>
                                    <input matInput placeholder="Matrix" [disabled]="LeftDisable" formControlName="leftMatrix" />
            
                                  </mat-form-field> 
                                </div>
                             </div>
                             </div>
                            </div>

                            <div class="col-sm-6 col-12">
                              <div class="form-group">
                                <div class="row">
                                   <div class="col-sm-4">
                                   <h5 class="mt-4">Shell :</h5>
                                 </div>
                                 <div class="col-sm-8">
                                  <div class="row">
                                    <div class="col-sm-10">
                                  <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>Shell</mat-label>
                                    <!-- <input matInput placeholder="Shell" [disabled]="LeftDisable" formControlName="leftShell" /> -->
                                    <mat-select #select12 (click)="fetchShellList();" formControlName="leftShell">
                                      <mat-select-filter *ngIf="select12.focused" [placeholder]="'Filter'"
                                        [displayMember]="'leftshellName'" [array]="shellList"
                                        (filteredReturn)="filteredListshell =$event"></mat-select-filter>
                                      <mat-option *ngFor="let item of filteredListshell" [value]="item._id"
                                        (click)="shellClick(item._id,item.shell);">
                                        {{ item.shell }}</mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                </div>
                                <div class="col-sm-2 mt-2">
                                  <i (click)="openShellPopUpLeft();" matTooltip="Add shell" class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"> </i>
                                </div>
                                </div>
                                </div>
                             </div>
                             </div>
                            </div>

                            <div class="col-sm-6 col-12">
                              <div class="form-group">
                                <div class="row">
                                   <div class="col-sm-4">
                                   <h5 class="mt-4">Faceplate :</h5>
                                 </div>
                                 <div class="col-sm-8">
                                  <div class="row">
                                    <div class="col-sm-10">
                                  <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>Faceplate</mat-label>
                                    <!-- <input matInput placeholder="Faceplate" [disabled]="LeftDisable"
                                      formControlName="leftFacePlate" /> -->
                                      <mat-select #select11 (click)="fetchFaceplateList();" formControlName="leftFacePlate">
                                        <mat-select-filter *ngIf="select11.focused" [placeholder]="'Filter'"
                                          [displayMember]="'leftfaceplateName'" [array]="faceplateList"
                                          (filteredReturn)="filteredListfaceplate =$event"></mat-select-filter>
                                        <mat-option *ngFor="let item of filteredListfaceplate" [value]="item.faceplate"
                                      >
                                          {{ item.faceplate }}</mat-option>
                                      </mat-select>
                                  </mat-form-field>
                                </div>
                                <div class="col-sm-2 mt-2">
                                  <i (click)="openFaceplatePopUpLeft();" matTooltip="Add Faceplate" class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"> </i>
                    
                                </div>
                                </div>
                             </div>
                             </div>
                            </div>
                            </div>

                            <div class="col-sm-6 col-12">
                              <div class="form-group">
                                <div class="row mt-4">
                                   <div class="col-sm-4">
                                   <h5>Program Switch :</h5>
                                 </div>
                                 <div class="col-sm-8">
                                  <mat-radio-group [disabled]="LeftDisable" formControlName="leftProgramSwitch"
                        class="example-full-width" aria-label="Select Type">
                        <div class="row">
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="Yes">Yes</mat-radio-button>
                          </div>
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="No">No</mat-radio-button>
                          </div>

                        </div>
                      </mat-radio-group>
                                </div>
                             </div>
                             </div>
                            </div>
                        
                            <div class="col-sm-6 col-12">
                              <div class="form-group">
                                <div class="row">
                                   <div class="col-sm-4">
                                   <h5>Volume Control :</h5>
                                 </div>
                                 <div class="col-sm-8">
                                  <mat-radio-group [disabled]="LeftDisable" formControlName="leftVolumeControl"
                        class="example-full-width" aria-label="Select Type">
                        <div class="row">
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="Yes">Yes</mat-radio-button>
                          </div>
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="No">No</mat-radio-button>
                          </div>

                        </div>
                      </mat-radio-group>
                                </div>
                             </div>
                             </div>
                            </div>

                            </div> 
                        </div>  
                      </div> 
              </div> 


              <div class="col-sm-6  mb-2 pl-0 pr-2 pt-0 pb-0">
                <div class="grey-title-card card p-0 mb-4">
                  <div class="grey-card-header bg-grey p-3 d-flex justify-content-between align-items-center">
                           <h5>Right</h5>
                           <div class="right d-flex align-items-center">
                              <span class="side-mark right-mark">RIGHT</span>
                              <span class="material-symbols-outlined pointer" (click)="rightReset()">restart_alt</span>
                              <span class="material-symbols-outlined font-20 pointer"  (click)="copyToLeft()">file_copy</span>
                           </div>
                  </div>
                  <div class="card=content p-4">
                    <div class="row">
                      <div class="col-sm-6 col-12 mb-lg-0 mb-2">
                        <div class="form-group">
                          <div class="row">
                            <div class="col-sm-4">
                              <h5 class="mt-4">Model :</h5>
                            </div>
                            <div class="col-sm-8">
                              <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Model</mat-label>
                                <mat-select  #select1 formControlName="rightModel" >
                                  <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'model'"
                                  [array]="DeviceList"  (filteredReturn)="filteredListDevice =$event"></mat-select-filter>
                                 
                                  <mat-option *ngFor="let item of filteredListDevice" [value]="item.model" 
                                  (click)="onRightModelChange($event,item._id,item.brandLable)"
                                  >
                                   {{ item.model }}</mat-option>
                                  </mat-select>
                              </mat-form-field>
                              <mat-error class="margin_tp"
                                *ngIf="customHaForm.controls['rightModel'].touched && customHaForm.controls['rightModel'].errors?.required">
                                This field is mandatory.
                              </mat-error>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6 col-12">
                        <div class="form-group">
                          <div class="row">
                            <div class="col-sm-4">
                              <h5 class="mt-4">Make :</h5>
                            </div>
                            <div class="col-sm-8">
                              <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Make</mat-label>
                                <!-- <input matInput placeholder="Make" [disabled]="LeftDisable" formControlName="rightMake" /> -->
                                <!-- <mat-select  #select11 formControlName="rightMake" >
                                  <mat-select-filter *ngIf="select11.focused" [placeholder]="'Filter'" [displayMember]="'model'"
                                  [array]="DeviceList"  (filteredReturn)="filteredListDevice =$event"></mat-select-filter>
                                 
                                  <mat-option *ngFor="let item of filteredListDevice" [value]="item.brandLable" 
                                  (click)="onRightModelChange($event,item._id)"
                                  >
                                   {{ item.brandLable }}</mat-option>
                                  </mat-select> -->
                                  <input matInput placeholder="Make" formControlName="rightMake" readonly />
                              </mat-form-field>
           
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                            <div class="col-sm-6 col-12 mb-lg-0 mb-2">
                         <div class="form-group">
                          <div class="row">
                            <div class="col-sm-4 mt-2">
                              <h5>Battery :</h5>
                            </div>
                             <div class="col-sm-8">
                              <mat-radio-group [disabled]="RightDisable" formControlName="rightBattery" class="example-full-width"
                              aria-label="Select Type">
                              <div class="row ">
                                <div class="col-sm-6 pt-1">
                                  <mat-radio-button value="312">312</mat-radio-button>
                                </div>
                                <div class="col-sm-6 pt-1">
                                  <mat-radio-button value="10">10</mat-radio-button>
                                </div>
                                <div class="col-sm-6 pt-1">
                                  <mat-radio-button value="13">13</mat-radio-button>
                                </div>
                              </div>
                            </mat-radio-group>
                            </div>
                          </div>
                         </div>
                            </div>
                            <div class="col-sm-6 col-12">
                               <div class="form-group">
                                 <div class="row">
                                    <div class="col-sm-4 mt-2">
                                    <h5>Style :</h5>
                                  </div>
                                  <div class="col-sm-8">
                                    <mat-radio-group [disabled]="RightDisable" formControlName="rightStyle" class="example-full-width"
                        aria-label="Select Type">
                        <div class="row">
                          <!-- <div class="col-sm-6 pt-1">
                            <mat-radio-button value="BTE">BTE</mat-radio-button>
                          </div> -->
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="CIC">CIC</mat-radio-button>
                          </div>
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="IIC">IIC</mat-radio-button>
                          </div>
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="ITC">ITC</mat-radio-button>
                          </div>
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="ITE">ITE</mat-radio-button>
                          </div>
                          <!-- <div class="col-sm-6 pt-1">
                            <mat-radio-button value="RIC">RIC</mat-radio-button>
                          </div> -->
                        </div>
                      </mat-radio-group>
                                 </div>
                              </div>
                              </div>
                             </div>
                             <div class="col-sm-6 col-12">
                              <div class="form-group">
                                <div class="row">
                                   <div class="col-sm-4 mt-2">
                                   <h5>Vent :</h5>
                                 </div>
                                 <div class="col-sm-8">
                                  <mat-radio-group [disabled]="RightDisable" formControlName="rightVent" class="example-full-width"
                                  aria-label="Select Type">
                                  <div class="row">
                                    <div class="col-sm-6 pt-1">
                                      <mat-radio-button value="No Vent">No Vent</mat-radio-button>
                                    </div>
                                    <div class="col-sm-6 pt-1">
                                      <mat-radio-button value="Pressure">Pressure</mat-radio-button>
                                    </div>
                                    <div class="col-sm-6 pt-1">
                                      <mat-radio-button value="1mm">1mm</mat-radio-button>
                                    </div>
                                    <div class="col-sm-6 pt-1">
                                      <mat-radio-button value="2mm">2mm</mat-radio-button>
                                    </div>
                                    <div class="col-sm-6 pt-1">
                                      <mat-radio-button value="IROS">IROS</mat-radio-button>
                                    </div>
          
                                  </div>
                                </mat-radio-group>
                                </div>
                             </div>
                             </div>
                            </div>
                            <div class="col-sm-6 col-12">
                              <div class="form-group">
                                <div class="row">
                                   <div class="col-sm-4 mt-2">
                                   <h5>Removal String:</h5>
                                 </div>
                                 <div class="col-sm-8">
                                  <mat-radio-group [disabled]="RightDisable" formControlName="rightRemovalString"
                        class="example-full-width" aria-label="Select Type">
                        <div class="row">
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="Yes">Yes</mat-radio-button>
                          </div>
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="No">No</mat-radio-button>
                          </div>

                        </div>
                      </mat-radio-group>
                                </div>
                             </div>
                             </div>
                            </div>
                            <div class="col-sm-6 col-12">
                              <div class="form-group">
                                <div class="row">
                                   <div class="col-sm-4">
                                   <h5 class="mt-4">Matrix :</h5>
                                 </div>
                                 <div class="col-sm-8">
                                  <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>Matrix</mat-label>
                                    <input matInput placeholder="Matrix" [disabled]="RightDisable" formControlName="rightMatrix" />
            
                                  </mat-form-field> 
                                </div>
                             </div>
                             </div>
                            </div>

                            <div class="col-sm-6 col-12">
                              <div class="form-group">
                                <div class="row">
                                   <div class="col-sm-4">
                                   <h5 class="mt-4">Shell :</h5>
                                 </div>
                                 <div class="col-sm-8">
                                  <div class="row">
                                    <div class="col-sm-10">
                                  <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>Shell</mat-label>
                                    <!-- <input matInput placeholder="Shell" [disabled]="LeftDisable" formControlName="leftShell" /> -->
                                    <mat-select #select12 (click)="fetchShellList();" formControlName="rightShell">
                                      <mat-select-filter *ngIf="select12.focused" [placeholder]="'Filter'"
                                        [displayMember]="'rightshellName'" [array]="shellList"
                                        (filteredReturn)="filteredListshell =$event"></mat-select-filter>
                                      <mat-option *ngFor="let item of filteredListshell" [value]="item._id"
                                        (click)="shellClick(item._id,item.shell);">
                                        {{ item.shell }}</mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                </div>
                                <div class="col-sm-2 mt-2">
                                  <i (click)="openShellPopUpRight();" matTooltip="Add shell" class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"> </i>
                                </div>
                             </div>
                             </div>
                             </div>
                             </div>
                            </div>

                            <div class="col-sm-6 col-12">
                              <div class="form-group">
                                <div class="row">
                                   <div class="col-sm-4">
                                   <h5 class="mt-4">Faceplate :</h5>
                                 </div>
                                 <div class="col-sm-8">
                                  <div class="row">
                                    <div class="col-sm-10">
                                  <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>Faceplate</mat-label>
                                    <!-- <input matInput placeholder="Faceplate" [disabled]="LeftDisable"
                                      formControlName="leftFacePlate" /> -->
                                      <mat-select #select11 (click)="fetchFaceplateList();" formControlName="rightFacePlate">
                                        <mat-select-filter *ngIf="select11.focused" [placeholder]="'Filter'"
                                          [displayMember]="'rightfaceplateName'" [array]="faceplateList"
                                          (filteredReturn)="filteredListfaceplate =$event"></mat-select-filter>
                                        <mat-option *ngFor="let item of filteredListfaceplate" [value]="item.faceplate"
                                     >
                                          {{ item.faceplate }}</mat-option>
                                      </mat-select>
                                  </mat-form-field>
                                </div>
                                <div class="col-sm-2 mt-2">
                                  <i (click)="openFaceplatePopUpRight();" matTooltip="Add Faceplate" class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"> </i>
                                </div>
                                </div>
                             </div>
                             </div>
                            </div>
                            </div>

                            <div class="col-sm-6 col-12">
                              <div class="form-group">
                                <div class="row mt-4">
                                   <div class="col-sm-4">
                                   <h5>Program Switch :</h5>
                                 </div>
                                 <div class="col-sm-8">
                                  <mat-radio-group [disabled]="RightDisable" formControlName="rightProgramSwitch"
                        class="example-full-width" aria-label="Select Type">
                        <div class="row">
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="Yes">Yes</mat-radio-button>
                          </div>
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="No">No</mat-radio-button>
                          </div>

                        </div>
                      </mat-radio-group>
                                </div>
                             </div>
                             </div>
                            </div>
                        
                            <div class="col-sm-6 col-12">
                              <div class="form-group">
                                <div class="row">
                                   <div class="col-sm-4">
                                   <h5>Volume Control :</h5>
                                 </div>
                                 <div class="col-sm-8">
                                  <mat-radio-group [disabled]="RightDisable" formControlName="rightVolumeControl"
                        class="example-full-width" aria-label="Select Type">
                        <div class="row">
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="Yes">Yes</mat-radio-button>
                          </div>
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="No">No</mat-radio-button>
                          </div>

                        </div>
                      </mat-radio-group>
                                </div>
                             </div>
                             </div>
                            </div>

                            </div> 
                        </div>  
                      </div> 
              </div> 

              <div class="card divbody borderradius p-3 mb-3 pb-0">
                <div class="col-sm-12">
                  <div class="row mt-2">

                    <div class="col-sm-12 ">
                      <h5 class="mb-1">Audiogram :</h5> 
                    </div>
                    <div class="col-sm-12 table-responsive">
                      <table 
                   class="table table-bordered data-table-custom">
                        <thead>
                          <tr class="text-center">
                            <th scope="col"></th>
                            <th scope="col">250 Hz</th>
                            <th scope="col">500 Hz</th>
                            <th scope="col">1 KHz</th>
                            <th scope="col">2 KHz</th>
                            <th scope="col">4 KHz</th>
                            <th scope="col">6 KHz</th>
                            <th scope="col">8 KHz</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row"><h5 class="mt-3">Right</h5></th>
                            <td class="text-center">

                              <mat-form-field class="widthh example-full-width" appearance="outline">
                                <mat-label>dB</mat-label>
                                <input maxlength="3" matInput placeholder="dB" formControlName="right250HZ" />

                              </mat-form-field>
                              <mat-error class="margin_tp"
                                *ngIf="customHaForm.controls['right250HZ'].touched && customHaForm.controls['right250HZ'].errors?.pattern">
                                Enter Data in Multiplies of 5
                              </mat-error>
                              <mat-error class="margin_tp"
                                *ngIf="customHaForm.controls['right250HZ'].touched && customHaForm.controls['right250HZ'].errors?.min">
                                Enter Minimum -15dB.
                              </mat-error>
                              <mat-error class="margin_tp"
                                *ngIf="customHaForm.controls['right250HZ'].touched && customHaForm.controls['right250HZ'].errors?.max">
                                Enter Maximum 120dB.
                              </mat-error>
                            </td>
                            <td class="text-center">
                              <mat-form-field class="widthh example-full-width" appearance="outline">
                                <mat-label>dB</mat-label>
                                <input matInput placeholder="dB" formControlName="right500HZ" />

                              </mat-form-field>
                              <mat-error class="margin_tp"
                                *ngIf="customHaForm.controls['right500HZ'].touched && customHaForm.controls['right500HZ'].errors?.pattern">
                                Enter Data in Multiplies of 5
                              </mat-error>
                              <mat-error class="margin_tp"
                                *ngIf="customHaForm.controls['right500HZ'].touched && customHaForm.controls['right500HZ'].errors?.min">
                                Enter Minimum -15dB.
                              </mat-error>
                              <mat-error class="margin_tp"
                                *ngIf="customHaForm.controls['right500HZ'].touched && customHaForm.controls['right500HZ'].errors?.max">
                                Enter Maximum 120dB.
                              </mat-error>
                            </td>
                            <td class="text-center">
                              <mat-form-field class="widthh example-full-width" appearance="outline">
                                <mat-label>dB</mat-label>
                                <input matInput placeholder="dB" formControlName="right1KHZ" />

                              </mat-form-field>
                              <mat-error class="margin_tp"
                                *ngIf="customHaForm.controls['right1KHZ'].touched && customHaForm.controls['right1KHZ'].errors?.pattern">
                                Enter Data in Multiplies of 5
                              </mat-error>
                              <mat-error class="margin_tp"
                                *ngIf="customHaForm.controls['right1KHZ'].touched && customHaForm.controls['right1KHZ'].errors?.min">
                                Enter Minimum -15dB.
                              </mat-error>
                              <mat-error class="margin_tp"
                                *ngIf="customHaForm.controls['right1KHZ'].touched && customHaForm.controls['right1KHZ'].errors?.max">
                                Enter Maximum 120dB.
                              </mat-error>
                            </td>
                            <td class="text-center">
                              <mat-form-field class="widthh example-full-width" appearance="outline">
                                <mat-label>dB</mat-label>
                                <input matInput placeholder="dB" formControlName="right3KHZ" />

                              </mat-form-field>
                              <mat-error class="margin_tp"
                                *ngIf="customHaForm.controls['right3KHZ'].touched && customHaForm.controls['right3KHZ'].errors?.pattern">
                                Enter Data in Multiplies of 5
                              </mat-error>
                              <mat-error class="margin_tp"
                                *ngIf="customHaForm.controls['right3KHZ'].touched && customHaForm.controls['right3KHZ'].errors?.min">
                                Enter Minimum -15dB.
                              </mat-error>
                              <mat-error class="margin_tp"
                                *ngIf="customHaForm.controls['right3KHZ'].touched && customHaForm.controls['right3KHZ'].errors?.max">
                                Enter Maximum 120dB.
                              </mat-error>
                            </td>
                            <td class="text-center">
                              <mat-form-field class="widthh example-full-width" appearance="outline">
                                <mat-label>dB</mat-label>
                                <input matInput placeholder="dB" formControlName="right4KHZ" />

                              </mat-form-field>
                              <mat-error class="margin_tp"
                                *ngIf="customHaForm.controls['right4KHZ'].touched && customHaForm.controls['right4KHZ'].errors?.pattern">
                                Enter Data in Multiplies of 5
                              </mat-error>
                              <mat-error class="margin_tp"
                                *ngIf="customHaForm.controls['right4KHZ'].touched && customHaForm.controls['right4KHZ'].errors?.min">
                                Enter Minimum -15dB.
                              </mat-error>
                              <mat-error class="margin_tp"
                                *ngIf="customHaForm.controls['right4KHZ'].touched && customHaForm.controls['right4KHZ'].errors?.max">
                                Enter Maximum 120dB.
                              </mat-error>
                            </td>
                            <td class="text-center">
                              <mat-form-field class="widthh example-full-width" appearance="outline">
                                <mat-label>dB</mat-label>
                                <input matInput placeholder="dB" formControlName="right6KHZ" />

                              </mat-form-field>
                              <mat-error class="margin_tp"
                                *ngIf="customHaForm.controls['right6KHZ'].touched && customHaForm.controls['right6KHZ'].errors?.pattern">
                                Enter Data in Multiplies of 5
                              </mat-error>
                              <mat-error class="margin_tp"
                                *ngIf="customHaForm.controls['right6KHZ'].touched && customHaForm.controls['right6KHZ'].errors?.min">
                                Enter Minimum -15dB.
                              </mat-error>
                              <mat-error class="margin_tp"
                                *ngIf="customHaForm.controls['right6KHZ'].touched && customHaForm.controls['right6KHZ'].errors?.max">
                                Enter Maximum 120dB.
                              </mat-error>
                            </td>
                            <td class="text-center">
                              <mat-form-field class="widthh example-full-width" appearance="outline">
                                <mat-label>dB</mat-label>
                                <input matInput placeholder="dB" formControlName="right8KHZ" />

                              </mat-form-field>
                              <mat-error class="margin_tp"
                                *ngIf="customHaForm.controls['right8KHZ'].touched && customHaForm.controls['right8KHZ'].errors?.pattern">
                                Enter Data in Multiplies of 5
                              </mat-error>
                              <mat-error class="margin_tp"
                                *ngIf="customHaForm.controls['right8KHZ'].touched && customHaForm.controls['right8KHZ'].errors?.min">
                                Enter Minimum -15dB.
                              </mat-error>
                              <mat-error class="margin_tp"
                                *ngIf="customHaForm.controls['right8KHZ'].touched && customHaForm.controls['right8KHZ'].errors?.max">
                                Enter Maximum 120dB.
                              </mat-error>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row"><h5 class="mt-3">Left</h5></th>
                            <td class="text-center">
                              <mat-form-field class="widthh example-full-width" appearance="outline">
                                <mat-label>dB</mat-label>
                                <input matInput placeholder="dB" formControlName="left250HZ" />

                              </mat-form-field>
                              <mat-error class="margin_tp"
                                *ngIf="customHaForm.controls['left250HZ'].touched && customHaForm.controls['left250HZ'].errors?.pattern">
                                Enter Data in Multiplies of 5
                              </mat-error>
                              <mat-error class="margin_tp"
                                *ngIf="customHaForm.controls['left250HZ'].touched && customHaForm.controls['left250HZ'].errors?.min">
                                Enter Minimum -15dB.
                              </mat-error>
                              <mat-error class="margin_tp"
                                *ngIf="customHaForm.controls['left250HZ'].touched && customHaForm.controls['left250HZ'].errors?.max">
                                Enter Maximum 120dB.
                              </mat-error>
                            </td>
                            <td class="text-center">
                              <mat-form-field class="widthh example-full-width" appearance="outline">
                                <mat-label>dB</mat-label>
                                <input matInput placeholder="dB" formControlName="left500HZ" />

                              </mat-form-field>
                              <mat-error class="margin_tp"
                                *ngIf="customHaForm.controls['left500HZ'].touched && customHaForm.controls['left500HZ'].errors?.pattern">
                                Enter Data in Multiplies of 5
                              </mat-error>
                              <mat-error class="margin_tp"
                                *ngIf="customHaForm.controls['left500HZ'].touched && customHaForm.controls['left500HZ'].errors?.min">
                                Enter Minimum -15dB.
                              </mat-error>
                              <mat-error class="margin_tp"
                                *ngIf="customHaForm.controls['left500HZ'].touched && customHaForm.controls['left500HZ'].errors?.max">
                                Enter Maximum 120dB.
                              </mat-error>
                            </td>
                            <td class="text-center">
                              <mat-form-field class="widthh example-full-width" appearance="outline">
                                <mat-label>dB</mat-label>
                                <input matInput placeholder="dB" formControlName="left1KHZ" />

                              </mat-form-field>
                              <mat-error class="margin_tp"
                                *ngIf="customHaForm.controls['left1KHZ'].touched && customHaForm.controls['left1KHZ'].errors?.pattern">
                                Enter Data in Multiplies of 5
                              </mat-error>
                              <mat-error class="margin_tp"
                                *ngIf="customHaForm.controls['left1KHZ'].touched && customHaForm.controls['left1KHZ'].errors?.min">
                                Enter Minimum -15dB.
                              </mat-error>
                              <mat-error class="margin_tp"
                                *ngIf="customHaForm.controls['left1KHZ'].touched && customHaForm.controls['left1KHZ'].errors?.max">
                                Enter Maximum 120dB.
                              </mat-error>
                            </td>
                            <td class="text-center">
                              <mat-form-field class="widthh example-full-width" appearance="outline">
                                <mat-label>dB</mat-label>
                                <input matInput placeholder="dB" formControlName="left3KHZ" />

                              </mat-form-field>
                              <mat-error class="margin_tp"
                                *ngIf="customHaForm.controls['left3KHZ'].touched && customHaForm.controls['left3KHZ'].errors?.pattern">
                                Enter Data in Multiplies of 5
                              </mat-error>
                              <mat-error class="margin_tp"
                                *ngIf="customHaForm.controls['left3KHZ'].touched && customHaForm.controls['left3KHZ'].errors?.min">
                                Enter Minimum -15dB.
                              </mat-error>
                              <mat-error class="margin_tp"
                                *ngIf="customHaForm.controls['left3KHZ'].touched && customHaForm.controls['left3KHZ'].errors?.max">
                                Enter Maximum 120dB.
                              </mat-error>
                            </td>
                            <td class="text-center">
                              <mat-form-field class="widthh example-full-width" appearance="outline">
                                <mat-label>dB</mat-label>
                                <input matInput placeholder="dB" formControlName="left4KHZ" />

                              </mat-form-field>
                              <mat-error class="margin_tp"
                                *ngIf="customHaForm.controls['left4KHZ'].touched && customHaForm.controls['left4KHZ'].errors?.pattern">
                                Enter Data in Multiplies of 5
                              </mat-error>
                              <mat-error class="margin_tp"
                                *ngIf="customHaForm.controls['left4KHZ'].touched && customHaForm.controls['left4KHZ'].errors?.min">
                                Enter Minimum -15dB.
                              </mat-error>
                              <mat-error class="margin_tp"
                                *ngIf="customHaForm.controls['left4KHZ'].touched && customHaForm.controls['left4KHZ'].errors?.max">
                                Enter Maximum 120dB.
                              </mat-error>
                            </td>
                            <td class="text-center">
                              <mat-form-field class="widthh example-full-width" appearance="outline">
                                <mat-label>dB</mat-label>
                                <input matInput placeholder="dB" formControlName="left6KHZ" />

                              </mat-form-field>
                              <mat-error class="margin_tp"
                                *ngIf="customHaForm.controls['left6KHZ'].touched && customHaForm.controls['left6KHZ'].errors?.pattern">
                                Enter Data in Multiplies of 5
                              </mat-error>
                              <mat-error class="margin_tp"
                                *ngIf="customHaForm.controls['left6KHZ'].touched && customHaForm.controls['left6KHZ'].errors?.min">
                                Enter Minimum -15dB.
                              </mat-error>
                              <mat-error class="margin_tp"
                                *ngIf="customHaForm.controls['left6KHZ'].touched && customHaForm.controls['left6KHZ'].errors?.max">
                                Enter Maximum 120dB.
                              </mat-error>
                            </td>
                            <td class="text-center">
                              <mat-form-field class="widthh example-full-width" appearance="outline">
                                <mat-label>dB</mat-label>
                                <input matInput placeholder="dB" formControlName="left8KHZ" />

                              </mat-form-field>
                              <mat-error class="margin_tp"
                                *ngIf="customHaForm.controls['left8KHZ'].touched && customHaForm.controls['left8KHZ'].errors?.pattern">
                                Enter Data in Multiplies of 5
                              </mat-error>
                              <mat-error class="margin_tp"
                                *ngIf="customHaForm.controls['left8KHZ'].touched && customHaForm.controls['left8KHZ'].errors?.min">
                                Enter Minimum -15dB.
                              </mat-error>
                              <mat-error class="margin_tp"
                                *ngIf="customHaForm.controls['left8KHZ'].touched && customHaForm.controls['left8KHZ'].errors?.max">
                                Enter Maximum 120dB.
                              </mat-error>
                            </td>
                          </tr>

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card divbody borderradius col-sm-12 mb-2 pl-4 pr-4 p-1">
                <div class="row mt-2">

                  <div class="col-sm-6 ">
                    <h5 class="mt-2" >Enclosures :</h5>
                  </div>
                  <div class="col-sm-6 mt-3">
                    <h5 >Bill To Company :</h5>
                  </div>
                  <div class="col-sm-2 mt-2">
                    <mat-checkbox class="example-full-width " formControlName="audiogram">
                      Audiogram
                    </mat-checkbox>
                  </div> 
                  <div class="col-sm-2" *ngIf="hide == false">
                    <input #fileUpload type="file" style="display: none;" (change)="onChange($event)" />
                      <button type="button" class="btn btn-light shadow btn-sm m-0 example-full-width"
                       matTooltip="Upload JPG,PNG,CSV or PDF image.300 x 300 required." 
                       (click)="fileUpload.click()" ><i class="ai-refresh-cw me-2" ></i>Upload File or Photo</button>
                  </div>
                  <div class="col-sm-2" *ngIf="hide == true">
                    <div class="row ">
                      <a class="col-sm-10" (click)="showbase64(audiogramFile)"> 
                        <label class="blue_col mt-0 pt-0">{{uploadFileName}}</label></a>
                        <i class="fa fa-times col-sm-2 pointer" (click)="remove()" 
                        aria-hidden="true" style="cursor:pointer;" ></i>
                    </div>
                  </div>
                  <div class="col-sm-2"></div>

                  <!-- <div class="col-sm-3" *ngIf="hide == false">
                    <input  type="file" class="example-full-width " #fileUpload
                     name="Upload File" id="txtFileUpload" placeholder="Upload File"
                    (change)="onChange($event);" />
                </div>
                <div class="col-sm-3 " *ngIf="hide == true">
                  <div class="row">
                    <a class="col-sm-8" (click)="showbase64(audiogramFile)">{{uploadFileName}}</a>
                    <i class="col-sm-4 fa fa-trash-o" (click)="remove()"></i>
                  </div> 
                </div> -->
                  <div *ngIf="billingCreate" class="col-sm-3">
                    <div class="row">
                      <div class="col-sm-10 pr-1">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Billing Company
                            <!-- <span class="required-asterisk">*</span> -->
                          </mat-label>
                          <mat-select #select1 (click)="fetchBillingCompanyList();" formControlName="billingRefId">
                            <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'"
                              [displayMember]="'companyName'" [array]="billingCompanyList"
                              (filteredReturn)="filteredListBillingCompany =$event"></mat-select-filter>
                            <mat-option *ngFor="let item of filteredListBillingCompany" [value]="item._id"
                              (click)="companyClick(item._id,item.companyName);">
                              {{ item.companyName }}</mat-option>
                          </mat-select>

                        </mat-form-field>
                        <mat-error class="margin_tp"
                          *ngIf="customHaForm.controls['billingRefId'].touched && customHaForm.controls['billingRefId'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                      </div>
                      <div class="col-sm-2 mt-2 no-print">
                        <i (click)="openBillingCompPopUp();" matTooltip="Add New Billing Company" class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"> </i>

                      </div>
                    </div>
                  </div>
                  <div *ngIf="!billingCreate" class="col-sm-3">
                    <div class="row">
                      <div class="col-sm-12 pr-1">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Billing Company
                            <!-- <span class="required-asterisk">*</span> -->
                          </mat-label>
                          <mat-select #select1 (click)="fetchBillingCompanyList();" formControlName="billingRefId">
                            <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'"
                              [displayMember]="'companyName'" [array]="billingCompanyList"
                              (filteredReturn)="filteredListBillingCompany =$event"></mat-select-filter>
                            <mat-option *ngFor="let item of filteredListBillingCompany" [value]="item._id"
                              (click)="companyClick(item._id,item.companyName);">
                              {{ item.companyName }}</mat-option>
                          </mat-select>

                        </mat-form-field>
                        <mat-error class="margin_tp"
                          *ngIf="customHaForm.controls['billingRefId'].touched && customHaForm.controls['billingRefId'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                      </div>

                    </div>
                  </div>
                  <!-- <div class="col-sm-6 mt-1">
                                              <mat-checkbox class="example-full-width " formControlName="earImpression" >
                                               Ear Impressions
                                            </mat-checkbox>
                                              </div> -->
                  <div class="col-sm-3">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Ear Impression</mat-label>
                      <input maxlength="10" matInput placeholder="Ear Impressions" formControlName="earImpressionValue" />

                    </mat-form-field>
                    <mat-error class="margin_tp"
                      *ngIf="customHaForm.controls['earImpressionValue'].touched && customHaForm.controls['earImpressionValue'].errors?.pattern">
                      Enter Valid Ear Impression Value
                    </mat-error>
                  </div>


                  <div *ngIf="isSuperAdmin" class="col-sm-12">

                    <div class="row">
                      <div class="col-sm-12  mt-2">
                        <h5 >Select Branch :</h5>
                      </div>
                    </div>
                    <div class="row">

                      <div *ngIf="branchCreate" class="col-sm-3">
                        <div class="row">
                          <div class="col-sm-10 pr-1">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Branch
                                <!-- <span class="required-asterisk">*</span> -->
                              </mat-label>
                              <mat-select #select3 formControlName="branchRefId">
                                <mat-select-filter *ngIf="select3.focused" [placeholder]="'Filter'"
                                  [displayMember]="'branchName'" [array]="BranchList"
                                  (filteredReturn)="filteredListBranch =$event"></mat-select-filter>
                                <mat-option *ngFor="let item of filteredListBranch" (click)="branchClick(item._id,item.branchName)" [value]="item._id"
                             >
                                  {{ item.branchName }}</mat-option>
                              </mat-select>

                            </mat-form-field>
                            <mat-error class="margin_tp"
                              *ngIf="customHaForm.controls['branchRefId'].touched && customHaForm.controls['branchRefId'].errors?.required">
                              This field is mandatory.
                            </mat-error>
                          </div>
                          <div class="col-sm-2 mt-2 no-print">
                            <i (click)="openBranchPopUp();" matTooltip="Add New Branch" class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"> </i>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="!branchCreate" class="col-sm-3">
                        <div class="row">
                          <div class="col-sm-12 pr-1">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Branch
                                <!-- <span class="required-asterisk">*</span> -->
                              </mat-label>
                              <mat-select #select3 formControlName="branchRefId">
                                <mat-select-filter *ngIf="select3.focused" [placeholder]="'Filter'"
                                  [displayMember]="'branchName'" [array]="BranchList"
                                  (filteredReturn)="filteredListBranch =$event"></mat-select-filter>
                                <mat-option *ngFor="let item of filteredListBranch" [value]="item._id"
                                  (click)="branchClick(item._id,item.branchName);">
                                  {{ item.branchName }}</mat-option>
                              </mat-select>

                            </mat-form-field>
                            <mat-error class="margin_tp"
                              *ngIf="customHaForm.controls['branchRefId'].touched && customHaForm.controls['branchRefId'].errors?.required">
                              This field is mandatory.
                            </mat-error>
                          </div>

                        </div>
                      </div>
                      <div class="col-sm-3">
                        <!-- <label class="ml-3 mt-3">Instructions :</label> -->
                      </div>
                      <div class="col-sm-3">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Instructions </mat-label>
                          <!-- <input matInput placeholder="Address" formControlName="streetAddress" readonly /> -->
                          <textarea placeholder="Address" maxlength="1000"
                           matInput formControlName="complaint" cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2" cdkAutosizeMaxRows="5"></textarea>
                        </mat-form-field>
                      </div> 
                    </div>
                  </div>

                  <div class="col-sm-10 no-print" *ngIf="submitButton">
                  </div>
                  <div class="col-sm-6 no-print" *ngIf="updateButton">
                  </div>


                  <div class="col-sm-2 mb-2 mt-2 no-print" *ngIf="submitButton && customHACreate">
                    <button class="btn btn-primary btn-sm example-full-width" type="button"
                      (click)="submitCustomHA()">Save</button>
                  </div>
                  <div *ngIf="updateButton && customHAUpdate" class="col-sm-2 mb-2 mt-2 no-print">
                    <button class="btn btn-primary btn-sm example-full-width" type="button"
                      (click)="printForm();">Generate Pdf</button>
                  </div>
                  <div class="col-sm-2 mb-2 mt-2 no-print" *ngIf="updateButton && customHAUpdate">
                    <button class="btn btn-primary btn-sm   example-full-width" type="button"
                      (click)="updateCustomHADetails()">Update</button>
                  </div>

                  <div class="col-sm-2 mb-2 mt-2 no-print" *ngIf="cancelButton">
                    <button class="btn btn-primary btn-sm example-full-width" (click)="cancelUpdate()"
                      type="reset">Cancel</button>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-12 pl-0 pr-2 no-print">
          <div class="row">
            <div class="card divbody col-sm-12 borderradius p-3 pb-0">
              <div class="col-sm-12">
                <div class="row">
                  <div class="col-sm-9 ">
                    <h2 class="titlecolor"> Custom HA List</h2>
                  </div>
                  <div class="search-box col-sm-3 pb-2">
                    <input class="form-control amber-border" type="text" placeholder="Search" 
                    formControlName="searchCustomHA" aria-label="Search" (keyup)="onKeyUpEvent($event)"
                    >
                    <div class="input-group-append">
                       <span class="input-group-text amber lighten-3" id="basic-text1"><span class="material-symbols-outlined">search</span></span>
                    </div>
                 </div>

                  <div class="col-sm-12 table-responsive">
                    <table id="toCustomHAList"
                 class="table table-bordered data-table-custom">
                      <thead>
                        <tr class="text-center">
                          <th>Custom HA ID</th>
                          <th *ngIf="userType == 'Super Admin'">Branch Name</th>
                          <th>Patient Name</th>
                          <th>HA Company</th>
                          <th>Right</th>
                          <th>Left</th>
                          <th>Ear</th>
                          <th>Bill to Company</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of listofCustomHA | paginate : { id:'CustomeHAMaster',
                                      itemsPerPage: size, currentPage: page, totalItems:totalLength }"
                          (click)="prepopulation(item._id)" (click)="toCustomHAForm()"
                          style="cursor:pointer; text-align: center ">
                          <td matTooltip="click to view">{{item.branchWiseId}}</td>
                          <td *ngIf="userType == 'Super Admin'" matTooltip="click to view">{{item.branchName}}
                          </td>
                          <td matTooltip="click to view">{{item.firstName}} {{item.lastName}}</td>
                          <td matTooltip="click to view">{{item.companyName}}</td>
                          <td matTooltip="click to view">{{item.rightModel}}-{{item.rightMake}}</td>
                          <td matTooltip="click to view">{{item.leftModel}}-{{item.leftMake}}</td>
                          <td matTooltip="click to view">{{item.ear}}</td>
                          <td matTooltip="click to view">{{item.billingCompanyName}}</td>
                          <!-- <td class="text-center">
                                          <i matTooltip="View" (click)="prepopulation(Inquiry._id)" class="fas fa-eye"></i>
                                         </td> -->
                        </tr>


                      </tbody>
                    </table>
                  </div>
                  <div class="col-sm-12 pt-0 text-right">
                    <pagination-controls id="CustomeHAMaster" directionLinks="true" (pageChange)="onPageChange($event)"
                      class="float-right pagination my-pagination"></pagination-controls>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>


      </div>
    </div>
  </form>
</div>