<div class="container-fluid " [formGroup]="courierServiceProviderMasterForm" >
    <form autocomplete="off" [formGroup]="courierServiceProviderMasterForm" novalidate>
      <div class="row">
        
        <div class="col-sm-12">
          <div class="row">
            <div class="col-sm-11">
              <div class="row ">
            <!-- <span><img src="../../assets/truck.png" width="45rem"> </span>  -->
            <span> <h1 class="fass ml-3 pt-1" id="toCourierForm">Add Courier Service Provider</h1></span>
            </div>
            </div>
            <div class="col-sm-1">
              <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                <a (click)="onClose()" matTooltip="Close" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">close</span></a>
                </div>
            </div>
          </div> 
          <div class="row ">
            <div class="col-sm-12 pr-4">
                     <div class="row pl-3 pr-3">
                       <div class="col-sm-12">
                         <div class="row mt-2">
                          <div class="col-sm-12  ">
                            <label class="header">Courier Details :</label>
                              </div>
                         </div>
                         <div class="row">
                          <div class="col-sm-3  ">
                           
                            <mat-form-field *ngIf="userType !== 'Super Admin'" class="example-full-width" appearance="outline">
                              <mat-label>Courier Id</mat-label>
                              <input matInput formControlName="branchWiseId" readonly  />   
                            </mat-form-field>
                            <mat-form-field *ngIf="userType == 'Super Admin'" class="example-full-width" appearance="outline">
                              <mat-label>Courier Id</mat-label>
                              <input matInput [value]="superBranchId" readonly  />
                              </mat-form-field>
                          </div>
                          <div class="col-sm-3  ">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Service Provider Name
                                <!-- <span class="required-asterisk">*</span> -->
                              </mat-label>
                              <input required matInput matTooltip="Service Provider Name" placeholder="Service Provider Name" formControlName="serviceProviderName"  />
                              
                            </mat-form-field>
                            <mat-error class="margin_tp"
                            *ngIf="courierServiceProviderMasterForm.controls['serviceProviderName'].touched && courierServiceProviderMasterForm.controls['serviceProviderName'].errors?.required">
                            This field is mandatory.
                          </mat-error>
                          <mat-error class="margin_tp"
                          *ngIf="courierServiceProviderMasterForm.controls['serviceProviderName'].touched && courierServiceProviderMasterForm.controls['serviceProviderName'].errors?.pattern">
                              Please enter valid Provider Name.
                        </mat-error>
                          </div>
                          <div class="col-sm-3  ">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>GST Number</mat-label>
                              <input formControlName="gstNo"  matInput placeholder="eg.(29ABCDE1234F2Z5)"  />
                             
                              
                            </mat-form-field>
                            <mat-error class="margin_tp"
                            *ngIf="courierServiceProviderMasterForm.controls['gstNo'].touched && courierServiceProviderMasterForm.controls['gstNo'].errors?.pattern">
                                Please enter valid GST number.
                          </mat-error>
                          </div>
                         
                          <div class="col-sm-3  ">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Contact Person Name</mat-label>
                              <input matInput formControlName="contactPerson" placeholder="Name" 
                                 />
                            </mat-form-field>                        
                          </div>
  
                          <div class="col-sm-3  ">
                            <mat-form-field
                              class="example-full-width"
                              appearance="outline"
                            >
                              <mat-label>Customer Care Contact Person Name</mat-label>
                              <input matTooltip="Customer Care Contact Person Name" formControlName="custCareContactPerson"  
                              matInput placeholder="Name" />                                                      
                            </mat-form-field>
                          </div>
  
                         </div>
                       </div>
  
                       <div class="col-sm-12">
                         <div class="row"> 
                         
                          <div class="col-sm-12  ">
                            <label class="header">Address Details :</label>
                              </div>
                         </div>
                         <div class="row">
                          <div class="col-sm-3  ">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Street Address
                                <!-- <span class="required-asterisk">*</span> -->
                              </mat-label>
                              <input  matInput  formControlName="streetAddress" placeholder="streetAddress" 
                                 />
                                 
                            </mat-form-field>
                            <mat-error class="margin_tp"
                            *ngIf="courierServiceProviderMasterForm.controls['streetAddress'].touched && courierServiceProviderMasterForm.controls['streetAddress'].errors?.required">
                            This field is mandatory.
                          </mat-error>
                          </div>
                          <div class="col-sm-3  ">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Taluka</mat-label>
                              <input matInput placeholder="Taluka" formControlName="taluka" />
                              <mat-error class="margin_tp"
                              *ngIf="courierServiceProviderMasterForm.controls['taluka'].touched && courierServiceProviderMasterForm.controls['taluka'].errors?.pattern">
                                  Please enter valid taluka.
                            </mat-error>
                            </mat-form-field>
                          </div>
                          <div class="col-sm-3  ">
                            <!-- <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>District</mat-label>
                              <input matInput placeholder="District" formControlName="district" />
                              <mat-error class="margin_tp"
                              *ngIf="courierServiceProviderMasterForm.controls['district'].touched && courierServiceProviderMasterForm.controls['district'].errors?.pattern">
                                  Please enter valid district.
                            </mat-error>
                            </mat-form-field> -->
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>District</mat-label>
                              <mat-select   #select12 placeholder="District" class="colorblack"  formControlName="district"   >
                                <mat-select-filter  *ngIf="select12.focused" [placeholder]="'Filter'" [displayMember]="'district'"
                                  [array]="districtData2" (filteredReturn)="filteredListDistrict2 =$event"></mat-select-filter>
                                <mat-option *ngFor="let item of filteredListDistrict2" 
                                 [value]="item.district" >
                                  {{item.district}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div class="col-sm-3  ">
                            <!-- <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>State</mat-label>
                              <mat-select #select1 placeholder="State" class="colorblack"  formControlName="state"   >
                                <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'name'"
                                  [array]="allState"  (filteredReturn)="filteredList =$event"></mat-select-filter>
                                <mat-option *ngFor="let item of filteredList" 
                                 [value]="item.value"
                               
                                 >
                                  {{item.name}}
                                </mat-option>
                              </mat-select>
                             
                            </mat-form-field> -->
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>State</mat-label>
                              <mat-select   #select11 placeholder="State" class="colorblack"  formControlName="state"   >
                                <mat-select-filter  *ngIf="select11.focused" [placeholder]="'Filter'" [displayMember]="'state'"
                                  [array]="stateCityArr2"  (filteredReturn)="filteredList2 =$event"></mat-select-filter>
                                <mat-option *ngFor="let item of filteredList2" 
                                 [value]="item.state" (click)="getDistrict2(item.districts)">
                                  {{item.state}}
                                </mat-option>
                              </mat-select>
                            
                            </mat-form-field>
                            <mat-error class="margin_tp"
                            *ngIf="courierServiceProviderMasterForm.controls['state'].touched && courierServiceProviderMasterForm.controls['state'].errors?.required">
                            This field is mandatory.
                          </mat-error>
                          </div>
                          <div class="col-sm-3   ">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label> Pin Code 
                                  <!-- <span class="required-asterisk">*</span> -->
                                </mat-label>
                                <input  formControlName="PinCode" maxlength="6" matInput placeholder=" Pincode(e.g:416008)"  /> 
                               
                              </mat-form-field>
                              <mat-error class="margin_tp"
                              *ngIf="courierServiceProviderMasterForm.controls['PinCode'].touched && courierServiceProviderMasterForm.controls['PinCode'].errors?.pattern">
                                  Please enter valid PinCode.
                            </mat-error>
                            <mat-error class="margin_tp"
                            *ngIf="courierServiceProviderMasterForm.controls['PinCode'].touched && courierServiceProviderMasterForm.controls['PinCode'].errors?.required">
                            This field is mandatory
                          </mat-error>
                              
                          </div>
                          <div class="col-sm-3  ">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Country</mat-label>
                              <input matInput placeholder="Country" formControlName="country" />
                             
                            </mat-form-field>
                            <mat-error class="margin_tp"
                            *ngIf="courierServiceProviderMasterForm.controls['country'].touched && courierServiceProviderMasterForm.controls['country'].errors?.pattern">
                                Please enter valid country.
                          </mat-error>
                          </div>
                        
                         </div>
                       </div>
                     </div>
                      <div class="row pl-2 pr-2">
                        <div class="col-sm-12">
                          <div class="row">
                           
                              <div class="col-sm-12  ">
                                <label class="header">Contact Details :</label>
                                  </div>
                          </div>
                          <div class="row ">
                            <div class="col-sm-3  ">
                              <div class="row">
                                <div class="col-sm-10">
                              <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Service Provider Phone Number
                                  <!-- <span class="required-asterisk">*</span> -->
                                </mat-label>
                                <input required matInput maxlength="10" matTooltip="Service Provider Phone Number" placeholder="Service Provider Phone Number" formControlName="phoneNo"  />
                                                           </mat-form-field>
                                                           <mat-error class="margin_tp"
                                                           *ngIf="courierServiceProviderMasterForm.controls['phoneNo'].touched && courierServiceProviderMasterForm.controls['phoneNo'].errors?.required">
                                                           This field is mandatory.
                                                         </mat-error>
                                                         <mat-error class="margin_tp"
                                                           *ngIf="courierServiceProviderMasterForm.controls['phoneNo'].touched && courierServiceProviderMasterForm.controls['phoneNo'].errors?.pattern">
                                                           Please enter valid number.
                                                         </mat-error>
                             
                            
                            </div>
                            <div class="col-sm-2 wApp">
                              <i class="bi bi-whatsapp whatsapp-icon wApp fa-lg" (click)="WhatsApp();" matTooltip="WhatsApp Integration" ></i>
                                <!-- <img (click)="WhatsApp();" class=" mt-2" matTooltip="WhatsApp Integration" 
                                width="25" src="../../assets/whatsappGreen.png">  -->
                                </div>
                          </div>
                        </div>
                            <div class="col-sm-3  ">
                              <mat-form-field
                                class="example-full-width"
                                appearance="outline"
                              >
                                <mat-label>Customer Care Contact Person number </mat-label>
                                <input matTooltip="Customer Care Contact Person number" maxlength="10" formControlName="custCareContactPersonNo"  matInput placeholder="Customer Care Contact Person number" />
                              
                              </mat-form-field>
                              <mat-error class="margin_tp"
                              *ngIf="courierServiceProviderMasterForm.controls['custCareContactPersonNo'].touched && courierServiceProviderMasterForm.controls['custCareContactPersonNo'].errors?.pattern">
                              Please enter valid number.
                            </mat-error>
                           
                            </div>
                            <div class="col-sm-3   ">
                              <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label> Landline Number
                                </mat-label>
                                <input maxlength="15" formControlName="landline"  matInput
                                placeholder=" Landline eg 0223-666666" />
                              </mat-form-field>
                              <mat-error class="margin_tp"
                                *ngIf="courierServiceProviderMasterForm.controls['landline'].touched && courierServiceProviderMasterForm.controls['landline'].errors?.pattern">
                                Please enter valid Landline Number.
                              </mat-error>
                              
                            </div>
                            <div class="col-sm-3   ">
                              <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label> Toll Free Number
                                  <!-- <span class="required-asterisk">*</span> -->
                                </mat-label>
                                <input maxlength="11" formControlName="tollFreeNo"  matInput
                                  placeholder="Toll Free Number eg-18007676786" />
      
                              </mat-form-field>
                              <mat-error class="margin_tp"
                                *ngIf="courierServiceProviderMasterForm.controls['tollFreeNo'].touched && courierServiceProviderMasterForm.controls['tollFreeNo'].errors?.pattern">
                                Please enter valid Toll Free Number.
                              </mat-error>
                              
      
                            </div>
                          </div>
                        </div>
                            
                        <div *ngIf="isSuperAdmin" class="col-sm-12"> 
                              
                          <div class="row">
                            <div class="col-sm-12  ">
                          <label class="header">Select Branch :</label>
                            </div>
                            </div>
                            <div class="row">
  
                        <div class="col-sm-3">
                          <div class="row">
                            <div class="col-sm-10 pr-1">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Branch 
                              <!-- <span class="required-asterisk">*</span> -->
                            </mat-label>
                            <mat-select required #select2 (click)="fetchBranchList();" formControlName="branchRefId" >
                              <mat-select-filter *ngIf="select2.focused" [placeholder]="'Filter'" [displayMember]="'branchName'"
                                        [array]="BranchList"  (filteredReturn)="filteredListBranch =$event"></mat-select-filter>
                              <mat-option *ngFor="let item of filteredListBranch" [value]="item._id" 
                              (click)="branchClick(item._id,item.branchName);"
                              >
                               {{ item.branchName }}</mat-option>
                              </mat-select>
                              </mat-form-field>
                              <mat-error class="margin_tp"
                              *ngIf="courierServiceProviderMasterForm.controls['branchRefId'].touched && courierServiceProviderMasterForm.controls['branchRefId'].errors?.required">
                              This field is mandatory.
                            </mat-error>
                         </div>
                         <div class="col-sm-2 mt-2 mTop no-print">
                          <i (click)="openBranchPopUp();" matTooltip="Add New Branch" 
                          class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                       
                        </div>
                         </div>
                        </div>
                   </div>
                   </div>
                   
                        <div class="col-sm-12">
                          
                        
                          <div class="row mb-2">
                            <div class="col-sm-10" *ngIf="submitButton"></div>
                            <div class="col-sm-2 mt-2" *ngIf="submitButton">
                              <button *ngIf="courierServiceCreate"  type="button" class="btn btn-primary example-full-width  btn-sm"  (click)="submitCurier()" >Save </button>
                            </div>
                            <!-- <div class="col-sm-8" *ngIf="updateButton"></div> -->
                            <!-- <div class="col-sm-2 mt-2" *ngIf="updateButton">
                              <button *ngIf="courierServiceUpdate" class="btn btn-primary example-full-width  btn-sm"  (click)="updateCourierDetails()">Update </button>
                            </div>
                            <div class="col-sm-2 mt-2" *ngIf="updateButton">
                              <button class="btn btn-primary btn-sm  example-full-width"  (click)="cancelUpdate()" >Cancel </button>
                            </div> -->
                          </div>
                        </div>
                      </div>
            </div> 
          </div>
    </div>
      </div>
      </form>
    </div>
    