<div class="container-fluid " [formGroup]="patientMasterForm">
    <form [formGroup]="patientMasterForm" autocomplete="off" novalidate>
    <div class="row">
   
      <div class="col-sm-12">
         
        <div class="row pt-1">
          <div class="col-sm-12 pb-2">
            <!-- <div class="row">
              <div class="col-sm-9">
                <div class="row ">
                  
                  <span><img src="../../assets/patient.png" width="35rem"> </span> 
                  <span><h1 class="fass ml-3 pt-1" id="toPatientForm">Patient</h1></span>
                </div>
                  </div> 
                 
                  <div class="col-sm-3" >
                   
                    <div class="row justify-content-end ">
                      <img matTooltip="Back" class="mb-2 pb-2 mr-2" width=35rem src="../../assets/back.png" (click)="onback()">
                      <img matTooltip="Control Panel" (click)="controlClick()" class="mb-2 pb-2 mr-2" width="35rem" [routerLink]="['/home/generic-setting']"  src="../../assets/general settings.png">  
                    <img src="../../assets/list.png"  (click)="toPatientList()" class="mb-2 pb-2" matTooltip="List" width="35rem">
                   </div>
                   </div>
                   
                
            </div> -->
  
              <div class="row margingTop pb-2">
                  <div class="card divbody boxsssss borderradius pr-3 pb-2 p-1 " >
                    <div class="row"> 
                      
                      <!-- <h1 class="titlecolor"> Patient Master</h1> -->
                      <div class="col-sm-10"></div>
                      <div class="col-sm-2 mt-1">
                        <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                          <a (click)="redirectToPatientForm()" matTooltip="Edit" class="d-flex align-items-center pr-2 mt-2">
                            <span class="material-symbols-outlined pr-2">edit</span></a>
                       
                     </div>
</div>
                      <div class="col-sm-2">
                        <!-- <h1 class="titlecolor"> Patient Master</h1> -->
                            <div class="col-sm-12 bg-secondary rounded-3 p-1 pb-4 mb-4">
                             
                                 
                              <div class="col-sm-12 ">
                                <img *ngIf="!patientImage" class=" img2 frame  " src="../../assets/user2.png" alt="Amanda Wilson" width="500rem"><!--rounded-circle-->
                                <img *ngIf="patientImage" class=" img2 frame  " [src]="patientImage" alt="patient photo" width="500rem"><!--rounded-circle-->
                                <!-- <button class="btn btn-light shadow btn-sm mb-2" type="button"><i class="ai-refresh-cw me-2"></i>Change avatar</button> -->
                                 
                            </div>
                            <div class="col-sm-12 btnpossition "  >
                              <!-- <h1 class="fass p-0 m-0"> Patient Master</h1> -->
                              <input readonly #fileUpload type="file" style="display: none;" (change)="onChange($event)" />
                           
                              
                            </div>
                                
                              </div>
                        </div>
                      
                      <div class="col-sm-10">
                        <div class="row pt-1 pr-1"> 
                          <div class=" col-sm-3 ">
                            <div class="row">
                              <div class=" col-sm-6 ">
                            <!-- <mat-form-field *ngIf="userType !== 'Super Admin'" class="example-full-width" appearance="outline">
                              <mat-label>Patient Id</mat-label>
                              <input readonly matInput formControlName="branchWiseId" readonly  />   
                            </mat-form-field> -->
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Patient Id</mat-label>
                              <input readonly matInput [value]="superBranchId" readonly  />
                             
                            </mat-form-field>
                            </div>
                            <div class=" col-sm-6 ">
                            <mat-form-field class="example-full-width " appearance="outline">
                              <mat-label>PreFix</mat-label>
                              <input readonly matInput placeholder="First Name"  formControlName="prefix"  />
                        
                            </mat-form-field>
                        
                            </div>
                            </div>
                          </div>
                       
                           <div class="col-sm-3">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>First Name
                                <!-- <span class="required-asterisk">*</span> -->
                              </mat-label>
                              <input readonly matInput placeholder="First Name"  formControlName="firstName" required />
                          
                            </mat-form-field>
                            <mat-error 
                            *ngIf="patientMasterForm.controls['firstName'].touched && patientMasterForm.controls['firstName'].errors?.required">
                                This field is mandatory.
                          </mat-error>
                          <mat-error class="margin_tp"
                            *ngIf="patientMasterForm.controls['firstName'].touched && patientMasterForm.controls['firstName'].errors?.pattern">
                                Please enter valid first name.
                          </mat-error>
                          </div>
                          <div *ngIf="fieldsOfPatients.patientMiddleName !== 'No'" class=" col-sm-3   ">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Middle Name</mat-label>
                              <input readonly matInput placeholder="Middle Name" formControlName="middleName" />
                             
                            </mat-form-field>
                            <mat-error class="margin_tp"
                            *ngIf="patientMasterForm.controls['middleName'].touched && patientMasterForm.controls['middleName'].errors?.pattern">
                                Please enter valid middle name.
                          </mat-error>
                          </div>
                           <div class="col-sm-3">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Last Name
                                <!-- <span class="required-asterisk">*</span> -->
                              </mat-label>
                              <input readonly matInput placeholder="Last Name" formControlName="lastName" required/>
                              
                            </mat-form-field>
                            <mat-error
                              *ngIf="patientMasterForm.controls['lastName'].touched && patientMasterForm.controls['lastName'].errors?.required">
                              This field is mandatory.
                            </mat-error>
                            <mat-error class="margin_tp"
                                        *ngIf="patientMasterForm.controls['lastName'].touched && patientMasterForm.controls['lastName'].errors?.pattern">
                                        Please enter valid last name.
                                      </mat-error>
                          </div>
                          <div *ngIf="fieldsOfPatients.patientAlternateName !== 'No'" class="col-sm-3">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Alternate Name </mat-label>
                              <input readonly  matInput placeholder="Alternate Name" formControlName="alternateName" />
                            
                            </mat-form-field>
                            <mat-error class="margin_tp"
                            *ngIf="patientMasterForm.controls['alternateName'].touched && patientMasterForm.controls['alternateName'].errors?.pattern">
                                Please enter valid Alternate name.
                          </mat-error>
                          </div>
                           <div *ngIf="fieldsOfPatients.patientDOB !== 'No'"  class="col-sm-3">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>DOB</mat-label>
                              <input readonly formControlName="dob" matInput placeholder="DD/MM/YYYY" >
                            
                            </mat-form-field>
                            <!-- <mat-error 
                            *ngIf="patientMasterForm.controls['dob'].touched && patientMasterForm.controls['dob'].errors?.required">
                            This field is mandatory.
                          </mat-error> -->
                          </div>
                           
                          <div class="col-sm-3">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Age
                                <!-- <span class="required-asterisk">*</span> -->
                              </mat-label>
                              <input readonly matInput placeholder="Age"  formControlName="age"  />
                          
                            </mat-form-field>
                            <mat-error
                            *ngIf="patientMasterForm.controls['age'].touched && patientMasterForm.controls['age'].errors?.pattern">
                            Enter Valid Age.
                          </mat-error>
                          </div> 
                           <div *ngIf="fieldsOfPatients.patientClientSource !== 'No' " class="col-sm-3">
                            <div class="row">
                              <div class="col-sm-10 pr-1">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Patient Source </mat-label>
                              <input readonly matInput placeholder="Patient Source"  formControlName="clientSource"  />
                              <!-- <mat-select  #select1 placeholder="Patient Source" class="colorblack"  formControlName="clientSource" >
                                <mat-select-filter  *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'patientSource'"
                                  [array]="allPatientSource" (filteredReturn)="filteredListPatientSource =$event"></mat-select-filter>
                                <mat-option  (click)="patientClick()" *ngFor="let item of filteredListPatientSource" 
                                 [value]="item.patientSource">
                                  {{item.patientSource}}
                                </mat-option>
                                
                              </mat-select> -->
                             
                            </mat-form-field>
                            </div>
                            <div class="col-sm-2 mt-2">
                              <i (click)="openPatientSourcePopUp();" matTooltip="Add New Patient Source"
                               class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                            </div>
                            </div>
                          </div>
  
                          <div *ngIf="isPatient" class="col-sm-3">
                            <div class="row"> 
                              <div class="col-sm-10 pr-1">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Patient Name </mat-label>
                              <input readonly (click)="openDepartmentPopUp();" matInput readonly
                              placeholder="Patient Name" formControlName="patientName" />
                              <!-- <mat-select  #select1 (click)="fetchAllPatientList();" formControlName="patientdropDown" >
                                <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'firstName'"
                                [array]="patientList"  (filteredReturn)="filteredListPatient =$event"></mat-select-filter>
                              <mat-option *ngFor="let item of filteredListPatient" (click)="patientNameClick(item.firstName,item.lastName)" [value]="item._id"
                              >
                               {{ item.firstName }}  {{ item.lastName }}</mat-option>
                              </mat-select> -->
                            </mat-form-field>
                            </div>
                            <div class="col-sm-2 mt-2">
                              <i (click)="openPatientPopUp();" matTooltip="Add New Patient"
                               class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                            </div> 
                            </div>
                          </div> 
                          <div *ngIf="isDoctor" class="col-sm-3">
                       
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Audiologists Name </mat-label>
                              <input readonly matInput placeholder="Doctor Name"  formControlName="doctorDropDown"  />
                            </mat-form-field>
                          </div>
  
                          <div *ngIf="isAdvertiesment" class="col-sm-3">
                       
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label> Advertisement </mat-label>
                              <input readonly matInput placeholder="advertisement"  formControlName="advertisement"  />
                            </mat-form-field>
                          </div>
                          <div *ngIf="isSocialMedia" class="col-sm-3">
                       
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label> Social Media </mat-label>
                              <input readonly matInput placeholder="Social Media"  formControlName="socialMedia"  />
                            </mat-form-field>
                          </div> 
                          <!-- <div class="col-sm-1 mt-3 lclass">
                            <mat-label  class="mt-2 lclass"><span class="required-asterisk mr-1">*</span>Gender :</mat-label>
                          </div> -->
                          <div class="col-sm-5 mt-2 genderClass">
                            <div class="row"> 
                              <div class="col-sm-4 mt-2">
                                  <label for="" class="genderClass ">*Gender:</label>
                                  </div>  
                                  
                            <div class="col-sm-8">
                            <mat-radio-group formControlName="sex" readonly disabled required
                            class="example-full-width" aria-label="Select an option">
                              <div class="row">
                                  <div class="col-sm-4 p-1 mt-2">
                              <mat-radio-button value="Male">Male</mat-radio-button>
                              </div>
                              <div class="col-sm-4 p-1 mt-2">
                            <mat-radio-button value="Female">Female</mat-radio-button>
                            </div>
                            <div class="col-sm-4 p-1 mt-2">
                            <mat-radio-button value="Other">Other</mat-radio-button>
                            </div>
                            </div> 
                          </mat-radio-group>
                           
                            <mat-error
                            *ngIf="patientMasterForm.controls['sex'].touched && patientMasterForm.controls['sex'].errors?.required">
                            This field is mandatory.
                          </mat-error>
                          </div>
                          </div>
                          </div>
                           
                              <div class="col-sm-12  ">
                                <h5 class="pb-0 mb-0">Contact Details :</h5>
                                </div>
                                
                                   <div *ngIf="fieldsOfPatients.patientLandline1 !== 'No'" class="col-sm-3">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                      <mat-label>Landline 1</mat-label>
                                      <input readonly matInput placeholder="e.g(0221-666666)" formControlName="landline1" />
                                    
                                    </mat-form-field>
                                    <mat-error class="margin_tp"
                                    *ngIf="patientMasterForm.controls['landline1'].touched && patientMasterForm.controls['landline1'].errors?.pattern">
                                        Please enter valid landline number.
                                  </mat-error>
                                  </div>
                                   <div *ngIf="fieldsOfPatients.patientLandline2 !== 'No'" class="col-sm-3">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                      <mat-label>Landline 2</mat-label>
                                      <input readonly matInput placeholder="e.g(0221-666666)" formControlName="landline2" />
                                  
                                    </mat-form-field>
                                    <mat-error class="margin_tp"
                                    *ngIf="patientMasterForm.controls['landline2'].touched && patientMasterForm.controls['landline2'].errors?.pattern">
                                        Please enter valid landline number.
                                  </mat-error>
                                  </div>
                                   <div class="col-sm-3">
                                    <div class="row">
                                      <div class="col-sm-10">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                      <mat-label> Mobile 1 
                                        <!-- <span class="required-asterisk">*</span> -->
                                      </mat-label>
                                      <input readonly matInput maxlength="10" (change)="changemob1()" placeholder="Mobile 1"
                                        formControlName="mobile1" required/>
  
                                    </mat-form-field>
                                    <mat-error class="margin_tp"
                                    *ngIf="patientMasterForm.controls['mobile1'].touched && patientMasterForm.controls['mobile1'].errors?.pattern">
                                        Please enter valid mobile number.
                                  </mat-error>
                                  <mat-error
                                  *ngIf="patientMasterForm.controls['mobile1'].touched && patientMasterForm.controls['mobile1'].errors?.required">
                                  This field is mandatory.
                                </mat-error>
                                  </div>
                                  <div class="col-sm-2 wApp">
                                    <i class="bi bi-whatsapp whatsapp-icon wApp fa-lg" (click)="WhatsApp();" matTooltip="WhatsApp Integration" ></i>
                                    </div>
                            </div>
                            </div>
                              
                                   <div *ngIf="fieldsOfPatients.patientMobile2 !== 'No'" class="col-sm-3">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                      <mat-label>Mobile 2</mat-label>
                                      <input readonly matInput  maxlength="10" (change)="changemob2()" placeholder="Mobile 2 " formControlName="mobile2" />
                                     
                                    </mat-form-field>
                                    <mat-error class="margin_tp"
                                    *ngIf="patientMasterForm.controls['mobile2'].touched && patientMasterForm.controls['mobile2'].errors?.pattern">
                                        Please enter valid mobile number.
                                  </mat-error>
                                  </div>
                                   <div *ngIf="fieldsOfPatients.patientMobile3 !== 'No'" class="col-sm-3">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                      <mat-label>Mobile 3 </mat-label>
                                      <input readonly matInput  maxlength="10" (change)="changemob3()" placeholder="Mobile 3" formControlName="mobile3" />
                                      
                                    </mat-form-field>
                                    <mat-error class="margin_tp"
                                      *ngIf="patientMasterForm.controls['mobile3'].touched && patientMasterForm.controls['mobile3'].errors?.pattern">
                                          Please enter valid mobile number.
                                    </mat-error>
                                    
                                  </div>
                                   <div *ngIf="fieldsOfPatients.patientMobile4 !== 'No'" class="col-sm-3">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                      <mat-label>Mobile 4</mat-label>
                                      <input readonly matInput  maxlength="10" (change)="changemob4()" placeholder="Mobile 4" formControlName="mobile4" />
                                     
                                    </mat-form-field>
                                    <mat-error class="margin_tp"
                                    *ngIf="patientMasterForm.controls['mobile4'].touched && patientMasterForm.controls['mobile4'].errors?.pattern">
                                        Please enter valid mobile number.
                                  </mat-error>
                                  </div>
                             
                                   <div *ngIf="fieldsOfPatients.patientMobile5 !== 'No'" class="col-sm-3">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                      <mat-label>Mobile 5 </mat-label>
                                      <input readonly matInput  maxlength="10" (change)="changemob5()" placeholder="Mobile 5" formControlName="mobile5" />
                                     
                                    
                                    </mat-form-field>
                                    <mat-error class="margin_tp"
                                    *ngIf="patientMasterForm.controls['mobile5'].touched && patientMasterForm.controls['mobile5'].errors?.pattern">
                                        Please enter valid mobile number.
                                  </mat-error>
                                  </div>
                                   <div *ngIf="fieldsOfPatients.patientEmail !== 'No'" class="col-sm-3">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                      <mat-label> Email Address</mat-label>
                                      <input readonly matInput class="emailClass" type="email" placeholder=" Email" formControlName="email"  />
                                     
                                    </mat-form-field>
                                    <!-- <mat-error class="margin_tp"
                                    *ngIf="patientMasterForm.controls['email'].touched && patientMasterForm.controls['email'].errors?.email">
                                        Please enter valid email.
                                  </mat-error> -->
                                  <mat-error class="margin_tp"
                                  *ngIf="patientMasterForm.controls['email'].touched && patientMasterForm.controls['email'].errors?.pattern">
                                  Please enter valid email.
                                </mat-error>
                                  </div>
                               
  
                        </div>
                      </div>
                    </div>  
                      <div class="col-sm-12">
                          <div class="row ">
                          
                            <div class="col-sm-12">
                            
                                <div class="row">
                                <div class="col-sm-12  ">
                                  <h5 class="pb-0 mb-0">Address Details :</h5>
                                  </div>
                                  </div>
                                  
                                  <div class="row">
                                    <div class=" col-sm-2  " *ngIf="fieldsOfPatients.patientStreetAddress !== 'No'">
                                      <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>Street Address
                                          <!-- <span class="required-asterisk">*</span> -->
                                        </mat-label>
                                        <input readonly matInput placeholder="Address" formControlName="streetAddress"/>
                                      
                                      </mat-form-field>
                                      <!-- <mat-error class="margin_tp"
                                      *ngIf="patientMasterForm.controls['streetAddress'].touched && patientMasterForm.controls['streetAddress'].errors?.required">
                                      This field is mandatory.
                                    </mat-error> -->
                                    </div>
                                    <div *ngIf="fieldsOfPatients.patientCountry !== 'No'" class=" col-sm-2  ">
                                      <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>Country</mat-label>
                                        <input readonly matInput placeholder="Country" formControlName="country" />
                                       
                                      </mat-form-field>
                                      <mat-error class="margin_tp"
                                      *ngIf="patientMasterForm.controls['country'].touched && patientMasterForm.controls['country'].errors?.pattern">
                                          Please enter valid country.
                                    </mat-error>
                                    </div>
                                    <div *ngIf="fieldsOfPatients.patientState !== 'No'" class=" col-sm-2  ">
                                      <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>State</mat-label>
                                        <input readonly matInput placeholder="State" formControlName="state" />
                                        <!-- <mat-select required  #select1 placeholder="State" class="colorblack"  formControlName="state"   >
                                          <mat-select-filter  *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'name'"
                                            [array]="allState"  (filteredReturn)="filteredList =$event"></mat-select-filter>
                                          <mat-option *ngFor="let item of filteredList" 
                                           [value]="item.value"
                                         
                                           >
                                            {{item.name}}
                                          </mat-option>
                                        </mat-select> -->
                                      
                                      </mat-form-field>
                                      <mat-error
                                      *ngIf="patientMasterForm.controls['state'].touched && patientMasterForm.controls['state'].errors?.required">
                                      This field is mandatory.
                                    </mat-error>
                                    </div>
                                    <div *ngIf="fieldsOfPatients.patientDistrict !== 'No'" class=" col-sm-2  ">
                                      <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>District</mat-label>
                                        <input readonly matInput placeholder="District" formControlName="district" />
                                       
                                      </mat-form-field>
                                      <mat-error class="margin_tp"
                                      *ngIf="patientMasterForm.controls['district'].touched && patientMasterForm.controls['district'].errors?.pattern">
                                          Please enter valid district.
                                    </mat-error>
                                    </div>
                                    <div *ngIf="fieldsOfPatients.patientTaluka !== 'No'" class=" col-sm-2 ">
                                      <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>Taluka</mat-label>
                                        <input readonly matInput placeholder="Taluka" formControlName="taluka" />
                                       
                                      </mat-form-field>
                                      <mat-error class="margin_tp"
                                      *ngIf="patientMasterForm.controls['taluka'].touched && patientMasterForm.controls['taluka'].errors?.pattern">
                                          Please enter valid taluka.
                                    </mat-error>
                                    </div>
                                
                                
                                
                               
                                <div class="col-sm-2" *ngIf="fieldsOfPatients.patientPincode !== 'No'">
                                  <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>Pincode 
                                      <!-- <span class="required-asterisk">*</span> -->
                                    </mat-label>
                                    <input readonly maxLength="6"  matInput placeholder="Pincode(e.g:416008)" formControlName="pincode"/>
                                 
                                  
                                  </mat-form-field>
                                
                                </div>
                              <!-- <div class="col-sm-6"></div> -->
                                  <div *ngIf="fieldsOfPatients.patientAltAddress !== 'No'" class=" col-sm-4 mt-2">
                                  
                                    <mat-checkbox  readonly disabled class="example-margin header" formControlName="altAddress"  [checked]="isChecked" (change)="onChangeAltAddress($event)"
                                    > Alternate Address
                                   </mat-checkbox>
                                  </div>
                               
                              </div>
                            
  
                           
                                <div *ngIf="altAddressCheck" class="row">
                              <div class="col-sm-12 mt-1  ">
                                <h5 class="pb-0 mb-0">Alternate Address Details :</h5>
                                  </div>
                            </div>
                             <div class="row">
                              <div *ngIf="altAddressCheck" class=" col-sm-2  ">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Street Address</mat-label>
                                  <input readonly matInput placeholder="Address" formControlName="streetAddress2" />
                                 
                                </mat-form-field>
                              </div>
                            
                              <div *ngIf="altAddressCheck" class=" col-sm-2  ">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Taluka</mat-label>
                                  <input readonly matInput placeholder="Taluka" formControlName="taluka2" />
                                 
                                </mat-form-field>
                                <mat-error class="margin_tp"
                                *ngIf="patientMasterForm.controls['taluka2'].touched && patientMasterForm.controls['taluka2'].errors?.pattern">
                                    Please enter valid taluka.
                              </mat-error>
                              </div>
                          
                              <div *ngIf="altAddressCheck" class=" col-sm-2  ">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>District</mat-label>
                                  <input readonly matInput placeholder="District" formControlName="district2" />
                                 
                                </mat-form-field>
                                <mat-error class="margin_tp"
                                *ngIf="patientMasterForm.controls['district2'].touched && patientMasterForm.controls['district2'].errors?.pattern">
                                    Please enter valid district.
                              </mat-error>
                              </div>
                              <div *ngIf="altAddressCheck" class=" col-sm-2  ">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>State</mat-label>
                                  <input readonly matInput placeholder="State" formControlName="state2" />
                                  <!-- <mat-select  #select1 placeholder="State" class="colorblack"  formControlName="state2"   >
                                    <mat-select-filter  *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'name'"
                                      [array]="allState"  (filteredReturn)="filteredList =$event"></mat-select-filter>
                                    <mat-option *ngFor="let item of filteredList" 
                                     [value]="item.value"
                                   
                                     >
                                      {{item.name}}
                                    </mat-option>
                                  </mat-select> -->
                                
                             
                                </mat-form-field>
                              </div>
                              
                              <div *ngIf="altAddressCheck" class=" col-sm-2  ">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Country</mat-label>
                                  <input readonly matInput placeholder="Country" formControlName="country2" />
                                 
                                </mat-form-field>

                              </div>
                              <div *ngIf="altAddressCheck" class=" col-sm-2  ">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Pincode 
                                    
                                  </mat-label>
                                  <input readonly  matInput maxlength="6" placeholder="Pincode(e.g:416008)" formControlName="pincode2" />
                               
                                
                                </mat-form-field>
                              
                              </div>
                            </div>
                          </div>
  
                            <div class="col-sm-12">
                              
                                  <div class="row">
                                    <div class="col-sm-12  ">
                                      <h5 class="pb-0 mb-0">Other Details :</h5>
                                    </div>
                                    </div>
                                    <div class="row">
                                      <div *ngIf="fieldsOfPatients.patientOccupation !== 'No'" class=" col-sm-2  ">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>Occupation</mat-label>
                                          <input readonly  matInput maxlength="6" placeholder="Occupation" formControlName="occupation" />
                                          <!-- <mat-select  #select1 placeholder="Occupation" class="colorblack"  formControlName="occupation"   >
                                            <mat-select-filter  *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'name'"
                                              [array]="allOccupation" (filteredReturn)="filteredListOccupation =$event"></mat-select-filter>
                                            <mat-option *ngFor="let item of filteredListOccupation" 
                                             [value]="item.value" (click)="occupationClick()">
                                              {{item.name}}
                                            </mat-option>
                                          </mat-select> -->
                                        
                                        </mat-form-field>
                                        
                                      </div>
                                      <div *ngIf="otherOccupation" class=" col-sm-4  ">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>Other Occupation</mat-label>
                                          <input readonly matInput placeholder="Other" formControlName="otherOccupation" />
                                         
                                        </mat-form-field>
                                     
                                      </div>
                                      <div *ngIf="fieldsOfPatients.patientAadhaarNo !== 'No'" class=" col-sm-2  ">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>Aadhaar Card Number</mat-label>
                                          <input readonly matInput placeholder="e.g(9999-9999-9999)" formControlName="aadhaarNo" />
                                        
                                        </mat-form-field>
                                       
                                      </div>
                                      <div class=" col-sm-2  ">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>Patient Status</mat-label>
                                          <input readonly matInput placeholder="Status" formControlName="clientStatus" />
                                          <!-- <mat-select formControlName="clientStatus">
                                            <mat-option value="Active">Active</mat-option>
                                            <mat-option value="In-active">In-active</mat-option>
                                          </mat-select> -->
                                        </mat-form-field>
                                      </div>
                                      <div *ngIf="fieldsOfPatients.patientNotes !== 'No'" class="col-sm-6  ">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>Notes</mat-label>
                                  
                                          <textarea maxlength="1000" matInput formControlName="notes" readonly
                                          cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                          cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" ></textarea>
                                        </mat-form-field>
                                      </div>
                                    </div> 
                                   
                                   
                                   
                                  </div>
                                  
                                  <!-- <div *ngIf="isSuperAdmin" class="col-sm-12"> 
                              
                                    <div class="row">
                                      <div class="col-sm-12  ">
                                    <label class="header">Select Branch :</label>
                                      </div>
                                      </div>
                                      <div class="row">
  
                                  <div class="col-sm-2">
                                    <div class="row">
                                      <div class="col-sm-10 pr-1">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                      <mat-label>Branch 
                                       
                                      </mat-label>
                                      <mat-select #select1 formControlName="branchRefId" >
                                        <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'branchName'"
                                        [array]="BranchList"  (filteredReturn)="filteredListBranch =$event"></mat-select-filter>
                                        <mat-option *ngFor="let item of filteredListBranch" [value]="item._id" 
                                        (click)="branchClick(item._id,item.branchName);"
                                        >
                                         {{ item.branchName }}</mat-option>
                                        </mat-select>
                                       
                                    </mat-form-field>
                                    <mat-error class="margin_tp"
                                    *ngIf="patientMasterForm.controls['branchRefId'].touched && patientMasterForm.controls['branchRefId'].errors?.required">
                                    This field is mandatory.
                                  </mat-error>
                                   </div>
                                   <div class="col-sm-2 mTop">
                                    <img (click)="openBranchPopUp();" matTooltip="Add New Branch" width="25" src="../../assets/add_img.png"> 
                                  </div>
                                   </div>
                                  </div>
                             </div>
                             </div> -->
                             <div *ngIf="isDecease" class="col-sm-12"> 
                              
                              <div class="row">
                                <div class="col-sm-2">
                              <label class="header mt-3">Is Decease :</label>
                                </div>
                                <div class="col-sm-2">
                                  <mat-form-field class="example-full-width " appearance="outline">
                                    <mat-label>Decease</mat-label>
                                    <input readonly matInput placeholder="Decease" formControlName="decease" />
                                  <!-- <mat-select formControlName="decease">
                                    <mat-option value="Yes">Yes</mat-option>
                                    <mat-option value="No">No</mat-option>
                                  </mat-select> -->
                                  </mat-form-field>
                                  
                                </div>
                                </div>
                               
                       </div>
                          </div>
                       
                      </div>
                  </div>
              </div>
          </div>
        </div>
      
  
          
        
      
  </div>
    </div>
    </form>
  </div>