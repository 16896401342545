<div class="container-fluid bgcolor2" [formGroup]="ChequeListForm">
    <form [formGroup]="ChequeListForm" autocomplete="off" novalidate>
      <div class="row">
          <div class="col-sm-1">
    
          </div>
          <div class="col-sm-11">
            <div class="row pl-0 ">
              <div class="col-12 pl-0 ">
                <div class="row ">
                <div class="col-sm-9">
                    <h1 class="pt-2 pl-0" id="toChequeListForm">Cheque List</h1>
                    </div>
                    <div class="col-sm-3 mt-1">
                      <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                        <a (click)="onback()" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">arrow_back</span>Back</a>
                        <a (click)="toBillingLst()" class="d-flex align-items-center ms-3 pr-2 mt-2"><span class="material-symbols-outlined pr-2">reorder</span>List</a>
                   </div>
              </div>
            </div>
           </div>
           </div>  

              <div class="row">
                  <div class="col-sm-12 pr-4">
                      <div class="row">
                          <div class="card divbody mb-3 borderradius p-2">
                              
                              <div class="col-sm-12">
                                  <div class="row">
                                  
                                        <!-- <div class="col-sm-3">
                                          <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label>Patient ID</mat-label>
                                            <input matInput placeholder="Patient ID" formControlName="patientId" />
                                        
                                          </mat-form-field>
                                        </div> -->
                                        <div class="col-sm-3">
                                          <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label>Bill No</mat-label>
                                            <input matInput placeholder="Bill No" formControlName="billNo" />
                                          </mat-form-field>
                                        </div>
                                        <div class="col-sm-3">
                                          <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label>Cheque No</mat-label>
                                            <input matInput placeholder="Cheque No" formControlName="chequeNo" />
                                          </mat-form-field>
                                        </div>
                                        <div class="col-sm-3">
                                          <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label>Status</mat-label>
                                      
                                            <mat-select placeholder="Select" class="colorblack" formControlName="chequeStatus">
                                              <!-- <mat-option value="postdated">Post-dated</mat-option>
                                              <mat-option value="Deposited">Deposited</mat-option>
                                              <mat-option value="Clear">Clear</mat-option>
                                              <mat-option value="Bounce">Bounce</mat-option> -->

                                              
                                              <mat-option value="Received" >Received</mat-option>
                                              <mat-option value="postdated" >Received Post-Dated</mat-option>
                                             
                                              <mat-option value="bounce" >Dishonoured</mat-option>
                                              <mat-option value="Deposited" >Deposited</mat-option>
                                              <mat-option value="Clear" >Cleared</mat-option>
                                              <mat-option value="Cancelled" >Cancelled</mat-option>
                                              <mat-option value="Returned" >Returned</mat-option>

                                            </mat-select>
                                          </mat-form-field>
                                        </div> 
                                        <div class="col-sm-3 "></div>
                                        <div class="col-sm-3 ">
                                          <mat-form-field class="example-full-width " appearance="outline">
                                             <mat-label>From Date</mat-label>
                                             <input matInput [matDatepicker]="fdate" placeholder="Choose a date" 
                                             formControlName="fdate" (dateChange)="toOpenDateChnage();">
                               
                                              <mat-datepicker-toggle matSuffix [for]="fdate">
                                              </mat-datepicker-toggle>
                                              <mat-datepicker #fdate></mat-datepicker>   
                                         </mat-form-field>
   
                                             <mat-error
                                             *ngIf="ChequeListForm.controls['fdate'].touched && ChequeListForm.controls['fdate'].errors?.required">
                                             This field is mandatory.
                                           </mat-error>
                                         </div>
                                         <div class="col-sm-3 ">
                                           <mat-form-field class="example-full-width " appearance="outline">
                                               <mat-label>To Date</mat-label>
                                               <input matInput [matDatepicker]="todate" placeholder="Choose a date" 
                                               formControlName="todate" (dateChange)="toCloseDateChnage();">
                                
                                                <mat-datepicker-toggle matSuffix [for]="todate">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #todate></mat-datepicker>
                                              
                                           </mat-form-field>
                                           <mat-error
                                           *ngIf="ChequeListForm.controls['todate'].touched && ChequeListForm.controls['todate'].errors?.required">
                                           This field is mandatory.
                                         </mat-error>
                                       </div>
                                      
                                       
                                        <!-- <div *ngIf="isSuperAdmin" class="col-sm-12">
                              
                                          <div class="row">
                                            <div class="col-sm-12 mt-1 ">
                                          <label class="header">Select Branch :</label>
                                            </div>
                                            </div>
                                            <div class="row">
                      
                                        <div class="col-sm-3" *ngIf="branchCreate == true">
                                          <div class="row">
                                            <div class="col-sm-10 pr-1">
                                          <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label>Branch 
      
                                            </mat-label>
                                            <mat-select #select1 (click)="fetchBranchList();" formControlName="branchRefId" >
                                              <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'branchName'"
                                              [array]="BranchList"  (filteredReturn)="filteredListBranch =$event"></mat-select-filter>
                                              <mat-option *ngFor="let item of filteredListBranch" [value]="item._id"
                                              (click)="branchClick(item._id,item.branchName);" >
                                               {{ item.branchName }}</mat-option>
                                              </mat-select>
                                              
                                          </mat-form-field>
                                          <mat-error class="margin_tp"
                                          *ngIf="ChequeListForm.controls['branchRefId'].touched && ChequeListForm.controls['branchRefId'].errors?.required">
                                          This field is mandatory.
                                        </mat-error> 
                                        </div>
                                        <div class="col-sm-2 mTop">
                                          <img (click)="openBranchPopUp();" matTooltip="Add New Branch" width="25" src="../../assets/add_img.png"> 
                                        </div>
                                        </div>
                                        </div>
                                        <div class="col-sm-3" *ngIf="branchCreate == false">
                                            
                                          <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label>Branch 
                         
                                            </mat-label>
                                            <mat-select #select1 (click)="fetchBranchList();" formControlName="branchRefId" >
                                              <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'branchName'"
                                              [array]="BranchList"  (filteredReturn)="filteredListBranch =$event"></mat-select-filter>
                                              <mat-option *ngFor="let item of filteredListBranch" [value]="item._id"
                                              (click)="branchClick(item._id,item.branchName);" >
                                               {{ item.branchName }}</mat-option>
                                              </mat-select>
                                              
                                          </mat-form-field>
                                          <mat-error class="margin_tp"
                                          *ngIf="ChequeListForm.controls['branchRefId'].touched && ChequeListForm.controls['branchRefId'].errors?.required">
                                          This field is mandatory.
                                        </mat-error> 
                                        
                                        </div>
                                   </div>
                                   </div> -->
                                   
                                      <div class="col-sm-1 offset-sm-10 mb-2 mt-2" *ngIf="submitButton"> 
                                          <button class="btn btn-primary btn-sm example-full-width"  type="button" 
                                          (click)="searchChequeData()">Search </button>
  
                                      </div>
                                      <div class="col-sm-1 mb-2 mt-2">
                                        <button class="btn btn-primary btn-sm example-full-width"  (click)="clear()" type="reset">Cancel</button>
                                        </div>
                                      
                                      <!-- <div class="col-sm-2 mb-2 mt-2" > 
                                        <button class="btn btn-primary btn-sm example-full-width"  type="button" 
                                        (click)="billing()">Add New </button>
  
                                    </div> -->
                                      <!-- <div class="col-sm-2 offset-sm-8 mb-2 mt-2" *ngIf="updateButton && billingUpdate">
                                          <button (click)="UpdateInwordOutword();"  type="button" 
                                          class="btn btn-primary btn-sm example-full-width">Update </button>
                                      </div> -->
                                      <!-- <div class="col-sm-2 mb-2 mt-2 " *ngIf="updateButton">
                                          <button class="btn btn-primary btn-sm example-full-width" (click)="cancelUpdate()" type="reset" >Cancel </button>
                                          
                                      </div> -->
                                    
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-sm-12 pl-0 pr-2">
                  <div class="row">
                      <div class="card divbody borderradius p-3 pb-0">
                              <div class="col-sm-12">
                                  <div class="row">
                                      <div class="col-sm-9 ">
                                          <h1 class="titlecolor">Cheque List</h1>
                                      </div>
                                      <div class="search-box col-sm-3 pb-2">
                                        <input class="form-control amber-border" type="text" placeholder="Search" 
                                        formControlName="searchBilling" aria-label="Search" (keyup)="onKeyUpEvent($event)"
                                        >
                                        <div class="input-group-append">
                                           <span class="input-group-text amber lighten-3" id="basic-text1"><span class="material-symbols-outlined">search</span></span>
                                        </div>
                                     </div>
                                      
                                      <div class="col-sm-12">
                                          <table id="toBillingLst"
                                           class="table table-bordered data-table-custom text-center" 
                                       >
                                              <thead>
                                                  <tr class="text-center">
                                                      <th>Sr.No.</th>
                                                      <th>Bill No</th>
                                                      <th>Patient Name</th>
                                                      <th>Mobile</th>
                                                       <th>Cheque No</th>
                                                      <th>Cheque Date</th>
                                                      <th>Cheque Amount</th>
                                                      <th>Status</th>
                                                  </tr>
                                              </thead> 
                                              <tbody>
                                                  <tr *ngFor="let item of billingList | paginate : { id:'chequeListMaster',
                                                  itemsPerPage: size, currentPage: page, totalItems:totalLength }; let i = index" 
                                                  (click)="redirectToBillForm(item.receiptNo,item.chequeNo)" 
                                                   (click)="toChequeListForm()" 
                                                   style="cursor:pointer; text-align: center ">
                                                   <td matTooltip="Click To View">{{i+1}}</td>
                                                 <!-- | date -->
                                                 <td matTooltip="Click To View" >{{item.billNo}}</td>
                                                 <td matTooltip="Click To View" >{{item.patientName}}</td>
                                                 <td matTooltip="Click To View" >{{item.mobile}}</td>
                                                 <td matTooltip="Click To View" >{{item._id}}</td>
                                                 <td  matTooltip="Click To View" 
                                                 >{{item.chequeDate | date:'dd/MM/yyyy'}}
                                               </td>
                                               <td>{{item.amount}}</td>
                                               <td  *ngIf="item.chequeStatus == 'Clear'" class="green">          
                                                Clear
                                                 </td>
                                                 <td  *ngIf="item.chequeStatus == 'postdated'" class="orange">          
                                                  Post Dated
                                                   </td>
                                                   <td *ngIf="item.chequeStatus == 'Deposited'" class="orange">          
                                                    Deposited
                                                     </td>
                                                     <td  *ngIf="item.chequeStatus == 'bounce'" class="red">          
                                                      Dishonoured
                                                       </td>
                                                       <td  *ngIf="item.chequeStatus == 'Received'" class="blue">          
                                                        Received 
                                                         </td>
                                                         <td  *ngIf="item.chequeStatus == 'Cancelled'" class="red">          
                                                          Cancelled
                                                           </td>
                                                           <td  *ngIf="item.chequeStatus == 'Returned'" class="blue">          
                                                            Returned
                                                             </td>
                                                     </tr>
                                               
  
                                              </tbody>
                                          </table>
                                      </div>
                                      <div class="col-sm-12 pt-0 text-right">
                                              <pagination-controls id="chequeListMaster" directionLinks="true"
                                    (pageChange)="onPageChange($event)" class="float-right pagination my-pagination"></pagination-controls>
                                          
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
  
                  </div>
               
  
          </div>
      </div>
      </form>
  </div>