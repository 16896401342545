<div class="col-sm-12" [formGroup]="trackingStatusForm">
    <div class="row">
       
        <div class="col-sm-5  ">
            <h1 class="titlecolor" >Search Tracking Status</h1>  
        </div>
        <div class="col-sm-3 mt-0 pt-0">
              <mat-form-field class="example-full-width  mt-0 pt-0 mb-2"  appearance="outline">
                <mat-label>Tracking Status </mat-label>
                <mat-select #select1 placeholder="Status" multiple class="colorblack"
                  formControlName="trackingStatusName">
                  <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'"
                    [displayMember]="'trackingStatusName'" [array]="allPatientStatus"
                    (filteredReturn)="filteredListPatientStatus =$event"></mat-select-filter>
                  <mat-option *ngFor="let item of filteredListPatientStatus" (click)="onKeyUpEvent($event)"
                    [value]="item.trackingStatusName">
                    <i class="fa-solid fa-circle" [style.color]="item.color"></i>{{item.trackingStatusName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
        </div>
        <div class="col-sm-3 mt-2">
            <div class="input-group md-form form-sm form-1 pl-0"> 
              <div class="input-group-prepend">
                <span class="input-group-text purple lighten-3" id="basic-text1"><i class="fas fa-search text-white"
                    aria-hidden="true"></i></span>
              </div>
              <input class="form-control my-0 py-1" type="text" onkeydown="return (event.keyCode!=13);" formControlName="searchData"
              aria-label="Search" (keyup)="onKeyUpEvent($event)" placeholder="Search" aria-label="Search">

            </div>
        </div>
        <div class="col-sm-1 text-right pb-2">
          <a (click)="onClose()" matTooltip="Close" class="d-flex align-items-center pr-2 mt-2">
            <span class="material-symbols-outlined pr-2">close</span></a>

      </div>
      <div class="col-sm-12 table-responsive">
        <table id="toRepairList" 
     class="table table-bordered data-table-custom">
          <thead>
            <tr class="text-center">
              <th>First Name</th>
              <th>Middle Name</th>
              <th>Last Name</th>
              <th>Gender</th>
              <th>Mobile Number</th>
              <th>Tracking Status</th>
              <th>Tracking Status Date</th>
            </tr>
          </thead>
          <tbody>

            <tr *ngFor="let item of listOfCheckupDetails | paginate : { id:'repairpaginate', 
                   itemsPerPage: size, currentPage: page, totalItems:totalLength } " 
              class=" text-center">
              <td (click)="toPatientForm(item)" matTooltip="click to view">{{item.patientfirstName}}</td>
              <td (click)="toPatientForm(item)" matTooltip="click to view">{{item.patientmiddleName}}</td>
              <td (click)="toPatientForm(item)" matTooltip="click to view">{{item.patientlastName}}</td>
              <td (click)="toPatientForm(item)" matTooltip="click to view">{{item.patientGender}}</td>
              <td (click)="toPatientForm(item)" matTooltip="click to view">{{item.patientMobile}}</td>
              <td (click)="toPatientForm(item)" matTooltip="click to view">{{item.trackingStatusName}}</td>
              <td (click)="toPatientForm(item)" matTooltip="click to view">{{item.appointDate | date :'d/M/yy'}}</td>
            </tr>
          </tbody>
        </table>
        </div>
        <div class="col-sm-12 pt-0 text-right">
            <pagination-controls id="repairpaginate" directionLinks="true" (pageChange)="onPageChange($event)"
            class="float-right pt-3  my-pagination"></pagination-controls>
        
          </div>
    </div>
</div>
