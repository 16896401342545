<div class="row pt-2" >
    <h3 class="col-sm-12">Appointment Menu</h3>
    <div class="col-sm-12">
        <div class="col p-2 text-center">
            <div class="card p-3 borderradius boxsssss hover" (click)="close()" [routerLink]="['/home/calander']">
                <div class="col-sm-12">
                    <!-- <div class="card cardBoxpurple p-3 borderradius ">
                        <h2 class="fas fa-concierge-bell"></h2>
                    </div> -->
                    <img src="../../assets/calendar (1).png" width="60rem">
                </div>
                <div class="col-sm-12 p-0 pt-2">
                    <h4 class="subtitlecolor">Calendar</h4>
                </div>
            </div>
        </div>
        <div class="col p-2 text-center">
            <div class="card p-3 borderradius boxsssss hover " (click)="close()" [routerLink]="['/home/calander']">
                <div class="col-sm-12">
                    <!-- <div class="card cardBoxpurple p-3 borderradius ">
                        <h2 class="fas fa-concierge-bell"></h2>
                    </div> -->
                    <img src="../../assets/medical-appointment.png" width="60rem">
                </div>
                <div class="col-sm-12 p-0 pt-2">
                    <h4 class="subtitlecolor">Book Appointments</h4>
                </div>
            </div>
        </div>
        <div class="col p-2 text-center">
            <div class="card p-3 borderradius boxsssss hover" (click)="close()" [routerLink]="['/home/calander']">
                <div class="col-sm-12">
                    <!-- <div class="card cardBoxpurple p-3 borderradius ">
                        <h2 class="fas fa-concierge-bell"></h2>
                    </div> -->
                    <img src="../../assets/contact-form.png" width="60rem">
                </div>
                <div class="col-sm-12 p-0 pt-2">
                    <h4 class="subtitlecolor">Todays Appointments </h4>
                </div>
            </div>
        </div>
    </div>
    
</div>

