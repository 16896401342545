import { Component, Inject, OnInit } from "@angular/core";
import {FormGroup,FormBuilder,FormControl, Validators} from '@angular/forms';
import { ApiServiceService } from '../api-service.service';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-pop-up-city',
  templateUrl: './pop-up-city.component.html',
  styleUrls: ['./pop-up-city.component.scss']
})
export class PopUpCityComponent implements OnInit {
  popupCityForm: FormGroup;
  searchText: any;
  listOfCity: any;
  totalLength:number;
  page:number = 1;
  cid:any;
  size =3;
  submitButton = true;
  updateButton = false;
  cancelButton = false;
  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<PopUpCityComponent>,@Inject(MAT_DIALOG_DATA) public data:any, 
    public apimicroservice: ApiServiceService,
) { dialogRef.disableClose = true;}
 
  ngOnInit(): void {
    this.popupCityForm = this.fb.group({  
      "cityName": new FormControl('',[Validators.required,Validators.pattern("^[a-zA-Z ]+$")]),
      "createdAt": new Date(),
      "searchCity": new FormControl(''), 
    });
    this.fetchCityList();
  }

  saveCity(){
    let payload = this.popupCityForm.value; 
    let str = "";
    str = payload.cityName;
    payload.cityName = str.toLowerCase();
    //
    
    this.apimicroservice.submitCityData(payload).subscribe(res => {
      //
      
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);      
        this.ngOnInit();
       this.onClose();        
      }else if(res.status == false){       
        this.apimicroservice.openSnackbar(res.message);
      }
    },(ERROR:HttpErrorResponse) => {
      this.apimicroservice.openSnackbar(ERROR.error.message);
  });
  } 
  save() {
    if (this.popupCityForm.invalid) {
      this.popupCityForm.get("cityName").markAsTouched();
      return;
    } else {
      this.saveCity();
    }
  }

  prepopulation(id) {
    this.apimicroservice.getCityDataById(id).subscribe(res => {
      let data = res.data[0];
      this.cid = id;
       if(res.status){
        this.submitButton = false;
         this.updateButton = true;
         this.cancelButton = true;
         this.popupCityForm = this.fb.group({
          "cityName" :new FormControl(data.cityName),
          "updateddAt": new Date(),
         });
       }else{
     //    //this.apimicroservice.openSnackbar(res.message);
       }
     });
  }
  onKeyUpEvent(event: any) {
    this.searchText = this.popupCityForm.get('searchCity').value;
    let branchRef='';
    let clinicRef='';  
    //
      let pg = 1;
      let size = 5;
     this.apimicroservice.getCityDataDetails(this.page,this.size,this.searchText).subscribe(res => {
        //
        this.listOfCity = res.data.data;
        this.totalLength = res.data.totalItems;
        this.page = res.data.pageNumber;
       
       if(res.status){
       
         
       }else{
         
       }
     });
    
  }
  onPageChange(event) {
    this.page = event;
    let pg = 1;
    let size = 5;
   // let p = this.page - 1;
    this.searchText = this.popupCityForm.get('searchCity').value;
 
    this.apimicroservice.getCityDataDetails(this.page,this.size,this.searchText).subscribe(res => {
      //
      
      this.listOfCity = res.data.data;
      this.totalLength = res.data.totalItems;
         if(res.status){
          //  this.apimicroservice.openSnackbar(res.message);
           
         }else{
           //this.apimicroservice.openSnackbar(res.message);
         }
    
       });
  
  }
  
  updateCity() {
    let payload = this.popupCityForm.value;
     this.apimicroservice.updateCityData(payload,this.cid).subscribe(res => {
       if(res.status){
         this.apimicroservice.openSnackbar(res.message);
          this.updateButton = false;
          this.submitButton = true;
          this.ngOnInit();
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
    
  }
  onClose(): void {
    this.dialogRef.close();
  }
  cancelUpdate(){
    this.popupCityForm.reset();
    this.submitButton=true;
    this.updateButton=false;
    this.cancelButton=false;
    this.ngOnInit();
  }

  fetchCityList() {
    let pg = 1;
    let size = 3;
    let search='';
        this.apimicroservice.getCityDataDetails(pg,size,search).subscribe(res => {
          //
          
           this.listOfCity = res.data.data;
           this.totalLength = res.data.totalItems;
           //this.buildingLenght = this.listofbuild.length;
          if(res.status){
           // this.apimicroservice.openSnackbar(res.message);
            
          }else{
            //this.apimicroservice.openSnackbar(res.message);
          }
     
        });   
 
  }
}
