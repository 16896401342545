import { Component, Inject, OnInit } from "@angular/core";
import { ApiServiceService } from '../api-service.service';
import { NgxSpinnerService } from "ngx-spinner";
import * as htmlToImage from 'html-to-image';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PatientSearchPopComponent } from "../patient-search-pop/patient-search-pop.component";
import { PopUpPatientComponent } from "../pop-up-patient/pop-up-patient.component";
import { PdfviewerComponent } from "../pdfviewer/pdfviewer.component";

@Component({
  selector: 'app-communication',
  templateUrl: './communication.component.html',
  styleUrls: ['./communication.component.scss']
})
export class CommunicationComponent implements OnInit {
  appointRefId: any;
  appointDate: any;
  patientName: any;
  appDate: any;
  appTime: any;
  communicationMasterForm: FormGroup;
  address: any;
  todaysDate: Date;
  pincode: any;
  constructor(public dialogRef: MatDialogRef<CommunicationComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    public apimicroservice: ApiServiceService, private spinner: NgxSpinnerService, private fb: FormBuilder,
    public dialog: MatDialog) {
    dialogRef.disableClose = true;

  }

  ngOnInit(): void {
    this.onSelectPatient(this.data.patientId);

  }

  onSelectPatient(id) {
    this.apimicroservice.getPatientById(id).subscribe(res => {
      let data = res.data[0];
      //this.communicationMasterForm.controls["address"].setValue(data.address);
      this.communicationMasterForm = this.fb.group({
        "patientName": new FormControl(data.firstName + ' ' + data.lastName),
        "patientRefId": new FormControl(data._id),
        "address": new FormControl(data.streetAddress),
        "communicationHash256": new FormControl('dsds'),
      });
      this.patientName = data.firstName + ' ' + data.lastName;
      this.address = data.streetAddress;
      this.todaysDate = new Date();
      this.pincode = data.pincode;
    });

  }
  //  async generatePdf() {
  //   let payload = this.communicationMasterForm.value;
  //   await  htmlToImage.toJpeg(document.getElementById('communicationfffff'), { quality: 0.95 })
  //   .then(function(dataUrl){
  //     payload['communicationHash256'] = dataUrl;
  //   })
  //   this.apimicroservice.addCommunication(payload).subscribe((res) => {
  //     if (res.status) {
  //       this.apimicroservice.openSnackbar(res.message);
  //       this.ngOnInit();
  //     } else {
  //     }
  //   });
  // }
  onClose(): void {
    this.dialogRef.close();
  }
  async generatePdf()
   { 
    this.spinner.show(); 
    let payload = this.communicationMasterForm.value; 
    await htmlToImage.toJpeg(document.getElementById('communicationfffff'), 
    { quality: 0.95 }).then(function (dataUrl) { payload['communicationHash256'] = dataUrl; })
    this.apimicroservice.addCommunication(payload).subscribe((res) => { if (res.status) 
      { this.apimicroservice.openSnackbar(res.message); this.spinner.hide(); 
        this.showbase64(payload['communicationHash256']); } else { this.spinner.hide(); } }); } 
        showbase64(e) {
          // 
          
    const dialogRef3 = this.dialog.open(PdfviewerComponent, 
      { width: "1200px",
       data: e });
    }

  }
