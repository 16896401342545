import { Component, Inject, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  FormArray
} from "@angular/forms";
 import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  MatSnackBar,
} from "@angular/material/snack-bar";
import { MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { PopUpBranchComponent } from "../pop-up-branch/pop-up-branch.component";
@Component({
  selector: 'app-popup-mode-of-payment',
  templateUrl: './popup-mode-of-payment.component.html',
  styleUrls: ['./popup-mode-of-payment.component.scss']
})
export class PopupModeOfPaymentComponent implements OnInit {
  modeOfPaymentForm: FormGroup;
  date: any;
  listOfAppointment:any;
  totalLength:any;
  appointmentId: any;
  size=5;
  page=1;
  searchText: any;
  submitButton = true;
  updateButton = false;
  allColor: any;
  
  filteredList: { name: string; value: string; }[];
  filteredListAppoinmentType: { name: string; value: string; }[];
  userType: string;
  branchRef: string;
  clinicRef: string;
  BranchList: any;
  isSuperAdmin= true;
  superBranchId: any;
  branchName: string;
  filteredListBranch: any;
  currentdate: Date;
  listOfBilling: any;
  id: any;
  courierList: any;
  filteredListCourier: any;
  disableFromCity = false;
  disableToCity = true;
  tocityList: any;
  cityList: any;
  fromCity: any;
  toCity: any;
  courierServiceCreate = true;
  branchCreate = true;
  filteredListPatient: any;
  patientList: any;
  totalBillAmt = 0;
  chequeImage:any;
  printData=[
    {
      srNo:1,
      date:'201',
      serviceName:'Test',
      companyName:'A Company',
      rate:100,
     },
     {
      srNo:2,
      date:'202',
      serviceName:'Test',
      companyName:'A Company',
      rate:200,
     },
     {
      srNo:3,
      date:'203',
      serviceName:'Test 2',
      companyName:'A Company',
      rate:100,
     },
     {
      srNo:4,
      date:'204',
      serviceName:'Test 3',
      companyName:'A Company',
      rate:150,
     },                 
  ]
  patientRefId: any;
  appointId: any;
  billingList: any;
  mode: any;
  disableSubmit = false;
  checkAmountList = [];
  totalCheckAmount = 0;
  errormessage: string;
  pagename: any;
  chequeAr = [];
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
     public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<PopupModeOfPaymentComponent>,@Inject(MAT_DIALOG_DATA) public data:any,
  ) { 
    this.patientRefId = data.patientRefId;
    this.appointId = data.appointId;
    this.mode = data.data;
    this.checkAmountList = data.checkAmountArr;
    this.pagename = data.pagename;
    dialogRef.disableClose = true;
  }
  userPermission:any; 
  billingCreate= true;
  billingUpdate= true;
  paymentMode :any; 
  ngOnInit(): void {
    //
    //
    this.totalCheckAmount = 0;
    //
    this.checkAmountList.forEach(ele =>{
      
      
      this.totalCheckAmount =  this.totalCheckAmount + JSON.parse(ele.amount);
    })
    if(this.data.chequeData !== undefined)
    {
      this.chequeAr =this.data.chequeData;
    } 
    //
    this.paymentMode =this.mode;
    this.currentdate = new Date();
    this.page=1;
    this.size = 5;
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.userType = sessionStorage.getItem('userType');
    this.userType = sessionStorage.getItem('userType');
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    if(this.userType !== 'Super Admin'){
      this.isSuperAdmin= false;
    this.userPermission.forEach((element) => {
      if(element.pageName == 'Billing Record' && element.create == false)
      {
          this.billingCreate = false;  
      }
      if(element.pageName == 'Billing Record' && element.update == false)
      {
          this.billingUpdate = false;  
      }  
    }); 
 
  }
  this.disableSubmit = false;
  //
  //
    this.modeOfPaymentForm = this.fb.group({

      "searchBilling": new FormControl(''), 
      "chequeArray": this.fb.array([]),
    });
    
    let i = 0;
    if(this.chequeAr.length > 0)
    {
      this.chequeAr.forEach(element => {      
        const tmpDict = {};
        tmpDict['chequeDate'] = new FormControl(element.chequeDate,[Validators.required]);
        tmpDict['chequeNo'] = new FormControl(element.chequeNo,[Validators.required,Validators.pattern("^[0-9]*$")]);
        tmpDict['bankName'] = new FormControl(element.bankName,[Validators.required,Validators.pattern("^[a-zA-Z ]*$")]);
        tmpDict['amount'] = new FormControl(element.amount,[Validators.required,Validators.pattern("^[0-9]*$")]);
        tmpDict['chequeStatus'] = new FormControl(element.chequeStatus);
        tmpDict['photo'] = new FormControl(element.photo);

        const creds = this.modeOfPaymentForm.controls.chequeArray as FormArray;
         creds.push(new FormGroup(tmpDict));
         //

        });
    }
    else
    {
      this.addRow(i);
    }
   
}
  
  prepopulation(id) {
    this.dialogRef.close();
    this.redirect('bill-form');
  }

  tomodeOfPaymentForm() {
    document.getElementById("tomodeOfPaymentForm").scrollIntoView();
  }
  toBillingLst() {
    document.getElementById("toBillingLst").scrollIntoView();
  }

   
  // fetchBillList() {
  //   let search='';
  //   this.apimicroservice.getBillingDetailsByPatientId(this.page,this.size,search,this.patientRefId,this.appointId).subscribe(res => {
  //     //  
  //     if (res.status) {    
  //       this.billingList = res.data.data;
  //       this.totalLength=res.data.totalItems;
  //       this.billingList.forEach(element =>{
  //         this.totalBillAmt = this.totalBillAmt + element.totalAmount;
  //       })
  //     } else {
  //       this.apimicroservice.openSnackbar(res.message);
  //     }
  //   });
  // }

  onPageChange(event) {
    this.page = event;
    let search='';
    // this.apimicroservice.getBillingDetailsByPatientId(this.page,this.size,search,this.patientRefId,this.appointId).subscribe(res => {
    //   //  
    //   if (res.status) {    
    //     this.billingList = res.data.data;
    //     this.totalLength=res.data.totalItems;
    //   } else {
    //     this.apimicroservice.openSnackbar(res.message);
    //   }
    // });
    // if (this.userType !== 'Super Admin') {
    //   this.apimicroservice.getDoctorAvailablePaginationData(this.page,this.size,this.branchRef,this.clinicRef).subscribe(res => {
    //     if (res.status) {
    //       this.printData = res.data.data;
    //       this.totalLength=res.data.totalItems;
    //     } else {
    //       this.apimicroservice.openSnackbar("Something went wrong");
    //     }
  
    //   })
    // } 
  }

  printForm()
  {
    this.redirect('print-bill');
  } 
  redirect(to): void {
    this.router.navigate([to]);
   } 
   onClose(): void {
    this.dialogRef.close();
  }
  addRow(i) {
    const creds = this.modeOfPaymentForm.controls.chequeArray as FormArray;
        creds.push(
        this.fb.group({
          "chequeDate": new FormControl('',Validators.required),   
          "chequeNo": new FormControl('',[Validators.required, Validators.pattern("^[0-9]*$")]),  
          "bankName": new FormControl('',[Validators.required, Validators.pattern("^[a-zA-Z ]*$")]), 
          "amount": new FormControl('',[Validators.required, Validators.pattern("^[0-9]*$")]),
          "chequeStatus": new FormControl('postdated'),
          "photo": '',
        toggle: false
        }));
        this.chequeImage ="../../assets/user2.png";
  }
  deleteRow(index){
    //this.dynamicRows.splice(index, 1);
    (<FormArray>this.modeOfPaymentForm.get("chequeArray")).removeAt(index);
  }

  submitChequeDetails(){
    const creds = this.modeOfPaymentForm.controls.chequeArray as FormArray;  
    if(creds.invalid)
    {
      creds.controls.forEach(element =>{
        element.get('chequeDate').markAsTouched();
        element.get('chequeNo').markAsTouched();
        element.get('bankName').markAsTouched();
        element.get('amount').markAsTouched();
        element.get('photo').setValue(this.chequeImage);
      });
      
    }
    else{
      creds.controls.forEach(element =>{
        element.get('photo').setValue(this.chequeImage);
      });
      if(this.pagename == undefined){
      const tempData = this.modeOfPaymentForm.controls.chequeArray.value;
      // tempData.get('photo').setValue(this.chequeImage);
      let allChecksVal = [];
      allChecksVal = tempData;
      let totalamt = 0;
      allChecksVal.forEach(ele =>{
        totalamt = totalamt + parseInt(ele.amount);
        //
      });
      if(this.totalCheckAmount == totalamt){
        this.sumbitCheque();
      }else{
        this.apimicroservice.openSnackbar("Total amount payable should be equal to  "+this.totalCheckAmount+" Rs");
      }
    }else {
      //
      this.sumbitCheque();
    }
    }
  }

  sumbitCheque(){
    const tempData = this.modeOfPaymentForm.controls.chequeArray.value;
    //
    this.dialogRef.close({data:tempData});
  }
 
  findDuplicate(chqno,p):boolean{
    const creds = this.modeOfPaymentForm.controls.chequeArray as FormArray;
    let myArr = creds.controls;
    let test = myArr.filter(data => data['controls']['chequeNo'].value == chqno && chqno !== null)

    if(test.length > 1){
      this.disableSubmit = true;
      return true;
    }else{
      this.disableSubmit = false;
      return false;
    }
 
  }
  onChange($event: Event) {
    const file = ($event.target as HTMLInputElement).files[0];
    const fileName = file.name;
    this.errormessage = '';
    let ext = fileName.substring(fileName.lastIndexOf(".") + 1);
    if (
      ext.toLowerCase() == "png" ||
      ext.toLowerCase() == "jpg" ||
      ext.toLowerCase() == "jpeg" ||
      ext.toLowerCase() == "csv" ||
      ext.toLowerCase() == "xlsx" ||
      ext.toLowerCase() == "pdf" ||
      ext.toLowerCase() == "docx" 
    ) { 
      this.convertToBase64(file);
    }
  }
  convertToBase64(file: File) {
    const observable = new Observable((subscriber: Subscriber<any>) => {
      this.readFile(file, subscriber);
    });
    observable.subscribe((d) => {
      this.chequeImage = d;    
    });
  }
  readFile(file: File, subscriber: Subscriber<any>) {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      subscriber.next(fileReader.result);
      subscriber.complete();
    };
    fileReader.onerror = (error) => {
      subscriber.error(error);
      subscriber.complete();
    };
  }
  openSnackbar(message: string) {
    return this._snackBar.open(message, "close",
      { verticalPosition: 'bottom', horizontalPosition: 'right', duration: 2300, panelClass: ['snackbarStyle'] });
  }
}
