import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  FormArray
} from "@angular/forms";
import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { AfterViewInit, Directive, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner"; import { PopUpBranchComponent } from "../pop-up-branch/pop-up-branch.component";
import { PopUpPatientComponent } from "../pop-up-patient/pop-up-patient.component";
import { PopupCompanyComponent } from "../popup-company/popup-company.component";
import { PopupPlaceOrderMailComponent } from "../popup-place-order-mail/popup-place-order-mail.component";
import { PopUpDeviceComponent } from "../pop-up-device/pop-up-device.component";
import { PopupPlaceOrderAccessoriesComponent } from "../popup-place-order-accessories/popup-place-order-accessories.component";
import { PopupPlaceOrderTypeComponent } from "../popup-place-order-type/popup-place-order-type.component";
import { PopupPlaceOrderSizeComponent } from "../popup-place-order-size/popup-place-order-size.component";
import { PopupPlaceOrderPowerComponent } from "../popup-place-order-power/popup-place-order-power.component";
import { PopupHaColorComponent } from "../popup-ha-color/popup-ha-color.component";
import { PopupBillingcompanyComponent } from "../popup-billingcompany/popup-billingcompany.component";

@Component({
  selector: 'app-place-order',
  templateUrl: './place-order.component.html',
  styleUrls: ['./place-order.component.scss']
})
export class PlaceOrderComponent implements OnInit {
  errormessage: string;
  searchText: '';
  orderId: any;
  allState = [
    { name: 'Andaman and Nicobar Islands', value: 'AN' },
    { name: 'Andhra Pradesh', value: 'AP' },
    { name: 'Arunachal Pradesh', value: 'AR' },
    { name: 'Assam', value: 'AS' },
    { name: 'Bihar', value: 'BR' },
    { name: 'Chandigarh', value: 'CH' },
    { name: 'Chhattisgarh', value: 'CT' },
    { name: 'Dadra and Nagar Haveli', value: 'DN' },
    { name: 'Daman and Diu', value: 'DD' },
    { name: 'Delhi', value: 'DL' },
    { name: 'Goa', value: 'GA' },
    { name: 'Gujarat', value: 'GJ' },
    { name: 'Haryana', value: 'HR' },
    { name: 'Himachal Pradesh', value: 'HP' },
    { name: 'Jammu and Kashmir', value: 'JK' },
    { name: 'Jharkhand', value: 'JH' },
    { name: 'Karnataka', value: 'KA' },
    { name: 'Kerala', value: 'KL' },
    { name: 'Ladakh', value: 'LA' },
    { name: 'Lakshadweep', value: 'LD' },
    { name: 'Madhya Pradesh', value: 'MP' },
    { name: 'Maharashtra', value: 'MH' },
    { name: 'Manipur', value: 'MN' },
    { name: 'Meghalaya', value: 'ML' },
    { name: 'Mizoram', value: 'MZ' },
    { name: 'Nagaland', value: 'NL' },
    { name: 'Odisha', value: 'OR' },
    { name: 'Puducherry', value: 'PY' },
    { name: 'Punjab', value: 'PB' },
    { name: 'Rajasthan', value: 'RJ' },
    { name: 'Punjab', value: 'PB' },
    { name: 'Rajasthan', value: 'RJ' },
    { name: 'Sikkim', value: 'SK' },
    { name: 'Tamil Nadu', value: 'TN' },
    { name: 'Telangana', value: 'TG' },
    { name: 'Tripura', value: 'TR' },
    { name: 'Uttar Pradesh', value: 'UP' },
    { name: 'Uttarakhand', value: 'UT' },
    { name: 'West Bengal', value: 'WB' },
  ]
  filteredList: { name: string; value: string; }[];
  gstCheckbox = true;
  userType: string;
  clinicRef: string;
  clinicId: string;
  BranchList: any;
  filteredListBranch: any;
  superBranchId: any;
  branchRef: string;
  branchName: string;
  patientList: any;
  filteredListPatient: any;
  clinicSelect = false;
  pid: any;
  haDeviceList: any;
  deviceList = [];
  filteredDeviceList: any[];
  filteredListHADevice = [];
  filteredListHADevice2 = [];
  preId: any;
  companyList: any;
  filteredListCompany: any;
  companyName: any;
  canNo: any;
  placeOrderCreate = true;
  placeOrderUpdate = true;
  address: any;
  contact: any;
  totalAmount: number;
  branchCreate = true;
  companyCreate = true;
  canNumber: any;
  totalQty = 0;
  //totalqty: any;
  leftQtyVal: any;
  rightQtyVal: any;
  haDeviceList2: any;
  bID: any;
  make: any;
  lastId: any;
  sizeList: any;
  filteredListSize: any;
  powerList: any;
  filteredListPower: any;
  filteredListAccessoriesType: any;
  AccessoriesTypeList: any;
  AccessoriesList: any;
  filteredListAccessories: any;
  haColorList: any;
  filteredListHaColor: any;
  cName: any;
  clinicName: any;
  clinicPhone: any;
  haColor: any;
  notes: any;
  isPrepopulate = false;
  billingCompanyList: any;
  filteredListBillingCompany: any;
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService
  ) { }
  placeOrderForm: FormGroup;
  DeviceList: any;
  DeviceList2: any;
  filteredListDevice: any;
  filteredListDevice2: any;
  status: any;
  submitButton = true;
  updateButton = false;
  deleteButton = false;
  cancelButton = false;
  loggedId: any;
  myimage;
  date: any;
  size = 10;
  page = 1;
  InquiryData: any;
  totalLength: any;
  listofPlaceOrder: any;
  userPermission: any;
  billingCreate=true;
  // InquiryCreate= true;
  // InquiryUpdate= true;
  isSuperAdmin = true;
  ngOnInit(): void {
    this.isPrepopulate = false;
    this.totalQty = 0;
    this.page = 1;
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.date = new Date();
    this.filteredList = this.allState.slice();
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    this.userType = sessionStorage.getItem('userType');
    this.branchRef = sessionStorage.getItem('branchRef');
    this.branchName = sessionStorage.getItem('branchName');
    if (this.userType !== 'Super Admin') {
      this.isSuperAdmin = false;
      this.userPermission.forEach((element) => {
        if (element.pageName == 'Place Order' && element.create == false) {
          this.placeOrderCreate = false;
        }
        if (element.pageName == 'Place Order' && element.update == false) {
          this.placeOrderUpdate = false;
        }
        if (element.pageName == 'Branch' && element.create == false) {
          this.branchCreate = false;
        }
        if (element.pageName == 'Company Master' && element.create == false) {
          this.companyCreate = false;
        }
        if(element.pageName == 'Billing Company' && element.create == false)
        {
            this.billingCreate = false;  
        }
      });
    }
    let i = 0;
    this.placeOrderForm = this.fb.group({

      "placeOrderStatus": new FormControl(''),
      "branchWiseId": new FormControl(''),
      "date": new FormControl(''),
      "clinicRefId": new FormControl(''),
      "branchRefId": new FormControl(''),
      "branchName": new FormControl(''),
      "clinicName": new FormControl(''),
      "clinicPhone": new FormControl(''),
      "clinicAddress": new FormControl(''),
      "companyRefId": new FormControl('', Validators.required),
      "companyName": new FormControl(''),
      "companyAddress": new FormControl(''),
      "companyPhoneNumber": new FormControl(''),
      "canNumber": new FormControl(''),
      "make": new FormControl(''),
      // "total": new FormControl(''),
      "clinicMail": new FormControl(''),
      "companyMail": new FormControl(''),
      "createdAt": this.date,
      "searchPlaceOrder": new FormControl(''),
      "salesPersonName": new FormControl(''),
      "salesPersonNumber": new FormControl(''),
      "notes": new FormControl(''),
      "haColorRefId": new FormControl('',Validators.required),
      "haColorName": new FormControl(''),
      "billingCompanyRefId": new FormControl(''),
      "billingCompanyName": new FormControl(''),
      "HAModelDetails": this.fb.array([]),
      "HAModelReceiver": this.fb.array([]),
      "HAModelAccessories": this.fb.array([]),


    });
    let latest_date = this.datepipe.transform(this.date, 'dd/MM/yyyy');
    this.placeOrderForm.get("date").setValue(latest_date);
    this.placeOrderForm.get("placeOrderStatus").setValue('Pending');

    // this.placeOrderForm.get("total").setValue(0);
    this.fetchBillingCompanyList();
    this.fetchHaColorList();
    this.fetchPlaceOrderData();
    this.getLastId();
    this.fetchBranchList();
    this.fetchSizeList();
    this.getTypeAllData();
    this.fetchPowerList();
    this.getAccesoriesAllData();
    this.addRow(i);
    this.addRowReceiver(i);
    this.addRowAccessories(i);
    this.fetchModelList();
    this.fetchClinicList();
    this.fetchAllCompanies();
    this.getAllBranch();
    this.fetchModelLeftList();
    this.fetchModelList2();
  }
  fetchClinicList() {

    let pg = 1;
    let size = 10;
    let search = '';
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getClinicData(pg, size, search,clinicRef).subscribe(res => {
      // this.clinicData = res.data.clinicData;
      // this.totalLength=res.data.totalItems;
      this.preId = res.data.clinicData[0]._id;

      this.clinicPrepopulation(this.preId);

      if (res.status) {
        // this.apimicroservice.openSnackbar(res.message);

      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    });
  }
  clinicPrepopulation(id) {
    this.apimicroservice.getClinicDataById(id).subscribe(res => {

      let data = res.data[0];
      let payload = this.placeOrderForm.value;
      this.placeOrderForm.controls["clinicName"].setValue(data.clinicName);
      this.placeOrderForm.controls["clinicAddress"].setValue(data.clinicAddress);
      this.placeOrderForm.controls["clinicPhone"].setValue(data.clinicPhoneNumber);
      this.placeOrderForm.controls["clinicMail"].setValue(data.clinicEmail);
    });
  }
  getAllBranch() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      if (res.status) {
        let length = res.data.length - 1;
        this.placeOrderForm.controls["branchRefId"].setValue(res.data[length]._id);
        //
        if (this.userType == 'Super Admin') {
          //

          this.apimicroservice.getBranchById(res.data[length]._id).subscribe(res => {
            this.spinner.hide();
            this.placeOrderForm.get("branchName").setValue(res.data[length].branchName);
          });
        }
        this.branchClick(res.data[length]._id, res.data[length].branchName);

      }
    });
  }
  fetchBranchList() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      //
      if (res.status) {
        this.BranchList = res.data;
        this.filteredListBranch = this.BranchList.slice();
      } else {
        //  this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
  fetchSizeList() {
    this.apimicroservice.getSizeAllData().subscribe(res => {
      //
      if (res.status) {
        this.sizeList = res.data;
        this.filteredListSize = this.sizeList.slice();
      } else {
        //  this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
  fetchPowerList() {
    this.apimicroservice.getPowerAllData().subscribe(res => {
      if (res.status) {
        this.powerList = res.data;
        this.filteredListPower = this.powerList.slice();
      } else {
        //  this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
  fetchHaColorList() {
    this.apimicroservice.getAllHaColorlist().subscribe(res => {
      //
      if (res.status) {
        this.haColorList = res.data.data;
        this.filteredListHaColor = this.haColorList.slice();
      } else {
        //  this.apimicroservice.openSnackbar(res.message);
      }
    })
  }

  getTypeAllData() {
    this.apimicroservice.getTypeAllData().subscribe(res => {
      if (res.status) {
        this.AccessoriesTypeList = res.data;
        this.filteredListAccessoriesType = this.AccessoriesTypeList.slice();
      } else {
        //  this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
  getAccesoriesAllData() {
    this.apimicroservice.getAccesoriesPlaceOrderAllData().subscribe(res => {
      if (res.status) {
        this.AccessoriesList = res.data;
        this.filteredListAccessories = this.AccessoriesList.slice();
      } else {
        //  this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
  // submit(){
  //  const creds = this.placeOrderForm.controls.HAModelDetails as FormArray;  
  //  if (this.placeOrderForm.invalid || creds.invalid) {
  //    this.placeOrderForm.get("companyRefId").markAsTouched();

  //    creds.controls.forEach(element =>{
  //      element.get('model').markAsTouched();
  //      element.get('quantity').markAsTouched();

  //    });
  //    return;
  //  }
  // else if((creds.value[0].leftModel == ' ' && creds.value[0].leftQuantity==0) || (creds.value[0].rightModel == ' ' && creds.value[0].rightQuantity==0)){
  //   this.apimicroservice.openSnackbar("Quantity Must Greater than 0");
  //  }
  // else{
  //      if(creds.invalid && this.placeOrderForm.invalid)
  //      {
  //       this.placeOrderForm.get("companyRefId").markAsTouched();
  //        creds.controls.forEach(element =>{
  //          //
  //          //

  //        });

  //      }

  //  else{
  //     this.addPlaceOrder();
  //  }
  // }
  // }

  submit() {
    const creds = this.placeOrderForm.controls.HAModelDetails as FormArray;
    const creds1 = this.placeOrderForm.controls.HAModelAccessories as FormArray;
    const creds2 = this.placeOrderForm.controls.HAModelReceiver as FormArray;
    if (this.placeOrderForm.invalid || creds.invalid) {
      this.placeOrderForm.get("companyRefId").markAsTouched();
      this.placeOrderForm.get("salesPersonName").markAsTouched();
      this.placeOrderForm.get("haColorRefId").markAsTouched();
      this.placeOrderForm.get("billingCompanyRefId").markAsTouched();
      creds.controls.forEach(element => {
        element.get('deviceRefId').markAsTouched();
        element.get('deviceName').markAsTouched();
        element.get('qty').markAsTouched();
        element.get('focUnits').markAsTouched();
        element.get('totalNo').markAsTouched();
      });   

    }
    // else if (creds.invalid) {
    //   creds.controls.forEach(element => {
    //     element.get('deviceRefId').markAsTouched();
    //     element.get('deviceName').markAsTouched();
    //     element.get('qty').markAsTouched();
    //     element.get('focUnits').markAsTouched();
    //     element.get('totalNo').markAsTouched();
    //   });    
    // }
    
    else if ((creds.value[0].qty == ' ' && creds.value[0].qty == 0)) {
      this.apimicroservice.openSnackbar("Quantity Must Greater than 0");
    }
    else {
      this.addPlaceOrder();
    }
  }

  updatePlaceOrderData() {
    //  this.setValidor();
    const creds = this.placeOrderForm.controls.HAModelDetails as FormArray;
    if (this.placeOrderForm.invalid || creds.invalid) {
      this.placeOrderForm.get("companyRefId").markAsTouched();
      this.placeOrderForm.get("salesPersonName").markAsTouched();
      this.placeOrderForm.get("haColorRefId").markAsTouched();
      this.placeOrderForm.get("billingCompanyRefId").markAsTouched();
      creds.controls.forEach(element => {
        element.get('deviceRefId').markAsTouched();
        element.get('deviceName').markAsTouched();
        element.get('qty').markAsTouched();
        element.get('focUnits').markAsTouched();
        element.get('totalNo').markAsTouched();
      });
      return;
    } else {
      this.updatePlaceOrder();
    }
  }

  addPlaceOrder() {

    let payload = this.placeOrderForm.value;
    payload.createdAt = new Date();

    //);
    if (this.userType !== 'Super Admin') {
      payload.branchRefId = this.branchRef;
      payload.clinicRefId = this.clinicRef;
      payload.branchName = this.branchName;
    }

    let bar = new Promise<void>((resolve, reject) => {
      this.apimicroservice.savePlaceOrderData(payload).subscribe(res => {
        this.spinner.hide();
        resolve();
        if (res.status) {
          this.apimicroservice.openSnackbar(res.message);
          this.ngOnInit();
        } else {
          this.apimicroservice.openSnackbar(res.message);
        }
      }, (ERROR: HttpErrorResponse) => {
        this.apimicroservice.openSnackbar(ERROR.error.message);
        this.spinner.hide();
      });

    });

    bar.then(() => {
      let pg = 1;
      let size = 10;
      let search = '';
      let clinicRef= sessionStorage.getItem('clinicRef');
      let branchRef= sessionStorage.getItem('branchRef');
      let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
      let branchRefSuperAdmin= '';
      if (this.userType !== 'Super Admin') {
        this.apimicroservice.getPlaceOrderDetails(pg, size, search, branchRef, clinicRef).subscribe(res => {
          this.spinner.hide();
          //
          this.listofPlaceOrder = res.data.data;
          this.lastId = res.data.data[0]._id;
          this.totalLength = res.data.totalItems;     
          this.openPlaceOrderMailPopUp(payload.companyName,payload.clinicName,payload.clinicPhone,this.lastId,payload.canNumber,
            payload.haColorName,payload.notes);
          if (res.status) {
            // this.apimicroservice.openSnackbar(res.message);

          } else {
            //this.apimicroservice.openSnac   kbar(res.message);
          }
        });
      } else {
        this.apimicroservice.getPlaceOrderDetails(pg, size, search, branchRefSuperAdmin, clinicRefSuperAdmin).subscribe(res => {
          this.spinner.hide();
          //
          this.listofPlaceOrder = res.data.data;
          this.lastId = res.data.data[0]._id;
          this.openPlaceOrderMailPopUp(payload.companyName,payload.clinicName,payload.clinicPhone,this.lastId,payload.canNumber,
            payload.haColorName,payload.notes);
          this.totalLength = res.data.totalItems;
          if (res.status) {
            // this.apimicroservice.openSnackbar(res.message);

          } else {
            //this.apimicroservice.openSnackbar(res.message);
          }
        });

      }

    });


  }
  openSnackbar(message: string) {
    return this._snackBar.open(message, "close", {
      verticalPosition: "bottom",
      horizontalPosition: "right",
      duration: 2000,
      panelClass: ["snackbarStyle"],
    });
  }


  fetchPlaceOrderData() {
    let pg = 1;
    let size = 10;
    let search = '';
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    this.spinner.show();
    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getPlaceOrderDetails(pg, size, search, branchRef, clinicRef).subscribe(res => {
        this.spinner.hide();
        //
        this.listofPlaceOrder = res.data.data;
        this.totalLength = res.data.totalItems;
        if (res.status) {
          // this.apimicroservice.openSnackbar(res.message);

        } else {
          //this.apimicroservice.openSnac   kbar(res.message);
        }
      });
    } else {
      this.apimicroservice.getPlaceOrderDetails(pg, size, search, branchRefSuperAdmin, clinicRefSuperAdmin).subscribe(res => {
        this.spinner.hide();
        //
        this.listofPlaceOrder = res.data.data;
        this.totalLength = res.data.totalItems;
        if (res.status) {
          // this.apimicroservice.openSnackbar(res.message);

        } else {
          //this.apimicroservice.openSnackbar(res.message);
        }
      });

    }

  }
  branchClick(id, branchName) {

    this.placeOrderForm.controls["branchName"].setValue(branchName);

    this.apimicroservice.getBranchById(id).subscribe(res => {
      //

      let data = res.data[0];
      let payload = this.placeOrderForm.value;
      payload.clinicRefId = res.data[0].clinicRefId;
      this.placeOrderForm.controls["clinicRefId"].setValue(res.data[0].clinicRefId);
      if (this.updateButton == false) {
        this.spinner.show();
        this.apimicroservice.getLastPlaceOrderID(payload.branchRefId).subscribe(res => {
          //

          if (res['status']) {
            let InqIdd = res['data']['totalItems'];
            this.placeOrderForm.controls['branchWiseId'].setValue(InqIdd + 1);
            //this.bID=InqIdd + 1;
          } else {
            this.apimicroservice.openSnackbar("Something went wrong");
          }
          this.spinner.hide();
        })
      }
    });
  }
  openCopyPopUp() {
    this.openPlaceOrderMailPopUp(this.cName,this.clinicName,this.clinicPhone,this.orderId,this.canNumber,
      this.haColor,this.notes);
  }
  prepopulation(id) {
    this.isPrepopulate = true;
    this.pid = id; 

    this.apimicroservice.getPlaceOrderDataById(id).subscribe(res => {
      this.submitButton = false;
      this.updateButton = true; 
      //  this.deleteButton= true;
      this.cancelButton = true;

      let data = res.data[0];
      this.getCompany(data.companyRefId);
      this.clinicPrepopulation(data.clinicRefId);
      this.placeOrderForm = this.fb.group({
        "placeOrderStatus": new FormControl(data.placeOrderStatus),
        "branchWiseId": new FormControl(data.branchWiseId),
        "date": new FormControl(data.date), 
        "clinicRefId": new FormControl(data.clinicRefId),
        "branchRefId": new FormControl(data.branchRefId),
        "branchName": new FormControl(data.branchName),
        "clinicName": new FormControl(data.clinicName),
        "clinicPhone": new FormControl(data.clinicPhone),
        "clinicAddress": new FormControl(data.clinicAddress),
        "companyRefId": new FormControl(data.companyRefId, Validators.required),
        "companyName": new FormControl(data.companyName),
        "companyAddress": new FormControl(data.companyAddress),
        "companyPhoneNumber": new FormControl(data.companyPhoneNumber),
        "canNumber": new FormControl(data.canNumber),
        "make": new FormControl(data.make),
        "total": new FormControl(data.total),
        "clinicMail": new FormControl(data.clinicMail),
        "companyMail": new FormControl(data.companyMail),
        "salesPersonName": new FormControl(data.salesPersonName),
        "salesPersonNumber": new FormControl(data.salesPersonNumber),
        "searchPlaceOrder": new FormControl(''),
        "notes": new FormControl(data.notes),
        "haColorRefId": new FormControl(data.haColorRefId,Validators.required),
        "haColorName": new FormControl(data.haColorName),
        "billingCompanyRefId": new FormControl(data.billingCompanyRefId),
        "billingCompanyName": new FormControl(data.billingCompanyName),
        "HAModelDetails": this.fb.array([]),
        "HAModelReceiver": this.fb.array([]),
        "HAModelAccessories": this.fb.array([]),
        "updateddAt": new Date(),
      });
      this.onSelectCompany(data);
      this.cName = data.companyName;
      this.clinicName = data.clinicName;
      this.clinicPhone = data.clinicPhone;
      this.canNumber = data.canNumber;
      this.haColor = data.haColorName;
      this.notes = data.notes;
      this.orderId = id;
      this.superBranchId = data.branchWiseId;
      this.bID = data.branchWiseId;
      let preArr = [];
      data.HAModelDetails.forEach(element => {
        preArr.push(element);
        //
        const tmpDict = {};

        tmpDict['deviceName'] = new FormControl(element.deviceName);
        tmpDict['deviceRefId'] = new FormControl(element.deviceRefId, Validators.required);
        tmpDict['qty'] = new FormControl(element.qty, [Validators.required, Validators.pattern("^[0-9]*$")]);
        tmpDict['totalNo'] = new FormControl(element.totalNo, [Validators.required, Validators.pattern("^[0-9]*$")]);
        tmpDict['focUnits'] = new FormControl(element.focUnits, [Validators.pattern("^[0-9]*$")]);
        const creds = this.placeOrderForm.controls.HAModelDetails as FormArray;
        creds.push(new FormGroup(tmpDict));
        //
      });

      data.HAModelReceiver.forEach(element => {
        preArr.push(element);
        //
        const tmpDict = {};
        tmpDict['receivers'] = new FormControl(element.receivers);
        tmpDict['size'] = new FormControl(element.size);
        tmpDict['sizeRef'] = new FormControl(element.sizeRef);
        tmpDict['power'] = new FormControl(element.power);
        tmpDict['powerRef'] = new FormControl(element.powerRef);
        tmpDict['receiversQty'] = new FormControl(element.receiversQty);
        const credsReceiver = this.placeOrderForm.controls.HAModelReceiver as FormArray;
        credsReceiver.push(new FormGroup(tmpDict));
        //
      });

      data.HAModelAccessories.forEach(element => {
        preArr.push(element);
        //
        const tmpDict = {};
        tmpDict['accessories'] = new FormControl(element.accessories);
        tmpDict['accessoriesRef'] = new FormControl(element.accessoriesRef);
        tmpDict['accessoriesTypeRef'] = new FormControl(element.accessoriesTypeRef);
        tmpDict['accessoriesType'] = new FormControl(element.accessoriesType);
        tmpDict['accessoriesQty'] = new FormControl(element.accessoriesQty);
        const credsAccessories = this.placeOrderForm.controls.HAModelAccessories as FormArray;
        credsAccessories.push(new FormGroup(tmpDict));
        //
      });

    });


  }
  getCompany(id) {
    this.spinner.show();
    this.apimicroservice.getCompanyById(id).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        let data=res.data[0];
        this.placeOrderForm.controls['companyName'].setValue(data.companyName);
        this.placeOrderForm.controls['companyAddress'].setValue(data.companyAddress);
        this.placeOrderForm.controls['companyMail'].setValue(data.companyEmail);
        this.placeOrderForm.controls['companyPhoneNumber'].setValue(data.companyContact);
        this.placeOrderForm.controls['canNumber'].setValue(data.canNumber);
        this.placeOrderForm.controls['salesPersonName'].setValue(data.salesPersonName);
        this.placeOrderForm.controls['salesPersonNumber'].setValue(data.salesPersonNumber);
        this.placeOrderForm.controls['make'].setValue(data.make);
        
      } else {
      //  this.apimicroservice.openSnackbar(res.message);
      }
    }); 
  } 
 
  addNew() {
    this.placeOrderForm.reset();
    this.updateButton = false;
    this.deleteButton = false;
  }
  updatePlaceOrder() {
    this.spinner.show();
    let payload = this.placeOrderForm.value;
    if (this.userType !== 'Super Admin') {
      payload.branchRefId = this.branchRef;
      payload.clinicRefId = this.clinicRef;
      payload.branchName = this.branchName;
    }
    // payload.image = this.myimage;
    this.apimicroservice.updatePlaceOrderData(this.orderId, payload).subscribe(res => {
      this.spinner.hide();
      this.ngOnInit();
      if (res.status) {
        this.apimicroservice.openSnackbar(res.message);
        this.submitButton = true;
        this.updateButton = false;
        this.cancelButton = false;
        // this.deleteButton=false;
        //this.ngOnInit();
        // this.placeOrderForm.reset();
        // this.fetchInquiry();

      } else {
        this.apimicroservice.openSnackbar(res.message);
      }

    },
    );

  }

  getLastId() {
    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getLastPlaceOrderID(this.branchRef).subscribe(res => {
        //
        // if(res['data']['data'] == '')
        // {
        //   this.placeOrderForm.controls['branchWiseId'].setValue('1');
        // }
        if (res['status']) {
          let InqIdd = res['data']['totalItems'];
          // this.placeOrderForm.controls['branchWiseId'].setValue(InqIdd + 1);
          this.bID = InqIdd + 1;
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }

      })
    }
    else {
      this.apimicroservice.getLastPlaceOrderAll().subscribe(res => {
        //
        // if(res['data']['data'] == '')
        // {
        //   this.placeOrderForm.controls['branchWiseId'].setValue('1');
        // }
        if (res['status']) {
          let InqIdd = res['data']['totalItems'];
          this.superBranchId = InqIdd + 1;
          this.bID = InqIdd + 1;
          //this.placeOrderForm.controls['branchWiseId'].setValue(clientIdd + 1);
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }

      })
    }
  }


  onPageChange(event) {
    // this.spinner.show();
    this.page = event;
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    //pg = this.page;
    this.searchText = this.placeOrderForm.get('searchPlaceOrder').value;
    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getPlaceOrderDetails(this.page, this.size, this.searchText, branchRef, clinicRef).subscribe(res => {
        //this.spinner.hide();
        //
        if (res.status) {
          this.listofPlaceOrder = res.data.data;
          this.totalLength = res.data.totalItems;
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }

      })
    } else {
      this.apimicroservice.getPlaceOrderDetails(this.page, this.size, this.searchText, branchRefSuperAdmin, clinicRefSuperAdmin).subscribe(res => {
        // this.spinner.hide();
        //
        if (res.status) {
          this.listofPlaceOrder = res.data.data;
          this.totalLength = res.data.totalItems;
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }

      })

    }

  }
  onKeyUpEvent(event: any) {
    let page = 1;
    let size = 10;
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    this.searchText = this.placeOrderForm.get('searchPlaceOrder').value;
    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getPlaceOrderDetails(page, size, this.searchText, branchRef, clinicRef).subscribe(res => {
        // //
        //

        if (res.status) {
          this.listofPlaceOrder = res.data.data;
          this.totalLength = res.data.totalItems;
          this.page = res.data.pageNumber;
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }

      })
    } else {
      this.apimicroservice.getPlaceOrderDetails(page, size, this.searchText, branchRefSuperAdmin, clinicRefSuperAdmin).subscribe(res => {
        ////
        if (res.status) {
          this.listofPlaceOrder = res.data.data;
          this.totalLength = res.data.totalItems;
          this.page = res.data.pageNumber;
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }

      })
    }

  }

  redirect(to): void {
    this.router.navigate([to]);
  }

  cancelUpdate() {
    this.placeOrderForm.reset();
    this.submitButton = true;
    this.updateButton = false;
    this.cancelButton = false;
    this.ngOnInit();
  }

  toPlaceOrderForm() {
    document.getElementById("toPlaceOrderForm").scrollIntoView();
  }
  toPlaceOrderList() {
    document.getElementById("toPlaceOrderList").scrollIntoView();
  }

  openBranchPopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpBranchComponent,
      {
        width: "1200px",
      });

    dialogRef3.afterClosed().subscribe((result) => {
      let clinicRef= sessionStorage.getItem('clinicRef');
      this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
        //
        if (res.status) {
          this.BranchList = res.data;
          this.filteredListBranch = this.BranchList.slice();
          this.branchClick(res.data[0]._id, res.data[0].branchName);
          this.placeOrderForm.get("branchRefId").setValue(res.data[0]._id);
        } else {
          //  this.apimicroservice.openSnackbar(res.message);
        }
      })
      // this.fetchBranchList();  
    });
  }
  addRow(i) {
    const creds = this.placeOrderForm.controls.HAModelDetails as FormArray;
    creds.push(
      this.fb.group({
        "deviceName": new FormControl(''),
        "deviceRefId": new FormControl(Validators.required),
        //"deviceRefId": new FormControl('',),  
        "qty": new FormControl(1, [Validators.required, Validators.pattern("^[0-9]*$")]),
        "totalNo": new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$")]),
        "focUnits": new FormControl(''),
        toggle: false

      })
    );
  }
  addRowReceiver(i) {
    const creds = this.placeOrderForm.controls.HAModelReceiver as FormArray;
    creds.push(
      this.fb.group({
        "receivers": new FormControl(''),
        "size": new FormControl(''),
        "sizeRef": new FormControl(),
        "power": new FormControl(''),
        "powerRef": new FormControl(),
        "receiversQty": new FormControl(''),
        toggle: false
      })
    );
  }
  addRowAccessories(i) {
    const creds = this.placeOrderForm.controls.HAModelAccessories as FormArray;
    creds.push(
      this.fb.group({
        "accessories": new FormControl(''),
        "accessoriesRef": new FormControl(),
        "accessoriesType": new FormControl(''),
        "accessoriesTypeRef": new FormControl(),
        "accessoriesQty": new FormControl(''),
        toggle: false
      })
    );
  }
  deleteRow(index) {
    (<FormArray>this.placeOrderForm.get("HAModelDetails")).removeAt(index);
    const tempData = this.placeOrderForm.controls.HAModelDetails.value;
  }
  deleteRowReceiver(index) {
    (<FormArray>this.placeOrderForm.get("HAModelReceiver")).removeAt(index);
    const tempData = this.placeOrderForm.controls.HAModelReceiver.value;
  }
  deleteRowAccessories(index) {
    (<FormArray>this.placeOrderForm.get("HAModelAccessories")).removeAt(index);
    const tempData = this.placeOrderForm.controls.HAModelAccessories.value;
  }
  fetchModelList() {
    let branchRef = '';

    this.apimicroservice.getLastCustomHAAll().subscribe(res => {
      if (res.status) {
        //  / this.haDeviceList = res.data.data;
        this.haDeviceList = [];
        this.haDeviceList2 = [];
        res.data.data.forEach(element => {
          if (element.leftModel !== '') {

            this.haDeviceList.push({ "leftModel": element.leftModel, "_id": element._id });
          }
          if (element.rightModel !== '') {
            this.haDeviceList2.push({ "rightModel": element.rightModel, "_id": element._id });
          }

        });
        this.filteredListHADevice = this.haDeviceList.slice();
        this.filteredListHADevice2 = this.haDeviceList2.slice();
      } else {
        // this.apimicroservice.openSnackbar(res.message);
      }
    })


  }
  onModelChange(id, mname) {
    //
    //
  }
  openCompanyPopUp() {
    const dialogRef3 = this.dialog.open(PopupCompanyComponent,
      {
        width: "1000px",
      });

    dialogRef3.afterClosed().subscribe((result) => {
      let clinicRef= sessionStorage.getItem('clinicRef');
      let branchRef= sessionStorage.getItem('branchRef');
      this.apimicroservice.getAllCompanylist(branchRef,clinicRef).subscribe(res => {
        //
        if (res.status) {
          this.companyList = res.data.data;
          this.filteredListCompany = this.companyList.slice();
          this.onSelectCompany(this.companyList[0]);

          this.placeOrderForm.get("companyRefId").setValue(this.companyList[0]._id);
        } else {
          //  this.apimicroservice.openSnackbar(res.message);
        }
      })
    });
  }
  openHAColorPopUp() {
    const dialogRef3 = this.dialog.open(PopupHaColorComponent,
      {
        width: "400px",
      });
    dialogRef3.afterClosed().subscribe((result) => {
      this.apimicroservice.getAllHaColorlist().subscribe(res => {
        //
        if (res.status) {
          this.haColorList = res.data.data;
          this.filteredListHaColor = this.haColorList.slice();
          this.onSelectHaColor(this.haColorList[0]);
          this.placeOrderForm.get("companyRefId").setValue(this.haColorList[0]._id);
        } else {
          //  this.apimicroservice.openSnackbar(res.message);
        }
      })
    });
  }
  fetchAllCompanies() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    this.apimicroservice.getAllCompanylist(branchRef,clinicRef).subscribe(res => {
      
      if (res.status) {
        this.companyList = res.data.data;  
        this.filteredListCompany = this.companyList.slice();
        let lenght = res.data.totalItems-1;
        this.placeOrderForm.controls['companyRefId'].setValue(res.data.data[lenght]._id);
        this.onSelectCompany(res.data.data[lenght]);
      } else {
        //  this.apimicroservice.openSnackbar(res.message);
      }
    })
  }

  onSelectCompany(e) {
    //

    this.placeOrderForm.controls['companyName'].setValue(e.companyName);
    this.placeOrderForm.controls['companyAddress'].setValue(e.companyAddress);
    this.placeOrderForm.controls['companyPhoneNumber'].setValue(e.companyContact);
    this.placeOrderForm.controls['canNumber'].setValue(e.canNumber);
    this.placeOrderForm.controls['companyMail'].setValue(e.companyEmail);
    this.placeOrderForm.controls['make'].setValue(e.make);
    this.placeOrderForm.controls['salesPersonName'].setValue(e.salesPersonName);
    this.placeOrderForm.controls['salesPersonNumber'].setValue(e.salesPersonNumber);
  }
  onSelectHaColor(e) {
    this.placeOrderForm.controls['haColorName'].setValue(e.HaColorName);
  }
  // changeRightQty(event : any,i){
  //   this.rightQtyVal = (<FormArray>this.placeOrderForm.get("HAModelDetails")).at(i).get('rightQuantity').value;
  //   this.rightQtyVal = parseInt(this.rightQtyVal);
  //   //
  //   let serviceControl = (<FormArray>this.placeOrderForm.get("HAModelDetails")).at(i);
  //    if(this.rightQtyVal == "0"){
  //           this.apimicroservice.openSnackbar("Enter Quantity Greater than 0");
  //           serviceControl.get('rightQuantity').setValue("");
  //         }
  //     const tempData= this.placeOrderForm.controls.HAModelDetails.value;
  //     //
  //     this.totalQty = 0;   
  //     tempData.forEach(element =>{
  //       if((parseInt(element.leftQuantity) >= 0) && (parseInt(element.rightQuantity) >= 0)){
  //         this.totalQty = this.totalQty + parseInt(element.leftQuantity) + parseInt(element.rightQuantity)
  //       }
  //     })
  //     this.placeOrderForm.controls['total'].setValue(this.totalQty);
  //   }

  // changeLeftQty(event : any,i){   
  //     this.leftQtyVal = (<FormArray>this.placeOrderForm.get("HAModelDetails")).at(i).get('leftQuantity').value;
  //     this.leftQtyVal = parseInt(this.leftQtyVal);
  //   //
  //   let serviceControl = (<FormArray>this.placeOrderForm.get("HAModelDetails")).at(i);

  //     if(this.leftQtyVal == "0"){
  //       this.apimicroservice.openSnackbar("Enter Quantity Greater than 0");
  //       serviceControl.get('leftQuantity').setValue("");
  //       }

  //     const tempData= this.placeOrderForm.controls.HAModelDetails.value;
  //     //
  //     this.totalQty = 0;
  //     tempData.forEach(element =>{      
  //       if((parseInt(element.leftQuantity) >= 0) && (parseInt(element.rightQuantity) >= 0)){
  //         this.totalQty = this.totalQty + parseInt(element.leftQuantity) + parseInt(element.rightQuantity)
  //       }
  //     })
  //     this.placeOrderForm.controls['total'].setValue(this.totalQty);
  //   }

  globalPrint() {
    let currentDate = new Date();
    let date = currentDate.toLocaleDateString('en-GB');
    let titleName = 'Place Order Form.pdf' + date
    var tempTitle = document.title;
    document.title = titleName;
    window.print();
    document.title = tempTitle;
  }
  WhatsApp() {
    let payload = this.placeOrderForm.value;
    let num = payload.companyPhoneNumber;
    if (num.length == 10) {
      sessionStorage.setItem('whatsAppNumber', payload.companyPhoneNumber);
      let mobileNumber = sessionStorage.getItem('whatsAppNumber');
      window.open("https://wa.me/91" + mobileNumber);
    }
    else {
      this.placeOrderForm.get("companyPhoneNumber").markAsTouched();
    }
  }

  WhatsApp1() {
    let payload = this.placeOrderForm.value;
    let num = payload.clinicPhone;
    if (num.length == 10) {
      sessionStorage.setItem('whatsAppNumber', payload.clinicPhone);
      let mobileNumber = sessionStorage.getItem('whatsAppNumber');
      window.open("https://wa.me/91" + mobileNumber);
    }
    else {
      this.placeOrderForm.get("clinicPhone").markAsTouched();
    }
  }

  openPlaceOrderMailPopUp(cName, clinicName, clinicPhone, lastID,canNumber,haColor,notes) {
    const dialogRef3 = this.dialog.open(PopupPlaceOrderMailComponent, {
      width: "50%",
      data: { 
        companyName: cName, 
        clinicName: clinicName,
        clinicPhone: clinicPhone,
        lastId: lastID,
        canNumber:canNumber,
        haColor:haColor,
        notes:notes
      },
    });
  }
  onback() {
    this.apimicroservice.back()
  }


  // onLeftModelChange(event,id,i){
  //   //
  //   let str = event.target.value;
  //   let leftModel = event.target.value;
  // } 
  // onRightModelChange(event,id,i){
  //    let str = event.target.value;
  //    let rightModel = event.target.value;
  // }

  fetchModelLeftList() {
    let branchRef= sessionStorage.getItem('branchRef');
    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getAllDeviceList(branchRef).subscribe(res => {
        if (res.status) {
          this.DeviceList = res.data.data;
          this.filteredListDevice = this.DeviceList.slice();
        } else {
          //this.apimicroservice.openSnackbar(res.message);
        }
      })
    }
    else {
      this.apimicroservice.getAllDeviceList(branchRef).subscribe(res => {
        if (res.status) {
          this.DeviceList = res.data.data;
          this.filteredListDevice = this.DeviceList.slice();
        } else {
          // this.apimicroservice.openSnackbar(res.message);
        }
      })
    }

  }

  fetchModelList2() {
    let branchRef= sessionStorage.getItem('branchRef');
    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getAllDeviceList(branchRef).subscribe(res => {
        if (res.status) {
          this.DeviceList2 = res.data.data;
          this.filteredListDevice2 = this.DeviceList2.slice();
        } else {
          // this.apimicroservice.openSnackbar(res.message);
        }
      })
    }
    else {
      this.apimicroservice.getAllDeviceList(branchRef).subscribe(res => {
        if (res.status) {
          this.DeviceList2 = res.data.data;
          this.filteredListDevice2 = this.DeviceList2.slice();
        } else {
          //this.apimicroservice.openSnackbar(res.message);
        }
      })
    }

  }

  selectDevice(model, id, i) {
    let serialInfo = (<FormArray>this.placeOrderForm.get("HAModelDetails")).at(i);
    serialInfo.patchValue({
      deviceName: model,
      deviceRefId: id,
    });
  }
  openDevicePopUp() {
    //localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpDeviceComponent,
      {
        width: "1200px",
      });
    dialogRef3.afterClosed().subscribe((result) => {

      this.apimicroservice.getAllDevice().subscribe(res => {
        if (res.status) {
          this.deviceList = res.data.data;
          this.filteredDeviceList = this.deviceList.slice();
          // this.selectDevice(this.deviceList[0].model, this.deviceList[0]._id);
          // this.placeOrderForm.get("leftModel").setValue(this.deviceList[0]._id);
        }
      });

    });
  }

  openRightDevicePopUp() {
    //localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpDeviceComponent,
      {
        width: "1200px",
      });
    dialogRef3.afterClosed().subscribe((result) => {

      this.apimicroservice.getAllDevice().subscribe(res => {
        if (res.status) {
          this.deviceList = res.data.data;
          this.filteredDeviceList = this.deviceList.slice();
          // this.selectDevice(this.deviceList[0].model, this.deviceList[0]._id);
          // this.placeOrderForm.get("leftModel").setValue(this.deviceList[0]._id);
        }
      });

    });
  }
  openAccessoriesPopUp() {
    localStorage.clear();
    const dialogRef6 = this.dialog.open(PopupPlaceOrderAccessoriesComponent,
      {
        width: "420px",
      });
    dialogRef6.afterClosed().subscribe((result) => {
      this.getAccesoriesAllData();
    })
  }
  openOrderTypePopUp() {
    localStorage.clear();
    const dialogRef6 = this.dialog.open(PopupPlaceOrderTypeComponent,
      {
        width: "420px",
      });
    dialogRef6.afterClosed().subscribe((result) => {
      this.getTypeAllData();
    })
  }
  openOrderSizePopUp() {
    localStorage.clear();
    const dialogRef6 = this.dialog.open(PopupPlaceOrderSizeComponent,
      {
        width: "420px",
      });
    dialogRef6.afterClosed().subscribe((result) => {
      this.fetchSizeList();
    })
  }
  openPowerPopUp() {
    localStorage.clear();
    const dialogRef6 = this.dialog.open(PopupPlaceOrderPowerComponent,
      {
        width: "420px",
      });
    dialogRef6.afterClosed().subscribe((result) => {
      this.fetchPowerList();
    })
  }
  selectSize(id, size, i) {
    let serialInfo = (<FormArray>this.placeOrderForm.get("HAModelReceiver")).at(i);
    serialInfo.patchValue({
      sizeRef: id,
      size: size,
    });
  }
  selectPower(id, power, i) {
    let serialInfo = (<FormArray>this.placeOrderForm.get("HAModelReceiver")).at(i);
    serialInfo.patchValue({
      powerRef: id,
      power: power,
    });
  }
  selectType(id, accessoriesType, i) {
    let serialInfo = (<FormArray>this.placeOrderForm.get("HAModelAccessories")).at(i);
    serialInfo.patchValue({
      accessoriesTypeRef: id,
      accessoriesType: accessoriesType,
    });
  }
  selectAccessories(id, accessories, i) {
    let serialInfo = (<FormArray>this.placeOrderForm.get("HAModelAccessories")).at(i);
    serialInfo.patchValue({
      accessoriesRef: id,
      accessories: accessories,
    });
  }
  totalCal(i) {
    //

    let info = (<FormArray>this.placeOrderForm.get("HAModelDetails")).at(i);
    let qty = (<FormArray>this.placeOrderForm.get("HAModelDetails")).at(i).get('qty').value;
    let focUnits = (<FormArray>this.placeOrderForm.get("HAModelDetails")).at(i).get('focUnits').value;

    let total =  parseInt(qty) + parseInt(focUnits);
    info.patchValue({
      totalNo: total,
    });
  }
  printForm() {
    this.router.navigate(['place-order-print',this.pid]);
   }
   fetchBillingCompanyList(){
    let id='';
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getBillingDataById(id,clinicRef).subscribe(res => {
      //
      this.billingCompanyList = res.data;
      this.filteredListBillingCompany= this.billingCompanyList.slice();
      let lenght = res.data.length-1;  
      this.companyClick(res.data[lenght]._id,res.data[lenght].companyName);
      this.placeOrderForm.get("billingCompanyRefId").setValue(res.data[lenght]._id);
      if(res.status){
        //this.apimicroservice.openSnackbar(res.message);
      }else{ 
        //this.apimicroservice.openSnackbar(res.message);
      }
    });
  }
  companyClick(bid,bcname){
    this.placeOrderForm.controls['billingCompanyRefId'].setValue(bid);
    this.placeOrderForm.controls['billingCompanyName'].setValue(bcname);
  }
  openBillingCompPopUp(){
    const dialogRef3 = this.dialog.open(PopupBillingcompanyComponent,
      {
        width: "1200px",
      });
      dialogRef3.afterClosed().subscribe((result) => {
        let id='';
        let clinicRef= sessionStorage.getItem('clinicRef');
      this.apimicroservice.getBillingDataById(id,clinicRef).subscribe(res => {
        if(res.status){
          //this.apimicroservice.openSnackbar(res.message);
          //
          this.billingCompanyList = res.data;
          this.filteredListBillingCompany= this.billingCompanyList.slice();
         this.companyClick(res.data[0]._id,res.data[0].companyName);
         this.placeOrderForm.get("billingCompanyRefId").setValue(res.data.data[0]._id);
        }else{
          //this.apimicroservice.openSnackbar(res.message);
        }
      });
        //this.fetchBillingCompanyList();  
       });
  }
}
