import { Component, OnInit } from "@angular/core";
import {
  FormGroup, FormControl, Validators, FormBuilder,
} from "@angular/forms";
import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { DatePipe } from "@angular/common";
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from "@angular/material/snack-bar";
import { AfterViewInit, Directive, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EventEmitter, Output } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { Router,ActivatedRoute } from "@angular/router"; 
@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit {
  @Output() next = new EventEmitter<void>();

  size = 10;
  page = 1;
  totalLength: any;
  searchText: any;
  branchRef: string;
  clinicRef: string;
  userType: string;
  noteData: any;
  pId: any;
  paientdetails: any;
  patientName: any;
  patientRefId: any;
  noteDataLenght=false;
  notesId: any;
  pName: string;
  userRefId: string;
  uName: any;

  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    public route : ActivatedRoute
  ) { }
  notesMasterForm: FormGroup;
  status: any;
  submitButton = true;
  updateButton = false;
  deleteButton = false;
  BranchList: any;

  resetButton = true;
  cancelButton = false;
  loggedId: any;
  myimage;
  date: any; 
  userPermission:any;
  notesCreate= true;
  notesUpdate= true;
  isSuperAdmin= true;
  serviceTypeArr = [];
  filteredList = [];
  filteredList1 = [];
  ngOnInit() {
    this.page=1;
    this.noteDataLenght =false;
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.userType = sessionStorage.getItem('userType');
    this.userRefId = sessionStorage.getItem('userRef');
    this.date = new Date();
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    if(this.userType !== 'Super Admin'){
      this.isSuperAdmin= false;
    this.userPermission.forEach((element) => {
      if(element.pageName == 'Notes' && element.create == false)
      {
          this.notesCreate = false;  
      }
      if(element.pageName == 'Notes' && element.update == false)
      {
          this.notesUpdate = false;  
      }  
    });
  }
    this.notesMasterForm = this.fb.group({
      // "patientId": new FormControl(''),
      "patientName": new FormControl(''),
      "patientRefId": new FormControl(''),
      "userRefId": new FormControl(''),
      "userName": new FormControl(''),
      "noteDate": new FormControl(this.date),
      "notes": new FormControl('', [Validators.required]),
      "createdAt": this.date,
      "searchNotes": new FormControl('')
    });
    this.route.queryParams.forEach(params => {
      this.pId = params.PatientId;
      //
      this.patientRefId = this.pId;
      this.fetchNotesData(); 
      // this.getPatient(this.patientRefId);
      this.getUser(this.userRefId);
    });
  } 
  // getPatient(id){ 
  //   this.apimicroservice.getPatientById(id).subscribe(res => { 
  //       this.pName =res.data[0].firstName + ' ' + res.data[0].lastName;
  //       //    
  //    });
  // }
  getUser(id){  
    this.apimicroservice.getUserById(id).subscribe(res => { 
        this.uName =res.data[0].userName;
     });
  }

  submitNotes() {
    if (this.notesMasterForm.invalid) {
      this.notesMasterForm.get("notes").markAsTouched();
      return;
    } else {
      this.submitData();
    }
  }
  updateNotesData() {
    if (this.notesMasterForm.invalid) {
      this.notesMasterForm.get("notes").markAsTouched();
      return;
    } else {
      this.updateData();
    }
  }

  submitData() {
    this.spinner.show();
    let payload = this.notesMasterForm.value;
    payload.createdAt = new Date();
    payload.patientName = this.patientName;
    payload.patientRefId = this.patientRefId;
    payload.userRefId = this.userRefId;
    payload.userName = this.uName;
    this.apimicroservice.submitNotes(payload).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.apimicroservice.openSnackbar(res.message);
        this.ngOnInit();
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }

    },(ERROR:HttpErrorResponse) => {
      this.apimicroservice.openSnackbar(ERROR.error.message);
     this.spinner.hide();
  });
  }
  updateData() {
    let payload = this.notesMasterForm.value;
   this.spinner.show();
   payload.updatedAt = new Date();
    this.apimicroservice.updateNotesData(this.notesId,payload).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.apimicroservice.openSnackbar(res.message);
        this.submitButton = true;
        this.updateButton = false;
        this.cancelButton=false;
        this.ngOnInit();
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    },(ERROR:HttpErrorResponse) => {
      this.apimicroservice.openSnackbar(ERROR.error.message);
     this.spinner.hide();
  });
  }

  fetchNotesData() { 
    this.spinner.show();
      this.apimicroservice.getNotesDataByPId(this.patientRefId).subscribe(res => {
        this.spinner.hide(); 
        this.noteData=[];
        if(res.message == 'No Data Found')
        {
          this.noteDataLenght =true;
        }
        else
        {
          this.noteData = res.data;
          this.noteDataLenght =false;
        }
        
      //  this.totalLength = res.data.totalItems;
      }); 
  }
  prepopulation(id) {
    this.spinner.show();
    this.apimicroservice.getNotesDataById(id).subscribe(res => {
      this.submitButton = false;
      this.updateButton = true;
      this.deleteButton = true;
      this.resetButton = false;
      this.spinner.hide();
      this.cancelButton = true;
      let data = res.data[0];
      this.notesMasterForm = this.fb.group({
        "patientName": new FormControl(data.patientName),
        "patientRefId": new FormControl(data.patientRefId),
        "userRefId": new FormControl(data.userRefId),
        "userName": new FormControl(data.userName),
        "noteDate": new FormControl(data.noteDate),
        "notes": new FormControl(data.notes,[Validators.required]),
        "updatedAt": this.date,
        "searchNotes": new FormControl('')
      });
       this.notesId = data._id;
    });
  }

  onPageChange(event) {
    this.spinner.show();
    this.page = event;
    this.searchText = this.notesMasterForm.get('searchNotes').value;
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let userRef='';

      this.apimicroservice.getServiceData(this.page,this.size,this.searchText,branchRef,clinicRef).subscribe(res => {
        this.spinner.hide();
        if (res.status) {
          this.noteData = res.data.data;
          this.totalLength = res.data.totalItems;
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
  
      })
  }
  onKeyUpSearch(event: any) {
    this.searchText = this.notesMasterForm.get('searchNotes').value;
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let userRef='';

      this.apimicroservice.getServiceData(this.page,this.size,this.searchText,branchRef,clinicRef).subscribe(res => {
        //
        if (res.status) {
          this.noteData = res.data.data;
          this.totalLength = res.data.totalItems;
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
      })  
  }

  redirect(to): void {
    this.router.navigate([to]);
  }
  cancelUpdate(){  
    this.notesMasterForm.reset();
    this.submitButton=true;
    this.updateButton=false;
    this.cancelButton=false;
  }
  toServicesForm() {
    document.getElementById("toServicesForm").scrollIntoView();
  }
  toServicesList() {
    document.getElementById("toServicesList").scrollIntoView();
  }
  onback() { 
    this.apimicroservice.back()
  } 
}