import { Component, Inject, OnInit } from "@angular/core";
import {FormGroup,FormBuilder,FormControl} from '@angular/forms';
import { ApiServiceService } from '../api-service.service';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-popup-items-collected',
  templateUrl: './popup-items-collected.component.html',
  styleUrls: ['./popup-items-collected.component.scss']
})
export class PopupItemsCollectedComponent implements OnInit {
  itemId:any;
  addNewItem = false;
  popupItemsCollectedForm: FormGroup;
  size1:number = 10;
  page1:number = 1;
  variables1: any;
  filteredList: any;
  variables2: any;
  filteredList2: any;
  selectedItemList = [];
  tempArr = [];
  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<PopupItemsCollectedComponent>,@Inject(MAT_DIALOG_DATA) public data:any,
    public apimicroservice: ApiServiceService,
) { dialogRef.disableClose = true;}

  ngOnInit() {
   //this.getDataById();
   this.addNewItem = false;
   this.itemId=this.data.id;
    this.popupItemsCollectedForm = this.fb.group({  
      "leftItemName": new FormControl(''),
      "leftItemNameList": new FormControl(''),
      "rightRepairItemNameList": new FormControl(''),
      "rightRepairItemName": new FormControl(''),
      
    });
    if(this.itemId == 1){
      //this.selectedItemList = this.data.leftItemList;
      this.getLeftItemCollectedData();
    }else{
      //this.selectedItemList = this.data.rightItemList;
      this.getRightItemCollectedData();
    }
    
  }

  addLeftCollectedItemData(){
    let payload = this.popupItemsCollectedForm.value;
   
    
    this.apimicroservice.submitLeftRepairItemData(payload).subscribe(res => {
      //
      
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);
        
        this.ngOnInit();
        //this.loadBuildigdata()
       //this.onClose();
        
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    });
  }

  addRightCollectedItemData(){
    let payload = this.popupItemsCollectedForm.value;
   
    
    this.apimicroservice.submitRightRepairItemData(payload).subscribe(res => {
      //
      
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);
        
        this.ngOnInit();
        //this.loadBuildigdata()
       //this.onClose();
        
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    });
  }

  saveLeftItems(){
     
    this.selectedItemList = this.popupItemsCollectedForm.get('leftItemNameList').value;
    //
    this.dialogRef.close({data:this.tempArr});
  }

  saveRightItems(){
    this.selectedItemList = this.popupItemsCollectedForm.get('rightRepairItemNameList').value;
    //
    //
    this.dialogRef.close({data:this.tempArr});
  }

  getDataById()
  {
   
  }
  
  onClose(): void {
    this.dialogRef.close();
  }
  addNewItemFunc()
  {
    this.addNewItem = true;
  }
  cancelFunc()
  {
    this.addNewItem = false;
  }
  getLeftItemCollectedData(){
    if(this.data.leftItemList !== undefined){
      this.data.leftItemList.forEach(element =>{
        this.selectedItemList.push(element.leftItemName);
     });
     //
     this.popupItemsCollectedForm.controls['leftItemNameList'].setValue(this.selectedItemList);
    }
    
    this.apimicroservice.getLeftRepairItemAllData().subscribe(res => {
      //
      this.variables1 = res.data;
      this.filteredList = this.variables1.slice();
      
    });
  }
  getRightItemCollectedData(){
    if(this.data.rightItemList !== undefined){
      this.data.rightItemList.forEach(element =>{
        this.selectedItemList.push(element.rightRepairItemName);
     });
     //
     this.popupItemsCollectedForm.controls['rightRepairItemNameList'].setValue(this.selectedItemList);
    }
    this.apimicroservice.getRightRepairItemAllData().subscribe(res => {
      //
      this.variables2 = res.data;
      this.filteredList2 = this.variables2.slice();
    });
  
  }
  onSelectItem(event,id,name){
    //
    //
    if(event.source.selected == true){
      //
      if(this.itemId == 1){ 
        this.tempArr.push({"_id":id,"leftItemName":name});
      }else{
        this.tempArr.push({"_id":id,"rightRepairItemName":name});
      }
    }else{
      //
      this.tempArr = this.tempArr.filter(m => m._id!=id);
    } 
    //
  }
  
}
