<div class="container-fluid col-sm-12" [formGroup]="modeOfPaymentForm">
    <div class="row">
        <div class="col-sm-12">
         
                    <div class="row ">
                      <div class="col-sm-11 mb-2">
                        <div class="row ">
                          
                        <span><img  width="35rem"> </span> 
                        <!-- src="../../assets/bill.png" -->
                        <span> <h1 class="fass pt-1" id="tomodeOfPaymentForm">{{paymentMode}} Details</h1></span>
                        </div>
                        </div> 
                        <div class="col-sm-1 ">
                          <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                            <a (click)="onClose()" matTooltip="Close" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">close</span></a>
                            </div>
                        </div>
                        </div>
                                <div class="row">
                                    <div *ngIf="paymentMode == 'Card'" class="col-sm-4">
                                        <mat-form-field  class="example-full-width mb-2" appearance="outline">
                                        <mat-label>Card No </mat-label>
                                       <input matInput placeholder="Number"  />
                                     </mat-form-field>
                                     </div> 
                                     <div *ngIf="paymentMode == 'RTGS'" class="col-sm-4">
                                     <mat-form-field class="example-full-width mb-2" appearance="outline">
                                       <mat-label>RTGS NFT </mat-label>
                                       <input matInput placeholder="Number"  />
                                     </mat-form-field>
                                 </div>
                                 <div *ngIf="paymentMode == 'Card'" class="col-sm-4">
                                     <mat-form-field class=" example-full-width mb-2" appearance="outline">
                                    <mat-label>Transation No</mat-label>
                                    <input matInput placeholder="Number"  />
                                  </mat-form-field>
                                  </div>
                                  <div *ngIf="paymentMode == 'RTGS'" class="col-sm-4">
                                  <mat-form-field class="example-full-width mb-2" appearance="outline">
                                   <mat-label>Bank Name</mat-label>
                                   <input matInput placeholder="Bank Name"  />
                                 </mat-form-field>
                                 </div>
                                 <!-- <div *ngIf="paymentMode == 'RTGS'" class="col-sm-4">
                                   <mat-form-field  class="example-full-width mb-2"  appearance="outline">
                                     <mat-label>Amount</mat-label>
                                     <input matInput placeholder="Amount"  />
                                   </mat-form-field>
                                   </div> -->
                                    <div  *ngIf="paymentMode == 'Cheque'" class="col-sm-12 table-responsive">
                                        <table id="toBillingLst"
                                        class="table table-bordered data-table-custom">
                                           <thead>
                                               <tr class="text-center">
                                                   <th>Date</th>
                                                    <th>Cheque No.</th>
                                                    <th>Bank Name</th>
                                                   <th>Amount</th>
                                                   <th>Upload Cheque Img</th>
                                                   <th *ngIf="chequeAr.length == 0">Action</th>
                                               </tr>
                                           </thead>  
                                           <tbody formArrayName="chequeArray">
                        
                                             <tr [formGroupName]="i"
                                             *ngFor="let row of modeOfPaymentForm.get('chequeArray')['controls']; let i = index; let last = last"
                                               role="row"
                                               class="odd "
                                             >
                                               <td>
                                                 <!-- <label class="labelStyle justify-content-right mt-3"> {{currentdate | date: 'dd/mm/yyyy'}}</label> -->
                                                 <mat-form-field class="example-full-width" appearance="outline">
                                                  <mat-label>Cheque Date
                                                    <!-- <span class="required-asterisk">*</span> -->
                                                  </mat-label>
                                                  <input formControlName="chequeDate" readonly matInput [matDatepicker]="chequeDate" id="chequeDate" 
                                                  [min]="currentdate"  
                                                    placeholder="DD/MM/YYYY" required>
                           
                                                  <mat-datepicker-toggle matSuffix [for]="chequeDate">
                                                  </mat-datepicker-toggle>
                                                  <mat-datepicker #chequeDate></mat-datepicker>
                                                
                                                </mat-form-field>
                                                <mat-error class="margin_tp"
                                                *ngIf="row.controls.chequeDate.touched && row.controls.chequeDate.errors?.required">
                                                   This field is mandatory.
                                              </mat-error>
                                               </td>
                                               <td>
                                                   <mat-form-field class="example-full-width lable_style2  widthh" appearance="outline">
                                                       <mat-label class="lable_style2"> Cheque No</mat-label>
                                                   <input placeholder="Cheque No" formControlName="chequeNo" type="text"
                                                   matInput maxlength="10" />
                                                 
                                                 </mat-form-field>
                                                 <p style="color:red;" *ngIf="findDuplicate(row.controls.chequeNo.value,i) && i > 0">
                                                  Duplicate Cheque No.
                                                </p>
                                                <mat-error class="margin_tp"
                                                *ngIf="row.controls.chequeNo.touched && row.controls.chequeNo.errors?.required">
                                                   This field is mandatory.
                                              </mat-error>
                                              <mat-error class="margin_tp"
                                      *ngIf="row.controls.chequeNo.touched && row.controls.chequeNo.errors?.pattern">
                                      Please enter numeric value.
                                    </mat-error>
                                               </td>
                                               <td>
                                                 <mat-form-field class="example-full-width lable_style2 widthh" appearance="outline">
                                                     <mat-label class="lable_style2">Bank Name</mat-label>
                                                 <input placeholder="Bank Name" formControlName="bankName" type="text"
                                                 matInput />
                                               
                                               </mat-form-field>
                                               <mat-error class="margin_tp"
                                                *ngIf="row.controls.bankName.touched && row.controls.bankName.errors?.required">
                                                   This field is mandatory.
                                              </mat-error>
                                              <mat-error class="margin_tp"
                                      *ngIf="row.controls.bankName.touched && row.controls.bankName.errors?.pattern">
                                      Please enter characters.
                                    </mat-error>
                                             </td>
                                             <td>
                                               <mat-form-field class="example-full-width lable_style2 widthh" appearance="outline">
                                                   <mat-label class="lable_style2">Amount</mat-label>
                                               <input placeholder="Amount" formControlName="amount" type="text"
                                               matInput />
                                             
                                             </mat-form-field>
                                             <mat-error class="margin_tp"
                                                *ngIf="row.controls.amount.touched && row.controls.amount.errors?.required">
                                                   This field is mandatory.
                                              </mat-error>
                                              <mat-error class="margin_tp"
                                      *ngIf="row.controls.amount.touched && row.controls.amount.errors?.pattern">
                                      Please enter numeric value.
                                    </mat-error>
                                            </td>
                                            <td>
                                              <input #fileUpload type="file" style="display: none;" (change)="onChange($event)" />
                            <button class="btn btn-light shadow btn-sm m-0 example-full-width "
                             matTooltip="Upload JPG, GIF or PNG image.300 x 300 required."  
                             (click)="fileUpload.click()" ><i class="ai-refresh-cw me-2" ></i>Upload Photo</button>
                                           </td>
                           <td *ngIf="chequeAr.length == 0">   
                         <div class="row">
                           <div class="col-sm-5 pl-0">
                             <button mat-button mat-icon-button *ngIf="last" class="pl-0" (click)="addRow(i);" 
                               [disabled]="modeOfPaymentForm.controls.chequeArray.invalid || modeOfPaymentForm.disabled">
                                 <img matTooltip="Add" class="pl-0" width="25" src="../../assets/add.png"> 
                               </button>
                             </div>
                             <div class="col-sm-5 pl-0">
                               <button mat-button mat-icon-button (click)="deleteRow(i);" [disabled]="modeOfPaymentForm.get('chequeArray')['controls']['length']===1 || modeOfPaymentForm.disabled">
                                 <img matTooltip="Remove" class="mt-1" width="25" src="../../assets/dustbin.png"> 
                               </button>
                             </div>
                         </div>
                               
                             
                                 
 
                           </td>
                         
                                             </tr>
                                           </tbody>
                                        
                                       </table>
                                        
                                    </div>
                                    <!-- <div  *ngIf="paymentMode == 'Cheque'" class="col-sm-12 pt-0 text-right">
                                        <pagination-controls id="typeMaster" directionLinks="true"
                              (pageChange)="onPageChange($event)" class="float-right pagination my-pagination labelStyle"></pagination-controls>
                                    
                                </div> -->
                                <div class="col-sm-10 ">
                                </div>
                              
                        <div class="col-sm-2 mb-2 mt-2">
                          <button class="btn btn-primary btn-sm example-full-width" type="button" (click)="submitChequeDetails()" [disabled]="disableSubmit">Save</button>
                        </div>
                           
                         
                </div>
            

        </div>
    </div>
</div>