import { Component,OnInit, Inject } from '@angular/core';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ApiServiceService } from '../api-service.service';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from "ngx-spinner";
import * as moment from 'moment';
import { element } from 'protractor';
import { PopUpPatientComponent } from '../pop-up-patient/pop-up-patient.component';
import { PopUpBranchComponent } from '../pop-up-branch/pop-up-branch.component';
import { PopUpDoctorComponent } from '../pop-up-doctor/pop-up-doctor.component';
import { PopUpAppointmenttypeComponent } from "../pop-up-appointmenttype/pop-up-appointmenttype.component";


@Component({
  selector: 'app-popup-bookappointment',
  templateUrl: './popup-bookappointment.component.html',
  styleUrls: ['./popup-bookappointment.component.scss']
})
export class PopupBookappointmentComponent implements OnInit {
  appointmentMaster: FormGroup;
  appointtypelist; 
  doctorlist;
  patientlist;
  cliniclist;
  durationList;
  searchText: any;
  updateRefid;
  savebutton = true;
  updatebutton = false;
  clear = false;
  delete = false;
  searcheddoctor = false;
  endDateTime: string;
  fieldsOfCalenders: any;
  newAppointment:any;
  arrayCalender=[{
    
    "calenderDuration": "Yes",
    "calenderAppointmentType": "Yes",
    "calenderClinicBranch": "Yes",
    "calenderDoctorName": "Yes",
    "dailyCalender": "Yes",
    "weeklyCalender": "Yes",
    "monthlyCalender": "Yes",
  }];userType: string;
  listOfDoctorAvail: any;
  totalLength: any;
  clinicRef: any;
  branchRef: any;
  doctorList: any;
  timeLabels=[];
  durationValue: any;
  appointList: any;
  filteredPatientList: any;
  filteredAppointTypeList: any;
  filteredClinicList: any;
  filteredDoctorList: any;
  patientRefId: any;
  logged_id: string;
  pipe = new DatePipe('en-US'); // Use your own locale
  myFormattedDate: string;
  dateTime: Date;
  dateTime2: Date;
  dd: Date;
  isSave = true;
  closetime: any;
  userName: string;
  doctorID: string;
  PatientCreate=true;
  AppointmentTypeCreate=true;
  DoctorCreate=true;
  msg: string;
  patientDetails: any;
 
 // appointment_id: string;

  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
     public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<PopupBookappointmentComponent>,@Inject(MAT_DIALOG_DATA) public data:any,
  ) {
    this.patientDetails = data.patientInfo;
    dialogRef.disableClose = true;
   }
  page2:number = 1;
  size:number = 5;
  branchRefId: string;
  clinicRefId: string;
  doctorRefId:string;
  userPermission:any;
  bookAppointmentsCreate= true;
  bookAppointmentsUpdate= true;
  currentdate;
  isDisabled = false;

  ngOnInit(): void {
    //
    this.currentdate = new Date();
    this.timeLabels = [];
    this.searcheddoctor = false;
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    this.userType = sessionStorage.getItem('userType');
    this.logged_id = sessionStorage.getItem('userRef');
    this.branchRefId = sessionStorage.getItem('branchRef');
    this.branchRef = this.branchRefId;
    this.userName = sessionStorage.getItem('userName');
    this.doctorID= sessionStorage.getItem('doctorRefId');
    //this.appointment_id = sessionStorage.getItem('appointment_id');
    //
    //
    const now = Date.now();
    this.myFormattedDate = this.pipe.transform(now, 'shortTime');
    this.getCalender();
    if(this.userType !== 'Super Admin'){
    this.userPermission.forEach((element) => {
      if(element.pageName == 'Book Appointments' && element.create == false)
      {
          this.bookAppointmentsCreate = false;  
      }
      // if(element.pageName == 'Book Appointments' && element.update == false)
      // {
      //     this.bookAppointmentsUpdate = false;  
      // }    
      if(element.pageName == 'Patient' && element.create == false)
      {
          this.PatientCreate = false;  
      }
      if(element.pageName == 'Appointment Type' && element.create == false)
      {
          this.AppointmentTypeCreate = false;  
      }  
      if(element.pageName == 'Doctor' && element.create == false)
      {
          this.DoctorCreate = false;  
      }  
    });
  }
  
    this.appointmentMaster = this.fb.group({
      "clientName": new FormControl(''),
      "contactNumber": new FormControl(''),
      // "title": new FormControl(''),
      "eventColor": new FormControl(''),
      "doctorName": new FormControl(''),
      // "clinicName": new FormControl(''),
      "clinicLocation": new FormControl(''),
      "AppoinmentType": new FormControl(''),
      "startDateTime": new FormControl(''),
      "appoinmentTime": new FormControl(''),
      
      // "duration": new FormControl(''),
      
      
    });
    
    //this.toDateChange();
    if(this.userType == 'Doctor')
    {
      //this.appointmentMaster.get("contactNumber").setValue(mobile1);
    }
    
    if(this.userType !== 'Super Admin'){ 
    //  let bid = sessionStorage.getItem('branchRef');
      this.apimicroservice.getBranchById(this.branchRefId).subscribe(res => {
        //
          this.branchRef = this.branchRefId;
          this.appointmentMaster.get("clinicLocation").setValue(res.data[0].branchName);
          this.selectBranch(res.data[0]._id);
          if(this.userType == 'Doctor')
          {
            let date =new Date();
            //
            //
            //
            this.appointmentMaster.get("startDateTime").setValue(date);
            this.appointmentMaster.get("doctorName").setValue(this.userName);
            this.selectDoctor(this.doctorID);  
          }
         
      });
      // if(this.userType == 'Doctor')
      // {
      //   let date =new Date();
      //   //
      //   //
      //   //
      //   this.appointmentMaster.get("doctorName").setValue(this.userName);
      //    this.appointmentMaster.get("startDateTime").setValue(date);
      //   this.selectDoctor(this.doctorID);  
      // }
   
    }
    // this.apimicroservice.getAppointmentTypelist().subscribe(res => {
    //   if (res.status) {
    //     // //
    //     this.appointtypelist = res.data.data;
    //     this.filteredAppointTypeList= this.appointtypelist.slice();
    //   }
    // });
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
    this.apimicroservice.getAppointmentTypelist(branchRef,clinicRef).subscribe((res) => {
      if (res.status) {
        // ////
        this.appointtypelist = res.data.data;
        this.filteredAppointTypeList = this.appointtypelist.slice();
      }
    });
  } else {
    this.apimicroservice.getAppointmentTypelist(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe((res) => {
      if (res.status) {
        // ////
        this.appointtypelist = res.data.data;
        this.filteredAppointTypeList = this.appointtypelist.slice();
      }
    });
  }
  if (this.userType !== 'Super Admin') {
    this.apimicroservice.getPatientListAll(branchRef,clinicRef).subscribe(res => {
      if (res.status) {
        // //
        this.patientlist = res.data.data;
        this.filteredPatientList= this.patientlist.slice();
      }
    });
  }else{
    this.apimicroservice.getPatientListAll(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
      if (res.status) {
        // //
        this.patientlist = res.data.data;
        this.filteredPatientList= this.patientlist.slice();
      }
    });
  }

  if (this.userType !== 'Super Admin') {
    this.apimicroservice.getAllDoctorslist(branchRef,clinicRef).subscribe(res => {
      if (res.status) {
        // //
        this.doctorlist = res.data.data;

      }
    });
  }else{
    this.apimicroservice.getAllDoctorslist(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
      if (res.status) {
        // //
        this.doctorlist = res.data.data;

      }
    });
  }
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      if (res.status) {
        // //
        this.cliniclist = res.data;
        this.closetime = this.cliniclist.closeTime;
        this.filteredClinicList= this.cliniclist.slice();
         //
        
       // this.appointmentMaster.get("clinicLocation").setValue(this.cliniclist[0].branchName);
        
     //   this.selectBranch(this.cliniclist[0]._id);
      }
    });

    this.fetchDuration();
    this.appointmentMaster.get("startDateTime").setValue(this.patientDetails.followupDate);
    this.appointmentMaster.get("clientName").setValue(this.patientDetails.name);
    this.appointmentMaster.get("contactNumber").setValue(this.patientDetails.Mob);
  //  / this.appointmentMaster.get("doctorName").setValue(this.patientDetails.doctorName);
    this.appointmentMaster.get("clinicLocation").setValue(this.patientDetails.branchName);
    this.appointmentMaster.get("AppoinmentType").setValue(this.patientDetails.titleOfAppoinment);
    this.selectBranch(this.patientDetails.branchRefId);
   
      this.selectDoctor(this.patientDetails.doctorRefId);
      this.fetchDuration();
    
    //this.eventclickbranch(this.patientDetails.branchRefId,this.patientDetails.doctorRefId);
  }  
fetchPatient()
{
  let clinicRef= sessionStorage.getItem('clinicRef');
  let branchRef= sessionStorage.getItem('branchRef');
  let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
  let branchRefSuperAdmin= '';
  if (this.userType !== 'Super Admin') {
  this.apimicroservice.getPatientListAll(branchRef,clinicRef).subscribe(res => {
    if (res.status) {
      // //
      this.patientlist = res.data.data;
      this.filteredPatientList= this.patientlist.slice();
    }
  });
}else{
    this.apimicroservice.getPatientListAll(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
    if (res.status) {
      // //
      this.patientlist = res.data.data;
      this.filteredPatientList= this.patientlist.slice();
    }
  });
}
}

// fetchAppointmentTypeList(){
//   this.apimicroservice.getAppointmentTypelist().subscribe(res => {
//     if (res.status) {
//       // //
//       this.appointtypelist = res.data.data;
//       this.filteredAppointTypeList= this.appointtypelist.slice();
//     }
//   });
// }

fetchAppointmentTypeList() {
  let clinicRef = sessionStorage.getItem('clinicRef');
  let branchRef = sessionStorage.getItem('branchRef');
  let clinicRefSuperAdmin = sessionStorage.getItem('clinicRef');
  let branchRefSuperAdmin = '';
  
  if (this.userType !== 'Super Admin') {
    this.apimicroservice.getAppointmentTypelist(branchRef, clinicRef).subscribe((res) => {
      if (res.status) {
        // ////
        this.appointtypelist = res.data.data;
        this.filteredAppointTypeList = this.appointtypelist.slice();
      }
    });
  } else {
    this.apimicroservice.getAppointmentTypelist(branchRefSuperAdmin, clinicRefSuperAdmin).subscribe((res) => {
      if (res.status) {
        // ////
        this.appointtypelist = res.data.data;
        this.filteredAppointTypeList = this.appointtypelist.slice();
      }
    });
  }
}

getDoctorAvailability(){
  //
  //
  //
  this.apimicroservice.getDoctorAppointAvailable(this.branchRef,this.clinicRef,this.doctorRefId).subscribe(res => {
    // //
    // //
    // let appointStartTime = this.appointmentMaster.value.startDateTime._d.toLocaleTimeString('en-GB');
    // let appointEndTime = this.appointmentMaster.value.endDateTime._d.toLocaleTimeString('en-GB');
    let day =this.appointmentMaster.value.startDateTime;
    let appointDay = moment(day).format('dddd')
     //
    
    let drInfo = res.data[0];
    this.timeLabels = [];
    drInfo.dayInfo.forEach(element =>{
      // //
      if (element.day == appointDay ) {
        
        // //
        //this.timelineLabels(element.morningFrom,1,'hours',element.morningTo);
        this.timelineLabels(element.morningFrom,this.durationValue,'minutes',element.morningTo);
        this.timelineLabels(element.afternoonFrom,this.durationValue,'minutes',element.afternoonTo);
        this.timelineLabels(element.eveningFrom,this.durationValue,'minutes',element.eveningTo);
        }
    })
   
  });
}

 timelineLabels = (desiredStartTime, interval, period, desiredEndTime) => {
 
  // const periodsInADay = moment.duration(1, 'day').as(period);
  // const xyz= moment(desiredEndTime,'hh:mm').subtract(desiredStartTime,'hours').format();

  // parse time using 24-hour clock and use UTC to prevent DST issues 
  const start = moment.utc(desiredStartTime, "hh:mm A" );
  const end = moment.utc(desiredEndTime, "hh:mm A");
  // account for crossing over to midnight the next day
  if (end.isBefore(start)) end.add(0, 'day');
  // calculate the duration
  const d = moment.duration(end.diff(start)).as(period);

  const periodsInADay = moment.duration(desiredEndTime).as(period);
  // //
  
  // //
  
  // this.timeLabels = [];
  const startTimeMoment = moment(desiredStartTime, 'hh:mm A');
  for (let i = 0; i < d; i += interval) {
    startTimeMoment.add(i === 0 ? 0 : interval, period);

    this.timeLabels.push({"slot":startTimeMoment.format('hh:mm A'),"status":"unbooked","isDisabled":"No"});
    // //
    
  }

  return this.timeLabels;
};


// reset(){
//   this.savebutton = true;
//   this.updatebutton = false;
//   this.clear = false;
//   this.delete = false;
//   this.appointmentMaster.reset();
//   this.timeLabels = [];
// }

  submit(){
    //
    //.toISOString()",new Date(this.currentdate).toISOString());
    
    if(this.appointmentMaster.value.startDateTime < new Date(this.currentdate).toISOString()) {
      this.apimicroservice.openSnackbar("Previous Dated Slots Cannot be booked");
      this.ngOnInit();
     }else{
      this.spinner.show();
      let payload = this.appointmentMaster.value
      payload.appoinmentTime = this.newAppointment;
      payload.branchRefId = this.branchRef;
      payload.clinicRefId = this.clinicRef;
      payload.doctorRefId = this.doctorRefId;
      payload.patientRefId = this.patientRefId;
      // //
      this.apimicroservice.addAppointment(payload).subscribe(res => {
        // //
        
        this.spinner.hide();
        if (res.status) {
          // //
          if(res.message == 'Saved Successfully')
          {
            this.msg = "saved";
           // this.apimicroservice.openSnackbar('Appoinment Saved');
           this.ngOnInit();
           this.dialogRef.close({data:this.msg});
          }
          else{
            this.apimicroservice.openSnackbar(res.message);
          }
        
        } else {
          this.apimicroservice.openSnackbar(res.message);
        }
      }); 
     }
   
    
  }
  
  // update(){
  //   if(this.appointmentMaster.value.startDateTime < new Date(this.currentdate).toISOString()) {
  //     this.apimicroservice.openSnackbar("Previous Dated Slots Cannot be booked");
  //     this.ngOnInit();
  //    }else{
  //     this.spinner.show();
  //   let payload = this.appointmentMaster.value;
  //   payload.appoinmentTime = this.newAppointment;
  //   payload.branchRefId = this.branchRef;
  //   payload.doctorRefId = this.doctorRefId;
  //   payload.patientRefId = this.patientRefId;
  //   this.apimicroservice.updateAppointment(this.updateRefid,this.appointmentMaster.value).subscribe(res => {
  //     this.spinner.hide();
  //     if (res.status) {
  //       // //
  //       this.savebutton = true;
  //       this.updatebutton = false;
  //       this.clear = false;
  //       this.delete = false;
  //       this.apimicroservice.openSnackbar(res.message);
  //       this.ngOnInit();
  //     } else {
  //       this.apimicroservice.openSnackbar(res.message);
  //     }
  //   }); 
  //    }
  // }


  fetchDuration() {
  
    this.apimicroservice.getAppointGenericAllData().subscribe(res => {
      // //
      if(res.message == 'No Data Found')
      {
        this.durationValue = 30;
      }
      else
      {
        this.durationValue = res.data[0].duration;
      }
    })
  }
  // functiondelete(){
  //   this.spinner.show();
  //   this.apimicroservice.deleteAppointmentData(this.updateRefid).subscribe(res => {
  //     this.spinner.hide();
  //     if (res.status) {
  //       this.apimicroservice.openSnackbar(res.message);
  //       this.savebutton = true;
  //       this.updatebutton = false;
  //       this.clear = false;
  //       this.delete = false;
  //       this.appointmentMaster.reset();
  //       this.ngOnInit();

  //     } else {
  //       this.apimicroservice.openSnackbar(res.message);
  //     }
  //   }); 
  // }
  
  onSelectTime(time){
    this.isSave = false;
    let ssss = this.appointmentMaster.get("startDateTime").value;
    let currentime = new Date();
    let ss = moment.utc(time, "HH:mm A").toISOString();
    let mydaya = new Date(ss);
    let dtOffset = new Date(mydaya.setMinutes(mydaya.getMinutes() + currentime.getTimezoneOffset()));
    let hours = new Date(dtOffset).getHours();
    let minutes = new Date(dtOffset).getMinutes();
    let timex = new Date(ssss).setHours(hours); 
    let timess = new Date(timex).setMinutes(minutes); 
    this.appointmentMaster.get("startDateTime").setValue(new Date(timess).toISOString());
    this.appointmentMaster.get("appoinmentTime").setValue(time);
    this.newAppointment = time;
   

// // Date in EST and ISO format: "2021-11-30T15:33:32.222Z"
// //);
  }

  dddd(e){
    this.appointmentMaster.get("eventColor").setValue(e.color);
  }
  toDateChange()
  {
    let payload = this.appointmentMaster.value;
  
    if(Date.parse(payload.endDateTime) <= Date.parse(payload.startDateTime))
    {
      this.apimicroservice.openSnackbar("To date should be greater than From date");
      this.appointmentMaster.controls['startDateTime'].setValue('');
    }
    this.apimicroservice.getAppointmentbydatedate(payload.startDateTime).subscribe(res =>{
      // //
      if(res.status)
     {
      //  //
      this.apimicroservice.openSnackbar(res.message);
     }
     else{
      this.apimicroservice.openSnackbar(res.message);
     }
    })
  }
  fromDateChange()
  {
    let payload = this.appointmentMaster.value;
    if(Date.parse(payload.endDateTime) <= Date.parse(payload.startDateTime))
    {
      this.apimicroservice.openSnackbar("From date should be Less than To date");
      this.appointmentMaster.controls['endDateTime'].setValue('');
    }
  }
  toTimeClick()
  {
  // let payload = this.appointmentMaster.value;
  // let durationTime = this.datepipe.transform(payload.duration, 'mm:ss a');
  // //
  // let timee = durationTime + payload.startDateTime;
  // //
  
  // let latest_date =this.datepipe.transform(timee, 'MMM d, y, h:mm:ss a');
  // this.endDateTime =  latest_date;
  // //
  
  }

  selectBranch(id){
    this.timeLabels = [];
    this.branchRef = id; 
    //
   this.apimicroservice.getBranchById(id).subscribe(res => {
      //
    //  //
      this.clinicRef = res.data[0].clinicRefId;
     // this.fetchDoctorList();
     let clinicRef= sessionStorage.getItem('clinicRef');
     let branchRef= sessionStorage.getItem('branchRef');
     let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
     let branchRefSuperAdmin= '';
     if (this.userType !== 'Super Admin') {
      this.apimicroservice.getAllDoctorsList(branchRef,clinicRef).subscribe(res => {
        if (res.status) {
          this.doctorList = res.data;
          this.filteredDoctorList= this.doctorList.slice();
          
        } else {
          this.apimicroservice.openSnackbar(res.message);
        }
        });
      }else{
        this.apimicroservice.getAllDoctorsList(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
          if (res.status) {
            this.doctorList = res.data;
            this.filteredDoctorList= this.doctorList.slice();
            
          } else {
            this.apimicroservice.openSnackbar(res.message);
          }
          });
      }
    });
  }
  eventclickbranch(branch,doct){
    this.timeLabels = [];
    this.branchRef = branch;
   this.apimicroservice.getBranchById(branch).subscribe(res => {
    //  //
    //  //
      this.clinicRef = res.data[0].clinicRefId;
      this.fetchDoctorList();
      this.appointList = [];
      this.searcheddoctor = false;
      this.doctorRefId=doct;
      let day = this.appointmentMaster.value.startDateTime;
      day = new Date(day).toISOString();

      this.getDoctorAvailability();
      this.apimicroservice.getDoctorAppointAvailable(this.branchRef,this.clinicRef,this.doctorRefId).subscribe(res => {
        // //
        // //
        // let appointStartTime = this.appointmentMaster.value.startDateTime._d.toLocaleTimeString('en-GB');
        // let appointEndTime = this.appointmentMaster.value.endDateTime._d.toLocaleTimeString('en-GB');
        let day =this.appointmentMaster.value.startDateTime;
        let appointDay = moment(day).format('dddd')
        let drInfo = res.data[0];
        this.timeLabels = [];
        drInfo.dayInfo.forEach(element =>{
          // //
          if (element.day == appointDay ) {
            // //
            //this.timelineLabels(element.morningFrom,1,'hours',element.morningTo);
            this.timelineLabels(element.morningFrom,this.durationValue,'minutes',element.morningTo);
            this.timelineLabels(element.afternoonFrom,this.durationValue,'minutes',element.afternoonTo);
            this.timelineLabels(element.eveningFrom,this.durationValue,'minutes',element.eveningTo);
            }
        })
        this.apimicroservice.getAppointmentbydatedoctor(day,this.doctorRefId).subscribe(res => {
          this.dd = new Date();
          let a = '';
          let b ='';
          a = '6:00 AM';
          b = '6:00 PM';
          let xyz = this.appointmentMaster.get('startDateTime').value;
          let appointDate = new Date(xyz);
          /////////
          //
          for(let i=0;i<this.timeLabels.length;i++){
            //
           const now = Date.now();
            this.myFormattedDate = this.pipe.transform(now, 'shortTime');
           
            //
            this.dateTime = new Date(this.dd.toDateString()+" "+this.timeLabels[i].slot);
            this.dateTime2 = new Date(this.dd.toDateString()+" "+this.myFormattedDate);
            //
            //
            if(this.dd.toDateString() == appointDate.toDateString()){
            if(this.dateTime  < this.dateTime2) {
              this.timeLabels[i].isDisabled = 'Yes';
              //
           // this.isDisabled = true;
             //
             
             //
             //
        
            }
           }
            
          }
          this.appointList = res.data;
          if(this.appointList.length > 0){
            for(let i=0;i<this.timeLabels.length;i++){
              for(let j=0;j<this.appointList.length;j++){
                if(this.timeLabels[i].slot === this.appointList[j].appoinmentTime){
                  this.timeLabels[i].status = "booked";
                }
              }
            }
            this.searcheddoctor = false;
          }else{
            this.searcheddoctor = true;
          }
          // //
        });
       
      });
  
    });
  }

  selectPatient(id){
    this.patientRefId = id;
   this.apimicroservice.getPatientById(id).subscribe(res => {
    // //);
    let mobile1 = res.data[0].mobile1
    this.appointmentMaster.get("contactNumber").setValue(mobile1);
    });
  } 
  
 selectDoctor(id){
    //

    let payload = this.appointmentMaster.value;
    //
    
    this.appointList = []; 
    this.searcheddoctor = false;
    this.doctorRefId=id;
    let day = this.appointmentMaster.value.startDateTime;
    day = new Date(day).toISOString();
    let xyz = this.appointmentMaster.get('startDateTime').value;
          let appointDate = new Date(xyz);
    this.getDoctorAvailability();
    this.apimicroservice.getDoctorAppointAvailable(this.branchRef,this.clinicRef,this.doctorRefId).subscribe(res => {
       //
       if(res.message == 'No Data Found')
       {
        this.apimicroservice.openSnackbar("Doctor Un-Available");
       }
      // //
      // let appointStartTime = this.appointmentMaster.value.startDateTime._d.toLocaleTimeString('en-GB');
      // let appointEndTime = this.appointmentMaster.value.endDateTime._d.toLocaleTimeString('en-GB');
      let day =this.appointmentMaster.value.startDateTime;
      let appointDay = moment(day).format('dddd')
      let drInfo = res.data[0];
      //
      
      this.timeLabels = [];
      //
      //
      
      
      drInfo.dayInfo.forEach(element =>{
        // //
        if (element.day == appointDay ) {
          // //
          //this.timelineLabels(element.morningFrom,1,'hours',element.morningTo);
          this.timelineLabels(element.morningFrom,this.durationValue,'minutes',element.morningTo);
          this.timelineLabels(element.afternoonFrom,this.durationValue,'minutes',element.afternoonTo);
          this.timelineLabels(element.eveningFrom,this.durationValue,'minutes',element.eveningTo);
          }
      })
      this.apimicroservice.getAppointmentbydatedoctor(day,this.doctorRefId).subscribe(res => {
        //
        /////////////////
        this.dd = new Date();
        let a = '';
        let b ='';
        a = '6:00 AM';
        b = '6:00 PM';
      
        
        /////////
        //
        for(let i=0;i<this.timeLabels.length;i++){
          //
         const now = Date.now();
          this.myFormattedDate = this.pipe.transform(now, 'shortTime');
         
          //
          this.dateTime = new Date(this.dd.toDateString()+" "+this.timeLabels[i].slot);
          this.dateTime2 = new Date(this.dd.toDateString()+" "+this.myFormattedDate);
          //
          //
          //);
          //);
           if(this.dd.toDateString() == appointDate.toDateString()){
            if(this.dateTime  < this.dateTime2) {
              this.timeLabels[i].isDisabled = 'Yes';
              //
           // this.isDisabled = true;
             //
             
             //
             //
        
            }
          }
          
        }
        this.appointList = res.data;
        if(this.appointList.length > 0){
          for(let i=0;i<this.timeLabels.length;i++){
            //
           // if(this.timeLabels[i].slot <==)
          //  const now = Date.now();
          //   this.myFormattedDate = this.pipe.transform(now, 'shortTime');
          //   //
          //   //
          //   if(this.timeLabels[i].slot > this.myFormattedDate) {
             
          //    this.isDisabled = true;
          //    //
             
          //    //
          //    //
        
          //   }
         
          ////////////////
            for(let j=0;j<this.appointList.length;j++){
              if(this.timeLabels[i].slot === this.appointList[j].appoinmentTime){
                this.timeLabels[i].status = "booked";
              }
            }
          }
          this.searcheddoctor = false;
        }else{
          this.searcheddoctor = true;
        }
        // //
      });
     
    });

    
  }

  fetchDoctorList() {
    // //
    // //
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
    this.apimicroservice.getAllDoctorsList(branchRef,clinicRef).subscribe(res => {
      // //

      if (res.status) {
        this.doctorList = res.data;
        this.filteredDoctorList= this.doctorList.slice();
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
      });
    }else{
      this.apimicroservice.getAllDoctorsList(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
        // //
  
        if (res.status) {
          this.doctorList = res.data;
          this.filteredDoctorList= this.doctorList.slice();
        } else {
          this.apimicroservice.openSnackbar(res.message);
        }
        });
    }
  }

  getCalender(){
    this.apimicroservice.getAppointGenericAllData().subscribe(res => {
       //
     
      if (res.data.length > 0) {
         this.fieldsOfCalenders = res.data[0];
         
      }else{
        this.fieldsOfCalenders = this.arrayCalender;
      }

      // //",res.data);
        if(res.status){
          // this.apimicroservice.openSnackbar(res.message);
          
        }else{
          //this.apimicroservice.openSnackbar(res.message);
        }
        
       
      });
  }

  openPatientPopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpPatientComponent,
      {
        width: "1200px",
      });
  }
  //  openBranchPopUp() {
  //   localStorage.clear();
  //   const dialogRef3 = this.dialog.open(PopUpBranchComponent,
  //     {
  //       width: "1200px",
  //       height: "570px"
  //     });
  // }
  openDoctorPopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpDoctorComponent,
      {
        width: "1200px",
      });
  }
  openAppointmentTypePopUp(){
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpAppointmenttypeComponent,
      {
        width: "700px",
      });

      dialogRef3.afterClosed().subscribe((result) => {
        this.fetchAppointmentTypeList();  
       });
  }

  onClose(): void {
    this.dialogRef.close();
  }
 

}

