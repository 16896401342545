<div class="dialog-wrapper">
    <!-- <h3 mat-dialog-title>Change Password </h3> -->
    <mat-dialog-content>
      <div class="container">
        <form [formGroup]="popupFollowupReasonForm" autocomplete="off" novalidate>
        <div class="row">
          <div class="col-sm-12">
            <div class="card-group">
              <div class="card p-1" >
              

                <div class="card-body col-sm-12">
                    <div class="row">
                    <div class="col-sm-11">
                        <p class="text-muted">Add Follow-Up Reason</p>
                    </div>
                    <div class="col-sm-1 ">
                      <div class="page-title-right row justify-content-end d-flex align-items-center float-end">
                        <a (click)="onClose()" matTooltip="Close" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">close</span></a>
                        </div>
                    </div>
                    
              </div>
                  <div class="col-sm-12">
                    <div class="row">
                        
                  <div class="col-sm-6">
                    <label class="pt-3 lable_style">Follow-Up Reason :</label>
                    </div>
          <div class="col-sm-6 pl-1 pr-1">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Follow-Up Reason</mat-label>
              <input required matInput placeholder="Follow-Up Reason" formControlName="followupReason" required />
             
             </mat-form-field>
             <mat-error class="margin_tp"
             *ngIf="popupFollowupReasonForm.controls['followupReason'].touched && popupFollowupReasonForm.controls['followupReason'].errors?.required">
             This field is mandatory.
           </mat-error>
           <mat-error class="margin_tp"
             *ngIf="popupFollowupReasonForm.controls['followupReason'].touched && popupFollowupReasonForm.controls['followupReason'].errors?.pattern">
             Enter valid follow-up Reason.
           </mat-error>
          </div>
     
      
                </div>
                <div class="row">
                    <div class="col-sm-3"></div>
                <div class="col-sm-6">
                    <button class="btn btn-primary btn-sm example-full-width" type="button" 
                    (click)="save()" >Save</button>
                  </div>
                  <div class="col-sm-3"></div>
                </div>
</div>
                </div>



                
              </div>
            </div>
          </div>
      
        </div>
</form>


      </div>
    </mat-dialog-content>
  
  </div>