<div class="container-fluid bgcolor2" [formGroup]="servicesMasterForm" >
  <form [formGroup]="servicesMasterForm" autocomplete="off" novalidate>
    <div class="row">
      <div class="col-sm-1">
     
      </div>
      <div class="col-sm-11">
        <div class="row pl-0 ">

          <div class="col-12 pl-0 ">
            <div class="row ">
            <div class="col-sm-9">
            
                <h1 class="pt-2 pl-0" id="toServicesForm">Services</h1>
                </div>
                <div class="col-sm-3 mt-1">
                  <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                    <a (click)="onback()" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">arrow_back</span>Back</a>
                    <a (click)="toServicesList()" class="d-flex align-items-center ms-3 pr-2 mt-2"><span class="material-symbols-outlined pr-2">reorder</span>List</a>
               </div>
          </div>
        </div>
       </div>
       </div>

       
        <div class="row">
          <div class="col-sm-12 pr-4">
              <div class="row">
                  <div class="card divbody mb-2 borderradius p-1">
                   
                      
                      <div class="col-sm-12">
                          <div class="row">
                            <div class="col-sm-1 mt-3 ">
                           
                              <!-- <mat-form-field *ngIf="userType !== 'Super Admin'" class="example-full-width" appearance="outline">
                                <mat-label>Service Id</mat-label>
                                <input matInput formControlName="branchWiseId" readonly  />   
                              </mat-form-field> -->
                              <!-- <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Service Id</mat-label>
                                <input matInput [value]="superBranchId" readonly  />
                                </mat-form-field> -->
                                <mat-label class="center" ngDefaultControl [value]="superBranchId" >{{superBranchId}}</mat-label>
                            
                              </div>

                            <div class="col-sm-3  ">
                              <div class="row">
                                <div class="col-sm-10 pr-1">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label> Service Name
                                    <!-- <span class="required-asterisk">*</span> -->
                                  </mat-label>
                                  
                                  <mat-select #select1 formControlName="serviceType" required>
                                    
                                    <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'serviceType'" [array]="serviceTypeArr"
                                    (filteredReturn)="filteredList =$event"></mat-select-filter>
                                    <mat-option *ngFor="let item of filteredList" [value]="item.serviceType">
                                      {{item.serviceType}}
                                    </mat-option>
                                      </mat-select>
                                     
                                </mat-form-field>
                                <mat-error *ngIf="servicesMasterForm.controls['serviceType'].touched && servicesMasterForm.controls['serviceType'].errors?.required">
                                  This field is mandatory.
                                </mat-error>
                                </div>
                                
                                <div class="col-sm-2 ml-0 pl-0 mTop no-print">
                                  <i (click)="openServiceTypePopUp();" matTooltip="Add New Service Type" 
                                  class="blue_col  fa-solid fa-circle-plus pl-1 fa-lg"></i>
                                     </div>
                               
                                </div>
                              </div>
                           
        
                              <div class="col-sm-3  ">
                                <div class="row">
                                  <div class="col-sm-10 pr-1">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label> Test Type</mat-label>
                                  
                                  <mat-select #select2 formControlName="testType" required>
                                    <mat-select-filter *ngIf="select2.focused" [placeholder]="'Filter'" [displayMember]="'testType'" [array]="testTypeArr"
                                    (filteredReturn)="filteredList1 =$event"></mat-select-filter>
                                    <mat-option *ngFor="let item of filteredList1" [value]="item.testType">
                                      {{item.testType}}
                                    </mat-option>
                                  </mat-select>
                                  
                                </mat-form-field>
                                <mat-error *ngIf="servicesMasterForm.controls['testType'].touched && servicesMasterForm.controls['testType'].errors?.required">
                                  This field is mandatory.
                                </mat-error>
                                </div>
                                <div class="col-sm-2 ml-0 pl-0 mTop no-print">
                                  <i (click)="openTestTypePopUp();" matTooltip="Add New Test Type" 
                                  class="blue_col fa-solid fa-circle-plus pl-1 fa-lg"></i>
                                     </div>
                                
                              </div>
                              </div>
                              <!-- <div *ngIf="isSuperAdmin" class="col-sm-3"> 
                                <div class="row">
                                  <div class="col-sm-10 pr-1">
                              
                                 
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Branch 
                                    
                                  </mat-label>
                                  <mat-select  #select3 (click)="fetchBranchList();" formControlName="branchRefId" >
                                    <mat-select-filter *ngIf="select3.focused" [placeholder]="'Filter'" [displayMember]="'branchName'"
                                    [array]="BranchList"  (filteredReturn)="filteredListBranch =$event"></mat-select-filter>
                                    <mat-option *ngFor="let item of filteredListBranch" [value]="item._id" 
                                    (click)="branchClick(item._id,item.branchName);"
                                    >
                                     {{ item.branchName }}</mat-option>
                                    </mat-select>
                                   
                                </mat-form-field>
                                <mat-error class="margin_tp"
                                    *ngIf="servicesMasterForm.controls['branchRefId'].touched && servicesMasterForm.controls['branchRefId'].errors?.required">
                                    This field is mandatory.
                                  </mat-error>
                            </div>
                            <div class="col-sm-2 mTop">
                              <img (click)="openBranchPopUp();" matTooltip="Add New Branch" width="25" src="../../assets/add_img.png"> 
                            </div>
                            </div>
                         </div> -->
                         
                              <div class="col-sm-2 mb-2 offset-sm-8 mt-2" *ngIf="updateButton && servicesUpdate">
                             
                                <button  class="btn btn-primary  btn-sm  example-full-width"  (click)="updateServiceData()" type="button">Update </button>
                               
                              </div>
                              <div class="col-sm-2 mb-2 mt-2" *ngIf="cancelButton">
                                
                                <button class="btn btn-primary btn-sm  example-full-width"  (click)="cancelUpdate()">Cancel </button>
                                <!-- <button class="del btn btn-outline-danger example-full-width mb-3" *ngIf="deleteButton"(click)="deleteServices(servicesId)">Delete</button> -->
                              </div>

                           
                            <div class="col-sm-2 offset-sm-10 mb-2 mt-2" *ngIf="submitButton && servicesCreate" >
                              <button class="btn btn-primary btn-sm example-full-width"  (click)="submitService()" type="button">Save </button>
                           
                             
                            </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
        <div class="col-sm-12 pl-0 pr-2">
          <div class="row">
              <div class="card divbody borderradius p-3 pb-0">
                      <div class="col-sm-12">
                          <div class="row">
                              <div class="col-sm-9">
                                  <h1 class="titlecolor"> Services List</h1>  
                              </div>
                              <div class="search-box col-sm-3 pb-2">
                                <input class="form-control amber-border" type="text" placeholder="Search" 
                                formControlName="searchService" aria-label="Search" (keyup)="onKeyUpSearch($event)"
                                >
                                <div class="input-group-append">
                                   <span class="input-group-text amber lighten-3" id="basic-text1"><span class="material-symbols-outlined">search</span></span>
                                </div>
                             </div>
                              <!-- <div class="col-sm-3">
                                  <div class="input-group md-form form-sm form-1 pl-0">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text purple lighten-3" id="basic-text1"><i class="fas fa-search text-white"
                                          aria-hidden="true"></i></span>
                                    </div>
                                    <input class="form-control my-0 py-1" type="text" placeholder="Search" formControlName="searchService"
                                     (keyup)="onKeyUpSearch($event)" aria-label="Search">
                                  </div>
                              </div> -->
                              <div class="col-sm-12">
                                <table id="toServicesList" 
                                class="table table-bordered data-table-custom">
                          
                                    <thead>
                                      <tr class="text-center">
                                        <th>Service ID</th>
                                        <!-- <th *ngIf="userType == 'Super Admin'">Branch Name</th> -->
                                        <th>Service Type</th>
                                        <th>Test Type </th>
                                        <!-- <th>Action</th> -->
                                      </tr>
                                    </thead>
                                    <tbody>                                      
                                      <tr *ngFor="let item of ServiceData |
                                       paginate : { id:'servicesMaster', itemsPerPage: size, 
                                       currentPage: page, totalItems:totalLength }"
                                       (click)="prepopulation(item._id)"
                                       (click)="toServicesForm()" 
                                       style="cursor:pointer; text-align: center ">

                                       
                                      <td matTooltip="click to view">{{item.branchWiseId}}</td>
                                      <!-- <td *ngIf="userType == 'Super Admin'" matTooltip="click to view" 
                                      >{{item.branchName}}
                                    </td> -->
                                      <td matTooltip="click to view">{{item.serviceType}}</td>
                                      <td matTooltip="click to view">{{item.testType}}</td>        
                                      <!-- <td class="text-center"> <i matTooltip="View" 
                                        (click)="prepopulation(item._id)" class="fas fa-eye"></i> </td>                                  -->
                                        
                                      </tr>  
                                    </tbody>
                                  </table>
                              </div>
                              <div class="col-sm-12 pt-0 text-right">
                                <pagination-controls id="servicesMaster" directionLinks="true"  
                                (pageChange)="onPageChange($event)" class="float-right pt-3 my-pagination"></pagination-controls>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              
          </div>
      
    
  </div>
    </div>
    </form>
  </div>
  