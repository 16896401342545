import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiServiceService } from '../api-service.service';

@Component({
  selector: 'app-pop-up-accesories',
  templateUrl: './pop-up-accesories.component.html',
  styleUrls: ['./pop-up-accesories.component.scss']
})
export class PopUpAccesoriesComponent implements OnInit {
  popupAccesoriedForm: FormGroup;
  listOfAccesories:any;
  totalLength:any;
  searchText:any;
  page = 1;
  size =3;
  sid:any;
  submitButton = true;
  updateButton = false;
  cancelButton = false;
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<PopUpAccesoriesComponent>,@Inject(MAT_DIALOG_DATA) public data:any, 
    public apimicroservice: ApiServiceService,
    private spinner: NgxSpinnerService,

  ) { PopUpAccesoriesComponent}

  ngOnInit(): void {
    this.page = 1;
    this.popupAccesoriedForm = this.fb.group({  
      "accesories": new FormControl('',[Validators.required,Validators.pattern("^[a-zA-Z0-9 -]+$")]),
      "createdAt": new Date(),
      "searchaccesories": new FormControl(''),
    });
    this.fetchAccesoriesList();
  }


  fetchAccesoriesList() {
    let pg = 1;
    let size = 3;
    let search='';
        this.apimicroservice.getAccesoriesData(pg,size,search).subscribe(res => {
          //
          
           this.listOfAccesories = res.data.data;
           this.totalLength = res.data.totalItems;
           //this.buildingLenght = this.listofbuild.length;
          if(res.status){
           // this.apimicroservice.openSnackbar(res.message);
            
          }else{
            //this.apimicroservice.openSnackbar(res.message);
          }
     
        });   
 
  }
  saveAccesories(){
    let payload = this.popupAccesoriedForm.value; 
    //
    this.spinner.show();
    this.apimicroservice.saveAccesoriesData(payload).subscribe(res => {
      //
      this.spinner.hide();
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);      
        //this.ngOnInit();
       this.onClose();        
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    },(ERROR:HttpErrorResponse) => {
      this.apimicroservice.openSnackbar(ERROR.error.message);
      this.spinner.hide();
      this.onClose();    
  });
  }
  save() {
    if (this.popupAccesoriedForm.invalid) {
      this.popupAccesoriedForm.get("accesories").markAsTouched();
      return;
    } else {
      this.saveAccesories();
    }
  }
  onKeyUpEvent(event: any) {
    this.searchText = this.popupAccesoriedForm.get('searchaccesories').value;
    this.apimicroservice.getAccesoriesData(this.page,this.size,this.searchText).subscribe(res => {
      this.listOfAccesories = res.data.data;
      this.totalLength = res.data.totalItems;
       if(res.status){
        //  this.apimicroservice.openSnackbar(res.message);    
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
  }

  prepopulation(id) {
    this.apimicroservice.getAccesoriesDataById(id).subscribe(res => {
      let data = res.data[0];
      this.sid = id;
       if(res.status){
        this.submitButton = false;
         this.updateButton = true;
         this.cancelButton = true;
         this.popupAccesoriedForm = this.fb.group({
          "accesories" :new FormControl(data.accesories),
          "updateddAt": new Date(),
         });
       }else{
     //    //this.apimicroservice.openSnackbar(res.message);
       }
     });
  }
  updateAccesories() {
    let payload = this.popupAccesoriedForm.value;
     this.apimicroservice.updateAccesoriesData(payload,this.sid).subscribe(res => {
       if(res.status){
         this.apimicroservice.openSnackbar(res.message);
          this.updateButton = false;
          this.submitButton = true;
          this.ngOnInit();
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
    
  }
  onPageChange(event) {
    this.page = event;
   // let p = this.page - 1;
    this.searchText = this.popupAccesoriedForm.get('searchaccesories').value;
 
    this.apimicroservice.getAccesoriesData(this.page,this.size,this.searchText).subscribe(res => {
      //
      
      this.listOfAccesories = res.data.data;
      this.totalLength = res.data.totalItems;
         if(res.status){
          //  this.apimicroservice.openSnackbar(res.message);
           
         }else{
           //this.apimicroservice.openSnackbar(res.message);
         }
    
       });
  
  }
  cancelUpdate(){
    this.popupAccesoriedForm.reset();
    this.submitButton=true;
    this.updateButton=false;
    this.cancelButton=false;
    this.ngOnInit();
  }
  onClose(): void {
    this.dialogRef.close();
  }

}
 