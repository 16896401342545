import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  FormArray 
} from "@angular/forms";
 import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute } from "@angular/router";
import { element } from "protractor";
@Component({
  selector: 'app-ha-form-print',
  templateUrl: './ha-form-print.component.html',
  styleUrls: ['./ha-form-print.component.scss']
})
export class HaFormPrintComponent implements OnInit {
  haPrintForm: FormGroup;
  date: any;
  size=5;
  page=1;
  userType: string;
  branchRef: string;
  clinicRef: string;
  BranchList: any;
  isSuperAdmin= true;
  
  headerData: any;
  reportName: any;
  reportAddress: any;
  district: any;
  taluka: any;
  state: any;
  country: any;
  reportPinCode: any;
  reportPhoneNumber: any;
  openTime: any;
  closeTime: any;
  logoimg: any;
  footerData: any;
  emailAdd: any;
  website:any;
  reportWeeklyOff: any;
  prifix: any;
  lastName: any;
  firstName: any;
  middleName: any;
  address: any;
  invoiceDate: any;
  pMobile: any;
  companyGst: any;
  bankName: string;
  bankBranchName: string;
  accountNumber: string;
  ifcCode: string;
  upiId: string;
  haFormNo: string;
  currentdate: Date;
  branchName: string;
  companyName: any;
  companyCanNumber: any;
  companyMake: any;
  leftHardness: string;
  leftStyle: string;
  leftHAType: string;
  leftCanalLenght: string;
  leftVent: string;
  leftRemovalString: string;
  rightHardness: string;
  rightStyle: string;
  rightHAType: string;
  rightCanalLenght: string;
  rightVent: string;
  rightRemovalString: string;
  deviceName: string;
  tempHagiven: string;
  tillDate: string;
  noOfDays: string;
  advanceRs: string;
  status: string;
  remark: string;
  LeftSerialNo :any[];
  RightSerialNo :any[];
  ChargerSerialNo :any[];
  streetAddress1: any;
  taluka1: any;
  district1: any;
  country1: any;
  state1: any;
  pincode1: any;
  reportPhoneNumber1: any;
  signatureImg: any;
  billingRef: any;
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
     public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute, 
  ) { }
  userPermission:any;
  repairCreate= true;
  repairUpdate= true;

  ngOnInit(): void {
    this.currentdate = new Date();
    this.page=1;
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    if(this.userType !== 'Super Admin'){
      this.isSuperAdmin= false;
    this.userPermission.forEach((element) => {
      if(element.pageName == 'Repair Print' && element.create == false)
      {
          this.repairCreate = false;  
      }
      if(element.pageName == 'Repair Print' && element.update == false)
      {
          this.repairUpdate = false;  
      }  
    });
  }
    this.haPrintForm = this.fb.group({
      "searchBilling": new FormControl(''), 
      
    });  
    //  this.getReportHeaderGenericAllData();
    //  this.getReportFooterGenericAllData();
    //  this.haFormNo = this.route.snapshot.paramMap.get('haFormNo'); 
    //  this.prepopulation(this.haFormNo); 


    let bar = new Promise<void>((resolve, reject) => {
      this.haFormNo = this.route.snapshot.paramMap.get('haFormNo'); 
      this.apimicroservice.getHAById(this.haFormNo).subscribe(res => {
        this.billingRef = res.data[0].billingRefId;
        resolve();
      }); 
 
    });
   bar.then(()=>{
    this.prepopulation(this.haFormNo);
    this.getReportHeaderGenericAllData();
    this.getReportFooterGenericAllData();
   });

} 
prepopulation(id) {  
  this.LeftSerialNo =[];
  this.RightSerialNo =[];
  this.ChargerSerialNo =[];
  this.apimicroservice.getHAById(id).subscribe(res => {
    this.signatureImg =res.data[0].Signaturephoto;
    this.LeftSerialNo.push(res.data[0].LeftSerialNo);
    this.RightSerialNo.push(res.data[0].RightSerialNo);
    this.ChargerSerialNo.push(res.data[0].ChargerSerialNo);

    if(res.data[0].deviceName == '' || res.data[0].deviceName == null){ this.deviceName ="-";}else {this.deviceName =res.data[0].deviceName;}
    if(res.data[0].tempHagiven == '' || res.data[0].tempHagiven == null){ this.tempHagiven ="-";}else {this.tempHagiven =res.data[0].tempHagiven;}
    if(res.data[0].tillDate == '' || res.data[0].tillDate == null){ this.tillDate ="-";}else {this.tillDate =res.data[0].tillDate;}
    if(res.data[0].noOfDays == '' || res.data[0].noOfDays == null){ this.noOfDays ="-";}else {this.noOfDays =res.data[0].noOfDays;}
    if(res.data[0].advanceRs == '' || res.data[0].advanceRs == null){ this.advanceRs ="-";}else {this.advanceRs =res.data[0].advanceRs;}
    if(res.data[0].status == '' || res.data[0].status == null){ this.status ="-";}else {this.status =res.data[0].status;}
    if(res.data[0].remark == '' || res.data[0].remark == null){ this.remark ="-";}else {this.remark =res.data[0].remark;}
  
    
    this.apimicroservice.getPatientById(res.data[0].patientRefId).subscribe(res => { 
      this.prifix =res.data[0].prefix;
      this.lastName =res.data[0].lastName;
      this.firstName =res.data[0].firstName;
      this.middleName =res.data[0].middleName;
      this.pMobile =res.data[0].mobile1;

      this.streetAddress1 =res.data[0].streetAddress;
      this.taluka1 =res.data[0].taluka;
      this.district1 =res.data[0].district;
      this.state1 =res.data[0].state; 
      this.country1 =res.data[0].country;
      this.pincode1 =res.data[0].pincode;

   });
   });
 
}

printThisPage() {
let currentDate =new Date();
let date=currentDate.toLocaleDateString('en-GB');
  let repair = this.haFormNo;
  let titleName= 'HA-Form_'+date;
    var tempTitle = document.title;
    document.title = titleName;
    window.print();
    document.title = tempTitle;
}

redirect(to): void {
  this.router.navigate([to]);
 }
getReportHeaderGenericAllData()
{ 
  this.apimicroservice.getHeaderDataByBillingRefId(this.billingRef).subscribe(res => {
    if(res.status){  
      this.apimicroservice.openSnackbar(res.message);  
      this.headerData = res.data; 
      //
      if(res.data.length > 0){
        this.reportName = this.headerData[0].reportName;
        this.reportAddress = this.headerData[0].reportAddress;
        this.reportWeeklyOff = this.headerData[0].reportWeeklyOff;
        this.emailAdd = this.headerData[0].reportEmail;
        this.website = this.headerData[0].reportWebsite;
        this.taluka = this.headerData[0].taluka;
        this.district = this.headerData[0].district;
        this.state = this.headerData[0].state;
        this.country = this.headerData[0].country;
        this.reportPinCode = this.headerData[0].reportPinCode;
        this.reportPhoneNumber = this.headerData[0].reportPhoneNumber;
        this.reportPhoneNumber1 = this.headerData[0].reportPhoneNumber1;
        this.openTime = this.headerData[0].openTime;
        this.closeTime = this.headerData[0].closeTime;
        this.logoimg = res.data[0].uploadLogo;
        
        this.bankName = res.data[0].bankName;
        this.bankBranchName = res.data[0].bankBranchName;
        this.accountNumber = res.data[0].accountNumber;
        this.ifcCode = res.data[0].ifcCode;
        this.upiId = res.data[0].upiId;
      }else{
        this.reportName = "";
        this.reportAddress = "";
        this.reportWeeklyOff ="";
        this.taluka = "";
        this.district = "";
        this.state = "";
        this.country = "";
        this.reportPinCode = "";
        this.reportPhoneNumber = "";
        this.reportPhoneNumber1 = "";
        this.openTime = "";
        this.closeTime = "";
        this.emailAdd = "";
        this.website = "";
        this.logoimg = "";
        this.bankName = "";
        this.bankBranchName = "";
        this.accountNumber = "";
        this.ifcCode = "";
        this.upiId = "";
      }
      
      if(this.logoimg == "" || this.logoimg == '../../assets/main.jpeg')
      this.logoimg = "../../assets/clinic_logo3.jpg"
      //
    }else{
      //this.apimicroservice.openSnackbar(res.message);
    }
  });
}
getReportFooterGenericAllData()
{
  let clinicRef= sessionStorage.getItem('clinicRef');
  let branchRef= sessionStorage.getItem('branchRef');
  let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
  let branchRefSuperAdmin= '';
  if (this.userType !== 'Super Admin') {
  this.apimicroservice.getReportFooterGenericAllData(branchRef,clinicRef).subscribe(res => {
    if(res.status){
    //  this.apimicroservice.openSnackbar(res.message);
      this.footerData = res.data;
      //
    }else{
      //this.apimicroservice.openSnackbar(res.message);
    }
  });
}else{
  this.apimicroservice.getReportFooterGenericAllData(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
    if(res.status){
    //  this.apimicroservice.openSnackbar(res.message);
      this.footerData = res.data;
      //
    }else{
      //this.apimicroservice.openSnackbar(res.message);
    }
  });
}
}

}

 