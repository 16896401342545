<div class="container-fluid bgcolor2" [formGroup]="billingCompanyForm">
  <form [formGroup]="billingCompanyForm" autocomplete="off" novalidate>
    <div class="row">
      <div class="col-sm-1">

      </div>
      <div class="col-sm-11">
        <div class="row pl-0 ">

          <div class="col-12 pl-0 ">
            <div class="row ">
              <div class="col-sm-9">

                <h1 class="pt-2 pl-0" id="toBillingCompanyForm">Billing Company</h1>
              </div>
              <div class="col-sm-3 mt-1">
                <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                  <a (click)="onback()" class="d-flex align-items-center pr-2 mt-2"><span
                      class="material-symbols-outlined pr-2">arrow_back</span>Back</a>
                  <a (click)="toDeviceList()" class="d-flex align-items-center ms-3 pr-2 mt-2"><span
                      class="material-symbols-outlined pr-2">reorder</span>List</a>
                  <!--      <img matTooltip="Back" class="mb-2 pb-2" width=35rem src="../../assets/back.png" (click)="onback()">
                <img src="../../assets/list.png"  (click)="toDeviceList()" class="mb-2 pb-2" matTooltip="List" width="35rem">
             -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 pr-4">
            <div class="row">
              <div class="card divbody borderradius mb-3 pl-4 pr-4 p-1">

                <div class="row">
                  <div class="col-sm-12">
                    <div class="row mt-2">
                      <div class="col-sm-12  ">
                        <h5 class="pb-0 mb-0">Billing Details :</h5>
                        <!-- <label class="header">Billing Details :</label> -->
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-1 mt-3">
                        <!-- <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Company ID 
                                   
                                  </mat-label>
                                  <input  matInput  maxlength="3" placeholder="Company ID" formControlName="companyId"  readonly />
                                
                                </mat-form-field> -->
                        <mat-label class="center" placeholder="Company ID" ngDefaultControl
                          formControlName="companyId">{{bId}}</mat-label>
                      </div>


                      <div class="col-sm-2 ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Company Name
                            <!-- <span class="required-asterisk">*</span>  -->
                          </mat-label>
                          <input required matInput placeholder="Company Name" formControlName="companyName" />

                        </mat-form-field>
                        <mat-error class="margin_tp"
                          *ngIf="billingCompanyForm.controls['companyName'].touched && billingCompanyForm.controls['companyName'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                        <mat-error class="margin_tp"
                          *ngIf="billingCompanyForm.controls['companyName'].touched && billingCompanyForm.controls['companyName'].errors?.pattern">
                          Enter Valid Company Name.
                        </mat-error>
                      </div>


                      <div class="col-sm-3  ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Registered Under GST
                            <!-- <span class="required-asterisk">*</span>  -->
                          </mat-label>
                          <mat-select (keyup)="gstApplicable();" required matTooltip="Registered Under GST"
                            formControlName="registeredUnderGST">
                            <mat-option (click)="gstApplicable();" value="Yes">Yes</mat-option>
                            <mat-option (click)="gstApplicable();" value="No">No</mat-option>
                          </mat-select>

                        </mat-form-field>
                        <mat-error class="margin_tp"
                          *ngIf="billingCompanyForm.controls['registeredUnderGST'].touched && billingCompanyForm.controls['registeredUnderGST'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                      </div>
                      <div class="col-sm-3 " *ngIf="gstCheckbox">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Company GST</mat-label>
                          <input matInput placeholder="eg.(29ABCDE1234F2Z5)" formControlName="companyGST" />


                        </mat-form-field>
                        <mat-error class="margin_tp"
                          *ngIf="billingCompanyForm.controls['companyGST'].touched && billingCompanyForm.controls['companyGST'].errors?.pattern">
                          Please enter valid GST Number.
                        </mat-error>
                      </div>

                      <div class="col-sm-3 ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Company PAN
                            <!-- <span class="required-asterisk">*</span>  -->
                          </mat-label>
                          <input required maxlength="15" matInput placeholder="Company PAN"
                            formControlName="companyPAN" />

                        </mat-form-field>
                        <mat-error class="margin_tp"
                          *ngIf="billingCompanyForm.controls['companyPAN'].touched && billingCompanyForm.controls['companyPAN'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                      </div>
                      <div class="col-sm-3 ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Proprietor / Director </mat-label>
                          <input matTooltip="Proprietor / Director" matInput placeholder="Proprietor / Director "
                            formControlName="propriter" />

                        </mat-form-field>
                      </div>
                      <div class="col-sm-3 ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Company Type
                            <!-- <span class="required-asterisk">*</span>  -->
                          </mat-label>
                          <mat-select required matTooltip="Company Type" formControlName="companyType">
                            <mat-option value="Proprietory">Proprietory</mat-option>
                            <mat-option value="Pvt Ltd"> Pvt Ltd</mat-option>
                            <mat-option value="LLP">LLP</mat-option>
                          </mat-select>

                        </mat-form-field>
                        <mat-error class="margin_tp"
                          *ngIf="billingCompanyForm.controls['companyType'].touched && billingCompanyForm.controls['companyType'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-12">

                    <div class="row mt-2">

                      <div class="col-sm-12 ">
                        <h5 class="pb-0 mb-0">Address Details :</h5>
                        <!-- <label class="header">Address Details :</label> -->
                      </div>

                      <div class="col-sm-3 pt-0 mt-0 ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Street Address
                            <!-- <span class="required-asterisk">*</span>  -->
                          </mat-label>
                          <input required matInput matTooltip="Company Registered Address"
                            placeholder="Company Registered Address" formControlName="companyRegisteredAddress" />

                        </mat-form-field>
                        <mat-error class="margin_tp"
                          *ngIf="billingCompanyForm.controls['companyRegisteredAddress'].touched && billingCompanyForm.controls['companyRegisteredAddress'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                      </div>
                      <div class="col-sm-3 ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Taluka</mat-label>
                          <input matInput placeholder="Taluka" formControlName="taluka" />

                        </mat-form-field>
                        <mat-error class="margin_tp"
                          *ngIf="billingCompanyForm.controls['taluka'].touched && billingCompanyForm.controls['taluka'].errors?.pattern">
                          Please enter valid taluka.
                        </mat-error>
                      </div>
                      <div class="col-sm-3 ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>District</mat-label>
                          <mat-select #select12 placeholder="District" class="colorblack" formControlName="district">
                            <mat-select-filter *ngIf="select12.focused" [placeholder]="'Filter'"
                              [displayMember]="'district'" [array]="districtData2"
                              (filteredReturn)="filteredListDistrict2 =$event"></mat-select-filter>
                            <mat-option *ngFor="let item of filteredListDistrict2" [value]="item.district">
                              {{item.district}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                        <mat-error class="margin_tp"
                          *ngIf="billingCompanyForm.controls['district'].touched && billingCompanyForm.controls['district'].errors?.pattern">
                          Please enter valid district.
                        </mat-error>
                      </div>
                      <div class="col-sm-3 ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>State</mat-label>
                          <mat-select #select11 placeholder="State" class="colorblack" formControlName="state">
                            <mat-select-filter *ngIf="select11.focused" [placeholder]="'Filter'"
                              [displayMember]="'state'" [array]="stateCityArr2"
                              (filteredReturn)="filteredList2 =$event"></mat-select-filter>
                            <mat-option *ngFor="let item of filteredList2" [value]="item.state"
                              (click)="getDistrict2(item.districts)">
                              {{item.state}}
                            </mat-option>
                          </mat-select>

                        </mat-form-field>
                        <mat-error class="margin_tp"
                          *ngIf="billingCompanyForm.controls['state'].touched && billingCompanyForm.controls['state'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                      </div>
                      <div class="col-sm-3  ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Pin Code
                            <!-- <span class="required-asterisk">*</span>  -->
                          </mat-label>
                          <input required formControlName="companyPinCode" maxlength="6" matInput
                            placeholder="Pincode(e.g:416008)" />


                        </mat-form-field>
                        <mat-error
                          *ngIf="billingCompanyForm.controls['companyPinCode'].touched && billingCompanyForm.controls['companyPinCode'].errors?.pattern">Please
                          enter valid PinCode</mat-error>

                        <mat-error
                          *ngIf="billingCompanyForm.controls['companyPinCode'].touched && billingCompanyForm.controls['companyPinCode'].errors?.required">
                          This field is mandatory.</mat-error>
                      </div>
                      <div class="col-sm-3 ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Country</mat-label>
                          <input matInput placeholder="Country" formControlName="country" />

                        </mat-form-field>
                        <mat-error class="margin_tp"
                          *ngIf="billingCompanyForm.controls['country'].touched && billingCompanyForm.controls['country'].errors?.pattern">
                          Please enter valid country.
                        </mat-error>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="row mt-2">

                      <div class="col-sm-12 ">
                        <h5 class="pb-0 mb-0">Contact Details :</h5>
                        <!-- <label class="header">Contact Details :</label> -->
                      </div>

                      <div class="col-sm-3 pt-0 mt-0">
                        <div class="row">
                          <div class="col-sm-10">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Company Contact Number
                                <!-- <span class="required-asterisk">*</span>  -->
                              </mat-label>
                              <input required matInput maxlength="10" matTooltip="Company Contact Number"
                                placeholder="Company Contact Number " formControlName="companyContactNumber" />


                            </mat-form-field>
                            <mat-error class="margin_tp"
                              *ngIf="billingCompanyForm.controls['companyContactNumber'].touched && billingCompanyForm.controls['companyContactNumber'].errors?.required">
                              This field is mandatory.
                            </mat-error>
                            <mat-error class="margin_tp"
                              *ngIf="billingCompanyForm.controls['companyContactNumber'].touched && billingCompanyForm.controls['companyContactNumber'].errors?.pattern">
                              Please enter valid number.
                            </mat-error>
                          </div>
                          <div class="col-sm-2 wApp ml-0 pl-0">
                            <i class="bi bi-whatsapp whatsapp-icon wApp fa-lg" (click)="WhatsApp();"
                              matTooltip="WhatsApp Integration"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-sm-12"> 
                     
                                <div class="row">
                              
                                    <div class="col-sm-12 pb-0 mb-0">
                                      <h5 class="pb-0 mb-0 mt-2">Bank Details :</h5>
                                
                                  </div>
                                </div>
          
          
                                <div class="row">
                                  <div class=" col-sm-3  ">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                      <mat-label>Bank Name</mat-label>
                                      <input class="form-control2" formControlName="bankName" 
                                      matInput placeholder="Bank Name" />
                                    </mat-form-field>
                                    <mat-error class="margin_tp"
                        *ngIf="billingCompanyForm.controls['bankName'].touched && billingCompanyForm.controls['bankName'].errors?.pattern">
                        Please enter valid bank Name.
                      </mat-error>
                                  </div>
                                  <div class=" col-sm-3  ">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                      <mat-label>Branch Name</mat-label>
                                      <input class="form-control2" formControlName="bankBranchName" 
                                      matInput placeholder="Branch Name" />
                                    </mat-form-field>
                                    <mat-error class="margin_tp"
                        *ngIf="billingCompanyForm.controls['bankBranchName'].touched && billingCompanyForm.controls['bankBranchName'].errors?.pattern">
                        Please enter valid Branch Name.
                      </mat-error>
                                  </div>
                                  <div class=" col-sm-3  ">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                      <mat-label>Account Number</mat-label>
                                      <input class="form-control2" maxlength="18" formControlName="accountNumber" 
                                      matInput placeholder="Account Number" />
                                    </mat-form-field>
                                    <mat-error class="margin_tp"
                        *ngIf="billingCompanyForm.controls['accountNumber'].touched && billingCompanyForm.controls['accountNumber'].errors?.pattern">
                        Please enter valid account Number.
                      </mat-error>
                      <mat-error class="margin_tp"
                        *ngIf="billingCompanyForm.controls['accountNumber'].touched && billingCompanyForm.controls['accountNumber'].errors?.maxlength">
                        Please enter maximum 18 digit account Number.
                      </mat-error>
                    
                                  </div>
                                  <div class=" col-sm-3  ">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                      <mat-label>IFSC Code</mat-label>
                                      <input class="form-control2" formControlName="ifcCode" 
                                      matInput maxlength="15" placeholder="IFSC Code" />
                                    </mat-form-field>
                                    <mat-error class="margin_tp"
                        *ngIf="billingCompanyForm.controls['ifcCode'].touched && billingCompanyForm.controls['ifcCode'].errors?.pattern">
                        Please enter valid IFSC Code .
                      </mat-error>
                                  </div>
                                  <div class=" col-sm-3  ">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                      <mat-label>UPI Id</mat-label>
                                      <input class="form-control2 smallCase" formControlName="upiId" 
                                      matInput placeholder="UPI Id" /> 
                                    </mat-form-field> 
                                    <mat-error class="margin_tp"
                        *ngIf="billingCompanyForm.controls['upiId'].touched && billingCompanyForm.controls['upiId'].errors?.pattern">
                        Please enter valid UPI Id.
                      </mat-error>
                                  </div>
                                
                                </div>
          
          
                              </div> -->
                  <div class="col-sm-12">
                    <div class="row mt-2">


                      <div class="col-sm-12 ">
                        <h5 class="pb-0 mb-0">Other Details :</h5>
                        <!-- <label class="header">Other Details :</label> -->
                      </div>
                      <div class="col-sm-3  pt-0 mt-0">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Company Status</mat-label>

                          <mat-select formControlName="billingStatus">
                            <mat-option value="Active">Active</mat-option>
                            <mat-option value="In-active">In-active</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-10 " *ngIf="submitButton">
                  </div>
                  <div class="col-sm-8" *ngIf="updateButton">
                  </div>


                  <div class="col-sm-2 mb-2 mt-2" *ngIf="submitButton && billingCreate">
                    <button class="btn btn-primary btn-sm example-full-width" type="button"
                      (click)="submitBillingCompany()">Save</button>
                  </div>
                  <div class="col-sm-2 mb-2 mt-2" *ngIf="updateButton && billingUpdate">
                    <button class="btn btn-primary btn-sm   example-full-width" type="button"
                      (click)="updateBillingDeatils()">Update</button>
                  </div>
                  <div class="col-sm-2 mb-2 mt-2" *ngIf="cancelButton">
                    <button class="btn btn-primary btn-sm example-full-width" (click)="cancelUpdate()"
                      type="reset">Cancel</button>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-12 pl-0 pr-2">
          <div class="row">
            <div class="card divbody borderradius p-3 pb-0">
              <div class="col-sm-12">
                <div class="row">
                  <div class="col-sm-9 ">
                    <h1 class="titlecolor"> Billing Company List</h1>
                  </div>
                  <div class="search-box col-sm-3 pb-2">
                    <input class="form-control amber-border" type="text" placeholder="Search"
                      formControlName="searchBilling" aria-label="Search" (keyup)="onKeyUpEvent($event)">
                    <div class="input-group-append">
                      <span class="input-group-text amber lighten-3" id="basic-text1"><span
                          class="material-symbols-outlined">search</span></span>
                    </div>
                  </div>
                  <!-- <div class="col-sm-3">
                              
                                <div class="input-group md-form form-sm form-1 pl-0">
                                  <div class="input-group-prepend">
                                    <span class="input-group-text purple lighten-3" id="basic-text1"><i class="fas fa-search text-white"
                                        aria-hidden="true"></i></span>
                                  </div>
                                  <input class="form-control my-0 py-1" type="text" formControlName="searchBilling" aria-label="Search" (keyup)="onKeyUpEvent($event)"
                                   placeholder="Search" aria-label="Search">
                                </div>
                            </div> -->
                  <div class="col-sm-12">
                    <table id="toDeviceList" class="table table-bordered data-table-custom">
                      <!-- <table id="toDeviceList" 
                                class="table table-hover table-striped table-bordered"> -->
                      <thead>
                        <tr class="text-center">
                          <th>Company ID</th>
                          <th>Company Name</th>
                          <th>Address</th>
                          <th>Proprietor/ Director</th>
                          <th>Company Type</th>
                          <th>Contact Number</th>

                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let billing of listofbilling | paginate : { id:'billingMaster',
                                    itemsPerPage: size, currentPage: page, totalItems:totalLength }"
                          (click)="prepopulation(billing._id)" (click)="toBillingCompanyForm()"
                          style="cursor:pointer; text-align: center ">
                          <td matTooltip="click to view">{{billing.companyId}}</td>
                          <td matTooltip="click to view">{{billing.companyName}}</td>
                          <td matTooltip="click to view">{{billing.companyRegisteredAddress}}</td>
                          <td matTooltip="click to view">{{billing.propriter}}</td>
                          <td matTooltip="click to view">{{billing.companyType}}</td>
                          <td matTooltip="click to view">{{billing.companyContactNumber}}</td>
                          <!-- <td class="text-center">
                                        <i matTooltip="View" (click)="prepopulation(billing._id)" class="fas fa-eye"></i>
                                       </td> -->
                        </tr>


                      </tbody>
                    </table>
                  </div>
                  <div class="col-sm-12 pt-0 text-right">
                    <pagination-controls id="billingMaster" directionLinks="true" (pageChange)="onPageChange($event)"
                      class="float-right pagination my-pagination"></pagination-controls>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>


      </div>
    </div>
  </form>
</div>