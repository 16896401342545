<div class="container-fluid bgcolor2" [formGroup]="homeForm">
    <form  [formGroup]="homeForm" autocomplete="off" novalidate>
    <div class="row">
        <div class="col-sm-1">
 
        </div>
        <div class="col-sm-11">
               
                <div class="row">
                    <div class="col-sm-12">
                        <!-- <div class="row pt-2">
                            
                                <img class="imgallign" style="width: 15rem;" height="320rem" src="../assets/nurse.png" alt="Girl in a jacket">
                                <div class="col-sm-12 text-center">
                                    <div class="pt-5 pb-5 card cardbgdark">
                                        <h1 class="white">Welcome, Dr.Nilesh to F.C Road Branch</h1>
                                        <h3 class="">Have a nice day here</h3>
                                    </div>
                                </div>
                        </div> -->
                        <div class="row">
                            <!-- <div class="col-sm-1 text-left">
                               
                                <h3><b></b></h3>
                            </div>   -->
                            <!-- <div class="col-sm-8 text-right">
                                  <mat-form-field class="example-full-width" appearance="outline">
                                      <mat-label>Global Search By</mat-label>
                                    <mat-select placeholder="Basic">
                                      <mat-select-filter
                                        [array]="variables"
                                        (filteredReturn)="filteredList1 = $event"
                                      ></mat-select-filter>
                                      <mat-option *ngFor="let item of filteredList1" [value]="item" (click)="openDepartmentPopUp(item);">
                                        {{ item }}
                                      </mat-option>
                                    </mat-select>
                                </mat-form-field>
                           </div> --> 
                           <div class="col-sm-9 text-left justify-content-left">
                          
                                                        <div class="row pl-5 page-title-box my-4">
                                                    <h1 class="welcome-text mb-0">{{greet}} 
                                                         <span class="text-black fw-bold">{{userName}}</span></h1>
                                                    <h3 class="welcome-sub-text pl-2 pt-2">{{branchData.branchName}} </h3>
                                                    <!-- <h3 class="welcome-sub-text pl-2 pt-2"> to F.C Road Branch </h3> -->
                                                </div>
                
                        </div> 
                            <div class="col-sm-3 text-right pr-3">
                                <div class="row pt-2 page-title-box pr-3 justify-content-end my-4">
                                    <label class="subtitlecolor2 mt-1 ">{{todaysDate  | date:'EEEE, MMMM d, y, h:mm a'}}</label>
                                </div>
                             
                            </div>
                            
                        </div>
                     
                    </div>
                    <div class="col-sm-12">
                        <div class="row">
              
                            <div class="col-sm-12">
                               <div class="icons-wraper dashboard-boxes">
                              
                        <ul >
                            <li  *ngIf="dashboardView">
                                <a  class="icon-card" [routerLink]="['/dashboard']">
                                    <div class="col-sm-6 pb-2 icon-box">
                                        <img src="../../assets/dashboard_icon.svg"  class="icon-img">
                                        <img src="../../assets/icon-bg1.svg"  class="icon-bg justify-content-center">
                                    </div>
                                    <h4>Dashboard</h4>
                                 </a>
                        </li>
                      
                            <li  *ngIf="bookAppointmentsView">
                                <a  class="icon-card" [routerLink]="['/home/calander']">
                                    <div class="col-sm-6 pb-2 icon-box">
                                        <img src="../../assets/book_icon.svg" class="icon-img">
                                        <img src="../../assets/icon-bg2.svg" class="icon-bg">
                                    </div>
                                    <h4>Book Appointment</h4>
                                 </a>
                        
               
                    </li>
                
                    <li *ngIf="InquiryView">
                        <a  class="icon-card" [routerLink]="['/home/inquiry']">
                            <div class="col-sm-6 pb-2 icon-box">
                                <img src="../../assets/inquiry_icon.svg" class="icon-img">
                                <img src="../../assets/icon-bg3.svg" class="icon-bg">
                            </div>
                            <h4>Inquiry</h4>
                         </a>

                    </li>
                
                    <li  *ngIf="todaysAppointmentView">
                        <a class="icon-card" >
                            <div class="col-sm-6 pb-2 icon-box" [routerLink]="['/home/opt/dashboard']">
                                <img src="../../assets/appointment_icon.svg" class="icon-img">
                                <img src="../../assets/icon-bg4.svg" class="icon-bg">
                            </div>
                            <h4>Todays Appointment</h4>
                         </a>

                    </li>
                
                    <li  *ngIf="billingView">
                        <a  class="icon-card"  (click)="openBillingPopupPopUp()">
                            <div class="col-sm-6 pb-2 icon-box">
                                <img src="../../assets/billing_icon.svg" class="icon-img">
                                <img src="../../assets/icon-bg5.svg" class="icon-bg">
                            </div>
                            <h4>Billing</h4>
                         </a> 
                        
                    </li>
                
                    <li  *ngIf="repairComplaintView">
                        <a  class="icon-card" [routerLink]="['/repair-complaint']">
                            <div class="col-sm-6 pb-2 icon-box">
                                <img src="../../assets/repair_icon.svg" class="icon-img">
                                <img src="../../assets/icon-bg6.svg" class="icon-bg">
                            </div>
                            <h4>Repair</h4>
                         </a>
                         
                    </li>
                    <li  *ngIf="earMouldView">
                        <a  class="icon-card" [routerLink]="['/home/ear-mould']">
                            <div class="col-sm-6 pb-2 icon-box">
                                <img src="../../assets/hear_icon.svg" class="icon-img">
                                <img src="../../assets/icon-bg7.svg" class="icon-bg">
                            </div>
                            <h4>Ear Mould</h4>
                         </a>
                    </li>
                
                    <li  *ngIf="customHaFormView">
                        <a  class="icon-card" [routerLink]="['/home/custom-hearing-aid']">
                            <div class="col-sm-6 pb-2 icon-box">
                                <img src="../../assets/ha_icon.svg" class="icon-img">
                                <img src="../../assets/icon-bg5.svg" class="icon-bg">
                            </div>
                            <h4>Custom HA</h4>
                         </a>
                       
                    </li>
                
                    <li  *ngIf="haFormView">
                        <a  class="icon-card" [routerLink]="['/home/ha-form']">
                            <div class="col-sm-6 pb-2 icon-box">
                                <img src="../../assets/trail_icon.svg" class="icon-img">
                                <img src="../../assets/icon-bg8.svg" class="icon-bg">
                            </div>
                            <h4>HA Trial Form</h4>
                         </a>
                         
                    </li>
                
                    <li  *ngIf="PlaceOrderView">
                        <a  class="icon-card" [routerLink]="['/home/place-order']">
                            <div class="col-sm-6 pb-2 icon-box">
                                <img src="../../assets/order_icon.svg" class="icon-img">
                                <img src="../../assets/icon-bg2.svg" class="icon-bg">
                            </div>
                            <h4>Place Order</h4>
                         </a>
                            
                    </li>
                
                    <li *ngIf="stockView">
                        <a  class="icon-card"  (click)="openInventoryPopUp()">
                            <div class="col-sm-6 pb-2 icon-box">
                                <img src="../../assets/stock_icon.svg" class="icon-img">
                                <img src="../../assets/icon-bg12.svg" class="icon-bg">
                            </div>
                            <h4>Stock</h4>
                         </a>
                         
                    </li>
                
                    <li *ngIf="PatientView">
                        <a  class="icon-card" [routerLink]="['/home/patient-master']">
                            <div class="col-sm-6 pb-2 icon-box">
                                <img src="../../assets/patient_icon.svg" class="icon-img">
                                <img src="../../assets/icon-bg11.svg" class="icon-bg">
                            </div>
                            <h4>Patient</h4>
                         </a>
                          
                    </li>
                
                    <li  *ngIf="clinicExpensesView">
                        <a  class="icon-card" [routerLink]="['/home/clinic-expenses']">
                            <div class="col-sm-6 pb-2 icon-box">
                                <img src="../../assets/expense_icon.svg" class="icon-img">
                                <img src="../../assets/icon-bg6.svg" class="icon-bg">
                            </div>
                            <h4>Clinic Expenses</h4>
                         </a>
                           
                    </li>
                
                    <li  *ngIf="courierBookView">
                        <a  class="icon-card" [routerLink]="['/home/inward-outward']">
                            <div class="col-sm-6 pb-2 icon-box">
                                <img src="../../assets/courier_icon.svg" class="icon-img">
                                    <img src="../../assets/icon-bg12.svg" class="icon-bg">
                            </div>
                            <h4>Courier Book</h4>
                         </a>
                           
                    </li>
                
                    <li  *ngIf="reportView">
                        <a  class="icon-card" [routerLink]="['/home/report']">
                            <div class="col-sm-6 pb-2 icon-box">
                                <img src="../../assets/report_icon.svg" class="icon-img">
                                    <img src="../../assets/icon-bg13.svg" class="icon-bg">
                            </div>
                            <h4>Report</h4>
                         </a>
                           
                    </li>
                
                    
                
                    <li  *ngIf="chequeView">
                        <a  class="icon-card" [routerLink]="['/cheque-list']">
                            <div class="col-sm-6 pb-2 icon-box">
                                <img src="../../assets/check_icon.svg" class="icon-img">
                                <img src="../../assets/icon-bg1.svg" class="icon-bg">
                            </div>
                            <h4>Cheque</h4>
                         </a>
                          
                    </li>
                
                    <li  *ngIf="holidayLeaveView">
                        <a  class="icon-card" [routerLink]="['/holiday-leave']">
                            <div class="col-sm-6 pb-2 icon-box">
                                <img src="../../assets/holiday_icon.svg" class="icon-img">
                                <img src="../../assets/icon-bg15.svg" class="icon-bg">
                            </div>
                            <h4>Holiday/Leave</h4>
                         </a>
                           
                    </li>
                    <li  *ngIf="settingsView">
                        <a  class="icon-card" (click)="openMasterPopUp()">
                            <div class="col-sm-6 pb-2 icon-box">
                                <img src="../../assets/settings_icon.svg" class="icon-img">
                                <img src="../../assets/icon-bg14.svg" class="icon-bg">
                            </div>
                            <h4>Settings</h4> 
                         </a>      
                    </li>
                </ul>
            </div>
            </div>
                    </div>
                    </div>
                  
                    <div class="col-sm-12">
                        <div class="row five-cols">
                          <div class="col-md-1 ">
                  
                                    <div class=" card hw mb-2"> 
                                        <div class="col-sm-12 calendar-wraper text-left">
                                            <h2 class="mt-2">Todays Birthday</h2>
                                        </div>  
                                        <div class="col-sm-12 mt-0 justify-content-center " *ngIf="noBirthdayData" >
                             
                                          <div  class="card justify-content-center bgcolor2 col-sm-12 pt-2 pb-2" >
                                          <label  class="blColor justify-content-center">No Data Found </label>
                                           </div>
        
                                          
                                                 </div>
                                        <div  *ngIf="!noBirthdayData" class="og p-2" >
                                               <div class="col-sm-12  pb-3 p-2" *ngFor="let item of birthdayData" >
                                                   <div  class="card  bgColor1 col-sm-12 pt-2 pb-2"
                                                   (click)="birthdayPopup(item._id)" >
                                                        <label  class="blColor text-left">Patient :
                                                            <label class="bolD">{{item.firstName}} {{item.lastName}}</label></label><br>
                                                        <label  class="blColor">Mobile Number :<label class="bolD">{{item.mobile1}}</label></label><br>
                                                        <label  class="blColor">DOB :<label class="bolD">{{item.dob | date:'dd/MM/yyyy'}}
                                                            </label>
                                                        </label>
                                                    </div>
                                                 
                                                     </div>
                                       </div> 
                                    </div>
                              
                            </div>
                            <div *ngIf="followUpView" class="col-md-1 ">
                             
                                        <div class=" card hw mb-2"> 
                                            <div class="col-sm-12 calendar-wraper text-left">
                                                <h2 class="mt-2">Todays Follow Up</h2>
                                            </div>  
                                            <div class="col-sm-12 mt-0 justify-content-center" *ngIf="noFollowup" >
                                             
                                              <div  class="card justify-content-center bgcolor2 col-sm-12 pt-2 pb-2" >
                                                <label  class="blColor justify-content-center">No Data Found </label>
                                                 </div>
                                                     </div>
                                                     <div  *ngIf="!noFollowup" class="og p-2" >
                                                        <div class="col-sm-12  pb-3 p-2" *ngFor="let item of followupData" >
                                                           <div  class="card  bgColor3 col-sm-12 pt-2 pb-2" 
                                                            (click)="openFollowupDataPopUp(item._id)" >
                                                                 <label  class="blColor">Patient:<label class="bolD">{{item.patientName}} 
                                                                    </label>
                                                                 </label><br>
                                                                 <label  class="blColor">Status :<label class="bolD">{{item.followupStatus}}
                                                                    </label>
                                                                 </label>
                                                                 <br>
                                                                 <label  class="blColor">Time Period :<label class="bolD">{{item.followupTime}}
                                                                </label>
                                                             </label>
                                                                 
                                                             </div>
                                                          
                                                              </div>
                                                </div>
                                        </div>
                                      
                                </div>
                                <div *ngIf="chequeListView" class="col-md-1 ">
                                   
                                            <div class=" card hw mb-2"> 
                                                <div class="col-sm-12 calendar-wraper text-left">
                                                    <h2 class="mt-2">Cheque To Be deposited</h2>
                                                </div>  
                                                <div class="col-sm-12 mt-0 justify-content-center" *ngIf="noDataCheque" >
                                                 
                                                  <div  class="card justify-content-center bgcolor2 col-sm-12 pt-2 pb-2" >
                                                    <label  class="blColor justify-content-center">No Data Found </label>
                                                     </div>
                                                         </div>
                                                <div *ngIf="!noDataCheque" class="og p-2" >
                                                    <div class="col-sm-12 p-2" *ngFor="let item of paymentHistoryData" >
                                                      
                                                         <div 
                                                         class="card bgColor22 col-sm-12 pt-2 pb-2"
                                                         (click)="openChequeDetailsPopUp(item.receiptNo,item.chequeDetails[0].chequeNo)">
                                                             <label  style="cursor:pointer;" class="blColor">Cheque No :<label class="bolD">
                                                                {{item.chequeDetails[0].chequeNo}} </label></label><br>
                                                             <label  class="blColor">Bank Name :<label class="bolD">{{item.chequeDetails[0].bankName}} 
                                                                </label>
                                                             </label>
                                                         </div>
                                                    
                                                          </div>
                                                        </div>
                                            </div>
                                        
                                 
                                    </div>
                                    <div class="col-md-1 ">
                                                <div class=" card hw mb-2"> 
                                                    <div class="col-sm-12 calendar-wraper text-left">
                                                        <h2 class="mt-2">Pending Bill</h2>
                                                    </div>  
                                                    <div class="col-sm-12 mt-0 justify-content-center " *ngIf="noPendingBill" >
                                         
                                                      <div  class="card justify-content-center bgcolor2 col-sm-12 pt-2 pb-2" >
                                                      <label  class="blColor justify-content-center">No Data Found </label>
                                                       </div>
                        
                                                      
                                                             </div>
                                                    <div  *ngIf="!noPendingBill" class="og p-2" >
                                                           <div class="col-sm-12  pb-3 p-2" *ngFor="let item of getUnpaidData" >
                                                            <span *ngIf="item.paidAmount !== item.totalAmount && item.serviceInfo[0];
                                                            else elseBlock22">
                                                               <div class="card bgColorBlue col-sm-12 pt-2 pb-2" 
                                                               *ngIf="item.serviceInfo[0].discount !== item.serviceInfo[0].totalRate" >
                                                                    <label class="blColor">Patient :<label class="bolD">{{item.patientName}}</label></label><br>
                                                                    <label  class="blColor">Mobile Number :<label class="bolD">{{item.mobile}}</label></label><br>
                                                                    <label  class="blColor">Total Amount :<label class="bolD">{{item.totalAmount}}</label></label><br>
                                                                    <label  class="blColor">Paid Amount :<label class="bolD">{{item.paidAmount}}</label></label><br>
                                                                   <label  class="blColor">Remaining Amount :<label class="bolD">{{item.totalAmount - item.paidAmount}}
                                                                    </label>
                                                                   </label>
                                                                </div> 
                                                            </span>
                                                            <ng-template #elseBlock22>
                                                                    <div *ngIf="item.paidAmount !== item.totalAmount && !item.serviceInfo[0];"
                                                                    class="card bgColorBlue col-sm-12 pt-2 pb-2" 
                                                                     >
                                                                         <label  class="blColor">Patient :<label class="bolD">{{item.patientName}}</label></label><br>
                                                                         <label  class="blColor">Mobile Number :<label class="bolD">{{item.mobile}}</label></label><br>
                                                                         <label  class="blColor">Total Amount :<label class="bolD">{{item.totalAmount}}</label></label><br>
                                                                         <label  class="blColor">Paid Amount :<label class="bolD">{{item.paidAmount}}</label></label><br>
                                                                        <label  class="blColor">Remaining Amount :<label class="bolD">{{item.totalAmount - item.paidAmount}}
                                                                         </label>
                                                                        </label>
                                                                     </div>
                                                                   
                                                              </ng-template> 

                                                           
                                                             
                                                                 </div>
                                                   </div> 
                                               
                                                </div>
                                               
                                        </div>
                                        <div class="col-md-1 ">
                                  
                                                    <div class=" card hw mb-2"> 
                                                        <div class="col-sm-12 calendar-wraper text-left">
                                                            <h2 class="mt-2">Trial Aid Issued</h2>
                                                        </div>  
                                                        <div class="col-sm-12 mt-0 justify-content-center " *ngIf="Issued" >
                                             
                                                          <div  class="card justify-content-center bgcolor2 col-sm-12 pt-2 pb-2" >
                                                          <label  class="blColor justify-content-center">No Data Found </label>
                                                           </div>
                            
                                                          
                                                                 </div>
                                                        <div  *ngIf="!Issued" class="og p-2" >
                                                               <div class="col-sm-12  pb-3 p-2" *ngFor="let item of issuedList" >
                                                                   <div  class="card bgColorGreen col-sm-12 pt-2 pb-2"  >
                                                                        <label  class="blColor">Patient :<label class="bolD">{{item.patientName}}</label></label><br>
                                                                        <label  class="blColor">Mobile Number :<label class="bolD">{{item.phoneNo}}</label></label><br>
                                                                        <label  class="blColor">Device Name :<label class="bolD">{{item.deviceName}}</label></label><br>
                                                                        <label  class="blColor">Issued Date :<label class="bolD">{{item.updatedAt | date:'dd/MM/yyyy'}}
                                                                            </label>
                                                                        </label><br>
                                                                    </div>
                                                                 
                                                                     </div>
                                                       </div> 
                                                    </div>
                                                  
                                            </div>
                            </div>
                            </div>

                </div>
             
        </div>
        
    </div>
    </form>
</div>
