<div class="container-fluid bgcolor2" [formGroup]="advanceAmountForm">
    <form [formGroup]="advanceAmountForm" autocomplete="off" novalidate>
      <div class="row">
          <div class="col-sm-1">
     
          </div> 
          <div class="col-sm-11 ">
            <div class="row"> 
              <div class="col-sm-12"> 
                <div class="row ">
                <div class="col-sm-9">
                    <h1 class="pt-2 pl-1" id="toadvanceAmountForm">Advance Amount</h1>
                    </div>
                    <div class="col-sm-3 mt-1">
                      <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                        <a (click)="onback()" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">arrow_back</span>Back</a>
                        <a (click)="toBillingLst()" class="d-flex align-items-center ms-3 pr-2 mt-2"><span class="material-symbols-outlined pr-2">reorder</span>List</a>
                   </div>
              </div>
            </div>
           </div>
           </div>
              <div class="row">
                  <div class="col-sm-12 pr-4">
                      <div class="row">
                          <div class="card divbody mb-3 borderradius p-2">
                             
                              <div class="col-sm-12">
                                  <div class="row">
                                    <div *ngIf="userType == 'Super Admin'" class="col-sm-3 mb-2">
                                        <div class="row">
                                          <div class="col-sm-10 pr-1">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>Branch </mat-label>
                                          <mat-select required #select1 formControlName="branchRefId" >
                                            <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'branchName'"
                                            [array]="BranchList" (filteredReturn)="filteredListBranch =$event"></mat-select-filter>
                                           
                                            <mat-option *ngFor="let item of filteredListBranch" [value]="item._id" 
                                            (click)="branchClick(item._id,item.branchName);"
                                            >
                                             {{ item.branchName }}</mat-option>
                                            </mat-select>
                                            
                                        </mat-form-field>
                                        <mat-error class="margin_tp"
                                            *ngIf="advanceAmountForm.controls['branchRefId'].touched && advanceAmountForm.controls['branchRefId'].errors?.required">
                                            This field is mandatory.
                                          </mat-error>
                                       </div>
                                       <div class="col-sm-2 mt-2 mTop no-print">
                                        <i (click)="openBranchPopUp();" matTooltip="Add New Branch" 
                                        class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                                      </div>
                                       </div>
                                      </div>
              
                                      <div *ngIf="patientCreate" class="col-sm-3">
                                      <div class="row">
                                        <div class="col-sm-10 pr-1">
                                      <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>Select Patient </mat-label>
                                        <input (click)="openPatientSearchPopUp();" matInput readonly placeholder="Patient Name" formControlName="patientName" />
                                      </mat-form-field> 
                                      
                                      <mat-error class="margin_tp"
                                        *ngIf="advanceAmountForm.controls['patientName'].touched && advanceAmountForm.controls['patientName'].errors?.required">
                                        This field is mandatory.
                                      </mat-error>
                                      </div>
                                      <div class="col-sm-2 mt-2 mTop no-print">
                                        <i (click)="openPatientPopUp();" matTooltip="Add New Patient" 
                                        class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                                      </div>
                                      </div>
                                    </div>
                                    <div *ngIf="!patientCreate" class="col-sm-3">
                                      <div class="row">
                                        <div class="col-sm-12 pr-1">
                                      <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>Select Patient </mat-label>
                                 
                                        <input (click)="openPatientSearchPopUp();" matInput readonly placeholder="Patient Name" formControlName="patientName" />
                                      </mat-form-field>
                                      <mat-error class="margin_tp"
                                        *ngIf="advanceAmountForm.controls['patientName'].touched && advanceAmountForm.controls['patientName'].errors?.required">
                                        This field is mandatory.
                                      </mat-error>
                                      </div>
                                      
                                      </div>
                                      </div> 
                                      <div *ngIf="billingCreate" class="col-sm-3">
                                        <div class="row">
                                          <div class="col-sm-10 pr-1">
                                            <mat-form-field class="example-full-width" appearance="outline">
                                              <mat-label>Billing Company
                                                <!-- <span class="required-asterisk">*</span> -->
                                              </mat-label>
                                              <mat-select #select1 required formControlName="billingCompanyRefId">
                                                <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'"
                                                  [displayMember]="'companyName'" [array]="billingCompanyList"
                                                  (filteredReturn)="filteredListBillingCompany =$event"></mat-select-filter>
                                                <mat-option *ngFor="let item of filteredListBillingCompany" [value]="item._id"
                                                  (click)="companyClick(item._id,item.companyName);">
                                                  {{ item.companyName }}</mat-option>
                                              </mat-select>
                    
                                            </mat-form-field>
                                            <mat-error class="margin_tp"
                                              *ngIf="advanceAmountForm.controls['billingCompanyRefId'].touched && advanceAmountForm.controls['billingCompanyRefId'].errors?.required">
                                              This field is mandatory.
                                            </mat-error>
                                          </div>
                                          <div class="col-sm-2 mt-2 no-print">
                                            <i (click)="openBillingCompPopUp();" matTooltip="Add Billing Company" class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                                          </div>
                                        </div> 
                                      </div>
                                      <div *ngIf="!billingCreate" class="col-sm-3">
                                        <div class="row">
                                          <div class="col-sm-12 pr-1">
                                            <mat-form-field class="example-full-width" appearance="outline">
                                              <mat-label>Billing Company
                                                <!-- <span class="required-asterisk">*</span> -->
                                              </mat-label>
                                              <mat-select #select1 formControlName="billingCompanyRefId">
                                                <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'"
                                                  [displayMember]="'companyName'" [array]="billingCompanyList"
                                                  (filteredReturn)="filteredListBillingCompany =$event"></mat-select-filter>
                                                <mat-option *ngFor="let item of filteredListBillingCompany" [value]="item._id"
                                                  (click)="companyClick(item._id,item.companyName);">
                                                  {{ item.companyName }}</mat-option>
                                              </mat-select> 
                    
                                            </mat-form-field>
                                            <mat-error class="margin_tp"
                                              *ngIf="advanceAmountForm.controls['billingCompanyRefId'].touched && advanceAmountForm.controls['billingCompanyRefId'].errors?.required">
                                              This field is mandatory.
                                            </mat-error>
                                          </div>
                    
                                        </div>
                                      </div>
                                      <div  class="col-sm-3">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>Mode Of Payment
                                          </mat-label>
            
                                          <mat-select placeholder="Payment Mode" class="colorblack" formControlName="modeOfPayment">
                                       <mat-option (click)="ModeOfPaymentClick('Cash')" value="Cash">Cash</mat-option>
                                       <mat-option (click)="ModeOfPaymentClick('Card')" value="Card">Card</mat-option>
                                       <mat-option (click)="ModeOfPaymentClick('UPI')" value="UPI">UPI</mat-option>
                                       <mat-option (click)="ModeOfPaymentClick('Cheque')" value="Cheque">Cheque</mat-option>
                                       <mat-option (click)="ModeOfPaymentClick('RTGS/NEFT/IMPS')" value="RTGS/NEFT/IMPS">RTGS/NEFT/IMPS</mat-option>
                                          </mat-select>
                                        </mat-form-field>
                                        <mat-error class="margin_tp"
                                        *ngIf="advanceAmountForm.controls['modeOfPayment'].touched && advanceAmountForm.controls['modeOfPayment'].errors?.required">
                                        This field is mandatory.
                                      </mat-error>
                                    </div>
                                        <div class="col-sm-3">
                                            <mat-form-field class="example-full-width" appearance="outline">
                                              <mat-label>Advance Amount</mat-label>
                                              <input matInput placeholder="Amount" maxlength="7"
                                               formControlName="paidAmount" [readonly]="readonly" />
                                            </mat-form-field>
                                            <mat-error class="margin_tp"
                                            *ngIf="advanceAmountForm.controls['paidAmount'].touched && advanceAmountForm.controls['paidAmount'].errors?.required">
                                            This field is mandatory.
                                          </mat-error>
                                            <mat-error class="margin_tp"
                                            *ngIf="advanceAmountForm.controls['paidAmount'].touched && advanceAmountForm.controls['paidAmount'].errors?.pattern">
                                            Please enter valid Amount.
                                          </mat-error>
                                          </div>
                                          <div class="col-sm-3">
                                            <mat-form-field class="example-full-width" appearance="outline">
                                              <mat-label>Total Amount</mat-label>
                                              <input matInput placeholder="Total Amount" maxlength="8"
                                               formControlName="totalAmount" [readonly]="readonlyAmt" (change)="calculateAmt($event)"/>
                                            </mat-form-field>
                                            <!-- <mat-error class="margin_tp"
                                            *ngIf="advanceAmountForm.controls['totalAmount'].touched && advanceAmountForm.controls['totalAmount'].errors?.required">
                                            This field is mandatory.
                                          </mat-error> -->
                                            <mat-error class="margin_tp"
                                            *ngIf="advanceAmountForm.controls['totalAmount'].touched && advanceAmountForm.controls['totalAmount'].errors?.pattern">
                                            Please enter valid Amount.
                                          </mat-error>
                                          </div>
                                        
                                        <div class="col-sm-4  ">
                                            <mat-form-field class="example-full-width" appearance="outline">
                                              <mat-label>Description</mat-label>
                                              <textarea matInput formControlName="description" maxlength="1000"
                                              cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                              cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" ></textarea>
                                            </mat-form-field>
                                          </div>
                                        
                                      <div class="col-sm-2 offset-sm-8 mb-2 mt-2" *ngIf="submitButton && advanceAmountCreate"> 
                                          <button class="btn btn-primary btn-sm example-full-width"  type="button" 
                                          (click)="submitData()">Save </button>
   
                                      </div>
                                      <div class="col-sm-2 offset-sm-8 mb-2 mt-2" *ngIf="updateButton" >
                                        <button type="button" class="btn btn-sm btn-primary example-full-width"
                                        (click)="updateData()" >Update </button>  
                                       </div>

                                    <div class="col-sm-2 mb-2 mt-2">
                                      <button class="btn btn-primary btn-sm example-full-width"  (click)="cancelUpdate()" type="reset">Cancel</button>
                                      </div>
                                    
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-sm-12 pl-0 pr-2">
                  <div class="row">
                      <div class="card divbody borderradius p-3 pb-0">
                              <div class="col-sm-12">
                                  <div class="row">
                                      <div class="col-sm-9 ">
                                          <h1 class="titlecolor">Advance Amount List</h1>
                                      </div>
                                      <div class="search-box col-sm-3 pb-2">
                                        <input class="form-control amber-border" type="text" placeholder="Search" 
                                        formControlName="searchBilling" aria-label="Search" (keyup)="onKeyUpEvent($event)"
                                        >
                                        <div class="input-group-append">
                                           <span class="input-group-text amber lighten-3" id="basic-text1"><span class="material-symbols-outlined">search</span></span>
                                        </div>
                                     </div>
                                     
                                        <div class="col-sm-12 table-responsive">
                                          <table id="toBillingLst" 
                                       class="table table-bordered data-table-custom">
                                       
                                              <thead>
                                                  <tr class="text-center">
                                                      <th>Sr.No.</th>
                                                       <th>Payment Date</th>
                                                       <th>Payment Mode</th>
                                                       <th>Paid Amount By Cash/Cheque</th>
                                                       <th>Total Amount</th>
                                                       <th>Remaining Balance</th>
                                                       <th>Patient Name</th>
                                                      <th>Receipt No</th>
                                                  </tr>
                                              </thead>  
                                              <tbody> 
                                                  <tr *ngFor="let item of advanceList | paginate : { id:'advanceAmountFormMaster',
                                                  itemsPerPage: size, currentPage: page, totalItems:totalLength } let i = index" 
                                                   (click)="toadvanceAmountForm()" (click)="prepopulation2(item.receiptNo)" 
                                                   style="cursor:pointer; text-align: center ">
                                                   <td matTooltip="Click To View">{{i+1}}</td>
                                                   <td  matTooltip="Click To View" 
                                                   >{{item.createdAt | date:'dd/MM/yyyy'}}
                                                 </td>
                                                 <td matTooltip="Click To View" >{{item.modeOfPayment}}</td>
                                                 <td *ngIf="item.modeOfPayment !== 'Cheque'" matTooltip="Click To View" >{{item.paidAmount}}</td>
                                                 <td *ngIf="item.chequeDetails.length > 0 && item.modeOfPayment == 'Cheque'" matTooltip="Click To View" >{{item.chequeDetails[0].amount}}</td>
                                                 <td matTooltip="Click To View" >{{item.totalAmount}}</td>
                                                 <td matTooltip="Click To View" >{{item.balanceAmount}}</td>
                                                 <td matTooltip="Click To View" >{{item.patientName}}</td>
                                                 <td class="labelStyle"  >    
                                                  <a href="#"  [routerLink]="['/receipt/',item.receiptNo]"
                                                  class="repairtb link-primary" 
                                                    matTooltip="Receipt View">{{item.receiptNo}}</a>
                                                    </td>
                                                     </tr>
                                              </tbody> 
                                          </table>
                                      </div>
                                      <div class="col-sm-12 pt-0 text-right">
                                              <pagination-controls id="advanceAmountFormMaster" directionLinks="true"
                                    (pageChange)="onPageChange($event)" class="float-right pagination my-pagination"></pagination-controls>
                                          
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
  
                  </div>
              
  
          </div>
      </div>
      </form>
  </div>