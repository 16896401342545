import { DatePipe } from '@angular/common';
import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { ApiServiceService } from '../api-service.service';


@Component({
  selector: 'app-popup-tracking-status',
  templateUrl: './popup-tracking-status.component.html',
  styleUrls: ['./popup-tracking-status.component.scss']
})
export class PopupTrackingStatusComponent implements OnInit {
  listOfCheckupDetails: any;
  userType: string;
  branchRef: string;
  clinicRef: string;
  allPatientStatus: any;
  filteredListPatientStatus: any;
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService, 
    public router: Router,
     public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    public route : ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: {},
    private dialogRef: MatDialogRef<PopupTrackingStatusComponent>
  ) { dialogRef.disableClose = true; }
  trackingStatusForm: FormGroup;
  page:number = 1;
  size:number = 10;
  listOfPatients: any;
  searchText: any;
  totalLength:number;
  ngOnInit(): void { 
  this.page=1;
  this.branchRef = sessionStorage.getItem('branchRef');
  this.clinicRef = sessionStorage.getItem('clinicRef');
  this.userType = sessionStorage.getItem('userType');
    this.trackingStatusForm = this.fb.group({
      "searchData": new FormControl(''),
      "trackingStatusName": new FormControl(''),    
    });
    this.getCheckupDetails(); 
    this.fetchPatientStatusList();
  }
  fetchPatientStatusList() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
    this.apimicroservice.getTrackingStatusAllData(branchRef,clinicRef).subscribe(res => {
      ////
      this.allPatientStatus = res.data;
      this.filteredListPatientStatus = this.allPatientStatus.slice();
    });
  }else{
    this.apimicroservice.getTrackingStatusAllData(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
      ////
      this.allPatientStatus = res.data;
      this.filteredListPatientStatus = this.allPatientStatus.slice();
    });
  }
  }
  getCheckupDetails(){
    let pg = 1;
    let size = 10;
    let search = ''; 
    let payload = this.trackingStatusForm.value;
    payload = payload.trackingStatusName;
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
    this.apimicroservice.getCheckupDetailsAllData(pg,size,payload,search,branchRef,clinicRef).subscribe(res => {
        this.listOfCheckupDetails = res.data.data; 
        this.totalLength = res.data.totalItems;
      });
    }else{
      this.apimicroservice.getCheckupDetailsAllData(pg,size,payload,search,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
        this.listOfCheckupDetails = res.data.data; 
        this.totalLength = res.data.totalItems;
      });
    }
      }
  
  toRepairForm() {
    document.getElementById("toRepairForm").scrollIntoView();
  }
  toRepairList() {
    document.getElementById("toRepairList").scrollIntoView();
  }
  
  onPageChange(event) {
    // this.spinner.show();
    this.page = event;
    let payload = this.trackingStatusForm.value;
    payload = payload.trackingStatusName;
    this.searchText = this.trackingStatusForm.get('searchData').value;
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
    this.apimicroservice.getCheckupDetailsAllData(this.page,this.size,payload,this.searchText,branchRef,clinicRef).subscribe(res => {
      this.listOfCheckupDetails = res.data.data;
      this.totalLength = res.data.totalItems;
      this.page = res.data.pageNumber;
      if (res.status) {
        //  this.apimicroservice.openSnackbar(res.message);
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    });
  }else{
    this.apimicroservice.getCheckupDetailsAllData(this.page,this.size,payload,this.searchText,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
      this.listOfCheckupDetails = res.data.data;
      this.totalLength = res.data.totalItems;
      this.page = res.data.pageNumber;
      if (res.status) {
        //  this.apimicroservice.openSnackbar(res.message);
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    });
  }
  }
  onKeyUpEvent(event: any) {
    let payload = this.trackingStatusForm.value;
    payload = payload.trackingStatusName;

    let pg = 1;
    let size = 10;
    this.searchText = this.trackingStatusForm.get('searchData').value;
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
    this.apimicroservice.getCheckupDetailsAllData(pg,size,payload,this.searchText,branchRef,clinicRef).subscribe(res => {
      this.listOfCheckupDetails = res.data.data;
      this.totalLength = res.data.totalItems;
      this.page = res.data.pageNumber;
      //this.buildingLenght = this.listofbuild.length;
      if (res.status) {
        //  this.apimicroservice.openSnackbar(res.message);
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    });
  }else{
    this.apimicroservice.getCheckupDetailsAllData(pg,size,payload,this.searchText,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
      this.listOfCheckupDetails = res.data.data;
      this.totalLength = res.data.totalItems;
      this.page = res.data.pageNumber;
      //this.buildingLenght = this.listofbuild.length;
      if (res.status) {
        //  this.apimicroservice.openSnackbar(res.message);
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    });
  }
  }
  toPatientForm(e) { 
    let fromG = localStorage.getItem('fromGlobal');
    if(fromG == 'true') 
    { 
      this.dialogRef.close();
      this.router.navigate(['/global-dashboard'],{ queryParams: { PatientId: e.patientRefId  } });
    }
    else  
    {
      this.dialogRef.close({ data: e })
    }
  }
  redirectToRepairForm(repairNo){ 
    this.onClose();
    this.router.navigate(['repair-complaint',repairNo]);
  }
  onClose(): void {
    this.dialogRef.close();
  }
}
