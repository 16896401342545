<div class="dialog-wrapper">
  <!-- <h3 mat-dialog-title>Change Password </h3> -->
  <mat-dialog-content>
    <div class="container">
      <form [formGroup]="userChangePasswordForm" novalidate>
        <div class="row">
          <div class="col-sm-12">
            <div class="card-group">
              <div class="card ">
                <div class="row">
                  <div class="col-sm-11" ></div>
                  <div class="col-sm-1 justify-content-end" >
                    <div class="row justify-content-end">
                   
                     <a (click)="onClose()" matTooltip="Close" class="d-flex align-items-center pr-2"><span class="material-symbols-outlined pr-2">close</span></a>
                   
                    </div>
                 </div> 
                </div>
               

                <!-- <i class="far fa-window-close close_style" (click)="onClose()"></i> -->
                <div class="card-body" style="width: 30rem">
                  <p class="text-muted">Change Password</p>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fas fa-lock"></i>
                      </span>
                    </div>
                    <input type="password" class="form-control" formControlName="password"
                      placeholder="  Enter Current password" autocomplete="password" required />
                  </div>
                  <mat-error *ngIf="isFieldValid('password')">
                     <span>
                      <span *ngIf="
                          userChangePasswordForm.hasError('required', ['password'])">Cannot be blank.</span>
                    </span>
                    <span *ngIf="
                        userChangePasswordForm.get('password').touched ||
                        userChangePasswordForm.get('password').dirty
                      ">
                      <span *ngIf="
                          userChangePasswordForm.hasError('minlength', ['currentpassword'])
                        ">Current Password should contain minimum 8 characters.</span>
                    </span>
                  </mat-error>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fas fa-lock"></i>
                      </span>
                    </div>
                    <input type="password" class="form-control" formControlName="newPassword"
                      placeholder="   Enter New new Password" autocomplete="password" required />
                  </div>
                  <mat-error *ngIf="isFieldValid('newPassword')">
                    <span>
                      <span *ngIf="
                          userChangePasswordForm.hasError('required', ['newPassword'])
                        ">Cannot be blank.</span>
                    </span>
                    <span *ngIf="
                        userChangePasswordForm.get('newPassword').touched ||
                        userChangePasswordForm.get('newPassword').dirty
                      ">
                      <span *ngIf="
                          userChangePasswordForm.hasError('minlength', ['newPassword'])
                        ">New Password should contain minimum 8 characters.</span>
                    </span>
                  </mat-error>
                  <div class="row">
                    <div class="col-sm-12 text-center">
                      <button type="button" (click)="ChangePassword()" class="btn bg_col px-4">
                        Change password
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div> 
          </div>
          <div class="col-sm-4"></div>
        </div>
      </form>
    </div>
  </mat-dialog-content>
</div>