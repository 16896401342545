import { ActivatedRoute } from "@angular/router";
import { Component, Inject, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  FormArray
} from "@angular/forms";
 import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  MatSnackBar,
} from "@angular/material/snack-bar";
import { MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { PopUpBranchComponent } from "../pop-up-branch/pop-up-branch.component";

@Component({
  selector: 'app-popup-cheque-details',
  templateUrl: './popup-cheque-details.component.html',
  styleUrls: ['./popup-cheque-details.component.scss']
})
export class PopupChequeDetailsComponent implements OnInit {
  receiptNo: string;
  chequeDetailsForm: FormGroup;
  date: any; 
  listOfAppointment:any;
  totalLength:any;
  appointmentId: any;
  size=5;
  page=1;
  searchText: any;
  submitButton = true;
  updateButton = false;
  allColor: any;
  
  filteredList: { name: string; value: string; }[];
  filteredListAppoinmentType: { name: string; value: string; }[];
  userType: string;
  branchRef: string;
  clinicRef: string;
  BranchList: any;
  isSuperAdmin= true;
  superBranchId: any;
  branchName: string;
  filteredListBranch: any;
  currentdate: Date;
  listOfBilling: any;
  id: any;
  courierList: any;
  filteredListCourier: any;
  disableFromCity = false;
  disableToCity = true;
  tocityList: any;
  cityList: any;
  fromCity: any;
  toCity: any;
  courierServiceCreate = true;
  branchCreate = true;
  filteredListPatient: any;
  patientList: any;
  totalBillAmt = 0;
  printData=[
    {
      srNo:1,
      date:'201',
      serviceName:'Test',
      companyName:'A Company',
      rate:100,
     },
     {
      srNo:2,
      date:'202',
      serviceName:'Test',
      companyName:'A Company',
      rate:200,
     },
     {
      srNo:3,
      date:'203',
      serviceName:'Test 2',
      companyName:'A Company',
      rate:100,
     },
     {
      srNo:4,
      date:'204',
      serviceName:'Test 3',
      companyName:'A Company',
      rate:150,
     },                 
  ]
  patientRefId: any;
  appointId: any;
  billingList: any;
  mode: any;
  disableSubmit = false;
    billNo: any;
    paymentDate: any;
    chequePaymentStatus: any;
    serviceInfo = [];
    billServiceInfo = [];
    balanceAmount = 0;
    pendingAmount = 0;
    paidAmount = 0;
    billRefId: any;
    paymentRefId: any;
    chkstatus = '';
    paymentStatus: any;
    allPaydata = [];
    totalPaidAmount = 0;
    chequeStatus: any;
    chequeNo: string;
    rNo: string;
    chequeImage: any;
    btn: HTMLElement;
  chequeNoNew: any;
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
     public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
     public dialogRef: MatDialogRef<PopupChequeDetailsComponent>,@Inject(MAT_DIALOG_DATA) public data:any,
  ) { 
    this.chequeNoNew = data.chequeNo;
  } 
  userPermission:any;
  billingCreate= true;
  billingUpdate= true;
  paymentMode :any;
  modalImg: any;
  modal: any;
  
  ngOnInit(): void {
    this.receiptNo = this.chequeNoNew;
  
     

    //
    this.serviceInfo = [];
    this.billServiceInfo = []; 
    this.chkstatus = '';
    this.balanceAmount = 0;
    this.pendingAmount = 0;
    this.paidAmount = 0;
    this.totalPaidAmount = 0;
    this.currentdate = new Date();
    this.page=1;
    this.size = 5;
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.userType = sessionStorage.getItem('userType');
    this.userType = sessionStorage.getItem('userType');
    this.chequeNo = sessionStorage.getItem('chequeNo'); 
    this.rNo = sessionStorage.getItem('rNo'); 
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    if(this.userType !== 'Super Admin'){
      this.isSuperAdmin= false;
    this.userPermission.forEach((element) => {
      if(element.pageName == 'Billing Record' && element.create == false)
      {
          this.billingCreate = false;  
      }
      if(element.pageName == 'Billing Record' && element.update == false)
      {
          this.billingUpdate = false;  
      }  
    });
  
  }
  this.modal = document.getElementById("myModal");
  
  this.btn = document.getElementById("myBtn");
  window.onclick = function(event) {
    if (event.target ==this.modal) {
      this.modal.style.display = "none";
    }
  }
  this.disableSubmit = false;
  //
  //
    this.chequeDetailsForm = this.fb.group({
  
      "searchBilling": new FormControl(''), 
      "chequeStatus": new FormControl(''), 
      "date": new FormControl(''), 
      "billNo": new FormControl(''), 
      "alertType": new FormControl(''), 
      "billid": new FormControl(''), 
      
      "chequeArray": this.fb.array([]),
    });
    let i = 0;
    this.fetchChequeData();
    //this.fetchBillData(); 
   // this.addRow(i);
  }
  
  fetchChequeData(){
    this.apimicroservice.getPaymentHistoryByReceiptNo(this.receiptNo).subscribe(res => {
      //
      if(res.status){
     let data = res.data[0];
     this.serviceInfo = data.serviceInfo;
     this.paymentRefId = data._id;
     //
     this.billNo = data.billNo;
    //  this.chequeStatus = data.chequeStatus;
     this.paymentDate = data.paymentDate;
     let dt = new Date(this.paymentDate);
     this.chequePaymentStatus = data.chequePaymentStatus;
     //
     
     if(this.chequePaymentStatus == 'Clear' || this.chequePaymentStatus == 'bounce'){
       this.submitButton = false;
       this.chequeDetailsForm.disable();
     }
     if(this.chequePaymentStatus == 'Clear' || this.chequePaymentStatus == 'bounce'){
      this.submitButton = false;
      this.chequeDetailsForm.disable();
    }
     this.chequeDetailsForm.controls['billNo'].setValue(this.billNo);
    
    // this.chequeDetailsForm.controls['date'].setValue(dt.toDateString());
  
     this.chequeDetailsForm.controls['chequeStatus'].setValue(this.chequePaymentStatus);
     
    //  this.balanceAmount = data.balanceAmount;
    //  this.pendingAmount = data.pendingChequeAmount;
     this.paidAmount = data.paidAmount;
     this.fetchBillData();
     this.allPaymentByBillNo();
     data.chequeDetails.forEach((element,i) => {
      this.chequeDetailsForm.controls['chequeStatus'].setValue(element.chequeStatus);
       if(element.chequeStatus == 'postdated'){
         this.disableSubmit = true;
       }else{
         this.disableSubmit = false;
       }
      this.addRow(element,i)
     });
    }else{
      
    }
    });
  }
  
  allPaymentByBillNo(){
    this.apimicroservice.getPaymentHistoryByBillNo(this.billNo).subscribe(res => {
      //
      this.allPaydata = res.data;
    //  this.billServiceInfo = data.serviceInfo;
    //  this.billRefId = data._id;
    //  this.paymentStatus = data.paymentStatus;
     //
  
    });
  }
  
  fetchBillData(){
    this.apimicroservice.getBillDataById(this.billNo).subscribe(res => {
      //
     let data = res.data[0];
     this.billServiceInfo = data.serviceInfo;
     this.billRefId = data._id;
     this.paymentStatus = data.paymentStatus;
     this.totalPaidAmount = data.paidAmount;
     this.balanceAmount = data.balanceAmount;
     this.pendingAmount = data.pendingChequeAmount;
     //
    });
  }
  
  prepopulation(id) {
    this.redirect('bill-form');
  }
  
  tochequeDetailsForm() {
    document.getElementById("tochequeDetailsForm").scrollIntoView();
  }
  toBillingLst() {
    document.getElementById("toBillingLst").scrollIntoView();
  }
  
  openSnackbar(message: string) {
    return this._snackBar.open(message, "close",
      { verticalPosition: 'bottom', horizontalPosition: 'right', duration: 2300, panelClass: ['snackbarStyle'] });
  }
  // fetchBillList() {
  //   let search='';
  //   this.apimicroservice.getBillingDetailsByPatientId(this.page,this.size,search,this.patientRefId,this.appointId).subscribe(res => {
  //     //  
  //     if (res.status) {    
  //       this.billingList = res.data.data;
  //       this.totalLength=res.data.totalItems;
  //       this.billingList.forEach(element =>{
  //         this.totalBillAmt = this.totalBillAmt + element.totalAmount;
  //       })
  //     } else {
  //       this.apimicroservice.openSnackbar(res.message);
  //     }
  //   });
  // }
  
  onPageChange(event) {
    this.page = event;
    let search='';
    // this.apimicroservice.getBillingDetailsByPatientId(this.page,this.size,search,this.patientRefId,this.appointId).subscribe(res => {
    //   //  
    //   if (res.status) {    
    //     this.billingList = res.data.data;
    //     this.totalLength=res.data.totalItems;
    //   } else {
    //     this.apimicroservice.openSnackbar(res.message);
    //   }
    // });
    // if (this.userType !== 'Super Admin') {
    //   this.apimicroservice.getDoctorAvailablePaginationData(this.page,this.size,this.branchRef,this.clinicRef).subscribe(res => {
    //     if (res.status) {
    //       this.printData = res.data.data;
    //       this.totalLength=res.data.totalItems;
    //     } else {
    //       this.apimicroservice.openSnackbar("Something went wrong");
    //     }
  
    //   })
    // } 
  }
  
  printForm()
  {
    this.redirect('print-bill');
  } 
  redirect(to): void {
    this.router.navigate([to]);
   } 
  
  addRow(data,i) {
    // let dt = new Date(data.chequeDate);
    // let todayDate = new Date();
    //  let td = new Date(todayDate.setDate(todayDate.getDate() + 1));
  
    //  this.chkstatus = '';
    
    // if((dt.getTime() < td.getTime()) && (dt.getTime() !== todayDate.getTime())){
    //   this.chkstatus = 'Bounce';
    // }
    const creds = this.chequeDetailsForm.controls.chequeArray as FormArray;
        creds.push(
        this.fb.group({
          "chequeDate": new FormControl(data.chequeDate),   
          "chequeNo": new FormControl(data.chequeNo),  
          "bankName": new FormControl(data.bankName), 
          "amount": new FormControl(data.amount),
          "chequeStatus": new FormControl(data.chequeStatus,Validators.required),
          "alertType": new FormControl(''),
          "billid": new FormControl(''), 
          "photo": '', 
        toggle: false
  })
  );
  this.chequeImage = data.photo ? data.photo : "../../assets/user2.png"
  }
  deleteRow(index){
    //this.dynamicRows.splice(index, 1);
    (<FormArray>this.chequeDetailsForm.get("chequeArray")).removeAt(index);
  }
  
  onSelectStatus(event:any,i){
    let chequeControl = (<FormArray>this.chequeDetailsForm.get("chequeArray")).at(i);
    let statusVal = chequeControl.get("chequeStatus").value;
    if(statusVal == 'postdated'){
      this.disableSubmit = true;
    }else{
      this.disableSubmit = false;
    }
    // if(statusVal == 'Bounce'){
    //  this.apimicroservice.openSnackbar("You Can not change the status of bounce check!");
    // }
  }
  
  submitChequeDetails(){
    const creds = this.chequeDetailsForm.controls.chequeArray as FormArray;  
    if(creds.invalid)
    {
      creds.controls.forEach(element =>{
        // element.get('chequeDate').markAsTouched();
        // element.get('chequeNo').markAsTouched();
        // element.get('bankName').markAsTouched();
        // element.get('amount').markAsTouched();
        element.get('chequeStatus').markAsTouched();
      });
         
    }
    else{
     this.sumbitCheque();
    }
  }
  
  sumbitCheque(){ 
    this.spinner.show();
    const tempData = this.chequeDetailsForm.controls.chequeArray.value;
    let paymentpayload = {};
    let paymentpayload2 = {};
    let billingPayload = {};
    let bouncePayload = {};
    let chequeStatus = '';
    let finalStatus = '';
    let finalStatusBill = '';
    let bounceStatus = '';
    let tempArr = [];
    let serviceArr = [];
    let comment = '';
    let tempStatus = '';
    tempArr = tempData;
    let billPaymentStatus = 'clear';
    let checkPaidAmt = 0;
    let j = 0;
    let payStatus = 'Unpaid';
    let bounceAmount = 0;
    let chequeNo = 0;
    tempArr.forEach(ele =>{
      if(ele.chequeStatus == 'Deposited'){
        chequeStatus = 'deposited';
        payStatus = 'Unpaid';
      }
      if(ele.chequeStatus == 'Clear'){
        chequeStatus = 'Clear';
        payStatus = 'Paid';
        chequeNo = ele.chequeNo;
        checkPaidAmt = checkPaidAmt + ele.amount;
        this.balanceAmount = this.balanceAmount - checkPaidAmt;
        this.pendingAmount = this.pendingAmount - checkPaidAmt;
        this.paidAmount = this.paidAmount + checkPaidAmt;
        this.totalPaidAmount = this.totalPaidAmount + this.paidAmount;
      }
      if(ele.chequeStatus == 'bounce'){
        payStatus = 'Unpaid';
        chequeStatus = 'bounce';
        bounceAmount = ele.amount;
        j++;
      }
  
    });
  
    if(chequeStatus == 'bounce'){
      finalStatus = 'bounce';
      comment = j + " cheques has bounced";
    }else{
      finalStatus = chequeStatus;
    }
    
    this.allPaydata.forEach(ele =>{
      if(ele.chequePaymentStatus == 'deposited'){
        billPaymentStatus = 'deposited';
      }
      if(ele.chequePaymentStatus == 'postdated'){
        billPaymentStatus = 'postdated';
      }
      if(ele.chequePaymentStatus == 'bounce'){
        bounceStatus = 'bounce';
      }
    });
  //
    if(bounceStatus == 'bounce' || finalStatus == 'bounce'){
      finalStatusBill = 'bounce';
      comment = j + " cheques has bounced";
    }else{
      finalStatusBill = billPaymentStatus;
    }
  
    this.serviceInfo.forEach(ele =>{
      ele.status = finalStatusBill;
      if(finalStatusBill == 'bounce'){
        ele.comment = comment;
      }
      serviceArr.push({"serviceName":ele.serviceName,"testType":ele.testType});
    });
  
    serviceArr.forEach(element =>{
      this.billServiceInfo.forEach(ele =>{
        if((element.serviceName == ele.serviceName) && (element.testType == ele.testType)){
          ele.status = finalStatusBill;
          if(finalStatusBill == 'bounce'){
            ele.comment = comment;
          }
        }
      })
    });
    
    this.chequePaymentStatus = finalStatus;
    if(this.chequePaymentStatus == 'bounce'){
      this.pendingAmount = this.pendingAmount - bounceAmount;
    }
  
    // let tempStatus = 'Paid';
    // this.billServiceInfo.forEach(ele =>{
    //   if(ele.status == 'unpaid' || ele.status == 'postdated' || ele.status == 'deposited' || ele.status == 'bounce'){
    //     tempStatus = 'Unpaid';
    //   }
    // });
    if(this.balanceAmount == 0){
      tempStatus = 'Paid';
    }else{
      tempStatus = 'Unpaid';
    }
    //
    //
    //
    billingPayload['serviceInfo'] = this.billServiceInfo;
    billingPayload['paidAmount'] = this.totalPaidAmount;
    billingPayload['balanceAmount'] = this.balanceAmount;
    billingPayload['pendingChequeAmount'] = this.pendingAmount;
    billingPayload['bounceAmount'] = bounceAmount;
    billingPayload['paymentStatus'] = tempStatus;  
    paymentpayload['paymentStatus'] = payStatus;
    paymentpayload['chequePaymentStatus'] = this.chequePaymentStatus;
    paymentpayload['chequeDetails'] = tempArr;
    paymentpayload2['chequeDetails'] = tempArr;
    paymentpayload['serviceInfo'] = this.serviceInfo;
    paymentpayload['paidAmount'] = this.paidAmount;
    paymentpayload['balanceAmount'] = this.balanceAmount;
    paymentpayload['pendingChequeAmount'] = this.pendingAmount;
    paymentpayload['bounceAmount'] = bounceAmount;
    paymentpayload['bounceAmount'] = bounceAmount;
    paymentpayload['alertType'] = '10';
    paymentpayload['receiptNo']= this.rNo;  
    paymentpayload['billid']= this.chequeNo; 
    paymentpayload2['billid']= this.chequeNo; 
    paymentpayload2['receiptNo']= this.rNo; 
    paymentpayload2['alertType']= '10';
    //
    //
  
    let chequeControl = (<FormArray>this.chequeDetailsForm.get("chequeArray")).at(0);
    let chequeType = chequeControl.get("chequeStatus").value;
    paymentpayload['chequePaymentStatus'] = chequeType;
    this.apimicroservice.updatePaymentHistoryDetails(paymentpayload,this.paymentRefId).subscribe(res => {
      this.spinner.hide();
        //this.ngOnInit();
        if(res.status){
         // this.apimicroservice.openSnackbar(res.message);
         
          this.apimicroservice.updateBillingDetails(billingPayload,this.billRefId).subscribe(res => {
            if(res.status){
              this.apimicroservice.openSnackbar("Cheque "+this.chequePaymentStatus);
              // if(paymentpayload['chequePaymentStatus'] == 'clear'){
              //   this.apimicroservice.updateAlerts(chequeNo).subscribe(res => {
              //     if(res.status){
              //       this.apimicroservice.openSnackbar('alert updated');
              //       // this.ngOnInit();
              //     }
              //   });
              //  }
           let chequeControl = (<FormArray>this.chequeDetailsForm.get("chequeArray")).at(0);
            let chequeType = chequeControl.get("chequeStatus").value;
               //
               //
               
               
              if(chequeType == 'bounce'){  
                let chequeNum = Math.floor(10000 + Math.random() * 90000);
                paymentpayload['billid']= "chq-" + chequeNum;
                paymentpayload['chequeNo']= this.chequeNo;       
                paymentpayload['receiptNo']= this.rNo;      
   
                paymentpayload2['billid']= "chq-" + this.chequeNo;  
                paymentpayload2['receiptNo']= this.rNo;  
              
                // this.apimicroservice.UpdateChequeAlert(this.chequeNo,paymentpayload2).subscribe(res => {
                //   if(res.status){
                //     this.apimicroservice.openSnackbar('alert updated');
                //     window.location.reload();
                //     // this.ngOnInit();
                //   }
                // });
  
                  this.apimicroservice.addBounceAlerts(paymentpayload).subscribe(res => {
                    if(res.status){
                      this.apimicroservice.openSnackbar('alert created');
                      this.dialogRef.close();
                    window.location.reload();
                      // this.ngOnInit();
                    }
                  });  
               
                 }
                 else if(chequeType == 'Received'){
                  paymentpayload['alertType']= '101';
                  let chequeNum = Math.floor(10000 + Math.random() * 90000);
                  paymentpayload['billid']= "chq-" + chequeNum;
                  paymentpayload['chequeNo']= this.chequeNo;  
                  paymentpayload2['billid']= "chq-" + chequeNum;    
                  paymentpayload2['alertType']= '101';
                  paymentpayload['receiptNo']= this.rNo;  
                  paymentpayload2['receiptNo']= this.rNo;  
                  // this.apimicroservice.UpdateChequeAlert(this.chequeNo,paymentpayload2).subscribe(res => {
                  //   if(res.status){
                  //     this.apimicroservice.openSnackbar('alert updated');
                  //     window.location.reload();
                   
                  //     // this.ngOnInit();
                  //   }
                  // });
                  this.apimicroservice.addAlertNew(paymentpayload).subscribe(res => {
                    if(res.status){
                      this.apimicroservice.openSnackbar('alert created');
                      this.dialogRef.close();
                      window.location.reload();
                      // this.ngOnInit();
                    }
                  });
                
                 }
                 else if(chequeType == 'postdated')
                 { 
                  //
                  paymentpayload['alertType']= '102';        
                  let chequeNum = Math.floor(10000 + Math.random() * 90000);
                paymentpayload['billid']= "chq-" + chequeNum;
                paymentpayload['chequeNo']= this.chequeNo;  
                paymentpayload2['billid']= "chq-" + chequeNum;   
                  paymentpayload['receiptNo']= this.rNo;     
    
                  paymentpayload['receiptNo']= this.rNo;  
                  paymentpayload2['alertType']= '102';
                  paymentpayload2['receiptNo']= this.rNo;  
                  // this.apimicroservice.UpdateChequeAlert(this.chequeNo,paymentpayload2).subscribe(res => {
                  //   if(res.status){
                  //     this.apimicroservice.openSnackbar('alert updated');
                  //    window.location.reload();
                  //     // this.ngOnInit();
                  //   }
                  // });
  
                  this.apimicroservice.addAlertNew(paymentpayload).subscribe(res => {
                    if(res.status){
                      this.apimicroservice.openSnackbar('alert created');
                     window.location.reload();
                      this.dialogRef.close();
                      // this.ngOnInit();
                    }
                  });
                 }
                 else if(chequeType == 'Deposited')
                 {
                  //
                  paymentpayload['alertType']= '103';       
                  let chequeNum = Math.floor(10000 + Math.random() * 90000);
                paymentpayload['billid']= "chq-" + chequeNum;
                paymentpayload['chequeNo']= this.chequeNo;  
                paymentpayload2['billid']= "chq-" + chequeNum;    
                  paymentpayload['receiptNo']= this.rNo;  
                  paymentpayload2['alertType']= '103';
                  paymentpayload2['receiptNo']= this.rNo;  
                  // this.apimicroservice.UpdateChequeAlert(this.chequeNo,paymentpayload2).subscribe(res => {
                  //   if(res.status){
                  //     this.apimicroservice.openSnackbar('alert updated');
                  //    window.location.reload();
                  //     // this.ngOnInit();
                  //   }
                  // });
  
                  paymentpayload['receiptNo']= this.rNo;       
                  this.apimicroservice.addAlertNew(paymentpayload).subscribe(res => {
                    if(res.status){
                      this.apimicroservice.openSnackbar('alert created');
                     window.location.reload();
                      this.dialogRef.close();
                      // this.ngOnInit();
                    }
                  });
               
                 }
                 else if(chequeType == 'Clear') 
                 {
                  //
                  paymentpayload['alertType']= '104'; 
                  let chequeNum = Math.floor(10000 + Math.random() * 90000);
                paymentpayload['billid']= "chq-" + chequeNum;
                paymentpayload['chequeNo']= this.chequeNo;  
                paymentpayload2['billid']= "chq-" + chequeNum;    
                paymentpayload['receiptNo']= this.rNo;          
               paymentpayload2['alertType']= '104';
               paymentpayload2['receiptNo']= this.rNo;  
              //  this.apimicroservice.UpdateChequeAlert(this.chequeNo,paymentpayload2).subscribe(res => {
              //    if(res.status){
              //      this.apimicroservice.openSnackbar('alert updated');
              //    window.location.reload();
              //      // this.ngOnInit();
              //    }
              //  });
                  this.apimicroservice.addAlertNew(paymentpayload).subscribe(res => {
                    if(res.status){
                      this.apimicroservice.openSnackbar('alert created');
                    window.location.reload();
                      this.dialogRef.close();
                      // this.ngOnInit();
                    }
                  }); 
  
                 }
                 else if(chequeType == 'Cancelled')
                 {
                  //
                  paymentpayload['alertType']= '105'; 
                  let chequeNum = Math.floor(10000 + Math.random() * 90000);
                paymentpayload['billid']= "chq-" + chequeNum;
                paymentpayload['chequeNo']= this.chequeNo;  
                paymentpayload2['billid']= "chq-" + chequeNum;    
                  paymentpayload['receiptNo']= this.rNo;        
                  
                  paymentpayload2['alertType']= '105';
                  paymentpayload2['receiptNo']= this.rNo;    
                  // this.apimicroservice.UpdateChequeAlert(this.chequeNo,paymentpayload2).subscribe(res => {
                  //   if(res.status){
                  //     this.apimicroservice.openSnackbar('alert updated');
                  //    window.location.reload();
                  //     // this.ngOnInit();
                  //   }
                  // });
                  this.apimicroservice.addAlertNew(paymentpayload).subscribe(res => {
                    if(res.status){
                      this.apimicroservice.openSnackbar('alert created');
                      window.location.reload();
                      this.dialogRef.close();
                      // this.ngOnInit();
                    }
                  });
                 }
                 else if(chequeType == 'Returned')
                 {
                  //
                  paymentpayload['alertType']= '106';  
                  let chequeNum = Math.floor(10000 + Math.random() * 90000);
                paymentpayload['billid']= "chq-" + chequeNum;
                paymentpayload['chequeNo']= this.chequeNo;  
                paymentpayload2['billid']= "chq-" + chequeNum;         
                  paymentpayload['receiptNo']= this.rNo; 
                  
                  paymentpayload2['alertType']= '106'; 
                  paymentpayload2['receiptNo']= this.rNo;  
                  // this.apimicroservice.UpdateChequeAlert(this.chequeNo,paymentpayload2).subscribe(res => {
                  //   if(res.status){
                  //     this.apimicroservice.openSnackbar('alert updated');
                  //    // window.location.reload();
                  //     // this.ngOnInit();
                  //   }
                  // });
                  this.apimicroservice.addAlertNew(paymentpayload).subscribe(res => {
                    if(res.status){
                      this.apimicroservice.openSnackbar('alert created');
                     // window.location.reload();
                      this.dialogRef.close();
                      // this.ngOnInit();
                    }
                  });
                 }
            //    this.redirect('home/cheque-list');
              
              // this.ngOnInit();
              
            }
          })
        }
    });
  }
  
  cancelSumbit(){
    this.redirect('home/cheque-list');
  }
  
  findDuplicate(chqno,p):boolean{
    const creds = this.chequeDetailsForm.controls.chequeArray as FormArray;
    let myArr = creds.controls;
    let test = myArr.filter(data => data['controls']['chequeNo'].value == chqno && chqno !== null)
  
    if(test.length > 1){
      this.disableSubmit = true;
      return true;
    }else{
      this.disableSubmit = false;
      return false;
    }
  
  }
  onback() {
    this.apimicroservice.back()
  }
  imgClick()
  {
    //
    
    // this.modal.style.display = "block";
   // document.getElementById('id01').style.display='block'
   var modal = document.getElementById("myModal");
   // this.modal.style.display = "block";
  
     modal.style.display = "block";
  
  }
  close()
  {
    var modal = document.getElementById("myModal");
    modal.style.display = "none";
  }
  onClose(): void {
    this.dialogRef.close();
  }
  }
  
  