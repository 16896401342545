<div class="dialog-wrapper">
    <div class="row">
           
      <div class="col-sm-11  ">
        <h3 mat-dialog-title class="title">Appointment Rescheduled Confirmation :</h3>
          <!-- <h1 class="titlecolor" >  Search Patients</h1>   -->
      </div>
      <div class="col-sm-1 "> 
        <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
          <a (click)="onClose(false)" matTooltip="Close" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">close</span></a>
          </div>
      </div>
  
    <div class="col-sm-12 mb-4">
          Do You Really Want To Rescheduled Appointment For 
          <b>{{patientName}}</b> On <b>{{appDate}} {{appTime}}</b>
     </div>   
     <div class="col-sm-3"></div>
     <div class="col-sm-3">
      <button type="button" class="btn btn-primary btn-sm example-full-width" 
      (click)="confirmSubmit()">Yes</button>
     </div>
     <div class="col-sm-3">
      <button type="button" class="btn btn-primary btn-sm example-full-width" 
      (click)="confirmCancel()">No</button>
     </div>
     <div class="col-sm-3"></div>
    </div>
    </div>
     