<div class="container-fluid " [formGroup]="appointmentTypeForm">
    <form [formGroup]="appointmentTypeForm" autocomplete="off" novalidate>
    <div class="row">
        
        <div class="col-sm-12">
            <div class="row">
                <div class="col-sm-11">
                    <div class="row ">

                        <!-- <span><img src="../../assets/appoinement_type.png" width="30rem"> </span> -->
                        <span>
                            <h1 class="fass ml-3 pt-1" id="toAppointmentTypeForm">Add Appointment Type</h1>
                        </span>
                    </div>

                </div>
                <div class="col-sm-1 mb-2 justify-content-end">
                    <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                      <a (click)="onClose()" matTooltip="Close" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">close</span></a>
                      </div>
                  </div>
              
                <!-- <div class="col-sm-1 ">
                    <div class="row justify-content-end mr-1 ">

                        <img src="../../assets/list.png" (click)="toAppointmentTypeList()" class="mb-2 pb-2"
                            matTooltip="List" width="35rem">
                    </div>
                </div> -->
            </div>
            <div class="row">
                <div class="col-sm-12 pr-4">
                  

                            <div class="col-sm-12">
                                <div class="row">
                                    <div class="col-sm-1 mt-3">

                                        <!-- <mat-form-field *ngIf="userType !== 'Super Admin'" class="example-full-width"
                                            appearance="outline">
                                            <mat-label>Appointment Id</mat-label>
                                            <input matInput formControlName="branchWiseId" readonly />
                                        </mat-form-field>
                                        <mat-form-field *ngIf="userType == 'Super Admin'" class="example-full-width"
                                            appearance="outline">
                                            <mat-label>Appointment Id</mat-label>
                                            <input matInput [value]="superBranchId" readonly />
                                        </mat-form-field> -->
                                        <mat-label class="center" [value]="superBranchId" ngDefaultControl >{{superBranchId}}</mat-label>
                                    </div>

                                    <div class="col-sm-3 ">
                                        <mat-form-field class="example-full-width " appearance="outline">
                                            <mat-label> Appointment Type
                                                <!-- <span class="required-asterisk">*</span> -->
                                            </mat-label>
                                            <!-- <mat-select   class="colorblack"  formControlName="appointmentType"   >
                                                <mat-select-filter [placeholder]="'Filter'" [displayMember]="'name'"
                                                  [array]="allAppointmentType"  (filteredReturn)="filteredListAppoinmentType =$event"></mat-select-filter>
                                                <mat-option *ngFor="let item of filteredListAppoinmentType" 
                                                 [value]="item.value"
                                                 >
                                                  {{item.name}}
                                                </mat-option>
                                              </mat-select> -->
                                            <input required matInput placeholder="Appointment Type"
                                                formControlName="appointmentType" />
                                        </mat-form-field>
                                        <mat-error
                                            *ngIf="appointmentTypeForm.controls['appointmentType'].touched && appointmentTypeForm.controls['appointmentType'].errors?.required">
                                            This field is mandatory.
                                        </mat-error>
                                    </div>


                                    <div class="col-sm-4 ">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label>Choose Color</mat-label>
                                            <input required class="h18" matInput placeholder="Choose Color"
                                                formControlName="color" type="color" (change)="onSelectColor($event)" />

                                        </mat-form-field>
                                        <mat-error class="margin_tp"
                                            *ngIf="appointmentTypeForm.controls['color'].touched && appointmentTypeForm.controls['color'].errors?.required">
                                            This field is mandatory.
                                        </mat-error>
                                    
                                    </div>
                                    <!-- <div *ngIf="isSuperAdmin" class="col-sm-4">
                                        <div class="row">
                                            <div class="col-sm-10 pr-1">
                                                <mat-form-field class="example-full-width" appearance="outline">
                                                    <mat-label>Branch
                 
                                                    </mat-label>
                                                    <mat-select required #select2 (click)="fetchBranchList();"
                                                        formControlName="branchRefId">
                                                        <mat-select-filter *ngIf="select2.focused" [placeholder]="'Filter'"
                                                            [displayMember]="'branchName'" [array]="BranchList"
                                                            (filteredReturn)="filteredListBranch =$event">
                                                        </mat-select-filter>
                                                        <mat-option *ngFor="let item of filteredListBranch"
                                                            [value]="item._id"
                                                            (click)="branchClick(item._id,item.branchName);">
                                                            {{ item.branchName }}</mat-option>
                                                    </mat-select>
                                                    <mat-error class="margin_tp"
                                                        *ngIf="appointmentTypeForm.controls['branchRefId'].touched && appointmentTypeForm.controls['branchRefId'].errors?.required">
                                                        This field is mandatory.
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-sm-2 mt-2 mTop no-print">
                                                <i (click)="openBranchPopUp();" matTooltip="Add New Branch" 
                                                class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                                              
                                            </div>
                                        </div>
                               
                                    </div> -->

                                    <div class="col-sm-8"></div>

                                    <div class="col-sm-3 offset-sm-9 mb-2 mt-2"
                                        *ngIf="submitButton && appointmentTypeCreate">
                                        <button class="btn btn-primary btn-sm example-full-width"
                                            (click)="submitAppointmentType()"  type="button">Save </button>

                                    </div>
                                   

                                </div>
                            </div>
                       
                </div>
            </div>
        </div>
    </div>
</form>
</div>