<div class="container-fluid p-0 bgcolor2" [formGroup]="repairComplaintForm">
  <form [formGroup]="repairComplaintForm" autocomplete="off" novalidate>
  <div class="row col-sm-12 p-0" >
   
    <div class="col-sm-1">
      
  </div> 
    <div class="col-sm-11">
      
      <div class="row col-sm-12 pb-0 mb-0">
  
          <div class="col-sm-8">
              <h1 class="pt-2 pl-1" id="toRepairForm">Repair</h1>
              </div>
              <div class="col-sm-2 text-left justify-content-start pt-2 pr-0 mr-0">
                 
                <label *ngIf="isPrepopulate && lastStatus !== 'Awaiting Approval/Payment'" class="text-left fontClass2 justify-content-start pt-2 pl-3 pr-2">Status :</label>
                <label *ngIf="isPrepopulate && lastStatus == 'Awaiting Approval/Payment'" class="text-left fontClass2 justify-content-start pt-2 pl-3 pr-2">Status :</label>
                <label *ngIf="isPrepopulate && lastStatus !== 'Awaiting Approval/Payment'" class="text-left fontClass justify-content-start pt-2 pl-3 pr-2">{{lastStatus}}</label>
                <label *ngIf="isPrepopulate && lastStatus == 'Awaiting Approval/Payment'" class="text-left fontClass justify-content-start pt-2 pl-3 pr-2"> Approval/Payment</label>
              </div>
              <div class="col-sm-2 mt-1">
                <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                  <a (click)="onback()" class="d-flex align-items-center mt-2 pr-2"><span class="material-symbols-outlined">arrow_back</span>Back</a>
                  <a (click)="toRepairList()" class="d-flex align-items-center mt-2 pr-2"><span class="material-symbols-outlined">reorder</span>List</a>
                  <a *ngIf="isPrepopulate" (click)="printForm()" class="d-flex justify-content-end align-items-end mt-2">
                    <span class="material-symbols-outlined pr-1">print</span>Print</a>
             </div>
        </div> 
      </div>
      


          <div class="row pr-4 pl-4 pb-4 pt-0 mt-0">
              <div [ngClass]="{'col-sm-12' : !isPrepopulate}" [ngClass]="{'col-sm-8' : isPrepopulate}" class="mt-1">
                  <div class="row "> 
                    <div class="col-sm-12 mb-2  mr-0 pr-0">
             
                      <div class="card divbody mb-3 borderradius col-sm-12 p-2"> 
 
                          <div class="row pl-2">
                            <div class=" col-sm-1 mt-3 ">
                              <mat-label  formControlName="branchWiseId" ngDefaultControl *ngIf="userType !== 'Super Admin'" >{{bID}}</mat-label>
                              <mat-label [value]="superBranchId" ngDefaultControl *ngIf="userType == 'Super Admin'" >{{superBranchId}}</mat-label>
                            </div>
                            <div class=" col-sm-2"> 
                              <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Date</mat-label>
                                <input matInput placeholder="Date" formControlName="date" readonly />
                              </mat-form-field>
                            </div>
                            
                         
                            <!-- <div class="col-sm-3 close_style justify-content-end mt-2">
                              <label class="pt-2 close_style justify-content-end lable_style">Date : </label>
                              <label class="pt-2 close_style justify-content-end lable_style2">{{date | date:'dd/MM/yyyy'}}</label>
                            </div> -->
                            
                            
                          </div> 
                          <div class="row p-1"> 
                              <div class="col-sm-2">
                                <label class="lable_style pt-2 header">Patient Information:</label>
                              </div>
                              <div *ngIf="userType == 'Super Admin'" class="col-sm-3">
                                <div class="row ">
                                  <div class="col-sm-10 mr-0 pr-0 pl-0 ml-0">
                                <mat-form-field class="example-full-width mr-0 pr-0" appearance="outline">
                                  <mat-label>Branch</mat-label>
                                  <mat-select required #select1 formControlName="branchRefId" >
                                    <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'branchName'"
                                    [array]="BranchList"  (filteredReturn)="filteredListBranch =$event"></mat-select-filter>
                                   
                                    <mat-option *ngFor="let item of filteredListBranch" [value]="item._id" 
                                    (click)="branchClick(item._id,item.branchName);"
                                    >
                                     {{ item.branchName }}</mat-option>
                                    </mat-select>
                                    
                                </mat-form-field>
                                <mat-error class="margin_tp"
                                    *ngIf="repairComplaintForm.controls['branchRefId'].touched && repairComplaintForm.controls['branchRefId'].errors?.required">
                                    This field is mandatory.
                                  </mat-error>
                               </div> 
                               <div class="col-sm-2 mTop no-print">
                                <i (click)="openBranchPopUp();" matTooltip="Add New Branch"
                                 class="blue_col mTop fa-solid fa-circle-plus ml-0 pl-1
                                 pl-1 fa-lg"></i> 
                              </div>
                               </div>    
                              </div>
                              <div  *ngIf="patientCreate" class="col-sm-3">
                                <div class="row">
                                  <div class="col-sm-10 mr-0 pr-0 pl-0 ml-0">
                                <mat-form-field class="example-full-width mr-0 pr-0" appearance="outline">
                                  <mat-label>Select Patient </mat-label>
                                  <input required (click)="openDepartmentPopUp();" matInput readonly
                                  placeholder="Patient Name" formControlName="patientName" />
                                 
                                </mat-form-field>
                                <mat-error class="margin_tp"
                                *ngIf="repairComplaintForm.controls['patientName'].touched && repairComplaintForm.controls['patientName'].errors?.required">
                                This field is mandatory.
                              </mat-error>
                                </div>
                                <div class="col-sm-2 mTop no-print ">
                                  <i (click)="openPatientPopUp();" matTooltip="Add New Patient" 
                                  class="blue_col mTop fa-solid fa-circle-plus ml-0
                                   pl-1 fa-lg"></i>
                                </div>
                                </div>
                              </div>
                              <div *ngIf="!patientCreate" class="col-sm-3">
                                <div class="row">
                                  <div class="col-sm-12 pr-1">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                      <mat-label>Select Patient </mat-label>
                                      <input required (click)="openDepartmentPopUp();" matInput readonly
                                      placeholder="Patient Name" formControlName="patientName" />
                                     
                                    
                                    </mat-form-field>
                               
                                <mat-error class="margin_tp"
                                *ngIf="repairComplaintForm.controls['patientName'].touched && repairComplaintForm.controls['patientName'].errors?.required">
                                This field is mandatory.
                              </mat-error>
                                </div>
                               
                                </div>
                                
                              </div> 

                              <div class="row col-sm-12 p-1">
                                <div class="col-sm-2"></div>
                              <div class="col-sm-3"  *ngIf="patientSelect == true"  >
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label> First Name </mat-label>
                                  <input matInput placeholder="First Name "  formControlName="firstName" readonly />
                               
                                </mat-form-field>
                              </div>
                              <!-- <div class="col-sm-3" *ngIf="userType == 'Super Admin'" ></div> -->
                              <div class="col-sm-3" *ngIf="patientSelect == true" >
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>  Last Name</mat-label>
                                  <input matInput placeholder="Last Name"  formControlName="lastName" readonly />
                                 
                                </mat-form-field>
                              </div>
                              <!-- <div class="col-sm-3" *ngIf="userType != 'Super Admin'" ></div> -->
                              <div class="col-sm-3" *ngIf="patientSelect == true" >
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>  Gender</mat-label>
                                  <input matInput placeholder="sex"  formControlName="sex"  readonly />
                                 
                                </mat-form-field>
                              </div> 
                              <div class="col-sm-2"></div>
                              <div class="col-sm-3 mt-1 " *ngIf="patientSelect == true" >
                                <div class="row">
                                  <div class="col-sm-10 mr-0 pr-0 ml-0">
                                <mat-form-field class="example-full-width mr-0 pr-0" appearance="outline">
                                  <mat-label> Mobile No </mat-label>
                                  <input matInput placeholder="mobileNo" maxlength="10"
                                   formControlName="mobile1" />
                  
                                </mat-form-field>
                                <mat-error
                                *ngIf="repairComplaintForm.controls['mobile1'].touched && repairComplaintForm.controls['mobile1'].errors?.pattern">
                                Please enter valid Phone Number.
                              </mat-error> 
                                </div>
                                <div class="col-sm-2 ml-0 pl-2 mTop wApp no-print">
                                  <i class="bi bi-whatsapp whatsapp-icon mTop wApp fa-lg ml-0 pl-1" 
                                  (click)="WhatsApp();" matTooltip="WhatsApp Integration" ></i>
                                </div>
          </div>
                              </div>
      
                              <!-- <div class="col-sm-3" *ngIf="userType == 'Super Admin'" ></div> -->
                              <div class="col-sm-3" *ngIf="patientSelect == true" >
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label> Address </mat-label>
                                  <input matInput placeholder="Address"  formControlName="streetAddress"   />
                                 
                                </mat-form-field>
                              </div>
                             </div>
                            </div>  
                            <div class="row p-1"> 
                              <div class="col-sm-2">
                                <label class="lable_style pt-2 header">Add Signature :</label>
                              </div>
                         
         
                            <div class="col-sm-3">
                              <div class="row">
                                <div class="col-sm-12 mr-0 pl-0 pr-0 ml-0">
                              <canvas (click)="canvasClick()" class="example-full-width" #canvas (touchstart)="startDrawing($event)"
                                (touchmove)="moved($event)"></canvas>
                                </div>
                                </div>

                            </div>  
                        
                            <div *ngIf="signPadShow" class="col-sm-2"> 
                 
                                  <button class="btn btn-primary mt-2 example-full-width" type="button"
                                    (click)="clearPad()">Clear Signature</button>
                             
                        
                                  <button class="btn btn-primary mt-2 example-full-width" type="button" color="secondary"
                                    (click)="savePad()">Save Signature</button>
                             
                            </div>
                            <div *ngIf="signatureImg" class="col-sm-3">
                              <img src='{{ signatureImg }}' />
                            </div>
                            </div>
        
                        
                            
                            <div class="row p-1"> 
                              <div class="col-sm-2">
                                <label class="lable_style pt-2 header">Hearing Aid Company:</label>
                              </div>  
                              <div class=" col-sm-3">
                                <div class="row"> 
                                  <div class="col-sm-10 mr-0 pr-0 pl-0 ml-0">
                                    <mat-form-field class="example-full-width mr-0 pr-0" appearance="outline">
                                      <mat-label>Select HA Company </mat-label>
                                      <mat-select #select3 formControlName="companyRefId" >
                                        <mat-select-filter *ngIf="select3.focused" [placeholder]="'Filter'" [displayMember]="'companyName'"
                                        [array]="companyList"  (filteredReturn)="filteredListCompany =$event"></mat-select-filter>    
                                        <mat-option *ngFor="let item of filteredListCompany" [value]="item._id" 
                                        (click)="onSelectCompany(item.companyName,item.canNumber,item.make)"
                                      >
                                       {{ item.companyName }}</mat-option>
                                      </mat-select>
                                    </mat-form-field>
                                    <mat-error class="margin_tp"
                                    *ngIf="repairComplaintForm.controls['companyRefId'].touched && repairComplaintForm.controls['companyRefId'].errors?.required">
                                    This field is mandatory.
                                  </mat-error>
                              
                                </div>
                                <div class="col-sm-2 mTop no-print">
                                  <i (click)="openCompanyPopUp();" matTooltip="Add Company" 
                                  class="blue_col mTop fa-solid fa-circle-plus ml-0 
                                   pl-1 fa-lg"></i>
                                </div>  
                                </div>
                                </div>
                                <div class=" col-sm-3 ml-0 pl-0">
                                  <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label> CAN Number </mat-label>
                                    <input matInput placeholder="CAN Number "  formControlName="canNumber" readonly />
                                   
                                  </mat-form-field>
                                </div>
                                <div class="col-sm-3">
                                  <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>Make</mat-label>
                                    <input matInput placeholder="Make" formControlName="make" readonly />
            
                                  </mat-form-field>
                                  
                                </div>
                              </div> 
                              <div class="row p-1">
                               <div class="col-sm-2">
                                  <label class="lable_style pt-2 header">Scenario:</label>
                                </div>
                                <div class="col-sm-2 pt-2">
                                  <mat-radio-group formControlName="scenario" [disabled]="scenariodisable" class="example-full-width" aria-label="Select an option">
                                    <div class="row">
                                      <div class="col-sm-6">
                                    <mat-radio-button value="Scenario1" (change)="radioChange($event)">Standard</mat-radio-button>
                                 </div>
                                 <div class="col-sm-6">
                                    <mat-radio-button class="ml-3" value="Scenario2" (change)="radioChange($event)">Pre-Approved Estimate</mat-radio-button>
                              </div>
                              </div>
                                  </mat-radio-group>
                                </div>

                                </div>
                                <div class="row p-1">
                                  <div class="col-sm-2">
                                     <label class="lable_style pt-2 header">Device:</label>
                                   </div>
                                   <div class="col-sm-2 pt-2">
                                     <mat-radio-group formControlName="deviceType" class="example-full-width" aria-label="Select an option">
                                       <div class="row">
                                         <div class="col-sm-6">
                                       <mat-radio-button value="left" (click)="deviceType('left')">Left</mat-radio-button>
                                    </div>
                                    <div class="col-sm-6">
                                       <mat-radio-button class="ml-3" value="right" (click)="deviceType('right')">Right</mat-radio-button>
                                 </div>
                                 </div>
                                     </mat-radio-group>
                                   </div>

                                   <div class="col-sm-2"></div>

                                   <div class="col-sm-3 ">
                                    <div class="row">
                                    <div class="col-sm-10 pr-1">
                                    <mat-form-field class="example-full-width tdW3 lable_style2  widthh" appearance="outline">
                                      <mat-label class="lable_style2">Billing Company</mat-label>
                               
                                    <mat-select required #select4 formControlName="billingCompanyRefId">
                                      <mat-select-filter *ngIf="select4.focused" [placeholder]="'Filter'" [displayMember]="'companyName'"
                                      [array]="listOfBilling"  (filteredReturn)="filteredBillingCompany =$event"></mat-select-filter>
                                      <mat-option
                                          *ngFor="let item of filteredBillingCompany"
                                          [(value)]="item._id"
                                          >{{ item.companyName }}</mat-option
                                        >
                                    </mat-select>
                                </mat-form-field> 
                                <mat-error class="margin_tp"
                                    *ngIf="repairComplaintForm.controls['billingCompanyRefId'].touched && repairComplaintForm.controls['billingCompanyRefId'].errors?.required">
                                    This Field is Mandatory.
                                  </mat-error>
                                  </div> 
                                  <div class="col-sm-2 mTop ml-0 pl-0 no-print"> 
                                    <i (click)="openBillingCompPopUp();" matTooltip="Add New Billing Company" 
                                    class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                          
                                       </div>
                                  </div>
                                    </div>
                                   </div>
                           </div> 
                           </div>


                           
                           <div class="col-sm-6 mr-0 pr-0 mb-2">
                            <div class="grey-title-card card p-0 mb-2">
                              <div class="grey-card-header bg-grey p-3 d-flex justify-content-between align-items-center">
                                       <h5>FAULT</h5>
                                       <div class="right d-flex align-items-center">
                                          <span class="side-mark left-mark">Left</span>
                                          <span class="material-symbols-outlined pointer" (click)="leftReset()">restart_alt</span>
                                          <span class="material-symbols-outlined font-20 pointer"  (click)="copyToRight()">file_copy</span>
                                       </div>
                              </div>
                              <div class="card=content p-4">
                                <div class="row">
                                  <div class="col-lg-12 col-12 mb-lg-0 mb-2">
                                    <div class="form-group">
                                      <div class="row">
                                        <div class="col-sm-4"> 
                                          <label class="lable_style mt-2 header">Model :</label>
                                        
                                        </div>
 
                                <div class="col-sm-8 pt-0 mt-0">
                                
                                 <div class="row">
                                  <div class="col-sm-8 mr-0 pr-0 pl-0 ml-0"> 
                                  <mat-form-field class="example-full-width mr-0 pr-0 pt-0 mt-0" appearance="outline">
                                    <mat-label>Model </mat-label>   
                                    <mat-select [readonly]="!isDisable" required #select1 formControlName="leftModel" >
                                      <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'model'"
                                      [array]="allDevices"  (filteredReturn)="filteredListDevice =$event"></mat-select-filter>
                                      <mat-option *ngFor="let item of filteredListDevice" [value]="item.model" 
                                       (click)="onLeftModelChange($event,item._id,item.newSerialNo,item.oldSerialNo,item.type,
                                       item.billNo,item.dateOfPurchase,item.createdAt,false,item.paymentStatus)">
                                       {{ item.model }}</mat-option> 
                                      </mat-select>
                                  </mat-form-field>
                                  <mat-error
                                  *ngIf="repairComplaintForm.controls['leftModel'].touched && repairComplaintForm.controls['leftModel'].errors?.required">
                                      This field is mandatory.
                                </mat-error>
                                </div> 
                                  <!-- <div class="col-sm-2 no-print">
                                <i (click)="openFittedByUsComponent();" *ngIf="!isDisable"
                                 matTooltip="Add New Device Fitted By Us" class="blue_col mTop ml-0 pl-1 fa-solid fa-circle-plus
                                 pl-1 fa-lg"></i>
                                 </div> 
                                 <div class="col-sm-2 no-print">
                                  <i (click)="openDeviceByPatientPopUp();" *ngIf="!isDisable"
                                   matTooltip="Add New Device Not Fitted By Us" class="blue_col mTop ml-0 pl-1 fa-solid fa-circle-plus
                                   pl-1 fa-lg"></i>
                                   </div>  -->
                                   <div class="col-sm-2 mt-3 no-print">
                                    <!-- <i (click)="openFittedByUsComponent();" *ngIf="!isDisable"
                                     matTooltip="Add New Device Fitted By Us" class="blue_col mTop ml-0 pl-1 fa-solid fa-circle-plus
                                     pl-1 fa-lg"></i> -->
                                     <img  *ngIf="!isDisable" class="repairtb"
                                     (click)="openFittedByUsComponent();" matTooltip="Add New Device Fitted By Us" width="25"
                                        src="../../assets/down-arrow-square-button.png">
                                     </div>  
                                    <div class="col-sm-2 mt-3 no-print">
                                  <!-- <i (click)="openDeviceByPatientPopUp();" *ngIf="!isDisable"
                                   matTooltip="Add New Device Not Fitted By Us" class="blue_col mTop ml-0 pl-1 fa-solid fa-circle-plus
                                   pl-1 fa-lg"></i> -->
                                   <img  *ngIf="!isDisable" class="repairtb"
                                   (click)="openDeviceByPatientPopUp();" matTooltip="Add New Device Not Fitted By Us" width="25"
                                      src="../../assets/up-square-button-outline.png">
                                   </div> 
                                 </div>
                                </div>
                              </div>
                              
                      </div>
                      <div class="form-group">
                        <div class="row">
                          <div class="col-sm-4">
                            <label class="lable_style mt-2 header">Serial Number :</label>
                         </div>
                         <div class="col-sm-8 ">
                          <div class="row">
                            <div class="col-sm-10 mr-0 pr-0 pl-0 ml-0"> 
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Sr No</mat-label>
                            <!-- <input matInput formControlName="leftSrNo"  placeholder="Sr No" (input)="onLeftSerialChange($event)" /> -->
                            <mat-select  #select5 formControlName="leftSrNo" >
                              <mat-select-filter *ngIf="select5.focused" [placeholder]="'Filter'" [displayMember]="'serialNo'"
                              [array]="serialNumList"  (filteredReturn)="filteredListSerialNo =$event"></mat-select-filter>
                              <mat-option *ngFor="let item of filteredListSerialNo" [value]="item.serialNo" 
                               (click)="onLeftSerialChange($event,item.warranty,item.dateOfPurchase,item.paymentStatus)">
                               {{ item.serialNo }}</mat-option>
                              </mat-select>
                          </mat-form-field> 
                          <mat-error
                          *ngIf="repairComplaintForm.controls['leftSrNo'].touched && repairComplaintForm.controls['leftSrNo'].errors?.required">
                          This Field is Mandatory.
                        </mat-error>
                          <mat-error class="margin_tp"
                          *ngIf="repairComplaintForm.controls['leftSrNo'].touched && repairComplaintForm.controls['leftSrNo'].errors?.pattern">
                          Enter valid Serial Number
                        </mat-error>
                        </div>
                        </div>
                        </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="row">
                               <div class="col-sm-4">
                                    <mat-checkbox class="example-full-width " formControlName="leftDead"  >
                                      Dead
                                      </mat-checkbox>
                                  </div>
                                  <div class="col-sm-4">
                                    <mat-checkbox class="example-full-width " formControlName="leftNoisy" >
                                      Noisy
                                      </mat-checkbox>
                                  </div>
                                  <div class="col-sm-4">
                                    <mat-checkbox class="example-full-width " formControlName="leftDistortion" >
                                     Distortion
                                      </mat-checkbox>
                                  </div>
                                  <div class="col-sm-4">
                                    <mat-checkbox class="example-full-width " formControlName="leftFeedback" >
                                      Feedback
                                      </mat-checkbox>
                                  </div>
                                  <div class="col-sm-4">
                                    <mat-checkbox class="example-full-width " formControlName="leftConnectivityIssue" >
                                      Connectivity issue
                                      </mat-checkbox>
                                  </div>
                                  <div class="col-sm-4">
                                    <mat-checkbox class="example-full-width " formControlName="leftTightFit" >
                                      Tight Fit (CIC/ITC)
                                      </mat-checkbox>
                                  </div>
                                  <div class="col-sm-4">
                                    <mat-checkbox class="example-full-width " formControlName="leftLooseFit" >
                                      Loose Fit (CIC/ITC)
                                      </mat-checkbox>
                                  </div>
                                  <div class="col-sm-4">
                                    <mat-checkbox matTooltip="Other Shell issues(CIC/ITC)" class="example-full-width " formControlName="leftOtherShellIssue" >
                                      Other Shell Issues
                                      </mat-checkbox>
                                  </div>
                                  <div class="col-sm-4">
                                    <mat-checkbox class="example-full-width " formControlName="leftCharging" >
                                      Charging Issue
                                      </mat-checkbox>
                                  </div>
                                  <div class="col-sm-4">
                                    <mat-checkbox class="example-full-width " formControlName="leftBluetooth" >
                                      Bluetooth Issue
                                      </mat-checkbox>
                                  </div>
                                  <div class="col-sm-4">
                                    <mat-checkbox class="example-full-width " formControlName="leftServicing" >
                                      Servicing
                                      </mat-checkbox>
                                  </div>
                                  </div>
                                  </div>
                                  <div class="form-group">
                                    <div class="row">
                                      <div class="col-sm-4">
                                        <label class="lable_style mt-2 header">Other :</label>
                                    </div>
                                
                                    <div class="col-sm-8 ">
                                      <div class="row">
                                        <div class="col-sm-10 mr-0 pr-0 pl-0 ml-0"> 
                                      <mat-form-field class="example-full-width pt-0 mt-0" appearance="outline">
                                        <mat-label>Other</mat-label>
                                        <input matInput placeholder="Other"  formControlName="leftOther"   />
                                      </mat-form-field>
                                      </div>
                                      </div>
                                    </div>
                                    </div>
                                  </div>
                            
                       </div>
                       </div>
                       </div>
                       </div>
                       </div> 
                       <div class="col-sm-6 mr-0 pr-0 mb-2">
                        <div class="grey-title-card card p-0 mb-2">
                          <div class="grey-card-header bg-grey p-3 d-flex justify-content-between align-items-center">
                                   <h5>FAULT</h5>
                                   <div class="right d-flex align-items-center">
                                      <span class="side-mark right-mark">Right</span>
                                      <span class="material-symbols-outlined pointer" (click)="rightReset()">restart_alt</span>
                                      <span class="material-symbols-outlined font-20 pointer"  (click)="copyToLeft()">file_copy</span>
                                   </div>
                          </div>
                          <div class="card=content p-4">
                            <div class="row">
                              <div class="col-lg-12 col-12 mb-lg-0 mb-2">
                                <div class="form-group">
                                  <div class="row">
                                    <div class="col-sm-4">
                                      <label class="lable_style mt-2 header">Model :</label>
                                    </div>
                      
                      <div class="col-sm-8">
                        <div class="row">
                          <div class="col-sm-8 mr-0 pr-0 pl-0 ml-0">
                          <mat-form-field class="example-full-width mr-0 pr-0 pt-0 mt-0" appearance="outline">
                            <mat-label>Model </mat-label>
                            <mat-select #select4 formControlName="rightModel" >
                              <mat-select-filter *ngIf="select4.focused" [placeholder]="'Filter'" [displayMember]="'model'"
                              [array]="allDevices"  (filteredReturn)="filteredListDevice =$event"></mat-select-filter>  
                              <mat-option *ngFor="let item of filteredListDevice" [value]="item.model" 
                              (click)="onRightModelChange($event,item._id,item.newSerialNo,item.oldSerialNo,item.type,item.billNo,
                              item.dateOfPurchase,item.createdAt,false,item.paymentStatus)">
                               {{ item.model }}</mat-option>
                              </mat-select>
                          </mat-form-field>
                          <mat-error
                          *ngIf="repairComplaintForm.controls['rightModel'].touched && repairComplaintForm.controls['rightModel'].errors?.required">
                              This field is mandatory.
                        </mat-error>
                        </div>
                        <div class="col-sm-2 mt-3 -print">
                          <!-- <i (click)="openFittedByUsComponent();" *ngIf="!isDisable"
                           matTooltip="Add New Device Fitted By Us" class="blue_col mTop ml-0 pl-1 fa-solid fa-circle-plus
                           pl-1 fa-lg"></i> -->
                           <img  *ngIf="!isDisable" class="repairtb"
                           (click)="openFittedByUsComponent();" matTooltip="Add New Device Fitted By Us" width="25"
                              src="../../assets/down-arrow-square-button.png">
                           </div>  
                          <div class="col-sm-2 mt-3 no-print">
                        <!-- <i (click)="openDeviceByPatientPopUp();" *ngIf="!isDisable"
                         matTooltip="Add New Device Not Fitted By Us" class="blue_col mTop ml-0 pl-1 fa-solid fa-circle-plus
                         pl-1 fa-lg"></i> -->
                         <img  *ngIf="!isDisable" class="repairtb"
                         (click)="openDeviceByPatientPopUp();" matTooltip="Add New Device Not Fitted By Us" width="25"
                            src="../../assets/up-square-button-outline.png">
                         </div> 
                         </div>
                      </div>
                        </div>
                        </div>
                        <div class="form-group">
                          <div class="row">
                            <div class="col-sm-4">
                              <label class="lable_style mt-2 header">Serial Number :</label>
                           </div>
                            <div class="col-sm-8">
                              <div class="row">
                                <div class="col-sm-10 mr-0 pr-0 pl-0 ml-0"> 
                              <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Sr No</mat-label>
                                <!-- <input matInput formControlName="rightSrNo"  placeholder="Sr No" (input)="onRightSerialChange($event)" /> -->
                                <mat-select  #select6 formControlName="rightSrNo">
                                  <mat-select-filter *ngIf="select6.focused" [placeholder]="'Filter'" [displayMember]="'serialNo'"
                                  [array]="rightSerialNumList"  (filteredReturn)="rightFilteredListSerialNo =$event"></mat-select-filter>
                                  <mat-option *ngFor="let item of rightFilteredListSerialNo" [value]="item.serialNo" 
                                   (click)="onRightSerialChange($event,item.warranty,item.dateOfPurchase,item.paymentStatus)">
                                   {{ item.serialNo }}</mat-option>
                                  </mat-select>
                              </mat-form-field>
                              <mat-error class="margin_tp"
                              *ngIf="repairComplaintForm.controls['rightSrNo'].touched && repairComplaintForm.controls['rightSrNo'].errors?.pattern">
                              Enter valid Serial Number
                            </mat-error>
                            </div>
                            </div>
                            </div>
                            </div>
                            </div>
                            <div class="form-group">
                              <div class="row">
                                  <div class="col-sm-4">
                                    <mat-checkbox class="example-full-width " formControlName="rightDead"  >
                                      Dead
                                      </mat-checkbox>
                                  </div>
                                  <div class="col-sm-4">
                                    <mat-checkbox class="example-full-width " formControlName="rightNoisy" >
                                      Noisy
                                      </mat-checkbox>
                                  </div>
                                  <div class="col-sm-4">
                                    <mat-checkbox class="example-full-width "
                                    formControlName="rightDistortion"  >
                                      Distortion
                                      </mat-checkbox>
                                  </div>
                                  <div class="col-sm-4">
                                    <mat-checkbox class="example-full-width "  formControlName="rightFeedback"  >
                                      Feedback
                                      </mat-checkbox>
                                  </div>
                                  <div class="col-sm-4">
                                    <mat-checkbox class="example-full-width " formControlName="rightConnectivityIssue" >
                                      Connectivity issue
                                      </mat-checkbox>
                                  </div>
                                  <div class="col-sm-4">
                                    <mat-checkbox class="example-full-width " 
                                    formControlName="rightTightFit" >
                                      Tight Fit (CIC/ITC)
                                      </mat-checkbox>
                                  </div>
                                  <div class="col-sm-4">
                                    <mat-checkbox class="example-full-width " formControlName="rightLooseFit" >
                                      Loose Fit (CIC/ITC)
                                      </mat-checkbox>
                                  </div>
                                  <div class="col-sm-4">
                                    <mat-checkbox matTooltip="Other Shell issues(CIC/ITC)"
                                    formControlName="rightOtherShellIssue" class="example-full-width " >
                                      Other Shell issues
                                      </mat-checkbox>
                                  </div>
                                  <div class="col-sm-4">
                                    <mat-checkbox class="example-full-width " formControlName="rightCharging" >
                                      Charging Issue
                                      </mat-checkbox>
                                  </div>
                                  <div class="col-sm-4">
                                    <mat-checkbox class="example-full-width " formControlName="rightBluetooth" >
                                      Bluetooth Issue
                                      </mat-checkbox>
                                  </div>
                                  <div class="col-sm-4">
                                    <mat-checkbox class="example-full-width " formControlName="rightServicing" >
                                      Servicing
                                      </mat-checkbox>
                                  </div>
                                  </div>
                            </div>
                            <div class="form-group">
                              <div class="row">
                                <div class="col-sm-4">
                                  <label class="lable_style mt-2 header">Other :</label>
                              </div>
                                <div class="col-sm-8"> 
                                  <div class="row">
                                    <div class="col-sm-10 mr-0 pr-0 pl-0 ml-0"> 
                                  <mat-form-field class="example-full-width pt-0 mt-0 " appearance="outline">
                                    <mat-label>Other</mat-label>
                                    <input matInput placeholder="Other" formControlName="rightOther" />
                                  </mat-form-field>
                                  </div>
                                  </div>
                                </div>
                                </div>
                                </div>
                  
                   </div>
                  </div>
                  </div>
                  </div>
                  </div>
                  <div class="col-sm-6 mr-0 pr-0 mb-2 ">
                    <div class="grey-title-card card p-0 mb-2">
                      <div class="grey-card-header bg-grey p-3 d-flex justify-content-between align-items-center">
                               <h5>PHYSICAL CONDITION</h5>
                               <div class="right d-flex align-items-center">
                                  <span class="side-mark left-mark">Left</span>
                                  <span class="material-symbols-outlined pointer" (click)="leftReset1()">restart_alt</span>
                                  <span class="material-symbols-outlined font-20 pointer"  (click)="copyToRight1()">file_copy</span>
                               </div>
                      </div>
                      <div class="card=content p-4">
                        <div class="row">
                          <div class="col-lg-12 col-12 mb-lg-0 mb-2">
                            <div class="form-group">
                              <div class="row">
                    
                                <div class="col-sm-7">   
                               <div class="col-sm-12 pb-3">
                                <label class="lable_style header">Physical Damage</label>
                                </div>
                                <div class="col-sm-12 pb-3">
                                  <label class="lable_style header">Visible Corrosion</label>
                                  </div>
                                  <div class="col-sm-12 pb-3">
                                    <label class="lable_style header">Battery Leakage Visible</label>
                                    </div>
                                    <div class="col-sm-12 pb-3">
                                      <label class="lable_style header">Mould Included (BTE)</label>
                                      </div>
                                      <div class="col-sm-12 pb-2">
                                        <label class="lable_style header">Mould Damaged (BTE)</label>
                                       </div>
                              </div>
                              <div class="col-sm-5">
                                <div class="col-sm-12">
                                
                                </div>
                                <div class="col-sm-12 pl-0 ml-0 pb-2">
                                  <mat-radio-group formControlName="leftAnyPhysicalDamage" class="example-full-width" aria-label="Select an option" >
                                    <div class="row">
                                                <div class="col-sm-6 pb-2">
                                              <mat-radio-button class="margin_r" value="Yes">Yes</mat-radio-button>
                                              </div>
                                              <div class="col-sm-6 pb-2">
                                              <mat-radio-button  value="No">No</mat-radio-button>
                                              </div></div>
                                  </mat-radio-group>
                                </div>
                               <div class="col-sm-12 pl-0 ml-0 pb-2">
                                  <mat-radio-group formControlName="leftVisibleCorrorsion"  class="example-full-width" aria-label="Select an option" >
                                    <div class="row">
                                                <div class="col-sm-6 pb-2">
                                              <mat-radio-button class="margin_r" value="Yes">Yes</mat-radio-button>
                                              </div>
                                              <div class="col-sm-6 pb-2">
                                              <mat-radio-button  value="No">No</mat-radio-button>
                                              </div></div>
                                  </mat-radio-group>
                                </div>
                               <div class="col-sm-12 pl-0 ml-0 pb-2">
                                  <mat-radio-group formControlName="leftBatteryLeakageVisible"  class="example-full-width" aria-label="Select an option" >
                                    <div class="row">
                                                <div class="col-sm-6 pb-2">
                                              <mat-radio-button class="margin_r" value="Yes">Yes</mat-radio-button>
                                              </div>
                                              <div class="col-sm-6 pb-2">
                                              <mat-radio-button  value="No">No</mat-radio-button>
                                              </div></div>
                                  </mat-radio-group>
                                </div>
                               <div class="col-sm-12 pl-0 ml-0 pb-2">
                                  <mat-radio-group formControlName="leftMouldIncluded" class="example-full-width" aria-label="Select an option" >
                                    <div class="row">
                                                <div class="col-sm-6 pb-2">
                                              <mat-radio-button class="margin_r" value="Yes">Yes</mat-radio-button>
                                              </div>
                                              <div class="col-sm-6 pb-2">
                                              <mat-radio-button  value="No">No</mat-radio-button>
                                              </div></div>
                                  </mat-radio-group>
                                </div>
                               <div class="col-sm-12 pl-0 ml-0 pb-2">
                                  <mat-radio-group formControlName="leftMouldDamaged"  class="example-full-width" aria-label="Select an option" >
                                    <div class="row">
                                                <div class="col-sm-6 pb-2">
                                              <mat-radio-button class="margin_r" value="Yes">Yes</mat-radio-button>
                                              </div>
                                              <div class="col-sm-6 pb-2">
                                              <mat-radio-button  value="No">No</mat-radio-button>
                                              </div></div>
                                  </mat-radio-group>
                                </div>
                              </div>
                             
                               </div>
                             </div>
                             </div>
                             </div>
                             </div>
                             </div>
                             </div>
                             <div class="col-sm-6 mr-0 pr-0 mb-2">
                              <div class="grey-title-card card p-0 mb-2">
                                <div class="grey-card-header bg-grey p-3 d-flex justify-content-between align-items-center">
                                         <h5>PHYSICAL CONDITION</h5>
                                         <div class="right d-flex align-items-center">
                                            <span class="side-mark right-mark">Right</span>
                                            <span class="material-symbols-outlined pointer" (click)="rightReset1()">restart_alt</span>
                                            <span class="material-symbols-outlined font-20 pointer"  (click)="copyToLeft1()">file_copy</span>
                                         </div>
                                </div>
                                <div class="card=content p-4">
                                  <div class="row">
                                    <div class="col-lg-12 col-12 mb-lg-0 mb-2">
                                      <div class="form-group">
                            
                                        <div class="row">
                                          <div class="col-sm-7">   
                                            <div class="col-sm-12 pb-3">
                                             <label class="lable_style header" >Physical Damage</label>
                                             </div>
                                             <div class="col-sm-12 pb-3">
                                               <label class="lable_style header">Visible Corrosion</label>
                                               </div>
                                               <div class="col-sm-12 pb-3">
                                                 <label class="lable_style header">Battery Leakage Visible</label>
                                                 </div>
                                                 <div class="col-sm-12 pb-3">
                                                   <label class="lable_style header">Mould Included (BTE)</label>
                                                   </div>
                                                   <div class="col-sm-12 pb-2">
                                                     <label class="lable_style header">Mould Damaged (BTE)</label>
                                                    </div>
                                           </div>
                                      
                                        
                                        <div class="col-sm-5">
                                        
                                         <div class="col-sm-12 pl-0 ml-0 pb-2">
                                            <mat-radio-group formControlName="rightAnyPhysicalDamage"  class="example-full-width" aria-label="Select an option" >
                                              <div class="row">
                                                <div class="col-sm-6 pb-2">
                                              <mat-radio-button class="margin_r" value="Yes">Yes</mat-radio-button>
                                              </div>
                                              <div class="col-sm-6 pb-2">
                                              <mat-radio-button  value="No">No</mat-radio-button>
                                              </div></div>
                                            </mat-radio-group>
                                          </div>
                                         <div class="col-sm-12 pl-0 ml-0 pb-2">
                                            <mat-radio-group formControlName="rightVisibleCorrorsion"   class="example-full-width" aria-label="Select an option" >
                                              <div class="row">
                                                <div class="col-sm-6 pb-2">
                                              <mat-radio-button class="margin_r" value="Yes">Yes</mat-radio-button>
                                              </div>
                                              <div class="col-sm-6 pb-2">
                                              <mat-radio-button value="No">No</mat-radio-button>
                                              </div></div>
                                            </mat-radio-group>
                                          </div>
                                         <div class="col-sm-12 pl-0 ml-0 pb-2">
                                            <mat-radio-group formControlName="rightBatteryLeakageVisible" class="example-full-width" aria-label="Select an option" >
                                          <div class="row">
                                                <div class="col-sm-6 pb-2">
                                              <mat-radio-button class="margin_r" value="Yes">Yes</mat-radio-button>
                                              </div>
                                              <div class="col-sm-6 pb-2">
                                              <mat-radio-button value="No">No</mat-radio-button>
                                              </div></div>
                                            </mat-radio-group>
                                          </div>
                                         <div class="col-sm-12 pl-0 ml-0 pb-2">
                                            <mat-radio-group formControlName="rightMouldIncluded"  class="example-full-width" aria-label="Select an option" >
                                          <div class="row">
                                                <div class="col-sm-6 pb-2">
                                              <mat-radio-button class="margin_r" value="Yes">Yes</mat-radio-button>
                                              </div>
                                              <div class="col-sm-6 pb-2">
                                              <mat-radio-button value="No">No</mat-radio-button>
                                              </div></div>
                                            </mat-radio-group>
                                          </div>
                                         <div class="col-sm-12 pl-0 ml-0 pb-2">
                                            <mat-radio-group formControlName="rightMouldDamaged"   class="example-full-width" aria-label="Select an option" >
                                          <div class="row">
                                                <div class="col-sm-6 pb-2">
                                              <mat-radio-button class="margin_r" value="Yes">Yes</mat-radio-button>
                                              </div>
                                              <div class="col-sm-6 pb-2">
                                              <mat-radio-button value="No">No</mat-radio-button>
                                              </div></div>
                                            </mat-radio-group>
                                          </div>
                                        </div>
                                         </div>
                                         </div>
                                       </div>
                                       </div>
                                       </div>
                                       </div>
                                       </div>
                                       <div class="col-sm-6 mr-0 pr-0 mb-2 ">
                                        <div class="grey-title-card card p-0 mb-2">
                                          <div class="grey-card-header bg-grey p-3 d-flex justify-content-between align-items-center">
                                                   <h5>OTHERS</h5>
                                                   <div class="right d-flex align-items-center">
                                                      <span class="side-mark left-mark">Left</span>
                                                      <span class="material-symbols-outlined pointer" (click)="leftReset2()">restart_alt</span>
                                                      <span class="material-symbols-outlined font-20 pointer"  (click)="copyToRight2()">file_copy</span>
                                                   </div>
                                          </div>
                                          <div class="card=content p-4">
                                            <div class="row">
                                              <div class="col-lg-12 col-12 mb-lg-0 mb-2">
                                                <div class="form-group">
                                                  <div class="row">

                                       
                                <div class="col-sm-6 pl-0 ml-0">
                  
                                  <div class="col-sm-12 ">
                                   <label  class="lable_style header mt'"> Warranty </label>
                                  </div>
                                  <div class="col-sm-12 mt-3">
                                   <label class="mt-2 lable_style header">if Yes,Date Of Purchase</label>
                                 </div>
                                 <div class="col-sm-12  mt-3">
                                   <label class="mt-2 lable_style header"> Repair Quotation </label>
                                 </div>
                                 <div class="col-sm-12 mt-4">
                                  <label class="mt-4 lable_style header">Accessories included</label>
                                </div>
                                 <div class="col-sm-12 mt-4">
                                   <label class="mt-2 lable_style header">Past Repair Ref (optional)</label>
                                 </div>
                                 <div class="col-sm-12 mt-4">
                                   <label class="mt-1 lable_style header">Other Remarks</label>
                                 </div>
                                 
                                </div>
                             <div class="col-sm-6">
                           
                                  <div class="col-sm-12 pl-0 ml-0">
                                <mat-radio-group formControlName="leftWarranty" class=" example-full-width " aria-label="Select an option" >
                                   <div class="row">
                                                <div class="col-sm-6 pb-1">
                                              <mat-radio-button class="margin_r" value="Yes">Yes</mat-radio-button>
                                              </div>
                                              <div class="col-sm-6 pb-1">
                                              <mat-radio-button value="No">No</mat-radio-button>
                                              </div></div>
                                </mat-radio-group>
                                </div>
                             
                              <div class="col-sm-12 pl-0 ml-0">
                                <mat-form-field   class="example-full-width" appearance="outline">
                                  <mat-label>Date Of Purchase</mat-label>
                                  <input  matInput formControlName="leftDateOfPurchase" [matDatepicker]="dateOfPurchase" id="dateOfPurchase"
                               placeholder="DD/MM/YYYY" readonly>
        
                                <mat-datepicker-toggle matSuffix [for]="dateOfPurchase">
                                </mat-datepicker-toggle>
                                <mat-datepicker #dateOfPurchase ></mat-datepicker>
                                </mat-form-field>
                              </div>
                              <div class="col-sm-12 pl-0 ml-0 mt-1">
                                <mat-radio-group formControlName="leftRepairQuotation"   class="example-full-width" aria-label="Select an option" >
                                  <div class="row col-sm-12">
                                    <div class="col-sm-12">
                                  <mat-radio-button class="margin_r mt-2 pb-1" value="To Be Provided">To Be Provided</mat-radio-button>
                                 </div>
                                 <div class="col-sm-12">
                                  <mat-radio-button class="pb-1" value="Repair as per flat price">Repair as per flat price</mat-radio-button>
                                </div>
                                </div>
                                </mat-radio-group>
                              </div>
                              <div class="col-sm-12 pl-0 ml-0">
                                <div class="row">
                                  <div class="col-sm-10 mr-0 pr-0">
                                <mat-form-field class="example-full-width mr-0 pr-0" appearance="outline">
                                  <mat-label>Accessories included</mat-label>
                                  <!-- <input matInput  formControlName="leftAcessoriesIncluded" /> -->
                                  <mat-select #select12 (click)="fetchAllAccesories();" formControlName="leftAcessoriesIncluded">
                                    <mat-select-filter *ngIf="select12.focused" [placeholder]="'Filter'"
                                      [displayMember]="'leftAcessoriesIncludedName'" [array]="accesoriesList"
                                      (filteredReturn)="filteredListAccesories =$event"></mat-select-filter>
                                      <mat-option value="">Select Accessory</mat-option> 
                                    <mat-option *ngFor="let item of filteredListAccesories" [value]="item._id"
                                      (click)="accesoriesClickLeft(item._id,item.accesories);">
                                      {{ item.accesories }}</mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </div>
                              <div class="col-sm-2 mTop no-print">
                                <i (click)="openAccesoriesPopUpLeft();" matTooltip="Add Accesories" 
                                class="blue_col mTop fa-solid fa-circle-plus ml-0 pl-1
                                 pl-1 fa-lg"></i>
                              </div>
                            
                              </div>
                              </div>
                              <div class="col-sm-12 pl-0 ml-0">
                                <mat-form-field   class="example-full-width" appearance="outline">
                                  <mat-label>Past Repair Ref</mat-label>
                                  <input matInput formControlName="leftPastRepairRef"  />
                                </mat-form-field>
                              </div>
                              <div class="col-sm-12 pl-0 ml-0">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Other Remarks</mat-label>
                                  <input matInput  formControlName="leftOtherRemark"  />
                                </mat-form-field>
                              </div>
                              
                            </div>

                             </div>
                             </div>
                          </div>
                          </div>
                          </div>
                                        </div>
                                        </div>
                                        <div class="col-sm-6 mr-0 pr-0 mb-2">
                                          <div class="grey-title-card card p-0 mb-2">
                                            <div class="grey-card-header bg-grey p-3 d-flex justify-content-between align-items-center">
                                                     <h5>OTHERS</h5>
                                                     <div class="right d-flex align-items-center">
                                                        <span class="side-mark right-mark">Right</span>
                                                        <span class="material-symbols-outlined pointer" (click)="rightReset2()">restart_alt</span>
                                                        <span class="material-symbols-outlined font-20 pointer"  (click)="copyToLeft2()">file_copy</span>
                                                     </div>
                                            </div>
                                            <div class="card=content p-4">
                                              <div class="row">
                                                <div class="col-lg-12 col-12 mb-lg-0 mb-2">
                                                  <div class="form-group">

              
                  <div class="row">
                 <div class="col-sm-6 pl-0 ml-0">
                  
                  <div class="col-sm-12 ">
                    <label class="mt-1 lable_style header"> Warranty </label>
                   </div>
                   <div class="col-sm-12 mt-3">
                    <label class="mt-2 lable_style header">if Yes,Date Of Purchase</label>
                  </div>
                  <div class="col-sm-12  mt-3">
                    <label class="mt-2 lable_style header"> Repair Quotation </label>
                  </div>
                  <div class="col-sm-12 mt-4">
                   <label class="mt-4 lable_style header">Accessories included</label>
                 </div>
                  <div class="col-sm-12 mt-4">
                    <label class="mt-2 lable_style header">Past Repair Ref (optional)</label>
                  </div>
                  <div class="col-sm-12 mt-4">
                    <label class="mt-1 lable_style header">Other Remarks</label>
                  </div>
                  
                 </div>
                 
                   <div class="col-sm-6">
                   
             <div class="col-sm-12 pl-0 ml-0">
                    <mat-radio-group formControlName="rightWarranty"  class="example-full-width" aria-label="Select an option" >
                    <div class="row">
                                                <div class="col-sm-6 pb-1">
                                              <mat-radio-button class="margin_r" value="Yes">Yes</mat-radio-button>
                                              </div>
                                              <div class="col-sm-6 pb-1">
                                              <mat-radio-button value="No">No</mat-radio-button>
                                              </div></div>
                    </mat-radio-group>
                  </div>
                  <div class="col-sm-12 pl-0 ml-0">
                    <mat-form-field   class="example-full-width " appearance="outline">
                      <mat-label>Date Of Purchase</mat-label>

                      <input  matInput formControlName="rightDateOfPurchase"  [matDatepicker]="dateOfPurchase2" id="dateOfPurchase2"
                     placeholder="DD/MM/YYYY" readonly>

                    <mat-datepicker-toggle matSuffix [for]="dateOfPurchase2">
                    </mat-datepicker-toggle>
                    <mat-datepicker #dateOfPurchase2 ></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div class="col-sm-12 pl-0 ml-0 mt-1">
                    <mat-radio-group formControlName="rightRepairQuotation" class="example-full-width" aria-label="Select an option" >
                      <div class="row col-sm-12">
                        <div class="col-sm-12">
                      <mat-radio-button class="margin_r mt-2 pb-1" value="To Be Provided">To Be Provided</mat-radio-button>
                     </div>
                     <div class="col-sm-12">
                      <mat-radio-button class="pb-1" value="Repair as per flat price">Repair as per flat price</mat-radio-button>
                  </div>
                  </div>
                    </mat-radio-group>
                  </div>
                 
                  <div class="col-sm-12 pl-0 ml-0">
                    <div class="row">
                      <div class="col-sm-10 mr-0 pr-0">
                    <mat-form-field class="example-full-width mr-0 pr-0" appearance="outline">
                      <mat-label>Accessories included</mat-label>
                      <mat-select #select15 (click)="fetchAllAccesories();" formControlName="rightAcessoriesIncluded">
                        <mat-select-filter *ngIf="select15.focused" [placeholder]="'Filter'"
                          [displayMember]="'rightAcessoriesIncludedName'" [array]="accesoriesList"
                          (filteredReturn)="filteredListAccesories =$event"></mat-select-filter>
                          <mat-option value="">Select Accessory</mat-option>
                        <mat-option *ngFor="let item of filteredListAccesories" [value]="item._id"
                          (click)="accesoriesClickRight(item._id,item.accesories);">
                          {{ item.accesories }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-sm-2 mTop no-print">
                    <i (click)="openAccesoriesPopUpRight();" matTooltip="Add Accesories" 
                    class="blue_col mTop fa-solid fa-circle-plus ml-0 pl-1
                     pl-1 fa-lg"></i>
                  </div> 
                 
                  </div>
                  </div>
                  <div class="col-sm-12 pl-0 ml-0">
                    <mat-form-field   class="example-full-width" appearance="outline">
                      <mat-label>Past Repair Ref</mat-label>
                      <input matInput  formControlName="rightPastRepairRef" />
                    </mat-form-field>
                  </div>
                  <div class="col-sm-12 pl-0 ml-0">
                    <mat-form-field   class="example-full-width" appearance="outline">
                      <mat-label>Other Remarks</mat-label>
                      <input matInput formControlName="rightOtherRemark"  />
                    </mat-form-field>
                  </div>
                 
                  </div>
                 </div>
                 </div>
              </div>
              </div>
               </div>
               </div>

              
            </div>
        </div>
</div>
<div *ngIf="isPrepopulate" class="col-sm-4 pl-4 pr-4">
  <div class="row">
    <div class="card  divbody2 minCard1 borderradius  p-2 ">

      <div class="row" >
        <div class="col-sm-8 mt-3">
          <label class="titlecolor repairLable mt-3 pl-2">Repair Tracking History :</label>
        </div> 
        <div class="col-sm-1"></div>
        <div class="col-sm-1 mt-2 mb-2 pr-2 justify-content-end">
          <div class="icon-btns justify-content-end">
            <ul class="mb-2 "> 
               <li> 
                  <a class="icon-box2" data-toggle="tooltip" data-placement="top" 
                  (click)="openRepairActionPopUp()" matTooltip="Add New Action">
                  <span class="material-symbols-outlined">
                    monitoring
                    </span>
                  <!-- <span class="material-symbols-outlined mt-1">playlist_add</span> -->
                  <img src="../../assets/icon-bg2.svg" class="icon-bg2">
                  
                  </a>
               </li>
  
            </ul>
         </div>
        
        </div>
        <div class="col-sm-2"></div>
        <div class="col-lg-12 ">
          <div class="white_box mxHeight scroll card_height mb_30 mx35">
            <div class="box_header">
              <div class="main-title">
            
              </div>
            </div>
          
            <div *ngFor="let items of repairActionData" class="accordion  accordion_custom mb_50">
              <div  class="card " id="accordion_ex">
                <div class="card-header" id="headingOne">
                  <h2 class="mb-0">
                  
                    <a *ngIf="items.repairActionName == 'Payment Done'" href="#" class="btn collapsed" type="button" data-toggle="collapse"
                    [attr.data-target]="'#collapse'+ items._id" aria-expanded="false" aria-controls="collapseOne">
                    {{items.actionId}} . {{items.repairActionName}} -&nbsp;{{items.selectPaymentType}},&nbsp;
                   By- {{items.paymentType}}
                   : &nbsp; {{items.createdAt | date :'MMM d, y'}}
                   
                  </a> 
                  <a *ngIf="items.repairActionName == 'Checked By' || items.repairActionName == 'Programmed By'" href="#" class="btn collapsed" type="button" data-toggle="collapse"
                  [attr.data-target]="'#collapse'+ items._id" aria-expanded="false" aria-controls="collapseOne">
                  {{items.actionId}} . {{items.repairActionName}}&nbsp; - {{items.userName}}
                   : &nbsp; {{items.createdAt | date :'MMM d, y'}}
                </a>
                
                <a *ngIf="items.repairActionName == 'Communication With'
                || items.repairActionName == 'Estimate Advised To' || items.repairActionName == 'Estimate Approved By'
                || items.repairActionName == 'Estimate Received From' || items.repairActionName == 'Estimate Rejected By'
                || items.repairActionName == 'Received From' || items.repairActionName == 'Sent To'" href="#" class="btn collapsed" type="button" data-toggle="collapse"
                    [attr.data-target]="'#collapse'+ items._id" aria-expanded="false" aria-controls="collapseOne">
                    {{items.actionId}} . {{items.repairActionName}}&nbsp;- {{items.clientVendorBranchRadioBtn}},&nbsp;&nbsp;
                          <span *ngIf="items.clientVendorBranchRadioBtn == 'Client'">{{items.patientName}}</span>
                          <span *ngIf="items.clientVendorBranchRadioBtn == 'Next To Kin'">{{items.other}}</span>
                          <span *ngIf="items.clientVendorBranchRadioBtn == 'Vendor'">{{items.vendorName}}</span>
                 <span *ngIf="items.clientVendorBranchRadioBtn == 'Clinic'">{{items.clinicName}}</span> 
                 : &nbsp; {{items.createdAt | date :'MMM d, y'}}
                </a>

                <a *ngIf="items.repairActionName == 'Collected By'" href="#" class="btn collapsed" type="button" data-toggle="collapse"
                [attr.data-target]="'#collapse'+ items._id" aria-expanded="false" aria-controls="collapseOne">
                {{items.actionId}} . {{items.repairActionName}} - &nbsp; {{items.branchName}}
                 : &nbsp; {{items.createdAt | date :'MMM d, y'}}
              </a>
                  <a *ngIf="items.repairActionName == 'Duly Repaired Ready At' || items.repairActionName == 'Unrepaired Ready'" href="#" class="btn collapsed" type="button" data-toggle="collapse"
                  [attr.data-target]="'#collapse'+ items._id" aria-expanded="false" aria-controls="collapseOne">
                  {{items.actionId}} . {{items.repairActionName}} -&nbsp; {{items.branchName}}
                   : &nbsp; {{items.createdAt | date :'MMM d, y'}}
                </a>
                <a *ngIf="items.repairActionName == 'Estimate Calculated'" href="#" class="btn collapsed" type="button" data-toggle="collapse"
                [attr.data-target]="'#collapse'+ items._id" aria-expanded="false" aria-controls="collapseOne">
                {{items.actionId}} . {{items.repairActionName}}&nbsp; By- {{items.userName}}, &nbsp;
                  Amount - {{items.amountB}}
                  : &nbsp; {{items.createdAt | date :'MMM d, y'}}
              </a>
              <a *ngIf="items.repairActionName == 'Create Final Bill'" href="#" class="btn collapsed" type="button" data-toggle="collapse"
              [attr.data-target]="'#collapse'+ items._id" aria-expanded="false" aria-controls="collapseOne">
              {{items.actionId}} . {{items.repairActionName}}: &nbsp; Bill No - {{items.billNo}}
               : &nbsp; {{items.createdAt | date :'MMM d, y'}}
            </a> 
            <a *ngIf="items.repairActionName == 'Change of Device'" href="#" class="btn collapsed" type="button" data-toggle="collapse"
            [attr.data-target]="'#collapse'+ items._id" aria-expanded="false" aria-controls="collapseOne">
            {{items.actionId}} . {{items.repairActionName}}: 
              &nbsp; {{items.createdAt | date :'MMM d, y'}}
          </a>
                  </h2>
                </div>
      
                <div [attr.id]="'collapse'+ items._id"  
                (click)="prepopulationAction(items._id)" class="collapse repairtb"  aria-labelledby="headingOne"
                  data-parent="#accordion_ex">
                  <div class="card-body">
                    <div class="row"> 
                      <div class="QA_table">
                        <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper no-footer">
                          <table class="table lms_table_active2 dataTable no-footer dtr-inline text-left" id="DataTables_Table_0"
                            role="grid">
                            <tbody *ngIf="items.repairActionName == 'Payment Done'"> 
                              <tr role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Mode Of Payment : </td>
                                <td> {{items.paymentType}}
                                </td>
                              </tr>
                              <tr role="row" class="even">
                                <td tabindex="0" class="sorting_1">Amount (Rs.) :</td>
                                <td class="repairtb bCol">{{items.amountC}}</td> 
                              </tr>
                              <tr role="row" class="even">
                                <td tabindex="0" class="sorting_1">Status :</td>
                                <td>{{items.status}}</td>
                              </tr> 
                              <tr role="row" class="even">
                                <td tabindex="0" class="sorting_1">Created By :</td>
                                <td>{{items.userName}},&nbsp; {{items.createdAt | date :'M/d/yy, h:mm a'}}</td>
                              </tr> 
                            </tbody>

                            <tbody *ngIf="items.repairActionName == 'Checked By'"> 
                            
                              <tr role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Comments : </td>
                                <td> {{items.textBoxEntry}}
                                </td>
                              </tr>
                              <tr role="row" class="even">
                                <td tabindex="0" class="sorting_1">Status :</td>
                                <td>{{items.status}}</td>
                              </tr> 
                              <tr role="row" class="even">
                                <td tabindex="0" class="sorting_1">Created By :</td>
                                <td>{{items.userName}},&nbsp; {{items.createdAt | date :'M/d/yy, h:mm a'}}</td>
                              </tr> 
                            </tbody>

                            <tbody *ngIf="items.repairActionName == 'Collected By'"> 
                              <!-- <tr role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Collected With : </td>
                                <td> {{items.clientVendorBranchRadioBtn}}
                                </td>
                              </tr> -->
                              <!-- <tr *ngIf="items.clientVendorBranchRadioBtn == 'Client'" role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Patient Name : </td>
                                <td> {{items.patientName}}
                                </td>
                              </tr> -->
                              <!-- <tr *ngIf="items.clientVendorBranchRadioBtn == 'Other'" role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Next To Kin : </td>
                                <td> {{items.other}}
                                </td>
                              </tr> -->
                              <tr role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Comments : </td>
                                <td> {{items.textBoxEntry}}
                                </td>
                              </tr>
                              <tr role="row" class="even">
                                <td tabindex="0" class="sorting_1">Status :</td>
                                <td>{{items.status}}</td>
                              </tr> 
                              <tr *ngIf="items.textBoxEntrySignature !== undefined" role="row" class="even">
                                <td tabindex="0" class="sorting_1">Signature :</td>
                                <td><img class="imgSize" src='{{ items.textBoxEntrySignature }}' /></td>
                              </tr>
                              <tr role="row" class="even">
                                <td tabindex="0" class="sorting_1">Created By :</td>
                                <td>{{items.userName}},&nbsp; {{items.createdAt | date :'M/d/yy, h:mm a'}}</td>
                              </tr> 
                            </tbody>
 
                            <tbody *ngIf="items.repairActionName == 'Communication With'"> 
                              <!-- <tr role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Communication With : </td>
                                <td> {{items.clientVendorBranchRadioBtn}}
                                </td>
                              </tr> -->
                              <!-- <tr *ngIf="items.clientVendorBranchRadioBtn == 'Client'" role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Patient Name : </td>
                                <td> {{items.patientName}}
                                </td>
                              </tr>
                              <tr *ngIf="items.clientVendorBranchRadioBtn == 'Vendor'" role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Vendor Name : </td>
                                <td> {{items.vendorName}}
                                </td>
                              </tr> -->
                              <tr role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Communication By : </td>
                                <td> {{items.phoneMsg}}
                                </td>
                              </tr>
                              <tr role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Comments : </td>
                                <td> {{items.textBoxEntry}}
                                </td>
                              </tr>
                              <tr role="row" class="even">
                                <td tabindex="0" class="sorting_1">Status :</td>
                                <td>{{items.status}}</td>
                              </tr> 
                              <tr role="row" class="even">
                                <td tabindex="0" class="sorting_1">Created By :</td>
                                <td>{{items.userName}},&nbsp; {{items.createdAt | date :'M/d/yy, h:mm a'}}</td>
                              </tr> 
                            </tbody>
                            <tbody *ngIf="items.repairActionName == 'Duly Repaired Ready At'"> 
                              <tr role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Branch Name : </td>
                                <td> {{items.branchName}}
                                </td>
                              </tr>
                              <tr role="row" class="even">
                                <td tabindex="0" class="sorting_1">Status :</td>
                                <td>{{items.status}}</td>
                              </tr> 
                              <!-- <tr role="row" class="even">
                                <td tabindex="0" class="sorting_1">Paid By :</td>
                                <td>{{items.userName}}</td>
                              </tr>  -->
                              <tr role="row" class="even">
                                <td tabindex="0" class="sorting_1">Created By :</td>
                                <td>{{items.userName}},&nbsp; {{items.createdAt | date :'M/d/yy, h:mm a'}}</td>
                              </tr> 
                            </tbody>

                            <tbody *ngIf="items.repairActionName == 'Estimate Advised To'"> 
                              <!-- <tr role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Advised To: </td>
                                <td> {{items.clientVendorBranchRadioBtn}}
                                </td>
                              </tr> -->
                              <!-- <tr *ngIf="items.clientVendorBranchRadioBtn == 'Client'" role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Patient Name : </td>
                                <td> {{items.patientName}}
                                </td>
                              </tr>
                              <tr *ngIf="items.clientVendorBranchRadioBtn == 'Other'" role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Next To Kin : </td>
                                <td> {{items.other}}
                                </td>
                              </tr> -->
                              <tr role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Communication By : </td>
                                <td> {{items.phoneMsg}}
                                </td>
                              </tr>
                              <tr role="row" class="even">
                                <td tabindex="0" class="sorting_1">Amount (Rs.) :</td>
                                <td class="repairtb bCol">{{items.amountC}}</td> 
                              </tr>
                              <tr role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Comments : </td>
                                <td> {{items.textBoxEntry}}
                                </td>
                              </tr>
                              <tr role="row" class="even">
                                <td tabindex="0" class="sorting_1">Status :</td>
                                <td>{{items.status}}</td>
                              </tr> 
                              <tr role="row" class="even">
                                <td tabindex="0" class="sorting_1">Created By :</td>
                                <td>{{items.userName}},&nbsp; {{items.createdAt | date :'M/d/yy, h:mm a'}}</td>
                              </tr> 
                              <!-- <tr role="row" class="even">
                                <td tabindex="0" class="sorting_1">Date & Time :</td>
                                <td>{{items.createdAt | date :'medium'}}</td>
                              </tr>  -->
                            </tbody>

                            <tbody *ngIf="items.repairActionName == 'Estimate Approved By'"> 
                              <!-- <tr role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Approved Type : </td>
                                <td> {{items.clientVendorBranchRadioBtn}}
                                </td>
                              </tr> -->
                              <!-- <tr *ngIf="items.clientVendorBranchRadioBtn == 'Client'" role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Patient Name : </td>
                                <td> {{items.patientName}}
                                </td>
                              </tr>
                              <tr *ngIf="items.clientVendorBranchRadioBtn == 'Other'" role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Next To Kin : </td>
                                <td> {{items.other}}
                                </td>
                              </tr> -->
                              <tr *ngIf="items.clientVendorBranchRadioBtn == 'Clinic'" role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Clinic Name : </td>
                                <td> {{items.clinicName}}
                                </td>
                              </tr>
                              <tr role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Communication By : </td>
                                <td> {{items.phoneMsg}}
                                </td>
                              </tr>
                              <tr role="row" class="even">
                                <td tabindex="0" class="sorting_1">Amount (Rs.) :</td>
                                <td class="repairtb bCol">{{items.amountC}}</td> 
                              </tr>
                              <tr role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Comments : </td>
                                <td> {{items.textBoxEntry}}
                                </td>
                              </tr>
                              <tr role="row" class="even">
                                <td tabindex="0" class="sorting_1">Status :</td>
                                <td>{{items.status}}</td>
                              </tr> 
                              <!-- <tr role="row" class="even">
                                <td tabindex="0" class="sorting_1">Approved By :</td>
                                <td>{{items.userName}}</td>
                              </tr> 
                              <tr role="row" class="even">
                                <td tabindex="0" class="sorting_1">Date & Time :</td>
                                <td>{{items.createdAt | date :'medium'}}</td>
                              </tr>  -->
                              <tr role="row" class="even">
                                <td tabindex="0" class="sorting_1">Created By :</td>
                                <td>{{items.userName}},&nbsp; {{items.createdAt | date :'M/d/yy, h:mm a'}}</td>
                              </tr> 
                            </tbody>

                            <tbody *ngIf="items.repairActionName == 'Estimate Calculated'"> 
                              <tr role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Left Device Age : </td>
                                <td> {{items.leftDeviceAgeInYears}}
                                </td>
                              </tr>
                              <tr role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Right Device Age : </td>
                                <td> {{items.rightDeviceAgeInYears}}
                                </td>
                              </tr>
                              <tr role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Fitted By : </td>
                                <td> {{items.fittedBy}}
                                </td>
                              </tr>
                              <tr role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Courier Charges : </td>
                                <td> {{items.courierCharges}}
                                </td>
                              </tr>
                              <tr role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Final Amount (Rs.) : </td>
                                <td> {{items.amountB}}
                                </td>
                              </tr>
                              <tr role="row" class="even">
                                <td tabindex="0" class="sorting_1">Status :</td>
                                <td>{{items.status}}</td>
                              </tr> 
                              <tr role="row" class="even">
                                <td tabindex="0" class="sorting_1">Created By :</td>
                                <td>{{items.userName}},&nbsp; {{items.createdAt | date :'M/d/yy, h:mm a'}}</td>
                              </tr> 
                              <!-- <tr role="row" class="even">
                                <td tabindex="0" class="sorting_1">Calculated By :</td>
                                <td>{{items.userName}}</td>
                              </tr> 
                              <tr role="row" class="even">
                                <td tabindex="0" class="sorting_1">Date & Time :</td>
                                <td>{{items.createdAt | date :'medium'}}</td>
                              </tr>  -->
                            </tbody>

                            <tbody *ngIf="items.repairActionName == 'Estimate Received From'"> 
                              <!-- <tr role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Received From : </td>
                                <td> {{items.clientVendorBranchRadioBtn}}
                                </td>
                              </tr> -->
                              <!-- <tr *ngIf="items.clientVendorBranchRadioBtn == 'Client'" role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Patient Name : </td>
                                <td> {{items.patientName}}
                                </td>
                              </tr>
                              <tr *ngIf="items.clientVendorBranchRadioBtn == 'Other'" role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Next To Kin : </td>
                                <td> {{items.other}}
                                </td>
                              </tr> -->
                              <tr role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Communication By : </td>
                                <td> {{items.phoneMsg}}
                                </td>
                              </tr>
                              <tr role="row" class="even">
                                <td tabindex="0" class="sorting_1">Amount (Rs.) :</td>
                                <td class="repairtb bCol">{{items.amountA}}</td> 
                              </tr>
                              <tr role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Comments : </td>
                                <td> {{items.textBoxEntry}}
                                </td>
                              </tr>
                              <tr role="row" class="even">
                                <td tabindex="0" class="sorting_1">Status :</td>
                                <td>{{items.status}}</td>
                              </tr> 
                              <tr role="row" class="even">
                                <td tabindex="0" class="sorting_1">Created By :</td>
                                <td>{{items.userName}},&nbsp; {{items.createdAt | date :'M/d/yy, h:mm a'}}</td>
                              </tr> 
                            </tbody>

                            <tbody *ngIf="items.repairActionName == 'Estimate Rejected By'"> 
                              <!-- <tr role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Rejected From : </td>
                                <td> {{items.clientVendorBranchRadioBtn}}
                                </td>
                              </tr> -->
                              <tr *ngIf="items.clientVendorBranchRadioBtn == 'Vendor'" role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Vendor Name : </td>
                                <td> {{items.vendorName}}
                                </td>
                              </tr>
                              <tr role="row" class="even">
                                <td tabindex="0" class="sorting_1">Amount (Rs.) :</td>
                                <td class="repairtb bCol">{{items.amountC}}</td> 
                              </tr>
                              <tr role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Comments : </td>
                                <td> {{items.textBoxEntry}}
                                </td>
                              </tr>
                              <tr role="row" class="even">
                                <td tabindex="0" class="sorting_1">Status :</td>
                                <td>{{items.status}}</td>
                              </tr> 
                              <tr role="row" class="even">
                                <td tabindex="0" class="sorting_1">Created By :</td>
                                <td>{{items.userName}},&nbsp; {{items.createdAt | date :'M/d/yy, h:mm a'}}</td>
                              </tr> 
                            </tbody>

                            <tbody *ngIf="items.repairActionName == 'Programmed By'"> 
                            
                              <tr role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Comments : </td>
                                <td> {{items.textBoxEntry}}
                                </td>
                              </tr>
                              <tr role="row" class="even">
                                <td tabindex="0" class="sorting_1">Status :</td>
                                <td>{{items.status}}</td>
                              </tr> 
                              <tr role="row" class="even">
                                <td tabindex="0" class="sorting_1">Created By :</td>
                                <td>{{items.userName}},&nbsp; {{items.createdAt | date :'M/d/yy, h:mm a'}}</td>
                              </tr> 
                            </tbody>
                            <tbody *ngIf="items.repairActionName == 'Change of Device'"> 
                            
                              <tr role="row" class="odd"> 
                                <td tabindex="0" class="sorting_1">Original Serial No : </td>
                                <td> 
                                  {{items.originalSerialNo}}
                                </td>
                              </tr>
                              <tr role="row" class="odd">
                                <td tabindex="0" class="sorting_1">New Serial No : </td>
                                <td> {{items.newSerialNo}}
                                </td>
                              </tr>
                              <tr role="row" class="even">
                                <td tabindex="0" class="sorting_1">Status :</td>
                                <td>{{items.status}}</td>
                              </tr> 
                              <tr role="row" class="even">
                                <td tabindex="0" class="sorting_1">Created By :</td>
                                <td>{{items.userName}},&nbsp; {{items.createdAt | date :'M/d/yy, h:mm a'}}</td>
                              </tr> 
                            </tbody>
                            
                            <tbody *ngIf="items.repairActionName == 'Received From'"> 
                              <!-- <tr role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Received From : </td>
                                <td> {{items.clientVendorBranchRadioBtn}}
                                </td>
                              </tr> -->
                              <!-- <tr *ngIf="items.clientVendorBranchRadioBtn == 'Client'" role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Patient Name : </td>
                                <td> {{items.patientName}}
                                </td>
                              </tr>
                              <tr *ngIf="items.clientVendorBranchRadioBtn == 'Vendor'" role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Vendor Name : </td>
                                <td> {{items.vendorName}}
                                </td>
                              </tr> -->
                              <!-- <tr role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Receiver Name : </td>
                                <td> {{items.patientName}}
                                </td>
                              </tr> -->
                              <!-- <tr role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Inward-Outward Id : </td>
                                <td class="repairtb bCol"> {{items.branchWiseId}}
                                </td>
                              </tr> -->
                              <tr role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Inward-Outward Id : </td>
                                <td class="repairtb bCol" (click)="toInwardOutward(items.branchWiseId,items.branchName)"> {{items.branchWiseId}} 
                                </td>
                              </tr>
                              <tr role="row" class="even">
                                <td tabindex="0" class="sorting_1">Status :</td>
                                <td>{{items.status}}</td>
                              </tr> 
                              <tr role="row" class="even">
                                <td tabindex="0" class="sorting_1">Created By :</td>
                                <td>{{items.userName}},&nbsp; {{items.createdAt | date :'M/d/yy, h:mm a'}}</td>
                              </tr> 
                            </tbody>

                            <tbody *ngIf="items.repairActionName == 'Sent To'"> 
                              <!-- <tr role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Sent From : </td>
                                <td> {{items.clientVendorBranchRadioBtn}}
                                </td>
                              </tr> -->
                              <!-- <tr *ngIf="items.clientVendorBranchRadioBtn == 'Client'" role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Patient Name : </td>
                                <td> {{items.patientName}}
                                </td>
                              </tr>
                              <tr *ngIf="items.clientVendorBranchRadioBtn == 'Vendor'" role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Vendor Name : </td>
                                <td> {{items.vendorName}}
                                </td>
                              </tr> -->
                              <tr role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Branch Name : </td>
                                <td> {{items.branchName}}
                                </td>
                              </tr>
                              <tr role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Sent To : </td>
                                <td> {{items.companyName}}
                                </td>
                              </tr>
                              
                              <tr role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Comments : </td>
                                <td> {{items.notes}}
                                </td>
                              </tr>
                              <tr role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Inward-Outward Id : </td>
                                <td class="repairtb bCol" (click)="toInwardOutward(items.branchWiseId,items.branchName)"> {{items.branchWiseId}} 
                                </td>
                              </tr>
                              <tr role="row" class="even">
                                <td tabindex="0" class="sorting_1">Status :</td>
                                <td>{{items.status}}</td>
                              </tr> 
                              <tr role="row" class="even">
                                <td tabindex="0" class="sorting_1">Created By :</td>
                                <td>{{items.userName}},&nbsp; {{items.createdAt | date :'M/d/yy, h:mm a'}}</td>
                              </tr> 
                            </tbody>

                            <tbody *ngIf="items.repairActionName == 'Unrepaired Ready'"> 
                              <tr role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Branch Name : </td>
                                <td> {{items.branchName}}
                                </td>
                              </tr>
                              <tr role="row" class="even">
                                <td tabindex="0" class="sorting_1">Status :</td>
                                <td>{{items.status}}</td>
                              </tr> 
                              <!-- <tr role="row" class="even">
                                <td tabindex="0" class="sorting_1">By :</td>
                                <td>{{items.userName}}</td>
                              </tr>  -->
                              <tr role="row" class="even">
                                <td tabindex="0" class="sorting_1">Created By :</td>
                                <td>{{items.userName}},&nbsp; {{items.createdAt | date :'M/d/yy, h:mm a'}}</td>
                              </tr> 
                            </tbody>
                            <tbody *ngIf="items.repairActionName == 'Create Final Bill'"> 
                              <tr role="row" class="odd">
                                <td tabindex="0" class="sorting_1">Bill No : </td> 
                                <td (click)="redirectToBillForm(items.billNo)" class="repairtb bCol">{{items.billNo}}</td> 
                                <!-- <td> {{items.billNo}} 
                                </td> -->
                              </tr>
                              <tr role="row" class="odd"> 
                                <td tabindex="0" class="sorting_1">Service : </td>
                                <td> {{items.ServiceName}}
                                </td>
                              </tr>
                              <tr role="row" class="even">
                                <td tabindex="0" class="sorting_1">Status :</td>
                                <td>{{items.status}}</td>
                              </tr> 
                              <tr role="row" class="even">
                                <td tabindex="0" class="sorting_1">Created By :</td>
                                <td>{{items.userName}},&nbsp; {{items.createdAt | date :'M/d/yy, h:mm a'}}</td>
                              </tr> 
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
    
    
    
            </div> 
    
    
    
    
            <!-- accordian -->
          </div>
        </div>

        <!-- <div class="tab-content p-4 pt-0 mt-0 scroll2 pb-3" id="pills-tabContent">
          <div class="tab-pane fade show active" id="tab1" role="tabpanel" 
          aria-labelledby="pills-home-tab">
             <ul class="timeline history-timeline">
                <li *ngFor="let items of repairActionData | paginate : { id:'repairActionPaginate', 
                itemsPerPage: sizeAction, currentPage: pageAction, totalItems:totalLengthAction }"
                (click)="prepopulationAction(items._id)"
                 class="mb-5">
                   <h5 class="highlighted-color">
                {{items.createdAt | date:'MMM d, y, - h:mm a'}}
                   </h5>
                   <div class="d-flex mb-2">
                      <span class="title-text">User Name :</span>
                      <span>{{items.userName}}</span>
                   </div>
                   <div class="d-flex mb-2">
                      <span class="title-text">Action :</span>
                      <span> {{items.repairActionName}}</span>
                   </div>
                  
                   <div class="d-flex mb-2">
                      <span class="title-text">Status : </span>
                      <span>{{items.status}}</span>
                   </div>
                   <div *ngIf="items.textBoxEntrySignature !== undefined" class="d-flex mb-2">
                    <span class="title-text">Signature : </span>
                    <span><img class="imgSize" src='{{ items.textBoxEntrySignature }}' /></span>
                 </div>
                  
                </li>
              
             </ul>
          </div>
          </div> -->
          <!-- <div class="col-sm-12 pt-0 text-right">
            <pagination-controls id="repairActionPaginate" directionLinks="true" 
            (pageChange)="onPageChangeAction($event)"
            class="float-right pt-3  my-pagination"></pagination-controls>
        
          </div> -->
    </div>
  </div>
  </div>
</div>


        <div class="col-sm-12 pl-0 pr-4">
        <div class="row">
          <div class="col-sm-6 text-start">
          </div>
         
          <div class="col-sm-4 text-start" *ngIf="submitButton">
          </div>
          <div *ngIf="submitButton" class="col-sm-2 mt-2 mb-2 text-start">
            <button type="button" *ngIf="repairComplaintCreate" class="btn btn-primary example-full-width btn-sm" (click)="submitRepairList()">Save
              </button>
          </div>
          <div *ngIf="updateButton" class="col-sm-2 mt-2 mb-2 text-start">
            <button type="button" (click)="printForm();" *ngIf="repairComplaintCreate" class="btn btn-primary example-full-width btn-sm">Generate
              Pdf</button>
          </div>
          <div *ngIf="updateButton" class="col-sm-2 mt-2 mb-2 text-start">
            <button type="button" *ngIf="repairComplaintUpdate" class="btn btn-primary example-full-width  btn-sm" (click)="updateRepairComplaint()">Update</button>
          </div>
          <div class="col-sm-2 mt-2 mb-2 text-start" *ngIf="cancelButton">
            <button class="btn btn-primary btn-sm  example-full-width"  (click)="cancelUpdate()">Cancel</button>
          </div>
        </div>
        </div>

        <div class="col-sm-12 pl-0">
          <div class="row">
            <div class="col-sm-12 mb-2  mr-0 pr-0">
                <div class="card divbody mb-3 borderradius p-2 no-print">
                    <div class="col-sm-12">
                        <div class="row">
                            <div class="col-sm-4  ">
                                <h1 class="titlecolor"> List Of Repair</h1>  
                            </div>
                            <div class="col-sm-5"></div>
                            <div class="search-box col-sm-3 pb-2">
                              <input class="form-control amber-border" type="text" placeholder="Search" 
                              formControlName="searchRepair" aria-label="Search" (keyup)="onKeyUpEvent($event)"
                              >
                              <div class="input-group-append">
                                 <span class="input-group-text amber lighten-3" id="basic-text1"><span class="material-symbols-outlined">search</span></span>
                              </div>
                           </div>
                          
                            <div class="col-sm-12 table-responsive"> 
                              <table id="toRepairList" 
                           class="table table-bordered data-table-custom" style="text-align:center">
              <thead>
                <tr class="text-center">
                  <!-- <th>Repair ID</th> -->
                  <th>Patient Name</th>
                  <th>Date</th>
                  <th>Mobile No.</th>
                  <th *ngIf="userType == 'Super Admin'">Branch Name</th>
                  <th>Left</th>
                  <th>Right</th>
                  <th>Repair Action Status</th>
                  <th>Status</th>
                  
                </tr>
              </thead>
              <tbody>

                <tr *ngFor="let item of listOfRepair | paginate : { id:'repairpaginate', 
        itemsPerPage: size, currentPage: page, totalItems:totalLength } " 
                  class="repairtb" (click)="prepopulation(item._id)" (click)="toRepairForm()">
                  <!-- <td  matTooltip="click to view"
                  >{{item.branchWiseId}}</td> -->
                  <td matTooltip="click to view">{{item.firstName}}  {{item.lastName}}</td>
                  <td matTooltip="click to view">{{item.repairDate | date :'dd/MM/yyyy'}}</td>
                  <td matTooltip="click to view">{{item.mobile1}}</td>
                  <td *ngIf="userType == 'Super Admin'" matTooltip="click to view" 
                  >{{item.branchName}}</td>
                  <td matTooltip="click to view">{{item.leftModel}}-{{ item.leftSrNo}}</td>
                  <td matTooltip="click to view">{{item.rightModel}}-{{item.rightSrNo}}</td>
                  <td matTooltip="click to view">{{item.repairActionLastStatus}}</td>
                  <td *ngIf="item.repairActionStatus == 'Inprocess'"
                   matTooltip="click to view" class="orange">{{item.repairActionStatus}}</td>
                   <td *ngIf="item.repairActionStatus == 'Completed'"
                   matTooltip="click to view" class="green">{{item.repairActionStatus}}</td>
 
                   <td *ngIf="item.repairActionStatus == undefined"
                   matTooltip="click to view">-</td>
               
              
                </tr>
              </tbody>
            </table>
                            </div>
                            <div class="col-sm-12 pt-0 text-right">
                              <pagination-controls id="repairpaginate" directionLinks="true" (pageChange)="onPageChange($event)"
                              class="float-right pt-3  my-pagination"></pagination-controls>
                          
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
</div>




            
        </div>
       
      </div>
  

</div>
  </div>
</form>
</div>







