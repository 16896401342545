<div class="container-fluid bgcolor2" [formGroup]="roleMasterForm" >
  <form [formGroup]="roleMasterForm" autocomplete="off" novalidate>  
  <div class="row">
      <div class="col-sm-1"> 
        
      </div>
      <div class="col-sm-11">
        <div class="row pl-0 ">

          <div class="col-12 pl-0 ">
            <div class="row ">
            <div class="col-sm-9">
            
                <h1 class="pt-2 pl-0" id="toRoleForm">Role</h1>
                </div>
                <div class="col-sm-3 mt-1">
                  <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                    <a (click)="onback()" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">arrow_back</span>Back</a>
                    <a (click)="toRoleList()" class="d-flex align-items-center ms-3 pr-2 mt-2"><span class="material-symbols-outlined pr-2">reorder</span>List</a>
               </div>
          </div>
        </div>
       </div>
       </div>

    
        <div class="row ">
          <div class="col-sm-12 pr-4">
              <div class="row">
                  <div class="card divbody  mb-3 borderradius p-1">
                     
                      <div class="col-sm-12">
                          <div class="row">
                            <div class="col-sm-1 mt-4">
                              <!-- <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Role Id</mat-label>
                                <input matInput formControlName="roleId" readonly  />
                               
                              </mat-form-field> -->
                              <mat-label class="center" formControlName="roleId" ngDefaultControl >{{bId}}</mat-label>
                            </div>
                            <div class="col-sm-3 mt-1">
                              <mat-form-field
                                class="example-full-width"
                                appearance="outline"
                              >
                                <mat-label>Role Name
                                  <!-- <span class="required-asterisk">*</span> -->
                                </mat-label>
                             
                                <input required matInput (input)="roleName()"  formControlName="roleName" placeholder="Enter Role Name">
                                
                              </mat-form-field>
                              <mat-error class="margin_tp"
                                *ngIf="roleMasterForm.controls['roleName'].touched && roleMasterForm.controls['roleName'].errors?.required">
                                This field is mandatory.
                              </mat-error>
                              <mat-error class="margin_tp"
                              *ngIf="roleMasterForm.controls['roleName'].touched && roleMasterForm.controls['roleName'].errors?.pattern">
                                  Please enter valid Role.
                            </mat-error>
                            </div>
                            <div class="col-sm-8"></div>
                      <div class="col-sm-8" *ngIf="updateButton"></div>
                   
                      <div class="col-sm-10" *ngIf="submitButton && roleCreate"></div>
                          <div class="col-sm-2 mb-2 mt-2" *ngIf="submitButton && roleCreate">
                            <button type="button" class="btn btn-sm btn-primary example-full-width" (click)="saveRole()">Save </button>
                        
                          </div>
                          <div  class="col-sm-2 mb-2 mt-2" *ngIf="updateButton && roleUpdate">
                            <button *ngIf="updateButton" type="button" class="btn btn-sm btn-primary example-full-width" (click)="update()">Update </button>
                          </div>
                          <div class="col-sm-2 mb-2 mt-2 " *ngIf="cancelButton">
                            <button class="btn btn-primary btn-sm  example-full-width"  (click)="cancelUpdate()">Cancel </button>
                         </div>
                          </div>
                         
                      </div>
                  </div>
              </div>
          </div>
        </div>
        <div class="col-sm-12 pl-0 pr-2">
          <div class="row">
              <div class="card divbody  borderradius p-3 pb-0">
                      <div class="col-sm-12">
                          <div class="row">
                              <div class="col-sm-9 pl-1 pr-1">
                                  <h1 class="titlecolor"> Role List</h1>  
                              </div>
                              <div class="search-box col-sm-3 pb-2">
                                <input class="form-control amber-border" type="text" placeholder="Search" 
                                formControlName="searchRole" aria-label="Search" (keyup)="onKeyUpEvent($event)"
                                >
                                <div class="input-group-append">
                                   <span class="input-group-text amber lighten-3" id="basic-text1"><span class="material-symbols-outlined">search</span></span>
                                </div>
                             </div>
                         
                                <div class="col-sm-12 table-responsive">
                                  <table id="toRoleList" 
                               class="table table-bordered data-table-custom">

                                      <thead>
                                        <tr class="text-center">
                                          <th>Role Id</th>
                                          <th>Role Name</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr *ngFor="
                                          let item of rolesList
                                          | paginate : { id:'role_pagination', 
                                          itemsPerPage: size, currentPage: page, totalItems:totalLength }
                                        " (click)="prepopulation(item._id)"
                                        (click)="toRoleForm()" 
                                        style="cursor:pointer; text-align: center ">
                                        <td matTooltip="click to view">
                                          {{item.roleId}}
                                        </td>
                                        <td matTooltip="click to view">
                                          {{item.roleName}}
                                        </td>
                                       
                                          <!-- <td class="text-center"> <i matTooltip="View" (click)="prepopulation(item._id)" class="fas fa-eye"></i> </td> -->
                                        
                                      </tbody>
                                      </table>
                              </div>
                              <div class="col-sm-12 pt-0 text-right">
                                <pagination-controls id="role_pagination" directionLinks="true"
                                 (pageChange)="onPageChange($event)" class="float-right pt-3 my-pagination">
                                </pagination-controls>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              
          </div>
       
   
  </div>
    </div>
    </form>
  </div>
   