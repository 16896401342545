<div class="container-fluid bgcolor2" [formGroup]="HAForm">
  <form autocomplete="off" [formGroup]="HAForm" novalidate>
    <div class="row col-sm-12 p-0 m-0 body">
      <div class="col-sm-1">

      </div>
      <div class="col-sm-11 divName">
        <div class="row pl-0 "> 
          <div class="col-sm-9">
            <h1 class="pt-2 pl-1" id="toHAForm">Hearing Aid Trial Form</h1>
          </div>
          <div class="col-sm-3 mt-1">
            <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
              <a (click)="onback()" class="d-flex align-items-center pr-2 mt-2"><span
                  class="material-symbols-outlined pr-2">arrow_back</span>Back</a>
              <a (click)="toHAFormList()" class="d-flex align-items-center ms-3 pr-2 mt-2"><span
                  class="material-symbols-outlined pr-2">reorder</span>List</a> 
              <a *ngIf="isPrepopulate" (click)="printForm()" class="d-flex justify-content-end align-items-end mt-2">
                <span class="material-symbols-outlined pr-1">print</span>Print</a>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 pr-4">
            <div class="row">
              <div class="card divbody col-sm-12 mb-3 borderradius p-2">

                <div class="col-sm-12" id="permit">
                  <div class="row mt-2">
                    <div class="col-sm-12  ">
                      <h5 class="header">Patient Details :</h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-1 mt-2">
                      <!--                          
                                      <mat-form-field *ngIf="userType !== 'Super Admin'" class="example-full-width" appearance="outline">
                                        <mat-label>Hearing Aid Id</mat-label>
                                        <input matInput formControlName="hAId" readonly  />   
                                      </mat-form-field>
                                      <mat-form-field *ngIf="userType == 'Super Admin'" class="example-full-width" appearance="outline">
                                        <mat-label> Hearing Aid Id</mat-label>
                                        <input matInput [value]="superBranchId" readonly  />
                                        </mat-form-field> -->
                      <mat-label class="center mt-3" ngDefaultControl formControlName="branchWiseId"
                        *ngIf="userType !== 'Super Admin'">{{bID}}</mat-label>
                      <mat-label class="center mt-3" ngDefaultControl [value]="superBranchId"
                        *ngIf="userType == 'Super Admin'">{{superBranchId}}</mat-label>
                    </div>
                    <div *ngIf="patientCreate" class="col-sm-2">
                      <div class="row">
                        <div class="col-sm-9 pr-1">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Patient Name</mat-label>

                            <!-- <mat-select required #select2 (click)="getAppPatient();" formControlName="patientName">
                                            <mat-select-filter *ngIf="select2.focused" [placeholder]="'Filter'" [displayMember]="'firstName'"
                                            [array]="patientlist"  (filteredReturn)="filteredPatientList =$event"></mat-select-filter>
                                            <mat-option  *ngFor="let row of filteredPatientList" (click)="selectPatient(row._id)" [value]="row.firstName">{{row.firstName}}</mat-option>
                                          </mat-select> -->
                            <input (click)="openPatientSearchPopUp();" matInput readonly placeholder="Patient Name"
                              formControlName="patientName" />
                          </mat-form-field>
                          <mat-error class="margin_tp"
                            *ngIf="HAForm.controls['patientName'].touched && HAForm.controls['patientName'].errors?.required">
                            This field is mandatory.
                          </mat-error>
                        </div>
                        <div class="col-sm-2 mt-2">
                          <i class="mt-2 no-print" (click)="openPatientPopUp();" matTooltip="Add New Patient"
                            class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"> </i>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="!patientCreate" class="col-sm-3">
                      <div class="row">
                        <div class="col-sm-12">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Patient Name</mat-label>

                            <!-- <mat-select #select2 (click)="getAppPatient();" formControlName="patientName">
                                            <mat-select-filter *ngIf="select2.focused" [placeholder]="'Filter'" [displayMember]="'firstName'"
                                            [array]="patientlist"  (filteredReturn)="filteredPatientList =$event"></mat-select-filter>
                                            <mat-option  *ngFor="let row of filteredPatientList" (click)="selectPatient(row._id)" [value]="row.firstName">{{row.firstName}}</mat-option>
                                          </mat-select> -->
                            <input (click)="openPatientSearchPopUp();" matInput readonly placeholder="Patient Name"
                              formControlName="patientName" />
                          </mat-form-field>
                          <mat-error class="margin_tp"
                            *ngIf="HAForm.controls['patientName'].touched && HAForm.controls['patientName'].errors?.required">
                            This field is mandatory.
                          </mat-error>
                          <mat-error class="margin_tp"
                            *ngIf="HAForm.controls['patientName'].touched && HAForm.controls['patientName'].errors?.pattern">
                            Please enter valid patient name.
                          </mat-error>
                        </div>

                      </div>
                    </div>

                    <div class="col-sm-3">
                      <div class="row">
                        <div class="col-sm-10 mr-0 pr-0">
                          <mat-form-field class="example-full-width mr-0 pr-0" appearance="outline">
                            <mat-label>Phone Number
                            </mat-label>
                            <input matInput placeholder="Phone Number" maxlength="10" formControlName="phoneNo" />
                          </mat-form-field>
                          <mat-error class="margin_tp"
                            *ngIf="HAForm.controls['phoneNo'].touched && HAForm.controls['phoneNo'].errors?.pattern">
                            Please enter valid Phone Number.
                          </mat-error>
                        </div>
                        <div class="col-sm-2 wApp no-print ml-0 pl-0">
                          <i class="bi bi-whatsapp whatsapp-icon wApp fa-lg ml-1 pl-0" (click)="WhatsApp();"
                            matTooltip="WhatsApp Integration"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Address
                        </mat-label>
                        <input matInput placeholder="Address" readonly formControlName="address" />
                      </mat-form-field>
                    </div>
                 
                    <div class="row col-sm-12 mt-2">
                      <div class="col-sm-12 ">
                        <h5 class="header">Device Details :</h5>
                      </div>
                    </div>
                    <!-- <div class="col-sm-3">
                      <div class="row">
                        <div class="col-sm-10 pr-1">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Device Name</mat-label>

                            <mat-select #select1 formControlName="deviceName">
                              <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'"
                                [displayMember]="'model'" [array]="deviceList"
                                (filteredReturn)="filteredDeviceList =$event"></mat-select-filter>
                              <mat-option *ngFor="let row of filteredDeviceList" (click)="selectDevice(row._id)"
                                [value]="row.model">{{row.model}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="col-sm-2 mt-2 ">
                          <i class="mt-2 no-print" (click)="openDevicePopUp();" matTooltip="Add Device"
                            class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"> </i>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="row">
                        <div class="col-sm-10 pr-1">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Left Serial Number
                            </mat-label>
                           <mat-select formControlName="LeftSerialNo">
                              <mat-option *ngFor="let item of leftSerialNoList" [value]="item.serialNumber"
                                (click)="serialClick(item.serialNumber)">{{ item.serialNumber }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field> 
                        </div>
                      
                      </div>
                    </div>
                
                    <div class="col-sm-3">
                      <div class="row">
                        <div class="col-sm-10 pr-1">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Right Serial Number
                            </mat-label>
                            <mat-select formControlName="RightSerialNo">
                              <mat-option *ngFor="let item of rightSerialNoList" [value]="item.serialNumber"
                                (click)="rightserialClick(item.serialNumber)">{{ item.serialNumber }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>

                        </div>
                        
                      </div>
                    </div>

                    <div class="col-sm-3">
                      <div class="row">
                        <div class="col-sm-10 pr-1">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Charger Serial Number
                            </mat-label>
                            <input matInput placeholder="Charger Serial Number" formControlName="ChargerSerialNo"
                              readonly />
                          </mat-form-field>

                        </div>
                        <div class="col-sm-2 mt-2 ">
                          <i class="mt-2 no-print" (click)="openPopUpCharger();" matTooltip="Add Charger Serial No"
                            class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"> </i>
                        </div>
                      </div>
                    </div> -->
                    <div class="col-sm-12">
                      <div class="white_box QA_section card_height_100">
                          <div class="table-responsive">
                            <table id="toServiceList" 
                            class="table table-bordered data-table-custom">
                         
                        <thead>
                          <tr class="text-center" >
                            <th>Device Name</th>
                            <th>Left Serial Number</th>
                            <th>Right Serial Number</th>
                            <th>Charger Serial Number</th>
                            <th>Action</th>
                           
                          </tr>
                        </thead>
                          <tbody formArrayName="deviceInfo">
                 
                            <tr [formGroupName]="i"
                            *ngFor="let row of HAForm.get('deviceInfo')['controls']; let i = index; let last = last"
                              role="row"
                              class="odd "
                            >
                              <td class="tdW3 pl-3 pr-3">
                                
                                <div class="row">
                                  <div class="col-sm-10 pr-1">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                      <mat-label>Device Name</mat-label>
          
                                      <mat-select #select1 formControlName="deviceName">
                                        <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'"
                                          [displayMember]="'model'" [array]="deviceList"
                                          (filteredReturn)="filteredDeviceList =$event"></mat-select-filter>
                                        <mat-option *ngFor="let row of filteredDeviceList" (click)="selectDevice(row._id,i)"
                                          [value]="row.model">{{row.model}}</mat-option>
                                      </mat-select>
                                    </mat-form-field>
                                  </div>
                                  <div class="col-sm-2 mt-2 ">
                                    <i class="mt-2 no-print" (click)="openDevicePopUp(i);" matTooltip="Add Device"
                                      class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"> </i>
                                  </div>
                                </div>
                               
                              </td>
                              <td class="tdW3  pl-3 pr-3">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Left Serial Number
                                  </mat-label>
                                 <mat-select formControlName="LeftSerialNo">
                                    <mat-option *ngFor="let item of leftSerialNoList[i]" [value]="item.serialNumber"
                                      (click)="serialClick(item.serialNumber,i)">{{ item.serialNumber }}
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field> 
                         
                        </td>
                              <td class="tdW3  pl-3 pr-3"> 
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Right Serial Number
                                  </mat-label>
                                  <mat-select formControlName="RightSerialNo">
                                    <mat-option *ngFor="let item of rightSerialNoList[i]" [value]="item.serialNumber"
                                      (click)="rightserialClick(item.serialNumber,i)">{{ item.serialNumber }}
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </td>
                           
                      <td  class="tdW2  pl-3 pr-3"> 
                        <div class="row">
                          <div class="col-sm-10 pr-1">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Charger Serial Number
                              </mat-label>
                              <input matInput placeholder="Charger Serial Number" formControlName="ChargerSerialNo"
                                readonly />
                            </mat-form-field>
  
                          </div>
                          <div class="col-sm-2 mt-2 ">
                            <i class="mt-2 no-print" (click)="openPopUpCharger(i);" matTooltip="Add Charger Serial No"
                              class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"> </i>
                          </div>
                        </div>
              </td>
              
          <td>  
              <button mat-button mat-icon-button *ngIf="last" (click)="addNew(i);" [disabled]="HAForm.controls.deviceInfo.invalid">
               <img matTooltip="Add New Device" class="mt-1" width="25" src="../../assets/add.png"> 
              </button>

              <button mat-button mat-icon-button (click)="deleteRow(i);" [disabled]="HAForm.get('deviceInfo')['controls']['length']===1">
                <img matTooltip="Remove Device" class="mt-1" width="25" src="../../assets/dustbin.png"> 
            
              </button>
          </td>
         
                            </tr>
                          </tbody>
                        </table>
                        <hr>
                  
          </div>     
                            </div>
                          
                         
                          
                            </div>

                    <div class="row col-sm-12 mt-2">
                      <div class="col-sm-12  ">
                        <h5 class="header mt-1">ID Details :</h5>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>ID Proof
                        </mat-label>

                        <mat-select formControlName="iDProof">
                          <mat-option value="Addhar Card">Addhar Card</mat-option>
                          <mat-option value="Pan Card">Pan Card</mat-option>
                          <mat-option value="Driving Licence">Driving Licence</mat-option>
                          <mat-option value="Other">Other</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-sm-2 mt-3" *ngIf="hide == false">
 <input #fileUpload type="file" style="display: none;" (change)="onChange($event)" />
                      <button type="button" class="btn btn-light shadow btn-sm m-0 mb-1 example-full-width"
                       matTooltip="Upload JPG,PNG,CSV or PDF image.300 x 300 required." 
                       (click)="fileUpload.click()" ><i class="ai-refresh-cw me-2" ></i>Upload Photo 1</button>
                  </div>
                  <div class="col-sm-2 mt-3" *ngIf="hide == true">
                    <div class="row ">
                      <a class="col-sm-10" (click)="showbase64(patientImage)">
                        <label class="blue_col mt-0 pt-0">{{fileName}}</label></a>
                        <i class="fa fa-times col-sm-2 pointer" (click)="remove()" 
                        aria-hidden="true" style="cursor:pointer;" ></i>
 
                    </div>
                  </div>
                  <div class="col-sm-2 mt-3" *ngIf="hide2 == false">
                    <input #fileUpload type="file" style="display: none;" (change)="onChangeFile($event)" />
                                         <button type="button" class="btn btn-light shadow btn-sm m-0 mb-1 example-full-width"
                                          matTooltip="Upload JPG,PNG,CSV or PDF image.300 x 300 required." 
                                          (click)="fileUpload.click()" ><i class="ai-refresh-cw me-2" ></i>Upload Photo 2</button>
                                     </div>
                                     <div class="col-sm-2 mt-3" *ngIf="hide2 == true">
                                       <div class="row ">
                                         <a class="col-sm-10" (click)="showbase64(patientImage2)">
                                           <label class="blue_col mt-0 pt-0">{{fileName2}}</label></a>
                                           <i class="fa fa-times col-sm-2 pointer" (click)="remove2()" 
                                           aria-hidden="true" style="cursor:pointer;" ></i>
                    
                                       </div>
                                     </div>
                    <!-- <div class="col-sm-2 mt-3 no-print">
                      <input type="file" class="example-full-width " #fileUpload name="Upload File" id="txtFileUpload"
                        (change)="onChange($event);" />
                    </div>
               
                    <div class="col-sm-2 mt-3 no-print">
                      <input type="file" class="example-full-width " #fileUpload name="Upload File" id="txtFileUpload"
                        (change)="onChangeFile($event);" />
                    </div> -->
            
                    <div class="row col-sm-12 mt-2">
                      <div class="col-sm-12  ">
                        <h5 class="header mt-1">Other :</h5>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Temp HA Given</mat-label>

                        <mat-select formControlName="tempHagiven">
                          <mat-option (click)="tempHagivenClick()" value="Yes">Yes</mat-option>
                          <mat-option (click)="tempHagivenClick()" value="No">No</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div *ngIf="isTempGiven" class="col-sm-3">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Serial No.
                        </mat-label>
                        <input matInput placeholder="Serial Number" formControlName="tempHagivenSerialNo" />
                      </mat-form-field>
                    </div>
                    <div class="col-sm-3">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Till Date
                        </mat-label>
                        <input formControlName="tillDate" matInput [matDatepicker]="tillDate" id="tillDate"
                          [max]="maxDate" placeholder="DD/MM/YYYY" (dateChange)="ageCalculate()">

                        <mat-datepicker-toggle matSuffix [for]="tillDate">
                        </mat-datepicker-toggle>
                        <mat-datepicker #tillDate></mat-datepicker>

                      </mat-form-field>
                      <mat-error class="margin_tp"
                        *ngIf="HAForm.controls['tillDate'].touched && HAForm.controls['tillDate'].errors?.required">
                        This field is mandatory.
                      </mat-error>
                      <!-- <mat-error class="margin_tp" *ngIf="HAForm.controls['tillDate'].hasError('matDatepickerMax')">
                        Please enter Date less than or equal to {{maxDateStr}}.
                      </mat-error>
                      <mat-error class="margin_tp" *ngIf="HAForm.controls['tillDate'].hasError('matDatepickerMin')">
                        Please enter Date greater than or equal to {{minDateStr}}.
                      </mat-error> -->
                    </div>
                    <div class="col-sm-3">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>No.Of Days For Trial
                        </mat-label>
                        <input readonly matInput placeholder="Days" (input)="dayChange();" formControlName="noOfDays" />
                      </mat-form-field>



                      <!-- <mat-error class="margin_tp"
                                      *ngIf="HAForm.controls['noOfDays'].touched && HAForm.controls['noOfDays'].errors?.minlength">
                                      Enter minimum 7 days.
                                    </mat-error>
                                    <mat-error class="margin_tp"
                                      *ngIf="HAForm.controls['noOfDays'].touched && HAForm.controls['noOfDays'].errors?.maxlength">
                                      Enter maximum 365 days.
                                    </mat-error> -->
                    </div>
                    <div class="col-sm-3">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Advance Rs.
                        </mat-label>
                        <input required matInput placeholder="Rs." formControlName="advanceRs" maxlength="7" />
                      </mat-form-field>
                      <mat-error class="margin_tp"
                        *ngIf="HAForm.controls['advanceRs'].touched && HAForm.controls['advanceRs'].errors?.required">
                        This field is mandatory.
                      </mat-error>
                      <mat-error class="margin_tp"
                        *ngIf="HAForm.controls['advanceRs'].touched && HAForm.controls['advanceRs'].errors?.pattern">
                        Please enter valid advance rs.
                      </mat-error>
                    </div> 
                    <div class="col-sm-3">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label> Status</mat-label>

                        <mat-select formControlName="status">
                          <mat-option value="Issued">Issued</mat-option>
                          <mat-option value="Return">Return</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <!-- <div *ngIf="billingCreate" class="col-sm-3">
                      <div class="row">
                        <div class="col-sm-10 pr-1">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Billing Company
                            </mat-label>
                            <mat-select #select1  formControlName="billingRefId">
                              <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'"
                                [displayMember]="'companyName'" [array]="billingCompanyList"
                                (filteredReturn)="filteredListBillingCompany =$event"></mat-select-filter>
                              <mat-option *ngFor="let item of filteredListBillingCompany" [value]="item._id"
                              >
                                {{ item.companyName }}</mat-option>
                            </mat-select>
  
                          </mat-form-field>
                          <mat-error class="margin_tp"
                            *ngIf="HAForm.controls['billingRefId'].touched && HAForm.controls['billingRefId'].errors?.required">
                            This field is mandatory.
                          </mat-error>
                        </div>
                        <div class="col-sm-2 mt-2 no-print">
                          <i (click)="openBillingCompPopUp();" matTooltip="Add New Billing Company" class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"> </i>
  
                        </div>
                      </div>
                    </div>
                    <div *ngIf="!billingCreate" class="col-sm-3">
                      <div class="row">
                        <div class="col-sm-12 pr-1">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Billing Company
                             </mat-label>
                            <mat-select #select1 formControlName="billingRefId">
                              <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'"
                                [displayMember]="'companyName'" [array]="billingCompanyList"
                                (filteredReturn)="filteredListBillingCompany =$event"></mat-select-filter>
                              <mat-option *ngFor="let item of filteredListBillingCompany" [value]="item._id"
                          >
                                {{ item.companyName }}</mat-option>
                            </mat-select>
  
                          </mat-form-field>
                          <mat-error class="margin_tp"
                            *ngIf="HAForm.controls['billingRefId'].touched && HAForm.controls['billingRefId'].errors?.required">
                            This field is mandatory.
                          </mat-error>
                        </div>
  
                      </div>
                    </div> -->
                    <div class="col-sm-6">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Remark</mat-label>

                        <textarea matInput maxlength="1000"
                         formControlName="remark" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                          cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5"></textarea>
                      </mat-form-field>
                    </div>
                    <div class="col-sm-12 pl-0 ml-0" *ngIf="isSuperAdmin">
                      <div class="row col-sm-12 mt-2">
                        <div class="col-sm-12  ">
                          <h5 class="header mt-1">Select Branch :</h5>
                        </div>
                      </div>
                      <div class="row col-sm-3 ">
                        <div class="col-sm-10 ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Select Branch
                              <!-- <span class="required-asterisk">*</span> -->
                            </mat-label>
                            <mat-select #select3 formControlName="branchRefId">
                              <mat-select-filter *ngIf="select3.focused" [placeholder]="'Filter'"
                                [displayMember]="'branchName'" [array]="branchList"
                                (filteredReturn)="filteredListBranch =$event"></mat-select-filter>
                              <mat-option *ngFor="let item of filteredListBranch" [value]="item._id"
                                (click)="branchClick(item._id,item.branchName);">
                                {{ item.branchName }}</mat-option>
                            </mat-select>

                          </mat-form-field>
                          <mat-error class="margin_tp"
                            *ngIf="HAForm.controls['branchRefId'].touched && HAForm.controls['branchRefId'].errors?.required">
                            This field is mandatory.
                          </mat-error>
                        </div>
                        <div class="col-sm-2 mt-2 ">
                          <i (click)="openBranchPopUp();" class="no-print" matTooltip="Add New Branch"
                            class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"> </i>
                        </div>
                      </div> 
                    </div>
                    <div class="row col-sm-12 mt-2">
                      <div class="col-sm-12  ">
                        <h5 class="header mt-1">Add Signature :</h5>
                      </div>
                    </div> 

                    <div class="col-sm-3 pr-1">
                      <canvas (click)="canvasClick()" #canvas (touchstart)="startDrawing($event)"
                        (touchmove)="moved($event)"></canvas>
                    </div>
                    <div *ngIf="signPadShow" class="col-sm-2"> 
                      <div  class="row col-sm-12">
                        <div class="col-sm-12">
                          <button class="btn btn-primary btn-sm mt-2 example-full-width" type="button"
                            (click)="clearPad()">Clear Signature</button>
                        </div>
                        <div class="col-sm-12">
                          <button class="btn btn-primary btn-sm mt-2 example-full-width" type="button" color="secondary"
                            (click)="savePad()">Save Signature</button>
                        </div>
                      </div>

                    </div>

                    <div class="col-sm-3">
                      <img src='{{ signatureImg }}' />
                    </div>
                    <div *ngIf="!signPadShow" class="col-sm-2"> </div>
                    <div class="col-sm-3" *ngIf="isSuperAdmin"></div>
                    <div class="col-sm-6" *ngIf="!isSuperAdmin"></div>
                    <div *ngIf="ifPrint == 'true'" class="col-sm-2 marginT">
                      <h5 class="justify-content-end labelStyle">Sign Of Patient : </h5>
                    </div>
                    <div *ngIf="ifPrint == 'true'" class="col-sm-10"></div>
                    <div *ngIf="ifPrint == 'true'" class="col-sm-2">
                      <h5 class="justify-content-end labelStyle">Date Time : </h5>
                    </div>
                    <div *ngIf="ifPrint == 'true'" class="col-sm-10"></div>
                    <div *ngIf="ifPrint == 'true'" class="col-sm-12 marginT">
                      <h5 class="justify-content-end labelStyle">Terms :
                        If Hearing Aid is not returned in the morning time period will be considered as sold
                      </h5>
                    </div>
                    <div *ngIf="ifPrint == 'true'" class="col-sm-12">
                      <h5 class="justify-content-end labelStyle">अटी :
                        जर श्रवणयंत्र सकाळच्या वेळेत परत न केल्यास ते विकले गेले असे मानले जाईल
                      </h5>
                    </div>
                    <div class="col-sm-6  mb-2 mt-2 " *ngIf="updateButton"> </div>
                    <div class="col-sm-2  mb-2 mt-2 no-print" *ngIf="updateButton && haFormUpdate">
                      <button class="btn btn-primary btn-sm example-full-width" type="button"
                        (click)="printForm();">Genrate pdf </button>

                    </div>
                    <div class="col-sm-2 offset-sm-10 mb-2 mt-2 no-print" *ngIf="submitButton && haFormCreate">
                      <button class="btn btn-primary btn-sm example-full-width" type="button" (click)="saveData();">Save
                      </button>

                    </div>
                    <div class="col-sm-2 mb-2 mt-2 no-print" *ngIf="updateButton && haFormUpdate">
                      <button class="btn btn-primary btn-sm example-full-width" type="button"
                        (click)="updateHADetails()">Update </button>
                    </div>
                    <div class="col-sm-2 mb-2 mt-2 no-print" *ngIf="updateButton">
                      <button class="btn btn-primary btn-sm example-full-width" (click)="cancelUpdate()"
                        type="reset">Cancel </button>

                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 pl-0 pr-2 no-print">
          <div class="row">
            <div class="card divbody borderradius p-3 pb-0">
              <div class="col-sm-12">
                <div class="row">
                  <div class="col-sm-9 ">
                    <h2 class="titlecolor"> Hearing Aid Trial Form List</h2>
                  </div>
                  <div class="search-box col-sm-3 pb-2">
                    <input class="form-control amber-border" type="text" placeholder="Search" formControlName="searchHA"
                      aria-label="Search" (keyup)="onKeyUpEvent($event)">
                    <div class="input-group-append">
                      <span class="input-group-text amber lighten-3" id="basic-text1"><span
                          class="material-symbols-outlined">search</span></span>
                    </div>
                  </div>

                  <div class="col-sm-12 table-responsive">
                    <table id="toHAFormList" class="table table-bordered data-table-custom">
                      <thead>
                        <tr class="text-center">
                          <th>ID</th>
                          <th *ngIf="userType == 'Super Admin'">Branch Name</th>
                          <th>Date</th>
                          <th>No.Of Days For Trial</th>
                          <!-- <th>Return Date </th> -->
                          <th>Patient Name</th>
                          <th>Device Name</th>
                          <th>Issued Date</th>
                          <th>Return Date</th>
                          <th>Status</th>
                        </tr>
                      </thead> 
                      <tbody>
                        <tr *ngFor="let item of listOfHAForm | paginate : { id:'ha_pagination', 
                                              itemsPerPage: size, currentPage: page, totalItems:totalLength }"
                          (click)="prepopulation(item._id)" (click)="toHAForm()"
                          style="cursor:pointer; text-align: center ">

                          <td matTooltip="click to view">{{item.hAId}}</td>
                          <td *ngIf="userType == 'Super Admin'" matTooltip="click to view">{{item.branchName}}</td>
                          <td matTooltip="click to view">{{item.createdAt | date :'d/M/yy'}}</td>
                          <td matTooltip="click to view">{{item.noOfDays}}</td>
                          <!-- <td matTooltip="click to view">{{item.date | date :'d/M/yy'}}</td> -->
                          <td matTooltip="click to view">{{item.patientName}}</td>
                          <td matTooltip="click to view">{{item.deviceName}}</td>
                          <td matTooltip="click to view">{{item.createdAt | date :'d/M/yy'}}</td>

                          <td matTooltip="Click To View">
                            <label *ngIf="item.status == 'Return'; else elseBlock">
                              {{item.updatedAt | date :'d/M/yy'}}
                            </label>
                            <ng-template #elseBlock>
                              <label>-</label>
                            </ng-template>
                          </td>

                          <td matTooltip="Click To View">
                            <label *ngIf="item.status !== '' && item.status !== null
                                                      && item.status !== undefined; else elseBlock">
                              {{item.status}}
                            </label>
                            <ng-template #elseBlock>
                              <label>-</label>
                            </ng-template>
                          </td>


                        </tr>




                      </tbody>
                    </table>
                  </div>
                  <div class="col-sm-12 pt-0 text-right">
                    <pagination-controls id="ha_pagination" directionLinks="true" (pageChange)="onPageChange($event)"
                      class="float-right pagination my-pagination"></pagination-controls>

                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div id="myModal" class="modal">
          <span (click)="closeClick()" class="close">&times;</span>
          <img class="modal-content" [src]="modalImg" id="img01">
          <div id="caption"></div>
        </div>
        <div id="myModal2" class="modal">
          <span (click)="closeClick2()" class="close">&times;</span>
          <img class="modal-content" [src]="modalImg2" id="img02">
          <div id="caption2"></div>
        </div>

      </div>
    </div>
  </form>
</div>