<div class="container-fluid col-sm-12 bgcolor2" [formGroup]="modeOfPaymentForm">
    <div class="row">
        <div class="col-sm-1"></div>
        <div class="col-sm-11">
          <div class="row pl-0 ">
            <div class="col-12 pl-0 ">
              <div class="row ">
              <div class="col-sm-9">
                  <h1 class="pt-2 pl-0" id="tomodeOfPaymentForm">Cheque Details</h1>
                  </div>
                  <div class="col-sm-3 mt-1">
                    <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                      <a (click)="onback()" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">arrow_back</span>Back</a>
                     
                 </div>
            </div>
          </div>
         </div>
         </div>  
                    <!-- <div class="row ">
                      <div class="col-sm-11">
                        <div class="row ">
                          
                        <span><img src="../../assets/cheque.png" width="35rem"> </span> 
                        <span> <h1 class="fass  ml-3 pt-1" id="tomodeOfPaymentForm">Cheque Details</h1></span>
                        </div>
                        </div>
                        <div class="col-sm-1 ">
                          <div class="row justify-content-end mr-1 ">
                            <img matTooltip="Back" class="mb-2 pb-2" width=35rem src="../../assets/back.png" (click)="onback()">
                          </div>
                        </div>
                        </div> -->
                   
                        <div class="col-sm-12 pr-4">
                                <div class="row">
                                   
                               
                                    <div class="card divbody boxsssss mb-3 borderradius p-2">
                                        <div class="row">
                                      
                                          <div class="col-sm-3 mb-2">
                                            <mat-form-field class="example-full-width" appearance="outline">
                                              <mat-label>Bill No</mat-label>
                                              <input matInput placeholder="Bill No" formControlName="billNo" readonly/>
                                            </mat-form-field>
                                          </div>
                                          <div class="col-sm-3 mb-2">
                                            <mat-form-field class="example-full-width" appearance="outline">
                                              <mat-label>Date</mat-label>
                                              <input matInput placeholder="Payment Date" formControlName="date" [value]="paymentDate | date:'dd/MM/yyyy'" readonly/>
                                            </mat-form-field>
                                          </div>
                                        
                                          <div class="col-sm-3 mb-2">
                                            <mat-form-field class="example-full-width" appearance="outline">
                                              <mat-label>Status</mat-label>
                                              <input matInput placeholder="Status" formControlName="chequeStatus" readonly/>
                                            </mat-form-field>
                                          </div>
                                    <div class="col-sm-12">
                                        <table id="toBillingLst"
                                        class="table table-hover col-sm-12 table-bordered" 
                                    >
                                           <thead> 
                                               <tr class="text-center">
                                                   <th>Date</th>
                                                    <th>Cheque No.</th>
                                                    <th>Bank Name</th>
                                                   <th>Amount</th>
                                                   <th>Photo</th>
                                                   <th>Status</th>
                                                   <!-- <th>Action</th> -->
                                               </tr>
                                           </thead> 
                                           <tbody formArrayName="chequeArray">
                        
                                             <tr [formGroupName]="i"
                                             *ngFor="let row of modeOfPaymentForm.get('chequeArray')['controls']; let i = index; let last = last"
                                               role="row"
                                               class="odd "
                                             >
                                               <td>
                                                 <!-- <label class="labelStyle justify-content-right mt-3"> {{currentdate | date: 'dd/mm/yyyy'}}</label> -->
                                                 <mat-form-field class="example-full-width" appearance="outline">
                                                  <mat-label>Cheque Date
                                                    <!-- <span class="required-asterisk">*</span> -->
                                                  </mat-label>
                                                  <input formControlName="chequeDate" readonly matInput [matDatepicker]="chequeDate" id="chequeDate" 
                                                     placeholder="DD/MM/YYYY" >
                           
                                                  <mat-datepicker-toggle matSuffix [for]="chequeDate" disabled>
                                                  </mat-datepicker-toggle>
                                                  <mat-datepicker #chequeDate></mat-datepicker>
                                                
                                                </mat-form-field>
                                                <!-- <mat-error class="margin_tp"
                                                *ngIf="row.controls.chequeDate.touched && row.controls.chequeDate.errors?.required">
                                                   This field is mandatory.
                                              </mat-error> -->
                                               </td>
                                               <td>
                                                   <mat-form-field class="example-full-width lable_style2  widthh" appearance="outline">
                                                       <mat-label class="lable_style2"> Cheque No</mat-label>
                                                   <input placeholder="Cheque No" formControlName="chequeNo" type="text" readonly
                                                   matInput maxlength="10" />
                                                 
                                                 </mat-form-field>
                                                 <!-- <p style="color:red;" *ngIf="findDuplicate(row.controls.chequeNo.value,i) && i > 0">
                                                  Duplicate Cheque No.
                                                </p>
                                                <mat-error class="margin_tp"
                                                *ngIf="row.controls.chequeNo.touched && row.controls.chequeNo.errors?.required">
                                                   This field is mandatory.
                                              </mat-error>
                                              <mat-error class="margin_tp"
                                      *ngIf="row.controls.chequeNo.touched && row.controls.chequeNo.errors?.pattern">
                                      Please enter numeric value.
                                    </mat-error> -->
                                               </td>
                                               <td>
                                                 <mat-form-field class="example-full-width lable_style2 widthh" appearance="outline">
                                                     <mat-label class="lable_style2">Bank Name</mat-label>
                                                 <input placeholder="Bank Name" formControlName="bankName" type="text" readonly
                                                 matInput />
                                               
                                               </mat-form-field>
                                               <!-- <mat-error class="margin_tp"
                                                *ngIf="row.controls.bankName.touched && row.controls.bankName.errors?.required">
                                                   This field is mandatory.
                                              </mat-error>
                                              <mat-error class="margin_tp"
                                      *ngIf="row.controls.bankName.touched && row.controls.bankName.errors?.pattern">
                                      Please enter characters.
                                    </mat-error> -->
                                             </td>
                                             <td>
                                               <mat-form-field class="example-full-width lable_style2 widthh" appearance="outline">
                                                   <mat-label class="lable_style2">Amount</mat-label>
                                               <input placeholder="Amount" formControlName="amount" type="text" readonly
                                               matInput />
                                             
                                             </mat-form-field>
                                             <mat-error class="margin_tp"
                                                *ngIf="row.controls.amount.touched && row.controls.amount.errors?.required">
                                                   This field is mandatory.
                                              </mat-error>
                                              <!-- <mat-error class="margin_tp"
                                      *ngIf="row.controls.amount.touched && row.controls.amount.errors?.pattern">
                                      Please enter numeric value.
                                    </mat-error> -->
                                            </td>
                                            <td>  
                                              
                                                <img *ngIf="!chequeImage" class=" img2 frame example-full-width widthh" id="myImg"
                                                (click)="imgClick()"  src="../../assets/user2.png" alt="Amanda Wilson" width="400rem"><!--rounded-circle-->
                                                <img *ngIf="chequeImage" class=" img2 frame example-full-width widthh" id="myImg"
                                                (click)="imgClick()"  [src]="chequeImage" alt="patient photo" width="400rem"><!--rounded-circle-->
                                              
                                                <div id="myModal" class="modal">
                                                  <span (click)="close()" class="close">&times;</span>
                                                  <img *ngIf="!chequeImage" class="modal-content" id="img01" src="../../assets/user2.png" alt="Amanda Wilson" width="400rem">
                                                  <img *ngIf="chequeImage" class="modal-content" id="img01" [src]="chequeImage" alt="patient photo" width="400rem">
                                                
                                                  <div id="caption"></div>
                                                  </div>

                                                <!-- <div id="myModal" class="modal">
                                                  <div class="modal-content">
                                                    <div class="row p-0">
                                                      
                                                    <div class="col-sm-11 p-0">
                                                      <img *ngIf="!chequeImage" class="modal-content" id="img01" src="../../assets/user2.png" alt="Amanda Wilson" width="400rem">
                                                      <img *ngIf="chequeImage" class="modal-content" id="img01" [src]="chequeImage" alt="patient photo" width="400rem">
                                                    
                                                    </div>
                                                    <div class="col-sm-1">
                                                      <span class="close" (click)="close();">&times;</span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                
                                                </div> -->
                                           </td>
                                            <td>
                                                    <mat-form-field class="example-full-width lable_style2 widthh1" appearance="outline">
                                                        <mat-label>Status</mat-label>
                                                  
                                                        <mat-select placeholder="Select" class="colorblack" formControlName="chequeStatus">
                                                           
                                                                  <mat-option value="Received" (click)="onSelectStatus($event,i)">Received</mat-option>
                                                                  <mat-option value="postdated" (click)="onSelectStatus($event,i)">Received Post-Dated</mat-option>
                                                                 
                                                                  <mat-option value="bounce" (click)="onSelectStatus($event,i)">Dishonoured</mat-option>
                                                                  <mat-option value="Deposited" (click)="onSelectStatus($event,i)">Deposited</mat-option>
                                                                  <mat-option value="Clear" (click)="onSelectStatus($event,i)">Cleared</mat-option>
                                                                  <mat-option value="Cancelled" (click)="onSelectStatus($event,i)">Cancelled</mat-option>
                                                                  <mat-option value="Returned" (click)="onSelectStatus($event,i)">Returned</mat-option>
 

                                                          <!-- <mat-option value="Deposited" (click)="onSelectStatus($event,i)">Deposited</mat-option>
                                                          <mat-option value="Clear" (click)="onSelectStatus($event,i)">Clear</mat-option>
                                                          <mat-option value="bounce" (click)="onSelectStatus($event,i)">Bounce</mat-option>
                                                          <mat-option value="postdated" (click)="onSelectStatus($event,i)">Postdated</mat-option> -->
                                                        </mat-select>
                                                      </mat-form-field>
                                                      <mat-error class="margin_tp"
                                                *ngIf="row.controls.chequeStatus.touched && row.controls.chequeStatus.errors?.required">
                                                   This field is mandatory.
                                              </mat-error>
                                             </td>
                           <!-- <td>  
                         <div class="row">
                           <div class="col-sm-5 pl-0">
                             <button mat-button mat-icon-button *ngIf="last" class="pl-0" (click)="addRow(i);" 
                               [disabled]="modeOfPaymentForm.controls.chequeArray.invalid || modeOfPaymentForm.disabled">
                                 <img matTooltip="Add" class="pl-0" width="25" src="../../assets/add.png"> 
                               </button>
                             </div>
                             <div class="col-sm-5 pl-0">
                               <button mat-button mat-icon-button (click)="deleteRow(i);" [disabled]="modeOfPaymentForm.get('chequeArray')['controls']['length']===1 || modeOfPaymentForm.disabled">
                                 <img matTooltip="Remove" class="mt-1" width="25" src="../../assets/dustbin.png"> 
                               </button>
                             </div>
                         </div>
            
                           </td> -->
                         
                                             </tr>
                                           </tbody>
                                        
                                       </table> 
                                        </div>
                                    </div>
                                    <!-- <div class="col-sm-12 pt-0 text-right">
                                        <pagination-controls id="typeMaster" directionLinks="true"
                              (pageChange)="onPageChange($event)" class="float-right pagination my-pagination labelStyle"></pagination-controls>
                                    
                                </div> -->
                                <div class="row">
                                <div class="col-sm-8 ">
                                </div>
                              
                        <div  class="col-sm-2 mb-2 mt-2">
                          <button *ngIf="submitButton" class="btn btn-primary btn-sm example-full-width" type="button" (click)="submitChequeDetails()" [disabled]="disableSubmit">Save</button>
                        </div>
                        <div class="col-sm-2 mb-2 mt-2">
                          <button class="btn btn-primary btn-sm example-full-width" type="button" (click)="cancelSumbit()">Cancel</button>
                        </div>
                        </div>
                           </div>
                         
                </div>
            </div>

        </div>
    </div>
</div>