<div class="container-fluid bgcolor2" [formGroup]="purchaseReturnForm"> 
    <form autocomplete="off" [formGroup]="purchaseReturnForm" novalidate>
      <div class="row col-sm-12 p-0 m-0 body">
        <div class="col-sm-1">
  
        </div>
        <div class="col-sm-11 divName">
            <div class="row pl-0 ">
              <div class="col-sm-9">
                  <h1 class="pt-2 pl-1" id="topurchaseReturnForm">Purchase Return Form</h1>
                  </div>
                  <div class="col-sm-3 mt-1">
                    <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                      <a (click)="onback()" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">arrow_back</span>Back</a>
                      <a (click)="topurchaseReturnFormList()" class="d-flex align-items-center ms-3 pr-2 mt-2"><span class="material-symbols-outlined pr-2">reorder</span>List</a>
                      <a *ngIf="isPrepopulate" (click)="printForm()" class="d-flex justify-content-end align-items-end mt-2">
                        <span class="material-symbols-outlined pr-1">print</span>Print</a>
                    </div>
            </div> 
          </div>
  
            <div class="row">
                <div class="col-sm-12 pr-4">
                    <div class="row">
                        <div class="card divbody col-sm-12 mb-3 borderradius p-2">
                           
                            <div class="col-sm-12" id="permit">
                             
                                      <div class="row col-sm-12 mt-2">
                                        <!-- <div class="col-sm-12 ">
                                          <h5 class="header">Device Details :</h5>
                                        </div> -->
                                    
                                                                            <!-- <div class="col-sm-2">
                                                                              <div class="row">
                                                                                <div class="col-sm-2 mt-3">
                                                                                  <mat-label class="center" ngDefaultControl formControlName="branchWiseId" *ngIf="userType !== 'Super Admin'" >{{bID}}</mat-label>
                                                                                  <mat-label class="center" ngDefaultControl [value]="superBranchId" *ngIf="userType == 'Super Admin'" >{{superBranchId}}</mat-label>
                                                                                  </div>
                                                                                  <div class="col-sm-10">
                                                                              <mat-form-field class="example-full-width" appearance="outline">
                                                                                <mat-label> Date
                                                                                </mat-label>
                                                                                <input formControlName="purchaseReturnDate" matInput [matDatepicker]="purchaseReturnDate" id="purchaseReturnDate" 
                                                                                  placeholder="DD/MM/YYYY" [min]="currentdate">
                                                                                <mat-datepicker-toggle matSuffix [for]="purchaseReturnDate">
                                                                                </mat-datepicker-toggle>
                                                                                <mat-datepicker #purchaseReturnDate></mat-datepicker>
                                                                              </mat-form-field>
                                                                              <mat-error class="margin_tp"
                                                                              *ngIf="purchaseReturnForm.controls['purchaseReturnDate'].hasError('matDatepickerMin')">
                                                                                   Please enter Date greater than or equal to {{minDateStr}}.
                                                                              </mat-error>
                                                                              </div>
                                                                              </div>
                                                                            </div> -->

                                                                            <div class=" col-sm-1 mt-3 ">
                                                                              <mat-label class="center" ngDefaultControl formControlName="branchWiseId" *ngIf="userType !== 'Super Admin'" >{{bID}}</mat-label>
                                                                              <mat-label class="center" ngDefaultControl [value]="superBranchId" *ngIf="userType == 'Super Admin'" >{{superBranchId}}</mat-label>
                                                                              
                                                                            </div>
                                                                            <div class=" col-sm-2">
                                                                              <mat-form-field class="example-full-width" appearance="outline">
                                                                                <mat-label> Date
                                                                                </mat-label>
                                                                                <input formControlName="purchaseReturnDate" matInput [matDatepicker]="purchaseReturnDate" id="purchaseReturnDate" 
                                                                                  placeholder="DD/MM/YYYY" [min]="currentdate">
                                                                                <mat-datepicker-toggle matSuffix [for]="purchaseReturnDate">
                                                                                </mat-datepicker-toggle>
                                                                                <mat-datepicker #purchaseReturnDate></mat-datepicker>
                                                                              </mat-form-field>
                                                                              <mat-error class="margin_tp"
                                                                              *ngIf="purchaseReturnForm.controls['purchaseReturnDate'].hasError('matDatepickerMin')">
                                                                                   Please enter Date greater than or equal to {{minDateStr}}.
                                                                              </mat-error>
                                                                            </div>

                                                                            <!-- <div class="col-sm-10"></div> -->
                                                                              <!-- <div class="row col-sm-12 mt-2">
                                                                                <div class="col-sm-12  ">
                                                                                  <h5 class="header mt-1">Device Details :</h5>
                                                                                </div>
                                                                              </div> -->
                                                                              <div class="col-sm-3" *ngIf="isSuperAdmin">
                                                                                <div class="row">
                                                                                  <div class="col-sm-10 ">
                                                                                <mat-form-field class="example-full-width" appearance="outline">
                                                                                  <mat-label>Select Branch 
                                                                                    <!-- <span class="required-asterisk">*</span> -->
                                                                                  </mat-label>
                                                                                  <mat-select #select3 formControlName="branchRefId" >
                                                                                    <mat-select-filter *ngIf="select3.focused" [placeholder]="'Filter'" [displayMember]="'branchName'"
                                                                                    [array]="branchList"  (filteredReturn)="filteredListBranch =$event"></mat-select-filter>
                                                                                    <mat-option *ngFor="let item of filteredListBranch" [value]="item._id" 
                                                                                    (click)="branchClick(item._id,item.branchName);"
                                                                                    >
                                                                                     {{ item.branchName }}</mat-option>
                                                                                    </mat-select>
                                                                                   
                                                                                </mat-form-field>
                                                                                <mat-error class="margin_tp"
                                                                                *ngIf="purchaseReturnForm.controls['branchRefId'].touched && purchaseReturnForm.controls['branchRefId'].errors?.required">
                                                                                This field is mandatory.
                                                                              </mat-error>
                                                                               </div>
                                                                               <div class="col-sm-2 mt-2 ">
                                                                                <i (click)="openBranchPopUp();" class="no-print" matTooltip="Add New Branch"  class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"> </i> 
                                                                              </div>
                                                                              </div>
                                                                               </div>

                                      <div class="col-sm-3">
                                    
                                        <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label>Device Name</mat-label>
                                           
                                            <mat-select #select1 formControlName="deviceRefId">
                                              <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'deviceName'"
                                              [array]="deviceList" (filteredReturn)="filteredDeviceList =$event"></mat-select-filter>
                                              <mat-option  *ngFor="let row of filteredDeviceList" 
                                              (click)="selectDevice(row.deviceRefId,row.deviceName)" [value]="row.deviceRefId">{{row.deviceName}}</mat-option>
                                            </mat-select>
                                          </mat-form-field>
                                          <mat-error class="margin_tp"
                        *ngIf="purchaseReturnForm.controls['deviceRefId'].touched && purchaseReturnForm.controls['deviceRefId'].errors?.required">
                        This field is mandatory.
                      </mat-error>
                                   
                                      <!-- <div class="col-sm-2 mt-2 ">
                                        <i class="mt-2 no-print" (click)="openDevicePopUp();" matTooltip="Add Device" 
                                        class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"> </i>
                                      </div> -->
                                     
                                      </div>
                                      <div class="col-sm-3">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>Serial Number
                                          </mat-label>
                                          <!-- <input matInput placeholder="Left Serial Number"  formControlName="LeftSerialNo" readonly /> -->
                                          <mat-select formControlName="serialNumber" multiple>
                                            <mat-option #matOption
                                            *ngFor="let item of serialNoList"
                                               [value]="item.serialNumber" (click)="onSelectSerialNo(matOption,item.serialNumber,item._id,item.status)"
                                               >{{ item.serialNumber }}
                                            </mat-option>
                                        </mat-select>
                                        </mat-form-field>
                                        <mat-error class="margin_tp"
                        *ngIf="purchaseReturnForm.controls['serialNumber'].touched && purchaseReturnForm.controls['serialNumber'].errors?.required">
                        This field is mandatory.
                      </mat-error>
                                      </div>
                                      <div class="col-sm-3">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>Qty
                                          </mat-label>
                                          <input matInput placeholder="Qty" formControlName="qty" readonly/>
                                        </mat-form-field>
                                      </div>
                                      <div  class="col-sm-6  ">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>Comment</mat-label>
                                          <textarea maxlength="1000" matInput formControlName="comment"
                                          cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                          cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" ></textarea>
                                        </mat-form-field>
                                      </div>
                                      <div  class="col-sm-3  "></div>
                                      <!-- <div class="col-sm-3">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>Stock Status
                                          </mat-label>
                                    
                                          <mat-select formControlName="stockStatus">
                                            <mat-option value="Return">Return</mat-option>
                                          </mat-select>
                                        </mat-form-field>
                                      </div> -->
                                   
                                  
                                 
                                  
                                    
                                    <div class="col-sm-2 offset-sm-10  no-print"  *ngIf="submitButton && purchaseReturnFormCreate"> 
                                        <button class="btn btn-primary btn-sm example-full-width" type="button"
                                         (click)="saveData();" >Save </button>
   
                                    </div>
                                   
                                    <div class="col-sm-2 offset-sm-6 no-print" *ngIf="updateButton && purchaseReturnFormUpdate">
                                        <button class="btn btn-primary btn-sm example-full-width" type="button"
                                         (click)="updateReturnDetails()">Update </button>
                                    </div>
                                    <div class="col-sm-2 no-print" *ngIf="updateButton && purchaseReturnFormUpdate">
                                      <button class="btn btn-primary btn-sm example-full-width" type="button"
                                       (click)="purchaseReturn()">Return</button>
                                  </div>
                                    <div class="col-sm-2 no-print" *ngIf="updateButton">
                                        <button class="btn btn-primary btn-sm example-full-width"  (click)="cancelUpdate()" type="reset" >Cancel </button>
                                       
                                    </div>
                                  </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                     
                <div class="row">
                    <div class="card col-sm-12 divbody borderradius p-3 pb-0">
                            <div class="col-sm-12">
                                <div class="row">
                                    <div class="col-sm-9 ">
                                        <h2 class="titlecolor">Purchase Return Form List</h2>
                                    </div>
                                    <div class="search-box col-sm-3">
                                      <input class="form-control amber-border" type="text" placeholder="Search" 
                                      formControlName="searchReturn" aria-label="Search" (keyup)="onKeyUpEvent($event)"
                                      >
                                      <div class="input-group-append">
                                         <span class="input-group-text amber lighten-3" id="basic-text1"><span class="material-symbols-outlined">search</span></span>
                                      </div>
                                   </div>
                                
                                    <div class="col-sm-12 table-responsive">
                                      <table id="topurchaseReturnFormList" 
                                   class="table table-bordered data-table-custom">
                                   <thead>
                                                <tr class="text-center">
                                                    <th>Return ID</th>
                                                    <th>Date</th>
                                                    <th>Device Name</th>
                                                    <th>Serial No.</th>
                                                    <th>Qty</th>
                                                    <!-- <th>Status</th> -->
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of listOfpurchaseReturn | paginate : { id:'return_pagination', 
                                                itemsPerPage: size, currentPage: page, totalItems:totalLength } let i = index" 
                                                (click)="prepopulation(item._id)"
                                                 (click)="topurchaseReturnForm()" 
                                                 style="cursor:pointer; text-align: center ">
  
                                                 <td matTooltip="click to view">{{i + 1}}</td>
                                                 <td matTooltip="click to view">{{item.purchaseReturnDate | date}}</td>
                                                 <td matTooltip="click to view" >{{item.deviceName}}</td>
                                        
                                                    <td matTooltip="click to view">{{item.serialNumber}}</td>
                                                    <td matTooltip="click to view">{{item.qty}}</td>
                                                    <!-- <td matTooltip="click to view">Return</td> -->
                                                 
                                                   
  
                                                      <!-- <td matTooltip="Click To View" >
                                                        <label *ngIf="item.status !== '' && item.status !== null
                                                        && item.status !== undefined; else elseBlock">
                                                          {{item.status}}
                                                        </label>
                                                      <ng-template #elseBlock>
                                                        <label>-</label>
                                                      </ng-template> 
                                                        </td> -->
  
                                               
                                                </tr>
                                              
                                            
                                               
  
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="col-sm-12 pt-0 text-right">
                                            <pagination-controls id="return_pagination" directionLinks="true"
                                  (pageChange)="onPageChange($event)" class="float-right pagination my-pagination"></pagination-controls>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          
    </form>
  </div>