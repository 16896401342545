import { Component, Inject, OnInit } from "@angular/core";
import {FormGroup,FormBuilder,FormControl, Validators} from '@angular/forms';
import { ApiServiceService } from '../api-service.service';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-pop-up-change-of-device',
  templateUrl: './pop-up-change-of-device.component.html',
  styleUrls: ['./pop-up-change-of-device.component.scss']
})
export class PopUpChangeOfDeviceComponent implements OnInit {
  complaintId:any;
  popupChangeDeviceForm: FormGroup;
  variables3: any;
  filteredList1: any;
  filteredList2: any;
  variables4: any;
  brandData: any;
  submitButton = true;
  updateButton = false;
  cancelButton = false;
  userType: string;
  listOfBrand: any;
  totalLength: any;
  searchText: any;
  page:number = 1;
  size:number = 3;
  bid: any;
  SrNo: any;
  Model: any;
  patientRef: any;
  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<PopUpChangeOfDeviceComponent>,@Inject(MAT_DIALOG_DATA) public data:any, 
    public apimicroservice: ApiServiceService,
) { dialogRef.disableClose = true;
   this.patientRef = data.patientRef; 
  // this.rightSrNo = data.rightSrNo; 
  // this.leftModel = data.leftModel; 
  // this.leftSrNo = data.leftSrNo; 
}
 addNewComplaint = false;
  ngOnInit() {
    this.page =1;
    this.userType = sessionStorage.getItem('userType');
    if(this.data.leftSrNo !== '' || this.data.leftSrNo !== undefined || this.data.leftSrNo !== null)
    {
      this.SrNo = this.data.leftSrNo;
    }
    else
    {
      this.SrNo = this.data.rightSrNo;
    }
    if(this.data.leftModel !== '' || this.data.leftModel !== undefined || this.data.leftModel !== null)
    {
      this.Model = this.data.leftModel;
    }
    else
    {
      this.Model = this.data.rightModel;
    }
    this.popupChangeDeviceForm = this.fb.group({  
      "modelName": new FormControl(this.Model),
      "originalSerialNo": new FormControl(this.SrNo),
      "serialNo": new FormControl('',Validators.required),

      
      "createdAt": new Date(),
      "searchBrand": new FormControl(''),   
    });
  }
  
  onPageChange(event) {
    this.page = event;
   // let p = this.page - 1;
    this.searchText = this.popupChangeDeviceForm.get('searchBrand').value;
 
      this.apimicroservice.getBrandData(this.page,this.size,this.searchText).subscribe(res => {
        this.listOfBrand = res.data.data;
        this.totalLength = res.data.totalItems;
         if(res.status){
          //  this.apimicroservice.openSnackbar(res.message);
           
         }else{
           //this.apimicroservice.openSnackbar(res.message);
         }
    
       });
  
  }
  onKeyUpEvent(event: any) {
    this.searchText = this.popupChangeDeviceForm.get('searchBrand').value;
    this.apimicroservice.getBrandData(this.page,this.size,this.searchText).subscribe(res => {
      this.listOfBrand = res.data.data;
      this.totalLength = res.data.totalItems;
       if(res.status){
        //  this.apimicroservice.openSnackbar(res.message);    
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
  }

  prepopulation(id) {
    this.apimicroservice.getBrandDataById(id).subscribe(res => {
      let data = res.data[0];
      this.bid = id;
       if(res.status){
        this.submitButton = false;
         this.updateButton = true;
         this.cancelButton = true;
         this.popupChangeDeviceForm = this.fb.group({
          "brandName" :new FormControl(data.brandName),
         });
       }else{
     //    //this.apimicroservice.openSnackbar(res.message);
       }
     });
  }
  updateDevice(){
    
    let payload = this.popupChangeDeviceForm.value; 
    //
    // this.rightModel = data.rightModel; 
    // this.rightSrNo = data.rightSrNo; 
    // this.leftModel = data.leftModel; 
    // this.leftSrNo = data.leftSrNo; 
    // this.apimicroservice.submitBrandData(payload).subscribe(res => {
    //   //
      
    //   if(res.status){
    //     this.apimicroservice.openSnackbar(res.message);      
    //     this.ngOnInit();
    //    this.onClose();        
    //   }else if(res.status == false){       
    //     this.apimicroservice.openSnackbar(res.message);
    //   }
    // }
    let devicePayload = {};
      devicePayload['changeModel'] = payload.modelName;
      devicePayload['changeSerialNumber'] = payload.serialNo;  
      this.apimicroservice.updateSoldDeviceDetails(devicePayload,this.SrNo).subscribe(res => {   
        //
        this.apimicroservice.openSnackbar("Saved data successfully");
        this.dialogRef.close({ data: payload }) 
      },(ERROR:HttpErrorResponse) => {
        this.apimicroservice.openSnackbar(ERROR.error.message); 
    }); 

    let devicePayload1 = {};
    devicePayload1['oldSerialNo'] = this.SrNo;   
      devicePayload1['newSerialNo'] = payload.serialNo;    
      // updateDeviceByPatient 
    this.apimicroservice.updateDeviceByPatient(devicePayload1,this.patientRef).subscribe(res => {   
      this.dialogRef.close({ data: payload })  
    },(ERROR:HttpErrorResponse) => { 
      this.apimicroservice.openSnackbar(ERROR.error.message);
  });
    
  } 
  save() { 
    if (this.popupChangeDeviceForm.invalid) {
      this.popupChangeDeviceForm.get("serialNo").markAsTouched();
      return;
    } else {
      this.updateDevice();
    }
  }
  getLeftComplaintData(){
    // this.apimicroservice.getLeftComplaintAllData().subscribe(res => {
    //   //
    //   this.variables3 = res.data;
    //   this.filteredList1 = this.variables3.slice();
    // });
    
  }
  
  onClose(): void {
    this.dialogRef.close();
  }
  addNewComplaintFunc()
  { 
    this.addNewComplaint = true;
  }
  cancelFunc()
  {
    this.addNewComplaint = false;
  }
 
  cancelUpdate(){
    this.popupChangeDeviceForm.reset();
    this.submitButton=true;
    this.updateButton=false;
    this.cancelButton=false;
    this.ngOnInit();
  }
} 
