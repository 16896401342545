import { DatePipe } from '@angular/common';
import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { Window } from 'selenium-webdriver';
import { ApiServiceService } from '../api-service.service';

@Component({
  selector: 'app-patient-search-pop',
  templateUrl: './patient-search-pop.component.html',
  styleUrls: ['./patient-search-pop.component.scss']
})
export class PatientSearchPopComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService, 
    public router: Router,
     public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    public route : ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: {},
    private dialogRef: MatDialogRef<PatientSearchPopComponent>
  ) { dialogRef.disableClose = true; }
  patientMasterForm: FormGroup;
  page:number = 1;
  size:number = 20;
  listOfPatients: any;
  searchText: any;
  totalLength:number;
  ngOnInit(): void { 
  this.page=1;
 // //
  
    this.patientMasterForm = this.fb.group({
      "searchPatient": new FormControl(''),
    });
    this.fetchPatient();
  }
  onPageChange(event) {
    this.spinner.show();
    this.page = event;
    this.searchText = this.patientMasterForm.get('searchPatient').value;
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    
   
      this.apimicroservice.getPatientList(this.page,this.size,this.searchText,branchRef,clinicRef).subscribe(res => {
        this.spinner.hide();
          this.listOfPatients = res.data.data;
          this.totalLength = res.data.totalItems;
          //this.buildingLenght = this.listofbuild.length;
         if(res.status){
          //  this.apimicroservice.openSnackbar(res.message);
           
         }else{
           //this.apimicroservice.openSnackbar(res.message);
         }
       });
  }
 fetchPatient() {
   let searchText = '';
   let clinicRef= sessionStorage.getItem('clinicRef');
   let branchRef= sessionStorage.getItem('branchRef');
    let page=1;
    let size=20;
      this.apimicroservice.getPatientList(page,size,searchText,branchRef,clinicRef).subscribe(res => {
        //
        this.listOfPatients = res.data.data;
        this.totalLength = res.data.totalItems;
        this.page=res.data.pageNumber;
        //this.buildingLenght = this.listofbuild.length;
       if(res.status){
        //  this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
    
  }
  onKeyUpEvent(event: any) {
    this.searchText = this.patientMasterForm.get('searchPatient').value;
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let page=1;
    let size=20;
      this.apimicroservice.getPatientList(page,size,this.searchText,branchRef,clinicRef).subscribe(res => {
        //
        this.listOfPatients = res.data.data;
        this.totalLength = res.data.totalItems;
        this.page=res.data.pageNumber;
        //this.buildingLenght = this.listofbuild.length;
       if(res.status){
        //  this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
    
  }
  toPatientForm(e) { 
    //
    let fromG = localStorage.getItem('fromGlobal');    
    if(fromG == 'true')
    {   
      this.dialogRef.close({ id:1 });  
    //  this.router.navigate(['/home/patient-global-search'],{ queryParams: { PatientId: e._id } })
      this.router.navigate(['/global-dashboard'],{ queryParams: { PatientId: e._id } });
   
    }   
    else  
    { 
      this.dialogRef.close({ data: e })
    } 
  }
  popUpClose()
  {
    this.dialogRef.close();
  }
}
