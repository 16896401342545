<div class="container-fluid bgcolor2" [formGroup]="AlertForm">
  <form  [formGroup]="AlertForm" autocomplete="off" novalidate>
    <div class="row">
        <div class="col-sm-1">
  
        </div>
        <div class="col-sm-11">
          <div class="row pl-0 ">
            <div class="col-12 pl-0 ">
              <div class="row ">
              <div class="col-sm-9">
                  <h1 class="pt-2 pl-0" id="toChequeListForm">Alert List</h1>
                  </div>
                  <div class="col-sm-3 mt-1">
                    <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                      <a (click)="onback()" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">arrow_back</span>Back</a>
                      <a (click)="toAlertForm()" class="d-flex align-items-center ms-3 pr-2 mt-2"><span class="material-symbols-outlined pr-2">reorder</span>List</a>
                 </div>
            </div>
          </div>
         </div>
         </div> 
          
            <div class="row">
                <div class="col-sm-12 pr-4">
                    <div class="row">
                        <div class="card divbody boxsssss mb-3 borderradius p-2">
                            
                            <div class="col-sm-12">
                                <div class="row">
                                
                                      <!-- <div class="col-sm-3">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>Date Of Alert</mat-label>
                                          <input matInput  [max]="currentdate" [matDatepicker]="altertdate" placeholder="Date Of Alert" (dateChange)="toOpenDateChnage();" formControlName="createdAt" />
                                          <mat-datepicker-toggle matSuffix [for]="altertdate">
                                          </mat-datepicker-toggle>
                                          <mat-datepicker #altertdate></mat-datepicker>   
                                        </mat-form-field>
                                      </div> -->
                                      <div class="col-sm-3">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>Alert Type</mat-label>
                                          <mat-select #select6  placeholder="Alert Type" class="colorblack" formControlName="alertType">
                                            <mat-select-filter *ngIf="select6.focused" [placeholder]="'Filter'" [displayMember]="'name'" [array]="allAlertType"
                                              (filteredReturn)="filteredAllAlertList =$event"></mat-select-filter>
                                            <mat-option *ngFor="let item of filteredAllAlertList" [value]="item.value">
                                              {{item.name}}
                                            </mat-option>
                                          </mat-select>
                                          <!-- <input matInput placeholder="Alert Type" formControlName="alertType" /> -->
                                        </mat-form-field>
                                      </div>
                                    
                                      <div class="col-sm-3 ">
                                        <mat-form-field class="example-full-width " appearance="outline">
                                           <mat-label>From Date</mat-label>
                                           <input matInput  [max]="currentdate" [matDatepicker]="fdate" placeholder="Choose a date" 
                                           formControlName="fdate" (dateChange)="toOpenDateChnage();">
                            
                                            <mat-datepicker-toggle matSuffix [for]="fdate">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #fdate></mat-datepicker>   
                                       </mat-form-field>
 
                                           <mat-error
                                           *ngIf="AlertForm.controls['fdate'].touched && AlertForm.controls['fdate'].errors?.required">
                                           This field is mandatory.
                                         </mat-error>
                                       </div>
                                       <div class="col-sm-3 ">
                                         <mat-form-field class="example-full-width " appearance="outline">
                                             <mat-label>To Date</mat-label>
                                             <input matInput  [max]="currentdate" [matDatepicker]="todate" placeholder="Choose a date" 
                                             formControlName="todate" (dateChange)="toCloseDateChnage();">
                              
                                              <mat-datepicker-toggle matSuffix [for]="todate">
                                              </mat-datepicker-toggle>
                                              <mat-datepicker #todate></mat-datepicker>
                                            
                                         </mat-form-field>
                                         <mat-error
                                         *ngIf="AlertForm.controls['todate'].touched && AlertForm.controls['todate'].errors?.required">
                                         This field is mandatory.
                                       </mat-error>
                                     </div>
                                    
                                     
                                      <!-- <div *ngIf="isSuperAdmin" class="col-sm-12">
                            
                                        <div class="row">
                                          <div class="col-sm-12 mt-1 ">
                                        <label class="header">Select Branch :</label>
                                          </div>
                                          </div>
                                          <div class="row">
                    
                                      <div class="col-sm-3" *ngIf="branchCreate == true">
                                        <div class="row">
                                          <div class="col-sm-10 pr-1">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>Branch 
    
                                          </mat-label>
                                          <mat-select #select1 (click)="fetchBranchList();" formControlName="branchRefId" >
                                            <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'branchName'"
                                            [array]="BranchList"  (filteredReturn)="filteredListBranch =$event"></mat-select-filter>
                                            <mat-option *ngFor="let item of filteredListBranch" [value]="item._id"
                                            (click)="branchClick(item._id,item.branchName);" >
                                             {{ item.branchName }}</mat-option>
                                            </mat-select>
                                            
                                        </mat-form-field>
                                        <mat-error class="margin_tp"
                                        *ngIf="AlertForm.controls['branchRefId'].touched && AlertForm.controls['branchRefId'].errors?.required">
                                        This field is mandatory.
                                      </mat-error> 
                                      </div>
                                      <div class="col-sm-2 mTop">
                                        <img (click)="openBranchPopUp();" matTooltip="Add New Branch" width="25" src="../../assets/add_img.png"> 
                                      </div>
                                      </div>
                                      </div>
                                      <div class="col-sm-3" *ngIf="branchCreate == false">
                                          
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>Branch 
                       
                                          </mat-label>
                                          <mat-select #select1 (click)="fetchBranchList();" formControlName="branchRefId" >
                                            <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'branchName'"
                                            [array]="BranchList"  (filteredReturn)="filteredListBranch =$event"></mat-select-filter>
                                            <mat-option *ngFor="let item of filteredListBranch" [value]="item._id"
                                            (click)="branchClick(item._id,item.branchName);" >
                                             {{ item.branchName }}</mat-option>
                                            </mat-select>
                                            
                                        </mat-form-field>
                                        <mat-error class="margin_tp"
                                        *ngIf="AlertForm.controls['branchRefId'].touched && AlertForm.controls['branchRefId'].errors?.required">
                                        This field is mandatory.
                                      </mat-error> 
                                      
                                      </div>
                                 </div>
                                 </div> -->
                                 
                                    <div class="col-sm-2 offset-sm-8 mb-2 mt-2" *ngIf="submitButton && billingCreate"> 
                                        <button class="btn btn-primary btn-sm example-full-width"  type="button" 
                                        (click)="searchChequeData()">Search </button>

                                    </div>
                                    <div class="col-sm-2 mb-2 mt-2">
                                      <button class="btn btn-primary btn-sm example-full-width"  (click)="clear()" type="reset">Cancel</button>
                                      </div>
                               
                                    <!-- <div class="col-sm-2 offset-sm-8 mb-2 mt-2" *ngIf="updateButton && billingUpdate">
                                        <button (click)="UpdateInwordOutword();"  type="button" 
                                        class="btn btn-primary btn-sm example-full-width">Update </button>
                                    </div> -->
                                    <!-- <div class="col-sm-2 mb-2 mt-2 " *ngIf="updateButton">
                                        <button class="btn btn-primary btn-sm example-full-width" (click)="cancelUpdate()" type="reset" >Cancel </button>
                                        
                                    </div> -->
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 pl-0 pr-2">
                <div class="row">
                    <div class="card divbody boxsssss borderradius p-3 pb-0">
                            <div class="col-sm-12">
                                <div class="row">
                                    <div class="col-sm-9 ">
                                        <h1 class="titlecolor">Alert List</h1>
                                    </div>
                                    <!-- <div class="col-sm-3">
                                        <div class="input-group md-form form-sm form-1 pl-0">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text purple lighten-3" id="basic-text1"><i
                                                        class="fas fa-search text-white" aria-hidden="true"></i></span>
                                            </div>
                                            <input class="form-control my-0 py-1" type="text" placeholder="Search"
                                                formControlName="searchBilling" aria-label="Search" (keyup)="onKeyUpEvent($event)" >
                                        </div>
                                    </div> -->
                                    <div class="col-sm-12">
                                      <table id="toBillingLst"
                                       class="table table-bordered data-table-custom text-center">
                                            <thead>
                                                <tr class="text-center">
                                                    <th>Sr.No.</th>
                                                     <th>Date Of Alert</th>
                                                     <th>Type Of Alert</th>
                                                    <th>Alert Description</th>
                                                    <th>Ref.No.</th>
                                                    <!-- <th>Status</th> -->
                                                </tr>
                                            </thead> 
                                            <tbody>
                                              
                                                <tr *ngFor="let item of billingList | paginate : { id:'alertMaster',
                                                itemsPerPage: size, currentPage: page, totalItems:totalLength } let i = index" 
                                                
                                                (click)="prepopulation(item._id)"
                                                 style="cursor:pointer; text-align: center ">
                                                 <td [class]="item.alertView == true ? 'colorA' : 'colorB'" matTooltip="Click To View">{{i+1}}</td>
                                                 <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType !== '11' && item.alertType !== '12' && item.alertType !== '13'" matTooltip="Click To View" 
                                                 >{{item.createdAt | date:'dd/MM/yyyy'}} 
                                               </td> 
                                               <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType == 11 || item.alertType == 12 || item.alertType == 13" matTooltip="Click To View" 
                                               >{{item.dateOfTransfer | date:'dd/MM/yyyy'}}
                                             </td> 
                                               <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType == 1" matTooltip="Click To View" >Cheque Clearance</td>
                                               <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType == 2" matTooltip="Click To View" >Cheque Dishonoured</td> 
                                               <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType == 11" matTooltip="Click To View" >Stock Dispatch</td>
                                               <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType == 12" matTooltip="Click To View" >Stock Rejected</td>
                                               <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType == 13" matTooltip="Click To View" >Stock Accepted</td>
                                               <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType == 21" matTooltip="Click To View" >Clinic Holiday</td>
                                               <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType == 22" matTooltip="Click To View" >Audiologist Leave</td>
                                               <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType == 101" matTooltip="Click To View" >Cheque Received</td>
                                               <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType == 102" matTooltip="Click To View" >Cheque Postdated</td>
                                               <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType == 103" matTooltip="Click To View" >Cheque Deposited</td>
                                               <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType == 104" matTooltip="Click To View" >Cheque Clear</td>
                                               <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType == 105" matTooltip="Click To View" >Cheque Cancelled</td>
                                               <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType == 106" matTooltip="Click To View" >Cheque Returned</td>
                                               <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType == 10" matTooltip="Click To View" >Not Defined</td>
                                               <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType == 111" matTooltip="Click To View" >Follow Up Created</td>

                                               <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType == 1" matTooltip="Click To View" >Just 2 days Remaing for Cheque Dishonoured</td>
                                               <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType == 2" matTooltip="Click To View" >Your cheque was Dishonoured</td>
                                               <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType == 11" matTooltip="Click To View" >Stock Transfer Request Number {{item.serialNumber}}, has been Initiated From {{item.fromBranchName}} To {{item.toBranchName}}</td>
                                               <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType == 12" matTooltip="Click To View" >Stock Transfer Request Number {{item.serialNumber}}, has been Rejected From {{item.fromBranchName}} To {{item.toBranchName}} </td>
                                               <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType == 13" matTooltip="Click To View" >Stock Transfer Request Number {{item.serialNumber}}, has been Accepted From {{item.fromBranchName}} To {{item.toBranchName}} </td>
                                               <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType == 21 && item.holidayfromDate !== item.holidaytoDate" matTooltip="Click To View" >On Occasion of {{item.holidayName}} clinic will be closed from {{item.holidayfromDate | date:'dd/MM/yyyy'}} to {{item.holidaytoDate | date:'dd/MM/yyyy'}}</td>
                                               <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType == 21 && item.holidayfromDate == item.holidaytoDate" matTooltip="Click To View" >On Occasion of {{item.holidayName}} clinic will be closed on {{item.holidayfromDate | date:'dd/MM/yyyy'}}</td>
                                               <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType == 22 && item.leaveType == 'Full Day'" matTooltip="Click To View" >Audiologist is on leave from {{item.holidayfromDate | date:'dd/MM/yyyy'}} to {{item.holidaytoDate | date:'dd/MM/yyyy'}}</td>
                                               <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType == 22 && item.leaveType == 'Half Day'" matTooltip="Click To View" >Audiologist is on leave - {{item.holidayfromDate | date:'dd/MM/yyyy'}}</td>
                                               <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType == 101" matTooltip="Click To View" >Your Cheque was Received</td>
                                               <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType == 102" matTooltip="Click To View" >Your Cheque was Postdated</td>
                                               <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType == 103" matTooltip="Click To View" >Your Cheque was Deposited</td>
                                               <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType == 104" matTooltip="Click To View" >Your Cheque was Clear</td>
                                               <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType == 105" matTooltip="Click To View" >Your Cheque was Cancelled</td>
                                               <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType == 106" matTooltip="Click To View" >Your Cheque was Returned</td>
                                               <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType == 10" matTooltip="Click To View" >Not Defined</td>
                                               <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType == 111" matTooltip="Click To View" >Follow Up Created</td>

                                               <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType == 1" matTooltip="Click To View" >{{item.receiptNo}}</td>
                                               <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType == 2" matTooltip="Click To View" >{{item.receiptNo}}</td>
                                               <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType == 11" matTooltip="Click To View" >{{item.billid}}</td>
                                               <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType == 12" matTooltip="Click To View" >{{item.billid}}</td>
                                               <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType == 13" matTooltip="Click To View" >{{item.billid}}</td>
                                               <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType == 21" matTooltip="Click To View" >{{item.billid}}</td>
                                               <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType == 22" matTooltip="Click To View" >{{item.billid}}</td>
                                               <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType == 101" matTooltip="Click To View" >{{item.receiptNo}}</td>
                                               <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType == 102" matTooltip="Click To View" >{{item.receiptNo}}</td>
                                               <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType == 103" matTooltip="Click To View" >{{item.receiptNo}}</td>
                                               <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType == 104" matTooltip="Click To View" >{{item.receiptNo}}</td>
                                               <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType == 105" matTooltip="Click To View" >{{item.receiptNo}}</td>
                                               <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType == 106" matTooltip="Click To View" >{{item.receiptNo}}</td>
                                               <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType == 10" matTooltip="Click To View" >{{item.receiptNo}}</td>
                                               <td [class]="item.alertView == true ? 'colorA' : 'colorB'" *ngIf="item.alertType == 111" matTooltip="Click To View" >{{item.billid}}</td>   
                                              </tr>
                                             
 
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="col-sm-12 pt-0 text-right">
                                            <pagination-controls id="alertMaster" directionLinks="true"
                                  (pageChange)="onPageChange($event)" class="float-right pagination my-pagination"></pagination-controls>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
             

        </div>
    </div>
    </form>
</div>