import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from "@angular/common";
import { ApiServiceService } from "../api-service.service";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-birthday-status',
  templateUrl: './birthday-status.component.html',
  styleUrls: ['./birthday-status.component.scss']
})
export class BirthdayStatusComponent implements OnInit {
  birthdayForm: FormGroup;
  pName: string;
  currentdate: Date;
  minDate: Date;
   constructor(private fb: FormBuilder,
    public datepipe: DatePipe,
    public apimicroservice: ApiServiceService,
    private spinner: NgxSpinnerService,
     public dialogRef: MatDialogRef<BirthdayStatusComponent>,@Inject(MAT_DIALOG_DATA) public data:any) {
       dialogRef.disableClose = true;}
 
   ngOnInit(): void {
    
    this.currentdate = new Date();
    this.minDate = new Date(1900, 0, 1);
    this.birthdayForm = this.fb.group({
      "branchWiseId": new FormControl(""),
      "prefix": new FormControl(''),
      "firstName": new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z-\\s]*$")]),
      "lastName": new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z-\\s]*$")]),
      "middleName": new FormControl('', [Validators.pattern("^[a-zA-Z-\\s]*$"), Validators.required]),
      "alternateName": new FormControl('', Validators.pattern("^[a-zA-Z-\\s]*$")),
      "dob": new FormControl('',Validators.required),
      "age": new FormControl('', Validators.pattern("^[0-9 ]*$")),
      "sex": new FormControl('', Validators.required),
      "clientSource": new FormControl(''),
      "clientStatus": new FormControl(''),
      "landline1": new FormControl('', Validators.pattern("[0-9]{3,4}[-][0-9]{6,8}")),
      "landline2": new FormControl('', Validators.pattern("[0-9]{3,4}[-][0-9]{6,8}")),
      "mobile1": new FormControl('', [Validators.pattern("(0|91)?[6-9][0-9]{9}")]),
      "mobile2": new FormControl('', Validators.pattern("(0|91)?[6-9][0-9]{9}")),
      "mobile3": new FormControl('', Validators.pattern("(0|91)?[6-9][0-9]{9}")),
      "mobile4": new FormControl('', Validators.pattern("(0|91)?[6-9][0-9]{9}")),
      "mobile5": new FormControl('', Validators.pattern("(0|91)?[6-9][0-9]{9}")),
      "email": new FormControl('', [Validators.email, Validators.pattern("(?![0-9]+@)[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}")]),
      "streetAddress": new FormControl(''),
      "taluka": new FormControl('', Validators.pattern("^[a-zA-Z ]*$")),
      "district": new FormControl(''),
      "state": new FormControl(''),
      "pincode": new FormControl('', [Validators.pattern("^[0-9]{6}$"), Validators.maxLength(6), Validators.minLength(6),]),
      "country": new FormControl('', [
        Validators.pattern("[A-Za-z _-]{3,19}$"),
      ]),
      "streetAddress2": new FormControl(''),
      "taluka2": new FormControl('', Validators.pattern("^[a-zA-Z ]*$")),
      "district2": new FormControl(''),
      "state2": new FormControl(''),
      "pincode2": new FormControl('', [Validators.pattern("^[0-9]{6}$"), Validators.maxLength(6), Validators.minLength(6),]),
      "country2": new FormControl('', [
        Validators.pattern("[A-Za-z _-]{3,19}$"),
      ]),

      "altAddress": new FormControl(''),
      "notes": new FormControl(''),
      "occupation": new FormControl(''),
      "otherOccupation": new FormControl('', Validators.pattern("^[a-zA-Z ]*$")),
      // "audiogram": new FormControl(''),
      "photo": '',
      "aadhaarNo": new FormControl('', Validators.pattern("^[2-9]\\d{3}[\\s-]?\\d{4}[\\s-]?\\d{4}$")),
      "createdAt": new Date(),
      "searchPatient": new FormControl(''),
      "branchRefId": new FormControl(),
      "clinicRefId": new FormControl(),
      "branchName": new FormControl(),
      "patientdropDown": new FormControl(),
      "doctorDropDown": new FormControl(),
      "advertisement": new FormControl(),
      "socialMedia": new FormControl(),
      "decease": new FormControl(),
      "patientName": new FormControl(),
      "birthdayStatus": new FormControl(''),
      "changedob": new FormControl(''),
      

    });
    if (this.data.refId) {
      this.prepopulation(this.data.refId);
    }
   }
 
   
   prepopulation(id) {
    this.spinner.show();

    this.apimicroservice.getPatientById(id).subscribe(res => {
      this.spinner.hide();
      let data = res.data[0];
      if (res.status) {
          this.birthdayForm = this.fb.group({
          "branchWiseId": new FormControl(data.branchWiseId),
          "prefix": new FormControl(data.prefix),
          "firstName": new FormControl(data.firstName, [Validators.required, Validators.pattern("^[a-zA-Z-\\s]*$")]),
          "lastName": new FormControl(data.lastName, [Validators.required, Validators.pattern("^[a-zA-Z-\\s]*$")]),
          "middleName": new FormControl(data.middleName, [Validators.pattern("^[a-zA-Z-\\s]*$"), Validators.required]),
          "alternateName": new FormControl(data.alternateName, Validators.pattern("^[a-zA-Z-\\s]*$")),
          "dob": new FormControl(data.dob,Validators.required),
          "age": new FormControl(data.age, Validators.pattern("^[0-9 ]*$")),
          "sex": new FormControl(data.sex, Validators.required),
          "clientSource": new FormControl(data.clientSource),
          "clientStatus": new FormControl(data.clientStatus),
          "landline1": new FormControl(data.landline1, Validators.pattern("[0-9]{3,4}[-][0-9]{6,8}")),
          "landline2": new FormControl(data.landline2, Validators.pattern("[0-9]{3,4}[-][0-9]{6,8}")),
          "mobile1": new FormControl(data.mobile1, [Validators.pattern("(0|91)?[6-9][0-9]{9}")]),
          "mobile2": new FormControl(data.mobile2, Validators.pattern("(0|91)?[6-9][0-9]{9}")),
          "mobile3": new FormControl(data.mobile3, Validators.pattern("(0|91)?[6-9][0-9]{9}")),
          "mobile4": new FormControl(data.mobile4, Validators.pattern("(0|91)?[6-9][0-9]{9}")),
          "mobile5": new FormControl(data.mobile5, Validators.pattern("(0|91)?[6-9][0-9]{9}")),
          "email": new FormControl(data.email, [Validators.email, Validators.pattern("(?![0-9]+@)[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}")]),
          "streetAddress": new FormControl(data.streetAddress),
          "taluka": new FormControl(data.taluka, Validators.pattern("^[a-zA-Z ]*$")),
          "district": new FormControl(data.district),
          "state": new FormControl(data.state),
          "pincode": new FormControl(data.pincode, [Validators.pattern("^[0-9]*$"), Validators.maxLength(6), Validators.minLength(6),]),
          "country": new FormControl(data.country, [
            Validators.pattern("[A-Za-z _-]{3,19}$"),
          ]),
          "streetAddress2": new FormControl(data.streetAddress2),
          "taluka2": new FormControl(data.taluka2, Validators.pattern("^[a-zA-Z ]*$")),
          "district2": new FormControl(data.district2, Validators.pattern("^[a-zA-Z ]*$")),
          "state2": new FormControl(data.state2),
          "pincode2": new FormControl(data.pincode2, [Validators.pattern("^[0-9]*$"), Validators.maxLength(6), Validators.minLength(6),]),
          "country2": new FormControl(data.country2, [
            Validators.pattern("[A-Za-z _-]{3,19}$"),
          ]),
          "altAddress": new FormControl(data.altAddress),
          "notes": new FormControl(data.notes),
          "occupation": new FormControl(data.occupation),
          "otherOccupation": new FormControl(data.otherOccupation),
          // "audiogram": new FormControl(data.audiogram),
          "photo": '',
          "aadhaarNo": new FormControl(data.aadhaarNo),
          "updatedAt": new Date(),
          "searchPatient": new FormControl(''),
          "branchRefId": new FormControl(data.branchRefId),
          "clinicRefId": new FormControl(data.clinicRefId),
          "branchName": new FormControl(data.branchName),
          "patientdropDown": new FormControl(data.patientdropDown),
          "doctorDropDown": new FormControl(data.doctorDropDown),
          "advertisement": new FormControl(data.advertisement),
          "socialMedia": new FormControl(data.socialMedia),
          "decease": new FormControl(data.decease),
          "patientName": new FormControl(data.firstName + ' ' + data.lastName),
          "birthdayStatus": new FormControl(data.birthdayStatus),
          "changedob": new FormControl(''),
        });

    
      } else {
        //    //this.apimicroservice.openSnackbar(res.message);
      }
    });
  }
   ageCalculate() {
    let payload = this.birthdayForm.value;
    if (payload.dob) {
      var timeDiff = Math.abs(Date.now() - new Date(payload.dob).getTime());
      let age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
      this.birthdayForm.controls["age"].setValue(age);
    }

  }
  ageCalculate2() {
    let payload = this.birthdayForm.value;
    if (payload.changedob) {
      var timeDiff = Math.abs(Date.now() - new Date(payload.changedob).getTime());
      let age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
      this.birthdayForm.controls["age"].setValue(age);
    }

  }
  updatePatient(e) {
    //this.spinner.show();
    let payload = this.birthdayForm.value;
    
    payload.birthdayStatus = e;
    
    
    if(payload.changedob !== '' && payload.changedob !== null)
{
  payload.dob = payload.changedob;
}  
  this.apimicroservice.updatePatientDetails(payload, this.data.refId).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.apimicroservice.openSnackbar(res.message);
        this.onClose();
      } else {
        //  //this.apimicroservice.openSnackbar(res.message);
      }
    });

  }
  WhatsApp() {
    let payload = this.birthdayForm.value;
    let num = payload.mobile1;
    if (num.length == 10) {
      sessionStorage.setItem('whatsAppNumber', payload.mobile1);
      let mobileNumber = sessionStorage.getItem('whatsAppNumber');
      window.open("https://wa.me/91" + mobileNumber);
    }
    else {
      this.birthdayForm.get("mobile1").markAsTouched();
    }
  }
  
   onClose(): void {
     this.dialogRef.close();
   }
 
 }
