import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiServiceService } from '../api-service.service';
@Component({
  selector: 'app-popup-sales-person-details',
  templateUrl: './popup-sales-person-details.component.html',
  styleUrls: ['./popup-sales-person-details.component.scss']
})
export class PopupSalesPersonDetailsComponent implements OnInit {
  popupSalesPersonDetailsForm: FormGroup;
  listOfSalesPerson:any;
  totalLength:any;
  searchText:any;
  page = 1;
  size =3;
  sid:any; 
  submitButton = true;
  updateButton = false;
  cancelButton = false;
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<PopupSalesPersonDetailsComponent>,@Inject(MAT_DIALOG_DATA) public data:any, 
    public apimicroservice: ApiServiceService,
    private spinner: NgxSpinnerService,
  ) { PopupSalesPersonDetailsComponent}

  ngOnInit(): void {
    this.page = 1;
    this.popupSalesPersonDetailsForm = this.fb.group({  
      "salesPersonName": new FormControl('',[Validators.required,Validators.pattern("^[a-zA-Z0-9 -]+$")]),
      "salesPersonContactNumber": new FormControl('',[Validators.pattern("(0|91)?[6-9][0-9]{9}")]),
      "createdAt": new Date(),
      "search": new FormControl(''),
    });
    this.fetchSalesPersonList();
  }


  fetchSalesPersonList() {
    let pg = 1;
    let size = 3;
    let search='';
        this.apimicroservice.getSalesPersonData(pg,size,search).subscribe(res => {
          //
          
           this.listOfSalesPerson = res.data.data;
           this.totalLength = res.data.totalItems;
           //this.buildingLenght = this.listofbuild.length;
          if(res.status){
           // this.apimicroservice.openSnackbar(res.message);
            
          }else{
            //this.apimicroservice.openSnackbar(res.message);
          }
     
        });   
 
  }
  saveSalesPerson(){
    let payload = this.popupSalesPersonDetailsForm.value; 
    //
    this.spinner.show();
    this.apimicroservice.saveSalePerson(payload).subscribe(res => {
      //
      this.spinner.hide();
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);      
        //this.ngOnInit();
       this.onClose();        
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    },(ERROR:HttpErrorResponse) => {
      this.apimicroservice.openSnackbar(ERROR.error.message);
      this.spinner.hide();
      this.onClose();    
  });
  }
  save() {
    if (this.popupSalesPersonDetailsForm.invalid) {
      this.popupSalesPersonDetailsForm.get("salesPersonName").markAsTouched();
      return;
    } else {
      this.saveSalesPerson();
    }
  }
  onKeyUpEvent(event: any) {
    this.searchText = this.popupSalesPersonDetailsForm.get('search').value;
    let page=1;
    let size=10;

    this.apimicroservice.getSalesPersonData(page,size,this.searchText).subscribe(res => {
      this.listOfSalesPerson = res.data.data;
      this.totalLength = res.data.totalItems;
      this.page=res.data.pageNumber;
       if(res.status){
        //  this.apimicroservice.openSnackbar(res.message);    
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
  }

  prepopulation(id) {
    this.apimicroservice.getSalesPersonDataById(id).subscribe(res => {
      let data = res.data[0];
      this.sid = id;
       if(res.status){
        this.submitButton = false;
         this.updateButton = true;
         this.cancelButton = true;
         this.popupSalesPersonDetailsForm = this.fb.group({
          "salesPersonName": new FormControl(data.salesPersonName,[Validators.required,Validators.pattern("^[a-zA-Z0-9 -]+$")]),
          "salesPersonContactNumber": new FormControl(data.salesPersonContactNumber,[Validators.pattern("(0|91)?[6-9][0-9]{9}")]),
          "updateddAt": new Date(),
         });
       }else{
     //    //this.apimicroservice.openSnackbar(res.message);
       }
     });
  }
  updateSalesPerson() {
    let payload = this.popupSalesPersonDetailsForm.value;
     this.apimicroservice.updateSalesPersonData(payload,this.sid).subscribe(res => {
       if(res.status){
         this.apimicroservice.openSnackbar(res.message);
          this.updateButton = false;
          this.submitButton = true;
          this.ngOnInit();
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
    
  }
  onPageChange(event) {
    this.page = event;
   // let p = this.page - 1;
    this.searchText = this.popupSalesPersonDetailsForm.get('search').value;
 
    this.apimicroservice.getSalesPersonData(this.page,this.size,this.searchText).subscribe(res => {
      //
      
      this.listOfSalesPerson = res.data.data;
      this.totalLength = res.data.totalItems;
         if(res.status){
          //  this.apimicroservice.openSnackbar(res.message);
           
         }else{
           //this.apimicroservice.openSnackbar(res.message);
         }
    
       });
  
  }
  cancelUpdate(){
    this.popupSalesPersonDetailsForm.reset();
    this.submitButton=true;
    this.updateButton=false;
    this.cancelButton=false;
    this.ngOnInit();
  }
  onClose(): void {
    this.dialogRef.close();
  }
}
