<div class="container-fluid " [formGroup]="billingCompanyForm">
  <form [formGroup]="billingCompanyForm" autocomplete="off" novalidate>
    <div class="row mtt">
      <div class="col-sm-12">

        <div class="row">
          <div class="col-sm-12 pr-4">
            <div class="row">
              <div class="col-sm-11">
                <div class="row ">

                  <!-- <span><img src="../../assets/bank.png" width="30rem"> </span>  -->
                  <span> 
                    <h1 class="fass ml-3 pt-1">Billing Company</h1>
                  </span>
                </div>

              </div>
              <div class="col-sm-1 ">
                <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                  <a (click)="onClose()" matTooltip="Close" class="d-flex align-items-center pr-2 mt-2"><span
                      class="material-symbols-outlined pr-2">close</span></a>
                </div>
              </div>

            </div>
            <div class="row">
              <div class="col-sm-12 pr-4">

                <div class="row pl-3 pr-3">
                  <div class="col-sm-12">
                    <div class="row mt-2">
                      <div class="col-sm-12  ">
                        <label class="header">Billing Details :</label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-1 mt-3 ">
                        <!-- <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Company ID 
                                    
                                  </mat-label>
                                  <input  matInput  maxlength="3" placeholder="Company ID" formControlName="companyId"  readonly />
                                
                                </mat-form-field>
                                <mat-error class="margin_tp" 
                                *ngIf="billingCompanyForm.controls['companyId'].touched && billingCompanyForm.controls['companyId'].errors?.required">
                                This field is mandatory.
                              </mat-error>  -->
                        <mat-label class="center" placeholder="Company ID" ngDefaultControl formControlName="companyId">
                          {{bId}}</mat-label>
                      </div>


                      <div class="col-sm-2 ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Company Name
                            <!-- <span class="required-asterisk">*</span>  -->
                          </mat-label>
                          <input matInput placeholder="Company Name" formControlName="companyName" />

                        </mat-form-field>
                        <mat-error class="margin_tp"
                          *ngIf="billingCompanyForm.controls['companyName'].touched && billingCompanyForm.controls['companyName'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                      </div>


                      <div class="col-sm-3  ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Registered Under GST
                            <!-- <span class="required-asterisk">*</span>  -->
                          </mat-label>
                          <mat-select (keyup)="gstApplicable();" required matTooltip="Registered Under GST"
                            formControlName="registeredUnderGST">
                            <mat-option (click)="gstApplicable();" value="Yes">Yes</mat-option>
                            <mat-option (click)="gstApplicable();" value="No">No</mat-option>
                          </mat-select>

                        </mat-form-field>
                        <mat-error class="margin_tp"
                          *ngIf="billingCompanyForm.controls['registeredUnderGST'].touched && billingCompanyForm.controls['registeredUnderGST'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                      </div>
                      <div class="col-sm-3 " *ngIf="gstCheckbox">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Company GST</mat-label>
                          <input matInput placeholder="eg.(29ABCDE1234F2Z5)" formControlName="companyGST" />


                        </mat-form-field>
                        <mat-error class="margin_tp"
                          *ngIf="billingCompanyForm.controls['companyGST'].touched && billingCompanyForm.controls['companyGST'].errors?.pattern">
                          Please enter valid GST Number.
                        </mat-error>
                      </div>

                      <div class="col-sm-3 ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Company PAN
                            <!-- <span class="required-asterisk">*</span>  -->
                          </mat-label>
                          <input required maxlength="15" matInput placeholder="Company PAN"
                            formControlName="companyPAN" />

                        </mat-form-field>
                        <mat-error class="margin_tp"
                          *ngIf="billingCompanyForm.controls['companyPAN'].touched && billingCompanyForm.controls['companyPAN'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                      </div>
                      <div class="col-sm-3 ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Proprietor / Director </mat-label>
                          <input matTooltip="Proprietor / Director" matInput placeholder="Proprietor / Director "
                            formControlName="propriter" />

                        </mat-form-field>
                      </div>
                      <div class="col-sm-3 ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Company Type
                            <!-- <span class="required-asterisk">*</span>  -->
                          </mat-label>
                          <mat-select required matTooltip="Company Type" formControlName="companyType">
                            <mat-option value="Proprietory">Proprietory</mat-option>
                            <mat-option value="Pvt Ltd"> Pvt Ltd</mat-option>
                            <mat-option value="LLP">LLP</mat-option>
                          </mat-select>

                        </mat-form-field>
                        <mat-error class="margin_tp"
                          *ngIf="billingCompanyForm.controls['companyType'].touched && billingCompanyForm.controls['companyType'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-12">

                    <div class="row mt-2">

                      <div class="col-sm-12 ">
                        <label class="header">Address Details :</label>
                      </div>

                      <div class="col-sm-3 ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Street Address
                            <!-- <span class="required-asterisk">*</span>  -->
                          </mat-label>
                          <input required matInput matTooltip="Company Registered Address"
                            placeholder="Company Registered Address" formControlName="companyRegisteredAddress" />

                        </mat-form-field>
                        <mat-error class="margin_tp"
                          *ngIf="billingCompanyForm.controls['companyRegisteredAddress'].touched && billingCompanyForm.controls['companyRegisteredAddress'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                      </div>
                      <div class="col-sm-3 ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Taluka</mat-label>
                          <input matInput placeholder="Taluka" formControlName="taluka" />

                        </mat-form-field>
                        <mat-error class="margin_tp"
                          *ngIf="billingCompanyForm.controls['taluka'].touched && billingCompanyForm.controls['taluka'].errors?.pattern">
                          Please enter valid taluka.
                        </mat-error>
                      </div>
                      <div class="col-sm-3 ">
                        <!-- <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>District</mat-label>
                                          <input matInput placeholder="District" formControlName="district" />
                                         
                                        </mat-form-field> -->
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>District</mat-label>
                          <mat-select #select12 placeholder="District" class="colorblack" formControlName="district">
                            <mat-select-filter *ngIf="select12.focused" [placeholder]="'Filter'"
                              [displayMember]="'district'" [array]="districtData2"
                              (filteredReturn)="filteredListDistrict2 =$event"></mat-select-filter>
                            <mat-option *ngFor="let item of filteredListDistrict2" [value]="item.district">
                              {{item.district}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                        <mat-error class="margin_tp"
                          *ngIf="billingCompanyForm.controls['district'].touched && billingCompanyForm.controls['district'].errors?.pattern">
                          Please enter valid district.
                        </mat-error>
                      </div>
                      <div class="col-sm-3 ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>State</mat-label>
                          <mat-select #select11 placeholder="State" class="colorblack" formControlName="state">
                            <mat-select-filter *ngIf="select11.focused" [placeholder]="'Filter'"
                              [displayMember]="'state'" [array]="stateCityArr2"
                              (filteredReturn)="filteredList2 =$event"></mat-select-filter>
                            <mat-option *ngFor="let item of filteredList2" [value]="item.state"
                              (click)="getDistrict2(item.districts)">
                              {{item.state}}
                            </mat-option>
                          </mat-select>

                        </mat-form-field>
                        <mat-error class="margin_tp"
                          *ngIf="billingCompanyForm.controls['state'].touched && billingCompanyForm.controls['state'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                      </div>
                      <div class="col-sm-3  ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Pin Code
                            <!-- <span class="required-asterisk">*</span>  -->
                          </mat-label>
                          <input required formControlName="companyPinCode" maxlength="6" matInput
                            placeholder="Pincode(e.g:416008)" />


                        </mat-form-field>
                        <mat-error
                          *ngIf="billingCompanyForm.controls['companyPinCode'].touched && billingCompanyForm.controls['companyPinCode'].errors?.pattern">
                          Please enter valid PinCode</mat-error>

                        <mat-error
                          *ngIf="billingCompanyForm.controls['companyPinCode'].touched && billingCompanyForm.controls['companyPinCode'].errors?.required">
                          This field is mandatory.</mat-error>
                      </div>


                      <div class="col-sm-3 ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Country</mat-label>
                          <input matInput placeholder="Country" formControlName="country" />

                        </mat-form-field>
                        <mat-error class="margin_tp"
                          *ngIf="billingCompanyForm.controls['country'].touched && billingCompanyForm.controls['country'].errors?.pattern">
                          Please enter valid country.
                        </mat-error>
                      </div>




                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="row mt-2">

                      <div class="col-sm-12 ">
                        <label class="header">Contact Details :</label>
                      </div>

                      <div class="col-sm-3 ">
                        <div class="row">
                          <div class="col-sm-10">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Company Contact Number
                                <!-- <span class="required-asterisk">*</span>  -->
                              </mat-label>
                              <input required matInput maxlength="10" matTooltip="Company Contact Number"
                                placeholder="Company Contact Number " formControlName="companyContactNumber" />


                            </mat-form-field>
                            <mat-error class="margin_tp"
                              *ngIf="billingCompanyForm.controls['companyContactNumber'].touched && billingCompanyForm.controls['companyContactNumber'].errors?.required">
                              This field is mandatory.
                            </mat-error>
                            <mat-error class="margin_tp"
                              *ngIf="billingCompanyForm.controls['companyContactNumber'].touched && billingCompanyForm.controls['companyContactNumber'].errors?.pattern">
                              Please enter valid number.
                            </mat-error>
                          </div>
                          <div class="col-sm-2 wApp">
                            <i class="bi bi-whatsapp whatsapp-icon wApp fa-lg" (click)="WhatsApp();"
                              matTooltip="WhatsApp Integration"></i>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="row mt-2">


                      <div class="col-sm-12 ">
                        <label class="header">Other Details :</label>
                      </div>
                      <div class="col-sm-3 ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Company Status</mat-label>

                          <mat-select formControlName="billingStatus">
                            <mat-option value="Active">Active</mat-option>
                            <mat-option value="In-active">In-active</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-10 " *ngIf="submitButton">
                  </div>
                  <!-- <div class="col-sm-8" *ngIf="updateButton">
                                    </div> -->


                  <div class="col-sm-2 mb-2 mt-2" *ngIf="submitButton && billingCreate">
                    <button class="btn btn-primary btn-sm example-full-width" type="button"
                      (click)="submitBillingCompany()">Save</button>
                  </div>
                  <!-- <div class="col-sm-2 mb-2 mt-2"  *ngIf="updateButton && billingUpdate">
                              <button class="btn btn-primary btn-sm   example-full-width" type="button"  (click)="updateBillingDeatils()" >Update</button>
                              </div>
                            <div class="col-sm-2 mb-2 mt-2" *ngIf="cancelButton">
                              <button class="btn btn-primary btn-sm example-full-width"  (click)="cancelUpdate()" type="reset">Cancel</button>
                              </div> -->

                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </form>
</div>