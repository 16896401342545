import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiServiceService } from "../api-service.service";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";

@Component({
  selector: 'app-popup-stock-in-hand',
  templateUrl: './popup-stock-in-hand.component.html',
  styleUrls: ['./popup-stock-in-hand.component.scss']
})
export class PopupStockInHandComponent implements OnInit {
  typeOfSelect: string;
  laterDate: Date;
  toDate: Date;
  currentdate: Date;
  selectType1: any;
  BranchList: any;
  filteredListBranch: any;

  constructor(private dialogRef: MatDialogRef<PopupStockInHandComponent>,
    public apimicroservice: ApiServiceService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    //this.src = data
  }
  public pieChartType = 'bar';
  public barChartLegend = false;
  public barChartOptions = {
    scaleShowVerticalLines: false,
  };
  public chartColors: any[] = [
    { 
      backgroundColor:["#36a2eb","#ffa1b5","#B5D397","#fdb658","#C8CDF0", "#5dc1e9", "#b7f3eb", "#7fe79b", "#efc77e"] 
    }];
  isImage = false;
  src
  stockInHandArr=[];
  stockInHandLabels: any[];
  userType: string;
  branchRef: any;
  dashboardForm: FormGroup;
  isFromToVisible=false;
  
  ngOnInit(): void {
    this.userType = sessionStorage.getItem('userType');
    this.branchRef = sessionStorage.getItem('branchRef');
    this.toDate = new Date();
    this.currentdate = new Date();
    this.dashboardForm = this.fb.group({
      "searchdashboard": new FormControl(''),
      "selectType": new FormControl(''),
      "formDate": new FormControl(''),
      "toDate": new FormControl(''),
      "repairActivity": new FormControl(''),
      "branchRefId": new FormControl(''),
      
    }); 
    this.fetchBranchList();
    this.getStock(); 
    this.dashboardForm.controls["selectType"].setValue('Yearly');
    this.dashboardForm.controls["formDate"].setValue('');
    this.dashboardForm.controls["toDate"].setValue('');
    this.dashboardForm.controls["repairActivity"].setValue('scenario1');
    this.dashboardForm.controls["branchRefId"].setValue('');

   // this.yearData();
    // this.src = this.data
    // //
    // if (this.src.startsWith('data:image')) {
    //   this.isImage = true;
    // }
    // else {
    //   this.isImage = false;
    // }

  }
  monthlyData(){
    let branchRef= sessionStorage.getItem('branchRef');
    if (this.userType !== 'Super Admin') {
      this.apimicroservice.stockInHandCountByMonth(this.branchRef).subscribe(res => {
        if (res.status) {   
          this.stockInHandLabels = [];
          this.stockInHandArr = [];
          //
          if(res.data.dataPush.length > 0){
        res.data.dataPush.forEach(element => {
           this.stockInHandLabels.push(element.deviceName);
           this.stockInHandArr.push(element.stockInHand); 
        });
      }
      else
      {
        this.stockInHandLabels =['',' '];
        this.stockInHandArr = [0,0];
      }
        } 
      }); 
    }
    else{
      this.apimicroservice.stockInHandCountByMonth(branchRef).subscribe(res => {
        if (res.status) {   
          this.stockInHandLabels = [];
          this.stockInHandArr = [];
          //
          if(res.data.dataPush.length > 0){
        res.data.dataPush.forEach(element => {
           this.stockInHandLabels.push(element.deviceName);
           this.stockInHandArr.push(element.stockInHand);
        });
      }
      else
      {
        this.stockInHandLabels =['',' '];
        this.stockInHandArr = [0,0];
      }
        } 
      });
    }
  }
  yearData()
  {
    let branchRef= sessionStorage.getItem('branchRef');
    if (this.userType !== 'Super Admin') {
      this.apimicroservice.stockInHandCountByYear(branchRef).subscribe(res => {
        this.stockInHandLabels = [];
        this.stockInHandArr = [];
        //
        if(res.countData.dataPush.length > 0){
        res.countData.dataPush.forEach(element => {     
          this.stockInHandLabels.push(element.deviceName);
          this.stockInHandArr.push(element.stockInHand);
        });
      }
      else
      {
        this.stockInHandLabels =[' ',' '];
        this.stockInHandArr = [0,0];
      }
    });
  }
  else{
    this.apimicroservice.stockInHandCountByYear(branchRef).subscribe(res => {
      this.stockInHandLabels = [];
      this.stockInHandArr = [];
      //
      if(res.countData.dataPush.length > 0){
      res.countData.dataPush.forEach(element => {     
        this.stockInHandLabels.push(element.deviceName);
        this.stockInHandArr.push(element.stockInHand);
      });
    }
    else
    {
      this.stockInHandLabels =[' ',' '];
      this.stockInHandArr = [0,0];
    }
  });
  }
  }
  getStock()
  {
    let branchRef;
    let payload = this.dashboardForm.value; 
    if(payload.branchRefId == '' || payload.branchRefId == null)
    {
      branchRef= sessionStorage.getItem('branchRef');
    } 
    else
    {
      branchRef=payload.branchRefId;
    }
    if (this.userType !== 'Super Admin') {
      this.apimicroservice.stockInHandCount(this.branchRef).subscribe(res => {
        if (res.status) {   
          this.stockInHandLabels = [];
          this.stockInHandArr = [];
          //
          if(res.countData.dataPush.length > 0){
        res.countData.dataPush.forEach(element => {
           this.stockInHandLabels.push(element.deviceName);
           this.stockInHandArr.push(element.stockInHand); 
        });
      }
      else
      {
        this.stockInHandLabels =['',' '];
        this.stockInHandArr = [0,0];
      }
        } 
      }); 
    }
    else{
      this.apimicroservice.stockInHandCount(branchRef).subscribe(res => {
        if (res.status) {   
          this.stockInHandLabels = [];
          this.stockInHandArr = [];
          //
          if(res.countData.dataPush.length > 0){
        res.countData.dataPush.forEach(element => {
           this.stockInHandLabels.push(element.deviceName);
           this.stockInHandArr.push(element.stockInHand);
        });
      }
      else
      {
        this.stockInHandLabels =['',' '];
        this.stockInHandArr = [0,0];
      }
        } 
      });
    }
  }
selectType(e)
{

  let branchRef;
  let payload = this.dashboardForm.value; 
  if(payload.branchRefId == '' || payload.branchRefId == null)
  {
    branchRef= sessionStorage.getItem('branchRef');
  }
  else
  {
    branchRef=payload.branchRefId;
  }
   if(e == 'Custom')
   {
     this.isFromToVisible = true;
    this.typeOfSelect = 'Custom';
   }
   else if(e == 'Monthly')
   {

    this.typeOfSelect = 'Monthly';
    this.isFromToVisible = false;
   // this.getASPPerMonth();
    //
  

   //
   //ASP per Month
  
    //stock in hand 
    if (this.userType !== 'Super Admin') {
    this.apimicroservice.stockInHandCountByMonth(this.branchRef).subscribe(res => {
      if (res.status) {   
        this.stockInHandLabels = [];
        this.stockInHandArr = [];
        //
        if(res.data.dataPush.length > 0){
      res.data.dataPush.forEach(element => {
         this.stockInHandLabels.push(element.deviceName);
         this.stockInHandArr.push(element.stockInHand); 
      });
    }
    else
    {
      this.stockInHandLabels =['',' '];
      this.stockInHandArr = [0,0];
    }
      } 
    }); 
  }
  else{
    this.apimicroservice.stockInHandCountByMonth(branchRef).subscribe(res => {
      if (res.status) {   
        this.stockInHandLabels = [];
        this.stockInHandArr = [];
        //
        if(res.data.dataPush.length > 0){
      res.data.dataPush.forEach(element => {
         this.stockInHandLabels.push(element.deviceName);
         this.stockInHandArr.push(element.stockInHand);
      });
    }
    else
    {
      this.stockInHandLabels =['',' '];
      this.stockInHandArr = [0,0];
    }
      } 
    });
  }
}
else if(e == 'Yearly')
{
  //Stoack in hand 
  if (this.userType !== 'Super Admin') {
  this.apimicroservice.stockInHandCountByYear(this.branchRef).subscribe(res => {
    this.stockInHandLabels = [];
    this.stockInHandArr = [];
    //
    if(res.countData.dataPush.length > 0){
    res.countData.dataPush.forEach(element => {     
      this.stockInHandLabels.push(element.deviceName);
      this.stockInHandArr.push(element.stockInHand);
    });
  }
  else
  {
    this.stockInHandLabels =[' ',' '];
    this.stockInHandArr = [0,0];
  }
});
}
else{
this.apimicroservice.stockInHandCountByYear(branchRef).subscribe(res => {
  this.stockInHandLabels = [];
  this.stockInHandArr = [];
  //
  if(res.countData.dataPush.length > 0){
  res.countData.dataPush.forEach(element => {     
    this.stockInHandLabels.push(element.deviceName);
    this.stockInHandArr.push(element.stockInHand);
  });
}
else
{
  this.stockInHandLabels =[' ',' '];
  this.stockInHandArr = [0,0];
}
});
}
}
}
selectFromdate(event : any){
  let payload = this.dashboardForm.value;
  this.laterDate = new Date(payload.formDate);
  this.laterDate.setDate(this.laterDate.getDate() + 14);
  //
}
  toDatee(event :any){
    let payload = this.dashboardForm.value; 
    let branchRef= sessionStorage.getItem('branchRef');
     if(payload.branchRefId == '' || payload.branchRefId == null)
     {
       branchRef= sessionStorage.getItem('branchRef');
     }
     else
     {
       branchRef=payload.branchRefId;
     }
     if (this.userType !== 'Super Admin') {
      this.apimicroservice.stockInHandCountByCustomDate(payload.formDate,payload.toDate,this.branchRef).subscribe(res => {
        if (res.status) {   
          this.stockInHandLabels = [];
          this.stockInHandArr = [];
          if(res.data.count.length > 0){
         res.data.count.forEach(element => {
          //
          this.stockInHandLabels.push(element._id);
          this.stockInHandArr.push(element.count);
         });
        }
         else
         {
           this.stockInHandLabels =[' ',' '];
           this.stockInHandArr = [0,0];
         }
        } 
      });
      }
      else{
        this.apimicroservice.stockInHandCountByCustomDate(payload.formDate,payload.toDate,branchRef).subscribe(res => {
          if (res.status) {   
            this.stockInHandLabels = [];
            this.stockInHandArr = [];
            if(res.data.count.length > 0){
           res.data.count.forEach(element => {
            //
            this.stockInHandLabels.push(element._id);
            this.stockInHandArr.push(element.count);
           });
          }
           else
           {
             this.stockInHandLabels =[' ',' '];
             this.stockInHandArr = [0,0];
           }
          } 
        });
      }

    }
    selectBranch(){
      let payload = this.dashboardForm.value; 
      this.selectType1 = payload.selectType;
    }
    refres()
    {
      this.ngOnInit();
    }
    fetchBranchList() {
      let clinicRef= sessionStorage.getItem('clinicRef');
      this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
        //  
        if (res.status) {    
          this.BranchList = res.data;
          this.filteredListBranch= this.BranchList.slice();
        } else {
        //  this.apimicroservice.openSnackbar(res.message);
        }
      })
    } 
    branchClick(id,branchName)
    {
      let payload = this.dashboardForm.value; 
      this.selectType1 = payload.selectType;
    //   this.selectType(this.selectType1);
    let newpayload = new Promise<void>((resolve, reject) => {
      let payload = this.dashboardForm.value;
       this.branchRef =id;
       //
      setTimeout(() => {
        resolve();
      }, 1000);
    });   
    this.selectType(payload.selectType);
    }
}