<div class="container-fluid padding     ">
    <form [formGroup]="forgotPasswordForm" autocomplete="off" class="md-float-material form-material" (keydown)="keyDownFunction($event)">
    <div class="card card0 border-0">
        <div class="row d-flex">
            <div class="col-lg-6">
                <div class="card1 pb-5">
                    <div class="row logo"> 
                        <!-- <img class="pr-3"  width="120rem" src="../../assets/logo-removebg-preview.png"> -->
                        <!-- <img src="../../assets/logo-removebg-preview.png" class="logo" alt=""> -->

                     </div>
                    <div class="row px-3 justify-content-center mt-4 mb-5 border-line"> 
                        <!-- <img class="pr-3"  width="150rem" src="../../assets/audiology.png"> -->
                        <img src="../../assets/audiology.png" > </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="card2 card border-0 px-4 py-5">
                    <div class="row mb-4 px-3">
                        
                    </div>
                    <!-- <div class="row mb-4">
                        <div class="line"></div> <small class="or text-center">Or</small>
                        <div class="line"></div>
                    </div> -->
                    <p class="text-muted">
                        Enter your email for new password
                      </p>
                    <div class="row px-3"> <label class="mb-1">
                            <h6 class="mb-0 text-sm">Email Address</h6>
                        </label> <input class="mb-4" type="text" name="emailId" class="emailClass" formControlName="emailId" placeholder="Enter a valid email address"> 
                          <mat-error
                            *ngIf="forgotPasswordForm.controls['emailId'].touched && forgotPasswordForm.controls['emailId'].errors?.required">
                            This field is mandatory.
                          </mat-error>
                          <mat-error
                          *ngIf="forgotPasswordForm.controls['emailId'].touched && forgotPasswordForm.controls['emailId'].errors?.pattern">
                          Please enter valid Email.
                        </mat-error>
                        <!-- <mat-error
                        *ngIf="forgotPasswordForm.controls['emailId'].touched && forgotPasswordForm.controls['emailId'].errors?.minlength">
                        Please enter strong Email.
                      </mat-error> -->
                        </div>
                          <!-- <p class="text-muted">
                            Enter your email for new password
                          </p>
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <span class="input-group-text"
                                ><i class="far fa-user"></i>
                              </span>
                            </div>
    
                            <input
                              type="text"
                              formControlName="emailId"
                              class="form-control"
                              placeholder="     Email"
                              autocomplete="username"
                              required
                            />
                          </div>
                            <mat-error
                            *ngIf="forgotPasswordForm.controls['emailId'].touched && forgotPasswordForm.controls['emailId'].errors?.required">
                            This field is mandatory.
                          </mat-error>
                          <mat-error
                          *ngIf="forgotPasswordForm.controls['emailId'].touched && forgotPasswordForm.controls['emailId'].errors?.email">
                          Please enter valid Email.
                        </mat-error>
                        <mat-error
                        *ngIf="forgotPasswordForm.controls['emailId'].touched && forgotPasswordForm.controls['emailId'].errors?.minlength">
                        Please enter strong Email.
                      </mat-error> -->
                    
                    <div class="row mb-3 mt-1 px-3"> 
                        <button type="submit"  type="button" (click)="submit();" class="btn btn-blue text-center mr-4">Reset Password</button> 
                        <button type="button"  (click)="redirect('login')" class="btn btn-blue text-center">Back to login</button>
                    </div>
                    <!-- <div class="row mb-4 px-3"> <button type="button"  (click)="redirect('login')" class="btn btn-blue text-center">
                        Back to login
                       </button> </div> -->
                </div>
            </div>
        </div>
        
    </div>
    </form>
</div>