import {
  Component,
  OnInit,
  VERSION,
  ElementRef,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
// import { Calendar, CalendarOptions } from '@fullcalendar/angular';
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import { MatDialog } from "@angular/material/dialog";
import { AppointmentComponent } from "../appointment/appointment.component";
import { MasterComponent } from "../master/master.component";
import { DatePipe } from "@angular/common";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { ApiServiceService } from "../api-service.service";
import { InventoryMenuComponent } from "../inventory-menu/inventory-menu.component";
import { PopUpPatientComponent } from "../pop-up-patient/pop-up-patient.component";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { PatientSearchPopComponent } from '../patient-search-pop/patient-search-pop.component';
import { PopUpInvoiceComponent } from "../pop-up-invoice/pop-up-invoice.component";
import { PopUpDeviceDataComponent } from "../pop-up-device-data/pop-up-device-data.component";
import { PopUpRepairDataComponent } from "../pop-up-repair-data/pop-up-repair-data.component";
import { PopupChequeDetailsComponent } from "../popup-cheque-details/popup-cheque-details.component";
import { PopupFollowUpComponent } from "../popup-follow-up/popup-follow-up.component";
import { BillingPopupComponent } from "../billing-popup/billing-popup.component";
import { BirthdayStatusComponent } from "../birthday-status/birthday-status.component";
@Component({
  selector: "app-homepage",
  templateUrl: "./homepage.component.html",
  styleUrls: ["./homepage.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HomepageComponent implements OnInit {
  @ViewChild("calendar", { static: true }) calendar: ElementRef<any>;
  name = "Angular " + VERSION.major;
  public variables = ['Patient', 'Invoice', 'Device', 'Repair'];
  public filteredList1 = this.variables.slice();
appointmentlist;
  todaysDate: Date;
  homeForm: FormGroup;
  userPermission: any;
  userType: string;
  dashboardView= true;
  bookAppointmentsView= true;
  billingView= true;
  stockView= true;
  repairComplaintView= true;
  earMouldView= true;
  clinicExpensesView= true;
  haFormView= true;
  settingsView= true;
  todaysAppointmentView= true;
  InquiryView= true;
  customHaFormView= true;
  PlaceOrderView= true;
  courierBookView= true;
  reportView= true;
  chequeView= true;
  serverConfigView= true;
  holidayLeaveView= true;
  PatientView= true;
  noDataCheque= false;
  noFollowup= false;
  noPendingBill= false;
  Issued= false;
  // genericSettingView= true;
  // doctorAvailabilityView= true;
  clinicRef: string;
  branchList: any;
  patientlist: any;
  filteredPatientList: any; 
  branchRefId: string;
  branchRef: string;
  userName: string;
  userImage: string;
  branchData: any;
  greet: string;
  branchData2=[
    {branchName:''},
      ]
  bid: string;
  allColor=[
    // {name:'crimson',value:'#DC143C' },
    // {name:'darkorange',value:'#FF8C00'},
    // {name:'limegreen',value:'#32CD32' },   
    {name:'lightseagreen',value:'#20B2AA' },                     
    {name:'skyblue',value:'#87CEEB' }, 
    {name:'plum',value:'#DDA0DD' }, 
    {name:'hotpink',value:'#FF69B4' }, 
  ]
  paymentHistoryData: any;
  docterLeaveData: any;
  noBirthdayData = false;
  birthdayData: any;
  doctorLeaveView = true;
  chequeListView = true;
  followUpView = true;
  followupData: any[];
  getUnpaidData: any;
  issuedList: any;

  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
    public dialog: MatDialog,
    public datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.todaysDate = new Date();
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    this.userType = sessionStorage.getItem('userType');
    this.clinicRef = sessionStorage.getItem('clinicRef');  
    this.branchRefId = sessionStorage.getItem('branchRef'); 

    this.branchRef = sessionStorage.getItem('branchRef');
    this.userName = sessionStorage.getItem('userName');
    this.userType = sessionStorage.getItem('userType');
    this.userImage = sessionStorage.getItem('userImage');
    
    if(this.userType !== 'Super Admin'){
    this.userPermission.forEach((element) => {
      if(element.pageName == 'Dashboard' && element.view == false)
      {
          this.dashboardView = false;  
      }
      if(element.pageName == 'Book Appointments' && element.view == false)
      {
          this.bookAppointmentsView = false;  
      }  
      if(element.pageName == 'Inquiry' && element.view == false)
      {
          this.InquiryView = false;  
      }   
      if(element.pageName == 'Todays Appointment' && element.view == false)
      {
          this.todaysAppointmentView = false;  
      }
      if(element.pageName == 'Billing' && element.view == false)
      {
          this.billingView = false;  
      }   
      if(element.pageName == 'Stock' && element.view == false)
      {
          this.stockView = false;  
      } 
      if(element.pageName == 'Repair Complaint' && element.view == false)
      {
          this.repairComplaintView = false;  
      }
      if(element.pageName == 'Ear Mould' && element.view == false)
      {
          this.earMouldView = false;  
      }
      if(element.pageName == 'Custom Hearing Aid' && element.view == false)
      {
          this.customHaFormView = false;  
      }
      if(element.pageName == 'HA Form' && element.view == false)
      {
          this.haFormView = false;  
      }
      if(element.pageName == 'Place Order' && element.view == false)
      {
          this.PlaceOrderView = false;  
      }
      if(element.pageName == 'Patient' && element.view == false)
      {
          this.PatientView = false;  
      } 
      if(element.pageName == 'Clinic Expenses' && element.view == false)
      {
          this.clinicExpensesView = false;  
      }
      if(element.pageName == 'Settings' && element.view == false)
      {
          this.settingsView = false;  
      }
      if(element.pageName == 'Inward Outward' && element.view == false)
      {
          this.courierBookView = false;   
      }
      if(element.pageName == 'Report' && element.view == false)
      {
          this.reportView = false;  
      }
      if(element.pageName == 'Cheque List' && element.view == false)
      {
          this.chequeView = false;  
      }
      if(element.pageName == 'Holiday/Leave' && element.view == false)
      {
          this.holidayLeaveView = false;  
      }
      if(element.pageName == 'Server Configuration' && element.view == false)
      {
          this.serverConfigView = false;  
      }
      if(element.pageName == 'Doctor Leave' && element.view == false)
      {
          this.doctorLeaveView = false;  
      } 
      if(element.pageName == 'Cheque List' && element.view == false)
      {
          this.chequeListView = false;  
      }  
      if(element.pageName == 'Follow Up' && element.view == false)
      {
          this.followUpView = false;  
      } 
      
      // if(element.pageName == 'Generic Setting' && element.view == false)
      // {
      //     this.genericSettingView = false;  
      // }
      // if(element.pageName == 'Doctor Availability' && element.view == false)
      // {
      //     this.doctorAvailabilityView = false;  
      // }
      
    });
  } 
  this.homeForm = this.fb.group({
    "patientName": new FormControl(''),  
    "patientRefId": new FormControl(''),
  });
   
  let branchRef= sessionStorage.getItem('branchRef');
    if(this.userType !== 'Super Admin'){
      branchRef = sessionStorage.getItem('branchRef');
    }else{
      branchRef= sessionStorage.getItem('branchRef');
    }
  
    this.fetchBranchList();
    this.fetchPatient();
    if(this.userType !== 'Super Admin')
  {
  this.getBranchName(this.branchRef);
  }
  else
  {
    this.branchData = this.branchData2;
  }
  this.greetMessage();
  this.getPatientBirthdayData();
  this.getPaymentHistoryDataByChequeDate();
  this.getFollowUpData();
  this.getBill();
  this.getHATrailIssued();
}
calender()
{
  var calendarEl = this.calendar.nativeElement;
  var calendar = new Calendar(calendarEl, {
    dateClick: (arg) => {
      //
      let branchRef= sessionStorage.getItem('branchRef');
      let dddate = new Date(arg.date).setHours(new Date(arg.date).getHours() + 6);
      let datess = new Date(dddate).toISOString();
      this.appointmentlist =[];
        this.apimicroservice.getAppointmentbydatebranch(datess,branchRef).subscribe((res) => {
        if (res.status) {
          let ssss = [];
          if(res.data.length > 0){
            res.data.forEach((element) => { 
              let x = {};
              x["title"] = element.clientName;
              x["start"] = new Date(element.startDateTime);
              x["end"] = new Date(element.endDateTime);
              x["color"] = element.eventColor;
              ssss.push(x);
            });
            this.appointmentlist = ssss;
          }else{
            this.appointmentlist = [{"title": "No Appointments Available","color":"white"}];
          }
        } else {
          this.apimicroservice.openSnackbar(res.message);
        }
      });
    },
    initialView: "dayGridMonth",
    initialDate: new Date(),
    selectable: true,
    plugins: [dayGridPlugin, timeGridPlugin],
    headerToolbar: {
      left: "prev,next",
      center: "title",
      right: "dayGridMonth",
    },
  });
  let currentdate = new Date().toISOString();
  let branchRef= sessionStorage.getItem('branchRef');
    this.apimicroservice.getAppointmentbydatebranch(currentdate,branchRef).subscribe((res) => {
    this.appointmentlist = [];
    if (res.status) {
      let ssss = [];
      if(res.data.length > 0){
        res.data.forEach((element) => {
          let x = {};
          x["title"] = element.clientName;
          x["start"] = new Date(element.startDateTime);
          x["end"] = new Date(element.endDateTime);
          x["color"] = element.eventColor;
          ssss.push(x);
        });
        this.appointmentlist = ssss;
      }else{
        this.appointmentlist = [{"title": "No Appointments Available","color":"white"}];
      }
    } else {
      this.apimicroservice.openSnackbar(res.message);
    }
  });
  calendar.render();
}
greetMessage(){
  let myDate = new Date();
  let hrs = myDate.getHours();
  if (hrs < 12)
      this.greet = 'Good Morning';
  else if (hrs >= 12 && hrs <= 17)
      this.greet = 'Good Afternoon';
  else if (hrs >= 17 && hrs <= 24)
      this.greet = 'Good Evening';
}
getBranchName(id) {
  this.apimicroservice.getBranchById(id).subscribe(res => {
    //
   this.branchData = res.data[0];
   });
}
logout(){
  sessionStorage.removeItem('token');
  sessionStorage.clear();
  localStorage.clear();
}
  handleDateClick(event: any) {
    alert("date click! " + event.title);
  }
  openPopUp() {
    localStorage.clear();
    const dialogRef2 = this.dialog.open(AppointmentComponent);

    // dialogRef2.afterClosed().subscribe((result) => {
    //   //
    // });
  } 
  openMasterPopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(MasterComponent, 
      {
        width: "1400px",
      });
  } 
  openChequeDetailsPopUp(receiptNo,chequeNo) {   
      sessionStorage.setItem('chequeNo',chequeNo);
      sessionStorage.setItem('rNo',receiptNo);
    
    const dialogRef3 = this.dialog.open(PopupChequeDetailsComponent, 
      {
        width: "1400px", 
        data: {
          chequeNo: receiptNo, 
        }
      });
  } 
  openBillingPopupPopUp() { 
   
      const dialogRef2 = this.dialog.open(BillingPopupComponent,
        {
          width: "600px",
          data: {
            patientRefId: '',
            appointId:'',
            patientDetails:'', 
            page:"Billing Page" 
          },
        });   
    
  } 
  openInventoryPopUp() {
    // if(this.branchList >= 1)
    // {
      const dialogRef2 = this.dialog.open(InventoryMenuComponent,
        {
          width: "900px",
        });  
    
    // else{
    //   this.apimicroservice.openSnackbar("This Feature Is Not Available");
    // }
  } 
  openFollowupDataPopUp(followupId) {    
    const dialogRef3 = this.dialog.open(PopupFollowUpComponent,
      { 
        width: "70%", 
        data: {
          patientInfo:'',
          bookDate: '',
          followupId:followupId
        }
      });
      dialogRef3.afterClosed().subscribe((result) => {
       this.getFollowUpData();
  
         });

} 
  fetchBranchList() {
     this.apimicroservice.getBranchByClinicRefId(this.clinicRef).subscribe(res => {
       this.branchList = res.data.length;
     //
     
       if(res.status){
        //  this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
  
     });
   
  }
  workInProgressClick()
  {
    this.apimicroservice.openSnackbar("Work In Progress");
  }
  openPatientPopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpPatientComponent,
      {
        width: "1200px",
      });
  }
  fetchPatient()
{
  let clinicRef= sessionStorage.getItem('clinicRef');
  let branchRef= sessionStorage.getItem('branchRef');
  let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
  let branchRefSuperAdmin= '';
  if (this.userType !== 'Super Admin') {
  this.apimicroservice.getPatientListAll(branchRef,clinicRef).subscribe(res => {
    if (res.status) {
      this.patientlist = res.data.data;
      this.filteredPatientList= this.patientlist.slice();
    }
  });
}else{
  this.apimicroservice.getPatientListAll(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
    if (res.status) {
      this.patientlist = res.data.data;
      this.filteredPatientList= this.patientlist.slice();
    }
  });
}
}
getPaymentHistoryDataByChequeDate()
{
  //let date = this.todaysDate.toDateString();
  const fdateTemp = new Date();
  fdateTemp.setHours(0);
  fdateTemp.setMinutes(0);
  fdateTemp.setSeconds(0);
  fdateTemp.setSeconds(0);
  fdateTemp.setMilliseconds(0);
  let text = fdateTemp.toISOString();
  this.paymentHistoryData=[];
  let arr =[];
  let branchRef= sessionStorage.getItem('branchRef');
  this.apimicroservice.getPaymentHistoryDataByChequeDate(text,branchRef).subscribe(res => {
    if (res.status) { 
      if(res.data.length > 0){
      
      // this.paymentHistoryData=res.data.chequeDetails;
      this.paymentHistoryData= [];
      res.data.forEach((element) => {
        if(element.chequeDetails[0].chequeStatus !== 'Clear')
        {
          this.paymentHistoryData.push(element);  
        }
      });

      //
      this.noDataCheque = false;
      if(res.data.length == 1 && res.data[0].chequeDetails[0].chequeStatus == "Clear")
      {
        this.noDataCheque = true;
      }
    
      }
      else
      {
        this.noDataCheque = true;
      }
       
//       //
//       this.paymentHistoryData.forEach((element) => {
//         //
//         element.chequeDetails[0].forEach((e) => {
//           //
//         });    

// // arr.push(element.chequeDetails);     
// // //
// // //   
//       });
    
    }
  });
}
getFollowUpData()
{
    this.followupData=[];
    let arr =[];
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
    this.apimicroservice.getTodayFollowupAllData(branchRef,clinicRef).subscribe(res => {
      if (res.status) {
        if(res.data.length > 0){
        this.followupData=res.data;
        this.noFollowup = false;
        }
        else
        {
          this.noFollowup = true;
        }
      }
    });
  }else{
    this.apimicroservice.getTodayFollowupAllData(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
      if (res.status) {
        if(res.data.length > 0){
        this.followupData=res.data;
        this.noFollowup = false;
        }
        else
        {
          this.noFollowup = true;
        }
      }
    });
  }
}
getBill()
{
  let status='Unpaid';
  let branchRef= sessionStorage.getItem('branchRef');
  this.apimicroservice.getBill(status,branchRef).subscribe(res => { 
    if (res.status) {
      this.getUnpaidData = res.data.data;
      this.noPendingBill = false;
    } else {
      this.apimicroservice.openSnackbar(res.message);
      this.noPendingBill = true;
    }
  });
}
getHATrailIssued(){
  let search = 'Issued';
  let page = 1;
  let size = 20;
 let branchRef= sessionStorage.getItem('branchRef');
  let clinicRef= sessionStorage.getItem('clinicRef');
  this.apimicroservice.getHAList(page,size,search,branchRef,clinicRef).subscribe(res => {
   if(res.status){ 
    this.issuedList = res.data.data;
    this.Issued = false; 
   }else{
    this.Issued = true;
     //this.apimicroservice.openSnackbar(res.message);
   }
 });
}
getPatientBirthdayData()
{
    this.birthdayData=[];
    let arr =[];
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
    this.apimicroservice.getPatientBirthdate(branchRef,clinicRef).subscribe(res => {
      if (res.status) {
        if(res.data.length > 0){
        this.birthdayData=res.data;
        this.noBirthdayData = false; 
        }
        else
        {
          this.noBirthdayData = true;
        }
      }
    });
  }else{
    this.apimicroservice.getPatientBirthdate(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
      if (res.status) {
        if(res.data.length > 0){
        this.birthdayData=res.data;
        this.noBirthdayData = false; 
        }
        else
        {
          this.noBirthdayData = true;
        }
      }
    });
  }
}
// getDoctorLeave()
// {
//   this.docterLeaveData=[];
//   let arr =[];
//   const fdateTemp = new Date();
//   fdateTemp.setHours(0);
//   fdateTemp.setMinutes(0);
//   fdateTemp.setSeconds(0);
//   fdateTemp.setSeconds(0);
//   fdateTemp.setMilliseconds(0);
  
//   let text = fdateTemp.toISOString();
//   const toDateTemp = new Date();
//   toDateTemp.setDate(toDateTemp.getDate() + 1);
//   toDateTemp.setHours(0);
//   toDateTemp.setMinutes(0); 
//   toDateTemp.setSeconds(0);
//   toDateTemp.setMilliseconds(0);
//   let text2 = toDateTemp.toISOString(); 
//   this.apimicroservice.getAllDoctorLeaveData(text,text2).subscribe(res => {
//     if (res.status) {
//       if(res.data.length > 0){
//       this.docterLeaveData=res.data;
//       //
//       this.noBirthdayData = false; 
//       }
//       else 
//       {
//         this.noBirthdayData = true;
//       }
//     }
//   });
// }

selectPatient(id,fname,lname){
  let payload = this.homeForm.value; 
  payload.patientRefId = id;
  payload.patientName = fname + " " + lname;
  //
  
   this.router.navigate(['/home/patient-global-search'],{ queryParams: { PatientId: id } })
} 
loadpatient(e){ 
  //
  localStorage.setItem("patient",JSON.stringify(e));
   
  if(this.userType == 'Super Admin')
       {
      this.apimicroservice.getBranchById(e.branchRefId).subscribe(res => {   
        let data = res.data[0];
        sessionStorage.setItem('superClinicRefId',res.data[0].clinicRefId);
       });
      } 
      // if(this.CheckupDetailCreate == true)
      // {  
      //   this.redirect('/home/opt');
      // }
      // else
      // {
      //   this.apimicroservice.openSnackbar("Sorry,You Dont Have Permission To Check Details");
      // }
    
}


openDepartmentPopUp(e) {
 //
 if(e == 'Patient')
 {
  localStorage.setItem("fromGlobal",'true');
  const dialogRef3 = this.dialog.open(PatientSearchPopComponent, {
    width: "97%",
    height: "90%", 
  });

  dialogRef3.afterClosed().subscribe((result) => {
    // this.selectPatient(  result.data._id,result.data.firstName,result.data.lastName);
   // this.router.navigate(['/global-dashboard']);
   
    // this.appointmentMaster.get("clientName").setValue(result.data.firstName);
// selectPatient
    // this.selectPatient(
    //   result.data._id,
    //   result.data.firstName,
    //   result.data.lastName,
    //   result.data.patientId
    // );
    // alert("what next ")
  });
 }
 else if(e == 'Invoice')
 {
  const dialogRef3 = this.dialog.open(PopUpInvoiceComponent, {
    width: "70%",
    height: "80%",
  });
  dialogRef3.afterClosed().subscribe((result) => {
  });
 }
 else if(e == 'Device')
 {
  const dialogRef3 = this.dialog.open(PopUpDeviceDataComponent, {
    width: "70%",
    height: "80%",
  });
  dialogRef3.afterClosed().subscribe((result) => {
  });
 }
 else if(e == 'Repair')
 {
  const dialogRef3 = this.dialog.open(PopUpRepairDataComponent, {
    width: "70%",
    height: "80%",
  });
  dialogRef3.afterClosed().subscribe((result) => {
  });
 }
}
birthdayPopup(id)
{
  const dialogRef3 = this.dialog.open(BirthdayStatusComponent, {
     width: "30%",
    // height: "50%",
    data:{
      refId:id
    }
  });
  dialogRef3.afterClosed().subscribe((result) => {
    this.getPatientBirthdayData();
  });
}

}
