<div class="col-sm-12" [formGroup]="repairPopUpForm">
    <div class="row">
       
        <div class="col-sm-5  ">
            <h1 class="titlecolor" >  Search Repair</h1>  
        </div>
        
        <div class="col-sm-6">
            <div class="input-group md-form form-sm form-1 pl-0"> 
              <div class="input-group-prepend">
                <span class="input-group-text purple lighten-3" id="basic-text1"><i class="fas fa-search text-white"
                    aria-hidden="true"></i></span>
              </div>
              <input class="form-control my-0 py-1" type="text" onkeydown="return (event.keyCode!=13);" formControlName="searchRepair"
              aria-label="Search" (keyup)="onKeyUpEvent($event)" placeholder="Search" aria-label="Search">

            </div>
        </div>
        <div class="col-sm-1 text-right pb-2">
          <a (click)="onClose()" matTooltip="Close" class="d-flex align-items-center pr-2 mt-2">
            <span class="material-symbols-outlined pr-2">close</span></a>

      </div>
      <div class="col-sm-12 table-responsive">
        <table id="toRepairList" 
     class="table table-bordered data-table-custom">
          <thead>
            <tr class="text-center">
              <th>Repair ID</th>
              <th>Date</th>
              <th>Patient Name</th>
              <th>Mobile No.</th>
              <th *ngIf="userType == 'Super Admin'">Branch Name</th>
              <th>Left Device</th>
              <th>Right Device</th>
              <th>Track Repair</th>
            </tr>
          </thead>
          <tbody>

            <tr *ngFor="let item of listOfRepair | paginate : { id:'repairpaginate', 
                   itemsPerPage: size, currentPage: page, totalItems:totalLength } " 
              class=" text-center">
              <td  (click)="toPatientForm(item)" matTooltip="click to view"
              >{{item.branchWiseId}}</td>
              <td  (click)="toPatientForm(item)" matTooltip="click to view">{{item.repairDate | date :'d/M/yy'}}</td>
              <td   (click)="toPatientForm(item)"matTooltip="click to view">{{item.firstName}}  {{item.lastName}}</td>
              <td  (click)="toPatientForm(item)" matTooltip="click to view">{{item.mobile1}}</td>
              <td  (click)="toPatientForm(item)" *ngIf="userType == 'Super Admin'" matTooltip="click to view" 
              >{{item.branchName}}</td>
              <td  (click)="toPatientForm(item)" matTooltip="click to view">{{item.leftModel}}-{{item.leftSrNo}}</td>
              <td  (click)="toPatientForm(item)" matTooltip="click to view">{{item.rightModel}}-{{item.rightSrNo}}</td>
              <td (click)="redirectToRepairForm(item._id)"
              matTooltip="click to view" class="repairtb">View Tracking</td> 
            </tr>
          </tbody>
        </table>
        </div>
        <div class="col-sm-12 pt-0 text-right">
            <pagination-controls id="repairpaginate" directionLinks="true" (pageChange)="onPageChange($event)"
            class="float-right pt-3  my-pagination"></pagination-controls>
        
          </div>
    </div>
</div>
