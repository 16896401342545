<div class="container-fluid padding     ">
    <form [formGroup]="LoginForm" autocomplete="off" class="md-float-material form-material">
    <div class="card card0 border-0">
        <div class="row d-flex">
            <div class="col-lg-6">
                <div class="card1 pb-5">
                    <div class="row logo"> 
                        <!-- <img class="pr-3"  width="120rem" src="../../assets/logo-removebg-preview.png"> -->
                        <!-- <img src="../../assets/logo-removebg-preview.png" class="logo" alt=""> -->

                     </div>
                    <div class="row px-3 justify-content-center mt-4 mb-5 border-line"> 
                        <!-- <img class="pr-3"  width="150rem" src="../../assets/audiology.png"> -->
                        <img src="../../assets/audiology2.png" > </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="card2 card border-0 px-4 py-5">
                    <div class="row mb-4 px-3">
                        <!-- <h6 class="mb-0 mr-4 mt-2">Sign in with</h6>
                        <div class="facebook text-center mr-3">
                            <i class="fab fa-facebook-f mt-1"></i>
                        </div>
                        <div class="twitter text-center mr-3">
                            <i class="fab fa-twitter mt-1"></i>
                        </div>
                        <div class="linkedin text-center mr-3">
                            <i class="fab fa-linkedin-in mt-1"></i>
                        </div> -->
                    </div>
                    <!-- <div class="row mb-4">
                        <div class="line"></div> <small class="or text-center">Or</small>
                        <div class="line"></div>
                    </div> -->
                    <div class="row px-3"> <label class="mb-1">
                            <h6 class="mb-0 text-sm">Email Address / User ID</h6>
                        </label> <input class="mb-4" type="text" name="emailId" formControlName="emailId" placeholder="Enter a valid email address or user ID"> 
                        <mat-error *ngIf="LoginForm.controls['emailId'].touched && LoginForm.controls['emailId'].errors?.required">
                            Please Enter Email ID or User ID.
                          </mat-error>
                          <mat-error
                            *ngIf="LoginForm.controls['emailId'].touched && LoginForm.controls['emailId'].errors?.pattern">
                            Please Enter Valid Email ID or User ID.
                          </mat-error></div>
                    <div class="row px-3"> <label class="mb-1">
                            <h6 class="mb-0 text-sm">Password</h6>
                        </label> <input type="password" name="password" formControlName="password" placeholder="Enter password"> 
                        <mat-error *ngIf="LoginForm.controls['password'].touched && LoginForm.controls['password'].errors?.required">
                            Please Enter Password.
                          </mat-error>
                          <mat-error *ngIf="LoginForm.controls['password'].touched && LoginForm.controls['password'].errors?.minlength">
                            Please Enter Minimum 6 Characters.
                          </mat-error>
                        </div>
                    <div class="row ml-2">
                        <div class="custom-control custom-checkbox col-sm-7 "> 
                            <!-- <input id="chk1" type="checkbox" name="chk" class="custom-control-input"> 
                            <label for="chk1" class="custom-control-label text-sm">Remember me</label>  -->
                        </div> 
                        <!-- <div class="col-sm-1"></div> -->
                        <div class="col-sm-5">
                            <a href="/forgot-password" class="text-sm">Forgot Password?</a>
                        </div>
                        <!-- <div class="col-sm-3">
                            <a href="/change-password" class="text-sm">Change Password?</a>
                        </div> -->
                    </div>
                    <div class="row mb-3 mt-1 px-3"> <button type="submit" (click)="Login();" class="btn btn-blue text-center">Login</button> </div>
                    <!-- <div class="row mb-4 px-3"> <small class="font-weight-bold">Don't have an account? <a class="text-secondary">Register</a></small> </div> -->
                </div>
            </div>
        </div>
        <!-- <div class="bg-blue py-4">
            <div class="row px-3"> 
                <div class="col-sm-8">
                    <small class="ml-4 ml-sm-5 mb-2">Copyright &copy; 2019. All rights reserved.</small>
                </div>
                <div class="col-sm-4">
                <div class="social-contact ml-4 ml-sm-auto"> <i class="fab fa-facebook-f mt-1 mr-4 text-sm"></i>  <span class="fa fa-google-plus mr-4 text-sm"></span> <i class="fab fa-google-plus-g mr-4 text-sm"></i> 
                    <i class="fab fa-twitter mt-1 mr-4 mr-sm-5 text-sm"></i> </div>
                    </div>
            </div>
        </div> -->
    </div>
    </form>
</div>