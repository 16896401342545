<div class="container-fluid bgcolor2" [formGroup]="reportForm">
   <form autocomplete="off" [formGroup]="reportForm" novalidate>
     <div class="row">
         <div class="col-sm-1">
 
         </div>
         <div class="col-sm-11">
           <div class="row pl-0 ">
              <div class="col-12 pl-0 ">
                <div class="row ">
                <div class="col-sm-9">
                    <h1 class="pt-2 pl-0" id="toReport">Report</h1>
                    </div>
                    <div class="col-sm-3 mt-1">
                      <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                        <a (click)="onback()" class="d-flex align-items-center pr-2 mt-2
                        no-print"><span class="material-symbols-outlined pr-2
                          ">arrow_back</span>Back</a>
                        <!-- <a (click)="toClinicExpensesList()" class="d-flex align-items-center ms-3 pr-2 mt-2"><span class="material-symbols-outlined pr-2">reorder</span>List</a> -->
                   </div>
              </div>
            </div>
           </div>
           </div>  
          
               <div class="row no-print">
                 <div class="col-sm-12 pr-4">
                     <div class="row">
                         <div class="card divbody mb-3 borderradius p-2">
                            
                                 <div class="row">
                                     
                                       <div class="col-sm-3 ">
                                        
                                      <mat-form-field class="example-full-width " appearance="outline">
                                      
                                        <mat-label>Report Type</mat-label>
                                        <mat-select 
                                          class="colorblack"
                                          formControlName="reportType"
                                        >
  
                                        <!-- <mat-option > Select Report</mat-option> -->
                                        <mat-option (click)="reportClick('1');" [value]="1" >Daily Sales Report</mat-option>
                                        <mat-option (click)="reportClick('2');" [value]="2">Daily Activity Report</mat-option>
                                        <mat-option (click)="reportClick('3');" [value]="3" >Monthly Sales Report</mat-option>
                                        <!-- <mat-option [value]="4" >Flat-Wise Consumption</mat-option> -->
                                        <mat-option (click)="reportClick('4');" [value]="4">Month Till Date OPD Report</mat-option>
                                        
                                        <!-- <mat-option [value]="6" >SPDU Active/In-Active Report</mat-option> -->
                                        
                                        <mat-option [value]="5" (click)="reportClick('5');">Patient Report 1</mat-option>
                                        <!-- <mat-option [value]="6">Patient Report 2</mat-option> -->
                                        <mat-option [value]="7" (click)="reportClick('7');">Stock File Report</mat-option>
                                        <!-- <mat-option [value]="8" (click)="reportClick('8');">Ear Mold</mat-option> -->
                                        
                                        <mat-option [value]="9" (click)="reportClick('9');">Payment Receipt</mat-option>
                                        <mat-option [value]="10" (click)="reportClick('10');">Repair Job Card</mat-option>
                                        <!-- <mat-option [value]="11" (click)="reportClick('11');">Payment List</mat-option> -->
                                        <mat-option [value]="12" (click)="reportClick('12');">Patient List</mat-option>
                                        <mat-option [value]="8" (click)="reportClick('8');">Ear Mold Report</mat-option>
                                        <mat-option [value]="13" (click)="reportClick('13');">Unsold Stock Report</mat-option>
                                        <mat-option [value]="14" (click)="reportClick('14');">Follow Up Report</mat-option>
                                       </mat-select>
                                       
                                     
                                        
                                      </mat-form-field>
                                      <mat-error class="margin_tp"
                                        *ngIf="reportForm.controls['reportType'].touched && reportForm.controls['reportType'].errors?.required">
                                        This field is mandatory.
                                      </mat-error>
                                 
                                  </div>
                                       <div class="col-sm-3" *ngIf="isSuperAdmin && branchCreate == true">
                                           <div class="row">
                                               <div class="col-sm-10 pr-1">
                                             <mat-form-field class="example-full-width" appearance="outline">
                                               <mat-label>Select Branch 
                                                 <!-- <span class="required-asterisk">*</span> -->
                                               </mat-label>
                                               <mat-select #select2 formControlName="branchRefId" >
                                                 <mat-select-filter *ngIf="select2.focused" [placeholder]="'Filter'" [displayMember]="'branchName'"
                                                 [array]="branchList"  (filteredReturn)="filteredListBranch =$event"></mat-select-filter>
                                                 <mat-option *ngFor="let item of filteredListBranch" [value]="item._id" 
                                                 (click)="branchClick(item._id,item.branchName);"
                                                 >
                                                  {{ item.branchName }}</mat-option>
                                                 </mat-select>
                                                
                                             </mat-form-field>
                                             <mat-error class="margin_tp"
                                             *ngIf="reportForm.controls['branchRefId'].touched && reportForm.controls['branchRefId'].errors?.required">
                                             This field is mandatory.
                                           </mat-error>
                                            </div>
                                            <div class="col-sm-2 mTop no-print">
                                               <i (click)="openBranchPopUp();" matTooltip="Add New Branch" class="blue_col mTop fa-solid fa-circle-plus
                                                pl-1 fa-lg"></i>
                                             </div>
                                            
                                            </div>
                                        </div>
                                        <div class="col-sm-3" *ngIf="isSuperAdmin && branchCreate == false">
                                        
                                           <mat-form-field class="example-full-width" appearance="outline">
                                             <mat-label>Select Branch 
                                               <!-- <span class="required-asterisk">*</span> -->
                                             </mat-label>
                                             <mat-select #select2 formControlName="branchRefId" >
                                               <mat-select-filter *ngIf="select2.focused" [placeholder]="'Filter'" [displayMember]="'branchName'"
                                               [array]="branchList"  (filteredReturn)="filteredListBranch =$event"></mat-select-filter>
                                               <mat-option *ngFor="let item of filteredListBranch" [value]="item._id" 
                                               (click)="branchClick(item._id,item.branchName);"
                                               >
                                                {{ item.branchName }}</mat-option>
                                               </mat-select>
                                              
                                           </mat-form-field>
                                           <mat-error class="margin_tp"
                                           *ngIf="reportForm.controls['branchRefId'].touched && reportForm.controls['branchRefId'].errors?.required">
                                           This field is mandatory.
                                         </mat-error>
                                          
                                      </div>
                                      
                                  <div class="col-sm-3">
                                   <div class="row">
                                     <div class="col-sm-10 pr-1">
                                   <!-- <mat-form-field class="example-full-width" appearance="outline">
                                       <mat-label>Patient Name</mat-label>
                                       
                                       <mat-select #select2 (click)="getAppPatient();" formControlName="patientName">
                                         <mat-select-filter *ngIf="select2.focused" [placeholder]="'Filter'" [displayMember]="'firstName'"
                                         [array]="patientList"  (filteredReturn)="filteredPatientList =$event"></mat-select-filter>
                                         <mat-option  *ngFor="let row of filteredPatientList" [value]="row._id" (click)="pName(row.firstName,row.lastName,row._id);">{{row.firstName}}</mat-option>
                                       </mat-select>
                                     </mat-form-field>  -->
                                      <mat-form-field class="example-full-width" appearance="outline">
                           <mat-label>Select Patient </mat-label>
                           <input  (click)="getAppPatient();" (click)="openDepartmentPopUp();" matInput readonly
                             placeholder="Patient Name" formControlName="patientNm" />
                           <!-- <mat-select #select3 formControlName="patientRefId">
                             <mat-select-filter *ngIf="select3.focused" [placeholder]="'Filter'" [displayMember]="'firstName'"
                             [array]="patientList"  (filteredReturn)="filteredListPatient =$event"></mat-select-filter>    
                             <mat-option  *ngFor="let item of filteredListPatient" [value]="item._id" (click)="onSelectPatient(item._id)"
                           >
                            {{ item.firstName }}</mat-option>
                           </mat-select> -->
                         </mat-form-field>
                                     <mat-error class="margin_tp"
                                     *ngIf="reportForm.controls['patientNm'].touched && reportForm.controls['patientNm'].errors?.required">
                                     This field is mandatory.
                                   </mat-error>
                                   </div>
             
                                      <div *ngIf="PatientCreate" class="col-sm-2 mTop no-print">
                                         <i (click)="openPatientPopUp();" matTooltip="Add New Patient" class="blue_col mTop fa-solid fa-circle-plus
                                          pl-1 fa-lg"></i>
                                     </div> 
                                   </div>
                                 </div>
                                 <div class="col-sm-3 " *ngIf="option13Show">
                                    <mat-form-field *ngIf="submitButton" class=" widthsize2 pr-2 pl-2 pb-0"
                                    appearance="outline">
                                    <mat-label>Model </mat-label>
                                    <mat-select #select4 formControlName="deviceRefId">
                                      <mat-select-filter *ngIf="select4.focused" [placeholder]="'Filter'" [displayMember]="'model'"
                                        [array]="DeviceList2" (filteredReturn)="filteredListDevice2 =$event"></mat-select-filter>
            
                                      <mat-option *ngFor="let item of filteredListDevice2" 
                                        [value]="item._id">
                                        {{ item.model }}</mat-option>
            
                                    </mat-select>
            
                                  </mat-form-field>
                                 </div>
                                     <div class="col-sm-3 " *ngIf="showFromDate">
                                      <mat-form-field class="example-full-width " appearance="outline">
                                         <mat-label>From Date</mat-label>
                                         <input matInput  [max]="currentdate"   [matDatepicker]="fdate" placeholder="Choose a date" 
                                         formControlName="fdate" (dateChange)="toOpenDateChnage();">
                          
                                          <mat-datepicker-toggle matSuffix [for]="fdate">
                                          </mat-datepicker-toggle>
                                          <mat-datepicker #fdate></mat-datepicker>
                                        
                                     </mat-form-field>

                                         <!-- <mat-form-field class="example-full-width " appearance="outline">
                                             <mat-label>From Date</mat-label>
                                             <input matInput  [max]="currentdate"  [matDatepicker]="date" placeholder="Choose a date" 
                                             formControlName="fdate">
                              
                                              <mat-datepicker-toggle matSuffix [for]="date">
                                              </mat-datepicker-toggle>
                                              <mat-datepicker #date></mat-datepicker>
                                            
                                         </mat-form-field> -->
                                         <mat-error
                                         *ngIf="reportForm.controls['fdate'].touched && reportForm.controls['fdate'].errors?.required">
                                         This field is mandatory.
                                       </mat-error>
                                     </div>
                                     <div class="col-sm-3 " *ngIf="showToDate">
                                       <mat-form-field class="example-full-width " appearance="outline">
                                           <mat-label>To Date</mat-label>
                                           <input matInput  [max]="currentdate"   [matDatepicker]="todate" placeholder="Choose a date" 
                                           formControlName="todate" (dateChange)="toCloseDateChnage();">
                            
                                            <mat-datepicker-toggle matSuffix [for]="todate">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #todate></mat-datepicker>
                                          
                                       </mat-form-field>
                                       <!-- <mat-error
                                       *ngIf="reportForm.controls['todate'].touched && reportForm.controls['todate'].errors?.required">
                                       This field is mandatory.
                                     </mat-error> -->
                                   </div>
                                   <div class="col-sm-3 " *ngIf="showMonth">
                                      <mat-form-field class="example-full-width " appearance="outline">
                                          <mat-label>Select Month</mat-label>
                                          <mat-select #select3 formControlName="monthName" >
                                            <mat-select-filter *ngIf="select3.focused" [placeholder]="'Filter'" [displayMember]="'monthName'"
                                            [array]="monthList"  (filteredReturn)="filteredListMonth =$event"></mat-select-filter>
                                            <mat-option *ngFor="let item of filteredListMonth" [value]="item.val" 
                                            (click)="monthSelect(item.val)"
                                            >
                                             {{ item.monthName }}</mat-option>
                                            </mat-select>
                                         
                                      </mat-form-field>
                                      <mat-error
                                      *ngIf="reportForm.controls['monthName'].touched && reportForm.controls['monthName'].errors?.required">
                                      This field is mandatory.
                                    </mat-error> 
                                  </div>

                                  <div class="col-sm-3" *ngIf="modeOfPayment" >
                                  <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>Mode Of Payment</mat-label>
      
                                    <mat-select placeholder="Payment Mode" class="colorblack" 
                                    formControlName="modeOfPayment" >
                                 <mat-option value="Cash">Cash</mat-option>
                                 <mat-option value="Card">Card</mat-option>
                                 <mat-option value="UPI">UPI</mat-option>
                                 <mat-option value="Cheque">Cheque</mat-option>
                                 <mat-option value="RTGS/NEFT/IMPS">RTGS/NEFT/IMPS</mat-option>
                                    </mat-select> 
                           </mat-form-field>
                           </div>
                                  <div class="col-sm-3" *ngIf="option13Show" >
                                        
                                   <mat-form-field class="example-full-width" appearance="outline">
                                     <mat-label>Select HA Company 
                                       <!-- <span class="required-asterisk">*</span> -->
                                     </mat-label>
                                     <mat-select #select22 formControlName="HaCompany" >
                                       <mat-select-filter *ngIf="select22.focused" [placeholder]="'Filter'" [displayMember]="'companyName'"
                                       [array]="HAcompany"  (filteredReturn)="filteredHACompanyList =$event"></mat-select-filter>
                                       <mat-option *ngFor="let item of filteredHACompanyList" [value]="item._id"
                                       >
                                        {{ item.companyName }}</mat-option>
                                       </mat-select>
                                      
                                   </mat-form-field>
                                   <mat-error class="margin_tp"
                                   *ngIf="reportForm.controls['HaCompany'].touched && reportForm.controls['HaCompany'].errors?.required">
                                   This field is mandatory.
                                 </mat-error>
                                  
                              </div>
                                    
                               
                               
                               <div class="col-sm-3">
                                <div class="row">
                                  <div class="col-sm-10 pr-1">
                                <mat-form-field hidden class="example-full-width" appearance="outline">
                                    <mat-label></mat-label>
                                    
                                    <mat-select #select4 >
                                      <mat-select-filter ></mat-select-filter>
                                      
                                    </mat-select>
                                  </mat-form-field>
                                 
                                </div>
                                
                                </div>
                              </div>
                              <div *ngIf="showMonth" class="col-sm-8"></div>
                              <div *ngIf="userType !== 'Super Admin'" class="col-sm-10"></div>
                              <div *ngIf="!isSubmit" class="col-sm-10"></div>
                              <div *ngIf="isSubmit" class="col-sm-8"></div>
                                  <!-- <button  class="btn btn-primary btn-sm example-full-width" (click)="export()" 
                                   type="button">Export</button>  -->
                                   <div *ngIf="isSubmit" class="col-sm-1 mb-2 mt-2 ">
                                    <button class="btn btn-primary btn-sm example-full-width" type="button" 
                                    (click)="export();">Export Excel</button>
                                  </div> 
                              <div *ngIf="isSubmit" class="col-sm-1 mb-2 mt-2 ">
                                <button class="btn btn-primary btn-sm example-full-width" type="button" 
                                (click)="globalPrint();">Generate Pdf</button>
                              </div> 
                              <!-- <div class="col-sm-1 mb-2 mt-2" *ngIf="option1">
                                 <button class="btn btn-primary btn-sm example-full-width"  type="button" (click)="download1()">Download</button>
                              </div>
                              <div class="col-sm-1 mb-2 mt-2" *ngIf="option13">
                                 <button class="btn btn-primary btn-sm example-full-width"  type="button" (click)="download13()"> Download </button>
                               </div>
                               <div class="col-sm-1 mb-2 mt-2" *ngIf="option3">
                                 <button class="btn btn-primary btn-sm example-full-width"  type="button" (click)="download3()"> Download  </button>
                              </div>
                             <div class="col-sm-1 mb-2 mt-2" *ngIf="option4">
                               <button type="button" class="btn btn-primary btn-sm example-full-width" (click)="download4()"> Download  </button>
                             </div>
                             <div class="col-sm-1 mb-2 mt-2" *ngIf="option7">
                               <button type="button" class="btn btn-primary btn-sm example-full-width" (click)="download()"> Download  </button>
                              </div>
                             <div class="col-sm-1 mb-2 mt-2" *ngIf="option9">
                               <button type="button" class="btn btn-primary btn-sm example-full-width" (click)="download9()"> Download  </button>
                               </div>
                             
                               <div class="col-sm-1 mb-2 mt-2" *ngIf="option12">
                                  <button type="button" class="btn btn-primary btn-sm example-full-width" (click)="download12()"> Download  </button>
                               </div> -->
                                     <div class="col-sm-1 mb-2 mt-2" *ngIf="submitButton && clinicExpensesCreate"> 
                                         <button type="button" class="btn btn-primary btn-sm example-full-width" (click)="submitClinicExpenses()">Submit </button>
 
                                     </div>
                                     <div class="col-sm-1 mb-2 mt-2">
                                      <button class="btn btn-primary btn-sm example-full-width"  (click)="clear()" type="reset">Cancel</button>
                                      </div>
                                    
                                   
                                 </div>
                           
                         </div>
                     </div>
                 </div>
             </div>
             <div *ngIf="isSubmit" class="row">
              <div class="col-sm-12 pr-4">
                  <div class="row">
                      <div class="card divbody col-sm-12 mb-3 borderradius p-2">
                          
                               <div class="row col-sm-12 scroll" >
                               
                                 <h1 class="col-sm-10" *ngIf="option1"> <span > Daily Sales Report
                                    </span><hr></h1>
                                  <h1 class="col-sm-12" *ngIf="option2"> <span > Daily Activity Report</span><hr></h1> 
                                  <h1 class="col-sm-10" *ngIf="option3"> <span> Monthly Sales Report
                                   </span><hr></h1> 
                                  <h1 class="col-sm-10" *ngIf="option4"> <span> Month Till Date OPD Report
                                   </span><hr></h1>
                                  
                                  <h1 class="col-sm-12" *ngIf="option5"> <span> Patient Report 1
                                   
                                  </span><hr></h1>
                                  <!-- <h1 class="col-sm-12" *ngIf="option6"> <span> Patient Report 2</span><hr></h1> -->
                                  <h1 class="col-sm-10" *ngIf="option7"> <span> Stock File Report</span>
                                    <hr></h1> 
                                  <h1 class="col-sm-12" *ngIf="option8"> <span> Ear Mold</span><hr></h1>
                                  <h1 class="col-sm-10" *ngIf="option9"> <span> Payment Receipt
                                    </span><hr></h1>
                                  <h1 class="col-sm-12" *ngIf="option10"> <span> Repair Job Card</span><hr></h1>
                                  <h1 class="col-sm-10" *ngIf="option11"> <span>Daily Payment Report</span><hr></h1>
                                  <h1 class="col-sm-10" *ngIf="option13"> <span>Unsold Stock Report
                                    </span><hr></h1>
                                    <h1 class="col-sm-10" *ngIf="option14"> <span>Follow Up Report
                                    </span><hr></h1>
                                  <div class="col-sm-2" *ngIf="option11">
                                   <!-- <button  class="btn btn-primary btn-sm example-full-width" (click)="export()" 
                                   type="button">Export</button>  -->
                                 </div>
                                 <h1 class="col-sm-12" *ngIf="option12"> <span>Patient List
                                  
                                 </span><hr></h1>
                                 <div class="col-sm-2" *ngIf="option12">
                                   <!-- <button  class="btn btn-primary btn-sm example-full-width" (click)="export2()" 
                                   type="button">Export</button>  -->
                                 </div>
                               
                                 
                                 
                                   <table *ngIf="option1" id="toClinicExpensesList"
                                    class="table table-bordered data-table-custom" 
                                >
                                       <thead  >
                                         <tr  >
                                               <th class="text-center"> Sr.No</th>
                                               <th class="text-left">Patient Name</th>
                                               <!-- <th>Audiologist Name</th> -->
                                               <th>Bill No</th>
                                               <!-- <th>Referring Doc</th> -->
                                               <th>Mode Of Payment</th>
                                               <th>Billing Company</th>
                                               <th>Payment Status</th>
                                               <th>Total Amt (Rs.)</th>
                                               <th >Cash (Rs.)</th>
                                               <th>Non Cashh (Rs.)</th>
                                               <th>Amt Collected (Rs.)</th>
                                           
                                               
                                              
                                           </tr>

                                         
                                       </thead>
                                       <tbody *ngIf="getFirstReport.length > 0;else salecond">
                                         <tr *ngFor="
                                         let data of getDailySalesAll let i = index">
                                         <!-- <tr *ngFor="
                                         let data of getDailySalesAll
                                         | paginate : { id:'option1_pagination', 
                                         itemsPerPage: size, currentPage: page, totalItems:totalLength }
                                         let i = index"> -->
                                         
                                         <td class="text-center">{{i + 1}}</td>
                                         <td class="text-left"> {{data.patientName}}</td>
                                         <td>{{data.billNo}}</td>
                                         <td class="text-center">{{data.modeOfPayment}}</td>
                                         <td>{{data.billingCompanyName}}</td>
                                         <td class="labelStyle text-center" matTooltip="Click To View">
                                          <label *ngIf="data.paidAmount !== data.totalAmount; else elseBlock11"
                                          class="red">Unpaid</label>
                                          <ng-template #elseBlock11>
                                          <label *ngIf="data.paidAmount == data.totalAmount; else elseBlock22"
                                          class="green">Paid</label>
                                        </ng-template> 
                                        <ng-template #elseBlock22>
                                          <label>-</label>
                                        </ng-template> 

                                        </td>

                                         <td  class="textRight"> {{data.totalAmount | number : '1.2-2'}}</td>
                                         <td class="textRight" matTooltip="Click To View" >
                                          <label *ngIf="data.modeOfPayment == 'Cash'; else elseBlock12">
                                             {{data.paidAmount | number : '1.2-2'}}
                                          </label>
                                          <ng-template #elseBlock12>
                                          <label>-</label>
                                        </ng-template> 
                                          </td>
                                          <td class="textRight">
                                             <label *ngIf="data.modeOfPayment == 'Cheque'; else elseBlock13">
                                                {{data.pendingChequeAmount | number : '1.2-2'}}</label>
                                             <ng-template 
                                             #elseBlock13>
                                             <label *ngIf="data.modeOfPayment == 'UPI' || data.modeOfPayment == 'Card'
                                             || data.modeOfPayment == 'RTGS/NEFT/IMPS'; else elseBlock14">
                                             {{data.paidAmount | number : '1.2-2'}}</label>
                                           </ng-template> 
                                           <ng-template #elseBlock14>
                                             <label >-</label>
                                           </ng-template> 
                                        
                                         
                                       </td>

                                         <td class="textRight">{{data.paidAmount | number : '1.2-2'}}</td>
                                     
                                         
                                         </tr>
                                      </tbody>
                                      <tbody *ngIf="getFirstReport.length > 0;else salecond">
                                       <tr *ngFor="
                                       let data of getDailySalesTotal let i = index">
                                      
                                       <td class="text-center">{{data.f1}}</td>
                                       <td class="text-right">{{data.f2}}</td>
                                       <td class="text-right">{{data.f3}}</td>
                                       <td class="text-right">{{data.f4}}</td>
                                       <td class="text-right">{{data.f5}}</td>
                                       <td class="text-right">{{data.f6}}</td>
                                       <td class="text-right">{{data.f7}}</td>
                                       <td class="text-right">{{data.f8}}</td>
                                       <td class="text-right">{{data.f9}}</td>
                                       <td class="text-right">{{data.f10}}</td>
                                       </tr>

                                   </table>
                                   <tbody><ng-template #salecond><td  colspan="10">
                                      <p style="text-align: center;">No Data Available</p>
                                   </td></ng-template> </tbody>
                                   <!-- <div  *ngIf="option1" class="col-sm-12 pt-0 text-right">
                                      <pagination-controls id="option1_pagination" directionLinks="true"
                                      (pageChange)="onPageChange($event)" class="float-right pagination no-print my-pagination"></pagination-controls>
                                  </div> -->
                                    <!-------------------------------------Report 2 ----------------------------------->
                                    <!-------------------------------------Report 2 ----------------------------------->
                                    <!-------------------------------------Report 2 ----------------------------------->
                                 
                                   <table class="table table-bordered data-table-custom  text-center col-sm-12"  *ngIf="option2"  style="border-collapse:collapse;margin-left:6.02581pt" cellspacing="0">
                                     <tr >
                                       <td style=" border-right: 0px solid #dee2e6;"  colspan="2">
                                          <p   >DAILY ACTIVITY REPORT FOR</p>
                                       </td>
                                       <td  colspan="2">
                                         <p  style="text-align: center; font-size: medium; font-weight: bold;" font-size>{{reportName}}</p>
                                      </td>
                                      <td style=" border: 0px px solid #dee2e6;"  colspan="1">
                                       <p style="text-align: right;">As On: {{myDate | date:'medium'}}</p>
                                    </td>
                                    </tr>
                                     <tr>
                                        <td  colspan="5">
                                           <p  style="text-align: center;"><b>Inquiries</b></p>
                                        </td>
                                     </tr>
                                     <tr >
                                        <td  colspan="5">
                                           <p ><b>{{reportName}}</b></p>
                                        </td>
                                     </tr>
                                     <tr >
                                        <td >
                                           <p ><label class="header2">Sr.No</label></p>
                                        </td>
                                        <td >
                                           <p ><label class="header2">Patient Name</label></p>
                                        </td>
                                        <td>
                                           <!-- <p><label class="header2">Ref. Doc.</label></p> -->
                                           <p ><label class="header2">Inquiry Type</label></p>
                                        </td>
                                        <td >
                                           <!-- <p ><label class="header2">Test Reff.</label></p> -->
                                           <p ><label class="header2">Phone Number</label></p>
                                        </td>
                                        <td >
                                           <!-- <p ><label class="header2">Status</label></p> -->
                                           <p ><label class="header2">Address</label></p>
                                        </td>
                                     </tr>
                                     <tbody *ngIf="enquiryData.length > 0;else enqcond">
                                     <tr  *ngFor="
                                     let data of enquiryData
                                     | paginate : { id:'enquiry_pagination', 
                                     itemsPerPage: size, currentPage: pageEnquiry, totalItems:totalLengthEnquiry }
                                     let i = index">
                                     <td>{{i + 1}}</td>
                                     <td>{{enquiryData[i].firstName}} {{enquiryData[i].lastName}}</td>
                                     <td>{{enquiryData[i].inquiryType}} </td>
                                     <td>{{enquiryData[i].mobile}} </td>
                                     <td>{{enquiryData[i].streetAddress}} </td>
                                        <!-- <td  colspan="5">
                                           <p style="text-align: center;">No Data Available</p>
                                        </td> -->
                                     </tr>
                                   </tbody>
                                   <tbody><ng-template #enqcond><td  colspan="5">
                                      <p style="text-align: center;">No Data Available</p>
                                   </td></ng-template> </tbody>
                                   
                                      
                                     
                                     <tr >
                                        <td  colspan="5">
                                           <p ><b>CHRC</b></p>
                                        </td>
                                     </tr>
                                     <tr>
                                        <td >
                                           <p ><label class="header2"> Sr.No</label></p>
                                        </td>
                                        <td >
                                           <p ><label class="header2">Patient Name</label></p>
                                        </td>
                                        <td >
                                           <p ><label class="header2">Ref. Doc.</label></p>
                                        </td>
                                        <td >
                                           <p ><label class="header2">Test Reff.</label></p>
                                        </td>
                                        <td >
                                           <p ><label class="header2">Status</label></p>
                                        </td>
                                     </tr>
                                     <tr >
                                        <td colspan="5">
                                           <p style="text-align: center;">No Data Available</p>
                                        </td>
                                     </tr>
                                  </table>
                                  <div  *ngIf="option2" class="col-sm-12 pt-0 text-right">
                                   <pagination-controls id="enquiry_pagination" directionLinks="true"
                                   (pageChange)="onPageChange($event)" class="float-right pagination no-print my-pagination"></pagination-controls>
                               </div>
                                  <table class="table table-bordered"  *ngIf="option2"  style="border-collapse:collapse;margin-left:6.02581pt" cellspacing="0">
                                   
                                   <tr>
                                      <td  colspan="5">
                                         <p  style="text-align: center;"><b>Appointments</b></p>
                                      </td>
                                   </tr>
                                   <tr >
                                      <td  colspan="5">
                                         <p ><b>{{reportName}}</b></p>
                                      </td>
                                   </tr>
                                   <tr >
                                      <td >
                                         <p ><label class="header2">Sr.No</label></p>
                                      </td>
                                      <td >
                                         <p ><label class="header2">Patient Name</label></p>
                                      </td>
                                      <td>
                                         <p><label class="header2">Ref. Doc.</label></p>
                                      </td>
                                      <td >
                                         <p ><label class="header2">Test Reff.</label></p>
                                      </td>
                                      <td >
                                         <p ><label class="header2">Status</label></p>
                                      </td>
                                   </tr>
                                   <tbody *ngIf="appointmentData.length > 0;else appcond">
                                      <tr *ngFor="
                                      let data of appointmentData
                                      | paginate : { id:'appointments_pagination', 
                                      itemsPerPage: size, currentPage: pageAppoint, totalItems:totalLengthAppointment }
                                      let i = index">
                                         <td>{{i + 1}}</td>
                                         <td>{{appointmentData[i].clientName}}</td>
                                         <td>{{appointmentData[i].doctorName}}</td>
                                         <td>{{appointmentData[i].AppoinmentType}}</td>
                                         <td>N/A</td>
                                         <!-- <td>{{appointmentData[i].aptStatus}}</td> -->
                                      </tr>
                                      
                                   </tbody>
                                   <tbody><ng-template #appcond><td  colspan="5">
                                      <p style="text-align: center;">No Data Available</p>
                                   </td></ng-template> </tbody>
                                   <tr >
                                      <td  colspan="5">
                                         <p ><b>CHRC</b></p>
                                      </td>
                                   </tr>
                                   <tr>
                                      <td >
                                         <p ><label class="header2">Sr.No</label></p>
                                      </td>
                                      <td >
                                         <p ><label class="header2">Patient Name</label></p>
                                      </td>
                                      <td >
                                         <p ><label class="header2">Ref. Doc.</label></p>
                                      </td>
                                      <td >
                                         <p ><label class="header2">Test Reff.</label></p>
                                      </td>
                                      <td >
                                         <p ><label class="header2">Status</label></p>
                                      </td>
                                   </tr>
                                   <tr >
                                      <td colspan="5">
                                         <p style="text-align: center;">No Data Available</p>
                                      </td>
                                   </tr>
                                </table>
                                <div *ngIf="option2" class="col-sm-12 pt-0 text-right">
                                   <pagination-controls id="appointments_pagination" directionLinks="true"
                                   (pageChange)="onPageChangeAppoint($event)" class="float-right pagination no-print my-pagination"></pagination-controls>
                               </div>
                                <table class="table table-bordered"  *ngIf="option2"  style="border-collapse:collapse;margin-left:6.02581pt" cellspacing="0">
                                   
                                 <tr>
                                    <td  colspan="5">
                                       <p  style="text-align: center;"><b>Patient Registration </b></p>
                                    </td>
                                 </tr>
                                 <tr >
                                    <td  colspan="5">
                                       <p ><b>{{reportName}}</b></p>
                                    </td>
                                 </tr>
                                 <tr >
                                    <td >
                                       <p ><label class="header2">Sr.No</label></p>
                                    </td>
                                    <td >
                                       <p ><label class="header2">Patient Name</label></p>
                                    </td>
                                    <td>
                                       <p><label class="header2">Ref. Doc.</label></p>
                                    </td>
                                    <td >
                                       <p ><label class="header2">Test Reff.</label></p>
                                    </td>
                                    <td >
                                       <p ><label class="header2">Status</label></p>
                                    </td>
                                 </tr>
                                 <tbody *ngIf="patientReg.length > 0;else ptntcond">
                                   <tr *ngFor="
                                   let data of patientReg
                                   | paginate : { id:'patient_pagination', 
                                   itemsPerPage: size, currentPage: pagePatient, totalItems:totalLengthPatient }
                                   let i = index">
                                      <td>{{i + 1}}</td>
                                      <td>{{patientReg[i].firstName}} {{patientReg[i].lastName}}</td>
                                      <td>N/A</td>
                                      <td>N/A</td>
                                      <td>N/A</td>
                                   </tr>
                                   
                                </tbody>
                                <tbody><ng-template #ptntcond><td  colspan="5">
                                   <p style="text-align: center;">No Data Available</p>
                                </td></ng-template> </tbody>
                                 <tr >
                                    <td  colspan="5">
                                       <p ><b>CHRC</b></p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td >
                                       <p ><label class="header2">Sr.No</label></p>
                                    </td>
                                    <td >
                                       <p ><label class="header2">Patient Name</label></p>
                                    </td>
                                    <td >
                                       <p ><label class="header2">Ref. Doc.</label></p>
                                    </td>
                                    <td >
                                       <p ><label class="header2">Test Reff.</label></p>
                                    </td>
                                    <td >
                                       <p ><label class="header2">Status</label></p>
                                    </td>
                                 </tr>
                                 <tr >
                                    <td colspan="5">
                                       <p style="text-align: center;">No Data Available</p>
                                    </td>
                                 </tr>
                              </table>
                              <div *ngIf="option2" class="col-sm-12 pt-0 text-right">
                                <pagination-controls id="patient_pagination" directionLinks="true"
                                (pageChange)="onPageChangePatient($event)" class="float-right pagination no-print my-pagination"></pagination-controls>
                            </div>
                            <!-- <div *ngIf="option2" class="col-sm-12 pt-0 text-right">
                             <pagination-controls id="patient_pagination" directionLinks="true"
                             (pageChange)="onPageChange($event)" class="float-right pagination no-print my-pagination"></pagination-controls>
                         </div> -->
                              <table class="table table-bordered"  *ngIf="option2"  style="border-collapse:collapse;margin-left:6.02581pt" cellspacing="0">
                                   
                               <tr>
                                  <td  colspan="5">
                                     <p  style="text-align: center;"><b>Repair Complaint</b></p>
                                  </td>
                               </tr>
                               <tr >
                                  <td  colspan="5">
                                     <p ><b>{{reportName}}</b></p>
                                  </td>
                               </tr>
                               <tr >
                                  <td >
                                     <p ><label class="header2">Sr.No</label></p>
                                  </td>
                                  <td >
                                     <p ><label class="header2">Patient Name</label></p>
                                  </td>
                                  <td>
                                     <p><label class="header2">Ref. Doc.</label></p>
                                  </td>
                                  <td >
                                     <p ><label class="header2">Test Reff.</label></p>
                                  </td>
                                  <td >
                                     <p ><label class="header2">Status</label></p>
                                  </td>
                               </tr>
                               <tbody *ngIf="repairDetails.length > 0;else reprcond">
                                <tr *ngFor="
                                let data of repairDetails
                                | paginate : { id:'repairDetails_pagination', 
                                itemsPerPage: size, currentPage: pageRepair, totalItems:totalLengthrepairDetails }
                                let i = index">
                                   <td>{{i + 1}}</td>
                                   <td>{{repairDetails[i].firstName}} {{repairDetails[i].lastName}}</td>
                                   <td>N/A</td>
                                   <td>N/A</td>
                                   <td>N/A</td>
                                </tr>
                                
                             </tbody>
                             <tbody><ng-template #reprcond><td  colspan="5">
                                <p style="text-align: center;">No Data Available</p>
                             </td></ng-template> </tbody>
                               <tr >
                                  <td  colspan="5">
                                     <p ><b>CHRC</b></p>
                                  </td>
                               </tr>
                               <tr>
                                  <td >
                                     <p ><label class="header2">Sr.No</label></p>
                                  </td>
                                  <td >
                                     <p ><label class="header2">Patient Name</label></p>
                                  </td>
                                  <td >
                                     <p ><label class="header2">Ref. Doc.</label></p>
                                  </td>
                                  <td >
                                     <p ><label class="header2">Test Reff.</label></p>
                                  </td>
                                  <td >
                                     <p ><label class="header2">Status</label></p>
                                  </td>
                               </tr>
                               <tr >
                                  <td colspan="5">
                                     <p style="text-align: center;">No Data Available</p>
                                  </td>
                               </tr>
                            </table>
                            <!-- <div *ngIf="option2" class="col-sm-12 pt-0 text-right">
                             <pagination-controls id="repairDetails_pagination" directionLinks="true"
                             (pageChange)="onPageChange($event)" class="float-right pagination no-print my-pagination"></pagination-controls>
                         </div> -->
                         <div *ngIf="option2" class="col-sm-12 pt-0 text-right">
                          <pagination-controls id="repairDetails_pagination" directionLinks="true"
                          (pageChange)="onPageChangeRepair($event)" class="float-right pagination no-print my-pagination"></pagination-controls>
                      </div>
                            <table class="table table-bordered"  *ngIf="option2"  style="border-collapse:collapse;margin-left:6.02581pt" cellspacing="0">
                                   
                             <tr>
                                <td  colspan="5">
                                   <p  style="text-align: center;"><b>Earmold</b></p>
                                </td>
                             </tr>
                             <tr >
                                <td  colspan="5">
                                   <p ><b>{{reportName}}</b></p>
                                </td>
                             </tr>
                             <tr >
                                <td >
                                   <p ><label class="header2">Sr.No</label></p>
                                </td>
                                <td >
                                   <p ><label class="header2">Patient Name</label></p>
                                </td>
                                <td>
                                   <p><label class="header2">Ref. Doc.</label></p>
                                </td>
                                <td >
                                   <p ><label class="header2">Test Reff.</label></p>
                                </td>
                                <td >
                                   <p ><label class="header2">Status</label></p>
                                </td>
                             </tr>
                             <tbody *ngIf="earDetails.length > 0;else earcond">
                                <tr *ngFor="
                                let data of earDetails
                                | paginate : { id:'earDetails_pagination', 
                                itemsPerPage: size, currentPage: pageEarmold, totalItems:totalLengthEarMould }
                                let i = index">
                                   <td>{{i + 1}}</td>
                                   <td>{{earDetails[i].firstName}} {{earDetails[i].lastName}}</td>
                                   <td>N/A</td>
                                   <td>N/A</td>
                                   <td>N/A</td>
                                </tr>
                                
                             </tbody>
                          
                             <tbody><ng-template #earcond><td  colspan="5">
                                <p style="text-align: center;">No Data Available</p>
                             </td></ng-template> </tbody>
                             <tr >
                                <td  colspan="5">
                                   <p ><b>CHRC</b></p>
                                </td>
                             </tr>
                             <tr>
                                <td >
                                   <p ><label class="header2">Sr.No</label></p>
                                </td>
                                <td >
                                   <p ><label class="header2">Patient Name</label></p>
                                </td>
                                <td >
                                   <p ><label class="header2">Ref. Doc.</label></p>
                                </td>
                                <td >
                                   <p ><label class="header2">Test Reff.</label></p>
                                </td>
                                <td >
                                   <p ><label class="header2">Status</label></p>
                                </td>
                             </tr>
                             <tr >
                                <td colspan="5">
                                   <p style="text-align: center;">No Data Available</p>
                                </td>
                             </tr>
                          </table>
                          <!-- <div *ngIf="option2" class="col-sm-12 pt-0 text-right">
                             <pagination-controls id="earDetails_pagination" directionLinks="true"
                             (pageChange)="onPageChange($event)" class="float-right pagination no-print my-pagination"></pagination-controls>
                         </div> -->
                         <div *ngIf="option2" class="col-sm-12 pt-0 text-right">
                          <pagination-controls id="earDetails_pagination" directionLinks="true"
                          (pageChange)="onPageChangeEar($event)" class="float-right pagination no-print my-pagination"></pagination-controls>
                      </div>
                          <!-------------------------------------Report 3 ----------------------------------->
                          <!-------------------------------------Report 3 ----------------------------------->
                          <!-------------------------------------Report 3 ----------------------------------->
                          <table *ngIf="option3" id="toClinicExpensesList"
                          class="table table-hover table-striped table-bordered" 
                      >
                             <thead  >
                               <tr>
                                     <th> Sr.No</th>
                                     <th > Patient Name</th>
                                     <th>Bill No</th>
                                      <th>Test Name</th>
                                     <th>No Of Tests</th>
                                     <th class="textRight">Cash (Rs.)</th>
                                     <th>Non Cash (Rs.)</th>
                                     <th>Amt Collected (Rs.)</th>
                                     <th>Total Amt (Rs.)</th>
                                 </tr>

                               
                             </thead>
                          <tbody *ngIf="monthlyData.length > 0;else monthlyDataNoData">
                           <tr *ngFor="
                           let data of monthlyData
                           | paginate : { id:'monthlyData_pagination', 
                           itemsPerPage: size, currentPage: pageSales, totalItems:totalLengthMonthlyData }
                           let i = index" >
                              <td class="text-center">{{i + 1}}</td>
                              <td class="text-left">{{data.patientName}}</td>
                              <td *ngIf="data.billNo !== ''">{{data.billNo}}</td>
                              <td *ngIf="data.billNo == ''">N/A</td>
                              <td><label *ngFor="let ele of data.serviceInfo">
                                 {{ele.serviceName}}
                                 <label *ngIf="data.serviceInfo.length > 1">
                                   ,
                                   </label>
                                </label></td>

                  
                              <td>{{data.serviceInfo.length}}</td>
                              <td class="textRight" >{{data.paidAmount | number : '1.2-2'}}</td>
                             <td>{{data.pendingChequeAmount}}</td>  

                              <td>{{data.paidAmount}}</td>
                              <td>{{data.totalAmount}}</td>
                              
                           </tr>
                           
                        </tbody>
                        <tbody><ng-template #monthlyDataNoData><td  colspan="9">
                          <p style="text-align: center;">No Data Available</p>
                       </td></ng-template> </tbody>
                         </table>
                         <div  *ngIf="option3" class="col-sm-12 pt-0 text-right">
                          <pagination-controls id="monthlyData_pagination" directionLinks="true"
                (pageChange)="onPageChange($event)" class="float-right pagination no-print my-pagination"></pagination-controls>
                  </div>
                          <!-------------------------------------Report 4 ----------------------------------->
                          <!-------------------------------------Report 4 ----------------------------------->
                          <!-------------------------------------Report 4 ----------------------------------->
                          <table *ngIf="option4" id="toClinicExpensesList"
                          class="table table-hover table-striped table-bordered" 
                      >
                      <thead  >
                       <tr  class="text-center">
                                   <th>Sr.No</th>
                                   <th>Patient Name</th>
                                   <th>Date</th>
                                   <th>Followup Date</th>
                                   <th>Tracking Status</th>
                                   <th>Appointment Status</th>
                                   
                                   
                                   </tr>
                    </thead>
                    <tbody *ngIf="monthlyTillDateData.length > 0;else monthlyTillDateNoData">
                             <tr *ngFor="
                             let data of monthlyTillDateData
                             | paginate : { id:'monthlyTillDate_pagination', 
                             itemsPerPage: size, currentPage: page, totalItems:totalLengthMonthlyTillDate }
                             let i = index"
                             class="text-center">
                             <td>{{i + 1}}</td>
                             <td>{{data.patientName}}</td>
                             <td>{{data.appointDate | date:'d MMM y - h:mm a'}}</td>
                             <td>{{data.followupDate | date:'d MMM y - h:mm a'}}</td>
                             <td><label *ngFor="let item of data.trackingStatusName">
                              {{item}}
                              <label *ngIf="data.trackingStatusName.length > 1">
                                ,
                                </label>
                             </label></td>
                             <td>{{data.aptStatus}}</td>
                             </tr>
                             <tbody><ng-template #monthlyTillDateNoData><td  colspan="4">
                                <p style="text-align: center;">No Data Available</p>
                             </td></ng-template> </tbody>
                               </table>
                               <div  *ngIf="option4" class="col-sm-12 pt-0 text-right">
                                <pagination-controls id="monthlyTillDate_pagination" directionLinks="true"
                      (pageChange)="onPageChange($event)" class="float-right pagination no-print my-pagination"></pagination-controls>
                        </div>
                         <!--   -->
                          <!-------------------------------------Report 5 not yet done----------------------------------->
                          <!-------------------------------------Report 5 ----------------------------------->
                          <!-------------------------------------Report 5 ----------------------------------->
                          <div *ngIf="option5" class="row pl-0 ml-0"> 
                             <div class="col-sm-2 text-left">
                                   <img *ngIf="!logoimg" class=" img2 frame rounded-circle " src="../../assets/main.jpeg" alt="Amanda Wilson" width="300rem">
                                   <img *ngIf="logoimg" class=" img2 frame rounded-circle " [src]="logoimg" alt="patient photo" width="300rem">
                               </div>
                               <div class="col-sm-1"></div>
                               <div class="col-sm-6 text-center">
                                <strong><span class="auto-style2 fass">
                                 <label class="pt-0 mt-0" *ngIf="reportName !== ''"> {{reportName}} <br>
                                    {{reportAddress}}, {{taluka}} ,{{district}}, {{state}}, {{country}} - {{reportPinCode}}
                                   <br> Contact No:{{reportPhoneNumber}} <br></label>
                                   <label *ngIf="reportName == '' || reportName == undefined  || reportName == null" class="mt-3"><h1 class="cliniStyle">Clinic Name</h1></label>
                                  <br> <label *ngIf="openTime !== '' && closeTime !== ''">From {{openTime}} To {{closeTime}}</label> <br>
                                 </span></strong>  
                                 
                                  <!-- <label>{{reportName}} <br>
                                   {{reportAddress}}, {{taluka}} ,{{district}}, {{state}}, {{country}} - {{reportPinCode}}
                                  <br> Contact No:{{reportPhoneNumber}} <br>
                                  From {{openTime}} To {{closeTime}}</label> -->
                                  </div>
                                  <div class="col-sm-1"></div>
                                  <div class="col-sm-2 text-right justify-content-end">
                                   <label class="text-right justify-content-end"> Date : {{currentdate | date:'dd/MM/yyyy'}} </label> <br>
                                     </div>
                                     <div class="col-sm-12">
                                      <table
                                      class="table noBorder table-hover table-bordered" >
                                         <thead  class="noBorderTd">
                                            <tr class="noBorderTd">
                                               <th width="33.33%" class="noBorderTd"></th>
                                               <th width="33.33%" class="text-center noBorderTd">AUDIOGRAM</th>
                                               
                                               <th width="33.33%" class="noBorderTd"></th>   
                                      </tr>
                                         </thead>
                                         </table>
                                         </div>
                                     <div class="col-sm-2">
                                      <label class="text-right justify-content-end"> Patient Name : {{patientName}}</label>
                                     </div>
                                     <div class="col-sm-8"></div>
                                     <div class="col-sm-2"> Ref Dr: SELF(.)</div>
                                     <div class="col-sm-2 mt-2">
                                      <label class="text-right justify-content-end"> Age/Gender : {{pAge}}, {{pGender}}</label>
                                     </div>
                                     <div class="col-sm-8"></div>
                                     <div class="col-sm-2 mt-2">   <label class="text-right justify-content-end"> Date : {{currentdate | date:'dd/MM/yyyy'}} </label> </div>
                                       <div class="col-sm-8">
                                         <div class="row"> 
                                            <div class="col-sm-12" >
                                         <canvas baseChart
                                         [datasets]="barChartData"
                                         [labels]="barChartLabels"
                                         [options]="barChartOptions"
                                         [legend]="barChartLegend"
                                         [chartType]="barChartType"
                                         >
                                      </canvas>
                                      </div>
                                      <div class="col-sm-12">
                                         <table
                                         class="table table-hover  table-bordered " >
                                            <thead  >
                                               <tr>
                                                  <th width="33.33%" class="noBorderTd">PTA AVARAGE</th>
                                                  <th width="33.33%" class="noBorderTd">RE:</th>
                                                  <th width="33.33%" class="noBorderTd">LE:</th>   
                                         </tr>
                                            </thead>
                                            </table>
                                        
                                      <!-- <label class="text-center">Webers</label> -->
                                      <div class="row">
                                      <div class=" col-sm-1">
                                         
                                         <div class="row-sm-12 text-center rotate">Special Audiological Test</div>
                                      </div>
                                      <div class=" col-sm-11">
                                         
                                      <table
                                      class="table table-hover col-sm-12  table-bordered" >
                              
                                         <thead  >
                                            <tr class="text-center">
                                               <th></th>
                                               <th></th>
                                               <th>500Hz</th>
                                               <th>1000Hz</th>
                                               <th>2000Hz</th>
                                               <th>4000Hz</th>
                                      </tr>
                                         </thead>
                                         <tbody>
                                            <tr>
                                              <th rowspan="3">RIGHT EAR</th>
                                              <td>TDT</td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                            </tr>
                                            <tr>
                                              
                                               <td>SISI</td>
                                               <td></td>
                                               <td></td>
                                               <td></td>
                                               <td></td>
                                             </tr>
                                             <tr>
                                   
                                              <td>STAT</td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                            </tr>
                                            </tbody>
                                            <tbody>
                                               <tr>
                                                 <th rowspan="3">LEFT EAR</th>
                                                 <td>TDT</td>
                                                 <td></td>
                                                 <td></td>
                                                 <td></td>
                                                 <td></td>
                                               </tr>
                                               <tr>
                                                 
                                                  <td>SISI</td>
                                                  <td></td>
                                                  <td></td>
                                                  <td></td>
                                                  <td></td>
                                                </tr>
                                                <tr>
                                      
                                                 <td>STAT</td>
                                                 <td></td>
                                                 <td></td>
                                                 <td></td>
                                                 <td></td>
                                               </tr>
                                               </tbody>
                                     </table>
                                     </div>
                                     </div>
                                      <label class="text-left col-sm-12 marginTp"><u class="fontstyle">Audiological Intepretation :</u></label>
                                      <label class="text-left col-sm-12 fontstyle">BIL.SEVERE SN HGH LOSS.</label>
                                     </div>
</div>
                                       </div>
                                       <div class="col-sm-4">
                                         <table
                                         class="table table-hover text-center  table-bordered" >
                                            <thead  >
                                               <tr  class=" pt-2 pb-2">
                                                  <th  class=" pt-2 pb-2">Right</th>
                                                  <th  class=" pt-2 pb-2"> A.C</th>
                                                  <th  class=" pt-2 pb-2">Left</th>
                                         </tr>
                                             
                                            </thead>
                                            <tbody>
                                               <tr  class=" pt-2 pb-2">
                                                 <th  class=" pt-2 pb-2">  
                                                     <span  class=" pt-2 pb-2"> <img src="../../assets/circle.png" width="25rem"> </span>
                                                   </th>
                                                 <td >Unmasked</td>
                                                 <td>
                                                  <span><img src="../../assets/cancel.png" width="20rem"> </span>
                                                 </td>
                                               </tr>
                                               <tr >
                                                  <th >
                                                     <span><img src="../../assets/triangle.png" width="30rem"> </span>
                                                  </th>
                                                  <td >Masked</td>
                                                  <td >
                                                     <span ><img src="../../assets/square.png" width="25rem"> </span>
                                                  </td>
                                                </tr>
                                               </tbody>
                                               <tr  class=" pt-2 pb-2">
                                                  <th colspan="2"  class=" pt-2 pb-2">No Response</th>
                                                  <td  class=" pt-2 pb-2"> NR</td>
                                                </tr>
                                               <thead  >
                                                  <tr  class=" pt-2 pb-2">
                                                     <th  class=" pt-2 pb-2">Right</th>
                                                     <th  class=" pt-2 pb-2">B.C</th>
                                                     <th  class=" pt-2 pb-2">Left</th>
                                            </tr>
                                                
                                               </thead>
                                               <tbody>
                                                  <tr >
                                                    <th > <span><img src="../../assets/open-bracket (4).png" width="35rem"> </span></th>
                                                    <td >Unmasked</td>
                                                    <td > <span  class=" pt-2 pb-2"><img src="../../assets/open-bracket (5).png" width="35rem"> </span></td>
                                                  </tr>
                                                  <tr>
                                                     <th> <span  class=" pt-2 pb-2"><img src="../../assets/open-bracket (1).png" width="30rem"> </span></th>
                                                     <td>Masked</td>
                                                     <td> <span  class=" pt-2 pb-2"><img src="../../assets/open-bracket.png" width="30rem"> </span></td>
                                                   </tr>
                                                  </tbody>
                                        </table>
                                     
                                       <label class="text-center col-sm-12 fontstyle">Speech Audiometry</label>
                                       <table 
                                       class="table table-hover text-center  table-bordered" >
                                          <thead  >
                                             <tr class=" pt-2 pb-2">
                                                <th class=" pt-2 pb-2"></th>
                                                <th class=" pt-2 pb-2">(RE)</th>
                                                <th class=" pt-2 pb-2">(LE)</th>
                                       </tr>
                                          </thead>
                                          <tbody>
                                             <tr class=" pt-2 pb-2">
                                               <th class=" pt-2 pb-2">SRT</th>
                                               <td class=" pt-2 pb-2"></td>
                                               <td class=" pt-2 pb-2"></td>
                                             </tr>
                                             <tr class=" pt-2 pb-2">
                                                <th class=" pt-2 pb-2">MCL</th>
                                                <td class=" pt-2 pb-2"></td>
                                                <td class=" pt-2 pb-2"></td>
                                              </tr>
                                              <tr class=" pt-2 pb-2">
                                               <th class=" pt-2 pb-2">SDS</th>
                                               <td class=" pt-2 pb-2"></td>
                                               <td class=" pt-2 pb-2"></td>
                                             </tr>
                                             <tr class=" pt-2 pb-2">
                                               <th class=" pt-2 pb-2">UCL</th>
                                               <td class=" pt-2 pb-2"></td>
                                               <td class=" pt-2 pb-2"></td>
                                             </tr>
                                             </tbody>
                                      </table>
                                      <label class="text-center col-sm-12 fontstyle">Webers</label>
                                      <table
                                      class="table table-hover text-center  table-bordered" >
                                         <thead  >
                                            <tr class=" pt-2 pb-2">
                                               <th class=" pt-2 pb-2">RE</th>
                                               <th class=" pt-2 pb-2"></th>
                                               <th class=" pt-2 pb-2">LE</th>
                                      </tr>
                                         </thead>
                                         <tbody>
                                            <tr class=" pt-2 pb-2">
                                              <th class=" pt-2 pb-2"></th>
                                              <td class=" pt-2 pb-2">250</td>
                                              <td class=" pt-2 pb-2"></td>
                                            </tr>
                                            <tr class=" pt-2 pb-2">
                                               <th class=" pt-2 pb-2"></th>
                                               <td class=" pt-2 pb-2">500</td>
                                               <td class=" pt-2 pb-2"></td>
                                             </tr>
                                             <tr class=" pt-2 pb-2">
                                              <th class=" pt-2 pb-2"></th>
                                              <td class=" pt-2 pb-2">1k</td>
                                              <td class=" pt-2 pb-2"></td>
                                            </tr>
                                           
                                            </tbody>
                                     </table>
                                     <label class="text-center col-sm-12 fontstyle">Rinne</label>
                                     <table
                                     class="table table-hover text-center  table-bordered" >
                                        <thead  >
                                           <tr class=" pt-2 pb-2">
                                              <th class=" pt-2 pb-2">RE</th>
                                              <th class=" pt-2 pb-2"></th>
                                              <th class=" pt-2 pb-2">LE</th>
                                     </tr>
                                        </thead>
                                        <tbody>
                                           <tr class=" pt-2 pb-2">
                                             <th class=" pt-2 pb-2"></th>
                                             <td class=" pt-2 pb-2">250</td>
                                             <td class=" pt-2 pb-2"> </td>
                                           </tr>
                                           <tr>
                                              <th class=" pt-2 pb-2"></th>
                                              <td class=" pt-2 pb-2">500</td>
                                              <td class=" pt-2 pb-2"></td>
                                            </tr>
                                            <tr>
                                             <th class=" pt-2 pb-2"></th>
                                             <td class=" pt-2 pb-2">1k</td>
                                             <td class=" pt-2 pb-2"></td>
                                           </tr>
                                         
                                           </tbody>
                                    </table>
                                    <label class="text-center col-sm-12 fontstyle">{{docName}}</label>
                                    <!-- <label class="text-center col-sm-12 fontstyle">RCI NO. : A05142</label> -->
                                    <label class="text-center col-sm-12 ">Audiologist</label>
                                       </div>
                                       <label class="text-left col-sm-12 mt-3">The Audiological findings represent only a diagonistic possiblity, correlates clinically. This reports do not hold good for any medico legal purpose.</label>
                             </div>
                          


                          <!-------------------------------------Report 6 not yet done ----------------------------------->
                          <!-------------------------------------Report 6 ----------------------------------->
                          <!-------------------------------------Report 6 ----------------------------------->



                          <!-------------------------------------Report 7 ----------------------------------->
                          <!-------------------------------------Report 7 ----------------------------------->
                          <!-------------------------------------Report 7 ----------------------------------->
                         <table *ngIf="option7" id="toClinicExpensesList"
                          class="table table-bordered data-table-custom text-center" 
                      >
                             <thead  >
                               <tr  class="text-center col-sm-12">
                                     <!-- <th> BIN_ID</th>
                                     <th>BATCH ID</th> -->
                                      <th> PURCHASE DATE</th>
                                     <!-- <th>PRODUCT BARCODE</th>
                                     <th>PRODUCT CODE</th> -->
                                     <th>PRODUCT NAME</th>
                                     <th class="maxWidth">HA SERIAL NUMBER</th>
                                     <th>COMPANY</th>
                                     
                                     <th>STYLE OF HA</th>
                                     <!-- <th>PRODUCT MODEL NO</th> -->
                                     <th>QUANTITY</th>
                                     <th>PURCHASE RATE</th>
                                     <th>SELL RATE</th>
                                     <th>DEVICE AGE</th>
                                   
                                     
                                 </tr>

                               
                             </thead>
                             <tbody>
                                <tr *ngFor="
                                let data of getSeventhReport;
                                let i = index">
                                
                                <!-- <td>-- </td> -->
                                <!-- <td> {{data.BATCH_ID}}</td> -->
                                <td> {{data.PURCHASE_DATE | date:'medium'}}</td>
                                 <!-- <td>{{data.PRODUCT_BARCODE}} </td>
                                <td>{{data.PRODUCT_CODE}}</td> -->
                                <td>{{data.PRODUCT_NAME}}</td>
                                <td class="maxWidth">{{data.SERIAL_NUMBER}}</td>
                                <td>{{data.PRODUCT_COMPANY}}</td>
                                <td> {{data.HA_STYLE}}</td>
                                <!-- <td> {{data.HA_PRODUCT_TYPE}}</td>
                                <td>{{data.PRODUCT_MODEL_NO}} </td>
                                <td> {{data.PRODUCT_GROUP}} </td>
                                <td> {{data.PRODUCT_SUBGROUP}} </td>
                                <td> {{data.PRODUCT_SKU}}</td>
                                <td> {{data.PRODUCT_UOM}}</td>
                                <td>{{data.PO_NUMBER}} </td> -->
                                <!-- <td>{{data.HSN_CODE}} </td> -->
                                <td> {{data.QUANTITY_ON_HAND}}</td>
                                <td> {{data.PURCHASE_RATE}}</td>
                                <td> {{data.SELL_RATE}}</td>
                                <td> {{data.DEVICE_AGE}}</td>
                               
                                </tr>
                             </tbody>

                         </table>
                         <!-- <div *ngIf="option7" class="col-sm-12 pt-0 text-right">
                          <pagination-controls id="stock_pagination" directionLinks="true"
                           (pageChange)="onPageChange($event)" class="float-right pagination no-print my-pagination"></pagination-controls>
                           </div> -->
                          <!-------------------------------------Report 8 ----------------------------------->
                          <!-------------------------------------Report 8 ----------------------------------->
                          <!-------------------------------------Report 8 ----------------------------------->
                          <table *ngIf="option8" id="toClinicExpensesList"
                          class="table table-bordered data-table-custom text-center" 
                      >
                             <thead  *ngIf="earData.length > 0">
                             
                                 <tr style="border-top-style: none; border-left-style: none; border-right-style: none; border-bottom-style: none;"  class="text-center">
                                   <th  style="border-top-style: none; border-left-style: none; border-right-style: none; border-bottom-style: none;    text-align: left;"> 
                                      <div class="col-sm-12 ">
                                         <img *ngIf="!logoimg" class=" img2 frame rounded-circle " src="../../assets/main.jpeg" alt="Amanda Wilson" width="500rem">
                                         <img *ngIf="logoimg" class=" img2 frame rounded-circle " [src]="logoimg" alt="patient photo" width="500rem">
                                         
                                     </div></th>
                                  
                                     <th class="pb-0 mb-0" style="border-top-style: none; border-left-style: none; border-right-style: none; border-bottom-style: none;">
                                      <label class="pt-0 mt-0" *ngIf="reportName !== ''"> {{reportName}} <br>
                                         {{reportAddress}}, {{taluka}} ,{{district}}, {{state}}, {{country}} - {{reportPinCode}}
                                        <br> Contact No:{{reportPhoneNumber}} <br></label>
                                        <label *ngIf="reportName == '' || reportName == undefined  || reportName == null" class="mt-3"><h1 class="cliniStyle">Clinic Name</h1></label>
                                       <br> <label *ngIf="openTime !== '' && closeTime !== ''">From {{openTime}} To {{closeTime}}</label> <br>
                                        Earmold Job-card
                                      </th>
                                   <th  style="border-top-style: none; border-left-style: none; border-right-style: none; border-bottom-style: none;"> 
                                 
                                 </th>
                                   </tr>
                               
                                     <tr style="border-top-style: none; border-left-style: none; border-right-style: none; border-bottom-style: none;"  class="text-center mt-2">
                                       <th  style="border-top-style: none; border-left-style: none; border-right-style: none; border-bottom-style: none;    text-align: left;">Customer Name: {{EarMouldData.firstName}} {{EarMouldData.lastName}}</th>
                                       <th  style="border-top-style: none; border-left-style: none; border-right-style: none; border-bottom-style: none;"> </th>
                                       <th  style="border-top-style: none; border-left-style: none; border-right-style: none; border-bottom-style: none;">Date: {{currentdate | date:'medium'}}</th>
                                       </tr>

                           
                                 <tr class="table-bordered">
                                     <th width="33.33%"> </th>
                                     <th width="33.33%">Left</th>
                                      <th width="33.33%"> Right</th>
                                     
                                 </tr>

                               
                             </thead>
                             <tbody *ngIf="earData.length > 0;else EarMouldNoData">
                                <!-- <tr *ngFor="
                                let data of EarMouldData
                                | paginate : { id:'EarMould_pagination', 
                                itemsPerPage: size, currentPage: pageNoEarmold, totalItems:totalLengthEarMould }
                                let i = index">
                                
                                <td>{{i + 1}}</td>
                                <td> {{EarMouldData[i].leftHardness}}</td>
                                <td> {{EarMouldData[i].leftCanalLenght}}</td>
                                 <td>{{EarMouldData[i].leftHAType}}</td>
                                 <td>{{EarMouldData[i].leftRemovalString}}</td>
                                 <td>{{EarMouldData[i].leftStyle}}</td>
                                 <td>{{EarMouldData[i].leftVent}}</td>
                               
                                </tr> -->
                                <!-- <tr>
                                   <th scope="row"> Serial No : </th>
                                   <td>N/A</td>
                                   <td>N/A</td>
                                 </tr> -->
                                 <tr>
                                   <th scope="row">Company :</th>
                                   <td>{{EarMouldData.billingCompanyName}}</td>
                                   <td>{{EarMouldData.billingCompanyName}}</td>
                                 </tr>
                                 <tr> 
                                   <th scope="row">HA/Type :</th>
                                   <td>{{EarMouldData.leftHAType}}</td>
                                   <td>{{EarMouldData.rightHAType}}</td>
                                 </tr>

                                 <tr>
                                   <th scope="row">Hardness :</th>
                                   <td>{{EarMouldData.leftHardness}}</td>
                                   <td>{{EarMouldData.rightHardness}}</td>
                                 </tr>
                                 <tr>
                                   <th scope="row">Removal String :</th>
                                   <td>{{EarMouldData.leftRemovalString}}</td>
                                   <td>{{EarMouldData.rightRemovalString}}</td>
                                 </tr>
                                 <tr>
                                   <th scope="row">Style :</th>
                                   <td>{{EarMouldData.leftStyle}}</td>
                                   <td>{{EarMouldData.rightStyle}}</td>
                                 </tr>
                                 <tr>
                                   <th scope="row">Vent :</th>
                                   <td>{{EarMouldData.leftVent}}</td>
                                   <td>{{EarMouldData.rightVent}}</td>
                                 </tr>
                                 <tr>
                                   <th scope="row">Canal Lenght :</th>
                                   <td>{{EarMouldData.leftCanalLenght}}</td>
                                   <td>{{EarMouldData.rightCanalLenght}}</td>
                                 </tr>
                               
                             </tbody>
                             <tbody><ng-template #EarMouldNoData><td  colspan="16">
                                <p style="text-align: center;">No Data Available</p>
                             </td></ng-template> </tbody>
                         </table>
  
                          <!-------------------------------------Report 9 ----------------------------------->
                          <!-------------------------------------Report 9 ----------------------------------->
                          <!-------------------------------------Report 9 ----------------------------------->
                       
                          <!-- <div *ngIf="option9" class="col-sm-2 text-left">
                             <img *ngIf="!logoimg" class=" img2 frame rounded-circle " src="../../assets/main.jpeg" alt="Amanda Wilson" width="300rem">
                             <img *ngIf="logoimg" class=" img2 frame rounded-circle " [src]="logoimg" alt="patient photo" width="300rem">
                         </div> -->
                         <!-- <div *ngIf="option9" class="col-sm-1"></div>
                         <div *ngIf="option9" class="col-sm-6 text-center">
                          <strong><span class="auto-style2 fass">
                             <label *ngIf="reportName !== ''"> {{reportName}} <br>
                               {{reportAddress}}, {{taluka}} ,{{district}}, {{state}}, {{country}} - {{reportPinCode}}
                              <br> Contact No:{{reportPhoneNumber}} <br></label>
                              <label *ngIf="reportName == '' || reportName == undefined  || reportName == null" class="mt-3"><h1 class="cliniStyle">Clinic Name</h1></label>
                              <label *ngIf="openTime !== '' && closeTime !== ''">From {{openTime}} To {{closeTime}}</label> 
                           </span></strong>  
                            </div> -->

                            <!-- <div  *ngIf="option9" class="col-sm-1"></div>
                            <div *ngIf="option9" class="col-sm-2 text-right justify-content-end">
                             <label class="text-right justify-content-end"> Date : {{currentdate | date:'dd/MM/yyyy'}} </label> <br>
                               </div> -->
                          
                          <table  *ngIf="option9" id="toClinicExpensesList"
                          class="table table-bordered data-table-custom text-center">
                             <thead >
                              <tr  class="text-center col-sm-12">
                                    <th width="20%">Sr.No </th>
                                     <th width="20%">Receipt No </th>
                                     <th width="20%">Patient Name</th>
                                     <th width="20%">Test Name</th>
                                      <th width="20%"> Amount</th>
                                      <th width="20%"> Discount</th>
                                      <th width="20%"> BalanceAmt</th>
                                 </tr>

                               
                             </thead>
                             <tbody>
                                <tr *ngFor="
                                let data of receiptData
                                | paginate : { id:'receipt_pagination', 
                                itemsPerPage: size, currentPage: page, totalItems:totalLengthReceiptData }
                                let i = index">
                                
                                <td>{{i + 1}}</td>
                                <td> {{data.receiptNo?data.receiptNo:'-'}}
                                 
                                </td>
                                <td> {{data.patientName}}</td>
                                <td >
                                   <label  *ngFor="let prod of data.serviceInfo" >
                                    <label >{{prod.serviceName}}</label> 
                                   
                                   </label>
                                 </td>
                                 
                                <td> {{data.totalAmount}}</td>
                                <td>{{data.totalDiscount}} </td>
                                <td>{{data.balanceAmount}}</td>
                                </tr>
                            
                          </tbody>

                         </table>

                         <div  *ngIf="option9" class="col-sm-12 pt-0 text-right">
                          <pagination-controls id="receipt_pagination" directionLinks="true"
                           (pageChange)="onPageChange($event)" class="float-right pagination no-print my-pagination"></pagination-controls>
                           </div>
                          <!-------------------------------------Report 10 ----------------------------------->
                          <!-------------------------------------Report 10 ----------------------------------->
                          <!-------------------------------------Report 10 ----------------------------------->


                         <table *ngIf="option10" id="toClinicExpensesList"
                          class="table table-bordered data-table-custom text-center" 
                      >
                             <thead  >
                               <tr style="border-top-style: none; border-left-style: none; border-right-style: none; border-bottom-style: none;"  class="text-center">
                                 <th  style="border-top-style: none; border-left-style: none; border-right-style: none; border-bottom-style: none;    text-align: left;"> </th>

                               
                                 <th  style="border-top-style: none; border-left-style: none; border-right-style: none; border-bottom-style: none;"> </th>
                                 <th  style="border-top-style: none; border-left-style: none; border-right-style: none; border-bottom-style: none;"> </th>
                                 </tr>
                                 <tr style="border-top-style: none; border-left-style: none; border-right-style: none; border-bottom-style: none;"  class="text-center">
                                   <th  style="border-top-style: none; border-left-style: none; border-right-style: none; border-bottom-style: none;    text-align: left;"> 
                                      <div class="col-sm-12 ">
                                         <img *ngIf="!logoimg" class=" img2 frame rounded-circle " src="../../assets/main.jpeg" alt="Amanda Wilson" width="500rem">
                                         <img *ngIf="logoimg" class=" img2 frame rounded-circle " [src]="logoimg" alt="patient photo" width="500rem">
                                         
                                     </div>
                                   </th>
                                   <th style="border-top-style: none; border-left-style: none; border-right-style: none; border-bottom-style: none; font-size:x-large;margin-top: 2rem;"
                               >Repair Job-card</th>
                                   <th  style="border-top-style: none; border-left-style: none; border-right-style: none; border-bottom-style: none;"></th>
                                  
                                 </tr>
                               
                                     <tr style="border-top-style: none; border-left-style: none; border-right-style: none; border-bottom-style: none;"  class="text-center">
                                       <th  style="border-top-style: none; border-left-style: none; border-right-style: none; border-bottom-style: none;    text-align: left;">Patient Name : {{getNinthReport[0].patientName}}</th>
                                      
                                       <th  style="border-top-style: none; border-left-style: none; border-right-style: none; border-bottom-style: none;"> </th>
                                       <th  style="border-top-style: none; border-left-style: none; border-right-style: none; border-bottom-style: none;">Date :  {{getNinthReport[0].date | date:'medium'}} </th>
                                       </tr>

                                       <tr style="border-top-style: none; border-left-style: none; border-right-style: none; border-bottom-style: none;"  class="text-center">
                                         <th  style="border-top-style: none; border-left-style: none; border-right-style: none; border-bottom-style: none;    text-align: left;">Job No:{{getNinthReport[0].jobNo}} </th>
                                       
                                        <th  style="border-top-style: none; border-left-style: none; border-right-style: none; border-bottom-style: none;"> </th>
                                         <th  style="border-top-style: none; border-left-style: none; border-right-style: none; border-bottom-style: none;"></th>
                                         </tr>
                                        
                                 <tr class="table-bordered">
                                     <th width="33.33%"></th>
                                     <th width="33.33%">Left</th>
                                      <th width="33.33%"> Right</th>
                                      
                                     
                                 </tr>
                                
                              
                             </thead>
                             <tbody>
                               <tr class="table-bordered">
                               <td><b>Serial No:</b></td>
                               <td >
                                  <span *ngIf="getNinthReport[0].LserialNo;else other_content">{{getNinthReport[0].LserialNo}}</span>
                                  <ng-template #other_content>N/A</ng-template>
                             </td>
                               <td>
                                  <span *ngIf="getNinthReport[0].RserialNo;else other_content1">{{getNinthReport[0].RserialNo}}</span>
                             
                                <ng-template #other_content1>N/A</ng-template>
                             </td>
                               
                             </tr>
                             <tr class="table-bordered">
                               <td><b>Company:</b></td>
                               <td>
                                <span *ngIf="getNinthReport[0].Lcompany;else other_content2">{{getNinthReport[0].Lcompany}}</span>
                                <ng-template #other_content2>N/A</ng-template>
                             </td>
                               <td>
                                <span *ngIf="getNinthReport[0].Rcompany;else other_content3">{{getNinthReport[0].Rcompany}}</span>
                                <ng-template #other_content3>N/A</ng-template>
                             </td>
                               
                             </tr>
                             <tr class="table-bordered">
                               <td><b>H/A Type:</b></td>
                               <td>
                                <span *ngIf="getNinthReport[0].LhaType;else other_content4">{{getNinthReport[0].LhaType}}</span>
                                <ng-template #other_content4>N/A</ng-template></td>
                               <td>
                                <span *ngIf="getNinthReport[0].RhaType;else other_content5">{{getNinthReport[0].RhaType}}</span>
                                <ng-template #other_content5>N/A</ng-template></td>
                               
                             </tr>

                             <tr class="table-bordered">
                               <td><b>H/A Model:</b></td>
                               <td> <span *ngIf="getNinthReport[0].LhaModel;else other_content6">{{getNinthReport[0].LhaModel}}</span>
                                <ng-template #other_content6>N/A</ng-template></td>
                               <td>
                                <span *ngIf="getNinthReport[0].RhaModel;else other_content7">{{getNinthReport[0].RhaModel}}</span>
                                <ng-template #other_content7>N/A</ng-template></td>
                               
                             </tr>
                             <tr class="table-bordered">
                               <td><b>Warranty:</b></td>
                               <td>
                                <span *ngIf="getNinthReport[0].Lwarranty;else other_content8">{{getNinthReport[0].Lwarranty}}</span>
                                <ng-template #other_content8>N/A</ng-template></td>
                               <td><span *ngIf="getNinthReport[0].Rwarranty;else other_content9">{{getNinthReport[0].Rwarranty}}</span>
                                <ng-template #other_content9>N/A</ng-template></td>
                               
                             </tr>
                               <!-- <tr >
                                 <th width="" colspan="2">Items Collected:
                                   <br>
                                   Left ear: <span *ngIf="getNinthReport[0].itmCollectedLeftEar[0];else other_content10">{{getNinthReport[0].itmCollectedLeftEar[0].deviceType}}</span>
                                   <ng-template #other_content10>N/A</ng-template>
                                   <br>
                                   Right ear: <span *ngIf="getNinthReport[0].itmCollectedRightEar[0];else other_content11" >{{getNinthReport[0].itmCollectedRightEar[0].deviceType}}</span>
                                   <ng-template #other_content11>N/A</ng-template>

                                   <br>
                                   <br>
                                   Complaints:
                                   <br>
                                   Left ear: <span *ngIf="getNinthReport[0].leftComplaints;else other_content12">{{getNinthReport[0].leftComplaints}}</span>
                                   <ng-template #other_content12>N/A</ng-template>
                                   <br>
                                   Right ear: <span *ngIf="getNinthReport[0].rightComplaints;else other_content13">{{getNinthReport[0].rightComplaints}}</span>
                                   <ng-template #other_content13>N/A</ng-template>
                                   <br>
                                   <br>
                                   Terms and Conditions:
                                   <br>
                                  
                                  
                                   <label class="fontSize">  1. Hearing aid repair time would be a minimum of 7 working days</label>
                                   <br>
                                   <label class="fontSize">2. If a hearing aid is out of warranty, 50% of the repair estimate needs to be paid in
advance before repairs commence</label>
<br>
<label class="fontSize">3. The clinic shall not be responsible for the loss of any hearing aid after 30 days of
approving/not approving repairs</label>
<br>
<label class="fontSize">4. Warranty will not be considered without the submission of a warranty card</label>
<br>
<label class="fontSize">5. An amount of Rs. 50/- will be charged for hearing aids that are out of warranty</label>
<br>
<label class="fontSize">6. Estimates below Rs. 1500/- will be considered as approved by the patient</label>
<br>
<label class="fontSize">7. If the patient does not approve the hearing aid repair within 48 hours after being
informed about the repair estimate, the hearing aid will be called back unrepaired</label>
<br>
<label class="fontSize">8. Patient is expected to collect warrant card post repair for warranty claim</label>
                                 </th>
                             </tr> -->
                             <!-- <tr style="border-top-style: none; border-left-style: none; border-right-style: none; border-bottom-style: none;"  class="text-center">
                                <td  style="border-top-style: none; border-left-style: none; border-right-style: none; border-bottom-style: none;    text-align: left;">Place: {{footerData[0].place}}<br>Date :  {{getNinthReport[0].date | date:'medium'}} </td>
                              
                               <td  style="border-top-style: none; border-left-style: none; border-right-style: none; border-bottom-style: none;">{{getNinthReport[0].patientName}} </td>
                                <td  style="border-top-style: none; border-left-style: none; border-right-style: none; border-bottom-style: none;">{{reportName}}</td>
                                </tr> -->
                             </tbody>
                         </table>
                         
                               <table *ngIf="option11" id="toPaymentList"
                               class="table table-bordered data-table-custom text-center">
                                  <thead>
                                    <tr class="text-center">
                                          <th> Sr.No</th>
                                          <th>Patient Name</th>
                                          <th>Date</th>
                                          <th>Bill No.</th>
                                          <th>Total Amt (Rs.)</th>
                                          <th>Paid Amount </th>
                                          <th>Payment Mode</th>  
                                      </tr>
                                  </thead>
                                  <tbody *ngIf="paymentData.length > 0;else paycond">
                                    <tr *ngFor="
                                    let data of paymentData
                                    | paginate : { id:'option11_pagination', 
                                    itemsPerPage: size, currentPage: page, totalItems:totalLength }
                                    let i = index">
                                    <td>{{i + 1}}</td>
                                    <td >{{paymentData[i].patientName}}</td>
                                    <td> {{paymentData[i].paymentDate | date:'dd/MM/yyyy' }}</td>
                                   
                                 
                                    <td >{{paymentData[i].billNo}}</td>
                                    <td >{{paymentData[i].totalAmount}}</td>  

                                     <td >{{paymentData[i].paidAmount}}</td>
                                    <td >{{paymentData[i].modeOfPayment}}</td>  
                                      </tr>
                                 </tbody>
                                 <tbody><ng-template #paycond><td  colspan="7">
                                   <p style="text-align: center;">No Data Available</p>
                                </td></ng-template> </tbody>
                              </table>
                              <div  *ngIf="option11" class="col-sm-12 pt-0 text-right">
                                <pagination-controls id="option11_pagination" directionLinks="true"
                                 (pageChange)="onPageChange($event)" class="float-right pagination no-print my-pagination"></pagination-controls>
                                 </div>
                                 <table *ngIf="option12" id="toPaymentList"
                                 class="table table-bordered data-table-custom text-center">
                                    <thead>
                                      <tr class="text-center">
                                            <th>Patient ID</th>
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            <th>Age</th>
                                            <th>Mobile Number</th>
                                            <th>Street Address</th>
                                            <th>District</th>
                                        </tr>
                                    </thead>
                                    <tr  *ngFor=" 
                                    let item of listOfPatients
                                    | paginate : { id:'option12_pagination', 
                                    itemsPerPage: size, currentPage: page, totalItems:totalLength }
                                  " style="cursor:pointer; text-align: center " >

                           <td matTooltip="click to view">{{item.patientId}}</td>
                          <td matTooltip="click to view">{{item.firstName}}</td>
                          <td matTooltip="click to view">{{item.lastName}}</td>
                          <td matTooltip="click to view">{{item.age}}</td>
                          <td matTooltip="click to view">{{item.mobile1}}</td>
                          <td matTooltip="click to view">{{item.streetAddress}}</td>
                          <td matTooltip="click to view">{{item.district}}</td> 
                        </tr>
                                </table>
                                <div  *ngIf="option12" class="col-sm-12 pt-0 text-right">
                                  <pagination-controls id="option12_pagination" directionLinks="true"
                                   (pageChange)="onPageChange($event)" class="float-right pagination no-print my-pagination"></pagination-controls>
                                   </div>         
                                   
                                   


                                   <!-- report unsold stock -->

                                   <table *ngIf="option13" id="toPaymentList"
                                 class="table table-bordered data-table-custom text-center">
                                    <thead>
                                      <tr class="text-center">
                                            <th>HA Company Name</th>
                                            <th>Invoice No</th>
                                            <th>Invoice Date</th>
                                            <th>Device Name</th>
                                            <th class="maxWidth">Serial Number</th>
                                            <th>Unit Price</th>
                                            <th>Quantity</th>
                                        </tr>
                                    </thead>
                                    <tr  *ngFor=" 
                                    let item of getThirteenReport
                                    | paginate : { id:'option13_pagination', 
                                    itemsPerPage: size, currentPage: page13, totalItems:totalLength13 }
                                  " style="cursor:pointer; text-align: center " >

                           <td matTooltip="click to view">{{item.haCompanyName}}</td>
                          <td matTooltip="click to view">{{item.invoiceNo}}</td>
                          <td matTooltip="click to view">{{item.invoiceDate | date:'dd/MM/yyyy'}}</td>
                          <td matTooltip="click to view">{{item.deviceName}}</td>
                          <td matTooltip="click to view" class="maxWidth">{{item.serialNumber}}</td>
                          <td matTooltip="click to view">{{item.unitPrice}}</td>
                          <td matTooltip="click to view">{{item.serialNumber.length}}</td> 
                        </tr>
                                </table>
                                <div *ngIf="option13" class="col-sm-12 pt-0 text-right">
                                 <pagination-controls id="option13_pagination" directionLinks="true"
                                  (pageChange)="onPage13Change($event)" class="float-right pagination no-print my-pagination"></pagination-controls>
                                  </div> 

                                <!-- <div *ngIf="option13" class="col-sm-12 pt-0 text-right">
                                  <pagination-controls id="option13_pagination" directionLinks="true"
                                   (pageChange)="onPageChange($event)" class="float-right pagination no-print my-pagination"></pagination-controls>
                                   </div>    -->
                                   <!-- Follow up -->
                                   <table *ngIf="option14" id="toPaymentList"
                                   class="table table-bordered data-table-custom text-center">
                                      <thead>
                                        <tr class="text-center">
                                              <th>Follow up Date</th>
                                              <th>Patient Name</th>
                                              <th>Follow up Type</th>
                                              <th>Follow up Status</th>
                                          
                                          </tr>
                                      </thead>
                                      <tr *ngFor=" 
                                      let item of followupData
                                      | paginate : { id:'option14_pagination', 
                                      itemsPerPage: size, currentPage: pageFollow, totalItems:totalLengthFollowup }
                                    " style="cursor:pointer; text-align: center" >
  
                             <td matTooltip="click to view">{{item.followupDate | date:"dd/MM/yyyy"}}</td>
                            <td matTooltip="click to view">{{item.patientName}}</td>
                            <td matTooltip="click to view">{{item.followupType}}</td>
                            <td matTooltip="click to view">{{item.followupStatus}}</td>
                          </tr>
                                  </table>
                                  <div  *ngIf="option14" class="col-sm-12 pt-0 text-right">
                                    <pagination-controls id="option14_pagination" directionLinks="true"
                                     (pageChange)="onPageChangeFollowup($event)" class="float-right pagination no-print my-pagination"></pagination-controls>
                                     </div> 
                               </div>
                          
                       </div>
                   </div>
               </div>
           </div>
           
 
         </div>
     </div>
     </form>
 </div>