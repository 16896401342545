<div class="dialog-wrapper">
    <!-- <h3 mat-dialog-title>Change Password </h3> -->
    <mat-dialog-content>
      <div class="container">
        <form [formGroup]="PopupSerialNoForm" autocomplete="off" novalidate>
        <div class="row">
          <div class="col-sm-12">
            <div class="card-group">
              <div class="card" >
              
                <div class="card-body  p-0">
                    <div class="col-sm-12">
                        <div class="row">
                            <div class="col-sm-11">
                                <p class="text-muted ">Add Serial No :</p>
                                </div>
                                <div class="col-sm-1 ">
                                  <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                                    <a (click)="onClose()" matTooltip="Close" class="d-flex align-items-center"><span class="material-symbols-outlined pr-2">close</span></a>
                                    </div>
                                </div>
                               
                        </div>
                    </div>
                   
                  
                
                  <div class="col-sm-12">
                        <div class="white_box QA_section card_height_100">
                          <div class="table-responsive">
                              <table
                           class="table table-bordered data-table-custom">
                              <thead>
                                <tr class="text-center">
                                  <th>Serial No.</th>
                                  <th class="pr-0 pl-0">Add</th>
                                  <th class="pr-0 pl-0">Delete</th>
                                </tr>
                              </thead>
                              <tbody formArrayName="device">
    
                                <tr [formGroupName]="i"
                                  *ngFor="let row of PopupSerialNoForm.get('device')['controls']; let i = index; let last = last"
                                  role="row" class="odd text-center">
                               
                                  <td>
                                    <mat-form-field class="example-full-width lable_style2 widthh" appearance="outline">
                                      <mat-label class="lable_style2">Serial No.</mat-label>
                                      <input placeholder="Serial Number" formControlName="serialNumber" matInput
                                        type="text" />
                                    </mat-form-field>
                                    <p style="color:red;" *ngIf="findDuplicate(row.controls.serialNumber.value,i) && i > 0">
                                      Duplicate Serial Number
                                    </p>
                                    <mat-error class="margin_tp"
                                    *ngIf="row.controls.serialNumber.touched && row.controls.serialNumber.errors?.required">
                                       This Field is Mandatory.
                                  </mat-error>
                                  </td>
                                  <td class="pr-0 pl-0">
                                    <!-- <i matTooltip="Delete" (click)="deleteRow(i)" class="fas fa-plus mt-3"></i> -->
                                    <!-- <img class="pl-2 mt-2" (click)="addNew();" matTooltip="Add" width="30"
                                      src="../../assets/add-button.png"> -->
                                      <button mat-button mat-icon-button *ngIf="last" (click)="addNew();" [disabled]="PopupSerialNoForm.controls.device.invalid">
                                        <img matTooltip="Add New Serial No" class="mt-1" width="25" src="../../assets/add.png"> 
                                      </button>
                                     
                                  </td>
                                  <td class="pr-0 pl-0">
                                    <!-- <img class="pl-2 mt-2" (click)="deleteRow(i);" matTooltip="Delete" width="30" src="../../assets/minus-sign.png"> -->
                                    <button mat-button mat-icon-button (click)="deleteRow(i);" matTooltip="Delete" [disabled]="PopupSerialNoForm.get('device')['controls']['length']===1">
                                      <img matTooltip="Remove Serial No" class="mt-1" width="25" src="../../assets/dustbin.png"> 
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <hr>
    
                          </div>
                        </div>
                          </div>
                        
                       <div class="row">
                        <div class="col-sm-3">
                            </div>
                        <div class="col-sm-6">
                            <button type="button" class="btn btn-sm btn-primary example-full-width" (click)="sumbitSerialNo()" [disabled]="disableSubmit">Save</button>
                        </div>
                        <div class="col-sm-3">
                        </div>
                       </div>
                        
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4"></div>
        </div>
</form>


      </div>
    </mat-dialog-content>
  
  </div>
  