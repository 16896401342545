<div class="container-fluid bgcolor2" [formGroup]="popUpInwardOutward">
    <form  [formGroup]="popUpInwardOutward" autocomplete="off" novalidate>
      <div  class="row">
          <div class="col-sm-12">
            <div class="row pl-0 ">
              <div class="col-12 pl-0 "> 
                <div class="row ">
                <div class="col-sm-9">
                    <h1 class="pt-2 pl-0" id="toInwardOutwardForm">Inward Outward</h1>
                    </div>
                    <div class="col-sm-3 mt-1"> 
                      
                      <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                        <a (click)="onback()" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">arrow_back</span>Back</a>
                     
                        <a (click)="popUpClose()" matTooltip="Close" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">close</span></a>
                      </div>
              </div>
            </div>
           </div>
           </div>  
  
              <div class="row">
                  <div class="col-sm-12 pr-4">
                      <div class="row">
                          <div class="card divbody mb-3 borderradius p-2">
                             
                              <div class="col-sm-12">
                                  <div class="row"> 
                                      <div class="col-sm-1 mt-3 ">
                                        <mat-label class="center" ngDefaultControl formControlName="branchWiseId" *ngIf="userType !== 'Super Admin'" >{{bId}}</mat-label>
                                        <mat-label class="center" ngDefaultControl [value]="superBranchId" *ngIf="userType == 'Super Admin'" >{{superBranchId}}</mat-label>
                                       </div>
  
                                      <div class="col-sm-2">
                                          <mat-form-field class="example-full-width " appearance="outline">
                                              <mat-label>Date</mat-label>
                                              <input required matInput placeholder="Choose a date" 
                                              formControlName="date" readonly>
                               
                                          </mat-form-field>
                                          <mat-error
                                          *ngIf="popUpInwardOutward.controls['date'].touched && popUpInwardOutward.controls['date'].errors?.required">
                                          This field is mandatory.
                                        </mat-error>
                                      </div>
                                    
                                      <div class="col-sm-4">
                                        <div class="row">
                                          <div class="col-sm-6">
                                            <h5 class="mt-3"><span class="required-asterisk mr-1">*</span>Inward/Outward:</h5>
                                       </div>
                                          <div class="col-sm-6">  
                                            <mat-label class="labelStyle " ngDefaultControl formControlName="inwardOutward">
                                                <div class="row ml-2 mt-3">
                                                   <h5 ><u>{{isInwardOutward}}</u> </h5> 
                                                  </div></mat-label> 
                                         </div>
                                        </div>
                                        </div>
                                        <div class="col-sm-5"></div>
                                     
                               <div class="col-sm-2">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label *ngIf="inward">To</mat-label>
                                  <mat-label *ngIf="outward">From</mat-label> 
                                  <mat-select [disabled]="true" required formControlName="refType">
                                    <mat-option (click)="selectRef('Client');" value="Client">Client</mat-option>
                                    <mat-option (click)="selectRef('Manufacturer');" value="Manufacturer">Manufacturer</mat-option>
                                  </mat-select>
                                </mat-form-field>
                 <mat-error class="mt-1"
                *ngIf="popUpInwardOutward.controls['refType'].touched && popUpInwardOutward.controls['refType'].errors?.required">
               This field is mandatory.
               </mat-error>
                              </div> 
                                        <div *ngIf="client" class="col-sm-3">
                                       
                                              <mat-form-field class="example-full-width" appearance="outline">
                                                <mat-label>Patient Name</mat-label>
                                                <input required matInput readonly placeholder="Patient Name" formControlName="patientName" />
                                                </mat-form-field> 
                                                <mat-error
                                                *ngIf="popUpInwardOutward.controls['patientName'].touched && popUpInwardOutward.controls['patientName'].errors?.required">
                                                This field is mandatory.
                                              </mat-error>
                                         
                                              </div>
          
                                              <div *ngIf="manufacturer" class=" col-sm-3">
                                                <mat-form-field class="example-full-width" appearance="outline">
                                                  <mat-label>Manufacturer</mat-label>
                                                  <input required matInput readonly placeholder="Manufacturer Name" formControlName="companyName" />
                                                  </mat-form-field>  
                                              
                                                </div>
                                                <div class="col-sm-3 ">
                                                    <mat-form-field class="example-full-width" appearance="outline">
                                                      <mat-label>Select</mat-label>
                                                      <mat-select required formControlName="type">
                                                        <mat-option (click)="type()" value="Devices">Devices</mat-option>
                                                        <mat-option (click)="type()" value="Spares">Spares</mat-option>
                                                        <mat-option (click)="type()" value="Accessories">Accessories</mat-option>
                                                        <mat-option (click)="type()" value="Documents">Documents</mat-option>
                                                      </mat-select>
                                                    </mat-form-field>
                                     <mat-error class="mt-1"
                                    *ngIf="popUpInwardOutward.controls['type'].touched && popUpInwardOutward.controls['type'].errors?.required">
                                   This field is mandatory.
                                   </mat-error>
                                                  </div>
                                                  <div *ngIf="isDevices" class="col-sm-3 ">
                                                    <mat-form-field class="example-full-width" appearance="outline">
                                                      <mat-label>Select Device</mat-label>
                                                      <mat-select required formControlName="deviceType">
                                                        <mat-option value="New">New</mat-option>
                                                        <mat-option value="For Repair">For Repairs</mat-option>
                                                      </mat-select>
                                                    </mat-form-field>
                                     <mat-error class="mt-1"
                                    *ngIf="popUpInwardOutward.controls['deviceType'].touched && popUpInwardOutward.controls['deviceType'].errors?.required">
                                   This field is mandatory.
                                   </mat-error>
                                                  </div>
                                                  <div *ngIf="isDocuments" class="col-sm-3 ">
                                                    <mat-form-field class="example-full-width" appearance="outline">
                                                      <mat-label>Select Documents</mat-label>
                                                      <mat-select required formControlName="docType">
                                                        <mat-option value="Letter">Letter</mat-option>
                                                        <mat-option value="Miscellaneous">Miscellaneous</mat-option>
                                                      </mat-select>
                                                    </mat-form-field>
                                     <mat-error class="mt-1"
                                    *ngIf="popUpInwardOutward.controls['docType'].touched && popUpInwardOutward.controls['docType'].errors?.required">
                                   This field is mandatory.
                                   </mat-error>
                                                  </div>
                                                  <div *ngIf="!isDocuments && !isDevices" class="col-sm-3"></div>
                                                  <div *ngIf="!isDocuments && !isDevices" class="col-sm-1"></div>
                                                  <div class="col-sm-5">
                                                    <mat-form-field class="example-full-width" appearance="outline">
                                                      <mat-label>Notes</mat-label>
                                              
                                                      <textarea matInput maxlength="1000" formControlName="notes"
                                                      cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                                      cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" ></textarea>
                                                    </mat-form-field>
                                                  </div>
                                                 
                                                  <div *ngIf="isSuperAdmin" class="col-sm-12"> 
                            
                                                    <div class="row">
                                                      <div class="col-sm-12 mt-2">
                                                        <h5 class="pb-0 mb-0">Select Branch :</h5>
                                                  
                                                      </div>
                                                      </div>
                                                       <div class="row">
                                  
                                                  <div class="col-sm-3">
                                                    <div class="row">
                                                      <div class="col-sm-10 pr-1"> 
                                                    <mat-form-field class="example-full-width" appearance="outline">
                                                      <mat-label>Branch 
                                                      </mat-label>
                                                      <mat-select required #select5 (click)="fetchBranchList();" formControlName="branchRefId" >
                                                        <mat-select-filter *ngIf="select5.focused" [placeholder]="'Filter'" [displayMember]="'branchName'"
                                                        [array]="BranchList"  (filteredReturn)="filteredListBranch =$event"></mat-select-filter>
                                                        <mat-option *ngFor="let item of filteredListBranch" [value]="item._id" 
                                                        (click)="branchClick(item._id,item.branchName);"
                                                        >
                                                         {{ item.branchName }}</mat-option>
                                                        </mat-select>
                                                       
                                                    </mat-form-field>
                                                    <mat-error class="margin_tp"
                                                        *ngIf="popUpInwardOutward.controls['branchRefId'].touched && popUpInwardOutward.controls['branchRefId'].errors?.required">
                                                        This field is mandatory.
                                                      </mat-error>
                                                      </div>
                                                      <div class="col-sm-2 mt-2 mTop no-print">
                                                        <i (click)="openBranchPopUp();" matTooltip="Add New Branch" 
                                                        class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                                                           </div>
                                                     
                                                      </div>
                                                  </div>
                                             </div>
                                             </div>
                                      <div class="col-sm-2 offset-sm-10 mb-2 mt-2" *ngIf="submitButton && inwardOutwardCreate"> 
                                          <button class="btn btn-primary btn-sm example-full-width"  type="button" 
                                          (click)="submitInwordOutword()">Save </button>
  
                                      </div>
                                      <div class="col-sm-2 offset-sm-8 mb-2 mt-2" *ngIf="updateButton && inwardOutwardUpdate">
                                          <button (click)="UpdateInwordOutword();"  type="button" 
                                          class="btn btn-primary btn-sm example-full-width">Update </button>
                                      </div>
                                      <div class="col-sm-2 mb-2 mt-2 " *ngIf="updateButton">
                                          <button class="btn btn-primary btn-sm example-full-width" (click)="cancelUpdate()" type="reset" >Cancel </button>
                                         
                                      </div>
                                    
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
            
  
          </div>
      </div>
    </form>
  </div>