import { Component, Inject, OnInit } from "@angular/core";
import { ApiServiceService } from '../api-service.service';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-popup-rejectstock',
  templateUrl: './popup-rejectstock.component.html',
  styleUrls: ['./popup-rejectstock.component.scss']
})
export class PopupRejectstockComponent implements OnInit {
  PopupRejectStockForm: FormGroup;
  _id: any;
  stockTransferPayload: any;
  constructor(public dialogRef: MatDialogRef<PopupRejectstockComponent>,@Inject(MAT_DIALOG_DATA) public data:any,
  public apimicroservice: ApiServiceService,private spinner: NgxSpinnerService,private fb: FormBuilder) {
    this._id = data.stockRefId;
    this.stockTransferPayload = data.stockTransferPayload;
    dialogRef.disableClose = true;
   } 

  ngOnInit(): void {
    this.PopupRejectStockForm = this.fb.group({  
      "reasonOfReject": new FormControl('')
    });
    //
    //
  }

  confirmSubmit(){
   // this.spinner.show();
    let payload = this.PopupRejectStockForm.value;
    //
      // let payload = this.stockTransferForm.value;
  let newsenoarr = [];
  let alertpayload = {};
  this.stockTransferPayload.device.forEach(element =>{
    element.serialNumber.forEach(item =>{
      newsenoarr.push(item);
    })
    
  });
  this.stockTransferPayload.transferStatus = "Reject";
  this.stockTransferPayload.reasonOfReject = payload.reasonOfReject;
  //
  ////
  let stockMaintainPayload = {};
////
this.apimicroservice.updateStockTransfer(this._id,this.stockTransferPayload).subscribe(res => {
    //
  // this.submitButton=true;
  // this.proceedupdateButton = false;
  // this.updateButton=false;
  // this.cancelButton=false;
  // this.acceptButton = false;
  // this.rejectButton = false;
   // this.spinner.hide();
    if(res.status){
      //this.apimicroservice.openSnackbar(res.message);
      stockMaintainPayload['transferStatus'] = "Reject";
      stockMaintainPayload['reasonOfReject'] = payload.reasonOfReject;
      alertpayload['transferStatus'] = "Reject";
      alertpayload['alertType'] = 12;
      //stockMaintainPayload['transferRefId'] = res.data.data._id;
      //
      let bar = new Promise<void>((resolve,reject) => {
      newsenoarr.forEach(element =>{
        stockMaintainPayload['serialNumber'] = element;
        alertpayload['serialNumber'] = element;
        this.apimicroservice.updateStockMaintainByTransfer(this._id,stockMaintainPayload).subscribe(res => {
          //
          if(res.status){
            resolve();
            this.apimicroservice.updateTransferAlert(element,alertpayload).subscribe(res => {
              //
              window.location.reload();
              
            });
          }
        });
  })
});

bar.then(() =>{
  this.onClose();
})
      
    }else{
      //this.apimicroservice.openSnackbar(res.message);
    }
  });
  }

  confirmCancel(){
   this.onClose();
  }

  onClose(): void {
    this.dialogRef.close();
  }

}
