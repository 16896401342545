import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiServiceService } from '../api-service.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-preview-popup',
  templateUrl: './preview-popup.component.html',
  styleUrls: ['./preview-popup.component.scss']
})
export class PreviewPopupComponent implements OnInit {
  printBillForm: FormGroup;
  date: any;
  listOfAppointment:any;
  totalLength:any;
  appointmentId: any;
  size=5;
  page=1;
  searchText: any;
  submitButton = true;
  updateButton = false;
  allColor: any;
  
  filteredList: { name: string; value: string; }[];
  filteredListAppoinmentType: { name: string; value: string; }[];
  userType: string;
  branchRef: string;
  clinicRef: string;
  BranchList: any;
  isSuperAdmin= true;
  superBranchId: any;
  branchName: string;
  filteredListBranch: any;
  currentdate: Date;
  listOfBilling: any;
  id: any;
  courierList: any;
  filteredListCourier: any;
  disableFromCity = false;
  disableToCity = true;
  tocityList: any;
  cityList: any;
  fromCity: any;
  toCity: any;
  courierServiceCreate = true;
  branchCreate = true;
  filteredListPatient: any;
  patientList: any;
  totalCgst=0;
  totalIgst=0;
  totalSgst=0;
  totalGST=0;
  totalRate=0;
  serialVal = '';
  dashVal = '-';
  printData=[
    {
      srNo:1,
      date:'12/03/2001',
      serviceName:'Test',
      companyName:'A Company',
      rate:100,
     },
     {
      srNo:2,
      date:'12/03/2001',
      serviceName:'Test',
      companyName:'A Company',
      rate:200,
     },
     {
      srNo:3,
      date:'12/03/2001',
      serviceName:'Test 2',
      companyName:'A Company',
      rate:100,
     },
     {
      srNo:4,
      date:'12/03/2001',
      serviceName:'Test 3',
      companyName:'A Company',
      rate:150,
     },                 
  ]
  billingList = [];
  patientName: any;
  amount: any;
  totalAmount: any;
  discount = 0;
  billData: any;
  advanceAmount = 0;
  balanceAmount = 0;
  gst = 0;
  chequeDetails = [];
  billRefId: any;
  paymentHistoryList= [];
  amountInWord: any;
  totalAmountInWords: any;
  totalsgst = 0;
  totalcgst = 0;
  totaligst = 0;
  totalsgstAmt = 0;
  totalcgstAmt = 0;
  totaligstAmt = 0;
  tempTotalAmount = 0;

  billNo: any;
  headerData: any;
  reportName: any;
  reportAddress: any;
  district: any;
  taluka: any;
  state: any;
  country: any;
  reportPinCode: any;
  reportPhoneNumber: any;
  openTime: any;
  closeTime: any;
  logoimg: any;
  footerData: any;
  emailAdd: any; 
  website:any;
  reportWeeklyOff: any;
  prifix: any;
  lastName: any;
  firstName: any;
  middleName: any;
  address: any;
  invoiceDate: any;
  pMobile: any;
  companyGst: any;
  bankName: string;
  bankBranchName: string;
  accountNumber: string;
  ifcCode: string;
  upiId: string;
  resultArr=[];
  streetAddress1: any;
  taluka1: any;
  district1: any;
  country1: any;
  state1: any;
  pincode1: any;
  reportPhoneNumber1: any;
  billingRef: any;
  paymentStatus: any;
  gstNumber: any;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<PreviewPopupComponent>,@Inject(MAT_DIALOG_DATA) public data:any,
    public apimicroservice: ApiServiceService,
    private spinner: NgxSpinnerService,
    public router: Router,
    private route: ActivatedRoute, 

  ) { dialogRef.disableClose = true;}
 userPermission:any;
  billingCreate= true;
  billingUpdate= true;

  ngOnInit(): void {
    this.currentdate = new Date();
    this.page=1;
    this.prifix = '';
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.userType = sessionStorage.getItem('userType');
    this.billNo =this.data.billNo;
    //
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    if(this.userType !== 'Super Admin'){
      this.isSuperAdmin= false;
    this.userPermission.forEach((element) => {
      if(element.pageName == 'Print Bill' && element.create == false)
      {
          this.billingCreate = false;  
      }
      if(element.pageName == 'Print Bill' && element.update == false)
      {
          this.billingUpdate = false;  
      }  
    }); 
  }
    this.printBillForm = this.fb.group({
 
      "modeOfPayment": new FormControl(''),
      "branchRefId": new FormControl(''),
      "branchName": new FormControl(''),
      "clinicRefId": new FormControl(''), 
      "searchBilling": new FormControl(''), 
      "chequeArray": this.fb.array([]),
      "serviceInfo": this.fb.array([]),
      "selectAllService": new FormControl(false),
      "searchHistory": new FormControl(''), 
      
    }); 
 
 
      let bar = new Promise<void>((resolve, reject) => {
        this.apimicroservice.getBillDataById(this.billNo).subscribe(res => {
          //
         this.billingRef = res.data[0].billingCompanyRefId;
          resolve();
        }); 
      });
     bar.then(()=>{
      this.fetchBillData();
      this.fetchPaymentHistoryData(),
      this.getReportHeaderGenericAllData(),
      this.getReportFooterGenericAllData()
     });

}
fetchPaymentHistoryData() {
  let pg = 1;
  let size = 25;
  let search='';
  this.spinner.show();
  this.searchText ='';
    this.apimicroservice.getAllPaymentHistoryByBillNo(pg,size,search,this.billNo).subscribe(res => {
      this.spinner.hide(); 
      this.paymentHistoryList= [];
      //
      let statusVal = false;
       // this.paymentHistoryList = res.data.data;
       if(res.data.data.length > 0){
        res.data.data.forEach(ele =>{
          if(ele.modeOfPayment == 'Cheque' && ele.chequePaymentStatus !== 'Clear'){
           
          }
          else{       
            this.paymentHistoryList.push(ele);
          }       
        });
        }
        this.totalLength = res.data.totalItems;
        //
       if(res.status){
         this.apimicroservice.openSnackbar(res.message);        
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
   
 
}

receiptPage()
{
    this.router.navigate(['receipt',this.billNo]);
}

redirect(to): void {
  this.router.navigate([to]);
 }
getReportHeaderGenericAllData()
{
  let clinicRef= sessionStorage.getItem('clinicRef');
  this.apimicroservice.getHeaderDataByBillingRefId(this.billingRef).subscribe(res => {
    let data = res.data[0]; 
    this.companyGst = data.companyGST;
    this.bankName = data.bankName;
    this.bankBranchName = data.bankBranchName;
    this.accountNumber = data.accountNumber;
    this.ifcCode = data.ifcCode;
    this.upiId = data.upiId;

    this.headerData = res.data;
    this.apimicroservice.getBillingDataById(this.headerData[0].billingRefId,clinicRef).subscribe(res => {
      this.gstNumber = res.data[0].companyGST;
    });
    //
    if(res.data.length > 0){
      this.reportName = this.headerData[0].reportName;
      this.reportAddress = this.headerData[0].reportAddress;
      this.reportWeeklyOff = this.headerData[0].reportWeeklyOff;
      this.emailAdd = this.headerData[0].reportEmail;
      this.website = this.headerData[0].reportWebsite;
      this.taluka = this.headerData[0].taluka;
      this.district = this.headerData[0].district;
      this.state = this.headerData[0].state;
      this.country = this.headerData[0].country;
      this.reportPinCode = this.headerData[0].reportPinCode;
      this.reportPhoneNumber = this.headerData[0].reportPhoneNumber;
      this.reportPhoneNumber1 = this.headerData[0].reportPhoneNumber1;
      this.openTime = this.headerData[0].openTime;
      this.closeTime = this.headerData[0].closeTime;
      this.logoimg = res.data[0].uploadLogo;

    }else{
      this.reportName = "";
      this.reportAddress = "";
      this.reportWeeklyOff ="";
      this.taluka = "";
      this.district = "";
      this.state = "";
      this.country = "";
      this.reportPinCode = "";
      this.reportPhoneNumber = "";
      this.reportPhoneNumber1 = "";
      this.openTime = "";
      this.closeTime = "";
      this.emailAdd = "";
      this.website = "";
      this.logoimg = "";
    }
  }); 
}
getReportFooterGenericAllData()
{
  let clinicRef= sessionStorage.getItem('clinicRef');
  let branchRef= sessionStorage.getItem('branchRef');
  let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
  let branchRefSuperAdmin= '';
  if (this.userType !== 'Super Admin') {
  this.apimicroservice.getReportFooterGenericAllData(branchRef,clinicRef).subscribe(res => {
    if(res.status){
    //  this.apimicroservice.openSnackbar(res.message);
      this.footerData = res.data;
      //
    }else{
      //this.apimicroservice.openSnackbar(res.message);
    }
  });
}else{
  this.apimicroservice.getReportFooterGenericAllData(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
    if(res.status){
    //  this.apimicroservice.openSnackbar(res.message);
      this.footerData = res.data;
      //
    }else{
      //this.apimicroservice.openSnackbar(res.message);
    }
  });
}
}
 
fetchBillData(){
  this.apimicroservice.getBillDataById(this.billNo).subscribe(res => {
    //
   let data = res.data[0];
   
   this.billData = data;
   this.patientName = data.patientName;
   this.billingList = data.serviceInfo;
   this.totalAmount = data.totalAmount;
   this.tempTotalAmount = data.totalAmount;
   this.paymentStatus = data.paymentStatus;
   this.invoiceDate = data.createdAt;
   this.totalCgst=0;
   this.totalIgst=0;
   this.totalSgst=0;
   this.totalGST=0;
   this.totalRate=0;
   let result = [];
   
   
   this.billingList.forEach(function (a) {
    if (!this[a.hasSac])
     {
      this[a.hasSac] = { hasSac: a.hasSac,
    sgst: 0,cgst:0,igst:0,rate:0,sgstAmt: 0,cgstAmt:0,igstAmt:0}; 
    result.push(this[a.hasSac]);
     }
    this[a.hasSac].sgst = a.sgst;
    this[a.hasSac].cgst = a.cgst;
    this[a.hasSac].igst = a.igst;
    this[a.hasSac].rate += a.rate;
    this[a.hasSac].sgstAmt += (a.sgst*a.rate)/100;
    this[a.hasSac].cgstAmt += (a.cgst*a.rate)/100;
    this[a.hasSac].igstAmt += (a.igst*a.rate)/100;
  }, 
     
     Object.create(null));
     //
     this.resultArr = result;
     //
     
     this.resultArr.forEach(element=>{
    // 
    this.totalRate = this.totalRate + element.rate;
    this.totalSgst=this.totalSgst+element.sgstAmt;
    this.totalCgst=this.totalCgst+element.cgstAmt;
    this.totalIgst=this.totalIgst+element.igstAmt;

    if(element.sgst !== null && element.cgst !== null)
    {
      // this.totalGST=this.totalGST+((element.sgst*element.rate)/100+(element.cgst*element.rate)/100);
      this.totalGST=this.totalGST+element.sgstAmt+element.cgstAmt;
    }
    else
    {
      this.totalGST=this.totalGST+element.igstAmt;
      // this.totalGST=this.totalGST+(element.igst*element.rate/100);
    }
   
   })
   //
   //
   //
   //
     this.apimicroservice.getPatientById(data.patientRefId).subscribe(res => { 
      
       this.lastName =res.data[0].lastName;
       this.firstName =res.data[0].firstName; 
       this.middleName =res.data[0].middleName;
       this.pMobile =res.data[0].mobile1;
       this.streetAddress1 =res.data[0].streetAddress;
       this.taluka1 =res.data[0].taluka;
       this.district1 =res.data[0].district;
       this.state1 =res.data[0].state;
       this.country1 =res.data[0].country;
       this.pincode1 =res.data[0].pincode;
        this.prifix =res.data[0].prefix;

        //
        
    });
   if(data.paymentStatus == "Paid"){
     this.submitButton = false;
   }else{
    this.submitButton = true;
   }
   this.gst = data.gst;

   //this.balanceAmount = data.balanceAmount;

   this.billRefId = data._id;
   //
   if(data !== undefined){
    data.serviceInfo.forEach((element,i) => {
      let sgstAmt = 0;
      let cgstAmt = 0;
      let igstAmt = 0;
      if(this.discount !== null){
        this.discount = this.discount + element.discount;
      }
      if(element.sgst !== null){
        this.totalsgst = this.totalsgst + element.sgst;
       sgstAmt = (element.totalRate * element.sgst) / 100;
       this.totalsgstAmt = this.totalsgstAmt + sgstAmt;
        //
      }
      if(element.cgst !== null){
        this.totalcgst = this.totalcgst + element.cgst;
        cgstAmt = (element.totalRate * element.cgst) / 100;
       this.totalcgstAmt = this.totalcgstAmt + cgstAmt;
        //
      }
      if(element.igst !== null){
       this.totaligst = this.totaligst + element.igst;
       igstAmt = (element.totalRate * element.igst) / 100;
       this.totaligstAmt = this.totaligstAmt + igstAmt;
        //
      }

      this.addNew(element,i)
    });
    // this.totalsgstAmt = (this.totalAmount * this.totalsgst) / 100;
    // this.totalcgstAmt = (this.totalAmount * this.totalcgst) / 100;
    // this.totaligstAmt = (this.totalAmount * this.totaligst) / 100;
    this.totalAmount = this.totalAmount + this.totalsgstAmt + this.totalcgstAmt;
    if(data.balanceAmount !== undefined){
      
      this.balanceAmount = data.balanceAmount;
     }else{
      this.balanceAmount = this.totalAmount;
     }
     this.amountInWord =this.totalAmount;
     //
   ////
   const numWords = require('num-words');
   //
   //numWords(this.totalAmount)",numWords(this.totalAmount));
    this.totalAmountInWords = numWords(this.totalAmount);
   //
   }
   //

   //
   
  //  this.apimicroservice.getBillingDataById(data.billingCompanyRefId).subscribe(res => {
  //    let data = res.data[0]; 
  //    this.companyGst = data.companyGST;
  //    this.bankName = data.bankName;
  //    this.bankBranchName = data.bankBranchName;
  //    this.accountNumber = data.accountNumber;
  //    this.ifcCode = data.ifcCode;
  //    this.upiId = data.upiId;
     
  //  });
  });
} 
// fetchPaymentHistoryData() {
//   let pg = 1;
//   let size = 5;
//   let search='';
//   this.spinner.show();
//   this.searchText ='';
//     this.apimicroservice.getAllPaymentHistoryByBillNo(pg,size,search,this.billNo).subscribe(res => {
//       this.spinner.hide(); 
//         this.paymentHistoryList = res.data.data;
//         this.totalLength = res.data.totalItems;
//         //
//        if(res.status){
//          this.apimicroservice.openSnackbar(res.message);        
//        }else{
//          //this.apimicroservice.openSnackbar(res.message);
//        }
//      });
   
 
// }
addNew(data,i) {
  //
  //let amt = data.rate * 1;
  const creds = this.printBillForm.controls.serviceInfo as FormArray;
      creds.push(
      this.fb.group({
       // "serviceRefId": new FormControl(data.serviceRefId),
        "serviceName": new FormControl(data.serviceName),
        "testType": new FormControl(data.testType),
        "rate": new FormControl(data.rate),
        "quantity": new FormControl(data.quantity),
        "discount": new FormControl(data.discount),
        "earStyle": new FormControl(data.earStyle),
        "status" : new FormControl(data.status),
        "totalRate":new FormControl(data.totalRate),
        "serialNumber": new FormControl(data.serialNumber), 
        "sgst":new FormControl(data.sgst),
        "cgst":new FormControl(data.cgst),
        "igst":new FormControl(data.igst),
        "hasSac":new FormControl(data.hasSac),
        "warrantyPeriod":new FormControl(data.warrantyPeriod),
        "expiryDate":new FormControl(data.expiryDate),
        "selectService": new FormControl(false),
      toggle: false

})
);
}

}

 