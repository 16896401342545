<div class="container-fluid " [formGroup]="checkupDetailsForm">
    <form [formGroup]="checkupDetailsForm" autocomplete="off" novalidate>
      <div class="row">
          <div class="col-sm-1">
            
          </div>
          <div class="col-sm-11">
               
                  <div class="row pt-3 ">
                    <div class="col-sm-5 row pl-4 pr-4">
                   
                       <div class="col-sm-10">
                          <div class="row">
                            <div class="card divbodyf boxsssss borderradius p-3 mb-2 fixeds">
                              <div class="col-sm-12">
                                  <div class="col-sm-8 offset-sm-2 text-center">
                                      <h2 class="col-sm-12">{{patientData.firstName}} {{patientData.lastName}} </h2> 
                                 <label *ngIf="patientData.decease == 'Yes'" class="redColor">Patient is Decease</label>
                                      <!-- <marquee width = "50%">    <label class="redColor">Patient is Decease</label></marquee>   -->
                                  
                                     
                                      <!-- <span class="fa fa-star checked"></span>
                                      <span class="fa fa-star checked"></span>
                                      <span class="fa fa-star checked"></span>
                                      <span class="fa fa-star"></span>
                                      <span class="fa fa-star"></span> -->
                                  </div>
                              </div>
                              <div class="col-sm-12">
                                  <div class="row pb-3">
                                    <mat-list  class="fhfhff">
                                      <div class="row col-sm-12">
                                    <mat-divider class="col-sm-12"></mat-divider>
                                 
                                          <div class="row pt-2 pb-2 titlecolor col-sm-12">
                                           
                                            <label class="col-sm-6">Gender:</label>
                                            <label class="col-sm-6">{{patientData.sex}}</label>
                                            </div>
                                        
                                        <mat-divider class="col-sm-12"></mat-divider>
                                    
                                          <div class="row titlecolor col-sm-12 mt-1">
                                            <div class="col-sm-6">
                                            <label>Reference</label>
                                            <label>Audiologists: </label>
                                            </div>
                                            <div class="col-sm-6">
                                            <label class=" mt-2">{{paientdetails.doctorName}}</label>
                                            </div>
                                            </div>
                                     
                                       <mat-divider class="col-sm-12"></mat-divider>
                                    
                                        <div class="row pt-2 pb-2 titlecolor col-sm-12">
                                         
                                          <label class="col-sm-6">DOB:</label>
                                          <label class="col-sm-6">{{patientData.dob | date: 'dd/MM/yyyy'}}</label>
                                          </div>
                                      
                                       <mat-divider class="col-sm-12"></mat-divider>
                                       
                                        <div class="row pt-2 pb-2 titlecolor col-sm-12">
                                         
                                          <label class="col-sm-6">Contact:</label>
                                          <label class="col-sm-6">{{patientData.mobile1}}</label>
                                          </div>
                                         
                                       <mat-divider class="col-sm-12"></mat-divider>
                                    
                                        <div class="row pt-2 pb-2 titlecolor col-sm-12">
                                         
                                          <label class="col-sm-6">Address:</label>
                                          <label class="col-sm-6">{{patientData.streetAddress}}</label>
                                          </div>
                                          
                                          
                                       <mat-divider class="col-sm-12"></mat-divider>
                                      
                                        <div class="row pt-2 pb-2 titlecolor col-sm-12">
                                         
                                          <label class="col-sm-6">Age:</label>
                                          <label class="col-sm-6">{{patientData.age}}</label>
                                          </div>
                                        
                                          <mat-divider class="col-sm-12"></mat-divider>
                                    
                                          <div class="row pt-2 pb-2 titlecolor col-sm-12">
                                              <div class="col-sm-2">
                                                <img width=40rem src="../../assets/information.png">
                                                </div>
                                              <div class="col-sm-7 ">
                                              <label class="ml-1 mt-2 mr-2">No.Of Visit:</label>
                                              </div>
                                              <div class="col-sm-3">
                                              <label class="text-right mt-2 ml-3"> {{NoOfVisits}}</label>
                                              </div>
                                              </div>

                                              <mat-divider class="col-sm-12"></mat-divider>
                                
                                              <div class="row pt-2 pb-2 titlecolor col-sm-12">
                                              <div class="col-sm-2">
                                                <img width=40rem src="../../assets/growth.png">
                                                </div>
                                              <div class="col-sm-7">
                                              <label class="ml-1 mt-2 mr-2">Total Business :</label>
                                              </div>
                                              <div class="col-sm-3">
                                              <label class="text-right mt-2 ml-3"> {{totalAmount}}</label>
                                              </div>
                                              </div>
                                         
                                              <mat-divider class="col-sm-12"></mat-divider>
                                
                                              <div class="row pt-2 pb-2 titlecolor col-sm-12">
                                            <div class="col-sm-2">
                                              <img width=40rem src="../../assets/change.png">
                                              </div>
                                            <div class="col-sm-7">
                                            <label class="ml-1 mt-2 mr-2">No.Of Reference :</label>
                                            </div>
                                            <div class="col-sm-3">
                                            <label class="text-right mt-2 ml-3"> {{refData}}</label>
                                            </div>
                                            </div>
                                           
                                       <mat-divider class="col-sm-12"></mat-divider>
                                        </div>
                                      </mat-list>
                                  </div>
                              </div>
                          </div>

                          </div>  
                      </div>
                      <div class="col-sm-2 pl-4 pr-2">
                          <div class="row">
                              <div class="card boxsssss divbodyf borderradius mb-2 fixeds">
                                <!-- <form [formGroup]="checkupDetailsForm" novalidate> -->
                                  <div class="col-sm-12">   
                                      <div class="row">
                                          <!-- <div class="col-sm-3 p-0">
                                              <h1 class="titlecolor"> Checkup Details </h1>
                                          </div> -->
                                         <div class="col-sm-12 text-center">
                                          <img title="Back" class="p-2" width=50rem src="../../assets/back.png" [routerLink]="['/home']">
                                          <img title="Patient History" matTooltip="Patient History" class="p-2" width=50rem src="../../assets/contact-form.png" (click)="chnagetable('patient')">
                                          <img title="Payment History" matTooltip="Payment History" class="p-2" width=50rem src="../../assets/get-money.png" (click)="chnagetable('payment')">
                                          <img  title="Product History" matTooltip="Product History" class="p-2" width=50rem src="../../assets/qa.png" (click)="chnagetable('product')">
                                          <img title="Repair History"  matTooltip="Repair History"  class="p-2" width=50rem src="../../assets/repair.png" (click)="chnagetable('repair')">
                                          <!-- <img title="this will be displayed as a tooltip"   class="p-1" width=40rem src="../../assets/review.png"> -->
                                         </div>
                                       
                                       
                                      </div>
                                  </div>
                                  
                                <!-- </form> -->
                              </div>
                          </div>
                      </div>
                     
  
                      </div>
                      <div class="col-sm-7 pl-4 pr-4">
                          <div class="row">
                              <div class="card divbody boxsssss borderradius  divbodyf p-3 mb-2 scroll" >
                                  <div class="col-sm-12">
                                      <div class="row" *ngIf="patient">
                                          <div class="col-sm-12">
              
                                              <h1 class="titlecolora2">Patient History</h1>
                                              
                                          </div>
                                          <div class="col-sm-12 p-0">
                                              <div id="accordion">
                                                <div id="accordion">
                                              
                                                  <div class="card" *ngFor="let datee of historyData">
                                                    <div class="card-header" >
                                                      <a class="collapsed card-link"  data-toggle="collapse" href="#{{datee._id}}">
                                                      {{datee.createdAt | date:'medium'}}
                                                      </a>
                                                    </div>
                                                    <div id="{{datee._id}}" class="collapse" data-parent="#accordion">
                                                      <div class="card-body">
                                                        <span class="colorp">Complaints :</span> {{datee.complaints}}
                                                        <br>
                                                        <span class="colorp">Observations :</span> {{datee.observations}}
                                                        <br>
                                                        <span class="colorp">Diagnoses :</span>  {{datee.diagnoses}}
                                                       <br>
                                                       <span class="colorp">Notes :</span> {{datee.notes}}
                                                      </div>
                                                    </div>
                                                  </div> 
                                                  <!-- <div *ngIf="historyData.length == 0">
                                                    <div *ngFor="let value of allPreFix" class="accordion accordion_custom mb_50">
                                                        <div class="card " id="accordion_ex">
                                                          <div class="card-header" id="headingOne">
                                                            <h2 class="mb-0">
                                                              <a href="#" class="btn collapsed" type="button" data-toggle="collapse"
                                                                [attr.data-target]="'#collapse'+ value.name" aria-expanded="false" aria-controls="collapseOne">
                                                                kk
                                                              </a>
                                                            </h2>
                                                          </div>
                                              
                                                          <div [attr.id]="'collapse'+ value.name" class="collapse" aria-labelledby="headingOne"
                                                            data-parent="#accordion_ex">
                                                            <div class="card-body">
                                                              <div class="row">
                                                                <div class="QA_table">
                                                                  <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper no-footer">
                                                                    <table class="table lms_table_active2 dataTable no-footer dtr-inline" id="DataTables_Table_0"
                                                                      role="grid">
                                                                      <tbody>
                                              
                                                                        <tr role="row" class="odd">
                                                                          <td tabindex="0" class="sorting_1">Description </td>
                                                                          <td>hg
                                                                          </td>
                                                                        </tr>
                                                                        <tr role="row" class="odd">
                                                                          <td tabindex="0" class="sorting_1">Issue Reported At</td>
                                                                          <td>
                                                                            31/DEC/2020  7:30 AM
                                                                          </td>
                                                                        </tr>
                                                                        <tr role="row" class="even">
                                                                          <td tabindex="0" class="sorting_1">Attended By</td>
                                                                          <td>Shiv </td>
                                                                        </tr>
                                                                        <tr role="row" class="odd">
                                                                          <td tabindex="0" class="sorting_1">Status</td>
                                                                          <td>Done</td>
                                                                        </tr>
                                                                        <tr role="row" class="even">
                                                                          <td tabindex="0" class="sorting_1">Remark</td>
                                                                          <td>90</td>
                                                                        </tr>
                                                                      </tbody>
                                                                    </table>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        </div>
                                                  </div> -->
                                                 
                                                </div>
                                                </div>
                                              
                                          </div>
                                      </div>
                                      <div class="row" *ngIf="payment">
                                        <div class="col-sm-12">
            
                                            <h1 class="titlecolora2"> Payment History</h1>
                                            
                                        </div>
                                        <div class="col-sm-12 p-0">
                                            <div id="accordion">
                                              <div id="accordion">
                                            
                                                <div class="card" *ngFor="let item of paymentData">
                                                  <div class="card-header" >
                                                    <a class="collapsed card-link"  data-toggle="collapse" href="#{{item._id}}">
                                                    {{item.createdAt | date:'medium'}}
                                                  
                                                    </a>
                                                  </div>
                                                  <div id="{{item._id}}" class="collapse" data-parent="#accordion">
                                                    <div class="card-body">
                                                      <span class="colorp">Bill No :</span> {{item.billNo}}
                                                      <br>
                                                      <span class="colorp">Total Amount :</span> {{item.totalAmount}}
                                                      <br>
                                                      <span class="colorp">Balance Amount :</span>  {{item.balanceAmount}}
                                                     <br>
                                                     <span class="colorp">Status :</span> {{item.paymentStatus}}
                                                    </div>
                                                  </div>
                                                </div>
                                                <div *ngIf="paymentData.length == 0">
                                                  <h2> No Payment History Records </h2>
                                                </div>
                                               
                                              </div>
                                              </div>
                                            
                                        </div>
                                    </div>
                                  
                                    <div class="row" *ngIf="product">
                                      <div class="col-sm-12">
          
                                          <h1 class="titlecolora2"> Product History</h1>
                                          
                                      </div>
                                      <div class="col-sm-12 p-0">
                                          <div id="accordion">
                                            <div id="accordion">
                                          
                                              <div class="card" *ngFor="let item of productData">
                                                <div class="card-header" >
                                                  <a class="collapsed card-link"  data-toggle="collapse" href="#{{item._id}}">
                                                 Model :{{item.model}}
                                                 <!-- Status : {{item.status}} -->
                                                  </a>
                                                </div>
                                                <div id="{{item._id}}" class="collapse" data-parent="#accordion">
                                                  <div class="card-body">
                                                    <span class="colorp">Model :</span> {{item.model}}
                                                    <br>
                                                    <span class="colorp">Serial No. :</span> {{item.serialNumber}}
                                                    <br>
                                                    <span class="colorp">MRP :</span>  {{item.mrp}}
                                                   <br>
                                                   <span class="colorp">Warranty :</span>From - To
                                                  </div>
                                                </div>
                                              </div>
                                              <div *ngIf="productData.length == 0">
                                                <h2> No Payment History Records </h2>
                                              </div>
                                             
                                            </div>
                                            </div>
                                          
                                      </div>
                                  </div>
                                      <div class="row" *ngIf="repair">
                                        <div class="row col-sm-12 pr-0">
                                          <div class="col-sm-6">
                                            <mat-form-field class="example-full-width lable_style2 widthh" appearance="outline">
                                              
                                              <mat-label class="lable_style2"> Repair From Date</mat-label>
                                              <input matInput [matDatepicker]="repairDateOf" readonly
                                              placeholder="Choose a date" formControlName="repairDateOf"
                                                 >
                                  
                                              <mat-datepicker-toggle matSuffix [for]="repairDateOf">
                                              </mat-datepicker-toggle>
                                              <mat-datepicker #repairDateOf ></mat-datepicker>
                                          
                                            </mat-form-field>
                                          </div>
                                          <div class="col-sm-6 pr-0">
                                            <mat-form-field class="example-full-width lable_style2 widthh" appearance="outline">
                                              
                                              <mat-label class="lable_style2">Repair To Date</mat-label>
                                              <input matInput [matDatepicker]="repairDateto" placeholder="Choose a date" formControlName="repairDateto"
                                                (dateChange)="onSelectRepairDate()" readonly>
                                          
                                              <mat-datepicker-toggle matSuffix [for]="repairDateto">
                                              </mat-datepicker-toggle>
                                              <mat-datepicker #repairDateto ></mat-datepicker>
                                          
                                            </mat-form-field>
                                          </div>
                                          <div class="col-sm-12 pr-0">
                                            <mat-form-field class="example-full-width lable_style2 widthh" appearance="outline">
                                              <mat-label class="lable_style2">Select Repair No With Date</mat-label>
                                              <mat-select formControlName="repairNo">
                                          
                                                <mat-option  *ngFor="let repair of repairList" [value]="repair._id" (click)="onSelectRepairNo(repair._id)">
                                                  Repair No.-{{ repair.repairId }} : {{repair.repairDate | date}} </mat-option>
                                          
                                              </mat-select>
                                          
                                            </mat-form-field>
                                        </div>
                                        </div>
                                          <div class="col-sm-12">
                                              <h1 class="titlecolora2"> Repair History</h1>
                                          </div>
                                          <div class="col-sm-12 ">
                                            <div *ngIf="statusArr.length == 0">
                                              <h2 class="pl-3"> No Records</h2>
                                            </div>
                                            <mat-stepper  orientation="vertical" [linear]="!isLinear" #stepper>  
                                              <mat-step  *ngFor="let item of statusArr;let i = index;" [completed]="item.comments.length > 0 ? true : false">
                                                <!-- <form> -->
                                                  
                                                <ng-template matStepLabel>{{item.labelOne}} - {{item.datetime | date:"medium"}} 
                                                  <br>
                                                  <label>{{item.labeltwo}}</label>
                                                </ng-template>
                                                
                                                <h2 *ngIf="item.comments.length > 0"> {{item.comments}}</h2>
                                              </mat-step>
                                              <ng-template matStepperIcon="edit">
                                                <mat-icon>check</mat-icon>
                                              </ng-template>
                                              <ng-template matStepperIcon="done">
                                                <mat-icon>done_all</mat-icon>
                                              </ng-template>
                                            </mat-stepper>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                 
            
          </div>
      </div>
    </form>
  </div>