import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss']
})
export class AppointmentComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<AppointmentComponent>,@Inject(MAT_DIALOG_DATA) public data:any,) { 
    }

  ngOnInit(): void {
  }
  close(): void {
    this.dialogRef.close();
  }
}
