<div class="container-fluid bgcolor2" [formGroup]="dashboardForm" >
        <form [formGroup]="dashboardForm" autocomplete="off" novalidate>  
    <div class="row col-sm-12 p-0 m-0 body">
      <div class="col-sm-12 mt-2">
            <div class="row">
              <div class="col-sm-12">
                <div class="white_card card_height_100 mb_30 overflow_hidden"
                >
                  <div class="white_card_header">
                    <div class="box_header m-0 row">
                      <div class="main-title mt-3 col-sm-2">
                        <h3 class="m-0 mt-2">Stock In Hand</h3>
                      </div>
                      <!-- <div class="col-sm-2 mt-1 mb-1" >
                        <mat-form-field class="example-full-width "  appearance="outline">
                          <mat-label>Select Type</mat-label>
                          <mat-select (click)="selectBranch()" class="colorblack" formControlName="selectType" >
                   
                            <mat-option value="Monthly"  (click)="selectType('Monthly')">Monthly</mat-option>
                            <mat-option value="Yearly" (click)="selectType('Yearly')">Yearly</mat-option>
                            <mat-option value="Custom" (click)="selectType('Custom')">Custom</mat-option>
                          </mat-select> 
                        </mat-form-field>
                        </div>
                        <div class="col-sm-2 mt-1 mb-1" *ngIf="isFromToVisible">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Form Date</mat-label>
                            <input matInput placeholder="Date" [matDatepicker]="formDate" readonly formControlName="formDate" [max]="currentdate" (dateChange)="selectFromdate($event)"/>
                            <mat-datepicker-toggle matSuffix [for]="formDate">
                            </mat-datepicker-toggle>
                            <mat-datepicker #formDate></mat-datepicker>
                          </mat-form-field>
                          </div>
                          <div class="col-sm-2 mt-1 mb-1" *ngIf="isFromToVisible">
                            <div class="row">
                                <div class="col-sm-10 pr-1">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>To Date</mat-label>
                              <input formControlName="toDate" class="colorblack date_font"  matInput [max]="laterDate"
                              [matDatepicker]="toDate" readonly  id="toDate" (dateChange)="toDatee($event)">
                          <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
                          <mat-datepicker #toDate></mat-datepicker>
  
                            </mat-form-field>
                            </div>
                            <div class="col-sm-1 Refreshwidth  text-center mt-2 pr-0 mr-0 pl-0 ml-0">
                                <a (click)="refres()" class="align-self-center ms-1 add-icon highlighted-color"><span class="material-symbols-outlined fontSize mt-2">refresh</span></a>
                               
                              </div>
                              </div>
                            </div>
                          
                              <div class="col-sm-2 mt-1 mb-1">
                             
                                    <mat-form-field class="example-full-width" appearance="outline">
                                      <mat-label>Branch
                                       </mat-label>
                                 
                                      <mat-select #select3 
                                      (click)="fetchBranchList();" formControlName="branchRefId">
                                        
                                        <mat-select-filter *ngIf="select3.focused" [placeholder]="'Filter'" [displayMember]="'branchName'"
                                          [array]="BranchList" (filteredReturn)="filteredListBranch =$event"></mat-select-filter>
                                          <mat-option value="" (click)="branchClick('','');">All</mat-option>
                                          <mat-option *ngFor="let item of filteredListBranch" [value]="item._id"
                                          (click)="branchClick(item._id,item.branchName);">
                                          {{ item.branchName }}</mat-option>
                                          
                                      </mat-select>
                          
                                    </mat-form-field>
                          
                            </div> -->
                           
                    </div>
                  </div> 
                  <div class="white_card_body pb-0">
                    <div class="Sales_Details_plan_a">
                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <canvas baseChart
                           [data]="stockInHandArr"
                           [labels]="stockInHandLabels" 
                           [chartType]="pieChartType"
                           [legend]="barChartLegend"
                           [options]="barChartOptions" 
                           [colors]='chartColors'></canvas>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      </div>
      <div class="col-sm-10"></div>
      <!-- <div class="col-sm-2 mt-2">
        <button (click)="dowload();" class="btn btn-primary btn-sm btnCol example-full-width">Download</button>
      </div> -->
      <div class="col-sm-2 mt-2">
        <button mat-dialog-close class="btn btn-primary btn-sm btnCol example-full-width">Close</button>
      </div>
    </div>
    </form>
  </div>