import { Component, OnInit } from '@angular/core';
import { MasterComponent } from "../master/master.component";
import { MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WhatsAppPopupComponent } from '../whats-app-popup/whats-app-popup.component';
import { ApiServiceService } from "../api-service.service";
@Component({
  selector: 'app-side-navbar',
  templateUrl: './side-navbar.component.html',
  styleUrls: ['./side-navbar.component.scss']
})
export class SideNavbarComponent implements OnInit {
  listOfAlert: any;
  branchRef: string;

  constructor(public dialog: MatDialog,
    public apimicroservice: ApiServiceService,) { }
  userPermission: any;
  userType: string;
  homeView= true;
  repairComplaintView= true;
  bookAppointmentsView= true;
  todaysAppointmentView= true;
  settingsView= true;
  chequeAlertView= true;
  whatsAppIntegrationView= true;
  ngOnInit(): void {
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    this.userType = sessionStorage.getItem('userType');
    this.branchRef = sessionStorage.getItem('branchRef');
    if(this.userType !== 'Super Admin'){
      this.userPermission.forEach((element) => {
        if(element.pageName == 'Home' && element.view == false)
        {
            this.homeView = false;  
        }
        if(element.pageName == 'Repair Complaint' && element.view == false)
        {
            this.repairComplaintView = false;  
        }    
        if(element.pageName == 'Book Appointments' && element.view == false)
        {
            this.bookAppointmentsView = false;  
        }    
        if(element.pageName == 'Todays Appointment' && element.view == false)
        {
            this.todaysAppointmentView = false;  
        }    
        if(element.pageName == 'Settings' && element.view == false)
        {
            this.settingsView = false;  
        }    
        if(element.pageName == 'Settings' && element.view == false)
        {
            this.settingsView = false;  
        }  
        if(element.pageName == 'Alert' && element.view == false)
        {
            this.chequeAlertView = false;  
        }
        if(element.pageName == 'Whats App Integration' && element.view == false)
        {
            this.whatsAppIntegrationView = false;  
        }
      });
    }
    
    this.getAlertCount();
  }
  getAlertCount() {
    let branchRef= sessionStorage.getItem('branchRef');
      if(this.userType !== 'Super Admin')
      {
        this.apimicroservice.getAlertStatusCount(this.branchRef).subscribe(res => {
          this.listOfAlert = res.countData.totalViewByUser;     
       });
      }
      else
      {
        this.apimicroservice.getAlertStatusCount(branchRef).subscribe(res => {
          this.listOfAlert = res.countData.totalViewByUser;     
       });
      }
         
  }
  openMasterPopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(MasterComponent,
      {
        width: "1400px",
      });
  } 
  // WhatsApp()
  // { 
  //   let mobileNumber = sessionStorage.getItem('whatsAppNumber');
  //   window.open("https://wa.me/91"+mobileNumber);
  // }
  // {
  //   width: "1200px",
  //   height: "570px"
  // }
  openWhatsAppPopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(WhatsAppPopupComponent,
      {
          width: "400px",
        }
      );
  }
}
