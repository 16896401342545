<div class="container-fluid"  [formGroup]="customHaPrintForm">
    <form [formGroup]="customHaPrintForm" autocomplete="off" novalidate>
      <div class="row pr-2 pl-2">
          <div class="col-sm-12 ">
           
                <div class="col-sm-12">
                  
                  <div class="row">
                    <div class="col-sm-11" ></div>
                    <div class="col-sm-1" > 
                    
                      <div class="row no-print justify-content-end ">
                        <a (click)="printThisPage()" class="d-flex justify-content-end align-items-end ms-3">
                          <!-- <span class="material-symbols-outlined pr-2">print</span>Print -->
                          <span class="material-symbols-outlined">
                            print
                            </span>Print
                        </a>

                     </div>
                     </div>
                     <div class="col-sm-12 ">
                      <div class="row"> 
                        <table id="toBillingLst"
                        class="table table-hover mb-0 pb-0 table-bordered">
                                 
                              
                        <tbody >
                        
                          <tr role="row"
                           class="odd tw">
                            
                           <td class=" mt-0 pt-0 mb-0 pt-0">  
                            <div class="row w2 pt-2  mt-0 pt-0 mb-0 pt-0">
                              <div class="col-sm-12  mt-0 pt-0 mb-0 pt-0"
                              style="display:block;" >
                                <img *ngIf="!logoimg" class="img2 mt-2 pt-0 mb-0 pt-0"
                                width="100%" height="100%"
                            src="../../assets/clinic_logoDemo.png" alt="Amanda Wilson" >
                            <img *ngIf="logoimg" width="100%" height="100%"
                           [src]="logoimg" alt=" photo" class="img2  mt-2 pt-0 mb-0 pt-0">
                            </div>
                            </div>
                          </td>
                          <td class="pr-0  mr-2 pl-0 ml-0 text-center justify-content-center pt-1 pb-0 ">
                            <div class="row w1">
                              <div class="col-sm-12  mb-0 pb-0">
                                <div class="row">
                                  <div class="col-sm-12">
                            <strong class="auto-style2 fass mb-0 pb-0 mt-0 pt-0">
                                <h1 class="fsize" *ngIf="reportName !== ''">{{reportName}} </h1>
                                <h1 class="fsize" *ngIf="reportName == ''">Clinic Name</h1>
                             </strong>
                             </div>
                             </div>
                             </div>

                              <div class="col-sm-12  mt-0 pt-0">
                                <div class="row">
                                  <div class="col-sm-12">
       
                                    <label class="col-sm-12" *ngIf="reportName !== ''">
                                      {{reportAddress}}, {{taluka}} , {{district}}, {{state}},</label>
                                      <label class="col-sm-12 mt-1" *ngIf="reportName !== ''">
                                      {{country}} - {{reportPinCode}}</label>
                                  </div>
                            </div> 
                            </div>

                            <div class="col-sm-12 mt-2 text-center justify-content-center">
                              <div class="row">
                                <div class="col-sm-12  pb-2">
                              <label *ngIf="reportName !== ''">Mobile No :
                                <b>
                                  {{reportPhoneNumber | slice:0:2}} {{reportPhoneNumber | slice:2:4}} {{reportPhoneNumber | slice:4:7}} {{reportPhoneNumber | slice:7:10}},
                                  {{reportPhoneNumber1 | slice:0:2}} {{reportPhoneNumber1 | slice:2:4}} {{reportPhoneNumber1 | slice:4:7}} {{reportPhoneNumber1 | slice:7:10}},
                                </b>
                              
                              </label>
                              </div>
                            <div class="col-sm-12 pb-2 text-center justify-content-center">
                            <label class="trans">Email Id :{{emailAdd}}</label> 
                            </div>
                            <div class="col-sm-12 pb-2 text-center justify-content-center">
                              <label class="trans">Website :
                                {{website}} </label> 
                              </div>
                          </div>
                          </div>
                          
                          </div>
                              </td> 
                               
                                <td class="w2 pt-1 pb-0 text-center
                                justify-content-center">
                              
                                   <div class="row marginTopp">
                                     <!-- <div class="col-sm-12 pb-2">
                                      <strong>
                                GST Number :{{companyGst}}
                                </strong>
                                </div> -->
                                 <!-- <br> -->
                                 <div class="col-sm-12 pb-2">
                                 <strong> Weekly Off : {{reportWeeklyOff}}</strong>
                                 </div>
                                 <br>
                                 <div class="col-sm-12">
                                 <strong class="labelMin" *ngIf="openTime !== '' &&
                                  closeTime !== ''">From<br> {{openTime}}<br> To<br> {{closeTime}}</strong> 
                                  </div>
                              </div>
                               </td> 
                      
                             </tr>
                             
                      </tbody> 
                        </table>
                                        <div class="col-sm-12 mt-2 mb-0 pt-0 pb-0">
                                          <h1 class="text-center mt-0 mb-0 pt-0 pb-0">Custom Hearing Aid</h1></div>
                                          <table id="toBillingLst"
                                           class="table table-hover mb-3 pt-0 pb-3 table-bordered">
                                  <thead class="bcol">
                                      <th class="pt-1 bcol pb-0 ">
                                        <label>Name :</label>  
                                      </th>
                                      <th colspan="4" class=" bcol pt-1 pb-0">
                                  
                                        <label *ngIf="prifix !== '' && prifix !== null
                                        && prifix !== undefined; else elseBlock">{{prifix}}. {{firstName}} {{lastName}}</label>
                                       <ng-template #elseBlock>
                                         <label >{{firstName}} {{lastName}} </label>
                                       </ng-template>  
                                    </th>
                                       <th class="pt-1 bcol pb-0 ">
                                        <label >Date : {{currentdate | date: 'dd/MM/yyyy'}}</label>
                                          </th>
                                   </thead> 
                                   <thead  class="bcol"> 
                                    <th class="pt-1 bcol pb-0 ">
                                     
                                      <label>Address :</label>
                                       </th>
                                     <th colspan="4"  class="width1 bcol pt-1 pb-0 ">
                                      <label>
                                        {{streetAddress1}} {{taluka1}} {{district1}} {{state1}} 
                                        {{country1}} {{pincode1}}
                                        </label>
                                      </th>
                                     <th class="pt-1 bcol pb-0 ">
                                      <label > Time : {{currentdate | date: 'h:mm a'}}</label>
                                    </th>
                                     <!-- <th class="width1 pt-1 bcol pb-0">Date</th>
                                     <th class="width1 pt-1 bcol pb-0">{{invoiceDate | date: 'dd/MM/yyyy'}}</th> -->
                                 </thead>
                               
                               </table>
                           
                                          <table id="toBillingLst"
                                          class="table table-hover mt-4 table-bordered">
                                            <thead>
                                              <tr class="text-center">
                                                  <th class=" pt-1 pb-1">Hearing Aid Company Name</th>
                                                  <th class=" pt-1 pb-1">CAN Number</th>
                                                  <th class=" pt-1 pb-1">Make</th>
                                              </tr>
                                          </thead> 
                                          <tbody >
                                            <tr 
                                            class="text-center">
                                             <td class="pt-1 pb-0">{{companyName}}</td>     
                                              <td class="width1 pt-1 pb-0">{{companyCanNumber}}</td>
                                              <td class="width1 pt-1 pb-0">{{companyMake}}</td>
                                               </tr>     
                                        </tbody>
                                          </table>
                                          <table id="toBillingLst"
                                          class="table table-hover table-bordered">
                                            <thead>
                                              <tr class="text-center">
                                                  <th colspan="2" class=" pt-1 pb-1">Right</th>
                                                  <th colspan="2" class=" pt-1 pb-1">Left</th>
                                             </tr>
                                          </thead> 
                                          <tbody >
                                            <tr
                                            class="text-left">
                                             <td class="pt-1 pb-0 boldd">Model</td>     
                                           <td class="width1 pt-1 pb-0">{{rightModel}}</td>
                                           <td class="width1 pt-1 pb-0 boldd">Model</td>
                                           <td class="width1 pt-1 pb-0">{{leftModel}}</td>
                                               </tr>   
                                               <tr
                                               class="text-left">
                                                <td class="pt-1 pb-0 boldd">Make</td>     
                                              <td class="width1 pt-1 pb-0">{{rightMake}}</td>
                                              <td class="width1 pt-1 pb-0 boldd">Make</td>
                                              <td class="width1 pt-1 pb-0">{{leftMake}}</td>
                                                  </tr> 
                                                  <tr
                                                  class="text-left">
                                                   <td class="pt-1 pb-0 boldd">Battery</td>     
                                                 <td class="width1 pt-1 pb-0">{{rightBattery}}</td>
                                                 <td class="width1 pt-1 pb-0 boldd">Battery</td>
                                                 <td class="width1 pt-1 pb-0">{{leftBattery}}</td>
                                                     </tr>
                                                     <tr
                                                     class="text-left">
                                                      <td class="pt-1 pb-0 boldd">Style </td>     
                                                    <td class="width1 pt-1 pb-0">{{rightStyle}}</td>
                                                    <td class="width1 pt-1 pb-0 boldd">Style </td>
                                                    <td class="width1 pt-1 pb-0">{{leftStyle}}</td>
                                                        </tr>
                                                        <tr
                                                        class="text-left">
                                                         <td class="pt-1 pb-0 boldd">Vent </td>     
                                                       <td class="width1 pt-1 pb-0">{{rightVent}}</td>
                                                       <td class="width1 pt-1 pb-0 boldd">Vent </td>
                                                       <td class="width1 pt-1 pb-0">{{leftVent}}</td>
                                                           </tr>

                                                           <tr
                                                           class="text-left">
                                                            <td class="pt-1 pb-0 boldd">Removal String</td>     
                                                          <td class="width1 pt-1 pb-0">{{rightRemovalString}}</td>
                                                          <td class="width1 pt-1 pb-0 boldd">Removal String</td>
                                                          <td class="width1 pt-1 pb-0">{{leftRemovalString}}</td>
                                                              </tr>


                                                           <!-- <tr
                                                           class="text-left">
                                                            <td class="pt-1 pb-0 boldd">Removal String</td>     
                                                          <td class="width1 pt-1 pb-0">{{rightRemovalString}}</td>
                                                          <td class="width1 pt-1 pb-0 boldd">Removal String</td>
                                                          <td class="width1 pt-1 pb-0">{{leftRemovalString}}</td>
                                                              </tr>    -->

                                                              <tr
                                                              class="text-left">
                                                               <td class="pt-1 pb-0 boldd">Matrix </td>     
                                                             <td class="width1 pt-1 pb-0">{{rightMatrix}}</td>
                                                             <td class="width1 pt-1 pb-0 boldd">Matrix </td>
                                                             <td class="width1 pt-1 pb-0">{{leftMatrix}}</td>
                                                                 </tr>
                                                                 <tr
                                                                 class="text-left">
                                                                  <td class="pt-1 pb-0 boldd">Shell </td>     
                                                                <td class="width1 pt-1 pb-0">{{rightShell}}</td>
                                                                <td class="width1 pt-1 pb-0 boldd">Shell </td>
                                                                <td class="width1 pt-1 pb-0">{{leftShell}}</td>
                                                                    </tr>
                                                                    <tr
                                                                    class="text-left">
                                                                     <td class="pt-1 pb-0 boldd">Faceplate </td>     
                                                                   <td class="width1 pt-1 pb-0">{{rightFaceplate}}</td>
                                                                   <td class="width1 pt-1 pb-0 boldd">Faceplate </td>
                                                                   <td class="width1 pt-1 pb-0">{{leftFaceplate}}</td>
                                                                       </tr>
                                                                       <tr
                                                                       class="text-left">
                                                                        <td class="pt-1 pb-0 boldd">Program Switch </td>     
                                                                      <td class="width1 pt-1 pb-0">{{rightProgramSwitch}}</td>
                                                                      <td class="width1 pt-1 pb-0 boldd">Program Switch </td>
                                                                      <td class="width1 pt-1 pb-0">{{leftProgramSwitch}}</td>
                                                                          </tr>
                                                                          <tr
                                                                          class="text-left">
                                                                           <td class="pt-1 pb-0 boldd">Volume Control </td>     
                                                                         <td class="width1 pt-1 pb-0">{{rightVolumeControl}}</td>
                                                                         <td class="width1 pt-1 pb-0 boldd">Volume Control </td>
                                                                         <td class="width1 pt-1 pb-0">{{leftVolumeControl}}</td>
                                                                             </tr>
                                        </tbody>
                                          </table>
                                     
                                         
                                              <label class="mt-3 mb-2">
                                                <b>Audiogram : </b></label>
                                          
                                          <table id="toBillingLst"
                                          class="table mt-1 pt-1 table-hover table-bordered">
                                            <thead>
                                              <tr class="text-center">
                                                <th class=" pt-1 pb-1"></th>
                                                  <th class=" pt-1 pb-1">250 Hz</th>
                                                  <th class=" pt-1 pb-1">500 Hz</th>
                                                  <th class=" pt-1 pb-1">1 KHz</th>
                                                  <th class=" pt-1 pb-1">2 KHz</th>
                                                  <th class=" pt-1 pb-1">4 KHz</th>
                                                  <th class=" pt-1 pb-1">6 KHz</th>
                                                  <th class=" pt-1 pb-1">8 KHz</th>
                                             </tr>
                                          </thead> 
                                          <tbody >
                                            <tr
                                            class="text-center">
                                             <td class="pt-1 pb-0 boldd text-left">Right</td>     
                                           <td class="width1 pt-1 pb-0">{{right250HZ}}</td>
                                           <td class="width1 pt-1 pb-0">{{right500HZ}}</td>
                                           <td class="width1 pt-1 pb-0">{{right1KHZ}}</td>
                                           <td class="width1 pt-1 pb-0">{{right3KHZ}}</td>
                                           <td class="width1 pt-1 pb-0">{{right4KHZ}}</td>
                                           <td class="width1 pt-1 pb-0">{{right6KHZ}}</td>
                                           <td class="width1 pt-1 pb-0">{{right8KHZ}}</td>
                                               </tr>   
                                               <tr
                                               class="text-center">
                                               <td class="pt-1 pb-0 boldd text-left">Left</td> 
                                                <td class="pt-1 pb-0 boldd">{{left250HZ}}</td>     
                                              <td class="width1 pt-1 pb-0">{{left500HZ}}</td>
                                              <td class="width1 pt-1 pb-0">{{left1KHZ}}</td>
                                              <td class="width1 pt-1 pb-0">{{left3KHZ}}</td>
                                              <td class="width1 pt-1 pb-0">{{left4KHZ}}</td>
                                              <td class="width1 pt-1 pb-0">{{left6KHZ}}</td>
                                              <td class="width1 pt-1 pb-0">{{left8KHZ}}</td>
                                                  </tr> 
                                        </tbody>
                                          </table>
                                          <div class="col-sm-12 pl-4 mt-3 labelClass">
                                            <br>
                                          <label>श्रवणयंत्र दुरुस्तीला देताना.....   </label><br> <br>
                                          <label class="mt-2">  १) श्रवणयंत्र वापरणाऱ्यांच्या जबाबदारीवरच आम्ही नवी, जुनी श्रवणयंत्रे दुरुस्त करतो.</label><br>  
                                          <label class="mt-3">  २) श्रवणयंत्रे दुरुस्तीला देताना त्याचे मोल्ड, रिसीव्हर, कॉर्ड, बॅटरी इ.सुटे भाग परत दिले जातात.</label><br> 
                                          <label class="mt-3">  ३) दुरुस्तीच्या खर्चाचा अंदाज आपणांस दिला जाईल व आपली संमती घेतल्यावरच आपले श्रवणयंत्र दुरूस्त केले जाईल.</label><br> 
                                          <label class="mt-3">  ४) बदललेल्या पार्टसूची वॉरंटी ३ महिने राहील.</label><br> 
                                          <label class="mt-3">  ५) श्रवणयंत्र दुरूस्त झाल्यापासून २ महिन्यांत न नेल्यास व्यवस्थापनाची जबाबदारी राहणार नाही.</label><br> 
                                          <label class="mt-3">  ६) दुरुस्त झालेले श्रवणयंत्र परत नेताना कृपया ही पावती आणावी अन्यथा यंत्र मिळणार नाही.</label><br> 
                                          <label class="mt-3">  ७) श्रवणयंत्र दुरूस्तीला वेळ लागल्यास व्यवस्थापन जबाबदार राहणार नाही. त्यासंबंधी कंपनीशी आम्ही सतत संपर्कात असतो.</label><br> 
                                          <label class="mt-3">  ८) श्रवणयंत्र दुरूस्तीनंतर आलेला खर्चदेणे आवश्यक राहील.</label><br> 
                                    
                                          </div>
                                            <div class="col-sm-3 mt-5">
                                              <br><br><br>  <br><br><br>
                                              <!-- <br><br><br>  <br><br><br> -->
                                              <!-- <br><br><br>  <br><br><br>
                                              <br><br><br>  <br><br><br>
                                              <br><br><br>  <br><br><br>
                                              <br><br><br>  <br><br><br>
                                              <br><br><br>  <br><br><br> -->
                                                <h2 hidden class="mt-5 text-center">Clients Signature</h2>
                                            </div>
                                            <div class="col-sm-7"></div>
                                            <div class="col-sm-2 mt-5">
                                              <br><br><br>  <br><br><br>
                                              <!-- <br><br><br>  <br><br><br> -->
                                              <!-- <br><br><br>  <br><br><br>
                                              <br><br><br>  <br><br><br>
                                              <br><br><br>  <br><br><br>
                                              <br><br><br>  <br><br><br>
                                              <br><br><br>  <br><br><br> -->
                                                <h2 class="mt-5 ml-1">Signature</h2>
                                            </div>
                                            
                                          </div>
                                          </div>
                                       
                      </div>
  
                  </div>
              
  
          </div>
      </div>
      </form>
  </div>