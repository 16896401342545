import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from "../api-service.service";
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import {Router,ActivatedRoute} from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-service-rate',
  templateUrl: './service-rate.component.html',
  styleUrls: ['./service-rate.component.scss']
})
export class ServiceRateComponent implements OnInit {
  size:number = 10;
  page:number = 1;
  updateButton = false;
  submitButton = true;
  serviceRateForm: FormGroup;
  userType: any;
  permissionList=[];
  loggedId: any;
  gstCheckbox = true;
  _id: any;
  serviceData:any[] = [];
  listOfBilling: any;
  clinicRef: string;
  filteredServiceRate:any[] = [];
  filteredBillingCompany: any;
  testTypeArr: any[] = [];
  filteredList1: any[] = [];
  constructor( public apimicroservice: ApiServiceService,
    private fb: FormBuilder,
    public router: Router,private route: ActivatedRoute,
    private spinner: NgxSpinnerService) { }
    userPermission:any;
    serviceRateCreate= true;
    serviceRateUpdate= true;
  ngOnInit(): void {
    this.clinicRef = sessionStorage.getItem('clinicRef');
    //
    
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    this.userType = sessionStorage.getItem('userType');
    if(this.userType !== 'Super Admin'){
    this.userPermission.forEach((element) => {
      if(element.pageName == 'Service Rate' && element.create == false)
      {
          this.serviceRateCreate = false;  
      }
      if(element.pageName == 'Service Rate' && element.update == false)
      {
          this.serviceRateUpdate = false;  
      }    
    });
  }
    this.serviceRateForm = this.fb.group({
      // "service": new FormControl(''), 
      // "billingCompany": new FormControl(''), 
      // "rate": new FormControl(''), 
      // "gstApplicable": new FormControl(''), 
    
      "searchServiceRate": new FormControl(''),  
      "serviceRateInfo": this.fb.array([]),
      
    });
   
    //this.addNew();
    this.fetchBillingCompanyDetails();
    this.fetchServices();
   // this.prepopulation();
    this.getAllBranch();
  }
  getAllBranch()
  {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      if (res.status) {        
        this.serviceRateForm.get("branchRefId").setValue(res.data[0]._id);
      }
    });
  }

  submitServiceRate() {
    const creds = this.serviceRateForm.controls.serviceRateInfo as FormArray;  
    //
   //
   ////
   
    // if(this.serviceRateForm.invalid) {   
    //   // this.serviceRateForm.get('branchRefId').markAsTouched();
    //   // this.serviceRateForm.get('deviceName').markAsTouched();
    //   // this.serviceRateForm.get('serialNumber').markAsTouched();
    //   // this.serviceRateForm.get('transporterName').markAsTouched();
    
    // }
    if(creds.invalid)
    {
      creds.controls.forEach(element =>{
    
        element.get('serviceRefId').markAsTouched();
        element.get('testType').markAsTouched();
        element.get('billingCompanyRefId').markAsTouched();
        element.get('rate').markAsTouched();
        element.get('gstApplicable').markAsTouched();
        if( element.get('gstApplicable').value === "Yes"){
          element.get('hasSac').markAsTouched();
          element.get('sgst').markAsTouched();
          element.get('cgst').markAsTouched(); 
        }
      });
         
    }
    else{
     this.addServiceRate();
    }
}

updateServiceRate(){
  const creds = this.serviceRateForm.controls.serviceRateInfo as FormArray;  
    //
   //
   
    if(creds.invalid)
    {
      creds.controls.forEach(element =>{
    
        element.get('serviceRefId').markAsTouched();
        element.get('testType').markAsTouched();
        element.get('billingCompanyRefId').markAsTouched();
        element.get('rate').markAsTouched();
        element.get('gstApplicable').markAsTouched();
        if( element.get('gstApplicable').value === "Yes"){
          element.get('hasSac').markAsTouched();
          element.get('sgst').markAsTouched();
          element.get('cgst').markAsTouched();
        }
      });
         
    }
    else{
     this.updateRate();
    }
}

updateRate() {
  let payload = this.serviceRateForm.value;
  payload.clinicRefId = this.clinicRef;
  this.spinner.show();
  this.apimicroservice.updateServiceRate(this._id,payload).subscribe(res => {
   // //
    this.spinner.hide();
    if(res.status){
      this.apimicroservice.openSnackbar(res.message);
      
    }else{
      //this.apimicroservice.openSnackbar(res.message);
    }
  });
}

addServiceRate(){
  let payload = this.serviceRateForm.value;
  payload.clinicRefId = this.clinicRef;
  //
  this.spinner.show();
  this.apimicroservice.addServiceRate(payload).subscribe(res => {
    this.spinner.hide();
    if(res.status){
      this.apimicroservice.openSnackbar(res.message);
      this.ngOnInit();
      //this.prepopulation();
    }else{
      //this.apimicroservice.openSnackbar(res.message);
    }
  });  
}

  prepopulation(){
    this.spinner.show();
    let id = '';
    let i = 0;
    this.apimicroservice.getServiceRateById(id,this.clinicRef).subscribe(res => {
      this.spinner.hide();
     let data = res.data[0];
     //
     if(data == undefined){
      this.addNew(i);
     }else{
      if(res.status){
        this.updateButton = true;
        this.submitButton = false;
        this.serviceRateForm = this.fb.group({
          //"batchname": new FormControl(data.batchname,[Validators.required]),  
          "serviceRateInfo": this.fb.array([]),
        });
        let preArr = [];
        data.serviceRateInfo.forEach((element,i) => {
      
          preArr.push(element);
          //       
          const tmpDict = {};
          tmpDict['serviceRefId'] = new FormControl(element.serviceRefId,[Validators.required]);
          tmpDict['testType'] = new FormControl(element.testType,[Validators.required]);
          tmpDict['serviceName'] = new FormControl(element.serviceName),
          tmpDict['billingCompanyRefId'] = new FormControl(element.billingCompanyRefId,[Validators.required]);
          tmpDict['billingCompanyName'] = new FormControl(element.billingCompanyName),
          tmpDict['rate'] = new FormControl(element.rate,[Validators.required,Validators.pattern("^[0-9 .]*$"),Validators.maxLength(6)]);
          tmpDict['gstApplicable'] = new FormControl(element.gstApplicable);
          tmpDict['hasSac'] = new FormControl(element.hasSac,Validators.pattern("^[0-9.]*$"));
          tmpDict['sgst'] = new FormControl(element.sgst,[Validators.pattern("^[1-9]\\d*(\\.\\d+)?$"),Validators.maxLength(3)]);
          tmpDict['cgst'] = new FormControl(element.cgst,[Validators.pattern("[1-9]\\d*(\\.\\d+)?$"),Validators.maxLength(3)]);
          tmpDict['igst'] = new FormControl(element.igst,[Validators.pattern("[1-9]\\d*(\\.\\d+)?$"),Validators.maxLength(3)]);
          const creds = this.serviceRateForm.controls.serviceRateInfo as FormArray;
           creds.push(new FormGroup(tmpDict));
           //
          // this.checkTestType(element.serviceRefId,element.serviceName,i);
          this.checkTestType(i)
           this.gstApplicable(i);
          });

          this._id = data._id;
          //
      }
     }
       
    });
  }
  deleteRow(index){
    (<FormArray>this.serviceRateForm.get("serviceRateInfo")).removeAt(index);
  }
  addNew(i) {
    let tempservices = [];
    tempservices = this.serviceData[i];
    //  tempservices = this.testTypeArr[i];
    const creds = this.serviceRateForm.controls.serviceRateInfo as FormArray;
        creds.push( 
        this.fb.group({
          
          "serviceRefId": new FormControl('',[Validators.required]),
          "serviceName" : new FormControl(''),
          "testType": new FormControl('',[Validators.required]),
          "billingCompanyRefId": new FormControl('',[Validators.required]),  
          "billingCompanyName" : new FormControl(''), 
          "rate": new FormControl('',[Validators.required,Validators.pattern("^[0-9 .]*$"),Validators.maxLength(5)]),   
          "gstApplicable": new FormControl('No',[Validators.required]),
          "hasSac": new FormControl('',Validators.pattern("^[0-9.]*$")),      
          "sgst": new FormControl('',[Validators.pattern("^[0-9]*$"),Validators.maxLength(3)]),     
          "cgst": new FormControl('',[Validators.pattern("^[0-9]*$"),Validators.maxLength(3)]),   
          "igst": new FormControl('',[Validators.pattern("^[0-9]*$"),Validators.maxLength(3)]),    
           toggle: false

})
);
let serviceInfo = (<FormArray>this.serviceRateForm.get("serviceRateInfo")).at(i);
//
// if(serviceInfo !== undefined){
//   let gstval = serviceInfo.get('gstApplicable').value;
//   if(gstval == "Yes"){
//     serviceInfo.get('hasSac').enable();
//     serviceInfo.get('sgst').enable();
//     serviceInfo.get('cgst').enable();
//     serviceInfo.get('igst').enable();     
//   }else{
//     serviceInfo.get('hasSac').disable();
//     serviceInfo.get('sgst').disable();
//     serviceInfo.get('cgst').disable();
//     serviceInfo.get('igst').disable();
//   }
// }else{
//   serviceInfo.get('hasSac').disable();
//   serviceInfo.get('sgst').disable();
//   serviceInfo.get('cgst').disable();
//   serviceInfo.get('igst').disable();
// }

    let serviceid = serviceInfo.get('serviceRefId').value;
    //let serviceid = serviceInfo.get('testType').value;
     i = i + 1;
    //
    let serviceInfoNext = (<FormArray>this.serviceRateForm.get("serviceRateInfo")).at(i);
   serviceInfoNext.get('hasSac').disable();
    serviceInfoNext.get('sgst').disable();
    serviceInfoNext.get('cgst').disable();
    serviceInfoNext.get('igst').disable();

    tempservices = tempservices.filter(m => m._id!=serviceid);
   this.serviceData[i] = tempservices;
   this.filteredServiceRate[i] = this.serviceData[i].slice();
  // tempservices = tempservices.filter(m => m.testType!=serviceid);
  //  this.testTypeArr[i] = tempservices;
  //  this.filteredList1= this.testTypeArr[i].slice();
  }
  redirect(to): void {
    this.router.navigate([to]);
   }
  onback() {
    this.apimicroservice.back()
  }
  onPageChange(event)
  {
  }
  gstApplicable(i) 
  {
    let serviceControl = (<FormArray>this.serviceRateForm.get("serviceRateInfo")).at(i);
    let gstApplicable = serviceControl.get("gstApplicable").value;
    if(gstApplicable == 'Yes')
      { 
        //this.gstCheckbox = false; 
        //
        serviceControl.get('hasSac').enable();
        serviceControl.get('sgst').enable();
        serviceControl.get('cgst').enable();
        serviceControl.get('igst').enable();
        serviceControl.get('hasSac').setValidators([Validators.required,Validators.pattern("^[0-9.]*$")]);
        serviceControl.get('hasSac').updateValueAndValidity();
        serviceControl.get('sgst').setValidators([Validators.required,Validators.maxLength(3)]);
        serviceControl.get('sgst').updateValueAndValidity();
        serviceControl.get('cgst').setValidators([Validators.required,Validators.maxLength(3)]);
        serviceControl.get('cgst').updateValueAndValidity();
      }
      else
      {
       // this.gstCheckbox = true; 
        //
        serviceControl.get('hasSac').disable();
        serviceControl.get('sgst').disable();
        serviceControl.get('cgst').disable();
        serviceControl.get('igst').disable();
        serviceControl.get('sgst').setErrors(null);
        serviceControl.get('sgst').clearValidators();
        serviceControl.get('sgst').updateValueAndValidity();
        serviceControl.get('sgst').setValue('');

        serviceControl.get('hasSac').setErrors(null);
        serviceControl.get('hasSac').clearValidators();
        serviceControl.get('hasSac').updateValueAndValidity();
        serviceControl.get('hasSac').setValue('');

        serviceControl.get('cgst').setErrors(null);
        serviceControl.get('cgst').clearValidators();
        serviceControl.get('cgst').updateValueAndValidity();
        serviceControl.get('cgst').setValue('');
       
      }
  }

  fetchBillingCompanyDetails(){
    let id='';
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getBillingDataById(id,clinicRef).subscribe(res => {
      //
      this.listOfBilling = res.data;
      this.filteredBillingCompany= this.listOfBilling.slice();
      if(res.status){
        //this.apimicroservice.openSnackbar(res.message);
        
      }else{
        //this.apimicroservice.openSnackbar(res.message);
      }
    });
  }

  fetchServices(){
    let i = 0;
    let id='';
    this.spinner.show();
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
    this.apimicroservice.getServiceById(id,branchRef,clinicRef).subscribe(res => {
      //
      this.spinner.hide();
     // this.serviceData[i] = res.data;
      const ids = res.data.map(o => o.serviceType);
      let temparr = [];
      let allServices = [];
     // temparr = res.data;
     // allServices = temparr.filter(({serviceType},index) => !ids.includes(serviceType, index + 1))
     // this.serviceData[i] = allServices;
     this.serviceData[i] = res.data;
      this.filteredServiceRate[i] = this.serviceData[i].slice();
      //this.filteredServiceRate= allServices.slice();
      if(res.status){
        this.prepopulation();
        //this.apimicroservice.openSnackbar(res.message);
        
      }else{
        //this.apimicroservice.openSnackbar(res.message);
      }
    });
  }else{
    this.apimicroservice.getServiceById(id,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
      //
      this.spinner.hide();
     // this.serviceData[i] = res.data;
      const ids = res.data.map(o => o.serviceType);
      let temparr = [];
      let allServices = [];
     // temparr = res.data;
     // allServices = temparr.filter(({serviceType},index) => !ids.includes(serviceType, index + 1))
     // this.serviceData[i] = allServices;
     this.serviceData[i] = res.data;
      this.filteredServiceRate[i] = this.serviceData[i].slice();
      //this.filteredServiceRate= allServices.slice();
      if(res.status){
        this.prepopulation();
        //this.apimicroservice.openSnackbar(res.message);
        
      }else{
        //this.apimicroservice.openSnackbar(res.message);
      }
    });
  }
  }
  onSelectService(id,sname,i){ 
    let tempservices = [];
    let tempArr = this.serviceRateForm.get("serviceRateInfo").value;
    //
    //
    //
    let control = (<FormArray>this.serviceRateForm.get("serviceRateInfo")).at(i);
    control.patchValue({
      serviceName: sname
  });
  let clinicRef= sessionStorage.getItem('clinicRef');
  let branchRef= sessionStorage.getItem('branchRef');
  let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
  let branchRefSuperAdmin= '';
  if (this.userType !== 'Super Admin') {
  this.apimicroservice.getTestTypeDataByService(sname,branchRef,clinicRef).subscribe(res => {
    ////
    let serviceRef = control.get('serviceRefId').value;
    control.get('testType').setValue(res.data[0].testType);
    
    if(res.status){
      //this.apimicroservice.openSnackbar(res.message);
      
    }else{
      //this.apimicroservice.openSnackbar(res.message);
    }
  });
}else{
  this.apimicroservice.getTestTypeDataByService(sname,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
    ////
    let serviceRef = control.get('serviceRefId').value;
    control.get('testType').setValue(res.data[0].testType);
    
    if(res.status){
      //this.apimicroservice.openSnackbar(res.message);
      
    }else{
      //this.apimicroservice.openSnackbar(res.message);
    }
  });
}

  }
  onSelectBillingComp(bname,i){
    //
    //
    let control = (<FormArray>this.serviceRateForm.get("serviceRateInfo")).at(i);
    control.patchValue({
      billingCompanyName: bname
  });
  }

  toServiceForm() {
    document.getElementById("toServiceForm").scrollIntoView();
  }
  toServiceList() {
    document.getElementById("toServiceList").scrollIntoView();
  }
 
  cancelUpdate(){
    this.serviceRateForm.reset();
     this.submitButton=true;
     this.updateButton=false;
    this.ngOnInit();
  }

  checkTestType(i){
    // let tempservices = [];
    // let tempArr = this.serviceRateForm.get("serviceRateInfo").value;
    // //
    // //
    // //
    // let control = (<FormArray>this.serviceRateForm.get("serviceRateInfo")).at(i);
    // //
    // this.apimicroservice.getTestTypeDataByService(sname).subscribe(res => {
    //   //
    //   this.testTypeArr[i] = res.data;
    //   this.filteredList1[i]= this.testTypeArr[i].slice();
    //   //
    //   let serviceRef = control.get('serviceRefId').value;
    //   let testName = control.get('testType').value;
    //   if(i > 0){
      
    //   tempservices = this.testTypeArr[i];
    //   tempArr.forEach(element =>{
    //     if(element.serviceRefId == serviceRef && element.testType !== testName){
    //       tempservices = tempservices.filter(m => m.testType!=element.testType);
    //     }
    //   });
    //   //
    //   this.testTypeArr[i] = tempservices;
    //   this.filteredList1[i]= this.testTypeArr[i].slice();
    //   }
      
    //   if(res.status){
    //     //this.apimicroservice.openSnackbar(res.message);
        
    //   }else{
    //     //this.apimicroservice.openSnackbar(res.message);
    //   }
    // });

    let tempservices = [];
    tempservices = this.serviceData[i];
    
    let serviceInfo = (<FormArray>this.serviceRateForm.get("serviceRateInfo")).at(i);
    //

    let serviceid = serviceInfo.get('serviceRefId').value;
    //let serviceid = serviceInfo.get('testType').value;
     i = i + 1;
    //
    let serviceInfoNext = (<FormArray>this.serviceRateForm.get("serviceRateInfo")).at(i);

    tempservices = tempservices.filter(m => m._id!=serviceid);
   this.serviceData[i] = tempservices;
   this.filteredServiceRate[i] = this.serviceData[i].slice();

  //  serviceInfoNext.get('hasSac').disable();
  //  serviceInfoNext.get('sgst').disable();
  //  serviceInfoNext.get('cgst').disable();
  //  serviceInfoNext.get('igst').disable();
  
  }
  igstCalculate(i)
  {
    let serviceControl = (<FormArray>this.serviceRateForm.get("serviceRateInfo")).at(i);
    let sgst = serviceControl.get("sgst").value;
    let cgst = serviceControl.get("cgst").value;
    let igst = parseInt(sgst) + parseInt(cgst);
    serviceControl.get('igst').setValue(igst);
  }

  
}
