import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  FormArray 
} from "@angular/forms";
 import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { AfterViewInit, Directive, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";import { PopUpBranchComponent } from "../pop-up-branch/pop-up-branch.component";
import { PopUpPatientsourceComponent } from "../pop-up-patientsource/pop-up-patientsource.component";
import { PopUpPatientComponent } from "../pop-up-patient/pop-up-patient.component";
import { PatientSearchPopComponent } from "../patient-search-pop/patient-search-pop.component";
import { HttpClient } from "@angular/common/http";
 @Component({
  selector: 'app-inquiry', 
  templateUrl: './inquiry.component.html',
  styleUrls: ['./inquiry.component.scss']
})
export class InquiryComponent implements OnInit {

  errormessage: string;
  searchText: any;
  InquiryId: any;
  allState=[
    {name:'Andaman and Nicobar Islands',value:'AN' },
    {name:'Andhra Pradesh',value:'AP'},
    { name:'Arunachal Pradesh',value:'AR' },
{name:'Assam',value:'AS' },
{ name:'Bihar',value:'BR'},
 { name:'Chandigarh',value:'CH' },
{name:'Chhattisgarh',value:'CT' },
{ name:'Dadra and Nagar Haveli', value:'DN' },
 { name:'Daman and Diu', value:'DD'},
 { name:'Delhi',value:'DL'},
 {name:'Goa',value:'GA' },
{name:'Gujarat', value:'GJ'}, 
{name:'Haryana',value:'HR'}, 
{name:'Himachal Pradesh',value:'HP'}, 
{ name:'Jammu and Kashmir',value:'JK'}, 
 {name:'Jharkhand',value:'JH'}, 
{ name:'Karnataka', value:'KA'},
{ name:'Kerala',value:'KL'},
{name:'Ladakh',value:'LA'},
 {name:'Lakshadweep',value:'LD' },
 {name:'Madhya Pradesh', value:'MP'},
{ name:'Maharashtra',value:'MH'},
 { name:'Manipur',value:'MN'},
{ name:'Meghalaya', value:'ML' },
{ name:'Mizoram',value:'MZ' },
{ name:'Nagaland',value:'NL' },
 { name:'Odisha',value:'OR'},
 { name:'Puducherry', value:'PY'},
 {name:'Punjab', value:'PB' },
{ name:'Rajasthan', value:'RJ'},           
 {name:'Punjab',value:'PB'},   
 {name:'Rajasthan', value:'RJ'},
{name:'Sikkim',value:'SK' },
{ name:'Tamil Nadu',value:'TN'}, 
 {name:'Telangana',value:'TG'},   
  { name:'Tripura',  value:'TR' },
 { name:'Uttar Pradesh', value:'UP'},
{name:'Uttarakhand',value:'UT'},  
{ name:'West Bengal', value:'WB' },                        
  ]
  filteredList: { name: string; value: string; }[];
  gstCheckbox= true;
  userType: string;
  clinicRef: string;
  clinicId: string;
  BranchList: any;
  filteredListBranch: any;
   superBranchId: any;
   branchRef: string;
   branchName: string;
   convertMsg=false;
   filteredListPatientSource: any;
   allPatientSource: any;
   isPatient= false;
  isDoctor= false;
  isAdvertiesment= false;
  isSocialMedia= false;
  isOther=false;
   patientList: any;
   filteredListPatient: any;
   bID: any;
   stateCityArr2: any[];
  stateCity2: any;
  districtData2: any;
  filteredList2: { state: string; districts: string; }[];
  filteredListDistrict2: { district: string;}[];
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
   public apimicroservice: ApiServiceService,
    public router: Router,
     public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    private httpClient: HttpClient,
  ) {}
  inquiryForm: FormGroup;
  status: any;
  minDate: Date;
  submitButton = true;
  updateButton = false;
  deleteButton=false;
  cancelButton = false;
  loggedId: any;
  myimage;
  date: any;
  size=10;
  page=1;
  InquiryData: any; 
  totalLength:any;
  listofInquiry:any;
  userPermission:any;
  InquiryCreate= true;
  InquiryUpdate= true;
  isSuperAdmin= true;
  checkboxx:boolean = false;
  isredirecttoPtion= false;
  currentdate:any;
  ngOnInit(): void {
    this.page=1;
    this.isPatient=false, 
    this.isDoctor=false,
    this.isAdvertiesment=false, 
    this.isSocialMedia=false,
    this.isOther=false
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.date=new Date();
    this.filteredList = this.allState.slice();
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    this.userType = sessionStorage.getItem('userType');
    this.branchRef = sessionStorage.getItem('branchRef');
    this.branchName = sessionStorage.getItem('branchName');
    if(this.userType !== 'Super Admin'){
      this.isSuperAdmin= false;
    this.userPermission.forEach((element) => {
      if(element.pageName == 'Inquiry' && element.create == false)
      {
          this.InquiryCreate = false;  
      }
      if(element.pageName == 'Inquiry' && element.update == false)
      {
          this.InquiryUpdate = false;  
      }  
    });
  }
    this.inquiryForm = this.fb.group({
 
      "clinicRefId": new FormControl(''),
      "branchRefId": new FormControl(''),
     // "inquiryId": new FormControl(''),
      "branchWiseId": new FormControl(''),
      "branchName": new FormControl(''),
      "inquiryType": new FormControl('',Validators.required),
      "firstName": new FormControl('',[Validators.required, Validators.pattern("^[a-zA-Z-\\s]*$")]),
      "middleName": new FormControl('',[Validators.required, Validators.pattern("^[a-zA-Z-\\s]*$")]),
      "lastName": new FormControl('',[Validators.required, Validators.pattern("^[a-zA-Z-\\s]*$")]),
      "sex": new FormControl(''),
      "mobile": new FormControl('',[Validators.pattern("(0|91)?[6-9][0-9]{9}")]),
      "email": new FormControl('',[Validators.pattern("(?![0-9]+@)[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}")]),
      "age": new FormControl('',[Validators.pattern("^[0-9]*$")]),
      "dob": new FormControl(''),
      "streetAddress": new FormControl(''),
      "taluka": new FormControl('',Validators.pattern("^[a-zA-Z ]*$")),
      "district": new FormControl('',[Validators.pattern("^[a-zA-Z ]*$")]),
      "state": new FormControl(''),
      "country": new FormControl('', [Validators.pattern("[A-Za-z _-]{3,19}$"), ]),
      "pincode": new FormControl('',[Validators.pattern("^[0-9]{6}$")]),
      "createdAt": this.date,
      "searchInquiry": new FormControl(''),
      "redirectToPatient" :new FormControl(false),
      "convertTopatient": new FormControl(''),
      "clientSource": new FormControl(),

      "doctorDropDown": new FormControl(),
      "advertisement": new FormControl(), 
      "socialMedia": new FormControl(), 
      "patientName": new FormControl(),
      "remark": new FormControl(),
      "date": new FormControl(),
    });
    let latest_date = this.datepipe.transform(this.date, 'dd/MM/yyyy');
    this.inquiryForm.get("date").setValue(latest_date);
 
  this.inquiryForm.controls["country"].setValue("India");
  this.inquiryForm.controls["inquiryType"].setValue("In-Person");
  this.inquiryForm.controls["state"].setValue("Maharashtra");
  let arr=[]
    arr = [
      "Ahmednagar",
      "Akola",
      "Amravati",
      "Aurangabad",
      "Beed",
      "Bhandara",
      "Buldhana",
      "Chandrapur",
      "Dhule",
      "Gadchiroli",
      "Gondia",
      "Hingoli",
      "Jalgaon",
      "Jalna",
      "Kolhapur",
      "Latur",
      "Mumbai City",
      "Mumbai Suburban",
      "Nagpur",
      "Nanded",
      "Nandurbar",
      "Nashik",
      "Osmanabad",
      "Palghar",
      "Parbhani",
      "Pune",
      "Raigad",
      "Ratnagiri",
      "Sangli",
      "Satara",
      "Sindhudurg",
      "Solapur",
      "Thane",
      "Wardha",
      "Washim",
      "Yavatmal"
  ]
    this.getDistrict2(arr);
  this.currentdate = new Date();
  this.minDate = new Date(1900,0,1);
    this.fetchInquiry();
    this.getLastId();
    this.fetchBranchList();
    this.getAllBranch();
    this.fetchPatientSourceList();
    this.fetchAllPatientList();
    this.getState2(); 
  }

  getState2()
  {
   this.httpClient.get("assets/state-city.json").subscribe(data =>{ 
     this.stateCity2 = data;
     this.stateCityArr2 = [];
     this.stateCity2.states.forEach((element) => {
       this.stateCityArr2.push(element);
     });
     this.filteredList2 = this.stateCityArr2.slice();
     //
   })
  }
  getDistrict2(state)
  {
   //
   this.districtData2 = [];
   state.forEach((element) => {
     //
     this.districtData2.push({"district":element});
   });
   //
   let arr = [];
   arr = state;
  this.filteredListDistrict2 = this.districtData2.slice();
  }
  getAllBranch()
  {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      if (res.status) {        
        let length= res.data.length - 1;
        this.branchName =res.data[length].branchName;
        this.inquiryForm.controls["branchRefId"].setValue(res.data[length]._id);
        this.branchClick(res.data[length]._id,this.branchName);
        if(this.userType == 'Super Admin')
        {  
          this.apimicroservice.getBranchById(res.data[length]._id).subscribe(res => {
            this.spinner.hide();
            this.inquiryForm.get("branchName").setValue(res.data[length].branchName);
           });
        }
       
      } 
    });
  }
  fetchBranchList() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      //  
      if (res.status) {    
        this.BranchList = res.data;
        this.filteredListBranch= this.BranchList.slice();
      } else {
       // this.apimicroservice.openSnackbar(res.message);
      }
    })
  } 
  submitInquiry() {
    if (this.inquiryForm.invalid) {
      this.inquiryForm.get("inquiryType").markAsTouched();
      this.inquiryForm.get("firstName").markAsTouched();
      this.inquiryForm.get("middleName").markAsTouched();
      this.inquiryForm.get("lastName").markAsTouched();
      this.inquiryForm.get("sex").markAsTouched();
      this.inquiryForm.get("age").markAsTouched();
      this.inquiryForm.get("email").markAsTouched();
      this.inquiryForm.get("streetAddress").markAsTouched();
       this.inquiryForm.get("district").markAsTouched();
       this.inquiryForm.get("state").markAsTouched();
       this.inquiryForm.get("country").markAsTouched();
       this.inquiryForm.get("pincode").markAsTouched();
       this.inquiryForm.get("mobile").markAsTouched();
      return;
    } else {
      this.addInquiry();
    }
  }


  updateInquiryDetails() {
  //  this.setValidor();
    if (this.inquiryForm.invalid) {
      this.inquiryForm.get("mobile").markAsTouched();
      this.inquiryForm.get("firstName").markAsTouched();
      this.inquiryForm.get("middleName").markAllAsTouched();
      this.inquiryForm.get("lastName").markAsTouched();
      this.inquiryForm.get("sex").markAsTouched();
      this.inquiryForm.get("age").markAsTouched();
      this.inquiryForm.get("email").markAsTouched();
      this.inquiryForm.get("streetAddress").markAsTouched();
       this.inquiryForm.get("district").markAsTouched();
       this.inquiryForm.get("state").markAsTouched();
       this.inquiryForm.get("country").markAsTouched();
       this.inquiryForm.get("pincode").markAsTouched();
      return;
    } else {
      this.updateInquiry();
    }
  }

  // updateUpdateSingleCustomer() {
  //   if (this.inquiryForm.invalid) {
  //     // this.inquiryForm.get("companyName").markAsTouched();
  //     // this.inquiryForm.get("inquiryId").markAsTouched();
  //     // this.inquiryForm.get("companyRegisteredAddress").markAsTouched();
  //     // this.inquiryForm.get("registeredUnderGST").markAsTouched();
  //     // this.inquiryForm.get("companyGST").markAsTouched();
  //     // this.inquiryForm.get("companyContactNumber").markAsTouched();
  //     // this.inquiryForm.get("companyPAN").markAsTouched();
  //     // this.inquiryForm.get("propriter").markAsTouched();
  //     // this.inquiryForm.get("companyType").markAsTouched();
  //     return;
  //   } else {
  //   //update code
  //   }
  // }
  
  addInquiry() {
    this.spinner.show();
    let payload = this.inquiryForm.value;
    //
    payload.firstName = payload.firstName.toLowerCase() ;
    payload.middleName = payload.middleName.toLowerCase() ;
    payload.lastName = payload.lastName.toLowerCase() ;
    payload.createdAt= new Date();
      // payload.image = this.myimage;
      // payload.locationImage = this.myLocationimage;
       //);
       if(this.userType !== 'Super Admin')
    {
      payload.branchRefId = this.branchRef;
      payload.clinicRefId = this.clinicRef;
      payload.branchName = this.branchName;
    }
    payload.convertTopatient = 'No';
      this.apimicroservice.saveInquiryData(payload).subscribe(res => {
        this.spinner.hide();
        if (res.status) {
          this.apimicroservice.openSnackbar(res.message);
          this.ngOnInit();
          // this.inquiryForm.reset();
          // this.fetchInquiry();
        } else {
          this.apimicroservice.openSnackbar(res.message);
        }   
      },(ERROR:HttpErrorResponse) => {
        this.apimicroservice.openSnackbar(ERROR.error.message);
        this.spinner.hide();
    });
  }
  openSnackbar(message: string) {
    return this._snackBar.open(message, "close", {
      verticalPosition: "bottom",
      horizontalPosition: "right",
      duration: 2000,
      panelClass: ["snackbarStyle"],
    });
  }
  
  redirectTo(){ 
    //
    sessionStorage.setItem('InquiryId','true');
    this.router.navigate(['/home/patient-master'],{ queryParams: { InquiryId: this.InquiryId } })
  }

  fetchInquiry() {
    let pg = 1;
    let size = 10;
    let search='';
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRef1= sessionStorage.getItem('clinicRef');
    let branchRef1='';
    this.spinner.show();
    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getInquiry(pg,size,search,branchRef,clinicRef).subscribe(res => {
        this.spinner.hide();
        //
        this.listofInquiry = res.data.data;
        this.totalLength=res.data.totalItems;
        if(res.status){
          //this.apimicroservice.openSnackbar(res.message);
          
        }else{
          //this.apimicroservice.openSnackbar(res.message);
        }
      });
    } else {
      this.apimicroservice.getInquiry(pg,size,search,branchRef1,clinicRef1).subscribe(res => {
        this.spinner.hide();
        //
        this.listofInquiry = res.data.data;
        this.totalLength=res.data.totalItems;
        if(res.status){
          //this.apimicroservice.openSnackbar(res.message);
          
        }else{
          //this.apimicroservice.openSnackbar(res.message);
        }
      });
      
    }
    
  }
  branchClick(id,branchName)
  {

    this.inquiryForm.controls["branchName"].setValue(branchName);

   this.apimicroservice.getBranchById(id).subscribe(res => { 
     //
       
     let data = res.data[0];
     let payload = this.inquiryForm.value;
      payload.clinicRefId = res.data[0].clinicRefId;
      this.inquiryForm.controls["clinicRefId"].setValue(res.data[0].clinicRefId);
      if(this.updateButton == false){
        this.spinner.show();
        this.apimicroservice.getLastInquiryID(payload.branchRefId).subscribe(res => {
          //   
         
          if (res['status']) {
            let InqIdd = res['data']['totalItems'];
            //this.inquiryForm.controls['branchWiseId'].setValue(InqIdd + 1);
           this.bID=InqIdd + 1;
          } else {
            this.apimicroservice.openSnackbar("Something went wrong");
          }
          this.spinner.hide();
        })
      }
    });
  }

  ageCalculate()
{
  let payload = this.inquiryForm.value;
  // 
  
  if (payload.dob) {
    var timeDiff = Math.abs(Date.now() - new Date(payload.dob).getTime());
    let age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
    //
    this.inquiryForm.controls["age"].setValue(age);
      }
    
} 
  prepopulation(id) {
    this.checkboxx = true;
    this.spinner.show();
    this.apimicroservice.getInquiryDataById(id).subscribe(res => {
      this.spinner.hide();
      this.submitButton=false;
       this.updateButton =true;
      //  this.deleteButton= true;
      this.cancelButton=true;
    
       let data = res.data[0];
 
         this.inquiryForm = this.fb.group({
         
          "clinicRefId": new FormControl(data.clinicRefId),
          "branchRefId": new FormControl(data.branchRefId),
         // "inquiryId": new FormControl(data.inquiryId),
          "branchWiseId": new FormControl(data.branchWiseId),
          "branchName": new FormControl(data.branchName),
          "inquiryType": new FormControl(data.inquiryType,Validators.required),
          "firstName": new FormControl(data.firstName,[Validators.required, Validators.pattern("^[a-zA-Z-\\s]*$")]),
          "middleName": new FormControl(data.middleName,[Validators.required, Validators.pattern("^[a-zA-Z-\\s]*$")]),
          "lastName": new FormControl(data.lastName,[Validators.required, Validators.pattern("^[a-zA-Z-\\s]*$")]),
          "sex": new FormControl(data.sex),
          "mobile": new FormControl(data.mobile,[Validators.pattern("(0|91)?[6-9][0-9]{9}")]),
          "email": new FormControl(data.email,[Validators.email,Validators.pattern("(?![0-9]+@)[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}")]),
          "age": new FormControl(data.age,[Validators.pattern("^[0-9]*$")]),
          "dob": new FormControl(data.dob),
          "streetAddress": new FormControl(data.streetAddress),
          "taluka": new FormControl(data.taluka,Validators.pattern("^[a-zA-Z ]*$")),
          "district": new FormControl(data.district,[Validators.pattern("^[a-zA-Z ]*$")]),
          "state": new FormControl(data.state),
          "country": new FormControl(data.country, [Validators.pattern("[A-Za-z _-]{3,19}$"), ]),
          "pincode": new FormControl(data.pincode,[Validators.pattern("^[0-9]{6}$")]),
          "updateddAt":new Date(),
          "searchInquiry": new FormControl(''),
          "redirectToPatient" :new FormControl(data.redirectToPatient),
          "convertTopatient": new FormControl(data.convertTopatient),
          "clientSource": new FormControl(data.clientSource),

          "doctorDropDown": new FormControl(data.doctorDropDown),
          "advertisement": new FormControl(data.advertisement),
          "socialMedia": new FormControl(data.socialMedia),
          "patientName": new FormControl(data.patientName),
          "remark": new FormControl(data.remark),
          "date": new FormControl(''),
         });
         let latest_date = this.datepipe.transform(data.createdAt, 'dd/MM/yyyy');
         this.inquiryForm.get("date").setValue(latest_date);
         this.patientClick();
         this.InquiryId = id;
         this.superBranchId = data.branchWiseId;
         this.bID= data.branchWiseId;
         if(data.convertTopatient == 'Yes')
         {
          this.convertMsg = true;
         }
         else
         {
          this.convertMsg = false;
         }
        //  this.myimage = data.image ? data.image : "../../assets/images/setting2.png";
        //  this.myLocationimage = data.locationImage ? data.locationImage : "../../assets/images/no-image-available.png";
     });

    
  }
  addNew() {
    this.inquiryForm.reset();
    this.updateButton = false;
    this.deleteButton = false;
  }
  updateInquiry() {
    this.spinner.show();
    let payload = this.inquiryForm.value;
    payload.firstName = payload.firstName.toLowerCase() ;
    payload.middleName = payload.middleName.toLowerCase() ;
    payload.lastName = payload.lastName.toLowerCase() ;
    if(this.userType !== 'Super Admin')
    {
      payload.branchRefId = this.branchRef;
      payload.clinicRefId = this.clinicRef;
      payload.branchName = this.branchName;
    }
    // payload.image = this.myimage;
    this.apimicroservice.updateInquiryData(this.InquiryId,payload).subscribe(res => {
      this.spinner.hide();
      this.ngOnInit();
      if (res.status) {
        this.apimicroservice.openSnackbar(res.message);
        this.submitButton=true;
      this.updateButton = false;
      this.cancelButton=false;
      // this.deleteButton=false;
      //this.ngOnInit();
      // this.inquiryForm.reset();
      // this.fetchInquiry();
      
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
      
    },
    );
    
  } 
  covertToPatient() {
    this.spinner.show();
    let payload = this.inquiryForm.value;
    if(this.userType !== 'Super Admin')
    {
      payload.branchRefId = this.branchRef;
      payload.clinicRefId = this.clinicRef;
      payload.branchName = this.branchName;
    }
     payload.convertTopatient = 'Yes';
    this.apimicroservice.updateInquiryData(this.InquiryId,payload).subscribe(res => {
      this.spinner.hide();
      this.router.navigate(['/home/patient-master'],{ queryParams: { InquiryId: this.InquiryId } })
      this.ngOnInit();
      if (res.status) {
        this.apimicroservice.openSnackbar(res.message);
        this.submitButton=true;
      this.updateButton = false;
      this.cancelButton=false;
      // this.deleteButton=false;
      //this.ngOnInit();
      // this.inquiryForm.reset();
      // this.fetchInquiry();
      
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
      
    },
    );
    
  }

   getLastId() 
   {
    if(this.userType !== 'Super Admin'){
      this.apimicroservice.getLastInquiryID(this.branchRef).subscribe(res => {
        //   
        // if(res['data']['data'] == '')
        // {
        //   this.inquiryForm.controls['branchWiseId'].setValue('1');
        // }
        if (res['status']) {
          let InqIdd = res['data']['totalItems'];
          //this.bID=  InqIdd + 1;
          this.inquiryForm.controls['branchWiseId'].setValue(InqIdd + 1);
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
       
      })
    }
    else
    {
      this.apimicroservice.getLastInquiryAll().subscribe(res => {
        //   
        // if(res['data']['data'] == '')
        // {
        //   this.inquiryForm.controls['branchWiseId'].setValue('1');
        // }
        if (res['status']) {
          let InqIdd = res['data']['totalItems'];
          this.superBranchId = InqIdd + 1;
         // this.bID=  InqIdd + 1;
          this.inquiryForm.controls['branchWiseId'].setValue(InqIdd + 1);
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
       
      })
    }
   }
 

  onPageChange(event) {
   // this.spinner.show();
    this.page = event;
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRef1= sessionStorage.getItem('clinicRef');
    let branchRef1='';
    //pg = this.page;
    this.searchText = this.inquiryForm.get('searchInquiry').value;
    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getInquiry(this.page,this.size,this.searchText,branchRef,clinicRef).subscribe(res => {
        //this.spinner.hide();
        //
        if (res.status) {
          this.listofInquiry = res.data.data;
          this.totalLength=res.data.totalItems;
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
  
      })
    } else {
      this.apimicroservice.getInquiry(this.page,this.size,this.searchText,branchRef1,clinicRef1).subscribe(res => {
       // this.spinner.hide();
        //
        if (res.status) {
          this.listofInquiry = res.data.data;
          this.totalLength=res.data.totalItems;
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
  
      })
      
    }
   
  }
  onKeyUpEvent(event: any) {
    // let pg = 1;

    // let size=5;
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRef1= sessionStorage.getItem('clinicRef');
    let branchRef1='';
    let page=1;
    let size=10;
    this.searchText = this.inquiryForm.get('searchInquiry').value;
    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getInquiry(page,size,this.searchText,branchRef,clinicRef).subscribe(res => {
       // //
        if (res.status) {
          this.listofInquiry = res.data.data;
          this.totalLength=res.data.totalItems;
          this.page=res.data.pageNumber;
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
  
      })
    } else {
      this.apimicroservice.getInquiry(page,size,this.searchText,branchRef1,clinicRef1).subscribe(res => {
        ////
        if (res.status) {
          this.listofInquiry = res.data.data;
          this.totalLength=res.data.totalItems;
          this.page=res.data.pageNumber;
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
  
      })
    }
   
  }
 
  redirect(to): void {
    this.router.navigate([to]);
  }
  
  cancelUpdate(){
    this.convertMsg = false;
    this.isPatient=false, 
    this.isDoctor=false,
    this.isAdvertiesment=false, 
    this.isSocialMedia=false,
    this.isOther=false
    this.inquiryForm.reset();
    this.submitButton=true;
    this.updateButton=false;
    this.cancelButton=false;


    this.ngOnInit();
  }

  toInquiryForm() {
    document.getElementById("toInquiryForm").scrollIntoView();
  }
  toInquiryList() {
    document.getElementById("toInquiryList").scrollIntoView();
  }
  
  openBranchPopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpBranchComponent,
      {
        width: "1200px",
      }); 
      dialogRef3.afterClosed().subscribe((result) => {
        let clinicRef= sessionStorage.getItem('clinicRef');
        this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
          //  
          if (res.status) {    
            this.BranchList = res.data;
            this.filteredListBranch= this.BranchList.slice();
            this.branchClick(res.data[0]._id,res.data[0].branchName);
            this.inquiryForm.get("branchRefId").setValue(res.data[0]._id);
          } else {
           // this.apimicroservice.openSnackbar(res.message);
          }
        })
        //this.fetchBranchList();
      })
  }
  WhatsApp()
  {
    let payload = this.inquiryForm.value;  
    let num = payload.mobile; 
    if(num.length == 10)
    {
      sessionStorage.setItem('whatsAppNumber',payload.mobile);
      let mobileNumber = sessionStorage.getItem('whatsAppNumber');
      window.open("https://wa.me/91"+mobileNumber);
    }
    else
    {
      this.inquiryForm.get("mobile").markAsTouched();
    }
  }
  fetchPatientSourceList() { 
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
    this.apimicroservice.getPatientSourceList(branchRef,clinicRef).subscribe(res => {
      //
       this.allPatientSource = res.data;   
       this.filteredListPatientSource = this.allPatientSource.slice();
    });
  }else{
    this.apimicroservice.getPatientSourceList(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
      //
       this.allPatientSource = res.data;   
       this.filteredListPatientSource = this.allPatientSource.slice();
    });
  }  
}
patientClick(){
  let payload =this.inquiryForm.value;
  //
if(payload.clientSource !== null && payload.clientSource !== '')
{
  if(payload.clientSource=='patient'){
    this.isPatient=true,
    this.isDoctor=false,
    this.isAdvertiesment=false,
    this.isSocialMedia=false,
    this.isOther=false
     }  
     else if(payload.clientSource=='doctor'){
      this.isPatient=false,
      this.isDoctor=true,
      this.isAdvertiesment=false,
      this.isSocialMedia=false,
      this.isOther=false
     }
     else if(payload.clientSource=='advertisement'){
      this.isPatient=false,
      this.isDoctor=false,
      this.isAdvertiesment=true,
      this.isSocialMedia=false,
      this.isOther=false
     }
     else if(payload.clientSource=='social media'){
      this.isPatient=false,
      this.isDoctor=false,
      this.isAdvertiesment=false,
      this.isSocialMedia=true,
      this.isOther=false
     }
     else {
      this.isPatient=false, 
      this.isDoctor=false,
      this.isAdvertiesment=false, 
      this.isSocialMedia=false,
      this.isOther=true
     }
}
  


//   if(payload.clientSource=='Patient'){
     
//  this.isPatient=true,
//  this.isDoctor=false,
//  this.isAdvertiesment=false,
//  this.isSocialMedia=false,
//  this.isOther=false
//   }
//   else if(payload.clientSource=='Doctor'){
//    //
//    this.isPatient=false,
//    this.isDoctor=true,
//    this.isAdvertiesment=false,
//    this.isSocialMedia=false,
//    this.isOther=false
//   }
//   else if(payload.clientSource=='Advertisement'){
//    this.isPatient=false,
//    this.isDoctor=false,
//    this.isAdvertiesment=true,
//    this.isSocialMedia=false,
//    this.isOther=false
//   }
//   else {
//    this.isPatient=false,
//    this.isDoctor=false,
//    this.isAdvertiesment=false,
//    this.isSocialMedia=false
//    this.isOther=true
//   }
 }
 openPatientSourcePopUp() {
  //localStorage.clear();
  const dialogRef3 = this.dialog.open(PopUpPatientsourceComponent,
    {
      width: "500px", 
    });

    dialogRef3.afterClosed().subscribe((result) => {
      let clinicRef= sessionStorage.getItem('clinicRef');
      let branchRef= sessionStorage.getItem('branchRef');
      let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
      let branchRefSuperAdmin= '';
      if (this.userType !== 'Super Admin') {
      this.apimicroservice.getPatientSourceList(branchRef,clinicRef).subscribe(res => {
        //
         this.allPatientSource = res.data;   
         this.filteredListPatientSource = this.allPatientSource.slice();
         this.inquiryForm.get("clientSource").setValue(res.data[0].patientSource);
      }); 
    }else{
      this.apimicroservice.getPatientSourceList(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
        //
         this.allPatientSource = res.data;   
         this.filteredListPatientSource = this.allPatientSource.slice();
         this.inquiryForm.get("clientSource").setValue(res.data[0].patientSource);
      }); 
    }
      //this.fetchPatientSourceList();  
     });
}
fetchAllPatientList() {
  this.apimicroservice.getAllPatientlist().subscribe(res => {
    //
    if (res.status) {    
      this.patientList = res.data.data;
      this.filteredListPatient= this.patientList.slice();
    } else {
      //this.apimicroservice.openSnackbar(res.message);
    }
  })
}
openPatientPopUp() {
  localStorage.clear();
  const dialogRef3 = this.dialog.open(PopUpPatientComponent,
    {
      width: "1200px",
    });
    dialogRef3.afterClosed().subscribe((result) => {
      this.fetchAllPatientList() 
     });
} 
openDepartmentPopUp() {
  const dialogRef3 = this.dialog.open(PatientSearchPopComponent, {
    width: "97%",
    height: "90%", 
  });
 
  dialogRef3.afterClosed().subscribe((result) => {
    //
    let middleName;
    if(result.data.middleName == null)
    {
      middleName = '';
    }
    else{
      middleName = result.data.middleName;
    }
    this.inquiryForm.get("patientName").setValue(result.data.firstName + ' ' + middleName + ' ' + result.data.lastName);
    this.inquiryForm.get("patientdropDown").setValue(result.data._id);
  });
} 
  onback() {
    this.apimicroservice.back()
  }

 myValidator() {
    var userBirthDate = this.inquiryForm.value.dob;
    var regexVar = new RegExp("([0-9]{2})\/([0-9]{2})\/([0-9]{4})");
   // var regexVarTest = regexVar.test(userBirthDate);
    if(!regexVar.test(userBirthDate))
    {
      //alert("Plese enter valid date format dd/mm/yyyy");
      //
        this.inquiryForm.controls['dob'].setValidators(Validators.pattern("/^(0?[1-9]|[12][0-9]|3[01])[\/](0?[1-9]|1[012])[\/]\d{4}$/"));
        this.inquiryForm.controls['dob'].updateValueAndValidity();
    }
    else{
        this.inquiryForm.controls['dob'].setErrors(null);
      this.inquiryForm.controls['dob'].clearValidators();
      this.inquiryForm.controls['dob'].updateValueAndValidity();
    }
   
  } 
//   _keyUp(event: any) {
//     
//     let payload = this.inquiryForm.value;
//     const pattern = new RegExp("^[0-9]*$");
//     let inputChar = String.fromCharCode(event.key);
// 
// ",payload.dob.test("^[0-9]*$"));
// 

//     if (pattern.test(payload.dob) == false && payload.dob !== '') {
//       event.preventDefault();
//     }
// }
//   dobClick(event)
//   {
//     
    
//     let payload = this.inquiryForm.value;
//     const input = document.getElementById('dob');
    
// input.oninput = (e) => {  
//   // const cursorPosition = event.target.selectionStart - 1;
//   const hasInvalidCharacters = payload.dob.match(/[^0-9/]/);

//   if (!hasInvalidCharacters) return;
  
//   // Replace all non-digits:
//   payload.dob = payload.dob.replace(/[^0-9/]/g, '');
  
//   // Keep cursor position:
//   // event.target.setSelectionRange(cursorPosition, cursorPosition);
// };
//   }
}