import { ActivatedRoute } from "@angular/router";
import { Component, Inject, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  FormArray
} from "@angular/forms";
 import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  MatSnackBar,
} from "@angular/material/snack-bar";
import { MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-popup-follow-up',
  templateUrl: './popup-follow-up.component.html',
  styleUrls: ['./popup-follow-up.component.scss']
}) 
export class PopupFollowUpComponent implements OnInit {
  patientInfo: any; 
  followUpForm: FormGroup;
  status: any; 
  submitButton = true;
  updateButton = false;
  deleteButton = false;
  cancelButton = false;
  loggedId: any;
  myimage;
  date: any;
  arr=[];
  
  userPermission:any;
  vendorCreate= true;
  vendorUpdate= true;
  isSuperAdmin= true;
  superBranchId: any;
  errormessage: string;
  fid: any;
  searchText: any;
  totalLength:number;
  page:number = 1;
  size:number = 5;
  listOfFollowups: any;
  branchRef: string;
  clinicRef: string;
  userRef: string;
  userType: string;
  BranchList: any;
  branchName: any;
  bId: any;
  minDate: Date;
  followupId: any;
  patientNm: any;
  patientRf: any;
  mobile1: any;
  patientAptData: string;
  constructor( 
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
     public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<PopupFollowUpComponent>,@Inject(MAT_DIALOG_DATA) public data:any,
  ) { 
    this.patientInfo = data.patientInfo;
    this.followupId = data.followupId;
    this.patientNm = data.patientNm;
    this.patientRf = data.patientRf;
 
  }
  dateDisable = true;
  ngOnInit(): void {
    
    this.page=1;
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.userType = sessionStorage.getItem('userType');
  //   this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
  //   if(this.userType !== 'Super Admin'){
  //   this.userPermission.forEach((element) => {
  //     this.isSuperAdmin= false;
  //     if(element.pageName == 'Vendor' && element.create == false)
  //     {
  //         this.vendorCreate = false;  
  //     }
  //     if(element.pageName == 'Vendor' && element.update == false)
  //     {
  //         this.vendorUpdate = false;  
  //     }  
  //   });
  // }
  //
  
    this.followUpForm = this.fb.group({
      "patientName": new FormControl(''),
      "followupType": new FormControl('',Validators.required),
      "patientRefId": new FormControl(''),
      "followupTime": new FormControl('',Validators.required),
      "followupDate": new FormControl(''),
      "followupStatus": new FormControl(''),     
      "followupComments": new FormControl(''),
      "searchFollowup": new FormControl(),
      "contactNo": new FormControl(''),
      "branchRefId": new FormControl(this.branchRef),
      "clinicRefId": new FormControl(this.clinicRef),
      "createdAt": new Date(),   
    });
    this.followUpForm.controls['followupType'].setValue('Phone Call');
    this.patientAptData = localStorage.getItem("patient");
    
    if(this.patientRf !== '' && this.patientRf !== null)
    {
      this.followUpForm.controls['patientName'].setValue(this.patientNm);
      this.followUpForm.controls['patientRefId'].setValue(this.patientRf);
    }
    else 
    {
      this.followUpForm.controls['patientName'].setValue(this.patientNm);
      this.followUpForm.controls['patientRefId'].setValue(this.patientRf);
    }
    // this.fetchFollowupList();
    //   
    if(this.followupId !== undefined)
    {
      this.prepopulation(this.followupId)
    }  
  }
  submitFollowup() {
   // this.setValidor();
    // if(this.userType == 'Super Admin')
    // {
    //   this.followUpForm.controls['branchRefId'].setValidators(Validators.required);
    //   this.followUpForm.controls['branchRefId'].updateValueAndValidity();
    // }
    // else{
    //   this.followUpForm.controls['branchRefId'].setErrors(null);
    //   this.followUpForm.controls['branchRefId'].clearValidators();
    //   this.followUpForm.controls['branchRefId'].updateValueAndValidity();
    // }
    
    if (this.followUpForm.invalid) {
      this.followUpForm.get("followupType").markAsTouched();
      this.followUpForm.get("followupTime").markAsTouched();
     // this.followUpForm.get("followupStatus").markAsTouched();
    
      return;
    } else {
      this.addFollowup();
    }
  }


  updateFollowupDetails() {
    //this.setValidor();
    // if(this.userType == 'Super Admin')
    // {
    //   this.followUpForm.controls['branchRefId'].setValidators(Validators.required);
    //   this.followUpForm.controls['branchRefId'].updateValueAndValidity();
    // }
    // else{
    //   this.followUpForm.controls['branchRefId'].setErrors(null);
    //   this.followUpForm.controls['branchRefId'].clearValidators();
    //   this.followUpForm.controls['branchRefId'].updateValueAndValidity();
    // }
    if (this.followUpForm.invalid) {
      this.followUpForm.get("followupType").markAsTouched();
      this.followUpForm.get("followupTime").markAsTouched();
   //   this.followUpForm.get("followupStatus").markAsTouched();
     
      return;
    } else {
      this.updateFollowup();
    }
  }

  addFollowup() {
    this.spinner.show();
    let payload = this.followUpForm.value;
    let alertFollowupPayload = {};
    alertFollowupPayload['patientRefId'] = payload.patientRefId;
    alertFollowupPayload['patientName'] = payload.patientName;
    alertFollowupPayload['followupType'] = payload.followupType;
    alertFollowupPayload['followupDate'] = payload.followupDate;
    alertFollowupPayload['branchRefId'] = payload.branchRef;
    alertFollowupPayload['clinicRefId'] = payload.clinicRef;
    payload.followupStatus = 'Follow Up Scheduled';
    payload.patientInfo = this.patientAptData;
    //alertFollowupPayload['patientRefId'] = payload.patientRefId;

   // payload.vendorBrandLable = this.arr;
  
    // if(this.userType !== 'Super Admin')
    // {
    //   payload.branchRefId = this.branchRef;
    //   payload.clinicRefId = this.clinicRef;
    //   payload.branchName = this.branchName;
    // }
    this.apimicroservice.addFollowup(payload).subscribe(res => {
      this.spinner.hide(); 
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);
        // this.ngOnInit();
        // this.dialogRef.close(); 
        // 
        let data = res.data;
        alertFollowupPayload['followupId'] = data.followupId;
        //
        this.apimicroservice.addFollowupAlerts(alertFollowupPayload).subscribe(res => {
          if(res.status){
            this.apimicroservice.openSnackbar('alert created');
            window.location.reload();
            // this.ngOnInit();
          }
        }); 
        
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    },(ERROR:HttpErrorResponse) => {
      this.apimicroservice.openSnackbar(ERROR.error.message);
      this.spinner.hide();
  });
    
  }
  openSnackbar(message: string) {
    return this._snackBar.open(message, "close", {
      verticalPosition: "bottom",
      horizontalPosition: "right",
      duration: 2000,
      panelClass: ["snackbarStyle"],
    });
  }
  
  fetchFollowupList() {
    this.spinner.show();
    let pg = 1;
    let size = 5;
    let search='';
    let branchRef='';
    let clinicRef='';
    let patientRef = this.followUpForm.get('patientRefId').value;
    //
      // if (this.userType !== 'Super Admin') {
        this.apimicroservice.getFollowupList(pg,size,search,patientRef).subscribe(res => {
          this.spinner.hide();
          //
           this.listOfFollowups = res.data.data;
           this.totalLength = res.data.totalItems;
           //this.buildingLenght = this.listofbuild.length;
          if(res.status){
           // this.apimicroservice.openSnackbar(res.message);  
          }else{
            //this.apimicroservice.openSnackbar(res.message);
          }
        });   
      // } else {

      //   this.apimicroservice.getVendorList(pg,size,search,branchRef,clinicRef).subscribe(res => {
      //     this.spinner.hide();
      //      this.listOfVendors = res.data.vendorData;
      //      this.totalLength = res.data.totalItems;
      //      //this.buildingLenght = this.listofbuild.length;
      //     if(res.status){
      //       // this.apimicroservice.openSnackbar(res.message);   
      //     }else{
      //       //this.apimicroservice.openSnackbar(res.message);
      //     }
      //   });
      // }
  }

  prepopulation(id) {
    this.spinner.show();
    this.apimicroservice.getFollowupById(id).subscribe(res => {
      this.spinner.hide();
      let data = res.data[0];
      //
      this.fid = id;
      if(data.followupTime == 'Custom'){
        this.dateDisable = false;
      }
      else{
        this.dateDisable = true;
      }
      this.apimicroservice.getPatientById(data.patientRefId).subscribe(res => {
        //
        //
      //  this.mobile1 = res.data[0].mobile1;
        if(res.data[0].mobile1 == '')
        {
          this.mobile1 = res.data[0].landline1; 
        }
        else
        {
          this.mobile1 = res.data[0].mobile1;
        }
       });
       //
       
       if(res.status){
        this.submitButton = false;
         this.updateButton = true;
         this.cancelButton = true;
         this.followUpForm = this.fb.group({
          "patientName": new FormControl(data.patientName),
          "followupType": new FormControl(data.followupType,Validators.required),
          "patientRefId": new FormControl(data.patientRefId),
          "followupTime": new FormControl(data.followupTime,Validators.required),
          "followupDate": new FormControl(data.followupDate),
          "followupStatus": new FormControl(data.followupStatus),     
          "followupComments": new FormControl(data.followupComments),
          "contactNo": new FormControl(this.mobile1),
          "searchFollowup": new FormControl(), 
          "updateddAt": new Date(),
          
          // "branchRefId": new FormControl(data.branchRefId),
          // "clinicRefId": new FormControl(data.clinicRefId),
          // "branchName": new FormControl(data.branchName),
        });
        
        if(data.followupTime == 'Custom'){
          this.dateDisable = false;
        }
        else{
          this.dateDisable = true;
        }
        // this.superBranchId = data.branchWiseId;
        // this.bId = data.branchWiseId;
       }else{
     //    //this.apimicroservice.openSnackbar(res.message);
       }
    
     });
    
  }
  addNew() {
    this.followUpForm.reset();
    this.updateButton = false;
    this.deleteButton = false;
  }




  updateFollowup() {
    this.spinner.show();
    let payload = this.followUpForm.value;
    // payload.vendorBrandLable = this.arr;
    //  if(this.userType !== 'Super Admin')
    //  {
    //    payload.branchRefId = this.branchRef;
    //    payload.clinicRefId = this.clinicRef;
    //    payload.branchName = this.branchName;
    //  }
    
     this.apimicroservice.updateFollowupDetails(payload,this.fid).subscribe(res => {
      this.spinner.hide(); 
       if(res.status){
         this.apimicroservice.openSnackbar(res.message);
          this.updateButton = false;
          this.submitButton = true;
          this.cancelButton = false;
          
          this.ngOnInit();
          this.dialogRef.close(); 
          //this.followUpForm.reset();
         // this.removeValidator();
         
       }else{
       //  //this.apimicroservice.openSnackbar(res.message);
       }
     });
    
  }
 
  onPageChange(event) {
    this.spinner.show();
    this.page = event;
   // let p = this.page - 1;
    this.searchText = this.followUpForm.get('searchFollowup').value;
    let branchRef='';
    let clinicRef='';
    let patientRef = this.followUpForm.get('patientRefId').value;
    // if (this.userType !== 'Super Admin') {
      this.apimicroservice.getFollowupList(this.page,this.size,this.searchText,patientRef).subscribe(res => {
        this.spinner.hide();
          this.listOfFollowups = res.data.data;
          this.totalLength = res.data.totalItems;
          //this.buildingLenght = this.listofbuild.length;
         if(res.status){
          //  this.apimicroservice.openSnackbar(res.message);
           
         }else{
           //this.apimicroservice.openSnackbar(res.message);
         }
    
       });
    // } else {

    //   this.apimicroservice.getVendorList(this.page,this.size,this.searchText,branchRef,clinicRef).subscribe(res => {
    //     this.spinner.hide();
    //       this.listOfFollowups = res.data.vendorData;
    //       this.totalLength = res.data.totalItems;
    //       //this.buildingLenght = this.listofbuild.length;
    //      if(res.status){
    //       //  this.apimicroservice.openSnackbar(res.message);
           
    //      }else{
    //        //this.apimicroservice.openSnackbar(res.message);
    //      }
    
    //    });
      
    // }
      
    
  }
  onKeyUpEvent(event: any) {
    this.searchText = this.followUpForm.get('searchFollowup').value;
    let branchRef='';
    let clinicRef='';
   
let page=1;
let size=5;
    // if (this.userType !== 'Super Admin') {
      let patientRef = this.followUpForm.get('patientRefId').value;
      this.apimicroservice.getFollowupList(this.page,this.size,this.searchText,patientRef).subscribe(res => {
        //
        this.listOfFollowups = res.data.data;
        this.totalLength = res.data.totalItems;
        this.page=res.data.pageNumber;
        //this.buildingLenght = this.listofbuild.length;
       if(res.status){
        //  this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
      
    // } else {

    //   this.apimicroservice.getVendorList(page,size,this.searchText,branchRef,clinicRef).subscribe(res => {
    //     //
    //     this.listOfFollowups = res.data.vendorData;
    //     this.totalLength = res.data.totalItems;
    //     this.page=res.data.pageNumber;
    //     //this.buildingLenght = this.listofbuild.length;
    //    if(res.status){
    //     //  this.apimicroservice.openSnackbar(res.message);
         
    //    }else{
    //      //this.apimicroservice.openSnackbar(res.message);
    //    }
    //  });
      
    // }
      
    
  }

  onSelectFollowupTime(){
    let val =  this.followUpForm.get('followupTime').value;
    //
    this.minDate = new Date();
    if(val == "One Week"){
      this.minDate.setDate(this.minDate.getDate()+7);
      this.dateDisable = true;
    }else if(val == "Six Month"){
      this.minDate.setMonth(this.minDate.getMonth()+6);
      this.dateDisable = true;
    }else if(val == "One Year"){
      this.minDate.setMonth(this.minDate.getMonth()+12);
      this.dateDisable = true;
    }
    else if(val == "Custom"){
      this.dateDisable = false;
    }
    //
    this.followUpForm.controls['followupDate'].setValue(this.minDate);
  }

  cancelUpdate(){
    this.followUpForm.reset();
    this.submitButton=true;
    this.updateButton=false;
    this.cancelButton=false;
    this.ngOnInit();
   // this.removeValidator();
  }
 
  redirect(to): void {
    this.router.navigate([to]);
  }
  toFollowupForm() {
    document.getElementById("toFollowupForm").scrollIntoView();
  }
  toFollowupList(){
    document.getElementById("toFollowupTable").scrollIntoView();
  }
  

  onback() {
    this.apimicroservice.back()
  }
  onClose(): void {
    this.dialogRef.close(); 
  }
  WhatsApp() {
    let payload = this.followUpForm.value;
    let num = payload.contactNo;
    if (num.length == 10) {
      sessionStorage.setItem('whatsAppNumber', payload.contactNo);
      let mobileNumber = sessionStorage.getItem('whatsAppNumber');
      window.open("https://wa.me/91" + mobileNumber);
    }
    else {
      this.followUpForm.get("contactNo").markAsTouched();
    }
  }
}
 