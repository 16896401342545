import { Component, Inject, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
 import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { AfterViewInit, Directive, ViewChild } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PopupBrandComponent } from "../popup-brand/popup-brand.component";
import { NgxSpinnerService } from "ngx-spinner";
import { PopUpBranchComponent } from "../pop-up-branch/pop-up-branch.component";
@Component({
  selector: 'app-pop-up-vendor',
  templateUrl: './pop-up-vendor.component.html',
  styleUrls: ['./pop-up-vendor.component.scss']
})
export class PopUpVendorComponent implements OnInit {
  errormessage: string;
  vid: any;
  searchText: any;
  totalLength:number;
  page:number = 1;
  size:number = 5;
  allState=[
    {name:'Andaman and Nicobar Islands',value:'AN' },
    {name:'Andhra Pradesh',value:'AP'},
    { name:'Arunachal Pradesh',value:'AR' },
{name:'Assam',value:'AS' },
{ name:'Bihar',value:'BR'},
 { name:'Chandigarh',value:'CH' },
{name:'Chhattisgarh',value:'CT' },
{ name:'Dadra and Nagar Haveli', value:'DN' },
 { name:'Daman and Diu', value:'DD'},
 { name:'Delhi',value:'DL'},
 {name:'Goa',value:'GA' },
{name:'Gujarat', value:'GJ'}, 
{name:'Haryana',value:'HR'}, 
{name:'Himachal Pradesh',value:'HP'}, 
{ name:'Jammu and Kashmir',value:'JK'}, 
 {name:'Jharkhand',value:'JH'}, 
{ name:'Karnataka', value:'KA'},
{ name:'Kerala',value:'KL'},
{name:'Ladakh',value:'LA'},
 {name:'Lakshadweep',value:'LD' },
 {name:'Madhya Pradesh', value:'MP'},
{ name:'Maharashtra',value:'MH'},
 { name:'Manipur',value:'MN'},
{ name:'Meghalaya', value:'ML' },
{ name:'Mizoram',value:'MZ' },
{ name:'Nagaland',value:'NL' },
 { name:'Odisha',value:'OR'},
 { name:'Puducherry', value:'PY'},
 {name:'Punjab', value:'PB' },
{ name:'Rajasthan', value:'RJ'},           
 {name:'Punjab',value:'PB'},   
 {name:'Rajasthan', value:'RJ'},
{name:'Sikkim',value:'SK' },
{ name:'Tamil Nadu',value:'TN'}, 
 {name:'Telangana',value:'TG'},   
  { name:'Tripura',  value:'TR' },
 { name:'Uttar Pradesh', value:'UP'},
{name:'Uttarakhand',value:'UT'},  
{ name:'West Bengal', value:'WB' },                        
  ]
  filteredList: { name: string; value: string; }[];
  brandlist: any;
  filteredListBrand: any;
  branchRef: string;
  clinicRef: string;
  userRef: string;
  userType: string;
  BranchList: any;
  branchName: any;
  filteredListBranch: any;
  bId: any;
  stateCityArr2: any[];
  stateCity2: any;
  districtData2: any;
  filteredList2: { state: string; districts: string; }[];
  filteredListDistrict2: { district: string;}[];
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
     public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    private httpClient: HttpClient,
    public dialogRef: MatDialogRef<PopUpVendorComponent>,@Inject(MAT_DIALOG_DATA) public data:any
  ) {dialogRef.disableClose = true;}
  vendorMasterForm: FormGroup;
  status: any;
  submitButton = true;
  updateButton = false;
  deleteButton = false;
  cancelButton = false;
  loggedId: any;
  myimage;
  date: any;
  arr=[];
  
  userPermission:any;
  vendorCreate= true;
  vendorUpdate= true;
  isSuperAdmin= true;
  superBranchId: any;
  ngOnInit() {
    this.page=1;
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.userType = sessionStorage.getItem('userType');
    this.filteredList = this.allState.slice();
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    if(this.userType !== 'Super Admin'){
    this.userPermission.forEach((element) => {
      this.isSuperAdmin= false;
      if(element.pageName == 'Vendor' && element.create == false)
      {
          this.vendorCreate = false;  
      }
      if(element.pageName == 'Vendor' && element.update == false)
      {
          this.vendorUpdate = false;  
      }  
    });
  }
  //
  
    this.vendorMasterForm = this.fb.group({
      "branchWiseId" :new FormControl(''),
      "vendorName": new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z &-\\s]*$")]),
      "vendorStreetAddress": new FormControl('', Validators.required),
      "vendorPhoneNumber": new FormControl('',[Validators.pattern("(0|91)?[6-9][0-9]{9}")]),
      "contactPersonSales": new FormControl('',Validators.pattern("^[a-zA-Z ]*$")),
      "contactPersonAccounts": new FormControl('',[Validators.pattern("^[a-zA-Z0-9 -]+$")]),
      "contactPersonSalesNo": new FormControl('',Validators.pattern("(0|91)?[6-9][0-9]{9}")),
      "contactPersonAccountNo": new FormControl('',[Validators.pattern("^[a-zA-Z0-9 -]+$")]),
      "customerCareContactPerson": new FormControl('',Validators.pattern("^[a-zA-Z ]*$")),
      "customerCareContactPersonNo": new FormControl('',Validators.pattern("(0|91)?[6-9][0-9]{9}")),
      "vendorGstNo": new FormControl('',Validators.pattern('^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$')),
      "vendorBrands": new FormControl('',Validators.required),
      "vendorBrandLable": new FormControl(''),
      "vendorPinCode": new FormControl('',Validators.pattern("^[0-9]{6}$")),
      "vendorStatus": new FormControl(''),
      "taluka": new FormControl('',Validators.pattern("^[a-zA-Z ]*$")),
      "district": new FormControl('',Validators.pattern("^[a-zA-Z ]*$")),
      "state": new FormControl('',Validators.required),
      "country": new FormControl('', [  Validators.pattern("[A-Za-z _-]{3,19}$"),]),
     

      "createdAt": new Date(),
      "searchVendor": new FormControl(''),

      "branchRefId": new FormControl(),
      "clinicRefId": new FormControl(),
      "branchName": new FormControl(),
      
      
    });
    this.vendorMasterForm.controls["country"].setValue("India");
    this.vendorMasterForm.controls["vendorStatus"].setValue("Active");
    this.vendorMasterForm.controls["state"].setValue("Maharashtra");
    let arr=[]
    arr = [
      "Ahmednagar",
      "Akola",
      "Amravati",
      "Aurangabad",
      "Beed",
      "Bhandara",
      "Buldhana",
      "Chandrapur",
      "Dhule",
      "Gadchiroli",
      "Gondia",
      "Hingoli",
      "Jalgaon",
      "Jalna",
      "Kolhapur",
      "Latur",
      "Mumbai City",
      "Mumbai Suburban",
      "Nagpur",
      "Nanded",
      "Nandurbar",
      "Nashik",
      "Osmanabad",
      "Palghar",
      "Parbhani",
      "Pune",
      "Raigad",
      "Ratnagiri",
      "Sangli",
      "Satara",
      "Sindhudurg",
      "Solapur",
      "Thane",
      "Wardha",
      "Washim",
      "Yavatmal"
  ]
  this.getDistrict2(arr);
    this.getLastId();
    this.fetchBrandList();
  //  this.removeValidator();
    this.fetchBranchList();
    this.getAllBranch();
    this.getState2();
  }

  getState2()
 {
  this.httpClient.get("assets/state-city.json").subscribe(data =>{ 
    this.stateCity2 = data;
    this.stateCityArr2 = [];
    this.stateCity2.states.forEach((element) => {
      this.stateCityArr2.push(element);
    });
    this.filteredList2 = this.stateCityArr2.slice();
    //
  })
 }
 getDistrict2(state)
 {
  //
  this.districtData2 = [];
  state.forEach((element) => {
    //
    this.districtData2.push({"district":element});
  });
  //
  let arr = [];
  arr = state;
 this.filteredListDistrict2 = this.districtData2.slice();
 }
  getAllBranch()
  {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      if (res.status) {        
        let length = res.data.length - 1;

        this.vendorMasterForm.controls["branchRefId"].setValue(res.data[length]._id);
        this.branchClick(res.data[length]._id,this.branchName);
        if(this.userType == 'Super Admin')
        {  
          this.apimicroservice.getBranchById(res.data[length]._id).subscribe(res => {
            this.spinner.hide();
            this.vendorMasterForm.get("branchName").setValue(res.data[length].branchName);
           });
        }
       
      }
    });
  }
  submitVendor() {
   // this.setValidor();
    if(this.userType == 'Super Admin')
    {
      this.vendorMasterForm.controls['branchRefId'].setValidators(Validators.required);
      this.vendorMasterForm.controls['branchRefId'].updateValueAndValidity();
    }
    else{
      this.vendorMasterForm.controls['branchRefId'].setErrors(null);
      this.vendorMasterForm.controls['branchRefId'].clearValidators();
      this.vendorMasterForm.controls['branchRefId'].updateValueAndValidity();
    }
    if (this.vendorMasterForm.invalid) {
      this.vendorMasterForm.get("vendorName").markAsTouched();
      this.vendorMasterForm.get("vendorStreetAddress").markAsTouched();
      this.vendorMasterForm.get("state").markAsTouched();
      this.vendorMasterForm.get("vendorPinCode").markAsTouched();
      this.vendorMasterForm.get("vendorBrands").markAsTouched();
      // this.vendorMasterForm.get("contactPersonAccounts").markAsTouched();
      // this.vendorMasterForm.get("contactPersonAccountNo").markAsTouched();
      
      return;
    } else {
      this.addVendor();
    }
  }

  // removeValidator() { 
   
    
  //   this.vendorMasterForm.controls['vendorName'].setErrors(null);
  //   this.vendorMasterForm.controls['vendorName'].clearValidators();
  //   this.vendorMasterForm.controls['vendorName'].updateValueAndValidity();

  //   this.vendorMasterForm.controls['vendorStreetAddress'].setErrors(null);
  //   this.vendorMasterForm.controls['vendorStreetAddress'].clearValidators();
  //   this.vendorMasterForm.controls['vendorStreetAddress'].updateValueAndValidity();

  //   this.vendorMasterForm.controls['vendorBrands'].setErrors(null);
  //   this.vendorMasterForm.controls['vendorBrands'].clearValidators();
  //   this.vendorMasterForm.controls['vendorBrands'].updateValueAndValidity();
    
   

  //   this.vendorMasterForm.controls['vendorPinCode'].setErrors(null);
  //   this.vendorMasterForm.controls['vendorPinCode'].clearValidators();
  //   this.vendorMasterForm.controls['vendorPinCode'].updateValueAndValidity();

  //   this.vendorMasterForm.controls['vendorPhoneNumber'].setErrors(null);
  //   this.vendorMasterForm.controls['vendorPhoneNumber'].clearValidators();
  //   this.vendorMasterForm.controls['vendorPhoneNumber'].updateValueAndValidity();

  //   this.vendorMasterForm.controls['contactPersonAccounts'].clearValidators();

  //   this.vendorMasterForm.controls['contactPersonAccountNo'].clearValidators();
    

  
  //   }

    // setValidor() { 

    //   this.vendorMasterForm.controls['vendorName'].setValidators([Validators.required,Validators.pattern("^[a-zA-Z ]*$")]);
    //   this.vendorMasterForm.controls['vendorName'].updateValueAndValidity();

    //   this.vendorMasterForm.controls['vendorPhoneNumber'].setValidators([Validators.required,Validators.pattern("(0|91)?[6-9][0-9]{9}")]);
    //   this.vendorMasterForm.controls['vendorPhoneNumber'].updateValueAndValidity();

    //   this.vendorMasterForm.controls['vendorStreetAddress'].setValidators(Validators.required);
    //   this.vendorMasterForm.controls['vendorStreetAddress'].updateValueAndValidity();

    //   this.vendorMasterForm.controls['vendorBrands'].setValidators(Validators.required);
    //   this.vendorMasterForm.controls['vendorBrands'].updateValueAndValidity();

    //   this.vendorMasterForm.controls['vendorPinCode'].setValidators([Validators.required,Validators.pattern("^[0-9]{6}$")]);
    //   this.vendorMasterForm.controls['vendorPinCode'].updateValueAndValidity();

    //   this.vendorMasterForm.controls['contactPersonAccounts'].setValidators([Validators.required,Validators.pattern("^[a-zA-Z0-9 -]+$")]);
    //   this.vendorMasterForm.controls['contactPersonAccounts'].updateValueAndValidity();

    //   this.vendorMasterForm.controls['contactPersonAccountNo'].setValidators([Validators.required,Validators.pattern("^[a-zA-Z0-9 -]+$")]);
    //   this.vendorMasterForm.controls['contactPersonAccountNo'].updateValueAndValidity();
      
    //   }


  updateVendorDetails() {
    //this.setValidor();
    if(this.userType == 'Super Admin')
    {
      this.vendorMasterForm.controls['branchRefId'].setValidators(Validators.required);
      this.vendorMasterForm.controls['branchRefId'].updateValueAndValidity();
    }
    else{
      this.vendorMasterForm.controls['branchRefId'].setErrors(null);
      this.vendorMasterForm.controls['branchRefId'].clearValidators();
      this.vendorMasterForm.controls['branchRefId'].updateValueAndValidity();
    }
    if (this.vendorMasterForm.invalid) {
      this.vendorMasterForm.get("vendorName").markAsTouched();
      this.vendorMasterForm.get("vendorStreetAddress").markAsTouched();
      this.vendorMasterForm.get("state").markAsTouched();
      this.vendorMasterForm.get("vendorPinCode").markAsTouched();
      this.vendorMasterForm.get("vendorBrands").markAsTouched();
      // this.vendorMasterForm.get("contactPersonAccounts").markAsTouched();
      // this.vendorMasterForm.get("contactPersonAccountNo").markAsTouched();
     
      return;
    } else {
      this.updateVendor();
    }
  }

  addVendor() {
    this.spinner.show();
    let payload = this.vendorMasterForm.value;
    //payload.vendorBrandLable = this.arr;

    if(this.userType !== 'Super Admin')
    {
      payload.branchRefId = this.branchRef;
      payload.clinicRefId = this.clinicRef;
      payload.branchName = this.branchName;
    }
    this.apimicroservice.addVendor(payload).subscribe(res => {
      this.spinner.hide(); 
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);
        this.ngOnInit();
        this.onClose();     
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    },(ERROR:HttpErrorResponse) => {
      this.apimicroservice.openSnackbar(ERROR.error.message);
      this.spinner.hide();
  });
    
  }
  openSnackbar(message: string) {
    return this._snackBar.open(message, "close", {
      verticalPosition: "bottom",
      horizontalPosition: "right",
      duration: 2000,
      panelClass: ["snackbarStyle"],
    });
  }
  
  getLastId()
  {
    if(this.userType !== 'Super Admin'){
      this.apimicroservice.getLastVendorID(this.branchRef).subscribe(res => {
        //   
        // if(res['data']['data'] == '')
        // {
        //   this.vendorMasterForm.controls['branchWiseId'].setValue('1');
        // }
        if (res['status']) {
          let vendorIdd = res['data']['totalItems'];
          this.bId=vendorIdd+1;
          this.vendorMasterForm.controls['branchWiseId'].setValue(vendorIdd + 1);
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
       
      })
    }else{
      this.apimicroservice.getLastVendorAll().subscribe(res => {
        //   
        // if(res['data']['data'] == '')
        // {
        //   this.vendorMasterForm.controls['branchWiseId'].setValue('1');
        // }
        if (res['status']) {
          let vendorIdd = res['data']['totalItems'];
          this.superBranchId = vendorIdd + 1;
          this.bId=vendorIdd+1;
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
       
      })
    }
    
  }
 
  addNew() {
    this.vendorMasterForm.reset();
    this.updateButton = false;
    this.deleteButton = false;
  }




  updateVendor() {
    this.spinner.show();
    let payload = this.vendorMasterForm.value;
     //payload.vendorBrandLable = this.arr;
     if(this.userType !== 'Super Admin')
     {
       payload.branchRefId = this.branchRef;
       payload.clinicRefId = this.clinicRef;
       payload.branchName = this.branchName;
     }
    
     this.apimicroservice.updateVendorDetails(payload,this.vid).subscribe(res => {
      this.spinner.hide(); 
       if(res.status){
         this.apimicroservice.openSnackbar(res.message);
          this.updateButton = false;
          this.submitButton = true;
          this.cancelButton = false;
          this.ngOnInit();
       }else{
       //  //this.apimicroservice.openSnackbar(res.message);
       }
     });
    
  }
 
  cancelUpdate(){
    this.vendorMasterForm.reset();
    this.submitButton=true;
    this.updateButton=false;
    this.cancelButton=false;
    this.ngOnInit();
  }
 
  redirect(to): void {
    this.router.navigate([to]);
  }
  fetchBrandList() {  
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
    this.apimicroservice.getBrandList(branchRef,clinicRef).subscribe(res => {
      //
       this.brandlist = res.data;   
       this.filteredListBrand = this.brandlist.slice();
    }); 
  }else{
    this.apimicroservice.getBrandList(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
      //
       this.brandlist = res.data;   
       this.filteredListBrand = this.brandlist.slice();
    }); 
  }
  }
  openBrandPopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopupBrandComponent,
      {
        width: "700px",
      });
  }
   openBranchPopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpBranchComponent,
      {
        width: "1200px",
      });
      dialogRef3.afterClosed().subscribe((result) => {
        let clinicRef= sessionStorage.getItem('clinicRef');
        this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
          //  
          if (res.status) {    
            this.BranchList = res.data;
            this.filteredListBranch= this.BranchList.slice();
            this.branchClick(res.data[0]._id,res.data[0].branchName);
            this.vendorMasterForm.get("branchRefId").setValue(res.data[0]._id)
          } else {
            //this.apimicroservice.openSnackbar(res.message);
          }
        })
       // this.fetchBranchList();
      })
  }
  brandSelect(e)
  {
    let payload = this.vendorMasterForm.value;
    this.vendorMasterForm.controls['brandLable'].setValue(e);
    // if(event.source.selected){
    //   this.arr.push(e);
    // }
    // else{
    //   this.arr = this.arr.filter(b => b!=e);
    // } 
    // //
    
    // payload.brandLable = this.arr;
  }
  fetchBranchList() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      //  
      if (res.status) {    
        this.BranchList = res.data;
        this.filteredListBranch= this.BranchList.slice();
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    })
  } 
  branchClick(id,branchName)
  {

    this.vendorMasterForm.controls["branchName"].setValue(branchName);

   this.apimicroservice.getBranchById(id).subscribe(res => { 
     //
       
     let data = res.data[0];
     let payload = this.vendorMasterForm.value;
      payload.clinicRefId = res.data[0].clinicRefId;
      this.vendorMasterForm.controls["clinicRefId"].setValue(res.data[0].clinicRefId);
      if(this.updateButton == false){
        this.spinner.show();
        this.apimicroservice.getLastVendorID(payload.branchRefId).subscribe(res => {
          //   
         
          if (res['status']) {
            let vendorIdd = res['data']['totalItems'];
            this.vendorMasterForm.controls['branchWiseId'].setValue(vendorIdd + 1);
          } else {
            this.apimicroservice.openSnackbar("Something went wrong");
          }
          this.spinner.hide();
        })
      }
    });
  }
  onClose(): void {
    this.dialogRef.close();
  }
}
