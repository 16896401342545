import { ActivatedRoute } from "@angular/router";
import { Component, Inject, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  FormArray
} from "@angular/forms";
 import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  MatSnackBar,
} from "@angular/material/snack-bar";
import { MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { PopUpClinicalStaffComponent } from "../pop-up-clinical-staff/pop-up-clinical-staff.component";
import { PopUpDoctorComponent } from "../pop-up-doctor/pop-up-doctor.component";
import { PopUpBranchComponent } from "../pop-up-branch/pop-up-branch.component";
import { ElementRef } from '@angular/core';
import SignaturePad from 'signature_pad';
import { AfterViewInit, Directive, ViewChild } from "@angular/core";
import { PopUpInwardOutwardComponent } from "../pop-up-inward-outward/pop-up-inward-outward.component";
import { PopupServiceComponent } from "../popup-service/popup-service.component";
import { PopupDeviceByPatientComponent } from "../popup-device-by-patient/popup-device-by-patient.component";
import { PopupModeOfPaymentComponent } from "../popup-mode-of-payment/popup-mode-of-payment.component";
import { PopUpCourierserviceComponent } from "../pop-up-courierservice/pop-up-courierservice.component";
import { PopUpCityComponent } from "../pop-up-city/pop-up-city.component";
import { InwardOutwadPopupTypeComponent } from "../inward-outwad-popup-type/inward-outwad-popup-type.component";
import { PopupCompanyComponent } from "../popup-company/popup-company.component";
import { PopUpChangeOfDeviceComponent } from "../pop-up-change-of-device/pop-up-change-of-device.component";
@Component({
  selector: 'app-repair-action',
  templateUrl: './repair-action.component.html',
  styleUrls: ['./repair-action.component.scss']
})
export class RepairActionComponent implements OnInit {
  title = 'signatureJS'; 
  signaturePad: SignaturePad;
  @ViewChild('canvas') canvasEl: ElementRef;
  signatureImg: string;
  errormessage: string;
  userName: string;
  repairRefId: any;
  clinicName: any;
  branchRefId: any;
  inwardOutwardId: any;
  repairBranch: any;
  patientMobileNo: any;
  billNo: any;
  currentdate: string;
  isInwardOutward: any;
  patientId: any;
  estimateAmount: any;
  amountA: any;
  DeviceList: any[];
  allDevices: any[];
  filteredListDevice: any[];
  amountB: any;
  // deviceType: any;
  modeval: string;
  chequeDetails = [];
  ServiceName: any;
  LeftDeviceAge: any;
  RightDeviceAge: any;
  repairActionAllData = [];
  billGenerated = false;
  billNumber: any;
  disableSubmit = false;
  leftType: any;
  rightType: any;
  courierList: any;
  filteredListCourier: any;
  cityList: any;
  filteredListCity: any;
  inoutTypeList: any;
  filteredListInoutType: any;
  companyList: any;
  filteredListCompany: any;
  allowUser = false;
  restrictData= true;
  step: any;
  pervalue: any;
  repairActionId: any;

  ngAfterViewInit() {
    //
    this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
    //
    
  }

  startDrawing(event: Event) {
    //
    //
    // works in device not in browser

  }

  moved(event: Event) {
    //
    // works in device not in browser
  }

  clearPad() {
    
    this.signaturePad.clear();
  }
 
  savePad() {
    this.signatureImg = '';
    this.signatureIs = true;
    const base64Data = this.signaturePad.toDataURL();
    this.signatureImg = base64Data;
    //
    
  }

  patientInfo: any;
  repairActionForm: FormGroup;
  status: any;
  submitButton = true;
  updateButton = false; 
  deleteButton = false;
  cancelButton = false;
  loggedId: any;
  myimage;
  date: any;
  arr=[];
  
  userPermission:any;
  isSuperAdmin= true;
  superBranchId: any;
  fid: any;
  searchText: any;
  totalLength:number;
  page:number = 1;
  size:number = 5;
  listOfFollowups: any;
  branchRef: string;
  clinicRef: string;
  userRef: string;
  userType: string;
  BranchList: any;
  branchName: any;
  bId: any;
  minDate: Date;
  followupId: any;
  AddRepairActionCreate = true;
  AddRepairActionUpdate = true;
  
 ActionPaymentDone = false;
 ActionCheckedBy = false;
 ActionCollectedBy = false;
 ActionCommunicationWith = false;
 ActionDulyRepaired = false;
 ActionEstimateAdvised = false;
 ActionEstimateApproved = false;
 ActionEstimateCalculated = false;
 ActionEstimateReceived = false;
 ActionEstimateRejected = false;
 ActionProgrammedBy = false;
 ActionReceivedFrom = false;
 ActionSentTo = false;
 ActionUnrepairedReady = false;

 isDoctor = false;
isclinicalStaff = false;
  doctorList: any;
  filteredListDoctor: any;
  clinicalStaffList: any;
  filteredListClinicalStaff: any;
  statusAction= false;
  patientName: any;
  patientRefId: any;
  pId: any;
  vendorList: any;
  filteredListVendor: any;
  isClient = false;
  isVendor = false;
  isBranch = false;
  isPhone = false;
  isNextToKin = false;
  isClinic = false;
  isMessage = false;
  companyName: any;
  companyRefId: any;
  filteredListBranch: any;
  FinalBill = false;
  actionId:any;
  hideSignature = false;
  signatureIs = false;
  constructor( 
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
     public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<RepairActionComponent>,@Inject(MAT_DIALOG_DATA) public data:any,
  ) { 
    dialogRef.disableClose = true;
    this.patientRefId = data.patientRefId;
    this.patientName = data.patientName;
    this.companyRefId = data.companyRefId;
    this.companyName = data.companyName;
    this.actionId = data.actionId; 
    this.repairRefId = data.repairRefId;
    this.branchRefId = data.branchRefId;
    this.branchRefId = data.branchRefId;
    this.repairBranch = data.repairBranch;
    this.patientMobileNo = data.patientMobile;
    this.patientId = data.patientId;
    this.amountA = data.amountA;
    this.amountB = data.amountB; 
    this.LeftDeviceAge = data.LeftDeviceAge; 
    this.RightDeviceAge = data.RightDeviceAge;
    this.repairActionAllData = data.repairActionAllData;
    this.leftType = data.leftType;
    this.rightType = data.rightType; 

    // this.deviceType = data.deviceType;    
  }
  inward = false;
  outward = false;
  client = false;
  manufacturer = false;
  isDevices = false;
  isDocuments = false;
  bytheFault = true;
  ngOnInit(): void {
    //

  
    if(this.amountA == undefined)
    {
      this.amountA = 0;
    }
    this.signatureIs = false;
    this.bytheFault = false;
    this.billGenerated = false;
    let date=new Date();
    this.page=1;
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.userType = sessionStorage.getItem('userType');
    this.userName = sessionStorage.getItem('userName');
    this.userRef = sessionStorage.getItem('userRef');
    this.currentdate =this.datepipe.transform(date, 'dd/MM/yyyy');
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    if(this.userType !== 'Super Admin'){
    this.userPermission.forEach((element) => {
      this.isSuperAdmin= false;
      if(element.pageName == 'Add Repair Action' && element.create == false)
      {
          this.AddRepairActionCreate = false;  
      }
      if(element.pageName == 'Add Repair Action' && element.update == false)
      {
          this.AddRepairActionUpdate = false;  
      }  
      if(element.pageName == 'Repair Calculation Permissions' && element.create == false)
      {
          this.restrictData = false;  
      }  
    });
  }
  
  //
  
    this.repairActionForm = this.fb.group({
      "repairActionName": new FormControl('',Validators.required),
      "repairRefId": new FormControl(),
      "patientRefId": new FormControl(),
      "patientName": new FormControl(''),
      "paymentType": new FormControl(''),
      "userRefId": new FormControl(),
      "userName": new FormControl(''),
      "other": new FormControl(''),
      "userId": new FormControl(''),     
      "amountA": new FormControl(''),
      "amountB": new FormControl(''),
      "amountC": new FormControl(''),
      "automatedAction": new FormControl(''),
      "status": new FormControl(''),
      "clientOrNextToKinRadioBtn": new FormControl(''),
      "branchRefId": new FormControl(),
      "branchName": new FormControl(''), 
      "clientOrVendorRadioBtn": new FormControl(''),
      "deviceAgeInYears": new FormControl(''),
      "vendorRefId": new FormControl(),
      "vendorName": new FormControl(''),
      "clientVendorBranchRadioBtn": new FormControl(''),
      // "phone": new FormControl('',[Validators.pattern("(0|91)?[6-9][0-9]{9}")]),
      // "message": new FormControl(''),
      "leftFittedBy": new FormControl(''),
      "rightFittedBy": new FormControl(''),
      "clientPinCode": new FormControl(''),
      "textBoxEntry": new FormControl(''),
      "textBoxEntrySignature": new FormControl(''),
      "textBoxEntryForAmount": new FormControl(''),
      "userType": new FormControl(''),
      "clinicName": new FormControl(''),
      "clinicRefId": new FormControl(),
      "searchFollowup": new FormControl(''),
      "phoneMsg": new FormControl(''),
      "billNo": new FormControl(''),
      "inwardOutwardId": new FormControl(''),
      //Inward Items
      "branchWiseId": new FormControl(''),
      "notes": new FormControl(''),
      "refType": new FormControl(),
      "deviceType": new FormControl(''),
      "inwardOutward": new FormControl(''),
      "date": new FormControl(''),
      "docType": new FormControl(''),
      "companyName": new FormControl(''),
      "companyRefId": new FormControl(),
      "courierCharges": new FormControl('',Validators.pattern("^[0-9]+$")),
      "fittedDeviceRefId": new FormControl(''),
      "selectPaymentType": new FormControl(''),
      "ServiceName": new FormControl(''),
      "leftDeviceAgeInYears": new FormControl(''),
      "rightDeviceAgeInYears": new FormControl(''),
   
      //////////////
      "billingCompanyRefId": new FormControl(),
      "courierRefId": new FormControl(),
      "courierName": new FormControl(''),
      "cityRefId": new FormControl(),
      "cityName": new FormControl(''),  
      "docDate": new FormControl(''),   
      "typeRefId": new FormControl(),
      "searchInwardOutward": new FormControl(''),
      "typeName": new FormControl(''),
      "trackingNumber": new FormControl('',[Validators.pattern("^[a-zA-Z0-9]*$")]),  
      "documentsNumber": new FormControl('', [Validators.pattern("^[a-zA-Z0-9/ ]*$")]),  
      "actionId": new FormControl(''),
      "originalSerialNo": new FormControl(''),
      "newSerialNo": new FormControl(''),
      "createdAt": new Date(),
    }); 
    this.getRepairPercentage(this.userRef);
    this.repairActionForm.controls['courierCharges'].setValue(0);

  this.repairActionForm.controls['date'].setValue(this.currentdate);
  this.repairActionForm.controls['leftDeviceAgeInYears'].setValue(this.LeftDeviceAge);
  this.repairActionForm.controls['rightDeviceAgeInYears'].setValue(this.RightDeviceAge);
 this.getLastRepairId(); 
  this.fetchAllCompanies();
  this.getLastId();
  this.fetchAllCourier();
  this.fetchAllCity();
  this.fetchAllType();
 this.fetchBranchList();
    this.fetchClinicList(); 
    // this.prepopulationRepair(this.userRef);
    this.repairActionForm.controls["repairRefId"].setValue(this.repairRefId);
    this.repairActionForm.controls["phoneMsg"].setValue('Phone');
    
    this.repairActionForm.controls["userName"].setValue(this.userName);
    this.repairActionForm.controls["userRefId"].setValue(this.userRef);

    this.repairActionForm.controls["patientRefId"].setValue(this.patientRefId);
    this.repairActionForm.controls["patientName"].setValue(this.patientName);
   
    this.repairActionForm.controls["vendorRefId"].setValue(this.companyRefId);
    this.repairActionForm.controls["vendorName"].setValue(this.companyName);
    this.repairActionForm.controls["branchRefId"].setValue(this.branchRefId);
    this.repairActionForm.controls["branchName"].setValue(this.repairBranch);
    
    this.repairActionForm.controls['companyRefId'].setValue(this.companyRefId);
    this.repairActionForm.controls['companyName'].setValue(this.companyName);

    this.repairActionForm.controls["clientVendorBranchRadioBtn"].setValue('Vendor');
    this.selectType('Vendor');
  // this.repairActionForm.controls["clientVendorBranchRadioBtn"].setValue('Client');
  //
   
    this.branchClick(this.branchRefId,this.branchName)
    
    //
    
    if(this.actionId !== '')
    {
      this.prepopulation(this.actionId);
    }

    if(this.repairActionAllData.length > 0){
      this.repairActionAllData.forEach(ele =>{
        if(ele.repairActionName == 'Create Final Bill'){
          this.billGenerated = true;
          this.billNumber = ele.billNo;
        }
      }) 
    }
  
  }
  // prepopulationRepair(id) {  
  //   this.apimicroservice.getRepairGenericDataById(id).subscribe(res => {
  //     let data = res.data[0];
  //     //
  //       this.restrictData = data.repairCalcukationRestrict;
   
   
  //   });
  // }
  getLastId()
  {
    let branchRef ='';
    if (this.userType !== 'Super Admin') {
      //
      this.apimicroservice.getInOutwardLastId(this.branchRef).subscribe(res => {
        //   
        // if(res['data']['data'] == '')
        // {
        //   this.clinicalStaffMasterForm.controls['branchWiseId'].setValue('1');
        // }
        if (res['status']) {
          // let clientIdd = res.data[0].branchWiseId; 
          // this.bId=clientIdd + 1;

          let clientIdd = res['data']['totalItems'];
          this.bId=clientIdd + 1;
          //this.clinicalStaffMasterForm.controls['branchWiseId'].setValue(staffIdd + 1);
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
       
      })
    } else {
      //
      //
      this.apimicroservice.getInOutwardLastId(branchRef).subscribe(res => {
        //    
        // if(res['data']['data'] == '')
        // {
        //   this.clinicalStaffMasterForm.controls['branchWiseId'].setValue('1');
        // }
        if (res['status']) {
          // let clientIdd =  res.data[0].branchWiseId; 
          // this.superBranchId = clientIdd + 1;

          let clientIdd = res['data']['totalItems'];
          this.superBranchId = clientIdd + 1;

          this.bId=clientIdd + 1;
           //this.clinicalStaffMasterForm.controls['branchWiseId'].setValue(staffIdd + 1);
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
       
      })
      
    }
    
  }
  fetchClinicList() {
    let pg = 1;
    let size = 10;
    let search='';
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getClinicData(pg,size,search,clinicRef).subscribe(res => {  
      this.repairActionForm.controls["clinicRefId"].setValue(res.data.clinicData[0]._id);
        this.clinicPrepopulation(res.data.clinicData[0]._id);
  }); 
  }
  clinicPrepopulation(id) { 
  this.apimicroservice.getClinicDataById(id).subscribe(res => {
    let data = res.data[0];
    this.clinicName = data.clinicName;
  }); 
  }
  submitAction() {
    //
    
    let payload = this.repairActionForm.value;
    //
    // if(payload.repairActionName == 'Sent To' || payload.repairActionName == 'Received From'){
    //   //
      
    //   this.repairActionForm.controls['typeRefId'].setValidators(Validators.required);
    //   this.repairActionForm.controls['typeRefId'].updateValueAndValidity();
 
    //   this.repairActionForm.controls['trackingNumber'].setValidators(Validators.required);
    //   this.repairActionForm.controls['trackingNumber'].updateValueAndValidity();

  
    //   this.repairActionForm.controls['refType'].setValidators(Validators.required);
    //   this.repairActionForm.controls['refType'].updateValueAndValidity();
    // }
    // else
    // {
    //   this.repairActionForm.controls['typeRefId'].setErrors(null);
    //   this.repairActionForm.controls['typeRefId'].clearValidators();
    //   this.repairActionForm.controls['typeRefId'].updateValueAndValidity();

    //   this.repairActionForm.controls['trackingNumber'].setErrors(null);
    //   this.repairActionForm.controls['trackingNumber'].clearValidators();
    //   this.repairActionForm.controls['trackingNumber'].updateValueAndValidity();

  
    //   this.repairActionForm.controls['refType'].setErrors(null);
    //   this.repairActionForm.controls['refType'].clearValidators();
    //   this.repairActionForm.controls['refType'].updateValueAndValidity();
      

    // }

    // if (this.repairActionForm.invalid) {
    //   //
    //   this.repairActionForm.get("trackingNumber").markAsTouched();
    //   this.repairActionForm.get("documentsNumber").markAsTouched();
    //   this.repairActionForm.get("refType").markAsTouched();
    //   this.repairActionForm.get("typeRefId").markAsTouched();
    
    //   return;
    // } else {
    //   //
    //   this.addAction();
    // }
    this.addAction();
  }
  getLastRepairId() {
    this.apimicroservice.getRepairActionAllData().subscribe(res => {
      this.repairActionId = res.data.data;
      this.repairActionId.sort((a, b) => b.repairActionId - a.repairActionId);
      this.repairActionId = this.repairActionId[0].repairActionId;
    });
  }
  addAction() {
    //
    
    this.spinner.show(); 
    this.signatureImg = '';
    this.signatureIs = true;
    const base64Data = this.signaturePad.toDataURL();
    this.signatureImg = base64Data;
    let payload = this.repairActionForm.value;
    let payload2 = this.repairActionForm.value;
    payload.textBoxEntrySignature = this.signatureImg;
    payload.inwardOutwardId = this.inwardOutwardId; 
    payload.repairRefId = this.repairRefId;
    payload.clinicName = this.clinicName;
    payload.branchName = this.repairBranch;
    if(payload.repairActionName == 'Sent To')
    {
      payload.actionId = '1';
    }
    else if(payload.repairActionName == 'Estimate Received From')
    {
      payload.actionId = '2';
    }
    else if(payload.repairActionName == 'Estimate Calculated')
    {
      payload.actionId = '3';
    }
    else if(payload.repairActionName == 'Estimate Advised To')
    {
      payload.actionId = '4';
    }
    else if(payload.repairActionName == 'Estimate Approved By')
    {
      payload.actionId = '5';
    }
    else if(payload.repairActionName == 'Estimate Rejected By')
    {
      payload.actionId = '6';
    }
    else if(payload.repairActionName == 'Received From')
    {
      payload.actionId = '7';
    }
    else if(payload.repairActionName == 'Programmed By')
    {
      payload.actionId = '8';
    }
    else if(payload.repairActionName == 'Checked By')
    {
      payload.actionId = '9';
    }
    else if(payload.repairActionName == 'Communication With')
    {
      payload.actionId = '10';
    }
    else if(payload.repairActionName == 'Unrepaired Ready')
    {
      payload.actionId = '11';
    }
    else if(payload.repairActionName == 'Create Final Bill')
    {
      payload.actionId = '12';
    }
    else if(payload.repairActionName == 'Payment Done')
    {
      payload.actionId = '13';
    }
    else if(payload.repairActionName == 'Duly Repaired Ready At')
    {
      payload.actionId = '14';
    }
    else if(payload.repairActionName == 'Collected By')
    {
      payload.actionId = '15';
    }
    else if(payload.repairActionName == 'Change of Device')
    {
      payload.actionId = '16';
    }
  
    //
    
    // this.repairActionForm.controls["repairRefId"].setValue(this.repairRefId);
    if(payload.repairActionName == 'Received From' || payload.repairActionName == 'Sent To'){
      //
      this.submitInwordOutword();
    }
    if(payload.repairActionName == 'Sent To'){
      
      if(payload.vendorName !== '')
      {
        payload.status = 'In-Transit';
      }
      else if(payload.patientName !== '')
      {
        payload.status = 'Completed';
      }
    }
    if(payload.repairActionName == 'Sent To'){
      if(payload.vendorName !== '' || payload.branchRefId !== '')
      {
        payload.status = 'In-Transit';
      }
      else if(payload.patientName !== '')
      {
        payload.status = 'Completed'; 
      }
    }
    
    if(payload.repairActionName == 'Payment Done'){
      let tempPayload = {};
      tempPayload['repairRefId'] = this.repairRefId;
      tempPayload['mode'] = payload.paymentType;
      let bar = new Promise<void>((resolve,reject) =>{
        if(payload.paymentType !== 'Cheque'){
          tempPayload['advanceAmount'] = payload.amountC;
        this.apimicroservice.addTempPaymentHistory(tempPayload).subscribe(res => {
          this.spinner.hide(); 
          if(res.status){
            this.apimicroservice.openSnackbar(res.message);
            resolve();
          }else{
            this.apimicroservice.openSnackbar(res.message);
          }
        },(ERROR:HttpErrorResponse) => {
          this.apimicroservice.openSnackbar(ERROR.error.message);
          this.spinner.hide();
      }); 
    }else{
      this.chequeDetails.forEach(ele =>{
        tempPayload['chequeDetails'] = ele;
        tempPayload['advanceAmount'] = ele.amount;
        this.apimicroservice.addTempPaymentHistory(tempPayload).subscribe(res => {
          this.spinner.hide(); 
          if(res.status){
            this.apimicroservice.openSnackbar(res.message);
            resolve();
          }else{
            this.apimicroservice.openSnackbar(res.message);
          }
        },(ERROR:HttpErrorResponse) => {
          this.apimicroservice.openSnackbar(ERROR.error.message);
          this.spinner.hide();
      });
      })
    }
       });
        bar.then(() => {
          payload['repairActionId'] = this.repairActionId +1;
          this.apimicroservice.addRepairAction(payload).subscribe(res => {
            this.spinner.hide(); 
            this.signatureImg = '';
            if(res.status){
              this.apimicroservice.openSnackbar(res.message);
              this.ngOnInit();
              this.dialogRef.close();   
            }else{
              this.apimicroservice.openSnackbar(res.message);
            }
          },(ERROR:HttpErrorResponse) => {
            this.apimicroservice.openSnackbar(ERROR.error.message);
            this.spinner.hide();
        });
        });
    } 
  else if(payload.repairActionName == 'Collected By'){ 
  let payload1 = {}; 
  payload1['repairActionStatus'] = 'Completed';
  payload1['repairActionLastStatus'] = payload.repairActionName;
      let bar1 = new Promise<void>((resolve,reject) =>{     
        this.apimicroservice.updateRepairData(this.repairRefId, payload1).subscribe(res => {
          resolve();
          if (res.status) {
            this.apimicroservice.openSnackbar(res.message);
          } else {
            //  //this.apimicroservice.openSnackbar(res.message);
          }
        },(ERROR:HttpErrorResponse) => {
          this.apimicroservice.openSnackbar(ERROR.error.message);
          this.spinner.hide();
      });
   
       });
        bar1.then(() => {
          payload['repairActionId'] = this.repairActionId +1;
          this.apimicroservice.addRepairAction(payload).subscribe(res => {
            this.spinner.hide(); 
            this.signatureImg = '';
            if(res.status){
              this.apimicroservice.openSnackbar(res.message);
              this.ngOnInit();
              this.dialogRef.close();   
            }else{
              this.apimicroservice.openSnackbar(res.message);
            }
          },(ERROR:HttpErrorResponse) => {
            this.apimicroservice.openSnackbar(ERROR.error.message);
            this.spinner.hide();
        });
        });
    } 
    else
    {
      let payload1 = {};
      
      payload1['repairActionLastStatus'] = payload.repairActionName;
          let bar11 = new Promise<void>((resolve,reject) =>{     
            this.apimicroservice.updateRepairData(this.repairRefId, payload1).subscribe(res => {
              resolve();
              if (res.status) {
                this.apimicroservice.openSnackbar(res.message);
              } else {
              //this.apimicroservice.openSnackbar(res.message);
              }
            },(ERROR:HttpErrorResponse) => {
              this.apimicroservice.openSnackbar(ERROR.error.message);
              this.spinner.hide();
          });
       
           });
           bar11.then(() => {
            payload['repairActionId'] = this.repairActionId + 1;

            if(payload.repairActionName !== 'Create Final Bill')
            {
              this.apimicroservice.addRepairAction(payload).subscribe(res => {
                this.spinner.hide(); 
                this.signatureImg = '';
                if(res.status){
                  this.apimicroservice.openSnackbar(res.message);
                  this.ngOnInit();
                  this.dialogRef.close();   
                }else{
                  this.apimicroservice.openSnackbar(res.message);
                }
              },(ERROR:HttpErrorResponse) => {
                this.apimicroservice.openSnackbar(ERROR.error.message);
                this.spinner.hide();
            });
            }
            else if(payload.repairActionName == 'Create Final Bill')
            {
              this.spinner.hide(); 
              this.ngOnInit();
              this.dialogRef.close(); 
            }
   
  });
    }
  }
  openSnackbar(message: string) {
    return this._snackBar.open(message, "close", {
      verticalPosition: "bottom",
      horizontalPosition: "right",
      duration: 3000,
      panelClass: ["snackbarStyle"],
    });
  }
  
  fetchFollowupList() {
    this.spinner.show();
    let pg = 1;
    let size = 5;
    let search='';
    let branchRef='';
    let clinicRef='';
    let patientRef = this.repairActionForm.get('patientRefId').value;
    //
      // if (this.userType !== 'Super Admin') {
        this.apimicroservice.getFollowupList(pg,size,search,patientRef).subscribe(res => {
          this.spinner.hide();
          //
           this.listOfFollowups = res.data.data;
           this.totalLength = res.data.totalItems;
           //this.buildingLenght = this.listofbuild.length;
          if(res.status){
           // this.apimicroservice.openSnackbar(res.message);  
          }else{
            //this.apimicroservice.openSnackbar(res.message);
          }
        });   
      // } else {

      //   this.apimicroservice.getVendorList(pg,size,search,branchRef,clinicRef).subscribe(res => {
      //     this.spinner.hide();
      //      this.listOfVendors = res.data.vendorData;
      //      this.totalLength = res.data.totalItems;
      //      //this.buildingLenght = this.listofbuild.length;
      //     if(res.status){
      //       // this.apimicroservice.openSnackbar(res.message);   
      //     }else{
      //       //this.apimicroservice.openSnackbar(res.message);
      //     }
      //   });
      // }
  }

  prepopulation(id) {
    this.spinner.show();
    this.apimicroservice.getRepairActionDataById(id).subscribe(res => {
      this.spinner.hide();
      let data = res.data[0];
      //
      this.fid = id;
       if(res.status){
        this.submitButton = false;
         this.updateButton = true;
         this.cancelButton = true;
         this.repairActionForm = this.fb.group({
          "repairActionName": new FormControl(data.repairActionName,Validators.required),
      "patientRefId": new FormControl(data.patientRefId),
      "patientName": new FormControl(data.patientName),
      "paymentType": new FormControl(data.paymentType),
      "userRefId": new FormControl(data.userRefId),
      "userName": new FormControl(data.userName),
      "other": new FormControl(data.other),
      "userId": new FormControl(data.userId),     
      "amountA": new FormControl(data.amountA),
      "amountB": new FormControl(data.amountB),
      "amountC": new FormControl(data.amountC),
      "automatedAction": new FormControl(data.automatedAction),
      "status": new FormControl(data.status),
      "clientOrNextToKinRadioBtn": new FormControl(data.clientOrNextToKinRadioBtn),
      "branchRefId": new FormControl(data.branchRefId),
      "branchName": new FormControl(data.branchName), 
      "clientOrVendorRadioBtn": new FormControl(data.clientOrVendorRadioBtn),
      "deviceAgeInYears": new FormControl(data.deviceAgeInYears),
      "vendorRefId": new FormControl(data.vendorRefId),
      "vendorName": new FormControl(data.vendorName),
      "clientVendorBranchRadioBtn": new FormControl(data.clientVendorBranchRadioBtn),
      // "phone": new FormControl(data.phone,[Validators.pattern("(0|91)?[6-9][0-9]{9}")]),
      // "message": new FormControl(data.message),
      // "fittedBy": new FormControl(data.fittedBy),
      "leftFittedBy": new FormControl(data.leftFittedBy),
      "rightFittedBy": new FormControl(data.rightFittedBy),
      "clientPinCode": new FormControl(data.clientPinCode),
      "textBoxEntry": new FormControl(data.textBoxEntry),
      "textBoxEntrySignature": new FormControl(data.textBoxEntrySignature),
      "textBoxEntryForAmount": new FormControl(data.textBoxEntryForAmount),
      "userType": new FormControl(data.userType),
      "searchFollowup": new FormControl(data.searchFollowup),
      "phoneMsg": new FormControl(data.phoneMsg),
      "clinicName": new FormControl(data.clinicName),
      "clinicRefId": new FormControl(data.clinicRefId),
      "billNo": new FormControl(data.billNo),
      "inwardOutwardId": new FormControl(data.inwardOutwardId),
      "branchWiseId": new FormControl(data.branchWiseId),
      "notes": new FormControl(data.notes),
     
      "refType": new FormControl(data.refType),
      "deviceType": new FormControl(data.deviceType),
      "inwardOutward": new FormControl(data.inwardOutward),
      "date": new FormControl(data.date),
      "docType": new FormControl(data.docType),
      "companyName": new FormControl(data.companyName),
      "companyRefId": new FormControl(data.companyRefId),
      "courierCharges": new FormControl(data.courierCharges,Validators.pattern("^[0-9]+$")),
      "fittedDeviceRefId": new FormControl(data.fittedDeviceRefId),
      "selectPaymentType": new FormControl(data.selectPaymentType),
      "ServiceName": new FormControl(data.ServiceName),
      "leftDeviceAgeInYears": new FormControl(data.leftDeviceAgeInYears),
      "rightDeviceAgeInYears": new FormControl(data.rightDeviceAgeInYears),
   
      "billingCompanyRefId": new FormControl(data.billingCompanyRefId),
      "courierRefId": new FormControl(data.courierRefId),
      "courierName": new FormControl(data.courierName),
      "docDate": new FormControl(data.docDate),   
      "cityRefId": new FormControl(data.cityRefId),
      "cityName": new FormControl(data.cityName), 
      "typeRefId": new FormControl(data.typeRefId),
      "typeName": new FormControl(data.typeName),
      "repairRefId": new FormControl(),
      "trackingNumber": new FormControl(data.trackingNumber,[Validators.pattern("^[a-zA-Z0-9]*$")]),  
      "documentsNumber": new FormControl(data.documentsNumber,[Validators.pattern("^[a-zA-Z0-9/ ]*$")]), 
      "actionId": new FormControl(data.actionId),
      "originalSerialNo": new FormControl(data.originalSerialNo),
      "newSerialNo": new FormControl(data.newSerialNo),
         "updateddAt": new Date(), 


         ///////////////////////////////////
       
      
         //////////////
         
  

        });
        // this.signatureImg = data.textBoxEntrySignature;
        // //
        this.patientName = data.patientName;
        this.InwardOutwardClick(data.inwardOutward);
        this.type(data.typeName); 
        let bar = new Promise<void>((resolve,reject) =>{
          // this.actionClick();
          if(this.actionId == '')
          {
            this.actionClick();
          }
          resolve();
         });
          bar.then(() => {
            this.selectType(data.clientVendorBranchRadioBtn);
          });
         this.type(data.typeName);
        
        this.signatureImg = data.textBoxEntrySignature;
        if(data.repairActionName == 'Collected By')
        {
          this.hideSignature = true;
        }
        else
        {
          this.hideSignature = false;
        }
     
        //
        // this.superBranchId = data.branchWiseId;
        // this.bId = data.branchWiseId;
       }else{
     //    //this.apimicroservice.openSnackbar(res.message);
       }
     });
  }
  addNew() {
    this.repairActionForm.reset();
    this.updateButton = false;
    this.deleteButton = false;
  }

  onPageChange(event) {
    this.spinner.show();
    this.page = event;
   // let p = this.page - 1;
    this.searchText = this.repairActionForm.get('searchFollowup').value;
    let branchRef='';
    let clinicRef='';
    let patientRef = this.repairActionForm.get('patientRefId').value;
    // if (this.userType !== 'Super Admin') {
      this.apimicroservice.getFollowupList(this.page,this.size,this.searchText,patientRef).subscribe(res => {
        this.spinner.hide();
          this.listOfFollowups = res.data.data;
          this.totalLength = res.data.totalItems;
          //this.buildingLenght = this.listofbuild.length;
         if(res.status){
          //  this.apimicroservice.openSnackbar(res.message);
           
         }else{
           //this.apimicroservice.openSnackbar(res.message);
         }
    
       });
    // } else {

    //   this.apimicroservice.getVendorList(this.page,this.size,this.searchText,branchRef,clinicRef).subscribe(res => {
    //     this.spinner.hide();
    //       this.listOfFollowups = res.data.vendorData;
    //       this.totalLength = res.data.totalItems;
    //       //this.buildingLenght = this.listofbuild.length;
    //      if(res.status){
    //       //  this.apimicroservice.openSnackbar(res.message);
           
    //      }else{
    //        //this.apimicroservice.openSnackbar(res.message);
    //      }
    
    //    });
      
    // }
      
    
  }
  onKeyUpEvent(event: any) {
    this.searchText = this.repairActionForm.get('searchFollowup').value;
    let branchRef='';
    let clinicRef='';
   
let page=1;
let size=5;
    // if (this.userType !== 'Super Admin') {
      let patientRef = this.repairActionForm.get('patientRefId').value;
      this.apimicroservice.getFollowupList(this.page,this.size,this.searchText,patientRef).subscribe(res => {
        //
        this.listOfFollowups = res.data.data;
        this.totalLength = res.data.totalItems;
        this.page=res.data.pageNumber;
        //this.buildingLenght = this.listofbuild.length;
       if(res.status){
        //  this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
      
    // } else {

    //   this.apimicroservice.getVendorList(page,size,this.searchText,branchRef,clinicRef).subscribe(res => {
    //     //
    //     this.listOfFollowups = res.data.vendorData;
    //     this.totalLength = res.data.totalItems;
    //     this.page=res.data.pageNumber;
    //     //this.buildingLenght = this.listofbuild.length;
    //    if(res.status){
    //     //  this.apimicroservice.openSnackbar(res.message);
         
    //    }else{
    //      //this.apimicroservice.openSnackbar(res.message);
    //    }
    //  });
      
    // }
      
    
  }
  cancelUpdate(){
    this.repairActionForm.reset();
    this.submitButton=true;
    this.updateButton=false;
    this.cancelButton=false;
    this.ngOnInit();
   // this.removeValidator();
  }
 
  redirect(to): void {
    this.router.navigate([to]);
  }
  torepairActionForm() {
    document.getElementById("torepairActionForm").scrollIntoView();
  }
  toFollowupList(){
    document.getElementById("toFollowupTable").scrollIntoView();
  }
  onback() {
    this.apimicroservice.back()
  }
  onClose(): void {
    this.dialogRef.close(); 
  }
  actionClick()
  { 
    this.bytheFault = true;
    this.isDoctor = false;
    this.isclinicalStaff = false;
    this.isClient = false;
    this.isVendor = false;
    this.isBranch = false;
    this.isNextToKin = false;
    this.isClinic = false;
    this.isPhone = false;
    this.isMessage = false; 
    this.hideSignature = false;
    let payload = this.repairActionForm.value;
    this.statusAction = true;
    this.step = payload.repairActionName;
    if(payload.repairActionName == "Payment Done")
    {
      // this.allowUser = true;
      if(this.billGenerated == true){
        this.disableSubmit = true;
       this.openSnackbar("Bill Is Already Generated, Can't Do the Advance Payment! Bill No. Is - "+this.billNumber);
      }else{
        this.disableSubmit = false;
      this.ActionPaymentDone = true;
      this.ActionCheckedBy = false;
      this.ActionCollectedBy = false;
      this.ActionCommunicationWith = false;
      this.ActionDulyRepaired = false;
      this.ActionEstimateAdvised = false;
      this.ActionEstimateApproved = false;
      this.ActionEstimateCalculated = false;
      this.ActionEstimateReceived = false;
      this.ActionEstimateRejected = false;
      this.ActionProgrammedBy = false;
      this.ActionReceivedFrom = false;
      this.ActionSentTo = false;
      this.ActionUnrepairedReady = false;
      this.repairActionForm.controls["status"].setValue('In-process');
      }
    }
    else if(payload.repairActionName == "Change of Device")
  {
    this.openChangeDevicePopUp();
    // this.allowUser = true;
    this.disableSubmit = false;
    this.ActionPaymentDone = false;
    this.ActionCheckedBy = false;
    this.ActionCollectedBy = false;
    this.ActionCommunicationWith = false;
    this.ActionDulyRepaired = false;
    this.ActionEstimateAdvised = false;
    this.ActionEstimateApproved = false;
    this.ActionEstimateCalculated = false;
    this.ActionEstimateReceived = false;
    this.ActionEstimateRejected = false;
    this.ActionProgrammedBy = false;
    this.ActionReceivedFrom = false;
    this.ActionSentTo = false;
    this.ActionUnrepairedReady = false;
    this.signatureImg =''; 
    this.repairActionForm.controls["status"].setValue('Awaiting Collection');

  } 
    else if(payload.repairActionName == "Checked By")
    {
      //this.allowUser = true;
      this.disableSubmit = false;
      this.ActionPaymentDone = false;
      this.ActionCheckedBy = true;
      this.ActionCollectedBy = false;
      this.ActionCommunicationWith = false;
      this.ActionDulyRepaired = false;
      this.ActionEstimateAdvised = false;
      this.ActionEstimateApproved = false;
      this.ActionEstimateCalculated = false;
      this.ActionEstimateReceived = false;
      this.ActionEstimateRejected = false;
      this.ActionProgrammedBy = false;
      this.ActionReceivedFrom = false;
      this.ActionSentTo = false;
      this.ActionUnrepairedReady = false;

      let action = this.userName;
      this.signatureImg ='';
      this.repairActionForm.controls["status"].setValue('In-process');
      // this.repairActionForm.controls["automatedAction"].setValue(this.userName);
      
      // if(payload.userType == 'Doctor')
      // {
      //   this.doctorSelect();
      // }
      // else if(payload.userType == 'Clinical Staff')
      // {
      //   this.clinicalStaffSelect();
      // }
    }
    else if(payload.repairActionName == "Collected By")
    {
      //this.allowUser = true;
      this.disableSubmit = false;
      this.ActionPaymentDone = false;
      this.ActionCheckedBy = false;
      this.ActionCollectedBy = true;
      this.ActionCommunicationWith = false;
      this.ActionDulyRepaired = false;
      this.ActionEstimateAdvised = false;
      this.ActionEstimateApproved = false;
      this.ActionEstimateCalculated = false;
      this.ActionEstimateReceived = false;
      this.ActionEstimateRejected = false;
      this.ActionProgrammedBy = false;
      this.ActionReceivedFrom = false;
      this.ActionSentTo = false;
      this.ActionUnrepairedReady = false;
      //this.selectType(payload.clientVendorBranchRadioBtn);
      // this.repairActionForm.controls["clientVendorBranchRadioBtn"].setValue('Client');
      // this.selectType('Client');
      this.repairActionForm.controls["status"].setValue('Completed');
    //
    
      this.ngAfterViewInit();
    

    
      this.clearPad();
     
      // this.savePad();
    }
    else if(payload.repairActionName == "Communication With")
    {
      //this.allowUser = true;
      this.disableSubmit = false;
      this.ActionPaymentDone = false;
      this.ActionCheckedBy = false;
      this.ActionCollectedBy = false;
      this.ActionCommunicationWith = true;
      this.ActionDulyRepaired = false;
      this.ActionEstimateAdvised = false;
      this.ActionEstimateApproved = false;
      this.ActionEstimateCalculated = false;
      this.ActionEstimateReceived = false;
      this.ActionEstimateRejected = false;
      this.ActionProgrammedBy = false;
      this.ActionReceivedFrom = false;
      this.ActionSentTo = false;
      this.ActionUnrepairedReady = false;
      this.repairActionForm.controls["status"].setValue('In-process');
      this.selectType(payload.clientVendorBranchRadioBtn);
      // this.repairActionForm.controls["clientVendorBranchRadioBtn"].setValue('Client');
      // this.selectType('Client');
      // this.selectType(payload.clientVendorBranchRadioBtn);
      this.selectPhoneMsg(payload.phoneMsg);
      this.signatureImg ='';
    } 
    else if(payload.repairActionName == "Duly Repaired Ready At")
    {
      //this.allowUser = true;
      this.disableSubmit = false;
      this.ActionPaymentDone = false;
      this.ActionCheckedBy = false;
      this.ActionCollectedBy = false;
      this.ActionCommunicationWith = false;
      this.ActionDulyRepaired = true;
      this.ActionEstimateAdvised = false;
      this.ActionEstimateApproved = false;
      this.ActionEstimateCalculated = false;
      this.ActionEstimateReceived = false;
      this.ActionEstimateRejected = false;
      this.ActionProgrammedBy = false;
      this.ActionReceivedFrom = false;
      this.ActionSentTo = false;
      this.ActionUnrepairedReady = false;
      this.signatureImg ='';
      this.repairActionForm.controls["status"].setValue('Awaiting Collection');
    }
    else if(payload.repairActionName == "Estimate Advised To")
    {
      //this.allowUser = true;
      this.disableSubmit = false;
      this.ActionPaymentDone = false;
      this.ActionCheckedBy = false;
      this.ActionCollectedBy = false;
      this.ActionCommunicationWith = false;
      this.ActionDulyRepaired = false;
      this.ActionEstimateAdvised = true;
      this.ActionEstimateApproved = false;
      this.ActionEstimateCalculated = false;
      this.ActionEstimateReceived = false;
      this.ActionEstimateRejected = false;
      this.ActionProgrammedBy = false;
      this.ActionReceivedFrom = false;
      this.ActionSentTo = false;
      this.ActionUnrepairedReady = false;
      this.signatureImg ='';
      //this.selectType(payload.clientVendorBranchRadioBtn);
       this.repairActionForm.controls["clientVendorBranchRadioBtn"].setValue('Client');
      this.selectPhoneMsg(payload.phoneMsg);
      this.selectType('Client');
      this.repairActionForm.controls["status"].setValue('Awaiting Approval/Payment');

    }
    else if(payload.repairActionName == "Estimate Approved By")
    {
      //this.allowUser = true;
      this.disableSubmit = false;
      this.ActionPaymentDone = false;
      this.ActionCheckedBy = false;
      this.ActionCollectedBy = false;
      this.ActionCommunicationWith = false;
      this.ActionDulyRepaired = false;
      this.ActionEstimateAdvised = false;
      this.ActionEstimateApproved = true;
      this.ActionEstimateCalculated = false;
      this.ActionEstimateReceived = false;
      this.ActionEstimateRejected = false;
      this.ActionProgrammedBy = false;
      this.ActionReceivedFrom = false;
      this.ActionSentTo = false;
      this.ActionUnrepairedReady = false;
      this.signatureImg ='';
     // this.selectType(payload.clientVendorBranchRadioBtn);
      // this.repairActionForm.controls["clientVendorBranchRadioBtn"].setValue('Client');
      // this.selectType('Client');
      this.repairActionForm.controls["status"].setValue('In-process');
      this.selectPhoneMsg(payload.phoneMsg);
    }
    else if(payload.repairActionName == "Estimate Calculated")
    {
      this.disableSubmit = false;
      this.ActionPaymentDone = false;
      this.ActionCheckedBy = false;
      this.ActionCollectedBy = false;
      this.ActionCommunicationWith = false;
      this.ActionDulyRepaired = false;
      this.ActionEstimateAdvised = false;
      this.ActionEstimateApproved = false;
      this.ActionEstimateCalculated = true;
      this.ActionEstimateReceived = false;
      this.ActionEstimateRejected = false;
      this.ActionProgrammedBy = false;
      this.ActionReceivedFrom = false;
      this.ActionSentTo = false;
      this.ActionUnrepairedReady = false;
      this.signatureImg ='';
     
        // if(this.restrictData == true) 
        // {
        //   this.allowUser = true;
        // } 
        // else
        // {
        //   this.allowUser = false;
        // } 
      this.repairActionForm.controls["status"].setValue('In-process');
    }
    else if(payload.repairActionName == "Estimate Received From")
    {
      //this.allowUser = true;
      this.disableSubmit = false;
      this.ActionPaymentDone = false;
      this.ActionCheckedBy = false;
      this.ActionCollectedBy = false;
      this.ActionCommunicationWith = false;
      this.ActionDulyRepaired = false;
      this.ActionEstimateAdvised = false;
      this.ActionEstimateApproved = false;
      this.ActionEstimateCalculated = false;
      this.ActionEstimateReceived = true;
      this.ActionEstimateRejected = false;
      this.ActionProgrammedBy = false;
      this.ActionReceivedFrom = false;
      this.ActionSentTo = false;
      this.ActionUnrepairedReady = false;
      this.signatureImg ='';
      this.repairActionForm.controls["status"].setValue('In-process');
    }
    else if(payload.repairActionName == "Estimate Rejected By")
    {
      //this.allowUser = true;
      this.disableSubmit = false;
      this.ActionPaymentDone = false;
      this.ActionCheckedBy = false;
      this.ActionCollectedBy = false;
      this.ActionCommunicationWith = false;
      this.ActionDulyRepaired = false;
      this.ActionEstimateAdvised = false;
      this.ActionEstimateApproved = false;
      this.ActionEstimateCalculated = false;
      this.ActionEstimateReceived = false;
      this.ActionEstimateRejected = true;
      this.ActionProgrammedBy = false;
      this.ActionReceivedFrom = false;
      this.ActionSentTo = false;
      this.ActionUnrepairedReady = false;
      this.signatureImg ='';
     // this.selectType(payload.clientVendorBranchRadioBtn);
      // this.repairActionForm.controls["clientVendorBranchRadioBtn"].setValue('Client');
      // this.selectType('Client');
      this.repairActionForm.controls["status"].setValue('In-process');
      this.selectPhoneMsg(payload.phoneMsg);
    }
    else if(payload.repairActionName == "Programmed By")
    {
      //this.allowUser = true;
      this.disableSubmit = false;
      this.ActionPaymentDone = false;
      this.ActionCheckedBy = false;
      this.ActionCollectedBy = false;
      this.ActionCommunicationWith = false;
      this.ActionDulyRepaired = false;
      this.ActionEstimateAdvised = false;
      this.ActionEstimateApproved = false;
      this.ActionEstimateCalculated = false;
      this.ActionEstimateReceived = false;
      this.ActionEstimateRejected = false;
      this.ActionProgrammedBy = true;
      this.ActionReceivedFrom = false;
      this.ActionSentTo = false;
      this.ActionUnrepairedReady = false;
      this.signatureImg ='';
      this.repairActionForm.controls["status"].setValue('In-process');
      if(payload.userType == 'Doctor')
      {
        this.doctorSelect();
      }
      else if(payload.userType == 'Clinical Staff')
      {
        this.clinicalStaffSelect();
      }
    }
    else if(payload.repairActionName == "Received From")
    {
      //this.allowUser = true;
      this.disableSubmit = false;
      this.ActionPaymentDone = false;
      this.ActionCheckedBy = false;
      this.ActionCollectedBy = false;
      this.ActionCommunicationWith = false;
      this.ActionDulyRepaired = false;
      this.ActionEstimateAdvised = false;
      this.ActionEstimateApproved = false;
      this.ActionEstimateCalculated = false;
      this.ActionEstimateReceived = false;
      this.ActionEstimateRejected = false;
      this.ActionProgrammedBy = false;
      this.ActionReceivedFrom = true;
      this.ActionSentTo = false;
      this.ActionUnrepairedReady = false;
      this.signatureImg ='';
      // this.repairActionForm.controls["clientVendorBranchRadioBtn"].setValue('Client');
      this.isInwardOutward = 'Inward';
  
      this.repairActionForm.controls["branchRefId"].setValue(this.branchRefId);
      this.repairActionForm.controls["branchName"].setValue(this.repairBranch);
      this.selectType(payload.clientVendorBranchRadioBtn);

      this.repairActionForm.controls['inwardOutward'].setValue('Inward');
      this.InwardOutwardClick('Inward');
     // this.selectType('Client');
      this.repairActionForm.controls["status"].setValue('In-process');

    } 
    else if(payload.repairActionName == "Sent To")
    {
      //this.allowUser = true;
      this.disableSubmit = false;
      this.ActionPaymentDone = false;
      this.ActionCheckedBy = false;
      this.ActionCollectedBy = false;
      this.ActionCommunicationWith = false;
      this.ActionDulyRepaired = false;
      this.ActionEstimateAdvised = false;
      this.ActionEstimateApproved = false;
      this.ActionEstimateCalculated = false;
      this.ActionEstimateReceived = false;
      this.ActionEstimateRejected = false;
      this.ActionProgrammedBy = false;
      this.ActionReceivedFrom = false;
      this.ActionSentTo = true;
      this.ActionUnrepairedReady = false;
      this.signatureImg ='';
      // this.repairActionForm.controls["status"].setValue('In-process');
      this.repairActionForm.controls["branchRefId"].setValue(this.branchRefId);
      this.repairActionForm.controls["branchName"].setValue(this.repairBranch);
      this.selectType(payload.clientVendorBranchRadioBtn);
      this.isInwardOutward = 'Outward';
    this.repairActionForm.controls['inwardOutward'].setValue('Outward');
    this.InwardOutwardClick('Outward');
    //this.type("Hearing Device");
    }
    else if(payload.repairActionName == "Unrepaired Ready")
    {
      // this.allowUser = true;
      this.disableSubmit = false;
      this.ActionPaymentDone = false;
      this.ActionCheckedBy = false;
      this.ActionCollectedBy = false;
      this.ActionCommunicationWith = false;
      this.ActionDulyRepaired = false;
      this.ActionEstimateAdvised = false;
      this.ActionEstimateApproved = false;
      this.ActionEstimateCalculated = false;
      this.ActionEstimateReceived = false;
      this.ActionEstimateRejected = false;
      this.ActionProgrammedBy = false;
      this.ActionReceivedFrom = false;
      this.ActionSentTo = false;
      this.ActionUnrepairedReady = true;
      this.signatureImg ='';
      this.repairActionForm.controls["status"].setValue('Awaiting Collection');
    } 
    else if(payload.repairActionName == "Create Final Bill")
    {
      //
      
      // this.allowUser = true;
      if(this.restrictData == true){
      if(this.billGenerated == true){
        this.openSnackbar("Bill Is Already Generated,Bill No. Is :-  "+this.billNumber);
        this.disableSubmit = true;
       }else{
      this.disableSubmit = false;
      this.ActionPaymentDone = false;
      this.ActionCheckedBy = false;
      this.ActionCollectedBy = false;
      this.ActionCommunicationWith = false;
      this.ActionDulyRepaired = false; 
      this.ActionEstimateApproved = false;
      this.ActionEstimateCalculated = false;
      this.ActionEstimateReceived = false;
      this.ActionEstimateRejected = false;
      this.ActionProgrammedBy = false;
      this.ActionReceivedFrom = false;
      this.ActionSentTo = false;
      this.ActionUnrepairedReady = false;
      this.FinalBill = true;
      this.signatureImg ='';
      this.repairActionForm.controls["status"].setValue('In-Process');
      this.openServicePopUp();
    } 
  }
  
else
{
  this.openSnackbar("Estimate Amount is not approved please approved");
}
}
  }
  fetchDoctorList() {
    this.apimicroservice.getAllDoctorlist().subscribe(res => {
      //
      if (res.status) {    
        this.doctorList = res.data.data;
        this.filteredListDoctor= this.doctorList.slice(); 
      } else {
      //  this.apimicroservice.openSnackbar(res.message);
      }
    })


}
InwardOutwardClick(e)
{
  if(e == 'Inward')
  {
  //   this.repairActionForm.controls['patientName'].setValidators(Validators.required);
  //   this.repairActionForm.controls['patientName'].updateValueAndValidity();
  // this.repairActionForm.controls['companyRefId'].setErrors(null);
  // this.repairActionForm.controls['companyRefId'].clearValidators();
  // this.repairActionForm.controls['companyRefId'].updateValueAndValidity();

    this.inward = true;
    this.outward = false;
    this.selectRef('Client');
    this.repairActionForm.get("refType").setValue('Client');      
  }
  else if(e == 'Outward')
  {
    // this.repairActionForm.controls['companyRefId'].setValidators(Validators.required);
    // this.repairActionForm.controls['companyRefId'].updateValueAndValidity();

    // this.repairActionForm.controls['patientName'].setErrors(null);
    // this.repairActionForm.controls['patientName'].clearValidators();
    // this.repairActionForm.controls['patientName'].updateValueAndValidity();

    this.outward = true;
    this.inward = false;
    this.selectRef('Manufacturer');
    this.repairActionForm.get("refType").setValue('Manufacturer');     
  }
}
selectRef(e)
{
if(e == 'Client')
{
  this.client = true;
  this.manufacturer = false;
  this.inward = true;
  this.outward = false;
  // this.repairActionForm.get("inwardOutward").setValue('Inward'); 
  // this.repairActionForm.get("companyName").setValue(''); 
  
}
else if(e == 'Manufacturer')
{
  this.manufacturer = true;
  this.client = false;
  this.outward = true;
  this.inward = false;
  // this.repairActionForm.get("inwardOutward").setValue('Outward'); 
  // this.repairActionForm.get("patientName").setValue(''); 
  
}
}
fetchClinicalStaffList() {
    this.apimicroservice.getAllclinicalStafflist().subscribe(res => {
      //
      if (res.status) {    
        this.clinicalStaffList = res.data.data;
        this.filteredListClinicalStaff= this.clinicalStaffList.slice();
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    })
}
doctorClick(fname,lname)
{
  this.repairActionForm.controls["userName"].setValue(fname + ' ' + lname);
}
clinicalClick(staffName)
{
  this.repairActionForm.controls["userName"].setValue(staffName);
}
  doctorSelect()
{
this.isDoctor = true;
this.isclinicalStaff = false;
this.fetchDoctorList();
  // this.repairActionForm.controls['userRefId'].setValidators(Validators.required);
  // this.repairActionForm.controls['userRefId'].updateValueAndValidity();

  // this.repairActionForm.controls['userRefId'].setErrors(null);
  // this.repairActionForm.controls['userRefId'].clearValidators();
  // this.repairActionForm.controls['userRefId'].updateValueAndValidity();

  // this.repairActionForm.controls['userName'].setErrors(null);
  // this.repairActionForm.controls['userName'].clearValidators();
  // this.repairActionForm.controls['userName'].updateValueAndValidity();
}

clinicalStaffSelect()
{
  this.isDoctor = false;
  this.isclinicalStaff = true;
  this.fetchClinicalStaffList();
  // this.repairActionForm.controls['userRefId'].setValidators(Validators.required);
  // this.repairActionForm.controls['userRefId'].updateValueAndValidity();

  // this.repairActionForm.controls['userRefId'].setErrors(null);
  // this.repairActionForm.controls['userRefId'].clearValidators();
  // this.repairActionForm.controls['userRefId'].updateValueAndValidity();

  // this.repairActionForm.controls['userName'].setErrors(null);
  // this.repairActionForm.controls['userName'].clearValidators();
  // this.repairActionForm.controls['userName'].updateValueAndValidity();
}
openChangeDevicePopUp() {
  //
  
  const dialogRef3 = this.dialog.open(PopUpChangeOfDeviceComponent, 
    {
      width: "500px",
      data:{
        rightModel:this.data.rightModel,
        rightSrNo:this.data.rightSrNo,
        leftModel:this.data.leftModel,
        leftSrNo:this.data.leftSrNo,
        patientRef:this.data.patientRefId,
      } 
      // height: "550px"
    }); 
    dialogRef3.afterClosed().subscribe((result) => {
   //
   this.repairActionForm.get("originalSerialNo").setValue(result.data.originalSerialNo);
   this.repairActionForm.get("newSerialNo").setValue(result.data.serialNo);
   
      // this.customHaForm.get("patientRefId").setValue(result.data._id);
      
    });
} 
openDoctorPopUp() {
  const dialogRef3 = this.dialog.open(PopUpDoctorComponent,
    {
      width: "1200px",
    });
    dialogRef3.afterClosed().subscribe((result) => {
      // this.apimicroservice.getAllDoctorlist().subscribe(res => {
      //   //
      //   if (res.status) {    
      //     this.doctorList = res.data.data;
      //     this.filteredListDoctor= this.doctorList.slice(); 
      //     this.fetchDoctorList();
      //     this.repairActionForm.get("userRefId").setValue(res.data.data[0]._id)
      //   } else {
      //   //  this.apimicroservice.openSnackbar(res.message);
      //   }
      // })
       this.fetchDoctorList();  
       });
} 
openClinicalStaffPopUp() {
  const dialogRef3 = this.dialog.open(PopUpClinicalStaffComponent,
    {
      width: "1200px",
    });
    dialogRef3.afterClosed().subscribe((result) => {
      this.apimicroservice.getAllclinicalStafflist().subscribe(res => {
        //
        if (res.status) {    
          this.clinicalStaffList = res.data.data;
          this.filteredListClinicalStaff= this.clinicalStaffList.slice();
          this.clinicalClick(res.data.data[0].staffMemberName);
          this.repairActionForm.get("userRefId").setValue(res.data.data[0]._id)
        } else {
          //this.apimicroservice.openSnackbar(res.message);
        }
      })
     // this.fetchClinicalStaffList();  
     }); 
}
onSelectPatient(id){
  this.spinner.show();
  this.apimicroservice.getPatientById(id).subscribe(res => {
    this.spinner.hide();
    let data = res.data[0];
    this.repairActionForm.controls["patientName"].setValue(data.firstName + ' ' + data.lastName);
    this.repairActionForm.controls["patientRefId"].setValue(data._id);
    this.pId = data._id;

  });
 
}
fetchVendorList(){
    let id = '';
    this.apimicroservice.getVendorById(id).subscribe(res => {
      //
     
     this.vendorList = res.data;
     this.filteredListVendor= this.vendorList.slice();
     if(res.status){
       //this.apimicroservice.openSnackbar(res.message);
       
     }else{
       //this.apimicroservice.openSnackbar(res.message);
     }
  });
}
selectType(e)
{
  //
  // this.isVendor = false;
  // this.ActionEstimateReceived = false;
  if(e == 'Client')
  {
    this.isClient = true;
    this.isVendor = false;
    this.isBranch = false;
    this.isNextToKin = false;
    this.isClinic = false;
  }
  else if(e == 'Next To Kin')
  {
    this.isBranch = false;
    this.isClient = false;
    this.isVendor = false;
    this.isNextToKin = true;
    this.isClinic = false;
  }
  else if(e == 'Clinic')
  {
    this.isBranch = false;
    this.isClient = false;
    this.isVendor = false;
    this.isNextToKin = false;
    this.isClinic = true;
  }
  else if(e == 'Vendor')
  {
    this.isVendor = true;
    this.isClient = false;
    this.isBranch = false;
    this.isNextToKin = false;
    this.isClinic = false;
  }
  else if(e == 'Branch')
  {
    this.isBranch = true;
    this.isClient = false;
    this.isVendor = false;
    this.isNextToKin = false;
    this.isClinic = false;
  }
  else 
  {
    this.isBranch = false;
    this.isClient = false;
    this.isVendor = false;
    this.isNextToKin = false;
    this.isClinic = false;
  }
}
onSelectVendor(vdname){
    this.repairActionForm.controls['vendorName'].setValue(vdname);
  }
  selectPhoneMsg(e)
  {
    if(e == 'Phone')
  {
    this.isPhone = true;
    this.isMessage = false;
  }
  else if( e == 'Message')
  {
    this.isPhone = false;
    this.isMessage = true;
  }
  else
  {
    this.isPhone = false;
    this.isMessage = false;
  }
  }
  // WhatsApp()
  // {
  //   let payload = this.repairActionForm.value;  
  //   let num = payload.phone; 
  //   if(num.length == 10)
  //   {
  //     sessionStorage.setItem('whatsAppNumber',payload.phone);
  //     let mobileNumber = sessionStorage.getItem('whatsAppNumber');
  //     window.open("https://wa.me/91"+mobileNumber);
  //   }
  //   else
  //   {
  //     this.repairActionForm.get("phone").markAsTouched();
  //   }
  // }
  fetchBranchList() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      //  
      if (res.status) {    
        this.BranchList = res.data;
        this.filteredListBranch= this.BranchList.slice();
      } else {
      //  this.apimicroservice.openSnackbar(res.message);
      }
    })
  } 
  branchClick(id,branchName)
  {
    //  this.repairActionForm.controls["branchName"].setValue(branchName);
    //  this.apimicroservice.getBranchById(id).subscribe(res => { 
    //  let payload = this.repairActionForm.value;
    //   payload.clinicRefId = res.data[0].clinicRefId;
    //   this.repairActionForm.controls["clinicRefId"].setValue(res.data[0].clinicRefId);
    // });
    
   this.repairActionForm.controls["branchName"].setValue(branchName);

   this.apimicroservice.getBranchById(id).subscribe(res => { 
     //
       
     let data = res.data[0]; 
     let payload = this.repairActionForm.value;
      payload.clinicRefId = res.data[0].clinicRefId;
      this.repairActionForm.controls["clinicRefId"].setValue(res.data[0].clinicRefId);
       if(this.updateButton == false){
      
        this.apimicroservice.getInOutwardLastId(payload.branchRefId).subscribe(res => { 
          //   
         
          if (res['status']) {
            let staffIdd = res['data']['totalItems'];
            this.repairActionForm.controls['branchWiseId'].setValue(staffIdd + 1);
            //this.bId=staffIdd + 1;
          } else {
            this.apimicroservice.openSnackbar("Something went wrong");
          }
        
        })
      }
    });
  }
  openBranchPopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpBranchComponent,
      {
        width: "1200px",
      }); 
      dialogRef3.afterClosed().subscribe((result) => {
        let clinicRef= sessionStorage.getItem('clinicRef');
        this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
          //  
          if (res.status) {    
            this.BranchList = res.data;
            this.filteredListBranch= this.BranchList.slice();
            this.branchClick(res.data[0]._id,res.data[0].branchName);
            this.repairActionForm.get("branchRefId").setValue(res.data[0]._id);
          } else {
           // this.apimicroservice.openSnackbar(res.message);
          }
        })
        //this.fetchBranchList();
      })
  }
  canvasClick()
{
  //
  
 this.signatureImg = "";
 //
 
}
openInward(inwardOutward)
{ 
  let payload = this.repairActionForm.value;
      const dialogRef3 = this.dialog.open(PopUpInwardOutwardComponent,
          {
            // width: "850px",
            data: {
              patientRefId:payload.patientRefId,
              patientName:payload.patientName,    
              companyRefId:payload.vendorRefId,
              companyName:payload.vendorName,     
              isInwardOutward:inwardOutward,
              repairRefId:this.repairRefId 
            } 
          });
          dialogRef3.afterClosed().subscribe((result) => {
         this.inwardOutwardId = result.data;
         //
         
           });
}
openServicePopUp() {
  localStorage.clear();
  const dialogRef3 = this.dialog.open(PopupServiceComponent,
    {
      // width: "900px", 
      // height: "400px",
        data: {
          patientRefId: this.patientRefId,
          patientName: this.patientName,
          branchRefId: this.branchRefId,
          repairBranch: this.repairBranch,
          repairRefId: this.repairRefId,
          patientMobileNo: this.patientMobileNo,
          patientId:this.patientId, 
          appointId:'', 
          patientDetails:'',
          estimateAmount:this.amountB,
          page:'Repair', 
        }
    
    }); 

    dialogRef3.afterClosed().subscribe((result) => {
       //
       if(result !== undefined){
        this.billNo = result.data.billNo;
        this.ServiceName = result.data.ServiceName;
        this.repairActionForm.controls["billNo"].setValue(this.billNo);
        //
        this.repairActionForm.controls["ServiceName"].setValue(this.ServiceName);
        let payload = this.repairActionForm.value;
        payload.actionId = '12';
        payload['repairActionId'] = this.repairActionId +1;
        this.apimicroservice.addRepairAction(payload).subscribe(res => {
         // this.spinner.hide(); 
          this.signatureImg = '';
          if(res.status){
            this.apimicroservice.openSnackbar(res.message);
            //this.ngOnInit();
            //this.dialogRef.close();   
          }else{
            this.apimicroservice.openSnackbar(res.message);
          }
        },(ERROR:HttpErrorResponse) => {
          this.apimicroservice.openSnackbar(ERROR.error.message);
          //this.spinner.hide();
      });
       }
      // if(result !== undefined){
      //   this.patientRefId = result.data.patientRefId;
      //   this.appointId = result.data.appointId;
      //   //
      //   if(result.data.withoutAppId !== undefined){
      //     this.withoutAppId = result.data.withoutAppId;
      //     this.fetchBillingListByWithoutAppId();
      //   }else{
      //   this.fetchBillList();
      //   }
      // }
      
     });
} 
ModeOfPaymentClick(e)
{
  let mode = e;
  
 if(e == 'Cheque')
 {
   this.modeval = 'Cheque';
   
   let finalChequeAmt = []; 
  // this.finalChequeAmt.push({"amount":calAmt,"id":i});
   
   //
   const dialogRef = this.dialog.open(PopupModeOfPaymentComponent,
     {
       data: {
         data: mode,
         checkAmountArr:finalChequeAmt,
         pagename:'Repair Action'
       }
     });

     dialogRef.afterClosed().subscribe((result) => {
       //
       this.chequeDetails = [];
       if(result !== undefined){
       this.chequeDetails = result.data;
       //
       let chequeAmt = 0;
       this.chequeDetails.forEach(ele =>{
          chequeAmt = chequeAmt + parseInt(ele.amount);
       });
       this.repairActionForm.controls['amountC'].setValue(chequeAmt);
       }else{
         this.repairActionForm.controls['paymentType'].setValue('Cash');
         this.modeval = 'Cash';
        //  this.balanceAmount = this.balanceAmount - this.advanceAmount;
        //  this.pendingChequeAmount = this.pendingChequeAmount - this.advanceAmount;
       }
     });
 }
 else{
    this.modeval = mode;
    this.chequeDetails = [];
  //  if(this.pendingChequeAmount !== 0){
  //  this.balanceAmount = this.balanceAmount - this.advanceAmount;
  //  this.pendingChequeAmount = this.pendingChequeAmount - this.advanceAmount;
   }
 }

 
submitInwordOutword(){
  
   let payload = this.repairActionForm.value;
   payload.repairRefId = this.repairRefId;
   if(this.userType !== 'Super Admin')
   {
     payload.branchRefId = this.branchRef;
     payload.clinicRefId = this.clinicRef;
     payload.branchName = this.repairBranch;
   } 

   let InwardOutwardpayload = {};
   InwardOutwardpayload['date'] = payload.date;
   InwardOutwardpayload['inwardOutward'] = payload.inwardOutward;
   InwardOutwardpayload['patientName'] = payload.patientName;
   InwardOutwardpayload['patientRefId'] = payload.patientRefId;
   InwardOutwardpayload['companyRefId'] = payload.companyRefId;
   InwardOutwardpayload['companyName'] = payload.companyName;
   InwardOutwardpayload['deviceType'] = payload.deviceType;
   InwardOutwardpayload['refType'] = payload.refType;
   InwardOutwardpayload['type'] = payload.type;
   InwardOutwardpayload['notes'] = payload.notes;
   InwardOutwardpayload['branchWiseId'] = payload.branchWiseId;
   InwardOutwardpayload['branchName'] = payload.branchName;
   InwardOutwardpayload['branchRefId'] = payload.branchRefId;
   InwardOutwardpayload['clinicRefId'] = payload.clinicRefId;
   InwardOutwardpayload['repairRefId'] = this.repairRefId;
   InwardOutwardpayload['docType'] = payload.docType;
   
  
   this.apimicroservice.saveInOutwardData(payload).subscribe(res => {
     if(res.status){
     //  this.signatureImg = "../../assets/user2.png";
       this.apimicroservice.openSnackbar(res.message);
       //
       
     }else{
       this.apimicroservice.openSnackbar(res.message);
     }
   },(ERROR:HttpErrorResponse) => {
     this.apimicroservice.openSnackbar(ERROR.error.message);
    //
  
 });
 }
 estimateCalculation()
 {
  this.estimateAmount =0;
  let payload = this.repairActionForm.value;
  //
  let courierCharges=0;
  courierCharges = parseInt(payload.courierCharges);
  //
  let per = 0;
  per= this.pervalue/100;
  let value= 0;
   value= this.amountA * per + courierCharges;   
  this.estimateAmount =  value + this.amountA;
 
  this.repairActionForm.controls['amountB'].setValue(this.estimateAmount);
 }

 fetchAllCourier(){
  let clinicRef= sessionStorage.getItem('clinicRef');
  let branchRef= sessionStorage.getItem('branchRef');
  let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
  let branchRefSuperAdmin= '';
  if (this.userType !== 'Super Admin') {
  this.apimicroservice.getLastCourierAll(branchRef,clinicRef).subscribe(res => {
    // 
     if (res.status) {    
       this.courierList = res.data.data;
       this.filteredListCourier= this.courierList.slice();
     } else {
     //  this.apimicroservice.openSnackbar(res.message);
     }
   })
  }else{
    this.apimicroservice.getLastCourierAll(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
      // 
       if (res.status) {    
         this.courierList = res.data.data;
         this.filteredListCourier= this.courierList.slice();
       } else {
       //  this.apimicroservice.openSnackbar(res.message);
       }
     })
  }
 }
 fetchAllCity(){
  this.apimicroservice.getCityList().subscribe(res => {
    // 
     if (res.status) {     
       this.cityList = res.data;
       this.filteredListCity = this.cityList.slice();
     } else {
     //  this.apimicroservice.openSnackbar(res.message);
     }
   })
 } 
 fetchAllType(){
  let clinicRef= sessionStorage.getItem('clinicRef');
  let branchRef= sessionStorage.getItem('branchRef');
  let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
  let branchRefSuperAdmin= '';
  if (this.userType !== 'Super Admin') {
  this.apimicroservice.getInoutTypeList(branchRef,clinicRef).subscribe(res => {
    //  
     if (res.status) {     
       this.inoutTypeList = res.data;
       this.filteredListInoutType= this.inoutTypeList.slice();
       res.data.forEach(ele =>{
        if(ele.inoutType == 'Hearing Device'){
          this.repairActionForm.controls['typeRefId'].setValue(ele._id);
          this.type(ele.inoutType);
          this.repairActionForm.controls['deviceType'].setValue('For Repair');
        }
       })
       
     } else {
     //  this.apimicroservice.openSnackbar(res.message);
     }
   }) 
  }else{
    this.apimicroservice.getInoutTypeList(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
      //  
       if (res.status) {     
         this.inoutTypeList = res.data;
         this.filteredListInoutType= this.inoutTypeList.slice();
         res.data.forEach(ele =>{
          if(ele.inoutType == 'Hearing Device'){
            this.repairActionForm.controls['typeRefId'].setValue(ele._id);
            this.type(ele.inoutType);
            this.repairActionForm.controls['deviceType'].setValue('For Repair');
          }
         })
         
       } else {
       //  this.apimicroservice.openSnackbar(res.message);
       }
     }) 
  }
 }
 type(e)
 {
  this.repairActionForm.get("typeName").setValue(e);   
  if(e == 'Devices' || e == 'Hearing Device')
  {
    this.isDevices = true;
    this.isDocuments = false;
  }
  else if(e == 'Documents')
  {
    this.isDocuments = true;
    this.isDevices = false;
    this.repairActionForm.get("docType").setValue('Miscellaneous');   
  }
  else
  {
    this.isDevices = false;
    this.isDocuments = false;
  }
 }
  openCompanyPopUp(){
    const dialogRef3 = this.dialog.open(PopupCompanyComponent,
      {
        width: "900px",
      });

      dialogRef3.afterClosed().subscribe((result) => {
        let clinicRef= sessionStorage.getItem('clinicRef');
        let branchRef= sessionStorage.getItem('branchRef');
        this.apimicroservice.getAllCompanylist(branchRef,clinicRef).subscribe(res => {
          //
           if (res.status) {    
             this.companyList = res.data.data;
             this.filteredListCompany= this.companyList.slice();
              this.repairActionForm.get("companyRefId").setValue(res.data.data[0]._id);
           } else {
           //  this.apimicroservice.openSnackbar(res.message);
           }
         })

       });
  }
 openFromCityPopUp() {
   
  const dialogRef3 = this.dialog.open(PopUpCityComponent,
    {
      width: "500px",
    });

    dialogRef3.afterClosed().subscribe((result) => {
      this.apimicroservice.getCityList().subscribe(res => {
        //
     
       if(res.status){
       this.cityList = res.data;
        this.filteredListCity= this.cityList.slice();
        //  this.selectCity(res.data[0]._id,res.data[0].cityName)
        //  this.repairActionForm.get("selectCity").setValue(res.data[0]._id)
       }
    });
      //this.fetchFromCityList();  
     });
}
openTypePopUp() {
 
  const dialogRef3 = this.dialog.open(InwardOutwadPopupTypeComponent,
    {
      width: "500px",
    });

    // dialogRef3.afterClosed().subscribe((result) => {
    //   this.apimicroservice.getCityList().subscribe(res => {
    //     //
     
    //    if(res.status){
    //    this.cityList = res.data;
     
    //     this.filteredCityList= this.cityList.slice();

    //    }
    // });
  
    //  });
}
openCourierServicePopUp(){
  const dialogRef3 = this.dialog.open(PopUpCourierserviceComponent,
    {
      width: "1200px",
    });
 
    dialogRef3.afterClosed().subscribe((result) => {
      let clinicRef= sessionStorage.getItem('clinicRef');
      let branchRef= sessionStorage.getItem('branchRef');
      let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
      let branchRefSuperAdmin= '';
      if (this.userType !== 'Super Admin') {
      this.apimicroservice.getCourierAllData(branchRef,clinicRef).subscribe(res => {
        //  
        if (res.status) {    
          this.courierList = res.data.data;
          //
          
          this.filteredListCourier= this.courierList.slice();
          this.selectCourier(res.data.data[0].serviceProviderName);
          this.repairActionForm.get("courierRefId").setValue(res.data.data[0]._id);
        } 
      })
    }else{
      this.apimicroservice.getCourierAllData(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
        //  
        if (res.status) {    
          this.courierList = res.data.data;
          //
          
          this.filteredListCourier= this.courierList.slice();
          this.selectCourier(res.data.data[0].serviceProviderName);
          this.repairActionForm.get("courierRefId").setValue(res.data.data[0]._id);
        } 
      })
    }
     // this.fetchCourierList(); 
     });
}
selectCompany(e)
{
  //
  
  this.repairActionForm.get("companyName").setValue(e);
}
selectCourier(e)
{ 
  this.repairActionForm.get("courierName").setValue(e);
}
selectCity(e)
{ 
  this.repairActionForm.get("cityName").setValue(e);
}
getRepairPercentage(id) {
  this.apimicroservice.getRepairGenericDataById(id).subscribe(res => {
    
    let data = res.data[0];
    this.pervalue = res.data[0].repairCalPercentage;
    this.estimateCalculation();
    // if (res.data.length > 0) {
    //   this.repairSubmitButton = false; 
    //   this.repairUpdateButton = true;
    //   this.repairGenricForm = this.fb.group({
    //     "repairCalPercentage": new FormControl(data.repairCalPercentage),
    //     "loggedUserName": new FormControl(data.loggedUserName),
    //     "loggedUserRefId": new FormControl(data.loggedUserRefId),
    //     "updatedAt": new Date(),
    //   });
    //   this.repairId = data._id;
    // }

  });
}
fetchAllCompanies() {
  let clinicRef= sessionStorage.getItem('clinicRef');
  let branchRef= sessionStorage.getItem('branchRef');
  this.apimicroservice.getAllCompanylist(branchRef,clinicRef).subscribe(res => {
    
    if (res.status) {
      this.companyList = res.data.data;
      this.filteredListCompany = this.companyList.slice();
      // let lenght = res.data.totalItems-1;
      // this.repairComplaintForm.controls['companyRefId'].setValue(res.data.data[lenght]._id);
    } else {
      //  this.apimicroservice.openSnackbar(res.message);
    }
  })
}
}  
