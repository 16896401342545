<div class="container-fluid"  [formGroup]="EarMouldPrintForm">
    <form [formGroup]="EarMouldPrintForm" autocomplete="off" novalidate>
      <div class="row ">
          <div class="col-sm-12 ">
           
                <div class="col-sm-12">
                  
                  <div class="row">
                    <div class="col-sm-11" ></div>
                    <div class="col-sm-1" > 
                    
                      <div class="row no-print justify-content-end ">
                        <a (click)="printThisPage()" class="d-flex justify-content-end align-items-end ms-3">
                          <span class="material-symbols-outlined pr-2">print</span>Print</a>
                     </div>
                     </div>
                     <div class="col-sm-12">
                      <div class="row">
                        <table id="toBillingLst"
                        class="table table-hover mb-0 pb-0 table-bordered">
                                
                              
                        <tbody > 
                        
                          <tr role="row"
                           class="odd tw">
                            
                           <td class=" mt-0 pt-0 mb-0 pt-0">  
                            
                            <div class="row w2 pt-2  mt-0 pt-0 mb-0 pt-0">
                              <div class="col-sm-12  mt-0 pt-0 mb-0 pb-0"
                              >
                                <img *ngIf="!logoimg" class="img2 pt-0 mb-0 pb-0"
                                width="100%" height="80%"
                            src="../../assets/clinic_logoDemo.png" alt="Amanda Wilson" >
                            <img *ngIf="logoimg" width="80%" height="100%"
                           [src]="logoimg" alt=" photo" class="img2 pb-0 pt-0 mb-0 ">
                            </div>
                              <!-- <div class="col-sm-12  mt-0 pt-0 mb-0 pt-0"
                              style="display:block;" >
                                <img *ngIf="!logoimg" class="img2 mt-1 pt-0 mb-0 pt-0"
                                width="100%" height="100%"
                            src="../../assets/clinic_logoDemo.png" alt="Amanda Wilson" >
                            <img *ngIf="logoimg" width="100%" height="100%"
                           [src]="logoimg" alt=" photo" class="img2  mt-1 pt-0 mb-0 pt-0">
                            </div> -->
                            </div>
                          </td>
                          <td class="pr-0  mr-2 pl-0 ml-0 text-center justify-content-center pt-1 pb-0 ">
                            <div class="row w1">
                              <div class="col-sm-12  mb-0 pb-0">
                                <div class="row">
                                  <div class="col-sm-12">
                            <strong class="auto-style2 fass mb-0 pb-0 mt-0 pt-0">
                                <h1 class="fsize" *ngIf="reportName !== ''">{{reportName}} </h1>
                                <h1 class="fsize" *ngIf="reportName == ''">Clinic Name</h1>
                             </strong>
                             </div>
                             </div>
                             </div>

                              <div class="col-sm-12  mt-0 pt-0">
                                <div class="row">
                                  <div class="col-sm-12">
       
                                    <label class="col-sm-12" *ngIf="reportName !== ''">
                                      {{reportAddress}}, {{taluka}} , {{district}}, {{state}},</label>
                                      <label class="col-sm-12 mt-1" *ngIf="reportName !== ''">
                                      {{country}} - {{reportPinCode}}</label>
                                  </div>
                            </div> 
                            </div>

                            <div class="col-sm-12 mt-2 text-center justify-content-center">
                              <div class="row">
                                <div class="col-sm-12  pb-2">
                              <label *ngIf="reportName !== ''">Mobile No :
                                <b>
                                  {{reportPhoneNumber | slice:0:2}} {{reportPhoneNumber | slice:2:4}} {{reportPhoneNumber | slice:4:7}} {{reportPhoneNumber | slice:7:10}},
                                  {{reportPhoneNumber1 | slice:0:2}} {{reportPhoneNumber1 | slice:2:4}} {{reportPhoneNumber1 | slice:4:7}} {{reportPhoneNumber1 | slice:7:10}},
                                </b>
                              
                              </label>
                              </div>
                            <div class="col-sm-12 pb-2 text-center justify-content-center">
                            <label class="trans">Email Id :{{emailAdd}}</label> 
                            </div>
                            <div class="col-sm-12 pb-2 text-center justify-content-center">
                              <label class="trans">Website :
                                {{website}} </label> 
                              </div>
                          </div>
                          </div>
                          </div>
                              </td>
                               
                                <td class="w2 pb-0 text-center
                                justify-content-center">
                              
                                   <div class="row marginTopp">
                                     <!-- <div class="col-sm-12 pb-2">
                                      <strong>
                                GST Number :{{companyGst}}
                                </strong>
                                </div> -->
                                 <!-- <br> -->
                                 <div class="col-sm-12 pb-2">
                                 <strong> Weekly Off : {{reportWeeklyOff}}</strong>
                                 </div>
                                 <br>
                                 <div class="col-sm-12">
                                  <strong class="labelMin" *ngIf="openTime !== '' &&
                                  closeTime !== ''">From<br> {{openTime}}<br> To<br> {{closeTime}}</strong> 
                                  </div>
                              </div>
                               </td> 
                      
                             </tr>
                             
                      </tbody> 
                        </table>
                                        <div class="col-sm-12 mt-2 mb-0 pt-0 pb-0">
                                          <h1 class="text-center mb-1 pt-0 pb-0">Ear mould </h1></div>
                                          <table id="toBillingLst"
                                          class="table table-hover mb-0 pt-0 pb-0 table-bordered">
                                 <thead class="bcol">
                                     <th class="pt-1 bcol pb-0">
                                      <label>Name:</label>
                                      </th>
                                     <th colspan="4" class="fontSize bcol pt-1 pb-0">
                                  
                                      <label *ngIf="prifix !== '' && prifix !== null
                                      && prifix !== undefined; else elseBlock">{{prifix}}. {{firstName}} {{lastName}}</label>
                                     <ng-template #elseBlock>
                                       <label class="fontSize">{{firstName}} {{lastName}} </label>
                                     </ng-template>  
                                  </th>
                                      <th class="pt-1 bcol pb-0">
                                        <label>Date : {{currentdate | date: 'dd/MM/yyyy'}}</label>
                                        </th>
                                  </thead> 
                                  <thead  class="bcol"> 
                                   <th class="pt-1 bcol pb-0">
                                    <label>Address:</label>
                                    </th>
                                    <th colspan="4"  class="width1 bcol pt-1 pb-0">
                                      <label>
                                        {{streetAddress1}} {{taluka1}} {{district1}} {{state1}} 
                                        {{country1}} {{pincode1}}
                                        </label>
                                      </th>
                                    <th class="pt-1 bcol pb-0">
                                      <label>Time : {{currentdate | date: 'h:mm a'}}</label>
                                      </th>
                                    <!-- <th class="width1 pt-1 bcol pb-0">Date</th>
                                    <th class="width1 pt-1 bcol pb-0">{{invoiceDate | date: 'dd/MM/yyyy'}}</th> -->
                                </thead>
                              
                              </table>
                                   
                                          <table id="toBillingLst"
                                          class="table table-hover table-bordered">
                                            <thead>
                                              <tr class="text-center">
                                                  <th>Hearing Aid Company Name</th>
                                                  <th>CAN Number</th>
                                                  <th>Make</th>
                                              </tr>
                                          </thead> 
                                          <tbody > 
                                            <tr 
                                            class="text-center">
                                             <td class="pt-1 pb-0">{{companyName}}</td>     
                                              <td class="width1 pt-1 pb-0">{{companyCanNumber}}</td>
                                              <td class="width1 pt-1 pb-0">{{companyMake}}</td>
                                               </tr>     
                                        </tbody>
                                          </table>
                                          <table id="toBillingLst"
                                          class="table table-hover table-bordered">
                                            <thead>
                                              <tr class="text-center">
                                                  <th colspan="2" class=" pt-1 pb-1">Right</th>
                                                  <th colspan="2" class=" pt-1 pb-1">Left</th>
                                             </tr>
                                          </thead> 
                                          <tbody >
                                            <tr
                                            class="text-left">
                                             <td class="pt-1 pb-0 boldd">Hardness</td>     
                                           <td class="width1 pt-1 pb-0">{{rightHardness}}</td>
                                           <td class="width1 pt-1 pb-0 boldd">Hardness</td>
                                           <td class="width1 pt-1 pb-0">{{leftHardness}}</td>
                                               </tr>   
                                               <tr
                                               class="text-left">
                                                <td class="pt-1 pb-0 boldd">Style </td>     
                                              <td class="width1 pt-1 pb-0">{{rightStyle}}</td>
                                              <td class="width1 pt-1 pb-0 boldd">Style </td>
                                              <td class="width1 pt-1 pb-0">{{leftStyle}}</td>
                                                  </tr> 
                                                  <tr
                                                  class="text-left">
                                                   <td class="pt-1 pb-0 boldd">HA Type</td>     
                                                 <td class="width1 pt-1 pb-0">{{rightHAType}}</td>
                                                 <td class="width1 pt-1 pb-0 boldd">HA Type</td>
                                                 <td class="width1 pt-1 pb-0">{{leftHAType}}</td>
                                                     </tr>
                                                     <tr
                                                     class="text-left">
                                                      <td class="pt-1 pb-0 boldd">Vent </td>     
                                                    <td class="width1 pt-1 pb-0">{{rightVent}}</td>
                                                    <td class="width1 pt-1 pb-0 boldd">Vent </td>
                                                    <td class="width1 pt-1 pb-0">{{leftVent}}</td>
                                                        </tr>
                                                        <tr
                                                        class="text-left">
                                                         <td class="pt-1 pb-0 boldd">Canal Length </td>     
                                                       <td class="width1 pt-1 pb-0">{{rightCanalLenght}}</td>
                                                       <td class="width1 pt-1 pb-0 boldd">Canal Length </td>
                                                       <td class="width1 pt-1 pb-0">{{leftCanalLenght}}</td>
                                                           </tr>
                                                           <tr
                                                           class="text-left">
                                                            <td class="pt-1 pb-0 boldd">Removal String</td>     
                                                          <td class="width1 pt-1 pb-0">{{rightRemovalString}}</td>
                                                          <td class="width1 pt-1 pb-0 boldd">Removal String</td>
                                                          <td class="width1 pt-1 pb-0">{{leftRemovalString}}</td>
                                                              </tr>     
                                                           
                                        </tbody>
                                      </table>
                                       
                                       
                                          
                                            <div class="col-sm-2 mt-5">
                                              <br><br><br>  <br><br><br>
                                              <!-- <br><br><br>  <br><br><br>
                                              <br><br><br>  <br><br><br>
                                              <br><br><br>  <br><br><br>
                                              <br><br><br>  <br><br><br>
                                              <br><br><br>  <br><br><br>
                                              <br><br><br>  <br><br><br>
                                              <br><br><br>  <br><br><br>
                                              <br><br><br>  <br><br><br>
                                              <br><br><br>  <br><br><br> -->
                                                <h2 hidden class="mt-5">Clients Signature</h2>
                                            </div>
                                            <div class="col-sm-8"></div>
                                            <div class="col-sm-2 mt-5">
                                              <br><br><br>  <br><br><br>
                                              <!-- <br><br><br>  <br><br><br>
                                              <br><br><br>  <br><br><br>
                                              <br><br><br>  <br><br><br>
                                              <br><br><br>  <br><br><br>
                                              <br><br><br>  <br><br><br>
                                              <br><br><br>  <br><br><br>
                                              <br><br><br>  <br><br><br>
                                              <br><br><br>  <br><br><br>
                                              <br><br><br>  <br><br><br> -->
                                                <h2 class="mt-5">Signature</h2>
                                            </div>
                                            
                                          </div>
                                       </div>
                      </div>
  
                  </div>
              
  
          </div>
      </div>
      </form>
  </div>