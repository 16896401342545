import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from "../api-service.service";
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import {Router,ActivatedRoute} from '@angular/router';
import { PopupSerialNoComponent } from '../popup-serial-no/popup-serial-no.component';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { PopUpBranchComponent } from '../pop-up-branch/pop-up-branch.component';
import { PopUpVendorComponent } from '../pop-up-vendor/pop-up-vendor.component';
import { HttpErrorResponse } from '@angular/common/http';
import { PopUpDeviceComponent } from '../pop-up-device/pop-up-device.component';
import { PopupBillingcompanyComponent } from '../popup-billingcompany/popup-billingcompany.component';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { AnyComponent } from 'preact';
import { Observable, Subscriber } from 'rxjs';
import { PopupCompanyComponent } from "../popup-company/popup-company.component";
import { PdfviewerComponent } from '../pdfviewer/pdfviewer.component';
import { PopupStockInHandComponent } from '../popup-stock-in-hand/popup-stock-in-hand.component';
@Component({
  selector: 'app-goods-in-word',
  templateUrl: './goods-in-word.component.html',
  styleUrls: ['./goods-in-word.component.scss']
})
export class GoodsInWordComponent implements OnInit {
  size:number = 10;
  page:number = 1;
  listOfBatch: any;
  totalLength: any;
  updateButton = false;
  submitButton = true;
  cancelButton=false;
  goodInWordForm: FormGroup;
  userPermission: any;
  userType: any;
  permissionList=[];
  loggedId: any;
  dispatchDeviceCreate= true;
  dispatchDeviceUpdate: boolean = false;
  _id: any;
  purchaseList = [];
  searchText: any;
  vendorList: any;
  branchList: any;
  amountVal: number;
  totalAmount: number = 0;
  totalDiscount : number = 0;
  totalFinalAmount = 0;
 // deviceList: any[]=[];
  deviceList = [];
  purchaseInvoiceCreate= true;
  purchaseInvoiceUpdate= true;
  branchCreate = true;
  vendorCreate = true;
  billingCreate = true;
  companyCreate = true;
  branchRef: any;
  clinicRef: any;
  disableBranch = false;
  prepopulationData: any;
  companyList = [];
  filteredListCompany :any;
  filteredListVendor: any;
  filteredListBranch: any;
  filteredListDevice = [];
  currentdate:any;
  duedate:any;
  minDate: Date;
  maxDate: Date;
  amountRate: number;
  branchName: string;
  taxValue : number = 0;
  finalAount: void;
  finalAmt : number = 0;
  state: string;
  totalGst = 0;
  branchRefId: any;
  filteredDeviceList: any[];
  billingCompanyList: any;
  filteredListBillingCompany: any;
  sgstValue = 0;
  cgstValue = 0;
  igstValue = 0;
  allDeviceArr: any = [];
  oldAmount = 0;
  checkNo: string;
  isSerial: any;
  poList: any;
  filteredListPoNo: any;
  errormessage: string;
  filedata: any;
  constructor( public apimicroservice: ApiServiceService,
    private fb: FormBuilder,
    public router: Router,private route: ActivatedRoute,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService) { }
    
  ngOnInit(): void {
    this.totalGst = 0;
    this.oldAmount = 0;
    this.sgstValue = 0;
    this.cgstValue = 0;
    this.igstValue = 0;
    this.totalFinalAmount = 0;
    this.currentdate = new Date();  
    this.duedate = new Date();
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 9, 0, 1);
    this.maxDate = new Date(currentYear + 9, 11, 31);
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    this.userType = sessionStorage.getItem('userType');
    this.branchName = sessionStorage.getItem('branchName');
    if (this.userType !== 'Super Admin') { 
    this.userPermission.forEach((element) => {
      if(element.pageName == 'Purchase Invoice' && element.create == false)
      {  
          this.purchaseInvoiceCreate = false;  
      }
      if(element.pageName == 'Purchase Invoice' && element.update == false)
      {
          this.purchaseInvoiceUpdate = false;  
      } 
      if(element.pageName == 'Branch' && element.create == false)
      {
          this.branchCreate = false;  
      }
      if(element.pageName == 'Vendor' && element.create == false)
      {
          this.vendorCreate = false;  
      }  
      if(element.pageName == 'Company Master' && element.create == false)
      {
          this.companyCreate = false;  
      }
      if(element.pageName == 'Billing Company' && element.create == false)
      {
          this.billingCreate = false;  
      }
    });
  }
    let i = 0;
    this.branchRef = sessionStorage.getItem('branchRef');
    this.userType = sessionStorage.getItem('userType');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.totalAmount = 0;
    this.totalDiscount = 0;
    this.goodInWordForm = this.fb.group({
      "grnNo": new FormControl(''), 
      "vendorRefId": new FormControl(),
      "vendorName":new FormControl(''),
      "haCompanyRefId": new FormControl('',[Validators.required]),
      "haCompanyName":new FormControl(''),
      "branchRefId": new FormControl('',[Validators.required]), 
      "branchName":new FormControl(''),
      "invoiceNo": new FormControl('',[Validators.required,Validators.pattern("^[ A-Za-z0-9_@./#&+-]*$"),Validators.maxLength(16),Validators.minLength(4)]), 
      "invoiceDate": new FormControl('',[Validators.required]), 
      "methodOfShifting": new FormControl('',[Validators.pattern("^[a-zA-Z ]*$")]), 
      "paymentTerms": new FormControl(''), 
      "dueDate": new FormControl(''), 
      "paymentOption": new FormControl(''), 
      "poNumber": new FormControl('',[Validators.pattern("^[a-zA-Z0-9 -]+$"),Validators.maxLength(15),Validators.minLength(4)]), 
      "soNumber": new FormControl('',[Validators.pattern("^[a-zA-Z0-9_./#-]+$"),Validators.maxLength(18),Validators.minLength(4)]), 
      "orderDate": new FormControl(''),
      "totalAmount": new FormControl(this.totalAmount),
      "totalDiscount" : new FormControl(this.totalDiscount),
     // "transporterName": new FormControl('',[Validators.required]),   
      //"grnDate": new FormControl('',[Validators.required]),   
     // "poDate": new FormControl('',[Validators.required]), 
      "device": this.fb.array([]),
      "searchGoods": new FormControl(''), 
      "totalTax": new FormControl(''), 
      "billingCompanyRefId" :new FormControl('',Validators.required),
      "billingCompanyName":new FormControl(''),
      "totalInvoiceAmount": new FormControl(''), 
      "fileData": new FormControl(''),
      "fileName": new FormControl(''),
    });
    this.goodInWordForm.controls['invoiceDate'].setValue(this.currentdate);
    this.goodInWordForm.controls['dueDate'].setValue(this.duedate);
    this.goodInWordForm.controls['orderDate'].setValue(this.currentdate);
    this.goodInWordForm.controls['paymentOption'].setValue('Credit');
    this.goodInWordForm.get('branchRefId').enable();
    this.addNew(i);
    this.getAllPO();
    this.getLastId();
    this.fetchVendorList();
    this.fetchHACompanyList();
    this.fetchBranchList();
    this.fetchPurchaseList();
    this.fetchDeviceList();
    this.fetchBillingCompanyList();
    this.getAllBranch();
  }
  // fetchBillingCompanyList(){
  //   let id='';
  //   this.apimicroservice.getBillingDataById(id).subscribe(res => {
  //     //
  //     this.billingCompanyList = res.data;
  //     this.filteredListBillingCompany= this.billingCompanyList.slice();
  //   });
  // }
  fetchBillingCompanyList(){
    let id='';
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getBillingDataById(id,clinicRef).subscribe(res => {
      //
      this.billingCompanyList = res.data;
      this.filteredListBillingCompany= this.billingCompanyList.slice();
      let lenght = res.data.length-1;  
      this.companyClick(res.data[lenght]._id,res.data[lenght].companyName);
      this.goodInWordForm.get("billingCompanyRefId").setValue(res.data[lenght]._id);
      if(res.status){
        //this.apimicroservice.openSnackbar(res.message);
      }else{ 
        //this.apimicroservice.openSnackbar(res.message);
      }
    });
  }
  companyClick(bid,bcname){
    this.goodInWordForm.controls['billingCompanyName'].setValue(bcname);
  }
  getAllBranch()
  {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      if (res.status) {        
        let length = res.data.length - 1;
        this.branchName =res.data[length].branchName;
        this.goodInWordForm.get("branchRefId").setValue(res.data[length]._id);
        this.onSelectBranch(res.data[length]._id,this.branchName);
        if(this.userType == 'Super Admin')
        {  
          this.apimicroservice.getBranchById(res.data[length]._id).subscribe(res => {
            this.spinner.hide();
            this.goodInWordForm.get("branchName").setValue(res.data[length].branchName);
           });
        }
       
      }
    });
  }
  getAllPO()
  {
    this.apimicroservice.getAllPOList().subscribe(res => {
      if (res.status) { 
        this.poList = res.data.data;
        this.filteredListPoNo= this.poList.slice();       
        // let length = res.data.length - 1;
        // this.branchName =res.data[length].branchName;
        // this.goodInWordForm.get("branchRefId").setValue(res.data[length]._id);
        // this.onSelectBranch(res.data[length]._id,this.branchName);
      
      }
    });
  }
  // removeValidator() { 
  //   const creds = this.goodInWordForm.controls.device as FormArray;

  //   this.goodInWordForm.controls['vendorRefId'].setErrors(null);
  //   this.goodInWordForm.controls['vendorRefId'].clearValidators();
  //   this.goodInWordForm.controls['vendorRefId'].updateValueAndValidity();

  //   this.goodInWordForm.controls['branchRefId'].setErrors(null);
  //   this.goodInWordForm.controls['branchRefId'].clearValidators();
  //   this.goodInWordForm.controls['branchRefId'].updateValueAndValidity();

  //   this.goodInWordForm.controls['invoiceNo'].setErrors(null);
  //   this.goodInWordForm.controls['invoiceNo'].clearValidators();
  //   this.goodInWordForm.controls['invoiceNo'].updateValueAndValidity();

  //   this.goodInWordForm.controls['invoiceDate'].setErrors(null);
  //   this.goodInWordForm.controls['invoiceDate'].clearValidators();
  //   this.goodInWordForm.controls['invoiceDate'].updateValueAndValidity();

  //   this.goodInWordForm.controls['methodOfShifting'].setErrors(null);
  //   this.goodInWordForm.controls['methodOfShifting'].clearValidators();
  //   this.goodInWordForm.controls['methodOfShifting'].updateValueAndValidity();

  //   this.goodInWordForm.controls['poNumber'].setErrors(null);
  //   this.goodInWordForm.controls['poNumber'].clearValidators();
  //   this.goodInWordForm.controls['poNumber'].updateValueAndValidity();

  //   this.goodInWordForm.controls['soNumber'].setErrors(null);
  //   this.goodInWordForm.controls['soNumber'].clearValidators();
  //   this.goodInWordForm.controls['soNumber'].updateValueAndValidity();

  //   creds.controls.forEach(element =>{
  //     element.get('deviceRefId').setErrors(null);
  //     element.get('deviceRefId').clearValidators();
  //     element.get('deviceRefId').updateValueAndValidity();

  //     element.get('serialNumber').setErrors(null);
  //     element.get('serialNumber').clearValidators();
  //     element.get('serialNumber').updateValueAndValidity();

  //     element.get('qty').setErrors(null);
  //     element.get('qty').clearValidators();
  //     element.get('qty').updateValueAndValidity();

  //     element.get('unitPrice').setErrors(null);
  //     element.get('unitPrice').clearValidators();
  //     element.get('unitPrice').updateValueAndValidity();

  //     element.get('amount').setErrors(null);
  //     element.get('amount').clearValidators();
  //     element.get('amount').updateValueAndValidity();
  //   });

  // }
 
  // setValidator() { 
  //   const creds = this.goodInWordForm.controls.device as FormArray;
 
  //   this.goodInWordForm.controls['vendorRefId'].setValidators(Validators.required);
  //   this.goodInWordForm.controls['vendorRefId'].updateValueAndValidity();

  //   this.goodInWordForm.controls['branchRefId'].setValidators([Validators.required]);
  //   this.goodInWordForm.controls['branchRefId'].updateValueAndValidity();

  //   this.goodInWordForm.controls['invoiceNo'].setValidators([Validators.required,Validators.pattern("^[a-zA-Z0-9 -]+$"),Validators.maxLength(10),Validators.minLength(4)]);
  //   this.goodInWordForm.controls['invoiceNo'].updateValueAndValidity();

  //   this.goodInWordForm.controls['invoiceDate'].setValidators(Validators.required);
  //   this.goodInWordForm.controls['invoiceDate'].updateValueAndValidity();

  //   this.goodInWordForm.controls['methodOfShifting'].setValidators([Validators.pattern("^[a-zA-Z ]*$")]);
  //   this.goodInWordForm.controls['methodOfShifting'].updateValueAndValidity();

  //   this.goodInWordForm.controls['poNumber'].setValidators([Validators.pattern("^[a-zA-Z0-9 -]+$"),Validators.maxLength(10),Validators.minLength(4)]);
  //   this.goodInWordForm.controls['poNumber'].updateValueAndValidity();

  //   this.goodInWordForm.controls['soNumber'].setValidators([Validators.pattern("^[a-zA-Z0-9 -]+$"),Validators.maxLength(10),Validators.minLength(4)]);
  //   this.goodInWordForm.controls['soNumber'].updateValueAndValidity();

  //   creds.controls.forEach(element =>{
  //     element.get('deviceRefId').setValidators([Validators.required]);
  //     element.get('deviceRefId').updateValueAndValidity();
  //     element.get('serialNumber').setValidators([Validators.required]);
  //     element.get('serialNumber').updateValueAndValidity();
  //     element.get('qty').setValidators([Validators.required]);
  //     element.get('qty').updateValueAndValidity();
  //     element.get('unitPrice').setValidators([Validators.required,Validators.pattern("^[0-9]*$"),Validators.minLength(2),Validators.maxLength(7)]);
  //     element.get('unitPrice').updateValueAndValidity();
  //     element.get('amount').setValidators([Validators.required]);
  //     element.get('amount').updateValueAndValidity();
  //   });

  // }

  submitPurchaseList() {
    //this.setValidator();
    //
    this.goodInWordForm.get('billingCompanyRefId').markAsTouched();
    const creds = this.goodInWordForm.controls.device as FormArray;     
    if(this.goodInWordForm.invalid || creds.invalid) {
  
     // this.goodInWordForm.get('grnNo').markAsTouched();

      this.goodInWordForm.get('haCompanyRefId').markAsTouched();
      this.goodInWordForm.get('branchRefId').markAsTouched();
      this.goodInWordForm.get('invoiceNo').markAsTouched();
      this.goodInWordForm.get('invoiceDate').markAsTouched();
     // this.goodInWordForm.get('methodOfShifting').markAsTouched();
      //this.goodInWordForm.get('paymentTerms').markAsTouched();
     // this.goodInWordForm.get('dueDate').markAsTouched();
     // this.goodInWordForm.get('paymentOption').markAsTouched();
     // this.goodInWordForm.get('poNumber').markAsTouched();
     // this.goodInWordForm.get('soNumber').markAsTouched();
     // this.goodInWordForm.get('orderDate').markAsTouched();

      creds.controls.forEach(element =>{ 
            element.get('deviceRefId').markAsTouched();
            element.get('serialNumber').markAsTouched();
            // element.get('poNumber').markAsTouched();
            // element.get('soNumber').markAsTouched();
            // element.get('orderDate').markAsTouched();
          //  element.get('qty').markAsTouched();
            // element.get('unitOfMeasurement').markAsTouched();
            element.get('unitPrice').markAsTouched();
            // element.get('discount').markAsTouched();
           // element.get('amount').markAsTouched();
            //  element.get('rate').markAsTouched();
          }); 
    }
    else{
      this.addPurchaseList();
     }
    // else if(creds.invalid)
    // {
    //   creds.controls.forEach(element =>{
    //     element.get('deviceRefId').markAsTouched();
    //     element.get('serialNumber').markAsTouched();
    //     element.get('poNumber').markAsTouched();
    //     element.get('soNumber').markAsTouched();
    //     element.get('orderDate').markAsTouched();
    //     element.get('qty').markAsTouched();
    //     element.get('unitOfMeasurement').markAsTouched();
    //     element.get('unitPrice').markAsTouched();
    //     // element.get('discount').markAsTouched();
    //     element.get('amount').markAsTouched();
    //     //  element.get('rate').markAsTouched();
    //   });
    // }
    
}

updatePurchaseList() {
  //this.setValidator();
  // let control = (<FormArray>this.goodInWordForm.get("device")).at(i);
  // const tempData= this.goodInWordForm.controls.device.value;
  const creds = this.goodInWordForm.controls.device as FormArray;     
//   if(isSerialNo == true) 
//   { 
//     creds.get('serialNumber').setValidators(Validators.required);
//     creds.get('serialNumber').updateValueAndValidity();
//   }
//   else
//   {
//     creds.get('serialNumber').setErrors(null);
//     creds.get('serialNumber').clearValidators();
//     creds.get('serialNumber').updateValueAndValidity();
//   //   creds.patchValue({
//   //     qty: 1,
//   // });
// }
  if(this.goodInWordForm.invalid || creds.invalid) {
//
//
   // this.goodInWordForm.get('grnNo').markAsTouched();
   this.goodInWordForm.get('billingCompanyRefId').markAsTouched();
    this.goodInWordForm.get('haCompanyRefId').markAsTouched();
   // this.goodInWordForm.get('branchRefId').markAsTouched();
    this.goodInWordForm.get('invoiceNo').markAsTouched();
    this.goodInWordForm.get('invoiceDate').markAsTouched();
    //this.goodInWordForm.get('methodOfShifting').markAsTouched();
    //this.goodInWordForm.get('paymentTerms').markAsTouched();
    //this.goodInWordForm.get('dueDate').markAsTouched();
   // this.goodInWordForm.get('paymentOption').markAsTouched();
    //this.goodInWordForm.get('poNumber').markAsTouched();
   // this.goodInWordForm.get('soNumber').markAsTouched();
   // this.goodInWordForm.get('orderDate').markAsTouched();

    creds.controls.forEach(element =>{
          element.get('deviceRefId').markAsTouched();
          element.get('serialNumber').markAsTouched();
          // element.get('poNumber').markAsTouched();
          // element.get('soNumber').markAsTouched();
          // element.get('orderDate').markAsTouched();
          //element.get('qty').markAsTouched();
          // element.get('unitOfMeasurement').markAsTouched();
          element.get('unitPrice').markAsTouched();
          // element.get('discount').markAsTouched();
         // element.get('amount').markAsTouched();
          //  element.get('rate').markAsTouched();
        }); 
  }
  else{
    //
    this.updatePurchase();
   }
  
  
}
updatePurchase() {
  let payload = this.goodInWordForm.value;
  payload.fileData = this.filedata;
  payload.fileName = this.fileName;
  // payload.clinicRefId = this.clinicRef;
  // payload.totalAmount = this.totalAmount;
  // payload.totalDiscount = this.totalDiscount;

 // payload.clinicRefId = this.clinicRef;
  payload.totalAmount = this.finalAmt;
  payload.totalDiscount = this.totalDiscount;
  payload.totalTax = this.taxValue;
  payload.totalInvoiceAmount = this.totalAmount;


  let stockMaintainPayload = {};
  let stockMaintainPayloadUpdated = {};
  //
  //
 
  let oldsrnoarr = [];
  let newsenoarr = [];
  let allsrnolist = [];

  this.prepopulationData.device.forEach(element =>{
    element.serialNumber.forEach(item =>{
     // oldsrnoarr.push(item);
     oldsrnoarr.push({"serialNumber":item,"deviceRefId":element.deviceRefId,"deviceName":element.deviceName,"unitPrice":element.unitPrice,"sgst":element.sgst,"cgst":element.cgst,"igst":element.igst});
    })
    
  });
  //
  // payload.device.forEach(element =>{
  //   element.serialNumber.forEach(item =>{
  //    // newsenoarr.push(item);
  //    allsrnolist.push({"serialNumber":item,"status":"new","deviceRefId":element.deviceRefId,"deviceName":element.deviceName,"unitPrice":element.unitPrice,"sgst":element.sgst,"cgst":element.cgst,"igst":element.igst});
  //   })
     
  // });
  ////////////////////////////////////hidede
  // payload.device.forEach(element =>{
  //   element.serialNumber.forEach(item =>{
  //     newsenoarr.push({"serialNumber":item,"deviceRefId":element.deviceRefId,"deviceName":element.deviceName,"unitPrice":element.unitPrice,"sgst":element.sgst,"cgst":element.cgst,"igst":element.igst});
  //   })
    
  // });
  payload.device.forEach(element =>{
    // 
    if(element.serialNumber == '')
    { 
      let randomNo = Math.floor(10000 + Math.random() * 90000);
      //
      let ranNum = "No SerialNo-"+randomNo;
      element.serialNumber=ranNum;
      element.serialNumberStatus =[{"serialNo":ranNum,"status":'unsold'}];
    }
  })
  //
  let uniqueArr = [];
   uniqueArr = newsenoarr.filter(el => {
    return !oldsrnoarr.find(element =>{
      return element.serialNumber === el.serialNumber;
    })
   });
//  let missing = oldsrnoarr.filter(item =>newsenoarr.indexOf(item) < 0);
 
//   //
//   if(missing.length > 0){
//     missing.forEach(element =>{
//       allsrnolist.push({"serialNumber":element,"status":"deleted"});
//     });
//   }

  // newsenoarr.forEach(element =>{
  //   allsrnolist.push({"serialNumber":element,"status":"new"});
  // });
  //

  uniqueArr.forEach(ele =>{
    allsrnolist.push(ele);
  });
  //
  this.spinner.show();
  this.apimicroservice.updateGoodsInWord(this._id,payload).subscribe(res => {
    //
    this.ngOnInit();
    this.spinner.hide();
    if(res.status){
      this.apimicroservice.openSnackbar(res.message);
      this.filedata= '';
      this.fileName= '';
      this.hide = false;
      this.submitButton=true;
      this.updateButton = false;
      this.cancelButton=false;
  

      let purchaseId = this._id;
      if(allsrnolist.length > 0){
      let bar = new Promise<void>((resolve,reject) =>{
        allsrnolist.forEach(element =>{
          stockMaintainPayload = {};
          //  if(element.status == "new"){
            stockMaintainPayload['serialNumber'] = element.serialNumber;
            stockMaintainPayload['branchRefId'] = this.branchRefId;
            //stockMaintainPayload['vendorRefId'] = payload.vendorRefId;
            //stockMaintainPayload['vendorName'] = payload.vendorName;
            stockMaintainPayload['haCompanyRefId'] = payload.haCompanyRefId;
            stockMaintainPayload['haCompanyName'] = payload.haCompanyName;
            stockMaintainPayload['invoiceNo'] = payload.invoiceNo;
            stockMaintainPayload['invoiceDate'] = payload.invoiceDate;
            stockMaintainPayload['deviceRefId'] = element.deviceRefId;
            stockMaintainPayload['deviceName'] = element.deviceName;
            stockMaintainPayload['qty'] = 1;
            stockMaintainPayload['unitPrice'] = element.unitPrice;
            stockMaintainPayload['purchaseRefId'] = this._id;
            stockMaintainPayload['status'] = "unsold";
            stockMaintainPayload['cgst'] = element.cgst;
            stockMaintainPayload['sgst'] = element.sgst;
            stockMaintainPayload['igst'] = element.igst;
            stockMaintainPayload['hasSac'] = element.has;
            this.apimicroservice.addStockMaintain(stockMaintainPayload).subscribe(res => {
              //
              resolve();
            });
          // }
           
        })
      });
      bar.then(() => {
        this.ngOnInit();
         
      });
    }else{
      this.ngOnInit();
    }
      //this.ngOnInit();
    }else{
      this.apimicroservice.openSnackbar(res.message);
    }
  },(ERROR:HttpErrorResponse) => {
    this.apimicroservice.openSnackbar("Serial Number Already Exist");
    this.spinner.hide();
});
}

addPurchaseList(){
  let payload = this.goodInWordForm.value;
  payload.fileData = this.filedata;
  payload.fileName = this.fileName;
  payload.clinicRefId = this.clinicRef;
  payload.totalAmount = this.finalAmt;
  payload.totalDiscount = this.totalDiscount;
  payload.totalTax = this.taxValue;
  payload.totalInvoiceAmount = this.totalAmount; 
   const tempData= this.goodInWordForm.controls.device.value;
  // let tempArr = [];
  payload.device.forEach(element =>{
    // 
    if(element.serialNumber == '')
    { 
      let randomNo = Math.floor(10000 + Math.random() * 90000);
      //
      let ranNum = "No SerialNo-"+randomNo;
      element.serialNumber.push(ranNum);
      element.serialNumberStatus =[{"serialNo":ranNum,"status":'unsold'}];
    }
    // if(element.serialNumberStatus == '') 
    // {
    //   element.serialNumberStatus = [{"serialNo":'',"status":''}];
    // }
  })
  // let stockpayload = {
  //   branchRefId : payload.branchRefId,
  //   device : tempArr,
  // };
  this.spinner.show();

  this.apimicroservice.addGoodsInWord(payload).subscribe(res => {
    this.filedata= '';
    this.fileName= '';
    this.hide = false;
    this.spinner.hide();
    if(res.status){
      this.apimicroservice.openSnackbar(res.message);
      // stockpayload["purchaseRefId"] = res.data._id;
      // this.apimicroservice.addStockMaintain(stockpayload).subscribe(res => {
      //   //
      // });
      this.ngOnInit();
    }else{
      this.apimicroservice.openSnackbar(res.message);
    }
  },(ERROR:HttpErrorResponse) => {
    this.apimicroservice.openSnackbar("Serial Number Already Exist");
    this.spinner.hide();
}); 
  

}

fetchPurchaseList() {
  let search='';
  let clinicRef= sessionStorage.getItem('clinicRef');
  let branchRef= sessionStorage.getItem('branchRef');
  let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
  let branchRefSuperAdmin= '';
  this.spinner.show();
  if(this.userType !== 'Super Admin'){
    this.apimicroservice.getGoodsInWordData(this.page,this.size,search,branchRef,clinicRef).subscribe(res => {
      //
      this.spinner.hide();
     this.purchaseList = res.data.purchaseData;
     this.totalLength=res.data.totalItems;
     if(res.status){
       //this.apimicroservice.openSnackbar(res.message);
       
     }else{
       //this.apimicroservice.openSnackbar(res.message);
     }
 });
  }else{
    this.apimicroservice.getGoodsInWordData(this.page,this.size,search,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
      //
      this.spinner.hide();
     this.purchaseList = res.data.purchaseData;
     this.totalLength=res.data.totalItems;
     if(res.status){
      // this.apimicroservice.openSnackbar(res.message);
       
     }else{
       //this.apimicroservice.openSnackbar(res.message);
     }
 });
  }
  
}

fetchHACompanyList(){
  let clinicRef= sessionStorage.getItem('clinicRef');
  let branchRef= sessionStorage.getItem('branchRef');
  let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
  let branchRefSuperAdmin= '';
  if (this.userType !== 'Super Admin') {
  this.apimicroservice.getAllHACompanylist(branchRef,clinicRef).subscribe(res => {
    //
     if (res.status) {    
       this.companyList = res.data.data;
       this.filteredListCompany= this.companyList.slice();
       //this.onSelectCompany(this.companyList[0]._id,this.companyList[0].companyName,this.companyList[0].state);
       let lenght = res.data.totalItems-1;
       this.goodInWordForm.get("haCompanyRefId").setValue(res.data.data[lenght]._id);
       this.goodInWordForm.get("haCompanyName").setValue(res.data.data[lenght].companyName);
       this.onSelectCompany(res.data.data[lenght]._id,res.data.data[lenght].companyName,res.data.data[lenght].state);
     } else {
     //  this.apimicroservice.openSnackbar(res.message);
     }
   })
  }else{
    this.apimicroservice.getAllHACompanylist(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
      //
       if (res.status) {    
         this.companyList = res.data.data;
         this.filteredListCompany= this.companyList.slice();
         //this.onSelectCompany(this.companyList[0]._id,this.companyList[0].companyName,this.companyList[0].state);
         let lenght = res.data.totalItems-1;
         this.goodInWordForm.get("haCompanyRefId").setValue(res.data.data[lenght]._id);
         this.goodInWordForm.get("haCompanyName").setValue(res.data.data[lenght].companyName);
         this.onSelectCompany(res.data.data[lenght]._id,res.data.data[lenght].companyName,res.data.data[lenght].state);
       } else {
       //  this.apimicroservice.openSnackbar(res.message);
       }
     })
  }
 
}

fetchVendorList(){
  // if(this.userType !== 'Super Admin'){
  //   this.apimicroservice.getVendorByBranchId(this.branchRef,this.clinicRef).subscribe(res => {
  //     //
     
  //    this.vendorList = res.data;
  //    this.filteredListVendor= this.vendorList.slice();
     
  //    if(res.status){
  //      //this.apimicroservice.openSnackbar(res.message);
       
  //    }else{
  //      //this.apimicroservice.openSnackbar(res.message);
  //    }
  // });
  // }else{
    let id = '';
    this.apimicroservice.getVendorById(id).subscribe(res => {
      //
     
     this.vendorList = res.data;
     this.filteredListVendor= this.vendorList.slice();
     if(res.status){
       //this.apimicroservice.openSnackbar(res.message);
       
     }else{
       //this.apimicroservice.openSnackbar(res.message);
     }
  });
  //}
  
}
fetchBranchList(){
  let branchRef= sessionStorage.getItem('branchRef');
  this.apimicroservice.getBranchById(branchRef).subscribe(res => {
    //
   
   this.branchList = res.data;
   this.filteredListBranch= this.branchList.slice();
   if(this.userType !== 'Super Admin'){
    this.goodInWordForm.controls['branchRefId'].setValue(this.branchRef);
    this.disableBranch = true;
   }else{
    this.disableBranch = false;
   }
   
   if(res.status){
     //this.apimicroservice.openSnackbar(res.message);
     
   }else{
     //this.apimicroservice.openSnackbar(res.message);
   }
});
}

fetchDeviceList(){
  let i = 0;
  // if(this.userType !== 'Super Admin'){
  //   this.apimicroservice.getDeviceByBranchId(this.branchRef,this.clinicRef).subscribe(res => {
  //     //
     
  //   //  this.deviceList[i] = res.data;
  //   //  this.filteredListDevice[i]= this.deviceList[i].slice();
  //   this.deviceList = res.data;
  //   this.filteredListDevice= this.deviceList.slice();
  //    if(res.status){
  //     // this.apimicroservice.openSnackbar(res.message);
       
  //    }else{
  //      //this.apimicroservice.openSnackbar(res.message);
  //    }
  // });
  // }else{
    let id = '';
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
    this.apimicroservice.getDeviceById(id,branchRef,clinicRef).subscribe(res => {
      //
     
     this.deviceList = res.data;
     this.filteredListDevice = this.deviceList.slice();
     if(res.status){
       //this.apimicroservice.openSnackbar(res.message);
       
     }else{
       //this.apimicroservice.openSnackbar(res.message);
     }
  });
}else{
  this.apimicroservice.getDeviceById(id,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
    //
   
   this.deviceList = res.data;
   this.filteredListDevice = this.deviceList.slice();
   if(res.status){
     //this.apimicroservice.openSnackbar(res.message);
     
   }else{
     //this.apimicroservice.openSnackbar(res.message);
   }
});
}
  //}
  
}

// changeAmount(event : any,i){
// //
// let rateVal = event.target.value;
// let deviceInfo = (<FormArray>this.goodInWordForm.get("device")).at(i);

// let qtyVal = (<FormArray>this.goodInWordForm.get("device")).at(i).get('qty').value;
// //
// qtyVal = parseInt(qtyVal);
// rateVal = parseInt(rateVal);
// this.amountVal = qtyVal * rateVal;

//   deviceInfo.patchValue({
//       amount: this.amountVal
//   });
//   const tempData= this.goodInWordForm.controls.device.value;
//   //

//   tempData.forEach(element =>{
//     this.totalAmount =element.amount;
//     //
//   })
//   this.changeTax(event,i);
// }

// changeDiscount(event:any,i){
//   //
//   let dis = event.target.value;
//   let deviceInfo = (<FormArray>this.goodInWordForm.get("device")).at(i);
//   if(dis == ''){
//     deviceInfo.patchValue({
//       amount: this.amountVal
//   });
//   let qtyVal = (<FormArray>this.goodInWordForm.get("device")).at(i).get('qty').value;
// //
// qtyVal = parseInt(qtyVal);
//   const tempData= this.goodInWordForm.controls.device.value;
//   //
//   this.totalDiscount = 0;
  
//   tempData.forEach(element =>{
//     this.totalAmount = element.amount;
//     //
//   })
//   tempData.forEach(element =>{
//     this.totalAmount =element.amount;
//     //
   
//       if(element.discount == ''){
//         element.discount = 0;
//       }
//       this.totalDiscount = this.totalDiscount + parseInt(element.discount);
//       //
    
//   })
//   }
// }

// changeTax(event : any,i){
//   //
//   let rateVal = event.target.value;
//   let deviceInfo = (<FormArray>this.goodInWordForm.get("device")).at(i);
//    let gst = 0;
//   let qtyVal = (<FormArray>this.goodInWordForm.get("device")).at(i).get('qty').value;
//    let sgst = (<FormArray>this.goodInWordForm.get("device")).at(i).get('sgst').value;
//    let cgst = (<FormArray>this.goodInWordForm.get("device")).at(i).get('cgst').value;
//    let igst = (<FormArray>this.goodInWordForm.get("device")).at(i).get('igst').value;
//    if(sgst == null)
//    {
//     sgst = 0;
//    }
//    if(cgst == null)
//    {
//     cgst = 0;
//    }
//    if(igst == null)
//    {
//     igst = 0;
//    }
//   //
//   //
//   //

//   let amount = (<FormArray>this.goodInWordForm.get("device")).at(i).get('amount').value;
//   sgst = parseInt(sgst);
//   qtyVal = parseInt(qtyVal);
//   cgst = parseInt(cgst);
//   igst = parseInt(igst);
//   if(this.state == 'MH')
//   {
//     gst = sgst + cgst;
//   }
//   else
//   {
//     gst = igst;
//   }
   
//   //

//   this.taxValue = (gst/100 )*amount;
//   this.finalAmt = this.totalAmount + this.taxValue;
//   //

//     const tempData= this.goodInWordForm.controls.device.value;
//     //
//     tempData.forEach(element =>{
//       this.totalAmount = element.amount;
//       //
//     })
//   }

// onDiscountSelect(event : any,i){
//   //
//   let deviceInfo = (<FormArray>this.goodInWordForm.get("device")).at(i);
//   let rateVal = (<FormArray>this.goodInWordForm.get("device")).at(i).get('unitPrice').value;
//   let qtyVal = (<FormArray>this.goodInWordForm.get("device")).at(i).get('qty').value;
//   let amt = (<FormArray>this.goodInWordForm.get("device")).at(i).get('amount').value;
//   let discount = event.target.value;
//   amt = parseInt(amt);
//    if(parseInt(discount) > amt){
//     deviceInfo.patchValue({
//       discount: ""
//   });
//     this.apimicroservice.openSnackbar("Discount should be less than or equal to the total amount");
//    }else{
//     qtyVal = parseInt(qtyVal);
//    rateVal = parseInt(rateVal);
//   if(discount !== ''){
//     discount = parseInt(discount);
//     this.amountRate =(rateVal * qtyVal) - discount;
  
//     deviceInfo.patchValue({
//         amount: this.amountRate
//     });
    
//     const tempData= this.goodInWordForm.controls.device.value;
//     //
//     this.totalDiscount = 0;
//     let amttt = rateVal;
//     tempData.forEach(element =>{
//       this.totalAmount =amttt;
//       //
//       if(element.discount == ''){
//         element.discount = 0;
//       }
//       this.totalDiscount = this.totalDiscount + parseInt(element.discount);
//       //
//     })
//     this.totalAmount = amttt - this.totalDiscount;
//     this.finalAmt = this.totalAmount + this.taxValue;
//     //
//     //
//   }
  
//    }
// }

changeAmount(event : any,i){
/////
//  this.totalGst = 0;


this.oldAmount = 0;
this.sgstValue = 0;
this.cgstValue = 0;
this.igstValue = 0;
this.totalFinalAmount = 0;
///
//totalAmount
  let sgstVal = 0;
  let cgstVal = 0;
  let igstVal = 0;
  //
  let rateVal = event.target.value;
  let deviceInfo = (<FormArray>this.goodInWordForm.get("device")).at(i);
  
  let qtyVal = (<FormArray>this.goodInWordForm.get("device")).at(i).get('qty').value;
  // if(qtyVal == '' || qtyVal == null || qtyVal == undefined)
  // {
  //   qtyVal= 1; 
  // }
  let sgst = (<FormArray>this.goodInWordForm.get("device")).at(i).get('sgst').value;
     let cgst = (<FormArray>this.goodInWordForm.get("device")).at(i).get('cgst').value;
     let igst = (<FormArray>this.goodInWordForm.get("device")).at(i).get('igst').value;
     let has = (<FormArray>this.goodInWordForm.get("device")).at(i).get('has').value;
     
  //let discount = (<FormArray>this.goodInWordForm.get("device")).at(i).get('discount').value;
  //
  qtyVal = parseInt(qtyVal);
  rateVal = parseFloat(rateVal);
  this.amountVal = qtyVal * rateVal;
  
    deviceInfo.patchValue({
        amount: this.amountVal
    });
    if(this.state == 'MH')
      {
        if(sgst !== null)
            {
            sgstVal = (this.amountVal * parseInt(sgst)) / 100;
            }
            if(cgst !== null)
            {
            cgstVal = (this.amountVal * parseInt(cgst)) / 100;
            }
            //
            //
            //
            this.totalGst = this.totalGst + sgstVal + cgstVal;
            //
      }
      else
      {
        if(igst !== null)
        {
        igstVal = (this.amountVal * parseInt(igst)) / 100;
        }
          //
        this.totalGst = this.totalGst + igstVal;
      
        //
      }
   
    const tempData= this.goodInWordForm.controls.device.value;
    //
    this.allDeviceArr = tempData;
    this.totalAmount = 0;
    // this.totalAmount = this.totalAmount + this.amountVal;
    // //
   
    
    tempData.forEach(element =>{
      
      this.totalAmount = this.totalAmount + element.amount;
      //

      // if(this.state == 'MH')
      // {
      //   if(sgst !== null)
      //       {
      //       sgstVal = (this.amountVal * parseInt(element.sgst)) / 100;
      //       }
      //       if(cgst !== null)
      //       {
      //       cgstVal = (this.amountVal * parseInt(element.cgst)) / 100;
      //       }
      //       this.totalGst = this.totalGst + sgstVal + cgstVal;
      // }
      // else
      // {
      //   if(igst !== null)
      //   {
      //   igstVal = (this.amountVal * parseInt(element.igst)) / 100;
      //   }
      //   this.totalGst = this.totalGst + igstVal;
      // }

    }) 
    
    
    this.totalFinalAmount = this.totalAmount + this.totalGst;
  }
  
  changeDiscount(event:any,i){
    //
    let dis = event.target.value;
    let deviceInfo = (<FormArray>this.goodInWordForm.get("device")).at(i);
    if(dis == ''){
      deviceInfo.patchValue({
        amount: this.amountVal
    });
    const tempData= this.goodInWordForm.controls.device.value;
    //
    this.totalAmount = 0;
    this.totalDiscount = 0;
    
    tempData.forEach(element =>{
      this.totalAmount = this.totalAmount + element.amount;
      //
     
        if(element.discount == ''){
          element.discount = 0;
        }
        this.totalDiscount = this.totalDiscount + parseInt(element.discount);
        //
      
    })
    }
  }
  
  onDiscountSelect(event : any,i){
    //
    //let rateVal = event.target.value;
   //////
  //  this.totalGst = 0;
  
  //this.totalGst = 0;
    this.oldAmount = 0;
  //  this.sgstValue = 0;
  //  this.cgstValue = 0;
  //  this.igstValue = 0;
    this.totalFinalAmount = 0;
   /////
    let sgstVal = 0;
    let cgstVal = 0;
    let igstVal = 0; 
    let deviceInfo = (<FormArray>this.goodInWordForm.get("device")).at(i);
    let rateVal = (<FormArray>this.goodInWordForm.get("device")).at(i).get('unitPrice').value;
    let qtyVal = (<FormArray>this.goodInWordForm.get("device")).at(i).get('qty').value;
    let amt = (<FormArray>this.goodInWordForm.get("device")).at(i).get('amount').value;
     let sgst = (<FormArray>this.goodInWordForm.get("device")).at(i).get('sgst').value;
     let cgst = (<FormArray>this.goodInWordForm.get("device")).at(i).get('cgst').value;
     let igst = (<FormArray>this.goodInWordForm.get("device")).at(i).get('igst').value;
    let discount = event.target.value;
    //let amount = (<FormArray>this.goodInWordForm.get("device")).at(i).get('amount').value;
    amt = parseInt(amt);
    // let x = (amt * 50 )/100;
    // //
     if(parseInt(discount) > amt){
      deviceInfo.patchValue({
        discount: ""
    });
      this.apimicroservice.openSnackbar("Discount should be less than or equal to the total amount");
     }else{
      qtyVal = parseInt(qtyVal);
     rateVal = parseFloat(rateVal);
    if(discount !== ''){
      discount = parseInt(discount);
      this.amountRate = (qtyVal * rateVal) - discount;
    
      deviceInfo.patchValue({
          amount: this.amountRate
      });

      if(this.state == 'MH')
      {
        this.amountVal = qtyVal * rateVal;
        if(sgst !== null)
            {
            sgstVal = (this.amountVal * parseInt(sgst)) / 100;
            }
            if(cgst !== null)
            {
            cgstVal = (this.amountVal * parseInt(cgst)) / 100;
            }
            //
            //
            //
            this.totalGst = this.totalGst - sgstVal - cgstVal;
            //

            if(sgst !== null)
            {
            sgstVal = (this.amountRate * parseInt(sgst)) / 100;
            this.sgstValue = sgstVal;
            }
            if(cgst !== null)
            {
            cgstVal = (this.amountRate * parseInt(cgst)) / 100;
            this.cgstValue = cgstVal;
            }
            //
            //
            //
            this.totalGst = this.totalGst + sgstVal + cgstVal;
            //
      }
      else
      {
        if(igst !== null)
        {
        igstVal = (this.amountVal * parseInt(igst)) / 100;
       
        }
          //
        this.totalGst = this.totalGst - igstVal;
      
        //

        if(igst !== null)
        {
        igstVal = (this.amountRate * parseInt(igst)) / 100;
        this.igstValue = igstVal;
        }
          //
        this.totalGst = this.totalGst + igstVal;
      
        //
      }

      const tempData= this.goodInWordForm.controls.device.value;
      //
      this.allDeviceArr = tempData;
      //
      this.totalAmount = 0;
      this.totalDiscount = 0;
      tempData.forEach(element =>{
        this.totalAmount = this.totalAmount + element.amount;
        //
        if(element.discount == ''){
          this.totalDiscount = this.totalDiscount;
        }else{
          this.totalDiscount = this.totalDiscount + parseInt(element.discount);
        }
        
        //
      })
      this.totalFinalAmount = this.totalAmount + this.totalGst;
    }
    else
    {
      let oldsgstVal = 0;
      let oldcgstval = 0;
      let oldigstVal = 0;
      let oldsgst : any;
      let oldcgst :any;
      let oldigst :any;
      let price :any;
      let qty = 0;
      let dis :any;
     this.allDeviceArr.forEach((ele,index) =>{
      if(index == i){
         price = parseFloat(ele.unitPrice);
         qty = ele.qty;
         dis = parseInt(ele.discount);
         oldsgst = ele.sgst;
         oldcgst = ele.cgst;
         oldigst = ele.igst;
         //
         //
         //
      }
     });

     this.oldAmount = (qty * price) - dis;
     //
      this.amountRate = qtyVal * rateVal;
    
      deviceInfo.patchValue({
          amount: this.amountRate
      });

      if(this.state == 'MH')
      {
       // this.totalGst = this.totalGst - this.sgstValue - this.cgstValue;
       if(oldsgst !== null){
        oldsgstVal = (this.oldAmount * parseInt(oldsgst)) / 100;
       }
       if(oldcgst !== null){
        oldcgstval = (this.oldAmount * parseInt(oldcgst)) / 100;
       }

       //
      //
      this.totalGst = this.totalGst - oldsgstVal - oldcgstval;
      //
        if(sgst !== null)
            {
            sgstVal = (this.amountRate * parseInt(sgst)) / 100;
            }
            if(cgst !== null)
            {
            cgstVal = (this.amountRate * parseInt(cgst)) / 100;
            }
            //
            //
            // //
            this.totalGst = this.totalGst + sgstVal + cgstVal;
            //
      }
      else
      {
       // this.totalGst = this.totalGst - this.igstValue;
       if(oldigst !== null)
        {
        oldigstVal = (this.oldAmount * parseInt(oldigst)) / 100;
        }
        //
        this.totalGst = this.totalGst - oldigstVal;
      
        //

        if(igst !== null)
        {
        igstVal = (this.amountRate * parseInt(igst)) / 100;
        }
          //
        this.totalGst = this.totalGst + igstVal;
      
        //
      }
   
      const tempData= this.goodInWordForm.controls.device.value;
      //
      // this.allDeviceArr = tempData;
      // //
      this.totalAmount = 0;
      this.totalDiscount = 0;
      tempData.forEach(element =>{
        this.totalAmount = this.totalAmount + element.amount;
        //
        if(element.discount == ''){
          this.totalDiscount = this.totalDiscount;
        }else{
          this.totalDiscount = this.totalDiscount + parseInt(element.discount);
        }
        
        //
      })
      this.totalFinalAmount = this.totalAmount + this.totalGst;
    }
    
     }
     
  }

  
onPageChange(event)
{
  
  this.page = event;
  //this.spinner.show();
 // pg = this.page;
 this.searchText = this.goodInWordForm.get('searchGoods').value;
 let clinicRef= sessionStorage.getItem('clinicRef');
 let branchRef= sessionStorage.getItem('branchRef');
 let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
 let branchRefSuperAdmin= '';
 //this.spinner.show();
 if(this.userType !== 'Super Admin'){
   this.apimicroservice.getGoodsInWordData(this.page,this.size,this.searchText,branchRef,clinicRef).subscribe(res => {
     //
    // this.spinner.hide();
    this.purchaseList = res.data.purchaseData;
    this.totalLength=res.data.totalItems;
    if(res.status){
    //  this.apimicroservice.openSnackbar(res.message);
      
    }else{
      //this.apimicroservice.openSnackbar(res.message);
    }
});
 }else{
   this.apimicroservice.getGoodsInWordData(this.page,this.size,this.searchText,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
     //
    // this.spinner.hide();
    this.purchaseList = res.data.purchaseData;
    this.totalLength=res.data.totalItems;
    if(res.status){
      //this.apimicroservice.openSnackbar(res.message);
      
    }else{
      //this.apimicroservice.openSnackbar(res.message);
    }
});
 }
}

onKeyUpEvent(event: any) {
  
  this.searchText = this.goodInWordForm.get('searchGoods').value;
  let clinicRef= sessionStorage.getItem('clinicRef');
  let branchRef= sessionStorage.getItem('branchRef');
  let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
  let branchRefSuperAdmin= '';
 let page=1;
 let size=10;
 //this.spinner.show();
 if(this.userType !== 'Super Admin'){
   this.apimicroservice.getGoodsInWordData(page,size,this.searchText,branchRef,clinicRef).subscribe(res => {
     //
    // this.spinner.hide();
    this.purchaseList = res.data.purchaseData;
    this.totalLength=res.data.totalItems;
    this.page=res.data.pageNumber; 
    if(res.status){
      //this.apimicroservice.openSnackbar(res.message);
      
    }else{
      //this.apimicroservice.openSnackbar(res.message);
    }
});
 }else{
   this.apimicroservice.getGoodsInWordData(page,size,this.searchText,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
     //
     //this.spinner.hide();
    this.purchaseList = res.data.purchaseData;
    this.totalLength=res.data.totalItems;
    this.page=res.data.pageNumber;
    if(res.status){
     // this.apimicroservice.openSnackbar(res.message);
      
    }else{
      //this.apimicroservice.openSnackbar(res.message);
    }
});
 }
}

  prepopulation(id){
    this.spinner.show();
    this.apimicroservice.getGoodsInWordDataById(id).subscribe(res => {
      //
      this.spinner.hide();
      this._id = id;
      this.submitButton=false;
      this.updateButton=true;
      this.cancelButton=true;
      this.disableBranch = true;
     let data = res.data[0];
     this.prepopulationData = res.data[0];
      if(res.status){
        this.goodInWordForm = this.fb.group({
          "grnNo": new FormControl(data.grnNo), 
          "vendorRefId": new FormControl(data.vendorRefId), 
          "vendorName":new FormControl(data.vendorName),
          "haCompanyRefId": new FormControl(data.haCompanyRefId,[Validators.required]),
          "haCompanyName":new FormControl(data.haCompanyName),
          "branchRefId": new FormControl(data.branchRefId), 
          "branchName":new FormControl(data.branchName),
          "methodOfShifting": new FormControl(data.methodOfShifting,[Validators.pattern("^[a-zA-Z ]*$")]), 
          "paymentTerms": new FormControl(data.paymentTerms), 
          "dueDate": new FormControl(data.dueDate), 
          "paymentOption": new FormControl(data.paymentOption), 
         // "transporterName": new FormControl(data.transporterName,[Validators.required]),   
          //"grnDate": new FormControl(data.grnDate,[Validators.required]),   
          "poNumber": new FormControl(data.poNumber,[Validators.pattern("^[a-zA-Z0-9 -]+$"),Validators.maxLength(15),Validators.minLength(4)]), 
          "soNumber": new FormControl(data.soNumber,[Validators.pattern("^[a-zA-Z0-9_./#-]*$"),Validators.maxLength(18),Validators.minLength(4)]), 
          "orderDate": new FormControl(data.orderDate),
          "invoiceNo": new FormControl(data.invoiceNo,[Validators.required,Validators.pattern("^[ A-Za-z0-9_@./#&+-]*$"),Validators.maxLength(16),Validators.minLength(4)]), 
          "invoiceDate": new FormControl(data.invoiceDate,[Validators.required]), 
          "totalAmount": new FormControl(data.totalAmount),
          "totalDiscount": new FormControl(data.totalDiscount),
          "totalTax": new FormControl(data.totalTax), 
          "totalInvoiceAmount": new FormControl(data.totalInvoiceAmount), 
          "billingCompanyRefId" :new FormControl(data.billingCompanyRefId,Validators.required),
          "billingCompanyName":new FormControl(data.billingCompanyName),
          "fileData": new FormControl(data.fileData),
          "fileName": new FormControl(data.fileName),
          "device": this.fb.array([]),
          "searchGoods": new FormControl(''), 
        });
        this.filedata = data.fileData;
        this.fileName = data.fileName;
        this.hide = true;
        this.totalAmount = data.totalInvoiceAmount;
        this.totalDiscount = data.totalDiscount;
        this.taxValue = data.totalTax;
        this.finalAmt = data.totalAmount;
        this.duedate = data.dueDate;
        this.branchRefId = data.branchRefId;
        this.onSelectBranch(data.branchRefId,data.branchName);
        this.goodInWordForm.get('branchRefId').disable();
        let preArr = [];
        data.device.forEach(element => {
       
          preArr.push(element);
          //       
          const tmpDict = {};

          tmpDict['deviceRefId'] = new FormControl(element.deviceRefId,[Validators.required]);
          tmpDict['deviceName'] = new FormControl(element.deviceName);
          if(element.serialNumber[0].includes('No SerialNo'))
          {
            tmpDict['serialNumber'] = new FormControl('');  
          }
          else
          {
            tmpDict['serialNumber'] = new FormControl(element.serialNumber,[RxwebValidators.unique()]);
          }
         
          tmpDict['serialNumberStatus'] = new FormControl(element.serialNumberStatus);
          // tmpDict['poNumber'] = new FormControl(element.poNumber);
          // tmpDict['soNumber'] = new FormControl(element.soNumber);
          // tmpDict['orderDate'] = new FormControl(element.orderDate);
          tmpDict['qty'] = new FormControl(element.qty);
          // tmpDict['unitOfMeasurement'] = new FormControl(element.unitOfMeasurement);
          tmpDict['unitPrice'] = new FormControl(element.unitPrice,[Validators.required,Validators.pattern("^[0-9 .]*$"),Validators.minLength(2),Validators.maxLength(7)]);
          tmpDict['discount'] = new FormControl(element.discount,[Validators.pattern("^[0-9]*$")]);
          tmpDict['amount'] = new FormControl(element.amount);
          tmpDict['cgst'] = new FormControl(element.cgst);
          tmpDict['sgst'] = new FormControl(element.sgst);
          tmpDict['igst'] = new FormControl(element.igst);
          tmpDict['has'] = new FormControl(element.has);
          tmpDict['isSerialNo'] = new FormControl(element.isSerialNo);  
               
        //  tmpDict['rate'] = new FormControl(element.rate);
         // tmpDict['warrentyPeriod'] = new FormControl(element.warrentyPeriod);
          const creds = this.goodInWordForm.controls.device as FormArray;
           creds.push(new FormGroup(tmpDict));
           //
          });
      }
    });
  }
  deleteRow(index){
//////////
// this.totalGst = 0;
// this.oldAmount = 0;
// this.sgstValue = 0;
// this.cgstValue = 0;
// this.igstValue = 0;
// this.totalFinalAmount = 0;
this.oldAmount = 0;


  this.totalFinalAmount = 0;
/////////
  //   const tempData= this.goodInWordForm.controls.device.value;
  //   //
  //  // this.totalAmount = 0;
  //   this.totalDiscount = 0;
  //   tempData.forEach(element =>{
  //     this.totalAmount = this.totalAmount + element.amount;
  //     //
  //     this.totalDiscount = this.totalDiscount + parseInt(element.discount);
  //     //
  //   })

  let sgstVal = 0;
  let cgstVal = 0;
  let igstVal = 0;
   
  
 // let deviceInfo = (<FormArray>this.goodInWordForm.get("device")).at(index);
  let discount = (<FormArray>this.goodInWordForm.get("device")).at(index).get('discount').value;
  let rateVal = (<FormArray>this.goodInWordForm.get("device")).at(index).get('unitPrice').value;
  let qtyVal = (<FormArray>this.goodInWordForm.get("device")).at(index).get('qty').value;
  let sgst = (<FormArray>this.goodInWordForm.get("device")).at(index).get('sgst').value;
     let cgst = (<FormArray>this.goodInWordForm.get("device")).at(index).get('cgst').value;
     let igst = (<FormArray>this.goodInWordForm.get("device")).at(index).get('igst').value;
     
  //let discount = (<FormArray>this.goodInWordForm.get("device")).at(i).get('discount').value;
  //
  //
  qtyVal = parseInt(qtyVal);
  rateVal = parseFloat(rateVal);
  if(discount == '') {
    this.amountVal = qtyVal * rateVal;
  }else {
    this.amountVal = (qtyVal * rateVal) - parseInt(discount);
  }
  
  
    if(this.state == 'MH')
      {
        if((sgst !== null) && (sgst !== ''))
            {
            sgstVal = (this.amountVal * parseInt(sgst)) / 100;
            }
            if((cgst !== null) && (cgst !== ''))
            {
            cgstVal = (this.amountVal * parseInt(cgst)) / 100;
            }
            //
            //
            //
            this.totalGst = this.totalGst - sgstVal - cgstVal;
            //
      }
      else
      {
        if((igst !== null) && (igst !== ''))
        {
        igstVal = (this.amountVal * parseInt(igst)) / 100;
        }
          //
        this.totalGst = this.totalGst - igstVal;
      
        //
      }

  //removing row at index
  (<FormArray>this.goodInWordForm.get("device")).removeAt(index);
   
    const tempData= this.goodInWordForm.controls.device.value;
    //
    this.allDeviceArr = tempData;
      //
    this.totalAmount = 0;
    this.totalDiscount = 0;
    // this.totalAmount = this.totalAmount + this.amountVal;
    // //
    tempData.forEach(element =>{
      this.totalAmount = this.totalAmount + element.amount;
      //
      if(element.discount == ''){
        this.totalDiscount = this.totalDiscount;
      //
      }else{
        this.totalDiscount = this.totalDiscount + parseInt(element.discount);
        //
      }
      
    })
    this.totalFinalAmount = this.totalAmount + this.totalGst;

    
  }
  addNew(i) {
    
    // let tempdevices = [];
    // tempdevices = this.deviceList[i];
   // //
    const creds = this.goodInWordForm.controls.device as FormArray;
        creds.push(
        this.fb.group({
          
          "deviceRefId": new FormControl('',[Validators.required]),
          "deviceName": new FormControl(''),
          "serialNumber": new FormControl([],[RxwebValidators.unique()]),
          "serialNumberStatus": new FormControl(''),
          // "poNumber": new FormControl('',[Validators.required]), 
          // "soNumber": new FormControl('',[Validators.required]), 
          // "orderDate": new FormControl('',[Validators.required]), 
          "qty": new FormControl(''),   
          // "unitOfMeasurement": new FormControl('',[Validators.required]), 
          "unitPrice": new FormControl('',[Validators.required,Validators.pattern("^[0-9 .]*$"),Validators.minLength(2),Validators.maxLength(7)]), 
          "discount": new FormControl('',[Validators.pattern("^[0-9]*$")]),
         
         // "rate": new FormControl('',[Validators.required]),   
         // "warrentyPeriod": new FormControl('',[Validators.required]),   
          "amount": new FormControl(''),   
          "cgst": new FormControl(''), 
          "sgst": new FormControl(''), 
          "igst": new FormControl(''), 
          "has": new FormControl(''), 
          "isSerialNo": new FormControl(true), 
      
        toggle: false

})
);
// let deviceInfo = (<FormArray>this.goodInWordForm.get("device")).at(i);
   
// let deviceid = deviceInfo.get('deviceRefId').value;
// //
// if(deviceid !== ""){
//  i = i + 1;
//  //
//  tempdevices = tempdevices.filter(m => m._id!=deviceid);
//  //
//  this.deviceList[i] = tempdevices;
//  this.filteredListDevice[i]= this.deviceList[i].slice();
// }
  }

  onSelectCompany(id,cname,state){
    //
    //
    //
    this.goodInWordForm.controls['haCompanyName'].setValue(cname);
    if(state == 'MH' || state == 'Maharashtra')
    {
      this.state = 'MH';
    }
  }

  

  onSelectVendor(id,vdname,state){
    //
    //
    //
    //
    this.goodInWordForm.controls['vendorName'].setValue(vdname);
    if(state == 'MH' || state == 'Maharashtra')
    {
      this.state = 'MH';
    }
  }
  onSelectDevice(id,dname,cgst,sgst,igst,has,isSerialNo,i){
    //
    //
    //
    //
    //
    //
   //
   
    let control = (<FormArray>this.goodInWordForm.get("device")).at(i);
    const tempData= this.goodInWordForm.controls.device.value;
 this.isSerial = isSerialNo;
    if(isSerialNo == true) 
    { 
      control.get('serialNumber').setValidators(Validators.required);
      control.get('serialNumber').updateValueAndValidity();
    }
    else
    {
      control.get('serialNumber').setErrors(null);
      control.get('serialNumber').clearValidators();
      control.get('serialNumber').updateValueAndValidity();
      control.patchValue({
        qty: 1,
    });
    }
    control.patchValue({
      deviceName: dname,
      cgst: cgst,
      sgst: sgst,
      igst: igst,
      has: has,
      isSerialNo:isSerialNo
  });
  }
  openPopUp(i) {
    let serialNoControl = (<FormArray>this.goodInWordForm.get("device")).at(i);
    let branchId = this.goodInWordForm.get("branchRefId").value;
    ////
    //.value);
    let ssno = serialNoControl.get('serialNumber').value;
    
    const dialogRef3 = this.dialog.open(PopupSerialNoComponent,
      {
        width: "600px",
        data: {
          serialValue: ssno
        }
      });
    dialogRef3.afterClosed().subscribe((result) => {
      //
      ////
      let tempArr = [];
      let tempsrno = [];
      result.data.forEach(element =>{
       tempArr.push({"serialNo":element.serialNumber,"status":"unsold"});
       tempsrno.push(element.serialNumber);
       //
       //
      })
      ////
      let qtyCount = tempsrno.length;
      let serialInfo = (<FormArray>this.goodInWordForm.get("device")).at(i);
      let discount = (<FormArray>this.goodInWordForm.get("device")).at(i).get('discount').value;
      let rate = serialInfo.get('unitPrice').value;
      if(discount == null){
        discount = "";
      }
      //let qtyVal = parseInt(qtyCount);
     //
      if(rate !== ''){
       rate = parseFloat(rate);
       
        //
        if(discount !== ""){
          discount = parseInt(discount);
          this.amountVal = (qtyCount * rate) - discount;
          //
        }else{
          this.amountVal = qtyCount * rate;
          //
        }
        serialInfo.patchValue({
          amount: this.amountVal,
      });
      const tempData= this.goodInWordForm.controls.device.value;
    //
    this.totalAmount = 0;
    
    tempData.forEach(element =>{
      this.totalAmount = this.totalAmount + element.amount;
      //
    })
      }
      serialInfo.patchValue({
        serialNumber: tempsrno,
        serialNumberStatus:tempArr,
        qty:qtyCount
    });
  //  this.changeAmount(event,i);
    });
  }
  onSelectBranch(id,bname){
    //
    //
    this.goodInWordForm.controls['branchName'].setValue(bname);
  }
  redirect(to): void {
    this.router.navigate([to]);
   }
  onback() {
    this.apimicroservice.back()
  }
  cancelUpdate(){
    this.ngOnInit();
    //this.goodInWordForm.reset();
    this.submitButton=true;
    this.updateButton=false;
    this.cancelButton=false;
    this.filedata= '';
    this.fileName= '';
    this.hide = false;
  }
  getLastId()
  {
    this.apimicroservice.getLastPurchaseID().subscribe(res => {
      //   
      if(res['data']['data'] == '')
      {
        this.goodInWordForm.controls['grnNo'].setValue('1');
      }else{
        if (res['status']) {
          let grnIdd = res['data'][0].grnNo;
          this.goodInWordForm.controls['grnNo'].setValue(grnIdd + 1);
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
      }  
    })
  }
  toPurchaseInvoiceForm() {
    document.getElementById("toPurchaseInvoiceForm").scrollIntoView();
  }
  toPurchaseInvoiceList(){
    document.getElementById("toPurchaseInvoiceList").scrollIntoView();
  }
   openBranchPopUp() {
   // localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpBranchComponent,
      {
        width: "1200px",
      });
      dialogRef3.afterClosed().subscribe((result) => {
        let branchRef= sessionStorage.getItem('branchRef');
        this.apimicroservice.getBranchById(branchRef).subscribe(res => {
          //
         
         this.branchList = res.data;
         this.filteredListBranch= this.branchList.slice();
         this.onSelectBranch(res.data._id,res.data[0].branchName);
         this.goodInWordForm.get("branchRefId").setValue(res.data[0]._id);
         if(this.userType !== 'Super Admin'){
          //this.goodInWordForm.controls['branchRefId'].setValue(this.branchRef);
          this.goodInWordForm.get("branchRefId").setValue(res.data[0]._id);
          this.disableBranch = true;
         }else{
          this.disableBranch = false;
         }
         
         if(res.status){
           //this.apimicroservice.openSnackbar(res.message);
           
         }else{
           //this.apimicroservice.openSnackbar(res.message);
         }
      });
      
        //this.fetchBranchList(); 
       });
  }
  openVendorPopUp() {
   // localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpVendorComponent,
      {
        width: "1200px",
      });
      dialogRef3.afterClosed().subscribe((result) =>{
        if(this.userType !== 'Super Admin'){
          this.apimicroservice.getVendorByBranchId(this.branchRef,this.clinicRef).subscribe(res => {
            //
           
           this.vendorList = res.data;
           this.filteredListVendor= this.vendorList.slice();
           this.onSelectVendor(res.data[0]._id,res.data[0].vendorName,res.data[0].state);
          // this.goodInWordForm.controls['vendorRefId'].setValue(res.data[0]._id);
           this.goodInWordForm.get("vendorRefId").setValue(res.data[0]._id);
           if(res.status){
             //this.apimicroservice.openSnackbar(res.message);
             
           }else{
             //this.apimicroservice.openSnackbar(res.message);
           }
        });
        }else{
          let id = '';
          this.apimicroservice.getVendorById(id).subscribe(res => {
            //
           
           this.vendorList = res.data;
           this.filteredListVendor= this.vendorList.slice();
           this.onSelectVendor(res.data[0]._id,res.data[0].vendorName,res.data[0].state);
           //this.goodInWordForm.controls['vendorRefId'].setValue(res.data[0]._id);
           this.goodInWordForm.get("vendorRefId").setValue(res.data[0]._id);
           if(res.status){
             //this.apimicroservice.openSnackbar(res.message);
             
           }else{
             //this.apimicroservice.openSnackbar(res.message);
           }
        });
        }
       
      }
      )
  }

  openCompanyPopUp(){
    const dialogRef3 = this.dialog.open(PopupCompanyComponent,
      {
        width: "900px",
      });
  
      dialogRef3.afterClosed().subscribe((result) => {
        let clinicRef= sessionStorage.getItem('clinicRef');
        let branchRef= sessionStorage.getItem('branchRef');
        let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
        let branchRefSuperAdmin= '';
        if (this.userType !== 'Super Admin') {
        this.apimicroservice.getAllHACompanylist(branchRef,clinicRef).subscribe(res => {
          //
           if (res.status) {    
             this.companyList = res.data.data;
             this.filteredListCompany= this.companyList.slice();
             this.onSelectCompany(this.companyList[0]._id,this.companyList[0].companyName,this.companyList[0].state);
            
             this.goodInWordForm.get("haCompanyRefId").setValue(res.data.data[0]._id);
           } else {
           //  this.apimicroservice.openSnackbar(res.message);
           }
         })
        }else{
          this.apimicroservice.getAllHACompanylist(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
            //
             if (res.status) {    
               this.companyList = res.data.data;
               this.filteredListCompany= this.companyList.slice();
               this.onSelectCompany(this.companyList[0]._id,this.companyList[0].companyName,this.companyList[0].state);
              
               this.goodInWordForm.get("haCompanyRefId").setValue(res.data.data[0]._id);
             } else {
             //  this.apimicroservice.openSnackbar(res.message);
             }
           })
        }
       });
  }

  selectDevice(id){
    this.goodInWordForm.get("deviceRefId").setValue(id);
  }
  openDevicePopUp(){
    //localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpDeviceComponent,
      {
        width: "1200px",
      });
      dialogRef3.afterClosed().subscribe((result) => {
       
        this.apimicroservice.getAllDevice().subscribe(res => {
          if (res.status) {
            this.deviceList = res.data.data;
            this.filteredDeviceList= this.deviceList.slice();
            this.selectDevice(this.deviceList[0]._id);
            //this.goodInWordForm.get("model").setValue(this.deviceList[0]._id);
          }
        });
        
       });
  }
  openBillingCompPopUp(){
    const dialogRef3 = this.dialog.open(PopupBillingcompanyComponent,
      {
        width: "1200px",
      });
  
      dialogRef3.afterClosed().subscribe((result) => {
        let id='';
        let clinicRef= sessionStorage.getItem('clinicRef');
      this.apimicroservice.getBillingDataById(id,clinicRef).subscribe(res => {
       
        if(res.status){
          //this.apimicroservice.openSnackbar(res.message);
          //
          this.billingCompanyList = res.data;
          this.filteredListBillingCompany= this.billingCompanyList.slice();
         this.companyClick(res.data[0]._id,res.data[0].companyName);
         this.goodInWordForm.get("billingCompanyRefId").setValue(res.data.data[0]._id);
        }else{
          //this.apimicroservice.openSnackbar(res.message);
        }
      });
        //this.fetchBillingCompanyList();  
       });
  }
  fileName = "";
  hide = false;
  onChange($event: Event) {
    const file = ($event.target as HTMLInputElement).files[0];
    const fileName = file.name;
    this.fileName = fileName;
    this.errormessage = '';
    let ext = fileName.substring(fileName.lastIndexOf(".") + 1);
    if (
      ext.toLowerCase() == "png" ||
      ext.toLowerCase() == "jpg" ||
      ext.toLowerCase() == "jpeg" ||
      ext.toLowerCase() == "csv" ||
      ext.toLowerCase() == "xlsx" ||
      ext.toLowerCase() == "pdf" ||
      ext.toLowerCase() == "docx" 
    ) { 
      this.convertToBase64(file);
      this.hide = true;
      this.fileName = fileName;
    }
  }
  convertToBase64(file: File) {
    const observable = new Observable((subscriber: Subscriber<any>) => {
      this.readFile(file, subscriber);
    });
    //
    
    observable.subscribe((d) => {
      this.filedata = d;
       
    });
  }
  showbase64(e){
    const dialogRef3 = this.dialog.open(PdfviewerComponent,
      {
        width: "1200px",
        data: e
      });
  }
  readFile(file: File, subscriber: Subscriber<any>) {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
  
    fileReader.onload = () => {
      subscriber.next(fileReader.result);
      subscriber.complete();
    };
    fileReader.onerror = (error) => {
      subscriber.error(error);
      subscriber.complete();
    };
  }
  remove(){
    this.hide = false;
  }
  stockChart(){
    const dialogRef3 = this.dialog.open(PopupStockInHandComponent,
      {
        width: "1200px",
      });
  }
}