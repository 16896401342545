import { Component } from '@angular/core';
import { Routes, Router, RouterModule } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'audiology';
  hideNavBar;
  userName;
  role;
  constructor(public router: Router,
    private http: HttpClient
  ) { }
  ngOnInit() {
    setInterval(() => {
      this.userName = localStorage.getItem('userName');

this.role = sessionStorage.getItem('role');
    }, 1000);
    setInterval(() => {
      if (this.router.url == '/login' || this.router.url == '/reset') {
        this.hideNavBar = false;
      } else {
        this.hideNavBar = true;
      }
    }, 1000); // for Headder
    
  }
  logOut() {
  sessionStorage.clear();
    localStorage.clear();
    this.router.navigate(['login']);
  }
}


