import { Component, OnInit } from "@angular/core";
import { ApiServiceService } from "../api-service.service";
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { PopUpBranchComponent } from "../pop-up-branch/pop-up-branch.component";
import { MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PopUpDoctorComponent } from "../pop-up-doctor/pop-up-doctor.component";
import { textChangeRangeIsUnchanged } from "typescript";
import { NoopScrollStrategy } from "@angular/cdk/overlay";
import { HttpErrorResponse } from "@angular/common/http";
@Component({
  selector: 'app-doctor-availability',
  templateUrl: './doctor-availability.component.html',
  styleUrls: ['./doctor-availability.component.scss']
})
export class DoctorAvailabilityComponent implements OnInit {
  searchText: any;
  BranchList: any;
  doctorList: any;
  filteredListDoctor: any;
  branchRef: any;
  clinicRef: any;
  doctorAvailabiltyForm: FormGroup;
  dayLabel: string;
  userType: string;
  userPermission: any;
  doctorAvailabilityCreate = true;
  doctorAvailabilityUpdate = true;
  disableUpdate = false;
  disableSubmit = false;
  totalLength: any;
  size = 10;
  page = 1;
  listofDoctorAvailable: any;
  submitButton = true;
  updateButton = false;
  cancelButton = false;
  did: any;
  openTime: any;
  closeTime: any;
  branchWeeklyOff: any;
  filteredListBranch: any;
  doctorCreate = true;
  branchCreate = true;
  morningToTime: any;
  afternoonFromTime: any;
  afternoonToTime: any;
  eveningFromTime: any;
  eveningToTime: any;
  morningFromTime: string;
  doctorRefId: any;
  userName: string;
  fromSelectBranch = false;
  branchOpenTime: string;
  branchCloseTime: string;
  branchWeekOff: string;
  doctorOpenTime: any;
  doctorCloseTime: any;
  constructor(public apimicroservice: ApiServiceService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,) { }
  dayArray = [
    { value: 'Monday' },
    { value: 'Tuesday' },
    { value: 'Wednesday' },
    { value: 'Thursday' },
    { value: 'Friday' },
    { value: 'Saturday' },
    { value: 'Sunday' },
  ]
  ngOnInit(): void {
    this.disableUpdate = false;
    this.disableSubmit = false;
    this.fromSelectBranch = false;
    this.morningFromTime = "";
    this.morningToTime = "";
    this.afternoonFromTime = "";
    this.afternoonToTime = "";
    this.eveningFromTime = "";
    this.eveningToTime = "";
    this.userType = sessionStorage.getItem('userType');
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.doctorRefId = sessionStorage.getItem('doctorRefId');
    this.userName = sessionStorage.getItem('userName');
    this.branchOpenTime = sessionStorage.getItem('branchOpenTime');
    this.branchCloseTime = sessionStorage.getItem('branchCloseTime');
    this.branchWeekOff = sessionStorage.getItem('branchWeekOff');
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    //

    if (this.userType !== 'Super Admin') {
      this.userPermission.forEach((element) => {
        if (element.pageName == 'Doctor Availability' && element.create == false) {
          this.doctorAvailabilityCreate = false;
        }
        if (element.pageName == 'Doctor Availability' && element.update == false) {
          this.doctorAvailabilityUpdate = false;
        }
        if (element.pageName == 'Doctor' && element.create == false) {
          this.doctorCreate = false;
        }
        if (element.pageName == 'Branch' && element.create == false) {
          this.branchCreate = false;
        }
      });
    }
    this.fetchBranchList();
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.doctorAvailabiltyForm = this.fb.group({
      "doctorRefId": new FormControl(null, [Validators.required]),
      "branchRefId": new FormControl(null, [Validators.required]),
      "clinicRefId": new FormControl(),
      "branchName": new FormControl(''),
      "doctorName": new FormControl(''),
      "openTime": new FormControl(''),
      "closeTime": new FormControl(''),
      "branchWeekOff": new FormControl(''),
      // "branchWeeklyOff":new FormControl(''),
      "searchDoctorAvailable": new FormControl(''),
      "dayInfo": this.fb.array([]),
    });
    //  this.doctorAvailabiltyForm.controls["branchRefId"].setValue(this.branchRef);
    if (this.userType == 'Doctor') {

      this.doctorAvailabiltyForm.controls["doctorRefId"].setValue(this.doctorRefId);
      this.doctorAvailabiltyForm.controls["doctorRefId"].disable();
      this.getBranchListById2(this.doctorRefId, this.userName);
    }
    if (this.userType !== 'Super Admin') {
      this.doctorAvailabiltyForm.controls["branchRefId"].setValue(this.branchRef);
    }

    //   for (var i = 0; i < 6; i++) {
    //     this.addNew();
    // }
    for (var index in this.dayArray) {
      // // prints indexes: 0, 1, 2, 3

      //
      let dayName = this.dayArray[index] // prints elements: 10, 20, 30, 40
      // if(dayName.value !== this.branchWeeklyOff){
      this.addNew(dayName);
      // }
      //this.addNew(dayName);

    }
    // this.dayArray.forEach((element) => {
    //   //
    //   //

    //   this.addNew(element.value);


    // });
    this.fetchDoctorList();
    this.fetchDoctorAvailable();
  }
  addNew(dayName) {
    //

    this.dayLabel = dayName.value;

    const creds = this.doctorAvailabiltyForm.controls.dayInfo as FormArray;
    creds.push(
      this.fb.group({
        "day": [this.dayLabel],
        "morningFrom": new FormControl((this.dayLabel == this.branchWeeklyOff ? '' : this.morningFromTime)),
        "morningTo": new FormControl((this.dayLabel == this.branchWeeklyOff ? '' : this.morningToTime)),
        "afternoonFrom": new FormControl((this.dayLabel == this.branchWeeklyOff ? '' : this.afternoonFromTime)),
        "afternoonTo": new FormControl((this.dayLabel == this.branchWeeklyOff ? '' : this.afternoonToTime)),
        "eveningFrom": new FormControl((this.dayLabel == this.branchWeeklyOff ? '' : this.eveningFromTime)),
        "eveningTo": new FormControl((this.dayLabel == this.branchWeeklyOff ? '' : this.eveningToTime)),
        toggle: false
      }));

    creds.controls.forEach(ele => {
      if (ele.get('day').value == this.branchWeeklyOff) {
        ele.disable();
      }
    });
  }
  fetchBranchList() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      //
      if (res.status) {
        this.BranchList = res.data;
        this.filteredListBranch = this.BranchList.slice();
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    })
  }

  getAvailability() {
    this.apimicroservice.getDoctorAppointAvailable(this.branchRef, this.clinicRef, this.doctorRefId).subscribe(res => {
      //
      if (res.message == 'Fetched Successfully') {
        //
        this.fromSelectBranch = true;
        this.prepopulation(res.data[0]._id, this.fromSelectBranch);
      }
      else {
        this.fromSelectBranch = true;
        //
        const creds = this.doctorAvailabiltyForm.controls.dayInfo as FormArray;

        creds.clear();
        for (var index in this.dayArray) {
          // // prints indexes: 0, 1, 2, 3

          //
          let dayName = this.dayArray[index] // prints elements: 10, 20, 30, 40
          // if(dayName.value !== this.branchWeeklyOff){
          this.addNew(dayName);
          // }
        }
        this.updateButton = false;
        this.submitButton = true;
        this.cancelButton = false;
      }
      if (res.status) {
        // this.doctorList = res.data;
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    });
  }

  onSelectBranch(item) {
    //
    this.openTime = item.openTime;
    this.closeTime = item.closeTime;
    this.branchWeeklyOff = item.branchWeeklyOff;
    this.doctorAvailabiltyForm.controls["branchName"].setValue(item.branchName);
    this.branchRef = item._id;

    //
    //
    //

    this.getAvailability();
    // const creds = this.doctorAvailabiltyForm.controls.dayInfo as FormArray;
    // creds.controls.forEach(element =>{
    //   element.get('morningFrom').setValue(this.openTime);
    // })

    let dd = new Date();
    // let a = '';
    // let b ='';
    // a = morningFrom;
    // b = morningTo;
    this.morningFromTime = "12:05 am";
    this.morningToTime = "12:00 pm";
    this.afternoonFromTime = "12:00 pm";
    this.afternoonToTime = "04:00 pm";
    this.eveningFromTime = "04:00 pm";
    this.eveningToTime = "11:59 pm";
    //
    const date1 = new Date(dd.toDateString() + " " + this.closeTime);
    const date2 = new Date(dd.toDateString() + " " + this.morningToTime);
    const date3 = new Date(dd.toDateString() + " " + this.afternoonFromTime);
    const date4 = new Date(dd.toDateString() + " " + this.afternoonToTime);
    const date5 = new Date(dd.toDateString() + " " + this.eveningFromTime);
    const date6 = new Date(dd.toDateString() + " " + this.eveningToTime);
    const date7 = new Date(dd.toDateString() + " " + this.morningFromTime);
    const date8 = new Date(dd.toDateString() + " " + this.openTime);

    //check for open time
    if (date8.getTime() < date2.getTime()) {
      this.morningFromTime = this.openTime;
    } else if ((date8.getTime() >= date3.getTime()) && (date8.getTime() < date4.getTime())) {
      this.morningFromTime = "";
      this.morningToTime = "";
      this.afternoonFromTime = this.openTime;
    } else if ((date8.getTime() >= date5.getTime()) && (date8.getTime() < date6.getTime())) {
      this.morningFromTime = "";
      this.morningToTime = "";
      this.afternoonFromTime = "";
      this.afternoonToTime = "";
      this.eveningFromTime = this.openTime;
      this.eveningToTime = "11:59 pm";
    }

    const newdate3 = new Date(dd.toDateString() + " " + this.afternoonFromTime);
    const newdate5 = new Date(dd.toDateString() + " " + this.eveningFromTime);
    const newdate2 = new Date(dd.toDateString() + " " + this.morningToTime);
    const newdate4 = new Date(dd.toDateString() + " " + this.afternoonToTime);
    const newopen = new Date(dd.toDateString() + " " + this.morningFromTime);

    if (date1.getTime() <= newdate2.getTime()) {
      this.morningToTime = this.closeTime;
      this.afternoonFromTime = "";
      this.afternoonToTime = "";
      this.eveningFromTime = "";
      this.eveningToTime = "";
    } else if ((date1.getTime() > newdate3.getTime()) && (date1.getTime() <= newdate4.getTime()) && (newdate3.getTime() == date8.getTime()) && (newopen.getTime() !== date8.getTime())) {
      this.morningToTime = "";
      //this.afternoonFromTime = "12:00 pm";
      this.afternoonToTime = this.closeTime;
      this.eveningFromTime = "";
      this.eveningToTime = "";
    } else if ((date1.getTime() > newdate3.getTime()) && (date1.getTime() <= newdate4.getTime()) && (newdate3.getTime() !== date8.getTime()) && (newopen.getTime() == date8.getTime())) {
      this.morningToTime = "12:00 pm";
      this.afternoonFromTime = "12:00 pm";
      this.afternoonToTime = this.closeTime;
      this.eveningFromTime = "";
      this.eveningToTime = "";
    } else if ((date1.getTime() > newdate5.getTime()) && (date1.getTime() <= date6.getTime()) && (newdate5.getTime() == date8.getTime())) {
      this.morningToTime = "";
      this.afternoonFromTime = "";
      this.afternoonToTime = "";
      //this.eveningFromTime = "04:00 pm";
      this.eveningToTime = this.closeTime;
    } else if ((date1.getTime() > newdate5.getTime()) && (date1.getTime() <= date6.getTime()) && (newdate5.getTime() !== date8.getTime()) && (newdate3.getTime() == date8.getTime())) {
      this.morningToTime = "";
      //this.afternoonFromTime = "";
      this.afternoonToTime = "04:00 pm";
      this.eveningFromTime = "04:00 pm";
      this.eveningToTime = this.closeTime;
    } else if ((date1.getTime() > newdate5.getTime()) && (date1.getTime() <= date6.getTime()) && (newdate5.getTime() !== date8.getTime()) && (newopen.getTime() == date8.getTime())) {
      this.morningToTime = "12:00 pm";
      this.afternoonFromTime = "12:00 pm";
      this.afternoonToTime = "04:00 pm";
      this.eveningFromTime = "04:00 pm";
      this.eveningToTime = this.closeTime;
    }
  }
  prepopulateTimeData(opTime, clTime) {
    ////
    this.openTime = opTime;
    this.closeTime = clTime;
    // this.branchWeeklyOff = item.branchWeeklyOff;
    //
    //
    // //
    // const creds = this.doctorAvailabiltyForm.controls.dayInfo as FormArray;
    // creds.controls.forEach(element =>{
    //   element.get('morningFrom').setValue(this.openTime);
    // })

    let dd = new Date();
    // let a = '';
    // let b ='';
    // a = morningFrom;
    // b = morningTo;
    this.morningFromTime = "12:05 am";
    this.morningToTime = "12:00 pm";
    this.afternoonFromTime = "12:00 pm";
    this.afternoonToTime = "04:00 pm";
    this.eveningFromTime = "04:00 pm";
    this.eveningToTime = "11:59 pm";
    //
    const date1 = new Date(dd.toDateString() + " " + this.closeTime);
    const date2 = new Date(dd.toDateString() + " " + this.morningToTime);
    const date3 = new Date(dd.toDateString() + " " + this.afternoonFromTime);
    const date4 = new Date(dd.toDateString() + " " + this.afternoonToTime);
    const date5 = new Date(dd.toDateString() + " " + this.eveningFromTime);
    const date6 = new Date(dd.toDateString() + " " + this.eveningToTime);
    const date7 = new Date(dd.toDateString() + " " + this.morningFromTime);
    const date8 = new Date(dd.toDateString() + " " + this.openTime);

    //check for open time
    if (date8.getTime() < date2.getTime()) {
      this.morningFromTime = this.openTime;
    } else if ((date8.getTime() >= date3.getTime()) && (date8.getTime() < date4.getTime())) {
      this.morningFromTime = "";
      this.morningToTime = "";
      this.afternoonFromTime = this.openTime;
    } else if ((date8.getTime() >= date5.getTime()) && (date8.getTime() < date6.getTime())) {
      this.morningFromTime = "";
      this.morningToTime = "";
      this.afternoonFromTime = "";
      this.afternoonToTime = "";
      this.eveningFromTime = this.openTime;
      this.eveningToTime = "11:59 pm";
    }

    const newdate3 = new Date(dd.toDateString() + " " + this.afternoonFromTime);
    const newdate5 = new Date(dd.toDateString() + " " + this.eveningFromTime);
    const newdate2 = new Date(dd.toDateString() + " " + this.morningToTime);
    const newdate4 = new Date(dd.toDateString() + " " + this.afternoonToTime);
    const newopen = new Date(dd.toDateString() + " " + this.morningFromTime);

    if (date1.getTime() <= newdate2.getTime()) {
      this.morningToTime = this.closeTime;
      this.afternoonFromTime = "";
      this.afternoonToTime = "";
      this.eveningFromTime = "";
      this.eveningToTime = "";
    } else if ((date1.getTime() > newdate3.getTime()) && (date1.getTime() <= newdate4.getTime()) && (newdate3.getTime() == date8.getTime()) && (newopen.getTime() !== date8.getTime())) {
      this.morningToTime = "";
      //this.afternoonFromTime = "12:00 pm";
      this.afternoonToTime = this.closeTime;
      this.eveningFromTime = "";
      this.eveningToTime = "";
    } else if ((date1.getTime() > newdate3.getTime()) && (date1.getTime() <= newdate4.getTime()) && (newdate3.getTime() !== date8.getTime()) && (newopen.getTime() == date8.getTime())) {
      this.morningToTime = "12:00 pm";
      this.afternoonFromTime = "12:00 pm";
      this.afternoonToTime = this.closeTime;
      this.eveningFromTime = "";
      this.eveningToTime = "";
    } else if ((date1.getTime() > newdate5.getTime()) && (date1.getTime() <= date6.getTime()) && (newdate5.getTime() == date8.getTime())) {
      this.morningToTime = "";
      this.afternoonFromTime = "";
      this.afternoonToTime = "";
      //this.eveningFromTime = "04:00 pm";
      this.eveningToTime = this.closeTime;
    } else if ((date1.getTime() > newdate5.getTime()) && (date1.getTime() <= date6.getTime()) && (newdate5.getTime() !== date8.getTime()) && (newdate3.getTime() == date8.getTime())) {
      this.morningToTime = "";
      //this.afternoonFromTime = "";
      this.afternoonToTime = "04:00 pm";
      this.eveningFromTime = "04:00 pm";
      this.eveningToTime = this.closeTime;
    } else if ((date1.getTime() > newdate5.getTime()) && (date1.getTime() <= date6.getTime()) && (newdate5.getTime() !== date8.getTime()) && (newopen.getTime() == date8.getTime())) {
      this.morningToTime = "12:00 pm";
      this.afternoonFromTime = "12:00 pm";
      this.afternoonToTime = "04:00 pm";
      this.eveningFromTime = "04:00 pm";
      this.eveningToTime = this.closeTime;
    }
  }
  fetchDoctorList() {
    //
    //
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getAllDoctorsList(branchRef, clinicRef).subscribe(res => {
        //

        if (res.status) {
          this.doctorList = res.data;
          this.filteredListDoctor = this.doctorList.slice();
        } else {
          this.apimicroservice.openSnackbar(res.message);
        }
      });
    }
    else {
      this.apimicroservice.getAllDoctorslist(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
        //
        if (res.status) {
          //
          this.doctorList = res.data.data;
          this.filteredListDoctor = this.doctorList.slice();
        }
      });
    }
  }
  // branchClick(id)
  // {
  //   this.branchRef = id;
  //  this.apimicroservice.getBranchById(id).subscribe(res => { 
  //    //
  //    //
  //     this.clinicRef = res.data[0].clinicRefId;
  //     this.fetchDoctorList();
  //   });

  // }
  //getDoctorAppointAvailable(branchRefId,clinicRefId,doctorRefId)
  getBranchListById(id, firstName, lastName) {
    this.doctorRefId = id;
    this.apimicroservice.getDoctorById(id).subscribe(res => {
      //
      let dName = firstName + ' ' + lastName;
      if (res.status) {
        //this.branchRef=res.data[0].branchRefId;
        //this.doctorAvailabiltyForm.controls["branchRefId"].setValue(this.branchRef);
        this.doctorAvailabiltyForm.controls["doctorName"].setValue(dName);

        //     this.apimicroservice.getDoctorAppointAvailable(this.branchRef,this.clinicRef,id).subscribe(res => {   
        //       //
        //          if(res.message == 'Fetched Successfully')
        //          {
        //            //
        //            this.fromSelectBranch = true;
        //            this.prepopulation(res.data[0]._id);
        //          }
        //          else
        //          {
        //           this.fromSelectBranch = true;
        //            //
        //            const creds = this.doctorAvailabiltyForm.controls.dayInfo as FormArray;

        //            creds.clear();
        //            for (var index in this.dayArray) {
        //             // // prints indexes: 0, 1, 2, 3

        //             //
        //             let dayName = this.dayArray[index] // prints elements: 10, 20, 30, 40
        //             // if(dayName.value !== this.branchWeeklyOff){
        //               this.addNew(dayName);
        //             // }
        //           }
        //            this.updateButton = false;
        //            this.submitButton = true;
        //            this.cancelButton=false;
        //          }
        //       if (res.status) {
        //        // this.doctorList = res.data;
        //       } else {
        //         this.apimicroservice.openSnackbar(res.message);
        //       }
        //       });
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }

      //      this.apimicroservice.getBranchById(this.branchRef).subscribe(res => { 
      //  this.doctorAvailabiltyForm.controls["branchName"].setValue(res.data[0].branchName);
      // });
    });
  }
  getBranchListById2(id, userName) {
    this.doctorRefId = id;
    this.apimicroservice.getDoctorById(id).subscribe(res => {
      //

      if (res.status) {
        //this.branchRef=res.data[0].branchRefId;
        // this.doctorAvailabiltyForm.controls["branchRefId"].setValue(this.branchRef);
        this.doctorAvailabiltyForm.controls["doctorName"].setValue(userName);

        // this.apimicroservice.getDoctorAppointAvailable(this.branchRef,this.clinicRef,id).subscribe(res => {   
        //   //
        //      if(res.message == 'Fetched Successfully')
        //      {
        //        //
        //        this.prepopulation(res.data[0]._id);
        //      }
        //      else
        //      {
        //        //
        //        const creds = this.doctorAvailabiltyForm.controls.dayInfo as FormArray;

        //        creds.clear();
        //        for (var index in this.dayArray) {
        //         // // prints indexes: 0, 1, 2, 3

        //         //
        //         let dayName = this.dayArray[index] // prints elements: 10, 20, 30, 40
        //         // if(this.dayArray[index] !== this.branchWeeklyOff){
        //           this.addNew(dayName);
        //         // }
        //       }
        //        this.updateButton = false;
        //        this.submitButton = true;
        //        this.cancelButton=false;
        //      }
        //   if (res.status) {
        //    // this.doctorList = res.data;
        //   } else {
        //     this.apimicroservice.openSnackbar(res.message);
        //   }
        //   });
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }

      //      this.apimicroservice.getBranchById(this.branchRef).subscribe(res => { 
      //  this.doctorAvailabiltyForm.controls["branchName"].setValue(res.data[0].branchName);
      // });
    });
  }

  saveAvailabilityData() {
    const creds = this.doctorAvailabiltyForm.controls.dayInfo as FormArray;
    let payload = this.doctorAvailabiltyForm.value;
    payload.clinicRefId = this.clinicRef;
    if (this.doctorAvailabiltyForm.invalid || creds.invalid) {
      this.doctorAvailabiltyForm.get("doctorRefId").markAsTouched();
      this.doctorAvailabiltyForm.get("branchRefId").markAsTouched();

      creds.controls.forEach(element => {
        element.get('morningFrom').markAsTouched();
        element.get('morningTo').markAsTouched();
        element.get('afternoonFrom').markAsTouched();
        element.get('afternoonTo').markAsTouched();
        element.get('eveningFrom').markAsTouched();
        element.get('eveningTo').markAsTouched();
      });

      return;
    } else {
      //
      this.saveData();
    }
  }
  saveData() {
    //
    this.spinner.show();
    let payload = this.doctorAvailabiltyForm.value;
    //
    payload.clinicRefId = this.clinicRef;
    payload.openTime = this.openTime;
    payload.closeTime = this.closeTime;
    payload.branchWeekOff = this.branchWeeklyOff;
    //payload.branchWeeklyOff = this.branchWeeklyOff;
    this.apimicroservice.saveDoctorAvailable(payload).subscribe((res) => {
      //
      this.spinner.hide();
      if (res.status) {

        this.ngOnInit();
        this.apimicroservice.openSnackbar(res.message);
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    }, (ERROR: HttpErrorResponse) => {
      this.apimicroservice.openSnackbar(ERROR.error.message);
      //
      this.spinner.hide();
    });
  }
  fetchDoctorAvailable() {
    this.spinner.show();
    let pg = 1;
    let size = 10;
    let branchRef = sessionStorage.getItem('branchRef');
    let clinicRef = sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin = '';
    let clinicRefSuperAdmin  = sessionStorage.getItem('clinicRef');
    let search = '';
    this.searchText = this.doctorAvailabiltyForm.get('searchDoctorAvailable').value;
    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getDoctorAvailablePaginationData(pg, size, search, branchRef, clinicRef).subscribe(res => {
        this.spinner.hide();
        //

        this.listofDoctorAvailable = res.data.data;
        this.totalLength = res.data.totalItems;
      });
    } else {
      this.apimicroservice.getDoctorAvailablePaginationData(pg, size, search, branchRefSuperAdmin, clinicRefSuperAdmin).subscribe(res => {
        this.spinner.hide();
        this.listofDoctorAvailable = res.data.data;
        this.totalLength = res.data.totalItems;
      });
    }


  }
  prepopulation(id, selectBranch) {
    this.fromSelectBranch = selectBranch;
    //
    let indexVal = 0;
    this.spinner.show();
    this.apimicroservice.getDoctorAvailableDataById(id).subscribe(res => {
      //
      this.spinner.hide();
      let data = res.data[0];
      this.doctorRefId = data.doctorRefId;
      if (res.status) {
        this.doctorAvailabiltyForm = this.fb.group({
          "doctorRefId": new FormControl(data.doctorRefId, [Validators.required]),
          "branchRefId": new FormControl(data.branchRefId, [Validators.required]),
          "clinicRefId": new FormControl(data.clinicRefId),
          "branchName": new FormControl(data.branchName),
          "doctorName": new FormControl(data.doctorName),
          "openTime": new FormControl(data.openTime),
          "closeTime": new FormControl(data.closeTime),
          "branchWeekOff": new FormControl(data.branchWeekOff),

          "dayInfo": this.fb.array([]),

        });
        if (this.fromSelectBranch == false) {
          //
          //

          // if(this.branchOpenTime == null && this.branchCloseTime == null){
          this.BranchList.forEach(element => {
            if (element._id == data.branchRefId) {
              this.openTime = element.openTime;
              this.closeTime = element.closeTime;
              this.branchWeeklyOff = element.branchWeeklyOff;
            }
          });
          this.prepopulateTimeData(this.openTime, this.closeTime);


        }

        let preArr = [];
        data.dayInfo.forEach(element => {

          preArr.push(element);
          //
          const tmpDict = {};
          if ((data.openTime == this.openTime) && (data.closeTime == this.closeTime)) {
            tmpDict['day'] = new FormControl(element.day);
            tmpDict['morningFrom'] = new FormControl(element.morningFrom, Validators.pattern("^$|([1-9]|1[0-2]):([0-5]\\d)\\s?((?:[Aa])\\.?[Mm]\\.?)$"));
            tmpDict['morningTo'] = new FormControl(element.morningTo);
            tmpDict['afternoonFrom'] = new FormControl(element.afternoonFrom, Validators.pattern("^$|(12|[1-4]):([0-5]\\d)\\s?((?:[Pp])\\.?[Mm]\\.?)$"));
            tmpDict['afternoonTo'] = new FormControl(element.afternoonTo);
            tmpDict['eveningFrom'] = new FormControl(element.eveningFrom);
            tmpDict['eveningTo'] = new FormControl(element.eveningTo, Validators.pattern("^$|([4-9]|1[0-2]):([0-5]\\d)\\s?((?:[Pp])\\.?[Mm]\\.?)$"));
          } else {
            tmpDict['day'] = new FormControl(element.day);
            tmpDict['morningFrom'] = new FormControl(this.morningFromTime, Validators.pattern("^$|([1-9]|1[0-2]):([0-5]\\d)\\s?((?:[Aa])\\.?[Mm]\\.?)$"));
            tmpDict['morningTo'] = new FormControl(this.morningToTime);
            tmpDict['afternoonFrom'] = new FormControl(this.afternoonFromTime, Validators.pattern("^$|(12|[1-4]):([0-5]\\d)\\s?((?:[Pp])\\.?[Mm]\\.?)$"));
            tmpDict['afternoonTo'] = new FormControl(this.afternoonToTime);
            tmpDict['eveningFrom'] = new FormControl(this.eveningFromTime);
            tmpDict['eveningTo'] = new FormControl(this.eveningToTime, Validators.pattern("^$|([4-9]|1[0-2]):([0-5]\\d)\\s?((?:[Pp])\\.?[Mm]\\.?)$"));
          }

          // if(element.day !== this.branchWeeklyOff){
          //   tmpDict['day'] = new FormControl(element.day);
          // for (var index in this.dayArray) {
          //   // // prints indexes: 0, 1, 2, 3

          //   // //
          //   let dayName = this.dayArray[index] // prints elements: 10, 20, 30, 40
          //   if(dayName.value == element.day){
          //     //
          //     //
          //     indexVal = parseInt(index);
          //     if(dayName.value == 'Sunday'){
          //       indexVal = 6;
          //     }
          //   }
          // }
          // }else{
          //   tmpDict['day'] = new FormControl(data.branchWeekOff);
          // for (var index in this.dayArray) {
          //   // // prints indexes: 0, 1, 2, 3

          //   // //
          //   let dayName = this.dayArray[index] // prints elements: 10, 20, 30, 40
          //   if(dayName.value == data.branchWeekOff){
          //     //
          //     //
          //     indexVal = parseInt(index);
          //     if(dayName.value == 'Sunday'){
          //       indexVal = 6;
          //     }
          //   }
          // }
          // tmpDict['morningFrom'] = new FormControl(this.morningFromTime,Validators.pattern("^$|([1-9]|1[0-2]):([0-5]\\d)\\s?((?:[Aa])\\.?[Mm]\\.?)$"));
          // tmpDict['morningTo'] = new FormControl(this.morningToTime,Validators.pattern("^$|([1-9]|1[0-2]):([0-5]\\d)\\s?((?:[Aa])\\.?[Mm]\\.?)$"));
          // tmpDict['afternoonFrom'] = new FormControl(this.afternoonFromTime,Validators.pattern("^$|(12|[1-4]):([0-5]\\d)\\s?((?:[Pp])\\.?[Mm]\\.?)$"));
          // tmpDict['afternoonTo'] = new FormControl(this.afternoonToTime);
          // tmpDict['eveningFrom'] = new FormControl(this.eveningFromTime);
          // tmpDict['eveningTo'] = new FormControl(this.eveningToTime,Validators.pattern("^$|([4-9]|1[0-2]):([0-5]\\d)\\s?((?:[Pp])\\.?[Mm]\\.?)$"));
          // }

          const creds = this.doctorAvailabiltyForm.controls.dayInfo as FormArray;
          creds.push(new FormGroup(tmpDict));
          ///recent code//// creds.insert(indexVal,new FormGroup(tmpDict));
          //old // if(element.day !== this.branchWeeklyOff){
          //   creds.push(new FormGroup(tmpDict));
          // }

        });
        const creds = this.doctorAvailabiltyForm.controls.dayInfo as FormArray;
        creds.controls.forEach(ele => {
          if (ele.get('day').value == this.branchWeeklyOff) {

            ele.get('morningFrom').setValue('');
            ele.get('morningTo').setValue('');
            ele.get('afternoonFrom').setValue('');
            ele.get('afternoonTo').setValue('');
            ele.get('eveningFrom').setValue('');
            ele.get('eveningTo').setValue('');
            ele.disable();
          }
        })

        const tmpDict = {};
        if (data.branchWeekOff !== this.branchWeeklyOff) {
          tmpDict['day'] = new FormControl(data.branchWeekOff);
          tmpDict['morningFrom'] = new FormControl(this.morningFromTime, Validators.pattern("^$|([1-9]|1[0-2]):([0-5]\\d)\\s?((?:[Aa])\\.?[Mm]\\.?)$"));
          tmpDict['morningTo'] = new FormControl(this.morningToTime);
          tmpDict['afternoonFrom'] = new FormControl(this.afternoonFromTime, Validators.pattern("^$|(12|[1-4]):([0-5]\\d)\\s?((?:[Pp])\\.?[Mm]\\.?)$"));
          tmpDict['afternoonTo'] = new FormControl(this.afternoonToTime);
          tmpDict['eveningFrom'] = new FormControl(this.eveningFromTime);
          tmpDict['eveningTo'] = new FormControl(this.eveningToTime, Validators.pattern("^$|([4-9]|1[0-2]):([0-5]\\d)\\s?((?:[Pp])\\.?[Mm]\\.?)$"));

          for (var index in this.dayArray) {
            // // prints indexes: 0, 1, 2, 3

            // //
            let dayName = this.dayArray[index] // prints elements: 10, 20, 30, 40
            if (dayName.value == data.branchWeekOff) {
              //
              //
              indexVal = parseInt(index);
              // if(dayName.value == 'Sunday'){
              //   indexVal = 6;
              // }
            }
          }
          //
          creds.insert(indexVal, new FormGroup(tmpDict));
        }



        //
        this.did = id;
        this.updateButton = true;
        this.submitButton = false;
        this.cancelButton = true;
      }
    });
  }
  onPageChange(event) {
    this.page = event;
    let branchRef = '';
    let clinicRef = '';
    let search = '';
    this.searchText = this.doctorAvailabiltyForm.get("searchDoctorAvailable");

    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getDoctorAvailablePaginationData(this.page, this.size, search, this.branchRef, this.clinicRef).subscribe(res => {
        if (res.status) {
          this.listofDoctorAvailable = res.data.data;
          this.totalLength = res.data.totalItems;
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }

      })
    } else {
      this.apimicroservice.getDoctorAvailablePaginationData(this.page, this.size, search, branchRef, clinicRef).subscribe(res => {
        if (res.status) {
          this.listofDoctorAvailable = res.data.data;
          this.totalLength = res.data.totalItems;
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }

      })
    }
  }

  onKeyUpEvent(event: any) {
    //

    this.searchText = this.doctorAvailabiltyForm.get("searchDoctorAvailable").value;
    let branchRef = '';
    let clinicRef = '';
    let pg = 1;
    let size = 10;
    //
    //


    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getDoctorAvailablePaginationData(pg, size, this.searchText, this.branchRef, this.clinicRef).subscribe(res => {
        //
        this.listofDoctorAvailable = res.data.data;
        this.totalLength = res.data.totalItems;
        this.page = res.data.pageNumber;
      });
    }
    else {
      this.apimicroservice.getDoctorAvailablePaginationData(pg, size, this.searchText, branchRef, clinicRef).subscribe(res => {
        //
        this.listofDoctorAvailable = res.data.data;
        this.totalLength = res.data.totalItems;
        this.page = res.data.pageNumber;
      });
    }
  }


  cancelUpdate() {
    this.submitButton = true;
    this.updateButton = false;
    this.cancelButton = false;
    this.ngOnInit();
    // this.doctorAvailabiltyForm.reset();
  }
  updateAvailabilityData() {
    const creds = this.doctorAvailabiltyForm.controls.dayInfo as FormArray;
    if (this.doctorAvailabiltyForm.invalid || creds.invalid) {
      this.doctorAvailabiltyForm.get("doctorRefId").markAsTouched();
      this.doctorAvailabiltyForm.get("branchRefId").markAsTouched();

      creds.controls.forEach(element => {
        element.get('morningFrom').markAsTouched();
        element.get('morningTo').markAsTouched();
        element.get('afternoonFrom').markAsTouched();
        element.get('afternoonTo').markAsTouched();
        element.get('eveningFrom').markAsTouched();
        element.get('eveningTo').markAsTouched();
      });

      return;
    } else {
      this.updateDoctor();
    }
  }
  updateDoctor() {
    this.spinner.show();
    let payload = this.doctorAvailabiltyForm.value;
    payload.openTime = this.openTime;
    payload.closeTime = this.closeTime;
    payload.branchWeekOff = this.branchWeeklyOff;
    //payload.branchWeeklyOff = this.branchWeeklyOff;
    this.apimicroservice.updateDoctorAvailable(this.did, payload).subscribe(res => {
      this.spinner.hide();
      this.updateButton = false;
      this.cancelButton = false;
      this.submitButton = true;
      if (res.status) {
        this.apimicroservice.openSnackbar(res.message);
        this.ngOnInit();
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    });
  }
  toDoctorAvailabilityForm() {
    document.getElementById("toDoctorAvailabilityForm").scrollIntoView();
  }
  toDoctorAvailabilityList() {
    document.getElementById("toDoctorAvailabilityList").scrollIntoView();
  }
  toMorningFromChange(i) {
    ////
    ////
    let morningFrom = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i).get('morningFrom').value;
    let morningTo = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i).get('morningTo').value;
    //
    //
    const creds = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i);
    //
    if (morningFrom !== '') {
      creds.get('morningTo').setValidators([Validators.pattern("^$|([7-12]|1[0-2]):([0-5]\\d)\\s?((?:[Aa])\\.?[Mm]\\.?)$")]);
      creds.get('morningTo').updateValueAndValidity();
    }
    else {
      creds.get('morningTo').setErrors(null);
      creds.get('morningTo').clearValidators();
      creds.get('morningTo').updateValueAndValidity();
    }
    if (morningTo !== '') {
      creds.get('morningFrom').setValidators([Validators.pattern("^$|([7-12]|1[0-2]):([0-5]\\d)\\s?((?:[Aa])\\.?[Mm]\\.?)$")]);
      creds.get('morningFrom').updateValueAndValidity();
    }
    else {
      creds.get('morningFrom').setErrors(null);
      creds.get('morningFrom').clearValidators();
      creds.get('morningFrom').updateValueAndValidity();
    }

    let dd = new Date();
    let a = '';
    let b = '';
    a = morningFrom;
    b = morningTo;
    //
    //
    // const [hh,mm,ss] = a.split(':');
    // const [hh2,mm2,ss2] = b.split(':');
    const date1 = new Date(dd.toDateString() + " " + a);
    const date2 = new Date(dd.toDateString() + " " + b);
    // const date3 = new Date(dd.toDateString()+" "+this.morningFromTime);
    // + " " + a);
    //
    if (date2.getTime() <= date1.getTime() && morningTo !== '') {
      this.apimicroservice.openSnackbar("Morning From Time should be Less than Morning To Time");
      let control = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i);
      control.patchValue({
        morningFrom: ''
      });
    }
    // if(morningTo !== '' && morningTo <= morningFrom)
    // {
    //   this.apimicroservice.openSnackbar("Morning From Time should be Less than Morning To Time");
    //   let control = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i);
    //   control.patchValue({
    //     morningFrom: ''
    // });
    // // this.disableSubmit = true;
    // // this.disableUpdate = true;
    // }
    // else{
    //   this.disableSubmit = false;
    // this.disableUpdate = false;
    // }

  }
  toMorningToChange(i) {
    let morningFrom = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i).get('morningFrom').value;
    let morningTo = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i).get('morningTo').value;
    //
    //
    const creds = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i);
    //
    if (morningFrom !== '') {
      creds.get('morningTo').setValidators([Validators.pattern("^$|([7-12]|1[0-2]):([0-5]\\d)\\s?((?:[Aa])\\.?[Mm]\\.?)$")]);
      creds.get('morningTo').updateValueAndValidity();
    }
    else {
      creds.get('morningTo').setErrors(null);
      creds.get('morningTo').clearValidators();
      creds.get('morningTo').updateValueAndValidity();
    }
    if (morningTo !== '') {
      creds.get('morningFrom').setValidators([Validators.pattern("^$|([7-12]|1[0-2]):([0-5]\\d)\\s?((?:[Aa])\\.?[Mm]\\.?)$")]);
      creds.get('morningFrom').updateValueAndValidity();
    }
    else {
      creds.get('morningFrom').setErrors(null);
      creds.get('morningFrom').clearValidators();
      creds.get('morningFrom').updateValueAndValidity();
    }

    let dd = new Date();
    let a = '';
    let b = '';
    a = morningFrom;
    b = morningTo;
    //
    //
    // const [hh,mm,ss] = a.split(':');
    // const [hh2,mm2,ss2] = b.split(':');
    const date1 = new Date(dd.toDateString() + " " + a);
    const date2 = new Date(dd.toDateString() + " " + b);
    const date3 = new Date(dd.toDateString() + " " + this.morningToTime);
    // + " " + a);
    //
    if (date2.getTime() <= date1.getTime() && morningFrom !== '') {
      this.apimicroservice.openSnackbar("Morning To Time Should Be Greater Than Morning From Time");
      let control = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i);

      control.patchValue({
        morningTo: ''
      });
    }
    else if (date2.getTime() > date3.getTime()) {
      this.apimicroservice.openSnackbar("Morning To Time Should Be Less Than Or Equal To " + this.morningToTime);
      let control = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i);

      control.patchValue({
        morningTo: ''
      });
    }
    // if(morningTo <= morningFrom)
    // {
    //   this.apimicroservice.openSnackbar("Morning To Time Should Be Greater Than Morning From Time");
    //   let control = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i);

    //   control.patchValue({
    //     morningTo: ''
    // });

    // }
    // else{
    //   this.disableSubmit = false;
    //   this.disableUpdate = false;
    // }
  }
  toAfternoonFromChange(i) {
    let afternoonTo = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i).get('afternoonTo').value;
    let afternoonFrom = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i).get('afternoonFrom').value;
    //
    let dd = new Date();
    let a = '';
    let b = '';
    a = afternoonFrom;
    b = afternoonTo;
    const creds = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i);
    //
    if (afternoonFrom !== '') {
      creds.get('afternoonTo').setValidators([Validators.pattern("^$|(12|[1-5]):([0-5]\\d)\\s?((?:[Pp])\\.?[Mm]\\.?)$")]);
      creds.get('afternoonTo').updateValueAndValidity();
    }
    else {
      creds.get('afternoonTo').setErrors(null);
      creds.get('afternoonTo').clearValidators();
      creds.get('afternoonTo').updateValueAndValidity();
    }
    if (afternoonTo !== '') {
      creds.get('afternoonFrom').setValidators([Validators.pattern("^$|(12|[1-4]):([0-5]\\d)\\s?((?:[Pp])\\.?[Mm]\\.?)$")]);
      creds.get('afternoonFrom').updateValueAndValidity();
    }
    else {
      creds.get('afternoonFrom').setErrors(null);
      creds.get('afternoonFrom').clearValidators();
      creds.get('afternoonFrom').updateValueAndValidity();
    }
    // const [hh,mm,ss] = a.split(':');
    // const [hh2,mm2,ss2] = b.split(':');
    const date1 = new Date(dd.toDateString() + " " + a);
    const date2 = new Date(dd.toDateString() + " " + b);
    const date3 = new Date(dd.toDateString() + " " + this.afternoonFromTime);
    const date4 = new Date(dd.toDateString() + " " + this.afternoonToTime);
    // + " " + a);
    //
    if (date2.getTime() <= date1.getTime() && afternoonTo !== '') {
      this.apimicroservice.openSnackbar("Afternoon From Time should be Less than Afternoon To Time");
      let control = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i);
      control.patchValue({
        afternoonFrom: ''
      });
    } else if (this.afternoonFromTime == '' && a !== '') {
      this.apimicroservice.openSnackbar("Branch Close Time is " + this.closeTime + " So Afternoon Time Slot Is Not Available");
      let control = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i);
      control.patchValue({
        afternoonFrom: ''
      });
    }
    else if (date1.getTime() < date3.getTime()) {
      this.apimicroservice.openSnackbar("Afternoon From Time should be Greater than Or Equal To " + this.afternoonFromTime);
      let control = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i);
      control.patchValue({
        afternoonFrom: ''
      });
    }
    else if (date1.getTime() >= date4.getTime()) {
      this.apimicroservice.openSnackbar("Afternoon From Time should be Less than " + this.afternoonToTime);
      let control = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i);
      control.patchValue({
        afternoonFrom: ''
      });
    }
    // if(afternoonTo !== '' && afternoonTo <= afternoonFrom)
    // {
    //   this.apimicroservice.openSnackbar("Afternoon To Time Should Be Greater Than Afternoon From Time");
    //   let control = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i);
    //   control.patchValue({
    //     afternoonFrom: ''
    // });
    // // this.disableSubmit = true;
    // // this.disableUpdate = true;
    // }
    // else{
    //   this.disableSubmit = false;
    // this.disableUpdate = false;
    // }

  }
  toAfternoonToChange(i) {
    let afternoonFrom = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i).get('afternoonFrom').value;
    let afternoonTo = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i).get('afternoonTo').value;
    //
    const creds = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i);
    if (afternoonFrom !== '') {
      creds.get('afternoonTo').setValidators([Validators.pattern("^$|(12|[1-5]):([0-5]\\d)\\s?((?:[Pp])\\.?[Mm]\\.?)$")]);
      creds.get('afternoonTo').updateValueAndValidity();
    } 
    else {
      creds.get('afternoonTo').setErrors(null);
      creds.get('afternoonTo').clearValidators();
      creds.get('afternoonTo').updateValueAndValidity();
    } 
    if (afternoonTo !== '') {
      creds.get('afternoonFrom').setValidators([Validators.pattern("^$|(12|[1-4]):([0-5]\\d)\\s?((?:[Pp])\\.?[Mm]\\.?)$")]);
      creds.get('afternoonFrom').updateValueAndValidity();
    }
    else {
      creds.get('afternoonFrom').setErrors(null);
      creds.get('afternoonFrom').clearValidators();
      creds.get('afternoonFrom').updateValueAndValidity();
    }
    let dd = new Date();
    let a = '';
    let b = '';
    a = afternoonFrom;
    b = afternoonTo;
    //
    //
    // const [hh,mm,ss] = a.split(':');
    // const [hh2,mm2,ss2] = b.split(':');
    const date1 = new Date(dd.toDateString() + " " + a);
    const date2 = new Date(dd.toDateString() + " " + b);
    const date3 = new Date(dd.toDateString() + " " + this.afternoonFromTime);
    const date4 = new Date(dd.toDateString() + " " + this.afternoonToTime);

    // + " " + a);
    //
    if (date2.getTime() <= date1.getTime() && afternoonFrom !== '') {
      this.apimicroservice.openSnackbar("Afternoon To Time Should Be Greater Than Afternoon From Time");
      let control = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i);
      control.patchValue({
        afternoonTo: ''
      });
    }
    else if (this.afternoonToTime == '' && afternoonTo !== '') {
      this.apimicroservice.openSnackbar("Branch Close Time is " + this.closeTime + " So Afternoon Time Slot Is Not Available");
      let control = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i);
      control.patchValue({
        afternoonTo: ''
      });
    }
    else if (date2.getTime() > date4.getTime()) {
      this.apimicroservice.openSnackbar("Afternoon To Time should be Less than Or Equal To " + this.afternoonToTime);
      let control = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i);
      control.patchValue({
        afternoonTo: ''
      });
    }
    else if (date2.getTime() <= date3.getTime()) {
      this.apimicroservice.openSnackbar("Afternoon To Time should be Greater Than " + this.afternoonFromTime);
      let control = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i);
      control.patchValue({
        afternoonTo: ''
      });
    }
    // if(afternoonTo <= afternoonFrom)
    // {
    //   this.apimicroservice.openSnackbar("Afternoon From Time should be Less than Afternoon To Time");
    //   let control = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i);
    //   control.patchValue({
    //     afternoonTo: ''
    // });
    // // this.disableSubmit = true;
    // // this.disableUpdate = true;
    // }
    // else{
    //   this.disableSubmit = false;
    // this.disableUpdate = false;
    // }
  }
  toEveningFromChange(i) {
    let eveningFrom = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i).get('eveningFrom').value;
    let eveningTo = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i).get('eveningTo').value;
    //
    const creds = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i);
    if (eveningFrom !== '') {
      creds.get('eveningTo').setValidators([Validators.pattern("^$|([4-9]|1[0-2]):([0-5]\\d)\\s?((?:[Pp])\\.?[Mm]\\.?)$")]);
      creds.get('eveningTo').updateValueAndValidity();
    }
    else {
      creds.get('eveningTo').setErrors(null);
      creds.get('eveningTo').clearValidators();
      creds.get('eveningTo').updateValueAndValidity();
    }
    if (eveningTo !== '') {
      creds.get('eveningFrom').setValidators([Validators.pattern("^$|([3-9]|1[0-2]):([0-5]\\d)\\s?((?:[Pp])\\.?[Mm]\\.?)$")]);
      creds.get('eveningFrom').updateValueAndValidity();
    }
    else {
      creds.get('eveningFrom').setErrors(null);
      creds.get('eveningFrom').clearValidators();
      creds.get('eveningFrom').updateValueAndValidity();
    }
    let dd = new Date();
    let a = '';
    let b = '';
    a = eveningFrom;
    b = eveningTo;

    // const [hh,mm,ss] = a.split(':');
    // const [hh2,mm2,ss2] = b.split(':');
    const date1 = new Date(dd.toDateString() + " " + a);
    const date2 = new Date(dd.toDateString() + " " + b);
    const date3 = new Date(dd.toDateString() + " " + this.eveningFromTime);
    const date4 = new Date(dd.toDateString() + " " + this.eveningToTime);
    // + " " + a);
    //
    if (date2.getTime() <= date1.getTime() && eveningTo !== '') {
      this.apimicroservice.openSnackbar("Evening From Time should be Less than Evening To Time");
      let control = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i);
      control.patchValue({
        eveningFrom: ''
      });
    }
    else if (this.eveningFromTime == '' && eveningFrom !== '') {
      this.apimicroservice.openSnackbar("Branch Close Time is " + this.closeTime + " So Evening Time Slot Is Not Available");
      let control = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i);
      control.patchValue({
        eveningFrom: ''
      });
    }
    else if (date1.getTime() < date3.getTime()) {
      this.apimicroservice.openSnackbar("Evening From Time should be Greater than Or Equal To " + this.eveningFromTime);
      let control = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i);
      control.patchValue({
        eveningFrom: ''
      });
    }
    else if (date1.getTime() >= date4.getTime()) {
      this.apimicroservice.openSnackbar("Evening From Time should be Less than " + this.eveningToTime);
      let control = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i);
      control.patchValue({
        eveningFrom: ''
      });
    }
    // if(eveningTo !== '' && eveningTo <= event)
    // if(eveningTo !== '' && eveningTo <= eveningFrom)
    // {
    //   this.apimicroservice.openSnackbar("Evening To Time Should Be Greater Than Evening From Time");
    //   let control = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i);
    //   control.patchValue({
    //     eveningFrom: ''
    // });
    // // this.disableSubmit = true;
    // // this.disableUpdate = true;
    // }
    // else{
    //   this.disableSubmit = false;
    // this.disableUpdate = false;
    // }

  }
  toEveningToChange(i) {
    // //
    let eveningFrom = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i).get('eveningFrom').value;
    let eveningTo = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i).get('eveningTo').value;
    const creds = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i);
    if (eveningFrom !== '') {
      creds.get('eveningTo').setValidators([Validators.pattern("^$|([4-9]|1[0-2]):([0-5]\\d)\\s?((?:[Pp])\\.?[Mm]\\.?)$")]);
      creds.get('eveningTo').updateValueAndValidity();
    }
    else {
      creds.get('eveningTo').setErrors(null);
      creds.get('eveningTo').clearValidators();
      creds.get('eveningTo').updateValueAndValidity();
    }
    if (eveningTo !== '') {
      creds.get('eveningFrom').setValidators([Validators.pattern("^$|([3-9]|1[0-2]):([0-5]\\d)\\s?((?:[Pp])\\.?[Mm]\\.?)$")]);
      creds.get('eveningFrom').updateValueAndValidity();
    }
    else {
      creds.get('eveningFrom').setErrors(null);
      creds.get('eveningFrom').clearValidators();
      creds.get('eveningFrom').updateValueAndValidity();
    }
    let dd = new Date();
    let a = '';
    let b = '';
    a = eveningFrom;
    b = eveningTo;

    // const [hh,mm,ss] = a.split(':');
    // const [hh2,mm2,ss2] = b.split(':');
    const date1 = new Date(dd.toDateString() + " " + a);
    const date2 = new Date(dd.toDateString() + " " + b);
    const date3 = new Date(dd.toDateString() + " " + this.eveningFromTime);
    const date4 = new Date(dd.toDateString() + " " + this.eveningToTime);
    // + " " + a);
    //
    if (date2.getTime() <= date1.getTime() && eveningFrom !== '') {
      this.apimicroservice.openSnackbar("Evening To Time Should Be Greater Than Evening From Time");
      let control = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i);
      control.patchValue({
        eveningTo: ''
      });
    }
    else if (this.eveningToTime == '' && eveningTo !== '') {
      this.apimicroservice.openSnackbar("Branch Close Time is " + this.closeTime + " So Evening Time Slot Is Not Available");
      let control = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i);
      control.patchValue({
        eveningTo: ''
      });
    }
    else if (date2.getTime() > date4.getTime()) {
      this.apimicroservice.openSnackbar("Evening To Time should be Less than Or Equal To " + this.eveningToTime);
      let control = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i);
      control.patchValue({
        eveningTo: ''
      });
    }
    else if (date2.getTime() <= date3.getTime()) {
      this.apimicroservice.openSnackbar("Evening To Time should be Greater than " + this.eveningFromTime);
      let control = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i);
      control.patchValue({
        eveningTo: ''
      });
    }

    //   if(eveningTo <= eveningFrom)
    //   {
    //     this.apimicroservice.openSnackbar("Evening From Time should be Less than Evening To Time");
    //     let control = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i);
    //     control.patchValue({
    //       eveningTo: ''
    //   });
    // }
    // this.disableSubmit = true;
    // this.disableUpdate = true;
    // }else{
    //   this.disableSubmit = false;
    //   this.disableUpdate = false;
    // }
  }
  openBranchPopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpBranchComponent,
      {
        width: "1200px",
      });
    dialogRef3.afterClosed().subscribe((result) => {
      let clinicRef= sessionStorage.getItem('clinicRef');
      this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
        //
        if (res.status) {
          this.BranchList = res.data;
          this.filteredListBranch = this.BranchList.slice();
          this.onSelectBranch(res.data[0]);
          this.doctorAvailabiltyForm.get("branchRefId").setValue(res.data[0]._id);
        } else {
          this.apimicroservice.openSnackbar(res.message);
        }
      })
      // this.fetchBranchList();
    })
  }
  openDoctorPopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpDoctorComponent,
      {
        width: "1200px",
      });
    dialogRef3.afterClosed().subscribe((result) => {
      //
      //
      let clinicRef= sessionStorage.getItem('clinicRef');
      let branchRef= sessionStorage.getItem('branchRef');
      let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
      let branchRefSuperAdmin= '';
      if (this.userType !== 'Super Admin') {
        this.apimicroservice.getAllDoctorsList(branchRef, clinicRef).subscribe(res => {
          //

          if (res.status) {
            this.doctorList = res.data;
            this.filteredListDoctor = this.doctorList.slice();
          } else {
            this.apimicroservice.openSnackbar(res.message);
          }
        });
      }
      else {
        this.apimicroservice.getAllDoctorslist(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
          //
          if (res.status) {
            //
            this.doctorList = res.data.data;
            this.filteredListDoctor = this.doctorList.slice();
            this.getBranchListById(res.data.data[0]._id, res.data.data[0].firstName, res.data.data[0].lastName);
            this.doctorAvailabiltyForm.get("doctorRefId").setValue(res.data.data[0]._id);
          }
        });
      }
      // this.fetchDoctorList();  
    });
  }

  onback() {
    this.apimicroservice.back()
  }
  refreshMorning(i) {
    let control = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i);
    control.get('morningFrom').setValue('');
  }
  refreshMorningTo(i) {
    let control = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i);
    control.get('morningTo').setValue('');
  }
  refreshAfternoon(i) {
    let control = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i);
    control.get('afternoonFrom').setValue('');
  }
  refreshAfternoonTo(i) {
    let control = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i);
    control.get('afternoonTo').setValue('');
  }
  refreshEvening(i) {
    let control = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i);
    control.get('eveningFrom').setValue('');
  }
  refreshEveningTo(i) {
    let control = (<FormArray>this.doctorAvailabiltyForm.get("dayInfo")).at(i);
    control.get('eveningTo').setValue('');
  }
}

