<div class="dialog-wrapper">
    <div class="container">
      <form [formGroup]="patientTrackingStatusForm" autocomplete="off" novalidate>
      <div class="row">
        <div class="col-sm-12">
           
            <div class="card  p-0" >
                  <div class="row">
                  <div class="col-sm-10">
                    <p class="text-muted">Add New Tracking Status</p>   
                  </div> 
                  <div class="col-sm-2 ">
                    <div class="page-title-right row justify-content-end d-flex align-items-center float-end">
                      <a (click)="onClose()" matTooltip="Close" class="d-flex align-items-center pr-2"><span class="material-symbols-outlined pr-2">close</span></a>
                      </div>
                      </div> 
            </div>
                <div class="col-sm-12">
                  <div class="row">
                      
                <div class="col-sm-6">
                  <label class="pt-3 lable_style">Tracking Status :</label>
                  </div>
        <div class="col-sm-6 pl-1 pr-1">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Status</mat-label>
            <input required matInput placeholder="Status" formControlName="trackingStatusName" />
           
           </mat-form-field>
           <mat-error class="margin_tp pr-0"
           *ngIf="patientTrackingStatusForm.controls['trackingStatusName'].touched && patientTrackingStatusForm.controls['trackingStatusName'].errors?.required">
           This field is mandatory. 
         </mat-error>
         <mat-error class="margin_tp"
           *ngIf="patientTrackingStatusForm.controls['trackingStatusName'].touched && patientTrackingStatusForm.controls['trackingStatusName'].errors?.pattern">
           Enter valid Name.
         </mat-error>
        </div>
        <div class="col-sm-6">
          <label class="pt-3 lable_style">Color :</label>
          </div>
<div class="col-sm-6 pl-1 pr-1">
    <mat-form-field
                                  class="example-full-width"
                                  appearance="outline"
                                > 
                                <mat-label>Choose Color</mat-label>
                                <input required class="h18" matInput placeholder="Choose Color" formControlName="color" type="color" 
                                (change)="onSelectColor($event)" />
                                 
                                </mat-form-field> 
                                <mat-error class="margin_tp"
                                *ngIf="patientTrackingStatusForm.controls['color'].touched && patientTrackingStatusForm.controls['color'].errors?.required">
                                This field is mandatory.
                              </mat-error>
</div>
    
              </div>
              <div class="row mb-2">
                  <div *ngIf="submitButton" class="col-sm-3"></div>
                
              <div *ngIf="submitButton" class="col-sm-6">
                  <button class="btn btn-primary btn-sm example-full-width" type="button" (click)="save()" >Save</button>
                </div>
                <div *ngIf="updateButton" class="col-sm-6">
                  <button class="btn btn-primary btn-sm example-full-width" type="button" (click)="update()" >Update</button>
                </div>
                <div *ngIf="updateButton" class="col-sm-6">
                  <button class="btn btn-primary btn-sm example-full-width" type="reset" (click)="cancelUpdate()" >Cancle</button>
                </div>
                <div *ngIf="submitButton" class="col-sm-3"></div>
              
              </div>
            </div> 
          </div>  
            <div class="card pr-3 pt-4 pl-3" >
            
                                <div class="row">
                                  <div class="col-sm-5">
                                      <h3 class="titlecolor">Tracking Status List</h3>  
                                  </div>
                                  <div class="col-sm-7 mb-2">
                                      <div class="input-group md-form form-sm form-1 pl-0">
                                        <div class="input-group-prepend">
                                          <span class="input-group-text purple lighten-3" id="basic-text1"><i class="fas fa-search text-white"
                                              aria-hidden="true"></i></span>
                                        </div>
                                        <input class="form-control my-0  py-1" type="text"
                                         placeholder="Search" formControlName="search"
                                         (keyup)="onKeyUpEvent($event)" aria-label="Search">
                                      </div>
                                  </div>
                                    <div class="col-sm-12">
                                      <table id="toDeviceList" 
                                      class="table table-hover table-striped table-bordered">
                                        <thead>
                                          <tr class="text-center">
                                            <th> Status</th>
                                            <th>Color</th>
                                             </tr>
                                        </thead>
                                        <tbody >
                                          
                                          <tr  *ngFor="let data of listOfStatus | paginate : { id:'makeMaster',
                                          itemsPerPage: size, currentPage: page, totalItems:totalLength }"
                                       (click)="prepopulation(data._id)" 
                                        style="cursor:pointer; text-align: center ">
                                            <td matTooltip="click to view">{{data.trackingStatusName}}</td> 
                                            <td matTooltip="click to view">{{data.colorName}}</td>                               
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                    <div class="col-sm-12 pt-0 text-right">
                                      <pagination-controls id="makeMaster" directionLinks="true"  
                                      (pageChange)="onPageChange($event)" class="float-right pagination my-pagination"></pagination-controls>
                                    </div>
                                </div>
                              </div>
      
        </div>
    
      </div>
</form>


    </div>


</div>