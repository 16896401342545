<div class="dialog-wrapper">
    <!-- <h3 mat-dialog-title>Change Password </h3> -->
    <mat-dialog-content>
      <div class="container">
        <form [formGroup]="popupItemsPackedForm" autocomplete="off" novalidate>
        <div class="row">
          <div class="col-sm-12">
            <div class="card-group">
              <div class="card p-4" >
                <!-- <button mat-button (click)="onClose()">X</button> -->
               

                <div class="card-body col-sm-12">
                    <div class="row">
                    <div class="col-sm-11">
                        <p class="text-muted">Add Items Packed</p>
                    </div>
                    <div class="col-sm-1 ">
                      <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                        <a (click)="onClose()" matTooltip="Close" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">close</span></a>
                        </div>
                    </div>
                   
              </div>
                  <div class="col-sm-12">
                    <div class="row">
                        <div class="col-sm-4 ">
                            <label class="pt-3 lable_style"   >Item Name :</label>
                            </div>
                  <div *ngIf="addNewItem" class="col-sm-4">
                    <mat-form-field class="example-full-width" *ngIf="itemId==1"  appearance="outline">
                      <mat-label>Items Name</mat-label>
                      <input matInput placeholder="Items Name" formControlName="leftItemName"  required />
                     </mat-form-field>
                     <mat-form-field class="example-full-width" *ngIf="itemId==2"    appearance="outline">
                      <mat-label>Items Name</mat-label>
                      <input matInput placeholder="Items Name" formControlName="rightItemName"  required />
                     </mat-form-field>
                  </div>
                  <div *ngIf="!addNewItem" class="col-sm-4">
                    <mat-form-field class="example-full-width" *ngIf="itemId==1"  appearance="outline">
                      <mat-label>Select Items </mat-label>
                 
                      <mat-select #select1 multiple formControlName="leftItemNameList" >
                        <mat-select-filter [placeholder]="'Filter'" [displayMember]="'leftItemNameList'"  
                        [array]="variables1" (filteredReturn)="filteredList =$event" *ngIf="select1.focused"></mat-select-filter>
                                      <mat-option *ngFor="let item of filteredList" [value]="item.leftItemName">
                                        {{item.leftItemName}}
                                      </mat-option>
                      
                    </mat-select>
                     </mat-form-field>
                     <mat-form-field class="example-full-width" *ngIf="itemId==2"    appearance="outline">
                      <mat-label>Select Items </mat-label>
                      <mat-select #select1 multiple formControlName="rightItemNameList" >
                        <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'rightItemNameList'"  [array]="variables2"
                         (filteredReturn)="filteredList2 =$event"></mat-select-filter>
                                      <mat-option *ngFor="let item of filteredList2" [value]="item.rightItemName">
                                        {{item.rightItemName}}
                                      </mat-option>
                      
                    </mat-select>
                      <!-- <mat-select multiple formControlName="rightRepairItemName" >
                        <mat-option value="Item 1">Item 1</mat-option>
                        <mat-option value="Item 2">Item 2</mat-option>
                        <mat-option value="Item 3">Item 3</mat-option>
                        <mat-option value="Item 4">Item 4</mat-option>
                    </mat-select> -->
                     </mat-form-field>
                  </div>
                  <div class="col-sm-1"></div>
                  <div *ngIf="!addNewItem" class="col-sm-3">
                    
                      <a class="nav-link headers_custom" id="home" (click)="addNewItemFunc()"  [routerLink]="">
                        Add New Item
                       </a> 
                    
                  </div>
                  <div *ngIf="addNewItem" class="col-sm-3">
                    <a class="nav-link headers_custom" id="home" (click)="cancelFunc()" [routerLink]="">
                     Cancel 
                     </a> 
                  </div>
                  <div class="col-sm-5">
                      </div>
                  <div *ngIf="addNewItem" class="col-sm-2">
                    <button type="button" class="btn btn-outline-primary btn-sm example-full-width" *ngIf="itemId==1" (click)="saveLeftItemData()" >Add</button>
                    <button type="button" class="btn btn-outline-primary btn-sm example-full-width" *ngIf="itemId==2"  (click)="saveRightItemData()" >Add</button>
                  </div>
                  <div *ngIf="!addNewItem" class="col-sm-2">
                    <button type="button" class="btn btn-outline-primary btn-sm example-full-width"  *ngIf="itemId==1" >Save</button>
                    <button type="button" class="btn btn-outline-primary btn-sm example-full-width"  *ngIf="itemId==2" >Save</button>
                  </div>
                  <div class="col-sm-5">
                </div>
                </div>
                </div>

                </div>



                
              </div>
            </div>
          </div>
          <div class="col-sm-4"></div>
        </div>
</form>


      </div>
    </mat-dialog-content>
  
  </div>