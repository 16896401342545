import { Component, Inject, OnInit } from "@angular/core";
import { ApiServiceService } from '../api-service.service';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import * as moment from 'moment';
@Component({
  selector: 'app-popup-drag-appointment',
  templateUrl: './popup-drag-appointment.component.html',
  styleUrls: ['./popup-drag-appointment.component.scss']
})
export class PopupDragAppointmentComponent implements OnInit {
  appointRefId: any; 
  appointDate: any;
  patientName = '';
  appDate: any;
  appTime: any; 

  constructor(public dialogRef: MatDialogRef<PopupDragAppointmentComponent>,@Inject(MAT_DIALOG_DATA) public data:any,
  public apimicroservice: ApiServiceService,private spinner: NgxSpinnerService,) { 
    this.appointRefId = data.appointRefId;
    this.patientName = data.patientName;
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.appointDate = new Date(this.data.appointDate);
    this.patientName = this.patientName.charAt(0).toUpperCase() + this.patientName.slice(1);
     let tt = this.appointDate;
     tt = moment(this.appointDate);
     this.appDate = tt.format("MMMM Do YYYY");
     this.appTime = tt.format("LT");
  }

  confirmSubmit(){
   this.onClose(true);
  }
  confirmCancel(){
    this.onClose(false);
   }
  onClose(e): void {
    this.dialogRef.close(e);
  }
}
