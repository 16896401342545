import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pop-up-device-by-patient',
  templateUrl: './pop-up-device-by-patient.component.html',
  styleUrls: ['./pop-up-device-by-patient.component.scss']
})
export class PopUpDeviceByPatientComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
