import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
 import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { DatePipe } from "@angular/common";
import { NgxSpinnerService } from "ngx-spinner"; 
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { AfterViewInit, Directive, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PopupItemsCollectedComponent } from "../popup-items-collected/popup-items-collected.component";
import { PopupNewComplaintComponent } from "../popup-new-complaint/popup-new-complaint.component";
import { refineEventDef } from '@fullcalendar/core';
import { createBuilderStatusReporter } from 'typescript';
import { json } from 'express';
import { PopUpBranchComponent } from "../pop-up-branch/pop-up-branch.component";
import { PopUpPatientComponent } from "../pop-up-patient/pop-up-patient.component";
import { PopupCompanyComponent } from "../popup-company/popup-company.component";
import { PatientSearchPopComponent } from "../patient-search-pop/patient-search-pop.component";
import { Router,ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-repair-dashboard',
  templateUrl: './repair-dashboard.component.html',
  styleUrls: ['./repair-dashboard.component.scss']
})
export class RepairDashboardComponent implements OnInit {
  errormessage: string;
  searchText: any;
  repairData:any;
  repairId: any;
 listOfRepair:any;
  patientList: any;
  branchRef: string;
  clinicRef: string;
  userRef: string;
  userType: string;
  patientSelect=false;
  doctorSelect=false;
  listOfdoctor: any;
  patientFirstName: any;
  patientLastName: any;
  patientGender: any;
  patientMobileNo: any;
  patientAddress: any;
  doctorBranchName: any;
  doctorMobileNo: any;
  doctorAddress: any;
  doctorClinicName: any;
  branchName: any;
  superBranchId: any;
  BranchList: any;
  pid: any;
  did: any;
  sessionBranchName: string;
  repairD = new Date();
  isEditable = false;
  statusArr = [];
  matchId: number;
  filteredListPatient: any;
  filteredListDoctor: any;
  scenariodisable = false;
  leftPartData = true;
  rightPartData = true;
  statusArray=[ {name:'Aid Received for repairs',value:'Aid Received for repairs' },
          {name:'Job Receipt Issued',value:'Job Receipt Issued' },
          {name:'Sent to Manufacturer',value:'Sent to Manufacturer' },
          {name:'Repair Estimate is Received from Company',value:'Repair Estimate is Received from Company' },
          {name:'Estimate to Client is calculated',value:'Estimate to Client is calculated' },
          {name:'Estimate is Advised by call/SMS/WA',value:'Estimate is Advised by call/SMS/WA' },
          {name:'Payment is Received from Client',value:'Payment is Received from Client' },
          {name:'Estimate is Approved with the company on Email',value:'Estimate is Approved with the company on Email' },
          {name:'Device is repaired and Sent back by the company',value:'Device is repaired and Sent back by the company' },
          {name:'Received by NHS',value:'Received by NHS' },
          {name:'Checked and Programmed by audiologist',value:'Checked and Programmed by audiologist' },
          {name:'Client is advised to collect it or Appointment is arranged',value:'Client is advised to collect it or Appointment is arranged' },
          {name:'Or Sent back by Courie',value:'Or Sent back by Courie' },
         ]
         filteredStatusList: { name: string; value: string; }[];
  filteredListBranch: any;
  stepRefId: any;
  repairRefId: any;
  myrepairId: any;
  showstepper = true; 
  DeviceList: any;
  filteredListDevice: any;
  filteredListDevice2: any;
  DeviceList2: any;
  patientCreate= true;
  companyCreate=true;
  repair_branchRefId: string;
  repair_patientRefId: string;
  repair_branchName: string;
  companyName: any;
  canNo: any;
  pId: any;
  patientRef: any;
  branchRefId: any;
  branchNamee: any;
  pName: string;
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
     public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    public route : ActivatedRoute 
  ) {}
  repairDashboardForm: FormGroup;
  status: any;
  submitButton = true;
  updateButton = false;
  deleteButton = false;
  cancelButton = false;
  isLinear = false;
  loggedId: any;
  myimage;
  date: any;
  size:number = 5;
  page:number = 1;
  size1:number = 5;
  page1:number = 1;
  size2:number = 5;
  page2:number = 1;
  size3:number = 5;
  page3:number = 1;
  size4:number = 5;
  page4:number = 1;
  size5:number = 5;
  page5:number = 1;
  totalLength: any;
  variables1;
  variable1List: any;
  variables2;
  variable2List: any;
  variables3;
  variable3List: any;
  filteredListCompany:any;
  companyList = [];
  variables4;
  variable4List: any;
  leftSerialNoReadOnly= false;
  rightSerialNoReadOnly= false;
  rightCompanyReadOnly= false;
  leftCompanyReadOnly= false;
  leftHATypeReadOnly= false;
  rightHATypeReadOnly= false;
  leftHAModelReadOnly= false;
  rightHAModelReadOnly= false;
  userPermission:any;
  repairComplaintCreate= true;
  repairComplaintUpdate= true;
  isSuperAdmin= true;
  norecord = true;
  stepperRefId;
  minDate:Date;
 currentDate:any;
  ngOnInit() {
    this.currentDate = new Date();
    this.minDate = new Date(1950,0,1);
    this.page=1;
    this.date = new Date();
    this.isEditable = false;
    this.scenariodisable = false;
    this.leftPartData = true;
    this.rightPartData = true;
    this.statusArr = [
      {
        "labelOne": "Step 1",
        "datetime": "",
        "labeltwo": "Aid Received for repairs",
        "button": true,
        "controlname": "step1",
        "comments": "",
        "placeholderText":"Items collected from patient-Wires,Charger etc.",
        "_id": "6243002ae53ff64495b22797"
      },
      {
        "labelOne": "Step 2",
        "datetime": "",
        "labeltwo": "Job Receipt Issued​",
         "button": true,
        "controlname": "step2",
        "comments": "",
        "placeholderText":"Job Id generated by system & receipt given to",
        "_id": "6243002ae53ff64495b22798"
      },
      {
        "labelOne": "Step 3",
        "datetime": "",
        "labeltwo": "Sent to Manufacturer​",
         "button": true,
        "controlname": "step3",
        "comments": "",
        "placeholderText":"Courier Details-Tracking Id,Date etc.",
        "_id": "6243002ae53ff64495b22799"
      },
      {
        "labelOne": "Step 4",
        "datetime": "",
        "labeltwo": "Repair Estimate is Received from Company​",
         "button": true,
        "controlname": "step4",
        "comments": "",
        "placeholderText":"Status updated with estimates received",
        "_id": "6243002ae53ff64495b2279a"
      },
      {
        "labelOne": "Step 5",
        "datetime": "",
        "labeltwo": "Estimate to Client is calculated​",
         "button": true,
        "controlname": "step5",
        "comments": "",
        "placeholderText":"System calculates the charges to convey to patient",
        "_id": "6243002ae53ff64495b2279b"
      },
      {
        "labelOne": "Step 6",
        "datetime": "",
        "labeltwo": "Estimate is Advised by call/SMS/WA​",
         "button": true,
        "controlname": "step6",
        "comments": "",
        "placeholderText":"Informed patient via Email,SMS or Phone",
        "_id": "6243002ae53ff64495b2279c"
      },
      {
        "labelOne": "Step 7",
        "datetime": "",
        "labeltwo": "Payment is Received from Client​",
         "button": true,
        "controlname": "step7",
        "comments": "",
        "placeholderText":"Status updated with confirmation received from",
        "_id": "6243002ae53ff64495b2279d"
      },
      {
        "labelOne": "Step 8",
        "datetime": "",
        "labeltwo": "Estimate is Approved with the company on Email",
         "button": true,
        "controlname": "step8",
        "comments": "",
        "placeholderText":"Informed company via Email,SMS or Phone",
        "_id": "6243002ae53ff64495b2279e"
      },
      {
        "labelOne": "Step 9",
        "datetime": "",
        "labeltwo": "Device is repaired and Sent back by the company​",
         "button": true,
        "controlname": "step9",
        "comments": "",
        "placeholderText":"Courier details -tracking Id,Date etc.",
        "_id": "6243002ae53ff64495b2279f"
      },
      {
        "labelOne": "Step 10",
        "datetime": "",
        "labeltwo": "Received by NHS​",
         "button": true,
        "controlname": "step10",
        "comments": "",
        "placeholderText":"Status updated as HA received after Repair",
        "_id": "6243002ae53ff64495b227a0"
      },
      {
        "labelOne": "Step 11",
        "datetime": "",
        "labeltwo": "Checked and Programmed by audiologist​​",
         "button": true,
        "controlname": "step11",
        "comments": "",
        "placeholderText":"Repair verified & necessary steps taken by doctor",
        "_id": "6243002ae53ff64495b227a1"
      },
      {
        "labelOne": "Step 12",
        "datetime": "",
        "labeltwo": "Client is advised to collect it or Appointment is arranged​",
         "button": true,
        "controlname": "step12",
        "comments": "",
        "placeholderText":"Repair status informed to patient via Email,SMS or Phone",
        "_id": "6243002ae53ff64495b227a2"
      },
      {
        "labelOne": "Step 13",
        "datetime": "",
        "labeltwo": "Or Sent back by Courier​",
         "button": true,
        "controlname": "step13",
        "comments": "",
        "placeholderText":"Courier Details-Tracking Id,Date",
        "_id": "6243002ae53ff64495b227a3"
      }
    ];
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.userRef = sessionStorage.getItem('userRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    this.userType = sessionStorage.getItem('userType');
    this.repair_branchRefId = sessionStorage.getItem('repair_branchRefId');
    this.repair_patientRefId = sessionStorage.getItem('repair_patientRefId');
    this.repair_branchName = sessionStorage.getItem('repair_branchName');
    
    this.filteredStatusList = this.statusArray.slice();
    if(this.userType !== 'Super Admin'){
      this.isSuperAdmin= false;
    this.userPermission.forEach((element) => {
      if(element.pageName == 'Repair Complaint' && element.create == false)
      {
          this.repairComplaintCreate = false;  
      }
      if(element.pageName == 'Repair Complaint' && element.update == false)
      {
          this.repairComplaintUpdate = false;  
      }  
      if(element.pageName == 'Patient' && element.create == false)
      {
          this.patientCreate = false;  
      }   
    });
  }
  
    this.repairDashboardForm = this.fb.group({
      "firstName": new FormControl(''),
      "lastName": new FormControl(''),
      "sex": new FormControl(''),
      "mobile1": new FormControl(''),
      "streetAddress": new FormControl(''),
      // "doctorClinicName": new FormControl(''),
      // "doctorBranchName": new FormControl(''),
      // "doctorMobileNo": new FormControl(''),
      // "doctorAddress": new FormControl(''),
      "patientRefId":new FormControl('',Validators.required),
      // "doctorRefId":new FormControl(''),
      "branchRefId":new FormControl(''),
      "branchWiseId":new FormControl(''),
      "branchName":new FormControl(''),
      //"date": new FormControl(''),
      // "repairId":new FormControl(''),
      // "status":new FormControl(''),
      "leftModel":new FormControl('',Validators.required),
      "rightModel":new FormControl(''),
      "leftSrNo":new FormControl('',[Validators.required, Validators.pattern("^[a-zA-Z0-9 -]+$")]),
      "rightSrNo":new FormControl('',Validators.pattern("^[a-zA-Z0-9 -]+$")),
      "leftDead":new FormControl(false),
      "rightDead":new FormControl(false),
      "leftNoisy":new FormControl(false),
      "rightNoisy":new FormControl(false),
      "leftDistortion":new FormControl(false),
      "rightDistortion":new FormControl(false),
      "leftFeedback":new FormControl(false),
      "rightFeedback":new FormControl(false),
      "leftConnectivityIssue":new FormControl(false),
      "rightConnectivityIssue":new FormControl(false),
      "leftTightFit":new FormControl(false),
      "rightTightFit":new FormControl(false),  
      "leftLooseFit":new FormControl(false),
      "rightLooseFit":new FormControl(false),
      "leftOtherShellIssue":new FormControl(false),
      "rightOtherShellIssue":new FormControl(false),
      "leftOther":new FormControl(''),
      "rightOther":new FormControl(''),
      "leftAnyPhysicalDamage":new FormControl(''),
      "rightAnyPhysicalDamage":new FormControl(''),
      "leftVisibleCorrorsion":new FormControl(''),
      "rightVisibleCorrorsion":new FormControl(''),
      "leftBatteryLeakageVisible":new FormControl(''),
      "rightBatteryLeakageVisible":new FormControl(''),
      "leftMouldIncluded":new FormControl(''),
      "rightMouldIncluded":new FormControl(''),
      "leftMouldDamaged":new FormControl(''),
      "rightMouldDamaged":new FormControl(''),
      "leftWarranty":new FormControl(''),
      "rightWarranty":new FormControl(''),
      "leftDateOfPurchase":new FormControl(''),
      "rightDateOfPurchase":new FormControl(''),
      "leftRepairQuotation":new FormControl(''),
      "rightRepairQuotation":new FormControl(''),
      "leftPastRepairRef":new FormControl(''),
      "rightPastRepairRef":new FormControl(''),
      "leftOtherRemark":new FormControl(''),
      "rightOtherRemark":new FormControl(''),
      "leftAcessoriesIncluded":new FormControl(''),
      "rightAcessoriesIncluded":new FormControl(''),
      "searchRepair":new FormControl(''),
      "step1":new FormControl(''),
      "step2":new FormControl(''),
      "step3":new FormControl(''),
      "step4":new FormControl(''),
      "step5":new FormControl(''),
      "step6":new FormControl(''),
      "step7":new FormControl(''),
      "step8":new FormControl(''),
      "step9":new FormControl(''),
      "step10":new FormControl(''),
      "step11":new FormControl(''),
      "step12":new FormControl(''),
      "step13":new FormControl(''),
      "scenario":new FormControl('Scenario1'),
      "leftCharging":new FormControl(false),
      "leftBluetooth":new FormControl(false),
      "leftServicing":new FormControl(false),
      "rightCharging":new FormControl(false),
      "rightBluetooth":new FormControl(false),
      "rightServicing":new FormControl(false),
      "leftDeviceRefId":new FormControl(),
      "rightDeviceRefId":new FormControl(),
      "companyRefId":new FormControl('',Validators.required),
      "companyName":new FormControl(''),
      "canNumber":new FormControl(''),
      "createdAt": new Date(),
      "patientName": new FormControl(''),
    });
    let ss = this.repairDashboardForm.get('scenario').value;
    this.fetchRepairList();
 //   this.fetchStepTrackerlist(ss);
    this.getDoctorList();
    this.fetchModelList();
    this.fetchModelList2();
    this.fetchAllCompanies();
   this.getLastId();
   this.route.queryParams.forEach(params => {
    this.pId = params.PatientId;
    //
    localStorage.setItem("fromGlobal",'false');
    this.patientRef = this.pId;
    this.fetchRepairList();
    this.getPatient(this.patientRef);
  });
  this.fetchPatientList(); 
  if(this.userType == 'Super Admin'){
    this.fetchBranchList();
    this.getAllBranch();
  }
  this.fetchModelList();
  } 
  getPatient(id){
    this.apimicroservice.getPatientById(id).subscribe(res => { 
        this.pName =res.data[0].firstName + ' ' + res.data[0].middleName + ' ' + res.data[0].lastName;

     });
  }
  getAllBranch()
  {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      if (res.status) {        
        let length = res.data.length - 1;
        this.branchName= res.data[length].branchName;
        this.branchRefId= res.data[length]._id; 
        this.branchNamee =res.data[length].branchName;

        this.repairDashboardForm.controls["branchRefId"].setValue(res.data[length]._id);
        this.branchClick(res.data[length]._id,this.branchName);
        if(this.userType == 'Super Admin')
        {  
          this.apimicroservice.getBranchById(res.data[length]._id).subscribe(res => {
            this.spinner.hide();
            this.repairDashboardForm.get("branchName").setValue(res.data[length].branchName);
           });
        }
        if(this.repair_branchRefId !== '' && this.repair_patientRefId !== '')
        {
         this.repairDashboardForm.controls["branchRefId"].setValue(this.repair_branchRefId);
         this.repairDashboardForm.controls["patientRefId"].setValue(this.repair_patientRefId);
         this.branchClick(this.repair_branchRefId,this.repair_branchName);  
         this.onSelectPatient(this.repair_patientRefId);
         sessionStorage.setItem('repair_branchRefId','');
         sessionStorage.setItem('repair_patientRefId','');
         sessionStorage.setItem('repair_branchName','');
        }
       
      }
    });
  }
  fetchStepTrackerlist(sname){
    this.apimicroservice.getRepairStatusFlowByScenario(sname).subscribe(res => {
      this.statusArr = [];
      if(res.data.length > 0){
        if(res.data[0].stepperInfo.length > 0){
          this.statusArr = res.data[0].stepperInfo;
          //
          
          //this.stepperRefId =  res.data[0]._id;
          this.norecord = true;
        }
      }
    })
  }
  showTracker(status){
    let ss = "";
    ss = status;
     //
     
     for(let i=0;i<this.statusArr.length;i++){
       if(ss.indexOf(this.statusArr[i].labeltwo) > -1 ){
              // this.statusArr[i].status = 'completed';
               this.matchId = this.statusArr[i].id;
       }
     }
     for(let i=0;i<=this.matchId;i++){
       this.statusArr[i].status = 'completed';
     }
      let j = this.matchId + 1;
      this.statusArr[j].status = "active";
      //
   }

   fetchPatientList(){
    this.searchText = '';
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    //
    if(this.userType !== 'Super Admin')
    {
      this.apimicroservice.getPatientListByBranchClinic(branchRef,clinicRef).subscribe(res => {
        //
        
        if (res.status) {    
          this.patientList = res.data;
          this.filteredListPatient = this.patientList.slice();
        } else {
         // this.apimicroservice.openSnackbar(res.message);
        }
      })
    }
    else
    {
      this.apimicroservice.getPatientListByBranchClinic(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
        //
         
        if (res.status) {    
          this.patientList = res.data;
          this.filteredListPatient = this.patientList.slice();
        } else {
         // this.apimicroservice.openSnackbar(res.message);
        }
      })
   }
   
  }

  submitRepairList() {
    let payload = this.repairDashboardForm.value;
    if(this.userType == 'Super Admin')
    {
      this.repairDashboardForm.controls['branchRefId'].setValidators(Validators.required);
      this.repairDashboardForm.controls['branchRefId'].updateValueAndValidity();
    }
    else{
      this.repairDashboardForm.controls['branchRefId'].setErrors(null);
      this.repairDashboardForm.controls['branchRefId'].clearValidators();
      this.repairDashboardForm.controls['branchRefId'].updateValueAndValidity();
    }
    if(payload.leftModel == '' &&  payload.rightModel == ''){
      this.repairDashboardForm.controls['leftModel'].setValidators(Validators.required);
      this.repairDashboardForm.controls['leftModel'].updateValueAndValidity();
      this.repairDashboardForm.controls['leftSrNo'].setValidators([Validators.required, Validators.pattern("^[a-zA-Z0-9 -]+$")]);
      this.repairDashboardForm.controls['leftSrNo'].updateValueAndValidity();
      // this.repairDashboardForm.controls['rightModel'].setValidators(Validators.required);
      // this.repairDashboardForm.controls['rightModel'].updateValueAndValidity();
      this.repairDashboardForm.controls['rightSrNo'].setValidators([ Validators.pattern("^[a-zA-Z0-9 -]+$")]);
      this.repairDashboardForm.controls['rightSrNo'].updateValueAndValidity();
    }else {
      this.repairDashboardForm.controls['leftModel'].setErrors(null);
      this.repairDashboardForm.controls['leftModel'].clearValidators();
      this.repairDashboardForm.controls['leftModel'].updateValueAndValidity();
      this.repairDashboardForm.controls['leftSrNo'].setErrors(null);
    this.repairDashboardForm.controls['leftSrNo'].clearValidators();
    this.repairDashboardForm.controls['leftSrNo'].updateValueAndValidity();
      // this.repairDashboardForm.controls['rightModel'].setErrors(null);
      // this.repairDashboardForm.controls['rightModel'].clearValidators();
      // this.repairDashboardForm.controls['rightModel'].updateValueAndValidity();
      this.repairDashboardForm.controls['rightSrNo'].setErrors(null);
    this.repairDashboardForm.controls['rightSrNo'].clearValidators();
    this.repairDashboardForm.controls['rightSrNo'].updateValueAndValidity();
    }
    if (this.repairDashboardForm.invalid) {

       this.repairDashboardForm.get("patientRefId").markAsTouched();
       this.repairDashboardForm.get("companyRefId").markAsTouched();
       this.repairDashboardForm.get("leftModel").markAsTouched();
       this.repairDashboardForm.get("leftSrNo").markAsTouched();
      

    }else {
      this.addRepairList();
    }
    
  }

  getDoctorList(){
    
    this.apimicroservice.getLastDoctorAll().subscribe(res => {
      //
      this.listOfdoctor = res['data']['data'];
      this.filteredListDoctor = this.listOfdoctor.slice();
      //
    })
  }

  addRepairList(){
    // this.spinner.show();
    this.spinner.show();
    let ss = '';
    let payload = this.repairDashboardForm.value;
    payload.companyName = this.companyName;
    payload.canNumber = this.canNo;
    // ss = payload.status;
    // for(let i=0;i<this.statusArr.length;i++){
    //   if(ss.indexOf(this.statusArr[i].labeltwo) > -1 ){
    //           this.statusArr[i].status = 'completed';
    //           this.statusArr[i].repairDate = this.repairD;
    //   }
    // }
    // payload.repairStatusInfo = this.statusArr;
    payload.repairDate = this.repairD;
    //
    
    if(this.userType !== 'Super Admin')
    {
      payload.branchRefId = this.branchRef;
      payload.clinicRefId = this.clinicRef;
      payload.branchName = this.branchName;

    }
    this.apimicroservice.submitRepairData(payload).subscribe(res => {
      //
     this.spinner.hide();
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);
        let sspayload = {};
        sspayload['repairRefId'] = res.data.data._id;
        sspayload['repairId'] = res.data.data.repairId;
        this.toRepairForm();
        this.ngOnInit();
    // this.apimicroservice.updateRepairStatusFlow(this.stepRefId,sspayload).subscribe(res => {
    //   //
    //   this.ngOnInit();
    // });
            
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    });
  }
  

  updateRepairComplaint() {
    if(this.userType == 'Super Admin')
    {
      this.repairDashboardForm.controls['branchRefId'].setValidators(Validators.required);
      this.repairDashboardForm.controls['branchRefId'].updateValueAndValidity();
    }
    else{
      this.repairDashboardForm.controls['branchRefId'].setErrors(null);
      this.repairDashboardForm.controls['branchRefId'].clearValidators();
      this.repairDashboardForm.controls['branchRefId'].updateValueAndValidity();
    }
    if (this.repairDashboardForm.invalid) {
      // this.repairDashboardForm.get("patientRefId").markAsTouched();
    
    
      return;
    } else {
    this.update();
    }
  }

  update() {
    // this.spinner.show();
    let ss = '';
    let payload = this.repairDashboardForm.value;
    payload.companyName = this.companyName;
    payload.canNumber = this.canNo;
    //ss = payload.status;
    // for(let i=0;i<this.statusArr.length;i++){
    //   if(ss.indexOf(this.statusArr[i].labeltwo) > -1 ){
    //           this.statusArr[i].status = 'completed';
    //           this.statusArr[i].repairDate = this.repairD; 
    //   }
    // }
    // payload.repairStatusInfo = this.statusArr;
    payload.repairDate = this.repairD;
    //
    if(this.userType !== 'Super Admin')
    {
      payload.branchRefId = this.branchRef;
      payload.clinicRefId = this.clinicRef;
      payload.branchName = this.sessionBranchName;
    }
   //  payload.uuid_owner = 1;
     //
     this.apimicroservice.updateRepairData(this.repairId,payload).subscribe(res => {
       //
       this.toRepairForm();
      this.ngOnInit();
       if(res.status){
         this.apimicroservice.openSnackbar(res.message);
        
          this.updateButton = false;
          this.submitButton = true;
          this.cancelButton = false;
          this.ngOnInit();
         
       }else{
       //  //this.apimicroservice.openSnackbar(res.message);
       }
     });
    
  }
 
  fetchRepairList() {
    let pg = 1;
    let size = 5;
    let search=''; 
 //   this.spinner.show();
 let clinicRef= sessionStorage.getItem('clinicRef');
 let branchRef= sessionStorage.getItem('branchRef');
 let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
 let branchRefSuperAdmin= '';
    let patientRef='';
    this.searchText = this.repairDashboardForm.get('searchRepair').value;

    // if(this.userType !== 'Super Admin')
    //  {
      this.apimicroservice.getRepairDashboardData(pg,size,search,branchRef,patientRef).subscribe(res => {
        //
        this.listOfRepair = res.data.data;
        this.totalLength = res.data.totalItems;
        
        
        //this.buildingLenght = this.listofbuild.length;
       if(res.status){
        //  this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
      });
    //  }else{
    //   this.apimicroservice.getRepairDashboardData(pg,size,this.searchText,branchRefSuperAdmin,this.patientRef,clinicRefSuperAdmin).subscribe(res => {
    //     //
    //     this.listOfRepair = res.data.data;
    //     this.totalLength = res.data.totalItems;
    //     //
    //     //this.buildingLenght = this.listofbuild.length;
    //    if(res.status){
    //     // this.apimicroservice.openSnackbar(res.message);
         
    //    }else{
    //      //this.apimicroservice.openSnackbar(res.message);
    //    }
    //   });
    //  }
     
    


 
  }
  // fetchPatientList() {
  //   this.searchText = '';
  //   this.apimicroservice.getPatientList(this.page5, this.size5, this.searchText,this.branchRef,this.clinicRef).subscribe(res => {
  //     //
      
  //     if (res.status) {    
  //       this.patientList = res.data.data;
  //     } else {
  //       this.apimicroservice.openSnackbar(res.message);
  //     }
  //   })
  // }
  // branchClick(id,branchName)
  // {
  //   this.repairDashboardForm.controls["branchName"].setValue(branchName);
  //   this.branchRef = id;
  //   this.searchText = '';
  //   this.apimicroservice.getPatientList(this.page5, this.size5, this.searchText,this.branchRef,this.clinicRef).subscribe(res => {
  //     //
      
  //     if (res.status) {    
  //       this.patientList = res.data.data;
  //     } else {
  //       this.apimicroservice.openSnackbar(res.message);
  //     }
  //   })
  // }
  onSelectPatient(id){
    //
    this.patientSelect=true;
    this.apimicroservice.getPatientById(id).subscribe(res => {
      let data = res.data[0];
      this.pid = id;

      this.repairDashboardForm.controls["firstName"].setValue(data.firstName);
      this.repairDashboardForm.controls["lastName"].setValue(data.lastName);
      this.repairDashboardForm.controls["sex"].setValue(data.sex);
      this.repairDashboardForm.controls["mobile1"].setValue(data.mobile1);
      this.repairDashboardForm.controls["streetAddress"].setValue(data.streetAddress);

    });

    // //
    // this.patientFirstName = patient.firstName;
    // //
    
    // this.patientLastName = patient.lastName;
    // this.patientGender = patient.sex;
    // this.patientMobileNo = patient.mobile1;
    // this.patientAddress = patient.streetAddress;
    
  }
  
  // onSelectDoctor(id){
  //   this.doctorSelect=true;
  //   //
  //   this.apimicroservice.getDoctorById(id).subscribe(res => {
  //     let data=res.data[0];
  //     this.did = data._id;

  //     // this.repairDashboardForm.controls["doctorClinicName"].setValue(data.branchName);
  //     this.repairDashboardForm.controls["doctorBranchName"].setValue(data.branchName);
  //     this.repairDashboardForm.controls["doctorMobileNo"].setValue(data.mobile1);
  //     this.repairDashboardForm.controls["doctorAddress"].setValue(data.streetAddress);
        
  //   }); 
  // }


  prepopulation(id) {
    // this.spinner.show();
    this.showstepper = false 
    this.apimicroservice.getRepairDataById(id).subscribe(res => {
      //
      let data = res.data[0];
      this.repairId = id;
      this.repairRefId = data._id;
      this.myrepairId = data.repairId;
      
         this.updateButton = true;
         this.submitButton = false;
         this.cancelButton = true;
         this.scenariodisable = true;
         this.branchClick(data.branchRefId,data.branchName);
         this.onSelectPatient(data.patientRefId);
        
        //  this.onSelectDoctor(data.doctorRefId);
     this.repairDashboardForm = this.fb.group({

      "branchWiseId":new FormControl(data.branchWiseId),
      "status":new FormControl(data.status),
      "firstName": new FormControl(data.firstName),
      "lastName": new FormControl(data.lastName),
      "sex": new FormControl(data.sex),
      "mobile1": new FormControl(data.mobile1),
      "streetAddress": new FormControl(data.streetAddress),
      "clinicName": new FormControl(data.clinicName),
     

      // "doctorBranchName": new FormControl(data.doctorBranchName),
      // "doctorMobileNo": new FormControl(data.doctorMobileNo),
      // "doctorAddress": new FormControl(data.doctorAddress),

      "patientRefId":new FormControl(data.patientRefId,Validators.required),
      // "doctorRefId":new FormControl(data.doctorRefId),
      "branchRefId":new FormControl(data.branchRefId),
      // "branchWiseId":new FormControl(data.branchWiseId),
      "branchName":new FormControl(data.branchName),
     
      "leftModel":new FormControl(data.leftModel,Validators.required),
      "rightModel":new FormControl(data.rightModel),
      "leftSrNo":new FormControl(data.leftSrNo,[Validators.required, Validators.pattern("^[a-zA-Z0-9 -]+$")]),
      "rightSrNo":new FormControl(data.rightSrNo,[Validators.pattern("^[a-zA-Z0-9 -]+$")]),
      "leftDead":new FormControl(data.leftDead),
      "rightDead":new FormControl(data.rightDead),
      "leftNoisy":new FormControl(data.leftNoisy),
      "rightNoisy":new FormControl(data.rightNoisy),
      "leftDistortion":new FormControl(data.leftDistortion),
      "rightDistortion":new FormControl(data.rightDistortion),
      "leftFeedback":new FormControl(data.leftFeedback),
      "rightFeedback":new FormControl(data.rightFeedback),
      "leftConnectivityIssue":new FormControl(data.leftConnectivityIssue),
      "rightConnectivityIssue":new FormControl(data.rightConnectivityIssue),
      "rightComplaintInterpretation":new FormControl(data.rightComplaintInterpretation),
      "leftTightFit":new FormControl(data.leftTightFit),

      "rightTightFit":new FormControl(data.rightTightFit),  
      "leftLooseFit":new FormControl(data.leftLooseFit),
      "rightLooseFit":new FormControl(data.rightLooseFit),
      "leftOtherShellIssue":new FormControl(data.leftOtherShellIssue),
      "rightOtherShellIssue":new FormControl(data.rightOtherShellIssue),
      "leftOther":new FormControl(data.leftOther),
      "rightOther":new FormControl(data.rightOther),
      "leftAnyPhysicalDamage":new FormControl(data.leftAnyPhysicalDamage),
      "rightAnyPhysicalDamage":new FormControl(data.rightAnyPhysicalDamage),
      "leftVisibleCorrorsion":new FormControl(data.leftVisibleCorrorsion),
      "rightVisibleCorrorsion":new FormControl(data.rightVisibleCorrorsion),
      "leftBatteryLeakageVisible":new FormControl(data.leftBatteryLeakageVisible),
      "rightBatteryLeakageVisible":new FormControl(data.rightBatteryLeakageVisible),
      "leftMouldIncluded":new FormControl(data.leftMouldIncluded),
      "rightMouldIncluded":new FormControl(data.rightMouldIncluded),
      "leftMouldDamaged":new FormControl(data.leftMouldDamaged),
      "rightMouldDamaged":new FormControl(data.rightMouldDamaged),
      "leftWarranty":new FormControl(data.leftWarranty),
      "rightWarranty":new FormControl(data.rightWarranty),
      "leftDateOfPurchase":new FormControl(data.leftDateOfPurchase),
      "rightDateOfPurchase":new FormControl(data.rightDateOfPurchase),
      "leftRepairQuotation":new FormControl(data.leftRepairQuotation),
      "rightRepairQuotation":new FormControl(data.rightRepairQuotation),
      "leftPastRepairRef":new FormControl(data.leftPastRepairRef),
      "rightPastRepairRef":new FormControl(data.rightPastRepairRef),
      "leftOtherRemark":new FormControl(data.leftOtherRemark),
      "rightOtherRemark":new FormControl(data.rightOtherRemark),
      "leftAcessoriesIncluded":new FormControl(data.leftAcessoriesIncluded),
      "rightAcessoriesIncluded":new FormControl(data.rightAcessoriesIncluded),
      "scenario":new FormControl(data.scenario),
      "searchRepair": new FormControl(''),
      "step1":new FormControl(''),
      "step2":new FormControl(''),
      "step3":new FormControl(''),
      "step4":new FormControl(''),
      "step5":new FormControl(''),
      "step6":new FormControl(''),
      "step7":new FormControl(''),
      "step8":new FormControl(''),
      "step9":new FormControl(''),
      "step10":new FormControl(''),
      "step11":new FormControl(''),
      "step12":new FormControl(''),
      "step13":new FormControl(''),
      "updatedAt": new Date(),
      "leftCharging":new FormControl(data.leftCharging),
      "leftBluetooth":new FormControl(data.leftBluetooth),
      "leftServicing":new FormControl(data.leftServicing),
      "rightCharging":new FormControl(data.rightCharging),
      "rightBluetooth":new FormControl(data.rightBluetooth),
      "rightServicing":new FormControl(data.rightServicing),

      "leftDeviceRefId":new FormControl(data.leftDeviceRefId),
      "rightDeviceRefId":new FormControl(data.rightDeviceRefId),
      "companyRefId":new FormControl(data.companyRefId,Validators.required),
      "companyName":new FormControl(data.companyName),
      "canNumber":new FormControl(data.canNumber),
      "patientName": new FormControl(data.firstName + ' ' + data.lastName),
    });
    this.superBranchId = data.branchWiseId;
    //
    
    //this.statusArr = data.repairStatusInfo;

    //this.showTracker(data.status);
       
     this.fetchStatusByID(id);
   //  this.onRightModelChange(event);
     // this.onLeftModelChange($event);
     });
   
  }
  addNew() {
    this.repairDashboardForm.reset();
    this.updateButton = false;
    this.deleteButton = false;
  }
 
 
  onPageChange(event) {
    // this.spinner.show();
    this.page = event;
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    let patientRef='';
    
     this.searchText = this.repairDashboardForm.get('searchRepair').value;
       
    //  if(this.userType !== 'Super Admin')
    //  {
      this.apimicroservice.getRepairDashboardData(this.page,this.size,this.searchText,branchRef,this.patientRef).subscribe(res => {
        //
        this.listOfRepair = res.data.data;
        this.totalLength = res.data.totalItems;
        
       if(res.status){
       //  this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
  
     });
    //  }else{
    //   this.apimicroservice.getRepairDashboardData(this.page,this.size,this.searchText,branchRefSuperAdmin,this.patientRef,clinicRefSuperAdmin).subscribe(res => {
    //     //
    //     this.listOfRepair = res.data.data;
    //     this.totalLength = res.data.totalItems;
    
        
    //    if(res.status){
    //   //   this.apimicroservice.openSnackbar(res.message);
         
    //    }else{
    //      //this.apimicroservice.openSnackbar(res.message);
    //    }
  
    //  });
    //  }

     

  }

  getLastId()
  {
    if(this.userType !== 'Super Admin'){
      this.apimicroservice.getLastRepairID(this.branchRef).subscribe(res => {
        //   
       
        if (res['status']== true) {
          let repairIdd = res['data']['totalItems'];
          //
          
          this.repairDashboardForm.controls['branchWiseId'].setValue(repairIdd + 1);
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
       
      })
    }else{
      let branchRef= sessionStorage.getItem('branchRef');
      this.apimicroservice.getLastRepairAll(branchRef).subscribe(res => {
        //   
        
        if (res['status'] == true) {
          
          let repairIdd = res['data']['totalItems'];
          this.superBranchId = repairIdd + 1;
          // this.repairDashboardForm.controls['branchWiseId'].setValue( this.superBranchId );
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
       
      })
    }
    
  }

  branchClick(id,branchName){

     this.repairDashboardForm.controls["branchName"].setValue(branchName);
    this.branchRef = id;
    this.searchText = '';
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if(this.userType == 'Super Admin')
    {
    this.apimicroservice.getPatientList(this.page5,this.size5,this.searchText,branchRef,clinicRef).subscribe(res => {
      //
      
      if (res.status) {    
        this.patientList = res.data.data;
        this.filteredListPatient = this.patientList.slice();
        // this.onSelectPatient(res.data.data[0]._id);
        // this.repairDashboardForm.get("patientRefId").setValue(res.data.data[0]._id);
        
      } else {
      //  this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
  else
  {
    this.apimicroservice.getPatientList(this.page5,this.size5,this.searchText,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
      //
      
      if (res.status) {    
        this.patientList = res.data.data;
        this.filteredListPatient = this.patientList.slice();
        // this.onSelectPatient(res.data.data[0]._id);
        // this.repairDashboardForm.get("patientRefId").setValue(res.data.data[0]._id);
        
      } else {
      //  this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
    

    this.apimicroservice.getBranchById(id).subscribe(res => { 
      //
        
      let data = res.data[0];
      let payload = this.repairDashboardForm.value;
       payload.clinicRefId = res.data[0].clinicRefId;
      //  this.repairDashboardForm.controls["clinicRefId"].setValue(res.data[0].clinicRefId);
       if(this.updateButton == false){
        //  this.spinner.show();
         this.apimicroservice.getLastRepairID(payload.branchRefId).subscribe(res => {
             
          
           if (res['status']) {
             let repairIdd = res['data']['totalItems'];
             this.repairDashboardForm.controls['branchWiseId'].setValue(repairIdd + 1);
           } else {
             this.apimicroservice.openSnackbar("Something went wrong");
           }
          //  this.spinner.hide();
         })
       }
     });

  }

  onKeyUpEvent(event: any) {
    
    this.searchText = this.repairDashboardForm.get('searchRepair').value;
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    let patientRef='';
    // if(this.userType !== 'Super Admin')
    // {
      this.apimicroservice.getRepairDashboardData(this.page,this.size,this.searchText,branchRef,this.patientRef).subscribe(res => {
        //
        this.listOfRepair = res.data.data;
        this.totalLength = res.data.totalItems; 
       if(res.status){
       //  this.apimicroservice.openSnackbar(res.message); 
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
    // }else{
    //   this.apimicroservice.getRepairDashboardData(this.page,this.size,this.searchText,branchRefSuperAdmin,this.patientRef,clinicRefSuperAdmin).subscribe(res => {
    //     //
    //     this.listOfRepair = res.data.data;
    //     this.totalLength = res.data.totalItems; 
    //    if(res.status){
    //    //  this.apimicroservice.openSnackbar(res.message); 
    //    }else{
    //      //this.apimicroservice.openSnackbar(res.message);
    //    }
    //  });
    // }
    
   
  }
  redirect(to): void {
    this.router.navigate([to]);
  }
  

toRepairForm() {
  document.getElementById("toRepairForm").scrollIntoView();
}
toRepairList() {
  document.getElementById("toRepairList").scrollIntoView();
}
fetchBranchList() {
  let clinicRef= sessionStorage.getItem('clinicRef');
  this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
    if (res.status) {    
      this.BranchList = res.data;
      this.filteredListBranch= this.BranchList.slice();
    } else {
    //  this.apimicroservice.openSnackbar(res.message);
    }
  })
} 
fetchModelList() {
  let branchRef= sessionStorage.getItem('branchRef');
  if(this.userType !== 'Super Admin')
  {
    this.apimicroservice.getAllDeviceList(branchRef).subscribe(res => {
      if (res.status) {    
        this.DeviceList = res.data.data;
        this.filteredListDevice= this.DeviceList.slice();
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
  else 
  {
    this.apimicroservice.getAllDeviceList(branchRef).subscribe(res => {
      if (res.status) {    
        this.DeviceList = res.data.data;
        this.filteredListDevice= this.DeviceList.slice();
      } else {
      // this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
 
} 
fetchModelList2() {
  let branchRef= sessionStorage.getItem('branchRef');
  if(this.userType !== 'Super Admin')
  {
    this.apimicroservice.getAllDeviceList(branchRef).subscribe(res => {
      if (res.status) {    
        this.DeviceList2 = res.data.data;
        this.filteredListDevice2= this.DeviceList2.slice();
      } else {
       // this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
  else
  {
    this.apimicroservice.getAllDeviceList(branchRef).subscribe(res => {
      if (res.status) {    
        this.DeviceList2 = res.data.data;
        this.filteredListDevice2= this.DeviceList2.slice();
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
 
}
fetchStatusByID(id){
  this.apimicroservice.getRepairStatusFlowById(id).subscribe(res => {

    if(res.data.length > 0){
      if(res.data[0].stepperInfo.length > 0){
        this.statusArr = res.data[0].stepperInfo;
        this.stepperRefId =  res.data[0]._id;
        this.norecord = false;
      }
    }else{
      this.norecord = true
      this.radioChange({value:this.repairDashboardForm.get('scenario').value});
     
    }
  })
}

  radioChange(event:any){
    //
    let ss = event.value;
    // this.fetchStepTrackerlist(ss);
    this.statusArr = []; 
    if(event.value == "Scenario1"){
      this.statusArr = [
        {
          "labelOne": "Step 1",
          "datetime": "",
          "labeltwo": "Aid Received for repairs",
          "button": true,
          "controlname": "step1",
          "comments": "",
          "placeholderText":"Items collected from patient-Wires,Charger etc.",
          "status":"In-Progress",
          "_id": "6243002ae53ff64495b22797"
        },
        {
          "labelOne": "Step 2",
          "datetime": "",
          "labeltwo": "Job Receipt Issued​",
           "button": true,
          "controlname": "step2",
          "comments": "",
          "placeholderText":"Job Id generated by system & receipt given to",
          "status":"Pending",
          "_id": "6243002ae53ff64495b22798"
        },
        {
          "labelOne": "Step 3",
          "datetime": "",
          "labeltwo": "Sent to Manufacturer​",
           "button": true,
          "controlname": "step3",
          "comments": "",
          "placeholderText":"Courier Details-Tracking Id,Date etc.",
          "status":"Pending",
          "_id": "6243002ae53ff64495b22799"
        },
        {
          "labelOne": "Step 4",
          "datetime": "",
          "labeltwo": "Repair Estimate is Received from Company​",
           "button": true,
          "controlname": "step4",
          "comments": "",
          "placeholderText":"Status updated with estimates received",
          "status":"Pending",
          "_id": "6243002ae53ff64495b2279a"
        },
        {
          "labelOne": "Step 5",
          "datetime": "",
          "labeltwo": "Estimate to Client is calculated​",
           "button": true,
          "controlname": "step5",
          "comments": "",
          "placeholderText":"System calculates the charges to convey to patient",
          "status":"Pending",
          "_id": "6243002ae53ff64495b2279b"
        },
        {
          "labelOne": "Step 6",
          "datetime": "",
          "labeltwo": "Estimate is Advised by call/SMS/WA​",
           "button": true,
          "controlname": "step6",
          "comments": "",
          "placeholderText":"Informed patient via Email,SMS or Phone",
          "status":"Pending",
          "_id": "6243002ae53ff64495b2279c"
        },
        {
          "labelOne": "Step 7",
          "datetime": "",
          "labeltwo": "Payment is Received from Client​",
           "button": true,
          "controlname": "step7",
          "comments": "",
          "placeholderText":"Status updated with confirmation received from",
          "status":"Pending",
          "_id": "6243002ae53ff64495b2279d"
        },
        {
          "labelOne": "Step 8",
          "datetime": "",
          "labeltwo": "Estimate is Approved with the company on Email",
           "button": true,
          "controlname": "step8",
          "comments": "",
          "placeholderText":"Informed company via Email,SMS or Phone",
          "status":"Pending",
          "_id": "6243002ae53ff64495b2279e"
        },
        {
          "labelOne": "Step 9",
          "datetime": "",
          "labeltwo": "Device is repaired and Sent back by the company​",
           "button": true,
          "controlname": "step9",
          "comments": "",
          "placeholderText":"Courier details -tracking Id,Date etc.",
          "status":"Pending",
          "_id": "6243002ae53ff64495b2279f"
        },
        {
          "labelOne": "Step 10",
          "datetime": "",
          "labeltwo": "Received by NHS​",
           "button": true,
          "controlname": "step10",
          "comments": "",
          "placeholderText":"Status updated as HA received after Repair",
          "status":"Pending",
          "_id": "6243002ae53ff64495b227a0"
        },
        {
          "labelOne": "Step 11",
          "datetime": "",
          "labeltwo": "Checked and Programmed by audiologist​​",
           "button": true,
          "controlname": "step11",
          "comments": "",
          "placeholderText":"Repair verified & necessary steps taken by doctor",
          "status":"Pending",
          "_id": "6243002ae53ff64495b227a1"
        },
        {
          "labelOne": "Step 12",
          "datetime": "",
          "labeltwo": "Client is advised to collect it or Appointment is arranged​",
           "button": true,
          "controlname": "step12",
          "comments": "",
          "placeholderText":"Repair status informed to patient via Email,SMS or Phone",
          "status":"Pending",
          "_id": "6243002ae53ff64495b227a2"
        },
        {
          "labelOne": "Step 13",
          "datetime": "",
          "labeltwo": "Or Sent back by Courier​",
           "button": true,
          "controlname": "step13",
          "comments": "",
          "placeholderText":"Courier Details-Tracking Id,Date",
          "status":"Pending",
          "_id": "6243002ae53ff64495b227a3"
        }
      ];
    }else if (ss == "Scenario2"){
      this.statusArr = [
        {
          "labelOne": "Step 1",
          "datetime": "",
          "labeltwo": "Aid Received for repairs",
          "button": true,
          "controlname": "step1",
          "comments": "",
          "placeholderText":"Items collected from patient-Wires,Charger etc.",
          "status":"In-Progress",
          "_id": "6243002ae53ff64495b22797"
        },
        {
          "labelOne": "Step 2",
          "datetime": "",
          "labeltwo": "Job Receipt Issued​",
           "button": true,
          "controlname": "step2",
          "comments": "",
          "placeholderText":"Job Id generated by system & receipt given to",
          "status":"Pending",
          "_id": "6243002ae53ff64495b22798"
        },
        {
          "labelOne": "Step 3",
          "datetime": "",
          "labeltwo": "Estimate is given to the client",
           "button": true,
          "controlname": "step3",
          "comments": "",
          "placeholderText":"System calculates the charges to convey to patient",
          "status":"Pending",
          "_id": "6243002ae53ff64495b22799"
        },
        {
          "labelOne": "Step 4",
          "datetime": "",
          "labeltwo": "Payment is Received from Client​",
           "button": true,
           "controlname": "step4",
          "comments": "",
          "placeholderText":"Status updated with confirmation received from",
          "status":"Pending",
          "_id": "6243002ae53ff64495b2279a"
        },
        {
          "labelOne": "Step 5",
          "datetime": "",
          "labeltwo": "Estimate is Approved with the company on Email",
           "button": true,
          "controlname": "step5",
          "comments": "",
          "placeholderText":"Informed company via Email,SMS or Phone",
          "status":"Pending",
          "_id": "6243002ae53ff64495b2279e"
        },
        {
          "labelOne": "Step 6",
          "datetime": "",
          "labeltwo": "Sent to Manufacture​",
           "button": true,
          "controlname": "step6",
          "comments": "",
          "placeholderText":"Courier details -tracking Id,Date etc.",
          "status":"Pending",
          "_id": "6243002ae53ff64495b2279c"
        },
        {
          "labelOne": "Step 7",
          "datetime": "",
          "labeltwo": "Device is repaired and Sent back by the company​",
           "button": true,
          "controlname": "step7",
          "comments": "",
          "placeholderText":"Courier details -tracking Id,Date etc.",
          "status":"Pending",
          "_id": "6243002ae53ff64495b2279f"
        },
        {
          "labelOne": "Step 8",
          "datetime": "",
          "labeltwo": "Received by NHS​",
           "button": true,
          "controlname": "step8",
          "comments": "",
          "placeholderText":"Status updated as HA received after Repair",
          "status":"Pending",
          "_id": "6243002ae53ff64495b227a0"
        },
        {
          "labelOne": "Step 9",
          "datetime": "",
          "labeltwo": "Checked and Programmed by audiologist​​",
           "button": true,
          "controlname": "step9",
          "comments": "",
          "placeholderText":"Repair verified & necessary steps taken by doctor",
          "status":"Pending",
          "_id": "6243002ae53ff64495b227a1"
        },
        {
          "labelOne": "Step 10",
          "datetime": "",
          "labeltwo": "Client is advised to collect it or Appointment is arranged​",
           "button": true,
          "controlname": "step10",
          "comments": "",
          "placeholderText":"Repair status informed to patient via Email,SMS or Phone",
          "status":"Pending",
          "_id": "6243002ae53ff64495b227a2"
        },
        {
          "labelOne": "Step 11",
          "datetime": "",
          "labeltwo": "Or Sent back by Courier​",
           "button": true,
          "controlname": "step11",
          "comments": "",
          "placeholderText":"Courier Details-Tracking Id,Date",
          "status":"Pending",
          "_id": "6243002ae53ff64495b227a3"
        }
      ];
    }
  }

  fetchAllCompanies(){
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
   this.apimicroservice.getAllCompanylist(branchRef,clinicRef).subscribe(res => {
     //
      if (res.status) {    
        this.companyList = res.data.data;
        this.filteredListCompany= this.companyList.slice();
        //  this.onSelectCompany(res.data.data[0].companyName,res.data.data[0].canNumber);
        
        // this.repairDashboardForm.get("companyRefId").setValue(res.data.data[0]._id);
      } else {
      //  this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
  onSelectCompany(cname,canno){
  //
  //
  this.companyName = cname;
  this.canNo = canno;
  this.repairDashboardForm.controls['canNumber'].setValue(this.canNo);
  } 
   openBranchPopUp() {
    
    const dialogRef3 = this.dialog.open(PopUpBranchComponent,
      {
        width: "1200px",
      }); 
      dialogRef3.afterClosed().subscribe((result) => {
        let clinicRef= sessionStorage.getItem('clinicRef');
        this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
          if (res.status) {    
            this.BranchList = res.data;
            this.filteredListBranch= this.BranchList.slice();
            this.branchClick(res.data[0]._id,res.data[0].branchName);
            this.repairDashboardForm.get("branchRefId").setValue(res.data[0]._id);
          } 
        })
      
        //this.fetchBranchList();
       
       
      })
  }
  openPatientPopUp() {
    
    const dialogRef3 = this.dialog.open(PopUpPatientComponent,
      {
        width: "1200px",
      });
      dialogRef3.afterClosed().subscribe((result) => {
       // this.fetchPatientList();  
       let clinicRef= sessionStorage.getItem('clinicRef');
       let branchRef= sessionStorage.getItem('branchRef');
       let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
       let branchRefSuperAdmin= '';
       if (this.userType !== 'Super Admin') {
       this.apimicroservice.getPatientListByBranchClinic(branchRef,clinicRef).subscribe(res => {
        //
        
        if (res.status) {    
          this.patientList = res.data;
          this.filteredListPatient = this.patientList.slice();
          this.onSelectPatient(this.patientList[0]._id);
        this.repairDashboardForm.get("patientRefId").setValue(this.patientList[0]._id);
        } else {
         // this.apimicroservice.openSnackbar(res.message);
        }
      })
    }else{
      this.apimicroservice.getPatientListByBranchClinic(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
        //
        
        if (res.status) {    
          this.patientList = res.data;
          this.filteredListPatient = this.patientList.slice();
          this.onSelectPatient(this.patientList[0]._id);
        this.repairDashboardForm.get("patientRefId").setValue(this.patientList[0]._id);
        } else {
         // this.apimicroservice.openSnackbar(res.message);
        }
      })
    }
       });
  }
  
  openCompanyPopUp(){
    const dialogRef3 = this.dialog.open(PopupCompanyComponent,
      {
        width: "900px",
      });

      dialogRef3.afterClosed().subscribe((result) => {
        let clinicRef= sessionStorage.getItem('clinicRef');
        let branchRef= sessionStorage.getItem('branchRef');
        this.apimicroservice.getAllCompanylist(branchRef,clinicRef).subscribe(res => {
          //
           if (res.status) {    
             this.companyList = res.data.data;
             this.filteredListCompany= this.companyList.slice();
              this.onSelectCompany(res.data.data[0].companyName,res.data.data[0].canNumber);
             
              this.repairDashboardForm.get("companyRefId").setValue(res.data.data[0]._id);
           } else {
           //  this.apimicroservice.openSnackbar(res.message);
           }
         })

       });
  }
  globalPrint() {
    let titleName='Repair Complaint Form.pdf'
      var tempTitle = document.title;
      document.title = titleName;
      window.print();
      document.title = tempTitle;
  }  
  WhatsApp()
  {
    let payload = this.repairDashboardForm.value;  
    let num = payload.mobile1; 
    if(num.length == 10)
    {
      sessionStorage.setItem('whatsAppNumber',payload.mobile1);
      let mobileNumber = sessionStorage.getItem('whatsAppNumber');
      window.open("https://wa.me/91"+mobileNumber);
    }
    else
    {
      this.repairDashboardForm.get("mobile1").markAsTouched();
    }
  }


  onback() {
    this.apimicroservice.back()
  }
  openDepartmentPopUp() {
    const dialogRef3 = this.dialog.open(PatientSearchPopComponent, {
      width: "97%",
      height: "90%", 
    });

    dialogRef3.afterClosed().subscribe((result) => {
      this.repairDashboardForm.get("patientName").setValue(result.data.firstName + ' ' + result.data.lastName);
      this.repairDashboardForm.get("patientRefId").setValue(result.data._id);
      this.onSelectPatient(result.data._id);
    
    });
  }
  redirectToRepairForm(repairNo){ 
    this.router.navigate(['repair-complaint',repairNo]);
  }
  redirectToRepair(billNo){
    this.router.navigate(['bill-form',billNo]);
  } 
  repairClick(branchRefId,patientRefId,branchName,patientName)
    {
     sessionStorage.setItem('repair_branchRefId',branchRefId);
     sessionStorage.setItem('repair_patientRefId',patientRefId);
     sessionStorage.setItem('repair_branchName',branchName);
     sessionStorage.setItem('repair_patientName',patientName);
     this.redirect('repair-complaint');  
    } 
}
