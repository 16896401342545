<div class="dialog-wrapper">
    <!-- <h3 mat-dialog-title>Change Password </h3> -->
    <mat-dialog-content>
      
      <div class="container">
        <form [formGroup]="whatsAppForm" autocomplete="off" novalidate>
        <div class="row">
            <div class="col-sm-11">
             
            </div> 
            <div class="col-sm-1 justify-content-end  p-0 m-0 ">
              <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                <a (click)="onClose()" matTooltip="Close" class="d-flex align-items-center pr-2 mt-2">
                  <span class="material-symbols-outlined pr-2">close</span></a>
                </div>
            </div>
              <div class="card p-4" >
                  <div class="col-sm-12 mt-0 pt-0">
                    <div class="row">
                        <div class="col-sm-4"></div>
                        <div class="col-sm-4 mb-3">
                        <img src="../../assets/whatsAppIcon.png" matTooltip="WhatsApp" width="500rem">
                        </div>
                        <div class="col-sm-4"></div>
                
          <div class="col-sm-12 pl-1 pr-1">
            <div class="row">
                <div class="col-sm-2 mt-3">
                    <label class="fontStyle text-right">+91</label>
                    </div>
                    <div class="col-sm-10">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Enter Number</mat-label>
              <input matInput placeholder="Mobile Name" formControlName="whatsAppNumber" maxlength="10" required />
             
             </mat-form-field>
             <mat-error class="margin_tp"
             *ngIf="whatsAppForm.controls['whatsAppNumber'].touched && whatsAppForm.controls['whatsAppNumber'].errors?.required">
             This field is mandatory.
           </mat-error>
           <mat-error class="margin_tp"
             *ngIf="whatsAppForm.controls['whatsAppNumber'].touched && whatsAppForm.controls['whatsAppNumber'].errors?.pattern">
             Enter valid Mobile Number.
           </mat-error>
          </div>
          </div>
          </div>
     
          
                </div>
                <div class="row">
                    <div class="col-sm-2"></div>
                <div class="col-sm-8">
                    <button class="btn btn-primary btn-sm example-full-width mt-2"  type="button"
                     (click)="save()" >Login</button>
                  </div>
                  <div class="col-sm-2"></div>
                </div>
</div>
               
              </div>
            </div>
</form>


      </div>
    </mat-dialog-content>
  
  </div>