<div class="dialog-wrapper">
    <!-- <h3 mat-dialog-title>Change Password </h3> -->
      <div class="container" [formGroup]="popupServiceForm">
    <div class="row">
        <div class="col-sm-12">
         
            <div class="row">
                <div class="col-sm-10">
                  
                    <div class="row ">
  
                        
                      
                        <span> <h1 class="fass ml-3 pt-1" id="topopupServiceForm">Add Service Bill</h1></span>
                        
                      </div>
                     
                    </div>
                    <div class="col-sm-2 justify-content-end" >
                      <div class="row pt-1 pb-1 justify-content-end">
                     
                       <a (click)="onClose()" matTooltip="Close" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">close</span></a>
                     
                      </div>
                   </div>
                    <div class="col-sm-12 " >
                      
                          <div *ngIf="pagename === 'Billing Page'" class="row">
                           
                           
                          <div *ngIf="userType == 'Super Admin'" class="col-sm-5 mb-2">
                            <div class="row">

                              <div class="col-sm-10 pr-1">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Branch 
                                <!-- <span class="required-asterisk">*</span> -->
                              </mat-label>
                              <mat-select [readonly]="way==2" [disabled]="way==2" required #select1 formControlName="branchRefId" >
                                <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'branchName'"
                                [array]="BranchList"  (filteredReturn)="filteredListBranch =$event"></mat-select-filter>
                               
                                <mat-option [readonly]="way==2" [disabled]="way==2" *ngFor="let item of filteredListBranch" [value]="item._id" 
                                (click)="branchClick(item._id,item.branchName);"
                                >
                                 {{ item.branchName }}</mat-option>
                                </mat-select>
                                
                            </mat-form-field>
                            <mat-error class="margin_tp"
                                *ngIf="popupServiceForm.controls['branchRefId'].touched && popupServiceForm.controls['branchRefId'].errors?.required">
                                This field is mandatory.
                              </mat-error>
                           </div>
                           <div *ngIf="way!==2" class="col-sm-2 mt-2 mTop no-print">
                            <i (click)="openBranchPopUp();" matTooltip="Add New Branch" 
                            class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                          </div>
                       </div>
                       </div>
                          <div  *ngIf="patientCreate" class="col-sm-5">
                          <div class="row">
                            <div class="col-sm-10 pr-1">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Select Patient </mat-label>
                            
                            <!-- <mat-select #select2 formControlName="patientRefId">
 
                              <mat-select-filter *ngIf="select2.focused" [placeholder]="'Filter'"
                                [displayMember]="'firstName'" [array]="patientList"
                                (filteredReturn)="filteredListPatient =$event"></mat-select-filter>

                              <mat-option *ngFor="let item of filteredListPatient" [value]="item._id" (click)="onSelectPatient(item)"
                               >
                               {{ item.firstName }}</mat-option>

                            </mat-select> -->
                            <input [readonly]="way==2"
                            (click)="openPatientSearchPopUp();" matInput readonly placeholder="Patient Name" formControlName="patientName" />
                          </mat-form-field> 
                          
                          <mat-error class="margin_tp"
                            *ngIf="popupServiceForm.controls['patientName'].touched && popupServiceForm.controls['patientName'].errors?.required">
                            This field is mandatory.
                          </mat-error>
                          </div>
                          <div *ngIf="way!==2" class="col-sm-2 mt-2 mTop no-print">
                            <i (click)="openPatientPopUp();" matTooltip="Add New Patient" 
                            class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                          </div>
                          </div>
                        </div>
                        <div *ngIf="!patientCreate" class="col-sm-5">
                            <div class="col-sm-12 pr-1">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Select Patient </mat-label>
                            <!-- <mat-select #select2 formControlName="patientRefId">

                              <mat-select-filter *ngIf="select2.focused" [placeholder]="'Filter'" [displayMember]="'firstName'"
                              [array]="patientList"  (filteredReturn)="filteredListPatient =$event"></mat-select-filter>
                              
                              <mat-option  *ngFor="let item of filteredListPatient" [value]="item._id" (click)="onSelectPatient(item)"
                            >
                             {{ item.firstName }}</mat-option>
  
                            </mat-select> -->
                            <input (click)="openPatientSearchPopUp();" matInput readonly placeholder="Patient Name" formControlName="patientName" />
                          </mat-form-field>
                          <mat-error class="margin_tp"
                            *ngIf="popupServiceForm.controls['patientName'].touched && popupServiceForm.controls['patientName'].errors?.required">
                            This field is mandatory.
                          </mat-error>
                          </div>
                         
                          </div>
                          <div class="col-sm-1 ">
                            <!-- <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                              <a (click)="onClose()" matTooltip="Close" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">close</span></a>
                              </div> -->
                          </div>
                          
                        </div>
                        <div *ngIf="pagename === 'Repair'" class="row">
                          <!-- <div class="col-sm-5">
                            <div class="col-sm-12 pr-1">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Branch </mat-label>
                            
                            <input matInput readonly placeholder="Branch Name" formControlName="branchName" />
                          </mat-form-field>
                          
                          </div>
                         
                          </div> -->
                          <div class="col-sm-5">
                            <div class="col-sm-12 pr-1">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Patient Name</mat-label>
                            
                            <input matInput readonly placeholder="Patient Name" formControlName="patientName" />
                          </mat-form-field>
                          
                          </div>
                         
                          </div>
                        </div>
                            </div>
                            
                             
                            
                            <!-- <div class="col-sm-2 mt-2">
                              <img (click)="openPatientPopUp();" matTooltip="Add New Patient" width="25"
                                src="../../assets/add_img.png">
                            </div> -->
                          </div>
                       
                   
              <div class="col-sm-12 pl-0">
             
                                <div class="row">
                                  <div class="col-sm-12 table-responsive">
                                    <table  
                                 class="table scroll height p-0 table-bordered data-table-custom">
                                            <thead> 
                                              <tr class="text-center">
                                                <th *ngIf="pagename !== 'Repair'">Select</th>
                                                <th class="width1">Services</th>
                                                <th *ngIf="pagename !== 'Repair'"class="width2">Quantity</th>
                                                <th *ngIf="pagename !== 'Repair'">Service Rate</th>
                                                <th *ngIf="pagename == 'Repair'">Estimate Final Amount</th>
                                                
                                                <th *ngIf="pagename === 'Repair'">Other</th>
                                                <th *ngIf="pagename !== 'Repair'">Discount (₹)</th>
                                              </tr>
                                            </thead>
                                            <!-- <thead> 
                                              <tr class="text-center">
                                                <td>Services</td>
                                                <td>Service Rate</td>
                                                <td>Discount</td>
                                              </tr>
                                            </thead> --> 
                                          <tbody formArrayName="serviceRateInfo" *ngIf="pagename !== 'Repair'">
                                            <tr [formGroupName]="i"
                                              *ngFor="let row of popupServiceForm.get('serviceRateInfo')['controls']; let i = index; let last = last" role="row"
                                              class="odd text-center">
                                              <td class="text-center">
                                                <mat-checkbox formControlName="addService" class="example-margin" (change)="selectService($event,i)">
                                                  </mat-checkbox>
                                              </td>
                                              <td class="text-left width1 ">
                                                {{row.controls.serviceName.value}}
                                              </td>
                                              <td  class="p-2">
                                                <mat-form-field class=" width2 " appearance="outline">
                                                  <mat-label class="lable_style2">Quantity</mat-label>
                                                  <input matInput formControlName="quantity" placeholder="Quantity" maxlength="2"/>
                                                </mat-form-field>
                                                <mat-error class="margin_tp" *ngIf="row.controls.quantity.touched && row.controls.quantity.errors?.pattern">
                                                  Please enter valid quantity.
                                                </mat-error>
                                                <mat-error class="margin_tp" *ngIf="row.controls.quantity.touched && row.controls.quantity.errors?.minlength">
                                                  Enter minimum 1 digit quantity.
                                                </mat-error>
                                              </td>
                                              <td  class="p-2">
                                                <mat-form-field class=" widthsize " appearance="outline">
                                                  <mat-label class="lable_style2">Rate</mat-label>
                                                  <input matInput formControlName="rate" placeholder="Rate" readonly/>
                                                </mat-form-field>
                                               
                                              </td>
                                              <td class="p-2" >
                                                <mat-form-field class="example-full-width lable_style2 widthsize " appearance="outline">
                                                  <mat-label class="lable_style2">Discount</mat-label>
                                                  <input matInput formControlName="discount" placeholder="₹" maxlength="5" (change)="onSelectDiscount($event,i)" />
                                                </mat-form-field>
                                                <mat-error class="margin_tp" *ngIf="row.controls.discount.touched && row.controls.discount.errors?.pattern">
                                                  Please enter valid discount.
                                                </mat-error>
                                                <mat-error class="margin_tp" *ngIf="row.controls.discount.touched && row.controls.discount.errors?.minlength">
                                                  Enter minimum 2 digit discount.
                                                </mat-error>
                                              </td>
                                            </tr> 
                                          </tbody>
                                          <tbody formArrayName="serviceRateInfo" *ngIf="pagename == 'Repair'">
                                            <tr [formGroupName]="i"
                                              *ngFor="let row of popupServiceForm.get('serviceRateInfo')['controls']; let i = index; let last = last" role="row"
                                              class="odd text-center">
                                              <!-- <td class="text-center">
                                                <mat-checkbox formControlName="addService" class="example-margin" (change)="selectService($event,i)">
                                                  </mat-checkbox>
                                              </td> -->
                                              <td class="p-2">
                                                <mat-form-field class="example-full-width tdW" appearance="outline">
                                                  <mat-label>Service Name </mat-label>
                                                  <mat-select #select4 formControlName="serviceName">
                                                    <mat-select-filter *ngIf="select4.focused" [placeholder]="'Filter'"
                                                      [displayMember]="'serviceName'" [array]="serviceList"
                                                      (filteredReturn)="filteredListService =$event"></mat-select-filter>
                           
                                                     <mat-option *ngFor="let item of filteredListService"
                                                     (click)="clickService(item,i)" [value]="item.serviceName">
                                                      {{ item.serviceName }}</mat-option>
                                                  </mat-select>
                                                  <!-- <mat-error class="margin_tp"
                                            *ngIf="row.controls.deviceRefId.touched && row.controls.deviceRefId.errors?.required">
                                               This Field is Mandatory
                                          </mat-error> -->
                                                </mat-form-field>
                                                <mat-error class="margin_tp"
                                                *ngIf="row.controls.serviceName.touched && row.controls.serviceName.errors?.required">
                                                   This Field is Mandatory
                                                </mat-error>
                                              </td>
                                              <td  *ngIf="pagename !== 'Repair'" class="p-2">
                                                <mat-form-field class=" width2 " appearance="outline">
                                                  <mat-label class="lable_style2">Quantity</mat-label>
                                                  <input matInput formControlName="quantity" placeholder="Quantity" maxlength="2"/>
                                                </mat-form-field>
                                                <mat-error class="margin_tp" *ngIf="row.controls.quantity.touched && row.controls.quantity.errors?.pattern">
                                                  Please enter valid quantity.
                                                </mat-error>
                                                <mat-error class="margin_tp" *ngIf="row.controls.quantity.touched && row.controls.quantity.errors?.minlength">
                                                  Enter minimum 1 digit quantity.
                                                </mat-error>
                                              </td>
                        
                                              <td  class="p-2">
                                                <mat-form-field class=" widthsize " appearance="outline">
                                                  <mat-label class="lable_style2">Rate</mat-label>
                                                  <input matInput formControlName="rate" placeholder="Rate"/>
                                                  
                                                </mat-form-field>
                                                <mat-error class="margin_tp"
                                                *ngIf="row.controls.rate.touched && row.controls.rate.errors?.required">
                                                   This Field is Mandatory
                                                </mat-error>
                                                <mat-error class="margin_tp" *ngIf="row.controls.rate.touched && row.controls.rate.errors?.minlength">
                                                  Enter minimum 3 digit rate.
                                                </mat-error>
                                              </td>
                                              <td class="p-2" >
                                                <mat-form-field class="example-full-width lable_style2 widthsize " appearance="outline">
                                                  <mat-label *ngIf="pagename !== 'Repair'" class="lable_style2">Discount</mat-label>
                                                  <mat-label *ngIf="pagename === 'Repair'" class="lable_style2">Other</mat-label>
                                                  <input matInput formControlName="discount" placeholder="₹" maxlength="5" (change)="onSelectDiscount($event,i)" />
                                                </mat-form-field>
                                                <mat-error class="margin_tp" *ngIf="row.controls.discount.touched && row.controls.discount.errors?.pattern">
                                                  Please enter valid discount.
                                                </mat-error>
                                                <mat-error class="margin_tp" *ngIf="row.controls.discount.touched && row.controls.discount.errors?.minlength">
                                                  Enter minimum 2 digit discount.
                                                </mat-error>
                                              </td>
                                            </tr> 
                                          </tbody>
                                          </table> 
                                        
                                    </div>
                                    
                                    <!-- <div class="col-sm-12 pt-0 text-right">
                                        <pagination-controls id="popupServiceMaster" directionLinks="true"
                                          (pageChange)="onPageChange($event)" class="float-right pt-3 my-pagination">
                                        </pagination-controls>
                                      </div> -->
                                      <div class="row col-sm-12 pr-3">
                                        <div class="col-sm-9" *ngIf="submitButton"></div>
                                        <div class="col-sm-6" *ngIf="updateButton"></div>
                                        <div class="col-sm-3 " *ngIf="submitButton && pagename !== 'Repair'">
                                         <button type="button" class="btn btn-primary  btn-sm example-full-width" (click)="submitCheckupDetails()">Save</button>
                                        </div> 
                                        <div class="col-sm-3 " *ngIf="submitButton && pagename == 'Repair'">
                                          <button type="button" class="btn btn-primary  btn-sm example-full-width" (click)="submitRepairBill()">Save</button>
                                         </div> 
                                        <div class="col-sm-3" *ngIf="updateButton" >
                                          <button type="button" class="btn btn-sm btn-primary example-full-width"
                                          (click)="update()" >Update </button>
                                         
                                           
                                         </div>
                                         <div class="col-sm-3" *ngIf="updateButton" >
                                      
                                          <button type="button" class="btn btn-sm btn-primary example-full-width" (click)="cancelUpdate()"
                                        >Cancel </button>
                                          
                                         </div>
                                         <!-- <div class="col-sm-4" *ngIf="updateButton">
                                          <button class="btn btn-primary btn-sm  example-full-width"  (click)="cancelUpdate()" type="reset" >Reset </button>
                                         </div> -->
                                      </div>   
                                    
                                  
                           
                                </div>
                       
                  
  
                </div>
            
  
        </div>
        
    </div>
  </div>
  </div>