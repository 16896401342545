import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  FormArray
} from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { AfterViewInit, Directive, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-billing-company',
  templateUrl: './billing-company.component.html',
  styleUrls: ['./billing-company.component.scss']
})
export class BillingCompanyComponent implements OnInit {

  errormessage: string;
  searchText: any;
  billingId: any;
  allState = [
    { name: 'Andaman and Nicobar Islands', value: 'AN' },
    { name: 'Andhra Pradesh', value: 'AP' },
    { name: 'Arunachal Pradesh', value: 'AR' },
    { name: 'Assam', value: 'AS' },
    { name: 'Bihar', value: 'BR' },
    { name: 'Chandigarh', value: 'CH' },
    { name: 'Chhattisgarh', value: 'CT' },
    { name: 'Dadra and Nagar Haveli', value: 'DN' },
    { name: 'Daman and Diu', value: 'DD' },
    { name: 'Delhi', value: 'DL' },
    { name: 'Goa', value: 'GA' },
    { name: 'Gujarat', value: 'GJ' },
    { name: 'Haryana', value: 'HR' },
    { name: 'Himachal Pradesh', value: 'HP' },
    { name: 'Jammu and Kashmir', value: 'JK' },
    { name: 'Jharkhand', value: 'JH' },
    { name: 'Karnataka', value: 'KA' },
    { name: 'Kerala', value: 'KL' },
    { name: 'Ladakh', value: 'LA' },
    { name: 'Lakshadweep', value: 'LD' },
    { name: 'Madhya Pradesh', value: 'MP' },
    { name: 'Maharashtra', value: 'MH' },
    { name: 'Manipur', value: 'MN' },
    { name: 'Meghalaya', value: 'ML' },
    { name: 'Mizoram', value: 'MZ' },
    { name: 'Nagaland', value: 'NL' },
    { name: 'Odisha', value: 'OR' },
    { name: 'Puducherry', value: 'PY' },
    { name: 'Punjab', value: 'PB' },
    { name: 'Rajasthan', value: 'RJ' },
    { name: 'Punjab', value: 'PB' },
    { name: 'Rajasthan', value: 'RJ' },
    { name: 'Sikkim', value: 'SK' },
    { name: 'Tamil Nadu', value: 'TN' },
    { name: 'Telangana', value: 'TG' },
    { name: 'Tripura', value: 'TR' },
    { name: 'Uttar Pradesh', value: 'UP' },
    { name: 'Uttarakhand', value: 'UT' },
    { name: 'West Bengal', value: 'WB' },
  ]
  filteredList: { name: string; value: string; }[];
  filteredList2: { state: string; districts: string; }[];
  filteredListDistrict2: { district: string; }[];
  gstCheckbox = true;
  userType: string;
  clinicRef: string;
  clinicId: string;
  bId: any;
  pagee: 1;
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    private httpClient: HttpClient
  ) { }
  billingCompanyForm: FormGroup;
  status: any;
  submitButton = true;
  updateButton = false;
  deleteButton = false;
  cancelButton = false;
  loggedId: any;
  myimage;
  date: any;
  size = 10;
  page = 1;
  billingData: any;
  totalLength: any;
  listofbilling: any;
  userPermission: any;
  billingCreate = true;
  billingUpdate = true;
  stateCityArr2: any[];
  stateCity2: any;
  districtData2: any;

  ngOnInit() {
    this.page = 1;
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.clinicId = '622b1d4e39a66ac7fd3ada1c';
    this.date = new Date();
    this.filteredList = this.allState.slice();
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    this.userType = sessionStorage.getItem('userType');
    if (this.userType !== 'Super Admin') {
      this.userPermission.forEach((element) => {
        if (element.pageName == 'Billing Company' && element.create == false) {
          this.billingCreate = false;
        }
        if (element.pageName == 'Billing Company' && element.update == false) {
          this.billingUpdate = false;
        }
      });
    }
    this.billingCompanyForm = this.fb.group({

      "companyName": new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z &\\s ]*$")]),
      "companyId": new FormControl(''),
      "companyRegisteredAddress": new FormControl('', Validators.required),
      "registeredUnderGST": new FormControl('', Validators.required),
      "companyGST": new FormControl('', Validators.pattern('^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}Z[0-9A-Za-z]{1}$')),
      "companyContactNumber": new FormControl('', [Validators.required, Validators.pattern("(0|91)?[6-9][0-9]{9}")]),
      "companyPAN": new FormControl('', Validators.required),
      "propriter": new FormControl(''),
      "companyType": new FormControl('', Validators.required),
      "companyPinCode": new FormControl('', [Validators.required, Validators.pattern("^[0-9]{6}$")]),
      "taluka": new FormControl('', Validators.pattern("^[a-zA-Z ]*$")),
      "district": new FormControl('', Validators.pattern("^[a-zA-Z ]*$")),
      "state": new FormControl('', Validators.required),
      "country": new FormControl('', [Validators.pattern("[A-Za-z _-]{3,19}$"),]),
      "clinicRefId": new FormControl(),
      "billingStatus": new FormControl(''),
      "searchBilling": new FormControl(''),
      // "bankName":new FormControl('',Validators.pattern("^[a-zA-Z_-\\s]*$")),
      // "bankBranchName":new FormControl('',Validators.pattern("^[a-zA-Z_-\\s]*$")),
      // "accountNumber":new FormControl('',[Validators.pattern("^[0-9]{9,18}$"),Validators.maxLength(18),Validators.minLength(9),]),
      // "ifcCode":new FormControl('',Validators.pattern("^[A-Za-z]{4}0[A-Z0-9a-z]{6}$")),
      // "upiId":new FormControl('',Validators.pattern("^[a-zA-Z0-9_@./#&+-\\s]*$")),

      "createdAt": this.date,
    });
    this.billingCompanyForm.controls["billingStatus"].setValue("Active");
    this.billingCompanyForm.controls["country"].setValue("India");
    this.billingCompanyForm.controls["state"].setValue("Maharashtra");
    let arr = []
    arr = [
      "Ahmednagar",
      "Akola",
      "Amravati",
      "Aurangabad",
      "Beed",
      "Bhandara",
      "Buldhana",
      "Chandrapur",
      "Dhule",
      "Gadchiroli",
      "Gondia",
      "Hingoli",
      "Jalgaon",
      "Jalna",
      "Kolhapur",
      "Latur",
      "Mumbai City",
      "Mumbai Suburban",
      "Nagpur",
      "Nanded",
      "Nandurbar",
      "Nashik",
      "Osmanabad",
      "Palghar",
      "Parbhani",
      "Pune",
      "Raigad",
      "Ratnagiri",
      "Sangli",
      "Satara",
      "Sindhudurg",
      "Solapur",
      "Thane",
      "Wardha",
      "Washim",
      "Yavatmal"
    ]
    this.fetchBillingCompany();
    this.getLastId();
    // this.removeValidator();
    this.getState2();
    this.getDistrict2(arr);
  }

  getState2() {
    this.httpClient.get("assets/state-city.json").subscribe(data => {
      this.stateCity2 = data;
      this.stateCityArr2 = [];
      this.stateCity2.states.forEach((element) => {
        this.stateCityArr2.push(element);
      });
      this.filteredList2 = this.stateCityArr2.slice();
      //
    })
  }
  getDistrict2(state) {
    //
    this.districtData2 = [];
    state.forEach((element) => {
      //
      this.districtData2.push({ "district": element });
    });
    //
    let arr = [];
    arr = state;
    this.filteredListDistrict2 = this.districtData2.slice();
  }
  submitBillingCompany() {
    //this.setValidor();
    if (this.billingCompanyForm.invalid) {
      this.billingCompanyForm.get("companyName").markAsTouched();
      this.billingCompanyForm.get("companyId").markAsTouched();
      this.billingCompanyForm.get("companyRegisteredAddress").markAsTouched();
      this.billingCompanyForm.get("registeredUnderGST").markAsTouched();
      // this.billingCompanyForm.get("companyGST").markAsTouched();
      this.billingCompanyForm.get("companyContactNumber").markAsTouched();
      this.billingCompanyForm.get("companyPAN").markAsTouched();
      this.billingCompanyForm.get("state").markAsTouched();
      this.billingCompanyForm.get("companyType").markAsTouched();
      this.billingCompanyForm.get("companyPinCode").markAsTouched();
      return;
    } else {
      this.addBilling();
    }
  }


  // removeValidator() { 
  //   this.billingCompanyForm.controls['companyName'].setErrors(null);
  //   this.billingCompanyForm.controls['companyName'].clearValidators();
  //   this.billingCompanyForm.controls['companyName'].updateValueAndValidity();

  //   this.billingCompanyForm.controls['companyRegisteredAddress'].setErrors(null);
  //   this.billingCompanyForm.controls['companyRegisteredAddress'].clearValidators();
  //   this.billingCompanyForm.controls['companyRegisteredAddress'].updateValueAndValidity();

  //   this.billingCompanyForm.controls['companyType'].setErrors(null);
  //   this.billingCompanyForm.controls['companyType'].clearValidators();
  //   this.billingCompanyForm.controls['companyType'].updateValueAndValidity();



  //   this.billingCompanyForm.controls['registeredUnderGST'].setErrors(null);
  //   this.billingCompanyForm.controls['registeredUnderGST'].clearValidators();
  //   this.billingCompanyForm.controls['registeredUnderGST'].updateValueAndValidity();

  //   this.billingCompanyForm.controls['companyPAN'].setErrors(null);
  //   this.billingCompanyForm.controls['companyPAN'].clearValidators();
  //   this.billingCompanyForm.controls['companyPAN'].updateValueAndValidity();


  //   this.billingCompanyForm.controls['companyPinCode'].setErrors(null);
  //   this.billingCompanyForm.controls['companyPinCode'].clearValidators();
  //   this.billingCompanyForm.controls['companyPinCode'].updateValueAndValidity();

  //   this.billingCompanyForm.controls['companyContactNumber'].setErrors(null);
  //   this.billingCompanyForm.controls['companyContactNumber'].clearValidators();
  //   this.billingCompanyForm.controls['companyContactNumber'].updateValueAndValidity();


  //   }

  // setValidor() { 
  //   this.billingCompanyForm.controls['companyName'].setValidators(Validators.required);
  //   this.billingCompanyForm.controls['companyName'].updateValueAndValidity();

  //   this.billingCompanyForm.controls['companyRegisteredAddress'].setValidators(Validators.required);
  //   this.billingCompanyForm.controls['companyRegisteredAddress'].updateValueAndValidity();

  //   this.billingCompanyForm.controls['companyType'].setValidators(Validators.required);
  //   this.billingCompanyForm.controls['companyType'].updateValueAndValidity();

  //   this.billingCompanyForm.controls['registeredUnderGST'].setValidators(Validators.required);
  //   this.billingCompanyForm.controls['registeredUnderGST'].updateValueAndValidity();

  //   this.billingCompanyForm.controls['companyPAN'].setValidators(Validators.required);
  //   this.billingCompanyForm.controls['companyPAN'].updateValueAndValidity();

  //   this.billingCompanyForm.controls['companyContactNumber'].setValidators([Validators.required,Validators.pattern("(0|91)?[6-9][0-9]{9}")]);
  //   this.billingCompanyForm.controls['companyContactNumber'].updateValueAndValidity();

  //   this.billingCompanyForm.controls['companyPinCode'].setValidators([Validators.required,Validators.pattern("^[0-9]{6}$")]);
  //   this.billingCompanyForm.controls['companyPinCode'].updateValueAndValidity();

  //   }


  updateBillingDeatils() {
    //  this.setValidor();
    if (this.billingCompanyForm.invalid) {
      this.billingCompanyForm.get("companyName").markAsTouched();
      this.billingCompanyForm.get("companyId").markAsTouched();
      this.billingCompanyForm.get("companyRegisteredAddress").markAsTouched();
      this.billingCompanyForm.get("registeredUnderGST").markAsTouched();
      // this.billingCompanyForm.get("companyGST").markAsTouched();
      this.billingCompanyForm.get("companyContactNumber").markAsTouched();
      this.billingCompanyForm.get("companyPAN").markAsTouched();
      this.billingCompanyForm.get("state").markAsTouched();
      this.billingCompanyForm.get("companyType").markAsTouched();
      this.billingCompanyForm.get("companyPinCode").markAsTouched();
      return;
    } else {
      this.updateBilling();
    }
  }

  // updateUpdateSingleCustomer() {
  //   if (this.billingCompanyForm.invalid) {
  //     // this.billingCompanyForm.get("companyName").markAsTouched();
  //     // this.billingCompanyForm.get("companyId").markAsTouched();
  //     // this.billingCompanyForm.get("companyRegisteredAddress").markAsTouched();
  //     // this.billingCompanyForm.get("registeredUnderGST").markAsTouched();
  //     // this.billingCompanyForm.get("companyGST").markAsTouched();
  //     // this.billingCompanyForm.get("companyContactNumber").markAsTouched();
  //     // this.billingCompanyForm.get("companyPAN").markAsTouched();
  //     // this.billingCompanyForm.get("propriter").markAsTouched();
  //     // this.billingCompanyForm.get("companyType").markAsTouched();
  //     return;
  //   } else {
  //   //update code
  //   }
  // }

  addBilling() {
    this.spinner.show();
    let payload = this.billingCompanyForm.value;
    payload.createdAt = new Date();
    // payload.image = this.myimage;
    // payload.locationImage = this.myLocationimage;
    //("payload", JSON.stringify(payload));
    payload.clinicRefId = this.clinicRef;
    this.apimicroservice.saveBillingData(payload).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.apimicroservice.openSnackbar(res.message);
        this.ngOnInit();
        // this.billingCompanyForm.reset();
        // this.fetchBillingCompany();
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    }, (ERROR: HttpErrorResponse) => {
      this.apimicroservice.openSnackbar(ERROR.error.message);
      this.spinner.hide();
    });
  }
  openSnackbar(message: string) {
    return this._snackBar.open(message, "close", {
      verticalPosition: "bottom",
      horizontalPosition: "right",
      duration: 2000,
      panelClass: ["snackbarStyle"],
    });
  }


  fetchBillingCompany() {
    let pg = 1;
    let size = 10;
    let search = '';
    let clinicRef= sessionStorage.getItem('clinicRef');

    this.spinner.show();
    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getBilling(pg, size, search, this.clinicRef).subscribe(res => {
        this.spinner.hide();
        this.listofbilling = res.data.billingData;
        this.totalLength = res.data.totalItems;
        if (res.status) {
          // this.apimicroservice.openSnackbar(res.message);

        } else {
          //this.apimicroservice.openSnackbar(res.message);
        }
      });
    } else {
      this.apimicroservice.getBilling(pg, size, search, clinicRef).subscribe(res => {
        this.spinner.hide();
        this.listofbilling = res.data.billingData;
        this.totalLength = res.data.totalItems;
        if (res.status) {
          // this.apimicroservice.openSnackbar(res.message);

        } else {
          //this.apimicroservice.openSnackbar(res.message);
        }
      });

    }

  }

  prepopulation(id) {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.spinner.show();
    this.apimicroservice.getBillingDataById(id,clinicRef).subscribe(res => {
      this.spinner.hide();
      this.submitButton = false;
      this.updateButton = true;

      //  this.deleteButton= true;
      this.cancelButton = true;

      let data = res.data[0]; 

      this.billingCompanyForm = this.fb.group({


        "companyName": new FormControl(data.companyName, [Validators.required, Validators.pattern("^[a-zA-Z - &\\s]*$")]),
        "companyId": new FormControl(data.companyId),
        "companyRegisteredAddress": new FormControl(data.companyRegisteredAddress, Validators.required),
        "registeredUnderGST": new FormControl(data.registeredUnderGST, Validators.required),
        "companyGST": new FormControl(data.companyGST, Validators.pattern('^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}Z[0-9A-Za-z]{1}$')),
        "companyContactNumber": new FormControl(data.companyContactNumber, [Validators.required, Validators.pattern("(0|91)?[6-9][0-9]{9}")]),
        "companyPAN": new FormControl(data.companyPAN, Validators.required),
        "propriter": new FormControl(data.propriter),
        "companyType": new FormControl(data.companyType, Validators.required),
        "billingStatus": new FormControl(data.billingStatus),
        "taluka": new FormControl(data.taluka, Validators.pattern("^[a-zA-Z ]*$")),
        "district": new FormControl(data.district, Validators.pattern("^[a-zA-Z ]*$")),
        "state": new FormControl(data.state, Validators.required),
        "companyPinCode": new FormControl(data.companyPinCode, [Validators.required, Validators.pattern("^[0-9]{6}$")]),
        "country": new FormControl(data.country, [
          Validators.pattern("[A-Za-z _-]{3,19}$"),
        ]),
        "clinicRefId": new FormControl(data.clinicRefId),
        "updateddAt": new Date(),
        "searchBilling": new FormControl(''),
        //      "bankName":new FormControl(data.bankName,Validators.pattern("^[a-zA-Z_-\\s]*$")),
        // "bankBranchName":new FormControl(data.bankBranchName,Validators.pattern("^[a-zA-Z_-\\s]*$")),
        // "accountNumber":new FormControl(data.accountNumber,[Validators.pattern("^[0-9]{9,18}$"),Validators.maxLength(18),Validators.minLength(9),]),
        // "ifcCode":new FormControl(data.ifcCode,Validators.pattern("^[A-Za-z]{4}0[A-Z0-9a-z]{6}$")),
        // "upiId":new FormControl(data.upiId,Validators.pattern("^[a-zA-Z0-9_@./#&+-\\s]*$")),

      });

      this.billingId = id;
      this.bId = data.companyId;

      //  this.myimage = data.image ? data.image : "../../assets/images/setting2.png";
      //  this.myLocationimage = data.locationImage ? data.locationImage : "../../assets/images/no-image-available.png";
    });


  }
  addNew() {
    this.billingCompanyForm.reset();
    this.updateButton = false;
    this.deleteButton = false;
  }
  updateBilling() {
    this.spinner.show();
    let payload = this.billingCompanyForm.value;
    payload.clinicRefId = this.clinicRef;

    // payload.image = this.myimage;
    this.apimicroservice.updateBillingData(this.billingId, payload).subscribe(res => {
      this.spinner.hide();
      this.ngOnInit();
      if (res.status) {
        this.apimicroservice.openSnackbar(res.message);
        this.submitButton = true;
        this.updateButton = false;
        this.cancelButton = false;
        // this.deleteButton=false;
        //this.ngOnInit();
        // this.billingCompanyForm.reset();
        // this.fetchBillingCompany();

      } else {
        this.apimicroservice.openSnackbar(res.message);
      }

    },
    );

  }

  // deleteBillingCompany(id){
  //   this.spinner.show();
  //   this.apimicroservice.deleteBillingData(id).subscribe(res => {
  //     this.spinner.hide();
  //     this.fetchBillingCompany();
  //     if (res.status) {
  //       this.apimicroservice.openSnackbar(res.message);
  //       this.billingCompanyForm.reset();  
  //       this.submitButton=true;
  //       this.updateButton = false;

  //       this.deleteButton=false;
  //     } else {
  //       this.apimicroservice.openSnackbar(res.message);
  //     }

  //   });
  //  }

  //  getLastId()
  //  {
  //    this.apimicroservice.getLastBillingID().subscribe(res => {
  //      //   
  //     //  if(res['data']['data'] == '')
  //     //  {
  //     //    this.billingCompanyForm.controls['companyId'].setValue('1');
  //     //  }
  //      if (res['status']) {
  //        let companyIdd = res['data'][0].companyId;
  //        this.bId=companyIdd + 1
  //        this.billingCompanyForm.controls['companyId'].setValue(companyIdd + 1);
  //      } else {
  //        this.apimicroservice.openSnackbar("Something went wrong");
  //      }

  //    })
  //  }

  getLastId() {
    this.apimicroservice.getLastBillingID().subscribe(res => {
      //
      // if(res['data']['data'] == '')
      // {
      //   this.billingCompanyForm.controls['companyId'].setValue(1);
      // }
      if (res['status']) {
        let companyIdd = res['data']['totalItems'];
        // let companyIdd = res['data'][0].companyId;
        this.bId = companyIdd + 1;
        this.billingCompanyForm.controls['companyId'].setValue(companyIdd + 1);
      } else {
        this.apimicroservice.openSnackbar("Something went wrong");
      }

    })
  }

  onPageChange(event) {
    this.spinner.show();
    this.page = event;
    let clinicRef= sessionStorage.getItem('clinicRef');
    //pg = this.page;
    this.searchText = this.billingCompanyForm.get('searchBilling').value;
    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getBilling(this.page, this.size, this.searchText, this.clinicRef).subscribe(res => {
        this.spinner.hide();
        if (res.status) {
          this.listofbilling = res.data.billingData;
          this.totalLength = res.data.totalItems;
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }

      })
    } else {
      this.apimicroservice.getBilling(this.page, this.size, this.searchText, clinicRef).subscribe(res => {
        this.spinner.hide();
        if (res.status) {
          this.listofbilling = res.data.billingData;
          this.totalLength = res.data.totalItems;
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }

      })

    }

  }
  onKeyUpEvent(event: any) {
    // let pg = 1;

    // let size=5;
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.searchText = this.billingCompanyForm.get('searchBilling').value;
    let page = 1;
    let size = 10;
    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getBilling(page, size, this.searchText, this.clinicRef).subscribe(res => {

        if (res.status) {
          this.listofbilling = res.data.billingData;
          this.totalLength = res.data.totalItems;
          this.page = res.data.pageNumber;
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }

      })
    } else {
      this.apimicroservice.getBilling(page, size, this.searchText, clinicRef).subscribe(res => {

        if (res.status) {
          this.listofbilling = res.data.billingData;
          this.totalLength = res.data.totalItems;
          this.page = res.data.pageNumber;
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }

      })
    }

  }

  redirect(to): void {
    this.router.navigate([to]);
  }

  onChange($event: Event) {
    const file = ($event.target as HTMLInputElement).files[0];
    const fileName = file.name;
    this.errormessage = '';
    let ext = fileName.substring(fileName.lastIndexOf(".") + 1);
    if (
      ext.toLowerCase() == "png" ||
      ext.toLowerCase() == "jpg" ||
      ext.toLowerCase() == "jpeg" ||
      ext.toLowerCase() == "csv" ||
      ext.toLowerCase() == "xlsx" ||
      ext.toLowerCase() == "pdf" ||
      ext.toLowerCase() == "docx" 
    ) { 
      this.convertToBase64(file);
    }
  }

  cancelUpdate() {
    this.billingCompanyForm.reset();
    this.submitButton = true;
    this.updateButton = false;
    this.cancelButton = false;
    this.ngOnInit();
  }

  convertToBase64(file: File) {
    const observable = new Observable((subscriber: Subscriber<any>) => {
      this.readFile(file, subscriber);
    });
    observable.subscribe((d) => {
      this.myimage = d;

    });
  }
  readFile(file: File, subscriber: Subscriber<any>) {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      subscriber.next(fileReader.result);
      subscriber.complete();
    };
    fileReader.onerror = (error) => {
      subscriber.error(error);
      subscriber.complete();
    };
  }
  toBillingCompanyForm() {
    document.getElementById("toBillingCompanyForm").scrollIntoView();
  }
  toDeviceList() {
    document.getElementById("toDeviceList").scrollIntoView();
  }
  gstApplicable() {
    // let gstApplicable = serviceControl.get("registeredUnderGST").value;
    let payload = this.billingCompanyForm.value;
    if (payload.registeredUnderGST == 'Yes') {
      this.gstCheckbox = true;
      //
    }
    else {
      this.gstCheckbox = false;
      //
    }
  }
  WhatsApp() {
    let payload = this.billingCompanyForm.value;
    let num = payload.companyContactNumber;
    if (num.length == 10) {
      sessionStorage.setItem('whatsAppNumber', payload.companyContactNumber);
      let mobileNumber = sessionStorage.getItem('whatsAppNumber');
      window.open("https://wa.me/91" + mobileNumber);
    }
    else {
      this.billingCompanyForm.get("companyContactNumber").markAsTouched();
    }
  }
  onback() {
    this.apimicroservice.back()
  }
}