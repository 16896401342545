<div class="container-fluid bgcolor2" [formGroup]="doctorLeaveForm">
    <form [formGroup]="doctorLeaveForm" autocomplete="off" novalidate>
      <div class="row">
          <div class="col-sm-12">
            
              <div class="row">
                
                  <div class="col-sm-12">
                    
                      <div class="row">
                       
                          <div class="card divbody mb-3 borderradius p-2" id="todoctorLeaveForm">
                            <div class="row">

                              <div class="col-12">
                                <div class="row ">
                                <div class="col-sm-9">
                                
                                    <h1 class="pt-2 pl-0" >Audiologists Leave</h1>
                                    </div> 
                                    <div class="col-sm-3 mt-1">
                                      <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                                        <a (click)="onback()" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">arrow_back</span>Back</a>
                                        <a (click)="toDoctorLeaveLst()" class="d-flex align-items-center ms-3 pr-2 mt-2"><span class="material-symbols-outlined pr-2">reorder</span>List</a>
                              </div>
                              </div>
                            </div>
                           </div>
                           </div>
                              <div class="col-sm-12">
                                  <div class="row"> 
                                  
                                        <div class="col-sm-3">
                                          <div class="row">
                                            <div class="col-sm-10 pr-1"> 
                                          <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label>Audiologists Name</mat-label>
                                            <mat-select #select1 formControlName="doctorRefId">
                                              <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'firstName'"
                                                [array]="doctorList" (filteredReturn)="filteredDoctorList =$event"></mat-select-filter>
                                              <mat-option *ngFor="let item of filteredDoctorList" [value]="item._id"
                                                (click)="doctorClick(item._id,item.firstName+ ' ' +item.lastName,item.branchRefId);">
                                                {{ item.firstName +" "+ item.lastName}}</mat-option>
                                            </mat-select>
                                          </mat-form-field>
                                          <mat-error
                                          *ngIf="doctorLeaveForm.controls['doctorRefId'].touched && doctorLeaveForm.controls['doctorRefId'].errors?.required">
                                          This field is mandatory.
                                        </mat-error>
                                        </div>
                                        <div class="col-sm-2 mTop no-print">
                                          <i (click)="openDoctorPopUp();" matTooltip="Add New Audiologists" class="blue_col mTop fa-solid fa-circle-plus
                                           pl-1 fa-lg"></i>
                                       </div> 
                                        </div>
                                        </div>
                                          <!-- <div class="col-sm-3">
                                            
                                          <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label>Branch Name</mat-label> -->
                                            <!-- <input matInput readonly placeholder="Branch Name" formControlName="branchName" /> -->
                                            <!-- <mat-select #select2  formControlName="branchRefId" [disabled]="disableBranch" >
                                              <mat-select-filter *ngIf="select2.focused" [placeholder]="'Filter'"
                                                [displayMember]="'branchName'" [array]="BranchList"
                                                (filteredReturn)="filteredListBranch =$event"></mat-select-filter>
                                              <mat-option *ngFor="let item of filteredListBranch" [value]="item._id"
                                                (click)="branchClick(item._id);">
                                                {{ item.branchName }}</mat-option>
                                            </mat-select> -->
                                          <!-- </mat-form-field>
                                          <mat-error
                                          *ngIf="doctorLeaveForm.controls['branchName'].touched && doctorLeaveForm.controls['branchName'].errors?.required">
                                          This field is mandatory.
                                        </mat-error>
                                        </div>
                                       -->

                                         <div *ngIf="branchCreate" class="col-sm-3">
                                <div class="row">
                                  <div class="col-sm-10 pr-1">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Branch 
                                  </mat-label> 
                                    <mat-select required #select1 formControlName="branchRefId">
                                      <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'branchName'"
                                      [array]="BranchList"  (filteredReturn)="filteredListBranch =$event"></mat-select-filter>
                                     
                                      <mat-option *ngFor="let item of filteredListBranch" [value]="item._id" 
                                      (click)="onSelectBranch(item)"
                                      >
                                       {{ item.branchName }}</mat-option>
                                      </mat-select>
                                </mat-form-field>
                                <mat-error
                                *ngIf="doctorLeaveForm.controls['branchRefId'].touched && doctorLeaveForm.controls['branchRefId'].errors?.required">
                                This field is mandatory.
                              </mat-error>
                              </div>
                              <div class="col-sm-2 mTop no-print">
                                <i (click)="openBranchPopUp();" matTooltip="Add New Branch" class="blue_col mTop fa-solid fa-circle-plus
                                 pl-1 fa-lg"></i>
                              </div>
                              </div> 
                              </div>
                              <div class="col-sm-3 ">
                                <mat-form-field class="example-full-width " appearance="outline">
                                  <mat-label>Leave Type</mat-label>
                                <mat-select formControlName="leaveType">
                                  <mat-option (click)="leaveType('Full Day');" value="Full Day">Full Day</mat-option>
                                  <mat-option (click)="leaveType('Half Day');" value="Half Day">Half Day</mat-option>
                                </mat-select>
                                </mat-form-field> 
                              </div>
                                        <div class="col-sm-3 ">
                                          <mat-form-field class="example-full-width " appearance="outline">
                                            <mat-label *ngIf="!HalfDay">From Date</mat-label>
                                            <mat-label *ngIf="HalfDay">Date</mat-label>
                                            <input matInput [min]="currentdate" [matDatepicker]="fromDate" placeholder="Choose a date"
                                             formControlName="fromDate"
                                            (dateChange)="toOpenDateChnage();">
                                            <mat-datepicker-toggle matSuffix [for]="fromDate">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #fromDate></mat-datepicker> 
                                          </mat-form-field>
                                          <mat-error
                                            *ngIf="doctorLeaveForm.controls['fromDate'].touched && doctorLeaveForm.controls['fromDate'].errors?.required">
                                            This field is mandatory.
                                          </mat-error>
                                          <mat-error class="margin_tp"
                                        *ngIf="doctorLeaveForm.controls['fromDate'].hasError('matDatepickerMin')">
                                        Please enter Date greater than or equal to {{minDateStr}}.
                                      </mat-error>
                                         </div>
                                         <div *ngIf="HalfDay" class=" col-sm-3  ">
                                          <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label>From Time</mat-label>
                                              <input class="form-control2" matInput
                                              placeholder="08:00 am"
                                              formControlName="fromTime"
                                              [ngxTimepicker]="pickerF"
                                              value=""
                                              [disableClick]="true"
                                              readonly
                                              />
                                              <ngx-material-timepicker-toggle matSuffix [for]="pickerF"></ngx-material-timepicker-toggle>
                                              <ngx-material-timepicker #pickerF></ngx-material-timepicker>
                                          </mat-form-field>
                                        </div>
                                         <div *ngIf="!HalfDay" class="col-sm-3 ">
                                           <mat-form-field class="example-full-width " appearance="outline">
                                               <mat-label>To Date</mat-label>
                                               <input matInput  [min]="currentdate" [matDatepicker]="toDate" placeholder="Choose a date" 
                                                formControlName="toDate" (dateChange)="toCloseDateChnage();">
                                                <mat-datepicker-toggle matSuffix [for]="toDate">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #toDate></mat-datepicker>
                                           </mat-form-field>
                                           <mat-error class="margin_tp"
                                        *ngIf="doctorLeaveForm.controls['toDate'].hasError('matDatepickerMin')">
                                        Please enter Date greater than or equal to {{minDateStr}}.
                                      </mat-error>
                                           <!-- <mat-error
                                           *ngIf="doctorLeaveForm.controls['toDate'].touched && doctorLeaveForm.controls['toDate'].errors?.required">
                                           This field is mandatory.
                                         </mat-error> -->
                                       </div>
                                       <div *ngIf="HalfDay" class=" col-sm-3  ">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>To Time</mat-label>
                                            <input class="form-control2" matInput
                                            placeholder="08:00 am"
                                            formControlName="toTime"
                                            [ngxTimepicker]="pickerF"
                                            value=""
                                            [disableClick]="true"
                                            readonly
                                            />
                                            <ngx-material-timepicker-toggle matSuffix [for]="pickerF"></ngx-material-timepicker-toggle>
                                            <ngx-material-timepicker  #pickerF></ngx-material-timepicker>
                                        </mat-form-field>
                                      </div>
                                      <div class="col-sm-1 offset-sm-10 mb-2 mt-2" *ngIf="submitButton"> 
                                          <button class="btn btn-primary btn-sm example-full-width"  type="button" 
                                          (click)="validateSave()">Save </button>
  
                                      </div>
                                      <div class="col-sm-1 offset-sm-10 mb-2 mt-2" *ngIf="updateButton"> 
                                        <button class="btn btn-primary btn-sm example-full-width"  type="button" 
                                        (click)="validateUpdate()">Update</button>
  
                                    </div>
                                    <div class="col-sm-1 mb-2 mt-2">
                                      <button class="btn btn-primary btn-sm example-full-width"  (click)="clear()" type="reset">Cancel</button>
                                      </div>
                                    
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-sm-12 pl-0 pr-2">
                  <div class="row">
                      <div class="card divbody borderradius p-3 pb-0">
                              <div class="col-sm-12">
                                  <div class="row">
                                      <div class="col-sm-9">
                                          <h1 class="titlecolor">Audiologists Leave List</h1>
                                      </div>
                                      <div class="search-box col-sm-3 pb-2">
                                        <input class="form-control amber-border" type="text" placeholder="Search" 
                                        formControlName="searchDoctorLeave" aria-label="Search" (keyup)="onKeyUpEvent($event)"
                                        >
                                        <div class="input-group-append">
                                           <span class="input-group-text amber lighten-3" id="basic-text1"><span class="material-symbols-outlined">search</span></span>
                                        </div>
                                     </div>
                                     
                                      <div class="col-sm-12">
                                        <table id="toDoctorLeaveLst" class="table table-bordered data-table-custom text-center">
                                          <thead>
                                            <tr class="text-center">
                                              <th>Sr.No.</th>
                                              <th>Audiologists Name</th>
                                              <th>Branch Name</th>
                                              <th>From Date</th>
                                              <th>To Date</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr
                                              *ngFor="let item of billingList | paginate : { id:'doctorLeaveMaster',
                                              itemsPerPage: size, currentPage: page, totalItems:totalLength } let i = index"
                                              (click)="prepopulation(item._id)" (click)="todoctorLeaveForm()" style="cursor:pointer; text-align: center ">
                                              <td matTooltip="Click To View">{{i+1}}</td>
                                              <td matTooltip="Click To View">{{item.doctorName}}</td>
                                              <td matTooltip="Click To View">{{item.branchName}}</td>
                                              <td matTooltip="Click To View">{{item.fromDate | date:'dd/MM/yyyy'}}</td>
                                              <td *ngIf="item.toDate == ''" matTooltip="Click To View">-</td>
                                              <td *ngIf="item.toDate !== ''" matTooltip="Click To View">{{item.toDate | date:'dd/MM/yyyy' }}</td>
                                          </tbody>
                                        </table>
                                      </div>
                                      <div class="col-sm-12 pt-0 text-right">
                                              <pagination-controls id="doctorLeaveMaster" directionLinks="true"
                                    (pageChange)="onPageChange($event)" class="float-right pagination my-pagination"></pagination-controls>
                                          
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
          </div>
      </div>
      </form>
  </div>