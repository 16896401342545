<div class="container-fluid bgcolor2" [formGroup]="inquiryForm">
    <form [formGroup]="inquiryForm" autocomplete="off" novalidate>
      <div class="row p-0">
        <div class="col-sm-1">
           
        </div>
        <div class="col-sm-11">
          <div class="row ">

            <div class="col-sm-12">
              <div class="row ">
              <div class="col-sm-2">
              
                  <h1 class="pt-2 ml-1" >Inquiry</h1>
                  </div>
                  <div class="col-sm-2 p-1 pb-2"></div>
                  <div class="col-sm-4 p-1 pb-2">
                    <div *ngIf="convertMsg" class="card divbody borderradius text-center" >
                
                      <h1 class="pt-2 ">Inquiry Converted To Patient</h1>
                    
                      </div>
                  </div> 
                  
                  <div class="col-sm-4 mt-1">
                    <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                      <a (click)="onback()" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">arrow_back</span>Back</a>
                      <a [routerLink]="['/home/calander']" class="d-flex align-items-center ms-3 pr-2 mt-2"><span class="material-symbols-outlined pr-2">calendar_month</span>Book Appoinment</a>
                      <a (click)="toInquiryList()" class="d-flex align-items-center ms-3 pr-2 mt-2"><span class="material-symbols-outlined pr-2">reorder</span>List</a>
               </div>
            </div>
          </div>
         </div>
         </div>
       
          <div class="row"> 
            <div class="col-sm-12 pr-4">
                <div class="row">
                    <div class="card divbody  borderradius mb-3 pl-4 pr-4 p-1">
                    
                          <div class="row">
                            <div class="col-sm-12">
                              <div class="row mt-2">
                                <!-- <div class=" col-sm-1 mt-2"> 
                                  <div class="row"> 
                                    <div class="col-sm-12 mt-2">
                                    <mat-label class="center" ngDefaultControl formControlName="branchWiseId" *ngIf="userType !== 'Super Admin'" >{{bID}}</mat-label>
                                    <mat-label class="center" ngDefaultControl [value]="superBranchId" *ngIf="userType == 'Super Admin'" >{{superBranchId}}</mat-label>
                                    </div>
                                    </div>
                                    </div> -->


                                    <div class=" col-sm-1 mt-3 ">
                                      <mat-label class="center" ngDefaultControl formControlName="branchWiseId" *ngIf="userType !== 'Super Admin'" >{{bID}}</mat-label>
                                      <mat-label class="center" ngDefaultControl [value]="superBranchId" *ngIf="userType == 'Super Admin'" >{{superBranchId}}</mat-label>
                                    </div>
                                    <div class=" col-sm-2">
                                      <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>Date</mat-label>
                                        <input matInput placeholder="Date" formControlName="date" readonly />
                                      </mat-form-field>
                                    </div>
                                  
                                   
                                <div class="col-sm-3 mt-2">
                                  <div class="row">
                                    <div class="col-sm-4 mt-2">
                                      <label for="" class="fw-bold me-3">*Inquiry Type:</label>
                                    <!-- <label class="fw-bold me-3"><span class="required-asterisk ">*</span>Inquiry Type :</label> -->
                                  </div>
                                  <div class="col-sm-8  mt-1">
                                    <mat-radio-group formControlName="inquiryType" required
                                    class="example-full-width" aria-label="Select Type">
                                      <div class="row col-sm-12">
                                          <div class="col-sm-7 pt-1">
                                      <mat-radio-button value="In-Person">In-Person</mat-radio-button>
                                      </div>
                                      <div class="col-sm-5 pt-1">
                                    <mat-radio-button value="Phone">Phone</mat-radio-button>
                                    </div>
                                    </div>
                                  </mat-radio-group>
                                  <mat-error
                                  *ngIf="inquiryForm.controls['inquiryType'].touched && inquiryForm.controls['inquiryType'].errors?.required">
                                      This field is mandatory.
                                </mat-error>
                                  </div>
                                 </div>
                                </div>
                           
                              </div>
                            <div class="row">
                              <div class="col-sm-3 ">
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>First Name
                                      <!-- <span class="required-asterisk">*</span> -->
                                    </mat-label>
                                    <input  matInput placeholder="First Name"  formControlName="firstName" required />
                               
                                  </mat-form-field>
                                  <mat-error
                                  *ngIf="inquiryForm.controls['firstName'].touched && inquiryForm.controls['firstName'].errors?.required">
                                      This field is mandatory.
                                </mat-error>
                                <mat-error class="margin_tp"
                                  *ngIf="inquiryForm.controls['firstName'].touched && inquiryForm.controls['firstName'].errors?.pattern">
                                      Please enter valid first name.
                                </mat-error>
                            </div>
                           
                            <div class="col-sm-3 ">
                              <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Middle Name
                                    <!-- <span class="required-asterisk">*</span> -->
                                  </mat-label>
                                  <input  matInput placeholder="Middle Name"  formControlName="middleName" required />
                             
                                </mat-form-field>
                                <mat-error
                                *ngIf="inquiryForm.controls['middleName'].touched && inquiryForm.controls['middleName'].errors?.required">
                                    This field is mandatory.
                              </mat-error>
                              <mat-error class="margin_tp"
                                *ngIf="inquiryForm.controls['middleName'].touched && inquiryForm.controls['middleName'].errors?.pattern">
                                    Please enter valid first name.
                              </mat-error>
                          </div>
                                <div class="col-sm-3 ">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>Last Name
                                          <!-- <span class="required-asterisk">*</span> -->
                                        </mat-label>
                                        <input  matInput placeholder="Last Name" formControlName="lastName" required/>
                                        
                                      </mat-form-field>
                                      <mat-error
                                        *ngIf="inquiryForm.controls['lastName'].touched && inquiryForm.controls['lastName'].errors?.required">
                                        This field is mandatory. 
                                      </mat-error>
                                      <mat-error class="margin_tp"
                                       *ngIf="inquiryForm.controls['lastName'].touched && inquiryForm.controls['lastName'].errors?.pattern">
                                      Please enter valid last name.
                                        </mat-error>
                                </div>
                                <!-- <div class="col-sm-1 mt-2">
                                  <label for="" class="genderClass">*Gender:</label>
                                 </div> -->
                           
                              <div class="col-sm-3 mt-3 genderClass">
                                <div class="row mt-2">
                                  <div class="col-sm-3  ml-0 pl-0">
                                    <label for="" class="genderClass ml-0 pl-0">Gender:</label>
                                    </div>
                                    <div class="col-sm-9"> 
                                      <mat-radio-group formControlName="sex"
                                      class="example-full-width" aria-label="Select an option">
                                        <div class="row col-sm-12 pl-2">
                                            <div class="col-sm-4 ">
                                        <mat-radio-button value="Male">Male</mat-radio-button>
                                        </div>
                                        <div class="col-sm-4">
                                      <mat-radio-button value="Female">Female</mat-radio-button>
                                      </div>
                                      <div class="col-sm-4 ">
                                      <mat-radio-button value="Other">Other</mat-radio-button>
                                      </div>
                                      </div>
                                    </mat-radio-group>
                                
                                    </div>
                                </div>
                             
                              
                              </div>
                              <div class="col-sm-3 ">
                                <div class="row">
                                  <div class="col-sm-10 mr-0 pr-0">
                                <mat-form-field class="example-full-width mr-0 pr-0" appearance="outline">
                                    <mat-label> Phone Number
                                      <!-- <span class="required-asterisk">*</span> -->
                                    </mat-label>
                                    <input  matInput maxlength="10" placeholder="Phone Number"
                                      formControlName="mobile"/>

                                  </mat-form-field>
                                  <mat-error class="margin_tp"
                                  *ngIf="inquiryForm.controls['mobile'].touched && inquiryForm.controls['mobile'].errors?.pattern">
                                      Please enter valid mobile number.
                                </mat-error>
                                <!-- <mat-error
                                *ngIf="inquiryForm.controls['mobile'].touched && inquiryForm.controls['mobile'].errors?.required">
                                This field is mandatory.
                              </mat-error> -->
                              </div> 
                              <div class="col-sm-2 wApp ml-0 pl-0">
                              <i class="bi bi-whatsapp whatsapp-icon wApp fa-lg ml-0 pl-1" (click)="WhatsApp();"
                               matTooltip="WhatsApp Integration" ></i> 
                            
                                </div>
                              </div>
                            </div>

                              <div class="col-sm-3 ">
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label> Email Address</mat-label>
                                    <input  matInput type="email" class="emailClass" placeholder=" Email" formControlName="email"  />
                                   
                                  </mat-form-field>
                                  
                                <mat-error class="margin_tp"
                                *ngIf="inquiryForm.controls['email'].touched && inquiryForm.controls['email'].errors?.pattern">
                                Please enter valid email.
                              </mat-error>
                              </div>  
                              <div class="col-sm-3">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>DOB
                                    <!-- <span class="required-asterisk">*</span> -->
                                  </mat-label>
                                  <input  formControlName="dob"  matInput [matDatepicker]="dob" id="dob" 
                                  [min]="minDate" [max]="currentdate" type="text"
                                    placeholder="DD/MM/YYYY" (dateChange)="ageCalculate()" >
           
                                  <mat-datepicker-toggle matSuffix [for]="dob">
                                  </mat-datepicker-toggle>
                                  <mat-datepicker #dob></mat-datepicker>
                                
                                </mat-form-field>
                                <mat-error class="margin_tp"
                                *ngIf="inquiryForm.controls['dob'].touched && inquiryForm.controls['dob'].errors?.pattern">
                                Please enter valid Date.
                              </mat-error>

                                <!-- <mat-error class="margin_tp"
                                  *ngIf="inquiryForm.controls['dob'].touched && inquiryForm.controls['dob'].errors?.pattern">
                                      Please enter valid Date.
                                </mat-error> -->
                              </div>
                              <div class="col-sm-3 ">
                                  <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>Age </mat-label>
                                    <input   maxlength="3" matInput placeholder=" Age" formControlName="age"  /> 
                                    </mat-form-field>
                              
                                  <!-- <mat-error class="margin_tp"
                                  *ngIf="inquiryForm.controls['age'].touched && inquiryForm.controls['age'].errors?.pattern">
                                  Please enter valid Age.
                                </mat-error>
                                     -->
                              </div>
                              <div class="col-sm-3">
                                <div class="row ">
                                  <div class="col-sm-10 mr-0 pr-0">
                                <mat-form-field class="example-full-width mr-0 pr-0" appearance="outline">
                                  <mat-label>Patient Source </mat-label>
                                  <mat-select  #select1 placeholder="Patient Source" class="colorblack"  formControlName="clientSource" >
                                    <mat-select-filter  *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'patientSource'"
                                      [array]="allPatientSource" (filteredReturn)="filteredListPatientSource =$event"></mat-select-filter>
                                    <mat-option  (click)="patientClick()" *ngFor="let item of filteredListPatientSource" 
                                     [value]="item.patientSource">
                                      {{item.patientSource}}
                                    </mat-option>
                                    
                                  </mat-select>
                                </mat-form-field>
                                </div>
                                <div class="col-sm-2 mt-2 ml-0 pl-0">
                                  <i (click)="openPatientSourcePopUp();" matTooltip="Add Patient Source" 
                                  class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg ml-0"></i>
                                  <!-- <img (click)="openPatientSourcePopUp();" matTooltip="Add New Patient Source" width="25" src="../../assets/add_img.png">  -->
                                </div>
                                </div>
                              </div> 
                              <div *ngIf="isPatient" class="col-sm-3">
                                <div class="row">  
                                  <div class="col-sm-10 mr-0 pr-0">
                                <mat-form-field class="example-full-width mr-0 pr-0" appearance="outline">
                                  <mat-label>Patient Name </mat-label>
                                  <input  (click)="openDepartmentPopUp();" matInput readonly
                                  placeholder="Patient Name" formControlName="patientName" />
                                  <!-- <mat-select  #select1 (click)="fetchAllPatientList();" formControlName="patientdropDown" >
                                    <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'firstName'"
                                    [array]="patientList"  (filteredReturn)="filteredListPatient =$event"></mat-select-filter>
                                  <mat-option *ngFor="let item of filteredListPatient" (click)="patientNameClick(item.firstName,item.lastName)" [value]="item._id"
                                  >
                                   {{ item.firstName }}  {{ item.lastName }}</mat-option>
                                  </mat-select> -->
                                </mat-form-field>
                                </div>
                                <div class="col-sm-2 mTop ml-0 pl-0">
                                  <i (click)="openPatientPopUp();" matTooltip="Add New Patient" 
                                  class="blue_col mTop fa-solid fa-circle-plus pl-1 ml-0 fa-lg"></i>
                                  <!-- <img (click)="openPatientPopUp();" matTooltip="Add New Patient" width="25" src="../../assets/add_img.png">  -->
                                </div> 
                                </div>
                              </div> 
                              <div *ngIf="isDoctor" class="col-sm-3">
                           
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Doctor Name </mat-label>
                                  <input  matInput placeholder="Doctor Name"  formControlName="doctorDropDown"  />
                                </mat-form-field>
                              </div>
      
                              <div *ngIf="isAdvertiesment" class="col-sm-3">
                           
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label> Advertisement </mat-label>
                                  <input  matInput placeholder="advertisement"  formControlName="advertisement"  />
                                </mat-form-field>
                              </div>
                              <div *ngIf="isSocialMedia" class="col-sm-3">
                           
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label> Social Media </mat-label>
                                  <input  matInput placeholder="Social Media"  formControlName="socialMedia"  />
                                </mat-form-field>
                              </div>
                              <div *ngIf="isOther" class="col-sm-3">
                           
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label> Other </mat-label>
                                  <input  matInput placeholder=""  formControlName="socialMedia"  />
                                </mat-form-field>
                              </div> 
                              <div class="col-sm-6">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Remark</mat-label>
                          
                                  <textarea maxlength="1000" matInput formControlName="remark"
                                  cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                  cdkAutosizeMinRows="3" maxlength="1000" cdkAutosizeMaxRows="5" ></textarea>
                                </mat-form-field>
                              </div>
                              </div>
                              </div>
                              
                                <div class="col-sm-12">
                                 
                                  <div class="row mt-2">
                                    
                                    <div class="col-sm-12 pb-0 mb-0">
                                      <h5 class="pb-0 mb-0">Address Details :</h5>
                                      <!-- <label class="header">Address Details :</label> -->
                                        </div>
      
                                        <div class="col-sm-3 pt-0 mt-0">
                                          <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label>Street Address 
                                              <!-- <span class="required-asterisk">*</span>  -->
                                            </mat-label>
                                            <input  matInput placeholder="Address" formControlName="streetAddress"   />
                                           
                                            </mat-form-field>
                                            <!-- <mat-error class="margin_tp"
                                          *ngIf="inquiryForm.controls['streetAddress'].touched && inquiryForm.controls['streetAddress'].errors?.required">
                                             This Field Is Mandotory.
                                        </mat-error> -->
                                      </div>
                                      <div class="col-sm-3 ">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>Taluka</mat-label>
                                          <input  matInput placeholder="Taluka" formControlName="taluka" />
                                          
                                        </mat-form-field>
                                        <mat-error class="margin_tp"
                                          *ngIf="inquiryForm.controls['taluka'].touched && inquiryForm.controls['taluka'].errors?.pattern">
                                              Please enter valid taluka.
                                        </mat-error>
                                      </div>
                                      <div class="col-sm-3 ">
                                        <!-- <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>District</mat-label>
                                          <input  matInput placeholder="District" formControlName="district" />
                                         
                                        </mat-form-field> -->
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>District</mat-label>
                                          <mat-select #select12 placeholder="District" class="colorblack"  formControlName="district"   >
                                            <mat-select-filter  *ngIf="select12.focused" [placeholder]="'Filter'" [displayMember]="'district'"
                                              [array]="districtData2" (filteredReturn)="filteredListDistrict2 =$event"></mat-select-filter>
                                            <mat-option *ngFor="let item of filteredListDistrict2" 
                                             [value]="item.district" >
                                              {{item.district}}
                                            </mat-option>
                                          </mat-select>
                                        </mat-form-field>
                                        <!-- <mat-error class="margin_tp"
                                        *ngIf="inquiryForm.controls['district'].touched && inquiryForm.controls['district'].errors?.required">
                                        This field is mandatory.
                                      </mat-error> -->
                                      </div>
                                      <div class="col-sm-3 ">
                                        <!-- <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>State</mat-label>
                                          <mat-select required #select1 placeholder="State" class="colorblack"  formControlName="state"   >
                                            <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'name'"
                                              [array]="allState"  (filteredReturn)="filteredList =$event"></mat-select-filter>
                                            <mat-option *ngFor="let item of filteredList" 
                                             [value]="item.value">
                                              {{item.name}}
                                            </mat-option>
                                          </mat-select>
                                       
                                        </mat-form-field>  -->
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>State</mat-label>
                                          <mat-select #select11 placeholder="State" class="colorblack"  formControlName="state"   >
                                            <mat-select-filter  *ngIf="select11.focused" [placeholder]="'Filter'" [displayMember]="'state'"
                                              [array]="stateCityArr2"  (filteredReturn)="filteredList2 =$event"></mat-select-filter>
                                            <mat-option *ngFor="let item of filteredList2" 
                                             [value]="item.state" (click)="getDistrict2(item.districts)">
                                              {{item.state}}
                                            </mat-option>
                                          </mat-select>
                                        
                                        </mat-form-field>
                                        <!-- <mat-error
                                          *ngIf="inquiryForm.controls['state'].touched && inquiryForm.controls['state'].errors?.required">
                                          This field is mandatory.
                                        </mat-error> -->

                                      </div>
                                      <div class="col-sm-3  ">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label>Pin Code 
                                              <!-- <span class="required-asterisk">*</span>  -->
                                            </mat-label>
                                            <input  formControlName="pincode" maxlength="6" matInput placeholder="Pincode(e.g:416008)"  /> 
                                          
                                           
                                          </mat-form-field>
                                          <mat-error *ngIf="inquiryForm.controls['pincode'].touched && inquiryForm.controls['pincode'].errors?.pattern">
                                            Please Enter Valid PinCode</mat-error>
                                   </div>
                                      <div class="col-sm-3 ">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>Country</mat-label>
                                          <input  matInput placeholder="Country" formControlName="country" />
                                         
                                        </mat-form-field>
                                        <mat-error class="margin_tp"
                                        *ngIf="inquiryForm.controls['country'].touched && inquiryForm.controls['country'].errors?.pattern">
                                            Please enter valid country.
                                      </mat-error>
                                      </div>
                                  
                                     
                                      
                                     
                                    
                              
                                </div>
                                </div>
                                <div *ngIf="isSuperAdmin" class="col-sm-12"> 
                            
                                    <div class="row mt-1">
                                      <div class="col-sm-12  pb-0 mb-0">
                                        <h5 class=" pb-0 mb-0">Select Branch  :</h5>
                                    <!-- <label class="header">Select Branch :</label> -->
                                      </div>
                                      </div>
                                      <div class="row">
              
                                  <div class="col-sm-3">
                                    <div class="row">
                                      <div class="col-sm-10 mr-0 pr-0">
                                    <mat-form-field class="example-full-width mr-0 pr-0" appearance="outline">
                                      <mat-label>Branch 
                                        <!-- <span class="required-asterisk">*</span> -->
                                      </mat-label>
                                      <mat-select  #select3 (click)="fetchBranchList();" formControlName="branchRefId" >
                                        <mat-select-filter *ngIf="select3.focused" [placeholder]="'Filter'" [displayMember]="'branchName'"
                                                    [array]="BranchList"  (filteredReturn)="filteredListBranch =$event"></mat-select-filter>
                                        <mat-option *ngFor="let item of filteredListBranch" [value]="item._id" 
                                        (click)="branchClick(item._id,item.branchName);"
                                        > 
                                         {{ item.branchName }}</mat-option>
                                        </mat-select>
                                       
                                    </mat-form-field>
                                    <mat-error class="margin_tp"
                                        *ngIf="inquiryForm.controls['branchRefId'].touched && inquiryForm.controls['branchRefId'].errors?.required">
                                        This field is mandatory.
                                      </mat-error>
                                  </div> 
                                  <div class="col-sm-2 mt-2 pl-0 ml-0 mTop">
                                   <i (click)="openBranchPopUp();" matTooltip="Add New Branch" 
                                   class="blue_col mTop fa-solid fa-circle-plus pl-1 ml-0 fa-lg"></i>
                                  </div>
                                  </div>
                                  </div>
              
                                  
                             </div>
                             <div class="col-sm-3">
                     
                            <!-- <label *ngIf="convertMsg" class="mt-3 blueCol">Inquiry Converted To Patient</label> -->
                              </div> 
                             </div>
                             
                                  <div class="col-sm-10 " *ngIf="submitButton && InquiryCreate">
                                    </div>
                                    <div class="col-sm-8" *ngIf="cancelButton && convertMsg">
                                    </div>
                                    <div class="col-sm-6" *ngIf="cancelButton && !convertMsg">
                                    </div>
                                   
                            <div class="col-sm-2 mb-2 mt-2"  *ngIf="submitButton && InquiryCreate">
                              <button class="btn btn-primary btn-sm example-full-width" type="button" (click)="submitInquiry()" >Save</button>
                            </div>
                            <div class="col-sm-2 mb-2 mt-2"  *ngIf="updateButton && InquiryUpdate">
                              <button *ngIf="!convertMsg" class="btn btn-primary btn-sm   example-full-width" type="button"  (click)="updateInquiryDetails()" >Update</button>
                              </div>
                              <div class="col-sm-2 mb-2 mt-2" *ngIf="cancelButton && !convertMsg">
                                <button class="btn btn-primary btn-sm example-full-width" type="button" (click)="redirectTo()" >Covert To Patient</button>
                                </div>
                            <div class="col-sm-2 mb-2 mt-2" *ngIf="cancelButton">
                              <button class="btn btn-primary btn-sm example-full-width"  (click)="cancelUpdate()" type="reset">Cancel</button>
                              </div>
                           
                            </div>
                        
                    </div>
                </div>
            </div>
          </div>
         
          <div class="col-sm-12 pl-0 pr-2">
            <div class="row">
                <div class="card divbody col-sm-12 borderradius p-3 pb-0">
                        <div class="col-sm-12">
                            <div class="row pt-1">
                              <div class="col-sm-9 ">
                                <h2 class="col pl-0">Inquiry List </h2>
                                  <!-- <h1 class="titlecolor"> Inquiry List</h1>   -->
                              </div> 
                            
                                <div class="search-box col-sm-3 pb-2">
                                  <input class="form-control amber-border" type="text" placeholder="Search" 
                                  formControlName="searchInquiry" aria-label="Search" (keyup)="onKeyUpEvent($event)"
                                  onkeydown="return (event.keyCode!=13);">
                                  <div class="input-group-append">
                                     <span class="input-group-text amber lighten-3" id="basic-text1"><span class="material-symbols-outlined">search</span></span>
                                  </div>
                               </div>
                               
                              <!-- <div class="col-sm-3">
                                  <div class="input-group md-form form-sm form-1 pl-0">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text purple lighten-3" id="basic-text1"><i class="fas fa-search text-white"
                                          aria-hidden="true"></i></span>
                                    </div>
                                    <input  class="form-control my-0 py-1" type="text" formControlName="searchInquiry" aria-label="Search" (keyup)="onKeyUpEvent($event)"
                                     placeholder="Search" aria-label="Search">
                                  </div>
                              </div> -->  
                                <div class="col-sm-12 table-responsive">
                                     <table id="toInquiryList" 
                                  class="table table-bordered data-table-custom">
                                    <thead>
                                      <tr class="text-center">
                                        <th>Inquiry ID</th>
                                        <th *ngIf="userType == 'Super Admin'">Branch Name</th>
                                        <th>Inquiry Type</th>
                                        <th> Name</th>
                                        <th>Phone Number</th>
                                        <th>Email</th>
                                        <th>Address</th>
                                        <!-- <th>WhatsApp Integration</th> -->
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr *ngFor="let Inquiry of listofInquiry | paginate : { id:'InquiryMaster',
                                      itemsPerPage: size, currentPage: page, totalItems:totalLength }"
                                      (click)="prepopulation(Inquiry._id)"
                      (click)="toInquiryForm()" style="cursor:pointer; text-align: center ">
                      <td matTooltip="click to view">{{Inquiry.branchWiseId}}</td>
                      <td *ngIf="userType == 'Super Admin'" matTooltip="click to view"  
                    >{{Inquiry.branchName}}
                  </td>
                                        <td matTooltip="click to view">{{Inquiry.inquiryType}}</td>
                                        <td matTooltip="click to view">{{Inquiry.firstName}} {{Inquiry.lastName}}</td>
                                        <td matTooltip="click to view">{{Inquiry.mobile}}</td>
                                        <td matTooltip="click to view">{{Inquiry.email}}</td>
                                        <td matTooltip="click to view">{{Inquiry.streetAddress}}</td>
                                        <!-- <td class="text-center">
                                          <i matTooltip="View" (click)="prepopulation(Inquiry._id)" class="fas fa-eye"></i>
                                         </td> -->
                                      </tr>
                                      
                                      
                                    </tbody>
                                  </table>
                                </div>
                                <div class="col-sm-12 pt-0 text-right">
                                  <pagination-controls id="InquiryMaster" directionLinks="true"  
                                  (pageChange)="onPageChange($event)" class="float-right pagination my-pagination"></pagination-controls>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        
     
    </div>
      </div>
      </form>
    </div>
    