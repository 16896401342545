import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from "../api-service.service";
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { element } from 'protractor';
import { ActivatedRoute } from "@angular/router";
import { MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PopupFollowupreasonComponent } from "../popup-followupreason/popup-followupreason.component";
import { PopupBookappointmentComponent } from "../popup-bookappointment/popup-bookappointment.component";
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppoinmentPopupComponent } from '../appoinment-popup/appoinment-popup.component';
import { Router } from "@angular/router";
import { PopUpPatientComponent } from '../pop-up-patient/pop-up-patient.component';
import { PopupTotalBillComponent } from '../popup-total-bill/popup-total-bill.component';
import { PopupCancelConfirmComponent } from '../popup-cancel-confirm/popup-cancel-confirm.component';
import { PopupFollowUpComponent } from '../popup-follow-up/popup-follow-up.component';
import { Observable, Subscriber } from "rxjs";
import {
  HttpClient,
  HttpEventType,
  HttpErrorResponse
} from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { PdfviewerComponent } from '../pdfviewer/pdfviewer.component';
import { PatientTrackingStatusComponent } from '../patient-tracking-status/patient-tracking-status.component';
@Component({
  selector: 'app-outpatient',
  templateUrl: './outpatient.component.html',
  styleUrls: ['./outpatient.component.scss']
})
export class OutpatientComponent implements OnInit {

repair = false;
patient = true;
paientdetails;
patientRef;
  userType: string;
  clinicRef: string;
  branchRef: string;
  checkupDetailsForm: FormGroup; 
  patientFileDataForm: FormGroup; 
  superClinicRefId: string;
  servicerateList = [];
  repairList:any;
  repairD = new Date();
  datearray = [];
  statusArr = [];
  isLinear = false;
  submitButton = true;
  updateButton =false;
  matchId: number;
  repairStatus = "";
  repairStatusArr = [];
  filteredListRepair: any;
  disabledate = true;
  repairRef: '';
  cid: any;
  followupReasonList: any;
  filteredList1: any;
  currentdate: Date;
  appointmentInfo: any;
  billingData = [];
  billingInfo = [];
  totalAmount = 0;
  historyData = [];
  aptDate: string;
  billingList = [];
  totalLength = 0;
  tempServicArr = [];
  dataInfo = [];
  tempSerInfo = [];
  refData:any;
  noOfRef:any;
  NoOfVisits: any;
  payment: boolean;
  paymentData: any;
  patientData: any;
  product: any;
  productData: any;
  expdate = '';
  bookDate: any;
  bookRefId: any;
  ariaSelected1="false";
  ariaSelected2="false";
  ariaSelected3="false";
  ariaSelected4="false";
  patientInfo: string;
  errormessage: string;
  filedata: any;
  fileName: string;
  fileShow: boolean;
  fileDataArr: any;
  files=[{
    base64:"",
    name: "filee",
    id:"1"
  },
  {
    base64:"",
    name: "file2",
    id:"2"
  },
  {
    base64:"",
    name: "file3",
    id:"3"
  }]
  progress: number;
  allPatientStatus: any;
  filteredListPatientStatus: any;
  trackingStatus: any;
  fileDataArrLength: any;
  constructor(public apimicroservice: ApiServiceService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public router: Router,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.fileName= '';
    this.bookDate = null;
    this.bookRefId = null;
    this.currentdate = new Date();
    this.submitButton = true;
  this.updateButton =false;
  this.expdate = 'none';
    this.userType = sessionStorage.getItem('userType');
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
  this.paientdetails = localStorage.getItem("patient");  
  this.aptDate = sessionStorage.getItem("aptDate");
  //
  
  this.superClinicRefId = sessionStorage.getItem("superClinicRefId");
 // this.superClinicRefId = this.route.snapshot.paramMap.get('pid');
  let ddd= localStorage.getItem("patient");

  this.paientdetails = JSON.parse(ddd);
  //
  
  this.statusArr = [];
  this.billingData = [];
  this.billingInfo = [];
  this.tempSerInfo = [];
  this.totalAmount = 0;
  this.patienthistoryDates();
 this.checkupDetailsForm = this.fb.group({
  "complaints": new FormControl(''),
  "observations": new FormControl(''),
  "diagnoses": new FormControl(''),
  "notes": new FormControl(''),
  "trackingStatusName": new FormControl(''),
  "followupReasons":new FormControl(''),
  "followupDate":new FormControl(''),
 "serviceRateInfo": this.fb.array([]),
 "repairNo": new FormControl(''),
 "repairDateOf" : new FormControl(''),
 "repairDateto":new FormControl(''),
 "patientRefId":new FormControl(this.paientdetails.patientRefId),
 "patientName":new FormControl(this.paientdetails.patientName),
 "appointId":new FormControl(this.paientdetails.appointId),
 "searchRepairId":new FormControl(''),
 "aptStatus": new FormControl('Completed'), 
 "appointDate":new FormControl(''),
 
}); 
this.patientFileDataForm = this.fb.group({
  "appointId": new FormControl(''),
  "patientFile": new FormControl(''),
  "patientFileName": new FormControl(''),
  "fileUploadDate": new FormControl(''),
  "patientRefId":new FormControl(''),
  "patientName":new FormControl(''),
  "createdAt":new FormControl(''),
});
this.checkupDetailsForm.get("followupReasons").setValue('Follow Up Reasons...');
//  this.fetchRepairList();
  // this.showTracker();
  //this.fetchServiceRateList();
  this.fetchPatientStatusList();
  this.fetchFollowUpReasonList();
  this.loadPatientHistory();
  this.getpaymentHistory();
  this.getTotalRef();
  this.totalVisits();
  this.totalBuisness();
  this.getPatientData();
  this.getProductHistory();
  this.getPatientFileData();
  this.checkupDetailsForm.controls['followupDate'].setValue(this.currentdate);
  }
  getPatientData()
  {
    this.apimicroservice.getPatientById(this.paientdetails.patientRefId).subscribe(res => {
      //
      this.patientData = res.data[0]; 
    });
    
  }
reset(){
  this.submitButton = true;
  this.updateButton =false;
  this.billingData = [];
  this.billingInfo = [];
  this.currentdate = new Date();
    this.userType = sessionStorage.getItem('userType');
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
  this.paientdetails = localStorage.getItem("patient");
  this.superClinicRefId = sessionStorage.getItem("superClinicRefId");
 // this.superClinicRefId = this.route.snapshot.paramMap.get('pid');
  let ddd= localStorage.getItem("patient");
  this.paientdetails = JSON.parse(ddd);
  //
  
  this.statusArr = [];
 this.checkupDetailsForm = this.fb.group({
  "complaints": new FormControl(''),
  "observations": new FormControl(''),
  "diagnoses": new FormControl(''),
  "notes": new FormControl(''),
  "trackingStatusName": new FormControl(''),
  "followupReasons":new FormControl(''),
  "followupDate":new FormControl(''),
 "serviceRateInfo": this.fb.array([]), 
 "repairNo": new FormControl(''),
 "repairDateOf" : new FormControl(''),
 "repairDateto":new FormControl(''),
 "patientRefId":new FormControl(this.paientdetails.patientRefId),
 "patientName":new FormControl(this.paientdetails.patientName),
 "appointId":new FormControl(this.paientdetails.appointId),
 "searchRepairId":new FormControl(''),
 "branchRefId":new FormControl(this.paientdetails.branchRefId),
 "branchName":new FormControl(this.paientdetails.branchName),
 "aptStatus": new FormControl('Completed'), 
 "appointDate":new FormControl(''),
});
  this.fetchServiceRateList();
  this.fetchFollowUpReasonList();
  this.checkupDetailsForm.controls['followupDate'].setValue(this.currentdate);
}
  showTracker(status){
   let ss = "";
   ss = status;
    for(let i=0;i<this.statusArr.length;i++){
      if(ss.indexOf(this.statusArr[i].statusVal) > -1 ){
              this.statusArr[i].status = 'completed';
              this.matchId = this.statusArr[i].id;
      }
    }
    for(let i=0;i<=this.matchId;i++){
      this.statusArr[i].status = 'completed';
    }
     let j = this.matchId + 1;
     this.statusArr[j].status = "active";
     //
  }

fetchServiceRateList(){
  let id = '';
  let clinicId;
  // this.superClinicRefId = sessionStorage.getItem("superClinicRefId");
  this.clinicRef = sessionStorage.getItem('clinicRef');
  if(this.userType !== 'Super Admin'){
   clinicId = this.clinicRef;
  }else{
    clinicId = this.clinicRef;
  }
  //
  //
  this.apimicroservice.getServiceRateById(id,clinicId).subscribe(res => {
    //
   let data = res.data[0];
   this.servicerateList = data.serviceRateInfo;
   if(data !== undefined){
    data.serviceRateInfo.forEach((element,i) => {
      this.billingData.push({"billingCompanyRefId":element.billingCompanyRefId,"billingCompanyName":element.billingCompanyName});
      this.addNew(element,i)
    });
    //
    if(this.billingData.length > 0){
      // this.billingData.forEach((c) =>{
      //  if(!this.billingInfo.includes(c)){
      //   this.billingInfo.push(c);
      //  }
      // });

      const ids = this.billingData.map(o => o.billingCompanyRefId);
      this.billingInfo = this.billingData.filter(({billingCompanyRefId},index) => !ids.includes(billingCompanyRefId,index + 1));
      this.getLastIDBilling();
    }
    //
   }
   //
  });
  
}

patienthistoryDates(){

  //
  //
  this.apimicroservice.getPatientDateHistoryById(this.paientdetails.patientRefId,this.paientdetails.appointId).subscribe(res => {
    //
    let ss;
    if(res.data.length > 0){
      this.updateButton = true;
      this.submitButton = false;
      // res.data.forEach(element => {

      //   this.datearray.push(element);
       
      //   });
      this.datearray = res.data;
        this.checkupDetailsForm = this.fb.group({
          "complaints": new FormControl(this.datearray[0].complaints),
          "observations": new FormControl(this.datearray[0].observations),
          "diagnoses": new FormControl(this.datearray[0].diagnoses),
          "notes": new FormControl(this.datearray[0].notes),
          "trackingStatusName": new FormControl(this.datearray[0].trackingStatusName),
          
          "followupReasons":new FormControl(this.datearray[0].followupReasons),
          "followupDate":new FormControl(this.datearray[0].followupDate),
         "serviceRateInfo": this.fb.array([]),
         "repairNo": new FormControl(),
         "repairDateOf" : new FormControl(),
         "repairDateto": new FormControl(),
         "patientRefId":new FormControl(this.paientdetails.patientRefId),
         "patientName":new FormControl(this.paientdetails.patientName),
         "branchRefId":new FormControl(this.datearray[0].branchRefId),
         "branchName":new FormControl(this.datearray[0].branchName),
         "appointId":new FormControl(this.paientdetails.appointId),
         "searchRepairId":new FormControl(),
         "aptStatus": new FormControl(this.datearray[0].aptStatus), 
         "appointDate":new FormControl(this.datearray[0].appointDate),
        });
        this.cid = this.datearray[0]._id
        let preArr = [];
        this.datearray[0].serviceRateInfo.forEach(element => {
          
              preArr.push(element);
              //    
              this.billingData.push({"billingCompanyRefId":element.billingCompanyRefId,"billingCompanyName":element.billingCompanyName});   
              
              
              const tmpDict = {};
    
              tmpDict['serviceRefId'] = new FormControl(element.serviceRefId);
              tmpDict['addService'] = new FormControl(element.addService);
              tmpDict['serviceName'] = new FormControl(element.serviceName);
              tmpDict['testType'] = new FormControl(element.testType);
              tmpDict['billingCompanyRefId'] = new FormControl(element.billingCompanyRefId),
              tmpDict['billingCompanyName'] = new FormControl(element.billingCompanyName),
              tmpDict['rate'] = new FormControl(element.rate);
              tmpDict['discount'] = new FormControl(element.discount,[Validators.pattern("^[0-9]*$"),Validators.minLength(2)]);
              tmpDict['quantity'] = new FormControl(element.quantity,[Validators.pattern("^[0-9]*$"),Validators.minLength(1)]);
              tmpDict['hsnSac'] = new FormControl(element.hsnSac);
              tmpDict['sgst'] = new FormControl(element.sgst);
              tmpDict['cgst'] = new FormControl(element.cgst);
              tmpDict['igst'] = new FormControl(element.igst);
              if(element.addService == true){
               
                tmpDict['billGenerate'] = new FormControl('yes');
                this.tempSerInfo.push(element);
              }else{
                tmpDict['billGenerate'] = new FormControl('no');
              }
              const creds = this.checkupDetailsForm.controls.serviceRateInfo as FormArray;
               creds.push(new FormGroup(tmpDict));
               
               //
              });
              
              const creds = this.checkupDetailsForm.controls.serviceRateInfo as FormArray;
              creds.controls.forEach(ele =>{
                if(ele.get('addService').value == true || ele.get('addService').value == undefined){
                  
                  ele.get('addService').disable();
                  
                  // ele.get('discount').disable();
                  // ele.get('quantity').disable();
                }else{
                  ele.get('addService').enable();
                  // ele.get('discount').enable();
                  // ele.get('quantity').enable();
                }
              });
              //
              this.fetchBillList();
              
              const ids = this.billingData.map(o => o.billingCompanyRefId);
              this.billingInfo = this.billingData.filter(({billingCompanyRefId},index) => !ids.includes(billingCompanyRefId,index + 1));
              //
              this.getLastIDBilling();
              
    }else{
      this.fetchServiceRateList();
     
      this.updateButton = false;
      this.submitButton = true;
    }
  });
}

getLastIDBilling(){
  //
let billComp = '';
let uniqNo:any;
  if(this.billingInfo.length > 0){
        
    this.billingInfo.forEach((element,index) => {
      if(element.billingCompanyRefId !== undefined)
      {
      this.apimicroservice.getLastBillID(element.billingCompanyRefId).subscribe(res => {
        //   
        // if(res['data']['data'] == '')
        // {
        //   this.inquiryForm.controls['branchWiseId'].setValue('1');
        // }
        if (res['status']) {
          billComp = element.billingCompanyName.substring(0,3);
          uniqNo = Math.floor(1000 + Math.random() * 9000);
          let billIdd = res['data']['totalItems'];
          this.billingInfo[index]['billingId'] = billIdd + 1;
          let id = billIdd + 1;
          this.billingInfo[index]['billNo'] = billComp + "-" + uniqNo + id;
          // this.inquiryForm.controls['branchWiseId'].setValue(billIdd + 1);

        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
       
      })
    }
    });
  //
}
}

  
loadPatientHistory(){
let appId = '';
  this.apimicroservice.getPatientDateHistoryById(this.paientdetails.patientRefId,appId).subscribe(res => {
    this.historyData = res.data; 
    this.trackingStatus ='';  
    if(res.data.length > 0)
    { 
      this.trackingStatus =res.data[0].trackingStatusName;
      
    }

    
    //
    //
  });
  }
  
 

//
addNew(data,i) {
  //
  const creds = this.checkupDetailsForm.controls.serviceRateInfo as FormArray;
      creds.push(
      this.fb.group({
        "serviceRefId": new FormControl(data.serviceRefId),
        "addService": new FormControl(false),
        "serviceName": new FormControl(data.serviceName),
        "testType": new FormControl(data.testType),
        "rate": new FormControl(data.rate),
        "quantity": new FormControl(1,[Validators.pattern("^[0-9]*$"),Validators.minLength(1)]),
        "billingCompanyRefId": new FormControl(data.billingCompanyRefId),
        "billingCompanyName":new FormControl(data.billingCompanyName),
        "discount": new FormControl('',[Validators.pattern("^[0-9]*$"),Validators.minLength(2)]),  
        "hsnSac":new FormControl(data.hasSac),
        "sgst":new FormControl(data.sgst),
        "cgst":new FormControl(data.cgst),
        "igst":new FormControl(data.igst),
        "billGenerate":new FormControl(''),
      toggle: false

})
);
let serviceControl = (<FormArray>this.checkupDetailsForm.get("serviceRateInfo")).at(i);
serviceControl.get('discount').disable();
}

submitCheckupDetails() {
  let payload = this.checkupDetailsForm.value;
  //
  if(this.checkupDetailsForm.invalid) {   
    // this.checkupDetailsForm.get('complaints').markAsTouched();
    // this.checkupDetailsForm.get('observations').markAsTouched();
    // this.checkupDetailsForm.get('diagnoses').markAsTouched();  
  }
  else{
    // payload.serviceRateInfo.forEach(ele =>{
    //   if(ele.addService == true){
    //     let amt = 0;
    //     if(ele.discount !== ''){
    //       amt = parseInt(ele.rate) - parseInt(ele.discount);
    //       this.totalAmount = this.totalAmount + amt;
    //     }else{
    //       this.totalAmount = this.totalAmount + parseInt(ele.rate);
    //     }
        
    //   }
      
    // });
    // //
    this.addCheckupDetails();
  }
}
addCheckupDetails(){
  let payload = this.checkupDetailsForm.value;
  //
  let billingPayload = {};
  let appointPayload ={};
  appointPayload['aptStatus'] = 'Completed';
  //let billingPayload = this.paientdetails;
  let info = [];
  payload.clinicRefId = this.clinicRef;
  payload.branchRefId = this.branchRef;
  this.spinner.show();
  payload.appointDate = new Date(this.paientdetails.dateTime);
  this.apimicroservice.addCheckupDetails(payload).subscribe(res => {
    this.spinner.hide();
    //
    if(res.status){ 
      this.apimicroservice.openSnackbar(res.message);
      this.apimicroservice.updateAppointment(this.paientdetails.id,appointPayload).subscribe(res => {
        if(res.status){
          if(this.billingInfo.length > 0){
        
            this.billingInfo.forEach(element => {
              billingPayload = {};
              info = [];
              let totalBill = 0;
              let totalBalance = 0;
              payload.serviceRateInfo.forEach(ele =>{
               if(element.billingCompanyRefId === ele.billingCompanyRefId && ele.addService === true){
                 let totalRate = 0;
                 let totalServiceAmount = 0;
                 totalRate = parseInt(ele.quantity) * parseInt(ele.rate);

                 let sgstval = 0;
                 let cgstval = 0;
                 let igstval = 0;
                 if(ele.sgst !== null){
                   sgstval = (totalRate * parseInt(ele.sgst)) /100
                 }
                 if(ele.cgst !== null){
                   cgstval = (totalRate * parseInt(ele.cgst)) /100
                 }
                 if(ele.igst !== null){
                   igstval = (totalRate * parseInt(ele.igst)) /100
                 }
                 if(ele.sgst !== null && ele.cgst !== null){
                  totalServiceAmount = totalRate + sgstval + cgstval;
                 }else if(ele.igst !== null){
                  totalServiceAmount = totalRate + igstval;
                 }else{
                  totalServiceAmount = totalRate;
                }
                 if(ele.discount !== ''){
                 totalServiceAmount = totalServiceAmount - parseInt(ele.discount);
                 }
                 info.push({"serviceName":ele.serviceName,"testType":ele.testType,"rate":ele.rate,"discount":ele.discount,"quantity":ele.quantity,"status":"unpaid","totalRate":totalRate,"totalServiceAmount":totalServiceAmount,"hsnSac":ele.hsnSac,"sgst":ele.sgst,"cgst":ele.cgst,"igst":ele.igst});
                 let amt = 0;
            if(ele.discount !== ''){
              //amt = parseInt(ele.rate) - parseInt(ele.discount);
              amt = totalRate - parseInt(ele.discount);
              totalBill = totalBill + amt;
            }else{
             // totalBill = totalBill + parseInt(ele.rate);
             totalBill = totalBill + totalRate;
            }
            totalBalance = totalBalance + totalServiceAmount;
            // let sgstval = 0;
            // let cgstval = 0;
            // let igstval = 0;
            // if(ele.sgst !== null){
            //   sgstval = (totalBill * ele.sgst) /100
            // }
            // if(ele.cgst !== null){
            //   cgstval = (totalBill * ele.cgst) /100
            // }
            // if(ele.igst !== null){
            //   igstval = (totalBill * ele.igst) /100
            // }
            // if(ele.sgst !== null || ele.igst !== null){
            //   totalBill = totalBill + sgstval + cgstval;
            // }else if(ele.igst !== null){
            //   totalBill = totalBill + igstval;
            // }
            
               }
              });
              if(info.length > 0){
                billingPayload = this.paientdetails;
                billingPayload["mobile"] = this.paientdetails.Mob;
                billingPayload['billingCompanyRefId'] = element.billingCompanyRefId;
                billingPayload['billingCompanyName'] = element.billingCompanyName;
                billingPayload['billingId'] = element.billingId;
                billingPayload['billNo'] = element.billNo;
                billingPayload['totalAmount'] = totalBill;
                billingPayload['balanceAmount'] = totalBalance;
                billingPayload['serviceInfo'] = info;
                billingPayload['paymentStatus'] = "Unpaid";
                //
                this.apimicroservice.addBillingDetails(billingPayload).subscribe(res => {
                  if(res.status){
                    this.apimicroservice.openSnackbar("bill generated");
                    this.ngOnInit();
                  }
                }); 


              }
              
            });
            window.location.reload();
            // //
          }
        }else{

        }
      });
      
    }else{
      //this.apimicroservice.openSnackbar(res.message);
    }
  });  
}

updateCheckupDetails() {
  let payload = this.checkupDetailsForm.value;
  //
  if(this.checkupDetailsForm.invalid) {   
    // this.checkupDetailsForm.get('complaints').markAsTouched();
    // this.checkupDetailsForm.get('observations').markAsTouched();
    // this.checkupDetailsForm.get('diagnoses').markAsTouched();  
  }
  else{
   this.updateCheckup();
  }
}

updateCheckup(){
  this.spinner.show();
    let payload = this.checkupDetailsForm.value;
    payload.appointDate = new Date(this.paientdetails.dateTime);
    //
    payload.patientFile = this.filedata;
  let billingPayload = {};
  let billingPayloadNew = {};
  // payload.serviceRateInfo.forEach(ele =>{
  //   if(ele.billGenerate == 'no'){
  //    this.dataInfo.push(ele);
  //   }
  //  });
  //let dataInfo = [];
  //let serviceInfo2 = payload.serviceRateInfo;
  
  this.tempSerInfo.forEach((ele,index) =>{
    if(ele.addService == true){
      payload.serviceRateInfo[index]['addService'] = true;
    }
  });
  //
 
  this.dataInfo = payload.serviceRateInfo;
  //
  
  //let billingPayload = this.paientdetails;
  let info = [];
  let tempInfo = [];
    
     this.apimicroservice.updateCheckupDetails(this.cid,payload).subscribe(res => {
       //
       this.spinner.hide();
       
       if(res.status){
        
         this.apimicroservice.openSnackbar(res.message);
        
         this.billingList.forEach(element =>{
          billingPayload = {};
          info = [];
          let totalBill = 0;
          let totalBalance = 0;
          let totalsgstAmt = 0;
          let totalcgstAmt = 0;
          let totaligstAmt = 0;
          this.dataInfo.forEach(ele => {
            if(element.billingCompanyRefId === ele.billingCompanyRefId && ele.addService === true && ele.billGenerate == 'no'){
              let totalRate = 0;
              let totalServiceAmount = 0;
              let sgstAmt = 0;
              let cgstAmt = 0;
              let igstAmt = 0;

              let sgstval = 0;
              let cgstval = 0;
              let igstval = 0;

                 
              totalRate = parseInt(ele.quantity) * parseInt(ele.rate);

              if(ele.sgst !== null){
                sgstval = (totalRate * parseInt(ele.sgst)) /100
              }
              if(ele.cgst !== null){
                cgstval = (totalRate * parseInt(ele.cgst)) /100
              }
              if(ele.igst !== null){
                igstval = (totalRate * parseInt(ele.igst)) /100
              }
              if(ele.sgst !== null && ele.cgst !== null){
               totalServiceAmount = totalRate + sgstval + cgstval;
              }else if(ele.igst !== null){
               totalServiceAmount = totalRate + igstval;
              }else{
                totalServiceAmount = totalRate;
              }
              if(ele.discount !== ''){
              totalServiceAmount = totalServiceAmount - parseInt(ele.discount);
              }

              info.push({"serviceName":ele.serviceName,"testType":ele.testType,"rate":ele.rate,"discount":ele.discount,"quantity":ele.quantity,"status":"unpaid","totalRate":totalRate,"totalServiceAmount":totalServiceAmount,"hsnSac":ele.hsnSac,"sgst":ele.sgst,"cgst":ele.cgst,"igst":ele.igst});
              let amt = 0;
         if(ele.discount !== null){
           //amt = parseInt(ele.rate) - parseInt(ele.discount);
           amt = totalRate - parseInt(ele.discount);
           if(ele.sgst !== null){
            
            sgstAmt = (totalRate * ele.sgst) / 100;
            totalsgstAmt = totalsgstAmt + sgstAmt;
             ////
           }
           if(ele.cgst !== null){
             
             cgstAmt = (totalRate * ele.cgst) / 100;
            totalcgstAmt = totalcgstAmt + cgstAmt;
             ////
           }
           if(ele.igst !== null){
            
            igstAmt = (totalRate * ele.igst) / 100;
            totaligstAmt = totaligstAmt + igstAmt;
            // //
           }
           totalBalance = totalBill + amt + totalsgstAmt + totalcgstAmt;
           totalBill = totalBill + amt;
           
         }else{
          // totalBill = totalBill + parseInt(ele.rate);
          if(ele.sgst !== null){
            
            sgstAmt = (totalRate * ele.sgst) / 100;
            totalsgstAmt = totalsgstAmt + sgstAmt;
             ////
           }
           if(ele.cgst !== null){
             
             cgstAmt = (totalRate * ele.cgst) / 100;
            totalcgstAmt = totalcgstAmt + cgstAmt;
             ////
           }
           if(ele.igst !== null){
            
            igstAmt = (totalRate * ele.igst) / 100;
            totaligstAmt = totaligstAmt + igstAmt;
            // //
           }
           totalBalance = totalBill + totalRate + totalsgstAmt + totalcgstAmt;
          totalBill = totalBill + totalRate;
          
         }
                if(tempInfo.length > 0){
                 //const found = tempInfo.some(o => o.billingCompanyRefId === element.billingCompanyRefId);
               let billArr = tempInfo.filter(o => o.billingCompanyRefId !== element.billingCompanyRefId);
               tempInfo = billArr;
               //
                }
            }else if(element.billingCompanyRefId !== ele.billingCompanyRefId && ele.addService === true && ele.billGenerate == 'no') {
              tempInfo.push(ele);
              //
            }   
        });
    
        if(info.length > 0){
                // billingPayload = this.paientdetails;
                // billingPayload['billingCompanyRefId'] = element.billingCompanyRefId;
                // billingPayload['billingCompanyName'] = element.billingCompanyName;
                // billingPayload['billingId'] = element.billingId;
                // billingPayload['billNo'] = element.billNo;
                if(element.totalAmount !== null){
                  totalBill = totalBill + element.totalAmount;
                }
                //
                //
                if(element.balanceAmount !== undefined){
                  totalBalance = totalBalance + element.balanceAmount;
                  billingPayload['balanceAmount'] = totalBalance;
                }
                billingPayload['totalAmount'] = totalBill;
                element.serviceInfo.map(ele =>{
                  info.push(ele);
                });
                
                billingPayload['serviceInfo'] = info;
                billingPayload['paymentStatus'] = "Unpaid";
                //
                this.tempServicArr.push(element);
                
                //
                this.apimicroservice.updateBillingDetails(billingPayload,element._id).subscribe(res => {
                  if(res.status){
                    this.apimicroservice.openSnackbar("bill updated");
                   // this.ngOnInit();
                  }
                });
          }
    
      });
    
      //
      if(this.tempServicArr.length > 0){
        this.tempServicArr.forEach(ele =>{
          let billArr = tempInfo.filter(o => o.billingCompanyRefId !== ele.billingCompanyRefId);
          tempInfo = billArr;
        //
      });
      }
      if(tempInfo.length > 0){
        if(this.billingInfo.length > 0){
            
          this.billingInfo.forEach(element => {
            billingPayloadNew = {};
            info = [];
            let totalBill = 0;
            let totalBalance = 0;
            tempInfo.forEach(ele =>{
             if(element.billingCompanyRefId === ele.billingCompanyRefId && ele.addService === true && ele.billGenerate == 'no'){
              let totalRate = 0;
              let totalServiceAmount = 0;
              let sgstval = 0;
              let cgstval = 0;
              let igstval = 0;

                 totalRate = parseInt(ele.quantity) * parseInt(ele.rate);

                 if(ele.sgst !== null){
                  sgstval = (totalRate * parseInt(ele.sgst)) /100
                }
                if(ele.cgst !== null){
                  cgstval = (totalRate * parseInt(ele.cgst)) /100
                }
                if(ele.igst !== null){
                  igstval = (totalRate * parseInt(ele.igst)) /100
                }
                if(ele.sgst !== null && ele.cgst !== null){
                 totalServiceAmount = totalRate + sgstval + cgstval;
                }else if(ele.igst !== null){
                 totalServiceAmount = totalRate + igstval;
                }else{
                  totalServiceAmount = totalRate;
                }
                if(ele.discount !== ''){
                totalServiceAmount = totalServiceAmount - parseInt(ele.discount);
                }

                 info.push({"serviceName":ele.serviceName,"testType":ele.testType,"rate":ele.rate,"discount":ele.discount,"quantity":ele.quantity,"status":"unpaid","totalRate":totalRate,"totalServiceAmount":totalServiceAmount,"hsnSac":ele.hsnSac,"sgst":ele.sgst,"cgst":ele.cgst,"igst":ele.igst});
                 let amt = 0;
            if(ele.discount !== null){
              //amt = parseInt(ele.rate) - parseInt(ele.discount);
              amt = totalRate - parseInt(ele.discount);
              totalBill = totalBill + amt;
            }else{
             // totalBill = totalBill + parseInt(ele.rate);
             totalBill = totalBill + totalRate;
            }
            totalBalance = totalBalance + totalServiceAmount;
             }
            });
            if(info.length > 0){
              billingPayloadNew = this.paientdetails;
              //
              billingPayloadNew["mobile"] = this.paientdetails.Mob;
              billingPayloadNew['billingCompanyRefId'] = element.billingCompanyRefId;
              billingPayloadNew['billingCompanyName'] = element.billingCompanyName;
              billingPayloadNew['billingId'] = element.billingId;
              billingPayloadNew['billNo'] = element.billNo;
              billingPayloadNew['totalAmount'] = totalBill;
              billingPayloadNew['balanceAmount'] = totalBalance;
              billingPayloadNew['serviceInfo'] = info;
              billingPayloadNew['paymentStatus'] = "Unpaid";
              //
              this.apimicroservice.addBillingDetails(billingPayloadNew).subscribe(res => {
                if(res.status){
                  this.apimicroservice.openSnackbar("bill generated");
                 // this.ngOnInit();

                }
              });
            }
            
          });
          // //
        }
        
      }
      this.ngOnInit();
       }else{
       //  //this.apimicroservice.openSnackbar(res.message);
       }
     });
}

fetchRepairList(){
  let id = '';
  // this.statusArr.forEach(element =>{
  //   this.repairStatusArr.push(element);
  // })
  //
  this.apimicroservice.getRepairDataByPatientId(this.paientdetails.patientRefId).subscribe(res => {
    
    if(res.data.length != 0 ){
      this.repairList = res.data;
      this.filteredListRepair = this.repairList.slice();
      this.apimicroservice.openSnackbar(res.message);
     if(res.data.length == 0 ){
      this.statusArr = [];
     }else{
      this.onSelectRepairNo(this.repairList[0]._id);
     }
     
    }else{
      // this.statusArr = [];
      this.repairList = [];
      //this.apimicroservice.openSnackbar(res.message);
    }
  });  
}

selectService(event:any,i){
  //
  let serviceControl = (<FormArray>this.checkupDetailsForm.get("serviceRateInfo")).at(i);
  
    let serviceVal = serviceControl.get("addService").value;
    
  if(serviceVal == true){
    serviceControl.get('discount').enable();
    
  }else{
    serviceControl.get('discount').disable();
    
  }
  
}

onSelectRepairNo(id){
  this.repairRef = id;
  this.disabledate = false;
  // let id = event.target.value;
  // id = parseInt(id);
  this.apimicroservice.getRepairStatusFlowById(id).subscribe(res => {
    if(res.data.length > 0){
      if(res.data[0].stepperInfo.length > 0){
        this.statusArr = res.data[0].stepperInfo;
        //)
        this.checkupDetailsForm.controls['repairNo'].setValue(res.data[0].repairRefId);
        //this.stepperRefId =  res.data[0]._id;
        //this.norecord = false;
      }
    }else{
      this.statusArr = [];
    }
  })
  // this.apimicroservice.getRepairStatusFlowById(id).subscribe(res => {
  //   //
  //       if(res.data.length > 0){
  //         if(res.data[0].stepperInfo.length > 0){
  //           this.statusArr = res.data[0].stepperInfo;
  //           //this.stepperRefId =  res.data[0]._id;
  //           //this.norecord = false;
  //         }
  //       }else{
  //         this.statusArr = [];
  //       } 
  //     })
}

onSelectRepairDate(){
  let payload = this.checkupDetailsForm.value;
  this.apimicroservice.getRepairStatusFlowByRepairDate('',payload.repairDateOf,payload.repairDateto).subscribe(res => {
    if(res.data.length > 0){
      this.repairList = [];
      res.data.forEach(element => {
        element['_id'] = element.repairRefId
      });
      this.repairList = res.data;
    }else{
      this.statusArr = [];
      this.repairList = [];
    }
  })
}

onSelectDiscount(event,i){
  let dis = event.target.value;
  let serviceControl = (<FormArray>this.checkupDetailsForm.get("serviceRateInfo")).at(i);
  let rateVal = serviceControl.get("rate").value;

  if(parseInt(dis) > parseInt(rateVal)){
   this.apimicroservice.openSnackbar("Please enter discount value less than or equal to rate value");
   serviceControl.patchValue({
    discount: '',
   });
  }
}
   
chnagetable(e){
if(e == "patient"){
  this.patient = true;
  this.repair = false;
  this.payment = false;
  this.product = false; 
  this.fileShow = false;  
}else if(e == "repair"){
  this.patient = false;
  this.repair = true;
  this.payment = false;
  this.product = false; 
  this.fileShow = false;  
  this.fetchRepairList();
}else if(e == 'payment'){
  this.patient = false;
  this.repair = false;
  this.product = false; 
  this.payment = true;  
  this.fileShow = false;  
}
else if(e == 'product'){
  this.patient = false;
  this.repair = false;
  this.payment = false;  
  this.product = true;  
  this.fileShow = false;  
}
else if(e == 'fileShow'){
  this.patient = false;
  this.repair = false;
  this.payment = false;  
  this.product = false;  
  this.fileShow = true;  
}
}

cancelUpdate(){
  // this.submitButton=true;
  // this.updateButton=false;
  this.ngOnInit();
 // this.checkupDetailsForm.reset();
}

fetchFollowUpReasonList(){
  this.apimicroservice.getfollowupReasonList().subscribe(res => {
    //  
    if (res.status) {     
      this.followupReasonList = res.data;
      this.filteredList1= this.followupReasonList.slice();
    } else {
      this.apimicroservice.openSnackbar(res.message);
    }
  })
}

  openBookAppointmentPopup(id) {
  this.bookRefId = id; 
  this.appointmentInfo = this.paientdetails;
  let followDate = this.checkupDetailsForm.get('followupDate').value;
   this.appointmentInfo.followupDate = followDate;
   //
  const dialogRef3 = this.dialog.open(AppoinmentPopupComponent,
    { 
      width: "70%",
      //height: "550px",  
      data: {
        // patientInfo: this.appointmentInfo,
        // bookDate: this.bookDate,
        pName: this.paientdetails.patientName, 
        pRefId: this.paientdetails.patientRefId, 
        pMobile: this.paientdetails.Mob, 
      }
    });

    dialogRef3.afterClosed().subscribe((result) => {
      let msg = "";
      if(result !== undefined){
        msg = result.data;
      }
      
      if(msg == "saved"){
        this.apimicroservice.openSnackbar("Follow-Up Appointment is Booked");
      }
      
     });
} 
openFollowUpPopup(id) { 
  this.bookRefId = id;
  this.appointmentInfo = this.paientdetails;
  let followDate = this.checkupDetailsForm.get('followupDate').value;
   this.appointmentInfo.followupDate = followDate;
   //
  const dialogRef3 = this.dialog.open(PopupFollowUpComponent,
    { 
      width: "70%", 
      data: {
        patientInfo: this.appointmentInfo,
        patientNm: this.paientdetails.patientName, 
        patientRf: this.paientdetails.patientRefId, 
        bookDate: this.bookDate,
      }
    });
} 
// openBookAppointmentPopup(id) {
//   this.bookRefId = id;
// //

//   const dialogRef3 = this.dialog.open(AppoinmentPopupComponent, {
//     width: "70%",
//     //height: "550px",
//    data: {
//      patientInfo:  this.bookRefId,
//      bookDate: this.bookDate,
//    },
//  }); 

//  dialogRef3.afterClosed().subscribe((result) => {
//    this.ngOnInit();
   
//  });
 
 
// }
openFollowUpReasonPopUp() {
  
  const dialogRef3 = this.dialog.open(PopupFollowupreasonComponent,
    {
      width: "500px",
    });

    dialogRef3.afterClosed().subscribe((result) => {
      this.apimicroservice.getfollowupReasonList().subscribe(res => {
        //  
        if (res.status) {    
          this.followupReasonList = res.data;
          this.filteredList1= this.followupReasonList.slice();
          this.checkupDetailsForm.get("followupReasons").setValue(res.data[0].followupReason);
        } else {
          this.apimicroservice.openSnackbar(res.message);
        }
      })
    //  this.fetchFollowUpReasonList();  
     });
}
// openBookAppointmentPopup(id) {
//   this.bookRefId = id;
// //

//   const dialogRef3 = this.dialog.open(AppoinmentPopupComponent, {
//     width: "70%",
//     //height: "550px",
//    data: {
//      patientInfo:  this.bookRefId,
//      bookDate: this.bookDate,
//    },
//  }); 

//  dialogRef3.afterClosed().subscribe((result) => {
//    this.ngOnInit();
   
//  });
 
 
// }
openSnackbar(message: string) {
  return this.snackBar.open(message, "close",
    { verticalPosition: 'bottom', horizontalPosition: 'right', duration: 3000, panelClass: ['snackbarStyle'] });
}

fetchBillList() {
  let search='';
  this.apimicroservice.getBillingDataByPatientId(this.paientdetails.patientRefId,this.paientdetails.appointId).subscribe(res => {
    //  
    if (res.status) {    
      this.billingList = res.data;
      this.totalLength=res.data.totalItems;
      
    } else {
      //this.apimicroservice.openSnackbar(res.message);
    }
  });
}

getpaymentHistory(){
  this.apimicroservice.getPaymentHistoryData(this.paientdetails.patientRefId).subscribe(res => {
    if(res.status){
      this.paymentData = res.data;

      //
   
    }
  });
}
getProductHistory(){
  this.apimicroservice.getPatientDevice(this.paientdetails.patientRefId).subscribe(res => {
    if(res.status){
      this.productData = res.data;   
    }
  });
}
getPatientFileData(){ 
  this.apimicroservice.getPatientFileData(this.paientdetails.patientRefId,this.paientdetails.appointId).subscribe(res => {
   
    if(res.status){
      this.fileDataArr = res.data;  
       this.fileDataArrLength = res.data.length;
    }
  });
}

removePatientFileData(e){ 
  this.apimicroservice.removePatientFileData(e).subscribe(res => {
    if(res.status){
      this.apimicroservice.openSnackbar(res.message);
      this.getPatientFileData();
    }
  });
}

totalVisits(){
  this.apimicroservice.getTotalVisits(this.paientdetails.patientRefId).subscribe(res => {
    if(res.status){
      this.NoOfVisits = res.data;

      //
   
    }
  });
}

totalBuisness(){
  this.apimicroservice.getTotalBuisness(this.paientdetails.patientRefId).subscribe(res => {
    //
    if(res.data.length > 0){
    this.totalAmount = res.data[0].totalPayment;
    }else{
      this.totalAmount = 0;
    }

   //
  });
}

getTotalRef(){
    this.apimicroservice.getTotalRefreance(this.paientdetails.patientRefId).subscribe(res => {
        this.refData = res.data; 
    });
    }
    onback() {
      this.apimicroservice.back()
    }
    redirect(to): void {
      this.router.navigate([to]);
     }
    editAppointment(id)
    {
      //
         
     sessionStorage.setItem('appointment_id',id);
     let e = null;
      this.openBookAppointmentPopup(id);
    // this.redirect('calander');
    }
    editPatient(id)
    {
        const dialogRef3 = this.dialog.open(PopUpPatientComponent,
          {
            data: {
              id: id,
            },
            width: "1200px",
          });
      //   
    }
    billing(patientId,appointId,branchRefId,e)
    {
     this.patientInfo = JSON.stringify(e);
      const dialogRef = this.dialog.open(PopupTotalBillComponent,
        { 
          data: {
            patientRefId: patientId,
            appointId:appointId,
            branchRefId: branchRefId,
            patientDetails:this.patientInfo,
            page:'Todays Apt'
          }
        }
        );
    }
    repairClick(branchRefId,patientRefId,branchName,patientName)
    {
     sessionStorage.setItem('repair_branchRefId',branchRefId);
     sessionStorage.setItem('repair_patientRefId',patientRefId);
     sessionStorage.setItem('repair_branchName',branchName);
     sessionStorage.setItem('repair_patientName',patientName);
     this.redirect('repair-complaint');  
    } 
    hat(id,name)
    { 
     sessionStorage.setItem('hat_patientRefId',id);
     sessionStorage.setItem('hat_patientName',name);
     this.redirect('/home/ha-form'); 
    }
    earMold(branchRefId,patientRefId,branchName,patientName)
    { 
      sessionStorage.setItem('ear_branchRefId',branchRefId);
      sessionStorage.setItem('ear_patientRefId',patientRefId);
      sessionStorage.setItem('ear_branchName',branchName);
      sessionStorage.setItem('ear_patientName',patientName);
     this.redirect('/home/ear-mould');
    }
    functiondelete(id,pname,appdate){
      //this.spinner.show();
      //
      const dialogRef3 = this.dialog.open(PopupCancelConfirmComponent,
        { 
          width: "50%",
          //height: "570px"
          data: {
            appointRefId: id,
            appointDate:appdate,
            patientName:pname
          }
        });
        dialogRef3.afterClosed().subscribe((result) => {
          this.ngOnInit(); 
         });
        }
         
onChange($event: Event) {
  const file = ($event.target as HTMLInputElement).files[0];
  const fileName = file.name;
  this.fileName = fileName;
  
  
  // if (file.size >= 3000000) { 
  //   this.errormessage = "Max upload size allowed is 3mb";
  // } else {
    this.errormessage = '';
    let ext = fileName.substring(fileName.lastIndexOf(".") + 1);
   
    if (
      ext.toLowerCase() == "png" ||
      ext.toLowerCase() == "jpg" ||
      ext.toLowerCase() == "jpeg" ||
      ext.toLowerCase() == "csv" ||
      ext.toLowerCase() == "xlsx" ||
      ext.toLowerCase() == "pdf" ||
      ext.toLowerCase() == "docx" 
    ) { 
      this.convertToBase64(file);
    }
  
}
convertToBase64(file: File) {
  const observable = new Observable((subscriber: Subscriber<any>) => {
    this.readFile(file, subscriber);
  });
  //
  
  observable.subscribe((d) => {
    this.filedata = d;
    this.saveData();
    //
    
  });
}
readFile(file: File, subscriber: Subscriber<any>) {
  const fileReader = new FileReader();
  fileReader.readAsDataURL(file);

  fileReader.onload = () => {
    subscriber.next(fileReader.result);
    subscriber.complete();
  };
  fileReader.onerror = (error) => {
    subscriber.error(error);
    subscriber.complete();
  };
}
 

saveData(){
  let payload = this.patientFileDataForm.value;
  //
  payload.patientFile = this.filedata;
  payload.fileUploadDate = new Date(); 
  payload.patientFileName = this.fileName;
  payload.patientRefId = this.paientdetails.patientRefId;
  payload.patientName = this.paientdetails.patientName;
  payload.appointId = this.paientdetails.appointId;
  this.spinner.show(); 

  this.http
    .post(this.apimicroservice.APIService+"/patientFileUpload/savePatientFileData", payload, {
      reportProgress: true,
      observe: "events"
    })
    .pipe(
      map((event: any) => {
        if (event.type == HttpEventType.UploadProgress) {
          this.progress = Math.round((100 / event.total) * event.loaded);
          this.getPatientFileData();
          this.spinner.hide(); 
        } else if (event.type == HttpEventType.Response) {
          this.progress = null;
        } 
      }),
      catchError((err: any) => {
        this.progress = null;
        // alert("File Size is to big");
        this.apimicroservice.openSnackbar("File Size is to big"); 
        return throwError(err.message);
      })
    )
    .toPromise();
//   this.apimicroservice.patientFileUpload(payload).subscribe(res => {
//     this.spinner.hide();
//     this.filedata = '';
//     this.ngOnInit();
//     if(res.status){
//       this.apimicroservice.openSnackbar(res.message);
//     }else{
//       this.apimicroservice.openSnackbar(res.message);
//     }
//   },(ERROR:HttpErrorResponse) => {
//     this.apimicroservice.openSnackbar(ERROR.error.message);
//     this.spinner.hide();
// });  
} 
deleteFile(e){
  alert("delete button"+e)
}
showbase64(e){
  const dialogRef3 = this.dialog.open(PdfviewerComponent,
    {
      width: "1200px",
      data: e
    });
}
toPatientForm() { 
  //
  
  localStorage.setItem("fromGlobal",'true');
  this.router.navigate(['/global-dashboard'],{ queryParams: { PatientId: this.paientdetails.patientRefId } });

  // if(fromG == 'true') 
  // {  

  //   this.router.navigate(['/global-dashboard'],{ queryParams: { PatientId: this.paientdetails.id } });
  // } 
  // else 
  // { 
  //   //this.dialogRef.close({ data: e })
  // } 
 
}
fetchPatientStatusList() {
  let clinicRef= sessionStorage.getItem('clinicRef');
  let branchRef= sessionStorage.getItem('branchRef');
  let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
  let branchRefSuperAdmin= '';
  if (this.userType !== 'Super Admin') {
  this.apimicroservice.getTrackingStatusAllData(branchRef,clinicRef).subscribe(res => {
    ////
    this.allPatientStatus = res.data;
    this.filteredListPatientStatus = this.allPatientStatus.slice();
  });
  }else{
    this.apimicroservice.getTrackingStatusAllData(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
      ////
      this.allPatientStatus = res.data;
      this.filteredListPatientStatus = this.allPatientStatus.slice();
    });
  }
}
openPatientStatusPopUp() {
  //localStorage.clear();
  const dialogRef3 = this.dialog.open(PatientTrackingStatusComponent,
    {
      width: "400px",
    });

  dialogRef3.afterClosed().subscribe((result) => {  
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
    this.apimicroservice.getTrackingStatusAllData(branchRef,clinicRef).subscribe(res => {
      this.allPatientStatus = res.data;
      this.filteredListPatientStatus = this.allPatientStatus.slice();
      this.checkupDetailsForm.get("trackingStatusName").setValue(res.data[0].trackingStatusName);
    });
  }else{
    this.apimicroservice.getTrackingStatusAllData(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
      this.allPatientStatus = res.data;
      this.filteredListPatientStatus = this.allPatientStatus.slice();
      this.checkupDetailsForm.get("trackingStatusName").setValue(res.data[0].trackingStatusName);
    });
  }
  });
}
}
