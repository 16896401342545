import { Component, Inject, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
 import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { Router,ActivatedRoute } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  MatSnackBar,
} from "@angular/material/snack-bar";

import { MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PopupBrandComponent } from "../popup-brand/popup-brand.component";
import { NgxSpinnerService } from "ngx-spinner";
import { PopupDeviceDetailsGlobalComponent } from "../popup-device-details-global/popup-device-details-global.component";
@Component({
  selector: 'app-pop-up-device-data',
  templateUrl: './pop-up-device-data.component.html',
  styleUrls: ['./pop-up-device-data.component.scss']
})
export class PopUpDeviceDataComponent implements OnInit {
  errormessage: string;
  searchText: any;
  devicelist: any;
  id: any;
          allVent=[
            {name:'No Vent',value:'No Vent'},
            { name:'1 mm',value:'1 mm' },
            {name:'2 mm',value:'2 mm' },
            { name:'IROS',value:'IROS'},
           
          ]
  filteredList: { name: string; value: string; }[];
  filteredDeviceList: { name: string; value: string; }[];
  filteredConditionList: { name: string; value: string; }[];
  filteredAllVentList: { name: string; value: string; }[];

  filteredListStyle: { name: string; value: string; }[];
  filteredListbatteryType: { name: string; value: string; }[];
  brandlist: any;
  branchRef: string;
  clinicRef: string;
  BranchList: any;
 deviceTypelist:any;
 styleList:any;
 batteryTypeList:any;
 conditionTypeList:any;
  userType: string;
  branchName: string;
  filteredListBranch: any;
  listOfBilling: any;
  filteredBillingCompany: any;
  isGstApplicable= false;
  pId: any;
  devicelist2=[];
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
     public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    public route : ActivatedRoute,
    public dialogRef: MatDialogRef<PopUpDeviceDataComponent>,@Inject(MAT_DIALOG_DATA) public data:any,
  ) { dialogRef.disableClose = true;}
  devicePopUpForm: FormGroup;
  status: any;
  submitButton = true;
  updateButton = false;
  deleteButton = false;
  cancelButton=false;
  loggedId: any;
  myimage;
  date: any;
  size:number = 5;
  page:number = 1;
  totalLength: any;
  size1:number = 5;
  page1:number = 1;
  totalLength1: any;
  arr = [];
  userPermission:any;
  devicesCreate= true;
  devicesUpdate= true;
  isSuperAdmin= true;
  superBranchId: any;
  detailsView = false;
  ngOnInit() {
    this.date = new Date();
    this.page=1;
    //this.filteredListStyle = this.deviceStyle.slice();
   // this.filteredListbatteryType = this.batteryType.slice();
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.userType = sessionStorage.getItem('userType');
   // this.filteredDeviceList = this.allDeviceType.slice();
    // this.filteredConditionList = this.allCondition.slice();
    this.filteredAllVentList = this.allVent.slice();
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    if(this.userType !== 'Super Admin'){
      this.isSuperAdmin= false;
    this.userPermission.forEach((element) => {
      if(element.pageName == 'Devices' && element.create == false)
      {
          this.devicesCreate = false;  
      }
      if(element.pageName == 'Devices' && element.update == false)
      {
          this.devicesUpdate = false;  
      }    
    });
  }
    this.devicePopUpForm = this.fb.group({
      "branchWiseId": new FormControl(''),
      "searchDevice": new FormControl(''),
      "brand": new FormControl('',Validators.required),
      "brandLable": new FormControl(''),
      "model": new FormControl('',Validators.required),
      //"serialNo": new FormControl('',Validators.required),
      "deviceType": new FormControl('',Validators.required),
      "style": new FormControl(''),
      "batteryType": new FormControl(''),
      "cordType": new FormControl(''),
      "ear": new FormControl(''),
      "technology": new FormControl(''),
      "condition": new FormControl(''),
      "deviceColor": new FormControl('', Validators.pattern("^[a-zA-Z ]*$")),
      "shellColor": new FormControl('', Validators.pattern("^[a-zA-Z ]*$")),
      "facePlateColor": new FormControl('', Validators.pattern("^[a-zA-Z ]*$")),
      "vent": new FormControl(''),
      "matrix": new FormControl(''),
      "receiver": new FormControl(''),
      "dome": new FormControl(''),
      "facePlateNumber": new FormControl('',Validators.pattern("^[0-9 ]*$")),
      "waxManagement": new FormControl(''),
      "removalString": new FormControl(''),
      "notes": new FormControl(''),
      "createdAt": this.date,
      "branchRefId": new FormControl(),
      "clinicRefId": new FormControl(),
      "branchName": new FormControl(),
      "billingCompanyRefId": new FormControl('',Validators.required),
      "billingCompanyName": new FormControl(),
      "gstApplicable": new FormControl('No',Validators.required),
      "has": new FormControl('',[Validators.pattern("^[0-9 ]*$"),Validators.maxLength(6),Validators.minLength(4)]),
      "sgst": new FormControl('',[Validators.pattern("^[0-9 ]*$"),Validators.maxLength(3)]),
      "cgst": new FormControl('',[Validators.pattern("^[0-9 ]*$"),Validators.maxLength(3)]),
      "igst": new FormControl('',[Validators.pattern("^[0-9 ]*$"),Validators.maxLength(3)]),
      "mrp": new FormControl('',[Validators.required,Validators.pattern("^[0-9 ]*$"),Validators.maxLength(8)]),
    });
     this.devicePopUpForm.controls["deviceType"].setValue("Hearing Aid");
     this.devicePopUpForm.controls["technology"].setValue("Digital");
     this.devicePopUpForm.controls["style"].setValue("RIC");
     //this.devicePopUpForm.controls["batteryType"].setValue("Rechargable");
     this.devicePopUpForm.controls["ear"].setValue("Not Defined");
     this.devicePopUpForm.controls["batteryType"].setValue("Rechargeable");

        this.fetchInternalDevice();
        this.fetchExternalDevice();

  }
  // fetchDeviceList() {
  //   let pg = 1;
  //   let size = 5;
  //   let search='';
  //   this.searchText = this.devicePopUpForm.get('searchDevice').value;    
  //   let branchRef='';
  //   let clinicRef='';
  //   this.apimicroservice.getBillingDeviceDataByPatientRef(this.pId).subscribe(res => {
  //     //this.spinner.hide();
  //       // this.devicelist = res.data.deviceData;
  //       // this.totalLength = res.data.totalItems; 
  //       //  
  //       this.devicelist = res.data;  
  //     // this.totalLength = res.data.totalItems;  
  //     });
  // } 
  fetchInternalDevice() {
    this.spinner.show();
    let pg = 1;
    let size = 5;
    let search='';  
    this.devicelist = [];
    let pId='';
    this.searchText = this.devicePopUpForm.get('searchDevice').value;    
    this.apimicroservice.getInternalDevice(pg,size,search,pId).subscribe(res => {
    this.spinner.hide();
         res.data.data.forEach((element) => {
         this.devicelist.push(element)
              });
        this.totalLength = this.devicelist.length; 
       }); 
  }
  fetchExternalDevice() {
       this.spinner.show();
       let pg = 1;
       let size1 = 5;
       let search='';  
       this.devicelist2 = [];
       let pId='';
       this.searchText = this.devicePopUpForm.get('searchDevice').value;  
      // let type = 'external'; 
      let type = "Not Fitted By Us";
       this.apimicroservice.getDeviceDetailsByPatient(pg,size1,search,pId,type).subscribe(res => {  
      this.spinner.hide();
      res.data.deviceData.forEach((element) => {
        this.devicelist2.push(element)
      });  
      this.totalLength1 = res.data.totalItems;  
          }); 
    // } 
     }
  onPageChange(event) {
    this.page = event;
    let pId='';
    this.spinner.show();
    this.searchText = this.devicePopUpForm.get("searchDevice").value;   
    this.devicelist = [];
    this.apimicroservice.getInternalDevice(this.page,this.size,this.searchText,pId).subscribe(res => {
    this.spinner.hide();
         res.data.data.forEach((element) => {
         this.devicelist.push(element)
              });
        this.totalLength = this.devicelist.length; 
       }); 
     // }
  } 
  onPageChange2(event) {
    this.page1 = event;
    let pId='';
    this.spinner.show();
    this.searchText = this.devicePopUpForm.get("searchDevice").value;
    this.devicelist2 = [];   
    //let type = 'external'; 
    let type = "Not Fitted By Us";
    this.apimicroservice.getDeviceDetailsByPatient(this.page1,this.size1,this.searchText,pId,type).subscribe(res => {  
      this.spinner.hide();
      res.data.deviceData.forEach((element) => {
        this.devicelist2.push(element)
      });  
      this.totalLength1 = res.data.totalItems;  
          }); 
  }
  onKeyUpEvent(event: any) {
    this.searchText = this.devicePopUpForm.get("searchDevice").value;  
    this.devicelist = [];
    let pId='';
    this.apimicroservice.getInternalDevice(this.page,this.size,this.searchText,pId).subscribe(res => {
    this.spinner.hide();
         res.data.data.forEach((element) => {
         this.devicelist.push(element)
              });
        this.totalLength = this.devicelist.length; 
       }); 
          /////////External Device
          this.devicelist2 = [];   
          //let type = 'external'; 
          let type = "Not Fitted By Us";
          this.apimicroservice.getDeviceDetailsByPatient(this.page1,this.size1,this.searchText,pId,type).subscribe(res => {  
          res.data.deviceData.forEach((element) => {
            this.devicelist2.push(element)
          });  
          this.totalLength1 = res.data.totalItems;  
              }); 
  }
  toPatientForm(e) { 
    //
    //
    
    let fromG = localStorage.getItem('fromGlobal');
    if(fromG == 'true') 
    { 
      this.dialogRef.close(); 
    //  this.router.navigate(['/home/patient-global-search'],{ queryParams: { PatientId: e._id } })
      this.router.navigate(['/global-dashboard'],{ queryParams: { PatientId: e.patientRefId} });
    }
    else  
    {
      this.dialogRef.close({ data: e })
    }
  }
   onClose(): void {
    this.dialogRef.close();
  }
  redirect(to): void {
    this.router.navigate([to]);
  }
 
} 