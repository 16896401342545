import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ApiServiceService } from '../api-service.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner"; 
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-popup-test-type',
  templateUrl: './popup-test-type.component.html',
  styleUrls: ['./popup-test-type.component.scss']
})
export class PopupTestTypeComponent implements OnInit {

  popupTestTypeForm: FormGroup;
  listOftestType: any;
  totalLength: any;
  searchText: any;
  page: number = 1;
  size: number = 3;
  sid: any;
  submitButton = true;
  updateButton = false;
  cancelButton = false;
  addNewComplaint = false;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<PopupTestTypeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, 
    public apimicroservice: ApiServiceService,
    private spinner: NgxSpinnerService,
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.page = 1;
    this.popupTestTypeForm = this.fb.group({  
      "testType": new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9 -]+$")]),
      "createdAt": new Date(),
      "searchtestType": new FormControl(''),
    });
    this.fetchServiceList();
  }

  fetchServiceList() {
    let pg = 1;
    let size = 3;
    let search = '';
    this.apimicroservice.getTestType(pg, size, search).subscribe(res => {
      this.listOftestType = res.data.data;
      this.totalLength = res.data.totalItems;
      if (res.status) {
        // this.apimicroservice.openSnackbar(res.message);
      } else {
        // this.apimicroservice.openSnackbar(res.message);
      }
    });   
  }

  saveTestType() {
    let payload = this.popupTestTypeForm.value;
    
    // Get clinicRefId and branchRefId from session storage
    const clinicRefId = sessionStorage.getItem('clinicRef');
    const branchRefId = sessionStorage.getItem('branchRef');

    // Add clinicRefId and branchRefId to the payload
    payload = {
      ...payload,
      clinicRefId,
      branchRefId
    };

    this.spinner.show();
    this.apimicroservice.submitTestTypeData(payload).subscribe(
      res => {
        this.spinner.hide();
        if (res.status) { 
          this.apimicroservice.openSnackbar(res.message);      
          this.ngOnInit();
          this.onClose();        
        } else {
          this.apimicroservice.openSnackbar(res.message);
        }
      },
      (ERROR: HttpErrorResponse) => {
        this.apimicroservice.openSnackbar(ERROR.error.message);
        this.spinner.hide();
        this.onClose();    
      }
    );
  }

  save() { 
    if (this.popupTestTypeForm.invalid) {
      this.popupTestTypeForm.get("testType").markAsTouched();
      return;
    } else {
      this.saveTestType();
    }
  }

  onKeyUpEvent(event: any) {
    this.searchText = this.popupTestTypeForm.get('searchtestType').value;
    this.apimicroservice.getTestType(this.page, this.size, this.searchText).subscribe(res => {
      this.listOftestType = res.data.data;
      this.totalLength = res.data.totalItems;
      if (res.status) {
        // this.apimicroservice.openSnackbar(res.message);    
      } else {
        // this.apimicroservice.openSnackbar(res.message);
      }
    });
  }

  prepopulation(id) {
    this.apimicroservice.getTestTypeDataById(id).subscribe(res => {
      let data = res.data[0];
      this.sid = id;
      if (res.status) {
        this.submitButton = false;
        this.updateButton = true;
        this.cancelButton = true;
        this.popupTestTypeForm = this.fb.group({
          "testType": new FormControl(data.testType),
          "updateddAt": new Date(),
        });
      } else {
        // this.apimicroservice.openSnackbar(res.message);
      }
    });
  }

  updateTestTypeData() {
    let payload = this.popupTestTypeForm.value;
    this.apimicroservice.updateTestTypeData(payload, this.sid).subscribe(res => {
      if (res.status) {
        this.apimicroservice.openSnackbar(res.message);
        this.updateButton = false;
        this.submitButton = true;
        this.onClose();      
      } else {
        // this.apimicroservice.openSnackbar(res.message);
      }
    });
  }

  onPageChange(event) {
    this.page = event;
    this.searchText = this.popupTestTypeForm.get('searchtestType').value;
 
    this.apimicroservice.getTestType(this.page, this.size, this.searchText).subscribe(res => {
      this.listOftestType = res.data.data;
      this.totalLength = res.data.totalItems;
      if (res.status) {
        // this.apimicroservice.openSnackbar(res.message);
      } else {
        // this.apimicroservice.openSnackbar(res.message);
      }
    });
  }

  cancelUpdate() {
    this.popupTestTypeForm.reset();
    this.submitButton = true;
    this.updateButton = false;
    this.cancelButton = false;
    this.ngOnInit();
  }

  onClose(): void {
    this.dialogRef.close();
  }
}