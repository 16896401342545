<div class="container-fluid bgcolor2" [formGroup]="BillingForm">
  <form [formGroup]="BillingForm" autocomplete="off" novalidate>
    <div class="row">
        <div class="col-sm-1">
   
        </div> 
        <div class="col-sm-11 ">
          <div class="row">
            <div class="col-sm-12"> 
              <div class="row "> 
              <div class="col-sm-9">
                  <h1 class="pt-2 pl-1" id="toBil lingForm">Billing</h1>
                  </div> 
                  <div class="col-sm-3 mt-1">
                    <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                      <a (click)="onback()" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">arrow_back</span>Back</a>
                      <a (click)="toBillingLst()" class="d-flex align-items-center ms-3 pr-2 mt-2"><span class="material-symbols-outlined pr-2">reorder</span>List</a>
                 </div>
            </div>
          </div>
         </div>
         </div>
            <div class="row">
                <div class="col-sm-12 pr-4">
                    <div class="row">
                        <div class="card divbody mb-3 borderradius p-2">
                           
                            <div class="col-sm-12">
                                <div class="row">
                                    <!-- <div class="col-sm-3 ">
                           
                                        <mat-form-field *ngIf="userType !== 'Super Admin'" class="example-full-width" appearance="outline">
                                          <mat-label> Id</mat-label>
                                          <input matInput formControlName="branchWiseId" readonly  />   
                                        </mat-form-field>
                                        <mat-form-field *ngIf="userType == 'Super Admin'" class="example-full-width" appearance="outline">
                                          <mat-label> Id</mat-label>
                                          <input matInput [value]="superBranchId" readonly  />
                                          </mat-form-field>
                                      </div> -->
                                      <div class="col-sm-3">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>Patient ID</mat-label>
                                          <input matInput placeholder="Patient ID" formControlName="patientId" />
                                      
                                        </mat-form-field>
                                      </div>
                                      <div class="col-sm-3">
                                        <!-- <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>Patient Name</mat-label>
                                          <input matInput placeholder="Patient Name" formControlName="patientName" />
                                        </mat-form-field> -->
                                        <div class="row"> 
                                          <div class="col-sm-10 pr-1">
                                           
                                            <mat-form-field class="example-full-width" appearance="outline">
                                              <mat-label>Patient Name</mat-label>
                                              <input (click)="openDepartmentPopUp();" matInput readonly
                                               placeholder="Patient Name" formControlName="patientName" />
                                              </mat-form-field>  
                                            
                                            </div> 
                                            <div *ngIf="PatientCreate" class="col-sm-2 mt-2 ml-0 pl-0 mTop no-print">
                                                <i (click)="openPatientPopUp();" matTooltip="Add New Patient" 
                                                class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                                            </div>   
                                            </div>
                                      </div>
                                      
                                      <div class="col-sm-3">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>Bill No</mat-label>
                                          <input class="emailClass" matInput placeholder="Bill No" formControlName="billNo" />
                                        </mat-form-field>
                                      </div>
                                      <div class="col-sm-3">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>Status</mat-label>
                                    
                                          <mat-select placeholder="Select" class="colorblack" formControlName="status">
                                            <mat-option value="Paid">Paid</mat-option>
                                            <mat-option value="Unpaid">Unpaid</mat-option>
                                          </mat-select>
                                        </mat-form-field>
                                      </div>
                                      <div class="col-sm-3 ">
                                        <mat-form-field class="example-full-width " appearance="outline">
                                           <mat-label>From Date</mat-label>
                                           <input matInput  [max]="currentdate" [matDatepicker]="fdate" placeholder="Choose a date" 
                                           formControlName="fdate" (dateChange)="toOpenDateChnage();">
                            
                                            <mat-datepicker-toggle matSuffix [for]="fdate">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #fdate></mat-datepicker>   
                                       </mat-form-field>
 
                                           <mat-error
                                           *ngIf="BillingForm.controls['fdate'].touched && BillingForm.controls['fdate'].errors?.required">
                                           This field is mandatory.
                                         </mat-error>
                                         <mat-error class="margin_tp"
                                        *ngIf="BillingForm.controls['fdate'].hasError('matDatepickerMax')">
                                        Please enter Date less than or equal to {{maxDateStr}}.
                                      </mat-error>
                                       </div>
                                       <div class="col-sm-3 ">
                                         <mat-form-field class="example-full-width " appearance="outline">
                                             <mat-label>To Date</mat-label>
                                             <input matInput  [max]="currentdate" [matDatepicker]="todate" placeholder="Choose a date" 
                                             formControlName="todate" (dateChange)="toCloseDateChnage();">
                              
                                              <mat-datepicker-toggle matSuffix [for]="todate">
                                              </mat-datepicker-toggle>
                                              <mat-datepicker #todate></mat-datepicker>
                                            
                                         </mat-form-field>
                                         <mat-error
                                         *ngIf="BillingForm.controls['todate'].touched && BillingForm.controls['todate'].errors?.required">
                                         This field is mandatory.
                                       </mat-error>
                                       <mat-error class="margin_tp"
                                        *ngIf="BillingForm.controls['todate'].hasError('matDatepickerMax')">
                                        Please enter Date less than or equal to {{maxDateStr}}.
                                      </mat-error>
                                     </div>
                                       <div class="col-sm-2 offset-sm-8 mb-2 mt-2" *ngIf="submitButton && billingCreate"> 
                                        <button class="btn btn-primary btn-sm example-full-width pt-2 pb-2"  type="button" 
                                        (click)="searchBillingData()">Search </button>

                                    </div>
                                    <!-- <div class="col-sm-2 mb-2 mt-2" > 
                                      <button class="btn btn-primary btn-sm example-full-width
                                      white " type="button"  mat-raised-button (click)="billingNew()">
                                        New Bill 
                                        <span class="material-symbols-outlined">
                                          receipt_long
                                          </span>
                                    </button> 
                                   </div> -->
                                  <div class="col-sm-2 mb-2 mt-2">
                                    <button class="btn btn-primary pt-2 pb-2 btn-sm example-full-width" 
                                     (click)="cancelUpdate()" type="reset">Cancel</button>
                                    </div>
                                    <!-- <div class="col-sm-2 offset-sm-8 mb-2 mt-2" *ngIf="updateButton && billingUpdate">
                                        <button (click)="UpdateInwordOutword();"  type="button" 
                                        class="btn btn-primary btn-sm example-full-width">Update </button>
                                    </div> -->
                                    <!-- <div class="col-sm-2 mb-2 mt-2 " *ngIf="updateButton">
                                        <button class="btn btn-primary btn-sm example-full-width" (click)="cancelUpdate()" type="reset" >Cancel </button>
                                        
                                    </div> -->
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
            <div class="col-sm-12 pl-0 pr-2">
                <div class="row">
                    <div class="card divbody borderradius p-3 pb-0">
                            <div class="col-sm-12">
                                <div class="row">
                                    <div class="col-sm-9 ">
                                        <h1 class="titlecolor">Billing List</h1>
                                    </div>
                                    <div class="search-box col-sm-3 pb-2">
                                      <input class="form-control amber-border" type="text" placeholder="Search" 
                                      formControlName="searchBilling" aria-label="Search" (keyup)="onKeyUpEvent($event)"
                                      >
                                      <div class="input-group-append">
                                         <span class="input-group-text amber lighten-3" id="basic-text1"><span class="material-symbols-outlined">search</span></span>
                                      </div>
                                   </div>
                                   
                                      <div class="col-sm-12 table-responsive">
                                        <table id="toBillingLst" 
                                     class="table table-bordered data-table-custom">
                                     
                                            <thead>
                                                <tr class="text-center">
                                                    <th>Sr.No.</th>
                                                     <th>Date</th>
                                                     <th>Bill No</th>
                                                     <th>Patient Id</th>
                                                    <th>Patient Name</th>
                                                    <th>Mobile</th>
                                                    <th>Total Amount</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead> 
                                            <tbody> 
                                                <tr *ngFor="let item of billingList | paginate : { id:'billingFormMaster',
                                                itemsPerPage: size, currentPage: page, totalItems:totalLength } let i = index" 
                                                (click)="redirectToBillForm(item.billNo,item.isMigrated,item.isDraft)"
                                                 (click)="toBillingForm()"  
                                                 style="cursor:pointer; text-align: center ">
                                                 <td matTooltip="Click To View">{{i+1}}</td>
                                                 <td  matTooltip="Click To View" 
                                                 >{{item.createdAt | date:'dd/MM/yyyy'}}
                                               </td>
                                               <td class="emailClass" matTooltip="Click To View" >{{item.billNo}}</td>
                                               <td matTooltip="Click To View" >{{item.patientId}}</td>
                                               <td matTooltip="Click To View" >{{item.patientName}}</td>
                                               <td matTooltip="Click To View" >{{item.mobile}}</td>
                                               <td matTooltip="Click To View" >{{item.totalAmount}}</td>

                                               <td class="labelStyle" matTooltip="Click To View" >
                                                <label class="green" *ngIf="item.paidAmount == item.totalAmount; else elseBlock">
                                                  Paid 
                                                </label>
                                           
                                              <ng-template #elseBlock>
                                                <!-- <span *ngIf="item.deviceInfo[0]">
                                                  <label *ngIf="item.deviceInfo[0].discount == item.deviceInfo[0].totalRate; else elseBlock2"
                                                  class="green">Paid</label>
                                                </span> -->
                                                <span *ngIf="item.serviceInfo[0]">
                                                  <label *ngIf="item.serviceInfo[0].discount == item.serviceInfo[0].totalRate; else elseBlock2"
                                                  class="green">Paid</label>
                                                </span>
                                                <!-- <span *ngIf="!item.deviceInfo[0]">
                                                  <label class="red">Unpaid</label>
                                                </span> -->
                                              </ng-template>  

                                              <ng-template #elseBlock2>
                                                <label class="red">Unpaid</label>
                                              </ng-template> 
                                              

                                                </td>

                                             <!-- <td *ngIf="item.paidAmount == item.totalAmount" class="green">          
                                              {{item.paymentStatus}}
                                               </td>
                                               <td matTooltip="Click To View" *ngIf="item.paymentStatus == 'Unpaid'" class="red">          
                                                {{item.paymentStatus}}
                                                 </td>
                                                 <td matTooltip="Click To View" *ngIf="item.paymentStatus == 'Cheque Payment Pending'" class="red">          
                                                  {{item.paymentStatus}}
                                                   </td> -->
                                                 
                                                   </tr>
                                             

                                            </tbody> 
                                        </table>
                                    </div>
                                    <div class="col-sm-12 pt-0 text-right">
                                            <pagination-controls id="billingFormMaster" directionLinks="true"
                                  (pageChange)="onPageChange($event)" class="float-right pagination my-pagination"></pagination-controls>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            

        </div>
    </div>
    </form>
</div>