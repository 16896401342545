import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
 import { ApiServiceService } from "../api-service.service";
import { Router } from "@angular/router";
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import {HttpEvent, HttpHeaders,HttpInterceptor, HttpResponse,HttpErrorResponse,HttpHandler,HttpRequest,} from "@angular/common/http";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  hide = true;
  forgotPasswordForm: FormGroup;
  errorMessage;
  private formSumitAttempt: boolean;
  EmployeeId: any;
  login = true;
  forgotpassword = false;
  loginbtn = true;
  uemail: any;
  constructor(private fb: FormBuilder,
    public router: Router,
    public apimicroservice: ApiServiceService,
    public snackBar: MatSnackBar,
    private spinner: NgxSpinnerService
    ) { }

  ngOnInit(): void {
    this.forgotPasswordForm = this.fb.group({
      emailId: [
        "",
        [
          Validators.required,
          //Validators.email
          Validators.pattern("(?![0-9]+@)[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}")
        ],
      ],
    });
  }


  ResetPassword(){
    let payload = this.forgotPasswordForm.value; 
    //
    this.spinner.show();
    if(payload.emailId !== 'superadmin@audiology.com')
   { 
    this.apimicroservice.forgotPassword(payload,payload.emailId).subscribe(res => {
      //
      this.spinner.hide();
      if(res.status){
        this.apimicroservice.openSnackbar("Password sent to given email id.");   
        this.redirect('login');   
        //this.ngOnInit();
      // this.onClose();        
      }else{
        this.apimicroservice.openSnackbar("Email id is not registered,Enter valid email!");
      }
    },
    (ERROR:HttpErrorResponse) => {
      this.apimicroservice.openSnackbar(ERROR.error.message);
      this.spinner.hide();
     // this.onClose();    
  });}
  else{
    this.apimicroservice.openSnackbar("Forgot Password Is Not For Super Admin");
    this.forgotPasswordForm.controls["emailId"].setValue('');
  }
  }
  // ResetPassword() {
  //   let payload = this.forgotPasswordForm.value;
  //   if(payload.emailId !== 'superadmin@audiology.com')
  //   {
  //     //
  //     this.spinner.show();
  //    this.apimicroservice.forgotPassword(payload).subscribe(
  //      (res) => {
  //        this.spinner.hide();
  //        if (res.status) {
  //          this.apimicroservice.openSnackbar("Password sent to given email id.");
  //          this.redirect('login');
  //        }else{
  //          this.apimicroservice.openSnackbar("Email id is not registered,Enter valid email!");
  //        }
  //      },
  //      (ERROR: HttpErrorResponse) => {
  //        // alert(ERROR.error.message);
  //        this.apimicroservice.openSnackbar("Email has not sent on given email id,Please enter valid email!");
  //        //
  //      }
  //    );
  //   }
  //   else
  //   {
  //     this.apimicroservice.openSnackbar("Forgot Password Is Not For Super Admin");
  //     this.forgotPasswordForm.controls["emailId"].setValue('');
  //   }
     
  // }
  submit() {
    if (this.forgotPasswordForm.invalid) { 
      this.forgotPasswordForm.get('emailId').markAsTouched();
      return;
    }
    else{
      this.ResetPassword();
    }
}
  isFieldValids(field: string) {
    return (
      (!this.forgotPasswordForm.get(field).valid && this.forgotPasswordForm.get(field).touched) ||
      (this.forgotPasswordForm.get(field).untouched && this.formSumitAttempt)
    );
  }
  onSubmit() {
    this.formSumitAttempt = true;
    if (this.forgotPasswordForm.valid) {
      // //
    }
  }

  Forgotpassword() {
    this.apimicroservice.openSnackbar("Clicked on forgot password");
  }
  redirect(to): void {
    this.router.navigate([to]);
  }
  customerDetails() {
    // this.forgotpassword = true;
    // this.login = false;
  }
  customerDetailsClose() {
    // this.forgotpassword = false;
    // this.login = true;
  }
  keyDownFunction(event) {
    if (event.keyCode == 13) {
      this.submit();
    }
  }

}
