import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators, 
  FormBuilder,
} from "@angular/forms";
 import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { DatePipe } from "@angular/common";
import {
  MatSnackBar,
} from "@angular/material/snack-bar";
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { PopupTotalBillComponent } from "../popup-total-bill/popup-total-bill.component";
import { Router,ActivatedRoute } from "@angular/router";
import { AppoinmentPopupComponent } from "../appoinment-popup/appoinment-popup.component";

@Component({
  selector: 'app-appoinment-dashboard',
  templateUrl: './appoinment-dashboard.component.html',
  styleUrls: ['./appoinment-dashboard.component.scss']
})
export class AppoinmentDashboardComponent implements OnInit {
  apptDashboardForm: FormGroup;
  date: any;
  listOfAppointment:any;
  totalLength:any;
  appointmentId: any;
  size=5;
  page=1;
  searchText: any;
  submitButton = true;
  updateButton = false;
  allColor: any;
  
  filteredList: { name: string; value: string; }[];
  filteredListAppoinmentType: { name: string; value: string; }[];
  userType: string;
  branchRef: string;
  clinicRef: string;
  BranchList: any;
  isSuperAdmin= true; 
  superBranchId: any;
  branchName: string;
  filteredListBranch: any;
  currentdate: Date;
  listOfBilling: any;
  id: any;
  courierList: any;
  filteredListCourier: any;
  disableFromCity = false;
  disableToCity = true;
  tocityList: any;
  cityList: any;
  fromCity: any;
  toCity: any;
  courierServiceCreate = true;
  branchCreate = true;
  appoinmentList: any; 
  patientRefId: FormGroup;
  pId: any;
  bookDate: any;
  bookRefId: any;
  pData: any;
  pName: any;
  pRefId: any;
  pMobile: any;
  firstName: any;
  patientId: any;
  lastName: any; 
  branchRefId: any;
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
     public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
   public route : ActivatedRoute
  ) { }
  userPermission:any;
  billingCreate= true;
  billingUpdate= true;
  printData=[
    {
      srNo:1,
      date:'10/03/2020',
      aptId:101,
      patientName:'Shivani Bhosale',
      mobile:9088776653,
      status:'Paid'
     },
     {
      srNo:2,
      date:'10/03/2020',
      aptId:102,
      patientName:'Sai Mane',
      mobile:9276321653,
      status:'Unpaid'
     },
     {
      srNo:3,
      date:'10/03/2020',
      aptId:103,
      patientName:'Rahul Thakur',
      mobile:8932776653,
      status:'Unpaid'
     },
     {
      srNo:4,
      date:'10/03/2020',
      aptId:104,
      patientName:'Aishwarya Shaha',
      mobile:8877776653,
      status:'Paid'
     },                 
  ]
  ngOnInit(): void {
    this.bookDate = null;
    this.bookRefId = null;
    this.currentdate = new Date();
    this.page=1;
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.userType = sessionStorage.getItem('userType');
    this.userType = sessionStorage.getItem('userType');
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    if(this.userType !== 'Super Admin'){
      this.isSuperAdmin= false;
    this.userPermission.forEach((element) => {
      if(element.pageName == 'Billing' && element.create == false)
      {
          this.billingCreate = false;  
      }
      if(element.pageName == 'Billing' && element.update == false)
      {
          this.billingUpdate = false;  
      }  
      if(element.pageName == 'Courier Service Provider' && element.create == false)
      {
          this.courierServiceCreate = false;  
      } 
      if(element.pageName == 'Branch' && element.create == false)
      {
          this.branchCreate = false;  
      } 
    });
  }
  this.disableFromCity = false;
  this.disableToCity = true;
    this.apptDashboardForm = this.fb.group({
      "patientId": new FormControl(''),
      "patientName": new FormControl(''),
      "billNo": new FormControl(''),
      "status": new FormControl(''),
      "fdate": new FormControl(''),
      "todate": new FormControl(''),
      "docketNo": new FormControl('',[Validators.pattern("^[0-9]*$")]), 
      "searchBilling": new FormControl(''),    
    });
    this.route.queryParams.forEach(params => {
      this.pId = params.PatientId;
      //
      localStorage.setItem("fromGlobal",'false');
      this.patientRefId = this.pId;
      this.fetchApt();
      this.getPatient(this.patientRefId);
    });   
 // this.removeValidator();
}   
getPatient(id){
  this.apimicroservice.getPatientById(id).subscribe(res => { 
      this.firstName =res.data[0].firstName;
      this.patientId =res.data[0].patientId;
      this.lastName =res.data[0].lastName;
      this.pName =res.data[0].firstName + ' ' + res.data[0].middleName + ' ' + res.data[0].lastName;
      this.pRefId =res.data[0]._id;
      this.pMobile =res.data[0].mobile1;
   });
}

  // setValidator() { 
  //   this.apptDashboardForm.controls['date'].setValidators(Validators.required);
  //   this.apptDashboardForm.controls['date'].updateValueAndValidity();

  //   this.apptDashboardForm.controls['inwardOutward'].setValidators(Validators.required);
  //   this.apptDashboardForm.controls['inwardOutward'].updateValueAndValidity();

  //   this.apptDashboardForm.controls['fromInwardOutward'].setValidators(Validators.required);
  //   this.apptDashboardForm.controls['fromInwardOutward'].updateValueAndValidity();

  //   this.apptDashboardForm.controls['toInwardOutward'].setValidators(Validators.required);
  //   this.apptDashboardForm.controls['toInwardOutward'].updateValueAndValidity();

  //   this.apptDashboardForm.controls['courierRefId'].setValidators(Validators.required);
  //   this.apptDashboardForm.controls['courierRefId'].updateValueAndValidity();

  //   this.apptDashboardForm.controls['item'].setValidators(Validators.required);
  //   this.apptDashboardForm.controls['item'].updateValueAndValidity();

  // }

  // removeValidator() { 
  //   this.apptDashboardForm.controls['date'].setErrors(null);
  //   this.apptDashboardForm.controls['date'].clearValidators();
  //   this.apptDashboardForm.controls['date'].updateValueAndValidity();

  //   this.apptDashboardForm.controls['inwardOutward'].setErrors(null);
  //   this.apptDashboardForm.controls['inwardOutward'].clearValidators();
  //   this.apptDashboardForm.controls['inwardOutward'].updateValueAndValidity();

  //   this.apptDashboardForm.controls['fromInwardOutward'].setErrors(null);
  //   this.apptDashboardForm.controls['fromInwardOutward'].clearValidators();
  //   this.apptDashboardForm.controls['fromInwardOutward'].updateValueAndValidity();

  //   this.apptDashboardForm.controls['toInwardOutward'].setErrors(null);
  //   this.apptDashboardForm.controls['toInwardOutward'].clearValidators();
  //   this.apptDashboardForm.controls['toInwardOutward'].updateValueAndValidity();

  //   this.apptDashboardForm.controls['courierRefId'].setErrors(null);
  //   this.apptDashboardForm.controls['courierRefId'].clearValidators();
  //   this.apptDashboardForm.controls['courierRefId'].updateValueAndValidity();

  //   this.apptDashboardForm.controls['item'].setErrors(null);
  //   this.apptDashboardForm.controls['item'].clearValidators();
  //   this.apptDashboardForm.controls['item'].updateValueAndValidity();

  // }

  searchBillingData(){
    let branchId = '';
    if(this.userType !== "Super Admin"){
      branchId = this.branchRef;
    }else{
      branchId = '';
    }
    this.searchText = this.apptDashboardForm.get('searchBilling').value;
    let patientId = this.apptDashboardForm.get('patientId').value;
    let patientName = this.apptDashboardForm.get('patientName').value;
    let billNo = this.apptDashboardForm.get('billNo').value;
    let status = this.apptDashboardForm.get('status').value;
    let fromDate = this.apptDashboardForm.get('fdate').value;
    let toDate = this.apptDashboardForm.get('todate').value;
    //
    //
    this.apimicroservice.getAllAptDashboard(this.page,this.size,this.searchText,this.patientRefId).subscribe(res => {
      //  
      if (res.status) {    
        this.appoinmentList = res.data.data;
        this.totalLength=res.data.totalItems;
        
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    });
  }

  addData(){
    this.spinner.show();
    let payload = this.apptDashboardForm.value;
    payload.fromCityName = this.fromCity;
    payload.toCityName = this.toCity;
      payload.branchRefId = this.branchRef;
      payload.clinicRefId = this.clinicRef;
      payload.branchName = this.branchName;
   //
   
    this.apimicroservice.addInwardOutward(payload).subscribe(res => {
      this.spinner.hide();
      this.ngOnInit();
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);
        
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    },(ERROR:HttpErrorResponse) => {
      this.apimicroservice.openSnackbar(ERROR.error.message);
     //
     this.spinner.hide();
  });
  }

  redirectToBillForm(billNo){
    this.router.navigate(['bill-form',billNo]);
  }
 
  fetchApt() {
    let pg = 1;
    let size = 5;
    let search='';
    let branchId = '';
    if(this.userType !== "Super Admin"){
      branchId = this.branchRef;
    }else{
      branchId = '';
    }  
    this.apimicroservice.getAllAptDashboard(pg,size,search,this.patientRefId).subscribe(res => {
      //  
      if (res.status) {    
        this.appoinmentList = res.data.data;
        this.totalLength=res.data.totalItems;
     
        
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    });
    
   
  }
  prepopulation(id) {
    this.redirect('bill-form');
  }
  redirect(to): void {
    this.router.navigate([to]);
   } 

  // prepopulation(id) {
  //   this.spinner.show();
  //   this.apimicroservice.getInOutwardById(id).subscribe(res => {
  //     this.spinner.hide();
  //     let data = res.data[0]; 
  //     this.id = id;
  //      if(res.status){
  //        this.updateButton = true;
  //        this.submitButton = false;
  //        this.disableFromCity = true;
  //        this.disableToCity = true;
  //        this.apptDashboardForm = this.fb.group({
  //         "branchWiseId": new FormControl(data.branchWiseId),
  //         "date": new FormControl(data.date,Validators.required),
  //         "inwardOutward": new FormControl(data.inwardOutward,Validators.required),
  //         "fromInwardOutward": new FormControl(data.fromInwardOutward,Validators.required),
  //         "toInwardOutward": new FormControl(data.toInwardOutward,Validators.required),
  //         "fromCityName": new FormControl(data.fromCityName),
  //         "toCityName": new FormControl(data.toCityName),
  //         "docketNo": new FormControl(data.docketNo,[Validators.pattern("^[0-9]*$")]), 
  //         "branchRefId": new FormControl(data.branchRefId),
  //         "item": new FormControl(data.item,Validators.required), 
  //         "branchName": new FormControl(data.branchName),
  //         "courierRefId": new FormControl(data.courierRefId,Validators.required), 
  //         "courierProviderName": new FormControl(data.courierProviderName),
  //         "clinicRefId": new FormControl(data.clinicRefId),
  //         "remark": new FormControl(data.remark),
  //         "searchBilling": new FormControl(''), 
  //       });
  //       this.superBranchId = data.branchWiseId;   
  //      }else{

  //      }
  //    });
  // }

  // UpdateInwordOutword(){
  //  // this.setValidator();
  //   let payload = this.apptDashboardForm.value;
    
  //   if(this.userType == 'Super Admin')
  //   {
  //     this.apptDashboardForm.controls['branchRefId'].setValidators(Validators.required);
  //     this.apptDashboardForm.controls['branchRefId'].updateValueAndValidity();
  //   }
  //   else{
  //     this.apptDashboardForm.controls['branchRefId'].setErrors(null);
  //     this.apptDashboardForm.controls['branchRefId'].clearValidators();
  //     this.apptDashboardForm.controls['branchRefId'].updateValueAndValidity();
  //   }
    
  //   if (this.apptDashboardForm.invalid) {

  //      this.apptDashboardForm.get("date").markAsTouched();
  //      this.apptDashboardForm.get("inwardOutward").markAsTouched();
  //      this.apptDashboardForm.get("fromInwardOutward").markAsTouched();
  //      this.apptDashboardForm.get("toInwardOutward").markAsTouched();
  //      this.apptDashboardForm.get("courierRefId").markAsTouched();
  //      this.apptDashboardForm.get("item").markAsTouched();

  //   }else {
  //     this.updateData();
  //   }
  // }

  // updateData(){
  //   this.spinner.show();
  //   let payload = this.apptDashboardForm.value;
  //   payload.fromCityName = this.fromCity;
  //   payload.toCityName = this.toCity;
  //   if(this.userType !== 'Super Admin')
  //   {
  //     payload.branchRefId = this.branchRef;
  //     payload.clinicRefId = this.clinicRef;
  //     payload.branchName = this.branchName;
  //   }
  
  //    this.apimicroservice.updateInOutwardDetails(payload,this.id).subscribe(res => {
  //      //
  //      this.spinner.hide();
  //      if(res.status){
  //        this.apimicroservice.openSnackbar(res.message);
  //         this.updateButton = false;
  //         this.submitButton = true;
  //         this.ngOnInit();
  //      }else{ 
  //      //  //this.apimicroservice.openSnackbar(res.message);
  //      }
  //    });
  // }
 
  // cancelUpdate(){
  //   this.apptDashboardForm.reset();
  //   this.submitButton=true;
  //   this.updateButton=false;
  //   this.ngOnInit();
  // }

  openSnackbar(message: string) {
    return this._snackBar.open(message, "close", {
      verticalPosition: "bottom",
      horizontalPosition: "right",
      duration: 2000,
      panelClass: ["snackbarStyle"],
    });
  }
  toapptDashboardForm() {
    document.getElementById("toapptDashboardForm").scrollIntoView();
  }
  toBillingLst() {
    document.getElementById("toBillingLst").scrollIntoView();
  }

  onPageChange(event) {
    this.page = event;
    this.searchText = this.apptDashboardForm.get('searchBilling').value;
    let patientId = this.apptDashboardForm.get('patientId').value;
    let patientName = this.apptDashboardForm.get('patientName').value;
    let billNo = this.apptDashboardForm.get('billNo').value;
    let status = this.apptDashboardForm.get('status').value;
    let fromDate = this.apptDashboardForm.get('fdate').value;
    let toDate = this.apptDashboardForm.get('todate').value;
    let branchId = '';
    if(this.userType !== "Super Admin"){
      branchId = this.branchRef;
    }else{
      branchId = '';
    }
    this.apimicroservice.getAllAptDashboard(this.page,this.size,this.searchText,this.patientRefId).subscribe(res => {
      //  
      if (res.status) {    
        this.appoinmentList = res.data.data;
        this.totalLength=res.data.totalItems;
        
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    });
  }
  onKeyUpEvent(event: any) {
    
    this.searchText = this.apptDashboardForm.get('searchBilling').value;
    let patientId = this.apptDashboardForm.get('patientId').value;
    let patientName = this.apptDashboardForm.get('patientName').value;
    let billNo = this.apptDashboardForm.get('billNo').value;
    let status = this.apptDashboardForm.get('status').value;
    let fromDate = this.apptDashboardForm.get('fdate').value;
    let toDate = this.apptDashboardForm.get('todate').value;
    //
    //
    let branchId = '';
    if(this.userType !== "Super Admin"){
      branchId = this.branchRef;
    }else{
      branchId = '';
    }
    this.apimicroservice.getAllAptDashboard(this.page,this.size,this.searchText,this.patientRefId).subscribe(res => {
      //  
      if (res.status) {    
        this.appoinmentList = res.data.data;
        this.totalLength=res.data.totalItems;
        
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    });
  }

  toOpenDateChnage()
  {

    let payload = this.apptDashboardForm.value;
    // const date1 = new Date(dd.toDateString()+" "+a);
    // const date2 = new Date(dd.toDateString()+" "+b);
    
    if(payload.fdate >= payload.todate && payload.todate !== '')
    {
      this.apimicroservice.openSnackbar("From Date should be Less than To Date");
      this.apptDashboardForm.controls['fdate'].setValue('');
    }
  }
  toCloseDateChnage()
  {
    
    let payload = this.apptDashboardForm.value;
    // const date1 = new Date(dd.toDateString()+" "+a);
    // const date2 = new Date(dd.toDateString()+" "+b);

    
    if(payload.todate <= payload.fdate && payload.fdate !== '')
    {
      this.apimicroservice.openSnackbar("To Date should be Greater than From Date");
      this.apptDashboardForm.controls['todate'].setValue('');
    }
  }
  billing()
  {
    const dialogRef = this.dialog.open(PopupTotalBillComponent,
      { 
        data: {
          patientRefId: '',
          appointId:'',
          patientDetails:'',
          page:"Billing Page"
        }
      }
      
      );
      dialogRef.afterClosed().subscribe((result) => {
        this.fetchApt();
      })
  }
  editAppointment(id)
  {      
   sessionStorage.setItem('appointment_id',id);
   let e = null;
   this.openBookAppointmentPopup(id);
  }
  openBookAppointmentPopup(id) {
    this.bookRefId = id;
  // 
  
    const dialogRef3 = this.dialog.open(AppoinmentPopupComponent, {
      width: "70%",
      //height: "550px",
     data: {
       patientInfo:  this.bookRefId,
       bookDate: this.bookDate,
     },
   }); 

   dialogRef3.afterClosed().subscribe((result) => {
     this.ngOnInit();
     
   });
   
   
  }
  openBookAppointmentPopup2(id) {
    this.bookRefId = id; 
  //  
  this.bookRefId = null;
    const dialogRef3 = this.dialog.open(AppoinmentPopupComponent, {
      width: "70%",
      //height: "550px", 
     data: { 
      pName: this.pName, 
      firstName: this.firstName, 
      patientId: this.patientId, 
      lastName: this.lastName, 
      pRefId: this.pRefId, 
      pMobile: this.pMobile
   },
   }); 

   dialogRef3.afterClosed().subscribe((result) => {
     this.ngOnInit();
     
   });
   
   
  }
  
  onback() {
    this.apimicroservice.back()
  }
}

