<div class="container-fluid bgcolor2" >
    <form  autocomplete="off" novalidate>
        <div class="row">
            <div class="col-sm-12">
                        <div class="row col-sm-12">
                            <div class="col-sm-4"></div>
                            <div class="col-sm-7">
                                <h1 class="pt-2 pl-0">New Bill</h1> 
                                <!-- <h3 class="col-sm-12">Stock Menu</h3> -->
                            </div>
                            <div class="col-sm-1 justify-content-end">
                                  <a (click)="onClose()" matTooltip="Close" class="d-flex align-items-center mt-2"><span class="material-symbols-outlined">close</span></a>
                              </div>
                      </div>
                      <div class="row col-sm-12">
                      <div class="icons-wraper dashboard-boxes col-sm-12">
                        <ul class=" row rowStyle">
                            <!-- <i class="fa fa-usd icon-img" aria-hidden="true"></i>
                            
                            <img src="../../assets/icon-bg8.svg" alt="" class=" icon-bg"> -->
 
                            <li class=" col-sm-6">
                                <a class="icon-card p-0 col-sm-12"
                                (click)="openServicePopUp()">
                                    <div class="icon-box">
                                        <!-- <i class="bi bi-receipt-cutoff icon-img" aria-hidden="true"></i> -->
                                        <!-- <i class="fa fa-usd icon-img" aria-hidden="true"></i> -->
                                        <!-- <i class="fa fa-usd icon-img"></i> -->
                                        <!-- <i class="fa-solid fa-money-bill " aria-hidden="true"></i> -->
                                        <i class="fa fa-money icon-img" aria-hidden="true"></i>
                                        <img src="../../assets/icon-bg8.svg" alt="" class=" icon-bg">
                                     </div>
                                     <h4 class="">Service Bill</h4>
                                </a>
                          </li>
                            <li class=" col-sm-6"> 
                                <a class="icon-card p-0 col-sm-12"
                                (click)="openDevicePopUp()">
                                   <div class="icon-box">
                                      <i class="fa-solid fa-ear-listen icon-img"></i>
                                      <img src="../../assets/icon-bg5.svg" alt="" class="icon-bg">
                                   </div>
                                   <h4 class="">HA Bill</h4>
                                </a>
                          </li>
                         
                      
                </ul>
            </div>
            </div>
  </div>
  </div>
    </form>
</div>
