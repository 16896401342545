import { Component, Inject, OnInit } from "@angular/core";
import {FormGroup,FormControl,Validators,FormBuilder} from "@angular/forms";
import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  MatSnackBar,
} from "@angular/material/snack-bar"; 
import { MatDialog} from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { PopupTotalBillComponent } from "../popup-total-bill/popup-total-bill.component";
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PopupBrandComponent } from "../popup-brand/popup-brand.component";
import { PopUpDevicetypeComponent } from "../pop-up-devicetype/pop-up-devicetype.component";
import { PopUpDeviceComponent } from "../pop-up-device/pop-up-device.component";

@Component({
  selector: 'app-popup-device-by-patient',
  templateUrl: './popup-device-by-patient.component.html',
  styleUrls: ['./popup-device-by-patient.component.scss']
})
export class PopupDeviceByPatientComponent implements OnInit {
  deviceByPatientForm: FormGroup;
  holidayForm: FormGroup;
  date: any;
  listOfAppointment:any; 
  totalLength:any;
  appointmentId: any;
  size=5;
  page=1;
  searchText: any;
  submitButton = true;
  updateButton = false;
  cancelButton = false;
  allColor: any;
  
  filteredList: { name: string; value: string; }[];
  filteredListAppoinmentType: { name: string; value: string; }[];
  userType: string;
  branchRef: string;
  clinicRef: string;
  BranchList: any;
  isSuperAdmin= true;
  superBranchId: any;
  branchName: string;
  filteredListBranch: any;
  currentdate: Date;
  listOfBilling: any;
  id: any;
  courierList: any;
  filteredListCourier: any;
  disableFromCity = false;
  disableToCity = true;
  tocityList: any;
  cityList: any;
  fromCity: any;
  toCity: any;
  arr =[];
  courierServiceCreate = true;
  branchCreate = true;
  billingList: any;
  search: any;
  clinicHolidayList: any;
  vendorName: any;
  invoiceNo: any;
  invoiceDate :any;
  patientRefId: any;
  patientName: any;
  brandlist: any;
  deviceTypelist: any;
  deviceMasterForm: any;
  filteredDeviceList: any;
  devicelist:any;
  sid: any;
  deviceList: any;
  filteredListDevice: any;
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
     public dialog: MatDialog, 
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<PopupDeviceByPatientComponent>,@Inject(MAT_DIALOG_DATA) public data:any,  
  ) { 
    dialogRef.disableClose = true;
    this.patientRefId = data.patientRefId;
    this.patientName = data.patientName;
  }
  userPermission:any;
  billingCreate= true;
  billingUpdate= true;
   
  branchArr = [];

  ngOnInit(): void {
    this.currentdate = new Date();
    this.branchArr = [];
    this.page=1;
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.userType = sessionStorage.getItem('userType');
    this.userType = sessionStorage.getItem('userType');

    this.deviceByPatientForm = this.fb.group({
      "billingRefId": new FormControl(),
      "patientRefId": new FormControl(''),
      "patientName": new FormControl('',[Validators.pattern("^[a-zA-Z &-\\s]*$")]),
      "model": new FormControl('',[Validators.required,Validators.pattern("^[a-zA-Z0-9 &-\\s]*$")]),
      "oldSerialNo": new FormControl(''), 
      "newSerialNo": new FormControl('',[Validators.required,Validators.pattern("^[a-zA-Z0-9 &-\\s]*$")]),
      "warranty": new FormControl('',[Validators.pattern("^[0-9]*$")]),
      "billNo": new FormControl(''),
      "brand": new FormControl('',Validators.required),
      "brandLable": new FormControl(''),
      "deviceType": new FormControl('',Validators.required), 
      "type": new FormControl('Not Fitted By Us'),
      "searchDevice": new FormControl(''),
      "freetext": new FormControl(''), 
      "deviceRefId": new FormControl(''), 
      "createdAt": new FormControl(''),
      "dateOfPurchase": new FormControl(''), 
      
    });
    this.deviceByPatientForm.get("patientRefId").setValue(this.patientRefId);
    this.deviceByPatientForm.get("patientName").setValue(this.patientName);
    //HEARING AID
    this.deviceByPatientForm.controls["deviceType"].setValue("HEARING AID");
  this.fetchBrandList();
  this.fetchDeviceTypeList();
  this.fetchExternalDevice(); 
  this.fetchDeviceList();
} 

  redirect(to): void {
    this.router.navigate([to]);
  } 
  submitDevice() {
     if (this.deviceByPatientForm.invalid) {
       this.deviceByPatientForm.get("billingRefId").markAsTouched();
       this.deviceByPatientForm.get("brand").markAsTouched();
       this.deviceByPatientForm.get("deviceType").markAsTouched();
       this.deviceByPatientForm.get("dateOfPurchase").markAsTouched(); 
       this.deviceByPatientForm.get("model").markAsTouched();
      // this.deviceByPatientForm.get("oldSerialNo").markAsTouched();
       this.deviceByPatientForm.get("newSerialNo").markAsTouched();
       this.deviceByPatientForm.get("warranty").markAsTouched();
       this.deviceByPatientForm.get("billNo").markAsTouched();
       return;
     } else {
       this.addDevice();
     }
   }
   prepopulation(id) { 
    this.apimicroservice.getDeviceByPatientDataById(id).subscribe(res => {
      let data = res.data[0];
      this.sid = id;
       if(res.status){
        this.submitButton = false;
         this.updateButton = true;
         this.cancelButton = true;
         this.deviceByPatientForm = this.fb.group({ 
          "billingRefId": new FormControl(data.billingRefId),
          "patientRefId": new FormControl(data.patientRefId),
          "patientName": new FormControl(data.patientName,[Validators.pattern("^[a-zA-Z &-\\s]*$")]),
          "model": new FormControl(data.model,[Validators.required,Validators.pattern("^[a-zA-Z0-9 &-\\s]*$")]),
          "oldSerialNo": new FormControl(data.oldSerialNo), 
          "newSerialNo": new FormControl(data.newSerialNo,[Validators.required,Validators.pattern("^[a-zA-Z0-9 &-\\s]*$")]),
          "warranty": new FormControl(data.warranty,[Validators.pattern("^[0-9]*$")]),
          "billNo": new FormControl(data.billNo),
          "brand": new FormControl(data.brand,Validators.required),
          "brandLable": new FormControl(data.brandLable),
          "deviceType": new FormControl(data.deviceType,Validators.required),
          "type": new FormControl('Not Fitted By Us'),
          "searchDevice": new FormControl(''),
          "freetext": new FormControl(data.freetext), 
          "deviceRefId": new FormControl(data.deviceRefId), 
          "dateOfPurchase": new FormControl(data.dateOfPurchase), 
          "updateddAt": new Date(),
         });
       }else{
     //    //this.apimicroservice.openSnackbar(res.message);
       }
     });
  }
   addDevice() {
     this.spinner.show();
     let payload = this.deviceByPatientForm.value;
     payload.clinicRefId = this.clinicRef;
     payload.branchRefId = this.branchRef; 
     this.apimicroservice.addPatientByDevice(payload).subscribe(res => {
       this.spinner.hide();
       if (res.status) {
         this.apimicroservice.openSnackbar(res.message);     
        this.ngOnInit();
        this.onClose();      
       } else {
         this.apimicroservice.openSnackbar(res.message);
       }
     } ,(ERROR:HttpErrorResponse) => {
     this.apimicroservice.openSnackbar(ERROR.error.message);
      this.spinner.hide();
  }); 
   } 
   updateData() {
    let payload = this.deviceByPatientForm.value;
     this.apimicroservice.updateDeviceByPatientData(payload,this.sid).subscribe(res => {
       if(res.status){
         this.apimicroservice.openSnackbar(res.message);
          this.updateButton = false;
          this.submitButton = true;
          this.ngOnInit();
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
    
  }
  cancelUpdate(){
    this.deviceByPatientForm.reset();
    this.submitButton=true;
    this.updateButton=false;
    this.cancelButton=false;
    this.ngOnInit();
  }
  onback() {
    this.apimicroservice.back()
  }
  onClose(): void {
    this.dialogRef.close();
  }

  
  brandSelect(e)
  {
    let payload = this.deviceByPatientForm.value;
    // if(event.source.selected){
    //   this.arr.push(e);
    // }
    // else{
    //   this.arr = this.arr.filter(b => b!=e);
    // } 
    // //
    
    // payload.brandLable = this.arr;
    this.deviceByPatientForm.controls['brandLable'].setValue(e);
  }
  openBrandPopUp() {
    localStorage.clear();
    const dialogRef = this.dialog.open(PopupBrandComponent,
      {
        width: "400px",
      }); 
      dialogRef.afterClosed().subscribe((result) => {
        let clinicRef= sessionStorage.getItem('clinicRef');
        let branchRef= sessionStorage.getItem('branchRef');
        let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
        let branchRefSuperAdmin= '';
        if (this.userType !== 'Super Admin') {
        this.apimicroservice.getBrandList(branchRef,clinicRef).subscribe(res => {
          //
           this.brandlist = res.data;   
           this.filteredList = this.brandlist.slice();
           this.brandSelect(res.data[0].brandName);
           this.deviceByPatientForm.get("brand").setValue(res.data[0]._id);
        });
      }else{
        this.apimicroservice.getBrandList(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
          //
           this.brandlist = res.data;   
           this.filteredList = this.brandlist.slice();
           this.brandSelect(res.data[0].brandName);
           this.deviceByPatientForm.get("brand").setValue(res.data[0]._id);
        });
      }
        //this.fetchBrandList();
      });
  }

  fetchBrandList() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
    this.apimicroservice.getBrandList(branchRef,clinicRef).subscribe(res => {
      //
       this.brandlist = res.data;   
       this.filteredList = this.brandlist.slice();
    }); 
  }else{
    this.apimicroservice.getBrandList(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
      //
       this.brandlist = res.data;   
       this.filteredList = this.brandlist.slice();
    }); 
  }
  }

  openDeviceTypePopUp() {
    localStorage.clear();
    const dialogRef4 = this.dialog.open(PopUpDevicetypeComponent,
      {
        width: "400px",
      });
      dialogRef4.afterClosed().subscribe((result) => {
        this.apimicroservice.getAllDeviceTypeList().subscribe(res => {
          //  
          if (res.status) {    
            this.deviceTypelist = res.data;
            this.deviceByPatientForm= this.deviceTypelist.slice();
            this.deviceMasterForm.get("deviceType").setValue(res.data[0].deviceType);
          } else {
            //this.apimicroservice.openSnackbar(res.message);
          }
        })
       // this.fetchDeviceTypeList();
      });
  }

  fetchDeviceTypeList() {
    this.apimicroservice.getAllDeviceTypeList().subscribe(res => {
      //  
      if (res.status) {    
        this.deviceTypelist = res.data;
        this.filteredDeviceList= this.deviceTypelist.slice();
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    })
  } 
  fetchExternalDevice() {
    this.spinner.show();
    let pg = 1;
    let size1 = 5;
    let search='';  
    this.devicelist = [];
    this.searchText = this.deviceByPatientForm.get('searchDevice').value;    
  this.apimicroservice.getExternalDevice(pg,size1,search,this.patientRefId).subscribe(res => {
   this.spinner.hide();
   res.data.deviceData.forEach((element) => {
     this.devicelist.push(element)
   });  
   this.totalLength = res.data.totalItems;  
       }); 
 // }
  }
  onPageChange(event) {
    this.page = event;
    this.spinner.show();
    this.searchText = this.deviceByPatientForm.get("searchDevice").value;
    this.devicelist = [];   
     this.apimicroservice.getExternalDevice(this.page,this.size,this.searchText,this.patientRefId).subscribe(res => {
      this.spinner.hide();
      res.data.deviceData.forEach((element) => {
        this.devicelist.push(element)
      });  
      this.totalLength = res.data.totalItems;  
          }); 
  }
  onKeyUpEvent(event: any) {
    this.searchText = this.deviceByPatientForm.get("searchDevice").value; 
          this.devicelist = [];   
          this.apimicroservice.getExternalDevice(this.page,this.size,this.searchText,this.patientRefId).subscribe(res => {
          res.data.deviceData.forEach((element) => {
            this.devicelist.push(element)
          });  
          this.totalLength = res.data.totalItems;  
              }); 
  } 
  fetchDeviceList(){
    let i = 0;
      let id = '';
      let clinicRef= sessionStorage.getItem('clinicRef');
      let branchRef= sessionStorage.getItem('branchRef');
      let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
      let branchRefSuperAdmin= '';
      if (this.userType !== 'Super Admin') {
      this.apimicroservice.getDeviceById(id,branchRef,clinicRef).subscribe(res => {   
       this.deviceList = res.data;
       this.filteredListDevice = this.deviceList.slice();
       if(res.status){
         //this.apimicroservice.openSnackbar(res.message);      
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
    });  
  }else{
    this.apimicroservice.getDeviceById(id,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {   
      this.deviceList = res.data;
      this.filteredListDevice = this.deviceList.slice();
      if(res.status){
        //this.apimicroservice.openSnackbar(res.message);      
      }else{
        //this.apimicroservice.openSnackbar(res.message);
      }
   });
  }
  }
  openDevicePopUp(){ 
    //localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpDeviceComponent,
      {
        width: "1200px",
      });
      dialogRef3.afterClosed().subscribe((result) => {
       //
       // 
        // this.fetchDeviceList(); 
        let id = '';
        let clinicRef= sessionStorage.getItem('clinicRef');
        let branchRef= sessionStorage.getItem('branchRef');
        let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
        let branchRefSuperAdmin= '';
        if (this.userType !== 'Super Admin') {
        this.apimicroservice.getDeviceById(id,branchRef,clinicRef).subscribe(res => {   
          this.deviceList = res.data;
          this.filteredListDevice = this.deviceList.slice();
          let lenght = res.data.length-1;
          //
          
          this.deviceByPatientForm.get("model").setValue(this.deviceList[lenght].model);
          this.selectDevice(this.deviceList[lenght]._id); 
       
          if(res.status){
            //this.apimicroservice.openSnackbar(res.message);      
          }else{
            //this.apimicroservice.openSnackbar(res.message);
          }
       });  
      }else{
        this.apimicroservice.getDeviceById(id,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {   
          this.deviceList = res.data;
          this.filteredListDevice = this.deviceList.slice();
          let lenght = res.data.length-1;
          //
          
          this.deviceByPatientForm.get("model").setValue(this.deviceList[lenght].model);
          this.selectDevice(this.deviceList[lenght]._id); 
       
          if(res.status){
            //this.apimicroservice.openSnackbar(res.message);      
          }else{
            //this.apimicroservice.openSnackbar(res.message);
          }
       });  
      }
       
        
       });
  } 
  selectDevice(id){
    this.deviceByPatientForm.get("deviceRefId").setValue(id);
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
    this.apimicroservice.getDeviceById(id,branchRef,clinicRef).subscribe(res => {
      this.spinner.hide();
     let data = res.data[0];
      this.deviceByPatientForm.get("brand").setValue(data.brand);
      this.deviceByPatientForm.controls['brandLable'].setValue(data.brandLable);
      this.deviceByPatientForm.get("deviceType").setValue(data.deviceType);
    //  this.deviceByPatientForm.get("model").setValue(this.deviceList[lenght].model);
    });
  }else{
    this.apimicroservice.getDeviceById(id,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
      this.spinner.hide();
     let data = res.data[0];
      this.deviceByPatientForm.get("brand").setValue(data.brand);
      this.deviceByPatientForm.controls['brandLable'].setValue(data.brandLable);
      this.deviceByPatientForm.get("deviceType").setValue(data.deviceType);
    //  this.deviceByPatientForm.get("model").setValue(this.deviceList[lenght].model);
    });
  }
  } 
}

