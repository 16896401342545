<div class="container-fluid bgcolor2" [formGroup]="permissionForm" >
  <div class="row">
      <div class="col-sm-12">
          <div class="row">
              <div class="card divbodyy mb-2 borderradius2 ">
                <div class="col-sm-12 ">
                 
                <!-- <div class="col-sm-12">
                      <div class="col-sm-12 bg-secondary rounded-3 pl-4 pr-4 mb-3">
                          <div class="row">
                         
                                <h1 class="fass mt-2">Add Permission </h1>
                               
                          </div>
                        </div>
                  </div> -->
                  <div class="col-sm-12 margin_left2 margin_right2 row divbody3 colorblack">
                    <div class="table-responsive">
                      <table
                      class="table table-bordered data-table-custom">
                    
                      <!-- <table id="excel-table" class="table table-striped mb-0"> -->
                        <tr class="title-card-cust red_col">
                          <!-- <th style="width: 10px">#</th> -->
                          <th class="text-center table_w" *ngIf="page == true">Pages</th>
                          <th class="text-center table_w" *ngIf="tab == true && page!== true">Tabs</th>
                          <th class="text-center table_w">Select All
                            
                                <mat-checkbox class="example-full-width mt-1" formControlName="selectAllPages" (change)="onSelectAllPagesChange($event)"> 
                                                            
                                </mat-checkbox>
                          </th>
                          <th class="text-center table_w">View</th>
                          <th class="text-center table_w">Create</th>
                          <th class="text-center table_w">Update</th>
                          <th class="text-center table_w">Delete</th>
  
                        </tr>
                        <!-- <tbody *ngIf="alertLenght != 0 && page == true || tab == true"> -->
                        <tbody *ngIf="page == true || tab == true">
                          <tr
                           *ngFor="
                  let item of allPages
                  | paginate : { id:'alert_pagination', 
                  itemsPerPage: 3, currentPage: 1, totalItems:3
                   }"
                   >
  
                            <td *ngIf="page == true && item.isTab == false"
                              class="pt-1 pb-1 pr-4 text-left ml-3 pl-3 colorblack">
  
                              {{item.pageName}} <br>
                            </td>
                            <td *ngIf="tab == true && item.isTab == true"
                              class="pt-1 pb-1 pr-4 text-left ml-3 pl-3 colorblack">
                              <span class="text-right ml-4" *ngIf="item.isTab == true && tab == true">
  
  
                                {{item.pageName}}
  
                                <br>
                              </span>
                            </td>
                          <td class="pt-1 pb-1 text-center colorblack">
                            <input type="checkbox" value="{{item.pageName}}" (change)="onSelectAllChange($event)"
                                id="{{item.id}}" [checked]="item.selectAll" class=" mr-2" />
                          </td>
                            <td *ngIf="page == true && item.isTab == false" class="pt-1 pb-1 text-center colorblack">
  
                              <input type="checkbox" value="{{item.pageName}}" (change)="onViewChange($event)"
                                id="{{item.id}}" [checked]="item.view" class=" mr-2" />
                              <!-- {{item.View}}  -->
  
                            </td>
                            <td *ngIf="tab == true && item.isTab == true" class="pt-1 pb-1 text-center colorblack">
  
                              <input *ngIf="!item.isStockTab" type="checkbox" value="{{item.pageName}}" 
                              (change)="onCheckboxViewChange($event)"
                                [checked]="item.view" class="mr-2" [disabled]="!tabenable" />
                                
                                <input *ngIf="item.isStockTab" type="checkbox" value="{{item.pageName}}" (change)="onCheckboxViewChange($event)"
                                [checked]="item.view" class="mr-2" [disabled]="!stocktabenable" />
  
                            </td>
           
                            <td *ngIf="page == true && item.isTab == false" class="pt-1 pb-1 colorblack text-center">
                          
                              <input type="checkbox" [disabled]="!item.view" value="{{item.pageName}}" id="{{item.id}}"
                                (change)="onCreateChange($event)" [checked]="item.create" class=" mr-2" />
                            </td>
                            <td *ngIf="tab == true && item.isTab == true" class="pt-1 pb-1 colorblack text-center">
  
                              <input type="checkbox" [disabled]="!item.view" value="{{item.pageName}}"
                                (change)="onCheckboxCreateChange($event)" [checked]="item.create" class="mr-2" /><br>
  
                            </td>
                            <td *ngIf="page == true && item.isTab == false" class="pt-1 pb-1 colorblack text-center">
  
                              <input type="checkbox" [disabled]="!item.view" value="{{item.pageName}}"
                                (change)="onUpdateChange($event)" [checked]="item.update" class=" mr-2" />
                            </td>
                            <td *ngIf="tab == true && item.isTab == true" class="pt-1 pb-1 colorblack text-center">
  
                              <input type="checkbox" [disabled]="!item.view" value="{{item.pageName}}"
                                (change)="onCheckboxUpdateChange($event)" [checked]="item.update" class="mr-2" /><br>
  
                            </td>
                            <td *ngIf="page == true && item.isTab == false" class="pt-1 pb-1 colorblack text-center">
  
                              <input type="checkbox" [disabled]="!item.view" value="{{item.pageName}}"
                                (change)="onDeleteChange($event)" [checked]="item.delete" class=" mr-2" />
  
                            </td>
                            <td *ngIf="tab == true && item.isTab == true" class="pt-1 pb-1 colorblack text-center">
  
  
                              <input type="checkbox" [disabled]="!item.view" value="{{item.pageName}}"
                                (change)="onCheckboxDeleteChange($event)" [checked]="item.delete" class="mr-2" /><br>
  
  
                            </td>
  
                          </tr>
  
                        </tbody>
                     
                      </table>
                    </div>
                  </div>
              </div>
              <div  class="row">
                 <div  class="col-sm-2 offset-sm-8 mb-2">
                        <button class="btn btn-sm btn-primary example-full-width mt-2 mr-3" *ngIf="updateButton"
                        (click)="updatePermission()"  type="button">Update </button>
                      </div>
                      <div class="col-sm-2 mb-2" *ngIf="cancelButton">
                        <button class="btn btn-sm btn-primary example-full-width mt-2 mr-3"  type="reset" (click)="cancelUpdate()">Cancel </button>
                     </div>
                      <div class="col-sm-2 mb-2  offset-sm-10 ">
                        <button class="btn btn-sm btn-primary example-full-width mt-2 mr-3" *ngIf="submitButton"
                        (click)="submitUser()"  type="button">Save </button>
                      </div>
                    
                <!-- <button *ngIf="permissionCreate == true && permissionCreate" 
                class="btn btn-sm btn-primary example-full-width mt-2 mr-3" 
                (click)="validateForm()"> Save</button> -->
             </div>
              </div>
            </div>
          </div>
          </div>
  </div>
