<div class="container-fluid bgcolor2" [formGroup]="clinicMasterForm">
  <form [formGroup]="clinicMasterForm" autocomplete="off" novalidate>
    <div class="row">
      <div class="col-sm-1">
  
      </div>
      <div class="col-sm-11">

        <div class="row">
          <div class="col-sm-12 pr-4">

            <div class="row pl-0 ">

              <div class="col-12 pl-0 ">
                <div class="row ">
                <div class="col-sm-9">
                
                    <h1 class="pt-2 pl-0" >Clinic</h1>
                    </div>
                    <div class="col-sm-3 mt-1">
                      <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                        <a (click)="onback()" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">arrow_back</span>Back</a>
                 </div>
              </div>
            </div>
           </div>
           </div>

        <!-- <div class="row">
          <div class="col-sm-11">
            <div class="row">
 
              <span><img src="../../assets/clinic.png" width="35rem"> </span> 
              <span><h1 class="fass ml-3 pt-1" id="toClinicForm">Clinic</h1></span>
            </div>
        
           </div>
      
         
          <div class="col-sm-1">
            <div class="row justify-content-end mr-1">
           
              <img matTooltip="Back"  width=35rem src="../../assets/back.png" (click)="onback()">
          </div>
          </div>
        </div> -->

            <div class="row margingTop">
              <div class="card divbody borderradius mb-3 pl-4 pr-4 p-1">
                <div class="row">
                <div class="col-sm-2">
                  <!-- <h1 class="titlecolor"> Patient Master</h1> -->
                      <div class="col-sm-12 bg-secondary rounded-3 p-1">
                       
                          
                        <div class="col-sm-12 ">
                          <img *ngIf="!logoIcon" class=" img2 frame  " src="../../assets/clinic_logo2.jpg" alt="Amanda Wilson" width="500rem"><!--rounded-circle-->
                          <img *ngIf="logoIcon" class=" img2 frame " [src]="logoIcon" alt="patient photo" width="500rem"><!--rounded-circle-->
                          <!-- <button class="btn btn-light shadow btn-sm mb-2" type="button"><i class="ai-refresh-cw me-2"></i>Change avatar</button> -->
                          
                      </div>
                      <div class="col-sm-12 btnpossition"  >
                        <!-- <h1 class="fass p-0 m-0"> Patient Master</h1> -->
                        <input class="form-control2" #fileUpload type="file" style="display: none;" (change)="onChange($event)" />
                        <button type="button" class="btn btn-light shadow btn-sm m-0 mt-1 example-full-width"
                         matTooltip="Upload JPG, GIF or PNG image.300 x 300 required." 
                         (click)="fileUpload.click()" ><i class="ai-refresh-cw me-2" ></i>Upload Photo</button>
                         <!-- <a [routerLink]="" class="p-0 m-0">Upload Photo</a>   -->
                        
                      </div>
                          
                        </div>
                  </div>
                <div class="col-sm-10">
                  <div class="row">
                    <div class="col-sm-12">

                      <div class="row mt-2">
                        <div class="col-sm-12 pb-0 mb-0">
          
                          <h5 class="pb-0 mb-0">Clinic Details :</h5>
                          <!-- <label class="header">Clinic Details :</label> -->
                        </div>
                      </div>


                      <div class="row">
                        <div class=" col-sm-3  ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Clinic Id</mat-label>
                            <input class="form-control2" matInput formControlName="clinicId" readonly />

                          </mat-form-field>
                        </div>
                        <div class=" col-sm-3 ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Clinic Name
                              <!-- <span class="required-asterisk">*</span>   -->
                            </mat-label>
                            <input class="form-control2" required formControlName="clinicName" matInput placeholder="Clinic Name"  />
                            

                          </mat-form-field>
                          <mat-error
                              *ngIf="clinicMasterForm.controls['clinicName'].touched && clinicMasterForm.controls['clinicName'].errors?.pattern">
                              Please enter valid Clinic Name</mat-error>

                            <mat-error
                              *ngIf="clinicMasterForm.controls['clinicName'].touched && clinicMasterForm.controls['clinicName'].errors?.required">
                              This field is mandatory.</mat-error>
                        </div>
                        <div class=" col-sm-3  ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Clinic Type 

                              <!-- <span class="required-asterisk">*</span> -->
                            </mat-label>
                            <mat-select required formControlName="clinicType" >
                              <mat-option value="Permanent">Permanent</mat-option>
                              <mat-option value="Visiting">Visiting</mat-option>
                            </mat-select>

                           

                          </mat-form-field>
                          <mat-error
                          *ngIf="clinicMasterForm.controls['clinicType'].touched && clinicMasterForm.controls['clinicType'].errors?.required">
                          This field is mandatory.</mat-error>
                        </div>
                      </div>
                      <div class="row">
                        
                        <div class="col-sm-12 pb-0 mb-0">
                          <h5 class="pb-0 mb-0 mt-2">Time Details :</h5>
                       
                        </div>
                        <div class=" col-sm-3  ">
                          
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Open Time</mat-label>
                            <!-- <input class="form-control2" formControlName="openTime" (change)="toOpenTimeChange()" matInput id="appt-time" type="time" name="appt-time"
                              step="2" [min]="'07:00 am'"
                              [max]="'09:00 pm'"> -->
                              <input class="form-control2" required matInput
                              placeholder="08:00 am"
                              formControlName="openTime"
                              [ngxTimepicker]="pickerF"
                              value=""
                              [disableClick]="true"
                              readonly
                              />
                              <ngx-material-timepicker-toggle matSuffix [for]="pickerF"></ngx-material-timepicker-toggle>
                              <ngx-material-timepicker (closed)="toOpenTimeChange()" #pickerF></ngx-material-timepicker>
                          </mat-form-field>
                          <mat-error
                          *ngIf="clinicMasterForm.controls['openTime'].touched && clinicMasterForm.controls['openTime'].errors?.required">
                          This field is mandatory.</mat-error>
                        </div>
                        <div class=" col-sm-3  ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Close Time</mat-label>
                            <!-- <input class="form-control2" id="appt-time2" type="time" (change)="toCloseTimeChange()"
                             name="appt-time2" step="2" formControlName="closeTime" [min]="'08:00 am'"
                              [max]="'09:00 pm'"
                              matInput> -->
                              <input class="form-control2" required matInput
                              placeholder="08:00 pm"
                              formControlName="closeTime"
                              [ngxTimepicker]="pickerA"
                              value=""
                              [disableClick]="true"
                              readonly
                              />
                              <ngx-material-timepicker-toggle matSuffix [for]="pickerA"></ngx-material-timepicker-toggle>
                              <ngx-material-timepicker (closed)="toCloseTimeChange()" #pickerA></ngx-material-timepicker>
                          </mat-form-field>
                          <mat-error
                          *ngIf="clinicMasterForm.controls['closeTime'].touched && clinicMasterForm.controls['closeTime'].errors?.required">
                          This field is mandatory.</mat-error>
                        </div> 
                        <div class=" col-sm-3  ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Weekly Off</mat-label>
                            <mat-select #select1 placeholder="Weekly Off" class="colorblack" formControlName="clinicWeeklyOff">
                              <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'name'" [array]="allDays"
                                (filteredReturn)="filteredListDays =$event"></mat-select-filter>
                              <mat-option *ngFor="let item of filteredListDays" [value]="item.value">
                                {{item.name}}
                              </mat-option>
                            </mat-select>
                             
                          </mat-form-field>
                          <!-- <mat-error
                            *ngIf="clinicMasterForm.controls['clinicWeeklyOff'].touched && clinicMasterForm.controls['clinicWeeklyOff'].errors?.required">
                            This field is mandatory.</mat-error> -->
                        </div>
                      </div>
                    </div>
                   
                      </div>
                    

                    </div>
                 
                 
                    <div class="col-sm-12">
                      <div class="col-sm-12 pb-0 mb-0">
                        <h5 class="pb-0 mb-0 mt-2">Address Details :</h5>
                 
                        </div>

                    


                      <div class="col-sm-12">
                        <div class="row">
                        <div class=" col-sm-3  ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Street Address
                              <!-- <span class="required-asterisk">*</span> -->
                            </mat-label>
                            <textarea required matInput formControlName="clinicAddress"
                            cdkTextareaAutosize #autosize="cdkTextareaAutosize" maxlength="1000"
                            cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5" ></textarea>
                            <!-- <input class="form-control2" required formControlName="clinicAddress"
                             matInput placeholder="Street Address"  /> -->
                          </mat-form-field>
                          <mat-error
                          *ngIf="clinicMasterForm.controls['clinicAddress'].touched && clinicMasterForm.controls['clinicAddress'].errors?.required">
                          This field is mandatory.</mat-error>
                        </div>

                        <div class=" col-sm-3  ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Country</mat-label>
                            <input class="form-control2" matInput placeholder="Country" formControlName="country" />
                           
                          </mat-form-field>
                          <mat-error class="margin_tp"
                          *ngIf="clinicMasterForm.controls['country'].touched && clinicMasterForm.controls['country'].errors?.pattern">
                          Please enter valid country.
                        </mat-error>
                        </div>
                        <div class=" col-sm-3  ">
                          <!-- <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>State</mat-label>
                            <mat-select #select2 placeholder="State" class="colorblack" formControlName="state">
                              <mat-select-filter *ngIf="select2.focused" [placeholder]="'Filter'" [displayMember]="'name'" [array]="allState"
                                (filteredReturn)="filteredList =$event"></mat-select-filter>
                              <mat-option *ngFor="let item of filteredList" [value]="item.value">
                                {{item.name}}
                              </mat-option>
                            </mat-select>

                          </mat-form-field> -->
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>State</mat-label>
                            <mat-select required  #select11 placeholder="State" class="colorblack"  formControlName="state"   >
                              <mat-select-filter  *ngIf="select11.focused" [placeholder]="'Filter'" [displayMember]="'state'"
                                [array]="stateCityArr2"  (filteredReturn)="filteredList2 =$event"></mat-select-filter>
                              <mat-option *ngFor="let item of filteredList2" 
                               [value]="item.state" (click)="getDistrict2(item.districts)">
                                {{item.state}}
                              </mat-option>
                            </mat-select>
                          
                          </mat-form-field>
                          <mat-error
                          *ngIf="clinicMasterForm.controls['state'].touched && clinicMasterForm.controls['state'].errors?.required">
                          This field is mandatory.</mat-error>
                        </div>
                       
                        <div class=" col-sm-3  ">
                          <!-- <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>District</mat-label>
                            <input class="form-control2" matInput placeholder="District" formControlName="district" />
                           
                          </mat-form-field> -->
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>District</mat-label>
                            <mat-select   #select12 placeholder="District" class="colorblack"  formControlName="district"   >
                              <mat-select-filter  *ngIf="select12.focused" [placeholder]="'Filter'" [displayMember]="'district'"
                                [array]="districtData2" (filteredReturn)="filteredListDistrict2 =$event"></mat-select-filter>
                              <mat-option *ngFor="let item of filteredListDistrict2" 
                               [value]="item.district" >
                                {{item.district}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                          <mat-error class="margin_tp"
                          *ngIf="clinicMasterForm.controls['district'].touched && clinicMasterForm.controls['district'].errors?.pattern">
                          Please enter valid district.
                        </mat-error>
                        </div>
                        <div class=" col-sm-3  ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Taluka</mat-label>
                            <input class="form-control2" matInput placeholder="Taluka" formControlName="taluka" />
                           
                          </mat-form-field>
                          <mat-error class="margin_tp"
                          *ngIf="clinicMasterForm.controls['taluka'].touched && clinicMasterForm.controls['taluka'].errors?.pattern">
                          Please enter valid taluka.
                        </mat-error>
                        </div>
                       
                        
                          <div class=" col-sm-3   ">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Pin Code 
                                <!-- <span class="required-asterisk">*</span> -->
                              </mat-label>
                              <input class="form-control2" required formControlName="clinicPinCode"  matInput matInput maxlength="6"
                                placeholder="Pincode(e.g:416008)" />
                            </mat-form-field>
                            <mat-error
                                *ngIf="clinicMasterForm.controls['clinicPinCode'].touched && clinicMasterForm.controls['clinicPinCode'].errors?.pattern">
                                Please enter valid PinCode</mat-error>
  
                              <mat-error
                                *ngIf="clinicMasterForm.controls['clinicPinCode'].touched && clinicMasterForm.controls['clinicPinCode'].errors?.required">
                                This field is mandatory.</mat-error>
                          </div>
                     
                        </div>
                  </div>
               
                    <div class="col-sm-12">
                     
                      <div class="row">
                    
                          <div class="col-sm-12 pb-0 mb-0">
                            <h5 class="pb-0 mb-0 mt-2">Contact Details :</h5>
                      
                        </div>
                      </div>


                      <div class="row">
                        <div class=" col-sm-3  ">
                          <div class="row">
                          <div class="col-sm-10">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Mobile Number 1
                              <!-- <span class="required-asterisk">*</span> -->
                            </mat-label>
                            <input class="form-control2" required formControlName="clinicPhoneNumber" 
                             matInput  placeholder="Mobile Number" maxlength="10" />     
                          </mat-form-field>
                          <mat-error
                          *ngIf="clinicMasterForm.controls['clinicPhoneNumber'].touched && clinicMasterForm.controls['clinicPhoneNumber'].errors?.pattern">
                          Please enter valid Clinic Mobile Number</mat-error>
                        <mat-error
                          *ngIf="clinicMasterForm.controls['clinicPhoneNumber'].touched && clinicMasterForm.controls['clinicPhoneNumber'].errors?.required">
                          This field is mandatory.</mat-error>
                          </div>
                          <div class="col-sm-2 wApp">
                            <i class="bi bi-whatsapp whatsapp-icon wApp fa-lg" (click)="WhatsApp();" matTooltip="WhatsApp Integration" ></i>
                              </div>
                              </div>
                        </div>
                        <div class=" col-sm-3  ">
                          <div class="row">
                          <div class="col-sm-10">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Mobile Number 2
                              <!-- <span class="required-asterisk">*</span> -->
                            </mat-label>
                            <input class="form-control2" formControlName="clinicPhoneNumber1" matInput 
                            placeholder="Mobile Number" maxlength="10" />     
                          </mat-form-field>
                          <mat-error
                          *ngIf="clinicMasterForm.controls['clinicPhoneNumber1'].touched && clinicMasterForm.controls['clinicPhoneNumber1'].errors?.pattern">
                          Please enter valid Clinic Mobile Number</mat-error>
                     
                          </div>
                          <div class="col-sm-2 wApp">
                            <i class="bi bi-whatsapp whatsapp-icon wApp fa-lg" (click)="WhatsApp1();" matTooltip="WhatsApp Integration" ></i>
                              </div>
                              </div>
                        </div>

                        <div class=" col-sm-3  ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Clinic Landline Number
                              <!-- <span class="required-asterisk">*</span> -->
                            </mat-label>
                            <input class="form-control2"  formControlName="clinicLandline"  matInput maxlength="15" placeholder=" Landline eg 0223-666666"  />
                           </mat-form-field>
                          <mat-error
                          *ngIf="clinicMasterForm.controls['clinicLandline'].touched && clinicMasterForm.controls['clinicLandline'].errors?.pattern">
                          Please enter valid Clinic Landline Number</mat-error>

                        <!-- <mat-error
                          *ngIf="clinicMasterForm.controls['clinicPhoneNumber'].touched && clinicMasterForm.controls['clinicPhoneNumber'].errors?.required">
                          This field is mandatory.</mat-error> -->
                        </div>
                        <div class=" col-sm-3  ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Clinic Email ID
                              <!-- <span class="required-asterisk">*</span> -->
                            </mat-label>
                            <input class="form-control2" formControlName="clinicEmail" class="emailClass" matInput placeholder="Clinic Email ID" />
                
                          </mat-form-field>
                          <mat-error
                          *ngIf="clinicMasterForm.controls['clinicEmail'].touched && clinicMasterForm.controls['clinicEmail'].errors?.required">
                          This field is mandatory.</mat-error>

                        <mat-error class="margin_tp"
                        *ngIf="clinicMasterForm.controls['clinicEmail'].touched && clinicMasterForm.controls['clinicEmail'].errors?.pattern">
                        Please enter valid email.
                      </mat-error>
                        </div>
                        <div class=" col-sm-3  ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Clinic Website
                              <!-- <span class="required-asterisk">*</span> -->
                            </mat-label>
                            <input class="form-control2" formControlName="clinicWebsite" class="emailClass" matInput placeholder="Website" />
                 
                          </mat-form-field>
                          <mat-error
                          *ngIf="clinicMasterForm.controls['clinicWebsite'].touched && clinicMasterForm.controls['clinicWebsite'].errors?.required">
                          This field is mandatory.</mat-error>

                        <!-- <mat-error class="margin_tp"
                        *ngIf="clinicMasterForm.controls['clinicWebsite'].touched && clinicMasterForm.controls['clinicWebsite'].errors?.pattern">
                        Please enter valid site.
                      </mat-error> -->
                        </div>
                      </div>


                    </div>
                    <!-- <div class="col-sm-12">
                     
                      <div class="row">
                    
                          <div class="col-sm-12 pb-0 mb-0">
                            <h5 class="pb-0 mb-0 mt-2">Bank Details :</h5>
                      
                        </div>
                      </div>


                      <div class="row">
                        <div class=" col-sm-3  ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Bank Name</mat-label>
                            <input class="form-control2" formControlName="bankName" 
                            matInput placeholder="Bank Name" />
                          </mat-form-field>
                          <mat-error class="margin_tp"
                        *ngIf="clinicMasterForm.controls['bankName'].touched && clinicMasterForm.controls['bankName'].errors?.pattern">
                        Please enter valid bank Name.
                      </mat-error>
                        </div>
                        <div class=" col-sm-3  ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Branch Name</mat-label>
                            <input class="form-control2" formControlName="bankBranchName" 
                            matInput placeholder="Branch Name" />
                          </mat-form-field>
                          <mat-error class="margin_tp"
                        *ngIf="clinicMasterForm.controls['bankBranchName'].touched && clinicMasterForm.controls['bankBranchName'].errors?.pattern">
                        Please enter valid bank Name.
                      </mat-error>
                        </div>
                        <div class=" col-sm-3  ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Account Number</mat-label>
                            <input maxlength="15" class="form-control2" formControlName="accountNumber" 
                            matInput placeholder="Account Number" />
                          </mat-form-field>
                          <mat-error class="margin_tp"
                        *ngIf="clinicMasterForm.controls['accountNumber'].touched && clinicMasterForm.controls['accountNumber'].errors?.pattern">
                        Please enter valid bank Name.
                      </mat-error>
                        </div>
                        <div class=" col-sm-3  ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>IFSC Code</mat-label>
                            <input maxlength="11" class="form-control2" formControlName="ifcCode" 
                            matInput placeholder="IFC Code" />
                          </mat-form-field>
                          <mat-error class="margin_tp"
                        *ngIf="clinicMasterForm.controls['ifcCode'].touched && clinicMasterForm.controls['ifcCode'].errors?.pattern">
                        Please enter valid bank Name.
                      </mat-error>
                        </div>
                        <div class=" col-sm-3  ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>UPI Id</mat-label>
                            <input class="form-control2" formControlName="upiId" 
                            matInput placeholder="UPI Id" />
                          </mat-form-field>
                          <mat-error class="margin_tp"
                        *ngIf="clinicMasterForm.controls['upiId'].touched && clinicMasterForm.controls['upiId'].errors?.pattern">
                        Please enter valid bank Name.
                      </mat-error>
                        </div>
                      
                      </div>


                    </div> -->
                    <div class="col-sm-12">
                      <!-- <div class="row">
                        

                        <div class="col-sm-12  ">
                          <label class="header">Other Details :</label>
                        </div>
                        <div class=" col-sm-3  ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Clinic Status</mat-label>

                            <mat-select formControlName="clinicStatus">
                              <mat-option value="Active">Active</mat-option>
                              <mat-option value="Deceased">In-active</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div> -->

                      <div class="row">


                        <div class="col-sm-10" *ngIf="submitButton"></div>
                       
                        <div class="col-sm-8" *ngIf="updateButton"></div>
                        <div class="col-sm-2 mt-2" *ngIf="submitButton && clinicCreate && clinicData.length == 0">
                          <button type="button" class="btn btn-primary example-full-width btn-sm  mb-2"
                            (click)="submitClinic()">Save</button>
                        </div>
                        <div class="col-sm-2 mt-2" *ngIf="updateButton && clinicUpdate">
                          <button type="button" class="btn btn-primary example-full-width btn-sm  mb-2"
                            (click)="updateClinicDetails()">Update</button>
                        </div>
                        <div class="col-sm-2 mt-2" *ngIf="cancelButton">

                          <button class="btn btn-primary btn-sm  example-full-width  mb-2"
                            (click)="cancelUpdate()" type="reset">Cancel </button>

                        </div>
                      </div>
                    </div>

                </div>
              </div>
              </div>
            </div>
        </div>
        </div>
        <!-- <div class="row">
          <div class="col-sm-12 ">
            <div class="row">
              <div class="card divbody mb-1  boxsssss borderradius pt-3 mr-2">
                <div class="col-sm-12">
                  <div class="row">
                    <div class="col-sm-4  ">
                      <h1 class="titlecolor">List Of Clinic</h1>
                    </div>
                    <div class="col-sm-5"></div>
                    <div class="col-sm-3">
                      <div class="input-group md-form form-sm form-1 pl-0">
                        <div class="input-group-prepend">
                          <span class="input-group-text purple lighten-3" id="basic-text1"><i
                              class="fas fa-search text-white" aria-hidden="true"></i></span>
                        </div>
                        <input class="form-control2" class="form-control my-0 py-1" type="text" placeholder="Search" onkeydown="return (event.keyCode!=13);"
                          (keyup)="onKeyUpEvent($event)" formControlName="searchClinic" aria-label="Search">
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <table class="table table-hover table-striped table-bordered" style="text-align:center" id="toClinicTable">
                        <thead>

                          <tr >

                            <th>Clinic id</th>
                            <th>Clinic Name</th>
                            <th>Clinic Address</th>
                            <th>Clinic Pin Code</th>
                            <th>Clinic Phone No</th>
                            <th>Clinic Time</th>
                            <th>Weekly Of</th>
                          

                          </tr>
                        </thead>
                        <tbody>

                          <tr (click)="toClinicForm()" (click)="prepopulation(clinic._id)" *ngFor="let clinic of clinicData | paginate : { id:'clinicMaster',
                                       itemsPerPage: size, currentPage: page, totalItems:totalLength }"
                            style="cursor:pointer; ">


                            <td matTooltip="click to view">{{clinic.clinicId}}</td>
                            <td matTooltip="click to view">{{clinic.clinicName}}</td>
                            <td matTooltip="click to view">{{clinic.clinicAddress}}</td>
                            <td matTooltip="click to view">{{clinic.clinicPinCode}}</td>

                            <td matTooltip="click to view">{{clinic.clinicPhoneNumber}}</td>
                            <td matTooltip="click to view">{{clinic.openTime}} to {{clinic.closeTime}}</td>
                            <td matTooltip="click to view">{{clinic.clinicWeeklyOff}}</td>

                            
                          </tr>
                        </tbody>
                      </table>

                    </div>
                    <div class="col-sm-12 pt-0 text-right">
                      <pagination-controls id="clinicMaster" directionLinks="true" (pageChange)="onPageChange($event)"
                        class="float-right pt-3 my-pagination"></pagination-controls>

                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div> -->

      </div>
    </div>
  </form>
</div>