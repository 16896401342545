import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Router} from '@angular/router';
import { ApiServiceService } from "../api-service.service";
import { PopupServiceComponent } from "../popup-service/popup-service.component";
import { PopupAddDeviceComponent } from "../popup-add-device/popup-add-device.component";
import { PopupTotalBillComponent } from "../popup-total-bill/popup-total-bill.component";
@Component({
  selector: 'app-billing-popup',
  templateUrl: './billing-popup.component.html',
  styleUrls: ['./billing-popup.component.scss']
})
export class BillingPopupComponent implements OnInit {
  clinicRef: string;
  branchList: any; 
  patientRefId: any;
  appointId: any;
  patientDetails: any;
  pagename: any;
  withoutAppId: any;
  way: any;
    constructor(
      public dialog: MatDialog,
      public dialogRef: MatDialogRef<BillingPopupComponent>,@Inject(MAT_DIALOG_DATA) public data:any,
      public router: Router,
      public apimicroservice: ApiServiceService,) { 
        dialogRef.disableClose = true;
        this.patientRefId = data.patientRefId; 
      this.appointId = data.appointId; 
      this.patientDetails = data.patientDetails; 
      this.pagename = data.page; 
      this.way = data.way;
      }

  ngOnInit(): void {
    
    
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.fetchBranchList()
  }
  fetchBranchList() {
    this.apimicroservice.getBranchByClinicRefId(this.clinicRef).subscribe(res => {
      this.branchList = res.data.length;
    //
    
      if(res.status){
       //  this.apimicroservice.openSnackbar(res.message);
        
      }else{
        //this.apimicroservice.openSnackbar(res.message);
      }
 
    });
  
 }
 openDevicePopUp() {
  const dialogRef3 = this.dialog.open(PopupAddDeviceComponent,
    {
      // width: "1200px", 
      data: {
        patientRefId: this.patientRefId,
        appointId:this.appointId,
        patientDetails:this.patientDetails,
        way:this.way,
        page:this.pagename, 
      }
    }
    ); 

    dialogRef3.afterClosed().subscribe((result) => {
      
      if(result !== undefined){
        this.patientRefId = result.data.patientRefId;
        this.appointId = result.data.appointId;
        if(result.data.withoutAppId !== undefined){
          this.withoutAppId = result.data.withoutAppId;
          
        }else{
        
        }
        const dialogRef4 = this.dialog.open(PopupTotalBillComponent,
          { 
            data: {
              patientRefId: this.patientRefId,
              appointId:this.appointId,
              patientDetails:'',  
              withoutAppId:this.withoutAppId,
              page:result.data.page 
            }
          }
          
          ); 
      }
      this.close();
     });
}
openServicePopUp() {
  const dialogRef3 = this.dialog.open(PopupServiceComponent,
    {  
      width: "1200px", 
      // height: "400px",
        data: {
          patientRefId: this.patientRefId,
          appointId:this.appointId,
          patientDetails:this.patientDetails,
          way:this.way,
          page:this.pagename,
        }
    
    }); 

    dialogRef3.afterClosed().subscribe((result) => {
      //
      if(result !== undefined){
        this.patientRefId = result.data.patientRefId;
        this.appointId = result.data.appointId;
        //
        if(result.data.withoutAppId !== undefined){
          this.withoutAppId = result.data.withoutAppId;
          
        }else{
        
        }
        const dialogRef4 = this.dialog.open(PopupTotalBillComponent,
          { 
            data: {
              patientRefId: this.patientRefId,
              appointId:this.appointId,
              patientDetails:'', 
              withoutAppId:this.withoutAppId,
              page:"Billing Page" 
            }
          }
          
          ); 
      }
      this.close();
     });
}
  openBillingPopUp() {
      this.dialogRef.close();
      this.redirect('billing');
  }
  openAdvancePopUp() {
    this.redirect('advance-amount');
    this.dialogRef.close();
  }
  close(): void {
    this.dialogRef.close();
  }
  redirect(to): void {
    this.router.navigate([to]);
   }
   
  onClose(): void {
    this.dialogRef.close();
  }
}
