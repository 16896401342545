import { Component, Inject, OnInit } from "@angular/core";
import {FormGroup,FormBuilder,FormControl, Validators} from '@angular/forms';
import { ApiServiceService } from '../api-service.service';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner"; 
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: 'app-pop-up-expense-type',
  templateUrl: './pop-up-expense-type.component.html',
  styleUrls: ['./pop-up-expense-type.component.scss']
})
export class PopUpExpenseTypeComponent implements OnInit {
  
  popupExpenseTypeForm: FormGroup;
  page= 1;
  size= 3;
  searchText: any;
listOfExpense: any;
  totalLength: any;
  eid: any;
  submitButton = true;
  updateButton = false;
  cancelButton = false;
  userType: string;
  clinicRefId: string;
  branchRefId: string;
  
  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<PopUpExpenseTypeComponent>,@Inject(MAT_DIALOG_DATA) public data:any, 
    public apimicroservice: ApiServiceService,private spinner: NgxSpinnerService) { 
      dialogRef.disableClose = true;
    }

  ngOnInit(): void {
    this.popupExpenseTypeForm = this.fb.group({  
      "expenceType": new FormControl('',[Validators.required,Validators.pattern("^[a-zA-Z ]+$")]),
      "createdAt": new Date(),
      "searchExpenseType": new FormControl(''),
    });
    this.fetchExpenseTypeList();
    this.userType = sessionStorage.getItem('userType');
    this.clinicRefId = sessionStorage.getItem('clinicRef');
    this.branchRefId = sessionStorage.getItem('branchRef');
  }

  saveExpenseType(){
    let payload = {
      ...this.popupExpenseTypeForm.value,
      clinicRefId: this.clinicRefId,
      branchRefId: this.branchRefId
    };
    //
    this.spinner.show();
    this.apimicroservice.submitExpenseTypeData(payload).subscribe(res => {
      //
      this.spinner.hide();
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);      
        this.ngOnInit();
       this.onClose();        
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    },(ERROR:HttpErrorResponse) => {
      this.spinner.hide();
      this.apimicroservice.openSnackbar(ERROR.error.message);
  });
  }
  save() {
    if (this.popupExpenseTypeForm.invalid) {
      this.popupExpenseTypeForm.get("expenceType").markAsTouched();
      return;
    } else {
      this.saveExpenseType();
    }
  }
  
  
  onClose(): void {
    this.dialogRef.close();
  }

  onKeyUpEvent(event: any) {
    this.searchText = this.popupExpenseTypeForm.get('searchExpenseType').value;
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    let userType = sessionStorage.getItem('userType');
    if (userType !== 'Super Admin') {
    this.apimicroservice.getExpenseTypeData(this.page,this.size,this.searchText,branchRef,clinicRef).subscribe(res => {
      this.listOfExpense = res.data.data;
      this.totalLength = res.data.totalItems;
       if(res.status){
        //  this.apimicroservice.openSnackbar(res.message);    
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
    }else{
      this.apimicroservice.getExpenseTypeData(this.page,this.size,this.searchText,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
        this.listOfExpense = res.data.data;
        this.totalLength = res.data.totalItems;
         if(res.status){
          //  this.apimicroservice.openSnackbar(res.message);    
         }else{
           //this.apimicroservice.openSnackbar(res.message);
         }
       });
    }
  }


  onPageChange(event) {
    this.page = event;
   // let p = this.page - 1;
    this.searchText = this.popupExpenseTypeForm.get('searchExpenseType').value;
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    let userType = sessionStorage.getItem('userType');
    if (userType !== 'Super Admin') {
    this.apimicroservice.getExpenseTypeData(this.page,this.size,this.searchText,branchRef,clinicRef).subscribe(res => {
      this.listOfExpense = res.data.data;
      this.totalLength = res.data.totalItems;
         if(res.status){
          //  this.apimicroservice.openSnackbar(res.message);
           
         }else{
           //this.apimicroservice.openSnackbar(res.message);
         }
    
       });
      }else{
        this.apimicroservice.getExpenseTypeData(this.page,this.size,this.searchText,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
          this.listOfExpense = res.data.data;
          this.totalLength = res.data.totalItems;
             if(res.status){
              //  this.apimicroservice.openSnackbar(res.message);
               
             }else{
               //this.apimicroservice.openSnackbar(res.message);
             }
        
           });
      }
  
  }


  prepopulation(id) {
    this.apimicroservice.getExpenseById(id).subscribe(res => {
      let data = res.data[0];
      this.eid = id;
       if(res.status){
        this.submitButton = false;
         this.updateButton = true;
         this.cancelButton = true;
         this.popupExpenseTypeForm = this.fb.group({
          "expenceType" :new FormControl(data.expenceType),
          "updateddAt": new Date(),
         });
       }else{
     //    //this.apimicroservice.openSnackbar(res.message);
       }
     });
  }

  fetchExpenseTypeList() {
    let pg = 1;
    let size = 3;
    let search='';
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    let userType = sessionStorage.getItem('userType');
    if (userType !== 'Super Admin') {
        this.apimicroservice.getExpenseTypeData(pg,size,search,branchRef,clinicRef).subscribe(res => {
           this.listOfExpense = res.data.data;
           this.totalLength = res.data.totalItems;
           //this.buildingLenght = this.listofbuild.length;
          if(res.status){
           // this.apimicroservice.openSnackbar(res.message);
            
          }else{
            //this.apimicroservice.openSnackbar(res.message);
          }
     
        });   
    }else{
      this.apimicroservice.getExpenseTypeData(pg,size,search,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
        this.listOfExpense = res.data.data;
        this.totalLength = res.data.totalItems;
        //this.buildingLenght = this.listofbuild.length;
       if(res.status){
        // this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
  
     }); 
    }
  
  }

  updateExpenseType() {
    let payload = this.popupExpenseTypeForm.value;
     this.apimicroservice.updateExpenseData(payload,this.eid).subscribe(res => {
       if(res.status){
         this.apimicroservice.openSnackbar(res.message);
          this.updateButton = false;
          this.submitButton = true;
          this.ngOnInit();
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
    
  }

  cancelUpdate(){
    this.popupExpenseTypeForm.reset();
    this.submitButton=true;
    this.updateButton=false;
    this.cancelButton=false;
    this.ngOnInit();
  }
}
