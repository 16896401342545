<div class="container-fluid bgcolor2" [formGroup]="diagnosisForm">
    <form [formGroup]="diagnosisForm" autocomplete="off" novalidate>
   
         <div class="row pt-1">
          <div class="col-sm-12 pb-2">
             
  
          <div *ngIf="followupId == undefined" class="row">
            <div class="col-sm-12 ">
              <div class="row">
                <div class="card divbody  borderradius  mb-2 pt-3 mr-2 pb-0">
                  <div class="col-sm-12">
                    <div class="row">
                      <div class="col-sm-12">
                        <h1 class="titlecolor">List Of Diagnosis</h1>
                      </div>
                     
                      <!-- <div class="col-sm-1 justify-content-right">
                        <div class="row">
                            <div class="col-sm-6"></div>
                            <div class="col-sm-6 justify-content-right">
                                <div class="icon-btns pb-4"> 
                                    <a class="icon-box2" data-toggle="tooltip" data-placement="top" matTooltip="Add Follow Up"
                    (click)="openFollowUpPopup()" >
                    <span class="material-symbols-outlined">
                      fact_check
                      </span>
                    <img src="../../assets/icon-bg10.svg" class="icon-bg2">
                    </a>  
                                    </div>
                      </div>
                      </div>
                      </div>
                      <div class="search-box col-sm-3 pb-2">
                        <input class="form-control amber-border" type="text" placeholder="Search" 
                        formControlName="searchFollowup" aria-label="Search" (keyup)="onKeyUpEvent($event)"
                        >
                        <div class="input-group-append">
                           <span class="input-group-text amber lighten-3" id="basic-text1"><span class="material-symbols-outlined">search</span></span>
                        </div>
                     </div>  -->
                      
                      <div class="col-sm-12"> 
                        <div class="tab-content p-4 height scroll2 pb-3" id="pills-tabContent">
                          <div class="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="pills-home-tab">
                             <!-- <h3>Patient History</h3> -->
                             <ul class="timeline history-timeline ">
                                <li *ngFor="let datee of listOfDiagnosis" class="mb-5">
                                   <h5 class="highlighted-color">
                                {{datee.createdAt | date:'MMM d, y, - h:mm a'}}
                                   </h5>
                                   <div class="d-flex mb-2">
                                      <span class="title-text">Complaints :</span>
                                      <span>{{datee.complaints}}</span>
                                   </div>
                                   <div class="d-flex mb-2">
                                      <span class="title-text">Observations :</span>
                                      <span> {{datee.observations}}</span>
                                   </div>
                                   <div class="d-flex mb-2">
                                      <span class="title-text">Diagnoses :</span>
                                      <span> {{datee.diagnoses}}</span>
                                   </div>
                                   <div class="d-flex mb-2">
                                      <span class="title-text">Notes : </span>
                                      <span>{{datee.notes}}</span>
                                   </div>
                                   <div class="d-flex mb-2">
                                    <span class="title-text">Tracking Status : </span>
                                    <span>{{datee.trackingStatusName}}</span>
                                 </div>
                                   
                                </li>
                                <li *ngIf="listOfDiagnosis.length == 0">
                                  <h2 class="pl-4"> No Patient History </h2>
                                </li>
                             </ul>
                          </div>
                      </div> 
                      </div>
                      <!-- <div class="col-sm-12 pt-0 text-right">
                        <pagination-controls id="followup_pagination" directionLinks="true"
                          (pageChange)="onPageChange($event)" class="float-right pt-3 my-pagination">
                        </pagination-controls>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
  
            </div>
          </div>
  
        </div>
      </div>
    </form>
  </div>