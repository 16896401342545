import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from "../api-service.service";
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { element } from 'protractor';
import { ActivatedRoute } from "@angular/router";
import { MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PopupFollowupreasonComponent } from "../popup-followupreason/popup-followupreason.component";
import { PopupBookappointmentComponent } from "../popup-bookappointment/popup-bookappointment.component";
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-patient-global-search',
  templateUrl: './patient-global-search.component.html',
  styleUrls: ['./patient-global-search.component.scss']
})
export class PatientGlobalSearchComponent implements OnInit {

  repair = false;
  patient = true;
  payment = false;
  product = false;
  paientdetails: any;
  userType: string;
  clinicRef: string;
  branchRef: string;
  checkupDetailsForm: FormGroup;
  superClinicRefId: string;
  servicerateList = [];
  repairList: any;
  repairD = new Date();
  datearray = [];
  statusArr = [];
  isLinear = false;
  submitButton = true;
  updateButton = false;
  matchId: number;
  repairStatus = "";
  repairStatusArr = [];
  filteredListRepair: any;
  disabledate = true;
  repairRef: '';
  cid: any;
  followupReasonList: any;
  filteredList1: any;
  currentdate: Date;
  appointmentInfo: any;
  billingData = [];
  billingInfo = [];
  totalAmount = 0;
  historyData = [];
  aptDate: string;
  billingList = [];
  totalLength = 0;
  tempServicArr = [];
  dataInfo = [];
  tempSerInfo = [];
  allPreFix = [
    { name: 'Mr', value: 'Mr' },
    { name: 'Mrs', value: 'Mrs' },
    { name: 'Ms', value: 'Ms' },
    { name: 'Master', value: 'Master' },
    { name: 'Baby', value: 'Baby' },
    { name: 'Adv', value: 'Adv' },
    { name: 'Col', value: 'Col' },
    { name: 'Dr', value: 'Dr' },
    { name: 'Dr(Mrs)', value: 'Dr(Mrs)' },
    { name: 'Dr(Ms)', value: 'Dr(Ms)' },
    { name: 'Dr(Smt)', value: 'Dr(Smt)' },
    { name: 'M/s', value: 'M/s' },
    { name: 'Maj', value: 'Maj' },
    { name: 'Prof', value: 'Prof' },
    { name: 'Shri', value: 'Shri' },
    { name: 'Smt', value: 'Smt' },
  ]
  pId: any;
  aptId: string;
  refData: any;
  paymentData: any;
  NoOfVisits: any;
  patientData: any;
  productData: any;
  constructor(public apimicroservice: ApiServiceService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    localStorage.setItem("fromGlobal",'false');
    this.currentdate = new Date();
    this.submitButton = true;
    this.updateButton = false;
    this.userType = sessionStorage.getItem('userType');
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    // this.paientdetails = localStorage.getItem("patient");
    this.aptDate = sessionStorage.getItem("aptDate");
    this.aptId = '';
    this.superClinicRefId = sessionStorage.getItem("superClinicRefId");
    // this.superClinicRefId = this.route.snapshot.paramMap.get('pid');
    let ddd = localStorage.getItem("patient");

    this.statusArr = [];
    this.billingData = [];
    this.billingInfo = [];
    this.tempSerInfo = [];
    this.totalAmount = 0;
    //this.patienthistoryDates();
    this.checkupDetailsForm = this.fb.group({
      "complaints": new FormControl(''),
      "observations": new FormControl(''),
      "diagnoses": new FormControl(''),
      "notes": new FormControl(''),
      "followupReasons": new FormControl(''),
      "followupDate": new FormControl(''),
      "serviceRateInfo": this.fb.array([]),
      "repairNo": new FormControl(''),
      "repairDateOf": new FormControl(''),
      "repairDateto": new FormControl(''),
      "patientRefId": new FormControl(this.pId),
      "appointId": new FormControl(this.aptId),
      "searchRepairId": new FormControl(''),
      "aptStatus": new FormControl('Completed'),
      "appointDate": new FormControl(''),

    });
    this.route.queryParams.forEach(params => {
      this.pId = params.PatientId;
      //
      this.apimicroservice.patientGlobalSearch(this.pId).subscribe(res => {
        this.paientdetails = res.data[0];
        //
      });
      this.loadPatientHistory(this.pId);
     
    }); 

   this.getPatientData();
    this.getProductHistory();
    this.fetchFollowUpReasonList();
    this.getTotalRef();
    this.getpaymentHistory();
   this.totalBuisness();
   this.totalVisits();
  
 
    this.checkupDetailsForm.controls['followupDate'].setValue(this.currentdate);
    }
    getPatientData()
    {
      this.apimicroservice.getPatientById(this.pId).subscribe(res => {
        this.patientData = res.data[0]; 
      });
      
    }
  reset() {
    this.submitButton = true;
    this.updateButton = false;
    this.billingData = [];
    this.billingInfo = [];
    this.currentdate = new Date();
    this.userType = sessionStorage.getItem('userType');
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.paientdetails = localStorage.getItem("patient");
    this.superClinicRefId = sessionStorage.getItem("superClinicRefId");
    // this.superClinicRefId = this.route.snapshot.paramMap.get('pid');
    let ddd = localStorage.getItem("patient");
    this.paientdetails = JSON.parse(ddd);
    this.statusArr = [];
    this.checkupDetailsForm = this.fb.group({
      "complaints": new FormControl(''),
      "observations": new FormControl(''),
      "diagnoses": new FormControl(''),
      "notes": new FormControl(''),
      "followupReasons": new FormControl(''),
      "followupDate": new FormControl(''),
      "serviceRateInfo": this.fb.array([]),
      "repairNo": new FormControl(''),
      "repairDateOf": new FormControl(''),
      "repairDateto": new FormControl(''),
      "patientRefId": new FormControl(this.pId),
      "appointId": new FormControl(this.aptId),
      "searchRepairId": new FormControl(''),
      "aptStatus": new FormControl('Completed'),
      "appointDate": new FormControl(''),
    });
    this.fetchServiceRateList();
    this.fetchFollowUpReasonList();
    this.checkupDetailsForm.controls['followupDate'].setValue(this.currentdate);
  }
  showTracker(status) {
    let ss = "";
    ss = status;
    for (let i = 0; i < this.statusArr.length; i++) {
      if (ss.indexOf(this.statusArr[i].statusVal) > -1) {
        this.statusArr[i].status = 'completed';
        this.matchId = this.statusArr[i].id;
      }
    }
    for (let i = 0; i <= this.matchId; i++) {
      this.statusArr[i].status = 'completed';
    }
    let j = this.matchId + 1;
    this.statusArr[j].status = "active";
    //
  }

  fetchServiceRateList() {
    let id = '';
    let clinicId;
    if (this.userType !== 'Super Admin') {
      clinicId = this.clinicRef;
    } else {
      clinicId = this.superClinicRefId;
    }
    //
    //
    this.apimicroservice.getServiceRateById(id, clinicId).subscribe(res => {
      //
      let data = res.data[0];
      this.servicerateList = data.serviceRateInfo;
      if (data !== undefined) {
        data.serviceRateInfo.forEach((element, i) => {
          this.billingData.push({ "billingCompanyRefId": element.billingCompanyRefId, "billingCompanyName": element.billingCompanyName });
          this.addNew(element, i)
        });
        //
        if (this.billingData.length > 0) {
          // this.billingData.forEach((c) =>{
          //  if(!this.billingInfo.includes(c)){
          //   this.billingInfo.push(c);
          //  }
          // });

          const ids = this.billingData.map(o => o.billingCompanyRefId);
          this.billingInfo = this.billingData.filter(({ billingCompanyRefId }, index) => !ids.includes(billingCompanyRefId, index + 1));
          this.getLastIDBilling();
        }
        //
      }
      //
    });

  }

  patienthistoryDates() {

    this.apimicroservice.getPatientDateHistoryById(this.pId, this.aptId).subscribe(res => {
      //
      let ss;
      if (res.data.length > 0) {
        this.updateButton = true;
        this.submitButton = false;
        // res.data.forEach(element => {

        //   this.datearray.push(element);

        //   });
        this.datearray = res.data;
        this.checkupDetailsForm = this.fb.group({
          "complaints": new FormControl(this.datearray[0].complaints),
          "observations": new FormControl(this.datearray[0].observations),
          "diagnoses": new FormControl(this.datearray[0].diagnoses),
          "notes": new FormControl(this.datearray[0].notes),
          "followupReasons": new FormControl(this.datearray[0].followupReasons),
          "followupDate": new FormControl(this.datearray[0].followupDate),
          "serviceRateInfo": this.fb.array([]),
          "repairNo": new FormControl(),
          "repairDateOf": new FormControl(),
          "repairDateto": new FormControl(),
          "patientRefId": new FormControl(this.pId),
          "appointId": new FormControl(this.aptId),
          "searchRepairId": new FormControl(),
          "aptStatus": new FormControl(this.datearray[0].aptStatus),
          "appointDate": new FormControl(this.datearray[0].appointDate),
        });
        this.cid = this.datearray[0]._id
        let preArr = [];
        this.datearray[0].serviceRateInfo.forEach(element => {

          preArr.push(element);
          //
          this.billingData.push({ "billingCompanyRefId": element.billingCompanyRefId, "billingCompanyName": element.billingCompanyName });


          const tmpDict = {};

          tmpDict['serviceRefId'] = new FormControl(element.serviceRefId);
          tmpDict['addService'] = new FormControl(element.addService);
          tmpDict['serviceName'] = new FormControl(element.serviceName);
          tmpDict['testType'] = new FormControl(element.testType);
          tmpDict['billingCompanyRefId'] = new FormControl(element.billingCompanyRefId),
            tmpDict['billingCompanyName'] = new FormControl(element.billingCompanyName),
            tmpDict['rate'] = new FormControl(element.rate);
          tmpDict['discount'] = new FormControl(element.discount, [Validators.pattern("^[0-9]*$"), Validators.minLength(2)]);
          tmpDict['quantity'] = new FormControl(element.quantity, [Validators.pattern("^[0-9]*$"), Validators.minLength(1)]);
          tmpDict['hsnSac'] = new FormControl(element.hsnSac);
          tmpDict['sgst'] = new FormControl(element.sgst);
          tmpDict['cgst'] = new FormControl(element.cgst);
          tmpDict['igst'] = new FormControl(element.igst);
          if (element.addService == true) {

            tmpDict['billGenerate'] = new FormControl('yes');
            this.tempSerInfo.push(element);
          } else {
            tmpDict['billGenerate'] = new FormControl('no');
          }
          const creds = this.checkupDetailsForm.controls.serviceRateInfo as FormArray;
          creds.push(new FormGroup(tmpDict));

          //
        });

        const creds = this.checkupDetailsForm.controls.serviceRateInfo as FormArray;
        creds.controls.forEach(ele => {
          if (ele.get('addService').value == true || ele.get('addService').value == undefined) {

            ele.get('addService').disable();

            // ele.get('discount').disable();
            // ele.get('quantity').disable();
          } else {
            ele.get('addService').enable();
            // ele.get('discount').enable();
            // ele.get('quantity').enable();
          }
        });
        //
        this.fetchBillList();

        const ids = this.billingData.map(o => o.billingCompanyRefId);
        this.billingInfo = this.billingData.filter(({ billingCompanyRefId }, index) => !ids.includes(billingCompanyRefId, index + 1));
        //
        this.getLastIDBilling();

      } else {
        this.fetchServiceRateList();

        this.updateButton = false;
        this.submitButton = true;
      }
    });
  }

  getLastIDBilling() {
    //
    let billComp = '';
    let uniqNo: any;
    if (this.billingInfo.length > 0) {

      this.billingInfo.forEach((element, index) => {
        if(element.billingCompanyRefId !== undefined)
        {
        this.apimicroservice.getLastBillID(element.billingCompanyRefId).subscribe(res => {
          //
          // if(res['data']['data'] == '')
          // {
          //   this.inquiryForm.controls['branchWiseId'].setValue('1');
          // }
          if (res['status']) {
            billComp = element.billingCompanyName.substring(0, 3);
            uniqNo = Math.floor(1000 + Math.random() * 9000);
            let billIdd = res['data']['totalItems'];
            this.billingInfo[index]['billingId'] = billIdd + 1;
            let id = billIdd + 1;
            this.billingInfo[index]['billNo'] = billComp + "-" + uniqNo + id;
            // this.inquiryForm.controls['branchWiseId'].setValue(billIdd + 1);

          } else {
            this.apimicroservice.openSnackbar("Something went wrong");
          }

        })
      }
      });
      //
    }
  }

  //
  addNew(data, i) {
    //
    const creds = this.checkupDetailsForm.controls.serviceRateInfo as FormArray;
    creds.push(
      this.fb.group({
        "serviceRefId": new FormControl(data.serviceRefId),
        "addService": new FormControl(false),
        "serviceName": new FormControl(data.serviceName),
        "testType": new FormControl(data.testType),
        "rate": new FormControl(data.rate),
        "quantity": new FormControl(1, [Validators.pattern("^[0-9]*$"), Validators.minLength(1)]),
        "billingCompanyRefId": new FormControl(data.billingCompanyRefId),
        "billingCompanyName": new FormControl(data.billingCompanyName),
        "discount": new FormControl('', [Validators.pattern("^[0-9]*$"), Validators.minLength(2)]),
        "hsnSac": new FormControl(data.hasSac),
        "sgst": new FormControl(data.sgst),
        "cgst": new FormControl(data.cgst),
        "igst": new FormControl(data.igst),
        "billGenerate": new FormControl(''),
        toggle: false

      })
    );
    let serviceControl = (<FormArray>this.checkupDetailsForm.get("serviceRateInfo")).at(i);
    serviceControl.get('discount').disable();
  }

  submitCheckupDetails() {
    let payload = this.checkupDetailsForm.value;
    //
    if (this.checkupDetailsForm.invalid) {
      // this.checkupDetailsForm.get('complaints').markAsTouched();
      // this.checkupDetailsForm.get('observations').markAsTouched();
      // this.checkupDetailsForm.get('diagnoses').markAsTouched();  
    }
    else {
      // payload.serviceRateInfo.forEach(ele =>{
      //   if(ele.addService == true){
      //     let amt = 0;
      //     if(ele.discount !== ''){
      //       amt = parseInt(ele.rate) - parseInt(ele.discount);
      //       this.totalAmount = this.totalAmount + amt;
      //     }else{
      //       this.totalAmount = this.totalAmount + parseInt(ele.rate);
      //     }

      //   }

      // });
      // //
      this.addCheckupDetails();
    }
  }
  addCheckupDetails() {
    let payload = this.checkupDetailsForm.value;
    //
    let billingPayload = {};
    let appointPayload = {};
    appointPayload['aptStatus'] = 'Completed';
    //let billingPayload = this.paientdetails;
    let info = [];
    payload.clinicRefId = this.clinicRef;
    payload.branchRefId = this.branchRef;
    this.spinner.show();
    payload.appointDate = new Date(this.paientdetails.dateTime);
    this.apimicroservice.addCheckupDetails(payload).subscribe(res => {
      this.spinner.hide();
      //
      if (res.status) {
        this.apimicroservice.openSnackbar(res.message);
        this.apimicroservice.updateAppointment(this.paientdetails.id, appointPayload).subscribe(res => {
          if (res.status) {
            if (this.billingInfo.length > 0) {

              this.billingInfo.forEach(element => {
                billingPayload = {};
                info = [];
                let totalBill = 0;
                payload.serviceRateInfo.forEach(ele => {
                  if (element.billingCompanyRefId === ele.billingCompanyRefId && ele.addService === true) {
                    let totalRate = 0;
                    totalRate = parseInt(ele.quantity) * parseInt(ele.rate);
                    info.push({ "serviceName": ele.serviceName, "testType": ele.testType, "rate": ele.rate, "discount": ele.discount, "quantity": ele.quantity, "status": "unpaid", "totalRate": totalRate, "hsnSac": ele.hsnSac, "sgst": ele.sgst, "cgst": ele.cgst, "igst": ele.igst });
                    let amt = 0;
                    if (ele.discount !== '') {
                      //amt = parseInt(ele.rate) - parseInt(ele.discount);
                      amt = totalRate - parseInt(ele.discount);
                      totalBill = totalBill + amt;
                    } else {
                      // totalBill = totalBill + parseInt(ele.rate);
                      totalBill = totalBill + totalRate;
                    }

                    // let sgstval = 0;
                    // let cgstval = 0;
                    // let igstval = 0;
                    // if(ele.sgst !== null){
                    //   sgstval = (totalBill * ele.sgst) /100
                    // }
                    // if(ele.cgst !== null){
                    //   cgstval = (totalBill * ele.cgst) /100
                    // }
                    // if(ele.igst !== null){
                    //   igstval = (totalBill * ele.igst) /100
                    // }
                    // if(ele.sgst !== null || ele.igst !== null){
                    //   totalBill = totalBill + sgstval + cgstval;
                    // }else if(ele.igst !== null){
                    //   totalBill = totalBill + igstval;
                    // }

                  }
                });
                if (info.length > 0) {
                  billingPayload = this.paientdetails;
                  billingPayload["mobile"] = this.paientdetails.Mob;
                  billingPayload['billingCompanyRefId'] = element.billingCompanyRefId;
                  billingPayload['billingCompanyName'] = element.billingCompanyName;
                  billingPayload['billingId'] = element.billingId;
                  billingPayload['billNo'] = element.billNo;
                  billingPayload['totalAmount'] = totalBill;
                  billingPayload['serviceInfo'] = info;
                  billingPayload['paymentStatus'] = "Unpaid";
                  //
                  this.apimicroservice.addBillingDetails(billingPayload).subscribe(res => {
                    if (res.status) {
                      this.apimicroservice.openSnackbar("bill generated");
                      this.ngOnInit();
                    }
                  });
                }

              });
              // //
            }
          } else {

          }
        });

      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    });
  }

  updateCheckupDetails() {
    let payload = this.checkupDetailsForm.value;
    //
    if (this.checkupDetailsForm.invalid) {
      // this.checkupDetailsForm.get('complaints').markAsTouched();
      // this.checkupDetailsForm.get('observations').markAsTouched();
      // this.checkupDetailsForm.get('diagnoses').markAsTouched();  
    }
    else {
      this.updateCheckup();
    }
  }

  updateCheckup() {
    this.spinner.show();
    let payload = this.checkupDetailsForm.value;
    //
    let billingPayload = {};
    let billingPayloadNew = {};
    // payload.serviceRateInfo.forEach(ele =>{
    //   if(ele.billGenerate == 'no'){
    //    this.dataInfo.push(ele);
    //   }
    //  });
    //let dataInfo = [];
    //let serviceInfo2 = payload.serviceRateInfo;

    this.tempSerInfo.forEach((ele, index) => {
      if (ele.addService == true) {
        payload.serviceRateInfo[index]['addService'] = true;
      }
    });
    //

    this.dataInfo = payload.serviceRateInfo;
    //

    //let billingPayload = this.paientdetails;
    let info = [];
    let tempInfo = [];

    this.apimicroservice.updateCheckupDetails(this.cid, payload).subscribe(res => {
      //
      this.spinner.hide();

      if (res.status) {

        this.apimicroservice.openSnackbar(res.message);

        this.billingList.forEach(element => {
          billingPayload = {};
          info = [];
          let totalBill = 0;
          let totalBalance = 0;
          let totalsgstAmt = 0;
          let totalcgstAmt = 0;
          let totaligstAmt = 0;
          this.dataInfo.forEach(ele => {
            if (element.billingCompanyRefId === ele.billingCompanyRefId && ele.addService === true && ele.billGenerate == 'no') {
              let totalRate = 0;
              let sgstAmt = 0;
              let cgstAmt = 0;
              let igstAmt = 0;
              totalRate = parseInt(ele.quantity) * parseInt(ele.rate);
              info.push({ "serviceName": ele.serviceName, "testType": ele.testType, "rate": ele.rate, "discount": ele.discount, "quantity": ele.quantity, "status": "unpaid", "totalRate": totalRate, "hsnSac": ele.hsnSac, "sgst": ele.sgst, "cgst": ele.cgst, "igst": ele.igst });
              let amt = 0;
              if (ele.discount !== null) {
                //amt = parseInt(ele.rate) - parseInt(ele.discount);
                amt = totalRate - parseInt(ele.discount);
                if (ele.sgst !== null) {

                  sgstAmt = (totalRate * ele.sgst) / 100;
                  totalsgstAmt = totalsgstAmt + sgstAmt;
                  ////
                }
                if (ele.cgst !== null) {

                  cgstAmt = (totalRate * ele.cgst) / 100;
                  totalcgstAmt = totalcgstAmt + cgstAmt;
                  ////
                }
                if (ele.igst !== null) {

                  igstAmt = (totalRate * ele.igst) / 100;
                  totaligstAmt = totaligstAmt + igstAmt;
                  // //
                }
                totalBalance = totalBill + amt + totalsgstAmt + totalcgstAmt;
                totalBill = totalBill + amt;

              } else {
                // totalBill = totalBill + parseInt(ele.rate);
                if (ele.sgst !== null) {

                  sgstAmt = (totalRate * ele.sgst) / 100;
                  totalsgstAmt = totalsgstAmt + sgstAmt;
                  ////
                }
                if (ele.cgst !== null) {

                  cgstAmt = (totalRate * ele.cgst) / 100;
                  totalcgstAmt = totalcgstAmt + cgstAmt;
                  ////
                }
                if (ele.igst !== null) {

                  igstAmt = (totalRate * ele.igst) / 100;
                  totaligstAmt = totaligstAmt + igstAmt;
                  // //
                }
                totalBalance = totalBill + totalRate + totalsgstAmt + totalcgstAmt;
                totalBill = totalBill + totalRate;

              }
              if (tempInfo.length > 0) {
                //const found = tempInfo.some(o => o.billingCompanyRefId === element.billingCompanyRefId);
                let billArr = tempInfo.filter(o => o.billingCompanyRefId !== element.billingCompanyRefId);
                tempInfo = billArr;
                //
              }
            } else if (element.billingCompanyRefId !== ele.billingCompanyRefId && ele.addService === true && ele.billGenerate == 'no') {
              tempInfo.push(ele);
              //
            }
          });

          if (info.length > 0) {
            // billingPayload = this.paientdetails;
            // billingPayload['billingCompanyRefId'] = element.billingCompanyRefId;
            // billingPayload['billingCompanyName'] = element.billingCompanyName;
            // billingPayload['billingId'] = element.billingId;
            // billingPayload['billNo'] = element.billNo;
            if (element.totalAmount !== null) {
              totalBill = totalBill + element.totalAmount;
            }
            //
            //
            if (element.balanceAmount !== undefined) {
              totalBalance = totalBalance + element.balanceAmount;
              billingPayload['balanceAmount'] = totalBalance;
            }
            billingPayload['totalAmount'] = totalBill;
            element.serviceInfo.map(ele => {
              info.push(ele);
            });

            billingPayload['serviceInfo'] = info;
            billingPayload['paymentStatus'] = "Unpaid";
            //
            this.tempServicArr.push(element);

            //
            this.apimicroservice.updateBillingDetails(billingPayload, element._id).subscribe(res => {
              if (res.status) {
                this.apimicroservice.openSnackbar("bill updated");
                // this.ngOnInit();
              }
            });
          }

        });

        //
        if (this.tempServicArr.length > 0) {
          this.tempServicArr.forEach(ele => {
            let billArr = tempInfo.filter(o => o.billingCompanyRefId !== ele.billingCompanyRefId);
            tempInfo = billArr;
            //
          });
        }
        if (tempInfo.length > 0) {
          if (this.billingInfo.length > 0) {

            this.billingInfo.forEach(element => {
              billingPayloadNew = {};
              info = [];
              let totalBill = 0;
              tempInfo.forEach(ele => {
                if (element.billingCompanyRefId === ele.billingCompanyRefId && ele.addService === true && ele.billGenerate == 'no') {
                  let totalRate = 0;
                  totalRate = parseInt(ele.quantity) * parseInt(ele.rate);
                  info.push({ "serviceName": ele.serviceName, "testType": ele.testType, "rate": ele.rate, "discount": ele.discount, "quantity": ele.quantity, "status": "unpaid", "totalRate": totalRate, "hsnSac": ele.hsnSac, "sgst": ele.sgst, "cgst": ele.cgst, "igst": ele.igst });
                  let amt = 0;
                  if (ele.discount !== null) {
                    //amt = parseInt(ele.rate) - parseInt(ele.discount);
                    amt = totalRate - parseInt(ele.discount);
                    totalBill = totalBill + amt;
                  } else {
                    // totalBill = totalBill + parseInt(ele.rate);
                    totalBill = totalBill + totalRate;
                  }
                }
              });
              if (info.length > 0) {
                billingPayloadNew = this.paientdetails;
                //
                billingPayloadNew["mobile"] = this.paientdetails.Mob;
                billingPayloadNew['billingCompanyRefId'] = element.billingCompanyRefId;
                billingPayloadNew['billingCompanyName'] = element.billingCompanyName;
                billingPayloadNew['billingId'] = element.billingId;
                billingPayloadNew['billNo'] = element.billNo;
                billingPayloadNew['totalAmount'] = totalBill;
                billingPayloadNew['serviceInfo'] = info;
                billingPayloadNew['paymentStatus'] = "Unpaid";
                //
                this.apimicroservice.addBillingDetails(billingPayloadNew).subscribe(res => {
                  if (res.status) {
                    this.apimicroservice.openSnackbar("bill generated");
                    // this.ngOnInit();

                  }
                });
              }

            });
            // //
          }

        }
        this.ngOnInit();
      } else {
        //  //this.apimicroservice.openSnackbar(res.message);
      }
    });
  }

  fetchRepairList() {
    let id = '';
    // this.statusArr.forEach(element =>{
    //   this.repairStatusArr.push(element);
    // })
    //
    this.apimicroservice.getRepairDataByPatientId(this.pId).subscribe(res => {

      if (res.data.length != 0) {
        this.repairList = res.data;
        this.filteredListRepair = this.repairList.slice();
        this.apimicroservice.openSnackbar(res.message);
        if (res.data.length == 0) {
          this.statusArr = [];
        } else {
          this.onSelectRepairNo(this.repairList[0]._id);
        }

      } else {
        // this.statusArr = [];
        this.repairList = [];
        //this.apimicroservice.openSnackbar(res.message);
      }
    });
  }

  selectService(event: any, i) {
    //
    let serviceControl = (<FormArray>this.checkupDetailsForm.get("serviceRateInfo")).at(i);

    let serviceVal = serviceControl.get("addService").value;

    if (serviceVal == true) {
      serviceControl.get('discount').enable();

    } else {
      serviceControl.get('discount').disable();

    }

  }

  onSelectRepairNo(id) {
    this.repairRef = id;
    this.disabledate = false;
    // let id = event.target.value;
    // id = parseInt(id);
    this.apimicroservice.getRepairStatusFlowById(id).subscribe(res => {
      if (res.data.length > 0) {
        if (res.data[0].stepperInfo.length > 0) {
          this.statusArr = res.data[0].stepperInfo;
          //)
          this.checkupDetailsForm.controls['repairNo'].setValue(res.data[0].repairRefId);
          //this.stepperRefId =  res.data[0]._id;
          //this.norecord = false;
        }
      } else {
        this.statusArr = [];
      }
    })
    // this.apimicroservice.getRepairStatusFlowById(id).subscribe(res => {
    //   //
    //       if(res.data.length > 0){
    //         if(res.data[0].stepperInfo.length > 0){
    //           this.statusArr = res.data[0].stepperInfo;
    //           //this.stepperRefId =  res.data[0]._id;
    //           //this.norecord = false;
    //         }
    //       }else{
    //         this.statusArr = [];
    //       } 
    //     })
  }

  onSelectRepairDate() {
    let payload = this.checkupDetailsForm.value;
    this.apimicroservice.getRepairStatusFlowByRepairDate('', payload.repairDateOf, payload.repairDateto).subscribe(res => {
      if (res.data.length > 0) {
        this.repairList = [];
        res.data.forEach(element => {
          element['_id'] = element.repairRefId
        });
        this.repairList = res.data;
      } else {
        this.statusArr = [];
        this.repairList = [];
      }
    })
  }

  onSelectDiscount(event, i) {
    let dis = event.target.value;
    let serviceControl = (<FormArray>this.checkupDetailsForm.get("serviceRateInfo")).at(i);
    let rateVal = serviceControl.get("rate").value;

    if (parseInt(dis) > parseInt(rateVal)) {
      this.apimicroservice.openSnackbar("Please enter discount value less than or equal to rate value");
      serviceControl.patchValue({
        discount: '',
      });
    }
  }

  chnagetable(e) {
    if (e == "patient") {
      this.patient = true;
      this.repair = false;
      this.payment = false;
      this.product = false;
    } else if (e == "repair") {
      this.patient = false;
      this.payment = false;
      this.product = false;
      this.repair = true;
      this.fetchRepairList();
    } else if (e == "payment") {
      this.patient = false;
      this.repair = false;
      this.product = false;
      this.payment = true;
    } else if (e == "product") {
      this.patient = false;
      this.repair = false;
      this.payment = false;
      this.product = true;
    }
  }

  cancelUpdate() {
    // this.submitButton=true;
    // this.updateButton=false;
    this.ngOnInit();
    // this.checkupDetailsForm.reset();
  }

  fetchFollowUpReasonList() {
    this.apimicroservice.getfollowupReasonList().subscribe(res => {
      //
      if (res.status) {
        this.followupReasonList = res.data;
        this.filteredList1 = this.followupReasonList.slice();
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    })
  }

  openBookAppointmentPopup() {
    this.appointmentInfo = this.paientdetails;
    let followDate = this.checkupDetailsForm.get('followupDate').value;
    this.appointmentInfo.followupDate = followDate;
    //
    const dialogRef3 = this.dialog.open(PopupBookappointmentComponent,
      {
        width: "500px",
        data: {
          patientInfo: this.appointmentInfo
        }
      });

    dialogRef3.afterClosed().subscribe((result) => {
      let msg = "";
      if (result !== undefined) {
        msg = result.data;
      }

      if (msg == "saved") {
        this.apimicroservice.openSnackbar("Follow-Up Appointment is Booked");
      }

    });
  }

  openFollowUpReasonPopUp() {

    const dialogRef3 = this.dialog.open(PopupFollowupreasonComponent,
      {
        width: "500px",
      });

    dialogRef3.afterClosed().subscribe((result) => {
      this.fetchFollowUpReasonList();
    });
  }

  openSnackbar(message: string) {
    return this.snackBar.open(message, "close",
      { verticalPosition: 'bottom', horizontalPosition: 'right', duration: 3000, panelClass: ['snackbarStyle'] });
  }

  fetchBillList() {
    let search = '';
    this.apimicroservice.getBillingDataByPatientId(this.pId, this.aptId).subscribe(res => {
      //
      if (res.status) {
        this.billingList = res.data;
        this.totalLength = res.data.totalItems;

      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    });
  }
  loadPatientHistory(id) {
    let appId = '';
    let pid = id;
    this.apimicroservice.getPatientDateHistoryById(pid,appId).subscribe(res => {
      this.historyData = res.data;
    });
  }
  getpaymentHistory(){
    this.apimicroservice.getPaymentHistoryData(this.pId).subscribe(res => {
      if(res.status){
        this.paymentData = res.data;
  
        //
     
      }
    });
  }
  totalVisits(){
    this.apimicroservice.getTotalVisits(this.pId).subscribe(res => {
      if(res.status){
        this.NoOfVisits = res.data;
  
        //
     
      }
    });
  }
  
  totalBuisness(){
    this.apimicroservice.getTotalBuisness(this.pId).subscribe(res => {
      this.totalAmount = res.data[0].totalPayment;
  
  //
    });
  }
  getTotalRef() {
    this.apimicroservice.getTotalRefreance(this.pId).subscribe(res => {
      this.refData = res.data;
    });
  }
  getProductHistory(){
    this.apimicroservice.getPatientDevice(this.pId).subscribe(res => {    
      if(res.status){
        this.productData = res.data;   
      }
    });
  }
}
