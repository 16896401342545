export const MY_DATE_FORMATS = {
    parse: {
      dateInput: 'DD-MM-YYYY',
    },
    display: {
      dateInput: 'DD/MM/YYYY',
    //   monthYearLabel: 'MMMM YYYY',
    //   dateA11yLabel: 'LL',
    //   monthYearA11yLabel: 'MMMM YYYY'
    },
};