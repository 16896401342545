<div class="container-fluid"  [formGroup]="repairPrintForm">
    <form [formGroup]="repairPrintForm" autocomplete="off" novalidate>
      <div class="row ">
          <div class="col-sm-12 ">
           
                <div class="col-sm-12">
                   
                  <div class="row">
                    <div class="col-sm-11" ></div>
                    <div class="col-sm-1" > 
                    
                      <div class="row no-print justify-content-end ">
                        <a (click)="printThisPage()" class="d-flex justify-content-end align-items-end ms-3">
                          <span class="material-symbols-outlined pr-2">print</span>Print</a>
                     </div>
                     </div>
                     <div class="col-sm-12">
                      <div class="row">
                        <table id="toBillingLst"
                        class="table table-hover mb-0 pb-0 table-bordered">
                                
                              
                        <tbody >
                        
                          <tr role="row"
                           class="odd tw">
                            
                           <td class=" mt-0 pt-0 mb-0 pt-0">  
                            <div class="row w2 pt-2  mt-0 pt-0 mb-0 pt-0">
                              <div class="col-sm-12  mt-0 pt-0 mb-0 pb-0"
                              >
                                <img *ngIf="!logoimg" class="img2 pt-0 mb-0 pb-0"
                                width="100%" height="80%"
                            src="../../assets/clinic_logoDemo.png" alt="Amanda Wilson" >
                            <img *ngIf="logoimg" width="80%" height="100%"
                           [src]="logoimg" alt=" photo" class="img2 pb-0 pt-0 mb-0 ">
                            </div>
                            </div>
                          </td>
                          <td class="pr-0  mr-2 pl-0 ml-0 text-center justify-content-center pt-1 pb-0 ">
                            <div class="row w1">
                              <div class="col-sm-12  mb-0 pb-0">
                                <div class="row">
                                  <div class="col-sm-12">
                            <strong class="auto-style2 fass mb-0 pb-0 mt-0 pt-0">
                                <h1 class="fsize" *ngIf="reportName !== ''">{{reportName}} </h1>
                                <h1 class="fsize" *ngIf="reportName == ''">Clinic Name</h1>
                             </strong>
                             </div>
                             </div>
                             </div>

                              <div class="col-sm-12  mt-0 pt-0">
                                <div class="row">
                                  <div class="col-sm-12">
       
                                    <label class="col-sm-12" *ngIf="reportName !== ''">
                                      {{reportAddress}}, {{taluka}} , {{district}}, {{state}},</label>
                                      <label class="col-sm-12 mt-1" *ngIf="reportName !== ''">
                                      {{country}} - {{reportPinCode}}</label>
                                  </div>
                            </div> 
                            </div>

                            <div class="col-sm-12 mt-2 text-center justify-content-center">
                              <div class="row">
                                <div class="col-sm-12  pb-2">
                              <label *ngIf="reportName !== ''">Mobile No :
                                <b>
                                  {{reportPhoneNumber | slice:0:2}} {{reportPhoneNumber | slice:2:4}} {{reportPhoneNumber | slice:4:7}} {{reportPhoneNumber | slice:7:10}},
                                  {{reportPhoneNumber1 | slice:0:2}} {{reportPhoneNumber1 | slice:2:4}} {{reportPhoneNumber1 | slice:4:7}} {{reportPhoneNumber1 | slice:7:10}},
                                </b>
                              
                              </label>
                              </div>
                            <div class="col-sm-12 pb-2 text-center justify-content-center">
                            <label class="trans">Email Id :{{emailAdd}}</label> 
                            </div>
                            <div class="col-sm-12 pb-2 text-center justify-content-center">
                              <label class="trans">Website :
                                {{website}} </label> 
                              </div>
                          </div>
                          </div>
                          </div>
                              </td>
                               
                                <td class="w2 pt-1 pb-0 text-center
                                justify-content-center">
                              
                                   <div class="row marginTopp">
                                     <!-- <div class="col-sm-12 pb-2">
                                      <strong>
                                GST Number :{{companyGst}}
                                </strong>
                                </div>
                                 <br> -->
                                 <div class="col-sm-12 pb-2">
                                 <strong> Weekly Off : {{reportWeeklyOff}}</strong>
                                 </div>
                                 <br>
                                 <div class="col-sm-12">
                                  <strong class="labelMin" *ngIf="openTime !== '' &&
                                  closeTime !== ''">From<br> {{openTime}}<br> To<br> {{closeTime}}</strong> 
                                  </div>
                              </div>
                               </td> 
                      
                             </tr>
                             
                      </tbody> 
                        </table>
                                        <div class="col-sm-12 mt-2 mb-0 pt-0 pb-0">
                                          <h1 class="text-center mb-1 pt-0 pb-0">Hearing Aids For Repair</h1></div>
                                          <table id="toBillingLst"
                                          class="table table-hover mb-3 pt-0 pb-0 table-bordered">
                                 <thead class="bcol">
                                     <th class="pt-1 bcol pb-0">
                                      <label>Name :</label>
                                      </th>
                                     <th colspan="4" class="fontSize bcol pt-1 pb-0">
                                      <label *ngIf="prifix !== '' && prifix !== null
                                      && prifix !== undefined; else elseBlock1">{{prifix}}. {{firstName}} {{lastName}}</label>
                                     <ng-template #elseBlock1>
                                       <label class="fontSize">{{firstName}} {{lastName}} </label>
                                     </ng-template>  
                                  </th>
                                      <th class="pt-1 bcol pb-0">
                                        <label>Date : {{currentdate | date: 'dd/MM/yyyy'}}</label>
                                        </th>
                                  </thead> 
                                  <thead  class="bcol"> 
                                   <th class="pt-1 bcol pb-0">
                                    <label>Address :</label></th>

                                    <th colspan="4"  class="width1 bcol pt-1 pb-0">
                                      <label>
                                        {{streetAddress1}} {{taluka1}} {{district1}} {{state1}} 
                                        {{country1}} {{pincode1}}
                                        </label>
                                    </th>
                                    <th class="pt-1 bcol pb-0">
                                      <label>Time : {{currentdate | date: 'h:mm a'}}</label>
                                      </th>
                                    <!-- <th class="width1 pt-1 bcol pb-0">Date</th>
                                    <th class="width1 pt-1 bcol pb-0">{{invoiceDate | date: 'dd/MM/yyyy'}}</th> -->
                                </thead>
                              
                              </table>
                                   
                                          <table id="toBillingLst"
                                          class="table table-hover table-bordered">
                                            <thead>
                                              <tr class="text-center">
                                                  <th class=" pt-2 pb-2">Hearing Aid Company Name</th>
                                                  <th class=" pt-2 pb-2">CAN Number</th>
                                                  <th class=" pt-2 pb-2">Make</th>
                                              </tr>
                                          </thead> 
                                          <tbody >
                                            <tr 
                                            class="text-center">
                                             <td class="pt-1 pb-0">{{companyName}}</td>     
                                              <td class="width1 pt-1 pb-0">{{companyCanNumber}}</td>
                                              <td class="width1 pt-1 pb-0">{{companyMake}}</td>
                                               </tr>     
                                        </tbody>
                                          </table>
                                          <table id="toBillingLst"
                                          class="table table-hover table-bordered">
                                            <thead>
                                              <tr class="text-center">
                                                  <th colspan="2" class=" pt-1 pb-1">Right</th>
                                                  <th colspan="2" class=" pt-1 pb-1">Left</th>
                                             </tr>
                                          </thead> 
                                          <tbody >
                                            <tr
                                            class="text-left">
                                             <td class="pt-1 pb-0 boldd">Model Name</td>      
                                           <td class="width1 pt-1 pb-0">
                                            <label *ngIf="rightModel !== '' && rightModel !== null
                                            && rightModel !== undefined; else elseBlock">{{rightModel}}</label>
                                          
                                           </td>
                                           <td class="width1 pt-1 pb-0 boldd">Model Name</td>
                                           <td class="width1 pt-1 pb-0">
                                            <label *ngIf="leftModel !== '' && leftModel !== null
                                            && leftModel !== undefined; else elseBlock">{{leftModel}}</label>
                                        
                                           </td> 
                                     
                                               </tr>   
                                               <tr
                                               class="text-left">
                                                <td class="pt-1 pb-0 boldd">HA Serial Number</td> 
                                                <td class="width1 pt-1 pb-0">
                                                  <label *ngIf="rightSrNo !== '' && rightSrNo !== null
                                                  && rightSrNo !== undefined; else elseBlock">{{rightSrNo}}</label>
                                                 <ng-template #elseBlock>
                                                   <label>-</label>
                                                 </ng-template> 
                                                 </td>
                                             
                                              <td class="width1 pt-1 pb-0 boldd">HA Serial Number</td>
                                              <td class="width1 pt-1 pb-0">
                                                <label *ngIf="leftSrNo !== '' && leftSrNo !== null
                                                && leftSrNo !== undefined; else elseBlock">{{leftSrNo}}</label>
                                             
                                               </td>
                                      
                                                  </tr>   
                                        </tbody>
                                          </table>
                                          <div *ngIf="rightArr.length > 0" class="col-sm-6 pl-1 pr-1">
                                          <table id="toBillingLst"
                                          class="table table-hover col-sm-6 table-bordered">
                                            <thead>
                                              <tr class="text-center">
                                                  <th colspan="3" class=" pt-1 pb-1">Right Fault</th>
                                                  
                                             </tr>
                                          </thead> 
                                      
                                          <tbody >
                                            <tr class="text-left"
                                            *ngFor=" 
                                              let item of rightArr" 
                                             style="cursor:pointer; text-align: center " >
                                             <td class="pt-1 pb-0 ">
                                              <label *ngIf="item.value !== '' && item.value !== null
                                      && item.value !== undefined; else elseBlock">{{item.value}}</label>
                                     </td>     
                                          </tr>   
                                         </tbody>
                                          </table>
                                          </div>
                                          <div *ngIf="leftArr.length > 0" class="col-sm-6 pr-1 pl-1">
                                          <table id="toBillingLst"
                                          class="table table-hover col-sm-6 table-bordered">
                                            <thead>
                                              <tr class="text-center">
                                                  <th colspan="3" class=" pt-1 pb-1">
                                                    Left Fault</th>   
                                             </tr>
                                          </thead> 
                                        
                                          <tbody >
                                            <tr class="text-left"
                                            *ngFor=" 
                                              let item of leftArr"  
                                             style="cursor:pointer; text-align: center " >
                            
                                             <td class="pt-1 pb-0 ">
                                              <label *ngIf="item.value !== '' && item.value !== null
                                              && item.value !== undefined && item.value !== '-'; else elseBlock">{{item.value}}</label></td>  

                                          </tr>   
                                         </tbody>
                                          </table>
                                          </div>
                                          <div *ngIf="rightPhysicalCondition.length > 0" class="col-sm-6 pl-1 pr-1">
                                            <table id="toBillingLst"
                                            class="table table-hover col-sm-6 table-bordered">
                                              <thead>
                                                <tr class="text-center">
                                                    <th colspan="3" class=" pt-1 pb-1">Right Physical Condition</th>   
                                               </tr>
                                            </thead> 
                                          
                                            <tbody >
                                              <tr class="text-left"
                                              *ngFor=" 
                                                let item of rightPhysicalCondition" 
                                               style="cursor:pointer; text-align: center " >
                                      
                                               <td class="pt-1 pb-0 ">
                                                <label *ngIf="item.value !== '' && item.value !== null
                                                && item.value !== undefined; else elseBlock">{{item.value}}</label></td>    
                                            </tr>   
                                           </tbody>
                                            </table>
                                            </div>
                                          <div *ngIf="leftPhysicalCondition.length > 0" class="col-sm-6 pl-1 pr-1">
                                            <table id="toBillingLst"
                                            class="table table-hover col-sm-6 table-bordered">
                                              <thead>
                                                <tr class="text-center">
                                                    <th colspan="3" class=" pt-1 pb-1">Left Physical Condition</th>   
                                               </tr>
                                            </thead> 
                                          
                                            <tbody >
                                              <tr class="text-left"
                                              *ngFor=" 
                                                let item of leftPhysicalCondition" 
                                               style="cursor:pointer; text-align: center " >
                                               <td class="pt-1 pb-0 ">
                                                  <label *ngIf="item.value !== '' && item.value !== null
                                                  && item.value !== undefined; else elseBlock">{{item.value}}</label>
                                               </td>  
                                                  
                                            </tr>   
                                           </tbody>
                                            </table>
                                            </div>
                                           
                                          <table id="toBillingLst"
                                          class="table table-hover table-bordered">
                                            <thead>
                                              <tr>
                                                <th class="text-left pt-1 pb-1">Others</th>
                                                  <th class="text-center pt-1 pb-1">Right</th>
                                                  <th class="text-center pt-1 pb-1">Left</th>
                                             </tr>
                                          </thead> 
                                          <tbody >
                                            <tr
                                          >
                                             <td class="pt-1 text-left pb-0 boldd">Warranty</td>   
                                             <td class="width1 text-center pt-1 pb-0">

                                             <label *ngIf="rightWarranty !== '' && rightWarranty !== null
                                             && rightWarranty !== undefined; else elseBlock">{{rightWarranty}}</label>

                                           
                                            </td>
                                            <td class="width1 text-center pt-1 pb-0">
                                              <label *ngIf="leftWarranty !== '' && leftWarranty !== null
                                              && leftWarranty !== undefined; else elseBlock">{{leftWarranty}}</label>
                                          
                                             </td>

                                               </tr>   
                                               <tr>
                                                <td class="pt-1 text-left pb-0 boldd">if Yes, Date Of Purchase</td>    
                                                <td class="width1 text-center pt-1 pb-0">
                                                  <label *ngIf="rightDateOfPurchase !== '' && rightDateOfPurchase !== null
                                                  && rightDateOfPurchase !== undefined; else elseBlock">{{rightDateOfPurchase | date:"dd/MM/yyyy"}}</label>
                                               
                                                 </td>
                                                 <td class="width1 text-center pt-1 pb-0">
                                                  <label *ngIf="leftDateOfPurchase !== '' && leftDateOfPurchase !== null
                                                  && leftDateOfPurchase !== undefined; else elseBlock">{{leftDateOfPurchase | date:"dd/MM/yyyy"}}</label>
                                              
                                                 </td>

          
                                                  </tr>  
                                                  <tr>
                                                    <td class="pt-1 text-left pb-0 boldd">Repair Quotation</td>    
                                                    <td class="width1 text-center pt-1 pb-0">
                                                      <label *ngIf="rightRepairQuotation !== '' && rightRepairQuotation !== null
                                                      && rightRepairQuotation !== undefined; else elseBlock">{{rightRepairQuotation}}</label>
                                                  
                                                     </td>
                                                     <td class="width1 text-center pt-1 pb-0">
                                                      <label *ngIf="leftRepairQuotation !== '' && leftRepairQuotation !== null
                                                      && leftRepairQuotation !== undefined; else elseBlock">{{leftRepairQuotation}}</label>
                                                
                                                     </td>

                                                      </tr>  
                                                      <tr>
                                                        <td class="pt-1 text-left pb-0 boldd">Accessories included</td>    
                                                        <td class="width1 text-center pt-1 pb-0">
                                                          <label *ngIf="rightAcessoriesIncluded !== '' && rightAcessoriesIncluded !== null
                                                          && rightAcessoriesIncluded !== undefined; else elseBlock">{{rightAcessoriesIncluded}}</label>
                                                       
                                                         </td>  
                                                         <td class="width1 text-center pt-1 pb-0">
                                                          <label *ngIf="leftAcessoriesIncluded !== '' && leftAcessoriesIncluded !== null
                                                          && leftAcessoriesIncluded !== undefined; else elseBlock">{{leftAcessoriesIncluded}}</label>
                                                
                                                         </td> 
                                               
                                                          </tr>   
                                                          <tr>
                                                            <td class="pt-1 text-left pb-0 boldd">Past Repair Ref (optional)</td> 
                                                            <td class="width1 text-center pt-1 pb-0">
                                                              <label *ngIf="rightPastRepairRef !== '' && rightPastRepairRef !== null
                                                              && rightPastRepairRef !== undefined; else elseBlock">{{rightPastRepairRef}}</label>
                                                        
                                                             </td>     
                                                             <td class="width1 text-center pt-1 pb-0">
                                                              <label *ngIf="leftPastRepairRef !== '' && leftPastRepairRef !== null
                                                              && leftPastRepairRef !== undefined; else elseBlock">{{leftPastRepairRef}}</label>
                                                            
                                                             </td>  
                                                     
                                                              </tr>          
                                                              <tr >
                                                                <td class="pt-1 pb-0 text-left boldd">Other Remarks</td>   
                                                                <td class="width1 text-center pt-1 pb-0">
                                                                  <label *ngIf="rightOtherRemark !== '' && rightOtherRemark !== null
                                                                  && rightOtherRemark !== undefined; else elseBlock">{{rightOtherRemark}}</label>
                                                            
                                                                 </td>    
                                                                 <td class="width1 text-center pt-1 pb-0">
                                                                  <label *ngIf="leftOtherRemark !== '' && leftOtherRemark !== null
                                                                  && leftOtherRemark !== undefined; else elseBlock">{{leftOtherRemark}}</label>
                                                          
                                                                 </td>
                                                           
                                                                  </tr>
                                        </tbody>
                                          </table>
                                          
                                          <div class="col-sm-12 pl-4 mt-3 labelClass">
                                            <br>
                                          <label>श्रवणयंत्र दुरुस्तीला देताना.....   </label><br> <br>
                                          <label class="mt-2">  १) श्रवणयंत्र वापरणाऱ्यांच्या जबाबदारीवरच आम्ही नवी, जुनी श्रवणयंत्रे दुरुस्त करतो.</label><br>  
                                          <label class="mt-3">  २) श्रवणयंत्रे दुरुस्तीला देताना त्याचे मोल्ड, रिसीव्हर, कॉर्ड, बॅटरी इ.सुटे भाग परत दिले जातात.</label><br> 
                                          <label class="mt-3">  ३) दुरुस्तीच्या खर्चाचा अंदाज आपणांस दिला जाईल व आपली संमती घेतल्यावरच आपले श्रवणयंत्र दुरूस्त केले जाईल.</label><br> 
                                          <label class="mt-3">  ४) बदललेल्या पार्टसूची वॉरंटी ३ महिने राहील.</label><br> 
                                          <label class="mt-3">  ५) श्रवणयंत्र दुरूस्त झाल्यापासून २ महिन्यांत न नेल्यास व्यवस्थापनाची जबाबदारी राहणार नाही.</label><br> 
                                          <label class="mt-3">  ६) दुरुस्त झालेले श्रवणयंत्र परत नेताना कृपया ही पावती आणावी अन्यथा यंत्र मिळणार नाही.</label><br> 
                                          <label class="mt-3">  ७) श्रवणयंत्र दुरूस्तीला वेळ लागल्यास व्यवस्थापन जबाबदार राहणार नाही. त्यासंबंधी कंपनीशी आम्ही सतत संपर्कात असतो.</label><br> 
                                          <label class="mt-3">  ८) श्रवणयंत्र दुरूस्तीनंतर आलेला खर्चदेणे आवश्यक राहील.</label><br> 
                                    
                                          </div> 
                                          <div class="col-sm-12 mt-5">
                                            <div class="row">
                                            <div class="col-sm-2">
                                              <img  src='{{ signatureImg }}' />
                                    </div>
                                    </div>
                                            </div>
                                            <div class="col-sm-12">
                                              <div class="row">
                                              <div class="col-sm-2">
                                                <h2 class="mt-5 text-center">Clients Signature</h2>
                                      </div>
                                      <div class="col-sm-8"></div>
                                      <div class="col-sm-2">
                                        <h2 class="mt-5 ml-4">Signature</h2>
                              </div>
                                      </div>
                                              </div>



                                          <!-- <div class="col-sm-1"></div>
                                          <div class="col-sm-3 mt-5">
                                            <br><br><br>  <br><br><br>
                                            
                                        
                                           <label class="mt-5 text-right"><b class="mt-5 text-center">Clients Signature</b></label>
                                          
                                          </div>
                                          <div class="col-sm-6"></div>
                                          <div class="col-sm-2 mt-5">
                                            <br><br><br>  <br><br><br>
                                          
                                        
                                            <label class="mt-5 text-right"><b class="mt-5 text-center">Signature</b></label>

                                  
                                       
                                          </div> -->
                                            
                                          </div>
                                       </div>
                      </div>
  
                  </div>
              
  
          </div>
      </div>
      </form>
  </div>