<app-header *ngIf="!router.url.includes('login') && !router.url.includes('forgot-password') 
&& !router.url.includes('print-bill') && !router.url.includes('receipt') 
&& !router.url.includes('repair-print') && !router.url.includes('custom-ha-print') 
&& !router.url.includes('ear-mould-print') && !router.url.includes('ha-form-print')
&& !router.url.includes('place-order-print')"></app-header>

<app-side-navbar *ngIf="!router.url.includes('login') && !router.url.includes('forgot-password') && 
!router.url.includes('print-bill') && !router.url.includes('receipt') && !router.url.includes('repair-print')
&& !router.url.includes('custom-ha-print') && !router.url.includes('ear-mould-print')
&& !router.url.includes('ha-form-print') && !router.url.includes('place-order-print')"></app-side-navbar>

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "square-spin" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>
<router-outlet></router-outlet>
