<div class="container-fluid " [formGroup]="paymentDashboardForm">
    <form [formGroup]="paymentDashboardForm" autocomplete="off" novalidate>
        <div class="row">
   
            <div class="col-sm-12">
               
              <div class="row pt-1"> 
                <div class="col-sm-12 pb-2">
                    <div class="row margingTop pb-2">

                        <div class="card divbody boxsssss borderradius pr-3 pb-2 p-1 " >
                            <div class="col-sm-12 mt-3">
                                  <div class="row">
                                      <div class="col-sm-8 "> 
                                          <h1 class="titlecolor">Billing List</h1>
                                      </div> 
                                      <div class="col-sm-1 justify-content-right">
                                        <div class="row">
                                            <div class="col-sm-6"></div>
                                            <div class="col-sm-6 justify-content-right">
                                                <div class="icon-btns pb-4"  (click)="billing()"
                                                >
                                                   
                                                    <a class="icon-box2" data-toggle="tooltip" data-placement="top" 
                                         matTooltip="Billing">
                                        <span class="material-symbols-outlined">receipt_long</span>
                                        <img src="../../assets/icon-bg5.svg" class="icon-bg2">
                                        </a>
                                                    </div>
                                      </div>
                                      </div>
                                      </div>
                                    
                                      <div class="search-box col-sm-3 pb-2">
                                        <input class="form-control amber-border" type="text" placeholder="Search" 
                                        formControlName="searchBilling" aria-label="Search" (keyup)="onKeyUpEvent($event)"
                                        >
                                        <div class="input-group-append">
                                           <span class="input-group-text amber lighten-3" id="basic-text1"><span class="material-symbols-outlined">search</span></span>
                                        </div>
                                     </div>
                                     
                                       <div class="col-sm-12">
                                        <table id="toBillingLst" 
                                        class="table table-bordered data-table-custom">
                                              <thead>
                                                  <tr class="text-center">
                                                      <th>Sr.No.</th>
                                                       <th>Date</th>
                                                       <th>Bill No</th>
                                                       <th>Billing Company Name</th>
                                                       <th>Branch Name</th>
                                                       <!-- <th>Patient Id</th>
                                                      <th>Patient Name</th>
                                                      <th>Mobile</th> -->
                                                      <th>Status</th>
                                                  </tr>
                                              </thead>  
                                              <tbody> 
                                                  <tr *ngFor="let item of billingList | paginate : { id:'paymentDashboardFormMaster',
                                                  itemsPerPage: size, currentPage: page, totalItems:totalLength } let i = index" 
                                                   (click)="topaymentDashboardForm()" 
                                              
                                                   style="cursor:pointer; text-align: center ">
                                                   <td matTooltip="Click To View">{{i+1}}</td>
                                                   <td  matTooltip="Click To View" 
                                                   >{{item.createdAt | date:'dd/MM/yyyy'}}
                                                 </td>
                                                 <td (click)="redirectToBillForm(item.billNo)" 
                                                 matTooltip="Click To View" class="repairtb">{{item.billNo}}</td>
                                                 <td matTooltip="Click To View" >{{item.billingCompanyName}}</td>
                                                 <td matTooltip="Click To View" >{{item.branchName}}</td>
                                                 <!-- <td matTooltip="Click To View" >{{item.patientId}}</td>
                                                 <td matTooltip="Click To View" >{{item.patientName}}</td>
                                                 <td matTooltip="Click To View" >{{item.mobile}}</td> -->
                                               <td *ngIf="item.paymentStatus == 'Paid'" class="green">          
                                                {{item.paymentStatus}}
                                                 </td>
                                                 <td matTooltip="Click To View" *ngIf="item.paymentStatus == 'Unpaid'" class="red">          
                                                  {{item.paymentStatus}}
                                                   </td>
                                                   <td matTooltip="Click To View" *ngIf="item.paymentStatus == 'Cheque Payment Pending'" class="red">          
                                                    {{item.paymentStatus}}
                                                     </td>
                                                   
                                                     </tr>
                                               
   
                                              </tbody> 
                                          </table>
                                      </div>
                                      <div class="col-sm-12 pt-0 text-right">
                                              <pagination-controls id="paymentDashboardFormMaster" directionLinks="true"
                                    (pageChange)="onPageChange($event)" class="float-right pagination my-pagination"></pagination-controls>
                                          
                                      </div>
                                  </div>

                      </div>

  </div>
                  </div>
              </div>
              </div>
  
          </div>
      </div>
      </form>
  </div>