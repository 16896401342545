import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
 import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { AfterViewInit, Directive, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { PopUpBranchComponent } from "../pop-up-branch/pop-up-branch.component";
import { PopUpPatientComponent } from "../pop-up-patient/pop-up-patient.component";
import { PatientSearchPopComponent } from "../patient-search-pop/patient-search-pop.component";
import { PopupCompanyComponent } from "../popup-company/popup-company.component";
import { Inject } from '@angular/core';
@Component({
  selector: 'app-pop-up-inward-outward',
  templateUrl: './pop-up-inward-outward.component.html',
  styleUrls: ['./pop-up-inward-outward.component.scss']
})
export class PopUpInwardOutwardComponent implements OnInit {
  errormessage: string;
  bId: any;
  companyList: any; 
  filteredListCompany: any;

  popUpInwardOutward: FormGroup;
  date: any;
  listOfAppointment:any;
  totalLength:any;
  appointmentId: any;
  size=10;
  page=1;
  searchText: any;
  submitButton = true;
  updateButton = false;
  allColor: any;
  
  filteredList: { name: string; value: string; }[];
  filteredListAppoinmentType: { name: string; value: string; }[];
  userType: string;
  branchRef: string;
  clinicRef: string;
  BranchList: any;
  isSuperAdmin= true;
  superBranchId: any;
  branchName: string;
  filteredListBranch: any;
  currentdate: any;
  listOfInwardOutward: any;
  id: any;
  courierList: any;
  filteredListCourier: any;
  disableFromCity = false;
  disableToCity = true;
  tocityList: any;
  cityList: any;
  fromCity: any;
  toCity: any;
  courierServiceCreate = true;
  branchCreate = true;
  filteredCityList: any;
  filteredToCityList: any;
  inward = false;
  outward = false;
  client = false;
  manufacturer = false;
  isDevices = false;
  isDocuments = false;
  patientRefId: any;
  patientName: any;
  companyRefId: any;
  companyName: any;
  isInwardOutward: any;
  repairRefId: any;
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
     public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<PopUpInwardOutwardComponent>,@Inject(MAT_DIALOG_DATA) public data:any,
    ) { 
      dialogRef.disableClose = true;
      this.patientRefId = data.patientRefId;
      this.patientName = data.patientName; 
      this.companyRefId = data.companyRefId; 
      this.companyName = data.companyName;
      this.repairRefId = data.repairRefId; 
      this.isInwardOutward = data.isInwardOutward;
    }
  userPermission:any;
  inwardOutwardCreate= true;
  inwardOutwardUpdate= true;

  ngOnInit(): void {
   let date=new Date();
   this.isDevices=false;
    this.currentdate =this.datepipe.transform(date, 'dd/MM/yyyy');
   
    this.page=1;
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.userType = sessionStorage.getItem('userType');
    this.userType = sessionStorage.getItem('userType');
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    if(this.userType !== 'Super Admin'){
      this.isSuperAdmin= false;
    this.userPermission.forEach((element) => {
      if(element.pageName == 'Inward Outward' && element.create == false)
      {
          this.inwardOutwardCreate = false;  
      }
      if(element.pageName == 'Inward Outward' && element.update == false)
      {
          this.inwardOutwardUpdate = false;  
      }  
      if(element.pageName == 'Courier Service Provider' && element.create == false)
      {
          this.courierServiceCreate = false;  
      } 
      if(element.pageName == 'Branch' && element.create == false)
      {
          this.branchCreate = false;  
      } 
    });
  }
  this.disableFromCity = false; 
  this.disableToCity = true;
    this.popUpInwardOutward = this.fb.group({
      "id": new FormControl(''),
      "date": new FormControl('',Validators.required),
      "inwardOutward": new FormControl('',Validators.required),
      "patientName": new FormControl(''),
      "patientRefId": new FormControl(),
      "companyRefId": new FormControl(),
      "companyName": new FormControl(''),
      "deviceType": new FormControl(),
      "refType": new FormControl('',Validators.required),
      "type": new FormControl('',Validators.required),
      "docType": new FormControl(''),
      "notes": new FormControl(''),
      "searchInwardOutward": new FormControl(''),
      "branchWiseId": new FormControl(''),
      "branchName": new FormControl(),
      "branchRefId": new FormControl(),
      "clinicRefId": new FormControl(),
      "repairRefId": new FormControl(),     
    });
  this.popUpInwardOutward.controls['date'].setValue(this.currentdate);
  this.popUpInwardOutward.controls['patientRefId'].setValue(this.patientRefId);
  this.popUpInwardOutward.controls['patientName'].setValue(this.patientName);
  this.popUpInwardOutward.controls['companyRefId'].setValue(this.companyRefId);
  this.popUpInwardOutward.controls['companyName'].setValue(this.companyName);

  if(this.isInwardOutward == 'Inward')
  {
    this.popUpInwardOutward.controls['inwardOutward'].setValue('Inward');
    this.InwardOutwardClick('Inward');
  }
  else if(this.isInwardOutward == 'Outward')
  {
    this.popUpInwardOutward.controls['inwardOutward'].setValue('Outward');
    this.InwardOutwardClick('Outward');
  }
  this.getLastId();
  this.fetchAllCompanies();
 this.fetchBranchList();
 this.getAllBranch();
}
fetchAllCompanies(){
  let clinicRef= sessionStorage.getItem('clinicRef');
  let branchRef= sessionStorage.getItem('branchRef');
  this.apimicroservice.getAllCompanylist(branchRef,clinicRef).subscribe(res => {
    //
     if (res.status) {    
       this.companyList = res.data.data;
       this.filteredListCompany= this.companyList.slice();
     } else {
     //  this.apimicroservice.openSnackbar(res.message);
     }
   })
 }
  
  submitInwordOutword(){
   // this.setValidator();
   //this.InwardOutwardClick();
    let payload = this.popUpInwardOutward.value;
    this.popUpInwardOutward.controls['branchRefId'].setValidators(Validators.required);
    this.popUpInwardOutward.controls['branchRefId'].updateValueAndValidity();
    if (this.popUpInwardOutward.invalid) {
      this.popUpInwardOutward.get("date").markAsTouched();
       this.popUpInwardOutward.get("inwardOutward").markAsTouched();
       this.popUpInwardOutward.get("refType").markAsTouched();
       this.popUpInwardOutward.get("type").markAsTouched();
       this.popUpInwardOutward.get("patientName").markAsTouched();
        this.popUpInwardOutward.get("companyRefId").markAsTouched();

    }else {
      this.addData();
    }
  }
  
  
  addData(){
    this.spinner.show();
    let payload = this.popUpInwardOutward.value;
    payload.repairRefId = this.repairRefId;
    if(this.userType !== 'Super Admin')
    {
      payload.branchRefId = this.branchRef;
      payload.clinicRefId = this.clinicRef;
      payload.branchName = this.branchName;
    }
   //
    
    this.apimicroservice.saveInOutwardData(payload).subscribe(res => {
      this.spinner.hide();
      // this.dialogRef.close();
   
      // this.ngOnInit(); 
      this.fetchInwardOutward();
    
      if(res.status){
      //  this.signatureImg = "../../assets/user2.png";
        this.apimicroservice.openSnackbar(res.message);
        
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    },(ERROR:HttpErrorResponse) => {
      this.apimicroservice.openSnackbar(ERROR.error.message);
     //
     this.spinner.hide(); 
  });
  }
  // getLastId()
  // {
  //   this.apimicroservice.getInOutwardLastId().subscribe(res => {
  //     //   
  //     if (res.status) {
   
  //       if(res.data.length > 0)
  //       {
  //         let clientIdd = res.data[0].inOutId;
  //         this.bId =clientIdd + 1;
  //       }
  //       else
  //       {
  //         this.bId =1;
  //       }
  //     } else {
  //       this.apimicroservice.openSnackbar("Something went wrong");
  //     }
     
  //   })
  // }

  getLastId()
  {
    let branchRef ='';
    if (this.userType !== 'Super Admin') {
      //
      this.apimicroservice.getInOutwardLastId(this.branchRef).subscribe(res => {
        //   
        // if(res['data']['data'] == '')
        // {
        //   this.clinicalStaffMasterForm.controls['branchWiseId'].setValue('1');
        // }
        if (res['status']) {
          // let clientIdd = res.data[0].branchWiseId; 
          // this.bId=clientIdd + 1;

          let clientIdd = res['data']['totalItems'];
          this.bId=clientIdd + 1;
          //this.clinicalStaffMasterForm.controls['branchWiseId'].setValue(staffIdd + 1);
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
       
      })
    } else {
      //
      //
      this.apimicroservice.getInOutwardLastId(branchRef).subscribe(res => {
        //    
        // if(res['data']['data'] == '')
        // {
        //   this.clinicalStaffMasterForm.controls['branchWiseId'].setValue('1');
        // }
        if (res['status']) {
          // let clientIdd =  res.data[0].branchWiseId; 
          // this.superBranchId = clientIdd + 1;

          let clientIdd = res['data']['totalItems'];
          this.superBranchId = clientIdd + 1;

          this.bId=clientIdd + 1;
           //this.clinicalStaffMasterForm.controls['branchWiseId'].setValue(staffIdd + 1);
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
       
      })
      
    }
    
  }
  fetchInwardOutward() {
    let pg = 1;
    let size = 10;
    let search='';
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    this.spinner.show();
    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getInOutwardData(pg,size,search,branchRef,clinicRef).subscribe(res => {
        this.spinner.hide(); 
          this.listOfInwardOutward = res.data.data;
          this.totalLength = res.data.totalItems;
          this.dialogRef.close({ data: res.data.data[0]._id})
         if(res.status){
          // this.apimicroservice.openSnackbar(res.message);        
         }else{
           //this.apimicroservice.openSnackbar(res.message);
         }
       }); 
      }
      else
      {
        this.apimicroservice.getInOutwardData(pg,size,search,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
          this.spinner.hide();
          this.listOfInwardOutward = res.data.data;
          this.totalLength=res.data.totalItems;
          this.dialogRef.close({ data: res.data.data[0]._id})
        });
      }
  }
  

  prepopulation(id) {
    this.spinner.show();
    this.apimicroservice.getInOutwardDataById(id).subscribe(res => {
      this.spinner.hide();
      let data = res.data[0]; 
      // this.id = id;
       if(res.status){
         this.updateButton = true;
         this.submitButton = false;
         this.disableFromCity = true;
         this.disableToCity = true;
         this.popUpInwardOutward = this.fb.group({
      "id": new FormControl(data.branchWiseId),
      "date": new FormControl(data.date,Validators.required),
      "inwardOutward": new FormControl(data.inwardOutward,Validators.required),
      "patientName": new FormControl(data.patientName),
      "patientRefId": new FormControl(data.patientRefId),
      "companyRefId": new FormControl(data.companyRefId),
      "companyName": new FormControl(data.companyName),
      "deviceType": new FormControl(data.deviceType),
      "refType": new FormControl(data.refType,Validators.required),
      "type": new FormControl(data.type,Validators.required),
      "docType": new FormControl(data.docType),
      "notes": new FormControl(data.notes),
      "searchInwardOutward": new FormControl(''),
      "branchWiseId": new FormControl(data.branchWiseId),
      "branchRefId": new FormControl(data.branchRefId),
      "clinicRefId": new FormControl(data.clinicRefId),
      "branchName": new FormControl(data.branchName),
      "repairRefId": new FormControl(data.repairRefId),

        });
      
        this.superBranchId = data.branchWiseId;
        this.bId=data.branchWiseId;
        this.id = id;
      this.InwardOutwardClick(data.inwardOutward);
      this.type();
       }else{
     //    //this.apimicroservice.openSnackbar(res.message);
       }
     });
  }

  UpdateInwordOutword(){
   // this.setValidator();
    let payload = this.popUpInwardOutward.value;
    this.popUpInwardOutward.controls['branchRefId'].setValidators(Validators.required);
    this.popUpInwardOutward.controls['branchRefId'].updateValueAndValidity();
    if (this.popUpInwardOutward.invalid) {
      this.popUpInwardOutward.get("date").markAsTouched();
       this.popUpInwardOutward.get("inwardOutward").markAsTouched();
       this.popUpInwardOutward.get("refType").markAsTouched();
       this.popUpInwardOutward.get("type").markAsTouched();
    }else {
      this.updateData();
    }
  }

  updateData(){
    this.spinner.show();
    let payload = this.popUpInwardOutward.value;
    if(this.userType !== 'Super Admin')
    {
      payload.branchRefId = this.branchRef;
      payload.clinicRefId = this.clinicRef;
      payload.branchName = this.branchName;
    }
     if(payload.inwardOutward == 'Inward')
     {
      payload.companyRefId = undefined;
      payload.companyName = '';
     }
     else if(payload.inwardOutward == 'Outward')
     {
      payload.patientName = '';
     }
     this.apimicroservice.updateInOutwardData(payload,this.id).subscribe(res => {
       //
       this.spinner.hide();
       if(res.status){
         this.apimicroservice.openSnackbar(res.message);
        // this.signatureImg = "../../assets/user2.png";
          this.updateButton = false;
          this.submitButton = true;
          this.ngOnInit();
          
       }else{ 
       //  //this.apimicroservice.openSnackbar(res.message);
       }
     });
  }
 
  cancelUpdate(){
    this.popUpInwardOutward.reset();
    this.submitButton=true;
    this.updateButton=false;
    this.ngOnInit();
  }
  openSnackbar(message: string) {
    return this._snackBar.open(message, "close", {
      verticalPosition: "bottom",
      horizontalPosition: "right",
      duration: 2000,
      panelClass: ["snackbarStyle"],
    });
  }
  toInwardOutwardForm() {
    document.getElementById("toInwardOutwardForm").scrollIntoView();
  }
  toInwardOutwardLst() {
    document.getElementById("toInwardOutwardLst").scrollIntoView();
  }
  onPageChange(event) {
    this.spinner.show();
    this.page = event;
    this.searchText = this.popUpInwardOutward.get('searchInwardOutward').value;
    if(this.searchText == null)
    {
      this.searchText = ''; 
    }
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
     this.apimicroservice.getInOutwardData(this.page,this.size,this.searchText,branchRef,clinicRef).subscribe(res => {
        this.spinner.hide(); 
          this.listOfInwardOutward = res.data.data;
          this.totalLength = res.data.totalItems;
       if(res.status){
        //  this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
  
     });
    }
    else
    {
      this.apimicroservice.getInOutwardData(this.page,this.size,this.searchText,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
        this.spinner.hide(); 
          this.listOfInwardOutward = res.data.data;
          this.totalLength = res.data.totalItems;
       if(res.status){
        //  this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
  
     });
    }
  }
  onKeyUpEvent(event: any) {
    this.searchText = this.popUpInwardOutward.get('searchInwardOutward').value;
    let page=1;
    let size=10;
    let branchRef = '';
    let clinicRef = '';
    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getInOutwardData(page,size,this.searchText,this.branchRef,this.clinicRef).subscribe(res => {
        this.spinner.hide(); 
          this.listOfInwardOutward = res.data.data;
          this.totalLength = res.data.totalItems;
          this.page=res.data.pageNumber;
       if(res.status){
        //  this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
    }
    else
    {
      this.apimicroservice.getInOutwardData(page,size,this.searchText,branchRef,clinicRef).subscribe(res => {
        this.spinner.hide(); 
          this.listOfInwardOutward = res.data.data;
          this.totalLength = res.data.totalItems;
          this.page=res.data.pageNumber;
       if(res.status){
        //  this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
    }
  }
  InwardOutwardClick(e)
  {
    if(e == 'Inward')
    {
      this.popUpInwardOutward.controls['patientName'].setValidators(Validators.required);
      this.popUpInwardOutward.controls['patientName'].updateValueAndValidity();

    this.popUpInwardOutward.controls['companyRefId'].setErrors(null);
    this.popUpInwardOutward.controls['companyRefId'].clearValidators();
    this.popUpInwardOutward.controls['companyRefId'].updateValueAndValidity();

      this.inward = true;
      this.outward = false;
      this.selectRef('Client');
      this.popUpInwardOutward.get("refType").setValue('Client');      
    }
    else if(e == 'Outward')
    {
      this.popUpInwardOutward.controls['companyRefId'].setValidators(Validators.required);
      this.popUpInwardOutward.controls['companyRefId'].updateValueAndValidity();

      this.popUpInwardOutward.controls['patientName'].setErrors(null);
      this.popUpInwardOutward.controls['patientName'].clearValidators();
      this.popUpInwardOutward.controls['patientName'].updateValueAndValidity();
  
      this.outward = true;
      this.inward = false;
      this.selectRef('Manufacturer');
      this.popUpInwardOutward.get("refType").setValue('Manufacturer');     
    }
  }
 selectRef(e)
 {
  if(e == 'Client')
  {
    this.client = true;
    this.manufacturer = false;
    this.inward = true;
    this.outward = false;
    this.popUpInwardOutward.get("inwardOutward").setValue('Inward'); 
  }
  else if(e == 'Manufacturer')
  {
    this.manufacturer = true;
    this.client = false;
    this.outward = true;
    this.inward = false;
    this.popUpInwardOutward.get("inwardOutward").setValue('Outward'); 
  }
 }
 type()
 {
  let payload = this.popUpInwardOutward.value;
  if(payload.type == 'Devices')
  {
    this.isDevices = true;
    this.isDocuments = false;
  }
  else if(payload.type == 'Documents')
  {
    this.isDocuments = true;
    this.isDevices = false;
    this.popUpInwardOutward.get("docType").setValue('Miscellaneous');   
  }
  else
  {
    this.isDevices = false;
    this.isDocuments = false; 
  }
 }
  openCompanyPopUp(){
    const dialogRef3 = this.dialog.open(PopupCompanyComponent,
      {
        width: "900px",
      });

      dialogRef3.afterClosed().subscribe((result) => {
        let clinicRef= sessionStorage.getItem('clinicRef');
        let branchRef= sessionStorage.getItem('branchRef');
        this.apimicroservice.getAllCompanylist(branchRef,clinicRef).subscribe(res => {
          //
           if (res.status) {    
             this.companyList = res.data.data;
             this.filteredListCompany= this.companyList.slice();
              this.popUpInwardOutward.get("companyRefId").setValue(res.data.data[0]._id);
           } else {
           //  this.apimicroservice.openSnackbar(res.message);
           }
         })

       });
  }
  openPatientPopUp() {
    
    const dialogRef3 = this.dialog.open(PopUpPatientComponent,
      {
        width: "1200px",
      });
      dialogRef3.afterClosed().subscribe((result) => {
       // this.fetchPatientList();  
      //  let branchRef = '';
      //  let clinicRef = '';
      //  this.apimicroservice.getPatientListByBranchClinic(branchRef,clinicRef).subscribe(res => {
      //   //
        
      //   if (res.status) {    
      //     this.patientList = res.data;
      //     this.filteredListPatient = this.patientList.slice();
      //    // this.onSelectPatient(this.patientList[0]._id);
      //   this.repairComplaintForm.get("patientRefId").setValue(this.patientList[0]._id);
      
      //   } else {
      //    // this.apimicroservice.openSnackbar(res.message);
      //   }
      // })
       });
  }
  openDepartmentPopUp() {
    const dialogRef3 = this.dialog.open(PatientSearchPopComponent, {
      width: "97%",
      height: "90%", 
    });

    dialogRef3.afterClosed().subscribe((result) => {
      this.popUpInwardOutward.get("patientName").setValue(result.data.firstName + ' ' + result.data.lastName);
      this.popUpInwardOutward.get("patientRefId").setValue(result.data._id);
      // this.onSelectPatient(result.data._id);
    
    });
  }
  selectCompany(e)
  {
    //
    
    this.popUpInwardOutward.get("companyName").setValue(e);
  }
  getAllBranch()
  {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      if (res.status) {         
        let length =res.data.length - 1;
        this.branchName=res.data[length].branchName;
        //
        
        this.popUpInwardOutward.controls["branchRefId"].setValue(res.data[length]._id);
        this.branchClick(res.data[length]._id,this.branchName);
        if(this.userType == 'Super Admin')
        {  
          this.apimicroservice.getBranchById(res.data[length]._id).subscribe(res => {
            this.spinner.hide();
            this.popUpInwardOutward.get("branchName").setValue(this.branchName);
           });
        }
      }
    });
  }
  fetchBranchList() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      //  
      if (res.status) {    
        this.BranchList = res.data;
        this.filteredListBranch= this.BranchList.slice();
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    })
  } 
  branchClick(id,branchName)
  {

    this.popUpInwardOutward.controls["branchName"].setValue(branchName);

   this.apimicroservice.getBranchById(id).subscribe(res => { 
     //
       
     let data = res.data[0]; 
     let payload = this.popUpInwardOutward.value;
      payload.clinicRefId = res.data[0].clinicRefId;
      this.popUpInwardOutward.controls["clinicRefId"].setValue(res.data[0].clinicRefId);
       if(this.updateButton == false){
        this.spinner.show();
        this.apimicroservice.getInOutwardLastId(payload.branchRefId).subscribe(res => { 
          //   
         
          if (res['status']) {
            let staffIdd = res['data']['totalItems'];
            this.popUpInwardOutward.controls['branchWiseId'].setValue(staffIdd + 1);
            //this.bId=staffIdd + 1;
          } else {
            this.apimicroservice.openSnackbar("Something went wrong");
          }
          this.spinner.hide();
        }) 
      }
    });
  }
   openBranchPopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpBranchComponent,
      {
        width: "1200px",
      });
      dialogRef3.afterClosed().subscribe((result) => {
        let clinicRef= sessionStorage.getItem('clinicRef');
        this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
          //  
          if (res.status) {    
            this.BranchList = res.data;
            this.filteredListBranch= this.BranchList.slice();

          this.branchClick(res.data[0]._id,res.data[0].branchName);
            this.popUpInwardOutward.get("branchRefId").setValue(res.data[0]._id);
          } 
        })
        //this.fetchBranchList();
      })
  }
  popUpClose()
  {
    this.dialogRef.close();
  }
  onback() {
    this.apimicroservice.back()
  }
}