<div class="container-fluid bgcolor2" [formGroup]="clinicHolidayForm">
    <form [formGroup]="clinicHolidayForm" autocomplete="off" novalidate>
      <div class="row">
          <div class="col-sm-12">
           
              <div class="row">
                  <div class="col-sm-12">
                      <div class="row">
                          <div class="card divbody mb-3 borderradius p-2" id="toclinicHolidayForm">
                            <div class="row">

                              <div class="col-12">
                                <div class="row ">
                                <div class="col-sm-9">
                                
                                    <h1 class="pt-2 pl-0" >Clinic Holiday</h1>
                                    </div> 
                                    <div class="col-sm-3 mt-1">
                                      <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                                        <a (click)="onback()" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">arrow_back</span>Back</a>
                                        <a (click)="toClinicHolidayLst()" class="d-flex align-items-center ms-3 pr-2 mt-2"><span class="material-symbols-outlined pr-2">reorder</span>List</a>
                              </div>
                              </div>
                            </div>
                           </div>
                           </div>
                              <div class="col-sm-12">
                                  <div class="row">
                                  
                                          <div class="col-sm-3">
                                            <div class="row">
                                              <div class="col-sm-10 pr-1">
                                            <mat-form-field class="example-full-width" appearance="outline">
                                              <mat-label>Branch Name</mat-label>
                                             
                                              <mat-select #select2 formControlName="branchRefId">
                                                <mat-select-filter *ngIf="select2.focused" [placeholder]="'Filter'"
                                                  [displayMember]="'branchName'" [array]="BranchList"
                                                  (filteredReturn)="filteredListBranch =$event"></mat-select-filter>
                                                <mat-option *ngFor="let item of filteredListBranch" [value]="item._id"
                                                  (click)="branchClick(item._id,item.branchName);">
                                                  {{ item.branchName }}</mat-option>
                                              </mat-select>
                                            </mat-form-field>
                                            <mat-error
                                             *ngIf="clinicHolidayForm.controls['branchRefId'].touched && clinicHolidayForm.controls['branchRefId'].errors?.required">
                                             This field is mandatory.
                                           </mat-error> 
                                           </div>
                                           <div class="col-sm-2 mTop no-print">
                                            <i (click)="openBranchPopUp();" matTooltip="Add New Branch" class="blue_col mTop fa-solid fa-circle-plus
                                             pl-1 fa-lg"></i>
                                          </div>
                                        </div>
                                     </div>
                                        <div class="col-sm-3">
                                            <mat-form-field class="example-full-width" appearance="outline">
                                              <mat-label>Holiday Name</mat-label>
                                              <input matInput placeholder="Holiday Name" formControlName="holidayName" />
                                            </mat-form-field>
                                            <mat-error
                                             *ngIf="clinicHolidayForm.controls['holidayName'].touched && clinicHolidayForm.controls['holidayName'].errors?.required">
                                             This field is mandatory.
                                           </mat-error>
                                          </div>
                                      
                                        <div class="col-sm-3 ">
                                          <mat-form-field class="example-full-width " appearance="outline">
                                             <mat-label>From Date</mat-label>
                                             <input matInput  [min]="currentdate" [matDatepicker]="fromDate" placeholder="Choose a date" 
                                              formControlName="fromDate" (dateChange)="toOpenDateChnage();"> 
                              
                                              <mat-datepicker-toggle matSuffix [for]="fromDate">
                                              </mat-datepicker-toggle>
                                              <mat-datepicker #fromDate></mat-datepicker>   
                                         </mat-form-field>
   
                                             <mat-error
                                             *ngIf="clinicHolidayForm.controls['fromDate'].touched && clinicHolidayForm.controls['fromDate'].errors?.required">
                                             This field is mandatory.
                                           </mat-error>
                                           <mat-error class="margin_tp"
                                        *ngIf="clinicHolidayForm.controls['fromDate'].hasError('matDatepickerMin')">
                                        Please enter Date greater than or equal to {{minDateStr}}.
                                      </mat-error>
                                         </div>
                                         <div class="col-sm-3 ">
                                           <mat-form-field class="example-full-width " appearance="outline">
                                               <mat-label>To Date</mat-label>
                                               <input matInput  [min]="currentdate" [matDatepicker]="toDate" placeholder="Choose a date" 
                                                formControlName="toDate" (dateChange)="toCloseDateChnage();">
                                
                                                <mat-datepicker-toggle matSuffix [for]="toDate">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #toDate></mat-datepicker>
                                              
                                           </mat-form-field>
                                           <mat-error
                                           *ngIf="clinicHolidayForm.controls['toDate'].touched && clinicHolidayForm.controls['toDate'].errors?.required">
                                           This field is mandatory.
                                         </mat-error>
                                         <mat-error class="margin_tp"
                                        *ngIf="clinicHolidayForm.controls['toDate'].hasError('matDatepickerMin')">
                                        Please enter Date greater than or equal to {{minDateStr}}.
                                      </mat-error>
                                       </div>
                                      
                                      <div class="col-sm-1 offset-sm-10 mb-2 mt-2" *ngIf="submitButton"> 
                                          <button class="btn btn-primary btn-sm example-full-width"  type="button" 
                                          (click)="validateSave()">Save </button>
  
                                      </div>
                                      <div class="col-sm-1 offset-sm-10 mb-2 mt-2" *ngIf="updateButton"> 
                                        <button class="btn btn-primary btn-sm example-full-width"  type="button" 
                                        (click)="validateUpdate()">Update</button>
  
                                    </div>
                                    <div class="col-sm-1 mb-2 mt-2">
                                      <button class="btn btn-primary btn-sm example-full-width"  (click)="clear()" type="reset">Cancel</button>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-sm-12 pl-0 pr-2">
                  <div class="row">
                      <div class="card divbody borderradius p-3 pb-0">
                              <div class="col-sm-12">
                                  <div class="row">
                                      <div class="col-sm-9 ">
                                          <h1 class="titlecolor">Clinic Holiday List</h1>
                                      </div>
                                      <div class="search-box col-sm-3 pb-2">
                                        <input class="form-control amber-border" type="text" placeholder="Search" 
                                        formControlName="searchHoliday" aria-label="Search" (keyup)="onKeyUpEvent($event)"
                                        >
                                        <div class="input-group-append">
                                           <span class="input-group-text amber lighten-3" id="basic-text1"><span class="material-symbols-outlined">search</span></span>
                                        </div>
                                     </div>
                                     
                                      <div class="col-sm-12">
                                          <table id="toClinicHolidayLst"
                                           class="table table-bordered data-table-custom text-center" 
                                       >
                                              <thead>
                                                  <tr class="text-center">
                                                      <th>Sr.No.</th>
                                                       <th>Branch Name</th>
                                                       <th>Holiday Name</th>
                                                      <th>From Date</th>
                                                      <th>To Date</th>
                                                  </tr>
                                              </thead> 
                                              <tbody>
                                                  <tr *ngFor="let item of clinicHolidayList | paginate : { id:'clinicHolidayMaster',
                                                  itemsPerPage: size, currentPage: page, totalItems:totalLength } let i = index" 
                                                  (click)="prepopulation(item._id)"(click)="toclinicHolidayForm()" style="cursor:pointer; text-align: center ">
                                                   <td  matTooltip="click to view">{{i+1}}</td>
                                                   <td  matTooltip="click to view">{{item.branchName}}</td>
                                                   <td  matTooltip="click to view">{{item.holidayName}}</td>
                                                   <td  matTooltip="click to view">{{item.fromDate | date:'dd/MM/yyyy'}}</td>
                                                   <td  matTooltip="click to view">{{item.toDate | date:'dd/MM/yyyy'}}</td>
                                                 </tr>
                                              </tbody>
                                          </table>
                                      </div>
                                      <div class="col-sm-12 pt-0 text-right">
                                              <pagination-controls id="clinicHolidayMaster" directionLinks="true"
                                    (pageChange)="onPageChange($event)" class="float-right pagination my-pagination"></pagination-controls>
                                          
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
  
                  </div>
              
  
          </div>
      </div>
      </form>
  </div>