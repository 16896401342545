<div class="container-fluid no-print" >
    <div class="sidenav side-nav text-center">
        <!-- <div class="col-sm-12  text-center">
                <img class="cardcolor" width="65rem" src="../assets/pp.jpeg" alt="Girl in a jacket">
              
        </div> -->
        <!-- <div class="leftside-menu h-100">
            <div id="leftside-menu-container" >
               <ul class="side-nav">
                  <li class="side-nav-item menuitem-active">
                
                     <div class="col-sm-12 p-2 text-center side-nav-link" *ngIf="homeView" routerLinkActive="active">
                        <div class="p-2 " [routerLink]="['/home']">
                              <i matTooltip="Home Page" class="fas  fa-home fa-2x"></i>
                        </div>
                    </div>
                  </li>
                  <li class="side-nav-item">
                     <a href="#" class="side-nav-link"><i class="bi bi-file-earmark-fill"></i></a>
                  </li>
                  <li class="side-nav-item">
                     <a href="#" class="side-nav-link"><i class="fas fa-calendar-alt"></i></a>
                  </li>
                  <li class="side-nav-item">
                     <a href="#" class="side-nav-link"><i class="fas fa-calendar-check"></i></a>
                  </li>
                  <li class="side-nav-item">
                     <a href="#" class="side-nav-link"><i class="fas fa-cog"></i></a>
                  </li>
                  <li class="side-nav-item menuitem-active">
                     <a href="#" class="side-nav-link"><i class="fab fa-whatsapp-square"></i></a>
                  </li>
                  <li class="side-nav-item">
                     <a href="#" class="side-nav-link"><i class="fas fa-bell"></i></a>
                  </li>
               </ul>
            </div>
         </div> -->
         <div class="col-sm-12 p-2 text-center" >
            <div class="p-2 " >
                  
            </div>
        </div>
        <div class="col-sm-12 p-2 text-center" >
            <div class="p-2 " >
                  
            </div>
        </div>
        
        <div class="col-sm-12 fasW p-2 text-center " *ngIf="homeView" routerLinkActive="active">
            <div class="p-2 padding " [routerLink]="['/home']">
                <i matTooltip="Home Page" class="bi bi-house-door-fill fa-2x"></i>
                  <!-- <i matTooltip="Home Page" class="fas  fa-home fa-2x"></i> -->
            </div>
        </div>
        <br *ngIf="homeView">
        <div class="col-sm-12 p-2 fasW" *ngIf="repairComplaintView" routerLinkActive="active">
            <div class="p-2 padding" [routerLink]="['repair-complaint']"> 
                <i matTooltip="Repair Track" class="fas  fa-file fa-2x"></i>
            </div>
        </div>
        <br *ngIf="repairComplaintView">
        <div class="col-sm-12  p-2 fasW" *ngIf="bookAppointmentsView" routerLinkActive="active">
            <div class="p-2 padding" [routerLink]="['calander']"> 
              
                <i matTooltip="Appointment Calendar" class="fas  fa-calendar-alt fa-2x"></i>
            </div>
        </div>
        <br *ngIf="bookAppointmentsView" >
        <div class="col-sm-12  p-2 fasW" *ngIf="todaysAppointmentView" routerLinkActive="active">
            <div class="p-2 padding" [routerLink]="['/opt/dashboard']">
                <i matTooltip="Today's Appointments" class="fas fa-calendar-check fa-2x"></i>
            </div>
        </div>
        <br *ngIf="todaysAppointmentView">
        <div class="col-sm-12  p-2 fasW" *ngIf="settingsView" routerLinkActive="active">
            <div class="p-2 padding">
                <i matTooltip="Settings" class="fas fa-cog fa-2x" (click)="openMasterPopUp()"></i>
            </div>
        </div> 
        <br *ngIf="settingsView" >
        <div class="col-sm-12  p-2 fasW" *ngIf="whatsAppIntegrationView"  routerLinkActive="active">
            <div class="p-2 padding">
                <i class="fa fa-whatsapp wSize" (click)="openWhatsAppPopUp()" matTooltip="WhatsApp"></i>
            </div>
        </div>
        <br *ngIf="whatsAppIntegrationView" > 
        <div class="col-sm-12  p-2 fasW" *ngIf="chequeAlertView"  routerLinkActive="active">
            <div class="p-2 padding" [routerLink]="['/cheque-alert']" >
                <i *ngIf="listOfAlert > 0" matBadge="{{listOfAlert}}" class="fas fa-bell fa-2x"></i>
                <i  *ngIf="listOfAlert == 0" class="fas fa-bell fa-2x"></i>
            </div>
        </div>
      
        </div>
</div>