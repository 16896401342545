<div class="wrapper bgcolor2">
  <div class="main-panel">
          <div class="content">
       <div class="margin_top">
      
          <div class="col-sm-12">
              <div class="row"> 
                  <div class="col-sm-1">
                      </div> 
                      <div class="col-sm-11">
                        <div class="row col-sm-12 pr-0 pl-0 pt-0 pb-0 mb-0 ">
                          <div class="col-sm-8 capitalize" >
                            <div class="row"> 
                              <div class="col-sm-12 capitalize" >
                                <div class="row"> 
                            <h1 class="pt-2 pl-0"> 
                            Patient Name : {{patientData.firstName}} {{patientData.lastName}},</h1>
                            <h2 class="pt-3 pl-3 text-left">
                              <label *ngIf="patientData.age !== ''">Age : {{patientData.age}}yrs,
                               ID : {{patientData.patientId}}</label> 
                               <label *ngIf="patientData.age == ''">
                                 ID : {{patientData.patientId}}</label> 
    
                             </h2>
                            </div>
                                </div>
                                <!-- <div class="col-sm-6 text-left" >
                            <h2 class="pt-2 pl-0 text-left">
                             <label *ngIf="patientData.age !== ''">Age : {{patientData.age}}yrs,
                              ID : {{patientData.patientId}}</label> 
                              <label *ngIf="patientData.age == ''">
                                ID : {{patientData.patientId}}</label> 
   
                            </h2>
                                </div> -->
                                </div>
                          </div>
                          <!-- <div class="col-sm-2" ></div> -->
                            <div class="col-sm-4 text-left justify-content-start" >
                              <!-- *ngIf="aptPatient == patientData._id" -->
                              <div  class="row blue_col justify-content-end">
                                
                                  <!-- <h2 *ngIf="arr == ''" class="pl-0">
                                     
                                   </h2> -->
                                   <h2 *ngIf="datee !== aptDate">Last Appoinment At :</h2>
                                   <h2 *ngIf="datee == aptDate">Today's Appoinment At :</h2>
                                   <!-- <h2 *ngFor="let data of appoinmentlist" class=" pl-0"> -->
                              <h2 class="cursor pl-0">
         
                                <label class="cursor" (click)="loadpatient()">
                                <label class="cursor" *ngIf="datee !== aptDate">{{lastApt | date:'dd/MM/yy, h:mm a'}}</label>
                                <label class="cursor" *ngIf="datee == aptDate">{{lastApt | date:'h:mm a'}}</label> &nbsp; &nbsp;
                           
                                </label>
                              
                                <!-- <label *ngIf="data.patientRefId == patientData._id">
                                  Todays Appoinment At :</label> -->
                                  <!-- <label *ngIf="data.patientRefId == patientData._id" (click)="loadpatient(data)">
                                    {{data.dateTime | date:'h:mm a'}} 
                             </label> -->
                         
                                    </h2>
                                </div>
                                </div>
                       
                           </div>
                        <mat-toolbar class="mat2 row pr-0 pb-0 mb-0 pl-0 col-sm-12 ">
                   
                          
                           <div class="row col-sm-12 pl-0 pr-0 app-dashboard">
                         
                                                <div class="col-sm-3">
                                                  <div class="card card2 overflow-hidden">
                                                    <div class="card-body p-0">
                                                      <div class="row">
                                                        <div class="col p-0">
                                                          <p *ngIf="deviceCount !== '' && deviceCount !== undefined" class="muted-text yellow mb-2">{{deviceCount}}</p>
                                                          <p *ngIf="deviceCount == '' || deviceCount == undefined" class="muted-text yellow mb-2">-</p>
                                                          <h2 class="mt-2">Device</h2>
                                                        </div>
                                                      
                                                        <div class="col col-auto p-0">
                                                          <div class="icon-box icon-box-md">
                                                            <span class="material-symbols-outlined">
                                                              activity_zone
                                                              </span>
                                                                          <img src="../../assets/icon-bg4.svg" class="icon-bg iconPadding">
                                                                       </div>
                                                      </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                          </div>
                                                          <div class="col-sm-3">
                                                  <div class="card card2 overflow-hidden">
                                                    <div class="card-body p-0">
                                                      <div class="row">
                                                        <div class="col p-0">
                                                          <p class="muted-text green mb-2">{{earMouldCount}}</p>
                                                          <h2 class="mt-2">Earmould</h2>
                                                        </div>
                                                      
                                                        <div class="col col-auto p-0">
                                                          <div class="icon-box icon-box-md">
                                                            <span class="material-symbols-outlined">
                                                              hearing
                                                              </span>
                                                                          <img src="../../assets/icon-bg2.svg" class="icon-bg iconPadding">
                                                                       </div>
                                                      </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                          </div>

                                                          <div class="col-sm-3">
                                                  <div class="card card2 overflow-hidden">
                                                    <div class="card-body p-0">
                                                      <div class="row">
                                                        <div class="col p-0">
                                                          <p class="muted-text red mb-2">{{appoinmentCount}}</p>
                                                          <h2 class="mt-2">Appointments</h2>
                                                        </div>
                                                      
                                                        <div class="col col-auto p-0">
                                                          <div class="icon-box icon-box-md">
                                                            <span class="material-symbols-outlined">
                                                              event_available
                                                              </span>
                                                                          <img src="../../assets/icon-bg8.svg" class="icon-bg iconPadding">
                                                                       </div>
                                                      </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                          </div>
                                                          <div class="col-sm-3">
                                                            <div class="card card2 overflow-hidden">
                                                              <div class="card-body p-0">
                                                                <div class="row">
                                                                  <div class="col p-0">
                                                                    <p class="muted-text gyellow mb-2">{{communicationCount}}</p>
                                                                    <h2 class="mt-2">Communication</h2>
                                                                  </div>
                                                                
                                                                  <div class="col col-auto p-0">
                                                                    <div class="icon-box icon-box-md">
                                                                      <span class="material-symbols-outlined">
                                                                        chat
                                                                        </span>
                                                                                    <img src="../../assets/icon-bg11.svg" class="icon-bg iconPadding">
                                                                                 </div>
                                                                </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                                    </div>
                                                                
                            </div>
                       
                        </mat-toolbar>        
         <mat-tab-group (selectedTabChange)="onTabChanged($event)" class="mat-tab-labels mt-0 pt-0 mb-4 " 
         [selectedIndex]="selectedIndex">   
          <mat-tab class="blue_col" label="Client">
              <app-patient-dashboard></app-patient-dashboard>
            </mat-tab>
            <mat-tab label="Diagnosis">
              <app-diagnosis></app-diagnosis>
            </mat-tab>
            <mat-tab label="Appointments">
              <app-appoinment-dashboard></app-appoinment-dashboard>
            </mat-tab>
            <mat-tab label="Device">
              <app-device-dashboard></app-device-dashboard>
            </mat-tab>
          <mat-tab label="Payment">
              <app-payment-dashboard></app-payment-dashboard>
            </mat-tab>
            <mat-tab label="Repair">
              <app-repair-dashboard></app-repair-dashboard>
            </mat-tab>
            <mat-tab label="Earmould">
              <app-earmould-dashboard></app-earmould-dashboard>
            </mat-tab>
            <mat-tab label="Communication">
              <app-communication-dashboard></app-communication-dashboard>
            </mat-tab>
            <mat-tab label="Notes">
              <app-notes></app-notes>
            </mat-tab>
            <mat-tab label="Follow Up">
              <app-followup-dashboard></app-followup-dashboard>
            </mat-tab>
          </mat-tab-group>
           </div>
           </div>
       </div>
      </div>
     </div>
      </div>
 </div> 