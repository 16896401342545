<div class="container-fluid bgcolor2">
    <div class="row">
        <div class="col-sm-1">
          
        </div>
        <div class="col-sm-11">  
            <div class="row">

                <div class="col-sm-12">
                  <div class="row ">
                  <div class="col-sm-9 ">
                  
                      <h1 class="pt-2 pl-1" >Todays Appointment</h1>
                      </div> 
                      <div class="col-sm-3 mt-1">
                        <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                          <a (click)="onback()" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">arrow_back</span>Back</a>
                    </div>
                </div>
              </div>
             </div>
             </div> 

            <!-- <div class="row">
                <div class="col-12">
                   <div class="page-title-box my-4">
                      <h1>Todays Appointment</h1>
                   </div>
                </div>
             </div> -->
             <div class="row">
                <div class="col-sm-9">
                    <div class="today-appointment-wrapper" *ngIf="appoinmentlist !== undefined">
                       <div class="row">
                          <div class="col-xxl-4 p-1" *ngFor="let data of appoinmentlist">


                            <div class="appointment-card card p-0" >
                               <table >
                                  <thead> 
                                     <tr >
                                 <th> 
                     <span class="patient-name a_style" (click)="editPatient(data.patientRefId)">
                        <a>{{data.patientName}}</a></span>
                               </th>
                                        <th><span class="date">{{data.dateTime | date:'d MMM y - h:mm a'}}</span></th>
                                     </tr>
                                  </thead>  
                                  <tbody (click)="loadpatient(data,data.id)">
                                    <tr>
                                        <td>Patient's ID:</td>
                                        <td>
                                            <span class="bold">{{data.patientId}}</span>
                                             </td>
                                     </tr>
                                     <tr>
                                        <td>Audiologists Name:</td>
                                        <td>{{data.doctorName}}</td>
                                     </tr> 
                                     <tr>
                                        <td>Referred By:</td>
                                        <td>{{data.clientSource}}</td>
                                     </tr>
                                   
                                     <tr>
                                        <td>Patient's Mob:</td>
                                        <td> {{data.Mob}}</td>
                                     </tr>
                                     <tr>
                                        <td>Appointment Type:</td>
                                        <td>  {{data.titleOfAppoinment}}</td>
                                     </tr>
                                  </tbody>
                               </table>  
                               <div class="icon-btns pb-4">
                                  <ul class="mb-2"> 
                                     <li>  
                                        <a class="icon-box2" data-toggle="tooltip" data-placement="top" 
                                        (click)="editAppointment(data.id)" matTooltip="Rescheduled Appointment">
                                        <span class="material-symbols-outlined">event_repeat</span>
                                        <img src="../../assets/icon-bg2.svg" class="icon-bg2">
                                        </a>
                                     </li>
                                     <li>
                                        <a class="icon-box2" data-toggle="tooltip" data-placement="top" 
                                        (click)="billing(data.patientRefId,data.appointId,data.branchRefId,data)" matTooltip="Billing">
                                        <span class="material-symbols-outlined">receipt_long</span>
                                        <img src="../../assets/icon-bg5.svg" class="icon-bg2">
                                        </a>
                                     </li>
                                     <li>
                                        <a class="icon-box2" data-toggle="tooltip" data-placement="top" matTooltip="Repair"
                                        (click)="repairClick(data.branchRefId,data.patientRefId,data.branchName,data.patientName)" >
                                        <span class="material-symbols-outlined">handyman</span>
                                        <img src="../../assets/icon-bg6.svg" class="icon-bg2">
                                        </a>
                                     </li>
                                     <li>
                                        <a class="icon-box2" data-toggle="tooltip" data-placement="top" matTooltip="HAT"
                                        (click)="hat(data.patientRefId,data.name)" >
                                        <span class="material-symbols-outlined">note_alt</span>
                                        <img src="../../assets/icon-bg1.svg" class="icon-bg2"> 
                                        </a> 
                                     </li> 
                                     <li>
                                        <a class="icon-box2" data-toggle="tooltip" data-placement="top" matTooltip="Ear Mold"
                                        (click)="earMold(data.branchRefId,data.patientRefId,data.branchName,data.patientName)" >
                                        <span class="material-symbols-outlined">hearing</span>
                                        <img src="../../assets/icon-bg10.svg" class="icon-bg2">
                                        </a> 
                                     </li> 
                                     <li> 
                                        <a class="icon-box2" data-toggle="tooltip" data-placement="top"
                                        (click)="functiondelete(data.id,data.patientName,data.dateTime)" matTooltip="Appointment Cancel">
                                        <span class="material-symbols-outlined">event_busy</span>
                                        <img src="../../assets/icon-bg8.svg" class="icon-bg2">
                                        </a>
                                     </li>
                                  </ul> 
                               </div>
                            </div>
                         </div>
                </div>
                </div>
                        <!-- <div *ngIf="appoinmentlist !== undefined" class="row pt-2">
                            <div class="col-sm-4 p-2 text-center" *ngFor="let data of appoinmentlist">
                                
                                <div class="card textTransform pt-0 pl-0 pr-0 borderradius boxsssss hover "  >
                                    <div class="col-sm-12 cardBoxblue2 borderradiusd p-2" >
                                        <div class="row">
                                         
                                            <h3  class="col-sm-12 cardtitlecolorrr text-left pl-4">
                                                Patient Name : <label class="a_style" (click)="editPatient(data.patientRefId)">{{data.patientName}}</label> 
                                            </h3>
                                        </div>
                                        <div class="row ">
                                            <h3 class="col-sm-12 text-left pl-4">
                                                {{data.dateTime | date:'MMM d, y, h:mm a'}}
                                                <label class="text-right justify-content-end pId_col ml-1"> {{data.patientId}}</label>
                                            </h3>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 pointer p-3 pb-0">
                                        <div class="row">
                                        
                                             <div class="col-sm-12 text-left">
                                                <div class="row" (click)="loadpatient(data)">
                                                    <h3 class="col-sm-7 cardtitlecolorrrwhite">
                                                        Audiologists Name:
                                                    </h3>
                                                    <h3 class="col-sm-5" >
                                                        {{data.doctorName}}
                                                    </h3>
                                                    <h3 class="col-sm-7 cardtitlecolorrrwhite">
                                                        Branch Name :
                                                    </h3>
                                                    <h3 class="col-sm-5">
                                                        {{data.branchName}}
                                                    </h3>
                                                    <h3 class="col-sm-7 cardtitlecolorrrwhite">
                                                        Patient's Mob :
                                                    </h3>
                                                    <h3 class="col-sm-5">
                                                        {{data.Mob}}
                                                    </h3>
                                              
                                                    <h3 class="col-sm-7">
                                                       Appointment Type :
                                                    </h3>
                                                    <h3 class="col-sm-5"> 
                                                        {{data.titleOfAppoinment}}
                                                        </h3>
                                                        </div>
                                                         <div class="row seven-cols">
                                                            <div class="col-md-3">
                                                
                                                        <img matTooltip="Rescheduled  Appointment" (click)="editAppointment(data.id)" width="30" src="../../assets/medical-appointment.png">  
                                                    </div> 
                                                    <div class="row">
                                                    
                                                    <div class="col-md-3">
                                                        <img matTooltip="Billing" (click)="billing(data.patientRefId,data.appointId,data)" width="30" src="../../assets/bill.png">  
                                                    </div>
                                                    <div class="col-md-3">
                                                        <img matTooltip="Repair" width="30" (click)="repairClick(data.branchRefId,data.patientRefId,data.branchName)" src="../../assets/repair.png">  
                                                    </div>
                                                    <div class="col-md-3">
                                                        <img matTooltip="HAT" width="30" (click)="hat(data.patientRefId,data.name)" src="../../assets/contact-form.png">  
                                                    </div> 
                                                    <div class="col-md-3">
                                                        <img matTooltip="Appoinment Cancel" (click)="functiondelete(data.id,data.patientName,data.dateTime)" width="30" src="../../assets/cancelApt.png">  
                                                    </div>
                                                    </div>
                                                </div>
                                             </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div> -->

                        <div *ngIf="appoinmentlist == undefined || appoinmentlist == ''" class="row pt-2">
                                <div class="col-sm-11 p-2 text-center">
                                    <div class="card pt-0 pl-0 pr-0 borderradius boxsssss hover cardHeight2" >
                                        <div class="col-sm-12 cardBoxblue2 borderradiusd p-2">
                                            <h1 class="col-sm-12 cardtitlecolorrrwhite">
                                              No Appoinments Schedule
                                              </h1>
                              
</div>
<div class="col-sm-12 p-3">
    <div class="row">
         <div class="col-sm-12 text-center">
            <div class="row justify-content-center">
                <img matTooltip="Appoinment Is Not Available" class="mr-3" width="250" src="../../assets/calender.png">    
            </div>
         </div>
    </div>
</div>
</div>
                                </div>
                                </div>



                    </div>
                  
                     <div class="col-sm-3">
                        <div class="row card pt-2">
                             
                            <div class="col-sm-12 calendar-wraper text-left">
                                <h2 class="mb-2 mt-2">Appointment Calander</h2>
                            </div> 
                           
                                    <div class="col-sm-12 pb-3 pt-3" #calendar>
                                    <div class="mt-2 p-2" *ngFor="let row of appointmentlist" >
                                        <div class="card col-sm-12 pt-2 pb-2 " [ngStyle]="{'background-color': row.color}" >
                                            <!-- <label>{{row.title}} </label> 
                                            <label *ngIf="row.start" > <span class="ml-1">At</span>  {{row.start | date :'shortTime'}} </label> -->
                                            <label *ngIf="row.title == 'No Appointments Available'" class="blColor">{{row.title }}</label>
                                            <label *ngIf="row.color !== '#FFFFFF' && row.title !== 'No Appointments Available'" class="whColor">{{row.title }}, </label>
                                            <label class="whColor ml-1" *ngIf="row.color !== '#FFFFFF' && row.start" > At {{row.start | date :'shortTime'}} </label>
                                           
                                            <label *ngIf="row.color == '#FFFFFF' && row.title !== 'No Appointments Available'" class="blColor">{{row.title }}, </label>
                                            <label class="blColor ml-1" *ngIf="row.color == '#FFFFFF' && row.start" > At {{row.start | date :'shortTime'}} </label>
                                        </div>
                                    </div> <!-- <img class="widthimage" src="../../assets/Screenshot 2022-02-07 at 1.03.07 PM.png"> -->
                                </div>
                            </div>
                        <!-- </div> -->
                      
                    </div>
                </div>
            
        </div>
        
    </div>
</div>