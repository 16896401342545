import { Component, Inject, OnInit } from "@angular/core";
import {FormGroup,FormControl,Validators,FormBuilder} from "@angular/forms";
import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  MatSnackBar,
} from "@angular/material/snack-bar";
import { MatDialog} from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { PopupTotalBillComponent } from "../popup-total-bill/popup-total-bill.component";
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeviceNotesComponent } from "../device-notes/device-notes.component";

@Component({
  selector: 'app-popup-device-details-global',
  templateUrl: './popup-device-details-global.component.html',
  styleUrls: ['./popup-device-details-global.component.scss']
})
export class PopupDeviceDetailsGlobalComponent implements OnInit {
  deviceDetailsGlobalForm: FormGroup; 
  holidayForm: FormGroup;
  date: any;
  listOfAppointment:any;
  totalLength:any; 
  appointmentId: any;
  size=5;
  page=1;
  searchText: any;
  submitButton = true;
  updateButton = false;
  allColor: any;
  
  filteredList: { name: string; value: string; }[];
  filteredListAppoinmentType: { name: string; value: string; }[];
  userType: string;
  branchRef: string;
  clinicRef: string;
  BranchList: any;
  isSuperAdmin= true;
  superBranchId: any;
  branchName: string;
  filteredListBranch: any;
  currentdate: Date;
  listOfBilling: any;
  id: any;
  courierList: any;
  filteredListCourier: any;
  disableFromCity = false;
  disableToCity = true;
  tocityList: any;
  cityList: any;
  fromCity: any;
  toCity: any;
  arr =[];
  courierServiceCreate = true;
  branchCreate = true;
  billingList: any;
  search: any;
  clinicHolidayList: any;
  deviceRefId: any;
  serialNo: any;
  vendorName: any;
  invoiceNo: any;
  invoiceDate :any;
  currentdate1: any;
  Devicedata: any;
  expDate: any;
  Devicedata2: any;
  deviceName: any;
  serialNumber: any;
  listOfRepair: any;
  model: any;
  billDate: any;
  billNo: any;
  pId: any;
  isExternal: any;
  todaydate: string;
  isExpty = false;
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
     public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<PopupDeviceDetailsGlobalComponent>,@Inject(MAT_DIALOG_DATA) public data:any,  
  ) {dialogRef.disableClose = true;
    this.deviceRefId = data.deviceRefId;
    this.serialNo = data.serialNo;
    this.model = data.model;   
    this.pId = data.pId;
    this.isExternal =data.isExternal; 
  }
  userPermission:any;
  billingCreate= true;
  billingUpdate= true;
   
  branchArr = [];
 
  ngOnInit(): void {
    // this.Devicedata=[
    //   { warrantyPeriod:'-'},
    // ];


    let currentdate = new Date();
    this.todaydate = currentdate.toISOString()
    this.currentdate1=this.datepipe.transform(currentdate, 'dd/MM/yyyy');
    this.branchArr = [];
    this.page=1;
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.userType = sessionStorage.getItem('userType');
    this.userType = sessionStorage.getItem('userType');

    this.deviceDetailsGlobalForm = this.fb.group({
      "vendorName": new FormControl(''),
      "receivedDate": new FormControl(''),
      "warrantyPeriod": new FormControl(''),
      "purchaseInvoiceNumber": new FormControl(''),
      "purchaseInvoiceDate": new FormControl(''), 
      "warrantyExpiry": new FormControl(''),
      "salePrice": new FormControl(''),
      "billNo": new FormControl(''), 
      "references": new FormControl(''),
    });
  this.getSalesDetails();
  this.getPurchaseDetails();
  this.fetchRepairList();
} 

getSalesDetails(){
  this.spinner.show();
  this.apimicroservice.getBillingDeviceInfoBySerialNo(this.serialNo,this.deviceRefId).subscribe(res => {
    this.spinner.hide();    
    this.Devicedata = []; 
     this.Devicedata.push(res.data[0])
     this.billDate = res.data[0].createdAt;
     this.billNo = res.data[0].billNo;
 
     let day = new Date(res.data[0].createdAt);
     let year = day.getFullYear();
     let month = day.getMonth();
     let datee = day.getDate();

   // this.expDate = new Date(this.Devicedata.expiryDate);
let edate= new Date(year + parseInt(res.data[0].warrantyPeriod), month, datee);
   this.expDate = edate.toISOString()
    res.data[0].deviceData.forEach(ele =>{
      if(ele.serialNumber == this.serialNo){
        this.vendorName = ele.vendorName;
        this.invoiceNo = ele.invoiceNo;
        this.invoiceDate = ele.invoiceDate;
      }
     });
   });
}
getPurchaseDetails(){
  this.spinner.show(); 
  
  this.apimicroservice.getPurchaseData(this.model,this.serialNo).subscribe(res => {
    this.spinner.hide();    
    this.Devicedata2 = [];  
     this.Devicedata2.push(res.data)
    //
    if(res.data.length>0)
    {
      this.isExpty = true;
    }
    else{
      this.isExpty = false;
    }
  
    res.data[0].forEach(ele =>{
        this.deviceName = ele.device.deviceName;
        this.serialNumber = ele.device.serialNumber;
     });
   });
}
  getClinicHolidays(){
    this.page = 1;
    this.search = '';
    this.id = '';
    let branchRef= sessionStorage.getItem('branchRef');
    this.apimicroservice.getClinicHolidays(this.page,this.size,this.search,this.id,branchRef).subscribe(res => {
      if (res.status) {   
        //
        this.clinicHolidayList = res.data.clinicalStaffData;
      //  this.totalLength = res.data.totalItems;
        this.apimicroservice.openSnackbar(res.message);
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    });
  }

  redirectToBillForm(receiptNo){
    this.router.navigate(['cheque-reconciliation',receiptNo]);
  }
  redirect(to): void {
    this.router.navigate([to]);
  } 

  prepopulation(id) {
    this.spinner.show();
    this.search= '';
    let branchRef= sessionStorage.getItem('branchRef');
    this.apimicroservice.getClinicHolidays(this.page,this.size,this.search,id,branchRef).subscribe(res => {
      this.spinner.hide();      
      let data = res.data.clinicalStaffData[0];
      //
       
      this.id = id;
       if(res.status){
         this.updateButton = true;
         this.submitButton = false;
         this.deviceDetailsGlobalForm = this.fb.group({
          "vendorName": new FormControl(data.vendorName),
          "receivedDate": new FormControl(data.receivedDate),
          "warrantyPeriod": new FormControl(data.warrantyPeriod),
          "purchaseInvoiceNumber": new FormControl(data.purchaseInvoiceNumber),
          "purchaseInvoiceDate": new FormControl(data.purchaseInvoiceDate), 
          "warrantyExpiry": new FormControl(data.branchName),
          "salePrice": new FormControl(data.branchName),
          "billNo": new FormControl(data.branchName),
          "references": new FormControl(data.branchName),
        });  
       }else{

       }
     });
  }
  openSnackbar(message: string) {
    return this._snackBar.open(message, "close", {
      verticalPosition: "bottom",
      horizontalPosition: "right",
      duration: 2000,
      panelClass: ["snackbarStyle"],
    });
  }
  onback() {
    this.apimicroservice.back()
  }
  onClose(): void {
    this.dialogRef.close(); 
  }
  fetchRepairList() {
    let pg = 1;
    let size = 5;
    let search=''; 
 //   this.spinner.show();
 let clinicRef= sessionStorage.getItem('clinicRef');
 let branchRef= sessionStorage.getItem('branchRef');
 let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
 let branchRefSuperAdmin= '';
    let patientRef='';
    this.searchText = '';

    if(this.userType !== 'Super Admin')
     {
     ////http://localhost:2220/api/repair/getRepairDataForGlobalSearch?page=1&size=3&search=&branchRef=&patientRef=6305c317799ba223ae948feb&leftSrNo=444&rightSrNo
 
      this.apimicroservice.getRepairDataForGlobalSearch(pg,size,search,branchRef,this.pId,this.serialNo,clinicRef).subscribe(res => {
        //
        this.listOfRepair = res.data.data;
        this.totalLength = res.data.totalItems;
        //
        
        //this.buildingLenght = this.listofbuild.length;
       if(res.status){
        //  this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
      });
     }else{
      this.apimicroservice.getRepairDataForGlobalSearch(pg,size,this.searchText,branchRefSuperAdmin,this.pId,this.serialNo,clinicRefSuperAdmin).subscribe(res => {
        //
        this.listOfRepair = res.data.data;
        this.totalLength = res.data.totalItems;
        //
        //this.buildingLenght = this.listofbuild.length;
       if(res.status){
        // this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
      });
     }
  } 
  onPageChange(event) {
    // this.spinner.show();
    this.page = event;
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    let patientRef='';
    let size = 5;
     this.searchText = '';
       
     if(this.userType !== 'Super Admin')
     {
      this.apimicroservice.getRepairDataForGlobalSearch(this.page,size,this.searchText,branchRef,this.pId,this.serialNo,clinicRef).subscribe(res => {
        //
        this.listOfRepair = res.data.data;
        this.totalLength = res.data.totalItems;
        //
       if(res.status){
       //  this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
  
     });
     }else{
      this.apimicroservice.getRepairDataForGlobalSearch(this.page,size,this.searchText,branchRefSuperAdmin,this.pId,this.serialNo,clinicRefSuperAdmin).subscribe(res => {
        //
        this.listOfRepair = res.data.data;
        this.totalLength = res.data.totalItems;
        //
        
       if(res.status){
      //   this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
  
     });
     }

     

  } 
  onKeyUpEvent(event: any) {
    
    this.searchText = '';
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    let patientRef='';
    if(this.userType !== 'Super Admin')
    {
      this.apimicroservice.getRepairDataForGlobalSearch(this.page,this.size,this.searchText,branchRef,this.pId,this.serialNo,clinicRef).subscribe(res => {
        //
        this.listOfRepair = res.data.data;
        this.totalLength = res.data.totalItems; 
       if(res.status){
       //  this.apimicroservice.openSnackbar(res.message); 
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       } 
     });
    }else{
      this.apimicroservice.getRepairDataForGlobalSearch(this.page,this.size,this.searchText,branchRefSuperAdmin,this.pId,this.serialNo,clinicRefSuperAdmin).subscribe(res => {
        //
        this.listOfRepair = res.data.data;
        this.totalLength = res.data.totalItems; 
       if(res.status){
       //  this.apimicroservice.openSnackbar(res.message); 
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     }); 
    } 
  }
  openNotesPopUp() {
     const dialogRef3 = this.dialog.open(DeviceNotesComponent,
       {
         width: "1000px",
          data: {
           deviceRefId:this.deviceRefId,
           deviceName:this.model,
           pId:this.pId,
         }
         // height: "570px"
       }); 
       // dialogRef3.afterClosed().subscribe((result) => {
       //   this.fetchBrandList();
       // })
   }
}