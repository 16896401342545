<div class="container-fluid " >
  <div class="row" [formGroup]="deviceDashboardForm">
    <div class="col-sm-12">
      
      <form autocomplete="off" [formGroup]="deviceDashboardForm" novalidate>
      <div class="row">
      
        <div class="col-sm-12 pr-4">
          <div class="row ">
            <div class="col-sm-12 pr-4">
              <div class="row"> 

                <div class="card divbody mb-3 boxsssss borderradius p-1">
           
                    <div class="col-sm-12"> 
                        <div class="row">
                          <div class="col-sm-12">
                         
                            <div class="col-sm-12 mt-3 pl-0 pr-3">
                              <div class="row">
                                      <div class="col-sm-12">
                                          <div class="row">
                                              <div class="col-sm-9 ">
                                                  <h1 class="titlecolor"> Device List</h1>  
                                              </div>
                                              <div class="search-box col-sm-3 pb-2"> 
                                                <input class="form-control amber-border" type="text" placeholder="Search" 
                                                formControlName="searchDevice" aria-label="Search" (keyup)="onKeyUpEvent($event)"
                                                > 
                                                <div class="input-group-append">
                                                   <span class="input-group-text amber lighten-3" id="basic-text1"><span class="material-symbols-outlined">search</span></span>
                                                </div> 
                                             </div> 
                                             <div class="col-sm-12">
                                              <div class="row">
                                                <div class="col-sm-6 table-responsive">
                                                <h4 class="internalDevice mb-0 pl-2 pt-1">Fitted By Us :</h4>  
                                        
                                                  <table id="toPatientList"
                                               class="table table-bordered data-table-custom">
                                              
                                  <thead>
                                    <tr class="text-center">
                                      <!-- <th>Device ID</th> -->
                                      <!-- <th *ngIf="userType == 'Super Admin'">Branch Name</th> -->
                                      <th>Billing Company</th>
                                      <th>Model</th>
                                      <!-- <th>Device Type</th> -->
                                      <th>Original Sr.No</th>
                                      <th>New Sr.No</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                         
                                    <tr
                                    *ngFor="let item of devicelist | paginate : { id:'devicepaginate', 
                                    itemsPerPage: size, currentPage: page, totalItems:totalLength } "
                                      role="row"
                                      class="odd text-center"   
                                      (click)="openDetailsPopUp(item.deviceRefId,item.serialNumber,item.model,0)" style="cursor:pointer; text-align: center " >
                                      <!-- <td  matTooltip="click to view">{{item.deviceData[0].billingCompanyName}}</td> -->

                                      <td class="labelStyle" matTooltip="Click To View" >
                                        <label *ngIf="item.deviceData[0]; else elseBlock">{{item.deviceData[0].billingCompanyName}}</label>
                          
                                      <ng-template #elseBlock>
                                        <label>-</label>
                                      </ng-template> 

                                        </td>

                                      <td  matTooltip="click to view">{{item.model}}</td>
                                      <!-- <td  matTooltip="click to view">{{item.deviceData[0].deviceType}} -->
                                      <td  matTooltip="click to view">{{item.serialNumber}}</td>
                                      <td  matTooltip="click to view">{{item.changeSerialNumber}}</td>
                                      <!-- <td  matTooltip="click to view">{{item.deviceData[0].deviceType}}</td>
                                      <td  matTooltip="click to view">{{item.deviceData[0].batteryType}}</td> -->
                                    </tr>
                                  </tbody>
                                </table>
                                <div class="col-sm-12 pt-0 text-right">
                                  <pagination-controls id="devicepaginate" directionLinks="true"  
                                  (pageChange)="onPageChange($event)" 
                                    class="float-right pt-3 my-pagination"></pagination-controls>
                                    </div>
                                              </div>
                                              <div class="col-sm-6 table-responsive">
                                               
                                                  <h4 class="externalDevice mb-0 pl-2 pt-1">Not Fitted By Us :</h4>
                                                  <table id="toPatientList"
                                                  class="table table-bordered data-table-custom">
                                                
                                    <thead>
                                      <tr class="text-center">
                                        <!-- <th>Device ID</th> -->
                                        <!-- <th *ngIf="userType == 'Super Admin'">Branch Name</th> -->
                                        <th>Brand</th>
                                        <th>Model</th>
                                        <th>Serial Number</th>
                                        <!-- <th>Device Type</th> -->
                                        <th>Warranty</th>
                                    
                                      </tr>
                                    </thead>
                                    <tbody>
                           
                                      <tr
                                      *ngFor="let item of devicelist2 | paginate : { id:'devicepaginate2', 
                                      itemsPerPage: size1, currentPage: page1, totalItems:totalLength1 } "
                                        role="row" (click)="openDetailsPopUp(item.deviceRefId,item.newSerialNo,item.model,1)"
                                        class="odd text-center" 
                                        style="cursor:pointer; text-align: center " >
                    
                                        <td  matTooltip="click to view">{{item.brandLable}}</td>
                                        <td  matTooltip="click to view">{{item.model}}</td>
                                        <td matTooltip="click to view">{{item.newSerialNo}}</td>
                                        <!-- <td matTooltip="click to view">{{item.deviceType}}</td> -->
                                        <td matTooltip="click to view">{{item.warranty}}</td>
                                       
                                      </tr> 
                                    </tbody>
                                  </table>
                                  <div class="col-sm-12 pt-0 text-right">
                                    <pagination-controls id="devicepaginate2" directionLinks="true"  
                                    (pageChange)="onPageChange2($event)" 
                                      class="float-right pt-3 my-pagination"></pagination-controls>
                                      </div>
                                                </div>
                                               
                                                  </div>
                                                  </div>
                                          </div>
                                      </div>
                                 
                              </div>
                              
                          </div>


            </div>
                      
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
      </div>
      </div>
      
   
  
    </form>
   
</div>
  </div>
</div>