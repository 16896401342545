<div class="container-fluid " [formGroup]="deviceDetailsGlobalForm">
  <form [formGroup]="deviceDetailsGlobalForm" autocomplete="off" novalidate>
    <div class="row">
        <div class="col-sm-12">
            <div class="row"> 
                <div class="col-sm-12">
                    <div class="row">
                                                                                                                                                                                                                                           
                            <div class="col-sm-12">
                                <div class="row">
                                  <div class="col-sm-10">
                                      <h1 class="titlecolor" >Device Details</h1>  
                                  </div>
                                  <div class="col-sm-2 mt-1"> 
                                    <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                                      <a *ngIf="isExternal !== 1" (click)="openNotesPopUp()" class="d-flex align-items-center ms-3 pr-2 mt-2"><span class="material-symbols-outlined pr-2">notes</span>Notes</a>
                                      <a (click)="onClose()" class="d-flex align-items-center ms-3 pr-2 mt-2"><span class="material-symbols-outlined pr-2">close</span></a>
                               </div>
                            </div> 
                            <div *ngIf="isExternal !== 1 && isExpty == true" class="col-sm-12 table-responsive">
                              <h4 class="externalDevice mb-0 mt-0">Purchase Details :</h4>
                              <table id="toDeviceList"  
                           class="table table-bordered data-table-custom">   
                <thead>
                <tr class="text-center">
                  <th>Bill No</th>
                  <th>Bill Date</th>
                  <th>Vendor Name</th>
                  <th>Device Name</th>
                  <th>Serial No</th>
                  <th>Invoice No</th>
                  <!-- <th>Vendor Name</th>
                  <th>Invoice Number</th>
                  <th>Invoice Date</th>
                  <th>Device Name</th>
                  <th>Serial Number</th> -->
                </tr>
                </thead>
                <tbody>
                <tr
                *ngFor="let item of Devicedata2"
                  role="row" 
                  class="odd text-center" 
                   style="cursor:pointer; text-align: center " >
                  <td  matTooltip="click to view">{{billNo}}</td>
                  <td  matTooltip="click to view">{{billDate | date:'dd/MM/yyyy'}}</td>
                  <!-- <td  matTooltip="click to view">{{item.receivedDate | date:'dd/mm/yyyy'}}</td>
                  <td  matTooltip="click to view">{{item.warrantyPeriod}}</td> -->
                  <!-- <td  matTooltip="click to view">{{item[0].invoiceNo}}</td> -->
                  <td  matTooltip="click to view">{{item[0].vendorName}}</td>
                  <!-- <td  matTooltip="click to view">{{item[0].invoiceDate | date:'dd/mm/yyyy'}}</td> -->
                  <td  matTooltip="click to view">{{model}}</td>
                  <td  matTooltip="click to view">{{serialNo}}</td>
                  <td  matTooltip="click to view">{{invoiceNo}}</td>
                </tr>
                </tbody>
                </table>
                </div>
 
                                 <div *ngIf="isExternal !== 1" class="col-sm-12 table-responsive">
                                  <h4 class="externalDevice mb-0 mt-0">Sales Details :</h4>
                                  <table id="toDeviceList"  
                               class="table table-bordered data-table-custom">   
                    <thead>
                    <tr class="text-center">
                      <th>Bill No</th>
                      <th>Bill Date</th>
                      <th>Device Name</th>
                      <th>Serial No</th>
                      <th>Warranty</th>
                      <th>Sales Rate</th>
                      <th>Discount</th>
                      <!-- <th>Vendor Name</th>
                      <th>Received Date</th>
                      <th>Warranty Period</th>
                      <th>Invoice Number</th>
                      <th>Invoice Date</th>
                      <th>Warranty Expiry</th>
                      <th>Sale Price</th>
                      <th>Bill No</th>
                      <th>References</th> -->
                    </tr>
                    </thead>
                    <tbody> 
                    <tr
                    *ngFor="let item of Devicedata"
                      role="row" 
                      class="odd text-center" 
                       style="cursor:pointer; text-align: center " >
                      <td  matTooltip="click to view">{{billNo}}</td>
                      <td  matTooltip="click to view">{{billDate | date:'dd/MM/yyyy'}}</td>
                      <td  matTooltip="click to view">{{model}}</td>
                      <td  matTooltip="click to view">{{item.serialNumber}}</td>
                      <td class="labelStyle" matTooltip="Click To View" >
                        <label *ngIf="todaydate <= expDate && item.paymentStatus == 'Paid'; else elseBlock">Yes</label>
                      <ng-template #elseBlock>
                        <label>No</label>
                      </ng-template> 

                        </td>
                      <td  matTooltip="click to view">{{item.totalServiceAmount}}</td>
                      <td  matTooltip="click to view">{{item.discount}}</td>
                    </tr>
                    </tbody>
                    </table>
                    </div>
                   
        <div class="col-sm-12 table-responsive">
          <h4 class="externalDevice mb-0 mt-0">Repair Details :</h4>
          <table id="toRepairList" 
          class="table table-bordered data-table-custom">
<thead>
<tr class="text-center">
<th>Repair ID</th>
<th>Date</th>
<th>Patient Name</th>
<th>Mobile No.</th>
<!-- <th *ngIf="userType == 'Super Admin'">Branch Name</th> -->
<th>Left Serial No</th>
<th>Right Serial No</th>
<th>Left Model </th>
<th>Right Model </th>


</tr>
</thead>
<tbody>

<tr *ngFor="let item of listOfRepair | paginate : { id:'repairpaginate',
itemsPerPage: size, currentPage: page, totalItems:totalLength }"
class="repairtb text-center" (click)="prepopulation(item._id)" 
> 
<td  matTooltip="click to view"
>{{item.branchWiseId}}</td>
<td matTooltip="click to view">{{item.repairDate | date :'d/M/yy'}}</td>
<td matTooltip="click to view">{{item.firstName}}  {{item.lastName}}</td>
<td matTooltip="click to view">{{item.mobile1}}</td>
<!-- <td *ngIf="userType == 'Super Admin'" matTooltip="click to view" 
>{{item.branchName}}</td> -->
<td *ngIf="item.leftSrNo == ''" matTooltip="click to view">-</td>
<td *ngIf="item.leftSrNo !== ''" matTooltip="click to view">{{item.leftSrNo}}</td>
<td *ngIf="item.rightSrNo == ''" matTooltip="click to view">-</td>
<td *ngIf="item.rightSrNo !== ''" matTooltip="click to view">{{item.rightSrNo}}</td>
<td *ngIf="item.leftModel == ''" matTooltip="click to view">-</td>
<td *ngIf="item.leftModel !== ''" matTooltip="click to view">{{item.leftModel}}</td>
<td *ngIf="item.rightModel == ''" matTooltip="click to view">-</td>
<td *ngIf="item.rightModel !== ''" matTooltip="click to view">{{item.rightModel}}</td>



</tr>
</tbody>
</table> 
<!-- <div class="col-sm-12 pt-0 text-right">
  <pagination-controls id="repairpaginate" directionLinks="true"  
  (pageChange)="onPageChange($event)" 
    class="float-right pt-3 my-pagination"></pagination-controls>
</div> -->
</div> 


                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </form>
</div> 