import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
 import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss']
})
export class ReceiptComponent implements OnInit {
  receiptForm: FormGroup;
  date: any;
  listOfAppointment:any;
  totalLength:any;
  appointmentId: any;
  size=5;
  page=1;
  searchText: any;
  submitButton = true;
  updateButton = false;
  allColor: any;
  
  filteredList: { name: string; value: string; }[];
  filteredListAppoinmentType: { name: string; value: string; }[];
  userType: string;
  branchRef: string;
  clinicRef: string;
  BranchList: any;
  isSuperAdmin= true;
  superBranchId: any;
  branchName: string;
  filteredListBranch: any;
  currentdate: Date;
  listOfBilling: any;
  id: any;
  courierList: any;
  filteredListCourier: any;
  disableFromCity = false;
  disableToCity = true;
  tocityList: any;
  cityList: any;
  fromCity: any;
  toCity: any;
  courierServiceCreate = true;
  branchCreate = true;
  filteredListPatient: any;
  patientList: any;
  printData=[
    {
      srNo:1,
      date:'12/03/2001',
      serviceName:'Test',
      companyName:'A Company',
      rate:100,
     },
     {
      srNo:2,
      date:'12/03/2001',
      serviceName:'Test',
      companyName:'A Company',
      rate:200,
     },
     {
      srNo:3,
      date:'12/03/2001',
      serviceName:'Test 2',
      companyName:'A Company',
      rate:100,
     },
     {
      srNo:4,
      date:'12/03/2001',
      serviceName:'Test 3',
      companyName:'A Company',
      rate:150,
     },                 
  ]
  billNo: any;
  patientName: any;
  billingList: any;
  totalAmount: any;
  discount: any;
  headerData: any;
  reportName: any;
  reportAddress: any;
  district: any;
  taluka: any;
  state: any;
  country: any;
  reportPinCode: any;
  reportPhoneNumber: any;
  openTime: any;
  closeTime: any;
  logoimg: any;
  footerData: any;
  billData: any;
  gst: any;
  balanceAmount: any;
  billRefId: any;
  recepitNo: string;
  totalAmountInWords: any;
  reportWeeklyOff: any;
  emailAdd: any;
  website: any;
  reportPhoneNumber1: any;
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
     public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute, 
  ) { }
  userPermission:any;
  billingCreate= true;
  billingUpdate= true;

  ngOnInit(): void {
    this.currentdate = new Date();
    this.page=1;
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.userType = sessionStorage.getItem('userType');
    this.billNo = this.route.snapshot.paramMap.get('billNo');
    this.recepitNo = this.route.snapshot.paramMap.get('receiptNo');
    //
    //
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    if(this.userType !== 'Super Admin'){
      this.isSuperAdmin= false;
    this.userPermission.forEach((element) => {
      if(element.pageName == 'Print Bill' && element.create == false)
      {
          this.billingCreate = false;  
      }
      if(element.pageName == 'Print Bill' && element.update == false)
      {
          this.billingUpdate = false;  
      }  
    });
  }
    this.receiptForm = this.fb.group({
 
      "searchBilling": new FormControl(''), 
      
    });
    //  this.fetchReceiptData(); 
    //  this.getReportHeaderGenericAllData();
    //  this.getReportFooterGenericAllData();

     let bar = new Promise<void>((resolve, reject) => {
      this.apimicroservice.getPaymentHistoryByReceiptNo(this.recepitNo).subscribe(res => {
       this.billRefId = res.data[0].billingCompanyRefId;
        resolve();
      });
    });
   bar.then(()=>{
    this.fetchReceiptData(); 
    this.getReportHeaderGenericAllData();
    this.getReportFooterGenericAllData();
   });

}

// fetchBillData(){
//   this.apimicroservice.getBillDataById(this.billNo).subscribe(res => {
//     //
//    let data = res.data[0];
//    this.billData = data;
//    this.patientName = data.patientName;
//    this.billingList = data.serviceInfo;
//    this.totalAmount = data.totalAmount;
//    const numWords = require('num-words');
//     this.totalAmountInWords = numWords(this.billData.paidAmount);
//     //
    
//    this.gst = data.gst;
//    if(data.balanceAmount !== undefined){
//     this.balanceAmount = data.balanceAmount;
//    }else{
//     this.balanceAmount = this.totalAmount;
//    }

//    if(data.totalDiscount !== undefined){
//     this.discount = data.totalDiscount;
//    }else{
//     this.discount = 0;
//    }

//    this.billRefId = data._id;
//    //
//    if(data !== undefined){
//     data.serviceInfo.forEach((element,i) => {
//       if(this.discount !== null){
//         this.discount = this.discount + element.discount;
//       }
//     });
    
//    }
//    //
//   });
// }
fetchReceiptData(){
  this.apimicroservice.getPaymentHistoryByReceiptNo(this.recepitNo).subscribe(res => {
    //
   let data = res.data[0];
   this.billData = data;
   this.patientName = data.patientName;
   this.billingList = data.serviceInfo;
   this.totalAmount = data.totalAmount;
   const numWords = require('num-words');
   this.totalAmountInWords = numWords(this.billData.paidAmount);
  //  this.totalAmountInWords = numWords(this.billData.paidAmount).toUpperCase();
   //
   this.gst = data.gst;
   if(data.balanceAmount !== undefined){
    this.balanceAmount = data.balanceAmount;
   }else{
    this.balanceAmount = this.totalAmount;
   }

   if(data.totalDiscount !== undefined){
    this.discount = data.totalDiscount;
   }else{
    this.discount = 0;
   }

   this.billRefId = data._id;
   //
   if(data !== undefined){
    data.serviceInfo.forEach((element,i) => {
      if(this.discount !== null){
        this.discount = this.discount + element.discount;
      }
      
    //  this.addNew(element,i)
    });
    
   }
   //
  });
}
printThisPage() {
  window.print();
}
getReportHeaderGenericAllData() 
{
  // this.apimicroservice.getReportHeaderGenericAllData().subscribe(res => {
  //   if(res.status){
  //     this.apimicroservice.openSnackbar(res.message);  
  //     this.headerData = res.data;
  //     // 
  //     if(res.data.length > 0){
  //       this.reportName = this.headerData[0].reportName;
  //       this.reportAddress = this.headerData[0].reportAddress;
  //       this.reportWeeklyOff = this.headerData[0].reportWeeklyOff;
  //       this.emailAdd = this.headerData[0].reportEmail;
  //       this.website = this.headerData[0].reportWebsite;
  //       this.taluka = this.headerData[0].taluka;
  //       this.district = this.headerData[0].district;
  //       this.state = this.headerData[0].state;
  //       this.country = this.headerData[0].country;
  //       this.reportPinCode = this.headerData[0].reportPinCode;
  //       this.reportPhoneNumber = this.headerData[0].reportPhoneNumber;
  //       this.reportPhoneNumber1 = this.headerData[0].reportPhoneNumber1;
  //       this.openTime = this.headerData[0].openTime;
  //       this.closeTime = this.headerData[0].closeTime;
  //       this.logoimg = res.data[0].uploadLogo;

  //     }else{
  //       this.reportName = "";
  //       this.reportAddress = "";
  //       this.reportWeeklyOff ="";
  //       this.taluka = "";
  //       this.district = "";
  //       this.state = "";
  //       this.country = "";
  //       this.reportPinCode = "";
  //       this.reportPhoneNumber = "";
  //       this.reportPhoneNumber1 = "";
  //       this.openTime = "";
  //       this.closeTime = "";
  //       this.emailAdd = "";
  //       this.website = "";
  //       this.logoimg = "";
  //     }
  //     if(this.logoimg == "" || this.logoimg == '../../assets/clinic_logoDemo.jpeg')
  //     this.logoimg = "../../assets/clinic_logo3.jpg"
  //     //
  //   }else{ 
  //     //this.apimicroservice.openSnackbar(res.message);
  //   }
  // });
  //
  this.apimicroservice.getHeaderDataByBillingRefId(this.billRefId).subscribe(res => {
    // let data = res.data[0]; 
    // this.companyGst = data.companyGST;
    // this.bankName = data.bankName;
    // this.bankBranchName = data.bankBranchName;
    // this.accountNumber = data.accountNumber;
    // this.ifcCode = data.ifcCode;
    // this.upiId = data.upiId;   
    this.headerData = res.data;
    //
    if(res.data.length > 0){
      this.reportName = this.headerData[0].reportName;
      this.reportAddress = this.headerData[0].reportAddress;
      this.reportWeeklyOff = this.headerData[0].reportWeeklyOff;
      this.emailAdd = this.headerData[0].reportEmail;
      this.website = this.headerData[0].reportWebsite;
      this.taluka = this.headerData[0].taluka;
      this.district = this.headerData[0].district;
      this.state = this.headerData[0].state;
      this.country = this.headerData[0].country;
      this.reportPinCode = this.headerData[0].reportPinCode;
      this.reportPhoneNumber = this.headerData[0].reportPhoneNumber;
      this.reportPhoneNumber1 = this.headerData[0].reportPhoneNumber1;
      this.openTime = this.headerData[0].openTime;
      this.closeTime = this.headerData[0].closeTime;
      this.logoimg = res.data[0].uploadLogo;

    }else{
      this.reportName = "";
      this.reportAddress = "";
      this.reportWeeklyOff ="";
      this.taluka = "";
      this.district = "";
      this.state = "";
      this.country = "";
      this.reportPinCode = "";
      this.reportPhoneNumber = "";
      this.reportPhoneNumber1 = "";
      this.openTime = "";
      this.closeTime = "";
      this.emailAdd = "";
      this.website = "";
      this.logoimg = "";
    }
  }); 
}
getReportFooterGenericAllData()
{
  let clinicRef= sessionStorage.getItem('clinicRef');
  let branchRef= sessionStorage.getItem('branchRef');
  let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
  let branchRefSuperAdmin= '';
  if (this.userType !== 'Super Admin') {
  this.apimicroservice.getReportFooterGenericAllData(branchRef,clinicRef).subscribe(res => {
    if(res.status){
    //  this.apimicroservice.openSnackbar(res.message);
      this.footerData = res.data;
      //
    }else{
      //this.apimicroservice.openSnackbar(res.message);
    }
  });
}else{
  this.apimicroservice.getReportFooterGenericAllData(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
    if(res.status){
    //  this.apimicroservice.openSnackbar(res.message);
      this.footerData = res.data;
      //
    }else{
      //this.apimicroservice.openSnackbar(res.message);
    }
  });
}
}
}
