<div class="container-fluid " [formGroup]="BillingForm">
  <div class="row">
      <div class="col-sm-12">
          <div class="row">
              <div class="col-sm-9">
                  <div class="row ">
  
                      <!-- <span><img src="../../assets/bill.png" width="35rem"> </span>  -->
                      <span> <h1 class="fass ml-3 pt-1" id="toBillingForm">Total Bill</h1></span>
                    </div> 
                   
                  </div>
                  <div class="icon-btns col-sm-3 justify-content-end">
                    <ul class="mb-2 justify-content-end"> 
                      <!-- <span class="material-symbols-outlined">
view_in_ar 
</span>-->
                       <li> 
                          <a class="icon-box2" data-toggle="tooltip" data-placement="top" 
                          (click)="openServicePopUp()" matTooltip="Service Bill">
                          <span class="material-symbols-outlined"> 
                            request_quote
                            </span>
                          <img src="../../assets/icon-bg10.svg" class="icon-bg2">
                          </a>
                       </li> 
                       <li> 
                        <a class="icon-box2" data-toggle="tooltip" data-placement="top" 
                        (click)="openDevicePopUp()" matTooltip="HA Bill">
                        <span class="material-symbols-outlined">
                          view_in_ar
                          </span> 
                        <img src="../../assets/icon-bg5.svg" class="icon-bg2">
                        </a>
                     </li>
                       <li> 
                        <a (click)="onClose()" matTooltip="Close" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">close</span></a>
                     </li>
                    </ul>
                 </div>
                  <!-- <div class="col-sm-2 justify-content-end" > 
                    <div class="row pt-1 pb-1 justify-content-end">
                    
                      <img matTooltip="Add Service" (click)="openServicePopUp()" width="30rem" src="../../assets/service.png">
                       
                    <img src="../../assets/smart-earing.png"  (click)="openDevicePopUp()" matTooltip="Add Device" width="30rem">
                    <a (click)="onClose()" matTooltip="Close" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">close</span></a>
                   
                    </div>
                </div> -->
                  <!-- <div class="col-sm-1" > 
                
                      <div class="row justify-content-end ">
                        <img matTooltip="Add Service" class="mb-2 pb-2"  (click)="printForm()" width="35rem" src="../../assets/service.png">          
                        <img src="../../assets/smart-earing.png"  (click)="toBillingLst()" class="mb-2 pb-2" matTooltip="Add Device" width="35rem">
                     </div>
                     </div> -->
                
            </div>
            <div class="col-sm-12 pl-0">
           
                              <div class="row">
                                <div class="col-sm-12 table-responsive">
                                  <table id="toBillingLst" 
                               class="table table-bordered data-table-custom">
                                          <thead>
                                              <tr class="text-center">
                                                  <!-- <th></th> -->
                                                   <th>Bill No.</th>
                                                   <th>Billing Company</th>
                                                  <th>Total Bill Amount</th>
                                                  <th>Status</th>
                                                  <!-- <th class="widthh">Action</th> -->
                                              
                                        
                                              </tr>
                                          </thead> 
                                          <tbody> 
                                              <tr *ngFor="let item of billingList | paginate : { id:'totalbillingMaster',
                                              itemsPerPage: size, currentPage: page, totalItems:totalLength }" 
                                              (click)="prepopulation(item.billNo)"
                                               (click)="toBillingForm()" 
                                               style="cursor:pointer; text-align: center ">
                                               <!-- <td ></td> -->
                                               <td  
                                               >{{item.billNo}}
                                             </td>
                                             <!-- | date -->
                                             <td >{{item.billingCompanyName}}</td>
                                             <td >{{item.totalAmount}}</td>
                                             <td *ngIf="item.paymentStatus == 'Paid'" class="green">          
                                              {{item.paymentStatus}}
                                               </td>
                                               <td matTooltip="Click To View" *ngIf="item.paymentStatus == 'Unpaid'" class="red">          
                                                {{item.paymentStatus}}
                                                 </td> 
                                                  </tr>
                                          

                                          </tbody>
                                          <thead>
                                              <tr class="text-center">
                                                <th colspan="3" class="text-right">Total</th>
                                                <th >₹ {{totalBillAmt}}.00</th>
                                              
                                              </tr>
                                            </thead>
                                      </table>
                                      
                                  </div>
                                  <div class="col-sm-12 pt-0 text-right">
                                      <pagination-controls id="totalbillingMaster" directionLinks="true"
                                        (pageChange)="onPageChange($event)" class="float-right pt-3 my-pagination">
                                      </pagination-controls>
                                    </div>
                                  <div class="col-sm-10 ">
                                  </div>
                                
                         
                              </div>
                     
                

              </div>
          

      </div>
  </div>
</div> 