import { Component, OnInit } from "@angular/core";
import {
  FormGroup, FormControl, Validators, FormBuilder,
} from "@angular/forms";
import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from "@angular/material/snack-bar";
import { AfterViewInit, Directive, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EventEmitter, Output } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { PopUpBranchComponent } from "../pop-up-branch/pop-up-branch.component";
import { PopUpServicetypeComponent } from "../pop-up-servicetype/pop-up-servicetype.component";
import { PopupTestTypeComponent } from "../popup-test-type/popup-test-type.component";

@Component({
  selector: 'app-services-master',
  templateUrl: './services-master.component.html',
  styleUrls: ['./services-master.component.scss']
})
export class ServicesMasterComponent implements OnInit {
  @Output() next = new EventEmitter<void>();
  errormessage: string;
  ServiceData: any;
  servicesId: any;
  prep: any;
  size = 10;
  page = 1;
  totalLength: any;
  searchText: any;
  branchRef: string;
  clinicRef: string;
 
  userType: string;
  superBranchId: any;
  branchName: string;
  filteredListBranch: any;
  testTypeArr: any;

  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService
  ) { }
  servicesMasterForm: FormGroup;
  status: any;
  submitButton = true;
  updateButton = false;
  deleteButton = false;
  BranchList: any;

  resetButton = true;
  cancelButton = false;
  loggedId: any;
  myimage;
  date: any; 
  userPermission:any;
  servicesCreate= true;
  servicesUpdate= true;
  isSuperAdmin= true;
  // //serviceTypeArr=[
  //   {name:'Diagnostic Test',value:'Diagnostic Test' },
  //   {name:'Repairs',value:'Repairs' },
  //   {name:'Consultation',value:'Consultation' },
  //   {name:'Aid Adjustment',value:'Aid Adjustment' },
  //   {name:'Service',value:'Service' },
  //   {name:'SLP',value:'SLP' },
   
  // ]
  serviceTypeArr = [];
  filteredList = [];
  // testTypeArr=[
  //   {name:'PTA',value:'PTA' },
  //   {name:'Impedance',value:'Impedance' },
  //   {name:'BERA',value:'BERA' },
  //   {name:'OAE',value:'OAE' },
  //   {name:'Video Otoscopy',value:'Video Otoscopy' },
  // ] 
  //filteredList: { name: string; value: string; }[];
  filteredList1 = [];
  ngOnInit() {
    this.page=1;
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.userType = sessionStorage.getItem('userType');
    //this.filteredList = this.serviceTypeArr.slice();
   // this.filteredList1 = this.testTypeArr.slice();
    this.date = new Date();
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    if(this.userType !== 'Super Admin'){
      this.isSuperAdmin= false;
    this.userPermission.forEach((element) => {
      if(element.pageName == 'Services' && element.create == false)
      {
          this.servicesCreate = false;  
      }
      if(element.pageName == 'Services' && element.update == false)
      {
          this.servicesUpdate = false;  
      }  
    });
  }
    this.servicesMasterForm = this.fb.group({
      "branchWiseId": new FormControl(''),
      "serviceType": new FormControl('',[Validators.required]),
      "testType": new FormControl('',Validators.required),
      "createdAt": this.date,
      "searchService": new FormControl(''),
      "branchRefId": new FormControl(),
      "clinicRefId": new FormControl(),
      "branchName": new FormControl(),
      
    });
    this.fetchServices();
    this.getLastId();
    //this.removeValidator();
   // this.fetchBranchList();
    this.fetchServiceTypeList();
   // this.getAllBranch();
    this.getTestTypeAllData();
  }
  getAllBranch()
  {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      if (res.status) {        
        this.servicesMasterForm.get("branchRefId").setValue(res.data[0]._id);
        this.branchClick(res.data[0]._id,this.branchName);
        if(this.userType == 'Super Admin')
        {  
          this.apimicroservice.getBranchById(res.data[0]._id).subscribe(res => {
            this.spinner.hide();
            let data = res.data[0];
            this.servicesMasterForm.get("branchName").setValue(res.data[0].branchName);
           });
        }
       
      }
    });
  }


  setValidator() { 
    this.servicesMasterForm.controls['serviceType'].setValidators(Validators.required);
    this.servicesMasterForm.controls['serviceType'].updateValueAndValidity();
  }

  removeValidator() { 
    this.servicesMasterForm.controls['serviceType'].setErrors(null);
    this.servicesMasterForm.controls['serviceType'].clearValidators();
    this.servicesMasterForm.controls['serviceType'].updateValueAndValidity();
  }

  submitService() {
    // this.setValidator()
    //  if(this.userType == 'Super Admin')
    // {
    //   this.servicesMasterForm.controls['branchRefId'].setValidators(Validators.required);
    //   this.servicesMasterForm.controls['branchRefId'].updateValueAndValidity();
    // }
    // else{
    //   this.servicesMasterForm.controls['branchRefId'].setErrors(null);
    //   this.servicesMasterForm.controls['branchRefId'].clearValidators();
    //   this.servicesMasterForm.controls['branchRefId'].updateValueAndValidity();
    // }
    if (this.servicesMasterForm.invalid) {
      this.servicesMasterForm.get("serviceType").markAsTouched();
      this.servicesMasterForm.get("testType").markAsTouched();
      return;
    } else {

      this.submitData();

    }

  }

  updateServiceData() {
    //this.setValidator();
    // if(this.userType == 'Super Admin')
    // {
    //   this.servicesMasterForm.controls['branchRefId'].setValidators(Validators.required);
    //   this.servicesMasterForm.controls['branchRefId'].updateValueAndValidity();
    // }
    // else{
    //   this.servicesMasterForm.controls['branchRefId'].setErrors(null);
    //   this.servicesMasterForm.controls['branchRefId'].clearValidators();
    //   this.servicesMasterForm.controls['branchRefId'].updateValueAndValidity();
    // }
    if (this.servicesMasterForm.invalid) {
      this.servicesMasterForm.get("serviceType").markAsTouched();
      this.servicesMasterForm.get("testType").markAsTouched();
      return;
    } else {

      this.updateData();

    }
  }

  submitData() {
    this.spinner.show();
    let payload = this.servicesMasterForm.value;
    //
    payload.createdAt = new Date();
    payload.clinicRefId = this.clinicRef;
    payload.branchRefId = this.branchRef;
    // if(this.userType !== 'Super Admin')
    // {
    //   payload.branchRefId = this.branchRef;
    //   payload.clinicRefId = this.clinicRef;
    //   payload.branchName = this.branchName;
     
    // }
   

    this.apimicroservice.submitServices(payload).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.apimicroservice.openSnackbar(res.message);
        this.ngOnInit();
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }

    },(ERROR:HttpErrorResponse) => {
      this.apimicroservice.openSnackbar(ERROR.error.message);
     this.spinner.hide();
  });
  }


  updateData() {
    let payload = this.servicesMasterForm.value;
   // payload.image = this.myimage;
   this.spinner.show();
   payload.clinicRefId = this.clinicRef;
  //  if(this.userType !== 'Super Admin')
  //    {
  //      payload.branchRefId = this.branchRef;
  //      payload.clinicRefId = this.clinicRef;
  //      payload.branchName = this.branchName;
  //    }
    

    this.apimicroservice.updateServices(this.servicesId, payload).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.apimicroservice.openSnackbar(res.message);
        this.submitButton = true;
        this.updateButton = false;
        this.cancelButton=false;
        this.ngOnInit();
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    },(ERROR:HttpErrorResponse) => {
      this.apimicroservice.openSnackbar(ERROR.error.message);
     this.spinner.hide();
  });
  }

  fetchServices() {
  
    let pg = 1;
    let size = 10;
    let search='';
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
   
    
   
    this.spinner.show();

    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getServiceData(pg, size, search,branchRef,clinicRef).subscribe(res => {
        this.spinner.hide();
        this.ServiceData = res.data.data;
        this.totalLength = res.data.totalItems;
        if(res.status){
         // this.apimicroservice.openSnackbar(res.message);
          
        }else{
          //this.apimicroservice.openSnackbar(res.message);
        }
      });
    } else {
      this.apimicroservice.getServiceData(pg,size,search,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
        this.spinner.hide();
        //
        this.ServiceData = [];
        if(res.data.data.length > 0)
        {
          this.ServiceData = res.data.data;
          this.totalLength = res.data.totalItems;
        }
      
        if(res.status){
          //this.apimicroservice.openSnackbar(res.message);
          
        }else{
          //this.apimicroservice.openSnackbar(res.message);
        }
      });
    }

   
  }

  // deleteServices(id) {
  //   this.apimicroservice.deleteServices(id).subscribe(res => {
  //     if (res.status) {
  //       this.apimicroservice.openSnackbar(res.message);
  //       this.servicesMasterForm.reset();
  //       this.fetchServices();
  //     this.deleteButton = false;
  //     this.updateButton = false;
  //     this.submitButton = true;
  //     this.resetButton = true;
  //     this.cancelButton = false
  //     } else {
  //       this.apimicroservice.openSnackbar(res.message);
  //     }
      
      
  //   });
  // }

  prepopulation(id) {
    this.spinner.show();
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
    this.apimicroservice.getServiceById(id,branchRef,clinicRef).subscribe(res => {
      this.submitButton = false;
      this.updateButton = true;
      this.deleteButton = true;
      this.resetButton = false;
      this.spinner.hide();
      this.cancelButton = true;
      let data = res.data[0];
      this.servicesMasterForm = this.fb.group({
        "branchWiseId": new FormControl(data.branchWiseId),
        "serviceType": new FormControl(data.serviceType, [Validators.required]),
        "testType": new FormControl(data.testType,[Validators.required]),
        "updatedAt": this.date,
        "branchRefId": new FormControl(data.branchRefId),
          "clinicRefId": new FormControl(data.clinicRefId),
          "branchName": new FormControl(data.branchName),
        "searchService": new FormControl('')
      });
      this.superBranchId = data.branchWiseId;
      this.servicesId = data._id;
    });
  }else{
    this.apimicroservice.getServiceById(id,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
      this.submitButton = false;
      this.updateButton = true;
      this.deleteButton = true;
      this.resetButton = false;
      this.spinner.hide();
      this.cancelButton = true;
      let data = res.data[0];
      this.servicesMasterForm = this.fb.group({
        "branchWiseId": new FormControl(data.branchWiseId),
        "serviceType": new FormControl(data.serviceType, [Validators.required]),
        "testType": new FormControl(data.testType,[Validators.required]),
        "updatedAt": this.date,
        "branchRefId": new FormControl(data.branchRefId),
          "clinicRefId": new FormControl(data.clinicRefId),
          "branchName": new FormControl(data.branchName),
        "searchService": new FormControl('')
      });
      this.superBranchId = data.branchWiseId;
      this.servicesId = data._id;
    });
  }
  }

  openSnackbar(message: string) {
    return this._snackBar.open(message, "close", {
      verticalPosition: "bottom",
      horizontalPosition: "right",
      duration: 2000,
      panelClass: ["snackbarStyle"],
    });
  }

  onPageChange(event) {
    this.spinner.show();
    this.page = event;
    //pg = this.page;
    this.searchText = this.servicesMasterForm.get('searchService').value;
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    let userRef='';

    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getServiceData(this.page, this.size, this.searchText,branchRef,clinicRef).subscribe(res => {
        this.spinner.hide();
        if (res.status) {
          this.ServiceData = res.data.data;
          this.totalLength = res.data.totalItems;
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
  
      })
    } else {
      this.apimicroservice.getServiceData(this.page,this.size,this.searchText,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
        this.spinner.hide();
        this.ServiceData = [];
        if (res.status) {
          if(res.data.data.length > 0)
          {
          this.ServiceData = res.data.data;
          this.totalLength = res.data.totalItems;
          }
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
  
      })
   }

    


  }
  onKeyUpSearch(event: any) {
    this.searchText = this.servicesMasterForm.get('searchService').value;
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    let userRef='';

    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getServiceData(this.page, this.size, this.searchText,branchRef,clinicRef).subscribe(res => {
        //
        if (res.status) {
          this.ServiceData = res.data.data;
          this.totalLength = res.data.totalItems;
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
      })
    } else {
      this.apimicroservice.getServiceData(this.page,this.size,this.searchText,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
        //
        this.ServiceData = [];
        if (res.status) {
          if(res.data.data.length > 0)
          {
            this.ServiceData = res.data.data;
            this.totalLength = res.data.totalItems;
          }
         
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
      })
   }

    
  }

  redirect(to): void {
    this.router.navigate([to]);
  }

  onChange($event: Event) {
    const file = ($event.target as HTMLInputElement).files[0];
    const fileName = file.name;
    this.errormessage = '';
    let ext = fileName.substring(fileName.lastIndexOf(".") + 1);
    if (
      ext.toLowerCase() == "png" ||
      ext.toLowerCase() == "jpg" ||
      ext.toLowerCase() == "jpeg" ||
      ext.toLowerCase() == "csv" ||
      ext.toLowerCase() == "xlsx" ||
      ext.toLowerCase() == "pdf" ||
      ext.toLowerCase() == "docx" 
    ) { 
      this.convertToBase64(file);
    }
  }
  cancelUpdate(){
   
    this.servicesMasterForm.reset();
    this.submitButton=true;
    this.updateButton=false;
    this.cancelButton=false;
  }
  convertToBase64(file: File) {
    const observable = new Observable((subscriber: Subscriber<any>) => {
      this.readFile(file, subscriber);
    });
    observable.subscribe((d) => {
      this.myimage = d;

    });
  }
  readFile(file: File, subscriber: Subscriber<any>) {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      subscriber.next(fileReader.result);
      subscriber.complete();
    };
    fileReader.onerror = (error) => {
      subscriber.error(error);
      subscriber.complete();
    };
  }
  toServicesForm() {
    document.getElementById("toServicesForm").scrollIntoView();
  }
  toServicesList() {
    document.getElementById("toServicesList").scrollIntoView();
  }

  fetchBranchList() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      //  
      if (res.status) {    
        this.BranchList = res.data;
        this.filteredListBranch= this.BranchList.slice();
      } else {
      // this.apimicroservice.openSnackbar(res.message);
      }
    })
  } 
  fetchServiceTypeList(){
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
    this.apimicroservice.getAllServiceTypeList(branchRef,clinicRef).subscribe(res => {
      //  
      this.filteredList=[];
      this.serviceTypeArr = []; 
      if (res.status) { 
        if(res.data.data.length > 0)
        {
        this.serviceTypeArr = res.data.data;
        this.filteredList= this.serviceTypeArr.slice();
        }
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    })
  }else{
    this.apimicroservice.getAllServiceTypeList(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
      //  
      this.filteredList=[];
      this.serviceTypeArr = []; 
      if (res.status) { 
        if(res.data.data.length > 0)
        {
        this.serviceTypeArr = res.data.data;
        this.filteredList= this.serviceTypeArr.slice();
        }
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
  }
  getTestTypeAllData(){
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
    this.apimicroservice.getTestTypeAllData(branchRef,clinicRef).subscribe(res => {
      //  
      this.testTypeArr = [];
      if (res.status) {
        if(res.data.data.length > 0)
        {
        this.testTypeArr = res.data.data;
        this.filteredList1= this.testTypeArr.slice();
        }
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    })
  }else{
    this.apimicroservice.getTestTypeAllData(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
      //  
      this.testTypeArr = [];
      if (res.status) {
        if(res.data.data.length > 0)
        {
        this.testTypeArr = res.data.data;
        this.filteredList1= this.testTypeArr.slice();
        }
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
  }
  branchClick(id,branchName)
  {

    this.servicesMasterForm.controls["branchName"].setValue(branchName);

   this.apimicroservice.getBranchById(id).subscribe(res => { 
     //
       
     let data = res.data[0];
     let payload = this.servicesMasterForm.value;
      payload.clinicRefId = res.data[0].clinicRefId;
      this.servicesMasterForm.controls["clinicRefId"].setValue(res.data[0].clinicRefId);
       if(this.updateButton == false){
        this.spinner.show();
        this.apimicroservice.getServicesBranchLastId(payload.branchRefId).subscribe(res => {
          //   
         
          if (res['status']) {
            let servicesIdd = res['data']['totalItems'];
            this.servicesMasterForm.controls['branchWiseId'].setValue(servicesIdd + 1);
          } else {
            this.apimicroservice.openSnackbar("Something went wrong");
          }
          this.spinner.hide();
        })
      }
    });
  }
  getLastId()
  {
    // if(this.userType !== 'Super Admin'){
    //   this.apimicroservice.getServicesBranchLastId(this.branchRef).subscribe(res => {
    //     //   
       
    //     if (res['status']) {
    //       let servicesIdd = res['data']['totalItems'];
    //       this.servicesMasterForm.controls['branchWiseId'].setValue(servicesIdd + 1);
    //     } else {
    //       this.apimicroservice.openSnackbar("Something went wrong");
    //     }
       
    //   })
    // }else{
      this.apimicroservice.getLastServicesAll().subscribe(res => {
        //   
       
        if (res['status']) {
          let servicesIdd = res['data']['totalItems'];
          this.superBranchId = servicesIdd + 1;
           this.servicesMasterForm.controls['branchWiseId'].setValue(this.superBranchId);
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
       
      })
    //} 
    
  }
openBranchPopUp() {
 // localStorage.clear();
  const dialogRef3 = this.dialog.open(PopUpBranchComponent,
    {
      width: "1200px",
    });
    dialogRef3.afterClosed().subscribe((result) => {
      this.fetchBranchList();
    })
}
openServiceTypePopUp() {
  const dialogRef3 = this.dialog.open(PopUpServicetypeComponent,
    {
      width: "400px",
    });

    dialogRef3.afterClosed().subscribe((result) => {
      let clinicRef= sessionStorage.getItem('clinicRef');
      let branchRef= sessionStorage.getItem('branchRef');
      let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
      let branchRefSuperAdmin= '';
      if (this.userType !== 'Super Admin') {
      this.apimicroservice.getAllServiceTypeList(branchRef,clinicRef).subscribe(res => {
        //  
        this.filteredList=[];
        if (res.status) {    
          if(res.data.data.length > 0)
          {
          this.serviceTypeArr = res.data.data;
          this.filteredList= this.serviceTypeArr.slice();
          this.servicesMasterForm.get("serviceType").setValue(res.data.data[0].serviceType);
          }
        } else {
          //this.apimicroservice.openSnackbar(res.message);
        }
      })
    }else{
      this.apimicroservice.getAllServiceTypeList(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
        //  
        this.filteredList=[];
        if (res.status) {    
          if(res.data.data.length > 0)
          {
          this.serviceTypeArr = res.data.data;
          this.filteredList= this.serviceTypeArr.slice();
          this.servicesMasterForm.get("serviceType").setValue(res.data.data[0].serviceType);
          }
        } else {
          //this.apimicroservice.openSnackbar(res.message);
        }
      })
    }
    // this.fetchServiceTypeList();  
    });
}
openTestTypePopUp() {
  const dialogRef3 = this.dialog.open(PopupTestTypeComponent,
    { 
      width: "400px",
    }); 

    dialogRef3.afterClosed().subscribe((result) => {
      let clinicRef= sessionStorage.getItem('clinicRef');
      let branchRef= sessionStorage.getItem('branchRef');
      let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
      let branchRefSuperAdmin= '';
      if (this.userType !== 'Super Admin') {
      this.apimicroservice.getTestTypeAllData(branchRef,clinicRef).subscribe(res => {
        //  
        this.testTypeArr = [];
        if (res.status) {
          if(res.data.data.length > 0)
          {
          this.testTypeArr = res.data.data;
          this.filteredList1= this.testTypeArr.slice();
          this.servicesMasterForm.get("testType").setValue(res.data.data[0].testType);
          }
        } else {
          //this.apimicroservice.openSnackbar(res.message);
        }
      })
    }else{
      this.apimicroservice.getTestTypeAllData(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
        //  
        this.testTypeArr = [];
        if (res.status) {
          if(res.data.data.length > 0)
          {
          this.testTypeArr = res.data.data;
          this.filteredList1= this.testTypeArr.slice();
          this.servicesMasterForm.get("testType").setValue(res.data.data[0].testType);
          }
        } else {
          //this.apimicroservice.openSnackbar(res.message);
        }
      })
    }
    // this.getTestTypeAllData();  
    });
}

onback() {
  this.apimicroservice.back()
}
} 