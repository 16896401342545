import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
 import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { AfterViewInit, Directive, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { PopUpRoleComponent } from '../pop-up-role/pop-up-role.component';
import { PopUpBranchComponent } from '../pop-up-branch/pop-up-branch.component';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  @Output() next = new EventEmitter<void>();
    @Output() enable = new EventEmitter<void>();
  errormessage: string;
  searchText: any;
  userlist: any;
  totalLength: any;
  id: any;
  rolesList: any;
  BranchList: any;
  clinicRef:  any;
  filteredListBranch: any;
  filteredListRole: any;
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService, 
    public router: Router,
     public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService
  ) {}
  usersMasterForm: FormGroup;
  status: any;
  submitButton = true;
  updateButton = false;
  deleteButton = false;
  cancelButton=false;
  userRef: any;
  myimage;
  date: any;
  size:number = 5;
  page2:number = 1;
  isPassword= true;
  //permission code
  @Output() prev = new EventEmitter<void>();
  user_role:any;
  permissionForm: FormGroup;
  customerType:any;
  tab = true;
  page= true;
  
  view= false;
  listofCustomer:any;
  listofuser:any;
  dashboard_view:any;
  Tab1 = false;
 Tab2 = false;
  Tab3 = false;
  Tab4 = false;
  CreateTab1= false;
  CreateTab2= false;
  CreateTab3= false;
  CreateTab4= false;
  UpdateTab1= false;
  UpdateTab2= false;
  UpdateTab3= false;
  UpdateTab4= false;
  DeleteTab1= false;
  DeleteTab2= false;
  DeleteTab3= false;
  DeleteTab4= false;
  CreateTab= true;
  permission :any = [];
  user : any = [];
  selectedItemsList: any;
  listofcustuser: any;
  userSelectList:any = [];
  allPages:any = [];
  tempArray:any = [];
  customerRefId:any;
  userData: any;
  listOfRole: any;
  checkCust:Boolean = true;
  checkUser:Boolean = true;
  disableField:Boolean = false;
  tabenable: boolean = false;
  usertabenable: boolean =false;
  userPermission:any;
  userType:any;
  custRefId:any;
  permissionList=[];
  permissionCreate = true;
  permissionUpdate = true;
  patientImage: any;
  roleCreate=true;
  //end permission code
  ngOnInit() {
    ///permission code
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.checkCust = true;
    this.checkUser = true;
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    this.userType = sessionStorage.getItem('userType');
    this.custRefId = sessionStorage.getItem('custRefId');
    this.userRef = sessionStorage.getItem('userRef');
    if(this.userType !== 'Super Admin'){
    this.userPermission.forEach((element) => {  
      if(element.pageName == 'User' && element.create == false)
      {
          this.permissionCreate = false;  
      }
      if(element.pageName == 'User' && element.update == false)
      {
          this.permissionUpdate = false;  
      }
      if(element.pageName == 'Role' && element.create == false)
      {
          this.roleCreate = false;  
      }
    });
  }
  this.allPages = [];
  //
  for(let i=0;i<this.router.config.length;i++){
    let routePath = this.router.config[i].data;

    
    if(routePath == undefined){

    }else{
    this.allPages.push({pageName:routePath.name,view:false,create:false,update:false,delete:false,isTab:routePath.isTab,id:i,isUserTab:routePath.isUserTab});
    
    }
  }
  this.tempArray = this.allPages;
    //permission code end
    this.date = new Date();
    this.usersMasterForm = this.fb.group({
      "userType": new FormControl('',Validators.required),
      "userName": new FormControl('',Validators.required),
      "userID": new FormControl('',[Validators.required,Validators.pattern("^[a-zA-Z]*$|^[A-Za-z]\\w*$"),Validators.maxLength(25),Validators.minLength(5)]),
      "emailId": new FormControl('',[Validators.required,Validators.pattern("[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}")]),
      // "password": new FormControl('', [Validators.required,
      //   Validators.pattern('^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,20}$')]),
      "status": new FormControl('',Validators.required),
      "roleRefId": new FormControl('',Validators.required),
      "branchRefId": new FormControl('',Validators.required),
      "createdAt": this.date,
      "searchUser": new FormControl(''),
      "clinicRefId": new FormControl(),
      //permission code
      "Page": new FormControl(true, [Validators.required]),
      "Tab": new FormControl(''),
      "View": new FormControl(''),
      "userImage": '',
    });
    this.patientImage ="../../assets/main.jpeg";
   
    this.usersMasterForm.controls["status"].setValue("Active");
    this.fetchRoleList();
    this.fetchBranchList();
    this.prepopulation(this.userRef);
    this.getAllBranch();
  }
  getAllBranch()
  {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      if (res.status) {        
        this.usersMasterForm.get("branchRefId").setValue(res.data[0]._id);
      }
    });
  }


  // getAllBranch()
  // {
  //   this.apimicroservice.getAllBranchList().subscribe(res => {
  //     if (res.status) {         
  //       let length =res.data.length - 1; 
  //       this.branchRefId=res.data[length].branchName;
  //       this.usersMasterForm.controls["branchRefId"].setValue(res.data[length]._id);
  //       this.branchClick(res.data[length]._id,this.branchRefId);
  //       if(this.userType == 'Super Admin')
  //       {  
  //         this.apimicroservice.getBranchById(res.data[length]._id).subscribe(res => {
  //           this.spinner.hide();
  //           this.usersMasterForm.get("branchName").setValue(res.data[length].branchName);
  //          });
  //       }
  //     }
  //   });
  // }
  submitUser() {
    if (this.usersMasterForm.invalid) {
      this.usersMasterForm.get("userType").markAsTouched();
      this.usersMasterForm.get("userName").markAsTouched();
      this.usersMasterForm.get("emailId").markAsTouched();
      // this.usersMasterForm.get("password").markAsTouched();
      this.usersMasterForm.get("status").markAsTouched();
      this.usersMasterForm.get("roleRefId").markAsTouched();
      this.usersMasterForm.get("branchRefId").markAsTouched();

    
      return;
    } else {
      this.spinner.show();
      this.date = new Date();
      let payload = this.usersMasterForm.value;
      payload.userImage = this.patientImage;
      payload.clinicRefId = this.clinicRef;
      this.apimicroservice.addUser(payload).subscribe((res) => {
        this.spinner.hide();
        if (res.status) {
          this.apimicroservice.openSnackbar(res.message);
          this.patientImage = "../../assets/main.jpeg";
          this.ngOnInit();
        } else {
        }
      });
    }
  }
 

  openSnackbar(message: string) {
    return this._snackBar.open(message, "close", {
      verticalPosition: "bottom",
      horizontalPosition: "right",
      duration: 2000,
      panelClass: ["snackbarStyle"],
    });
  }
  

  prepopulation(id) {
    this.spinner.show();
    this.isPassword = false;
    this.apimicroservice.getUserById(id).subscribe(res => {
      this.spinner.hide();
      this.userData = res.data[0];
      let userId = res.data[0]._id;
      localStorage.setItem('userId',userId);
      this.allPages = res.data[0].userPermission;
      if(this.allPages.length !== 0 ){
      for(let i=0;i<this.allPages.length;i++){
       
        if(this.allPages[i].pageName == "Masters"){
           if(this.allPages[i].view == true){
          this.tabenable = true;
           }else{
            this.tabenable = false;
           }
        }
      }
    }
      if(this.allPages.length == 0){
        this.allPages = this.tempArray;
        for(let i=0;i<this.tempArray.length;i++){
       
          if(this.tempArray[i].pageName == "Masters"){
             if(this.tempArray[i].view == true){
            this.tabenable = true;
             }else{
              this.tabenable = false;
             }
          }
        }
      }
      //end permission code
      //
     let data = res.data[0];
     this.submitButton = false;
     this.updateButton = true;
     this.cancelButton=true;
      if(res.status){
        this.usersMasterForm = this.fb.group({
          "userType": new FormControl(data.userType,Validators.required),
          "userName": new FormControl(data.userName,[Validators.required]),
          "emailId": new FormControl(data.emailId,[Validators.required,Validators.pattern("[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}")]),
          "userID": new FormControl(data.userID,[Validators.required,Validators.pattern("^[a-zA-Z]*$|^[A-Za-z]\\w*$"),Validators.maxLength(25),Validators.minLength(5)]),
          // "password": new FormControl(data.password),
          "status": new FormControl(data.status,Validators.required),
          "roleRefId": new FormControl(data.roleRefId,Validators.required),
          "branchRefId": new FormControl(data.branchRefId,Validators.required),
          "clinicRefId": new FormControl(data.clinicRefId),
           "updatedAt": this.date,
           "userImage": '',
      // "deviceId": id
        });
        this.id = id;
        this.patientImage = data.userImage ? data.userImage : "../../assets/main.jpeg"
        // this.fetchUserList();
        // this.fetchRoleList();
        // this.fetchBranchList();
      }
    });
  }
  updateUser() {
    // if (this.usersMasterForm.invalid) {
    //   this.usersMasterForm.get("userName").markAsTouched();
    //   this.usersMasterForm.get("userType").markAsTouched();
    //   this.usersMasterForm.get("emailId").markAsTouched();
    //   // this.usersMasterForm.get("password").markAsTouched();
    //   this.usersMasterForm.get("status").markAsTouched();
    //   this.usersMasterForm.get("roleRefId").markAsTouched();
    //   this.usersMasterForm.get("branchRefId").markAsTouched();
    
    //   return;
    // } 

      let payload = this.usersMasterForm.value;
      //
      
      this.spinner.show();;  
    payload.userPermission = this.allPages;
       payload.clinicRefId = this.clinicRef;    
     payload.userImage = this.patientImage;
    this.apimicroservice.updateUser(this.id,payload).subscribe(res => {
      this.spinner.hide();
    //  this.updateButton = false;
     // this.submitButton = true;
      this.cancelButton=true;  
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);
        this.patientImage = "../../assets/main.jpeg";
        this.ngOnInit();
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    });
    
  }
  addNew() {
    this.usersMasterForm.reset();
    this.updateButton = false;
    this.deleteButton = false;
  }
 
 
  redirect(to): void {
    this.router.navigate([to]);
  }
  
  // fetchRoleList() {
  //   this.searchText = '';
  //   let clinicRef= '';
  //   if (this.userType !== 'Super Admin') {
  //     //
      
  //     this.apimicroservice.getAllRoles(this.page2,this.size,this.searchText,clinicRef).subscribe(res => {
  //       //
        
  //       if (res.status) {    
  //         this.rolesList = res.data.data;
  //       } else {
  //         this.apimicroservice.openSnackbar(res.message);
  //       }
  //     })
  //   } else {
  //     //
      
  //     this.apimicroservice.getAllRoles(this.page2,this.size,this.searchText,clinicRef).subscribe(res => {
  //       //
        
  //       if (res.status) {    
  //         this.rolesList = res.data.data;
  //       } else {
  //         this.apimicroservice.openSnackbar(res.message);
  //       }
  //     })
  //   }
   
  // }
  fetchRoleList() {
    this.searchText = '';
    let clinicRef='';
      this.apimicroservice.getAllRolelist().subscribe(res => {
        //
        if (res.status) {    
          this.rolesList = res.data;
          this.filteredListRole= this.rolesList.slice();
        } else {
        //  this.apimicroservice.openSnackbar(res.message);
        }
      })
  
  }
  fetchBranchList() {
    this.searchText = '';
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      //  
      if (res.status) {    
        this.BranchList = res.data;
        this.filteredListBranch= this.BranchList.slice();
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    })

    // this.apimicroservice.getAllBranch(this.page2, this.size, this.searchText).subscribe(res => {
    //   //
      
    //   if (res.status) {    
    //     this.BranchList = res.data.branchData;
    //   } else {
    //     this.apimicroservice.openSnackbar(res.message);
    //   }
    // })
  }
  onChange($event: Event) {
    const file = ($event.target as HTMLInputElement).files[0];
    const fileName = file.name;
    this.errormessage = '';
    let ext = fileName.substring(fileName.lastIndexOf(".") + 1);
    if (
      ext.toLowerCase() == "png" ||
      ext.toLowerCase() == "jpg" ||
      ext.toLowerCase() == "jpeg" ||
      ext.toLowerCase() == "csv" ||
      ext.toLowerCase() == "xlsx" ||
      ext.toLowerCase() == "pdf" ||
      ext.toLowerCase() == "docx" 
    ) { 
      this.convertToBase64(file);
    }
  }
  convertToBase64(file: File) {
    const observable = new Observable((subscriber: Subscriber<any>) => {
      this.readFile(file, subscriber);
    });
    observable.subscribe((d) => {
      this.patientImage = d;
      
    });
  }
  readFile(file: File, subscriber: Subscriber<any>) {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      subscriber.next(fileReader.result);
      subscriber.complete();
    };
    fileReader.onerror = (error) => {
      subscriber.error(error);
      subscriber.complete();
    };
  }
  cancelUpdate(){
    this.patientImage ="../../assets/main.jpeg";
    this.usersMasterForm.reset();
    this.submitButton=true;
    this.updateButton=false;
    this.cancelButton=false;
    this.ngOnInit();
  }
 
  
toUserForm() {
  document.getElementById("toUserForm").scrollIntoView();
}
toUserList(){
  document.getElementById("toUserList").scrollIntoView();
}


openChangePasswordPopUp() {
  
  const dialogRef3 = this.dialog.open(ChangePasswordComponent,
    {
      // width: "1200px",
      // height: "570px"
    });
    dialogRef3.afterClosed().subscribe((result) => {
      // this.apimicroservice.getAllDoctorlist().subscribe(res => {
      //   //
      //   if (res.status) {    
      //     this.doctorList = res.data.data;
      //     this.filteredListDoctor= this.doctorList.slice(); 
      //     this.fetchDoctorList();
      //     this.usersMasterForm.get("doctorRefId").setValue(res.data.data[0]._id)
      //   } else {
      //   //  this.apimicroservice.openSnackbar(res.message);
      //   }
      // })
       });
}

openRolePopUp() {
  localStorage.clear();
  const dialogRef3 = this.dialog.open(PopUpRoleComponent,
    {
       width: "500px",
    }); 
      dialogRef3.afterClosed().subscribe((result) => {
        this.searchText = '';
        let clinicRef='';
          this.apimicroservice.getAllRolelist().subscribe(res => {
            //
            if (res.status) {    
              this.rolesList = res.data;
              this.filteredListRole= this.rolesList.slice();
              this.fetchRoleList();
              this.usersMasterForm.get("roleRefId").setValue(res.data[0]._id)
            } else {
            //  this.apimicroservice.openSnackbar(res.message);
            }
          })
     // this.fetchRoleList();
    })
}


openBranchPopUp() {
  localStorage.clear();
  const dialogRef3 = this.dialog.open(PopUpBranchComponent,
    {
      width: "1200px",
    });
    dialogRef3.afterClosed().subscribe((result) => {
      let clinicRef= sessionStorage.getItem('clinicRef');
      this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
        //  
        if (res.status) {    
          this.BranchList = res.data;
          this.filteredListBranch= this.BranchList.slice();

        this.branchClick(res.data[0]._id,res.data[0].branchName);
          this.usersMasterForm.get("branchRefId").setValue(res.data[0]._id);
        } 
      })
      //this.fetchBranchList();
    })
}
branchClick(id,branchName)
{

  this.usersMasterForm.controls["branchName"].setValue(branchName);

 this.apimicroservice.getBranchById(id).subscribe(res => { 
   //
     
   let data = res.data[0];
   let payload = this.usersMasterForm.value;
    payload.clinicRefId = res.data[0].clinicRefId;
    this.usersMasterForm.controls["clinicRefId"].setValue(res.data[0].clinicRefId);
     if(this.updateButton == false){ 
      this.spinner.show();
      this.apimicroservice.getLastStaffID(payload.branchRefId).subscribe(res => {
        //   
       
        if (res['status']) {
          let staffIdd = res['data']['totalItems'];
          this.usersMasterForm.controls['branchWiseId'].setValue(staffIdd + 1);
          //this.bId=staffIdd + 1;
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
        this.spinner.hide();
      })
    }
  });
}
}