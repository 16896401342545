<div class="dialog-wrapper" [formGroup]="popupServiceTypeForm">
      <div class="container">
        <form [formGroup]="popupServiceTypeForm" autocomplete="off" novalidate>
        <div class="row">
          <div class="col-sm-12">
          
              <div class="card pt-2 pr-2 pl-2 mb-2" >
             

                    <div class="row">
                    <div class="col-sm-10">
                        <p class="text-muted">Add New Service Type</p>
                    </div>
                    <div class="col-sm-1">
                      <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                        <a (click)="onClose()" matTooltip="Close" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">close</span></a>
                        </div>
                    </div>
                   
              </div>
                  <div class="col-sm-12">
                    <div class="row">
                        
                  <div class="col-sm-6">
                    <label class="pt-3 lable_style">Service Type :</label>
                    </div>
          <div class="col-sm-6 pl-1 pr-1">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Service Type</mat-label>
              <input required matInput placeholder="Service Type" formControlName="serviceType" />
               
             </mat-form-field>
             <mat-error class="margin_tp"
             *ngIf="popupServiceTypeForm.controls['serviceType'].touched && popupServiceTypeForm.controls['serviceType'].errors?.required">
             This field is mandatory.
           </mat-error>
           <mat-error class="margin_tp"
           *ngIf="popupServiceTypeForm.controls['serviceType'].touched && popupServiceTypeForm.controls['serviceType'].errors?.pattern">
           Enter Valid Service Type.
         </mat-error>
          </div>
       
      
                </div>
                <!-- <div class="row">
                    <div class="col-sm-4"></div>
                <div class="col-sm-4 mb-1">
                    <button class="btn btn-primary btn-sm example-full-width"  (click)="save()" >Save</button>
                  </div>
                  <div class="col-sm-4"></div>
                </div> -->

                <div class="row mb-2">
                  <div *ngIf="submitButton" class="col-sm-3"></div>
                 
              <div *ngIf="submitButton" class="col-sm-6">
                  <button class="btn btn-primary btn-sm example-full-width" type="button"
                   (click)="save()" >Save</button>
                </div>
                <div *ngIf="updateButton" class="col-sm-6">
                  <button class="btn btn-primary btn-sm example-full-width" type="button"
                   (click)="updateServiceType()" >Update</button>
                </div>
                <div *ngIf="updateButton" class="col-sm-6">
                  <button class="btn btn-primary btn-sm example-full-width" type="reset"
                   (click)="cancelUpdate()" >Cancle</button>
                </div>
                <div *ngIf="submitButton" class="col-sm-3"></div>
              
              </div>

</div>
               

                
              </div>

              <div class="card pr-2 pt-4 pl-2" >
              
                <div class="row">
                  <div class="col-sm-6">
                      <h3 class="titlecolor"> Service Type List</h3>  
                  </div>
                  <div class="col-sm-6 mb-2">
                      <div class="input-group md-form form-sm form-1 pl-0">
                        <div class="input-group-prepend">
                          <span class="input-group-text purple lighten-3" id="basic-text1"><i class="fas fa-search text-white"
                              aria-hidden="true"></i></span>
                        </div>
                        <input class="form-control my-0  py-1" type="text"
                         placeholder="Search" formControlName="searchServiceType"
                         (keyup)="onKeyUpEvent($event)" aria-label="Search">
                      </div>
                  </div>
                  <div class="col-sm-12 table-responsive">
                    <table id="toDeviceList" 
                 class="table table-bordered data-table-custom">
                        <thead>
                          <tr class="text-center">
                            <th>Service Type</th>
                            <!-- <th>Date</th> -->
                             </tr>
                        </thead>
                        <tbody >
                         
                          <tr  *ngFor="let data of listOfServiceType | paginate : { id:'serviceTypeMaster',
                          itemsPerPage: size, currentPage: page, totalItems:totalLength }"
                       (click)="prepopulation(data._id)" 
                        style="cursor:pointer; text-align: center ">
                            <td matTooltip="click to view">{{data.serviceType}}</td>
                            <!-- <td matTooltip="click to view">{{data.createdAt | date}}</td> -->
                        
                          </tr>
                          
                          
                        </tbody>
                      </table>
                    </div>
                    <div class="col-sm-12 pt-0 text-right">
                      <pagination-controls id="serviceTypeMaster" directionLinks="true"  
                      (pageChange)="onPageChange($event)" class="float-right pagination my-pagination"></pagination-controls>
                    </div>
                </div>
           

            </div>
          </div>
      
        </div>
</form>


      </div>
    </div>