import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {AfterViewInit, Directive, ViewChild} from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ApiServiceService } from '../api-service.service';
import { ClinicHolidayComponent } from '../clinic-holiday/clinic-holiday.component';
import { DoctorLeaveComponent } from '../doctor-leave/doctor-leave.component';

@Component({
  selector: 'app-holiday-leave',
  templateUrl: './holiday-leave.component.html',
  styleUrls: ['./holiday-leave.component.scss']
})
export class HolidayLeaveComponent implements OnInit {

  selectedIndex: number = 0;
  disable : Boolean = true;
  userPermission: any;
  userType: any;
  permissionList =[];
  CustomerSettingView = true;
  UserSettingView= true;
  UserPermissionsView= true;
  
  constructor(public router: Router,
    private route: ActivatedRoute,
    public apimicroservice: ApiServiceService,
    private change: ChangeDetectorRef) { }
    @ViewChild(ClinicHolidayComponent, {static: false}) private ClinicHolidayMaster: ClinicHolidayComponent;
    @ViewChild(DoctorLeaveComponent, {static: false}) private DoctorLeavemaster: DoctorLeaveComponent;
  ngOnInit(): void {
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermissions"));
    this.userType = sessionStorage.getItem("type");
     
    // if(this.userType == 'User')
    // {
    //   this.userPermission.forEach((element) => {
    //     this.permissionList.push(element.pageName);
    //     if (element.pageName == "Customer Setting" && element.view == false) {
    //       this.CustomerSettingView = false;
    //     } 
    //       if (element.pageName == "User-Setting" && element.view == false) {
    //       this.UserSettingView = false;
    //     } 
    //     if (element.pageName == "User-Permissions" && element.view == false) {
    //       this.UserPermissionsView = false;
    //     } 
    //   });
    // }
    this.disable = true;
  }
  onTabChanged(event: MatTabChangeEvent) 
  {
      if(event.tab.textLabel == 'Clinic Holidays')
      {
        this.ClinicHolidayMaster.ngOnInit();//Or whatever name the method is called
        this.disable = true;
      }
      else if(event.tab.textLabel == 'Doctor Leave')
      {
        this.DoctorLeavemaster.ngOnInit();//Or whatever name the method is called
      }
      
  }
  
 

public tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    
  this.selectedIndex = tabChangeEvent.index;
}

public next() {
  this.disable = false;
  this.selectedIndex += 1;
  this.DoctorLeavemaster.ngOnInit();
}

public prev() {
  this.selectedIndex -= 1;
 // this.UserMaster.ngOnInit();
  this.disable = true;
}

public enable(){
  this.disable = false;
 // this.Permissions.ngOnInit();
}


ngOnChanges(changes: HolidayLeaveComponent) {
}
redirect(to): void {
  this.router.navigate([to]);
}
onback() {
  this.apimicroservice.back()
}

}
