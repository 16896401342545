<div class="col-sm-12" [formGroup]="patientMasterForm">
    <div class="row">
       
        <div class="col-sm-6">
            <h1 class="titlecolor" >  Search Patients</h1>  
        </div>
        
        <div class="search-box justify-content-end float-end col-sm-5 pb-2">
          <input class="form-control justify-content-end float-end amber-border" type="text" placeholder="Search"  onkeydown="return (event.keyCode!=13);"
          formControlName="searchPatient" aria-label="Search" (keyup)="onKeyUpEvent($event)"
          >
          <div class="input-group-append">
             <span class="input-group-text amber lighten-3" id="basic-text1"><span class="material-symbols-outlined">search</span></span>
          </div>
       </div>

        <!-- <div class="col-sm-6">
            <div class="input-group md-form form-sm form-1 pl-0">
              <div class="input-group-prepend">
                <span class="input-group-text purple lighten-3" id="basic-text1"><i class="fas fa-search text-white"
                    aria-hidden="true"></i></span>
              </div>
              <input class="form-control my-0 py-1" type="text" onkeydown="return (event.keyCode!=13);" formControlName="searchPatient"
              aria-label="Search" (keyup)="onKeyUpEvent($event)" placeholder="Search" aria-label="Search">

            </div>
        </div> --> 
        <div class="col-sm-1">
          <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
            <a (click)="popUpClose()" matTooltip="Close" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">close</span></a>
            </div>
        </div> 
        <!-- <div class="col-sm-1 text-right pb-2">
          <button mat-icon-button class="close-button" [mat-dialog-close]="true">
              <mat-icon class="close-icon" color="warn">close</mat-icon>
            </button>
      </div> -->
      <div class="col-sm-12 table-responsive">
        <table id="toPatientList"  
     class="table table-bordered data-table-custom">
            <thead>
              <tr style="text-align: center;">
                <!-- <th>ID</th> -->
                <th>First Name</th>
                <th>Middle Name</th>
                <th>Last Name</th>
                <th>Gender</th>
                <th>Age</th>
                <th>Mobile Number</th>
                <th>Street Address</th>
              </tr>
            </thead>
            <tbody>
              <tr  *ngFor=" 
                          let item of listOfPatients
                          | paginate : { id:'patient_pagination', 
                          itemsPerPage: size, currentPage: page, totalItems:totalLength }
                        "  (click)="toPatientForm(item)" style="cursor:pointer; text-align: center " >
                  
                <!-- <td  matTooltip="click to view"
                 >{{item.branchWiseId}}</td> -->
                 <td matTooltip="click to view">{{item.firstName}}</td>
                <td matTooltip="click to view">{{item.middleName}}</td>
                <td matTooltip="click to view">{{item.lastName}}</td>
                <td matTooltip="click to view">{{item.sex}}</td>
                <td matTooltip="click to view">{{item.age}}</td>
                <td matTooltip="click to view">{{item.mobile1}}</td>
                <td matTooltip="click to view">{{item.streetAddress}}</td>
                
              </tr>
            </tbody>
          </table>
        </div> 
        <div class="col-sm-12 pt-0 text-right">
          <pagination-controls id="patient_pagination" directionLinks="true"
            (pageChange)="onPageChange($event)" class="float-right pt-3 my-pagination"></pagination-controls>
       
        </div>
    </div>
</div>
