<div class="container-fluid bgcolor2">
  <div class="row col-sm-12 p-0 m-0 body">
    <div class="col-sm-12">

      <!-- 
        style="width: 100%; height: 600px;" -->

      <img *ngIf="isImage" [src]="src" style="width: 100%; height: 600px;">
      <!-- <iframe *ngIf="!isImage" width="100%" height="100%" frameBorder="0" [src]="src"></iframe> -->
      <pdf-viewer *ngIf="!isImage" [src]="src" [rotation]="0" [original-size]="false" [show-all]="true"
        [fit-to-page]="false" [zoom]="1" [zoom-scale]="'page-width'" [stick-to-page]="false" [render-text]="true"
        [external-link-target]="'blank'" [autoresize]="true" [show-borders]="false"
        style="width: 100%; height: 600px;"></pdf-viewer>
    </div>
    <div class="col-sm-8"></div> 
    <div class="col-sm-2 mt-2">
      <button (click)="dowload();" class="btn btn-primary btn-sm btnCol example-full-width">Download</button>
    </div>
    <div class="col-sm-2 mt-2">
      <button mat-dialog-close class="btn btn-primary btn-sm btnCol example-full-width">Close</button>
    </div>
  </div>
</div>