<div class="container-fluid bgcolor2" >
  <div class="row" [formGroup]="deviceMasterForm">
   
    <div class="col-sm-1">
       
    </div>
    <div class="col-sm-11">
      
      <form autocomplete="off" [formGroup]="deviceMasterForm" novalidate>
      <div class="row">
      
        <div class="col-sm-12 pr-4">
          <div class="row pl-0 ">
            <div class="col-12 pl-0 ">
              <div class="row ">
              <div class="col-sm-2">
               
                  <h1 class="pt-2 pl-0" id="toDeviceForm">Device</h1>
                  </div>
                  <div class="col-sm-8 p-1 pb-2">
                    
                    <div *ngIf="deviceName" class="card divbody borderradius " >
                      <!-- <div class="row" [style.color]="colorA"> -->
                      <div class="row">
                        <div class="col-sm-4">
                      <h1 class="pt-2 pl-3">{{deviceName}}</h1>
                      </div> 
                      <div class="col-sm-4 justify-content-end">
                        <h1 class="pt-2 pl-3 justify-content-end">{{brandName}}</h1></div>
                        <div class="col-sm-2"></div>
                        <div class="col-sm-2 justify-content-end" >
                          <div class="row" *ngIf="dataEar !== 'Not Defined'">
                          <span class="material-symbols-outlined mt-2 earSize">
                            hearing
                            </span>
                          <h1 class="pt-2 pl-3 justify-content-end" [style.color]="colorA">{{dataEar}}</h1>
                        </div>
                          </div>
                      </div>
                     
                      </div>
                  </div> 
                  <div class="col-sm-2 mt-1">
                    <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                      <a (click)="onback()" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">arrow_back</span>Back</a>
                      <a (click)="toDeviceList()" class="d-flex align-items-center ms-3 pr-2 mt-2"><span class="material-symbols-outlined pr-2">reorder</span>List</a>  
            </div>
            </div>
          </div>
         </div>
         </div>
   
          <div class="row ">
            <div class="col-sm-12 pr-4">
              <div class="row">
                <div class="card divbody mb-3 borderradius p-1">
           
                    <div class="col-sm-12">
                        <div class="row">
                          <div class="col-sm-12">
                            <div class="row mt-2">
                              <div class="col-sm-12">
                                <h5 class="pb-0 mb-0">Device Details :</h5>
                              </div>
                              
                            </div>
                            <div class="row pt-0 mt-0">
                                <div class="col-sm-1 mt-2 ">
                                      <mat-label class="center mt-2" ngDefaultControl [value]="superBranchId"  >{{superBranchId}}</mat-label>
                                </div>
                                
                                <div class="col-sm-2 ">
                                  <div class="row">
                                    <div class="col-sm-9 pr-1">
                                  <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>Brand
                                      <!-- <span class="required-asterisk">*</span>  -->
                                    </mat-label>
                                    <mat-select required #select1 placeholder="Brand"  class="colorblack"  formControlName="brand"   >
                                      <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'brandName'"
                                        [array]="brandlist"  (filteredReturn)="filteredList =$event"></mat-select-filter>
                                      <mat-option *ngFor="let item of filteredList" 
                                       [value]="item._id" 
                                       (onSelectionChange)="brandSelect(item.brandName)">
                                        {{item.brandName}}
                                      </mat-option>
                                    </mat-select>
                                   
                                    
                                  </mat-form-field>
                                  <mat-error class="margin_tp"
                                  *ngIf="deviceMasterForm.controls['brand'].touched && deviceMasterForm.controls['brand'].errors?.required">
                                  This field is mandatory.
                                </mat-error>
                                  </div>
                                  <div class="col-sm-3 ml-0 pl-0 mTop">
                              <i (click)="openBrandPopUp();" matTooltip="Add New Brand" 
                              class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                            
                            </div>
                            </div>
                          </div>
                          <div class="col-sm-3 ">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Model
                                <!-- <span class="required-asterisk">*</span>  -->
                              </mat-label>
                              <input required matInput placeholder="Model"  formControlName="model"/>
                              
                            </mat-form-field>
                            <mat-error class="margin_tp"
                                *ngIf="deviceMasterForm.controls['model'].touched && deviceMasterForm.controls['model'].errors?.required">
                                This field is mandatory.
                              </mat-error>
                          </div>
                         
                          <div class="col-sm-3  ">
                            <div class="row">
                              <div class="col-sm-10 pr-1">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Device Type </mat-label>
                              <mat-select #select2 placeholder="Device Type" class="colorblack" formControlName="deviceType">
                                <mat-select-filter *ngIf="select2.focused" [placeholder]="'Filter'" [displayMember]="'deviceType'" [array]="deviceTypelist"
                                  (filteredReturn)="filteredDeviceList =$event"></mat-select-filter>
                                <mat-option *ngFor="let item of filteredDeviceList" [value]="item.deviceType">
                                  {{item.deviceType}}
                                </mat-option>
                              
                              </mat-select>
                                
                            </mat-form-field>
                            <mat-error class="margin_tp"
                                *ngIf="deviceMasterForm.controls['deviceType'].touched && deviceMasterForm.controls['deviceType'].errors?.required">
                                This field is mandatory.
                              </mat-error>
                          </div>
                          <div class="col-sm-2 ml-0 pl-0 mTop">
                            <i (click)="openDeviceTypePopUp();" matTooltip="Add New Device Type" 
                            class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                      
                          </div>
                          </div>
                          </div>
                          <div class="col-sm-3 ">
                            <div class="row">
                              <div class="col-sm-10 pr-1">
                            <mat-form-field
                            class="example-full-width"
                            appearance="outline"
                             >
                            <mat-label>Style </mat-label>
                            <mat-select #select3 placeholder="Style" class="colorblack"  formControlName="style"   >
                              <mat-select-filter *ngIf="select3.focused" [placeholder]="'Filter'" [displayMember]="'styleName'"
                                [array]="styleList"  (filteredReturn)="filteredListStyle =$event"></mat-select-filter>
                              <mat-option *ngFor="let item of filteredListStyle" 
                               [value]="item.styleName" >
                                {{item.styleName}}
                              </mat-option>
                            </mat-select>
                           
                           </mat-form-field>
                          </div>
                          <div class="col-sm-2 mTop ml-0 pl-0">
                            <i (click)="openStylePopUp();" matTooltip="Add New Style" 
                            class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                         
                          </div>
                          </div>
                          </div>
                          
                          <div class="col-sm-3 ">
                            <div class="row">
                              <div class="col-sm-10 pr-1">
                            <mat-form-field
                            class="example-full-width"
                            appearance="outline"
                             >
                            <mat-label>Battery Type</mat-label>  
                            <mat-select #select4 placeholder="Battery Type" class="colorblack"  formControlName="batteryType"   >
                              <mat-select-filter *ngIf="select4.focused" [placeholder]="'Filter'" [displayMember]="'batteryType'"
                                [array]="batteryTypeList"  (filteredReturn)="filteredListbatteryType =$event"></mat-select-filter>
                              <mat-option *ngFor="let item of filteredListbatteryType" 
                               [value]="item.batteryType" >
                                {{item.batteryType}}
                              </mat-option>
                            </mat-select> 
                            <!-- <mat-select formControlName="batteryType">
                                <mat-option value="10">10</mat-option>
                                <mat-option value="13">13</mat-option>
                                <mat-option value="312">312</mat-option>
                                <mat-option value="675">675</mat-option>
                                <mat-option value="AA">AA</mat-option>
                                <mat-option value="AAA">AAA</mat-option>
                                <mat-option value="Rechargeable">Rechargeable</mat-option>
                                <mat-option value="Power Adaptor">Power Adaptor </mat-option>
                                </mat-select> -->
                           </mat-form-field>
                          </div>
                          <div class="col-sm-2 ml-0 pl-0 mTop">
                            <i (click)="openBatteryTypePopUp();" matTooltip="Add New Battery Type" 
                            class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                           
                          </div>
                          </div>
                          </div>
                          <div class="col-sm-3 ">
                            <mat-form-field
                            class="example-full-width"
                            appearance="outline"
                          >
                            <mat-label>Cord Type </mat-label>
                            <input formControlName="cordType" matInput placeholder="Cord Type" />
                          </mat-form-field>
                          </div>
                          <div class="col-sm-3 ">
                            <mat-form-field
                            class="example-full-width"
                            appearance="outline"
                          >
                            <mat-label>Ear</mat-label>
                            <mat-select formControlName="ear">
                                <mat-option class="blue" value="Left">Left</mat-option>
                                <mat-option class="red" value="Right">Right</mat-option>
                                <mat-option value="Not Defined">Not Defined</mat-option>
                                </mat-select>
                          </mat-form-field>
                          </div>
                          <div class="col-sm-3 ">
                            <mat-form-field
                            class="example-full-width"
                            appearance="outline"
                          >
                          
                            <mat-label>Technology
                            </mat-label>
                            <mat-select formControlName="technology">
                                <mat-option value="Analog">Analog</mat-option>
                                <mat-option value="Digital">Digital</mat-option>

                                </mat-select>
                          </mat-form-field>
                          </div>
                          <div class="col-sm-3 ">
                            <div class="row">
                              <div class="col-sm-10 pr-1">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Condition</mat-label>
                              <mat-select #select5 placeholder="Condition" class="colorblack" formControlName="condition">
                                <mat-select-filter *ngIf="select5.focused" [placeholder]="'Filter'" [displayMember]="'conditionType'" [array]="conditionTypeList"
                                  (filteredReturn)="filteredConditionList =$event"></mat-select-filter>
                                <mat-option *ngFor="let item of filteredConditionList" [value]="item.conditionType">
                                  {{item.conditionType}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div class="col-sm-2 ml-0 pl-0 mTop">
                            <i (click)="openConditionTypePopUp();" matTooltip="Add New Condition Type" 
                            class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                         
                          </div>
                         </div>
                         </div>
                          <div class="col-sm-3 ">
                            <mat-form-field
                            class="example-full-width"
                            appearance="outline">
                            <mat-label>Vent</mat-label>
                            <mat-select #select6  placeholder="Vent" class="colorblack" formControlName="vent">
                              <mat-select-filter *ngIf="select6.focused" [placeholder]="'Filter'" [displayMember]="'name'" [array]="allVent"
                                (filteredReturn)="filteredAllVentList =$event"></mat-select-filter>
                              <mat-option *ngFor="let item of filteredAllVentList" [value]="item.value">
                                {{item.name}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                          </div>
                          <div class="col-sm-3 ">
                            <mat-form-field
                            class="example-full-width"
                            appearance="outline">
                            <mat-label>Matrix</mat-label>
                            <input matInput formControlName="matrix" placeholder="Matrix" />
                          </mat-form-field>
                          </div>
                          <div class="col-sm-3 ">
                            <mat-form-field
                            class="example-full-width"
                            appearance="outline">
                            <mat-label>Receiver</mat-label>
                            <input matInput formControlName="receiver" placeholder="Receiver" />
                          </mat-form-field>
                        </div>
                        <div class="col-sm-3 ">
                          <mat-form-field
                          class="example-full-width"
                          appearance="outline">
                          <mat-label>Dome</mat-label>
                          <input matInput formControlName="dome" placeholder="Dome" />
                        </mat-form-field>
                      </div>
                      <div class="col-sm-3 ">
                        <mat-form-field
                        class="example-full-width"
                        appearance="outline">
                        <mat-label>Faceplate Number</mat-label>
                        <input matInput formControlName="facePlateNumber" maxlength="10" placeholder="Faceplate Number" />
                      
                      </mat-form-field>
                      <mat-error class="margin_tp"
                      *ngIf="deviceMasterForm.controls['facePlateNumber'].touched && deviceMasterForm.controls['facePlateNumber'].errors?.pattern">
                      Enter valid Faceplate Number
                    </mat-error>
                    </div>
                    <div class="col-sm-3 ">
                      <mat-form-field
                            class="example-full-width"
                            appearance="outline">
                            <mat-label>Wax Management</mat-label>
                            <input matInput formControlName="waxManagement" placeholder="Wax Management" />
                          </mat-form-field>
                  </div>
                  <div class="col-sm-3 ">
                    <mat-form-field
                    class="example-full-width"
                    appearance="outline">
                    <mat-label>Removal String</mat-label>
                    <mat-select formControlName="removalString">
                      <mat-option value="Yes">Yes</mat-option>
                      <mat-option value="No">No</mat-option>
                      </mat-select>
                  </mat-form-field>
                </div> 
              
              <div class="col-sm-3 ">
                <div class="row">
                <div class="col-sm-10 pr-1">
                <mat-form-field class="example-full-width tdW3 lable_style2  widthh" appearance="outline">
                  <mat-label class="lable_style2">Billing Company</mat-label>
           
                <mat-select required #select4 formControlName="billingCompanyRefId">
                  <mat-select-filter *ngIf="select4.focused" [placeholder]="'Filter'" [displayMember]="'companyName'"
                  [array]="listOfBilling"  (filteredReturn)="filteredBillingCompany =$event"></mat-select-filter>
                  <mat-option
                      *ngFor="let item of filteredBillingCompany"
                      [(value)]="item._id"
                      (click)="onSelectBillingComp(item.companyName)"
                      >{{ item.companyName }}</mat-option
                    >
                </mat-select>
            </mat-form-field> 
            <mat-error class="margin_tp"
                *ngIf="deviceMasterForm.controls['billingCompanyRefId'].touched && deviceMasterForm.controls['billingCompanyRefId'].errors?.required">
                This Field is Mandatory.
              </mat-error>
              </div> 
              <div class="col-sm-2 mTop ml-0 pl-0 no-print"> 
                <i (click)="openBillingCompPopUp();" matTooltip="Add New Billing Company" 
                class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
      
                   </div>
              </div>
                </div>
           
                <div class="col-sm-1 mt-4">
                  <mat-checkbox class="example-full-width " formControlName="isSerialNo">
                    Is Serial No. Required
                  </mat-checkbox>
                </div>
             </div>
                           </div>
             <div class="col-sm-12">
              <div class="row mt-2">
                
                <div class="col-sm-12 ">
                  <h5 class="pb-0 mb-0">Other Details :</h5>
                <!-- <label class="header">Other Details :</label> -->
                  </div>
                  <div class="col-sm-3 pt-0 mt-0  ">
                  
                    <mat-form-field
                          class="example-full-width"
                          appearance="outline"
                        >
                        <mat-label>Device Color</mat-label>
                        <input  matInput placeholder="Device Color" formControlName="deviceColor" />
                     
                        </mat-form-field>
                        <mat-error class="margin_tp"
                        *ngIf="deviceMasterForm.controls['deviceColor'].touched && deviceMasterForm.controls['deviceColor'].errors?.pattern">
                        Please enter valid Device color.
                      </mat-error>
                  </div>
                  <div class="col-sm-3 ">
                    <!-- <mat-form-field
                    class="example-full-width"
                    appearance="outline"
                  >
                    <mat-label>Shell Color</mat-label>
                    <input matInput formControlName="shellColor" placeholder="Shell Color" />
                     
                  </mat-form-field> -->
                  <mat-form-field
                  class="example-full-width"
                  appearance="outline"
                >
                <mat-label>Shell Color</mat-label>
                <input  matInput placeholder="Shell Color"  formControlName="shellColor"  />
               
                </mat-form-field>
                <mat-error class="margin_tp"
                *ngIf="deviceMasterForm.controls['shellColor'].touched && deviceMasterForm.controls['shellColor'].errors?.pattern">
                Please enter valid Shell color.
              </mat-error>
                  </div>
                  <div class="col-sm-3 ">
                    <!-- <mat-form-field
                    class="example-full-width"
                    appearance="outline">
                    <mat-label>Faceplate Color</mat-label>
                    <input matInput formControlName="facePlateColor" placeholder="Faceplate Color" />
                 
                  </mat-form-field> -->
                  <mat-form-field
                  class="example-full-width"
                  appearance="outline"
                >
                <mat-label>Faceplate Color</mat-label>
                <input  matInput placeholder="Faceplate Color" formControlName="facePlateColor"  />
                <!-- <mat-error class="margin_tp"
                *ngIf="deviceMasterForm.controls['facePlateColor'].touched && deviceMasterForm.controls['facePlateColor'].errors?.pattern">
                Please enter valid Faceplate color.
              </mat-error> -->
             
                </mat-form-field>
                <mat-error class="margin_tp"
                *ngIf="deviceMasterForm.controls['facePlateColor'].touched && deviceMasterForm.controls['facePlateColor'].errors?.pattern">
                Please enter valid Faceplate color.
              </mat-error>
                  </div>
                  <div class="col-sm-3"></div>
                  <div class="col-sm-6 ">
                    <mat-form-field
                              class="example-full-width"
                              appearance="outline">
                              <mat-label>Notes</mat-label>
                              <textarea matInput formControlName="notes" maxlength="1000"
                              cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                              cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" 
                         ></textarea>
                            
                            </mat-form-field>
                </div>
</div>

              </div>
              <div class="col-sm-12">
                <div class="row mt-2">
                  
                  <div class="col-sm-12 ">
                    <h5 class="pb-0 mb-0">Bill Details :</h5>
                  <!-- <label class="header">Bill Details :</label> -->
                    </div>
                    <div class="col-sm-3 pt-0 mt-0 ">
                      <mat-form-field
                      class="example-full-width"
                      appearance="outline">
                      <mat-label>MRP(₹)</mat-label>
                      <input required maxlength="8" matInput formControlName="mrp" placeholder="MRP" />
                    </mat-form-field>
                    <mat-error class="margin_tp"
                    *ngIf="deviceMasterForm.controls['mrp'].touched && deviceMasterForm.controls['mrp'].errors?.required">
                    This Field is Mandatory.
                  </mat-error>
                  <mat-error class="margin_tp"
                    *ngIf="deviceMasterForm.controls['mrp'].touched && deviceMasterForm.controls['mrp'].errors?.pattern">
                    Plese Enter Valid MRP
                  </mat-error>
                  </div> 
                    <div class="col-sm-3 ">
                      <mat-form-field class="example-full-width tdW lable_style2  tdW2 widthh" appearance="outline">
                        <mat-label class="lable_style2">GST Applicable</mat-label>
                 
                    <mat-select required (keyup)="gstApplicable();" formControlName="gstApplicable"  >
                          <mat-option (click)="gstApplicable();" value="Yes">Yes</mat-option>
                        <mat-option (click)="gstApplicable();" value="No">No</mat-option>
                       
                      </mat-select>
                  </mat-form-field>
                 
                    </div>
                    <div class="col-sm-6 "></div>
                    <div class="col-sm-3" *ngIf="isGstApplicable">
                      <mat-form-field class="example-full-width tdW2 lable_style2 widthh" appearance="outline">
                        <mat-label class="lable_style2">HSN/SAC</mat-label>
                    <input maxlength="6" placeholder="HSN/SAC" formControlName="has" 
                    matInput type="text" />
                  
                  </mat-form-field>
                  <mat-error class="margin_tp"
                  *ngIf="deviceMasterForm.controls['has'].touched && deviceMasterForm.controls['has'].errors?.pattern">
                  Plese Enter Valid HSN/SAC
                </mat-error>
                <mat-error class="margin_tp"
                  *ngIf="deviceMasterForm.controls['has'].touched && deviceMasterForm.controls['has'].errors?.maxLength">
                  Plese Enter Maximum 6 Digit
                </mat-error>
                <mat-error class="margin_tp"
                  *ngIf="deviceMasterForm.controls['has'].touched && deviceMasterForm.controls['has'].errors?.minlength">
                  Plese Enter Minimum 4 Digit
                </mat-error>
                <mat-error class="margin_tp"
                  *ngIf="deviceMasterForm.controls['has'].touched && deviceMasterForm.controls['has'].errors?.required">
                This Field Is Mandatory
                </mat-error>
                    </div>
                    <div class="col-sm-3"  *ngIf="isGstApplicable">
                      <mat-form-field class="example-full-width tdW2 lable_style2 widthh" appearance="outline">
                        <mat-label class="lable_style2">SGST</mat-label>
                    <input maxlength="3" placeholder="SGST %" formControlName="sgst" 
                    matInput type="text" (input)="igstCalculate()" />
                  
                  </mat-form-field>
                  <mat-error class="margin_tp"
                  *ngIf="deviceMasterForm.controls['sgst'].touched && deviceMasterForm.controls['sgst'].errors?.pattern">
                  Plese Enter Valid SGST
                </mat-error>
                <mat-error class="margin_tp"
                *ngIf="deviceMasterForm.controls['sgst'].touched && deviceMasterForm.controls['sgst'].errors?.required">
              This Field Is Mandatory
              </mat-error>
                    </div>
                    <div class="col-sm-3"  *ngIf="isGstApplicable">
                      <mat-form-field class="example-full-width tdW2 lable_style2 widthh" appearance="outline">
                        <mat-label class="lable_style2">CGST</mat-label>
                    <input maxlength="3" placeholder="CGST %" formControlName="cgst" 
                    matInput type="text" (input)="igstCalculate()"/>
                  
                  </mat-form-field>
                  <mat-error class="margin_tp"
                  *ngIf="deviceMasterForm.controls['cgst'].touched && deviceMasterForm.controls['cgst'].errors?.pattern">
                  Plese Enter Valid CGST
                </mat-error>
                <mat-error class="margin_tp"
                *ngIf="deviceMasterForm.controls['cgst'].touched && deviceMasterForm.controls['cgst'].errors?.required">
              This Field Is Mandatory
              </mat-error>
                    </div>
                    <div class="col-sm-3"  *ngIf="isGstApplicable">
                      <mat-form-field class="example-full-width tdW2 lable_style2 widthh" appearance="outline">
                        <mat-label class="lable_style2">IGST</mat-label>
                    <input maxlength="3" placeholder="IGST %" formControlName="igst" 
                    matInput type="text" />
                  </mat-form-field>
                  <mat-error class="margin_tp"
                  *ngIf="deviceMasterForm.controls['igst'].touched && deviceMasterForm.controls['igst'].errors?.pattern">
                  Plese Enter Valid IGST
                </mat-error>
                <mat-error class="margin_tp"
                *ngIf="deviceMasterForm.controls['igst'].touched && deviceMasterForm.controls['igst'].errors?.required">
              This Field Is Mandatory
              </mat-error>
                  </div>
  </div>
  
                </div>
              <!-- <div *ngIf="isSuperAdmin" class="col-sm-12"> 
                            
                <div class="row">
                  <div class="col-sm-12  ">
                <label class="header">Select Branch :</label>
                  </div>
                  </div>
                  <div class="row">
              
              <div class="col-sm-3">
                <div class="row">
                  <div class="col-sm-10 pr-1"> 
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Branch 
                    
                  </mat-label>
                  <mat-select required #select7 (click)="fetchBranchList();" formControlName="branchRefId" >
                    <mat-select-filter *ngIf="select7.focused" [placeholder]="'Filter'" [displayMember]="'branchName'"
                    [array]="BranchList"  (filteredReturn)="filteredListBranch =$event"></mat-select-filter>
                    <mat-option *ngFor="let item of filteredListBranch" [value]="item._id" 
                    (click)="branchClick(item._id,item.branchName);"
                    >
                     {{ item.branchName }}</mat-option>
                    </mat-select>
                   
                </mat-form-field>
                <mat-error class="margin_tp"
                *ngIf="deviceMasterForm.controls['branchRefId'].touched && deviceMasterForm.controls['branchRefId'].errors?.required">
                This field is mandatory.
              </mat-error>
               </div>
               <div class="col-sm-2 mTop">
                <img (click)="openBranchPopUp();" matTooltip="Add New Branch" width="25" src="../../assets/add_img.png"> 
              </div>
               </div>
              </div>
              </div>
              </div> -->
                <div class="col-sm-10" *ngIf="submitButton"></div>
                <div class="col-sm-8" *ngIf="updateButton"></div>
                        <div *ngIf="submitButton" class="col-sm-2 mt-2 mb-2">
                          <button *ngIf="devicesCreate" class="btn btn-sm btn-primary example-full-width" 
                          (click)="submitDevice()" type="button" >Save </button>
                        </div>
                        <div *ngIf="updateButton" class="col-sm-2 mt-2 mb-2">
                          <button *ngIf="devicesUpdate" class="btn btn-sm btn-primary example-full-width" 
                          (click)="update()" type="button"  >Update</button>
                        </div>
                        <div class="col-sm-2 mt-2 mb-2 " *ngIf="cancelButton">
                          <button class="btn btn-primary btn-sm  example-full-width" (click)="cancelUpdate()" type="reset">Cancel </button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
      </div>
      </div>
        <div class="col-sm-12 pl-0 pr-3">
            <div class="row">
                <div class="card divbody borderradius p-3 pb-0">
                    <div class="col-sm-12">
                         <div class="row">
                            <div class="col-sm-9 ">
                                <h1 class="titlecolor"> Device List</h1>  
                            </div>
                            <div class="search-box col-sm-3 pb-2">
                              <input class="form-control amber-border" type="text" placeholder="Search" 
                              formControlName="searchDevice" aria-label="Search" (keyup)="onKeyUpEvent($event)"
                              >
                              <div class="input-group-append">
                                 <span class="input-group-text amber lighten-3" id="basic-text1"><span class="material-symbols-outlined">search</span></span>
                              </div>
                           </div>
                            <!-- <div class="col-sm-3">
                                <div class="input-group md-form form-sm form-1 pl-0">
                                  <div class="input-group-prepend">
                                    <span class="input-group-text purple lighten-3" id="basic-text1"><i class="fas fa-search text-white"
                                        aria-hidden="true"></i></span>
                                  </div>
                                  <input class="form-control my-0 py-1" formControlName="searchDevice"
                                  (keyup)="onKeyUpEvent($event)" type="text" placeholder="Search" aria-label="Search">
                                </div> 
                            </div> -->
                            <div class="col-sm-12">
                              <table id="toDeviceList" 
                              class="table table-bordered data-table-custom">
                             
                <thead>
                  <tr class="text-center">
                    <th>Device ID</th>
                    <!-- <th *ngIf="userType == 'Super Admin'">Branch Name</th> -->
                    <th>Brand</th>
                    <th>Model</th>
                    <th>Device Type</th>
                    <th>Battery Type</th>
                    <th>Style</th>
                    <th>Ear</th>
                  </tr>
                </thead>
                <tbody>
        
                  <tr
                  *ngFor="let item of devicelist | paginate : { id:'devicepaginate', 
                  itemsPerPage: size, currentPage: page, totalItems:totalLength } "
                    role="row"
                    class="odd text-center" (click)="prepopulation(item._id)"
                    (click)="toDeviceForm()" style="cursor:pointer; text-align: center " >

                    <td matTooltip="click to view">{{item.branchWiseId}}</td>
                    <!-- <td *ngIf="userType == 'Super Admin'" matTooltip="click to view" 
                    >{{item.branchName}}
                  </td> -->
                    <td  matTooltip="click to view">{{item.brandLable}}</td>
                    <td  matTooltip="click to view">{{item.model}}</td>
                    <td  matTooltip="click to view">{{item.deviceType}}</td>
                    <td  matTooltip="click to view">{{item.batteryType}}</td>
                    <td  matTooltip="click to view">{{item.style}}</td>
                    <td  matTooltip="click to view">{{item.ear}}</td>
                    <!-- <td class="text-center">
                      <i matTooltip="View" (click)="prepopulation(item._id)" class="fas fa-eye"></i>
                     </td> -->
                   
                  </tr>
                </tbody>
              </table>
                            </div>
                            <div class="col-sm-12 pt-0 text-right">
                            <pagination-controls id="devicepaginate" directionLinks="true"  
                            (pageChange)="onPageChange($event)" 
                              class="float-right pt-3 my-pagination"></pagination-controls>
                              </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
   
  
    </form>
   
</div>
  </div>
</div>