<div class="container-fluid bgcolor2" [formGroup]="appointmentTypeForm">
  <form [formGroup]="appointmentTypeForm" autocomplete="off" novalidate>
    <div class="row">
        <div class="col-sm-1">

        </div>
        <div class="col-sm-11">
          <div class="row pl-0 ">

            <div class="col-12 pl-0 ">
              <div class="row ">
              <div class="col-sm-9">
              
                  <h1 class="pt-2 pl-0" id="toAppointmentTypeForm">Appointment Type</h1>
                  </div>
                  <div class="col-sm-3 mt-1">
                    <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                      <a (click)="onback()" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">arrow_back</span>Back</a>
                      <a (click)="toAppointmentTypeList()" class="d-flex align-items-center ms-3 pr-2 mt-2"><span class="material-symbols-outlined pr-2">reorder</span>List</a>
                 </div>
            </div>
          </div>
         </div>
         </div>

            <div class="row">
                <div class="col-sm-12 pr-4">
                    <div class="row">
                        <div class="card divbody mb-3 borderradius p-2">
                           
                            <div class="col-sm-12">
                                <div class="row">
                                    <div class="col-sm-1 mt-3 ">
                           
                                        <!-- <mat-form-field *ngIf="userType !== 'Super Admin'" class="example-full-width" appearance="outline">
                                          <mat-label>Appointment Id</mat-label>
                                          <input matInput formControlName="branchWiseId" readonly  />   
                                        </mat-form-field> -->
                                        <!-- <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>Appointment Id</mat-label>
                                          <input matInput [value]="superBranchId" readonly />
                                          </mat-form-field> -->
                                          <mat-label class="center" [value]="superBranchId" ngDefaultControl >{{superBranchId}}</mat-label>
                                      </div>

                                    <div class="col-sm-2 ">
                                        <mat-form-field class="example-full-width " appearance="outline">
                                            <mat-label> Appointment Type
                                                <!-- <span class="required-asterisk">*</span> -->
                                            </mat-label>
                                            <!-- <mat-select   class="colorblack"  formControlName="appointmentType"   >
                                                <mat-select-filter [placeholder]="'Filter'" [displayMember]="'name'"
                                                  [array]="allAppointmentType"  (filteredReturn)="filteredListAppoinmentType =$event"></mat-select-filter>
                                                <mat-option *ngFor="let item of filteredListAppoinmentType" 
                                                 [value]="item.value"
                                                 >
                                                  {{item.name}}
                                                </mat-option>
                                              </mat-select> -->
                                              <input required matInput placeholder="Appointment Type" formControlName="appointmentType" />
                                        </mat-form-field>
                                        <mat-error
                                        *ngIf="appointmentTypeForm.controls['appointmentType'].touched && appointmentTypeForm.controls['appointmentType'].errors?.required">
                                        This field is mandatory.
                                      </mat-error>
                                      <mat-error
                                        *ngIf="appointmentTypeForm.controls['appointmentType'].touched && appointmentTypeForm.controls['appointmentType'].errors?.pattern">
                                        Enter Valid Appointment Type.
                                      </mat-error>
                                    </div>


                                    <div class="col-sm-3 ">
                                <mat-form-field
                                  class="example-full-width"
                                  appearance="outline"
                                >
                                <mat-label>Choose Color</mat-label>
                                <input required class="h18" matInput placeholder="Choose Color" formControlName="color" type="color" (change)="onSelectColor($event)" />
                                
                                </mat-form-field>
                                <mat-error class="margin_tp"
                                *ngIf="appointmentTypeForm.controls['color'].touched && appointmentTypeForm.controls['color'].errors?.required">
                                This field is mandatory.
                              </mat-error>
                                <!-- <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>Choose Color
                                        
                                    </mat-label> -->
                                    <!-- 
    <mat-select required class="colorblack" formControlName="color" >

                                      <mat-option  

                                      *ngFor="let color of allColorr"

                                      (click)="colorClick(color.name)"

                                      [value]="color.hex">{{color.name}}

                                      <i class="fas fa-circle" [ngStyle]="{'color': color.hex}"></i>

                                    </mat-option>

                                    </mat-select>
 -->

                                    <!-- <mat-select  class="colorblack"  formControlName="color"   >
                                        <mat-select-filter [placeholder]="'Filter'" [displayMember]="'name'"
                                          [array]="allColorr"  (filteredReturn)="filteredList =$event"></mat-select-filter>
                                        <mat-option *ngFor="let item of filteredList" 
                                         [value]="item.hex"
                                         (click)="colorClick(item.name)"
                                         >
                                          {{item.name}}
                                        </mat-option> 
                                      </mat-select> 
                                    
                                  </mat-form-field>
                                  <mat-error class="margin_tp"
                                    *ngIf="appointmentTypeForm.controls['color'].touched && appointmentTypeForm.controls['color'].errors?.required">
                                    This field is mandatory.
                                  </mat-error> -->
                              </div> 
                              <!-- <div *ngIf="isSuperAdmin" class="col-sm-3"> 
                                <div class="row">
                                  <div class="col-sm-10 pr-1">
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Branch 
                                   
                                  </mat-label>
                                  <mat-select required #select1 (click)="fetchBranchList();" formControlName="branchRefId" >
                                    <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'branchName'"
                                    [array]="BranchList"  (filteredReturn)="filteredListBranch =$event"></mat-select-filter>
                                    <mat-option *ngFor="let item of filteredListBranch" [value]="item._id" 
                                    (click)="branchClick(item._id,item.branchName);"
                                    >
                                     <table>
                                      <tbody>
                                        <tr class="p-2 pt-5">
                                          <td class="p-3">
                                          {{item._id}}
                                          </td>
                                          <td class="p-3">
                                          {{item.branchName}}
                                          </td>
                                        </tr>
                                      </tbody>
                                     </table>
                                    </mat-option>
                                    </mat-select>
                                 
                                </mat-form-field>
                                <mat-error class="margin_tp"
                                *ngIf="appointmentTypeForm.controls['branchRefId'].touched && appointmentTypeForm.controls['branchRefId'].errors?.required">
                                This field is mandatory.
                              </mat-error>
                              
                               </div>
                               <div class="col-sm-2 mTop">
                                <img (click)="openBranchPopUp();" matTooltip="Add New Branch" width="25" src="../../assets/add_img.png"> 
                              </div>
                          </div>
                         
                         </div> -->
                         


                                    <div class="col-sm-2 offset-sm-10 mb-2 mt-2" *ngIf="submitButton && appointmentTypeCreate"> 
                                        <button class="btn btn-primary btn-sm example-full-width"  (click)="submitAppointmentType()" type="button">Save </button>

                                    </div>
                                    <div class="col-sm-2 offset-sm-8 mb-2 mt-2" *ngIf="updateButton && appointmentTypeUpdate">
                                        <button class="btn btn-primary btn-sm example-full-width"  (click)="updateAppointmentType()" type="button">Update </button>
                                    </div>
                                    <div class="col-sm-2 mb-2 mt-2 " *ngIf="updateButton">
                                        <button class="btn btn-primary btn-sm example-full-width"  (click)="cancelUpdate()" type="reset" >Cancel </button>
                                       
                                    </div>
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 pl-0 pr-2">
                <div class="row">
                    <div class="card divbody borderradius p-3 pb-0">
                            <div class="col-sm-12">
                                <div class="row">
                                    <div class="col-sm-9 ">
                                        <h1 class="titlecolor"> Appointment List</h1>
                                    </div>
                                    <div class="search-box col-sm-3 pb-2">
                                      <input class="form-control amber-border" type="text" placeholder="Search" 
                                      formControlName="searchAppointment" aria-label="Search" (keyup)="onKeyUpEvent($event)"
                                      >
                                      <div class="input-group-append">
                                         <span class="input-group-text amber lighten-3" id="basic-text1"><span class="material-symbols-outlined">search</span></span>
                                      </div>
                                   </div>
                                    <!-- <div class="col-sm-3">
                                        <div class="input-group md-form form-sm form-1 pl-0">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text purple lighten-3" id="basic-text1"><i
                                                        class="fas fa-search text-white" aria-hidden="true"></i></span>
                                            </div>
                                            <input class="form-control my-0 py-1" type="text" placeholder="Search" onkeydown="return (event.keyCode!=13);"
                                                formControlName="searchAppointment" aria-label="Search" (keyup)="onKeyUpEvent($event)" >
                                        </div>
                                    </div> -->
                                    <div class="col-sm-12">
                                      <table id="toAppointmentTypeList" 
                                      class="table table-bordered data-table-custom">
                                       
                                            <thead>
                                                <tr class="text-center">
                                                    <th>Appointment ID</th>
                                                     <!-- <th *ngIf="userType == 'Super Admin'">Branch Name</th> -->
                                                    <th>Appointment Type</th>
                                                    <th>Color </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let appointment of listOfAppointment | paginate : { id:'appointmentMaster',
                                                itemsPerPage: size, currentPage: page, totalItems:totalLength }" 
                                                (click)="prepopulation(appointment._id)"
                                                 (click)="toAppointmentTypeForm()" 
                                                 style="cursor:pointer; text-align: center ">
                                                 <td matTooltip="click to view">{{appointment.branchWiseId}}</td>
                                                 <!-- <td *ngIf="userType == 'Super Admin'" matTooltip="click to view" 
                                                 >{{appointment.branchName}}
                                               </td> -->
                                                    <td matTooltip="click to view">{{appointment.appointmentType}}</td>
                                                    <td matTooltip="click to view">{{appointment.colorName}}
                                                        <i class="fas fa-circle" [ngStyle]="{'color': appointment.color}"></i> 
                                                    </td>

                                                    <!-- <td class="text-center"> <i matTooltip="View"
                                                            class="fas fa-eye" (click)="prepopulation(appointment._id)" ></i> </td> -->
                                                </tr>
                                              
                                            
                                               
                                                <!-- <tr>
                                          <td>July</td>
                                          <td>Dooley</td>
                                          
                                          <td class="text-center"> <i matTooltip="View" class="fas fa-eye"></i> </td>
                                        </tr> -->

                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="col-sm-12 pt-0 text-right">
                                            <pagination-controls id="appointmentMaster" directionLinks="true"
                                  (pageChange)="onPageChange($event)" class="float-right pagination my-pagination"></pagination-controls>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            

        </div>
    </div>
    </form>
</div>