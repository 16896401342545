<div class="container-fluid bgcolor2 p-0"> 
    <div class="row justify-content-center col-sm-12 p-0 " >
    <div class="row col-sm-12 p-0">
        <div class="col-sm-11">
         <h4 class="m-0 col-sm-12 masterStyle">Masters Menu</h4>
            <!-- <h3 class="col-sm-12">Masters Menu</h3> -->
        </div>
        <div class="col-sm-1 justify-content-end">
            <div (click)="onClose()" class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
              <a matTooltip="Close" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">close</span></a>
              </div>
          </div> 
       
  </div> 
    <div class="row col-sm-12 pr-0">
        <div class="icons-wraper dashboard-boxes col-sm-12 pr-0">
            <ul class=" row rowStyle col-sm-12 pr-0">
                
                <li class=" col-sm-2 p-0 m-0 mb-2" *ngIf="clinicView">
                    <a class="icon-card p-0 col-sm-10" (click)="close()" [routerLink]="['/home/clinic-master']"> 
                       <div class="icon-box">
                        <i class="bi bi-house-door icon-img"></i>
                        <img src="../../assets/icon-bg1.svg" alt="" class=" icon-bg">
                       </div>
                       <h4 class="">Clinic</h4>
                    </a>
              </li>
              <li class=" col-sm-2 p-0 m-0 mb-2" *ngIf="branchView">
                <a class="icon-card p-0 col-sm-10" (click)="close()" [routerLink]="['/home/branch-master']">
                   <div class="icon-box">
                    <i class="bi bi-pin-map icon-img"></i>
                    <img src="../../assets/icon-bg2.svg" alt="" class=" icon-bg">
                   </div>
                   <h4 class="">Branch</h4>
                </a>
          </li>
          <li class=" col-sm-2 p-0 m-0 mb-2" *ngIf="billingCompanyView">
            <a class="icon-card p-0 col-sm-10" (click)="close()" [routerLink]="['/home/billing-company']"> 
               <div class="icon-box">
                <i class="bi bi-bank icon-img"></i>
                <img src="../../assets/icon-bg3.svg" alt="" class=" icon-bg">
               </div>
               <h4 class="">Billing Company</h4>
            </a>
      </li>
     
  <li class=" col-sm-2 p-0 m-0 mb-2" *ngIf="vendorView">
   <a class="icon-card p-0 col-sm-10" (click)="close()" [routerLink]="['/home/vendor-master']">
      <div class="icon-box">
       <i class="bi bi-person-lines-fill icon-img"></i>
<img src="../../assets/icon-bg4.svg" alt="" class=" icon-bg">
      </div>
      <h4 class="">Expense Vendor</h4>
   </a>
   </li>
  <li class=" col-sm-2 p-0 m-0 mb-2" *ngIf="devicesView">
    <a class="icon-card p-0 col-sm-10" (click)="close()" [routerLink]="['/home/devices-master']"> 
       <div class="icon-box">
        <i class="bi bi-motherboard icon-img"></i>
        <img src="../../assets/icon-bg5.svg" alt="" class=" icon-bg">
       </div>
       <h4 class="">Devices</h4>
    </a>
</li> 
<li class=" col-sm-2 p-0 m-0 mb-2" *ngIf="doctorView">
<a class="icon-card p-0 col-sm-10" (click)="close()" [routerLink]="['/home/doctor-master']">
   <div class="icon-box">
    <i class="fas fa-user-md icon-img"></i>
    <img src="../../assets/icon-bg6.svg" alt="" class=" icon-bg">
   </div>
   <h4 class="">Audiologist</h4>
</a>
</li>
<li class=" col-sm-2 p-0 m-0 mb-2" *ngIf="clinicalStaffView">
    <a class="icon-card p-0 col-sm-10" (click)="close()" [routerLink]="['/home/clinical-staff-master']">
       <div class="icon-box">
        <i class="bi bi-people icon-img"></i>
        <img src="../../assets/icon-bg7.svg" alt="" class=" icon-bg">
       </div>
       <h4 class="">Clinical Staff</h4>
    </a>
    </li>
    <li class=" col-sm-2 p-0 m-0 mb-2" *ngIf="userView">
        <a class="icon-card p-0 col-sm-10" (click)="close()" [routerLink]="['/home/user-tab']">
           <div class="icon-box">
            <i class="far fa-user-circle icon-img"></i>
            <img src="../../assets/icon-bg8.svg" alt="" class=" icon-bg"> 
           </div>
           <h4 class="">User</h4>
        </a>
        </li>
        <li class=" col-sm-2 p-0 m-0 mb-2" *ngIf="servicesView">
            <a class="icon-card p-0 col-sm-10" (click)="close()" [routerLink]="['/home/services-master']">
               <div class="icon-box">
                <i class="bi bi-journal-check icon-img"></i>
                <img src="../../assets/icon-bg9.svg" alt="" class=" icon-bg"> 
               </div>
               <h4 class="">Services</h4>
            </a>
            </li> 
            <li class=" col-sm-2 p-0 m-0 mb-2" *ngIf="serviceRateView">
                <a class="icon-card p-0 col-sm-10" (click)="close()" [routerLink]="['/home/service-rate']">
                   <div class="icon-box">
                    <img src="../../assets/invoice.png" width="50" class="icon-img">
                    <img src="../../assets/icon-bg10.svg" alt="" class=" icon-bg"> 
                   </div>
                   <h4 class="">Service Rate</h4>
                </a>
                </li>

                <li class=" col-sm-2 p-0 m-0 mb-2" *ngIf="courierServiceProviderView">
                    <a class="icon-card p-0 col-sm-10" (click)="close()" [routerLink]="['/home/curier-service-provider']">
                       <div class="icon-box">
                        <img src="../../assets/box2.png" width="50" class="icon-img" alt="">
                        <img src="../../assets/icon-bg11.svg" alt="" class=" icon-bg"> 
                       </div>
                       <h4 class="">Courier Service</h4>
                    </a>
                    </li>
                    <li class=" col-sm-2 p-0 m-0 mb-2" *ngIf="appointmentTypeView">
                        <a class="icon-card p-0 col-sm-10" (click)="close()" [routerLink]="['/home/appointment/type-appointment']">
                           <div class="icon-box">
                            <i class="bi bi-card-checklist icon-img"></i>
                            <img src="../../assets/icon-bg12.svg" alt="" class=" icon-bg">
                           </div>
                           <h4 class="">Appointment Type</h4>
                        </a>
                        </li>
                        <li class=" col-sm-2 p-0 m-0 mb-2" *ngIf="roleView">
                            <a class="icon-card p-0 col-sm-10" (click)="close()" [routerLink]="['/home/role-master']">
                               <div class="icon-box">
                                <i class="fas fa-tasks icon-img"></i>
                                <img src="../../assets/icon-bg13.svg" alt="" class=" icon-bg">
                               </div>
                               <h4 class="">Role</h4>
                            </a>
                            </li>
                            <li class=" col-sm-2 p-0 m-0 mb-2" *ngIf="genericSettingView">
                                <a class="icon-card p-0 col-sm-10" (click)="controlClick()" [routerLink]="['/home/generic-setting']">
                                   <div class="icon-box">
                                    <img src="../../assets/settings_icon.svg"  class="icon-img"
                                    alt="">
                                    <img src="../../assets/icon-bg14.svg" alt="" class=" icon-bg">
                                   </div>
                                   <h4 class="">Control Panel</h4>
                                </a>
                                </li>
                                <li class=" col-sm-2 p-0 m-0 mb-2" *ngIf="doctorAvailabilityView">
                                    <a class="icon-card p-0 col-sm-10" (click)="close()" [routerLink]="['/home/doctor-availability']">
                                       <div class="icon-box">
                                        <i class="fas fa-stethoscope icon-img"></i>
                                        <img src="../../assets/icon-bg15.svg" alt="" class=" icon-bg">
                                       </div>
                                       <h4 class="">Audiologists Availability</h4>
                                    </a>
                                    </li>
                                    <li class=" col-sm-2 p-0 m-0 mb-2" *ngIf="companyMasterView">
                                       <a class="icon-card p-0 col-sm-10" (click)="close()" [routerLink]="['/home/company-master']">
                                          <div class="icon-box">
                                           <i class="fas fa-building icon-img"></i>
                                           <img src="../../assets/icon-bg6.svg" alt="" class=" icon-bg">
                                          </div>
                                          <h4 class="">Hearing Aid Company</h4>
                                       </a>
                                 </li>
                                 <li class=" col-sm-2 p-0 m-0 mb-2" *ngIf="migratedDataView">
                                    <a class="icon-card p-0 col-sm-10" (click)="close()" [routerLink]="['home/fitted-by-us']">
                                       <div class="icon-box">
                                          <span class="material-symbols-outlined">
                                             analytics
                                             </span>
                                        <!-- <i class="fas fa-building icon-img"></i> -->
                                        <img src="../../assets/icon-bg1.svg" alt="" class=" icon-bg">
                                       </div>
                                       <h4 class="">Migrated Data</h4>
                                    </a>
                              </li>

      </ul>
      </div>
      </div>
     
  
</div>
</div>