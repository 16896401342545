import {
  Component,
  OnInit,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DatePipe } from "@angular/common";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { ApiServiceService } from "../api-service.service";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { PatientSearchPopComponent } from '../patient-search-pop/patient-search-pop.component';
import { PopUpInvoiceComponent } from "../pop-up-invoice/pop-up-invoice.component";
import { PopUpDeviceDataComponent } from "../pop-up-device-data/pop-up-device-data.component";
import { PopUpRepairDataComponent } from "../pop-up-repair-data/pop-up-repair-data.component";
import { PopupTrackingStatusComponent } from "../popup-tracking-status/popup-tracking-status.component";


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  branchRef: string;
  branchData: any;
  greet:any;
  userName: string;
  userType: string;
  userImage: string;
  clinicData: any;
  preId: any;
  logoIcon: string;
  public variables = ['Patient', 'Invoice', 'Device', 'Repair', 'Tracking Status'];
  public filteredList1 = this.variables.slice();
  homeForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
    public dialog: MatDialog,
    public datepipe: DatePipe) { }
  branchData2=[
    {branchName:''},
      ]
  ngOnInit(): void {
    this.branchRef = sessionStorage.getItem('branchRef');
    this.userName = sessionStorage.getItem('userName');
    this.userType = sessionStorage.getItem('userType');
    this.userImage = sessionStorage.getItem('userImage');
    if(this.userType !== 'Super Admin')
    {
    this.getBranchName(this.branchRef);
    }
    else
    {
      this.branchData = this.branchData2;
    }
    this.homeForm = this.fb.group({
      "name": new FormControl(''),  
    });
    this.homeForm.get("name").setValue('Global Search...');
    this.greetMessage();
    this.fetchClinicList();
  }

  
  greetMessage(){
    
    let myDate = new Date();
    let hrs = myDate.getHours();

    if (hrs < 12)
        this.greet = 'Good Morning';
    else if (hrs >= 12 && hrs <= 17)
        this.greet = 'Good Afternoon';
    else if (hrs >= 17 && hrs <= 24)
        this.greet = 'Good Evening';

  }


  getBranchName(id) {
    this.apimicroservice.getBranchById(id).subscribe(res => {
      //
      
     this.branchData = res.data[0];
      //this.vid = id;
       if(res.status){
       

       }else{
     //    //this.apimicroservice.openSnackbar(res.message);
       }
     });
  }
  logout(){
    sessionStorage.removeItem('token');
    sessionStorage.clear();
    localStorage.clear();
  }
  // fetchClinicList() {

  //   let pg = 1;
  //   let size = 5;
  //   let search='';
  //   let clinicRef= sessionStorage.getItem('clinicRef');
  //   this.apimicroservice.getClinicDataById(clinicRef).subscribe(res => {
  //     this.clinicData = res.data.clinicData;
  //     this.preId = res.data.clinicData;
  //     // if(this.clinicData.length >= 0 )
  //     // {
  //       this.prepopulation(this.preId);
  //     // } 
      
  //     if(res.status){
  //      // this.apimicroservice.openSnackbar(res.message);
        
  //     }else{
  //       //this.apimicroservice.openSnackbar(res.message);
  //     }
  // });
  // }
  // prepopulation(clinicRef) {
  // this.apimicroservice.getClinicDataById(clinicRef).subscribe(res => {
  //     let data = res.data[0];
  //     this.logoIcon = data.uploadLogo ? data.uploadLogo : "../../assets/logo-removebg-preview.png"
  // });
  // }

  fetchClinicList() {
    let pg = 1;
    let size = 5;
    let search = '';
    let clinicRef = sessionStorage.getItem('clinicRef');
  
    this.apimicroservice.getClinicDataById(clinicRef).subscribe(res => {
       // Log the entire response to inspect its structure
  
      // Check if the response is an array
      if (Array.isArray(res.data)) {
        this.clinicData = res.data; // Assign the array to clinicData
         // Log the clinicData
  
        // Extract ID from the first item if it exists
        if (this.clinicData.length > 0) {
          this.preId = this.clinicData[0]._id || null; // Adjust this line based on the actual structure
          
  
          if (this.preId) {
            this.prepopulation(this.preId);
          } else {
            console.error('Pre ID is undefined or null');
          }
        } else {
          console.error('clinicData array is empty');
        }
      } else {
        console.error('Expected array but received:', res.data);
      }
    });
  }
  
  prepopulation(id) {
    this.apimicroservice.getClinicDataById(id).subscribe(res => {
       // Log the response for prepopulation
  
      // Handle the response structure for prepopulation
      if (Array.isArray(res.data) && res.data.length > 0) {
        let data = res.data[0];
        this.logoIcon = data.uploadLogo ? data.uploadLogo : "../../assets/clinic_logo2.jpg";
      } else {
        console.error('Invalid data in prepopulation response:', res.data);
      }
    });
  }





  shiv()
  {
    //
    
  }
openDepartmentPopUp() {
  
  let payload = this.homeForm.value;
  //
  if(payload.name == 'Patient')
  {
   localStorage.setItem("fromGlobal",'true');
   const dialogRef3 = this.dialog.open(PatientSearchPopComponent, { 
    width: "97%",
    height: "90%", 
   });  
  
   dialogRef3.afterClosed().subscribe((result) => {
    
    if(result.id == 1)
    {  
     window.location.reload();
    }
   });
  }
  else if(payload.name == 'Invoice')
  { 
    localStorage.setItem("fromGlobal",'true');
   const dialogRef3 = this.dialog.open(PopUpInvoiceComponent, {
    width: "73%", 
   });
   dialogRef3.afterClosed().subscribe((result) => {
   });
  }
  else if(payload.name == 'Device')
  {
    localStorage.setItem("fromGlobal",'true');
   const dialogRef3 = this.dialog.open(PopUpDeviceDataComponent, {
     width: "70%",
   });
   dialogRef3.afterClosed().subscribe((result) => {
   });
  }
  else if(payload.name == 'Repair')
  {
    localStorage.setItem("fromGlobal",'true');
   const dialogRef3 = this.dialog.open(PopUpRepairDataComponent, {
     width: "70%",
   });
   dialogRef3.afterClosed().subscribe((result) => {
   });
  }
  else if(payload.name == 'Tracking Status')
  {
    localStorage.setItem("fromGlobal",'true');
   const dialogRef3 = this.dialog.open(PopupTrackingStatusComponent, {
     width: "70%",
   });
   dialogRef3.afterClosed().subscribe((result) => {
   });
  }
 }
 
}
 