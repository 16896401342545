<div class="container-fluid " [formGroup]="deviceByPatientForm">
    <form [formGroup]="deviceByPatientForm" autocomplete="off" novalidate>
      <div class="row">
          <div class="col-sm-12">
              <div class="row">
                  <div class="col-sm-12">
                      <div class="row"> 
                                                                                                                                                                                                                                             
                              <div class="col-sm-12">
                                  <div class="row col-sm-12">
                                    <div class="col-sm-11">
                                        <h1 class="titlecolor" >Add New Device Not Fitted By Us </h1>  
                                    </div>
                                    <div class="col-sm-1 "> 
                                        <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                                          <a (click)="onClose()" matTooltip="Close" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">close</span></a>
                                          </div>
                                      </div> 
                                   
                                        <div class="col-sm-4">
                                            <mat-form-field class="example-full-width" appearance="outline">
                                              <mat-label>Patient Name</mat-label>
                                              <input readonly matInput placeholder="Patient Name" formControlName="patientName" />
                                            </mat-form-field>
                                            <!-- <mat-error class="margin_tp"
                                            *ngIf="deviceByPatientForm.controls['patientName'].touched && deviceByPatientForm.controls['patientName'].errors?.required">
                                            This field is mandatory.
                                          </mat-error> -->
                                          
                                          </div>
                                         
                                          <div class="col-sm-4">
                                            <div class="row">
                                              <div class="col-sm-10 mr-0 pr-0"> 
                                            <!-- <mat-form-field class="example-full-width" appearance="outline">
                                              <mat-label>Model</mat-label>
                                              <input matInput placeholder="Model" formControlName="model" />
                                            </mat-form-field>
                                            <mat-error class="margin_tp"
                                            *ngIf="deviceByPatientForm.controls['model'].touched && deviceByPatientForm.controls['model'].errors?.required">
                                            This field is mandatory.
                                          </mat-error>  -->


                                          <mat-form-field class="example-full-width  lable_style2" appearance="outline">
                                            <mat-label class="lable_style2">Model</mat-label>
        
                                            <mat-select #select2 formControlName="model" class="colorblack">
                                              <mat-select-filter *ngIf="select2.focused" [placeholder]="'Filter'"
                                                [displayMember]="'model'" [array]="deviceList"
                                                (filteredReturn)="filteredListDevice =$event"></mat-select-filter>
                                              <mat-option *ngFor="let device of filteredListDevice" [(value)]="device.model"
                                                (click)="selectDevice(device._id)">{{ device.model }}</mat-option>
                                            </mat-select>
        
                                          </mat-form-field>
        
                                          <mat-error class="margin_tp"
                                          *ngIf="deviceByPatientForm.controls['model'].touched && deviceByPatientForm.controls['model'].errors?.required">
                                          This field is mandatory.
                                        </mat-error>
                                          </div> 
                                          <div class="col-sm-2 no-print mTop">
                                            <i (click)="openDevicePopUp();" 
                                             matTooltip="Add New Model" class="blue_col mTop ml-0 pl-1 fa-solid fa-circle-plus
                                             pl-1 fa-lg"></i>
                                             </div> 
                                          </div>
                                          </div>
                                       
                                          <div class="col-sm-4">
                                            <mat-form-field class="example-full-width" appearance="outline">
                                              <mat-label>New Serial Number</mat-label>
                                              <input matInput placeholder="New Serial Number" formControlName="newSerialNo" />
                                            </mat-form-field>
                                            <mat-error class="margin_tp"
                                            *ngIf="deviceByPatientForm.controls['newSerialNo'].touched && deviceByPatientForm.controls['newSerialNo'].errors?.required">
                                            This field is mandatory.
                                          </mat-error>
                                          </div>
                                          <div class="col-sm-4 ">
                                            <div class="row">
                                              <div class="col-sm-9 pr-1">
                                            <mat-form-field class="example-full-width" appearance="outline">
                                              <mat-label>Brand
                                               
                                              </mat-label>
                                              <mat-select readonly disabled required #select1 placeholder="Brand"  class="colorblack"  formControlName="brand"   >
                                                <mat-select-filter readonly disabled *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'brandName'"
                                                  [array]="brandlist"  (filteredReturn)="filteredList =$event"></mat-select-filter>
                                                <mat-option *ngFor="let item of filteredList" 
                                                 [value]="item._id" 
                                                 (onSelectionChange)="brandSelect(item.brandName)"> 
                                                  {{item.brandName}}
                                                </mat-option>
                                              </mat-select>
                                            </mat-form-field>
                                            <mat-error class="margin_tp"
                                            *ngIf="deviceByPatientForm.controls['brand'].touched && deviceByPatientForm.controls['brand'].errors?.required">
                                            This field is mandatory.
                                          </mat-error>
                                            </div>
                                            <div class="col-sm-3 mTop">
                                        <i (click)="openBrandPopUp();" matTooltip="Add New Brand" 
                                        class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                                      
                                      </div>
                                      </div>
                                    </div>
                                    <div class="col-sm-4 ">
                                      <div class="row">
                                        <div class="col-sm-9 pr-1">
                                      <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>Device Type
                                         
                                        </mat-label>
                                        <mat-select readonly disabled required #select1 placeholder="Device Type"  class="colorblack"  formControlName="deviceType"   >
                                          <mat-select-filter readonly disabled *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'deviceType'"
                                            [array]="deviceTypelist"  (filteredReturn)="filteredDeviceList =$event"></mat-select-filter>
                                          <mat-option *ngFor="let item of filteredDeviceList" 
                                           [value]="item.deviceType" 
                                           >
                                            {{item.deviceType}}
                                          </mat-option>
                                        </mat-select>
                                      </mat-form-field>
                                      <mat-error class="margin_tp"
                                      *ngIf="deviceByPatientForm.controls['deviceType'].touched && deviceByPatientForm.controls['deviceType'].errors?.required">
                                      This field is mandatory.
                                    </mat-error>
                                      </div>
                                      <div class="col-sm-3 mTop">
                                  <i (click)="openDeviceTypePopUp();" matTooltip="Add New Type" 
                                  class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                                
                                </div>
                                </div>
                              </div>
                                          <div class="col-sm-4">
                                            <mat-form-field class="example-full-width" appearance="outline">
                                              <mat-label>Warranty(Year) </mat-label>
                                              <input matInput placeholder="Warranty" formControlName="warranty" />
                                            </mat-form-field>
                                            <!-- <mat-error
                                            *ngIf="deviceByPatientForm.controls['warranty'].touched && deviceByPatientForm.controls['warranty'].errors?.required">
                                            This field is mandatory.
                                          </mat-error> -->
                                          <mat-error class="margin_tp"
                                          *ngIf="deviceByPatientForm.controls['warranty'].touched && deviceByPatientForm.controls['warranty'].errors?.pattern">
                                          Enter Valid Warranty in Year.
                                        </mat-error>
                                          </div>
                                          <div class="col-sm-4">
                                            <mat-form-field class="example-full-width" appearance="outline">
                                              <mat-label>Date of Purchase</mat-label>
                                              <input formControlName="dateOfPurchase" matInput [matDatepicker]="dateOfPurchase"
                                               id="dateOfPurchase" 
                                             
                                                placeholder="DD/MM/YYYY"  >
                                              <mat-datepicker-toggle matSuffix [for]="dateOfPurchase">
                                              </mat-datepicker-toggle>
                                              <mat-datepicker #dateOfPurchase></mat-datepicker>        
                                            </mat-form-field>
                                            <!-- <mat-error
                                            *ngIf="deviceByPatientForm.controls['dateOfPurchase'].touched && deviceByPatientForm.controls['dateOfPurchase'].errors?.required">
                                            This field is mandatory.
                                          </mat-error> -->
                                          </div>
                                          <div class="col-sm-8">
                                            <mat-form-field class="example-full-width" appearance="outline">
                                              <mat-label>Remark</mat-label>
                                      
                                              <textarea matInput formControlName="freetext" maxlength="1000"
                                              cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                              cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" ></textarea>
                                            </mat-form-field>
                                          </div> 
                                         
                                            <div *ngIf="submitButton" class="col-sm-10"></div>
                                            <div *ngIf="updateButton" class="col-sm-8"></div>
                                        <div *ngIf="submitButton" class="col-sm-2 mt-3 mb-3">
                                            <button class="btn btn-primary btn-sm example-full-width" type="button"
                                             (click)="submitDevice()" >Save</button>
                                          </div>
                                          <div *ngIf="updateButton" class="col-sm-2  mt-3  mb-3">
                                            <button class="btn btn-primary btn-sm example-full-width" type="button"
                                             (click)="updateData()" >Update</button>
                                          </div>
                                          <div *ngIf="updateButton" class="col-sm-2  mt-3  mb-3">
                                            <button class="btn btn-primary btn-sm example-full-width" type="reset"
                                             (click)="cancelUpdate()" >Cancel</button>
                                          </div>
                                          
                                         
                                           
                                              <!-- <div class="row">
                                                <div class="col-sm-8"></div>
                                              <div class="search-box col-sm-4 pb-2">
                                                <input class="form-control amber-border" type="text" placeholder="Search" 
                                                formControlName="searchDevice" aria-label="Search" (keyup)="onKeyUpEvent($event)"
                                                >
                                                <div class="input-group-append">
                                                   <span class="input-group-text amber lighten-3" id="basic-text1"><span class="material-symbols-outlined">search</span></span>
                                                </div> 
                                             </div> 
                                            
                                             </div> -->
                                             <div class="col-sm-12">
                                              <div class="row">
                                                  <div class="col-sm-4  ">
                                                      <h1 class="titlecolor"> Device List</h1>  
                                                  </div>
                                                  <div class="col-sm-5"></div>
                                                  <div class="search-box col-sm-3 pb-2">
                                                    <input class="form-control amber-border" type="text" placeholder="Search" 
                                                    formControlName="searchDevice" aria-label="Search" (keyup)="onKeyUpEvent($event)"
                                                    onkeydown="return (event.keyCode!==91);"> 
                                                    <div class="input-group-append">
                                                       <span class="input-group-text amber lighten-3" id="basic-text1"><span class="material-symbols-outlined">search</span></span>
                                                    </div>
                                                 </div>
                                     
                                                <!-- <div class="col-sm-6">
                                                    <h3 class="titlecolor"> Device List</h3>  
                                                </div> 
                                                <div class="col-sm-6 mb-2">
                                                  <div class="search-box col-sm-4 pb-2">
                                                    <input class="form-control amber-border example-full-width" type="text" placeholder="Search" 
                                                    formControlName="searchDevice" aria-label="Search" (keyup)="onKeyUpEvent($event)"
                                                    >
                                                    <div class="input-group-append">
                                                       <span class="input-group-text amber lighten-3" id="basic-text1"><span class="material-symbols-outlined">search</span></span>
                                                    </div> 
                                                 </div> 
                                                </div> -->
                                                
                                                <div class="col-sm-12 table-responsive"> 
                                                  <table 
                                               class="table table-bordered data-table-custom" style="text-align:center">
                                            
                                <thead>
                                  <tr class="text-center">
                                    <th>Brand</th>
                                    <th>Model</th>
                                    <th>Serial Number</th>
                                    <th>Device Type</th> 
                                    <th>Warranty</th>
                                  </tr>
                                </thead>
                                <tbody>
                        
                                  <tr
                                  *ngFor="let item of devicelist | paginate : { id:'devicepaginate2', 
                                  itemsPerPage: size, currentPage: page, totalItems:totalLength } "
                                    role="row" (click)="prepopulation(item._id)" 
                                    class="odd text-center" 
                                    style="cursor:pointer; text-align: center " >
                
                                    <td *ngIf="item.type !== 'Fitted By Us'" matTooltip="click to view">{{item.brandLable}}</td>
                                    <td *ngIf="item.type !== 'Fitted By Us'" matTooltip="click to view">{{item.model}}</td>
                                    <td *ngIf="item.type !== 'Fitted By Us'" matTooltip="click to view">{{item.newSerialNo}}</td>
                                    <td *ngIf="item.type !== 'Fitted By Us'" matTooltip="click to view">{{item.deviceType}}</td>
                                    <td *ngIf="item.type !== 'Fitted By Us'" matTooltip="click to view">{{item.warranty}}</td>
                                   
                                  </tr>
                                </tbody>
                              </table>
                              <div class="col-sm-12 pt-0 text-right">
                                <pagination-controls id="devicepaginate2" directionLinks="true"  
                                (pageChange)="onPageChange($event)" 
                                  class="float-right pt-3 my-pagination"></pagination-controls>
                                  </div>
                                            </div>
                                        </div>
                                        </div>
                                  </div>
                              </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      </form>
  </div> 