<div class="container-fluid bgcolor2">
    <div class="row">
        <div class="col-sm-1">
        </div>
        <div class="col-sm-11">
            <div class="row">
                <div class="col-sm-12 pr-4">
                    <div class="row pl-0 ">
                        <div class="col-12 pl-0 ">
                            <div class="row ">
                                <div class="col-sm-9">
                                    <h1 class="pt-2 pl-0" id="toCompanyForm">Data Migration</h1>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" [formGroup]="dataMigrationForm">
                        <form class="col" [formGroup]="dataMigrationForm" autocomplete="off" novalidate>
                            <div class="card col-sm-12 borderradius mb-3 pl-4 p-1">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="row mt-2">
                                            <div class="col-sm-12  ">
                                                <h5 class="pb-0 mb-0 mt-1 mb-3">Upload Clinic Master File :</h5>
                                            </div>
                                        </div>

                                        <div class="row pt-2 mb-2">
                                            <div class="col-sm-10">
                                                <!-- <mat-form-field class="example-full-width" appearance="outline"> -->
                                                    <!-- <mat-label>
                                                        Upload Clinic
                                                    </mat-label> -->
                                                    <input class="form-control my-0 myform col-sm-4 example-full-width"
                                                        type="file" placeholder="Clinic File" aria-label="Fota File"
                                                        formControlName="clinicData" accept=".xlsx, .xls" #fileUpload
                                                        (change)="clinicUpload($event)" id="clinicFile"
                                                        onkeydown="return (event.keyCode!=13);" />
                                                    <mat-error class="margin_tp"
                                                        *ngIf="dataMigrationForm.controls['clinicData'].touched && dataMigrationForm.controls['clinicData'].errors?.required">
                                                        This field is mandatory.
                                                    </mat-error>
                                                <!-- </mat-form-field> -->
                                            </div>
                                            <div class="col-sm-2 example-full-width mt-4">
                                                <button type="button" class="btn btn-sm btn-primary example-full-width "  (click)="saveClinic()"
                                                >Upload</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="row" [formGroup]="branchMigrationForm">
                        <form class="col" [formGroup]="branchMigrationForm" autocomplete="off" novalidate>
                            <div class="card col-sm-12 borderradius mb-3 pl-4 p-1">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="row mt-2">
                                            <div class="col-sm-12  ">
                                                <h5 class="pb-0 mb-0 mt-1 mb-3">Upload Branch Master File :</h5>
                                            </div>
                                        </div>

                                        <div class="row pt-2 mb-2">
                                            <div class="col-sm-10">
                                                <!-- <mat-form-field class="example-full-width" appearance="outline"> -->
                                                    <!-- <mat-label>
                                                        Upload Branch Data
                                                    </mat-label> -->
                                                    <input class="form-control my-0 myform col-sm-4 example-full-width"
                                                        type="file" placeholder="Branch File" aria-label="Fota File"
                                                        formControlName="branchData" accept=".xlsx, .xls"
                                                        (change)="branchUpload($event)"
                                                        onkeydown="return (event.keyCode!=13);" />

                                                <!-- </mat-form-field> -->
                                                <mat-error class="margin_tp"
                                                    *ngIf="branchMigrationForm.controls['branchData'].touched && branchMigrationForm.controls['branchData'].errors?.required">
                                                    This field is mandatory.
                                                </mat-error>
                                            </div>
                                            <div class="col-sm-2 example-full-width mt-4">
                                                <button type="button" class="btn btn-sm btn-primary example-full-width " (click)="saveBranch()"
                                                >Upload</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="row" [formGroup]="audiologistsForm">
                        <form class="col" [formGroup]="audiologistsForm" autocomplete="off" novalidate>
                            <div class="card col-sm-12 borderradius mb-3 pl-4 p-1">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="row mt-2">
                                            <div class="col-sm-12  ">
                                                <h5 class="pb-0 mb-0 mt-1 mb-3">Upload Doctor Master File :</h5>
                                            </div>
                                        </div>

                                        <div class="row pt-2 mb-2">
                                            <div class="col-sm-10">
                                                <!-- <mat-form-field class="example-full-width" appearance="outline">
                                                    <mat-label>
                                                        Upload Doctor
                                                    </mat-label> -->
                                                    <input class="form-control my-0 myform col-sm-4 example-full-width"
                                                        type="file" placeholder="Doctor File" aria-label="File"
                                                        formControlName="audiologyData" accept=".xlsx, .xls"
                                                        (change)="audiologistsUpload($event)"
                                                        onkeydown="return (event.keyCode!=13);" />

                                                <!-- </mat-form-field> -->
                                                <mat-error class="margin_tp"
                                                    *ngIf="audiologistsForm.controls['audiologyData'].touched && audiologistsForm.controls['audiologyData'].errors?.required">
                                                    This field is mandatory.
                                                </mat-error>
                                            </div>
                                            <div class="col-sm-2 example-full-width mt-4">
                                                <button type="button" class="btn btn-sm btn-primary example-full-width " (click)="saveAudiologists()"
                                                >Upload</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="row" [formGroup]="patientForm">
                        <form class="col" [formGroup]="patientForm" autocomplete="off" novalidate>
                            <div class="card col-sm-12 borderradius mb-3 pl-4 p-1">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="row mt-2">
                                            <div class="col-sm-12  ">
                                                <h5 class="pb-0 mb-0 mt-1 mb-3">Upload Patient Master File :</h5>
                                            </div>
                                        </div>

                                        <div class="row pt-2 mb-2">
                                            <div class="col-sm-10">
                                                <!-- <mat-form-field class="example-full-width" appearance="outline">
                                                    <mat-label>
                                                        Upload Patient
                                                    </mat-label> -->
                                                    <input class="form-control my-0 myform col-sm-4 example-full-width"
                                                        type="file" placeholder="Patient File" aria-label="File"
                                                        formControlName="patientData" accept=".xlsx, .xls"
                                                        (change)="patientUpload($event)"
                                                        onkeydown="return (event.keyCode!=13);" />

                                                <!-- </mat-form-field> -->
                                                <mat-error class="margin_tp"
                                                    *ngIf="patientForm.controls['patientData'].touched && patientForm.controls['patientData'].errors?.required">
                                                    This field is mandatory.
                                                </mat-error>
                                            </div>
                                            <div class="col-sm-2 example-full-width mt-4">
                                                <button type="button" class="btn btn-sm btn-primary example-full-width " (click)="savePatients()"
                                                >Upload</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="row" [formGroup]="appointmentTypeForm">
                        <form class="col" [formGroup]="appointmentTypeForm" autocomplete="off" novalidate>
                            <div class="card col-sm-12 borderradius mb-3 pl-4 p-1">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="row mt-2">
                                            <div class="col-sm-12  ">
                                                <h5 class="pb-0 mb-0 mt-1 mb-3">Upload Appointment Type Master File :</h5>
                                            </div>
                                        </div>

                                        <div class="row pt-2 mb-2">
                                            <div class="col-sm-10">
                                                <!-- <mat-form-field class="example-full-width" appearance="outline">
                                                    <mat-label>
                                                        Upload Appointment Type
                                                    </mat-label> -->
                                                    <input class="form-control my-0 myform col-sm-4 example-full-width"
                                                        type="file" placeholder="Appointment Type File" aria-label="File"
                                                        formControlName="appintmentTypeData" accept=".xlsx, .xls"
                                                        (change)="appointmentTypeUpload($event)"
                                                        onkeydown="return (event.keyCode!=13);" />

                                                <!-- </mat-form-field> -->
                                                <mat-error class="margin_tp"
                                                    *ngIf="appointmentTypeForm.controls['appintmentTypeData'].touched && appointmentTypeForm.controls['appintmentTypeData'].errors?.required">
                                                    This field is mandatory.
                                                </mat-error>
                                            </div>
                                            <div class="col-sm-2 example-full-width mt-4">
                                                <button type="button" class="btn btn-sm btn-primary example-full-width " (click)="saveAppointmentType()"
                                                >Upload</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="row" [formGroup]="appointmentForm">
                        <form class="col" [formGroup]="appointmentForm" autocomplete="off" novalidate>
                            <div class="card col-sm-12 borderradius mb-3 pl-4 p-1">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="row mt-2">
                                            <div class="col-sm-12  ">
                                                <h5 class="pb-0 mb-0 mt-1 mb-3">Upload Appointment Master File :</h5>
                                            </div>
                                        </div>

                                        <div class="row pt-2 mb-2">
                                            <div class="col-sm-10">
                                                <!-- <mat-form-field class="example-full-width" appearance="outline">
                                                    <mat-label>
                                                        Upload Appointment
                                                    </mat-label> -->
                                                    <input class="form-control my-0 myform col-sm-4 example-full-width"
                                                        type="file" placeholder="appintment File" aria-label="Fota File"
                                                        formControlName="appintmentData" accept=".xlsx, .xls"
                                                        (change)="appointmentUpload($event)"
                                                        onkeydown="return (event.keyCode!=13);" />

                                                <!-- </mat-form-field> -->
                                                <mat-error class="margin_tp"
                                                    *ngIf="appointmentForm.controls['appintmentData'].touched && appointmentForm.controls['appintmentData'].errors?.required">
                                                    This field is mandatory.
                                                </mat-error>
                                            </div>
                                            <div class="col-sm-2 example-full-width mt-4">
                                                <button type="button" class="btn btn-sm btn-primary example-full-width " (click)="saveAppointment()"
                                                >Upload</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="row" [formGroup]="billingCompanyForm">
                        <form class="col" [formGroup]="billingCompanyForm" autocomplete="off" novalidate>
                            <div class="card col-sm-12 borderradius mb-3 pl-4 p-1">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="row mt-2">
                                            <div class="col-sm-12  ">
                                                <h5 class="pb-0 mb-0 mt-1 mb-3">Upload Billing Company File :</h5>
                                            </div>
                                        </div>

                                        <div class="row pt-2 mb-2">
                                            <div class="col-sm-10">
                                                <!-- <mat-form-field class="example-full-width" appearance="outline">
                                                    <mat-label>
                                                        Upload Billing Company File
                                                    </mat-label> -->
                                                    <input class="form-control my-0 myform col-sm-4 example-full-width"
                                                        type="file" placeholder="Billing Comapny File" aria-label="Fota File"
                                                        formControlName="billingCompanyData" accept=".xlsx, .xls"
                                                        (change)="billingCompanyUpload($event)"
                                                        onkeydown="return (event.keyCode!=13);" />

                                                <!-- </mat-form-field> -->
                                                <mat-error class="margin_tp"
                                                    *ngIf="billingCompanyForm.controls['billingCompanyData'].touched && billingCompanyForm.controls['billingCompanyData'].errors?.required">
                                                    This field is mandatory.
                                                </mat-error>
                                            </div>
                                            <div class="col-sm-2 example-full-width mt-4">
                                                <button type="button" class="btn btn-sm btn-primary example-full-width " (click)="saveBillingCompany()"
                                                >Upload</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="row" [formGroup]="brandForm">
                        <form class="col" [formGroup]="brandForm" autocomplete="off" novalidate>
                            <div class="card col-sm-12 borderradius mb-3 pl-4 p-1">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="row mt-2">
                                            <div class="col-sm-12  ">
                                                <h5 class="pb-0 mb-0 mt-1 mb-3">Upload Brand File :</h5>
                                            </div>
                                        </div>

                                        <div class="row pt-2 mb-2">
                                            <div class="col-sm-10">
                                                <!-- <mat-form-field class="example-full-width" appearance="outline">
                                                    <mat-label>
                                                        Upload Brand
                                                    </mat-label> -->
                                                    <input class="form-control my-0 myform col-sm-4 example-full-width"
                                                        type="file" placeholder="Brand File" aria-label="Fota File"
                                                        formControlName="brandData" accept=".xlsx, .xls"
                                                        (change)="brandUpload($event)"
                                                        onkeydown="return (event.keyCode!=13);" />

                                                <!-- </mat-form-field> -->
                                                <mat-error class="margin_tp"
                                                    *ngIf="brandForm.controls['brandData'].touched && brandForm.controls['brandData'].errors?.required">
                                                    This field is mandatory.
                                                </mat-error>
                                            </div>
                                            <div class="col-sm-2 example-full-width mt-4">
                                                <button type="button" class="btn btn-sm btn-primary example-full-width " (click)="saveBrand()"
                                                >Upload</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="row" [formGroup]="deviceTypeForm">
                        <form class="col" [formGroup]="deviceTypeForm" autocomplete="off" novalidate>
                            <div class="card col-sm-12 borderradius mb-3 pl-4 p-1">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="row mt-2">
                                            <div class="col-sm-12  ">
                                                <h5 class="pb-0 mb-0 mt-1 mb-3">Upload Clinic Master File :</h5>
                                            </div>
                                        </div>

                                        <div class="row pt-2 mb-2">
                                            <div class="col-sm-10">
                                                <!-- <mat-form-field class="example-full-width" appearance="outline">
                                                    <mat-label>
                                                        Upload Device Type File
                                                    </mat-label> -->
                                                    <input class="form-control my-0 myform col-sm-4 example-full-width"
                                                        type="file" placeholder="Device Type File" aria-label="Fota File"
                                                        formControlName="deviceTypeData" accept=".xlsx, .xls"
                                                        (change)="deviceTypeUpload($event)"
                                                        onkeydown="return (event.keyCode!=13);" />

                                                <!-- </mat-form-field> -->
                                                <mat-error class="margin_tp"
                                                    *ngIf="deviceTypeForm.controls['deviceTypeData'].touched && deviceTypeForm.controls['deviceTypeData'].errors?.required">
                                                    This field is mandatory.
                                                </mat-error>
                                            </div>
                                            <div class="col-sm-2 example-full-width mt-4">
                                                <button type="button" class="btn btn-sm btn-primary example-full-width " (click)="saveDeviceType()"
                                                >Upload</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="row" [formGroup]="styleForm">
                        <form class="col" [formGroup]="styleForm" autocomplete="off" novalidate>
                            <div class="card col-sm-12 borderradius mb-3 pl-4 p-1">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="row mt-2">
                                            <div class="col-sm-12  ">
                                                <h5 class="pb-0 mb-0 mt-1 mb-3">Upload Style Master File :</h5>
                                            </div>
                                        </div>

                                        <div class="row pt-2 mb-2">
                                            <div class="col-sm-10">
                                                <!-- <mat-form-field class="example-full-width" appearance="outline">
                                                    <mat-label>
                                                        Upload Style
                                                    </mat-label> -->
                                                    <input class="form-control my-0 myform col-sm-4 example-full-width"
                                                        type="file" placeholder="Style File" aria-label="Fota File"
                                                        formControlName="styleData" accept=".xlsx, .xls"
                                                        (change)="styleUpload($event)"
                                                        onkeydown="return (event.keyCode!=13);" />

                                                <!-- </mat-form-field> -->
                                                <mat-error class="margin_tp"
                                                    *ngIf="styleForm.controls['styleData'].touched && styleForm.controls['styleData'].errors?.required">
                                                    This field is mandatory.
                                                </mat-error>
                                            </div>
                                            <div class="col-sm-2 example-full-width mt-4">
                                                <button type="button" class="btn btn-sm btn-primary example-full-width " (click)="saveStyle()"
                                                >Upload</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="row" [formGroup]="batteryTypeForm">
                        <form class="col" [formGroup]="batteryTypeForm" autocomplete="off" novalidate>
                            <div class="card col-sm-12 borderradius mb-3 pl-4 p-1">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="row mt-2">
                                            <div class="col-sm-12  ">
                                                <h5 class="pb-0 mb-0 mt-1 mb-3">Upload Battery Type File :</h5>
                                            </div>
                                        </div>

                                        <div class="row pt-2 mb-2">
                                            <div class="col-sm-10">
                                                <!-- <mat-form-field class="example-full-width" appearance="outline">
                                                    <mat-label>
                                                        Upload Battery Type File
                                                    </mat-label> -->
                                                    <input class="form-control my-0 myform col-sm-4 example-full-width"
                                                        type="file" placeholder="Battery Type File" aria-label="Fota File"
                                                        formControlName="batteryTypeData" accept=".xlsx, .xls"
                                                        (change)="batteryTypeUpload($event)"
                                                        onkeydown="return (event.keyCode!=13);" />

                                                <!-- </mat-form-field> -->
                                                <mat-error class="margin_tp"
                                                    *ngIf="batteryTypeForm.controls['batteryTypeData'].touched && batteryTypeForm.controls['batteryTypeData'].errors?.required">
                                                    This field is mandatory.
                                                </mat-error>
                                            </div>
                                            <div class="col-sm-2 example-full-width mt-4">
                                                <button type="button" class="btn btn-sm btn-primary example-full-width " (click)="saveBatteryType()"
                                                >Upload</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="row" [formGroup]="conditionForm">
                        <form class="col" [formGroup]="conditionForm" autocomplete="off" novalidate>
                            <div class="card col-sm-12 borderradius mb-3 pl-4 p-1">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="row mt-2">
                                            <div class="col-sm-12  ">
                                                <h5 class="pb-0 mb-0 mt-1 mb-3">Upload Condition File :</h5>
                                            </div>
                                        </div>

                                        <div class="row pt-2 mb-2">
                                            <div class="col-sm-10">
                                                <!-- <mat-form-field class="example-full-width" appearance="outline">
                                                    <mat-label>
                                                        Upload Condition
                                                    </mat-label> -->
                                                    <input class="form-control my-0 myform col-sm-4 example-full-width"
                                                        type="file" placeholder="Condition Type File" aria-label="Fota File"
                                                        formControlName="conditionTypeData" accept=".xlsx, .xls"
                                                        (change)="conditionUpload($event)"
                                                        onkeydown="return (event.keyCode!=13);" />

                                                <!-- </mat-form-field> -->
                                                <mat-error class="margin_tp"
                                                    *ngIf="conditionForm.controls['conditionTypeData'].touched && conditionForm.controls['conditionTypeData'].errors?.required">
                                                    This field is mandatory.
                                                </mat-error>
                                            </div>
                                            <div class="col-sm-2 example-full-width mt-4">
                                                <button type="button" class="btn btn-sm btn-primary example-full-width " (click)="saveCondition()"
                                                >Upload</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="row" [formGroup]="deviceForm">
                        <form class="col" [formGroup]="deviceForm" autocomplete="off" novalidate>
                            <div class="card col-sm-12 borderradius mb-3 pl-4 p-1">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="row mt-2">
                                            <div class="col-sm-12  ">
                                                <h5 class="pb-0 mb-0 mt-1 mb-3">Upload Device File :</h5>
                                            </div>
                                        </div>

                                        <div class="row pt-2 mb-2">
                                            <div class="col-sm-10">
                                                <!-- <mat-form-field class="example-full-width" appearance="outline">
                                                    <mat-label>
                                                        Upload Device
                                                    </mat-label> -->
                                                    <input class="form-control my-0 myform col-sm-4 example-full-width"
                                                        type="file" placeholder="Device File" aria-label="Fota File"
                                                        formControlName="deviceData" accept=".xlsx, .xls"
                                                        (change)="deviceUpload($event)"
                                                        onkeydown="return (event.keyCode!=13);" />

                                                <!-- </mat-form-field> -->
                                                <mat-error class="margin_tp"
                                                    *ngIf="deviceForm.controls['deviceData'].touched && deviceForm.controls['deviceData'].errors?.required">
                                                    This field is mandatory.
                                                </mat-error>
                                            </div>
                                            <div class="col-sm-2 example-full-width mt-4">
                                                <button type="button" class="btn btn-sm btn-primary example-full-width " (click)="saveDevice()"
                                                >Upload</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="row" [formGroup]="salesPersonForm">
                        <form class="col" [formGroup]="salesPersonForm" autocomplete="off" novalidate>
                            <div class="card col-sm-12 borderradius mb-3 pl-4 p-1">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="row mt-2">
                                            <div class="col-sm-12  ">
                                                <h5 class="pb-0 mb-0 mt-1 mb-3">Upload Sales Person File :</h5>
                                            </div>
                                        </div>

                                        <div class="row pt-2 mb-2">
                                            <div class="col-sm-10">
                                                <!-- <mat-form-field class="example-full-width" appearance="outline">
                                                    <mat-label>
                                                        Upload Sales Person
                                                    </mat-label> -->
                                                    <input class="form-control my-0 myform col-sm-4 example-full-width"
                                                        type="file" placeholder="Sales Person File" aria-label="Fota File"
                                                        formControlName="salesPersonData" accept=".xlsx, .xls"
                                                        (change)="salesPersonUpload($event)"
                                                        onkeydown="return (event.keyCode!=13);" />

                                                <!-- </mat-form-field> -->
                                                <mat-error class="margin_tp"
                                                    *ngIf="salesPersonForm.controls['salesPersonData'].touched && salesPersonForm.controls['salesPersonData'].errors?.required">
                                                    This field is mandatory.
                                                </mat-error>
                                            </div>
                                            <div class="col-sm-2 example-full-width mt-4">
                                                <button type="button" class="btn btn-sm btn-primary example-full-width " (click)="saveSalesPerson()"
                                                >Upload</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="row" [formGroup]="companyForm">
                        <form class="col" [formGroup]="companyForm" autocomplete="off" novalidate>
                            <div class="card col-sm-12 borderradius mb-3 pl-4 p-1">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="row mt-2">
                                            <div class="col-sm-12  ">
                                                <h5 class="pb-0 mb-0 mt-1 mb-3">Upload Company File :</h5>
                                            </div>
                                        </div>

                                        <div class="row pt-2 mb-2">
                                            <div class="col-sm-10">
                                                <!-- <mat-form-field class="example-full-width" appearance="outline">
                                                    <mat-label>
                                                        Upload Company
                                                    </mat-label> -->
                                                    <input class="form-control my-0 myform col-sm-4 example-full-width"
                                                        type="file" placeholder="Company File" aria-label="Fota File"
                                                        formControlName="companyData" accept=".xlsx, .xls"
                                                        (change)="companyUpload($event)"
                                                        onkeydown="return (event.keyCode!=13);" />

                                                <!-- </mat-form-field> -->
                                                <mat-error class="margin_tp"
                                                    *ngIf="companyForm.controls['companyData'].touched && companyForm.controls['companyData'].errors?.required">
                                                    This field is mandatory.
                                                </mat-error>
                                            </div>
                                            <div class="col-sm-2 example-full-width mt-4">
                                                <button type="button" class="btn btn-sm btn-primary example-full-width " (click)="saveCompany()"
                                                >Upload</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="row" [formGroup]="earmoldForm">
                        <form class="col" [formGroup]="earmoldForm" autocomplete="off" novalidate>
                            <div class="card col-sm-12 borderradius mb-3 pl-4 p-1">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="row mt-2">
                                            <div class="col-sm-12  ">
                                                <h5 class="pb-0 mb-0 mt-1 mb-3">Upload Earmold File :</h5>
                                            </div>
                                        </div>

                                        <div class="row pt-2 mb-2">
                                            <div class="col-sm-10">
                                                <!-- <mat-form-field class="example-full-width" appearance="outline">
                                                    <mat-label>
                                                        Upload Earmold
                                                    </mat-label> -->
                                                    <input class="form-control my-0 myform col-sm-4 example-full-width"
                                                        type="file" placeholder="Earmold File" aria-label="Fota File"
                                                        formControlName="earmoldData" accept=".xlsx, .xls"
                                                        (change)="earmoldUpload($event)"
                                                        onkeydown="return (event.keyCode!=13);" />

                                                <!-- </mat-form-field> -->
                                                <mat-error class="margin_tp"
                                                    *ngIf="earmoldForm.controls['earmoldData'].touched && earmoldForm.controls['earmoldData'].errors?.required">
                                                    This field is mandatory.
                                                </mat-error>
                                            </div>
                                            <div class="col-sm-2 example-full-width mt-4">
                                                <button type="button" class="btn btn-sm btn-primary example-full-width " (click)="saveEarmold()"
                                                >Upload</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="row" [formGroup]="repairForm">
                        <form class="col" [formGroup]="repairForm" autocomplete="off" novalidate>
                            <div class="card col-sm-12 borderradius mb-3 pl-4 p-1">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="row mt-2">
                                            <div class="col-sm-12  ">
                                                <h5 class="pb-0 mb-0 mt-1 mb-3">Upload Repair File :</h5>
                                            </div>
                                        </div>

                                        <div class="row pt-2 mb-2">
                                            <div class="col-sm-10">
                                                <!-- <mat-form-field class="example-full-width" appearance="outline">
                                                    <mat-label>
                                                        Upload Repair
                                                    </mat-label> -->
                                                    <input class="form-control my-0 myform col-sm-4 example-full-width"
                                                        type="file" placeholder="Repair File" aria-label="Fota File"
                                                        formControlName="repairData" accept=".xlsx, .xls"
                                                        (change)="repairUpload($event)"
                                                        onkeydown="return (event.keyCode!=13);" />

                                                <!-- </mat-form-field> -->
                                                <mat-error class="margin_tp"
                                                    *ngIf="repairForm.controls['repairData'].touched && repairForm.controls['repairData'].errors?.required">
                                                    This field is mandatory.
                                                </mat-error>
                                            </div>
                                            <div class="col-sm-2 example-full-width mt-4">
                                                <button type="button" class="btn btn-sm btn-primary example-full-width " (click)="saveRepair()"
                                                >Upload</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="row" [formGroup]="customHearingAidForm">
                        <form class="col" [formGroup]="customHearingAidForm" autocomplete="off" novalidate>
                            <div class="card col-sm-12 borderradius mb-3 pl-4 p-1">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="row mt-2">
                                            <div class="col-sm-12  ">
                                                <h5 class="pb-0 mb-0 mt-1 mb-3">Upload Custom Hearing Aid File :</h5>
                                            </div>
                                        </div>

                                        <div class="row pt-2 mb-2">
                                            <div class="col-sm-10">
                                                <!-- <mat-form-field class="example-full-width" appearance="outline">
                                                    <mat-label>
                                                        Upload Custom Hearing Aid
                                                    </mat-label> -->
                                                    <input class="form-control my-0 myform col-sm-4 example-full-width"
                                                        type="file" placeholder="Custom Hearing File" aria-label="Fota File"
                                                        formControlName="customHearingAidData" accept=".xlsx, .xls"
                                                        (change)="chaUpload($event)"
                                                        onkeydown="return (event.keyCode!=13);" />

                                                <!-- </mat-form-field> -->
                                                <mat-error class="margin_tp"
                                                    *ngIf="customHearingAidForm.controls['customHearingAidData'].touched && customHearingAidForm.controls['customHearingAidData'].errors?.required">
                                                    This field is mandatory.
                                                </mat-error>
                                            </div>
                                            <div class="col-sm-2 example-full-width mt-4">
                                                <button type="button" class="btn btn-sm btn-primary example-full-width " (click)="saveCHA()"
                                                >Upload</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="row" [formGroup]="communicationForm">
                        <form class="col" [formGroup]="communicationForm" autocomplete="off" novalidate>
                            <div class="card col-sm-12 borderradius mb-3 pl-4 p-1">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="row mt-2">
                                            <div class="col-sm-12  ">
                                                <h5 class="pb-0 mb-0 mt-1 mb-3">Upload Communication Data File :</h5>
                                            </div>
                                        </div>

                                        <div class="row pt-2 mb-2">
                                            <div class="col-sm-10">
                                                <!-- <mat-form-field class="example-full-width" appearance="outline">
                                                    <mat-label>
                                                        Upload Communication Data
                                                    </mat-label> -->
                                                    <input class="form-control my-0 myform col-sm-4 example-full-width"
                                                        type="file" placeholder="Communication Data File" aria-label="Fota File"
                                                        formControlName="communicationData" accept=".xlsx, .xls"
                                                        (change)="communicationUpload($event)"
                                                        onkeydown="return (event.keyCode!=13);" />

                                                <!-- </mat-form-field> -->
                                                <mat-error class="margin_tp"
                                                    *ngIf="communicationForm.controls['communicationData'].touched && communicationForm.controls['communicationData'].errors?.required">
                                                    This field is mandatory.
                                                </mat-error>
                                            </div>
                                            <div class="col-sm-2 example-full-width mt-4">
                                                <button type="button" class="btn btn-sm btn-primary example-full-width " (click)="saveCommunication()"
                                                    >Upload</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="row" [formGroup]="deviceByPatientForm">
                        <form class="col" [formGroup]="deviceByPatientForm" autocomplete="off" novalidate>
                            <div class="card col-sm-12 borderradius mb-3 pl-4 p-1">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="row mt-2">
                                            <div class="col-sm-12  ">
                                                <h5 class="pb-0 mb-0 mt-1 mb-3">Upload Device By Patient Data File :</h5>
                                            </div>
                                        </div>

                                        <div class="row pt-2 mb-2">
                                            <div class="col-sm-10">
                                                <input class="form-control my-0 myform col-sm-4 example-full-width" type="file"
                                                    placeholder="Device By Patient Data File" aria-label="Fota File" formControlName="deviceByPatientData"
                                                    accept=".xlsx, .xls" (change)="deviceByPatientUpload($event)" onkeydown="return (event.keyCode!=13);" />
                                                <!-- </mat-form-field> -->
                                                <mat-error class="margin_tp"
                                                    *ngIf="deviceByPatientForm.controls['deviceByPatientData'].touched && deviceByPatientForm.controls['deviceByPatientData'].errors?.required">
                                                    This field is mandatory.
                                                </mat-error>
                                            </div>
                                            <div class="col-sm-2 example-full-width mt-4">
                                                <button type="button" class="btn btn-sm btn-primary example-full-width "
                                                    (click)="saveDeviceByPatient()">Upload</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>