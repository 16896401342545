<div class="container-fluid bgcolor2" [formGroup]="branchMasterForm">
  <form [formGroup]="branchMasterForm" autocomplete="off" novalidate>
    <div class="row">
      <div class="col-sm-1">

      </div>
      <div class="col-sm-11">
        <div class="row pl-0 ">

          <div class="col-12 pl-0 ">
            <div class="row ">
            <div class="col-sm-9">
            
                <h1 class="pt-2 pl-0" id="toBranchForm">Branch</h1>
                </div>
                <div class="col-sm-3 mt-1">
                  <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                    <!-- <img matTooltip="Back" class="mb-2 pb-2 mr-2" width=35rem src="../../assets/back.png" (click)="onback()">
                    <img src="../../assets/copy.png" class="mb-2 pb-2 mr-2" (click)="copyFromClinic()" matTooltip="Copy From Clinic"
                      width="35rem">
                    <img src="../../assets/list.png" (click)="toBranchList()" class="mb-2 pb-2" matTooltip="List" width="35rem"> 
                  -->
                  
                    <a (click)="onback()" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">arrow_back</span>Back</a>
                    <a (click)="copyFromClinic()" class="d-flex align-items-center ms-3 pr-2 mt-2"><span class="material-symbols-outlined pr-2">content_copy</span>From Clinic</a>
                    <a (click)="toBranchList()" class="d-flex align-items-center ms-3 pr-2 mt-2"><span class="material-symbols-outlined pr-2">reorder</span>List</a>
             
             </div>
          </div>
        </div>
       </div>
       </div>

        <!-- <div class="row">
          <div class="col-sm-9">
            <div class="row ">

              <span><img src="../../assets/branch.png" width="35rem"> </span>
              <span>
                <h1 class="fass ml-3 pt-1" id="toBranchForm">Branch</h1>
              </span>
            </div>

          </div>


          <div class="col-sm-3 mb-2">
            <div class="row justify-content-end mr-1">
              <img matTooltip="Back" class="mb-2 pb-2 mr-2" width=35rem src="../../assets/back.png" (click)="onback()">
              <img src="../../assets/copy.png" class="mb-2 pb-2 mr-2" (click)="copyFromClinic()" matTooltip="Copy From Clinic"
                width="35rem">
              <img src="../../assets/list.png" (click)="toBranchList()" class="mb-2 pb-2" matTooltip="List" width="35rem">
            </div>
          </div>
        </div> -->

        <div class="row">
          <div class="col-sm-12 pr-4">
            <div class="row">
              <div class="card divbody borderradius p-1">


                <div class="col-sm-12">
                  <div class="row mt-2 pl-2 pr-2">
                    <div class="col-sm-12">
                      <div class="row"> 
                        <div class="col-sm-12 pt-1 mt-1  ">
                          <h5 class="pb-0 mb-0">Branch Details :</h5>
                          <!-- <label class="header">Branch Details :</label> -->
                        </div>
                      </div>
                      <div class="row  pt-0 mt-0">
                          <div class="col-sm-1 mt-3  ">
                            <!-- <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Branch Id</mat-label>
                              <input matInput formControlName="branchId" readonly />
                            </mat-form-field> -->
                            <mat-label class="center"  formControlName="branchId" ngDefaultControl >{{bId}}</mat-label>
                          </div> 
                        <div class="col-sm-2  "> 
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Clinic Name
                              <!-- <span class="required-asterisk">*</span>   -->
                            </mat-label>
                            <!-- <mat-select required class="colorblack" formControlName="clinicRefId" >
                                        <mat-option  
                                        *ngFor="let clinic of clinicList" 
                                        (click)="onSelectClinic(clinic._id)" 
                                        [value]="clinic._id">{{clinic.clinicName}}</mat-option>
                                      </mat-select> -->

                            <mat-select #select3 placeholder="Clinic Name" class="colorblack"
                              formControlName="clinicRefId">
                              <mat-select-filter *ngIf="select3.focused" [placeholder]="'Filter'"
                                [displayMember]="'clinicName'" [array]="clinicList"
                                (filteredReturn)="filteredListClinic =$event" (click)="fetchClinicData()">
                              </mat-select-filter>
                              <mat-option *ngFor="let item of filteredListClinic" [value]="item._id"
                                (click)="onSelectClinic(item._id)">
                                {{item.clinicName}}
                              </mat-option>
                            </mat-select>


                          </mat-form-field>
                          <mat-error
                            *ngIf="branchMasterForm.controls['clinicRefId'].touched && branchMasterForm.controls['clinicRefId'].errors?.required">
                            This field is mandatory.
                          </mat-error>
                        </div>
                        <div class="col-sm-3  ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Branch Code
                              <!-- <span class="required-asterisk">*</span>   -->
                            </mat-label>
                            <input maxlength="10" required matInput placeholder="Branch Code"
                              formControlName="branchCode" />

                          </mat-form-field>
                          <mat-error
                            *ngIf="branchMasterForm.controls['branchCode'].touched && branchMasterForm.controls['branchCode'].errors?.required">
                            This field is mandatory.
                          </mat-error>
                          <mat-error class="margin_tp"
                            *ngIf="branchMasterForm.controls['branchCode'].touched && branchMasterForm.controls['branchCode'].errors?.pattern">
                            Please enter valid branch code.
                          </mat-error>
                          <!-- <mat-error class="margin_tp"
                            *ngIf="branchMasterForm.controls['branchCode'].touched && branchMasterForm.controls['branchCode'].errors?.minlength">
                            Please enter 6 digit branch code.
                          </mat-error> -->
                        </div>
                        <div class="col-sm-3  ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Branch Name
                              <!-- <span class="required-asterisk">*</span>     -->
                            </mat-label>
                            <input required matInput placeholder="Branch Name" formControlName="branchName" />

                          </mat-form-field>
                          <mat-error class="margin_tp"
                            *ngIf="branchMasterForm.controls['branchName'].touched && branchMasterForm.controls['branchName'].errors?.required">
                            This field is mandatory.
                          </mat-error>
                          <mat-error class="margin_tp"
                            *ngIf="branchMasterForm.controls['branchName'].touched && branchMasterForm.controls['branchName'].errors?.pattern">
                            Please enter valid branch Name.
                          </mat-error>
                        </div>
                        <div class="col-sm-3  ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Branch Type
                              <!-- <span class="required-asterisk">*</span>   -->
                            </mat-label>
                            <mat-select required class="colorblack" formControlName="branchType">
                              <mat-option value="Permanent">Permanent</mat-option>
                              <mat-option value="Visiting">Visiting</mat-option>

                            </mat-select>

                          </mat-form-field>
                          <mat-error class="margin_tp"
                            *ngIf="branchMasterForm.controls['branchType'].touched && branchMasterForm.controls['branchType'].errors?.required">
                            This field is mandatory.
                          </mat-error>
                        </div>

                      </div>
                      <div class="row">

                        <div class="col-sm-12 pt-1 mt-1  ">
                          <h5 class="pb-0 mb-0">Time Details :</h5>
                          <!-- <label class="header">Time Details :</label> -->
                        </div>
                      </div>
                      <div class="row pt-0 mt-0">


                        <div class="col-sm-3  ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Open Time</mat-label>
                            <!-- <input formControlName="openTime" matInput id="appt-time" 
                            (change)="toOpenTimeChange()"  type="time" name="appt-time" [min]="'07:00 am'"
                              [max]="'09:00 pm'"
                              step="2"> -->
                            <input required matInput placeholder="08:00 am" formControlName="openTime"
                              [ngxTimepicker]="pickerF" value="" [disableClick]="true" readonly />
                            <ngx-material-timepicker-toggle matSuffix [for]="pickerF"></ngx-material-timepicker-toggle>
                            <ngx-material-timepicker (closed)="toOpenTimeChange()" #pickerF></ngx-material-timepicker>
                          </mat-form-field>
                          <mat-error class="margin_tp"
                            *ngIf="branchMasterForm.controls['openTime'].touched && branchMasterForm.controls['openTime'].errors?.required">
                            This field is mandatory.
                          </mat-error>
                        </div>
                        <div class="col-sm-3  ">

                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Close Time</mat-label>
                            <!-- <input formControlName="closeTime" matInput id="appt-time2" 
                            (change)="toCloseTimeChange()" type="time" name="appt-time2" [min]="'08:00 am'"
                              [max]="'09:00 pm'"
                              step="2"> -->
                            <input required matInput placeholder="08:00 pm" formControlName="closeTime"
                              [ngxTimepicker]="pickerA" value="" [disableClick]="true" readonly />
                            <ngx-material-timepicker-toggle matSuffix [for]="pickerA"></ngx-material-timepicker-toggle>
                            <ngx-material-timepicker (closed)="toCloseTimeChange()" #pickerA></ngx-material-timepicker>
                          </mat-form-field>
                          <mat-error class="margin_tp"
                            *ngIf="branchMasterForm.controls['closeTime'].touched && branchMasterForm.controls['closeTime'].errors?.required">
                            This field is mandatory.
                          </mat-error>
                        </div>
                        <div class="col-sm-3  ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Weekly Off</mat-label>
                            <mat-select [disableOptionCentering]="true" #select1 placeholder="Weekly Off"
                              class="colorblack" formControlName="branchWeeklyOff">
                              <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'"
                                [displayMember]="'name'" [array]="allDays" (filteredReturn)="filteredListDays =$event">
                              </mat-select-filter>
                              <mat-option *ngFor="let item of filteredListDays" [value]="item.value">
                                {{item.name}}
                              </mat-option>
                            </mat-select>

                          </mat-form-field>
                          <!-- <mat-error
                          *ngIf="branchMasterForm.controls['branchWeeklyOff'].touched && branchMasterForm.controls['branchWeeklyOff'].errors?.required">
                          This field is mandatory.
                        </mat-error> -->

                        </div>

                      </div>

                    </div>
                    <div class="col-sm-12">
                      <div class="row">

                        <div class="col-sm-12 pt-1 mt-1  ">
                          <h5 class="pb-0 mb-0">Address Details :</h5>
                          <!-- <label class="header">Address Details :</label> -->
                        </div>
                      </div>
                      <div class="row pt-0 mt-0">
                        <div class="col-sm-3   ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Street Address
                              <!-- <span class="required-asterisk">*</span>   -->
                            </mat-label>
                            <input required matInput placeholder="Street Address"
                              formControlName="branchStreetAddress" />

                          </mat-form-field>
                          <mat-error class="margin_tp"
                            *ngIf="branchMasterForm.controls['branchStreetAddress'].touched && branchMasterForm.controls['branchStreetAddress'].errors?.required">
                            This field is mandatory.
                          </mat-error>
                        </div>
                        <div class="col-sm-3  ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Taluka</mat-label>
                            <input matInput placeholder="Taluka" formControlName="taluka" />

                          </mat-form-field>
                          <mat-error class="margin_tp"
                            *ngIf="branchMasterForm.controls['taluka'].touched && branchMasterForm.controls['taluka'].errors?.pattern">
                            Please enter valid taluka.
                          </mat-error>
                        </div>
                        <div class="col-sm-3  ">
                          <!-- <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>District</mat-label>
                            <input matInput placeholder="District" formControlName="district" />

                          </mat-form-field> -->
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>District</mat-label>
                            <mat-select   #select12 placeholder="District" class="colorblack"  formControlName="district"   >
                              <mat-select-filter  *ngIf="select12.focused" [placeholder]="'Filter'" [displayMember]="'district'"
                                [array]="districtData2" (filteredReturn)="filteredListDistrict2 =$event"></mat-select-filter>
                              <mat-option *ngFor="let item of filteredListDistrict2" 
                               [value]="item.district" >
                                {{item.district}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                          <mat-error class="margin_tp"
                            *ngIf="branchMasterForm.controls['district'].touched && branchMasterForm.controls['district'].errors?.pattern">
                            Please enter valid district.
                          </mat-error>
                          <!-- <mat-error class="margin_tp"
                            *ngIf="branchMasterForm.controls['district'].touched && branchMasterForm.controls['district'].errors?.required">
                            This Field is Mandatory.
                          </mat-error> -->
                        </div>
                        <div class="col-sm-3  ">
                          <!-- <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>State</mat-label>
                            <mat-select required #select2 placeholder="State" class="colorblack" formControlName="state">
                              <mat-select-filter *ngIf="select2.focused" [placeholder]="'Filter'"
                                [displayMember]="'name'" [array]="allState" (filteredReturn)="filteredList =$event">
                              </mat-select-filter>
                              <mat-option *ngFor="let item of filteredList" [value]="item.value">
                                {{item.name}}
                              </mat-option>
                            </mat-select>

                          </mat-form-field> -->
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>State</mat-label>
                            <mat-select required  #select11 placeholder="State" class="colorblack"  formControlName="state"   >
                              <mat-select-filter  *ngIf="select11.focused" [placeholder]="'Filter'" [displayMember]="'state'"
                                [array]="stateCityArr2"  (filteredReturn)="filteredList2 =$event"></mat-select-filter>
                              <mat-option *ngFor="let item of filteredList2" 
                               [value]="item.state" (click)="getDistrict2(item.districts)">
                                {{item.state}}
                              </mat-option>
                            </mat-select>
                          
                          </mat-form-field>
                          <mat-error class="margin_tp"
                          *ngIf="branchMasterForm.controls['state'].touched && branchMasterForm.controls['state'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                        </div>
                        <div class="col-sm-3  ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Pin Code
                              <!-- <span class="required-asterisk">*</span>   -->
                            </mat-label>
                            <input required matInput maxlength="6" placeholder="Pincode(e.g:416008)"
                              formControlName="branchPinCode" />

                          </mat-form-field>
                          <mat-error class="margin_tp"
                            *ngIf="branchMasterForm.controls['branchPinCode'].touched && branchMasterForm.controls['branchPinCode'].errors?.required">
                            This field is mandatory.
                          </mat-error>
                          <mat-error class="margin_tp"
                            *ngIf="branchMasterForm.controls['branchPinCode'].touched && branchMasterForm.controls['branchPinCode'].errors?.pattern">
                            Please enter valid Pin Code.
                          </mat-error>
                        </div>
           <div class="col-sm-3  ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Country</mat-label>
                            <input matInput placeholder="country" formControlName="country" />

                          </mat-form-field>
                          <mat-error class="margin_tp"
                            *ngIf="branchMasterForm.controls['country'].touched && branchMasterForm.controls['country'].errors?.pattern">
                            Please enter valid country.
                          </mat-error>
                        </div>
                        
                      
                     
                     
                      </div>

                    </div>
                  </div>
                  <div class="row pl-2 pr-2">
                    <div class="col-sm-12">
                      <div class="row">

                        <div class="col-sm-12 pt-1 mt-1  ">
                          <h5 class="pb-0 mb-0">Contact Details :</h5>
                          <!-- <label class="header">Contact Details :</label> -->
                        </div>
                      </div>
                      <div class="row pt-0 mt-0 ">
                        <div class="col-sm-3  ">
                          <div class="row">
                            <div class="col-sm-10">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Branch Phone Number
                              <!-- <span class="required-asterisk">*</span>   -->
                            </mat-label>
                            <input required matTooltip="Branch Phone Number" maxlength="10" matInput
                              placeholder="Branch Phone Number" formControlName="branchPhoneNumber" />

                          </mat-form-field>
                          <mat-error class="margin_tp"
                            *ngIf="branchMasterForm.controls['branchPhoneNumber'].touched && branchMasterForm.controls['branchPhoneNumber'].errors?.required">
                            This field is mandatory.
                          </mat-error>
                          <mat-error class="margin_tp"
                            *ngIf="branchMasterForm.controls['branchPhoneNumber'].touched && branchMasterForm.controls['branchPhoneNumber'].errors?.pattern">
                            Please enter valid branch Phone Number.
                          </mat-error>
                          
                        </div>
                        <div class="col-sm-2  wApp ml-0 pl-0">
                          <i class="bi bi-whatsapp whatsapp-icon wApp fa-lg" (click)="WhatsApp();" matTooltip="WhatsApp Integration" ></i>
                              
                          <!-- <img (click)="WhatsApp();" class="ml-0 pl-0 mt-2" matTooltip="WhatsApp Integration"
                            width="25" src="../../assets/whatsappGreen.png"> -->
                        </div>
                      </div>
                        </div> 
                        <div class="col-sm-3  ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Branch Landline
                            </mat-label>
                            <input matInput maxlength="15" placeholder=" Landline eg 0223-666666"  class="emailClass" formControlName="branchLandline" />

                          </mat-form-field>
                          <mat-error class="margin_tp"
                            *ngIf="branchMasterForm.controls['branchLandline'].touched && branchMasterForm.controls['branchLandline'].errors?.pattern">
                            Please enter valid  branch Landline.
                          </mat-error>
                        </div>
                        <div class="col-sm-3  ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Branch Email Id
                            </mat-label>
                            <input matInput placeholder="Branch Email Id" class="emailClass" formControlName="branchEmailId" />

                          </mat-form-field>
                          <mat-error class="margin_tp"
                            *ngIf="branchMasterForm.controls['branchEmailId'].touched && branchMasterForm.controls['branchEmailId'].errors?.pattern">
                            Please enter valid branch email id.
                          </mat-error>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <div class="row">

                        <div class="col-sm-12 pt-1 mt-1  ">
                          <h5 class="pb-0 mb-0">Other Details :</h5>
                          <!-- <label class="header">Other Details :</label> -->
                        </div>
                      </div>
                      <div class="row pt-0 mt-0">
                        <div class="col-sm-3  ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Branch Status</mat-label>

                            <mat-select formControlName="branchStatus">
                              <mat-option value="Active">Active</mat-option>
                              <mat-option value="In-active">In-active</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row pl-2 pr-2 mb-2">

                    <div class="col-sm-10" *ngIf="submitButton"></div>
                    <div class="col-sm-2 mt-2" *ngIf="submitButton && branchCreate">
                      <button type="button" class="btn btn-primary btn-sm mb-2 example-full-width"
                        (click)="submitBranch()">Save
                      </button>
                    </div>
                    <div class="col-sm-8" *ngIf="updateButton"></div>
                    <div class="col-sm-2 mt-2" *ngIf="updateButton && branchUpdate">
                      <button type="button" class="btn btn-primary btn-sm mb-2 example-full-width"
                        (click)="updateBranchDetails()">Update</button>
                    </div>
                    <div class="col-sm-2 mt-2" *ngIf="updateButton">
                      <button class="btn btn-primary btn-sm mb-2 example-full-width" (click)="cancelUpdate()"
                        type="reset">Cancel
                      </button>
                    </div>


                  </div>




                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="row mt-3">
          <div class="col-sm-12 pr-4">

            <div class="row">
              <div class="card col-sm-12 borderradius mb-2 p-3 pb-0">
                <div class="col-sm-12">
                  <div class="row">
                    <div class="col-sm-9  ">
                      <h1 class="titlecolor">List Of Branch</h1>
                    </div> 
                    <div class="search-box col-sm-3 pb-2">
                      <input class="form-control amber-border" type="text" placeholder="Search" 
                      formControlName="searchBranch" aria-label="Search" (keyup)="onKeyUpEvent($event)"
                      >
                      <div class="input-group-append">
                         <span class="input-group-text amber lighten-3" id="basic-text1"><span class="material-symbols-outlined">search</span></span>
                      </div>
                   </div>
                    <!-- <div class="col-sm-3">
                      <div class="input-group md-form form-sm form-1 pl-0">
                        <div class="input-group-prepend">
                          <span class="input-group-text purple lighten-3" id="basic-text1"><i
                              class="fas fa-search text-white" aria-hidden="true"></i></span>
                        </div>
                        <input class="form-control my-0 py-1" type="text" formControlName="searchBranch"
                          aria-label="Search" (keyup)="onKeyUpEvent($event)" placeholder="Search" aria-label="Search">
                      </div>
                    </div> -->
                    
                  <div class="col-sm-12 table-responsive">
                    <table id="toPlaceOrderList"
                 class="table table-bordered data-table-custom">

                   
                        <thead>

                          <tr>
                            <th>Branch Id</th>
                            <th>Branch Code</th>
                            <th>Branch Name</th>
                            <th>Branch Address</th>
                            <th>Branch Pin Code</th>
                            <th>Branch PhoneNumber</th>
                            <th>Branch Type</th>


                          </tr>
                        </thead>
                        <tbody>
                          <tr (click)="toBranchForm()" (click)="prepopulation(item._id)" *ngFor="
                                                let item of listOfBranch
                                                | paginate : { id:'branch_pagination', 
                                                itemsPerPage: size, currentPage: page, totalItems:totalLength }
                                              " style="cursor: pointer;">
                            <td matTooltip="click to view">{{item.branchId}}</td>
                            <td matTooltip="click to view">{{item.branchCode}}</td>
                            <td matTooltip="click to view">{{item.branchName}}</td>
                            <td matTooltip="click to view">{{item.branchStreetAddress}}</td>
                            <td matTooltip="click to view">{{item.branchPinCode}}</td>
                            <td matTooltip="click to view">{{item.branchPhoneNumber}}</td>
                            <td matTooltip="click to view">{{item.branchType}}</td>


                          </tr>

                        </tbody>
                      </table>
                    </div>
                    <div class="col-sm-12 pt-0 text-right">
                      <pagination-controls id="branch_pagination" directionLinks="true"
                        (pageChange)="onPageChange($event)" class="float-right pt-3 my-pagination">
                      </pagination-controls>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>

        </div>
      </div>
    </div>
  </form>
</div>