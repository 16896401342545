import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
 import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { AfterViewInit, Directive, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { PopUpBranchComponent } from "../pop-up-branch/pop-up-branch.component";
import { PopUpExpenseTypeComponent } from "../pop-up-expense-type/pop-up-expense-type.component";
import { PdfviewerComponent } from "../pdfviewer/pdfviewer.component";
@Component({
  selector: 'app-clinic-expenses',
  templateUrl: './clinic-expenses.component.html',
  styleUrls: ['./clinic-expenses.component.scss']
})
export class ClinicExpensesComponent implements OnInit {
  clinicExpensesForm: FormGroup;
  date: any;
  listOfExpenses: any;
  totalLength:any;
  size=10;
  page=1;
  searchText = '';
  submitButton = true;
  updateButton = false;
  errormessage: string;
  clientImage: any;
  fileName:any;
  // allExpensesType=[
  //   {name:'Telephone Expenses', value:'Telephone Expenses'},
  //   {name:'Travelling Expenses', value:'Travelling Expenses' },
  //   {name:'Office Equipment and Supplies',value:'Office Equipment and Supplies'},
  //    { name:'Electricity / Utility',value:'Electricity / Utility' },
  //    { name:'Property Tax', value:'Property Tax' },
  //     {name:'Legal Expenses', value:'Legal Expenses'},
  //     { name:'Bank Charges', value:'Bank Charges' },
  //      {name:'Repair and Maintenance Expenses', value:'Repair and Maintenance Expenses'},
  //      { name:'Insurance Expenses',value:'Insurance Expenses'},
  //       { name:'Advertising Expenses', value:'Advertising Expenses'},
  //       { name:'Rent',value:'Rent' },
  //        {name:'Courier charges',value:'Courier charges' }, 
  // ]
  
  allExpensesType = [];
  filteredList: any;
  filteredListAppoinmentType: { name: string; value: string; }[];
  userType: string;
  branchRef: string;
  clinicRef: string;
  branchList: any;
  isSuperAdmin= true;
  superBranchId: any;
  branchName: string;
  filteredListBranch: any;
  currentdate: Date;
  expenseId: any;
  modal: any;
  modalImg: any;
  branchCreate = true;
  vendorCreate = true;
  pid: any;
  vendorList: any;
  filteredListVendor: any;
  hide = false;
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
     public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService 
  ) { }
  userPermission:any;
  clinicExpensesCreate= true;
  clinicExpensesUpdate= true;

  ngOnInit(): void {
    this.currentdate = new Date();
    this.page=1;
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.userType = sessionStorage.getItem('userType');
    //this.filteredList = this.allExpensesType.slice();
    this.userType = sessionStorage.getItem('userType');
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    if(this.userType !== 'Super Admin'){
      this.isSuperAdmin= false;
    this.userPermission.forEach((element) => {
      if(element.pageName == 'Clinic Expenses' && element.create == false)
      {
          this.clinicExpensesCreate = false;  
      }
      if(element.pageName == 'Clinic Expenses' && element.update == false)
      {
          this.clinicExpensesUpdate = false;  
      } 
      if(element.pageName == 'Branch' && element.create == false)
      {
          this.branchCreate = false;  
      }  
      if(element.pageName == 'Vendor' && element.create == false)
      {
          this.vendorCreate = false;  
      }  
    });
  }
    this.clinicExpensesForm = this.fb.group({
      "branchWiseId": new FormControl(''),
      "date": new FormControl('',Validators.required),
      "amount": new FormControl('',[Validators.required,Validators.pattern("^[0-9]*$"),Validators.minLength(2),Validators.maxLength(7)]),
      "expensesType": new FormControl('',Validators.required),
      "vendorRefId": new FormControl('',Validators.required), 
      "expenseName" : new FormControl('',[Validators.required,Validators.pattern("^[a-zA-Z0-9_@.,*'!~S/#&+-\\s]*$")]),
      "fileUpload": new FormControl(),
      "searchExpenses": new FormControl(),
      "branchRefId": new FormControl(),
      "clinicRefId": new FormControl(),
      "branchName": new FormControl(),
      "createdAt": new Date(),

      "vendorName":new FormControl(''),
      "fileName":new FormControl(''),
      
    });
    
    this.clinicExpensesForm.controls['date'].setValue(this.currentdate);
    this.fetchVendorList();
    this.clientImage = "";
    this.fileName = '';
    this.hide = false;
    //
    //this.removeValidator();
    this.getLastId();
    this.fetchClinicExpensesList();
    if(this.userType == 'Super Admin'){
      this.fetchBranchList();
      this.getAllBranch();
    }
    this.fetchExpenseTypeList();
    this.modal = document.getElementById("myModal");
    this.modalImg = document.getElementById("img01");
   
  }
  
fetchVendorList(){
    let id = '';
    this.apimicroservice.getVendorById(id).subscribe(res => {
     this.vendorList = res.data;
     this.filteredListVendor= this.vendorList.slice();
     if(res.status){
       //this.apimicroservice.openSnackbar(res.message);    
     }else{
       //this.apimicroservice.openSnackbar(res.message);
     }
  });
  //}
  
}
onSelectVendor(vdname){
  this.clinicExpensesForm.controls['vendorName'].setValue(vdname);
}
  getAllBranch()
  {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      if (res.status) {        
        let length = res.data.length - 1;
        this.branchName = res.data[length].branchName;
        this.clinicExpensesForm.controls["branchRefId"].setValue(res.data[length]._id);
        this.branchClick(res.data[length]._id,this.branchName);
        //
        //
        if(this.userType == 'Super Admin')
        {  
          this.apimicroservice.getBranchById(res.data[length]._id).subscribe(res => {
            this.spinner.hide();
            this.clinicExpensesForm.get("branchName").setValue(res.data[length].branchName);
            //
            
           });
        }
      }
    });
  }
  fetchClinicExpensesList(){
    // let pg = 1;
    // let size = 15;
   this.spinner.show();
   let clinicRef= sessionStorage.getItem('clinicRef');
   let branchRef= sessionStorage.getItem('branchRef');
   let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
   let branchRefSuperAdmin= '';
    let search = '';
    this.searchText = '';
    //
    if(this.userType !== 'Super Admin')
     {
      this.apimicroservice.getClinicExpensesData(this.page,this.size,search,branchRef,clinicRef).subscribe(res => {
        this.spinner.hide();
        //
        this.listOfExpenses = res.data.data;
        this.totalLength = res.data.totalItems;
        
       if(res.status){
        // this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
      });
     }else{
      this.apimicroservice.getClinicExpensesData(this.page,this.size,search,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
        this.spinner.hide();
        //
        this.listOfExpenses = res.data.data;
        this.totalLength = res.data.totalItems;
        //this.buildingLenght = this.listofbuild.length;
       if(res.status){
        // this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
      });
     }
  }

  // setValidator() { 
  //   this.clinicExpensesForm.controls['date'].setValidators(Validators.required);
  //   this.clinicExpensesForm.controls['date'].updateValueAndValidity();

  //   this.clinicExpensesForm.controls['amount'].setValidators([Validators.required,Validators.pattern("^[0-9]*$"),Validators.minLength(2),Validators.maxLength(7)]);
  //   this.clinicExpensesForm.controls['amount'].updateValueAndValidity();

  //   this.clinicExpensesForm.controls['expensesType'].setValidators(Validators.required);
  //   this.clinicExpensesForm.controls['expensesType'].updateValueAndValidity();

  //   this.clinicExpensesForm.controls['expenseName'].setValidators([Validators.required,Validators.pattern("^[a-zA-Z ]*$")]);
  //   this.clinicExpensesForm.controls['expenseName'].updateValueAndValidity();
    
  // }

  // removeValidator() { 
  //   this.clinicExpensesForm.controls['date'].setErrors(null);
  //   this.clinicExpensesForm.controls['date'].clearValidators();
  //   this.clinicExpensesForm.controls['date'].updateValueAndValidity();

  //   this.clinicExpensesForm.controls['amount'].setErrors(null);
  //   this.clinicExpensesForm.controls['amount'].clearValidators();
  //   this.clinicExpensesForm.controls['amount'].updateValueAndValidity();

  //   this.clinicExpensesForm.controls['expensesType'].setErrors(null);
  //   this.clinicExpensesForm.controls['expensesType'].clearValidators();
  //   this.clinicExpensesForm.controls['expensesType'].updateValueAndValidity();

  //   this.clinicExpensesForm.controls['expenseName'].setErrors(null);
  //   this.clinicExpensesForm.controls['expenseName'].clearValidators();
  //   this.clinicExpensesForm.controls['expenseName'].updateValueAndValidity();

  // }

  submitClinicExpenses(){
  //  this.setValidator();
    let payload = this.clinicExpensesForm.value;
    
    if(this.userType == 'Super Admin')
    {
      this.clinicExpensesForm.controls['branchRefId'].setValidators(Validators.required);
      this.clinicExpensesForm.controls['branchRefId'].updateValueAndValidity();
    }
    else{
      this.clinicExpensesForm.controls['branchRefId'].setErrors(null);
      this.clinicExpensesForm.controls['branchRefId'].clearValidators();
      this.clinicExpensesForm.controls['branchRefId'].updateValueAndValidity();
    }
    this.clinicExpensesForm.controls['vendorRefId'].setValidators(Validators.required);
      this.clinicExpensesForm.controls['vendorRefId'].updateValueAndValidity();
    if (this.clinicExpensesForm.invalid) {
      
      this.clinicExpensesForm.get("vendorRefId").markAsTouched();
       this.clinicExpensesForm.get("date").markAsTouched();
       this.clinicExpensesForm.get("amount").markAsTouched();
       this.clinicExpensesForm.get("expensesType").markAsTouched();
       this.clinicExpensesForm.get("expenseName").markAsTouched();
       this.clinicExpensesForm.get("branchRefId").markAsTouched();

    }else {
      this.addClinicExpenses();
    }
  }
  
  addClinicExpenses(){
    this.spinner.show();
    let payload = this.clinicExpensesForm.value;
    payload.fileUpload = this.clientImage;
    payload.fileName = this.fileName;
    //
    if(this.userType !== 'Super Admin')
    {
      payload.branchRefId = this.branchRef;
      payload.clinicRefId = this.clinicRef;
      payload.branchName = this.branchName;
    }
    
    this.apimicroservice.addClinicExpenses(payload).subscribe(res => {
      this.spinner.hide();
      
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);
        this.hide = false;
        this.ngOnInit();
      //  this.clinicExpensesForm.reset();
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    },(ERROR:HttpErrorResponse) => {
      this.apimicroservice.openSnackbar(ERROR.error.message);
      this.spinner.hide();
  });  
  }

  prepopulation(id) {
    this.apimicroservice.getClinicExpensesDataById(id).subscribe(res => {
      //
      let data = res.data[0];
      this.expenseId = id;
    
         this.updateButton = true;
         this.submitButton = false;
         //this.cancelButton = true;
         
        this.clinicExpensesForm = this.fb.group({
          "branchWiseId": new FormControl(data.branchWiseId),
          "date": new FormControl(data.date, Validators.required),
          "amount": new FormControl(data.amount, [Validators.required,Validators.pattern("^[0-9]*$"),Validators.minLength(2),Validators.maxLength(7)]),
          "expensesType": new FormControl(data.expensesType, Validators.required),
          "expenseName" : new FormControl(data.expenseName,[Validators.required,Validators.pattern("^[a-zA-Z0-9_@.,*'!~S/#&+-\\s]*$")]),
          "fileUpload": new FormControl(data.fileUpload),
          "searchExpenses": new FormControl(),
          "branchRefId": new FormControl(data.branchRefId),
          "clinicRefId": new FormControl(data.clinicRefId),
          "branchName": new FormControl(data.branchName),
          "updatedAt": new Date(),
          "vendorRefId": new FormControl(data.vendorRefId,Validators.required), 
          "vendorName":new FormControl(data.vendorName),
          "fileName":new FormControl(data.fileName),
          
        });
           this.superBranchId = data.branchWiseId;
           this.clientImage = data.fileUpload ? data.fileUpload : "../../assets/no_data_found.jpeg"
           this.fileName = data.fileName ? data.fileName: "no_data";
           this.hide = true;
     });
  }

  updateClinicExpenses(){
    //this.setValidator();
    let payload = this.clinicExpensesForm.value;
    
    if(this.userType == 'Super Admin')
    {
      this.clinicExpensesForm.controls['branchRefId'].setValidators(Validators.required);
      this.clinicExpensesForm.controls['branchRefId'].updateValueAndValidity();
    }
    else{
      this.clinicExpensesForm.controls['branchRefId'].setErrors(null);
      this.clinicExpensesForm.controls['branchRefId'].clearValidators();
      this.clinicExpensesForm.controls['branchRefId'].updateValueAndValidity();
    }
    
    if (this.clinicExpensesForm.invalid) {
      this.clinicExpensesForm.get("vendorRefId").markAsTouched();
       this.clinicExpensesForm.get("date").markAsTouched();
       this.clinicExpensesForm.get("amount").markAsTouched();
       this.clinicExpensesForm.get("expensesType").markAsTouched();
       this.clinicExpensesForm.get("expenseName").markAsTouched();
       this.clinicExpensesForm.get("branchRefId").markAsTouched();
    }else {
      this.update();
    }
  }

  update(){
    this.spinner.show();
    let payload = this.clinicExpensesForm.value;
    payload.fileUpload = this.clientImage;
    payload.fileName  = this.fileName;
    if(this.userType !== 'Super Admin')
    {
      payload.branchRefId = this.branchRef;
      payload.clinicRefId = this.clinicRef;
      payload.branchName = this.branchName;
    }
     this.apimicroservice.updateClinicExpenses(payload,this.expenseId).subscribe(res => {
       //
       this.hide = false;
       this.spinner.hide();
       if(res.status){
         this.apimicroservice.openSnackbar(res.message);
         this.clientImage = "../../assets/no_data_found.jpeg";
         this.fileName = "no_data"
          this.updateButton = false;
          this.submitButton = true;
         //this.cancelButton=false;
          this.ngOnInit();
         // this.clinicExpensesForm.reset();
       }else{
       //  //this.apimicroservice.openSnackbar(res.message);
       }
     },(ERROR:HttpErrorResponse) => {
      this.apimicroservice.openSnackbar(ERROR.error.message);
      this.spinner.hide();
  });
  }


  onKeyUpEvent(event: any) {
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    this.searchText = this.clinicExpensesForm.get('searchExpenses').value;
    let page=1;
    let size=10;
    if(this.userType !== 'Super Admin')
     {
      this.apimicroservice.getClinicExpensesData(page,size,this.searchText,branchRef,clinicRef).subscribe(res => {
        this.spinner.hide();
        //
        this.listOfExpenses = res.data.data;
        this.totalLength = res.data.totalItems;
        this.page=res.data.pageNumber;
       if(res.status){
      //   this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
      });
     }else{
      this.apimicroservice.getClinicExpensesData(page,size,this.searchText,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
        this.spinner.hide();
        //
        this.listOfExpenses = res.data.data;
        this.totalLength = res.data.totalItems;
        this.page=res.data.pageNumber;
        //this.buildingLenght = this.listofbuild.length;
       if(res.status){
      //   this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
      });
     }
  }
  onPageChange(event) {
    this.page = event;
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    this.searchText = '';
//

    if(this.userType !== 'Super Admin')
     {
      this.apimicroservice.getClinicExpensesData(this.page,this.size,this.searchText,branchRef,clinicRef).subscribe(res => {
        this.spinner.hide();
        //
        this.listOfExpenses = res.data.data;
        this.totalLength = res.data.totalItems;
        
       if(res.status){
       //  this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
      });
     }else{
      this.apimicroservice.getClinicExpensesData(this.page,this.size,this.searchText,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
        this.spinner.hide();
        //
        this.listOfExpenses = res.data.data;
        this.totalLength = res.data.totalItems;
        //this.buildingLenght = this.listofbuild.length;
       if(res.status){
         //this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
      });
     }
  }
  cancelUpdate(){
    this.clientImage = "../../assets/no_data_found.jpeg";
    this.fileName = "no_data";
    this.submitButton=true;
    this.updateButton=false;
    this.hide = false;
    this.ngOnInit();
    this.clinicExpensesForm.reset();
    this.currentdate = new Date();
    this.clinicExpensesForm.controls['date'].setValue(this.currentdate);
  }

  openSnackbar(message: string) {
    return this._snackBar.open(message, "close", {
      verticalPosition: "bottom",
      horizontalPosition: "right",
      duration: 2000,
      panelClass: ["snackbarStyle"],
    });
  }
  toClinicExpensesForm() {
    document.getElementById("toClinicExpensesForm").scrollIntoView();
  }
  toClinicExpensesList() {
    document.getElementById("toClinicExpensesList").scrollIntoView();
  }

  getLastId()
{
  if(this.userType !== 'Super Admin'){
  this.apimicroservice.getLastClinicExpenseID(this.branchRef).subscribe(res => {
    //   
    if (res['status']) {
      
        let clientIdd = res.data.totalItems;
      //
      
      this.clinicExpensesForm.controls['branchWiseId'].setValue(clientIdd + 1);
      
    } else {
      this.apimicroservice.openSnackbar("Something went wrong");
    }
   
  })
}
else
{
  this.apimicroservice.getLastAllClinicExpenseID().subscribe(res => {
    //   
    if (res['status']) {
      
        let clientIdd = res.data.totalItems;
      //
      
      this.superBranchId = clientIdd + 1;
      
      //this.clinicExpensesForm.controls['branchWiseId'].setValue(clientIdd + 1);
    } else {
      this.apimicroservice.openSnackbar("Something went wrong");
    }
   
  })
}
}

   openBranchPopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpBranchComponent,
      {
        width: "1200px",
      });
      dialogRef3.afterClosed().subscribe((result) => {
        let clinicRef= sessionStorage.getItem('clinicRef');
        this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
          //  
          if (res.status) {    
            this.branchList = res.data;
            let branchId;
           
            this.filteredListBranch= this.branchList.slice();
           this.branchClick(res.data[0]._id,res.data[0].branchName);
            this.clinicExpensesForm.get("branchRefId").setValue(res.data[0]._id)
          } 
        })
       // this.fetchBranchList();
      })
  }

  fetchBranchList() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      //  
      if (res.status) {    
        this.branchList = res.data;
        let branchId;
        // this.branchList.map(element =>{
        //   if(element.branchName == 'Home'){
        //     branchId = element._id;
        //     this.clinicExpensesForm.controls['branchRefId'].setValue(branchId);
        //   }
        // })
        this.filteredListBranch= this.branchList.slice();
      } else {
       // this.apimicroservice.openSnackbar(res.message);
      }
    })
  } 
  branchClick(id,branchName)
  {
    this.clinicExpensesForm.controls["branchName"].setValue(branchName);

   this.apimicroservice.getBranchById(id).subscribe(res => { 
     let payload = this.clinicExpensesForm.value;
      payload.clinicRefId = res.data[0].clinicRefId;
      //
      this.clinicExpensesForm.controls["clinicRefId"].setValue(res.data[0].clinicRefId);
      if(this.updateButton == false){
        this.spinner.show();
        this.apimicroservice.getLastClinicExpenseID(payload.branchRefId).subscribe(res => {
          //   
          if (res['status']) {
            let clientIdd = res.data.totalItems;
            //
      
            this.clinicExpensesForm.controls['branchWiseId'].setValue(clientIdd + 1);
          } else {
            this.apimicroservice.openSnackbar("Something went wrong");
          }
          this.spinner.hide();
        })
      }
     
    });
  }

  closeClick()
  {
    this.modal.style.display = "none";
  }
  imgClick()
  {
    this.modal.style.display = "block";
    this.modalImg.src = this.clientImage;
  }
  onChange($event: Event) {
    const file = ($event.target as HTMLInputElement).files[0];
    const fileName = file.name;
    this.errormessage = '';
    let ext = fileName.substring(fileName.lastIndexOf(".") + 1);
    if (
      ext.toLowerCase() == "png" ||
      ext.toLowerCase() == "jpg" ||
      ext.toLowerCase() == "jpeg" ||
      ext.toLowerCase() == "csv" ||
      ext.toLowerCase() == "xlsx" ||
      ext.toLowerCase() == "pdf" ||
      ext.toLowerCase() == "docx" 
    ) { 
      this.convertToBase64(file);
      this.hide = true; 
      this.fileName = fileName;
    }
  }
  convertToBase64(file: File) {
    const observable = new Observable((subscriber: Subscriber<any>) => {
      this.readFile(file, subscriber);
    });
    observable.subscribe((d) => {
      this.clientImage = d;
     
    });
  }
  readFile(file: File, subscriber: Subscriber<any>) {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      subscriber.next(fileReader.result);
      subscriber.complete();
    };
    fileReader.onerror = (error) => {
      subscriber.error(error);
      subscriber.complete();
    };
  }

  openExpensePopUp() {
    //localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpExpenseTypeComponent,
      {
        width: "500px",
      });

      dialogRef3.afterClosed().subscribe((result) => {
        let clinicRef= sessionStorage.getItem('clinicRef');
        let branchRef= sessionStorage.getItem('branchRef');
        let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
        let branchRefSuperAdmin= '';
        if (this.userType !== 'Super Admin') {
        this.apimicroservice.getExpenseList(branchRef,clinicRef).subscribe(res => {
          //
           this.allExpensesType = res.data;   
           this.filteredList = this.allExpensesType.slice();
           this.onSelectExpence(res.data[0]._id);
           this.clinicExpensesForm.get("expensesType").setValue(res.data[0].expenceType);
        });  
      }else{
        this.apimicroservice.getExpenseList(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
          //
           this.allExpensesType = res.data;   
           this.filteredList = this.allExpensesType.slice();
           this.onSelectExpence(res.data[0]._id);
           this.clinicExpensesForm.get("expensesType").setValue(res.data[0].expenceType);
        }); 
      }
        //this.fetchExpenseTypeList();  
       });
  }


   onSelectExpence(id){
    //this.patientSelect=true;
    this.apimicroservice.getExpenseById(id).subscribe(res => {
      let data = res.data[0];
      this.pid = id;
    });
}
  fetchExpenseTypeList() { 
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getExpenseList(branchRef,clinicRef).subscribe(res => {
        //
         this.allExpensesType = res.data;   
         this.filteredList = this.allExpensesType.slice();
      });  
    }else{
      this.apimicroservice.getExpenseList(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
        //
         this.allExpensesType = res.data;   
         this.filteredList = this.allExpensesType.slice();
      });  
    }
  }
  onback() {
    this.apimicroservice.back()
  }

  showbase64(e){
    const dialogRef3 = this.dialog.open(PdfviewerComponent,
      {
        width: "1200px",
        data: e
      });
  }
  remove(){
    this.hide = false;
  }
}
