<div class="container-fluid bgcolor2" [formGroup]="serviceRateForm">
  <form [formGroup]="serviceRateForm" autocomplete="off" novalidate>
  <div class="row">
      <div class="col-sm-1">
       
      </div>
      <div class="col-sm-11">
        <div class="row pl-0 ">

          <div class="col-12 pl-0 ">
            <div class="row ">
            <div class="col-sm-9">
            
                <h1 class="pt-2 pl-0" id="toServiceForm">Service Rate</h1>
                </div>
                <div class="col-sm-3 mt-1">
                  <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                    <a (click)="onback()" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">arrow_back</span>Back</a>
                </div>
          </div>
        </div>
       </div>
       </div>

          <div class="row">
            <div class="col-sm-12 pr-4">
              <div class="row">
                <div class="card divbody borderradius p-1">
                 
  
               
                  <div class="col-sm-12">
                    <div class="row">
                      <!-- <div class="col-sm-3  ">
                        <mat-form-field class="example-full-width lable_style2" appearance="outline" >
                            <mat-label>Branch Name</mat-label>
                            <mat-select formControlName="branchRefId" >
                                <mat-option value="Branch 1">Branch 1</mat-option>
                                <mat-option value="Branch 2">Branch 2</mat-option>
                                <mat-option value="Branch 3">Branch 3</mat-option>
                                <mat-option value="Branch 4">Branch 4</mat-option>
                            </mat-select>
                          </mat-form-field>
                      </div>
  
                      <div class="col-sm-3  ">
                        <mat-form-field class="example-full-width lable_style2" appearance="outline" >
                            <mat-label>Device Name</mat-label>
                            <mat-select formControlName="deviceName" >
                                <mat-option value="Device 1">Device 1</mat-option>
                                <mat-option value="Device 2">Device 2</mat-option>
                                <mat-option value="Device 3">Device 3</mat-option>
                                <mat-option value="Device 4">Device 4</mat-option>
                            </mat-select>
                          </mat-form-field>
                      </div>
                      <div class="col-sm-3  ">
                        <mat-form-field class="example-full-width lable_style2" appearance="outline">
                            <mat-label class="lable_style2">Serial Number</mat-label>
                        <input placeholder="Serial Number" formControlName="serialNumber" 
                        matInput type="text"   />          
                      </mat-form-field>  
                      </div>
  
                      <div class="col-sm-3  ">
                        <mat-form-field class="example-full-width lable_style2" appearance="outline">
                            <mat-label class="lable_style2">Transporter Name</mat-label>
                        <input placeholder="Name" formControlName="transporterName" 
                        matInput type="text"   />          
                      </mat-form-field>  
                      </div> -->
  
                   
   
                    
                      <div class="col-sm-12">
                        <div class="white_box QA_section card_height_100">
                            <div class="table-responsive">
                              <table id="toServiceList" 
                              class="table table-bordered data-table-custom">
                           
                          <thead>
                            <tr class="text-center" (click)="toServiceForm()" >
                              <th>Service Type</th>
                              <th>Test Type</th>
                              <th>Billing Company</th>
                              <th>Rate</th>
                              <th>GST Applicable</th>
                              <th> HAS/SAC</th>
                              <th>SGST</th>
                              <th>CGST</th>
                              <th>IGST</th>  
                              <th>Action</th>
                             
                            </tr>
                          </thead>
                            <tbody formArrayName="serviceRateInfo">
                   
                              <tr [formGroupName]="i"
                              *ngFor="let row of serviceRateForm.get('serviceRateInfo')['controls']; let i = index; let last = last"
                                role="row"
                                class="odd "
                              >
                                <td class="tdW3 pl-3 pr-3">
                                  
                          
                                        <mat-form-field class="example-full-width tdW3 lable_style2 widthh" appearance="outline">
                                            <mat-label class="lable_style2">Service Type</mat-label>
                                             
                                              <mat-select #select2 formControlName="serviceRefId">
                                                <mat-select-filter *ngIf="select2.focused" [placeholder]="'Filter'" [displayMember]="'serviceType'"
                                      [array]="serviceData[i]"  (filteredReturn)="filteredServiceRate[i] =$event"></mat-select-filter>
                                                <mat-option
                                                    *ngFor="let item of filteredServiceRate[i]"
                                                    [(value)]="item._id" 
                                                    (click)="onSelectService(item._id,item.serviceType,i)"
                                                    >{{ item.serviceType }}</mat-option
                                                  >
                                              </mat-select>
                                              
                                      </mat-form-field>
                                      <mat-error class="margin_tp"
                                      *ngIf="row.controls.serviceRefId.touched && row.controls.serviceRefId.errors?.required">
                                         This Field is Mandatory
                                    </mat-error>
                                 
                                </td>
                                <td class="tdW3  pl-3 pr-3">
                                 
                          
                                  <mat-form-field class="example-full-width tdW3 lable_style2 widthh" appearance="outline">
    
                                      <mat-label class="lable_style2">Test Type</mat-label>
                                  <input readonly placeholder="Test Type" formControlName="testType" 
                                  matInput type="text" />
        
                                    <!-- <mat-label class="lable_style2"> Test Type</mat-label>
                                    
                                    <mat-select #select3 formControlName="testType">
                                      <mat-select-filter *ngIf="select3.focused" [placeholder]="'Filter'" [displayMember]="'testType'" [array]="testTypeArr[i]"
                                      (filteredReturn)="filteredList1[i] =$event"></mat-select-filter>
                                      <mat-option *ngFor="let item of filteredList1[i]" [(value)]="item.testType">
                                        {{item.testType}}
                                      </mat-option>
                                    </mat-select> -->
                                    
                                  </mat-form-field>
                                  <mat-error class="margin_tp"
                                  *ngIf="row.controls.testType.touched && row.controls.testType.errors?.required">
                                     This Field is Mandatory
                                </mat-error>
                           
                          </td>
                                <td class="tdW3  pl-3 pr-3"> 
                                
                                        <mat-form-field class="example-full-width tdW3 lable_style2  widthh" appearance="outline">
                                            <mat-label class="lable_style2">Billing Company</mat-label>
                                     
                                          <mat-select #select4 formControlName="billingCompanyRefId">
                                            <mat-select-filter *ngIf="select4.focused" [placeholder]="'Filter'" [displayMember]="'companyName'"
                                            [array]="listOfBilling"  (filteredReturn)="filteredBillingCompany =$event"></mat-select-filter>
                                            <mat-option
                                                *ngFor="let item of filteredBillingCompany"
                                                [(value)]="item._id"
                                                (click)="onSelectBillingComp(item.companyName,i)"
                                                >{{ item.companyName }}</mat-option
                                              >
                                          </mat-select>
                                      </mat-form-field>
                                      <mat-error class="margin_tp"
                                  *ngIf="row.controls.billingCompanyRefId.touched && row.controls.billingCompanyRefId.errors?.required">
                                     This Field is Mandatory
                                </mat-error>
                                </td>
                                <td class="tdW2  pl-3 pr-3">      
                                            <mat-form-field class="example-full-width tdW2 lable_style2 widthh" appearance="outline">
                                                <mat-label class="lable_style2">Rate</mat-label>
                                            <input maxlength="6" placeholder="Rate" formControlName="rate" 
                                            matInput type="text"   />
                                          
                                          </mat-form-field>
                                          <mat-error class="margin_tp"
                                          *ngIf="row.controls.rate.touched && row.controls.rate.errors?.required">
                                          This Field is Mandatory
                                         </mat-error>
                                         <mat-error class="margin_tp"
                                         *ngIf="row.controls.rate.touched && row.controls.rate.errors?.pattern">
                                         Please enter valid rate.
                                        </mat-error>
                              
                               </td>
                        <td  class="tdW2  pl-3 pr-3"> 
                         
                            <mat-form-field class="example-full-width tdW lable_style2  tdW2 widthh" appearance="outline">
                                <mat-label class="lable_style2">GST Applicable</mat-label>
                         
                            <mat-select required formControlName="gstApplicable"  >
                                  <mat-option (click)="gstApplicable(i);" value="Yes">Yes</mat-option>
                                <mat-option (click)="gstApplicable(i);" value="No">No</mat-option>
                              
                              </mat-select>
                          </mat-form-field>
                      
                </td>
                <td class="tdW2  pl-3 pr-3">      
                  <mat-form-field class="example-full-width tdW2 lable_style2 widthh" appearance="outline">
                      <mat-label class="lable_style2">HSN/SAC</mat-label>
                  <input maxlength="12" placeholder="HSN/SAC %" formControlName="hasSac" 
                  matInput type="text" />
                 vvv
                </mat-form-field>
                <mat-error class="margin_tp"
                *ngIf="row.controls.hasSac.touched && row.controls.hasSac.errors?.required">
                   This Field is Mandatory
              </mat-error>
              <!-- <mat-error class="margin_tp"
                *ngIf="row.controls.hasSac.touched && row.controls.hasSac.errors?.maxlength">
                   Please Enter Maximum 6 Digit
              </mat-error>
              <mat-error class="margin_tp"
                *ngIf="row.controls.hasSac.touched && row.controls.hasSac.errors?.minlength">
                Please Enter Minimum 4 Digit
              </mat-error> -->
     </td> 
                <td class="tdW2  pl-3 pr-3">      
                  <mat-form-field class="example-full-width tdW2 lable_style2 widthh" appearance="outline">
                      <mat-label class="lable_style2">SGST</mat-label>
                  <input maxlength="4" placeholder="SGST %" formControlName="sgst" 
                  matInput type="text" (input)="igstCalculate(i)"/>
                
                </mat-form-field>
                <mat-error class="margin_tp"
                *ngIf="row.controls.sgst.touched && row.controls.sgst.errors?.required">
                   This Field is Mandatory
              </mat-error>
     </td> 
     <td class="tdW2  pl-3 pr-3">      
      <mat-form-field class="example-full-width tdW2 lable_style2 widthh" appearance="outline">
          <mat-label class="lable_style2">CGST</mat-label>
      <input maxlength="4" placeholder="CGST %" formControlName="cgst" 
      matInput type="text" (input)="igstCalculate(i)"/>
     
    </mat-form-field>
    <mat-error class="margin_tp"
                *ngIf="row.controls.cgst.touched && row.controls.cgst.errors?.required">
                   This Field is Mandatory
              </mat-error>
</td>
<td class="tdW2  pl-3 pr-3">      
  <mat-form-field class="example-full-width tdW2 lable_style2 widthh" appearance="outline">
      <mat-label class="lable_style2">IGST</mat-label>
  <input maxlength="4" placeholder="IGST %" formControlName="igst" 
  matInput type="text" />

</mat-form-field>
</td>
            <td>  
                <button mat-button mat-icon-button *ngIf="last" (click)="addNew(i);" [disabled]="serviceRateForm.controls.serviceRateInfo.invalid">
                  <!-- <mat-icon class="mt-2 iconcol">add_circle_outline</mat-icon> -->
                  <img matTooltip="Add New Rate" class="mt-1" width="25" src="../../assets/add.png"> 
                </button>
 
                <button mat-button mat-icon-button (click)="deleteRow(i);" [disabled]="serviceRateForm.get('serviceRateInfo')['controls']['length']===1">
                  <!-- <mat-icon class="mt-2 iconcol">remove_circle_outline</mat-icon> -->
                  <img matTooltip="Remove Rate" class="mt-1" width="25" src="../../assets/dustbin.png"> 
              
                </button>
            </td>
            <!-- <td>
                <button mat-button mat-icon-button (click)="deleteRow(i);" matTooltip="Delete" [disabled]="serviceRateForm.get('serviceRateInfo')['controls']['length']===1">
                  <mat-icon class="mt-2 iconcol">remove_circle_outline</mat-icon>
                </button>
            </td> -->
            
                              </tr>
                            </tbody>
                          </table>
                          <hr>
                    
            </div>     
                              </div>
                            
                           
                            
                              </div>
                              <div class="row col-sm-12 mb-2">

                                <div *ngIf="submitButton && serviceRateCreate" class="col-sm-10">
                                </div>
                                <div *ngIf="updateButton && serviceRateUpdate" class="col-sm-8">
                                </div>
                                  <div *ngIf="submitButton && serviceRateCreate" class="col-sm-2 mt-2">
                                    <button  type="button" class="btn btn-primary btn-sm example-full-width" (click)="submitServiceRate()">Save</button>
                                  </div>
                                  <div *ngIf="updateButton && serviceRateUpdate" class="col-sm-2 mt-2">
                                    <button type="button"  class="btn btn-primary btn-sm example-full-width" (click)="updateServiceRate()">Update</button>
                                  </div>
                                  <div  *ngIf="updateButton && serviceRateUpdate" class="col-sm-2 mt-2">
                                    <button type="reset" class="btn btn-primary btn-sm example-full-width" (click)="cancelUpdate()">Cancel</button>
                                  </div>
                              </div>
                        
                              
                    </div>
                  </div>
                </div>
              </div>
            </div> 
          </div>
          

          <!-- <div class="row mt-2">
            <div class="col-sm-12 pr-4">
              <div class="row">
                <div class="card divbody borderradius p-3 mb-3 pb-0">
                  <div class="col-sm-12">
                    <div class="row">
                      <div class="col-sm-8 pl-3 pr-3">
                        <h1 class="titlecolor">Service Rate List</h1>
                      </div>
                      <div class="col-sm-4">
                        <div class="input-group md-form form-sm form-1 pl-0">
                          <div class="input-group-prepend">
                            <span class="input-group-text purple lighten-3" id="basic-text1"><i
                                class="fas fa-search text-white" aria-hidden="true"></i></span>
                          </div>
                          <input class="form-control my-0 py-1" type="text" placeholder="Search"
                            formControlName="searchServiceRate" aria-label="Search">
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <table class="table table-hover table-striped">
                          <thead>
                            <tr class="text-center">
                       
                              <th>GRN No</th>
                              <th>Transporter Name </th>
                              <th>Supplier Name </th>
                              <th>PO Name</th>
                              <th>Invoice No</th>
                              <th >Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="text-center">
                              <td class="pt-1 pb-1 colorblack">
                             898877
                              </td>
                              <td class="pt-1 pb-1 colorblack">
                               SLK Transport
                              </td>
                              <td class="pt-1 pb-1 colorblack">
                              Parle Supply
                              </td>
                              <td class="pt-1 pb-1 colorblack">
                                Sharad
                                </td>
                              <td class="pt-1 pb-1 colorblack">
                               998277
                              </td>
                            
                                <td class="colorblack text-left"> 
                                  <i matTooltip="View" class="fas fa-eye"></i> </td>
                               
                            </tr>
                          </tbody>
                        </table>
                        
                      </div>
                    
                    </div>
                      <div class="col-sm-12 pt-0 text-right">
                    <pagination-controls id="batchpaginate" directionLinks="true"  
                    (pageChange)="onPageChange($event)" 
                      class="float-right pt-3 my-pagination"></pagination-controls>
                      </div>
                  </div>
                </div>
              </div>
  
            </div>
       </div> -->
     
      </div>
    </div>
    </form>
  </div>