import { Component, Inject, OnInit } from "@angular/core";
import {FormGroup,FormBuilder,FormControl} from '@angular/forms';
import { ApiServiceService } from '../api-service.service';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-popup-items-packed',
  templateUrl: './popup-items-packed.component.html',
  styleUrls: ['./popup-items-packed.component.scss']
})
export class PopupItemsPackedComponent implements OnInit {
  itemId:any
  addNewItem = false;
  popupItemsPackedForm: FormGroup;
  size1:number = 10;
  page1:number = 1;
  variables1: any;
  filteredList: any;
  variables2: any;
  filteredList2: any;
  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<PopupItemsPackedComponent>,@Inject(MAT_DIALOG_DATA) public data:any,
    
    public apimicroservice: ApiServiceService,
) { dialogRef.disableClose = true;}

  ngOnInit() {
   //
   //
   this.itemId=this.data.id;
    this.popupItemsPackedForm = this.fb.group({  
      "leftItemName": new FormControl(''),
      "rightItemName": new FormControl(''),
      "leftItemNameList": new FormControl(''),
      "rightItemNameList": new FormControl(''),
      

    });
    this.getLeftItemPackedData();
    this.getRightItemPackedData();
    
  }
  saveLeftItemData(){
    let payload = this.popupItemsPackedForm.value;
   
    
    this.apimicroservice.submitLeftItemData(payload).subscribe(res => {
      //
      
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);
        
        this.ngOnInit();
        //this.loadBuildigdata()
       this.onClose();
        
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    });
  }

  saveRightItemData(){
    let payload = this.popupItemsPackedForm.value;
   
    
    this.apimicroservice.submitRightItemData(payload).subscribe(res => {
      //
      
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);
        
        this.ngOnInit();
        //this.loadBuildigdata()
       this.onClose();
        
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    });
  }

  getDataById()
  {
   
  }
  
  onClose(): void {
    this.dialogRef.close();
  }

  addNewItemFunc()
  {
    this.addNewItem = true;
  }
  cancelFunc()
  {
    this.addNewItem = false;
  }

  getLeftItemPackedData(){
    this.apimicroservice.getLeftItemData().subscribe(res => {
      //
      this.variables1 = res.data;
      this.filteredList = this.variables1.slice();
    });
  }
  getRightItemPackedData(){
    this.apimicroservice.getRightItemData().subscribe(res => {
      //
      this.variables2 = res.data;
      this.filteredList2 = this.variables2.slice();
    });
  }

}