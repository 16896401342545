import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import {FormGroup,FormBuilder,FormControl, Validators} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiServiceService } from '../api-service.service';

@Component({
  selector: 'app-popup-post',
  templateUrl: './popup-post.component.html',
  styleUrls: ['./popup-post.component.scss']
})
export class PopupPostComponent implements OnInit {
  popupPostForm: FormGroup;
  
  totalLength: any;
  searchText: any;
  page:number = 1;
  size:number = 3;
  sid: any;
  submitButton = true;
  updateButton = false;
  cancelButton = false;
  listOfPost: any;
 
  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<PopupPostComponent>,@Inject(MAT_DIALOG_DATA) public data:any, 
    public apimicroservice: ApiServiceService,
    private spinner: NgxSpinnerService,

  ) { PopupPostComponent}

  ngOnInit(): void {
    this.page = 1;
    this.popupPostForm = this.fb.group({  
      "postName": new FormControl('',[Validators.required,Validators.pattern("^[a-zA-Z0-9 -]+$")]),
      "createdAt": new Date(),
      "searchpost": new FormControl(''),
    });
    this.fetchPostList();
  }
  fetchPostList() {
    let pg = 1;
    let size = 3;
    let search='';
        this.apimicroservice.getPostData(pg,size,search).subscribe(res => {
           this.listOfPost = res.data.data;
           this.totalLength = res.data.totalItems;
           //this.buildingLenght = this.listofbuild.length;
          if(res.status){
           // this.apimicroservice.openSnackbar(res.message);
            
          }else{
            //this.apimicroservice.openSnackbar(res.message);
          }
     
        });   
 
  }
  savePost(){
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let payload = this.popupPostForm.value; 
    payload.clinicRefId = clinicRef;
    payload.branchRefId = branchRef;
    //
    this.spinner.show();
    this.apimicroservice.savePostData(payload).subscribe(res => {
      //
      this.spinner.hide();
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);      
        //this.ngOnInit();
       this.onClose();        
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    },(ERROR:HttpErrorResponse) => {
      this.apimicroservice.openSnackbar(ERROR.error.message);
      this.spinner.hide();
      this.onClose();    
  });
  }
  save() {
    if (this.popupPostForm.invalid) {
      this.popupPostForm.get("postName").markAsTouched();
      return;
    } else {
      this.savePost();
    }
  }
  onKeyUpEvent(event: any) {
    this.searchText = this.popupPostForm.get('searchpost').value;
    this.apimicroservice.getPostData(this.page,this.size,this.searchText).subscribe(res => {
      this.listOfPost = res.data.data;
      this.totalLength = res.data.totalItems;
       if(res.status){
        //  this.apimicroservice.openSnackbar(res.message);    
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
  }

  prepopulation(id) {
    this.apimicroservice.getPostDataById(id).subscribe(res => {
      let data = res.data[0];
      this.sid = id;
       if(res.status){
        this.submitButton = false;
         this.updateButton = true;
         this.cancelButton = true;
         this.popupPostForm = this.fb.group({
          "postName" :new FormControl(data.postName),
          "updateddAt": new Date(),
         });
       }else{
     //    //this.apimicroservice.openSnackbar(res.message);
       }
     });
  }
  updatePost() {
    let payload = this.popupPostForm.value;
     this.apimicroservice.updatePostData(payload,this.sid).subscribe(res => {
       if(res.status){
         this.apimicroservice.openSnackbar(res.message);
          this.updateButton = false;
          this.submitButton = true;
          this.ngOnInit();
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
    
  }
  onPageChange(event) {
    this.page = event;
   // let p = this.page - 1;
    this.searchText = this.popupPostForm.get('searchpost').value;
 
    this.apimicroservice.getPostData(this.page,this.size,this.searchText).subscribe(res => {
      this.listOfPost = res.data.data;
      this.totalLength = res.data.totalItems;
         if(res.status){
          //  this.apimicroservice.openSnackbar(res.message);
           
         }else{
           //this.apimicroservice.openSnackbar(res.message);
         }
    
       });
  
  }
  cancelUpdate(){
    this.popupPostForm.reset();
    this.submitButton=true;
    this.updateButton=false;
    this.cancelButton=false;
    this.ngOnInit();
  }
  onClose(): void {
    this.dialogRef.close();
  }

}
