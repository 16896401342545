<div class="container-fluid " [formGroup]="clinicalStaffMasterForm">
    <form autocomplete="off" [formGroup]="clinicalStaffMasterForm" novalidate>
      <div class="row mtt">
       
        <div class="col-sm-12">
          <div class="row">
            <div class="col-sm-11">
              <div class="row ">
  
                <!-- <span><img src="../../assets/clinical_staff.png" width="40rem"> </span>  -->
                <span><h1 class="fass ml-3 pt-1" id="toClinicalStaffForm">Clinical Staff</h1></span>
              </div>
              
            </div>
            <div class="col-sm-1">
              <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                <a (click)="onClose()" matTooltip="Close" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">close</span></a>
                </div>
            </div>
            <!-- <div class="col-sm-1 mb-2">
              <div class="row justify-content-end mr-1">
                <img src="../../assets/close.png" (click)="onClose()" class="justify-content-end" width="25rem">
              </div>
            </div> -->
           
          </div>
  
          <div class="row ">
            <div class="col-sm-12 pr-4">
            
                 <div class="row mt-2 pl-3 pr-3">
                   <div class="col-sm-12">
                     <div class="row"> 
                      <div class="col-sm-12    ">
                        <label class="header">Staff Details :</label>
                      </div>
                     </div>
                     <div class="row">
                      <div class="col-sm-3    ">
                       
                        <mat-form-field *ngIf="userType !== 'Super Admin'" class="example-full-width" appearance="outline">
                          <mat-label>Clinical Staff Id</mat-label>
                          <input matInput formControlName="branchWiseId" readonly  />   
                        </mat-form-field>
                        <mat-form-field *ngIf="userType == 'Super Admin'" class="example-full-width" appearance="outline">
                          <mat-label>Clinical Staff Id</mat-label>
                          <input matInput [value]="superBranchId" readonly  />
                          </mat-form-field>
                      </div>
  
                      <div class="col-sm-3    ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Staff Member Name
                            <!-- <span class="required-asterisk">*</span> -->
                          </mat-label>
                          <input required matInput placeholder="Staff Member Name" matTooltip="Staff Member Name"
                            formControlName="staffMemberName"  />
                          
                        </mat-form-field>
                        <mat-error class="margin_tp"
                            *ngIf="clinicalStaffMasterForm.controls['staffMemberName'].touched && clinicalStaffMasterForm.controls['staffMemberName'].errors?.required">
                            This field is mandatory.
                          </mat-error>
                      </div>
                      <div class="col-sm-3    ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Staff Member Short Code
                            <!-- <span class="required-asterisk">*</span> -->
                          </mat-label>
                          <input required matInput placeholder="Staff Member Short Code"  maxlength="10"
                            matTooltip="Staff Member Short Code" formControlName="staffMemberShortCode"  />
                         
  
                        </mat-form-field>
                        <mat-error class="margin_tp"
                        *ngIf="clinicalStaffMasterForm.controls['staffMemberShortCode'].touched && clinicalStaffMasterForm.controls['staffMemberShortCode'].errors?.required">
                        This field is mandatory.
                      </mat-error>
                      </div>
                    
                      <div class="col-sm-3    ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Assigned Clinic 
                            <!-- <span class="required-asterisk">*</span> -->
                          </mat-label>
                          <mat-select required class="colorblack" formControlName="assignedClinic">
                            <mat-option *ngFor="let clinic of clinicData" (click)="onSelectClinic(clinic._id)"
                              [value]="clinic._id">{{clinic.clinicName}}</mat-option>
                          </mat-select>
                          
                        </mat-form-field>
                        <mat-error class="margin_tp"
                            *ngIf="clinicalStaffMasterForm.controls['assignedClinic'].touched && clinicalStaffMasterForm.controls['assignedClinic'].errors?.required">
                            This field is mandatory.
                          </mat-error>
                      </div>
                      <div class="col-sm-3">
                        <div class="row">
                          <div class="col-sm-10">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Department
                            <!-- <span class="required-asterisk">*</span> -->
                          </mat-label>
                          <mat-select required #select1 placeholder="Department" class="colorblack" formControlName="department">
                            <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'name'" [array]="departmentArr"
                              (filteredReturn)="departmentList =$event"></mat-select-filter>
                            <mat-option *ngFor="let item of departmentList" [value]="item.departmentName">
                              {{item.departmentName}} 
                            </mat-option> 
                          </mat-select>
                          
                        </mat-form-field>
                        <mat-error class="margin_tp"
                            *ngIf="clinicalStaffMasterForm.controls['department'].touched && clinicalStaffMasterForm.controls['department'].errors?.required">
                            This field is mandatory.
                          </mat-error>
</div>
                          <div class="col-sm-2 ml-0 pl-0 no-print">
                            <i (click)="openDepartmentPopUp();" matTooltip="Add New Department" 
                            class="blue_col  fa-solid fa-circle-plus pl-1 fa-lg"></i>
                               </div>
                      </div>
                      </div>
                      <div class="col-sm-3">
                        <div class="row">
                          <div class="col-sm-10">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Post
                            <!-- <span class="required-asterisk">*</span> -->
                          </mat-label>
                          <mat-select required #select2 placeholder="Post" class="colorblack" formControlName="post">
                            <mat-select-filter *ngIf="select2.focused" [placeholder]="'Filter'" [displayMember]="'name'" [array]="postArr"
                              (filteredReturn)="postList =$event"></mat-select-filter>
                            <mat-option *ngFor="let item of postList" [value]="item.postName">
                              {{item.postName}} 
                            </mat-option> 
                          </mat-select>
                        
                        </mat-form-field>
                        <mat-error class="margin_tp"
                        *ngIf="clinicalStaffMasterForm.controls['post'].touched && clinicalStaffMasterForm.controls['post'].errors?.required">
                        This field is mandatory.
                      </mat-error>
</div>
                      <div class="col-sm-2 ml-0 pl-0  no-print">
                        <i (click)="openPostPopUp();" matTooltip="Add New post" 
                        class="blue_col  fa-solid fa-circle-plus pl-1 fa-lg"></i>
                           </div>
                           </div>
                      </div>
                    
                      <div class="col-sm-3    ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Working from</mat-label>
                          <input matInput [matDatepicker]="workingFrom" id="workingFrom" placeholder="DD/MM/YYYY"
                            formControlName="workingFrom" readonly>
  
                          <mat-datepicker-toggle matSuffix [for]="workingFrom">
                          </mat-datepicker-toggle>
                          <mat-datepicker #workingFrom></mat-datepicker>
  
                        </mat-form-field>
                      </div>
                      <div class="col-sm-3    ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Basic Salary Per Hour</mat-label>
                          <input matInput placeholder="Basic Salary Per Hour" matTooltip="Basic Salary Per Hour"
                            formControlName="basicSalaryPerHours" />
  
                         
                        </mat-form-field>
                        <mat-error class="margin_tp"
                        *ngIf="clinicalStaffMasterForm.controls['basicSalaryPerHours'].touched && clinicalStaffMasterForm.controls['basicSalaryPerHours'].errors?.pattern">
                        Please enter a number.
                      </mat-error>
                      </div>
                      <div class="col-sm-3    ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Daily Travelling Allowence</mat-label>
                          <input matInput placeholder="Daily Travelling Allowence" matTooltip="Daily Travelling Allowence"
                            formControlName="dailyTravellingAllowence" />
  
                         
                        </mat-form-field>
                        <mat-error class="margin_tp"
                        *ngIf="clinicalStaffMasterForm.controls['dailyTravellingAllowence'].touched && clinicalStaffMasterForm.controls['dailyTravellingAllowence'].errors?.pattern">
                        Please enter a number.
                      </mat-error>
                      </div>
                      
                     </div>
  
                     <div class="row">
                      
                      <div class="col-sm-12 mt-1">
                        <label class="header">Time Details :</label>
                      </div>
                      <div class="col-sm-3    ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Start Time
                            <!-- <span class="required-asterisk">*</span> -->
                          </mat-label>
                          <!-- <input  formControlName="openTime" matInput id="appt" (change)="toOpenTimeChange()"
                           type="time" name="appt"
                            step="2">
                          -->
                          <input required matInput
                          placeholder="08:00 am"
                          formControlName="openTime"
                          [ngxTimepicker]="pickerF"
                          value=""
                          [min]="'07:00 am'"
                          [max]="'09:00 pm'"
                          [disableClick]="true"
                          readonly
                          />
                          <ngx-material-timepicker-toggle matSuffix [for]="pickerF"></ngx-material-timepicker-toggle>
                          <ngx-material-timepicker (closed)="toOpenTimeChange()" #pickerF></ngx-material-timepicker>
                    
                        </mat-form-field>
                        <mat-error class="margin_tp"
                        *ngIf="clinicalStaffMasterForm.controls['openTime'].touched && clinicalStaffMasterForm.controls['openTime'].errors?.required">
                        This field is mandatory.
                      </mat-error>
                      
                      </div>
  
                      <div class="col-sm-3    ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>End Time
                            <!-- <span class="required-asterisk">*</span> -->
                          </mat-label>
                          <!-- <input  formControlName="closeTime" (change)="toCloseTimeChange()" 
                          matInput id="appt-time2" type="time"
                            name="appt-time2" step="2">
                         -->
                         <input required matInput
                                placeholder="08:00 pm"
                                formControlName="closeTime"
                                [ngxTimepicker]="pickerA"
                                value=""
                                [min]="'08:00 am'"
                                [max]="'09:00 pm'"
                                [disableClick]="true"
                                readonly
                                />
                                <ngx-material-timepicker-toggle matSuffix [for]="pickerA"></ngx-material-timepicker-toggle>
                                <ngx-material-timepicker (closed)="toCloseTimeChange()" #pickerA></ngx-material-timepicker>
                           
                        </mat-form-field>
                        <mat-error class="margin_tp"
                        *ngIf="clinicalStaffMasterForm.controls['closeTime'].touched && clinicalStaffMasterForm.controls['closeTime'].errors?.required">
                        This field is mandatory.
                      </mat-error>
                      </div>
                     
  
                      <div class="col-sm-3    ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Weekly Off</mat-label>
                          <mat-select #select3 placeholder="Weekly Off" class="colorblack" formControlName="weeklyOff">
                            <mat-select-filter *ngIf="select3.focused" [placeholder]="'Filter'" [displayMember]="'name'" [array]="allDays"
                              (filteredReturn)="filteredListDays =$event"></mat-select-filter>
                            <mat-option *ngFor="let item of filteredListDays" [value]="item.value">
                              {{item.name}}
                            </mat-option>
                          </mat-select>
  
                        </mat-form-field>
                      </div>
  
                     
                     </div>
                   </div>
                   <div class="col-sm-12">
                     <div class="row">
                      
                      <div class="col-sm-12    ">
                        <label class="header">Address Details :</label>
                      </div>
                     </div>
                     <div class="row">
                      <div class="col-sm-3    ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Street Address
                            <!-- <span class="required-asterisk">*</span> -->
                          </mat-label>
                          <input required matInput placeholder="Street Address" matTooltip="Staff Member Address"
                            formControlName="staffMemberAddress" />
                         
                        </mat-form-field>
                        <mat-error class="margin_tp"
                        *ngIf="clinicalStaffMasterForm.controls['staffMemberAddress'].touched && clinicalStaffMasterForm.controls['staffMemberAddress'].errors?.required">
                        This field is mandatory.
                      </mat-error>
                      </div>
                      <div class="col-sm-3    ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Taluka</mat-label>
                          <input matInput placeholder="Taluka" formControlName="taluka" />
                         
                        </mat-form-field>
                        <mat-error class="margin_tp"
                        *ngIf="clinicalStaffMasterForm.controls['taluka'].touched && clinicalStaffMasterForm.controls['taluka'].errors?.pattern">
                        Please enter valid taluka.
                      </mat-error>
                      </div>
                      <div class="col-sm-3    ">
                        <!-- <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>District</mat-label>
                          <input matInput placeholder="District" formControlName="district" />
                         
                        </mat-form-field> -->
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>District</mat-label>
                          <mat-select   #select12 placeholder="District" class="colorblack"  formControlName="district"   >
                            <mat-select-filter  *ngIf="select12.focused" [placeholder]="'Filter'" [displayMember]="'district'"
                              [array]="districtData2" (filteredReturn)="filteredListDistrict2 =$event"></mat-select-filter>
                            <mat-option *ngFor="let item of filteredListDistrict2" 
                             [value]="item.district" >
                              {{item.district}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                        <mat-error class="margin_tp"
                        *ngIf="clinicalStaffMasterForm.controls['district'].touched && clinicalStaffMasterForm.controls['district'].errors?.pattern">
                        Please enter valid district.
                      </mat-error>
                      </div>
                      <div class="col-sm-3    ">
                        <!-- <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>State</mat-label>
                          <mat-select #select4 placeholder="State" required class="colorblack" formControlName="state">
                            <mat-select-filter *ngIf="select4.focused" [placeholder]="'Filter'" [displayMember]="'name'" [array]="allState"
                              (filteredReturn)="filteredList =$event"></mat-select-filter>
                            <mat-option *ngFor="let item of filteredList" [value]="item.value">
                              {{item.name}}
                            </mat-option>
                          </mat-select>
  
                        </mat-form-field> -->
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>State</mat-label>
                          <mat-select   #select11 placeholder="State" class="colorblack"  formControlName="state"   >
                            <mat-select-filter  *ngIf="select11.focused" [placeholder]="'Filter'" [displayMember]="'state'"
                              [array]="stateCityArr2"  (filteredReturn)="filteredList2 =$event"></mat-select-filter>
                            <mat-option *ngFor="let item of filteredList2" 
                             [value]="item.state" (click)="getDistrict2(item.districts)">
                              {{item.state}}
                            </mat-option>
                          </mat-select>
                        
                        </mat-form-field>
                        <mat-error class="margin_tp"
                        *ngIf="clinicalStaffMasterForm.controls['state'].touched && clinicalStaffMasterForm.controls['state'].errors?.required">
                        This field is mandatory.
                      </mat-error>
                      </div>
                           
                      <div class="col-sm-3     ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label> Pin Code 
                            <!-- <span class="required-asterisk">*</span> -->
                          </mat-label>
                          <input required formControlName="PinCode"  matInput maxlength="6" placeholder="Pincode(e.g:416008)" />
                         
  
                        </mat-form-field>
                        <mat-error
                        *ngIf="clinicalStaffMasterForm.controls['PinCode'].touched && clinicalStaffMasterForm.controls['PinCode'].errors?.pattern">
                        Please enter valid PinCode</mat-error>
  
                      <mat-error
                        *ngIf="clinicalStaffMasterForm.controls['PinCode'].touched && clinicalStaffMasterForm.controls['PinCode'].errors?.required">
                        This field is mandatory.</mat-error>
                      </div>
                      <div class="col-sm-3    ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Country</mat-label>
                          <input matInput placeholder="Country" formControlName="country" />
                       
                        </mat-form-field>
                        <mat-error class="margin_tp"
                        *ngIf="clinicalStaffMasterForm.controls['country'].touched && clinicalStaffMasterForm.controls['country'].errors?.pattern">
                        Please enter valid country.
                      </mat-error>
                      </div>
                   
                   
                     
                     
        
                     
  
                 
                     </div>
                     <div class="row">
                     
                      <div class="col-sm-12 mt-1">
                        <label class="header">Contact Details :</label>
                      </div>
                     </div>
                     <div class="row">
                      <div class="col-sm-3    ">
                        <div class="row">
                          <div class="col-sm-10">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Staff Contact Number
                            <!-- <span class="required-asterisk">*</span> -->
                          </mat-label>
                          <input required matInput maxlength="10" placeholder="Staff Contact Numbers" matTooltip="Staff Contact Number"
                            formControlName="staffContactNumbers" />
                         
  
                        </mat-form-field>
                        <mat-error class="margin_tp"
                        *ngIf="clinicalStaffMasterForm.controls['staffContactNumbers'].touched && clinicalStaffMasterForm.controls['staffContactNumbers'].errors?.required">
                        This field is mandatory.
                      </mat-error>
                      <mat-error class="margin_tp"
                        *ngIf="clinicalStaffMasterForm.controls['staffContactNumbers'].touched && clinicalStaffMasterForm.controls['staffContactNumbers'].errors?.pattern">
                        Please enter valid number.
                      </mat-error>
                      </div>
                      <div class="col-sm-2 wApp mt-2 pt-2">
                        <i class="bi bi-whatsapp whatsapp-icon wApp fa-lg" (click)="WhatsApp();" matTooltip="WhatsApp Integration" ></i>
                      </div>
                     </div>
                    </div>
                  </div>
               
                   </div>
                   <div *ngIf="isSuperAdmin" class="col-sm-12"> 
                              
                    <div class="row">
                      <div class="col-sm-12  ">
                    <label class="header">Select Branch :</label>
                      </div>
                      </div>
                      <div class="row">
  
                  <div class="col-sm-3">
                    <div class="row">
                      <div class="col-sm-10 pr-1"> 
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Branch 
                        <!-- <span class="required-asterisk">*</span> -->
                      </mat-label>
                      <mat-select required #select5 (click)="fetchBranchList();" formControlName="branchRefId" >
                        <mat-select-filter *ngIf="select5.focused" [placeholder]="'Filter'" [displayMember]="'branchName'"
                        [array]="BranchList"  (filteredReturn)="filteredListBranch =$event"></mat-select-filter>
                        <mat-option *ngFor="let item of filteredListBranch" [value]="item._id" 
                        (click)="branchClick(item._id,item.branchName);"
                        >
                         {{ item.branchName }}</mat-option>
                        </mat-select>
                       
                    </mat-form-field>
                    <mat-error class="margin_tp"
                        *ngIf="clinicalStaffMasterForm.controls['branchRefId'].touched && clinicalStaffMasterForm.controls['branchRefId'].errors?.required">
                        This field is mandatory.
                      </mat-error>
                      </div>
                      <div class="col-sm-2 no-print">
                        <i (click)="openBranchPopUp();" matTooltip="Add New Branch" 
                        class="blue_col  fa-solid fa-circle-plus pl-1 fa-lg"></i>
                      </div>
                      </div>
                  </div>
             </div>
             </div>
                 </div>
                  
                    <div class="row pl-2 pr-2 mtt">
                    
                      <div class="col-sm-10 " *ngIf="submitButton"> </div>
                      <div class="col-sm-2" *ngIf="submitButton && staffCreate">
                        <button  type="button" class="btn btn-primary example-full-width btn-sm " 
                          (click)="submitStaff()">Save </button> 
                        </div>
                        <div class="col-sm-8 " *ngIf="updateButton"> </div>
                        <div class="col-sm-2" *ngIf="updateButton && staffUpdate"> 
                          <button  type="button" class="btn btn-primary example-full-width btn-sm " 
                          (click)="updateStaffDetails()">Update </button>
                        </div>
                        <div class="col-sm-2 " *ngIf="updateButton"> 
                          <button  type="reset" class="btn btn-primary btn-sm   example-full-width" (click)="cancelUpdate()"
                         >Cancel </button>
                        </div>
                    </div>
             
            </div>
  
          </div>
        
        </div>
      </div>
    </form>
  </div>