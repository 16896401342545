<div class="dialog-wrapper">
    <!-- <h3 mat-dialog-title>Change Password </h3> -->
    <mat-dialog-content>
      <div class="container">
        <form [formGroup]="popupCityForm" autocomplete="off" novalidate>
        <div class="row">
          <div class="col-sm-12">
            <div class="card-group">
              <div class="card p-4" >
             

                <div class="card-body col-sm-12">
                    <div class="row">
                    <div class="col-sm-10">
                        <p class="text-muted">Add New City</p>
                    </div>
                    <div class="col-sm-2">
                      <div class="page-title-right row justify-content-end d-flex align-items-center float-end">
                        <a (click)="onClose()" matTooltip="Close" class="d-flex align-items-center"><span class="material-symbols-outlined pr-2">close</span></a>
                        </div>
                    </div>
                  
              </div>
                  <div class="col-sm-12">
                    <div class="row">
                        
                  <div class="col-sm-6">
                    <label class="pt-3 lable_style">City Name :</label>
                    </div>
          <div class="col-sm-6 pl-1 pr-1">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>City Name</mat-label>
              <input required matInput placeholder="City Name" formControlName="cityName" required />
             
             </mat-form-field>
             <mat-error class="margin_tp"
             *ngIf="popupCityForm.controls['cityName'].touched && popupCityForm.controls['cityName'].errors?.required">
             This field is mandatory.
           </mat-error>
           <mat-error class="margin_tp"
             *ngIf="popupCityForm.controls['cityName'].touched && popupCityForm.controls['cityName'].errors?.pattern">
             Enter valid city name.
           </mat-error>
          </div>
     
      
                </div>
                <!-- <div class="row">
                    <div class="col-sm-3"></div>
                <div class="col-sm-6">
                    <button class="btn btn-primary btn-sm example-full-width"  type="button"
                     (click)="save()" >Save</button>
                  </div>
                  <div class="col-sm-3"></div>
                </div> -->
                <div class="row mb-2">
                  <div *ngIf="submitButton" class="col-sm-3"></div>
                
              <div *ngIf="submitButton" class="col-sm-6">
                  <button class="btn btn-primary btn-sm example-full-width" type="button" (click)="save()" >Save</button>
                </div>
                <div *ngIf="updateButton" class="col-sm-6">
                  <button class="btn btn-primary btn-sm example-full-width" type="button" (click)="updateCity()" >Update</button>
                </div>
                <div *ngIf="updateButton" class="col-sm-6">
                  <button class="btn btn-primary btn-sm example-full-width" type="reset" (click)="cancelUpdate()" >Cancle</button>
                </div>
                <div *ngIf="submitButton" class="col-sm-3"></div>
              
              </div>
</div>
                </div>
                <div class="card pr-3 pt-4 pl-3" >
            
                  <div class="row">
                    <div class="col-sm-6">
                        <h3 class="titlecolor">City List</h3>  
                    </div>
                    <div class="col-sm-6 mb-2">
                        <div class="input-group md-form form-sm form-1 pl-0">
                          <div class="input-group-prepend">
                            <span class="input-group-text purple lighten-3" id="basic-text1"><i class="fas fa-search text-white"
                                aria-hidden="true"></i></span>
                          </div>
                          <input class="form-control my-0  py-1" type="text"
                           placeholder="Search" formControlName="searchCity"
                           (keyup)="onKeyUpEvent($event)" aria-label="Search">
                        </div>
                    </div>
                    <div class="col-sm-12 table-responsive">
                            <table id="toPatientList"
                            class="table table-bordered data-table-custom">
                                 <thead>
                                   <tr style="text-align: center;">
                                    <th>City</th>
                                   </tr>
                                 </thead>  
                                 <tbody>
                             
                            <tr  *ngFor="let data of listOfCity | paginate : { id:'cityMaster',
                            itemsPerPage: size, currentPage: page, totalItems:totalLength }"
                         (click)="prepopulation(data._id)" 
                          style="cursor:pointer; text-align: center ">
                              <td matTooltip="click to view">{{data.cityName}}</td>
                              <!-- <td matTooltip="click to view">{{data.createdAt | date}}</td> -->
                          
                            </tr>
                            
                            
                          </tbody>
                        </table>
                      </div>
                      <div class="col-sm-12 pt-0 text-right">
                        <pagination-controls id="cityMaster" directionLinks="true"  
                        (pageChange)="onPageChange($event)" class="float-right pagination my-pagination"></pagination-controls>
                      </div>
                  </div>
             
    
              </div>
          

 
                
              </div>
            </div>
          </div>
      
        </div>
</form>


      </div>
    </mat-dialog-content>
  
  </div>