import { Component, Inject, OnInit } from "@angular/core";
import {FormGroup,FormBuilder,FormControl, Validators} from '@angular/forms';
import { ApiServiceService } from '../api-service.service';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-whats-app-popup',
  templateUrl: './whats-app-popup.component.html',
  styleUrls: ['./whats-app-popup.component.scss']
}) 
export class WhatsAppPopupComponent implements OnInit {
  whatsAppForm: FormGroup;

  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<WhatsAppPopupComponent>,@Inject(MAT_DIALOG_DATA) public data:any, 
    public apimicroservice: ApiServiceService,
) { dialogRef.disableClose = true;}
 
  ngOnInit(): void {
    let mobileNumber = sessionStorage.getItem('whatsAppNumber');
    this.whatsAppForm = this.fb.group({  
      "whatsAppNumber": new FormControl('',[Validators.required, Validators.pattern("(0|91)?[6-9][0-9]{9}")]),
      // "createdAt": new Date(),
    }); 
    this.whatsAppForm.controls["whatsAppNumber"].setValue(mobileNumber);
  } 
  save(){
    let payload = this.whatsAppForm.value; 
    if (this.whatsAppForm.invalid) {
      this.whatsAppForm.get("whatsAppNumber").markAsTouched();
      return;
    } else {  
      sessionStorage.setItem('whatsAppNumber',payload.whatsAppNumber);
      let mobileNumber = sessionStorage.getItem('whatsAppNumber');
      window.open("https://wa.me/91"+mobileNumber);
    }
  } 
  onClose(): void {
    this.dialogRef.close();
  }
}
