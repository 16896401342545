import { Component, Inject, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { HttpClient } from "@angular/common/http";
 import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";

import { MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner"; 
import { PopUpBranchComponent } from "../pop-up-branch/pop-up-branch.component";

@Component({
  selector: 'app-pop-up-courierservice',
  templateUrl: './pop-up-courierservice.component.html',
  styleUrls: ['./pop-up-courierservice.component.scss']
})
export class PopUpCourierserviceComponent implements OnInit {

  errormessage: string;
  searchText: any;
  courierId: any;
  allState=[
    {name:'Andaman and Nicobar Islands',value:'AN' },
    {name:'Andhra Pradesh',value:'AP'},
    { name:'Arunachal Pradesh',value:'AR' },
{name:'Assam',value:'AS' },
{ name:'Bihar',value:'BR'},
 { name:'Chandigarh',value:'CH' },
{name:'Chhattisgarh',value:'CT' },
{ name:'Dadra and Nagar Haveli', value:'DN' },
 { name:'Daman and Diu', value:'DD'},
 { name:'Delhi',value:'DL'},
 {name:'Goa',value:'GA' },
{name:'Gujarat', value:'GJ'}, 
{name:'Haryana',value:'HR'}, 
{name:'Himachal Pradesh',value:'HP'}, 
{ name:'Jammu and Kashmir',value:'JK'}, 
 {name:'Jharkhand',value:'JH'}, 
{ name:'Karnataka', value:'KA'},
{ name:'Kerala',value:'KL'},
{name:'Ladakh',value:'LA'},
 {name:'Lakshadweep',value:'LD' },
 {name:'Madhya Pradesh', value:'MP'},
{ name:'Maharashtra',value:'MH'},
 { name:'Manipur',value:'MN'},
{ name:'Meghalaya', value:'ML' },
{ name:'Mizoram',value:'MZ' },
{ name:'Nagaland',value:'NL' },
 { name:'Odisha',value:'OR'},
 { name:'Puducherry', value:'PY'},
 {name:'Punjab', value:'PB' },
{ name:'Rajasthan', value:'RJ'},           
 {name:'Punjab',value:'PB'},   
 {name:'Rajasthan', value:'RJ'},
{name:'Sikkim',value:'SK' },
{ name:'Tamil Nadu',value:'TN'}, 
 {name:'Telangana',value:'TG'},   
  { name:'Tripura',  value:'TR' },
 { name:'Uttar Pradesh', value:'UP'},
{name:'Uttarakhand',value:'UT'},  
{ name:'West Bengal', value:'WB' },                        
  ]
  filteredList: { name: string; value: string; }[];
  superBranchId: any;
  branchName: string;
  filteredListBranch: any;
  stateCityArr2: any[];
  stateCity2: any;
  districtData2: any;
  filteredList2: { state: string; districts: string; }[];
  filteredListDistrict2: { district: string;}[];
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
     public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    private httpClient: HttpClient,
    public dialogRef: MatDialogRef<PopUpCourierserviceComponent>,@Inject(MAT_DIALOG_DATA) public data:any
  ) {dialogRef.disableClose = true;}
  courierServiceProviderMasterForm: FormGroup;
  status: any;
  submitButton = true;
  updateButton = false;
  deleteButton = false;
  cancelButton= false;
  loggedId: any;
  myimage;
  date: any;
  size=5;
  page=1;
  curierData: any; 
  totalLength:any;
  listofcourier:any;
  branchRef: string;
  clinicRef: string;
  isSuperAdmin= true;
  BranchList: any;
  userType: string;
  userPermission:any;
  courierServiceCreate= true;
  courierServiceUpdate= true;

  ngOnInit(): void {
    this.page=1;
    this.date=new Date();
    this.filteredList = this.allState.slice();
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.userType = sessionStorage.getItem('userType');

    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    if(this.userType !== 'Super Admin'){
      this.isSuperAdmin= false;
    this.userPermission.forEach((element) => {
      if(element.pageName == 'Courier Service Provider' && element.create == false)
      {
          this.courierServiceCreate = false;  
      }
      if(element.pageName == 'Courier Service Provider' && element.update == false)
      {
          this.courierServiceUpdate = false;  
      }    
    });
  }
    this.courierServiceProviderMasterForm = this.fb.group({
      "branchWiseId": new FormControl(''),
      "serviceProviderName": new FormControl('',[Validators.required, Validators.pattern("^[a-zA-Z-\\s]*$")]),
      "streetAddress": new FormControl(''),
      "phoneNo": new FormControl('', [Validators.required,Validators.pattern("(0|91)?[6-9][0-9]{9}")]),
      "contactPerson": new FormControl(''),
      "custCareContactPerson": new FormControl(''),
      "tollFreeNo": new FormControl('', [Validators.pattern("(18)?[0-9]{9}")]),
       "landline": new FormControl('',Validators.pattern("[0-9]{3,4}[-][0-9]{6,8}")),
      "custCareContactPersonNo": new FormControl('',[Validators.pattern("(0|91)?[6-9][0-9]{9}")]),
      "gstNo": new FormControl('',Validators.pattern('^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$')),
      "PinCode":new FormControl('', [ Validators.pattern("^[0-9]{6}$")]),
      "taluka": new FormControl('',Validators.pattern("^[a-zA-Z ]*$")),
      "district": new FormControl('',Validators.pattern("^[a-zA-Z ]*$")),
      "state": new FormControl(''),
      "country": new FormControl('', [Validators.pattern("[A-Za-z _-]{3,19}$"),]),
      "createdAt": this.date,
      "searchCourierService": new FormControl(''),
      "branchRefId": new FormControl(),
      "clinicRefId": new FormControl(),
      "branchName": new FormControl(),
       
    });
this.courierServiceProviderMasterForm.controls["country"].setValue("India");
this.courierServiceProviderMasterForm.controls["state"].setValue("Maharashtra");
let arr=[]
    arr = [
      "Ahmednagar",
      "Akola",
      "Amravati",
      "Aurangabad",
      "Beed",
      "Bhandara",
      "Buldhana",
      "Chandrapur",
      "Dhule",
      "Gadchiroli",
      "Gondia",
      "Hingoli",
      "Jalgaon",
      "Jalna",
      "Kolhapur",
      "Latur",
      "Mumbai City",
      "Mumbai Suburban",
      "Nagpur",
      "Nanded",
      "Nandurbar",
      "Nashik",
      "Osmanabad",
      "Palghar",
      "Parbhani",
      "Pune",
      "Raigad",
      "Ratnagiri",
      "Sangli",
      "Satara",
      "Sindhudurg",
      "Solapur",
      "Thane",
      "Wardha",
      "Washim",
      "Yavatmal"
  ]
  this.getDistrict2(arr);
    this.getLastId();
   // this.removeValidator();
    this.fetchBranchList();
    this.getAllBranch(); 
    this.getState2();
  }
  getState2()
  {
   this.httpClient.get("assets/state-city.json").subscribe(data =>{ 
     this.stateCity2 = data;
     this.stateCityArr2 = [];
     this.stateCity2.states.forEach((element) => {
       this.stateCityArr2.push(element);
     });
     this.filteredList2 = this.stateCityArr2.slice();
     //
   })
  }
  getDistrict2(state)
  {
   //
   this.districtData2 = [];
   state.forEach((element) => {
     //
     this.districtData2.push({"district":element});
   });
   //
   let arr = [];
   arr = state;
  this.filteredListDistrict2 = this.districtData2.slice();
  }
  getAllBranch()
  {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      if (res.status) {        
        let length = res.data.length - 1;
        this.courierServiceProviderMasterForm.controls["branchRefId"].setValue(res.data[length]._id);
        
        this.branchClick(res.data[length]._id,res.data[length].branchName);
        if(this.userType == 'Super Admin')
        {  
          this.apimicroservice.getBranchById(res.data[length]._id).subscribe(res => {
            this.spinner.hide();
            this.courierServiceProviderMasterForm.get("branchName").setValue(res.data[length].branchName);
           });
           //.value);
           //.value);
        }
       
      }
    });
  }
  // removeValidator() { 
  //   this.courierServiceProviderMasterForm.controls['serviceProviderName'].setErrors(null);
  //   this.courierServiceProviderMasterForm.controls['serviceProviderName'].clearValidators();
  //   this.courierServiceProviderMasterForm.controls['serviceProviderName'].updateValueAndValidity();

  //   this.courierServiceProviderMasterForm.controls['streetAddress'].setErrors(null);
  //   this.courierServiceProviderMasterForm.controls['streetAddress'].clearValidators();
  //   this.courierServiceProviderMasterForm.controls['streetAddress'].updateValueAndValidity();
    
  //   this.courierServiceProviderMasterForm.controls['phoneNo'].setErrors(null);
  //   this.courierServiceProviderMasterForm.controls['phoneNo'].clearValidators();
  //   this.courierServiceProviderMasterForm.controls['phoneNo'].updateValueAndValidity();
    
  //   this.courierServiceProviderMasterForm.controls['PinCode'].setErrors(null);
  //   this.courierServiceProviderMasterForm.controls['PinCode'].clearValidators();
  //   this.courierServiceProviderMasterForm.controls['PinCode'].updateValueAndValidity();
    

  // }


  // setValidator() { 
  //   this.courierServiceProviderMasterForm.controls['serviceProviderName'].setValidators([Validators.required, Validators.pattern("^[a-zA-Z ]*$")]);
  //   this.courierServiceProviderMasterForm.controls['serviceProviderName'].updateValueAndValidity();

  //   this.courierServiceProviderMasterForm.controls['streetAddress'].setValidators(Validators.required);
  //   this.courierServiceProviderMasterForm.controls['streetAddress'].updateValueAndValidity();

  //   this.courierServiceProviderMasterForm.controls['phoneNo'].setValidators([Validators.required,Validators.pattern("(0|91)?[6-9][0-9]{9}")]);
  //   this.courierServiceProviderMasterForm.controls['phoneNo'].updateValueAndValidity();

  //   this.courierServiceProviderMasterForm.controls['PinCode'].setValidators([Validators.required, Validators.pattern("^[0-9]{6}$")]);
  //   this.courierServiceProviderMasterForm.controls['PinCode'].updateValueAndValidity();


  // }


  submitCurier() {
   // this.setValidator();
    if(this.userType == 'Super Admin')
    {
      this.courierServiceProviderMasterForm.controls['branchRefId'].setValidators(Validators.required);
      this.courierServiceProviderMasterForm.controls['branchRefId'].updateValueAndValidity();
    }
    else{
      this.courierServiceProviderMasterForm.controls['branchRefId'].setErrors(null);
      this.courierServiceProviderMasterForm.controls['branchRefId'].clearValidators();
      this.courierServiceProviderMasterForm.controls['branchRefId'].updateValueAndValidity();
    }
    if (this.courierServiceProviderMasterForm.invalid) {
      this.courierServiceProviderMasterForm.get("serviceProviderName").markAsTouched();
     // this.courierServiceProviderMasterForm.get("streetAddress").markAsTouched();
      this.courierServiceProviderMasterForm.get("phoneNo").markAsTouched();
      this.courierServiceProviderMasterForm.get("contactPerson").markAsTouched();
      this.courierServiceProviderMasterForm.get("custCareContactPerson").markAsTouched();
      this.courierServiceProviderMasterForm.get("custCareContactPersonNo").markAsTouched();
      this.courierServiceProviderMasterForm.get("gstNo").markAsTouched();
      //this.courierServiceProviderMasterForm.get("PinCode").markAsTouched();
    //  this.courierServiceProviderMasterForm.get("state").markAsTouched();
      return;
    } else {
      this.addCurier();
    }
  }
  
  addCurier() {
    this.spinner.show();
    let payload = this.courierServiceProviderMasterForm.value;
    payload.createdAt= new Date();
   if(this.userType !== 'Super Admin')
    {
      payload.branchRefId = this.branchRef;
      payload.clinicRefId = this.clinicRef;
      payload.branchName = this.branchName;
    }
    
      this.apimicroservice.saveCourierServiceData(payload).subscribe(res => {
        this.spinner.hide();
        
        if (res.status) {
          this.apimicroservice.openSnackbar(res.message);
          this.ngOnInit();
          this.onClose();
         // this.courierServiceProviderMasterForm.reset();
         // this.fetchCurierList();
        } else {
          this.apimicroservice.openSnackbar(res.message);
        }
        
        // this.myimage ="../assets/images/setting2.png";
        // this.myLocationimage ="../../assets/images/no-image-available.png";
        
      });
  }
  openSnackbar(message: string) {
    return this._snackBar.open(message, "close", {
      verticalPosition: "bottom",
      horizontalPosition: "right",
      duration: 2000,
      panelClass: ["snackbarStyle"],
    });
  }
  
  addNew() {
    this.courierServiceProviderMasterForm.reset();
    this.updateButton = false;
    this.deleteButton = false;
  }
   
  getLastId()
  {
    if(this.userType !== 'Super Admin'){
      this.apimicroservice.getLastCourierID(this.branchRef).subscribe(res => {
        //   
        // if(res['data']['data'] == '')
        // {
        //   this.courierServiceProviderMasterForm.controls['branchWiseId'].setValue('1');
        // }
        if (res['status']) { 
          let courierIdd = res['data']['totalItems'];
          this.courierServiceProviderMasterForm.controls['branchWiseId'].setValue(courierIdd + 1);
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
       
      })
    }else{
      let clinicRef= sessionStorage.getItem('clinicRef');
      let branchRef= sessionStorage.getItem('branchRef');
      this.apimicroservice.getLastCourierAll(branchRef,clinicRef).subscribe(res => {
        //   
        // if(res['data']['data'] == '')
        // {
        //   this.courierServiceProviderMasterForm.controls['branchWiseId'].setValue('1');
        // }
        if (res['status']) {
          let courierIdd = res['data']['totalItems'];
          this.superBranchId = courierIdd + 1;
          // this.courierServiceProviderMasterForm.controls['branchWiseId'].setValue(courierIdd + 1);
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
       
      })
    }
    
  }

  redirect(to): void {
    this.router.navigate([to]);
  }
 
  onChange($event: Event) {
    const file = ($event.target as HTMLInputElement).files[0];
    const fileName = file.name;
   this.errormessage = '';
    let ext = fileName.substring(fileName.lastIndexOf(".") + 1);
    if (
      ext.toLowerCase() == "png" ||
      ext.toLowerCase() == "jpg" ||
      ext.toLowerCase() == "jpeg" ||
      ext.toLowerCase() == "csv" ||
      ext.toLowerCase() == "xlsx" ||
      ext.toLowerCase() == "pdf" ||
      ext.toLowerCase() == "docx" 
    ) { 
      this.convertToBase64(file);
    }
  }
  convertToBase64(file: File) {
    const observable = new Observable((subscriber: Subscriber<any>) => {
      this.readFile(file, subscriber);
    });
    observable.subscribe((d) => {
      this.myimage = d;
      
    });
  }
  readFile(file: File, subscriber: Subscriber<any>) {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      subscriber.next(fileReader.result);
      subscriber.complete();
    };
    fileReader.onerror = (error) => {
      subscriber.error(error);
      subscriber.complete();
    };
  }

  // toCourierForm() {
  //   document.getElementById("toCourierForm").scrollIntoView();
  // }
  // toCourierList(){
  //   document.getElementById("toCourierTable").scrollIntoView();
  // }
  fetchBranchList() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      //  
      if (res.status) {    
        this.BranchList = res.data;
        this.filteredListBranch= this.BranchList.slice();
      } else {
      //  this.apimicroservice.openSnackbar(res.message);
      }
    })
  } 
  branchClick(id,branchName)
  {

    this.courierServiceProviderMasterForm.controls["branchName"].setValue(branchName);

   this.apimicroservice.getBranchById(id).subscribe(res => { 
     //
       
     let data = res.data[0];
     let payload = this.courierServiceProviderMasterForm.value;
      payload.clinicRefId = res.data[0].clinicRefId;
      this.courierServiceProviderMasterForm.controls["clinicRefId"].setValue(res.data[0].clinicRefId);
      if(this.updateButton == false){
        this.spinner.show();
        this.apimicroservice.getLastCourierID(payload.branchRefId).subscribe(res => {
            
         
          if (res['status']) {
            let courierIdd = res['data']['totalItems'];
            this.courierServiceProviderMasterForm.controls['branchWiseId'].setValue(courierIdd + 1);
          } else {
            this.apimicroservice.openSnackbar("Something went wrong");
          }
          this.spinner.hide();
        })
      }
    });
  }
   openBranchPopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpBranchComponent,
      {
        width: "1200px",
      });
      dialogRef3.afterClosed().subscribe((result) => {
        let clinicRef= sessionStorage.getItem('clinicRef');
        this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
          //  
          if (res.status) {    
            this.BranchList = res.data;
            this.filteredListBranch= this.BranchList.slice();
            this.branchClick(res.data[0]._id,res.data[0].branchName);
            this.courierServiceProviderMasterForm.get("branchRefId").setValue(res.data[0]._id)
          } else {
          //  this.apimicroservice.openSnackbar(res.message);
          }
        })
        //this.fetchBranchList();
      })
  }

  onClose(): void {
    this.dialogRef.close();
  }

  WhatsApp()
  {
    let payload = this.courierServiceProviderMasterForm.value;  
    let num = payload.phoneNo; 
    if(num.length == 10)
    {
      sessionStorage.setItem('whatsAppNumber',payload.phoneNo);
      let mobileNumber = sessionStorage.getItem('whatsAppNumber');
      window.open("https://wa.me/91"+mobileNumber);
    }
    else
    {
      this.courierServiceProviderMasterForm.get("phoneNo").markAsTouched();
    }
  }
}
