import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
 import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { AfterViewInit, Directive, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { PopUpBranchComponent } from "../pop-up-branch/pop-up-branch.component";
import { PopUpCityComponent } from "../pop-up-city/pop-up-city.component";
import { PopUpItemComponent } from "../pop-up-item/pop-up-item.component";
import { PopUpCourierserviceComponent } from "../pop-up-courierservice/pop-up-courierservice.component";
import { PopupTotalBillComponent } from "../popup-total-bill/popup-total-bill.component";

@Component({
  selector: 'app-clinic-holiday',
  templateUrl: './clinic-holiday.component.html',
  styleUrls: ['./clinic-holiday.component.scss']
})
export class ClinicHolidayComponent implements OnInit {
  clinicHolidayForm: FormGroup;
  holidayForm: FormGroup;
  date: any;
  listOfAppointment:any;
  totalLength:any;
  appointmentId: any;
  size=10;
  page=1;
  searchText: any;
  submitButton = true;
  updateButton = false;
  allColor: any;
  
  filteredList: { name: string; value: string; }[];
  filteredListAppoinmentType: { name: string; value: string; }[];
  userType: string;
  branchRef: string;
  clinicRef: string;
  BranchList: any;
  isSuperAdmin= true;
  superBranchId: any;
  branchName: string;
  filteredListBranch: any;
  currentdate: Date;
  listOfBilling: any;
  id: any;
  courierList: any;
  filteredListCourier: any;
  disableFromCity = false;
  disableToCity = true;
  tocityList: any;
  cityList: any;
  fromCity: any;
  toCity: any;
  arr =[];
  courierServiceCreate = true;
  branchCreate = true;
  billingList: any;
  search: any;
  clinicHolidayList: any;
  minDateStr: string;
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
     public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService 
  ) { }
  userPermission:any;
  billingCreate= true;
  billingUpdate= true;
  
  printData=[
    {
      srNo:1,
      date:'10/03/2020',
      aptId:101,
      patientName:'Shivani Bhosale',
      mobile:9088776653,
      status:'Paid'
     },
     {
      srNo:2,
      date:'10/03/2020',
      aptId:102,
      patientName:'Sai Mane',
      mobile:9276321653,
      status:'Unpaid'
     },
     {
      srNo:3,
      date:'10/03/2020',
      aptId:103,
      patientName:'Rahul Thakur',
      mobile:8932776653,
      status:'Unpaid'
     },
     {
      srNo:4,
      date:'10/03/2020',
      aptId:104,
      patientName:'Aishwarya Shaha',
      mobile:8877776653,
      status:'Paid'
     },                 
  ];
  branchArr = [];

  ngOnInit(): void {
    this.currentdate = new Date();
    let mm = this.currentdate.getMonth() + 1;
    this.minDateStr = this.currentdate.getDate() + '/' + mm + '/' + this.currentdate.getFullYear();
    this.branchArr = [];
    this.page=1;
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.userType = sessionStorage.getItem('userType');
    this.userType = sessionStorage.getItem('userType');
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    this.clinicHolidayForm = this.fb.group({
      "branchName": new FormControl('',Validators.required),
      "branchRefId": new FormControl('',Validators.required),
      "holidayName": new FormControl('',Validators.required),
      "fromDate": new FormControl('',Validators.required),
      "toDate": new FormControl('',Validators.required), 
      "searchHoliday": new FormControl(''),
    });
    this.holidayForm = this.fb.group({
      "branchName": new FormControl(''),
      "branchRefId": new FormControl(''),
      "holidayName": new FormControl(''),
      "holidayfromDate": new FormControl(''),
      "holidaytoDate": new FormControl(''), 
      "alertName": new FormControl('holiday'),
    });
  this.getClinicHolidays();
  this.fetchBranchList();
}

fetchBranchList() {
  let clinicRef= sessionStorage.getItem('clinicRef');
  this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => { 
    if (res.status) {    
      this.BranchList = res.data;
      this.filteredListBranch= this.BranchList.slice();
      let length =res.data.length - 1;
      this.branchName= res.data[length].branchName;
      this.clinicHolidayForm.controls["branchName"].setValue(this.branchName);
      this.clinicHolidayForm.controls["branchRefId"].setValue(res.data[length]._id); 
    } else {
    //  this.apimicroservice.openSnackbar(res.message);
    }
  })
} 

branchClick(id, bname) {
  // if(event.source.selected){
  //   this.branchArr.push(bname);
  //   ////
  // }
  // else{
  //   this.branchArr = this.branchArr.filter(b => b!=bname);
  //   ////
  // } 
  this.clinicHolidayForm.controls["branchName"].setValue(bname);
  // this.apimicroservice.getBranchById(id).subscribe(res => {
  //   let payload = this.clinicHolidayForm.value;
  //   payload.branchRefId = res.data[0]._id;
  //   this.clinicHolidayForm.controls["branchRefId"].setValue(res.data[0]._id);
  // });
}

  saveClinicHoliday(){
    let payload = this.clinicHolidayForm.value;
    //payload.branchName = this.branchArr;
    //payload.branchName = this.arr;
    this.apimicroservice.clinicHoliday(payload).subscribe(res => {
      if (res.status) {    
       // payload.branchName = this.arr;
        this.apimicroservice.openSnackbar(res.message);
        this.addHolidayAlert();
        this.ngOnInit();
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    });
  }
 
  addHolidayAlert(){
    let payload = this.clinicHolidayForm.value;
    let payload2 = this.holidayForm.value;
    payload2.branchName = payload.branchName;
    payload2.branchRefId = payload.branchRefId;
    payload2.holidayName = payload.holidayName;
    payload2.holidayfromDate = payload.fromDate;
    payload2.holidaytoDate = payload.toDate;
    payload2.alertType = 21;
   
    this.apimicroservice.addHolidayAlert(payload2).subscribe(res => {
      if (res.status) {    
       // payload.branchName = this.arr;
        this.apimicroservice.openSnackbar(res.message);
        window.location.reload();
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    });
  }
  clear(){
    this.clinicHolidayForm.reset();
    window.location.reload();
    this.ngOnInit();
  }
  getClinicHolidays(){
    this.page = 1;
    this.search = '';
    this.id = '';
    let branchRef= sessionStorage.getItem('branchRef');
    this.apimicroservice.getClinicHolidays(this.page,this.size,this.search,this.id,branchRef).subscribe(res => {
      if (res.status) {   
        ////
        this.clinicHolidayList = res.data.clinicalStaffData;
        this.totalLength = res.data.totalItems;
       // this.apimicroservice.openSnackbar(res.message);
      } else {
       // this.apimicroservice.openSnackbar(res.message);
      }
    });
  }

  addData(){
    this.spinner.show();
    let payload = this.clinicHolidayForm.value;
    payload.fromCityName = this.fromCity;
    payload.toCityName = this.toCity;
    if(this.userType !== 'Super Admin')
    {
      payload.branchRefId = this.branchRef;
      payload.clinicRefId = this.clinicRef;
      payload.branchName = this.branchName;
    }
   ////
   
    this.apimicroservice.addInwardOutward(payload).subscribe(res => {
      this.spinner.hide();
      this.ngOnInit();
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);
        
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    },(ERROR:HttpErrorResponse) => {
      this.apimicroservice.openSnackbar(ERROR.error.message);
     ////
     this.spinner.hide();
  });
  }

  redirectToBillForm(receiptNo){
    this.router.navigate(['cheque-reconciliation',receiptNo]);
  }
 
  // fetchChequeList() {
  //   // let pg = 1;
  //   // let size = 10;
  //   let search='';
  //   this.billingList = [];
  //   let modeOfPayment = 'Cheque';
  //   this.apimicroservice.getPaymentHistoryList(this.page,this.size,search,modeOfPayment).subscribe(res => {
  //     ////  
  //     if (res.status) {    
  //       this.billingList = res.data.data;
  //       this.totalLength=res.data.totalItems;
        
  //     } else {
  //       this.apimicroservice.openSnackbar(res.message);
  //     }
  //   });
    
   
  // }

  redirect(to): void {
    this.router.navigate([to]);
  } 

  prepopulation(id) {
    this.spinner.show();
    this.search= '';
    let branchRef= sessionStorage.getItem('branchRef');
    this.apimicroservice.getClinicHolidays(this.page,this.size,this.search,id,branchRef).subscribe(res => {
      this.spinner.hide();      
      let data = res.data.clinicalStaffData[0];
      ////
       
      this.id = id;
       if(res.status){
         this.updateButton = true;
         this.submitButton = false;
         this.clinicHolidayForm = this.fb.group({
          "branchName": new FormControl(data.branchName,Validators.required),
          "branchRefId": new FormControl(data.branchRefId,Validators.required),
          "holidayName": new FormControl(data.holidayName,Validators.required),
          "fromDate": new FormControl(data.fromDate,Validators.required),
          "toDate": new FormControl(data.toDate,Validators.required), 
          "searchHoliday": new FormControl(''),
        });  
       }else{

       }
     });
  }

  validateSave(){
    let payload = this.clinicHolidayForm.value;
    if (this.clinicHolidayForm.invalid) {
    
      this.clinicHolidayForm.get("branchRefId").markAsTouched();
      this.clinicHolidayForm.get("holidayName").markAsTouched();
      this.clinicHolidayForm.get("fromDate").markAsTouched();
      this.clinicHolidayForm.get("toDate").markAsTouched();
    } else {
      this.saveClinicHoliday();
     
    }
  }

  validateUpdate(){
    if (this.clinicHolidayForm.invalid) {
      this.clinicHolidayForm.get("branchRefId").markAsTouched();
      this.clinicHolidayForm.get("holidayName").markAsTouched();
      this.clinicHolidayForm.get("fromDate").markAsTouched();
      this.clinicHolidayForm.get("toDate").markAsTouched();
    } else {
      this.updateData();
    }
  }

  updateData(){
    this.spinner.show();
    let payload = this.clinicHolidayForm.value;
     this.apimicroservice.updateClinicHoliday(this.id,payload).subscribe(res => {
       this.spinner.hide();
       if(res.status){
         this.apimicroservice.openSnackbar(res.message);
          this.updateButton = false;
          this.submitButton = true;
          this.ngOnInit();
       }else{ 
     //   this.apimicroservice.openSnackbar(res.message);
       }
     });
  }
 
  // cancelUpdate(){
  //   this.clinicHolidayForm.reset();
  //   this.submitButton=true;
  //   this.updateButton=false;
  //   this.ngOnInit();
  // }

  openSnackbar(message: string) {
    return this._snackBar.open(message, "close", {
      verticalPosition: "bottom",
      horizontalPosition: "right",
      duration: 2000,
      panelClass: ["snackbarStyle"],
    });
  }
  toclinicHolidayForm() {
    document.getElementById("toclinicHolidayForm").scrollIntoView();
  }
  toClinicHolidayLst() {
    document.getElementById("toClinicHolidayLst").scrollIntoView();
  }

  onPageChange(event) {
    this.page = event;
    this.search = this.clinicHolidayForm.get('searchHoliday').value;
    this.id = '';
    let branchRef= sessionStorage.getItem('branchRef');
    this.apimicroservice.getClinicHolidays(this.page,this.size,this.search,this.id,branchRef).subscribe(res => {
      if (res.status) {  
        this.clinicHolidayList = res.data.clinicalStaffData;
        this.totalLength = res.data.totalItems;
        this.apimicroservice.openSnackbar(res.message);
      } else {
       // this.apimicroservice.openSnackbar(res.message);
      }
    });
  }
  onKeyUpEvent(event: any) {
    this.page = 1;
    this.search = this.clinicHolidayForm.get('searchHoliday').value;
    this.id = '';
    let branchRef= sessionStorage.getItem('branchRef');
    this.apimicroservice.getClinicHolidays(this.page,this.size,this.search,this.id,branchRef).subscribe(res => {
      if (res.status) {  
        this.clinicHolidayList = res.data.clinicalStaffData;
        this.totalLength = res.data.totalItems;
        this.apimicroservice.openSnackbar(res.message);
      } else {
      //  this.apimicroservice.openSnackbar(res.message);
      }
    });
  }

  billing()
  {
    const dialogRef = this.dialog.open(PopupTotalBillComponent,
      {
        data: {
          patientRefId: '',
          appointId:'',
          patientDetails:'',
          page:"Billing Page"
        }
      }
      );
  }

  branchSelect(event :any,e)
  {
    let payload = this.clinicHolidayForm.value;
    if(event.source.selected){
      this.arr.push(e);
    }
    else{
      this.arr = this.arr.filter(b => b!=e);
    } 
    ////
    this.clinicHolidayForm.controls["branchName"].setValue(this.arr);
   // payload.branchName = this.arr;
  }
  openBranchPopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpBranchComponent,
      {
        width: "1200px",
      }); 
      dialogRef3.afterClosed().subscribe((result) => {
        let clinicRef= sessionStorage.getItem('clinicRef');
        this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => { 
          if (res.status) {    
            this.BranchList = res.data;
            this.filteredListBranch= this.BranchList.slice();
            this.branchClick(res.data[0]._id,res.data[0].branchName);
            this.clinicHolidayForm.get("branchRefId").setValue(res.data[0]._id);
          } else {
          //  this.apimicroservice.openSnackbar(res.message);
          }
        })
       // this.fetchBranchList();
      })
  }
  toOpenDateChnage()
  {
    ////
    
    let payload = this.clinicHolidayForm.value; 
    let fDate = payload.fromDate.toString();
    let tDate = payload.toDate.toString();
    if(fDate != tDate)
    {
      if(payload.fromDate >= payload.toDate && payload.toDate !== '')
    {
      this.apimicroservice.openSnackbar("From Date should be Less than To Date");
      this.clinicHolidayForm.controls['fromDate'].setValue('');
    }
    }

  }
  toCloseDateChnage()
  {
    ////
    let payload = this.clinicHolidayForm.value;   
    let fDate = payload.fromDate.toString();
    let tDate = payload.toDate.toString();
    if(fDate != tDate)
    {
    if(payload.toDate <= payload.fromDate && payload.fromDate !== '')
    {
      this.apimicroservice.openSnackbar("To Date should be Greater than From Date");
      this.clinicHolidayForm.controls['toDate'].setValue('');
    }
  }
  } 

  // toOpenDateChnage()
  // {
  //   let payload = this.clinicHolidayForm.value; 
  //   if(payload.fromDate >= payload.toDate && payload.toDate !== '')
  //   {
  //     this.apimicroservice.openSnackbar("From Date should be Less than To Date");
  //     this.clinicHolidayForm.controls['fromDate'].setValue('');
  //   }
  // }
  // toCloseDateChnage()
  // {
  //   let payload = this.clinicHolidayForm.value;   
  //   if(payload.toDate <= payload.fromDate && payload.fromDate !== '')
  //   {
  //     this.apimicroservice.openSnackbar("To Date should be Greater than From Date");
  //     this.clinicHolidayForm.controls['toDate'].setValue('');
  //   }
  // }
  onback() {
    this.apimicroservice.back()
  }
}
 
