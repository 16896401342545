import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
 import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { AfterViewInit, Directive, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { PopUpBranchComponent } from "../pop-up-branch/pop-up-branch.component";
import { PopUpCityComponent } from "../pop-up-city/pop-up-city.component";
import { PopUpItemComponent } from "../pop-up-item/pop-up-item.component";
import { PopUpCourierserviceComponent } from "../pop-up-courierservice/pop-up-courierservice.component";
import { PopupTotalBillComponent } from "../popup-total-bill/popup-total-bill.component";

@Component({
  selector: 'app-cheque-list',
  templateUrl: './cheque-list.component.html',
  styleUrls: ['./cheque-list.component.scss']
})
export class ChequeListComponent implements OnInit {
  ChequeListForm: FormGroup;
  date: any;
  listOfAppointment:any;
  totalLength:any;
  appointmentId: any;
  size=10;
  page=1;
  searchText: any;
  submitButton = true;
  updateButton = false;
  allColor: any;
  
  filteredList: { name: string; value: string; }[];
  filteredListAppoinmentType: { name: string; value: string; }[];
  userType: string;
  branchRef: string;
  clinicRef: string;
  BranchList: any;
  isSuperAdmin= true;
  superBranchId: any;
  branchName: string;
  filteredListBranch: any;
  currentdate: any;
  listOfBilling: any;
  id: any;
  courierList: any;
  filteredListCourier: any;
  disableFromCity = false;
  disableToCity = true;
  tocityList: any;
  cityList: any;
  fromCity: any;
  toCity: any;
  courierServiceCreate = true;
  branchCreate = true;
  billingList: any;
  minDate: any;
  maxDate: any;
  isSearch = false;
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
     public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService 
  ) { }
  userPermission:any;
  billingCreate= true;
  billingUpdate= true;
  printData=[
    {
      srNo:1,
      date:'10/03/2020',
      aptId:101,
      patientName:'Shivani Bhosale',
      mobile:9088776653,
      status:'Paid'
     },
     {
      srNo:2,
      date:'10/03/2020',
      aptId:102,
      patientName:'Sai Mane',
      mobile:9276321653,
      status:'Unpaid'
     },
     {
      srNo:3,
      date:'10/03/2020',
      aptId:103,
      patientName:'Rahul Thakur',
      mobile:8932776653,
      status:'Unpaid'
     },
     {
      srNo:4,
      date:'10/03/2020',
      aptId:104,
      patientName:'Aishwarya Shaha',
      mobile:8877776653,
      status:'Paid'
     },                 
  ]
  ngOnInit(): void {
    this.currentdate = new Date();
    this.minDate = new Date();
    this.maxDate = new Date();
    this.isSearch = false;
    this.page=1;
    this.size=10;
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.userType = sessionStorage.getItem('userType');
    this.userType = sessionStorage.getItem('userType');
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
   // const currentYear = new Date().getFullYear();
   // this.minDate = new Date(this.currentdate.getDate() - 15);
   
    this.minDate.setDate(this.minDate.getDate()-15);
    this.maxDate.setDate(this.maxDate.getDate()+15);
       //
       //
    if(this.userType !== 'Super Admin'){
      this.isSuperAdmin= false;
    this.userPermission.forEach((element) => {
      if(element.pageName == 'Cheque List' && element.create == false)
      {
          this.billingCreate = false;  
      }
      if(element.pageName == 'Cheque List' && element.update == false)
      {
          this.billingUpdate = false;  
      }  
     
    });
  } 
  this.disableFromCity = false;
  this.disableToCity = true;
    this.ChequeListForm = this.fb.group({
      "chequeNo": new FormControl(''),
      "chequeStatus": new FormControl(''),
      "billNo": new FormControl(''),
      "status": new FormControl(''),
      "fdate": new FormControl(''),
      "todate": new FormControl(''),
      "docketNo": new FormControl('',[Validators.pattern("^[0-9]*$")]), 
      "searchBilling": new FormControl(''), 
      
    });
    
    this.ChequeListForm.controls['fdate'].setValue(this.minDate);
    this.ChequeListForm.controls['todate'].setValue(this.maxDate);
 // this.removeValidator();
  this.fetchChequeList();
}


  // setValidator() { 
  //   this.ChequeListForm.controls['date'].setValidators(Validators.required);
  //   this.ChequeListForm.controls['date'].updateValueAndValidity();

  //   this.ChequeListForm.controls['inwardOutward'].setValidators(Validators.required);
  //   this.ChequeListForm.controls['inwardOutward'].updateValueAndValidity();

  //   this.ChequeListForm.controls['fromInwardOutward'].setValidators(Validators.required);
  //   this.ChequeListForm.controls['fromInwardOutward'].updateValueAndValidity();

  //   this.ChequeListForm.controls['toInwardOutward'].setValidators(Validators.required);
  //   this.ChequeListForm.controls['toInwardOutward'].updateValueAndValidity();

  //   this.ChequeListForm.controls['courierRefId'].setValidators(Validators.required);
  //   this.ChequeListForm.controls['courierRefId'].updateValueAndValidity();

  //   this.ChequeListForm.controls['item'].setValidators(Validators.required);
  //   this.ChequeListForm.controls['item'].updateValueAndValidity();

  // }

  // removeValidator() { 
  //   this.ChequeListForm.controls['date'].setErrors(null);
  //   this.ChequeListForm.controls['date'].clearValidators();
  //   this.ChequeListForm.controls['date'].updateValueAndValidity();

  //   this.ChequeListForm.controls['inwardOutward'].setErrors(null);
  //   this.ChequeListForm.controls['inwardOutward'].clearValidators();
  //   this.ChequeListForm.controls['inwardOutward'].updateValueAndValidity();

  //   this.ChequeListForm.controls['fromInwardOutward'].setErrors(null);
  //   this.ChequeListForm.controls['fromInwardOutward'].clearValidators();
  //   this.ChequeListForm.controls['fromInwardOutward'].updateValueAndValidity();

  //   this.ChequeListForm.controls['toInwardOutward'].setErrors(null);
  //   this.ChequeListForm.controls['toInwardOutward'].clearValidators();
  //   this.ChequeListForm.controls['toInwardOutward'].updateValueAndValidity();

  //   this.ChequeListForm.controls['courierRefId'].setErrors(null);
  //   this.ChequeListForm.controls['courierRefId'].clearValidators();
  //   this.ChequeListForm.controls['courierRefId'].updateValueAndValidity();

  //   this.ChequeListForm.controls['item'].setErrors(null);
  //   this.ChequeListForm.controls['item'].clearValidators();
  //   this.ChequeListForm.controls['item'].updateValueAndValidity();

  // }

  searchChequeData(){
    this.billingList = [];
    this.isSearch = true;
    this.searchText = this.ChequeListForm.get('searchBilling').value;
    let chequeNo = this.ChequeListForm.get('chequeNo').value;
    //let patientName = this.ChequeListForm.get('patientName').value;
    let billNo = this.ChequeListForm.get('billNo').value;
    let chequeStatus = this.ChequeListForm.get('chequeStatus').value;
    let fromDate = this.ChequeListForm.get('fdate').value;
    let toDate = this.ChequeListForm.get('todate').value;
    let branchRef= sessionStorage.getItem('branchRef');
    //
    //
    // /page=1&size=10&search=&billNo=&chequeNo=&chequeStatus=&fromDate=&toDate=
    this.apimicroservice.getPaymentHistoryData2(this.page,this.size,this.searchText,billNo,chequeNo,chequeStatus,fromDate,toDate,branchRef).subscribe(res => {
      //  
    
      if (res.status) {    
        this.billingList = res.data.data;
        this.totalLength=res.data.totalItems;
        
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    });
  }

  addData(){
    this.spinner.show();
    let payload = this.ChequeListForm.value;
    payload.fromCityName = this.fromCity;
    payload.toCityName = this.toCity;
    if(this.userType !== 'Super Admin')
    {
      payload.branchRefId = this.branchRef;
      payload.clinicRefId = this.clinicRef;
      payload.branchName = this.branchName;
    }
   //
   
    this.apimicroservice.addInwardOutward(payload).subscribe(res => {
      this.spinner.hide();
      this.ngOnInit();
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);
        
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    },(ERROR:HttpErrorResponse) => {
      this.apimicroservice.openSnackbar(ERROR.error.message);
     //
     this.spinner.hide();
  });
  }
  
  redirectToBillForm(receiptNo,chequeNo){ 
    sessionStorage.setItem('chequeNo',chequeNo);
    sessionStorage.setItem('rNo',receiptNo);
    this.router.navigate(['cheque-reconciliation',chequeNo]);  
  }
 
  fetchChequeList() {
    // let pg = 1;
    // let size = 10;
    let search='';
   // this.billingList = [];
    let modeOfPayment = 'Cheque';
    // this.apimicroservice.getPaymentHistoryList(this.page,this.size,search,modeOfPayment).subscribe(res => {
    //   //  
    //   if (res.status) {    
    //     this.billingList = res.data.data;
    //     this.totalLength=res.data.totalItems;
        
    //   } else {
    //     this.apimicroservice.openSnackbar(res.message);
    //   }
    // });
    let branchRef= sessionStorage.getItem('branchRef');
    this.apimicroservice.getPaymentHistoryList(this.page,this.size,search,branchRef).subscribe(res => {
        //  
        if (res.status) {    
          this.billingList = res.data.data;
          this.totalLength=res.data.totalItems;
          
        } else {
          this.apimicroservice.openSnackbar(res.message);
        }
      });
   
  }
  prepopulation(id) {
    this.redirect('bill-form');
    
  }
  redirect(to): void {
    this.router.navigate([to]);
   } 

  // prepopulation(id) {
  //   this.spinner.show();
  //   this.apimicroservice.getInOutwardById(id).subscribe(res => {
  //     this.spinner.hide();
  //     let data = res.data[0]; 
  //     this.id = id;
  //      if(res.status){
  //        this.updateButton = true;
  //        this.submitButton = false;
  //        this.disableFromCity = true;
  //        this.disableToCity = true;
  //        this.ChequeListForm = this.fb.group({
  //         "branchWiseId": new FormControl(data.branchWiseId),
  //         "date": new FormControl(data.date,Validators.required),
  //         "inwardOutward": new FormControl(data.inwardOutward,Validators.required),
  //         "fromInwardOutward": new FormControl(data.fromInwardOutward,Validators.required),
  //         "toInwardOutward": new FormControl(data.toInwardOutward,Validators.required),
  //         "fromCityName": new FormControl(data.fromCityName),
  //         "toCityName": new FormControl(data.toCityName),
  //         "docketNo": new FormControl(data.docketNo,[Validators.pattern("^[0-9]*$")]), 
  //         "branchRefId": new FormControl(data.branchRefId),
  //         "item": new FormControl(data.item,Validators.required), 
  //         "branchName": new FormControl(data.branchName),
  //         "courierRefId": new FormControl(data.courierRefId,Validators.required), 
  //         "courierProviderName": new FormControl(data.courierProviderName),
  //         "clinicRefId": new FormControl(data.clinicRefId),
  //         "remark": new FormControl(data.remark),
  //         "searchBilling": new FormControl(''), 
  //       });
  //       this.superBranchId = data.branchWiseId;   
  //      }else{

  //      }
  //    });
  // }

  // UpdateInwordOutword(){
  //  // this.setValidator();
  //   let payload = this.ChequeListForm.value;
    
  //   if(this.userType == 'Super Admin')
  //   {
  //     this.ChequeListForm.controls['branchRefId'].setValidators(Validators.required);
  //     this.ChequeListForm.controls['branchRefId'].updateValueAndValidity();
  //   }
  //   else{
  //     this.ChequeListForm.controls['branchRefId'].setErrors(null);
  //     this.ChequeListForm.controls['branchRefId'].clearValidators();
  //     this.ChequeListForm.controls['branchRefId'].updateValueAndValidity();
  //   }
    
  //   if (this.ChequeListForm.invalid) {

  //      this.ChequeListForm.get("date").markAsTouched();
  //      this.ChequeListForm.get("inwardOutward").markAsTouched();
  //      this.ChequeListForm.get("fromInwardOutward").markAsTouched();
  //      this.ChequeListForm.get("toInwardOutward").markAsTouched();
  //      this.ChequeListForm.get("courierRefId").markAsTouched();
  //      this.ChequeListForm.get("item").markAsTouched();

  //   }else {
  //     this.updateData();
  //   }
  // }

  // updateData(){
  //   this.spinner.show();
  //   let payload = this.ChequeListForm.value;
  //   payload.fromCityName = this.fromCity;
  //   payload.toCityName = this.toCity;
  //   if(this.userType !== 'Super Admin')
  //   {
  //     payload.branchRefId = this.branchRef;
  //     payload.clinicRefId = this.clinicRef;
  //     payload.branchName = this.branchName;
  //   }
  
  //    this.apimicroservice.updateInOutwardDetails(payload,this.id).subscribe(res => {
  //      //
  //      this.spinner.hide();
  //      if(res.status){
  //        this.apimicroservice.openSnackbar(res.message);
  //         this.updateButton = false;
  //         this.submitButton = true;
  //         this.ngOnInit();
  //      }else{ 
  //      //  //this.apimicroservice.openSnackbar(res.message);
  //      }
  //    });
  // }
 
  // cancelUpdate(){
  //   this.ChequeListForm.reset();
  //   this.submitButton=true;
  //   this.updateButton=false;
  //   this.ngOnInit();
  // }

  openSnackbar(message: string) {
    return this._snackBar.open(message, "close", {
      verticalPosition: "bottom",
      horizontalPosition: "right",
      duration: 2000,
      panelClass: ["snackbarStyle"],
    });
  }
  toChequeListForm() {
    document.getElementById("toChequeListForm").scrollIntoView();
  }
  toBillingLst() {
    document.getElementById("toBillingLst").scrollIntoView();
  }

  onPageChange(event) {
    this.page = event;
    // this.billingList = [];
    this.searchText = this.ChequeListForm.get('searchBilling').value;
    let chequeNo = this.ChequeListForm.get('chequeNo').value;
    //let patientName = this.ChequeListForm.get('patientName').value;
    let billNo = this.ChequeListForm.get('billNo').value;
    let chequeStatus = this.ChequeListForm.get('chequeStatus').value;
    let fromDate = this.ChequeListForm.get('fdate').value;
    let toDate = this.ChequeListForm.get('todate').value; 
    let branchRef= sessionStorage.getItem('branchRef');
    //
    //
    if(this.searchText == null)
    {
      this.searchText = '';
    }
    if(this.isSearch == true)
    {
      this.apimicroservice.getPaymentHistoryData2(this.page,this.size,this.searchText,billNo,chequeNo,chequeStatus,fromDate,toDate,branchRef).subscribe(res => {
        //  
        if (res.status) {    
          this.billingList = res.data.data;
          this.totalLength=res.data.totalItems; 
          
        } else {
          this.apimicroservice.openSnackbar(res.message);
        }
      });
    }
    else
    {
      let branchRef= sessionStorage.getItem('branchRef');
      this.apimicroservice.getPaymentHistoryList(this.page,this.size,this.searchText,branchRef).subscribe(res => {
        //  
        if (res.status) {    
          this.billingList = res.data.data;
          this.totalLength=res.data.totalItems;
          
        } else {
          this.apimicroservice.openSnackbar(res.message);
        }
      });
    }
    
  }
  onKeyUpEvent(event: any) {
    
    //this.billingList = [];
    this.searchText = this.ChequeListForm.get('searchBilling').value;
    let chequeNo = this.ChequeListForm.get('chequeNo').value;
    //let patientName = this.ChequeListForm.get('patientName').value;
    let billNo = this.ChequeListForm.get('billNo').value;
    let chequeStatus = this.ChequeListForm.get('chequeStatus').value;
    let fromDate = this.ChequeListForm.get('fdate').value;
    let toDate = this.ChequeListForm.get('todate').value;
    let branchRef= sessionStorage.getItem('branchRef');
    //
    //
    if(this.isSearch == true)
    {
    this.apimicroservice.getPaymentHistoryData2(this.page,this.size,this.searchText,billNo,chequeNo,chequeStatus,fromDate,toDate,branchRef).subscribe(res => {
      //  
      if (res.status) {    
        this.billingList = res.data.data;
        this.totalLength=res.data.totalItems;
        
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    });
  }
  else
  {
    let branchRef= sessionStorage.getItem('branchRef');
    this.apimicroservice.getPaymentHistoryList(this.page,this.size,this.searchText,branchRef).subscribe(res => {
      //  
      if (res.status) {    
        this.billingList = res.data.data;
        this.totalLength=res.data.totalItems;
        
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    });
  }
  }

  toOpenDateChnage()
  {

    let payload = this.ChequeListForm.value;
    // const date1 = new Date(dd.toDateString()+" "+a);
    // const date2 = new Date(dd.toDateString()+" "+b);
    
    if(payload.fdate >= payload.todate && payload.todate !== '')
    {
      this.apimicroservice.openSnackbar("From Date should be Less than To Date");
      this.ChequeListForm.controls['fdate'].setValue('');
    }
  }
  toCloseDateChnage()
  {
    
    let payload = this.ChequeListForm.value;
    // const date1 = new Date(dd.toDateString()+" "+a);
    // const date2 = new Date(dd.toDateString()+" "+b);
    
    if(payload.todate <= payload.fdate && payload.fdate !== '')
    {
      this.apimicroservice.openSnackbar("To Date should be Greater than From Date");
      this.ChequeListForm.controls['todate'].setValue('');
    }
  }
  billing()
  {
    const dialogRef = this.dialog.open(PopupTotalBillComponent,
      {
        data: {
          patientRefId: '',
          appointId:'',
          patientDetails:'',
          page:"Billing Page"
        }
      }
      );
  }
  onback() {
    this.apimicroservice.back()
  }

  clear(){
    //this.reportForm.reset();
    window.location.reload();
    //this.ngOnInit();
  }
} 

