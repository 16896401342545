<div class="container-fluid bgcolor2" [formGroup]="inwardOutwardForm">
    <form  [formGroup]="inwardOutwardForm" autocomplete="off" novalidate>
      <div  class="row">
          <div class="col-sm-1">
  
          </div>
          <div class="col-sm-11">
            <div class="row pl-0 ">
              <div class="col-12 pl-0 ">
                <div class="row ">
                <div class="col-sm-9">
                    <h1 class="pt-2 pl-0" id="toInwardOutwardForm">Inward Outward</h1>
                    </div>
                    <div class="col-sm-3 mt-1"> 
                      <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                        <a (click)="onback()" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">arrow_back</span>Back</a>
                        <a (click)="toInwardOutwardLst()" class="d-flex align-items-center ms-3 pr-2 mt-2"><span class="material-symbols-outlined pr-2">reorder</span>List</a>
                   </div>
              </div>
            </div>
           </div>
           </div>  
  
              <div class="row">
                  <div class="col-sm-12 pr-4">
                      <div class="row">
                          <div class="card divbody mb-3 borderradius p-2">
                             
                              <div class="col-sm-12">
                                  <div class="row">
                                      <div class="col-sm-1 mt-3 "> 
                                        <mat-label class="center" ngDefaultControl formControlName="branchWiseId" *ngIf="userType !== 'Super Admin'" >{{bId}}</mat-label>
                                        <mat-label class="center" ngDefaultControl [value]="superBranchId" *ngIf="userType == 'Super Admin'" >{{superBranchId}}</mat-label>
                                 
                            <!-- <mat-label class="center" ngDefaultControl formControlName="branchWiseId">{{bId}}</mat-label>
                            <mat-label class="center" ngDefaultControl [value]="superBranchId" *ngIf="userType == 'Super Admin'" >{{superBranchId}}</mat-label> -->
                                        </div>
  
                                      <div class="col-sm-3">
                                          <mat-form-field class="example-full-width " appearance="outline">
                                              <mat-label>Date</mat-label>
                                              <input required matInput placeholder="Choose a date" 
                                              formControlName="date" readonly>
                               
                                               <!-- <mat-datepicker-toggle matSuffix [for]="date">
                                               </mat-datepicker-toggle>
                                               <mat-datepicker #date></mat-datepicker> -->
                                             
                                          </mat-form-field>
                                          <mat-error
                                          *ngIf="inwardOutwardForm.controls['date'].touched && inwardOutwardForm.controls['date'].errors?.required">
                                          This field is mandatory.
                                        </mat-error>
                                      </div>
                                     
                                        <!-- <div class="col-sm-3 mt-3">
                                          <label class="header">Inward/Outward :</label>
                                     
                                          </div> -->
                                      <div class="col-sm-2">
                                
                                          <mat-radio-group formControlName="inwardOutward" class="example-full-width" aria-label="Select an option">
                                              <div class="row mt-3">
                                                  <div class="col-sm-4 p-1">
                                              <mat-radio-button value="Inward">Inward</mat-radio-button>
                                              </div>
                                              <div class="col-sm-4 p-1">
                                            <mat-radio-button value="Outward">Outward</mat-radio-button>
                                            </div>
                                            
                                        </div>
                                          </mat-radio-group>
                                       
                                    
                                        </div>
                                        <div class="col-sm-6"></div>
                                         <!-- <div class="col-sm-5"></div> -->
                                        <!-- <div class="col-sm-1 mt-3 ">
  
                               </div> -->
                               <div class="col-sm-3"> 
                                <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label *ngIf="outward">To</mat-label>
                                  <mat-label *ngIf="inward">From</mat-label> 
                                  <mat-select required formControlName="refType">
                                    <mat-option (click)="selectRef('Client');" value="Client">Client</mat-option>
                                    <mat-option (click)="selectRef('Manufacturer');" value="Manufacturer">Manufacturer</mat-option>
                                  </mat-select>
                                </mat-form-field>
                 <mat-error class="mt-1"
                *ngIf="inwardOutwardForm.controls['refType'].touched && inwardOutwardForm.controls['refType'].errors?.required">
               This field is mandatory.
               </mat-error>
                              </div> 
                                        <div *ngIf="client" class="col-sm-3">
                                          <div class="row"> 
                                            <div class="col-sm-10 pr-1">
                                              <mat-form-field class="example-full-width" appearance="outline">
                                                <mat-label>Patient Name</mat-label>
                                                <input (click)="openDepartmentPopUp();" matInput readonly placeholder="Patient Name" formControlName="patientName" />
                                                </mat-form-field> 
                                                <mat-error
                                                *ngIf="inwardOutwardForm.controls['patientName'].touched && inwardOutwardForm.controls['patientName'].errors?.required">
                                                This field is mandatory.
                                              </mat-error>
                                              </div>
                                              <div class="col-sm-2 mt-2 mTop">
                                                <i (click)="openPatientPopUp();" matTooltip="Add New Patient" class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                                              </div>   
                                              </div>
                                              </div>
          
                                              <div *ngIf="manufacturer" class=" col-sm-3">
                                                <div class="row"> 
                                                  <div class="col-sm-10 pr-1">
                                                    <mat-form-field class="example-full-width" appearance="outline">
                                                      <mat-label>Select Manufacturer </mat-label>
                                                      <mat-select  #select3 formControlName="companyRefId" >
                                                        <mat-select-filter *ngIf="select3.focused" [placeholder]="'Filter'" [displayMember]="'companyName'"
                                                        [array]="companyList"  (filteredReturn)="filteredListCompany =$event"></mat-select-filter>    
                                                        <mat-option *ngFor="let item of filteredListCompany" 
                                                        [value]="item._id" (click)="selectCompany(item.companyName);"
                                                      >  
                                                       {{ item.companyName }}</mat-option>
                                                      </mat-select>
                                                    </mat-form-field>
                                                    <mat-error class="margin_tp"
                                                    *ngIf="inwardOutwardForm.controls['companyRefId'].touched && inwardOutwardForm.controls['companyRefId'].errors?.required">
                                                    This field is mandatory.
                                                  </mat-error>
                                              
                                                </div>
                                                <div class="col-sm-2 mTop no-print">
                                                  <i (click)="openCompanyPopUp();" matTooltip="Add Company" class="blue_col mTop fa-solid fa-circle-plus
                                                   pl-1 fa-lg"></i>
                                                </div>  
                                                </div>
                                                </div>
                                              
                                                <div class=" col-sm-3">
                                                  <div class="row">
                                                    <div class="col-sm-10 pr-1">
                                                      <mat-form-field class="example-full-width" appearance="outline">
                                                        <mat-label>Select Type</mat-label> 
                                                        <mat-select #select3 formControlName="typeRefId" >
                                                          <mat-select-filter *ngIf="select3.focused" [placeholder]="'Filter'" [displayMember]="'typeName'"
                                                          [array]="inoutTypeList" (filteredReturn)="filteredListInoutType =$event"></mat-select-filter>    
                                                          <mat-option *ngFor="let item of filteredListInoutType" 
                                                          [value]="item._id" (click)="type(item.inoutType);">
                                                         {{ item.inoutType }}</mat-option>
                                                        </mat-select>
                                                      </mat-form-field>
                                                      <mat-error class="mt-1"
                                                      *ngIf="inwardOutwardForm.controls['typeRefId'].touched && inwardOutwardForm.controls['typeRefId'].errors?.required">
                                                     This field is mandatory.
                                                     </mat-error>
                                                  </div>
                                                  <div class="col-sm-2 mTop no-print">
                                                    <i (click)="openTypePopUp();" matTooltip="Add New Type" 
                                                    class="blue_col mTop fa-solid fa-circle-plus
                                                     pl-1 fa-lg"></i>
                                                  </div>  
                                                  </div>
                                                  </div>
                                                  <div *ngIf="isDevices" class="col-sm-3 ">
                                                    <mat-form-field class="example-full-width" appearance="outline">
                                                      <mat-label>Select Device</mat-label>
                                                      <mat-select required formControlName="deviceType">
                                                        <mat-option value="New">New</mat-option>
                                                        <mat-option value="For Repair">For Repairs</mat-option>
                                                      </mat-select>
                                                    </mat-form-field>
                                     <mat-error class="mt-1"
                                    *ngIf="inwardOutwardForm.controls['deviceType'].touched && inwardOutwardForm.controls['deviceType'].errors?.required">
                                   This field is mandatory.
                                   </mat-error>
                                                  </div>
                                                  <div *ngIf="isDocuments" class="col-sm-3 ">
                                                    <mat-form-field class="example-full-width" appearance="outline">
                                                      <mat-label>Select Documents</mat-label>
                                                      <mat-select required formControlName="docType">
                                                        <mat-option value="Letter">Letter</mat-option>
                                                        <mat-option value="Miscellaneous">Miscellaneous</mat-option>
                                                      </mat-select>
                                                    </mat-form-field>
                                     <mat-error class="mt-1"
                                    *ngIf="inwardOutwardForm.controls['docType'].touched && inwardOutwardForm.controls['docType'].errors?.required">
                                   This field is mandatory.
                                   </mat-error>
                                                  </div>
                                                  <div class="col-sm-3">
                                                    <mat-form-field class="example-full-width " appearance="outline">
                                                        <mat-label>Documents Number</mat-label>
                                                        <input maxlength="15" matInput placeholder="Add Documents Number" 
                                                        formControlName="documentsNumber" >          
                                                    </mat-form-field>
                                                    <mat-error class="mt-1"
                                                      *ngIf="inwardOutwardForm.controls['documentsNumber'].touched && inwardOutwardForm.controls['documentsNumber'].errors?.required">
                                                     This field is mandatory.
                                                     </mat-error>
                                                </div> 
                                              
                                                <div *ngIf="outward" class=" col-sm-3">
                                                  <div class="row">
                                                    <div class="col-sm-10 pr-1">
                                                      <mat-form-field class="example-full-width" appearance="outline">
                                                        <mat-label>City</mat-label> 
                                                        <mat-select #select3 formControlName="cityRefId" >
                                                          <mat-select-filter *ngIf="select3.focused" [placeholder]="'Filter'" [displayMember]="'cityName'"
                                                          [array]="cityList" (filteredReturn)="filteredListCity =$event"></mat-select-filter>    
                                                          <mat-option *ngFor="let item of filteredListCity" 
                                                          [value]="item._id" (click)="selectCity(item.cityName);">
                                                         {{ item.cityName }}</mat-option>
                                                        </mat-select>
                                                      </mat-form-field>
                                               
                                                  </div>
                                                  <div class="col-sm-2 mTop no-print">
                                                    <i (click)="openFromCityPopUp();" matTooltip="Add City" class="blue_col mTop fa-solid fa-circle-plus
                                                     pl-1 fa-lg"></i>
                                                  </div>  
                                                  </div>
                                                  </div>
                                                <div class=" col-sm-3">
                                                  <div class="row">
                                                    <div class="col-sm-10 pr-1">
                                                      <mat-form-field class="example-full-width" appearance="outline">
                                                        <mat-label>Courier Service</mat-label> 
                                                        <mat-select  #select3 formControlName="courierRefId" >
                                                          <mat-select-filter *ngIf="select3.focused" [placeholder]="'Filter'" [displayMember]="'serviceProviderName'"
                                                          [array]="courierList"  (filteredReturn)="filteredListCourier =$event"></mat-select-filter>    
                                                          <mat-option *ngFor="let item of filteredListCourier" 
                                                          [value]="item._id" (click)="selectCourier(item.serviceProviderName);">
                                                         {{ item.serviceProviderName }}</mat-option>
                                                        </mat-select>
                                                      </mat-form-field>
                                                      <!-- <mat-error class="margin_tp"
                                                      *ngIf="inwardOutwardForm.controls['courierRefId'].touched && inwardOutwardForm.controls['courierRefId'].errors?.required">
                                                      This field is mandatory.
                                                    </mat-error> -->
                                                
                                                  </div>
                                                  <div class="col-sm-2 mTop no-print">
                                                    <i (click)="openCourierServicePopUp();" matTooltip="Add Courier Service" class="blue_col mTop fa-solid fa-circle-plus
                                                     pl-1 fa-lg"></i>
                                                  </div>  
                                                  </div>
                                                  </div>
                                                  <div class="col-sm-3">
                                                    <mat-form-field class="example-full-width " appearance="outline">
                                                        <mat-label>Tracking Number</mat-label>
                                                        <input maxlength="15" matInput placeholder="Add Tracking Number" 
                                                        formControlName="trackingNumber" >          
                                                    </mat-form-field>
                                                    <!-- <mat-error
                                                    *ngIf="inwardOutwardForm.controls['trackingNumber'].touched && inwardOutwardForm.controls['trackingNumber'].errors?.required">
                                                    This field is mandatory.
                                                  </mat-error>  -->
                                                </div>
                                              
                                              <div *ngIf="inward" class="col-sm-3">
                                                <mat-form-field class="example-full-width" appearance="outline">
                                                  <mat-label>Doc Date</mat-label>
                                                  <input formControlName="docDate" matInput [matDatepicker]="docDate" id="docDate" 
                                                  placeholder="DD/MM/YYYY"   >
                           
                                                  <mat-datepicker-toggle matSuffix [for]="docDate">
                                                  </mat-datepicker-toggle>
                                                  <mat-datepicker #docDate></mat-datepicker>
                                                
                                                </mat-form-field>
                                          
                                              </div>
                                                 
                                              
                                                  <div class="col-sm-3">
                                                    <div class="row">
                                                      <div class="col-sm-10 pr-1"> 
                                                    <mat-form-field class="example-full-width" appearance="outline">
                                                      <mat-label>Office 
                                                        <!-- <span class="required-asterisk">*</span> -->
                                                      </mat-label>
                                                      <mat-select required #select5 (click)="fetchBranchList();" formControlName="branchRefId" >
                                                        <mat-select-filter *ngIf="select5.focused" [placeholder]="'Filter'" [displayMember]="'branchName'"
                                                        [array]="BranchList"  (filteredReturn)="filteredListBranch =$event"></mat-select-filter>
                                                        <mat-option *ngFor="let item of filteredListBranch" [value]="item._id" 
                                                        (click)="branchClick(item._id,item.branchName);"
                                                        >
                                                         {{ item.branchName }}</mat-option>
                                                        </mat-select>
                                                       
                                                    </mat-form-field>
                                                    <mat-error class="margin_tp"
                                                        *ngIf="inwardOutwardForm.controls['branchRefId'].touched && inwardOutwardForm.controls['branchRefId'].errors?.required">
                                                        This field is mandatory.
                                                      </mat-error>
                                                      </div>
                                                      <div class="col-sm-2 mt-2 mTop no-print">
                                                        <i (click)="openBranchPopUp();" matTooltip="Add New Office" 
                                                        class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                                                           </div>
                                                     
                                                      </div>
                                                  </div>
                                                  <div class="col-sm-3"> 
                                                     
                                                    <mat-form-field class="example-full-width" appearance="outline">
                                                      <mat-label>Repair</mat-label>
                                                      <mat-select #select11 placeholder="Status" multiple class="colorblack"
                                                        formControlName="repaidId" [disabled]="whenUpdate">   
                                                        <mat-select-filter [disabled]="whenUpdate" *ngIf="select11.focused" [placeholder]="'Filter'"
                                                          [displayMember]="'firstName'" [array]="repairData"  
                                                          (filteredReturn)="filteredListRepair =$event"></mat-select-filter>
                                                        <mat-option *ngFor="let item of filteredListRepair" [disabled]="whenUpdate"
                                                          [value]="item._id" (onSelectionChange)="repairClick($event,item._id,item.branchWiseId,
                                                          item.firstName,item.lastName)"> 
                                                           Repair Id: {{item.branchWiseId}}, Patient: {{item.firstName}} {{item.lastName}}
                                                        </mat-option>
                                                      </mat-select>
                                        
                                                    </mat-form-field> 
                                              </div>
                                              <div class="col-sm-3"> 
                                                     
                                                <mat-form-field class="example-full-width" appearance="outline">
                                                  <mat-label>Ear Mould</mat-label>
                                                  <mat-select #select14 placeholder="Status" multiple class="colorblack"
                                                    formControlName="earMoldId" [disabled]="whenUpdate">   
                                                    <mat-select-filter [disabled]="whenUpdate" *ngIf="select14.focused" [placeholder]="'Filter'"
                                                      [displayMember]="'firstName'" [array]="earMouldData"  
                                                      (filteredReturn)="filteredListEarMould =$event"></mat-select-filter>
                                                    <mat-option *ngFor="let item of filteredListEarMould" [disabled]="whenUpdate"
                                                      [value]="item._id" (onSelectionChange)="earMouldClick($event,item._id,item.branchWiseId,
                                                      item.firstName,item.lastName)"> 
                                                      Ear Mould Id: {{item.branchWiseId}}, Patient: {{item.firstName}} {{item.lastName}}
                                                    </mat-option>
                                                  </mat-select>
                                    
                                                </mat-form-field> 
                                          </div>
                                          <div class="col-sm-3"> 
                                                     
                                            <mat-form-field class="example-full-width" appearance="outline">
                                              <mat-label>Custom HA</mat-label>
                                              <mat-select #select12 placeholder="Status" multiple class="colorblack"
                                                formControlName="customHAId" [disabled]="whenUpdate">   
                                                <mat-select-filter [disabled]="whenUpdate" *ngIf="select12.focused" [placeholder]="'Filter'"
                                                  [displayMember]="'firstName'" [array]="customHAData"  
                                                  (filteredReturn)="filteredListCustomHA =$event"></mat-select-filter>
                                                <mat-option *ngFor="let item of filteredListCustomHA" [disabled]="whenUpdate"
                                                  [value]="item._id" (onSelectionChange)="customHAClick($event,item._id,item.branchWiseId,
                                                  item.firstName,item.lastName)"> 
                                                  Custom HA Id: {{item.branchWiseId}}, Patient: {{item.firstName}} {{item.lastName}}
                                                </mat-option>
                                              </mat-select>
                                
                                            </mat-form-field> 
                                      </div>
                                      <div class="col-sm-3"> 
                                                     
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>Place Order</mat-label>
                                          <mat-select #select13 placeholder="Status" multiple class="colorblack"
                                            formControlName="placeOrederId" [disabled]="whenUpdate">   
                                            <mat-select-filter [disabled]="whenUpdate" *ngIf="select13.focused" [placeholder]="'Filter'"
                                              [displayMember]="'branchWiseId'" [array]="placeOrderData"  
                                              (filteredReturn)="filteredListPlaceOrder =$event"></mat-select-filter>
                                            <mat-option *ngFor="let item of filteredListPlaceOrder" [disabled]="whenUpdate"
                                              [value]="item._id" (onSelectionChange)="placeOrderClick($event,item._id,item.branchWiseId,
                                              item.firstName,item.lastName)"> 
                                              Place Order Id: {{item.branchWiseId}}
                                            </mat-option>
                                          </mat-select>
                            
                                        </mat-form-field> 
                                  </div>
                                                  <!-- <div *ngIf="!isDocuments && !isDevices" class="col-sm-3"></div>
                                                  <div *ngIf="!isDocuments && !isDevices" class="col-sm-1"></div> -->
                                                  <div class="col-sm-3">
                                                    <mat-form-field class="example-full-width" appearance="outline">
                                                      <mat-label *ngIf="inward">Items Received</mat-label>
                                                      <mat-label *ngIf="outward">Items Sent</mat-label>
                                                      
                                                      <textarea readonly maxlength="1500" matInput formControlName="notes"
                                                      cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                                      cdkAutosizeMinRows="3" cdkAutosizeMaxRows="8" ></textarea>
                                                    </mat-form-field>
                                                  </div>
                                                  <div class="col-sm-3">
                                                    <mat-form-field class="example-full-width" appearance="outline">
                                                      <mat-label>Remark</mat-label>
                                                      
                                                      <textarea maxlength="1500" matInput formControlName="remark"
                                                      cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                                      cdkAutosizeMinRows="3" cdkAutosizeMaxRows="8" ></textarea>
                                                    </mat-form-field>
                                                  </div>
                                               
                                      <div class="col-sm-2 offset-sm-10 mb-2 mt-2" *ngIf="submitButton && inwardOutwardCreate"> 
                                          <button class="btn btn-primary btn-sm example-full-width"  type="button" 
                                          (click)="submitInwordOutword()">Save </button>
  
                                      </div>
                                      <div class="col-sm-2 offset-sm-8 mb-2 mt-2" *ngIf="updateButton && inwardOutwardUpdate">
                                          <button (click)="UpdateInwordOutword();"  type="button" 
                                          class="btn btn-primary btn-sm example-full-width">Update </button>
                                      </div>
                                      <div class="col-sm-2 mb-2 mt-2 " *ngIf="updateButton">
                                          <button class="btn btn-primary btn-sm example-full-width" (click)="cancelUpdate()" type="reset" >Cancel </button>
                                         
                                      </div>
                                    
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-sm-12 pl-0 pr-2">
                  <div class="row">
                      <div class="card divbody borderradius p-3 pb-0">
                              <div class="col-sm-12">
                                  <div class="row">
                                      <div class="col-sm-9 ">
                                          <h1 class="titlecolor">Inward Outward List</h1>
                                      </div>
                                      <div class="search-box col-sm-3 pb-2">
                                        <input class="form-control amber-border" type="text" placeholder="Search" 
                                        formControlName="searchInwardOutward" aria-label="Search" (keyup)="onKeyUpEvent($event)"
                                        >
                                        <div class="input-group-append">
                                           <span class="input-group-text amber lighten-3" id="basic-text1"><span class="material-symbols-outlined">search</span></span>
                                        </div>
                                     </div>
                                  
                                      <div class="col-sm-12 table-responsive">
                                        <table id="toInwardOutwardLst" 
                                     class="table table-bordered data-table-custom">
                                              <thead>
                                                  <tr class="text-center">
                                                      <th> ID</th>
                                                    
                                                       <th>Date</th>
                                                       <th>To Client</th>
                                                       <th>From Manufacturer</th>
                                                      <th>Inward/Outward</th>
                                                      <th>Type</th>
                                                    <th>Courier Name</th>
                                                  </tr>
                                              </thead> 
                                              <tbody>
                                                  <tr *ngFor="let item of listOfInwardOutward | paginate : { id:'inwardOutwardMaster',
                                                  itemsPerPage: size, currentPage: page, totalItems:totalLength }" 
                                                  (click)="prepopulation(item._id)"
                                                   (click)="toInwardOutwardForm()" 
                                                   style="cursor:pointer; text-align: center ">
                                                   <td matTooltip="click to view">{{item.branchWiseId}}</td>
                                                   <td matTooltip="click to view" 
                                                   >{{item.date}}
                                                 </td>
                                          <td *ngIf="item.patientName !== ''" matTooltip="click to view">{{item.patientName}}</td>
                                          <td *ngIf="item.patientName == ''" matTooltip="click to view">-</td>
                                          <td *ngIf="item.companyName !== ''" matTooltip="click to view">{{item.companyName}}</td>
                                          <td *ngIf="item.companyName == ''" matTooltip="click to view">-</td>
                                                 <td matTooltip="click to view">{{item.inwardOutward}}</td>
                                                 <td matTooltip="click to view">{{item.typeName}}</td>
                                                      <td matTooltip="click to view">{{item.courierName}}</td>
                                                  
                                                     </tr>
                                              
  
                                              </tbody>
                                          </table>
                                      </div>
                                      <div class="col-sm-12 pt-0 text-right">
                                              <pagination-controls id="inwardOutwardMaster" directionLinks="true"
                                    (pageChange)="onPageChange($event)" class="float-right pagination my-pagination"></pagination-controls>
                                          
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
  
                  </div>
              
  
          </div>
      </div>
    </form>
  </div>