<div class="container-fluid bgcolor2" [formGroup]="patientMasterForm">
  <form [formGroup]="patientMasterForm" autocomplete="off" novalidate>
    <div class="row">
      <div class="col-sm-1"> 

      </div>
      <div class="col-sm-11">

        <div class="row pt-1">
          <div class="col-sm-12 pl-0 pr-4">
            <div class="row pl-0 ">
              <div class="col-12 pl-0 ">
                <div class="row ">
                  <div class="col-sm-2">

                    <h1 class="pt-2 pl-0" id="toPatientForm">Patient</h1>
                  </div>
                  <div class="col-sm-7 p-1 pb-2">
                    <div *ngIf="pName" class="card divbody borderradius ">
                      <div class="row ">
                        <div class="col-sm-9">
                          <h1 class="pt-2 pl-3">{{pName}}</h1>
                        </div>
                        <div *ngIf="!pDob" class="col-sm-1"></div>
                        <div class="col-sm-2 row justify-content-end">
                          <h1 class="pt-2  justify-content-end">({{pAge}}{{pGender}})</h1>
                        </div>
                        <div *ngIf="pDob" class="col-sm-1 mt-3 justify-content-end">
                          <span class="material-symbols-outlined imgSize "
                            matTooltip="Happy Birthday, Its your birthday">
                            cake
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-3 mt-1">
                    <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                      <a (click)="onback()" class="d-flex align-items-center pr-2 mt-2"><span
                          class="material-symbols-outlined pr-2">arrow_back</span>Back</a>
                      <a matTooltip="Communication" *ngIf="isDecease" (click)="openCommunicationPopUp(pid)"
                        class="d-flex align-items-center ms-3 pr-2 mt-2"><span
                          class="material-symbols-outlined pr-2">chat</span>Communication</a>
                      <a (click)="controlClick()" class="d-flex align-items-center ms-3 pr-2 mt-2"><span
                          class="material-symbols-outlined pr-2">settings</span>Control Panel</a>
                      <a (click)="toPatientList()" class="d-flex align-items-center ms-3 pr-2 mt-2"><span
                          class="material-symbols-outlined pr-2">reorder</span>List</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="row">
            <div class="col-sm-9">
              <div class="row ">
               
                <span><img src="../../assets/patient.png" width="35rem"> </span> 
                <span><h1 class="fass ml-3 pt-1" id="toPatientForm">Patient</h1></span>
              </div>
                </div> 
               
                <div class="col-sm-3" >
                 
                  <div class="row justify-content-end ">
                    <img matTooltip="Back" class="mb-2 pb-2 mr-2" width=35rem src="../../assets/back.png" (click)="onback()">
                    <img matTooltip="Communication" *ngIf="isDecease" (click)="openCommunicationPopUp(pid)" 
                    class="mb-2 pb-2 mr-2" width="35rem" src="../../assets/speech-bubble.png">  
                    <img matTooltip="Control Panel" (click)="controlClick()" class="mb-2 pb-2 mr-2" width="35rem" [routerLink]="['/home/generic-setting']"  src="../../assets/general settings.png">  
                  <img src="../../assets/list.png"  (click)="toPatientList()" class="mb-2 pb-2" matTooltip="List" width="35rem">
                 </div>
                 </div>
                
              
          </div> -->

            <div class="row margingTop mt-1">
              <div class="card divbody borderradius pr-3 p-1 ">
                <div class="row">

                  <!-- <h1 class="titlecolor"> Patient Master</h1> -->
                  <div class="col-sm-2">
                    <!-- <h1 class="titlecolor"> Patient Master</h1> -->
                    <div class="col-sm-12 bg-secondary rounded-3 p-1 pb-4 mb-4">


                      <div class="col-sm-12 ">
                        <img *ngIf="!patientImage" class=" img2 frame  " src="../../assets/user2.png"
                          alt="Amanda Wilson" width="500rem"><!--rounded-circle-->
                        <img *ngIf="patientImage" class=" img2 frame  " [src]="patientImage" alt="patient photo"
                          width="500rem"><!--rounded-circle-->
                        <!-- <button class="btn btn-light shadow btn-sm mb-2" type="button"><i class="ai-refresh-cw me-2"></i>Change avatar</button> -->

                      </div>
                      <div class="col-sm-12 btnpossition ">
                        <input #fileUpload type="file" style="display: none;" (change)="onChange($event)" />
                        <button type="button" class="btn btn-light shadow btn-sm m-0 example-full-width "
                          matTooltip="Upload JPG, GIF or PNG image.300 x 300 required." (click)="fileUpload.click()"><i
                            class="ai-refresh-cw me-2"></i>Upload Photo</button>
                      </div>
                      
                    </div>
                  </div>

                  <div class="col-sm-10">
                    <div class="row pt-1 pr-1">
                      <div class=" col-sm-3 ">
                        <div class="row">
                          <div class=" col-sm-4 mt-3">
                            <!-- <mat-form-field *ngIf="userType !== 'Super Admin'" class="example-full-width" appearance="outline">
                            <mat-label>Patient Id</mat-label>
                            <input matInput formControlName="branchWiseId" readonly  />   
                          </mat-form-field> -->
                            <!-- <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Patient Id</mat-label>
                            <input matInput [value]="superBranchId" readonly  />
                           
                          </mat-form-field> -->
                            <mat-label class="center" [value]="superBranchId"
                              ngDefaultControl>{{superBranchId}}</mat-label>
                          </div>
                          <div class=" col-sm-8 ">
                            <mat-form-field class="example-full-width " appearance="outline">
                              <mat-label>PreFix
                                <!-- <span class="required-asterisk">*</span> -->
                              </mat-label>
                              <mat-select #select104 placeholder="PreFix" class="colorblack" formControlName="prefix">
                                <mat-select-filter *ngIf="select104.focused" [placeholder]="'Filter'"
                                  [displayMember]="'name'" [array]="allPreFix"
                                  (filteredReturn)="filteredListPreFix =$event"></mat-select-filter>
                                <mat-option *ngFor="let item of filteredListPreFix" [value]="item.value">
                                  {{item.name}}
                                </mat-option>
                              </mat-select>


                            </mat-form-field>
                            <!-- <mat-error 
                          *ngIf="patientMasterForm.controls['prefix'].touched && patientMasterForm.controls['prefix'].errors?.required">
                          mandatory.
                        </mat-error> -->
                          </div>
                        </div>
                      </div>
                      <!-- <div class=" col-sm-1 ml-2 mr-2 pr-0 pl-0 ">
                          <mat-form-field class="example-full-width " appearance="outline">
                            <mat-label>PreFix</mat-label>
                            <mat-select required placeholder="PreFix" class="colorblack"  formControlName="prefix"   >
                              <mat-select-filter [placeholder]="'Filter'" [displayMember]="'name'"
                                [array]="allPreFix" (filteredReturn)="filteredListPreFix =$event"></mat-select-filter>
                              <mat-option *ngFor="let item of filteredListPreFix" 
                               [value]="item.value">
                                {{item.name}}
                              </mat-option>
                            </mat-select>
                          
                            <mat-error
                                    *ngIf="patientMasterForm.controls['prefix'].touched && patientMasterForm.controls['prefix'].errors?.required">
                                    This field is mandatory.
                                  </mat-error>
                          </mat-form-field>
                        </div> -->

                      <div class="col-sm-3">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>First Name
                            <!-- <span class="required-asterisk">*</span> -->
                          </mat-label>
                          <input matInput placeholder="First Name" (input)="openInquryPatientDuplicatePopUp();"
                            formControlName="firstName" required />
                        </mat-form-field>
                        <mat-error
                          *ngIf="patientMasterForm.controls['firstName'].touched && patientMasterForm.controls['firstName'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                        <mat-error class="margin_tp"
                          *ngIf="patientMasterForm.controls['firstName'].touched && patientMasterForm.controls['firstName'].errors?.pattern">
                          Please enter valid first name.
                        </mat-error>
                      </div>
                      <div class=" col-sm-3   ">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Middle Name</mat-label>
                          <input required matInput placeholder="Middle Name"
                            (input)="openInquryPatientDuplicatePopUp();" formControlName="middleName" />

                        </mat-form-field>
                        <mat-error class="margin_tp"
                          *ngIf="patientMasterForm.controls['middleName'].touched && patientMasterForm.controls['middleName'].errors?.pattern">
                          Please enter valid middle name.
                        </mat-error>
                        <mat-error
                          *ngIf="patientMasterForm.controls['middleName'].touched && patientMasterForm.controls['middleName'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                      </div>
                      <div class="col-sm-3">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Last Name
                            <!-- <span class="required-asterisk">*</span> -->
                          </mat-label>
                          <input matInput placeholder="Last Name" (input)="openInquryPatientDuplicatePopUp();"
                            formControlName="lastName" required />

                        </mat-form-field>
                        <mat-error
                          *ngIf="patientMasterForm.controls['lastName'].touched && patientMasterForm.controls['lastName'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                        <mat-error class="margin_tp"
                          *ngIf="patientMasterForm.controls['lastName'].touched && patientMasterForm.controls['lastName'].errors?.pattern">
                          Please enter valid last name.
                        </mat-error>
                      </div>
                      <div *ngIf="fieldsOfPatients.patientAlternateName !== 'No'" class="col-sm-3">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Alternate Name </mat-label>
                          <input matInput placeholder="Alternate Name" formControlName="alternateName" />

                        </mat-form-field>
                        <mat-error class="margin_tp"
                          *ngIf="patientMasterForm.controls['alternateName'].touched && patientMasterForm.controls['alternateName'].errors?.pattern">
                          Please enter valid Alternate name.
                        </mat-error>
                      </div> 
                      <div *ngIf="fieldsOfPatients.patientDOB !== 'No'" class="col-sm-3">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>DOB
                            <!-- <span class="required-asterisk">*</span> -->
                          </mat-label>
                          <input formControlName="dob" matInput [matDatepicker]="dob" id="dob" [min]="minDate"
                            [max]="currentdate" placeholder="DD/MM/YYYY" (dateChange)="ageCalculate()">
                          <mat-datepicker-toggle matSuffix [for]="dob">
                          </mat-datepicker-toggle>
                          <mat-datepicker #dob></mat-datepicker>
 
                        </mat-form-field>
                        <!-- <mat-error 
                          *ngIf="patientMasterForm.controls['dob'].errors?.pattern">
                         Enter Valid Date in DD/MM/YYYY format.
                        </mat-error> -->
                        <mat-error class="margin_tp"
                          *ngIf="patientMasterForm.controls['dob'].touched && patientMasterForm.controls['dob'].errors?.pattern">
                          Please Enter Valid Date in DD/MM/YYYY format.
                        </mat-error>
                      </div>

                      <div class="col-sm-3">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Age
                            <!-- <span class="required-asterisk">*</span> -->
                          </mat-label>
                          <input matInput placeholder="Age" formControlName="age" />

                        </mat-form-field>
                        <mat-error
                          *ngIf="patientMasterForm.controls['age'].touched && patientMasterForm.controls['age'].errors?.pattern">
                          Enter Valid Age.
                        </mat-error>
                        <!-- <mat-error
                        *ngIf="patientMasterForm.controls['age'].touched && patientMasterForm.controls['age'].errors?.maxlength">
                        Age Is Not Valid So Please Enter Valid Birth Date in DD/MM/YYYY format.
                      </mat-error> -->
                      </div>
                      <div *ngIf="fieldsOfPatients.patientClientSource !== 'No' " class="col-sm-3">
                        <div class="row">
                          <div class="col-sm-10 pr-1">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Patient Source </mat-label>
                              <mat-select #select1 placeholder="Patient Source" class="colorblack"
                                formControlName="clientSource">
                                <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'"
                                  [displayMember]="'patientSource'" [array]="allPatientSource"
                                  (filteredReturn)="filteredListPatientSource =$event"></mat-select-filter>
                                <mat-option (click)="patientClick()" *ngFor="let item of filteredListPatientSource"
                                  [value]="item.patientSource">
                                  {{item.patientSource}}
                                </mat-option>
                              </mat-select>
                
                            </mat-form-field>
                          </div>
                          <div class="col-sm-2 mt-2">
                            <i (click)="openPatientSourcePopUp();" matTooltip="Add New Patient Source"
                              class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"> </i>
                          </div>

                        </div>
                      </div>

                      <div *ngIf="isPatient" class="col-sm-3">
                        <div class="row">
                          <div class="col-sm-10 pr-1"> 
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Patient Name </mat-label>
                              <input (click)="openDepartmentPopUp();" matInput readonly placeholder="Patient Name"
                                formControlName="patientName" />
                              <!-- <mat-select  #select1 (click)="fetchAllPatientList();" formControlName="patientdropDown" >
                              <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'firstName'"
                              [array]="patientList"  (filteredReturn)="filteredListPatient =$event"></mat-select-filter>
                            <mat-option *ngFor="let item of filteredListPatient" (click)="patientNameClick(item.firstName,item.lastName)" [value]="item._id"
                            >
                             {{ item.firstName }}  {{ item.lastName }}</mat-option>
                            </mat-select> -->
                            </mat-form-field>
                          </div>
                          <!-- <div class="col-sm-2 mt-2">
                            <img (click)="openPatientPopUp();" matTooltip="Add New Patient" width="25" src="../../assets/add_img.png"> 
                          </div>  -->
                          <div class="col-sm-2 mt-2">
                            <i (click)="openPatientPopUp();" matTooltip="Add New Patient"
                              class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"> </i>
                          </div>

                        </div>
                      </div>
                      <div *ngIf="isDoctor" class="col-sm-3">

                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Doctor Name </mat-label>
                          <input matInput placeholder="Doctor Name" formControlName="doctorDropDown" />
                        </mat-form-field>
                      </div>

                      <div *ngIf="isAdvertiesment" class="col-sm-3">

                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label> Advertisement </mat-label>
                          <input matInput placeholder="advertisement" formControlName="advertisement" />
                        </mat-form-field>
                      </div>
                      <div *ngIf="isSocialMedia" class="col-sm-3">

                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label> Social Media </mat-label>
                          <input matInput placeholder="Social Media" formControlName="socialMedia" />
                        </mat-form-field>
                      </div>
                      <div *ngIf="isOther" class="col-sm-3">

                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label> Other </mat-label>
                          <input matInput placeholder="" formControlName="socialMedia" />
                        </mat-form-field>
                      </div>

                      <div class="col-sm-5">
                        <div class="row">
                          <div class="col-sm-4 mt-2">
                            <mat-label class="mt-1 lclass"><span class="required-asterisk mr-1">*</span>Gender
                              :</mat-label>
                          </div>
                          <div class="col-sm-8">
                            <mat-radio-group formControlName="sex" required class="example-full-width"
                              aria-label="Select an option">
                              <div class="row">
                                <div class="col-sm-4 p-1 mt-2">
                                  <mat-radio-button value="Male">Male</mat-radio-button>
                                </div>
                                <div class="col-sm-4 p-1 mt-2">
                                  <mat-radio-button value="Female">Female</mat-radio-button>
                                </div>
                                <div class="col-sm-4 p-1 mt-2">
                                  <mat-radio-button value="Other">Other</mat-radio-button>
                                </div>
                              </div>
                            </mat-radio-group>

                            <mat-error
                              *ngIf="patientMasterForm.controls['sex'].touched && patientMasterForm.controls['sex'].errors?.required">
                              This field is mandatory.
                            </mat-error>
                          </div>
                        </div>
                      </div>

                      <div class="col-sm-12  ">
                        <h5 class="pb-0 mb-0">Contact Details :</h5>
                      </div>

                      <div *ngIf="fieldsOfPatients.patientLandline1 !== 'No'" class="col-sm-3">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Landline 1</mat-label>
                          <input maxlength="15" matInput placeholder=" Landline eg 0223-666666"
                            formControlName="landline1" />

                        </mat-form-field>
                        <mat-error class="margin_tp"
                          *ngIf="patientMasterForm.controls['landline1'].touched && patientMasterForm.controls['landline1'].errors?.pattern">
                          Please enter valid landline number.
                        </mat-error>
                      </div>
                      <div *ngIf="fieldsOfPatients.patientLandline2 !== 'No'" class="col-sm-3">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Landline 2</mat-label>
                          <input maxlength="15" matInput placeholder=" Landline eg 0223-666666"
                            formControlName="landline2" />

                        </mat-form-field>
                        <mat-error class="margin_tp"
                          *ngIf="patientMasterForm.controls['landline2'].touched && patientMasterForm.controls['landline2'].errors?.pattern">
                          Please enter valid landline number.
                        </mat-error>
                      </div>
                      <div class="col-sm-3" *ngIf="fieldsOfPatients.patientMobile1 !== 'No'">
                        <div class="row">
                          <div class="col-sm-10">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label> Mobile 1
                                <!-- <span class="required-asterisk">*</span> -->
                              </mat-label>
                              <input matInput maxlength="10" (change)="changemob1()" placeholder="Mobile 1"
                                formControlName="mobile1" />

                            </mat-form-field>
                            <mat-error class="margin_tp"
                              *ngIf="patientMasterForm.controls['mobile1'].touched && patientMasterForm.controls['mobile1'].errors?.pattern">
                              Please enter valid mobile number.
                            </mat-error>
                            <!-- <mat-error
                                *ngIf="patientMasterForm.controls['mobile1'].touched && patientMasterForm.controls['mobile1'].errors?.required">
                                This field is mandatory.
                              </mat-error>  -->
                          </div>
                          <div class="col-sm-2 wApp">
                            <i class="bi bi-whatsapp whatsapp-icon wApp fa-lg" (click)="WhatsApp();"
                              matTooltip="WhatsApp Integration"></i>
                          </div>
                          <!-- <div class="col-sm-2 ml-0 pl-0 mt-1">
                                  <img (click)="WhatsApp();" class="ml-0 pl-0 mt-2" matTooltip="WhatsApp Integration" 
                                  width="25" src="../../assets/whatsappGreen.png"> 
                                  </div> -->
                        </div>
                      </div>

                      <div *ngIf="fieldsOfPatients.patientMobile2 !== 'No'" class="col-sm-3">
                        <div class="row">
                          <div class="col-sm-10">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Mobile 2</mat-label>
                              <input matInput maxlength="10" (change)="changemob2()" placeholder="Mobile 2 "
                                formControlName="mobile2" />

                            </mat-form-field>
                            <mat-error class="margin_tp"
                              *ngIf="patientMasterForm.controls['mobile2'].touched && patientMasterForm.controls['mobile2'].errors?.pattern">
                              Please enter valid mobile number.
                            </mat-error>
                          </div>
                          <div class="col-sm-2 wApp">
                            <i class="bi bi-whatsapp whatsapp-icon wApp fa-lg" (click)="WhatsApp2();"
                              matTooltip="WhatsApp Integration"></i>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="fieldsOfPatients.patientMobile3 !== 'No'" class="col-sm-3">
                        <div class="row">
                          <div class="col-sm-10">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Mobile 3 </mat-label>
                              <input matInput maxlength="10" (change)="changemob3()" placeholder="Mobile 3"
                                formControlName="mobile3" />

                            </mat-form-field>
                            <mat-error class="margin_tp"
                              *ngIf="patientMasterForm.controls['mobile3'].touched && patientMasterForm.controls['mobile3'].errors?.pattern">
                              Please enter valid mobile number.
                            </mat-error>

                          </div>
                          <div class="col-sm-2 wApp">
                            <i class="bi bi-whatsapp whatsapp-icon wApp fa-lg" (click)="WhatsApp3();"
                              matTooltip="WhatsApp Integration"></i>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="fieldsOfPatients.patientMobile4 !== 'No'" class="col-sm-3">
                        <div class="row">
                          <div class="col-sm-10">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Mobile 4</mat-label>
                              <input matInput maxlength="10" (change)="changemob4()" placeholder="Mobile 4"
                                formControlName="mobile4" />

                            </mat-form-field>
                            <mat-error class="margin_tp"
                              *ngIf="patientMasterForm.controls['mobile4'].touched && patientMasterForm.controls['mobile4'].errors?.pattern">
                              Please enter valid mobile number.
                            </mat-error>
                          </div>
                          <div class="col-sm-2 wApp">
                            <i class="bi bi-whatsapp whatsapp-icon wApp fa-lg" (click)="WhatsApp4();"
                              matTooltip="WhatsApp Integration"></i>
                          </div>
                        </div>
                      </div>


                      <div *ngIf="fieldsOfPatients.patientMobile5 !== 'No'" class="col-sm-3">
                        <div class="row">
                          <div class="col-sm-10">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Mobile 5 </mat-label>
                              <input matInput maxlength="10" (change)="changemob5()" placeholder="Mobile 5"
                                formControlName="mobile5" />


                            </mat-form-field>
                            <mat-error class="margin_tp"
                              *ngIf="patientMasterForm.controls['mobile5'].touched && patientMasterForm.controls['mobile5'].errors?.pattern">
                              Please enter valid mobile number.
                            </mat-error>
                          </div>
                          <div class="col-sm-2 wApp">
                            <i class="bi bi-whatsapp whatsapp-icon wApp fa-lg" (click)="WhatsApp5();"
                              matTooltip="WhatsApp Integration"></i>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="fieldsOfPatients.patientEmail !== 'No'" class="col-sm-3">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label> Email Address</mat-label>
                          <input matInput class="emailClass" type="email" placeholder=" Email"
                            formControlName="email" />

                        </mat-form-field>
                        <!-- <mat-error class="margin_tp"
                                  *ngIf="patientMasterForm.controls['email'].touched && patientMasterForm.controls['email'].errors?.email">
                                      Please enter valid email.
                                </mat-error> -->
                        <mat-error class="margin_tp"
                          *ngIf="patientMasterForm.controls['email'].touched && patientMasterForm.controls['email'].errors?.pattern">
                          Please enter valid email.
                        </mat-error>
                      </div>


                    </div>
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="row ">

                    <div class="col-sm-12">

                      <div class="row">
                        <div class="col-sm-12  ">
                          <h5 class="pb-0 mb-0">Address Details :</h5>
                        </div>
                      </div>

                      <div class="row">
                        <div class=" col-sm-2 " *ngIf="fieldsOfPatients.patientStreetAddress !== 'No'">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Street Address
                              <!-- <span class="required-asterisk">*</span> -->
                            </mat-label>
                            <textarea maxlength="1000" matInput formControlName="streetAddress" cdkTextareaAutosize
                              #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea>

                            <!-- <input matInput placeholder="Address" formControlName="streetAddress"/> -->

                          </mat-form-field>

                        </div>
                        <div *ngIf="fieldsOfPatients.patientTaluka !== 'No'" class=" col-sm-2 ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Taluka</mat-label>
                            <input matInput placeholder="Taluka" formControlName="taluka" />

                          </mat-form-field>
                          <mat-error class="margin_tp"
                            *ngIf="patientMasterForm.controls['taluka'].touched && patientMasterForm.controls['taluka'].errors?.pattern">
                            Please enter valid taluka.
                          </mat-error>
                        </div>
                        <div *ngIf="fieldsOfPatients.patientDistrict !== 'No'" class=" col-sm-2  ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>District</mat-label>
                            <!-- <input matInput placeholder="District" formControlName="district" /> -->
                            <mat-select #select1 placeholder="District" class="colorblack" formControlName="district">
                              <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'"
                                [displayMember]="'district'" [array]="districtData"
                                (filteredReturn)="filteredListDistrict =$event"></mat-select-filter>
                              <mat-option *ngFor="let item of filteredListDistrict" [value]="item.district">
                                {{item.district}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>

                        </div>
                        <div *ngIf="fieldsOfPatients.patientState !== 'No'" class=" col-sm-2  ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>State</mat-label>
                            <mat-select #select1 placeholder="State" class="colorblack" formControlName="state">
                              <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'"
                                [displayMember]="'state'" [array]="stateCityArr"
                                (filteredReturn)="filteredList =$event"></mat-select-filter>
                              <mat-option *ngFor="let item of filteredList" [value]="item.state"
                                (click)="getDistrict(item.districts)">
                                {{item.state}}
                              </mat-option>
                            </mat-select>

                          </mat-form-field>

                        </div>
                        <div class="col-sm-2" *ngIf="fieldsOfPatients.patientPincode !== 'No'">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Pincode
                              <!-- <span class="required-asterisk">*</span> -->
                            </mat-label>
                            <input maxLength="6" matInput placeholder="Pincode(e.g:416008)" formControlName="pincode" />


                          </mat-form-field>
                          <mat-error class="margin_tp"
                            *ngIf="patientMasterForm.controls['pincode'].touched && patientMasterForm.controls['pincode'].errors?.pattern">
                            Enter only number.
                          </mat-error>
                          <mat-error class="margin_tp"
                            *ngIf="patientMasterForm.controls['pincode'].touched && patientMasterForm.controls['pincode'].errors?.maxlength">
                            Enter 6 digit Pincode.
                          </mat-error>
                          <mat-error class="margin_tp"
                            *ngIf="patientMasterForm.controls['pincode'].touched && patientMasterForm.controls['pincode'].errors?.minlength">
                            Enter 6 digit Pincode.
                          </mat-error>


                          <!-- <mat-error class="margin_tp"
                                  *ngIf="patientMasterForm.controls['pincode'].touched && patientMasterForm.controls['pincode'].errors?.required">
                                  This field is mandatory.
                                </mat-error> -->
                        </div>
                        <div *ngIf="fieldsOfPatients.patientCountry !== 'No'" class=" col-sm-2  ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Country</mat-label>
                            <input matInput placeholder="Country" formControlName="country" />

                          </mat-form-field>
                          <mat-error class="margin_tp"
                            *ngIf="patientMasterForm.controls['country'].touched && patientMasterForm.controls['country'].errors?.pattern">
                            Please enter valid country.
                          </mat-error>
                        </div>






                        <!-- <div class="col-sm-6"></div> -->
                        <div *ngIf="fieldsOfPatients.patientAltAddress !== 'No'" class=" col-sm-4 mt-2">

                          <mat-checkbox class="example-margin header" formControlName="altAddress" [checked]="isChecked"
                            (change)="onChangeAltAddress($event)"> Alternate Address
                          </mat-checkbox>
                        </div>

                      </div>



                      <div *ngIf="altAddressCheck" class="row">
                        <div class="col-sm-12 mt-1  ">
                          <h5 class="pb-0 mb-0">Alternate Address Details :</h5>
                        </div>
                      </div>
                      <div class="row">
                        <div *ngIf="altAddressCheck" class=" col-sm-2  ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Street Address</mat-label>
                            <textarea maxlength="1000" matInput formControlName="streetAddress2" cdkTextareaAutosize
                              #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea>
                            <!-- <input matInput placeholder="Address" formControlName="streetAddress2" /> -->

                          </mat-form-field>
                        </div>
                        <div *ngIf="altAddressCheck" class=" col-sm-2  ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Taluka</mat-label>
                            <input matInput placeholder="Taluka" formControlName="taluka2" />

                          </mat-form-field>
                          <mat-error class="margin_tp"
                            *ngIf="patientMasterForm.controls['taluka2'].touched && patientMasterForm.controls['taluka2'].errors?.pattern">
                            Please enter valid taluka.
                          </mat-error>
                        </div>
                        <div *ngIf="altAddressCheck" class=" col-sm-2  ">
                        
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>District</mat-label>
                            <!-- <input matInput placeholder="District" formControlName="district" /> -->
                            <mat-select #select101 placeholder="District" class="colorblack"
                              formControlName="district2">
                              <mat-select-filter *ngIf="select101.focused" [placeholder]="'Filter'"
                                [displayMember]="'district'" [array]="districtData2"
                                (filteredReturn)="filteredListDistrict2 =$event"></mat-select-filter>
                              <mat-option *ngFor="let item of filteredListDistrict2" [value]="item.district">
                                {{item.district}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div *ngIf="altAddressCheck" class=" col-sm-2  ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>State</mat-label>
                            <mat-select #select1 placeholder="State" class="colorblack" formControlName="state2">
                              <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'"
                                [displayMember]="'state'" [array]="stateCityArr2"
                                (filteredReturn)="filteredList2 =$event"></mat-select-filter>
                              <mat-option *ngFor="let item of filteredList2" [value]="item.state"
                                (click)="getDistrict2(item.districts)">
                                {{item.state}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div *ngIf="altAddressCheck" class=" col-sm-2  ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Pincode

                            </mat-label>
                            <input matInput maxlength="6" placeholder="Pincode(e.g:416008)"
                              formControlName="pincode2" />


                          </mat-form-field>
                          <mat-error class="margin_tp"
                            *ngIf="patientMasterForm.controls['pincode2'].touched && patientMasterForm.controls['pincode2'].errors?.pattern">
                            Enter only number.
                          </mat-error>
                          <mat-error class="margin_tp"
                            *ngIf="patientMasterForm.controls['pincode2'].touched && patientMasterForm.controls['pincode2'].errors?.maxlength">
                            Enter 6 digit Pincode.
                          </mat-error>
                          <mat-error class="margin_tp"
                            *ngIf="patientMasterForm.controls['pincode2'].touched && patientMasterForm.controls['pincode2'].errors?.minlength">
                            Enter 6 digit Pincode.
                          </mat-error>
                          <mat-error class="margin_tp"
                            *ngIf="patientMasterForm.controls['pincode2'].touched && patientMasterForm.controls['pincode2'].errors?.pattern">
                            Please enter valid pincode.
                          </mat-error>
                        </div>
                        <div *ngIf="altAddressCheck" class=" col-sm-2  ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Country</mat-label>
                            <input matInput placeholder="Country" formControlName="country2" />

                          </mat-form-field>
                          <mat-error class="margin_tp"
                            *ngIf="patientMasterForm.controls['country2'].touched && patientMasterForm.controls['country2'].errors?.pattern">
                            Please enter valid country.
                          </mat-error>
                        </div>
                     
                      </div>
                    </div>

                    <div class="col-sm-12">

                      <div class="row">
                        <div class="col-sm-12  ">
                          <h5 class="pb-0 mt-2 mb-0">Other Details :</h5>
                        </div>
                      </div>
                      <div class="row">

                        <div *ngIf="fieldsOfPatients.patientOccupation !== 'No'" class=" col-sm-2  ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Occupation</mat-label>
                            <mat-select #select102 placeholder="Occupation" class="colorblack"
                              formControlName="occupation">
                              <mat-select-filter *ngIf="select102.focused" [placeholder]="'Filter'"
                                [displayMember]="'name'" [array]="allOccupation"
                                (filteredReturn)="filteredListOccupation =$event"></mat-select-filter>
                              <mat-option *ngFor="let item of filteredListOccupation" [value]="item.value"
                                (click)="occupationClick()">
                                {{item.name}}
                              </mat-option>
                            </mat-select>

                          </mat-form-field>

                        </div>
                        <div *ngIf="otherOccupation" class=" col-sm-4  ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Other Occupation</mat-label>
                            <input matInput placeholder="Other" formControlName="otherOccupation" />

                          </mat-form-field>
                          <mat-error class="margin_tp"
                            *ngIf="patientMasterForm.controls['otherOccupation'].touched && patientMasterForm.controls['otherOccupation'].errors?.pattern">
                            Please enter valid occupation.
                          </mat-error>
                        </div>
                        <div *ngIf="fieldsOfPatients.patientAadhaarNo !== 'No'" class=" col-sm-2  ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Aadhaar Card Number</mat-label>
                            <input matInput placeholder="e.g(9999-9999-9999)" formControlName="aadhaarNo" />
                          </mat-form-field>
                          <mat-error class="margin_tp"
                            *ngIf="patientMasterForm.controls['aadhaarNo'].touched && patientMasterForm.controls['aadhaarNo'].errors?.pattern">
                            Please enter valid Aadhaar card number.
                          </mat-error>
                        </div>
                        <div *ngIf="isDecease" class="col-sm-2">
                          <mat-form-field class="example-full-width " appearance="outline">
                            <mat-label>Decease</mat-label>
                            <mat-select formControlName="decease">
                              <mat-option (click)="deceaseClick();" value="Yes">Yes</mat-option>
                              <mat-option (click)="deceaseClick();" value="No">No</mat-option>
                            </mat-select>
                          </mat-form-field>

                        </div>
                        <div class=" col-sm-2  ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Patient Status</mat-label>
                            <mat-select formControlName="clientStatus">
                              <mat-option (click)="patientStatus()" value="Active">Active</mat-option>
                              <mat-option value="In-active">In-active</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>

                        <div *ngIf="fieldsOfPatients.patientNotes !== 'No'" class="col-sm-6  ">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Notes</mat-label>
                            <textarea maxlength="1000" matInput formControlName="notes" cdkTextareaAutosize
                              #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5"></textarea>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>

                    <!-- <div *ngIf="isDecease" class="col-sm-12"> 
                            
                            <div class="row">
                              <div class="col-sm-2">
                            <label class="header mt-3">Is Decease :</label>
                              </div>
                              <div class="col-sm-2">
                                <mat-form-field class="example-full-width " appearance="outline">
                                  <mat-label>Decease</mat-label>
                                <mat-select formControlName="decease">
                                  <mat-option value="Yes">Yes</mat-option>
                                  <mat-option value="No">No</mat-option>
                               
                                </mat-select>
                                </mat-form-field>
                                
                              </div>
                              </div>
                             
                     </div> -->
                  </div>

                  <div class="row mb-2">
                    <div class="col-sm-10" *ngIf="submitButton"></div>
                    <div class="col-sm-8" *ngIf="updateButton"></div>


                    <div class="col-sm-2 mt-2" *ngIf="submitButton && patientCreate">
                      <button type="button" class="btn btn-sm btn-primary example-full-width"
                        (click)="submitPatient()">Save </button>
                    </div>

                    <div class="col-sm-2 mt-2" *ngIf="updateButton">
                      <button type="button" *ngIf="patientUpdate" class="btn btn-sm btn-primary example-full-width"
                        (click)="updatePatientDetails()">Update </button>
                    </div>
                    <div class="col-sm-2 mt-2" *ngIf="updateButton">
                      <button class="btn btn-primary btn-sm  example-full-width" type="reset" (click)="cancelUpdate()"
                        type="reset">Cancel </button>
                    </div>


                  </div>



                  <!-- <div class="col-sm-3" *ngIf="submitButton"></div>
                          <div class="col-sm-2" ></div>
                          <div class="col-sm-2 mt-3">
                            
                            
                          </div>
                          <div class="col-sm-2 mt-3" >
                            
                         </div> -->



                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row pt-3 ">
          <div class="col-sm-12 pl-0 pr-3 ">
            <div class="row">
              <div class="card col-sm-12 borderradius p-3 mb-2">
                <div class="col-sm-12">
                  <div class="row">
                    <!-- <div class="col-sm-1"></div> -->
                    <div class="col-sm-3  ">
                      <h1 class="titlecolor"> List Of Patients</h1>
                    </div>
                    <div class="col-sm-5"></div>
                    <div class="col-sm-1 mt-1">
                      <button class="btn btn-primary btn-sm example-full-width" (click)="export()"
                        type="button">Export</button>
                    </div>
                    <div class="search-box col-sm-3 pb-2">
                      <input class="form-control amber-border" type="text" placeholder="Search"
                        formControlName="searchPatient" aria-label="Search" (keyup)="onKeyUpEvent($event)"
                        onkeydown="return (event.keyCode!=13);">
                      <div class="input-group-append">
                        <span class="input-group-text amber lighten-3" id="basic-text1"><span
                            class="material-symbols-outlined">search</span></span>
                      </div>
                    </div>

                    <div class="col-sm-12 table-responsive">
                      <table id="toPatientList" class="table table-bordered data-table-custom">
                        <thead>
                          <tr style="text-align: center;">
                            <!-- <th>ID</th> -->
                            <th>Patient ID</th>
                            <!-- <th *ngIf="userType == 'Super Admin'">Branch Name</th> -->
                            <th>First Name</th>
                            <th>Last Name</th>
                            <!-- <th>Gender</th> -->
                            <th>Age</th>
                            <th>Mobile Number</th>
                            <th>Street Address</th>
                            <th>District</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor=" 
                                              let item of listOfPatients
                                              | paginate : { id:'patient_pagination', 
                                              itemsPerPage: size, currentPage: page, totalItems:totalLength }
                                            " (click)="prepopulation(item._id)" (click)="toPatientForm()"
                            style="cursor:pointer; text-align: center ">

                            <!-- <td  matTooltip="click to view"
                                     >{{item.branchWiseId}}</td> -->
                            <td matTooltip="click to view">{{item.patientId}}</td>
                            <!-- <td *ngIf="userType == 'Super Admin'" matTooltip="click to view" 
                                     >{{item.branchName}}</td> -->
                            <td matTooltip="click to view">{{item.firstName}}</td>
                            <td matTooltip="click to view">{{item.lastName}}</td>
                            <!-- <td matTooltip="click to view">{{item.sex}}</td> -->
                            <td matTooltip="click to view">{{item.age}}</td>
                            <td matTooltip="click to view">{{item.mobile1}}</td>
                            <td matTooltip="click to view">{{item.streetAddress}}</td>
                            <td matTooltip="click to view">{{item.district}}</td>
                            <!-- <td>{{item.email}}</td> -->
                            <!-- <td class="text-center">
                                      <i matTooltip="View" (click)="prepopulation(item._id)" class="fas fa-eye"></i>
                                     </td> -->

                          </tr>
                          <!-- <tr>
                                                <td>July</td>
                                                <td>Dooley</td>
                                                <td>july@example.com</td>
                                                <td><button class="btn btn-sm btn-primary-outline">View</button></td>
                                              </tr> -->

                        </tbody>
                      </table>
                    </div>
                    <div class="col-sm-12 pt-0 text-right">
                      <pagination-controls id="patient_pagination" directionLinks="true"
                        (pageChange)="onPageChange($event)"
                        class="float-right pt-3 my-pagination"></pagination-controls>

                    </div>
                  </div>
                </div>
                <!-- <div *ngIf="altAddressCheck" class="extraDiv" >
                      </div> -->
              </div>
            </div>

          </div>

        </div>




      </div>
    </div>
  </form>
</div>