<div class="dialog-wrapper">
    <!-- <h3 mat-dialog-title>Change Password </h3> -->
    <mat-dialog-content>
      <div class="container">
        <form [formGroup]="popupNewComplaintForm" autocomplete="off" novalidate>
        <div class="row">
          <div class="col-sm-12">
            <div class="card-group">
              <div class="card p-4" >
                <!-- <button mat-button (click)="onClose()">X</button> -->
               

                <div class="card-body col-sm-12">
                    <div class="row">
                    <div class="col-sm-11">
                        <p class="text-muted">Add New Complaint</p>
                    </div>
                    <div class="col-sm-1 ">
                      <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                        <a (click)="onClose()" matTooltip="Close" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">close</span></a>
                        </div>
                    </div>
                   
              </div>
                  <div class="col-sm-12">
                    <div class="row">
                        <!-- <div class="col-sm-6 ">
                            <label class="pt-3 lable_style">Complaint Name :</label>
                            </div>
                  <div class="col-sm-6">
                    <mat-form-field class="example-full-width" appearance="outline" *ngIf="complaintId==1">
                      <mat-label>Instructions Name</mat-label>
                      <input matInput placeholder="Instructions Name" formControlName="leftComplaintName" required />
                     </mat-form-field>
                     <mat-form-field class="example-full-width" appearance="outline" *ngIf="complaintId==2" >
                      <mat-label>Instructions Name</mat-label>
                      <input matInput placeholder="Instructions Name" formControlName="rightComplaintName"  required />
                     </mat-form-field>
                  </div> -->
                  <div class="col-sm-4">
                    <label class="pt-3 lable_style">Item Name :</label>
                    </div>
          <div *ngIf="addNewComplaint" class="col-sm-4">
            <mat-form-field class="example-full-width" appearance="outline" *ngIf="complaintId==1">
              <mat-label>Complaint Name</mat-label>
              <input matInput placeholder="Complaint Name" formControlName="leftComplaintName" required />
             </mat-form-field>
             <mat-form-field class="example-full-width" appearance="outline" *ngIf="complaintId==2" >
              <mat-label>Complaint Name</mat-label>
              <input matInput placeholder="Complaint Name" formControlName="rightComplaintName"  required />
             </mat-form-field>
          </div>
          <div *ngIf="!addNewComplaint" class="col-sm-4">
            <mat-form-field class="example-full-width" *ngIf=" complaintId==1"  appearance="outline">
              <mat-label>Select Complaint </mat-label>
         
              <mat-select #select1 multiple formControlName="leftComplaintNameList" >
                <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" 
                [displayMember]="'leftComplaintNameList'"  [array]="variables3" (filteredReturn)="filteredList1 =$event"></mat-select-filter>
                              <mat-option *ngFor="let item of filteredList1" [value]="item.leftComplaintName">
                                {{item.leftComplaintName}}
                              </mat-option>
              
            </mat-select>
             </mat-form-field>
             <mat-form-field class="example-full-width" *ngIf=" complaintId==2"    appearance="outline">
              <mat-label>Select Items </mat-label>
              
              <mat-select #select1 multiple formControlName="rightRepairItemNameList" >
                <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'rightRepairItemNameList'" 
               [array]="variables4" (filteredReturn)="filteredList2 =$event"></mat-select-filter>
              <mat-option *ngFor="let item of filteredList2" [value]="item.rightComplaintName">
                {{item.rightComplaintName}}
              </mat-option>
              
            </mat-select>
             </mat-form-field>
          </div>
          <div class="col-sm-1">
          </div>
        <div *ngIf="!addNewComplaint" class="col-sm-3">
          <a class="nav-link headers_custom" id="home" (click)="addNewComplaintFunc()" [routerLink]="">
            Add New Complaint
           </a> 
        </div>
        <div *ngIf="addNewComplaint" class="col-sm-3">
          <a class="nav-link headers_custom" id="home" (click)="cancelFunc()" [routerLink]="">
           Cancel 
           </a> 
        </div>
      
        <div class="col-sm-5">
            </div>
        <div *ngIf="addNewComplaint" class="col-sm-2">
          <button type="button" class="btn btn-outline-primary btn-sm example-full-width"  *ngIf=" complaintId==1" (click)="saveLeftComplaintData()" >Add</button>
          <button type="button" class="btn btn-outline-primary btn-sm example-full-width"  *ngIf=" complaintId==2" (click)="saveLeftComplaintData()" >Add</button>
        </div>
        <div *ngIf="!addNewComplaint" class="col-sm-2">
          <button type="button" class="btn btn-outline-primary btn-sm example-full-width"  *ngIf=" complaintId==1" >Save</button>
          <button type="button" class="btn btn-outline-primary btn-sm example-full-width"  *ngIf=" complaintId==2" >Save</button>
        </div>
        <div class="col-sm-5">
      </div>
          <!-- <div class="col-sm-1">
            </div>
                  <div class="col-sm-5">
                      </div>
                  <div class="col-sm-2">
                    <button class="btn btn-outline-primary btn-sm example-full-width" *ngIf="complaintId==1" (click)="saveLeftComplaintData()">Add</button>
                    <button class="btn btn-outline-primary btn-sm example-full-width" *ngIf="complaintId==2"  (click)="saveRightComplaintData()" >Add</button>
                  </div>
                  <div class="col-sm-5">
                </div> -->
                </div>
                </div>

                </div>



                
              </div>
            </div>
          </div>
          <div class="col-sm-4"></div>
        </div>
</form>


      </div>
    </mat-dialog-content>
  
  </div>