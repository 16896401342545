import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-backdated-apt',
  templateUrl: './backdated-apt.component.html',
  styleUrls: ['./backdated-apt.component.scss']
})
export class BackdatedAptComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
