import { Component, Inject, OnInit } from "@angular/core";
import {FormGroup,FormBuilder,FormControl, Validators} from '@angular/forms';
import { ApiServiceService } from '../api-service.service';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';;

@Component({
  selector: 'app-popup-followupreason',
  templateUrl: './popup-followupreason.component.html',
  styleUrls: ['./popup-followupreason.component.scss']
})
export class PopupFollowupreasonComponent implements OnInit {

  popupFollowupReasonForm: FormGroup;
  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<PopupFollowupreasonComponent>,@Inject(MAT_DIALOG_DATA) public data:any, 
    public apimicroservice: ApiServiceService,
) {dialogRef.disableClose = true; }

  ngOnInit(): void {
    this.popupFollowupReasonForm = this.fb.group({  
      "followupReason": new FormControl('',[Validators.required,Validators.pattern("^[a-zA-Z -]+$")]),
      "createdAt": new Date(),
    });
  }

  saveReason(){
    let payload = this.popupFollowupReasonForm.value; 
    //
    
    this.apimicroservice.submitfollowupReasonData(payload).subscribe(res => {
      //
      
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);      
        this.ngOnInit();
       this.onClose();        
      }else if(res.status == false){       
        this.apimicroservice.openSnackbar(res.message);
      }
    },(ERROR:HttpErrorResponse) => {
      this.apimicroservice.openSnackbar(ERROR.error.message);
  });
  } 

  save() {
    if (this.popupFollowupReasonForm.invalid) {
      this.popupFollowupReasonForm.get("followupReason").markAsTouched();
      return;
    } else {
      this.saveReason();
    }
  }
  
  onClose(): void {
    this.dialogRef.close();
  }

}
