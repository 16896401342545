<!DOCTYPE html>
<html lang="en" >

<head>
  <title>Bootstrap Example</title>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <link rel="stylesheet" href="https:cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css">
  <script src="https:cdn.jsdelivr.net/npm/jquery@3.6.3/dist/jquery.slim.min.js"></script>
  <script src="https:cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js"></script>
  <script src="https:cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/js/bootstrap.bundle.min.js"></script>
  <style>
    .sidenav {
    height: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
     background-color: #000000;
    background-color: #000000;
    overflow-x: hidden;
    padding-top: 20px;
    border-radius: 20px;
  }
  
  .vl {
    border-left: 1px solid #e5e5e5;
    height: 50px;
    position: absolute;
    left: 50%;
    margin-left: -3px;
    top: 0;
  }
    .sidenav a {
      padding: 6px 8px 6px 16px;
      text-decoration: none;
      font-size: 25px;
      color: #f1f1f1;
      display: block;
    }
    
    .sidenav a:hover {
      color: #f1f1f1;
    }
    
    @media screen and (max-height: 450px) {
      .sidenav {padding-top: 15px;}
      .sidenav {margin-top: 5px;}
      .sidenav a {font-size: 18px;}
    }
    @media screen and (max-height: 350px) {
      .divbody {padding-top: 15px;}
      .divbody {margin-top: 5px;}
    }
    .divbody {
      height: 100%;
       min-height: 86rem !important;
       max-height: 86rem !important;
      z-index: 1;
      width: 100%;
       overflow-x: hidden;
       border-radius: 20px;
    }
    .close_style {
      text-align: end;
    }
    .mm
    {
      margin-left: 3.5rem;
    }
    .fa-clone
    {
      font-size:18px;
    }
    .color{
      color:#a7adc9;
    }
    .header
    {
      font-size:15px;
    font-weight:500;
    color: #505050;
    }
    .chk-list {
      width: 500px;
      max-width: 100%;
       border: solid 1px #ccc;
       min-height: 60px;
    
      display: block;
      background: white;
      border-radius: 4px;
      overflow: hidden;
    }
    
    .chk-box {
      padding: 10px 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      font-size: 14px;
    }
    .slist{
      overflow-y: scroll;
      height: 100px;
      margin-right: 3px;
    }
    .cardcolor {
      background-color: #fff !important;
      color: #f1f1f1 !important;
      border-radius: 20px;
    }
    .cardcolorplace{
      color: #f1f1f1 !important;  
    }
  
    .subtitlecolor{
      color: #9da0f5 !important; 
    }
    .white{
      color: #fff !important;
    }
    .cardbgdark{
      background-color: #000000 !important;
      border-radius: 26px;
    }
    .imgallign{
        margin-bottom: -15rem !important;
        z-index: 1;
        margin-left: 2rem;
    }
    
    .cardBoxpurple{
      background-color: #000000 !important;
       border-radius: 20px;
      color: #f1f1f1;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
    .boxsssss{
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
      border: 1px transparent !important;
      padding-bottom: 6rem;
    }
    .cardBoxred{
      background-color: #e94b70 !important;
       border-radius: 20px;
      color: #f1f1f1;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      border: 1px transparent !important;
    }
    .cardBoxyellow{
      background-color: #d9da55 !important;
       border-radius: 20px;
      color: #f1f1f1;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      border: 1px transparent !important;
    }
    .cardBoxgreen{
      background-color: #69d8e7 !important;
       border-radius: 20px;
      color: #f1f1f1;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      border: 1px transparent !important;
    }
    .cardBoxblue{
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      border: 1px transparent !important;
      background-color: #4281f7 !important;
       border-radius: 20px;
      color: #f1f1f1;
    }
    .borderradius{
        border-radius: 1rem !important;
    }
    .widthimage{
      width: inherit;
      height:30rem
    }
    .hover:hover{
       background-color: red !important;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
      transform: scale(1.1);
    }
    .hover{  transition: transform .2s;}
  
  
  
  .dropbtn {
    color: white;
    padding: 16px;
    border: none;
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 130px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-size: 12px;
  }
  
  .dropdown-content a:hover {background-color: #ddd;}
  
  .dropdown:hover .dropdown-content {display: block;}
  
   .dropdown:hover .dropbtn {background-color: #3e8e41;}
  .img_style
  {
    width:10rem;
  }
  
  .titlecolor{
      color: #000000 !important;
  }
  
  .colordarkbg{
    color: #000000 !important;
  }
  .whitecolcor{
  color: #ffffff;
  }
  
  
   pirlie
  .rounded-3 {
      border-radius: 1rem !important;
  }
  
  .bg-secondary {
      --bs-bg-opacity: 1;
      background-color: #f4f5f7 !important;
  }
  @media (min-width: 500px){
      .d-sm-flex {
      display: flex !important;
  }
  }
  
  .align-items-center {
      align-items: center !important;
  }
  .d-block {
      display: block !important;
  }
  @media (min-width: 500px){
  .mb-sm-0 {
      margin-bottom: 0 !important;
  }
  .mx-sm-0 {
      margin-right: 0 !important;
      margin-left: 0 !important;
  }
  .rounded-circle {
      border-radius: 50% !important;
  }
  }
.btn-light {
  color: #fff;
  border-color: #0066CC !important;
  background-color: #0066CC !important;
}
  .shadow {
      box-shadow: 0 0 0.625rem -0.1875rem rgb(0 0 0 / 13%) !important;
  }
  .text-muted {
      color: #9e9fb4 !important;
  }
  .fs-ms {
      font-size: 0.8125rem !important;
  }
  .mb-0 {
      margin-bottom: 0 !important;
  }
  
  .bgcolors{
      background-image : linear-gradient(to right, #766df4 0%, #766df4 30%, #6a9bf4 100%); 
  }
  .btnpossition{
      line-height: 2rem;
      padding-top: 1.5rem;
  }
  ::ng-deep .mat-form-field-flex > .mat-form-field-infix {
      padding: 0.4em 0px !important;
    }
    
   ::ng-deep .mat-form-field-label-wrapper {
      top: -1.5em;
    }
    
   ::ng-deep .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
      -webkit-transform: translateY(-1.1em) scale(0.75);
              transform: translateY(-1.1em) scale(0.75);
      width: 133.33333%;
    }
    mat-form-field{
      margin: 0.25em 0;
      /* margin-bottom: -8px; */
  }
  .fa-2x
  {
      font-size: 	1.5em; 
  }
  .fasW
  {
    color: white !important;  
  }
  .fass
  {
    color: #000000 !important;  
  }
  .my-pagination::ng-deep .ngx-pagination .current {
    background: #99999a  !important;
   
  }
  .example-full-width
  {
      width: 100%;
  }

  ::ng-deep .mat-checkbox-checked.mat-accent .mat-checkbox-background,.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: #99999a!important;
    color: #99999a;
  }
  .disabled {
    pointer-events: none;
    color: lightgray;
  
  }
  
  ::ng-deep .mat-checkbox-checked .mat-checkbox-background, 
  .mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #99999a !important;
  }
  
  ::ng-deep .mat-checkbox:not(.mat-checkbox-disabled) .mat-checkbox-ripple .mat-ripple-element {
  background-color: #99999a !important;
  }

.progress-tracker--vertical {
  flex-direction: column;
}
.progress-tracker {
  display: flex;
  padding: 0;
  list-style: none;
}
.progress-tracker--vertical .progress-step {
  display: flex;
  flex: 1 1 auto;
}
.progress-step .progress-marker {
  color: #fff;
}

.progress-marker {
  display: block;
  position: relative;
}
.progress-step.is-complete .progress-marker::before, .progress-step.is-progress .progress-marker::before {
  background-color: #000000;
}

.progress-step .progress-marker::before {
  background-color: #b6b6b6;
}
.progress-marker::before {
  content: attr(data-text);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 20;
  width: 24px;
  height: 24px;
  padding-bottom: 2px;
  border-radius: 50%;
  transition: background-color, border-color;
  transition-duration: 0.3s;
}
.progress-step.is-complete .progress-marker::after, .progress-step.is-progress .progress-marker::after {
  background-color: #9da0f5;
}
.progress-tracker--vertical .progress-marker::after {
  right: auto;
  top: 12px;
  left: 10px;
  width: 4px;
  height: 100%;
}
.progress-step .progress-marker::after {
  background-color: #b6b6b6;
}
.progress-marker::after {
  content: "";
  display: block;
  position: absolute;
  z-index: -10;
  top: 10px;
  right: -12px;
  width: 100%;
  height: 4px;
  transition: background-color 0.3s, background-position 0.3s;
}
.progress-tracker--vertical .progress-text {
  padding: 0 12px 24px 12px;
}

.progress-text {
  display: block;
  padding: 8px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.heighttextarea{
  min-height: 20rem ;
}
.divbody1{
  
  min-height: 58.5rem !important;
  max-height: 58.5rem !important;
}
.mTop
{
  margin-top: 8px;
}
.red{
  color: red;
 
}
.blue{
  color:blue;
}

::ng-deep .mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #99999a!important;   /*inner circle color change*/
}

::ng-deep.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color:#99999a!important; /*outer ring color change*/
}
@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }
    .divName{
      max-width: 100%;
      min-width: 100%;
      height: 100%;
      width: 100%;
      }
}
.capitalize {
  text-transform:capitalize;
}
[contenteditable="true"]:active,
[contenteditable="true"]:focus{
  border:none;
  outline:none;
}
  </style>
</head>
<body id="communicationfffff">

  <div class="dialog-wrapper ">
    <div contenteditable="true" class="row capitalize">

      <div class="col-sm-11 justify-content-center text-center">
        <h3 mat-dialog-title class="title justify-content-center text-center"><u>Communication Text</u></h3>
        <!-- <h1 class="titlecolor" >  Search Patients</h1>   -->
      </div>
      <div class="col-sm-1 text-right pb-2 ">
        <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
          <a (click)="onClose()" matTooltip="Close" class="d-flex no-print align-items-center pr-2 mt-2"><span
              class="material-symbols-outlined pr-2">close</span></a>
        </div>
      </div>
      <!-- <div class="col-sm-1 text-right pb-2">
        <button mat-icon-button class="close-button no-print" [mat-dialog-close]="true">
            <mat-icon class="close-icon" color="warn">close</mat-icon>
          </button>
    </div> -->
      <div class="col-sm-4 text-left">
        <h2><b>{{patientName}}</b></h2>
      </div>
      <div class="col-sm-8 text-left"></div>
      <div class="col-sm-4 text-left">
        <h3> {{address}} </h3>
      </div>
      <div class="col-sm-8 text-left"></div>
      <div class="col-sm-4 text-left">
        <h3> Date: {{todaysDate | date:'dd/MM/yyyy'}}</h3>
      </div>
      <div class="col-sm-8 text-left"></div>
      <div class="col-sm-4 text-left">
        <h3> {{pincode}}</h3>
      </div>
      <div class="col-sm-8 text-left"></div>
      <div class="col-sm-6 text-left">
        <h3> Sub: Information on submitted documents.</h3>
      </div>
      <div class="col-sm-6 text-left"></div>
      <div class="col-sm-12 text-left">
        <h3>
          Dear HR, <br>

          I am Kiran Choudhary with employee ID E0000. I am sending with this letter, all the documents which are needed
          for the application process. Along with this letter, I have attached all the documents. The list of documents
          is given below.

          I hope that I have attached all the documents asked by you. Please do not hesitate to revert back and let me
          know if any more documents are needed.

          <br>Thank You.

          <br>Yours sincerely,
          <br> XYZ
        </h3>
        <!-- (Signature)

KIRAN CHOUDHARY -->
      </div>

     
    </div> 
  </div>
</body>
<div class="row">
<div class="col-sm-10"></div>
<div class="col-sm-2">
  
  <button type="button" class="btn btnCol btn-primary btn-sm no-print example-full-width"
    (click)="generatePdf()">Generate Pdf</button> 
</div>

</div>
</html>