import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {AfterViewInit, Directive, ViewChild} from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ApiServiceService } from '../api-service.service';
import { AppoinmentDashboardComponent } from '../appoinment-dashboard/appoinment-dashboard.component';
import { ClinicHolidayComponent } from '../clinic-holiday/clinic-holiday.component';
import { CommunicationDashboardComponent } from '../communication-dashboard/communication-dashboard.component';
import { DeviceDashboardComponent } from '../device-dashboard/device-dashboard.component';
import { DoctorLeaveComponent } from '../doctor-leave/doctor-leave.component';
import { EarmouldDashboardComponent } from '../earmould-dashboard/earmould-dashboard.component';
import { PatientDashboardComponent } from '../patient-dashboard/patient-dashboard.component';
import { PaymentDashboardComponent } from '../payment-dashboard/payment-dashboard.component';
import { RepairDashboardComponent } from '../repair-dashboard/repair-dashboard.component';
import { DatePipe } from '@angular/common';
import {
  VERSION,
  ElementRef, 
  ViewEncapsulation,
} from "@angular/core";
import { Calendar } from "@fullcalendar/core";
import { NotesComponent } from '../notes/notes.component';
import { FollowupDashboardComponent } from '../followup-dashboard/followup-dashboard.component';
import { DiagnosisComponent } from '../diagnosis/diagnosis.component';
 
@Component({
  selector: 'app-search-dashboard',
  templateUrl: './search-dashboard.component.html',
  styleUrls: ['./search-dashboard.component.scss',]
})  
export class SearchDashboardComponent implements OnInit {
  @ViewChild("calendar", { static: true }) calendar: ElementRef<any>;
  name = "Angular " + VERSION.major;
  selectedIndex: number = 0;
  disable : Boolean = true;
  userPermission: any;
  userType: any;
  permissionList =[];
  CustomerSettingView = true;
  UserSettingView= true;
  UserPermissionsView= true;
  patientData: any;
  appoinmentCount: any;
  earMouldCount: any;
  inwardOutwardDataList: any[];
  communicationCount: number;
  deviceCount: any;
  appoinmentlist: any[];
  appointmentlist: any[];
  branchRefId: string;
  clinicRef: string;
  aptPatient: any;
  CheckupDetailCreate= true;
  datee: any;
  pId: any;
  arr: any;
  hide =true;
  lastApt: any;
  latest_date: Date;
  currentdate: string;
  date: string;
  lastAptDate: Date;
  aptDate: string;
  lastAptData: any;
  
  constructor(public router: Router,
    private route: ActivatedRoute,
    public apimicroservice: ApiServiceService,
    private change: ChangeDetectorRef,
    public datepipe: DatePipe) { }  

    @ViewChild(PatientDashboardComponent, {static: false}) private PatientDashboardMaster: PatientDashboardComponent;
    @ViewChild(DeviceDashboardComponent, {static: false}) private DeviceDashboardMaster: DeviceDashboardComponent;
    @ViewChild(PaymentDashboardComponent, {static: false}) private PaymentDashboardMaster: PaymentDashboardComponent;
    @ViewChild(RepairDashboardComponent, {static: false}) private RepairDashboardMaster: RepairDashboardComponent;
    @ViewChild(EarmouldDashboardComponent, {static: false}) private EarmouldDashboardMaster: EarmouldDashboardComponent;
    @ViewChild(AppoinmentDashboardComponent, {static: false}) private AppoinmentDashboardMaster: AppoinmentDashboardComponent;
    @ViewChild(CommunicationDashboardComponent, {static: false}) private CommunicationDashboardMaster: CommunicationDashboardComponent;
    @ViewChild(NotesComponent, {static: false}) private NotesComponentMaster: NotesComponent;
    @ViewChild(FollowupDashboardComponent, {static: false}) private FollowupComponent: FollowupDashboardComponent;
    @ViewChild(DiagnosisComponent, {static: false}) private DiagnosisComponent: DiagnosisComponent;
    // @ViewChild(DoctorLeaveComponent, {static: false}) private PaymentDashboardMaster: DoctorLeaveComponent;
  ngOnInit(): void {
    this.arr = '';
    this.hide = false;
    this.patientData=[
      { firstName:'-',lastName:'-',age:'-',patientId:'' },
    ] 
    this.appoinmentCount = 0;
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermissions"));
    this.userType = sessionStorage.getItem("type");
    this.route.queryParams.forEach(params => { 
      // 
      localStorage.setItem("fromGlobal",'false');  
      this.getPatient(params.PatientId);
      this.fetchApt(params.PatientId); 
      this.fetchEarMouldOrderList(params.PatientId);
      this.fetchInwardOutwardData(params.PatientId);
      this.fetchDeviceByPatientById(params.PatientId);
      //
      
    }); 
    this.disable = true;
    //////////////////////////////////////Todays Apt
  
    this.userType = sessionStorage.getItem('userType');
    this.clinicRef = sessionStorage.getItem("clinicRef");
    let branchRef= sessionStorage.getItem('branchRef');
    let bId = "";
    //
    if (this.userType !== "Super Admin") {
      bId = this.branchRefId;
    } else {
      bId = "";
    }
    this.date = new Date().toISOString();
    this.currentdate = new Date().toISOString();
    //
    this.latest_date=new Date();
    this.datee=this.datepipe.transform(this.latest_date,'dd-MM-yyyy');
    //
    
    this.apimicroservice.getAppointmentbydatebranch(this.currentdate,branchRef).subscribe((res) => {
    
      //
      this.appointmentlist = []; 
      this.appoinmentlist = [];
      if (res.status) {
        let ssss = []; 
        let wwww = [];
        if(res.data.length > 0){
          res.data.forEach((element) => {
            sessionStorage.setItem('aptDate',element.startDateTime);
            let x = {};
            x["title"] = element.clientName;
            x["start"] = new Date(element.startDateTime);
            x["end"] = new Date(element.endDateTime);
            x["color"] = element.eventColor;
            ssss.push(x);
            let z = {};
            z["id"]= element._id,
            z["name"]= element.clientName,
            z["doctorName"]=element.doctorName,
            z["doctorRefId"]=element.doctorRefId,
            z["branchName"]= element.clinicLocation,
            z["branchRefId"]= element.branchRefId,
            z["patientRefId"]=element.patientRefId, 
            z["patientName"]=element.patientName,
            z["patientId"]=element.patientId,
            z["dateTime"]= new Date(element.startDateTime).toLocaleString(),
            z["titleOfAppoinment"]=element.AppoinmentType,
            z["appointId"]=element.appointId,
            z["appointStatus"]=element.aptStatus,
            z["status"]= "N/A",
            z["Mob"] = element.contactNumber
            wwww.push(z)
            //
            
          });
          this.appointmentlist = ssss; 
         this.appoinmentlist = wwww;
    //
    
         this.appoinmentlist.forEach(element => { 
            if(this.pId == element.patientRefId)
            {
              //
              
              this.arr.push(element.patientRefId);
            }
           });
    
           //
           this.hide = true;
           //
         this.aptPatient = this.appoinmentlist[0].patientRefId;
         //
         //
         
        }else{ 
          this.appointmentlist = [{"title": "No Appointments Available","color":"white"}];
        }
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    });
  

  } 
  reloadFunc() 
  { 
    
    
    this.DeviceDashboardMaster.ngOnInit();

    }
  onTabChanged(event: MatTabChangeEvent) 
  { 
    //
    
      if(event.tab.textLabel == 'Patient')
      {
        this.PatientDashboardMaster.ngOnInit();//Or whatever name the method is called
        this.disable = true; 
      }
      else if(event.tab.textLabel == 'Appointments')
      {
        this.AppoinmentDashboardMaster.ngOnInit();//Or whatever name the method is called
      }
      else if(event.tab.textLabel == 'Diagnosis')
      {
        this.DiagnosisComponent.ngOnInit();//Or whatever name the method is called
      }
      else if(event.tab.textLabel == 'Device')
      {
        this.DeviceDashboardMaster.ngOnInit();//Or whatever name the method is called
      }
      else if(event.tab.textLabel == 'Payment')
      {
        this.PaymentDashboardMaster.ngOnInit();//Or whatever name the method is called
      }
      else if(event.tab.textLabel == 'Repair')
      {
        this.RepairDashboardMaster.ngOnInit();//Or whatever name the method is called
      }
      else if(event.tab.textLabel == 'Earmould')
      {
        this.EarmouldDashboardMaster.ngOnInit();//Or whatever name the method is called
      }
      else if(event.tab.textLabel == 'Communication')
      {
        this.CommunicationDashboardMaster.ngOnInit();//Or whatever name the method is called
      }
      else if(event.tab.textLabel == 'Notes')
      {
        this.NotesComponentMaster.ngOnInit();//Or whatever name the method is called
      }
      else if(event.tab.textLabel == 'Follow Up')
      {
        this.FollowupComponent.ngOnInit();//Or whatever name the method is called
      }
  }
  
 

public tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    
  this.selectedIndex = tabChangeEvent.index;
}

public next() {
  this.disable = false;
  this.selectedIndex += 1;
  this.PaymentDashboardMaster.ngOnInit();
}

public prev() { 
  this.selectedIndex -= 1;
 // this.UserMaster.ngOnInit();
  this.disable = true;
}

public enable(){
  this.disable = false;
 // this.Permissions.ngOnInit();
}


ngOnChanges(changes: SearchDashboardComponent) {
}
redirect(to): void {
  this.router.navigate([to]);
}
onback() {
  this.apimicroservice.back()
}  
getPatient(id){ 
  this.apimicroservice.getPatientById(id).subscribe(res => {
    // 
    this.patientData = [];
    if(res.message !== 'No Data Found')
    { 
      //
      
      this.patientData =res.data[0]; 
      this.pId =res.data[0]._id
      //
      
    } 
   else
   {
    this.patientData =[];
   }
   //
   
   });
}
fetchApt(id) {   

  
  let pg = 1;  
  let size = 5;
  let search='';
  this.apimicroservice.getAllAptDashboard(pg,size,search,id).subscribe(res => {
    // 
    if (res.status) {     
      this.appoinmentCount = res.data.totalItems;    
      // this.lastAptData =res.data.data[0];
      
     this.lastApt= res.data.data[0].startDateTime;
     let a= res.data.data[0].startDateTime;

     let x = {};
     let ssss = []; 
     let wwww = [];
     x["title"] = res.data.data[0].clientName;
     x["start"] = new Date(res.data.data[0].startDateTime);
     x["end"] = new Date(res.data.data[0].endDateTime);
     x["color"] = res.data.data[0].eventColor;
     ssss.push(x);
     let z = {};
     z["id"]= res.data.data[0]._id,
     z["name"]= res.data.data[0].clientName,
     z["doctorName"]=res.data.data[0].doctorName,
     z["doctorRefId"]=res.data.data[0].doctorRefId,
     z["branchName"]= res.data.data[0].clinicLocation,
     z["branchRefId"]= res.data.data[0].branchRefId,
     z["patientRefId"]=res.data.data[0].patientRefId, 
     z["patientName"]=res.data.data[0].patientName,
     z["patientId"]=res.data.data[0].patientId,
     z["dateTime"]= new Date(res.data.data[0].startDateTime).toLocaleString(),
     z["titleOfAppoinment"]=res.data.data[0].AppoinmentType,
     z["appointId"]=res.data.data[0].appointId,
     z["appointStatus"]=res.data.data[0].aptStatus,
     z["status"]= "N/A",
     z["Mob"] = res.data.data[0].contactNumber
     wwww.push(z)
     this.lastAptData = ssss; 
     this.lastAptData = wwww;
     //
     
     this.aptDate=this.datepipe.transform(a,'dd-MM-yyyy');
      //
      
    } else {
    //  this.apimicroservice.openSnackbar(res.message);
    }
  });
}
fetchEarMouldOrderList(id){
  let pg = 1;
  let size = 5;
  let clinicRef= sessionStorage.getItem('clinicRef');
  let branchRef= sessionStorage.getItem('branchRef');
  let searchText = '';
    this.apimicroservice.getEarMouldOrderDashboard(pg,size,searchText,branchRef,id,clinicRef).subscribe(res => {
      this.earMouldCount = res.data.totalItems;
    });
}
fetchDeviceByPatientById(id){
    this.apimicroservice.getDeviceByPatientById(id).subscribe(res => {
      let data = res.data; 
      this.deviceCount = data.length; 
    });
}
fetchInwardOutwardData(id){
  let search='';
  let pg = 1;
  let size = 5;
  this.apimicroservice.getInwardOutwardDataByPatientId(pg,size,search,id).subscribe(res => {
    //  
    if (res.status) {    
      this.inwardOutwardDataList = [];  
      res.communicaionData.forEach(element => {
        this.inwardOutwardDataList.push({"date":element.createdAt,"_id":element._id,"type":"Communicaion"});
            });
      res.courierData.forEach(element => {
      this.inwardOutwardDataList.push({"date":element.createdAt,"_id":element._id,"type":"Courier"});
       });
       this.communicationCount=this.inwardOutwardDataList.length;
    } else { 
      this.apimicroservice.openSnackbar(res.message);
    }
  });
}
 loadpatient(){  
  //
  //
    
      localStorage.setItem("patient",JSON.stringify(this.lastAptData[0]));
      //",JSON.stringify(this.lastAptData[0]));
      if(this.userType == 'Super Admin') 
           {
          this.apimicroservice.getBranchById(this.lastAptData[0].branchRefId).subscribe(res => {   
            let data = res.data[0];
            //
            sessionStorage.setItem('superClinicRefId',res.data[0].clinicRefId);
            // let pid = res.data[0].clinicRefId;
            // this.router.navigate(['home','opt',pid]);
           });
          } 
          if(this.CheckupDetailCreate == true)
          {
          
            this.redirect('/home/opt');
          }
          else
          {
            this.apimicroservice.openSnackbar("Sorry,You Dont Have Permission To Check Details");
          }
        
    } 

}
