<div class="container-fluid bgcolor2" [formGroup]="checkupDetailsForm">
  <form [formGroup]="checkupDetailsForm" autocomplete="off" novalidate>
    <div class="row p-0">
      <div class="col-sm-1">

      </div>
      <div class="col-sm-11">
        <div class="row pl-0 ">
          <div class="col-12 pl-0 ">
            <h1 class="pt-2 pl-0">Appointment Detail</h1>
          </div>
        </div>
        <div class="row  pr-0 mr-0">
          <div class="col-sm-12 pr-0 mr-0">
            <div class="row pr-0 mr-0">
              <div class="col-sm-8">
                <div class="row">


                  <div class="appointment-detail-card card  col-sm-12 boxsssss borderradius pl-4 pt-2 pr-4  pb-4  mb-2">
                    <div class="row ">
                      <!-- <div *ngIf="trackingStatus !== ''" class="col-sm-5">
                        <h2>{{paientdetails.name}}-{{trackingStatus}}</h2>
                      </div> -->
                      <div class="col-sm-4">
                        <div class="row">
                        <h2>{{paientdetails.name}}</h2>
                        <label *ngIf="patientData.decease == 'Yes'" class="redColor mt-2 ml-2">( Patient is Decease
                          )</label>
                          </div>
                      </div>
                  
                      <div class="col-sm-3">
                        <!-- <div class="row">
                        <h2 *ngIf="trackingStatus !== ''">Tracking Status :</h2>
                        <h2 class="blue" *ngIf="trackingStatus !== ''"> {{trackingStatus}}</h2>
                        </div> -->
                      </div>
                      <div class="col-sm-5">

                        <div
                          class="icon-btns age-title-right row justify-content-end d-flex align-items-center float-end pb-4">
                          <ul>
                            <li>
                              <a class="icon-box2" data-toggle="tooltip" data-placement="top"
                                (click)="editAppointment(paientdetails.id)" matTooltip="Rescheduled Appointment">
                                <span class="material-symbols-outlined">event_repeat</span>
                                <img src="../../assets/icon-bg2.svg" class="icon-bg2">
                              </a>
                            </li>
                            <li>
                              <a class="icon-box2" data-toggle="tooltip" data-placement="top"
                                (click)="billing(paientdetails.patientRefId,paientdetails.appointId,paientdetails.branchRefId,paientdetails)"
                                matTooltip="Billing">
                                <span class="material-symbols-outlined">receipt_long</span>
                                <img src="../../assets/icon-bg5.svg" class="icon-bg2">
                              </a>
                            </li>
                            <li>
                              <a class="icon-box2" data-toggle="tooltip" data-placement="top" matTooltip="Repair"
                                (click)="repairClick(paientdetails.branchRefId,paientdetails.patientRefId,paientdetails.branchName,paientdetails.patientName)">
                                <span class="material-symbols-outlined">handyman</span>
                                <img src="../../assets/icon-bg6.svg" class="icon-bg2">
                              </a>
                            </li>
                            <li>
                              <a class="icon-box2" data-toggle="tooltip" data-placement="top" matTooltip="HAT"
                                (click)="hat(paientdetails.patientRefId,paientdetails.name)">
                                <span class="material-symbols-outlined">note_alt</span>
                                <img src="../../assets/icon-bg1.svg" class="icon-bg2">
                              </a>
                            </li>
                            <li>
                              <a class="icon-box2" data-toggle="tooltip" data-placement="top" matTooltip="Ear Mold"
                                (click)="earMold(paientdetails.branchRefId,paientdetails.patientRefId,paientdetails.branchName,paientdetails.patientName)">
                                <span class="material-symbols-outlined">hearing</span>
                                <img src="../../assets/icon-bg10.svg" class="icon-bg2">
                              </a>
                            </li>
                            <li>
                              <a class="icon-box2" data-toggle="tooltip" data-placement="top"
                                (click)="functiondelete(paientdetails.id,paientdetails.patientName,paientdetails.dateTime)"
                                matTooltip="Appointment Cancel">
                                <span class="material-symbols-outlined">event_busy</span>
                                <img src="../../assets/icon-bg8.svg" class="icon-bg2">
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="detail text-center">
                      <div class="card-block">
                        <span class="title secondary-color mb-1">Audiologists</span>
                        <span>{{paientdetails.doctorName}}</span>
                      </div>
                      <div class="card-block">
                        <span class="title secondary-color mb-1">Gender</span>
                        <span>{{patientData.sex}}</span>
                      </div>
                
                      <div class="card-block">
                        <span class="title secondary-color mb-1">DOB</span>
                        <span>{{patientData.dob | date: 'dd/MM/yyyy'}}</span>
                      </div>
                      <div class="card-block">
                        <span class="title secondary-color mb-1">Contact</span>
                        <span>{{patientData.mobile1}}</span>
                      </div>
                      <div class="card-block">
                        <span class="title secondary-color mb-1">Address</span>
                        <span>{{patientData.streetAddress}}</span>
                      </div>
                      <div class="card-block">
                        <span class="title secondary-color mb-1">Age</span>
                        <span>{{patientData.age}}</span>
                      </div>
                      <div class="card-block">
                        <span class="title secondary-color mb-1">Visit No.</span>
                        <span>{{NoOfVisits}}</span>
                      </div>
                      <div class="card-block">
                        <span class="title secondary-color mb-1">Total Business</span>
                        <span>{{totalAmount}}</span>
                      </div>
                      <div class="card-block">
                        <span class="title secondary-color mb-1">Reference No.</span>
                        <span>{{refData}}</span>
                      </div>
                    </div>
                  </div>



                  <div class="checkup-detail card col-sm-12 boxsssss borderradius p-3  mb-2">
                    <h2 class="mb-0 pb-1">Checkup Details</h2>
                    <!-- <h5 class="mt-0 pt-2">Add Checkup Details</h5> -->
                    <form [formGroup]="checkupDetailsForm">
                      <div class="row ">
                        <div class="col-3">
                          <div class="form-group">
                            <!-- <label for="">Complaints:</label> -->

                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Complaints</mat-label>
                              <textarea maxlength="1000" placeholder="Complaints" matInput formControlName="complaints"
                                cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="4"
                                cdkAutosizeMaxRows="5"></textarea>

                            </mat-form-field>

                          </div>
                        </div>
                        <div class="col-3">
                          <div class="form-group">
                            <!-- <label for="">Observations:</label> -->
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Observations</mat-label>
                              <textarea maxlength="1000" placeholder="Observations" matInput formControlName="observations"
                                cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="4"
                                cdkAutosizeMaxRows="5"></textarea>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="col-3">
                          <div class="form-group">
                            <!-- <label for="">Diagnosis:</label> -->
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Diagnosis</mat-label>
                              <textarea maxlength="1000" placeholder="Diagnoses" matInput formControlName="diagnoses" cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="4"
                                cdkAutosizeMaxRows="5"></textarea>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="col-3 ">
                          <div class="form-group">
                            <!-- <label for="">Notes:</label> -->
                            <mat-form-field class="example-full-width" appearance="outline">
                              <mat-label>Notes</mat-label>
                              <textarea maxlength="1000" placeholder="Notes" matInput formControlName="notes" cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="4"
                                cdkAutosizeMaxRows="5"></textarea>
                            </mat-form-field>
                          </div>
                        </div>
                      </div> 
                      <div class="row">
                        <div class="col-sm-3"> 
                          <div class="row">
                            <div class="col-sm-10 pr-1">
                              <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Category</mat-label>
                                <mat-select #select1 placeholder="Status" multiple class="colorblack"
                                  formControlName="trackingStatusName">
                                  <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'"
                                    [displayMember]="'trackingStatusName'" [array]="allPatientStatus"
                                    (filteredReturn)="filteredListPatientStatus =$event"></mat-select-filter>
                                  <mat-option *ngFor="let item of filteredListPatientStatus"
                                    [value]="item.trackingStatusName">
                                    <i class="fa-solid fa-circle" [style.color]="item.color" ></i>  {{item.trackingStatusName}}
                                  </mat-option>
                                </mat-select>
                  
                              </mat-form-field>
                            </div>
                            <div class="col-sm-2 mt-1">
                              <i (click)="openPatientStatusPopUp();" matTooltip="Add New Patient Status"
                                class="blue_col fa-solid fa-circle-plus pl-1 fa-lg"> </i>
                            </div>
  
                          </div>
                        </div>

                        <!-- <div class="col-6"> 
                                   <div class="form-group">
                                      <label for="">Follow Up Reason:</label> 
                                      <div class="d-flex">
                                   
                      <select  placeholder="Follow Up Reasons.." formControlName="followupReasons"
                      placeholder="Follow Up Reasons.." class=" form-control form-select" id="inputGroupSelect01">
                                           <option selected>Follow Up Reasons...</option> 
                                            <option *ngFor="let item of filteredList1"  [(value)]="item.followupReason">
                                              {{item.followupReason}}
                                              </option>
                                         </select>
                                         <a  class="align-self-center ms-1 add-icon highlighted-color ">
                                          <i class="blue_col fa-solid fa-circle-plus pl-1 fa-lg mb-2 pt-0 mt-1" 
                                          (click)="openFollowUpReasonPopUp();" matTooltip="Add Follow Up Reason"></i></a>
                                      </div>
                                   </div>
                                </div> -->
                        <div class="col-sm-6">
                          <div class="form-group">
                            <!-- <div class="row">
                              <div class="col-sm-4">
                            <h5 class="mt-0 pt-2">Follow Up :</h5>
                            </div>
                            <div class="col-sm-4">
                              <h5 class="mt-0 pt-2">Book Appointment :</h5>
                              </div>
                              <div class="col-sm-4">
                                <h5 *ngIf="submitButton" class="mt-0 pt-2">Upload File :</h5>
                                <h5 *ngIf="updateButton" class="mt-0 pt-2">Uploaded File :</h5>
                                </div>
                            </div> -->
                            <div class="row">
                              <div class="col-sm-4">
                                <input #fileUpload type="file" style="display: none;" (change)="onChange($event)" />
                                <button type="button" class="btn uploadBtn mt-2 btn-primary btn-sm example-full-width"
                                  matTooltip="Upload JPG,PNG,CSV or PDF image.300 x 300 required."
                                  (click)="fileUpload.click()"><i class="ai-refresh-cw me-2"></i>Upload Photo</button>
                            </div>
                             
                        
                            <div class="col-sm-8"></div>
                            <div class="col-sm-4">
                               
                              <div class="progress" *ngIf="progress">
                                <div class="progress-bar" [style.width]="progress + '%'">{{progress}}%</div>
                              </div>
                            </div>
                         
                            <!-- <div class="row pl-3">
                              <div class="col-sm-6 pl-0 ml-0">
                                <button type="button" class="btn btn-primary  btn-sm example-full-width"
                                  (click)="openBookAppointmentPopup(patientData._id)">Book Appointment</button>
                              </div>

                            </div> -->
                          </div>
                        </div>
                        </div>
                        <!-- <div class="col-sm-4">
                          <div class="form-group row"> 
                         
                              <div class="col-sm-6 pl-0 ml-0">
                                <input #fileUpload type="file" style="display: none;" (change)="onChange($event)" />
                                <button type="button" class="btn btn-primary btn-sm example-full-width"
                                  matTooltip="Upload JPG,PNG,CSV or PDF image.300 x 300 required."
                                  (click)="fileUpload.click()"><i class="ai-refresh-cw me-2"></i>Upload Photo</button>

                              </div>
                              <div class="col-sm-6 pl-0 ml-0"></div>
                              <div class="col-sm-12 pl-0 ml-0">
                               
                                <div class="progress" *ngIf="progress">
                                  <div class="progress-bar" [style.width]="progress + '%'">{{progress}}%</div>
                                </div>
                              </div>

                          </div>
                        </div> -->
                        <div *ngIf="fileDataArrLength !==0" class="col-sm-6 min-high">
                                <h5 class="mt-0">Uploaded File :</h5>
                          <span class="row" *ngFor="let file of fileDataArr">
                            <a class="col-sm-11" [href]="file.patientFile" 
                              (click)="showbase64(file.patientFile)">
                              <label class="blue_col mt-0 pt-0">{{file.patientFileName}}</label></a>
                              <i class="fa fa-times col-sm-1 pointer" (click)="removePatientFileData(file._id)" 
                              aria-hidden="true"></i>
                            <!-- <i class="col-cm-2" (click)="removePatientFileData(file._id)">X</i>  -->
                          </span>
                        </div>
                        <div *ngIf="fileDataArrLength ==0" class="col-sm-6"></div>
                        <!-- <div class="col-sm-2"></div> -->
                        <div class="col-sm-3 mTop">
                          <button type="button" class="btn btn-primary  pr-0 pl-0 btn-sm example-full-width"
                            (click)="openFollowUpPopup(patientData._id)">Set Follow Up</button>
                        </div> 
                        <div class="col-sm-3 mTop">
                          <button type="button" class="btn btn-primary  pr-0 pl-0 btn-sm example-full-width"
                            (click)="openBookAppointmentPopup(patientData._id)">Book Appointment</button>
                        </div>
                        <!-- <div class="col-2">
                                <div class="form-group">
                                  <h5 class="mt-0 pt-2"></h5>
                                   <div class="col-sm-12 pl-0 mt-1 ml-0">
                                    <button type="button" class="btn btn-primary  btn-sm example-full-width"
                                     (click)="openFollowUpPopup(patientData._id)">Set Follow Up</button>
                                 </div>
                              
                                </div>
                             </div> -->
                      </div>
                    </form>
                  </div>



                  <div class="select-service card col-sm-12 boxsssss borderradius p-3  mb-2">
                    <h2>Select Service </h2>
                    <table class="table-bordered  scroll  height data-table-custom">
                      <thead>
                        <tr>
                          <th>Select</th>
                          <th class="wid-40">Services</th>
                          <th>Quantity</th>
                          <th>Service Rate</th>
                          <th>Discount (₹)</th>
                        </tr>
                      </thead>
                      <tbody formArrayName="serviceRateInfo">
                        <tr [formGroupName]="i"
                          *ngFor="let row of checkupDetailsForm.get('serviceRateInfo')['controls']; let i = index; let last = last"
                          role="row" class="odd text-center">
                          <td class="text-center  mt-0 pt-0 mb-0 pb-0">
                            <mat-checkbox formControlName="addService" class="example-margin  mt-0 pt-0 mb-0 pb-0"
                              (change)="selectService($event,i)">
                            </mat-checkbox>
                          </td>
                          <td class="text-left width1 mt-0 pt-0 mb-0 pb-0">
                            {{row.controls.serviceName.value}}
                          </td>
                          <td class=" mt-0 pt-0 mb-0 pb-0">
                            <mat-form-field class=" mt-0 pt-0 widthsize mb-0 pb-0 width2 " appearance="outline">

                              <input matInput formControlName="quantity" placeholder="Quantity" maxlength="2" />
                            </mat-form-field>
                            <mat-error class="margin_tp"
                              *ngIf="row.controls.quantity.touched && row.controls.quantity.errors?.pattern">
                              Please enter valid quantity.
                            </mat-error>
                            <mat-error class="margin_tp"
                              *ngIf="row.controls.quantity.touched && row.controls.quantity.errors?.minlength">
                              Enter minimum 1 digit quantity.
                            </mat-error>
                          </td>
                          <td class=" mt-0 pt-0 mb-0 pb-0">
                            <mat-form-field class=" widthsize  mt-0 pt-0 mb-0 pb-0" appearance="outline">

                              <input matInput formControlName="rate" placeholder="Rate" readonly />
                            </mat-form-field>

                          </td>
                          <td class=" mt-0 pt-0 mb-0 pb-0">
                            <mat-form-field class="example-full-width lable_style2 widthsize  mt-0 pt-0 mb-0 pb-0"
                              appearance="outline">

                              <input matInput formControlName="discount" placeholder="₹" maxlength="5"
                                (change)="onSelectDiscount($event,i)" />
                            </mat-form-field>
                            <mat-error class="margin_tp"
                              *ngIf="row.controls.discount.touched && row.controls.discount.errors?.pattern">
                              Please enter valid discount.
                            </mat-error>
                            <mat-error class="margin_tp"
                              *ngIf="row.controls.discount.touched && row.controls.discount.errors?.minlength">
                              Enter minimum 2 digit discount.
                            </mat-error>
                          </td>
                        </tr>
                      </tbody>

                    </table>
                    <div class="row mt-2">
                      <div class="col-sm-9" *ngIf="submitButton"></div>
                      <div class="col-sm-6" *ngIf="updateButton"></div>
                      <div class="col-sm-3 " *ngIf="submitButton">
                        <button type="button" class="btn btn-primary  btn-sm example-full-width"
                          (click)="submitCheckupDetails()">Save</button>
                      </div>
                      <div class="col-sm-3" *ngIf="updateButton">
                        <button type="button" class="btn btn-sm btn-primary example-full-width"
                          (click)="updateCheckupDetails()">Update </button>


                      </div>
                      <div class="col-sm-3" *ngIf="updateButton">

                        <button type="button" class="btn btn-sm btn-primary example-full-width"
                          (click)="cancelUpdate()">Cancel </button>

                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-4  pr-0 mr-0">
                <div class="row pl-4 pr-0 mr-0">

                  <div class="history-tab-card card col-sm-12 ml-0 pl-0 pr-0 mr-0">
                    <ul class="nav nav-pills  bg-grey mb-3 px-3" id="pills-tab" role="tablist">
                      <li class="nav-item " role="presentation">
                        <div class="nav-link" [ngClass]="{'whiteBg' : patient}" (click)="chnagetable('patient')"
                          id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#tab1" type="button" role="tab"
                          aria-controls="pills-home" aria-selected="false">
                          <div class="icon-box2 pointer">
                            <span class="material-symbols-outlined" matTooltip="Patient History">description</span>
                            <img src="../../assets/icon-bg13.svg" class="icon-bg2">
                          </div>
                        </div>
                      </li>
                      <li class="nav-item" role="presentation">
                        <div class="nav-link" [ngClass]="{'whiteBg' : payment}" (click)="chnagetable('payment')"
                          id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#tab2" type="button" role="tab"
                          aria-controls="pills-profile" aria-selected="false">
                          <div class="icon-box2 pointer">
                            <span class="material-symbols-outlined" matTooltip="Payment History">account_balance_wallet</span>
                            <img src="../../assets/icon-bg5.svg" class="icon-bg2">
                          </div>
                        </div>
                      </li>
                      <li class="nav-item" role="presentation">
                        <div class="nav-link" [ngClass]="{'whiteBg' : product}" (click)="chnagetable('product')"
                          id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#tab3" type="button" role="tab"
                          aria-controls="pills-profile" aria-selected="false">
                          <div class="icon-box2 pointer">
                            <span class="material-symbols-outlined" matTooltip="Product History">history_toggle_off</span>
                            <img src="../../assets/icon-bg7.svg" class="icon-bg2">
                          </div>
                        </div>
                      </li>
                      <li class="nav-item" role="presentation">
                        <div class="nav-link" [ngClass]="{'whiteBg' : fileShow}" (click)="chnagetable('fileShow')"
                          id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#tab4" type="button" role="tab"
                          aria-controls="pills-profile" aria-selected="false">
                          <div class="icon-box2 pointer">
                            <span class="material-symbols-outlined" matTooltip="Uploaded File">
                              upload
                            </span>
                            <!-- <span class="material-symbols-outlined">handyman</span> -->
                            <img src="../../assets/icon-bg6.svg" class="icon-bg2">
                          </div>
                        </div>
                      </li>
                      <!-- <li class="nav-item" role="presentation">
                                       <div class="nav-link" [ngClass]="{'whiteBg' : repair}" (click)="chnagetable('repair')"  id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#tab4" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">
                                          <div class="icon-box2 pointer">
                                             <span class="material-symbols-outlined">handyman</span>
                                             <img src="../../assets/icon-bg6.svg" class="icon-bg2">
                                          </div>
                                       </div>
                                    </li> -->
                      <li class="nav-item" role="presentation">
                        <div class="nav-link" id="pills-profile-tab" (click)="toPatientForm();" data-bs-toggle="pill"
                          data-bs-target="#tab5" type="button" role="tab" aria-controls="pills-profile"
                          aria-selected="false">
                          <div class="icon-box2 pointer">
                            <span class="material-symbols-outlined" matTooltip="Patient Dashboard">
                              patient_list
                              </span>
                            <img src="../../assets/icon-bg1.svg" class="icon-bg2">
                          </div>
                        </div>  
                      </li>

                      <!-- <li>
                        <a class="icon-box2" data-toggle="tooltip" data-placement="top" matTooltip="HAT"
                          (click)="hat(paientdetails.patientRefId,paientdetails.name)">
                          <span class="material-symbols-outlined">note_alt</span>
                          <img src="../../assets/icon-bg1.svg" class="icon-bg2">
                        </a>
                      </li> -->

                      <li class="nav-item" role="presentation">
                        <div class="nav-link" id="pills-profile-tab" (click)="onback();" data-bs-toggle="pill"
                          data-bs-target="#tab5" type="button" role="tab" aria-controls="pills-profile"
                          aria-selected="false">
                          <div class="icon-box2 pointer">
                            <span class="material-symbols-outlined" matTooltip="Back">arrow_back</span>
                            <img src="../../assets/icon-bg8.svg" class="icon-bg2">
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div class="tab-content p-4 scroll2 pb-3" id="pills-tabContent">
                      <div *ngIf="patient" class="tab-pane fade show active" id="tab1" role="tabpanel"
                        aria-labelledby="pills-home-tab">
                        <h3>Patient History</h3>
                        <ul class="timeline history-timeline">
                          <li *ngFor="let datee of historyData" class="mb-5">
                            <h5 class="highlighted-color">
                              {{datee.createdAt | date:'MMM d, y, - h:mm a'}}
                            </h5>
                            <div class="d-flex mb-2">
                              <span class="title-text">Complaints :</span>
                              <span>{{datee.complaints}}</span>
                            </div>
                            <div class="d-flex mb-2">
                              <span class="title-text">Observations :</span>
                              <span> {{datee.observations}}</span>
                            </div>
                            <div class="d-flex mb-2">
                              <span class="title-text">Diagnoses :</span>
                              <span> {{datee.diagnoses}}</span>
                            </div>
                            <div class="d-flex mb-2">
                              <span class="title-text">Notes : </span>
                              <span>{{datee.notes}}</span>
                            </div>
                            <div class="d-flex mb-2">
                              <span class="title-text">Category : </span>
                              <span>{{datee.trackingStatusName}}</span>
                            </div>
                          </li>
                          <li *ngIf="historyData.length == 0">
                            <h2> No Patient History </h2>
                          </li>
                        </ul>
                      </div>

                      <div *ngIf="payment" class="tab-pane show active fade" id="tab2" role="tabpanel"
                        aria-labelledby="pills-profile-tab">
                        <h3>Payment History</h3>
                        <ul class="timeline history-timeline">
                          <li *ngFor="let item of paymentData" class="mb-5">
                            <h5 class="highlighted-color">
                              {{item.createdAt | date:'MMM d, y, - h:mm a'}}
                            </h5>
                            <div class="d-flex mb-2">
                              <span class="title-text">Bill No :</span>
                              <span>{{item.billNo}}</span>
                            </div>
                            <div class="d-flex mb-2">
                              <span class="title-text">Total Amount :</span>
                              <span> {{item.totalAmount}}</span>
                            </div>
                            <div class="d-flex mb-2">
                              <span class="title-text">Balance Amount :</span>
                              <span> {{item.balanceAmount}}</span>
                            </div>
                            <div class="d-flex mb-2">
                              <span class="title-text">Status :</span>
                              <span>{{item.paymentStatus}}</span>
                            </div>
                          </li>
                          <li *ngIf="paymentData.length == 0">
                            <h2> No Payment History </h2>
                          </li>
                        </ul>
                      </div>


                      <div *ngIf="product" class="tab-pane show active fade" id="tab2" role="tabpanel"
                        aria-labelledby="pills-profile-tab">
                        <h3>Product History</h3>

                        <ul class="timeline history-timeline">
                          <li *ngFor="let item of productData" class="mb-5">

                            <h5 class="highlighted-color">
                              Model :{{item.model}}, Status : {{item.status}}
                            </h5>
                            <div class="d-flex mb-2">
                              <span class="title-text">Model :</span>
                              <span> {{item.model}}</span>
                            </div>
                            <div class="d-flex mb-2">
                              <span class="title-text">Serial No :</span>
                              <span> {{item.serialNumber}}</span>
                            </div>
                            <div class="d-flex mb-2">
                              <span class="title-text">MRP :</span>
                              <span> {{item.mrp}}</span>
                            </div>
                            <div class="d-flex mb-2">
                              <span class="title-text">Quantity :</span>
                              <span>{{item.qty}}</span>
                            </div>
                            <div class="d-flex mb-2">
                              <span class="title-text">Expiry Date :</span>
                              <span> {{item.expiryDate | date:'MMM d, y, - h:mm a'}}</span>
                            </div>
                          </li>
                          <li *ngIf="productData.length == 0">
                            <h2> No Product History </h2>
                          </li>
                        </ul>

                      </div>
                      <div *ngIf="repair" class="tab-pane show active fade" id="tab2" role="tabpanel"
                        aria-labelledby="pills-profile-tab">
                        <h3>Repair History</h3>
                        <div class="row" *ngIf="repair">
                          <div class="row col-sm-12 pr-0">
                            <div class="col-sm-6">
                              <mat-form-field class="example-full-width lable_style2 widthh" appearance="outline">

                                <mat-label class="lable_style2"> Repair From Date</mat-label>
                                <input matInput [matDatepicker]="repairDateOf" readonly placeholder="Choose a date"
                                  formControlName="repairDateOf">

                                <mat-datepicker-toggle matSuffix [for]="repairDateOf">
                                </mat-datepicker-toggle>
                                <mat-datepicker #repairDateOf></mat-datepicker>

                              </mat-form-field>
                            </div>
                            <div class="col-sm-6 pr-0">
                              <mat-form-field class="example-full-width lable_style2 widthh" appearance="outline">

                                <mat-label class="lable_style2">Repair To Date</mat-label>
                                <input matInput [matDatepicker]="repairDateto" placeholder="Choose a date"
                                  formControlName="repairDateto" (dateChange)="onSelectRepairDate()" readonly>

                                <mat-datepicker-toggle matSuffix [for]="repairDateto">
                                </mat-datepicker-toggle>
                                <mat-datepicker #repairDateto></mat-datepicker>

                              </mat-form-field>
                            </div>
                            <div class="col-sm-12 pr-0">
                              <mat-form-field class="example-full-width lable_style2 widthh" appearance="outline">
                                <mat-label class="lable_style2">Select Repair No With Date</mat-label>
                                <mat-select formControlName="repairNo">

                                  <mat-option *ngFor="let repair of repairList" [value]="repair._id"
                                    (click)="onSelectRepairNo(repair._id)">
                                    Repair No.-{{ repair.repairId }} : {{repair.repairDate | date}} </mat-option>

                                </mat-select>

                              </mat-form-field>
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <h1 class="titlecolor"> Repair History</h1>
                          </div>
                          <div class="col-sm-12 ">
                            <div *ngIf="statusArr.length == 0">
                              <h2 class="pl-3"> No Records</h2>
                            </div>
                            <mat-stepper orientation="vertical" [linear]="!isLinear" #stepper>
                              <mat-step *ngFor="let item of statusArr;let i = index;"
                                [completed]="item.comments.length > 0 ? true : false">
                                <!-- <form> -->

                                <ng-template matStepLabel>{{item.labelOne}} - {{item.datetime | date:"medium"}}
                                  <br>
                                  <label>{{item.labeltwo}}</label>
                                </ng-template>

                                <h2 *ngIf="item.comments.length > 0"> {{item.comments}}</h2>
                              </mat-step>
                              <ng-template matStepperIcon="edit">
                                <mat-icon>check</mat-icon>
                              </ng-template>
                              <ng-template matStepperIcon="done">
                                <mat-icon>done_all</mat-icon>
                              </ng-template>
                            </mat-stepper>
                          </div>
                        </div>

                      </div>
                      <div *ngIf="fileShow" class="tab-pane show active fade" id="tab2" role="tabpanel"
                        aria-labelledby="pills-profile-tab">
                        <h3>File Upload Data</h3>

                        <ul class="timeline history-timeline">
                          <li *ngFor="let item of fileDataArr" class="mb-5">

                            <h5 class="highlighted-color">
                              {{item.createdAt | date:'MMM d, y, - h:mm a'}}
                            </h5>
                            <div class="d-flex mb-2">
                              <span class="title-text">File Name :</span>
                              <span> {{item.patientFileName}}</span>
                            </div>
                            <div class="d-flex mb-2">
                              <span class="title-text">file Upload Date :</span>
                              <span> {{item.fileUploadDate | date:'MMM d, y'}}</span>
                            </div>

                          </li>
                          <li *ngIf="fileDataArr.length == 0">
                            <h2> No Product History </h2>
                          </li>
                        </ul>

                      </div>

                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-sm-8 row pl-4 pr-4">
                 
                     <div class="col-sm-5">
                        <div class="row"> 
                            <div class="card divbodyf boxsssss borderradius p-3 mb-2 fixeds">
                                <div class="col-sm-12">
                                    <div class="col-sm-8 offset-sm-2 text-center">
                                        <h2 class="col-sm-12">{{paientdetails.name}}  </h2> 
                                   <label *ngIf="patientData.decease == 'Yes'" class="redColor">Patient is Decease</label>
                                      
                                    </div>
                                </div> 
                                <div class="col-sm-12">
                                    <div class="row pb-3">
                                        <mat-list  class="fhfhff">
                                          <div class="row col-sm-12">
                                        <mat-divider class="col-sm-12"></mat-divider>
                                     
                                              <div class="row pt-2 pb-2 titlecolor col-sm-12">
                                               
                                                <label class="col-sm-6">Gender:</label>
                                                <label class="col-sm-6">{{patientData.sex}}</label>
                                                </div>
                                            
                                            <mat-divider class="col-sm-12"></mat-divider>
                                        
                                              <div class="row titlecolor col-sm-12 mt-1">
                                                <div class="col-sm-6">
                                                <label>Reference</label>
                                                <label>Audiologists: </label>
                                                </div>
                                                <div class="col-sm-6">
                                                <label class=" mt-2">{{paientdetails.doctorName}}</label>
                                                </div>
                                                </div>
                                         
                                           <mat-divider class="col-sm-12"></mat-divider>
                                        
                                            <div class="row pt-2 pb-2 titlecolor col-sm-12">
                                             
                                              <label class="col-sm-6">DOB:</label>
                                              <label class="col-sm-6">{{patientData.dob | date: 'dd/MM/yyyy'}}</label>
                                              </div>
                                          
                                           <mat-divider class="col-sm-12"></mat-divider>
                                           
                                            <div class="row pt-2 pb-2 titlecolor col-sm-12">
                                             
                                              <label class="col-sm-6">Contact:</label>
                                              <label class="col-sm-6">{{patientData.mobile1}}</label>
                                              </div>
                                             
                                           <mat-divider class="col-sm-12"></mat-divider>
                                        
                                            <div class="row pt-2 pb-2 titlecolor col-sm-12">
                                             
                                              <label class="col-sm-6">Address:</label>
                                              <label class="col-sm-6">{{patientData.streetAddress}}</label>
                                              </div>
                                              
                                              
                                           <mat-divider class="col-sm-12"></mat-divider>
                                          
                                            <div class="row pt-2 pb-2 titlecolor col-sm-12">
                                             
                                              <label class="col-sm-6">Age:</label>
                                              <label class="col-sm-6">{{patientData.age}}</label>
                                              </div>
                                            
                                              <mat-divider class="col-sm-12"></mat-divider>
                                    
                                              <div class="row pt-2 pb-2 titlecolor col-sm-12">
                                                  <div class="col-sm-2">
                                                    <img width=40rem src="../../assets/information.png">
                                                    </div>
                                                  <div class="col-sm-7 ">
                                                  <label class="ml-1 mt-2 mr-2">No.Of Visit:</label>
                                                  </div>
                                                  <div class="col-sm-3">
                                                  <label class="text-right mt-2 ml-3"> {{NoOfVisits}}</label>
                                                  </div>
                                                  </div>

                                                  <mat-divider class="col-sm-12"></mat-divider>
                                    
                                                  <div class="row pt-2 pb-2 titlecolor col-sm-12">
                                                  <div class="col-sm-2">
                                                    <img width=40rem src="../../assets/growth.png">
                                                    </div>
                                                  <div class="col-sm-7">
                                                  <label class="ml-1 mt-2 mr-2">Total Business :</label>
                                                  </div>
                                                  <div class="col-sm-3">
                                                  <label class="text-right mt-2 ml-3"> {{totalAmount}}</label>
                                                  </div>
                                                  </div>
                                             
                                                  <mat-divider class="col-sm-12"></mat-divider>
                                    
                                                  <div class="row pt-2 pb-2 titlecolor col-sm-12">
                                                <div class="col-sm-2">
                                                  <img width=40rem src="../../assets/change.png">
                                                  </div>
                                                <div class="col-sm-7">
                                                <label class="ml-1 mt-2 mr-2">No.Of Reference :</label>
                                                </div>
                                                <div class="col-sm-3">
                                                <label class="text-right mt-2 ml-3"> {{refData}}</label>
                                                </div>
                                                </div>
                                               
                                           <mat-divider class="col-sm-12"></mat-divider>
                                            </div>
                                          </mat-list>
                                    </div>
                                </div>
                            </div>
                        </div>  
                    </div>
                    <div class="col-sm-7 pl-4 pr-2">
                        <div class="row">
                            <div class="card boxsssss divbodyf borderradius p-3 mb-2 fixeds">
                         
                                <div class="col-sm-12">   
                                    <div class="row">
                                        <div class="col-sm-3 p-0">
                                            <h1 class="titlecolor2"> Checkup Details </h1>
                                        </div>
                                       <div class="col-sm-12 text-right">
                                        <img title="Back" class="p-2" width=50rem src="../../assets/back.png" [routerLink]="['/home/opt/dashboard']">
                                        <img title="Patient History" matToolTip="Patient History" class="p-2" width=50rem src="../../assets/contact-form.png" (click)="chnagetable('patient')">
                                        <img title="Payment History" matToolTip="Payment History" class="p-2" width=50rem src="../../assets/get-money.png" (click)="chnagetable('payment')">
                                        <img  title="Product History" matToolTip="Product History" class="p-2" (click)="chnagetable('product')" width=50rem src="../../assets/qa.png">
                                        <img title="Repair History" matToolTip="Repair History" class="p-2" width=50rem src="../../assets/repair.png" (click)="chnagetable('repair')">
                                   
                                       </div>
                                        <div class="card col-sm-12">
                                            <h3 class="titlecolor"> Add Checkup Details </h3>
                                            <div class="row mt-2">
                                               
                                                  
                                                     <div class="col-sm-5">
                                                      <label class="header mt-3">Complaints:</label>
                                                     </div>
                                                     <div class="col-sm-7">
                                                      <mat-form-field class="example-full-width" appearance="outline">
                                                        <mat-label>Complaints</mat-label>
                                                        <textarea matInput formControlName="complaints" placeholder="Complaints"></textarea>
                                                      </mat-form-field>
                                                     </div>
                                                  
                                                     <div class="col-sm-5">
                                                      <label class="header mt-3">Observations:</label>
                                                     </div>
                                                     <div class="col-sm-7">
                                                      <mat-form-field class="example-full-width" appearance="outline">
                                                        <mat-label>Observations</mat-label>
                                                        <textarea matInput formControlName="observations" placeholder="Observations"></textarea>
                                                      </mat-form-field>
                                                     </div>

                                                     <div class="col-sm-5">
                                                      <label class="header mt-3">Diagnosis:</label>
                                                     </div>
                                                     <div class="col-sm-7">
                                                      <mat-form-field class="example-full-width" appearance="outline">
                                                        <mat-label>Diagnosis</mat-label>
                                                        <textarea matInput formControlName="diagnoses" placeholder="Diagnoses"></textarea>
                                                      </mat-form-field>
                                                     </div>

                                                     <div class="col-sm-5">
                                                      <label class="header mt-3">Notes:</label>
                                                     </div>
                                                     <div class="col-sm-7">
                                                      <mat-form-field class="example-full-width" appearance="outline">
                                                        <mat-label>Notes</mat-label>
                                                        <textarea matInput formControlName="notes" placeholder="Notes"></textarea>
                                                      </mat-form-field>
                                                     </div>
                                                     <div class="col-sm-5">
                                                      <label class="header mt-3">Follow Up:</label>
                                                     </div>
                                                     <div class="col-sm-7">
                                                  
                                                        <img matTooltip="Book Appointment" (click)="openBookAppointmentPopup()" width="30" src="../../assets/medical-appointment.png">  
                                                     
                                                     </div>
                                                     <div class="col-sm-5">
                                                      <label class="header mt-3">Follow Up Reason:</label>
                                                     </div>
                                                     <div class="col-sm-7">
                                                      <div class="row">
                                                        <div class="col-sm-10 pr-1">
                                                      <mat-form-field class="example-full-width lable_style2" appearance="outline" >
                                                          <mat-label>Follow Up Reasons</mat-label>
                                                          <mat-select #select3 formControlName="followupReasons">
                                                            <mat-select-filter *ngIf="select3.focused" [placeholder]="'Filter'" [displayMember]="'followupReason'" [array]="followupReasonList"
                                                            (filteredReturn)="filteredList1 =$event"></mat-select-filter>
                                                            <mat-option *ngFor="let item of filteredList1" [(value)]="item.followupReason">
                                                              {{item.followupReason}}
                                                            </mat-option>
                                                          </mat-select>
                                                        </mat-form-field>
                                                      
                                                        </div>
                                                        <div class="col-sm-2 pl-1 mt-2">
                                                          <img (click)="openFollowUpReasonPopUp();" matTooltip="Add Follow Up Reason" width="25" src="../../assets/add_img.png"> 
                                                        </div>
                              
                                                        </div>
                                                    </div>
                                                </div>
                                           
                                        </div>
                                     
                                    </div>
                                </div>
                             
                            </div>
                        </div>
                    </div>
                   

                    <div class="col-sm-12 pl-3 pr-2">
                      <div class="row">
                          <div class="card boxsssss col-sm-12 borderradius pl-3 pt-3 pr-0 pb-3 mb-2 fixeds">
                            
                                          <h1 class="titlecolor"> Select Service </h1>
                                          <div class="row pr-0 col-sm-12">
                                           
                                            <table class="table scroll  height p-0 table-hover table-striped table-bordered">
                                              <thead> 
                                                <tr class="text-center">
                                                  <th>Select</th>
                                                  <th class="width1">Services</th>
                                                  <th class="width2">Quantity</th>
                                                  <th>Service Rate</th>
                                                  <th>Discount (₹)</th>
                                                </tr>
                                              </thead>
                                            
                                            <tbody formArrayName="serviceRateInfo">
                                              <tr [formGroupName]="i"
                                                *ngFor="let row of checkupDetailsForm.get('serviceRateInfo')['controls']; let i = index; let last = last" role="row"
                                                class="odd text-center">
                                                <td class="text-center">
                                                  <mat-checkbox formControlName="addService" class="example-margin" (change)="selectService($event,i)">
                                                    </mat-checkbox>
                                                </td>
                                                <td class="text-left width1 ">
                                                  {{row.controls.serviceName.value}}
                                                </td>
                                                <td  class="p-2">
                                                  <mat-form-field class=" width2 " appearance="outline">
                                                    <mat-label class="lable_style2">Quantity</mat-label>
                                                    <input matInput formControlName="quantity" placeholder="Quantity" maxlength="2"/>
                                                  </mat-form-field>
                                                  <mat-error class="margin_tp" *ngIf="row.controls.quantity.touched && row.controls.quantity.errors?.pattern">
                                                    Please enter valid quantity.
                                                  </mat-error>
                                                  <mat-error class="margin_tp" *ngIf="row.controls.quantity.touched && row.controls.quantity.errors?.minlength">
                                                    Enter minimum 1 digit quantity.
                                                  </mat-error>
                                                </td>
                                                <td  class="p-2">
                                                  <mat-form-field class=" widthsize " appearance="outline">
                                                    <mat-label class="lable_style2">Rate</mat-label>
                                                    <input matInput formControlName="rate" placeholder="Rate" readonly/>
                                                  </mat-form-field>
                                                
                                                </td>
                                                <td class="p-2" >
                                                  <mat-form-field class="example-full-width lable_style2 widthsize " appearance="outline">
                                                    <mat-label class="lable_style2">Discount</mat-label>
                                                    <input matInput formControlName="discount" placeholder="₹" maxlength="5" (change)="onSelectDiscount($event,i)" />
                                                  </mat-form-field>
                                                  <mat-error class="margin_tp" *ngIf="row.controls.discount.touched && row.controls.discount.errors?.pattern">
                                                    Please enter valid discount.
                                                  </mat-error>
                                                  <mat-error class="margin_tp" *ngIf="row.controls.discount.touched && row.controls.discount.errors?.minlength">
                                                    Enter minimum 2 digit discount.
                                                  </mat-error>
                                                </td>
                                              </tr> 
                                            </tbody>
                                            </table> 
                                          </div> 
                                          <div class="row pr-3">
                                            <div class="col-sm-12" *ngIf="submitButton"></div>
                                            <div class="col-sm-6" *ngIf="updateButton"></div>
                                            <div class="col-sm-3 " *ngIf="submitButton">
                                             <button type="button" class="btn btn-primary  btn-sm example-full-width" (click)="submitCheckupDetails()">Save</button>
                                            </div>
                                            <div class="col-sm-3" *ngIf="updateButton" >
                                              <button type="button" class="btn btn-sm btn-primary example-full-width"
                                              (click)="updateCheckupDetails()" >Update </button>
                                             
                                              
                                             </div>
                                             <div class="col-sm-3" *ngIf="updateButton" >
                                          
                                              <button type="button" class="btn btn-sm btn-primary example-full-width"
                                              (click)="cancelUpdate()" >Cancel </button>
                                              
                                             </div>
                                           
                                          </div>   
                                     
                            </div>
                            </div>
                            </div>
                    </div> -->
          <!-- <div class="col-sm-4 pl-4 pr-4">
                        <div class="row">
                            <div class="card divbody boxsssss borderradius   p-3 mb-2 scroll" >
                                <div class="col-sm-12">
                                    <div class="row" *ngIf="patient">
                                        <div class="col-sm-12">
            
                                            <h1 class="titlecolor"> Patient History</h1>
                                            
                                        </div>
                                        <div class="col-sm-12 p-0">
                                            <div id="accordion">
                                              <div id="accordion">
                                            
                                                <div class="card" *ngFor="let datee of historyData">
                                                  <div class="card-header" >
                                                    <a class="collapsed card-link"  data-toggle="collapse" href="#{{datee._id}}">
                                                    {{datee.createdAt | date:'medium'}}
                                                  
                                                    </a>
                                                  </div>
                                                  <div id="{{datee._id}}" class="collapse" data-parent="#accordion">
                                                    <div class="card-body">
                                                      <span class="colorp">Complaints :</span> {{datee.complaints}}
                                                      <br>
                                                      <span class="colorp">Observations :</span> {{datee.observations}}
                                                      <br>
                                                      <span class="colorp">Diagnoses :</span>  {{datee.diagnoses}}
                                                     <br>
                                                     <span class="colorp">Notes :</span> {{datee.notes}}
                                                    </div>
                                                  </div>
                                                </div>
                                                <div *ngIf="historyData.length == 0">
                                                  <h2> No Patient History Records </h2>
                                                </div>
                                               
                                              </div>
                                              </div>
                                            
                                        </div>
                                    </div>
                                    
                                    <div class="row" *ngIf="repair">
                                      <div class="row col-sm-12 pr-0">
                                        <div class="col-sm-6">
                                          <mat-form-field class="example-full-width lable_style2 widthh" appearance="outline">
                                            
                                            <mat-label class="lable_style2"> Repair From Date</mat-label>
                                            <input matInput [matDatepicker]="repairDateOf" readonly
                                            placeholder="Choose a date" formControlName="repairDateOf"
                                               >
                                
                                            <mat-datepicker-toggle matSuffix [for]="repairDateOf">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #repairDateOf ></mat-datepicker>
                                        
                                          </mat-form-field>
                                        </div>
                                        <div class="col-sm-6 pr-0">
                                          <mat-form-field class="example-full-width lable_style2 widthh" appearance="outline">
                                            
                                            <mat-label class="lable_style2">Repair To Date</mat-label>
                                            <input matInput [matDatepicker]="repairDateto" placeholder="Choose a date" formControlName="repairDateto"
                                              (dateChange)="onSelectRepairDate()" readonly>
                                        
                                            <mat-datepicker-toggle matSuffix [for]="repairDateto">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #repairDateto ></mat-datepicker>
                                        
                                          </mat-form-field>
                                        </div>
                                        <div class="col-sm-12 pr-0">
                                          <mat-form-field class="example-full-width lable_style2 widthh" appearance="outline">
                                            <mat-label class="lable_style2">Select Repair No With Date</mat-label>
                                            <mat-select formControlName="repairNo">
                                        
                                              <mat-option  *ngFor="let repair of repairList" [value]="repair._id" (click)="onSelectRepairNo(repair._id)">
                                                Repair No.-{{ repair.repairId }} : {{repair.repairDate | date}} </mat-option>
                                        
                                            </mat-select>
                                        
                                          </mat-form-field>
                                      </div>
                                      </div>
                                        <div class="col-sm-12">
                                            <h1 class="titlecolor"> Repair History</h1>
                                        </div>
                                        <div class="col-sm-12 ">
                                          <div *ngIf="statusArr.length == 0">
                                            <h2 class="pl-3"> No Records</h2>
                                          </div>
                                          <mat-stepper  orientation="vertical" [linear]="!isLinear" #stepper>  
                                            <mat-step  *ngFor="let item of statusArr;let i = index;" [completed]="item.comments.length > 0 ? true : false">
                                               
                                              <ng-template matStepLabel>{{item.labelOne}} - {{item.datetime | date:"medium"}} 
                                                <br>
                                                <label>{{item.labeltwo}}</label>
                                              </ng-template>
                                              
                                              <h2 *ngIf="item.comments.length > 0"> {{item.comments}}</h2>
                                            </mat-step>
                                            <ng-template matStepperIcon="edit">
                                              <mat-icon>check</mat-icon>
                                            </ng-template>
                                            <ng-template matStepperIcon="done">
                                              <mat-icon>done_all</mat-icon>
                                            </ng-template>
                                          </mat-stepper>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="payment">
                                      <div class="col-sm-12">
          
                                          <h1 class="titlecolor"> Payment History</h1>
                                          
                                      </div>
                                      <div class="col-sm-12 p-0">
                                          <div id="accordion">
                                            <div id="accordion">
                                          
                                              <div class="card" *ngFor="let item of paymentData">
                                                <div class="card-header" >
                                                  <a class="collapsed card-link"  data-toggle="collapse" href="#{{item._id}}">
                                                  {{item.createdAt | date:'medium'}}
                                                
                                                  </a>
                                                </div>
                                                <div id="{{item._id}}" class="collapse" data-parent="#accordion">
                                                  <div class="card-body">
                                                    <span class="colorp">Bill No :</span> {{item.billNo}}
                                                    <br>
                                                    <span class="colorp">Total Amount :</span> {{item.totalAmount}}
                                                    <br>
                                                    <span class="colorp">Balance Amount :</span>  {{item.balanceAmount}}
                                                   <br>
                                                   <span class="colorp">Status :</span> {{item.paymentStatus}}
                                                  </div>
                                                </div>
                                              </div>
                                              <div *ngIf="paymentData.length == 0">
                                                <h2> No Payment History Records </h2>
                                              </div>
                                             
                                            </div>
                                            </div>
                                          
                                      </div>
                                  </div>
                                  <div class="row" *ngIf="product">
                                    <div class="col-sm-12">
        
                                        <h1 class="titlecolor"> Product History</h1>
                                        
                                    </div>
                                    <div class="col-sm-12 p-0">
                                        <div id="accordion">
                                          <div id="accordion">
                                        
                                            <div class="card" *ngFor="let item of productData">
                                              <div class="card-header" >
                                                <a class="collapsed card-link"  data-toggle="collapse" href="#{{item._id}}">
                                               Model :{{item.model}}, Status : {{item.status}}
                                               
                                                </a>
                                              </div>
                                              <div id="{{item._id}}" class="collapse" data-parent="#accordion">
                                                <div class="card-body">
                                                  <span class="colorp">Model :</span> {{item.model}}
                                                  <br>
                                                  <span class="colorp">Serial No. :</span> {{item.serialNumber}}
                                                  <br>
                                                  <span class="colorp">MRP :</span>  {{item.mrp}}
                                                 <br>
                                                 <span class="colorp">Quantity :</span> {{item.qty}}
                                                 <br>
                                                 <span class="colorp">Expiry Date :</span> {{item.expiryDate | date:'dd/MM/yyyy'}}
                                                </div>
                                              </div>
                                            </div>
                                            <div *ngIf="productData.length == 0">
                                              <h2> No Payment History Records </h2>
                                            </div>
                                           
                                          </div>
                                          </div>
                                        
                                    </div>
                                </div>


                                </div>
                            </div>
                        </div>
                    </div> -->











        </div>


      </div>
    </div>
  </form>
</div>