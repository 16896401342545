<div class="container-fluid bgcolor2" [formGroup]="inwardOutwardForm">
  <form  [formGroup]="inwardOutwardForm" autocomplete="off" novalidate>
    <div  class="row">
        <div class="col-sm-1">

        </div>
        <div class="col-sm-11">
          <div class="row pl-0 ">
            <div class="col-12 pl-0 ">
              <div class="row ">
              <div class="col-sm-9">
                  <h1 class="pt-2 pl-0" id="toInwardOutwardForm">Inward Outward</h1>
                  </div>
                  <div class="col-sm-3 mt-1"> 
                    <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                      <a (click)="onback()" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">arrow_back</span>Back</a>
                      <a (click)="toInwardOutwardLst()" class="d-flex align-items-center ms-3 pr-2 mt-2"><span class="material-symbols-outlined pr-2">reorder</span>List</a>
                 </div>
            </div>
          </div>
         </div>
         </div>  

            <div class="row">
                <div class="col-sm-12 pr-4">
                    <div class="row">
                        <div class="card divbody mb-3 borderradius p-2">
                           
                            <div class="col-sm-12">
                                <div class="row">
                                    <div class="col-sm-1 mt-3 ">
                           
                                        <!-- <mat-form-field *ngIf="userType !== 'Super Admin'" class="example-full-width" appearance="outline">
                                          <mat-label> Id</mat-label>
                                          <input matInput formControlName="branchWiseId" readonly  />   
                                        </mat-form-field>
                                        <mat-form-field *ngIf="userType == 'Super Admin'" class="example-full-width" appearance="outline">
                                          <mat-label> Id</mat-label>
                                          <input matInput [value]="superBranchId" readonly  />
                                          </mat-form-field> -->
                          <mat-label class="center" ngDefaultControl formControlName="branchWiseId" *ngIf="userType !== 'Super Admin'" >{{bId}}</mat-label>
                          <mat-label class="center" ngDefaultControl [value]="superBranchId" *ngIf="userType == 'Super Admin'" >{{superBranchId}}</mat-label>
                                      </div>

                                    <div class="col-sm-2">
                                        <mat-form-field class="example-full-width " appearance="outline">
                                            <mat-label>Date</mat-label>
                                            <input required matInput placeholder="Choose a date" 
                                            formControlName="date" readonly>
                             
                                             <!-- <mat-datepicker-toggle matSuffix [for]="date">
                                             </mat-datepicker-toggle>
                                             <mat-datepicker #date></mat-datepicker> -->
                                           
                                        </mat-form-field>
                                        <mat-error
                                        *ngIf="inwardOutwardForm.controls['date'].touched && inwardOutwardForm.controls['date'].errors?.required">
                                        This field is mandatory.
                                      </mat-error>
                                    </div>
                                   
                                      <!-- <div class="col-sm-3 mt-3">
                                        <label class="header">Inward/Outward :</label>
                                   
                                        </div> -->
                                    <div class="col-sm-4">
                                      <div class="row">
                                        <div class="col-sm-4">
                                          <h5 class="mt-3"><span class="required-asterisk mr-1">*</span>Inward/Outward:</h5>
                                        <!-- <label class="header mt-2"><span class="required-asterisk mr-1">*</span>Inward/Outward:</label> -->
                                     </div>
                                        <div class="col-sm-8">  
                                      
                                        <mat-radio-group formControlName="inwardOutward" class="example-full-width" aria-label="Select an option">
                                            <div class="row mt-3">
                                                <div class="col-sm-4 p-1">
                                            <mat-radio-button (click)="InwardOutwardClick('Inward');" value="Inward">Inward</mat-radio-button>
                                            </div>
                                            <div class="col-sm-4 p-1">
                                          <mat-radio-button  (click)="InwardOutwardClick('Outward');" value="Outward">Outward</mat-radio-button>
                                          </div>
                                          
                                      </div>
                                        </mat-radio-group>
                                        <mat-error
                                        *ngIf="inwardOutwardForm.controls['inwardOutward'].touched && inwardOutwardForm.controls['inwardOutward'].errors?.required">
                                        This field is mandatory.
                                      </mat-error>
                                       </div>
                                      </div>
                                      </div>
                                      <div class="col-sm-5"></div>
                                      <div class="col-sm-3  ">
                                        <div class="row">
                                          <div class="col-sm-10 pr-1">
                                        <mat-form-field class="example-full-width lable_style2" appearance="outline" >
                                            <mat-label>From City</mat-label>
                                            <mat-select required formControlName="fromInwardOutward" [disabled]="disableFromCity">
                                              <mat-option
                                                  *ngFor="let item of cityList"
                                                  [(value)]="item._id"
                                                  (click)="onSelectFromCity(item._id,item.cityName)"
                                                  >{{ item.cityName }}</mat-option
                                                >
                                            </mat-select>
                                          </mat-form-field>
                                          <mat-error
                                        *ngIf="inwardOutwardForm.controls['fromInwardOutward'].touched && inwardOutwardForm.controls['fromInwardOutward'].errors?.required">
                                        This field is mandatory.
                                      </mat-error> 
                                          </div>
                                          <div class="col-sm-2 mt-2 mTop">
                                            <i (click)="openFromCityPopUp();" matTooltip="Add From City" class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                                          </div>
                
                                          </div>
                                      </div>
                                      <!-- <div class="col-sm-3 ">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>To</mat-label>
                                          <input matInput formControlName="toInwardOutward"   />   
                                        </mat-form-field>    
                                        <mat-error
                                        *ngIf="inwardOutwardForm.controls['toInwardOutward'].touched && inwardOutwardForm.controls['toInwardOutward'].errors?.required">
                                        This field is mandatory.
                                      </mat-error>           
                                      </div> --> 
                                      <div class="col-sm-3">
                                        <div class="row">
                                          <div class="col-sm-10 pr-1">
                                        <mat-form-field class="example-full-width lable_style2" appearance="outline" >
                                            <mat-label>To City</mat-label>
                                            <mat-select required formControlName="toInwardOutward" [disabled]="disableToCity">
                                              <mat-option
                                                  *ngFor="let item of tocityList"
                                                  [(value)]="item._id"
                                                  (click)="onSelectToCity(item._id,item.cityName)"
                                                  >{{ item.cityName }}</mat-option
                                                >
                                            </mat-select>
                                          </mat-form-field>
                                          <mat-error
                                        *ngIf="inwardOutwardForm.controls['toInwardOutward'].touched && inwardOutwardForm.controls['toInwardOutward'].errors?.required">
                                        This field is mandatory.
                                      </mat-error>
                                          </div>
                                          <div class="col-sm-2 mt-2 mTop">
                                            <i (click)="openToCityPopUp();" matTooltip="Add To City" class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                                          </div>
                                          </div> 
                                      </div>

                                      <div class="col-sm-3 " *ngIf="courierServiceCreate == true">
                                        <div class="row">
                                          <div class="col-sm-10 pr-1">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>Courier Service Provider</mat-label>
                                          <!-- <input matInput formControlName="courierRefId"   />    -->
                                         
                                            <mat-select required #select1 formControlName="courierRefId" >
                                              <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'serviceProviderName'"
                                              [array]="courierList"  (filteredReturn)="filteredListCourier =$event"></mat-select-filter>
                                              <mat-option *ngFor="let item of filteredListCourier" [value]="item._id"
                                              (click)="courierClick(item._id,item.serviceProviderName);" >
                                               {{ item.serviceProviderName }}</mat-option>
                                              </mat-select>
                                          
                                        </mat-form-field> 
                                        <mat-error
                                        *ngIf="inwardOutwardForm.controls['courierRefId'].touched && inwardOutwardForm.controls['courierRefId'].errors?.required">
                                        This field is mandatory.
                                      </mat-error> 
                                      </div>
                                      <div class="col-sm-2 mt-2 mTop">
                                        <i (click)="openCourierServicePopUp();" matTooltip="Add New Service Provider" class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                                        <!-- <img (click)="openCourierServicePopUp();" matTooltip="Add New Courier Service Provider " width="25" src="../../assets/add_img.png">  -->
                                      </div>
                                      </div>             
                                      </div>
                                      <div class="col-sm-3 " *ngIf="courierServiceCreate == false">
                                        
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>Courier Service Provider</mat-label>
                                          <!-- <input matInput formControlName="courierRefId"   />    -->
                                         
                                            <mat-select #select1 formControlName="courierRefId" >
                                              <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'serviceProviderName'"
                                              [array]="courierList"  (filteredReturn)="filteredListCourier =$event"></mat-select-filter>
                                              <mat-option *ngFor="let item of filteredListCourier" [value]="item._id"
                                              (click)="courierClick(item._id,item.serviceProviderName);" >
                                               {{ item.serviceProviderName }}</mat-option>
                                              </mat-select>
                                          
                                        </mat-form-field> 
                                        <mat-error
                                        *ngIf="inwardOutwardForm.controls['courierRefId'].touched && inwardOutwardForm.controls['courierRefId'].errors?.required">
                                        This field is mandatory.
                                      </mat-error> 
                                                  
                                      </div>
                                      <div class="col-sm-3 ">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>Docket No.</mat-label>
                                          <input matInput formControlName="docketNo" maxlength="10"  />   
                                        </mat-form-field>      
                                        <!-- <mat-error 
                                        *ngIf="inwardOutwardForm.controls['docketNo'].touched && inwardOutwardForm.controls['docketNo'].errors?.required">
                                      Mandatory field.
                                      </mat-error> -->
                                        <mat-error 
                                          *ngIf="inwardOutwardForm.controls['docketNo'].touched && inwardOutwardForm.controls['docketNo'].errors?.pattern">
                                       Please enter valid Docket No.
                                        </mat-error>        
                                      </div>
                                      <div class="col-sm-3 ">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>Receiver Name</mat-label>
                                          <input matInput required formControlName="reciverName" />   
                                        </mat-form-field>      
                                  
                                        <mat-error
                                        *ngIf="inwardOutwardForm.controls['reciverName'].touched && inwardOutwardForm.controls['reciverName'].errors?.required">
                                            This field is mandatory.
                                      </mat-error>
                                      <mat-error class="margin_tp"
                                        *ngIf="inwardOutwardForm.controls['reciverName'].touched && inwardOutwardForm.controls['reciverName'].errors?.pattern">
                                            Please enter valid name.
                                      </mat-error>   
                                      </div>
                                      <div class="col-sm-3">
                                        <div class="row">
                                          <div class="col-sm-10 pr-1">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>Item
                                          </mat-label>
                                          <input required matInput placeholder="Item"  formControlName="item" readonly />
                                        </mat-form-field>
                                        <mat-error
                                        *ngIf="inwardOutwardForm.controls['item'].touched && inwardOutwardForm.controls['item'].errors?.required">
                                        This field is mandatory.
                                      </mat-error>
                                        </div>
                                        <div class="col-sm-2 mt-2 mTop">
                                          <i (click)="openPopUp();" matTooltip="Add Item" class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                                        </div>
                                        </div>
                                      </div>

                                    
                                  
                                      <div class="col-sm-3">
                                        <div class="row"> 
                                          <div class="col-sm-10 pr-1">
                                            <mat-form-field class="example-full-width" appearance="outline">
                                              <mat-label>Patient Name</mat-label>
                                              <input (click)="openDepartmentPopUp();" matInput readonly placeholder="Patient Name" formControlName="patientName" />
                                              </mat-form-field> 
                                              <mat-error
                                              *ngIf="inwardOutwardForm.controls['patientName'].touched && inwardOutwardForm.controls['patientName'].errors?.required">
                                              This field is mandatory.
                                            </mat-error>
                                            </div>
                                            <div class="col-sm-2 mt-2 mTop">
                                              <i (click)="openPatientPopUp();" matTooltip="Add New Patient" class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                                            </div>   
                                            </div>
                                            </div>
                                      <div class="col-sm-3">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>Remark</mat-label>
                                  
                                          <textarea matInput formControlName="remark"
                                          cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                          cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" ></textarea>
                                        </mat-form-field>
                                      </div>
                                      <div class="col-sm-5"></div>
                                      <div class="col-sm-12"> 
                             
                                        <div class="row col-sm-12">
                                            <h5>Add Signature :</h5>
                                          </div>
                                          </div>
                                      <div class="col-sm-3 pr-1">
                                       <canvas (click)="canvasClick()" #canvas (touchstart)="startDrawing($event)" (touchmove)="moved($event)"></canvas>
</div>
<div class="col-sm-2">
  <div *ngIf="signatureImg !== undefined" class="row col-sm-12">
    <div class="col-sm-12">
<button class="btn btn-primary btn-sm mt-2 example-full-width"  type="button" 
 (click)="clearPad()">Clear Signature</button>
</div>
<div class="col-sm-12">
  <button class="btn btn-primary btn-sm mt-2 example-full-width" 
  type="button"  color="secondary" (click)="savePad()">Save Signature</button>
  </div>
</div>

</div>

<div class="col-sm-3"> 
<img src='{{ signatureImg }}' />
</div>
                                      <div *ngIf="isSuperAdmin" class="col-sm-12">
                            
                                        <div class="row">
                                          <div class="col-sm-12 mt-1 ">
                                        <label class="header">Select Branch :</label>
                                          </div>
                                          </div>
                                          <div class="row">
                    
                                      <div class="col-sm-3" *ngIf="branchCreate == true">
                                        <div class="row">
                                          <div class="col-sm-10 pr-1">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>Branch 
                                            <!-- <span class="required-asterisk">*</span> -->
                                          </mat-label>
                                          <mat-select #select1 (click)="fetchBranchList();" formControlName="branchRefId" >
                                            <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'branchName'"
                                            [array]="BranchList"  (filteredReturn)="filteredListBranch =$event"></mat-select-filter>
                                            <mat-option *ngFor="let item of filteredListBranch" [value]="item._id"
                                            (click)="branchClick(item._id,item.branchName);" >
                                             {{ item.branchName }}</mat-option>
                                            </mat-select>
                                            
                                        </mat-form-field>
                                        <mat-error class="margin_tp"
                                        *ngIf="inwardOutwardForm.controls['branchRefId'].touched && inwardOutwardForm.controls['branchRefId'].errors?.required">
                                        This field is mandatory.
                                      </mat-error> 
                                      </div>
                                      <div class="col-sm-2 mt-2 mTop">
                                        <i (click)="openBranchPopUp();" matTooltip="Add New Branch" class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                                      </div>
                                      </div>
                                      </div>
                                      <div class="col-sm-3" *ngIf="branchCreate == false">
                                          
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-label>Branch 
                                            <!-- <span class="required-asterisk">*</span> -->
                                          </mat-label>
                                          <mat-select #select1 (click)="fetchBranchList();" formControlName="branchRefId" >
                                            <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'" [displayMember]="'branchName'"
                                            [array]="BranchList"  (filteredReturn)="filteredListBranch =$event"></mat-select-filter>
                                            <mat-option *ngFor="let item of filteredListBranch" [value]="item._id"
                                            (click)="branchClick(item._id,item.branchName);" >
                                             {{ item.branchName }}</mat-option>
                                            </mat-select>
                                            
                                        </mat-form-field>
                                        <mat-error class="margin_tp"
                                        *ngIf="inwardOutwardForm.controls['branchRefId'].touched && inwardOutwardForm.controls['branchRefId'].errors?.required">
                                        This field is mandatory.
                                      </mat-error> 
                                      
                                      </div>
                                 </div>
                                 </div>
                                 
                                    <div class="col-sm-2 offset-sm-10 mb-2 mt-2" *ngIf="submitButton && inwardOutwardCreate"> 
                                        <button class="btn btn-primary btn-sm example-full-width"  type="button" 
                                        (click)="submitInwordOutword()">Save </button>

                                    </div>
                                    <div class="col-sm-2 offset-sm-8 mb-2 mt-2" *ngIf="updateButton && inwardOutwardUpdate">
                                        <button (click)="UpdateInwordOutword();"  type="button" 
                                        class="btn btn-primary btn-sm example-full-width">Update </button>
                                    </div>
                                    <div class="col-sm-2 mb-2 mt-2 " *ngIf="updateButton">
                                        <button class="btn btn-primary btn-sm example-full-width" (click)="cancelUpdate()" type="reset" >Cancel </button>
                                       
                                    </div>
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 pl-0 pr-2">
                <div class="row">
                    <div class="card divbody borderradius p-3 pb-0">
                            <div class="col-sm-12">
                                <div class="row">
                                    <div class="col-sm-9 ">
                                        <h1 class="titlecolor">Inward Outward List</h1>
                                    </div>
                                    <div class="search-box col-sm-3 pb-2">
                                      <input class="form-control amber-border" type="text" placeholder="Search" 
                                      formControlName="searchInwardOutward" aria-label="Search" (keyup)="onKeyUpEvent($event)"
                                      >
                                      <div class="input-group-append">
                                         <span class="input-group-text amber lighten-3" id="basic-text1"><span class="material-symbols-outlined">search</span></span>
                                      </div>
                                   </div>
                                
                                    <div class="col-sm-12 table-responsive">
                                      <table id="toInwardOutwardLst" 
                                   class="table table-bordered data-table-custom">
                                            <thead>
                                                <tr class="text-center">
                                                    <th> ID</th>
                                                    <th *ngIf="userType == 'Super Admin'">Branch Name</th>
                                                     <th>Date</th>
                                                     <th>Item</th>
                                                    <th>Inward/Outward</th>
                                                    <th>From City</th>
                                                    <th>To City</th>
                                                    <th>Docket No.</th>
                                                </tr>
                                            </thead> 
                                            <tbody>
                                                <tr *ngFor="let item of listOfInwardOutward | paginate : { id:'inwardOutwardMaster',
                                                itemsPerPage: size, currentPage: page, totalItems:totalLength }" 
                                                (click)="prepopulation(item._id)"
                                                 (click)="toInwardOutwardForm()" 
                                                 style="cursor:pointer; text-align: center ">
                                                 <td matTooltip="click to view">{{item.branchWiseId}}</td>
                                                 <td *ngIf="userType == 'Super Admin'" matTooltip="click to view" >{{item.branchName}}</td>
                                                 <td matTooltip="click to view" 
                                                 >{{item.date | date}}
                                               </td>
                                               <td matTooltip="click to view">{{item.item}}</td>
                                               <td matTooltip="click to view">{{item.inwardOutward}}</td>
                                                    <td matTooltip="click to view">{{item.fromCityName}}</td>
                                                    <td matTooltip="click to view">{{item.toCityName}}</td>
                                                    <td matTooltip="click to view">{{item.docketNo}}</td>
                                                   </tr>
                                            

                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="col-sm-12 pt-0 text-right">
                                            <pagination-controls id="inwardOutwardMaster" directionLinks="true"
                                  (pageChange)="onPageChange($event)" class="float-right pagination my-pagination"></pagination-controls>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            

        </div>
    </div>
  </form>
</div>