<div class="container-fluid " [formGroup]="notesMasterForm">
    <form [formGroup]="notesMasterForm" autocomplete="off" novalidate>
        <div class="row">
    
            <div class="col-sm-12">
               
              <div class="row pt-1"> 
                <div class="col-sm-12 pb-2">
                    <div class="row margingTop pb-2">

                        <div class="card divbody boxsssss borderradius pr-3 pb-2 p-1 " >
                            <div class="col-sm-12 mt-3">
                                  <div class="row">
      
            <div class="col-sm-12 pl-3 ">
              <div class="row ">
              <div class="col-sm-12">
                  <h1 class="pt-2 pl-0" id="toServicesForm">Notes</h1>
                  </div>
                 
          </div>
         </div>
       
         <div class="col-sm-7">

          <div class="tab-content  pb-3" id="pills-tabContent">
              <div class="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="pills-home-tab">
                 <!-- <h2>Notes History </h2> -->
                 <div class="col-sm-12 scroll height pl-0 ml-0">
                 <ul class="timeline history-timeline ml-1">
                    <li *ngFor="let item of noteData"
                    (click)="prepopulation(item._id)" class="mb-5 pl-0">
                       <h5 class="highlighted-color">
                   Added By, {{item.userName}}, On {{item.createdAt | date:'MMM d, y, - h:mm a'}}
                       </h5>
                       <div class="d-flex mb-2">
                          <span class="title-text">Note :</span>
                          <span> {{item.notes}}</span>
                       </div>
                    </li>
                    <li *ngIf="noteDataLenght == true">
                      <h2>Notes History Not Available</h2>
                    </li>
                 </ul>
                 </div>
              </div>
              </div>
      </div>
     
                                <div class="col-sm-5 mt-3">
                                  <h2>Add Notes  </h2>
                                        <mat-form-field class="example-full-width mb-4" appearance="outline">
                                            <mat-label>Notes</mat-label>
                                            <textarea maxlength="1000" placeholder="Start writing" matInput required formControlName="notes"
                                            cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                            cdkAutosizeMinRows="7" cdkAutosizeMaxRows="10" ></textarea>
                                           </mat-form-field>
                            <mat-error
                            *ngIf="notesMasterForm.controls['notes'].touched && notesMasterForm.controls['notes'].errors?.required">
                            This field is mandatory.
                            </mat-error>
                            <div class="row">
                            <div class="col-sm-3 mb-2 offset-sm-6 mt-4" *ngIf="updateButton && notesUpdate">
                               
                                <button  class="btn btn-primary  btn-sm  example-full-width"  (click)="updateNotesData()" type="button">Update </button>
                               
                              </div>
                              <div class="col-sm-3 mb-2 mt-4" *ngIf="cancelButton">
                               
                                <button class="btn btn-primary btn-sm  example-full-width"  (click)="cancelUpdate()">Cancel </button>
                                </div>
                            <div class="col-sm-3 offset-sm-9 mb-2 mt-5" *ngIf="submitButton && notesCreate" >
                              <button class="btn btn-primary btn-sm example-full-width"  (click)="submitNotes()" type="button">Save </button> 
                            </div> 
                        </div>
                                </div>
                               
                              
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
          
            </div>
        </div>
        </form>
    </div>

    