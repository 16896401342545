import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";
import { PopUpPatientComponent } from "../pop-up-patient/pop-up-patient.component";
import { PopUpBranchComponent } from "../pop-up-branch/pop-up-branch.component";
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { PopupCompanyComponent } from "../popup-company/popup-company.component";
import { PopupBillingcompanyComponent } from "../popup-billingcompany/popup-billingcompany.component";
import { PatientSearchPopComponent } from "../patient-search-pop/patient-search-pop.component";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { PdfviewerComponent } from "../pdfviewer/pdfviewer.component";
import SignaturePad from 'signature_pad';
import { ElementRef } from '@angular/core';
import { AfterViewInit, Directive, ViewChild } from "@angular/core";
@Component({
  selector: 'app-ear-mould',
  templateUrl: './ear-mould.component.html',
  styleUrls: ['./ear-mould.component.scss']
})
export class EarMouldComponent implements OnInit {

  earMouldNewForm: FormGroup;
  submitButton = true;
  updateButton = false;
  filteredListBranch: any;
  listOfEarMouldOrders: any;
  BranchList = [];
  size: number = 10;
  page: number = 1;
  totalLength: any;
  filteredListPatient: any;
  patientList = [];
  branchRef: any;
  searchText: string;
  clinicRef: any;
  pid: any;
  patientSelect = false;
  date: Date;
  userPermission: any;
  earMouldCreate = true;
  earMouldUpdate = true;
  isSuperAdmin = true;
  branchName: any;
  superBranchId: any;
  userRef: string;
  userType: string;
  cancelButton = false;
  earId: any;
  canNumberList = [];
  filteredListCanNumber: any;
  audiogramFile: any;
  errormessage: string;
  companyList = [];
  filteredListCompany: any;
  companyName: any;
  address: any;
  email: any;
  canNumber: any;
  make: any;
  billingCompanyList = [];
  filteredListBillingCompany: any;
  billingCompany: any;
  patientCreate = true;
  branchCreate = true;
  billingCreate = true;
  companyCreate = true;
  RightDisable = false;
  LeftDisable = false;
  earMouldNo: string;
  bId: any;
  ear_branchRefId: string;
  ear_patientRefId: string;
  ear_branchName: string;
  ear_patientName: string;
  isPrepopulate = false;
  constructor(private fb: FormBuilder,
    public apimicroservice: ApiServiceService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    private route: ActivatedRoute,
    public router: Router,) { }

  title = 'signatureJS';
  signaturePad: SignaturePad;
  @ViewChild('canvas') canvasEl: ElementRef;
  signatureImg: string;
  fileName2: any;
  signPadShow = false;
  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
  }

  startDrawing(event: Event) {
    ////
  }

  moved(event: Event) {
    // works in device not in browser
  }

  clearPad() {
    this.signaturePad.clear();
  }

  savePad() {
    const base64Data = this.signaturePad.toDataURL();
    this.signatureImg = base64Data;
  }
  ngOnInit(): void {
    this.date = new Date();

    this.isPrepopulate = false;
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.userRef = sessionStorage.getItem('userRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    this.userType = sessionStorage.getItem('userType');
    this.earMouldNo = this.route.snapshot.paramMap.get('earMouldNo');
    this.ear_branchRefId = sessionStorage.getItem('ear_branchRefId');
    this.ear_patientRefId = sessionStorage.getItem('ear_patientRefId');
    this.ear_branchName = sessionStorage.getItem('ear_branchName');
    this.ear_patientName = sessionStorage.getItem('ear_patientName');
    if (this.userType !== 'Super Admin') {
      this.isSuperAdmin = false;
      this.userPermission.forEach((element) => {
        if (element.pageName == 'Ear Mould' && element.create == false) {
          this.earMouldCreate = false;
        }
        if (element.pageName == 'Ear Mould' && element.update == false) {
          this.earMouldUpdate = false;
        }
        if (element.pageName == 'Patient' && element.create == false) {
          this.patientCreate = false;
        }
        if (element.pageName == 'Branch' && element.create == false) {
          this.branchCreate = false;
        }
        if (element.pageName == 'Billing Company' && element.create == false) {
          this.billingCreate = false;
        }
        if (element.pageName == 'Company Master' && element.create == false) {
          this.companyCreate = false;
        }

      });
    }

    this.earMouldNewForm = this.fb.group({

      "branchWiseId": new FormControl(''),
      "date": new FormControl(''),
      "manufacturing": new FormControl(''),

      "branchRefId": new FormControl(''),
      "clinicRefId": new FormControl(''),
      "branchName": new FormControl(''),
      "patientRefId": new FormControl('', Validators.required),
      "patientUniqueId": new FormControl(''),
      "patientName": new FormControl(''),
      "firstName": new FormControl(''),
      "lastName": new FormControl(''),
      "mobile1": new FormControl('', [Validators.pattern("(0|91)?[6-9][0-9]{9}")]),
      "age": new FormControl(''),

      "companyRefId": new FormControl('', Validators.required),
      "companyName": new FormControl(''),
      "companyAddress": new FormControl(''),
      "companyEmail": new FormControl(''),
      "canNumber": new FormControl(''),
      "make": new FormControl(''),
      "ear": new FormControl('', Validators.required),
      "leftStyle": new FormControl(''),
      "rightStyle": new FormControl(''),
      "leftHAType": new FormControl(''),
      "rightHAType": new FormControl(''),
      "leftHardness": new FormControl(''),
      "rightHardness": new FormControl(''),
      "leftVent": new FormControl(''),
      "rightVent": new FormControl(''),
      "leftCanalLenght": new FormControl(''),
      "rightCanalLenght": new FormControl(''),
      "leftRemovalString": new FormControl(''),
      "rightRemovalString": new FormControl(''),
      "estimateRequired": new FormControl('',),
      "complaint": new FormControl(''),
      "audiogram": new FormControl(false),
      "audiogramFile": new FormControl(''),
      // "earImpression" :new FormControl(false),
      "earImpressionText": new FormControl(''),
      "billingRefId": new FormControl('', Validators.required),
      "billingCompanyName": new FormControl(''),
      "createdAt": new Date(),
      "searchEarMouldOrder": new FormControl(''),
      "Signaturephoto": new FormControl(''),
      "status": new FormControl(''),
    });
    this.fileName = "";
    this.hide = false;
    let latest_date = this.datepipe.transform(this.date, 'dd/MM/yyyy');
    this.earMouldNewForm.get("date").setValue(latest_date);
    this.earMouldNewForm.controls["manufacturing"].setValue("Manufacturer");
    this.earMouldNewForm.controls["status"].setValue("Inprocess");
    this.fetchEarMouldOrderList();

    this.fetchCompanyList();
    this.fetchPatientList();
    this.fetchBillingCompanyList();
    this.fetchBranchList();
 
    this.getLastId();
    this.getAllBranch();
    if (this.earMouldNo) {
      this.prepopulation(this.earMouldNo);
    }
  }
  getAllBranch() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      if (res.status) {
        let length = res.data.length - 1;

        this.branchName = res.data[length].branchName;
        this.earMouldNewForm.controls["branchRefId"].setValue(res.data[length]._id);

        if (this.userType == 'Super Admin') {
          this.branchClick(res.data[length]._id, this.branchName);
          this.apimicroservice.getBranchById(res.data[length]._id).subscribe(res => {
            this.spinner.hide();
            this.earMouldNewForm.get("branchName").setValue(res.data[length].branchName);
          });
        }
        if (this.ear_branchRefId !== '' && this.ear_patientRefId !== '') {
          this.earMouldNewForm.controls["branchRefId"].setValue(this.ear_branchRefId);
          this.earMouldNewForm.controls["patientRefId"].setValue(this.ear_patientRefId);
          this.branchClick(this.ear_branchRefId, this.ear_branchName);
          this.onSelectPatient(this.ear_patientRefId);
          this.earMouldNewForm.get("patientName").setValue(this.ear_patientName);
          sessionStorage.setItem('ear_branchRefId', '');
          sessionStorage.setItem('ear_patientRefId', '');
          sessionStorage.setItem('ear_branchName', '');
        }

      }
    });
  }

  fetchEarMouldOrderList() {
    let pg = 1;
    let size = 10;
    //   this.spinner.show(); 
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    this.searchText = this.earMouldNewForm.get('searchEarMouldOrder').value;

    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getEarMouldOrderData(pg, size, this.searchText, branchRef, clinicRef).subscribe(res => {
        //
        this.listOfEarMouldOrders = res.data.data;
        this.totalLength = res.data.totalItems;
        //this.buildingLenght = this.listofbuild.length;
        if (res.status) {
          //  this.apimicroservice.openSnackbar(res.message);

        } else {
          //this.apimicroservice.openSnackbar(res.message);
        }
      });
    } else {
      this.apimicroservice.getEarMouldOrderData(pg, size, this.searchText, branchRefSuperAdmin, clinicRefSuperAdmin).subscribe(res => {
        //
        this.listOfEarMouldOrders = res.data.data;
        this.totalLength = res.data.totalItems;
        //this.buildingLenght = this.listofbuild.length; 
        if (res.status) {
          //  this.apimicroservice.openSnackbar(res.message);

        } else {
          //this.apimicroservice.openSnackbar(res.message);
        }
      });
    }

  }
  toearMouldNewForm() {
    document.getElementById("toearMouldNewForm").scrollIntoView();
  }
  toearMouldNewFormList() {
    document.getElementById("toearMouldNewFormList").scrollIntoView();
  }
  prepopulation(id) {
    this.isPrepopulate = true;
    this.apimicroservice.getEarMouldDataById(id).subscribe(res => {
      //
      let data = res.data[0];
      this.earId = id;

      this.updateButton = true;
      this.submitButton = false;
      this.cancelButton = true;
       this.getCompany(data.companyRefId);
      this.branchClick(data.branchRefId, data.branchName);
      this.onSelectPatient(data.patientRefId);
      let pName; 
      if(data.patientName)
      { 
      pName = data.patientName;
      }else{
        pName = data.firstName + ' ' + data.lastName;
      }
      //  this.onSelectDoctor(data.doctorRefId);
      this.earMouldNewForm = this.fb.group({
        "branchWiseId": new FormControl(data.branchWiseId),
        "date": new FormControl(data.date),
        "manufacturing": new FormControl(data.manufacturing),
        "branchRefId": new FormControl(data.branchRefId),
        "clinicRefId": new FormControl(data.clinicRefId),
        "branchName": new FormControl(data.branchName),
        "patientRefId": new FormControl(data.patientRefId, Validators.required),
        "patientUniqueId": new FormControl(data.patientUniqueId),
        ///   "patientName": new FormControl(data.patientName),
        "firstName": new FormControl(data.firstName),
        "lastName": new FormControl(data.lastName),
        "mobile1": new FormControl(data.mobile1, [Validators.pattern("(0|91)?[6-9][0-9]{9}")]),
        "age": new FormControl(data.age),

        "companyRefId": new FormControl(data.companyRefId, Validators.required),
        "companyName": new FormControl(data.companyName),
        "companyAddress": new FormControl(data.companyAddress),
        "companyEmail": new FormControl(data.companyEmail),
        "canNumber": new FormControl(data.canNumber),
        "make": new FormControl(data.make),
        "ear": new FormControl(data.ear, Validators.required),
        "leftStyle": new FormControl(data.leftStyle),
        "rightStyle": new FormControl(data.rightStyle),
        "leftHAType": new FormControl(data.leftHAType),
        "rightHAType": new FormControl(data.rightHAType),
        "leftHardness": new FormControl(data.leftHardness),
        "rightHardness": new FormControl(data.rightHardness),
        "leftVent": new FormControl(data.leftVent),
        "rightVent": new FormControl(data.rightVent),
        "leftCanalLenght": new FormControl(data.leftCanalLenght),
        "rightCanalLenght": new FormControl(data.rightCanalLenght),
        "leftRemovalString": new FormControl(data.leftRemovalString),
        "rightRemovalString": new FormControl(data.rightRemovalString),
        "estimateRequired": new FormControl(data.estimateRequired),
        "complaint": new FormControl(data.complaint),
        "audiogram": new FormControl(data.audiogram),
        "audiogramFile": new FormControl(data.audiogramFile),
        "fileName": new FormControl(data.fileName),
        // "earImpression" :new FormControl(data.earImpression),
        "earImpressionText": new FormControl(data.earImpressionText),
        "billingRefId": new FormControl(data.billingRefId, Validators.required),
        "billingCompanyName": new FormControl(data.billingCompanyName),
        "searchEarMouldOrder": new FormControl(''),
        "patientName": new FormControl(pName),
        "status": new FormControl(data.status),
        "Signaturephoto": new FormControl(''),
        "updatedAt": new Date(),

      });
      this.signatureImg = data.Signaturephoto;
      this.superBranchId = data.branchWiseId;
      this.bId = data.branchWiseId;
      this.audiogramFile = data.audiogramFile ? data.audiogramFile : "../../assets/images/setting2.png";
      this.fileName = data.fileName;
      this.hide = true;
    });
  }
  getCompany(id) {
    this.spinner.show();
    this.apimicroservice.getCompanyById(id).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        let data=res.data[0];
        this.earMouldNewForm.controls['companyName'].setValue(data.companyName);
        this.earMouldNewForm.controls['companyAddress'].setValue(data.companyAddress);
        this.earMouldNewForm.controls['companyEmail'].setValue(data.companyEmail);
        this.earMouldNewForm.controls['make'].setValue(data.make);
        this.earMouldNewForm.controls['canNumber'].setValue(data.canNumber);
        this.earMouldNewForm.controls['salesPersonName'].setValue(data.salesPersonName);
        this.earMouldNewForm.controls['salesPersonNumber'].setValue(data.salesPersonNumber);
   
        
      } else {
      //  this.apimicroservice.openSnackbar(res.message);
      }
    }); 
  }
  cancelUpdate() {
    this.signaturePad.clear();
    this.earMouldNewForm.reset();
    this.submitButton = true;
    this.updateButton = false;
    this.cancelButton = false;
    this.ngOnInit();
  }

  submitEarMouldOrderList() {
    let payload = this.earMouldNewForm.value;
    let bar = new Promise<void>((resolve, reject) => {
      if (this.userType == 'Super Admin') {
        this.earMouldNewForm.controls['branchRefId'].setValidators(Validators.required);
        this.earMouldNewForm.controls['branchRefId'].updateValueAndValidity();
      }
      else {
        this.earMouldNewForm.controls['branchRefId'].setErrors(null);
        this.earMouldNewForm.controls['branchRefId'].clearValidators();
        this.earMouldNewForm.controls['branchRefId'].updateValueAndValidity();
      }
      resolve();
    });
    bar.then(() => {
      if (this.earMouldNewForm.invalid) {
        this.earMouldNewForm.get("ear").markAsTouched();
        this.earMouldNewForm.get("patientRefId").markAsTouched();
        this.earMouldNewForm.get("billingRefId").markAsTouched();
        this.earMouldNewForm.get("companyRefId").markAsTouched();
        this.earMouldNewForm.get("rightRemovalString").markAsTouched();
        this.earMouldNewForm.get("rightHAType").markAsTouched();
        this.earMouldNewForm.get("rightCanalLenght").markAsTouched();
        this.earMouldNewForm.get("rightStyle").markAsTouched();
        this.earMouldNewForm.get("rightVent").markAsTouched();
        this.earMouldNewForm.get("rightHardness").markAsTouched();
        this.earMouldNewForm.get("leftHardness").markAsTouched();
        this.earMouldNewForm.get("leftVent").markAsTouched();
        this.earMouldNewForm.get("leftStyle").markAsTouched();
        this.earMouldNewForm.get("leftCanalLenght").markAsTouched();
        this.earMouldNewForm.get("leftHAType").markAsTouched();
        this.earMouldNewForm.get("leftRemovalString").markAsTouched();
      }
      else {
        this.saveData();
      }
    })


  }


  saveData() {

    let payload = this.earMouldNewForm.value;
    //
    payload.earMoldOrderDate = this.date;
    if (this.userType !== 'Super Admin') {
      payload.branchRefId = this.branchRef;
      payload.clinicRefId = this.clinicRef;
      payload.branchName = this.branchName;
    }
    payload.Signaturephoto = this.signatureImg;
    this.spinner.show();
    payload.audiogramFile = this.audiogramFile;
    payload.fileName = this.fileName;
    this.apimicroservice.addEarMold(payload).subscribe(res => {
      this.spinner.hide();
      this.signatureImg = '';
      this.signaturePad.clear();
      this.toearMouldNewForm();
      this.ngOnInit();
      if (res.status) {
        this.apimicroservice.openSnackbar(res.message);
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    }, (ERROR: HttpErrorResponse) => {
      this.toearMouldNewForm();
      this.apimicroservice.openSnackbar(ERROR.error.message);
      this.spinner.hide();
    });
  }

  updateEarMouldOrderList() {
    let payload = this.earMouldNewForm.value; 
    let bar = new Promise<void>((resolve, reject) => {
      if (this.userType == 'Super Admin') {
        this.earMouldNewForm.controls['branchRefId'].setValidators(Validators.required);
        this.earMouldNewForm.controls['branchRefId'].updateValueAndValidity();
      }
      else {
        this.earMouldNewForm.controls['branchRefId'].setErrors(null);
        this.earMouldNewForm.controls['branchRefId'].clearValidators();
        this.earMouldNewForm.controls['branchRefId'].updateValueAndValidity();
      }
      resolve();
    });
    bar.then(() => {
      if (this.earMouldNewForm.invalid) {
        this.earMouldNewForm.get("ear").markAsTouched();
        this.earMouldNewForm.get("patientRefId").markAsTouched();
        this.earMouldNewForm.get("billingRefId").markAsTouched();
        this.earMouldNewForm.get("companyRefId").markAsTouched();
        this.earMouldNewForm.get("rightRemovalString").markAsTouched();
        this.earMouldNewForm.get("rightHAType").markAsTouched();
        this.earMouldNewForm.get("rightCanalLenght").markAsTouched();
        this.earMouldNewForm.get("rightStyle").markAsTouched();
        this.earMouldNewForm.get("rightVent").markAsTouched();
        this.earMouldNewForm.get("rightHardness").markAsTouched();
        this.earMouldNewForm.get("leftHardness").markAsTouched();
        this.earMouldNewForm.get("leftVent").markAsTouched();
        this.earMouldNewForm.get("leftStyle").markAsTouched();
        this.earMouldNewForm.get("leftCanalLenght").markAsTouched();
        this.earMouldNewForm.get("leftHAType").markAsTouched();
        this.earMouldNewForm.get("leftRemovalString").markAsTouched();
      }
      else {
        this.updateEarMoldData();
      }
    })

  } 

  updateEarMoldData() {
    this.spinner.show();
    let payload = this.earMouldNewForm.value;
    payload.earMoldOrderDate = this.date;
    if (this.userType !== 'Super Admin') {
      payload.branchRefId = this.branchRef;
      payload.clinicRefId = this.clinicRef;
      payload.branchName = this.branchName;
    }
    payload.audiogramFile = this.audiogramFile;
    payload.fileName = this.fileName;
    payload.Signaturephoto = this.signatureImg;
    this.apimicroservice.updateEarMold(payload, this.earId).subscribe(res => {
      //
      this.signatureImg = '';
      this.signaturePad.clear();
      this.spinner.hide();
      if (res.status) {
        this.apimicroservice.openSnackbar(res.message);
        this.updateButton = false;
        this.submitButton = true;
        this.cancelButton = false;
        this.toearMouldNewForm();
        this.ngOnInit();
      } else {
        //  //this.apimicroservice.openSnackbar(res.message);
      }
    });

  }

  onKeyUpEvent(event: any) {
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    this.searchText = this.earMouldNewForm.get('searchEarMouldOrder').value;
    let page = 1;
    let size = 10;
    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getEarMouldOrderData(this.page, this.size, this.searchText, branchRef, clinicRef).subscribe(res => {
        //
        this.listOfEarMouldOrders = res.data.data;
        this.totalLength = res.data.totalItems;
        this.page = res.data.pageNumber;
        //this.buildingLenght = this.listofbuild.length;
        if (res.status) {
          // this.apimicroservice.openSnackbar(res.message);

        } else {
          //this.apimicroservice.openSnackbar(res.message);
        }
      });
    } else {
      this.apimicroservice.getEarMouldOrderData(page, size, this.searchText, branchRefSuperAdmin, clinicRefSuperAdmin).subscribe(res => {
        //
        this.listOfEarMouldOrders = res.data.data;
        this.totalLength = res.data.totalItems;
        this.page = res.data.pageNumber;
        //this.buildingLenght = this.listofbuild.length;
        if (res.status) {
          //  this.apimicroservice.openSnackbar(res.message);

        } else {
          //this.apimicroservice.openSnackbar(res.message);
        }
      });
    }
  }
  onPageChange(event) {
    this.page = event;
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    this.searchText = this.earMouldNewForm.get('searchEarMouldOrder').value;

    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getEarMouldOrderData(this.page, this.size, this.searchText, branchRef, clinicRef).subscribe(res => {
        //
        this.listOfEarMouldOrders = res.data.data;
        this.totalLength = res.data.totalItems;
        //this.buildingLenght = this.listofbuild.length;
        if (res.status) {
          // this.apimicroservice.openSnackbar(res.message);

        } else {
          //this.apimicroservice.openSnackbar(res.message);
        }
      });
    } else {
      this.apimicroservice.getEarMouldOrderData(this.page, this.size, this.searchText, branchRefSuperAdmin, clinicRefSuperAdmin).subscribe(res => {
        //
        this.listOfEarMouldOrders = res.data.data;
        this.totalLength = res.data.totalItems;
        //this.buildingLenght = this.listofbuild.length;
        if (res.status) {
          // this.apimicroservice.openSnackbar(res.message);

        } else {
          //this.apimicroservice.openSnackbar(res.message);
        }
      });
    }
  }
  fetchPatientList() {
    this.searchText = '';
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    //
    if(this.userType !== 'Super Admin')
    {
      this.apimicroservice.getPatientListByBranchClinic(branchRef,clinicRef).subscribe(res => {
        //

        if (res.status) {    
          this.patientList = res.data;
          this.filteredListPatient = this.patientList.slice();
          // this.onSelectPatient(this.patientList[0]._id);
          // this.earMouldNewForm.get("patientRefId").setValue(this.patientList[0]._id);
        } else {
         // this.apimicroservice.openSnackbar(res.message);
        }
      })
    }
    else
    {
    this.apimicroservice.getPatientListByBranchClinic(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
      //

      if (res.status) {
        this.patientList = res.data;
        this.filteredListPatient = this.patientList.slice();
        // this.onSelectPatient(this.patientList[0]._id);
        // this.earMouldNewForm.get("patientRefId").setValue(this.patientList[0]._id);
      } else {
        // this.apimicroservice.openSnackbar(res.message);
      }
    })
    }

  }
  fetchCompanyList() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    this.apimicroservice.getAllCompanylist(branchRef,clinicRef).subscribe(res => {
      
      if (res.status) {
        this.companyList = res.data.data;
        this.filteredListCompany = this.companyList.slice();
        let lenght = res.data.totalItems - 1;
        
        this.earMouldNewForm.controls['companyRefId'].setValue(res.data.data[lenght]._id);
        this.onSelectCompany(res.data.data[lenght].companyName, res.data.data[lenght].companyAddress,
          res.data.data[lenght].companyEmail, res.data.data[lenght].canNumber, res.data.data[lenght].make);

      } else {
        //  this.apimicroservice.openSnackbar(res.message);
      }
    })
  }

  fetchBranchList() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      if (res.status) {
        this.BranchList = res.data;
        this.filteredListBranch = this.BranchList.slice();
      } else {
        //  this.apimicroservice.openSnackbar(res.message);
      }
    })
  }

  branchClick(id, branchName) {

    this.earMouldNewForm.controls["branchName"].setValue(branchName);
    this.branchRef = id;
    this.searchText = '';
    //  this.apimicroservice.getPatientListByBranchClinic(id,this.clinicRef).subscribe(res => {
    //    //
    //    //
    //    if (res.status) {  
    //      if(res.data.length > 0){
    //       this.patientList = res.data;
    //       this.filteredListPatient = this.patientList.slice();
    //       // this.onSelectPatient(this.patientList[0]._id);
    //       // this.earMouldNewForm.get("patientRefId").setValue(this.patientList[0]._id);

    //      }  else{
    //       this.patientList = res.data;
    //      } 

    //    } else {
    //    //  this.apimicroservice.openSnackbar(res.message);
    //    }
    //  }) 

    this.apimicroservice.getBranchById(id).subscribe(res => {
      //

      let data = res.data[0];
      let payload = this.earMouldNewForm.value;
      payload.clinicRefId = res.data[0].clinicRefId;
      this.earMouldNewForm.controls["clinicRefId"].setValue(res.data[0].clinicRefId);
      if (this.updateButton == false) {
        //  this.spinner.show();
        this.apimicroservice.getLastEarMoldID(payload.branchRefId).subscribe(res => {


          if (res['status']) {
            let repairIdd = res['data']['totalItems'];
            this.bId = repairIdd + 1;
            this.earMouldNewForm.controls['branchWiseId'].setValue(repairIdd + 1);

          } else {
            this.apimicroservice.openSnackbar("Something went wrong");
          }
          //  this.spinner.hide();
        })
      }
    });

  }

  onSelectPatient(id) {
    this.patientSelect = true;
    this.spinner.show();
    this.apimicroservice.getPatientById(id).subscribe(res => {
      this.spinner.hide();
      let data = res.data[0];
      this.pid = id;
      //

      this.earMouldNewForm.controls["firstName"].setValue(data.firstName);
      this.earMouldNewForm.controls["lastName"].setValue(data.lastName);
      this.earMouldNewForm.controls["patientUniqueId"].setValue(data.patientId);
      this.earMouldNewForm.controls["mobile1"].setValue(data.mobile1);
      this.earMouldNewForm.controls["age"].setValue(data.age);


      if (data.dob) {
        var timeDiff = Math.abs(Date.now() - new Date(data.dob).getTime());
        let age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
        //
        this.earMouldNewForm.controls["age"].setValue(age);
      }


    });
  }
  getLastId() {
    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getLastEarMoldID(this.branchRef).subscribe(res => {
        //   
        if (res['status']) {

          let clientIdd = res.data.totalItems;
          //
          this.bId = clientIdd + 1
          this.earMouldNewForm.controls['branchWiseId'].setValue(clientIdd + 1);
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }

      })
    }
    else {
      this.apimicroservice.getEarMoldLastAll().subscribe(res => {
        //   
        if (res['status']) {

          let clientIdd = res.data.totalItems;
          //

          this.superBranchId = clientIdd + 1;
          this.bId = clientIdd + 1;

          //this.earMouldNewForm.controls['branchWiseId'].setValue(clientIdd + 1);
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }

      })
    }
  }

  onSelectCompany(cname, address, email, canNumber, make) {
    //

    this.companyName = cname;
    this.address = address;
    this.email = email;
    this.canNumber = canNumber;
    this.make = make;
    this.earMouldNewForm.controls['companyName'].setValue(this.companyName);
    this.earMouldNewForm.controls['companyAddress'].setValue(this.address);
    this.earMouldNewForm.controls['companyEmail'].setValue(this.email);
    this.earMouldNewForm.controls['canNumber'].setValue(this.canNumber);
    this.earMouldNewForm.controls['make'].setValue(this.make);

  }

  companyClick(bid, bcname) {
    this.billingCompany = bcname;
    this.earMouldNewForm.controls['billingCompanyName'].setValue(this.billingCompany);
  } 

  fetchBillingCompanyList() {
    let id = '';
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getBillingDataById(id,clinicRef).subscribe(res => {
      //
      this.billingCompanyList = res.data;
      this.filteredListBillingCompany = this.billingCompanyList.slice();
      let lenght = res.data.length - 1;
      

      this.companyClick(res.data[lenght]._id, res.data[lenght].companyName);
      this.earMouldNewForm.get("billingRefId").setValue(res.data[lenght]._id);
      if (res.status) {
        //this.apimicroservice.openSnackbar(res.message);

      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    });
  }
  openPatientPopUp() {

    const dialogRef3 = this.dialog.open(PopUpPatientComponent,
      {
        width: "1200px",
      });

    dialogRef3.afterClosed().subscribe((result) => {
      let clinicRef= sessionStorage.getItem('clinicRef');
      let branchRef= sessionStorage.getItem('branchRef');
      this.apimicroservice.getPatientListByBranchClinic(branchRef,clinicRef).subscribe(res => {
        //

        if (res.status) {
          this.patientList = res.data;
          let length = res.data.length - 1;
          this.filteredListPatient = this.patientList.slice();
          this.onSelectPatient(this.patientList[length]._id);
          this.earMouldNewForm.get("patientRefId").setValue(this.patientList[length]._id);
        } else {
          // this.apimicroservice.openSnackbar(res.message);
        }
      })
    });
  }
  openBranchPopUp() {

    const dialogRef3 = this.dialog.open(PopUpBranchComponent,
      {
        width: "1200px",
      });

    dialogRef3.afterClosed().subscribe((result) => {
      let clinicRef= sessionStorage.getItem('clinicRef');
      this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
        if (res.status) {
          this.BranchList = res.data;
          this.filteredListBranch = this.BranchList.slice();
          this.branchClick(res.data[0]._id, res.data[0].branchName);
          this.earMouldNewForm.get("branchRefId").setValue(res.data[0]._id);
        } else {
          //  this.apimicroservice.openSnackbar(res.message);
        }
      })
      // this.fetchBranchList();  
    });
  }

  openCompanyPopUp() {
    const dialogRef3 = this.dialog.open(PopupCompanyComponent,
      {
        width: "900px",
      });

    dialogRef3.afterClosed().subscribe((result) => {
      //this.fetchCompanyList();  
      let clinicRef= sessionStorage.getItem('clinicRef');
      let branchRef= sessionStorage.getItem('branchRef');
      this.apimicroservice.getAllCompanylist(branchRef,clinicRef).subscribe(res => {
        //
        if (res.status) {
          this.companyList = res.data.data;
          this.filteredListCompany = this.companyList.slice();
          this.onSelectCompany(this.companyList[0].companyName, this.companyList[0].companyAddress, this.companyList[0].companyEmail, this.companyList[0].canNumber, this.companyList[0].make);

          this.earMouldNewForm.get("companyRefId").setValue(res.data.data[0]._id);
        } else {
          //  this.apimicroservice.openSnackbar(res.message);
        }
      })
    });
  }

  openBillingCompPopUp() {
    const dialogRef3 = this.dialog.open(PopupBillingcompanyComponent,
      {
        width: "1200px",
      });

    dialogRef3.afterClosed().subscribe((result) => {
      let id = '';
      let clinicRef= sessionStorage.getItem('clinicRef');
      this.apimicroservice.getBillingDataById(id,clinicRef).subscribe(res => {

        if (res.status) {
          //this.apimicroservice.openSnackbar(res.message);
          //
          this.billingCompanyList = res.data;
          this.filteredListBillingCompany = this.billingCompanyList.slice();
          this.companyClick(res.data[0]._id, res.data[0].companyName);
          this.earMouldNewForm.get("billingRefId").setValue(res.data.data[0]._id);
        } else {
          //this.apimicroservice.openSnackbar(res.message);
        }
      });
      //this.fetchBillingCompanyList();  
    });
  }
  fileName = "";
  hide = false;
  onChange($event: Event) {
    const file = ($event.target as HTMLInputElement).files[0];
    const fileName = file.name;
    this.fileName = fileName;
    this.errormessage = '';
    let ext = fileName.substring(fileName.lastIndexOf(".") + 1);
    if (
      ext.toLowerCase() == "png" ||
      ext.toLowerCase() == "jpg" ||
      ext.toLowerCase() == "jpeg" ||
      ext.toLowerCase() == "csv" ||
      ext.toLowerCase() == "xlsx" ||
      ext.toLowerCase() == "pdf" ||
      ext.toLowerCase() == "docx" 
    ) { 
      this.convertToBase64(file);
      this.hide = true;
    }
  }
  convertToBase64(file: File) {
    const observable = new Observable((subscriber: Subscriber<any>) => {
      this.readFile(file, subscriber);
    });
    //

    observable.subscribe((d) => {
      this.audiogramFile = d;
      //

    });
  }
  readFile(file: File, subscriber: Subscriber<any>) {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      subscriber.next(fileReader.result);
      subscriber.complete();
    };
    fileReader.onerror = (error) => {
      subscriber.error(error);
      subscriber.complete();
    };
  }

  copyToRight() {

    //
    let payload = this.earMouldNewForm.value;

    this.earMouldNewForm.controls["rightStyle"].setValue(payload.leftStyle);
    this.earMouldNewForm.controls["rightHAType"].setValue(payload.leftHAType);
    this.earMouldNewForm.controls["rightHardness"].setValue(payload.leftHardness);

    this.earMouldNewForm.controls["rightVent"].setValue(payload.leftVent);
    this.earMouldNewForm.controls["rightCanalLenght"].setValue(payload.leftCanalLenght);

    this.earMouldNewForm.controls["rightRemovalString"].setValue(payload.leftRemovalString);


  }
  leftReset() {
    this.earMouldNewForm.controls["leftStyle"].reset();
    this.earMouldNewForm.controls["leftHAType"].reset();
    this.earMouldNewForm.controls["leftHardness"].reset();
    this.earMouldNewForm.controls["leftVent"].reset();
    this.earMouldNewForm.controls["leftCanalLenght"].reset();
    this.earMouldNewForm.controls["leftRemovalString"].reset();



  }
  copyToLeft() {

    //
    let payload = this.earMouldNewForm.value;

    this.earMouldNewForm.controls["leftStyle"].setValue(payload.rightStyle);
    this.earMouldNewForm.controls["leftHAType"].setValue(payload.rightHAType);
    this.earMouldNewForm.controls["leftHardness"].setValue(payload.rightHardness);

    this.earMouldNewForm.controls["leftVent"].setValue(payload.rightVent);
    this.earMouldNewForm.controls["leftCanalLenght"].setValue(payload.rightCanalLenght);

    this.earMouldNewForm.controls["leftRemovalString"].setValue(payload.rightRemovalString);


  }
  rightReset() {
    this.earMouldNewForm.controls["rightStyle"].reset();
    this.earMouldNewForm.controls["rightHAType"].reset();
    this.earMouldNewForm.controls["rightHardness"].reset();
    this.earMouldNewForm.controls["rightVent"].reset();
    this.earMouldNewForm.controls["rightCanalLenght"].reset();
    this.earMouldNewForm.controls["rightRemovalString"].reset();



  }
  globalPrint() {
    let currentDate = new Date();
    let date = currentDate.toLocaleDateString('en-GB');
    let titleName = 'Ear Mould Form_' + date + '.pdf'
    var tempTitle = document.title;
    document.title = titleName;
    window.print();
    document.title = tempTitle;
  }
  WhatsApp() {
    let payload = this.earMouldNewForm.value;
    let num = payload.mobile1;
    if (num.length == 10) {
      sessionStorage.setItem('whatsAppNumber', payload.mobile1);
      let mobileNumber = sessionStorage.getItem('whatsAppNumber');
      window.open("https://wa.me/91" + mobileNumber);
    }
    else {
      this.earMouldNewForm.get("mobile1").markAsTouched();
    }
  }
  onback() {
    this.apimicroservice.back()
  }


  onEarChange(e) {
    if (e == 'Both') {
      this.RightDisable = false;
      this.LeftDisable = false;

      this.earMouldNewForm.controls['leftHardness'].setValidators(Validators.required);
      this.earMouldNewForm.controls['leftHardness'].updateValueAndValidity();

      this.earMouldNewForm.controls['leftVent'].setValidators(Validators.required);
      this.earMouldNewForm.controls['leftVent'].updateValueAndValidity();

      this.earMouldNewForm.controls['leftStyle'].setValidators(Validators.required);
      this.earMouldNewForm.controls['leftStyle'].updateValueAndValidity();

      this.earMouldNewForm.controls['leftCanalLenght'].setValidators(Validators.required);
      this.earMouldNewForm.controls['leftCanalLenght'].updateValueAndValidity();

      this.earMouldNewForm.controls['leftHAType'].setValidators(Validators.required);
      this.earMouldNewForm.controls['leftHAType'].updateValueAndValidity();

      this.earMouldNewForm.controls['leftRemovalString'].setValidators(Validators.required);
      this.earMouldNewForm.controls['leftRemovalString'].updateValueAndValidity();

      this.earMouldNewForm.controls['rightHardness'].setValidators(Validators.required);
      this.earMouldNewForm.controls['rightHardness'].updateValueAndValidity();

      this.earMouldNewForm.controls['rightVent'].setValidators(Validators.required);
      this.earMouldNewForm.controls['rightVent'].updateValueAndValidity();

      this.earMouldNewForm.controls['rightStyle'].setValidators(Validators.required);
      this.earMouldNewForm.controls['rightStyle'].updateValueAndValidity();

      this.earMouldNewForm.controls['rightCanalLenght'].setValidators(Validators.required);
      this.earMouldNewForm.controls['rightCanalLenght'].updateValueAndValidity();

      this.earMouldNewForm.controls['rightHAType'].setValidators(Validators.required);
      this.earMouldNewForm.controls['rightHAType'].updateValueAndValidity();

      this.earMouldNewForm.controls['rightRemovalString'].setValidators(Validators.required);
      this.earMouldNewForm.controls['rightRemovalString'].updateValueAndValidity();

    }
    else if (e == 'Left') {
      this.LeftDisable = false;
      this.RightDisable = true;

      this.earMouldNewForm.controls['leftHardness'].setValidators(Validators.required);
      this.earMouldNewForm.controls['leftHardness'].updateValueAndValidity();

      this.earMouldNewForm.controls['leftVent'].setValidators(Validators.required);
      this.earMouldNewForm.controls['leftVent'].updateValueAndValidity();

      this.earMouldNewForm.controls['leftStyle'].setValidators(Validators.required);
      this.earMouldNewForm.controls['leftStyle'].updateValueAndValidity();

      this.earMouldNewForm.controls['leftCanalLenght'].setValidators(Validators.required);
      this.earMouldNewForm.controls['leftCanalLenght'].updateValueAndValidity();

      this.earMouldNewForm.controls['leftHAType'].setValidators(Validators.required);
      this.earMouldNewForm.controls['leftHAType'].updateValueAndValidity();

      this.earMouldNewForm.controls['leftRemovalString'].setValidators(Validators.required);
      this.earMouldNewForm.controls['leftRemovalString'].updateValueAndValidity();

      //////////////////

      this.earMouldNewForm.controls['rightHardness'].setErrors(null);
      this.earMouldNewForm.controls['rightHardness'].clearValidators();
      this.earMouldNewForm.controls['rightHardness'].updateValueAndValidity();

      this.earMouldNewForm.controls['rightVent'].setErrors(null);
      this.earMouldNewForm.controls['rightVent'].clearValidators();
      this.earMouldNewForm.controls['rightVent'].updateValueAndValidity();

      this.earMouldNewForm.controls['rightStyle'].setErrors(null);
      this.earMouldNewForm.controls['rightStyle'].clearValidators();
      this.earMouldNewForm.controls['rightStyle'].updateValueAndValidity();

      this.earMouldNewForm.controls['rightCanalLenght'].setErrors(null);
      this.earMouldNewForm.controls['rightCanalLenght'].clearValidators();
      this.earMouldNewForm.controls['rightCanalLenght'].updateValueAndValidity();

      this.earMouldNewForm.controls['rightHAType'].setErrors(null);
      this.earMouldNewForm.controls['rightHAType'].clearValidators();
      this.earMouldNewForm.controls['rightHAType'].updateValueAndValidity();

      this.earMouldNewForm.controls['rightRemovalString'].setErrors(null);
      this.earMouldNewForm.controls['rightRemovalString'].clearValidators();
      this.earMouldNewForm.controls['rightRemovalString'].updateValueAndValidity();
    }
    else if (e == 'Right') {
      this.RightDisable = false;
      this.LeftDisable = true;

      this.earMouldNewForm.controls['rightHardness'].setValidators(Validators.required);
      this.earMouldNewForm.controls['rightHardness'].updateValueAndValidity();

      this.earMouldNewForm.controls['rightVent'].setValidators(Validators.required);
      this.earMouldNewForm.controls['rightVent'].updateValueAndValidity();

      this.earMouldNewForm.controls['rightStyle'].setValidators(Validators.required);
      this.earMouldNewForm.controls['rightStyle'].updateValueAndValidity();

      this.earMouldNewForm.controls['rightCanalLenght'].setValidators(Validators.required);
      this.earMouldNewForm.controls['rightCanalLenght'].updateValueAndValidity();

      this.earMouldNewForm.controls['rightHAType'].setValidators(Validators.required);
      this.earMouldNewForm.controls['rightHAType'].updateValueAndValidity();

      this.earMouldNewForm.controls['rightRemovalString'].setValidators(Validators.required);
      this.earMouldNewForm.controls['rightRemovalString'].updateValueAndValidity();
      ///////////////////
      this.earMouldNewForm.controls['leftHardness'].setErrors(null);
      this.earMouldNewForm.controls['leftHardness'].clearValidators();
      this.earMouldNewForm.controls['leftHardness'].updateValueAndValidity();

      this.earMouldNewForm.controls['leftVent'].setErrors(null);
      this.earMouldNewForm.controls['leftVent'].clearValidators();
      this.earMouldNewForm.controls['leftVent'].updateValueAndValidity();

      this.earMouldNewForm.controls['leftStyle'].setErrors(null);
      this.earMouldNewForm.controls['leftStyle'].clearValidators();
      this.earMouldNewForm.controls['leftStyle'].updateValueAndValidity();

      this.earMouldNewForm.controls['leftCanalLenght'].setErrors(null);
      this.earMouldNewForm.controls['leftCanalLenght'].clearValidators();
      this.earMouldNewForm.controls['leftCanalLenght'].updateValueAndValidity();

      this.earMouldNewForm.controls['leftHAType'].setErrors(null);
      this.earMouldNewForm.controls['leftHAType'].clearValidators();
      this.earMouldNewForm.controls['leftHAType'].updateValueAndValidity();

      this.earMouldNewForm.controls['leftRemovalString'].setErrors(null);
      this.earMouldNewForm.controls['leftRemovalString'].clearValidators();
      this.earMouldNewForm.controls['leftRemovalString'].updateValueAndValidity();
    }
  }
  openDepartmentPopUp() {
    const dialogRef3 = this.dialog.open(PatientSearchPopComponent, {
      width: "97%",
      height: "90%",
    });

    dialogRef3.afterClosed().subscribe((result) => {
      this.earMouldNewForm.get("patientName").setValue(result.data.firstName + ' ' + result.data.lastName);
      this.earMouldNewForm.get("patientRefId").setValue(result.data._id);
      this.onSelectPatient(result.data._id);

    });
  }
  printForm() {
    this.router.navigate(['ear-mould-print', this.earId]);
  }
  redirect(to): void {
    this.router.navigate([to]);
  }
  showbase64(e) {
    const dialogRef3 = this.dialog.open(PdfviewerComponent,
      {
        width: "1200px",
        data: e
      });
  }
  remove() {
    this.hide = false;
  }
  canvasClick() {
    this.signPadShow = true;
    this.signatureImg = "";
    ////

  }
}