<div class="container-fluid " [formGroup]="communicationDashboardForm">
    <form [formGroup]="communicationDashboardForm" autocomplete="off" novalidate>
        <div class="row">
   
            <div class="col-sm-12">
               
              <div class="row pt-1"> 
                <div class="col-sm-12 pb-2">
                    <div class="row margingTop pb-2">

                        <div class="card divbody boxsssss borderradius pr-3 pb-2 p-1 " >
                            <div class="col-sm-12 mt-3">
                                  <div class="row">
                                      <div class="col-sm-8 ">
                                          <h1 class="titlecolor">Communication List</h1>
                                      </div>
                                      <div class="col-sm-1 justify-content-right">
                                        <div class="row">
                                            <div class="col-sm-6"></div>
                                            <div class="col-sm-6 justify-content-right">
                                                <div class="icon-btns pb-4">
                                                    <a class="icon-box2" data-toggle="tooltip" data-placement="top" matTooltip="Communication"
                                                    (click)="openCommunicationPopUp(pId)" >
                                                    <span class="material-symbols-outlined">
                                                        chat
                                                        </span>
                                    <img src="../../assets/icon-bg1.svg" class="icon-bg2">
                                    </a> 
                                                    </div>
                                      </div> 
                                      </div>
                                      </div>
                                      <div class="search-box col-sm-3 pb-2">
                                        <input class="form-control amber-border" type="text" placeholder="Search" 
                                        formControlName="searchBilling" aria-label="Search" (keyup)="onKeyUpEvent($event)"
                                        >
                                        <div class="input-group-append">
                                           <span class="input-group-text amber lighten-3" id="basic-text1"><span class="material-symbols-outlined">search</span></span>
                                        </div>
                                     </div>
                                   
                                      <div class="col-sm-12">
                                        <table id="toBillingLst" 
                                        class="table table-bordered data-table-custom">
                                              <thead>
                                                  <tr class="text-center">
                                                      <th>Sr.No.</th>
                                                       <th>Date</th>
                                                       <th>Communication Type</th>
                                                       <th>View</th>
                                                      <!-- <th>Status</th> -->
                                                  </tr>
                                              </thead>  
                                              <tbody> 
                                                  <tr *ngFor="let item of inwardOutwardDataList | paginate : { id:'communicationDashboardFormMaster',
                                                  itemsPerPage: size, currentPage: page, totalItems:totalLength } let i = index" 
                                              
                                                   style="cursor:pointer; text-align: center ">
                                                   <td matTooltip="Click To View">{{i+1}}</td>
                                                   <td  matTooltip="Click To View" 
                                                   >{{item.date | date:'dd/MM/yyyy'}}
                                                 </td>
                                                 <td matTooltip="Click To View" >{{item.type}}</td>
                                                 <td class="blue_col"
                                                  (click)="showbase64(item.communicationHash256)">View Document</td>
                                                    </tr>
                                            
                                              </tbody> 
                                          </table>
                                      </div>
                                      <div class="col-sm-12 pt-0 text-right">
                                              <pagination-controls id="communicationDashboardFormMaster" directionLinks="true"
                                    (pageChange)="onPageChange($event)" class="float-right pagination my-pagination"></pagination-controls>
                                          
                                      </div>
                                  </div>

                      </div>

  </div>
                  </div>
              </div>
              </div>
  
          </div>
      </div>
      </form>
  </div>