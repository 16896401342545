<div class="container-fluid bgcolor2" [formGroup]="goodInWordForm">
  <form [formGroup]="goodInWordForm" autocomplete="off" novalidate>
    <div class="row">
      <div class="col-sm-1">

      </div>
      <div class="col-sm-11">
        <div class="row pl-0 ">

          <div class="col-12 pl-0 ">
            <div class="row ">
              <div class="col-sm-9">

                <h1 class="pt-2 pl-0" id="toPurchaseInvoiceForm">Purchase Invoice</h1>
              </div>
              <div class="col-sm-3 mt-1">
                <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                  <a (click)="onback()" class="d-flex align-items-center pr-2 mt-2"><span
                      class="material-symbols-outlined pr-2">arrow_back</span>Back</a>
                  <a (click)="toPurchaseInvoiceList()" class="d-flex align-items-center ms-3 pr-2 mt-2"><span
                      class="material-symbols-outlined pr-2">reorder</span>List</a>
                      <a (click)="stockChart()" class="d-flex align-items-center ms-3 pr-2 mt-2"><span
                        class="material-symbols-outlined pr-2">reorder</span>Stock</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="row">
        <div class="col-sm-10">
          <div class="row ">

            <span><img src="../../assets/puchase_invoice.png" width="35rem"> </span> 
            <span><h1 class="fass ml-3 pt-1" id="toPurchaseInvoiceForm">Purchase Invoice</h1></span>
          </div>
          
        </div>
        <div class="col-sm-3 mt-1">
          <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
            <a (click)="onback()" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">arrow_back</span>Back</a>
            <a (click)="toPurchaseInvoiceList()" class="d-flex align-items-center ms-3 pr-2 mt-2"><span class="material-symbols-outlined pr-2">reorder</span>List</a>
  </div>
  </div>
      </div> -->

        <div class="row">
          <div class="col-sm-12 pr-4">
            <div class="row">
              <div class="card divbody borderradius p-1">


                <!-- <div class="col-sm-12">
                <div class="col-sm-12 bg-secondary rounded-3 pl-4 pr-4 mb-3">
                  <div class="row">

                    <h1 class="fass mt-2">Purchase Invoice</h1>

                  </div>
                </div>
              </div> -->
                <div class="col-sm-12">
                  <div class="row">
                    <div class="col-sm-3  ">
                      <mat-form-field class="example-full-width lable_style2" appearance="outline">
                        <mat-label class="lable_style2">GRN No</mat-label>
                        <input placeholder="GRN" readonly formControlName="grnNo" matInput type="text" />
                      </mat-form-field>

                    </div>

                    <!-- <div class="col-sm-3 " *ngIf="vendorCreate == true">
                      <div class="row">
                        <div class="col-sm-10 pr-1">
                          <mat-form-field class="example-full-width lable_style2" appearance="outline">
                            <mat-label>Expense Vendor Name</mat-label>
                            <mat-select #select3 (click)="fetchVendorList();" formControlName="vendorRefId">
                              <mat-select-filter *ngIf="select3.focused" [placeholder]="'Filter'"
                                [displayMember]="'vendorName'" [array]="vendorList"
                                (filteredReturn)="filteredListVendor =$event"></mat-select-filter>
                              <mat-option *ngFor="let vendor of filteredListVendor" [(value)]="vendor._id"
                                (onSelectionChange)="onSelectVendor(vendor._id,vendor.vendorName,vendor.state)">{{
                                vendor.vendorName }}</mat-option>
                            </mat-select>

                          </mat-form-field>
                          <mat-error class="margin_tp"
                            *ngIf="goodInWordForm.controls['vendorRefId'].touched && goodInWordForm.controls['vendorRefId'].errors?.required">
                            This field is mandatory.
                          </mat-error>
                        </div>

                        <div class="col-sm-2 mt-2">
                          <i (click)="openVendorPopUp();" matTooltip="Add New Expense Vendor"
                            class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"> </i>
                        </div>
                      </div>
                    </div> -->
                    <!-- <div class="col-sm-3 " *ngIf="vendorCreate == false">

                      <mat-form-field class="example-full-width lable_style2" appearance="outline">
                        <mat-label>Expense Vendor Name</mat-label>
                        <mat-select #select3 (click)="fetchVendorList();" formControlName="vendorRefId">
                          <mat-select-filter *ngIf="select3.focused" [placeholder]="'Filter'"
                            [displayMember]="'vendorName'" [array]="vendorList"
                            (filteredReturn)="filteredListVendor =$event"></mat-select-filter>
                          <mat-option *ngFor="let vendor of filteredListVendor" [(value)]="vendor._id"
                            (onSelectionChange)="onSelectVendor(vendor._id,vendor.vendorName,vendor.state)">{{
                            vendor.vendorName }}</mat-option>
                        </mat-select>

                      </mat-form-field>
                      <mat-error class="margin_tp"
                        *ngIf="goodInWordForm.controls['vendorRefId'].touched && goodInWordForm.controls['vendorRefId'].errors?.required">
                        This field is mandatory.
                      </mat-error>
                    </div>  -->
                    <div *ngIf="companyCreate" class="col-sm-3">
                      <div class="row">
                        <div class="col-sm-10 pr-1">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Select HA Company </mat-label>
                            <mat-select #select3 formControlName="haCompanyRefId">
                              <mat-select-filter *ngIf="select3.focused" [placeholder]="'Filter'"
                                [displayMember]="'companyName'" [array]="companyList"
                                (filteredReturn)="filteredListCompany =$event"></mat-select-filter>
                              <mat-option *ngFor="let item of filteredListCompany" [value]="item._id"
                                (click)="onSelectCompany(item._id,item.companyName,item.state)">
                                {{ item.companyName }}</mat-option>
                            </mat-select>
                          </mat-form-field>
                          <mat-error
                            *ngIf="goodInWordForm.controls['haCompanyRefId'].touched && goodInWordForm.controls['haCompanyRefId'].errors?.required">
                            This field is mandatory.
                          </mat-error>
                        </div>

                        <div class="col-sm-2 mt-2 no-print">
                          <i (click)="openCompanyPopUp();" matTooltip="Add HA Company"
                            class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"> </i>
                        </div>
                      </div>
                    </div> 
                    <div *ngIf="!companyCreate" class="col-sm-3">
                      <div class="row">
                        <div class="col-sm-12 pr-1">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Select HA Company </mat-label>
                            <mat-select #select3 formControlName="haCompanyRefId">
                              <mat-select-filter *ngIf="select3.focused" [placeholder]="'Filter'"
                                [displayMember]="'companyName'" [array]="companyList"
                                (filteredReturn)="filteredListCompany =$event"></mat-select-filter>
                              <mat-option *ngFor="let item of filteredListCompany" [value]="item._id"
                                (click)="onSelectCompany(item._id,item.companyName,item.state)">
                                {{ item.companyName }}</mat-option>
                            </mat-select>
                          </mat-form-field>
                          <mat-error
                            *ngIf="goodInWordForm.controls['haCompanyRefId'].touched && goodInWordForm.controls['haCompanyRefId'].errors?.required">
                            This field is mandatory.
                          </mat-error>
                        </div>

                      </div>
                    </div>
                    <div class="col-sm-3" *ngIf="branchCreate == true">
                      <div class="row">
                        <div class="col-sm-10 pr-1">
                          <mat-form-field class="example-full-width lable_style2" appearance="outline">
                            <mat-label>Branch Name</mat-label>
                            <mat-select #select1 formControlName="branchRefId">
                              <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'"
                                [displayMember]="'branchName'" [array]="branchList"
                                (filteredReturn)="filteredListBranch =$event" [disabled]="disableBranch">
                              </mat-select-filter>
                              <mat-option *ngFor="let branch of filteredListBranch" [(value)]="branch._id"
                                (click)="onSelectBranch(branch._id,branch.branchName)">{{ branch.branchName }}
                              </mat-option>
                            </mat-select>

                          </mat-form-field>
                          <mat-error class="margin_tp"
                            *ngIf="goodInWordForm.controls['branchRefId'].touched && goodInWordForm.controls['branchRefId'].errors?.required">
                            This field is mandatory.
                          </mat-error>
                        </div>

                        <div class="col-sm-2 mt-2">
                          <i (click)="openBranchPopUp();" matTooltip="Add New Branch"
                            class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"> </i>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-3" *ngIf="branchCreate == false">
                      <mat-form-field class="example-full-width lable_style2" appearance="outline">
                        <mat-label>Branch Name</mat-label>
                        <mat-select #select1 formControlName="branchRefId">
                          <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'"
                            [displayMember]="'branchName'" [array]="branchList"
                            (filteredReturn)="filteredListBranch =$event" [disabled]="disableBranch">
                          </mat-select-filter>
                          <mat-option *ngFor="let branch of filteredListBranch" [(value)]="branch._id"
                            (click)="onSelectBranch(branch._id,branch.branchName)">{{ branch.branchName }}</mat-option>
                        </mat-select>

                      </mat-form-field>
                      <mat-error class="margin_tp"
                        *ngIf="goodInWordForm.controls['branchRefId'].touched && goodInWordForm.controls['branchRefId'].errors?.required">
                        This field is mandatory.
                      </mat-error>
                    </div>
                    <div *ngIf="billingCreate" class="col-sm-3">
                      <div class="row"> 
                        <div class="col-sm-10 pr-1">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Billing Company
                              <!-- <span class="required-asterisk">*</span> -->
                            </mat-label>
                            <mat-select required #select1 formControlName="billingCompanyRefId">
                              <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'"
                                [displayMember]="'companyName'" [array]="billingCompanyList"
                                (filteredReturn)="filteredListBillingCompany =$event"></mat-select-filter>
                              <mat-option *ngFor="let item of filteredListBillingCompany" [value]="item._id"
                                (click)="companyClick(item._id,item.companyName);">
                                {{ item.companyName }}</mat-option>
                            </mat-select>

                          </mat-form-field>
                          <mat-error class="margin_tp"
                            *ngIf="goodInWordForm.controls['billingCompanyRefId'].touched && goodInWordForm.controls['billingCompanyRefId'].errors?.required">
                            This field is mandatory.
                          </mat-error>
                        </div>
                        <div class="col-sm-2 mt-2 no-print">
                          <i (click)="openBillingCompPopUp();" matTooltip="Add Billing Company"
                            class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="!billingCreate" class="col-sm-3">
                      <div class="row">
                        <div class="col-sm-12 pr-1">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Billing Company
                              <!-- <span class="required-asterisk">*</span> -->
                            </mat-label>
                            <mat-select required #select1 formControlName="billingCompanyRefId">
                              <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'"
                                [displayMember]="'companyName'" [array]="billingCompanyList"
                                (filteredReturn)="filteredListBillingCompany =$event"></mat-select-filter>
                              <mat-option *ngFor="let item of filteredListBillingCompany" [value]="item._id"
                                (click)="companyClick(item._id,item.companyName);">
                                {{ item.companyName }}</mat-option>
                            </mat-select>

                          </mat-form-field>
                          <mat-error class="margin_tp"
                            *ngIf="goodInWordForm.controls['billingCompanyRefId'].touched && goodInWordForm.controls['billingCompanyRefId'].errors?.required">
                            This field is mandatory.
                          </mat-error>
                        </div>

                      </div>
                    </div>
                    <div class="col-sm-3  ">
                      <mat-form-field class="example-full-width lable_style2" appearance="outline">
                        <mat-label class="lable_style2">Invoice No</mat-label>
                        <input required placeholder="Number" maxlength="16" formControlName="invoiceNo" matInput
                          type="text" />

                      </mat-form-field>
                      <mat-error class="margin_tp"
                        *ngIf="goodInWordForm.controls['invoiceNo'].touched && goodInWordForm.controls['invoiceNo'].errors?.required">
                        This field is mandatory.
                      </mat-error>
                      <mat-error class="margin_tp"
                        *ngIf="goodInWordForm.controls['invoiceNo'].touched && goodInWordForm.controls['invoiceNo'].errors?.pattern">
                        Please enter valid invoice No.
                      </mat-error>
                      <mat-error class="margin_tp"
                        *ngIf="goodInWordForm.controls['invoiceNo'].touched && goodInWordForm.controls['invoiceNo'].errors?.minlength">
                        Enter minimum length of 4 invoice No.
                      </mat-error>
                      <mat-error class="margin_tp"
                        *ngIf="goodInWordForm.controls['invoiceNo'].touched && goodInWordForm.controls['invoiceNo'].errors?.maxlength">
                        Enter maximum length of 10 invoice No.
                      </mat-error>
                    </div>

                    <div class="col-sm-3  ">
                      <mat-form-field class="example-full-width lable_style2" appearance="outline">
                        <mat-label class="lable_style2">Invoice Date</mat-label>
                        <input matInput [matDatepicker]="invoiceDate" formControlName="invoiceDate" id="invoiceDate"
                          placeholder="DD/MM/YYYY" [min]="minDate" [max]="maxDate">

                        <mat-datepicker-toggle matSuffix [for]="invoiceDate">
                        </mat-datepicker-toggle>
                        <mat-datepicker #invoiceDate></mat-datepicker>

                      </mat-form-field>
                      <mat-error class="margin_tp"
                        *ngIf="goodInWordForm.controls['invoiceDate'].touched && goodInWordForm.controls['invoiceDate'].errors?.required">
                        This field is mandatory.
                      </mat-error>
                    </div>
                    <div class="col-sm-3  ">
                      <mat-form-field class="example-full-width lable_style2" appearance="outline">
                        <mat-label class="lable_style2">Method Of Shifting</mat-label>
                        <input placeholder="Method Of Shifting" formControlName="methodOfShifting" matInput
                          type="text" />

                      </mat-form-field>
                      <!-- <mat-error class="margin_tp"
                    *ngIf="goodInWordForm.controls['methodOfShifting'].touched && goodInWordForm.controls['methodOfShifting'].errors?.required">
                    This field is mandatory.
                  </mat-error> -->
                      <mat-error class="margin_tp"
                        *ngIf="goodInWordForm.controls['methodOfShifting'].touched && goodInWordForm.controls['methodOfShifting'].errors?.pattern">
                        Please enter valid method of shifting.
                      </mat-error>
                    </div>
                    <div class="col-sm-3  ">
                      <mat-form-field class="example-full-width lable_style2" appearance="outline">
                        <mat-label class="lable_style2">Payment Terms</mat-label>
                        <input placeholder="Payment Terms" formControlName="paymentTerms" matInput type="text" />

                      </mat-form-field>
                      <!-- <mat-error class="margin_tp"
                      *ngIf="goodInWordForm.controls['paymentTerms'].touched && goodInWordForm.controls['paymentTerms'].errors?.required">
                      This field is mandatory.
                    </mat-error> -->
                    </div>
                    <div class="col-sm-3  ">
                      <mat-form-field class="example-full-width lable_style2" appearance="outline">
                        <mat-label class="lable_style2">Due Date</mat-label>
                        <input matInput [matDatepicker]="dueDate" formControlName="dueDate" id="dueDate"
                          placeholder="DD/MM/YYYY">

                        <mat-datepicker-toggle matSuffix [for]="dueDate">
                        </mat-datepicker-toggle>
                        <mat-datepicker #dueDate></mat-datepicker>

                      </mat-form-field>
                      <!-- <mat-error class="margin_tp"
                    *ngIf="goodInWordForm.controls['dueDate'].touched && goodInWordForm.controls['dueDate'].errors?.required">
                    This field is mandatory.
                  </mat-error> -->
                    </div>
                    <div class="col-sm-3  ">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Payment Option</mat-label>
                        <mat-select formControlName="paymentOption">
                          <mat-option value="Cash">Cash</mat-option>
                          <mat-option value="Credit">Credit</mat-option>
                        </mat-select>

                      </mat-form-field>
                      <!-- <mat-error class="margin_tp"
                   *ngIf="goodInWordForm.controls['paymentOption'].touched && goodInWordForm.controls['paymentOption'].errors?.required">
                   This field is mandatory.
                 </mat-error> -->
                    </div>
                    <div class="col-sm-3  ">
                      <mat-form-field class="example-full-width lable_style2" appearance="outline">
                        <mat-label class="lable_style2">PO No.</mat-label>
                        <mat-select #select2 formControlName="poNumber" class="colorblack">
                          <mat-select-filter *ngIf="select2.focused" [placeholder]="'Filter'"
                            [displayMember]="'placeOrederId'" [array]="poList"
                            (filteredReturn)="filteredListPoNo =$event"></mat-select-filter>

                          <!-- <mat-select #select2 formControlName="poNumber" class="colorblack">
                          <mat-select-filter *ngIf="select2.focused" [placeholder]="'Filter'"
                            [displayMember]="'placeOrederId'" [array]="poList" 
                            (filteredReturn)="filteredListPoNo =$event"></mat-select-filter> -->

                          <mat-option *ngFor="let item of filteredListPoNo" [(value)]="item.branchWiseId">{{
                            item.branchWiseId }}</mat-option>
                        </mat-select>
                      </mat-form-field>

                      <!-- <mat-error class="margin_tp"
                        *ngIf="goodInWordForm.controls['poNumber'].touched && goodInWordForm.controls['poNumber'].errors?.pattern">
                        Please enter valid po Number.
                      </mat-error>
                      <mat-error class="margin_tp"
                        *ngIf="goodInWordForm.controls['poNumber'].touched && goodInWordForm.controls['poNumber'].errors?.minlength">
                        Enter alphanumeric minimum length of 4 po Number.
                      </mat-error>
                      <mat-error class="margin_tp"
                        *ngIf="goodInWordForm.controls['poNumber'].touched && goodInWordForm.controls['poNumber'].errors?.maxlength">
                        Enter alphanumeric maximum length of 15 po Number.
                      </mat-error> -->
                    </div>
                    <div class="col-sm-3  ">
                      <mat-form-field class="example-full-width lable_style2" appearance="outline">
                        <mat-label class="lable_style2">SO No.</mat-label>
                        <input placeholder="SO No" formControlName="soNumber" maxlength="18" matInput type="text" />

                      </mat-form-field>
                      <!-- <mat-error class="margin_tp"
                        *ngIf="goodInWordForm.controls['soNumber'].touched && goodInWordForm.controls['soNumber'].errors?.required">
                        This field is mandatory.
                      </mat-error> -->
                      <mat-error class="margin_tp"
                        *ngIf="goodInWordForm.controls['soNumber'].touched && goodInWordForm.controls['soNumber'].errors?.pattern">
                        Please enter valid so Number.
                      </mat-error>
                      <mat-error class="margin_tp"
                        *ngIf="goodInWordForm.controls['soNumber'].touched && goodInWordForm.controls['soNumber'].errors?.minlength">
                        Enter Alphanumeric minimum length of 4 so Number.
                      </mat-error>
                      <mat-error class="margin_tp"
                        *ngIf="goodInWordForm.controls['soNumber'].touched && goodInWordForm.controls['soNumber'].errors?.maxlength">
                        Enter Alphanumeric maximum length of 18 so Number.
                      </mat-error>
                    </div>
                    <div class="col-sm-3  ">
                      <mat-form-field class="example-full-width lable_style2" appearance="outline">
                        <mat-label class="lable_style2">Order Date</mat-label>
                        <input matInput [matDatepicker]="orderDate" formControlName="orderDate" id="orderDate"
                          placeholder="DD/MM/YYYY" [max]="currentdate">

                        <mat-datepicker-toggle matSuffix [for]="orderDate">
                        </mat-datepicker-toggle>
                        <mat-datepicker #orderDate></mat-datepicker>

                      </mat-form-field>
                      <!-- <mat-error class="margin_tp"
                        *ngIf="goodInWordForm.controls['orderDate'].touched && goodInWordForm.controls['orderDate'].errors?.required">
                        This field is mandatory.
                      </mat-error> -->
                    </div>
                    <div class="col-sm-4 mt-3">
                      <div class="row">



                        <div *ngIf="hide == true" class="col-sm-3">
                          <!-- <h5 *ngIf="hide == false" class="mt-0 pt-2">Upload File</h5> -->
                          <h5 class="mt-0 pt-2">Uploaded File</h5>
                        </div> 
                        <div class="col-sm-4" *ngIf="hide == false">
                          <!-- <input  type="file" class="example-full-width " #fileUpload
                             name="Upload File" id="txtFileUpload" placeholder="Upload File"
                            (change)="onChange($event);" /> -->

                          <input #fileUpload type="file" style="display: none;" (change)="onChange($event)" />
                          <button type="button" class="btn btn-light shadow btn-sm m-0 mb-1 example-full-width"
                            matTooltip="Upload JPG,PNG,CSV or PDF image.300 x 300 required."
                            (click)="fileUpload.click()"><i class="ai-refresh-cw me-2"></i>Upload Photo</button>

                      
                        </div>
                        <div class="col-sm-7" *ngIf="hide == true">
                          <div class="row">
                            <a class="col-sm-8 blue_col mt-0 pt-2" (click)="showbase64(filedata)">{{fileName}}</a>
                            <i class="fa fa-times mt-0 pt-2" style="cursor:pointer;" (click)="remove()"
                              aria-hidden="true"></i>
                            <!-- <i class="col-sm-4 fa fa-trash-o " style="cursor:pointer;" (click)="remove()"></i> -->
                          </div>


                        </div>
                      </div>
                    </div>

                    <!-- <div class="col-sm-3  ">
                    <mat-form-field class="example-full-width lable_style2" appearance="outline">
                      <mat-label class="lable_style2">Transporter Name</mat-label>
                      <input placeholder="Name" formControlName="transporterName" matInput type="text" />
                    </mat-form-field>
                  </div> -->

                    <!-- <div class="col-sm-3  ">
                    <mat-form-field class="example-full-width lable_style2" appearance="outline">
                      <mat-label class="lable_style2">GRN Date</mat-label>
                      <input matInput [matDatepicker]="Date" formControlName="grnDate" id="Date"
                        placeholder="DD/MM/YYYY">

                      <mat-datepicker-toggle matSuffix [for]="Date">
                      </mat-datepicker-toggle>
                      <mat-datepicker #Date></mat-datepicker>
                    </mat-form-field>
                  </div> -->
                    <div class="col-sm-12 mt-2 table-responsive">
                      <table class="table table-bordered data-table-custom">
                        <thead>
                          <tr class="text-center">
                            <th>Device</th>
                            <th>Serial No.</th>
                            <!-- <th>PO No.</th>
                              <th>SO No.</th>
                              <th>Order Date</th> -->
                            <th>Qty</th>
                            <!-- <th >Unit of Measurement</th> -->
                            <th>Unit Price</th>
                            <th>Discount</th>
                            <th>Amount</th>
                            <!-- <th >CGST</th>
                              <th >SGST</th>
                              <th >IGST</th> -->
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody formArrayName="device">

                          <tr [formGroupName]="i"
                            *ngFor="let row of goodInWordForm.get('device')['controls']; let i = index; let last = last"
                            role="row" class="odd text-center">
                            <td class="w2 pl-2 pr-2">

                              <div class="row w2">
                                <div class="col-sm-10 ">
                                  <mat-form-field class="example-full-width  lable_style2" appearance="outline">
                                    <mat-label class="lable_style2">Device Name</mat-label>

                                    <mat-select #select4 formControlName="deviceRefId" class="colorblack">
                                      <mat-select-filter *ngIf="select4.focused" [placeholder]="'Filter'"
                                        [displayMember]="'model'" [array]="deviceList"
                                        (filteredReturn)="filteredListDevice =$event"></mat-select-filter>
                                      <mat-option *ngFor="let device of filteredListDevice" [(value)]="device._id"
                                        (click)="onSelectDevice(device._id,device.model,
                                        device.cgst,device.sgst,device.igst,device.has,device.isSerialNo,i)">{{
                                        device.model }}</mat-option>
                                    </mat-select>

                                  </mat-form-field>

                                  <mat-error class="margin_tp"
                                    *ngIf="row.controls.deviceRefId.touched && row.controls.deviceRefId.errors?.required">
                                    This Field is Mandatorty
                                  </mat-error>
                                </div>
                                <div class="col-sm-2 mt-2  pl-0 ml-0 ">
                                  <i (click)="openDevicePopUp();" matTooltip="Add Device"
                                    class="blue_col mTop fa-solid fa-circle-plus fa-lg"> </i>
                                </div>
                              </div>
                            </td>
                            <td class="w1 pl-2 pr-2">
                              <div class="row w1">
                                <div class="col-sm-10 ">
                                  <mat-form-field class="example-full-width lable_style2 " appearance="outline">
                                    <mat-label class="lable_style2 mt-1">Serial No.</mat-label>
                                    <!-- <input placeholder="Serial Number" formControlName="serialNumber" matInput
                                      type="text" readonly /> -->
                                    <textarea maxlength="1000" readonly matInput placeholder="Serial Number"
                                      formControlName="serialNumber" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                      cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea>
                                  </mat-form-field>
                                  <mat-error class="margin_tp"
                                    *ngIf="row.controls.serialNumber.touched && row.controls.serialNumber.errors?.required">
                                    This Field is Mandatory
                                  </mat-error>
                                  <mat-error class="margin_tp" *ngIf="row.controls.serialNumber.errors?.unique">
                                    Serial no. should be unique
                                  </mat-error>
                                </div>
                                <div class="col-sm-2 mt-2 w4 pl-0 ml-0">
                                  <i *ngIf="row.controls.isSerialNo.value" (click)="openPopUp(i);"
                                    matTooltip="Add Serial No" class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg">
                                  </i>
                                </div>
                                <!-- <div class="col-sm-2 pl-0 ml-0 widthh2 ">
                                <img class="mt-2 pr-1" (click)="openPopUp(i);" matTooltip="Add Serial No" width="30"
                                src="../../assets/add_img.png">
                              </div>  -->
                              </div>
                            </td>

                            <td class="coldisplay">
                              <mat-form-field class="example-full-width lable_style2 widthh3" appearance="outline">

                                <input placeholder="Serial Number" formControlName="serialNumberStatus" matInput
                                  type="text" />

                              </mat-form-field>
                              <!-- <mat-error class="margin_tp"
                                *ngIf="row.controls.serialNumberStatus.touched && row.controls.serialNumberStatus.errors?.required">
                                   This Field is Mandatory
                              </mat-error> -->
                            </td>
                            <!-- <td>

                                <mat-form-field class="example-full-width lable_style2 widthh" appearance="outline">
                                  <mat-label class="lable_style2">PO No.</mat-label>
                                  <input placeholder="PO No" formControlName="poNumber" matInput
                                    type="text" />

                                </mat-form-field>

                              </td>
                              <td>

                                <mat-form-field class="example-full-width lable_style2 widthh " appearance="outline">
                                  <mat-label class="lable_style2">SO No.</mat-label>
                                  <input placeholder="SO No" formControlName="soNumber" matInput
                                    type="text" />

                                </mat-form-field>

                              </td>
                              <td>

                                <mat-form-field class="example-full-width lable_style2 widthh" appearance="outline">
                                  <mat-label class="lable_style2">Order Date</mat-label>
                                  <input matInput [matDatepicker]="orderDate" formControlName="orderDate" id="orderDate"
                                    placeholder="DD/MM/YYYY">
            
                                  <mat-datepicker-toggle matSuffix [for]="orderDate">
                                  </mat-datepicker-toggle>
                                  <mat-datepicker #orderDate></mat-datepicker>
                                </mat-form-field>

                              </td> -->
                            <td class="w4 pl-2 pr-2">
                              <mat-form-field class="example-full-width lable_style2  w4" appearance="outline">
                                <mat-label class="lable_style2">Qty</mat-label>
                                <input placeholder="Qty" readonly formControlName="qty" matInput type="text" />

                              </mat-form-field>
                              <!-- <mat-error class="margin_tp"
                                *ngIf="row.controls.qty.touched && row.controls.qty.errors?.required">
                                   This Field is Mandatory
                              </mat-error> -->
                              <!-- <mat-form-field class="example-full-width lable_style2 widthh" appearance="outline">
                                  <mat-label class="lable_style2">Rate</mat-label>
                                  <input placeholder="Rate" formControlName="rate" (change)="changeAmount($event,i)" matInput type="text" />

                                </mat-form-field> -->

                            </td>
                            <!-- <td>
                                <mat-form-field class="example-full-width lable_style2  widthh5" appearance="outline">
                                  <mat-label class="lable_style2">Unit Of Measurement</mat-label>
                                  <input placeholder="Unit" formControlName="unitOfMeasurement" matInput type="text" />

                                </mat-form-field>
                                </td> -->
                            <td class="w3 pl-2 pr-2">
                              <mat-form-field class="example-full-width lable_style2  w3" appearance="outline">
                                <mat-label class="lable_style2">Unit Price</mat-label>
                                <input placeholder="Unit Price" formControlName="unitPrice" matInput
                                  (change)="changeAmount($event,i)" maxlength="7" />

                              </mat-form-field>
                              <mat-error class="margin_tp"
                                *ngIf="row.controls.unitPrice.touched && row.controls.unitPrice.errors?.pattern">
                                Please enter valid unit price.
                              </mat-error>
                              <mat-error class="margin_tp"
                                *ngIf="row.controls.unitPrice.touched && row.controls.unitPrice.errors?.minlength">
                                Enter minimum 2 digit unit price.
                              </mat-error>
                              <mat-error class="margin_tp"
                                *ngIf="row.controls.unitPrice.touched && row.controls.unitPrice.errors?.maxlength">
                                Enter maximum 7 digit unit price.
                              </mat-error>
                              <mat-error class="margin_tp"
                                *ngIf="row.controls.unitPrice.touched && row.controls.unitPrice.errors?.required">
                                This Field is Mandatory
                              </mat-error>
                            </td>
                            <td class="w5 pl-2 pr-2">
                              <mat-form-field class="example-full-width lable_style2 w5" appearance="outline">
                                <mat-label class="lable_style2">Discount</mat-label>
                                <!-- <input placeholder="Discount(₹)" formControlName="discount" matInput type="text"
                                     (input)="onDiscountSelect($event,i)"/> -->
                                <input placeholder="Discount" formControlName="discount" matInput type="text"
                                  (change)="onDiscountSelect($event,i)" />
                              </mat-form-field>
                              <mat-error class="margin_tp"
                                *ngIf="row.controls.discount.touched && row.controls.discount.errors?.pattern">
                                Please enter valid discount.
                              </mat-error>
                            </td>
                            <td class="w3 pl-2 pr-2">
                              <mat-form-field class="example-full-width lable_style2 w3 " appearance="outline">
                                <mat-label class="lable_style2">Amount</mat-label>
                                <input placeholder="Amount" formControlName="amount" matInput type="text" readonly />
                              </mat-form-field>
                            </td>
                            <!-- <td>
                                <mat-form-field class="example-full-width lable_style2 widthh8 " appearance="outline">
                                  <mat-label class="lable_style2">CGST</mat-label>
                                  <input placeholder="Amount" formControlName="cgst" (input)="changeTax($event,i)"
                                   matInput type="text" />
                                </mat-form-field>
                              </td>
                              <td>
                                <mat-form-field class="example-full-width lable_style2 widthh8 " appearance="outline">
                                  <mat-label class="lable_style2">SGST</mat-label>
                                  <input placeholder="Amount" formControlName="sgst" (input)="changeTax($event,i)" matInput type="text" />
                                </mat-form-field>
                              </td>
                              <td>
                                <mat-form-field class="example-full-width lable_style2 widthh8 " appearance="outline">
                                  <mat-label class="lable_style2">IGST</mat-label>
                                  <input placeholder="Amount" formControlName="igst" matInput type="text" readonly/>
                                </mat-form-field>
                              </td> -->
                            <td class="w3 pl-2 pr-2">
                              <div class="row w3 justify-content-center">
                                <div class="col-sm-6 ">
                                  <button mat-button mat-icon-button *ngIf="last" (click)="addNew(i);"
                                    [disabled]="goodInWordForm.controls.device.invalid">
                                    <!-- <mat-icon class="mt-2 iconcol">add_circle_outline</mat-icon> -->
                                    <img matTooltip="Add" class="mt-1" width="25" src="../../assets/add.png">
                                  </button>
                                </div>
                                <div class="col-sm-6 ">
                                  <button mat-button mat-icon-button (click)="deleteRow(i);"
                                    [disabled]="goodInWordForm.get('device')['controls']['length']===1">
                                    <!-- <mat-icon class="mt-2 iconcol">remove_circle_outline</mat-icon> -->
                                    <img matTooltip="Remove" class="mt-1" width="25" src="../../assets/dustbin.png">

                                  </button>
                                </div>
                              </div>
                            </td>
                            <!-- <td class="widthh9">
                            
                             <button mat-button mat-icon-button (click)="deleteRow(i);" matTooltip="Delete" [disabled]="goodInWordForm.get('device')['controls']['length']===1">
                             
                              <img matTooltip="Remove Invoice" class="mt-1" width="25" src="../../assets/dustbin.png"> 
                              
                            </button>
                            </td> -->
                          </tr>
                        </tbody>
                      </table>
                      <hr>

                    </div>
                    <div class="col-sm-2 mt-2" *ngIf="submitButton && purchaseInvoiceCreate">
                      <button class="btn btn-sm btn-primary example-full-width" (click)="submitPurchaseList()"
                        type="button">Save</button>
                    </div>
                    <div class="col-sm-2 mt-2" *ngIf="updateButton && purchaseInvoiceUpdate">
                      <button class="btn  btn-sm btn-primary example-full-width" (click)="updatePurchaseList()"
                        type="button">Update</button>
                    </div>  
                    <div class="col-sm-2 mt-2" *ngIf="cancelButton">
                      <button class="btn btn-primary btn-sm  example-full-width" type="reset"
                        (click)="cancelUpdate()">Cancel</button>
                    </div>
                    <div *ngIf="submitButton" class="col-sm-5">
                    </div>
                    <div *ngIf="updateButton" class="col-sm-3">
                    </div>
                    <div class="col-sm-3 offset-sm-1">
                      <label>Discount Rs.</label><br>
                      <label>Total Invoice Amount Rs.</label><br>
                      <label>Total Tax Rs.</label><br>
                      <label><b>Total Amount Rs.</b> </label><br>
                    </div>
                    <!-- <div class="col-sm-1 close_style">
                    <label class="fa fa-inr"> 0.00</label><br>
                    <label class="fa fa-inr"> 0.00</label><br>
                    <label class="fa fa-inr"> {{totalDiscount}}.00</label><br>
                    <label class="fa fa-inr">
                      {{totalAmount}}.00</label>
                  </div> -->  
                    <div class="col-sm-1 close_style">
                      <label class="fa fa-inr"> {{totalDiscount}}.00</label><br>
                      <label class="fa fa-inr">{{totalAmount}}</label><br>
                      <label class="fa fa-inr"> {{totalGst | number : '1.2-2'}}</label><br>
                      <label class="fa fa-inr"> {{totalFinalAmount | number : '1.2-2'}}</label><br>
                    </div>
                  </div>
                </div>
              </div>
            </div> 
          </div>
        </div>


        <div class="row mt-2">
          <div class="col-sm-12 pr-4">
            <div class="row">
              <div class="card divbody borderradius p-3 mb-3 pb-0">
                <div class="col-sm-12">
                  <div class="row">
                    <div class="col-sm-9 pl-1 pr-1">
                      <h1 class="titlecolor"> Purchase Invoice List</h1>
                    </div>
                    <div class="search-box col-sm-3 pb-2">
                      <input class="form-control amber-border" type="text" placeholder="Search"
                        formControlName="searchGoods" aria-label="Search" (keyup)="onKeyUpEvent($event)">
                      <div class="input-group-append">
                        <span class="input-group-text amber lighten-3" id="basic-text1"><span
                            class="material-symbols-outlined">search</span></span>
                      </div>
                    </div>

                    <div class="col-sm-12 table-responsive">
                      <table id="toPurchaseInvoiceList" style="text-align: center;"
                        class="table table-bordered data-table-custom">
                        <thead>
                          <tr class="text-center">

                            <th>GRN No</th>
                            <th>Invoice Date</th>
                            <th>Invoice No</th>
                            <!-- <th>PO No</th> -->
                            <th>Device Name</th>
                            <th>Branch Name </th>
                            <th>HA Company Name </th>
                            <th>Amount</th>

                            <th>Due Date</th>
                            <!-- <th>Action</th> -->
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="text-center" *ngFor="
                            let item of purchaseList
                            | paginate : { id:'purchase_pagination', 
                            itemsPerPage: size, currentPage: page, totalItems:totalLength }
                          " style="cursor: pointer;" (click)="toPurchaseInvoiceForm()"
                            (click)="prepopulation(item._id)">
                            <td class="pt-1 pb-1">
                              {{item.grnNo}}
                            </td>
                            <td class="pt-1 pb-1">
                              {{item.invoiceDate | date }}
                            </td>
                            <td class="pt-1 pb-1">
                              {{item.invoiceNo}}
                            </td>
                            <!-- <td class="pt-1 pb-1">
                              {{item.poNumber}}
                            </td> -->
                            <td class="pt-1 pb-1" matTooltip="Click To View">
                              {{ item.device[0].deviceName}}
                            </td>

                            <td class="pt-1 pb-1">
                              {{item.branchName}}
                            </td>
                            <td class="pt-1 pb-1">
                              {{item.haCompanyName}}
                            </td>
                            <td class="pt-1 pb-1">
                              {{item.totalInvoiceAmount}}
                            </td>

                            <td class="pt-1 pb-1">
                              {{item.dueDate | date }}
                            </td>
                            <!-- <td class="text-center">
                            <i matTooltip="View" class="fas fa-eye" (click)="prepopulation(item._id)"></i>
                          </td> -->
                            <!-- <button class="btn btn-secondary btn-sm" (click)="prepopulation(item._id)"><i class="fas fa-eye text-white"></i></button>
                                <button class="btn btn-danger btn-sm" (click)="deleteDoctor(item._id)"><i class="fas fa-trash"></i></button> -->

                          </tr>
                        </tbody>
                      </table>

                    </div>

                  </div>
                  <div class="col-sm-12 pt-0 text-right">
                    <pagination-controls id="purchase_pagination" directionLinks="true"
                      (pageChange)="onPageChange($event)" class="float-right pt-3 my-pagination"></pagination-controls>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  </form>
</div>