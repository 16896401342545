<div class="container-fluid bgcolor2" [formGroup]="earMouldNewForm">
  <form [formGroup]="earMouldNewForm" autocomplete="off" novalidate>
    <div class="row col-sm-12 p-0 m-0 body">
      <div class="col-sm-1 ">
 
      </div>
      <div class="col-sm-11 divName ">
          <div class="row pl-0 ">
            <div class="col-12 pl-0 "> 
              <div class="row ">
              <div class="col-sm-9">
              
                  <h1 class="pt-2 pl-0" id="toearMouldNewForm">Earmold Order Form</h1>
                  </div>
                  <div class="col-sm-3 mt-1">
                    <div class="page-title-right row justify-content-end mt-1 d-flex align-items-center float-end">
                      <a (click)="onback()" class="d-flex align-items-center pr-2 mt-2"><span class="material-symbols-outlined pr-2">arrow_back</span>Back</a>
                      <a (click)="toearMouldNewFormList()" class="d-flex align-items-center ms-3 pr-2 mt-2"><span class="material-symbols-outlined pr-2">reorder</span>List</a>  
                      <a *ngIf="isPrepopulate" (click)="printForm()" class="d-flex justify-content-end align-items-end mt-2">
                        <span class="material-symbols-outlined pr-1">print</span>Print</a> 
                    </div>
            </div>
          </div>
         </div>
         </div>

        <div class="row">
          <div class="col-sm-12 pr-4">
            <div class="row">

              <div class="card divbody borderradius mb-2 pl-3 pr-4 p-1">
                <div class="row mt-2">
                  <div class=" col-sm-1 mt-3">
                    <!-- <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Repair Id</mat-label>
                            <input matInput formControlName="repairId" readonly  />   
                          </mat-form-field> -->
                          <mat-label ngDefaultControl class="center" formControlName="branchWiseId" *ngIf="userType !== 'Super Admin'" >{{bId}}</mat-label>
                          <mat-label ngDefaultControl class="center" [value]="superBranchId" *ngIf="userType == 'Super Admin'" >{{superBranchId}}</mat-label>
                    <!-- <mat-form-field *ngIf="userType !== 'Super Admin'" class="example-full-width" appearance="outline">
                      <mat-label>Ear Mould Order Id</mat-label>
                      <input matInput formControlName="branchWiseId" />
                    </mat-form-field>
                    <mat-form-field *ngIf="userType == 'Super Admin'" class="example-full-width" appearance="outline">
                      <mat-label>Ear Mould Order Id</mat-label>
                      <input matInput [value]="superBranchId" readonly />
                    </mat-form-field> -->
                  </div>
                  <div class=" col-sm-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Date</mat-label>
                      <input matInput placeholder="Date" formControlName="date" readonly />
                    </mat-form-field>
                  </div> 
                  <div class="col-sm-3 " *ngIf="userType == 'Super Admin' && branchCreate == true">
                    <div class="row">
                      <div class="col-sm-10 pr-1">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Branch
                            <!-- <span class="required-asterisk">*</span> -->
                          </mat-label>
                          <mat-select #select1 formControlName="branchRefId">
                            <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'"
                              [displayMember]="'branchName'" [array]="BranchList"
                              (filteredReturn)="filteredListBranch =$event"></mat-select-filter>

                            <mat-option *ngFor="let item of filteredListBranch" [value]="item._id"
                              (click)="branchClick(item._id,item.branchName);">
                              {{ item.branchName }}</mat-option>
                          </mat-select>

                        </mat-form-field>
                        <mat-error
                          *ngIf="earMouldNewForm.controls['branchRefId'].touched && earMouldNewForm.controls['branchRefId'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                      </div>
                        <div class="col-sm-2 mt-2 no-print">
                          <i (click)="openBranchPopUp();" matTooltip="Add New Branch" class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                      </div>

                    </div>

                  </div>
                  <div class="col-sm-3 " *ngIf="userType == 'Super Admin' && branchCreate == false">
                    <div class="row">
                      <div class="col-sm-12 pr-1">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Branch
                            <!-- <span class="required-asterisk">*</span> -->
                          </mat-label>
                          <mat-select #select1 formControlName="branchRefId">
                            <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'"
                              [displayMember]="'branchName'" [array]="BranchList"
                              (filteredReturn)="filteredListBranch =$event"></mat-select-filter>

                            <mat-option *ngFor="let item of filteredListBranch" [value]="item._id"
                              (click)="branchClick(item._id,item.branchName);">
                              {{ item.branchName }}</mat-option>
                          </mat-select>

                        </mat-form-field>
                        <mat-error
                          *ngIf="earMouldNewForm.controls['branchRefId'].touched && earMouldNewForm.controls['branchRefId'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                      </div>

                    </div>

                  </div>
                  <div class="col-sm-5" *ngIf="userType == 'Super Admin'"></div>
                  <div class="col-sm-8" *ngIf="userType !== 'Super Admin'"></div>
                  <div class="col-sm-2">
                    <label class="lable_style pt-2 header">Manufacturing :</label>
                  </div>
                  <div class=" col-sm-3">

                    <mat-radio-group formControlName="manufacturing" class="example-full-width"
                      aria-label="Select an option">
                      <div class="row">
                        <div class="col-sm-5 mt-2">
                          <mat-radio-button value="Manufacturer"> Manufacturer</mat-radio-button>
                        </div>
                        <div class="col-sm-5 mt-2">
                          <mat-radio-button class="ml-3" value="Inhouse">Inhouse</mat-radio-button>
                        </div>

                      </div>
                    </mat-radio-group>
                  </div>
                  <div class=" col-sm-7"></div>
                </div>
              </div>


              <div class="card divbody borderradius mb-2 pl-3 pr-4 p-1">
                <div class="row mt-2  mb-2">
                  <div class="col-sm-12">
                    <h5 class="pb-0 mb-0">Patient Information :</h5>
                  </div>
                  <div class="row col-sm-12">
 
                    <div *ngIf="patientCreate" class="col-sm-3">
                      <div class="row">
                        <div class="col-sm-10 pr-1">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Select Patient </mat-label>
                            <input required (click)="openDepartmentPopUp();" matInput readonly
                              placeholder="Patient Name" formControlName="patientName" />
                            <!-- <mat-select #select3 formControlName="patientRefId">
                              <mat-select-filter *ngIf="select3.focused" [placeholder]="'Filter'" [displayMember]="'firstName'"
                              [array]="patientList"  (filteredReturn)="filteredListPatient =$event"></mat-select-filter>    
                              <mat-option  *ngFor="let item of filteredListPatient" [value]="item._id" (click)="onSelectPatient(item._id)"
                            >
                             {{ item.firstName }}</mat-option>
                            </mat-select> -->
                          </mat-form-field>
                          <mat-error
                            *ngIf="earMouldNewForm.controls['patientRefId'].touched && earMouldNewForm.controls['patientRefId'].errors?.required">
                            This field is mandatory.
                          </mat-error>
                        </div>
                        <div class="col-sm-2 mTop no-print">
                   <i (click)="openPatientPopUp();" matTooltip="Add New Patient" 
                   class="blue_col mTop fa-solid fa-circle-plus
                           pl-1 fa-lg"></i>  </div>
                      </div>
                    </div>
                    <div *ngIf="!patientCreate" class="col-sm-3">
                      <div class="row">
                        <div class="col-sm-12 pr-1">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Select Patient </mat-label>
                            <mat-select #select3 formControlName="patientRefId">
                              <mat-select-filter *ngIf="select3.focused" [placeholder]="'Filter'"
                                [displayMember]="'firstName'" [array]="patientList"
                                (filteredReturn)="filteredListPatient =$event"></mat-select-filter>
                              <mat-option *ngFor="let item of filteredListPatient" [value]="item._id"
                                (click)="onSelectPatient(item._id)">
                                {{ item.firstName }}</mat-option>
                            </mat-select>
                          </mat-form-field>
                          <mat-error
                            *ngIf="earMouldNewForm.controls['patientRefId'].touched && earMouldNewForm.controls['patientRefId'].errors?.required">
                            This field is mandatory.
                          </mat-error>
                        </div>

                      </div>
                    </div>
                    <div class="col-sm-3">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label> Patient Unique Id</mat-label>
                        <input matInput placeholder="First Name " formControlName="patientUniqueId" readonly />
                      </mat-form-field>
                      <!-- <mat-error class="margin_tp"
                  *ngIf="earMouldNewForm.controls['patientUniqueId'].touched && earMouldNewForm.controls['patientUniqueId'].errors?.required">
                  This field is mandatory.
                </mat-error> -->

                    </div>

                    <div class="col-sm-3">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label> First Name </mat-label>
                        <input matInput placeholder="First Name " formControlName="firstName" readonly />
                        <!-- <mat-error
                            *ngIf="repairComplaintForm.controls['leftSerialNo'].touched && repairComplaintForm.controls['leftSerialNo'].errors?.required">
                            This field is mandatory.
                          </mat-error> -->
                      </mat-form-field>
                      <!-- <mat-error class="margin_tp"
                          *ngIf="earMouldNewForm.controls['firstName'].touched && earMouldNewForm.controls['firstName'].errors?.required">
                          This field is mandatory.
                        </mat-error> -->
                    </div>
                    <!-- <div class="col-sm-3" *ngIf="userType == 'Super Admin'"></div> -->
                    <div class="col-sm-3">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label> Last Name</mat-label>
                        <input matInput placeholder="Last Name" formControlName="lastName" readonly />

                      </mat-form-field>
                      <!-- <mat-error class="margin_tp"
                          *ngIf="earMouldNewForm.controls['lastName'].touched && earMouldNewForm.controls['lastName'].errors?.required">
                          This field is mandatory.
                        </mat-error> -->
                    </div>

                    <div class="col-sm-3">
                      <div class="row">
                        <div class="col-sm-10">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label> Mobile No </mat-label>
                            <input matInput placeholder="mobileNo" maxlength="10" formControlName="mobile1" />
                          </mat-form-field>
                          <mat-error
                          *ngIf="earMouldNewForm.controls['mobile1'].touched && earMouldNewForm.controls['mobile1'].errors?.pattern">
                          Please enter valid Phone Number.
                        </mat-error>
                        </div> 
                        <div class="col-sm-2 wApp no-print">
                          <i class="bi bi-whatsapp whatsapp-icon wApp fa-lg" (click)="WhatsApp();" matTooltip="WhatsApp Integration" ></i>
                      </div>
                      </div>
                    </div>

                    <!-- <div class="col-sm-3">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Age</mat-label>
                        <input matInput placeholder="Age" formControlName="age" readonly />

                      </mat-form-field>
                     
                    </div> -->
                  </div>
                  <div class="row col-sm-12">
                    <div class="col-sm-12">
                      <h5 class="pb-0 mb-0">Add Signature :</h5>
                      <!-- <label class="lable_style pt-2 header">Add Signature :</label> -->
                    </div>
               

                  <div class="col-sm-3 ">
                    <div class="row pl-3">
                      <div class="col-sm-12 mr-0 pl-0 pr-0 ml-0">
                    <canvas (click)="canvasClick()" class="example-full-width" #canvas (touchstart)="startDrawing($event)"
                      (touchmove)="moved($event)"></canvas>
                      </div>
                      </div>

                  </div> 
               
                  <div *ngIf="signPadShow" class="col-sm-2"> 
       
                        <button class="btn btn-primary mt-2 example-full-width" type="button"
                          (click)="clearPad()">Clear Signature</button>
                   
              
                        <button class="btn btn-primary mt-2 example-full-width" type="button" color="secondary"
                          (click)="savePad()">Save Signature</button>
                   
                  </div>
                  <div *ngIf="signatureImg" class="col-sm-3">
                    <img src='{{ signatureImg }}' class="pb-0 mb-0 mt-0 pt-0" />
                  </div>
                  </div>

                </div>





              </div>
              <div class="card divbody borderradius mb-2 pl-3 pr-4 p-1">
                <div class="row mt-2 mb-2">
                  <div class="col-sm-12">
                    <h5 class="pb-0 mb-0">Hearing Aid Company Details :</h5>
                  </div>
                  <div class="row col-sm-12">
                    <div *ngIf="companyCreate" class="col-sm-3">
                      <div class="row">
                        <div class="col-sm-10 pr-1"> 
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Select HA Company </mat-label>
                            <mat-select #select2 formControlName="companyRefId">
                              <mat-select-filter *ngIf="select2.focused" [placeholder]="'Filter'"
                                [displayMember]="'companyName'" [array]="companyList"
                                (filteredReturn)="filteredListCompany =$event"></mat-select-filter>
                              <mat-option *ngFor="let item of filteredListCompany" [value]="item._id"
                                (click)="onSelectCompany(item.companyName,item.companyAddress,item.companyEmail,item.canNumber,item.make)">
                                {{ item.companyName }}</mat-option>
                            </mat-select>
                          </mat-form-field>
                          <mat-error
                            *ngIf="earMouldNewForm.controls['companyRefId'].touched && earMouldNewForm.controls['companyRefId'].errors?.required">
                            This field is mandatory.
                          </mat-error>
                        </div>
                        <div class="col-sm-2 mt-2 no-print">
                          <i (click)="openCompanyPopUp();" matTooltip="Add Company" class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="!companyCreate" class="col-sm-3">
                      <div class="row">
                        <div class="col-sm-12 pr-1">
                          <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Select HA Company </mat-label>
                            <mat-select #select2 formControlName="companyRefId">
                              <mat-select-filter *ngIf="select2.focused" [placeholder]="'Filter'"
                                [displayMember]="'companyName'" [array]="companyList"
                                (filteredReturn)="filteredListCompany =$event"></mat-select-filter>
                              <mat-option *ngFor="let item of filteredListCompany" [value]="item._id"
                                (click)="onSelectCompany(item.companyName,item.companyAddress,item.companyEmail,item.canNumber,item.make)">
                                {{ item.companyName }}</mat-option>
                            </mat-select>
                          </mat-form-field>
                          <!-- <mat-error
                      *ngIf="earMouldNewForm.controls['companyRefId'].touched && earMouldNewForm.controls['companyRefId'].errors?.required">
                      This field is mandatory.
                    </mat-error> -->
                        </div>

                      </div>
                    </div>
                    <div class="col-sm-3 mt-1">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label> Company Name</mat-label>
                        <input matInput placeholder="Company Name" formControlName="companyName" readonly />
                      </mat-form-field>
                    </div>
                    <!-- <div class="col-sm-3 mt-1">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>  Company Address</mat-label>
                      <input matInput placeholder="Company Address" formControlName="companyAddress" readonly />
                    </mat-form-field>
                  </div>  -->
                    <div class="col-sm-3 mt-1">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label> Company Email</mat-label>
                        <input matInput placeholder="Company Address" class="emailClass" formControlName="companyEmail"
                          readonly />
                      </mat-form-field>
                    </div>
                    <div class="col-sm-3 mt-1">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label> CAN Number</mat-label>
                        <input matInput placeholder="CAN Number" formControlName="canNumber" readonly />
                      </mat-form-field>
                    </div>
                    <div class="col-sm-3">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Make</mat-label>
                        <input matInput placeholder="Make" formControlName="make" readonly />

                      </mat-form-field>
                      
                    </div>
                  </div>
                </div>
              </div>
              <div class="card divbody borderradius mb-2 pl-3 pr-4 p-1">
                <div class="row mt-2  mb-2">
                  <div class="col-sm-6 mb-1">
                    <div class="row">
                    <div class="col-sm-12">
                      <h5 class="pb-0 mb-0">Ear Mold :</h5>
                    </div>
                    <div class="col-sm-3">
                      <label class=" mt-1">Ear :</label>
                    </div>
                    <div class="col-sm-4">
                      <mat-radio-group formControlName="ear" required class="example-full-width" aria-label="Select Type">
                        <div class="row">
                          <div class="col-sm-4 pt-1">
                            <mat-radio-button (click)="onEarChange('Right')" value="Right">Right</mat-radio-button>
                          </div>
                          <div class="col-sm-4 pt-1">
                            <mat-radio-button (click)="onEarChange('Left')" value="Left">Left</mat-radio-button>
                          </div>
                          <div class="col-sm-4 pt-1">
                            <mat-radio-button (click)="onEarChange('Both')" value="Both">Both</mat-radio-button>
                          </div>
                        </div>
                      </mat-radio-group>
                      <mat-error
                        *ngIf="earMouldNewForm.controls['ear'].touched && earMouldNewForm.controls['ear'].errors?.required">
                        This field is mandatory.
                      </mat-error>
                    </div>
                    <div class="col-sm-5"></div>
                    <div class="col-sm-3">
                      <label class=" mt-1">Estimates Required :</label>
                    </div>
                    <div class="col-sm-4">
                      <mat-radio-group formControlName="estimateRequired" class="example-full-width"
                        aria-label="Select Type">
                        <div class="row">
                          <div class="col-sm-4 pt-1">
                            <mat-radio-button value="Yes">Yes</mat-radio-button>
                          </div>
                          <div class="col-sm-4 pt-1">
                            <mat-radio-button value="No">No</mat-radio-button>
                          </div>
  
                        </div>
                      </mat-radio-group>
  
                    </div>
                    <div class="col-sm-5"></div>
                    </div>
                  </div>

                  <div class="col-sm-6 mb-1">
                    <div class="row col-sm-12">
                    <div class="col-sm-12 pl-0 ml-0">
                      <h5>Complaints/Instructions :</h5>
                    </div>
                    <div class="col-sm-6 pl-0 ml-0">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label> Complaints/Instructions </mat-label>
                        <!-- <input matInput placeholder="Address" formControlName="streetAddress" readonly /> -->
                        <textarea  maxlength="1000" 
                        matInput formControlName="complaint" cdkTextareaAutosize
                          #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2" cdkAutosizeMaxRows="5"></textarea>
                      </mat-form-field>
                    </div>

                  </div>
                  </div>

                </div>
              </div>
              <div class="col-sm-6 pl-0 pr-2 pt-0 pb-0">
                <div class="grey-title-card card p-0 mb-2">
                  <div class="grey-card-header bg-grey p-3 d-flex justify-content-between align-items-center">
                           <h5>LEFT</h5>
                           <div class="right d-flex align-items-center">
                              <span class="side-mark left-mark">Left</span>
                              <span class="material-symbols-outlined pointer" (click)="leftReset()">restart_alt</span>
                              <span class="material-symbols-outlined font-20 pointer"  (click)="copyToRight()">file_copy</span>
                           </div>
                  </div>
                  <div class="card=content p-4">
                    <div class="row">
                      <div class="col-lg-12 col-12 mb-lg-0 mb-2">
                        <div class="form-group">
                          <div class="row">
                            <div class="col-sm-2"> 
                              <h5>Hardness :</h5>
                            </div>



              <!-- <div class="col-sm-6  mb-2 pl-0 pr-2 pt-0 pb-0">
                <div class="card divbody borderradius  p-1">
                  <div class="row">
                    <div class="col-sm-12 text-center ">
                      <label class="lable_style pt-2 close_style b header marginLft blue">LEFT</label>
                      <i class="fa fa-refresh mt-2 ml-4 close_style color mr-2" (click)="leftReset()"
                        matTooltip="reset left Side" aria-hidden="true"></i>
                      <i class="fa fa-clone mt-2 ml-2 close_style color" (click)="copyToRight()"
                        matTooltip="Copy To Right Side"></i>
                    </div> -->

  
                
                    <div class="col-sm-4">
                      <mat-radio-group [disabled]="LeftDisable" formControlName="leftHardness"
                        class="example-full-width" aria-label="Select an option">
                        <div class="row">
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="Soft">Soft</mat-radio-button>
                          </div>
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="Hard">Hard</mat-radio-button>
                          </div>
                        </div>
                      </mat-radio-group>
                      <mat-error class="margin_tp"
                      *ngIf="earMouldNewForm.controls['leftHardness'].touched && earMouldNewForm.controls['leftHardness'].errors?.required">
                      This field is mandatory.
                    </mat-error>
                    </div>
                    <div class="col-sm-2">
                      <h5>Vent :</h5>
                      <!-- <label class="ml-3  mt-1">Vent :</label> -->
                    </div>
                    <div class="col-sm-4">
                      <mat-radio-group formControlName="leftVent" [disabled]="LeftDisable" class="example-full-width"
                        aria-label="Select an option">
                        <div class="row">
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="No Vent">No Vent</mat-radio-button>
                          </div>
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="1 mm">1 mm</mat-radio-button>
                          </div>
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="2 mm">2 mm</mat-radio-button>
                          </div>
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="IROS">IROS</mat-radio-button>
                          </div>
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="Open">Open</mat-radio-button>
                          </div>

                        </div>
                      </mat-radio-group>
                      <mat-error class="margin_tp"
                      *ngIf="earMouldNewForm.controls['leftVent'].touched && earMouldNewForm.controls['leftVent'].errors?.required">
                      This field is mandatory.
                    </mat-error>
                    </div>
                    <!--div class="vl"></div-->
                   
                    <div class="col-sm-12">
                      <hr>
                    </div>
                    <div class="col-sm-2">
                      <h5>Style :</h5>
                      <!-- <label class="ml-3 mt-1">Style :</label> -->
                    </div>
                    <div class="col-sm-4">
                      <mat-radio-group [disabled]="LeftDisable" formControlName="leftStyle" class="example-full-width"
                        aria-label="Select an option">
                        <div class="row">
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="Full Concha">Full Concha</mat-radio-button>
                          </div>
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="Half Concha">Half Concha</mat-radio-button>
                          </div>
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="Canal Tip">Canal Tip</mat-radio-button>
                          </div>
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="Skeleton">Skeleton</mat-radio-button>
                          </div>
                        </div>
                      </mat-radio-group>
                      <mat-error class="margin_tp"
                      *ngIf="earMouldNewForm.controls['leftStyle'].touched && earMouldNewForm.controls['leftStyle'].errors?.required">
                      This field is mandatory.
                    </mat-error>
                    </div>
                    <div class="col-sm-2">
                      <h5>Canal Length:</h5>
                      <!-- <label class="ml-3  mt-1">Canal Length:</label> -->
                    </div>
                    <div class="col-sm-4">
                      <mat-radio-group formControlName="leftCanalLenght" [disabled]="LeftDisable"
                        class="example-full-width" aria-label="Select an option">
                        <div class="row">
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="Small">Small</mat-radio-button>
                          </div>
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="Medium">Medium</mat-radio-button>
                          </div>
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="Long">Long</mat-radio-button>
                          </div>

                        </div>
                      </mat-radio-group>
                      <mat-error class="margin_tp"
                      *ngIf="earMouldNewForm.controls['leftCanalLenght'].touched && earMouldNewForm.controls['leftCanalLenght'].errors?.required">
                      This field is mandatory.
                    </mat-error>
                    </div>
                    <!--div class="vl"></div-->
                   
                    <div class="col-sm-12">
                      <hr>
                    </div>
                    <div class="col-sm-2">
                      <h5>HA Type :</h5>
                      <!-- <label class="ml-3 mt-1">HA Type:</label> -->
                    </div>
                    <div class="col-sm-4">
                      <mat-radio-group [disabled]="LeftDisable" formControlName="leftHAType" class="example-full-width"
                        aria-label="Select an option">
                        <div class="row">
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="BTE">BTE</mat-radio-button>
                          </div>
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="CIC">CIC</mat-radio-button>
                          </div>
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="IIC">IIC</mat-radio-button>
                          </div>
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="ITC">ITC</mat-radio-button>
                          </div>
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="ITE">ITE</mat-radio-button>
                          </div>
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="RIC">RIC</mat-radio-button>
                          </div>

                        </div>
                      </mat-radio-group>
                      <mat-error class="margin_tp"
                      *ngIf="earMouldNewForm.controls['leftHAType'].touched && earMouldNewForm.controls['leftHAType'].errors?.required">
                      This field is mandatory.
                    </mat-error>
                    </div>
                   
                    <!--div class="vl"></div-->
                    <div class="col-sm-2">
                      <h5>Removal String :</h5>
                      <!-- <label class="ml-3  mt-1">Removal String :</label> -->
                    </div>
                    <div class="col-sm-4">
                      <mat-radio-group formControlName="leftRemovalString" [disabled]="LeftDisable"
                        class="example-full-width" aria-label="Select an option">
                        <div class="row">
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="Yes">Yes</mat-radio-button>
                          </div>
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="No">No</mat-radio-button>
                          </div>

                        </div>
                      </mat-radio-group>
                          <mat-error class="margin_tp"
                      *ngIf="earMouldNewForm.controls['leftRemovalString'].touched && earMouldNewForm.controls['leftRemovalString'].errors?.required">
                      This field is mandatory.
                    </mat-error>
                    </div>
                  </div>
                </div>
              </div>
              </div>
              </div>
              </div>
              </div>
              <div class="col-sm-6  pl-0 pr-2 pt-0 pb-0">
                <div class="grey-title-card card p-0 mb-2">
                  <div class="grey-card-header bg-grey p-3 d-flex justify-content-between align-items-center">
                           <h5>RIGHT</h5>
                           <div class="right d-flex align-items-center">
                              <span class="side-mark right-mark">Right</span>
                              <span class="material-symbols-outlined pointer" (click)="rightReset()">restart_alt</span>
                              <span class="material-symbols-outlined font-20 pointer"  (click)="copyToLeft()">file_copy</span>
                           </div>
                  </div>
                  <div class="card=content p-4">
                    <div class="row">
                      <div class="col-lg-12 col-12 mb-lg-0 mb-2">
                        <div class="form-group">
                          <div class="row">
                            <div class="col-sm-2">
                              <h5>Hardness :</h5>
                            </div>

              <!-- <div class="col-sm-6  mb-2 p-0">
                <div class="card divbody borderradius  p-1">

                  <div class="row">

                    <div class="col-sm-12 text-center ">
                      <label class="lable_style pt-2 close_style b header marginLft red">RIGHT</label>
                      <i class="fa fa-refresh mt-2 ml-4 close_style mr-2 color" (click)="rightReset()"
                        matTooltip="reset right Side" aria-hidden="true"></i>
                      <i class="fa fa-clone mt-2 ml-2 color" (click)="copyToLeft()" matTooltip=" Copy To Left Side"></i>
                    </div>
                    <div class="col-sm-2">
                      <label class="ml-3 mt-1">Hardness:</label>
                    </div> -->
                    <div class="col-sm-4">
                      <mat-radio-group formControlName="rightHardness" [disabled]="RightDisable"
                        class="example-full-width" aria-label="Select an option">
                        <div class="row">
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="Soft">Soft</mat-radio-button>
                          </div>
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="Hard">Hard</mat-radio-button>
                          </div>
                        </div>
                      </mat-radio-group>
                      <mat-error class="margin_tp"
                      *ngIf="earMouldNewForm.controls['rightHardness'].touched && earMouldNewForm.controls['rightHardness'].errors?.required">
                      This field is mandatory.
                    </mat-error>
                    </div>
                    <div class="col-sm-2">
                      <h5>Vent :</h5>
                      <!-- <label class="ml-3  mt-1">Vent :</label> -->
                    </div>
                    <div class="col-sm-4">
                      <mat-radio-group formControlName="rightVent" [disabled]="RightDisable" class="example-full-width"
                        aria-label="Select an option">
                        <div class="row">
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="No Vent">No Vent</mat-radio-button>
                          </div>
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="1 mm">1 mm</mat-radio-button>
                          </div>
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="2 mm">2 mm</mat-radio-button>
                          </div>
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="IROS">IROS</mat-radio-button>
                          </div>
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="Open">Open</mat-radio-button>
                          </div>

                        </div>
                      </mat-radio-group>
                      <mat-error class="margin_tp"
                      *ngIf="earMouldNewForm.controls['rightVent'].touched && earMouldNewForm.controls['rightVent'].errors?.required">
                      This field is mandatory.
                    </mat-error>
                    </div>
                    <!--div class="vl"></div-->
                  
                    <div class="col-sm-12">
                      <hr>
                    </div>
                    <div class="col-sm-2">
                      <h5>Style :</h5>
                      <!-- <label class="ml-3 mt-1">Style :</label> -->
                    </div>
                    <div class="col-sm-4">
                      <mat-radio-group [disabled]="RightDisable" formControlName="rightStyle" class="example-full-width"
                        aria-label="Select an option">
                        <div class="row">
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="Full Concha">Full Concha</mat-radio-button>
                          </div>
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="Half Concha">Half Concha</mat-radio-button>
                          </div>
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="Canal Tip">Canal Tip</mat-radio-button>
                          </div>
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="Skeleton">Skeleton</mat-radio-button>
                          </div>
                        </div>
                      </mat-radio-group>
                      <mat-error class="margin_tp"
                      *ngIf="earMouldNewForm.controls['rightStyle'].touched && earMouldNewForm.controls['rightStyle'].errors?.required">
                      This field is mandatory.
                    </mat-error>
                    </div>
                    <div class="col-sm-2">
                      <h5>Canal Length:</h5>
                      <!-- <label class="ml-3  mt-1">Canal Length:</label> -->
                    </div>
                    <div class="col-sm-4">
                      <mat-radio-group formControlName="rightCanalLenght" [disabled]="RightDisable"
                        class="example-full-width" aria-label="Select an option">
                        <div class="row">
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="Small">Small</mat-radio-button>
                          </div>
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="Medium">Medium</mat-radio-button>
                          </div>
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="Long">Long</mat-radio-button>
                          </div>

                        </div>
                      </mat-radio-group>
                      <mat-error class="margin_tp"
                      *ngIf="earMouldNewForm.controls['rightCanalLenght'].touched && earMouldNewForm.controls['rightCanalLenght'].errors?.required">
                      This field is mandatory.
                    </mat-error>
                    </div>
                    <!--div class="vl"></div-->
                   
                    <div class="col-sm-12">
                      <hr>
                    </div>
                    <div class="col-sm-2">
                      <h5>HA Type :</h5>
                      <!-- <label class="ml-3 mt-1">HA Type:</label> -->
                    </div>
                    <div class="col-sm-4">
                      <mat-radio-group formControlName="rightHAType" [disabled]="RightDisable"
                        class="example-full-width" aria-label="Select an option">
                        <div class="row">
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="BTE">BTE</mat-radio-button>
                          </div>
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="CIC">CIC</mat-radio-button>
                          </div>
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="IIC">IIC</mat-radio-button>
                          </div>
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="ITC">ITC</mat-radio-button>
                          </div>
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="ITE">ITE</mat-radio-button>
                          </div>
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="RIC">RIC</mat-radio-button>
                          </div>

                        </div>
                      </mat-radio-group>
                      <mat-error class="margin_tp"
                      *ngIf="earMouldNewForm.controls['rightHAType'].touched && earMouldNewForm.controls['rightHAType'].errors?.required">
                      This field is mandatory.
                    </mat-error>
                    </div>
                    <!--div class="vl"></div-->
                    <div class="col-sm-2">
                      <h5>Removal String :</h5>
                      <!-- <label class="ml-3  mt-1">Removal String :</label> -->
                    </div>
                    <div class="col-sm-4">
                      <mat-radio-group formControlName="rightRemovalString" [disabled]="RightDisable"
                        class="example-full-width" aria-label="Select an option">
                        <div class="row">
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="Yes">Yes</mat-radio-button>
                          </div>
                          <div class="col-sm-6 pt-1">
                            <mat-radio-button value="No">No</mat-radio-button>
                          </div>

                        </div>
                      </mat-radio-group>
                      <mat-error class="margin_tp"
                      *ngIf="earMouldNewForm.controls['rightRemovalString'].touched && earMouldNewForm.controls['rightRemovalString'].errors?.required">
                      This field is mandatory.
                    </mat-error>
                    </div>
                  </div>
                </div>
              </div>
              </div>
              </div>
              </div>
              </div>


              <div class="card divbody borderradius mb-2 pl-3 pr-4 p-1">
                <div class="row mt-2  mb-2">
                  <div class="col-sm-6 mb-1">
                    <h5 class="pb-0 mb-0">Enclosures :</h5>
                  </div> 
                  <div class="col-sm-6  mb-1">
                    <h5 class="pb-0 mb-0">Bill To Company :</h5>
                  </div>
                  <div class="col-sm-2">
                    <mat-checkbox class="example-full-width " formControlName="audiogram">
                      Audiogram 
                    </mat-checkbox>
                    <!-- <label *ngIf="hide == false" class="header">Upload File:</label>
                    <label *ngIf="hide == true" class="header">Uploaded File:</label> -->
                  </div> 
                  <!-- <div class="col-sm-2">
                    <label *ngIf="hide == false" class="header">Upload File:</label>
                    <label *ngIf="hide == true" class="header">Uploaded File:</label>
                    </div> -->
                    <div class="col-sm-2" *ngIf="hide == false">
                    <input #fileUpload type="file" style="display: none;" (change)="onChange($event)" />
                      <button type="button" class="btn btn-light shadow btn-sm m-0 example-full-width"
                       matTooltip="Upload JPG,PNG,CSV or PDF image.300 x 300 required." 
                       (click)="fileUpload.click()" ><i class="ai-refresh-cw me-2" ></i>Upload File or Photo</button>
                  </div> 
                  <div class="col-sm-2" *ngIf="hide == true">
                    <div class="row ">
                      <a class="col-sm-10" (click)="showbase64(audiogramFile)">
                        <label class="blue_col mt-0 pt-0">{{fileName}}</label></a> 
                        <i class="fa fa-times col-sm-2 pointer" (click)="remove()" 
                        aria-hidden="true" style="cursor:pointer;" ></i>
                    </div> 
                  </div>
                  <div class="col-sm-2"></div>
                  <!-- <div class="col-sm-3" *ngIf="hide == false">
                    <input  type="file" class="example-full-width " #fileUpload
                     name="Upload File" id="txtFileUpload" placeholder="Upload File"
                    (change)="onChange($event);" />
                </div>
                <div class="col-sm-3 " *ngIf="hide == true">
                  <div class="row"> 
                    <a class="col-sm-8" (click)="showbase64(audiogramFile)">{{fileName}}</a>
                    <i class="col-sm-4 fa fa-trash-o" (click)="remove()"></i>
                  </div>
                </div> --> 
                  <div *ngIf="billingCreate" class="col-sm-3">
                    <div class="row">
                      <div class="col-sm-10 pr-1"> 
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Billing Company
                            <!-- <span class="required-asterisk">*</span> -->
                          </mat-label>
                          <mat-select #select1 formControlName="billingRefId">
                            <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'"
                              [displayMember]="'companyName'" [array]="billingCompanyList"
                              (filteredReturn)="filteredListBillingCompany =$event"></mat-select-filter>
                            <mat-option *ngFor="let item of filteredListBillingCompany" [value]="item._id"
                              (click)="companyClick(item._id,item.companyName);">
                              {{ item.companyName }}</mat-option>
                          </mat-select>

                        </mat-form-field>
                        <mat-error class="margin_tp"
                          *ngIf="earMouldNewForm.controls['billingRefId'].touched && earMouldNewForm.controls['billingRefId'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                      </div>
                      <div class="col-sm-2 mt-2 no-print">
                        <i (click)="openBillingCompPopUp();" matTooltip="Add Billing Company" class="blue_col mTop fa-solid fa-circle-plus pl-1 fa-lg"></i>
                      </div>
                    </div> 
                  </div>
                  <div *ngIf="!billingCreate" class="col-sm-3">
                    <div class="row">
                      <div class="col-sm-12 pr-1">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label>Billing Company
                            <!-- <span class="required-asterisk">*</span> -->
                          </mat-label>
                          <mat-select #select1 formControlName="billingRefId">
                            <mat-select-filter *ngIf="select1.focused" [placeholder]="'Filter'"
                              [displayMember]="'companyName'" [array]="billingCompanyList"
                              (filteredReturn)="filteredListBillingCompany =$event"></mat-select-filter>
                            <mat-option *ngFor="let item of filteredListBillingCompany" [value]="item._id"
                              (click)="companyClick(item._id,item.companyName);">
                              {{ item.companyName }}</mat-option>
                          </mat-select>

                        </mat-form-field>
                        <mat-error class="margin_tp"
                          *ngIf="earMouldNewForm.controls['billingRefId'].touched && earMouldNewForm.controls['billingRefId'].errors?.required">
                          This field is mandatory.
                        </mat-error>
                      </div>

                    </div>
                  </div>
                  <div class="col-sm-3"></div>
                  <!-- <div class="col-sm-3">
        <mat-checkbox class="example-full-width " formControlName="earImpression" >
         Ear Impressions
      </mat-checkbox>
        </div> -->
                  <div class="col-sm-3 ">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Remarks</mat-label>
                      <!-- <input maxlength="10" matInput placeholder="Remarks" formControlName="earImpressionText" /> -->
                      <textarea matInput maxlength="1000" formControlName="earImpressionText"
                      cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                      cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" ></textarea>
                    </mat-form-field>
                    <!-- <mat-error class="margin_tp"
                      *ngIf="earMouldNewForm.controls['earImpressionText'].touched && earMouldNewForm.controls['earImpressionText'].errors?.pattern">
                      Please enter valid Ear Impressions.
                    </mat-error> -->
                  </div>
                  <div class="col-sm-3"></div>
                  <div class="col-sm-3">
                    <div class="row">
                      <div class="col-sm-10 pr-1"> 
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-label> Status</mat-label>
    
                          <mat-select formControlName="status">
                            <mat-option value="Inprocess">Inprocess</mat-option>
                            <mat-option value="Completed">Completed</mat-option>
                          </mat-select>
                        </mat-form-field>
                       
                      </div>
                    
                    </div> 

                    
                  </div>

                </div>

              </div>

              <div class="row col-sm-12 no-print">
                <div *ngIf="submitButton" class="col-sm-10  mb-2"></div>
                <div *ngIf="updateButton" class="col-sm-6  mb-2"></div>
                <div *ngIf="submitButton" class="col-sm-2   example-full-width mb-2">
                  <button type="button" *ngIf="earMouldCreate" class="btn btn-sm btn-primary example-full-width "
                    (click)="submitEarMouldOrderList();"> Save</button>

                </div>
                <div *ngIf="updateButton" class="col-sm-2 mb-2">
                  <button class="btn btn-primary btn-sm example-full-width" type="button"
                    (click)="printForm();">Generate Pdf</button>
                </div>
                <div *ngIf="updateButton" class="col-sm-2 mb-2">
                  <button type="button" *ngIf="earMouldUpdate" class="btn btn-sm btn-primary example-full-width"
                    (click)="updateEarMouldOrderList();"> Update</button>
                </div>
                <div *ngIf="cancelButton" class="col-sm-2 mb-2 ">
                  <button class="btn btn-primary btn-sm example-full-width" type="reset"
                    (click)="cancelUpdate()">Cancel</button>
                </div>
              </div>

              <div class="card divbody col-sm-12 borderradius p-3 pb-0 no-print">

                <div class="row">


                  <div class="col-sm-9 ">
                    <h1 class="titlecolor"> Ear Mould Order Form List</h1>
                  </div>
                  <div class="search-box col-sm-3 pb-2">
                    <input class="form-control amber-border" type="text" placeholder="Search" 
                    formControlName="searchEarMouldOrder" aria-label="Search" (keyup)="onKeyUpEvent($event)"
                    >
                    <div class="input-group-append">
                       <span class="input-group-text amber lighten-3" id="basic-text1"><span class="material-symbols-outlined">search</span></span>
                    </div>
                 </div> 
                  <div class="col-sm-12">
                    <table id="toearMouldNewFormList"
                    class="table table-bordered data-table-custom">
                      <thead>
                        <tr class="text-center">
                          <th>Patient Name </th>
                          <th>Date</th>
                          <th *ngIf="userType == 'Super Admin'">Branch Name</th>
                          <th>Company Name</th>
                          <th>Billing Company Name</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of listOfEarMouldOrders | paginate : { id:'ear_pagination', 
                                              itemsPerPage: size, currentPage: page, totalItems:totalLength }"
                          (click)="prepopulation(item._id)" (click)="toearMouldNewForm()"
                          style="cursor:pointer; text-align: center ">

                          <td class="labelStyle" matTooltip="Click To View" >
                            <label *ngIf="item.firstName; else elseBlock1">
                              {{item.firstName}} {{item.lastName}}
                            </label>
                           <ng-template #elseBlock1>
                            <label>{{item.patientName}}</label>
                          </ng-template> 

                            </td>

                          
                          <td matTooltip="click to view">{{item.createdAt | date :'dd/MM/yyyy'}}</td>

                          <td *ngIf="userType == 'Super Admin'" matTooltip="click to view">{{item.branchName}}</td>
                          <td matTooltip="click to view">{{item.companyName}}</td>
                          <td matTooltip="click to view">{{item.billingCompanyName}}</td>
                            <td matTooltip="click to view">{{item.status}}</td>
                        </tr>
 



                      </tbody>
                    </table>

                    <div class="col-sm-12 pt-0 text-right">
                      <pagination-controls id="ear_pagination" directionLinks="true" (pageChange)="onPageChange($event)"
                        class="float-right pagination my-pagination"></pagination-controls>

                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>

        </div>
      </div>

    </div>

  </form>
</div>